import { AnnouncementForm } from "./AnnouncementForm";
import { EntityAddForm, EntityAddFormPage } from "../common/entity/EntityAddForm";
import { EntityCloneForm, EntityCloneFormPage } from "../common/entity/EntityCloneForm";
import { EntityCloneFormProps, EntityDetailViewProps, EntityEditFormProps } from "../common/entity/EntityInterfaces";
import { EntityEditForm, EntityEditFormPage } from "../common/entity/EntityEditForm";
import { EntityDetailPage, EntityDetailView } from "../common/entity/EntityDetailPage";
import { AnnouncementsDetail } from "./AnnouncementsDetail";
import { AnnouncementsTable } from "./Table/AnnouncementsTable";
import { EntityTablePage } from "../common/entity/EntityTablePage";

// Add
export const AnnouncementAddForm = () => {
  return <EntityAddForm entityTypeId={"announcements"}>{(props) => <AnnouncementForm {...props} />}</EntityAddForm>;
};

export const AnnouncementAddFormPage = () => {
  return (
    <EntityAddFormPage>
      <AnnouncementAddForm />
    </EntityAddFormPage>
  );
};

// Clone
export const AnnouncementCloneForm = ({ id }: EntityCloneFormProps<"announcements">) => {
  return (
    <EntityCloneForm id={id} entityTypeId={"announcements"}>
      {(props) => <AnnouncementForm {...props} />}
    </EntityCloneForm>
  );
};

export const AnnouncementCloneFormPage = () => {
  return (
    <EntityCloneFormPage<"announcements"> isIntId>{(id) => <AnnouncementCloneForm id={id} />}</EntityCloneFormPage>
  );
};

// Edit
export const AnnouncementEditForm = ({ id }: EntityEditFormProps<"announcements">) => {
  return (
    <EntityEditForm id={id} entityTypeId={"announcements"} filters={{}}>
      {(props) => <AnnouncementForm {...props} />}
    </EntityEditForm>
  );
};

export const AnnouncementEditFormPage = () => {
  return <EntityEditFormPage<"announcements"> isIntId>{(id) => <AnnouncementEditForm id={id} />}</EntityEditFormPage>;
};

// Detail
export const AnnouncementsDetailView = ({ id }: EntityDetailViewProps<"announcements">) => {
  return (
    <EntityDetailView id={id} entityTypeId={"announcements"} filters={{}}>
      {(props) => <AnnouncementsDetail {...props} />}
    </EntityDetailView>
  );
};

export const AnnouncementDetailsPage = () => {
  return <EntityDetailPage<"announcements"> isIntId>{(id) => <AnnouncementsDetailView id={id} />}</EntityDetailPage>;
};

export interface AnnouncementsTableOptions {
  showSidebar?: boolean;
}
export const AnnouncementsTablePage = ({ showSidebar }: AnnouncementsTableOptions) => {
  return (
    <EntityTablePage entityTypeId={"announcements"}>
      {(props) => <AnnouncementsTable {...props} showSidebar={showSidebar} />}
    </EntityTablePage>
  );
};
