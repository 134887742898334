import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { FormButtons } from "../common/forms/FormButtons";
import { FormHeader } from "../common/forms/FormHeader";
import styles from "../common/forms/forms.module.css";
import { EntityFormProps } from "../common/entity/EntityInterfaces";
import { InventoryItem, inventoryItemsConstants } from "../api/Inventories";
import { EntityCustomTypeForm, useCustomTypeForm } from "../Customization/CustomTypes/generics/useCustomTypeForm";
import { useCallback } from "react";
import { InputFormField } from "../common/formfields/InputFormField";
import * as yup from "yup";
import { toUppercase } from "../common/helperfunctions/stringFunctions";
import { ProjectsVirtualizedSelectForm } from "../common/forms/EntityForms/formsVirtualized/ProjectsVirtualizedSelectForm";

export const inventoryItemFormBaseSchema = {
  name: yup.string().required("Inventory name is required").typeError("Inventory name is required"),
  description: yup.string().optional(),
  customType: yup.object().required("Custom type is required").typeError("Custom type is required"),
};

export const InventoryItemForm = ({
  fieldLabels,
  permissions,
  title,
  subtitle,
  initialValues,
  onSubmit,
  onCancel,
  loading,
  submitButtonLabel,
  event,
}: EntityFormProps<"inventoryItems">) => {
  const { defaultValues, typedFormSchema, type, processCustomFields, setType, types } = useCustomTypeForm({
    initialValues: initialValues,
    formSchema: inventoryItemFormBaseSchema,
    typeId: initialValues?.customType?.id,
    entityType: "Inventory",
  });

  const {
    register,
    handleSubmit,
    control,
    setValue,
    trigger,
    formState: { errors, isSubmitting },
  } = useForm<Partial<InventoryItem>>({
    values: defaultValues,
    resolver: yupResolver(typedFormSchema),
  });

  // Helper function to process some fields
  const processEntity = useCallback((entity: Partial<InventoryItem>) => {
    return {
      ...entity,
      customType: entity.customType ?? null,
      ...(!entity.customType && { customFields: {} }),
    } as Partial<InventoryItem>;
  }, []);

  return (
    <>
      <FormHeader title={title} subtitle={subtitle || initialValues?.customType?.name} />
      <form
        onSubmit={(e) => {
          e.preventDefault();
        }}
        autoComplete="off"
        className={`form-horizontal ${styles.form_holder}`}
      >
        <fieldset>
          <legend className="col-md-offset-2 col-md-10">Basic details</legend>
        </fieldset>

        <InputFormField
          id="name"
          label={fieldLabels.name}
          errors={errors}
          register={register}
          autoFocus={true}
          required={true}
        />

        <ProjectsVirtualizedSelectForm
          id="projects"
          label={fieldLabels.projects}
          control={control}
          isMulti
          horizontal
          allowCreateEntity
          showControls
          disabled={!!type?.rootHierarchy}
          filters={{ currentUserHasAddPermission: true }}
        />

        {/* Render custom type */}
        <EntityCustomTypeForm
          entityType="Inventory"
          typeId={type?.id}
          type={type}
          types={types}
          setType={setType}
          control={control}
          register={register}
          setValue={setValue}
          initialValues={initialValues}
          errors={errors}
          required
          disableTypeSelection={!!initialValues?.customType && (event === "ADD" || event === "CLONE")}
          disableParentSelection={!!initialValues?.parent && (event === "ADD" || event === "CLONE")}
          entityConstants={inventoryItemsConstants}
        />

        <FormButtons
          groupName="inventories"
          entityId={initialValues ? initialValues.id : undefined}
          onClose={onCancel}
          onSubmit={handleSubmit(async (entity) => {
            await trigger();
            await onSubmit(processCustomFields(processEntity(entity)));
          })}
          submitButtonLabel={submitButtonLabel || "Save changes"}
          disabled={isSubmitting}
          errors={errors}
          loading={loading}
        />
      </form>
    </>
  );
};
