import { LucideIcon } from "../../../common/icon/LucideIcon";
import Codeblock from "../../../common/textfields/Codeblock/Codeblock";
import styles from "../CommonPages.module.css";

export interface PageErrorProps {
  code?: number | string;
  errorMessage?: string;
  errorDesc?: string;
  stackTrace?: string;
}
/**
 * Component to display an error page with details about the error.
 * @author @CorradoSurmanowicz
 * @param {PageErrorProps} props - The properties for the PageError component.
 * @param {string} props.code - The error code.
 * @param {string} props.errorMessage - The error message.
 * @param {string} props.errorDesc - A description of the error.
 * @param {string} props.stackTrace - The stack trace of the error.
 *
 * @returns {JSX.Element} The rendered error page component.
 */
export const PageError = ({ code, errorMessage, errorDesc, stackTrace }: PageErrorProps) => {
  return (
    <div className={styles.container}>
      <div className={styles.image_error}>
        <LucideIcon name="circle-alert" />
      </div>
      <div className="flex row-nowrap align-center gap-5">
        <div className={styles.desc}>
          <div className="flex col-nowrap align-center">
            <span style={{ fontWeight: "bold", fontSize: "1.5em" }}>Oops!</span>
            <span style={{ fontWeight: "400", fontSize: "0.75em" }}>Something went wrong.</span>
          </div>
          <button
            className="btn btn-primary"
            onClick={() => window.history.back()}
            style={{ width: "min-content", margin: "auto" }}
          >
            Take me back
          </button>
        </div>
      </div>
      <div className={styles.details}>
        {code && <span className={styles.codeDesc}>Code: {code}</span>}
        {errorMessage && <span className={styles.codeDesc}>Error: {errorMessage}</span>}
        {errorDesc && <span className={styles.codeDesc}>Description: {errorDesc}</span>}
        {stackTrace && (
          <>
            <span className={styles.codeDesc}>Stack trace:</span>
            <div className={styles.stack}>
              <Codeblock>{stackTrace}</Codeblock>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
