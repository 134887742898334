import { initSettingType, settingType } from "../../SpectrumViewer/ViewerNavigation/TrackSettings";
import { Color, HierarchyBase } from "../../ViewerLayout/ViewerLayoutTypes";
import { annotationVisibilityType, colorType, trackState } from "./../../ViewerLayout/ViewerTypes";

export interface treeNode {
  index: number;
  type: "track" | "list";
  name: string;
  // id?: string;
  visible: boolean;
  selected: boolean;
  // data: boolean;
  state: trackState;
  content?: treeNode[];
  trackIDs: string[];
  active?: boolean;
  // colors?: colorType | string[] | string;
  color?: colorType;
  border?: string;
  settings?: settingType;
  simpleColor?: boolean;
}

export class TreeNodeProperties {
  visible: boolean;
  selected: boolean;
  state: trackState;
  active?: boolean;
  expanded?: boolean;
  color?: Color;
  border?: string;
  simpleColor?: boolean;
  settings: settingType;
  annotation: Record<annotationVisibilityType, boolean>;

  constructor(ref?: Partial<TreeNodeProperties>) {
    this.visible = ref?.visible ?? true;
    this.selected = ref?.selected ?? false;
    this.state = ref?.state ?? trackState.loading;
    this.active = ref?.active ?? true;
    this.expanded = ref?.expanded ?? true;
    this.color = ref?.color ?? undefined;
    this.border = ref?.border ?? undefined;
    this.simpleColor = ref?.simpleColor ?? undefined;
    this.annotation = ref?.annotation ?? { tics: true, labels: true };
    this.settings = initSettingType(ref?.settings);
  }
}

export type selectActionType = "add" | "reset";
export type iconModeType = "eye" | "noEye" | "noEye-noCog";

export type TreeViewerProps = {
  children: HierarchyBase;
  iconMode: iconModeType;
  nodeProperties: Record<string, TreeNodeProperties>;
  onVisibilityChange: (trackIDs: string[], visible: boolean) => void;
  onSelectionChange: (trackIDs: string[], selection: boolean, mode: selectActionType) => void;
  onColorChange: (trackIDs: string[], color: Color) => void;
  onTrackSettingsChange: (trackIDs: string[], type: string, settings: any) => void;
  onTracksZoom: (trackIDs: string[], visible: boolean) => void;
};

type clickModeType = "visibility" | "selection";

export interface trackStateType {
  multiTrack: boolean;
  clickMode: clickModeType;
  multiSelect: boolean;
  iconMode: iconModeType;
}
