import React, { useCallback, useEffect, useMemo, useState } from "react";
import { LucideIcon } from "../../icon/LucideIcon";
import styles from "./ColumnsSelector.module.css";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import "./styles.css";
import GridLayout from "react-grid-layout";
import { useResizeDetector } from "react-resize-detector";
import { ExclusiveDropdown } from "../../buttons/ExclusiveDropdown/ExclusiveDropdown";
import { GenericEntity } from "../../../api/GenericTypes";
import { Path } from "react-hook-form";
import { CheckboxSquare } from "../../formfields/CheckboxFormField/CheckboxFormField";

interface ColumnSetting<Entity extends GenericEntity> {
  pos: number;
  active: boolean;
  label?: string;
  header: string; //TODO deprecate header definition here and map it from the ID instead
  property?: Path<Entity>;
}
export interface ColumnsSettings<Entity extends GenericEntity> {
  [id: string]: ColumnSetting<Entity>;
}

interface ColumnSelectorProps<Entity extends GenericEntity> {
  id: string;
  column: ColumnSetting<Entity>;
  isDragging: boolean;
  onChangeHandle: (id: string) => void;
  disabled?: boolean;
}
const ColumnSelector = <Entity extends GenericEntity>({
  id,
  column,
  isDragging,
  onChangeHandle,
  disabled,
}: ColumnSelectorProps<Entity>) => {
  return (
    <div className={`${styles.column} DragHandle`} key={id.toString()}>
      <div className={"flex row-nowrap align-center"}>
        <strong>
          <LucideIcon name="align-justify" color={"var(--gray-400)"} />
        </strong>
      </div>
      <div
        className="flex row-nowrap align-center gap-5"
        onClick={(e) => {
          if (!isDragging) {
            e.preventDefault();
            e.stopPropagation();
            if (!disabled) onChangeHandle(id);
          }
        }}
        style={{ cursor: "pointer" }}
      >
        {/* <span
          className={`${styles.checkboxVirtual} ${column.active ? styles.checkboxVirtualActive : ""}`}
          style={{ pointerEvents: "none" }}
        >
          {column.active && <LucideIcon name="check" strokeWidth={4} color={"var(--white)"} />}
        </span> */}

        <CheckboxSquare checked={column.active} style={{ pointerEvents: "none" }} disabled={disabled} />

        <span className={styles.label} style={{ pointerEvents: "none" }}>
          <span
            style={{
              fontStyle: "normal",
              fontWeight: 600,
              fontSize: "12px",
              lineHeight: "17px",
              textTransform: "uppercase",
            }}
          >
            {column.header}
          </span>{" "}
          {column.label && (
            <label className="label label-soft-info" style={{ margin: 0 }}>
              {column.label}
            </label>
          )}
        </span>
      </div>
    </div>
  );
};
interface ColumnsSelectorProps<Entity extends GenericEntity> {
  defaults: ColumnsSettings<Entity>;
  columnsSetting?: ColumnsSettings<Entity>;
  onColumnsSettingChange: (settings: ColumnsSettings<Entity>) => void;
  onReset?: () => void;
}
export const ColumnsSelector = <Entity extends GenericEntity>({
  defaults,
  columnsSetting,
  onColumnsSettingChange,
  // dispatchTabStore,
  onReset,
}: ColumnsSelectorProps<Entity>) => {
  const [show, setShow] = useState(false);
  const [node, setNode] = useState<HTMLDivElement>();
  const { ref, width } = useResizeDetector();
  const [settings, setSettings] = useState<ColumnsSettings<Entity>>(columnsSetting ?? defaults);
  const [isDragging, setIsDragging] = useState<boolean>(false);

  useEffect(() => {
    setSettings(columnsSetting ?? defaults);
  }, [columnsSetting, defaults]);

  const getColumnSettings = useCallback(
    (
      currentSettings: ColumnsSettings<Entity> | undefined,
      defaultColumnSettings: ColumnsSettings<Entity> | undefined
    ) => {
      // Some consistency check for the ColumnSettings
      if (currentSettings && !Object.keys(currentSettings).length) {
        // console.log("Returning default", defaultColumnSettings);
        return { ...defaultColumnSettings };
      }
      if (currentSettings && defaultColumnSettings) {
        // Some Settings in local storage -> we need to perform some checks
        const currentKeys = Object.keys(currentSettings);
        const incomingKeys = Object.keys(defaultColumnSettings);
        const isEqual =
          incomingKeys.length === currentKeys.length && incomingKeys.every((key) => currentKeys.includes(key));
        if (isEqual) return currentSettings;
        if (incomingKeys.length > currentKeys.length) {
          // More keys in defaults than in current state
          let colSettings: ColumnsSettings<Entity> = {};
          incomingKeys.forEach((key) => {
            if (currentKeys.includes(key)) {
              colSettings[key] = {
                ...currentSettings[key],
                label: defaultColumnSettings[key].label ?? currentSettings[key].label,
                header: defaultColumnSettings[key].header ?? currentSettings[key].header,
              };
            } else {
              colSettings[key] = defaultColumnSettings[key];
            }
          });
          return colSettings;
        } else {
          // More keys in current state than in defaults

          let colSettings: ColumnsSettings<Entity> = {};
          Object.keys(defaultColumnSettings).forEach((key) => {
            if (Object.hasOwn(currentSettings, key)) {
              colSettings[key] = {
                ...currentSettings[key],
                label: defaultColumnSettings[key].label ?? currentSettings[key].label,
                header: defaultColumnSettings[key].header ?? currentSettings[key].header,
              };
            } else {
              colSettings[key] = defaultColumnSettings[key];
            }
          });
          return colSettings;

          // return currentSettings;
        }
      } else {
        return { ...defaultColumnSettings };
      }
    },
    []
  );

  useEffect(() => {
    const _columnSetting = getColumnSettings(settings, defaults);
    setSettings(_columnSetting);
    onColumnsSettingChange(_columnSetting);
  }, [defaults, getColumnSettings, onColumnsSettingChange, settings]);

  const layout = useMemo(() => {
    if (Object.keys(settings).length > 0) {
      return Object.entries(settings).map(([id, col]) => ({
        i: id.toString(),
        x: 0,
        y: col.pos,
        w: 1,
        h: 1,
        isDraggable: true,
        isResizable: false,
      })) as GridLayout.Layout[];
    }
  }, [settings]);

  const parent = useCallback((node: HTMLDivElement) => setNode(node), []);

  const handleClick = useCallback(
    (event: MouseEvent) => {
      if (node && !node.contains(event.target as Node)) {
        setShow(false);
      }
    },
    [node]
  );

  const onChangeHandle = useCallback((id: string) => {
    setSettings((prev) => ({ ...prev, [id]: { ...prev[id], active: !prev[id].active } }));
  }, []);

  const activeItems = Object.fromEntries(Object.entries(settings).filter(([id, column]) => column.active));
  const disabled = Object.keys(activeItems).length === 1;

  const onLayoutChange = useCallback((layout: GridLayout.Layout[]) => {
    setSettings((prev) => {
      return Object.fromEntries(layout.map((l) => [l.i, { ...prev[l.i], pos: l.y }]));
    });
  }, []);

  useEffect(() => {
    document.addEventListener("click", handleClick);
    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, [handleClick]);

  return (
    // <div className={styles.container}>
    <ExclusiveDropdown
      show={show}
      setShow={setShow}
      triggerComponent={(onToggleDropdown) => (
        <div className={styles.column_button} onClick={onToggleDropdown} title="Column settings">
          <LucideIcon name="columns-2" />
        </div>
      )}
    >
      <div className={styles.columns} ref={parent}>
        <div className={styles.column_head}>Columns</div>
        <div className={styles.columns_body} ref={ref}>
          {layout && width && (
            <GridLayout
              layout={layout}
              cols={1}
              rowHeight={26}
              preventCollision={false}
              width={width}
              onLayoutChange={(layout) => onLayoutChange(layout)}
              style={{ margin: "0px 0px", overflow: "auto" }}
              margin={[0, 0]}
              isBounded={false}
              onDrag={() => setIsDragging(true)}
              onDragStop={() => setTimeout(() => setIsDragging(false), 100)}
              draggableHandle=".DragHandle"
            >
              {Object.entries(settings).map(([id, column], index) => (
                <div key={id.toString()}>
                  <ColumnSelector
                    id={id}
                    column={column}
                    isDragging={isDragging}
                    onChangeHandle={onChangeHandle}
                    disabled={disabled && !!activeItems?.[id]}
                  />
                </div>
              ))}
            </GridLayout>
          )}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            borderTop: "1px solid var(--gray-300)",
            padding: "5px 0px",
          }}
        >
          <button
            className="btn btn-xs btn-ghost-secondary"
            onClick={() => {
              setSettings({ ...defaults });
              onReset?.();
              // setShow(false);
            }}
          >
            Reset to defaults
          </button>
        </div>
      </div>
    </ExclusiveDropdown>
  );
};
