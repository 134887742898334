export function convertDateFields<T>(data: any, fields: (keyof T)[]): T
{
  for (const field of fields) {
    data[field] = new Date(data[field])
  }
  return data as T;
}

export function convertDateFieldsInList<T>(data: any[],fields: (keyof T)[]): T[]
{
  return data.map(x => convertDateFields(x, fields));
}