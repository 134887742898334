import { StackTraces } from "./StackTrace";
import { generateUid } from "../tools/UID/UID";
import { initType, CommandParameter, PipelineCommand, trackListType } from "./PipelineTypes";

export class PipelineNode {
  name: string;
  id: string;

  command?: PipelineCommand;
  parameter: Record<string, CommandParameter>;

  connections: string[];
  index: number;
  errors: StackTraces;
  warnings: StackTraces;
  updated: boolean;

  constructor(init?: PipelineNode) {
    this.name = "Node";
    this.id = generateUid();
    this.index = -1;
    this.command = undefined;
    this.parameter = {};
    this.connections = [];
    this.switcher = () => 0;
    this.warnings = new StackTraces();
    this.errors = new StackTraces();
    this.updated = true;

    initType(this, init);
  }

  run(tracks: trackListType): trackListType {
    if (!this.command) return [];
    const result = this.command.run(tracks);

    this.warnings.addTracesWithMessage(this.command.warnings, {
      component: "PipelineNode.run",
      id: this.id,
      message: `Node '${this.name}' execution warning`,
    });

    if (this.command.errors.length > 0) {
      this.errors.addTracesWithMessage(this.command.errors, {
        component: "PipelineInterpreter.runProgram",
        id: this.id,
        message: `Execution of Node '${this.name}' failed.`,
      });
      return [];
    }

    return result;
  }

  reset() {
    this.warnings = new StackTraces();
    this.errors = new StackTraces();
    this.command?.reset();
  }

  switcher(): number {
    return 0;
  }
}
