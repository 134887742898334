import React, { useCallback, useEffect, useMemo, useState } from "react";

import { generateUid } from "../tools/UID/UID";
import { Color } from "../ViewerLayout/ViewerLayoutTypes";
import { ColorNeedsUpdate, initColor } from "../ViewerLayout/ViewerLayoutUtils";
import { ColorNeedsUpdate as OldColorNeedsUpdate, colorType } from "../ViewerLayout/ViewerTypes";
import { ColorSelector } from "./ColorSelector";
import { Tools, ToolsType } from "./Tools";

export function makeOldGradient(color: colorType) {
  let fill = "black";
  let gradient: any = undefined;

  // console.log("makeGradient", color);
  if (color.colors === undefined) return [fill, gradient];

  if (color.colors.length === 1) fill = color.colors[0];
  else if (color.colors.length > 1) {
    // console.log("makeGradient", color);
    // const size = color.colors.length - 1;
    const lines = color.colors.map((c, i) => {
      // const p = (100 * i) / size;
      // console.log(">>>", c, color.offsets[i] + "%");
      return <stop key={i} offset={100 * (color?.offsets?.[i] ?? 0) + "%"} style={{ stopColor: c, stopOpacity: 1 }} />;
    });
    const id = generateUid();
    // console.log("makeGradient", lines);
    gradient = (
      <linearGradient id={id} x1="0%" y1="0%" x2="100%" y2="0%">
        {lines}
      </linearGradient>
    );

    fill = "url(#" + id + ")";
  }
  return [fill, gradient];
}

export const OldColorBox = React.memo(
  ({
    color,
    onColorChange,
    allowColorChange,
    width,
    height,
  }: {
    color: colorType | string;
    onColorChange?: any;
    allowColorChange?: boolean;
    width?: number;
    height?: number;
  }) => {
    const [boxColor, setBoxColor] = useState<colorType>({ colors: [], offsets: [] });
    const [dim, setDim] = useState<{ width: number; height: number }>();

    // const [toolState, setToolState] = useState<ToolsType>();

    const [toolState, setToolState] = useState<ToolsType>({
      x: undefined,
      y: undefined,
      // active: false,
    });

    const [, setTool] = useState<any>();

    useEffect(() => {
      let c: colorType | undefined = undefined;
      if (typeof color === "string") c = { colors: [color], offsets: [0] };
      // else if (typeof color === "object" && color?.id && color?.id !== boxColor?.id) setBoxColor(color);
      else if (
        typeof color === "object" &&
        color?.colors &&
        color?.colors.some((c, i) => c !== boxColor.colors[i] || (color?.offsets?.[i] ?? 0) !== boxColor.offsets[i])
      )
        c = color;

      if (c && OldColorNeedsUpdate(boxColor, c)) setBoxColor(c);
    }, [color, boxColor]);

    useEffect(() => {
      // console.log(boxColor, "===", colors);
      setDim({ width: width || 10, height: height || 10 });
      // if (Array.isArray(color)) setBoxColor(color);
    }, [width, height]);

    useEffect(() => {
      if (toolState?.active) {
        setTool(
          <Tools state={toolState} setState={setToolState}>
            {/* <ColorSelector color={boxColor} setColor={changeColor} /> */}
          </Tools>
        );
      } else {
        setTool(undefined);
        if (onColorChange && toolState?.active === false) onColorChange(boxColor);
      }
    }, [toolState, boxColor, onColorChange]);

    const toolHandler = useCallback(
      (e: any) => {
        // console.log("Test", e.target.getBoundingClientRect(), e.clientX, e.clientY);
        if (allowColorChange) {
          // console.log("toolHandler", e.target.getBoundingClientRect(), e.x + 0, e.y + 0);
          const ts = { x: e.clientX, y: e.clientY, active: true };
          // const ts = { x: e.x, y: e.y, active: true };
          // console.log("toolHandler", ts);
          setToolState(ts);
          e.stopPropagation();
        }
        // else if (onColorChange) onColorChange();
      },
      [allowColorChange]
    );

    // const changeColor = (color: colorType) => {
    //   // console.log("changeColor", color, ColorNeedsUpdate(boxColor, color));

    //   setBoxColor(color);
    //   // if (onColorChange) onColorChange(color);
    // };

    // const tool = (
    //   <Tools state={toolState} setState={setToolState}>
    //     {console.log("rerender", boxColor.id)}
    //     <ColorSelector color={boxColor} setColor={changeColor} />
    //   </Tools>
    // );

    // useEffect(
    //   () =>
    //     setTool(
    //       <Tools state={toolState} setState={setToolState}>
    //         {console.log("rerender", boxColor.id)}
    //         <ColorSelector color={boxColor} setColor={changeColor} />
    //       </Tools>
    //     ),
    //   [changeColor]
    // );

    const [fill, gradient] = useMemo((): any[] => makeOldGradient(boxColor), [boxColor]);

    // console.log("boxColor", boxColor, "=>", fill, gradient);

    // const smallrect = <rect width={dim?.width} height={dim?.height} style={{ fill: fill }} />;
    // const smallrect = (
    //   <rect width={dim?.width} height={dim?.height} style={{ stroke: "black", strokeWidth: "1px", fill: fill }} />
    // );

    return (
      <span>
        {/* {toolState.active ? tool : undefined} */}
        {/* {tool} */}
        <Tools state={toolState} setState={setToolState}>
          {/* <ColorSelector color={boxColor} setColor={changeColor} /> */}
        </Tools>

        <svg width={dim?.width} height={dim?.height} onClick={(e) => toolHandler(e)}>
          <defs>{gradient}</defs>
          {/* {smallrect} */}
          <rect width={dim?.width} height={dim?.height} style={{ stroke: "black", strokeWidth: "2px", fill: fill }} />
        </svg>
      </span>
    );
  }
);

export function makeGradient(color: Color) {
  let fill = "black";
  let gradient: any = undefined;

  // console.log("makeGradient", color);
  if (color.colors === undefined) return [fill, gradient];

  if (color.colors.length === 1) fill = color.colors[0].color;
  else if (color.colors.length > 1) {
    // console.log("makeGradient", color);

    let last = 0;
    const offsets = color.colors.map((c, i) => {
      const result = c.offset ?? last;
      last = result;
      return result;
    });

    // const size = color.colors.length - 1;
    const lines = color.colors.map((c, i) => {
      // const p = (100 * i) / size;
      // console.log(">>>", c, color.offsets[i] + "%");
      return <stop key={i} offset={100 * offsets[i] + "%"} style={{ stopColor: c.color, stopOpacity: 1 }} />;
    });
    const id = generateUid();
    // console.log("makeGradient", lines);
    gradient = (
      <linearGradient id={id} x1="0%" y1="0%" x2="100%" y2="0%">
        {lines}
      </linearGradient>
    );

    fill = "url(#" + id + ")";
  }
  return [fill, gradient];
}

export const ColorBox = React.memo(
  ({
    color,
    onColorChange,
    allowColorChange,
    width,
    height,
  }: {
    color: Color;
    onColorChange?: any;
    allowColorChange?: boolean;
    width?: number;
    height?: number;
  }) => {
    const [boxColor, setBoxColor] = useState<Color>(initColor(color));
    const [dim, setDim] = useState<{ width: number; height: number }>();

    // const [toolState, setToolState] = useState<ToolsType>();

    const [toolState, setToolState] = useState<ToolsType>({
      x: undefined,
      y: undefined,
    });

    useEffect(() => {
      if (ColorNeedsUpdate(boxColor, color)) setBoxColor(color);
      // ignored because boxColor is set by color
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [color]);

    useEffect(() => {
      setDim({ width: width || 10, height: height || 10 });
    }, [width, height]);

    useEffect(() => {
      if (toolState?.active) {
      } else {
        if (onColorChange && toolState?.active === false) onColorChange(boxColor);
      }
    }, [toolState, boxColor, onColorChange]);

    const toolHandler = useCallback(
      (e: any) => {
        if (allowColorChange) {
          const ts = { x: e.clientX, y: e.clientY, active: true };
          setToolState(ts);
          e.stopPropagation();
        }
      },
      [allowColorChange]
    );

    const [fill, gradient] = useMemo((): any[] => makeGradient(boxColor), [boxColor]);

    return (
      <span>
        {/* {toolState.active ? tool : undefined} */}
        {/* {tool} */}
        <Tools state={toolState} setState={setToolState}>
          <ColorSelector color={boxColor} setColor={setBoxColor} />
        </Tools>

        <svg width={dim?.width} height={dim?.height} onClick={(e) => toolHandler(e)}>
          <defs>{gradient}</defs>
          {/* {smallrect} */}
          <rect width={dim?.width} height={dim?.height} style={{ stroke: "black", strokeWidth: "2px", fill: fill }} />
        </svg>
      </span>
    );
  }
);
