import { useCallback, useEffect, useState } from "react";
import { useGet, usePost } from "../../../../../api/BaseEntityApi";
import { requestFileBytes, requestFileLines, FilesStructure } from "../../../../../api/Datasets";

export const arrayBufferToString = (buf: Uint8Array) => {
  const decoder = new TextDecoder("utf-8");
  return decoder.decode(buf);
};

export const formatBytes = (bytes: number, decimals: number = 2) => {
  if (bytes === 0) return "0 Bytes";
  const k = 1000;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};

interface FilePreviewProps {
  id: number;
  body?: requestFileBytes | requestFileLines;
  chunkSize?: number;
  maxLineLength?: number;
  returnBytes: boolean;
}
export const useFilePreview = ({
  id,
  body,
  chunkSize = 128000,
  maxLineLength = 120,
  returnBytes,
}: FilePreviewProps) => {
  const {
    data: fileResults,
    status: fileResultsStatus,
    isFetching: isFetchingFileResults,
    refetch: fileResultsRefetch,
  } = usePost<ArrayBuffer | { results: string[] }>(
    `${returnBytes ? `datasets/${id}/file` : `datasets/${id}/file/lines`}`,
    { ...body, ...(returnBytes ? { size: chunkSize } : { maxLineLength: maxLineLength }) },
    {
      enabled: !!id && !!body,
    }
  );
  return { fileResults, fileResultsStatus, isFetchingFileResults, fileResultsRefetch };
};

// Performs a single file download from files of a dataset
export const useSingleFileDownload = () => {
  const [file, setFile] = useState<{ id: number; path: string }>();
  const {
    data: blob,
    isFetching: isPreparingDownload,
    isFetched,
  } = usePost<ArrayBuffer>(`datasets/${file?.id}/file`, { path: file?.path } as requestFileBytes, {
    enabled: !!file,
  });
  useEffect(() => {
    if (blob && isFetched && file) {
      const filename = file.path.split("/").slice(-1)[0] ?? "file";
      const url = URL.createObjectURL(new Blob([blob]));
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = filename;
      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);
      setFile(undefined);
    }
  }, [blob, file, isFetched]);
  const triggerDownload = useCallback((id: number, path: string) => {
    if (id && path) {
      setFile({ id: id, path: path });
    }
  }, []);

  return { triggerDownload, isPreparingDownload };
};

// Returns the file structure of a dataset
export const useFileStructure = (id?: number) => {
  const {
    data: filesStructure,
    status: filesStructureStatus,
    fetchStatus,
    error,
  } = useGet<FilesStructure>(`datasets/${id}/files`, null, {
    enabled: !!id,
    cacheTime: 120000,
  });
  return { filesStructure, filesStructureStatus, fetchStatus, error };
};
