import { useContext, useMemo, useState } from "react";
import { LinkButton } from "../../../../../../common/buttons/LinkButton/LinkButton";
import { toUppercase } from "../../../../../../common/helperfunctions/stringFunctions";
import { LucideIcon } from "../../../../../../common/icon/LucideIcon";
import { EntityAttributes, EntityOptions } from "../entity-types";
import styles from "./EntityWrapper.module.css";
import { SessionContext } from "../../../../../../common/contexts/SessionContext";
import { getDetailLink } from "../../../../../../main/Routing";
import { useCommands, useHelpers } from "@remirror/react";
import { IGenericEntity, INamedEntity } from "../../../../../../api/GenericTypes";
import { ExclusiveDropdown } from "../../../../../../common/buttons/ExclusiveDropdown/ExclusiveDropdown";
import { Link, useHistory } from "react-router-dom";
import { compareAndUpdateAttributes } from "../EntityViews";
import { onClickInEditMode } from "../../../TextEditor";
import { genericEntityConstants } from "../../../../../../api/GenericConstants";

export type GetPosition = () => number | undefined;

export const EntityWrapper = ({
  entity,
  children,
  attrs,
  getPosition,
  updateAttributes,
  additionalSettingsControls,
  options,
}: {
  entity: IGenericEntity & INamedEntity;
  children: JSX.Element | null;
  attrs: EntityAttributes;
  getPosition?: GetPosition;
  updateAttributes: (attrs: EntityAttributes) => void;
  additionalSettingsControls?: JSX.Element | null;
  options?: EntityOptions;
}) => {
  const history = useHistory();
  const { route } = useContext(SessionContext);
  const commands = useCommands();

  const { isViewEditable } = useHelpers();
  const editable = useMemo(() => {
    return isViewEditable();
  }, [isViewEditable]);

  const [showHeader, setShowHeader] = useState<boolean>(attrs.settings?.showHeader ?? true);
  const [collapsible, setCollapsible] = useState<boolean>(attrs.settings?.collapsible ?? true);
  const [isCollapsed, setIsCollapsed] = useState<boolean>(attrs.settings?.defaultCollapsed ?? false);
  const [showSettings, setShowSettings] = useState<boolean>(false);

  const combinedCollapsible = showHeader && collapsible;
  const combinedIsCollapsed = isCollapsed && combinedCollapsible;

  const entityConstants = !!attrs?.entityTypeId
    ? genericEntityConstants[attrs?.entityTypeId].entityConstants
    : undefined;

  const title = useMemo(() => {
    if (combinedIsCollapsed && entity.name) {
      return entity.name;
    } else if (entityConstants?.entitySingular) {
      return toUppercase(entityConstants.entitySingular);
    } else {
      return "N/A";
    }
  }, [combinedIsCollapsed, entity.name, entityConstants?.entitySingular]);

  const controls = useMemo(() => {
    return (
      <div className={styles.entityWrapperToolbarHeaderControls}>
        <ExclusiveDropdown
          show={showSettings}
          setShow={setShowSettings}
          drop="down-right"
          backdropStyle={{ backgroundColor: "transparent", backdropFilter: "none" }}
        >
          <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
            {!!entityConstants?.frontendIndexRoute && !!attrs.entityId && (
              <>
                <LinkButton
                  style={{ textAlign: "left" }}
                  linkTo={route(getDetailLink(entityConstants.frontendIndexRoute, attrs.entityId))}
                  className="btn btn-xs btn-ghost-primary btn-block"
                >
                  <LucideIcon name="external-link" /> Go to {entityConstants.entitySingular}
                </LinkButton>
                <hr style={{ margin: "2px 0", borderColor: "var(--gray-300)" }} />
              </>
            )}
            {/* Settings */}
            <button
              style={{ textAlign: "left", width: "100%" }}
              className="btn btn-xs btn-ghost-secondary btn-block"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setShowHeader((prevState) => {
                  compareAndUpdateAttributes(
                    {
                      ...attrs,
                      settings: { ...attrs.settings, showHeader: !prevState },
                    },
                    attrs,
                    updateAttributes
                  );
                  return !prevState;
                });
              }}
            >
              <LucideIcon name={showHeader ? "eye-off" : "eye"} /> {showHeader ? "Hide" : "Show"} header
            </button>
            <button
              disabled={!showHeader}
              style={{ textAlign: "left", width: "100%" }}
              className="btn btn-xs btn-ghost-secondary btn-block"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setCollapsible((prevState) => {
                  compareAndUpdateAttributes(
                    { ...attrs, settings: { ...attrs.settings, collapsible: !prevState } },
                    attrs,
                    updateAttributes
                  );
                  return !prevState;
                });
              }}
            >
              <LucideIcon name={combinedCollapsible ? "toggle-left" : "toggle-right"} />{" "}
              {combinedCollapsible ? "Not collapsible" : "Collapsible"}
            </button>
            <hr style={{ margin: "2px 0", borderColor: "var(--gray-300)" }} />
            {/* Additional settings */}
            {!!additionalSettingsControls && (
              <>
                {additionalSettingsControls}
                <hr style={{ margin: "2px 0", borderColor: "var(--gray-300)" }} />
              </>
            )}
            <button
              style={{ textAlign: "left" }}
              className="btn btn-xs btn-ghost-danger btn-block"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                commands.deleteEntity();
              }}
            >
              <LucideIcon name="trash" /> Remove reference
            </button>
          </div>
        </ExclusiveDropdown>
      </div>
    );
  }, [
    additionalSettingsControls,
    attrs,
    combinedCollapsible,
    commands,
    entityConstants?.entitySingular,
    entityConstants?.frontendIndexRoute,
    route,
    showHeader,
    showSettings,
    updateAttributes,
  ]);

  return (
    <div className={styles.entityWrapperContainer} onClick={() => getPosition && commands.focus(getPosition())}>
      <div className={styles.entityWrapperToolbarHeader} style={{ opacity: showHeader ? 1 : 0.5 }}>
        {(editable || showHeader) && (
          <div className={styles.entityWrapperToolbarHeaderTitle}>
            <button
              style={{ display: combinedCollapsible ? "block" : "none" }}
              className="btn btn-ghost-secondary btn-xs"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setIsCollapsed((prevState) => {
                  compareAndUpdateAttributes(
                    { ...attrs, settings: { ...attrs.settings, defaultCollapsed: !prevState } },
                    attrs,
                    updateAttributes
                  );
                  return !prevState;
                });
              }}
            >
              {isCollapsed ? <LucideIcon name="chevron-right" /> : <LucideIcon name="chevron-down" />}
            </button>
            {entityConstants?.icon && <LucideIcon name={entityConstants.icon} color="var(--primary)" />}
            {!!entityConstants?.frontendIndexRoute && !!attrs.entityId ? (
              <Link
                className={styles.entityWrapperToolbarHeaderTitleLabel}
                style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", width: "100%" }}
                to={route(getDetailLink(entityConstants.frontendIndexRoute, attrs.entityId))}
                title={editable ? "'CTRL + Click' to open link" : title ?? ""}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  if (!editable || e.ctrlKey) {
                    entityConstants &&
                      attrs.entityId &&
                      history.push(route(getDetailLink(entityConstants.frontendIndexRoute, attrs.entityId)));
                  } else {
                    onClickInEditMode();
                  }
                }}
              >
                {title}
              </Link>
            ) : (
              <span className={styles.entityWrapperToolbarHeaderTitleLabel} title={title ?? ""}>
                {title}
              </span>
            )}
            {combinedIsCollapsed && !!attrs.entityId && !!entityConstants?.entitySingular && (
              <span className={styles.entityWrapperToolbarHeaderId}>
                {toUppercase(entityConstants.entitySingular)}-ID: {attrs.entityId}
              </span>
            )}
          </div>
        )}
        {editable && !showHeader && (
          <div>
            <label className="label label-soft-info" style={{ fontSize: "10px", margin: 0 }}>
              Header hidden in view mode
            </label>
          </div>
        )}
        {!!editable && controls}
      </div>
      {!combinedIsCollapsed && (
        <div className={styles.entityWrapperChildContainer}>
          {children}
          {/* {editable && !showHeader ? <div style={{ margin: "5px", marginRight: 0 }}>{controls}</div> : <div />} */}
        </div>
      )}
    </div>
  );
};
