import { useContext, useMemo, useState } from "react";
import {
  Announcement,
  AnnouncementFilters,
  AnnouncementSortingOptionsConsts,
  announcementsConstants,
} from "../../api/Announcements";
// import { EntityList } from "../../common/lists/EntityList";
import { LucideIcon } from "../../common/icon/LucideIcon";
import { Link } from "react-router-dom";
import { SessionContext } from "../../common/contexts/SessionContext";
import { getAddRoute, getIndexRoute } from "../../main/Routing";
import TableView, { TableViewLabel } from "../../common/panels/TableView/TableView";
import { SearchInput } from "../../common/forms/SearchInput/SearchInput";
import { useEntityCount, useInfiniteListEntity } from "../../api/BaseEntityApi";
import { useDebouncedValue } from "../../common/helperfunctions/useDebouncedValue";
import { ToggleButtonComponent } from "../../ViewerUIElements/ToggleButtonComponent";
import { OrderBySelectDropdown } from "../../common/buttons/OrderBySelectDropdown/OrderBySelectDropdown";
import styles from "./AnnouncementsList.module.css";
import { AnnouncementCard } from "../AnnouncementCard";
import { Alert } from "../../common/overlays/Alert/Alert";
import { useQueryClient } from "@tanstack/react-query";
import { LoadingWrapper } from "../../common/LoadingWrapper";

export const AnnouncementsList = () => {
  const queryClient = useQueryClient();
  const { route, session, api } = useContext(SessionContext);

  const [searchValue, setSearchValue] = useState<string>("");
  const debouncedSearchValue = useDebouncedValue(searchValue, 300);
  const [includeRead, setIncludeRead] = useState<boolean>(false);
  const [filters, setFilters] = useState<AnnouncementFilters>({
    orderBy: "DEFAULT_ASC",
  });

  const activeFilters = useMemo(
    () =>
      ({
        ...filters,
        searchTerm: debouncedSearchValue,
        includeCurrentUserHasRead: includeRead,
        includeCurrentUserStates: true,
        includeSystemAnnouncements: true,
        current: true,
      } as AnnouncementFilters),
    [debouncedSearchValue, filters, includeRead]
  );

  const { data: announcementsCountQuery } = useEntityCount<AnnouncementFilters>(announcementsConstants.resource, {
    ...activeFilters,
    includeCurrentUserStates: false,
    includeCount: true,
  });

  const { data, error, fetchNextPage, hasNextPage, status, fetchStatus } = useInfiniteListEntity<
    Announcement,
    AnnouncementFilters
  >(
    announcementsConstants.resource,
    {
      ...activeFilters,
      page: 1,
      pageSize: 5,
    },
    undefined,
    "post"
  );

  const announcements = useMemo(() => {
    return data?.pages.map((d) => d.results).flat() || [];
  }, [data?.pages]);

  return (
    <TableView>
      <TableView.Head style={{ paddingBottom: "10px", borderBottom: "1px solid var(--gray-300)", gap: "10px" }}>
        <TableView.Head.Label>
          <TableViewLabel entityConstants={announcementsConstants} resultsCount={announcementsCountQuery?.count} />
        </TableView.Head.Label>

        <TableView.Head.Controls>
          <div className="flex align-center gap-5">
            <div style={{ minWidth: "115px" }}>
              <SearchInput searchValue={searchValue} setSearchValue={setSearchValue} />
            </div>

            {(session?.permissions.administer_announcements || session?.permissions.view_admin_pages) && (
              <Link to={route(getIndexRoute("announcements"))}>
                <button className="btn btn-ghost-secondary" title="Show all announcements">
                  <LucideIcon name="table" />
                </button>
              </Link>
            )}

            {session?.permissions.administer_announcements && (
              <Link to={route(getAddRoute("announcements"))}>
                <button className="btn btn-ghost-secondary" title="Create new announcement">
                  <LucideIcon name="plus" />
                </button>
              </Link>
            )}
          </div>
        </TableView.Head.Controls>
      </TableView.Head>

      <TableView.Body>
        <div className={styles.AnnouncementsListBodyContainer}>
          <div className={styles.AnnouncementsListEntiyListControls}>
            <button
              className="btn btn-ghost-secondary btn-xs"
              onClick={() =>
                api
                  .post("announcements/setCurrentUserStates", {
                    isRead: true,
                  })
                  .then(() => {
                    queryClient.invalidateQueries({ queryKey: ["announcements", "list"] });
                    queryClient.invalidateQueries({ queryKey: ["announcements", "suggestions"] });
                    queryClient.invalidateQueries({ queryKey: ["announcements", "ids_only"] });
                  })
                  .catch((e) => console.error(e))
              }
            >
              <LucideIcon name="circle-check-big" /> <span> Mark all as read</span>
            </button>
            |
            <ToggleButtonComponent checked={includeRead} setChecked={setIncludeRead}>
              Include already read
            </ToggleButtonComponent>
            |
            <OrderBySelectDropdown
              btnCls="btn btn-xs btn-ghost-secondary"
              orderByOptions={AnnouncementSortingOptionsConsts}
              currentFilters={filters}
              setCurrentFilters={setFilters}
            />
          </div>

          <div className={styles.AnnouncementsListEntityListContainer}>
            <LoadingWrapper status={status} fetchStatus={fetchStatus} error={error} type="skeleton-rows">
              <div>
                {!announcements.length && (
                  <Alert
                    message={
                      !!debouncedSearchValue
                        ? `No announcements for search term: "${debouncedSearchValue}"`
                        : "No announcements"
                    }
                    type="light"
                    fit
                    centered
                  />
                )}

                {announcements.map((announcement, index) => (
                  <div style={{ paddingRight: "10px", paddingBottom: "25px" }} key={index}>
                    <AnnouncementCard announcement={announcement} />
                  </div>
                ))}
              </div>

              {hasNextPage && (
                <div style={{ width: "100%" }}>
                  <hr style={{ margin: "10px 0" }} />
                  <button
                    className="btn btn-sm btn-soft-primary btn-block flex align-center gap-5"
                    style={{ justifyContent: "center" }}
                    onClick={() => fetchNextPage()}
                    disabled={announcements.length >= 1000}
                  >
                    <LucideIcon name="chevrons-down" />
                    Load more
                  </button>
                </div>
              )}
            </LoadingWrapper>
          </div>
        </div>
      </TableView.Body>
    </TableView>
  );
};
