import produce from "immer";
import { useCallback, useEffect, useState } from "react";
import { SketchPicker } from "react-color";
import { Color, SingleColor } from "../ViewerLayout/ViewerLayoutTypes";
import { ColorNeedsUpdate, initColor, initSingleColor } from "../ViewerLayout/ViewerLayoutUtils";

export function getCSSGradient(values: SingleColor[]): string {
  const r = values.map((v) => `${v.color} ${v.offset}%`).join(", ");
  return `linear-gradient(90deg, ${r})`;
}

export const ColorSelector = ({
  color,
  setColor,
  setSingleColor,
}: {
  color: SingleColor | Color;
  setSingleColor?: (color: SingleColor) => void;
  setColor?: (color: Color) => void;
}) => {
  const [currentColor, setCurrentColor] = useState<SingleColor>(
    initSingleColor((color as SingleColor)?.color ? (color as SingleColor) : (color as Color).colors?.[0] ?? "#3d83c1")
  );

  const updateColor = useCallback(
    (color: any) => {
      const newColor = produce(currentColor, (next) => {
        next.color = color.hex;
      });
      setCurrentColor(newColor);
      if (setColor) setColor(initColor([newColor]));
      if (setSingleColor) setSingleColor(newColor);
    },
    [setColor, setSingleColor, currentColor]
  );

  return (
    <div>
      <SketchPicker
        color={currentColor.color}
        onChangeComplete={updateColor}
        onChange={updateColor}
        disableAlpha={true}
        presetColors={[]}
      />
    </div>
  );
};

export const ColorSelectorOld1 = ({ color, setColor }: { color: Color; setColor?: (color: Color) => void }) => {
  const [currentColor, setCurrentColor] = useState<string>("#3d83c1");
  // const [newSlider, setNewSlider] = useState<number>(-1);
  const [gradientMode, setGradientMode] = useState<boolean>(true);

  const [values, setValues] = useState<SingleColor[]>(
    color.colors.length > 0
      ? color.colors.map((c) => initSingleColor(c))
      : [initSingleColor({ color: "red", offset: 0 }), initSingleColor({ color: "blue", offset: 100 })]
  );
  const [activeSlider] = useState<number>(0);

  useEffect(() => {
    if (values.length === 1) {
      setValues([
        { offset: 0, color: values[0].color },
        { offset: 100, color: values[0].color },
      ]);
      setGradientMode(false);
    }
    // console.log("ColorSelector new", gradientMode ? colorListToColorType(values) : colorListToColorType([values[0]]))
    if (setColor) {
      const newColor = gradientMode ? initColor({ colors: values }) : initColor({ colors: [values[0]] });
      // console.log("ColorSelector new", ColorNeedsUpdate(color, newColor));
      if (ColorNeedsUpdate(color, newColor)) setColor(newColor);
    }
    // }, [values, gradientMode, setValues, setColors]);
  }, [values, gradientMode, setValues, setColor, color]);

  useEffect(() => {
    setCurrentColor(values[activeSlider].color);
  }, [activeSlider, values]);

  // if (values.length === 1) {
  //   const v1 = copyColor(values[0]);
  //   const v2 = copyColor(values[0]);
  //   v1.offset = 0;
  //   v2.offset = 100;
  //   setValues([v1, v2]);
  // }

  // useEffect(() => {
  //   console.log("style", colors);
  // }, [colors]);
  // console.log("style", styles.slider);

  // const colorBox = gradientMode ? (
  //   <div className={styles.sliderBox}>
  //     <div
  //       className={styles.sliderGradient}
  //       style={{
  //         // background: "linear-gradient(90deg, red 0%, blue 100%)",
  //         background: getCSSGradient(values),
  //       }}
  //     ></div>
  //     <ReactSlider
  //       className={styles.slider}
  //       snapDragDisabled={true}
  //       // className="horizontal-slider"
  //       thumbClassName={styles.sliderThumb}
  //       // defaultValue={values.map((state) => state.value)}
  //       value={values.map((state) => state.offset ?? 0)}
  //       minDistance={3}
  //       pearling
  //       onChange={(a: any) => {
  //         const changed: SingleColor[] = values.slice();
  //         setNewSlider(-1);
  //         setValues(
  //           produce(values, (next) => {
  //             values.forEach((v, i) => {
  //               if (v.offset !== a[i]) {
  //                 // console.log("change:", v.value, a[i]);
  //                 setActiveSlider(i);
  //                 next[i].offset = a[i];
  //               }
  //             });
  //           })
  //         );
  //       }}
  //       onAfterChange={(a: any) => {
  //         // console.log("add:", values[0].offset > 0, values[values.length - 1].offset < 100);
  //         if (values[0].offset ?? 0 > 0) {
  //           setValues(
  //             produce(values, (next) => {
  //               const v: any = {};
  //               Object.assign(v, values[0]);
  //               // setNewSlider(1);
  //               setActiveSlider(1);
  //               v.offset = 0;
  //               next.unshift(v);
  //             })
  //           );
  //         }
  //         if (values[values.length - 1].offset ?? 100 < 100) {
  //           setValues(
  //             produce(values, (next) => {
  //               const v: any = {};
  //               Object.assign(v, values[values.length - 1]);
  //               v.offset = 100;
  //               setNewSlider(values.length - 1);
  //               next.push(v);
  //             })
  //           );
  //         }
  //         // setValues(
  //         //   produce(values, (next) => {
  //         //     if (next[0].value > 0) {
  //         //       // next.unshift(next[0])
  //         //       const v: any = {};
  //         //       Object.assign(v, next[0]);
  //         //       v.value = 0;
  //         //       next.unshift(v);
  //         //       console.log("add:", v);
  //         //     }
  //         //     if (next[next.length - 1].value < 100) {
  //         //       // const v: any = {};
  //         //       // Object.assign(v, next[next.length - 1]);
  //         //       // v.value = 100;
  //         //       // next.unshift(v);
  //         //       const v: sliderState = { color: next[next.length - 1].color, value: 100 };
  //         //       next.unshift(v);
  //         //       console.log("add:", next[0]);
  //         //     }
  //         //   })
  //         // );
  //       }}
  //       // trackClassName="example-track"
  //       renderThumb={(props, state) => (
  //         <div
  //           {...props}
  //           onClick={() => {
  //             // console.log("Test", state.index, newSlider);
  //             if (newSlider >= 0) {
  //               setActiveSlider(newSlider);
  //               setNewSlider(-1);
  //             } else setActiveSlider(state.index);
  //             // setActiveSlider(state.index);
  //           }}
  //           onDoubleClick={() => {
  //             if (state.index > 0 && state.index < values.length - 1) {
  //               setValues(
  //                 produce(values, (next) => {
  //                   // const v: any = {};
  //                   // Object.assign(v, values[values.length - 1]);
  //                   // v.value = 100;
  //                   // next.push(v);
  //                   next.splice(state.index, 1);
  //                 })
  //               );
  //             }
  //             // setValues(
  //             //   // produce(values, (next) => {
  //             //   //   next = values
  //             //   //   delete next[state.index];
  //             //   // })
  //             // );
  //           }}
  //         >
  //           {/* <div className={styles.sliderThumb} style={{ backgroundColor: color }}> */}
  //           <div>
  //             <div className={styles.sliderThumb} style={{ backgroundColor: values[state.index].color }}>
  //               {/* {tmp(state)} */}
  //               {/* <span></span> */}
  //               {/* {state.valueNow} */}
  //               {/* <div className={styles.sliderThumbColor} /> */}
  //               {/* <div className={styles.sliderThumbColor} /> */}
  //               {/* {console.log("test", state)} */}
  //             </div>
  //             {state.index === activeSlider ? (
  //               <div className={styles.sliderMarker} style={{ color: values[state.index].color }}>
  //                 &#x25b2;
  //               </div>
  //             ) : undefined}
  //           </div>
  //         </div>
  //       )}
  //     />
  //   </div>
  // ) : (
  //   <div className={styles.singleColorBox}>
  //     <div
  //       className={styles.sliderGradient}
  //       style={{
  //         // background: "linear-gradient(90deg, red 0%, blue 100%)",
  //         background: values[0].color,
  //       }}
  //     ></div>
  //   </div>
  // );

  return (
    <div>
      {/* <ReactResizeDetector handleWidth onResize={(w, h) => setWidth(w)}> */}
      <SketchPicker
        color={currentColor}
        onChangeComplete={(color: any) => {
          // console.log("color", color);
          // rgba(253,187,45,1)

          setValues(
            produce(values, (next) => {
              next[activeSlider].color = color.hex;
            })
          );
        }}
        onChange={(color: any) => {
          // console.log("color", color);
          setCurrentColor(color.hex);
        }}
        disableAlpha={true}
        presetColors={[]}
      />
      {/* <SelectButton active={gradientMode} setActive={setGradientMode} onChange={() => setActiveSlider(0)}>
        gradient mode
      </SelectButton>
      {colorBox} */}
    </div>
  );
};
