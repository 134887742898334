import { ConnectionStatus, useLiveAutoloadConnection } from "../../../common/signalr/useLiveConnection";
import Status from "../../../common/badges/Status/Status";
import { Bridge, bridgeConstants } from "../../../api/Bridges";
import { useEntityDetail } from "../../../api/BaseEntityApi";
import { LoadingWrapper } from "../../../common/LoadingWrapper";
import { NotAvailable } from "../../../common/misc/UIconstants";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { LucideIcon } from "../../../common/icon/LucideIcon";
import { useCallback, useMemo } from "react";
import { DataSource } from "../../../api/DataSource";
import { DataSourceStatus } from "../../../api/DataSourceStatus";
import { useSFTPCheckConnectionById } from "../../../api/autoload/AutoloadClientsApi";
import { Button } from "../../../common/buttons/Button/Button";
import { Alert } from "../../../common/overlays/Alert/Alert";

export const BridgeConnectionStatus = ({
  bridge,
  dataSource,
  hideCheckConnection,
  signalRConnection,
}: {
  bridge: Bridge;
  dataSource?: DataSource;
  hideCheckConnection?: boolean;
  signalRConnection?: ConnectionStatus;
}) => {
  switch (bridge.type) {
    case "Client":
      return <BridgeClientConnectionStatus bridge={bridge} signalRConnection={signalRConnection} />;
    case "SFTP":
      return (
        <BridgeSFTPConnectionStatus
          bridge={bridge}
          dataSourceStatus={dataSource?.status?.lastClientStatus}
          hideCheckConnection={hideCheckConnection}
        />
      );
    default:
      return NotAvailable;
  }
};

interface BridgeConnectionStatusProps {
  bridge: Bridge;
  dataSourceStatus?: DataSourceStatus;
  hideCheckConnection?: boolean;
  signalRConnection?: ConnectionStatus;
}
export const BridgeClientConnectionStatus = ({
  bridge: bridgeInitial,
  signalRConnection: signalRConnectionOverride,
}: BridgeConnectionStatusProps) => {
  const {
    data: bridgeUpdated,
    status,
    fetchStatus,
    refetch,
    error,
  } = useEntityDetail<Bridge>(bridgeConstants.resource, bridgeInitial.id, { enabled: !signalRConnectionOverride });

  const bridge = useMemo(() => bridgeUpdated ?? bridgeInitial, [bridgeInitial, bridgeUpdated]);

  const { connectionStatus } = useLiveAutoloadConnection({
    callbacks: {
      ClientListUpdated: () => {},
      ClientConnectionChanged: (id) => bridge.id === id && refetch(),
      DataSourceScheduled: () => {},
      DataSourceStatusChanged: () => {},
    },
    enabled: !!bridge.id && !signalRConnectionOverride,
  });

  const signalRConnection = useMemo(
    () => signalRConnectionOverride ?? connectionStatus,
    [connectionStatus, signalRConnectionOverride]
  );

  const getInfo = useMemo(() => {
    if (signalRConnection === "disconnected") return "The connection to the LOGS client failed.";
    if (!bridge) return "The requested bridge does not exist.";
    if (!bridge?.isConnected) return "No connection from the remote computer to the server has been established.";
    if (!!bridge?.isConnected)
      return "The LOGS client is running on the remote computer and is connected to the server.";
    // Should not appear
    return NotAvailable;
  }, [bridge, signalRConnection]);

  return (
    <LoadingWrapper status={status} fetchStatus={fetchStatus} error={error}>
      <div className="flex align-center gap-5" style={{ justifyContent: "space-between", width: "100%" }}>
        <div className="flex align-center">
          {signalRConnection === "disconnected" || !bridge ? (
            NotAvailable
          ) : (
            <label className={`label label-soft-${bridge?.isConnected ? "success" : "danger"}`} style={{ margin: 0 }}>
              <Status type={bridge.isConnected ? "success" : "danger"} idle={bridge.isConnected}>
                {bridge.isConnected ? "Connected" : "Connection failed"}
              </Status>
            </label>
          )}
        </div>
        <div>
          <OverlayTrigger placement="bottom" overlay={<Popover id="popover-info">{getInfo}</Popover>}>
            <LucideIcon name="info" color={"var(--gray-400)"} />
          </OverlayTrigger>
        </div>
      </div>
    </LoadingWrapper>
  );
};

export const BridgeSFTPConnectionStatus = ({
  bridge,
  dataSourceStatus,
  hideCheckConnection,
}: BridgeConnectionStatusProps) => {
  const { checkConnection, isIdleCheckingConnection, connectionSuccessful } = useSFTPCheckConnectionById({
    id: bridge.id,
  });

  const getInfo = useCallback(
    ({ bridge, connectionSuccessful }: { bridge?: Bridge; connectionSuccessful?: boolean }) => {
      if (connectionSuccessful === undefined && !hideCheckConnection)
        return "The connection to the remote computer has not been checked.";
      if (connectionSuccessful === undefined && hideCheckConnection)
        return "The connection to the remote computer has not been checked. Visit the detail page to check the connection.";
      if (!bridge) return "The requested bridge does not exist.";
      if (!connectionSuccessful)
        return "The connection check failed, no SFTP connection to the remote computer could be established.";
      if (connectionSuccessful)
        return "The connection check was successful, a SFTP connection to the remote computer was established.";
      // Should not appear
      return NotAvailable;
    },
    [hideCheckConnection]
  );

  const combinedStatus = useMemo(() => {
    if (dataSourceStatus?.runState === "Running") return true;
    return connectionSuccessful?.success;
  }, [connectionSuccessful, dataSourceStatus?.runState]);

  return (
    <div className="flex align-center gap-5" style={{ justifyContent: "space-between", width: "100%" }}>
      <div className="flex align-center gap-5">
        {combinedStatus === undefined || !bridge ? (
          <div className="flex align-center gap-5">
            {!hideCheckConnection && dataSourceStatus?.runState !== "Running" ? (
              <Button
                className="btn btn-default btn-xs flex align-center gap-5"
                loading={isIdleCheckingConnection}
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  checkConnection();
                }}
              >
                <LucideIcon name="wifi" />
                Check connection
              </Button>
            ) : (
              NotAvailable
            )}
          </div>
        ) : (
          <>
            {!combinedStatus && connectionSuccessful?.errors.length ? (
              <OverlayTrigger
                placement="bottom"
                overlay={
                  <Popover id="popover-info" style={{ width: "50vh", minWidth: "400px", overflow: "auto" }}>
                    <div className="flex col-nowrap align-center gap-5">
                      {connectionSuccessful?.errors?.map((error, index) => (
                        <Alert type="danger" fit message={error} style={{ margin: 0 }} key={index} />
                      ))}
                    </div>
                  </Popover>
                }
              >
                <label className={`label label-soft-${combinedStatus ? "success" : "danger"}`} style={{ margin: 0 }}>
                  <Status type={combinedStatus ? "success" : "danger"} idle={combinedStatus}>
                    {combinedStatus ? "Connected" : "Connection failed"}
                  </Status>
                </label>
              </OverlayTrigger>
            ) : (
              <label className={`label label-soft-${combinedStatus ? "success" : "danger"}`} style={{ margin: 0 }}>
                <Status type={combinedStatus ? "success" : "danger"} idle={combinedStatus}>
                  {combinedStatus ? "Connected" : "Connection failed"}
                </Status>
              </label>
            )}
          </>
        )}
      </div>
      <div className="flex align-center gap-5">
        {!hideCheckConnection &&
          dataSourceStatus?.runState !== "Running" &&
          !(combinedStatus === undefined || !bridge) && (
            <Button
              className="btn btn-default btn-xs flex align-center gap-5"
              loading={isIdleCheckingConnection}
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                checkConnection();
              }}
            >
              <LucideIcon name="refresh-ccw" />
              {!!combinedStatus ? "Refresh" : "Try again"}
            </Button>
          )}
        <OverlayTrigger
          placement="bottom"
          overlay={<Popover id="popover-info">{getInfo({ bridge, connectionSuccessful: combinedStatus })}</Popover>}
        >
          <LucideIcon name="info" color={"var(--gray-400)"} />
        </OverlayTrigger>
      </div>
    </div>
  );
};
