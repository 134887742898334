import styles from "./ELN.module.css";
import { NotebooksRouter } from "./ELNRouter/ELNRouter";

export const Notebooks = () => {
  return (
    <div className={styles.wrapperContainer}>
      <NotebooksRouter />
    </div>
  );
};
