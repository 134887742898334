import { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { CustomFieldDataTypeUtils } from "../../api/CustomFields";
import { toUppercase } from "../../common/helperfunctions/stringFunctions";
import { Table } from "../../common/panels/Detail/DetailTable";
import { NotSet } from "../../common/misc/UIconstants";
import { EntityDetailProps, EntityDetailTableProps } from "../../common/entity/EntityInterfaces";
import Status from "../../common/badges/Status/Status";
import { SessionContext } from "../../common/contexts/SessionContext";
import Detail from "../../common/panels/Detail/Detail";
import { DetailsPageTopControlsView } from "../../common/panels/misc/DetailsPageTopControlsView/DetailsPageTopControlsView";
import { LucideIcon } from "../../common/icon/LucideIcon";
import { MoreDropdown } from "../../common/buttons/MoreDropdown/MoreDropdown";
import { AlertModal } from "../../common/modals/AlertModal/AlertModal";
import { showtoast } from "../../common/overlays/Toasts/showtoast";
import { DatasetsTile } from "../../common/sidebar/tiles/DatasetsTile";
import { CustomTypeTile } from "../../common/sidebar/tiles/CustomTypeTile";
import { SamplesTile } from "../../common/sidebar/tiles/SamplesTile";
import { InventoriesTile } from "../../common/sidebar/tiles/InventoriesTile";
// import { RenderCustomFieldPreview } from "./CustomFieldPreviewRenderer";
import { CustomFieldRenderer } from "./CustomFieldRenderer";
import { ProjectsTile } from "../../common/sidebar/tiles/ProjectsTile";
import { PersonsTile } from "../../common/sidebar/tiles/PersonsTile";
import { InstrumentsTile } from "../../common/sidebar/tiles/InstrumentsTile";
import { generateAbstractedFromDataType } from "./CustomFieldUtils";
import { AbstractedCustomFieldDataTypeIcon } from "./CustomFieldRenderUtils";
import { RenderMinimalEntity } from "../../common/misc/EntityRenders/EntityRenderer";
import { customTypeConstants } from "../../api/CustomTypes";
import { ToggleIndicator } from "../../common/badges/ToggleIndicator/ToggleIndicator";

export const CustomFieldsDetailTable = ({
  permissions,
  routes,
  entity,
  nonFoldable = true,
  entityConstants,
  fieldLabels,
}: EntityDetailTableProps<"customFields"> & { nonFoldable?: boolean }) => {
  const abstractedDataType = generateAbstractedFromDataType(entity.dataType);
  return (
    <>
      <Table noPadding nonFoldable={nonFoldable}>
        <Table.Head>Basic details</Table.Head>
        <Table.Body>
          <Table.Body.RowContent
            title={fieldLabels.name}
            content={
              <div className="container_label">
                <div className="container_label_name" style={{ fontWeight: 600 }}>
                  {entity.name ?? NotSet}
                </div>
                <div className="container_label_id">
                  <span style={{ whiteSpace: "nowrap" }}>
                    {toUppercase(entityConstants.entitySingular)}-ID: {entity.id}
                  </span>
                </div>
              </div>
            }
          />
          <Table.Body.RowContent
            title={fieldLabels.dataType}
            content={
              abstractedDataType.abstractedDataType ? (
                <div className="flex row-nowrap align-center gap-5">
                  <AbstractedCustomFieldDataTypeIcon dataType={abstractedDataType.abstractedDataType} />
                  {abstractedDataType.abstractedDataType}
                </div>
              ) : (
                NotSet
              )
            }
          />
          {abstractedDataType.abstractedDataType === "Entity Reference" && (
            <Table.Body.RowContent
              title={"Reference type"}
              content={
                abstractedDataType.entitySelectOption ? (
                  <div className="flex row-nowrap align-center gap-5">
                    <LucideIcon
                      name={
                        CustomFieldDataTypeUtils.MapAbstractedEntityReferenceTypeToEntityConstants(
                          abstractedDataType.entitySelectOption
                        ).icon
                      }
                      color="var(--primary)"
                    />
                    {abstractedDataType.entitySelectOption}
                  </div>
                ) : (
                  NotSet
                )
              }
            />
          )}
          {Array.isArray(entity.customTypeConstraint) && !!entity.customTypeConstraint.length && (
            <Table.Body.RowContent
              title={fieldLabels.customTypeConstraint}
              content={
                <RenderMinimalEntity
                  entities={entity.customTypeConstraint}
                  entityConstants={customTypeConstants}
                  showLinks={true}
                />
              }
            />
          )}

          <Table.Body.RowContent
            title={fieldLabels.description}
            content={
              <>
                {entity.description ? (
                  <div className="container_notes">
                    <textarea rows={4} className="container_textarea" value={entity?.description} disabled />
                  </div>
                ) : (
                  NotSet
                )}
              </>
            }
          />
          <Table.Body.RowContent
            title={fieldLabels.placeholder}
            content={
              <>
                {entity.placeholder ? (
                  <div className="ellipsisContainer">
                    <span>{entity?.placeholder}</span>
                  </div>
                ) : (
                  NotSet
                )}
              </>
            }
          />
          <Table.Body.RowContent
            title={fieldLabels.isMultiValue}
            content={<ToggleIndicator enabled={!!abstractedDataType.isMulti} />}
          />
          {CustomFieldDataTypeUtils.IsNumberDataType(entity.dataType) && (
            <Table.Body.RowContent
              title={fieldLabels.isFloat}
              content={<ToggleIndicator enabled={!!abstractedDataType.isFloat} />}
            />
          )}
          {abstractedDataType.abstractedDataType === "Date" && (
            <Table.Body.RowContent
              title={fieldLabels.isDateTime}
              content={<ToggleIndicator enabled={!!abstractedDataType.isDateTime} />}
            />
          )}
          {abstractedDataType.abstractedDataType === "Date" && abstractedDataType.isDateTimeRange && (
            <Table.Body.RowContent
              title={fieldLabels.isDateTimeRange}
              content={<ToggleIndicator enabled={!!abstractedDataType.isDateTimeRange} />}
            />
          )}
          {abstractedDataType.abstractedDataType === "Time" && abstractedDataType.isTimeRange && (
            <Table.Body.RowContent
              title={fieldLabels.isTimeRange}
              content={<ToggleIndicator enabled={!!abstractedDataType.isTimeRange} />}
            />
          )}

          {entity.dataType === "String" && (
            <Table.Body.RowContent
              title={fieldLabels.showAsTextArea}
              content={
                <span className="badge">
                  <Status type={entity.showAsTextArea ? "success" : "danger"}>
                    {entity.showAsTextArea ? "on" : "off"}
                  </Status>
                </span>
              }
            />
          )}

          <Table.Body.RowContent
            title={fieldLabels.required}
            content={
              <span className="badge">
                <Status type={entity.required ? "success" : "danger"}>{entity.required ? "on" : "off"}</Status>
              </span>
            }
          />
          <Table.Body.RowContent
            title={fieldLabels.readOnly}
            content={
              <span className="badge">
                <Status type={entity.readOnly ? "success" : "danger"}>{entity.readOnly ? "on" : "off"}</Status>
              </span>
            }
          />
          <Table.Body.RowContent title={fieldLabels.validationRegexp} content={entity.validationRegexp || NotSet} />
          <Table.Body.RowContent title={fieldLabels.validationMessage} content={entity.validationMessage || NotSet} />

          {CustomFieldDataTypeUtils.IsCustomizibleRegexDataType(entity.dataType) && (
            <>
              {entity.enumOptionsFromValues ? (
                <Table.Body.RowContent
                  title={fieldLabels.enumOptionsFromValues}
                  content={
                    <span className="badge">
                      <Status type={entity.enumOptionsFromValues ? "success" : "danger"}>
                        {entity.enumOptionsFromValues ? "on" : "off"}
                      </Status>
                    </span>
                  }
                />
              ) : (
                <Table.Body.RowContent
                  title={fieldLabels.enumOptions}
                  content={
                    entity.enumOptions ? (
                      <div className="flex row-nowrap align-center gap-5">
                        {entity.enumOptions.map((e, i) => (
                          <label className={"label label-soft-info"} style={{ margin: 0 }} key={i}>
                            {e}
                          </label>
                        ))}
                      </div>
                    ) : (
                      NotSet
                    )
                  }
                />
              )}
            </>
          )}
          <CustomFieldRenderer
            entity={entity}
            title={fieldLabels.defaultValues}
            customfield={entity}
            value={entity.defaultValues}
            includeCustomFieldId={false}
          />
        </Table.Body>
      </Table>
    </>
  );
};

export const CustomFieldsDetail = ({
  entity,
  entityApi,
  entityConstants,
  fieldLabels,
  permissions,
  routes,
}: EntityDetailProps<"customFields">) => {
  const { session } = useContext(SessionContext);
  let history = useHistory();

  const [showModal, setShowModal] = useState<boolean>(false);

  return (
    <Detail
      head={
        <DetailsPageTopControlsView
          entity={entity}
          entityConstants={entityConstants}
          controls={
            <>
              <button
                className="btn btn-default"
                onClick={() => history.push(routes.getEditLink(entity.id))}
                disabled={!permissions.canEdit(entity)}
              >
                <LucideIcon name="square-pen" />
                <span> Edit</span>
              </button>
              <MoreDropdown drop="right" btn="btn btn-ghost-secondary">
                <li>
                  <button
                    className="btn btn-sm btn-ghost-secondary"
                    title={`Clone this Instrument`}
                    onClick={() => history.push(routes.getCloneLink(entity.id))}
                    disabled={!permissions.canCreate}
                  >
                    <LucideIcon name="copy" /> Clone
                  </button>
                </li>
                <li>
                  <button
                    className={`btn btn-sm btn-ghost-danger`}
                    title={"Delete"}
                    onClick={() => setShowModal(true)}
                    disabled={!permissions.canDelete(entity)}
                  >
                    <LucideIcon name="trash-2" /> Delete
                  </button>
                </li>
              </MoreDropdown>
              <AlertModal
                type={"danger"}
                showModal={showModal}
                setShowModal={setShowModal}
                title={`Permanently delete ${entityConstants.entitySingular}?`}
                description={`This ${entityConstants.entitySingular} may be referenced by other entities. Deleting a ${entityConstants.entitySingular} is only possible if all references are removed.`}
                proceedLabel={"Delete"}
                onProceed={async () => {
                  await entityApi
                    .deleteMutationAsync(
                      {
                        id: entity.id,
                        goBackOnSuccess: false,
                        showToast: false,
                        entityName: entityConstants.entitySingular,
                        params: { deletePermanently: true },
                      },

                      {
                        onSuccess: () => {
                          showtoast(
                            "success",
                            `Deleted ${toUppercase(entityConstants.entitySingular)}-ID: ${entity.id}.`
                          );
                          history.goBack();
                        },
                      }
                    )
                    .catch((e) => {});
                }}
                loading={entityApi.isLoadingDeleteMutation}
              />
            </>
          }
        />
      }
      mainStyle={{ padding: "0px 10px" }}
      main={
        <>
          <CustomFieldsDetailTable
            entity={entity}
            entityConstants={entityConstants}
            fieldLabels={fieldLabels}
            permissions={permissions}
            routes={routes}
          />
          {/* <RenderCustomFieldPreview initialValues={customField} dataType={customField.dataType} /> */}
        </>
      }
      sidebar={
        <>
          {/* TODO */}
          {!!entity.relations?.types.count && (
            <CustomTypeTile
              title="Used in custom types"
              defaultFilters={{ customFieldIds: [entity.id] }}
              excludeFilters={{ customFieldIds: () => false }}
            />
          )}
          {!!entity.relations?.samples.count && (
            <SamplesTile
              title="Used in samples"
              defaultFilters={{ customFieldIds: [entity.id] }}
              excludeFilters={{ customFieldIds: () => false }}
            />
          )}
          {!!entity.relations?.datasets.count && (
            <DatasetsTile
              title="Used in datasets"
              defaultFilters={{ customFieldIds: [entity.id] }}
              excludeFilters={{ customFieldIds: () => false }}
            />
          )}
          {!!entity.relations?.inventories.count && (
            <InventoriesTile
              title="Used in inventory items"
              defaultFilters={{ customFieldIds: [entity.id] }}
              excludeFilters={{ customFieldIds: () => false }}
            />
          )}
          {session?.features.enable_project_custom_types && !!entity.relations?.projects.count && (
            <ProjectsTile
              title="Used in projects"
              defaultFilters={{ customFieldIds: [entity.id] }}
              excludeFilters={{ customFieldIds: () => false }}
            />
          )}
          {session?.features.enable_person_custom_types && !!entity.relations?.persons.count && (
            <PersonsTile
              title="Used in persons"
              defaultFilters={{ customFieldIds: [entity.id] }}
              excludeFilters={{ customFieldIds: () => false }}
            />
          )}
          {session?.features.enable_facility_custom_types && !!entity.relations?.facilities.count && (
            <InstrumentsTile
              title="Used in instruments"
              defaultFilters={{ customFieldIds: [entity.id] }}
              excludeFilters={{ customFieldIds: () => false }}
            />
          )}
          {!entity.relations?.types.count && <CustomTypeTile title="Used in custom Types" disabled />}
          {!entity.relations?.samples.count && <SamplesTile title="Used in samples" disabled />}
          {!entity.relations?.datasets.count && <DatasetsTile title="Used in datasets" disabled />}
          {!entity.relations?.inventories.count && <InventoriesTile title="Used in inventory items" disabled />}
          {session?.features.enable_project_custom_types && !entity.relations?.projects.count && (
            <ProjectsTile title="Used in projects" disabled />
          )}
          {session?.features.enable_person_custom_types && !entity.relations?.persons.count && (
            <PersonsTile title="Used in persons" disabled />
          )}
          {session?.features.enable_facility_custom_types && !entity.relations?.facilities.count && (
            <InstrumentsTile title="Used in instruments" disabled />
          )}
        </>
      }
    />
  );
};
