import React from "react";
import { Color } from "../ViewerLayout/ViewerLayoutTypes";
import { ColorBox } from "./ColorBox";

export const ColorRectList = React.memo(({ colors }: { colors: Color[] | undefined | null }): any => {
  if (colors === undefined || colors === null) return null;

  return colors.map((c, i) => (
    <span key={i}>
      &nbsp;
      <ColorBox color={c} allowColorChange={false} />
    </span>
  ));
  // return colors.map((c, i) => <span key={i} className="glyphicon glyphicon-stop" style={{ color: c }} />);
});

export const ColorRectListOld = React.memo(({ colors }: { colors: string[] | undefined | null }): any => {
  if (colors === undefined || colors === null) return null;

  return colors.map((c, i) => <span key={i} className="glyphicon glyphicon-stop" style={{ color: c }} />);
});
