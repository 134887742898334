import React, { useEffect, useState } from "react";
import { useInputDebounce } from "../../../ViewerUIElements/InputDebounce";
import { DecoratedBooleanInput } from "../../../ViewerUIElements/Range/DecoratedBooleanInput";

export const BooleanInput = ({
  parameterName,
  value,
  setParameter,
}: {
  parameterName: string;
  value: boolean;
  setParameter?: (parameterName: string, parameters: Record<string, any>) => void;
}) => {
  const [userValue, setUserValue] = useState<boolean>(value);
  const debouncedInput = useInputDebounce<boolean>(userValue, 20);

  useEffect(() => {
    if (setParameter) setParameter(parameterName, { value: userValue });
  }, [debouncedInput]);

  useEffect(() => {
    // console.log("boolean", value);
    setUserValue(value);
  }, [value]);

  // return <DecoratedBooleanInput value={userValue} setValue={setUserValue} />;
  return <DecoratedBooleanInput value={userValue} setValue={setUserValue} />;
};
