import React from "react";
import { GroupProvider } from "./GroupProvider";
import { SessionProvider } from "./SessionProvider";
import { LocalLoginForm } from "./LocalLoginForm";
import { OIDCLoginForm } from "./OIDCLoginForm";
import { AuthenticationConfigProvider } from "./AuthenticationConfigProvider";

/**
 * The `Login` component is responsible for rendering the login form based on the authentication configuration.
 * It uses several context providers to manage the authentication state and configuration.
 * @author @CorradoSurmanowicz
 * @component
 * @example
 * // Usage example:
 * <Login />
 *
 * @returns {JSX.Element} The rendered login form component.
 *
 * @remarks
 * This component uses the following context providers:
 * - `GroupProvider`: Provides the group context.
 * - `AuthenticationConfigProvider`: Provides the authentication configuration based on the group.
 * - `SessionProvider`: Manages the session state and provides the login option and session token setter.
 *
 * Depending on the `loginOption` value, it renders either the `OIDCLoginForm` or the `LocalLoginForm` component.
 *
 * @see {@link OIDCLoginForm}
 * @see {@link LocalLoginForm}
 */
export const Login = () => {
  return (
    <GroupProvider>
      {({ group }) => (
        <AuthenticationConfigProvider group={group}>
          <SessionProvider group={group}>
            {({ loginOption, group, setSessionToken }) => {
              if (loginOption === "oidc") {
                return <OIDCLoginForm group={group} setSessionToken={setSessionToken} loginOption={loginOption} />;
              } else if (loginOption === "local") {
                return <LocalLoginForm group={group} setSessionToken={setSessionToken} loginOption={loginOption} />;
              }
            }}
          </SessionProvider>
        </AuthenticationConfigProvider>
      )}
    </GroupProvider>
  );
};
