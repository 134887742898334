import produce from "immer";
import { useEffect, useState } from "react";

import { SelectButton, selectState as _selectState } from "./SelectButton";

import styles from "./CheckBoxSelector.module.css";
import { needUpdate } from "../ViewerLayout/ViewerLayoutUtils";

type VerticalSelectorItem = { key: string; text: string };
export type SelectorStateType = Record<string, selectState>;

export type SelectorElement = {
  title?: string;
  multiselect: boolean;
  items: VerticalSelectorItem[];
  state?: SelectorStateType;
  preselect?: SelectorStateType;
  defaultValue?: string;
  minSelectCount?: number;
  setState?: (state: SelectorStateType) => void;
  onClick?: (state: SelectorStateType) => void;
};

export type selectState = _selectState;

export const Selector = ({
  title,
  multiselect,
  items,
  state,
  preselect,
  defaultValue,
  setState,
  onClick,
  orientation,
  minSelectCount,
}: SelectorElement & { orientation?: "vertical" | "horizontal" }) => {
  // const { title, items, onClick, ...restProps } = props;
  // const { title, items, onClick, ...restProps } = props;
  // const { onClick, state, setState } = props;

  const [choice, setChoice] = useState<any[]>();

  const init: string = defaultValue ?? items[0].key;
  // const preselect = props?.preselect ?? true;

  multiselect = multiselect ?? false;

  type stateType = SelectorStateType;
  const initState: stateType = {};
  items.forEach((item: any) => {
    initState[item.key] = false;
  });

  if (preselect) {
    // const preselect = preselect as SelectorStateType;
    Object.keys(preselect).forEach((k: string) => {
      if (k in initState) initState[k] = preselect[k];
    });
  } else {
    initState[init] = true;
  }
  const [active, setActive] = useState<SelectorStateType>(initState);

  useEffect(() => {
    // console.log("parent", state);
    // console.log("child", state, "->", active);
    if (state !== undefined) {
      // const keys = items.map((item: any) => item.key);
      const active: SelectorStateType = {};
      if (items.some((item: any) => active[item.key] !== (state[item.key] || false))) {
        items.forEach((item: any) => (active[item.key] = state[item.key] || false));
        setActive(active);
      }
    }
  }, [state, items]);

  // useEffect(() => {
  //   if (setState)
  //     setState(
  //       produce(state, (next: SelectorStateType) => {
  //         // Object.entries(newState).forEach(([k, v]) => console.log("set", k, state[k], "->", v));
  //         Object.entries(active).forEach(([k, v]) => (next[k] = v));
  //       })
  //     );
  // }, [active]);

  useEffect(() => {
    // const keys = props.items.map((item: any) => item.key);
    // console.log("next", keys);
    setChoice(
      items.map((item: any, i: number) => {
        return (
          <div key={item.key} style={{ paddingLeft: orientation === "horizontal" ? 5 : 0 }}>
            <SelectButton
              id={item.key}
              active={active[item.key]}
              key={item.key}
              onClick={(e: any) => {
                const newState = produce(active, (next: SelectorStateType) => {
                  if (!multiselect || Object.values(active).some((v) => v === "uncertain"))
                    Object.keys(active).forEach((v) => (next[v] = false));
                  if (minSelectCount && active[e.id]) {
                    // console.log("min", minSelectCount, Object.values(active).filter((v) => v).length);
                    if (Object.values(active).filter((v) => v).length <= minSelectCount) {
                      const id = Object.keys(active).filter((k) => k !== e.id)[0];
                      next[id] = true;
                    }
                  }
                  next[e.id] = !active[e.id];
                });
                // console.log("new state", newState, needUpdate(active, newState));

                if (needUpdate(active, newState)) setActive(newState);
                if (onClick) onClick(newState);
                if (setState)
                  setState(
                    produce(state || {}, (next: SelectorStateType) => {
                      Object.entries(newState).forEach(([k, v]) => (next[k] = v));
                    })
                  );
              }}
            >
              {item.text}
            </SelectButton>
          </div>
        );
      })
    );
  }, [items, minSelectCount, active, multiselect, onClick, state, orientation, setState]);

  return (
    <div
      className={orientation === "horizontal" ? styles.viewerButtonRow : styles.viewerButtonColumn}
      // style={{
      //   flexFlow: orientation === "horizontal" ? "row wrap" : "column wrap",
      //   marginTop: orientation === "horizontal" ? 0 : 5,
      //   marginBottom: orientation === "horizontal" ? 0 : 5,
      // }}
    >
      {title === undefined ? null : title === "" ? <span>&nbsp;</span> : title}
      {choice}
    </div>
  );
};

export const CheckBoxSelectorDiv = function ({
  elements,
  orientation,
}: {
  orientation?: "vertical" | "horizontal";
  elements: SelectorElement[];
}) {
  // console.log("elements", elements);
  const elementList = elements.map((props: SelectorElement, i: number) => {
    return <Selector key={i} {...props} orientation={orientation} />;
  });

  return (
    // <div className={orientation === "horizontal" ? styles.viewerButtonColumn : styles.viewerButtonRow}>
    <div
      className={styles.viewerButtonRow}
      style={{ flexFlow: orientation === "horizontal" ? "column wrap" : "row wrap" }}
    >
      {/* {props.children} */}
      {elementList}
    </div>
  );
};
