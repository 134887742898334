import { SelectFormField } from "../../../common/formfields/SelectFormField";
import { LabNotebook, LabNotebookFilters, LabNotebookStatus, LabNotebookStatusList } from "../../types/LabNotebook";
import { DefinedPropertyObject, IEntityMinimalModel } from "../../../api/GenericTypes";
import { ToggleFormField } from "../../../common/formfields/ToggleFormField";
import { SplitDatePickerFormField } from "../../../common/formfields/DatePickerFormField/SplitDatePickerFormField";
import { date2localDate } from "../../../common/datetime/DateTimeFormatter";
import { FilterSidebarProps, useFilterSidebar } from "../../../common/sidebar/filtersidebar/FilterSidebar";
import { ProjectsVirtualizedSelectForm } from "../../../common/forms/EntityForms/formsVirtualized/ProjectsVirtualizedSelectForm";
import { PersonsVirtualizedSelectForm } from "../../../common/forms/EntityForms/formsVirtualized/PersonsVirtualizedSelectForm";
import { EntityFilterSideBar } from "../../../common/sidebar/filtersidebar/EntityFilterBar";

interface LabNotebookStatusObject {
  id: LabNotebookStatus;
  name: LabNotebookStatus;
}
export interface LabNotebookFilterForm {
  status?: LabNotebookStatusObject[] | undefined | null;
  projectIds?: IEntityMinimalModel[] | undefined | null;
  createdByIds?: IEntityMinimalModel[] | undefined | null;
  createdFrom?: Date | undefined | null;
  createdTo?: Date | undefined | null;
  modifiedByIds?: IEntityMinimalModel[] | undefined | null;
  modifiedFrom?: Date | undefined | null;
  modifiedTo?: Date | undefined | null;
  includeSoftDeletedLabNotebooks?: boolean | undefined | null;
  isSoftDeletedLabNotebook?: boolean | undefined | null;
}
const defaultValues: DefinedPropertyObject<LabNotebookFilterForm> = {
  status: null,
  projectIds: null,
  createdByIds: null,
  createdFrom: null,
  createdTo: null,
  modifiedByIds: null,
  modifiedFrom: null,
  modifiedTo: null,
  includeSoftDeletedLabNotebooks: false,
  isSoftDeletedLabNotebook: false,
};
const formToFilters = (form: LabNotebookFilterForm): LabNotebookFilters => {
  let filters: LabNotebookFilters = {};

  filters.status = Array.isArray(form.status) && form.status.length > 0 ? form.status?.map((d) => d.id) : null;
  filters.projectIds =
    Array.isArray(form.projectIds) && form.projectIds.length > 0 ? form.projectIds?.map((d) => d.id) : null;
  filters.createdByIds =
    Array.isArray(form.createdByIds) && form.createdByIds.length > 0 ? form.createdByIds?.map((d) => d.id) : null;
  filters.createdFrom = form.createdFrom ? date2localDate(form.createdFrom).toISOString() : null;
  filters.createdTo = form.createdTo ? date2localDate(form.createdTo).toISOString() : null;
  filters.modifiedByIds =
    Array.isArray(form.modifiedByIds) && form.modifiedByIds.length > 0 ? form.modifiedByIds?.map((d) => d.id) : null;
  filters.modifiedFrom = form.modifiedFrom ? date2localDate(form.modifiedFrom).toISOString() : null;
  filters.modifiedTo = form.modifiedTo ? date2localDate(form.modifiedTo).toISOString() : null;
  filters.includeSoftDeleted = form.includeSoftDeletedLabNotebooks || false;
  filters.isSoftDeleted = form.isSoftDeletedLabNotebook || false;

  return filters;
};
const defaultFilters: LabNotebookFilters = { orderBy: "MODIFIED_ON_DESC" };

export const LabNotebookFilterBar = ({
  initialValues,
  setFilters,
  onFormChange,
  dispatchTabStore,
  tabsLoading,
  currentTab,
}: FilterSidebarProps<LabNotebook, LabNotebookFilterForm, LabNotebookFilters>) => {
  const { register, control, onReset } = useFilterSidebar({
    initialValues,
    defaultValues,
    defaultFilters,
    formToFilters,
    onFormChange,
    dispatchTabStore,
    setFilters,
  });

  return (
    <EntityFilterSideBar onReset={onReset}>
      <SelectFormField
        id="status"
        label="Notebook status"
        {...register}
        items={LabNotebookStatusList.map((e) => {
          return { id: e, name: e };
        })}
        control={control}
        horizontal={false}
        isMulti={true}
      />
      <ProjectsVirtualizedSelectForm id="projectIds" control={control} isMulti={true} />

      {currentTab !== "mydata" && (
        <PersonsVirtualizedSelectForm id="createdByIds" label="Created by" control={control} isMulti />
      )}
      <SplitDatePickerFormField
        control={control}
        idStart="createdFrom"
        idEnd="createdTo"
        label="Creation date (from - to)"
        horizontal={false}
        showTimeSelect
      />
      <PersonsVirtualizedSelectForm id="modifiedByIds" label="Modified by" control={control} isMulti />

      <SplitDatePickerFormField
        control={control}
        idStart="modifiedFrom"
        idEnd="modifiedTo"
        label="Modification date (from - to)"
        horizontal={false}
        showTimeSelect
      />

      {currentTab !== "trash" && (
        <ToggleFormField id={"includeSoftDeletedLabNotebooks"} control={control} label={"Include trashed"} horizontal />
      )}
      {currentTab !== "trash" && (
        <ToggleFormField id={"isSoftDeletedLabNotebook"} control={control} label={"Is trashed"} horizontal />
      )}
    </EntityFilterSideBar>
  );
};
