import produce from "immer";
import { useCallback, useEffect, useState } from "react";
import { FormControl, InputGroup } from "react-bootstrap";

import { LucideIcon } from "../common/icon/LucideIcon";
import { useTranslatedFieldUpdate } from "../ViewerLayout/ViewerLayoutHooks";
import { ParameterList, Track } from "../ViewerLayout/ViewerLayoutTypes";
import { CheckBoxSelectorDiv, SelectorStateType } from "../ViewerUIElements/CheckBoxSelector";
import { Resizeable } from "../ViewerUIElements/Resizeable";
import styles from "./ParameterComponent.module.css";
import { TreeViewer } from "./TreeViewer/TreeViewer";
import { SearchRequestType } from "./TreeViewer/TreeViewerTypes";

// import { sequence } from "./example";
const ParameterDiv = (props: any) => {
  const {
    width,
    height,
    children,
  }: {
    width: number;
    height: number;
    children: any;
  } = props;

  return (
    <div className={styles.resize} style={{ width: width, height: height - 5 }}>
      {children}
    </div>
  );
};

const escapeRegExp = (s: string) => {
  return s.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
};

export const ParameterComponent = ({
  tracks,
  parameter,
}: {
  tracks: Record<string, Track>;
  parameter: ParameterList;
}) => {
  // const targetRef = useRef<HTMLDivElement>(null);

  // const [parameter, setParameter] = useState<parameterType[]>();
  // const [currentTrack, setCurrentTrack] = useState(0);

  // const maxTrackNumber = 6;
  // const [trackList, setTrackList] = useState<TrackType[]>([]);
  // const [trackVisibility, setTrackVisibility] = useState<Record<string, boolean>>({});
  const [inSearch, setInSearch] = useState<boolean>(false);
  const [searchFocused, setSearchFocused] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>("");
  const [searchMode, setSearchMode] = useState<{ key: boolean; value: boolean; header: boolean }>({
    key: true,
    value: true,
    header: false,
  });
  const trackVisibility = useTranslatedFieldUpdate<Record<string, Track>, Record<string, boolean>>(
    tracks,
    (tracks) => {
      return Object.fromEntries(
        Object.values(tracks).map((track) => [track.id, track.settings.visible && track.settings.active])
      );
    },
    {}
  );

  const [searchRequest, setSearchRequest] = useState<SearchRequestType>({});

  const keyPress = useCallback((event: any) => {
    if (event.key === "Escape") {
      setInSearch(false);
      setSearchFocused(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", keyPress, false);

    return () => {
      document.removeEventListener("keydown", keyPress, false);
    };
  }, [keyPress]);

  useEffect(() => {
    setSearchRequest(
      produce(searchRequest, (next) => {
        if (inSearch || searchFocused) {
          // next.keyText = searchMode.key && searchText !== "" ? searchText : undefined;
          // next.valueText = searchMode.value && searchText !== "" ? searchText : undefined;
          // next.headerText = searchMode.header && searchText !== "" ? searchText : undefined;
          next.keyText = searchMode.key && searchText !== "" ? escapeRegExp(searchText) : undefined;
          next.valueText = searchMode.value && searchText !== "" ? escapeRegExp(searchText) : undefined;
          next.headerText = searchMode.header && searchText !== "" ? escapeRegExp(searchText) : undefined;
        } else {
          next.keyText = undefined;
          next.valueText = undefined;
          next.headerText = undefined;
        }
      })
    );
    // console.log(">", searchRequest);
  }, [searchMode, searchFocused, inSearch, searchText]);

  // useEffect(() => {
  //   console.log("searchMode", searchMode);
  //   // if (!Object.values(searchMode).some((mode) => mode))
  //   //   setSearchMode(
  //   //     produce(searchMode, (next) => {
  //   //       next.key = true;
  //   //     })
  //   //   );
  // }, [searchMode]);
  // console.log(">", parameter);

  const selector = [
    {
      multiselect: true,
      items: [
        { key: "key", text: "in keys" },
        { key: "value", text: "in values" },
        { key: "header", text: "in headers" },
      ],
      state: searchMode as SelectorStateType,
      minSelectCount: 1,
      setState: setSearchMode as (state: SelectorStateType) => void,
      // onClick: (state: Record<string, boolean>) => console.log("state:", state),
    },
  ];

  // const setActive = useCallback(
  //   (active: boolean) =>
  //     setSearchMode(
  //       produce(searchMode, (next) => {
  //         next.key = active;
  //       })
  //     ),
  //   []
  // );

  return (
    <div className={styles.main}>
      <div className={styles.search}>
        {/* <div className={styles.debugButtons}>
          {parserLogs ? <ParserLogsIcon logs={parserLogs} size="200%" /> : null}
        </div> */}
        <div className={styles.searchIcon} style={{ display: inSearch || searchFocused ? "none" : undefined }}>
          {/* <Button bsSize="xs" style={{ marginBottom: "5px" }} >
            <span className="glyphicon glyphicon-filter" /> Filter
          </Button> */}
          <span onClick={() => setInSearch(true)}>
            <LucideIcon name="filter" style={{ height: 13, strokeWidth: 3 }} />
            Filter
          </span>
        </div>
        <div style={{ display: inSearch || searchFocused ? undefined : "none" }}>
          <InputGroup bsSize={"sm"} className={styles.searchInputGroup}>
            <FormControl
              type="text"
              // placeholder="Search"
              placeholder="Filter"
              value={searchText}
              onChange={useCallback((e) => setSearchText((e.target as HTMLInputElement).value), [])}
              onFocus={useCallback(() => setSearchFocused(true), [])}
              onBlur={useCallback(() => setSearchFocused(false || searchText !== ""), [])}
            />
            <InputGroup.Addon>
              {/* <span
                className="glyphicon glyphicon-remove"
                onClick={useCallback(() => {
                  setInSearch(false);
                  setSearchFocused(false);
                }, [])}
              /> */}
              <LucideIcon
                name="x"
                onClick={useCallback(() => {
                  setInSearch(false);
                  setSearchFocused(false);
                }, [])}
                style={{ strokeWidth: 3 }}
              />
            </InputGroup.Addon>
          </InputGroup>

          <CheckBoxSelectorDiv elements={selector} orientation="horizontal" />

          {/* <div className={styles.searchSelect}>
            <SelectButton active={searchMode.key} setActive={setActive}>
              in keys
            </SelectButton>
            &nbsp;
            <SelectButton active={searchMode.value} setActive={setActive}>
              in values
            </SelectButton>
            &nbsp;
            <SelectButton active={searchMode.header} setActive={setActive}>
              in headers
            </SelectButton>
          </div> */}
        </div>
      </div>
      <Resizeable>
        <ParameterDiv>
          <TreeViewer trackVisibility={trackVisibility} search={searchRequest}>
            {parameter}
          </TreeViewer>
        </ParameterDiv>
      </Resizeable>
    </div>
  );
};
