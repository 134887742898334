import React, { useCallback, useMemo } from "react";
import { LucideIcon } from "../icon/LucideIcon";
import styles from "./StepwiseWizard.module.css";
import { useHistory } from "react-router-dom";

/**
 * This a WIP generic component to create a stepwise Wizard.
 * Author: CS
 * @param: title, steps, children, onFinish
 * @returns: JSX.Element
 */

interface WizardStep {
  label: string;
  validatefn: () => boolean;
  nextOnValidFn?: boolean;
  hideNextButton?: boolean;
  hidePrevButton?: boolean;
  params?: { [key: string]: any };
  component: (props: StepwiseWizardComponentProps) => React.ReactNode;
}

interface Props {
  title: string;
  steps: WizardStep[];
}

export interface StepwiseWizardComponentProps extends Props {
  prevHandle: (urlParams?: { [key: string]: string }) => void;
  nextHandle: (urlParams?: { [key: string]: string }) => void;
}

export const StepwiseWizard = ({ title, steps }: Props) => {
  const history = useHistory();
  const step = useMemo(() => new URLSearchParams(history.location.search).get("step"), [history.location.search]);
  const currentStep = useMemo(() => Number(step) || 1, [step]);
  const currentIndex = currentStep - 1;
  const nextHandle = useCallback(
    (urlParams?: { [key: string]: string }) => {
      var allQueryParams: { [key: string]: string } = { ...urlParams };
      new URLSearchParams(history.location.search).forEach((value, key) => (allQueryParams[key] = value));
      var stepURL = allQueryParams["step"] ? Number(allQueryParams["step"]) : 1;
      allQueryParams["step"] = (stepURL + 1 <= steps.length ? stepURL + 1 : stepURL).toString();
      history.push({ pathname: history.location.pathname, search: new URLSearchParams(allQueryParams).toString() });
    },
    [history, steps.length]
  );
  const prevHandle = useCallback(() => {
    history.goBack();
  }, [history]);

  return (
    <div className={styles.container}>
      <div className={styles.container_wrapper}>
        <div className={styles.container_title}>
          <h1 style={{ margin: 0 }}>{title}</h1>
        </div>
        <div className={styles.container_steps}>
          {steps.map((step, index) => (
            <div className={styles.container_step} key={index}>
              <div
                className={`${styles.container_step_index} ${
                  index <= currentIndex && styles.container_step_index_current
                }`}
              >
                <span>{index + 1}</span>
              </div>
              <div
                className={`${styles.container_step_label} ${
                  index <= currentIndex && styles.container_step_label_current
                }`}
              >
                {step.label}
              </div>
              <div
                className={`${styles.container_step_line} ${
                  currentIndex >= index && styles.container_step_line_current
                } ${index < currentIndex && styles.container_step_line_past} ${
                  currentStep === steps.length && steps[currentIndex]?.validatefn() && styles.container_step_line_past
                }`}
              ></div>
            </div>
          ))}
        </div>
        <div className={styles.container_body}>
          <div className={styles.container_body_child}>
            <div>{steps[currentIndex]?.component({ title, steps, prevHandle, nextHandle })}</div>
          </div>

          <div className={styles.container_body_controls}>
            {!(steps[currentIndex]?.hidePrevButton ?? false) && currentStep > 1 && (
              <button className="btn btn-default" type="button" onClick={() => prevHandle()}>
                Previous
              </button>
            )}
            {!(steps[currentIndex]?.hideNextButton ?? false) && (
              <button
                className="btn btn-primary"
                type="button"
                onClick={() => nextHandle()}
                disabled={!steps[currentIndex]?.validatefn()}
              >
                {currentStep === steps.length ? (
                  "Finish"
                ) : (
                  <>
                    Next <LucideIcon name="chevron-right" />
                  </>
                )}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
