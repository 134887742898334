import { LucideIcon, IconProps, IconNames } from "../../common/icon/LucideIcon";
import { AbstractedCustomFieldDataTypes } from "./CustomFieldUtils";
import styles from "./CustomFieldRenderUtils.module.css";

export const abstractedDataTypeToIconName = (dataType: AbstractedCustomFieldDataTypes): IconNames => {
  switch (dataType) {
    case "Text":
      return "text-cursor";
    case "Number":
      return "binary";
    case "Toggle":
      return "toggle-right";
    case "Date":
      return "calendar-days";
    case "Time":
      return "clock";
    case "Entity Reference":
      return "link";
    case "Entity Barcode":
      return "qr-code";
    case "Attachment":
      return "image-up";
    case "URL":
      return "link-2";
  }
};

interface AbstractedCustomFieldDataTypeIconProps extends Omit<IconProps, "name"> {
  dataType: AbstractedCustomFieldDataTypes;
}
export const AbstractedCustomFieldDataTypeIcon = ({ dataType, ...props }: AbstractedCustomFieldDataTypeIconProps) => {
  return <LucideIcon {...props} name={abstractedDataTypeToIconName(dataType)} />;
};

interface RenderCustomFieldDraggableProps {
  dataType: AbstractedCustomFieldDataTypes;
}
export const RenderCustomFieldDraggable = ({ dataType }: RenderCustomFieldDraggableProps) => {
  return (
    <div className={styles.customFieldDraggable}>
      <div>
        <AbstractedCustomFieldDataTypeIcon
          dataType={dataType}
          style={{ width: "30px", height: "30px" }}
          color={"var(--gray-400)"}
        />
      </div>
      <span>{dataType}</span>
    </div>
  );
};
