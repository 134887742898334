import { StringIndexedDict } from "../../../../api/GenericTypes";
import {
  GenericEntityVirtualizedEntitySelectFormFieldProps,
  GenericVirtualizedEntitySelectFormFieldControllerWrapper,
} from "../../../formfields/GenericVirtualizedSelectFormField/GenericVirtualizedEntitySelectFormFieldControllerWrapper";
import {
  VendorFilters,
  VendorSortingOptionsConsts,
  VendorSuggestions,
  vendorsConstants,
} from "../../../../api/Vendors";

export const VendorsVirtualizedSelectForm = <Entity extends StringIndexedDict>(
  props: GenericEntityVirtualizedEntitySelectFormFieldProps<Entity, VendorSuggestions, VendorFilters>
) => {
  return (
    <GenericVirtualizedEntitySelectFormFieldControllerWrapper<Entity, VendorSuggestions, VendorFilters>
      {...props}
      filters={{ orderBy: "NAME_ASC", ...props.filters }}
      //   onRowRenderer={(item) => <></>}
      entityConstants={vendorsConstants}
      orderByOptions={VendorSortingOptionsConsts}
      allowCreateEntity={false}
    />
  );
};
