import React, { MouseEventHandler } from "react";
import styles from "./styles.module.css";

interface Props {
  icon: JSX.Element;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  title?: string;
}
export const ControlBtn = ({ icon, onClick, disabled, title }: Props) => {
  return (
    <div className={styles.container}>
      <button onClick={onClick} disabled={disabled ?? false} title={title}>
        {icon}
      </button>
    </div>
  );
};
