export class CodonTranslator {
  static codon: Record<string, string> = {
    TTT: "Phe",
    TTC: "Phe",
    TTA: "Leu",
    TTG: "Leu",
    TCT: "Ser",
    TCC: "Ser",
    TCA: "Ser",
    TCG: "Ser",
    TAT: "Tyr",
    TAC: "Tyr",
    TAA: "STOP",
    TAG: "STOP",
    TGT: "Cys",
    TGC: "Cys",
    TGA: "STOP",
    TGG: "Trp",
    CTT: "Leu",
    CTC: "Leu",
    CTA: "Leu",
    CTG: "Leu",
    CCT: "Pro",
    CCC: "Pro",
    CCA: "Pro",
    CCG: "Pro",
    CAT: "His",
    CAC: "His",
    CAA: "Gln",
    CAG: "Gln",
    CGT: "Arg",
    CGC: "Arg",
    CGA: "Arg",
    CGG: "Arg",
    ATT: "Ile",
    ATC: "Ile",
    ATA: "Ile",
    ATG: "Met",
    ACT: "Thr",
    ACC: "Thr",
    ACA: "Thr",
    ACG: "Thr",
    AAT: "Asn",
    AAC: "Asn",
    AAA: "Lys",
    AAG: "Lys",
    AGT: "Ser",
    AGC: "Ser",
    AGA: "Arg",
    AGG: "Arg",
    GTT: "Val",
    GTC: "Val",
    GTA: "Val",
    GTG: "Val",
    GCT: "Ala",
    GCC: "Ala",
    GCA: "Ala",
    GCG: "Ala",
    GAT: "Asp",
    GAC: "Asp",
    GAA: "Glu",
    GAG: "Glu",
    GGT: "Gly",
    GGC: "Gly",
    GGA: "Gly",
    GGG: "Gly",
  };

  static oneLetterCode: Record<string, string> = {
    ALA: "A",
    ARG: "R",
    ASN: "N",
    ASP: "D",
    CYS: "C",
    GLN: "Q",
    GLU: "E",
    GLY: "G",
    HIS: "H",
    ILE: "I",
    LEU: "L",
    LYS: "K",
    MET: "M",
    PHE: "F",
    PRO: "P",
    SER: "S",
    THR: "T",
    TRP: "W",
    TYR: "Y",
    VAL: "V",
    STOP: "*",
    XXX: "-",
  };

  asCode: Record<string, string[]>;

  constructor() {
    this.asCode = {};
    Object.entries(CodonTranslator.codon).forEach(([k, v]) => {
      if (!(k in this.asCode)) this.asCode[k] = [];
      this.asCode[k].push(v);
    });
  }

  getOneLetterCode(aa: string | undefined) {
    return CodonTranslator.oneLetterCode[(aa || "-").toUpperCase()] || CodonTranslator.oneLetterCode.XXX;
  }

  translateToPeptideOneLetter(dna: string): string[] {
    return this.translateToPeptide(dna).map((a) => CodonTranslator.oneLetterCode[a]);
  }

  translateToTriplet(dna: string | string[]): string[] {
    if (dna.length < 3) return [];
    if (Array.isArray(dna)) dna = dna.join("");
    const str: string = dna as string;

    // console.log("str", str, "->", str.length / 3)
    const result: string[] = Array(Math.floor(str.length / 3))
      .fill(0)
      .map((a, i) => str.substr(i * 3, 3));
    return result;
    // return [];
  }

  translateToPeptide(dna: string | string[]): string[] {
    if (dna.length < 3) return [];
    if (Array.isArray(dna)) dna = dna.join("");
    const str: string = dna.toUpperCase();
    // console.log("str", str, "->", str.length / 3)
    const result: string[] = Array(Math.floor(str.length / 3))
      .fill(0)
      .map((a, i) => CodonTranslator.codon?.[str.substr(i * 3, 3)] || "XXX");
    // console.log("dna", result);
    return result;
  }
}
