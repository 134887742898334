import { useMemo } from "react";

import { HierarchyBase } from "../../ViewerLayout/ViewerLayoutTypes";
import { TreeKeyLeaf } from "./TreeKeyLeaf";
import { TreeListNode } from "./TreeListNode";
import { TreeViewerProps } from "./TreeViewerTypes";

export const TreeElement = ({
  children,
  onVisibilityChange,
  onSelectionChange,
  onColorChange,
  onTrackSettingsChange,
  onTracksZoom,
  nodeProperties,
  iconMode,
}: TreeViewerProps) => {
  const root = useMemo(() => {
    if (!children) return <div></div>;

    if (typeof children === "object") {
      if (Array.isArray(children)) {
        // const child = { type: "list", name: undefined, content: children, border: false };

        // root = children.map((child: treeNode, i: number): any => (
        //   <TreeElement key={i} {...otherProps}>
        //     {child}
        //   </TreeElement>
        // ));
        return (
          <>
            {children.map((child: HierarchyBase, i: number) => (
              <TreeElement
                key={i}
                children={child}
                nodeProperties={nodeProperties}
                // nodeProperties={{}}
                onVisibilityChange={onVisibilityChange}
                onSelectionChange={onSelectionChange}
                onColorChange={onColorChange}
                onTrackSettingsChange={onTrackSettingsChange}
                onTracksZoom={onTracksZoom}
                iconMode={iconMode}
              />
            ))}
          </>
        );
      } else {
        // console.log("children", children.name);
        switch (children.type) {
          case "node":
            // console.log("translateList");
            return (
              <TreeListNode
                children={children}
                nodeProperties={nodeProperties}
                // nodeProperties={{}}
                onVisibilityChange={onVisibilityChange}
                onSelectionChange={onSelectionChange}
                onColorChange={onColorChange}
                onTrackSettingsChange={onTrackSettingsChange}
                onTracksZoom={onTracksZoom}
                iconMode={iconMode}
              />
            );

            break;
          case "leaf":
            // console.log("translateParameter");
            return (
              <TreeKeyLeaf
                children={children}
                nodeProperties={nodeProperties}
                // nodeProperties={{}}
                onVisibilityChange={onVisibilityChange}
                onSelectionChange={onSelectionChange}
                onColorChange={onColorChange}
                onTrackSettingsChange={onTrackSettingsChange}
                onTracksZoom={onTracksZoom}
                iconMode={iconMode}
              />
            );
            break;
          default:
            console.log('Unknown element "' + children.type + '"');
            return <div>Empty</div>;
        }
      }
    } else if (typeof children === "string") {
      return <div>Empty</div>;
    }
    return null;
  }, [
    children,
    nodeProperties,
    iconMode,
    onVisibilityChange,
    onColorChange,
    onSelectionChange,
    onVisibilityChange,
    onTrackSettingsChange,
    onTracksZoom,
  ]);

  // useEffect(() => {
  //   if (!children) {
  //     setRoot(<div></div>);
  //     return;
  //   }

  //   if (typeof children === "object") {
  //     if (Array.isArray(children)) {
  //       // const child = { type: "list", name: undefined, content: children, border: false };

  //       // root = children.map((child: treeNode, i: number): any => (
  //       //   <TreeElement key={i} {...otherProps}>
  //       //     {child}
  //       //   </TreeElement>
  //       // ));
  //       setRoot(
  //         children.map((child: HierarchyBase, i: number): any => (
  //           <TreeElement
  //             key={i}
  //             children={child}
  //             // nodeProperties={nodeProperties}
  //             nodeProperties={{}}
  //             onVisibilityChange={onVisibilityChange}
  //             onSelectionChange={onSelectionChange}
  //             onColorChange={onColorChange}
  //             onTrackSettingsChange={onTrackSettingsChange}
  //             onTracksZoom={onTracksZoom}
  //             iconMode={iconMode}
  //           />
  //         ))
  //       );
  //     } else {
  //       // console.log("children", children.name);
  //       switch (children.type) {
  //         case "node":
  //           // console.log("translateList");
  //           setRoot(
  //             <TreeListNode
  //               children={children}
  //               nodeProperties={nodeProperties}
  //               // nodeProperties={{}}
  //               onVisibilityChange={onVisibilityChange}
  //               onSelectionChange={onSelectionChange}
  //               onColorChange={onColorChange}
  //               onTrackSettingsChange={onTrackSettingsChange}
  //               onTracksZoom={onTracksZoom}
  //               iconMode={iconMode}
  //             />
  //           );
  //           break;
  //         case "leaf":
  //           // console.log("translateParameter");
  //           setRoot(
  //             <TreeKeyLeaf
  //               children={children}
  //               nodeProperties={nodeProperties}
  //               // nodeProperties={{}}
  //               onVisibilityChange={onVisibilityChange}
  //               onSelectionChange={onSelectionChange}
  //               onColorChange={onColorChange}
  //               onTrackSettingsChange={onTrackSettingsChange}
  //               onTracksZoom={onTracksZoom}
  //               iconMode={iconMode}
  //             />
  //           );
  //           break;
  //         default:
  //           setRoot(<div>Empty</div>);
  //           console.log('Unknown element "' + children.type + '"');
  //       }
  //     }
  //   } else if (typeof children === "string") {
  //     setRoot(<div>Empty</div>);
  //   }
  // }, [children, nodeProperties, iconMode, onVisibilityChange, onColorChange]); // TODO: onSelectionChange needs to be added but updates too often

  return root;
};
