import { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";

import styles from "./PDFViewer.module.css";

pdfjs.GlobalWorkerOptions.workerSrc = "/assets/pdf.worker.min.js";
export type sizeType = { width: number; height: number };

export const PDFViewer = ({
  pdf,
  height,
  // size,
  width,
  scale,
  page,
  fitScale,
  setPageNumber,
  setFitScale,
}: {
  pdf?: Uint8Array;
  height?: number;
  width?: number;
  // size: sizeType;
  scale: number;
  page: number;
  fitScale: number | undefined;
  setFitScale: (scale: number) => void;
  setPageNumber?: (pageNumber: number) => void;
}) => {
  // const [fitScale, setFitScale] = useState<number>();
  const [size, setSize] = useState<sizeType>();

  const file = useMemo(() => ({ data: pdf }), [pdf]);
  const paddingVertical = 10;
  const paddingHorizontal = 10;
  const targetRef = useRef<HTMLDivElement>(null);

  const getDimension = useCallback(() => {
    if (targetRef.current) {
      const element = targetRef.current as Element;
      const canvas = element.getElementsByTagName("canvas")?.[0];
      if (canvas) {
        const bound = canvas.getBoundingClientRect();
        // console.log("set", { width: bound.width, height: bound.height });
        setSize({ width: bound.width, height: bound.height });
      } else {
        // console.log("wait...");
        setTimeout(getDimension, 100);
      }
    }
  }, [targetRef.current]);

  useLayoutEffect(() => {
    getDimension();
  }, [targetRef.current]);

  useEffect(() => {
    const s = calcFitScale();
    if (s) setFitScale(s);
  }, [width, height, size]);

  // useEffect(() => {
  //   const s = calcFitScale();
  //   // if (s && fitScale) console.log("page", page, s, Math.abs(fitScale - s));
  //   // if (s && fitScale && Math.abs(fitScale - s) > 0.0001) setFitScale(s);
  // }, [page, fitScale]);

  const calcFitScale = useCallback(() => {
    if (
      size &&
      size.width > 0 &&
      size.height > 0 &&
      width !== undefined &&
      height !== undefined &&
      width > 0 &&
      height > 0
    ) {
      let s = 1;
      const w = (width ?? 0) - 2 * paddingHorizontal;
      const h = (height ?? 0) - 2 * paddingVertical;

      const widthScale = w / size.width;
      const heightScale = h / size.height;
      s = Math.min(widthScale, heightScale);
      // console.log("test", w, h, "_", size.width, size.height, "->", widthScale, heightScale, "=>", s);
      return s;
    }
    return undefined;
  }, [width, height, size]);

  if (page < 1 || pdf === undefined) return <div>No data</div>;

  return (
    <div
      className={styles.resize}
      style={{
        width: width,
        height: height,
        paddingTop: paddingVertical,
        paddingLeft: paddingHorizontal,
      }}
    >
      <div>
        <div
          className={styles.shadow}
          ref={targetRef}
          style={{
            width: (size?.width ?? 0) * scale - 1,
            height: (size?.height ?? 0) * scale - 1,
          }}
        >
          <Document
            file={file}
            onLoadSuccess={(d) => {
              if (setPageNumber) setPageNumber(d._pdfInfo.numPages);
            }}
          >
            <Page pageNumber={page} scale={scale} />
          </Document>
        </div>
        <div
          style={{
            width: (size?.width ?? 0) * scale + paddingHorizontal,
            height: paddingVertical,
          }}
        ></div>
      </div>
    </div>
  );
};
