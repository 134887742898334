import { useEffect, useState } from "react";

import { ParameterList } from "../../ViewerLayout/ViewerLayoutTypes";
import { TreeElement } from "./TreeElement";
import { SearchRequestType } from "./TreeViewerTypes";
import styles from "./TreeViewer.module.css";
// export const makeColorMarker = (colors: string[] | undefined | null): any => {
//   let colorBox = undefined;
//   if (colors !== undefined && colors !== null) {
//     colorBox = colors.map((c, i) => {
//       return <span key={i} className="glyphicon glyphicon-stop" style={{ color: c }} />;
//     });
//     colorBox.unshift(<span key={colors.length}>&nbsp;</span>);
//   }
//   return colorBox;
// };

// const parameterFormatter = new ParameterFormatter();

const checkVisibility = (
  elements: any,
  search: SearchRequestType,
  trackVisibility: Record<string, boolean>,
  space: string = ""
) => {
  let params: any;

  if (typeof elements === "object") {
    if (Array.isArray(elements)) {
      params = elements.map((element: any) => checkVisibility(element, search, trackVisibility, space + "  "));
    } else {
      params = {};
      Object.assign(params, elements);

      if (params.tracks && Object.keys(trackVisibility).length > 0) {
        params.isVisible = params.tracks.some((id: string) => trackVisibility[id]);
      } else params.isVisible = true;

      let visible;
      switch (params.type) {
        case "table":
          visible = true;
          let isLineVisible: boolean[] = params?.table ? params?.table.map(() => true) : [true];
          if (search?.keyText || search?.valueText) {
            isLineVisible = params?.table ? params?.table.map(() => false) : [false];
            if (search?.keyText) {
              const re = new RegExp(`${search.keyText}`, "i");
              isLineVisible = params.table.map(() => re.test(params.name));
            }
            if (search?.valueText && params?.table) {
              const re = new RegExp(`${search.valueText}`, "i");
              isLineVisible = params.table.map(
                (line: string[], i: number) => isLineVisible[i] || line.some((v) => re.test(v))
              );
            }
            visible = isLineVisible.some((v: boolean) => v);
          }
          params.isLineVisible = isLineVisible;
          params.isVisible = params.isVisible && visible;
          break;
        case "list":
          visible = false;
          if (search?.headerText) {
            visible = true;
            const re = new RegExp(`${search.headerText}`, "i");
            visible = re.test(params.name);
            if (visible) search = { headerText: search.headerText, keyText: ".*", valueText: search.valueText };
            else
              search = {
                headerText: search.headerText,
                keyText: search.keyText ?? "(?=a)b",
                valueText: search.valueText ?? "(?=a)b",
              };
          }

          params.content = elements.content.map((element: any) =>
            checkVisibility(element, search, trackVisibility, space + "  ")
          );

          params.isVisible = visible || (params.isVisible && params.content.some((element: any) => element.isVisible));
          break;
        case "parameter":
          const {
            name,
            value,
            unit,
          }: // formattedValue,
          {
            name: string;
            value: number | string;
            formattedValue: [number, string];
            valueType: string;
            decimal: number;
            unit: string;
            formatter: string;
            search: SearchRequestType;
          } = elements;
          // const formattedValue =
          // console.log("visible?", name, "|" + formattedValue + "|");

          params.formattedValue = value + (unit && unit !== "" ? " " + unit : "");
          visible = true;
          if (search?.keyText || search?.valueText) {
            visible = false;
            if (search?.keyText) {
              const re = new RegExp(`${search.keyText}`, "i");
              visible = visible || re.test(name);
            }
            if (search?.valueText) {
              const re = new RegExp(`${search.valueText}`, "i");
              // visible = visible || re.test(params.formattedValue.join(" "));
              visible = visible || re.test(params.formattedValue);
            }
          }
          params.isVisible = params.isVisible && visible;

          break;
        default:
          console.log('Unknown element "' + elements.type + '"');
      }
    }
  }

  return params;
};

export const TreeViewer = ({
  children,
  search,
  trackVisibility,
}: {
  children: ParameterList;
  search: SearchRequestType;
  trackVisibility: Record<string, boolean>;
}) => {
  // const data = ["item1", "item2", "item3", ["item4.1", "item4.2", "item4.3"]];

  const [params, setParams] = useState<ParameterList>(children);

  useEffect(() => {
    checkVisibility(children, search, trackVisibility);
    // console.log(">>", checkVisibility(children, search, trackVisibility));
    setParams(checkVisibility(children, search, trackVisibility));
    // setParams(children);
  }, [children, search, trackVisibility]);

  // const tree = new KeyValueTree(data);
  // console.log("tree", tree.toString());
  // console.log("SEARCH", props.search);
  // const params =
  // console.log("params", params);
  // console.log("---");
  // if (params) Object.values(params).forEach((param: any) => console.log("param", param.name, param.isVisible));
  // useEffect(() => {
  //   console.log("TreeViewer rendered");
  // });
  // console.log("-----");

  return (
    <div className={styles.tree}>
      {/* <Button
        onClick={() => {
          const p = checkVisibility(children, search, trackVisibility);
          console.log("checkVisibility done");
          console.log("count", countParameter(p));
          setParams(p);
        }}
      >
        Test
      </Button> */}

      {/* <TreeElement searching={(search?.keyText || search?.valueText) !== undefined} search={search}>
        {params}
      </TreeElement> */}
      {params.content.map((p, i) => (
        <TreeElement key={i} search={search}>
          {p}
        </TreeElement>
      ))}
      {/* <TreeElement search={search}>{params}</TreeElement> */}
      {/* <TreeElement>{params}</TreeElement> */}
    </div>
  );
};
