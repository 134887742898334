import { yupResolver } from "@hookform/resolvers/yup";
import { useCallback, useContext, useMemo } from "react";
import { useForm, useWatch } from "react-hook-form";
import * as yup from "yup";
import { SessionContext } from "../../common/contexts/SessionContext";
import { InputFormField } from "../../common/formfields/InputFormField";
import { TextareaFormField } from "../../common/formfields/TextareaFormField";
import { FormButtons } from "../../common/forms/FormButtons";
import { FormHeader } from "../../common/forms/FormHeader";
import styles from "../../common/forms/forms.module.css";
import { Dataset, datasetsConstants } from "../../api/Datasets";
import { ProjectsVirtualizedSelectForm } from "../../common/forms/EntityForms/formsVirtualized/ProjectsVirtualizedSelectForm";
import { OrganizationsVirtualizedSelectForm } from "../../common/forms/EntityForms/formsVirtualized/OrganizationsVirtualizedSelectForm";
import { PersonsVirtualizedSelectForm } from "../../common/forms/EntityForms/formsVirtualized/PersonsVirtualizedSelectForm";
import { MethodsVirtualizedSelectForm } from "../../common/forms/EntityForms/formsVirtualized/MethodsVirtualizedSelectForm";
import { InstrumentsVirtualizedSelectForm } from "../../common/forms/EntityForms/formsVirtualized/InstrumentsVirtualizedSelectForm";
import { SamplesVirtualizedSelectForm } from "../../common/forms/EntityForms/formsVirtualized/SamplesVirtualizedSelectForm";
import { ExperimentsVirtualizedSelectForm } from "../../common/forms/EntityForms/formsVirtualized/ExperimentsVirtualizedSelectForm";
import { EquipmentsVirtualizedSelectForm } from "../../common/forms/EntityForms/formsVirtualized/EquipmentsVirtualizedSelectForm";
import { useDatasetInstrument } from "../common/HelperModules";
import { SingleDatePickerFormField } from "../../common/formfields/DatePickerFormField/SingleDatePickerFormField";
import { EntityCustomTypeForm, useCustomTypeForm } from "../../Customization/CustomTypes/generics/useCustomTypeForm";
import { CustomTypedEntityFormProps } from "../../common/entity/EntityInterfaces";
import { FormFieldLayout } from "../../common/formfields/FormFieldLayouts";
import { LucideIcon } from "../../common/icon/LucideIcon";

export const datasetClaimFormSchema = {
  name: yup
    .string()
    .required("Dataset name is required")
    .typeError("Dataset name is required")
    .test({
      test: (value) => value === value?.trim(),
      message: "Whitespaces at the beginning or at the end of the name are not allowed",
    }),
  projects: yup
    .array()
    .of(yup.object().shape({ id: yup.number().required().positive().integer(), name: yup.string().required() }))
    .min(1, "At least one project is required")
    .typeError("At least one project is required"),
  operators: yup
    .array()
    .of(yup.object().shape({ id: yup.number().required().positive().integer(), name: yup.string().required() }))
    .min(1, "At least one operator is required")
    .typeError("At least one operator is required"),
  sample: yup
    .object()
    .shape({ id: yup.number().required().positive().integer(), name: yup.string().required() })
    .typeError("A sample must be defined")
    .required("A sample must be defined"),
  method: yup
    .object()
    .shape({ id: yup.number().required().positive().integer(), name: yup.string().required() })
    .strict()
    .required("A method must be defined")
    .typeError("A method must be defined"),
};

const iso2local = (isodate: string | undefined | null) => {
  if (isodate) {
    return new Date(isodate).toISOString().slice(0, -1);
  } else {
    return "";
  }
};

export const datasetFormSchema = {
  method: yup
    .object()
    .shape({ id: yup.number().required().positive().integer(), name: yup.string().required() })
    .strict()
    .required("A method must be defined")
    .typeError("A method must be defined"),
};

interface DatasetFormProps extends Omit<CustomTypedEntityFormProps<"datasets">, "event"> {
  event: CustomTypedEntityFormProps<"datasets">["event"] | "CLAIM";
}
export const DatasetForm = ({
  fieldLabels,
  permissions,
  title,
  subtitle,
  initialValues,
  onSubmit,
  onCancel,
  loading,
  submitButtonLabel,
  event,
  typeId,
}: DatasetFormProps) => {
  const { session } = useContext(SessionContext);

  const prepareEntity = useCallback(
    (entity: Partial<Dataset>) => {
      // We need to add back the time zone info
      entity.acquisitionDate = entity.acquisitionDate + "Z";

      if (entity.name !== entity.automaticName) {
        entity.isManuallyNamed = true;
      } else {
        entity.isManuallyNamed = false;
      }

      // Perform the claiming if in the claim context
      if (event === "CLAIM") {
        if (entity.sample && session?.person) {
          entity.claimed = true;
          entity.owner = session.person;
        } else {
          entity.claimed = false;
        }
        entity.isViewableEntity = false;
      }
      return entity;
    },
    [session?.person, event]
  );

  const _initialValues = useMemo(() => {
    if (initialValues)
      return {
        ...initialValues,
        acquisitionDate: iso2local(initialValues.acquisitionDate),
        operators:
          event === "CLAIM" && initialValues.operators?.length === 0 && !!session?.person
            ? [session.person]
            : initialValues.operators,
      } as Partial<Dataset>;
  }, [initialValues, session?.person, event]);

  const { defaultValues, typedFormSchema, processCustomFields, type, setType, types } = useCustomTypeForm({
    initialValues: _initialValues,
    formSchema: event === "CLAIM" ? datasetClaimFormSchema : datasetFormSchema,
    typeId: typeId,
    entityType: "Dataset",
  });

  const {
    register,
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { errors, isSubmitting },
  } = useForm<Partial<Dataset>>({
    values: defaultValues,
    resolver: yupResolver(typedFormSchema),
  });

  const currentName = watch("name");

  // Custom hook to set method if instrument is set
  const { instrument } = useDatasetInstrument<Partial<Dataset>, "instrument", "method">({ control, setValue });
  const method = useWatch({ name: "method", control });

  return (
    <>
      <FormHeader title={`${event === "CLAIM" ? "Claim dataset" : title}`} subtitle={subtitle} />
      <form
        onSubmit={(e) => {
          e.preventDefault();
        }}
        autoComplete="off"
        className={`form-horizontal ${styles.form_holder}`}
      >
        <fieldset>
          <legend className="col-md-offset-2 col-md-10">Basic details</legend>
        </fieldset>
        <InputFormField
          id={"name"}
          label={fieldLabels.name}
          errors={errors}
          register={register}
          autoFocus={false}
          required={false}
          // readOnly={true}
        />

        {!!initialValues?.isManuallyNamed && currentName !== initialValues.automaticName && (
          <FormFieldLayout id="" label="" horizontal>
            <div className="flex align-center gap-5 form form-control" style={{ justifyContent: "space-between" }}>
              <div style={{ color: "var(--gray-400)" }}>{initialValues.automaticName}</div>
              <button
                onClick={() => setValue("name", initialValues?.automaticName)}
                className="flex align-center gap-5 btn btn-ghost-secondary btn-xs"
              >
                <LucideIcon name="refresh-ccw" />
                Restore {fieldLabels.automaticName.toLowerCase()}
              </button>
            </div>
          </FormFieldLayout>
        )}

        <SingleDatePickerFormField
          type={"datetime"}
          id={"acquisitionDate"}
          label={fieldLabels.acquisitionDate}
          control={control}
          required={false}
          errors={errors}
          readOnly={true}
          horizontal
        />

        <ProjectsVirtualizedSelectForm
          id={"projects"}
          label={fieldLabels.projects}
          control={control}
          required={event === "CLAIM"}
          isMulti
          horizontal
          allowCreateEntity
          allowUnassigned={event !== "CLAIM"}
          showControls
          filters={{ currentUserHasAddPermission: true }}
        />

        <OrganizationsVirtualizedSelectForm
          id={"organizations"}
          label={fieldLabels.organizations}
          control={control}
          isMulti
          horizontal
          allowCreateEntity
          showControls
        />

        <PersonsVirtualizedSelectForm
          id={"operators"}
          label={fieldLabels.operators}
          control={control}
          required={event === "CLAIM"}
          horizontal
          isMulti
          allowUnassigned={event !== "CLAIM"}
          allowCreateEntity
          showControls
        />
        <MethodsVirtualizedSelectForm
          id={"method"}
          label={fieldLabels.method}
          control={control}
          horizontal
          allowCreateEntity
          showControls
          required
          disabled={!!instrument}
        />

        <InstrumentsVirtualizedSelectForm
          id={"instrument"}
          label={fieldLabels.instrument}
          control={control}
          horizontal
          allowUnassigned
          allowCreateEntity
          showControls
        />

        <SamplesVirtualizedSelectForm
          id={"sample"}
          label={fieldLabels.sample}
          control={control}
          required={event === "CLAIM"}
          horizontal
          allowUnassigned={event !== "CLAIM"}
          allowCreateEntity
          showControls
        />

        <ExperimentsVirtualizedSelectForm
          id={"experiment"}
          label={fieldLabels.experiment}
          control={control}
          horizontal
          allowUnassigned
          allowCreateEntity
          showControls
          filters={{ methodIds: method && method.id > 0 ? [method.id] : undefined }}
        />

        <EquipmentsVirtualizedSelectForm
          id={"equipments"}
          label={fieldLabels.equipments}
          control={control}
          horizontal
          allowUnassigned
          allowCreateEntity
          showControls
          isMulti
        />

        <InputFormField
          id={"other"}
          label={fieldLabels.other}
          errors={errors}
          register={register}
          autoFocus={false}
          required={false}
        />
        <TextareaFormField id={"notes"} label={fieldLabels.notes} errors={errors} register={register} />
        <EntityCustomTypeForm
          entityType="Dataset"
          typeId={typeId}
          type={type}
          types={types}
          setType={setType}
          control={control}
          register={register}
          setValue={setValue}
          initialValues={_initialValues}
          errors={errors}
          entityConstants={datasetsConstants}
        />

        <FormButtons
          groupName={datasetsConstants.frontendIndexRoute}
          entityId={defaultValues ? defaultValues.id : undefined}
          onClose={onCancel}
          onSubmit={handleSubmit(async (entity) => await onSubmit(processCustomFields(prepareEntity(entity))))}
          // onSubmit={handleSubmit(async (entity: Dataset) => {
          //   await editMutationAsync(
          //     {
          //       id: entity.id,
          //       body: processCustomFields(prepareEntity(entity), datasetType),
          //     },
          //     {
          //       onSuccess: () => {
          //         showtoast("success", `${type === "edit" ? "Edited dataset" : "Claimed dataset"}`);
          //         dataset.isViewableEntity
          //           ? history.replace(route(getDetailLink(datasetsConstants.frontendIndexRoute, dataset.id)))
          //           : history.goBack();
          //       },
          //     }
          //   ).catch(() => {});
          // })}
          disabled={isSubmitting}
          errors={errors}
          submitButtonLabel={`${event === "CLAIM" ? "Claim" : submitButtonLabel}`}
          loading={loading}
        />
      </form>
    </>
  );
};
