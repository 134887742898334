import { CSSProperties, useCallback, useContext, useMemo, useState } from "react";
import { FixedSizeList } from "react-window";
import InfiniteLoader from "react-window-infinite-loader";
import { useInfiniteListEntity } from "../api/BaseEntityApi";
import { IEntityMinimalModel, IPaginationParameters } from "../api/GenericTypes";
import { SessionContext } from "../common/contexts/SessionContext";
import { useDebouncedValue } from "../common/helperfunctions/useDebouncedValue";
import { Skeleton } from "../common/loaders/Skeleton/Skeleton";
import { Alert } from "../common/overlays/Alert/Alert";
import styles from "./ProjectPersonPermissions.module.css";
import { Link } from "react-router-dom";
import { getDetailLink } from "../main/Routing";
import { Project } from "../api/Projects";
import { ProjectPersonPermissionHeader, ProjectPersonPermissionRow } from "./ProjectPersonPermissions";

interface PersonProjectPermission {
  administer: boolean;
  edit: boolean;
  add: boolean;
  read: boolean;
  person: IEntityMinimalModel<number>;
  project: IEntityMinimalModel<number>;
}

interface PersonProjectPermissionParameters extends IPaginationParameters {
  searchTerm?: string | null;
  usePagination?: boolean | null;
}

const ITEM_SIZE = 35;
const PAGE_SIZE = 10;

export const PersonProjectPermissions = ({ project }: { project: Project }) => {
  const { route } = useContext(SessionContext);

  const [searchValue, setSearchValue] = useState<string>("");
  const debouncedSearchValue = useDebouncedValue(searchValue, 300);

  const { data, fetchNextPage, hasNextPage, isFetching, count } = useInfiniteListEntity<
    PersonProjectPermission,
    PersonProjectPermissionParameters
  >(
    `projects/${project?.id || 0}/projectPermissions` as any,
    { page: 1, pageSize: PAGE_SIZE, searchTerm: debouncedSearchValue, includeCount: true },
    { enabled: !!project?.id },
    "post"
  );

  const projectPermissions = useMemo(() => {
    return data?.pages.map((d) => d.results).flat() || [];
  }, [data?.pages]);

  const fetchNext = useCallback(() => {
    if (hasNextPage) {
      fetchNextPage();
    }
  }, [fetchNextPage, hasNextPage]);

  const loadMoreCallback = useCallback(() => {}, []);
  const itemCount = hasNextPage ? projectPermissions.length + 1 : projectPermissions.length;
  const loadMoreItems = isFetching ? loadMoreCallback : fetchNext;
  const isItemLoaded = useCallback(
    (index: number) => !hasNextPage || index < projectPermissions.length,
    [hasNextPage, projectPermissions.length]
  );
  const Item = ({ index, style }: { index: number; style: CSSProperties }) => {
    const current_element = projectPermissions[index];
    if (!isItemLoaded(index)) {
      return (
        <div style={{ ...style }} key={index}>
          <span className="skeleton-block btn-lg" style={{ height: 51 }} />
        </div>
      );
    } else {
      return (
        <div style={{ ...style }} key={index}>
          <Link to={route(getDetailLink("persons", current_element.person.id))} style={{ textDecoration: "none" }}>
            <ProjectPersonPermissionRow projectPersonPermission={current_element} target="persons" />
          </Link>
        </div>
      );
    }
  };

  return (
    <div className={styles.PersonProjectPermissionsContainer}>
      <div className={styles.PersonProjectPermissionsTable}>
        <ProjectPersonPermissionHeader
          count={count}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          target="persons"
        />

        {projectPermissions && projectPermissions.length > 0 ? (
          <InfiniteLoader isItemLoaded={isItemLoaded} itemCount={itemCount} loadMoreItems={loadMoreItems as any}>
            {({ onItemsRendered, ref }) => (
              <FixedSizeList
                itemCount={itemCount}
                onItemsRendered={onItemsRendered}
                ref={ref}
                width="100%"
                height={
                  projectPermissions.length > PAGE_SIZE ? PAGE_SIZE * ITEM_SIZE : projectPermissions.length * ITEM_SIZE
                }
                itemSize={ITEM_SIZE}
              >
                {Item}
              </FixedSizeList>
            )}
          </InfiniteLoader>
        ) : (
          <>
            {isFetching ? (
              <Skeleton type="rows" />
            ) : (
              <>
                {debouncedSearchValue ? (
                  <Alert type="light" message={`No results for: "${debouncedSearchValue}"`} fit centered />
                ) : (
                  <Alert type="light" message="No project permissions" fit centered />
                )}
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};
