import { CSSProperties, useEffect, useState } from "react";
import { Dropdown, MenuItem } from "react-bootstrap";
import { Color } from "../../ViewerLayout/ViewerLayoutTypes";
// import { ColorNeedsUpdate,  ListToColorType } from "../../ViewerLayout/ViewerTypes";
import { ColorBox } from "../ColorBox";

import styles from "./DecoratedChoiceInput.module.css";

type listElement = { id: string; value: string; color?: Color };

const Label = ({ current }: { current?: listElement }) => {
  if (!current) return <span>--</span>;

  return (
    <span>
      {current.color ? (
        <span>
          <ColorBox color={current.color} />{" "}
        </span>
      ) : null}
      {current.value}
    </span>
  );
};

export const DecoratedChoiceInput = ({
  value,
  choices,
  setValue,
  name,
  maxHeight,
}: {
  value?: string;
  choices: (string | listElement)[];
  setValue?: (value: string) => void;
  name?: string;
  style?: CSSProperties;
  maxHeight?: number;
}) => {
  const [list, setList] = useState<listElement[]>(
    choices ? choices.map((c: any): listElement => (typeof c === "object" ? c : { id: c, value: c })) : []
  );
  const [lookup, setLookup] = useState<Record<string, listElement>>(Object.fromEntries(list.map((l, i) => [l.id, l])));
  const [current, setCurrent] = useState<string>();

  useEffect(() => {
    // const l = choices.map((c: any) => (typeof c === "object" ? c : { id: c, value: c }));
    // console.log("choice", l);
    setList(choices.map((c: any): listElement => (typeof c === "object" ? c : { id: c, value: c })));
    setLookup(Object.fromEntries(list.map((l, i) => [l.id, l])));
  }, [choices, list]);

  useEffect(() => {
    // console.log("choice", list);
    // let c = list?.[0].id ?? "";
    if (value && value in lookup) setCurrent(value);
  }, [value, list, lookup]);

  return (
    <div className={styles.rangeSetter}>
      <div className={styles.settingText} style={{ position: "relative", top: -10 }}>
        {name ? <span>{name}:&nbsp;</span> : null}
      </div>
      <div className={styles.inputField} style={{ position: "relative", top: -10 }}>
        <Dropdown
          id={"pipeline-dropdown"}
          className={styles.dropdownText}
          onSelect={(key: any) => {
            setCurrent(key);
            if (setValue) setValue(key);
          }}
        >
          <Dropdown.Toggle bsSize="xsmall">
            <Label current={lookup?.[current ?? ""]} />
            {/* {current ? list[lookup[current]].value : "--"} */}
          </Dropdown.Toggle>
          <Dropdown.Menu
            className={styles.scrollableMenu}
            style={{ maxHeight: maxHeight ?? (100 || 100), overflowY: "auto" }}
          >
            {list.map((item, i) => (
              <MenuItem key={i} eventKey={item.id} active={item.id === current} className={styles.dropdownText}>
                <Label current={item} />
                {/* {item.value} */}
              </MenuItem>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </div>
  );
};
