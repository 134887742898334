import { BridgeType } from "./Bridges";
import {
  IGenericRequestParametersTranslator,
  IPaginationParametersTranslator,
  PropertyTranslator,
} from "./GenericTranslator";
import {
  DefaultOrderTypeConsts,
  EntityConstants,
  IGenericEntity,
  IGenericRequestParameters,
  IPaginationParameters,
} from "./GenericTypes";

export const dataSourceStatusConstants: EntityConstants<DataSourceStatus, DataSourceStatusFilters> = {
  resource: "data_sources_status",
  frontendIndexRoute: "data_sources_status",
  entitySingular: "data source status",
  entityPlural: "data source status",
  icon: "server",
};

export const dataSourceStatusFieldLabels: PropertyTranslator<DataSourceStatus> & { [key: string]: string } = {
  type: "Bridge type",
  lastUpdated: "State last updated",
  runState: "State",
  startedOn: "Started on",
  duration: "Duration",
  errors: "Errors",
  info: "Info",
  counter: "Counter",
  dataSourceId: "Data source ID",
  uuid: "UUID",
  id: "ID",
};
export interface DataSourceStatus extends IGenericEntity {
  type: BridgeType | null;
  uuid: string;
  lastUpdated?: string;
  counter?: number;
  dataSourceId: number;
  runState: DataSourceStatusRunState;
  startedOn: string;
  duration?: number;
  errors?: { message: string }[];
  info?: DataSourceStatusInfoSSH | DataSourceStatusInfoClient;
}

export interface DataSourceStatusChangedEvent
  extends Omit<
    DataSourceStatus,
    "type" | "uuid" | "lastUpdated" | "counter" | "dataSourceId" | "startedOn" | "duration" | "errors" | "info"
  > {}

export interface DataSourceStatusInfoSSH {
  datasetsFound: number;
  datasetsUploaded: number;
  datasetsUpdated: number;
  datasetsKnown: number;
  datasetsFailed: number;
  directoriesEntered: number;
  directoriesFailedToRead: number;
  filesFound: number;
  filesUploaded: number;
  filesUploadSize: number;
  filesFailedToRead: number;
}
export interface DataSourceStatusInfoClient {
  clientVersion: string;
  datasetInQueue: number;
  directoriesEntered: number;
  entriesScanned: number;
  entriesMatched: number;
  brokenSymlinksIgnored?: number;
  datasetsKnownFromCache: number;
  datasetsNotKnownFromCache: number;
  datasetsKnownFromServer: number;
  datasetsOlderThanCutoffDate: number;
  incompleteDatasets: number;
  datasetsSubmitted: number;
  datasetsFailedSubmission: number;
  totalSizeSubmittedDatasetsInBytes: number;
  totalSizeDatasetsInQueueInBytes: number;
}
export type DataSourceStatusRunState = "Running" | "Failed" | "Finished" | "Waiting" | "Aborted";

export interface DataSourceSourceClientStatus {
  isConnected: boolean;
  DataSourceStatusList: DataSourceStatus[];
}

export interface DataSourceStatusFilters
  extends IGenericRequestParameters<DataSourceStatus, DataSourceStatusHistorySortingOptions>,
    IPaginationParameters {
  dataSourceIds?: number[] | null;
}

export const DataSourceStatusFiltersTranslator: PropertyTranslator<DataSourceStatusFilters> = {
  ...IPaginationParametersTranslator,
  ...IGenericRequestParametersTranslator,
  dataSourceIds: "Data source IDs",
} as const;

export const DataSourceStatusSortingOptionsConsts = [
  ...DefaultOrderTypeConsts,
  "STARTED_ON_ASC",
  "STARTED_ON_DESC",
] as const;

export type DataSourceStatusHistorySortingOptions = (typeof DataSourceStatusSortingOptionsConsts)[number];

export const DataSourceStatusFieldLabels: PropertyTranslator<DataSourceStatus> = {
  counter: "Counter",
  dataSourceId: "Data source ID",
  duration: "Duration in seconds",
  errors: "Errors",
  id: "Data source status ID",
  info: "Info",
  lastUpdated: "Last updated",
  runState: "Run state",
  startedOn: "Started on",
  type: "Bridge type",
  uuid: "Uuid",
} as const;
