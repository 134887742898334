import { useHistory } from "react-router-dom";
import React, { useMemo, useState } from "react";
import { SearchInput } from "../../common/forms/SearchInput/SearchInput";
import { LucideIcon } from "../../common/icon/LucideIcon";
import TableView, { TableViewLabel } from "../../common/panels/TableView/TableView";
import { ColumnsSettings } from "../../common/tables/ColumnsSelector/ColumnsSelector";
import { AlertModal } from "../../common/modals/AlertModal/AlertModal";
// import { showtoast } from "../../common/overlays/Toasts/showtoast";
import { MoreDropdown } from "../../common/buttons/MoreDropdown/MoreDropdown";
import { EntityTable } from "../../common/tables/EntityTable/EntityTable";
import {
  GenericVirtualizedTableCells,
  SortState,
} from "../../common/tables/GenericVirtualizedTable/GenericVirtualizedTableTypes";
import { EntityFilterIndicator } from "../../common/tables/EntityFilterIndicator/EntityFilterIndicator";
import { useTabStore } from "../../common/tables/Tabs/useTabStore";
import { TableTabsDict } from "../../common/tables/Tabs/TableTabsTypes";
import { AnnouncementFilterBar } from "./AnnouncementFilterBar";
import { AnnouncementFilterForm } from "./AnnouncementFilterBar";
import { DateTimeRenderer } from "../../common/datetime/DateTimeFormatter";
import { GetPersons } from "../../common/misc/EntityRenders/EntityRenderer";
import {
  Announcement,
  AnnouncementFilters,
  AnnouncementFiltersTranslator,
  announcementFieldLabels,
} from "../../api/Announcements";
import { AnnouncementContentTypeField, AnnouncementTypeLabel } from "../AnnouncementsDetail";
import { NotSet } from "../../common/misc/UIconstants";
import {
  UseEntityTableDefaultProps,
  UseEntityTableProps,
  useGenericVirtualizedTable,
  useGenericVirtualizedTableTabs,
} from "../../common/tables/GenericVirtualizedTable/useGenericVirtualizedTable";
import { EntityTableProps } from "../../common/entity/EntityInterfaces";
import { AnnouncementsTableOptions } from "../AnnouncementViews";
import { EntityTableEditButton } from "../../common/entity/entityComponents/EntityTableEditButton";
import { EntityTableCloneButton } from "../../common/entity/entityComponents/EntityTableCloneButton";
import { EntityTableDeleteButton } from "../../common/entity/entityComponents/EntityTableDeleteButton";

export const switchAnnouncementsDefaultSortState = (
  sortState: AnnouncementFilters["orderBy"]
): SortState<AnnouncementFilters["orderBy"]> => {
  switch (sortState) {
    case "ID_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-id",
        orderBy: sortState,
      };
    case "ID_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-id",
        orderBy: sortState,
      };
    case "TYPE_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-type",
        orderBy: sortState,
      };
    case "TYPE_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-type",
        orderBy: sortState,
      };
    case "START_DATE_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-start",
        orderBy: sortState,
      };
    case "START_DATE_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-start",
        orderBy: sortState,
      };
    case "END_DATE_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-end",
        orderBy: sortState,
      };
    case "END_DATE_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-end",
        orderBy: sortState,
      };
    case "CREATED_ON_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-createdOn",
        orderBy: sortState,
      };
    case "CREATED_ON_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-createdOn",
        orderBy: sortState,
      };
    case "CREATED_BY_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-createdBy",
        orderBy: sortState,
      };
    case "CREATED_BY_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-createdBy",
        orderBy: sortState,
      };
    case "MODIFIED_ON_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-modifiedOn",
        orderBy: sortState,
      };
    case "MODIFIED_ON_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-modifiedOn",
        orderBy: sortState,
      };
    case "MODIFIED_BY_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-modifiedBy",
        orderBy: sortState,
      };
    case "MODIFIED_BY_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-modifiedBy",
        orderBy: sortState,
      };
    default:
      return {
        sortDirection: "ASC",
        headerId: "default-name",
        orderBy: sortState,
      };
  }
};

const defaultFilter: AnnouncementFilters = { orderBy: "TYPE_DESC", includeCurrentUserHasRead: true };

export const useAnnouncementsTableDefaults = ({ fieldLabels }: UseEntityTableDefaultProps<"announcements">) => {
  const defaults: ColumnsSettings<Announcement> = useMemo(
    () => ({
      "default-id": { pos: 0, active: false, header: fieldLabels.id, property: "id" },
      "default-type": { pos: 1, active: true, header: fieldLabels.type, property: "type" },
      "default-message": { pos: 2, active: true, header: fieldLabels.message, property: "message" },
      "default-startDate": { pos: 3, active: true, header: fieldLabels.startDate, property: "startDate" },
      "default-endDate": { pos: 4, active: true, header: fieldLabels.endDate, property: "endDate" },
      "default-createdOn": { pos: 5, active: true, header: fieldLabels.createdOn, property: "createdOn" },
      "default-createdBy": { pos: 6, active: true, header: fieldLabels.createdBy, property: "createdBy" },
      "default-modifiedOn": {
        pos: 7,
        active: true,
        header: announcementFieldLabels.modifiedOn,
        property: "modifiedOn",
      },
      "default-modifiedBy": {
        pos: 8,
        active: true,
        header: announcementFieldLabels.modifiedBy,
        property: "modifiedBy",
      },
    }),
    [fieldLabels]
  );
  const tabStoreDefaults: TableTabsDict<Announcement, AnnouncementFilters, AnnouncementFilterForm> = useMemo(
    () => ({
      default: {
        tabId: "default",
        type: "fixed",
        label: "All",
        title: "All",
        icon: "house",
        align: "left",
        xPos: 0,
        settings: {
          columnSettings: {},
          columnWidths: {},
          filters: defaultFilter,
          sidebarFilters: {},
        },
        forcedSettings: {
          columnSettings: {},
          columnWidths: {},
          filters: {},
          sidebarFilters: {},
        },
      },
    }),
    []
  );

  return { defaults, tabStoreDefaults };
};

export const useAnnouncementsTableColumns = ({
  entityConstants,
  fieldLabels,
  sort,
  setSort,
}: UseEntityTableProps<"announcements">) => {
  const columns = React.useMemo(() => {
    let headers: GenericVirtualizedTableCells<Announcement> = [
      {
        id: "default-id",
        Header: fieldLabels.id,
        accessor: (row) => <span style={{ color: "var(--gray-400)" }}>{row.id}</span>,
        width: 120,
        minWidth: 120,
        align: "center",
        sortingFn: (id) => {
          if (sort.headerId === id) {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "ID_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "ID_ASC" }));
            }
          } else {
            setSort({ headerId: id, sortDirection: "ASC", orderBy: "ID_ASC" });
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
      {
        id: "default-type",
        Header: fieldLabels.type,
        accessor: (row) => (
          <div className="flex align-center">
            <AnnouncementTypeLabel type={row.type} />
          </div>
        ),
        width: 120,
        minWidth: 120,
        align: "center",
        sortingFn: (id) => {
          if (sort.headerId === id) {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "ID_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "ID_ASC" }));
            }
          } else {
            setSort({ headerId: id, sortDirection: "ASC", orderBy: "ID_ASC" });
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
      {
        id: "default-message",
        Header: fieldLabels.message,
        accessor: (row) => (
          <AnnouncementContentTypeField
            message={row.message}
            contentType={row.contentType}
            style={{ minHeight: "100%" }}
          />
        ),
        width: 800,
        align: "left",
      },
      {
        id: "default-startDate",
        Header: fieldLabels.startDate,
        accessor: (row) => (row.startDate ? <DateTimeRenderer date={row.startDate} includeElapsed={false} /> : NotSet),
        width: 200,
        align: "left",
        sortingFn: (id) => {
          if (sort.headerId === id) {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "START_DATE_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "START_DATE_ASC" }));
            }
          } else {
            setSort({ headerId: id, sortDirection: "ASC", orderBy: "START_DATE_ASC" });
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
      {
        id: "default-endDate",
        Header: fieldLabels.endDate,
        accessor: (row) => (row.endDate ? <DateTimeRenderer date={row.endDate} includeElapsed={false} /> : NotSet),
        width: 200,
        align: "left",
        sortingFn: (id) => {
          if (sort.headerId === id) {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "END_DATE_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "END_DATE_ASC" }));
            }
          } else {
            setSort({ headerId: id, sortDirection: "ASC", orderBy: "END_DATE_ASC" });
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
      {
        id: "default-createdOn",
        Header: fieldLabels.createdOn,
        accessor: (row) => <DateTimeRenderer date={row.createdOn} includeElapsed={false} />,
        width: 200,
        align: "left",
        sortingFn: (id) => {
          if (sort.headerId === id) {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "CREATED_ON_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "CREATED_ON_ASC" }));
            }
          } else {
            setSort({ headerId: id, sortDirection: "ASC", orderBy: "CREATED_ON_ASC" });
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
      {
        id: "default-createdBy",
        Header: fieldLabels.createdBy,
        accessor: (row) => <GetPersons persons={row.createdBy} createLinks={false} />,
        width: 200,
        align: "left",
        sortingFn: (id) => {
          if (sort.headerId === id) {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "CREATED_BY_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "CREATED_BY_ASC" }));
            }
          } else {
            setSort({ headerId: id, sortDirection: "ASC", orderBy: "CREATED_BY_ASC" });
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
      {
        id: "default-modifiedOn",
        Header: fieldLabels.modifiedOn,
        accessor: (row) => <DateTimeRenderer date={row.modifiedOn} includeElapsed={false} />,
        width: 200,
        align: "left",
        sortingFn: (id) => {
          if (sort.headerId === id) {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "MODIFIED_ON_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "MODIFIED_ON_ASC" }));
            }
          } else {
            setSort({ headerId: id, sortDirection: "ASC", orderBy: "MODIFIED_ON_ASC" });
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
      {
        id: "default-modifiedBy",
        Header: fieldLabels.modifiedBy,
        accessor: (row) => <GetPersons persons={row.modifiedBy} createLinks={false} />,
        width: 200,
        align: "left",
        sortingFn: (id) => {
          if (sort.headerId === id) {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "MODIFIED_BY_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "MODIFIED_BY_ASC" }));
            }
          } else {
            setSort({ headerId: id, sortDirection: "ASC", orderBy: "MODIFIED_BY_ASC" });
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
    ];

    return headers;
  }, [fieldLabels, setSort, sort.headerId, sort.sortDirection]);
  return { columns };
};
export const AnnouncementsTable = ({
  entityApi,
  entityConstants,
  fieldLabels,
  showSidebar = true,
  permissions,
  routes,
}: EntityTableProps<"announcements"> & AnnouncementsTableOptions) => {
  const history = useHistory();
  const [showModal, setShowModal] = useState(false);
  const { bulkDeleteMutationAsync: deleteAnnouncement, isLoadingBulkDeleteMutation } = entityApi;

  const { defaults, tabStoreDefaults } = useAnnouncementsTableDefaults({ fieldLabels });

  const { selection, resultsCount, selectionPermissions, onCountChange, onSelectionChange, onSelectionPermissions } =
    useGenericVirtualizedTable<Announcement>();

  const {
    filters,
    sidebarFilters,
    forcedFilters,
    columnSetting,
    columnWidths,
    dispatchTabStore,
    currentTab,
    tabsLoading,
  } = useTabStore<Announcement, AnnouncementFilters, AnnouncementFilterForm>({
    resource: entityConstants.resource,
    defaults: tabStoreDefaults,
  });

  const { functionRef, sort, setSort, searchValue, setSearchValue } = useGenericVirtualizedTableTabs({
    tabsLoading,
    filters,
    switchSortState: switchAnnouncementsDefaultSortState,
    dispatchTabStore,
  });

  const { columns } = useAnnouncementsTableColumns({ entityConstants, fieldLabels, sort, setSort });

  const memoizedFilters = useMemo(
    () => ({ ...filters, ...forcedFilters, includeRelations: true }),
    [filters, forcedFilters]
  );

  return (
    <TableView>
      <TableView.Head>
        <TableView.Head.Label>
          <TableViewLabel entityConstants={entityConstants} resultsCount={resultsCount} />
        </TableView.Head.Label>
        <TableView.Head.Controls>
          <button
            className="btn btn-primary"
            title={`Add ${entityConstants.entitySingular}`}
            onClick={() => history.push(routes.getAddRoute)}
            disabled={!permissions.canCreate}
          >
            <LucideIcon name="plus" /> Add {entityConstants.entitySingular}
          </button>
        </TableView.Head.Controls>
      </TableView.Head>
      <TableView.Body>
        {showSidebar && (
          <TableView.Body.Sidebar>
            <AnnouncementFilterBar
              initialValues={sidebarFilters}
              dispatchTabStore={dispatchTabStore}
              tabsLoading={tabsLoading}
              currentTab={currentTab}
            />
          </TableView.Body.Sidebar>
        )}
        <TableView.Body.Content>
          <EntityTable>
            <EntityTable.Controls
              style={{
                borderTop: "0px",
                borderRadius: "0px",
              }}
            >
              <EntityFilterIndicator<Announcement, AnnouncementFilters>
                filters={filters}
                excludeFilters={{
                  includeCurrentUserHasRead: (value) => !value,
                  current: (value) => !!value,
                  includeSystemAnnouncements: (value) => !!value,
                }}
                translatorConsts={AnnouncementFiltersTranslator}
              />
              <SearchInput searchValue={searchValue} setSearchValue={setSearchValue} placeholder="Search" />
              <EntityTableEditButton
                entityConstants={entityConstants}
                routes={routes}
                permissions={permissions}
                selection={selection}
                selectionPermissions={selectionPermissions}
              />
              <MoreDropdown drop="right" btn="btn btn-ghost-secondary">
                <li>
                  <EntityTableCloneButton
                    entityConstants={entityConstants}
                    routes={routes}
                    permissions={permissions}
                    selection={selection}
                  />
                </li>
                <li>
                  <EntityTableDeleteButton
                    entityConstants={entityConstants}
                    permissions={permissions}
                    selection={selection}
                    selectionPermissions={selectionPermissions}
                    onClick={() => setShowModal(true)}
                  />
                </li>
              </MoreDropdown>
              <AlertModal
                type="danger"
                showModal={showModal}
                setShowModal={setShowModal}
                title={`${
                  selection.size === 0
                    ? "Select announcement to delete"
                    : selection.size === 1
                    ? `Delete the selected ${entityConstants.entitySingular}?`
                    : `Delete the selected ${entityConstants.entityPlural}?`
                }`}
                description={`${`Proceeding will permanently delete the selected ${entityConstants.entityPlural}.`}`}
                proceedLabel="Delete"
                onProceed={async () => {
                  await deleteAnnouncement({
                    ids: Array.from(selection),
                    goBackOnSuccess: false,
                    showToast: false,
                    entityName: entityConstants.resource,
                    params: { deletePermanently: true },
                  }).catch((e) => {});
                  functionRef.current?.resetSelection();
                  setShowModal(false);
                }}
                loading={isLoadingBulkDeleteMutation}
              />
            </EntityTable.Controls>
            <EntityTable.Body<Announcement, AnnouncementFilters>
              functionRef={functionRef}
              entityConstants={entityConstants}
              filters={memoizedFilters}
              columns={columns}
              columnSelect
              columnSetting={columnSetting}
              columnWidths={columnWidths}
              defaultColumnSettings={defaults}
              dispatchTabStore={dispatchTabStore}
              setResultsCount={onCountChange}
              onSelectionChange={onSelectionChange}
              onSelectionPermissions={onSelectionPermissions}
              showPermissionColumn
              loading={tabsLoading}
              fixedRowHeight={100}
            />
          </EntityTable>
        </TableView.Body.Content>
      </TableView.Body>
    </TableView>
  );
};
