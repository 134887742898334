import React, { useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { EquipmentFacility, equipmentsConstants } from "../../../../api/Facilities";
import { useForm } from "react-hook-form";
import { FormButtons } from "../../FormButtons";
import { FormHeader } from "../../FormHeader";
import { QuickAddChildrenProps } from "../common/QuickAddWrapper";
import { InputFormField } from "../../../formfields/InputFormField";
import { TextareaFormField } from "../../../formfields/TextareaFormField";
import { useQuickAddSubmit } from "../common/useQuickAddHooks";
import { EquipmentFieldLabels } from "../../../../api/Equipment";
import { EquipmentTagsVirtualizedSelectForm } from "../../EntityForms/formsVirtualized/EquipmentTagsVirtualizedSelectForm";
import { FormFieldLayout } from "../../../formfields/FormFieldLayouts";
import { ToggleButtonComponent } from "../../../../ViewerUIElements/ToggleButtonComponent";
import { OrganizationsVirtualizedSelectForm } from "../../EntityForms/formsVirtualized/OrganizationsVirtualizedSelectForm";
import { PersonsVirtualizedSelectForm } from "../../EntityForms/formsVirtualized/PersonsVirtualizedSelectForm";
import { phoneNumberRegex } from "../../../misc/RegularExpressionConstats";

export const QuickAddEquipmentForm = ({ onCreate, onCancel }: QuickAddChildrenProps<EquipmentFacility>) => {
  const [isObsolete, setIsObsolete] = useState<boolean>(false);

  const { onSubmit, loading } = useQuickAddSubmit<EquipmentFacility>({
    resource: equipmentsConstants.resource,
    onCreate: onCreate,
    onCancel: onCancel,
  });

  const EquipmentFormSchema = yup.object().shape({
    name: yup.string().required("Equipment name is required").typeError("Equipment name is required"),
    localPhone: yup
      .string()
      .nullable()
      .optional()
      .matches(phoneNumberRegex, { message: "Invalid phone number", excludeEmptyString: true })
      .typeError("Invalid phone number")
      .default(""),
  });

  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
  } = useForm<EquipmentFacility>({
    defaultValues: {},
    resolver: yupResolver(EquipmentFormSchema),
  });

  return (
    <>
      <FormHeader title={"Add suppl. equipment"} subtitle={""} />
      <InputFormField
        id="name"
        label={EquipmentFieldLabels.name}
        errors={errors}
        register={register}
        autoFocus={true}
        required={true}
        placeholder="Enter equipment name..."
      />

      <EquipmentTagsVirtualizedSelectForm
        id="equipmentTag"
        label={EquipmentFieldLabels.equipmentTag}
        control={control}
        horizontal
        allowCreateEntity
      />

      <FormFieldLayout id="isObsolete" label="Obsolete" hasLabel horizontal>
        <div className="flex align-center gap-5" style={{ padding: "10px 0" }}>
          <ToggleButtonComponent checked={isObsolete} setChecked={setIsObsolete}>
            <></>
          </ToggleButtonComponent>
          {isObsolete && <div className="label label-soft-danger">obsolete</div>}
        </div>
      </FormFieldLayout>

      <InputFormField
        id="serialnumber"
        label={EquipmentFieldLabels.serialnumber}
        errors={errors}
        register={register}
        placeholder="Enter serialnumber..."
      />

      <InputFormField
        id="model"
        label={EquipmentFieldLabels.model}
        errors={errors}
        register={register}
        placeholder="Enter model..."
      />

      <InputFormField
        id="room"
        label={EquipmentFieldLabels.room}
        errors={errors}
        register={register}
        placeholder="Enter room..."
      />

      <InputFormField
        id="localPhone"
        label={EquipmentFieldLabels.localPhone}
        errors={errors}
        register={register}
        placeholder="Enter local phone number..."
      />

      <OrganizationsVirtualizedSelectForm
        id="homeLab"
        label={EquipmentFieldLabels.homeLab}
        control={control}
        horizontal
        allowCreateEntity
      />

      <PersonsVirtualizedSelectForm
        id="homeLabContacts"
        label={EquipmentFieldLabels.homeLabContacts}
        control={control}
        horizontal
        isMulti
        allowCreateEntity
      />

      <OrganizationsVirtualizedSelectForm
        id="company"
        label={EquipmentFieldLabels.company}
        control={control}
        horizontal
        allowCreateEntity
      />

      <PersonsVirtualizedSelectForm
        id="companyContacts"
        label={EquipmentFieldLabels.companyContacts}
        control={control}
        horizontal
        isMulti
        allowCreateEntity
      />

      <TextareaFormField
        id="notes"
        label={EquipmentFieldLabels.notes}
        errors={errors}
        register={register}
        placeholder="Enter notes..."
      />
      <FormButtons
        // groupName={}
        entityId={undefined}
        onClose={onCancel}
        onSubmit={handleSubmit((entity) => onSubmit({ ...entity, isObsolete: isObsolete }))}
        disabled={isSubmitting}
        errors={errors}
        submitButtonLabel="Create"
        loading={loading}
      />
    </>
  );
};
