import produce from "immer";
import { useCallback, useEffect, useState } from "react";
import { ImageComponent } from "../Images/ImageComponent";
// import { ImageComponent } from "../ImageViewer/ImageComponent";
import { PDFComponent } from "../PDFViewer/PDFComponent";
import { SpectrumComponent } from "../SpectrumViewer/SpectrumComponent";
import { TableComponent } from "../TableViewer/TableComponent";
import { useFieldsUpdated, useTranslatedFieldUpdate } from "./ViewerLayoutHooks";
import { ViewerInitState, viewerMapping, viewerPropsType, viewerType } from "./ViewerLayoutTypes";
import { MoleculeComponent } from "../Images/MoleculeComponent";

type propsType = viewerPropsType & { activeViewer: viewerType | undefined };

export const ViewerSelector = (props: propsType) => {
  // const [viewer, setViewer] = useState<viewerComponets>();
  const { activeViewer, tracks, setTracksToZoom } = props;
  const [viewerState, setViewerState] = useState<Record<string, ViewerInitState>>({});
  const [initViewerState, setInitViewerState] = useState<ViewerInitState>();

  const active = useFieldsUpdated(activeViewer);
  const updateViewerState = useCallback(
    (state: ViewerInitState) => {
      setViewerState((v) =>
        produce(v, (next) => {
          if (active) next[active] = state;
        })
      );
    },
    [active]
  );

  const viewer = useTranslatedFieldUpdate(
    active,
    (activeViewer: viewerType | undefined) => viewerMapping[activeViewer ?? "unknown"],
    undefined
  );

  useEffect(() => {
    if (active) {
      if (active in viewerState) setInitViewerState(viewerState[active]);
      else {
        if (setTracksToZoom)
          setTracksToZoom(
            Object.keys(tracks).filter((id) => tracks[id].settings.visible && tracks[id].settings.active)
          );
      }
    }
  }, [active, setTracksToZoom]);

  const v = useCallback(
    (props: viewerPropsType) => {
      switch (viewer) {
        case "SpectrumComponent":
          return <SpectrumComponent {...props} />;
        case "ImageComponent":
          return <ImageComponent {...props} />;
        case "MoleculeComponent":
          return <MoleculeComponent {...props} />;
        // return <ImageComponent {...props} />;
        case "PDFComponent":
          return <PDFComponent {...props} />;
        case "TableComponent":
          return <TableComponent {...props} />;
        // case "SequenceComponent":
        //   return <SequenceComponent {...props} />;
        default:
          return null;
      }
      // console.log(">>", setTrackMode);
    },
    [viewer]
  );

  props = Object.assign({}, props);
  props.setViewerState = updateViewerState;
  props.initViewerState = initViewerState;

  return v(props);
};
