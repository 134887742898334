import React, { CSSProperties } from "react";
import { IEntityMinimalModel, IdTypes, StringIndexedDict, IGenericRequestParameters } from "../../../api/GenericTypes";
import { MoreDropdown } from "../MoreDropdown/MoreDropdown";
import { Icon } from "@remirror/react";

interface OrderBySelectDropdownProps<
  Entity extends IEntityMinimalModel<IdTypes>,
  Filters extends StringIndexedDict & IGenericRequestParameters<Entity, Filters["orderBy"]>
> {
  btnCls?: string;
  orderByOptions: readonly Filters["orderBy"][];
  currentFilters: Filters;
  setCurrentFilters: React.Dispatch<React.SetStateAction<Filters>>;
  drop?: "left" | "right";
  listContainerStyle?: CSSProperties;
}
export const OrderBySelectDropdown = <
  Entity extends IEntityMinimalModel<IdTypes>,
  Filters extends StringIndexedDict & IGenericRequestParameters<Entity, Filters["orderBy"]>
>({
  btnCls = "btn btn-sm btn-default",
  orderByOptions,
  currentFilters,
  setCurrentFilters,
  drop,
  listContainerStyle,
}: OrderBySelectDropdownProps<Entity, Filters>) => {
  const options = Array.from(
    new Set(orderByOptions.map((opt) => opt).map((opt) => (opt as string).replace("_ASC", "").replace("_DESC", "")))
  );

  const currentOrderBy = (currentFilters.orderBy as string)?.replace("_ASC", "").replace("_DESC", "");
  const currentOrderByDirection = (currentFilters.orderBy as string)?.includes("_DESC") ? "DESC" : "ASC";

  return (
    <MoreDropdown
      wrapperCls="btn-group"
      drop={drop}
      btn="btn btn-default"
      title="Sorting"
      button={(onDropDownToggle, disabled) => (
        <>
          <button
            className={`${btnCls} ${disabled ? "disabled" : ""}`}
            onClick={onDropDownToggle}
            title="Sorting options"
          >
            {`${currentOrderBy ? `Sort by: ${currentOrderBy.replaceAll("_", " ")}` : "Sort by"}`}
          </button>
          <button
            className={`${btnCls} dropdown-toggle ${disabled ? "disabled" : ""}`}
            onClick={() => {
              setCurrentFilters((prev) => ({
                ...prev,
                orderBy: currentOrderBy + `${currentOrderByDirection === "ASC" ? "_DESC" : "_ASC"}`,
              }));
            }}
            title="Sort direction"
            disabled={!currentOrderBy}
            style={{ display: "flex", alignItems: "center" }}
          >
            {currentOrderByDirection === "DESC"
              ? Icon({ name: "sortDesc", size: 16 })
              : Icon({ name: "sortAsc", size: 16 })}
            <span className="sr-only">Sort direction</span>
          </button>
        </>
      )}
    >
      <div style={listContainerStyle}>
        {options?.map((opt, index) => (
          <li key={index}>
            <button
              className={`btn btn-ghost-secondary ${currentOrderBy === opt && "focus"}`}
              onClick={() => {
                setCurrentFilters((prev) => ({ ...prev, orderBy: opt + "_" + currentOrderByDirection }));
              }}
            >
              {opt.replaceAll("_", " ")}
            </button>
          </li>
        ))}
      </div>
    </MoreDropdown>
  );
};
