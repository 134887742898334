import { useCallback, useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { API } from "../../api/Api";
import { SessionContext } from "../../common/contexts/SessionContext";
import { LucideIcon } from "../../common/icon/LucideIcon";
import { AlertModal } from "../../common/modals/AlertModal/AlertModal";
import { showtoast } from "../../common/overlays/Toasts/showtoast";
import FullDetail from "../../common/panels/FullDetail/FullDetail";
import { getDetailLink, getIndexRoute } from "../../main/Routing";
import { DownloadDataset } from "../common/DownloadDataset/DownloadDataset";
import { datasetsConstants } from "../../api/Datasets";
import { MoreDropdown } from "../../common/buttons/MoreDropdown/MoreDropdown";
import { SharedContentModal } from "../../common/helpers/EntityModals/SharedContentModal";
import { Button } from "../../common/buttons/Button/Button";
import { DetailsPageTopControlsView } from "../../common/panels/misc/DetailsPageTopControlsView/DetailsPageTopControlsView";
import { EntityDetailProps } from "../../common/entity/EntityInterfaces";
import { EntityRestoreButton } from "../../common/entity/entityComponents/EntityRestoreButton";
// import { MoreDropdown } from "../common/MoreDropdown/MoreDropdown";

interface Props {
  api: API;
  printCallback: () => void;
  viewViewer: boolean;
}
export const Navigation = ({
  entity,
  entityApi,
  entityConstants,
  fieldLabels,
  permissions,
  routes,
  api,
  printCallback,
  viewViewer,
}: Props & EntityDetailProps<"datasets">) => {
  const history = useHistory();
  const { route, session } = useContext(SessionContext);
  // const [showDatasets, setShowDatasets] = useState(false);

  const { deleteMutationAsync, editMutationAsync, isLoadingDeleteMutation, isLoadingEditMutation } = entityApi;

  const [showModal, setShowModal] = useState(false);

  const deleteHandle = useCallback(() => {
    setShowModal(() => true);
  }, []);

  // const setShowDatasetsCallback = useCallback(() => {
  //   setShowDatasets(() => !showDatasets);
  // }, [showDatasets]);

  const editHandler = useCallback(() => history.push(routes.getEditLink(entity.id)), [entity, history, routes]);

  const claimHandler = useCallback(
    () => history.push(route(`${getIndexRoute(entityConstants.frontendIndexRoute)}/${entity.id}/claim`)),
    [entity.id, entityConstants.frontendIndexRoute, history, route]
  );

  const unclaimHandler = useCallback(async () => {
    if (entity) {
      await editMutationAsync(
        { body: { ...entity, claimed: false }, id: entity.id },
        {
          onSuccess: () => {
            showtoast("success", `Unclaimed Dataset-ID: ${entity.id}.`);
          },
        }
      ).catch(() => {});
    }
  }, [entity, editMutationAsync]);

  return (
    <FullDetail.Navigation style={{ display: "flex", flexFlow: "row-nowrap", alignItems: "center" }}>
      <DetailsPageTopControlsView
        entity={entity}
        entityConstants={datasetsConstants}
        containerStyle={{ borderBottom: "0px" }}
        // createdByOverride={entity.owner}
        // createdOnOverride={entity.dateAdded}
        attributes={
          <>
            {entity.isDeleted && (
              <h2 style={{ margin: 0 }}>
                <label className="label label-sm label-soft-warning" style={{ margin: 0 }} title={"Trashed"}>
                  <LucideIcon name="trash" />
                </label>
              </h2>
            )}
          </>
        }
        controlsLeft={
          <>
            {viewViewer && (
              <button
                className={"btn btn-primary"}
                onClick={() => {
                  // context.fullscreen.set({
                  //   ...context.fullscreen.get,
                  //   state: !context.fullscreen.get.state,
                  //   component: "",
                  // });
                  history.push(route(getDetailLink("datasets", entity.id)));
                }}
                style={{ margin: 0 }}
              >
                <LucideIcon name="chevrons-left" /> Show details
              </button>
            )}
          </>
        }
        controls={
          <>
            <button
              className={`btn btn-sm btn-ghost-${entity.isDeleted ? "danger" : "warning"}`}
              title={`${entity.isDeleted ? "Delete" : "Move to trash"}`}
              onClick={deleteHandle}
              disabled={!permissions.canDelete(entity)}
            >
              {entity.isDeleted ? (
                <>
                  <LucideIcon name="trash-2" /> Delete
                </>
              ) : (
                <LucideIcon name="trash" />
              )}
            </button>
            {entity.isDeleted && (
              <EntityRestoreButton
                entity={entity}
                entityConstants={entityConstants}
                entityApi={entityApi}
                permissions={permissions}
              />
            )}
            {/* <button className={"btn btn-sm btn-ghost-secondary"} title="Print" onClick={printCallback}>
              <FeatherIcon name="printer" />
            </button> */}
            {!entity.isDeleted && (
              <button
                className={"btn btn-sm btn-default"}
                onClick={editHandler}
                disabled={!permissions.canEdit(entity)}
              >
                <LucideIcon name="external-link" /> Edit
              </button>
            )}
            {!entity.claimed && !entity.isDeleted && (
              <button
                className={`btn btn-sm btn-success`}
                title={"Claim dataset"}
                onClick={async () => await claimHandler()}
                disabled={!permissions.canEdit(entity)}
              >
                <LucideIcon name="paperclip" /> Claim
              </button>
            )}
            <DownloadDataset api={api} id={entity.id} />
            <MoreDropdown drop="right">
              <li>
                <SharedContentModal datasetIds={[entity.id]} disabled={!session?.permissions.add_bundles} />
              </li>
              {entity.claimed && (
                <li>
                  <Button
                    className={`btn btn-sm btn-ghost-danger`}
                    title={"Unclaim dataset"}
                    onClick={async () => await unclaimHandler()}
                    disabled={!permissions.canEdit(entity)}
                    loading={isLoadingEditMutation}
                  >
                    <LucideIcon name="circle-x" /> Unclaim
                  </Button>
                </li>
              )}
            </MoreDropdown>
            <AlertModal
              type={`${entity.isDeleted ? "danger" : "warning"}`}
              showModal={showModal}
              setShowModal={setShowModal}
              title={`${entity.isDeleted ? `Permanently delete ${entityConstants.entitySingular}` : "Move to trash?"}`}
              description={
                entity.isDeleted
                  ? `This ${entityConstants.entitySingular} may be referenced by other entities. All references will be lost if you proceed.`
                  : `This ${entityConstants.entitySingular} may be referenced by other entities. Trashing a dataset does not delete the files or references, but other functionalities may be limited.`
              }
              proceedLabel={entity.isDeleted ? "Delete" : "Trash"}
              onProceed={async () => {
                if (entity.isDeleted) {
                  await deleteMutationAsync(
                    {
                      id: entity.id,
                      goBackOnSuccess: false,
                      showToast: false,
                      entityName: "dataset",
                      params: { deletePermanently: true },
                    },

                    {
                      onSuccess: () => {
                        showtoast("success", `Deleted Dataset-ID: ${entity.id}.`);
                        history.goBack();
                      },
                    }
                  ).catch((e) => {});
                } else {
                  await deleteMutationAsync(
                    { id: entity.id, goBackOnSuccess: false, showToast: false, entityName: "dataset" },

                    {
                      onSuccess: () => {
                        showtoast("success", `Moved Dataset-ID: ${entity.id} to trash.`);
                        setShowModal(false);
                      },
                    }
                  ).catch((e) => {});
                }
              }}
              loading={isLoadingDeleteMutation}
            />
          </>
        }
      />
    </FullDetail.Navigation>
  );
};
