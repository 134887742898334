import { generateUid } from "../../tools/UID/UID";
import { argumentType, commandTypes, trackListType } from "../PipelineTypes";
import { StackTraces } from "../StackTrace";
import { Empty } from "./Empty";

export class AutophaseNMRSpectrum extends Empty {
  id: string;
  name: string;
  readonly type = commandTypes.autophaseNMRSpectrum;
  errors: StackTraces;
  warnings: StackTraces;

  parameterTypes = {};
  parameterSettings = {};

  internalParameter: Record<string, any>;
  readonly input: argumentType[];
  readonly output: argumentType[];

  constructor() {
    super();
    this.id = generateUid();
    this.name = "Autophased NMR spectrum";
    this.errors = new StackTraces();
    this.warnings = new StackTraces();
    this.internalParameter = {};
    this.input = [
      { name: "FID real", type: "1D_real" },
      { name: "FID imaginary", type: "1D_real" },
    ];
    this.output = [{ name: "Spectrum", type: "1D_real" }];
  }

  run(tracks: trackListType): trackListType {
    // if (!tracks?.[0]?.data || !tracks?.[1]?.data) {
    //   this.errors.create({
    //     id: this.id,
    //     component: "AutophaseNMRSpectrum.run",
    //     message: `Some tracks do not contain any data.`,
    //   });
    //   return [];
    // }

    // const reIn: Data1DReal = tracks[0].data;
    // const imIn: Data1DReal = tracks[1].data;

    // const { phi0: phase0, phi1: phase1, pivot } = acme(reIn, imIn, Infinity); // Autophase
    // phaseSpectrum(reIn, imIn, reIn.y, phase0, phase1, pivot);
    // tracks[0].label = "Spectrum";

    return [tracks[0]];
  }
}
