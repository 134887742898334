import { useEffect, useState } from "react";
import { Nav, NavItem } from "react-bootstrap";

import { viewerType, viewerTypeRecord } from "./ViewerLayoutTypes";

export const TrackTypeTabComponent = ({
  viewerTypes,
  activeViewer,
  setActiveViewer,
}: {
  viewerTypes: Record<viewerType, viewerTypeRecord>;
  activeViewer?: viewerType;
  setActiveViewer?: (activeViewer: viewerType) => void;
}) => {
  const [key, setKey] = useState(activeViewer);

  useEffect(() => {
    setKey(activeViewer);
  }, [activeViewer]);

  if (Object.keys(viewerTypes).length < 2) return <div />;

  const tabs: any = Object.entries(viewerTypes)
    .filter(([t]) => t !== "unknown")
    .map(([t, type]) => (
      <NavItem eventKey={t} key={t}>
        {type.name}
      </NavItem>
    ));

  return (
    <div style={{ paddingTop: "5px" }}>
      <Nav
        bsStyle="tabs"
        activeKey={key}
        onSelect={(k: any) => {
          setKey(k);
          if (setActiveViewer) setActiveViewer(k);
        }}
        style={{ padding: "0px 5px" }}
      >
        {tabs}
      </Nav>
    </div>
  );
};

export const TrackTypeTab = TrackTypeTabComponent;
