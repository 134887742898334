import { yupResolver } from "@hookform/resolvers/yup";
import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { ToggleButtonComponent } from "../ViewerUIElements/ToggleButtonComponent";
import { IEntityMinimalModel, IResultModel } from "../api/GenericTypes";
import { PersonFilters, PersonWriteModel, personsConstants } from "../api/Person";
import { FormFieldLayout } from "../common/formfields/FormFieldLayouts";
import { InputFormField } from "../common/formfields/InputFormField";
import { SelectFormField } from "../common/formfields/SelectFormField";
import { TextareaFormField } from "../common/formfields/TextareaFormField";
import { FormButtons } from "../common/forms/FormButtons";
import { FormHeader } from "../common/forms/FormHeader";
import styles from "../common/forms/forms.module.css";
import { useDebouncedValue } from "../common/helperfunctions/useDebouncedValue";
import { SessionContext } from "../common/contexts/SessionContext";
import { PasswordFormField } from "../common/formfields/PasswordFormField";
import { RolesVirtualizedSelectForm } from "../common/forms/EntityForms/formsVirtualized/RolesVirtualizedSelectForm";
import { PersonTagsVirtualizedSelectForm } from "../common/forms/EntityForms/formsVirtualized/PersonTagsVirtualizedSelectForm";
import { OrganizationsVirtualizedSelectForm } from "../common/forms/EntityForms/formsVirtualized/OrganizationsVirtualizedSelectForm";
import { CustomTypedEntityFormProps } from "../common/entity/EntityInterfaces";
import { LucideIcon } from "../common/icon/LucideIcon";
import { EntityCustomTypeForm, useCustomTypeForm } from "../Customization/CustomTypes/generics/useCustomTypeForm";
import { phoneNumberRegex } from "../common/misc/RegularExpressionConstats";
import { TotpForm, TwoFactorAuthIndicator } from "./TotpModal";
import { useInvalidateQueries, usePostMutation } from "../api/BaseEntityApi";
import { ResourceName } from "../main/Routing";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { documentationConstants } from "../api/Misc";
import { LoginRequest } from "../api/Login";

export const SALUTATIONS: IEntityMinimalModel[] = [
  { id: 1, name: "Dr." },
  { id: 2, name: "Other" },
  { id: 3, name: "Mr." },
  { id: 4, name: "Ms." },
  { id: 5, name: "Prof." },
];

export const PersonFormSchema = {
  lastName: yup.string().required("Last name is required").typeError("Last name is required"),
  email: yup
    .string()
    .nullable()
    .optional()
    .email("Invalid E-mail address")
    .typeError("Invalid E-mail address")
    .default(""),
  phone: yup
    .string()
    .nullable()
    .optional()
    .matches(phoneNumberRegex, { message: "Invalid phone number", excludeEmptyString: true })
    .typeError("Invalid phone number")
    .default(""),
  officePhone: yup
    .string()
    .nullable()
    .optional()
    .matches(phoneNumberRegex, { message: "Invalid phone number", excludeEmptyString: true })
    .typeError("Invalid phone number")
    .default(""),
  web: yup.string().nullable().optional().url("Invalid URL").typeError("Invalid URL").default(""),
};

interface PasswordValidation {
  regex: RegExp;
  message: string;
  match?: boolean;
}

const PasswordCharacterClasses: PasswordValidation[] = [
  { regex: /[a-z]+/, message: "Lowercase letters (a-z)" },
  { regex: /[A-Z]+/, message: "Uppercase letters (A-Z)" },
  { regex: /[0-9]+/, message: "Numbers (0-9)" },
  { regex: /[_/\W]+/, message: "Special characters (e.g., !, @, #, $, %, ^, &)" },
];
const PasswordMinimumCharacterClassesRequired = 2;
const PasswordMinimumLength = 8;

export const PersonForm = ({
  fieldLabels,
  permissions,
  title,
  subtitle,
  initialValues,
  onSubmit,
  onCancel,
  loading,
  submitButtonLabel,
  event,
  typeId,
}: CustomTypedEntityFormProps<"persons">) => {
  const { api, session } = useContext(SessionContext);
  const enable2FA = session?.features.enable_2fa;
  const { mutateAsync: deleteTotpMutation } = usePostMutation({ resource: "totp/remove" as ResourceName });
  const invalidateQueries = useInvalidateQueries(personsConstants.resource);
  const [createAccount, setCreateAccount] = useState<boolean>(!!initialValues?.login);
  const [loginDisabled, setLoginDisabled] = useState<boolean>(initialValues?.accountState === "Disabled");
  const [isSystemUser, setIsSystemUser] = useState<boolean>(!!initialValues?.isSystemUser);
  const [loginState, setLoginState] = useState<string>("");
  const [oldPasswordState, setOldPasswordState] = useState<string>("");

  const [changeLogin, setChangeLogin] = useState<boolean>(event === "CLONE" && !!initialValues?.login);
  const [changePassword, setChangePassword] = useState<boolean>(false);

  const [passwordValidationInfo, setPasswordValidationInfo] = useState<PasswordValidation[]>();

  const [showTotpModal, setTotpModal] = useState<boolean>(false);

  useEffect(() => {
    setCreateAccount(!!initialValues?.login);
  }, [initialValues?.login]);

  useEffect(() => {
    setLoginDisabled(initialValues?.accountState === "Disabled");
  }, [initialValues?.accountState]);

  useEffect(() => {
    setIsSystemUser(!!initialValues?.isSystemUser);
  }, [initialValues?.isSystemUser]);

  const UserFormSchema = {
    lastName: yup.string().required("Last name is required").typeError("Last name is required"),
    email: yup
      .string()
      .nullable()
      .required("E-mail is required")
      .email("Invalid E-mail address")
      .typeError("Invalid E-mail address")
      .default(""),
    phone: yup
      .string()
      .nullable()
      .optional()
      .matches(phoneNumberRegex, { message: "Invalid phone number", excludeEmptyString: true })
      .typeError("Invalid phone number")
      .default(""),
    officePhone: yup
      .string()
      .nullable()
      .optional()
      .matches(phoneNumberRegex, { message: "Invalid phone number", excludeEmptyString: true })
      .typeError("Invalid phone number")
      .default(""),
    web: yup.string().nullable().optional().url("Invalid URL").typeError("Invalid URL").default(""),
    login: yup
      .string()
      .required("Login is required")
      .typeError("Login is required")
      .test({
        test: async () => {
          if (!loginState) return false;
          if (loginState === initialValues?.login && event !== "CLONE") return true;
          const existingLogins = (
            (await api.post("persons/ids_only", { logins: [loginState] } as PersonFilters, {})) as IResultModel<number>
          )?.results;
          return !!existingLogins && !existingLogins.length;
        },
        message: "Login already in use",
      }),
    oldPassword: yup
      .string()
      .default("")
      .when("password", (password, field) =>
        changePassword && !!session?.userId && session.userId === initialValues?.id
          ? field.required("Incorrect password").test({
              test: async () => {
                if (!initialValues?.login) return false;
                if (!oldPasswordState) return false;
                const loginValidated = (await api.post(
                  "login/validateLoginData",
                  { username: initialValues.login, password: oldPasswordState } as LoginRequest,
                  {}
                )) as boolean;
                return !!loginValidated;
              },
              message: "Incorrect password",
            })
          : field.optional()
      ),
    password:
      (createAccount && !initialValues?.login) || changePassword
        ? yup
            .string()
            .required("New password required")
            .min(PasswordMinimumLength, `Password must be at least ${PasswordMinimumLength} characters long`)
            .test({
              test: (value) => {
                if (!!value) {
                  const validClasses = PasswordCharacterClasses.filter((p) => p.regex.test(value));
                  if (validClasses.length >= PasswordMinimumCharacterClassesRequired) {
                    return true;
                  }
                }
                return false;
              },
              message: `Invalid password. Password must contain at least ${PasswordMinimumCharacterClassesRequired} different character classes`,
            })
            .default("")
        : yup.string().optional().default(""),
    passwordConfirmation: yup
      .string()
      .nullable()
      .oneOf([yup.ref("password"), null], "Password confirmation does not match")
      .default(""),
    roles: yup
      .array()
      .min(1, "Select at least one role")
      .required("Select at least one role")
      .typeError("Select at least one role"),
    isSystemUser: yup.bool().optional().default(false),
  };

  const { defaultValues, typedFormSchema, processCustomFields, type, setType, types } = useCustomTypeForm({
    initialValues: {
      ...initialValues,
      salutation: SALUTATIONS.find((s) => s.name === initialValues?.salutation) as any,
    },
    formSchema: createAccount ? UserFormSchema : PersonFormSchema,
    typeId: typeId,
    entityType: "Person",
  });

  const {
    register,
    handleSubmit,
    watch,
    control,
    resetField,
    setError,
    clearErrors,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm<Partial<PersonWriteModel>>({
    values: defaultValues,
    resolver: yupResolver(typedFormSchema),
  });

  const loginValue = watch("login");
  const debouncedLoginValue = useDebouncedValue(loginValue, 300);

  useEffect(() => {
    setLoginState(debouncedLoginValue || "");
  }, [debouncedLoginValue]);

  const oldPasswordValue = watch("oldPassword");
  const debouncedOldPasswordValue = useDebouncedValue(oldPasswordValue, 300);

  useEffect(() => {
    setOldPasswordState(debouncedOldPasswordValue || "");
  }, [debouncedOldPasswordValue]);

  const passwordValue = watch("password");
  useEffect(() => {
    setPasswordValidationInfo(
      PasswordCharacterClasses.map((p) => ({ ...p, match: !!passwordValue && p.regex.test(passwordValue) }))
    );
  }, [passwordValue]);

  useEffect(() => {
    clearErrors("login");
    clearErrors("accountState");
    clearErrors("roles");
    clearErrors("isSystemUser");

    setChangePassword(false);
  }, [clearErrors, createAccount, setValue]);

  useEffect(() => {
    setValue("oldPassword", undefined);
    clearErrors("password");
    setValue("password", undefined);
    clearErrors("passwordConfirmation" as any);
    setValue("passwordConfirmation" as any, undefined);
  }, [clearErrors, setValue, changePassword]);

  const [activeCharacterClasses, setActiveCharacterClasses] = useState<number>(0);
  useEffect(() => {
    setActiveCharacterClasses(passwordValidationInfo?.filter((v) => v.match).length ?? 0);
  }, [passwordValidationInfo]);

  useEffect(() => {
    if (
      changePassword &&
      !!passwordValue &&
      (activeCharacterClasses < PasswordMinimumCharacterClassesRequired ||
        passwordValue?.length < PasswordMinimumLength)
    )
      setError("password", { message: "Invalid password", type: "validate" });
    else clearErrors("password");
  }, [activeCharacterClasses, changePassword, clearErrors, passwordValue, setError]);

  return (
    <>
      <FormHeader title={title} subtitle={subtitle} />
      <form
        onSubmit={(e) => {
          e.preventDefault();
        }}
        autoComplete="off"
        className={`form-horizontal ${styles.form_holder}`}
      >
        <fieldset>
          <legend className="col-md-offset-2 col-md-10">Account details</legend>
        </fieldset>

        {!!session?.permissions.administer_persons && (!initialValues?.login || event === "CLONE") && (
          <FormFieldLayout id="createAccount" label="Create account" hasLabel horizontal>
            <div className="flex align-center" style={{ padding: "10px 0" }}>
              <ToggleButtonComponent checked={createAccount} setChecked={setCreateAccount}>
                <></>
              </ToggleButtonComponent>
            </div>
          </FormFieldLayout>
        )}

        {createAccount && (
          <>
            <InputFormField
              id="login"
              label={fieldLabels.login}
              errors={errors}
              register={register}
              required={createAccount}
              autoComplete="username"
              readOnly={!!initialValues?.login && !changeLogin}
              placeholder="Enter login name..."
              autoFocus={!initialValues}
              buttons={
                event === "EDIT" && !!session?.permissions.administer_persons && initialValues?.login ? (
                  <>
                    {changeLogin ? (
                      <button
                        type="button"
                        className="btn btn-default flex align-center gap-5"
                        onClick={() => {
                          resetField("login");
                          setChangeLogin(false);
                        }}
                      >
                        <LucideIcon name="x" />
                        Cancel change login
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="btn btn-default flex align-center gap-5"
                        onClick={() => setChangeLogin(true)}
                      >
                        <LucideIcon name="square-pen" />
                        Change login
                      </button>
                    )}
                  </>
                ) : undefined
              }
            />

            {session?.userId !== initialValues?.id && (
              <FormFieldLayout id="accountState" label="Disable account" hasLabel horizontal>
                <div className="flex align-center gap-5" style={{ padding: "10px 0" }}>
                  <ToggleButtonComponent checked={loginDisabled} setChecked={setLoginDisabled}>
                    <></>
                  </ToggleButtonComponent>
                  {loginDisabled && <div className="label label-soft-danger">Account disabled</div>}
                </div>
              </FormFieldLayout>
            )}

            <RolesVirtualizedSelectForm
              id="roles"
              label={fieldLabels.roles}
              control={control}
              disabled={!session?.permissions.administer_persons}
              required={createAccount}
              horizontal
              isMulti
              filters={{ includeInternals: !!session?.permissions.logsadmin }}
              children={
                <a
                  href="https://logs-support.sciy.com/kb/article/332-permissions-and-roles/"
                  target="_blank"
                  rel="noreferrer"
                  style={{ paddingLeft: "5px" }}
                >
                  <OverlayTrigger
                    placement="bottom"
                    overlay={<Popover id="popover-info">Click to learn more about roles and permissions.</Popover>}
                  >
                    <LucideIcon name={documentationConstants.icon} color={"var(--gray-400)"} />
                  </OverlayTrigger>
                </a>
              }
            />

            {!!session?.permissions.logsadmin && (
              <FormFieldLayout id="isSystemUser" label={fieldLabels.isSystemUser} hasLabel horizontal>
                <div className="flex align-center gap-5" style={{ padding: "10px 0" }}>
                  <ToggleButtonComponent checked={isSystemUser} setChecked={setIsSystemUser}>
                    <></>
                  </ToggleButtonComponent>
                  {isSystemUser && <div className="label label-soft-secondary">System user</div>}
                </div>
              </FormFieldLayout>
            )}

            {(createAccount && (!initialValues?.login || event === "CLONE")) || changePassword ? (
              <>
                {event === "EDIT" && changePassword && session?.userId === initialValues?.id && (
                  <PasswordFormField
                    id="oldPassword"
                    label="Old password"
                    required
                    errors={errors}
                    register={register}
                    autoComplete="password"
                    placeholder="Enter password..."
                  />
                )}

                <PasswordFormField
                  id="password"
                  label="Password"
                  required
                  errors={errors}
                  register={register}
                  autoComplete="new-password"
                  placeholder="Enter password..."
                />

                {!!passwordValidationInfo?.length ? (
                  <FormFieldLayout id="" label="" horizontal>
                    <div style={{ color: "var(--gray-500)", fontStyle: "italic" }}>
                      <div className="flex align-center">
                        {`Note: Password must be at least 8 characters long and include characters from at least ${PasswordMinimumCharacterClassesRequired} of the following character classes:`}
                      </div>
                      <div style={{ marginLeft: "20px", marginTop: "5px" }}>
                        {passwordValidationInfo?.map((v, i) => (
                          <div key={i} className="flex align-center gap-5">
                            <LucideIcon
                              name={!!v.match ? "check" : "x"}
                              color={
                                !!v.match
                                  ? "var(--success)"
                                  : activeCharacterClasses >= PasswordMinimumCharacterClassesRequired
                                  ? "var(--warning)"
                                  : "var(--danger)"
                              }
                            />
                            {v.message}
                          </div>
                        ))}
                      </div>
                    </div>
                  </FormFieldLayout>
                ) : null}

                <PasswordFormField
                  id="passwordConfirmation"
                  label="Confirm password"
                  required
                  errors={errors}
                  register={register}
                  autoComplete="new-password"
                  placeholder="Enter password confirmation..."
                />

                {!!initialValues?.login && event === "EDIT" && (
                  <FormFieldLayout id="" label="" horizontal hasLabel>
                    <button
                      className="btn btn-default flex align-center gap-5"
                      onClick={() => setChangePassword(false)}
                      type="button"
                    >
                      <LucideIcon name="x" />
                      Cancel password change
                    </button>
                  </FormFieldLayout>
                )}
              </>
            ) : (
              <>
                {!!initialValues?.isLocalUser && !!initialValues?.login && event === "EDIT" && (
                  <FormFieldLayout id="" label="Password" horizontal hasLabel>
                    <button
                      className="btn btn-default flex align-center gap-5"
                      onClick={() => setChangePassword(true)}
                      type="button"
                    >
                      <LucideIcon name="square-pen" />
                      Change password
                    </button>
                  </FormFieldLayout>
                )}
              </>
            )}
            {enable2FA && !!initialValues?.isLocalUser && initialValues?.id && (
              <>
                <FormFieldLayout id="" label={fieldLabels.has2FA} horizontal hasLabel>
                  <div className="flex row-nowrap align-center gap-5">
                    <TwoFactorAuthIndicator enabled={!!initialValues.has2FA} />
                    {initialValues?.id && initialValues.has2FA ? (
                      <button
                        className="btn btn-danger"
                        title="Configure two-factor authentication"
                        onClick={async () =>
                          await deleteTotpMutation(
                            { body: { accountId: initialValues?.id } },
                            {
                              onSuccess: () => {
                                invalidateQueries();
                              },
                            }
                          ).catch(() => {})
                        }
                        type="button"
                        style={{ marginLeft: "auto" }}
                      >
                        <LucideIcon name="shield-off" /> Remove 2FA
                      </button>
                    ) : (
                      <button
                        className="btn btn-success"
                        title="Configure two-factor authentication"
                        onClick={() => setTotpModal(true)}
                        type="button"
                        style={{ marginLeft: "auto" }}
                      >
                        <LucideIcon name="shield-check" /> Configure 2FA
                      </button>
                    )}
                  </div>
                </FormFieldLayout>
              </>
            )}

            <hr style={{ borderColor: "transparent" }} />
          </>
        )}

        <fieldset>
          <legend className="col-md-offset-2 col-md-10">Person details</legend>
        </fieldset>

        <InputFormField
          id="email"
          label={fieldLabels.email}
          errors={errors}
          register={register}
          placeholder="Enter E-mail address (e.g. support@sciy.com) ..."
          autoComplete="email"
          required={createAccount}
        />

        <InputFormField
          id="lastName"
          label={fieldLabels.lastName}
          errors={errors}
          register={register}
          autoFocus
          required
          placeholder="Enter last name..."
          autoComplete="off"
        />

        <InputFormField
          id="firstName"
          label={fieldLabels.firstName}
          errors={errors}
          register={register}
          placeholder="Enter first name..."
          autoComplete="off"
        />

        <SelectFormField
          id="salutation"
          label={fieldLabels.salutation}
          required={false}
          control={control}
          isMulti={false}
          items={SALUTATIONS?.map((x) => ({ id: x.id, name: x.name })) ?? []}
          errors={errors}
          placeholder="Select salutation..."
        />

        <OrganizationsVirtualizedSelectForm id="organization" control={control} horizontal allowCreateEntity />

        <TextareaFormField
          id="privateAddress"
          label={fieldLabels.privateAddress}
          errors={errors}
          register={register}
          placeholder="Enter address..."
        />

        <InputFormField
          id="officePhone"
          label={fieldLabels.officePhone}
          errors={errors}
          register={register}
          placeholder="Enter phone number..."
          autoComplete="off"
        />

        <InputFormField
          id="phone"
          label={fieldLabels.phone}
          errors={errors}
          register={register}
          placeholder="Enter phone number..."
          autoComplete="off"
        />

        <InputFormField
          id="web"
          label={fieldLabels.web}
          errors={errors}
          register={register}
          placeholder="Enter web adress (e.g. https://demo.logs-repository.com) ..."
          autoComplete="off"
        />

        <PersonTagsVirtualizedSelectForm
          id="personTags"
          label={fieldLabels.personTags}
          control={control}
          isMulti
          horizontal
          allowCreateEntity
        />

        <TextareaFormField
          id="notes"
          label={fieldLabels.notes}
          errors={errors}
          register={register}
          placeholder="Enter notes..."
        />
        {session?.features.enable_person_custom_types && (
          <EntityCustomTypeForm
            entityType="Person"
            typeId={typeId}
            type={type}
            types={types}
            setType={setType}
            control={control}
            register={register}
            setValue={setValue}
            initialValues={initialValues}
            errors={errors}
            entityConstants={personsConstants}
          />
        )}
        <FormButtons
          groupName="persons"
          entityId={initialValues ? initialValues.id : undefined}
          onClose={onCancel}
          onSubmit={handleSubmit(
            async (entity) =>
              await onSubmit(
                processCustomFields({
                  ...entity,
                  salutation: (entity.salutation as any)?.name || "",

                  // only send for accounts
                  login: createAccount ? entity.login : undefined,
                  accountState: createAccount ? (loginDisabled ? "Disabled" : "Enabled") : undefined,
                  roles: createAccount ? entity.roles : undefined,
                  isSystemUser: createAccount ? isSystemUser : undefined,
                  oldPassword: createAccount ? entity.oldPassword : undefined,
                  password: createAccount ? entity.password : undefined,
                } as PersonWriteModel)
              )
          )}
          loading={loading}
          submitButtonLabel={submitButtonLabel || "Save changes"}
          disabled={isSubmitting}
          errors={errors}
        />
      </form>
      {initialValues?.id && enable2FA && (
        <TotpForm accountId={initialValues?.id!} showModal={showTotpModal} setShowModal={setTotpModal} />
      )}
    </>
  );
};
