import {
  IGenericRequestParametersTranslator,
  IPaginationParametersTranslator,
  IRelationParametersTranslator,
  PropertyTranslator,
} from "./GenericTranslator";
import {
  IRelationModel,
  IGenericRequestParameters,
  IEntityMinimalModel,
  IModelWithIntId,
  INamedEntity,
  IOwnedEntity,
  IPaginationParameters,
  IPermissionedEntity,
  IRelatedEntity,
  IRelation,
  IRelationParameters,
  EntityConstants,
  DefaultOrderTypeConsts,
} from "./GenericTypes";

export const documentsConstants: EntityConstants = {
  resource: "documents",
  frontendIndexRoute: "documents",
  entitySingular: "document",
  entityPlural: "documents",
  icon: "file-text",
};

interface File extends IEntityMinimalModel {
  mime: string;
  size: number;
}

export interface DocumentRelations extends IRelationModel {
  authors: IRelation;
  datasets: IRelation;
  samples: IRelation;
  documents: IRelation;
}

export interface Document
  extends IPermissionedEntity,
    IOwnedEntity,
    IModelWithIntId,
    IRelatedEntity<DocumentRelations>,
    INamedEntity {
  creationDate: string | null;
  modificationDate: string | null;
  notes: string | null;
  publicationDate: string | null;
  publicationType: IEntityMinimalModel;
  doi: string | null;
  url: string | null;
  files: File[];
  authors: IEntityMinimalModel[];
  projects: IEntityMinimalModel[];
  datasets: IEntityMinimalModel[];
  samples: IEntityMinimalModel[];
  organizations: IEntityMinimalModel[];
}
export interface DocumentSuggestions extends IEntityMinimalModel<Document["id"]> {}

export const DocumentSortingOptionsConsts = [...DefaultOrderTypeConsts] as const;
export type DocumentSortingOptions = (typeof DocumentSortingOptionsConsts)[number];
export interface DocumentFilters
  extends IGenericRequestParameters<Document, DocumentSortingOptions>,
    IPaginationParameters,
    IRelationParameters {
  name?: string | null;
  creationDate?: string | null;
  publicationTypes?: number[] | null;
  doi?: string | null;
  authorIds?: number[] | null;
  projectIds?: number[] | null;
  datasetIds?: number[] | null;
  sampleIds?: number[] | null;
  organizationIds?: number[] | null;
}

export const DocumentFiltersTranslator: PropertyTranslator<DocumentFilters> = {
  ...IGenericRequestParametersTranslator,
  ...IPaginationParametersTranslator,
  ...IRelationParametersTranslator,

  creationDate: "Creation date",
  publicationTypes: "Publication types",
  doi: "DOI",
  authorIds: "Author IDs",
  projectIds: "Project IDs",
  datasetIds: "Dataset IDs",
  sampleIds: "Sample IDs",
  organizationIds: "Organization IDs",
} as const;

export const DocumentFieldLabels: PropertyTranslator<Document> = {
  authors: "Authors",
  creationDate: "Creation date",
  datasets: "Datasets",
  doi: "DOI",
  files: "Files",
  id: "ID",
  modificationDate: "Modification date",
  name: "Name",
  notes: "Notes",
  organizations: "Organizations",
  owner: "Owner",
  permissions: "Permissions",
  projects: "Projects",
  publicationDate: "Publication date",
  publicationType: "Publication type",
  relations: "Relations",
  samples: "Samples",
  url: "URL",
} as const;
