import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { InputFormField } from "../../../formfields/InputFormField";
import { QuickAddChildrenProps } from "../common/QuickAddWrapper";
import { FormButtons } from "../../FormButtons";
import { FormHeader } from "../../FormHeader";
import { useQuickAddSubmit } from "../common/useQuickAddHooks";
import { InventoryItem, InventoryItemFieldLabels, inventoryItemsConstants } from "../../../../api/Inventories";
import { inventoryItemFormBaseSchema } from "../../../../Inventories/InventoryItemForm";
import {
  EntityCustomTypeForm,
  useCustomTypeForm,
} from "../../../../Customization/CustomTypes/generics/useCustomTypeForm";
import { useCallback } from "react";
import { ProjectsVirtualizedSelectForm } from "../../EntityForms/formsVirtualized/ProjectsVirtualizedSelectForm";
import { CustomTypeFilters } from "../../../../api/CustomTypes";

interface QuickAddInventoryItemFormProps extends QuickAddChildrenProps<InventoryItem> {
  customTypeFilters?: CustomTypeFilters;
  subtitle?: string;
}

export const QuickAddInventoryItemForm = ({
  onCreate,
  onCancel,
  initialValues,
  customTypeFilters,
  subtitle,
}: QuickAddInventoryItemFormProps) => {
  const { onSubmit, loading } = useQuickAddSubmit<InventoryItem>({
    resource: inventoryItemsConstants.resource,
    onCreate: onCreate,
    onCancel: onCancel,
  });

  const { defaultValues, typedFormSchema, type, processCustomFields, setType, types } = useCustomTypeForm({
    initialValues: initialValues,
    formSchema: inventoryItemFormBaseSchema,
    typeId: initialValues?.customType?.id,
    entityType: "Inventory",
  });

  const {
    register,
    handleSubmit,
    control,
    trigger,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm<Partial<InventoryItem>>({
    values: defaultValues,
    resolver: yupResolver(typedFormSchema),
  });

  // Helper function to process some fields
  const processEntity = useCallback((entity: Partial<InventoryItem>) => {
    return {
      ...entity,
      customType: entity.customType ?? null,
      ...(!entity.customType && { customFields: {} }),
    } as Partial<InventoryItem>;
  }, []);

  return (
    <>
      <FormHeader
        title={`Add ${inventoryItemsConstants.entitySingular}`}
        subtitle={subtitle ?? initialValues?.customType?.name ?? ""}
      />
      <InputFormField
        id="name"
        label={InventoryItemFieldLabels.name}
        errors={errors}
        register={register}
        autoFocus={true}
        required={true}
      />

      <ProjectsVirtualizedSelectForm
        id="projects"
        control={control}
        isMulti
        horizontal
        allowCreateEntity
        showControls
        placeholder="Projects are inherit from top level inventory item..."
        disabled={!!type?.rootHierarchy}
        filters={{ currentUserHasAddPermission: true }}
      />

      {/* Render custom type */}
      <EntityCustomTypeForm
        entityType="Inventory"
        typeId={initialValues?.customType?.id}
        type={type}
        types={types}
        setType={setType}
        control={control}
        register={register}
        setValue={setValue}
        initialValues={initialValues}
        errors={errors}
        required
        disableTypeSelection={!!initialValues?.customType}
        disableParentSelection={!!initialValues?.parent}
        entityConstants={inventoryItemsConstants}
        customTypeFilters={customTypeFilters}
        allowUnassigned={false}
      />

      <FormButtons
        groupName="inventories"
        entityId={initialValues ? initialValues.id : undefined}
        onClose={onCancel}
        onSubmit={handleSubmit(async (entity) => {
          await trigger();
          await onSubmit(processCustomFields(processEntity(entity)));
        })}
        submitButtonLabel="Create"
        disabled={isSubmitting}
        errors={errors}
        loading={loading}
      />
    </>
  );
};
