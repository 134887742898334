import { PropertyTranslator } from "./GenericTranslator";
import { IModelWithIntId, INamedEntity, ICreatedOn, EntityConstants } from "./GenericTypes";

export const apiKeysConstants: EntityConstants<ApiKey> = {
  resource: "api_keys",
  frontendIndexRoute: "apikeys",
  entitySingular: "API key",
  entityPlural: "API keys",
  icon: "key",
};

export interface ApiKey extends IModelWithIntId, INamedEntity, ICreatedOn {
  apiKey: string;
  readOnly: boolean;
}

export interface ApiKeyWriteModel extends Omit<ApiKey, "id, createdOn"> {}

export const ApiKeyFieldLabels: PropertyTranslator<ApiKey> = {
  id: "API-Key ID",
  name: "Name",
  apiKey: "API key",
  readOnly: "Read only",
  createdOn: "Created on",
} as const;
