import { OverlayTrigger, Popover } from "react-bootstrap";
import { LucideIcon } from "../../../../common/icon/LucideIcon";
import Status from "../../../../common/badges/Status/Status";
import { NotAvailable } from "../../../../common/misc/UIconstants";
import { DataSource } from "../../../../api/DataSource";
import { DataSourceStatusRunState } from "../../../../api/DataSourceStatus";

export const getDataSourceStatusLabel = ({
  runState,
  hasErrors,
}: {
  runState?: DataSourceStatusRunState;
  hasErrors: boolean;
}) => {
  switch (runState) {
    case "Failed":
      return (
        <label className="label label-soft-danger flex align-center gap-5" style={{ margin: 0 }}>
          <LucideIcon name="triangle-alert" />
          {runState}
        </label>
      );
    case "Aborted":
      return (
        <label className="label label-soft-danger flex align-center gap-5" style={{ margin: 0 }}>
          <LucideIcon name="triangle-alert" />
          {runState}
        </label>
      );
    case "Finished":
      return (
        <label
          className={`label label-soft-${hasErrors ? "warning" : "success"} flex align-center gap-5`}
          style={{ margin: 0 }}
        >
          <Status type={hasErrors ? "warning" : "success"} />
          {runState}
        </label>
      );
    case "Waiting":
      return (
        <label className="label label-soft-warning flex align-center gap-5" style={{ margin: 0 }}>
          <Status idle type="warning" />
          {runState}
        </label>
      );
    case "Running":
      return (
        <label className="label label-soft-warning flex align-center gap-5" style={{ margin: 0 }}>
          <Status idle type="warning" />
          {runState}
        </label>
      );
    default:
      return NotAvailable;
  }
};

export const getDataSourceStatusInfo = ({ dataSource }: { dataSource?: DataSource }) => {
  const runState = dataSource?.status?.lastClientStatus?.runState;
  const hasErrors = !!dataSource?.status?.lastClientStatus?.errors?.length;
  if (!!dataSource && !dataSource.status?.lastClientStatus) return "The data transfer has not run yet.";
  switch (runState) {
    case "Failed":
      return "The last attempted data upload failed.";
    case "Finished":
      if (hasErrors) return "The last data upload finished with errors.";
      return "The last data upload finished without errors.";
    case "Running":
      return "A data transfer is currently in progress.";
    default:
      return NotAvailable;
  }
};

export const DataSourceRunningStatus = ({ dataSource }: { dataSource: DataSource }) => {
  return (
    <div className="flex align-center gap-5" style={{ justifyContent: "space-between", width: "100%" }}>
      <div className="flex align-center">
        {getDataSourceStatusLabel({
          runState: dataSource.status?.lastClientStatus?.runState,
          hasErrors: !!dataSource.status?.lastClientStatus?.errors?.length,
        })}
      </div>
      <OverlayTrigger
        placement="bottom"
        overlay={<Popover id="popover-info">{getDataSourceStatusInfo({ dataSource })}</Popover>}
      >
        <LucideIcon name="info" color={"var(--gray-400)"} />
      </OverlayTrigger>
    </div>
  );
};
