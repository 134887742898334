import { generateUid } from "../../tools/UID/UID";
import { argumentType, commandTypes, parameterTypes, trackListType } from "../PipelineTypes";
import { StackTraces } from "../StackTrace";
import { Empty } from "./Empty";

type precalculatedTable = { cosTable: Float32Array; sinTable: Float32Array };

export class ComplexFFT extends Empty {
  id: string;
  name: string;
  readonly type = commandTypes.complexFFT;
  errors: StackTraces;
  warnings: StackTraces;

  parameterTypes = { fidSize: parameterTypes.string, size: parameterTypes.string };
  parameterSettings = {
    fidSize: { name: "FID size", readonly: true, value: "" },
    size: { name: "Spectrum size", readonly: true, value: "" },
  };

  internalParameter: Record<string, any>;
  readonly input: argumentType[];
  readonly output: argumentType[];

  precalculatedTables: Record<number, precalculatedTable>;

  constructor() {
    super();
    this.id = generateUid();
    this.name = "Complex FFT";
    this.errors = new StackTraces();
    this.warnings = new StackTraces();
    this.internalParameter = {};
    this.input = [
      { name: "FID real", type: "1D_real" },
      { name: "FID imaginary", type: "1D_real" },
    ];
    this.output = [
      { name: "Spectrum real", type: "1D_real" },
      { name: "Spectrum imaginary", type: "1D_real" },
    ];

    this.precalculatedTables = {};
  }

  precalculateFftTables(length: number) {
    // console.log("precalculateFftTables", length);
    const max = Math.log2(length * 2);

    const arrayLengthsToPrecalculate = new Int32Array(max - 1);

    for (let i = 1; i < max; i++) {
      arrayLengthsToPrecalculate[i - 1] = Math.pow(2, i);
    }

    for (let i = 0; i < arrayLengthsToPrecalculate.length; i++) {
      const N = arrayLengthsToPrecalculate[i];
      if (N in this.precalculatedTables) continue;
      const cosTable = new Float32Array(N / 2);
      const sinTable = new Float32Array(N / 2);
      for (let j = 0; j < N / 2; j++) {
        cosTable[j] = Math.cos((2 * Math.PI * j) / N);
        sinTable[j] = Math.sin((2 * Math.PI * j) / N);
      }
      this.precalculatedTables[N] = { cosTable: cosTable, sinTable: sinTable };
    }
  }

  FFT(re: Float32Array | Float64Array | number[], im: Float32Array | Float64Array | number[], N: number) {
    // console.log("miniFFTprecalc2", N);

    // console.log("re", Array.prototype.slice.call(re));
    // console.log("im", Array.prototype.slice.call(im));

    for (var i = 0; i < N; i++) {
      for (var j = 0, h = i, k = N; (k >>= 1); h >>= 1) j = (j << 1) | (h & 1);
      if (j > i) {
        re[j] = [re[i], (re[i] = re[j])][0];
        im[j] = [im[i], (im[i] = im[j])][0];
      }
    }

    for (var hN = 1; hN * 2 <= N; hN *= 2) {
      for (var i = 0; i < N; i += hN * 2) {
        for (var j = i; j < i + hN; j++) {
          var cos = this.precalculatedTables[N].cosTable[(((j - i) / hN) * N) / 2],
            sin = this.precalculatedTables[N].sinTable[(((j - i) / hN) * N) / 2];
          var tre = re[j + hN] * cos + im[j + hN] * sin,
            tim = -re[j + hN] * sin + im[j + hN] * cos;
          re[j + hN] = re[j] - tre;
          im[j + hN] = im[j] - tim;
          re[j] += tre;
          im[j] += tim;
        }
      }
    }
  }

  spectrumSwap(spec: Float32Array | Float64Array | number[]) {
    const mid = spec.length / 2;

    for (let i = 0; i < mid; i++) {
      this.swapElement(spec, i, i + mid);
    }
  }

  swapElement(array: Float32Array | Float64Array | number[], i: number, j: number) {
    const tmp = array[i];
    array[i] = array[j];
    array[j] = tmp;
  }

  // dft(array: Float32Array) {
  //   const N = array.length;
  //   const re = new Float32Array(array);
  // }

  quadratureDetection(re: Float32Array, im: Float32Array, N: number) {
    // Function to create quadrature detected spectra from FID
    // Input: 1D complex array (FID), ideally processed (e.g. zf, window function)
    // Output: 1D complex array (spectra)

    const M = Math.floor(N / 2);

    // // FFT
    let reFr = re; // creates new complex output
    let imFr = new Float32Array(N); // creates new complex output
    let reFi = im; // creates new complex output
    let imFi = new Float32Array(N); // creates new complex output

    this.precalculateFftTables(N);
    this.FFT(reFr, imFr, N);
    this.FFT(reFi, imFi, N);
    console.log("FR", N, M);
    // console.log("Fr", reFr, imFr);
    // console.log("Fi", reFi, imFi);

    // // (1) Real Channel (cosine wave)
    const real = new Array(N); // allocate memory
    const real_left = new Array(N);
    let real_right = new Array(N);
    for (let i = 0; i < N; i++) {
      real_left[i] = reFr[i] - imFi[i];
      // real_right[N - i - 1] = reFr[i] + imFi[i];
      real_right[i] = reFr[N - i - 1] + imFi[N - i - 1];
    }
    for (let i = 0; i < M; i++) {
      real[i] = reFr[M + i] - imFi[M + i];
      real[M + i] = reFr[N - i - 1] + imFi[N - i - 1];
    }
    // console.log("real_left", real_left.splice(M));
    // console.log("real", real.splice(0, M));

    // console.log("real_right", real_left.splice(0, M));
    // console.log("real", real.splice(0, M));

    // console.log("real_right", real_right.splice(0, M));
    // console.log("real", real.splice(M));
    // real_right = real_right.reverse();
    // console.log("real", real_right);
    console.log("real", real);
    // console.log("real", real.splice(M));

    let j = 0;
    for (let i = 0; i < N; i++) {
      if (i < M) {
        real[i] = real_left[M + i]; // left half of real left
      } else {
        real[i] = real_right[j]; // right half of real right
        j += 1;
      }
    }

    // // (2) Imag Channel (sine wave)
    // let imag = new Array(N); // allocate memory
    // let imag_left = new Array(N);
    // let imag_right = new Array(N);
    // for (let i = 0; i < N; i++) {
    //   imag_left[i] = Fi.re[i] + Fr.im[i];
    //   imag_right[i] = Fi.re[i] - Fr.im[i];
    // }
    // imag_right = imag_right.reverse();
    // j = 0;
    // for (let i = 0; i < N; i++) {
    //   if (i < M) {
    //     imag[i] = imag_left[M + i]; // left half of imag left
    //   } else {
    //     imag[i] = imag_right[j]; // right half of imag right
    //     j += 1;
    //   }
    // }
    // // Quadrature spectra
    // const spec = math.complex({ re: real, im: imag });
    // return spec;
  }

  calculatePot2Length(len: number) {
    const expo = Math.ceil(Math.log2(len));
    return Math.pow(2, expo);
  }

  run(tracks: trackListType): trackListType {
    // if (!tracks?.[0]?.data || !tracks?.[1]?.data) {
    //   this.errors.create({
    //     id: this.id,
    //     component: "ComplexFFT.run",
    //     message: `Some tracks do not contain any data.`,
    //   });
    //   return [];
    // }

    // if (tracks[0].data.length !== tracks[1].data.length) {
    //   this.errors.create({
    //     id: this.id,
    //     component: "ComplexFFT.run",
    //     message: `The real and imaginary tracks are not the same size.`,
    //   });
    //   return [];
    // }

    // const fidSize = tracks[0].data.length;
    // const len = this.calculatePot2Length(fidSize);

    // const reIn = resizeData1DReal(tracks[0].data, len);
    // const imIn = resizeData1DReal(tracks[1].data, len);
    // const parameter = tracks[0]?.internalParameter.NMR ?? {};

    // if (reIn.length < 2) {
    //   this.errors.create({
    //     id: this.id,
    //     component: "ComplexFFT.run",
    //     message: `Invalid data. Fid has less than 2 data points`,
    //   });
    //   return [];
    // }

    // this.parameter = {
    //   fidSize: `${fidSize} points`,
    //   size: `${reIn.length} points = 2<sup>${Math.log2(reIn.length)}</sup>`,
    // };

    // this.precalculateFftTables(reIn.length);
    // this.FFT(reIn.y, imIn.y, reIn.length);
    // this.spectrumSwap(reIn.x);
    // this.spectrumSwap(reIn.y);
    // this.spectrumSwap(imIn.x);
    // this.spectrumSwap(imIn.y);

    // if (parameter.reverse_spectrum) {
    //   reIn.x.reverse();
    //   reIn.y.reverse();
    //   imIn.x.reverse();
    //   imIn.y.reverse();
    // }

    // tracks[0].data = reIn;
    // tracks[1].data = imIn;
    // tracks[0].label = "spectrum.re";
    // tracks[1].label = "spectrum.im";

    // // console.log("tracks", parameter.reverse_spectrum);

    return tracks;
  }
}
