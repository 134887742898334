import { StringIndexedDict } from "../../../../api/GenericTypes";
import {
  GenericEntityVirtualizedEntitySelectFormFieldProps,
  GenericVirtualizedEntitySelectFormFieldControllerWrapper,
} from "../../../formfields/GenericVirtualizedSelectFormField/GenericVirtualizedEntitySelectFormFieldControllerWrapper";
import {
  ParserMethodFilters,
  ParserMethodSortingOptionsConsts,
  ParserMethodSuggestions,
  parserMethodsConstants,
} from "../../../../api/Parsers";

export const ParserMethodsVirtualizedSelectForm = <Entity extends StringIndexedDict>(
  props: GenericEntityVirtualizedEntitySelectFormFieldProps<Entity, ParserMethodSuggestions, ParserMethodFilters>
) => {
  return (
    <GenericVirtualizedEntitySelectFormFieldControllerWrapper<Entity, ParserMethodSuggestions, ParserMethodFilters>
      {...props}
      filters={{ orderBy: "NAME_ASC", ...props.filters }}
      //   onRowRenderer={(item) => <></>}
      entityConstants={parserMethodsConstants}
      orderByOptions={ParserMethodSortingOptionsConsts}
      allowCreateEntity={false}
    />
  );
};
