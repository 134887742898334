import { generateUid } from "../../tools/UID/UID";
import { commandTypes, parameterTypes, trackListType } from "../PipelineTypes";
import { Empty } from "./Empty";

// type parameterType = {
//   tracks: (tracks: trackListType) => trackListType;
//   names: string[];
// };

export class TrackRenamer extends Empty {
  id: string;
  name: string;
  readonly type = commandTypes.trackRenamer;

  parameterTypes = { tracks: parameterTypes.trackListSelection, names: parameterTypes.stringList };
  parameterSettings = { tracks: { name: "Input tracks" }, names: { name: "Rename to" } };

  constructor() {
    super();
    this.id = generateUid();
    this.name = "Track renamer";
  }

  getParams() {
    return Object.fromEntries(Object.entries(this.parameter).map(([k, v]) => [k, v.value]));
  }

  run(tracks: trackListType): trackListType {
    // console.log("---------- Rename tracks -----------");
    // const param = this.parameter as parameterType;
    // const trackList = param.tracks(tracks);
    // const names = param.names;

    // if (trackList.length !== names.length)
    //   this.warnings.create({
    //     id: this.id,
    //     component: "TrackRenamer.run",
    //     message: `Selected track count must be equal to name count. (Got track ${trackList.length}!=${names.length} names).`,
    //   });

    // // const l = Math.min(list.length, names.length);
    // for (let i = 0; i < trackList.length; i++) {
    //   if (!trackList[i]) continue;
    //   // if (!names[i]) continue;
    //   (trackList[i] as trackType).label = names[i] ?? "default";
    // }

    return tracks;
  }
}
