import { useCallback, useMemo, useState } from "react";

import { LucideIcon } from "../../common/icon/LucideIcon";
import { settingType, TrackSettings } from "../../SpectrumViewer/ViewerNavigation/TrackSettings";
import { useTranslatedFieldUpdate } from "../../ViewerLayout/ViewerLayoutHooks";
import { Color, HierarchyBase } from "../../ViewerLayout/ViewerLayoutTypes";
import { trackState } from "../../ViewerLayout/ViewerTypes";
import { ColorBox } from "../../ViewerUIElements/ColorBox";
import { EllipsisTextBlock } from "../../ViewerUIElements/TextBlock";
import { Tools, ToolsType } from "../../ViewerUIElements/Tools";
import styles from "./TreeViewer.module.css";
import { TreeNodeProperties, TreeViewerProps } from "./TreeViewerTypes";

const f = (source: Record<string, TreeNodeProperties>, children: HierarchyBase) => {
  const { id, name, tracks: tracks } = children;
  const node = source?.[id] ?? new TreeNodeProperties();
  // console.log("=>", name, id, node.color?.name);
  return {
    name: name,
    id: id,
    tracks: tracks,
    color: node.color,
    visible: node.visible,
    active: node.active ?? true,
    selected: node.selected,
    state: node.state,
    border: node.border,
    simpleColor: node.simpleColor,
    settings: node.settings,
  };
};

export const TreeKeyLeaf = ({
  children,
  onVisibilityChange,
  onSelectionChange,
  onColorChange,
  onTrackSettingsChange,
  onTracksZoom,
  nodeProperties,
  iconMode,
}: TreeViewerProps) => {
  const properties = useTranslatedFieldUpdate<
    {
      nodeProperties: Record<string, TreeNodeProperties>;
      children: HierarchyBase;
    },
    {
      id: string;
      active: boolean;
      name: string;
      tracks: string[];
      color?: Color;
      visible: boolean;
      selected: boolean;
      state: trackState;
      simpleColor?: boolean;
      settings: settingType;
    }
  >(
    { nodeProperties, children },
    ({ nodeProperties, children }: { nodeProperties: Record<string, TreeNodeProperties>; children: HierarchyBase }) =>
      f(nodeProperties, children),
    {}
  );

  const [toolState, setToolState] = useState<ToolsType>({
    x: undefined,
    y: undefined,
    active: false,
  });

  const [toolsVisible, setToolsVisible] = useState<boolean>(false);

  const toolHandler = useCallback((e: any) => {
    //   // console.log("Test", e.target.getBoundingClientRect(), e.clientX, e.clientY);
    setToolState({ x: e.clientX, y: e.clientY, active: true });
  }, []);

  const setColors = useCallback(
    (color: Color) => {
      onColorChange(properties.tracks, color);
    },
    [properties]
  );

  const colorBox = useMemo(() => {
    return properties.color?.id ? (
      <ColorBox color={properties.color} onColorChange={setColors} allowColorChange={properties.simpleColor} />
    ) : undefined;
  }, [properties.color, properties.simpleColor]);

  const showIcon = useMemo(() => {
    if (iconMode === undefined || iconMode === "eye") return { eye: true, cog: true };
    else if (iconMode === "noEye") return { eye: false, cog: true };
    else if (iconMode === "noEye-noCog") return { eye: true && properties.visible, cog: true && properties.visible };
    else return { eye: false, cog: true };
  }, [iconMode, onVisibilityChange, properties.tracks, properties.visible, properties.state]);

  const status = useMemo(() => {
    switch (properties.state) {
      case trackState.loading:
        return (
          <div style={{ paddingLeft: 3, paddingRight: 3 }}>
            <div className={styles.loader} />
          </div>
        );
      case trackState.failed:
        return (
          <div className={styles.broken} style={{ paddingLeft: 3, paddingRight: 3 }}>
            <LucideIcon name="triangle-alert" style={{ height: 15 }} />
          </div>
        );
      case trackState.ready:
      default:
        return null;
    }
    // console.log("key ", name, state);
  }, [properties.state]);

  if (!properties.active) return null;

  return (
    <div
      className={[
        styles.keyLine,
        toolState.active || toolsVisible
          ? styles.highlightedLine
          : properties.selected
          ? styles.selectedLine
          : undefined,
      ].join(" ")}
      // className={styles.keyLine}
      // style={{ background: toolState.active || toolsVisible ? "darkgray" : undefined }}
      onMouseEnter={() => setToolsVisible(true)}
      onMouseLeave={() => setToolsVisible(false)}
      onClick={(e) => {
        if (onSelectionChange) {
          if (e.shiftKey || e.ctrlKey) onSelectionChange(properties.tracks, !properties.selected, "add");
          else onSelectionChange(properties.tracks, !properties.selected, "reset");
        }
        e.preventDefault();
      }}
    >
      <div className={styles.parameterKey}>
        {toolState.active ? (
          <Tools state={toolState} setState={setToolState}>
            <span className={styles.settingsHeader}>Track: {properties.name}</span>
            <TrackSettings
              settings={properties.settings}
              onChange={onTrackSettingsChange}
              onZoom={onTracksZoom}
              tracks={properties.tracks}
            />
          </Tools>
        ) : undefined}
        {colorBox}
        &nbsp;
        <div style={{ flexGrow: 1, overflow: "hidden" }}>
          <EllipsisTextBlock text={properties.name} />
        </div>
      </div>
      <div className={styles.toolList}>
        {status}
        <div className={[properties.visible ? styles.active : styles.faded, styles.trackIcon].join(" ")}>
          <LucideIcon
            name="settings"
            onClick={(e) => {
              toolHandler(e);
              e.stopPropagation();
            }}
            style={{ visibility: showIcon.cog ? "visible" : "hidden", height: 15 }}
          />
        </div>
        <div className={[properties.visible ? styles.active : styles.faded, styles.trackIcon].join(" ")}>
          <LucideIcon
            name="eye"
            onClick={(e) => {
              onVisibilityChange(properties.tracks, !properties.visible);
              e.stopPropagation();
            }}
            style={{ visibility: showIcon.eye ? "visible" : "hidden", height: 15, strokeWidth: 3 }}
          />
        </div>
      </div>
    </div>
  );
};
