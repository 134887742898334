import { useHistory } from "react-router-dom";
import { useContext, useMemo, useState } from "react";
import { SessionContext } from "../../common/contexts/SessionContext";
import { SearchInput } from "../../common/forms/SearchInput/SearchInput";
import { LucideIcon } from "../../common/icon/LucideIcon";
import TableView, { TableViewLabel } from "../../common/panels/TableView/TableView";
import { ColumnsSettings } from "../../common/tables/ColumnsSelector/ColumnsSelector";
import { AlertModal } from "../../common/modals/AlertModal/AlertModal";
// import { showtoast } from "../../common/overlays/Toasts/showtoast";
import { MoreDropdown } from "../../common/buttons/MoreDropdown/MoreDropdown";
import { EntityTable } from "../../common/tables/EntityTable/EntityTable";
import {
  GenericVirtualizedTableCells,
  SortState,
} from "../../common/tables/GenericVirtualizedTable/GenericVirtualizedTableTypes";
import { EntityFilterIndicator } from "../../common/tables/EntityFilterIndicator/EntityFilterIndicator";
import { useTabStore } from "../../common/tables/Tabs/useTabStore";
import { TableTabsDict } from "../../common/tables/Tabs/TableTabsTypes";
import { Role, RoleFilters, RoleFiltersTranslator } from "../../api/Role";
import { RoleFilterBar, RoleFilterForm } from "./RolesFilterBar";
import styles from "./RolesTable.module.css";
import { NotSet } from "../../common/misc/UIconstants";
import { DateTimeRenderer } from "../../common/datetime/DateTimeFormatter";
import { GetPersons } from "../../common/misc/EntityRenders/EntityRenderer";
import {
  UseEntityTableDefaultProps,
  UseEntityTableProps,
  useGenericVirtualizedTable,
  useGenericVirtualizedTableTabs,
} from "../../common/tables/GenericVirtualizedTable/useGenericVirtualizedTable";
import { EntityTableProps } from "../../common/entity/EntityInterfaces";
import { EntityTableDeleteButton } from "../../common/entity/entityComponents/EntityTableDeleteButton";
import { EntityTableCloneButton } from "../../common/entity/entityComponents/EntityTableCloneButton";
import { EntityTableEditButton } from "../../common/entity/entityComponents/EntityTableEditButton";

export const switchRolesDefaultSortState = (sortState: RoleFilters["orderBy"]): SortState<RoleFilters["orderBy"]> => {
  switch (sortState) {
    case "ID_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-id",
        orderBy: sortState,
      };
    case "ID_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-id",
        orderBy: sortState,
      };
    case "NAME_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-name",
        orderBy: sortState,
      };
    case "NAME_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-name",
        orderBy: sortState,
      };
    case "CREATED_ON_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-createdOn",
        orderBy: sortState,
      };
    case "CREATED_ON_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-createdOn",
        orderBy: sortState,
      };
    case "CREATED_BY_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-createdBy",
        orderBy: sortState,
      };
    case "CREATED_BY_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-createdBy",
        orderBy: sortState,
      };
    case "MODIFIED_ON_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-modifiedOn",
        orderBy: sortState,
      };
    case "MODIFIED_ON_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-modifiedOn",
        orderBy: sortState,
      };
    case "MODIFIED_BY_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-modifiedBy",
        orderBy: sortState,
      };
    case "MODIFIED_BY_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-modifiedBy",
        orderBy: sortState,
      };
    default:
      return {
        sortDirection: "ASC",
        headerId: "default-name",
        orderBy: sortState,
      };
  }
};
const defaultFilter: RoleFilters = { orderBy: "NAME_ASC" };

export const useRolesTableDefaults = ({ fieldLabels }: UseEntityTableDefaultProps<"roles">) => {
  const defaults: ColumnsSettings<Role> = useMemo(
    () => ({
      "default-id": { pos: 0, active: false, header: fieldLabels.id, property: "id" },
      "default-name": { pos: 1, active: true, header: fieldLabels.name, property: "name" },
      "default-description": { pos: 2, active: true, header: fieldLabels.description, property: "description" },
      "default-createdOn": { pos: 3, active: true, header: fieldLabels.createdOn, property: "createdOn" },
      "default-createdBy": { pos: 4, active: true, header: fieldLabels.createdBy, property: "createdBy" },
      "default-modifiedOn": { pos: 5, active: true, header: fieldLabels.modifiedOn, property: "modifiedOn" },
      "default-modifiedBy": { pos: 6, active: true, header: fieldLabels.modifiedBy, property: "modifiedBy" },
    }),
    [fieldLabels]
  );

  const tabStoreDefaults: TableTabsDict<Role, RoleFilters, RoleFilterForm> = useMemo(
    () => ({
      default: {
        tabId: "default",
        type: "fixed",
        label: "All",
        title: "All",
        icon: "house",
        align: "left",
        xPos: 0,
        settings: {
          columnSettings: {},
          columnWidths: {},
          filters: defaultFilter,
          sidebarFilters: {},
        },
        forcedSettings: {
          columnSettings: {},
          columnWidths: {},
          filters: {},
          sidebarFilters: {},
        },
      },
    }),
    []
  );
  return { defaults, tabStoreDefaults };
};

export const useRolesTableColumns = ({ entityConstants, fieldLabels, sort, setSort }: UseEntityTableProps<"roles">) => {
  const columns = useMemo(() => {
    let headers: GenericVirtualizedTableCells<Role> = [
      {
        id: "default-id",
        Header: fieldLabels.id,
        accessor: (row) => <span style={{ color: "var(--gray-400)" }}>{row.id}</span>,
        width: 120,
        minWidth: 120,
        align: "center",
        sortingFn: () => {
          if (sort.headerId === "default-id") {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "ID_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "ID_ASC" }));
            }
          } else {
            setSort({ headerId: "default-id", sortDirection: "ASC", orderBy: "ID_ASC" });
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
      {
        Header: fieldLabels.name,
        id: "default-name",
        accessor: (row) => (
          <div className="flex gap-5" style={{ height: "max-content" }} data-toggle="tooltip" title={row.name}>
            <div className="flex align-center gap-5">
              <LucideIcon name={entityConstants.icon} color="var(--primary)" />
              <div
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  fontWeight: 600,
                  whiteSpace: "nowrap",
                  marginRight: "5px",
                }}
              >
                {row.name}
              </div>
            </div>
          </div>
        ),
        minWidth: 150,
        width: 250,
        align: "left",
        sortingFn: () => {
          if (sort.headerId === "default-name") {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "NAME_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "NAME_ASC" }));
            }
          } else {
            setSort({ headerId: "default-name", sortDirection: "ASC", orderBy: "NAME_ASC" });
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
      {
        Header: fieldLabels.description,
        id: "default-description",
        accessor: (row) => (
          <div title={row.description} className={styles.description}>
            {row.description || NotSet}
          </div>
        ),
        minWidth: 150,
        width: 800,
        align: "left",
      },
      {
        id: "default-createdOn",
        Header: fieldLabels.createdOn,
        accessor: (row) => <DateTimeRenderer date={row.createdOn} includeElapsed={false} />,
        width: 200,
        align: "left",
        sortingFn: () => {
          if (sort.headerId === "default-createdOn") {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "CREATED_ON_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "CREATED_ON_ASC" }));
            }
          } else {
            setSort({ headerId: "default-createdOn", sortDirection: "ASC", orderBy: "CREATED_ON_ASC" });
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
      {
        id: "default-createdBy",
        Header: fieldLabels.createdBy,
        accessor: (row) => <GetPersons persons={row.createdBy} createLinks={false} />,
        width: 200,
        align: "left",
        sortingFn: () => {
          if (sort.headerId === "default-createdBy") {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "CREATED_BY_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "CREATED_BY_ASC" }));
            }
          } else {
            setSort({ headerId: "default-createdBy", sortDirection: "ASC", orderBy: "CREATED_BY_ASC" });
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
      {
        id: "default-modifiedOn",
        Header: fieldLabels.modifiedOn,
        accessor: (row) => <DateTimeRenderer date={row.modifiedOn} includeElapsed={false} />,
        width: 200,
        align: "left",
        sortingFn: () => {
          if (sort.headerId === "default-modifiedOn") {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "MODIFIED_ON_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "MODIFIED_ON_ASC" }));
            }
          } else {
            setSort({ headerId: "default-modifiedOn", sortDirection: "ASC", orderBy: "MODIFIED_ON_ASC" });
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
      {
        id: "default-modifiedBy",
        Header: fieldLabels.modifiedBy,
        accessor: (row) => <GetPersons persons={row.modifiedBy} createLinks={false} />,
        width: 200,
        align: "left",
        sortingFn: () => {
          if (sort.headerId === "default-modifiedBy") {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "MODIFIED_BY_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "MODIFIED_BY_ASC" }));
            }
          } else {
            setSort({ headerId: "default-modifiedBy", sortDirection: "ASC", orderBy: "MODIFIED_BY_ASC" });
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
    ];

    return headers;
  }, [
    entityConstants.icon,
    fieldLabels.createdBy,
    fieldLabels.createdOn,
    fieldLabels.description,
    fieldLabels.id,
    fieldLabels.modifiedBy,
    fieldLabels.modifiedOn,
    fieldLabels.name,
    setSort,
    sort.headerId,
    sort.sortDirection,
  ]);
  return { columns };
};

export const RolesTable = ({
  entityApi,
  entityConstants,
  fieldLabels,
  permissions,
  routes,
}: EntityTableProps<"roles">) => {
  const history = useHistory();
  const { session } = useContext(SessionContext);
  const [showModal, setShowModal] = useState(false);
  const { bulkDeleteMutationAsync } = entityApi;

  const { defaults, tabStoreDefaults } = useRolesTableDefaults({ fieldLabels });

  const { selection, resultsCount, selectionPermissions, onCountChange, onSelectionChange, onSelectionPermissions } =
    useGenericVirtualizedTable<Role>();

  const {
    filters,
    sidebarFilters,
    forcedFilters,
    columnSetting,
    columnWidths,
    dispatchTabStore,
    currentTab,
    tabsLoading,
  } = useTabStore<Role, RoleFilters, RoleFilterForm>({
    resource: entityConstants.resource,
    defaults: tabStoreDefaults,
  });

  const { functionRef, sort, setSort, searchValue, setSearchValue } = useGenericVirtualizedTableTabs({
    tabsLoading,
    filters,
    switchSortState: switchRolesDefaultSortState,
    dispatchTabStore,
  });

  const { columns } = useRolesTableColumns({ fieldLabels, entityConstants, sort, setSort });

  const memoizedFilters = useMemo(() => ({ ...filters, ...forcedFilters }), [filters, forcedFilters]);

  return (
    <TableView>
      <TableView.Head>
        <TableView.Head.Label>
          <TableViewLabel entityConstants={entityConstants} resultsCount={resultsCount} />
        </TableView.Head.Label>
        <TableView.Head.Controls>
          {session?.permissions.logsadmin && (
            <button
              className="btn btn-primary"
              title="Add Role"
              onClick={() => history.push(routes.getAddRoute)}
              disabled={!permissions.canCreate}
            >
              <LucideIcon name="plus" /> Add Role
            </button>
          )}
        </TableView.Head.Controls>
      </TableView.Head>
      <TableView.Body>
        {(session?.permissions.logsadmin || session?.permissions.view_admin_pages) && (
          <TableView.Body.Sidebar>
            <RoleFilterBar
              initialValues={sidebarFilters}
              dispatchTabStore={dispatchTabStore}
              tabsLoading={tabsLoading}
              currentTab={currentTab}
            />
          </TableView.Body.Sidebar>
        )}
        <TableView.Body.Content>
          <EntityTable>
            <EntityTable.Controls
            // style={{
            //   borderTop: "0px",
            //   borderRadius: "0px",
            // }}
            >
              <EntityFilterIndicator<Role, RoleFilters>
                filters={filters}
                excludeFilters={{
                  includeInternals: (value) => !!value,
                }}
                translatorConsts={RoleFiltersTranslator}
              />
              <SearchInput searchValue={searchValue} setSearchValue={setSearchValue} placeholder="Search" />

              {session?.permissions.logsadmin && (
                <>
                  <EntityTableEditButton
                    entityConstants={entityConstants}
                    routes={routes}
                    permissions={permissions}
                    selection={selection}
                    selectionPermissions={selectionPermissions}
                  />
                  <MoreDropdown drop="right" btn="btn btn-ghost-secondary">
                    <li>
                      <EntityTableCloneButton
                        entityConstants={entityConstants}
                        routes={routes}
                        permissions={permissions}
                        selection={selection}
                      />
                    </li>
                    <li>
                      <EntityTableDeleteButton
                        entityConstants={entityConstants}
                        permissions={permissions}
                        selection={selection}
                        selectionPermissions={selectionPermissions}
                        onClick={() => setShowModal(true)}
                      />
                    </li>
                  </MoreDropdown>
                </>
              )}
              <AlertModal
                type="danger"
                showModal={showModal}
                setShowModal={setShowModal}
                title={`${
                  selection.size === 0
                    ? `Select ${entityConstants.entitySingular} to delete`
                    : selection.size === 1
                    ? `Delete the selected ${entityConstants.entitySingular}`
                    : `Delete the selected ${entityConstants.entityPlural}`
                }`}
                description={`Proceeding will permanently delete the selected ${entityConstants.entityPlural}.`}
                proceedLabel="Delete"
                onProceed={async () => {
                  await bulkDeleteMutationAsync({
                    ids: Array.from(selection),
                    goBackOnSuccess: false,
                    showToast: false,
                    entityName: "roles",
                    params: { deletePermanently: true },
                  }).catch((e) => {});
                  functionRef.current?.resetSelection();
                  setShowModal(false);
                }}
              />
            </EntityTable.Controls>
            <EntityTable.Body<Role, RoleFilters>
              functionRef={functionRef}
              entityConstants={entityConstants}
              filters={memoizedFilters}
              columns={columns}
              columnSelect
              columnSetting={columnSetting}
              columnWidths={columnWidths}
              defaultColumnSettings={defaults}
              dispatchTabStore={dispatchTabStore}
              setResultsCount={onCountChange}
              onSelectionChange={onSelectionChange}
              onSelectionPermissions={onSelectionPermissions}
              showPermissionColumn={session?.permissions.logsadmin}
              loading={tabsLoading}
              fixedRowHeight={55}
              disableCheckboxes={!session?.permissions.logsadmin}
            />
          </EntityTable>
        </TableView.Body.Content>
      </TableView.Body>
    </TableView>
  );
};
