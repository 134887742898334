import React, { CSSProperties } from "react";
// import ResizePanel from "react-resize-panel";
import styles from "./FullDetail.module.css";
import { Panel, PanelGroup } from "react-resizable-panels";
import { CustomResizeHandle } from "../misc/CustomResizeHandle";

/**
 * Generic FullDetailView wrapper component, with a main content area on the right and sidebar on the left
 * Author: CS
 * @param: children
 * @returns: JSX.Element
 */

interface Props {
  style?: CSSProperties;
  children: React.ReactNode;
}

const FullDetail = ({ style, children }: Props) => {
  return (
    <div className={styles.main} style={style}>
      {children}
    </div>
  );
};

// Navigation
const Navigation = ({ style, children }: Props) => {
  return (
    <div className={styles.mainNavigation} style={style}>
      {children}
    </div>
  );
};
FullDetail.Navigation = Navigation;

const ControlsLeft = ({ style, children }: Props) => (
  <div className={styles.mainNavigationControlsLeft} style={style}>
    {children}
  </div>
);
Navigation.ControlsLeft = ControlsLeft;

const Selector = ({ style, children }: Props) => (
  <div className={styles.mainNavigationSelector} style={style}>
    {children}
  </div>
);
Navigation.Selector = Selector;

const ControlsRight = ({ style, children }: Props) => (
  <div className={styles.mainNavigationControlsRight} style={style}>
    {children}
  </div>
);
Navigation.ControlsRight = ControlsRight;

const Info = ({ style, children }: Props) => (
  <div className={styles.mainNavigationInfo} style={style}>
    {children}
  </div>
);
Navigation.Info = Info;

// Body

const Body = ({ style, children }: Props) => {
  return (
    <div className={styles.mainBody} style={style}>
      <PanelGroup direction="horizontal">{children}</PanelGroup>
    </div>
  );
};
FullDetail.Body = Body;

const FullBody = ({ style, children }: Props) => {
  return (
    <div className={styles.mainFullBody} style={style}>
      {children}
    </div>
  );
};
FullDetail.FullBody = FullBody;

const Sidebar = ({ style, children }: Props) => {
  return (
    <>
      <Panel defaultSize={30} minSize={15} collapsible>
        <div className={styles.sidebar}>
          <div className={styles.sidebarContent} style={style}>
            {children}
          </div>
        </div>
      </Panel>
      <CustomResizeHandle orientation="horizontal" />
    </>
  );
};
Body.Sidebar = Sidebar;

const Content = ({ style, children }: Props) => {
  return (
    <Panel defaultSize={70} minSize={0}>
      {children}
    </Panel>
  );
};
Body.Content = Content;

const Flex = ({ style, children }: Props) => {
  return <Panel style={{ outline: "1px solid var(--gray-200)", width: "100%", height: "100%" }}>{children}</Panel>;
};
Content.Flex = Flex;

// const Row = ({ style, children }: Props) => {
//   return (
//     <div className={styles.flex} style={style}>
//       {children}
//     </div>
//   );
// };
// Content.Row = Row;

// const RowResizable = ({ style, children }: Props) => {
//   // const ref = useRef<HTMLDivElement>(null);
//   const [def, setDef] = useState("1fr");
//   const ref = useCallback((node: HTMLDivElement) => {
//     if (node) setTimeout(() => setDef("auto"), 500);
//   }, []);
//   // useLayoutEffect(() => {
//   //   if (ref.current) setDef("auto");
//   // }, [ref.current]);
//   return (
//     <ResizePanel direction="s" handleClass={"horizontalHandle"} borderClass={"horizontalResizeBorder"}>
//       <div className={styles.main_body_content_row} ref={ref} style={{ ...style, gridAutoColumns: def }}>
//         {children}
//       </div>
//     </ResizePanel>
//   );
// };
// Content.RowResizable = RowResizable;

// const Col = ({ style, children }: Props) => {
//   return (
//     <div className={styles.flex} style={style}>
//       {children}
//     </div>

//     // </PanelGroup>

//     // </Panel>
//     // <div className={styles.flex} style={style}>
//     //   {children}
//     // </div>
//   );
// };
// Content.Col = Col;

// const ColResizable = ({ style, children }: Props) => {
//   const { ref, height, width } = useResizeDetector();
//   return (
//     <ResizePanel direction="e" handleClass={"verticalHandle"} borderClass={"verticalResizeBorder"}>
//       <div className={styles.flex} ref={ref}>
//         {ref.current && <div style={{ ...style, height: height, width: width }}>{children}</div>}
//       </div>
//     </ResizePanel>
//   );
// };
// Content.ColResizable = ColResizable;
export default FullDetail;
