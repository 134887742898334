import produce from "immer";
import { useCallback } from "react";

import { ViewerButtonLine } from "../SpectrumViewer/ViewerNavigation/Menu/ViewerButtonLine";
import { CheckBoxSelectorDiv, SelectorElement, selectState } from "../ViewerUIElements/CheckBoxSelector";
import { viewerModeType } from "./TableComponentTypes";

export const TableViewerNavigation = ({
  viewerMode,
  setViewerMode,
}: {
  viewerMode: viewerModeType;
  setViewerMode?: (viewerMode: viewerModeType) => void;
}) => {
  const onChange = useCallback(
    (type: keyof viewerModeType, settings: Record<string, selectState>) => {
      if (setViewerMode)
        setViewerMode(
          produce(viewerMode, (next: any) => {
            next[type] = settings;
          })
        );
    },
    [setViewerMode, viewerMode]
  );

  const elementProps = [
    {
      title: "Fix:",
      multiselect: true,
      preselect: viewerMode.fixMode,
      items: [
        { key: "row", text: "First row" },
        { key: "column", text: "First colum" },
      ],
      onClick: (state: Record<string, selectState>) => onChange("fixMode", state),
      // onClick: (state: Record<string, boolean>) => (onClick ? onClick(ids, "annotation", state) : {}),
    },
    {
      title: "Show index:",
      multiselect: true,
      preselect: viewerMode.showIndex,
      items: [
        { key: "row", text: "Row" },
        { key: "column", text: "Column" },
      ],
      onClick: (state: Record<string, selectState>) => onChange("showIndex", state),
      // onClick: (state: Record<string, boolean>) => (onClick ? onClick(ids, "annotation", state) : {}),
    },
    {
      title: "Letter index:",
      multiselect: true,
      preselect: viewerMode.letterIndex,
      items: [
        { key: "row", text: "Row" },
        { key: "column", text: "Column" },
      ],
      onClick: (state: Record<string, selectState>) => onChange("letterIndex", state),
      // onClick: (state: Record<string, boolean>) => (onClick ? onClick(ids, "annotation", state) : {}),
    },
  ] as SelectorElement[];

  return (
    <ViewerButtonLine>
      <CheckBoxSelectorDiv elements={elementProps} />
    </ViewerButtonLine>
  );
};
