import { useCallback, useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
// import { useHistory, useLocation } from "react-router-dom";
import { LucideIcon } from "../../common/icon/LucideIcon";
import styles from "../../common/sidebar/forms/sidebarFormStyles.module.css";
import { Role, RoleFilters } from "../../api/Role";
import { ToggleFormField } from "../../common/formfields/ToggleFormField";
import { DefinedPropertyObject, IEntityMinimalModel } from "../../api/GenericTypes";
import { FilterSidebarProps, useSidebarValues } from "../../common/sidebar/filtersidebar/FilterSidebar";
import { PersonsVirtualizedSelectForm } from "../../common/forms/EntityForms/formsVirtualized/PersonsVirtualizedSelectForm";
import { SplitDatePickerFormField } from "../../common/formfields/DatePickerFormField/SplitDatePickerFormField";

export interface RoleFilterForm {
  includeInternals?: boolean | undefined | null;
  createdByIds?: IEntityMinimalModel[] | undefined | null;
  createdFrom?: Date | undefined | null;
  createdTo?: Date | undefined | null;
  modifiedByIds?: IEntityMinimalModel[] | undefined | null;
  modifiedFrom?: Date | undefined | null;
  modifiedTo?: Date | undefined | null;
}
const defaultValues: DefinedPropertyObject<RoleFilterForm> = {
  includeInternals: null,
  createdByIds: null,
  createdFrom: null,
  createdTo: null,
  modifiedByIds: null,
  modifiedFrom: null,
  modifiedTo: null,
};

export const RoleFilterBar = ({
  initialValues,
  setFilters,
  dispatchTabStore,
  onFormChange,
  tabsLoading,
  currentTab,
}: FilterSidebarProps<Role, RoleFilterForm, RoleFilters>) => {
  const initialForm = useMemo(() => initialValues, [initialValues]);
  const values = useSidebarValues({ defaultValues, initialValues: initialForm });

  const { control, reset, watch } = useForm<RoleFilterForm>({
    defaultValues: defaultValues,
    values: values,
  });

  const formToFilters = useCallback((form: RoleFilterForm): RoleFilters => {
    let filters: RoleFilters = {};
    filters.includeInternals = form.includeInternals || false;
    filters.createdByIds =
      Array.isArray(form.createdByIds) && form.createdByIds.length > 0 ? form.createdByIds?.map((d) => d.id) : null;
    filters.createdFrom = form.createdFrom ? form.createdFrom.toISOString() : null;
    filters.createdTo = form.createdTo ? form.createdTo.toISOString() : null;
    filters.modifiedByIds =
      Array.isArray(form.modifiedByIds) && form.modifiedByIds.length > 0 ? form.modifiedByIds?.map((d) => d.id) : null;
    filters.modifiedFrom = form.modifiedFrom ? form.modifiedFrom.toISOString() : null;
    filters.modifiedTo = form.modifiedTo ? form.modifiedTo.toISOString() : null;

    return filters;
  }, []);

  useEffect(() => {
    const subscription = watch(async (value: any) => {
      onFormChange?.(value);
      setFilters?.((prev) => ({ ...prev, ...formToFilters(value as RoleFilterForm) }));
      await dispatchTabStore?.({
        type: "setTab",
        options: { keepPrevious: true },
        payload: {
          settings: {
            sidebarFilters: {
              ...(value as RoleFilterForm),
            },
            filters: formToFilters(value as RoleFilterForm),
          },
        },
      });
    });
    return () => subscription.unsubscribe();
  }, [dispatchTabStore, formToFilters, onFormChange, setFilters, watch]);

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
      }}
      autoComplete="off"
      style={{ position: "relative" }}
      className={styles.form_container}
    >
      <div style={{ minWidth: "200px" }}>
        <ToggleFormField id="includeInternals" control={control} label="Include internals" horizontal />
      </div>

      {currentTab !== "mydata" && (
        <PersonsVirtualizedSelectForm id="createdByIds" label="Created by" control={control} isMulti />
      )}
      <SplitDatePickerFormField
        control={control}
        idStart="createdFrom"
        idEnd="createdTo"
        label="Creation date (from - to)"
        horizontal={false}
        showTimeSelect
      />
      <PersonsVirtualizedSelectForm id="modifiedByIds" label="Modified by" control={control} isMulti />

      <SplitDatePickerFormField
        control={control}
        idStart="modifiedFrom"
        idEnd="modifiedTo"
        label="Modification date (from - to)"
        horizontal={false}
        showTimeSelect
      />

      <div style={{ position: "sticky", bottom: 0, zIndex: 10 }}>
        <button
          type="reset"
          onClick={async (e) => {
            e.preventDefault();
            reset(defaultValues);
            await dispatchTabStore?.({
              type: "setTab",
              payload: {
                settings: {
                  sidebarFilters: defaultValues,
                  filters: {},
                },
              },
            });
          }}
          className="btn btn-primary btn-block"
        >
          <LucideIcon name="refresh-cw" />
          <span> Reset filters</span>
        </button>
      </div>
    </form>
  );
};
