import {
  CustomField,
  CustomFieldFilters,
  CustomFieldFiltersTranslator,
  CustomFieldSortingOptionsConsts,
  customFieldConstants,
} from "../../../api/CustomFields";
import { DateTimeRenderer } from "../../datetime/DateTimeFormatter";
import { EntitySidebarTile, EntitySidebarTileProps } from "./EntitySidebarTile/EntitySidebarTile";
import { NotSet } from "../../misc/UIconstants";
import { AbstractedCustomFieldDataTypeIcon } from "../../../Customization/CustomFields/CustomFieldRenderUtils";
import { generateAbstractedFromDataType } from "../../../Customization/CustomFields/CustomFieldUtils";
import { GetPersons } from "../../misc/EntityRenders/EntityRenderer";

export const CustomFieldsTile = (props: EntitySidebarTileProps<CustomField, CustomFieldFilters>) => {
  return (
    <EntitySidebarTile<CustomField, CustomFieldFilters>
      {...props}
      entityConstants={customFieldConstants}
      orderByOptions={CustomFieldSortingOptionsConsts}
      translatorConsts={CustomFieldFiltersTranslator}
      defaultFilters={{ orderBy: "CREATED_ON_DESC", ...props.defaultFilters }}
      rowLabel={(e) => <>{e.name}</>}
      rowDate={(e) => (
        <>{e.createdOn ? <DateTimeRenderer date={e.createdOn} includeElapsed={false} includeTime={false} /> : NotSet}</>
      )}
      // rowInfo={(e) => <>{generateAbstractedFromDataType(e?.dataType).abstractedDataType || NotSet}</>}
      rowInfo={(e) => <>{e.createdBy ? <GetPersons persons={e.createdBy} createLinks={false} /> : NotSet}</>}
      iconOverride={(e) => (
        <AbstractedCustomFieldDataTypeIcon dataType={generateAbstractedFromDataType(e?.dataType).abstractedDataType!} />
      )}
    />
  );
};

// interface Props {
//   filter?: CustomFieldFilters;
//   title?: string;
// }

// export const CustomFieldsTile = ({ filter = {}, title = "Fields" }: Props) => {
//   const history = useHistory();
//   const { route } = useContext(SessionContext);
//   // const [pageSize, setPageSize] = useState(5);
//   const pageSize = 5;
//   const [customFields, setCustomFields] = useState<CustomField[]>([]);
//   const [searchValue, setSearchValue] = useState("");
//   const debouncedSearchValue = useDebouncedValue(searchValue, 500);
//   const { data, error, fetchNextPage, hasNextPage, isFetching, status, fetchStatus, count } =
//     useInfiniteListEntity<CustomField>(
//       "custom_fields",
//       {
//         page: 1,
//         pageSize: pageSize,
//         includeCount: true,
//
//         ...(debouncedSearchValue && { searchTerm: debouncedSearchValue }),
//         ...filter,
//       },
//       { enabled: !!filter && !!pageSize },
//       "post"
//     );

//   useEffect(() => {
//     if (data) {
//       setCustomFields(data.pages.map((d) => d.results).flat());
//     }
//     return () => {
//       setCustomFields([]);
//     };
//   }, [data]);

//   const handleRowClick = useCallback(
//     (customFieldId: string) => {
//       history.push(route(`${getIndexRoute("manage")}/custom_fields/${customFieldId}`));
//     },
//     [history, route]
//   );

//   const fetchNext = useCallback(() => {
//     if (hasNextPage) {
//       fetchNextPage();
//     }
//   }, [fetchNextPage, hasNextPage]);

//   const loadMoreCallback = useCallback(() => {}, []);
//   const itemCount = hasNextPage ? customFields.length + 1 : customFields.length;
//   const loadMoreItems = isFetching ? loadMoreCallback : fetchNext;
//   const isItemLoaded = useCallback(
//     (index: number) => !hasNextPage || index < customFields.length,
//     [customFields.length, hasNextPage]
//   );
//   const Item = useCallback(
//     ({ index, style }: { index: number; style: CSSProperties }) => {
//       const customField = customFields[index];
//       if (!isItemLoaded(index)) {
//         return (
//           <div style={{ ...style, paddingBottom: "5px" }} key={index}>
//             <span className="skeleton-block btn-lg" style={{ height: 51 }} />
//           </div>
//         );
//       } else {
//         return (
//           <div style={{ ...style, paddingBottom: "5px" }} key={index}>
//             <Link
//               key={index}
//               to={route(`${getIndexRoute("manage")}/custom_fields/${customField.id}`)}
//               style={{ textDecoration: "none" }}
//             >
//               <ContentRow
//                 key={index}
//                 icon={widgetTranslator(customField.widget)[1]}
//                 label={customField.name}
//                 date={
//                   customField.createdAt ? (
//                     <DateTimeRenderer date={customField.createdAt} includeElapsed={false} includeTime={false} />
//                   ) : (
//                     "-"
//                   )
//                 }
//                 id={<>Field-ID: {customField.id}</>}
//                 info={customField.owner ? customField.owner.name : "-"}
//                 onClick={() => handleRowClick(customField.id)}
//               />
//             </Link>
//           </div>
//         );
//       }
//     },
//     [customFields, handleRowClick, isItemLoaded, route]
//   );

//   return (
//     <Tile isFolded={debouncedSearchValue ? false : !!!customFields?.hasOwnProperty("0")}>
//       <Tile.Head
//         title={
//           <div className="flex row-nowrap align-center gap-5">
//             {title}
//             {count !== undefined && <span className="badge">{count}</span>}
//           </div>
//         }
//       >
//         <Tile.Head.Controls>
//           <Tile.Head.Controls.Unfolded>
//             <SearchInput searchValue={searchValue} setSearchValue={setSearchValue} minimized={false} />
//             {/* {documents && documents.hasOwnProperty("0") && (
//             <button
//               className={"btn btn-sm btn-ghost-primary"}
//               title="Download all"
//               onClick={() => alert("Need endpoint Download All")}
//             >
//               <FeatherIcon name="download" />
//             </button>
//           )} */}
//           </Tile.Head.Controls.Unfolded>
//         </Tile.Head.Controls>
//       </Tile.Head>
//       <Tile.Body>
//         <div className={styles.column_container}>
//           <LoadingWrapper status={status} fetchStatus={fetchStatus} error={error}>
//             <div className={isFetching ? `${styles.container} ${styles.container_loading}` : styles.container}>
//               {customFields && customFields.length > 0 ? (
//                 <InfiniteLoader isItemLoaded={isItemLoaded} itemCount={itemCount} loadMoreItems={loadMoreItems as any}>
//                   {({ onItemsRendered, ref }) => (
//                     <FixedSizeList
//                       itemCount={itemCount}
//                       onItemsRendered={onItemsRendered}
//                       ref={ref}
//                       width="100%"
//                       height={customFields.length > pageSize ? pageSize * 59 : customFields.length * 59}
//                       itemSize={59}
//                     >
//                       {Item}
//                     </FixedSizeList>
//                   )}
//                 </InfiniteLoader>
//               ) : (
//                 <>
//                   {isFetching ? (
//                     <Skeleton type="rows" />
//                   ) : (
//                     <>
//                       {debouncedSearchValue ? (
//                         <Alert type="light" message={`No results for: "${debouncedSearchValue}"`} fit centered />
//                       ) : (
//                         <Alert type="light" message="No related custom fields" fit centered />
//                       )}
//                     </>
//                   )}
//                 </>
//               )}
//             </div>
//           </LoadingWrapper>
//         </div>
//       </Tile.Body>
//     </Tile>
//   );
// };
