import { Modal } from "react-bootstrap";
import { LucideIcon, IconNames } from "../../icon/LucideIcon";
import styles from "./AlertModal.module.css";
import { CSSProperties, useEffect, useRef, useState } from "react";
import { Button } from "../../buttons/Button/Button";

interface Props {
  showModal: boolean;
  setShowModal: (value: React.SetStateAction<boolean>) => void;
  title?: string | JSX.Element;
  description?: string | JSX.Element;
  proceedLabel?: string;
  onCancel?: () => void;
  onProceed: () => void;
  loading?: boolean; // Flag to indicate loading -> Disables buttons
  type?: "warning" | "danger" | "success" | "primary";
  forceCountdown?: boolean;
  forceUserInput?: boolean;
  forceUserInputText?: string;
  backdropClassName?: string;
  modalStyle?: CSSProperties;
}
export const AlertModal = ({
  showModal,
  setShowModal,
  title,
  description,
  proceedLabel = "Delete",
  onCancel,
  onProceed,
  loading,
  type = "danger",
  forceCountdown = false,
  forceUserInput = false,
  forceUserInputText = "DELETE",
  backdropClassName,
  modalStyle,
}: Props) => {
  const disableOTPmilliseconds = 5000;
  const [isDisabled, setIsDisabled] = useState(false);
  const [disableTimer, setDisableTimer] = useState(disableOTPmilliseconds / 1000);
  const interval = useRef<NodeJS.Timeout>();
  const timeout = useRef<NodeJS.Timeout>();

  useEffect(() => {
    setIsDisabled(forceCountdown ? true : forceUserInput ? true : false);
    if (forceCountdown) {
      if (showModal) {
        interval.current = setInterval(() => {
          return setDisableTimer((prev) => {
            if (prev > 0) {
              return prev - 1;
            } else {
              return 0;
            }
          });
        }, 1000);
        timeout.current = setTimeout(
          () => {
            setIsDisabled(false);
            setDisableTimer(disableOTPmilliseconds / 1000);
            interval.current && clearInterval(interval.current);
          },

          5000
        );
      } else {
        interval.current && clearInterval(interval.current);
        timeout.current && clearInterval(timeout.current);
        setIsDisabled(true);
        setDisableTimer(disableOTPmilliseconds / 1000);
      }
    }
    return () => {
      interval.current && clearInterval(interval.current);
      timeout.current && clearInterval(timeout.current);
    };
  }, [forceCountdown, forceUserInput, showModal]);

  const validateUserInput = (input: string) => {
    if (input === forceUserInputText) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  };

  const switchType = (_type: typeof type): { headerCls: string; icon?: IconNames; iconCls: string } => {
    switch (_type) {
      case "primary":
        return { headerCls: styles.primary, icon: "circle-help", iconCls: styles.primary_svg };
      case "danger":
        return { headerCls: styles.danger, icon: "triangle-alert", iconCls: styles.danger_svg };
      case "success":
        return { headerCls: styles.success, icon: "check", iconCls: styles.success_svg };
      case "warning":
        return { headerCls: styles.warning, icon: "circle-alert", iconCls: styles.warning_svg };
      default:
        return { headerCls: "", icon: undefined, iconCls: "" };
    }
  };

  const { headerCls, icon, iconCls } = switchType(type);

  return (
    <Modal
      show={showModal}
      onHide={() => {
        setShowModal(() => false);
      }}
      backdropClassName={backdropClassName}
      style={{ ...modalStyle, zIndex: 999997 }}
    >
      <Modal.Header className={`${styles.modal_header} ${headerCls}`}>
        <Modal.Title className={styles.modal_title}></Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className={styles.modal_body}>
          <div className={styles.modal_body_icon}>{icon && <LucideIcon name={icon} className={iconCls} />}</div>
          <div className={styles.modal_body_content}>
            <div className={styles.modal_body_content_title}>{title}</div>
            <div className={styles.modal_body_content_description}>{description}</div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="flex col-nowrap gap-5">
          {forceUserInput && (
            <div className="form-group" style={{ margin: 0 }}>
              <div className="flex row-nowrap align-center gap-5">
                <label className="control-label" style={{ whiteSpace: "nowrap", margin: 0 }}>
                  Confirm action:
                </label>
                <input
                  className="form-control input-sm"
                  type="text"
                  placeholder={`Type: "${forceUserInputText}" to proceed`}
                  onKeyPress={(e) => {
                    if (e.key === "Enter" && !isDisabled) {
                      onProceed();
                    }
                  }}
                  onChange={(e) => validateUserInput(e.target.value)}
                />
              </div>
            </div>
          )}

          <div className={styles.modal_footer}>
            <button
              className="btn btn-soft-secondary"
              onClick={() => {
                onCancel?.();
                setShowModal(false);
              }}
              disabled={loading}
            >
              Cancel
            </button>

            <Button className={`btn btn-${type}`} onClick={onProceed} disabled={isDisabled} loading={loading}>
              {isDisabled && forceCountdown && `(${disableTimer})`} {proceedLabel}
            </Button>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
};
