import { DefinedPropertyObject, IEntityMinimalModel } from "../../api/GenericTypes";
// import { PersonsVirtualizedSelectForm } from "../../common/forms/EntityForms/formsVirtualized/PersonsVirtualizedSelectForm";
import { SplitDatePickerFormField } from "../../common/formfields/DatePickerFormField/SplitDatePickerFormField";
import { FilterSidebarProps, useFilterSidebar } from "../../common/sidebar/filtersidebar/FilterSidebar";
import { EntityFilterSideBar } from "../../common/sidebar/filtersidebar/EntityFilterBar";
import { EquipmentFieldLabels } from "../../api/Equipment";
import { EquipmentFacility, EquipmentFacilityFilters } from "../../api/Facilities";
import { DatasetsVirtualizedSelectForm } from "../../common/forms/EntityForms/formsVirtualized/DatasetsVirtualizedSelectForm";
import { SelectFormField } from "../../common/formfields/SelectFormField";

type EquipmentStatus = { id: number; name: string; value: boolean };
export interface EquipmentFilterForm {
  datasetIds?: IEntityMinimalModel[] | undefined | null;
  isObsolete?: EquipmentStatus | undefined | null;
  createdByIds?: IEntityMinimalModel[] | undefined | null;
  createdFrom?: Date | undefined | null;
  createdTo?: Date | undefined | null;
  modifiedByIds?: IEntityMinimalModel[] | undefined | null;
  modifiedFrom?: Date | undefined | null;
  modifiedTo?: Date | undefined | null;
}

const defaultValues: DefinedPropertyObject<EquipmentFilterForm> = {
  datasetIds: null,
  isObsolete: null,
  createdByIds: null,
  createdFrom: null,
  createdTo: null,
  modifiedByIds: null,
  modifiedFrom: null,
  modifiedTo: null,
};

const formToFilters = (form: EquipmentFilterForm): EquipmentFacilityFilters => {
  let filters: EquipmentFacilityFilters = {};

  filters.datasetIds =
    Array.isArray(form.datasetIds) && form.datasetIds.length > 0 ? form.datasetIds?.map((d) => d.id) : null;
  filters.isObsolete = form.isObsolete?.value ?? null;
  filters.createdByIds =
    Array.isArray(form.createdByIds) && form.createdByIds.length > 0 ? form.createdByIds?.map((d) => d.id) : null;
  filters.createdFrom = form.createdFrom ? form.createdFrom.toISOString() : null;
  filters.createdTo = form.createdTo ? form.createdTo.toISOString() : null;
  filters.modifiedByIds =
    Array.isArray(form.modifiedByIds) && form.modifiedByIds.length > 0 ? form.modifiedByIds?.map((d) => d.id) : null;
  filters.modifiedFrom = form.modifiedFrom ? form.modifiedFrom.toISOString() : null;
  filters.modifiedTo = form.modifiedTo ? form.modifiedTo.toISOString() : null;

  return filters;
};

const defaultFilters: EquipmentFacilityFilters = { orderBy: "NAME_ASC" };

export const EquipmentFilterBar = ({
  initialValues,
  setFilters,
  dispatchTabStore,
  onFormChange,
  tabsLoading,
  currentTab,
}: FilterSidebarProps<EquipmentFacility, EquipmentFilterForm, EquipmentFacilityFilters>) => {
  const { control, onReset, register } = useFilterSidebar({
    initialValues,
    defaultValues,
    defaultFilters,
    formToFilters,
    onFormChange,
    dispatchTabStore,
    setFilters,
  });

  return (
    <EntityFilterSideBar onReset={onReset}>
      <DatasetsVirtualizedSelectForm
        id="datasetIds"
        label={"Datasets"}
        control={control}
        horizontal={false}
        showControls
        isMulti
      />

      <SelectFormField
        id="isObsolete"
        label={EquipmentFieldLabels.isObsolete}
        {...register}
        items={
          [
            { id: 2, name: "not in use", value: true },
            { id: 3, name: "in use", value: false },
          ] as EquipmentStatus[]
        }
        control={control}
        horizontal={false}
      />

      {/* {currentTab !== "mydata" && (
        <PersonsVirtualizedSelectForm id="createdByIds" label="Created By" control={control} isMulti />
      )} */}
      <SplitDatePickerFormField
        control={control}
        idStart="createdFrom"
        idEnd="createdTo"
        label="Creation date (from - to)"
        horizontal={false}
        showTimeSelect
      />

      {/* <PersonsVirtualizedSelectForm id="modifiedByIds" label="Modified By" control={control} isMulti /> */}
      <SplitDatePickerFormField
        control={control}
        idStart="modifiedFrom"
        idEnd="modifiedTo"
        label="Modification date (from - to)"
        horizontal={false}
        showTimeSelect
      />
    </EntityFilterSideBar>
  );
};
