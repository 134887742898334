import { CSSProperties, ReactNode } from "react";
import { defaultCellRangeRenderer, GridCellRangeProps, Grid } from "react-virtualized";
import { MainCell } from "./MainCell";
import {
  CellElementProps,
  CellIndex,
  CellSpan,
  CellState,
  columnSizeType,
  FormattedTableCell,
  indexToKey,
  rowSizeType,
} from "./SpreadSheetTypes";

export const CellRangeRenderer = (
  props: GridCellRangeProps & {
    cellState: CellState;
    spanningCells: CellSpan[];
    showColumnIndex: boolean;
    showRowIndex: boolean;
    fixedRowCount: number;
    fixedColumnCount: number;
    setCellState: (cellState: CellState) => void;
    ContentCellElement?: (props: CellElementProps) => JSX.Element;
    CellValues: (index: CellIndex) => FormattedTableCell;
  }
) => {
  // if (getParamNames(props.cellRenderer)?.[0] === "_ref") {
  //   return defaultCellRangeRenderer({ ...props });
  // }
  // if (getParamNames(props.cellRenderer)?.[0] === "_ref3") {
  //   return defaultCellRangeRenderer({ ...props });
  // }

  if (!(props.parent as any)?._scrollingContainer) (props.parent as Grid).forceUpdate();
  // if (getParamNames(props.cellRenderer)?.[0] !== "_ref2") return defaultCellRangeRenderer({ ...props });
  if (!(props.parent as any)?._scrollingContainer?.className.includes("classNameBottomRightGrid"))
    return defaultCellRangeRenderer({ ...props });

  const {
    rowSizeAndPositionManager,
    columnSizeAndPositionManager,
    horizontalOffsetAdjustment,
    verticalOffsetAdjustment,
    rowStartIndex,
    columnStartIndex,
    rowStopIndex,
    columnStopIndex,
    cellState,
    setCellState,
    spanningCells,
    ContentCellElement,
    CellValues,
    fixedRowCount,
    fixedColumnCount,
    showColumnIndex,
    showRowIndex,
  } = props;
  // const spanningCells: cellSpan[] = [{ row: 4, column: 2, columnSpan: 5, rowSpan: 6 }];

  const cellSpan: Record<string, CellSpan> = Object.fromEntries(
    spanningCells.map((c) => [indexToKey(c.row, c.column), c])
  );

  const cellMapping = Object.fromEntries(
    Object.entries(cellSpan)
      .filter(([k, v]) => {
        const c0 = v.column;
        const c1 = c0 + v.columnSpan;
        const r0 = v.row;
        const r1 = r0 + v.rowSpan;

        return (
          ((r0 >= rowStartIndex && r0 <= rowStopIndex) || (r1 >= rowStartIndex && r1 <= rowStopIndex)) &&
          ((c0 >= columnStartIndex && c0 <= columnStopIndex) || (c1 >= columnStartIndex && c1 <= columnStopIndex))
        );
      })
      .map(([k, v]) => {
        const span: [string, string][] = [];
        for (let r = v.row; r <= v.row + v.rowSpan; r++) {
          for (let c = v.column; c <= v.column + v.columnSpan; c++) {
            span.push([indexToKey(r, c), k]);
          }
        }
        return span;
      })
      .flat()
  );

  const children: ReactNode[] = [];
  const renderedSpan = new Set<string>();
  const rowSizes: Record<number, rowSizeType> = {};
  const columnSizes: Record<number, columnSizeType> = {};

  for (let rowIndex = rowStartIndex; rowIndex <= rowStopIndex; rowIndex++) {
    // This contains :offset (top) and :size (height) information for the cell
    const rowDatum = rowSizeAndPositionManager.getSizeAndPositionOfCell(rowIndex);
    const height = rowDatum.size;
    const top = rowDatum.offset + verticalOffsetAdjustment;

    for (let columnIndex = columnStartIndex; columnIndex <= columnStopIndex; columnIndex++) {
      const columnDatum = columnSizeAndPositionManager.getSizeAndPositionOfCell(columnIndex);
      const width = columnDatum.size;
      const left = columnDatum.offset + horizontalOffsetAdjustment;
      if (columnIndex === columnStartIndex) rowSizes[rowIndex] = { top: top, height: height };
      if (rowIndex === rowStartIndex) columnSizes[columnIndex] = { left: left, width: width };

      const key = indexToKey(rowIndex, columnIndex);
      if (key in cellMapping) {
        const k = cellMapping[key];
        if (renderedSpan.has(k)) continue;
        const v = cellSpan[k];

        const rowDatum = rowSizeAndPositionManager.getSizeAndPositionOfCell(v.row);
        const columnDatum = columnSizeAndPositionManager.getSizeAndPositionOfCell(v.column);
        let left = columnDatum.offset + horizontalOffsetAdjustment;
        let top = rowDatum.offset + verticalOffsetAdjustment;
        let width = 0;
        let height = 0;

        for (let c = v.column; c <= v.column + v.columnSpan; c++)
          width += columnSizeAndPositionManager.getSizeAndPositionOfCell(c).size;

        for (let r = v.row; r <= v.row + v.rowSpan; r++)
          height += rowSizeAndPositionManager.getSizeAndPositionOfCell(r).size;

        const style: CSSProperties = {
          top: top,
          left: left,
          width: width,
          height: height,
          position: "absolute",
          // background: "green",
        };

        children.push(
          <MainCell
            key={k}
            index={k}
            type={"main"}
            columnIndex={columnIndex}
            rowIndex={rowIndex}
            style={{ ...style }}
            cellState={cellState}
            setCellState={setCellState}
            CellValues={(index: CellIndex) => CellValues({ ...index, rowIndex: 13 })}
          />
        );
        renderedSpan.add(k);
        continue;
      }
      // const rowDatum = rowSizeAndPositionManager.getSizeAndPositionOfCell(rowIndex);

      const style: CSSProperties = {
        top: top,
        left: left,
        width: width,
        height: height,
        position: "absolute",
        // background: "green",
      };

      children.push(
        <MainCell
          key={key}
          index={key}
          type={"main"}
          columnIndex={columnIndex}
          rowIndex={rowIndex}
          style={{ ...style }}
          cellState={cellState}
          setCellState={setCellState}
          ContentCellElement={ContentCellElement}
          CellValues={(index: CellIndex) =>
            CellValues({
              rowIndex: index.rowIndex + fixedRowCount - (showColumnIndex ? 1 : 0),
              columnIndex: index.columnIndex + fixedColumnCount - (showRowIndex ? 1 : 0),
            })
          }
        />
      );
    }
  }

  return children;
};
