import {
  ICreatedRecordParametersTranslator,
  ICreationRecordLabels,
  ICustomTypedEntityLabels,
  IGenericRequestParametersTranslator,
  IModificationRecordLabels,
  IModifiedRecordParametersTranslator,
  INamedEntityLabels,
  INamedEntityParametersTranslator,
  IOwnedEntityParametersTranslator,
  IPaginationParametersTranslator,
  IPermissionedEntityLabels,
  IProjectBasedParametersTranslator,
  IRelatedEntityLabels,
  IRelationParametersTranslator,
  ISoftDeletableFilterParametersTranslator,
  ISoftDeletableLabels,
  ITypedEntityCustomValuesSearchParametersTranslator,
  IUniqueEntityLabels,
  PropertyTranslator,
} from "./GenericTranslator";
import {
  IRelationModel,
  IGenericRequestParameters,
  IEntityMinimalModel,
  IModelWithIntId,
  IOwnedEntity,
  IPaginationParameters,
  IPermissionedEntity,
  IProjectBasedResponseModel,
  IRelatedEntity,
  IRelationParameters,
  INamedEntity,
  IRelation,
  ISoftDeletableFilterParameters,
  ISoftDeletable,
  IUniqueEntity,
  IOwnedEntityParameters,
  EntityConstants,
  DefaultOrderTypeConsts,
  DefaultCustomTypeOrderTypeConsts,
  TypedEntitySuggestionsModel,
  ICustomTypedEntity,
  ICreationRecord,
  IModificationRecord,
  ICreatedRecordParameters,
  IModifiedRecordParameters,
  DefaultCreatedByRecordOrderTypeConsts,
  DefaultCreatedOnRecordOrderTypeConsts,
  DefaultModifiedByRecordOrderTypeConsts,
  DefaultModifiedOnRecordOrderTypeConsts,
  ITypedEntityCustomValuesSearchParameters,
  CustomFieldValuesSearchParametersITypedEntity,
  INamedEntityParameters,
  Nullable,
  IProjectBasedParameters,
} from "./GenericTypes";
import { FingerprintFragment } from "./Parsers";

export const datasetsConstants: EntityConstants<Dataset, DatasetFilters> = {
  resource: "datasets",
  frontendIndexRoute: "datasets",
  entitySingular: "dataset",
  entityPlural: "datasets",
  icon: "activity",
};

// interface DatasetExperiment {
//   id: number;
//   // owner: number;
//   name: string;
//   measurementMethodId: number;
//   // measurementMethod: MeasurementMethod;
// }
export type DatasetViewableEntityType = "ELN" | "CustomField";
export type DatasetParsingState = "NotYetParsed" | "ParsedSuccessfully" | "NotParseable" | "ParsingFailed";
export interface Dataset
  extends IModelWithIntId,
    IPermissionedEntity,
    IProjectBasedResponseModel,
    IOwnedEntity,
    INamedEntity,
    IRelatedEntity<DatasetRelations>,
    ISoftDeletable,
    IUniqueEntity,
    ICreationRecord,
    IModificationRecord,
    ICustomTypedEntity {
  // type: string;
  legacyId?: string;
  format?: IEntityMinimalModel<string>;
  acquisitionDate: string;
  path: string;
  sourceBaseDirectory?: string;
  sourceRelativeDirectory?: string;
  method?: IEntityMinimalModel;
  experiment?: IEntityMinimalModel;
  claimed: boolean;
  notes: string;
  isViewableEntity: boolean | null;
  viewableEntityType: DatasetViewableEntityType | null;
  other: string;
  instrument?: IEntityMinimalModel;
  sample?: IEntityMinimalModel;
  autouploadServer?: IEntityMinimalModel;
  bridge?: IEntityMinimalModel;
  operators?: IEntityMinimalModel[];
  equipments?: IEntityMinimalModel[];
  organizations?: IEntityMinimalModel[];
  customImport?: IEntityMinimalModel<string>;
  customImportId?: string;
  parsingState: DatasetParsingState;
  parsedMetadata: {
    parameters: boolean;
    tracks: boolean;
    trackCount: number;
    trackViewerTypes: string[];
  };
  parameters?: Record<string, object>;
  formatVersion?: number;
  parserLogs?: ParserLogModel[];
  tracks?: object;
  datatracks?: MinimalDataTrackModel[];
  tracksHierarchy?: object;
  source: DatasetSource;
  legacyFields: LegacyFields;
  automaticName?: string;
  isManuallyNamed?: boolean;
}

export interface DatasetSuggestions extends TypedEntitySuggestionsModel<Dataset["id"]> {}

export const DatasetSortingOptionsConsts = [
  ...DefaultOrderTypeConsts,
  ...DefaultCustomTypeOrderTypeConsts,
  ...DefaultCreatedOnRecordOrderTypeConsts,
  ...DefaultCreatedByRecordOrderTypeConsts,
  ...DefaultModifiedOnRecordOrderTypeConsts,
  ...DefaultModifiedByRecordOrderTypeConsts,
  "ACQUISITION_DATE_ASC",
  "ACQUISITION_DATE_DESC",
  "METHOD_ASC",
  "METHOD_DESC",
  "EXPERIMENT_ASC",
  "EXPERIMENT_DESC",
  "INSTRUMENT_ASC",
  "INSTRUMENT_DESC",
  "SAMPLE_ASC",
  "SAMPLE_DESC",
  "PARSING_STATE_ASC",
  "PARSING_STATE_DESC",
] as const;
export type DatasetSortingOptions = (typeof DatasetSortingOptionsConsts)[number];

export interface DatasetFilters
  extends IPaginationParameters,
    IGenericRequestParameters<Dataset, DatasetSortingOptions>,
    ITypedEntityCustomValuesSearchParameters<CustomFieldValuesSearchParametersITypedEntity>,
    IRelationParameters,
    ISoftDeletableFilterParameters,
    ICreatedRecordParameters,
    IModifiedRecordParameters,
    IOwnedEntityParameters,
    INamedEntityParameters,
    IProjectBasedParameters {
  includeParsedMetadata?: boolean | null;
  includeParsingState?: boolean | null;
  methodIds?: number[] | null;
  experimentIds?: number[] | null;
  includeDeleted?: boolean;
  sampleIds?: number[] | null;
  acquisitionDateFrom?: string | null;
  acquisitionDateTo?: string | null;
  operatorIds?: number[] | null;
  instrumentIds?: number[] | null;
  equipmentIds?: number[] | null;
  hasExperiment?: boolean | null;
  organizationIds?: number[] | null;
  customFields?: Record<string, number | string | boolean> | null;
  pathContains?: string | null;
  autouploadServer?: number[] | null;
  formatIds?: string[] | null;
  parsingState?: DatasetParsingState[] | null;
  bridgeIds?: number[] | null;
  dataSourceIds?: number[] | null;
  hashes?: string[] | null;
  includeViewableEntities?: boolean | null;
  isClaimed?: boolean | null;
  includeUnclaimed?: boolean | null;
  participatedPersonIds?: number[] | null;
  parameters?: Record<string, number | string | boolean> | null;
  isViewableEntity?: boolean | null;
  customFieldIds?: number[] | null;
  customTypeIds?: number[] | null;
  viewableEntityTypes?: DatasetViewableEntityType[] | null;
  searchTermIncludePaths?: boolean | null;
  searchTermIncludeNotes?: boolean | null;
  searchTermIncludeParameters?: boolean | null;
  hasCustomImport?: boolean | null;
  hasOperator?: boolean | null;
}

export const DatasetFiltersTranslator: PropertyTranslator<DatasetFilters> = {
  ...IPaginationParametersTranslator,
  ...IGenericRequestParametersTranslator,
  ...ITypedEntityCustomValuesSearchParametersTranslator,
  ...IRelationParametersTranslator,
  ...ICreatedRecordParametersTranslator,
  ...IModifiedRecordParametersTranslator,
  ...ISoftDeletableFilterParametersTranslator,
  ...IOwnedEntityParametersTranslator,
  ...INamedEntityParametersTranslator,
  ...IProjectBasedParametersTranslator,
  includeParsedMetadata: "Include parsed metadata",
  includeParsingState: "Include parsing state",
  methodIds: "Method IDs",
  experimentIds: "Experiment IDs",
  includeDeleted: "Include trashed",
  sampleIds: "Sample IDs",
  acquisitionDateFrom: "Acquisition date from",
  acquisitionDateTo: "Acquisition date to",
  operatorIds: "Operator IDs",
  instrumentIds: "Instrument IDs",
  equipmentIds: "Suppl. equipment IDs",
  hasExperiment: "Has experiment",
  organizationIds: "Organization IDs",
  customFields: "Custom fields",
  pathContains: "Path contains",
  autouploadServer: "Data source",
  formatIds: "Format IDs",
  parsingState: "Parsing state",
  bridgeIds: "Bridge ID",
  dataSourceIds: "Data source IDs",
  hashes: "Hashes",
  includeViewableEntities: "Include viewable entities",
  isClaimed: "Is claimed",
  includeUnclaimed: "Include unclaimed",
  participatedPersonIds: "Participated person IDs",
  parameters: "Parameters",
  isViewableEntity: "Is viewable entity",
  viewableEntityTypes: "Viewable entity types",
  searchTermIncludeNotes: "notes",
  searchTermIncludePaths: "paths",
  searchTermIncludeParameters: "parameters",
  hasOperator: "Has operator",
  hasCustomImport: "Has custom import",
} as const;

export const DatasetSearchTermOptions = {
  searchTermIncludeNames: DatasetFiltersTranslator["searchTermIncludeNames"],
  searchTermIncludeIds: DatasetFiltersTranslator["searchTermIncludeIds"],
  searchTermIncludeUuids: DatasetFiltersTranslator["searchTermIncludeUuids"],
  searchTermIncludePaths: DatasetFiltersTranslator["searchTermIncludePaths"],
  searchTermIncludeNotes: DatasetFiltersTranslator["searchTermIncludeNotes"],
  searchTermIncludeParameters: DatasetFiltersTranslator["searchTermIncludeParameters"],
} as const;

export const DatasetFieldLabels: PropertyTranslator<Dataset> = {
  ...INamedEntityLabels,
  ...IUniqueEntityLabels,
  ...IPermissionedEntityLabels,
  ...ICreationRecordLabels,
  ...IModificationRecordLabels,
  ...IRelatedEntityLabels,
  ...ISoftDeletableLabels,
  ...ICustomTypedEntityLabels,
  acquisitionDate: "Acquisition date",
  autouploadServer: "Autoupload server",
  bridge: "Bridge",
  claimed: "Claimed",
  customImport: "Metadata mapping",
  customImportId: "Metadata mapping ID",
  datatracks: "Datatracks",
  equipments: "Equipments",
  experiment: "Experiment",
  format: "Data format",
  formatVersion: "Format version",
  id: "Dataset ID",
  instrument: "Instrument",
  isViewableEntity: "Is viewable entity",
  legacyFields: "Legacy fields",
  legacyId: "Legacy ID",
  method: "Measuring method",
  notes: "Notes",
  operators: "Operators",
  organizations: "Organizations",
  other: "Other",
  owner: "Owner",
  parameters: "Parameters",
  parserLogs: "Parser logs",
  parsingState: "Parsing state",
  parsedMetadata: "Parsed metadata",
  path: "Path",
  permissions: "Permissions",
  projects: "Projects",
  sample: "Sample",
  source: "Data source",
  sourceBaseDirectory: "Data source base directory",
  sourceRelativeDirectory: "Data source relative directory",
  tracks: "Tracks",
  tracksHierarchy: "Tracks hierarchy",
  viewableEntityType: "Source type",
  automaticName: "Automatic name",
  isManuallyNamed: "Is manually named",
} as const;

export const AttachmentFieldLabels: PropertyTranslator<Dataset> = {
  ...DatasetFieldLabels,
  id: "Attachment ID",
} as const;

export interface DatasetRelations extends IRelationModel {
  labNotebookEntries: IRelation;
  sharedContent: IRelation;
  projects: IRelation;
}

// Dataset queue
export interface QueueJob {
  Id: number;
  Key: string;
  Priority: number;
  Type: string;
  State: string;
  EnqueueTime: string;
  StartTime: string;
  FinishTime: string;
  DatasetId: number;
  Age: string;
  WaitTime: string;
  RunTime: string;
}

// Dataset files
export interface FilesStructure {
  [name: string]: {
    name?: string;
    contents?: {
      [name: string]: FilesStructure;
    };
    hash?: string;
    size?: number;
    mtime?: number;
    isDirectory?: boolean;
  };
}

export interface requestFileBytes {
  path: string;
  size?: number;
  offset?: number;
}
export interface requestFileLines {
  path: string;
  count: number;
  line: number;
  maxLineLength: number;
}

interface DatasetSource extends IModelWithIntId, INamedEntity {
  type: DatasetSourceType;
}

type DatasetSourceType = "Unknown" | "ManualUpload" | "AutoUpload" | "ForeignServer";

// Dataset Upload
export interface IsDatasetKnown {
  unknownHashes: string[];
}

export interface DatasetFile {
  Name: string;
  Path: string;
  IsPrimary: boolean;
  Mtime: string;
  Size: number;
  Hash: string;
}

export interface DatasetDirectory {
  Name: string;
  Path: string;
  Mtime: string;
}
// export type DatasetSourceType = 0 | 1 | 2; //   ManualUpload, SFTPAutoload, ClientAutoload,

// Deprecated submission form
// export interface DatasetSubmissionForm {
//   DatasetName: string;
//   ParserId: string;
//   MethodId: number | null;
//   InstrumentId: number | null;
//   AutoloadBaseDir?: string;
//   RelativeDir: string;
//   Files: DatasetFile[];
//   Directories: DatasetDirectory[];
//   ParentDirs: string;
//   SourceType: DatasetSourceType;
//   AutoloadConfigurationId?: number;
//   IsViewableEntity?: boolean;
// }

// New submission form
export interface UploadFileModel {
  id: string;
  fullPath: string;
  isUnique: boolean;
  mtime?: string;
}
export interface DatasetForUploadWriteModel extends Partial<Nullable<Dataset>> {
  filePathsAreAbsolute?: boolean;
  files: UploadFileModel[];
}

export interface FormatFileWriteModel {
  id: string;
  fullPath: string;
  mtime?: string;
  fragments?: FingerprintFragment[];
  hash: string;
}
export interface DatasetForSearch {
  id: string;
  parserId: string;
  checkUpdatable: boolean;
  files: FormatFileWriteModel[];
}
export interface DatasetSearchRequest {
  datasets: DatasetForSearch[];
  onlyViewableEntities?: boolean;
}

export type FormatFileState = "NEW" | "UNCHANGED" | "NEEDSUPDATE" | "DELETE";
export interface FormatFileSearchResult {
  id: string;
  fullPath: string;
  state: FormatFileState;
  size?: number;
  mtime?: number;
}
export interface DatasetSearchResult {
  id: string;
  errors: string[] | null;
  logsId?: number;
  isUpdateable?: boolean;
  files: FormatFileSearchResult[];
}

// Update
export interface DatasetForUpdateWriteModel {
  logsId: number;
  files: UploadFileModel[];
}

// Zip file sizes
export interface ZipFileSize {
  size: number;
  datasetCount: number;
  isDownloadable: boolean;
  maxZipFileSize: number;
  maxDatasetCountPerZipFile: number;
}

// Misc
export interface LegacyFields {
  brukerPulseProgram: string;
  brukerTitle: string;
}

export interface ParserLogModel {
  type: string;
  code: number;
  message: string;
}

export interface MinimalDataTrackModel {
  name: string;
  id: string;
  type: string;
  count: number;
  codec: string;
  size: number[];
  min: number[];
  max: number[];
  numberType: string;
}
