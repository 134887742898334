import * as d3 from "d3";
import React, { useEffect, useState, useCallback } from "react";
import { Range } from "react-range";
import { explicitContoursState } from "../ContourClasses";
import { ColorNeedsUpdate, colorType } from "../../ViewerLayout/ViewerTypes";
import { OldColorBox } from "../../ViewerUIElements/ColorBox";
import { useInputDebounce } from "../../ViewerUIElements/InputDebounce";
import { SelectButton } from "../../ViewerUIElements/SelectButton";
import { ColorSliderThumb } from "./ColorSliderThumb";
import { ToolInput, getDigit, ArrayNeedsUpdate, RangeType } from "./ContourEditor";

import styles from "./ExplicitContourEditor.module.css";
import { ToolBox } from "./ToolBox";

export type ContourType = {
  contours: number[];
  range: RangeType;
};

const getOffsetsFromContours = (contours: ContourType): number[] => {
  if (!contours) return [];
  return contours.contours.map((v) => (v - contours.range.min) / contours.range.diff);
};

const getContoursFromOffsets = (offsets: number[], range: RangeType): ContourType => {
  return {
    contours: offsets.map((v) => v * range.diff + range.min),
    range: range,
  };
};

const getColorGenerator = (color: colorType) => {
  let f: (offset: number) => string;
  if (color) {
    // const g = d3
    //   .scaleLinear()
    //   .domain([0, 1])
    //   .range(color.colors as any)
    //   .interpolate(d3.interpolateRgb as any);
    const len = color.offsets.length - 1;

    const g = d3
      .scaleLinear()
      .domain(color.offsets)
      .range(color.colors as any)
      .interpolate(d3.interpolateRgb as any);
    // console.log("generate", color.offsets);
    f = (offset: number): string => {
      if (offset < color.offsets[0]) return color.colors[0];
      if (offset > color.offsets[len]) return color.colors[len];
      return g(offset) as any;
    };

    // console.log("color generate", color, "->", f);
  } else {
    // console.log("default generate");
    f = (offset: number): string => "gray";
  }
  return f;
};

export const ExplicitContourEditor = ({
  width,
  color,
  contours,
  valueMin,
  valueMax,
  setContours,
  setMinHeight,
  setMinWidth,
}: ToolInput) => {
  const [minMaxStr, setMinMaxStr] = useState<string[]>(["", ""]);
  const [values, setValues] = useState<number[]>([]);
  const [relative, setRelative] = useState<boolean>(false);
  const [gradientColor, setGradientColor] = useState<colorType>(color as unknown as colorType);
  const [contourLines, setContourLines] = useState<ContourType>({
    // contours: contours?.generate ? contours.generate(contours.state, valueMin || 0, valueMax || 1) : [],
    contours: [],
    range: {
      min: -Infinity,
      max: Infinity,
      diff: Infinity,
    },
  });
  const [selectedOffset, setSelectedOffset] = useState<number>(-1);
  const [toolBoxState, setToolBoxState] = useState<boolean>(false);
  const [oldToolBoxState, setOldToolBoxState] = useState<boolean>(toolBoxState);

  // console.log("ExplicitContourEditor", { width, color, contours, valueMin, valueMax, setContours });
  // console.log("ExplicitContourEditor", contours);

  const debouncedContour = useInputDebounce<ContourType>(contourLines, 300);

  useEffect(() => {
    if (setMinHeight) setMinHeight(160);
    if (setMinWidth) setMinWidth(440);
  }, [setMinHeight, setMinWidth]);

  // console.log(">>", color);

  useEffect(() => {
    // console.log("debounce contour", ArrayNeedsUpdate(contours, debouncedContour.contours));

    if (debouncedContour.contours.length > 0) {
      // console.log("debounce contour", debouncedContour.contours);
      // setContours({ contours: debouncedContour.contours });
      const state: explicitContoursState = {
        contours: debouncedContour.contours,
        type: contours.state.type,
      };
      setContours({ generate: contours.generate, state: state });
    }
    // if (debouncedContour.contours.length > 0) setContours(debouncedContour.contours);
    // if (debouncedContour.contours.length > 0)
  }, [debouncedContour, contours.generate, contours.state.type, setContours]);

  useEffect(() => {
    // if (color && color.colors.some((c, i) => c !== gradientColor.colors[i])) {
    if (color && ColorNeedsUpdate(gradientColor, color as unknown as colorType)) {
      // console.log("colors", color.offsets);
      // setValues(color.offsets);
      setGradientColor(color as unknown as colorType);
    }
  }, [color, gradientColor]);

  const updateValuesFromContours = (contours: ContourType): boolean => {
    // console.log("updateValuesFromContours", contours, getOffsetsFromContours(contours));
    return updateValues(getOffsetsFromContours(contours));
  };

  const updateValues = (valueList: number[]): boolean => {
    // console.log("update", values, "->", valueList, ArrayNeedsUpdate(values, valueList));
    if (ArrayNeedsUpdate(values, valueList)) {
      setValues(valueList);
      return true;
    }
    return false;
  };

  const updateContoursFromValues = (values: number[], range: RangeType): boolean => {
    // console.log("updateValuesFromContours");
    return updateContours(getContoursFromOffsets(values, range));
  };

  const updateContours = (contours: ContourType): boolean => {
    if (ArrayNeedsUpdate(contourLines.contours, contours.contours)) {
      setContourLines(contours);
      return true;
    }
    return false;
  };

  useEffect(() => {
    // console.log("contours", contours, valueMin, valueMax);
    // if (color && color.colors.some((c, i) => c !== gradientColor.colors[i])) {
    let range: RangeType = contourLines.range;
    // console.log("range", range.min, valueMin);
    if (Math.abs(range.min - valueMin) > Number.EPSILON || Math.abs(range.max - valueMax) > Number.EPSILON) {
      range = { min: valueMin || 0, max: valueMax || 1, diff: valueMax - valueMin || 1 };
      const digit = getDigit(range.diff);
      const rangeStr = [range.min.toFixed(digit), range.max.toFixed(digit)];
      if (rangeStr.some((r, i) => r !== minMaxStr[i])) setMinMaxStr(rangeStr);
    }

    // console.log("contours", contourLines, "->", contours, range);
    let c: number[] = contours?.generate ? contours.generate(contours.state, range.min, range.max) : [];
    if (c.length < 1) {
      // c.push((range.max - range.min) / 2);
      c.push(range.min + range.diff / 2);
    }
    // console.log("contours", contourLines.contours, c, range.min + range.diff / 2);
    if (contours && ArrayNeedsUpdate(contourLines.contours, c)) {
      const con: ContourType = { contours: c, range: range };
      setContourLines(con);
      updateValuesFromContours(con);
    }
  }, [contours, valueMin, valueMax]);

  useEffect(() => {
    if (values.length > 0) updateContoursFromValues(values, contourLines.range);
  }, [values]);

  useEffect(() => {
    setToolBoxState(selectedOffset >= 0 && selectedOffset < values.length);
  }, [gradientColor.offsets, selectedOffset]);

  useEffect(() => {
    if (setMinHeight && !oldToolBoxState && toolBoxState) setMinHeight(340);
    if (setMinHeight && oldToolBoxState && !toolBoxState) setMinHeight(160);
    setOldToolBoxState(toolBoxState);
  }, [toolBoxState]);

  // const changeContour = useCallback(
  //   (contour: ContourType) => {
  //     const valueList = getOffsetsFromContours(contour);

  //     // console.log("valueList", valueList);
  //     if (relative && values.length === valueList.length) changeValueRelativ(values, valueList);
  //     updateValues(valueList);
  //     // updateValuesFromContours(contour);
  //   },
  //   [contourLines, values]
  // );

  const changeValueRelativ = (values: number[], valueList: number[]): void => {
    // if (index < 0 || index >= valueList.length) return;

    const list = valueList.map((v, i) => i).filter((i) => valueList[i] !== values[i]);
    if (list.length !== 1) return;
    const index = list[0];

    const oldValue = values[index];
    const value = valueList[index];
    let scale = value / oldValue;
    for (let i = 0; i < index; i++) {
      // console.log("left", i, valueList[i], (value * valueList[i]) / oldValue);
      valueList[i] *= scale;
    }

    scale = (1 - value) / (1 - oldValue);
    for (let i = index + 1; i < valueList.length; i++) {
      // console.log("right", i, valueList[i]);
      valueList[i] = value + (valueList[i] - oldValue) * scale;
    }
    // valueList[index] = 0.001;
    // console.log("values", index);
  };

  // console.log("values", contourLines.contours, getOffsetsFromContours(contourLines));

  // const colorGenerator = useCallback((offset: number) => getColorGenerator(gradientColor)(offset), [gradientColor]);
  const svgLine = useCallback((offsets: number[], index: number, width: number) => {
    const v = offsets?.[index];
    if (v === undefined) return null;
    const x0 = v * width || 0;
    return (
      <svg
        style={{
          width: width > 0 ? width : 0,
          height: 35,
          strokeWidth: 3,
          stroke: "black",
          fill: "none",
          // background: ""pin"k",
        }}
      >
        <polyline points={`${x0},2 ${x0},25 20,25, 20,35`} />
      </svg>
    );
  }, []);

  // const sliderThumb = ({ index, props }: { index: number; props: any }) => {
  //   console.log("props", index);
  const sliderThumb = useCallback(({ index, props }: { index: number; props: any }) => {
    // console.log("props", index, props["aria-valuenow"], props.style.zIndex);
    props.style.zIndex = undefined;
    return (
      <div {...props} className={styles.sliderThumb}>
        {/* <div className={styles.sliderThumbBlock} /> */}
        {/* <div>
          <ColorBox color={gradientColors.colors[index]} width={10} height={10} allowColorChange={true}></ColorBox>
        </div> */}
        <div className={styles.sliderThumbLine} />
      </div>
    );
  }, []);

  const grepThumb = ({ index, props }: { index: number; props: any }) => {
    return (
      <div {...props}>
        <ColorSliderThumb
          color={getColorGenerator(gradientColor)(values[index])}
          onSingleClick={() => (index === selectedOffset ? setSelectedOffset(-1) : setSelectedOffset(index))}
          // onSingleClick={() => setSelectedOffset(index)}
        />
      </div>
    );
  };

  return (
    <div>
      {values.length < 1 ? (
        <div style={{ width: width, height: 20 }} />
      ) : (
        <Range
          step={0.001}
          min={0}
          // disabled={disabled}
          max={1}
          values={values}
          renderTrack={({ props, children }: { props: any; children: any }) => {
            return (
              <div {...props}>
                {children}
                <div style={{ width: width, height: 20 }} />
              </div>
            );
          }}
          onChange={(valueList: number[]) => {
            if (relative) changeValueRelativ(values, valueList);
            setValues(valueList);
          }}
          renderThumb={grepThumb}
        />
      )}
      <div style={{ height: 3 }} />

      {values.length < 1 ? (
        <OldColorBox color={gradientColor} width={width} height={20} />
      ) : (
        <Range
          step={0.001}
          min={0}
          // disabled={disabled}
          max={1}
          values={values}
          renderTrack={({ props, children }: { props: any; children: any }) => (
            // <div {...props} className={styles.sliderTrack} style={{ background: getCSSGradient(gradientColors) }}>
            <div {...props}>
              {children}
              {/* <div style={{ height: 20 }} /> */}
              <OldColorBox color={gradientColor} width={width} height={20} />
            </div>
          )}
          onChange={(valueList: number[]) => {
            if (relative) changeValueRelativ(values, valueList);
            setValues(valueList);
          }}
          renderThumb={sliderThumb}
        />
      )}
      <div className={styles.range}>
        <span>{minMaxStr[0]}</span>
        <SelectButton active={relative} setActive={setRelative}>
          Relative scale
        </SelectButton>
        <span>{minMaxStr[1]}</span>
        {/* <span>{color}</span> */}
      </div>

      {toolBoxState && selectedOffset >= 0 ? (
        <div
          style={{
            position: "relative",
            top: -25,
            // background: "green",
          }}
        >
          {svgLine(values, selectedOffset, width || 0)}
          <div style={{ position: "relative", top: -6 }}>
            <ToolBox
              selectedOffset={selectedOffset}
              setSelectedOffset={setSelectedOffset}
              contours={contourLines}
              setContours={(contours: ContourType) => {
                // console.log("con", contours);
                updateValuesFromContours(contours);
                // updateContours(contours);
              }}
              onClose={() => {
                setToolBoxState(false);
                // if (onSettingsClosed) onSettingsClosed();
              }}
            />
          </div>
        </div>
      ) : null}
    </div>
  );
};
