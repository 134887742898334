import styles from "./LinkEntity.module.css";
import { NotSet } from "../UIconstants";
import { EntityConstants, IEntityMinimalModel, IdTypes } from "../../../api/GenericTypes";
import { useContext } from "react";
import { SessionContext } from "../../contexts/SessionContext";
import { getDetailLink } from "../../../main/Routing";
import { IconNames, LucideIcon } from "../../icon/LucideIcon";
import { ConditionalLink } from "../ConditionalLink/ConditionalLink";
interface LinkEntityProps<Entity extends IEntityMinimalModel<IdTypes>> {
  property: Entity | undefined;
  routeOverride?: string;
  labelOverride?: string;
  iconOverride?: IconNames;
  entityConstants: EntityConstants;
  openInNewTab?: boolean;
  hideIcon?: boolean;
  showLink?: boolean;
  style?: React.CSSProperties;
}
export const LinkEntity = <Entity extends IEntityMinimalModel<IdTypes>>({
  property,
  routeOverride,
  labelOverride,
  iconOverride,
  entityConstants,
  openInNewTab,
  hideIcon = false,
  showLink = true,
  style,
}: LinkEntityProps<Entity>) => {
  const { route } = useContext(SessionContext);
  return (
    <>
      {property?.name ? (
        <ConditionalLink
          to={routeOverride ?? route(getDetailLink(entityConstants.frontendIndexRoute, property.id!))}
          className={styles.clickable}
          style={{ display: "block", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap", ...style }}
          showLink={showLink}
          {...(openInNewTab && { target: "_blank" })}
        >
          <div className="ellipsisContainer gap-5">
            <span>
              {!hideIcon && (
                <LucideIcon
                  name={iconOverride || entityConstants.icon}
                  color={"var(--primary)"}
                  style={{ flexShrink: 0 }}
                />
              )}{" "}
              {labelOverride ?? property?.name}
            </span>
          </div>
        </ConditionalLink>
      ) : (
        NotSet
      )}
    </>
  );
};
