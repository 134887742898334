import {
  EquipmentFacility,
  EquipmentFacilityFilters,
  FacilityFiltersTranslator,
  FacilitySortingOptionsConsts,
  equipmentsConstants,
} from "../../../api/Facilities";
import { DateTimeRenderer } from "../../datetime/DateTimeFormatter";
import { NotSet } from "../../misc/UIconstants";
import { EntitySidebarTile, EntitySidebarTileProps } from "./EntitySidebarTile/EntitySidebarTile";

export const EquipmentsTile = (props: EntitySidebarTileProps<EquipmentFacility, EquipmentFacilityFilters>) => (
  <EntitySidebarTile<EquipmentFacility, EquipmentFacilityFilters>
    {...props}
    entityConstants={equipmentsConstants}
    orderByOptions={FacilitySortingOptionsConsts}
    translatorConsts={FacilityFiltersTranslator}
    defaultFilters={{ orderBy: "NAME_ASC", ...props.defaultFilters }}
    rowLabel={(e) => <>{e.name}</>}
    rowDate={(e) => <DateTimeRenderer date={e.createdOn} />}
    rowInfo={(e) => <>{e.model || NotSet}</>}
  />
);

// interface Props {
//   foldable?: boolean;
//   title?: JSX.Element | string;
//   filter?: EquipmentFilters;
//   isFolded?: boolean;
// }

// const PAGE_SIZE = 5;

// export const EquipmentsTile = ({ foldable = true, title = "Equipments", filter = {}, isFolded = false }: Props) => {
//   const { route } = useContext(SessionContext);
//   const history = useHistory();
//   const [searchValue, setSearchValue] = useState("");
//   const debouncedSearchValue = useDebouncedValue(searchValue, 500);

//   const { data, error, fetchNextPage, hasNextPage, isFetching, status, fetchStatus, count } = useInfiniteListEntity<
//     Equipment,
//     EquipmentFilters
//   >(
//     "equipments",
//     {
//       ...filter,
//       page: 1,
//       pageSize: PAGE_SIZE,
//       includeCount: true,
//
//       includeContent: false,
//       searchTerm: debouncedSearchValue,
//       orderBy: "NAME_ASC",
//     },
//     { enabled: true },
//     "post"
//   );

//   const equipments = useMemo(() => {
//     return data?.pages.map((d) => d.results).flat() || [];
//   }, [data]);

//   const handleRowClick = useCallback((equipment: Equipment) => {}, []);

//   const fetchNext = useCallback(() => {
//     if (hasNextPage) {
//       fetchNextPage();
//     }
//   }, [fetchNextPage, hasNextPage]);

//   const loadMoreCallback = useCallback(() => {}, []);
//   const itemCount = hasNextPage ? equipments.length + 1 : equipments.length;
//   const loadMoreItems = isFetching ? loadMoreCallback : fetchNext;
//   const isItemLoaded = useCallback(
//     (index: number) => !hasNextPage || index < equipments.length,
//     [hasNextPage, equipments.length]
//   );
//   const Item = ({ index, style }: { index: number; style: CSSProperties }) => {
//     const current_element = equipments[index];
//     if (!isItemLoaded(index)) {
//       return (
//         <div style={{ ...style, paddingBottom: "5px" }} key={index}>
//           <span className="skeleton-block btn-lg" style={{ height: 51 }} />
//         </div>
//       );
//     } else {
//       return (
//         <div style={{ ...style, paddingBottom: "5px" }} key={index}>
//           <Link
//             key={index}
//             to={route(getDetailLink("equipments", current_element.id))}
//             style={{ textDecoration: "none" }}
//             onClick={() => handleRowClick(current_element)}
//           >
//             <ContentRow
//               key={index}
//               icon={
//                 <div className={styles.icon}>
//                   <FeatherIcon name="user" />
//                 </div>
//               }
//               label={current_element.name}
//               date={<>{current_element.organization?.name || NotSet}</>}
//               id={<>Entry-ID: {current_element.id}</>}
//               info={<>{current_element.email || NotSet}</>}
//               controls={<></>}
//             />
//           </Link>
//         </div>
//       );
//     }
//   };

//   return (
//     <>
//       <Tile
//         isFolded={count === 0 && !debouncedSearchValue ? true : isFolded}
//         foldable={count === 0 && !debouncedSearchValue ? false : foldable}
//         disabled={count === 0 && !debouncedSearchValue}
//       >
//         <Tile.Head
//           title={
//             <div className="flex row-nowrap align-center gap-5">
//               <div>{title}</div>
//               {count !== undefined && <span className="badge">{count}</span>}
//               <button
//                 onClick={(e) => {
//                   e.stopPropagation();
//                   e.preventDefault();
//                   history.push(route(getIndexRoute("equipments")));
//                 }}
//                 data-toggle="tooltip"
//                 title="Show list in fullscreen"
//                 className="btn btn-ghost-secondary"
//               >
//                 <FeatherIcon name="table" />
//               </button>
//             </div>
//           }
//         >
//           <Tile.Head.Controls>
//             <Tile.Head.Controls.Unfolded>
//               <>
//                 <SearchInput searchValue={searchValue} setSearchValue={setSearchValue} minimized={false} />
//                 <button
//                   onClick={(e) => {
//                     e.stopPropagation();
//                     e.preventDefault();
//                     history.push(route(getAddRoute("equipments")));
//                   }}
//                   data-toggle="tooltip"
//                   title="Create new entry"
//                   className="btn btn-ghost-primary btn-sm"
//                 >
//                   <FeatherIcon name="plus" />
//                 </button>
//               </>
//             </Tile.Head.Controls.Unfolded>
//           </Tile.Head.Controls>
//         </Tile.Head>
//         <Tile.Body>
//           <LoadingWrapper status={status} fetchStatus={fetchStatus} error={error} type="skeleton-rows">
//             <div className={isFetching ? `${styles.container} ${styles.container_loading}` : styles.container}>
//               {equipments && equipments.length > 0 ? (
//                 <InfiniteLoader isItemLoaded={isItemLoaded} itemCount={itemCount} loadMoreItems={loadMoreItems as any}>
//                   {({ onItemsRendered, ref }) => (
//                     <FixedSizeList
//                       itemCount={itemCount}
//                       onItemsRendered={onItemsRendered}
//                       ref={ref}
//                       width="100%"
//                       height={equipments.length > PAGE_SIZE ? PAGE_SIZE * 59 : equipments.length * 59}
//                       itemSize={59}
//                     >
//                       {Item}
//                     </FixedSizeList>
//                   )}
//                 </InfiniteLoader>
//               ) : (
//                 <>
//                   {isFetching ? (
//                     <Skeleton type="rows" />
//                   ) : (
//                     <>
//                       {debouncedSearchValue ? (
//                         <Alert type="light" message={`No results for: "${debouncedSearchValue}"`} fit centered />
//                       ) : (
//                         <Alert type="light" message="No entries" fit centered />
//                       )}
//                     </>
//                   )}
//                 </>
//               )}
//             </div>
//           </LoadingWrapper>
//         </Tile.Body>
//       </Tile>
//     </>
//   );
// };
