import { CustomType, CustomTypeWriteModel } from "../../api/CustomTypes";
import { customFieldToCustomFieldForm } from "../CustomFields/CustomFieldUtils";

export const CustomTypeToCustomTypeForm = (
  initialValues: Partial<CustomType> | undefined
): Partial<CustomTypeWriteModel> => {
  let customTypeForm: Partial<CustomTypeWriteModel> = {
    isEnabled: true,
    hasRestrictedAddPermission: false,
    hasRestrictedEditPermission: false,
    hasRestrictedReadPermission: false,
    sections: [],
  };

  if (initialValues?.entityType === "Inventory") {
    customTypeForm.hasRestrictedAddPermission = true;
    customTypeForm.hasRestrictedEditPermission = true;
    customTypeForm.hasRestrictedReadPermission = true;
  }
  customTypeForm = {
    ...customTypeForm,
    ...initialValues,
    sections: initialValues?.sections?.map((section) => {
      return {
        ...section,
        customFields: section.customFields?.map((field) => {
          return {
            ...field,
            ...customFieldToCustomFieldForm(field),
          };
        }),
      };
    }),
  };

  return customTypeForm;
};
