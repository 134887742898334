import {
  Project,
  ProjectFilters,
  ProjectFiltersTranslator,
  ProjectSortingOptionsConsts,
  projectsConstants,
} from "../../../api/Projects";
import { EntitySidebarTile, EntitySidebarTileProps } from "./EntitySidebarTile/EntitySidebarTile";
import { DateTimeRenderer } from "../../datetime/DateTimeFormatter";
import { NotSet } from "../../misc/UIconstants";
import { GetPersons } from "../../misc/EntityRenders/EntityRenderer";

export const ProjectsTile = (props: EntitySidebarTileProps<Project, ProjectFilters>) => (
  <EntitySidebarTile<Project, ProjectFilters>
    {...props}
    entityConstants={projectsConstants}
    orderByOptions={ProjectSortingOptionsConsts}
    translatorConsts={ProjectFiltersTranslator}
    defaultFilters={{ orderBy: "CREATED_ON_DESC", ...props.defaultFilters }}
    rowLabel={(e) => <>{e.name}</>}
    rowDate={(e) => (
      <>{e.createdOn ? <DateTimeRenderer date={e.createdOn} includeElapsed={false} includeTime={false} /> : NotSet}</>
    )}
    rowInfo={(e) => <>{e.owner ? <GetPersons persons={e.owner} createLinks={false} /> : NotSet}</>}
  />
);

// interface Props {
//   foldable?: boolean;
//   isFolded?: boolean;
//   filterquery: ProjectFilters;
//   title?: string;
// }
// export const ProjectsTile = ({ foldable = true, isFolded = false, filterquery, title = "Linked datasets" }: Props) => {
//   const history = useHistory();
//   const { route } = useContext(SessionContext);
//   const pageSize = 5;
//   const [searchValue, setSearchValue] = useState("");
//   const debouncedSearchValue = useDebouncedValue(searchValue, 500);

//   const { data, error, fetchNextPage, hasNextPage, isFetching, status, fetchStatus, count } =
//     useInfiniteListEntity<Project>(
//       "projects",
//       {
//         page: 1,
//         pageSize: pageSize,
//         includeCount: true,
//
//         ...(debouncedSearchValue && { searchTerm: debouncedSearchValue }),
//         ...filterquery,
//       },
//       { enabled: !!filterquery && !!pageSize },
//       "post"
//     );

//   const projects = useMemo(() => {
//     return data?.pages.map((d) => d.results).flat() || [];
//   }, [data]);

//   const handleRowClick = useCallback(
//     (projectId: number) => {
//       history.push(route(`/projects/${projectId}`));
//     },
//     [history, route]
//   );

//   const fetchNext = useCallback(() => {
//     if (hasNextPage) {
//       fetchNextPage();
//     }
//   }, [fetchNextPage, hasNextPage]);

//   const loadMoreCallback = useCallback(() => {}, []);
//   const itemCount = hasNextPage ? projects.length + 1 : projects.length;
//   const loadMoreItems = isFetching ? loadMoreCallback : fetchNext;
//   const isItemLoaded = useCallback(
//     (index: number) => !hasNextPage || index < projects.length,
//     [projects.length, hasNextPage]
//   );

//   const Item = useCallback(
//     ({ index, style }: { index: number; style: CSSProperties }) => {
//       const d = projects[index];
//       if (!isItemLoaded(index)) {
//         return <span className="skeleton-block btn-lg" style={{ height: 51 }} />;
//       } else {
//         // const date = formatDate(new Date(d.date));
//         // const formatted_date = `${date.month} ${date.day}, ${date.year}`;
//         return (
//           <div style={{ ...style, paddingBottom: "5px" }} key={index}>
//             <ContentRow
//               key={index}
//               icon={
//                 <div className={styles.icon}>
//                   <FeatherIcon name="folder" color="var(--primary)" />
//                 </div>
//               }
//               label={<>{d.name}</>}
//               date={<></>}
//               id={<>Project-ID: {d.id}</>}
//               info={<>{d.owner ? <GetPersons persons={[d.owner]} /> : "-"}</>}
//               onClick={() => handleRowClick(d.id)}
//             />
//           </div>
//         );
//       }
//     },
//     [projects, handleRowClick, isItemLoaded]
//   );

//   return (
//     <Tile
//       isFolded={count === 0 && !debouncedSearchValue ? true : isFolded}
//       foldable={count === 0 && !debouncedSearchValue ? false : foldable}
//       disabled={count === 0 && !debouncedSearchValue}
//     >
//       <Tile.Head
//         title={
//           <div className="flex row-nowrap align-center gap-5">
//             {title}
//             {count !== undefined && <span className="badge">{count}</span>}
//           </div>
//         }
//       >
//         <Tile.Head.Controls>
//           <Tile.Head.Controls.Unfolded>
//             <>
//               <SearchInput searchValue={searchValue} setSearchValue={setSearchValue} minimized={false} />
//             </>
//           </Tile.Head.Controls.Unfolded>
//         </Tile.Head.Controls>
//       </Tile.Head>
//       <Tile.Body>
//         <div className={styles.column_container}>
//           <LoadingWrapper status={status} fetchStatus={fetchStatus} error={error}>
//             <div className={isFetching ? `${styles.container} ${styles.container_loading}` : styles.container}>
//               {projects && projects.length > 0 ? (
//                 <InfiniteLoader isItemLoaded={isItemLoaded} itemCount={itemCount} loadMoreItems={loadMoreItems as any}>
//                   {({ onItemsRendered, ref }) => (
//                     <FixedSizeList
//                       itemCount={itemCount}
//                       onItemsRendered={onItemsRendered}
//                       ref={ref}
//                       width="100%"
//                       height={projects.length > pageSize ? pageSize * 59 : projects.length * 59}
//                       itemSize={59}
//                     >
//                       {Item}
//                     </FixedSizeList>
//                   )}
//                 </InfiniteLoader>
//               ) : (
//                 <>
//                   {isFetching ? (
//                     <Skeleton type="rows" />
//                   ) : (
//                     <>
//                       {debouncedSearchValue ? (
//                         <Alert type="light" message={`No results for: "${debouncedSearchValue}"`} fit centered />
//                       ) : (
//                         <Alert type="light" message="No mentions yet" fit centered />
//                       )}
//                     </>
//                   )}
//                 </>
//               )}
//             </div>
//           </LoadingWrapper>
//         </div>
//       </Tile.Body>
//     </Tile>
//   );
// };
