import { PersonForm } from "./PersonForm";
import { EntityAddForm, EntityAddFormPage } from "../common/entity/EntityAddForm";
import { EntityCloneForm, EntityCloneFormPage } from "../common/entity/EntityCloneForm";
import {
  CustomTypeEntityAddFormProps,
  EntityCloneFormProps,
  EntityDetailViewProps,
  EntityEditFormProps,
} from "../common/entity/EntityInterfaces";
import { EntityEditForm, EntityEditFormPage } from "../common/entity/EntityEditForm";
import { EntityDetailPage, EntityDetailView } from "../common/entity/EntityDetailPage";
import { PersonsDetail } from "./PersonsDetail";
import { useLocation } from "react-router-dom";
import { EntityTablePage } from "../common/entity/EntityTablePage";
import { PersonsTable } from "./Table/PersonsTable";

// Add
export const PersonAddForm = ({ typeId }: CustomTypeEntityAddFormProps) => {
  return <EntityAddForm entityTypeId={"persons"}>{(props) => <PersonForm {...props} typeId={typeId} />}</EntityAddForm>;
};

export const PersonAddFormPage = () => {
  const typeId = new URLSearchParams(useLocation().search).get("type");

  return (
    <EntityAddFormPage>
      <PersonAddForm typeId={typeId ? +typeId : null} />
    </EntityAddFormPage>
  );
};

// Clone
export const PersonCloneForm = ({ id }: EntityCloneFormProps<"persons">) => {
  return (
    <EntityCloneForm id={id} entityTypeId={"persons"}>
      {(props) => <PersonForm {...props} typeId={props.initialValues?.customType?.id} />}
    </EntityCloneForm>
  );
};

export const PersonCloneFormPage = () => {
  return <EntityCloneFormPage<"persons"> isIntId>{(id) => <PersonCloneForm id={id} />}</EntityCloneFormPage>;
};

// Edit
export const PersonEditForm = ({ id }: EntityEditFormProps<"persons">) => {
  return (
    <EntityEditForm id={id} entityTypeId={"persons"}>
      {(props) => <PersonForm {...props} typeId={props.initialValues?.customType?.id} />}
    </EntityEditForm>
  );
};

export const PersonEditFormPage = () => {
  return <EntityEditFormPage<"persons"> isIntId>{(id) => <PersonEditForm id={id} />}</EntityEditFormPage>;
};

// Detail

export const PersonsDetailView = ({ id }: EntityDetailViewProps<"persons">) => {
  return (
    <EntityDetailView id={id} entityTypeId={"persons"} filters={{}}>
      {(props) => <PersonsDetail {...props} />}
    </EntityDetailView>
  );
};

export const PersonDetailsPage = () => {
  return <EntityDetailPage<"persons"> isIntId>{(id) => <PersonsDetailView id={id} />}</EntityDetailPage>;
};

export const PersonsTablePage = () => {
  return <EntityTablePage entityTypeId={"persons"}>{(props) => <PersonsTable {...props} />}</EntityTablePage>;
};
