import { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { sharedContentConstants } from "../api/SharedContent";
import Detail from "../common/panels/Detail/Detail";
import { DatasetsTile } from "../common/sidebar/tiles/DatasetsTile";
import { DetailsPageTopControlsView } from "../common/panels/misc/DetailsPageTopControlsView/DetailsPageTopControlsView";
import { LucideIcon } from "../common/icon/LucideIcon";
import { SessionContext } from "../common/contexts/SessionContext";
import { AlertModal } from "../common/modals/AlertModal/AlertModal";
import { Table } from "../common/panels/Detail/DetailTable";
import Status from "../common/badges/Status/Status";
import { NotSet } from "../common/misc/UIconstants";
import { GenericModalWrapper } from "../common/modals/Modal/GenericModal";
import { EntityDetailProps, EntityDetailTableProps } from "../common/entity/EntityInterfaces";
import { copyTextToClipboard } from "../common/helperfunctions/copyToClipboard";
import { CustomFieldReferencesTile } from "../common/sidebar/tiles/CustomFieldReferencesTile";
import { MoreDropdown } from "../common/buttons/MoreDropdown/MoreDropdown";

export const SharedContentDetailTable = ({
  entity,
  entityConstants,
  fieldLabels,
}: EntityDetailTableProps<"sharedContents">) => {
  const { api } = useContext(SessionContext);

  return (
    <Table noPadding>
      <Table.Head>Basic details</Table.Head>
      <Table.Body>
        <Table.Body.RowContent
          title={"Name"}
          content={
            <div className={"container_label"}>
              <div className={"container_label_name"} style={{ fontWeight: 600 }}>
                {entity.name ?? NotSet}
              </div>
              <div className={"container_label_id"}>
                <span style={{ whiteSpace: "nowrap" }}>SharedContent-ID: {entity.id}</span>
              </div>
            </div>
          }
        />
        <Table.Body.RowContent
          title={"Link"}
          content={
            <div className={"flex row-nowrap align-center gap-5"} style={{ width: "100%", overflow: "hidden" }}>
              <a
                href={`${window.origin}/${api.getGroup()}/shared/${entity.shareId}`}
                target="_blank"
                rel="noreferrer"
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <span style={{ flex: "1 1 auto", margin: 0, overflow: "auto" }}>
                  {`${window.origin}/${api.getGroup()}/shared/${entity.shareId}`}
                </span>
              </a>
              <button
                title="Copy to clipboard"
                className="btn btn-xs btn-default"
                onClick={() => copyTextToClipboard(`${window.origin}/${api.getGroup()}/shared/${entity.shareId}`)}
              >
                <LucideIcon name="copy" /> Copy
              </button>
            </div>
          }
        />
        <Table.Body.RowContent
          title={"Public Notes"}
          content={
            <>
              {entity.publicNotes ? (
                <div className={"container_notes"}>
                  <textarea rows={4} className={"container_textarea"} value={entity.publicNotes} disabled />
                </div>
              ) : (
                NotSet
              )}
            </>
          }
        />
        <Table.Body.RowContent
          title={"Private Notes"}
          content={
            <>
              {entity.privateNotes ? (
                <div className={"container_notes"}>
                  <textarea rows={4} className={"container_textarea"} value={entity.privateNotes} disabled />
                </div>
              ) : (
                NotSet
              )}
            </>
          }
        />
        {entity.permissions?.edit && (
          <Table.Body.RowContent
            title={"Password"}
            content={
              <div className="flex row-nowrap align-center gap-5" style={{ width: "100%" }}>
                {entity.password ? (
                  <GenericModalWrapper>
                    {({ showModal, setShowModal }) => (
                      <div className="flex row-nowrap align-center gap-5" style={{ width: "100%" }}>
                        <samp>{showModal ? entity.password : "•••••••"}</samp>
                        <div
                          style={{ marginLeft: "auto", color: "var(--gray-400)", cursor: "pointer" }}
                          onClick={() => setShowModal((prev) => !prev)}
                        >
                          {!showModal ? <LucideIcon name="eye" /> : <LucideIcon name="eye-off" />}
                        </div>
                      </div>
                    )}
                  </GenericModalWrapper>
                ) : (
                  <Status type="warning">Disabled</Status>
                )}
              </div>
            }
          />
        )}
        <Table.Body.RowContent
          title={"Allow Download"}
          content={
            <span>
              {entity.allowContentDownload ? (
                <Status type="success">Enabled</Status>
              ) : (
                <Status type="warning">Disabled</Status>
              )}
            </span>
          }
        />
      </Table.Body>
    </Table>
  );
};

export const SharedContentDetail = ({
  permissions,
  routes,
  entity,
  entityApi,
  entityConstants,
  fieldLabels,
}: EntityDetailProps<"sharedContents">) => {
  const history = useHistory();
  const [showModal, setShowModal] = useState(false);

  const { deleteMutationAsync, isLoadingDeleteMutation } = entityApi;

  return (
    <Detail
      sidebarPosition="right"
      head={
        <DetailsPageTopControlsView
          entity={entity}
          entityConstants={sharedContentConstants}
          createdOnOverride={entity.createdAt}
          controls={
            <>
              <button
                className="btn btn-default"
                title={`Edit this shared content`}
                onClick={() => history.push(routes.getEditLink(entity.id))}
                disabled={!permissions.canEdit(entity)}
              >
                <LucideIcon name="square-pen" /> Edit
              </button>
              <MoreDropdown drop="right" btn="btn btn-ghost-secondary">
                <li>
                  <button
                    className="btn btn-sm btn-ghost-secondary"
                    title={`Clone this Person`}
                    onClick={() => history.push(routes.getCloneLink(entity.id))}
                    disabled={!permissions.canCreate}
                  >
                    <LucideIcon name="copy" /> Clone
                  </button>
                </li>
                <li>
                  <button
                    className="btn btn-soft-danger"
                    title={`Delete this shared content`}
                    onClick={() => setShowModal(true)}
                    disabled={!permissions.canDelete(entity)}
                  >
                    <LucideIcon name="trash-2" /> Delete
                  </button>
                </li>
              </MoreDropdown>
              <AlertModal
                type={`danger`}
                showModal={showModal}
                setShowModal={setShowModal}
                title={"Permanently delete content?"}
                description={"Deleting this content will not remove any referenced entities."}
                onProceed={async () => {
                  await deleteMutationAsync(
                    { id: entity.id, goBackOnSuccess: true },
                    {
                      onSuccess: () => {
                        setShowModal(false);
                      },
                    }
                  ).catch(() => {});
                }}
                loading={isLoadingDeleteMutation}
              />
            </>
          }
        />
      }
      mainStyle={{ padding: "0px 10px" }}
      main={<SharedContentDetailTable entity={entity} entityConstants={entityConstants} fieldLabels={fieldLabels} />}
      sidebar={
        <>
          {entity.datasets.length > 0 && (
            <DatasetsTile
              defaultFilters={{ ids: entity.datasets.map((d) => d.id) }}
              excludeFilters={{ ids: () => false }}
            />
          )}
          <CustomFieldReferencesTile entityType="SharedContent" entity={entity} />
        </>
      }
    />
  );
};
