import { DataSource } from "../../api/DataSource";
import { EntityAddForm, EntityAddFormPage } from "../../common/entity/EntityAddForm";
import { DataSourceForm } from "./DataSourceForm";
import { EntityCloneFormProps, EntityDetailViewProps, EntityEditFormProps } from "../../common/entity/EntityInterfaces";
import { EntityCloneForm, EntityCloneFormPage } from "../../common/entity/EntityCloneForm";
import { EntityEditForm, EntityEditFormPage } from "../../common/entity/EntityEditForm";
import { EntityDetailPage, EntityDetailView } from "../../common/entity/EntityDetailPage";
import { DataSourcesDetail } from "./DataSourcesDetail";
import { useHistory, useParams } from "react-router-dom";
import { useEntityDetail } from "../../api/BaseEntityApi";
import { Bridge, bridgeConstants } from "../../api/Bridges";
import { EntityTablePage } from "../../common/entity/EntityTablePage";
import { DataSourcesTable } from "./Table/DataSourcesTable";

interface DataSourceEditFormProps extends EntityEditFormProps<"dataSources"> {
  bridgeId?: number;
}
interface DataSourceCloneFormProps extends EntityCloneFormProps<"dataSources"> {
  bridgeId?: number;
}

// Add
export const DataSourceAddForm = ({
  onSuccess,
  initialValues,
}: {
  onSuccess?: (dataSource: DataSource) => void;
  initialValues?: Partial<DataSource>;
}) => {
  return (
    <EntityAddForm entityTypeId={"dataSources"} onSuccess={onSuccess}>
      {(props) => <DataSourceForm {...props} initialValues={initialValues} />}
    </EntityAddForm>
  );
};

export const DataSourceAddFormPage = () => {
  const history = useHistory();
  const { bridgeId: bridgeIdURL } = useParams<{ bridgeId: string }>();
  const bridgeId = new URLSearchParams(history.location.search).get("bridgeId") ?? bridgeIdURL;
  const { data: bridge } = useEntityDetail<Bridge>(
    bridgeConstants.resource,
    bridgeId ? Number(bridgeId) : 0,
    undefined,
    {
      enabled: !!bridgeId,
    }
  );
  return (
    <EntityAddFormPage>
      <DataSourceAddForm initialValues={{ bridge }} />
    </EntityAddFormPage>
  );
};

// Clone
export const DataSourceCloneForm = ({ id, bridgeId }: DataSourceCloneFormProps) => {
  const { data: bridge } = useEntityDetail<Bridge>(bridgeConstants.resource, bridgeId || 0, undefined, {
    enabled: !!bridgeId,
  });
  return (
    <EntityCloneForm<"dataSources"> id={id} entityTypeId={"dataSources"}>
      {(props) => (
        <DataSourceForm
          {...props}
          initialValues={{
            ...props.initialValues,
            bridge: bridge ?? props.initialValues?.bridge,
          }}
        />
      )}
    </EntityCloneForm>
  );
};

export const DataSourceCloneFormPage = () => {
  const history = useHistory();
  const { bridgeId: bridgeIdURL } = useParams<{ bridgeId: string }>();
  const bridgeId = new URLSearchParams(history.location.search).get("bridgeId") ?? bridgeIdURL;
  return (
    <EntityCloneFormPage<"dataSources"> isIntId>
      {(id) => <DataSourceCloneForm id={id} bridgeId={bridgeId ? Number(bridgeId) : undefined} />}
    </EntityCloneFormPage>
  );
};

// Edit
export const DataSourceEditForm = ({ id, bridgeId }: DataSourceEditFormProps) => {
  const { data: bridge } = useEntityDetail<Bridge>(bridgeConstants.resource, bridgeId || 0, undefined, {
    enabled: !!bridgeId,
  });
  return (
    <EntityEditForm id={id} entityTypeId={"dataSources"}>
      {(props) => (
        <DataSourceForm
          {...props}
          initialValues={{
            ...props.initialValues,
            bridge: bridge ?? props.initialValues?.bridge,
          }}
        />
      )}
    </EntityEditForm>
  );
};

export const DataSourceEditFormPage = () => {
  const history = useHistory();
  const { bridgeId: bridgeIdURL } = useParams<{ bridgeId: string }>();
  const bridgeId = new URLSearchParams(history.location.search).get("bridgeId") ?? bridgeIdURL;
  return (
    <EntityEditFormPage<"dataSources"> isIntId>
      {(id) => <DataSourceEditForm id={id} bridgeId={bridgeId ? Number(bridgeId) : undefined} />}
    </EntityEditFormPage>
  );
};

// Detail
export const DataSourcesDetailView = ({ id }: EntityDetailViewProps<"dataSources">) => {
  return (
    <EntityDetailView id={id} entityTypeId={"dataSources"} filters={{ includeStatusHistory: true }}>
      {(props) => <DataSourcesDetail {...props} />}
    </EntityDetailView>
  );
};

export const DataSourceDetailsPage = () => {
  return <EntityDetailPage<"dataSources"> isIntId>{(id) => <DataSourcesDetailView id={id} />}</EntityDetailPage>;
};

export const DataSourceTablePage = () => {
  return <EntityTablePage entityTypeId={"dataSources"}>{(props) => <DataSourcesTable {...props} />}</EntityTablePage>;
};
