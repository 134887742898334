import {
  PropertyTranslator,
  IGenericRequestParametersTranslator,
  IOwnedEntityParametersTranslator,
  INamedEntityLabels,
  IPermissionedEntityLabels,
} from "./GenericTranslator";
import {
  DefaultOrderTypeConsts,
  DefaultOwnerOrderTypeConsts,
  EntityConstants,
  IEntityMinimalModel,
  IEntityWithCreationDate,
  IGenericRequestParameters,
  IModelWithIntId,
  INamedEntity,
  IOwnedEntity,
  IOwnedEntityParameters,
  IPermissionedEntity,
} from "./GenericTypes";

export const sharedContentConstants: EntityConstants<SharedContent, SharedContentFilters> = {
  resource: "shared_content",
  frontendIndexRoute: "shared_content",
  entitySingular: "shared content",
  entityPlural: "shared contents",
  icon: "share-2",
};

export interface SharedContentPublicModel {
  name?: string;
  allowContentDownload?: boolean;
  publicNotes?: string;
  requiresPassword: boolean;
  suppliedPasswordCorrect?: boolean;
}
export interface SharedContentPublicModelRequest {
  password?: string;
}

export interface SharedContent
  extends IModelWithIntId,
    INamedEntity,
    IOwnedEntity,
    IEntityWithCreationDate,
    IPermissionedEntity {
  shareId: string;
  allowContentDownload: boolean;
  publicNotes: string;
  privateNotes: string;
  password: string;
  datasets: IEntityMinimalModel<number>[];
}

export const SharedContentFieldLabels: PropertyTranslator<SharedContent> & { link: string } = {
  ...INamedEntityLabels,
  ...IPermissionedEntityLabels,
  id: "Shared content ID",
  shareId: "Share ID",
  allowContentDownload: "Allow content download",
  publicNotes: "Public notes",
  privateNotes: "Private notes",
  password: "Password",
  datasets: "Datasets",
  owner: "Owner",
  createdAt: "Created at",
  link: "Link",
} as const;

export interface SharedContentSuggestions extends IEntityMinimalModel<SharedContent["id"]> {}

export const SharedContentSortingOptionsConsts = [
  ...DefaultOrderTypeConsts,
  ...DefaultOwnerOrderTypeConsts,
  "CREATION_DATE_ASC",
  "CREATION_DATE_DESC",
] as const;
export type SharedContentSortingOptions = (typeof SharedContentSortingOptionsConsts)[number];

export interface SharedContentFilters
  extends IGenericRequestParameters<SharedContent, SharedContentSortingOptions>,
    IOwnedEntityParameters {
  datasetIds?: number[] | null;
}
export const SharedContentFiltersTranslator: PropertyTranslator<SharedContentFilters> = {
  ...IGenericRequestParametersTranslator,

  ...IOwnedEntityParametersTranslator,
  datasetIds: "Dataset IDs",
} as const;
