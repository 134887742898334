import React, { useContext } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, useWatch } from "react-hook-form";
import { InputFormField } from "../../../formfields/InputFormField";
import { QuickAddChildrenProps } from "../common/QuickAddWrapper";
import { FormButtons } from "../../FormButtons";
import { FormHeader } from "../../FormHeader";
import { TextareaFormField } from "../../../formfields/TextareaFormField";
import { Project, ProjectFieldLabels, projectsConstants } from "../../../../api/Projects";
import { useQuickAddSubmit } from "../common/useQuickAddHooks";
import { ProjectsTagsVirtualizedSelectForm } from "../../EntityForms/formsVirtualized/ProjectTagsVirtualizedSelectForm";
import { ProjectPersonPermissionsEditForm } from "../../../../ProjectPersonPermissions/ProjectPersonPermissionsEditForm";
import { useProjectFormSchema } from "../../../../Projects/ProjectForm";
import {
  EntityCustomTypeForm,
  useCustomTypeForm,
} from "../../../../Customization/CustomTypes/generics/useCustomTypeForm";
import { CustomTypesVirtualizedSelectForm } from "../../EntityForms/formsVirtualized/CustomTypesVirtualizedSelectForm";
import { SessionContext } from "../../../contexts/SessionContext";

export const QuickAddProjectForm = ({ onCreate, onCancel, initialValues }: QuickAddChildrenProps<Project>) => {
  const { session } = useContext(SessionContext);

  const { onSubmit, loading } = useQuickAddSubmit<Project>({
    resource: projectsConstants.resource,
    onCreate: onCreate,
    onCancel: onCancel,
  });
  const ProjectFormSchema = useProjectFormSchema(initialValues, "ADD");
  const { defaultValues, typedFormSchema, processCustomFields, type, setType, types } = useCustomTypeForm({
    initialValues: initialValues,
    formSchema: ProjectFormSchema,
    typeId: initialValues?.customType?.id,
    entityType: "Project",
    featureFlag: session?.features.enable_project_custom_types,
  });

  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm<Partial<Project>>({
    values: defaultValues,
    resolver: yupResolver(typedFormSchema),
  });

  const _customType = useWatch({ name: "customType", control: control });
  const typesLength = Object.keys(types ?? {}).length;

  return (
    <>
      <FormHeader title={"Add project"} />
      {!!typesLength && !_customType ? (
        <CustomTypesVirtualizedSelectForm
          id={"customType"}
          control={control}
          concatItems={[{ name: "Basic project", id: -1 }]}
          filters={{ entityTypes: ["Project"] }}
          horizontal
        />
      ) : (
        <>
          <InputFormField
            id="name"
            label={ProjectFieldLabels.name}
            errors={errors}
            register={register}
            autoFocus={true}
            required={true}
            placeholder="Enter project name..."
          />

          <ProjectsTagsVirtualizedSelectForm
            id="projectTags"
            label={ProjectFieldLabels.projectTags}
            control={control}
            showControls
            horizontal
            isMulti
            allowCreateEntity
          />

          <TextareaFormField
            id="notes"
            label={ProjectFieldLabels.notes}
            errors={errors}
            register={register}
            placeholder="Enter notes..."
          />
          <fieldset>
            <legend className="col-md-offset-2 col-md-10">
              Project access<span style={{ color: "var(--danger)" }}>*</span>
            </legend>
          </fieldset>
          <ProjectPersonPermissionsEditForm
            id="projectPersonPermissions"
            errors={errors}
            project={defaultValues as Project}
            getProjectPersonPermissions={(values) => {
              setValue("projectPersonPermissions", values);
            }}
          />
          <EntityCustomTypeForm
            entityType="Project"
            typeId={initialValues?.customType?.id}
            type={type}
            types={types}
            setType={setType}
            control={control}
            register={register}
            setValue={setValue}
            initialValues={initialValues}
            errors={errors}
            entityConstants={projectsConstants}
          />
          <FormButtons
            onClose={onCancel}
            onSubmit={handleSubmit(async (entity) => await onSubmit(processCustomFields(entity)))}
            disabled={isSubmitting}
            errors={errors}
            submitButtonLabel="Create"
            loading={loading}
          />
        </>
      )}
    </>
  );
};
