import { useState } from "react";
import { DateTimeRenderer } from "../../common/datetime/DateTimeFormatter";
import { EntityDetailProps, EntityDetailTableProps } from "../../common/entity/EntityInterfaces";
import { GetPersons } from "../../common/misc/EntityRenders/EntityRenderer";
import { NotSet } from "../../common/misc/UIconstants";
import { Table } from "../../common/panels/Detail/DetailTable";
import { Link, useHistory } from "react-router-dom";
import { DetailsPageTopControlsView } from "../../common/panels/misc/DetailsPageTopControlsView/DetailsPageTopControlsView";
import { LucideIcon } from "../../common/icon/LucideIcon";
import { MoreDropdown } from "../../common/buttons/MoreDropdown/MoreDropdown";
import { AlertModal } from "../../common/modals/AlertModal/AlertModal";
import { showtoast } from "../../common/overlays/Toasts/showtoast";
import { useELNRoutes } from "../ELNRouter/useELNRoutes";

export const LabNotebookTemplatesDetailTable = ({
  entity,
  entityConstants,
  fieldLabels,
}: EntityDetailTableProps<"notebookTemplates">) => {
  return (
    <Table noPadding>
      <Table.Head>Lab notebook template details</Table.Head>
      <Table.Body>
        <Table.Body.RowContent
          title={fieldLabels.name}
          content={
            <div className="container_label">
              <div className="container_label_name" style={{ fontWeight: 600 }}>
                {entity.name ?? NotSet}
              </div>
              <div className="container_label_id">
                <span style={{ whiteSpace: "nowrap" }}>Lab-Notebook-Template-ID: {entity.id}</span>
              </div>
            </div>
          }
        />

        <Table.Body.RowContent
          title={fieldLabels.description}
          content={
            <>
              {entity.description ? (
                <div className="container_notes">
                  <textarea rows={4} className="container_textarea" value={entity?.description} disabled />
                </div>
              ) : (
                NotSet
              )}
            </>
          }
        />

        <Table.Body.RowContent
          title={fieldLabels.createdOn}
          content={
            <div className="flex align-center gap-5" style={{ width: "100%", flexWrap: "wrap" }}>
              <div style={{ maxWidth: "max-content", width: "100%" }}>
                <DateTimeRenderer date={entity.createdOn} includeElapsed={false} />
              </div>
              <div className="flex row-nowrap align-center gap-5">
                by <GetPersons persons={[entity.createdBy]} />
              </div>
            </div>
          }
        />
        <Table.Body.RowContent
          title={fieldLabels.modifiedOn}
          content={
            <div className="flex align-center gap-5" style={{ width: "100%", flexWrap: "wrap" }}>
              <div style={{ maxWidth: "max-content", width: "100%" }}>
                <DateTimeRenderer date={entity.modifiedOn} includeElapsed={false} />
              </div>
              <div className="flex row-nowrap align-center gap-5">
                by <GetPersons persons={[entity.modifiedBy]} />
              </div>
            </div>
          }
        />
      </Table.Body>
    </Table>
  );
};

export const LabNotebookTemplatesDetail = ({
  permissions,
  routes,
  entity,
  entityApi,
  entityConstants,
  fieldLabels,
}: EntityDetailProps<"notebookTemplates">) => {
  const { getELNTemplateRoute } = useELNRoutes();
  let history = useHistory();
  const [showModal, setShowModal] = useState<boolean>(false);

  return (
    <div className="flex" style={{ width: "100%", height: "100%", overflow: "hidden" }}>
      <div
        className="flex col-nowrap"
        style={{ padding: "15px 15px 10px 15px", width: "100%", backgroundColor: "var(--white)" }}
      >
        <DetailsPageTopControlsView
          entity={entity}
          entityConstants={entityConstants}
          controls={
            <div className="flex align-center gap-5">
              <Link to={routes.getEditLink(entity.id)}>
                <button className="btn btn-default" disabled={!permissions.canEdit(entity)}>
                  <LucideIcon name="square-pen" />
                  <span> Edit</span>
                </button>
              </Link>
              <Link to={getELNTemplateRoute(entity.id)}>
                <button className="btn btn-soft-primary">
                  <LucideIcon name="eye" />
                  <span> View content</span>
                </button>
              </Link>
              <Link to={getELNTemplateRoute(entity.id, "edit")}>
                <button className="btn btn-default" disabled={!permissions.canEdit(entity)}>
                  <LucideIcon name="pencil" />
                  <span> Edit content</span>
                </button>
              </Link>
              <MoreDropdown drop="right" btn="btn btn-ghost-secondary">
                <li>
                  <Link to={routes.getCloneLink(entity.id)}>
                    <button
                      className="btn btn-sm btn-ghost-secondary"
                      title={`Clone this ${entityConstants.entitySingular}`}
                      disabled={!permissions.canCreate}
                    >
                      <LucideIcon name="copy" /> Clone
                    </button>
                  </Link>
                </li>
                <li>
                  <button
                    className="btn btn-sm btn-ghost-danger"
                    title="Delete"
                    onClick={() => setShowModal(true)}
                    disabled={!permissions.canDelete(entity)}
                  >
                    <LucideIcon name="trash-2" /> Delete
                  </button>
                </li>
              </MoreDropdown>
              <AlertModal
                type="danger"
                showModal={showModal}
                setShowModal={setShowModal}
                title="Permanently delete labNotebookTemplate?"
                description="This labNotebookTemplate may be referenced by other entities. Deleting a lab notebook template is only possible if all references are removed."
                proceedLabel="Delete"
                onProceed={async () => {
                  await entityApi
                    .deleteMutationAsync(
                      {
                        id: entity.id,
                        goBackOnSuccess: false,
                        showToast: false,
                        entityName: "labNotebookTemplate",
                        params: { deletePermanently: true },
                      },

                      {
                        onSuccess: () => {
                          showtoast("success", `Deleted Lab-Notebook-Template-ID: ${entity.id}.`);
                          history.goBack();
                        },
                      }
                    )
                    .catch((e) => {});
                }}
                loading={entityApi.isLoadingDeleteMutation}
              />
            </div>
          }
        />

        <LabNotebookTemplatesDetailTable entity={entity} entityConstants={entityConstants} fieldLabels={fieldLabels} />
      </div>
    </div>
  );
};
