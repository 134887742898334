import React, { useCallback, useLayoutEffect, useRef, useState } from "react";
import * as d3 from "d3";
import { useResizeDetector } from "react-resize-detector";
import styles from "./TreeViewer.module.css";
import { GenericEntity, IdTypes, INamedEntity } from "../../../api/GenericTypes";
import { LucideIcon } from "../../icon/LucideIcon";
import { Container } from "../../panels/Container/Container";
import { hierarchyConstants } from "../../../api/Inventories";

/**
 * Recursively collects all ids from the EntityHierarchy.
 * @param {EntityHierarchy<IdType>} node - The root node of the hierarchy.
 * @returns {IdType[]} An array of all ids in the hierarchy.
 * @example
 * ```typescript
 * const hierarchy: EntityHierarchy<string> = {
 *   id: "root",
 *   name: "Root",
 *   children: [
 *     { id: "child1", name: "Child 1" },
 *     { id: "child2", name: "Child 2", children: [{ id: "grandchild1", name: "Grandchild 1" }] },
 *   ],
 * };
 * const ids = collectAllHierarchyIds(hierarchy);
 * console.log(ids); // Output: ["root", "child1", "child2", "grandchild1"]
 * ```
 * @author @CorradoSurmanowicz
 */
export function collectAllHierarchyIds<IdType>(node: EntityHierarchy<IdType>): IdType[] {
  let ids: IdType[] = [node.id];

  if (node.children) {
    for (const child of node.children) {
      ids = ids.concat(collectAllHierarchyIds(child));
    }
  }

  return ids;
}

export interface EntityHierarchy<IdType> {
  id: IdType;
  name: string;
  children?: EntityHierarchy<IdType>[];
}

interface PointNodeExtended<IdType> extends Omit<d3.HierarchyPointNode<EntityHierarchy<IdType>>, "children"> {
  children?: PointNodeExtended<IdType>[] | null;
  _children?: PointNodeExtended<IdType>[] | null;
}

// Set the dimensions and margins of the diagram
const margin = { top: 10, right: 10, bottom: 10, left: 20 };
const duration = 750;

interface TreeViewerProps<Entity extends GenericEntity & INamedEntity, TreeData extends EntityHierarchy<Entity["id"]>> {
  title?: string;
  nodeId: Entity["id"];
  treeData: TreeData;
  onLabelClick?: (d: d3.HierarchyPointNode<TreeData>) => void;
}

const findPathToId = (node: EntityHierarchy<IdTypes>, id: IdTypes, path: IdTypes[] = []): IdTypes[] | null => {
  // Add the current node's ID to the path
  path.push(node.id);

  // Check if the current node is the target ID
  if (node.id === id) {
    return path;
  }

  // If the node has children, iterate through them
  if (node.children) {
    for (const child of node.children) {
      const result = findPathToId(child, id, [...path]); // Pass a copy of the current path
      if (result) {
        return result; // If the target ID is found in a subtree, return the path
      }
    }
  }

  // If the target ID was not found in this node or its subtrees, return null
  return null;
};

function addNodeToChildren<TreeData extends EntityHierarchy<IdTypes>>(
  node: TreeData,
  targetId: IdTypes
): TreeData | null {
  if (node.id === targetId) {
    // Add the new child node
    const newNode: any = { id: undefined, name: `Add child ${hierarchyConstants.childType}` };
    if (!Array.isArray(node.children)) node.children = [];
    if (node.children && node.children.every((c) => !!c.id)) {
      node.children = [...(node.children ?? []), newNode];
    }
    return node; // Return the modified node
  }

  if (node.children) {
    for (let i = 0; i < node.children.length; i++) {
      const result = addNodeToChildren(node.children[i], targetId);
      if (result) {
        node.children[i] = result; // Update the child node if it was modified
        return node; // Return the modified node
      }
    }
  }
  return null; // Return null if the specific ID was not found
}

export const TreeViewer = <
  Entity extends GenericEntity & INamedEntity,
  TreeData extends EntityHierarchy<Entity["id"]>
>({
  title = "Hierarchy",
  nodeId,
  treeData,
  onLabelClick,
}: TreeViewerProps<Entity, TreeData>) => {
  const root = useRef<d3.HierarchyNode<TreeData>>();
  const svg = useRef<d3.Selection<SVGGElement, unknown, null, undefined>>();
  const treemap = useRef<d3.TreeLayout<unknown>>();
  const [width, setWidth] = useState<number>();

  const { ref: svgRef, height: svgHeight, width: svgWidth } = useResizeDetector();
  // declares a tree layout and assigns the size
  let i = 0;
  const IdsPathToNode = findPathToId(treeData, nodeId);

  // Collapse the node and all it's children
  const collapse = useCallback((d: any) => {
    if (d.children) {
      d._children = d.children;
      d._children.forEach(collapse);
      d.children = null;
    }
  }, []);

  const expandAll = useCallback((d: any) => {
    if (d._children) {
      d.children = d._children;
      d._children = null;
    }
    const children = d.children ? d.children : d._children;
    if (children) children.forEach(expandAll);
  }, []);

  //   const collapseAll = useCallback(() => {
  //     root.children.forEach(collapse);
  //     collapse(root);
  //     update(root);
  // },[])

  const collapseAllInactive = useCallback(
    (d: any) => {
      if (d.children) {
        d.children.forEach((child: any) => {
          if (child.data.id && !IdsPathToNode?.includes(child.data.id)) {
            collapse(child);
          } else {
            collapseAllInactive(child);
          }
        });
      }
    },
    [IdsPathToNode, collapse]
  );

  const findRoute = useCallback((d: any, id: IdTypes): boolean => {
    if (d.data && d.data.id === id) {
      return true;
    } else if (d.children) {
      return d.children.some((c: any) => findRoute(c, id));
    }
    return false;
  }, []);

  const update = useCallback(
    (source: any) => {
      // Assigns the x and y position for the nodes
      if (!root.current) return;
      if (!width) return;
      if (!treemap.current) return;
      if (!svg.current) return;
      if (!source) return;
      let treeData = treemap.current(root.current) as PointNodeExtended<Entity["id"]>;

      // Compute the new tree layout.
      var nodes = treeData.descendants(),
        links = treeData.descendants().slice(1);

      const maxDepth = new Set(nodes.map((d) => d.depth)).size;
      const nodeSpacing = width / maxDepth;
      // Normalize for fixed-depth.
      nodes.forEach(function (d) {
        d.y = d.depth * nodeSpacing;
      });

      // ****************** Nodes section ***************************

      // Update the nodes...
      var node = svg.current.selectAll("g.node").data(nodes, function (d: any) {
        return d.id || (d.id = ++i);
      });

      // Enter any new modes at the parent's previous position.
      var nodeEnter = node
        .enter()
        .append("g")
        .attr("class", "node")
        .attr("transform", function (d: any) {
          return "translate(" + source.y0 + "," + source.x0 + ")";
        });
      // Add Circle for the nodes
      nodeEnter
        .append("circle")
        .attr("class", "node")
        .classed(styles.nodeCircle, true)
        .classed(styles.nodeCircleWithChildren, (d: PointNodeExtended<Entity["id"]>) => !!d._children)
        .classed(styles.nodeCircleAdd, (d: PointNodeExtended<Entity["id"]>) => !d.data.id)
        .classed(styles.nodeCircleActive, (d: PointNodeExtended<Entity["id"]>, i, nodes) => {
          return d.data.id === nodeId;
        })
        .attr("r", 1e-6)
        .on("click", onClickLabel);

      // Add labels for the nodes
      nodeEnter
        .append("text")
        .classed(styles.nodeLabel, true)
        .classed(styles.nodeLabelActiveLabel, (d: PointNodeExtended<Entity["id"]>, i, nodes) => {
          return d.data.id === nodeId;
        })
        .classed(styles.nodeLabelAddNode, (d: PointNodeExtended<Entity["id"]>) => !d.data.id)

        .attr("dy", ".35em")
        .attr("x", function (d: PointNodeExtended<Entity["id"]>) {
          return d.children || d._children ? 13 : 13;
        })
        .attr("text-anchor", function (d: PointNodeExtended<Entity["id"]>) {
          return d.children || d._children ? "start" : "start";
        })
        .text(function (d: PointNodeExtended<Entity["id"]>) {
          return `${d.data.name}`;
        })
        .on("click", onClickLabel);

      // UPDATE
      var nodeUpdate = nodeEnter.merge(node as any);

      // Transition to the proper position for the node
      nodeUpdate
        .transition()
        .duration(duration)
        .attr("transform", function (d: PointNodeExtended<Entity["id"]>) {
          return "translate(" + d.y + "," + d.x + ")";
        });

      // Update the node attributes and style
      nodeUpdate
        .select("circle.node")
        .attr("r", 10)
        .classed(styles.nodeCircle, true)
        .classed(styles.nodeCircleWithChildren, (d: PointNodeExtended<Entity["id"]>) => !!d._children);

      // Remove any exiting nodes
      var nodeExit = node
        .exit()
        .transition()
        .duration(duration)
        .attr("transform", function (d: any) {
          return "translate(" + source.y + "," + source.x + ")";
        })
        .remove();

      // On exit reduce the node circles size to 0
      nodeExit.select("circle").attr("r", 1e-6);

      // On exit reduce the opacity of text labels
      nodeExit.select("text").style("fill-opacity", 1e-6);

      // ****************** links section ***************************

      // Update the links...
      var link = svg.current.selectAll("path.link").data(links, function (d: any) {
        return d.id;
      });

      // Enter any new links at the parent's previous position.
      var linkEnter = link
        .enter()
        .insert("path", "g")
        .attr("class", "link")
        .attr("style", "fill: none; stroke: var(--gray-300); stroke-width: 2px; cursor: pointer;")
        .classed(styles.nodeLabelActivePath, (d: any, i, nodes) => {
          return IdsPathToNode?.includes(d.data.id) ?? false;
          // return findRoute(d, nodeId);
        })
        .classed(styles.nodeLabelAddPath, (d: any, i, nodes) => !d.data.id)
        .attr("d", function (d: any) {
          var o = { x: source.x0, y: source.y0 } as PointNodeExtended<Entity["id"]>;
          return diagonal(o, o);
        })
        .on("click", click);

      // UPDATE
      var linkUpdate = linkEnter.merge(link as any);

      // Transition back to the parent element position
      linkUpdate
        .transition()
        .duration(duration)
        .attr("d", function (d: PointNodeExtended<Entity["id"]>) {
          return d.parent && diagonal(d, d.parent);
        });

      // Remove any exiting links
      link
        .exit()
        .transition()
        .duration(duration)
        .attr("d", function (d: any) {
          var o = { x: source.x, y: source.y } as PointNodeExtended<Entity["id"]>;
          return diagonal(o, o);
        })
        .remove();

      // Store the old positions for transition.
      nodes.forEach(function (d: any) {
        d.x0 = d.x;
        d.y0 = d.y;
      });

      // Creates a curved (diagonal) path from parent to the child nodes
      function diagonal(s: PointNodeExtended<Entity["id"]>, d: PointNodeExtended<Entity["id"]>) {
        const path = `M ${s.y} ${s.x}
        C ${(s.y + d.y) / 2} ${s.x},
          ${(s.y + d.y) / 2} ${d.x},
          ${d.y} ${d.x}`;

        return path;
      }

      // Toggle children on click.
      function click(d: PointNodeExtended<Entity["id"]>) {
        if (d.children) {
          d._children = d.children;
          d.children = null;
        } else {
          d.children = d._children;
          d._children = null;
        }
        update(d);
      }

      function onClickLabel(d: any) {
        onLabelClick?.(d);
      }
    },
    [IdsPathToNode, i, nodeId, onLabelClick, width]
  );

  useLayoutEffect(() => {
    if (treeData && svgRef.current && svgHeight && svgWidth && IdsPathToNode) {
      const _treeData = addNodeToChildren(treeData, nodeId);
      if (_treeData) {
        // Cleanup
        d3.select(svgRef.current).selectAll("*").remove();
        const _width = svgWidth - margin.left - margin.right;
        const _height = svgHeight - margin.top - margin.bottom;
        setWidth(_width);

        svg.current = d3
          .select(svgRef.current)
          .append("svg")
          .attr("width", _width + margin.right + margin.left)
          .attr("height", _height + margin.top + margin.bottom)
          .append("g")
          .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

        treemap.current = d3.tree().size([_height, _width]);

        // Assigns parent, children, height, depth
        const _root = d3.hierarchy(_treeData, function (d: TreeData) {
          return d.children as TreeData[];
        });
        (_root as any).x0 = _height / 2;
        (_root as any).y0 = 0;

        // Collapse all inactive
        collapseAllInactive(_root);

        root.current = _root;
        update(_root);
      }
    }
  }, [IdsPathToNode, collapseAllInactive, nodeId, svgHeight, svgRef, svgWidth, treeData, update]);

  return (
    <Container
      title={title}
      controls={
        <button
          className="btn btn-default"
          onClick={() => {
            if (root.current) {
              expandAll(root.current);
              update(root.current);
            }
          }}
        >
          <LucideIcon name={hierarchyConstants.hierarchyLayoutIcon} /> Expand All
        </button>
      }
    >
      <div className="flex" ref={svgRef} style={{ width: "100%", height: "100%", minHeight: "400px" }} />
    </Container>
  );
};
