import React from "react";
import { Button } from "../../buttons/Button/Button";
import { LucideIcon } from "../../icon/LucideIcon";
import { EntityConstants, IEntityPermissions } from "../../../api/GenericTypes";
import { IGenericEntityPermissions } from "../../permissions/useEntityPermissions";
import { EntityByEntityTypeId, GenericEntityConstantsEntities } from "../../../api/GenericConstants";

interface EntityTableRestoreButtonProps<EntityTypeId extends GenericEntityConstantsEntities> {
  entityConstants: EntityConstants;
  permissions: IGenericEntityPermissions<EntityTypeId>;
  selection: Set<EntityByEntityTypeId<EntityTypeId>["id"]>;
  selectionPermissions: IEntityPermissions | undefined;
  onClick: React.DOMAttributes<HTMLButtonElement>["onClick"];
  loading?: boolean;
}

/**
 * EntityRestoreButton component renders a button that allows users to restore soft-deleted entities.
 * The button's state and title are dynamically determined based on the user's permissions and the selection state.
 * @author @CorradoSurmanowicz
 * @template Entity - The type of the entity that extends GenericEntity and ISoftDeletable.
 * @param {EntityTableRestoreButtonProps<EntityTypeId>} props - The properties for the EntityRestoreButton component.
 * @param {EntityConstants} props.entityConstants - Constants related to the entity type.
 * @param {Set<Entity>} props.selection - The set of selected entities.
 * @param {Permissions} props.permissions - The user's permissions.
 * @param {SelectionPermissions} props.selectionPermissions - The permissions related to the current selection.
 * @param {() => void} props.onClick - The callback function to be called when the button is clicked.
 * @param {boolean} props.loading - Indicates whether the button is in a loading state.
 * @returns {JSX.Element} The rendered button component.
 */
export const EntityTableRestoreButton = <EntityTypeId extends GenericEntityConstantsEntities>({
  entityConstants,
  selection,
  permissions,
  selectionPermissions,
  onClick,
  loading,
}: EntityTableRestoreButtonProps<EntityTypeId>) => {
  return (
    <Button
      className="btn btn-sm btn-ghost-success"
      title={`${
        !permissions.canBulkEdit(selectionPermissions)
          ? `Insufficient permissions to restore selected ${entityConstants.entityPlural}`
          : selection.size === 0
          ? `Select ${entityConstants.entitySingular} to restore`
          : selection.size === 1
          ? `Restore the selected ${entityConstants.entitySingular}`
          : `Restore the selected ${entityConstants.entityPlural}`
      }`}
      disabled={selection.size === 0 || !permissions.canBulkEdit(selectionPermissions)}
      onClick={onClick}
      loading={loading}
    >
      <LucideIcon name="archive-restore" /> Restore from trash
    </Button>
  );
};
