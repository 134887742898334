import React from "react";
// import ResizePanel from "../../../modules/react-resize-panel.new/src/ResizePanel";
// import ResizePanel from "react-resize-panel";
import styles from "./Detail.module.css";
import { CSSProperties } from "remirror";
import { Panel, PanelGroup } from "react-resizable-panels";
import { CustomResizeHandle } from "../misc/CustomResizeHandle";

/**
 * Generic DetailView wrapper component, with a main content area and sidebar
 * Author: CS
 * @param: children
 * @returns: JSX.Element
 */

interface Props {
  head?: React.ReactNode;
  headStyle?: CSSProperties;
  main: React.ReactNode;
  mainStyle?: CSSProperties;
  sidebar?: React.ReactNode;
  sidebarPosition?: "left" | "right" | "hidden";
  sidebarStyle?: CSSProperties;
  sidebarContentStyle?: CSSProperties;
  mainDefaultSize?: number;
  mainMinSize?: number;
  sidebarMinSize?: number;
  sideFr?: number;
  resizable?: boolean;
  collapsible?: boolean;
}
const Detail = ({
  head,
  headStyle,
  main,
  mainStyle,
  sidebar,
  sidebarPosition = "right",
  sidebarStyle,
  sidebarContentStyle,
  mainDefaultSize = 70,
  mainMinSize = 30,
  sidebarMinSize = 10,
  resizable = true,
  collapsible = true,
}: Props) => {
  return (
    <PanelGroup direction="horizontal">
      {sidebarPosition === "left" ? (
        <>
          <Panel defaultSize={100 - mainDefaultSize} minSize={sidebarMinSize} collapsible={collapsible}>
            <div className={styles.mainSidebar} style={{ ...sidebarStyle }}>
              <div className={styles.mainSidebarContent} style={{ ...sidebarContentStyle }}>
                {sidebar}
              </div>
            </div>
          </Panel>
          {resizable && <CustomResizeHandle orientation="horizontal" />}
          <Panel defaultSize={mainDefaultSize} minSize={mainMinSize} collapsible={collapsible}>
            <div className={styles.main}>
              {!!head && (
                <div className={styles.mainHead} style={{ ...headStyle }}>
                  {head}
                </div>
              )}
              <div className={styles.mainContent} style={{ ...mainStyle }}>
                {main}
              </div>
            </div>
          </Panel>
        </>
      ) : (
        <>
          <Panel defaultSize={mainDefaultSize} minSize={mainMinSize} collapsible={collapsible}>
            <div className={styles.main}>
              {head && (
                <div className={styles.mainHead} style={{ ...headStyle }}>
                  {head}
                </div>
              )}
              <div className={styles.mainContent} style={{ ...mainStyle }}>
                {main}
              </div>
            </div>
          </Panel>
          {resizable && <CustomResizeHandle orientation="horizontal" />}
          <Panel defaultSize={100 - mainDefaultSize} minSize={sidebarMinSize} collapsible={collapsible}>
            <div className={styles.mainSidebar} style={{ ...sidebarStyle }}>
              <div className={styles.mainSidebarContent} style={{ ...sidebarContentStyle }}>
                {sidebar}
              </div>
            </div>
          </Panel>
        </>
      )}
    </PanelGroup>
  );
};

export default Detail;
