import React, { CSSProperties, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { LucideIcon } from "../../icon/LucideIcon";
import { Link as DOMLink, useHistory, useLocation } from "react-router-dom";
import styles from "./Menue.module.css";
import { EntityConstants } from "../../../api/GenericTypes";
import { getIndexRoute } from "../../../main/Routing";
import { SessionContext } from "../../contexts/SessionContext";
import { toUppercase } from "../../helperfunctions/stringFunctions";

interface Props {
  style?: CSSProperties;
  isFolded?: boolean;
  children: React.ReactNode;
  onClick?: () => void;
}
interface MenuContextStore {
  isFolded: boolean;
  setIsFolded: React.Dispatch<React.SetStateAction<boolean>>;
}
const MenuContext = React.createContext<MenuContextStore | null>(null);

const Menue = ({ style, isFolded = false, children }: Props) => {
  const [_isFolded, setIsFolded] = useState(false);

  useEffect(() => {
    setIsFolded((prev) => (prev !== isFolded ? isFolded : prev));
  }, [isFolded]);

  const context = useMemo(() => {
    return {
      isFolded: _isFolded,
      setIsFolded: setIsFolded,
    };
  }, [_isFolded]);

  return (
    <MenuContext.Provider value={context}>
      <div className={styles.container} style={style}>
        {children}
        <div className={`${styles.handle} verticalResizeBorder`} onClick={() => setIsFolded((prev) => !prev)}>
          <div className="verticalHandle">
            <span>{_isFolded ? <LucideIcon name="chevron-right" /> : <LucideIcon name="chevron-left" />}</span>
          </div>
        </div>
      </div>
    </MenuContext.Provider>
  );
};

const Items = Object.assign(
  React.forwardRef<HTMLDivElement, Props>(({ children, style }, ref) => {
    const context = useContext(MenuContext);
    return (
      <div className={`flex row-nowrap ${styles.container_menue}`}>
        <div
          className={`flex col-nowrap ${styles.container_menue_content}`}
          style={{ maxWidth: context?.isFolded ? 0 : "75vw", padding: context?.isFolded ? 0 : "10px 5px", ...style }}
          ref={ref}
        >
          {children}
        </div>

        {/* <div className={`${styles.handle} verticalResizeBorder`} onClick={() => setIsFolded(!isFolded)}>
        <div className="verticalHandle">
          <span>{isFolded ? <FeatherIcon name="chevron-right" /> : <FeatherIcon name="chevron-left" />}</span>
        </div>
      </div> */}
      </div>
    );
  })
);
Menue.Items = Items;

interface HeaderProps extends Props {
  label: JSX.Element | string;
  folded?: boolean;
}
const Header = ({ label, style, children, onClick, folded = true }: HeaderProps) => {
  const [show, setShow] = useState(!folded);
  return (
    <div className={`flex col-nowrap ${styles.container_menue_header}`} onClick={onClick} style={style}>
      <div
        className={`flex row-nowrap align-center gap-5 ${styles.container_menue_header_label}`}
        onClick={() => setShow(!show)}
      >
        {!show ? <LucideIcon name="chevron-right" /> : <LucideIcon name="chevron-down" />}
        <div>{label}</div>
      </div>
      <div
        className={`flex col-nowrap ${styles.container_menue_header_items}`}
        style={{ display: show ? "flex" : "none" }}
      >
        {children}
      </div>
    </div>
  );
};
Items.Header = Header;

interface ItemProps {
  style?: CSSProperties;
  children: React.ReactNode;
}
const Item = ({ style, children }: ItemProps) => {
  return (
    <div className={`flex row-nowrap ${styles.container_menue_item}`} style={style}>
      {children}
    </div>
  );
};
Items.Item = Item;

interface LinkProps {
  entityConstants: EntityConstants;
  labelOverride?: string;
  disabled?: boolean;
  style?: CSSProperties;
}
const Link = ({ entityConstants, labelOverride, disabled, style }: LinkProps) => {
  const { pathname } = useLocation();
  const { route } = useContext(SessionContext);
  const history = useHistory();

  const onClick = useCallback(() => {
    history.push(route(getIndexRoute(entityConstants.frontendIndexRoute)));
  }, [entityConstants.frontendIndexRoute, history, route]);

  const isFocused = useCallback(() => {
    let path = pathname.split("/");
    if (path.length > 0) {
      return path.some((p) => p === entityConstants.frontendIndexRoute);
    }
    return false;
  }, [entityConstants.frontendIndexRoute, pathname]);
  return (
    <DOMLink
      to={route(getIndexRoute(entityConstants.frontendIndexRoute))}
      style={{ textDecoration: "none", color: "unset" }}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <div
        className={`flex row-nowrap gap-5 align-center ${styles.container_menue_link} ${
          isFocused() && styles.container_menue_link_focused
        } ${disabled && styles.container_menue_link_disabled}`}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          !disabled && onClick();
        }}
        style={style}
      >
        <LucideIcon name={entityConstants.icon} />
        {labelOverride ?? toUppercase(entityConstants.entityPlural)}
      </div>
    </DOMLink>
  );
};
Item.Link = Link;

const Content = ({ children }: Props) => {
  return (
    <div className={`flex ${styles.container_content}`}>
      <div style={{ flex: "1 0 100%", overflow: "auto" }}>{children}</div>
    </div>
  );
};
Menue.Content = Content;
export default Menue;
