import styles from "./SliderElements.module.css";

export const SliderTrack = ({ props, children, trackStyle }: { props: any; children: any; trackStyle?: string }) => {
  return (
    <div {...props}>
      {children}
      <div className={trackStyle || styles.sliderTrack} />
    </div>
  );
};

export const SliderThumbBlock = ({ index, props }: { index: number; props: any }) => {
  props.style.zIndex = undefined;
  return (
    <div {...props} className={styles.sliderThumb}>
      <div className={styles.sliderThumbBlock} />
    </div>
  );
};
