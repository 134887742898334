import React from "react";
import { LucideProps } from "lucide-react";
import dynamicIconImports from "lucide-react/dynamicIconImports";
import { camelize } from "../helperfunctions/stringFunctions";
import { icons } from "lucide-react";
// import { camelize } from "../helperfunctions/stringFunctions";
/**
 * This component dynamically returns LucideIcons as SVG elements
 * Based on https://lucide.dev/icons
 *
 * Author: CS
 * @param: Name, SVGprops (optional)
 * @returns: JSX.Element
 */

// Kebabcase names
type IconKebabCaseNames = keyof typeof dynamicIconImports;
// Kamel case names
type IconKamelCaseNames = keyof typeof icons;

export type IconNames = IconKebabCaseNames | IconKamelCaseNames;

export interface IconProps extends Omit<LucideProps, "ref"> {
  name: IconNames;
}
// const cachedIcons: StringIndexedDict<(currentProps: Omit<IconProps, "name">) => JSX.Element> = {};

export const LucideIcon = ({ name, ...props }: IconProps) => {
  // // Lazy variant w/ caching
  // if (!cachedIcons?.[name]) {
  //   // console.log("Adding to icon cache: ", name);
  //   const LucideIcon = lazy(dynamicIconImports[name]);
  //   cachedIcons[name] = (currentProps) => (
  //     <Suspense fallback={<svg width="24" height="24" viewBox="0 0 24 24" className="feather" {...currentProps} />}>
  //       <LucideIcon className="feather" {...currentProps} />
  //     </Suspense>
  //   );
  // }
  // // Return cached
  // return cachedIcons[name](props);

  // Non Lazy variant
  const camelName = camelize(name);
  const LucideIcon = icons?.[camelName as IconKamelCaseNames];
  if (!LucideIcon || !camelName) {
    console.error(`Icon ${name} (camelized: ${camelName}) does not exist`);
    return <svg width="24" height="24" viewBox="0 0 24 24" className="feather" {...props} />;
  }
  return <LucideIcon className="feather" {...props} />;
};
