import { CSSProperties } from "react";
import { GridCellProps } from "react-virtualized";

export interface FormattedTable {
  type: "formatted_table";
  id: string;
  cells: FormattedTableCell[];
  rowRange: number[];
  columnRange: number[];
  fixedRow: number;
  fixedColumn: number;
}

export const initFormattedTableCell = (): FormattedTableCell => {
  return {
    value: undefined,
    type: "string",
    row: -1,
    column: -1,
  };
};

export const initFormattedTable = (): FormattedTable => {
  return {
    type: "formatted_table",
    id: "",
    cells: [],
    rowRange: [0, 0],
    columnRange: [0, 0],
    fixedRow: 0,
    fixedColumn: 0,
  };
};

// export interface CellIndex {
//   rowIndex: number;
//   columnIndex: number;
// }

export type CellElementProps = {
  width?: number;
  height?: number;
  rowIndex?: number;
  columnIndex?: number;
  content: FormattedTableCell;
  settings: {
    focused?: boolean;
  };
};

export type CellRenderProps = GridCellProps & {
  index: string;
  cellState: CellState;
  fixedRowCount: number;
  fixedColumnCount: number;
  showRowIndex: boolean;
  showColumnIndex: boolean;
  showRowLetterIndex: boolean;
  showColumnLetterIndex: boolean;
  setCellState: (cellState: CellState) => void;
  addToColumnWidth: (index: number, width: number) => void;
  addToRowHeight: (index: number, width: number) => void;
  setLinePos: (pos: LinePosType | undefined) => void;
  getColumnWidth: (index: number, stopIndex?: number) => number;
  getRowHeight: (index: number, stopIndex?: number) => number;
  CellValues: (index: CellIndex) => FormattedTableCell;
  ColumnIndexElement?: (props: CellElementProps) => JSX.Element;
  ColumnNameElement?: (props: CellElementProps) => JSX.Element;
  TopLeftCornerElement?: (props: CellElementProps) => JSX.Element;
  RowIndexElement?: (props: CellElementProps) => JSX.Element;
  RowNameElement?: (props: CellElementProps) => JSX.Element;
  ContentCellElement?: (props: CellElementProps) => JSX.Element;
};

export interface FormattedTableCell {
  value: string | number | boolean | undefined;
  type: "string" | "number" | "boolean";
  row: number;
  column: number;
  settings?: FormattedTableCellSettings;
}

export interface FormattedTableCellSettings {
  decimalPlaces?: number;
  unit?: string;
  focused?: boolean;
}

export type rowSizeType = { top: number; height: number };
export type columnSizeType = { left: number; width: number };

export type CellIndex = {
  rowIndex: number;
  columnIndex: number;
};

export type CellState = {
  focused: CellIndex;
};

export type CellSpan = {
  row: number;
  column: number;
  rowSpan: number;
  columnSpan: number;
};

export type CellProps = {
  columnIndex: number;
  type: "top" | "left" | "main";
  index: string;
  rowIndex: number;
  style: CSSProperties;
  cellState: CellState;
  setCellState?: (cellState: CellState) => void;
  getColumnWidth?: (index: number) => number;
  addToColumnWidth?: (index: number, width: number) => void;
  getRowHeight?: (index: number) => number;
  addToRowHeight?: (index: number, height: number) => void;
};

export type LinePosType = { left: number; top: number; mode: "horizontal" | "vertical" };

export const keyToIndex = (key: string) => key.split("-").map((c) => parseInt(c));
export const indexToKey = (rowIndex: number, columnIndex: number, prefix?: string) =>
  `${rowIndex}-${columnIndex}${prefix ? "-" + prefix : ""}`;

var STRIP_COMMENTS = /((\/\/.*$)|(\/\*[\s\S]*?\*\/))/gm;
var ARGUMENT_NAMES = /([^\s,]+)/g;
export const getParamNames = (func: (a: any) => any) => {
  var fnStr = func.toString().replace(STRIP_COMMENTS, "");
  var result = fnStr.slice(fnStr.indexOf("(") + 1, fnStr.indexOf(")")).match(ARGUMENT_NAMES);
  if (result === null) result = [""];
  return result;
};

const convertToBase = (value: number, base: number) => {
  value = Math.floor(Math.abs(value));
  if (value === 0) return [0];
  const result: number[] = [];
  while (value > 0) {
    result.push(value % base);
    value = Math.floor(value / base);
    if (value < 1) break;
  }

  return result;
};

const LETTERS = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

export const indexToLetterNonRecursive = (index: number) => {
  const a = convertToBase(index, LETTERS.length);

  // remove zeros
  for (let j = 0; j < a.length - 1; j++) {
    if (a[j] <= 0) {
      a[j] += LETTERS.length;
      a[j + 1] = a[j + 1] - 1;
    }
  }

  return a
    .reverse()
    .map((i) => LETTERS[i - 1])
    .join("");
};

export const indexToLetter = (index: number): string => {
  if (index < LETTERS.length) return LETTERS[index - 1];
  else {
    let q = Math.floor(index / LETTERS.length),
      r = index % LETTERS.length;
    if (r === 0) {
      if (q === 1) return LETTERS[LETTERS.length + r - 1];
      else return indexToLetter(q - 1) + LETTERS[LETTERS.length + r - 1];
    } else return indexToLetter(q) + LETTERS[r - 1];
  }
};
