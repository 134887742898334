import React, { CSSProperties, useContext, useMemo } from "react";
import { SearchInput } from "../../common/forms/SearchInput/SearchInput";
import { LucideIcon } from "../../common/icon/LucideIcon";
import TableView, { TableViewLabel } from "../../common/panels/TableView/TableView";
import { ColumnsSettings } from "../../common/tables/ColumnsSelector/ColumnsSelector";
// import { showtoast } from "../../common/overlays/Toasts/showtoast";
import { EntityTable } from "../../common/tables/EntityTable/EntityTable";
import {
  GenericVirtualizedTableCells,
  SortState,
} from "../../common/tables/GenericVirtualizedTable/GenericVirtualizedTableTypes";
import { EntityFilterIndicator } from "../../common/tables/EntityFilterIndicator/EntityFilterIndicator";
import { EntityTableTabs } from "../../common/tables/Tabs/EntityTableTabs";
import { useTabStore } from "../../common/tables/Tabs/useTabStore";
import { TableTabsDict } from "../../common/tables/Tabs/TableTabsTypes";
import { ParserFilterBar, ParserFilterForm } from "./ParserFilterBar";
import { Parser, ParserFilters, ParserFiltersTranslator, ParserMethodFilters } from "../../api/Parsers";
import { NotAvailable } from "../../common/misc/UIconstants";
import { useEntityCount, useEntityDetail } from "../../api/BaseEntityApi";
import { Vendor, VendorFilters } from "../../api/Vendors";
import { LoadingWrapper } from "../../common/LoadingWrapper";
import { FloatingDiv } from "../../common/buttons/FloatingDiv/FloatingDiv";
import styles from "./styles.module.css";
import { SessionContext } from "../../common/contexts/SessionContext";
import { ExportCsvButton } from "../../common/tables/ExportCsvButton/ExportCsvButton";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { Count } from "../../common/loaders/Count/Count";
import {
  UseEntityTableDefaultProps,
  UseEntityTableProps,
  useGenericVirtualizedTable,
  useGenericVirtualizedTableTabs,
} from "../../common/tables/GenericVirtualizedTable/useGenericVirtualizedTable";
import { EntityTableProps } from "../../common/entity/EntityInterfaces";

export const switchParsersDefaultSortState = (
  sortState: ParserFilters["orderBy"]
): SortState<ParserFilters["orderBy"]> => {
  switch (sortState) {
    case "ID_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-id",
        orderBy: sortState,
      };
    case "ID_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-id",
        orderBy: sortState,
      };
    case "NAME_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-name",
        orderBy: sortState,
      };
    case "NAME_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-name",
        orderBy: sortState,
      };
    default:
      return {
        sortDirection: "ASC",
        headerId: "default-name",
        orderBy: sortState,
      };
  }
};
const defaultFilter: ParserFilters = { orderBy: "NAME_ASC" };

export const useParsersTableDefaults = ({ fieldLabels }: UseEntityTableDefaultProps<"parsers">) => {
  const { session } = useContext(SessionContext);

  const defaults: ColumnsSettings<Parser> = useMemo(
    () => ({
      "default-id": { pos: 0, active: false, header: fieldLabels.id, property: "id" },
      "default-name": { pos: 1, active: true, header: fieldLabels.name, property: "name" },
      "default-version": { pos: 2, active: false, header: fieldLabels.version, property: "version" },
      "default-methods": { pos: 3, active: true, header: fieldLabels.methods, property: "metaData" },
      "default-vendors": { pos: 4, active: true, header: fieldLabels.vendors, property: "metaData" },
      "default-instruments": { pos: 5, active: false, header: fieldLabels.instruments, property: "metaData" },
      "default-formats": { pos: 6, active: false, header: fieldLabels.formats, property: "metaData" },
      "default-hasVisualization": {
        pos: 7,
        active: true,
        header: fieldLabels.hasVisualization,
        property: "hasVisualization",
      },
      "default-isCustom": { pos: 8, active: false, header: fieldLabels.isCustom, property: "isCustom" },
      ...(session?.features.development_mode && {
        "devel-isBeta": { pos: 9, active: true, header: fieldLabels.isBeta, property: "isBeta" },
      }),
    }),
    [
      fieldLabels.formats,
      fieldLabels.hasVisualization,
      fieldLabels.id,
      fieldLabels.instruments,
      fieldLabels.isBeta,
      fieldLabels.isCustom,
      fieldLabels.methods,
      fieldLabels.name,
      fieldLabels.vendors,
      fieldLabels.version,
      session?.features.development_mode,
    ]
  );

  const tabStoreDefaults: TableTabsDict<Parser, ParserFilters, ParserFilterForm> = useMemo(
    () => ({
      default: {
        tabId: "default",
        type: "fixed",
        label: "All",
        title: "All",
        icon: "house",
        align: "left",
        xPos: 0,
        settings: {
          columnSettings: {},
          columnWidths: {},
          filters: defaultFilter,
          sidebarFilters: {},
        },
        forcedSettings: {
          columnSettings: {},
          columnWidths: {},
          filters: {},
          sidebarFilters: {},
        },
      },
    }),
    []
  );
  return { defaults, tabStoreDefaults };
};

export const useParsersTableColumns = ({
  entityConstants,
  fieldLabels,
  sort,
  setSort,
}: UseEntityTableProps<"parsers">) => {
  const { session } = useContext(SessionContext);

  const columns = useMemo(() => {
    let headers: GenericVirtualizedTableCells<Parser> = [
      {
        id: "default-id",
        Header: fieldLabels.id,
        accessor: (row) => (
          <div
            className="flex align-center gap-5"
            style={{ width: "100%", height: "100%" }}
            data-toggle="tooltip"
            title={row.id}
          >
            <div
              style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                fontWeight: 600,
                whiteSpace: "nowrap",
                marginRight: "5px",
                color: "var(--gray-400)",
              }}
            >
              {row.id}
            </div>
          </div>
        ),
        width: 240,
        minWidth: 240,
        align: "center",
        sortingFn: () => {
          if (sort.headerId === "default-id") {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "ID_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "ID_ASC" }));
            }
          } else {
            setSort({ headerId: "default-id", sortDirection: "ASC", orderBy: "ID_ASC" });
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
      {
        Header: fieldLabels.name,
        id: "default-name",
        accessor: (row) => (
          <div
            className="flex align-center gap-5"
            style={{ width: "100%", height: "100%" }}
            data-toggle="tooltip"
            title={row.name}
          >
            <LucideIcon name={entityConstants.icon} color="var(--primary)" />
            <div
              style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                fontWeight: 600,
                whiteSpace: "nowrap",
                marginRight: "5px",
              }}
            >
              {row.name}
            </div>
          </div>
        ),
        minWidth: 200,
        width: 400,
        align: "left",
        sortingFn: () => {
          if (sort.headerId === "default-name") {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "NAME_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "NAME_ASC" }));
            }
          } else {
            setSort({ headerId: "default-name", sortDirection: "ASC", orderBy: "NAME_ASC" });
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
      {
        Header: fieldLabels.version,
        id: "default-version",
        accessor: (row) => <span>{row.version}</span>,
        minWidth: 150,
        width: 150,
        align: "left",
      },
      {
        Header: fieldLabels.vendors,
        id: "default-vendors",
        accessor: (row) => (
          <div className="flex align-center gap-5" style={{ overflowX: "auto", overflowY: "hidden" }}>
            {row.metaData?.map((metadata, index) => {
              if (Array.isArray(metadata.vendor) && metadata.vendor.length > 0) {
                return metadata.vendor?.map((f, i) => <ShowVendor vendorId={f.id} key={`vendor-${index}-${i}`} />);
              } else {
                return <React.Fragment key={`vendors-${index}`}>{NotAvailable}</React.Fragment>;
              }
            })}
          </div>
        ),
        minWidth: 410,
        width: 410,
        align: "left",
      },
      {
        Header: fieldLabels.methods,
        id: "default-methods",
        accessor: (row) => (
          <div className="flex align-center col-wrap gap-5" style={{ overflow: "auto" }}>
            {row.metaData?.map((metadata, index) => {
              if (Array.isArray(metadata.method) && metadata.method.length > 0) {
                return (
                  <div
                    key={`methods-${index}`}
                    style={{
                      width: "100%",
                      display: "flex",
                      flexWrap: "wrap",
                      gap: "5px",
                      alignItems: "center",
                      height: "100%",
                    }}
                  >
                    {metadata.method?.map((f, i) => (
                      <div
                        className="label label-soft-secondary"
                        style={{ minWidth: "max-content", overflow: "hidden" }}
                        title={f.fullName}
                        key={i}
                      >
                        <div>{f.name}</div>
                      </div>
                    ))}
                  </div>
                );
              } else {
                return <div key={`methods-${index}`}>{NotAvailable}</div>;
              }
            })}
          </div>
        ),
        minWidth: 300,
        width: 300,
        align: "left",
      },
      {
        Header: fieldLabels.formats,
        id: "default-formats",
        accessor: (row) => (
          <div className="flex align-center col-wrap gap-5" style={{ overflow: "auto" }}>
            {row.metaData?.map((metadata, index) => {
              if (Array.isArray(metadata.format) && metadata.format.length > 0) {
                return (
                  <div
                    key={`formats-${index}`}
                    style={{ width: "100%", display: "flex", flexWrap: "wrap", gap: "5px" }}
                  >
                    {metadata.format?.map((f, i) => (
                      <div className="label label-soft-secondary" key={`formats-${index}-${i}`} title={f.name}>
                        {f.name}
                      </div>
                    ))}
                  </div>
                );
              } else {
                return <div key={`formats-${index}`}>{NotAvailable}</div>;
              }
            })}
          </div>
        ),
        minWidth: 200,
        width: 400,
        align: "left",
      },
      {
        Header: fieldLabels.instruments,
        id: "default-instruments",
        accessor: (row) => (
          <div className="flex align-center col-wrap gap-5" style={{ overflow: "auto" }}>
            {row.metaData?.map((metadata, index) => {
              if (Array.isArray(metadata.instrument) && metadata.instrument.length > 0) {
                return (
                  <div
                    key={`instruments-${index}`}
                    style={{ width: "100%", display: "flex", flexWrap: "wrap", gap: "5px" }}
                  >
                    {metadata.instrument?.map((f, i) => (
                      <div className="label label-soft-secondary" key={`instruments-${index}-${i}`}>
                        {f.name}
                      </div>
                    ))}
                  </div>
                );
              } else {
                return <div key={`instruments-${index}`}>{NotAvailable}</div>;
              }
            })}
          </div>
        ),
        minWidth: 200,
        width: 400,
        align: "left",
      },
      {
        Header: fieldLabels.hasVisualization,
        id: "default-hasVisualization",
        accessor: (row) => (
          <div className="flex align-center">
            {row.hasVisualization ? (
              <span style={{ color: "var(--success)" }} title="Has visualization">
                <LucideIcon name="check" />
              </span>
            ) : (
              <span style={{ color: "var(--gray-300)" }} title="No visualization">
                <LucideIcon name="x" />
              </span>
            )}
          </div>
        ),
        minWidth: 200,
        width: 200,
        align: "center",
      },
      {
        Header: fieldLabels.isCustom,
        id: "default-isCustom",
        accessor: (row) => (
          <div className="flex align-center">
            {row.isCustom ? (
              <span style={{ color: "var(--success)" }} title="Custom format">
                <LucideIcon name="check" />
              </span>
            ) : (
              <span style={{ color: "var(--gray-300)" }} title="Default format">
                <LucideIcon name="x" />
              </span>
            )}
          </div>
        ),
        minWidth: 200,
        width: 200,
        align: "center",
      },
    ];

    if (session?.features.development_mode) {
      headers.push({
        Header: fieldLabels.isBeta,
        id: "devel-isBeta",
        accessor: (row) => (
          <div className="flex align-center">
            {row.isBeta ? (
              <span style={{ color: "var(--warning)" }} title="In development">
                <LucideIcon name="construction" />
              </span>
            ) : (
              <span style={{ color: "var(--success)" }} title="In production">
                <LucideIcon name="check" />
              </span>
            )}
          </div>
        ),
        minWidth: 200,
        width: 200,
        align: "center",
      });
    }

    return headers;
  }, [
    entityConstants.icon,
    fieldLabels.formats,
    fieldLabels.hasVisualization,
    fieldLabels.id,
    fieldLabels.instruments,
    fieldLabels.isBeta,
    fieldLabels.isCustom,
    fieldLabels.methods,
    fieldLabels.name,
    fieldLabels.vendors,
    fieldLabels.version,
    session?.features.development_mode,
    setSort,
    sort.headerId,
    sort.sortDirection,
  ]);
  return { columns };
};
export const ParsersTable = ({
  entityApi,
  entityConstants,
  fieldLabels,
  permissions,
  routes,
}: EntityTableProps<"parsers">) => {
  const { defaults, tabStoreDefaults } = useParsersTableDefaults({ fieldLabels });

  const { resultsCount, onCountChange } = useGenericVirtualizedTable<Parser>();

  const {
    filters,
    sidebarFilters,
    forcedFilters,
    columnSetting,
    columnWidths,
    customTabs,
    fixedTabs,
    dispatchTabStore,
    currentTab,
    tabsLoading,
    tabsModified,
  } = useTabStore<Parser, ParserFilters, ParserFilterForm>({
    resource: entityConstants.resource,
    defaults: tabStoreDefaults,
  });

  const { functionRef, sort, setSort, searchValue, setSearchValue, onTabChange, debouncedSearchValue } =
    useGenericVirtualizedTableTabs({
      tabsLoading,
      filters,
      switchSortState: switchParsersDefaultSortState,
      dispatchTabStore,
    });

  const { columns } = useParsersTableColumns({ fieldLabels, entityConstants, sort, setSort });

  const memoizedFilters = useMemo(
    () => ({ ...filters, ...forcedFilters, includeRelations: true }),
    [filters, forcedFilters]
  );

  const { data: parsersCount } = useEntityCount<ParserFilters>(
    "parsers",
    {
      page: 1,
      pageSize: 1,
      includeCount: true,
      hasVisualization: true,
    },
    { enabled: true }
  );

  const { data: customParsersCount } = useEntityCount<ParserFilters>(
    "parsers",
    {
      page: 1,
      pageSize: 1,
      includeCount: true,
      hasVisualization: true,
      isCustom: true,
    },
    { enabled: true }
  );

  const { data: vendorsCount } = useEntityCount<VendorFilters>(
    "vendors",
    {
      page: 1,
      pageSize: 1,
      includeCount: true,
    },
    { enabled: true }
  );

  const { data: parserMethodsCount } = useEntityCount<ParserMethodFilters>(
    "parser_methods",
    {
      page: 1,
      pageSize: 1,
      includeCount: true,
    },
    { enabled: true }
  );

  return (
    <TableView>
      <TableView.Head>
        <TableView.Head.Label>
          <TableViewLabel entityConstants={entityConstants} resultsCount={resultsCount} />
          {!!parsersCount && !!vendorsCount && !!parserMethodsCount && (
            <div
              className="flex align-center gap-5"
              style={{
                color: "var(--gray-500)",
                fontSize: "14px",
                width: "100%",
                flexWrap: "wrap",
                margin: "0 10px",
                justifyContent: "center",
              }}
            >
              <div>LOGS is currently supporting</div>
              <div className="flex align-center gap-5" style={{ flexWrap: "nowrap" }}>
                <div className="flex align-center">
                  <Count num={parsersCount.count} height={42} duration={0.75} />
                </div>
                <div>{entityConstants.entityPlural}</div>
                {!!customParsersCount?.count && (
                  <>
                    <div>including</div>
                    <div className="flex align-center">
                      <Count num={customParsersCount.count} height={42} duration={0.75} />
                    </div>
                    <div>custom {entityConstants.entityPlural}</div>
                  </>
                )}
              </div>
              <div>from</div>
              <div className="flex align-center gap-5" style={{ flexWrap: "nowrap" }}>
                <div className="flex align-center">
                  <Count num={vendorsCount.count} height={42} duration={0.75} />
                </div>
                <div>vendor{vendorsCount.count > 1 && "s"}</div>
              </div>
              <div>covering</div>
              <div className="flex align-center gap-5" style={{ flexWrap: "nowrap" }}>
                <div className="flex align-center">
                  <Count num={parserMethodsCount.count} height={42} duration={1.25} />
                </div>
                <div>measurement method{parserMethodsCount.count > 1 && "s"}</div>
              </div>

              <OverlayTrigger
                placement="bottom"
                overlay={
                  <Popover id="popover-info" style={{ textAlign: "justify" }}>
                    The listed instruments and formats are tested by us explicitly. However, the vendor formats usually
                    are stable over a broad number of instruments and software versions. Hence, we probably support more
                    than listed in the table below. If your format is not supported at the moment, please feel free to
                    contact us for additional implementations.
                  </Popover>
                }
              >
                <LucideIcon name="info" color={"var(--gray-400)"} />
              </OverlayTrigger>
            </div>
          )}
        </TableView.Head.Label>
        <TableView.Head.Controls>
          <a
            href="https://logs-repository.atlassian.net/servicedesk/customer/portal/4"
            target="_blank"
            rel="noreferrer"
          >
            <button className="btn btn-primary">
              <LucideIcon name="plus" /> <span> Submit new {entityConstants.entitySingular}</span>
            </button>
          </a>
        </TableView.Head.Controls>
      </TableView.Head>
      <TableView.Body>
        <TableView.Body.Sidebar>
          <ParserFilterBar
            initialValues={sidebarFilters}
            dispatchTabStore={dispatchTabStore}
            tabsLoading={tabsLoading}
            currentTab={currentTab}
          />
        </TableView.Body.Sidebar>
        <TableView.Body.Content>
          <EntityTableTabs
            currentTab={currentTab}
            onTabChange={onTabChange}
            fixedTabs={fixedTabs}
            filters={filters}
            sidebarFilters={sidebarFilters}
            columnSetting={columnSetting}
            columnWidths={columnWidths}
            customTabs={customTabs}
            dispatchTabStore={dispatchTabStore}
            tabsLoading={tabsLoading}
            tabsModified={tabsModified}
          />
          <EntityTable>
            <EntityTable.Controls
              style={{
                borderTop: "0px",
                borderRadius: "0px",
              }}
            >
              <EntityFilterIndicator<Parser, ParserFilters>
                filters={filters}
                excludeFilters={{ includeBetaParsers: (value) => !!value }}
                translatorConsts={ParserFiltersTranslator}
              />
              <SearchInput searchValue={searchValue} setSearchValue={setSearchValue} placeholder="Search" />

              <ExportCsvButton<Parser, ParserFilters>
                entityConstants={entityConstants}
                columnSettings={columnSetting}
                filters={{ ...memoizedFilters, searchTerm: debouncedSearchValue }}
                className="btn btn-default"
              />
            </EntityTable.Controls>
            <EntityTable.Body<Parser, ParserFilters>
              functionRef={functionRef}
              entityConstants={entityConstants}
              filters={memoizedFilters}
              columns={columns}
              columnSelect
              columnSetting={columnSetting}
              columnWidths={columnWidths}
              defaultColumnSettings={defaults}
              dispatchTabStore={dispatchTabStore}
              setResultsCount={onCountChange}
              loading={tabsLoading}
              fixedRowHeight={55}
              onRowClick={() => {}}
              linkTo={() => "#"}
              disableCheckboxes
              tableRowHoverCls={styles.tableRowHover}
            />
          </EntityTable>
        </TableView.Body.Content>
      </TableView.Body>
    </TableView>
  );
};

interface ShowcaseCardProps {
  title?: string;
  label?: string;
  style?: CSSProperties;
  spanStyle?: CSSProperties;
  onClick?: () => void;
  children?: React.ReactNode;
}
export const ShowcaseCard = ({ title, label, style, spanStyle, onClick, children }: ShowcaseCardProps) => {
  return (
    <div className={styles.vendor_showcase} title={title} style={style} onClick={() => onClick?.()}>
      <span style={spanStyle}>{label}</span>
      <div className={styles.icon_wrapper}>{children}</div>
    </div>
  );
};

export const ShowVendor = ({ vendorId }: { vendorId: string }) => {
  const {
    data: vendor,
    status,
    fetchStatus,
  } = useEntityDetail<Vendor, VendorFilters>("vendors", vendorId, { includeIcon: true }, { enabled: !!vendorId });
  if (!vendorId) {
    return <></>;
  }
  return (
    <LoadingWrapper status={status} fetchStatus={fetchStatus}>
      {vendor && (
        <FloatingDiv
          triggerComponent={({ ref, getReferenceProps, setOpen }) => (
            <button
              ref={ref}
              className="btn btn-xs btn-ghost-secondary flex align-center gap-5"
              style={{ minWidth: "max-content", overflow: "hidden" }}
              onMouseEnter={() => setOpen(true)}
              onMouseLeave={() => setOpen(false)}
              {...getReferenceProps}
            >
              {vendor.icon ? (
                <div style={{ height: "100%", width: "30px", display: "flex" }}>
                  <img
                    src={vendor.icon}
                    alt={vendor.name}
                    className={styles.icon}
                    style={{
                      filter: `${vendor.id === "gas" ? "invert(1)" : "normal"}`,
                    }}
                  />
                </div>
              ) : (
                <div style={{ height: "100%", width: "30px", display: "flex" }}>
                  <DefaultVendorIcon vendor={vendor} />
                </div>
              )}

              <div>{vendor.name}</div>
            </button>
          )}
        >
          {() => (
            <ShowcaseCard
              title={vendor.name}
              label={vendor.name}
              style={{ height: "128px", width: "128px" }}
              spanStyle={{ fontSize: "12px" }}
            >
              {vendor.icon ? (
                <div
                  style={{
                    padding: "5px",
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={vendor.icon}
                    alt={vendor.name}
                    className={styles.icon}
                    style={{
                      width: "100%",
                      height: "100%",
                      filter: `${vendor.id === "gas" ? "invert(1)" : "normal"}`,
                    }}
                  />
                </div>
              ) : (
                <DefaultVendorIcon vendor={vendor} />
              )}
            </ShowcaseCard>
          )}
        </FloatingDiv>
      )}
    </LoadingWrapper>
  );
};

const DefaultVendorIcon = ({ vendor }: { vendor: Vendor }) => {
  return (
    <div className="flex align-center" style={{ justifyContent: "center" }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="none"
        stroke="var(--gray-400)"
        strokeWidth="1"
        style={{ width: "90%", height: "90%" }}
      >
        <rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect>
        <circle cx="8.5" cy="8.5" r="1.5"></circle>
        <polyline points="21 15 16 10 5 21"></polyline>
      </svg>
    </div>
  );
};
