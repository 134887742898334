import {
  DataSource,
  DataSourceFilters,
  DataSourceFiltersTranslator,
  DataSourceSortingOptionsConsts,
  dataSourceConstants,
} from "../../../api/DataSource";
import { DateTimeRenderer } from "../../datetime/DateTimeFormatter";
import { EntitySidebarTile, EntitySidebarTileProps } from "./EntitySidebarTile/EntitySidebarTile";

export const DataSourcesTile = (props: EntitySidebarTileProps<DataSource, DataSourceFilters>) => (
  <EntitySidebarTile<DataSource, DataSourceFilters>
    {...props}
    entityConstants={dataSourceConstants}
    orderByOptions={DataSourceSortingOptionsConsts}
    translatorConsts={DataSourceFiltersTranslator}
    defaultFilters={{ orderBy: "NAME_ASC", ...props.defaultFilters }}
    rowLabel={(e) => <>{e.name}</>}
    rowDate={(e) => <DateTimeRenderer date={e.createdOn} />}
    rowInfo={(e) => <>{e.type}</>}
  />
);
