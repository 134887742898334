import React, { CSSProperties } from "react";

import icons from "./icons.json";

export type iconTypes = keyof typeof icons;

// const icons: Record<iconTypes, any> = {
//   curves: (
//     <g className={styles.icon}>
//       <path d="m 2.4267767,0 c 0,5.3323333 0,10.665667 0,16 M 0,13.466529 c 5.34475,0 10.6885,0 16.03125,0" />
//       <path
//         transform="matrix(0.87570349,0,0,0.57976864,1.7706159,3.8141639)"
//         d="m 3.5322302,4.4882731 c 0.7494996,3.0865273 1.499173,6.1737699 2.6090925,6.8367959 1.1099196,0.663025 2.5783295,-1.096984 4.1295593,-1.4040163 1.551231,-0.3070326 3.187652,0.8395043 4.822868,1.9851973"
//       />
//       <path
//         transform="matrix(0.87570349,0,0,0.57976864,1.8560319,-1.3536673)"
//         d="m 3.5322302,4.4882731 c 0.7494996,3.0865273 1.499173,6.1737699 2.6090925,6.8367959 1.1099196,0.663025 2.5783295,-1.096984 4.1295593,-1.4040163 1.551231,-0.3070326 3.187652,0.8395043 4.822868,1.9851973"
//       />
//     </g>
//   ),
//   // <g className={styles.icon}>
// };

export const IconBox = React.memo(
  ({ type, width, style, invert }: { type: string; width?: number; style?: CSSProperties; invert?: boolean }) => {
    // console.log("icons", Object.keys(icons));

    const cssStyle = { width: width || 16, filter: invert ? "invert(100%)" : "" };

    Object.assign(cssStyle, style);

    return (
      <span>
        <img alt={"X"} src={type in icons ? icons[type as keyof typeof icons] : ""} style={cssStyle} />
        {/* <svg height={16} width={16}> */}
        {/* {icons[type]} */}
        {/* </svg> */}
      </span>
    );
  }
);
