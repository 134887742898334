import { useEffect } from "react";
import { useResizeDetector } from "react-resize-detector";

// Custom hook to callback RGL parent with calculated height in grid units
export const useDynamicHeight = ({
  nodeHeightCallback,
  uuid,
  gridRowHeight,
  padding = 0,
}: {
  nodeHeightCallback: (uuid: string, height: number) => void;
  uuid: string;
  gridRowHeight: number;
  padding?: number;
}) => {
  const { ref, height } = useResizeDetector();
  useEffect(() => {
    if (height) {
      // RGL sets a default margin of margin: ?[number, number] = [10, 10],
      // Here we calculate pixel height to grid units
      const h = Math.ceil((height + 5 + padding) / (gridRowHeight + 10));
      nodeHeightCallback(uuid, h);
    }
  }, [gridRowHeight, height, nodeHeightCallback, padding, uuid]);
  return { ref, height };
};
