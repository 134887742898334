import React from "react";
import ReactDOM from "react-dom";

interface PortalProps {
  children: React.ReactNode;
  className?: string;
  element?: string;
}

export const Portal = ({ children, className = "root-portal", element = "div" }: PortalProps) => {
  const container = React.useMemo(() => {
    const el = document.createElement(element);
    el.classList.add(className);
    return el;
  }, [className, element]);

  React.useEffect(() => {
    document.body.appendChild(container);
    return () => {
      document.body.removeChild(container);
    };
  }, [container]);

  return ReactDOM.createPortal(children, container);
};
