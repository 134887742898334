import { Dispatch, Fragment, SetStateAction, useCallback, useMemo, useState } from "react";
import { LucideIcon } from "../common/icon/LucideIcon";
import styles from "./ParserLogsIcon.module.css";
import { viewerLogType } from "./ViewerLayoutTypes";
import { Modal } from "../ELN/common/ELNModal/ELNModal";
import Codeblock from "../common/textfields/Codeblock/Codeblock";

export const ParserLogsModal = ({
  logs,
  show,
  setShow,
}: {
  logs: viewerLogType[];
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
}) => {
  const [showDescription, setShowDescription] = useState<number>();

  const iconSwitch = useCallback((type: string) => {
    switch (type) {
      case "error":
        return <LucideIcon name="triangle-alert" />;
      case "warning":
        return <LucideIcon name="triangle-alert" />;
      default:
        return <LucideIcon name="circle-alert" />;
    }
  }, []);

  const setShowCallback = useCallback(() => {
    setShow((prevState) => !prevState);
    setShowDescription(undefined);
  }, [setShow]);

  const backgroundClass = useCallback((type: string) => {
    switch (type) {
      case "error":
        return "var(--danger-light)";
      case "warning":
        return "var(--warning-light)";
      default:
        return "var(--info-light)";
    }
  }, []);

  const labelSwitch = useCallback((type: string) => {
    switch (type) {
      case "error":
        return "Error";
      case "warning":
        return "Warning";
      default:
        return "Info";
    }
  }, []);

  var labelBackgroundClass = useCallback((type: string) => {
    switch (type) {
      case "error":
        return "label-soft-danger";
      case "warning":
        return "label-soft-warning";
      default:
        return "label-soft-info";
    }
  }, []);

  var codeblockBorderClass = useCallback((type: string) => {
    switch (type) {
      case "error":
        return "var(--danger)";
      case "warning":
        return "var(--warning)";
      default:
        return "var(--info)";
    }
  }, []);

  return (
    <Modal isOpen={show} onClose={setShowCallback}>
      <Modal.Title>
        Parser info <div className="badge">{logs.length ?? 0}</div>
      </Modal.Title>

      <Modal.Body style={{ minWidth: "90vw", minHeight: "50vh", paddingBottom: "10px", overflow: "auto" }}>
        <div style={{ width: "100%" }}>
          <table className={styles.ParserLogsModalTable}>
            <tbody className={styles.ParserLogsModalBody}>
              {logs.map((log, i) => (
                <Fragment key={i}>
                  <tr
                    className={styles.ParserLogsModalBodyTr}
                    onClick={() => setShowDescription((prevState) => (prevState !== i ? i : undefined))}
                    style={{ backgroundColor: showDescription === i ? backgroundClass(log.type) : undefined }}
                  >
                    <td className={styles.ParserLogsModalBodyTdIcon}>
                      <div style={{ width: "100%" }} className={`label ${labelBackgroundClass(log.type)}`}>
                        {iconSwitch(log.type)} {labelSwitch(log.type)}
                      </div>
                    </td>
                    <td className={styles.ParserLogsModalBodyTdMessage}>
                      <div className={styles.ParserLogsModalMessageContainer} title={log.message}>
                        <div>
                          {showDescription === i ? (
                            <LucideIcon name="chevron-down" />
                          ) : (
                            <LucideIcon name="chevron-right" />
                          )}
                        </div>
                        <div className={styles.ParserLogsModalMessage}>{log.message}</div>
                      </div>
                    </td>
                  </tr>
                  {log.description && showDescription === i && (
                    <tr className={styles.ParserLogsModalBodyTr} style={{ backgroundColor: backgroundClass(log.type) }}>
                      <td />
                      <td colSpan={2} className={styles.ParserLogsModalBodyTdDescription}>
                        <Codeblock borderColor={codeblockBorderClass(log.type)}>{log.description}</Codeblock>
                      </td>
                    </tr>
                  )}
                </Fragment>
              ))}
            </tbody>
          </table>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export const ParserLogsButton = ({ logs, label }: { logs: viewerLogType[]; label?: string }) => {
  const [show, setShow] = useState(false);

  const icon = useMemo(() => {
    if (logs.some((log) => log.type === "error")) return <LucideIcon name="triangle-alert" color="var(--danger)" />;
    if (logs.some((log) => log.type === "warning")) return <LucideIcon name="triangle-alert" color="var(--warning)" />;
    return <LucideIcon name="circle-alert" color="var(--info)" />;
  }, [logs]);

  const buttonClass = useMemo(() => {
    if (logs.some((log) => log.type === "error")) return "btn-ghost-danger";
    if (logs.some((log) => log.type === "warning")) return "btn-ghost-warning";
    return "btn-ghost-info";
  }, [logs]);

  return (
    <div>
      <button
        className={`btn btn-sm ${buttonClass} flex align-center gap-5`}
        onClick={() => {
          setShow((prevState) => !prevState);
        }}
      >
        <div>{icon}</div>
        {!!label && <div>{label}</div>}
      </button>
      <ParserLogsModal logs={logs} show={show} setShow={setShow} />
    </div>
  );
};
