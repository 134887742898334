import React from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { LucideIcon, IconNames } from "../../icon/LucideIcon";
import { v4 as uuidv4 } from "uuid";
import { zIndex } from "../../../api/CommonConstants";

interface OverlayInfoProps {
  icon: IconNames;
  children: React.ReactNode;
}
export const OverlayInfo = ({ icon, children }: OverlayInfoProps) => {
  const id = uuidv4();
  return (
    <OverlayTrigger
      placement="bottom"
      overlay={
        <Popover id={id} style={{ zIndex: zIndex.zIndexPortalDropdown }}>
          {children}
        </Popover>
      }
    >
      <LucideIcon name={icon} color={"var(--gray-400)"} />
    </OverlayTrigger>
  );
};
