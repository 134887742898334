import { useContext, useMemo } from "react";
import { API } from "../api/Api";
// import { ReactQueryDevtools } from "react-query/devtools";
import { ApiAuthentication, Session } from "../api/ApiTypes";
import { SessionContext } from "../common/contexts/SessionContext";
import { FullScreenWrapper } from "../common/wrapper/FullScreenWrapper/FullScreenWrapper";
// import { ToasterContainerView } from "../common/overlays/Toasts/ToasterContainerView";
import styles from "./main.module.css";
import { MainRouter } from "./MainRouter";
import { NavigationBar } from "./NavigationBar";
import { useEntityDetail } from "../api/BaseEntityApi";
import { Person, PersonFilters } from "../api/Person";
import { PersonEditForm } from "../Persons/PersonViews";
import { Alert } from "../common/overlays/Alert/Alert";
import React from "react";
import { useQuery } from "@tanstack/react-query";
import { LoadingWrapper } from "../common/LoadingWrapper";
import { GroupStatus } from "../api/GroupStatus";
import { AuthConfig } from "../api/Login";
import { LicenseProvider } from "./LoginPage/LicenseProvider";

interface Props {
  group: string;
  session: Session;
  authConfig?: AuthConfig;
  clearSession: () => void;
}

export const Main = ({ group, session, authConfig, clearSession }: Props) => {
  const auth =
    session.type === "jwt"
      ? { type: "jwt", value: `group_${group}_user:${authConfig?.oidcAuthority}:${authConfig?.oidcClientId}` }
      : { type: "session", value: session.sessionId! };
  const api = new API(group, auth as ApiAuthentication);
  const route = useMemo(() => (url: string) => `/${group}${url}`, [group]);

  return (
    <LicenseProvider api={api}>
      {({ license }) => (
        <>
          {/* ToastContainerView is embedded in announcements, which should be present everywhere */}
          {/* <ToasterContainerView />  */}
          <SessionContext.Provider value={{ api, session, group, clearSession, route, license }}>
            {/* <ReactQueryDevtools initialIsOpen={false} /> */}
            <MainLayout>
              <MigrationModeWrapper>
                <AccountRequiredFieldWrapper>
                  <MainRouter />
                </AccountRequiredFieldWrapper>
              </MigrationModeWrapper>
            </MainLayout>
          </SessionContext.Provider>
        </>
      )}
    </LicenseProvider>
  );
};

export const MainLayout = ({ children }: { children: React.ReactNode }) => (
  <>
    <NavigationBar />
    <FullScreenWrapper>
      <div className={styles.mainContentContainer}>{children}</div>
    </FullScreenWrapper>
  </>
);

const AccountRequirements = ["email", "lastName"] as Array<keyof Person>;

export const AccountRequiredFieldWrapper = ({ children }: { children: JSX.Element }) => {
  const { session } = useContext(SessionContext);
  const { data: currentUser } = useEntityDetail<Person, PersonFilters>(
    "persons",
    session?.userId || 0,
    {},
    { enabled: !!session?.userId && !!session?.features?.enable_after_login_account_validation_form }
  );

  if (session?.features?.enable_after_login_account_validation_form) {
    if (!currentUser?.id)
      return <Alert type="danger" message={"You don't have permissions to view this page."} fit centered />;
    if (AccountRequirements.some((p) => !currentUser?.[p]))
      return (
        <>
          <div style={{ position: "sticky", top: 0, left: 0, right: 0, zIndex: 999 }}>
            <Alert
              type="info"
              message={
                "A new update requires to update your account information. Please fill out all required fields to continue."
              }
              fit
              centered
            />
          </div>
          <div className="flex justify-center" style={{ paddingBottom: "30px" }}>
            <PersonEditForm id={currentUser?.id} />
          </div>
        </>
      );
  }
  return children;
};

export const MigrationModeWrapper = ({ children }: { children: JSX.Element }) => {
  const { api } = useContext(SessionContext);
  const {
    data: group_status,
    status,
    fetchStatus,
    error,
  } = useQuery<any, any, GroupStatus, any>({
    queryKey: ["status"],
    queryFn: async ({ signal }: { signal: any }) => await api.get("status", undefined, signal),
    refetchInterval: (data: GroupStatus) =>
      !data || !Object.values(data.groupMigrationStatus).every((v) => v === "Completed") ? 5000 : false,
  });

  const content = useMemo(() => {
    if (!group_status) {
      // if (!group_status || group_status.migrationStatus === undefined) {
      return (
        <Alert
          type="warning"
          message={
            <div style={{ padding: "20px", display: "flex", flexDirection: "column", gap: "5px" }}>
              <div style={{ fontWeight: 600, fontSize: "1.7rem" }}>Unknown migration status.</div>
              <div>Please contact your local LOGS administrator or the LOGS support team...</div>
            </div>
          }
          fit
          centered
        />
      );
    }
    if (group_status && Object.values(group_status.groupMigrationStatus).some((v) => v === "Failed")) {
      // if (group_status?.migrationStatus === "Failed") {
      return (
        <Alert
          type="danger"
          message={
            <div style={{ padding: "20px", display: "flex", flexDirection: "column", gap: "5px" }}>
              <div style={{ fontWeight: 600, fontSize: "1.7rem" }}>Unexpected error during system maintenance.</div>
              <div>Please contact your local LOGS administrator or the LOGS support team...</div>
            </div>
          }
          fit
          centered
        />
      );
    }
    if (group_status && Object.values(group_status.groupMigrationStatus).every((v) => v === "Completed")) {
      // if (group_status?.migrationStatus === "Completed") {
      return children;
    }
    return (
      <Alert
        type="info"
        message={
          <div style={{ padding: "20px", display: "flex", flexDirection: "column", gap: "5px" }}>
            <div style={{ fontWeight: 600, fontSize: "1.7rem" }}>The system is currently undergoing maintenance.</div>
            <div>Once finished, the page will automatically be updated...</div>
          </div>
        }
        fit
        centered
      />
    );
  }, [children, group_status]);

  return (
    <LoadingWrapper status={status} fetchStatus={fetchStatus} error={error}>
      {content}
    </LoadingWrapper>
  );
};
