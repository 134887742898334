import { ReactNode } from "react";
import { ElnHeaderParams, Header } from "../../Header/Header";
import styles from "./ELNLayoutContainer.module.css";

export const ELNLayoutContainer = ({
  headerParams,
  children,
}: {
  headerParams: ElnHeaderParams;
  children: ReactNode;
}) => {
  return (
    <div className={styles.wrapperContainer}>
      <div className={styles.headerContainer}>
        <Header {...headerParams} />
      </div>
      <div className={styles.contentContainer}>{children}</div>
    </div>
  );
};
