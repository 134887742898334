import { useHistory } from "react-router-dom";
import { CustomField, CustomFieldFilters, CustomFieldFiltersTranslator } from "../../../api/CustomFields";
import { ColumnsSettings } from "../../../common/tables/ColumnsSelector/ColumnsSelector";
import {
  GenericVirtualizedTableCells,
  SortState,
} from "../../../common/tables/GenericVirtualizedTable/GenericVirtualizedTableTypes";
import { useMemo, useState } from "react";
import { CustomFieldFilterForm, CustomFieldsFilterBar } from "./CustomFieldsFilterBar";
import { TableTabsDict } from "../../../common/tables/Tabs/TableTabsTypes";
import { useTabStore } from "../../../common/tables/Tabs/useTabStore";
import { LucideIcon } from "../../../common/icon/LucideIcon";
import { DateTimeRenderer } from "../../../common/datetime/DateTimeFormatter";
import { GetPersons } from "../../../common/misc/EntityRenders/EntityRenderer";
import TableView, { TableViewLabel } from "../../../common/panels/TableView/TableView";
import { EntityTableTabs } from "../../../common/tables/Tabs/EntityTableTabs";
import { EntityTable } from "../../../common/tables/EntityTable/EntityTable";
import { EntityFilterIndicator } from "../../../common/tables/EntityFilterIndicator/EntityFilterIndicator";
import { SearchInput } from "../../../common/forms/SearchInput/SearchInput";
import { MoreDropdown } from "../../../common/buttons/MoreDropdown/MoreDropdown";
import { AlertModal } from "../../../common/modals/AlertModal/AlertModal";
import styles from "../../../common/tables/GenericVirtualizedTable/commons.module.css";
import { AbstractedCustomFieldDataTypeIcon } from "../CustomFieldRenderUtils";
import { generateAbstractedFromDataType } from "../CustomFieldUtils";
import { NotSet } from "../../../common/misc/UIconstants";
import {
  UseEntityTableDefaultProps,
  UseEntityTableProps,
  useGenericVirtualizedTable,
  useGenericVirtualizedTableTabs,
} from "../../../common/tables/GenericVirtualizedTable/useGenericVirtualizedTable";
import { EntityTableProps } from "../../../common/entity/EntityInterfaces";
import { EntityTableDeleteButton } from "../../../common/entity/entityComponents/EntityTableDeleteButton";
import { EntityTableCloneButton } from "../../../common/entity/entityComponents/EntityTableCloneButton";
import { EntityTableEditButton } from "../../../common/entity/entityComponents/EntityTableEditButton";

export const switchCustomFieldsDefaultSortState = (
  sortState: CustomFieldFilters["orderBy"]
): SortState<CustomFieldFilters["orderBy"]> => {
  switch (sortState) {
    case "ID_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-id",
        orderBy: sortState,
      };
    case "ID_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-id",
        orderBy: sortState,
      };
    case "NAME_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-name",
        orderBy: sortState,
      };
    case "NAME_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-name",
        orderBy: sortState,
      };
    case "CREATED_ON_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-createdOn",
        orderBy: sortState,
      };
    case "CREATED_ON_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-createdOn",
        orderBy: sortState,
      };
    case "CREATED_BY_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-createdBy",
        orderBy: sortState,
      };
    case "CREATED_BY_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-createdBy",
        orderBy: sortState,
      };
    case "MODIFIED_ON_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-modifiedOn",
        orderBy: sortState,
      };
    case "MODIFIED_ON_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-modifiedOn",
        orderBy: sortState,
      };
    case "MODIFIED_BY_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-modifiedBy",
        orderBy: sortState,
      };
    case "MODIFIED_BY_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-modifiedBy",
        orderBy: sortState,
      };
    case "DATATYPE_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-dataType",
        orderBy: sortState,
      };
    case "DATATYPE_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-dataType",
        orderBy: sortState,
      };
    default:
      return {
        sortDirection: "ASC",
        headerId: "default-name",
        orderBy: sortState,
      };
  }
};
const defaultFilter: CustomFieldFilters = { orderBy: "NAME_ASC" };

export const useCustomFieldsTableDefaults = ({ fieldLabels }: UseEntityTableDefaultProps<"customFields">) => {
  const defaults: ColumnsSettings<CustomField> = useMemo(
    () => ({
      "default-id": { pos: 0, active: false, header: fieldLabels.id, property: "id" },
      "default-name": { pos: 1, active: true, header: fieldLabels.name, property: "name" },
      "default-dataType": { pos: 2, active: true, header: fieldLabels.dataType, property: "dataType" },
      "default-description": {
        pos: 3,
        active: true,
        header: fieldLabels.description,
        property: "description",
      },
      "default-createdOn": { pos: 4, active: true, header: fieldLabels.createdOn, property: "createdOn" },
      "default-createdBy": { pos: 5, active: true, header: fieldLabels.createdBy, property: "createdBy" },
      "default-modifiedOn": { pos: 6, active: true, header: fieldLabels.modifiedOn, property: "modifiedOn" },
      "default-modifiedBy": { pos: 7, active: true, header: fieldLabels.modifiedBy, property: "modifiedBy" },
    }),
    [fieldLabels]
  );

  const tabStoreDefaults: TableTabsDict<CustomField, CustomFieldFilters, CustomFieldFilterForm> = useMemo(
    () => ({
      default: {
        tabId: "default",
        type: "fixed",
        label: "All",
        title: "All",
        icon: "house",
        align: "left",
        xPos: 0,
        settings: {
          columnSettings: {},
          columnWidths: {},
          filters: defaultFilter,
          sidebarFilters: {},
        },
        forcedSettings: {
          columnSettings: {},
          columnWidths: {},
          filters: {},
          sidebarFilters: {},
        },
      },
      // trash: {
      //   tabId: "trash",
      //   type: "fixed",
      //   label: "Trash",
      //   title: "Trash",
      //   icon: "trash",
      //   align: "left",
      //   xPos: 1,
      //   settings: {
      //     columnSettings: {},
      //     columnWidths: {},
      //     filters: defaultFilter,
      //     sidebarFilters: {},
      //   },
      //   forcedSettings: {
      //     columnSettings: {},
      //     columnWidths: {},
      //     filters: { isSoftDeleted: true },
      //     sidebarFilters: {},
      //   },
      // },
    }),
    []
  );
  return { defaults, tabStoreDefaults };
};

export const useCustomFieldsTableColumns = ({
  entityConstants,
  fieldLabels,
  sort,
  setSort,
}: UseEntityTableProps<"customFields">) => {
  const columns = useMemo(() => {
    let headers: GenericVirtualizedTableCells<CustomField> = [
      {
        id: "default-id",
        Header: fieldLabels.id,
        accessor: (row) => <span style={{ color: "var(--gray-400)" }}>{row.id}</span>,
        width: 120,
        minWidth: 120,
        align: "center",
        sortingFn: (id) => {
          if (sort.headerId === id) {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "ID_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "ID_ASC" }));
            }
          } else {
            setSort({ headerId: id, sortDirection: "ASC", orderBy: "ID_ASC" });
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
      {
        Header: fieldLabels.name,
        id: "default-name",
        accessor: (row) => (
          <div className={`${styles.container_ellipsis} gap-5`}>
            <div className={`${styles.container_ellipsis}`}>
              <span className={styles.label_highlight}>
                <LucideIcon name={entityConstants.icon} color={"var(--primary)"} /> {row.name}
              </span>
            </div>
            {/* {row.isDeleted && (
              <div>
                <label className="label label-soft-warning" title={"Trashed"}>
                  <LucideIcon name="trash" />
                </label>
              </div>
            )} */}
          </div>
        ),
        minWidth: 150,
        width: 250,
        align: "left",
        sortingFn: (id) => {
          if (sort.headerId === id) {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "NAME_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "NAME_ASC" }));
            }
          } else {
            setSort({ headerId: id, sortDirection: "ASC", orderBy: "NAME_ASC" });
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
      {
        Header: fieldLabels.dataType,
        id: "default-dataType",
        accessor: (row) => {
          const _type = generateAbstractedFromDataType(row.dataType);
          return (
            <>
              {_type && (
                <div className="flex row-nowrap align-center gap-5">
                  {_type.abstractedDataType && (
                    <>
                      <AbstractedCustomFieldDataTypeIcon dataType={_type.abstractedDataType} />
                      {_type.abstractedDataType}
                      {_type.isMulti && (
                        <label className="label label-soft-info" style={{ margin: 0 }}>
                          Multi
                        </label>
                      )}
                    </>
                  )}
                </div>
              )}
            </>
          );
        },
        minWidth: 150,
        width: 250,
        align: "left",
        sortingFn: (id) => {
          if (sort.headerId === id) {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "DATATYPE_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "DATATYPE_ASC" }));
            }
          } else {
            setSort({ headerId: id, sortDirection: "ASC", orderBy: "DATATYPE_ASC" });
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
      {
        Header: fieldLabels.description,
        id: "default-description",
        accessor: (row) => (
          <div
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {row.description || NotSet}
          </div>
        ),
        minWidth: 150,
        width: 200,
        align: "left",
      },
      {
        id: "default-createdOn",
        Header: fieldLabels.createdOn,
        accessor: (row) => <DateTimeRenderer date={row.createdOn} includeElapsed={false} />,
        width: 200,
        align: "left",
        sortingFn: (id) => {
          if (sort.headerId === id) {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "CREATED_ON_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "CREATED_ON_ASC" }));
            }
          } else {
            setSort({ headerId: id, sortDirection: "ASC", orderBy: "CREATED_ON_ASC" });
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
      {
        id: "default-createdBy",
        Header: fieldLabels.createdBy,
        accessor: (row) => <GetPersons persons={row.createdBy} createLinks={false} />,
        width: 200,
        align: "left",
        sortingFn: (id) => {
          if (sort.headerId === id) {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "CREATED_BY_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "CREATED_BY_ASC" }));
            }
          } else {
            setSort({ headerId: id, sortDirection: "ASC", orderBy: "CREATED_BY_ASC" });
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
      {
        id: "default-modifiedOn",
        Header: fieldLabels.modifiedOn,
        accessor: (row) => <DateTimeRenderer date={row.modifiedOn} includeElapsed={false} />,
        width: 200,
        align: "left",
        sortingFn: (id) => {
          if (sort.headerId === id) {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "MODIFIED_ON_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "MODIFIED_ON_ASC" }));
            }
          } else {
            setSort({ headerId: id, sortDirection: "ASC", orderBy: "MODIFIED_ON_ASC" });
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
      {
        id: "default-modifiedBy",
        Header: fieldLabels.modifiedBy,
        accessor: (row) => <GetPersons persons={row.modifiedBy} createLinks={false} />,
        width: 200,
        align: "left",
        sortingFn: (id) => {
          if (sort.headerId === id) {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "MODIFIED_BY_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "MODIFIED_BY_ASC" }));
            }
          } else {
            setSort({ headerId: id, sortDirection: "ASC", orderBy: "MODIFIED_BY_ASC" });
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
    ];

    return headers;
  }, [entityConstants, fieldLabels, setSort, sort.headerId, sort.sortDirection]);
  return { columns };
};
export const CustomFieldsTable = ({
  entityApi,
  entityConstants,
  fieldLabels,
  permissions,
  routes,
}: EntityTableProps<"customFields">) => {
  const history = useHistory();
  const [showModal, setShowModal] = useState(false);
  const { bulkDeleteMutationAsync: deleteCustomField, isLoadingBulkDeleteMutation } = entityApi;

  const { defaults, tabStoreDefaults } = useCustomFieldsTableDefaults({ fieldLabels });

  const { selection, resultsCount, selectionPermissions, onCountChange, onSelectionChange, onSelectionPermissions } =
    useGenericVirtualizedTable<CustomField>();

  const {
    filters,
    sidebarFilters,
    forcedFilters,
    columnSetting,
    columnWidths,
    customTabs,
    fixedTabs,
    dispatchTabStore,
    currentTab,
    tabsLoading,
    tabsModified,
  } = useTabStore<CustomField, CustomFieldFilters, CustomFieldFilterForm>({
    resource: entityConstants.resource,
    defaults: tabStoreDefaults,
  });

  const { functionRef, onTabChange, sort, setSort, searchValue, setSearchValue } = useGenericVirtualizedTableTabs({
    tabsLoading,
    filters,
    switchSortState: switchCustomFieldsDefaultSortState,
    dispatchTabStore,
  });

  const { columns } = useCustomFieldsTableColumns({ entityConstants, fieldLabels, sort, setSort });

  const memoizedFilters = useMemo(
    () => ({ ...filters, ...forcedFilters, includeRelations: false }),
    [filters, forcedFilters]
  );

  return (
    <TableView>
      <TableView.Head>
        <TableView.Head.Label>
          <TableViewLabel entityConstants={entityConstants} resultsCount={resultsCount} />
        </TableView.Head.Label>
        <TableView.Head.Controls>
          <button
            className="btn btn-primary"
            title={`Add ${entityConstants.entitySingular}`}
            onClick={() => history.push(routes.getAddRoute)}
            disabled={!permissions.canCreate}
          >
            <LucideIcon name="plus" /> Add {entityConstants.entitySingular}
          </button>
        </TableView.Head.Controls>
      </TableView.Head>
      <TableView.Body>
        <TableView.Body.Sidebar>
          <CustomFieldsFilterBar
            initialValues={sidebarFilters}
            dispatchTabStore={dispatchTabStore}
            tabsLoading={tabsLoading}
            currentTab={currentTab}
          />
        </TableView.Body.Sidebar>
        <TableView.Body.Content>
          <EntityTableTabs
            currentTab={currentTab}
            onTabChange={onTabChange}
            fixedTabs={fixedTabs}
            filters={filters}
            sidebarFilters={sidebarFilters}
            columnSetting={columnSetting}
            columnWidths={columnWidths}
            customTabs={customTabs}
            dispatchTabStore={dispatchTabStore}
            tabsLoading={tabsLoading}
            tabsModified={tabsModified}
          />
          <EntityTable>
            <EntityTable.Controls
              style={{
                borderTop: "0px",
                borderRadius: "0px",
              }}
            >
              <EntityFilterIndicator<CustomField, CustomFieldFilters>
                filters={filters}
                excludeFilters={{}}
                translatorConsts={CustomFieldFiltersTranslator}
              />
              <SearchInput searchValue={searchValue} setSearchValue={setSearchValue} placeholder="Search" />
              <EntityTableEditButton
                entityConstants={entityConstants}
                routes={routes}
                permissions={permissions}
                selection={selection}
                selectionPermissions={selectionPermissions}
              />
              <MoreDropdown drop="right" btn="btn btn-ghost-secondary">
                <li>
                  <EntityTableCloneButton
                    entityConstants={entityConstants}
                    routes={routes}
                    permissions={permissions}
                    selection={selection}
                  />
                </li>
                <li>
                  <EntityTableDeleteButton
                    entityConstants={entityConstants}
                    permissions={permissions}
                    selection={selection}
                    selectionPermissions={selectionPermissions}
                    onClick={() => setShowModal(true)}
                  />
                </li>
              </MoreDropdown>
              <AlertModal
                type={"danger"}
                showModal={showModal}
                setShowModal={setShowModal}
                title={`${
                  selection.size === 0
                    ? `Select ${entityConstants.entitySingular} to delete`
                    : selection.size === 1
                    ? `Delete the selected ${entityConstants.entitySingular}?`
                    : `Delete the selected ${entityConstants.entityPlural}?`
                }`}
                description={`Proceeding will permanently delete the selected ${entityConstants.entityPlural}.`}
                proceedLabel={"Delete"}
                onProceed={async () => {
                  await deleteCustomField({
                    ids: Array.from(selection),
                    goBackOnSuccess: false,
                    showToast: false,
                    entityName: entityConstants.resource,
                    params: { deletePermanently: true },
                  }).catch((e) => {});
                  functionRef.current?.resetSelection();
                  setShowModal(false);
                }}
                loading={isLoadingBulkDeleteMutation}
              />
            </EntityTable.Controls>
            <EntityTable.Body<CustomField, CustomFieldFilters>
              functionRef={functionRef}
              entityConstants={entityConstants}
              filters={memoizedFilters}
              columns={columns}
              columnSelect
              columnSetting={columnSetting}
              columnWidths={columnWidths}
              defaultColumnSettings={defaults}
              dispatchTabStore={dispatchTabStore}
              setResultsCount={onCountChange}
              onSelectionChange={onSelectionChange}
              onSelectionPermissions={onSelectionPermissions}
              showPermissionColumn
              loading={tabsLoading}
            />
          </EntityTable>
        </TableView.Body.Content>
      </TableView.Body>
    </TableView>
  );
};
