import produce from 'immer';
import { useEffect } from 'react';

import { TrackStacking } from '../../TrackTools/TrackStacking';
import styles from './StackingTool.module.css';
import { TrackToolType } from './ViewerNavigation';

export const StackingTool = ({
  setOffset,
  getTranslateScale,
  setViewerMode,
  viewerMode,
  setMinHeight,
  setMinWidth,
}: TrackToolType) => {
  // const activeTracks = useTranslatedField(
  //   tracks,
  //   (tracks: Record<string, Track>) =>
  //     Object.values(tracks)
  //       .filter((track) => track.settings.active)
  //       .map((track) => track.id),
  //   []
  // );

  useEffect(() => {
    if (setMinHeight) setMinHeight(170);
    if (setMinWidth) setMinWidth(360);

    setViewerMode(
      produce(viewerMode, (next) => {
        next.stacked = true;
      })
    );

    return () => {
      setViewerMode(
        produce(viewerMode, (next) => {
          next.stacked = false;
        })
      );
    };
  }, []);

  // ##### This is not necessary whe stacking is called in a regular way ??? #####
  // useEffect(() => {
  //   if (selectedTracks.length > 0 && !viewerMode.stacked) {
  //     setViewerMode(
  //       produce(viewerMode, (next) => {
  //         next.stacked = true;
  //       })
  //     );
  //   }
  // }, [selectedTracks]);
  // ^##### This is not necessary whe stacking is called regulary ??? #####

  return (
    <div className={styles.viewerButtonColumn} style={{ flexGrow: 1 }}>
      <TrackStacking setOffset={setOffset} getTranslateScale={getTranslateScale} />
    </div>
  );
};
