import { throwServerError } from "../common/helperfunctions/ApiError";
import { Features, PermissionStrings, Session } from "./ApiTypes";
import { Person } from "./Person";

export interface SessionDto {
  validUntil: string;
  userId: number;
  loginDisabled: boolean;
  permissions: PermissionStrings[];
  sessionId?: string;
  apiKeyId?: number;
  readonly: boolean;
}
export interface SessionFullModel extends SessionDto {
  person: Person;
  features: Features;
  noAccount: boolean;
  isActualSession: boolean;
}

export interface LoginRequest {
  username: string;
  password: string;
  otp: string;
}

export interface LoginResponse {
  success: boolean;
  otpRequired: boolean;
  session: SessionDto;
}

interface SessionRequest {
  sessionId: string;
}

interface SessionRequestJwt {
  token: string;
}

interface RegistrationRequest {
  token: string;
}

// License
export interface LicenseModelValidated {
  isValid: boolean;
  edition: string;
  inputGroups: number;
  groups: number;
  expirationDate: string;
}

export const login = async (group: string, body: LoginRequest, signal?: AbortSignal): Promise<LoginResponse> => {
  const response = await fetch(`${window.location.protocol}//${window.location.host}/${group}/api/0.1/login`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
    signal: signal,
  });
  if (!response.ok) await throwServerError(response);
  return await response.json();
};

interface Group {
  name: string;
  edition: string;
}

export const availableGroups = async (signal?: AbortSignal) => {
  const response = await fetch(`${window.location.protocol}//${window.location.host}/groups/`, {
    headers: {
      "Content-Type": "application/json",
    },
    signal: signal,
  });
  if (!response.ok) await throwServerError(response);
  return (await response.json()) as Group[];
};

export const getSessionById = async (group: string, body: SessionRequest, signal?: AbortSignal) => {
  const response = await fetch(`${window.location.protocol}//${window.location.host}/${group}/api/0.1/session/by_id`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
    signal: signal,
  });
  if (!response.ok) await throwServerError(response);

  const res: SessionFullModel = await response.json();
  const permissions = Object.fromEntries(res.permissions.map((p: string) => [p, true]));
  return {
    ...res,
    permissions: permissions,
  } as Session;
};

export const getSessionByJWT = async (group: string, body: SessionRequestJwt, signal?: AbortSignal) => {
  const response = await fetch(`${window.location.protocol}//${window.location.host}/${group}/api/0.1/session/by_jwt`, {
    method: "POST",
    body: JSON.stringify(body),
    headers: {
      "Content-Type": "application/json",
    },
    signal: signal,
  });
  // console.log("getSessionByJWT", response.ok, response.status, response.statusText);
  if (!response.ok) await throwServerError(response);
  const res: SessionFullModel = await response.json();
  const permissions = res.permissions ? Object.fromEntries(res.permissions.map((p: string) => [p, true])) : {};
  var session = {
    ...res,
    permissions: permissions,
    type: "jwt",
  } as Session;
  // console.log("JWT session", session);
  return session;
};

export const registerByJWT = async (group: string, body: RegistrationRequest, signal?: AbortSignal) => {
  const response = await fetch(`${window.location.origin}/${group}/api/0.1/register`, {
    method: "POST",
    body: JSON.stringify(body),
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${body.token}`,
    },
    signal: signal,
  });
  // console.log("JWT register response", response);
  if (!response.ok) await throwServerError(response);
  return Promise.resolve();
};

export interface AuthConfig {
  oidcEnabled: boolean;
  oidcAuthority: string;
  oidcClientId: string;
}
export const fetchAuthenticationConfig = async (group: string, signal?: AbortSignal) => {
  const response = await fetch(`${window.location.protocol}//${window.location.host}/${group}/api/0.1/auth_config`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    signal: signal,
  });
  if (!response.ok) await throwServerError(response);
  const authConfig = await response.json();
  return authConfig as AuthConfig;
};
