import React from "react";
import { LucideIcon } from "../../common/icon/LucideIcon";

export const ForgotPassword = () => {
  return (
    <div>
      <LucideIcon name="construction" style={{ width: "90vw", height: "90vh" }} />
    </div>
  );
};
