import { DeepMap, FieldError } from "react-hook-form";
import { FormFieldError, FormFieldLayout } from "./FormFieldLayouts";
import styles from "./formfields.module.css";
import { CSSProperties } from "react";

export interface TextareaFormFieldProps {
  id: string;
  label: string;
  errors?: DeepMap<any, FieldError>;
  register?: any;
  required?: boolean;
  autoFocus?: boolean;
  readOnly?: boolean;
  placeholder?: string;
  defaultValue?: string | number;
  horizontal?: boolean;
  uncontained?: boolean;
  onBlur?: () => void;
  style?: CSSProperties;
  buttons?: React.ReactNode;
  wrapperContainerstyle?: CSSProperties;
  rows?: number;
}

export const TextareaFormField = ({
  id,
  label,
  errors,
  register,
  required,
  autoFocus,
  readOnly,
  placeholder = "Insert text...",
  defaultValue,
  horizontal = true,
  uncontained = false,
  onBlur,
  style,
  buttons,
  wrapperContainerstyle,
  rows = 4,
}: TextareaFormFieldProps) => {
  const { onBlur: onBlurRegister } = register?.(id) ?? { onBlur: undefined };
  const fieldPath = id.split(".");
  let error: any = undefined;
  for (const path of fieldPath) {
    if (!error) error = errors?.[path];
    else error = error?.[path];
  }
  if (uncontained) {
    return (
      <div className={`flex`} style={{ width: "100%", height: "100%", ...wrapperContainerstyle }}>
        <textarea
          className={`${error ? "border-danger form-control" : "form-control"} ${required ? "required" : ""}`}
          required={required}
          autoFocus={autoFocus}
          readOnly={readOnly}
          placeholder={placeholder}
          defaultValue={defaultValue}
          style={{ ...(readOnly && { cursor: "not-allowed" }), minHeight: "100%", resize: "none", ...style }}
          {...register(id)}
          onBlur={onBlur ?? onBlurRegister}
          autoComplete="off"
          rows={rows}
        />
        {buttons ? <div className={styles.buttons}>{buttons}</div> : null}
        <FormFieldError id={id} errors={errors} />
      </div>
    );
  } else {
    return (
      <FormFieldLayout id={id} label={label} required={required} horizontal={horizontal}>
        <div className={styles.container} style={wrapperContainerstyle}>
          <textarea
            className={`${error ? "border-danger form-control" : "form-control"}`}
            required={required}
            autoFocus={autoFocus}
            readOnly={readOnly}
            placeholder={placeholder}
            defaultValue={defaultValue}
            style={{ ...(readOnly && { cursor: "not-allowed" }), resize: "none", ...style }}
            {...register(id)}
            onBlur={onBlur ?? onBlurRegister}
            rows={rows}
          />
          {buttons ? <div className={styles.buttons}>{buttons}</div> : null}
        </div>

        <FormFieldError id={id} errors={errors} />
      </FormFieldLayout>
    );
  }
};
