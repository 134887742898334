import {
  Experiment,
  ExperimentFilters,
  ExperimentFiltersTranslator,
  ExperimentSortingOptionsConsts,
  experimentsConstants,
} from "../../../api/Experiments";
import { DateTimeRenderer } from "../../datetime/DateTimeFormatter";
import { NotSet } from "../../misc/UIconstants";
import { EntitySidebarTile, EntitySidebarTileProps } from "./EntitySidebarTile/EntitySidebarTile";

export const ExperimentsTile = (props: EntitySidebarTileProps<Experiment, ExperimentFilters>) => (
  <EntitySidebarTile<Experiment, ExperimentFilters>
    {...props}
    entityConstants={experimentsConstants}
    orderByOptions={ExperimentSortingOptionsConsts}
    translatorConsts={ExperimentFiltersTranslator}
    defaultFilters={{ orderBy: "NAME_DESC", ...props.defaultFilters }}
    rowLabel={(e) => <>{e.name}</>}
    rowDate={(e) => <DateTimeRenderer date={e.modifiedOn} includeDate includeTime={false} includeElapsed={false} />}
    rowInfo={(e) => <>{e.owner?.name || NotSet}</>}
  />
);
// interface Props {
//   foldable?: boolean;
//   title?: JSX.Element | string;
//   filter?: ExperimentFilters;
//   isFolded?: boolean;
// }

// const PAGE_SIZE = 5;

// export const ExperimentsTile = ({ foldable = true, title = "Experiments", filter = {}, isFolded = false }: Props) => {
//   const { route } = useContext(SessionContext);
//   const history = useHistory();
//   const [searchValue, setSearchValue] = useState("");
//   const debouncedSearchValue = useDebouncedValue(searchValue, 500);

//   const { data, error, fetchNextPage, hasNextPage, isFetching, status, fetchStatus, count } = useInfiniteListEntity<
//     Experiment,
//     ExperimentFilters
//   >(
//     "experiments",
//     {
//       ...filter,
//       page: 1,
//       pageSize: PAGE_SIZE,
//       includeCount: true,
//
//       includeContent: false,
//       searchTerm: debouncedSearchValue,
//       orderBy: "NAME_ASC",
//     },
//     { enabled: true },
//     "post"
//   );

//   const experiments = useMemo(() => {
//     return data?.pages.map((d) => d.results).flat() || [];
//   }, [data]);

//   const handleRowClick = useCallback((experiment: Experiment) => {}, []);

//   const fetchNext = useCallback(() => {
//     if (hasNextPage) {
//       fetchNextPage();
//     }
//   }, [fetchNextPage, hasNextPage]);

//   const loadMoreCallback = useCallback(() => {}, []);
//   const itemCount = hasNextPage ? experiments.length + 1 : experiments.length;
//   const loadMoreItems = isFetching ? loadMoreCallback : fetchNext;
//   const isItemLoaded = useCallback(
//     (index: number) => !hasNextPage || index < experiments.length,
//     [hasNextPage, experiments.length]
//   );
//   const Item = ({ index, style }: { index: number; style: CSSProperties }) => {
//     const current_element = experiments[index];
//     if (!isItemLoaded(index)) {
//       return (
//         <div style={{ ...style, paddingBottom: "5px" }} key={index}>
//           <span className="skeleton-block btn-lg" style={{ height: 51 }} />
//         </div>
//       );
//     } else {
//       return (
//         <div style={{ ...style, paddingBottom: "5px" }} key={index}>
//           <Link
//             key={index}
//             to={route(getDetailLink("experiments", current_element.id))}
//             style={{ textDecoration: "none" }}
//             onClick={() => handleRowClick(current_element)}
//           >
//             <ContentRow
//               key={index}
//               icon={
//                 <div className={styles.icon}>
//                   <FeatherIcon name="user" />
//                 </div>
//               }
//               label={current_element.name}
//               date={
//                 <DateTimeRenderer
//                   date={current_element.modifiedOn}
//                   includeDate
//                   includeTime={false}
//                   includeElapsed={false}
//                 />
//               }
//               id={<>Entry-ID: {current_element.id}</>}
//               info={current_element.owner?.name || NotSet}
//               controls={<></>}
//             />
//           </Link>
//         </div>
//       );
//     }
//   };

//   return (
//     <>
//       <Tile
//         isFolded={count === 0 && !debouncedSearchValue ? true : isFolded}
//         foldable={count === 0 && !debouncedSearchValue ? false : foldable}
//         disabled={count === 0 && !debouncedSearchValue}
//       >
//         <Tile.Head
//           title={
//             <div className="flex row-nowrap align-center gap-5">
//               <div>{title}</div>
//               {count !== undefined && <span className="badge">{count}</span>}
//               <button
//                 onClick={(e) => {
//                   e.stopPropagation();
//                   e.preventDefault();
//                   history.push(route(getIndexRoute("experiments")));
//                 }}
//                 data-toggle="tooltip"
//                 title="Show list in fullscreen"
//                 className="btn btn-ghost-secondary"
//               >
//                 <FeatherIcon name="table" />
//               </button>
//             </div>
//           }
//         >
//           <Tile.Head.Controls>
//             <Tile.Head.Controls.Unfolded>
//               <>
//                 <SearchInput searchValue={searchValue} setSearchValue={setSearchValue} minimized={false} />
//               </>
//             </Tile.Head.Controls.Unfolded>
//             {/* <button
//               onClick={(e) => {
//                 e.stopPropagation();
//                 e.preventDefault();
//                 history.push(route(getAddRoute("experiments")));
//               }}
//               data-toggle="tooltip"
//               title="Create new entry"
//               className="btn btn-ghost-primary btn-sm"
//             >
//               <FeatherIcon name="plus" />
//             </button> */}
//           </Tile.Head.Controls>
//         </Tile.Head>
//         <Tile.Body>
//           <LoadingWrapper status={status} fetchStatus={fetchStatus} error={error} type="skeleton-rows">
//             <div className={isFetching ? `${styles.container} ${styles.container_loading}` : styles.container}>
//               {experiments && experiments.length > 0 ? (
//                 <InfiniteLoader isItemLoaded={isItemLoaded} itemCount={itemCount} loadMoreItems={loadMoreItems as any}>
//                   {({ onItemsRendered, ref }) => (
//                     <FixedSizeList
//                       itemCount={itemCount}
//                       onItemsRendered={onItemsRendered}
//                       ref={ref}
//                       width="100%"
//                       height={experiments.length > PAGE_SIZE ? PAGE_SIZE * 59 : experiments.length * 59}
//                       itemSize={59}
//                     >
//                       {Item}
//                     </FixedSizeList>
//                   )}
//                 </InfiniteLoader>
//               ) : (
//                 <>
//                   {isFetching ? (
//                     <Skeleton type="rows" />
//                   ) : (
//                     <>
//                       {debouncedSearchValue ? (
//                         <Alert type="light" message={`No results for: "${debouncedSearchValue}"`} fit centered />
//                       ) : (
//                         <Alert type="light" message="No entries" fit centered />
//                       )}
//                     </>
//                   )}
//                 </>
//               )}
//             </div>
//           </LoadingWrapper>
//         </Tile.Body>
//       </Tile>
//     </>
//   );
// };
