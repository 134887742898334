import { EntityAddForm, EntityAddFormPage } from "../common/entity/EntityAddForm";
import { EntityCloneForm, EntityCloneFormPage } from "../common/entity/EntityCloneForm";
import { cloneElement, useContext } from "react";
import { SessionContext } from "../common/contexts/SessionContext";
import {
  EntityCloneFormProps,
  EntityDetailViewProps,
  EntityEditFormProps,
  EntityFormProps,
} from "../common/entity/EntityInterfaces";
import { EntityEditForm, EntityEditFormPage } from "../common/entity/EntityEditForm";
import { EntityDetailPage, EntityDetailView } from "../common/entity/EntityDetailPage";
import { InventoryItem, InventoryItemFilters, inventoriesConstants, inventoryItemsConstants } from "../api/Inventories";
import { InventoriesDetail } from "./InventoryItemsDetail";
import { InventoryItemForm } from "./InventoryItemForm";
import { Redirect, useLocation, useParams } from "react-router-dom";
import { getDetailLink } from "../main/Routing";
import { useEntityDetail } from "../api/BaseEntityApi";
import { CustomType, CustomTypeFilters, customTypeConstants } from "../api/CustomTypes";
import { InventoriesTable } from "./Table/InventoriesTable";
import { EntityTablePage } from "../common/entity/EntityTablePage";
import { InventoryItemsTableWrapper } from "./Table/InventoryItemsTable";
import { InventoryItemTree } from "./InventoriesTree";
import { EntityByEntityTypeId, getEntityConstants } from "../api/GenericConstants";
import { useEntityApi } from "../api/useEntityApi";
import { useEntityPermissions } from "../common/permissions/useEntityPermissions";
import { useEntityRoutes } from "../common/routes/useEntityRoutes";
import { PageNotAllowed } from "../main/common/PageNotAllowed/PageNotAllowed";

const InventoryItemFormWrapper = ({
  props,
  children,
}: {
  props: EntityFormProps<"inventoryItems">;
  children: JSX.Element;
}) => {
  const location = useLocation();
  const { customTypeId } = useParams<{ customTypeId: string }>();
  const queryParams = new URLSearchParams(location.search);
  const customTypeIdUrl = queryParams.get("type");

  const { data: customType } = useEntityDetail<CustomType, CustomTypeFilters>(
    customTypeConstants.resource,
    +(customTypeId ?? customTypeIdUrl),
    undefined,
    { enabled: !!customTypeId || !!customTypeIdUrl }
  );

  const parentItemIdUrl = queryParams.get("parent");
  const { data: parent } = useEntityDetail<InventoryItem, InventoryItemFilters>(
    inventoryItemsConstants.resource,
    +(parentItemIdUrl ?? 0),
    undefined,
    { enabled: !!parentItemIdUrl }
  );

  return cloneElement(children, { ...props, initialValues: { customType, parent, ...props.initialValues } });
};

// Add
export const InventoryItemAddForm = () => {
  return (
    <EntityAddForm entityTypeId={"inventoryItems"}>
      {(props) => (
        <InventoryItemFormWrapper props={props}>
          <InventoryItemForm {...props} initialValues={{ ...props.initialValues }} />
        </InventoryItemFormWrapper>
      )}
    </EntityAddForm>
  );
};

export const InventoryItemAddFormPage = () => {
  return (
    <EntityAddFormPage>
      <InventoryItemAddForm />
    </EntityAddFormPage>
  );
};

// Clone
export const InventoryItemCloneForm = ({ id }: EntityCloneFormProps<"inventoryItems">) => {
  return (
    <EntityCloneForm id={id} entityTypeId={"inventoryItems"}>
      {(props) => (
        <InventoryItemFormWrapper props={props}>
          <InventoryItemForm {...props} />
        </InventoryItemFormWrapper>
      )}
    </EntityCloneForm>
  );
};

export const InventoryItemCloneFormPage = () => {
  return (
    <EntityCloneFormPage<"inventoryItems"> isIntId>{(id) => <InventoryItemCloneForm id={id} />}</EntityCloneFormPage>
  );
};

// Edit
export const InventoryItemEditForm = ({ id }: EntityEditFormProps<"inventoryItems">) => {
  return (
    <EntityEditForm id={id} entityTypeId={"inventoryItems"}>
      {(props) => (
        <InventoryItemFormWrapper props={props}>
          <InventoryItemForm {...props} />
        </InventoryItemFormWrapper>
      )}
    </EntityEditForm>
  );
};

export const InventoryItemEditFormPage = () => {
  return <EntityEditFormPage<"inventoryItems"> isIntId>{(id) => <InventoryItemEditForm id={id} />}</EntityEditFormPage>;
};

// Detail
export const InventoryItemDetailView = ({ id }: EntityDetailViewProps<"inventoryItems">) => {
  return (
    <EntityDetailView id={id} entityTypeId={"inventoryItems"}>
      {(props) => <InventoriesDetail {...props} />}
    </EntityDetailView>
  );
};

export interface InventoryItemTreePageProps {
  customTypeIdOverride?: CustomType["id"];
  entity?: InventoryItem;
}
export const InventoryItemTreePage = ({ customTypeIdOverride, entity }: InventoryItemTreePageProps) => {
  const { entityConstants, fieldLabels } = getEntityConstants("inventoryItems");

  const permissions = useEntityPermissions({ entityTypeId: "inventoryItems" });
  const { canRead } = permissions;
  const entityApi = useEntityApi<EntityByEntityTypeId<"inventoryItems">>(entityConstants.resource);
  const routes = useEntityRoutes({ entityConstants });

  if (!canRead) return <PageNotAllowed />;
  return (
    <InventoryItemTree
      entityApi={entityApi}
      entityConstants={entityConstants}
      fieldLabels={fieldLabels}
      permissions={permissions}
      routes={routes}
      customTypeIdOverride={customTypeIdOverride}
      entity={entity}
    />
  );
};

// Wrapper for hierarchy item view with redirect
export const InventoryItemDetailsRedirect = () => {
  return (
    <EntityDetailPage<"inventoryItems"> isIntId>
      {(id) => (
        <EntityDetailView id={id} entityTypeId={"inventoryItems"}>
          {(props) => <InventoryItemTreePage entity={props.entity} />}
        </EntityDetailView>
      )}
    </EntityDetailPage>
  );
};

export const InventoryItemDetailsPage = () => {
  return <EntityDetailPage<"inventoryItems"> isIntId>{(id) => <InventoryItemDetailView id={id} />}</EntityDetailPage>;
};

export const InventoryDetailsRedirect = () => {
  const { route } = useContext(SessionContext);
  return (
    <EntityDetailPage<"inventoryItems"> isIntId>
      {(id) => <Redirect to={`${route(getDetailLink(inventoriesConstants.frontendIndexRoute, id))}/items`} />}
    </EntityDetailPage>
  );
};

export const InventoriesTablePage = () => {
  return <EntityTablePage entityTypeId={"inventories"}>{(props) => <InventoriesTable {...props} />}</EntityTablePage>;
};

export const InventoryItemsTablePage = () => {
  return (
    <EntityTablePage entityTypeId={"inventoryItems"}>
      {(props) => <InventoryItemsTableWrapper {...props} />}
    </EntityTablePage>
  );
};
