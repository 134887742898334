import * as d3 from "d3";

import { generateUid } from "../tools/UID/UID";
import { Color } from "../ViewerLayout/ViewerLayoutTypes";
import { initColor } from "../ViewerLayout/ViewerLayoutUtils";

export class GenerateTrackColor {
  colorScales: Record<string, Color>;
  colorCounter: number;
  colorScaleCounter: number;

  constructor() {
    this.colorScales = {};
    this.colorCounter = 0;
    this.colorScaleCounter = 0;
    this.createColorScales();
  }

  reset() {
    // this.colorScales = {};
    this.colorCounter = 0;
    this.colorScaleCounter = 0;
  }

  getNextColor(color?: Color, type?: "single" | "gradient") {
    let newColor: Color = initColor({ name: ">> Empty <<" } as Color);
    // console.log("## color user predefined", color);
    if (color !== undefined) {
      // console.log("## color user predefined", color);
      if (color.colors.length === 1 && color.colors[0].color in this.colorScales) {
        const name = color.colors[0].color;
        newColor = initColor(this.colorScales[name]);
        if (color.reverse) {
          newColor.colors = newColor.colors.map((c, i) => {
            return {
              ...c,
              color: newColor.colors[newColor.colors.length - i - 1].color,
              // TODO: revers should be implemented differently: eg. reverse single colors and offsets and initColor again
              name: (newColor.name ?? "") + "Reverse",
              generate: newColor.generate
                ? (v: number) => (newColor.generate as (offset: number) => string)(1 - v)
                : undefined,
            };
          });
        }
        // console.log("known color", newColor);
      } else {
        newColor = initColor(color);
        // console.log("defined color", newColor);
      }
    }

    if (!newColor.colors || newColor.colors.length < 1) {
      //   // console.log("## automatic color");
      if (type === "single") {
        const name = this.colorNameOrder[this.colorCounter % this.colorNameOrder.length];
        newColor = initColor(this.colorScales[name]);
        this.colorCounter++;
      } else if (type === "gradient") {
        const name = this.colorScaleOrder[this.colorScaleCounter % this.colorScaleOrder.length];
        newColor = initColor(this.colorScales[name]);
        this.colorScaleCounter++;
      }
      // console.log("undefined color", type, newColor);
    }

    return newColor;
  }

  colorScaleOrder = ["PuOr", "PiYG", "RdBu", "PRGn"];

  colorNameOrder = [
    "Blue",
    "Orange",
    "Purple",
    "Cyan",
    "Magenta",
    "Lime",
    "Pink",
    "Teal",
    "Red",
    "Green",
    "Yellow",
    "Lavender",
    "Brown",
    // "Beige",
    "Maroon",
    "Mint",
    "Olive",
    "Apricot",
    "Navy",
    "Grey",
  ];

  colors = [
    "#4363d8", // Blue
    "#f58231", // Orange
    "#911eb4", // Purple
    "#42d4f4", // Cyan
    "#f032e6", // Magenta
    "#bfef45", // Lime
    "#fabebe", // Pink
    "#469990", // Teal
    "#e6194B", // Red
    "#3cb44b", // Green
    "#ffe119", // Yellow
    "#e6beff", // Lavender
    "#9A6324", // Brown
    "#fffac8", // Beige
    "#800000", // Maroon
    "#aaffc3", // Mint
    "#808000", // Olive
    "#ffd8b1", // Apricot
    "#000075", // Navy
    "#a9a9a9", // Grey
  ];

  createColorScales() {
    const colorScaleNames = {
      BrBG: 3,
      PRGn: 3,
      PiYG: 3,
      PuOr: 3,
      RdBu: 3,
      RdGy: 3,
      RdYlBu: 5,
      RdYlGn: 5,
      Spectral: 11,
      Blues: 2,
      Greens: 2,
      Greys: 2,
      Oranges: 2,
      Purples: 2,
      Reds: 2,
      Turbo: 11,
      Viridis: 11,
      Inferno: 11,
      Magma: 11,
      Plasma: 11,
      Cividis: 11,
      Warm: 11,
      Cool: 11,
      CubehelixDefault: 11,
      BuGn: 3,
      BuPu: 3,
      GnBu: 3,
      OrRd: 3,
      PuBuGn: 4,
      PuBu: 3,
      PuRd: 3,
      RdPu: 3,
      YlGnBu: 5,
      YlGn: 5,
      YlOrBr: 5,
      YlOrRd: 5,
      Rainbow: 11,
      Sinebow: 11,
    };

    const colorNames = {
      Blue: "#4363d8",
      Orange: "#f58231",
      Purple: "#911eb4",
      Cyan: "#42d4f4",
      Magenta: "#f032e6",
      Lime: "#a1c900", // orig: "#bfef45",
      Pink: "#fa7d7d", // orig: "#fabebe",
      Teal: "#469990",
      Red: "#e6194B",
      Green: "#3cb44b",
      Yellow: "#e6c802", // orig: "#ffe119",
      Lavender: "#c874ff", // orig: "#e6beff",
      Brown: "#9A6324",
      Beige: "#fffac8",
      Maroon: "#800000",
      Mint: "#5ee075", // orig: "#aaffc3",
      Olive: "#808000",
      Apricot: "#fcbc65", // orig: "#ffd8b1",
      Navy: "#000075",
      Grey: "#a9a9a9",
    };

    const colors: Record<string, Color> = {};
    Object.entries(colorScaleNames).forEach(([name, number]) => {
      // const list = d3["scheme" + name];
      const colorScale = (d3 as any)["interpolate" + name];
      const color = d3.scaleSequential(colorScale).domain([0, 1]) as any as (offset: number) => string;

      let step = 1 / (number - 1);

      const list = [0];
      for (let offset = step; offset < 1; offset += step) {
        // list.push(color(scale));
        list.push(offset);
      }
      list.push(1);
      // console.log(
      //   "color",
      //   list.map((offset) => color(offset))
      // );
      colors[name] = {
        colors: list.map((offset) => {
          return { color: color(offset), offset: offset };
        }),
        name: name,
        generate: color,
        id: generateUid(),
      };

      // console.log(">>>", name, list);
    });

    Object.entries(colorNames).forEach(([name, value]) => {
      colors[name] = {
        colors: [{ color: value, offset: 0 }],
        name: name,
        generate: (v) => value,
        id: generateUid(),
      };
    });

    this.colorScales = colors;
    // console.log(">>>", colors);
  }
}
