import { useContext, useState } from "react";
import { useELNRoutes } from "../ELNRouter/useELNRoutes";
import { RecentActivities } from "./RecentActivities/RecentActivities";
import { ELNLayoutContainer } from "../common/ELNLayoutContainer/ELNLayoutContainer";
import { QuickAddWrapper } from "../../common/forms/QuickAdd/common/QuickAddWrapper";
import { LabNotebook } from "../types/LabNotebook";
import { QuickAddLabNotebookForm } from "../../common/forms/QuickAdd/forms/QuickAddLabNotebook";
import { useQueryClient } from "@tanstack/react-query";
import { LAB_NOTEBOOK_TOC_QUERY_KEY } from "../common/tiles/LabNotebookTocTile/LabNotebookTocTile";
import { SessionContext } from "../../common/contexts/SessionContext";
import { getIndexRoute } from "../../main/Routing";
import { attachmentsConstants } from "../../api/Attachments";

export const Dashboard = () => {
  const { getELNTemplateRoute, setELNRoute } = useELNRoutes();
  const [showCreateLabNotebookModal, setShowCreateLabNotebookModal] = useState<boolean>(false);
  const queryClient = useQueryClient();
  const { route } = useContext(SessionContext);

  return (
    <>
      <ELNLayoutContainer
        headerParams={{
          title: [{ label: "Notebook", icon: "book", iconColor: "var(--primary)" }],
          description: "Overview",
          controls: [
            {
              label: "Attachments",
              icon: "archive",
              color: "soft-primary",
              linkTo: route(getIndexRoute(attachmentsConstants.frontendIndexRoute)),
            },
            {
              label: "Templates",
              icon: "copy",
              color: "soft-primary",
              linkTo: getELNTemplateRoute(),
            },
            {
              label: "New notebook",
              icon: "book",
              type: "create",
              color: "primary",
              onClick: () => setShowCreateLabNotebookModal(true),
            },
          ],
        }}
      >
        <RecentActivities />
      </ELNLayoutContainer>

      {showCreateLabNotebookModal && (
        <QuickAddWrapper<LabNotebook>
          showModal={showCreateLabNotebookModal}
          setShowModal={setShowCreateLabNotebookModal}
          onCreate={(newLabNotebook) => {
            if (newLabNotebook && newLabNotebook.id > 0) {
              queryClient.invalidateQueries([LAB_NOTEBOOK_TOC_QUERY_KEY]);
              queryClient.invalidateQueries(["lab_notebooks"]);
              queryClient.invalidateQueries(["lab_notebook_experiments"]);
              queryClient.invalidateQueries(["lab_notebook_entries"]);
              setELNRoute({ labNotebookId: newLabNotebook.id });
            }
          }}
        >
          {(props) => <QuickAddLabNotebookForm {...props} initialValues={{ status: "ACTIVE" }} />}
        </QuickAddWrapper>
      )}
    </>
  );
};
