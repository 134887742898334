import { useCallback, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { Range } from "react-range";

import { SliderThumbBlock, SliderTrack } from "../SpectrumViewer/ViewerNavigation/SliderElements";
import { offsetType } from "../ViewerLayout/ViewerTypes";
import { IconBox } from "../ViewerUIElements/IconBox";
import { useInputDebounce } from "../ViewerUIElements/InputDebounce";
import styles from "./TrackStacking.module.css";

const copyStateType = (state: stateType): stateType => {
  return {
    translateX: state.translateX,
    translateY: state.translateY,
    xPadding: state.xPadding,
    yPadding: state.yPadding,
    graphAngle: state.graphAngle,
    angle: state.angle,
    radius: state.radius,
    offset: state.offset,
  };
};

type stateType = {
  xPadding: number;
  yPadding: number;
  translateX: number;
  translateY: number;
  graphAngle: number;
  angle: number;
  radius: number;
  offset: number;
};

const minPadding = 0;
const maxPadding = 50;
const maxDiagonalPadding = Math.sqrt(2 * maxPadding ** 2);

const calculateAngleFromPadding = (translate: stateType): [number, number] => {
  const r = Math.sqrt(translate.xPadding ** 2 + translate.yPadding ** 2);

  return [r, (Math.atan2(translate.yPadding, translate.xPadding) * 180) / Math.PI];
};

const calculatePaddingFromAngle = (translate: stateType): [number, number] => {
  // const a = ((90 - translate.angle) * Math.PI) / 180;
  const a = (translate.angle * Math.PI) / 180;
  const r = translate.radius;
  translate.xPadding = r * Math.cos(a);
  translate.yPadding = r * Math.sin(a);

  return [r * Math.cos(a), r * Math.sin(a)];
};

export const TrackStacking = ({
  // offset,
  setOffset,
  getTranslateScale,
}: {
  // offset: offsetType;
  setOffset: (offset: offsetType) => void;
  getTranslateScale: () => offsetType;
}) => {
  const [xPadding, setXPadding] = useState<number[]>([0]);
  const [yPadding, setYPadding] = useState<number[]>([0]);
  const [angle, setAngle] = useState<number[]>([0]);
  const [radius, setRadius] = useState<number[]>([0]);
  const [locked, setLocked] = useState<boolean>(false);
  const [translate, setTranslate] = useState<stateType>({
    translateX: 0,
    translateY: 0,
    graphAngle: 0,
    xPadding: 0,
    yPadding: 0,
    angle: 0,
    radius: 0,
    offset: 0,
  });

  // useEffect(() => {
  //   const scale = getTranslateScale?.() || { translateX: 0, translateY: 0, stepX: 0, stepY: 0 };
  //   setXPadding([(scale.translateX || 0) / (scale.stepX || 0)]);
  //   setYPadding([(scale.translateY || 0) / (scale.stepY || 0)]);
  // }, []);

  const debouncedTranslate = useInputDebounce<stateType>(translate, 100);

  const calculateTranslation = useCallback(
    (translate: stateType): { translateX: number; translateY: number; graphAngle: number; offset: number } => {
      const scale = getTranslateScale?.() || { stepX: 0, stepY: 0 };
      scale.directionX = Math.sign(scale?.stepX || 1);
      scale.directionY = Math.sign(scale?.stepY || 1);
      const translateX = translate.xPadding * (scale.stepX || 0);
      const translateY = translate.yPadding * (scale.stepY || 0);
      // console.log(">>", translateX, translateY, (Math.atan2(translateY, translateX) * 180) / Math.PI);

      return {
        translateX: translateX,
        translateY: translateY,
        offset: Math.sqrt(translateX ** 2 + translateY ** 2),
        graphAngle: (Math.atan2(translate.yPadding, translate.xPadding) * 180) / Math.PI,
        // graphAngle: (Math.atan2(translateY, translateX) * 180) / Math.PI,
      };
    },
    [getTranslateScale]
  );

  const updateTranslate = useCallback(
    (translate: stateType) => {
      Object.assign(translate, calculateTranslation(translate));
      if (Math.abs(xPadding[0] - translate.xPadding) > Number.EPSILON) setXPadding([translate.xPadding]);
      if (Math.abs(yPadding[0] - translate.yPadding) > Number.EPSILON) setYPadding([translate.yPadding]);
      if (Math.abs(radius[0] - translate.radius) > Number.EPSILON) setRadius([translate.radius]);
      if (Math.abs(angle[0] - translate.angle) > Number.EPSILON) setAngle([translate.angle]);

      setTranslate(translate);
    },
    [xPadding, yPadding, radius, angle, calculateTranslation]
  );

  useEffect(() => {
    if (setOffset) setOffset(debouncedTranslate);
  }, [debouncedTranslate, setOffset]);

  useEffect(() => {
    let newTranslate: stateType | null = null;
    if (Math.abs(xPadding[0] - translate.xPadding) > Number.EPSILON) {
      newTranslate = copyStateType(translate);
      newTranslate.xPadding = xPadding[0];
      if (locked) {
        // const a = Math.atan2(translate.yPadding, translate.xPadding);
        // let r = Math.sqrt(translate.xPadding ** 2 + translate.yPadding ** 2);
        const diff = xPadding[0] - translate.xPadding;
        newTranslate.yPadding += diff;
        if (newTranslate.yPadding > maxPadding) newTranslate.yPadding = maxPadding;
        if (newTranslate.yPadding < minPadding) newTranslate.yPadding = minPadding;
      }
      [newTranslate.radius, newTranslate.angle] = calculateAngleFromPadding(newTranslate);
    } else if (Math.abs(yPadding[0] - translate.yPadding) > Number.EPSILON) {
      newTranslate = copyStateType(translate);
      newTranslate.yPadding = yPadding[0];
      if (locked) {
        // const a = Math.atan2(translate.yPadding, translate.xPadding);
        // let r = Math.sqrt(translate.xPadding ** 2 + translate.yPadding ** 2);
        const diff = yPadding[0] - translate.yPadding;
        newTranslate.xPadding += diff;
        if (newTranslate.xPadding > maxPadding) newTranslate.xPadding = maxPadding;
        if (newTranslate.xPadding < minPadding) newTranslate.xPadding = minPadding;
      }
      [newTranslate.radius, newTranslate.angle] = calculateAngleFromPadding(newTranslate);
    } else if (Math.abs(radius[0] - translate.radius) > Number.EPSILON) {
      newTranslate = copyStateType(translate);
      newTranslate.radius = radius[0];
      [newTranslate.xPadding, newTranslate.yPadding] = calculatePaddingFromAngle(newTranslate);
    } else if (Math.abs(angle[0] - translate.angle) > Number.EPSILON) {
      // console.log("angle", angle);
      newTranslate = copyStateType(translate);
      newTranslate.angle = angle[0];
      [newTranslate.xPadding, newTranslate.yPadding] = calculatePaddingFromAngle(newTranslate);
    }
    if (newTranslate) updateTranslate(newTranslate);
  }, [xPadding, yPadding, radius, angle, calculateAngleFromPadding, calculatePaddingFromAngle]);

  return (
    <div className={styles.main}>
      <div className={styles.sliderMain}>
        <div className={styles.bottons}>
          <Button
            bsSize="sm"
            onClick={() => {
              const newTranslate = copyStateType(translate);
              newTranslate.xPadding = 5;
              newTranslate.yPadding = 10;
              [newTranslate.radius, newTranslate.angle] = calculateAngleFromPadding(newTranslate);
              updateTranslate(newTranslate);
            }}
          >
            Auto stack
          </Button>
        </div>
      </div>

      <div className={styles.sliderMain}>
        <div className={styles.slider}>
          <div className={styles.sliderText}>
            X offset
            <br />
            {translate.translateX?.toFixed(4) || 0}
          </div>
          <Range
            step={1}
            min={minPadding}
            max={maxPadding}
            values={xPadding}
            onChange={setXPadding}
            renderTrack={SliderTrack}
            renderThumb={SliderThumbBlock}
          />
        </div>

        {/* <div style={{ width: 10 }}></div> */}
        <div style={{ paddingLeft: 7, paddingRight: 7 }} onClick={() => setLocked(!locked)}>
          {locked ? <IconBox type="lockClosed" /> : <IconBox type="lockOpen" />}
        </div>
        {/* <div style={{ width: 10 }}></div> */}

        <div className={styles.slider}>
          <div className={styles.sliderText}>
            Y offset
            <br />
            {translate.translateY?.toFixed(4) || 0}
          </div>
          <Range
            step={1}
            min={minPadding}
            max={maxPadding}
            values={yPadding}
            onChange={setYPadding}
            renderTrack={SliderTrack}
            renderThumb={SliderThumbBlock}
          />
        </div>
      </div>
      <div className={styles.sliderMain}>
        <div className={styles.slider}>
          <div className={styles.sliderText}>
            Radial offset <br />
            {translate.offset.toFixed(3)}
          </div>
          <Range
            step={0.1}
            min={0}
            max={maxDiagonalPadding}
            values={radius}
            onChange={setRadius}
            renderTrack={({ props, children }: { props: any; children: any }) => (
              <div {...props} className={styles.sliderTrack}>
                {children}
              </div>
            )}
            renderThumb={({ props }: { props: any }) => (
              <div
                {...props}
                className={styles.sliderThumb}
                // style={{ width: thumbBound.width, height: 20, background: thumbColors[values[0]] }}
                // style={{ width: 20, height: 12, background: "silver" }}
              ></div>
            )}
          />
        </div>
        <div style={{ width: 30 }}></div>
        <div className={styles.slider}>
          <div className={styles.sliderText}>
            Angle <br />
            {translate.graphAngle.toFixed(3)}°
          </div>
          <Range
            step={0.1}
            min={0}
            max={90}
            values={[90 - angle[0]]}
            onChange={(values: number[]) => {
              setAngle([90 - values[0]]);
            }}
            renderTrack={({ props, children }: { props: any; children: any }) => (
              <div {...props} className={styles.sliderTrack}>
                {children}
              </div>
            )}
            renderThumb={({ props }: { props: any }) => <div {...props} className={styles.sliderThumb}></div>}
          />
        </div>
      </div>
    </div>
  );
};
