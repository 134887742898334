import { StringIndexedDict } from "../../../../api/GenericTypes";
import {
  GenericEntityVirtualizedEntitySelectFormFieldProps,
  GenericVirtualizedEntitySelectFormFieldControllerWrapper,
} from "../../../formfields/GenericVirtualizedSelectFormField/GenericVirtualizedEntitySelectFormFieldControllerWrapper";
import { QuickAddWrapper } from "../../QuickAdd/common/QuickAddWrapper";
import {
  FacilitySortingOptionsConsts,
  InstrumentFacilityFilters,
  InstrumentFacilitySuggestions,
  instrumentsConstants,
} from "../../../../api/Facilities";
import { QuickAddInstrumentForm } from "../../QuickAdd/forms/QuickAddInstrumentForm";
import { useContext } from "react";
import { SessionContext } from "../../../contexts/SessionContext";

export const InstrumentsVirtualizedSelectForm = <Entity extends StringIndexedDict>(
  props: GenericEntityVirtualizedEntitySelectFormFieldProps<
    Entity,
    InstrumentFacilitySuggestions,
    InstrumentFacilityFilters
  >
) => {
  const { session } = useContext(SessionContext);

  return (
    <GenericVirtualizedEntitySelectFormFieldControllerWrapper<
      Entity,
      InstrumentFacilitySuggestions,
      InstrumentFacilityFilters
    >
      {...props}
      filters={{ orderBy: "NAME_ASC", ...props.filters }}
      createModal={({ showModal, setShowModal, onCreate }) => (
        <QuickAddWrapper showModal={showModal} setShowModal={setShowModal} onCreate={onCreate}>
          {(quickAddProps) => <QuickAddInstrumentForm {...quickAddProps} />}
        </QuickAddWrapper>
      )}
      entityConstants={instrumentsConstants}
      orderByOptions={FacilitySortingOptionsConsts}
      allowCreateEntity={session?.permissions.administer_facilities && props.allowCreateEntity}
    />
  );
};
