import {
  PropertyTranslator,
  IGenericRequestParametersTranslator,
  IPaginationParametersTranslator,
  IRelationParametersTranslator,
  ICreatedRecordParametersTranslator,
  IModifiedRecordParametersTranslator,
  ICreationRecordLabels,
  IModificationRecordLabels,
  INamedEntityLabels,
  IPermissionedEntityLabels,
  IRelatedEntityLabels,
} from "./GenericTranslator";
import {
  DefaultCreatedByRecordOrderTypeConsts,
  DefaultCreatedOnRecordOrderTypeConsts,
  DefaultModifiedByRecordOrderTypeConsts,
  DefaultModifiedOnRecordOrderTypeConsts,
  DefaultOrderTypeConsts,
  EntityConstants,
  ICreatedRecordParameters,
  ICreationRecord,
  IEntityMinimalModel,
  IGenericRequestParameters,
  IModelWithIntId,
  IModificationRecord,
  IModifiedRecordParameters,
  INamedEntity,
  IPaginationParameters,
  IPermissionedEntity,
  IRelatedEntity,
  IRelation,
  IRelationModel,
  IRelationParameters,
} from "./GenericTypes";

export const organizationTagsConstants: EntityConstants<OrganizationTag, OrganizationTagFilters> = {
  resource: "organization_tags",
  frontendIndexRoute: "organization_tags",
  entitySingular: "organization tag",
  entityPlural: "organization tags",
  icon: "tag",
};

export const OrganizationTagFieldLabels: PropertyTranslator<OrganizationTag> = {
  ...INamedEntityLabels,
  ...IPermissionedEntityLabels,
  ...ICreationRecordLabels,
  ...IModificationRecordLabels,
  ...IRelatedEntityLabels,
  id: "Organization tag ID",
  notes: "Notes",
} as const;

export interface OrganizationTagRelations extends IRelationModel {
  organizations: IRelation;
}

export interface OrganizationTag
  extends IPermissionedEntity,
    IModelWithIntId,
    INamedEntity,
    IRelatedEntity<OrganizationTagRelations>,
    ICreationRecord,
    IModificationRecord {
  notes: string;
}
export interface OrganizationTagSuggestions extends IEntityMinimalModel<OrganizationTag["id"]> {}

export interface OrganizationTagFilters
  extends IGenericRequestParameters<OrganizationTag, OrganizationTagSortingOptions>,
    IPaginationParameters,
    IRelationParameters,
    ICreatedRecordParameters,
    IModifiedRecordParameters {
  notes?: string | null;
}

export const OrganizationTagFiltersTranslator: PropertyTranslator<OrganizationTagFilters> = {
  ...IGenericRequestParametersTranslator,
  ...IPaginationParametersTranslator,

  ...IRelationParametersTranslator,
  ...ICreatedRecordParametersTranslator,
  ...IModifiedRecordParametersTranslator,
  notes: "Notes",
} as const;

export const OrganizationTagSortingOptionsConsts = [
  ...DefaultOrderTypeConsts,
  ...DefaultCreatedOnRecordOrderTypeConsts,
  ...DefaultCreatedByRecordOrderTypeConsts,
  ...DefaultModifiedOnRecordOrderTypeConsts,
  ...DefaultModifiedByRecordOrderTypeConsts,
] as const;
export type OrganizationTagSortingOptions = (typeof OrganizationTagSortingOptionsConsts)[number];
