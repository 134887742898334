import { useCallback, useEffect, useState } from "react";
import { Dropdown, MenuItem } from "react-bootstrap";

import { Color, Track } from "../../ViewerLayout/ViewerLayoutTypes";
import { ColorBox } from "../../ViewerUIElements/ColorBox";
import styles from "./TrackSelector.module.css";

enum trackSelectionMode {
  all = "all",
  selected = "selected",
  visible = "visible",
}

export const TrackSelector = ({
  tracks,
  selectedTracks,
  currentTrack,
  setCurrentTrack,
  height,
}: {
  selectedTracks: string[];
  tracks: Record<string, Track>;
  currentTrack: string | undefined;
  setCurrentTrack: (trackID: string | undefined) => void;
  height?: number;
}) => {
  // const [currentTrack, setCurrentTrack] = useState<string>();
  const [trackList, setTrackList] = useState<string[]>([]);
  const [trackMode, setTrackMode] = useState<trackSelectionMode>(trackSelectionMode.all);
  const [autoMode, setAutoMode] = useState<boolean>(true);

  const trackSelect = useCallback(
    (mode: trackSelectionMode): string[] => {
      switch (mode) {
        case trackSelectionMode.selected:
          return selectedTracks.filter((id) => tracks[id].settings.selected);
        case trackSelectionMode.visible:
          return selectedTracks.filter((id) => tracks[id].settings.visible);
        case trackSelectionMode.all:
          return selectedTracks;
        default:
          return [];
      }
    },
    [selectedTracks, tracks]
  );

  useEffect(() => {
    let list: string[] = [];

    if (autoMode) {
      // list = selectedTracks.filter((id) => tracks[id].settings.selected);
      // // console.log("selected", list);
      // if (list.length < 1) {
      //   list = selectedTracks.filter((id) => tracks[id].settings.visible);
      //   mode = trackSelectionMode.visible;
      // }
      // if (list.length < 1) {
      //   list = selectedTracks;
      //   mode = trackSelectionMode.all;
      // }
      let mode: trackSelectionMode = trackSelectionMode.all;

      const modes = [trackSelectionMode.selected, trackSelectionMode.visible, trackSelectionMode.all];
      for (let i = 0; i < modes.length; i++) {
        mode = modes[i];
        list = trackSelect(mode);
        if (list.length > 0) break;
      }
      setTrackList(list);
      setTrackMode(mode);
    } else {
      list = trackSelect(trackMode);
    }

    setTrackList(list);
    // setTrackList(list);
    // setTrackMode(mode);
  }, [tracks, selectedTracks, trackSelect, autoMode, trackMode]);

  useEffect(() => {
    let c = currentTrack;
    // if (currentTrack === undefined && list.length > 0) setCurrentTrack(list[0]);
    if (trackList.length < 1) c = undefined;
    else {
      if (c === undefined) c = trackList[0];
      else if (trackList.indexOf(c) < 0) c = trackList[0];
    }
    // else c = trackList[0];

    // console.log("selected", currentTrack, trackList.length, "->", c);
    setCurrentTrack(c);
  }, [trackList]);

  const getTrackColor = useCallback(
    (id: string | undefined | null): Color | undefined => {
      if (id === undefined || id === null) return undefined;
      return tracks?.[id]?.settings?.color;
    },
    [tracks]
  );

  const getTrackTitle = useCallback(
    (id: string | undefined | null): any => {
      if (id === undefined || id === null) return <span>{"<empty>"}</span>;

      const name = tracks[id]?.name
        ? tracks[id].name.length > 22
          ? tracks[id].name.slice(0, 25) + ".."
          : tracks[id].name
        : "<empty>";

      const color = getTrackColor(id);
      return (
        <span>
          {/* {color ? <ColorBox color={color} allowColorChange={false} /> : null} {tracks[id]?.label || "<empty>"} */}
          {color ? <ColorBox color={color} allowColorChange={false} /> : null} {name}
        </span>
      );
    },
    [tracks]
  );

  return (
    <div>
      <div>
        <span style={{ fontWeight: "bold" }}>Track: </span>
        <Dropdown
          id={"track-dropdown"}
          onSelect={(key: any) => {
            setCurrentTrack(key);
            // if (setCurrentTrack) setCurrentTrack(key);
          }}
        >
          <Dropdown.Toggle bsSize="xs">
            {getTrackTitle(currentTrack)}
            {/* <span>
              {tracks?.[selection]?.parameter?.color ? (
                <ColorBox color={tracks[selection].parameter.color as Color} allowColorChange={false} />
              ) : null}{" "}
              {tracks[selection].label || "Unknown"}
            </span> */}
          </Dropdown.Toggle>
          <Dropdown.Menu className={styles.scrollableMenu} style={{ maxHeight: height || 100, overflowY: "auto" }}>
            {trackList.map((id) => (
              <MenuItem key={id} eventKey={id} active={id === currentTrack}>
                {tracks[id]?.settings?.color ? (
                  <ColorBox color={tracks[id].settings.color as Color} allowColorChange={false} />
                ) : null}{" "}
                {tracks[id].name.length > 22 ? tracks[id].name.slice(0, 25) + ".." : tracks[id].name}
              </MenuItem>
            ))}
            {/* {tmp.map((id) => (
              <MenuItem key={id} eventKey={id}>
                {id}
              </MenuItem>
            ))} */}
          </Dropdown.Menu>
        </Dropdown>

        <span style={{ paddingLeft: 5, fontWeight: "bold", fontSize: "small" }}>
          use{" "}
          <Dropdown
            id={"track-dropdown"}
            onSelect={(key: any) => {
              setAutoMode(false);
              setTrackMode(key);
              setTrackList(trackSelect(key));

              // setCurrentTrack(key);
              // if (setCurrentTrack) setCurrentTrack(key);
            }}
          >
            <Dropdown.Toggle bsSize="xs">
              {/* {getTrackTitle(currentTrack)} */}
              {trackMode}
              {/* <span>
              {tracks?.[selection]?.parameter?.color ? (
                <ColorBox color={tracks[selection].parameter.color as Color} allowColorChange={false} />
              ) : null}{" "}
              {tracks[selection].label || "Unknown"}
            </span> */}
            </Dropdown.Toggle>
            <Dropdown.Menu className={styles.scrollableMenu} style={{ maxHeight: height || 100, overflowY: "auto" }}>
              {Object.entries(trackSelectionMode).map(([id, name]) => (
                <MenuItem key={id} eventKey={id} active={id === currentTrack}>
                  {name}
                  {/* {tracks[id]?.parameter?.color ? (
                    <ColorBox color={tracks[id].settings.color as Color} allowColorChange={false} />
                  ) : null}{" "}
                  {tracks[id].label} */}
                </MenuItem>
              ))}
              {/* {tmp.map((id) => (
              <MenuItem key={id} eventKey={id}>
                {id}
              </MenuItem>
            ))} */}
            </Dropdown.Menu>
          </Dropdown>{" "}
          tracks
        </span>
        {/* <span style={{ fontWeight: "bold" }}> {trackMode} tracks</span> */}
      </div>
    </div>
  );
};
