import { CSSProperties, useEffect, useMemo, useState } from "react";
import { CircularProgress } from "../CircularProgress/CircularProgress";

export interface CircularCountdownProps {
  startTime: Date;
  endTime: Date;
  updateIntervalInMs?: number;
  width: number;
  backward?: boolean;
  style?: CSSProperties;
}
export const CircularCountdown = ({
  startTime,
  endTime,
  updateIntervalInMs = 1000,
  width,
  backward = false,
  style,
}: CircularCountdownProps) => {
  const [currentTime, setCurrentTime] = useState<number>(new Date().getTime());

  const totalTime = useMemo(() => {
    return endTime.getTime() - startTime.getTime();
  }, [endTime, startTime]);

  const remainingTime = endTime.getTime() - currentTime;

  const currentOffset = totalTime - remainingTime;

  const percentageDone = Math.min(Math.max((currentOffset / totalTime) * 100, 0), 100);

  useEffect(() => {
    if (percentageDone < 100) {
      const timer = setInterval(() => setCurrentTime((prev) => prev + updateIntervalInMs), updateIntervalInMs);
      return () => clearInterval(timer);
    }
  }, [percentageDone, updateIntervalInMs]);

  return <CircularProgress progress={backward ? 100 - percentageDone : percentageDone} width={width} style={style} />;
};
