import Status from "../../common/badges/Status/Status";
import { DateTimeRenderer } from "../../common/datetime/DateTimeFormatter";
import { EntityDetailTableProps } from "../../common/entity/EntityInterfaces";
import { LucideIcon } from "../../common/icon/LucideIcon";
import { GetPersons } from "../../common/misc/EntityRenders/EntityRenderer";
import { NotSet } from "../../common/misc/UIconstants";
import { Table } from "../../common/panels/Detail/DetailTable";
import { LabNotebookExperimentStatus } from "../types/LabNotebookExperiment";

export const LabNotebookExperimentStatusToLabel = (status: LabNotebookExperimentStatus) => {
  switch (status) {
    case "FAILED":
      return { label: "Failed", status: "danger" };
    case "PAUSED":
      return { label: "Paused", status: "warning" };
    case "RUNNING":
      return { label: "Running", status: "success" };
    case "COMPLETED":
      return { label: "Completed", status: "success" };
    case "ABORTED":
      return { label: "Aborted", status: "danger" };

    default:
      return { label: "-", status: "warning" };
  }
};

export const LabNotebookExperimentDetailContentTable = ({
  entity,
  entityConstants,
  fieldLabels,
}: EntityDetailTableProps<"notebookExperiments">) => {
  return (
    <Table noPadding>
      <Table.Head>Lab notebook experiment details</Table.Head>
      <Table.Body>
        <Table.Body.RowContent
          title={fieldLabels.name}
          content={
            <div
              className="flex row-nowrap align-center gap-5"
              data-toggle="tooltip"
              title={entity.name}
              style={{ fontWeight: "bold", overflow: "hidden" }}
            >
              <div style={{ overflow: "hidden", textOverflow: "ellipsis" }}>{entity.name || NotSet}</div>
              {entity.isDeleted && (
                <div>
                  <label className="label label-soft-warning" title="Trashed" style={{ margin: 0 }}>
                    <LucideIcon name="trash" />
                  </label>
                </div>
              )}
            </div>
          }
        />

        <Table.Body.RowContent
          title={fieldLabels.description}
          content={
            entity.description ? (
              <div className={"container_notes"}>
                <textarea rows={2} className={"container_textarea"} value={entity.description} disabled={true} />
              </div>
            ) : (
              NotSet
            )
          }
        />

        <Table.Body.RowContent
          title={fieldLabels.status}
          content={
            <div className="flex align-center gap-5">
              <Status
                idle={entity.status === "RUNNING"}
                type={LabNotebookExperimentStatusToLabel(entity.status).status as any}
              />
              {LabNotebookExperimentStatusToLabel(entity.status).label}
            </div>
          }
        />

        <Table.Body.RowContent
          title={fieldLabels.createdOn}
          content={
            <div className="flex align-center gap-5" style={{ width: "100%", flexWrap: "wrap" }}>
              <div style={{ maxWidth: "max-content", width: "100%" }}>
                <DateTimeRenderer date={entity?.createdOn} includeElapsed={false} />
              </div>
              <div className="flex row-nowrap align-center gap-5">
                by <GetPersons persons={[entity.createdBy]} />
              </div>
            </div>
          }
        />

        <Table.Body.RowContent
          title={fieldLabels.modifiedOn}
          content={
            <div className="flex align-center gap-5" style={{ width: "100%", flexWrap: "wrap" }}>
              <div style={{ maxWidth: "max-content", width: "100%" }}>
                <DateTimeRenderer date={entity?.modifiedOn} includeElapsed={false} />
              </div>
              <div className="flex row-nowrap align-center gap-5">
                by <GetPersons persons={[entity.modifiedBy]} />
              </div>
            </div>
          }
        />
      </Table.Body>
    </Table>
  );
};
