import { Dispatch, SetStateAction, useCallback, useState } from "react";
import { DateTimeRenderer } from "../../../common/datetime/DateTimeFormatter";
import { LucideIcon } from "../../../common/icon/LucideIcon";
import {
  EntitySidebarTile,
  EntitySidebarTileProps,
} from "../../../common/sidebar/tiles/EntitySidebarTile/EntitySidebarTile";
import {
  LabNotebook,
  LabNotebookFilters,
  LabNotebookFiltersTranslator,
  LabNotebookSortingOptionsConsts,
  labNoteBooksConstants,
} from "../../types/LabNotebook";
import { ExclusiveDropdown } from "../../../common/buttons/ExclusiveDropdown/ExclusiveDropdown";
import { useEntityApi } from "../../../api/useEntityApi";
import { useQueryClient } from "@tanstack/react-query";
import { LAB_NOTEBOOK_TOC_QUERY_KEY } from "./LabNotebookTocTile/LabNotebookTocTile";
import { QuickAddWrapper } from "../../../common/forms/QuickAdd/common/QuickAddWrapper";
import { useELNRoutes } from "../../ELNRouter/useELNRoutes";
import { QuickAddLabNotebookForm } from "../../../common/forms/QuickAdd/forms/QuickAddLabNotebook";
import { QuickEditWrapper } from "../../../common/forms/QuickEdit/common/QuickEditWrapper";
import { QuickEditLabNotebookForm } from "../../../common/forms/QuickEdit/forms/QuickEditLabNotebook";
import { LabNotebookExperiment } from "../../types/LabNotebookExperiment";
import { QuickAddLabNotebookExperimentForm } from "../../../common/forms/QuickAdd/forms/QuickAddLabNotebookExperiment";
import { ELNDeleteConfirmationModal } from "../../ELNCreateOrEditForms/ELNDeleteConfirmationModal";
import { Modal } from "../ELNModal/ELNModal";
import { EntityHistory } from "../EntityHistory/EntityHistory";
import { LabNotebookFilterBar } from "../../LabNotebooks/Table/LabNotebooksFilterBar";
import { EntityFilterIndicator } from "../../../common/tables/EntityFilterIndicator/EntityFilterIndicator";
import { useDefaultEntryCreateMutation } from "./LabNotebookExperimentsTile";
import { TableArrayRenderer } from "../../../common/misc/EntityRenders/EntityRenderer";
import { Button } from "../../../common/buttons/Button/Button";

interface LabNotebookSidebarTileProps extends EntitySidebarTileProps<LabNotebook, LabNotebookFilters> {
  useAddModal?: boolean;
}

export const LabNotebooksTile = (props: LabNotebookSidebarTileProps) => {
  const [showAddModal, setShowAddModal] = useState<boolean>(false);
  const handleAddButtonClick = useCallback(
    (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => setShowAddModal(true),
    []
  );
  const { setELNRoute } = useELNRoutes();
  const queryClient = useQueryClient();
  return (
    <>
      <EntitySidebarTile<LabNotebook, LabNotebookFilters>
        {...props}
        entityConstants={labNoteBooksConstants}
        orderByOptions={LabNotebookSortingOptionsConsts}
        translatorConsts={LabNotebookFiltersTranslator}
        defaultFilters={{ orderBy: "MODIFIED_ON_DESC", ...props.defaultFilters }}
        showAddButton={props.showAddButton}
        onAddButtonClick={props.useAddModal ? handleAddButtonClick : undefined}
        rowLabel={(e) => <>{e.name}</>}
        rowDate={(e) => (
          <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
            <LucideIcon name="save" style={{ marginBottom: "2px", width: "12px" }} />
            <DateTimeRenderer date={e.modifiedOn} includeElapsed={true} includeTime={false} />
          </div>
        )}
        rowInfo={(e) => <TableArrayRenderer values={e.projects}>{(value) => value.name}</TableArrayRenderer>}
        rowControls={LabNotebookTileRowControls}
        filterIndicatorOverride={LabNotebookTileControls}
      />

      {showAddModal && (
        <QuickAddWrapper<LabNotebook>
          showModal={showAddModal}
          setShowModal={setShowAddModal}
          onCreate={(newLabNotebook) => {
            if (newLabNotebook && newLabNotebook.id > 0) {
              queryClient.invalidateQueries([LAB_NOTEBOOK_TOC_QUERY_KEY]);
              queryClient.invalidateQueries(["lab_notebooks"]);
              setELNRoute({ labNotebookId: newLabNotebook.id });
            }
          }}
        >
          {(props) => <QuickAddLabNotebookForm {...props} initialValues={{ status: "ACTIVE" }} />}
        </QuickAddWrapper>
      )}
    </>
  );
};

const LabNotebookTileControls = ({
  filters,
  setFilters,
}: {
  filters: LabNotebookFilters;
  setFilters: Dispatch<SetStateAction<LabNotebookFilters>>;
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  return (
    <div
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <ExclusiveDropdown
        show={isOpen}
        setShow={setIsOpen}
        drop="down-left"
        title="Edit"
        btnCls="btn btn-sm btn-ghost-secondary"
        backdropStyle={{ background: "transparent", backdropFilter: "none" }}
        triggerComponent={(onToggleDropdown) => (
          <button
            onClick={(e) => onToggleDropdown(e)}
            className="btn btn-sm btn-ghost-secondary"
            style={{ padding: 0 }}
          >
            <EntityFilterIndicator<LabNotebook, LabNotebookFilters>
              filters={filters}
              excludeFilters={{
                includeSoftDeleted: (value) => !!value,
                isSoftDeleted: (value) => !!value,
              }}
              showAlways
            />
          </button>
        )}
      >
        <div style={{ maxHeight: "500px", display: "flex", minWidth: "300px", margin: "10px", marginBottom: 0 }}>
          <LabNotebookFilterBar setFilters={setFilters} />
        </div>
      </ExclusiveDropdown>
    </div>
  );
};

const LabNotebookTileRowControls = (entity: LabNotebook) => {
  const { setELNRoute } = useELNRoutes();

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const [showAddExperimentModal, setShowAddExperimentModal] = useState<boolean>(false);
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [showHistoryModal, setShowHistoryModal] = useState<boolean>(false);

  const { restoreMutationAsync, isLoadingRestoreMutation } = useEntityApi("lab_notebooks");

  const queryClient = useQueryClient();
  const createDefaultEntry = useDefaultEntryCreateMutation();

  return (
    <div
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <ExclusiveDropdown
        show={isOpen}
        setShow={setIsOpen}
        drop="down-right"
        disabled={false}
        title="Edit"
        btnCls="btn btn-sm btn-ghost-secondary"
        backdropStyle={{ background: "transparent", backdropFilter: "none" }}
      >
        <ul style={{ padding: 0, margin: 0 }}>
          <button
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              setShowAddExperimentModal(true);
            }}
            data-toggle="tooltip"
            title="Create new experiment"
            className="btn btn-ghost-primary btn-block"
            style={{ textAlign: "left" }}
            disabled={!entity?.permissions?.edit || entity.status === "CLOSED"}
          >
            <LucideIcon name="file-text" />
            <span> Create new experiment</span>
          </button>
          <hr style={{ margin: "5px 0" }} />
          <button
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              setShowEditModal(true);
            }}
            data-toggle="tooltip"
            title="Edit notebook details"
            className="btn btn-ghost-secondary btn-block"
            style={{ textAlign: "left" }}
            disabled={!entity.permissions?.edit || entity.isDeleted}
          >
            <LucideIcon name="square-pen" />
            <span> Edit notebook details</span>
          </button>
          <button
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              setShowHistoryModal(true);
            }}
            data-toggle="tooltip"
            title="Show notebook details history"
            className="btn btn-ghost-secondary btn-block"
            style={{ textAlign: "left" }}
          >
            <LucideIcon name="clock" />
            <span> Show notebook details history</span>
          </button>
          {entity.isDeleted ? (
            <Button
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                restoreMutationAsync({ id: entity.id })
                  .then(() => {
                    queryClient.invalidateQueries([LAB_NOTEBOOK_TOC_QUERY_KEY]);
                    queryClient.invalidateQueries(["lab_notebooks"]);
                    queryClient.invalidateQueries(["lab_notebook_experiments"]);
                    queryClient.invalidateQueries(["lab_notebook_entries"]);
                  })
                  .catch((e) => {
                    console.error(e);
                  });
              }}
              data-toggle="tooltip"
              title="Restore notebook"
              className="btn btn-ghost-success btn-block"
              style={{ textAlign: "left" }}
              disabled={!entity.permissions?.edit}
              loading={isLoadingRestoreMutation}
            >
              <LucideIcon name="refresh-ccw" />
              <span> Restore notebook</span>
            </Button>
          ) : (
            <button
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                setShowDeleteModal(true);
              }}
              data-toggle="tooltip"
              title="Trash notebook"
              className="btn btn-ghost-warning btn-block"
              style={{ textAlign: "left" }}
              disabled={!entity.permissions?.edit}
            >
              <LucideIcon name="trash-2" />
              <span> Trash notebook</span>
            </button>
          )}
        </ul>
      </ExclusiveDropdown>

      {showAddExperimentModal && (
        <QuickAddWrapper<LabNotebookExperiment>
          showModal={showAddExperimentModal}
          setShowModal={setShowAddExperimentModal}
          onCreate={(newExperiment) => {
            if (newExperiment && newExperiment.id > 0) {
              queryClient.invalidateQueries([LAB_NOTEBOOK_TOC_QUERY_KEY]);
              queryClient.invalidateQueries(["lab_notebooks"]);
              queryClient.invalidateQueries(["lab_notebook_experiments"]);
              queryClient.invalidateQueries(["lab_notebook_entries"]);
              createDefaultEntry(newExperiment);
              setELNRoute({ labNotebookId: newExperiment.labNotebook.id, labNotebookExperimentId: newExperiment.id });
            }
          }}
        >
          {(props) => (
            <QuickAddLabNotebookExperimentForm {...props} initialValues={{ labNotebook: entity, status: "RUNNING" }} />
          )}
        </QuickAddWrapper>
      )}

      {showEditModal && (
        <QuickEditWrapper<LabNotebook>
          showModal={showEditModal}
          setShowModal={setShowEditModal}
          onEdit={(editedLabNotebook) => {}}
        >
          {(props) => <QuickEditLabNotebookForm {...props} initialValues={entity} />}
        </QuickEditWrapper>
      )}

      {showDeleteModal && (
        <ELNDeleteConfirmationModal
          isOpen={showDeleteModal}
          setIsOpen={setShowDeleteModal}
          id={entity.id}
          name={entity.name.length > 25 ? "notebook" : entity.name}
          entityInfo={{ "Lab notebook": entity.name, "Description": entity.description }}
          resource="lab_notebooks"
          onDelete={() => {
            queryClient.invalidateQueries([LAB_NOTEBOOK_TOC_QUERY_KEY]);
            queryClient.invalidateQueries(["lab_notebooks"]);
            queryClient.invalidateQueries(["lab_notebook_experiments"]);
            queryClient.invalidateQueries(["lab_notebook_entries"]);
            setShowDeleteModal(false);
          }}
          description="Proceeding will move the selected notebook into trash."
          deletePermanently={false}
        />
      )}

      {showHistoryModal && (
        <Modal isOpen={showHistoryModal} onClose={() => setShowHistoryModal(false)}>
          <div style={{ width: "90vw", height: "90vh" }}>
            <EntityHistory<LabNotebook> resource="lab_notebooks" id={entity.id} />
          </div>
        </Modal>
      )}
    </div>
  );
};
