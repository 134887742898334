import { useEntityMentionSuggester } from "../../extensions/entityMention/useEntityMentionSuggester";
import { useKeymap, usePositioner } from "@remirror/react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { NotSet } from "../../../../../common/misc/UIconstants";
import { genericEntityConstants } from "../../../../../api/GenericConstants";
import { EntityList } from "../../../../../common/lists/EntityList";
import {
  GenericEntity,
  ICreatedRecordParameters,
  IGenericRequestParameters,
  IModifiedRecordParameters,
  INamedEntity,
} from "../../../../../api/GenericTypes";
import { LucideIcon } from "../../../../../common/icon/LucideIcon";
import { ExtensionPriority } from "remirror";
import styles from "./TextEditorEntitySelectComponent.module.css";
import { getNotebookEntryStringLabel } from "../../../../LabNotebookEntry/LabNotebookEntry";

export const TextEditorEntitySelectComponent = () => {
  const { state } = useEntityMentionSuggester();
  const { ref, x, y } = usePositioner("cursor");

  const searchTerm = useMemo(() => state?.query.full, [state?.query.full]);
  const activeEntityConstants = useMemo(() => {
    if (!!state && !!state.entityTypeId) {
      return genericEntityConstants[state.entityTypeId].entityConstants;
    }
  }, [state]);

  const [count, setCount] = useState<number>(0);

  const [currentIndex, setCurrentIndex] = useState<number>(0);

  const enabled = activeEntityConstants;

  const increaseCurrentIndexOnKeyPress = useCallback(
    ({ next }) => {
      if (enabled) {
        setCurrentIndex((prevState) => {
          if (prevState + 1 < count) return prevState + 1;
          return count - 1;
        });
        return true;
      }
      return next();
    },
    [enabled, count]
  );

  const decreaseCurrentIndexOnKeyPress = useCallback(
    ({ next }) => {
      if (enabled) {
        setCurrentIndex((prevState) => {
          if (prevState - 1 >= 0) return prevState - 1;
          return 0;
        });
        return true;
      }
      return next();
    },
    [enabled]
  );

  const [currentEntity, setCurrentEntity] = useState<(GenericEntity & INamedEntity) | null>(null);
  const currentEntityCallback = useCallback((entity: GenericEntity & INamedEntity) => {
    setCurrentEntity(entity);
  }, []);
  const createEntityMentionOnKeyPress = useCallback(
    ({ next }) => {
      if (enabled && state && currentEntity) {
        if (count - 1 >= currentIndex) {
          state.command({ entityTypeId: state.entityTypeId, entityId: currentEntity.id });
          return true;
        }
      }
      return next();
    },
    [enabled, state, currentEntity, count, currentIndex]
  );
  // const { insertEntity } = useCommands();
  // const createEntityOnKeyPress = useCallback(
  //   ({ next }) => {
  //     if (enabled && state && currentEntity) {
  //       if (count - 1 >= currentIndex) {
  //         insertEntity({ entityTypeId: state.entityTypeId, entityId: currentEntity.id }, state.range);
  //         return true;
  //       }
  //     }
  //     return next();
  //   },
  //   [count, currentEntity, currentIndex, enabled, insertEntity, state]
  // );

  useKeymap("Enter", createEntityMentionOnKeyPress, ExtensionPriority.High);
  useKeymap("Tab", createEntityMentionOnKeyPress, ExtensionPriority.High);
  useKeymap("ArrowUp", decreaseCurrentIndexOnKeyPress, ExtensionPriority.High);
  useKeymap("ArrowDown", increaseCurrentIndexOnKeyPress, ExtensionPriority.High);

  const reset = useCallback(() => {
    setCurrentIndex(0);
    setCurrentEntity(null);
    setCount(0);
  }, []);

  useEffect(() => {
    reset();
  }, [enabled, activeEntityConstants?.resource, reset]);

  const label = useCallback(
    ({ entity }: { entity: GenericEntity & INamedEntity }) => {
      switch (state?.entityTypeId) {
        case "notebookEntries":
          return getNotebookEntryStringLabel({ entry: entity as any });

        default:
          return entity?.name ?? NotSet;
      }
    },
    [state?.entityTypeId]
  );

  if (!enabled) {
    return null;
  }
  return (
    <div
      ref={ref}
      style={{
        top: y + 20,
        left: x - 30,
        height: `${count * 37 + 2}px`,
        maxHeight: `${5 * 37 + 2}px`,
      }}
      className={styles.TextEditorEntitySelectComponentDropDownContainer}
    >
      <EntityList<
        GenericEntity & INamedEntity,
        IGenericRequestParameters<GenericEntity & INamedEntity> & ICreatedRecordParameters & IModifiedRecordParameters
      >
        pageSize={10}
        entityConstant={activeEntityConstants}
        rowHeight={37}
        rowRenderer={(entity, index) => {
          return (
            <div
              className={"container_label btn btn-ghost-secondary btn-xs"}
              style={{
                paddingRight: "5px",
                backgroundColor: index === currentIndex ? "var(--primary-light)" : undefined,
              }}
              onClick={() => {
                state?.command({ entityTypeId: state.entityTypeId, entityId: entity.id });
              }}
            >
              <div style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
                {activeEntityConstants.icon && <LucideIcon name={activeEntityConstants.icon} color="var(--primary)" />}{" "}
                {label({ entity })}
              </div>
              <div className={"container_label_id"}>
                <span style={{ whiteSpace: "nowrap" }}>ID: {entity.id}</span>
              </div>
            </div>
          );
        }}
        countCallback={setCount}
        filters={{ searchTerm, searchTermIncludeIds: true }}
        // noItemMessage={
        //   !!searchTerm && searchTerm.length < searchTermMinLength
        //     ? `Please enter at least ${searchTermMinLength} characters...`
        //     : `No ${activeEntityConstants.entitySingular} found for "${searchTerm}"`
        // }
        noItemMessage={`No ${activeEntityConstants.entitySingular} found for "${searchTerm}"`}
        currentIndex={currentIndex}
        currentEntityCallback={currentEntityCallback}
      />
    </div>
  );
};
