import { Handler, ProsemirrorAttributes } from "remirror";
import { IdTypes, IGenericEntity, INamedEntity } from "../../../../../api/GenericTypes";
import { GenericEntityConstantsEntities } from "../../../../../api/GenericConstants";
import { EntityMentionOnChangeHandler } from "./useEntityMentionSuggester";

export const insertEntityMentionOptions: Remirror.CommandDecoratorOptions = {};
export const removeEntityMentionOptions: Remirror.CommandDecoratorOptions = {};

export interface EntityMentionOptions<T = {}> {
  openViewer?: (
    e: React.MouseEvent<HTMLSpanElement, MouseEvent>,
    entity: T & IGenericEntity & INamedEntity,
    entityTypeId: GenericEntityConstantsEntities
  ) => void;
  ignoreIntersectionObserver?: boolean;
  onChange?: Handler<EntityMentionOnChangeHandler>;
}

export interface EntityMentionAttributes extends ProsemirrorAttributes {
  id?: string;
  entityId?: IdTypes;
  entityUuid?: string;
  entityTypeId?: GenericEntityConstantsEntities;
  version?: number;
}
