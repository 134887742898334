import { useState } from "react";
import { Viewer } from "../../../../../../../../ViewerLayout/ViewerLayout";
import { ToggleButtonComponent } from "../../../../../../../../ViewerUIElements/ToggleButtonComponent";
import styles from "./ViewerModal.module.css";

export const ViewerModal = ({ id }: { id?: number }) => {
  const [showViewerParams, setShowViewerParams] = useState<boolean>(true);
  const [showViewerNavigation, setShowViewerNavigation] = useState<boolean>(true);
  const [showViewerTracks, setShowViewerTracks] = useState<boolean>(true);

  if (!id) return null;
  return (
    <div className={styles.elnDatasetWrapperViewerModalContainer}>
      <div className={styles.elnDatasetWrapperViewerModalToolbarContainer}>
        <div className={styles.elnDatasetWrapperViewerModalToolbar}>
          <ToggleButtonComponent
            checked={showViewerNavigation}
            setChecked={() => setShowViewerNavigation((prevState) => !prevState)}
          >
            <span>View navigation</span>
          </ToggleButtonComponent>
          <ToggleButtonComponent
            checked={showViewerTracks}
            setChecked={() => setShowViewerTracks((prevState) => !prevState)}
          >
            <span>View tracks</span>
          </ToggleButtonComponent>
          <ToggleButtonComponent
            checked={showViewerParams}
            setChecked={() => setShowViewerParams((prevState) => !prevState)}
          >
            <span>View params</span>
          </ToggleButtonComponent>
        </div>
      </div>
      <div className={styles.elnDatasetWrapperViewerModalViewerContainer}>
        <Viewer
          ids={[id]}
          settings={{
            interactiveMode: true,
            showParameter: showViewerParams,
            showNavigation: showViewerNavigation,
            showTrackList: showViewerTracks,
          }}
        />
      </div>
    </div>
  );
};
