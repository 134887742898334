import React, { CSSProperties, useCallback, useEffect, useRef, useState } from "react";
import styles from "./HierarchySelectForm.module.css";
import { LucideIcon } from "../../icon/LucideIcon";
import { EntityHierarchy } from "../TreeViewer/TreeViewer";
import { useEntitySuggestions, useGet } from "../../../api/BaseEntityApi";
import {
  EntityConstants,
  IdTypes,
  IEntityMinimalModel,
  IHierarchyTypeRead,
  StringIndexedDict,
} from "../../../api/GenericTypes";
import { useResizeDetector } from "react-resize-detector";
import { CheckboxRound } from "../../formfields/CheckboxFormField/CheckboxFormField";
import InfiniteLoader from "react-window-infinite-loader";
import { VariableSizeList } from "react-window";
import { AutoSizer } from "react-virtualized";
import { customTypeConstants } from "../../../api/CustomTypes";
import { hierarchyConstants } from "../../../api/Inventories";
import { SearchInput } from "../../forms/SearchInput/SearchInput";
import { useDebouncedValue } from "../../helperfunctions/useDebouncedValue";

interface ExtendedEntityHierarchy<IdType extends IdTypes> extends EntityHierarchy<IdType> {
  _children?: ExtendedEntityHierarchy<IdType>[];
}

// Helpers
const collectHierarchyItems = <IdType extends IdTypes>(
  nodes: ExtendedEntityHierarchy<IdType>,
  targetId: IdType,
  found: boolean = false
): IEntityMinimalModel<IdTypes>[] => {
  let items: IEntityMinimalModel<IdTypes>[] = [];
  // If the flag is true, start collecting IDs
  if (found) {
    items.push({ id: nodes.id, name: nodes.name });
  } else if (nodes.id === targetId) {
    // If the current node is the target, set the flag to true
    found = true;
    items.push({ id: nodes.id, name: nodes.name });
  }
  const children = nodes.children || nodes._children;
  // Recursively collect IDs from children if they exist
  if (children) {
    for (const child of children) {
      items = items.concat(collectHierarchyItems(child, targetId, found));
      // Once the target ID is found, all subsequent IDs are collected,
      // so we pass true for the found flag to all deeper recursive calls.
      if (nodes.id === targetId) {
        found = true;
      }
    }
  }
  return items;
};

const isIdFound = <IdType extends IdTypes>(node: ExtendedEntityHierarchy<IdType>, id: IdType): boolean => {
  if (node.id === id) {
    return true; // ID is found in the current node
  }
  const children = node.children || node._children;
  if (children) {
    for (const child of children) {
      if (isIdFound(child, id)) {
        return true; // ID is found in one of the children
      }
    }
  }
  return false; // ID is not found in this branch
};

const addValues = (
  newValues: IEntityMinimalModel<IdTypes>[] | null | undefined,
  values: IEntityMinimalModel<IdTypes>[] | null | undefined
) => {
  if (!newValues) return values;
  let _values = values || [];
  newValues.forEach((nv) => {
    if (!_values.some((v) => v.id === nv.id)) _values.push(nv);
  });

  return _values;
};

const removeValues = (
  newValues: IEntityMinimalModel<IdTypes>[] | null | undefined,
  values: IEntityMinimalModel<IdTypes>[] | null | undefined
) => {
  if (!newValues) return values;
  let _values = values || [];
  newValues.forEach((nv) => {
    _values = _values.filter((v) => v.id !== nv.id);
  });
  return _values;
};

export const addOrRemoveValue = (
  newValue: IEntityMinimalModel<IdTypes> | null | undefined,
  values: IEntityMinimalModel<IdTypes>[] | null | undefined
) => {
  if (!newValue) return values;
  let _values = Array.isArray(values) ? [...values] : [];
  if (_values.some((v) => v.id === newValue.id)) {
    _values = _values.filter((v) => v.id !== newValue.id);
  } else {
    _values = [..._values, newValue];
  }

  return _values;
};

const hasValue = (value: IEntityMinimalModel<IdTypes>[] | null | undefined, id: IdTypes) => {
  let isSelected: boolean = false;
  if (Array.isArray(value)) {
    isSelected = value.some((v) => v.id === id);
  }
  return isSelected;
};

const foldChildren = <IdType extends IdTypes>(
  id: IdType,
  node?: ExtendedEntityHierarchy<IdType>
): ExtendedEntityHierarchy<IdType> | undefined => {
  if (node) {
    if (node.id === id) {
      if (node.children) {
        node._children = node.children;
        node.children = undefined;
      } else {
        node.children = node._children;
        node._children = undefined;
      }
      return node;
    }
    if (node.children) {
      for (let i = 0; i < node.children.length; i++) {
        const result = foldChildren(id, node.children[i]);
        if (result) {
          node.children[i] = result;
          return node;
        }
      }
    }
  }
  return undefined;
};

// Functional components
const pageSize = 50;
const defaultRowHeight = 30;

interface HierarchyVirtualizedSelectFormDropdownProps<Filters extends StringIndexedDict> {
  entityConstants: EntityConstants;
  filters: Filters;
  value?: IEntityMinimalModel<IdTypes>[] | null | undefined;
  onChange?: (...event: any[]) => void;
}
export const HierarchyVirtualizedSelectFormDropdown = <
  SuggestionsEntity extends IEntityMinimalModel<IdTypes> & IHierarchyTypeRead,
  Filters extends StringIndexedDict
>({
  entityConstants,
  filters,
  value,
  onChange,
}: HierarchyVirtualizedSelectFormDropdownProps<Filters>) => {
  const virtualLoaderGrid = useRef<InfiniteLoader & { _listRef: VariableSizeList }>(null);
  const heights = useRef<{ [index: number]: number }>({});
  const [items, setItems] = useState<SuggestionsEntity[]>([]);
  const [searchValue, setSearchValue] = useState<string>("");
  const debouncedSearchValue = useDebouncedValue(searchValue, 500);

  const hierarchyData = useRef<{ [id: IdTypes]: ExtendedEntityHierarchy<IdTypes> | undefined }>({});

  const { data, fetchNextPage, hasNextPage, isFetching } = useEntitySuggestions<SuggestionsEntity, Filters>(
    entityConstants.resource,
    {
      page: 1,
      pageSize: pageSize,
      includeCount: false,

      ...filters,
      ...(debouncedSearchValue && { searchTerm: debouncedSearchValue }),
    },
    { enabled: true }
  );

  const onChangeCallback = useCallback(
    (event: any[]) => {
      onChange?.(event);
      virtualLoaderGrid.current?._listRef.forceUpdate();
    },
    [onChange]
  );

  useEffect(() => {
    if (data) setItems(data.pages.map((d) => d.results).flat());
  }, [data]);

  const fetchNext = useCallback(() => {
    if (hasNextPage) {
      fetchNextPage();
    }
  }, [fetchNextPage, hasNextPage]);

  const loadMoreCallback = useCallback(() => {}, []);
  const itemCount = hasNextPage ? items.length + 1 : items.length;
  const loadMoreItems = isFetching ? loadMoreCallback : fetchNext;
  const isItemLoaded = useCallback((index: number) => !!items?.[index], [items]);

  const onHeightChange = useCallback((index: number, height: number) => {
    heights.current[index] = height;
    virtualLoaderGrid.current?._listRef.resetAfterIndex(index, true);
  }, []);

  const Item = ({ index, style }: { index: number; style: CSSProperties }) => {
    if (!isItemLoaded(index)) {
      return (
        <div style={{ ...style }} key={index}>
          <span className="skeleton-block" />
        </div>
      );
    }
    const item = items[index];
    const hasValueOfHierarchy =
      hierarchyData.current?.[item.id] && Array.isArray(value)
        ? value.map((val) => +isIdFound(hierarchyData.current[item.id] as ExtendedEntityHierarchy<IdTypes>, val.id))
        : undefined;

    const selectedCount =
      hierarchyData.current?.[item.id] && hasValueOfHierarchy
        ? hasValueOfHierarchy.reduce((a, b) => a + b, 0)
        : undefined;

    const indeterminate = !!selectedCount && !value?.some((v) => v.id === item.id);

    // const isSelected = hasValue(value, item.id);
    return (
      <HierarchySelectFormParentLoader
        index={index}
        style={style}
        entityConstants={entityConstants}
        parent={item}
        defaultOpen={!!selectedCount}
        value={value}
        onChange={onChangeCallback}
        onHeightChange={onHeightChange}
        onLoad={(parentId, data) => (hierarchyData.current = { ...hierarchyData.current, [parentId]: data })}
        loadHierarchy={!Object.hasOwn(hierarchyData.current, item.id)}
        selectedCount={selectedCount}
        indeterminate={indeterminate}
      >
        <span className="skeleton-block" />
      </HierarchySelectFormParentLoader>
    );
  };

  return (
    <div className="flex col-nowrap gap-5" style={{ width: "100%", height: "100%" }}>
      <SearchInput searchValue={searchValue} setSearchValue={setSearchValue} placeholder="Search inventories" />
      <div className="flex col-nowrap" style={{ width: "100%", height: "100%" }}>
        {!!items.length && (
          <InfiniteLoader
            ref={virtualLoaderGrid}
            isItemLoaded={isItemLoaded}
            itemCount={itemCount}
            loadMoreItems={loadMoreItems as any}
          >
            {({ onItemsRendered, ref }) => (
              <AutoSizer>
                {({ width, height }) => (
                  <VariableSizeList
                    itemCount={itemCount}
                    onItemsRendered={onItemsRendered}
                    ref={ref}
                    width={width}
                    height={height}
                    itemSize={(index) => heights.current?.[index] || defaultRowHeight}
                  >
                    {Item}
                  </VariableSizeList>
                )}
              </AutoSizer>
            )}
          </InfiniteLoader>
        )}
      </div>
    </div>
  );
};

interface HierarchySelectFormParentLoaderProps<
  SuggestionsEntity extends IEntityMinimalModel<IdTypes> & IHierarchyTypeRead
> {
  index: number;
  style?: CSSProperties;
  entityConstants: EntityConstants;
  parent: SuggestionsEntity;
  defaultOpen: boolean;
  value?: IEntityMinimalModel<SuggestionsEntity["id"]>[] | null | undefined;
  onChange?: (...event: any[]) => void;
  onHeightChange: (index: number, height: number) => void;
  onLoad: (parentId: SuggestionsEntity["id"], data: EntityHierarchy<SuggestionsEntity["id"]> | undefined) => void;
  loadHierarchy?: boolean;
  selectedCount?: number;
  indeterminate?: boolean;
  children?: React.ReactNode;
}
const HierarchySelectFormParentLoader = <SuggestionsEntity extends IEntityMinimalModel<IdTypes> & IHierarchyTypeRead>({
  index,
  style,
  entityConstants,
  parent,
  defaultOpen,
  value,
  onChange,
  onHeightChange,
  onLoad,
  loadHierarchy,
  selectedCount,
  indeterminate,
  children,
}: HierarchySelectFormParentLoaderProps<SuggestionsEntity>) => {
  const [data, setData] = useState<ExtendedEntityHierarchy<SuggestionsEntity["id"]>>();
  const { ref, height } = useResizeDetector({ onResize: () => {} });
  const [show, setShow] = useState(defaultOpen);
  const isLoaded = useRef(false);
  const hasParent = hasValue(value, parent.id);

  useEffect(() => {
    setShow((prev) => (prev !== defaultOpen ? defaultOpen : prev));
  }, [defaultOpen]);

  const { data: hierarchyData, isFetching } = useGet<EntityHierarchy<SuggestionsEntity["id"]>>(
    `${customTypeConstants.resource}/hierarchy/${parent.id}`,
    undefined,
    {
      enabled: !!parent.isHierarchyRoot && loadHierarchy && !isLoaded.current,
    }
  );

  const collapse = useCallback(
    (id: IdTypes) => {
      if (data)
        setData((prev) => {
          const _data = foldChildren(id, prev) as ExtendedEntityHierarchy<SuggestionsEntity["id"]>;
          return { ..._data };
        });
    },
    [data]
  );

  const handleChange = useCallback(
    (id: IdTypes, data: ExtendedEntityHierarchy<SuggestionsEntity["id"]>) => {
      const minimalModels = collectHierarchyItems(data, id);
      if (hasValue(value, id)) {
        onChange?.(removeValues(minimalModels, value));
      } else {
        onChange?.(addValues(minimalModels, value));
      }
    },
    [onChange, value]
  );

  const handleCheck = useCallback(async () => {
    if (data) {
      handleChange(parent.id, data as ExtendedEntityHierarchy<SuggestionsEntity["id"]>);
    } else {
      onChange?.(addOrRemoveValue({ id: parent.id, name: parent.name }, value));
    }
  }, [data, handleChange, onChange, parent.id, parent.name, value]);

  useEffect(() => {
    if (hierarchyData) {
      setData(hierarchyData as ExtendedEntityHierarchy<SuggestionsEntity["id"]>);
      onLoad(parent.id, hierarchyData);
    } else {
      onLoad(parent.id, undefined);
    }
    isLoaded.current = true;
  }, [hierarchyData, onLoad, parent.id]);

  useEffect(() => {
    if (!!height) onHeightChange(index, height + defaultRowHeight);
    else {
      onHeightChange(index, defaultRowHeight);
    }
  }, [height, index, onHeightChange, ref]);

  return (
    <div className="flex col-nowrap" style={{ width: "100%", height: "fit-content", ...style }} key={index}>
      {isFetching ? (
        children
      ) : (
        <>
          <div className={`${styles.row} ${hasParent ? styles.rowChecked : ""}`}>
            {parent.isHierarchyRoot ? (
              <div
                className={styles.rowControls}
                onClick={async () => {
                  // await asyncLoad();
                  setShow((prev) => !prev);
                }}
              >
                {show ? <LucideIcon name="chevron-up" /> : <LucideIcon name="chevron-down" />}
              </div>
            ) : (
              <div className={styles.rowControls}>
                <LucideIcon name={hierarchyConstants.flatLayoutIcon} color={"var(--gray-400)"} />
              </div>
            )}

            <div
              className={`${styles.rowLabel} ellipsisContainer`}
              title={parent.name}
              onClick={async () => {
                await handleCheck();
                // setShow(true);
              }}
            >
              <span>
                <LucideIcon name={entityConstants.icon} color={"var(--primary)"} />{" "}
                {parent.inventoryName || parent.name}
              </span>

              {!!selectedCount && (
                <label className="label label-soft-default" style={{ margin: 0 }}>
                  <span className="badge">{selectedCount}</span> selected
                </label>
              )}
              <div style={{ marginLeft: "auto" }}>
                <label className="label label-soft-info" style={{ margin: 0 }}>
                  {parent.name}
                </label>
              </div>
              <div className={"ellipsisContainer"} style={{ color: "var(--gray-400)", fontSize: "0.75em" }}>
                <span>ID: {parent.id}</span>
              </div>
            </div>
            <div className={styles.rowCheckbox}>
              <CheckboxRound
                checked={hasParent || false}
                onChange={async () => {
                  await handleCheck();
                  // setShow(true);
                }}
                indeterminate={indeterminate}
              />
            </div>
          </div>
          <div className={styles.container} ref={ref} style={{ height: show ? "min-content" : 0 }}>
            {data && show && (
              <RenderTree
                entityConstants={entityConstants}
                treeData={data}
                handleChange={handleChange}
                collapse={collapse}
                value={value}
              />
            )}
          </div>
        </>
      )}
    </div>
  );
};

interface RenderTreeProps<IdType extends IdTypes> {
  entityConstants: EntityConstants;
  treeData: ExtendedEntityHierarchy<IdType>;
  handleChange: (id: IdType, data: ExtendedEntityHierarchy<IdType>) => void;
  collapse: (id: IdType) => void;
  value?: IEntityMinimalModel<IdType>[] | null | undefined;
}
const RenderTree = <IdType extends IdTypes>({
  entityConstants,
  treeData,
  handleChange,
  collapse,
  value,
}: RenderTreeProps<IdType>) => {
  const handleChangeWrapper = useCallback(
    (id: IdType) => {
      handleChange(id, treeData);
    },
    [handleChange, treeData]
  );
  return (
    <>
      {treeData.children &&
        Array.isArray(treeData.children) &&
        treeData.children.map((row) => (
          <div key={row.id} style={{ paddingLeft: 11, borderLeft: "1px solid var(--gray-200)" }}>
            <HierarchySelectFormRow
              entityConstants={entityConstants}
              row={row}
              collapse={collapse}
              handleChange={handleChangeWrapper}
              checked={hasValue(value, row.id) || false}
            />
            {row.children && Array.isArray(row.children) && !!row.children.length && (
              <RenderTree
                entityConstants={entityConstants}
                treeData={row}
                handleChange={handleChange}
                collapse={collapse}
                value={value}
              />
            )}
          </div>
        ))}
    </>
  );
};

interface HierarchySelectFormRowProps<IdType extends IdTypes> {
  entityConstants: EntityConstants;
  row: ExtendedEntityHierarchy<IdType>;
  handleChange: (id: IdType, checked: boolean) => void;
  collapse: (id: IdType) => void;
  checked: boolean;
}
const HierarchySelectFormRow = <IdType extends IdTypes>({
  entityConstants,
  row,
  handleChange,
  collapse,
  checked,
}: HierarchySelectFormRowProps<IdType>) => {
  return (
    <div className={`${styles.row} ${checked ? styles.rowChecked : ""}`}>
      {(row.children && Array.isArray(row.children) && !!row.children.length) ||
        (row._children && Array.isArray(row._children) && !!row._children.length && (
          <div className={styles.rowControls} onClick={() => collapse(row.id)}>
            {row.children && Array.isArray(row.children) && !!row.children.length ? (
              <LucideIcon name="chevron-up" />
            ) : (
              <LucideIcon name="chevron-down" />
            )}
          </div>
        ))}

      <div
        className={`${styles.rowLabel} ellipsisContainer`}
        title={row.name}
        onClick={() => handleChange(row.id, !checked)}
      >
        <span>
          <LucideIcon name={entityConstants.icon} color={"var(--primary)"} /> {row.name}
        </span>
        <div style={{ marginLeft: "auto" }}>
          <label className="label label-soft-info" style={{ margin: 0 }}>
            {row.name}
          </label>
        </div>
        <div className={"ellipsisContainer"} style={{ color: "var(--gray-400)", fontSize: "0.75em" }}>
          <span>ID: {row.id}</span>
        </div>
      </div>
      <div className={styles.rowCheckbox}>
        <CheckboxRound checked={checked} onChange={(_, value) => handleChange(row.id, value)} />
      </div>
    </div>
  );
};
