import React, { memo, useCallback, useState } from "react";
import { TextareaFormField } from "../../../formfields/TextareaFormField";
import { MultiEditTextAreaFormFieldProps } from "../common/MultiEditSelectFormTypes";
import { UseFormReturn, FieldValues, FieldError } from "react-hook-form";
import { GenericEntity } from "../../../../api/GenericTypes";
import { checkIsDirty } from "../common/MultiEditUtils";
import { MultiEditFormWrapper } from "../common/MultiEditFormWrapper";

const MemoComponent = memo(
  <Entity extends GenericEntity>({
    register,
    formState,
    getFieldState,
    row,
    id,
    readOnly,
    ...rest
  }: UseFormReturn<FieldValues> & MultiEditTextAreaFormFieldProps<Entity>) => {
    const [_isError, setIsError] = useState<FieldError | undefined>();
    const [_isDirty, setIsDirty] = useState<boolean>(false);

    const onBlur = useCallback(() => {
      setIsDirty(checkIsDirty(formState.dirtyFields?.[row.id]?.[id]));
      setIsError(getFieldState(`${row.id}.${id}`, formState).error);
    }, [formState, getFieldState, id, row.id]);

    const isDirty = checkIsDirty(formState.dirtyFields?.[row.id]?.[id]) ?? _isDirty;
    const isError = getFieldState(`${row.id}.${id}`, formState).error ?? _isError;

    return (
      <TextareaFormField
        {...rest}
        label=""
        id={`${row.id}.${id}`}
        errors={undefined} // errors will be handled at parent level
        register={register}
        style={{
          minHeight: "unset",
          width: "100%",
          height: "100%",
          resize: "none",
          borderRadius: 4,
          border: isError ? "1px solid var(--danger)" : isDirty ? "1px solid var(--success)" : "0px",
          background: readOnly
            ? "var(--gray-100)"
            : isError
            ? "var(--danger-light)"
            : isDirty
            ? "var(--success-light)"
            : "unset",
        }}
        onBlur={onBlur}
        uncontained
      />
    );
  },
  (prevProps, nextProps) =>
    checkIsDirty(prevProps.formState.dirtyFields?.[prevProps.row.id]?.[prevProps.id]) ===
    checkIsDirty(nextProps.formState.dirtyFields?.[nextProps.row.id]?.[nextProps.id])
  // (prevProps, nextProps) => prevProps.formState.isDirty === nextProps.formState.isDirty
);

export const MultiEditTextAreaForm = <Entity extends GenericEntity>(props: MultiEditTextAreaFormFieldProps<Entity>) => {
  return <MultiEditFormWrapper>{(formProps) => <MemoComponent {...formProps} {...props} />}</MultiEditFormWrapper>;
};
