import { useState, useEffect, useCallback } from "react";
import { Prompt } from "react-router-dom";

// Usage:
// {routePrompt} must be embedded into the DOM
// Call "onDirty()" to trigger an unsaved state
// Call "onPristine()" to untrigger an unsaved state

const useUnsavedChangesWarning = (
  message: string = "You have unsaved changes. If you leave before saving, then your changes will be lost. "
) => {
  const [isDirty, setDirty] = useState<boolean>(false);
  useEffect(() => {
    window.onbeforeunload = isDirty ? () => isDirty && !!message : null;
    return () => {
      window.removeEventListener("beforeunload", () => {});
    };
  }, [isDirty, message]);
  const routerPrompt = <Prompt when={isDirty} message={message} />;
  const onDirty = useCallback(() => setDirty(true), []);
  const onPristine = useCallback(() => setDirty(false), []);
  return { routerPrompt, onDirty, onPristine };
};

export default useUnsavedChangesWarning;
