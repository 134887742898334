import React from "react";
import { Link } from "react-router-dom";

interface ConditionalLinkProps extends React.ComponentProps<typeof Link> {
  showLink?: boolean;
  children: React.ReactNode;
}
/**
 * A component that conditionally renders a `Link` component based on the `showLink` prop.
 * If `showLink` is true and the `to` prop is provided, it renders a `Link` with the given props.
 * Otherwise, it renders the children without a link.
 * @author @CorradoSurmanowicz
 * @param {boolean} showLink - Determines whether to render the `Link` component. Defaults to true.
 * @param {React.ReactNode} children - The content to be rendered inside the component.
 * @param {ConditionalLinkProps} props - Additional props to be passed to the `Link` component.
 * @returns {JSX.Element} The rendered `Link` component or the children without a link.
 */
export const ConditionalLink = ({ showLink = true, children, ...props }: ConditionalLinkProps) => {
  return !!showLink && props.to ? (
    <Link {...props}>{children}</Link>
  ) : props.style ? (
    <div style={props.style}>{children}</div>
  ) : (
    <>{children}</>
  );
};
