import { ApiKeyWidget } from "./ApiKeyWidget";
import { KeyManagementCreateForm } from "./KeyManagementCreateForm";
import { motion, AnimatePresence } from "framer-motion";
import SinglePage from "../common/panels/SinglePage/SinglePage";
import { LucideIcon } from "../common/icon/LucideIcon";
import { useContext, useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import { ApiKey, apiKeysConstants } from "../api/ApiKey";
import { TableViewLabel } from "../common/panels/TableView/TableView";
import { SessionContext } from "../common/contexts/SessionContext";
import { ContentTable } from "../common/tables/ContentTable/ContentTable";
import { CopyToClipboardButton } from "../common/buttons/CopyToClipboardButton/CopyToClipboardButton";
import { logsPyConstants } from "../api/Misc";
import { toUppercase } from "../common/helperfunctions/stringFunctions";

interface ApiKeyManagerProps {
  apiKeys: ApiKey[];
  generateApiKey: (name: string, readOnly: boolean) => Promise<void>;
  deleteApiKey: (id: number) => Promise<void>;
  lastGeneratedApiKeyId: ApiKey["id"] | undefined;
}

export const ApiKeyManager = ({ apiKeys, generateApiKey, deleteApiKey, lastGeneratedApiKeyId }: ApiKeyManagerProps) => {
  const [showModal, setShowModal] = useState(false);
  const { api } = useContext(SessionContext);

  const baseUrl = useMemo(() => {
    const { hostname, port, protocol } = api.getUrlComponents();
    return `${protocol}//${hostname}${!!port ? `:${port}` : ""}/${api.group}`;
  }, [api]);

  const apiUrl = useMemo(() => {
    const { hostname, port, protocol } = api.getUrlComponents();
    return `${protocol}//${hostname}${!!port ? `:${port}` : ""}${api.url.slice(0, -1)}`;
  }, [api]);

  return (
    <SinglePage>
      <SinglePage.Head>
        <SinglePage.Head.Label>
          <TableViewLabel entityConstants={apiKeysConstants} resultsCount={apiKeys.length} />
        </SinglePage.Head.Label>
        <SinglePage.Head.Controls>
          <div className="flex align-center gap-5">
            <button className="btn btn-primary" title="Create API-Key" onClick={() => setShowModal(true)}>
              <LucideIcon name="plus" /> Create API-Key
            </button>
          </div>
          <Modal show={showModal} onHide={setShowModal} bsSize="large">
            <Modal.Header>
              <h3 style={{ margin: 0 }}>Create API-Key</h3>
            </Modal.Header>
            <Modal.Body>
              <div
                className="flex col-nowrap form-group center-horizontally"
                style={{
                  width: "100%",
                  height: "100%",
                  justifyContent: "flex-start",
                  padding: "10px 15px",
                }}
              >
                <KeyManagementCreateForm
                  onClose={() => setShowModal(false)}
                  generateApiKey={async (name: string, readOnly: boolean) => {
                    await generateApiKey(name, readOnly);
                    setShowModal(false);
                  }}
                />
              </div>
            </Modal.Body>
          </Modal>
        </SinglePage.Head.Controls>
      </SinglePage.Head>
      <SinglePage.Content>
        <div
          style={{
            display: "grid",
            gridTemplateRows: "max-content 1fr",
            gap: "5px",
            padding: "10px",
            overflow: "hidden",
          }}
        >
          <div style={{ padding: "0 10px" }}>
            <ContentTable
              content={[
                {
                  key: (
                    <div className="flex align-center" style={{ height: "100%" }}>
                      Base URL
                    </div>
                  ),
                  value: (
                    <div className="flex align-center gap-5" style={{ width: "100%" }}>
                      <div style={{ width: "100%", color: "var(--gray-400)" }}>{baseUrl}</div>
                      <CopyToClipboardButton content={baseUrl} />
                    </div>
                  ),
                },
                {
                  key: (
                    <div className="flex align-center" style={{ height: "100%" }}>
                      API URL
                    </div>
                  ),
                  value: (
                    <div className="flex align-center gap-5" style={{ width: "100%" }}>
                      <div style={{ width: "100%", color: "var(--gray-400)" }}>{apiUrl}</div>
                      <CopyToClipboardButton content={apiUrl} />
                    </div>
                  ),
                },
                {
                  key: (
                    <div className="flex align-center" style={{ height: "100%" }}>
                      {toUppercase(logsPyConstants.entitySingular)} documentation
                    </div>
                  ),
                  value: (
                    <div className="flex align-center">
                      <a href="https://docs.logs-python.com" target="_blank" rel="noreferrer">
                        https://docs.logs-python.com
                      </a>
                      <div className="btn btn-default btn-sm" style={{ visibility: "hidden" }}>
                        Placeholder
                        <LucideIcon name="info" />
                      </div>
                    </div>
                  ),
                },
              ]}
            />
          </div>
          <div
            className="flex col-nowrap gap-5"
            style={{
              padding: "20px 5px",
              width: "100%",
              height: "100%",
              overflow: "auto",
              borderTop: "1px solid var(--gray-200)",
            }}
          >
            {Array.isArray(apiKeys) && !!apiKeys.length ? (
              <AnimatePresence initial={false}>
                {apiKeys.map((apiKey) => {
                  return (
                    <motion.div key={apiKey.id} positionTransition exit={{ opacity: 0 }}>
                      <ApiKeyWidget
                        key={apiKey.id}
                        apiKey={apiKey}
                        deleteApiKey={deleteApiKey}
                        isNew={apiKey.id === lastGeneratedApiKeyId}
                      />
                    </motion.div>
                  );
                })}
              </AnimatePresence>
            ) : (
              <div
                className="flex align-center justify-center"
                style={{ width: "100%", height: "100%", color: "var(--gray-300)", fontSize: "2em" }}
              >
                No API-Keys
              </div>
            )}
          </div>
        </div>
      </SinglePage.Content>
    </SinglePage>
  );
};
