import { Button, ButtonGroup, Label } from "react-bootstrap";
import styles from "./Common.module.css";
import { currentPageLabel, nextLabel, pageOneLabel, prevLabel } from "./ConstantLabels";

interface PaginationProps {
  listRecords: any[];
  pageSize: number;
  page: number;
  setPage: (page: number) => void;
}

export const PaginationView = function ({ listRecords, pageSize, page, setPage }: PaginationProps) {
  const previousPage = () => {
    setPage(page - 1);
  };

  const nextPage = () => {
    setPage(page + 1);
  };

  const disablePrePaginationControl = page === 1;
  const disableNextPaginationControl = page === pageSize || listRecords.length === 0;

  return (
    <div className="row">
      <div className="col-xs-12" style={{ display: "flex", flexWrap: "wrap" }}>
        <div style={{ flex: 1, paddingTop: 10 }}>
          <Label bsStyle="success" className={styles.paginationPageText}>
            {currentPageLabel}: {page}
          </Label>
        </div>
        <div style={{ flex: 3 }}>
          <ButtonGroup className={styles.paginationControls}>
            {page !== 1 && (
              <Button onClick={() => setPage(1)}>
                {" "}
                <span> {pageOneLabel}</span>{" "}
              </Button>
            )}
            <Button disabled={disablePrePaginationControl} onClick={previousPage}>
              {prevLabel}{" "}
            </Button>
            <Button disabled={disableNextPaginationControl} onClick={nextPage}>
              {nextLabel}
            </Button>
          </ButtonGroup>
        </div>
        <div style={{ flex: 1 }}>
          <Label className={styles.paginationPageText}></Label>
        </div>
      </div>
    </div>
  );
};
