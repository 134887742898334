import React from "react";
import styles from "../forms/sidebarFormStyles.module.css";
import { LucideIcon } from "../../icon/LucideIcon";

interface EntityFilterBarProps {
  onReset: (e: React.MouseEvent) => void;
  children: React.ReactNode;
  hideReset?: boolean;
}
export const EntityFilterSideBar = ({ onReset, children, hideReset = false }: EntityFilterBarProps) => {
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
      }}
      autoComplete="off"
      className={styles.form_container}
      style={{ width: "max-content" }}
    >
      <div
        className="flex col-nowrap"
        style={{
          // width: "100%",
          // height: "fit-content",
          overflowX: "hidden",
          overflowY: "auto",
          padding: "0px 5px",
          flex: "1 1 100%",
        }}
      >
        {children}
      </div>
      {!hideReset && (
        <div
          style={{
            display: "flex",
            marginTop: "auto",
            justifyContent: "center",
            padding: "5px",
            borderTop: "1px solid var(--gray-200)",
          }}
        >
          <button type="reset" className="btn btn-primary" onClick={onReset}>
            <LucideIcon name="refresh-cw" /> Reset filters
          </button>
        </div>
      )}
    </form>
  );
};
