import { EntityAddForm, EntityAddFormPage } from "../../common/entity/EntityAddForm";
import { EntityEditForm, EntityEditFormPage } from "../../common/entity/EntityEditForm";
import { EntityEditFormProps } from "../../common/entity/EntityInterfaces";
import { LabNotebook } from "../types/LabNotebook";
import { LabNotebookForm } from "./Forms/LabNotebookForm";
import { LabNotebooksTable } from "./Table/LabNotebooksTable";
import { EntityTablePage } from "../../common/entity/EntityTablePage";

// Add
export const LabNotebookAddForm = ({
  onSuccess,
  onCancel,
}: {
  onSuccess?: (labNotebook: LabNotebook) => void;
  onCancel?: () => void;
}) => {
  return (
    <EntityAddForm entityTypeId={"notebooks"} onCancel={onCancel} onSuccess={onSuccess}>
      {(props) => <LabNotebookForm {...props} />}
    </EntityAddForm>
  );
};

export const LabNotebookAddFormPage = () => {
  return (
    <EntityAddFormPage>
      <LabNotebookAddForm />
    </EntityAddFormPage>
  );
};

// Edit
export const LabNotebookEditForm = ({ id, onCancel, onSuccess }: EntityEditFormProps<"notebooks">) => {
  return (
    <EntityEditForm id={id} entityTypeId={"notebooks"} filters={{}} onCancel={onCancel} onSuccess={onSuccess}>
      {(props) => <LabNotebookForm {...props} />}
    </EntityEditForm>
  );
};

export const LabNotebookEditFormPage = () => {
  return <EntityEditFormPage<"notebooks"> isIntId>{(id) => <LabNotebookEditForm id={id} />}</EntityEditFormPage>;
};

export const LabNotebooksTablePage = () => {
  return <EntityTablePage entityTypeId={"notebooks"}>{(props) => <LabNotebooksTable {...props} />}</EntityTablePage>;
};
