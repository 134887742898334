import * as React from "react";
import { useForm } from "react-hook-form";
import { useCallback } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { InputFormField } from "../common/formfields/InputFormField";
import { FormButtons } from "../common/forms/FormButtons";
import { ToggleFormField } from "../common/formfields/ToggleFormField";
import { ApiKey, ApiKeyWriteModel } from "../api/ApiKey";

interface KeyManagementCreateFormProps {
  generateApiKey: (name: string, readOnly: boolean) => Promise<void>;
  onClose: () => void;
}

export const KeyManagementCreateForm = ({ generateApiKey, onClose }: KeyManagementCreateFormProps) => {
  const apiKeyFormSchema = yup.object().shape({
    name: yup
      .string()
      .min(1, "The name for the API key cannot be less than 1 character")
      .max(200, "The name for the API key must not be longer than 200 characters")
      .required("A valid name is required"),
  });

  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
  } = useForm<ApiKey>({
    resolver: yupResolver(apiKeyFormSchema),
  });

  const onSubmit = useCallback(
    async (apikey: ApiKeyWriteModel) => {
      try {
        await generateApiKey(apikey.name, apikey.readOnly);
        reset();
      } catch (e) {
        console.error(e);
      }
    },
    [generateApiKey, reset]
  );

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
      }}
      autoComplete="off"
      className={`form-horizontal`}
    >
      <InputFormField
        id="name"
        label="Name"
        errors={errors}
        register={register}
        autoFocus
        required
        placeholder="Insert name..."
      />

      {/* This input prevents reloading the page on enter. Can be removed after we improve our key inputs on forms */}
      <input type="text" style={{ display: "none" }} />

      <ToggleFormField id="readOnly" label="Read-Only" control={control} horizontal />
      <FormButtons
        groupName="apikeys"
        onClose={onClose}
        onSubmit={handleSubmit(onSubmit)}
        errors={errors}
        submitButtonLabel="Create"
        disabled={isSubmitting}
      />
    </form>
  );
};
