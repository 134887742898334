import React from "react";
import { IGenericEntityPermissions } from "../../permissions/useEntityPermissions";
import { EntityConstants } from "../../../api/GenericTypes";
import { LucideIcon } from "../../icon/LucideIcon";
import { Routes } from "../../routes/useEntityRoutes";
import { useHistory } from "react-router-dom";
import { EntityByEntityTypeId, GenericEntityConstantsEntities } from "../../../api/GenericConstants";

interface EntityTableCloneButtonProps<EntityTypeId extends GenericEntityConstantsEntities> {
  entityConstants: EntityConstants;
  routes: Routes;
  permissions: IGenericEntityPermissions<EntityTypeId>;
  selection: Set<EntityByEntityTypeId<EntityTypeId>["id"]>;
  onClick?: React.DOMAttributes<HTMLButtonElement>["onClick"];
}

/**
 * Component for rendering a button that allows cloning of an entity.
 * @author @CorradoSurmanowicz
 * @template Entity - The type of the entity being cloned.
 * @param {EntityTableCloneButtonProps<EntityTypeId>} props - The properties for the EntityCloneButton component.
 * @param {EntityConstants} props.entityConstants - Constants related to the entity, such as singular and plural names.
 * @param {Routes} props.routes - Object containing route functions for navigation.
 * @param {Permissions} props.permissions - Object containing permission flags.
 * @param {Set<number>} props.selection - Set of selected entity IDs.
 * @param {function} [props.onClick] - Optional click handler for the button.
 * @returns {JSX.Element} The rendered button component.
 */
export const EntityTableCloneButton = <EntityTypeId extends GenericEntityConstantsEntities>({
  entityConstants,
  routes,
  permissions,
  selection,
  onClick,
}: EntityTableCloneButtonProps<EntityTypeId>) => {
  const history = useHistory();
  return (
    <button
      className="btn btn-sm btn-ghost-secondary"
      title={`${
        selection.size === 0
          ? `Select ${entityConstants.entitySingular} to clone`
          : selection.size === 1
          ? `Clone the selected ${entityConstants.entitySingular}`
          : ""
      }`}
      disabled={selection.size !== 1 || !permissions.canCreate}
      onClick={onClick ? onClick : () => history.push(routes.getCloneLink(+Array.from(selection)[0]))}
    >
      <LucideIcon name="copy" /> Clone
    </button>
  );
};
