import { CustomType, CustomTypeFilters, customTypeConstants } from "../../../api/CustomTypes";
import { useEntityDetail, useUnpaginate } from "../../../api/BaseEntityApi";
import { useEffect, useState } from "react";

export interface UseCustomTypesProps {
  entityType: CustomType["entityType"];
  filters?: CustomTypeFilters;
  enabled?: boolean;
}
export const useCustomTypes = ({ entityType, filters, enabled = true }: UseCustomTypesProps) => {
  // We fetch all customTypes of a certain EntityType
  const {
    data: types,
    status,
    isFetched,
    error,
    fetchStatus,
  } = useUnpaginate<CustomType, CustomTypeFilters>(
    customTypeConstants.resource,
    {
      entityTypes: [entityType],
      ...filters,
    },
    { enabled: enabled },
    1000
  );

  return { types, status, isFetched, fetchStatus, error };
};

export interface UseCustomTypeProps {
  typeId?: CustomType["id"] | null;
  filters?: CustomTypeFilters;
  enabled?: boolean;
}
export const useCustomType = ({ typeId, filters, enabled = true }: UseCustomTypeProps) => {
  const [type, setType] = useState<CustomType>();
  const {
    data: _type,
    status,
    fetchStatus,
    error,
  } = useEntityDetail<CustomType, CustomTypeFilters>(customTypeConstants.resource, typeId!, filters, {
    enabled: !!typeId && enabled,
  });

  useEffect(() => {
    if (_type) setType(_type);
    return () => setType(undefined);
  }, [_type]);

  return { type, setType, status, fetchStatus, error };
};
