import React from "react";
import { Resizeable } from "../../../ViewerUIElements/Resizeable";
import styles from "./ShowTraces.module.css";

const componentMarker = /(^ *)(<.*?>)(.*)/;
const replaceSpace = (text: string) => text.replace(/ /g, "\u00a0");

const NewLineText = ({ children }: { children: any }) => {
  if (!Array.isArray(children)) children = [children];

  const list = children.map((c: any) => {
    if (typeof c !== "string") return c;
    const lines = c.split("\n");
    const result: any[] = [];
    lines.forEach((line, i) => {
      const match = componentMarker.exec(line);
      if (match) {
        result.push(replaceSpace(match[1]));
        result.push(<i key={"module" + i}>{replaceSpace(match[2])}</i>);
        result.push(<b key={"error" + i}>{replaceSpace(match[3])}</b>);
      } else {
        result.push(replaceSpace(line));
      }
      result.push(<br key={i} />);
    });

    return result;
  });

  return <span>{list}</span>;
};

const ShowMessages = ({
  errors,
  warnings,
  width,
  height,
}: {
  errors: string[];
  warnings: string[];
  width?: number;
  height?: number;
}) => {
  return (
    <div className={styles.messageSection} style={{ width: width, height: height }}>
      {errors.length > 0 ? <p className={styles.messageHeader}>Errors:</p> : null}
      {errors.map((e, i) => (
        <p key={i} className="bg-danger" style={{ width: width, overflowX: "auto" }}>
          <NewLineText>{e}</NewLineText>
        </p>
      ))}

      {warnings.length > 0 ? <p className={styles.messageHeader}>Warnings:</p> : null}
      {warnings.map((e, i) => (
        <p key={i} className="bg-warning" style={{ width: width, overflowX: "auto" }}>
          <NewLineText>{e}</NewLineText>
        </p>
      ))}
    </div>
  );
};

export const ShowTraces = ({ errors, warnings }: { errors?: string[]; warnings?: string[] }) => {
  return (
    // <div className={styles.show} style={{ paddingBottom: 10 }}>
    <Resizeable className={styles.messageSection}>
      <ShowMessages errors={errors ?? []} warnings={warnings ?? []} />
    </Resizeable>
    // </div>
  );
};
