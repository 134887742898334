import React from "react";
import { EntityConstants, GenericEntity, INamedEntity } from "../../../api/GenericTypes";
import styles from "./GenericEntitySelectRenderer.module.css";
import { LucideIcon } from "../../icon/LucideIcon";
import { toUppercase } from "../../helperfunctions/stringFunctions";
import { NotSet } from "../../misc/UIconstants";

interface GenericEntitySelectRendererProps<Entity extends GenericEntity & INamedEntity> {
  row: Entity;
  entityConstants: EntityConstants;
  onRowStartRenderer?: (row: Entity) => React.ReactNode;
  onRowEndRenderer?: (row: Entity) => React.ReactNode;
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>, row: Entity) => void;
  iconOverride?: (row: Entity) => EntityConstants["icon"];
  onRowNameRendererOverride?: (row: Entity) => React.ReactNode;
  hideId?: boolean;
  selected?: boolean;
  style?: React.CSSProperties;
}

export const GenericEntitySelectRenderer = <Entity extends GenericEntity & INamedEntity>({
  row,
  entityConstants,
  onRowEndRenderer,
  onRowStartRenderer,
  onClick,
  iconOverride,
  onRowNameRendererOverride,
  hideId,
  selected,
  style,
}: GenericEntitySelectRendererProps<Entity>) => {
  return (
    <div
      className={styles.genericEntitySelectRendererContainer}
      onClick={(e) => onClick?.(e, row)}
      title={`${row.name} (${toUppercase(entityConstants.entitySingular)}-ID: ${row.id})`}
      style={{
        backgroundColor: !!selected ? "var(--primary-light)" : undefined,
        ...style,
      }}
    >
      <div className={styles.genericEntitySelectRendererRowLabel}>
        <LucideIcon name={iconOverride ? iconOverride(row) : entityConstants.icon} />
        {onRowStartRenderer?.(row)}
        {onRowNameRendererOverride ? onRowNameRendererOverride(row) : row.name || NotSet}
        <div className={styles.genericEntitySelectRendererRowEnd}>{onRowEndRenderer?.(row)}</div>
      </div>
      {!hideId && (
        <div className={styles.genericEntitySelectRendererRowId}>{`${toUppercase(entityConstants.entitySingular)}-ID: ${
          row.id
        }`}</div>
      )}
    </div>
  );
};
