export const defaultPageSize = 50;
export const rowHeight = 25;
// Sorted A-Z
//labels
export const actionLabel = "Actions";
export const addLabel = "Add";
export const addNewOrganizationLabel = "Add New Organization";
export const addNewPersonLabel = "Add Person";
export const addressLabel = "Address";
export const adminRightsLabel = "Admin Rights";
export const addSampleLabel = "Add Sample";
export const backLabel = "Back";
export const cancelLabel = "Cancel";
export const categoriesLabel = "Categories";
export const filterByCategoryLabel = "Filter by category";
export const clearFilter = "clear Filter";
export const clearLabel = "clear";
export const cloneLabel = "Clone";
export const copyErrorLabel = "Copy error to clipboard";
export const currentPageLabel = "Page";
export const deleteLabel = "Delete";
export const detailsLabel = "Details";
export const downloadDatasetLabel = "Download Dataset";
export const editLabel = "Edit";
export const editOrganizationTitle = "edit organization";
export const editPersonTitle = "edit person,";
export const emailLabel = "Email address";
export const enterNameLabel = "Enter a name";
export const failedLabel = "Failed";
export const faxLabel = "Fax";
export const filterNameTitle = "Filter the person list based on last name";
export const firstNameLabel = "First Name";
export const headerLabel = "Parser Status And Error Page";
export const lastNameLabel = "Last Name";
export const logsAccountLabel = "LOGS Account";
export const nameLabel = "Name";
export const nextLabel = "Next";
export const noEntriesFoundLabel = "No Entries Found";
export const notFoundErrorText = "The Error text is not found";
export const NotFoundPageText = "Not Found (404) ";
export const notesLabel = "Notes";
export const noticeLabel = "Notice";
export const officePhoneLabel = "Office Phone";
export const organizationLabel = "Organization";
export const organizationInformationLabel = "Organization Information";
export const organizationsLabel = "Organizations";
export const pageOneLabel = "Page 1";
export const parserStatusErrorsHeader = "Parsing Errors";
export const personLabel = "Person";
export const personInformationLabel = "Person Information";
export const personsLabel = "Persons";
export const phoneLabel = "Phone";
export const prevLabel = "Prev";
export const privateAddressLabel = "Private Address";
export const queueLabel = "In Queue";
export const requiredLabel = "required";
export const rolesLabel = "Roles";
export const rowClass = "row";
export const salutationLabel = "Salutation";
export const saveLabel = "Save";
export const selectedItemsText = "items are selected.";
export const selectLabel = "Select a category";
export const somethingWentWrongLabel = "Something went wrong";
export const successLabel = "Successful";
export const sumKey = "all";
export const titleLabelForCopyBtn = "You can copy the error text to clipboard";
export const titleLabelForDownloadBtn =
  "the downloaded file is supposed to be a zip file format of dataset from a different API";
export const typeLabel = "Type";
export const versionLabel = "Data Format Version";
export const viewDatasetDetails = "view the dataset details in logs portal";
export const viewMoreDetailsPersonTitle = "view more details of person";
export const viewMoreDetailsOrganizationTitle = "view more details of organization";
export const webLabel = "Web";
export const workAddressLabel = "Work Address";
// Ids
export const datasetIdLabel = "Dataset Id";
export const filterElementId = "filterElementId";
export const parserIDLabel = "Parser Type";
export const parserStatusErrorSectionId = "parserStatusErrorSection";
//Keys
export const shiftKeyCode = 16;
export const selectedObjectLabelKey = "label";
export const selectedObjectValueKey = "value";
// groups
export const parserErrorsDataGroup = "parser_errors";
export const parserStatisticsDataGroup = "parser_statistics";
// bootstrap styles
export const defaultButtonStyle = "btn btn-default btn-block pull-right";
export const actionButtonDivStyle = "pull-right";
export const col12Style = "col-xs-12";
export const failedCardStyle = "col-md-4 col-xs-12 text-center text-danger";
export const filterGroupStyle = "col-lg-4 col-md-6 col-sm-12 col-xs-12 pull-right";
export const formControlButtonsStyle = "col-lg-6 col-sm-4 col-xs-6 pull-right";
export const formControlButtonsDivStyle = "col-lg-4 col-md-6 col-xs-12";
export const formMainContentDivStyle = "col-lg-6 col-xs-12";
export const formTitleDivStyle = "col-lg-8 col-md-6 col-xs-12";
export const notYetParsedCardStyle = "col-md-4 col-xs-12 text-center text-info";
export const offsetFormDivStyle = "col-lg-3 col-xs-12";
export const parserErrorStyle = "col-xl-1 col-lg-3 col-md-3 col-sm-6 col-xs-12";
export const selectedRowsTitleDivStyle = "col-lg-8 col-md-9 col-xs-12";
export const successCardStyle = "col-md-4 col-xs-12 text-center text-success";
