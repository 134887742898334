import { useCallback, useEffect, useMemo, useState } from "react";
import { Badge, Table } from "react-bootstrap";
import { LucideIcon } from "../../common/icon/LucideIcon";
import { d3DataType, SelectedState } from "../Dashboard";
// import { Mainchart } from "./MainChart"

import styles from "./Stats.module.css";

interface Props {
  d3Data: d3DataType;
  type: string;
  selection: SelectedState;
}

export const StatsTable = ({ d3Data, type, selection }: Props) => {
  const [data, setData] = useState<any>([]);
  const [sum, setSum] = useState(0);

  const count = (array: any[], key: string | number) => {
    return array.reduce((r, a) => r + a[key], 0);
  };

  // Glyphicons for table sort
  // const sortdefault = "sort"
  // const sortup = "arrow-up"
  // const sortdown = "arrow-down"

  const sortdefault = "code";
  const sortup = "chevron-down";
  const sortdown = "chevron-up";

  useEffect(() => {
    if (d3Data.data) {
      let this_data: any = [];
      const active_targets = Object.keys(selection).filter((d) => selection[d] === true);
      const methods = d3Data.data.map((d) => d.subgroups);
      // setDays(methods.length)
      const subgroups = new Set(d3Data.data.map((d) => Object.keys(d.subgroups)).flat(1));
      // let index = 0
      subgroups.forEach((name: string) => {
        const this_datasets = count(methods, name);
        if (Array.isArray(active_targets) && active_targets.length) {
          if (active_targets.includes(name)) {
            this_datasets && this_data.push({ name: name, datasets: this_datasets });
          }
        } else {
          this_datasets && this_data.push({ name: name, datasets: this_datasets });
        }
      });
      setData(this_data.sort((a: any, b: any) => a.datasets < b.datasets));
      setSum(this_data.map((d: any) => d.datasets).reduce((a: number, b: number) => a + b, 0));
    }
  }, [d3Data, selection]);

  type SortConfig = { key: string; direction: "code" | "chevron-down" | "chevron-up" };
  const useSortableData = (items: any, config = null) => {
    const [sortConfig, setSortConfig] = useState<SortConfig | null>(config);

    const sortedItems = useMemo(() => {
      let sortableItems = [...items];
      if (sortConfig !== null) {
        sortableItems.sort((a, b) => {
          if (a[sortConfig.key] < b[sortConfig.key]) {
            return sortConfig.direction === sortdown ? -1 : 1;
          }
          if (a[sortConfig.key] > b[sortConfig.key]) {
            return sortConfig.direction === sortdown ? 1 : -1;
          }
          return 0;
        });
      }
      return sortableItems;
    }, [items, sortConfig]);

    const requestSort = useCallback(
      (key: string) => {
        let direction: "code" | "chevron-down" | "chevron-up" = sortdown;
        if (sortConfig && sortConfig.key === key && sortConfig.direction === sortdown) {
          direction = sortup;
        }
        setSortConfig({ key, direction });
      },
      [sortConfig]
    );

    return { items: sortedItems, requestSort, sortConfig };
  };

  const { items, requestSort, sortConfig } = useSortableData(data);

  const getClassNamesFor = useCallback(
    (name: string): "code" | "chevron-up" | "chevron-down" => {
      if (!sortConfig) {
        return sortdefault;
      }
      return sortConfig.key === name ? sortConfig.direction : sortdefault;
    },
    [sortConfig]
  );

  return (
    <Table responsive striped condensed hover className={styles.statstable}>
      <thead>
        <tr>
          <th onClick={() => requestSort("name")}>
            {type} <Badge>{data.length}</Badge>{" "}
            <LucideIcon
              name={getClassNamesFor("name")}
              style={{
                ...(getClassNamesFor("name") === "code" && { transform: "rotate(90deg)" }),
                color: "var(--gray-300)",
              }}
            />
          </th>
          <th onClick={() => requestSort("datasets")}>
            Datasets
            <Badge>{data.map((d: any) => d.datasets).reduce((pv: any, cv: any) => pv + cv, 0)}</Badge>{" "}
            <LucideIcon
              name={getClassNamesFor("datasets")}
              style={{
                ...(getClassNamesFor("datasets") === "code" && { transform: "rotate(90deg)" }),
                color: "var(--gray-300)",
              }}
            />
          </th>
          <th onClick={() => requestSort("datasets")}>%</th>
        </tr>
      </thead>
      <tbody>
        {items.map((d: any, i: number) => (
          <tr key={i}>
            <td>{d.name}</td>
            <td>{d.datasets}</td>
            <td>{d.datasets ? ((d.datasets * 100) / sum).toFixed(1) : 0}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};
