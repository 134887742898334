import {
  Organization,
  OrganizationFilters,
  OrganizationFiltersTranslator,
  OrganizationSortingOptionsConsts,
  organizationsConstants,
} from "../../../api/Organizations";
import { EntitySidebarTile, EntitySidebarTileProps } from "./EntitySidebarTile/EntitySidebarTile";
import { DateTimeRenderer } from "../../datetime/DateTimeFormatter";
import { NotSet } from "../../misc/UIconstants";
import { GetPersons } from "../../misc/EntityRenders/EntityRenderer";

export const OrganizationsTile = (props: EntitySidebarTileProps<Organization, OrganizationFilters>) => (
  <EntitySidebarTile<Organization, OrganizationFilters>
    {...props}
    entityConstants={organizationsConstants}
    orderByOptions={OrganizationSortingOptionsConsts}
    translatorConsts={OrganizationFiltersTranslator}
    defaultFilters={{ orderBy: "NAME_ASC", ...props.defaultFilters }}
    rowLabel={(e) => <>{e.name}</>}
    rowDate={(e) => (
      <>{e.createdOn ? <DateTimeRenderer date={e.createdOn} includeElapsed={false} includeTime={false} /> : NotSet}</>
    )}
    rowInfo={(e) => <>{e.createdBy ? <GetPersons persons={e.createdBy} createLinks={false} /> : NotSet}</>}
  />
);
