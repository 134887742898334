import React, { CSSProperties } from "react";
// import { noEntriesFoundLabel } from "./ConstantLabels";
// import styles from "./Common.module.css";
// import { FeatherIcon } from "./icon/FeatherIcon";
import { Alert } from "./overlays/Alert/Alert";

export const NoEntriesFound = ({
  message = "No entries found",
  style,
}: {
  message?: string;
  style?: CSSProperties;
}) => {
  return (
    // <div>
    //   <div className={styles.noEntries}>
    //     <h3 className="text-center">
    //       <FeatherIcon name="thumbs-down" /> {noEntriesFoundLabel}{" "}
    //     </h3>
    //   </div>
    // </div>
    <div style={{ padding: "5px 5px", ...style }}>
      <Alert type="secondary" message={message} fit centered style={{ margin: 0 }} />
    </div>
  );
};
