import { generateUid } from "../../tools/UID/UID";
import { commandTypes, parameterTypes, trackListType } from "../PipelineTypes";
import { Empty } from "./Empty";

// type parameterType = {
//   track: (tracks: trackListType) => trackListElement;
//   before: boolean;
//   allways: boolean;
// };

type replacerResultType = { after: string | undefined; replace: string | undefined; tracks: trackListType };

export class TrackAdder extends Empty {
  id: string;
  name: string;
  readonly type = commandTypes.trackAdder;

  parameterTypes = {
    track: parameterTypes.trackSelection,
    before: parameterTypes.boolean,
    allways: parameterTypes.boolean,
  };

  parameterSettings = {
    track: { name: "Add before/after track", hidden: false },
    allways: { name: "Add even if track not found" },
    before: { name: "Add before track", hidden: true },
  };

  tracks: trackListType;
  replacedTrackIds: Set<string>;

  constructor() {
    super();
    this.id = generateUid();
    this.name = "Track adder";
    this.tracks = [];
    this.replacedTrackIds = new Set<string>();
  }

  run(tracks: trackListType): trackListType {
    if (tracks.length < 1)
      this.warnings.create({
        id: this.id,
        component: "TrackReplacer.run",
        message: `No input tracks.`,
      });

    this.tracks = tracks.slice();
    return tracks;
  }

  runReplacer(tracks: trackListType): replacerResultType {
    const result: replacerResultType = { after: undefined, replace: undefined, tracks: [] };
    // if (this.tracks.length < 1) return result;

    // const param = this.parameter as parameterType;
    // // const { track, before, allwaysAdd, match }: parameterType = this.parameter as parameterType;
    // const ids = tracks.map((t) => t?.id ?? "");
    // const trackOder = Object.fromEntries(tracks.map((t, i) => [t?.id ?? "", i]));

    // // const names = tracks.map((t) => t?.label ?? "");
    // result.after = param.track(tracks.filter((t) => t && !t.generated))?.id;
    // // console.log(
    // //   "tracks",
    // //   tracks.map((t) => t?.label + ":" + t?.id)
    // // );
    // // console.log("after", result.replace?.label + ":" + result.replace?.id);
    // let index = param.before ? -1 : tracks.length - 1;
    // if (!result.after) {
    //   if (!param.allways) return result;
    // } else {
    //   index = trackOder[result.after];
    // }

    // // const next = tracks?.[index + 1];
    // // if (next) {
    // //   const t = param.match([next]);
    // //   result.replace = t;
    // // }

    // result.replace = tracks.filter((t) => t?.id && this.replacedTrackIds.has(t?.id)).shift()?.id;
    // this.tracks.forEach((t) => {
    //   if (t) {
    //     if (!this.replacedTrackIds.has(t.id)) t.id = generateUid();
    //     this.replacedTrackIds.add(t.id);
    //   }
    // });

    // result.tracks = this.tracks;
    // // console.log("tracks", next?.label);

    // // return this.parameter?.trackList(tracks) ?? [];

    // // return this.parameter?.trackList.value(tracks) ?? [];
    // // console.log("result", result);
    return result;
  }
}
