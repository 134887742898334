import { extension, InputRule, PlainExtension, plainInputRule } from "@remirror/core";

export interface ShortcutsOptions {}

const SHORTCUTS: Array<[RegExp, string]> = [
  // Dash
  [/--$/, "—"],
  // ellipsis
  [/\.{3}$/, "…"],
  // leftArrow
  [/<-$/, "←"],
  // rightArrow
  [/->$/, "→"],
  // left/right arrow
  // First "<-" gets replaced by left arrow; after typing ">", both get replaced by left/right arrow
  [/←>$/, "↔"],
  // copyright
  [/\(c\)$/, "©"],
  // trademark
  [/\(tm\)$/, "™"],
  // registeredTrademark
  [/\(r\)$/, "®"],
  // oneHalf
  [/1\/2$/, "½"],
  // plusMinus
  [/\+\/-$/, "±"],
  // notEqual
  [/!=$/, "≠"],
  // equivalent
  [/=\^$/, "≙"],
  // laquo
  [/<<$/, "«"],
  // raquo
  [/>>$/, "»"],
  // superscriptTwo
  [/\^2$/, "²"],
  // superscriptThree
  [/\^3$/, "³"],
  // oneQuarter
  [/1\/4$/, "¼"],
  // threeQuarters
  [/3\/4$/, "¾"],
];

@extension<ShortcutsOptions>({
  defaultOptions: {},
})
export class CustomShortcutsExtension extends PlainExtension<ShortcutsOptions> {
  get name() {
    return "shortcuts" as const;
  }

  createInputRules(): InputRule[] {
    return SHORTCUTS.map(([regexp, replace]) =>
      plainInputRule({
        regexp,
        transformMatch: () => replace,
      })
    );
  }
}

declare global {
  namespace Remirror {
    interface AllExtensions {
      template: CustomShortcutsExtension;
    }
  }
}
