import React, { useEffect, useState } from "react";

import { PipelineRunningState, range, SerializedPipelineConnector } from "../../ProcessingPipeline/PipelineTypes";

import styles from "./PipelineConnectorComponent.module.css";

const maxConnect = 9;

const SingleConnect = React.memo(() => {
  return (
    <div className={styles.singleConnectMain}>
      <div className={styles.singleConnect} />
      <div className={styles.singleConnectPoint} />
    </div>
  );
});

const FullConnect = React.memo(() => {
  return (
    <div className={styles.fullConnectMain}>
      <div className={styles.fullConnect}></div>
      <div className={styles.fullConnectPoint} />
    </div>
  );
});

export const PipelineConnectorComponent = ({
  node,
  state,
  warnings,
  errors,
}: {
  node: SerializedPipelineConnector;
  warnings: string[];
  errors: string[];
  state?: PipelineRunningState;
}) => {
  const [connect, setConnect] = useState<any>();
  const [showState, setShowState] = useState<any>();

  useEffect(() => {
    if (errors.length > 0) setShowState(styles.errorColor);
    else if (warnings.length > 0) setShowState(styles.warningColor);
    else setShowState(undefined);
  }, [warnings, errors]);

  useEffect(() => {
    // if (node.outputNumber) setConnect(range(node.outputNumber).map((i) => <div key={i} className={styles.singleConnect} />));
    if (node.outputNumber)
      setConnect(
        range(node.outputNumber > maxConnect ? maxConnect : node.outputNumber).map((i) => (
          // <div key={i} className={styles.singleConnect} />
          <SingleConnect key={i} />
        ))
      );
    else setConnect(<FullConnect />);
    // setConnect(<div className={styles.fullConnect}></div>);
  }, [node.inputNumber, node.outputNumber]);

  return (
    <div className={styles.main}>
      {showState ? <div className={styles.warning} /> : null}
      {connect}
      {showState ? (
        <div className={styles.warning}>
          <span className={"glyphicon glyphicon-alert " + showState} />
        </div>
      ) : null}
    </div>
  );
};
