// ###########################################################################################################

import { IconNames } from "../common/icon/LucideIcon";
import { ResourceName, RouteKey } from "../main/Routing";
import { CustomFieldDataType } from "./CustomFields";
import { CustomType } from "./CustomTypes";

// Utility types
export type Nullable<T> = { [K in keyof T]: T[K] | null }; // All property values can be null
export type ValueOf<T> = T[keyof T]; // Value Of something
export type IsRequired<T, K extends keyof T> = Exclude<T, K> & { [key in K]-?: Required<T[key]> }; // Make specific properties required
export type RequireKeys<T extends object, K extends keyof T> = Required<Pick<T, K>> & Omit<T, K>; // Make keys required e.g. RequireKeys<Dataset, "id" | "name">
export type DefinedPropertyObject<T extends StringIndexedDict> = { [key in keyof RequireKeys<T, keyof T>]: T[key] }; // Make all properties (keys) of an object required
export type RequiredKeyAndValue<T, K extends keyof T> = T & { [P in K]-?: NonNullable<T[P]> }; // Make a specific key and its value required

export interface StringIndexedDict<T = any> {
  [property: string]: T;
}
export interface IntIndexedDict<T = any> {
  [property: number]: T;
}
// ###########################################################################################################
// Entity building blocks
export type IdTypes = string | number;
export type IdType<T extends IdTypes> = T;

export interface GenericEntity {
  id: IdTypes;
}
export interface IGenericEntity<T extends IdTypes = number> {
  id: IdType<T>;
}

export type GenericIdEntity = IGenericEntity<GenericEntity["id"]>;

export interface INamedEntity {
  name: string;
}
export interface IEntityWithCreationDate {
  createdAt: string;
}
export interface IPermissionedEntity {
  permissions?: IEntityPermissions;
}
export interface IEntityPermissions {
  edit?: boolean;
  delete?: boolean;
}
export interface IRelation {
  count: number;
  link?: string;
}
export interface IRelationModel {
  [property: string]: IRelation;
}
export type InferRelations<T> = T extends IRelationModel
  ? { [K in keyof T as string extends K ? never : K]: T[K] }
  : never;

export type IModelWithIntId = IGenericEntity<number>;
export type IModelWithStringId = IGenericEntity<string>;
export type IConfigFile = IGenericEntity<string>;

export interface IEntityMinimalModel<T extends IdTypes = number> {
  id: IdType<T>;
  name: string;
  version?: number;
  uid?: string;
  link?: string;
}
export interface TypedEntitySuggestionsModel<T extends IdTypes = number> extends IEntityMinimalModel<T> {
  customType: IEntityMinimalModel<number> | null;
}
export interface IProjectBasedResponseModel {
  projects: IEntityMinimalModel[];
}
export interface IOwnedEntity {
  owner: IEntityMinimalModel<number> | null;
}
export interface IRelatedEntity<T extends IRelationModel> {
  relations?: InferRelations<T>;
}
// Deprecated
// export interface ICustomSchemaEntity {
//   type: IEntityMinimalModel<string> | null;
// }
// Deprecated
// export interface ICustomFieldsEntity {
//   customFields?: StringIndexedDict;
// }

export interface ICustomTypedEntity {
  customType: IEntityMinimalModel<CustomType["id"]> | null;
  customFields?: StringIndexedDict; // This a purely synthetic frontend property that is generated on the fly
  customValues?: CustomFieldMapModel[];
}

export interface IHierarchyTypeRead {
  inventoryName?: string | null;
  isHierarchyRoot?: boolean | null;
  rootHierarchy?: IEntityMinimalModel<CustomType["id"]> | null;
  parentTypes?: IEntityMinimalModel<CustomType["id"]>[] | null;
}

export interface CustomFieldMapModel {
  id?: number;
  name: string;
  type: CustomFieldMapModelType;
  content?: CustomFieldMapModel[];
  dataType?: CustomFieldDataType;
  value?: any;
}

type CustomFieldMapModelType = "CustomField" | "CustomTypeSection";

export interface ICreatedOn {
  createdOn: string;
}
export interface ICreatedBy {
  createdBy: IEntityMinimalModel;
}
export type ICreationRecord = ICreatedOn & ICreatedBy;

export interface IModifiedOn {
  modifiedOn: string;
}
export interface IModifiedBy {
  modifiedBy: IEntityMinimalModel;
}
export type IModificationRecord = IModifiedOn & IModifiedBy;
export interface IVersionedEntity {
  version: number;
}
export interface IVersionModel {
  originalId: number;
  version: number;
}
export interface IConcurrencyToken<T> {
  concurrencyToken?: T | null;
}
export interface ISoftDeletable {
  isDeleted: boolean;
}
export interface IUniqueEntity<T extends IdTypes = number> {
  uid: string;
  origin?: IEntityMinimalModel<T>;
}

export interface IHierarchyRead {
  ancestors: IEntityMinimalModel[];
  parent: IEntityMinimalModel;
}

// ###########################################################################################################
// API response types
export interface IResultModel<T> {
  results: T[];
  count?: number;
}
export interface IPagination<T> extends IResultModel<T> {
  hasNext: boolean;
}

export interface IBulkPermissionResponse<T extends IdTypes = number> extends Required<IPermissionedEntity> {
  id: IdType<T>;
}

// ###########################################################################################################
// API query filter interfaces
export const DefaultOrderTypeConsts = ["ID_ASC", "ID_DESC", "NAME_ASC", "NAME_DESC"] as const;
export type DefaultOrderType = (typeof DefaultOrderTypeConsts)[number];

export const DefaultOwnerOrderTypeConsts = ["OWNER_ASC", "OWNER_DESC"] as const;
export type DefaultOwnerOrderType = (typeof DefaultOwnerOrderTypeConsts)[number];

export const DefaultCustomTypeOrderTypeConsts = ["TYPE_ASC", "TYPE_DESC"] as const;
export type DefaultCustomTypeOrderType = (typeof DefaultCustomTypeOrderTypeConsts)[number];

export const DefaultCreatedOnRecordOrderTypeConsts = ["CREATED_ON_ASC", "CREATED_ON_DESC"] as const;
export type DefaultCreatedOnRecordOrderType = (typeof DefaultCreatedOnRecordOrderTypeConsts)[number];

export const DefaultCreatedByRecordOrderTypeConsts = ["CREATED_BY_ASC", "CREATED_BY_DESC"] as const;
export type DefaultCreatedByRecordOrderType = (typeof DefaultCreatedByRecordOrderTypeConsts)[number];

export const DefaultModifiedOnRecordOrderTypeConsts = ["MODIFIED_ON_ASC", "MODIFIED_ON_DESC"] as const;
export type DefaultModifiedOnRecordOrderType = (typeof DefaultModifiedOnRecordOrderTypeConsts)[number];

export const DefaultModifiedByRecordOrderTypeConsts = ["MODIFIED_BY_ASC", "MODIFIED_BY_DESC"] as const;
export type DefaultModifiedByRecordOrderType = (typeof DefaultModifiedByRecordOrderTypeConsts)[number];

export const DefaultVersionedOrderTypeConsts = ["VERSION_ASC", "VERSION_DESC"] as const;
export type DefaultVersionedOrderType = (typeof DefaultVersionedOrderTypeConsts)[number];

export const DefaultHierarchyOrderTypeConsts = [
  "NUMBER_OF_ITEMS_ASC",
  "NUMBER_OF_ITEMS_DESC",
  "INVENTORY_NAME_ASC",
  "INVENTORY_NAME_DESC",
  "LAYOUT_ASC",
  "LAYOUT_DESC",
] as const;
export type DefaultHierarchyOrderType = (typeof DefaultHierarchyOrderTypeConsts)[number];

export interface IPaginationParameters {
  page?: number;
  pageSize?: number;
}

export interface IOwnedEntityParameters {
  ownerIds?: number[] | null;
}

export interface INamedEntityParameters {
  names?: string[] | null;
}
export interface IProjectBasedParameters {
  projectIds?: number[] | null;
}
export interface ISearchTermParameters {
  searchTerm?: string | null;
  searchTermIncludeIds?: boolean | null;
  searchTermIncludeUuids?: boolean | null;
  searchTermIncludeNames?: boolean | null;
}

export interface IFilterParameter<IdTypes> extends ISearchTermParameters {
  ids?: IdTypes[] | null;
  excludeIds?: IdTypes[];
}

export interface IListRequestParameters<OrderType = ""> {
  orderBy?: DefaultOrderType | OrderType | null;
  includeCount?: boolean;
}
export interface IGenericRequestParameters<Entity extends GenericEntity, OrderType = "">
  extends IFilterParameter<Entity["id"]>,
    IListRequestParameters<OrderType> {
  name?: string | null; // Not sure how to implement this one, as we cannot derive it from inheritage of INamedEntity
}
export interface ITypedEntityParameters {
  customFieldIds?: number[] | null;
  customTypeIds?: number[] | null;
}

export interface ITypedEntityCustomValuesSearchParameters<
  CustomFieldValuesSearchParameters extends CustomFieldValuesSearchParametersBase
> extends ITypedEntityParameters,
    ICustomFieldValuesSearchParameters<CustomFieldValuesSearchParameters> {
  customFieldValues?: CustomFieldValuesSearchParameters[] | null;
}

export interface ICustomFieldValuesSearchParameters<
  CustomFieldValuesSearchParameters extends CustomFieldValuesSearchParametersBase
> {
  customFieldValues?: CustomFieldValuesSearchParameters[] | null;
}

export interface CustomFieldValuesSearchParametersITypedEntity extends CustomFieldValuesSearchParametersBase {
  entityIds?: number[] | null;
}
export interface CustomFieldValuesSearchParametersCustomFields extends CustomFieldValuesSearchParametersBase {
  sampleIds?: number[] | null;
  datasetIds?: number[] | null;
  projectIds?: number | null;
  personIds?: number[] | null;
  inventoryIds?: number[] | null;
  facilityIds?: number[] | null;
}
export interface CustomFieldValuesSearchParametersBase {
  values: any[];
  dataType: CustomFieldDataType;
  customFieldIds?: number[] | null;
  predicate?: "AND" | "OR";
}

export interface IRelationParameters {
  includeRelations?: boolean;
  includeRelationLink?: boolean;
  includeRelationCount?: boolean;
}

export interface ICreatedRecordParameters {
  createdFrom?: string | null;
  createdTo?: string | null;
  createdByIds?: number[] | null;
}

export interface IModifiedRecordParameters {
  modifiedFrom?: string | null;
  modifiedTo?: string | null;
  modifiedByIds?: number[] | null;
}

export interface IVersionedEntityParameters {
  original_ids?: number[];
  verions?: number[];
}

export interface ISoftDeletableFilterParameters {
  includeSoftDeleted?: boolean | null;
  isSoftDeleted?: boolean | null;
}

export interface IHierarchyParameters {
  childrenOfParentIds?: number[] | null;
  descendantsOfIds?: number[] | null;
  excludeHierarchyChildren?: boolean | null;
  isHierarchyRoot?: boolean | null;
  inventoryIds?: number[] | null;
}

// ###########################################################################################################
// Pure frontend interfaces
export interface EntityConstantsBase {
  entitySingular: string;
  entityPlural: string;
  icon: IconNames;
}

// We use the generic type to allow for type inference
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export interface EntityConstants<Entity extends any = {}, Filters extends any = {}> extends EntityConstantsBase {
  resource: ResourceName;
  frontendIndexRoute: RouteKey;
}
