import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useForm, useFormContext, useWatch } from "react-hook-form";
import { Dataset, DatasetFilters, datasetsConstants } from "../../api/Datasets";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Nullable, StringIndexedDict } from "../../api/GenericTypes";
import { SessionContext } from "../../common/contexts/SessionContext";
import { LucideIcon } from "../../common/icon/LucideIcon";
import { useHistory } from "react-router-dom";
import { getBulkEditRoute } from "../../main/Routing";
import { FormErrors } from "../../common/forms/FormButtons";
import { MultiEditMappedItemObject } from "../../common/forms/MultiEditForms/common/MultiEditProvider";
import {
  checkForUnassignedValue,
  getValueOrDefault,
  useMultiEditUtils,
} from "../../common/forms/MultiEditForms/common/MultiEditUtils";
import { showtoast } from "../../common/overlays/Toasts/showtoast";
import { MultiEditErrorContainer } from "../../common/forms/MultiEditForms/common/MultiEditRenderUtils";
import {
  BulkEditDropDownWrapperProps,
  BulkEditLocationState,
  BulkEditDropDownProps,
} from "../../common/tables/MultiEdit/BulkEditDropdown/BulkEditDropDownTypes";
import { BulkEditDropDownWrapper } from "../../common/tables/MultiEdit/BulkEditDropdown/BulkEditDropDownWrapper";

import { SamplesVirtualizedSelectForm } from "../../common/forms/EntityForms/formsVirtualized/SamplesVirtualizedSelectForm";
import { ProjectsVirtualizedSelectForm } from "../../common/forms/EntityForms/formsVirtualized/ProjectsVirtualizedSelectForm";
import { OrganizationsVirtualizedSelectForm } from "../../common/forms/EntityForms/formsVirtualized/OrganizationsVirtualizedSelectForm";
import { PersonsVirtualizedSelectForm } from "../../common/forms/EntityForms/formsVirtualized/PersonsVirtualizedSelectForm";
import { MethodsVirtualizedSelectForm } from "../../common/forms/EntityForms/formsVirtualized/MethodsVirtualizedSelectForm";
import { InstrumentsVirtualizedSelectForm } from "../../common/forms/EntityForms/formsVirtualized/InstrumentsVirtualizedSelectForm";
import { ExperimentsVirtualizedSelectForm } from "../../common/forms/EntityForms/formsVirtualized/ExperimentsVirtualizedSelectForm";
import { EquipmentsVirtualizedSelectForm } from "../../common/forms/EntityForms/formsVirtualized/EquipmentsVirtualizedSelectForm";
import { InputFormField } from "../../common/formfields/InputFormField";
import { Sample, SampleFilters, samplesConstants } from "../../api/Samples";
import { useEntityDetail } from "../../api/BaseEntityApi";
import { Person, PersonFilters, personsConstants } from "../../api/Person";
import { AlertModal } from "../../common/modals/AlertModal/AlertModal";
import { useCustomTypes } from "../../Customization/CustomTypes/generics/useCustomTypes";
import {
  processValuesForTypedEntity,
  useCustomFieldDefaultValues,
} from "../../Customization/CustomTypes/generics/CustomTypeValidationUtils";
import { CustomTypesVirtualizedSelectForm } from "../../common/forms/EntityForms/formsVirtualized/CustomTypesVirtualizedSelectForm";
import { CustomTypeFormRenderer } from "../../Customization/CustomTypes/generics/CustomTypeFormRenderer";
import { Button } from "../../common/buttons/Button/Button";
import { CustomType } from "../../api/CustomTypes";

export const DatasetsBulkEditDropDownWrapper = ({
  selection,
  onSuccess,
  onClose,
  filters,
  entityConstants,
}: BulkEditDropDownWrapperProps<Dataset, DatasetFilters>) => {
  return (
    <BulkEditDropDownWrapper<Dataset, DatasetFilters>
      selection={selection}
      onSuccess={onSuccess}
      onClose={onClose}
      filters={filters}
      entityConstants={entityConstants}
    >
      {(props) => <DatasetsBulkEditDropDown {...props} />}
    </BulkEditDropDownWrapper>
  );
};

const DatasetsBulkEditDropDown = ({
  entityConstants,
  items,
  consolidatedValues,
  selection,
  filters,
  onSuccess,
  onClose,
}: BulkEditDropDownProps<Dataset, DatasetFilters>) => {
  const [showAlertModal, setShowAlertModal] = useState(false);
  const [showClaimAlertModal, setShowClaimAlertModal] = useState(false);

  const {
    handleSubmit,
    reset,
    formState: { isSubmitting, errors },
  } = useFormContext<MultiEditMappedItemObject<Dataset>>();

  const history = useHistory();
  const { session, route } = useContext(SessionContext);
  // const { schemas, type, setType } = useCustomSchemas<DatasetType, DatasetTypeFilters>({
  //   entityConstants: datasetTypesConstants,
  // });

  const { types } = useCustomTypes({ entityType: "Dataset" });
  const [type, setType] = useState<CustomType>();

  const { customFieldPlaceholders, defaultValues, conditionalTypeFormSchema } = useCustomFieldDefaultValues<Dataset>(
    items,
    consolidatedValues,
    type
  );
  const isRequired = useRef(false);

  useEffect(() => {
    if (errors && !!Object.keys(errors).length) {
      showtoast(
        "error",
        <MultiEditErrorContainer errors={errors} entityDescSingular={entityConstants.entitySingular} />
      );
    }
  }, [entityConstants.entitySingular, errors]);

  const { onSubmit, loading } = useMultiEditUtils<Dataset>({
    entityConstants: entityConstants,
    showToast: true,
    onSuccessCallback: onSuccess,
  });

  const conditionalDatasetClaimFormSchema = useMemo(
    () => ({
      projects: yup.array().when([], () =>
        consolidatedValues.projects?.isEqual === false &&
        consolidatedValues.projects?.value === null &&
        consolidatedValues.projects?.hasEmpty === false
          ? yup.array().nullable().notRequired()
          : yup
              .array()
              .of(
                yup.object().shape({ id: yup.number().required().positive().integer(), name: yup.string().required() })
              )
              .min(1, "At least one project is required")
              .typeError(
                !!consolidatedValues.projects?.hasEmpty
                  ? "At least one item is missing a project"
                  : "At least one project is required"
              )
      ),
      operators: yup.array().when([], () =>
        consolidatedValues.operators?.isEqual === false &&
        consolidatedValues.operators?.value === null &&
        consolidatedValues.operators?.hasEmpty === false
          ? yup.array().nullable().notRequired()
          : yup
              .array()
              .of(
                yup.object().shape({ id: yup.number().required().positive().integer(), name: yup.string().required() })
              )
              .min(1, "At least one operator is required")
              .typeError(
                !!consolidatedValues.operators?.hasEmpty
                  ? "At least one item is missing an operator"
                  : "At least one operator is required"
              )
      ),
      sample: yup.object().when([], () =>
        consolidatedValues.sample?.isEqual === false &&
        consolidatedValues.sample?.value === null &&
        consolidatedValues.sample?.hasEmpty === false
          ? yup.object().nullable().notRequired()
          : yup
              .object()
              .shape({ id: yup.number().required().positive().integer(), name: yup.string().required() })
              .typeError(
                !!consolidatedValues.sample?.hasEmpty
                  ? "At least one item is missing a sample"
                  : "A sample must be defined"
              )
      ),
      method: yup.object().when([], () =>
        consolidatedValues.method?.isEqual === false &&
        consolidatedValues.method?.value === null &&
        consolidatedValues.method?.hasEmpty === false
          ? yup.object().nullable().notRequired()
          : yup
              .object()
              .shape({ id: yup.number().required().positive().integer(), name: yup.string().required() })
              .strict()
              .required("A method must be defined")
              .typeError(
                !!consolidatedValues.method?.hasEmpty
                  ? "At least one item is missing a method"
                  : "A method must be defined"
              )
      ),
      ...conditionalTypeFormSchema,
    }),
    [consolidatedValues, conditionalTypeFormSchema]
  );

  // Conditional validation by a custom resolver
  const resolver = useCallback(
    async (data: Partial<Nullable<Dataset>>, context: any, options: any) => {
      if (isRequired.current) {
        return await yupResolver(yup.object().shape(conditionalDatasetClaimFormSchema))(data, context, options);
      } else {
        return await yupResolver(yup.object().shape(conditionalTypeFormSchema))(data, context, options);
      }
    },
    [conditionalDatasetClaimFormSchema, conditionalTypeFormSchema]
  );

  const {
    trigger,
    register,
    control,
    formState: { errors: errorsExternal, isDirty, dirtyFields },
    handleSubmit: internalSubmit,
    clearErrors,
    reset: resetExternal,
  } = useForm<Partial<Nullable<Dataset>>>({
    values: defaultValues,
    resolver: resolver,
  });

  useEffect(() => {
    resetExternal({ ...defaultValues, customType: type ?? defaultValues.customType }, { keepDirty: true });
  }, [defaultValues, resetExternal, type]);

  const beforeSubmit = useCallback(
    (data: MultiEditMappedItemObject<Dataset>, changes: Partial<Nullable<Dataset>>, claimMode?: boolean) => {
      const currentChanges = {
        ...(changes.customType !== null && { customType: checkForUnassignedValue(changes.customType) }),
        ...(changes.sample !== null && { sample: checkForUnassignedValue(changes.sample) }),
        ...(changes.projects !== null && { projects: checkForUnassignedValue(changes.projects) }),
        ...(changes.organizations !== null && { organizations: checkForUnassignedValue(changes.organizations) }),
        ...(changes.operators !== null && { operators: checkForUnassignedValue(changes.operators) }),
        ...(changes.method !== null && { method: checkForUnassignedValue(changes.method) }),
        ...(changes.instrument !== null && { instrument: checkForUnassignedValue(changes.instrument) }),
        ...(changes.experiment !== null && { experiment: checkForUnassignedValue(changes.experiment) }),
        ...(changes.equipments !== null && { equipments: checkForUnassignedValue(changes.equipments) }),
        ...(changes.other !== null && { other: changes.other }),
        ...(claimMode && { claimed: true, owner: session?.person ?? null }),
      } as Partial<Dataset>;

      const dirtyCustomFields = Object.fromEntries(
        Object.entries(changes.customFields ?? {}).filter(
          ([key, value]) => (dirtyFields?.customFields as StringIndexedDict)?.[key]
        )
      );

      let updates: MultiEditMappedItemObject<Dataset> = {};
      for (const [id, entity] of Object.entries(data)) {
        updates[id] = {
          ...entity,
          ...currentChanges,
          ...(currentChanges.customType?.id && {
            ...processValuesForTypedEntity(
              {
                customType: currentChanges.customType?.id ? { id: currentChanges.customType.id } : null,
                customFields: { ...entity.customFields, ...dirtyCustomFields },
              } as Partial<Dataset>,
              currentChanges.customType?.id ? types?.[currentChanges.customType.id] : undefined
            ),
          }),
        };
      }
      return updates;
    },
    [session?.person, dirtyFields?.customFields, types]
  );

  const nChanges = selection.size;
  const nErrors = Object.keys(errorsExternal).length;
  const isError = !!nErrors;

  const _type = useWatch({ name: "customType", control: control });
  useEffect(() => {
    if (_type && typeof _type.id === "string" && types && Object.hasOwn(types, _type.id)) {
      setType(types[_type.id]);
    } else {
      // Clear custom field errors
      clearErrors("customFields");
    }
  }, [_type, clearErrors, types, setType]);

  // Create some suggestions
  const sample = useWatch({ control: control, name: "sample" });
  const { data: sampleFullModel } = useEntityDetail<Sample, SampleFilters>(
    samplesConstants.resource,
    sample?.id!,
    undefined,
    { enabled: !!sample }
  );

  const { data: personFullModel } = useEntityDetail<Person, PersonFilters>(
    personsConstants.resource,
    session?.person?.id!,
    undefined,
    { enabled: !!session?.person }
  );

  return (
    <div
      className={`flex col-nowrap align-center`}
      style={{ width: "50vw", height: "fit-content", padding: "10px 15px", overflow: "auto" }}
    >
      <h3>Edit Metadata</h3>
      <div className="form form-group form-horizontal" style={{ width: "100%", height: "fit-content" }}>
        <SamplesVirtualizedSelectForm
          id={"sample"}
          control={control}
          placeholder={getValueOrDefault(consolidatedValues?.sample)}
          horizontal
          allowCreateEntity
          allowUnassigned
        />

        <ProjectsVirtualizedSelectForm
          id={"projects"}
          control={control}
          placeholder={getValueOrDefault(consolidatedValues?.projects)}
          horizontal
          isMulti
          allowCreateEntity
          allowUnassigned
          suggestionsIds={sampleFullModel ? sampleFullModel.projects.map((p) => p.id) : undefined}
          required={isRequired.current}
          filters={{ currentUserHasAddPermission: true }}
        />

        <OrganizationsVirtualizedSelectForm
          id={"organizations"}
          control={control}
          placeholder={getValueOrDefault(consolidatedValues?.organizations)}
          horizontal
          isMulti
          allowCreateEntity
          allowUnassigned
          suggestionsIds={
            personFullModel && personFullModel.organization ? [personFullModel.organization.id] : undefined
          }
        />

        <PersonsVirtualizedSelectForm
          id={"operators"}
          label={"Operators"}
          control={control}
          placeholder={getValueOrDefault(consolidatedValues?.operators)}
          horizontal
          isMulti
          allowCreateEntity
          allowUnassigned
          suggestionsIds={session?.person.id ? [session?.person.id] : undefined}
          required={isRequired.current}
        />

        <MethodsVirtualizedSelectForm
          id={"method"}
          control={control}
          placeholder={getValueOrDefault(consolidatedValues?.method)}
          horizontal
          allowCreateEntity
          required={isRequired.current}
        />

        <InstrumentsVirtualizedSelectForm
          id={"instrument"}
          control={control}
          placeholder={getValueOrDefault(consolidatedValues?.instrument)}
          horizontal
          allowCreateEntity
          allowUnassigned
          required={isRequired.current}
        />

        <ExperimentsVirtualizedSelectForm
          id={"experiment"}
          control={control}
          placeholder={getValueOrDefault(consolidatedValues?.experiment)}
          horizontal
          allowCreateEntity
          allowUnassigned
        />

        <EquipmentsVirtualizedSelectForm
          id={"equipments"}
          label={"Suppl. Equipment"}
          control={control}
          placeholder={getValueOrDefault(consolidatedValues?.equipments)}
          horizontal
          isMulti
          allowUnassigned
        />
        <InputFormField
          id="other"
          label="Other"
          errors={errorsExternal}
          register={register}
          placeholder={getValueOrDefault(consolidatedValues?.other)}
        />

        <CustomTypesVirtualizedSelectForm
          id={"customType"}
          control={control}
          placeholder={getValueOrDefault(consolidatedValues?.customType)}
          horizontal
          allowUnassigned
          disabled={
            !(
              consolidatedValues.customType === undefined ||
              (consolidatedValues.customType.value === null && consolidatedValues.customType.isEqual)
            )
          }
          filters={{ entityTypes: ["Dataset"] }}
        />

        {_type && types ? (
          // <CustomFieldsFormRenderer
          //   customSchema={schemas[_type.id]}
          //   register={register}
          //   control={control}
          //   errors={errorsExternal}
          //   placeholders={customFieldPlaceholders}
          // />
          <CustomTypeFormRenderer
            customType={types[_type.id]}
            register={register}
            control={control as any}
            errors={errorsExternal}
            placeholders={customFieldPlaceholders}
          />
        ) : null}
      </div>
      <FormErrors errors={errorsExternal} />

      <AlertModal
        type={`primary`}
        showModal={showAlertModal}
        setShowModal={setShowAlertModal}
        title={`Apply changes to ${selection.size} ${
          selection.size === 1 ? entityConstants.entitySingular : entityConstants.entityPlural
        }?`}
        proceedLabel={"Apply changes"}
        onProceed={async () => {
          isRequired.current = false;
          await internalSubmit(async (changes) => {
            await handleSubmit(async (data) => await onSubmit(beforeSubmit(items, changes), nChanges, reset))();
          })();
        }}
        onCancel={() => {
          setShowAlertModal(false);
          onClose?.();
        }}
        loading={loading}
      />

      {/* Claim */}
      <AlertModal
        type={`primary`}
        showModal={showClaimAlertModal}
        setShowModal={setShowClaimAlertModal}
        title={`Apply changes & claim ${selection.size} ${
          selection.size === 1 ? entityConstants.entitySingular : entityConstants.entityPlural
        }?`}
        proceedLabel={"Apply changes"}
        onProceed={async () => {
          isRequired.current = true;
          await internalSubmit(async (changes) => {
            await handleSubmit(async (data) => await onSubmit(beforeSubmit(items, changes, true), nChanges, reset))();
          })();
          isRequired.current = false;
          setShowClaimAlertModal(false);
        }}
        onCancel={() => {
          setShowClaimAlertModal(false);
          onClose?.();
        }}
        loading={loading}
      />
      <div className="flex row-nowrap align-center gap-5" style={{ width: "100%" }}>
        <div>
          <button
            className="btn btn-primary"
            onClick={() => {
              history.push({
                pathname: route(getBulkEditRoute(datasetsConstants.frontendIndexRoute)),
                state: {
                  filters: { ...filters, ids: Array.from(selection) },
                  items: items,
                } as BulkEditLocationState<Dataset, DatasetFilters>,
              });
            }}
            disabled={isSubmitting}
          >
            <LucideIcon name="table" /> Advanced edit
          </button>
        </div>
        <div className="flex row-nowrap align-center gap-5" style={{ marginLeft: "auto" }}>
          <button className="btn btn-default" title="Close" onClick={onClose} disabled={isSubmitting}>
            Close
          </button>
          {consolidatedValues.claimed?.isEqual && !consolidatedValues.claimed?.value && (
            <Button
              className={`btn btn-success ${isSubmitting ? "loading" : ""}`}
              title={`Edit & claim all selected ${
                selection.size === 1 ? entityConstants.entitySingular : entityConstants.entityPlural
              }`}
              onClick={async () => {
                if (await trigger()) {
                  if (!isError) setShowClaimAlertModal(true);
                }
              }}
              // disabled={isSubmitting || isError}
              loading={isSubmitting}
            >
              {isSubmitting && <span className="spinner" />}
              {`${isSubmitting ? "Claiming..." : "Edit & claim"}`}{" "}
              {`${selection.size} ${
                selection.size === 1 ? entityConstants.entitySingular : entityConstants.entityPlural
              }`}
            </Button>
          )}
          <Button
            className={`btn btn-primary ${isSubmitting ? "loading" : ""}`}
            title={`Apply changes to all selected ${
              selection.size === 1 ? entityConstants.entitySingular : entityConstants.entityPlural
            }`}
            onClick={async () => {
              if (await trigger()) {
                if (!isError) setShowAlertModal(true);
              }
            }}
            disabled={!isDirty}
            loading={isSubmitting}
          >
            {`${isSubmitting ? "Processing..." : "Edit"}`}{" "}
            {`${selection.size} ${
              selection.size === 1 ? entityConstants.entitySingular : entityConstants.entityPlural
            }`}
          </Button>
        </div>
      </div>
    </div>
  );
};
