import { useCallback, useMemo, useRef, useState } from "react";
import Draggable from "react-draggable";
import styles from "./SpreadSheet.module.css";
import {
  CellElementProps,
  CellIndex,
  CellProps,
  FormattedTableCell,
  indexToLetter,
  LinePosType,
} from "./SpreadSheetTypes";

export const ColumnCell = ({
  index,
  rowIndex,
  style,
  cellState,
  addToRowHeight,
  setLinePos,
  showRowIndex,
  columnIndex,
  showRowLetterIndex,
  RowIndexElement,
  RowNameElement,
  CellValues,
}: CellProps & {
  setLinePos: (pos: LinePosType | undefined) => void;
  showRowIndex: boolean;
  showRowLetterIndex: boolean;
  fixedColumnCount: number;
  RowIndexElement?: (props: CellElementProps) => JSX.Element;
  RowNameElement?: (props: CellElementProps) => JSX.Element;
  CellValues: (index: CellIndex) => FormattedTableCell;
}) => {
  const [, setHover] = useState(false);
  const ref = useRef<any>();

  const width = style?.width ? parseInt(style?.width?.toString()) : 0;
  const height = style?.height ? parseInt(style?.height?.toString()) : 0;

  const setPosition = useCallback(() => {
    const element = ref.current as Element;
    const bound = element?.getBoundingClientRect() || { width: 0, height: 0, top: 0, left: 0 };
    setLinePos({ top: bound.top + bound.height / 2, left: bound.left, mode: "horizontal" });
    // setLinePos({ top: bound.top, left: bound.left, mode: "horizontal" });
  }, [ref, setLinePos]);

  const content = useMemo(() => {
    if (showRowIndex) {
      if (columnIndex < 1)
        return RowIndexElement ? (
          <RowIndexElement
            width={width}
            height={height}
            columnIndex={columnIndex}
            rowIndex={rowIndex}
            content={{
              value: showRowLetterIndex ? indexToLetter(rowIndex + 1) : rowIndex + 1,
              type: "string",
              row: rowIndex,
              column: columnIndex,
              settings: {},
            }}
            settings={{ focused: rowIndex === cellState.focused.rowIndex }}
          />
        ) : (
          <>{showRowLetterIndex ? indexToLetter(rowIndex + 1) : rowIndex + 1}</>
        );
      columnIndex--;
    }
    return RowNameElement ? (
      <RowNameElement
        width={width}
        height={height}
        columnIndex={columnIndex}
        rowIndex={rowIndex}
        content={CellValues({ rowIndex: rowIndex, columnIndex: columnIndex })}
        settings={{ focused: rowIndex === cellState.focused.rowIndex }}
      />
    ) : (
      <>
        Row {rowIndex} {columnIndex}
      </>
    );
  }, [showRowIndex, RowNameElement, width, height, columnIndex, rowIndex, CellValues, cellState.focused.rowIndex, RowIndexElement, showRowLetterIndex]);

  return (
    <div
      className={[
        styles.FixedGridCell,
        rowIndex === cellState.focused.rowIndex ? styles.FixedGridCellFocused : "",
      ].join(" ")}
      key={index}
      style={style}
    >
      <div className={styles.FixedGridCellInner} style={{ width: width, height: height }}>
        {content}
      </div>
      <Draggable
        axis="y"
        defaultClassName={styles.DragHandle}
        defaultClassNameDragging={styles.DragHandleActive}
        onDrag={() => {
          setPosition();
          setHover(true);
        }}
        onStart={() => {
          setPosition();
          setHover(true);
        }}
        onMouseDown={setPosition}
        onStop={(_, data) => {
          setLinePos(undefined);
          setHover(false);
          if (addToRowHeight) addToRowHeight(rowIndex, data.y);
        }}
        position={{
          x: 0,
          y: 0,
        }}
      >
        <div ref={ref} style={{ top: height - 3, height: 6, width: width, cursor: "row-resize" }} />
      </Draggable>
    </div>
  );
};
