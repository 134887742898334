import { useCallback, useContext } from "react";
import { customFieldConstants } from "../api/CustomFields";
import { customTypeConstants } from "../api/CustomTypes";
import { datasetsConstants } from "../api/Datasets";
import { experimentsConstants } from "../api/Experiments";
import { instrumentsConstants } from "../api/Facilities";
import { inventoryItemsConstants } from "../api/Inventories";
import { methodsConstants } from "../api/Methods";
import { personsConstants } from "../api/Person";
import { projectsConstants } from "../api/Projects";
import { samplesConstants } from "../api/Samples";
import { SessionContext } from "../common/contexts/SessionContext";
import { getDetailLink } from "../main/Routing";
import { EntityMinimalModelWithType } from "../api/ApiTypes";

export const useEntitiesRedirect = () => {
  const { route } = useContext(SessionContext);
  const redirectByUUID = useCallback(
    (result: EntityMinimalModelWithType) => {
      switch (result.type) {
        case "Experiment":
          return route(getDetailLink(experimentsConstants.frontendIndexRoute, result.id));
        case "Sample":
          return route(getDetailLink(samplesConstants.frontendIndexRoute, result.id));
        case "Dataset":
          return route(getDetailLink(datasetsConstants.frontendIndexRoute, result.id));
        case "Person":
          return route(getDetailLink(personsConstants.frontendIndexRoute, result.id));
        case "InstrumentFacility":
          return route(getDetailLink(instrumentsConstants.frontendIndexRoute, result.id));
        case "InventoryItem":
          return route(getDetailLink(inventoryItemsConstants.frontendIndexRoute, result.id));
        case "Method":
          return route(getDetailLink(methodsConstants.frontendIndexRoute, result.id));
        case "Project":
          return route(getDetailLink(projectsConstants.frontendIndexRoute, result.id));
        case "CustomField":
          return route(getDetailLink(customFieldConstants.frontendIndexRoute, result.id));
        case "CustomType":
          return route(getDetailLink(customTypeConstants.frontendIndexRoute, result.id));

        // case "Origin":
        //     return <Redirect to={route(getDetailLink(originsConstants.frontendIndexRoute, result.id))} />;
        default:
          return "";
      }
    },
    [route]
  );

  return redirectByUUID;
};
