import { useContext, useMemo, useState } from "react";
import { SessionContext } from "../../../common/contexts/SessionContext";
import { SearchInput } from "../../../common/forms/SearchInput/SearchInput";
import { LucideIcon } from "../../../common/icon/LucideIcon";
import TableView, { TableViewLabel } from "../../../common/panels/TableView/TableView";
import { ColumnsSettings } from "../../../common/tables/ColumnsSelector/ColumnsSelector";
import { AlertModal } from "../../../common/modals/AlertModal/AlertModal";
import { MoreDropdown } from "../../../common/buttons/MoreDropdown/MoreDropdown";
import { EntityTable } from "../../../common/tables/EntityTable/EntityTable";
import {
  GenericVirtualizedTableCells,
  SortState,
} from "../../../common/tables/GenericVirtualizedTable/GenericVirtualizedTableTypes";
import { EntityFilterIndicator } from "../../../common/tables/EntityFilterIndicator/EntityFilterIndicator";
import { EntityTableTabs } from "../../../common/tables/Tabs/EntityTableTabs";
import { useTabStore } from "../../../common/tables/Tabs/useTabStore";
import { TableTabsDict } from "../../../common/tables/Tabs/TableTabsTypes";
import { DateTimeRenderer } from "../../../common/datetime/DateTimeFormatter";
import { LabNotebookEntry, LabNotebookEntryFilters } from "../../types/LabNotebookEntry";
import { LabNotebookEntriesFilterBar, LabNotebookEntriesFilterForm } from "./LabNotebookEntriesFilterBar";
import { GetPersons } from "../../../common/misc/EntityRenders/EntityRenderer";
import { LinkButton } from "../../../common/buttons/LinkButton/LinkButton";
import styles from "../../../common/tables/GenericVirtualizedTable/commons.module.css";
import { NotSet } from "../../../common/misc/UIconstants";
import {
  UseEntityTableDefaultProps,
  UseEntityTableProps,
  useGenericVirtualizedTable,
  useGenericVirtualizedTableTabs,
} from "../../../common/tables/GenericVirtualizedTable/useGenericVirtualizedTable";
import { EntityTableProps } from "../../../common/entity/EntityInterfaces";
import { EntityTableEditButton } from "../../../common/entity/entityComponents/EntityTableEditButton";
import { EntityTableRestoreButton } from "../../../common/entity/entityComponents/EntityTableRestoreButton";
import { EntityTableSoftDeletableButton } from "../../../common/entity/entityComponents/EntityTableSoftDeletableButton";

export const switchLabNotebookEntriesDefaultSortState = (
  sortState: LabNotebookEntryFilters["orderBy"]
): SortState<LabNotebookEntryFilters["orderBy"]> => {
  switch (sortState) {
    case "ID_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-id",
      };
    case "ID_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-id",
      };
    case "ENTRY_DATE_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-entry-date",
      };
    case "ENTRY_DATE_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-entry-date",
      };
    case "LAB_NOTEBOOK_NAME_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-lab-notebook",
      };
    case "LAB_NOTEBOOK_NAME_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-lab-notebook",
      };
    case "LAB_NOTEBOOK_EXPERIMENT_NAME_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-lab-notebook-experiment",
      };
    case "LAB_NOTEBOOK_EXPERIMENT_NAME_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-lab-notebook-experiment",
      };
    case "CREATED_ON_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-createdOn",
      };
    case "CREATED_ON_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-createdOn",
      };
    case "CREATED_BY_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-createdBy",
      };
    case "CREATED_BY_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-createdBy",
      };
    case "MODIFIED_ON_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-modifiedOn",
      };
    case "MODIFIED_ON_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-modifiedOn",
      };
    case "MODIFIED_BY_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-modifiedBy",
      };
    case "MODIFIED_BY_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-modifiedBy",
      };
    default:
      return { sortDirection: "DESC", headerId: "default-modifiedOn" };
  }
};

const defaultFilter: LabNotebookEntryFilters = { orderBy: "MODIFIED_ON_DESC" };

export const useLabNotebookEntriesTableDefaults = ({ fieldLabels }: UseEntityTableDefaultProps<"notebookEntries">) => {
  const { session } = useContext(SessionContext);

  const defaults: ColumnsSettings<LabNotebookEntry> = useMemo(
    () => ({
      "default-id": { pos: 0, active: false, header: fieldLabels.id, property: "id" },
      "default-entry-date": {
        pos: 1,
        active: true,
        header: fieldLabels.entryDate,
        property: "entryDate",
      },
      "default-name": {
        pos: 2,
        active: true,
        header: fieldLabels.name,
        property: "name",
      },
      "default-lab-notebook": {
        pos: 3,
        active: true,
        header: fieldLabels.labNotebook,
        property: "labNotebook",
      },
      "default-lab-notebook-experiment": {
        pos: 4,
        active: true,
        header: fieldLabels.labNotebookExperiment,
        property: "labNotebookExperiment",
      },
      "default-createdOn": {
        pos: 5,
        active: true,
        header: fieldLabels.createdOn,
        property: "createdOn",
      },
      "default-createdBy": {
        pos: 6,
        active: true,
        header: fieldLabels.createdBy,
        property: "createdBy",
      },
      "default-modifiedOn": {
        pos: 7,
        active: true,
        header: fieldLabels.modifiedOn,
        property: "modifiedOn",
      },
      "default-modifiedBy": {
        pos: 8,
        active: true,
        header: fieldLabels.modifiedBy,
        property: "modifiedBy",
      },
    }),
    [fieldLabels]
  );
  const tabStoreDefaults: TableTabsDict<LabNotebookEntry, LabNotebookEntryFilters, LabNotebookEntriesFilterForm> =
    useMemo(
      () => ({
        default: {
          tabId: "default",
          type: "fixed",
          label: "All",
          title: "All",
          icon: "house",
          align: "left",
          xPos: 0,
          settings: {
            columnSettings: {},
            columnWidths: {},
            filters: defaultFilter,
            sidebarFilters: {},
          },
          forcedSettings: {
            columnSettings: {},
            columnWidths: {},
            filters: {},
            sidebarFilters: {},
          },
        },
        mydata: {
          tabId: "mydata",
          type: "fixed",
          label: "My entries",
          title: "My entries",
          icon: "house",
          align: "left",
          xPos: 1,
          settings: {
            columnSettings: {},
            columnWidths: {},
            filters: defaultFilter,
            sidebarFilters: {},
          },
          forcedSettings: {
            columnSettings: {},
            columnWidths: {},
            filters: { createdByIds: session?.userId ? [session.userId] : null },
            sidebarFilters: {},
          },
        },
        trash: {
          tabId: "trash",
          type: "fixed",
          label: "Trash",
          title: "Trash",
          icon: "trash",
          align: "left",
          xPos: 3,
          settings: {
            columnSettings: {},
            columnWidths: {},
            filters: defaultFilter,
            sidebarFilters: {},
          },
          forcedSettings: {
            columnSettings: {},
            columnWidths: {},
            filters: { isSoftDeleted: true },
            sidebarFilters: {},
          },
        },
      }),
      [session?.userId]
    );
  return { defaults, tabStoreDefaults };
};

export const useLabNotebookEntriesTableColumns = ({
  entityConstants,
  fieldLabels,
  sort,
  setSort,
}: UseEntityTableProps<"notebookEntries">) => {
  const columns = useMemo(() => {
    let headers: GenericVirtualizedTableCells<LabNotebookEntry> = [
      {
        id: "default-id",
        Header: fieldLabels.id,
        accessor: (row) => (
          <span style={{ color: "var(--gray-400)" }}>
            <samp>{row.id}</samp>
          </span>
        ),
        width: 150,
        align: "right",
        sortingFn: () => {
          if (sort.headerId === "default-id") {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "ID_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "ID_ASC" }));
            }
          } else {
            setSort({ headerId: "default-id", sortDirection: "ASC", orderBy: "ID_ASC" });
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
      {
        id: "default-entry-date",
        Header: fieldLabels.entryDate,
        accessor: (row) => <DateTimeRenderer date={row.entryDate} includeElapsed={false} includeTime={false} />,
        width: 120,
        minWidth: 120,
        align: "left",
        sortingFn: () => {
          if (sort.headerId === "default-entry-date") {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "ENTRY_DATE_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "ENTRY_DATE_ASC" }));
            }
          } else {
            setSort({ headerId: "default-entry-date", sortDirection: "ASC", orderBy: "ENTRY_DATE_ASC" });
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
      {
        id: "default-name",
        Header: fieldLabels.name,
        accessor: (row) =>
          row.name ? (
            <div className={`${styles.container_ellipsis} gap-5`}>
              <div className={`${styles.container_ellipsis}`}>
                <span className={styles.label_highlight}>
                  <LucideIcon name={entityConstants.icon} color={"var(--primary)"} /> {row.name}
                </span>
              </div>
              {row.isDeleted && (
                <div>
                  <label className="label label-soft-warning" title={"Trashed"}>
                    <LucideIcon name="trash" />
                  </label>
                </div>
              )}
            </div>
          ) : (
            NotSet
          ),
        minWidth: 150,
        width: 250,
        align: "left",
        sortingFn: (headerId) => {
          if (sort.headerId === headerId) {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "NAME_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "NAME_ASC" }));
            }
          } else {
            setSort({ headerId: headerId, sortDirection: "ASC", orderBy: "NAME_ASC" });
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
      {
        id: "default-lab-notebook",
        Header: fieldLabels.labNotebook,
        accessor: (row) => (
          <div style={{ display: "flex", flexFlow: "row nowrap", gap: "5px", overflow: "hidden" }}>
            <div style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
              <span style={{ fontWeight: 500, whiteSpace: "nowrap", marginRight: "5px" }}>
                <LucideIcon
                  name={`${row.labNotebook.status === "CLOSED" ? "book" : "book-open"}`}
                  color={"var(--primary)"}
                />{" "}
                {row.labNotebook.name}
              </span>
            </div>
          </div>
        ),
        width: 300,
        // maxWidth: 150,
        align: "left",
        sortingFn: () => {
          if (sort.headerId === "default-lab-notebook") {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "LAB_NOTEBOOK_NAME_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "LAB_NOTEBOOK_NAME_ASC" }));
            }
          } else {
            setSort({ headerId: "default-lab-notebook", sortDirection: "ASC", orderBy: "LAB_NOTEBOOK_NAME_ASC" });
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
      {
        id: "default-lab-notebook-experiment",
        Header: fieldLabels.labNotebookExperiment,
        accessor: (row) => (
          <div style={{ display: "flex", flexFlow: "row nowrap", gap: "5px", overflow: "hidden" }}>
            <div style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
              <span style={{ fontWeight: 500, whiteSpace: "nowrap", marginRight: "5px" }}>
                <LucideIcon name="file-text" color={"var(--primary)"} /> {row.labNotebookExperiment.name}
              </span>
            </div>
          </div>
        ),
        width: 300,
        // maxWidth: 150,
        align: "left",
        sortingFn: () => {
          if (sort.headerId === "default-lab-notebook-experiment") {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "LAB_NOTEBOOK_EXPERIMENT_NAME_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "LAB_NOTEBOOK_EXPERIMENT_NAME_ASC" }));
            }
          } else {
            setSort({
              headerId: "default-lab-notebook-experiment",
              sortDirection: "ASC",
              orderBy: "LAB_NOTEBOOK_EXPERIMENT_NAME_ASC",
            });
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
      {
        id: "default-createdOn",
        Header: fieldLabels.createdOn,
        accessor: (row) => (
          <div>
            <DateTimeRenderer date={row.createdOn} includeElapsed={false} />
          </div>
        ),
        width: 200,
        align: "left",
        sortingFn: () => {
          if (sort.headerId === "default-createdOn") {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "CREATED_ON_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "CREATED_ON_ASC" }));
            }
          } else {
            setSort({ headerId: "default-createdOn", sortDirection: "ASC", orderBy: "CREATED_ON_ASC" });
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
      {
        id: "default-createdBy",
        Header: fieldLabels.createdBy,
        accessor: (row) => (
          <div>
            <GetPersons persons={[row.createdBy]} createLinks={false} />
          </div>
        ),
        width: 200,
        align: "left",
        sortingFn: () => {
          if (sort.headerId === "default-createdBy") {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "CREATED_BY_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "CREATED_BY_ASC" }));
            }
          } else {
            setSort({ headerId: "default-createdBy", sortDirection: "ASC", orderBy: "CREATED_BY_ASC" });
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
      {
        id: "default-modifiedOn",
        Header: fieldLabels.modifiedOn,
        accessor: (row) => (
          <div>
            <DateTimeRenderer date={row.modifiedOn} includeElapsed={false} />
          </div>
        ),
        width: 200,
        align: "left",
        sortingFn: () => {
          if (sort.headerId === "default-modifiedOn") {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "MODIFIED_ON_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "MODIFIED_ON_ASC" }));
            }
          } else {
            setSort({ headerId: "default-modifiedOn", sortDirection: "ASC", orderBy: "MODIFIED_ON_ASC" });
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
      {
        id: "default-modifiedBy",
        Header: fieldLabels.modifiedBy,
        accessor: (row) => (
          <div>
            <GetPersons persons={[row.modifiedBy]} createLinks={false} />
          </div>
        ),
        width: 200,
        align: "left",
        sortingFn: () => {
          if (sort.headerId === "default-modifiedBy") {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "MODIFIED_BY_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "MODIFIED_BY_ASC" }));
            }
          } else {
            setSort({ headerId: "default-modifiedBy", sortDirection: "ASC", orderBy: "MODIFIED_BY_ASC" });
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
    ];

    return headers;
  }, [
    entityConstants.icon,
    fieldLabels.createdBy,
    fieldLabels.createdOn,
    fieldLabels.entryDate,
    fieldLabels.id,
    fieldLabels.labNotebook,
    fieldLabels.labNotebookExperiment,
    fieldLabels.modifiedBy,
    fieldLabels.modifiedOn,
    fieldLabels.name,
    setSort,
    sort.headerId,
    sort.sortDirection,
  ]);

  return { columns };
};
export const LabNotebookEntriesTable = ({
  entityApi,
  entityConstants,
  fieldLabels,
  permissions,
  routes,
}: EntityTableProps<"notebookEntries">) => {
  const [showModal, setShowModal] = useState(false);
  const {
    bulkRestoreMutationAsync: bulkRestoreLabNotebookEntries,
    bulkDeleteMutationAsync: deleteLabNotebookEntries,
    isLoadingBulkRestoreMutation,
    isLoadingBulkDeleteMutation,
  } = entityApi;

  const { defaults, tabStoreDefaults } = useLabNotebookEntriesTableDefaults({ fieldLabels });

  const { selection, resultsCount, selectionPermissions, onCountChange, onSelectionChange, onSelectionPermissions } =
    useGenericVirtualizedTable<LabNotebookEntry>();

  const {
    filters,
    forcedFilters,
    sidebarFilters,
    columnSetting,
    columnWidths,
    customTabs,
    fixedTabs,
    dispatchTabStore,
    currentTab,
    tabsLoading,
    tabsModified,
  } = useTabStore<LabNotebookEntry, LabNotebookEntryFilters, LabNotebookEntriesFilterForm>({
    resource: entityConstants.resource,
    defaults: tabStoreDefaults,
  });

  const { functionRef, sort, setSort, searchValue, setSearchValue, onTabChange } = useGenericVirtualizedTableTabs({
    tabsLoading,
    filters,
    switchSortState: switchLabNotebookEntriesDefaultSortState,
    dispatchTabStore,
  });

  const { columns } = useLabNotebookEntriesTableColumns({ fieldLabels, entityConstants, sort, setSort });

  const memoizedFilters = useMemo(() => ({ ...filters, ...forcedFilters }), [filters, forcedFilters]);

  return (
    <TableView>
      <TableView.Head>
        <TableView.Head.Label>
          <TableViewLabel entityConstants={entityConstants} resultsCount={resultsCount} />
        </TableView.Head.Label>
        <TableView.Head.Controls>
          <LinkButton
            linkTo={routes.getAddRoute}
            className="btn btn-primary"
            title={`Add ${entityConstants.entitySingular}`}
            disabled={!permissions.canCreate}
          >
            <LucideIcon name="plus" /> Add {entityConstants.entitySingular}
          </LinkButton>
        </TableView.Head.Controls>
      </TableView.Head>
      <TableView.Body>
        <TableView.Body.Sidebar>
          <LabNotebookEntriesFilterBar
            initialValues={sidebarFilters}
            dispatchTabStore={dispatchTabStore}
            tabsLoading={tabsLoading}
            currentTab={currentTab}
          />
        </TableView.Body.Sidebar>
        <TableView.Body.Content>
          <EntityTableTabs
            currentTab={currentTab}
            onTabChange={onTabChange}
            fixedTabs={fixedTabs}
            filters={{
              ...filters,
              isSoftDeleted: filters.isSoftDeleted || false,
              includeSoftDeleted: filters.includeSoftDeleted || false,
            }}
            sidebarFilters={sidebarFilters}
            columnSetting={columnSetting}
            columnWidths={columnWidths}
            customTabs={customTabs}
            dispatchTabStore={dispatchTabStore}
            tabsLoading={tabsLoading}
            tabsModified={tabsModified}
          />
          <EntityTable>
            <EntityTable.Controls
              style={{
                borderTop: "0px",
                borderRadius: "0px",
              }}
            >
              <EntityFilterIndicator<LabNotebookEntry, LabNotebookEntryFilters>
                filters={filters}
                excludeFilters={{ includeSoftDeleted: (value) => !!value, isSoftDeleted: (value) => !!value }}
              />
              <SearchInput searchValue={searchValue} setSearchValue={setSearchValue} placeholder="Search" />
              <EntityTableEditButton
                entityConstants={entityConstants}
                routes={routes}
                permissions={permissions}
                selection={selection}
                selectionPermissions={selectionPermissions}
              />

              <MoreDropdown drop="right" btn="btn btn-ghost-secondary">
                {currentTab === "trash" && (
                  <li>
                    <EntityTableRestoreButton
                      entityConstants={entityConstants}
                      selection={selection}
                      permissions={permissions}
                      selectionPermissions={selectionPermissions}
                      onClick={async () =>
                        await bulkRestoreLabNotebookEntries({ ids: Array.from(selection) }).catch(() => {})
                      }
                      loading={isLoadingBulkRestoreMutation}
                    />
                  </li>
                )}
                <li>
                  <EntityTableSoftDeletableButton
                    currentTab={currentTab}
                    entityConstants={entityConstants}
                    selection={selection}
                    permissions={permissions}
                    selectionPermissions={selectionPermissions}
                    onClick={() => setShowModal(true)}
                  />
                </li>
              </MoreDropdown>
              <AlertModal
                type={`${currentTab === "trash" ? "danger" : "warning"}`}
                showModal={showModal}
                setShowModal={setShowModal}
                forceUserInput={currentTab === "trash"}
                title={`${
                  selection.size === 0
                    ? `Select dataset to ${currentTab === "trash" ? "delete" : "trash"}`
                    : selection.size === 1
                    ? `${currentTab === "trash" ? "Delete" : "Trash"} the selected ${entityConstants.entitySingular}?`
                    : `${currentTab === "trash" ? "Delete" : "Trash"} the selected ${entityConstants.entityPlural}?`
                }`}
                description={`${
                  currentTab === "trash"
                    ? `Proceeding will permanently delete the selected ${entityConstants.entityPlural}.`
                    : `Proceeding will move the selected ${entityConstants.entityPlural} into trash.`
                }`}
                proceedLabel={`${currentTab === "trash" ? "Delete" : "Trash"}`}
                onProceed={async () => {
                  await deleteLabNotebookEntries({
                    ids: Array.from(selection),
                    goBackOnSuccess: false,
                    showToast: false,
                    entityName: entityConstants.resource,
                    params: { deletePermanently: currentTab === "trash" },
                  }).catch((e) => {});
                  functionRef.current?.resetSelection();
                  setShowModal(false);
                }}
                loading={isLoadingBulkDeleteMutation}
              />
            </EntityTable.Controls>
            <EntityTable.Body<LabNotebookEntry, LabNotebookEntryFilters>
              functionRef={functionRef}
              entityConstants={entityConstants}
              filters={memoizedFilters}
              columns={columns}
              columnSelect
              columnSetting={columnSetting}
              columnWidths={columnWidths}
              defaultColumnSettings={defaults}
              dispatchTabStore={dispatchTabStore}
              setResultsCount={onCountChange}
              onSelectionChange={onSelectionChange}
              onSelectionPermissions={onSelectionPermissions}
              showPermissionColumn
              loading={tabsLoading}
            />
          </EntityTable>
        </TableView.Body.Content>
      </TableView.Body>
    </TableView>
  );
};
