import { Dispatch, SetStateAction, useCallback, useState } from "react";
import Status from "../../../common/badges/Status/Status";
import { DateTimeRenderer } from "../../../common/datetime/DateTimeFormatter";
import { LucideIcon } from "../../../common/icon/LucideIcon";
import {
  EntitySidebarTile,
  EntitySidebarTileProps,
} from "../../../common/sidebar/tiles/EntitySidebarTile/EntitySidebarTile";
import {
  LabNotebookExperiment,
  LabNotebookExperimentFilters,
  LabNotebookExperimentFiltersTranslator,
  LabNotebookExperimentSortingOptionsConsts,
  labNotebookExperimentsConstants,
} from "../../types/LabNotebookExperiment";
import { useEntityApi } from "../../../api/useEntityApi";
import { useQueryClient } from "@tanstack/react-query";
import { ExclusiveDropdown } from "../../../common/buttons/ExclusiveDropdown/ExclusiveDropdown";
import { LAB_NOTEBOOK_TOC_QUERY_KEY } from "./LabNotebookTocTile/LabNotebookTocTile";
import { QuickAddWrapper } from "../../../common/forms/QuickAdd/common/QuickAddWrapper";
import { useELNRoutes } from "../../ELNRouter/useELNRoutes";
import { QuickAddLabNotebookExperimentForm } from "../../../common/forms/QuickAdd/forms/QuickAddLabNotebookExperiment";
import { EmptyLabNotebookEntry, LabNotebookEntry } from "../../types/LabNotebookEntry";
import { QuickEditWrapper } from "../../../common/forms/QuickEdit/common/QuickEditWrapper";
import { QuickEditLabNotebookExperimentForm } from "../../../common/forms/QuickEdit/forms/QuickEditLabNotebookExperiment";
import { QuickAddLabNotebookEntryForm } from "../../../common/forms/QuickAdd/forms/QuickAddLabNotebookEntry";
import { LabNotebookExperimentFilterBar } from "../../LabNotebookExperiments/Table/LabNotebookExperimentsFilterBar";
import { EntityFilterIndicator } from "../../../common/tables/EntityFilterIndicator/EntityFilterIndicator";
import { LabNotebookExperimentStatusToLabel } from "../../LabNotebookExperiments/LabNotebookExperimentDetailContentTable";
import { Button } from "../../../common/buttons/Button/Button";

interface LabNotebookExperimentSidebarTileProps
  extends EntitySidebarTileProps<LabNotebookExperiment, LabNotebookExperimentFilters> {
  useAddModal?: boolean;
}

export const useDefaultEntryCreateMutation = () => {
  const { createMutationAsync } = useEntityApi<LabNotebookEntry>("lab_notebook_entries");
  // TODO IMPLEMENT ISLOADING
  const queryClient = useQueryClient();
  const createDefaultEntry = useCallback(
    (labNotebookExperiment: LabNotebookExperiment) =>
      createMutationAsync({
        body: {
          entryDate: new Date().toISOString(),
          content: EmptyLabNotebookEntry,
          concurrencyToken: null,
          labNotebookExperiment,
        },
      })
        .then(() => {
          queryClient.invalidateQueries([LAB_NOTEBOOK_TOC_QUERY_KEY]);
          queryClient.invalidateQueries(["lab_notebook_entries"]);
        })
        .catch((e) => console.error(e)),
    [createMutationAsync, queryClient]
  );

  return createDefaultEntry;
};

export const LabNotebookExperimentsTile = (props: LabNotebookExperimentSidebarTileProps) => {
  const { setELNRoute } = useELNRoutes();
  const queryClient = useQueryClient();

  const [showAddModal, setShowAddModal] = useState<boolean>(false);
  const handleAddButtonClick = useCallback(
    (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => setShowAddModal(true),
    []
  );

  const createDefaultEntry = useDefaultEntryCreateMutation();

  return (
    <>
      <EntitySidebarTile<LabNotebookExperiment, LabNotebookExperimentFilters>
        {...props}
        entityConstants={labNotebookExperimentsConstants}
        orderByOptions={LabNotebookExperimentSortingOptionsConsts}
        translatorConsts={LabNotebookExperimentFiltersTranslator}
        defaultFilters={{ orderBy: "MODIFIED_ON_DESC", ...props.defaultFilters }}
        onAddButtonClick={props.useAddModal ? handleAddButtonClick : undefined}
        rowLabel={(e) => (
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "max-content auto",
              alignItems: "center",
              gap: "5px",
            }}
          >
            <Status idle={e.status === "RUNNING"} type={LabNotebookExperimentStatusToLabel(e.status).status as any} />
            <span style={{ textOverflow: "ellipsis", overflow: "hidden" }}>{e.name}</span>
          </div>
        )}
        rowDate={(e) => (
          <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
            <LucideIcon name="save" style={{ marginBottom: "2px", width: "12px" }} />
            <DateTimeRenderer date={e.modifiedOn} includeElapsed={true} includeTime={false} />
          </div>
        )}
        rowInfo={(e) => <>{`${e.labNotebook.name} > ${e.name}`}</>}
        rowControls={LabNotebookExperimentTileRowControls}
        filterIndicatorOverride={LabNotebookExperimentTileControls}
      />

      {showAddModal && (
        <QuickAddWrapper<LabNotebookExperiment>
          showModal={showAddModal}
          setShowModal={setShowAddModal}
          onCreate={(newLabNotebookExperiment) => {
            if (newLabNotebookExperiment && newLabNotebookExperiment.id > 0) {
              queryClient.invalidateQueries([LAB_NOTEBOOK_TOC_QUERY_KEY]);
              queryClient.invalidateQueries(["lab_notebooks"]);
              queryClient.invalidateQueries(["lab_notebook_experiments"]);
              createDefaultEntry(newLabNotebookExperiment);
              setELNRoute({
                labNotebookId: newLabNotebookExperiment.labNotebook.id,
                labNotebookExperimentId: newLabNotebookExperiment.id,
              });
            }
          }}
        >
          {(props) => <QuickAddLabNotebookExperimentForm {...props} initialValues={{ status: "RUNNING" }} />}
        </QuickAddWrapper>
      )}
    </>
  );
};

const LabNotebookExperimentTileControls = ({
  filters,
  setFilters,
}: {
  filters: LabNotebookExperimentFilters;
  setFilters: Dispatch<SetStateAction<LabNotebookExperimentFilters>>;
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <div
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <ExclusiveDropdown
        show={isOpen}
        setShow={setIsOpen}
        drop="down-left"
        title="Edit"
        btnCls="btn btn-sm btn-ghost-secondary"
        backdropStyle={{ background: "transparent", backdropFilter: "none" }}
        triggerComponent={(onToggleDropdown) => (
          <button
            onClick={(e) => onToggleDropdown(e)}
            className="btn btn-sm btn-ghost-secondary"
            style={{ padding: 0 }}
          >
            <EntityFilterIndicator<LabNotebookExperiment, LabNotebookExperimentFilters>
              filters={filters}
              excludeFilters={{
                includeSoftDeleted: (value) => !!value,
                isSoftDeleted: (value) => !!value,
              }}
              showAlways
            />
          </button>
        )}
      >
        <div style={{ maxHeight: "500px", display: "flex", minWidth: "300px", margin: "10px", marginBottom: 0 }}>
          <LabNotebookExperimentFilterBar setFilters={setFilters} />
        </div>
      </ExclusiveDropdown>
    </div>
  );
};

const LabNotebookExperimentTileRowControls = (entity: LabNotebookExperiment) => {
  const { setELNRoute } = useELNRoutes();

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const [showAddEntryModal, setShowAddEntryModal] = useState<boolean>(false);
  const [showEditModal, setShowEditModal] = useState<boolean>(false);

  const { restoreMutationAsync, isLoadingRestoreMutation } = useEntityApi("lab_notebook_experiments");

  const queryClient = useQueryClient();

  return (
    <div
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <ExclusiveDropdown
        show={isOpen}
        setShow={setIsOpen}
        drop="down-right"
        disabled={false}
        title="Edit"
        btnCls="btn btn-sm btn-ghost-secondary"
        backdropStyle={{ background: "transparent", backdropFilter: "none" }}
      >
        <ul style={{ padding: 0, margin: 0 }}>
          <button
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              setShowAddEntryModal(true);
            }}
            data-toggle="tooltip"
            title="Create new entry"
            className="btn btn-ghost-primary btn-block"
            style={{ textAlign: "left" }}
            disabled={!entity.permissions?.edit || entity.labNotebook.status === "CLOSED" || entity.isDeleted}
          >
            <LucideIcon name="pencil" />
            <span> Create new entry</span>
          </button>
          <hr style={{ margin: "5px 0" }} />
          <button
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              setShowEditModal(true);
            }}
            data-toggle="tooltip"
            title="Edit experiment details"
            className="btn btn-ghost-secondary btn-block"
            style={{ textAlign: "left" }}
            disabled={!entity.permissions?.edit || entity.labNotebook.status === "CLOSED" || entity.isDeleted}
          >
            <LucideIcon name="square-pen" />
            <span> Edit experiment details</span>
          </button>
          <button
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              // setShowLabNotebookExperimentDetailsHistory(entity);
            }}
            data-toggle="tooltip"
            title="Show experiment details history"
            className="btn btn-ghost-secondary btn-block"
            style={{ textAlign: "left" }}
          >
            <LucideIcon name="clock" />
            <span> Show experiment details history</span>
          </button>
          {entity.isDeleted ? (
            <Button
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                restoreMutationAsync({ id: entity.id })
                  .then(() => {
                    queryClient.invalidateQueries([LAB_NOTEBOOK_TOC_QUERY_KEY]);
                    queryClient.invalidateQueries(["lab_notebook_experiments"]);
                    queryClient.invalidateQueries(["lab_notebook_entries"]);
                  })
                  .catch((e) => {
                    console.error(e);
                  });
              }}
              data-toggle="tooltip"
              title="Restore experiment"
              className="btn btn-ghost-success btn-block"
              style={{ textAlign: "left" }}
              disabled={!entity.permissions?.edit || entity.labNotebook.status === "CLOSED"}
              loading={isLoadingRestoreMutation}
            >
              <LucideIcon name="refresh-ccw" />
              <span> Restore experiment</span>
            </Button>
          ) : (
            <button
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                // setShowDeleteLabNotebookExperimentModal(entity);
              }}
              data-toggle="tooltip"
              title="Trash experiment"
              className="btn btn-ghost-warning btn-block"
              style={{ textAlign: "left" }}
              disabled={!entity.permissions?.edit || entity.labNotebook.status === "CLOSED"}
            >
              <LucideIcon name="trash-2" />
              <span> Trash experiment</span>
            </button>
          )}
        </ul>
      </ExclusiveDropdown>

      {showAddEntryModal && (
        <QuickAddWrapper<LabNotebookEntry>
          showModal={showAddEntryModal}
          setShowModal={setShowAddEntryModal}
          onCreate={(newEntry) => {
            if (newEntry && newEntry.id > 0) {
              queryClient.invalidateQueries([LAB_NOTEBOOK_TOC_QUERY_KEY]);
              queryClient.invalidateQueries(["lab_notebooks"]);
              queryClient.invalidateQueries(["lab_notebook_experiments"]);
              queryClient.invalidateQueries(["lab_notebook_entries"]);
              setELNRoute({
                labNotebookId: newEntry.labNotebook.id,
                labNotebookExperimentId: newEntry.labNotebookExperiment.id,
                labNotebookEntryId: newEntry.id,
              });
            }
          }}
        >
          {(props) => (
            <QuickAddLabNotebookEntryForm
              {...props}
              initialValues={{
                labNotebookExperiment: entity,
                labNotebook: entity.labNotebook,
                entryDate: new Date().toISOString(),
              }}
            />
          )}
        </QuickAddWrapper>
      )}

      {showEditModal && (
        <QuickEditWrapper<LabNotebookExperiment>
          showModal={showEditModal}
          setShowModal={setShowEditModal}
          onEdit={(editedLabNotebook) => {}}
        >
          {(props) => <QuickEditLabNotebookExperimentForm {...props} initialValues={entity} />}
        </QuickEditWrapper>
      )}
    </div>
  );
};
