import { useState, useEffect } from "react";
import styles from "./styles.module.css";
import React from "react";

export const Count = ({
  num,
  height = 30,
  duration = 0.5,
  style,
}: {
  num: number;
  height?: number;
  duration?: number;
  style?: React.SVGProps<SVGSVGElement>;
}) => {
  const [count, setCount] = useState<number>(0);
  const steps = 50;
  const incrementCount = num / steps;
  const incrementTime = (duration / steps) * 1000;

  // const [increase, setIncrease] = useState<number>(0);
  // const incrementIncrease = rise / steps;

  useEffect(() => {
    let i = 0;
    let current_count = 0;
    if (num === 0) setCount(0);
    if (current_count === num) return;
    let timer = setInterval(() => {
      current_count += incrementCount;
      if (i === steps) {
        setCount(num);
        clearInterval(timer);
        return;
      }
      setCount(Math.min(num, Math.ceil(current_count)));
      i++;
    }, incrementTime);
    return () => {
      clearInterval(timer);
    };
  }, [incrementCount, incrementTime, num]);

  // useEffect(() => {
  //   let timer: NodeJS.Timeout;
  //   if (rise) {
  //     let current_count = 0;
  //     if (current_count === rise) return;
  //     timer = setInterval(() => {
  //       current_count += incrementCount;
  //       if (current_count >= rise) clearInterval(timer);
  //       setCount(Math.floor(current_count));
  //     }, incrementTime);
  //   }
  //   return () => {
  //     clearInterval(timer);
  //   };
  // }, [rise, incrementTime, incrementCount]);

  return (
    <svg
      // viewBox={`0 0 ${width} ${height}`}
      width={num.toString().length * 36}
      height={height}
      xmlns="http://www.w3.org/2000/svg"
      {...style}
    >
      <text x={"50%"} y={"50%"} textAnchor="middle" dominantBaseline="central" opacity={num !== 0 ? count / num : 1}>
        <tspan x={"50%"} y={"50%"} fontSize={"2em"} fontWeight={500} textAnchor="middle" dominantBaseline="central">
          {count}
        </tspan>

        {/* <tspan dx="7%" dy="-25%" stroke={"var(--success)"} fontSize={"1em"}>
            {increase ? <>+{increase}</> : ""}
          </tspan> */}
      </text>
      {/* <text x={"50%"} y={"50%"} fontSize={"2em"} textAnchor="middle" alignmentBaseline="central">
              {stat}
            </text> */}
    </svg>
  );
};

interface StatCardProps {
  label: string;
  count: number;
}
export const StatCard = ({ label, count }: StatCardProps) => {
  return (
    <div className={styles.statcard}>
      <div className={styles.statcard_label}>
        <h3>{label}</h3>
      </div>
      <div className={styles.statcard_stat}>
        <Count num={count} />
      </div>
    </div>
  );
};
