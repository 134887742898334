import { useQuery } from "@tanstack/react-query";
import { HashRouter } from "react-router-dom";
import { API } from "../api/Api";
import { SessionContext } from "../common/contexts/SessionContext";
import { LoadingWrapper } from "../common/LoadingWrapper";
// import { ToasterContainerView } from "../common/overlays/Toasts/ToasterContainerView";
import { EmbeddedRouter } from "../main/MainRouter";
import { AccountRequiredFieldWrapper, MigrationModeWrapper } from "../main/Main";

// export const SamplesRouter = ({ route = (url: string) => url }) => (
//   <>
//     <Route path={route(getAddRoute("samples"))}>
//       <AddSampleFormPage />
//     </Route>
//     <Route path={route(getEditRoute("samples"))}>
//       <SampleEditFormPage />
//     </Route>
//     <Route path={route(getDetailRoute("samples"))}>
//       <SampleDetailPageWithRouting />
//     </Route>
//     <Route path={route(getIndexRoute("samples"))}>
//       <SamplesTable />
//     </Route>
//   </>
// );

// export const SampleDetailPageWithRouting = () => {
//   const { id } = useParams<SingleEntityRoutingParams>();

//   return <SampleDetailPage sampleId={parseInt(id)} />;
// };

export const SamplesConductanceIntegration = ({ api, group }: { api: API; group: string }) => {
  // const session = useQuery("session", api.getSession);
  const session = useQuery({
    queryKey: ["session"],
    queryFn: async ({ signal }) => await api.getSession(),
  });
  return (
    <LoadingWrapper status={session.status} fetchStatus={session.fetchStatus}>
      {/* ToastContainerView is embedded in announcements, which should be present everywhere */}
      {/* <ToasterContainerView /> */}
      <SessionContext.Provider
        value={{ api, session: session.data!, group: group, clearSession: () => {}, route: (x: string) => x }}
      >
        <MigrationModeWrapper>
          <AccountRequiredFieldWrapper>
            <HashRouter hashType="noslash">
              <EmbeddedRouter />
            </HashRouter>
          </AccountRequiredFieldWrapper>
        </MigrationModeWrapper>
      </SessionContext.Provider>
    </LoadingWrapper>
  );
};
