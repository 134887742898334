import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { QuickAddChildrenProps } from "../common/QuickAddWrapper";
import { FormButtons } from "../../FormButtons";
import { FormHeader } from "../../FormHeader";
import { useQuickAddSubmit } from "../common/useQuickAddHooks";
import {
  EmptyLabNotebookEntry,
  LabNotebookEntry,
  LabNotebookEntryFilters,
  labNotebookEntriesConstants,
  labNotebookEntryFieldLabels,
} from "../../../../ELN/types/LabNotebookEntry";
import { LabNotebookExperimentsVirtualizedSelectForm } from "../../EntityForms/formsVirtualized/LabNotebookExperimentsVirtualizedSelectForm";
import { useCallback, useEffect, useMemo } from "react";
import { useInfiniteListEntity } from "../../../../api/BaseEntityApi";
import { date2localDate } from "../../../datetime/DateTimeFormatter";
import { Link } from "react-router-dom";
import { useELNRoutes } from "../../../../ELN/ELNRouter/useELNRoutes";
import { LabNotebookEntryFormValues } from "../../../../ELN/LabNotebookEntries/Forms/LabNotebookEntryForm";
import { InputFormField } from "../../../formfields/InputFormField";
import { SingleDatePickerFormField } from "../../../formfields/DatePickerFormField/SingleDatePickerFormField";
import { getNotebookEntryStringLabel } from "../../../../ELN/LabNotebookEntry/LabNotebookEntry";

export const QuickAddLabNotebookEntryForm = ({
  onCreate,
  onCancel,
  initialValues,
}: QuickAddChildrenProps<LabNotebookEntry>) => {
  const { getELNRoute } = useELNRoutes();
  const { onSubmit, loading } = useQuickAddSubmit<LabNotebookEntry>({
    resource: labNotebookEntriesConstants.resource,
    onCreate: onCreate,
    onCancel: onCancel,
  });

  const FormSchema = yup.object().shape({
    labNotebookExperiment: yup.object().required("An experiment is required").typeError("An experiment is required"),
    entryDate: yup.date().required("Entry date is required").typeError("Entry date is required"),
    name: yup.string().optional().typeError("Invalid entry name"),
  });

  const {
    handleSubmit,
    register,
    control,
    watch,
    setError,
    clearErrors,
    formState: { errors, isSubmitting },
  } = useForm<Partial<LabNotebookEntryFormValues>>({
    values: { ...initialValues, entryDate: initialValues?.entryDate ? new Date(initialValues.entryDate) : new Date() },
    resolver: yupResolver(FormSchema),
  });

  const formValuesToEntity = useCallback(
    (entity: Partial<LabNotebookEntryFormValues>) => {
      if (!!entity.entryDate && !!entity.labNotebookExperiment) {
        const entryDate = date2localDate(entity.entryDate);
        entryDate.setUTCHours(0, 0, 0, 0);
        onSubmit({
          ...entity,
          entryDate: entryDate.toISOString(),
          content: EmptyLabNotebookEntry,
          concurrencyToken: null,
        } as LabNotebookEntry);
      }
    },
    [onSubmit]
  );

  const currentExperiment = watch("labNotebookExperiment");
  const currentEntryDate = watch("entryDate");

  const { data: existingEntriesData } = useInfiniteListEntity<LabNotebookEntry, LabNotebookEntryFilters>(
    "lab_notebook_entries",
    {
      page: 1,
      pageSize: 1,
      orderBy: "ID_ASC",
      labNotebookIds: initialValues?.labNotebook?.id ? [initialValues.labNotebook.id] : undefined,
      labNotebookExperimentIds: currentExperiment?.id ? [currentExperiment.id] : null,
      entryDateFrom: currentEntryDate ? currentEntryDate.toISOString() : null,
      entryDateTo: currentEntryDate ? currentEntryDate.toISOString() : null,
      includeContent: false,
      includeCount: true,
      includeSoftDeleted: true,
    },
    { enabled: !!currentEntryDate && !!currentExperiment?.id },
    "post"
  );

  const existingEntries = useMemo(() => {
    return existingEntriesData?.pages.map((d) => d.results).flat() || [];
  }, [existingEntriesData?.pages]);

  useEffect(() => {
    if (existingEntries && existingEntries.length > 0 && existingEntries[0].id !== initialValues?.id) {
      setError("entryDate", {
        message: `${getNotebookEntryStringLabel({ entry: existingEntries[0] })} - Entry already exists!`,
        type: "validate",
      });
    } else {
      clearErrors("entryDate");
    }
  }, [clearErrors, existingEntries, initialValues?.id, setError]);

  const hasErrors = !!Object.keys(errors).length;
  return (
    <>
      <FormHeader title="Add lab notebook entry" />

      <LabNotebookExperimentsVirtualizedSelectForm
        id="labNotebookExperiment"
        label={labNotebookEntryFieldLabels.labNotebookExperiment}
        control={control}
        showControls
        horizontal
        required
        allowCreateEntity
      />

      <div id="calendarPortalRef">
        <SingleDatePickerFormField
          id="entryDate"
          label={labNotebookEntryFieldLabels.entryDate}
          control={control}
          required
          type="date"
          errors={errors}
          horizontal
          portalId="calendarPortalRef"
        />
      </div>

      <InputFormField
        id="name"
        label={labNotebookEntryFieldLabels.name}
        errors={errors}
        register={register}
        autoFocus={true}
        placeholder="Enter entry name..."
      />

      <FormButtons
        groupName={labNotebookEntriesConstants.frontendIndexRoute}
        entityId={undefined}
        onClose={onCancel}
        onSubmit={handleSubmit(formValuesToEntity)}
        disabled={isSubmitting || !currentEntryDate || hasErrors}
        errors={errors}
        disableDefaultOnClose
        submitButtonLabel="Create"
        loading={loading}
        children={
          existingEntries && existingEntries.length > 0 && existingEntries[0].id !== initialValues?.id ? (
            <Link
              to={getELNRoute({
                labNotebookId: existingEntries[0].labNotebook.id,
                labNotebookExperimentId: existingEntries[0].labNotebookExperiment.id,
                labNotebookEntryId: existingEntries[0].id,
              })}
            >
              <button className="btn btn-ghost-primary">Open entry</button>
            </Link>
          ) : undefined
        }
      />
    </>
  );
};
