import { Dispatch, RefObject, SetStateAction } from "react";
import { LucideIcon, IconNames } from "../../common/icon/LucideIcon";
import { useELNRoutes } from "../ELNRouter/useELNRoutes";
import styles from "./Header.module.css";
import { MoreDropdown } from "../../common/buttons/MoreDropdown/MoreDropdown";
import { Link } from "react-router-dom";
import { LinkButton } from "../../common/buttons/LinkButton/LinkButton";

type ElnTitleParams = {
  label: string;
  color?: string;
  icon?: IconNames;
  iconColor?: string;
  linkTo?: string;
};

type ElnTagParams = {
  label: string;
  color?: ButtonColor;
  linkTo?: string;
  icon?: IconNames;
  condition?: boolean;
};

type ButtonColor =
  | "default"
  | "primary"
  | "secondary"
  | "warning"
  | "danger"
  | "success"
  | "ghost-primary"
  | "ghost-secondary"
  | "ghost-warning"
  | "ghost-danger"
  | "ghost-success"
  | "soft-primary"
  | "soft-secondary"
  | "soft-warning"
  | "soft-danger"
  | "soft-success";

type ElnHeaderControl = {
  id?: string;
  label?: string | JSX.Element;
  icon?: IconNames;
  color?: ButtonColor;
  type?: "create" | "delete";
  onClick?: Function;
  linkTo?: string;
  options?: ElnHeaderControl[];
  disabled?: boolean;
  condition?: boolean;
  ref?: RefObject<HTMLButtonElement>;
  tooltip?: string;
};

export interface ElnHeaderParams {
  title?: ElnTitleParams[] | JSX.Element;
  description?: string;
  tags?: ElnTagParams[];
  controls?: ElnHeaderControl[];
  showDashboardButton?: boolean;
  hideHeader?: boolean;
}

export type SetElnHeaderType = Dispatch<SetStateAction<ElnHeaderParams>>;

export const Header = ({ title, description, tags, controls, showDashboardButton, hideHeader }: ElnHeaderParams) => {
  const { setELNRoute } = useELNRoutes();

  if (hideHeader) return null;
  return (
    <div className={styles.headerContainer}>
      <div className={styles.headerInformationContainer}>
        {/* TITLE */}
        <div className={styles.headerInformationTitleContainer}>
          {showDashboardButton && (
            <div className={styles.headerInformationTitleElement}>
              <div onClick={() => setELNRoute({})} className={styles.headerInformationTitleHover}>
                <LucideIcon name="menu" />
              </div>
              <LucideIcon name="chevron-right" />
            </div>
          )}
          {title && Array.isArray(title) && title.length > 0 ? (
            title.map((t, index) => (
              <div key={index} className={styles.headerInformationTitleElement}>
                {index !== 0 && <LucideIcon name="chevron-right" />}
                {t.linkTo ? (
                  <Link to={t.linkTo} style={{ textDecoration: "none", color: "inherit" }}>
                    <div
                      data-toggle="tooltip"
                      title={t.label}
                      className={!!t.linkTo ? styles.headerInformationTitleHover : styles.headerInformationTitle}
                      style={{ opacity: index < title.length - 1 ? 0.7 : 1 }}
                    >
                      {t.icon && <LucideIcon name={t.icon} color={t.iconColor || "var(--primary)"} />}
                      <div
                        className={styles.headerInformationTitleLabel}
                        style={{
                          color: t.color || "",
                          maxWidth: index < title.length - 1 ? "20vw" : "",
                        }}
                      >
                        {t.label}
                      </div>
                    </div>
                  </Link>
                ) : (
                  <div
                    data-toggle="tooltip"
                    title={t.label}
                    className={styles.headerInformationTitle}
                    style={{ opacity: index < title.length - 1 ? 0.7 : 1 }}
                  >
                    {t.icon && <LucideIcon name={t.icon} color={t.iconColor || "var(--primary)"} />}
                    <div
                      className={styles.headerInformationTitleLabel}
                      style={{
                        color: t.color || "",
                        maxWidth: index < title.length - 1 ? "20vw" : "",
                      }}
                    >
                      {t.label}
                    </div>
                  </div>
                )}
              </div>
            ))
          ) : title ? (
            <div className="flex row-nowrap align-center gap-5">
              <div className={styles.headerInformationTitleContainerTitle}>{title}</div>
            </div>
          ) : (
            <div className="flex row-nowrap align-center gap-5">
              <div className={styles.headerInformationTitleContainerTitle}>Electronic Lab Notebooks</div>
            </div>
          )}
        </div>
        <div className={styles.headerInformationDetailContainer}>
          {/* TAGS */}
          <div className={styles.headerInformationTagsContainer}>
            {tags &&
              tags.length > 0 &&
              tags
                .filter((t) => t.condition ?? true)
                .map((t, index) =>
                  t.linkTo ? (
                    <Link
                      key={index}
                      to={t.linkTo}
                      className={`label label-${t.color || "secondary"} flex align-center gap-5`}
                      style={{ cursor: "pointer" }}
                    >
                      {t.icon && <LucideIcon name={t.icon} />}
                      {t.label}
                    </Link>
                  ) : (
                    <div key={index} className={`label label-${t.color || "secondary"} flex align-center gap-5`}>
                      {t.icon && <LucideIcon name={t.icon} />}
                      {t.label}
                    </div>
                  )
                )}
          </div>

          {/* DESCRIPTION */}
          <div
            data-toggle="tooltip"
            title={description}
            className={styles.headerInformationDescription}
            style={{
              minWidth: description ? (description.length <= 30 ? `${description?.length}ch` : "200px") : "none",
              fontSize: "1.125em",
              color: "var(--gray-500)",
            }}
          >
            {description || "-"}
          </div>
        </div>
      </div>
      <div className={styles.headerToolbarContainer}>
        {controls &&
          controls.length > 0 &&
          controls
            .filter((e) => e.condition ?? true)
            .map((e, controlIndex) =>
              e.options && e.options.length > 0 ? (
                <div style={{ minWidth: "max-content" }} key={controlIndex}>
                  <div className="btn-group">
                    {e.linkTo ? (
                      <LinkButton
                        linkTo={e.linkTo}
                        className={`btn btn-${e.color}`}
                        id={e.id || `HeaderToolbarDropdownGroup-${controlIndex}`}
                        disabled={e.disabled}
                      >
                        <div className="flex align-center gap-5" data-toggle="tooltip" title={e.tooltip}>
                          <div>
                            {e.icon && <LucideIcon name={e.icon} />}
                            {e.type && (
                              <LucideIcon name={e.type === "delete" ? "minus" : "plus"} style={{ width: 8 }} />
                            )}
                          </div>
                          {e.label && <span className={styles.headerToolbarButtonLabel}>{e.label}</span>}
                        </div>
                      </LinkButton>
                    ) : (
                      <button
                        type="button"
                        className={`btn btn-${e.color}`}
                        id={e.id || `HeaderToolbarDropdownGroup-${controlIndex}`}
                        disabled={e.disabled}
                        onClick={() => e.onClick?.()}
                      >
                        <div className="flex align-center gap-5" data-toggle="tooltip" title={e.tooltip}>
                          <div>
                            {e.icon && <LucideIcon name={e.icon} />}
                            {e.type && (
                              <LucideIcon name={e.type === "delete" ? "minus" : "plus"} style={{ width: 8 }} />
                            )}
                          </div>
                          {e.label && <span className={styles.headerToolbarButtonLabel}>{e.label}</span>}
                        </div>
                      </button>
                    )}
                    <MoreDropdown btn={`btn btn-${e.color}`} drop="right" wrapperCls="btn-group">
                      {e.options
                        .filter((o) => o.condition ?? true)
                        .map((o, optionIndex) =>
                          o.linkTo ? (
                            <LinkButton
                              linkTo={o.linkTo}
                              id={o.id}
                              key={optionIndex}
                              onClick={() => o.onClick?.()}
                              className={`btn btn-${o.color || "ghost-secondary"} btn-block`}
                              disabled={o.disabled}
                              ref={o.ref}
                              data-toggle="tooltip"
                              title={o.tooltip}
                              style={{ textAlign: "left", display: "flex", alignItems: "center", gap: "5px" }}
                            >
                              <div>
                                {o.icon && <LucideIcon name={o.icon} />}
                                {o.type && (
                                  <LucideIcon name={o.type === "delete" ? "minus" : "plus"} style={{ width: 8 }} />
                                )}
                              </div>
                              {o.label && o.label}
                            </LinkButton>
                          ) : (
                            <button
                              key={optionIndex}
                              id={o.id}
                              className={`btn btn-${o.color || "ghost-secondary"} btn-block`}
                              onClick={(e) => o.onClick && o.onClick(e)}
                              disabled={o.disabled}
                              ref={o.ref}
                              style={{ textAlign: "left", display: "flex", alignItems: "center", gap: "5px" }}
                              data-toggle="tooltip"
                              title={o.tooltip}
                            >
                              <div>
                                {o.icon && <LucideIcon name={o.icon} />}
                                {o.type && (
                                  <LucideIcon name={o.type === "delete" ? "minus" : "plus"} style={{ width: 8 }} />
                                )}
                              </div>
                              {o.label && o.label}
                            </button>
                          )
                        )}
                    </MoreDropdown>
                  </div>
                </div>
              ) : e.linkTo ? (
                <LinkButton
                  linkTo={e.linkTo}
                  id={e.id}
                  key={controlIndex}
                  onClick={() => e.onClick?.()}
                  className={`btn btn-${e.color || "default"}`}
                  disabled={e.disabled}
                  ref={e.ref}
                  data-toggle="tooltip"
                  title={e.tooltip}
                >
                  {e.icon && <LucideIcon name={e.icon} />}
                  {e.type && <LucideIcon name={e.type === "delete" ? "minus" : "plus"} style={{ width: 8 }} />}
                  {e.label && <span className={styles.headerToolbarButtonLabel}>{e.label}</span>}
                </LinkButton>
              ) : (
                <button
                  id={e.id}
                  key={controlIndex}
                  onClick={() => e.onClick?.()}
                  className={`btn btn-${e.color || "default"}`}
                  disabled={e.disabled}
                  ref={e.ref}
                  data-toggle="tooltip"
                  title={e.tooltip}
                >
                  {e.icon && <LucideIcon name={e.icon} />}
                  {e.type && <LucideIcon name={e.type === "delete" ? "minus" : "plus"} style={{ width: 8 }} />}
                  {e.label && <span className={styles.headerToolbarButtonLabel}>{e.label}</span>}
                </button>
              )
            )}
      </div>
    </div>
  );
};
