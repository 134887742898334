import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { InputFormField } from "../common/formfields/InputFormField";
import { TextareaFormField } from "../common/formfields/TextareaFormField";
import { FormButtons } from "../common/forms/FormButtons";
import { FormHeader } from "../common/forms/FormHeader";
import styles from "../common/forms/forms.module.css";
import { FormFieldLayout } from "../common/formfields/FormFieldLayouts";
import { ToggleButtonComponent } from "../ViewerUIElements/ToggleButtonComponent";
import { MethodsVirtualizedSelectForm } from "../common/forms/EntityForms/formsVirtualized/MethodsVirtualizedSelectForm";
import { OrganizationsVirtualizedSelectForm } from "../common/forms/EntityForms/formsVirtualized/OrganizationsVirtualizedSelectForm";
import { useContext, useEffect, useState } from "react";
import { PersonsVirtualizedSelectForm } from "../common/forms/EntityForms/formsVirtualized/PersonsVirtualizedSelectForm";
import { CustomTypedEntityFormProps } from "../common/entity/EntityInterfaces";
import { InstrumentFacility, instrumentsConstants } from "../api/Facilities";
import { EntityCustomTypeForm, useCustomTypeForm } from "../Customization/CustomTypes/generics/useCustomTypeForm";
import { phoneNumberRegex } from "../common/misc/RegularExpressionConstats";
import { SessionContext } from "../common/contexts/SessionContext";

export const InstrumentFormSchema = {
  name: yup.string().required("Instrument name is required").typeError("Instrument name is required"),
  method: yup.object().required("Method is required").typeError("Method is required"),
  localPhone: yup
    .string()
    .nullable()
    .optional()
    .matches(phoneNumberRegex, { message: "Invalid phone number", excludeEmptyString: true })
    .typeError("Invalid phone number")
    .default(""),
};

export const InstrumentForm = ({
  fieldLabels,
  permissions,
  title,
  subtitle,
  initialValues,
  onSubmit,
  onCancel,
  submitButtonLabel,
  loading,
  event,
  typeId,
}: CustomTypedEntityFormProps<"instruments">) => {
  const { session } = useContext(SessionContext);

  const [isObsolete, setIsObsolete] = useState<boolean>(!!initialValues?.isObsolete);

  useEffect(() => {
    setIsObsolete(!!initialValues?.isObsolete);
  }, [initialValues?.isObsolete]);

  const { defaultValues, typedFormSchema, processCustomFields, type, setType, types } = useCustomTypeForm({
    initialValues: initialValues,
    formSchema: InstrumentFormSchema,
    typeId: typeId,
    entityType: "Instrument",
  });

  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm<Partial<InstrumentFacility>>({
    values: defaultValues,
    resolver: yupResolver(typedFormSchema),
  });
  return (
    <>
      <FormHeader title={title} subtitle={subtitle} />
      <form
        onSubmit={(e) => {
          e.preventDefault();
        }}
        autoComplete="off"
        className={`form-horizontal ${styles.form_holder}`}
      >
        <fieldset>
          <legend className="col-md-offset-2 col-md-10">Basic details</legend>
        </fieldset>

        <InputFormField
          id="name"
          label={fieldLabels.name}
          errors={errors}
          register={register}
          autoFocus={true}
          required={true}
          placeholder="Enter instrument name..."
        />

        <MethodsVirtualizedSelectForm
          id="method"
          label={fieldLabels.method}
          control={control}
          horizontal
          allowCreateEntity
          required={true}
        />

        <InputFormField
          id="serialnumber"
          label={fieldLabels.serialnumber}
          errors={errors}
          register={register}
          placeholder="Enter serialnumber..."
        />

        <InputFormField
          id="model"
          label={fieldLabels.model}
          errors={errors}
          register={register}
          placeholder="Enter model..."
        />

        <InputFormField
          id="room"
          label={fieldLabels.room}
          errors={errors}
          register={register}
          placeholder="Enter room..."
        />

        <InputFormField
          id="localPhone"
          label={fieldLabels.localPhone}
          errors={errors}
          register={register}
          placeholder="Enter local phone number..."
        />

        <OrganizationsVirtualizedSelectForm
          id="homeLab"
          label={fieldLabels.homeLab}
          control={control}
          horizontal
          allowCreateEntity
        />

        <PersonsVirtualizedSelectForm
          id="homeLabContacts"
          label={fieldLabels.homeLabContacts}
          control={control}
          horizontal
          isMulti
          allowCreateEntity
        />

        <OrganizationsVirtualizedSelectForm
          id="company"
          label={fieldLabels.company}
          control={control}
          horizontal
          allowCreateEntity
        />

        <PersonsVirtualizedSelectForm
          id="companyContacts"
          label={fieldLabels.companyContacts}
          control={control}
          horizontal
          isMulti
          allowCreateEntity
        />

        <FormFieldLayout id="isObsolete" label={fieldLabels.isObsolete} hasLabel horizontal>
          <div className="flex align-center gap-5" style={{ padding: "10px 0" }}>
            <ToggleButtonComponent checked={!isObsolete} setChecked={(checked) => setIsObsolete(!checked)}>
              <></>
            </ToggleButtonComponent>
            {isObsolete && <div className="label label-soft-danger">Not in use</div>}
          </div>
        </FormFieldLayout>

        <TextareaFormField
          id="notes"
          label={fieldLabels.notes}
          errors={errors}
          register={register}
          placeholder="Enter notes..."
        />
        {session?.features.enable_facility_custom_types && (
          <EntityCustomTypeForm
            entityType="Instrument"
            typeId={typeId}
            type={type}
            types={types}
            setType={setType}
            control={control}
            register={register}
            setValue={setValue}
            initialValues={initialValues}
            errors={errors}
            entityConstants={instrumentsConstants}
          />
        )}
        <FormButtons
          groupName={instrumentsConstants.frontendIndexRoute}
          entityId={initialValues ? initialValues.id : undefined}
          onClose={onCancel}
          onSubmit={handleSubmit(
            async (entity) => await onSubmit(processCustomFields({ ...entity, isObsolete: isObsolete }))
          )}
          submitButtonLabel={submitButtonLabel || "Save changes"}
          disabled={isSubmitting}
          errors={errors}
          loading={loading}
        />
      </form>
    </>
  );
};
