import { useCallback, useContext } from "react";
import { useMultipartPostMutation } from "../../api/BaseEntityApi";
import {
  Dataset,
  DatasetForUpdateWriteModel,
  // DatasetFilters,
  DatasetForUploadWriteModel,
  DatasetSearchRequest,
  DatasetSearchResult,
  // IsDatasetKnown,
} from "../../api/Datasets";
import { SessionContext } from "../../common/contexts/SessionContext";
import { DatasetResult } from "./FileFormatAutoDetection";
import { IResultModel } from "../../api/GenericTypes";

export const useFileUploadUtils = () => {
  const { api } = useContext(SessionContext);
  const uploadMutation = useMultipartPostMutation<IResultModel<Dataset>>("datasets/create");
  const updateMutation = useMultipartPostMutation<IResultModel<Dataset>>("datasets/update");

  const findDatasets = useCallback(
    async (payload: DatasetSearchRequest) => {
      const response: DatasetSearchResult[] = await api.post("datasets/find", payload);
      return response;
    },
    [api]
  );

  const generateDatasetSubmissionMultiForm = useCallback(
    (dataset: DatasetResult, metaData: DatasetForUploadWriteModel | DatasetForUpdateWriteModel) => {
      const body = new FormData();
      body.append("Dataset", JSON.stringify(metaData));
      dataset.files
        .filter((f) => f.id) //We filter out "ghost" items, as are injected when a file is deleted but we still want to display it
        .forEach((f) => body.append("files", f.file, f.id));
      return body;
    },
    []
  );

  return {
    findDatasets,
    uploadMutation,
    updateMutation,
    generateDatasetSubmissionMultiForm,
  };
};
