import { useCallback, useContext, useEffect, useRef } from "react";
import {
  MatchResult,
  MatchRequest,
  FormatFileEntryRequest,
  FormatFileEntryResponse,
  FingerprintFragment,
  MatchedDataset,
  Parser,
  ParserFilters,
} from "../../api/Parsers";
import { SessionContext } from "../../common/contexts/SessionContext";
import { LoadingStatus } from "./FileHandlingUtils";
import { Dataset, FormatFileState } from "../../api/Datasets";
import { Nullable } from "../../api/GenericTypes";
import { useFileHashing } from "./FileHashing";
import { renderError } from "../../common/helperfunctions/ApiError";
import { useUnpaginateOrdered } from "../../api/BaseEntityApi";
import path from "path";

export interface UploadedFile extends FormatFileEntryRequest {
  name: string;
  file: File;
  parent: string; // used for the File browser
  sha256?: string;
}

// We Modify some API types here
export interface FormatFileEntryResult extends UploadedFile, FormatFileEntryResponse {
  mtime: number;
  state?: FormatFileState;
}

export type DatasetResultStatus =
  | "idle"
  | "uploading"
  | "created"
  | "updated"
  | "known"
  | "error"
  | "warning"
  | "new"
  | "update";
export interface DatasetResult extends MatchedDataset {
  files: FormatFileEntryResult[];
  status?: DatasetResultStatus;
  queued?: boolean;
  progress?: number;
  error?: string;
  datasetId?: number;
  id: string;
  metadata?: Partial<Nullable<Dataset>>;
}
export interface MatchResultStats {
  new: number;
  update: number;
  success: number;
  updated: number;
  error: number;
  warning: number;
  known: number;
  pending: number;
}

export interface FrontendMatchResult {
  stats?: MatchResultStats;
  checked: boolean;
  datasets: DatasetResult[];
}
export interface MatchResults {
  [parserId: string]: FrontendMatchResult;
}

interface useMatchProps {
  // autoDetect: boolean;
  parserWhiteList: string[] | readonly string[];
  parserBlackList: string[] | readonly string[];
  transientHasher?: boolean;
}
export const useMatch = ({
  // autoDetect,
  parserWhiteList,
  parserBlackList,
  transientHasher = false,
}: useMatchProps) => {
  const { api } = useContext(SessionContext);
  const parsers = useRef<Parser[]>();
  const { calculateSHA256 } = useFileHashing(transientHasher);

  const { data } = useUnpaginateOrdered<Parser, ParserFilters>("parsers", { orderBy: "NAME_ASC" });

  const initialzeParsers = useCallback(async () => {
    parsers.current = data;
  }, [data]);

  useEffect(() => {
    if (!parsers.current) {
      (async () => await initialzeParsers())();
    }
  }, [initialzeParsers]);

  const readFragment = (file: File, offset: number, length: number): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      let endOffset = offset + length;
      if (offset >= file.size) {
        resolve("");
      }
      if (endOffset > file.size) {
        endOffset = file.size;
      }
      const blob = file.slice(offset, endOffset);
      reader.readAsArrayBuffer(blob);
      reader.onloadend = (event) => {
        const target = event.target;
        if (target) {
          const base64 = btoa(
            new Uint8Array(target.result as ArrayBuffer).reduce((data, byte) => data + String.fromCharCode(byte), "")
          );
          resolve(base64);
        } else {
          resolve("");
        }
      };
      reader.onerror = reject;
    });
  };

  const matchFormats = useCallback(
    async (bundle: UploadedFile[], parserIds: string[]) => {
      const files: FormatFileEntryRequest[] = bundle.map((file) => ({
        // name: file.name,
        id: file.id,
        fullPath: file.fullPath,
      }));
      // console.log("Requesting API: ", files);
      // First request
      const firstResponse: MatchResult = await api
        .post("parsers/match", { files: files, formatIds: parserIds } as MatchRequest)
        .catch((err) => renderError(err));
      // console.log("API first response: ", firstResponse);
      if (firstResponse.missingFragments.length === 0) {
        return firstResponse.matches;
      }
      // Second request checking magic bytes
      let matchRequest: FormatFileEntryRequest[] = [];
      const fragmentIds = firstResponse.missingFragments.map((f) => f.id);
      for (const fragment of firstResponse.missingFragments) {
        const _file = bundle.filter((file) => file.id === fragment.id);
        if (Array.isArray(_file) && _file.length > 0) {
          let fragments: FingerprintFragment[] = [];
          const file = _file[0];
          for (const frag of fragment.fragments) {
            const _magicbytes = await readFragment(file.file, frag.offset, frag.length);
            fragments.push({
              offset: frag.offset,
              length: frag.length,
              bytes: _magicbytes,
            });
          }
          const fileRequest: FormatFileEntryRequest = {
            id: file.id,
            fullPath: file.fullPath,
            fragments: fragments,
            // name: file.name,
          };
          matchRequest.push(fileRequest);
        }
      }
      // We add all remaining files
      bundle.forEach((file) => {
        if (!fragmentIds.includes(file.id)) {
          matchRequest.push({
            id: file.id,
            fullPath: file.fullPath,
          });
        }
      });

      const response: MatchResult = await api
        .post("parsers/match", { files: matchRequest, formatIds: parserIds } as MatchRequest)
        .catch((err) => renderError(err));

      // console.log("API final response: ", response);

      return response.matches;
    },
    [api]
  );

  const analyzeFiles = useCallback(
    async (
      bundle: UploadedFile[],
      setLoadingStatus: React.Dispatch<React.SetStateAction<LoadingStatus | undefined>>
    ): Promise<MatchResults> => {
      let totalresults: MatchResults = {};
      if (!parsers.current) {
        await initialzeParsers();
      }
      if (parsers.current) {
        let _parsers = [...parsers.current];
        if (parserWhiteList.length > 0) _parsers = _parsers.filter((parser) => parserWhiteList.includes(parser.id));
        if (parserBlackList.length > 0) _parsers = _parsers.filter((parser) => !parserBlackList.includes(parser.id));
        // New patterns
        const response = await matchFormats(
          bundle,
          _parsers.map((p) => p.id)
        ).catch(() => undefined);

        if (Array.isArray(response) && response.length > 0) {
          const apiUniqueResponseIds = new Set(
            response
              .filter((parserResult) => parserResult.parserId !== "files_generic") // We ignore files_generic as every file there is unique
              .map((parserResult) =>
                parserResult.datasets.map((dataset) => dataset.files.filter((d) => d.isUnique).map((file) => file.id))
              )
              .flat(Infinity)
          );
          const apiResponseIdsLength = apiUniqueResponseIds.size;
          // Hashing only the unique files returned by the backend
          let num = 0;
          for (let i = 0; i < bundle.length; i++) {
            if (apiUniqueResponseIds.has(bundle[i].id) && response) {
              setLoadingStatus({
                message: `Processing ${+num + 1}/${apiResponseIdsLength} files...`,
                progress: Math.floor(((+num + 1) * 100) / apiResponseIdsLength),
              });
              num += 1;
              const sha256 = await calculateSHA256(bundle[i].file);
              bundle[i] = { ...bundle[i], sha256: sha256 };
            }
          }

          for (const parserResult of response) {
            let datasets: DatasetResult[] = [];
            for (const dataset of parserResult.datasets) {
              let files: FormatFileEntryResult[] = [];
              for (const file of dataset.files) {
                const current_file = bundle.filter((d) => d.id === file.id);
                if (Array.isArray(current_file) && current_file.length > 0) {
                  const _file = current_file[0];
                  // console.log(file);
                  files.push({
                    ...file,
                    name: _file.name,
                    file: _file.file,
                    sha256: _file.sha256,
                    // parent: _file.parent,
                    fullPath: file.fullPath,
                    // fullPath: _file.fullPath,
                    // parent: `/${file.fullPath}`.replace(_file.name, ""),
                    parent: file.fullPath.split(path.sep).slice(0, -1).join(path.sep),
                    mtime: _file.file.lastModified / 1000,
                    isUnique: file.isUnique,
                  });
                }
              }
              const datasetResult: DatasetResult = {
                parserId: dataset.parserId,
                name: dataset.name,
                files: files,
                status: undefined,
                progress: undefined,
                error: undefined,
                datasetId: undefined,
                id: dataset.id,
                parentMissing: dataset.parentMissing,
                parentPath: dataset.parentPath,
              };
              datasets.push(datasetResult);
            }
            totalresults[parserResult.parserId] = {
              checked: false,
              stats: undefined,
              datasets: datasets,
            };
          }
        }
      } else {
        throw new Error("Could not fetch parsers");
      }
      return totalresults;
    },
    [calculateSHA256, initialzeParsers, matchFormats, parserBlackList, parserWhiteList]
  );

  return { analyzeFiles, calculateSHA256, parsers: parsers.current };
};
