import React, { CSSProperties } from "react";
import { Resizeable } from "../Resizeable";

// import styles from "./ResizeLayout.module.css";

export const FittingDiv = (props: any) => {
  const {
    width,
    height,
    children,
    style,
    className,
    overflow,
  }: {
    width: number;
    height: number;
    children: any;
    header?: any;
    style?: CSSProperties;
    className?: string;
    overflow: "hidden" | "auto";
  } = props;

  // console.log("set dim", width, height);
  const newStyle: CSSProperties = style || {};
  Object.assign(newStyle, { width: width, height: height, overflow: overflow ?? "hidden" });

  return (
    <div style={newStyle} className={className}>
      {children}
    </div>
  );
};

export const FitToContentDiv = (props: any) => {
  const {
    width,
    height,
    children,
    overflow,
  }: { width: number; height: number; children: any; header?: any; overflow: "hidden" | "auto" } = props;

  // console.log("size", width, height);
  // console.log("size", children, React.Children.count(children));
  if (React.Children.count(children) > 1)
    throw new Error(`AdaptableDiv expect exactly one child (got ${React.Children.count(children)}).`);

  const childrenWithProps = React.Children.map(children, (child) => {
    // checking isValidElement is the safe way and avoids a typescript error too
    // if (child.type.name === "DatasetStructureViewer") console.log("set dim", width, height, child.type.name);
    if (React.isValidElement(child)) return React.cloneElement(child, { width: width, height: height } as any);
    return child;
  });

  return <div style={{ width: width, height: height, overflow: overflow ?? "hidden" }}>{childrenWithProps}</div>;
};

export const AutoResizeDiv = (props: any) => {
  const { children, ...otherProps }: { width: number; height: number; children: any } = props;

  return (
    <Resizeable>
      <FitToContentDiv {...otherProps}>{children}</FitToContentDiv>
    </Resizeable>
  );
};
