import {
  ApplySchemaAttributes,
  command,
  CommandFunction,
  extension,
  ExtensionTag,
  findSelectedNodeOfType,
  NodeExtension,
  NodeExtensionSpec,
  NodeSpecOverride,
  DOMCompatibleAttributes,
  getTextSelection,
  isEqual,
} from "@remirror/core";
import { NodeType } from "@remirror/pm/model";
import { NodeViewComponentProps } from "@remirror/react";
import { ComponentType } from "react";
import { TextEditorTimerWrapper } from "./TextEditorTimerWrapper";
import { deleteTimerOptions, TimerAttributes, TimerOptions, toggleTimerOptions } from "./timer-types";

@extension<TimerOptions>({
  defaultOptions: {
    editable: false,
  },
})
export class TimerExtension extends NodeExtension<TimerOptions> {
  get name() {
    return "timer" as const;
  }

  ReactComponent: ComponentType<NodeViewComponentProps> = ({ node }) => {
    return TextEditorTimerWrapper({
      time: node.attrs.seconds,
      remainingSeconds: node.attrs.remainingSeconds,
      checkpoints: node.attrs.checkpoints,
      showContent: node.attrs.showContent,
      editable: this.options.editable,
    });
  };

  readonly tags = [ExtensionTag.BlockNode];

  createNodeSpec(extra: ApplySchemaAttributes, override: NodeSpecOverride): NodeExtensionSpec {
    const dataAttributeId = "timer-id";
    return {
      draggable: true,
      selectable: true,
      atom: true,
      ...override,
      attrs: {
        ...extra.defaults(),
        id: { default: null },
        seconds: { default: null },
        remainingSeconds: { default: null },
        checkpoints: { default: [] },
        showContent: { default: true },
      },
      toDOM: (node) => {
        const attrs: DOMCompatibleAttributes = {
          "id": node.attrs.id,
          "data-seconds": node.attrs.seconds,
          "data-remainingSeconds": node.attrs.remainingSeconds,
          "data-checkpoints": node.attrs.checkpoints,
          "data-show-content": node.attrs.showContent,
          [dataAttributeId]: 1,
        };
        return ["div", attrs];
      },
      parseDOM: [
        {
          attrs: {
            id: { default: null },
            seconds: { default: null },
            remainingSeconds: { default: null },
            checkpoints: { default: [] },
            showContent: { default: true },
          },
          tag: `div[${[dataAttributeId]}]`,
          getAttrs: (dom) => {
            const node = dom as HTMLAnchorElement;
            const id = node.getAttribute("id");
            const seconds = node.getAttribute("data-seconds");
            const remainingSeconds = node.getAttribute("data-remainingSeconds");
            const checkpoints = node.getAttribute("data-checkpoints");
            const showContent = node.getAttribute("data-show-content");

            if (!seconds && !remainingSeconds) return false;

            return {
              ...extra.parse(node),
              id,
              seconds,
              remainingSeconds,
              checkpoints,
              showContent,
            };
          },
        },
      ],
    };
  }

  @command(toggleTimerOptions)
  toggleTimer(attributes: TimerAttributes): CommandFunction {
    return ({ tr, dispatch }) => {
      const { from, to } = getTextSelection(tr.selection, tr.doc);
      const node = this.type.create(attributes);

      dispatch?.(tr.replaceRangeWith(from, to, node));

      return true;
    };
  }

  @command(deleteTimerOptions)
  deleteTimer(): CommandFunction {
    return ({ tr, dispatch }) => {
      const { from } = getTextSelection(tr.selection, tr.doc);

      dispatch?.(tr.deleteSelection());

      const { focus } = this.store.commands;
      focus({ from, to: from });

      return true;
    };
  }

  @command(toggleTimerOptions)
  updateTimer(attributes: TimerAttributes): CommandFunction {
    return updateNodeAttributes(this.type)(attributes);
  }
}

declare global {
  namespace Remirror {
    interface AllExtensions {
      timer: TimerExtension;
    }
  }
}

function updateNodeAttributes(type: NodeType) {
  return (attributes: TimerAttributes): CommandFunction =>
    ({ state: { tr, selection }, dispatch }) => {
      const node = findSelectedNodeOfType({ types: type, selection });

      if (!node || isEqual(attributes, node.node.attrs)) {
        // Do nothing since the attrs are the same
        return false;
      }

      tr.setNodeMarkup(node.pos, type, { ...node.node.attrs, ...attributes });

      if (dispatch) {
        dispatch(tr);
      }

      return true;
    };
}
