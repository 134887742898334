import { API } from "../../../api/Api";
import { Dataset } from "../../../api/Datasets";
import { LucideIcon } from "../../../common/icon/LucideIcon";
import { Alert } from "../../../common/overlays/Alert/Alert";
import { Container } from "../../../common/panels/Container/Container";
import { Viewer } from "../../../ViewerLayout/ViewerLayout";
import { FullScreenOpts } from "../../Dataset";
import styles from "./Parameters.module.css";

interface Props {
  api: API;
  dataset: Dataset;
  fullscreen?: {
    component: string;
    state: boolean;
  };
  fullScreenToggle?: (component: FullScreenOpts["component"]) => void;
}
export const Parameters = ({ api, dataset, fullscreen, fullScreenToggle }: Props) => {
  return (
    <Container
      title="Parameters"
      controls={
        fullscreen ? (
          <div className={styles.container_controls} onClick={() => fullScreenToggle && fullScreenToggle("parameters")}>
            {fullscreen.state ? <LucideIcon name="minimize" /> : <LucideIcon name="maximize" />}
          </div>
        ) : undefined
      }
    >
      <div className={styles.container}>
        <div className={styles.container_content}>
          {dataset.parsingState === "ParsedSuccessfully" ? (
            <Viewer
              key="ViewerInParameterTile"
              ids={[+dataset.id]}
              settings={{ showViewer: false, showParameter: true, showTrackList: false }}
            />
          ) : (
            <Alert type="light" message="No parameters available" fit centered />
          )}
        </div>
      </div>
    </Container>
  );
};
