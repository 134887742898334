import React, { CSSProperties, useState } from "react";
import { LucideIcon } from "../../icon/LucideIcon";
import styles from "./Alert.module.css";
/**
 * This component returns an Alert overlay
 * Author: CS
 * @param: type, message, children, dismissible, fit (=fits to screen width, centered)
 * @returns: JSX.Element
 */

interface Props {
  type: "primary" | "secondary" | "success" | "info" | "warning" | "danger" | "light";
  message: string | JSX.Element;
  children?: string | JSX.Element | React.ReactNode;
  dismissible?: boolean;
  onDismiss?: () => void;
  fit?: boolean;
  centered?: boolean;
  style?: CSSProperties;
}
export const Alert = ({
  type,
  message,
  children,
  dismissible = false,
  onDismiss,
  fit = false,
  centered = false,
  style,
}: Props) => {
  const [showChildren, setShowChildren] = useState(false);
  const [hide, setHide] = useState(false);
  const entity_icon =
    type === "danger" ? (
      <LucideIcon name="triangle-alert" />
    ) : type === "warning" ? (
      <LucideIcon name="circle-alert" />
    ) : type === "info" ? (
      <LucideIcon name="info" />
    ) : (
      ""
    );
  // const overlay_cls =
  //   type === "Danger" ? styles.overlay_danger : type === "Warning" ? styles.overlay_warning : styles.overlay_info;
  // const icon_cls = type === "Danger" ? styles.icon_danger : type === "Warning" ? styles.icon_warning : styles.icon_info;
  // const msg_cls =
  //   type === "Danger" ? styles.message_danger : type === "Warning" ? styles.message_warning : styles.message_info;
  return (
    <div
      className={`alert alert-${type} ${dismissible ? "alert-dismissible" : showChildren ? "alert-dismissible" : ""} ${
        hide ? styles.alert_hide : styles.alert
      } ${fit && styles.alert_fit} ${centered && styles.alert_centered}`}
      role="alert"
      style={style}
    >
      <div className={styles.container}>
        {showChildren ? (
          children
        ) : (
          <React.Fragment>
            {entity_icon && <div className={styles.icon}>{entity_icon}</div>}
            <div className={styles.message}>
              <span className={styles.message_span}>{message}</span>
            </div>
            {children && (
              // eslint-disable-next-line jsx-a11y/anchor-is-valid
              <button
                className="btn btn-sm btn-ghost-secondary alert-link"
                onClick={(e) => {
                  e.stopPropagation();
                  setShowChildren(() => !showChildren);
                }}
              >
                Show details
              </button>
            )}
          </React.Fragment>
        )}
        {(dismissible || showChildren) && (
          <div className={styles.dismiss}>
            {children && showChildren && (
              <button
                type="button"
                className={`btn btn-xs btn-ghost-${type}`}
                // data-dismiss="alert"
                // aria-label="Close"
                onClick={(e) => {
                  e.stopPropagation();
                  setShowChildren(() => !showChildren);
                }}
              >
                <LucideIcon name="chevron-left" />
              </button>
            )}
            {dismissible && (
              <button
                type="button"
                className={`btn btn-xs btn-ghost-${type}`}
                // data-dismiss="alert"
                // aria-label="Close"
                onClick={(e) => {
                  dismissible && onDismiss && onDismiss();
                  e.stopPropagation();
                  setHide(() => true);
                }}
              >
                <LucideIcon name="x" />
              </button>
            )}
          </div>
        )}
      </div>
    </div>
    // <div className={styles.main}>
    //   <div className={`${styles.overlay} ${overlay_cls}`}>
    //     <div className={`${styles.icon} ${icon_cls}`}>{entity_icon}</div>
    //     <div className={`${styles.message} ${msg_cls}`}>{children}</div>
    //   </div>
    // </div>
  );
};
