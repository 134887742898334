import { EntitySelectOptions } from "../Customization/CustomFields/CustomFieldUtils";
import { labNoteBooksConstants } from "../ELN/types/LabNotebook";
import { labNotebookEntriesConstants } from "../ELN/types/LabNotebookEntry";
import { labNotebookExperimentsConstants } from "../ELN/types/LabNotebookExperiment";
import { CustomType } from "./CustomTypes";
import { datasetsConstants } from "./Datasets";
import { instrumentsConstants } from "./Facilities";
import {
  PropertyTranslator,
  IGenericRequestParametersTranslator,
  IRelationParametersTranslator,
  IPaginationParametersTranslator,
  ISoftDeletableFilterParametersTranslator,
  ICreatedRecordParametersTranslator,
  IModifiedRecordParametersTranslator,
  ITypedEntityCustomValuesSearchParametersTranslator,
  INamedEntityParametersTranslator,
  ICreationRecordLabels,
  IModificationRecordLabels,
  INamedEntityLabels,
  IPermissionedEntityLabels,
  IUniqueEntityLabels,
  IRelatedEntityLabels,
} from "./GenericTranslator";
import {
  IModelWithIntId,
  INamedEntity,
  IPermissionedEntity,
  IUniqueEntity,
  ICreationRecord,
  IRelatedEntity,
  IModificationRecord,
  IEntityMinimalModel,
  IRelation,
  IRelationModel,
  DefaultCreatedByRecordOrderTypeConsts,
  DefaultCreatedOnRecordOrderTypeConsts,
  DefaultModifiedByRecordOrderTypeConsts,
  DefaultModifiedOnRecordOrderTypeConsts,
  DefaultOrderTypeConsts,
  ICreatedRecordParameters,
  IGenericRequestParameters,
  IModifiedRecordParameters,
  IPaginationParameters,
  IRelationParameters,
  ISoftDeletableFilterParameters,
  EntityConstants,
  ITypedEntityCustomValuesSearchParameters,
  CustomFieldValuesSearchParametersCustomFields,
  INamedEntityParameters,
} from "./GenericTypes";
import { inventoriesConstants, inventoryItemsConstants } from "./Inventories";
import { methodsConstants } from "./Methods";
import { organizationsConstants } from "./Organizations";
import { personsConstants } from "./Person";
import { projectsConstants } from "./Projects";
import { samplesConstants } from "./Samples";
import { sharedContentConstants } from "./SharedContent";

export const customFieldConstants: EntityConstants<CustomField, CustomFieldFilters> = {
  resource: "custom_fields",
  frontendIndexRoute: "custom_fields",
  entitySingular: "custom field",
  entityPlural: "custom fields",
  icon: "text-cursor-input",
};

export interface CustomField
  extends IModelWithIntId,
    IPermissionedEntity,
    IRelatedEntity<CustomFieldRelations>,
    INamedEntity,
    IUniqueEntity,
    // ISoftDeletable,
    ICreationRecord,
    IModificationRecord {
  description: string;
  placeholder: string;
  dataType: CustomFieldDataType;
  customTypeConstraint?: IEntityMinimalModel<CustomType["id"]>[] | null;
  required?: boolean;
  readOnly?: boolean;
  validationRegexp?: string;
  validationMessage?: string;
  showAsTextArea?: boolean;
  enumOptions?: any[];
  enumOptionsFromValues?: boolean;
  defaultValues?: any;
}

export const CustomFieldFieldLabels: PropertyTranslator<CustomField> & {
  isMultiValue: string;
  isTimeRange: string;
  isFloat: string;
  isDateTime: string;
  isDateTimeRange: string;
  entitySelectOption: string;
} = {
  ...INamedEntityLabels,
  ...IUniqueEntityLabels,
  ...IPermissionedEntityLabels,
  ...ICreationRecordLabels,
  ...IModificationRecordLabels,
  ...IRelatedEntityLabels,
  id: "Custom field ID",
  description: "Description",
  placeholder: "Placeholder",
  dataType: "Data type",
  customTypeConstraint: "Custom type constraints",
  required: "Required",
  readOnly: "Read-only",
  validationRegexp: "Validation regular expression",
  validationMessage: "Validation message",
  showAsTextArea: "Multiline text",
  enumOptions: "Predefined options",
  enumOptionsFromValues: "Dynamic options",
  defaultValues: "Default value",
  isMultiValue: "Multi selectable",
  isTimeRange: "Time range",
  isFloat: "Floating precision",
  isDateTime: "Date and time",
  isDateTimeRange: "Date and time range",
  entitySelectOption: "Entity reference type",
} as const;

export const CustomFieldDataTypes = [
  "String",
  "StringArray",
  "Integer",
  "IntegerArray",
  "Float",
  "FloatArray",
  "Boolean",
  "Date",
  "DateArray",
  "DateTime",
  "DateTimeArray",
  "Time",
  "TimeArray",
  "TimeRange",
  "DateTimeRange",
  "Dataset",
  "DatasetArray",
  "Sample",
  "SampleArray",
  "Project",
  "ProjectArray",
  "Organization",
  "OrganizationArray",
  "Person",
  "PersonArray",
  "Method",
  "MethodArray",
  "Instrument",
  "InstrumentArray",
  "SharedContent",
  "SharedContentArray",
  "LabNotebook",
  "LabNotebookArray",
  "LabNotebookExperiment",
  "LabNotebookExperimentArray",
  "LabNotebookEntry",
  "LabNotebookEntryArray",
  "ViewableEntity",
  "Inventory",
  "InventoryArray",
  "EntityBarCode",
  "Url",
  "UrlArray",
] as const;

export type CustomFieldDataType = (typeof CustomFieldDataTypes)[number];

export interface CustomFieldSuggestions extends IEntityMinimalModel<CustomField["id"]> {}

export interface CustomFieldRelations extends IRelationModel {
  types: IRelation;
  datasets: IRelation;
  samples: IRelation;
  inventories: IRelation;
  projects: IRelation;
  persons: IRelation;
  facilities: IRelation;
}
export interface CustomFieldFilters
  extends IGenericRequestParameters<CustomField, CustomFieldSortingOptions>,
    IRelationParameters,
    IPaginationParameters,
    IRelationParameters,
    ISoftDeletableFilterParameters,
    ICreatedRecordParameters,
    IModifiedRecordParameters,
    ITypedEntityCustomValuesSearchParameters<CustomFieldValuesSearchParametersCustomFields>,
    INamedEntityParameters {
  dataTypes?: CustomFieldDataType[] | null;
}

export const CustomFieldSortingOptionsConsts = [
  ...DefaultOrderTypeConsts,
  ...DefaultCreatedOnRecordOrderTypeConsts,
  ...DefaultCreatedByRecordOrderTypeConsts,
  ...DefaultModifiedOnRecordOrderTypeConsts,
  ...DefaultModifiedByRecordOrderTypeConsts,
  "DATATYPE_ASC",
  "DATATYPE_DESC",
] as const;
export type CustomFieldSortingOptions = (typeof CustomFieldSortingOptionsConsts)[number];

export const CustomFieldFiltersTranslator: PropertyTranslator<CustomFieldFilters> = {
  ...IGenericRequestParametersTranslator,
  ...IRelationParametersTranslator,
  ...IPaginationParametersTranslator,
  ...IRelationParametersTranslator,
  ...ISoftDeletableFilterParametersTranslator,
  ...ICreatedRecordParametersTranslator,
  ...IModifiedRecordParametersTranslator,
  ...ITypedEntityCustomValuesSearchParametersTranslator,
  ...INamedEntityParametersTranslator,
  dataTypes: "Data type(s)",
} as const;

export const CustomFieldDataTypeUtils = {
  CanDefineEnumOptionsFromValues: (dataType: CustomFieldDataType) => {
    switch (dataType) {
      case "String":
      case "StringArray":
      case "Integer":
      case "IntegerArray":
      case "Float":
      case "FloatArray":
      case "Url":
      case "UrlArray":
        return true;
      default:
        return false;
    }
  },
  IsValueDataType: (dataType: CustomFieldDataType) => {
    switch (dataType) {
      case "EntityBarCode":
        return false;
      default:
        return true;
    }
  },
  IsDefaultValueDataType: (dataType: CustomFieldDataType) => {
    switch (dataType) {
      case "EntityBarCode":
      case "ViewableEntity":
        return false;
      default:
        return true;
    }
  },
  IsValidableDataType: (dataType: CustomFieldDataType) => {
    switch (dataType) {
      case "String":
      case "Integer":
      case "Float":
      case "Url":
        return true;
      default:
        return false;
    }
  },
  IsStringDataType: (dataType: CustomFieldDataType) => {
    switch (dataType) {
      case "String":
      case "Date":
      case "DateTime":
      case "Time":
      case "Url":
        return true;
      default:
        return false;
    }
  },
  IsNumberDataType: (dataType: CustomFieldDataType) => {
    switch (dataType) {
      case "Integer":
      case "Float":
      case "IntegerArray":
      case "FloatArray":
        return true;
      default:
        return false;
    }
  },
  IsCustomizibleRegexDataType: (dataType: CustomFieldDataType) => {
    switch (dataType) {
      case "String":
      case "StringArray":
      case "Integer":
      case "IntegerArray":
      case "Float":
      case "FloatArray":
      case "Url":
      case "UrlArray":
        return true;
      default:
        return false;
    }
  },
  IsPrimitiveDataType: (dataType: CustomFieldDataType) => {
    switch (dataType) {
      case "String":
      case "StringArray":
      case "Integer":
      case "IntegerArray":
      case "Float":
      case "FloatArray":
      case "Boolean":
      case "Date":
      case "DateArray":
      case "DateTime":
      case "DateTimeArray":
      case "Time":
      case "TimeArray":
      case "DateTimeRange":
      case "EntityBarCode":
      case "Url":
      case "UrlArray":
        return true;
      default:
        return false;
    }
  },
  IsSingleEntityReference: (dataType: CustomFieldDataType) => {
    switch (dataType) {
      case "Dataset":
      case "Sample":
      case "Project":
      case "Organization":
      case "Person":
      case "Method":
      case "Instrument":
      case "SharedContent":
      case "LabNotebook":
      case "LabNotebookExperiment":
      case "LabNotebookEntry":
      case "ViewableEntity":
      case "Inventory":
        return true;
      default:
        return false;
    }
  },
  IsMultiEntityReference: (dataType: CustomFieldDataType) => {
    switch (dataType) {
      case "DatasetArray":
      case "SampleArray":
      case "ProjectArray":
      case "OrganizationArray":
      case "PersonArray":
      case "MethodArray":
      case "InstrumentArray":
      case "SharedContentArray":
      case "LabNotebookArray":
      case "LabNotebookExperimentArray":
      case "LabNotebookEntryArray":
      case "InventoryArray":
        return true;
      default:
        return false;
    }
  },
  CanDefineCustomTypeConstraint: (dataType: CustomFieldDataType) => {
    switch (dataType) {
      case "Dataset":
      case "Sample":
      case "Project":
      case "Person":
      case "Method":
      case "Instrument":
      case "Inventory":
      case "DatasetArray":
      case "SampleArray":
      case "ProjectArray":
      case "PersonArray":
      case "MethodArray":
      case "InstrumentArray":
      case "InventoryArray":
        return true;
      default:
        return false;
    }
  },
  IsArrayDataType: (dataType: CustomFieldDataType) => {
    switch (dataType) {
      case "StringArray":
      case "IntegerArray":
      case "FloatArray":
      case "DateArray":
      case "DateTimeArray":
      case "TimeArray":
      case "DateTimeRange":
      case "DatasetArray":
      case "SampleArray":
      case "ProjectArray":
      case "OrganizationArray":
      case "PersonArray":
      case "MethodArray":
      case "InstrumentArray":
      case "SharedContentArray":
      case "LabNotebookArray":
      case "LabNotebookExperimentArray":
      case "LabNotebookEntryArray":
      case "InventoryArray":
      case "UrlArray":
        return true;
      default:
        return false;
    }
  },
  HasEditForm: (dataType: CustomFieldDataType) => {
    switch (dataType) {
      case "EntityBarCode":
        return false;
      default:
        return true;
    }
  },
  HasMultiSelect: (dataType: CustomFieldDataType) => {
    switch (dataType) {
      case "String":
      case "Integer":
      case "Float":
      case "Date":
      case "DateTime":
      case "Time":
      case "Dataset":
      case "Sample":
      case "Project":
      case "Organization":
      case "Person":
      case "Method":
      case "Instrument":
      case "SharedContent":
      case "LabNotebook":
      case "LabNotebookExperiment":
      case "LabNotebookEntry":
      case "Inventory":
      case "StringArray":
      case "IntegerArray":
      case "FloatArray":
      case "DateArray":
      case "DateTimeArray":
      case "TimeArray":
      case "DatasetArray":
      case "SampleArray":
      case "ProjectArray":
      case "OrganizationArray":
      case "PersonArray":
      case "MethodArray":
      case "InstrumentArray":
      case "SharedContentArray":
      case "LabNotebookArray":
      case "LabNotebookExperimentArray":
      case "LabNotebookEntryArray":
      case "InventoryArray":
      case "Url":
      case "UrlArray":
        return true;
      default:
        return false;
    }
  },
  MapAbstractedEntityReferenceTypeToEntityConstants: (dataType?: EntitySelectOptions): EntityConstants => {
    switch (dataType) {
      case "Dataset":
        return datasetsConstants;
      case "Sample":
        return samplesConstants;
      case "Project":
        return projectsConstants;
      case "Organization":
        return organizationsConstants;
      case "Person":
        return personsConstants;
      case "Method":
        return methodsConstants;
      case "Instrument":
        return instrumentsConstants;
      case "SharedContent":
        return sharedContentConstants;
      case "LabNotebook":
        return labNoteBooksConstants;
      case "LabNotebookExperiment":
        return labNotebookExperimentsConstants;
      case "LabNotebookEntry":
        return labNotebookEntriesConstants;
      case "Inventory":
        return inventoriesConstants;
      default:
        throw new Error(`No entity constants found for data type: ${dataType}`);
    }
  },
  MapDataTypeToEntityConstants: (dataType?: CustomFieldDataType): EntityConstants => {
    switch (dataType) {
      case "Dataset":
      case "DatasetArray":
        return datasetsConstants;
      case "Sample":
      case "SampleArray":
        return samplesConstants;
      case "Project":
      case "ProjectArray":
        return projectsConstants;
      case "Organization":
      case "OrganizationArray":
        return organizationsConstants;
      case "Person":
      case "PersonArray":
        return personsConstants;
      case "Method":
      case "MethodArray":
        return methodsConstants;
      case "Instrument":
      case "InstrumentArray":
        return instrumentsConstants;
      case "SharedContent":
      case "SharedContentArray":
        return sharedContentConstants;
      case "LabNotebook":
      case "LabNotebookArray":
        return labNoteBooksConstants;
      case "LabNotebookExperiment":
      case "LabNotebookExperimentArray":
        return labNotebookExperimentsConstants;
      case "LabNotebookEntry":
      case "LabNotebookEntryArray":
        return labNotebookEntriesConstants;
      case "ViewableEntity":
        return datasetsConstants;
      case "Inventory":
      case "InventoryArray":
        return inventoryItemsConstants;
      default:
        throw new Error(`No entity constants found for data type: ${dataType}`);
    }
  },
} as const;

export interface CustomFieldValuesFilters extends IPaginationParameters {
  searchTerm?: string | null;
  includeCount?: boolean;
}
