import { useCallback, useContext, useEffect, useState } from "react";
import { useQuery, useQueryClient, UseQueryOptions } from "@tanstack/react-query";
import { SessionContext } from "../../common/contexts/SessionContext";
import { convertDateFieldsInList } from "../ApiUtils";
import { AutoloadClient, DataSource, dataSourceConstants } from "../DataSource";
import { Bridge, bridgeConstants } from "../Bridges";
import {
  useEntityDetail,
  useEntityPost,
  useGet,
  useListEntity,
  useNonPaginatedListEntityOld,
  usePostMutation,
} from "../BaseEntityApi";
import { ResourceName } from "../../main/Routing";
import { DataSourceStatus } from "../DataSourceStatus";
import { showtoast } from "../../common/overlays/Toasts/showtoast";

export const useAutoloadClientList = () =>
  useNonPaginatedListEntityOld<AutoloadClient>("autoload_clients", null, {
    mappingFunction: (data) => convertDateFieldsInList<AutoloadClient>(data, ["firstSeen"]),
  });

export const useAutoloadClientActions = () => {
  const { api } = useContext(SessionContext);
  return {
    approveClient: (id: string) => api.post(`autoload_clients/${id}/approve`, null) as Promise<Bridge>,
  };
};

// Autoload sources

// TODO: add type for params
export const useAutoloadSourcesList = (params: any) => useListEntity<Bridge>(bridgeConstants.resource, params);

export const useAutoloadSourceDetail = (id: number, options: UseQueryOptions<any, unknown, Bridge>) =>
  useEntityDetail<Bridge, any>(bridgeConstants.resource, id, undefined, options);

export interface AutoloadClientsReadDirectory {
  name: string;
  size: number;
  modTime: string;
  isDir: boolean;
}
export type AutoloadClientsReadDirectories = AutoloadClientsReadDirectory[];
export const useAutoloadSourceReadDirectory = (
  id: Bridge["id"],
  params: any,
  options: UseQueryOptions<any, unknown, AutoloadClientsReadDirectories>
) => useEntityPost<AutoloadClientsReadDirectories>(bridgeConstants.resource, id, "read_directory", params, options);

export interface AutoloadSimulation {
  [property: string]: {
    name: string;
    relativePath: string;
    isDir: string;
    hash: string;
  };
}
export const useSimulateAutoloadDirectory = (
  id: number | string,
  params: any,
  options: UseQueryOptions<any, unknown, AutoloadSimulation>
) => useEntityPost<AutoloadSimulation>(dataSourceConstants.resource, id, "simulate_autoload", params, options);

export interface AutoloadStatusResponse {
  isConnected: boolean;
  autoloadStatusList: DataSourceStatus[];
}

export const useClientBridgeStatus = (
  bridgeId: number,
  options?: UseQueryOptions<any, unknown, AutoloadStatusResponse>
) => {
  const { api } = useContext(SessionContext);
  return useQuery({
    queryKey: [bridgeConstants.resource, bridgeId, "client_status"],
    queryFn: async ({ signal }) => api.post(`${bridgeConstants.resource}/${bridgeId}/get_client_status`, null, signal),
    ...options,
  });
};

export interface SFTPConnectionParams {
  id?: number | null;
  hostname?: string | null;
  port?: number | null;
  username?: string | null;
  password?: string | null;
  privateKey?: string | null;
}

export interface SftpConnectionStatus {
  errors: string[];
  hostname: string;
  ipAddress: string;
  success: boolean;
  warnings: string[];
}

export interface SftpPingStatus {
  byteSize: number;
  errors: string[];
  hostname: string;
  ipAddress: string;
  success: boolean;
  timeInMs: number;
}

export const usePingConnection = () => {
  const [pingSuccessful, setPingSuccessful] = useState<SftpPingStatus>();
  const { mutateAsync, isLoading: isIdlePingingConnection } = usePostMutation<SftpPingStatus, SFTPConnectionParams>({
    resource: `${bridgeConstants.resource}/ping` as ResourceName,
  });

  const clearPingStatus = useCallback(() => {
    setPingSuccessful(undefined);
  }, []);

  const pingConnection = useCallback(
    async ({ hostname, port, username, password, privateKey }: SFTPConnectionParams) => {
      const result = await mutateAsync(
        {
          body: {
            hostname: hostname,
            port: port,
            username: username,
            password: password,
            privateKey: privateKey,
          },
        },
        {
          onSuccess: (res) => {
            setPingSuccessful(res);
          },
          onError: (err) => {
            if (typeof err === "object" && Object.hasOwn(err as any, "message"))
              setPingSuccessful({
                byteSize: 0,
                errors: [(err as any).message],
                hostname: hostname ?? "N/A",
                ipAddress: "N/A",
                success: false,
                timeInMs: 0,
              });
            else if (typeof err === "string")
              setPingSuccessful({
                byteSize: 0,
                errors: [err],
                hostname: hostname ?? "N/A",
                ipAddress: "N/A",
                success: false,
                timeInMs: 0,
              });
            else
              setPingSuccessful({
                byteSize: 0,
                errors: ["Unknown error"],
                hostname: hostname ?? "N/A",
                ipAddress: "N/A",
                success: false,
                timeInMs: 0,
              });
          },
        }
      ).catch(() => {});

      return result;
    },
    [mutateAsync]
  );

  return { pingSuccessful, pingConnection, isIdlePingingConnection, clearPingStatus };
};

export const useSFTPCheckConnection = () => {
  const [connectionSuccessful, setConnectionSuccessful] = useState<SftpConnectionStatus>();
  const { mutateAsync, isLoading: isIdleCheckingConnection } = usePostMutation<
    SftpConnectionStatus,
    SFTPConnectionParams
  >({ resource: `${bridgeConstants.resource}/check_connection` as ResourceName, hideToast: true });

  const clearConnectionStatus = useCallback(() => {
    setConnectionSuccessful(undefined);
  }, []);

  const checkConnection = useCallback(
    async ({ id, hostname, port, username, password, privateKey }: SFTPConnectionParams) => {
      const result = await mutateAsync(
        {
          body: {
            id: id,
            hostname: hostname,
            port: port,
            username: username,
            password: password,
            privateKey: privateKey,
          },
        },
        {
          onSuccess: (res) => {
            setConnectionSuccessful(res);
          },
          onError: (err) => {
            if (typeof err === "object" && Object.hasOwn(err as any, "message"))
              setConnectionSuccessful({
                errors: [(err as any).message],
                hostname: hostname ?? "N/A",
                ipAddress: "N/A",
                success: false,
                warnings: [],
              });
            else if (typeof err === "string")
              setConnectionSuccessful({
                errors: [err],
                hostname: hostname ?? "N/A",
                ipAddress: "N/A",
                success: false,
                warnings: [],
              });
            else
              setConnectionSuccessful({
                errors: ["Unknown error"],
                hostname: hostname ?? "N/A",
                ipAddress: "N/A",
                success: false,
                warnings: [],
              });
          },
        }
      ).catch(() => {});

      return result;
    },
    [mutateAsync]
  );

  return {
    connectionSuccessful,
    checkConnection,
    isIdleCheckingConnection,
    clearConnectionStatus,
  };
};

export const useSFTPCheckConnectionById = ({ id }: { id: Bridge["id"] }) => {
  const [connectionSuccessful, setConnectionSuccessful] = useState<SftpConnectionStatus>();
  const { refetch, isFetching } = useGet<SftpConnectionStatus>(
    `${bridgeConstants.resource}/${id}/check_connection` as ResourceName,
    undefined,
    {
      onSuccess: (res) => {
        setConnectionSuccessful(res);
      },
      onError: (err) => {
        if (typeof err === "object" && Object.hasOwn(err as any, "message"))
          setConnectionSuccessful({
            errors: [(err as any).message],
            hostname: "N/A",
            ipAddress: "N/A",
            success: false,
            warnings: [],
          });
        else if (typeof err === "string")
          setConnectionSuccessful({
            errors: [err],
            hostname: "N/A",
            ipAddress: "N/A",
            success: false,
            warnings: [],
          });
        else
          setConnectionSuccessful({
            errors: ["Unknown error"],
            hostname: "N/A",
            ipAddress: "N/A",
            success: false,
            warnings: [],
          });
      },
      enabled: false,
    }
  );

  const clearConnectionStatus = useCallback(() => {
    setConnectionSuccessful(undefined);
  }, []);

  useEffect(() => {
    // Clear connection status after 1 minute since SFTP connection check is not continuous
    var timeout = setTimeout(() => clearConnectionStatus(), 60000);
    return () => clearTimeout(timeout);
  }, [clearConnectionStatus, connectionSuccessful]);

  const checkConnection = useCallback(async () => {
    await refetch().catch(() => {});
  }, [refetch]);

  return {
    connectionSuccessful,
    checkConnection,
    isIdleCheckingConnection: isFetching,
    clearConnectionStatus,
  };
};

export const useAutoloadConfigurationsActions = (dataSource?: DataSource) => {
  const [isRunning, setIsRunning] = useState<boolean>(false);
  const queryClient = useQueryClient();
  const { api } = useContext(SessionContext);

  useEffect(() => {
    setIsRunning(dataSource?.status?.lastClientStatus?.runState === "Running");
  }, [dataSource?.status?.lastClientStatus?.runState, setIsRunning]);

  const trigger = useCallback(
    async (id: DataSource["id"], message?: string) => {
      setIsRunning(true);
      try {
        await api.get(`${dataSourceConstants.resource}/${id}/trigger_autoload`, null);
        message && showtoast("success", message);
      } catch (err) {
        showtoast("error", "An error occurred while starting data fetching");
      }
    },
    [api]
  );

  const cancel = useCallback(
    async (id: DataSource["id"], message?: string) => {
      try {
        await api.get(`${dataSourceConstants.resource}/${id}/cancel_autoload`, null);
        queryClient.invalidateQueries({ queryKey: [dataSourceConstants.resource, "detail", id] });
        message && showtoast("success", message);
        setIsRunning(false);
      } catch (err) {
        showtoast("error", "An error occurred while cancelling data fetching");
      }
    },
    [api, queryClient]
  );

  return {
    triggerAutoload: trigger,
    cancelAutoload: cancel,
    isRunning,
    setIsRunning,
  };
};
