import { DeepMap, FieldError, UseFormRegister } from "react-hook-form";
import { FormFieldError, FormFieldLayout } from "./FormFieldLayouts";
import styles from "./formfields.module.css";
import { CSSProperties } from "react";
export interface InputFormFieldProps {
  id: string;
  label: string;
  errors?: DeepMap<any, FieldError>;
  register: UseFormRegister<any>;
  required?: boolean;
  autoFocus?: boolean;
  readOnly?: boolean;
  type?: "text" | "textarea" | "date" | "datetime-local" | "number" | "password" | "radio";
  horizontal?: boolean;
  values?: any[];
  placeholder?: any;
  style?: CSSProperties;
  wrapperContainerstyle?: CSSProperties;
  defaultValue?: string | number;
  buttons?: React.ReactNode;
  uncontained?: boolean;
  onBlur?: () => void;
  autoComplete?: string;
  disabled?: boolean;
  children?: React.ReactNode;
  min?: number;
  max?: number;
}

export const InputFormField = ({
  id,
  label,
  errors,
  register,
  required,
  autoFocus,
  readOnly,
  type = "text",
  horizontal = true,
  placeholder = "Insert text...",
  style,
  wrapperContainerstyle,
  defaultValue,
  buttons,
  uncontained = false,
  onBlur,
  autoComplete = "off",
  disabled = false,
  children,
  min,
  max,
}: InputFormFieldProps) => {
  const { onBlur: onBlurRegister } = register(id);

  //#region error
  const fieldPath = id.split(".");
  let error: any = undefined;
  for (const path of fieldPath) {
    if (!error) error = errors?.[path];
    else error = error?.[path];
  }
  //#endregion
  if (uncontained) {
    return (
      <>
        <div className={`flex`} style={{ width: "100%", height: "100%", ...wrapperContainerstyle }}>
          <input
            className={`${error ? "border-danger form-control" : "form-control"} ${required ? "required" : ""}`}
            type={type}
            autoFocus={autoFocus}
            readOnly={readOnly}
            {...register(id)}
            placeholder={placeholder}
            style={{ ...style, ...(readOnly && { cursor: "not-allowed" }), height: "100%" }}
            defaultValue={defaultValue}
            onBlur={onBlur ?? onBlurRegister}
            autoComplete={autoComplete}
            disabled={disabled}
            min={min}
            max={max}
          />
          {buttons ? <div className={styles.buttons}>{buttons}</div> : null}
          {children}
        </div>
        <FormFieldError id={id} errors={errors} />{" "}
      </>
    );
  } else {
    return (
      <FormFieldLayout id={id} label={label} required={required} horizontal={horizontal} hasLabel={label !== ""}>
        <div className={styles.container} style={wrapperContainerstyle}>
          <input
            className={`${error ? "border-danger form-control" : "form-control"}`}
            type={type}
            autoFocus={autoFocus}
            readOnly={readOnly}
            {...register(id)}
            placeholder={placeholder}
            style={{ ...style, ...(readOnly && { cursor: "not-allowed" }) }}
            defaultValue={defaultValue}
            onBlur={onBlur ?? onBlurRegister}
            autoComplete={autoComplete}
            disabled={disabled}
            min={min}
            max={max}
          />
          {buttons ? <div className={styles.buttons}>{buttons}</div> : null}
          {children}
        </div>
        <FormFieldError id={id} errors={errors} />{" "}
        {/* it might need to pass label rather than id and display message with capital letter */}
      </FormFieldLayout>
    );
  }
};
