import React from "react";
import { Folder } from "./Folder/Folder";
// import { File } from "./File/File";
import { TreeElement, TreeElementBase } from "../../tiles/Files/Files";

interface FileTreeProps<T extends TreeElementBase> {
  files: TreeElement<T>[];
  parentId?: string;
  level: number;
  onFileClick?: (item: TreeElement<T>) => void;
  onFolderClick?: (item: TreeElement<T>) => void;
  fileControls?: (item: TreeElement<T>) => React.ReactNode;
  folderControls?: (item: TreeElement<T>) => React.ReactNode;
  fixedControls?: (item: TreeElement<T>) => React.ReactNode;
  rowClassName?: (item: TreeElement<T>) => string;
}
export const FileTree = <T extends TreeElementBase>({
  files,
  parentId = undefined,
  level,
  onFileClick,
  onFolderClick,
  fileControls,
  folderControls,
  fixedControls,
  rowClassName,
}: FileTreeProps<T>) => {
  const items = files
    .filter((item) => item.parentId === parentId)
    .sort((a, b) => (a.hasChildren.length > 0 ? -1 : 1))
    .sort((a, b) => (a.hasChildren.length === 0 && b.hasChildren.length === 0 ? (a.name > b.name ? 1 : -1) : 0));

  if (!items.length) {
    return null;
  } else
    return (
      <div className="flex col-nowrap" style={{ width: "100%", height: "100%" }}>
        {items.map((item) => (
          <Folder<T>
            label={""}
            key={item.id}
            item={item}
            level={level}
            children={
              <FileTree
                files={files}
                parentId={item.id}
                level={level + 1}
                onFileClick={onFileClick}
                onFolderClick={onFolderClick}
                fileControls={fileControls}
                folderControls={folderControls}
                fixedControls={fixedControls}
                rowClassName={rowClassName}
              />
            }
            onFileClick={onFileClick}
            onFolderClick={onFolderClick}
            fileControls={fileControls}
            folderControls={folderControls}
            fixedControls={fixedControls}
            rowClassName={rowClassName}
            collapsed
          />
        ))}
      </div>
    );
};
