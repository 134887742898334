import { EntityAddForm, EntityAddFormPage } from "../../common/entity/EntityAddForm";
import { LabNotebookTemplate } from "../types/LabNotebookTemplate";
import { LabNotebookTemplateForm } from "./LabNotebookTemplateForm";
import { EntityCloneFormProps, EntityDetailViewProps, EntityEditFormProps } from "../../common/entity/EntityInterfaces";
import { EntityCloneForm, EntityCloneFormPage } from "../../common/entity/EntityCloneForm";
import { EntityEditForm, EntityEditFormPage } from "../../common/entity/EntityEditForm";
import { EntityDetailPage, EntityDetailView } from "../../common/entity/EntityDetailPage";
import { LabNotebookTemplatesDetail } from "./LabNotebookTemplatesDetail";
import { EntityTablePage } from "../../common/entity/EntityTablePage";
import { LabNotebookTemplatesTable } from "./Table/LabNotebookTemplatesTable";

// Add
export const LabNotebookTemplateAddForm = ({
  onSuccess,
  onCancel,
}: {
  onSuccess?: (e: LabNotebookTemplate) => void;
  onCancel?: () => void;
}) => {
  return (
    <EntityAddForm entityTypeId={"notebookTemplates"} onCancel={onCancel} onSuccess={onSuccess}>
      {(props) => <LabNotebookTemplateForm {...props} />}
    </EntityAddForm>
  );
};

export const LabNotebookTemplateAddFormPage = () => {
  return (
    <EntityAddFormPage>
      <LabNotebookTemplateAddForm />
    </EntityAddFormPage>
  );
};

// Clone
export const LabNotebookTemplateCloneForm = ({ id }: EntityCloneFormProps<"notebookTemplates">) => {
  return (
    <EntityCloneForm id={id} entityTypeId={"notebookTemplates"}>
      {(props) => <LabNotebookTemplateForm {...props} />}
    </EntityCloneForm>
  );
};

export const LabNotebookTemplateCloneFormPage = () => {
  return (
    <EntityCloneFormPage<"notebookTemplates"> isIntId>
      {(id) => <LabNotebookTemplateCloneForm id={id} />}
    </EntityCloneFormPage>
  );
};

// Edit
export const LabNotebookTemplateEditForm = ({ id, onSuccess, onCancel }: EntityEditFormProps<"notebookTemplates">) => {
  return (
    <EntityEditForm id={id} entityTypeId={"notebookTemplates"} filters={{}} onSuccess={onSuccess} onCancel={onCancel}>
      {(props) => <LabNotebookTemplateForm {...props} />}
    </EntityEditForm>
  );
};

export const LabNotebookTemplateEditFormPage = () => {
  return (
    <EntityEditFormPage<"notebookTemplates"> isIntId>
      {(id) => <LabNotebookTemplateEditForm id={id} />}
    </EntityEditFormPage>
  );
};

// Detail
export const LabNotebookTemplatesDetailView = ({ id }: EntityDetailViewProps<"notebookTemplates">) => {
  return (
    <EntityDetailView id={id} entityTypeId={"notebookTemplates"} filters={{}}>
      {(props) => <LabNotebookTemplatesDetail {...props} />}
    </EntityDetailView>
  );
};

export const LabNotebookTemplateDetailsPage = () => {
  return (
    <EntityDetailPage<"notebookTemplates"> isIntId>
      {(id) => <LabNotebookTemplatesDetailView id={id} />}
    </EntityDetailPage>
  );
};

export const LabNotebookTemplatesTablePage = () => {
  return (
    <EntityTablePage entityTypeId={"notebookTemplates"}>
      {(props) => <LabNotebookTemplatesTable {...props} />}
    </EntityTablePage>
  );
};
