import { useContext, useMemo, useState } from "react";
import { SessionContext } from "../../common/contexts/SessionContext";
import { SearchInput } from "../../common/forms/SearchInput/SearchInput";
import { LucideIcon } from "../../common/icon/LucideIcon";
import TableView, { TableViewLabel } from "../../common/panels/TableView/TableView";
import { ColumnsSettings } from "../../common/tables/ColumnsSelector/ColumnsSelector";
import { AlertModal } from "../../common/modals/AlertModal/AlertModal";
// import { showtoast } from "../../common/overlays/Toasts/showtoast";
import { MoreDropdown } from "../../common/buttons/MoreDropdown/MoreDropdown";
import { EntityTable } from "../../common/tables/EntityTable/EntityTable";
import {
  GenericVirtualizedTableCells,
  SortState,
} from "../../common/tables/GenericVirtualizedTable/GenericVirtualizedTableTypes";
import { EntityFilterIndicator } from "../../common/tables/EntityFilterIndicator/EntityFilterIndicator";
import { EntityTableTabs } from "../../common/tables/Tabs/EntityTableTabs";
import { useTabStore } from "../../common/tables/Tabs/useTabStore";
import { TableTabsDict } from "../../common/tables/Tabs/TableTabsTypes";
import { Person, PersonFilters, PersonFiltersTranslator } from "../../api/Person";
import { PersonFilterBar } from "./PersonFilterBar";
import { PersonFilterForm } from "./PersonFilterBar";
import { NotAvailable, NotSet } from "../../common/misc/UIconstants";
import Status from "../../common/badges/Status/Status";
import { DateTimeRenderer } from "../../common/datetime/DateTimeFormatter";
import { GetPersons } from "../../common/misc/EntityRenders/EntityRenderer";
import { AddCustomTypeEntity } from "../../Customization/CustomTypes/generics/AddCustomTypeEntity";
import { useCustomTypesEntityTable } from "../../Customization/CustomTypes/generics/useCustomTypesEntityTable";
import { TwoFactorAuthIndicator } from "../TotpModal";
import {
  UseEntityTableDefaultProps,
  useGenericVirtualizedTable,
  useGenericVirtualizedTableTabs,
  UseITypedEntityTableProps,
} from "../../common/tables/GenericVirtualizedTable/useGenericVirtualizedTable";
import { renderCustomTypeColumn } from "../../Customization/CustomTypes/generics/CustomTypeRenderer";
import { EntityTableProps } from "../../common/entity/EntityInterfaces";
import { EntityTableEditButton } from "../../common/entity/entityComponents/EntityTableEditButton";
import { EntityTableCloneButton } from "../../common/entity/entityComponents/EntityTableCloneButton";
import { EntityTableRestoreButton } from "../../common/entity/entityComponents/EntityTableRestoreButton";
import { EntityTableSoftDeletableButton } from "../../common/entity/entityComponents/EntityTableSoftDeletableButton";
import { Button } from "../../common/buttons/Button/Button";
import { useUnpaginateEntities } from "../../common/forms/MultiEditForms/common/MultiEditUtils";

export const switchPersonsDefaultSortState = (
  sortState: PersonFilters["orderBy"]
): SortState<PersonFilters["orderBy"]> => {
  switch (sortState) {
    case "ID_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-id",
        orderBy: sortState,
      };
    case "ID_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-id",
        orderBy: sortState,
      };
    case "NAME_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-name",
        orderBy: sortState,
      };
    case "NAME_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-name",
        orderBy: sortState,
      };
    case "SALUTATION_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-salutation",
        orderBy: sortState,
      };
    case "SALUTATION_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-salutation",
        orderBy: sortState,
      };
    case "LAST_NAME_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-lastName",
        orderBy: sortState,
      };
    case "LAST_NAME_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-lastName",
        orderBy: sortState,
      };
    case "FIRST_NAME_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-firstName",
        orderBy: sortState,
      };
    case "FIRST_NAME_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-firstName",
        orderBy: sortState,
      };
    case "ORGANIZATION_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-organization",
        orderBy: sortState,
      };
    case "ORGANIZATION_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-organization",
        orderBy: sortState,
      };
    case "LOGIN_DISABLED_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-loginDisabled",
        orderBy: sortState,
      };
    case "LOGIN_DISABLED_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-loginDisabled",
        orderBy: sortState,
      };
    case "LOGIN_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-login",
        orderBy: sortState,
      };
    case "LOGIN_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-login",
        orderBy: sortState,
      };
    case "IS_LOCAL_USER_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-isLocalUser",
        orderBy: sortState,
      };
    case "IS_LOCAL_USER_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-isLocalUser",
        orderBy: sortState,
      };
    case "CREATED_ON_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-createdOn",
        orderBy: sortState,
      };
    case "CREATED_ON_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-createdOn",
        orderBy: sortState,
      };
    case "CREATED_BY_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-createdBy",
        orderBy: sortState,
      };
    case "CREATED_BY_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-createdBy",
        orderBy: sortState,
      };
    case "MODIFIED_ON_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-modifiedOn",
        orderBy: sortState,
      };
    case "MODIFIED_ON_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-modifiedOn",
        orderBy: sortState,
      };
    case "MODIFIED_BY_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-modifiedBy",
        orderBy: sortState,
      };
    case "MODIFIED_BY_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-modifiedBy",
        orderBy: sortState,
      };
    default:
      return {
        sortDirection: "ASC",
        headerId: "default-name",
        orderBy: sortState,
      };
  }
};

const defaultFilter: PersonFilters = { orderBy: "NAME_ASC" };

export const usePersonsTableDefaults = ({ fieldLabels }: UseEntityTableDefaultProps<"persons">) => {
  const { session } = useContext(SessionContext);
  const defaults: ColumnsSettings<Person> = useMemo(
    () => ({
      "default-id": { pos: 0, active: false, header: fieldLabels.id, property: "id" },
      "default-salutation": { pos: 1, active: false, header: fieldLabels.salutation, property: "salutation" },
      "default-name": { pos: 2, active: true, header: fieldLabels.name, property: "name" },
      ...(session?.features.enable_person_custom_types && {
        "default-type": { pos: 3, active: true, header: fieldLabels.customType, property: "customType" },
      }),
      "default-lastName": { pos: 4, active: false, header: fieldLabels.lastName, property: "lastName" },
      "default-firstName": { pos: 5, active: false, header: fieldLabels.firstName, property: "firstName" },
      "default-login": { pos: 6, active: true, header: fieldLabels.login, property: "login" },
      "default-loginDisabled": {
        pos: 7,
        active: true,
        header: fieldLabels.accountState,
        property: "accountState",
      },
      "default-isLocalUser": {
        pos: 8,
        active: true,
        header: fieldLabels.isLocalUser,
        property: "isLocalUser",
      },
      ...(session?.features.enable_2fa && {
        "default-has2FA": { pos: 9, active: true, header: fieldLabels.has2FA, property: "has2FA" },
      }),
      "default-organization": {
        pos: 10,
        active: true,
        header: fieldLabels.organization,
        property: "organization",
      },
      "default-personTags": { pos: 11, active: true, header: fieldLabels.personTags, property: "personTags" },
      "default-email": { pos: 12, active: true, header: fieldLabels.email, property: "email" },
      "default-officePhone": { pos: 13, active: true, header: fieldLabels.officePhone, property: "officePhone" },
      "default-phone": { pos: 14, active: false, header: fieldLabels.phone, property: "phone" },
      "default-roles": { pos: 15, active: true, header: fieldLabels.roles, property: "roles" },
      "default-privateAdress": {
        pos: 16,
        active: false,
        header: fieldLabels.privateAddress,
        property: "privateAddress",
      },
      "default-web": { pos: 17, active: false, header: fieldLabels.web, property: "web" },
      "default-createdOn": { pos: 18, active: true, header: fieldLabels.createdOn, property: "createdOn" },
      "default-createdBy": { pos: 19, active: true, header: fieldLabels.createdBy, property: "createdBy" },
      "default-modifiedOn": { pos: 20, active: true, header: fieldLabels.modifiedOn, property: "modifiedOn" },
      "default-modifiedBy": { pos: 21, active: true, header: fieldLabels.modifiedBy, property: "modifiedBy" },
    }),
    [
      fieldLabels.accountState,
      fieldLabels.createdBy,
      fieldLabels.createdOn,
      fieldLabels.customType,
      fieldLabels.email,
      fieldLabels.firstName,
      fieldLabels.has2FA,
      fieldLabels.id,
      fieldLabels.isLocalUser,
      fieldLabels.lastName,
      fieldLabels.login,
      fieldLabels.modifiedBy,
      fieldLabels.modifiedOn,
      fieldLabels.name,
      fieldLabels.officePhone,
      fieldLabels.organization,
      fieldLabels.personTags,
      fieldLabels.phone,
      fieldLabels.privateAddress,
      fieldLabels.roles,
      fieldLabels.salutation,
      fieldLabels.web,
      session?.features.enable_2fa,
      session?.features.enable_person_custom_types,
    ]
  );

  const tabStoreDefaults: TableTabsDict<Person, PersonFilters, PersonFilterForm> = useMemo(
    () => ({
      default: {
        tabId: "default",
        type: "fixed",
        label: "All",
        title: "All",
        icon: "house",
        align: "left",
        xPos: 0,
        settings: {
          columnSettings: {},
          columnWidths: {},
          filters: defaultFilter,
          sidebarFilters: {},
        },
        forcedSettings: {
          columnSettings: {},
          columnWidths: {},
          filters: {},
          sidebarFilters: {},
        },
      },
      activeUsers: {
        tabId: "activeUsers",
        type: "fixed",
        label: "Enabled accounts",
        title: "Enabled accounts",
        icon: "user-check",
        align: "left",
        xPos: 1,
        settings: {
          columnSettings: {},
          columnWidths: {},
          filters: defaultFilter,
          sidebarFilters: {},
        },
        forcedSettings: {
          columnSettings: {},
          columnWidths: {},
          filters: { hasAccount: true, isAccountEnabled: true },
          sidebarFilters: {},
        },
      },
      disabledUsers: {
        tabId: "disabledUsers",
        type: "fixed",
        label: "Disabled accounts",
        title: "Disabled accounts",
        icon: "user-x",
        align: "left",
        xPos: 2,
        settings: {
          columnSettings: {},
          columnWidths: {},
          filters: defaultFilter,
          sidebarFilters: {},
        },
        forcedSettings: {
          columnSettings: {},
          columnWidths: {},
          filters: { hasAccount: true, isAccountEnabled: false },
          sidebarFilters: {},
        },
      },
      trash: {
        tabId: "trash",
        type: "fixed",
        label: "Trash",
        title: "Trash",
        icon: "trash",
        align: "left",
        xPos: 3,
        settings: {
          columnSettings: {},
          columnWidths: {},
          filters: defaultFilter,
          sidebarFilters: {},
        },
        forcedSettings: {
          columnSettings: {},
          columnWidths: {},
          filters: { isSoftDeleted: true },
          sidebarFilters: {},
        },
      },
    }),
    []
  );

  return { defaults, tabStoreDefaults };
};

export const usePersonsTableColumns = ({
  entityConstants,
  fieldLabels,
  defaults,
  filters,
  sort,
  setSort,
}: UseITypedEntityTableProps<"persons">) => {
  const { session } = useContext(SessionContext);
  // Custom Fields
  const { columnSettings, customFieldHeaders } = useCustomTypesEntityTable({
    defaultColumnSettings: defaults,
    entityType: "Person",
    featureFlag: session?.features.enable_person_custom_types,
    customTypeIds: filters?.customTypeIds ?? undefined,
  });

  const columns = useMemo(() => {
    let headers: GenericVirtualizedTableCells<Person> = [
      {
        id: "default-id",
        Header: "Person-ID",
        accessor: (row) => <span style={{ color: "var(--gray-400)" }}>{row.id}</span>,
        width: 120,
        minWidth: 120,
        align: "center",
        sortingFn: () => {
          if (sort.headerId === "default-id") {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "ID_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "ID_ASC" }));
            }
          } else {
            setSort({ headerId: "default-id", sortDirection: "ASC", orderBy: "ID_ASC" });
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
      {
        Header: fieldLabels.salutation,
        id: "default-salutation",
        accessor: (row) => (
          <div
            style={{ display: "flex", flexFlow: "row nowrap", gap: "5px", overflow: "hidden", alignItems: "center" }}
          >
            <div style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
              {row.salutation ? (
                <span style={{ fontWeight: 500, whiteSpace: "nowrap", marginRight: "5px" }}>{row.salutation}</span>
              ) : (
                NotSet
              )}
            </div>
          </div>
        ),
        minWidth: 120,
        width: 120,
        align: "left",
        sortingFn: () => {
          if (sort.headerId === "default-salutation") {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "SALUTATION_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "SALUTATION_ASC" }));
            }
          } else {
            setSort({ headerId: "default-id", sortDirection: "ASC", orderBy: "SALUTATION_ASC" });
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
      {
        Header: fieldLabels.name,
        id: "default-name",
        accessor: (row) => (
          <div
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              fontWeight: 600,
              whiteSpace: "nowrap",
              marginRight: "5px",
            }}
            title={row.login ? (row.accountState === "Disabled" ? `${row.login} (account disabled)` : row.login) : ""}
          >
            <LucideIcon
              name={row.login ? (row.accountState === "Disabled" ? "user-x" : "user-check") : "user"}
              color="var(--primary)"
            />
            <span> {row.name} </span>
            {row.isDeleted && <LucideIcon name="trash" color="var(--warning)" />}
          </div>
        ),
        minWidth: 150,
        width: 250,
        align: "left",
        sortingFn: () => {
          if (sort.headerId === "default-name") {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "NAME_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "NAME_ASC" }));
            }
          } else {
            setSort({ headerId: "default-name", sortDirection: "ASC", orderBy: "NAME_ASC" });
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
      {
        Header: fieldLabels.lastName,
        id: "default-lastName",
        accessor: (row) => (
          <div
            style={{ display: "flex", flexFlow: "row nowrap", gap: "5px", overflow: "hidden", alignItems: "center" }}
          >
            <div style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
              <span style={{ fontWeight: 500, whiteSpace: "nowrap", marginRight: "5px" }}>{row.lastName}</span>
            </div>
          </div>
        ),
        minWidth: 150,
        width: 250,
        align: "left",
        sortingFn: () => {
          if (sort.headerId === "default-lastName") {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "LAST_NAME_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "LAST_NAME_ASC" }));
            }
          } else {
            setSort({ headerId: "default-lastName", sortDirection: "ASC", orderBy: "LAST_NAME_ASC" });
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
      {
        Header: fieldLabels.firstName,
        id: "default-firstName",
        accessor: (row) => (
          <div
            style={{ display: "flex", flexFlow: "row nowrap", gap: "5px", overflow: "hidden", alignItems: "center" }}
          >
            <div style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
              <span style={{ fontWeight: 500, whiteSpace: "nowrap", marginRight: "5px" }}>{row.firstName}</span>
            </div>
          </div>
        ),
        minWidth: 150,
        width: 250,
        align: "left",
        sortingFn: () => {
          if (sort.headerId === "default-firstName") {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "FIRST_NAME_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "FIRST_NAME_ASC" }));
            }
          } else {
            setSort({ headerId: "default-firstName", sortDirection: "ASC", orderBy: "FIRST_NAME_ASC" });
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
      {
        Header: fieldLabels.organization,
        id: "default-organization",
        accessor: (row) => (
          <div
            style={{ display: "flex", flexFlow: "row nowrap", gap: "5px", overflow: "hidden", alignItems: "center" }}
          >
            <div style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
              {row.organization ? (
                <span style={{ fontWeight: 500, whiteSpace: "nowrap" }}>{row.organization.name}</span>
              ) : (
                NotSet
              )}
            </div>
          </div>
        ),
        minWidth: 150,
        width: 250,
        align: "left",
        sortingFn: () => {
          if (sort.headerId === "default-organization") {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "ORGANIZATION_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "ORGANIZATION_ASC" }));
            }
          } else {
            setSort({ headerId: "default-organization", sortDirection: "ASC", orderBy: "ORGANIZATION_ASC" });
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
      {
        Header: fieldLabels.officePhone,
        id: "default-officePhone",
        accessor: (row) => (
          <div
            style={{ display: "flex", flexFlow: "row nowrap", gap: "5px", overflow: "hidden", alignItems: "center" }}
          >
            <div style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
              {row.officePhone ? (
                <span style={{ fontWeight: 500, whiteSpace: "nowrap", marginRight: "5px" }}>{row.officePhone}</span>
              ) : (
                NotSet
              )}
            </div>
          </div>
        ),
        minWidth: 150,
        width: 250,
        align: "left",
      },
      {
        Header: fieldLabels.phone,
        id: "default-phone",
        accessor: (row) => (
          <div
            style={{ display: "flex", flexFlow: "row nowrap", gap: "5px", overflow: "hidden", alignItems: "center" }}
          >
            <div style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
              {row.phone ? (
                <span style={{ fontWeight: 500, whiteSpace: "nowrap", marginRight: "5px" }}>{row.phone}</span>
              ) : (
                NotSet
              )}
            </div>
          </div>
        ),
        minWidth: 150,
        width: 250,
        align: "left",
      },
      {
        Header: fieldLabels.privateAddress,
        id: "default-privateAdress",
        accessor: (row) => (
          <div
            style={{ display: "flex", flexFlow: "row nowrap", gap: "5px", overflow: "hidden", alignItems: "center" }}
          >
            <div style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
              {row.privateAddress ? (
                <span style={{ fontWeight: 500, whiteSpace: "nowrap", marginRight: "5px" }}>{row.privateAddress}</span>
              ) : (
                NotSet
              )}
            </div>
          </div>
        ),
        minWidth: 150,
        width: 250,
        align: "left",
      },
      {
        Header: fieldLabels.email,
        id: "default-email",
        accessor: (row) => (
          <div
            style={{ display: "flex", flexFlow: "row nowrap", gap: "5px", overflow: "hidden", alignItems: "center" }}
          >
            <div style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
              {row.email ? (
                <span style={{ fontWeight: 500, whiteSpace: "nowrap", marginRight: "5px" }}>{row.email}</span>
              ) : (
                NotSet
              )}
            </div>
          </div>
        ),
        minWidth: 300,
        width: 300,
        align: "left",
      },
      {
        Header: fieldLabels.personTags,
        id: "default-personTags",
        accessor: (row) => (
          <div style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
            {!!row.personTags?.length
              ? row.personTags.map((t, i) => (
                  <span key={i} className="label label-soft-secondary" style={{ marginRight: "5px" }}>
                    {t.name}
                  </span>
                ))
              : NotSet}
          </div>
        ),
        minWidth: 300,
        width: 300,
        align: "left",
      },
      {
        Header: fieldLabels.roles,
        id: "default-roles",
        accessor: (row) => (
          <div
            style={{
              display: "flex",
              flexFlow: "row nowrap",
              gap: "5px",
              overflow: "hidden",
              overflowX: "auto",
              alignItems: "center",
            }}
          >
            {!!row.roles?.length
              ? row.roles.map((c, i) => (
                  <span
                    key={i}
                    style={{ margin: "0 2px", overflow: "hidden", textOverflow: "ellipsis" }}
                    className="label label-soft-secondary"
                  >
                    {c.name}
                  </span>
                ))
              : NotSet}
          </div>
        ),
        minWidth: 150,
        width: 300,
        align: "left",
      },
      {
        Header: fieldLabels.has2FA,
        id: "default-has2FA",
        accessor: (row) => <>{!row.isLocalUser ? NotAvailable : <TwoFactorAuthIndicator enabled={!!row.has2FA} />}</>,

        minWidth: 150,
        width: 150,
        align: "left",
      },
      {
        Header: fieldLabels.accountState,
        id: "default-loginDisabled",
        accessor: (row) => (
          <>
            {!!row.login ? (
              <span className="flex row-nowrap align-center gap-5 badge" title={row.login || ""}>
                <Status type={row.accountState === "Disabled" ? "danger" : "success"} />
                {row.accountState === "Disabled" ? "Disabled" : "Enabled"}
              </span>
            ) : (
              NotSet
            )}
          </>
        ),
        minWidth: 175,
        width: 175,
        align: "left",
        sortingFn: () => {
          if (sort.headerId === "default-loginDisabled") {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "LOGIN_DISABLED_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "LOGIN_DISABLED_ASC" }));
            }
          } else {
            setSort({ headerId: "default-loginDisabled", sortDirection: "ASC", orderBy: "LOGIN_DISABLED_ASC" });
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
      {
        Header: fieldLabels.isLocalUser,
        id: "default-isLocalUser",
        accessor: (row) => (
          <label
            // className="label label-soft-secondary"
            style={{
              margin: 0,
              lineHeight: undefined,
            }}
          >
            {row.isLocalUser ? "Local" : "SSO"}
          </label>
        ),
        minWidth: 50,
        width: 100,
        align: "left",
        sortingFn: (id) => {
          if (sort.headerId === id) {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "IS_LOCAL_USER_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "IS_LOCAL_USER_ASC" }));
            }
          } else {
            setSort({ headerId: id, sortDirection: "ASC", orderBy: "IS_LOCAL_USER_ASC" });
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
      {
        Header: fieldLabels.login,
        id: "default-login",
        accessor: (row) =>
          row.login ? (
            <div className="flex align-center gap-5">
              <div
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  fontWeight: 600,
                  whiteSpace: "nowrap",
                  marginRight: "5px",
                }}
              >
                {row.login}
              </div>
            </div>
          ) : (
            NotSet
          ),
        minWidth: 150,
        width: 250,
        align: "left",
        sortingFn: () => {
          if (sort.headerId === "default-login") {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "LOGIN_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "LOGIN_ASC" }));
            }
          } else {
            setSort({ headerId: "default-login", sortDirection: "ASC", orderBy: "LOGIN_ASC" });
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
      {
        Header: "Web",
        id: "default-web",
        accessor: (row) => (
          <div style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
            {row.web ? (
              <span style={{ fontWeight: 500, whiteSpace: "nowrap", marginRight: "5px" }}>{row.web}</span>
            ) : (
              NotSet
            )}
          </div>
        ),
        minWidth: 150,
        width: 250,
        align: "left",
      },
      {
        id: "default-createdOn",
        Header: fieldLabels.createdOn,
        accessor: (row) => <DateTimeRenderer date={row.createdOn} includeElapsed={false} />,
        width: 200,
        align: "left",
        sortingFn: () => {
          if (sort.headerId === "default-createdOn") {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "CREATED_ON_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "CREATED_ON_ASC" }));
            }
          } else {
            setSort({ headerId: "default-createdOn", sortDirection: "ASC", orderBy: "CREATED_ON_ASC" });
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
      {
        id: "default-createdBy",
        Header: fieldLabels.createdBy,
        accessor: (row) => <GetPersons persons={row.createdBy} createLinks={false} />,
        width: 200,
        align: "left",
        sortingFn: () => {
          if (sort.headerId === "default-createdBy") {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "CREATED_BY_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "CREATED_BY_ASC" }));
            }
          } else {
            setSort({ headerId: "default-createdBy", sortDirection: "ASC", orderBy: "CREATED_BY_ASC" });
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
      {
        id: "default-modifiedOn",
        Header: fieldLabels.modifiedOn,
        accessor: (row) => <DateTimeRenderer date={row.modifiedOn} includeElapsed={false} />,
        width: 200,
        align: "left",
        sortingFn: () => {
          if (sort.headerId === "default-modifiedOn") {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "MODIFIED_ON_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "MODIFIED_ON_ASC" }));
            }
          } else {
            setSort({ headerId: "default-modifiedOn", sortDirection: "ASC", orderBy: "MODIFIED_ON_ASC" });
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
      {
        id: "default-modifiedBy",
        Header: fieldLabels.modifiedBy,
        accessor: (row) => <GetPersons persons={row.modifiedBy} createLinks={false} />,
        width: 200,
        align: "left",
        sortingFn: () => {
          if (sort.headerId === "default-modifiedBy") {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "MODIFIED_BY_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "MODIFIED_BY_ASC" }));
            }
          } else {
            setSort({ headerId: "default-modifiedBy", sortDirection: "ASC", orderBy: "MODIFIED_BY_ASC" });
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
    ];
    if (session?.features.enable_person_custom_types) {
      // Add custom field headers
      headers.push(
        renderCustomTypeColumn<Person, PersonFilters["orderBy"], PersonFilters>(entityConstants, sort, setSort)
      );
      headers = headers.concat(customFieldHeaders);
    }

    return headers;
  }, [
    customFieldHeaders,
    entityConstants,
    fieldLabels.accountState,
    fieldLabels.createdBy,
    fieldLabels.createdOn,
    fieldLabels.email,
    fieldLabels.firstName,
    fieldLabels.has2FA,
    fieldLabels.isLocalUser,
    fieldLabels.lastName,
    fieldLabels.login,
    fieldLabels.modifiedBy,
    fieldLabels.modifiedOn,
    fieldLabels.name,
    fieldLabels.officePhone,
    fieldLabels.organization,
    fieldLabels.personTags,
    fieldLabels.phone,
    fieldLabels.privateAddress,
    fieldLabels.roles,
    fieldLabels.salutation,
    session?.features.enable_person_custom_types,
    setSort,
    sort,
  ]);

  return { columns, columnSettings };
};

export const PersonsTable = ({
  entityApi,
  entityConstants,
  fieldLabels,
  permissions,
  routes,
}: EntityTableProps<"persons">) => {
  const [showModal, setShowModal] = useState(false);
  const { session } = useContext(SessionContext);

  const {
    bulkEditMutationAsync,
    bulkRestoreMutationAsync: bulkRestorePersons,
    bulkDeleteMutationAsync: deletePerson,
    isLoadingBulkEditMutation,
    isLoadingBulkRestoreMutation,
    isLoadingBulkDeleteMutation,
  } = entityApi;
  const { unpaginateEntities } = useUnpaginateEntities({ resource: entityConstants.resource });

  const { defaults, tabStoreDefaults } = usePersonsTableDefaults({ fieldLabels });

  const { selection, resultsCount, selectionPermissions, onCountChange, onSelectionChange, onSelectionPermissions } =
    useGenericVirtualizedTable<Person>();

  const {
    filters,
    sidebarFilters,
    forcedFilters,
    columnSetting,
    columnWidths,
    customTabs,
    fixedTabs,
    dispatchTabStore,
    currentTab,
    tabsLoading,
    tabsModified,
  } = useTabStore<Person, PersonFilters, PersonFilterForm>({
    resource: entityConstants.resource,
    defaults: tabStoreDefaults,
  });

  const { functionRef, onTabChange, sort, setSort, searchValue, setSearchValue } = useGenericVirtualizedTableTabs({
    tabsLoading,
    filters,
    switchSortState: switchPersonsDefaultSortState,
    dispatchTabStore,
  });

  const { columns, columnSettings } = usePersonsTableColumns({
    fieldLabels,
    entityConstants,
    defaults,
    filters,
    sort,
    setSort,
  });

  const memoizedFilters = useMemo(() => ({ ...filters, ...forcedFilters }), [filters, forcedFilters]);

  return (
    <TableView>
      <TableView.Head>
        <TableView.Head.Label>
          <TableViewLabel entityConstants={entityConstants} resultsCount={resultsCount} />
        </TableView.Head.Label>
        <TableView.Head.Controls>
          {/* <button
            className="btn btn-primary"
            title={`Add ${entityConstants.entitySingular}`}
            onClick={() => history.push(route(getAddRoute(entityConstants.frontendIndexRoute)))}
            disabled={!session?.permissions.administer_persons}
          >
            <LucideIcon name="plus" /> Add {entityConstants.entitySingular}
          </button> */}
          <AddCustomTypeEntity
            entityConstants={entityConstants}
            canCreateEntityPermission={session?.permissions.administer_persons}
            entityType={"Person"}
            featureFlag={session?.features.enable_person_custom_types}
          />
        </TableView.Head.Controls>
      </TableView.Head>
      <TableView.Body>
        <TableView.Body.Sidebar>
          <PersonFilterBar
            initialValues={sidebarFilters}
            dispatchTabStore={dispatchTabStore}
            tabsLoading={tabsLoading}
            currentTab={currentTab}
          />
        </TableView.Body.Sidebar>
        <TableView.Body.Content>
          <EntityTableTabs
            currentTab={currentTab}
            onTabChange={onTabChange}
            fixedTabs={fixedTabs}
            filters={filters}
            sidebarFilters={sidebarFilters}
            columnSetting={columnSetting}
            columnWidths={columnWidths}
            customTabs={customTabs}
            dispatchTabStore={dispatchTabStore}
            tabsLoading={tabsLoading}
            tabsModified={tabsModified}
          />
          <EntityTable>
            <EntityTable.Controls
              style={{
                borderTop: "0px",
                borderRadius: "0px",
              }}
            >
              <EntityFilterIndicator<Person, PersonFilters>
                filters={filters}
                excludeFilters={{
                  includeSystemUsers: (value) => !!value,
                  hasAccount: (value) =>
                    currentTab !== "activeUsers" && currentTab !== "disabledUsers" ? !!value : false,
                  isAccountEnabled: (value) =>
                    currentTab !== "activeUsers" && currentTab !== "disabledUsers" ? !!value : false,
                  includeSoftDeleted: (value) => !!value,
                  isSoftDeleted: (value) => !!value,
                }}
                translatorConsts={PersonFiltersTranslator}
              />
              <SearchInput searchValue={searchValue} setSearchValue={setSearchValue} placeholder="Search" />
              <EntityTableEditButton
                entityConstants={entityConstants}
                routes={routes}
                permissions={permissions}
                selection={selection}
                selectionPermissions={selectionPermissions}
              />
              <MoreDropdown drop="right" btn="btn btn-ghost-secondary">
                <li>
                  <EntityTableCloneButton
                    entityConstants={entityConstants}
                    routes={routes}
                    permissions={permissions}
                    selection={selection}
                  />
                </li>
                {currentTab !== "trash" && (
                  <>
                    {currentTab !== "activeUsers" && (
                      <li>
                        <Button
                          className="btn btn-ghost-success"
                          disabled={!permissions.canBulkEdit(selectionPermissions)}
                          loading={isLoadingBulkEditMutation}
                          onClick={async () => {
                            const entities = await unpaginateEntities<Person>(Array.from(selection));
                            await bulkEditMutationAsync({
                              body: Object.values(entities).map((e) => ({ ...e, accountState: "Enabled" })),
                            }).catch(() => {});
                          }}
                        >
                          <LucideIcon name="toggle-right" /> Enable
                        </Button>
                      </li>
                    )}
                    {currentTab !== "disabledUsers" && (
                      <li>
                        <Button
                          className="btn btn-ghost-danger"
                          disabled={!permissions.canBulkEdit(selectionPermissions)}
                          loading={isLoadingBulkEditMutation}
                          onClick={async () => {
                            const entities = await unpaginateEntities<Person>(Array.from(selection));
                            await bulkEditMutationAsync({
                              body: Object.values(entities).map((e) => ({ ...e, accountState: "Disabled" })),
                            }).catch(() => {});
                          }}
                        >
                          <LucideIcon name="toggle-left" /> Disable
                        </Button>
                      </li>
                    )}
                  </>
                )}
                {currentTab === "trash" && (
                  <li>
                    <EntityTableRestoreButton
                      entityConstants={entityConstants}
                      selection={selection}
                      permissions={permissions}
                      selectionPermissions={selectionPermissions}
                      onClick={async () => await bulkRestorePersons({ ids: Array.from(selection) }).catch(() => {})}
                      loading={isLoadingBulkRestoreMutation}
                    />
                  </li>
                )}
                <li>
                  <EntityTableSoftDeletableButton
                    currentTab={currentTab}
                    entityConstants={entityConstants}
                    selection={selection}
                    permissions={permissions}
                    selectionPermissions={selectionPermissions}
                    onClick={() => setShowModal(true)}
                  />
                </li>
              </MoreDropdown>
              <AlertModal
                type={`${currentTab === "trash" ? "danger" : "warning"}`}
                showModal={showModal}
                setShowModal={setShowModal}
                title={`${
                  selection.size === 0
                    ? `Select dataset to ${currentTab === "trash" ? "delete" : "trash"}`
                    : selection.size === 1
                    ? `${currentTab === "trash" ? "Delete" : "Trash"} the selected ${entityConstants.entitySingular}?`
                    : `${currentTab === "trash" ? "Delete" : "Trash"} the selected ${entityConstants.entityPlural}?`
                }`}
                description={`${
                  currentTab === "trash"
                    ? `Proceeding will permanently delete the selected ${entityConstants.entityPlural}.`
                    : `Proceeding will move the selected ${entityConstants.entityPlural} into trash.`
                }`}
                proceedLabel={`${currentTab === "trash" ? "Delete" : "Trash"}`}
                onProceed={async () => {
                  await deletePerson({
                    ids: Array.from(selection),
                    goBackOnSuccess: false,
                    showToast: false,
                    entityName: entityConstants.resource,
                    params: { deletePermanently: currentTab === "trash" },
                  }).catch((e) => {});
                  functionRef.current?.resetSelection();
                  setShowModal(false);
                }}
                loading={isLoadingBulkDeleteMutation}
              />
            </EntityTable.Controls>
            <EntityTable.Body<Person, PersonFilters>
              functionRef={functionRef}
              entityConstants={entityConstants}
              filters={memoizedFilters}
              columns={columns}
              columnSelect
              columnSetting={columnSetting}
              columnWidths={columnWidths}
              defaultColumnSettings={columnSettings}
              dispatchTabStore={dispatchTabStore}
              setResultsCount={onCountChange}
              onSelectionChange={onSelectionChange}
              onSelectionPermissions={onSelectionPermissions}
              showPermissionColumn
              loading={tabsLoading}
            />
          </EntityTable>
        </TableView.Body.Content>
      </TableView.Body>
    </TableView>
  );
};
