import { useState } from "react";
import { showtoast } from "../../overlays/Toasts/showtoast";
import { LucideIcon } from "../../icon/LucideIcon";
import { copyTextToClipboard } from "../../helperfunctions/copyToClipboard";
import { Button, ButtonProps } from "../Button/Button";

export const CopyToClipboardButton = ({
  content,
  btnProps,
  showToast = false,
  hideLabel = false,
}: {
  content: string;
  btnProps?: Omit<ButtonProps, "children">;
  showToast?: boolean;
  hideLabel?: boolean;
}) => {
  const [isCopied, setIsCopied] = useState(false);
  return (
    <Button
      className="btn btn-default"
      {...btnProps}
      onClick={async (e) => {
        if (!!btnProps?.onClick) {
          btnProps.onClick(e);
        } else {
          try {
            copyTextToClipboard(content);
            showToast &&
              showtoast(
                "success",
                <div>
                  <div>API URL copied to clipboard</div>
                  <div style={{ fontSize: "0.8em", color: "var(--gray-300)", padding: "5px 0" }}>{content}</div>
                </div>
              );
            setIsCopied(true);
          } catch (error) {
            showToast && showtoast("error", "Failed to copy API URL to clipboard");
            console.error(error);
            setIsCopied(false);
          }
        }
      }}
    >
      <div className="flex align-center gap-5">
        <LucideIcon name="copy" />
        {!hideLabel && <>{isCopied ? "Copied" : "Copy"}</>}
        {isCopied && <LucideIcon name="check" color="var(--success)" />}
      </div>
    </Button>
  );
};
