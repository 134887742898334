import { EntityAddForm, EntityAddFormPage } from "../../common/entity/EntityAddForm";
import { EntityCloneForm, EntityCloneFormPage } from "../../common/entity/EntityCloneForm";
import { EntityDetailView, EntityDetailPage } from "../../common/entity/EntityDetailPage";
import { EntityEditForm, EntityEditFormPage } from "../../common/entity/EntityEditForm";
import { EntityCloneFormProps, EntityEditFormProps, EntityDetailViewProps } from "../../common/entity/EntityInterfaces";
import { EntityTablePage } from "../../common/entity/EntityTablePage";
import { LabNotebookEntry } from "../types/LabNotebookEntry";
import { LabNotebookEntryForm } from "./Forms/LabNotebookEntryForm";
import { LabNotebookEntrysDetail } from "./LabNotebookEntryDetail";
import { LabNotebookEntriesTable } from "./Table/LabNotebookEntriesTable";

// Add
export const LabNotebookEntryAddForm = ({
  onSuccess,
  onCancel,
}: {
  onSuccess?: (e: LabNotebookEntry) => void;
  onCancel?: () => void;
}) => {
  return (
    <EntityAddForm entityTypeId={"notebookEntries"} onCancel={onCancel} onSuccess={onSuccess}>
      {(props) => <LabNotebookEntryForm {...props} />}
    </EntityAddForm>
  );
};

export const LabNotebookEntryAddFormPage = () => {
  return (
    <EntityAddFormPage>
      <LabNotebookEntryAddForm />
    </EntityAddFormPage>
  );
};

// Clone
export const LabNotebookEntryCloneForm = ({ id }: EntityCloneFormProps<"notebookEntries">) => {
  return (
    <EntityCloneForm id={id} entityTypeId={"notebookEntries"}>
      {(props) => <LabNotebookEntryForm {...props} />}
    </EntityCloneForm>
  );
};

export const LabNotebookEntryCloneFormPage = () => {
  return (
    <EntityCloneFormPage<"notebookEntries"> isIntId>
      {(id) => <LabNotebookEntryCloneForm id={id} />}
    </EntityCloneFormPage>
  );
};

// Edit
export const LabNotebookEntryEditForm = ({ id, onSuccess, onCancel }: EntityEditFormProps<"notebookEntries">) => {
  return (
    <EntityEditForm id={id} entityTypeId={"notebookEntries"} filters={{}} onSuccess={onSuccess} onCancel={onCancel}>
      {(props) => <LabNotebookEntryForm {...props} />}
    </EntityEditForm>
  );
};

export const LabNotebookEntryEditFormPage = () => {
  return (
    <EntityEditFormPage<"notebookEntries"> isIntId>{(id) => <LabNotebookEntryEditForm id={id} />}</EntityEditFormPage>
  );
};

// Detail
export const LabNotebookEntrysDetailView = ({ id }: EntityDetailViewProps<"notebookEntries">) => {
  return (
    <EntityDetailView id={id} entityTypeId={"notebookEntries"} filters={{}}>
      {(props) => <LabNotebookEntrysDetail {...props} />}
    </EntityDetailView>
  );
};

export const LabNotebookEntryDetailsPage = () => {
  return (
    <EntityDetailPage<"notebookEntries"> isIntId>{(id) => <LabNotebookEntrysDetailView id={id} />}</EntityDetailPage>
  );
};

export const LabNotebookEntrysTablePage = () => {
  return (
    <EntityTablePage entityTypeId={"notebookEntries"}>
      {(props) => <LabNotebookEntriesTable {...props} />}
    </EntityTablePage>
  );
};
