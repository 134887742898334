import React from "react";
import {
  Control,
  Controller,
  ControllerFieldState,
  ControllerRenderProps,
  Path,
  UseFormStateReturn,
} from "react-hook-form";
import { StringIndexedDict } from "../../../api/GenericTypes";

export interface GenericControllerProviderProps<Entity extends StringIndexedDict> {
  id: Path<Entity>;
  control: Control<Entity, any, Entity>;
  defaultValue?: any;
  shouldUnregister?: boolean;
  disabled?: boolean;
  children: (
    field: ControllerRenderProps<Entity, Path<Entity>>,
    fieldState: ControllerFieldState,
    formState: UseFormStateReturn<Entity>
  ) => React.ReactNode;
}
export const GenericControllerProvider = <Entity extends StringIndexedDict>({
  id,
  control,
  defaultValue,
  shouldUnregister,
  disabled,
  children,
}: GenericControllerProviderProps<Entity>) => {
  return (
    <Controller
      control={control}
      name={id}
      render={({ field, fieldState, formState }) => <>{children(field, fieldState, formState)}</>}
      defaultValue={defaultValue}
      shouldUnregister={shouldUnregister}
      disabled={disabled}
    />
  );
};
