import { useCallback, useEffect, useState } from "react";

import { useTranslatedFieldUpdate } from "../../ViewerLayout/ViewerLayoutHooks";
import { Color, Track, trackSettingsType, updateColor } from "../../ViewerLayout/ViewerLayoutTypes";
import { ColorNeedsUpdate, initColor } from "../../ViewerLayout/ViewerLayoutUtils";
import { ColorGradientEditor } from "./ColorGradientEditor";
import { TrackSelector } from "./TrackSelector";
import { TrackToolType } from "./ViewerNavigation";
import viewerStyles from "./ViewerNavigation.module.css";

const emptyColor: Color = initColor(["gray", "gray"]);

export const ColorTool = ({
  tracks,
  datatracks,
  changeTrackSettings,
  selectedTracks,
  setMinHeight,
  setMinWidth,
}: TrackToolType) => {
  const [editTrack, setEditTrack] = useState<string | undefined>();
  const [currentColor, setCurrentColor] = useState<Color | undefined>();

  const activeTracks = useTranslatedFieldUpdate(
    tracks,
    (tracks: Record<string, Track>) =>
      Object.values(tracks)
        .filter((track) => track.settings.active)
        .map((track) => track.id),
    []
  );

  useEffect(() => {
    const track = editTrack ? tracks[editTrack] : undefined;
    if (track?.type === "matrix_real" && datatracks?.[track.data.matrix]) {
      const color = initColor(track.settings.color);
      color.range = [datatracks[track.data.matrix].min[2], datatracks[track.data.matrix].max[2]];
      if (ColorNeedsUpdate(currentColor ?? emptyColor, color)) setCurrentColor(color);
    } else setCurrentColor(undefined);
  }, [editTrack, tracks, datatracks]);

  useEffect(() => {
    if (activeTracks.length > 0) {
      // SetEditTrack(activeTracks[0]);
      // const change = activeTracks.map((id) => {
      //   return { id: id, parameter: { selected: false } as trackParameterType };
      // });
      // TODO: update changeTrackSettings
      // changeTrackParameter(
      //     selectedTracks.map((id) => {
      //       return { id: id, parameter: { selected: false } as trackParameterType };
      //     })
      //   );
    }

    // setViewerMode(
    //   produce(viewerMode, (next) => {
    //     next.trackMode = trackModes.singleSelect;
    //   })
    // );

    if (setMinHeight) setMinHeight(150);
    if (setMinWidth) setMinWidth(450);
  }, []);

  // console.log("set", setMinHeight);
  // useEffect(() => {
  //   if (selectedTracks.length > 0) {
  //     const marked: string[] = selectedTracks.filter((id) => tracks[id].parameter.selected);
  //     if (marked.length < 1) marked.push(selectedTracks[0]);
  //     // console.log("selected track", marked);
  //     const track = marked[0];
  //     if (track !== editTrack) {
  //       setEditTrack(track);

  //       // console.log("selected track", tracks[track].parameter);
  //     }
  //   }
  // }, [selectedTracks]);

  const changeColor = useCallback(
    (color: Color) => {
      if (editTrack)
        changeTrackSettings([{ id: editTrack, settings: { color: updateColor(color) } as trackSettingsType }]);
    },
    [editTrack]
  );

  return (
    <div className={viewerStyles.viewerButtonColumn} style={{ flexGrow: 1 }}>
      <TrackSelector
        tracks={tracks}
        selectedTracks={activeTracks}
        currentTrack={editTrack}
        setCurrentTrack={setEditTrack}
      />
      <div style={{ height: 5 }}></div>
      <ColorGradientEditor
        // color={editTrack ? tracks[editTrack].settings.color : undefined}
        color={currentColor}
        setColor={changeColor}
        onSettingsOpen={() => {
          if (setMinHeight) setMinHeight(385);
        }}
        onSettingsClosed={() => {
          if (setMinHeight) setMinHeight(150);
        }}
      />
    </div>
  );
};
