import { EmptyParameter } from "./CommandParameters/EmptyParameter";
import { ParameterSets } from "./CommandParameters/ParameterSets";
import { StackTraces } from "./StackTrace";
import { StringSetParameter } from "./CommandParameters/StringSetParameter";
import { TrackListSelection } from "./CommandParameters/TrackListSelection";
import { TrackSelection } from "./CommandParameters/TrackSelection";
import { BooleanParameter, FloatParameter, IntParameter, StringParameter } from "./CommandParameters/GenericTypes";
import { CommandParameter, parameterTypes } from "./PipelineTypes";
import { StringListParameter } from "./CommandParameters/StringListParameter";

export class ParameterChecker {
  errors: StackTraces;
  warnings: StackTraces;
  parameterTypes: Record<string, string>;
  funcNameRegex = /function (.{1,})\(/;
  parameter: Record<string, CommandParameter>;

  constructor(parameterTypes: Record<string, string> | Record<string, CommandParameter>) {
    this.errors = new StackTraces();
    this.warnings = new StackTraces();
    this.parameter = {};
    this.parameterTypes = {};
    if (Object.values(parameterTypes).some((v) => typeof v !== "string"))
      this.setParameter(parameterTypes as Record<string, CommandParameter>);
    else this.parameterTypes = parameterTypes as Record<string, string>;
  }

  getObjectName(obj: any): string {
    const results = this.funcNameRegex.exec(obj.constructor.toString());
    return results && results.length > 1 ? results[1] : "";
  }

  convertToType(type: string, v: any): CommandParameter {
    let t: any = undefined;
    switch (type) {
      case parameterTypes.string:
        t = new StringParameter(v);
        break;
      case parameterTypes.float:
        t = new FloatParameter(v);
        break;
      case parameterTypes.int:
        t = new IntParameter(v);
        break;
      case parameterTypes.boolean:
        t = new BooleanParameter(v);
        break;
      case parameterTypes.trackSelection:
        t = new TrackSelection(v);
        break;
      case parameterTypes.trackListSelection:
        t = new TrackListSelection(v);
        break;
      case parameterTypes.stringSet:
        t = new StringSetParameter(v);
        break;
      case parameterTypes.stringList:
        t = new StringListParameter(v);
        break;
      case parameterTypes.parameterSets:
        t = new ParameterSets(v);
        break;
    }

    // console.log("convertToType", type, "->", t);
    if (!t) return new EmptyParameter(v);
    // {
    //   name: "",
    //   value: undefined,
    //   errors: new StackTraces(
    //     new StackTrace({
    //       id: "",
    //       component: "ParameterChecker.convertToType",
    //       message: `Unknown parameter type '${type}'.`,
    //     })
    //   ),
    //   warnings: new StackTraces(),
    //   parameterType: parameterTypes.null,
    //   inputType: parameterInputTypes.default,
    //   serialize: () => {},
    //   deserialize: () => {},
    // };
    else return t;
  }

  getParameterValues() {
    return Object.fromEntries(Object.entries(this.parameter).map(([k, v]) => [k, v.value]));
  }

  updateParameter(parameter: Record<string, Record<string, any>>) {
    for (let [k, v] of Object.entries(parameter)) {
      if (!this.parameter?.[k]) continue;
      // console.log("Set:", k, "->", v);
      const p = this.parameter[k];
      p.deserialize(v);
      this.warnings.addTracesWithMessage(p.warnings, {
        component: "ParameterChecker.setParameter",
        id: k,
        message: `Warning in parameter '${k}'`,
      });
      this.errors.addTracesWithMessage(p.errors, {
        component: "ParameterChecker.setParameter",
        id: k,
        message: `Error in parameter '${k}'`,
      });
    }
  }

  setParameter(parameter: Record<string, CommandParameter>) {
    this.parameterTypes = Object.fromEntries(Object.entries(parameter).map(([k, v]) => [k, v.parameterType]));
    this.parameter = parameter;
  }

  createParameter(parameter: Record<string, Record<string, any>>) {
    const parameterTypes = this.parameterTypes;

    for (let [k, v] of Object.entries(parameterTypes)) {
      // console.log("type", k, v, parameter?.[k]);
      const p = this.convertToType(v, parameter?.[k]);
      // console.log("convertToType", k, "->", p.errors.toString());

      this.warnings.addTracesWithMessage(p.warnings, {
        component: "ParameterChecker.setParameter",
        id: k,
        message: `Warning in parameter '${k}'`,
      });
      this.errors.addTracesWithMessage(p.errors, {
        component: "ParameterChecker.setParameter",
        id: k,
        message: `Error in parameter '${k}'`,
      });
      this.parameter[k] = p as CommandParameter;
    }

    Object.keys(parameter).forEach((k) => {
      // console.log("k", k, k in parameterTypes)
      if (!(k in parameterTypes))
        this.warnings.create({
          id: k,
          component: "ParameterChecker.setParameter",
          message: `Unknown parameter '${k}'. (Allowed parameters ${
            Object.keys(parameterTypes).length < 1
              ? "none"
              : "'" +
                Object.entries(parameterTypes)
                  .map(([k, v]) => `${k}:${v}`)
                  .join(", ") +
                "'"
          }).`,
        });
    });
  }
}
