import styles from "./SpreadSheet.module.css";
import { LinePosType } from "./SpreadSheetTypes";

export const LineAtPos = (props: LinePosType & { width: number; height: number }) => {
  const { left, top, height, width, mode } = props;

  if (mode === "horizontal")
    return (
      <>
        <div
          className={styles.DragHandle}
          style={{
            position: "fixed",
            top: (top ?? 0) - 5,
            // top: getTopPosition(),
            backgroundColor: "rgba(0, 0, 0, 0.2)",
            borderBottom: "1px solid rgba(0, 0, 0, 0.3)",
            zIndex: 5,
            width: width ?? 500,
            height: 4,
          }}
        />
        <div
          className={styles.DragHandle}
          style={{
            position: "fixed",
            top: (top ?? 0) + 1,
            // top: getTopPosition(),
            backgroundColor: "rgba(0, 0, 0, 0.2)",
            borderTop: "1px solid rgba(0, 0, 0, 0.3)",
            zIndex: 5,
            width: width ?? 500,
            height: 4,
          }}
        />
      </>
    );

  return (
    <>
      <div
        className={styles.DragHandle}
        style={{
          position: "fixed",
          left: (left ?? 0) - 5,
          // top: getTopPosition(),
          backgroundColor: "rgba(0, 0, 0, 0.2)",
          borderRight: "1px solid rgba(0, 0, 0, 0.3)",
          zIndex: 5,
          height: height ?? 500,
          width: 4,
        }}
      />
      <div
        className={styles.DragHandle}
        style={{
          position: "fixed",
          left: (props?.left ?? 0) + 1,
          // top: getTopPosition(),
          backgroundColor: "rgba(0, 0, 0, 0.2)",
          borderLeft: "1px solid rgba(0, 0, 0, 0.3)",
          zIndex: 5,
          height: height ?? 500,
          width: 4,
        }}
      />
    </>
  );
};
