import { DataSource } from "../../api/DataSource";
import { EntityAddForm, EntityAddFormPage } from "../../common/entity/EntityAddForm";
import { IconNMRDataSourceForm } from "./IconNMRDataSourceForm";
import { EntityCloneFormProps, EntityDetailViewProps, EntityEditFormProps } from "../../common/entity/EntityInterfaces";
import { EntityCloneForm, EntityCloneFormPage } from "../../common/entity/EntityCloneForm";
import { EntityEditForm, EntityEditFormPage } from "../../common/entity/EntityEditForm";
import { EntityDetailPage, EntityDetailView } from "../../common/entity/EntityDetailPage";
import { DataSourcesDetail } from "./IconNMRDetail";
import { useHistory } from "react-router-dom";
import { useEntityDetail } from "../../api/BaseEntityApi";
import { Bridge, bridgeConstants } from "../../api/Bridges";
import { EntityTablePage } from "../../common/entity/EntityTablePage";
import { IconNMRHoldersTableWrapper } from "../../IconNMR/IconNMRHolders/Table/IconNMRHoldersTable";
import { IconNMRHistoryTableWrapper } from "../../IconNMR/IconNMRHistory/Table/IconNMRHistoryTable";

interface DataSourceEditFormProps extends EntityEditFormProps<"dataSources"> {
  bridgeId?: number;
}
interface DataSourceCloneFormProps extends EntityCloneFormProps<"dataSources"> {
  bridgeId?: number;
}

// Add
export const IconNMRDataSourceAddForm = ({
  onSuccess,
  initialValues,
}: {
  onSuccess?: (dataSource: DataSource) => void;
  initialValues?: Partial<DataSource>;
}) => {
  return (
    <EntityAddForm entityTypeId={"dataSources"} onSuccess={onSuccess}>
      {(props) => <IconNMRDataSourceForm {...props} initialValues={initialValues} />}
    </EntityAddForm>
  );
};

export const IconNMRDataSourceAddFormPage = () => {
  const history = useHistory();
  const bridgeId = new URLSearchParams(history.location.search).get("bridgeId");
  const { data: bridge } = useEntityDetail<Bridge>(bridgeConstants.resource, bridgeId || 0, undefined, {
    enabled: !!bridgeId,
  });
  return (
    <EntityAddFormPage>
      <IconNMRDataSourceAddForm
        initialValues={{ bridge, type: "IconNMR", format: { id: "NMR (Bruker)", name: "NMR (Bruker)" } }}
      />
    </EntityAddFormPage>
  );
};

// Clone
export const IconNMRDataSourceCloneForm = ({ id, bridgeId }: DataSourceCloneFormProps) => {
  const { data: bridge } = useEntityDetail<Bridge>(bridgeConstants.resource, bridgeId || 0, undefined, {
    enabled: !!bridgeId,
  });
  return (
    <EntityCloneForm id={id} entityTypeId={"dataSources"}>
      {(props) => (
        <IconNMRDataSourceForm
          {...props}
          initialValues={{
            ...props.initialValues,
            bridge: bridge ?? props.initialValues?.bridge,
          }}
        />
      )}
    </EntityCloneForm>
  );
};

export const IconNMRDataSourceCloneFormPage = () => {
  const history = useHistory();
  const bridgeId = new URLSearchParams(history.location.search).get("bridgeId");
  return (
    <EntityCloneFormPage<"dataSources"> isIntId>
      {(id) => <IconNMRDataSourceCloneForm id={id} bridgeId={bridgeId ? parseInt(bridgeId) : undefined} />}
    </EntityCloneFormPage>
  );
};

// Edit
export const IconNMRDataSourceEditForm = ({ id, bridgeId }: DataSourceEditFormProps) => {
  const { data: bridge } = useEntityDetail<Bridge>(bridgeConstants.resource, bridgeId || 0, undefined, {
    enabled: !!bridgeId,
  });
  return (
    <EntityEditForm id={id} entityTypeId={"dataSources"}>
      {(props) => (
        <IconNMRDataSourceForm
          {...props}
          initialValues={{
            ...props.initialValues,
            bridge: bridge ?? props.initialValues?.bridge,
          }}
        />
      )}
    </EntityEditForm>
  );
};

export const IconNMRDataSourceEditFormPage = () => {
  const history = useHistory();
  const bridgeId = new URLSearchParams(history.location.search).get("bridgeId");
  return (
    <EntityEditFormPage<"dataSources"> isIntId>
      {(id) => <IconNMRDataSourceEditForm id={id} bridgeId={bridgeId ? parseInt(bridgeId) : undefined} />}
    </EntityEditFormPage>
  );
};

// Detail
export const IconNMRDataSourcesDetailView = ({ id }: EntityDetailViewProps<"dataSources">) => {
  return (
    <EntityDetailView id={id} entityTypeId={"dataSources"} filters={{ includeStatusHistory: true }}>
      {(props) => <DataSourcesDetail {...props} />}
    </EntityDetailView>
  );
};

export const IconNMRDataSourceDetailsPage = () => {
  return <EntityDetailPage<"dataSources"> isIntId>{(id) => <IconNMRDataSourcesDetailView id={id} />}</EntityDetailPage>;
};

export const IconNMRHoldersTablePage = () => {
  return (
    <EntityTablePage entityTypeId={"iconNMRHolders"}>
      {(props) => <IconNMRHoldersTableWrapper {...props} />}
    </EntityTablePage>
  );
};

export const IconNMRHistoryTablePage = () => {
  return (
    <EntityTablePage entityTypeId={"iconNMRHistoryEntry"}>
      {(props) => <IconNMRHistoryTableWrapper {...props} />}
    </EntityTablePage>
  );
};
