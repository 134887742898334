import { ExperimentForm } from "./ExperimentForm";
import { EntityAddForm, EntityAddFormPage } from "../common/entity/EntityAddForm";
import { EntityCloneForm, EntityCloneFormPage } from "../common/entity/EntityCloneForm";
import { EntityCloneFormProps, EntityDetailViewProps, EntityEditFormProps } from "../common/entity/EntityInterfaces";
import { EntityEditForm, EntityEditFormPage } from "../common/entity/EntityEditForm";
import { EntityDetailPage, EntityDetailView } from "../common/entity/EntityDetailPage";
import { ExperimentsDetail } from "./ExperimentsDetail";
import { ExperimentsTable } from "./Table/ExperimentsTable";
import { EntityTablePage } from "../common/entity/EntityTablePage";

// Add
export const ExperimentAddForm = () => {
  return <EntityAddForm entityTypeId={"experiments"}>{(props) => <ExperimentForm {...props} />}</EntityAddForm>;
};

export const ExperimentAddFormPage = () => {
  return (
    <EntityAddFormPage>
      <ExperimentAddForm />
    </EntityAddFormPage>
  );
};

// Clone
export const ExperimentCloneForm = ({ id }: EntityCloneFormProps<"experiments">) => {
  return (
    <EntityCloneForm id={id} entityTypeId={"experiments"}>
      {(props) => <ExperimentForm {...props} />}
    </EntityCloneForm>
  );
};

export const ExperimentCloneFormPage = () => {
  return <EntityCloneFormPage<"experiments"> isIntId>{(id) => <ExperimentCloneForm id={id} />}</EntityCloneFormPage>;
};

// Edit
export const ExperimentEditForm = ({ id }: EntityEditFormProps<"experiments">) => {
  return (
    <EntityEditForm id={id} entityTypeId={"experiments"} filters={{}}>
      {(props) => <ExperimentForm {...props} />}
    </EntityEditForm>
  );
};

export const ExperimentEditFormPage = () => {
  return <EntityEditFormPage<"experiments"> isIntId>{(id) => <ExperimentEditForm id={id} />}</EntityEditFormPage>;
};

// Detail
export const ExperimentsDetailView = ({ id }: EntityDetailViewProps<"experiments">) => {
  return (
    <EntityDetailView id={id} entityTypeId={"experiments"} filters={{}}>
      {(props) => <ExperimentsDetail {...props} />}
    </EntityDetailView>
  );
};

export const ExperimentDetailsPage = () => {
  return <EntityDetailPage<"experiments"> isIntId>{(id) => <ExperimentsDetailView id={id} />}</EntityDetailPage>;
};

export const ExperimentsTablePage = () => {
  return <EntityTablePage entityTypeId={"experiments"}>{(props) => <ExperimentsTable {...props} />}</EntityTablePage>;
};
