import React, { useState } from "react";
import { LucideIcon } from "../../../icon/LucideIcon";
import styles from "./FullDetailNavigation.module.css";
import { GenericEntity, INamedEntity, ISoftDeletable } from "../../../../api/GenericTypes";
import { NotAvailable } from "../../../misc/UIconstants";

interface FullDetailNavigationProps<Entity> {
  icon: JSX.Element;
  labels?: JSX.Element;
  entityName: string;
  entity: Entity;
  onExpandCallback?: () => void;
  children: React.ReactNode;
}
export const FullDetailNavigation = <Entity extends GenericEntity & INamedEntity & Partial<ISoftDeletable>>({
  icon,
  labels,
  entityName,
  entity,
  onExpandCallback,
  children,
}: FullDetailNavigationProps<Entity>) => {
  const [show, setShow] = useState(false);
  return (
    <div className={styles.navigation_nav}>
      <div
        className={styles.navigation_nav_clickable}
        onClick={() => {
          setShow(!show);
          onExpandCallback?.();
        }}
      >
        <div className={styles.navigation_nav_content}>
          <div className={styles.navigation_router_nav_icon}>{icon}</div>
          <div className={styles.navigation_router_nav_label}>
            <div className="flex row-nowrap align-center gap-5" style={{ overflow: "hidden" }}>
              <span>{entity.name || NotAvailable}</span>
              {labels ?? ""}
              {entity.isDeleted ? (
                <label className="label label-soft-warning" style={{ margin: 0 }} title={"Trashed"}>
                  <LucideIcon name="trash" />
                </label>
              ) : (
                ""
              )}{" "}
            </div>
          </div>
          <div className={styles.navigation_router_nav_id}>
            <span>
              {entityName}-ID: {entity.id}
            </span>
          </div>
          <div className={styles.navigation_router_nav_navicon}>
            {show ? <LucideIcon name="chevron-up" /> : <LucideIcon name="chevron-down" />}
          </div>
        </div>
      </div>

      <>
        {show && <div className={styles.navigation_nav_table_invisible_overlay} onClick={() => setShow(() => !show)} />}
        <div
          className={`${styles.navigation_nav_table} ${
            show ? styles.navigation_nav_table_visible : styles.navigation_nav_table_invisible
          }`}
        >
          {children}
        </div>
      </>
    </div>
  );
};
