import { StringIndexedDict } from "../../../../api/GenericTypes";
import {
  GenericEntityVirtualizedEntitySelectFormFieldProps,
  GenericVirtualizedEntitySelectFormFieldControllerWrapper,
} from "../../../formfields/GenericVirtualizedSelectFormField/GenericVirtualizedEntitySelectFormFieldControllerWrapper";
// import { QuickAddWrapper } from "../../QuickAdd/common/QuickAddWrapper";
import {
  ParserFilters,
  ParserSortingOptionsConsts,
  ParserSuggestions,
  parsersConstants,
} from "../../../../api/Parsers";

export const DataFormatsVirtualizedSelectForm = <Entity extends StringIndexedDict>(
  props: GenericEntityVirtualizedEntitySelectFormFieldProps<Entity, ParserSuggestions, ParserFilters>
) => {
  return (
    <GenericVirtualizedEntitySelectFormFieldControllerWrapper<Entity, ParserSuggestions, ParserFilters>
      {...props}
      filters={{ orderBy: "NAME_ASC", excludeIds: ["doc_multi"], ...props.filters }}
      //   onRowRenderer={(item) => <></>}
      entityConstants={parsersConstants}
      orderByOptions={ParserSortingOptionsConsts}
      allowCreateEntity={false}
    />
  );
};
