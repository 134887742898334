import React from "react";
import Codeblock from "../textfields/Codeblock/Codeblock";
import styles from "./Display.module.css";

/**
 * This component is designated as wrapper for components for the overview page
 * Author: CS
 * @param: children, code
 * @returns: JSX.Element
 */

interface Props {
  children: React.ReactNode;
  code?: string;
}
// const getCircularReplacer = () => {
//   const seen = new WeakSet();
//   return (key: any, value: any) => {
//     if (typeof value === "object" && value !== null) {
//       if (seen.has(value)) {
//         return;
//       }
//       seen.add(value);
//     }
//     return value;
//   };
// };

export const Display = ({ children, code }: Props) => {
  return (
    <div className={styles.main_wrapper}>
      <div className={styles.component}>{children}</div>
      {/* <div className={styles.props}>
        Props:
        <textarea
          defaultValue={React.Children.map(children, (child) => {
            if (!React.isValidElement(child)) {
              return "";
            }
            if (child.props) {
              return JSON.stringify(child.props, getCircularReplacer());
            }
          })?.join("")}
          readOnly
        />
      </div> */}
      <Codeblock>{code ? code : ""}</Codeblock>
      {/* <div className={styles.code}>
        React:
        <textarea defaultValue={code} readOnly />
      </div> */}
    </div>
  );
};
