import React, { useState } from "react";
import { LucideIcon } from "../common/icon/LucideIcon";
import Codeblock from "../common/textfields/Codeblock/Codeblock";
import { ParserErrorFeedback } from "./ParsingErrorFeedback";
import { viewerErrorType, viewerLogType } from "./ViewerLayoutTypes";
import styles from "./ViewerLog.module.css";
import { Dataset } from "../api/Datasets";

// import { leadinZeros, needUpdate } from "./ViewerLayoutUtils";
const StackTrace = ({ description, active }: { description: string[]; active?: boolean }) => {
  const [show, setShow] = useState<boolean>(false);
  return (
    <div style={{ marginLeft: "14px" }}>
      {!active ? (
        <button className="btn btn-xs btn-soft-secondary" onClick={() => setShow(!show)}>
          <span className={show ? "glyphicon glyphicon-remove" : "glyphicon glyphicon-align-left"} />
          &nbsp;
          {show ? "Hide stacktrace" : "Show stacktrace"}
        </button>
      ) : null}
      {show || active ? <Codeblock>{description}</Codeblock> : null}
    </div>
  );
};

const spaceReplace = (s: string) => {
  if (!s) return "";
  return s.split(" ").map((s, i) => <span key={i}>{s}&nbsp;</span>);
};

export const ViewerLogEntry = ({
  log,
  defaultShowError = false,
}: {
  log: viewerLogType;
  defaultShowError?: boolean;
}) => {
  const [showError, setShowError] = useState(defaultShowError);
  const message: {
    prefix: string;
    type: "info" | "danger" | "warning";
  } = { prefix: "", type: "info" };

  if (log.type === "error") {
    message.prefix = "Error";
    message.type = "danger";
  } else if (log.type === "warning") {
    message.prefix = "Warning";
    message.type = "warning";
  } else if (log.type === "info") {
    message.prefix = "Info";
    message.type = "info";
  }

  let description = null;
  if (log.description) {
    description = (
      <div className={styles.description}>
        {log.description.length > 1
          ? log.description.map((d, i) => [spaceReplace(d), <br key={i} />])
          : [log.description?.[0], <br key={"description"} />]}
      </div>
    );
    if (log.code === 1) description = <StackTrace description={log.description} active={true} />;
  }

  return (
    <React.Fragment>
      <div style={{ display: "flex", flexFlow: "row", gap: 5, alignItems: "center" }}>
        <div style={{ display: "flex", flexFlow: "row", alignItems: "center", fontFamily: "Inter" }}>
          {/* <span style={{ height: "min-content" }}>:</span>
          <span>
            <FeatherIcon name="chevron-right" />
          </span> */}
          <span className={`label label-soft-${message.type}`} style={{ fontFamily: "monospace" }}>
            {message.prefix}
          </span>
        </div>

        <span style={{ fontFamily: "monospace" }}>
          {typeof log.message === "string" ? log.message.charAt(0).toUpperCase() + log.message.slice(1) : log.message}
        </span>
        {description ? (
          <span style={{ marginLeft: "auto" }}>
            <button className="btn btn-xs btn-primary" onClick={() => setShowError(!showError)}>
              <LucideIcon name="code" /> Show details
            </button>
          </span>
        ) : null}
      </div>
      {showError && description ? (
        <div style={{ display: "flex", flexFlow: "column nowrap", gap: "5px", width: "100%", marginLeft: "14px" }}>
          <div
            style={{
              display: "flex",
              flexFlow: "row nowrap",
              gap: "5px",
              alignItems: "center",
              width: "100%",
              marginLeft: "16px",
            }}
          ></div>
          {log.code && log.code > 0 ? <strong>{"Code: " + log.code}</strong> : ""}
          {description}
        </div>
      ) : null}
    </React.Fragment>
  );
};

export const ViewerLog = ({
  datasetId,
  logs,
  status,
}: {
  datasetId?: Dataset["id"];
  status: viewerErrorType;
  logs: viewerLogType[];
}) => {
  const header =
    status === "failed" ? <h1 style={{ margin: 0, color: "var(--gray-500)" }}>Visualization unavailable</h1> : null;

  // console.log("logs", logs);
  return (
    <div className={styles.container}>
      <div className="flex row-nowrap align-center gap-5">{header}</div>
      <div className={styles.logs}>
        {logs.map((log, i) => (
          <ViewerLogEntry log={log} key={i} />
        ))}
      </div>
      <div className={styles.controls}>
        <ParserErrorFeedback logs={logs} datasetId={datasetId} />
      </div>
    </div>
  );
};
