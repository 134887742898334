import { yupResolver } from "@hookform/resolvers/yup";
import { useCallback } from "react";
import { useForm } from "react-hook-form";
// import {
//   processCustomFieldsYupSchema,
//   processInitialValuesForCustomFields,
//   CustomFieldsFormRenderer,
//   processCustomFields,
// } from "../CustomFields/forms/CustomFieldsForm";
// import { SampleType, SampleTypeFilters, sampleTypesConstants } from "../api/SampleTypes";
import { Sample, samplesConstants } from "../api/Samples";
import { iso2localDate } from "../common/datetime/DateTimeFormatter";
import { CustomTypedEntityFormProps } from "../common/entity/EntityInterfaces";
import { SingleDatePickerFormField } from "../common/formfields/DatePickerFormField/SingleDatePickerFormField";
import { InputFormField } from "../common/formfields/InputFormField";
import { TextareaFormField } from "../common/formfields/TextareaFormField";
import { OrganizationsVirtualizedSelectForm } from "../common/forms/EntityForms/formsVirtualized/OrganizationsVirtualizedSelectForm";
import { PersonsVirtualizedSelectForm } from "../common/forms/EntityForms/formsVirtualized/PersonsVirtualizedSelectForm";
import { ProjectsVirtualizedSelectForm } from "../common/forms/EntityForms/formsVirtualized/ProjectsVirtualizedSelectForm";
// import { SampleTypesVirtualizedSelectForm } from "../common/forms/EntityForms/formsVirtualized/SampleTypesVirtualizedSelectForm";
import { FormButtons } from "../common/forms/FormButtons";
import { FormHeader } from "../common/forms/FormHeader";
import * as yup from "yup";
import styles from "../common/forms/forms.module.css";
import { EntityCustomTypeForm, useCustomTypeForm } from "../Customization/CustomTypes/generics/useCustomTypeForm";

export const sampleFormBaseSchema = {
  name: yup.string().required("A sample name is required"),
  preparedAt: yup.date().required("Preparation date is required"),
  projects: yup.array().ensure().min(1, "At least one project is required"),
  preparedBy: yup.array().min(1, "Prepared by is required"),
};

export const SampleForm = ({
  event,
  fieldLabels,
  permissions,
  title,
  subtitle,
  initialValues,
  onSubmit,
  onCancel,
  loading,
  submitButtonLabel,
  typeId,
}: CustomTypedEntityFormProps<"samples">) => {
  const { defaultValues, typedFormSchema, processCustomFields, type, setType, types } = useCustomTypeForm({
    initialValues: initialValues,
    formSchema: sampleFormBaseSchema,
    typeId: typeId,
    entityType: "Sample",
  });

  const {
    register,
    handleSubmit,
    setValue,
    control,
    trigger,
    formState: {
      errors,
      isSubmitting,
      // dirtyFields
    },
  } = useForm<Partial<Sample>>({
    values: defaultValues,
    resolver: yupResolver(typedFormSchema),
  });

  // Helper function to process some fields
  const processEntity = useCallback((entity: Partial<Sample>) => {
    const discardedAt = entity.discardedAt ? iso2localDate(entity.discardedAt) : null;
    if (discardedAt) discardedAt.setUTCHours(0, 0, 0, 0);
    const preparedAt = iso2localDate(entity.preparedAt);
    preparedAt.setUTCHours(0, 0, 0, 0);
    return {
      ...entity,
      ...(entity.discardedAt
        ? {
            discardedAt: discardedAt ? discardedAt.toISOString() : null,
          }
        : { discardedAt: null }),
      preparedAt: preparedAt.toISOString(),
      customType: entity.customType ?? null,
      ...(!entity.customType && { customFields: {} }),
    } as Partial<Sample>;
  }, []);

  return (
    <>
      <FormHeader title={title} subtitle={subtitle} />
      <form
        onSubmit={(e) => {
          e.preventDefault();
        }}
        autoComplete="off"
        className={`form-horizontal ${styles.form_holder}`}
      >
        <fieldset>
          <legend className="col-md-offset-2 col-md-10">Basic details</legend>
        </fieldset>
        <InputFormField
          id={"name"}
          label={fieldLabels.name}
          errors={errors}
          register={register}
          autoFocus={true}
          required={true}
        />
        <ProjectsVirtualizedSelectForm
          id={"projects"}
          label={fieldLabels.projects}
          control={control}
          required
          isMulti
          horizontal
          allowCreateEntity
          showControls
          filters={{ currentUserHasAddPermission: true }}
        />

        <OrganizationsVirtualizedSelectForm
          id={"organizations"}
          label={fieldLabels.organizations}
          control={control}
          isMulti
          horizontal
          allowCreateEntity
          showControls
        />

        <PersonsVirtualizedSelectForm
          id={"preparedBy"}
          label={fieldLabels.preparedBy}
          control={control}
          horizontal
          isMulti
          allowUnassigned
          allowCreateEntity
          showControls
          required
        />
        <SingleDatePickerFormField
          type="date"
          id={"preparedAt"}
          label={fieldLabels.preparedAt}
          control={control}
          required={true}
          errors={errors}
          horizontal
        />

        <PersonsVirtualizedSelectForm
          id={"discardedBy"}
          label={fieldLabels.discardedBy}
          control={control}
          horizontal
          isMulti
          allowUnassigned
          allowCreateEntity
          showControls
        />

        <SingleDatePickerFormField
          type="date"
          id={"discardedAt"}
          label={fieldLabels.discardedAt}
          control={control}
          required={false}
          errors={errors}
          horizontal
        />

        <TextareaFormField id="notes" label="Notes" errors={errors} register={register} />

        {/* Render custom type */}
        <EntityCustomTypeForm
          entityType="Sample"
          typeId={typeId}
          type={type}
          types={types}
          setType={setType}
          control={control}
          register={register}
          setValue={setValue}
          initialValues={initialValues}
          errors={errors}
          entityConstants={samplesConstants}
        />

        <FormButtons
          groupName={"samples"}
          entityId={initialValues?.id}
          onClose={onCancel}
          onSubmit={handleSubmit(async (entity) => {
            await trigger();
            await onSubmit(processCustomFields(processEntity(entity)));
          })}
          disabled={isSubmitting}
          errors={errors}
          loading={loading}
          submitButtonLabel={submitButtonLabel || "Save changes"}
        />
      </form>
    </>
  );
};
