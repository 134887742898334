import { OrganizationTagForm } from "./OrganizationTagForm";
import { EntityAddForm, EntityAddFormPage } from "../common/entity/EntityAddForm";
import { EntityDetailViewProps, EntityEditFormProps } from "../common/entity/EntityInterfaces";
import { EntityEditForm, EntityEditFormPage } from "../common/entity/EntityEditForm";
import { EntityDetailPage, EntityDetailView } from "../common/entity/EntityDetailPage";
import { OrganizationTagsDetail } from "./OrganizationTagsDetail";
import { EntityTablePage } from "../common/entity/EntityTablePage";
import { OrganizationTagsTable } from "./Table/OrganizationTagsTable";

// Add
export const OrganizationTagAddForm = () => {
  return (
    <EntityAddForm entityTypeId={"organizationTags"}>{(props) => <OrganizationTagForm {...props} />}</EntityAddForm>
  );
};

export const OrganizationTagAddFormPage = () => {
  return (
    <EntityAddFormPage>
      <OrganizationTagAddForm />
    </EntityAddFormPage>
  );
};

// Clone
// export const OrganizationTagCloneForm = ({ id }: EntityCloneFormProps<OrganizationTag>) => {
//   const { session } = useContext(SessionContext);
//   return (
//     <EntityCloneForm<OrganizationTag>
//       id={id}
//       entityTypeId={"organizationTags"}
//       hasPermission={session?.permissions.administer_organizations}
//     >
//       {(props) => <OrganizationTagForm {...props} />}
//     </EntityCloneForm>
//   );
// };

// export const OrganizationTagCloneFormPage = () => {
//   return (
//     <EntityCloneFormPage<OrganizationTag> isIntId>{(id) => <OrganizationTagCloneForm id={id} />}</EntityCloneFormPage>
//   );
// };

// Edit
export const OrganizationTagEditForm = ({ id }: EntityEditFormProps<"organizationTags">) => {
  return (
    <EntityEditForm id={id} entityTypeId={"organizationTags"} filters={{}}>
      {(props) => <OrganizationTagForm {...props} />}
    </EntityEditForm>
  );
};

export const OrganizationTagEditFormPage = () => {
  return (
    <EntityEditFormPage<"organizationTags"> isIntId>{(id) => <OrganizationTagEditForm id={id} />}</EntityEditFormPage>
  );
};

// Detail
export const OrganizationTagsDetailView = ({ id }: EntityDetailViewProps<"organizationTags">) => {
  return (
    <EntityDetailView id={id} entityTypeId={"organizationTags"} filters={{}}>
      {(props) => <OrganizationTagsDetail {...props} />}
    </EntityDetailView>
  );
};

export const OrganizationTagDetailsPage = () => {
  return (
    <EntityDetailPage<"organizationTags"> isIntId>{(id) => <OrganizationTagsDetailView id={id} />}</EntityDetailPage>
  );
};

export const OrganizationTagsTablePage = () => {
  return (
    <EntityTablePage entityTypeId={"organizationTags"}>
      {(props) => <OrganizationTagsTable {...props} />}
    </EntityTablePage>
  );
};
