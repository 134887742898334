import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { InputFormField } from "../common/formfields/InputFormField";
import { TextareaFormField } from "../common/formfields/TextareaFormField";
import { FormButtons } from "../common/forms/FormButtons";
import { FormHeader } from "../common/forms/FormHeader";
import styles from "../common/forms/forms.module.css";
import { Method } from "../api/Methods";
import { EntityFormProps } from "../common/entity/EntityInterfaces";

export const MethodForm = ({
  fieldLabels,
  permissions,
  title,
  subtitle,
  initialValues,
  onSubmit,
  onCancel,
  loading,
  submitButtonLabel,
  event,
}: EntityFormProps<"methods">) => {
  const MethodFormSchema = {
    name: yup
      .string()
      .required("Method name is required")
      .typeError("Method name is required")
      .test({
        test: (value) => value === value?.trim(),
        message: "Whitespaces at the beginning or at the end of the name are not allowed",
      }),
  };

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isSubmitting },
  } = useForm<Partial<Method>>({
    values: initialValues,
    resolver: yupResolver(yup.object(MethodFormSchema)),
  });

  const currentName = watch("name");

  return (
    <>
      <FormHeader title={title} subtitle={subtitle} />
      <form
        onSubmit={(e) => {
          e.preventDefault();
        }}
        autoComplete="off"
        className={`form-horizontal ${styles.form_holder}`}
      >
        <fieldset>
          <legend className="col-md-offset-2 col-md-10">Basic details</legend>
        </fieldset>

        <InputFormField
          id="name"
          label={fieldLabels.name}
          errors={errors}
          register={register}
          autoFocus={true}
          required={true}
          placeholder="Enter method name..."
        />

        <InputFormField
          id="fullName"
          label={fieldLabels.fullName}
          errors={errors}
          register={register}
          autoFocus={true}
          required={false}
          placeholder={currentName || "Enter full method name..."}
        />

        <TextareaFormField
          id="description"
          label={fieldLabels.description}
          errors={errors}
          register={register}
          placeholder="Enter description..."
        />

        <FormButtons
          groupName="methods"
          entityId={initialValues ? initialValues.id : undefined}
          onClose={onCancel}
          onSubmit={handleSubmit(async (entity) => await onSubmit(entity))}
          submitButtonLabel={submitButtonLabel || "Save changes"}
          disabled={isSubmitting}
          errors={errors}
          loading={loading}
        />
      </form>
    </>
  );
};
