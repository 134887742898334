import React, { useCallback, useMemo, useRef, useState } from "react";
import { MultiEditTable } from "../../common/tables/MultiEdit/MultiEditTable";
import {
  GenericVirtualizedTableCells,
  GenericVirtualizedTableFunctionRef,
  SortState,
} from "../../common/tables/GenericVirtualizedTable/GenericVirtualizedTableTypes";
import { useHistory, useLocation } from "react-router-dom";
import { Alert } from "../../common/overlays/Alert/Alert";
import {
  MultiEditMappedItemObject,
  MultiEditProvider,
} from "../../common/forms/MultiEditForms/common/MultiEditProvider";
import { MultiEditInputForm } from "../../common/forms/MultiEditForms/forms/MultiEditInputForm";
import { MultiEditTextAreaForm } from "../../common/forms/MultiEditForms/forms/MultiEditTextAreaForm";
import { MultiEditSelectForm } from "../../common/forms/MultiEditForms/forms/MultiEditSelectForm";
import { MultiEditHeaderWrapper } from "../../common/forms/MultiEditForms/common/MultiEditFormWrapper";
import { getValueOrDefault, useMultiEditUtils } from "../../common/forms/MultiEditForms/common/MultiEditUtils";
import { TextareaFormField } from "../../common/formfields/TextareaFormField";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { BulkEditLocationState } from "../../common/tables/MultiEdit/BulkEditDropdown/BulkEditDropDownTypes";
import { Sample, SampleFieldLabels, SampleFilters, samplesConstants } from "../../api/Samples";
import { switchSamplesDefaultSortState } from "./SamplesTable";
import { iso2localDate } from "../../common/datetime/DateTimeFormatter";
import { projectsConstants } from "../../api/Projects";
import { organizationsConstants } from "../../api/Organizations";
import { personsConstants } from "../../api/Person";
import { ProjectsVirtualizedSelectForm } from "../../common/forms/EntityForms/formsVirtualized/ProjectsVirtualizedSelectForm";
import { OrganizationsVirtualizedSelectForm } from "../../common/forms/EntityForms/formsVirtualized/OrganizationsVirtualizedSelectForm";
import { PersonsVirtualizedSelectForm } from "../../common/forms/EntityForms/formsVirtualized/PersonsVirtualizedSelectForm";
import { SingleDatePickerFormField } from "../../common/formfields/DatePickerFormField/SingleDatePickerFormField";
import { MultiEditDateForm } from "../../common/forms/MultiEditForms/forms/MultiEditDateForm";
import { LucideIcon } from "../../common/icon/LucideIcon";
import { GenericModalWrapper } from "../../common/modals/Modal/GenericModal";
import { AlertModal } from "../../common/modals/AlertModal/AlertModal";
import { customTypeConstants } from "../../api/CustomTypes";
import { CustomTypesVirtualizedSelectForm } from "../../common/forms/EntityForms/formsVirtualized/CustomTypesVirtualizedSelectForm";
import { multiEditCustomFieldTableCells } from "../../Customization/CustomTypes/generics/useCustomTypesEntityTable";
import { useCustomTypes } from "../../Customization/CustomTypes/generics/useCustomTypes";
import {
  processInitialValuesForTypedEntity,
  processValuesForTypedEntity,
  useCustomFieldsValidationObjectShape,
} from "../../Customization/CustomTypes/generics/CustomTypeValidationUtils";
import { InputFormField } from "../../common/formfields/InputFormField";

export const SamplesBulkEditTable = () => {
  const history = useHistory();
  const location = useLocation<BulkEditLocationState<Sample, SampleFilters>>();
  const defaultFilters = useMemo(() => (location && location.state?.filters) || {}, [location]);
  const initItems = useMemo(() => (location && location.state?.items) || undefined, [location]);
  const [selection, setSelection] = useState<Set<number>>(new Set());
  const functionRef = useRef<GenericVirtualizedTableFunctionRef<Sample>>(null);
  const [filters, setFilters] = useState<SampleFilters>({ ...defaultFilters });
  const [sort, setSort] = useState<SortState<SampleFilters["orderBy"]>>(switchSamplesDefaultSortState(filters.orderBy));
  const [typeOverride, setTypeOverride] = useState(false);
  const { types } = useCustomTypes({ entityType: "Sample", filters: { isEnabled: true } });

  // Hook to update searchTerm or orderBy if filter changes; (e.g. triggered by the sidebar)
  useMemo(() => {
    if (defaultFilters.orderBy !== undefined) {
      setSort(switchSamplesDefaultSortState(defaultFilters.orderBy));
    }
  }, [defaultFilters.orderBy]);

  const {
    control,
    register,
    getValues,
    formState: { errors },
  } = useForm<Sample>({ defaultValues: {} });

  const columns: GenericVirtualizedTableCells<Sample> = useMemo(() => {
    let res: GenericVirtualizedTableCells<Sample> = [
      {
        id: "default-id",
        Header: "ID",
        accessor: (row) => (
          <div className={"flex row-nowrap align-center"} style={{ padding: "7px" }}>
            <span style={{ color: "var(--gray-400)" }}>
              <samp>{row.id}</samp>
            </span>
          </div>
        ),
        width: 100,
        align: "right",
        sortingFn: () => {
          if (sort.headerId === "default-id") {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC" }));
              setFilters((prev) => ({ ...prev, orderBy: "ID_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC" }));
              setFilters((prev) => ({ ...prev, orderBy: "ID_ASC" }));
            }
          } else {
            setSort({ headerId: "default-id", sortDirection: "ASC" });
            setFilters((prev) => ({ ...prev, orderBy: "ID_ASC" }));
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
      {
        id: "default-name",
        Header: "Name",
        accessor: (row) => (
          <div className="flex" style={{ overflow: "auto", width: "100%", height: "100%" }}>
            <MultiEditInputForm id={`name`} row={row} />
          </div>
        ),
        minWidth: 150,
        width: 250,
        align: "left",
        sortingFn: () => {
          if (sort.headerId === "default-name") {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC" }));
              setFilters((prev) => ({ ...prev, orderBy: "NAME_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC" }));
              setFilters((prev) => ({ ...prev, orderBy: "NAME_ASC" }));
            }
          } else {
            setSort({ headerId: "default-name", sortDirection: "ASC" });
            setFilters((prev) => ({ ...prev, orderBy: "NAME_ASC" }));
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },

      {
        id: "default-projects",
        Header: (
          <MultiEditHeaderWrapper<Sample>
            control={control}
            getValues={getValues}
            ids={selection}
            label={SampleFieldLabels.projects}
            property="projects"
            required
            hasApply
            hasAppend
          >
            {(consolidatedValues) => (
              <ProjectsVirtualizedSelectForm
                id="projects"
                control={control}
                hasLabel={false}
                placeholder={getValueOrDefault(consolidatedValues?.projects)}
                defaultValue={consolidatedValues?.projects?.value ?? null}
                horizontal
                allowCreateEntity
                allowUnassigned
                uncontained
                // showControls
                isMulti
                filters={{ currentUserHasAddPermission: true }}
              />
              // <ProjectsSelectForm
              //   id={"projects"}
              //   label=""
              //   control={control}
              //   errors={errors}
              //   required={false}
              //   setValue={setValue}
              //   getValues={getValues}
              //   suggestions={projects.data}
              //   isMulti
              //   horizontal
              //   style={multiEditSelectHeaderStyle}
              //   allowQuickAdd
              //   allowUnassigned
              //   uncontained
              //   placeholder={getValueOrDefault(consolidatedValues?.projects)}
              //   defaultValue={consolidatedValues?.projects?.value ?? null}
              // />
            )}
          </MultiEditHeaderWrapper>
        ),
        accessor: (row, rowIndex, columnIndex) => (
          <div className="flex" style={{ overflow: "auto", width: "100%", height: "100%" }}>
            <MultiEditSelectForm id={`projects`} entityConstants={projectsConstants} row={row} isMulti required />
          </div>
        ),
        minWidth: 400,
        width: 400,
      },
      {
        id: "default-organizations",
        Header: (
          <MultiEditHeaderWrapper<Sample>
            control={control}
            getValues={getValues}
            ids={selection}
            label={SampleFieldLabels.organizations}
            property="organizations"
            hasApply
            hasAppend
          >
            {(consolidatedValues) => (
              <OrganizationsVirtualizedSelectForm
                id="organizations"
                control={control}
                hasLabel={false}
                placeholder={getValueOrDefault(consolidatedValues?.organizations)}
                defaultValue={consolidatedValues?.organizations?.value ?? null}
                horizontal
                allowCreateEntity
                allowUnassigned
                uncontained
                // showControls
                isMulti
              />
              // <OrganizationsSelectForm
              //   id={"organizations"}
              //   label=""
              //   control={control}
              //   errors={errors}
              //   required={false}
              //   setValue={setValue}
              //   getValues={getValues}
              //   suggestions={organizations.data}
              //   horizontal
              //   isMulti
              //   style={multiEditSelectHeaderStyle}
              //   allowQuickAdd
              //   allowUnassigned
              //   uncontained
              //   placeholder={getValueOrDefault(consolidatedValues?.organizations)}
              //   defaultValue={consolidatedValues?.organizations?.value ?? null}
              // />
            )}
          </MultiEditHeaderWrapper>
        ),
        accessor: (row, rowIndex, columnIndex) => (
          <div className="flex" style={{ overflow: "auto", width: "100%", height: "100%" }}>
            <MultiEditSelectForm id={`organizations`} entityConstants={organizationsConstants} row={row} isMulti />
          </div>
        ),
        minWidth: 400,
        width: 400,
      },
      {
        id: "default-preparedBy",
        Header: (
          <MultiEditHeaderWrapper<Sample>
            control={control}
            getValues={getValues}
            ids={selection}
            label={SampleFieldLabels.preparedBy}
            property="preparedBy"
            required
            hasApply
            hasAppend
          >
            {(consolidatedValues) => (
              <PersonsVirtualizedSelectForm
                id="preparedBy"
                control={control}
                hasLabel={false}
                placeholder={getValueOrDefault(consolidatedValues?.preparedBy)}
                defaultValue={consolidatedValues?.preparedBy?.value ?? null}
                horizontal
                allowCreateEntity
                allowUnassigned
                uncontained
                // showControls
                isMulti
              />
              // <PersonsSelectForm
              //   id={"preparedBy"}
              //   label=""
              //   control={control}
              //   errors={errors}
              //   required={false}
              //   setValue={setValue}
              //   getValues={getValues}
              //   suggestions={persons.data}
              //   horizontal
              //   isMulti
              //   style={multiEditSelectHeaderStyle}
              //   // allowQuickAdd
              //   allowUnassigned
              //   uncontained
              //   placeholder={getValueOrDefault(consolidatedValues?.preparedBy)}
              //   defaultValue={consolidatedValues?.preparedBy?.value ?? null}
              // />
            )}
          </MultiEditHeaderWrapper>
        ),
        accessor: (row, rowIndex, columnIndex) => (
          <div className="flex" style={{ overflow: "auto", width: "100%", height: "100%" }}>
            <MultiEditSelectForm id={`preparedBy`} entityConstants={personsConstants} row={row} required isMulti />
          </div>
        ),
        minWidth: 400,
        width: 400,
      },
      {
        id: "default-preparedAt",
        Header: (
          <MultiEditHeaderWrapper<Sample>
            control={control}
            getValues={getValues}
            ids={selection}
            label={SampleFieldLabels.preparedAt}
            property="preparedAt"
            hasApply
            required
          >
            {(consolidatedValues) => (
              <SingleDatePickerFormField
                id={"preparedAt"}
                label=""
                type="date"
                errors={errors}
                control={control}
                autoFocus={false}
                required
                uncontained
                placeholder={getValueOrDefault(consolidatedValues?.preparedAt)}
                defaultValue={consolidatedValues?.preparedAt?.value ?? null}
              />
            )}
          </MultiEditHeaderWrapper>
        ),
        accessor: (row, rowIndex, columnIndex) => (
          <div className="flex" style={{ overflow: "auto", width: "100%", height: "100%" }}>
            <MultiEditDateForm id={`preparedAt`} row={row} type="date" required />
          </div>
        ),
        width: 400,
        align: "left",
      },
      {
        id: "default-discardedBy",
        Header: (
          <MultiEditHeaderWrapper<Sample>
            control={control}
            getValues={getValues}
            ids={selection}
            label="Discarded by"
            property="discardedBy"
            hasApply
            hasAppend
          >
            {(consolidatedValues) => (
              <PersonsVirtualizedSelectForm
                id="discardedBy"
                control={control}
                hasLabel={false}
                placeholder={getValueOrDefault(consolidatedValues?.discardedBy)}
                defaultValue={consolidatedValues?.discardedBy?.value ?? null}
                horizontal
                allowCreateEntity
                allowUnassigned
                uncontained
                // showControls
                isMulti
              />
              // <PersonsSelectForm
              //   id={"discardedBy"}
              //   label=""
              //   control={control}
              //   errors={errors}
              //   required={false}
              //   setValue={setValue}
              //   getValues={getValues}
              //   suggestions={persons.data}
              //   horizontal
              //   isMulti
              //   style={multiEditSelectHeaderStyle}
              //   // allowQuickAdd
              //   allowUnassigned
              //   uncontained
              //   placeholder={getValueOrDefault(consolidatedValues?.discardedBy)}
              //   defaultValue={consolidatedValues?.discardedBy?.value ?? null}
              // />
            )}
          </MultiEditHeaderWrapper>
        ),
        accessor: (row, rowIndex, columnIndex) => (
          <div className="flex" style={{ overflow: "auto", width: "100%", height: "100%" }}>
            <MultiEditSelectForm id={`discardedBy`} entityConstants={personsConstants} row={row} isMulti />
          </div>
        ),
        minWidth: 400,
        width: 400,
      },
      {
        id: "default-discardedAt",
        Header: (
          <MultiEditHeaderWrapper<Sample>
            control={control}
            getValues={getValues}
            ids={selection}
            label={SampleFieldLabels.discardedAt}
            property="discardedAt"
            hasApply
          >
            {(consolidatedValues) => (
              <SingleDatePickerFormField
                id={"discardedAt"}
                label={""}
                type="date"
                errors={errors}
                control={control}
                autoFocus={false}
                uncontained
                placeholder={getValueOrDefault(consolidatedValues?.discardedAt)}
                defaultValue={consolidatedValues?.discardedAt?.value ?? null}
              />
            )}
          </MultiEditHeaderWrapper>
        ),
        accessor: (row, rowIndex, columnIndex) => (
          <div className="flex" style={{ overflow: "auto", width: "100%", height: "100%" }}>
            <MultiEditDateForm id={`discardedAt`} row={row} type="date" />
          </div>
        ),
        width: 400,
        align: "left",
      },
      {
        id: "default-other",
        Header: (
          <MultiEditHeaderWrapper<Sample>
            control={control}
            getValues={getValues}
            ids={selection}
            label={SampleFieldLabels.other}
            property="other"
            hasApply
            hasAppend
          >
            {(consolidatedValues) => (
              <InputFormField
                id={"other"}
                label={""}
                errors={errors}
                register={register}
                autoFocus={false}
                required={false}
                uncontained
                placeholder={getValueOrDefault(consolidatedValues?.other)}
                defaultValue={consolidatedValues?.other?.value ?? ""}
              />
            )}
          </MultiEditHeaderWrapper>
        ),
        accessor: (row, rowIndex, columnIndex) => (
          <div className="flex" style={{ overflow: "auto", width: "100%", height: "100%" }}>
            <MultiEditInputForm id={`other`} row={row} />
          </div>
        ),
        width: 400,
        align: "left",
      },
      {
        id: "default-notes",
        Header: (
          <MultiEditHeaderWrapper<Sample>
            control={control}
            getValues={getValues}
            ids={selection}
            label={SampleFieldLabels.notes}
            property="notes"
            hasApply
            hasAppend
          >
            {(consolidatedValues) => (
              <TextareaFormField
                id={"notes"}
                label=""
                errors={errors}
                register={register}
                autoFocus={false}
                required={false}
                uncontained
                placeholder={getValueOrDefault(consolidatedValues?.notes)}
                defaultValue={consolidatedValues?.notes?.value ?? ""}
                rows={2}
              />
            )}
          </MultiEditHeaderWrapper>
        ),
        accessor: (row, rowIndex, columnIndex) => (
          <div className="flex" style={{ overflow: "auto", width: "100%", height: "100%" }}>
            <MultiEditTextAreaForm id={`notes`} row={row} />
          </div>
        ),
        width: 400,
        align: "left",
      },
      {
        id: "default-type",
        Header: (
          <MultiEditHeaderWrapper<Sample>
            control={control}
            getValues={getValues}
            ids={selection}
            label={SampleFieldLabels.customType}
            additionalControls={
              <GenericModalWrapper>
                {({ showModal, setShowModal }) => (
                  <>
                    <AlertModal
                      type="success"
                      title="Confirm action"
                      description={`Switching between ${customTypeConstants.entityPlural} can lead to data loss. Please contact your system administrator if you are unsure about the implications.`}
                      showModal={showModal}
                      setShowModal={setShowModal}
                      proceedLabel={"Enable editing"}
                      // onCancel={() => setShowModal(false)}
                      onProceed={() => setTypeOverride(true)}
                      forceUserInput
                      forceUserInputText="EDIT"
                    />
                    {!typeOverride && (
                      <button className="btn btn-sm btn-success" onClick={() => setShowModal(true)}>
                        <LucideIcon name="square-pen" /> Enable editing
                      </button>
                    )}
                  </>
                )}
              </GenericModalWrapper>
            }
            property="customType"
            // required
            disabled={() => !typeOverride}
            hasApply
          >
            {(consolidatedValues) => (
              <CustomTypesVirtualizedSelectForm
                id="customType"
                control={control}
                hasLabel={false}
                placeholder={getValueOrDefault(consolidatedValues?.customType)}
                defaultValue={consolidatedValues?.customType?.value ?? null}
                disabled={!typeOverride}
                horizontal
                allowUnassigned
                uncontained
                filters={{ entityTypes: ["Sample"] }}
                // showControls
              />
            )}
          </MultiEditHeaderWrapper>
        ),
        accessor: (row, rowIndex, columnIndex) => (
          <MultiEditSelectForm
            id={`customType`}
            entityConstants={customTypeConstants}
            row={row}
            required
            disabled={!typeOverride}
            filters={{ entityTypes: ["Sample"] }}
          />
        ),
        minWidth: 300,
        width: 400,
        sortingFn: () => {
          if (sort.headerId === "default-type") {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "TYPE_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "TYPE_ASC" }));
            }
          } else {
            setSort({ headerId: "default-type", sortDirection: "ASC", orderBy: "TYPE_ASC" });
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
    ];

    if (types) {
      res = res.concat(
        multiEditCustomFieldTableCells({
          types: types,
          control: control,
          register: register,
          getValues: getValues,
          selection: selection,
          disabled: !typeOverride,
        })
      );
    }

    return res;
  }, [control, errors, getValues, register, types, selection, sort.headerId, sort.sortDirection, typeOverride]);

  const onSelectionChange = useCallback((selection: Set<number>) => {
    setSelection(selection);
  }, []);

  const onSuccessCallback = useCallback(
    (data: Sample[]) => {
      const _data = Object.fromEntries(Object.entries(data).map(([, d]) => [d.id, d]));
      history.replace({ state: { ...location.state, items: _data } });
    },
    [history, location.state]
  );

  const beforeSubmit = useCallback(
    (data: MultiEditMappedItemObject<Sample>) => {
      return Object.fromEntries(
        Object.entries(data).map(([id, d]) => [
          id,
          {
            ...d,
            ...(d.customType && types && processValuesForTypedEntity(d, types?.[d.customType.id])),
            preparedAt: d.preparedAt ? iso2localDate(d.preparedAt).toISOString() : "",
            discardedAt: d.discardedAt ? iso2localDate(d.discardedAt).toISOString() : null,
          },
        ])
      );
    },
    [types]
  );

  const beforeInit = useCallback(
    (data: MultiEditMappedItemObject<Sample>) => {
      return Object.fromEntries(
        Object.entries(data).map(([id, d]) => [
          id,
          {
            ...d,
            customFields: {},
            ...(d.customType && types && processInitialValuesForTypedEntity(d, types?.[d.customType.id])),
          },
        ])
      );
    },
    [types]
  );

  const { onSubmit, loading } = useMultiEditUtils<Sample>({
    entityConstants: samplesConstants,
    showToast: true,
    onSuccessCallback: onSuccessCallback,
    beforeSubmit: beforeSubmit,
    beforeReset: beforeInit,
  });

  const { conditionalTypeFormSchema } = useCustomFieldsValidationObjectShape();

  const conditionalSampleFormSchema = useMemo(
    () => ({
      name: yup.string().required("A sample name is required"),
      preparedAt: yup.date().required("Preparation date is required"),
      projects: yup.array().ensure().min(1, "At least one project is required"),
      preparedBy: yup.array().min(1, "Prepared by is required"),
      ...conditionalTypeFormSchema,
    }),
    [conditionalTypeFormSchema]
  );

  if (initItems && !Object.keys(initItems).length && !Object.keys(defaultFilters).length)
    return <Alert type="info" message="Bulk editing is only available to a prior selection" fit centered />;
  return (
    <MultiEditProvider<Sample>
      resource={samplesConstants.resource}
      initItems={initItems}
      ids={defaultFilters.ids ?? []}
      entityValidationSchema={conditionalSampleFormSchema}
      beforeInit={beforeInit}
      isCustomSchemaEntity
      canAbort
    >
      {({ consolidatedValues, items }) => (
        <MultiEditTable<Sample, SampleFilters>
          entityConstants={samplesConstants}
          columns={columns}
          filters={filters}
          defaultSelection={defaultFilters.ids ?? undefined}
          onSelectionChange={onSelectionChange}
          onSubmit={onSubmit}
          loading={loading}
          functionRef={functionRef}
        />
      )}
    </MultiEditProvider>
  );
};
