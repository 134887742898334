import {
  PropertyTranslator,
  IGenericRequestParametersTranslator,
  IPaginationParametersTranslator,
  ICreatedRecordParametersTranslator,
  IModifiedRecordParametersTranslator,
  IRelationParametersTranslator,
  ISoftDeletableFilterParametersTranslator,
  ITypedEntityCustomValuesSearchParametersTranslator,
  ICreationRecordLabels,
  ICustomTypedEntityLabels,
  IModificationRecordLabels,
  IPermissionedEntityLabels,
  INamedEntityLabels,
  IRelatedEntityLabels,
  IUniqueEntityLabels,
  ISoftDeletableLabels,
} from "./GenericTranslator";
import {
  IRelationModel,
  IGenericRequestParameters,
  IEntityMinimalModel,
  IModelWithIntId,
  INamedEntity,
  IPaginationParameters,
  IPermissionedEntity,
  IRelatedEntity,
  IRelation,
  IUniqueEntity,
  IRelationParameters,
  EntityConstants,
  ISoftDeletableFilterParameters,
  ICreatedRecordParameters,
  IModifiedRecordParameters,
  ISoftDeletable,
  ICreationRecord,
  IModificationRecord,
  DefaultCreatedByRecordOrderTypeConsts,
  DefaultCreatedOnRecordOrderTypeConsts,
  DefaultModifiedByRecordOrderTypeConsts,
  DefaultModifiedOnRecordOrderTypeConsts,
  DefaultOrderTypeConsts,
  ICustomTypedEntity,
  TypedEntitySuggestionsModel,
  ITypedEntityCustomValuesSearchParameters,
  CustomFieldValuesSearchParametersITypedEntity,
} from "./GenericTypes";

export const personsConstants: EntityConstants<Person, PersonFilters> = {
  resource: "persons",
  frontendIndexRoute: "persons",
  entitySingular: "person",
  entityPlural: "persons",
  icon: "user",
};

export const PersonFieldLabels: PropertyTranslator<Person> = {
  ...IPermissionedEntityLabels,
  ...ICreationRecordLabels,
  ...IModificationRecordLabels,
  ...ICustomTypedEntityLabels,
  ...INamedEntityLabels,
  ...IRelatedEntityLabels,
  ...IUniqueEntityLabels,
  ...ISoftDeletableLabels,
  accountState: "Account state",
  email: "E-mail",
  firstName: "First name",
  id: "Person ID",
  isSystemUser: "Is system user",
  lastName: "Last name",
  login: "Login",
  notes: "Notes",
  officePhone: "Office phone",
  organization: "Organization",
  personTags: "Tags",
  phone: "Phone",
  privateAddress: "Address",
  projects: "Project access",
  roles: "Roles",
  salutation: "Salutation",
  web: "Web address",
  has2FA: "Two-factor authentication (2FA)",
  isLocalUser: "Login type",
} as const;

export interface PersonRelations extends IRelationModel {
  datasets: IRelation;
  samples: IRelation;
  labNotebooks: IRelation;
  labNotebookExperiments: IRelation;
  labNotebooksEntries: IRelation;
  labNotebooksEntryMentions: IRelation;
}

type AccountState = "NoAccount" | "Enabled" | "Disabled";

export interface Person
  extends IModelWithIntId,
    IPermissionedEntity,
    IRelatedEntity<PersonRelations>,
    INamedEntity,
    IUniqueEntity,
    ISoftDeletable,
    ICreationRecord,
    IModificationRecord,
    ICustomTypedEntity {
  firstName?: string;
  lastName: string;
  salutation?: string;
  notes?: string;
  officePhone?: string | null;
  email?: string | null;
  privateAddress?: string;
  web?: string | null;
  phone?: string | null;
  organization?: IEntityMinimalModel;
  personTags?: IEntityMinimalModel[];
  projects?: IEntityMinimalModel[];
  roles?: IEntityMinimalModel[];
  login?: string;
  accountState?: AccountState;
  isSystemUser?: boolean;
  isLocalUser: boolean;
  has2FA?: boolean;
}
export interface PersonSuggestions extends TypedEntitySuggestionsModel<Person["id"]> {}

export interface PersonWriteModel extends Person {
  password?: string;
  oldPassword?: string;
}
export interface PersonFilters
  extends IGenericRequestParameters<Person, PersonSortingOptions>,
    IRelationParameters,
    IPaginationParameters,
    IRelationParameters,
    ISoftDeletableFilterParameters,
    ICreatedRecordParameters,
    IModifiedRecordParameters,
    ITypedEntityCustomValuesSearchParameters<CustomFieldValuesSearchParametersITypedEntity> {
  personTagIds?: number[] | null;
  organizationIds?: number[] | null;
  roleIds?: number[] | null;
  includeSystemUsers?: boolean;
  hasAccount?: boolean | null;
  isAccountEnabled?: boolean | null;
  logins?: string[] | null;
  isLocalUser?: boolean | null;
}
export const PersonFiltersTranslator: PropertyTranslator<PersonFilters> = {
  ...IGenericRequestParametersTranslator,
  ...IRelationParametersTranslator,
  ...IPaginationParametersTranslator,
  ...IRelationParametersTranslator,
  ...ISoftDeletableFilterParametersTranslator,
  ...ICreatedRecordParametersTranslator,
  ...IModifiedRecordParametersTranslator,
  ...ITypedEntityCustomValuesSearchParametersTranslator,
  personTagIds: "Person-Tag IDs",
  organizationIds: "Organization IDs",
  roleIds: "Role IDs",
  includeSystemUsers: "Include system users",
  hasAccount: "Has account?",
  isAccountEnabled: "Account status",
  logins: "Logins",
  isLocalUser: "Login type",
} as const;

export interface multiFormPropertyType {
  label: string;
  value: string;
  isRequired: boolean;
}

export const PersonSortingOptionsConsts = [
  ...DefaultOrderTypeConsts,
  ...DefaultCreatedOnRecordOrderTypeConsts,
  ...DefaultCreatedByRecordOrderTypeConsts,
  ...DefaultModifiedOnRecordOrderTypeConsts,
  ...DefaultModifiedByRecordOrderTypeConsts,
  "SALUTATION_ASC",
  "SALUTATION_DESC",
  "LAST_NAME_ASC",
  "LAST_NAME_DESC",
  "FIRST_NAME_ASC",
  "FIRST_NAME_DESC",
  "ORGANIZATION_ASC",
  "ORGANIZATION_DESC",
  "LOGIN_DISABLED_ASC",
  "LOGIN_DISABLED_DESC",
  "LOGIN_ASC",
  "LOGIN_DESC",
  "IS_SYSTEM_USER_ASC",
  "IS_SYSTEM_USER_DESC",
  "IS_LOCAL_USER_ASC",
  "IS_LOCAL_USER_DESC",
] as const;
export type PersonSortingOptions = (typeof PersonSortingOptionsConsts)[number];
