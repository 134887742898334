import { Button } from "react-bootstrap";

import { LucideIcon } from "../../common/icon/LucideIcon";
import { settingTrackType } from "../../ViewerLayout/ViewerLayoutTypes";
import { annotationVisibilityType, axisElementType, drawType, normalizeType } from "../../ViewerLayout/ViewerTypes";
import { CheckBoxSelectorDiv, selectState as _selectState } from "../../ViewerUIElements/CheckBoxSelector";
import viewerStyles from "./ViewerNavigation.module.css";

export type settingType = {
  type: settingTrackType[];
  // draw: drawType[];
  // axisLabel: axisLabelType[];
  // normalize: normalizeType[];
  // annotation: annotationType[];
  annotation: Record<annotationVisibilityType, selectState>;
  draw: Record<drawType, selectState>;
  hideAxis: Record<axisElementType, selectState>;
  normalize: Record<normalizeType, selectState>;
};

export const initSettingType = (ref?: Partial<settingType>): settingType => {
  return {
    type: ref?.type ?? [],
    annotation: ref?.annotation ?? { tics: true, labels: true },
    draw: ref?.draw ?? { line: true, dots: true, heatmap: true, contour: true, filled: true },
    hideAxis: ref?.hideAxis ?? {
      x: false,
      y: false,
      z: false,
    },
    normalize: ref?.normalize ?? {
      "0-1": false,
      "-1-1": false,
    },
  };
};

export type selectState = _selectState;

type itemType = {
  key: string;
  text: string;
};

type elementPropType = {
  title: string;
  multiselect: boolean;
  preselect?: Record<string, selectState>;
  items: itemType[];
  onClick: any;
  types: settingTrackType[];
};
const getElementPropForType = (settings: settingType, onClick: any, ids: string[]): elementPropType[] => {
  const elementProps: elementPropType[] = [
    {
      title: "Draw:",
      multiselect: true,
      // preselect: Object.fromEntries(settings.draw.map((draw) => [draw, true])),
      preselect: settings.draw,
      items: [
        { key: "line", text: "Line" },
        { key: "dots", text: "Dot" },
      ],
      types: [settingTrackType.track1D],
      onClick: (state: Record<string, boolean>) => (onClick ? onClick(ids, "draw", state) : {}),
    },
    {
      title: "Draw:",
      multiselect: true,
      // preselect: Object.fromEntries(settings.draw.map((entry) => [entry, true])),
      preselect: settings.draw,
      items: [
        { key: "heatmap", text: "Heatmap" },
        { key: "contour", text: "Contour" },
        { key: "filled", text: "Filled" },
      ],
      types: [settingTrackType.track2D],
      onClick: (state: Record<string, boolean>) => (onClick ? onClick(ids, "draw", state) : {}),
    },
    {
      title: "Hide tics:",
      multiselect: true,
      // preselect: {}, //Object.fromEntries(settings.axisLabel.map((entry) => [entry, true])),
      preselect: settings.hideAxis,
      items: [
        { key: "x", text: "X-axis" },
        { key: "y", text: "Y-axis" },
      ],
      types: [settingTrackType.track1D, settingTrackType.track2D],
      onClick: (state: Record<string, boolean>) => (onClick ? onClick(ids, "hideAxis", state) : {}),
    },
    {
      title: "Normalize:",
      multiselect: false,
      // preselect: Object.fromEntries(settings.normalize.map((entry) => [entry, true])),
      preselect: settings.normalize,
      items: [
        { key: "0-1", text: "to range [0;1]" },
        { key: "-1-1", text: "to range [-1;1]" },
      ],
      types: [settingTrackType.track1D],
      onClick: (state: Record<string, boolean>) => (onClick ? onClick(ids, "normalize", state) : {}),
    },
    {
      title: "Annotations:",
      multiselect: true,
      // preselect: { "annotation-tics": true, "annotation-labels": true },
      // preselect: Object.fromEntries(settings.annotation.map((entry) => [entry, true])),
      preselect: settings.annotation,
      items: [
        { key: "tics", text: "Tics" },
        { key: "labels", text: "Labels" },
      ],
      types: [settingTrackType.track1D, settingTrackType.track2D],
      onClick: (state: Record<string, boolean>) => (onClick ? onClick(ids, "annotation", state) : {}),
    },
  ];

  // console.log("Settings", settings, elementProps);

  return elementProps.filter((prop) => {
    let found = false;
    for (let type of prop.types) {
      // console.log(prop.title, " >>", type, "in", settings.type);
      if (settings.type.includes(type as settingTrackType)) {
        found = true;
        break;
      }
    }
    // console.log("Types", prop.types, "in", types, "=>", found);
    return found;
  });
};

export const TrackSettings = function (props: any) {
  const {
    settings,
    onChange,
    onZoom,
    tracks,
    ...otherProps
  }: { settings: settingType; onChange: any; onZoom: any; tracks: string[] } = props;

  const elementProps = getElementPropForType(settings || {}, onChange, tracks);

  // console.log("onZoom", onZoom);
  // console.log("settings", settings.annotation);

  return (
    // <div id="specviewer-buttons" className="viewer-top">
    <div {...otherProps}>
      <div className={viewerStyles.viewerButtonLine}>
        <div className={viewerStyles.viewerButtonRow}>
          <div className={viewerStyles.viewerButtonColumn}>
            <Button className="btn-xs" style={{ marginBottom: "5px" }} onClick={() => (onZoom ? onZoom(tracks) : {})}>
              {/* <span className="glyphicon glyphicon-fullscreen" /> Zoom in */}
              <LucideIcon name="maximize" style={{ strokeWidth: 3 }} /> Zoom in
            </Button>
          </div>
          <CheckBoxSelectorDiv elements={elementProps} />
        </div>
      </div>
      {/* <ViewerTrackNavigation /> */}
    </div>
  );
};
