import React, { CSSProperties } from "react";
import { OverlayTrigger, OverlayTriggerProps, Popover } from "react-bootstrap";
import { GenericIdEntity, IUniqueEntity } from "../../../api/GenericTypes";
import { LucideIcon } from "../../icon/LucideIcon";

interface UIDProps<Entity extends GenericIdEntity & IUniqueEntity<Entity["id"]>> {
  entity: Entity;
  placement?: OverlayTriggerProps["placement"];
  style?: CSSProperties;
}
export const UID = <Entity extends GenericIdEntity & IUniqueEntity<Entity["id"]>>({
  entity,
  placement = "left",
  style,
}: UIDProps<Entity>) => {
  return (
    <div className="flex row-nowrap align-center" style={{ cursor: "pointer" }}>
      <OverlayTrigger
        trigger={"click"}
        placement={placement}
        overlay={
          <Popover id="uid">
            UID: <span style={{ fontWeight: "500", textTransform: "uppercase" }}>{entity.uid}</span>
          </Popover>
        }
      >
        <LucideIcon name="fingerprint" color={"var(--gray-500)"} style={{ width: "21px", height: "21px", ...style }} />
      </OverlayTrigger>
    </div>
  );
};
