import { StringIndexedDict } from "../../../../api/GenericTypes";
import {
  GenericEntityVirtualizedEntitySelectFormFieldProps,
  GenericVirtualizedEntitySelectFormFieldControllerWrapper,
} from "../../../formfields/GenericVirtualizedSelectFormField/GenericVirtualizedEntitySelectFormFieldControllerWrapper";
import { QuickAddWrapper } from "../../QuickAdd/common/QuickAddWrapper";
import { useContext } from "react";
import { SessionContext } from "../../../contexts/SessionContext";
import { OrganizationFilters } from "../../../../api/Organizations";
import {
  OrganizationTag,
  OrganizationTagSortingOptionsConsts,
  OrganizationTagSuggestions,
  organizationTagsConstants,
} from "../../../../api/OrganizationTag";
import { QuickAddOrganizationTagForm } from "../../QuickAdd/forms/QuickAddOrganizationTagForm";

export const OrganizationsTagsVirtualizedSelectForm = <Entity extends StringIndexedDict>(
  props: GenericEntityVirtualizedEntitySelectFormFieldProps<Entity, OrganizationTagSuggestions, OrganizationFilters>
) => {
  const { session } = useContext(SessionContext);

  return (
    <GenericVirtualizedEntitySelectFormFieldControllerWrapper<Entity, OrganizationTagSuggestions, OrganizationFilters>
      {...props}
      filters={{ orderBy: "NAME_ASC", ...props.filters }}
      //   onRowRenderer={(item) => <></>}
      createModal={({ showModal, setShowModal, onCreate }) => (
        <QuickAddWrapper<OrganizationTag> showModal={showModal} setShowModal={setShowModal} onCreate={onCreate}>
          {(props) => <QuickAddOrganizationTagForm {...props} />}
        </QuickAddWrapper>
      )}
      entityConstants={organizationTagsConstants}
      orderByOptions={OrganizationTagSortingOptionsConsts}
      allowCreateEntity={session?.permissions.administer_organizations && props.allowCreateEntity}
    />
  );
};
