import React from "react";
import { SearchInput, SearchInputProps } from "../SearchInput/SearchInput";
import { ISearchTermParameters, StringIndexedDict } from "../../../api/GenericTypes";
import { CheckboxRound } from "../../formfields/CheckboxFormField/CheckboxFormField";
import { toUppercase } from "../../helperfunctions/stringFunctions";

const searchTermParamPrefix = "searchTermInclude";
export const searchTermMinLength = 3;
interface SearchInputWithOptionsProps<Filters extends StringIndexedDict & ISearchTermParameters>
  extends SearchInputProps {
  searchTermOptions: { [key in keyof Filters]: string };
  filters: Filters;
  setFilters: (newFilters: Filters) => void;
}
const SearchInputWithOptions = <Filters extends StringIndexedDict & ISearchTermParameters>({
  placeholder = "Search",
  searchValue,
  setSearchValue,
  size,
  children,
  minimized = false,
  minimizedBtnCls,
  minimizedDisabled = false,
  title,
  error,
  disabled,
  disableDefaultError = true,
  searchTermOptions,
  filters,
  setFilters,
}: SearchInputWithOptionsProps<Filters>) => {
  const activeFilters = Object.entries(filters)
    .filter(([key, value]) => key.startsWith(searchTermParamPrefix) && value)
    .map(([key, _]) => searchTermOptions[key]);

  const showMinLengthError = disableDefaultError
    ? false
    : filters.searchTerm && filters.searchTerm.length < searchTermMinLength && !filters.searchTermIncludeIds;
  const showMinCharError = disableDefaultError
    ? false
    : filters.searchTerm && filters.searchTerm.length < searchTermMinLength && filters.searchTermIncludeIds;
  return (
    <div className="flex col-nowrap" style={{ gap: "2.5px", width: "100%" }}>
      <SearchInput
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        size={size}
        minimized={minimized}
        minimizedBtnCls={minimizedBtnCls}
        minimizedDisabled={minimizedDisabled}
        title={title}
        // size="sm"
        placeholder={
          Array.isArray(activeFilters) && !!activeFilters.length
            ? `Search in ${activeFilters.map((f) => toUppercase(f)).join(", ")}...`
            : placeholder
        }
        error={
          showMinLengthError
            ? `Please enter at least ${searchTermMinLength} characters...`
            : showMinCharError
            ? `Please enter at least ${searchTermMinLength} characters to include text based searching...`
            : error
        }
        disabled={disabled || !activeFilters.length}
        disableDefaultError
      >
        {children}
      </SearchInput>
      <div className="flex row-nowrap gap-5" style={{ overflow: "hidden", fontSize: "1rem" }}>
        <span style={{ color: "gray" }}>Include:</span>
        {Object.entries(searchTermOptions).map(([searchOption, humanReadableOption], i) => (
          <div className="flex row-nowrap align-center" style={{ gap: "2.5px", overflow: "hidden" }} key={i}>
            <CheckboxRound
              checked={!!filters?.[searchOption]}
              onChange={(_, checked) =>
                setFilters({
                  ...filters,
                  [searchOption]: checked,
                })
              }
            />
            <div className="ellipsisContainer">
              <span
                style={{
                  color: "gray",
                }}
              >
                {toUppercase(humanReadableOption)}
              </span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
export default SearchInputWithOptions;
