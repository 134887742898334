import { APIError } from "../../api/ApiTypes";
import { showtoast } from "../overlays/Toasts/showtoast";
import styles from "./ApiError.module.css";

export class ServerError extends Error {
  status: number | undefined;
  details: string | undefined;
  description: string | undefined;
  type: string | undefined;
  payload: any;

  constructor({
    header = "Internal server error",
    description = "",
    title = "Unexpected error",
    details = "",
    status = 500,
    type,
    payload,
  }: APIError) {
    super(title);
    this.name = header || "Server error";
    this.message = title || "An unexpected error occurred. Try again or contact support."; // we map title from APIError to message here, as we want to treat this as a native JS error
    this.details = details || description || "No information available";
    this.status = status;
    this.description = description;
    this.type = type;
    this.payload = payload;
    Object.setPrototypeOf(this, ServerError.prototype);
  }
}

export const throwServerError = async (response: Response) => {
  const rawResponse = await response.text();
  let serverError: string | APIError;
  try {
    serverError = (await JSON.parse(rawResponse)) as APIError;
  } catch (e) {
    serverError = rawResponse;
  }
  console.error(`[ERROR]: ${rawResponse}`);
  if (typeof serverError === "object") throw new ServerError(serverError);
  throw new ServerError({
    title: "Internal server error",
    status: 500,
    details: "An unexpected error occurred. Try again or contact support.",
  });
};

export const ErrorContainer = ({
  message,
  description,
  status,
}: {
  message: ServerError["message"];
  description?: ServerError["description"];
  status: ServerError["status"];
}) => {
  return (
    <div className={styles.errorContainer}>
      <div className={styles.errorContainerLabel}>
        Error: <span>{message}</span>
      </div>
      {description && (
        <div className={styles.errorContainerDescription}>
          Description: <span>{description}</span>
        </div>
      )}
      {status && (
        <div className={styles.errorContainerStatus}>
          Code: <samp className="badge">{status}</samp>
        </div>
      )}
    </div>
  );
};

export const renderError = (error: Error | APIError | unknown) => {
  if (error) {
    if (typeof error === "object" && Object.hasOwn(error, "message")) {
      showtoast(
        "error",
        <ErrorContainer
          message={(error as ServerError).message}
          description={(error as ServerError).description}
          status={(error as ServerError).status}
        />
      );
    } else {
      // Unknown type?
      showtoast("error", `Error: ${error}`);
    }
  }
};
