import { useState } from "react";

import styles from "./ColorSliderThumb.module.css";

export const ColorSliderThumb = ({ color, onSingleClick }: { color: string; onSingleClick: () => void }) => {
  // const { onSingleClick, ...otherProps } = props;

  const [time, setTime] = useState<number>(0);

  const onMouseUp = (e: any) => {
    // console.log("TIME", index, time);
    // console.log("CLICK", new Date().getTime() - time);
    if (new Date().getTime() - time < 200) {
      // console.log("--> CLICK");
      // activateSlider(index);
      if (onSingleClick) onSingleClick();
      // toolHandler(e);
    }
    setTime(0);
  };

  return (
    <div
      className={styles.main}
      style={{ background: color }}
      onMouseDown={(e) => {
        setTime(new Date().getTime());
      }}
      onMouseUp={onMouseUp}
    />
  );
};
