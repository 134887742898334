// import {
//   IGenericRequestParameters,
//   IModelWithIntId,
//   INamedEntity,
//   IOwnedEntity,
//   IPaginationParameters,
//
//   IRelationParameters,
//   IUniqueEntity,
//   IPermissionedEntity,
//   EntityConstants,
//   ICreationRecord,
//   IModificationRecord,
//   ICreatedRecordParameters,
//   IModifiedRecordParameters,
//   IEntityMinimalModel,
//   IRelation,
//   IRelationModel,
//   IRelatedEntity,
// } from "./GenericTypes";

import { EquipmentFacility } from "./Facilities";
import {
  ICustomTypedEntityLabels,
  INamedEntityLabels,
  IPermissionedEntityLabels,
  IRelatedEntityLabels,
  IUniqueEntityLabels,
  PropertyTranslator,
} from "./GenericTranslator";

// export interface Equipment
//   extends IModelWithIntId,
//     INamedEntity,
//     IOwnedEntity,
//     IPermissionedEntity,
//     IUniqueEntity,
//     ICreationRecord,
//     IModificationRecord,
//     IRelatedEntity<EquipmentRelations> {
//   notes?: string;
//   serialnumber?: string;
//   localPhone?: string;
//   room?: string;
//   homeLab?: IEntityMinimalModel;
//   homeLabContacts?: IEntityMinimalModel[];
//   company?: IEntityMinimalModel;
//   companyContacts?: IEntityMinimalModel[];
//   method: IEntityMinimalModel;
//   hostname?: string;
//   isObsolete?: boolean;
//   model?: string;
//   isBookable?: boolean;
//   reverseSsh?: boolean;
// }
// export interface EquipmentRelations extends IRelationModel {
//   datasets: IRelation;
// }
// export interface EquipmentFilters
//   extends IPaginationParameters,
//     IGenericRequestParameters<Equipment, EquipmentSortingOptions>,
//
//     IRelationParameters,
//     ICreatedRecordParameters,
//     IModifiedRecordParameters {}

// export type EquipmentSortingOptions =
//   | "ID_ASC"
//   | "ID_DESC"
//   | "NAME_ASC"
//   | "NAME_DESC"
//   | "IS_OBSOLETE_ASC"
//   | "IS_OBSOLETE_DESC"
//   | "CREATED_ON_ASC"
//   | "CREATED_ON_DESC"
//   | "CREATED_BY_ASC"
//   | "CREATED_BY_DESC"
//   | "MODIFIED_ON_ASC"
//   | "MODIFIED_ON_DESC"
//   | "MODIFIED_BY_ASC"
//   | "MODIFIED_BY_DESC";

// export const equipmentsConstants: EntityConstants = {
//   resource: "equipments",
//   frontendIndexRoute: "equipments",
//   entitySingular: "equipment",
//   entityPlural: "equipments",
//   icon: "hard-drive",
// };

export const EquipmentFieldLabels: PropertyTranslator<EquipmentFacility> = {
  ...INamedEntityLabels,
  ...IUniqueEntityLabels,
  ...IPermissionedEntityLabels,
  ...IRelatedEntityLabels,
  ...ICustomTypedEntityLabels,
  company: "Company",
  companyContacts: "Company contacts",
  createdOn: "Created on",
  equipmentTag: "Equipment tag",
  homeLab: "Home Lab",
  homeLabContacts: "Home lab contacts",
  id: "Equipment ID",
  isObsolete: "In use?",
  localPhone: "Local phone",
  model: "Model",
  modifiedOn: "Modified on",
  notes: "Notes",
  owner: "Owner",
  room: "Room",
  serialnumber: "Serialnumber",
} as const;
