import { EntityAddForm, EntityAddFormPage } from "../../common/entity/EntityAddForm";
import { CustomFieldsForm } from "./CustomFieldsForm";
import { EntityCloneForm, EntityCloneFormPage } from "../../common/entity/EntityCloneForm";
import { EntityCloneFormProps, EntityDetailViewProps, EntityEditFormProps } from "../../common/entity/EntityInterfaces";
import { EntityEditForm, EntityEditFormPage } from "../../common/entity/EntityEditForm";
import { EntityDetailPage, EntityDetailView } from "../../common/entity/EntityDetailPage";
import { CustomFieldsDetail } from "./CustomFieldsDetailView";
import { EntityTablePage } from "../../common/entity/EntityTablePage";
import { CustomFieldsTable } from "./Table/CustomFieldsTable";

// Add
export const CustomFieldAddForm = () => {
  return <EntityAddForm entityTypeId={"customFields"}>{(props) => <CustomFieldsForm {...props} />}</EntityAddForm>;
};

export const CustomFieldAddFormPage = () => {
  return (
    <EntityAddFormPage>
      <CustomFieldAddForm />
    </EntityAddFormPage>
  );
};

// Clone
export const CustomFieldCloneForm = ({ id }: EntityCloneFormProps<"customFields">) => {
  return (
    <EntityCloneForm id={id} entityTypeId={"customFields"}>
      {(props) => <CustomFieldsForm {...props} />}
    </EntityCloneForm>
  );
};

export const CustomFieldCloneFormPage = () => {
  return <EntityCloneFormPage<"customFields"> isIntId>{(id) => <CustomFieldCloneForm id={id} />}</EntityCloneFormPage>;
};

// Edit
export const CustomFieldEditForm = ({ id }: EntityEditFormProps<"customFields">) => {
  return (
    <EntityEditForm id={id} entityTypeId={"customFields"} filters={{}}>
      {(props) => <CustomFieldsForm {...props} />}
    </EntityEditForm>
  );
};

export const CustomFieldEditFormPage = () => {
  return <EntityEditFormPage<"customFields"> isIntId>{(id) => <CustomFieldEditForm id={id} />}</EntityEditFormPage>;
};

// Detail
export const CustomFieldsDetailView = ({ id }: EntityDetailViewProps<"customFields">) => {
  return (
    <EntityDetailView id={id} entityTypeId={"customFields"} filters={{}}>
      {(props) => <CustomFieldsDetail {...props} />}
    </EntityDetailView>
  );
};

export const CustomFieldDetailsPage = () => {
  return <EntityDetailPage<"customFields"> isIntId>{(id) => <CustomFieldsDetailView id={id} />}</EntityDetailPage>;
};

export const CustomFieldsTablePage = () => {
  return <EntityTablePage entityTypeId={"customFields"}>{(props) => <CustomFieldsTable {...props} />}</EntityTablePage>;
};
