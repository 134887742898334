import * as d3 from "d3";
import { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import {
  Button,
  ButtonToolbar,
  Collapse,
  DropdownButton,
  FormControl,
  FormGroup,
  HelpBlock,
  InputGroup,
  MenuItem,
} from "react-bootstrap";
import ReactDOMServer from "react-dom/server";
import { useResizeDetector } from "react-resize-detector";
import { LucideIcon } from "../../common/icon/LucideIcon";
import { d3DataType, MainchartEntries, SelectedState } from "../Dashboard";

import styles from "./Stats.module.css";

// Date formatter
const formatDate = d3.timeFormat("%Y-%m-%d");
// const axis_formatDate = d3.timeFormat("%m-%d");
// const hexcolor = (color: any) => d3.rgb(color).formatHex() // d3 v6
const hexcolor = (color: any) => d3.rgb(color).hex(); // d3 v4
interface Props {
  d3Data: d3DataType;
  dateCallback: Function;
  pieStatsCallback: Function;
  activeColor: number;
  colorCallback: Function;
  selection: SelectedState;
  toggleSelection: Function;
  currentBrush: [Date, Date] | undefined;
  currentBrushCallback: Function;
  // parentHeight: number;
}

export const MainChart = ({
  d3Data,
  dateCallback,
  pieStatsCallback,
  activeColor,
  colorCallback,
  selection,
  toggleSelection,
  currentBrush,
  currentBrushCallback,
}: // parentHeight,
Props) => {
  const { ref: svgRef, height: svgHeight } = useResizeDetector();
  // const svgRef = useRef<HTMLDivElement>(null);
  const [stackedData, setStackedData] = useState<d3.Series<{ [key: string]: number }, string>[]>();
  const [dataselection, setDataSelection] = useState<MainchartEntries>();
  // const [currentBrush, setCurrentBrush] = useState<[Date, Date] | null>(null)
  const [color, setColor] = useState<{ [subgroup: string]: string }>();

  const [showCalendar, setShowCalendar] = useState(false);
  const [validationState, setValidationState] = useState<"success" | "warning" | "error" | null>(null);
  const [showError, setShowError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const startDate = useRef("");
  const endDate = useRef("");
  const [activeBtn, setActiveBtn] = useState<string>();

  // const intersection = useCallback((setA: Set<string>, setB: Set<string>) => {
  //   const _intersection: Set<string> = new Set();
  //   setB.forEach((elem) => {
  //     if (setA.has(elem)) {
  //       _intersection.add(elem);
  //     }
  //   });
  //   return _intersection;
  // }, []);

  // Stack generator
  const stacked = useCallback(
    (data: any, keys: string[]) =>
      d3
        .stack()
        .keys(keys)
        .value((obj: any, key: any) => obj.subgroups[key])
        .order(d3.stackOrderReverse)(data),
    []
  );

  useEffect(() => {
    if (validationState) {
      if (validationState === "warning") setShowError(() => true);
      if (validationState === "error") setShowError(() => true);
      if (validationState === "success") setShowError(() => false);
    } else {
      setShowError(() => false);
    }
  }, [validationState]);

  // Set up the environment
  const width = useMemo(() => 860, []);
  // Total height will be calculated from the individual heights
  const margin = useMemo(() => ({ top: 5, right: 10, bottom: 30, left: 40 }), []);

  // Legend
  const width_legend = useMemo(() => 0, []);

  // Main chart
  const height_main = useMemo(() => 360, []);
  const width_main = useMemo(
    () => width - margin.left - margin.right - width_legend,
    [margin.left, margin.right, width, width_legend]
  ); // x => [margin.left, width_main]

  // Overview
  const margin_overview = useMemo(
    () => ({ top: 40, left: 0, bottom: 20, right: margin.right + width_legend }),
    [margin.right, width_legend]
  );
  const height_overview = useMemo(() => 50, []);

  // Calculate the total height
  const height = useMemo(
    () => margin.top + height_main + margin_overview.top + height_overview + margin_overview.bottom + margin.bottom,
    [height_main, height_overview, margin.bottom, margin.top, margin_overview.bottom, margin_overview.top]
  );

  // Calculate the translations
  const xAxisRange = useMemo(
    () => [margin.left, width - margin.right - width_legend],
    [margin.left, margin.right, width, width_legend]
  );

  const yMainRange = useMemo(
    () => [height - margin.bottom - margin_overview.bottom - height_overview - margin_overview.top, margin.top],
    [height, height_overview, margin.bottom, margin.top, margin_overview.bottom, margin_overview.top]
  );

  const yOverviewRange = useMemo(
    () => [
      height - margin.bottom - margin_overview.bottom,
      height - margin.bottom - margin_overview.bottom - height_overview,
    ],
    [height, height_overview, margin.bottom, margin_overview.bottom]
  );

  // Set up the axis ranges
  // Main Chart
  const x = useMemo(() => d3.scaleUtc().range(xAxisRange).nice(), [xAxisRange]);
  const xBand = useMemo(
    () =>
      d3
        .scaleBand()
        .range(xAxisRange as any)
        .padding(0.3),
    [xAxisRange]
  );

  const y = useMemo(() => d3.scaleLinear().range(yMainRange), [yMainRange]);
  // Overview
  const xOverview = useMemo(() => d3.scaleUtc().range(xAxisRange).nice(), [xAxisRange]);

  const yOverview = useMemo(() => d3.scaleLinear().range(yOverviewRange), [yOverviewRange]);

  // Set up the axes
  const xAxis = useMemo(() => d3.axisBottom(x), [x]);
  // .tickFormat(axis_formatDate as any)
  const yAxis = useMemo(() => d3.axisLeft(y).tickFormat(d3.format("d")), [y]);

  // Grid axes
  const xGrid = useMemo(() => d3.axisBottom(x), [x]);
  const yGrid = useMemo(() => d3.axisLeft(y).tickFormat(d3.format("d")), [y]);

  const xAxisOverview = useMemo(() => d3.axisBottom(xOverview), [xOverview]);
  // const yAxisOverview = d3.axisLeft(yOverview).tickFormat(d3.format("d"));

  // MainChart handles
  const mouseover = useCallback(
    (event: any, d: any) => {
      const current_target_parentNode: any = d3.select(d3.event.currentTarget.parentNode).datum();
      if (current_target_parentNode) {
        const subgroupName = current_target_parentNode.key;
        const subgroupValue = event.data.subgroups[subgroupName];
        const subgroupDate = event.data.date;
        const subgroupColor = color && (hexcolor(color[subgroupName]) as string);
        // Show tooltip onhover
        d3.select("#tooltip")
          .html((d) => {
            return ReactDOMServer.renderToStaticMarkup(
              <>
                <div className={styles.tooltip_type}>
                  <span
                    className={styles.legend_span}
                    style={{ backgroundColor: subgroupColor ? subgroupColor : "black" }}
                  ></span>
                  <p style={{ margin: 0 }}>{subgroupName}</p>
                </div>
                <div className={styles.tooltip_datasets}>
                  Datasets: <strong>{subgroupValue}</strong>
                </div>
                <div className={styles.tooltip_date}>
                  Date: <strong>{subgroupDate.split("T").slice(0, 1)}</strong>
                </div>
              </>
            );
          })
          .style("opacity", 1);

        // Opacity of all elements
        // const sel = d3.select("#mainchart")
        // d3.selectAll(".subgroupElement").style("opacity", 0.2)
        // d3.selectAll(".subgroupElement." + d3Data.subgroupkeys[subgroupName]).style("opacity", 1)
        // d3.selectAll(".subgroupElement").style("opacity", .2)
        // d3.selectAll("." + d3Data.subgroupkeys[subgroupName]).style("opacity", 1.0)
        // Opacity of selected rect
        d3.select(d3.event.currentTarget).style("opacity", 1.0).style("stroke", "black").style("stroke-width", 2);
      }
    },
    [color]
  );

  const mousemove = useCallback((d: unknown, i: number, n: SVGRectElement[] | d3.ArrayLike<SVGRectElement>) => {
    // console.log("X, Y", d3.event.pageX, d3.event.pageY, d, i, n);
    d3.select("#tooltip")
      .style("left", d3.event.pageX + "px")
      .style("top", d3.event.pageY - 200 + "px");
    // .style("transform", "translateY(-5%)")
    // .style("left", d3.event.pageX / 2 + "px")
    // .style("top", d3.event.pageY / 4 + "px");
  }, []);
  const mouseleave = useCallback((event: any, d: any) => {
    d3.select(d3.event.currentTarget).style("stroke", "none").style("opacity", 1);
    // d3.selectAll(".subgroupElement").style("opacity", 1)
    d3.select("#tooltip").style("opacity", 0);
  }, []);

  // Legend handles
  const legendmouseover = useCallback(
    (event: any, d: any) => {
      const active_targets = Object.keys(d3Data.selection).filter((d) => d3Data.selection[d] === true);
      if (!(Array.isArray(active_targets) && active_targets.length)) {
        const current_target_parentNode: any = d3.select(d3.event.currentTarget.parentNode).datum();
        if (current_target_parentNode) {
          const subgroupName = current_target_parentNode;
          // Opacity of all elements
          d3.selectAll(".subgroupElement").style("opacity", 0.2);
          // Opacity of target
          d3.selectAll(".subgroupElement." + d3Data.subgroupkeys[subgroupName]).style("opacity", 1);
        }
      }
    },
    [d3Data.selection, d3Data.subgroupkeys]
  );

  const legendclicked = useCallback(
    (event: any, d: any) => {
      const current_target: any = d3.select(d3.event.currentTarget.parentNode).datum();
      if (current_target) {
        d3Data.selection[current_target] = !d3Data.selection[current_target];
        toggleSelection(current_target);
        const active_targets = Object.keys(d3Data.selection).filter((d) => d3Data.selection[d] === true);
        if (Array.isArray(active_targets) && active_targets.length) {
          d3Data.subgroups.forEach((d) => {
            if (active_targets.includes(d)) {
              // Change opacity
              d3.selectAll(".subgroupElement." + d3Data.subgroupkeys[d])
                .transition()
                .duration(300)
                .style("opacity", 1);
            } else {
              d3.selectAll(".subgroupElement." + d3Data.subgroupkeys[d])
                .transition()
                .duration(300)
                .style("opacity", 0.2);
            }
          });
        } else {
          d3.selectAll(".subgroupElement").transition().duration(300).style("opacity", 1);
        }
      }
    },
    [d3Data.selection, d3Data.subgroupkeys, d3Data.subgroups, toggleSelection]
  );

  const legendmouseleave = useCallback(
    (event: any, d: any) => {
      const active_targets = Object.keys(d3Data.selection).filter((d) => d3Data.selection[d] === true);
      if (!(Array.isArray(active_targets) && active_targets.length)) {
        // d3.select(event).style("stroke", "none").style("opacity", 1)
        d3.selectAll(".subgroupElement").style("opacity", 1);
      }
    },
    [d3Data.selection]
  );

  // HOOKS

  // Set the selection for the pie stats
  useEffect(() => {
    if (dataselection) {
      pieStatsCallback(dataselection);
    }
  }, [dataselection, pieStatsCallback]);

  // Switch color Hook
  useEffect(() => {
    if (d3Data.color) {
      setColor(() => d3Data.color);
    }
  }, [d3Data.color]);

  // Update the stack if selection changes
  useEffect(() => {
    const active_targets = Object.keys(selection).filter((d) => selection[d] === true);
    if (Array.isArray(active_targets) && active_targets.length) {
      setStackedData(() => stacked(d3Data.data, active_targets));
    } else {
      setStackedData(() => stacked(d3Data.data, d3Data.subgroups));
    }
  }, [d3Data.data, d3Data.subgroups, selection, stacked]);

  // Render the initial axes and default stack
  useLayoutEffect(() => {
    if (svgRef.current) {
      // Global cleanup
      d3.select(".svg-container").remove();

      const svg = d3
        .select(svgRef.current)
        .append("div")
        .classed("svg-container", true)
        .append("svg")
        // .attr("width", "100%")
        // .attr("height", "100%")
        .attr("preserveAspectRatio", "xMinYMin meet")
        .attr("viewBox", "0 0 " + width + " " + height)
        .classed("svg-content-responsive", true)
        .append("g");

      // Main group where things will be plotted
      svg
        .append("g")
        .attr("class", "main")
        // .attr("transform", "translate(" + margin.left + "," + 0 + ")")
        .attr("id", "mainchart");

      // Add a clip path
      svg
        .append("defs")
        .append("clipPath")
        .attr("id", "clip")
        .append("rect")
        .attr("transform", "translate(" + margin.left + "," + margin.top + ")")
        .attr("width", width_main)
        .attr("height", yMainRange[0]);

      // Add an overview
      svg
        .append("g")
        .attr("class", "overview")
        // .attr("transform", "translate(" + 0 + "," + margin_overview.top + ")")
        .attr("id", "overview");

      // Add a legend
      d3.select("." + styles.legend)
        // .append("svg")
        // .attr("viewBox", "0 0 90 100")
        // .attr("width", "100%")
        // .attr("height", "100%")
        // .append("g")
        .attr("id", "legend");

      // Add a Tooltip
      d3.select(svgRef.current)
        .append("div")
        .style("opacity", 0)
        .attr("class", styles.chart_tooltip)
        .style("z-index", "10")
        .attr("id", "tooltip");
    }
  }, [height, margin.left, margin.top, svgRef, width, width_main, yMainRange]);

  const showSelectedDates = useCallback(
    (startDate: string, endDate: string) => {
      if (!startDate || !endDate) {
        setValidationState(() => "error");
        setErrorMsg(() => "Start and/or end date not defined");
        return [null, null];
      } else {
        const start = new Date(startDate);
        const end = new Date(endDate);
        if (start < new Date(d3Data.data[0].date) || start > new Date(d3Data.data[d3Data.data.length - 1].date)) {
          setValidationState(() => "error");
          setErrorMsg(() => "Start and/or end date outside range");
          return [null, null];
        }
        if (end < new Date(d3Data.data[0].date) || end > new Date(d3Data.data[d3Data.data.length - 1].date)) {
          setValidationState(() => "error");
          setErrorMsg(() => "Start and/or end date outside range");
          return [null, null];
        }
        if (start <= end) {
          setValidationState(() => "success");
          // setShowCalendar(() => !showCalendar)
          return [start, end];
        } else {
          setValidationState(() => "warning");
          setErrorMsg(() => "Start date must be smaller or equal the end date");
          return [null, null];
        }
      }
    },
    [d3Data.data]
  );

  // Draw the axes now that we have data
  useEffect(() => {
    if (d3Data.data && stackedData && color) {
      setDataSelection(d3Data.data); // Set the selection to the whole dataset
      // Cleanup
      d3.select("#activity").select("svg").remove();
      d3.select("#legend").selectAll("*").remove();
      d3.select("#mainchart").selectAll("*").remove();
      d3.select("#overview").selectAll("*").remove();
      d3.selectAll(".subgroupElement").transition().duration(300);

      // Add Y-label
      d3.select("#mainchart")
        .append("g")
        .classed(styles.labels, true)
        .append("text") // Add some label to the y-axis
        // .attr("transform", "rotate(-90)")
        // .attr("transform", "translate(0," + (height_main / 2) + ")")
        .attr("transform", "translate(0," + height_main / 2 + ")rotate(-90)")
        .attr("dy", "0.75em")
        .attr("dx", "-6em")
        .style("text-anchor", "start")
        .text("Number of datasets");

      // Add legend for current data
      const legend = d3
        .select("#legend")
        .selectAll("div")
        .data(d3Data.subgroups)
        .enter()
        .append("div")
        .classed(styles.labels_legend, true)
        .attr("id", (d: any) => d3Data.subgroupkeys[d]);
      // .attr("transform", (d, i) => "translate(30," + i * 18 + ")")
      legend
        .append("span")
        .classed(styles.legend_span, true)
        .style("background-color", (d) => color[d]);
      // .attr("class", (d: any) => "subgroupElement " + d3Data.subgroupkeys[d]); // Add a class to each subgroup: their name
      // .classed("")
      legend
        .append("div")
        // .classed(styles.labels_legend, true)
        // .attr("class", (d: any) => "subgroupElement " + d3Data.subgroupkeys[d]) // Add a class to each subgroup: their name
        .html((d) => d)
        // .style("display", "inline")
        // .style("flex-flow", "row nowrap")
        // .style("padding", "5 0")
        .style("white-space", "nowrap");

      // .append("svg:rect")
      // .attr("x", -20)
      // // .attr("x", width_main + 35)
      // .attr("y", 0)
      // .attr("width", 16)
      // .attr("height", 16)
      // .style('fill', (d) => color(d) as string)
      // .attr("class", (d: any) => "subgroupElement " + d3Data.subgroupkeys[d]) // Add a class to each subgroup: their name
      // .style("fill",(d) => color(d))

      // const legend = d3.select("#legend")
      //     .selectAll('.legend')
      //     .data(d3Data.subgroups)
      //     .enter()
      //     .append('g')
      //     .classed(styles.labels_legend, true)
      //     .attr("transform", (d, i) => "translate(30," + i * 18 + ")")

      // legend.append('svg:rect')
      //     .attr("x", -20)
      //     // .attr("x", width_main + 35)
      //     .attr("y", 0)
      //     .attr("width", 16)
      //     .attr("height", 16)
      //     .style('fill', (d) => color(d) as string)
      //     .attr("class", (d: any) => "subgroupElement " + d3Data.subgroupkeys[d]) // Add a class to each subgroup: their name
      //     // .style('stroke', (d) => color(d) as string)
      legend
        .style("opacity", (d) => {
          const active_selection = Object.keys(d3Data.selection).filter((d) => d3Data.selection[d] === true);
          if (Array.isArray(active_selection) && active_selection.length) {
            if (active_selection.includes(d)) {
              return 1;
            } else {
              return 0.2;
            }
          } else {
            return 1;
          }
        })
        .style("cursor", "pointer");
      legend
        .selectAll("span, div")
        .on("mouseover", legendmouseover)
        .on("mouseleave", legendmouseleave)
        .on("click", legendclicked);
      // legend.append('text')
      //     .attr("x", 0)
      //     // .attr("x", width_main + 54)
      //     .attr("y", 8)
      //     .attr("dy", ".35em")
      //     .style("text-anchor", "start")
      //     .append('tspan')
      //     .text(function (d) { return d; })

      // Brush handle
      const brushed = (event: any) => {
        const extent = d3.event.selection; // d3 v4
        // const extent = event.selection // d3 v6
        if (!extent) {
          x.domain(xOverview.domain());
        } else {
          if (extent[0] < extent[1]) {
            // Update the x domain
            const range: [Date, Date] = [xOverview.invert(extent[0]), xOverview.invert(extent[1])];
            // Update the domain of the main chart
            x.domain(range);
            // Update the state of the brush area
            currentBrushCallback(range);
            // Update the y domain
            const this_data = d3Data.data.filter((d) => new Date(d.date) >= range[0] && new Date(d.date) <= range[1]);
            if (Array.isArray(this_data) && this_data.length) {
              const active_selection = Object.keys(selection).filter((d) => selection[d] === true);
              // Update the selection & data for pie Chart
              setDataSelection(() => this_data);
              // Update legend opacity
              if (!active_selection || (Array.isArray(active_selection) && active_selection.length === 0)) {
                const labels_with_data = new Set(
                  this_data
                    .map((k) => Object.entries(k.subgroups))
                    .flat(1)
                    .filter(([key, value]) => value > 0)
                    .map(([key, value]) => key)
                );
                // const labels_without_data = new Set(
                //   this_data
                //     .map((k) => Object.entries(k.subgroups))
                //     .flat(1)
                //     .filter(([key, value]) => value === 0)
                //     .map(([key, value]) => key)
                // );
                // Change opacity of all legend entries
                d3.select("#legend").selectAll("div").style("display", "none");
                // .selectAll(".subgroupElement")
                // .style("display", "none");
                // console.log("labels_with_data", labels_with_data);
                // console.log("labels_without_data", labels_without_data);

                // const _intersection = intersection(labels_with_data, labels_without_data);
                // console.log("intersection", _intersection);
                // Change opacity of legend entries with data in the current window
                labels_with_data &&
                  labels_with_data.forEach((d: string) => {
                    d3.select("#legend")
                      .selectAll(`#${d3Data.subgroupkeys[d]}`)
                      .style("display", "flex")
                      .selectAll("*")
                      .style("display", "inline-block");
                    // .selectAll(".subgroupElement." + d3Data.subgroupkeys[d])
                    // .style("display", "inline-block")
                    // .style("margin-top", 5)
                    // .style("margin-bottom", 5);
                  });
              }

              // Update Y-Axis
              const ymin = 0;
              // const ymax = d3.max(this_data.map((k) => Object.values(k.subgroups).reduce((acc, curr) => acc = acc + curr, 0)).flat(1)) as number
              const ymax = d3.max(
                this_data
                  .map((k) =>
                    Object.entries(k.subgroups)
                      .filter(([key, value]) =>
                        Array.isArray(active_selection) && active_selection.length
                          ? active_selection.includes(key)
                          : true
                      )
                      .map(([key, value]) => value)
                      .reduce((acc, curr) => (acc = acc + curr), 0)
                  )
                  .flat(1)
              ) as number;
              // const ymax = d3.max(stackedData.flat().map((d: number[]) => Math.max(...d))) as number
              // Update domains
              y.domain([ymin, ymax]);
              xBand.domain(this_data.map((d) => d.date));
              // update ticks
              xAxis.tickValues(x.ticks().filter((d) => +d.getUTCHours() === 0));
              yAxis.tickValues(y.ticks().filter((tick) => Number.isInteger(tick))).tickFormat(d3.format("d"));

              // Update the date state
              const ticks = xAxis.tickValues();
              if (Array.isArray(ticks) && ticks.length) {
                dateCallback(new Date(this_data[0].date), new Date(this_data[this_data.length - 1].date));
                // redraw the bars on the main chart
                d3.select("#mainchart")
                  .selectAll("." + styles.bars + " rect")
                  .transition()
                  .duration(300)
                  .attr("x", (d: any) => (x(new Date(d.data.date)) ?? 0) - xBand.bandwidth() / 2)
                  .attr("y", (d: any) => y(d[1]) ?? 0)
                  .attr("width", xBand.bandwidth())
                  .attr("height", (d: any) => (y(d[0]) ?? 0) - (y(d[1]) ?? 0));
                // redraw the x,y axis of the main chart
                d3.select("#mainchart")
                  .select("." + styles.xaxis)
                  .call(xAxis as any);
                d3.select("#mainchart")
                  .select("." + styles.yaxis)
                  .call(yAxis as any);

                d3.select("#mainchart")
                  .select("." + styles.xgrid)
                  .transition()
                  .duration(300)
                  .call(xGrid as any);
                d3.select("#mainchart")
                  .select("." + styles.ygrid)
                  .transition()
                  .duration(300)
                  .call(yGrid as any);

                d3.select("." + styles.xaxis)
                  .selectAll("text")
                  .style("text-anchor", "end")
                  .attr("dx", "-.8em")
                  .attr("dy", ".0em")
                  .attr("transform", "rotate(-65)");
              }
            }
          }
        }
      };

      // Map the total datasets over time for the overview
      const total_y = d3Data.data
        .map((k) => Object.values(k.subgroups).reduce((acc, curr) => (acc = acc + curr), 0))
        .flat(1);

      const xy_total = d3Data.data.map((e, i) => ({ date: e.date, total: total_y[i] }));

      // data ranges for the x and y axes
      // const dates = d3Data.data.map((d) => new Date(d.date))
      const dates: Date[] = d3Data.data.map((d) => {
        const isodate = d3.isoParse(d.date);
        if (isodate instanceof Date) {
          return isodate;
        } else {
          return new Date(d.date);
        }
      });
      const xmin = dates[0];
      const xmax = dates[dates.length - 1];
      const xminwoffset = new Date(new Date(xmin).setUTCHours(xmin.getUTCHours() - 12)); // Subtract half day offset
      const xmaxwoffset = new Date(new Date(xmax).setUTCHours(xmax.getUTCHours() + 12)); // Add half day offset
      const ymin = 0;
      const ymax = d3.max(total_y) as number;
      // Set the date state
      dateCallback(xmin, xmax);

      // Define brush
      const brush = d3
        .brushX()
        .extent([
          [x.range()[0], 0],
          [x.range()[1], height_overview],
        ])
        // .extent([[xmin, xmax], [width, heightOverview]])
        .on("end", brushed);

      // Set the domains
      x.domain([xminwoffset, xmaxwoffset]);
      xBand.domain(d3Data.data.map((d) => d.date));
      y.domain([ymin, ymax]);
      xOverview.domain([xminwoffset, xmaxwoffset]);
      yOverview.domain(y.domain());

      // draw the axes now that they are fully set up
      d3.select("#mainchart")
        .append("g")
        .classed(styles.xaxis, true)
        .attr("transform", `translate(0,${yMainRange[0]})`)
        .call(xAxis)
        .selectAll("text")
        .style("text-anchor", "end")
        .attr("dx", "-.8em")
        .attr("dy", ".15em")
        .attr("transform", "rotate(-65)");

      d3.select("#mainchart")
        .append("g")
        .classed(styles.yaxis, true)
        .attr("transform", `translate(${margin.left},0)`)
        .call(yAxis);

      d3.select("#overview")
        .append("g")
        .classed(styles.xaxis, true)
        .attr("transform", `translate(0,${yOverviewRange[0]})`)
        .call(xAxisOverview);
      // .call(yAxisOverview);

      // // Add X-label
      d3.select("#overview")
        .append("g")
        // .attr("class", "ylabel")
        .classed(styles.labels, true)
        .append("text") // Add some label to the y-axis
        .attr("transform", `translate(0,${yOverviewRange[0]})`)
        // .attr("transform", "rotate(-90)")
        .attr("dy", "3em")
        .attr("dx", (width_main + 30) / 2)
        .style("text-anchor", "start")
        .text("Acquisition date (UTC)");

      // d3.select("#overview")
      //   .append("g")
      //   // .attr("class", "ylabel")
      //   .style("text-anchor", "end")
      //   .style("font-size", "8px")
      //   .append("text") // Add some label to the y-axis
      //   .attr("transform", `translate(0,${yMainRange[0]})`)
      //   .attr("dy", "1.1em")
      //   .attr("dx", width_main + 30 - 10)
      //   .style("text-anchor", "start")
      //   .text("UTC");

      // d3.select("#overview")
      //     .append("g")
      //     .attr("class", "y axis")
      //     .attr("transform", `translate(${margin.left},0)`)
      //     .call(yAxisOverview);

      // const area = d3.area()
      //     .x((d: any) => x(new Date(d.data.date)) - (width / d3Data.data.length) / 2)
      //     // .y0(height)
      //     // .y1((d) => (d[1] - d[0]) > 0 ? y(d[1] - d[0]) : y(0))
      //     .y0((d) => y(d[0]))
      //     .y1((d) => y(d[1]))
      //     .curve(d3.curveCardinal.tension(0));

      // const line = d3.line()
      //     .x((d: any) => x(new Date(d.data.date)) - (width / d3Data.data.length) / 2)
      //     .y((d) => (d[1] - d[0]) > 0 ? y(d[1] - d[0]) : y(0))
      //     .curve(d3.curveCardinal.tension(0));

      // add the gridlines
      d3.select("#mainchart")
        .insert("g", "#xgrid")
        .classed(styles.xgrid, true)
        .attr("transform", `translate(0,${yMainRange[0]})`)
        .call(xGrid.tickSize(-height_main).tickFormat("" as any));

      d3.select("#mainchart")
        .insert("g", "#ygrid")
        .classed(styles.ygrid, true)
        .attr("transform", `translate(${margin.left},0)`)
        .call(yGrid.tickSize(-width_main).tickFormat("" as any));

      const bars = d3
        .select("#mainchart")
        .append("g")
        // .attr("class", "Bar")
        .classed(styles.bars, true)
        .selectAll("bar.stack")
        .data(stackedData)
        .join("g")
        // .classed(styles.rect, true)
        .attr("fill", (d) => color[d.key])
        .attr("class", (d: any) => "subgroupElement " + d3Data.subgroupkeys[d.key]); // Add a class to each subgroup: their name
      // bars
      //     .selectAll("rect")
      //     .data((d: any) => d)
      //     .join('rect')
      //     // .attr("width", width / (d3Data.data.length - 1))
      //     // .attr("x", (d: any) => x(new Date(d.data.date)) - (width / d3Data.data.length) / 2)
      //     .attr('x', (d: any) => x(new Date(d.data.date)) - xBand.bandwidth() / 2)
      //     .attr('y', (d: any) => y(d[1]))
      //     .attr('height', (d: any) => y(d[0]) - y(d[1]))
      //     .attr("width", xBand.bandwidth())
      //     .attr("clip-path", "url(#clip)")
      //     .on("mouseover", mouseover)
      //     .on("mousemove", mousemove)
      //     .on("mouseleave", mouseleave)
      //     .exit()
      //     .remove()

      // Pie slices
      bars
        .selectAll("rect")
        .data((d: any) => d)
        .join(
          (enter) =>
            enter
              .append("rect")
              .attr("x", (d: any) => (x(new Date(d.data.date)) ?? 0) - xBand.bandwidth() / 2)
              .attr("y", (d: any) => y(d[1]) ?? 0)
              .attr("height", (d: any) => (y(d[0]) ?? 0) - (y(d[1]) ?? 0))
              .attr("width", xBand.bandwidth())
              .attr("clip-path", "url(#clip)")
              .on("mouseover", mouseover)
              .on("mousemove", mousemove)
              .on("mouseleave", mouseleave)
              .call((g: any) => g.transition().duration(300)),
          (update) => update.call((g: any) => g.transition().duration(300)),
          (exit) => exit.call((g: any) => g.transition().duration(300).remove())
        );

      // // Plot
      // d3.select("#mainchart")
      //     .append("g")
      //     .attr("class", "Area")
      //     .selectAll("path")
      //     .data(stackedData)
      //     .join("path")
      //     .attr("class", (d: any) => "subgroupArea " + d3Data.subgroupkeys[d.key])
      //     .attr("d", area as any)
      //     .attr('fill', (d) => d3Data.color(d.key) as string)
      //     .attr("clip-path", "url(#clip)")
      //     .attr("opacity", 1)

      // // Plot
      // d3.select("#mainchart")
      //     .append("g")
      //     .attr("class", "Line")
      //     .selectAll("path")
      //     .data(stackedData)
      //     .join("path")
      //     .attr("class", (d: any) => "subgroupLine " + d3Data.subgroupkeys[d.key])
      //     .attr("d", line as any)
      //     .style('stroke', (d: any) => d3Data.color(d.key) as string)
      //     .attr("fill", "none")
      //     .attr("clip-path", "url(#clip)")
      //     .attr("opacity", 1)

      d3.select("#mainchart").transition().duration(300);
      d3.select("#mainchart").exit().remove();

      d3.select("#overview")
        .append("g")
        .classed(styles.overviewbar, true)
        // .attr("class", "overviewbars")
        .selectAll(".overviewbar")
        .data(xy_total)
        .enter()
        .append("rect")
        .attr("class", "overviewbar")
        .attr("x", (d) => xOverview(new Date(d.date)) as any)
        .attr("width", width / xy_total.length)
        .attr("y", (d) => yOverview(d.total) as any)
        .attr("height", (d) => {
          return d.total ? ((yOverviewRange[0] - yOverview(d.total)!) as number) : 0;
        });

      // add the brush target area on the overview chart
      const d3brush = d3
        .select("#overview")
        .append("g")
        .attr("class", "brush")
        .attr("transform", "translate(" + 0 + "," + yOverviewRange[1] + ")")
        .call(brush);

      // Event listener to untoggle selection on double click
      // d3.select(ref.current)
      d3.select("." + styles.graph).on("dblclick", (d) => {
        Object.keys(d3Data.selection).forEach((d) => (d3Data.selection[d] = false));
        toggleSelection(null, d3Data.selection);
        d3.selectAll(".subgroupElement").transition().duration(300).style("opacity", 1);
      });

      // Event listeners to zoom
      d3.select("#eventListener1").on("click", () => {
        setActiveBtn(() => "year");
        dates && d3brush.call(brush.move, xOverview.range());
      });
      d3.select("#eventListener2").on("click", () => {
        setActiveBtn(() => "month");
        const start = new Date(dates[dates.length - 30]);
        const end = new Date(dates[dates.length - 1]);
        dates &&
          d3brush.call(brush.move, [
            xOverview(start.setHours(start.getHours() - 12)),
            xOverview(new Date(end).setHours(end.getHours() + 12)),
          ]);
      });
      d3.select("#eventListener3").on("click", () => {
        setActiveBtn(() => "week");
        const _date = new Date(dates[dates.length - 7]);
        const end = new Date(dates[dates.length - 1]);
        dates &&
          d3brush.call(brush.move, [
            xOverview(_date.setHours(_date.getHours() - 12)),
            xOverview(new Date(end).setHours(end.getHours() + 12)),
          ]);
      });

      d3.select("#startControl").on("change", () => {
        const val = d3.select("#startControl").property("value");
        if (val && typeof val === "string") {
          startDate.current = val;
        } else {
          startDate.current = "";
        }
      });

      d3.select("#endControl").on("change", () => {
        const val = d3.select("#endControl").property("value");
        if (val && typeof val === "string") {
          endDate.current = val;
        } else {
          endDate.current = "";
        }
      });

      d3.select("#eventListener5").on("click", () => {
        const [start, end] = showSelectedDates(startDate.current, endDate.current);
        if (start && end)
          dates &&
            d3brush.call(brush.move, [
              xOverview(start.setHours(start.getHours() - 12)),
              xOverview(new Date(end).setHours(end.getHours() + 12)),
            ]);
      });
      // Set current brushing area
      if (currentBrush) {
        d3brush.call(brush.move, [xOverview(currentBrush[0]), xOverview(currentBrush[1])]);
      } else {
        d3.select("#eventListener2").dispatch("click");
      }
    }
    // TODO: SK: might be something wrong with the dependency list here
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stackedData]);

  const activeColorButton = useCallback(
    (id: number) => {
      if (id === activeColor) return true;
      else return false;
    },
    [activeColor]
  );

  // Handle to adjust stats table height
  // const getHeight = useCallback(() => {
  //   if (svgRef.current) {
  //     const curret_height = document.getElementsByClassName("svg-content-responsive")[0].clientHeight;
  //     return curret_height;
  //   }
  // }, []);

  // Custom Hook to add window resize event
  // const useCurrentHeight = () => {
  //   let [height, setHeight] = useState(getHeight());
  //   useEffect(() => {
  //     let timeoutId: any = null;
  //     const resizeListener = () => {
  //       clearTimeout(timeoutId);
  //       timeoutId = setTimeout(() => setHeight(getHeight()), 150);
  //     };
  //     window.addEventListener("resize", resizeListener);
  //     // clean up
  //     return () => {
  //       window.removeEventListener("resize", resizeListener);
  //     };
  //   }, []);
  //   return height;
  // };
  // let dynheight = useCurrentHeight();

  return (
    <>
      <div className={styles.graph}>
        <div className={styles.buttons_wrapper}>
          <div className={styles.buttons}>
            <ButtonToolbar className={styles.selection_buttons}>
              <Button
                bsStyle="primary"
                className="btn btn-soft-primary"
                bsSize="small"
                id="eventListener1"
                active={activeBtn === "year"}
              >
                Last 12 months
              </Button>
              <Button
                bsStyle="primary"
                className="btn btn-soft-primary"
                bsSize="small"
                id="eventListener2"
                active={activeBtn === "month"}
              >
                Last 30 days
              </Button>
              <Button
                bsStyle="primary"
                className="btn btn-soft-primary"
                bsSize="small"
                id="eventListener3"
                active={activeBtn === "week"}
              >
                Last 7 days
              </Button>
              <Button
                bsStyle="primary"
                className="btn btn-soft-primary"
                onClick={() => setShowCalendar(() => !showCalendar)}
                aria-expanded={showCalendar}
                active={showCalendar}
              >
                <LucideIcon name="calendar" />
              </Button>
              {color && Object.values(color).length > 1 && (
                <DropdownButton
                  bsStyle="primary"
                  className="btn btn-soft-primary"
                  // pullRight
                  // bsSize="small"
                  // dropup
                  title={<LucideIcon name="droplet" />}
                  id="eventListener4"
                  onSelect={(k) => colorCallback(k)}
                >
                  <MenuItem eventKey={1} active={activeColorButton(1)}>
                    Spectral
                  </MenuItem>
                  <MenuItem eventKey={2} active={activeColorButton(2)}>
                    Plasma
                  </MenuItem>
                  <MenuItem eventKey={3} active={activeColorButton(3)}>
                    Sinebow
                  </MenuItem>
                  <MenuItem eventKey={4} active={activeColorButton(4)}>
                    Viridis
                  </MenuItem>
                  <MenuItem eventKey={5} active={activeColorButton(5)}>
                    Cool
                  </MenuItem>
                  <MenuItem eventKey={6} active={activeColorButton(6)}>
                    Warm
                  </MenuItem>
                  <MenuItem eventKey={7} active={activeColorButton(7)}>
                    Magma
                  </MenuItem>
                  <MenuItem eventKey={8} active={activeColorButton(8)}>
                    Greyscale
                  </MenuItem>
                </DropdownButton>
              )}
              {Object.values(d3Data.selection).filter((d) => d === true).length > 0 && (
                <Button
                  bsStyle="default"
                  onClick={() => {
                    Object.keys(d3Data.selection).forEach((d) => (d3Data.selection[d] = false));
                    toggleSelection(null, d3Data.selection);
                  }}
                >
                  Unselect all
                </Button>
              )}
            </ButtonToolbar>
            <Collapse in={showCalendar}>
              <div>
                <FormGroup validationState={validationState} className={styles.calendar_buttons}>
                  <InputGroup>
                    <FormControl
                      type="date"
                      id="startControl"
                      min={formatDate(new Date(d3Data.data[0].date))}
                      max={formatDate(new Date(d3Data.data[d3Data.data.length - 1].date))}
                    />
                    <InputGroup.Addon>to</InputGroup.Addon>
                    <FormControl
                      type="date"
                      id="endControl"
                      min={formatDate(new Date(d3Data.data[0].date))}
                      max={formatDate(new Date(d3Data.data[d3Data.data.length - 1].date))}
                    />
                    <FormControl.Feedback />
                  </InputGroup>
                  <Button bsStyle="primary" bsSize="small" id="eventListener5">
                    Show
                  </Button>
                </FormGroup>
                {showError && <HelpBlock>{errorMsg}</HelpBlock>}
              </div>
            </Collapse>
          </div>
        </div>
        <div className={styles.svg_wrapper} ref={svgRef} />
        <div className={styles.legend_wrapper} style={{ height: svgHeight }}>
          {/* <span>Legend</span> */}
          <div
            className={styles.legend}
            // style={{
            //   height: dynheight ? dynheight : document.getElementsByClassName("svg-content-responsive")[0].clientHeight,
            // }}
          />
        </div>
      </div>
    </>
  );
};
