import { useEffect, useState } from "react";

import { Parameter, ParameterElementStr } from "../../ViewerLayout/ViewerLayoutTypes";
import { TreeKeyValueLeaf } from "./TreeKeyValueLeaf";
import { TreeListNode } from "./TreeListNode";
import { TreeMultilineLeaf } from "./TreeMultilineLeaf";
import { TreeTableLeaf } from "./TreeTableLeaf";
import { SearchRequestType } from "./TreeViewerTypes";

type countType = { element: number; list: number; paramter: number; table: number; string: number };

const addToCount = (a: countType, b: countType) => {
  Object.keys(a).forEach((k) => {
    if (k in b) a[k as keyof countType] += b[k as keyof countType];
  });
};

export const countParameters = (parameter: any): countType => {
  const count: countType = { element: 0, list: 0, paramter: 0, table: 0, string: 0 };

  if (typeof parameter === "object") {
    if (Array.isArray(parameter)) {
      count.element += parameter.length;
      parameter.forEach((p) => addToCount(count, countParameters(p)));
    } else {
      switch (parameter.type) {
        case "list":
          count.list += 1;
          parameter.content.forEach((p: any) => addToCount(count, countParameters(p)));
          break;
        case "parameter":
          count.paramter += 1;
          break;
        case "table":
          count.table += 1;
          break;
        default:
          console.log('Unknown element "' + parameter.type + '"');
      }
    }
  } else if (typeof parameter === "string") {
    count.string += 1;
  }

  return count;
};

export const TreeElement = (
  // const { children, ...otherProps }: { children: Parameter; otherProps: any } = props;
  { children, search }: { children: Parameter; search: SearchRequestType }
) => {
  const [root, setRoot] = useState<any>();
  // const id = props?.id ?? 0;

  // console.log(
  //   "child",
  //   props.children.map((c: any) => "tmp")
  // );
  // console.log("SEARCH", props.search);
  // console.log("CHILDREN", typeof children);

  useEffect(() => {
    if (!children) {
      // console.log("TreeElement no children");
      setRoot(null);
      return;
    }
    if (typeof children === "object") {
      if (Array.isArray(children)) {
        // const child = { type: "list", name: undefined, content: children, border: false };

        // root = <TreeElement {...otherProps}>{child}</TreeElement>;
        setRoot(
          children
            // .filter((c: any, i: number) => i < 20)
            .map((child: any, i: number): any => (
              <TreeElement key={i} search={search}>
                {child}
              </TreeElement>
            ))
        );
      } else {
        switch (children.type) {
          case "list":
            // console.log("translateList", otherProps.searching);
            setRoot(children.isVisible ? <TreeListNode search={search}>{children}</TreeListNode> : null);
            // root = <TreeListNode >{children}</TreeListNode>;
            break;
          case "parameter":
            if (children.vtype === "str" && (children as ParameterElementStr)?.multiline)
              setRoot(children.isVisible ? <TreeMultilineLeaf search={search}>{children}</TreeMultilineLeaf> : null);
            else setRoot(children.isVisible ? <TreeKeyValueLeaf>{children}</TreeKeyValueLeaf> : null);
            // root = <TreeKeyValueLeaf >{children}</TreeKeyValueLeaf>;
            break;
          case "table":
            // console.log("translateTable", otherProps.searching);
            setRoot(children.isVisible ? <TreeTableLeaf search={search}>{children}</TreeTableLeaf> : null);
            // root = <TreeTableLeaf >{children}</TreeTableLeaf>;
            break;
          default:
            console.log('Unknown element "' + children + '"');
        }
      }
    } else if (typeof children === "string") {
      // root = new TreeLeaf(data, undefined);
    }
    // Ignore is added because we don't want this effect to react to otherProps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [children]);

  // let root: any = <div></div>;

  // if (!children) return root;

  // if (typeof children === "object") {
  //   if (Array.isArray(children)) {
  //     // const child = { type: "list", name: undefined, content: children, border: false };

  //     // root = <TreeElement {...otherProps}>{child}</TreeElement>;
  //     console.log("children", children.length);

  //     root = children.map((child: any, i: number): any => (
  //       <TreeElement key={i} {...otherProps}>
  //         {child}
  //       </TreeElement>
  //     ));
  //   } else {
  //     switch (children.type) {
  //       case "list":
  //         // console.log("translateList", children);
  //         // root = <TreeListNode {...otherProps}>{children}</TreeListNode>;
  //         break;
  //       case "parameter":
  //         // console.log("translateParameter", children);
  //         // root = <TreeKeyValueLeaf {...otherProps}>{children}</TreeKeyValueLeaf>;
  //         break;
  //       case "table":
  //         // console.log("translateParameter", children);
  //         // root = <TreeTableLeaf {...otherProps}>{children}</TreeTableLeaf>;
  //         break;
  //       default:
  //         console.log('Unknown element "' + children.type + '"');
  //     }
  //   }
  // } else if (typeof children === "string") {
  //   // root = new TreeLeaf(data, undefined);
  // }

  // console.log(">", children.name, visible);
  return (
    <>
      {root && (
        <div style={{ display: "flex", flexFlow: "column nowrap", gap: "5px", padding: 0, margin: 0 }}>{root}</div>
      )}
    </>
  );

  // return <div style={{ display: visible ? undefined : "none" }}>{root}</div>;
};
