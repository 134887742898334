import React, { useCallback, useContext, useState } from "react";
import { attachmentsConstants } from "../api/Attachments";
import { IEntityMinimalModel } from "../api/GenericTypes";
import { LucideIcon } from "../common/icon/LucideIcon";
import { FilesCore, TreeElementBase } from "../Dataset/tiles/Files/Files";
import { Dataset, FilesStructure } from "../api/Datasets";
import { DownloadDatasetMulti } from "../Dataset/common/DownloadDataset/DownloadDataset";
import { SessionContext } from "../common/contexts/SessionContext";
import { MinimalViewer } from "../ViewerLayout/MinimalViewer";
import { GenericModal, GenericModalWrapper } from "../common/modals/Modal/GenericModal";
import { getDetailLink } from "../main/Routing";
import { APIFilePreview } from "../Dataset/common/Tree/Folder/helpers/FilePreview";
import { useFileStructure } from "../Dataset/common/Tree/Folder/helpers/FileHelpers";
import { LoadingWrapper } from "../common/LoadingWrapper";
import { NotAvailable } from "../common/misc/UIconstants";
import { ConditionalLink } from "../common/misc/ConditionalLink/ConditionalLink";

interface AttachmentMinimalViewProps {
  attachment: IEntityMinimalModel;
  showFiles?: boolean;
}
export const AttachmentMinimalView = ({ attachment, showFiles }: AttachmentMinimalViewProps) => {
  const [unfoldFiles, setUnfoldFiles] = useState(false);
  const { api, route } = useContext(SessionContext);
  const [showModal, setShowModal] = useState<string>();
  const onModalCloseCallback = useCallback(() => {
    setShowModal(undefined);
  }, []);
  const fileControls = useCallback(
    (item: TreeElementBase) =>
      showFiles ? (
        <APIFilePreview
          datasetId={attachment.id}
          item={item}
          defaultShowModal={showModal === item.id}
          onModalCloseCallback={onModalCloseCallback}
        />
      ) : (
        <></>
      ),
    [attachment.id, onModalCloseCallback, showFiles, showModal]
  );
  // const onFileClick = useCallback((item: TreeElementBase) => {
  //   setShowModal(item.id);
  // }, []);

  return (
    <GenericModalWrapper>
      {({ showModal, setShowModal }) => (
        <div className="flex col-nowrap" style={{ width: "100%", height: "fit-content", overflow: "hidden" }}>
          <div className="flex row-nowrap align-center gap-5" style={{ width: "100%" }}>
            {showFiles && (
              <button className="btn btn-xs btn-ghost-secondary" onClick={() => setUnfoldFiles((prev) => !prev)}>
                {unfoldFiles ? <LucideIcon name="chevron-down" /> : <LucideIcon name="chevron-right" />}
              </button>
            )}
            <ConditionalLink
              to={route(getDetailLink(attachmentsConstants.frontendIndexRoute, attachment.id))}
              className="ellipsisContainer"
              showLink={showFiles}
            >
              <span>
                <LucideIcon name={attachmentsConstants.icon} color="var(--primary)" /> {attachment.name || NotAvailable}
              </span>
            </ConditionalLink>
            {showFiles && (
              <>
                <ViewableEntityTableRenderer value={attachment} style={{ width: "min-content" }} />
                <div className="flex row-nowrap align-center" style={{ marginLeft: "auto" }}>
                  <button className="btn btn-ghost-primary btn-xs" onClick={() => setShowModal(true)}>
                    <LucideIcon name="eye" />
                  </button>
                  <DownloadDatasetMulti
                    btnStyle="btn btn-ghost-primary btn-xs"
                    api={api}
                    filters={{ ids: [attachment.id], includeViewableEntities: true }}
                    title={`Download ${attachment.name}`}
                    label={""}
                  />
                </div>
              </>
            )}
          </div>

          <GenericModal
            showModal={showModal}
            setShowModal={setShowModal}
            modalTitle={attachment.name}
            modalBody={
              <MinimalViewer
                id={attachment.id}
                settings={{
                  interactiveMode: false,
                  showParameter: true,
                  showNavigation: false,
                  showTrackList: true,
                }}
                width={"calc(70vw - 10px)"}
                height={"70vh"}
                disableOnClick
              />
            }
          />

          <div style={{ borderLeft: "1px solid var(--secondary-light)", marginLeft: 12 }}>
            {showFiles && unfoldFiles && <FilesCore dataset={attachment as Dataset} fileControls={fileControls} />}
          </div>
        </div>
      )}
    </GenericModalWrapper>
  );
};

// interface AttachmentMinimalViewControlProps<T extends TreeElementBase> {
//   id: Dataset["id"];
//   item: TreeElement<T>;
// }
// export const AttachmentMinimalViewControls = <T extends TreeElementBase>({
//   id,
//   item,
// }: AttachmentMinimalViewControlProps<T>) => {
//   const { triggerDownload, isPreparingDownload } = useSingleFileDownload();
//   return (
//     <Button
//       className="btn btn-round btn-xs btn-ghost-primary"
//       onClick={() => {
//         item.path && triggerDownload(id, item.path);
//       }}
//       disabled={isPreparingDownload}
//       title={"Download file"}
//     >
//       {isPreparingDownload ? <LucideIcon name="loader" /> : <LucideIcon name="download" />}
//     </Button>
//   );
// };
function countFiles(filesStructure: FilesStructure): number {
  let count = 0;

  function traverse(structure: FilesStructure) {
    for (const key in structure) {
      if (structure[key].isDirectory) {
        if (structure[key].contents) {
          traverse(structure[key].contents!);
        }
      } else {
        count++;
      }
    }
  }

  traverse(filesStructure);
  return count;
}

export const ViewableEntityTableRenderer = ({
  value,
  style,
}: {
  value: IEntityMinimalModel<number> | null | undefined;
  style?: React.CSSProperties;
}) => {
  const { filesStructure, filesStructureStatus, fetchStatus, error } = useFileStructure(value?.id);
  const nFiles = countFiles(filesStructure ?? {});
  return (
    <div className="flex row-nowrap align-center justify-center" style={{ width: "100%", ...style }}>
      <LoadingWrapper status={filesStructureStatus} fetchStatus={fetchStatus} error={error}>
        <span className="flex row-nowrap align-center gap-5 badge">
          <LucideIcon name={attachmentsConstants.icon} /> {nFiles} {`File${nFiles > 1 ? "s" : ""}`}
        </span>
      </LoadingWrapper>
    </div>
  );
};
