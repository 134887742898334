import { useParams } from "react-router-dom";
import { EntityDetailProps, SingleEntityRoutingParams } from "./EntityInterfaces";
import { useEntityDetail } from "../../api/BaseEntityApi";
import { LoadingWrapper } from "../LoadingWrapper";
import { useEntityApi } from "../../api/useEntityApi";
import {
  EntityByEntityTypeId,
  FiltersByEntityTypeId,
  GenericEntityConstant,
  GenericEntityConstantsEntities,
  getEntityConstants,
  InferEntity,
  InferFilters,
} from "../../api/GenericConstants";
import { useEntityPermissions } from "../permissions/useEntityPermissions";
import { useEntityRoutes } from "../routes/useEntityRoutes";
import { PageNotAllowed } from "../../main/common/PageNotAllowed/PageNotAllowed";

interface EntityDetailViewProps<EntityTypeId extends GenericEntityConstantsEntities> {
  id: InferEntity<GenericEntityConstant<EntityTypeId>["entityConstants"]>["id"];
  filters?: InferFilters<GenericEntityConstant<EntityTypeId>["entityConstants"]>;
  entityTypeId: EntityTypeId;
  children: (props: EntityDetailProps<EntityTypeId>) => React.ReactNode;
}

export const EntityDetailView = <EntityTypeId extends GenericEntityConstantsEntities>({
  id,
  filters,
  entityTypeId,
  children,
}: EntityDetailViewProps<EntityTypeId>) => {
  const { entityConstants, fieldLabels } = getEntityConstants(entityTypeId);
  type EntityType = EntityByEntityTypeId<EntityTypeId>;
  type FilterType = FiltersByEntityTypeId<EntityTypeId>;
  const permissions = useEntityPermissions({ entityTypeId });
  const { canRead } = permissions;
  const entityApi = useEntityApi<EntityType>(entityConstants.resource);
  const routes = useEntityRoutes({ entityConstants });

  const {
    data: entity,
    error,
    status,
    fetchStatus,
    isFetching,
  } = useEntityDetail<EntityType, FilterType>(
    entityConstants.resource,
    id,
    {
      includeRelations: true,
      ...filters,
    } as FilterType,
    { keepPreviousData: false }
  );

  if (entity && !canRead(entity)) return <PageNotAllowed />;
  return (
    <LoadingWrapper status={status} fetchStatus={isFetching ? "fetching" : fetchStatus} error={error}>
      {entity &&
        children({
          routes,
          permissions,
          entity,
          entityApi,
          entityConstants,
          fieldLabels,
        })}
    </LoadingWrapper>
  );
};

interface EntityDetailPageProps<EntityTypeId extends GenericEntityConstantsEntities> {
  isIntId?: boolean;
  children: (id: EntityByEntityTypeId<EntityTypeId>["id"]) => React.ReactNode;
}

export const EntityDetailPage = <EntityTypeId extends GenericEntityConstantsEntities>({
  isIntId = true,
  children,
}: EntityDetailPageProps<EntityTypeId>) => {
  const { id } = useParams<SingleEntityRoutingParams>();
  let _id = isIntId ? parseInt(id) : id;

  return <>{children(_id as EntityByEntityTypeId<EntityTypeId>["id"])}</>;
};
