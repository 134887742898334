import React, { CSSProperties } from "react";
import styles from "./ToggleSwitch.module.css";
import { v4 as uuidv4 } from "uuid";
import { DeepMap, FieldError, UseFormRegister } from "react-hook-form";
import { LucideIcon } from "../../icon/LucideIcon";
export interface ToggleSwitchProps {
  id?: string;
  name?: string;
  errors?: DeepMap<any, FieldError>;
  register?: UseFormRegister<any>;
  autoFocus?: boolean;
  readOnly?: boolean;
  required?: boolean;
  checked?: boolean | null;
  onToggle?: () => void;
  onBlur?: () => void;
  disabled?: boolean;
  intermediate?: boolean;
  defaultValue?: boolean;
  checkboxWrapperStyle?: CSSProperties;
  toggleStyle?: CSSProperties;
  style?: CSSProperties;
}
export const ToggleSwitch = ({
  id,
  name,
  errors,
  register,
  autoFocus,
  readOnly,
  required,
  checked,
  onToggle,
  onBlur,
  disabled = false,
  intermediate,
  defaultValue,
  checkboxWrapperStyle,
  toggleStyle,
  style,
}: ToggleSwitchProps) => {
  const uniqueID = uuidv4();
  let error: any = undefined;
  if (id) {
    const fieldPath = id.split(".");
    for (const path of fieldPath) {
      if (!error) error = errors?.[path];
      else error = error?.[path];
    }
  }
  const _intermediate = intermediate || checked === null;

  return (
    <div
      className={` ${styles.checkbox} ${_intermediate ? styles.intermediate : ""}  ${readOnly ? styles.readOnly : ""} ${
        disabled ? styles.nopointer : styles.pointer
      } ${disabled && "disabled"}`}
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        if (!disabled) {
          onToggle?.();
          onBlur?.();
        }
      }}
      style={checkboxWrapperStyle}
    >
      <label htmlFor={id ?? uniqueID} style={{ margin: 0 }}>
        <input
          id={id ?? uniqueID}
          name={name}
          className={`${error ? "border-danger form-control" : "form-control"} ${required ? "required" : ""}`}
          type="checkbox"
          autoFocus={autoFocus}
          readOnly={readOnly}
          {...(id && register && { ...register(id) })}
          onChange={onToggle}
          checked={checked ?? false}
          style={{ ...style, ...(readOnly && { cursor: "default" }) }}
        />
        <span style={{ width: "40px", height: "20px", ...toggleStyle }}>
          <small />
          <div style={{ width: "14px", height: "14px" }}>
            {_intermediate ? (
              <LucideIcon name="move-horizontal" style={{ strokeWidth: 4, color: "var(--gray-300)" }} />
            ) : checked ? (
              <LucideIcon name="check" style={{ strokeWidth: 4, color: "var(--white)" }} />
            ) : (
              <LucideIcon name="x" style={{ strokeWidth: 4, color: "var(--white)" }} />
            )}
          </div>
        </span>
      </label>
    </div>
  );
};

export const ToggleRenderer = ({ value }: { value: boolean }) => {
  return (
    <span className="badge">
      {!!value ? (
        <LucideIcon name="check" style={{ strokeWidth: 4, color: "var(--success)" }} />
      ) : (
        <LucideIcon name="x" style={{ strokeWidth: 4, color: "var(--danger)" }} />
      )}
    </span>
  );
};
