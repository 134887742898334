import { useContext, useMemo, useState } from "react";
import { Dataset, datasetsConstants } from "../../../api/Datasets";
import { DateTimeRenderer } from "../../../common/datetime/DateTimeFormatter";
import { Skeleton } from "../../../common/loaders/Skeleton/Skeleton";
import { Table } from "../../../common/panels/Detail/DetailTable";
import { Tile } from "../../../common/panels/Tile/Tile";
import { Content, ContentTable } from "../../../common/tables/ContentTable/ContentTable";
import { LinkEntity } from "../../../common/misc/LinkEntity/LinkEntity";
import { DatasetContext } from "../../Dataset";
import styles from "./Details.module.css";
// import { DownloadDataset } from "../../common/DownloadDataset/DownloadDataset";
import { NotSet } from "../../../common/misc/UIconstants";
import { experimentsConstants } from "../../../api/Experiments";
import { instrumentsConstants } from "../../../api/Facilities";
import { methodsConstants } from "../../../api/Methods";
import {
  GetEquipments,
  GetProjects,
  GetOrganizations,
  GetPersons,
} from "../../../common/misc/EntityRenders/EntityRenderer";
import {
  CustomTypeRenderer,
  CustomTypeMinimalRenderer,
} from "../../../Customization/CustomTypes/generics/CustomTypeRenderer";
import { customTypeConstants } from "../../../api/CustomTypes";

// import { Button } from "../../../common/buttons/Button/Button";
// import { Experiment } from "../LinkedEntities/Experiment";
interface Props {
  dataset: Dataset;
  tile?: boolean;
}
export const Details = ({ dataset, tile = true }: Props) => {
  const context = useContext(DatasetContext);
  const [mapping, setMapping] = useState<Content>([]);
  const [loading, setLoading] = useState(true);

  // Set the mapping for the ContentTable items
  useMemo(() => {
    let mapping: Content = [];
    if (dataset) {
      mapping.push(
        {
          key: "Name",
          value: (
            <div className={"container_label"}>
              <div className={"container_label_name"} style={{ fontWeight: 600 }}>
                {dataset.name ?? NotSet}
              </div>
              <div className={"container_label_id"}>
                <span style={{ whiteSpace: "nowrap" }}>Dataset-ID: {dataset.id}</span>
              </div>
            </div>
          ),
        },
        {
          key: "Dataset Type",
          value: (
            <div className={"container_label"}>
              {dataset.customType ? (
                context?.viewShared ? (
                  <div className={"container_label_name"} style={{ fontWeight: 500 }}>
                    {dataset.customType.name}
                  </div>
                ) : (
                  <LinkEntity entityConstants={customTypeConstants} property={dataset.customType} />
                )
              ) : (
                <div className={"container_label_name"}>
                  <span style={{ color: "var(--gray-400)", fontWeight: 500 }}>
                    Basic {datasetsConstants.entitySingular}
                  </span>
                </div>
              )}
            </div>
          ),
        },
        {
          key: "Method",
          value: context?.viewShared ? (
            <>
              {dataset.method?.name ? (
                <label className="label label-primary" style={{ margin: 0 }}>
                  {dataset.method.name}
                </label>
              ) : (
                NotSet
              )}
            </>
          ) : (
            <>
              {dataset.method?.id ? (
                <LinkEntity entityConstants={methodsConstants} property={dataset.method} />
              ) : (
                NotSet
              )}
            </>
          ),
        },
        {
          key: "Experiment",
          value: context?.viewShared ? (
            <>
              {dataset.experiment?.name ? (
                <label className="label label-primary" style={{ margin: 0 }}>
                  {dataset.experiment.name}
                </label>
              ) : (
                NotSet
              )}
            </>
          ) : (
            <>
              {dataset.experiment?.id ? (
                <LinkEntity entityConstants={experimentsConstants} property={dataset.experiment} />
              ) : (
                NotSet
              )}
            </>
          ),
        },
        {
          key: "Instrument",
          value: context?.viewShared ? (
            <>
              {dataset.instrument?.name ? (
                <label className="label label-primary" style={{ margin: 0 }}>
                  {dataset.instrument.name}
                </label>
              ) : (
                NotSet
              )}
            </>
          ) : (
            <>
              {dataset.instrument ? (
                <LinkEntity entityConstants={instrumentsConstants} property={dataset.instrument} />
              ) : (
                NotSet
              )}
            </>
          ),
        },
        {
          key: "Suppl. Equipment",
          value: <GetEquipments equipments={dataset.equipments} createLinks={!context?.viewShared} />,
        },
        {
          key: "Acquis. Date",
          value: dataset.acquisitionDate ? <DateTimeRenderer date={dataset.acquisitionDate} /> : NotSet,
        },
        {
          key: "Projects",
          value: <GetProjects projects={dataset.projects} createLinks={!context?.viewShared} />,
        },
        {
          key: "Organizations",
          value: <GetOrganizations organizations={dataset.organizations} createLinks={!context?.viewShared} />,
        },
        {
          key: "Operators",
          value: <GetPersons persons={dataset.operators} createLinks={!context?.viewShared} />,
        }
      );

      dataset.other &&
        mapping.push({
          key: "Other",
          value: <span>{dataset.other}</span>,
        });

      // dataset.customFields &&
      //   !dataset.customType &&
      //   Object.entries(dataset.customFields).map(([key, value], index) => mapping.push({ key: key, value: value }));

      mapping.push({
        key: "Notes",
        value: <>{!dataset.notes && NotSet} </>,
      });
      setMapping(() => mapping);
      setLoading(() => false);
    }
  }, [context?.viewShared, dataset]);

  const body = (
    <>
      {loading ? (
        <Skeleton type="pairs" />
      ) : (
        <>
          <div className={styles.container}>
            <Table noPadding nonFoldable>
              <Table.Head>Basic details</Table.Head>
              <Table.Body>
                <ContentTable content={mapping} />
                {dataset.notes && (
                  <div className={"container_notes"}>
                    <textarea rows={4} className={"container_textarea"} value={dataset.notes} disabled={true} />
                  </div>
                )}
              </Table.Body>
            </Table>
            {context?.viewShared ? (
              <CustomTypeMinimalRenderer entity={dataset} />
            ) : (
              <CustomTypeRenderer entity={dataset} includeCustomFieldId={false} rowStyle={{ borderBottom: 0 }} />
            )}
          </div>
        </>
      )}
    </>
  );

  if (!tile) return body;
  return (
    <>
      <Tile>
        {/* <Tile.Head title="Dataset" /> */}
        <Tile.Body style={{ borderTop: "0px" }}>{body}</Tile.Body>
      </Tile>
    </>
  );
};
