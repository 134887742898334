import React, { useCallback } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, useWatch } from "react-hook-form";
import { InputFormField } from "../../../formfields/InputFormField";
import { QuickAddChildrenProps } from "../common/QuickAddWrapper";
import { FormButtons } from "../../FormButtons";
import { FormHeader } from "../../FormHeader";
import { TextareaFormField } from "../../../formfields/TextareaFormField";
import { Sample, SampleFieldLabels, samplesConstants } from "../../../../api/Samples";
import { iso2localDate } from "../../../datetime/DateTimeFormatter";
import { useQuickAddSubmit } from "../common/useQuickAddHooks";
import { SingleDatePickerFormField } from "../../../formfields/DatePickerFormField/SingleDatePickerFormField";
import { ProjectsVirtualizedSelectForm } from "../../EntityForms/formsVirtualized/ProjectsVirtualizedSelectForm";
import { PersonsVirtualizedSelectForm } from "../../EntityForms/formsVirtualized/PersonsVirtualizedSelectForm";
import {
  EntityCustomTypeForm,
  useCustomTypeForm,
} from "../../../../Customization/CustomTypes/generics/useCustomTypeForm";
import { sampleFormBaseSchema } from "../../../../samples/SampleForm";
import { CustomTypesVirtualizedSelectForm } from "../../EntityForms/formsVirtualized/CustomTypesVirtualizedSelectForm";

export const QuickAddSampleForm = ({ onCreate, onCancel, initialValues }: QuickAddChildrenProps<Sample>) => {
  const { onSubmit, loading } = useQuickAddSubmit<Sample>({
    resource: samplesConstants.resource,
    onCreate: onCreate,
    onCancel: onCancel,
  });

  const { defaultValues, typedFormSchema, processCustomFields, type, setType, types } = useCustomTypeForm({
    initialValues: initialValues,
    formSchema: sampleFormBaseSchema,
    typeId: initialValues?.customType?.id,
    entityType: "Sample",
  });

  const processEntity = useCallback((entity: Partial<Sample>) => {
    const discardedAt = entity.discardedAt ? iso2localDate(entity.discardedAt) : null;
    if (discardedAt) discardedAt.setUTCHours(0, 0, 0, 0);
    const preparedAt = iso2localDate(entity.preparedAt);
    preparedAt.setUTCHours(0, 0, 0, 0);
    return {
      ...entity,
      ...(entity.discardedAt
        ? {
            discardedAt: discardedAt ? discardedAt.toISOString() : null,
          }
        : { discardedAt: null }),
      preparedAt: preparedAt.toISOString(),
      customType: entity.customType ?? null,
      ...(!entity.customType && { customFields: {} }),
    } as Partial<Sample>;
  }, []);

  const {
    control,
    register,
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm<Partial<Sample>>({
    values: defaultValues,
    resolver: yupResolver(typedFormSchema),
  });

  const _customType = useWatch({ name: "customType", control: control });
  const typesLength = Object.keys(types ?? {}).length;

  return (
    <>
      <FormHeader title={"Add Sample"} subtitle={_customType?.name ?? ""} />
      {!!typesLength && !_customType ? (
        <CustomTypesVirtualizedSelectForm
          id={"customType"}
          control={control}
          concatItems={[{ name: "Basic sample", id: -1 }]}
          filters={{ entityTypes: ["Sample"] }}
          horizontal
        />
      ) : (
        <>
          <fieldset>
            <legend className="col-md-offset-2 col-md-10">Basic details</legend>
          </fieldset>
          <InputFormField
            id={"name"}
            label={"Name"}
            errors={errors}
            register={register}
            autoFocus={true}
            required={true}
          />

          <ProjectsVirtualizedSelectForm
            id={"projects"}
            control={control}
            required
            isMulti
            allowCreateEntity
            horizontal
            filters={{ currentUserHasAddPermission: true }}
          />

          <PersonsVirtualizedSelectForm
            id={"preparedBy"}
            label={SampleFieldLabels.preparedBy}
            control={control}
            required
            isMulti
            allowCreateEntity
            horizontal
          />

          <SingleDatePickerFormField
            type="date"
            id={"preparedAt"}
            label={SampleFieldLabels.preparedAt}
            control={control}
            required={true}
            errors={errors}
            horizontal
          />

          <PersonsVirtualizedSelectForm
            id={"discardedBy"}
            label={SampleFieldLabels.discardedBy}
            control={control}
            allowCreateEntity
            isMulti
            horizontal
          />

          <SingleDatePickerFormField
            type="date"
            id={"discardedAt"}
            label={SampleFieldLabels.discardedAt}
            control={control}
            required={false}
            errors={errors}
            horizontal
          />

          <TextareaFormField id="notes" label="Notes" errors={errors} register={register} />

          <EntityCustomTypeForm
            entityType="Sample"
            typeId={initialValues?.customType?.id}
            type={type}
            types={types}
            setType={setType}
            control={control}
            register={register}
            setValue={setValue}
            initialValues={initialValues}
            errors={errors}
            entityConstants={samplesConstants}
          />

          <FormButtons
            onClose={onCancel}
            onSubmit={handleSubmit(async (entity) => await onSubmit(processCustomFields(processEntity(entity))))}
            disabled={isSubmitting}
            errors={errors}
            submitButtonLabel="Create"
            loading={loading}
          />
        </>
      )}
    </>
  );
};
