import { EmptyParameter } from "./EmptyParameter";
import { parameterTypes, serializedStringListType } from "../PipelineTypes";

type valueType = { value: string[] };

export class StringListParameter extends EmptyParameter {
  name: string = "stringList";
  parameterType = parameterTypes.stringList;

  value: string[];

  constructor(value?: any) {
    super(value);
    this.value = [];
    this.deserialize(value);
    // console.log("StringList ", value);
  }

  deserialize(value?: string[] | valueType) {
    super.deserialize(value);
    const v = this.parseValue(value);
    this.value = v.value;
  }

  parseValue(value?: string[] | valueType): valueType {
    const result: valueType = { value: this.value };

    if (value === undefined) return result;

    if (typeof value !== "object") {
      this.errors.create({
        id: "",
        component: "StringList.setValue",
        message: `Expected type 'string[]'. (Got type '${typeof value}').`,
      });
      return result;
    }

    if (!Array.isArray(value)) value = (value as valueType).value ?? [];

    if (!Array.isArray(value)) {
      this.errors.create({
        id: "",
        component: "StringList.setValue",
        message: `Expected type 'string[]'. (Got type '${typeof value}').`,
      });
      return result;
    }

    result.value = value.map((v) => String(v));
    return result;
  }

  serialize(): serializedStringListType {
    // const result = serializeCommandParameter(this) as serializedStringListType;
    const result = super.serialize() as serializedStringListType;
    Object.assign(result, { value: this.value });
    return result;
  }
}
