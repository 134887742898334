import { useContext, useMemo, useState } from "react";
import { useEntityDetail } from "../../../../../api/BaseEntityApi";
import { genericEntityConstants } from "../../../../../api/GenericConstants";
import { IGenericEntity, INamedEntity } from "../../../../../api/GenericTypes";
import { GetPosition } from "../entity/EntityWrapper/EntityWrapper";
import { EntityMentionAttributes, EntityMentionOptions } from "./entityMention-types";
import { toUppercase } from "../../../../../common/helperfunctions/stringFunctions";
import { SessionContext } from "../../../../../common/contexts/SessionContext";
import { getDetailLink } from "../../../../../main/Routing";
import { LucideIcon } from "../../../../../common/icon/LucideIcon";
import { useCommands, useHelpers } from "@remirror/react";
import { Link, useHistory } from "react-router-dom";
import { LinkButton } from "../../../../../common/buttons/LinkButton/LinkButton";
import { getNotebookEntryStringLabel } from "../../../../LabNotebookEntry/LabNotebookEntry";
import { ExclusiveDropdown } from "../../../../../common/buttons/ExclusiveDropdown/ExclusiveDropdown";
import { LoadingWrapper } from "../../../../../common/LoadingWrapper";
import { NotAvailable } from "../../../../../common/misc/UIconstants";

export const EntityMention = ({
  attrs,
  getPosition,
  updateAttributes,
  options,
}: {
  attrs: EntityMentionAttributes;
  getPosition: GetPosition;
  updateAttributes: (attrs: EntityMentionAttributes) => void;
  options?: EntityMentionOptions;
}) => {
  const { route } = useContext(SessionContext);
  const history = useHistory();
  const commands = useCommands();
  const { isViewEditable } = useHelpers();
  const editable = useMemo(() => {
    return isViewEditable();
  }, [isViewEditable]);

  const entityConstants =
    !!attrs?.entityTypeId && Object.hasOwn(genericEntityConstants, attrs.entityTypeId)
      ? genericEntityConstants[attrs.entityTypeId].entityConstants
      : undefined;

  const {
    data: entity,
    status,
    fetchStatus,
    error,
  } = useEntityDetail<IGenericEntity & INamedEntity>(entityConstants?.resource!, attrs?.entityId!, undefined, {
    enabled: !!entityConstants?.resource && !!attrs?.entityId,
  });

  const entityLabel = useMemo(() => {
    if (!attrs) return "Invalid item";
    if (!entity && !!entityConstants) return `${toUppercase(entityConstants.entitySingular)}`;
    if (!entity) return "Item could not be found";
    switch (attrs.entityTypeId) {
      // override name property for specific entity types here
      case "notebookEntries":
        return getNotebookEntryStringLabel({ entry: entity as any });
      default:
        if (!!entity?.name) return entity.name;
        if (entityConstants && !!entity?.id)
          return `${toUppercase(entityConstants?.entitySingular)} - ID: ${entity.id}`;
        return "Unknown name";
    }
  }, [attrs, entity, entityConstants]);

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [showError, setShowError] = useState<boolean>(false);
  if (!!error)
    return (
      <span
        style={{ padding: "0 5px", whiteSpace: "nowrap", cursor: "pointer", color: "var(--gray-300)" }}
        title="An error occurred while fetching the item"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setShowError(true);
        }}
      >
        {entityConstants?.entitySingular ? `${toUppercase(entityConstants.entitySingular)}:` : ""} {NotAvailable}{" "}
        <ExclusiveDropdown
          show={showError}
          setShow={setShowError}
          icon={null}
          backdropStyle={{ backgroundColor: "transparent", backdropFilter: "none" }}
          triggerstyle={{ display: "inline" }}
          triggerComponent={() => <LucideIcon name="triangle-alert" color="var(--danger)" />}
        >
          <div style={{ maxWidth: "50vw", maxHeight: "25vh", overflow: "auto" }}>
            <LoadingWrapper
              status={status}
              fetchStatus={fetchStatus}
              error={error}
              type="skeleton-block"
              alertStyle={{ marginBottom: 0, minWidth: "max-content", minHeight: "max-content" }}
            >
              <div />
            </LoadingWrapper>
          </div>
        </ExclusiveDropdown>
      </span>
    );
  return (
    <LoadingWrapper status={status} fetchStatus={fetchStatus} error={error} type="spinner-inline">
      {!entityConstants || !attrs.entityId ? (
        <span>{entityLabel}</span>
      ) : (
        <ExclusiveDropdown
          show={isOpen}
          setShow={setIsOpen}
          icon={null}
          backdropStyle={{ backgroundColor: "transparent", backdropFilter: "none" }}
          triggerstyle={{ display: "inline" }}
          triggerComponent={() => (
            <Link
              style={{ padding: 0, color: "inherit" }}
              to={route(getDetailLink(entityConstants.frontendIndexRoute, attrs.entityId!))}
              title={entityLabel}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                if (!editable) {
                  entityConstants &&
                    attrs.entityId &&
                    history.push(route(getDetailLink(entityConstants.frontendIndexRoute, attrs.entityId)));
                } else {
                  setIsOpen(true);
                }
              }}
            >
              <span style={{ whiteSpace: "nowrap" }}>
                {entityConstants?.icon && (
                  <>
                    <LucideIcon name={entityConstants.icon} color="var(--primary)" />{" "}
                  </>
                )}
                <span style={{ whiteSpace: "normal" }}>{entityLabel}</span>
              </span>
            </Link>
          )}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "5px",
            }}
          >
            {!!entityConstants?.frontendIndexRoute && !!attrs.entityId && (
              <>
                <LinkButton
                  style={{ textAlign: "left" }}
                  linkTo={route(getDetailLink(entityConstants.frontendIndexRoute, attrs.entityId))}
                  className="btn btn-xs btn-ghost-primary btn-block"
                >
                  <LucideIcon name="external-link" /> Go to {entityConstants.entitySingular}
                </LinkButton>
                {!!options?.openViewer &&
                  !!entity &&
                  !!attrs.entityTypeId &&
                  (attrs.entityTypeId === "datasets" || attrs.entityTypeId === "announcements") && (
                    <button
                      style={{ textAlign: "left" }}
                      className="btn btn-xs btn-ghost-danger btn-block"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        options.openViewer?.(e, entity, attrs.entityTypeId!);
                        setIsOpen(false);
                      }}
                    >
                      <LucideIcon name="eye" /> View {entityConstants.entitySingular}
                    </button>
                  )}
                <hr style={{ margin: "2px 0", borderColor: "var(--gray-300)" }} />
              </>
            )}
            <button
              style={{ textAlign: "left" }}
              className="btn btn-xs btn-ghost-danger btn-block"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                commands.deleteEntityMention();
              }}
            >
              <LucideIcon name="trash" /> Remove reference
            </button>
          </div>
        </ExclusiveDropdown>
      )}
    </LoadingWrapper>
  );
};
