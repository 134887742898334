const getSVGClasses = (svg: Element): string[] => {
  // console.log("node", svg)
  const children = svg.children;
  let svgClasses: string[] = [];
  for (let i = 0; i < children.length; i++) {
    const child = children[i];

    // console.log("child", child.nodeName);
    const nodeName = child.nodeName;
    if (nodeName === undefined) continue;

    svgClasses = svgClasses.concat(getSVGClasses(child));

    const svgClass = child.getAttribute("class");
    if (svgClass === null) continue;
    // console.log(">>", nodeName, svgClass);
    svgClasses.push("." + svgClass);
  }

  return svgClasses;
};

export const uniq = (list: string[]): string[] => {
  return Array.from(new Set(list));
};

export const getSVGCSS = (svg: SVGSVGElement) => {
  const svgClasses = new Set(uniq(getSVGClasses(svg)).map((a) => a.split(/ /)[0]));

  // console.log("svgClasses", svgClasses);

  const extractedCSSText = [];
  for (let i = 0; i < document.styleSheets.length; i++) {
    const s: CSSStyleSheet = document.styleSheets[i] as CSSStyleSheet;

    // console.log("css", s.cssRules);

    try {
      if (!s.cssRules) continue;
    } catch (e: any) {
      if (e.name !== "SecurityError") throw e; // for Firefox
      continue;
    }

    extractedCSSText.push(
      ...Array.from(s.cssRules)
        .filter((r) => {
          const rule = r as CSSStyleRule;
          return rule && svgClasses.has(rule.selectorText);
        })
        .map((r) => (r as CSSStyleRule).cssText)
    );
  }
  return extractedCSSText.join("");
};

export const addCSS = (cssText: string, element: Element) => {
  const styleElement = document.createElement("style");
  styleElement.setAttribute("type", "text/css");
  styleElement.innerHTML = cssText;

  // const styles = Array.from(element.getElementsByTagName("style")).forEach((s) => element.removeChild(s));

  const refNode = element.hasChildNodes() ? element.children[0] : null;
  element.insertBefore(styleElement, refNode);
};
