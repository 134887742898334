import React, { memo } from "react";
import { UseFormReturn, FieldValues, useWatch, Path, FieldErrors } from "react-hook-form";
import { GenericEntity, ICustomTypedEntity } from "../../../../api/GenericTypes";
import { checkIsDirty } from "../common/MultiEditUtils";
import { MultiEditFormWrapper } from "../common/MultiEditFormWrapper";
import { MultiEditCustomFieldFormProps } from "../common/MultiEditSelectFormTypes";
import { NotAvailable } from "../../../misc/UIconstants";
import { customFieldToCustomFieldForm } from "../../../../Customization/CustomFields/CustomFieldUtils";
import { CustomFieldEditRenderer } from "../../../../Customization/CustomFields/CustomFieldEditRenderer";

const MemoComponent = memo(
  <Entity extends GenericEntity & Partial<ICustomTypedEntity>>({
    control,
    register,
    formState,
    getFieldState,
    row,
    customField,
    types,
    disabled,
    ...rest
  }: UseFormReturn<FieldValues> & MultiEditCustomFieldFormProps<Entity>) => {
    // const [_isError, setIsError] = useState<FieldError | undefined>();
    // const [_isDirty, setIsDirty] = useState<boolean>(false);

    // const onBlur = useCallback(() => {
    //   setIsDirty(checkIsDirty(formState.dirtyFields?.[row.id]?.customFields?.[customField.id]));
    //   setIsError(getFieldState(`${row.id}.customFields.${customField.id}`, formState).error);
    // }, [customField.id, formState, getFieldState, row.id]);

    const isDirty = checkIsDirty(formState.dirtyFields?.[row.id]?.customFields?.[customField.id]);
    const isError = getFieldState(`${row.id}.customFields.${customField.id}`, formState).error;
    const _customType = useWatch({ name: `${row.id}.customType`, control: control });

    if (!_customType) {
      return (
        <div className="flex align-center justify-center" style={{ width: "100%", height: "100%" }}>
          {NotAvailable}
        </div>
      );
    }

    if (!Object.hasOwn(types, _customType.id)) {
      return (
        <div className="flex align-center justify-center" style={{ width: "100%", height: "100%" }}>
          {NotAvailable}
        </div>
      );
    }

    if (
      Object.hasOwn(types, _customType.id) &&
      !types[_customType.id].sections
        .map((s) => s.customFields.map((c) => c.id))
        .flat(Infinity)
        .includes(customField.id)
    ) {
      return (
        <div className="flex align-center justify-center" style={{ width: "100%", height: "100%" }}>
          {NotAvailable}
        </div>
      );
    }
    const _customField = customFieldToCustomFieldForm(customField);
    return (
      <CustomFieldEditRenderer<Entity>
        id={`${row.id}.customFields.${customField.id}` as Path<Entity>}
        label={""}
        register={register as any}
        control={control as any}
        errors={formState.errors as FieldErrors<Entity>}
        placeholder={_customField.placeholder ?? ""}
        required={_customField.required}
        defaultValue={_customField.defaultValues}
        disabled={disabled || _customField.readOnly}
        dataType={_customField.dataType!}
        enumOptions={_customField.enumOptions}
        showAsTextArea={_customField.showAsTextArea}
        description={_customField.description}
        isMulti={!!_customField.isMulti}
        customFieldId={customField.id}
        enumOptionsFromValues={customField.enumOptionsFromValues}
        filters={
          Array.isArray(customField.customTypeConstraint) && !!customField.customTypeConstraint.length
            ? { customTypeIds: customField.customTypeConstraint.map((c) => c.id) }
            : { customTypeIds: null }
        }
        style={{
          width: "100%",
          height: "100%",
          border: isError ? "1px solid var(--danger)" : isDirty ? "1px solid var(--success)" : "0px",
          borderRadius: 4,
          background: disabled
            ? "var(--gray-100)"
            : isError
            ? "var(--danger-light)"
            : isDirty
            ? "var(--success-light)"
            : "unset",
        }}
        uncontained
      />
    );
  },
  (prevProps, nextProps) =>
    checkIsDirty(prevProps.formState.dirtyFields?.[prevProps.row.id]?.customFields?.[prevProps.customField.id]) ===
    checkIsDirty(nextProps.formState.dirtyFields?.[nextProps.row.id]?.customFields?.[prevProps.customField.id])
);

export const MultiEditCustomFieldForm = <Entity extends GenericEntity & Partial<ICustomTypedEntity>>(
  props: MultiEditCustomFieldFormProps<Entity>
) => {
  return <MultiEditFormWrapper>{(formProps) => <MemoComponent {...formProps} {...props} />}</MultiEditFormWrapper>;
};
