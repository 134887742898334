import React from "react";
import styles from "./MultiEditStyles.module.css";
import { GenericEntity } from "../../../../api/GenericTypes";
import { FieldError, FieldErrors } from "react-hook-form";
import { MultiEditMappedItemObject } from "./MultiEditProvider";
import { Table } from "../../../panels/Detail/DetailTable";
import * as yup from "yup";
import { LucideIcon } from "../../../icon/LucideIcon";

export const ErrorsRenderer = ({ property, error }: { property?: string; error: Object | FieldError | undefined }) => {
  // console.log(error, typeof error, error?.constructor.name);
  if (!error) return <></>;
  // if (Object.hasOwn(error, "message") && Object.hasOwn(error, "ref") && Object.hasOwn(error, "type"))
  // We naively have to assume this is an actual error and not a customField entity with customFieldIds: "messsage" and "type"
  if (Object.hasOwn(error, "message") && Object.hasOwn(error, "type")) {
    return (
      <li>
        {property && <code>{property}</code>}{" "}
        <span className={styles.MultiEditErrorMsg}>{(error as yup.ValidationError).message}</span>
      </li>
    );
  } else {
    return (
      <>
        {!!Object.keys(error).length &&
          Object.entries(error).map(([_key, _err], idx) => {
            return (
              // <ul key={idx} style={{ margin: 0 }}>
              <ErrorsRenderer property={_key} error={_err} key={idx} />
              // </ul>
            );
          })}
      </>
    );
  }
};

interface MultiEditErrorContainerProps<Entity extends GenericEntity> {
  entityDescSingular: string;
  errors: FieldErrors<MultiEditMappedItemObject<Entity>>;
}
export const MultiEditErrorContainer = <Entity extends GenericEntity>({
  entityDescSingular,
  errors,
}: MultiEditErrorContainerProps<Entity>) => {
  const entityName = entityDescSingular.charAt(0).toUpperCase() + entityDescSingular.slice(1);
  const nErrors = Object.keys(errors).length;
  if (!errors || !nErrors) return <></>;
  return (
    <div className={styles.MultiEditErrorContainer}>
      <Table style={{ height: "100%", overflow: "hidden" }}>
        <Table.Head style={{ backgroundColor: "var(--danger-light)", color: "var(--danger)" }}>
          <label style={{ margin: 0 }}>
            Validation {nErrors === 1 ? "error" : "errors"} <span className="badge">{nErrors}</span>
          </label>
        </Table.Head>
        <Table.Body style={{ height: "100%", overflow: "scroll" }}>
          <div className={styles.MultiEditErrorContainerErrors}>
            <>
              {Object.entries(errors).map(([id, error], index) => (
                <React.Fragment key={index}>
                  <span style={{ fontWeight: "500" }}>
                    <LucideIcon name="square-x" /> {entityName}-ID: {id}
                  </span>
                  <ErrorsRenderer error={error} key={index} />
                </React.Fragment>
              ))}
            </>
          </div>
        </Table.Body>
      </Table>
    </div>
  );
};
