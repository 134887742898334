import { StringIndexedDict } from "../../../../api/GenericTypes";
import {
  GenericEntityVirtualizedEntitySelectFormFieldProps,
  GenericVirtualizedEntitySelectFormFieldControllerWrapper,
} from "../../../formfields/GenericVirtualizedSelectFormField/GenericVirtualizedEntitySelectFormFieldControllerWrapper";
import { QuickAddWrapper } from "../../QuickAdd/common/QuickAddWrapper";
import { PersonFilters, PersonSortingOptionsConsts, PersonSuggestions, personsConstants } from "../../../../api/Person";
import { useContext } from "react";
import { SessionContext } from "../../../contexts/SessionContext";
import { QuickAddPersonForm } from "../../QuickAdd/forms/QuickAddPersonForm";

export const PersonsVirtualizedSelectForm = <Entity extends StringIndexedDict>(
  props: GenericEntityVirtualizedEntitySelectFormFieldProps<Entity, PersonSuggestions, PersonFilters>
) => {
  const { session } = useContext(SessionContext);
  return (
    <GenericVirtualizedEntitySelectFormFieldControllerWrapper<Entity, PersonSuggestions, PersonFilters>
      {...props}
      filters={{ orderBy: "NAME_ASC", ...props.filters }}
      //   onRowRenderer={(item) => <></>}
      createModal={({ showModal, setShowModal, onCreate }) => (
        <QuickAddWrapper showModal={showModal} setShowModal={setShowModal} onCreate={onCreate}>
          {(quickAddProps) => (
            <QuickAddPersonForm
              {...quickAddProps}
              initialValues={{
                ...quickAddProps.initialValues,
                ...(props.filters?.customTypeIds && {
                  customType: { name: "", id: props.filters?.customTypeIds?.[0] },
                }),
              }}
            />
          )}
        </QuickAddWrapper>
      )}
      entityConstants={personsConstants}
      orderByOptions={PersonSortingOptionsConsts}
      allowCreateEntity={session?.permissions.administer_persons && props.allowCreateEntity}
    />
  );
};
