import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { InputFormField } from "../common/formfields/InputFormField";
import { TextareaFormField } from "../common/formfields/TextareaFormField";
import { FormButtons } from "../common/forms/FormButtons";
import { FormHeader } from "../common/forms/FormHeader";
import styles from "../common/forms/forms.module.css";
import { Project, ProjectFilters, ProjectFieldLabels, projectsConstants } from "../api/Projects";
import { ProjectPersonPermissionsEditForm } from "../ProjectPersonPermissions/ProjectPersonPermissionsEditForm";
import { ProjectsTagsVirtualizedSelectForm } from "../common/forms/EntityForms/formsVirtualized/ProjectTagsVirtualizedSelectForm";
import { ProjectPersonPermission } from "../ProjectPersonPermissions/ProjectPersonPermissions";
import { CustomTypedEntityFormProps, FormEventTypes } from "../common/entity/EntityInterfaces";
import { IResultModel } from "../api/GenericTypes";
import { useContext } from "react";
import { SessionContext } from "../common/contexts/SessionContext";
import { EntityCustomTypeForm, useCustomTypeForm } from "../Customization/CustomTypes/generics/useCustomTypeForm";

export const useProjectFormSchema = (initialValues?: Partial<Project>, event?: FormEventTypes) => {
  const { api } = useContext(SessionContext);
  const ProjectFormSchema = {
    name: yup
      .string()
      .required("Project name is required")
      .typeError("Project name is required")
      .test({
        name: "NoWhitespaces",
        test: (value) => value === value?.trim(),
        message: "Whitespaces at the beginning or at the end of the name are not allowed",
      })
      .test({
        name: "UniqueProjects",
        test: async (value) => {
          if (!value) return false;
          if (value === initialValues?.name && event !== "CLONE") return true;
          const existingProjectIds = (
            (await api.post("projects/ids_only", { names: [value] } as ProjectFilters, {})) as IResultModel<number>
          )?.results;
          return !!existingProjectIds && !existingProjectIds.length;
        },
        message: "Project name already exists",
      }),
    projectPersonPermissions: yup
      .array()
      .typeError("Invalid input for field 'Project access'")
      .min(1, "A project requires at least one administrator")
      .test({
        test: (value) => !!(value as ProjectPersonPermission[]).filter((p) => p.administer).length,
        message: "A project requires at least one administrator",
      }),
  };
  return ProjectFormSchema;
};

export const ProjectForm = ({
  title,
  subtitle,
  initialValues,
  onSubmit,
  onCancel,
  loading,
  submitButtonLabel,
  event,
  typeId,
}: CustomTypedEntityFormProps<"projects">) => {
  const { session } = useContext(SessionContext);

  const ProjectFormSchema = useProjectFormSchema(initialValues, event);

  const { defaultValues, typedFormSchema, processCustomFields, type, setType, types } = useCustomTypeForm({
    initialValues: initialValues,
    formSchema: ProjectFormSchema,
    typeId: typeId,
    entityType: "Project",
  });

  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm<Partial<Project>>({
    values: defaultValues,
    resolver: yupResolver(typedFormSchema),
  });

  return (
    <>
      <FormHeader title={title} subtitle={subtitle} />
      <form
        onSubmit={(e) => {
          e.preventDefault();
        }}
        autoComplete="off"
        className={`form-horizontal ${styles.form_holder}`}
      >
        <fieldset>
          <legend className="col-md-offset-2 col-md-10">Basic details</legend>
        </fieldset>

        <InputFormField
          id="name"
          label={ProjectFieldLabels.name}
          errors={errors}
          register={register}
          autoFocus={true}
          required={true}
          placeholder="Enter project name..."
        />

        <ProjectsTagsVirtualizedSelectForm
          id="projectTags"
          label={ProjectFieldLabels.projectTags}
          control={control}
          showControls
          horizontal
          isMulti
          allowCreateEntity
        />

        <TextareaFormField
          id="notes"
          label={ProjectFieldLabels.notes}
          errors={errors}
          register={register}
          placeholder="Enter notes..."
        />

        <fieldset>
          <legend className="col-md-offset-2 col-md-10">
            Project access<span style={{ color: "var(--danger)" }}>*</span>
          </legend>
        </fieldset>

        <ProjectPersonPermissionsEditForm
          id="projectPersonPermissions"
          errors={errors}
          project={initialValues as Project}
          getProjectPersonPermissions={(values) => {
            setValue("projectPersonPermissions", values);
          }}
        />
        {session?.features.enable_project_custom_types && (
          <EntityCustomTypeForm
            entityType="Project"
            typeId={typeId}
            type={type}
            types={types}
            setType={setType}
            control={control}
            register={register}
            setValue={setValue}
            initialValues={initialValues}
            errors={errors}
            entityConstants={projectsConstants}
          />
        )}
        <FormButtons
          groupName="projects"
          entityId={initialValues ? initialValues.id : undefined}
          onClose={onCancel}
          onSubmit={handleSubmit(async (entity) => await onSubmit(processCustomFields(entity)))}
          submitButtonLabel={submitButtonLabel || "Save changes"}
          disabled={isSubmitting}
          errors={errors}
          loading={loading}
        />
      </form>
    </>
  );
};
