import { EntityTableProps } from "./EntityInterfaces";
import { useEntityApi } from "../../api/useEntityApi";
import { EntityByEntityTypeId, GenericEntityConstantsEntities, getEntityConstants } from "../../api/GenericConstants";
import { useEntityPermissions } from "../permissions/useEntityPermissions";
import { useEntityRoutes } from "../routes/useEntityRoutes";
import { PageNotAllowed } from "../../main/common/PageNotAllowed/PageNotAllowed";

interface EntityTablePageProps<EntityTypeId extends GenericEntityConstantsEntities> {
  entityTypeId: EntityTypeId;
  children: (props: EntityTableProps<EntityTypeId>) => React.ReactNode;
}

export const EntityTablePage = <EntityTypeId extends GenericEntityConstantsEntities>({
  entityTypeId,
  children,
}: EntityTablePageProps<EntityTypeId>) => {
  const { entityConstants, fieldLabels } = getEntityConstants(entityTypeId);
  const permissions = useEntityPermissions({ entityTypeId });
  const { canReadIndex } = permissions;
  type EntityType = EntityByEntityTypeId<EntityTypeId>;
  const entityApi = useEntityApi<EntityType>(entityConstants.resource);
  const routes = useEntityRoutes({ entityConstants });

  if (!canReadIndex) return <PageNotAllowed />;

  return <>{children({ entityApi, entityConstants, fieldLabels, permissions, routes })}</>;
};
