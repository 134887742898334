import {
  Sample,
  SampleFilters,
  SampleFiltersTranslator,
  SampleSortingOptionsConsts,
  samplesConstants,
} from "../../../api/Samples";

import { EntitySidebarTile, EntitySidebarTileProps } from "./EntitySidebarTile/EntitySidebarTile";
import { NotSet } from "../../misc/UIconstants";
import { DateTimeRenderer } from "../../datetime/DateTimeFormatter";

export const SamplesTile = (props: EntitySidebarTileProps<Sample, SampleFilters>) => (
  <EntitySidebarTile<Sample, SampleFilters>
    {...props}
    entityConstants={samplesConstants}
    orderByOptions={SampleSortingOptionsConsts}
    translatorConsts={SampleFiltersTranslator}
    defaultFilters={{ orderBy: "PREPARATION_DATE_DESC", ...props.defaultFilters }}
    rowLabel={(e) => <>{e.name}</>}
    rowDate={(e) => (
      <>{e.preparedAt ? <DateTimeRenderer date={e.preparedAt} includeElapsed={false} includeTime={false} /> : NotSet}</>
    )}
    rowInfo={(e) => (
      <label className="label label-soft-info">{e.customType ? e.customType.name : "Basic sample"}</label>
    )}
  />
);
