// import { OverlayTrigger, Popover } from "react-bootstrap";
import { DataSource } from "../../../../api/DataSource";
import { Table } from "../../../../common/panels/Detail/DetailTable";
import { LucideIcon } from "../../../../common/icon/LucideIcon";
import { BridgeConnectionStatus } from "../../../Bridges/common/BridgeConnectionStatus";
import { NotAvailable } from "../../../../common/misc/UIconstants";
import { CircularCountdown } from "../../../../common/loaders/CircularCountdown/CircularCountdown";
import { DateTimeRenderer } from "../../../../common/datetime/DateTimeFormatter";
import { GenericModal } from "../../../../common/modals/Modal/GenericModal";
import { useState } from "react";
import { Alert } from "../../../../common/overlays/Alert/Alert";
import { DataSourceRunningStatus } from "../DataSourceRunningStatus/DataSourceRunningStatus";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { ParameterFormatter } from "../../../../ParameterViewer/TreeViewer/ParameterFormatter";

const parameterFormatter = new ParameterFormatter();

export const DataSourceStatus = ({ dataSource }: { dataSource: DataSource }) => {
  const [showErrorModal, setShowErrorModal] = useState<boolean>(false);
  return (
    <>
      <Table.Body.RowContent
        title="Bridge connection"
        content={<BridgeConnectionStatus bridge={dataSource.bridge} />}
      />

      <Table.Body.RowContent
        title="Data source activity"
        content={<DataSourceRunningStatus dataSource={dataSource} />}
      />

      {dataSource.status?.lastClientStatus?.runState === "Running" ? (
        <Table.Body.RowContent
          title="Started on"
          content={
            dataSource.status?.lastClientStatus?.startedOn ? (
              <div>
                <DateTimeRenderer date={dataSource.status.lastClientStatus.startedOn} includeElapsed={true} />
              </div>
            ) : (
              NotAvailable
            )
          }
        />
      ) : (
        <Table.Body.RowContent
          title="Duration of last run"
          content={
            dataSource.status?.lastClientStatus?.duration
              ? parameterFormatter.formatDuration(dataSource.status.lastClientStatus.duration, 1)
              : NotAvailable
          }
        />
      )}

      <Table.Body.RowContent
        title="Next scheduled"
        content={
          <div className="flex align-center" style={{ width: "100%", justifyContent: "space-between" }}>
            {dataSource.status?.lastClientStatus?.runState !== "Running" ? (
              <div className="flex align-center gap-5">
                {dataSource.enabled &&
                dataSource.status?.lastClientStatus?.startedOn &&
                dataSource.status.nextScheduledDate ? (
                  <div className="flex row-nowrap align-center gap-5">
                    <CircularCountdown
                      startTime={new Date(dataSource.status.lastClientStatus.startedOn)}
                      endTime={new Date(dataSource.status.nextScheduledDate)}
                      width={24}
                      style={{ stroke: "var(--primary)" }}
                    />
                    <DateTimeRenderer date={dataSource.status?.nextScheduledDate} includeElapsed={false} />
                  </div>
                ) : (
                  NotAvailable
                )}
                {!dataSource.enabled && (
                  <div className="flex align-center">
                    <span className="label label-soft-warning" style={{ marginLeft: "5px" }}>
                      disabled
                    </span>
                  </div>
                )}
              </div>
            ) : (
              NotAvailable
            )}
            <OverlayTrigger
              placement="bottom"
              overlay={<Popover id="popover-info">The time of the next scheduled data transfer.</Popover>}
            >
              <LucideIcon name="info" color={"var(--gray-400)"} />
            </OverlayTrigger>
          </div>
        }
      />

      {!!dataSource.status?.lastClientStatus?.errors?.length && (
        <Table.Body.RowContent
          title="Errors"
          content={
            <button
              className="btn btn-sm btn-block btn-soft-danger flex align-center gap-5"
              onClick={() => setShowErrorModal(true)}
            >
              <LucideIcon name="triangle-alert" />
              {`${dataSource.status?.lastClientStatus?.errors?.length} error${
                dataSource.status.lastClientStatus.errors.length > 1 ? "s" : ""
              } occured - Click to show more information!`}
            </button>
          }
        />
      )}

      <GenericModal
        showModal={showErrorModal}
        setShowModal={() => setShowErrorModal(false)}
        modalTitle={
          <div className="flex align-center gap-5">
            <LucideIcon name="triangle-alert" color="var(--danger)" />
            <div> Errors</div>
          </div>
        }
        modalBody={
          <div
            className="flex col-nowrap align-center gap-5"
            style={{ width: "100%", padding: "0 10px", overflow: "auto" }}
          >
            {dataSource.status?.lastClientStatus?.errors?.map((error, index) => (
              <Alert type="danger" fit message={error.message} style={{ margin: 0 }} key={index} />
            ))}
          </div>
        }
        modalControls={
          <div className="flex align-center gap-5" style={{ justifyContent: "flex-end" }}>
            <button className="btn btn-default" onClick={() => setShowErrorModal(false)}>
              <span> Close</span>
            </button>
          </div>
        }
      />
    </>
  );
};
