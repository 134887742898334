import { FetchStatus, QueryStatus, UseQueryResult } from "@tanstack/react-query";
import { Skeleton } from "./loaders/Skeleton/Skeleton";
import BrandLoader from "./loaders/Spinner/BrandLoader";
import { Spinner } from "./loaders/Spinner/Spinner";
// import { LoadingView } from "./LoadingView";
import { Alert } from "./overlays/Alert/Alert";
import { ErrorContainer, ServerError } from "./helperfunctions/ApiError";
import { CSSProperties } from "react";

// export type LoadingStatus = "loading" | "error" | "success" | "idle";

type Loaders =
  | "spinner"
  | "spinner-inline"
  | "fid"
  | "skeleton-block"
  | "skeleton-pairs"
  | "skeleton-rows"
  | "skeleton-columns"
  | "entity-tile";
export interface LoadingWrapperProps {
  children: React.ReactNode;
  status: QueryStatus;
  fetchStatus: FetchStatus;
  error?: any;
  type?: Loaders;
  alertStyle?: CSSProperties;
}

export const RenderLoader = ({ type }: { type: Loaders }) => {
  switch (type) {
    case "spinner":
      return <Spinner />;
    case "spinner-inline":
      return <Spinner size="xs" style={{ margin: "0 5px" }} inline />;
    case "fid":
      return <BrandLoader size={100} />;
    case "skeleton-block":
      return <span className="skeleton-block" style={{ height: 37 }} />;
    case "entity-tile":
      return <span className="skeleton-block" style={{ height: 54 }} />;
    case "skeleton-pairs":
      return <Skeleton type="pairs" />;
    case "skeleton-rows":
      return <Skeleton type="rows" />;
    case "skeleton-columns":
      return <Skeleton type="columns" />;
  }
};

export const LoadingWrapper = ({
  children,
  status,
  fetchStatus,
  error,
  type = "spinner",
  alertStyle,
}: LoadingWrapperProps) => {
  // Disabled queries
  // if (status === "loading" && fetchStatus === "idle") return renderLoader(type);
  if (status === "loading" && fetchStatus === "fetching") return <RenderLoader type={type} />;
  if (status === "error")
    return (
      <Alert
        type="danger"
        message={
          <>
            {error && Object.hasOwn(error, "message") ? (
              <ErrorContainer message={error.message} status={error.status ?? undefined} />
            ) : (
              "Connection to server lost"
            )}
          </>
        }
        children={error && Object.hasOwn(error, "details") ? <>{(error as ServerError).details}</> : undefined}
        fit
        centered
        style={{ ...alertStyle }}
      />
    );
  // if (error && error.hasOwnProperty("message")) console.error(error.message);
  return <>{children}</>;
};

export interface DictOfReactQueryResponses {
  [item: string]: UseQueryResult;
}

type ArrayOfReactQueryResponses = UseQueryResult[];

export const getLoadingState = (
  responses: DictOfReactQueryResponses | ArrayOfReactQueryResponses
): {
  status: QueryStatus;
  fetchStatus: FetchStatus;
} => {
  let responseArray;
  let status: QueryStatus = "loading";
  let fetchStatus: FetchStatus = "idle";

  if (!Array.isArray(responses)) {
    responseArray = Object.values(responses);
  } else responseArray = responses;

  const isLoading = responseArray.some((response) => response.status === "loading");
  const isError = responseArray.some((response) => response.status === "error");
  const isSuccess = responseArray.every((response) => response.status === "success");
  const isfetching = responseArray.some((response) => response.fetchStatus === "fetching");
  if (isError) status = "error"; // if any query has an error, the combined state is always an error
  if (isLoading) status = "loading"; // if there are no errors and any query is still loading, combined state is loading
  if (isSuccess) status = "success";
  if (isfetching) fetchStatus = "fetching";

  return { status, fetchStatus };
};
