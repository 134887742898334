import React from "react";
import styles from "./Button.module.css";

// Generic button component w/ integrated loading logic
export interface ButtonProps extends React.HTMLProps<HTMLButtonElement> {
  loading?: boolean;
  disabledOnLoading?: boolean;
  children: React.ReactNode;
}
export const Button = ({ loading, disabledOnLoading = true, children, ...rest }: ButtonProps) => {
  return (
    <button
      type={"button" as any}
      {...rest}
      disabled={(loading && disabledOnLoading) || rest.disabled}
      className={`${rest.className} ${loading ? `${styles.btnLoading} loading` : ""}`}
    >
      {loading && <span className="spinner" aria-hidden="true" />} {children}
    </button>
  );
};
