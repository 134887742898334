import { DateTimeRenderer } from "../../datetime/DateTimeFormatter";
import {
  SharedContent,
  SharedContentFilters,
  SharedContentFiltersTranslator,
  SharedContentSortingOptionsConsts,
  sharedContentConstants,
} from "../../../api/SharedContent";
import { EntitySidebarTile, EntitySidebarTileProps } from "./EntitySidebarTile/EntitySidebarTile";
import { NotSet } from "../../misc/UIconstants";
import { GetPersons } from "../../misc/EntityRenders/EntityRenderer";

export const SharedContentTile = (props: EntitySidebarTileProps<SharedContent, SharedContentFilters>) => (
  <EntitySidebarTile<SharedContent, SharedContentFilters>
    {...props}
    entityConstants={sharedContentConstants}
    orderByOptions={SharedContentSortingOptionsConsts}
    translatorConsts={SharedContentFiltersTranslator}
    defaultFilters={{ orderBy: "CREATION_DATE_DESC", ...props.defaultFilters }}
    rowLabel={(e) => <>{e.name}</>}
    rowDate={(e) => (
      <>{e.createdAt ? <DateTimeRenderer date={e.createdAt} includeElapsed={false} includeTime={false} /> : NotSet}</>
    )}
    rowInfo={(e) => <>{e.owner ? <GetPersons persons={e.owner} createLinks={false} /> : NotSet}</>}
  />
);

// interface Props {
//   foldable?: boolean;
//   filterquery: SharedContentFilters;
//   title?: string;
// }
// export const SharedContentTile = ({ foldable = true, filterquery, title = "Shared Content" }: Props) => {
//   const history = useHistory();
//   const { route } = useContext(SessionContext);
//   const pageSize = 5;
//   const [items, setItems] = useState<SharedContent[]>([]);
//   const [searchValue, setSearchValue] = useState("");
//   const debouncedSearchValue = useDebouncedValue(searchValue, 500);

//   const { data, error, fetchNextPage, hasNextPage, isFetching, status, fetchStatus, count } =
//     useInfiniteListEntity<SharedContent>(
//       "shared_content",
//       {
//         page: 1,
//         pageSize: pageSize,
//         includeCount: true,
//
//         ...(debouncedSearchValue && { searchTerm: debouncedSearchValue }),
//         ...filterquery,
//       },
//       { enabled: !!filterquery && !!pageSize },
//       "post"
//     );

//   // Cleanup
//   useEffect(() => {
//     return () => {
//       setItems([]);
//     };
//   }, []);

//   useMemo(() => {
//     if (data) {
//       setItems(data.pages.map((d) => d.results).flat());
//     }
//   }, [data]);

//   const handleRowClick = useCallback(
//     (id: SharedContent["id"]) => {
//       history.push(route(getDetailLink("shared_content", id)));
//     },
//     [history, route]
//   );

//   const fetchNext = useCallback(() => {
//     if (hasNextPage) {
//       fetchNextPage();
//     }
//   }, [fetchNextPage, hasNextPage]);

//   const loadMoreCallback = useCallback(() => {}, []);
//   const itemCount = hasNextPage ? items.length + 1 : items.length;
//   const loadMoreItems = isFetching ? loadMoreCallback : fetchNext;
//   const isItemLoaded = useCallback(
//     (index: number) => !hasNextPage || index < items.length,
//     [items.length, hasNextPage]
//   );

//   const Item = useCallback(
//     ({ index, style }: { index: number; style: CSSProperties }) => {
//       const d = items[index];
//       if (!isItemLoaded(index)) {
//         return (
//           <div style={{ ...style, paddingBottom: "5px" }} key={index}>
//             <span className="skeleton-block btn-lg" style={{ height: 51 }} />
//           </div>
//         );
//       } else {
//         return (
//           <div style={{ ...style, paddingBottom: "5px" }} key={index}>
//             <Link
//               key={index}
//               to={route(getDetailLink("shared_content", d.id))}
//               style={{ textDecoration: "none" }}
//               onClick={(e) => e.preventDefault()}
//             >
//               <ContentRow
//                 key={index}
//                 icon={
//                   <div className={styles.icon}>
//                     <FeatherIcon name="share-2" />
//                   </div>
//                 }
//                 label={d.name}
//                 date={
//                   d.createdAt ? <DateTimeRenderer date={d.createdAt} includeElapsed={false} includeTime={false} /> : "-"
//                 }
//                 id={<>SharedContent-ID: {d.id}</>}
//                 info={<>{d.owner ? <GetPersons persons={[d.owner]} createLinks={false} /> : "-"}</>}
//                 onClick={() => handleRowClick(d.id)}
//               />
//             </Link>
//           </div>
//         );
//       }
//     },
//     [handleRowClick, isItemLoaded, items, route]
//   );

//   return (
//     <Tile
//       isFolded={debouncedSearchValue ? false : !!!items?.hasOwnProperty("0")}
//       foldable={debouncedSearchValue ? true : !!items?.hasOwnProperty("0")}
//       disabled={debouncedSearchValue ? false : !!!items?.hasOwnProperty("0")}
//     >
//       <Tile.Head
//         title={
//           <div className="flex row-nowrap align-center gap-5">
//             {title}
//             {count !== undefined && <span className="badge">{count}</span>}
//           </div>
//         }
//       >
//         <Tile.Head.Controls>
//           <Tile.Head.Controls.Unfolded>
//             <>
//               <SearchInput searchValue={searchValue} setSearchValue={setSearchValue} minimized={false} />
//             </>
//           </Tile.Head.Controls.Unfolded>
//         </Tile.Head.Controls>
//       </Tile.Head>
//       <Tile.Body>
//         <div className={styles.column_container}>
//           <LoadingWrapper status={status} fetchStatus={fetchStatus} error={error}>
//             <div className={isFetching ? `${styles.container} ${styles.container_loading}` : styles.container}>
//               {items && items.length > 0 ? (
//                 <InfiniteLoader isItemLoaded={isItemLoaded} itemCount={itemCount} loadMoreItems={loadMoreItems as any}>
//                   {({ onItemsRendered, ref }) => (
//                     <FixedSizeList
//                       itemCount={itemCount}
//                       onItemsRendered={onItemsRendered}
//                       ref={ref}
//                       width="100%"
//                       height={items.length > pageSize ? pageSize * 59 : items.length * 59}
//                       itemSize={59}
//                     >
//                       {Item}
//                     </FixedSizeList>
//                   )}
//                 </InfiniteLoader>
//               ) : (
//                 <>
//                   {isFetching ? (
//                     <Skeleton type="rows" />
//                   ) : (
//                     <>
//                       {debouncedSearchValue ? (
//                         <Alert type="light" message={`No results for: "${debouncedSearchValue}"`} fit centered />
//                       ) : (
//                         <Alert type="light" message="No related shared content" fit centered />
//                       )}
//                     </>
//                   )}
//                 </>
//               )}
//             </div>
//           </LoadingWrapper>
//         </div>
//       </Tile.Body>
//     </Tile>
//   );
// };
