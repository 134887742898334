import { useQuery } from "@tanstack/react-query";
import { useHistory } from "react-router-dom";
import { availableGroups } from "../../api/Login";
import styles from "./GroupSelector.module.css";
import { useEffect, useState } from "react";
import { LandingPage } from "../LandingPage/LandingPage";

export const GroupSelectionPage = () => {
  const isDevel = !process.env.NODE_ENV || process.env.NODE_ENV === "development";
  const history = useHistory();
  const { data: groups, status } = useQuery({
    queryKey: ["groups"],
    queryFn: async ({ signal }) => await availableGroups(),
  });
  const [groupValue, setGroupValue] = useState<string | undefined>();
  const [isValid, setIsValid] = useState(true);

  useEffect(() => {
    if (status === "success") {
      setGroupValue(groups?.[0].name);
    }
    return () => setGroupValue(undefined);
  }, [groups, status]);

  const validate = () => {
    if (groupValue) {
      setIsValid(true);
      history.push(`/${groupValue}`);
    } else {
      setIsValid(false);
    }
  };

  return (
    <LandingPage>
      <form onSubmit={validate}>
        <div className="input-group">
          {isDevel && groupValue ? (
            <select
              id="company"
              className="form-control"
              defaultValue={groups?.[0].name}
              onChange={(e) => setGroupValue(e.target.value)}
              onKeyDown={(e) => e.key === "Enter" && validate()}
            >
              {groups?.map((g, i) => (
                <option key={i} value={g.name}>
                  {g.name}
                </option>
              ))}
            </select>
          ) : (
            <input
              type="text"
              className={`form-control ${!isValid && "border-danger"}`}
              placeholder="Group Name"
              onChange={(e) => setGroupValue(e.target.value)}
            />
          )}
          <span className="input-group-btn">
            <button className="btn btn-default" type="submit" disabled={!groupValue}>
              Go!
            </button>
          </span>
        </div>
        {!isValid && <div className={styles.formfield_error}>A group name is required</div>}
      </form>
    </LandingPage>
  );
};
