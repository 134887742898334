import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { FormButtons } from "../../FormButtons";
import { FormHeader } from "../../FormHeader";
import { QuickAddChildrenProps } from "../common/QuickAddWrapper";
import { InputFormField } from "../../../formfields/InputFormField";
import { TextareaFormField } from "../../../formfields/TextareaFormField";
import { useQuickAddSubmit } from "../common/useQuickAddHooks";

import { SharedContent, sharedContentConstants } from "../../../../api/SharedContent";
import { DatasetsVirtualizedSelectForm } from "../../EntityForms/formsVirtualized/DatasetsVirtualizedSelectForm";
import { ToggleFormField } from "../../../formfields/ToggleFormField";

export const QuickAddSharedContentForm = ({ onCreate, onCancel }: QuickAddChildrenProps<SharedContent>) => {
  const { onSubmit, loading } = useQuickAddSubmit<SharedContent>({
    resource: sharedContentConstants.resource,
    onCreate: onCreate,
    onCancel: onCancel,
  });

  const SharedContentFormSchema = yup.object().shape({
    name: yup.string().required("A name is required"),
    datasets: yup.array().min(1, "At least one dataset is required").required("At least one dataset is required"),
  });

  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
  } = useForm<SharedContent>({
    defaultValues: {},
    resolver: yupResolver(SharedContentFormSchema),
  });

  return (
    <>
      <FormHeader title={`Add ${sharedContentConstants.entitySingular}`} subtitle={""} />
      <InputFormField id={"name"} label={"Name"} errors={errors} register={register} autoFocus={true} required={true} />
      <TextareaFormField id="publicNotes" label="Public Notes" errors={errors} register={register} />
      <InputFormField
        id={"password"}
        label={"Password"}
        errors={errors}
        register={register}
        required={false}
        type={"text"}
      />
      <TextareaFormField id="privateNotes" label="Private Notes" errors={errors} register={register} />
      <DatasetsVirtualizedSelectForm id="datasets" control={control} isMulti showControls horizontal />

      <ToggleFormField id={"allowContentDownload"} label="Allow Download" control={control} horizontal />

      <FormButtons
        onClose={onCancel}
        onSubmit={handleSubmit((entity) => onSubmit({ ...entity }))}
        disabled={isSubmitting}
        errors={errors}
        submitButtonLabel="Create"
        loading={loading}
      />
    </>
  );
};
