import { generateUid } from "../../tools/UID/UID";
import { argumentType, commandTypes, parameterTypes, trackListType } from "../PipelineTypes";
import { StackTraces } from "../StackTrace";
import { Empty } from "./Empty";

// type parameterType = { phase0: number; phase1: number; pivot: number; autophase: boolean };

export class PhaseNMRSpectrum extends Empty {
  id: string;
  name: string;
  readonly type = commandTypes.phaseNMRSpectrum;
  errors: StackTraces;
  warnings: StackTraces;

  parameterTypes = {
    autophase: parameterTypes.boolean,
    phase0: parameterTypes.float,
    phase1: parameterTypes.float,
    pivot: parameterTypes.float,
  };
  parameterSettings = {
    phase0: {
      name: "0<sup>th</sup> order correction",
      inputType: "range",
      min: -3.14159265358979323,
      max: 3.14159265358979323,
      value: 0,
    },
    phase1: {
      name: "1<sup>rd</sup> order correction",
      inputType: "range",
      min: -3.14159265358979323,
      max: 3.14159265358979323,
      value: 0,
    },
    pivot: { name: "Pivot point", inputType: "xPick", value: Infinity },
    autophase: { name: "Autophase (time consuming)", value: false },
  };
  // "parameter": {
  //   "phase0": { "min": -3.14159265358979323, "max": 3.14159265358979323, "value": 0 },
  //   "phase1": { "min": -3.14159265358979323, "max": 3.14159265358979323, "value": 0 },
  //   "pivot": { "inputType": "xPick", "value": "Infinity" },
  //   "autophase": true
  // },

  internalParameter: Record<string, any>;
  readonly input: argumentType[];
  readonly output: argumentType[];

  constructor() {
    super();
    this.id = generateUid();
    this.name = "Phase NMR spectrum";
    this.errors = new StackTraces();
    this.warnings = new StackTraces();
    this.internalParameter = {};
    this.input = [
      { name: "FID real", type: "1D_real" },
      { name: "FID imaginary", type: "1D_real" },
    ];
    this.output = [{ name: "Spectrum phased", type: "1D_real" }];
  }

  run(tracks: trackListType): trackListType {
    // return parameter?.trackList.value(tracks) ?? [];
    // if (!tracks?.[0]?.data || !tracks?.[1]?.data) {
    //   this.errors.create({
    //     id: this.id,
    //     component: "PhaseNMRSpectrum.run",
    //     message: `Some tracks do not contain any data.`,
    //   });
    //   return [];
    // }
    // const reIn: Data1DReal = tracks[0].data;
    // const imIn: Data1DReal = tracks[1].data;
    // // Phased angles --> phi0 = 1.4394129386274583° phi1 = 0.6737024125661° pivot = -1.0202417066321767

    // const parameter = tracks[0]?.internalParameter.NMR ?? {};
    // let { phase0, phase1, pivot, autophase } = this.parameter as parameterType;

    // const param: Record<string, any> = {};

    // // let phase = this.parameter as parameterType;
    // if (autophase) {
    //   const phase = acme(reIn, imIn, pivot);
    //   phase0 = phase.phi0;
    //   phase1 = phase.phi1;
    //   pivot = phase.pivot;
    //   autophase = false;

    //   param.phase0 = phase0;
    //   param.phase1 = phase1;
    //   param.pivot = pivot;
    //   param.autophase = autophase;

    //   // (this.internalParameter.phase0 as FloatParameter).value = phase0;
    //   // (this.internalParameter.phase1 as FloatParameter).value = phase1;
    //   // (this.internalParameter.pivot as FloatParameter).value = pivot;
    //   // (this.internalParameter.autophase as BooleanParameter).value = autophase;
    // }

    // if (!isFinite(pivot)) {
    //   pivot = parseFloat(parameter?.OFFSET ?? 0);
    //   param.pivot = pivot;
    //   // (this.internalParameter.pivot as FloatParameter).value = pivot;
    // }

    // this.parameter = param;

    // phaseSpectrum(reIn, imIn, reIn.y, phase0, phase1, pivot);

    // // console.log("track", auto);

    // // phasedSpec.xmin = xmin;
    // // phasedSpec.xmax = xmax;
    // tracks[0].label = "Spectrum";

    return [tracks[0]];
  }

  // serialize(): any {
  //   const t = super.serialize();
  //   console.log("test", t);
  //   return t;
  // }
}
