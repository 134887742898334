import BwipJs from "bwip-js/dist/bwip-js.js";
import { v4 as uuidv4 } from "uuid";

import { CSSProperties, useEffect, useRef } from "react";
import { FormFieldLayout } from "../../formfields/FormFieldLayouts";
import { UseFormRegister } from "react-hook-form";

interface BarcodeProps {
  bcid?: BwipJs.RenderOptions["bcid"]; // see https://github.com/metafloor/bwip-js/wiki/BWIPP-Barcode-Types
  text: BwipJs.RenderOptions["text"]; // Text to encode
  scale?: BwipJs.RenderOptions["scale"]; // 3x scaling factor
  width?: BwipJs.RenderOptions["width"]; // Bar width, in millimeters
  height?: BwipJs.RenderOptions["height"]; // Bar height, in millimeters
  includetext?: BwipJs.RenderOptions["includetext"]; // Show human-readable text
  textxalign?: BwipJs.BwippOptions["textxalign"]; // Always good to set this
  canvasStyle?: CSSProperties;
  style?: CSSProperties;
}
export const Barcode = ({
  bcid = "datamatrix",
  text,
  scale = 3,
  width = 8,
  height = 8,
  includetext = true,
  textxalign = "center",
  canvasStyle,
  style,
}: BarcodeProps) => {
  const canvas = useRef<HTMLCanvasElement | null>(null);
  const id = uuidv4();
  useEffect(() => {
    try {
      // The return value is the canvas element
      canvas.current = BwipJs.toCanvas(id, {
        bcid: bcid, // Barcode type
        text: text, // Text to encode
        scale: scale, // 3x scaling factor
        width: width, // Bar width, in millimeters
        height: height, // Bar height, in millimeters
        includetext: includetext, // Show human-readable text
        textxalign: textxalign, // Always good to set this
        barcolor: "#2b4865", // Bar color obsidian
      });
    } catch (e) {
      // `e` may be a string or Error object
    }
    return () => {
      canvas.current = null;
    };
  }, [bcid, height, id, includetext, scale, text, textxalign, width]);

  return (
    <div className="flex" style={{ width: "100%", height: "100%", ...style }} title={text}>
      <canvas id={id} style={canvasStyle} />
    </div>
  );
};

interface BarcodeFormFieldProps extends BarcodeProps {
  id: string;
  register: UseFormRegister<any>;
  label: string;
  horizontal?: boolean;
  toggleControllerContainerStyle?: CSSProperties;
  wrapperContainerstyle?: CSSProperties;
  children?: React.ReactNode;
}

export const BarcodeFormField = (props: BarcodeFormFieldProps) => {
  return (
    <FormFieldLayout
      id={props.id}
      label={props.label}
      required={false}
      horizontal={props.horizontal}
      hasLabel={props.label !== ""}
    >
      <div
        className="flex row-nowrap align-center gap-5"
        style={{ justifyContent: "flex-end", ...props.wrapperContainerstyle }}
      >
        <Barcode {...props} />
        <input {...props.register(props.id)} type="checkbox" checked hidden />
        {props.children ? <div className="flex row-nowrap align-center gap-5">{props.children}</div> : null}
      </div>
    </FormFieldLayout>
  );
};
