import React, { CSSProperties, useCallback, useRef, useState } from "react";
import styles from "./styles.module.css";
import { Portal } from "../../helperfunctions/Portal";
import { LucideIcon } from "../../icon/LucideIcon";

// This is a custom dropdown that creates a portal and fixed positions the dropdown
// We need this as some components, e.g. react-select render dropdowns in it's own portals and we can't accurately detect outside clicks
// Closing of this dropdown is accomplished via a backdrop that is stacked behind the dropdown

interface ExclusiveDropdownProps {
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  drop?: "down-left" | "down-right" | "up-left" | "up-right";
  btnCls?: string;
  btnLabel?: string;
  title?: string;
  icon?: React.ReactNode;
  disabled?: boolean;
  onClose?: () => void;
  triggerComponent?: (onToggleDropdown: (e: React.MouseEvent) => void) => React.ReactNode;
  triggerstyle?: CSSProperties;
  children: React.ReactNode;
  backdropStyle?: CSSProperties;
}
export const ExclusiveDropdown = ({
  show,
  setShow,
  drop = "down-right",
  btnCls = "btn btn-sm btn-ghost-dark",
  btnLabel = "",
  title = "More",
  icon,
  disabled = false,
  onClose,
  triggerComponent,
  triggerstyle,
  backdropStyle,
  children,
}: ExclusiveDropdownProps) => {
  const navbarHeight = 58;
  const parentNode = useRef<HTMLDivElement>(null);
  const [adjustedHeight, setAdjustedHeight] = useState<number>();
  const getDropDownStyle = useCallback(
    (node: HTMLDivElement) => {
      const rect = node.getBoundingClientRect();
      // console.log("RECT", rect);
      switch (drop) {
        case "down-left":
          return { top: rect.top + rect.height - navbarHeight, left: rect.x };
        case "down-right":
          return { top: rect.bottom - navbarHeight, right: window.innerWidth - rect.right };
        case "up-left":
          return { bottom: window.innerHeight - rect.top, left: rect.x };
        case "up-right":
          return { bottom: window.innerHeight - rect.top, right: window.innerWidth - rect.right };
        default:
          return {};
      }
    },
    [drop]
  );

  const correctParentHeight = useCallback((node: HTMLDivElement) => {
    if (node) {
      const rect = node.getBoundingClientRect();
      if (rect.bottom > window.innerHeight) {
        setAdjustedHeight(rect.bottom);
      }
    }
  }, []);

  const onToggle = useCallback(
    (e: React.MouseEvent) => {
      onClose?.();
      setShow((prevState) => !prevState);
      e.preventDefault();
      e.stopPropagation();
    },
    [onClose, setShow]
  );

  return (
    <>
      <div className={styles.exclusive_dropdown_container} ref={parentNode} style={triggerstyle}>
        {triggerComponent ? (
          triggerComponent(onToggle)
        ) : (
          <button
            className={`${btnCls} ${disabled ? "disabled" : ""}`}
            title={title}
            onClick={onToggle}
            disabled={disabled}
          >
            {icon ?? <LucideIcon name="ellipsis-vertical" />} {btnLabel}
          </button>
        )}
      </div>

      {show && parentNode.current && (
        <Portal>
          <div
            className={styles.backdrop}
            style={{ ...backdropStyle }}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setShow(false);
            }}
          >
            <div
              className={styles.exclusive_dropdown_wrapper_flexible}
              style={{ height: adjustedHeight ?? "calc(100vh - 50px)" }}
            >
              <div
                className={styles.exclusive_dropdown}
                style={{ ...getDropDownStyle(parentNode.current) }}
                ref={correctParentHeight}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
              >
                {children}
              </div>
            </div>
          </div>
        </Portal>
      )}
    </>
  );
};
