import { CSSProperties, useCallback } from "react";
import styles from "./CheckboxFormField.module.css";
import { LucideIcon } from "../../icon/LucideIcon";
import { Control, Controller, DeepMap, FieldError } from "react-hook-form";
import { FormFieldError, FormFieldLayout } from "../FormFieldLayouts";

interface CheckboxProps {
  checked: boolean;
  indeterminate?: boolean;
  onChange?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>, checked: boolean) => void;
  disabled?: boolean;
  style?: CSSProperties;
}
export const CheckboxRound = ({ checked, indeterminate, onChange, disabled, style }: CheckboxProps) => {
  const handleChange = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      if (!disabled) {
        onChange?.(e, !checked);
      }
    },
    [checked, disabled, onChange]
  );

  return (
    <div
      className={`${styles.checkbox} ${checked ? styles.checkboxChecked : ""} ${
        disabled ? styles.checkboxDisabled : ""
      } ${indeterminate ? styles.checkboxIndeterminate : ""}`}
      onClick={handleChange}
      style={style}
    >
      {indeterminate ? <LucideIcon name="minus" /> : checked && <LucideIcon name="check" />}
    </div>
  );
};

export const CheckboxSquare = ({ checked, indeterminate, onChange, disabled, style }: CheckboxProps) => {
  const handleChange = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      if (!disabled) {
        onChange?.(e, !checked);
      }
    },
    [checked, disabled, onChange]
  );

  return (
    <div
      className={`${styles.checkboxSquare} ${checked ? styles.checkboxSquareChecked : ""} ${
        disabled ? styles.checkboxSquareDisabled : ""
      } ${indeterminate ? styles.checkboxIndeterminate : ""}`}
      onClick={handleChange}
      style={style}
    >
      {indeterminate ? <LucideIcon name="minus" /> : checked && <LucideIcon name="check" />}
    </div>
  );
};

interface CheckboxFieldProps extends CheckboxFormFieldProps {
  value: boolean;
  onChange: (...event: any[]) => void;
  children?: React.ReactNode;
}
const CheckboxField = (props: CheckboxFieldProps) => {
  const fieldPath = props.id.split(".");
  let error: any = undefined;
  for (const path of fieldPath) {
    if (!error) error = props.errors?.[path];
    else error = error?.[path];
  }

  if (props.uncontained) {
    return (
      <div
        className={`flex align-center justify-center`}
        style={{ width: "100%", height: "100%", ...props.wrapperContainerstyle }}
      >
        {props.checkboxStyle === "round" ? (
          <CheckboxRound {...props} checked={props.value} onChange={(e, value) => props.onChange(value)} />
        ) : (
          <CheckboxSquare {...props} checked={props.value} onChange={(e, value) => props.onChange(value)} />
        )}
        {props.children ? props.children : null}
        <FormFieldError id={props.id} errors={props.errors} />
      </div>
    );
  } else {
    return (
      <FormFieldLayout
        id={props.id}
        label={props.label}
        required={props.required}
        horizontal={props.horizontal}
        hasLabel={props.label !== ""}
      >
        <div
          className={`flex row-nowrap align-center gap-5 `}
          style={{ justifyContent: "flex-end", ...props.wrapperContainerstyle }}
        >
          {props.checkboxStyle === "round" ? (
            <CheckboxRound {...props} checked={props.value} onChange={(e, value) => props.onChange(value)} />
          ) : (
            <CheckboxSquare {...props} checked={props.value} onChange={(e, value) => props.onChange(value)} />
          )}
          {props.children ? (
            <div className="flex row-nowrap align-center gap-5" style={{ marginLeft: "auto" }}>
              {props.children}
            </div>
          ) : null}
        </div>
        <FormFieldError id={props.id} errors={props.errors} />
      </FormFieldLayout>
    );
  }
};

export interface CheckboxFormFieldProps extends Omit<CheckboxProps, "checked" | "onChange"> {
  id: string;
  checkboxStyle: "round" | "square";
  control: Control<any>;
  label: string;
  errors?: DeepMap<any, FieldError>;
  required?: boolean;
  horizontal?: boolean;
  uncontained?: boolean;
  toggleControllerContainerStyle?: CSSProperties;
  wrapperContainerstyle?: CSSProperties;
  disabled?: boolean;
  children?: React.ReactNode;
}
export const CheckboxFormField = (props: CheckboxFormFieldProps) => {
  return (
    <>
      <Controller
        control={props.control}
        name={props.id}
        render={({ field: { onChange, value } }) => (
          <div style={{ whiteSpace: "nowrap", ...props.toggleControllerContainerStyle }}>
            <CheckboxField {...props} value={value} onChange={onChange} children={props.children} />
          </div>
        )}
      />
    </>
  );
};
