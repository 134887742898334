import { useState } from "react";
import { useHistory } from "react-router-dom";
import { DetailsPageTopControlsView } from "../common/panels/misc/DetailsPageTopControlsView/DetailsPageTopControlsView";
import { LucideIcon } from "../common/icon/LucideIcon";
import { NotSet } from "../common/misc/UIconstants";
import { AlertModal } from "../common/modals/AlertModal/AlertModal";
import { showtoast } from "../common/overlays/Toasts/showtoast";
import Detail from "../common/panels/Detail/Detail";
import { Table } from "../common/panels/Detail/DetailTable";
import { MoreDropdown } from "../common/buttons/MoreDropdown/MoreDropdown";
import { DateTimeRenderer } from "../common/datetime/DateTimeFormatter";
import { ExperimentsTile } from "../common/sidebar/tiles/ExperimentsTile";
import { DatasetsTile } from "../common/sidebar/tiles/DatasetsTile";
import { EntityDetailProps, EntityDetailTableProps } from "../common/entity/EntityInterfaces";
import { GetPersons } from "../common/misc/EntityRenders/EntityRenderer";
import { CustomFieldReferencesTile } from "../common/sidebar/tiles/CustomFieldReferencesTile";

export const MethodsDetailTable = ({ entity, entityConstants, fieldLabels }: EntityDetailTableProps<"methods">) => {
  return (
    <>
      <Table noPadding nonFoldable>
        <Table.Head>Basic details</Table.Head>
        <Table.Body>
          <Table.Body.RowContent
            title={fieldLabels.name}
            content={
              <div className="container_label">
                <div className="container_label_name" style={{ fontWeight: 600 }}>
                  {entity.name ?? NotSet}
                </div>
                <div className="container_label_id">
                  <span style={{ whiteSpace: "nowrap" }}>Method-ID: {entity.id}</span>
                </div>
              </div>
            }
          />

          <Table.Body.RowContent title={fieldLabels.fullName} content={entity.fullName} />

          <Table.Body.RowContent
            title={fieldLabels.description}
            content={
              <>
                {entity.description ? (
                  <div className="container_notes">
                    <textarea rows={4} className="container_textarea" value={entity?.description} disabled />
                  </div>
                ) : (
                  NotSet
                )}
              </>
            }
          />

          <Table.Body.RowContent
            title={fieldLabels.createdOn}
            content={
              <div className="flex align-center gap-5" style={{ width: "100%", flexWrap: "wrap" }}>
                <div style={{ maxWidth: "max-content", width: "100%" }}>
                  <DateTimeRenderer date={entity.createdOn} includeElapsed={false} />
                </div>
                <div className="flex row-nowrap align-center gap-5">
                  by <GetPersons persons={[entity.createdBy]} />
                </div>
              </div>
            }
          />
          <Table.Body.RowContent
            title={fieldLabels.modifiedOn}
            content={
              <div className="flex align-center gap-5" style={{ width: "100%", flexWrap: "wrap" }}>
                <div style={{ maxWidth: "max-content", width: "100%" }}>
                  <DateTimeRenderer date={entity.modifiedOn} includeElapsed={false} />
                </div>
                <div className="flex row-nowrap align-center gap-5">
                  by <GetPersons persons={[entity.modifiedBy]} />
                </div>
              </div>
            }
          />
        </Table.Body>
      </Table>
    </>
  );
};

export const MethodsDetail = ({
  permissions,
  routes,
  entity,
  entityApi,
  entityConstants,
  fieldLabels,
}: EntityDetailProps<"methods">) => {
  let history = useHistory();
  const [showModal, setShowModal] = useState<boolean>(false);

  return (
    <Detail
      head={
        <DetailsPageTopControlsView
          entity={entity}
          entityConstants={entityConstants}
          controls={
            <>
              <button
                className="btn btn-default"
                onClick={() => history.push(routes.getEditLink(entity.id))}
                disabled={!permissions.canEdit(entity)}
              >
                <LucideIcon name="square-pen" />
                <span> Edit</span>
              </button>
              <MoreDropdown drop="right" btn="btn btn-ghost-secondary">
                <li>
                  <button
                    className="btn btn-sm btn-ghost-secondary"
                    title={`Clone this Method`}
                    onClick={() => history.push(routes.getCloneLink(entity.id))}
                    disabled={!permissions.canCreate}
                  >
                    <LucideIcon name="copy" /> Clone
                  </button>
                </li>
                <li>
                  <button
                    className="btn btn-sm btn-ghost-danger"
                    title="Delete"
                    onClick={() => setShowModal(true)}
                    disabled={!permissions.canDelete(entity)}
                  >
                    <LucideIcon name="trash-2" /> Delete
                  </button>
                </li>
              </MoreDropdown>
              <AlertModal
                type="danger"
                showModal={showModal}
                setShowModal={setShowModal}
                title="Permanently delete method?"
                description="This method may be referenced by other entities. Deleting a method is only possible if all references are removed."
                proceedLabel="Delete"
                onProceed={async () => {
                  await entityApi
                    .deleteMutationAsync(
                      {
                        id: entity.id,
                        goBackOnSuccess: false,
                        showToast: false,
                        entityName: "method",
                        params: { deletePermanently: true },
                      },

                      {
                        onSuccess: () => {
                          showtoast("success", `Deleted Method-ID: ${entity.id}.`);
                          history.goBack();
                        },
                      }
                    )
                    .catch((e) => {});
                }}
                loading={entityApi.isLoadingDeleteMutation}
              />
            </>
          }
        />
      }
      mainStyle={{ padding: "0px 10px" }}
      main={<MethodsDetailTable entity={entity} entityConstants={entityConstants} fieldLabels={fieldLabels} />}
      sidebar={
        <>
          {!!entity.relations?.datasets.count && (
            <DatasetsTile defaultFilters={{ methodIds: [entity.id] }} excludeFilters={{ methodIds: () => false }} />
          )}
          {!!entity.relations?.experiments.count && (
            <ExperimentsTile defaultFilters={{ methodIds: [entity.id] }} excludeFilters={{ methodIds: () => false }} />
          )}
          <CustomFieldReferencesTile entityType="Method" entity={entity} />

          {!entity.relations?.datasets.count && <DatasetsTile disabled />}
          {!entity.relations?.experiments.count && <ExperimentsTile disabled />}
        </>
      }
    />
  );
};
