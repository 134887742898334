import React from "react";
import ResizePanel from "../../modules/react-resize-panel.new/src/ResizePanel";
import { AutoResizeDiv } from "./AutoResizeDiv";
import styles from "./ResizeLayout.module.css";

export const HorizontalResizeDiv = ({
  width,
  height,
  children,
  rightWidth,
}: {
  width?: number;
  height?: number;
  rightWidth?: number;
  children: any;
}) => {
  if (React.Children.count(children) !== 2)
    throw new Error(
      `HorizontalResizeDiv expect exactly two (left & right) children (got ${React.Children.count(children)}).`
    );

  const [left, right] = children;

  if (width === undefined || height === undefined || width < 2 || height < 2) return <></>;

  return (
    <div className={styles.horizontal} style={{ width: width || 0, height: height || 0 }}>
      <div className={styles.content}>
        <AutoResizeDiv>{left}</AutoResizeDiv>
      </div>
      <ResizePanel
        direction="w"
        style={{ width: rightWidth ? rightWidth : width ? width / 2 : 100, minWidth: 1 }}
        // handleClass={styles.verticalHandle}
        handleClass={"verticalHandle"}
        borderClass={"verticalResizeBorder"}
      >
        <AutoResizeDiv className={styles.content}>{right}</AutoResizeDiv>
      </ResizePanel>
    </div>
  );
};
