import { useContext, useState } from "react";
import { EntityDetailProps, EntityDetailTableProps } from "../common/entity/EntityInterfaces";
import { toUppercase } from "../common/helperfunctions/stringFunctions";
import { LucideIcon } from "../common/icon/LucideIcon";
import { GetProjects } from "../common/misc/EntityRenders/EntityRenderer";
import { NotSet } from "../common/misc/UIconstants";
import { AlertModal } from "../common/modals/AlertModal/AlertModal";
import { showtoast } from "../common/overlays/Toasts/showtoast";
import Detail from "../common/panels/Detail/Detail";
import { Table } from "../common/panels/Detail/DetailTable";
import { DetailsPageTopControlsView } from "../common/panels/misc/DetailsPageTopControlsView/DetailsPageTopControlsView";
import { DownloadDataset } from "../Dataset/common/DownloadDataset/DownloadDataset";
import { Viewer } from "../ViewerLayout/ViewerLayout";
import { SessionContext } from "../common/contexts/SessionContext";
import { useHistory } from "react-router-dom";
import { MoreDropdown } from "../common/buttons/MoreDropdown/MoreDropdown";
import { Files } from "../Dataset/tiles/Files/Files";
import { CustomFieldReferencesTile } from "../common/sidebar/tiles/CustomFieldReferencesTile";
import { Panel, PanelGroup } from "react-resizable-panels";
import { CustomResizeHandle } from "../common/panels/misc/CustomResizeHandle";
import { EntityRestoreButton } from "../common/entity/entityComponents/EntityRestoreButton";

export const AttachmentsDetailTable = ({
  entity: dataset,
  entityConstants,
  fieldLabels,
}: EntityDetailTableProps<"attachments">) => {
  return (
    <>
      <Table noPadding nonFoldable>
        <Table.Head>Basic details</Table.Head>
        <Table.Body>
          <Table.Body.RowContent
            title={fieldLabels.name}
            content={
              <div className="container_label">
                <div className="container_label_name" style={{ fontWeight: 600 }}>
                  {dataset.name ?? NotSet}
                </div>
                <div className="container_label_id">
                  <span style={{ whiteSpace: "nowrap" }}>
                    {toUppercase(entityConstants.entitySingular)}-ID: {dataset.id}
                  </span>
                </div>
              </div>
            }
          />
          <Table.Body.RowContent
            title={fieldLabels.projects}
            content={<GetProjects projects={dataset.projects} createLinks />}
          />

          <Table.Body.RowContent title={fieldLabels.notes} content={dataset.notes ?? NotSet} />
        </Table.Body>
      </Table>
    </>
  );
};

export const AttachmentsDetail = ({
  entity,
  entityApi,
  entityConstants,
  fieldLabels,
  permissions,
  routes,
}: EntityDetailProps<"attachments">) => {
  const { api, session } = useContext(SessionContext);
  const history = useHistory();

  const [showModal, setShowModal] = useState<boolean>(false);

  const { deleteMutationAsync, isLoadingDeleteMutation } = entityApi;
  return (
    <Detail
      head={
        <DetailsPageTopControlsView
          entity={entity}
          entityConstants={entityConstants}
          attributes={
            <>
              {entity.isDeleted && (
                <h2 style={{ margin: 0 }}>
                  <label className="label label-sm label-soft-warning" style={{ margin: 0 }} title={"Trashed"}>
                    <LucideIcon name="trash" />
                  </label>
                </h2>
              )}
            </>
          }
          controls={
            <>
              {entity.isDeleted ? (
                <EntityRestoreButton
                  entity={entity}
                  entityConstants={entityConstants}
                  entityApi={entityApi}
                  permissions={permissions}
                />
              ) : (
                <>
                  {entity.viewableEntityType === "ELN" ? (
                    <>
                      <DownloadDataset
                        btnStyle="btn btn-sm btn-primary"
                        id={entity.id}
                        label={`Download ${entityConstants.entitySingular}`}
                        api={api}
                      />
                      <button
                        className="btn btn-default"
                        onClick={() => history.push(routes.getEditLink(entity.id))}
                        disabled={!permissions.canEdit(entity)}
                      >
                        <LucideIcon name="square-pen" />
                        <span> Edit</span>
                      </button>
                      <MoreDropdown drop="right" btn="btn btn-ghost-secondary">
                        <li>
                          <button
                            className={`btn btn-sm btn-ghost-${entity.isDeleted ? "danger" : "warning"}`}
                            title={`${entity.isDeleted ? "Delete" : "Move to trash"}`}
                            onClick={() => setShowModal(true)}
                            disabled={entity.isDeleted ? !permissions.canDelete(entity) : !permissions.canEdit(entity)}
                          >
                            <LucideIcon name="trash-2" /> {`${entity.isDeleted ? "Delete" : "Trash"}`}
                          </button>
                        </li>
                      </MoreDropdown>
                    </>
                  ) : (
                    <>
                      <DownloadDataset
                        btnStyle="btn btn-sm btn-primary"
                        id={entity.id}
                        label={`Download ${entityConstants.entitySingular}`}
                        api={api}
                      />
                      <button
                        className={`btn btn-sm btn-soft-${entity.isDeleted ? "danger" : "warning"}`}
                        title={`${entity.isDeleted ? "Delete" : "Move to trash"}`}
                        onClick={() => setShowModal(true)}
                        disabled={entity.isDeleted ? !permissions.canDelete(entity) : !permissions.canEdit(entity)}
                      >
                        <LucideIcon name="trash-2" /> {`${entity.isDeleted ? "Delete" : "Trash"}`}
                      </button>
                    </>
                  )}
                </>
              )}
              <AlertModal
                type={`${entity.isDeleted ? "danger" : "warning"}`}
                showModal={showModal}
                setShowModal={setShowModal}
                title={`${entity.isDeleted ? "Permanently delete item?" : "Move to trash?"}`}
                description={
                  entity.isDeleted
                    ? "This item may be referenced by other entities. Deleting a item is only possible if all references are removed."
                    : "This will move the item into trash. It is possible to restore this item at any time."
                }
                proceedLabel={entity.isDeleted ? "Delete" : "Trash"}
                loading={isLoadingDeleteMutation}
                onProceed={async () => {
                  if (entity.isDeleted) {
                    await deleteMutationAsync(
                      {
                        id: entity.id,
                        goBackOnSuccess: false,
                        showToast: false,
                        entityName: "attachment",
                        params: { deletePermanently: true },
                      },

                      {
                        onSuccess: () => {
                          showtoast("success", `Deleted Attachment-ID: ${entity.id}.`);
                          history.goBack();
                        },
                      }
                    ).catch((e) => {});
                  } else {
                    await deleteMutationAsync(
                      { id: entity.id, goBackOnSuccess: false, showToast: false, entityName: "attachment" },

                      {
                        onSuccess: () => {
                          showtoast(
                            "success",
                            `Moved ${toUppercase(entityConstants.entitySingular)}-ID: ${entity.id} to trash.`
                          );
                          setShowModal(false);
                        },
                      }
                    ).catch((e) => {});
                  }
                }}
              />
            </>
          }
        />
      }
      // mainStyle={{ padding: "0px 10px" }}
      main={
        <div className="flex col-nowrap gap-5" style={{ width: "100%", height: "100%" }}>
          <PanelGroup direction="vertical">
            <Panel defaultSize={50} minSize={0} collapsible={true} style={{ padding: 5 }}>
              <Viewer
                ids={[entity.id]}
                settings={{
                  interactiveMode: true,
                  showParameter: true,
                  showNavigation: true,
                  showTrackList: true,
                }}
              />
            </Panel>
            <CustomResizeHandle orientation="vertical" />
            <Panel defaultSize={50} minSize={0} collapsible={true} style={{ padding: 1 }}>
              <Files dataset={entity} />
            </Panel>
          </PanelGroup>
        </div>
      }
      sidebar={
        <>
          <CustomFieldReferencesTile
            entityType="ViewableEntity"
            entity={entity}
            labNotebookEntryFilters={session?.features.eln ? { mediaIds: [entity.id] } : undefined}
          />
        </>
      }
    />
  );
};
