import { LucideIcon, IconNames } from "../../../common/icon/LucideIcon";
import styles from "./ELNNoContentContainer.module.css";

interface CreateContentButton {
  label: string;
  onClick: Function;
  icon?: IconNames;
  color?:
    | "primary"
    | "secondary"
    | "warning"
    | "danger"
    | "soft-primary"
    | "soft-secondary"
    | "soft-warning"
    | "soft-danger"
    | "ghost-primary"
    | "ghost-secondary"
    | "ghost-warning"
    | "ghost-danger";
  disabled?: boolean;
}

export const ELNNoContentContainer = ({
  noContentMessage,
  createButton,
}: {
  noContentMessage?: string;
  createButton?: CreateContentButton;
}) => {
  return (
    <div className={styles.ELNNoContentContainer}>
      <div className="flex col-nowrap align-center gap-5" style={{ height: "fit-content" }}>
        <div className={styles.ELNNoContentMessage}>{noContentMessage || "No content"}</div>
        {createButton && (
          <div className={styles.ELNNoContentCreateButtonContainer}>
            <button className={`btn btn-${createButton.color || "primary"}`} onClick={(e) => createButton.onClick(e)}>
              {createButton.icon && (
                <span>
                  <LucideIcon name={createButton.icon} />
                  <LucideIcon name="plus" style={{ width: "8px" }} />
                </span>
              )}
              <span> {createButton?.label}</span>
            </button>
          </div>
        )}
      </div>
    </div>
  );
};
