import React, { useCallback, useEffect, useState } from "react";
import { SerializedPipelineCommand } from "../../../ProcessingPipeline/PipelineTypes";
import styles from "./ProcessingNodeParameter.module.css";
import { clickType } from "../../../ViewerLayout/ViewerTypes";
import { pipelineNodeState } from "../../../ViewerLayout/ViewerLayoutTypes";
import { Label } from "react-bootstrap";
import { ShowTraces } from "./ShowTraces";
import { ProcessParameterInput } from "./ProcessParameterInput";

enum labelModes {
  none = "none",
  error = "error",
  parameter = "parameter",
  errorSwitcher = "errorSwitcher",
  parameterSwitcher = "parameterSwitcher",
}
type viewTypes = "error" | "parameter";

const MessageHint = ({
  warningCount,
  errorCount,
  setView,
}: {
  warningCount: number;
  errorCount: number;
  setView?: (view: viewTypes) => void;
}) => {
  const [mode, setMode] = useState<labelModes>(errorCount || warningCount ? labelModes.error : labelModes.none);

  useEffect(() => {
    setMode(errorCount || warningCount ? labelModes.error : labelModes.none);
  }, [errorCount, warningCount]);

  const transfer = useCallback(
    (state: labelModes, event: "enter" | "leave" | "click", setView: (view: viewTypes) => void): labelModes => {
      if (state === labelModes.error) {
        if (event === "enter") {
          setView("error");
          return labelModes.errorSwitcher;
        }
      }
      if (state === labelModes.errorSwitcher) {
        if (event === "leave") {
          setView("parameter");
          return labelModes.error;
        }
        if (event === "click") {
          setView("error");
          return labelModes.parameterSwitcher;
        }
      }
      if (state === labelModes.parameter) {
        if (event === "enter") {
          setView("parameter");
          return labelModes.parameterSwitcher;
        }
      }
      if (state === labelModes.parameterSwitcher) {
        if (event === "leave") {
          setView("error");
          return labelModes.parameter;
        }
        if (event === "click") {
          setView("parameter");
          return labelModes.errorSwitcher;
        }
      }
      return labelModes.none;
    },
    []
  );

  const button = useCallback(
    (mode: labelModes) => {
      let content: any = null;

      switch (mode) {
        case labelModes.none:
          break;
        case labelModes.error:
          content = (
            <div>
              {warningCount ? (
                <span>
                  <Label bsStyle="warning">{warningCount} Warnings</Label>&nbsp;
                </span>
              ) : null}
              {errorCount ? (
                <span>
                  <Label bsStyle="danger">{errorCount} Errors</Label>&nbsp;
                </span>
              ) : null}
            </div>
          );
          break;
        case labelModes.errorSwitcher:
          content = (
            <Label
              style={{
                background: "repeating-linear-gradient(-45deg, #ebb236, #ebb236 10px, #d35649 10px, #d35649 20px)",
                color: "black",
                fontWeight: "bold",
              }}
              className={styles.message}
            >
              Click to pin error/warning view
            </Label>
          );

          break;
        case labelModes.parameter:
          content = <Label bsStyle="default">Show parameter</Label>;
          break;
        case labelModes.parameterSwitcher:
          content = (
            <Label
              style={{
                background: "repeating-linear-gradient(-45deg, #787878, #787878 10px, #a7a7a7 10px, #a7a7a7 20px)",
                color: "black",
                fontWeight: "bold",
              }}
              className={styles.message}
            >
              Click to pin parameter view
            </Label>
          );
      }

      // console.log("has", content === null);
      return (
        <div
          onMouseEnter={() => setMode(transfer(mode, "enter", setView ?? (() => {})))}
          onMouseLeave={() => setMode(transfer(mode, "leave", setView ?? (() => {})))}
          onClick={() => setMode(transfer(mode, "click", setView ?? (() => {})))}
          style={{ maxWidth: "max-content" }}
        >
          {content}
        </div>
      );
    },
    [errorCount, warningCount, setView, transfer]
  );

  return <div style={{ height: 20 }}>{button(mode)}</div>;
};

// const supRegex = /<sup> /

export const ProcessingNodeParameter = ({
  node,
  width,
  height,
  graphClick,
  setParameter,
  nodeState,
  getCommandColor,
}: {
  node?: SerializedPipelineCommand;
  nodeState?: pipelineNodeState;
  width?: number;
  height?: number;
  graphClick: clickType;
  setParameter: (nodeId: string, parameter: Record<string, any>) => void;
  getCommandColor: (command?: string) => string;
}) => {
  // const [parameterViewer, setParameterViewer] = useState<any[]>();
  const [showMessages, setShowMessages] = useState<boolean>(false);
  const [test, setTest] = useState<[string, Record<string, any>]>();

  const setParameters = useCallback((parameterName: string, parameter: Record<string, any>) => {
    setTest([parameterName, parameter]);
    // console.log("parameter set", parameterName, "->", parameter);
  }, []);

  useEffect(() => {
    if (!test) return;
    const [parameterName, parameter] = test;
    if (!node?.parameter?.[parameterName]) return;
    const result: Record<string, any> = {};
    result[parameterName] = parameter;
    setParameter(node.id, result);
  }, [test, setParameter]);

  // const setParameters = useCallback(
  //   (parameterName: string, parameter: Record<string, any>) => {
  //     if (!node?.parameter?.[parameterName]) return;
  //     const result: Record<string, any> = {};
  //     result[parameterName] = parameter;
  //     setParameter(node.id, result);
  //   },
  //   [node, setParameter]
  // );

  // useEffect(() => {
  //   console.log("> ProcessingNodeParameter setParameter");
  // }, [setParameter]);

  // useEffect(() => {
  //   setNodeParameter(node?.parameter);
  //   console.log("node", needUpdate(nodeParameter, node?.parameter));
  //   // setNodeId(node?.id ?? "");
  // }, [node]);

  // useEffect(() => {
  //   if (node && nodeParameter && needUpdate(node.parameter, nodeParameter)) {
  //     setParameter(node.id, nodeParameter);
  //   }
  // }, [node?.parameter, nodeParameter]);

  // console.log("Node", node);

  if (node === undefined) return null;
  return (
    <div className={styles.main} style={{ height: height, width: width }}>
      <div className={styles.inner} style={{ height: (height ?? 25) - 25, width: (width ?? 20) - 20 }}>
        <div className={styles.head}>
          <span style={{ borderLeft: "7px solid " + getCommandColor(node?.command), paddingLeft: 3 }}>
            <b>{node?.name}</b>
            {/* <sup>
              <span className={"glyphicon glyphicon-stop"} style={{ color: getCommandColor(node?.command) }} />
            </sup> */}
            <br />
            <b>{node?.commandName ?? node?.command}</b>
          </span>
          {/* <MessageHint
            hasWarnings={nodeState?.warnings ? nodeState.warnings.length > 0 : false}
            hasErrors={nodeState?.errors ? nodeState.errors.length > 0 : false}
            setView={(view: string) => setShowMessages(view === "error")}
          /> */}

          <div className={styles.label}>
            <MessageHint
              warningCount={nodeState?.warnings.length ?? 0}
              errorCount={nodeState?.errors.length ?? 0}
              setView={(view: string) => setShowMessages(view === "error")}
            />
            {nodeState?.state === "ok" ? (
              <span>
                <Label bsStyle="success">execution OK</Label>&nbsp;
              </span>
            ) : null}
          </div>
        </div>
        <div className={styles.body}>
          {showMessages ? (
            <ShowTraces warnings={nodeState?.warnings} errors={nodeState?.errors} />
          ) : (
            // <ParameterViewer graphClick={graphClick} parameter={node?.parameter ?? {}} setParameter={setParameters} />
            <ProcessParameterInput
              graphClick={graphClick}
              parameter={node?.parameter ?? {}}
              setParameter={setParameters}
            />
          )}
        </div>
      </div>
    </div>
  );
};
