import React, { useState, useEffect, useCallback } from "react";
import { produce } from "immer";

import { CheckBoxSelectorDiv } from "../ViewerUIElements/CheckBoxSelector";
import { SelectButton } from "../ViewerUIElements/SelectButton";
import { useInputDebounce } from "../ViewerUIElements/InputDebounce";
import { Button, FormControl } from "react-bootstrap";
import { viewerStateType } from "./SequenceComponent";
import { sequenceTrackType } from "../ViewerLayout/ViewerTypes";
import { ExportModal } from "../SpectrumViewer/ViewerNavigation/ExportModal";

import styles from "./ViewerNavigation.module.css";

const SearchDiv = ({
  viewerState,
  setViewerState,
}: {
  viewerState: viewerStateType;
  setViewerState: (state: viewerStateType) => void;
}) => {
  const [searchSequence, setSearchSequence] = useState("");
  const debouncedSearchSequence = useInputDebounce(searchSequence, 500);

  useEffect(() => {
    setViewerState(
      produce(viewerState, (next) => {
        next.search.searchSequence = debouncedSearchSequence;
      })
    );
  }, [debouncedSearchSequence]);

  if (viewerState.sequenceType !== "nucleotide_sequence") return null;

  return (
    <div className={styles.viewerButtonColumn}>
      Search:
      <FormControl
        type="text"
        placeholder="Search sequence"
        onChange={(e) => setSearchSequence((e.target as HTMLInputElement).value)}
        // onChange={(e) => setSearchSequence((e.target as HTMLInputElement).value)}
      />
      <SelectButton
        active={viewerState.search.caseSensitive}
        setActive={(state: boolean) =>
          setViewerState(
            produce(viewerState, (next) => {
              next.search.caseSensitive = state;
            })
          )
        }
      >
        Case sensitive
      </SelectButton>
      <SelectButton
        active={viewerState.search.antisense}
        setActive={(state: boolean) =>
          setViewerState(
            produce(viewerState, (next) => {
              next.search.antisense = state;
            })
          )
        }
      >
        Antisense
      </SelectButton>
    </div>
  );
};

// import "./ViewerUILayout.css";
export const ViewerNavigation = function ({
  viewerState,
  setViewerState,
  onExport,
}: {
  viewerState: viewerStateType;
  setViewerState: (state: viewerStateType) => void;
  onExport: (type: string) => void;
}) {
  const [showExportModal, setShowExportModal] = useState<boolean>(false);

  // const [caseSensitive, setCaseSensitive] = useState(false);
  // const [antisense, setAntisense] = useState(false);
  // const [searchSequence, setSearchSequence] = useState("");
  // const [menuElements, setMenuElements] = useState<any[]>([]);
  // const [selectedNumber, setSelectedNumber] = useState(0);
  // const [decoration, setDecoration] = useState<Record<string, boolean>>({
  //   showORF: true,
  //   showRestriction: true,
  //   showMarker: true,
  // });
  // const [orf, setOrf] = useState({ orfAsNumber: true, orfAsLetter: false });
  // const debouncedSearchSequence = useInputDebounce(searchSequence, 500);

  // useEffect(() => {
  //   // if (viewerState.sequenceSearch) {
  //   //   // console.log("start highlighting", debouncedSearchSequence);
  //   //   // const count = viewerState.sequenceSearch(debouncedSearchSequence, caseSensitive);
  //   //   setSelectedNumber(viewerState.sequenceSearch(debouncedSearchSequence, caseSensitive, antisense));
  //   //   // console.log("viewer", selectedNumber);
  //   //   // viewerState.searchSequence(debouncedSearchSequence);
  //   // }
  //   setViewerState(
  //     produce(viewerState, (next) => {
  //       next.search.searchSequence = debouncedSearchSequence;
  //       next.search.caseSensitive = caseSensitive;
  //       next.search.antisense = antisense;
  //     })
  //   );
  // }, [debouncedSearchSequence, caseSensitive, antisense]);

  const elementProps: Record<sequenceTrackType, any> = {
    nucleotide_sequence: [
      {
        title: "Decoration:",
        multiselect: true,
        items: [
          { key: "showORF", text: "ORF" },
          { key: "showRestrictase", text: "Restrictase" },
          { key: "showMarker", text: "Marker" },
        ],
        state: viewerState,
        setState: setViewerState,
        // onClick: (state: Record<string, boolean>) => console.log("state:", state),
      },
      {
        title: "ORF:",
        multiselect: false,
        items: [
          { key: "showORFLetter", text: "AS letter" },
          { key: "showORFNumber", text: "AS number" },
        ],
        state: viewerState,
        setState: setViewerState,
        // onClick: (state: Record<string, boolean>) => console.log("state:", state),
      },
      {
        title: "Coloring:",
        multiselect: false,
        items: [{ key: "colorByBase", text: "By base" }],
        state: viewerState,
        setState: setViewerState,
        // onClick: (state: Record<string, boolean>) => console.log("state:", state),
      },
    ],
    protein_sequence: [],
  };

  // useEffect(() => {
  //   if (viewerState.sequenceType === "nucleotide_sequence") setMenuElements(elementProps);
  //   else setMenuElements([]);
  //   // setViewerState(
  //   //   produce(viewerState, (next) => {
  //   //     next.search.searchSequence = "";
  //   //   })
  //   // );
  // }, [viewerState.sequenceType]);

  // const elements = elementProps.map((props: any, i: number) => {
  //   return <VerticalSelector key={i} {...props} />;
  // });

  // useEffect(() => {
  //   // if (decoration && viewerState?.changeDecoration) {
  //   //   viewerState.changeDecoration(decoration);
  //   // }
  //   console.log("decoration", decoration);
  //   setViewerState(
  //     produce(viewerState, (next) => {
  //       Object.entries(decoration).forEach(([k, v]) => {
  //         // console.log("decoration", k, v);
  //         next.showORF = v;
  //         // next[k as any] = v;
  //       });
  //     })
  //   );
  //   // console.log("decoration", decoration);
  // }, [decoration]);

  // useEffect(() => {
  //   if (orf && viewerState) {
  //     // viewerState.changeDecoration(decoration);
  //     const next = produce(viewerState, (state: any) => {
  //       state.showORFLetter = orf.orfAsLetter;
  //     });
  //     setViewerState(next);
  //     // console.log("orf", orf);
  //   }
  // }, [orf]);

  // console.log("sequence", viewerState.sequenceType);

  return (
    // <div id="specviewer-buttons" className="viewer-top">
    <div>
      {/* <Button onClick={() => onExport("csv")}>Export CSV</Button> */}
      {/* <Button onClick={() => onExport("svg")}>Export SVG</Button> */}
      <ExportModal show={showExportModal} setShow={setShowExportModal} onExport={onExport} formats={["csv"]} />{" "}
      {/* svg is deactivated because its buggy */}
      <div className={styles.viewerButtonLine}>
        <div className={styles.viewerButtonRow}>
          {/* {elements} */}
          {/* <VerticalSelectorDiv elements={elementProps} onClick={(e:any) => console.log("vertical:", e)} /> */}
          <CheckBoxSelectorDiv elements={elementProps[viewerState.sequenceType]} />
          <SearchDiv viewerState={viewerState} setViewerState={setViewerState} />
          {/* <div className={styles.viewerButtonColumn}>
            Search:
            <FormControl
              type="text"
              placeholder="Search sequence"
              onChange={(e) => setSearchSequence((e.target as HTMLInputElement).value)}
            />
            <SelectButton active={caseSensitive} setActive={setCaseSensitive}>
              Case sensitive
            </SelectButton>
            <SelectButton active={antisense} setActive={setAntisense}>
              Antisense
            </SelectButton>
          </div> */}
          <div className={styles.viewerButtonColumn}>
            <span>&nbsp;</span>
            {viewerState.search.searchSequence.length > 0 ? viewerState.search.count + " results" : ""}
          </div>
        </div>
        <div className={styles.viewerButtonRow}>
          <Button
            bsSize="xs"
            // style={{ padding: 10, fontSize: 14 }}
            // style={{ padding: 5 }}
            style={{ marginBottom: "5px" }}
            onClick={useCallback(() => setShowExportModal(true), [])}
          >
            <span className="glyphicon glyphicon-export" /> Export
          </Button>
        </div>
      </div>
    </div>
  );
};
