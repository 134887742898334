import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { InputFormField } from "../../../formfields/InputFormField";
import { QuickAddChildrenProps } from "../common/QuickAddWrapper";
import { FormButtons } from "../../FormButtons";
import { FormHeader } from "../../FormHeader";
import { useQuickAddSubmit } from "../common/useQuickAddHooks";
import { SelectFormField } from "../../../formfields/SelectFormField";
import { TextareaFormField } from "../../../formfields/TextareaFormField";
import {
  LabNotebookExperiment,
  LabNotebookExperimentStatusList,
  labNotebookExperimentFieldLabels,
  labNotebookExperimentsConstants,
} from "../../../../ELN/types/LabNotebookExperiment";
import { useCallback } from "react";
import { LabNotebookExperimentFormValues } from "../../../../ELN/LabNotebookExperiments/Forms/LabNotebookExperimentForm";
import { LabNotebooksVirtualizedSelectForm } from "../../EntityForms/formsVirtualized/LabNotebooksVirtualizedSelectFormField";

export const QuickAddLabNotebookExperimentForm = ({
  onCreate,
  onCancel,
  initialValues,
}: QuickAddChildrenProps<LabNotebookExperiment>) => {
  const { onSubmit, loading } = useQuickAddSubmit<LabNotebookExperiment>({
    resource: labNotebookExperimentsConstants.resource,
    onCreate: onCreate,
    onCancel: onCancel,
  });

  const entityToFormValues = useCallback((entity?: Partial<LabNotebookExperiment>) => {
    return !!entity
      ? ({
          ...entity,
          status: entity.status
            ? {
                id: entity.status,
                name: entity.status.charAt(0) + entity.status.slice(1).toLowerCase(),
                value: entity.status,
              }
            : undefined,
        } as LabNotebookExperimentFormValues)
      : {};
  }, []);

  const FormSchema = yup.object().shape({
    labNotebook: yup.object().required("A lab notebook is required").typeError("A lab notebook is required"),
    name: yup
      .string()
      .required("Lab notebook experiment name is required")
      .typeError("Lab notebook experiment name is required"),
    status: yup.object().required("Lab notebook status is required").typeError("Lab notebook status is required"),
  });

  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
  } = useForm<LabNotebookExperimentFormValues>({
    defaultValues: entityToFormValues(initialValues),
    resolver: yupResolver(FormSchema),
  });

  const formValuesToEntity = useCallback(
    (entity: LabNotebookExperimentFormValues) =>
      onSubmit({ ...entity, status: entity.status.value } as LabNotebookExperiment),
    [onSubmit]
  );

  return (
    <>
      <FormHeader title="Add lab notebook experiment" />

      <LabNotebooksVirtualizedSelectForm
        id="labNotebook"
        label={labNotebookExperimentFieldLabels.labNotebook}
        control={control}
        showControls
        horizontal
        required
        allowCreateEntity
      />

      <InputFormField
        id="name"
        label={labNotebookExperimentFieldLabels.name}
        errors={errors}
        register={register}
        autoFocus={true}
        required
        placeholder="Enter lab notebook experiment name..."
      />

      <SelectFormField
        id="status"
        label={labNotebookExperimentFieldLabels.status}
        items={LabNotebookExperimentStatusList.map((s) => {
          return { id: s, name: s.charAt(0) + s.slice(1).toLowerCase(), value: s };
        })}
        control={control}
        required
        menuPortalTarget={document.body}
        errors={errors}
      />

      <TextareaFormField
        id="description"
        label={labNotebookExperimentFieldLabels.description}
        errors={errors}
        register={register}
        placeholder="Enter description..."
      />

      <FormButtons
        groupName={labNotebookExperimentsConstants.frontendIndexRoute}
        entityId={undefined}
        onClose={onCancel}
        onSubmit={handleSubmit(formValuesToEntity)}
        disabled={isSubmitting}
        errors={errors}
        disableDefaultOnClose
        submitButtonLabel="Create"
        loading={loading}
      />
    </>
  );
};
