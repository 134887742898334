import { OverlayTrigger, Popover } from "react-bootstrap";
import { LucideIcon } from "../../../../common/icon/LucideIcon";
import { CircularProgress } from "../../../../common/loaders/CircularProgress/CircularProgress";
import { DatasetResult, FormatFileEntryResult, MatchResultStats } from "../../FileFormatAutoDetection";
import styles from "./RenderDatasetUtils.module.css";
import React from "react";
import { LoadingStatus } from "../../FileHandlingUtils";
// import { ServerError } from "../../../../common/helperfunctions/ApiError";

export const renderFileStats = (files: FormatFileEntryResult[]) => {
  const _new = files.filter((d) => d.state && d.state === "NEW").length;
  const _known = files.filter((d) => d.state && d.state === "UNCHANGED").length;
  const _deleted = files.filter((d) => d.state && d.state === "DELETE").length;
  const _update = files.filter((d) => d.state && d.state === "NEEDSUPDATE").length;

  return (
    <>
      {!!_new && (
        <label className="label label-success" style={{ margin: 0 }}>
          <div className="flex row-nowrap align-center gap-5">
            New
            <span className="badge" style={{ background: "var(--white)", color: "var(--success)" }}>
              {_new}
            </span>
          </div>
        </label>
      )}

      {!!_update && (
        <label className="label label-warning" style={{ margin: 0 }}>
          <div className="flex row-nowrap align-center gap-5">
            Modified
            <span className="badge" style={{ background: "var(--white)", color: "var(--warning)" }}>
              {_update}
            </span>
          </div>
        </label>
      )}
      {!!_deleted && (
        <label className="label label-danger" style={{ margin: 0 }}>
          <div className="flex row-nowrap align-center gap-5">
            Deleted
            <span className="badge" style={{ background: "var(--white)", color: "var(--danger)" }}>
              {_deleted}
            </span>
          </div>
        </label>
      )}
      {!!_known && (
        <label className="label label-default" style={{ margin: 0 }}>
          <div className="flex row-nowrap align-center gap-5">
            Unchanged
            <span className="badge" style={{ background: "var(--white)", color: "var(--dark)" }}>
              {_known}
            </span>
          </div>
        </label>
      )}
    </>
  );
};

const OverlayWrapper = ({ id, message, children }: { id: string; message: string; children: React.ReactNode }) => {
  return (
    <OverlayTrigger placement="right" overlay={<Popover id={id}>{message}</Popover>}>
      <span style={{ width: "min-content", height: "min-content" }}>{children}</span>
    </OverlayTrigger>
  );
};

export const getStatusIndication = (dataset: DatasetResult) => {
  if (dataset.queued && dataset.status && !["idle", "uploading"].includes(dataset.status)) {
    return (
      <OverlayWrapper id={dataset.id} message={"Queued dataset for upload"}>
        <div className={`label label-soft-dark ${styles.render_dataset_status}`}>
          <LucideIcon name="clock" color={"var(--black)"} /> Queued
        </div>
      </OverlayWrapper>
    );
  } else {
    switch (dataset.status) {
      case "error":
        return (
          <OverlayWrapper id={dataset.id} message={dataset.error ?? ""}>
            <div className={`label label-soft-danger ${styles.render_dataset_status}`}>
              <LucideIcon name="triangle-alert" color={"var(--danger)"} /> Error
            </div>
          </OverlayWrapper>
        );
      case "warning":
        return (
          <OverlayWrapper id={dataset.id} message={dataset.error ?? ""}>
            <div className={`label label-soft-warning ${styles.render_dataset_status}`}>
              <LucideIcon name="circle-alert" color={"var(--warning)"} /> Warning
            </div>
          </OverlayWrapper>
        );
      case "created":
        return (
          <OverlayWrapper id={dataset.id} message={"Uploaded successfully"}>
            <div className={`label label-soft-primary ${styles.render_dataset_status}`}>
              <LucideIcon name="check" color={"var(--primary)"} /> Uploaded
            </div>
          </OverlayWrapper>
        );
      case "updated":
        return (
          <OverlayWrapper id={dataset.id} message={"Updated successfully"}>
            <div className={`label label-soft-primary ${styles.render_dataset_status}`}>
              <LucideIcon name="check" color={"var(--primary)"} /> Updated
            </div>
          </OverlayWrapper>
        );
      case "idle":
        return (
          <OverlayWrapper id={dataset.id} message={"Processing dataset..."}>
            <div className={`label label-light ${styles.render_dataset_status}`}>
              <div className="spinner spinner-sm" style={{ width: 14, height: 14 }} /> Idle
            </div>
          </OverlayWrapper>
        );
      case "known":
        return (
          <OverlayWrapper id={dataset.id} message={"This dataset exists already"}>
            <div className={`label label-soft-primary ${styles.render_dataset_status}`}>
              <LucideIcon name="check" color={"var(--primary)"} /> Known
            </div>
          </OverlayWrapper>
        );
      case "uploading":
        return (
          <OverlayWrapper id={dataset.id} message={"Uploading dataset..."}>
            <div className={`label label-light ${styles.render_dataset_status}`}>
              <CircularProgress width={14} progress={dataset.progress ?? 0} /> Uploading
            </div>
          </OverlayWrapper>
        );
      case "new":
        return (
          <OverlayWrapper id={dataset.id} message={"Upload as a new dataset"}>
            <div className={`label label-soft-success ${styles.render_dataset_status}`}>
              <LucideIcon name="arrow-up" color={"var(--success)"} /> New
            </div>
          </OverlayWrapper>
        );
      case "update":
        return (
          <OverlayWrapper id={dataset.id} message={"Update existing dataset"}>
            <div className={`label label-soft-warning ${styles.render_dataset_status}`}>
              <LucideIcon name="git-merge" color={"var(--warning)"} /> Update
            </div>
          </OverlayWrapper>
        );
      default:
        return <></>;
    }
  }
};

// Render bundle utils
export const renderBundleStats = (stats: MatchResultStats) => {
  return (
    <React.Fragment>
      {stats.new > 0 && (
        <div className="flex row-nowrap align-center gap-5 label label-soft-success" style={{ margin: 0 }}>
          <LucideIcon name="arrow-up" /> New
          <span className="badge" style={{ background: "var(--white)", color: "var(--success)" }}>
            {stats.new}
          </span>
        </div>
      )}
      {stats.update > 0 && (
        <div className="flex row-nowrap align-center gap-5 label label-soft-warning" style={{ margin: 0 }}>
          <LucideIcon name="git-merge" />
          {stats.update === 1 ? "Update" : "Updates"}
          <span className="badge" style={{ background: "var(--white)", color: "var(--warning)" }}>
            {stats.update}
          </span>
        </div>
      )}
      {stats.success > 0 && (
        <div className="flex row-nowrap align-center gap-5 label label-soft-primary" style={{ margin: 0 }}>
          <LucideIcon name="check" /> Uploaded
          <span className="badge" style={{ background: "var(--white)", color: "var(--primary)" }}>
            {stats.success}
          </span>
        </div>
      )}
      {stats.updated > 0 && (
        <div className="flex row-nowrap align-center gap-5 label label-soft-primary" style={{ margin: 0 }}>
          <LucideIcon name="check" /> Updated
          <span className="badge" style={{ background: "var(--white)", color: "var(--primary)" }}>
            {stats.updated}
          </span>
        </div>
      )}
      {stats.pending > 0 && (
        <div className="flex row-nowrap align-center gap-5 label label-soft-dark" style={{ margin: 0 }}>
          <LucideIcon name="clock" /> Queued
          <span className="badge" style={{ background: "var(--white)", color: "var(--secondary)" }}>
            {stats.pending}
          </span>
        </div>
      )}
      {stats.known > 0 && (
        <div className="flex row-nowrap align-center gap-5 label label-soft-primary" style={{ margin: 0 }}>
          <LucideIcon name="check" /> Known
          <span className="badge" style={{ background: "var(--white)", color: "var(--primary)" }}>
            {stats.known}
          </span>
        </div>
      )}
      {stats.warning > 0 && (
        <div className="flex row-nowrap align-center gap-5 label label-soft-warning" style={{ margin: 0 }}>
          <LucideIcon name="circle-alert" />
          {stats.warning === 1 ? "Warning" : "Warnings"}
          <span className="badge" style={{ background: "var(--white)", color: "var(--warning)" }}>
            {stats.warning}
          </span>
        </div>
      )}
      {stats.error > 0 && (
        <div className="flex row-nowrap align-center gap-5 label label-soft-danger" style={{ margin: 0 }}>
          <LucideIcon name="triangle-alert" />
          {stats.error === 1 ? "Error" : "Errors"}
          <span className="badge" style={{ background: "var(--white)", color: "var(--danger)" }}>
            {stats.error}
          </span>
        </div>
      )}
    </React.Fragment>
  );
};

export const progressBar = (loadingStatus: LoadingStatus | undefined) => (
  <div
    className="flex col-nowrap align-center justify-center "
    style={{
      width: "100%",
      height: "54px",
      color: "var(--gray-500)",
      background: "rgba(65, 75, 90, 0.1)",
      borderRadius: "4px",
      flexShrink: 0,
      flexGrow: 0,
      overflow: "hidden",
    }}
  >
    <div className="flex row-nowrap align-center gap-5" style={{ height: "100%", margin: "auto 0px" }}>
      <div className="spinner" />
      {loadingStatus && loadingStatus.message}
    </div>
    {loadingStatus?.progress !== undefined && (
      <div
        className="flex progress skeleton-block"
        style={{ width: "100%", height: "5px", marginTop: "auto", marginBottom: "0px" }}
      >
        <div
          className="progress-bar"
          role="progressbar"
          style={{
            ...(loadingStatus.progress > 0 && { width: `${loadingStatus.progress}%` }),
            background: "var(--success)",
          }}
          aria-valuenow={loadingStatus.progress}
          aria-valuemin={0}
          aria-valuemax={100}
        />
      </div>
    )}
  </div>
);
