import {
  CSSProperties,
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { FixedSizeList } from "react-window";
import InfiniteLoader from "react-window-infinite-loader";
import { useInfiniteListEntity } from "../api/BaseEntityApi";
import { IEntityMinimalModel, IPaginationParameters } from "../api/GenericTypes";
import { Person } from "../api/Person";
import { SessionContext } from "../common/contexts/SessionContext";
import { SearchInput } from "../common/forms/SearchInput/SearchInput";
import { useDebouncedValue } from "../common/helperfunctions/useDebouncedValue";
import { Skeleton } from "../common/loaders/Skeleton/Skeleton";
import { Alert } from "../common/overlays/Alert/Alert";
import styles from "./ProjectPersonPermissions.module.css";
import { Link } from "react-router-dom";
import { getDetailLink } from "../main/Routing";
import { LucideIcon } from "../common/icon/LucideIcon";

export interface ProjectPersonPermission {
  administer: boolean;
  edit: boolean;
  add: boolean;
  read: boolean;
  person: IEntityMinimalModel<number>;
  project?: IEntityMinimalModel<number>;
}

export interface ProjectPersonPermissionParameters extends IPaginationParameters {
  searchTerm?: string | null;
}

const ITEM_SIZE = 35;
const PAGE_SIZE = 10;

export const ProjectPersonPermissions = ({ person }: { person: Person }) => {
  const { session, route } = useContext(SessionContext);

  const [searchValue, setSearchValue] = useState<string>("");
  const debouncedSearchValue = useDebouncedValue(searchValue, 300);

  const { data, fetchNextPage, hasNextPage, isFetching, count } = useInfiniteListEntity<
    ProjectPersonPermission,
    ProjectPersonPermissionParameters
  >(
    `persons/${person?.id || 0}/projectPermissions` as any,
    { page: 1, pageSize: PAGE_SIZE, searchTerm: debouncedSearchValue, includeCount: true },
    { enabled: !!person?.id },
    "post"
  );

  const personProjectPermissions = useMemo(() => {
    return data?.pages.map((d) => d.results).flat() || [];
  }, [data?.pages]);

  const fetchNext = useCallback(() => {
    if (hasNextPage) {
      fetchNextPage();
    }
  }, [fetchNextPage, hasNextPage]);

  const loadMoreCallback = useCallback(() => {}, []);
  const itemCount = hasNextPage ? personProjectPermissions.length + 1 : personProjectPermissions.length;
  const loadMoreItems = isFetching ? loadMoreCallback : fetchNext;
  const isItemLoaded = useCallback(
    (index: number) => !hasNextPage || index < personProjectPermissions.length,
    [hasNextPage, personProjectPermissions.length]
  );
  const Item = ({ index, style }: { index: number; style: CSSProperties }) => {
    const current_element = personProjectPermissions[index];
    if (!current_element)
      return (
        <div style={{ ...style }} key={index}>
          <div className="label label-danger">Permission not found</div>
        </div>
      );
    if (!current_element.project) return null;
    if (!isItemLoaded(index)) {
      return (
        <div style={{ ...style }} key={index}>
          <span className="skeleton-block btn-lg" style={{ height: 51 }} />
        </div>
      );
    } else {
      return (
        <div style={{ ...style }} key={index}>
          <Link to={route(getDetailLink("projects", current_element.project.id))} style={{ textDecoration: "none" }}>
            <ProjectPersonPermissionRow projectPersonPermission={current_element} />
          </Link>
        </div>
      );
    }
  };

  if (
    !session?.permissions.administer_projects &&
    !session?.permissions.administer_persons &&
    !session?.permissions.logsadmin &&
    !session?.permissions.view_admin_pages &&
    session?.userId !== person.id
  )
    return <Alert type="danger" message={"You don't have permissions to view this page."} fit centered />;
  return (
    <div className={styles.PersonProjectPermissionsContainer}>
      <div className={styles.PersonProjectPermissionsTable}>
        <ProjectPersonPermissionHeader count={count} searchValue={searchValue} setSearchValue={setSearchValue} />

        {personProjectPermissions && personProjectPermissions.length > 0 ? (
          <InfiniteLoader isItemLoaded={isItemLoaded} itemCount={itemCount} loadMoreItems={loadMoreItems as any}>
            {({ onItemsRendered, ref }) => (
              <FixedSizeList
                itemCount={itemCount}
                onItemsRendered={onItemsRendered}
                ref={ref}
                width="100%"
                height={
                  personProjectPermissions.length > PAGE_SIZE
                    ? PAGE_SIZE * ITEM_SIZE
                    : personProjectPermissions.length * ITEM_SIZE
                }
                itemSize={ITEM_SIZE}
              >
                {Item}
              </FixedSizeList>
            )}
          </InfiniteLoader>
        ) : (
          <>
            {isFetching ? (
              <Skeleton type="rows" />
            ) : (
              <>
                {debouncedSearchValue ? (
                  <Alert type="light" message={`No results for: "${debouncedSearchValue}"`} fit centered />
                ) : (
                  <Alert type="light" message="No project permissions" fit centered />
                )}
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export const ProjectPersonPermissionHeader = ({
  count,
  searchValue,
  setSearchValue,
  target = "projects",
}: // onReset,
// onDelete,
{
  count?: number;
  searchValue: string;
  setSearchValue: Dispatch<SetStateAction<string>>;
  target?: "projects" | "persons";
  // onReset?: () => void;
  // onDelete?: () => void;
}) => {
  return (
    <div className={styles.PersonProjectPermissionsTableHeader}>
      <div className={styles.PersonProjectPermissionsTableProjectLabel}>
        <div className="flex align-center gap-5">
          <div style={{ fontWeight: 600 }}>{target === "persons" ? "Persons" : "Projects"}</div>
          {count !== undefined && <span className="badge">{count}</span>}
          <div style={{ width: "100%", padding: "0 20px" }}>
            <SearchInput
              searchValue={searchValue}
              setSearchValue={setSearchValue}
              placeholder={`Search ${target === "persons" ? "persons" : "projects"}...`}
            />
          </div>
        </div>
      </div>
      <div className={styles.PersonProjectPermissionsHeaderCheckboxLabel}>Admin</div>
      <div className={styles.PersonProjectPermissionsHeaderCheckboxLabel}>Edit</div>
      <div className={styles.PersonProjectPermissionsHeaderCheckboxLabel}>Add</div>
      <div className={styles.PersonProjectPermissionsHeaderCheckboxLabel}>Read</div>

      {/* {!!onReset ? (
        <button title="Reset all" type="button" className="btn btn-xs btn-ghost-warning" onClick={() => onReset?.()}>
          <FeatherIcon name="refresh-ccw" />
        </button>
      ) : (
        <div />
      )} */}

      {/* {!!onDelete ? (
        <button
          title="Remove"
          type="button"
          className="btn btn-ghost-danger btn-xs"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            onDelete();
          }}
        >
          <FeatherIcon name="x" />
        </button>
      ) : (
        <div />
      )} */}
    </div>
  );
};

export const ProjectPersonPermissionRow = ({
  initialProjectPersonPermission,
  projectPersonPermission,
  target = "projects",
  label,
  onChange,
  onDelete,
}: {
  target?: "persons" | "projects";
  initialProjectPersonPermission?: ProjectPersonPermission;
  projectPersonPermission: ProjectPersonPermission;
  label?: ReactNode;
  onChange?: ({
    administer,
    add,
    edit,
    read,
  }: {
    administer: boolean;
    add: boolean;
    edit: boolean;
    read: boolean;
  }) => void;
  onDelete?: () => void;
}) => {
  const [initialAdminister] = useState<boolean>(
    !!initialProjectPersonPermission ? initialProjectPersonPermission.administer : projectPersonPermission.administer
  );
  const [initialAdd] = useState<boolean>(
    !!initialProjectPersonPermission ? initialProjectPersonPermission.add : projectPersonPermission.add
  );
  const [initialEdit] = useState<boolean>(
    !!initialProjectPersonPermission ? initialProjectPersonPermission.edit : projectPersonPermission.edit
  );
  // const [initialRead] = useState<boolean>(projectPersonPermission.read);

  const [administer, setAdminister] = useState<boolean>(projectPersonPermission.administer);
  const [add, setAdd] = useState<boolean>(projectPersonPermission.add);
  const [edit, setEdit] = useState<boolean>(projectPersonPermission.edit);
  // const [read, setRead] = useState<boolean>(projectPersonPermission.read);

  const permssions = useMemo(
    () => ({ administer, edit: administer || edit, add: administer || edit || add, read: true }),
    [add, administer, edit]
  );

  useEffect(() => {
    if (
      projectPersonPermission.administer !== permssions.administer ||
      projectPersonPermission.add !== permssions.add ||
      projectPersonPermission.edit !== permssions.edit ||
      projectPersonPermission.read !== permssions.read
    ) {
      onChange && onChange(permssions);
    }
  }, [onChange, permssions, projectPersonPermission]);

  return (
    <div className={styles.PersonProjectPermissionsTableRow} style={{ height: ITEM_SIZE + "px" }}>
      <div className={styles.PersonProjectPermissionsTableProjectLabel}>
        {target === "persons" ? projectPersonPermission.person.name : projectPersonPermission.project?.name}
        {label && <span style={{ padding: "0 5px" }}>{label}</span>}
      </div>
      <div
        className={styles.PersonProjectPermissionsRowCheckbox}
        style={
          administer !== initialAdminister
            ? { border: "1px solid var(--success)", backgroundColor: "var(--success-light)" }
            : undefined
        }
      >
        <input
          type="checkbox"
          readOnly={!onChange}
          disabled={!onChange}
          onChange={() => {
            setAdminister((prevState) => {
              !prevState && setAdd(true);
              !prevState && setEdit(true);
              return !prevState;
            });
          }}
          checked={projectPersonPermission.administer}
        />
      </div>
      <div
        className={styles.PersonProjectPermissionsRowCheckbox}
        style={
          edit !== initialEdit
            ? { border: "1px solid var(--success)", backgroundColor: "var(--success-light)" }
            : undefined
        }
      >
        <input
          type="checkbox"
          readOnly={!onChange}
          disabled={!onChange}
          onChange={() => {
            setEdit((prevState) => {
              prevState && setAdminister(false);
              !prevState && setAdd(true);
              return !prevState;
            });
          }}
          checked={projectPersonPermission.edit}
        />
      </div>
      <div
        className={styles.PersonProjectPermissionsRowCheckbox}
        style={
          add !== initialAdd
            ? { border: "1px solid var(--success)", backgroundColor: "var(--success-light)" }
            : undefined
        }
      >
        <input
          type="checkbox"
          readOnly={!onChange}
          disabled={!onChange}
          onChange={() =>
            setAdd((prevState) => {
              prevState && setAdminister(false);
              prevState && setEdit(false);
              return !prevState;
            })
          }
          checked={projectPersonPermission.add}
        />
      </div>

      <div className={styles.PersonProjectPermissionsRowCheckbox}>
        <input
          type="checkbox"
          readOnly
          // readOnly={!onChange}
          disabled={!onChange}
          // onChange={() => setRead((prevState) => !prevState)}
          checked={projectPersonPermission.read}
        />
      </div>

      {/* {!!onChange ? (
        <button
          title="Reset"
          type="button"
          className="btn btn-ghost-warning btn-xs"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setAdminister(initialAdminister);
            setAdd(initialAdd);
            setEdit(initialEdit);
            // setRead(initialRead);
          }}
        >
          <FeatherIcon name="refresh-ccw" />
        </button>
      ) : (
        <div />
      )} */}

      {!!onDelete ? (
        <button
          title="Remove"
          type="button"
          className="btn btn-ghost-danger btn-xs"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            onDelete();
          }}
        >
          <LucideIcon name="x" />
        </button>
      ) : (
        <div />
      )}
    </div>
  );
};
