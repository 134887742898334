import { useRef, useCallback } from "react";

export const useDebounceAsyncVoidFunction = <A extends any[]>(
  func: (...params: A) => Promise<void>,
  timeout: number
) => {
  let timer: React.MutableRefObject<NodeJS.Timeout | undefined> = useRef();

  const debouncedFunction = useCallback(
    (...params: A) => {
      if (timer.current) {
        clearTimeout(timer.current);
      }
      const promiseForFunc = new Promise((resolve) => {
        timer.current = setTimeout(resolve, timeout);
      });

      return promiseForFunc.then(() => func(...params));
    },
    [func, timeout]
  );

  return debouncedFunction;
};
