import {
  CSSProperties,
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useEntityCount, useEntityDetail, useInfiniteListEntity, useUnpaginateOrdered } from "../api/BaseEntityApi";
import {
  hierarchyConstants,
  inventoriesConstants,
  InventoryItem,
  InventoryItemFilters,
  inventoryItemsConstants,
  InventoryItemSortingOptionsConsts,
} from "../api/Inventories";
import styles from "./InventoriesTree.module.css";
import { LucideIcon } from "../common/icon/LucideIcon";
import { LoadingWrapper } from "../common/LoadingWrapper";
import Detail from "../common/panels/Detail/Detail";
import { InventoriesDetail } from "./InventoryItemsDetail";
import { Alert } from "../common/overlays/Alert/Alert";
import { NotSet } from "../common/misc/UIconstants";
import { Button } from "../common/buttons/Button/Button";
import { Redirect, useParams } from "react-router-dom";
import { SearchInput } from "../common/forms/SearchInput/SearchInput";
import { CustomType, customTypeConstants, CustomTypeFilters } from "../api/CustomTypes";
import { QuickAddWrapper } from "../common/forms/QuickAdd/common/QuickAddWrapper";
import { QuickAddInventoryItemForm } from "../common/forms/QuickAdd/forms/QuickAddInventoryItemForm";
import { IEntityMinimalModel } from "../api/GenericTypes";
import { useDebouncedValue } from "../common/helperfunctions/useDebouncedValue";
import { OrderBySelectDropdown } from "../common/buttons/OrderBySelectDropdown/OrderBySelectDropdown";
import { EntityBreadcrumbs } from "../common/hierarchy/breadcrumbs/EntityBreadcrumbs";
import { SessionContext } from "../common/contexts/SessionContext";
import { getEditLink, getIndexRoute } from "../main/Routing";
import { MoreDropdown } from "../common/buttons/MoreDropdown/MoreDropdown";
import { ToggleButtonComponent } from "../ViewerUIElements/ToggleButtonComponent";
import { LinkButton } from "../common/buttons/LinkButton/LinkButton";
import { EntityDetailProps } from "../common/entity/EntityInterfaces";
import { InventoryItemTreePageProps } from "./InventoryItemViews";

interface OpenStateDictionary {
  [key: InventoryItem["id"]]: boolean;
}

export const InventoryItemTree = ({
  entity,
  customTypeIdOverride,
  entityApi,
  entityConstants,
  fieldLabels,
  permissions,
  routes,
}: Omit<EntityDetailProps<"inventoryItems">, "entity"> & InventoryItemTreePageProps) => {
  const { customTypeId } = useParams<{ customTypeId: string }>();
  const hierarchyTypeId = +(customTypeIdOverride ?? customTypeId ?? 0);
  const { route } = useContext(SessionContext);

  const [parent, setParent] = useState<InventoryItem>();
  const onSelectItem = useCallback(
    (item: InventoryItem) => {
      window.history.replaceState(
        null,
        document.title,
        `${route(getIndexRoute(inventoriesConstants.frontendIndexRoute))}/${hierarchyTypeId}/hierarchy/${item.id}`
      );
      setParent(item);
    },
    [hierarchyTypeId, route]
  );

  const initialOpenState: OpenStateDictionary | undefined = useMemo(
    () =>
      entity
        ? {
            ...entity.ancestors.reduce((acc, a) => ({ ...acc, [a.id]: true }), {}),
            [entity.id]: true,
          }
        : undefined,
    [entity]
  );

  useEffect(() => {
    if (entity) setParent(entity);
  }, [entity]);

  return (
    <Detail
      main={
        <InventoryItemTreeSelectWidget
          hierarchyTypeId={hierarchyTypeId}
          selectedItems={parent ? [parent] : undefined}
          onSelectItem={onSelectItem}
          initialOpenState={initialOpenState}
        />
      }
      mainMinSize={25}
      mainDefaultSize={25}
      mainStyle={{ margin: 0, paddingRight: "10px", overflow: "auto" }}
      sidebar={
        parent ? (
          <InventoriesDetail
            entity={parent}
            entityApi={entityApi}
            entityConstants={entityConstants}
            fieldLabels={fieldLabels}
            permissions={permissions}
            routes={routes}
            hideDirectChildrenTile
            hideSameTypeTile
            useQuickAdd
          />
        ) : (
          // <div style={{ paddingTop: "10px" }}>
          //   <Alert type="light" fit message="Select item to view details..." />
          // </div>
          <div className="flex col-nowrap align-center justify-center" style={{ width: "100%", height: "100%" }}>
            <div className="flex row-nowrap align-center gap-5" style={{ color: "var(--gray-200)" }}>
              <LucideIcon name="arrow-left" style={{ width: "3vw", height: "3vw" }} />
              <span style={{ fontSize: "3vw", margin: 0 }}>Select an item to view details</span>
            </div>
          </div>
        )
      }
      sidebarMinSize={10}
      sidebarStyle={{ margin: 0, padding: 0 }}
      sidebarContentStyle={{ margin: 0, padding: "0 0px", backgroundColor: "var(--white)", height: "100%" }}
    />
  );
};

const Subtree = ({
  item,
  selectedItems,
  onSelectItem,
  filters,
  containerStyle,
  openStateDictionary,
  setOpenStateDictionary,
  hideAddButtons,
  selectableCustomTypeIds,
}: {
  item: InventoryItem;
  selectedItems?: InventoryItem[] | InventoryItem | null;
  onSelectItem?: (item: InventoryItem) => void;
  filters?: InventoryItemFilters;
  containerStyle?: CSSProperties;
  openStateDictionary?: OpenStateDictionary;
  setOpenStateDictionary?: Dispatch<SetStateAction<OpenStateDictionary>>;
  hideAddButtons?: boolean;
  selectableCustomTypeIds?: CustomType["id"][];
}) => {
  const { session } = useContext(SessionContext);
  const { data: countData } = useEntityCount<InventoryItemFilters>(
    inventoryItemsConstants.resource,
    { ...filters, searchTerm: undefined, childrenOfParentIds: [item.id], customTypeIds: undefined },
    { enabled: true }
  );

  const isOpen = !!countData?.count && (openStateDictionary?.[item.id] ?? false);
  const setIsOpen = useCallback(
    (isOpen: boolean) => {
      setOpenStateDictionary?.((prevState) => ({ ...prevState, [item.id]: isOpen }));
    },
    [item.id, setOpenStateDictionary]
  );
  useEffect(() => {
    if (openStateDictionary && !Object.hasOwn(openStateDictionary, item.id)) {
      setOpenStateDictionary?.((prevState) => ({ ...prevState, [item.id]: false }));
    }
  }, [item.id, openStateDictionary, setOpenStateDictionary]);

  const { data, hasNextPage, fetchNextPage, isFetching, status, fetchStatus, error } = useInfiniteListEntity<
    InventoryItem,
    InventoryItemFilters
  >(
    inventoryItemsConstants.resource,
    {
      ...filters,
      childrenOfParentIds: [item.id],
      customTypeIds: undefined,

      page: 1,
      pageSize: 50,
    },
    { enabled: isOpen },
    "post"
  );
  const items = useMemo(() => data?.pages.map((d) => d.results).flat() ?? [], [data]);
  const fetchNext = useCallback(() => {
    if (hasNextPage) {
      fetchNextPage();
    }
  }, [fetchNextPage, hasNextPage]);

  const loadMoreCallback = useCallback(() => {}, []);
  const loadMoreItems = isFetching ? loadMoreCallback : fetchNext;

  const [isQuickAddOpen, setIsQuickAddOpen] = useState<boolean>(false);
  const { data: validChildrenTypes } = useUnpaginateOrdered<CustomType, CustomTypeFilters>(
    customTypeConstants.resource,
    { entityTypes: ["Inventory"], parentTypeIds: item.customType?.id ? [item.customType.id] : [] },
    { enabled: !!item.isHierarchyItem, staleTime: 120000, cacheTime: 120000 }
  );
  const canHaveChildItems = validChildrenTypes && validChildrenTypes.length > 0;
  const validChildrenTypesWithPermission = useMemo(
    () =>
      validChildrenTypes?.filter((t) =>
        t.hasRestrictedAddPermission
          ? !!session?.permissions.add_admin_inventory_items
          : !!session?.permissions.add_inventory_items
      ) ?? [],
    [validChildrenTypes, session]
  );
  const canCreateChildItems = useMemo(
    () =>
      !!validChildrenTypes?.some((t) =>
        t.hasRestrictedAddPermission
          ? !!session?.permissions.add_admin_inventory_items
          : !!session?.permissions.add_inventory_items
      ),
    [validChildrenTypes, session]
  );

  const isSelected = useMemo(() => {
    if (selectedItems === null) return false;
    if (Array.isArray(selectedItems)) return !!selectedItems.find((i) => i.id === item.id);
    if (selectedItems && typeof selectedItems === "object" && Object.hasOwn(selectedItems, "id"))
      return selectedItems.id === item.id;

    return false;
  }, [selectedItems, item.id]);
  const isSelectable = useMemo(() => {
    return (
      !!item.customType?.id &&
      (!selectableCustomTypeIds?.length ||
        (!!selectableCustomTypeIds.length && selectableCustomTypeIds.includes(item.customType.id)))
    );
  }, [item.customType?.id, selectableCustomTypeIds]);

  if (fetchStatus !== "idle") return null;
  return (
    <LoadingWrapper status={status} fetchStatus={fetchStatus} error={error} type="skeleton-rows">
      <div className={styles.InventoriesSubTreeContainer} style={{ ...containerStyle }}>
        <div
          className={styles.InventoriesSubTreeLabelContainer}
          style={{
            backgroundColor: isSelectable ? (isSelected ? "var(--primary-light)" : undefined) : "var(--gray-100)",
            cursor: isSelectable ? "pointer" : "not-allowed",
          }}
          onClick={() => {
            if (isSelectable) onSelectItem?.(item);
          }}
          onDoubleClick={() => {
            !!countData?.count && setIsOpen(!isOpen);
            document.getSelection()?.removeAllRanges();
          }}
        >
          <div
            style={{
              visibility: !!countData?.count ? "visible" : "hidden",
              cursor: !!countData?.count ? "pointer" : "default",
              flexShrink: 0,
            }}
          >
            {isOpen ? (
              <LucideIcon
                name="chevron-down"
                color="var(--primary)"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  !!countData?.count && setIsOpen(false);
                }}
              />
            ) : (
              <LucideIcon
                name="chevron-right"
                color="var(--primary)"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  !!countData?.count && setIsOpen(true);
                }}
              />
            )}
          </div>
          <div style={{ flexShrink: 0 }}>
            <LucideIcon name={inventoryItemsConstants.icon} color="var(--primary)" />
          </div>
          <div
            className={styles.InventoriesSubTreeLabel}
            style={{ color: isSelected ? "var(--primary)" : undefined }}
            title={item.name}
          >
            {item.name}
            {item.isDeleted && (
              <>
                <span className="label label-soft-warning" title="Item is trashed">
                  <LucideIcon name="trash-2" color="var(--warning)" />
                </span>
              </>
            )}
          </div>
          <div className={styles.InventoriesSubTreeCustomType}>
            <span className={`label label-soft-info`}>{item.customType?.name ?? NotSet}</span>
          </div>
          <div title={canHaveChildItems ? `Number of items in ${item.name}` : undefined} style={{ flexShrink: 0 }}>
            <span className="badge" style={{ visibility: canHaveChildItems ? "visible" : "hidden" }}>
              {countData?.count ?? 0}
            </span>
          </div>
          <div className={`flex row-nowrap align-center gap-5 ${styles.InventoriesSubTreeControls}`}>
            {!hideAddButtons && (
              <div style={canHaveChildItems ? undefined : { visibility: "hidden", pointerEvents: "none" }}>
                <Button
                  onClick={(e) => {
                    setIsOpen(true);
                    e.preventDefault();
                    e.stopPropagation();
                    setIsQuickAddOpen(true);
                  }}
                  className="btn btn-xs btn-ghost-primary btn"
                  disabled={!canCreateChildItems}
                  title={
                    !!item
                      ? `Add ${inventoryItemsConstants.entitySingular} to ${item.name}`
                      : `Add ${inventoryItemsConstants.entitySingular}`
                  }
                >
                  <LucideIcon name={hierarchyConstants.childItemIcon} />
                </Button>
                <QuickAddWrapper showModal={isQuickAddOpen} setShowModal={setIsQuickAddOpen} onCreate={() => {}}>
                  {(quickAddProps) => (
                    <QuickAddInventoryItemForm
                      {...quickAddProps}
                      initialValues={{
                        parent: item as IEntityMinimalModel,
                        customType:
                          !!validChildrenTypes && validChildrenTypes.length === 1 ? validChildrenTypes[0] : undefined,
                      }}
                      customTypeFilters={{
                        entityTypes: ["Inventory"],
                        ids: validChildrenTypesWithPermission.map((t) => t.id),
                      }}
                      subtitle={
                        !!item
                          ? item.name.length > 30
                            ? `Parent: ${item.name.slice(0, 30)}...`
                            : `Parent: ${item.name}`
                          : undefined
                      }
                      onCreate={() => setIsQuickAddOpen(false)}
                      onCancel={() => setIsQuickAddOpen(false)}
                    />
                  )}
                </QuickAddWrapper>
              </div>
            )}
          </div>
        </div>
        {isOpen && (
          <div className={styles.InventoriesSubTreeListContainer}>
            {items.length > 0 ? (
              items?.map((i) => (
                <div id={`${i.id}`} key={i.id} className={styles.InventoriesSubTreeListItem}>
                  <Subtree
                    item={i}
                    selectedItems={selectedItems}
                    onSelectItem={onSelectItem}
                    openStateDictionary={openStateDictionary}
                    setOpenStateDictionary={setOpenStateDictionary}
                    hideAddButtons={hideAddButtons}
                    selectableCustomTypeIds={selectableCustomTypeIds}
                  />
                </div>
              ))
            ) : (
              <div className={styles.InventoriesSubTreeListItem}>
                <Alert
                  type="light"
                  fit
                  centered
                  message={
                    filters?.searchTerm
                      ? `No items for search term '${filters?.searchTerm}'`
                      : `No ${inventoryItemsConstants.entityPlural}`
                  }
                  style={{ margin: 0 }}
                />
              </div>
            )}
            {hasNextPage && (
              <div className={styles.InventoriesSubTreeLoadMoreButton} onClick={() => loadMoreItems()}>
                <LucideIcon name="chevron-down" color="var(--primary)" style={{ visibility: "hidden" }} />
                <LucideIcon name={inventoryItemsConstants.icon} color="var(--primary)" />
                ...
              </div>
            )}
          </div>
        )}
      </div>
    </LoadingWrapper>
  );
};

export const InventoryItemTreeSelectWidget = ({
  hierarchyTypeId: customTypeIdOverride,
  selectedItems,
  onSelectItem,
  hideAddButtons,
  selectableCustomTypeIds,
  initialOpenState,
  breadCrumbsIndexEntityFullRouteOverride,
  hideHeader,
}: {
  hierarchyTypeId: CustomType["id"];
  selectedItems?: InventoryItem[] | InventoryItem | null;
  onSelectItem?: (item: InventoryItem) => void;
  hideAddButtons?: boolean;
  selectableCustomTypeIds?: CustomType["id"][];
  initialOpenState?: OpenStateDictionary;
  breadCrumbsIndexEntityFullRouteOverride?: ReactNode;
  hideHeader?: boolean;
}) => {
  const { route, session } = useContext(SessionContext);
  const { customTypeId } = useParams<{ customTypeId: string }>();
  const hierarchyTypeId = +(customTypeIdOverride ?? customTypeId ?? 0);

  const { data: hierarchyType } = useEntityDetail<CustomType>(
    customTypeConstants.resource,
    +hierarchyTypeId,
    undefined,
    {
      enabled: !!hierarchyTypeId,
    }
  );

  const [searchValue, setSearchValue] = useState<string>("");
  const [filters, setFilters] = useState<InventoryItemFilters>({ orderBy: "NAME_ASC" });
  const [isQuickAddOpen, setIsQuickAddOpen] = useState<boolean>(false);
  const [openStateDictionary, setOpenStateDictionary] = useState<OpenStateDictionary>(initialOpenState ?? {});
  const debouncedSearchValue = useDebouncedValue(searchValue, 300);

  const { data, hasNextPage, fetchNextPage, isFetching, status, fetchStatus, error } = useInfiniteListEntity<
    InventoryItem,
    InventoryItemFilters
  >(
    inventoryItemsConstants.resource,
    {
      ...filters,
      customTypeIds: hierarchyTypeId ? [+hierarchyTypeId] : undefined,
      excludeHierarchyChildren: true,
      isHierarchyRoot: true,
      page: 1,
      pageSize: 50,
    },
    { enabled: !!hierarchyTypeId },
    "post"
  );
  const items = useMemo(() => data?.pages.map((d) => d.results).flat() ?? [], [data]);
  const fetchNext = useCallback(() => {
    if (hasNextPage) {
      fetchNextPage();
    }
  }, [fetchNextPage, hasNextPage]);

  const { data: countData } = useEntityCount<InventoryItemFilters>(
    inventoryItemsConstants.resource,
    { customTypeIds: hierarchyTypeId ? [+hierarchyTypeId] : undefined, excludeHierarchyChildren: true },
    { enabled: !!hierarchyTypeId }
  );
  const count = countData?.count;

  const loadMoreCallback = useCallback(() => {}, []);
  const loadMoreItems = isFetching ? loadMoreCallback : fetchNext;

  // Separate query for search term
  // State will be updated in the onSuccess callback of useUnpaginateOrdered
  useUnpaginateOrdered<InventoryItem, InventoryItemFilters>(
    inventoryItemsConstants.resource,
    {
      searchTerm: debouncedSearchValue,
      inventoryIds: hierarchyTypeId ? [+hierarchyTypeId] : undefined,
    },
    {
      enabled: !!hierarchyTypeId && !!debouncedSearchValue,
      onSuccess: (result) => {
        try {
          let openStateDict: OpenStateDictionary = {};
          result.forEach((i) => {
            openStateDict = { ...openStateDict, [i.id]: true };
            i.ancestors.forEach((a) => {
              openStateDict = { ...openStateDict, [a.id]: true };
            });
          });
          setOpenStateDictionary(openStateDict);
          setFilters((prev) => ({ ...prev, ids: Object.keys(openStateDict).map((key) => parseInt(key)) }));
        } catch (error) {
          console.error(error);
        }
      },
    },
    undefined,
    undefined,
    1000
  );

  // Separate query for initial selected items
  // State will be updated in the onSuccess callback of useUnpaginateOrdered
  const [initialSelectedIds] = useState<InventoryItem["id"][]>(
    selectedItems === null
      ? []
      : Array.isArray(selectedItems)
      ? selectedItems.map((s) => s.id)
      : selectedItems && typeof selectedItems === "object" && Object.hasOwn(selectedItems, "id")
      ? [selectedItems.id]
      : []
  );
  const [progress, setProgress] = useState<number>(0);
  const loadInitialState = !!initialSelectedIds.length && !debouncedSearchValue && !!hierarchyTypeId;
  useUnpaginateOrdered<InventoryItem, InventoryItemFilters>(
    inventoryItemsConstants.resource,
    {
      ids: initialSelectedIds,
      inventoryIds: hierarchyTypeId ? [+hierarchyTypeId] : undefined,
    },
    {
      enabled: loadInitialState,
      onSuccess: (result) => {
        try {
          let openStateDict: OpenStateDictionary = {};
          result.forEach((i) => {
            openStateDict = { ...openStateDict, [i.id]: true };
            i.ancestors.forEach((a) => {
              openStateDict = { ...openStateDict, [a.id]: true };
            });
          });
          setOpenStateDictionary(openStateDict);
        } catch (error) {
          console.error(error);
        }
      },
    },
    undefined,
    setProgress,
    1000
  );

  useEffect(() => {
    if (!debouncedSearchValue) {
      setFilters((prev) => ({ ...prev, ids: undefined }));
    }
  }, [debouncedSearchValue]);

  if (!hierarchyType) {
    return (
      <div style={{ padding: "10px", backgroundColor: "var(--white)", height: "100%" }}>
        <Alert type="light" fit centered message="Inventory not found..." />
      </div>
    );
  }
  if (hierarchyType.entityType !== "Inventory" || !hierarchyType.inventoryName) {
    return !!hierarchyType.rootHierarchy?.id ? (
      <Redirect
        to={`${route(getIndexRoute(inventoriesConstants.frontendIndexRoute))}/${
          hierarchyType.rootHierarchy?.id
        }/hierarchy`}
      />
    ) : (
      <div style={{ padding: "10px", backgroundColor: "var(--white)", height: "100%" }}>
        <Alert type="light" fit centered message="The selected item is no inventory type..." />
      </div>
    );
  }
  if (!hierarchyType.isHierarchyRoot) {
    return (
      <Redirect to={`${route(getIndexRoute(inventoriesConstants.frontendIndexRoute))}/${hierarchyType.id}/items`} />
    );
  }
  return (
    <div className={styles.InventoriesTreeContainer}>
      {!hideHeader && (
        <div
          className="flex align-center gap-5"
          style={{
            padding: "5px 10px",
            paddingBottom: "10px",
            borderBottom: "1px solid var(--gray-200)",
            flexShrink: 0,
          }}
        >
          {!!hierarchyType && (
            <EntityBreadcrumbs
              entity={{ ...hierarchyType, name: hierarchyType.inventoryName ?? "" }}
              breadcrumbs={[]}
              entityConstants={inventoriesConstants}
              indexEntityFullRouteOverride={breadCrumbsIndexEntityFullRouteOverride}
            />
          )}
          <div className="badge">{count}</div>
          <LinkButton
            className="btn btn-default"
            linkTo={route(getEditLink(customTypeConstants.frontendIndexRoute, customTypeId))}
            title={`Edit ${inventoriesConstants.entitySingular}`}
            disabled={!session?.permissions.administer_custom_field_schemas}
            style={{ marginLeft: "auto" }}
          >
            <LucideIcon name="square-pen" /> Edit
          </LinkButton>
        </div>
      )}
      <div className="flex row-nowrap align-center gap-5">
        <SearchInput
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          placeholder={`Search in ${hierarchyType.inventoryName}...`}
        />
        <OrderBySelectDropdown
          btnCls="btn btn-default"
          orderByOptions={InventoryItemSortingOptionsConsts.filter(
            (o) =>
              o !== "LAYOUT_ASC" && o !== "LAYOUT_DESC" && o !== "INVENTORY_NAME_ASC" && o !== "INVENTORY_NAME_DESC"
          )}
          currentFilters={filters}
          setCurrentFilters={setFilters}
          drop="right"
        />
        <MoreDropdown icon={<LucideIcon name="filter" />} btn="btn btn-default" drop="right" closeOnClickInside={false}>
          <label className="flex align-center gap-5 btn btn-ghost-secondary btn-sm">
            <ToggleButtonComponent
              checked={!!filters.includeSoftDeleted}
              setChecked={() =>
                setFilters((prevState) => ({ ...prevState, includeSoftDeleted: !prevState.includeSoftDeleted }))
              }
            >
              <></>
            </ToggleButtonComponent>
            Include trashed
          </label>
        </MoreDropdown>
      </div>
      <div className="flex col-nowrap" style={{ overflow: "auto", height: "fit-content", width: "100%" }}>
        <LoadingWrapper
          status={!loadInitialState || progress < 100 ? "loading" : status}
          fetchStatus={!loadInitialState || progress === 100 ? "idle" : "fetching"}
        >
          <LoadingWrapper
            status={status === "loading" ? "success" : status}
            fetchStatus={fetchStatus}
            error={error}
            type="skeleton-rows"
          >
            {!!items.length ? (
              items?.map((i) => (
                <div id={`${i.id}`} key={i.id} className={styles.InventoriesTreeListItem}>
                  <Subtree
                    item={i}
                    selectedItems={selectedItems}
                    onSelectItem={onSelectItem}
                    filters={filters}
                    openStateDictionary={openStateDictionary}
                    setOpenStateDictionary={setOpenStateDictionary}
                    hideAddButtons={hideAddButtons}
                    selectableCustomTypeIds={selectableCustomTypeIds}
                  />
                </div>
              ))
            ) : (
              <div className={styles.InventoriesTreeListItem}>
                <Alert
                  type="light"
                  fit
                  centered
                  message={`No ${inventoryItemsConstants.entityPlural} result for: ${debouncedSearchValue}`}
                  style={{ margin: 0 }}
                />
              </div>
            )}
            {hasNextPage && (
              <div className={styles.InventoriesTreeLoadMoreButton} onClick={() => loadMoreItems()}>
                <LucideIcon
                  name="chevron-down"
                  color="var(--primary)"
                  style={{ visibility: "hidden", pointerEvents: "none" }}
                />
                <LucideIcon name={inventoryItemsConstants.icon} color="var(--primary)" />
                ...
              </div>
            )}
          </LoadingWrapper>
        </LoadingWrapper>
      </div>
      {!hideAddButtons && (
        <div style={{ height: "fit-content", marginTop: "auto" }}>
          <Button
            onClick={() => setIsQuickAddOpen(true)}
            className="btn btn-soft-primary btn-block flex align-center gap-5"
            style={{ justifyContent: "center" }}
            disabled={
              !hierarchyType ||
              (hierarchyType.hasRestrictedAddPermission
                ? !session?.permissions.add_admin_inventory_items
                : !session?.permissions.add_inventory_items)
            }
          >
            <span title={`Add ${hierarchyType.name}`} style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
              <LucideIcon name={hierarchyConstants.childItemIcon} /> Add {hierarchyType.name}
            </span>
          </Button>
          <QuickAddWrapper showModal={isQuickAddOpen} setShowModal={setIsQuickAddOpen} onCreate={() => {}}>
            {(quickAddProps) => (
              <QuickAddInventoryItemForm
                {...quickAddProps}
                initialValues={{ customType: hierarchyType }}
                customTypeFilters={{
                  entityTypes: ["Inventory"],
                  hasRestrictedAddPermission: !session?.permissions.add_admin_inventory_items ? false : undefined,
                }}
                onCreate={() => setIsQuickAddOpen(false)}
                onCancel={() => setIsQuickAddOpen(false)}
              />
            )}
          </QuickAddWrapper>
        </div>
      )}
    </div>
  );
};
