import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { GenericEntity, ICustomTypedEntity, IdTypes, StringIndexedDict } from "../../../../api/GenericTypes";
import { FormProvider, ResolverResult, UseFormReturn, useForm } from "react-hook-form";
import { ResourceName } from "../../../../main/Routing";
import BrandLoader from "../../../loaders/Spinner/BrandLoader";
import { yupResolver } from "@hookform/resolvers/yup";
import { ObjectShape } from "yup";
import {
  ConsolidatedValues,
  generateBulkEditYupSchema,
  getValueOrDefault,
  multiEditInitialValues,
} from "./MultiEditUtils";
import { LucideIcon } from "../../../icon/LucideIcon";
import { useHistory } from "react-router-dom";
import * as yup from "yup";
import { Subscription } from "react-hook-form/dist/utils/createSubject";
import { useUnpaginate } from "../../../../api/BaseEntityApi";

export interface MultiEditMappedItemObject<Entity extends GenericEntity> {
  [id: IdTypes]: Entity;
}

interface MultiEditItemProviderProps<Entity extends GenericEntity & Partial<ICustomTypedEntity>> {
  resource: ResourceName;
  initItems?: MultiEditMappedItemObject<Entity>; // Custom injection of items; will disable fetch and use those instead
  ids: Entity["id"][];
  canAbort?: boolean; // Will add an abort button which sends an AbortSignal onClick
  beforeInit?: (data: MultiEditMappedItemObject<Entity>) => MultiEditMappedItemObject<Entity>; // Modify data before passing it to the children
  children: ({
    items,
    consolidatedValues,
  }: {
    items: MultiEditMappedItemObject<Entity>;
    consolidatedValues: Required<ConsolidatedValues<Entity>>;
    consolidatedCustomFieldValues?: Required<ConsolidatedValues<Entity>>;
    customFieldPlaceholders?: StringIndexedDict<string | undefined>;
  }) => React.ReactNode; // React.Node after the fetching is complete
  childrenOnLoad?: React.ReactNode; // This allows a custom React.Node during the fetching process (e.g. show additional buttons)
}
const MultiEditItemsProvider = <Entity extends GenericEntity & Partial<ICustomTypedEntity>>({
  resource,
  initItems,
  ids,
  canAbort,
  beforeInit,
  children,
  childrenOnLoad,
}: MultiEditItemProviderProps<Entity>) => {
  const history = useHistory();
  const [progress, setProgress] = useState(0);
  const [items, setItems] = useState<MultiEditMappedItemObject<Entity>>();

  const onProgress = useCallback((progress: number) => {
    setProgress(progress);
  }, []);

  const { data } = useUnpaginate<Entity>(
    resource,
    { ids: ids },
    { enabled: !initItems && Array.isArray(ids) && !!ids.length },
    1000,
    onProgress
  );

  useEffect(() => {
    if (initItems) {
      if (beforeInit) {
        setItems(beforeInit(initItems));
      } else {
        setItems(initItems);
      }
    } else {
      if (data) {
        if (beforeInit) {
          setItems(beforeInit(data));
        } else {
          setItems(data);
        }
      }
    }
    return () => {
      setItems(undefined);
    };
  }, [beforeInit, data, initItems]);

  const consolidatedValues = useMemo(() => (items ? multiEditInitialValues(items) : undefined), [items]);

  const consolidatedCustomFieldValues = useMemo(
    () =>
      items
        ? multiEditInitialValues(
            Object.fromEntries(
              Object.entries(items).map(([id, entity]) => [id, entity.customFields])
            ) as MultiEditMappedItemObject<Entity>
          )
        : undefined,
    [items]
  );

  const customFieldPlaceholders = useMemo(
    () =>
      consolidatedCustomFieldValues
        ? Object.fromEntries(
            Object.keys(consolidatedCustomFieldValues).map((key) => [
              key,
              getValueOrDefault((consolidatedCustomFieldValues as StringIndexedDict)[key]),
            ])
          )
        : undefined,
    [consolidatedCustomFieldValues]
  );

  return (
    <React.Fragment>
      {items && consolidatedValues ? (
        children({ items, consolidatedValues, consolidatedCustomFieldValues, customFieldPlaceholders })
      ) : (
        <div className="flex col-nowrap justify-center align-center gap-5" style={{ width: "100%", height: "100%" }}>
          <BrandLoader size={50} />
          <div className="flex col-nowrap align-center gap-5" style={{ width: 200 }}>
            Loading data...
            <div
              className="flex progress skeleton-block"
              style={{ width: "100%", height: "5px", marginTop: "auto", marginBottom: "0px" }}
            >
              <div
                className="progress-bar"
                role="progressbar"
                style={{
                  ...(progress > 0 && { width: `${progress}%` }),
                  background: "var(--success)",
                }}
                aria-valuenow={progress}
                aria-valuemin={0}
                aria-valuemax={100}
              />
            </div>
            {canAbort && (
              <button
                className="btn btn-sm btn-soft-danger"
                onClick={() => {
                  // abortController.abort();
                  history.goBack();
                }}
              >
                <LucideIcon name="x" /> Abort
              </button>
            )}
          </div>
          {childrenOnLoad}
        </div>
      )}
    </React.Fragment>
  );
};

// Custom Context
interface MultiEditFormValues<Entity extends GenericEntity> {
  consolidatedValues: Required<ConsolidatedValues<Entity>>;
  consolidatedCustomFieldValues?: Required<ConsolidatedValues<Entity>>;
  customFieldPlaceholders?: StringIndexedDict<string | undefined>;
}

const MultiEditFormValuesContext = React.createContext<any | undefined>(undefined);

export const useMultiEditFormValues = <Entity extends GenericEntity>() => {
  const context = useContext<MultiEditFormValues<Entity>>(MultiEditFormValuesContext);
  return context;
};

// MultiEditFormContextProvider
export type MultiEditFormContextProviderChildrenProps<Entity extends GenericEntity> = UseFormReturn<
  MultiEditMappedItemObject<Entity>
> & {
  isDirty: boolean;
  isError: boolean;
  nChanges: number;
  nErrors: number;
};

interface MultiEditFormContextProviderProps<Entity extends GenericEntity & Partial<ICustomTypedEntity>> {
  items: MultiEditMappedItemObject<Entity>; // items of type {[id: string] : Entity}[]
  consolidatedValues: Required<ConsolidatedValues<Entity>>;
  consolidatedCustomFieldValues?: Required<ConsolidatedValues<Entity>>;
  customFieldPlaceholders?: StringIndexedDict<string | undefined>;
  entityValidationSchema?: ObjectShape; // validation schema for a single entity
  isCustomSchemaEntity?: boolean; // This will trigger additional validation
  resolverCallback?: (
    schema: yup.ObjectSchema<any>
  ) => (
    data: MultiEditMappedItemObject<Entity>,
    context: any,
    options: any
  ) => Promise<ResolverResult<MultiEditMappedItemObject<Entity>>>; // Override the resolver by a function that returns a resolver, while injecting a generated schema
  children: React.ReactNode;
}

const MultiEditFormContextProvider = <Entity extends GenericEntity & Partial<ICustomTypedEntity>>({
  items,
  consolidatedValues,
  consolidatedCustomFieldValues,
  customFieldPlaceholders,
  entityValidationSchema,
  isCustomSchemaEntity,
  resolverCallback,
  children,
}: MultiEditFormContextProviderProps<Entity>) => {
  const defaults = useMemo(() => items, [items]);

  const formMethods = useForm<typeof items>({
    // mode: "all",
    // criteriaMode: "all",
    defaultValues: defaults as any,
    resolver:
      resolverCallback && entityValidationSchema
        ? resolverCallback(generateBulkEditYupSchema(items, entityValidationSchema))
        : entityValidationSchema
        ? yupResolver(generateBulkEditYupSchema(items, entityValidationSchema))
        : (undefined as any),
  });
  const { watch, trigger, clearErrors, reset } = formMethods;

  useEffect(() => {
    reset(defaults);
  }, [defaults, reset]);

  useEffect(() => {
    let subscription: Subscription;
    if (isCustomSchemaEntity) {
      subscription = watch((value, { name, type }) => {
        // We have to manually trigger the validation of customFields if type change
        // console.log(value, name, type);
        if (name) {
          const split = name?.split(".");
          if (split?.[1] === "customType" || split?.[1] === "customFields") {
            (async () => await trigger(`${split[0]!}.customFields` as any))();
          }
          // Clear customField errors if type is not set
          if (split?.[1] === "customType" && (value?.[split?.[0]] as Entity).customType === null) {
            // console.log("Clearing custom field errors");
            clearErrors(`${split[0]}.customFields` as any);
          }
        }
      });
    }
    return () => {
      // console.log("Unmounting -> unsubscribe");
      subscription?.unsubscribe();
    };
  }, [clearErrors, isCustomSchemaEntity, trigger, watch]);

  // const nChanges = Object.keys(dirtyFields).length;
  // const nErrors = Object.keys(errors).length;
  // const isError = !!nErrors;
  // const isDirty = !!nChanges;

  return (
    <MultiEditFormValuesContext.Provider
      value={{ consolidatedValues, consolidatedCustomFieldValues, customFieldPlaceholders }}
    >
      <FormProvider {...formMethods}>{children}</FormProvider>
    </MultiEditFormValuesContext.Provider>
  );
};

// MultiEditProvider
// This wrapper provides a FormContext , Pre-Fetched items and Metadata
interface MultiEditProviderProps<Entity extends GenericEntity & Partial<ICustomTypedEntity>>
  extends Omit<MultiEditItemProviderProps<Entity>, "children"> {
  initItems?: MultiEditItemProviderProps<Entity>["initItems"];
  entityValidationSchema?: MultiEditFormContextProviderProps<Entity>["entityValidationSchema"];
  beforeInit?: MultiEditItemProviderProps<Entity>["beforeInit"];
  isCustomSchemaEntity?: MultiEditFormContextProviderProps<Entity>["isCustomSchemaEntity"];
  resolverCallback?: MultiEditFormContextProviderProps<Entity>["resolverCallback"];
  children: ({
    items,
    consolidatedValues,
    consolidatedCustomFieldValues,
    customFieldPlaceholders,
  }: {
    items: MultiEditMappedItemObject<Entity>;
    consolidatedValues: Required<ConsolidatedValues<Entity>>;
    consolidatedCustomFieldValues?: Required<ConsolidatedValues<Entity>>;
    customFieldPlaceholders?: StringIndexedDict<string | undefined>;
  }) => React.ReactNode;
  childrenOnLoad?: MultiEditItemProviderProps<Entity>["childrenOnLoad"];
}
export const MultiEditProvider = <Entity extends GenericEntity & Partial<ICustomTypedEntity>>({
  resource,
  initItems,
  ids,
  entityValidationSchema,
  beforeInit,
  isCustomSchemaEntity,
  resolverCallback,
  children,
  childrenOnLoad,
}: MultiEditProviderProps<Entity>) => {
  return (
    <MultiEditItemsProvider<Entity>
      resource={resource}
      ids={ids}
      initItems={initItems}
      childrenOnLoad={childrenOnLoad}
      beforeInit={beforeInit}
    >
      {({ items, consolidatedValues, consolidatedCustomFieldValues, customFieldPlaceholders }) => (
        <MultiEditFormContextProvider<Entity>
          items={items}
          consolidatedValues={consolidatedValues}
          consolidatedCustomFieldValues={consolidatedCustomFieldValues}
          customFieldPlaceholders={customFieldPlaceholders}
          entityValidationSchema={entityValidationSchema}
          isCustomSchemaEntity={isCustomSchemaEntity}
          resolverCallback={resolverCallback}
        >
          {children({ items, consolidatedValues, consolidatedCustomFieldValues, customFieldPlaceholders })}
        </MultiEditFormContextProvider>
      )}
    </MultiEditItemsProvider>
  );
};
