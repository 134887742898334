import { generateUid } from "../tools/UID/UID";
import { initType, range, SerializedPipelineConnector, trackListType } from "./PipelineTypes";
import { StackTraces } from "./StackTrace";

export class PipelineConnector {
  name: string;
  id: string;
  previous?: string;
  next?: string;
  inputNumber: number;
  outputNumber: number;
  mapping: Record<number, number>;
  errors: StackTraces;
  warnings: StackTraces;
  cached: boolean = false;

  constructor(init?: PipelineConnector) {
    this.name = "Connector";
    this.id = generateUid();
    this.inputNumber = 0;
    this.outputNumber = 0;
    this.mapping = {};
    this.errors = new StackTraces();
    this.warnings = new StackTraces();

    initType(this, init);
    // if (init)
    //   for (let key of Object.keys(node)) {
    //     if (key in init) (node[key as keyof PipelineConnector] as any) = init[key as keyof PipelineConnector];
    //   }
  }

  connect(tracks: trackListType): trackListType {
    if (this.outputNumber < 1) {
      if (this.inputNumber < 1) return tracks;
      else return [];
    }
    // console.log(
    //   ">>",
    //   connector.mapping,
    //   this.range(connector.outputNumber).map((i) => {
    //     const j = i in connector.mapping ? connector.mapping[i] : i;
    //     return tracks?.[j];
    //   })
    // );

    const result = range(this.outputNumber).map((i) => {
      const j = i in this.mapping ? this.mapping[i] : i;
      return tracks?.[j];
    });

    // console.log("NUMBER", result.filter((r) => r !== undefined).length, this.outputNumber);

    if (result.filter((r) => r !== undefined).length < this.outputNumber)
      this.warnings.create({
        component: "Connector",
        id: this.id,
        message: `Not enough tracks connected to output. (Expected ${this.outputNumber} connected ${result.length})`,
      });

    return result;
  }

  serialize(): SerializedPipelineConnector {
    const result: SerializedPipelineConnector = { command: "connect", copy: false, id: this.id, cached: false };

    const mapping = Object.entries(this.mapping)
      .sort((a, b) => a[1] - b[1])
      .map(([k, v]) => [v, parseInt(k)] as [number, number]);

    if (mapping.length > 0) result.mapping = mapping;
    if (this.name) result.name = this.name;
    if (this.inputNumber > 0) result.inputNumber = this.inputNumber;
    if (this.outputNumber > 0) result.outputNumber = this.outputNumber;

    return result;
  }
}
