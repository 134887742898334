import { CSSProperties, useCallback, useMemo, useState } from "react";
import { useInfiniteListEntity } from "../../../../api/BaseEntityApi";
import {
  LabNotebookEntry,
  LabNotebookEntryFilters,
  LabNotebookEntrySortingOptionsConsts,
} from "../../../types/LabNotebookEntry";
import styles from "./LabNotebookEntriesVirtualized.module.css";
import { LoadingWrapper } from "../../../../common/LoadingWrapper";
import InfiniteLoader from "react-window-infinite-loader";
import { FixedSizeList } from "react-window";
import { Skeleton } from "../../../../common/loaders/Skeleton/Skeleton";
import { useDebouncedValue } from "../../../../common/helperfunctions/useDebouncedValue";
import { Alert } from "../../../../common/overlays/Alert/Alert";
import { LabNotebookEntryViewOnly } from "../../../LabNotebookEntry/LabNotebookEntryViewOnly/LabNotebookEntryViewOnly";
import { useResizeDetector } from "react-resize-detector";
import { Modal } from "../../../common/ELNModal/ELNModal";
import { SearchInput } from "../../../../common/forms/SearchInput/SearchInput";
import { Container } from "../../../../common/panels/Container/Container";
import { LucideIcon } from "../../../../common/icon/LucideIcon";
import { EntityFilterIndicator } from "../../../../common/tables/EntityFilterIndicator/EntityFilterIndicator";
import { useELNRoutes } from "../../../ELNRouter/useELNRoutes";
import { ExclusiveDropdown } from "../../../../common/buttons/ExclusiveDropdown/ExclusiveDropdown";
import { LabNotebookEntriesFilterBar } from "../../../LabNotebookEntries/Table/LabNotebookEntriesFilterBar";
import { QuickAddWrapper } from "../../../../common/forms/QuickAdd/common/QuickAddWrapper";
import { QuickAddLabNotebookEntryForm } from "../../../../common/forms/QuickAdd/forms/QuickAddLabNotebookEntry";
import { OrderBySelectDropdown } from "../../../../common/buttons/OrderBySelectDropdown/OrderBySelectDropdown";
import { useQueryClient } from "@tanstack/react-query";
import { LAB_NOTEBOOK_TOC_QUERY_KEY } from "../../../common/tiles/LabNotebookTocTile/LabNotebookTocTile";
import { LinkButton } from "../../../../common/buttons/LinkButton/LinkButton";
const pageSize = 3;
const itemSize = 800;

export const LabNotebookEntriesVirtualized = () => {
  const { setELNRoute, toLabNotebookEntriesList } = useELNRoutes();
  const queryClient = useQueryClient();

  const [showCreateLabNotebookEntryModal, setShowCreateLabNotebookEntryModal] = useState<boolean>(false);
  const [showViewLabNotebookEntryModal, setShowViewLabNotebookEntryModal] = useState<LabNotebookEntry | undefined>();

  const [searchTerm, setSearchTerm] = useState<string>("");
  const debouncedSearchTerm = useDebouncedValue(searchTerm);
  const [filters, setFilters] = useState<LabNotebookEntryFilters>({ orderBy: "MODIFIED_ON_DESC" });

  const { data, error, fetchNextPage, hasNextPage, isFetching, status, fetchStatus, count } = useInfiniteListEntity<
    LabNotebookEntry,
    LabNotebookEntryFilters
  >(
    "lab_notebook_entries",
    {
      page: 1,
      pageSize: pageSize,
      orderBy: "MODIFIED_ON_DESC",
      ...filters,
      includeCount: true,

      includeContent: true,
      searchTerm: debouncedSearchTerm,
      useFullTextSearch: true,
    },
    { enabled: true },
    "post"
  );
  const entries = useMemo(() => {
    return data?.pages.flatMap((e) => e.results) || [];
  }, [data?.pages]);

  const fetchNext = useCallback(() => {
    if (hasNextPage) {
      fetchNextPage();
    }
  }, [fetchNextPage, hasNextPage]);

  const loadMoreCallback = useCallback(() => {}, []);
  const itemCount = hasNextPage ? entries.length + 1 : entries.length;
  const loadMoreItems = isFetching ? loadMoreCallback : fetchNext;
  const isItemLoaded = useCallback(
    (index: number) => !hasNextPage || index < entries.length,
    [hasNextPage, entries.length]
  );
  const Item = useCallback(
    ({ index, style }: { index: number; style: CSSProperties }) => {
      const current_element = entries[index];
      if (!isItemLoaded(index)) {
        return (
          <div style={{ ...style, paddingBottom: "5px" }} key={index}>
            <span className="skeleton-block btn-lg" style={{ height: 51 }} />
          </div>
        );
      } else {
        return (
          <div style={{ ...style }} key={index}>
            <div className={styles.LabNotebookEntriesVirtualizedItemContainer}>
              <LabNotebookEntryViewOnly
                entry={current_element}
                useEntry
                showModifiedState
                showViewButton
                overlay={<Overlay onClick={() => setShowViewLabNotebookEntryModal(current_element)} />}
                ignoreStickyStyles
              />
            </div>
          </div>
        );
      }
    },
    [entries, isItemLoaded]
  );

  const { ref, width, height } = useResizeDetector();

  const [showFilters, setShowFilters] = useState<boolean>(false);

  return (
    <>
      <Container
        containerStyle={{ borderRadius: "5px" }}
        style={{ padding: 0 }}
        title={
          <div
            className="flex align-center gap-5"
            style={{ whiteSpace: "nowrap", fontSize: "16px", color: "var(--primary)" }}
          >
            <LucideIcon name="pencil" />
            Recent entries
            <div className="badge">{count || 0}</div>
          </div>
        }
        controls={
          <div className="flex align-center gap-5">
            <LinkButton
              linkTo={toLabNotebookEntriesList()}
              data-toggle="tooltip"
              title="Show list in fullscreen"
              className="btn btn-ghost-primary"
            >
              <LucideIcon name="table" />
            </LinkButton>

            <button
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                setShowCreateLabNotebookEntryModal(true);
              }}
              data-toggle="tooltip"
              title="Create new entry"
              className="btn btn-ghost-primary"
            >
              <LucideIcon name="plus" />
            </button>
          </div>
        }
      >
        <div style={{ width: "100%", display: "grid", gridTemplateRows: "max-content 1fr" }}>
          <div
            className="flex row-nowrap align-center gap-5"
            style={{ width: "100%", height: "max-content", backgroundColor: "var(--white)", padding: "10px" }}
          >
            <ExclusiveDropdown
              show={showFilters}
              setShow={setShowFilters}
              drop="down-left"
              title="Edit"
              btnCls="btn btn-sm btn-ghost-secondary"
              backdropStyle={{ background: "transparent", backdropFilter: "none" }}
              triggerComponent={(onToggleDropdown) => (
                <button
                  onClick={(e) => onToggleDropdown(e)}
                  className="btn btn-sm btn-ghost-secondary"
                  style={{ padding: 0 }}
                >
                  <EntityFilterIndicator<LabNotebookEntry, LabNotebookEntryFilters>
                    filters={filters}
                    excludeFilters={{
                      includeSoftDeleted: (value) => !!value,
                      isSoftDeleted: (value) => !!value,
                    }}
                    showAlways
                  />
                </button>
              )}
            >
              <div style={{ maxHeight: "300px", display: "flex", minWidth: "300px" }}>
                <LabNotebookEntriesFilterBar setFilters={setFilters} />
              </div>
            </ExclusiveDropdown>

            <SearchInput
              searchValue={searchTerm}
              setSearchValue={setSearchTerm}
              // minimizedBtnCls={"btn btn-xs btn-ghost-primary"}
              // minimized
            />
            <div style={{ marginLeft: "auto" }}>
              <OrderBySelectDropdown
                btnCls="btn btn-xs btn-ghost-secondary"
                orderByOptions={LabNotebookEntrySortingOptionsConsts}
                currentFilters={filters}
                setCurrentFilters={setFilters}
              />
            </div>
          </div>
          <div ref={ref} className={styles.LabNotebookEntriesVirtualizedContainer}>
            <LoadingWrapper status={status} fetchStatus={fetchStatus} error={error} type="skeleton-rows">
              <div className={isFetching ? `${styles.container} ${styles.container_loading}` : styles.container}>
                {entries && entries.length > 0 ? (
                  <InfiniteLoader
                    isItemLoaded={isItemLoaded}
                    itemCount={itemCount}
                    loadMoreItems={loadMoreItems as any}
                  >
                    {({ onItemsRendered, ref }) => (
                      <FixedSizeList
                        itemCount={itemCount}
                        onItemsRendered={onItemsRendered}
                        ref={ref}
                        width={width ?? 0}
                        direction="horizontal"
                        height={height ?? 0}
                        itemSize={itemSize}
                      >
                        {Item}
                      </FixedSizeList>
                    )}
                  </InfiniteLoader>
                ) : (
                  <>
                    {isFetching ? (
                      <Skeleton type="rows" />
                    ) : (
                      <>
                        {debouncedSearchTerm ? (
                          <Alert type="light" message={`No results for: "${debouncedSearchTerm}"`} fit centered />
                        ) : (
                          <Alert type="light" message="No entries" fit centered />
                        )}
                      </>
                    )}
                  </>
                )}
              </div>
            </LoadingWrapper>
          </div>
        </div>
      </Container>

      {showCreateLabNotebookEntryModal && (
        <QuickAddWrapper<LabNotebookEntry>
          showModal={showCreateLabNotebookEntryModal}
          setShowModal={setShowCreateLabNotebookEntryModal}
          onCreate={(newEntry) => {
            if (newEntry && newEntry.id > 0) {
              queryClient.invalidateQueries([LAB_NOTEBOOK_TOC_QUERY_KEY]);
              queryClient.invalidateQueries(["lab_notebooks"]);
              queryClient.invalidateQueries(["lab_notebook_experiments"]);
              queryClient.invalidateQueries(["lab_notebook_entries"]);
              setELNRoute({
                labNotebookId: newEntry.labNotebook.id,
                labNotebookExperimentId: newEntry.labNotebookExperiment.id,
                labNotebookEntryId: newEntry.id,
              });
            }
          }}
        >
          {(props) => (
            <QuickAddLabNotebookEntryForm {...props} initialValues={{ entryDate: new Date().toISOString() }} />
          )}
        </QuickAddWrapper>
      )}

      <Modal isOpen={!!showViewLabNotebookEntryModal} onClose={() => setShowViewLabNotebookEntryModal(undefined)}>
        <div className={styles.LabNotebookEntriesEntryModalContainer}>
          <LabNotebookEntryViewOnly entry={showViewLabNotebookEntryModal} showViewButton useEntry />
        </div>
      </Modal>
    </>
  );
};

const Overlay = ({ onClick }: { onClick: () => void }) => {
  return (
    <div
      className={styles.LabNotebookEntriesVirtualizedOverlay}
      onClick={() => onClick()}
      data-toggle="tooltip"
      title="Click to show full content"
    />
  );
};
