import React, { CSSProperties } from "react";
import {
  GenericControllerProvider,
  GenericControllerProviderProps,
} from "../../forms/common/GenericControllerProvider";
import {
  GenericVirtualizedEntitySelectFormField,
  GenericVirtualizedEntitySelectFormFieldProps,
} from "./GenericVirtualizedEntitySelectFormField";
import {
  IEntityMinimalModel,
  IGenericRequestParameters,
  IOwnedEntityParameters,
  ISoftDeletable,
  IdTypes,
  StringIndexedDict,
  TypedEntitySuggestionsModel,
} from "../../../api/GenericTypes";
import { FormFieldError, FormFieldLayout } from "../FormFieldLayouts";
import { toUppercase } from "../../helperfunctions/stringFunctions";
import styles from "../formfields.module.css";
import { FieldErrors, GlobalError, Path, PathValue } from "react-hook-form";
import { FormGroup } from "react-bootstrap";

// Stripped down entity interface

export interface GenericEntityVirtualizedEntitySelectFormFieldProps<
  Entity extends StringIndexedDict,
  SuggestionsEntity extends (Omit<IEntityMinimalModel<IdTypes>, "version"> | TypedEntitySuggestionsModel<IdTypes>) &
    Partial<ISoftDeletable>,
  SuggestionsEntityFilters extends StringIndexedDict &
    IOwnedEntityParameters &
    IGenericRequestParameters<SuggestionsEntity, SuggestionsEntityFilters["orderBy"]>
> extends Omit<
    GenericVirtualizedEntitySelectFormFieldControllerWrapperProps<Entity, SuggestionsEntity, SuggestionsEntityFilters>,
    "entityConstants" | "orderByOptions"
  > {}

// Pure interface
export interface GenericVirtualizedEntitySelectFormFieldControllerWrapperProps<
  Entity extends StringIndexedDict,
  SuggestionsEntity extends (Omit<IEntityMinimalModel<IdTypes>, "version"> | TypedEntitySuggestionsModel<IdTypes>) &
    Partial<ISoftDeletable>,
  SuggestionsEntityFilters extends StringIndexedDict &
    IOwnedEntityParameters &
    IGenericRequestParameters<SuggestionsEntity, SuggestionsEntityFilters["orderBy"]>
> extends Omit<GenericControllerProviderProps<Entity>, "children">,
    Omit<
      GenericVirtualizedEntitySelectFormFieldProps<Entity, SuggestionsEntity, SuggestionsEntityFilters>,
      "onChange" | "value" | "error"
    > {
  required?: boolean;
  horizontal?: boolean;
  hasLabel?: boolean;
  label?: string;
  uncontained?: boolean;
  defaultValue?: PathValue<Entity, Path<Entity>>;
  formStyle?: CSSProperties;
  style?: CSSProperties;
  children?:
    | (({
        value,
        onChange,
      }: {
        value: PathValue<Entity, Path<Entity>>;
        onChange: (...event: any[]) => void;
      }) => React.ReactNode)
    | React.ReactNode;
  prependChildren?: boolean;
}

export const getError = <Entity extends StringIndexedDict>(id: Path<Entity>, errors: FieldErrors<Entity>) => {
  const fieldPath = id.split(".");
  let error: any = undefined;
  for (const path of fieldPath) {
    if (!error) error = errors?.[path];
    else error = error?.[path];
  }
  return error as GlobalError;
};

/**
 * Wrapper component for the GenericVirtualizedEntitySelectFormFieldController.
 * @author @CorradoSurmanowicz
 * @template Entity - The type of the entity.
 * @template SuggestionsEntity - The type of the suggestions entity.
 * @template SuggestionsEntityFilters - The type of the suggestions entity filters.
 *
 * @param props - The props for the GenericVirtualizedEntitySelectFormFieldControllerWrapper.
 *
 * @returns The rendered GenericVirtualizedEntitySelectFormFieldControllerWrapper component.
 */
export const GenericVirtualizedEntitySelectFormFieldControllerWrapper = <
  Entity extends StringIndexedDict,
  SuggestionsEntity extends IEntityMinimalModel<IdTypes> | TypedEntitySuggestionsModel<IdTypes>,
  SuggestionsEntityFilters extends StringIndexedDict &
    IOwnedEntityParameters &
    IGenericRequestParameters<SuggestionsEntity, SuggestionsEntityFilters["orderBy"]>
>(
  props: GenericVirtualizedEntitySelectFormFieldControllerWrapperProps<
    Entity,
    SuggestionsEntity,
    SuggestionsEntityFilters
  >
) => {
  return (
    <GenericControllerProvider<Entity> id={props.id} control={props.control} defaultValue={props.defaultValue}>
      {({ value, onChange }, fieldState, { errors }) => (
        <>
          {props.uncontained ? (
            <FormGroup
              controlId={props.id}
              style={{ margin: 0, width: "100%", height: "100%", overflow: "hidden", ...props.formStyle }}
            >
              <div className="flex" style={{ margin: 0, width: "100%", height: "100%", ...props.style }}>
                {props.prependChildren && props.children ? (
                  <div className="flex row-nowrap align-center gap-5">
                    {typeof props.children === "function" ? props.children({ value, onChange }) : props.children}
                  </div>
                ) : (
                  <></>
                )}
                <GenericVirtualizedEntitySelectFormField<Entity, SuggestionsEntity, SuggestionsEntityFilters>
                  // key={JSON.stringify(value)} //We trigger a reconciliation (re-render) if the value changes to init new values
                  value={value}
                  onChange={onChange}
                  onBlur={props.onBlur}
                  error={getError(props.id, errors)}
                  placeholder={props.placeholder}
                  isMulti={props.isMulti}
                  disabled={props.disabled}
                  unassignOnX={props.unassignOnX}
                  allowCreateEntity={props.allowCreateEntity}
                  allowUnassigned={props.allowUnassigned}
                  entityConstants={props.entityConstants}
                  filters={props.filters}
                  orderByOptions={props.orderByOptions}
                  controls={props.controls}
                  onRowRenderer={props.onRowRenderer}
                  showControls={props.showControls}
                  createModal={props.createModal}
                  selectFieldStyle={props.selectFieldStyle}
                  concatItems={props.concatItems}
                  suggestionsIds={props.suggestionsIds}
                  overrideItemRenderer={props.overrideItemRenderer}
                  overrideSelectedRenderer={props.overrideSelectedRenderer}
                />
                {!props.prependChildren && props.children ? (
                  <div className="flex row-nowrap align-center gap-5">
                    {typeof props.children === "function" ? props.children({ value, onChange }) : props.children}
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </FormGroup>
          ) : (
            <FormFieldLayout
              id={props.id}
              label={
                props.label
                  ? props.label
                  : props.isMulti
                  ? toUppercase(props.entityConstants.entityPlural)
                  : toUppercase(props.entityConstants.entitySingular)
              }
              required={props.required}
              horizontal={props.horizontal}
              hasLabel={props.hasLabel}
              style={props.formStyle}
            >
              <div className={styles.container} style={props.style}>
                {props.prependChildren && props.children ? (
                  <div className="flex row-nowrap align-center gap-5">
                    {typeof props.children === "function" ? props.children({ value, onChange }) : props.children}
                  </div>
                ) : (
                  <></>
                )}
                <GenericVirtualizedEntitySelectFormField<Entity, SuggestionsEntity, SuggestionsEntityFilters>
                  // key={JSON.stringify(value)} //We trigger a reconciliation (re-render) if the value changes to init new values
                  value={value}
                  onChange={onChange}
                  error={getError(props.id, errors)}
                  placeholder={props.placeholder}
                  isMulti={props.isMulti}
                  disabled={props.disabled}
                  unassignOnX={props.unassignOnX}
                  allowCreateEntity={props.allowCreateEntity}
                  allowUnassigned={props.allowUnassigned}
                  entityConstants={props.entityConstants}
                  filters={props.filters}
                  orderByOptions={props.orderByOptions}
                  controls={props.controls}
                  onRowRenderer={props.onRowRenderer}
                  showControls={props.showControls}
                  createModal={props.createModal}
                  selectFieldStyle={props.selectFieldStyle}
                  concatItems={props.concatItems}
                  suggestionsIds={props.suggestionsIds}
                  overrideItemRenderer={props.overrideItemRenderer}
                  overrideSelectedRenderer={props.overrideSelectedRenderer}
                />
                {!props.prependChildren && props.children ? (
                  <div className="flex row-nowrap align-center gap-5">
                    {typeof props.children === "function" ? props.children({ value, onChange }) : props.children}
                  </div>
                ) : (
                  <></>
                )}
              </div>
              <FormFieldError id={props.id} errors={errors} />
            </FormFieldLayout>
          )}
        </>
      )}
    </GenericControllerProvider>
  );
};
