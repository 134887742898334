import { Tab, Tabs } from "react-bootstrap";
import styles from "./IconNMR.module.css";
import { useContext, useMemo } from "react";
import { IconNMRStatusOverview } from "./IconNMRStatus/IconNMRStatus";
import SinglePage from "../common/panels/SinglePage/SinglePage";
import { Redirect, Route, Switch, useHistory, useLocation } from "react-router-dom";
import { SessionContext } from "../common/contexts/SessionContext";
import { getAddRoute, getIndexRoute } from "../main/Routing";
import { iconNMRConstants } from "../api/IconNMR";
import { LucideIcon } from "../common/icon/LucideIcon";
import { toUppercase } from "../common/helperfunctions/stringFunctions";
import { dataSourceConstants } from "../api/DataSource";
import { IconNMRHoldersTablePage, IconNMRHistoryTablePage } from "../Autoloads/IconNMR/IconNMRViews";

type TabOptions = "overview" | "queue" | "history" | "configure";
const TabOptionsArray: TabOptions[] = ["overview", "queue", "history", "configure"];
export const IconNMR = () => {
  const { pathname } = useLocation();
  const { session } = useContext(SessionContext);

  const activeTab = useMemo(() => {
    let path = pathname.split("/");
    if (path.length > 0) {
      let currentRoute = path[path.length - 1];
      if (TabOptionsArray.some((o) => currentRoute === o)) {
        return currentRoute;
      }
    }
    return "overview";
  }, [pathname]);

  const history = useHistory();
  const { route } = useContext(SessionContext);

  return (
    <SinglePage>
      <SinglePage.Head>
        <div className="flex align-center gap-5">
          <LucideIcon name={iconNMRConstants.icon} color="var(--primary)" style={{ width: "20px", height: "20px" }} />
          <SinglePage.Head.Label>{toUppercase(iconNMRConstants.entitySingular)}</SinglePage.Head.Label>
          <div style={{ marginLeft: "10px" }}>
            <Tabs
              defaultActiveKey="overview"
              id="tabs-menue"
              bsStyle="tabs"
              onSelect={(k) => history.push(`${route(getIndexRoute(iconNMRConstants.frontendIndexRoute))}/${k}`)}
              activeKey={activeTab}
            >
              <Tab
                bsClass={styles.panel_left_tab}
                eventKey="overview"
                title={<div className="flex align-center gap-5">Overview</div>}
              />
              <Tab
                bsClass={styles.panel_left_tab}
                eventKey="queue"
                title={<div className="flex align-center gap-5">Queue</div>}
              />
              <Tab
                bsClass={styles.panel_left_tab}
                eventKey="history"
                title={<div className="flex align-center gap-5">History</div>}
              />
            </Tabs>
          </div>
        </div>
        <SinglePage.Head.Controls>
          <button
            className="btn btn-default flex align-center gap-5"
            onClick={() =>
              history.push(`${route(getIndexRoute(dataSourceConstants.frontendIndexRoute))}?tabId=iconnmr`)
            }
          >
            <LucideIcon name="table" />
            Show all data sources
          </button>
          <button
            className="btn btn-primary flex align-center gap-5"
            onClick={() => history.push(route(getAddRoute(iconNMRConstants.frontendIndexRoute)))}
            disabled={!session?.permissions.administer_autoload}
          >
            <LucideIcon name="plus" />
            Add monitoring
          </button>
        </SinglePage.Head.Controls>
      </SinglePage.Head>
      <SinglePage.Content>
        <Switch>
          <Route path={route(getIndexRoute(iconNMRConstants.frontendIndexRoute))} exact>
            {() => <Redirect to={`${route(getIndexRoute(iconNMRConstants.frontendIndexRoute))}/overview`} />}
          </Route>
          <Route path={`${route(getIndexRoute(iconNMRConstants.frontendIndexRoute))}/overview`} exact>
            <IconNMRStatusOverview />
          </Route>
          <Route path={`${route(getIndexRoute(iconNMRConstants.frontendIndexRoute))}/queue`} exact>
            <IconNMRHoldersTablePage />
          </Route>
          <Route path={`${route(getIndexRoute(iconNMRConstants.frontendIndexRoute))}/history`} exact>
            <IconNMRHistoryTablePage />
          </Route>
        </Switch>
      </SinglePage.Content>
    </SinglePage>
  );
};
