import React, { createContext, CSSProperties, useContext, useEffect, useMemo, useState } from "react";
import { LucideIcon } from "../../icon/LucideIcon";
import styles from "./Tile.module.css";
/**
 * Generic Tile component
 * Author: CS
 * @param: title, controls, children, isFolded, foldable
 * @returns: JSX.Element
 */

interface MainProps {
  style?: CSSProperties;
  children: React.ReactNode;
}
interface TileContextStore {
  foldable: boolean;
  isFolded: boolean;
  setIsFolded: React.Dispatch<React.SetStateAction<boolean>>;
}
const TileContext = createContext<TileContextStore | null>(null);

interface TileProps extends MainProps {
  foldable?: boolean;
  isFolded?: boolean;
  disabled?: boolean;
}
export const Tile = ({ foldable = true, isFolded = false, disabled = false, style, children }: TileProps) => {
  const [_isFolded, setIsFolded] = useState(isFolded);

  useEffect(() => {
    setIsFolded(isFolded);
  }, [isFolded]);

  const context = useMemo(
    () => ({ foldable: foldable, isFolded: _isFolded, setIsFolded: setIsFolded }),
    [_isFolded, foldable]
  );
  return (
    <TileContext.Provider value={context}>
      <div
        className={`${styles.tile} ${disabled && styles.tile_disabled}`}
        style={{ ...style, ...(_isFolded && { height: "fit-content" }) }}
      >
        {children}
      </div>
    </TileContext.Provider>
  );
};

interface TileHeadProps extends Omit<MainProps, "children"> {
  title: string | JSX.Element | React.ReactNode;
  children?: React.ReactNode;
}
const Head = ({ style, children, title }: TileHeadProps) => {
  const context = useContext(TileContext);

  return (
    <div className={styles.tile_head} style={style}>
      <div
        className={`${styles.tile_head_title} ${context?.foldable && styles.clickable}`}
        onClick={() => context?.foldable && context?.setIsFolded(() => !context?.isFolded)}
      >
        {context?.foldable && (
          <div className={styles.tile_head_chevron}>
            <>{context?.isFolded ? <LucideIcon name="chevron-right" /> : <LucideIcon name="chevron-down" />}</>
          </div>
        )}
        <div className={styles.tile_head_label}>
          <h4 style={{ margin: 0 }}>{title}</h4>
        </div>
      </div>
      {children}
    </div>
  );
};
Tile.Head = Head;

const Controls = ({ style, children }: MainProps) => {
  return (
    <div className={styles.tile_head_controls} style={style}>
      {children}
    </div>
  );
};
Head.Controls = Controls;

const Fixed = ({ children }: MainProps) => {
  return <>{children}</>;
};
Controls.Fixed = Fixed;

const Unfolded = ({ children }: MainProps) => {
  const context = useContext(TileContext);
  return <>{!context?.isFolded && children}</>;
};
Controls.Unfolded = Unfolded;

const Folded = ({ children }: MainProps) => {
  const context = useContext(TileContext);
  return <>{context?.isFolded && children}</>;
};
Controls.Folded = Folded;

const Body = ({ style, children }: MainProps) => {
  const context = useContext(TileContext);
  return (
    <>
      {!context?.isFolded && (
        <div
          className={`${styles.tile_body} ${
            !context?.foldable
              ? styles.tile_body_unfolded
              : context?.isFolded
              ? styles.tile_body_folded
              : styles.tile_body_unfolded
          }`}
          style={style}
        >
          {children}
        </div>
      )}
    </>
  );
};
Tile.Body = Body;
