import { BridgeFieldLabels, BridgeType, BridgeTypeFormValues } from "../../../api/Bridges";
import { customImportConstants } from "../../../api/CustomImports";
import { DataSource, DataSourceFilters, DataSourceFieldLabels, DataSourceTypes } from "../../../api/DataSource";
import { DefinedPropertyObject, IEntityMinimalModel } from "../../../api/GenericTypes";
import { SplitDatePickerFormField } from "../../../common/formfields/DatePickerFormField/SplitDatePickerFormField";
import { SelectFormField } from "../../../common/formfields/SelectFormField";
import { BridgesVirtualizedSelectForm } from "../../../common/forms/EntityForms/formsVirtualized/BridgeVirtualizedSelectForm";
import { CustomImportsVirtualizedSelectForm } from "../../../common/forms/EntityForms/formsVirtualized/CustomImportVirtualizedSelectForm";
import { DataFormatsVirtualizedSelectForm } from "../../../common/forms/EntityForms/formsVirtualized/DataFormatsVirtualizedSelectForm";
import { DatasetsVirtualizedSelectForm } from "../../../common/forms/EntityForms/formsVirtualized/DatasetsVirtualizedSelectForm";
import { InstrumentsVirtualizedSelectForm } from "../../../common/forms/EntityForms/formsVirtualized/InstrumentsVirtualizedSelectForm";
import { MethodsVirtualizedSelectForm } from "../../../common/forms/EntityForms/formsVirtualized/MethodsVirtualizedSelectForm";
import { PersonsVirtualizedSelectForm } from "../../../common/forms/EntityForms/formsVirtualized/PersonsVirtualizedSelectForm";
import { toUppercase } from "../../../common/helperfunctions/stringFunctions";
import { EntityFilterSideBar } from "../../../common/sidebar/filtersidebar/EntityFilterBar";
import { FilterSidebarProps, useFilterSidebar } from "../../../common/sidebar/filtersidebar/FilterSidebar";

export interface DataSourceFilterForm {
  bridgeIds?: IEntityMinimalModel[] | undefined | null;
  bridgeTypes?: { id: BridgeType; name: string }[] | undefined | null;
  datasetIds?: IEntityMinimalModel[] | undefined | null;
  formatIds?: IEntityMinimalModel<string>[] | undefined | null;
  instrumentIds?: IEntityMinimalModel[] | undefined | null;
  methodIds?: IEntityMinimalModel[] | undefined | null;
  customImportIds?: IEntityMinimalModel<string>[] | undefined | null;
  enabled?: { id: boolean | null; name: string } | null;
  types?: { id: DataSource["type"]; name: string }[] | undefined | null;
  createdByIds?: IEntityMinimalModel[] | undefined | null;
  createdFrom?: Date | undefined | null;
  createdTo?: Date | undefined | null;
  modifiedByIds?: IEntityMinimalModel[] | undefined | null;
  modifiedFrom?: Date | undefined | null;
  modifiedTo?: Date | undefined | null;
}

const EnabledFormValues = [
  { id: null, name: "All" },
  { id: true, name: "Enabled" },
  { id: false, name: "Disabled" },
];

const SourceTypeFormValues = DataSourceTypes.map((t) => ({ id: t, name: t }));

const defaultValues: DefinedPropertyObject<DataSourceFilterForm> = {
  bridgeIds: null,
  bridgeTypes: null,
  datasetIds: null,
  formatIds: null,
  instrumentIds: null,
  methodIds: null,
  customImportIds: null,
  enabled: null,
  types: null,
  createdByIds: null,
  createdFrom: null,
  createdTo: null,
  modifiedByIds: null,
  modifiedFrom: null,
  modifiedTo: null,
};
const formToFilters = (form: DataSourceFilterForm): DataSourceFilters => {
  let filters: DataSourceFilters = { enabled: null };
  filters.bridgeIds =
    Array.isArray(form.bridgeIds) && form.bridgeIds.length > 0 ? form.bridgeIds.map((d) => d.id) : null;
  filters.bridgeTypes =
    Array.isArray(form.bridgeTypes) && form.bridgeTypes.length > 0 ? form.bridgeTypes.map((d) => d.id) : null;
  filters.datasetIds =
    Array.isArray(form.datasetIds) && form.datasetIds.length > 0 ? form.datasetIds.map((d) => d.id) : null;
  filters.formatIds =
    Array.isArray(form.formatIds) && form.formatIds.length > 0 ? form.formatIds.map((d) => d.id) : null;
  filters.instrumentIds =
    Array.isArray(form.instrumentIds) && form.instrumentIds.length > 0 ? form.instrumentIds.map((d) => d.id) : null;
  filters.methodIds =
    Array.isArray(form.methodIds) && form.methodIds.length > 0 ? form.methodIds.map((d) => d.id) : null;
  filters.customImportIds =
    Array.isArray(form.customImportIds) && form.customImportIds.length > 0
      ? form.customImportIds.map((d) => d.id)
      : null;
  filters.enabled = form.enabled?.id ?? null;
  filters.types = Array.isArray(form.types) && form.types.length > 0 ? form.types.map((d) => d.id) : null;
  filters.createdByIds =
    Array.isArray(form.createdByIds) && form.createdByIds.length > 0 ? form.createdByIds.map((d) => d.id) : null;
  filters.createdFrom = form.createdFrom ? form.createdFrom.toISOString() : null;
  filters.createdTo = form.createdTo ? form.createdTo.toISOString() : null;
  filters.modifiedByIds =
    Array.isArray(form.modifiedByIds) && form.modifiedByIds.length > 0 ? form.modifiedByIds.map((d) => d.id) : null;
  filters.modifiedFrom = form.modifiedFrom ? form.modifiedFrom.toISOString() : null;
  filters.modifiedTo = form.modifiedTo ? form.modifiedTo.toISOString() : null;
  return filters;
};
const defaultFilters: DataSourceFilters = { enabled: null, orderBy: "NAME_ASC" };

export const DataSourceFilterBar = ({
  initialValues,
  setFilters,
  onFormChange,
  dispatchTabStore,
  tabsLoading,
  currentTab,
}: FilterSidebarProps<DataSource, DataSourceFilterForm, DataSourceFilters>) => {
  const { control, onReset } = useFilterSidebar({
    initialValues,
    defaultValues,
    defaultFilters,
    formToFilters,
    onFormChange,
    dispatchTabStore,
    setFilters,
  });

  return (
    <EntityFilterSideBar onReset={onReset}>
      <BridgesVirtualizedSelectForm id="bridgeIds" label="Bridges" control={control} isMulti />

      <SelectFormField
        id="bridgeTypes"
        label={BridgeFieldLabels.type}
        control={control}
        items={BridgeTypeFormValues}
        placeholder="Select connection type..."
        horizontal={false}
        isMulti
      />

      <DatasetsVirtualizedSelectForm id="datasetIds" label="Datasets" control={control} isMulti />

      <DataFormatsVirtualizedSelectForm id="formatIds" label="Formats" control={control} isMulti />

      <InstrumentsVirtualizedSelectForm id="instrumentIds" label="Instruments" control={control} isMulti />

      <MethodsVirtualizedSelectForm id="methodIds" label="Methods" control={control} isMulti />

      <CustomImportsVirtualizedSelectForm
        id="customImportIds"
        label={toUppercase(customImportConstants.entityPlural)}
        control={control}
        isMulti
      />

      {currentTab !== "status" && (
        <SelectFormField
          id="enabled"
          label={DataSourceFieldLabels.enabled}
          control={control}
          horizontal={false}
          items={EnabledFormValues}
          placeholder="Select status..."
        />
      )}

      <SelectFormField
        id="types"
        label={DataSourceFieldLabels.type}
        control={control}
        horizontal={false}
        items={SourceTypeFormValues}
        placeholder="Select source type..."
        isMulti
      />

      {currentTab !== "mydata" && (
        <PersonsVirtualizedSelectForm id="createdByIds" label="Created by" control={control} isMulti />
      )}
      <SplitDatePickerFormField
        control={control}
        idStart="createdFrom"
        idEnd="createdTo"
        label="Creation date (from - to)"
        horizontal={false}
        showTimeSelect
      />
      <PersonsVirtualizedSelectForm id="modifiedByIds" label="Modified by" control={control} isMulti />

      <SplitDatePickerFormField
        control={control}
        idStart="modifiedFrom"
        idEnd="modifiedTo"
        label="Modification date (from - to)"
        horizontal={false}
        showTimeSelect
      />
    </EntityFilterSideBar>
  );
};
