import { Dispatch, SetStateAction, useCallback, useEffect, useState } from "react";
import { LucideIcon } from "../../../../../../common/icon/LucideIcon";

export const TimerModal = ({
  setShowTimerModal,
  toggleTimer,
}: {
  setShowTimerModal: Dispatch<SetStateAction<boolean>>;
  toggleTimer: Function;
}) => {
  const [timeInSeconds, setTimeInSeconds] = useState<number>(0);

  const [days, setDays] = useState<number>(0);
  const [hours, setHours] = useState<number>(0);
  const [minutes, setMinutes] = useState<number>(0);
  const [seconds, setSeconds] = useState<number>(0);

  const timeToSeconds = useCallback(() => {
    return days * 24 * 60 * 60 + hours * 60 * 60 + minutes * 60 + seconds;
  }, [days, hours, minutes, seconds]);

  useEffect(() => {
    let timeout = setTimeout(() => {
      setTimeInSeconds(timeToSeconds());
    }, 500);

    return () => {
      clearTimeout(timeout);
    };
  }, [timeToSeconds]);

  const secondsToTime = useCallback(() => {
    if (timeInSeconds) {
      const days = Math.floor(timeInSeconds / 60 / 60 / 24);
      const hours = Math.floor((timeInSeconds - days * 24 * 60 * 60) / 60 / 60);
      const minutes = Math.floor((timeInSeconds - days * 24 * 60 * 60 - hours * 60 * 60) / 60);
      const seconds = Math.floor(timeInSeconds - days * 24 * 60 * 60 - hours * 60 * 60 - minutes * 60);
      const milliseconds = parseInt(
        (timeInSeconds - days * 24 * 60 * 60 - hours * 60 * 60 - minutes * 60 - seconds).toFixed(1).split(".")[1]
      );
      return {
        days,
        hours,
        minutes,
        seconds,
        milliseconds,
        timeString: `${days}:${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:${seconds
          .toString()
          .padStart(2, "0")}.${milliseconds}`,
      };
    }

    return {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
      milliseconds: 0,
      timeString: "",
    };
  }, [timeInSeconds]);

  useEffect(() => {
    const { days, hours, minutes, seconds } = secondsToTime();
    setDays(days > 99 ? 99 : days || 0);
    setHours(hours || 0);
    setMinutes(minutes || 0);
    setSeconds(seconds || 0);
  }, [secondsToTime]);

  return (
    <div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-evenly",
          gap: "20px",
          padding: "20px 40px",
        }}
      >
        <div style={{ display: "flex", flexDirection: "column", width: "max-content" }}>
          <label
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              gap: "10px",
            }}
          >
            <span>Days:</span>
            <input
              className="form form-control"
              value={days || 0}
              onChange={(e) => setDays(parseInt(e.target.value))}
              type="number"
              min={0}
              max={99}
              style={{ width: "150px", textAlign: "center" }}
            />
          </label>
          <label
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              gap: "10px",
            }}
          >
            <span>Hours:</span>
            <input
              className="form form-control"
              value={hours || 0}
              onChange={(e) => setHours(parseInt(e.target.value))}
              type="number"
              min={0}
              style={{ width: "150px", textAlign: "center" }}
            />
          </label>
          <label
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              gap: "10px",
            }}
          >
            <span>Minutes:</span>
            <input
              className="form form-control"
              value={minutes || 0}
              onChange={(e) => setMinutes(parseInt(e.target.value))}
              type="number"
              min={0}
              style={{ width: "150px", textAlign: "center" }}
            />
          </label>
          <label
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              gap: "10px",
            }}
          >
            <span>Seconds:</span>
            <input
              className="form form-control"
              value={seconds || 0}
              onChange={(e) => setSeconds(parseInt(e.target.value))}
              type="number"
              min={0}
              style={{ width: "150px", textAlign: "center" }}
            />
          </label>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            whiteSpace: "nowrap",
            gap: "10px",
          }}
        >
          <button
            className="btn btn-primary"
            onClick={() => {
              if (timeInSeconds > 0) {
                toggleTimer({ seconds: timeInSeconds });
                setShowTimerModal(false);
              }
            }}
          >
            <LucideIcon name="plus" />
            <span> Create timer</span>
          </button>
        </div>
      </div>
    </div>
  );
};
