import { useEffect, useState } from "react";
import { Table } from "react-bootstrap";

import { LucideIcon } from "../../common/icon/LucideIcon";
import { ParameterTable } from "../../ViewerLayout/ViewerLayoutTypes";
import { ColorRectList } from "../../ViewerUIElements/ColorRect";
import styles from "./TreeViewer.module.css";
import { SearchRequestType } from "./TreeViewerTypes";

// https://react-window.now.sh/#/examples/grid/fixed-size
// https://github.com/nadbm/react-datasheet

// export const TreeListNode = (props: any) => {
//   const { children, searching, ...otherProps }: { children: any; searching: boolean } = props;

//   const { name, colors, active, isVisible, border, content } = children;

export const TreeTableLeaf = ({ children, search }: { children: ParameterTable; search: SearchRequestType }) => {
  const { name, colors, isVisible, columnNames, table, active, isLineVisible } = children;

  const [expanded, setExpanded] = useState<boolean>(true);
  const [content, setContent] = useState<any>();
  const [searching, setSearching] = useState<boolean>((search?.keyText || search?.valueText) !== undefined);

  useEffect(() => {
    setSearching((search?.keyText || search?.valueText) !== undefined);
  }, [search?.keyText, search?.valueText]);

  useEffect(() => {
    setExpanded(active === undefined ? true : active);
  }, [active]);

  useEffect(() => {
    if (table && columnNames) {
      const header = columnNames.map((name, i) => <th key={i}>{name}</th>);

      const body = table
        .filter((line, i) => (isLineVisible?.[i] === undefined ? true : isLineVisible[i]))
        .map((line, i) => (
          <tr key={i}>
            {line.map((v, i) => (
              <th key={i}>{v}</th>
            ))}
          </tr>
        ));

      setContent(
        <Table condensed hover>
          <thead>
            <tr>{header}</tr>
          </thead>
          <tbody>{body}</tbody>
        </Table>
      );
    } else setContent(null);
  }, [table, columnNames, isLineVisible]);

  return (
    <div style={{ display: isVisible ? undefined : "none" }}>
      <div
        className={styles.parameterKey}
        onClick={() => {
          setExpanded(!expanded);
        }}
      >
        {/* <span
          className={expanded || searching ? "glyphicon glyphicon-chevron-down" : "glyphicon glyphicon-chevron-right"}
          style={{ fontSize: "smaller" }}
        /> */}
        <LucideIcon
          name={expanded || searching ? "chevron-down" : "chevron-right"}
          style={{ strokeWidth: 3, width: 15 }}
        />
        {/* &nbsp; */}
        <LucideIcon name="table" style={{ strokeWidth: 3, width: 15 }} />
        {/* <span className={"glyphicon glyphicon-th"} style={{ fontSize: "smaller" }} /> */}
        &nbsp;
        {name}
        {/* TODO: Implement colorBox here */}
        <ColorRectList colors={colors} />
      </div>
      <div className={styles.listContent} style={{ display: expanded || searching ? undefined : "none" }}>
        {content}
      </div>
    </div>
  );
};
