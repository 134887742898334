import React, { useEffect, useMemo, useState } from "react";
import { CustomField } from "../../../api/CustomFields";
import { FetchStatus, QueryStatus } from "@tanstack/react-query";
import { useUnpaginate } from "../../../api/BaseEntityApi";
import { CustomType, customTypeConstants, CustomTypeFilters } from "../../../api/CustomTypes";

/**
 * Custom wrapper for fetching parent inventories from custom type constraints
 * @author @CorradoSurmanowicz
 */

interface CustomTypeConstraintWrapperProps {
  customTypeConstraints: CustomField["customTypeConstraint"] | null | undefined;
  children: ({
    inventoryIds,
    constraintIds,
    status,
    fetchStatus,
    error,
  }: {
    inventoryIds: number[];
    constraintIds: number[];
    status: QueryStatus;
    fetchStatus: FetchStatus;
    error: unknown;
  }) => React.ReactNode;
}
export const CustomTypeConstraintWrapper = ({ customTypeConstraints, children }: CustomTypeConstraintWrapperProps) => {
  const [inventoryIds, setInventoryIds] = useState<number[]>([]); // Here we store the parent inventories only
  const constraintIds = useMemo(() => customTypeConstraints?.map((c) => c.id) || [], [customTypeConstraints]);

  const { data, status, fetchStatus, error } = useUnpaginate<CustomType, CustomTypeFilters>(
    customTypeConstants.resource,
    { ids: constraintIds },
    { enabled: Array.isArray(constraintIds) && !!constraintIds.length }
  );

  useEffect(() => {
    if (data) {
      let _ids = new Set<number>();
      Object.values(data).forEach((type) => {
        if (type.isHierarchyRoot) {
          // Hierarchy root
          _ids.add(type.id);
        } else if (type.rootHierarchy) {
          // Hierarchy child
          _ids.add(type.rootHierarchy.id);
        } else if (type.inventoryName) {
          // Flat inventory
          _ids.add(type.id);
        }
        setInventoryIds(Array.from(_ids));
      });
    }
  }, [data]);

  return <>{children({ inventoryIds, constraintIds, status, fetchStatus, error })}</>;
};
