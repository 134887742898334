import { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { Table } from "../../common/panels/Detail/DetailTable";
import { NotAvailable, NotSet } from "../../common/misc/UIconstants";
import { EntityDetailProps, EntityDetailTableProps } from "../../common/entity/EntityInterfaces";
import Detail from "../../common/panels/Detail/Detail";
import { DetailsPageTopControlsView } from "../../common/panels/misc/DetailsPageTopControlsView/DetailsPageTopControlsView";
import { LucideIcon } from "../../common/icon/LucideIcon";
import { showtoast } from "../../common/overlays/Toasts/showtoast";
import { getAddRoute, getDetailLink } from "../../main/Routing";
import { MoreDropdown } from "../../common/buttons/MoreDropdown/MoreDropdown";
import { AlertModal } from "../../common/modals/AlertModal/AlertModal";
import { SessionContext } from "../../common/contexts/SessionContext";
import { DatasetsTile } from "../../common/sidebar/tiles/DatasetsTile";
import { BridgeConnectionStatus } from "./common/BridgeConnectionStatus";
import { DataSourcesTile } from "../../common/sidebar/tiles/DataSourcesTile";
import { dataSourceConstants } from "../../api/DataSource";
import { LinkButton } from "../../common/buttons/LinkButton/LinkButton";

export const BridgeDetailTable = ({
  entity: bridge,
  entityConstants,
  fieldLabels,
}: EntityDetailTableProps<"bridges">) => {
  return (
    <>
      <Table noPadding nonFoldable>
        <Table.Head>Basic details</Table.Head>
        <Table.Body>
          <Table.Body.RowContent
            title={fieldLabels.name}
            content={
              <div className="container_label">
                <div className="container_label_name" style={{ fontWeight: 600 }}>
                  {bridge.name ?? NotSet}
                </div>
                <div className="container_label_id">
                  <span style={{ whiteSpace: "nowrap" }}>Bridge-ID: {bridge.id}</span>
                </div>
              </div>
            }
          />

          <Table.Body.RowContent
            title={fieldLabels.type}
            content={
              <div style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
                <label className="label label-soft-info" style={{ margin: 0 }}>
                  <LucideIcon name="wifi" /> {bridge.type}
                </label>
              </div>
            }
          />

          <Table.Body.RowContent title={fieldLabels.hostname} content={bridge.hostname} />
          <Table.Body.RowContent
            title={fieldLabels.ipAddress}
            content={<span style={{ fontFamily: "monospace" }}>{bridge.ipAddress}</span>}
          />
          {bridge.type === "SFTP" && <Table.Body.RowContent title={fieldLabels.port} content={bridge.port} />}

          {bridge.type === "SFTP" && (
            <>
              <Table.Body.RowContent title={fieldLabels.username} content={bridge.username ?? NotAvailable} />
            </>
          )}

          {bridge.type === "Client" && (
            <>
              <Table.Body.RowContent
                title={fieldLabels.operatingSystem}
                content={bridge.connectedClients[0]?.operatingSystem ?? NotSet}
              />
              <Table.Body.RowContent
                title={fieldLabels.executablePath}
                content={bridge.connectedClients[0]?.executablePath ?? NotSet}
              />
              <Table.Body.RowContent
                title={fieldLabels.clientVersion}
                content={bridge.connectedClients[0]?.clientVersion ?? NotSet}
              />
            </>
          )}

          <Table.Body.RowContent title={fieldLabels.isConnected} content={<BridgeConnectionStatus bridge={bridge} />} />
          <Table.Body.RowContent
            title={fieldLabels.description}
            content={
              <>
                {bridge.description ? (
                  <div className="container_notes">
                    <textarea rows={4} className="container_textarea" value={bridge.description} disabled />
                  </div>
                ) : (
                  NotSet
                )}
              </>
            }
          />
        </Table.Body>
      </Table>
    </>
  );
};

export const BridgesDetail = ({
  permissions,
  routes,
  entity,
  entityApi,
  entityConstants,
  fieldLabels,
}: EntityDetailProps<"bridges">) => {
  const history = useHistory();
  const { route } = useContext(SessionContext);
  const [showModal, setShowModal] = useState<boolean>(false);

  return (
    <Detail
      head={
        <DetailsPageTopControlsView
          entity={entity}
          entityConstants={entityConstants}
          controls={
            <>
              <LinkButton
                linkTo={`${route(getDetailLink(entityConstants.frontendIndexRoute, entity.id))}${getAddRoute(
                  dataSourceConstants.frontendIndexRoute
                )}`}
                className="btn btn-primary"
                disabled={!permissions.canCreate}
              >
                <LucideIcon name="plus" />
                <span> Add data source</span>
              </LinkButton>
              <LinkButton
                linkTo={routes.getEditLink(entity.id)}
                className="btn btn-default"
                disabled={!permissions.canEdit(entity)}
              >
                <LucideIcon name="square-pen" />
                <span> Edit</span>
              </LinkButton>

              <MoreDropdown drop="right" btn="btn btn-ghost-secondary">
                {entity.type !== "Client" && (
                  <li>
                    <button
                      className="btn btn-sm btn-ghost-secondary"
                      title={`Clone this bridge`}
                      onClick={() => routes.getCloneLink(entity.id)}
                      disabled={!permissions.canCreate}
                    >
                      <LucideIcon name="copy" /> Clone
                    </button>
                  </li>
                )}
                <li>
                  <button
                    className="btn btn-sm btn-ghost-danger"
                    title="Delete"
                    onClick={() => setShowModal(true)}
                    disabled={!permissions.canDelete(entity)}
                  >
                    <LucideIcon name="trash-2" /> Delete
                  </button>
                </li>
              </MoreDropdown>
              <AlertModal
                type="danger"
                showModal={showModal}
                setShowModal={setShowModal}
                title="Permanently delete bridge?"
                description={`Deleting this ${entityConstants.entitySingular} will also unapprove the bound client`}
                proceedLabel="Delete"
                onProceed={async () => {
                  await entityApi
                    .deleteMutationAsync(
                      {
                        id: entity.id,
                        goBackOnSuccess: false,
                        showToast: false,
                        entityName: "bridge",
                        params: { deletePermanently: true },
                      },

                      {
                        onSuccess: () => {
                          showtoast("success", `Deleted Bridge-ID: ${entity.id}.`);
                          history.goBack();
                        },
                      }
                    )
                    .catch((e) => {});
                }}
                loading={entityApi.isLoadingDeleteMutation}
              />
            </>
          }
        />
      }
      mainStyle={{ padding: "0px 10px" }}
      main={<BridgeDetailTable entity={entity} entityConstants={entityConstants} fieldLabels={fieldLabels} />}
      sidebar={
        <>
          {!!entity.relations?.dataSources.count && (
            <DataSourcesTile
              title="Data sources"
              defaultFilters={{ bridgeIds: [entity.id] }}
              excludeFilters={{ bridgeIds: () => false }}
            />
          )}
          {!!entity.relations?.datasets.count && (
            <DatasetsTile
              title="Uploaded Datasets"
              defaultFilters={{ bridgeIds: [+entity.id!] }}
              excludeFilters={{ bridgeIds: () => false }}
            />
          )}

          {/* Empty tiles will go here */}
          {!entity.relations?.dataSources.count && <DataSourcesTile title="Data sources" disabled />}
          {!entity.relations?.datasets.count && <DatasetsTile title="Uploaded Datasets" disabled />}
        </>
      }
    />
  );
};
