import React, { CSSProperties } from "react";
import styles from "./styles.module.css";

// export interface Content {
//   [label: string]: JSX.Element | JSX.Element[] | Element[] | string | String[] | number | number[];
// }

/**
 * Generic key-value Component by usage of CSS Grid
 * Author: CS
 * @param: [{key: xxx, value: xxx},{key: xxx, value: xxx}...]
 * @returns: JSX.Element
 */

export interface ContentRow {
  key: JSX.Element | JSX.Element[] | Element[] | string | String[] | number | number[];
  value: JSX.Element | JSX.Element[] | Element[] | string | String[] | number | number[];
}
export type Content = ContentRow[] | {}[];

interface Props {
  content: Content;
  styleKeys?: CSSProperties;
  styleValues?: CSSProperties;
}
export const ContentTable = ({ content, styleKeys, styleValues }: Props) => {
  const table = content
    .filter((d) => Object.hasOwn(d, "key") && Object.hasOwn(d, "value"))
    .map((row, index) => {
      return (
        <React.Fragment key={"row-" + index}>
          <div key={"row-" + index + "col1"} className={styles.container_table_col1} style={styleKeys}>
            {(row as ContentRow).key}
          </div>
          <div key={"row-" + index + "col2"} className={styles.container_table_col2} style={styleValues}>
            {(row as ContentRow).value}
          </div>
        </React.Fragment>
      );
    });
  return (
    <div className={styles.container}>
      <div className={styles.container_table}>{table}</div>
    </div>
  );
};
