import React, { useCallback, useContext, useMemo } from "react";
import { GenericModal } from "../../common/modals/Modal/GenericModal";
import { useUnpaginate } from "../../api/BaseEntityApi";
import { Dataset, DatasetFilters, datasetsConstants } from "../../api/Datasets";
import { LoadingWrapper } from "../../common/LoadingWrapper";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { SharedContent, sharedContentConstants } from "../../api/SharedContent";
import { SessionContext } from "../../common/contexts/SessionContext";
import { getDetailLink } from "../../main/Routing";
import { FormErrors } from "../../common/forms/FormButtons";
import { InputFormField } from "../../common/formfields/InputFormField";
import { TextareaFormField } from "../../common/formfields/TextareaFormField";
import { ToggleFormField } from "../../common/formfields/ToggleFormField";
import { DatasetsVirtualizedSelectForm } from "../../common/forms/EntityForms/formsVirtualized/DatasetsVirtualizedSelectForm";
import { useEntityApi } from "../../api/useEntityApi";
import { Button } from "../../common/buttons/Button/Button";

interface SharedContentCreateModalProps {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  datasetIds: number[];
}
export const SharedContentCreateModal = ({ showModal, setShowModal, datasetIds }: SharedContentCreateModalProps) => {
  const {
    data: datasetMinimalModels,
    status,
    fetchStatus,
    error,
  } = useUnpaginate<Dataset, DatasetFilters>(
    datasetsConstants.resource,
    { ids: datasetIds },
    { enabled: !!datasetIds.length && showModal }
  );

  const { route } = useContext(SessionContext);
  const history = useHistory();
  const { createMutationAsync, isLoadingCreateMutation } = useEntityApi<SharedContent>(sharedContentConstants.resource);

  const SharedContentFormSchema = yup.object().shape({
    name: yup.string().required("A name is required"),
    datasets: yup.array().min(1, "At least one dataset is required").required("At least one dataset is required"),
  });

  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
  } = useForm<Partial<SharedContent>>({
    values: useMemo(
      () => ({ datasets: datasetMinimalModels ? Object.values(datasetMinimalModels) : undefined }),
      [datasetMinimalModels]
    ),
    resolver: yupResolver(SharedContentFormSchema),
  });

  // useEffect(() => {
  //   if (showModal && datasetMinimalModels) {
  //     reset({ datasets: datasetMinimalModels });
  //   }
  // }, [datasetMinimalModels, reset, showModal]);

  // Handlers
  const onCancel = useCallback(() => {
    setShowModal(false);
  }, [setShowModal]);

  const onSave = useCallback(
    async (entity: Partial<SharedContent>) => {
      await createMutationAsync(
        { body: entity },
        {
          onSuccess: (result) =>
            history.push(route(getDetailLink(sharedContentConstants.frontendIndexRoute, result.id))),
        }
      ).catch(() => {});
    },
    [createMutationAsync, history, route]
  );

  return (
    <GenericModal
      showModal={showModal}
      setShowModal={setShowModal}
      modalTitle={"Add shared content"}
      modalBody={
        <div className="flex" style={{ width: "70vw", padding: "10px 15px", overflow: "auto" }}>
          <LoadingWrapper status={status} fetchStatus={fetchStatus} error={error}>
            <form
              onSubmit={(e) => {
                e.preventDefault();
              }}
              autoComplete="off"
              className={`form-horizontal`}
              style={{ width: "100%" }}
            >
              <InputFormField
                id={"name"}
                label={"Name"}
                errors={errors}
                register={register}
                autoFocus={true}
                required={true}
              />
              <TextareaFormField id="publicNotes" label="Public Notes" errors={errors} register={register} />
              <InputFormField
                id={"password"}
                label={"Password"}
                errors={errors}
                register={register}
                required={false}
                type={"text"}
              />
              <TextareaFormField id="privateNotes" label="Private Notes" errors={errors} register={register} />
              <DatasetsVirtualizedSelectForm id="datasets" control={control} isMulti showControls horizontal />
              {/* <SelectFormField
                  id={"datasets"}
                  label={"Datasets"}
                  items={datasetSuggestions}
                  required={true}
                  control={control}
                  isMulti={true}
                /> */}
              <ToggleFormField id={"allowContentDownload"} label="Allow Download" control={control} horizontal />

              {errors && <FormErrors errors={errors as any} groupName={""} />}
            </form>
          </LoadingWrapper>
        </div>
      }
      modalControls={
        <div
          className="flex row-nowrap gap-5"
          style={{ height: "min-content", width: "min-content", marginLeft: "auto" }}
        >
          <Button className="btn btn-default" title="Cancel" onClick={onCancel} loading={isLoadingCreateMutation}>
            Cancel
          </Button>
          <Button
            className="btn btn-primary"
            title="Create"
            onClick={() => {
              handleSubmit(async (entity) => await onSave(entity))();
            }}
            disabled={isSubmitting}
            loading={isLoadingCreateMutation}
          >
            Create
          </Button>
        </div>
      }
    />
  );
};
