import React from "react";
// import ReactResizeDetector from "react-resize-detector";

export const EllipsisTextBlock = ({ text, ...otherProps }: { text: string; width?: number }) => {
  return (
    // <ReactResizeDetector handleWidth>
    <TextBlock text={text} {...otherProps} />
    // </ReactResizeDetector>
  );
};

export const TextBlock = ({ text, width, ...otherProps }: { text: string; width?: number }) => {
  return (
    <div style={{ width: "100%" }}>
      <div style={{ width: width, overflow: "hidden", textOverflow: "ellipsis" }} {...otherProps}>
        {text}
      </div>
    </div>
  );
};
