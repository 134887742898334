import React, { CSSProperties } from "react";
import styles from "./CircularProgress.module.css";

interface CircularProgressProps {
  style?: CSSProperties;
  progress: number;
  width: number;
}
export const CircularProgress = ({ style, progress, width }: CircularProgressProps) => {
  const size = width;
  const strokeWidth = 3;
  const center = size / 2;
  const radius = center - strokeWidth;
  const dashArray = 2 * Math.PI * radius;
  const dashOffset = dashArray * ((100 - progress) / 100);

  return (
    <div className={styles.progress_ring_wrapper} style={{ width: width, height: width }}>
      <svg className={styles.progress_ring} width={width} height={width}>
        <circle
          className={styles.circle_track}
          stroke="var(--gray-200)"
          strokeWidth={strokeWidth}
          fill="transparent"
          r={radius}
          cx={center}
          cy={center}
        />
        <circle
          className={styles.circle_indicator}
          stroke="var(--success)"
          strokeWidth={strokeWidth}
          strokeDasharray={dashArray}
          strokeDashoffset={isNaN(dashOffset) ? 0 : dashOffset}
          strokeLinecap={"round"}
          fill="transparent"
          r={radius}
          cx={center}
          cy={center}
          style={style}
        />
      </svg>
    </div>
  );
};
