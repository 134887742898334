import React, { useEffect, useRef, useState } from "react";
import { useResizeDetector } from "react-resize-detector";
import { FixedSizeList } from "react-window";
import InfiniteLoader from "react-window-infinite-loader";
import styles from "./Hexviewer.module.css";
/**
 * This component is designed to show Array buffer in Hex, utilizing infinite scrolling and virtualization
 * Author: CS
 * @param: buffer, loading, callback
 * @returns: JSX.Element
 */

const asHex = (i: number) => {
  const h = i.toString(16).toUpperCase();
  return h.length % 2 !== 0 ? "0" + h : h;
};
const asAscii = (i: number) => {
  return isPrintable(i) ? String.fromCharCode(i) : ".";
};
const isPrintable = (asciiCode: number) => {
  return asciiCode >= 0x20 && asciiCode < 0x7f;
};
const decimalToHex = (decimal: number, chars: number) => {
  return (decimal + Math.pow(16, chars)).toString(16).slice(-chars).toUpperCase();
};

interface Data {
  [offset: string]: {
    hex: string[];
    ascii: string[];
  };
}

interface Props {
  buffer: ArrayBufferLike;
  loading?: boolean;
  reachedBottomCallback?: () => void;
}
export const Hexviewer = ({ buffer, loading = false, reachedBottomCallback }: Props) => {
  const listInnerRef = useRef<HTMLDivElement>(null);
  const { width, height, ref } = useResizeDetector();
  const [data, setData] = useState<Data>();
  const hex_index = Array.from(Array(16).keys());

  useEffect(() => {
    const result = Array.from(new Uint8Array(buffer)).reduce((resultArray: number[][], item, index) => {
      const chunkIndex = Math.floor(index / 16);
      if (!resultArray[chunkIndex]) {
        resultArray[chunkIndex] = []; // start a new chunk
      }
      resultArray[chunkIndex].push(item);
      return resultArray;
    }, []);

    const offsets = Array.from(Array(result.length).keys()).map((x) => x * 16);

    const mapped = Object.fromEntries(
      offsets.map((offset, i) => [
        offset,
        { hex: result[i].map((x) => asHex(x)), ascii: result[i].map((x) => asAscii(x)) },
      ])
    );

    setData(() => mapped);
    return () => setData(undefined);
  }, [buffer]);

  const loadMoreItems = loading ? () => {} : reachedBottomCallback;

  return (
    <div className={`${styles.container} ${loading && styles.loading}`} ref={ref}>
      <div className={styles.header}>
        <div className={styles.headerdesc}>Offset</div>
        <div className={styles.headerhex}>
          {hex_index.map((x, index) => (
            <span className={styles.span_item} key={`header_${index}`}>
              {decimalToHex(x, 2)}
            </span>
          ))}
        </div>
        <div className={styles.indexhex}>
          {hex_index.map((x, index) => (
            <span className={styles.span_item_sm} key={`index_${index}`}>
              {decimalToHex(x, 1)}
            </span>
          ))}
        </div>
      </div>
      <div className={styles.body} ref={listInnerRef}>
        {data && ref.current && width && height && (
          <InfiniteLoader
            isItemLoaded={(index) => index < Object.keys(data).length - 1}
            itemCount={Object.keys(data).length}
            loadMoreItems={loadMoreItems as any}
          >
            {({ onItemsRendered, ref: infiniteRef }) => (
              <FixedSizeList
                itemData={Object.entries(data)}
                itemCount={Object.keys(data).length}
                onItemsRendered={onItemsRendered}
                ref={infiniteRef}
                itemSize={20}
                height={height - 40}
                width={width}
              >
                {({ data, index, style }) => {
                  const styl = index % 2 === 0 ? { ...style, background: "var(--gray-200)" } : style;
                  return (
                    <div className={styles.row} style={styl} key={`row_${index}`}>
                      <div className={styles.offset} key={`offset_${index}`}>
                        {data[index][0]}
                      </div>
                      <div className={styles.hex} key={`hex_${index}`}>
                        {hex_index.map((d, i) => (
                          <span key={`hex_${index}_${i}`} className={`${styles.span_item} ${styles.outline}`}>
                            {data[index][1].hex[d] ?? " "}
                          </span>
                        ))}
                      </div>
                      <div className={styles.ascii} key={`ascii_${index}`}>
                        {hex_index.map((d, i) => (
                          <span key={`ascii_${index}_${i}`} className={`${styles.span_item_sm} ${styles.outline}`}>
                            {data[index][1].ascii[d] ?? " "}
                          </span>
                        ))}
                      </div>
                    </div>
                  );
                }}
              </FixedSizeList>
            )}
          </InfiniteLoader>
        )}
      </div>
    </div>
  );
};
