import { AutoloadClient } from "./DataSource";
import {
  PropertyTranslator,
  IGenericRequestParametersTranslator,
  IPaginationParametersTranslator,
  IRelationParametersTranslator,
  INamedEntityParametersTranslator,
  INamedEntityLabels,
} from "./GenericTranslator";
import {
  DefaultOrderTypeConsts,
  EntityConstants,
  IEntityMinimalModel,
  IGenericRequestParameters,
  IModelWithIntId,
  INamedEntity,
  INamedEntityParameters,
  IPaginationParameters,
  IPermissionedEntity,
  IRelatedEntity,
  IRelation,
  IRelationModel,
  IRelationParameters,
} from "./GenericTypes";

// Autoload source
export const bridgeConstants: EntityConstants<Bridge, BridgeFilters> = {
  resource: "bridges",
  frontendIndexRoute: "bridges",
  entitySingular: "bridge",
  entityPlural: "bridges",
  icon: "unplug",
};

export const BridgeFieldLabels: PropertyTranslator<Bridge> & {
  operatingSystem: string;
  executablePath: string;
  clientVersion: string;
  datasets: string;
} = {
  ...INamedEntityLabels,
  id: "Bridge ID",
  type: "Connection type",
  hostname: "Host name / IP address",
  port: "Port",
  ipAddress: "IP address",
  isConnected: "Status",
  username: "User name",
  password: "Password",
  privateKey: "Private key",
  description: "Description",
  areMultipleClientsConnected: "Multiple clients connected",
  connectedClients: "Connected clients",
  permissions: "Permissions",
  relations: "Relations",
  sftpAuthenticationMethod: "Authentication method",
  operatingSystem: "Operating system",
  executablePath: "Executable path",
  clientVersion: "Client version",
  datasets: "Datasets",
} as const;

export const BridgeValidationOptions = [
  { id: "Password" as BridgeValidationOption, name: "Password" },
  { id: "PrivateKey" as BridgeValidationOption, name: "SSH private key" },
];

export type BridgeType = "Unknown" | "Client" | "SFTP";
export const BridgeTypes = ["Unknown", "Client", "SFTP"] as BridgeType[];
export const BridgeTypeFormValues = BridgeTypes.map((t) => ({ id: t, name: t }));
export type BridgeValidationOption = "PrivateKey" | "Password";
export interface Bridge extends IPermissionedEntity, IModelWithIntId, INamedEntity, IRelatedEntity<BridgeRelations> {
  type: BridgeType;
  hostname: string;
  ipAddress: string;
  description: string;
  username?: string;
  port?: number;
  password?: string;
  privateKey?: string;
  connectedClients: AutoloadClient[];
  isConnected: boolean;
  areMultipleClientsConnected: boolean;
  sftpAuthenticationMethod?: BridgeValidationOption;
}

export interface BridgeRelations extends IRelationModel {
  datasets: IRelation;
  dataSources: IRelation;
}

export interface BridgeSuggestions extends IEntityMinimalModel<Bridge["id"]> {
  type: BridgeType;
  ipAddress: string;
  hostname: string;
}

export interface BridgeFilters
  extends IGenericRequestParameters<Bridge, BridgeSortingOptions>,
    IPaginationParameters,
    IRelationParameters,
    INamedEntityParameters {
  hostnames?: string[] | null;
  usernames?: string[] | null;
  ipAddresses?: string[] | null;
  dataSourceIds?: number[] | null;
  types?: BridgeType[] | null;
  datasetIds?: number[] | null;
  isConfigured?: boolean | null;
}

export const BridgeFiltersTranslator: PropertyTranslator<BridgeFilters> = {
  ...IPaginationParametersTranslator,
  ...IGenericRequestParametersTranslator,
  ...INamedEntityParametersTranslator,
  ...IRelationParametersTranslator,
  hostnames: "Host names",
  usernames: "User names",
  ipAddresses: "IP-Addresses",
  dataSourceIds: "Data source IDs",
  types: "Bridge types",
  datasetIds: "Datasets",
  isConfigured: "Is configured",
} as const;

export const BridgeSortingOptionsConsts = [...DefaultOrderTypeConsts, "TYPE_ASC", "TYPE_DESC"] as const;

export type BridgeSortingOptions = (typeof BridgeSortingOptionsConsts)[number];
