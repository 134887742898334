import { DefinedPropertyObject, IEntityMinimalModel } from "../../api/GenericTypes";
import { Sample, SampleFilters } from "../../api/Samples";
import { ToggleFormField } from "../../common/formfields/ToggleFormField";
import { FilterSidebarProps, useFilterSidebar } from "../../common/sidebar/filtersidebar/FilterSidebar";
import { PersonsVirtualizedSelectForm } from "../../common/forms/EntityForms/formsVirtualized/PersonsVirtualizedSelectForm";
import { OrganizationsVirtualizedSelectForm } from "../../common/forms/EntityForms/formsVirtualized/OrganizationsVirtualizedSelectForm";
import { ProjectsVirtualizedSelectForm } from "../../common/forms/EntityForms/formsVirtualized/ProjectsVirtualizedSelectForm";
import { EntityFilterSideBar } from "../../common/sidebar/filtersidebar/EntityFilterBar";
import { SplitDatePickerFormField } from "../../common/formfields/DatePickerFormField/SplitDatePickerFormField";
import { date2localDate } from "../../common/datetime/DateTimeFormatter";
import { CustomTypesVirtualizedSelectForm } from "../../common/forms/EntityForms/formsVirtualized/CustomTypesVirtualizedSelectForm";

export interface SampleFilterForm {
  project?: IEntityMinimalModel[] | undefined | null;
  organization?: IEntityMinimalModel[] | undefined | null;
  preparedBy?: IEntityMinimalModel[] | undefined | null;
  preparedAtFrom?: Date | undefined | null;
  preparedAtTo?: Date | undefined | null;
  customType?: { id: number; name: string }[] | undefined | null;
  includeSoftDeleted?: boolean;
  createdByIds?: IEntityMinimalModel[] | undefined | null;
  createdFrom?: Date | undefined | null;
  createdTo?: Date | undefined | null;
  modifiedByIds?: IEntityMinimalModel[] | undefined | null;
  modifiedFrom?: Date | undefined | null;
  modifiedTo?: Date | undefined | null;
  discardedByIds?: IEntityMinimalModel[] | undefined | null;
  discardedAtFrom?: Date | undefined | null;
  discardedAtTo?: Date | undefined | null;
  excludeDiscarded?: boolean;
}
const defaultValues: DefinedPropertyObject<SampleFilterForm> = {
  project: null,
  organization: null,
  preparedBy: null,
  preparedAtFrom: null,
  preparedAtTo: null,
  customType: null,
  includeSoftDeleted: false,
  createdByIds: null,
  createdFrom: null,
  createdTo: null,
  modifiedByIds: null,
  modifiedFrom: null,
  modifiedTo: null,
  discardedByIds: null,
  discardedAtFrom: null,
  discardedAtTo: null,
  excludeDiscarded: false,
};
const formToFilters = (form: SampleFilterForm): SampleFilters => {
  let filters: SampleFilters = {};

  if (!!form.preparedAtFrom) {
    const preparedAtFrom = date2localDate(form.preparedAtFrom);
    preparedAtFrom.setUTCHours(0, 0, 0, 0);
    filters.preparedAtFrom = preparedAtFrom.toISOString();
  } else {
    filters.preparedAtFrom = null;
  }

  if (!!form.preparedAtTo) {
    const preparedAtTo = date2localDate(form.preparedAtTo);
    preparedAtTo.setUTCHours(0, 0, 0, 0);
    filters.preparedAtTo = preparedAtTo.toISOString();
  } else {
    filters.preparedAtTo = null;
  }

  filters.customTypeIds =
    Array.isArray(form.customType) && form.customType.length > 0 ? form.customType.map((d) => d.id) : null;
  filters.projectIds = Array.isArray(form.project) && form.project.length > 0 ? form.project?.map((d) => d.id) : null;
  filters.organizationIds =
    Array.isArray(form.organization) && form.organization.length > 0 ? form.organization?.map((d) => d.id) : null;
  filters.preparedByIds =
    form.preparedBy && Array.isArray(form.preparedBy) && form.preparedBy.length > 0
      ? form.preparedBy.map((d) => d.id)
      : null;
  filters.includeSoftDeleted = form.includeSoftDeleted;
  filters.createdByIds =
    Array.isArray(form.createdByIds) && form.createdByIds.length > 0 ? form.createdByIds?.map((d) => d.id) : null;
  filters.createdFrom = form.createdFrom ? date2localDate(form.createdFrom).toISOString() : null;
  filters.createdTo = form.createdTo ? date2localDate(form.createdTo).toISOString() : null;
  filters.modifiedByIds =
    Array.isArray(form.modifiedByIds) && form.modifiedByIds.length > 0 ? form.modifiedByIds?.map((d) => d.id) : null;
  filters.modifiedFrom = form.modifiedFrom ? date2localDate(form.modifiedFrom).toISOString() : null;
  filters.modifiedTo = form.modifiedTo ? date2localDate(form.modifiedTo).toISOString() : null;

  filters.excludeDiscarded = form.excludeDiscarded;
  filters.discardedByIds =
    form.discardedByIds && Array.isArray(form.discardedByIds) && form.discardedByIds.length > 0
      ? form.discardedByIds.map((d) => d.id)
      : null;
  filters.discardedAtFrom = form.discardedAtFrom ? date2localDate(form.discardedAtFrom).toISOString() : null;
  filters.discardedAtTo = form.discardedAtTo ? date2localDate(form.discardedAtTo).toISOString() : null;
  return filters;
};
const defaultFilters: SampleFilters = { orderBy: "PREPARATION_DATE_DESC" };

export const SampleFilterBar = ({
  initialValues,
  setFilters,
  onFormChange,
  dispatchTabStore,
  tabsLoading,
  currentTab,
}: FilterSidebarProps<Sample, SampleFilterForm, SampleFilters>) => {
  const { control, onReset } = useFilterSidebar({
    initialValues,
    defaultValues,
    defaultFilters,
    formToFilters,
    onFormChange,
    dispatchTabStore,
    setFilters,
  });
  return (
    <EntityFilterSideBar onReset={onReset}>
      <CustomTypesVirtualizedSelectForm
        id="customType"
        label="Sample types"
        placeholder="Select sample types..."
        control={control}
        showControls={false}
        filters={{ entityTypes: ["Sample"] }}
        isMulti
        enableBasicOption
        hideIfNoTypes
      />

      <PersonsVirtualizedSelectForm
        id="preparedBy"
        label="Prepared By"
        control={control}
        showControls={false}
        isMulti
      />

      <SplitDatePickerFormField
        control={control}
        idStart="preparedAtFrom"
        idEnd="preparedAtTo"
        label="Preparation date (from - to)"
        horizontal={false}
      />

      <ProjectsVirtualizedSelectForm id="project" control={control} showControls={false} isMulti />
      <OrganizationsVirtualizedSelectForm id="organization" control={control} showControls={false} isMulti />

      <PersonsVirtualizedSelectForm id="discardedByIds" label="Discarded by" control={control} isMulti />

      <SplitDatePickerFormField
        control={control}
        idStart="discardedAtFrom"
        idEnd="discardedAtTo"
        label="Discarded date (from - to)"
        horizontal={false}
      />

      {currentTab !== "mydata" && (
        <PersonsVirtualizedSelectForm id="createdByIds" label="Created by" control={control} isMulti />
      )}
      <SplitDatePickerFormField
        control={control}
        idStart="createdFrom"
        idEnd="createdTo"
        label="Creation date (from - to)"
        horizontal={false}
        showTimeSelect
      />
      <PersonsVirtualizedSelectForm id="modifiedByIds" label="Modified by" control={control} isMulti />

      <SplitDatePickerFormField
        control={control}
        idStart="modifiedFrom"
        idEnd="modifiedTo"
        label="Modification date (from - to)"
        horizontal={false}
        showTimeSelect
      />

      <ToggleFormField id="excludeDiscarded" control={control} label="Exclude discarded" horizontal />

      {currentTab !== "trash" && (
        <ToggleFormField id={"includeSoftDeleted"} control={control} label={"Include trashed"} horizontal />
      )}
    </EntityFilterSideBar>
  );
};
