// import { Modal } from "react-bootstrap";
// import styles from "./styles.module.css";
import { CSSProperties, useState } from "react";
import React from "react";
import { Modal } from "../../../ELN/common/ELNModal/ELNModal";
import styles from "./styles.module.css";

interface Props {
  showModal: boolean;
  setShowModal: (value: React.SetStateAction<boolean>) => void;
  modalTitle?: string | React.ReactNode;
  styleTitle?: CSSProperties;
  modalBody?: string | React.ReactNode;
  styleBody?: CSSProperties;
  modalControls?: string | React.ReactNode;
  styleFooter?: CSSProperties;
  dialogClassName?: string;
}
export const GenericModal = ({
  showModal,
  setShowModal,
  modalTitle,
  styleTitle,
  modalBody,
  styleBody,
  modalControls,
  styleFooter,
  dialogClassName = "",
}: Props) => {
  // const { width, height, ref } = useResizeDetector();
  return (
    <Modal
      isOpen={showModal}
      onClose={() => {
        setShowModal(false);
      }}
    >
      {modalTitle && (
        <Modal.Title clsNames={styles.modal_header} style={styleTitle}>
          {modalTitle}
        </Modal.Title>
      )}
      {modalBody && (
        <Modal.Body clsNames={styles.modal_body} style={styleBody}>
          {modalBody}
        </Modal.Body>
      )}
      {modalControls && (
        <Modal.Controls clsNames={styles.modal_footer} style={styleFooter}>
          {modalControls}
        </Modal.Controls>
      )}
    </Modal>
  );
};

interface GenericModalWrapperChildrenProps {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
}
interface GenericModalWrapperProps {
  defaultShowModal?: boolean;
  children: ({ showModal, setShowModal }: GenericModalWrapperChildrenProps) => React.ReactNode;
}
// This is a minimal wrapper to provide a state
export const GenericModalWrapper = ({ defaultShowModal = false, children }: GenericModalWrapperProps) => {
  const [showModal, setShowModal] = useState(defaultShowModal);
  return <React.Fragment>{children({ showModal, setShowModal })}</React.Fragment>;
};
