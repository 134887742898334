import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  AutoloadClientsReadDirectory,
  useAutoloadSourceReadDirectory,
} from "../../../../api/autoload/AutoloadClientsApi";
import { DateTimeRenderer } from "../../../../common/datetime/DateTimeFormatter";
import { LucideIcon } from "../../../../common/icon/LucideIcon";
import { LoadingWrapper } from "../../../../common/LoadingWrapper";
import { Alert } from "../../../../common/overlays/Alert/Alert";
import { formatBytes } from "../../../../Dataset/common/Tree/Folder/helpers/FileHelpers";
import path from "path";
import styles from "./FileBrowser.module.css";
import { Bridge } from "../../../../api/Bridges";

// Helpers

// Handle for interactive input browsing

const selectPath = (index: number, paths: string[] | undefined) => {
  if (!Array.isArray(paths)) return undefined;
  return paths?.slice(0, index + 2).join(path.sep);
  // _path && setDir(path.sep + _path);
};

const splitPath = (_path: string | undefined, callback: (path: string) => void) => {
  if (!_path)
    return (
      <div className={styles.path_row}>
        <div key={`separator_home`}>/</div>
      </div>
    );
  const paths = _path.split(path.sep);
  const filteredPaths = paths.filter((d) => d);
  if (filteredPaths && filteredPaths.length > 0) {
    return (
      <div className={styles.path_row}>
        {filteredPaths.map((d, index) => (
          <div style={{ display: "flex", flexFlow: "row nowrap" }} key={index}>
            <div key={`separator_${index}`}>/</div>
            <div
              key={`path_${index}`}
              className={styles.path_part}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                const _path = selectPath(index, paths);
                if (_path) callback(_path);
              }}
            >
              {d}
            </div>
          </div>
        ))}
      </div>
    );
  } else {
    return (
      <div className={styles.path_row}>
        <div key={`separator_home`}>/</div>
      </div>
    );
  }
};

interface FileProps {
  file: AutoloadClientsReadDirectory;
  rowClick: (file: AutoloadClientsReadDirectory) => void;
  rowAdd: (file: AutoloadClientsReadDirectory) => void;
}
const File = ({ file, rowClick, rowAdd }: FileProps) => {
  // const [isCollapsed, setIsCollapsed] = useState(true);
  const entity_icon = file.isDir ? <LucideIcon name="folder" /> : <LucideIcon name="file" />;
  const entity_cls = file.isDir ? styles.folder : styles.file;

  return (
    <div className={styles.browser_row}>
      {/* {file.isDir && (
        <div className={styles.icon_add}>
          <button
            className="btn btn-xs btn-success"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              rowAdd(file);
            }}
            title="Add this directory"
          >
            <FeatherIcon name="plus" />
          </button>
        </div>
      )} */}
      <div
        className={`${styles.file_row} ${entity_cls}`}
        onClick={() => rowClick(file)}
        style={{ cursor: `${file.isDir ? "pointer" : "default"}` }}
      >
        <div className={styles.icon}>{entity_icon}</div>
        <div className={styles.label}>{file.name}</div>
        <div className={styles.controls} style={{ whiteSpace: "nowrap" }}>
          Last modified: <DateTimeRenderer date={file.modTime} />
        </div>
        <div className={styles.controls}>{formatBytes(file.size)}</div>
      </div>
    </div>
  );
};

interface FileBrowserProps {
  bridgeId: Bridge["id"];
  directory?: string;
  addCallback: (dir: string) => void;
}
export const FileBrowser = ({ bridgeId, directory = path.sep, addCallback }: FileBrowserProps) => {
  const [dir, setDir] = useState(directory);
  const [inputToggle, setInputToggle] = useState(false);
  const [input, setInput] = useState<string>();
  const { data, status, fetchStatus, error } = useAutoloadSourceReadDirectory(
    bridgeId!,
    { directory: dir },
    {
      enabled: !!(bridgeId && dir),
    }
  );
  const files = useMemo(() => {
    if (data)
      return data
        .sort((a, b) => (a.isDir ? -1 : 1))
        .sort((a, b) => (a.isDir && b.isDir ? (a.name.toLocaleLowerCase() > b.name.toLocaleLowerCase() ? 1 : -1) : 0))
        .sort((a, b) => (!a.isDir && !b.isDir ? (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1) : 0));
  }, [data]);
  // Handle to navigate when clicking a row
  const rowClick = (file: AutoloadClientsReadDirectory) => {
    if (file.isDir) {
      if (dir === path.sep) {
        // Hack for handling Windows drives for now
        if (file.name.includes(":")) setDir((dir) => file.name);
        else setDir((dir) => path.sep + file.name);
      } else {
        setDir((dir) => [dir, file.name].join(path.sep));
      }
    }
  };
  // Handle to trigger callback when selecting a path to be added
  const rowAdd = (file: AutoloadClientsReadDirectory) => {
    if (dir === path.sep) {
      addCallback(path.sep + file.name);
    } else {
      addCallback([dir, file.name].join(path.sep));
    }
  };
  // Navigate one directory up handle
  const backClick = (e: React.MouseEvent) => {
    e.preventDefault();
    if (e.detail === 0) {
      e.preventDefault();
      e.stopPropagation();
    } else {
      const paths = dir.split(path.sep).slice(0, -1);
      if (paths.length > 1) {
        setDir(paths.join(path.sep));
      } else {
        setDir(path.sep);
      }
    }
  };
  // Hook to update the input field
  useEffect(() => {
    setInput(dir);
    return () => setInput(undefined);
  }, [dir]);

  // Debounced Hook to update the current path if manual input
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      input && setDir(input);
    }, 300);
    return () => clearTimeout(delayDebounceFn);
  }, [input]);

  // Toggle between the input field and interactive path
  const parent = useCallback((node: HTMLDivElement) => setNode(node), []);
  const [node, setNode] = useState<HTMLDivElement>();
  const handleClick = useCallback(
    (event: MouseEvent) => {
      if (node && !node.contains(event.target as Node)) {
        setInputToggle(false);
      } else {
        setInputToggle(true);
      }
    },
    [node]
  );
  // Hook to detect clicking outside of the input component
  useEffect(() => {
    document.addEventListener("click", handleClick);
    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, [handleClick]);

  return (
    <div className={styles.browse_container}>
      <div className={styles.row}>
        {dir !== path.sep && (
          <button className="btn btn-sm btn-soft-secondary" onClick={backClick}>
            <LucideIcon name="arrow-up" />
          </button>
        )}
        <div className={styles.input_path} ref={parent}>
          <div className={styles.input_icon}>
            <LucideIcon name="folder" />
          </div>
          {inputToggle ? (
            <input
              className={`form-control ${styles.input}`}
              value={input}
              onChange={(e) => setInput(e.target.value)}
              autoFocus
            />
          ) : (
            <div className={`form-control ${styles.input_interactive}`}>{splitPath(input, setDir)}</div>
          )}
        </div>
      </div>
      <div className={styles.files_container}>
        <LoadingWrapper status={status} fetchStatus={fetchStatus} error={error}>
          {files && files.length > 0 ? (
            <div style={{ paddingRight: "5px" }}>
              {files.map((file, index) => (
                <File key={index} file={file} rowClick={rowClick} rowAdd={rowAdd} />
              ))}
            </div>
          ) : (
            <Alert type="secondary" message="No results" fit centered />
          )}
        </LoadingWrapper>
      </div>
      <div className={styles.files_controls}>
        <button
          className="btn btn-success"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            addCallback(dir);
          }}
        >
          <LucideIcon name="plus" /> Add current directory
        </button>
      </div>
    </div>
  );
};
