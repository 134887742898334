import { About } from "../About/About";
import { API } from "../api/Api";
import { bridgeConstants } from "../api/Bridges";
import { genericEntityConstants } from "../api/GenericConstants";
import { iconNMRConstants } from "../api/IconNMR";
import {
  AnnouncementAddFormPage,
  AnnouncementCloneFormPage,
  AnnouncementDetailsPage,
  AnnouncementEditFormPage,
  AnnouncementsTablePage,
} from "../Announcements/AnnouncementViews";
import { AttachmentDetailsPage, AttachmentEditFormPage, AttachmentsTablePage } from "../Attachments/AttachmentViews";
import { AuditLogsTablePage } from "../AuditLog/AuditLogViews";
import { BridgeClientWizard } from "../Autoloads/Wizard/BridgeClientWizard";
import { SFTPBridgeClientWizard } from "../Autoloads/Wizard/SFTPBridgeClientWizard";
import {
  BridgeCloneFormPage,
  BridgeDetailsPage,
  BridgeEditFormPage,
  BridgesTablePage,
} from "../Autoloads/Bridges/BridgeViews";
import {
  DataSourceAddFormPage,
  DataSourceCloneFormPage,
  DataSourceDetailsPage,
  DataSourceEditFormPage,
  DataSourceTablePage,
} from "../Autoloads/DataSources/DataSourceViews";
import {
  IconNMRDataSourceAddFormPage,
  IconNMRDataSourceCloneFormPage,
  IconNMRDataSourceDetailsPage,
  IconNMRDataSourceEditFormPage,
} from "../Autoloads/IconNMR/IconNMRViews";
import { ComponentOverview } from "../common/components/ComponentOverview";
import { SessionContext } from "../common/contexts/SessionContext";
import { useFeatureFlag } from "../common/contexts/UseFeatureFlags";
import {
  CustomFieldAddFormPage,
  CustomFieldCloneFormPage,
  CustomFieldDetailsPage,
  CustomFieldEditFormPage,
  CustomFieldsTablePage,
} from "../Customization/CustomFields/CustomFieldViews";
import {
  CustomTypeAddFormPage,
  CustomTypeCloneFormPage,
  CustomTypeDetailsPage,
  CustomTypeEditFormPage,
  CustomTypesTablePage,
} from "../Customization/CustomTypes/CustomTypeViews";
import {
  DatasetAddForm,
  DatasetClaimFormPage,
  DatasetDetailsPage,
  DatasetEditFormPage,
  DatasetsTablePage,
} from "../Dataset/DatasetViews";
import { DatasetsBulkEditTable } from "../Dataset/Table/DatasetsBulkEditTable";
import { Dashboard } from "../Dashboard/Dashboard";
import {
  EquipmentAddFormPage,
  EquipmentCloneFormPage,
  EquipmentDetailsPage,
  EquipmentEditFormPage,
  EquipmentsTablePage,
} from "../Equipments/EquipmentViews";
import {
  EquipmentTagAddFormPage,
  EquipmentTagCloneFormPage,
  EquipmentTagDetailsPage,
  EquipmentTagEditFormPage,
  EquipmentTagsTablePage,
} from "../EquipmentTags/EquipmentTagViews";
import {
  ExperimentAddFormPage,
  ExperimentCloneFormPage,
  ExperimentDetailsPage,
  ExperimentEditFormPage,
  ExperimentsTablePage,
} from "../Experiments/ExperimentViews";
import { IconNMR } from "../IconNMR/IconNMR";
import {
  InventoriesTablePage,
  InventoryDetailsRedirect,
  InventoryItemAddFormPage,
  InventoryItemCloneFormPage,
  InventoryItemDetailsPage,
  InventoryItemDetailsRedirect,
  InventoryItemEditFormPage,
  InventoryItemsTablePage,
  InventoryItemTreePage,
} from "../Inventories/InventoryItemViews";
import { Maintenance } from "../Maintenance/Maintenance";
import { Manage } from "../Manage/Manage";
import {
  MethodAddFormPage,
  MethodCloneFormPage,
  MethodDetailsPage,
  MethodEditFormPage,
  MethodsTablePage,
} from "../Methods/MethodViews";
import { Notebooks } from "../ELN/ELN";
import {
  OrganizationAddFormPage,
  OrganizationDetailsPage,
  OrganizationEditFormPage,
  OrganizationsTablePage,
} from "../Organizations/OrganizationViews";
import {
  OrganizationTagAddFormPage,
  OrganizationTagDetailsPage,
  OrganizationTagEditFormPage,
  OrganizationTagsTablePage,
} from "../OrganizationTags/OrganizationTagViews";
import { PageNotFound } from "./common/PageNotFound/PageNotFound";
import { ParsersTablePage } from "../Parsers/ParserViews";
import {
  PersonAddFormPage,
  PersonCloneFormPage,
  PersonDetailsPage,
  PersonEditFormPage,
  PersonsTablePage,
} from "../Persons/PersonViews";
import {
  PersonTagAddFormPage,
  PersonTagCloneFormPage,
  PersonTagDetailsPage,
  PersonTagEditFormPage,
  PersonTagsTablePage,
} from "../PersonTags/PersonTagViews";
import {
  ProjectAddFormPage,
  ProjectCloneFormPage,
  ProjectDetailsPage,
  ProjectEditFormPage,
  ProjectsTablePage,
} from "../Projects/ProjectViews";
import {
  ProjectTagAddFormPage,
  ProjectTagDetailsPage,
  ProjectTagEditFormPage,
  ProjectTagsTablePage,
} from "../ProjectTags/ProjectTagViews";
import {
  RoleAddFormPage,
  RoleCloneFormPage,
  RoleDetailsPage,
  RoleEditFormPage,
  RolesTablePage,
} from "../Roles/RoleViews";
import { Route, Switch, useHistory, useLocation } from "react-router-dom";
import {
  SampleAddFormPage,
  SampleCloneFormPage,
  SampleDetailsPage,
  SampleEditFormPage,
  SamplesTablePage,
} from "../samples/SampleViews";
import { SamplesBulkEditTable } from "../samples/Table/SamplesBulkEditTable";
import {
  SharedContentAddFormPage,
  SharedContentCloneFormPage,
  SharedContentDetailsPage,
  SharedContentEditFormPage,
  SharedContentsTablePage,
} from "../SharedBundles/SharedContentViews";
import { ApiKeyDataLayer } from "../ApiKeyManager/ApiKeyDataLayer";
import { EntitiesRedirect } from "../Entities/EntitiesRedirect";
import { EntitiesSearch } from "../Entities/EntitiesSearch";
import {
  getAddRoute,
  getBulkEditRoute,
  getCloneRoute,
  getDetailRoute,
  getEditRoute,
  getIndexRoute,
  getUUIDRoute,
} from "./Routing";
import { useContext } from "react";
import {
  InstrumentsTablePage,
  InstrumentAddFormPage,
  InstrumentEditFormPage,
  InstrumentCloneFormPage,
  InstrumentDetailsPage,
} from "../Instruments/InstrumentViews";
import { ParsersSelectionTable } from "../Parsers/ParserSelectionTable";
import { LegacyRouteRedirect } from "./common/LegacyRouteRedirect/LegacyRouteRedirect";

// import { SharedBundles } from "../SharedBundles/SharedBundles";
export const MainRouter = () => {
  const { route, api } = useContext(SessionContext);
  return <MainRoutingTable route={route} api={api} />;
};

// We embed all routes in LOGS even though only a part is used for each embedded page
// But the Conductance routing has priority there, so the other routes simply won't be hit
export const EmbeddedRouter = () => {
  const { route, api } = useContext(SessionContext);
  return <MainRoutingTable route={route} api={api} />;
};

export const MainRoutingTable = ({ route, api }: { route: (url: string) => string; api: API }) => {
  const location = useLocation();
  const history = useHistory();
  const isElnActivated = useFeatureFlag("eln");
  const isDevelopmentMode = useFeatureFlag("development_mode");

  // Legacy support for hash routing
  if (location.hash.startsWith("#")) {
    history.push(location.hash.replace("#", "")); // or history.replace
  }

  return (
    <>
      {/* Redirect routes */}
      <LegacyRouteRedirect
        from={"bundles"}
        to={genericEntityConstants.sharedContents.entityConstants.frontendIndexRoute}
      />
      {/* This doesn't work as we need a mapping from document_id to inventory_id */}
      {/* <LegacyRouteRedirect from={"documents"} to={inventoriesConstants.frontendIndexRoute} /> */}

      {/* Main routes */}
      <Switch>
        {/* Dashboard */}
        <Route path={route(getIndexRoute("dashboard"))} exact>
          <Dashboard />
        </Route>

        {/* Parser selection */}
        <Route path={route(getIndexRoute("parser_selections"))} exact>
          <Manage>
            {/* Permission check is done in the table */}
            <ParsersSelectionTable />
          </Manage>
        </Route>

        {/* About */}
        <Route path={route(getIndexRoute("about"))} exact>
          <About />
        </Route>

        {/* Announcements */}
        <Route
          path={route(getIndexRoute(genericEntityConstants.announcements.entityConstants.frontendIndexRoute))}
          exact
        >
          <Manage>
            <AnnouncementsTablePage />
          </Manage>
        </Route>
        <Route path={route(getAddRoute(genericEntityConstants.announcements.entityConstants.frontendIndexRoute))}>
          <Manage>
            <AnnouncementAddFormPage />
          </Manage>
        </Route>
        <Route path={route(getEditRoute(genericEntityConstants.announcements.entityConstants.frontendIndexRoute))}>
          <Manage>
            <AnnouncementEditFormPage />
          </Manage>
        </Route>
        <Route path={route(getCloneRoute(genericEntityConstants.announcements.entityConstants.frontendIndexRoute))}>
          <Manage>
            <AnnouncementCloneFormPage />
          </Manage>
        </Route>
        <Route path={route(getDetailRoute(genericEntityConstants.announcements.entityConstants.frontendIndexRoute))}>
          <Manage>
            <AnnouncementDetailsPage />
          </Manage>
        </Route>

        {/* Apikey */}
        <Route path={route(getIndexRoute(genericEntityConstants.apiKeys.entityConstants.frontendIndexRoute))} exact>
          <Manage>
            <ApiKeyDataLayer api={api} />
          </Manage>
        </Route>

        {/* Attachments */}
        <Route path={route(getIndexRoute(genericEntityConstants.attachments.entityConstants.frontendIndexRoute))} exact>
          <Manage>
            <AttachmentsTablePage />
          </Manage>
        </Route>
        <Route path={route(getEditRoute(genericEntityConstants.attachments.entityConstants.frontendIndexRoute))}>
          <Manage>
            <AttachmentEditFormPage />
          </Manage>
        </Route>
        <Route path={route(getDetailRoute(genericEntityConstants.attachments.entityConstants.frontendIndexRoute))}>
          <Manage>
            <AttachmentDetailsPage />
          </Manage>
        </Route>

        {/* Auditlog */}
        <Route path={route(getIndexRoute(genericEntityConstants.auditLogs.entityConstants.frontendIndexRoute))} exact>
          <Manage>
            <AuditLogsTablePage />
          </Manage>
        </Route>

        {/* Autoloads -> Bridges (former "sources") */}
        <Route path={route(getIndexRoute(genericEntityConstants.bridges.entityConstants.frontendIndexRoute))} exact>
          <Manage>
            <BridgesTablePage />
          </Manage>
        </Route>
        <Route path={route(getAddRoute(genericEntityConstants.bridges.entityConstants.frontendIndexRoute))}>
          <Manage>
            <BridgeClientWizard />
          </Manage>
        </Route>
        <Route
          path={route(
            `${getIndexRoute(genericEntityConstants.bridges.entityConstants.frontendIndexRoute)}/sftp_wizard`
          )}
        >
          <Manage>
            <SFTPBridgeClientWizard />
          </Manage>
        </Route>
        <Route path={route(getEditRoute(genericEntityConstants.bridges.entityConstants.frontendIndexRoute))}>
          <Manage>
            <BridgeEditFormPage />
          </Manage>
        </Route>
        <Route path={route(getCloneRoute(genericEntityConstants.bridges.entityConstants.frontendIndexRoute))}>
          <Manage>
            <BridgeCloneFormPage />
          </Manage>
        </Route>
        <Route path={route(getDetailRoute(genericEntityConstants.bridges.entityConstants.frontendIndexRoute))} exact>
          <Manage>
            <BridgeDetailsPage />
          </Manage>
        </Route>

        {/* Autoloads -> Data sources (former configurations) */}
        <Route path={route(getIndexRoute(genericEntityConstants.dataSources.entityConstants.frontendIndexRoute))} exact>
          <Manage>
            <DataSourceTablePage />
          </Manage>
        </Route>
        <Route path={route(getAddRoute(genericEntityConstants.dataSources.entityConstants.frontendIndexRoute))}>
          <Manage>
            <DataSourceAddFormPage />
          </Manage>
        </Route>
        <Route path={route(getEditRoute(genericEntityConstants.dataSources.entityConstants.frontendIndexRoute))}>
          <Manage>
            <DataSourceEditFormPage />
          </Manage>
        </Route>
        <Route path={route(getCloneRoute(genericEntityConstants.dataSources.entityConstants.frontendIndexRoute))}>
          <Manage>
            <DataSourceCloneFormPage />
          </Manage>
        </Route>
        <Route path={route(getDetailRoute(genericEntityConstants.dataSources.entityConstants.frontendIndexRoute))}>
          <Manage>
            <DataSourceDetailsPage />
          </Manage>
        </Route>
        <Route
          path={route(
            `${getIndexRoute(bridgeConstants.frontendIndexRoute)}/:bridgeId${getAddRoute(
              genericEntityConstants.dataSources.entityConstants.frontendIndexRoute
            )}`
          )}
        >
          <Manage>
            <DataSourceAddFormPage />
          </Manage>
        </Route>
        <Route
          path={route(
            `${getIndexRoute(bridgeConstants.frontendIndexRoute)}/:bridgeId${getEditRoute(
              genericEntityConstants.dataSources.entityConstants.frontendIndexRoute
            )}`
          )}
        >
          <Manage>
            <DataSourceEditFormPage />
          </Manage>
        </Route>
        <Route
          path={route(
            `${getIndexRoute(bridgeConstants.frontendIndexRoute)}/:bridgeId${getCloneRoute(
              genericEntityConstants.dataSources.entityConstants.frontendIndexRoute
            )}`
          )}
        >
          <Manage>
            <DataSourceCloneFormPage />
          </Manage>
        </Route>
        <Route
          path={route(
            `${getIndexRoute(bridgeConstants.frontendIndexRoute)}/:bridgeId${getDetailRoute(
              genericEntityConstants.dataSources.entityConstants.frontendIndexRoute
            )}`
          )}
        >
          <Manage>
            <DataSourceDetailsPage />
          </Manage>
        </Route>

        {/* Custom Fields */}
        <Route
          path={route(getIndexRoute(genericEntityConstants.customFields.entityConstants.frontendIndexRoute))}
          exact
        >
          <Manage>
            <CustomFieldsTablePage />
          </Manage>
        </Route>
        <Route path={route(getAddRoute(genericEntityConstants.customFields.entityConstants.frontendIndexRoute))}>
          <Manage>
            <CustomFieldAddFormPage />
          </Manage>
        </Route>
        <Route path={route(getEditRoute(genericEntityConstants.customFields.entityConstants.frontendIndexRoute))}>
          <Manage>
            <CustomFieldEditFormPage />
          </Manage>
        </Route>
        <Route path={route(getCloneRoute(genericEntityConstants.customFields.entityConstants.frontendIndexRoute))}>
          <Manage>
            <CustomFieldCloneFormPage />
          </Manage>
        </Route>
        <Route path={route(getDetailRoute(genericEntityConstants.customFields.entityConstants.frontendIndexRoute))}>
          <Manage>
            <CustomFieldDetailsPage />
          </Manage>
        </Route>

        {/* Custom Types */}
        <Route path={route(getIndexRoute(genericEntityConstants.customTypes.entityConstants.frontendIndexRoute))} exact>
          <Manage>
            <CustomTypesTablePage />
          </Manage>
        </Route>
        <Route path={route(getAddRoute(genericEntityConstants.customTypes.entityConstants.frontendIndexRoute))}>
          <Manage>
            <CustomTypeAddFormPage />
          </Manage>
        </Route>
        <Route path={route(getEditRoute(genericEntityConstants.customTypes.entityConstants.frontendIndexRoute))}>
          <Manage>
            <CustomTypeEditFormPage />
          </Manage>
        </Route>
        <Route path={route(getCloneRoute(genericEntityConstants.customTypes.entityConstants.frontendIndexRoute))}>
          <Manage>
            <CustomTypeCloneFormPage />
          </Manage>
        </Route>
        <Route path={route(getDetailRoute(genericEntityConstants.customTypes.entityConstants.frontendIndexRoute))}>
          <Manage>
            <CustomTypeDetailsPage />
          </Manage>
        </Route>

        {/* Datasets */}
        <Route path={route(getIndexRoute(genericEntityConstants.datasets.entityConstants.frontendIndexRoute))} exact>
          <DatasetsTablePage />
        </Route>
        <Route path={route(getAddRoute(genericEntityConstants.datasets.entityConstants.frontendIndexRoute))}>
          <DatasetAddForm />
        </Route>
        <Route path={route(getBulkEditRoute(genericEntityConstants.datasets.entityConstants.frontendIndexRoute))}>
          <DatasetsBulkEditTable />
        </Route>
        <Route path={route(getEditRoute(genericEntityConstants.datasets.entityConstants.frontendIndexRoute))}>
          <DatasetEditFormPage />
        </Route>
        <Route
          path={route(`${getIndexRoute(genericEntityConstants.datasets.entityConstants.frontendIndexRoute)}/:id/claim`)}
        >
          <DatasetClaimFormPage />
        </Route>
        <Route
          path={route(
            `${getIndexRoute(genericEntityConstants.datasets.entityConstants.frontendIndexRoute)}/:id/viewer`
          )}
        >
          <DatasetDetailsPage viewViewer={true} />
        </Route>
        <Route path={route(getDetailRoute(genericEntityConstants.datasets.entityConstants.frontendIndexRoute))}>
          <DatasetDetailsPage />
        </Route>

        {/* Dataset formats */}
        <Route path={route(getIndexRoute(genericEntityConstants.parsers.entityConstants.frontendIndexRoute))} exact>
          <Manage>
            <ParsersTablePage />
          </Manage>
        </Route>

        {/* Equipments */}
        <Route path={route(getIndexRoute(genericEntityConstants.equipments.entityConstants.frontendIndexRoute))} exact>
          <Manage>
            <EquipmentsTablePage />
          </Manage>
        </Route>
        <Route path={route(getAddRoute(genericEntityConstants.equipments.entityConstants.frontendIndexRoute))}>
          <Manage>
            <EquipmentAddFormPage />
          </Manage>
        </Route>
        <Route path={route(getEditRoute(genericEntityConstants.equipments.entityConstants.frontendIndexRoute))}>
          <Manage>
            <EquipmentEditFormPage />
          </Manage>
        </Route>
        <Route path={route(getCloneRoute(genericEntityConstants.equipments.entityConstants.frontendIndexRoute))}>
          <Manage>
            <EquipmentCloneFormPage />
          </Manage>
        </Route>
        <Route path={route(getDetailRoute(genericEntityConstants.equipments.entityConstants.frontendIndexRoute))}>
          <Manage>
            <EquipmentDetailsPage />
          </Manage>
        </Route>

        {/* Equipment Tags */}
        <Route
          path={route(getIndexRoute(genericEntityConstants.equipmentTags.entityConstants.frontendIndexRoute))}
          exact
        >
          <Manage>
            <EquipmentTagsTablePage />
          </Manage>
        </Route>
        <Route path={route(getAddRoute(genericEntityConstants.equipmentTags.entityConstants.frontendIndexRoute))}>
          <Manage>
            <EquipmentTagAddFormPage />
          </Manage>
        </Route>
        <Route path={route(getEditRoute(genericEntityConstants.equipmentTags.entityConstants.frontendIndexRoute))}>
          <Manage>
            <EquipmentTagEditFormPage />
          </Manage>
        </Route>
        <Route path={route(getCloneRoute(genericEntityConstants.equipmentTags.entityConstants.frontendIndexRoute))}>
          <Manage>
            <EquipmentTagCloneFormPage />
          </Manage>
        </Route>
        <Route path={route(getDetailRoute(genericEntityConstants.equipmentTags.entityConstants.frontendIndexRoute))}>
          <Manage>
            <EquipmentTagDetailsPage />
          </Manage>
        </Route>

        {/* Experiments */}
        <Route path={route(getIndexRoute(genericEntityConstants.experiments.entityConstants.frontendIndexRoute))} exact>
          <Manage>
            <ExperimentsTablePage />
          </Manage>
        </Route>
        <Route path={route(getAddRoute(genericEntityConstants.experiments.entityConstants.frontendIndexRoute))}>
          <Manage>
            <ExperimentAddFormPage />
          </Manage>
        </Route>
        <Route path={route(getEditRoute(genericEntityConstants.experiments.entityConstants.frontendIndexRoute))}>
          <Manage>
            <ExperimentEditFormPage />
          </Manage>
        </Route>
        <Route path={route(getCloneRoute(genericEntityConstants.experiments.entityConstants.frontendIndexRoute))}>
          <Manage>
            <ExperimentCloneFormPage />
          </Manage>
        </Route>
        <Route path={route(getDetailRoute(genericEntityConstants.experiments.entityConstants.frontendIndexRoute))}>
          <Manage>
            <ExperimentDetailsPage />
          </Manage>
        </Route>

        {/* IconNMR */}
        <Route path={route(getIndexRoute(iconNMRConstants.frontendIndexRoute))} exact>
          <Manage>
            <IconNMR />
          </Manage>
        </Route>
        <Route path={`${route(getIndexRoute(iconNMRConstants.frontendIndexRoute))}/overview`} exact>
          <Manage>
            <IconNMR />
          </Manage>
        </Route>
        <Route path={`${route(getIndexRoute(iconNMRConstants.frontendIndexRoute))}/queue`} exact>
          <Manage>
            <IconNMR />
          </Manage>
        </Route>
        <Route path={`${route(getIndexRoute(iconNMRConstants.frontendIndexRoute))}/history`} exact>
          <Manage>
            <IconNMR />
          </Manage>
        </Route>
        <Route path={route(getAddRoute(iconNMRConstants.frontendIndexRoute))}>
          <Manage>
            <IconNMRDataSourceAddFormPage />
          </Manage>
        </Route>
        <Route path={route(getEditRoute(iconNMRConstants.frontendIndexRoute))}>
          <Manage>
            <IconNMRDataSourceEditFormPage />
          </Manage>
        </Route>
        <Route path={route(getCloneRoute(iconNMRConstants.frontendIndexRoute))}>
          <Manage>
            <IconNMRDataSourceCloneFormPage />
          </Manage>
        </Route>
        <Route path={route(getDetailRoute(iconNMRConstants.frontendIndexRoute))}>
          <Manage>
            <IconNMRDataSourceDetailsPage />
          </Manage>
        </Route>

        {/* Inventories */}
        <Route path={route(getIndexRoute(genericEntityConstants.inventories.entityConstants.frontendIndexRoute))} exact>
          <InventoriesTablePage />
        </Route>
        <Route
          path={`${route(
            getIndexRoute(genericEntityConstants.inventories.entityConstants.frontendIndexRoute)
          )}/:customTypeId/hierarchy`}
          exact
        >
          <InventoryItemTreePage />
        </Route>
        <Route
          path={`${route(
            getIndexRoute(genericEntityConstants.inventories.entityConstants.frontendIndexRoute)
          )}/:customTypeId/hierarchy/:id`}
        >
          <InventoryItemDetailsRedirect />
        </Route>

        <Route
          path={`${route(
            getIndexRoute(genericEntityConstants.inventories.entityConstants.frontendIndexRoute)
          )}/:customTypeId/items`}
          exact
        >
          <InventoryItemsTablePage />
        </Route>
        <Route
          path={route(getIndexRoute(genericEntityConstants.inventoryItems.entityConstants.frontendIndexRoute))}
          exact
        >
          <InventoryItemsTablePage />
        </Route>
        <Route
          path={`${route(
            getIndexRoute(genericEntityConstants.inventories.entityConstants.frontendIndexRoute)
          )}/:customTypeId/items/new`}
        >
          <InventoryItemAddFormPage />
        </Route>
        <Route path={route(getAddRoute(genericEntityConstants.inventoryItems.entityConstants.frontendIndexRoute))}>
          <InventoryItemAddFormPage />
        </Route>
        <Route
          path={`${route(
            getIndexRoute(genericEntityConstants.inventories.entityConstants.frontendIndexRoute)
          )}/:customTypeId/items/:id/edit`}
        >
          <InventoryItemEditFormPage />
        </Route>
        <Route path={route(getEditRoute(genericEntityConstants.inventoryItems.entityConstants.frontendIndexRoute))}>
          <InventoryItemEditFormPage />
        </Route>
        <Route
          path={`${route(
            getIndexRoute(genericEntityConstants.inventories.entityConstants.frontendIndexRoute)
          )}/:customTypeId/items/:id/clone`}
        >
          <InventoryItemCloneFormPage />
        </Route>
        <Route path={route(getCloneRoute(genericEntityConstants.inventoryItems.entityConstants.frontendIndexRoute))}>
          <InventoryItemCloneFormPage />
        </Route>
        <Route
          path={`${route(
            getIndexRoute(genericEntityConstants.inventories.entityConstants.frontendIndexRoute)
          )}/:customTypeId/items/:id`}
        >
          <InventoryItemDetailsPage />
        </Route>
        <Route path={route(getDetailRoute(genericEntityConstants.inventoryItems.entityConstants.frontendIndexRoute))}>
          <InventoryItemDetailsPage />
        </Route>
        <Route path={route(getDetailRoute(genericEntityConstants.inventories.entityConstants.frontendIndexRoute))}>
          <InventoryDetailsRedirect />
        </Route>

        {/* Maintenance */}
        <Route path={route(getIndexRoute("maintenance"))} exact>
          <Manage>
            {/* Permission check is done in the table */}
            <Maintenance />
          </Manage>
        </Route>

        {/* Manage */}
        <Route path={route(getIndexRoute("manage"))} exact>
          <Manage />
        </Route>

        {/* Methods */}
        <Route path={route(getIndexRoute(genericEntityConstants.methods.entityConstants.frontendIndexRoute))} exact>
          <Manage>
            <MethodsTablePage />
          </Manage>
        </Route>
        <Route path={route(getAddRoute(genericEntityConstants.methods.entityConstants.frontendIndexRoute))}>
          <Manage>
            <MethodAddFormPage />
          </Manage>
        </Route>
        <Route path={route(getEditRoute(genericEntityConstants.methods.entityConstants.frontendIndexRoute))}>
          <Manage>
            <MethodEditFormPage />
          </Manage>
        </Route>
        <Route path={route(getCloneRoute(genericEntityConstants.methods.entityConstants.frontendIndexRoute))}>
          <Manage>
            <MethodCloneFormPage />
          </Manage>
        </Route>
        <Route path={route(getDetailRoute(genericEntityConstants.methods.entityConstants.frontendIndexRoute))}>
          <Manage>
            <MethodDetailsPage />
          </Manage>
        </Route>

        {/* Notebooks */}
        {isElnActivated && (
          <Route path={route(getIndexRoute(genericEntityConstants.notebooks.entityConstants.frontendIndexRoute))}>
            <Notebooks />
          </Route>
        )}

        {/* Organizations */}
        <Route
          path={route(getIndexRoute(genericEntityConstants.organizations.entityConstants.frontendIndexRoute))}
          exact
        >
          <Manage>
            <OrganizationsTablePage />
          </Manage>
        </Route>
        <Route path={route(getAddRoute(genericEntityConstants.organizations.entityConstants.frontendIndexRoute))}>
          <Manage>
            <OrganizationAddFormPage />
          </Manage>
        </Route>
        <Route path={route(getEditRoute(genericEntityConstants.organizations.entityConstants.frontendIndexRoute))}>
          <Manage>
            <OrganizationEditFormPage />
          </Manage>
        </Route>
        <Route path={route(getDetailRoute(genericEntityConstants.organizations.entityConstants.frontendIndexRoute))}>
          <Manage>
            <OrganizationDetailsPage />
          </Manage>
        </Route>

        {/* Organization tags */}
        <Route
          path={route(getIndexRoute(genericEntityConstants.organizationTags.entityConstants.frontendIndexRoute))}
          exact
        >
          <Manage>
            <OrganizationTagsTablePage />
          </Manage>
        </Route>
        <Route path={route(getAddRoute(genericEntityConstants.organizationTags.entityConstants.frontendIndexRoute))}>
          <Manage>
            <OrganizationTagAddFormPage />
          </Manage>
        </Route>
        <Route path={route(getEditRoute(genericEntityConstants.organizationTags.entityConstants.frontendIndexRoute))}>
          <Manage>
            <OrganizationTagEditFormPage />
          </Manage>
        </Route>
        <Route path={route(getDetailRoute(genericEntityConstants.organizationTags.entityConstants.frontendIndexRoute))}>
          <Manage>
            <OrganizationTagDetailsPage />
          </Manage>
        </Route>

        {/* Persons */}
        <Route path={route(getIndexRoute(genericEntityConstants.persons.entityConstants.frontendIndexRoute))} exact>
          <Manage>
            <PersonsTablePage />
          </Manage>
        </Route>
        <Route path={route(getAddRoute(genericEntityConstants.persons.entityConstants.frontendIndexRoute))}>
          <Manage>
            <PersonAddFormPage />
          </Manage>
        </Route>
        <Route path={route(getEditRoute(genericEntityConstants.persons.entityConstants.frontendIndexRoute))}>
          <Manage>
            <PersonEditFormPage />
          </Manage>
        </Route>
        <Route path={route(getCloneRoute(genericEntityConstants.persons.entityConstants.frontendIndexRoute))}>
          <Manage>
            <PersonCloneFormPage />
          </Manage>
        </Route>
        <Route path={route(getDetailRoute(genericEntityConstants.persons.entityConstants.frontendIndexRoute))}>
          <Manage>
            <PersonDetailsPage />
          </Manage>
        </Route>

        {/* Person Tags */}
        <Route path={route(getIndexRoute(genericEntityConstants.personTags.entityConstants.frontendIndexRoute))} exact>
          <Manage>
            <PersonTagsTablePage />
          </Manage>
        </Route>
        <Route path={route(getAddRoute(genericEntityConstants.personTags.entityConstants.frontendIndexRoute))}>
          <Manage>
            <PersonTagAddFormPage />
          </Manage>
        </Route>
        <Route path={route(getEditRoute(genericEntityConstants.personTags.entityConstants.frontendIndexRoute))}>
          <Manage>
            <PersonTagEditFormPage />
          </Manage>
        </Route>
        <Route path={route(getCloneRoute(genericEntityConstants.personTags.entityConstants.frontendIndexRoute))}>
          <Manage>
            <PersonTagCloneFormPage />
          </Manage>
        </Route>
        <Route path={route(getDetailRoute(genericEntityConstants.personTags.entityConstants.frontendIndexRoute))}>
          <Manage>
            <PersonTagDetailsPage />
          </Manage>
        </Route>

        {/* Projects */}
        <Route path={route(getIndexRoute(genericEntityConstants.projects.entityConstants.frontendIndexRoute))} exact>
          <ProjectsTablePage />
        </Route>
        <Route path={route(getAddRoute(genericEntityConstants.projects.entityConstants.frontendIndexRoute))}>
          <ProjectAddFormPage />
        </Route>
        <Route path={route(getEditRoute(genericEntityConstants.projects.entityConstants.frontendIndexRoute))}>
          <ProjectEditFormPage />
        </Route>
        <Route path={route(getCloneRoute(genericEntityConstants.projects.entityConstants.frontendIndexRoute))}>
          <ProjectCloneFormPage />
        </Route>
        <Route path={route(getDetailRoute(genericEntityConstants.projects.entityConstants.frontendIndexRoute))}>
          <ProjectDetailsPage />
        </Route>

        {/* ProjectTags */}
        <Route path={route(getIndexRoute(genericEntityConstants.projectTags.entityConstants.frontendIndexRoute))} exact>
          <Manage>
            <ProjectTagsTablePage />
          </Manage>
        </Route>
        <Route path={route(getAddRoute(genericEntityConstants.projectTags.entityConstants.frontendIndexRoute))}>
          <Manage>
            <ProjectTagAddFormPage />
          </Manage>
        </Route>
        <Route path={route(getEditRoute(genericEntityConstants.projectTags.entityConstants.frontendIndexRoute))}>
          <Manage>
            <ProjectTagEditFormPage />
          </Manage>
        </Route>
        <Route path={route(getDetailRoute(genericEntityConstants.projectTags.entityConstants.frontendIndexRoute))}>
          <Manage>
            <ProjectTagDetailsPage />
          </Manage>
        </Route>

        {/* Roles */}
        <Route path={route(getIndexRoute(genericEntityConstants.roles.entityConstants.frontendIndexRoute))} exact>
          <Manage>
            <RolesTablePage />
          </Manage>
        </Route>
        <Route path={route(getAddRoute(genericEntityConstants.roles.entityConstants.frontendIndexRoute))}>
          <Manage>
            <RoleAddFormPage />
          </Manage>
        </Route>
        <Route path={route(getEditRoute(genericEntityConstants.roles.entityConstants.frontendIndexRoute))}>
          <Manage>
            <RoleEditFormPage />
          </Manage>
        </Route>
        <Route path={route(getCloneRoute(genericEntityConstants.roles.entityConstants.frontendIndexRoute))}>
          <Manage>
            <RoleCloneFormPage />
          </Manage>
        </Route>
        <Route path={route(getDetailRoute(genericEntityConstants.roles.entityConstants.frontendIndexRoute))}>
          <Manage>
            <RoleDetailsPage />
          </Manage>
        </Route>

        {/* Samples */}
        <Route path={route(getIndexRoute(genericEntityConstants.samples.entityConstants.frontendIndexRoute))} exact>
          <SamplesTablePage />
        </Route>
        <Route path={route(getBulkEditRoute(genericEntityConstants.samples.entityConstants.frontendIndexRoute))}>
          <SamplesBulkEditTable />
        </Route>
        <Route path={route(getAddRoute(genericEntityConstants.samples.entityConstants.frontendIndexRoute))}>
          <SampleAddFormPage />
        </Route>
        <Route path={route(getEditRoute(genericEntityConstants.samples.entityConstants.frontendIndexRoute))}>
          <SampleEditFormPage />
        </Route>
        <Route path={route(getCloneRoute(genericEntityConstants.samples.entityConstants.frontendIndexRoute))}>
          <SampleCloneFormPage />
        </Route>
        <Route path={route(getDetailRoute(genericEntityConstants.samples.entityConstants.frontendIndexRoute))}>
          <SampleDetailsPage />
        </Route>

        {/* Shared content */}

        <Route
          path={route(getIndexRoute(genericEntityConstants.sharedContents.entityConstants.frontendIndexRoute))}
          exact
        >
          <Manage>
            <SharedContentsTablePage />
          </Manage>
        </Route>
        <Route path={route(getAddRoute(genericEntityConstants.sharedContents.entityConstants.frontendIndexRoute))}>
          <Manage>
            <SharedContentAddFormPage />
          </Manage>
        </Route>
        <Route path={route(getEditRoute(genericEntityConstants.sharedContents.entityConstants.frontendIndexRoute))}>
          <Manage>
            <SharedContentEditFormPage />
          </Manage>
        </Route>
        <Route path={route(getCloneRoute(genericEntityConstants.sharedContents.entityConstants.frontendIndexRoute))}>
          <Manage>
            <SharedContentCloneFormPage />
          </Manage>
        </Route>
        <Route path={route(getDetailRoute(genericEntityConstants.sharedContents.entityConstants.frontendIndexRoute))}>
          <Manage>
            <SharedContentDetailsPage />
          </Manage>
        </Route>

        {/* Instruments */}
        <Route path={route(getIndexRoute(genericEntityConstants.instruments.entityConstants.frontendIndexRoute))} exact>
          <Manage>
            <InstrumentsTablePage />
          </Manage>
        </Route>
        <Route path={route(getAddRoute(genericEntityConstants.instruments.entityConstants.frontendIndexRoute))}>
          <Manage>
            <InstrumentAddFormPage />
          </Manage>
        </Route>
        <Route path={route(getEditRoute(genericEntityConstants.instruments.entityConstants.frontendIndexRoute))}>
          <Manage>
            <InstrumentEditFormPage />
          </Manage>
        </Route>
        <Route path={route(getCloneRoute(genericEntityConstants.instruments.entityConstants.frontendIndexRoute))}>
          <Manage>
            <InstrumentCloneFormPage />
          </Manage>
        </Route>
        <Route path={route(getDetailRoute(genericEntityConstants.instruments.entityConstants.frontendIndexRoute))}>
          <Manage>
            <InstrumentDetailsPage />
          </Manage>
        </Route>

        {/* UUID */}
        <Route path={route(getIndexRoute("uuid"))} exact>
          <Manage>
            <EntitiesSearch />
          </Manage>
        </Route>
        <Route path={route(getUUIDRoute("uuid"))}>
          <EntitiesRedirect />
        </Route>

        {/* Devel only routes */}
        {isDevelopmentMode && (
          <Route path={route(getIndexRoute("components"))} exact>
            <ComponentOverview api={api} />
          </Route>
        )}

        {/* Page not found */}
        <Route path="/:group/*">
          <PageNotFound />
        </Route>
      </Switch>
    </>
  );
};
