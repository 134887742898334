import React, { useCallback, useEffect, useState } from "react";
import { LucideIcon } from "../../icon/LucideIcon";
import styles from "./SearchInput.module.css";
import { searchTermMinLength } from "../SearchInputWithOptions/SearchInputWithOptions";

/**
 * A search input component.
 * @author @CorradoSurmanowicz
 * @param {object} props - The component props.
 * @param {string} [props.placeholder="Search"] - The placeholder text for the search input.
 * @param {string} props.searchValue - The current value of the search input.
 * @param {function} props.setSearchValue - The function to update the search value.
 * @param {"sm" | "lg"} [props.size] - The size of the search input.
 * @param {React.ReactNode} [props.children] - The children components.
 * @param {boolean} [props.minimized=false] - Whether the search input is initially minimized.
 * @param {string} [props.minimizedBtnCls] - The CSS class for the minimized button.
 * @param {boolean} [props.minimizedDisabled=false] - Whether the minimized button is disabled.
 * @param {string} [props.title] - The title attribute for the search input container.
 * @returns {JSX.Element} The rendered SearchInput component.
 */

export interface SearchInputProps {
  placeholder?: string;
  searchValue: string;
  setSearchValue: React.Dispatch<React.SetStateAction<string>> | ((searchValue: any) => void);
  size?: "sm" | "lg";
  children?: React.ReactNode;
  minimized?: boolean;
  minimizedBtnCls?: string;
  minimizedDisabled?: boolean;
  title?: string;
  error?: string;
  disabled?: boolean;
  disableDefaultError?: boolean;
}
export const SearchInput = ({
  placeholder = "Search",
  searchValue,
  setSearchValue,
  size,
  children,
  minimized = false,
  minimizedBtnCls,
  minimizedDisabled = false,
  title,
  error,
  disabled,
  disableDefaultError = true,
}: SearchInputProps) => {
  const [visible, setVisible] = useState(minimized);
  const [node, setNode] = useState<HTMLDivElement>();
  const parent = useCallback((node: HTMLDivElement) => setNode(node), []);

  const handleClick = useCallback(
    (event: MouseEvent) => {
      if (minimized) {
        if (node && node.contains(event.target as Node)) {
          setVisible(false);
        } else {
          setVisible(true);
        }
      }
    },
    [minimized, node]
  );

  useEffect(() => {
    document.addEventListener("mousedown", handleClick);
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, [handleClick]);

  const _error = disableDefaultError
    ? error
    : searchValue && searchValue.length < searchTermMinLength
    ? `Please enter at least ${searchTermMinLength} characters...`
    : undefined;

  return (
    <div className={styles.search} ref={parent} title={title}>
      {visible ? (
        <button
          className={minimizedBtnCls || "btn btn-sm btn-ghost-primary"}
          title={title ?? "Search"}
          onClick={() => setVisible(!visible)}
          disabled={minimizedDisabled}
        >
          <LucideIcon name="search" />
        </button>
      ) : (
        <React.Fragment>
          <div className={styles.quicksearch}>
            <div className="flex col-nowrap" style={{ width: "100%" }}>
              <div className={styles.input}>
                <span className={styles.search_icon}>
                  <LucideIcon name="search" />
                </span>
                <input
                  className={`form-control input ${styles.form_control} ${size ? `input-${size}` : ""}`}
                  placeholder={placeholder}
                  value={searchValue}
                  type="search"
                  onChange={(e) => setSearchValue(e.currentTarget.value)}
                  disabled={disabled}
                />

                <span className={styles.cancel_icon} onClick={() => setSearchValue(() => "")}>
                  {searchValue && <LucideIcon name="x" />}
                </span>
              </div>
              {_error && <div className={styles.error}>{_error}</div>}
            </div>
          </div>
          {children}
        </React.Fragment>
      )}
    </div>
  );
};
