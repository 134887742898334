import { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { LucideIcon } from "../../../common/icon/LucideIcon";
import styles from "./ToolbarDropdownButton.module.css";

interface ToolbarOption {
  label?: string;
  icon?: ReactNode;
  iconColor?: string;
  function: Function;
  active: boolean;
  tooltip?: string;
  disabled?: boolean;
}

export const ToolbarDropdownButton = ({
  toolsOnBar,
  toolsOnDropdown,
  buttonsPerRow,
  defaultIndex,
}: {
  toolsOnBar?: ToolbarOption[];
  toolsOnDropdown: ToolbarOption[];
  buttonsPerRow?: number;
  defaultIndex?: number;
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [activeButton, setActiveButton] = useState<number>(defaultIndex || 0);

  const dropdownRef = useRef<HTMLDivElement>(null);
  const closeToolbarDropdown = useCallback((e: MouseEvent) => {
    if (!dropdownRef.current?.contains(e.target as any)) setIsOpen(false);
  }, []);

  useEffect(() => {
    if (isOpen) document.addEventListener("mousedown", closeToolbarDropdown);
    return () => document.removeEventListener("mousedown", closeToolbarDropdown);
  }, [closeToolbarDropdown, isOpen]);

  const toolbarLength = useMemo(() => {
    if (toolsOnDropdown.length === 0) return toolsOnBar && toolsOnBar.length > 0 ? toolsOnBar.length : 0;
    return toolsOnBar && toolsOnBar.length > 0 ? toolsOnBar.length + 1 : 1;
  }, [toolsOnBar, toolsOnDropdown.length]);

  const dropdownGridLength = useMemo(() => {
    return buttonsPerRow || toolsOnDropdown.length;
  }, [buttonsPerRow, toolsOnDropdown]);

  return (
    <div ref={dropdownRef} className={styles.toolbarDropdownButtonContainer}>
      <div
        className={styles.toolbarDropdownGrid}
        style={{ gridTemplateColumns: `repeat(${toolbarLength}, minmax(0, 1fr))` }}
      >
        {/* ALWAYS VISIBLE */}
        {toolsOnBar &&
          toolsOnBar.length > 0 &&
          toolsOnBar.map((t, index) => (
            <button
              disabled={t.disabled}
              key={index}
              className={t.active ? styles.toolbarDropdownButtonActive : styles.toolbarDropdownButton}
              onMouseDown={(event) => event.preventDefault()}
              onClick={() => t.function()}
              style={
                index === 0
                  ? {
                      borderTopLeftRadius: "5px",
                      borderBottomLeftRadius: "5px",
                      cursor: t.disabled ? "not-allowed" : "pointer",
                    }
                  : {
                      cursor: t.disabled ? "not-allowed" : "pointer",
                    }
              }
              data-toggle="tooltip"
              title={t.tooltip || ""}
            >
              {t.icon && t.icon}
              {t.label && <span className={styles.toolbarDropdownLabel}>{t.label}</span>}
            </button>
          ))}

        {/* LAST SELECTED */}
        {toolsOnDropdown.length > 0 && (
          <button
            className={
              toolsOnDropdown[activeButton].active ? styles.toolbarDropdownButtonActive : styles.toolbarDropdownButton
            }
            onMouseDown={(event) => event.preventDefault()}
            onClick={() => toolsOnDropdown[activeButton].function()}
            style={
              !toolsOnBar || toolsOnBar.length === 0
                ? {
                    borderTopLeftRadius: "5px",
                    borderBottomLeftRadius: "5px",
                    cursor: toolsOnDropdown[activeButton].disabled ? "not-allowed" : "pointer",
                  }
                : {
                    cursor: toolsOnDropdown[activeButton].disabled ? "not-allowed" : "pointer",
                  }
            }
            data-toggle="tooltip"
            title={toolsOnDropdown[activeButton].tooltip || ""}
            disabled={toolsOnDropdown[activeButton].disabled}
          >
            {toolsOnDropdown[activeButton].icon && toolsOnDropdown[activeButton].icon}
            {toolsOnDropdown[activeButton].label && (
              <span className={styles.toolbarDropdownLabel}>{toolsOnDropdown[activeButton].label}</span>
            )}
          </button>
        )}
      </div>

      {/* TOGGLE */}
      {toolsOnDropdown.length > 0 && (
        <div
          className={styles.toolbarDropdownToggle}
          onMouseDown={(event) => event.preventDefault()}
          onClick={() => setIsOpen((prevState) => !prevState)}
        >
          <LucideIcon
            name="chevron-up"
            strokeWidth={1}
            className={isOpen ? styles.toolbarDropdownToggleIconActive : styles.toolbarDropdownToggleIcon}
            width={13}
            height={13}
          />
        </div>
      )}

      {/* DROPDOWN */}
      {isOpen && toolsOnDropdown.length > 0 && (
        <div
          className={styles.toolbarDropdownContainer}
          style={{
            bottom: `-${Math.ceil(toolsOnDropdown.length / dropdownGridLength) * 30 + 11}px`,
          }}
        >
          <div className={styles.toolbarDropdownConnectionArrowContainer}>
            <div className={styles.toolbarDropdownConnectionArrow} />
          </div>
          <div
            className={styles.toolbarDropdownContentContainer}
            style={{ gridTemplateColumns: `repeat(${dropdownGridLength}, minmax(0, 1fr))` }}
          >
            {toolsOnDropdown.map((t, index) => (
              <button
                key={index}
                className={styles.toolbarDropdownContentButton}
                onMouseDown={(event) => event.preventDefault()}
                onClick={(e) => {
                  e.preventDefault();
                  t.function();
                  setActiveButton(index);
                  setIsOpen(false);
                }}
                data-toggle="tooltip"
                title={t.tooltip || ""}
                disabled={t.disabled}
                style={{
                  cursor: t.disabled ? "not-allowed" : "pointer",
                }}
              >
                {t.icon && t.icon}
                {t.label && <span className={styles.toolbarDropdownLabel}>{t.label}</span>}
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
