import { API } from "../api/Api";
import {
  DatatrackBinary,
  DatatrackImage,
  DatatrackNumericArray,
  DatatrackNumericMatrix,
  DatatrackTable,
  Track,
  TrackBinaryData,
  TrackData,
  TrackImageData,
  TrackTableData,
  TrackXYData,
} from "../ViewerLayout/ViewerLayoutTypes";
import { Datatrack, Level, TrackMatrixData } from "./../ViewerLayout/ViewerLayoutTypes";
import { initDatatrackNumericArray } from "./../ViewerLayout/ViewerLayoutUtils";
import { trackState } from "./../ViewerLayout/ViewerTypes";
import { fetchNewTiles, getNewTiles, getTrackData } from "./GraphApi";

export type promiseType = { id: string; payload: TrackData | undefined };
export type range = [number, number];

export const raceTilePromises = async (
  promises: Record<string, Promise<any>>,
  setDatatracks?: (data: TrackData[]) => void,
  setTrackState?: (trackState: { id: string; state: trackState }[]) => void,
  maxWaitTime: number = 500
) => {
  let trackData: promiseType[] = [];
  // console.log("promises", promises);
  while (Object.keys(promises).length > 0) {
    const last = performance.now();
    const promise = await Promise.race<promiseType>(Object.values(promises));

    promises = Object.fromEntries(Object.entries(promises).filter(([id]) => id !== promise.id));

    trackData.push(promise);

    if (performance.now() - last > maxWaitTime) {
      const data = trackData.filter((promise) => promise?.payload).map((promise) => promise.payload as TrackData);
      const state = trackData.map((promise) => {
        return { id: promise.id, state: promise?.payload ? trackState.ready : trackState.failed };
      });
      if (state.length > 0) {
        if (setDatatracks) setDatatracks(data);
        if (setTrackState) setTrackState(state);
      }
      trackData = [];
    }
  }
  // const states: { id: string; state: trackState }[] = [];
  // if (trackData.length > 0) console.log(" --> update after fetch", trackData);
  // console.log(" --> update after fetch", trackData.map((promise) => promise?.payload));
  const data = trackData.filter((promise) => promise?.payload).map((promise) => promise.payload as TrackData);
  const state = trackData.map((promise) => {
    return { id: promise.id, state: promise?.payload ? trackState.ready : trackState.failed };
  });
  if (state.length > 0) {
    if (setDatatracks) setDatatracks(data);
    if (setTrackState) setTrackState(state);
  }
};

export const merge1DTiles = (multiTileData: TrackXYData) => {
  const data = multiTileData.data;

  let typeX: "Float64Array" | "Float32Array" | "" = "";
  let typeY = "";
  let size = 0;
  for (let tileIndex = 0; tileIndex < data.x.length; tileIndex++) {
    const x = data.x[tileIndex].data;
    const y = data.y[tileIndex].data;
    if (!x || !y || x.length !== y.length || x.length < 1) size++;
    else {
      typeX = x.constructor.name as "Float64Array" | "Float32Array";
      typeY = y.constructor.name as "Float64Array" | "Float32Array";
      size += x.length;
    }
  }
  if (size < 1) return initDatatrackNumericArray();

  const minX = Math.min(...data.x.map((t) => t.min[0]));
  const maxX = Math.max(...data.x.map((t) => t.max[0]));
  const minY = Math.min(...data.y.map((t) => t.min[0]));
  const maxY = Math.max(...data.y.map((t) => t.max[0]));

  const rangeX: [number, number] = [
    Math.min(...data.x.map((t) => t.range[0][0])),
    Math.max(...data.x.map((t) => t.range[0][1])),
  ];
  const rangeY: [number, number] = [
    Math.min(...data.y.map((t) => t.range[0][0])),
    Math.max(...data.y.map((t) => t.range[0][1])),
  ];

  const countX = data.x.reduce((partialSum, t) => partialSum + t.count, 0);
  const countY = data.y.reduce((partialSum, t) => partialSum + t.count, 0);

  // console.log("size", size, typeX, typeY);
  let newX: Float32Array | Float64Array;
  let newY: Float32Array | Float64Array;

  if (typeX === "Float32Array") newX = new Float32Array(size);
  else if (typeX === "Float64Array") newX = new Float64Array(size);
  else newX = new Float32Array(0);

  if (typeY === "Float32Array") newY = new Float32Array(size);
  else if (typeY === "Float64Array") newY = new Float64Array(size);
  else newY = new Float32Array(0);

  let i = 0;
  for (let tileIndex = 0; tileIndex < data.x.length; tileIndex++) {
    const x = data.x[tileIndex].data;
    const y = data.y[tileIndex].data;
    if (!x || !y || x.length !== y.length || x.length < 1) {
      newX[i] = NaN;
      newY[i] = NaN;
      i++;
    } else {
      newX.set(x, i);
      newY.set(y, i);
      i += x.length;
    }
  }

  data.x = [
    initDatatrackNumericArray({
      min: [minX],
      max: [maxX],
      size: [size],
      range: [rangeX],
      data: newX,
      numberType: data.x[0].numberType,
      codec: data.x[0].codec,
      depth: data.x[0].depth,
      count: countX,
    }),
  ];

  data.y = [
    initDatatrackNumericArray({
      min: [minY],
      max: [maxY],
      size: [size],
      range: [rangeY],
      data: newY,
      numberType: data.y[0].numberType,
      codec: data.y[0].codec,
      depth: data.y[0].depth,
      count: countY,
    }),
  ];
};

export const mergeTiles = (multiTileData: TrackData) => {
  if (multiTileData.type === "XY_real") {
    merge1DTiles(multiTileData as TrackXYData);
  }
};

export const getTrackDataLevel = (
  api: API,
  level: number,
  tracks: Track[],
  datatracks: Record<string, Datatrack>,
  setDatatracks: (data: TrackData[]) => void,
  setTrackState: (trackState: { id: string; state: trackState }[]) => void
) => {
  const convert = (data: TrackData[]) => {
    data.forEach((track) => mergeTiles(track));
    setDatatracks(data);
  };

  getTilesForTrackLevel(api, level, tracks, datatracks, convert, setTrackState);
};

export const getTilesForTrackLevel = (
  api: API,
  level: number,
  tracks: Track[],
  datatracks: Record<string, Datatrack>,
  setDatatracks: (data: TrackData[]) => void,
  setTrackState: (trackState: { id: string; state: trackState }[]) => void
) => {
  const promises = getTilePromises(api, tracks, datatracks, undefined, level);

  if (Object.keys(promises.promises).length < 1) {
    setDatatracks(promises.trackData);
    setTrackState(promises.trackState);
    return;
  }
  setTrackState(promises.trackState);

  const trackData: TrackData[] = [];
  trackData.push(...promises.trackData);

  raceTilePromises(
    promises.promises,
    (data: TrackData[]) => {
      trackData.push(...data);
      setDatatracks(trackData);
    },
    setTrackState
  );
};

export const getTilesByDomainAsync = (
  api: API,
  tracks: Track[],
  datatracks: Record<string, Datatrack>,
  domain: { range: number[][]; points: number[] },
  setDatatracks: (data: TrackData[]) => void,
  setTrackState: (trackState: { id: string; state: trackState }[]) => void
) => {
  if (!domain || tracks.length < 1) return;

  const promises = getTilePromises(api, tracks, datatracks, domain);
  setTrackState(promises.trackState);
  setDatatracks(promises.trackData);

  raceTilePromises(promises.promises, setDatatracks, setTrackState);
};

export const getTilePromises = (
  api: API,
  tracks: Track[],
  datatracks: Record<string, Datatrack>,
  domain: { range: number[][]; points: number[] } | undefined = undefined,
  level: number = -1
) => {
  const promises: Record<string, Promise<promiseType>> = {};
  const trackStates: { id: string; state: trackState }[] = [];
  const dataTracks: (TrackXYData | TrackMatrixData | TrackImageData | TrackBinaryData | TrackTableData)[] = [];

  // console.log("getTilePromises", tracks);
  for (let track of tracks) {
    if (!track.settings.active) continue;

    const tiles =
      level >= 0
        ? getTilesByLevel(track, datatracks, 0)
        : getTilesByRange(track, datatracks, domain?.range ?? [[]], domain?.points ?? []);

    if (!tiles) continue;
    const newTiles = getNewTiles(tiles);
    if (newTiles.length < 1) {
      // console.log(
      //   "known track",
      //   tiles.x.map((t) => [t.min, t.max])
      // );
      trackStates.push({ id: track.id, state: trackState.ready });
      // console.log("tiles count", track.type);
      // console.log("found",  (tiles as any)[0]);
      const data = getTrackData(track, tiles);
      if (data) {
        dataTracks.push(data);
        trackStates.push({ id: track.id, state: trackState.ready });
      } else {
        trackStates.push({ id: track.id, state: trackState.failed });
      }
    } else {
      // console.log("prommise track", track.id);
      trackStates.push({ id: track.id, state: trackState.loading });
      promises[track.id] = fetchNewTiles(api, newTiles).then((check) => {
        if (check) return { id: track.id, payload: getTrackData(track, tiles) };
        return { id: track.id, payload: undefined };
      });
      // console.log("prommise track", promises[track.id]);
    }
  }

  return {
    promises: promises,
    trackState: trackStates,
    trackData: dataTracks,
  };
};

export const getTilesByLevel = (track: Track, datatracks: Record<string, Datatrack>, level: number) => {
  if (track.type === "XY_real") {
    return {
      x: datatracks?.[track.data?.x ?? ""]?.levels?.[level].tiles ?? [],
      y: datatracks?.[track.data?.y ?? ""]?.levels?.[level].tiles ?? [],
    } as { x: DatatrackNumericArray[]; y: DatatrackNumericArray[] };
  } else if (track.type === "matrix_real") {
    return { matrix: datatracks?.[track.data?.matrix ?? ""]?.levels?.[level].tiles ?? [] } as {
      matrix: DatatrackNumericMatrix[];
    };
  } else if (track.type === "image" || track.type === "molecule_compound") {
    return { image: datatracks?.[track.data?.image ?? ""]?.levels?.[level].tiles ?? [] } as { image: DatatrackImage[] };
  } else if (track.type === "pdf") {
    return { binary: datatracks?.[track.data?.pdf ?? ""]?.levels?.[level].tiles ?? [] } as {
      binary: DatatrackBinary[];
    };
  } else if (track.type === "table") {
    return { table: datatracks?.[track.data?.table ?? ""]?.levels?.[level].tiles ?? [] } as { table: DatatrackTable[] };
  }
};

export const getTilesByRange = (
  track: Track,
  datatracks: Record<string, Datatrack>,
  range: number[][],
  points: number[]
) => {
  if (track.type === "XY_real") {
    const datalevels = {
      x: datatracks?.[track.data?.x ?? ""]?.levels ?? [],
      y: datatracks?.[track.data?.y ?? ""]?.levels ?? [],
    };
    return get1DTileByRange(datalevels, range, points);
  } else if (track.type === "matrix_real") {
    // const x = datatracks?.[track.data?.x ?? ""];
    // const y = datatracks?.[track.data?.y ?? ""];

    const datalevels = {
      x: datatracks?.[track.data?.x ?? ""].levels ?? [],
      y: datatracks?.[track.data?.y ?? ""].levels ?? [],
      matrix: datatracks?.[track.data?.matrix ?? ""]?.levels ?? [],
    };
    // console.log("datalevels", datatracks?.[track.data?.y ?? ""].id)
    return get2DTileByRange(datalevels, range, points);
  } else if (track.type === "image" || track.type === "molecule_compound") {
    const datalevels = {
      image: datatracks?.[track.data?.image ?? ""]?.levels ?? [],
    };
    return getImageTileByRange(datalevels, range, points);
  } else if (track.type === "table") {
    const datalevels = {
      table: datatracks?.[track.data?.table ?? ""]?.levels ?? [],
    };
    return getTableTileByRange(datalevels, range);
  }
};

const getTableTileByRange = (datalevels: { table: Level[] }, range: number[][]) => {
  return { table: [] as Datatrack[] };
};

const getImageTileByRange = (datalevels: { image: Level[] }, range: number[][], points: number[]) => {
  let lastLevel = { tileCount: Infinity, level: -1, first: -1, last: -1 };

  const minLevel = 0;
  const maxLevel = datalevels.image.length - 1;

  // console.log("level range", minLevel, maxLevel);
  let first = 0;
  let last = 0;
  let level = maxLevel;
  for (let i = minLevel; i <= maxLevel; i++) {
    const currentLevel = datalevels.image[i];

    if (currentLevel.tiles.length < 1) continue;
    // const tile = currentLevel.tiles[0];

    // console.log("level ", i, currentLevel.size[0], "<", points[0], "&&", currentLevel.size[1], "<", points[1]);
    if (currentLevel.size[0] < points[0] || currentLevel.size[1] < points[1]) {
      if (i > minLevel) {
        level = lastLevel.level;
        first = lastLevel.first;
        last = lastLevel.last;
        break;
      } else {
        level = i;
        break;
      }
    }
    lastLevel = { level: i, first: first, last: last, tileCount: 1 };
  }
  // console.log("level", level, "-> tilePoints", datalevels.image[level].size);

  return { image: datalevels.image[level].tiles };
};

const get2DTileByRange = (
  // datalevels: { x: LinearGenerator; y: LinearGenerator; matrix: Level[] },
  datalevels: { x: Level[]; y: Level[]; matrix: Level[] },
  range: number[][],
  points: number[]
): { matrix: Datatrack[] } => {
  // console.log("Matrix x");
  let tileXRange = get1DTileRange(datalevels.x, range[0], points[0]);
  if (tileXRange.level < 0) return { matrix: [] };

  // console.log("Matrix y");
  let tileYRange = get1DTileRange(datalevels.y, range[1], points[1], 0, tileXRange.level);
  if (tileYRange.level < 0) return { matrix: [] };

  // console.log("Levels x y", tileXRange.level, tileYRange.level);
  const level = Math.min(tileXRange.level, tileYRange.level);
  // console.log(`get2DTileByRange: level set to ${level} of ${datalevels.matrix.length}`);
  // console.log("Matrix x y");
  if (tileXRange.level !== level) tileXRange = get1DTileRange(datalevels.x, range[0], points[0], level, level);
  if (tileYRange.level !== level) tileYRange = get1DTileRange(datalevels.y, range[1], points[1], level, level);

  const tiles: Datatrack[] = [];

  let k = -1;
  const xlen = datalevels.matrix[level].tileCount[0];
  for (let j = tileYRange.first; j <= tileYRange.last; j++) {
    for (let i = tileXRange.first; i <= tileXRange.last; i++) {
      k = i + j * xlen;
      tiles.push(datalevels.matrix[level].tiles[k]);
      // console.log(
      //   "k",
      //   i,
      //   j,
      //   "->",
      //   k,
      //   "range",
      //   datalevels.matrix[level].tiles[k].min[1],
      //   datalevels.matrix[level].tiles[k].max[1],
      //   "in",
      //   ...range[1]
      // );
    }
  }

  return { matrix: tiles };
};

// const get2DTilesBy1DTiles = (levels: Level[], x: Datatrack[], y: Datatrack[]) => {
//   const tiles: Datatrack[] = [];

//   if (y.length < 1) return undefined;
//   const tileMap: Record<string, Datatrack> = Object.fromEntries(
//     levels[y[0].depth].tiles.map((tile) => [
//       `${tile.range[0][0]}_${tile.range[0][1]}_${(tile.range[1] as range)[0]}_${(tile.range[1] as range)[1]}`,
//       tile,
//     ])
//   );

//   for (let yTile of y) {
//     const yKey = `_${yTile.range[0][0]}_${yTile.range[0][1]}`;
//     for (let xTile of x) {
//       const xKey = `${xTile.range[0][0]}_${xTile.range[0][1]}` + yKey;
//       const tile = tileMap[xKey];
//       if (!tile) return undefined;
//       tiles.push(tile);
//       // console.log("tile", tile);
//       // console.log("  x", ...xTile.range, ...yTile.range, ...tile.range);
//     }
//   }

//   return tiles;
// };

// const get2DTileRange = (
//   levels: Level[],
//   x: LinearGenerator,
//   y: LinearGenerator,
//   range: number[][],
//   points: number[],
//   minLevel: number = 0,
//   maxLevel: number = -1
// ) => {
//   const min = [Math.min(range[0][0], range[0][1]), Math.min(range[1][0], range[1][1])];
//   const max = [Math.max(range[0][0], range[0][1]), Math.max(range[1][0], range[1][1])];

//   if (max.every((_, i) => max[0] - min[0] < Number.EPSILON) || points.some((p) => p < 1) || levels.length < 1)
//     return {
//       x: [],
//       y: [],
//       matrix: [],
//     };

//   let lastLevel = {
//     tileCount: [Infinity, Infinity],
//     level: -1,
//     first: [-1, -1],
//     last: [-1, -1],
//     tilePoints: [-1, -1],
//     xTiles: [] as Datatrack[],
//     yTiles: [] as Datatrack[],
//   };

//   if (minLevel < 0) minLevel = 0;
//   if (maxLevel < 0 || maxLevel > levels.length - 1) maxLevel = levels.length - 1;
//   if (maxLevel < minLevel) maxLevel = minLevel;

//   const xRange = [Infinity, -Infinity];
//   const yRange = [Infinity, -Infinity];

//   let firstTile: Datatrack;
//   let lastTile: Datatrack;
//   let tMin: number;
//   let tMax: number;
//   let first = [0, 0];
//   let last = [0, 0];
//   let level = maxLevel;
//   let tilePoints = [0, 0];
//   console.log("--");
//   console.log("min max", x, y);
//   for (let i = minLevel; i <= maxLevel; i++) {
//     const currentLevel = levels[i];
//     const rx = currentLevel.tiles[0].range[0] ?? [[Infinity, Infinity]];
//     const ry = currentLevel.tiles[0].range[1] ?? [[Infinity, Infinity]];
//     let xTiles = currentLevel.tiles.filter((tile) => tile.range?.[1]?.[0] === ry[0] && tile.range?.[1]?.[1] === ry[1]);
//     let yTiles = currentLevel.tiles.filter((tile) => tile.range[0]?.[0] === rx[0] && tile.range[0]?.[1] === rx[1]);

//     const len = [xTiles.length - 1, yTiles.length - 1];
//     firstTile = xTiles[0];
//     lastTile = xTiles[len[0]];
//     first = [0, 0];
//     last = len;
//     x.setCount(lastTile.range[0][1] - firstTile.range[0][0]);
//     console.log("---> x tiles", x.reverseArray(firstTile.range[0]), x.reverseArray(lastTile.range[0]));

//     // ------ for x direction ------
//     for (let j = 0; j < xTiles.length; j++) {
//       xTiles[j].min[0] = x.reverse(xTiles[j].range[0][0]);
//     }

//     if (min[0] < firstTile.min[0]) {
//       first[0] = 0;
//     } else if (min[0] > lastTile.max[0]) {
//       first = len;
//     } else {
//       for (let j = 0; j < xTiles.length; j++) {
//         tMin = x.reverse(xTiles[j].range[0][0]);
//         tMax = x.reverse(xTiles[j].range[0][1]);
//         console.log(
//           "tile",
//           j,
//           "-> min",
//           xTiles[j].min[0],
//           "in",
//           min[0],
//           max[0],
//           "=>",
//           xTiles[j].min[0] >= min[0],
//           min[0] < xTiles[j].max[0]
//         );

//         if (xTiles[j].min[0] >= min[0] && xTiles[j].min[0] < max[0]) {
//           first[0] = j;
//           break;
//         }
//       }
//     }

//     if (max[0] < firstTile.min[0]) {
//       last[0] = 0;
//       // console.log("last left out");
//     } else if (max[0] > lastTile.max[0]) {
//       last = len;
//       // console.log("last right out");
//     } else {
//       for (let j = 0; j < xTiles.length; j++) {
//         if (max[0] >= xTiles[j].min[0] && max[0] < xTiles[j].max[0]) {
//           last[0] = j;
//           break;
//         }
//       }
//       // console.log("last in tile", last);
//     }

//     tilePoints[0] = 0;
//     // console.log("select", currentLevel.size[0], first, last);
//     // for (let i = first; i <= last; i++) {
//     //   console.log("tile", i, xTiles[i].size[0]);
//     // }

//     for (let i = first[0]; i <= last[0]; i++) tilePoints[0] += xTiles[i].size[0];
//     const tileCount = [0, 0];
//     tileCount[0] = last[0] - first[0] + 1;
//     // console.log("X level", i, "tiles", tileCount, "-> tilePoints", tilePoints, "<", points);

//     // ------ for y direction ------
//     if (min[1] < (yTiles[0].min[1] ?? Infinity)) {
//       first[1] = 0;
//     } else if (min[1] > (yTiles[len[1]].max[1] ?? -Infinity)) {
//       first = len;
//     } else {
//       for (let j = 0; j < yTiles.length; j++) {
//         if (min[1] >= (yTiles[j].min?.[1] ?? Infinity) && min[1] < (yTiles[j].max[1] ?? -Infinity)) {
//           first[1] = j;
//           break;
//         }
//       }
//     }

//     if (max[1] < (yTiles[0].min[1] ?? Infinity)) {
//       last[1] = 0;
//       // console.log("last left out");
//     } else if (max[1] > (yTiles[len[1]].max[1] ?? -Infinity)) {
//       last = len;
//       // console.log("last right out");
//     } else {
//       for (let j = 0; j < yTiles.length; j++) {
//         if (max[1] >= (yTiles[j].min[1] ?? Infinity) && max[1] < (yTiles[j].max[1] ?? -Infinity)) {
//           last[1] = j;
//           break;
//         }
//       }
//       // console.log("last in tile", last);
//     }

//     tilePoints[1] = 0;
//     // console.log("select", currentLevel.size[0], first, last);
//     // for (let i = first; i <= last; i++) {
//     //   console.log("tile", i, yTiles[i].size[0]);
//     // }

//     for (let i = first[1]; i <= last[1]; i++) tilePoints[1] += yTiles[i].size[1] ?? 0;

//     tileCount[1] = last[1] - first[1] + 1;
//     // console.log("level", i, "tiles", tileCount, "-> tilePoints", tilePoints, "<", points);
//     // console.log("level", i, "first", first, "last", last);

//     if (tilePoints[0] < points[0] && tilePoints[1] < points[1]) {
//       if (i > minLevel) {
//         level = lastLevel.level;
//         first = lastLevel.first;
//         last = lastLevel.last;
//         tilePoints = lastLevel.tilePoints;
//         xTiles = lastLevel.xTiles;
//         yTiles = lastLevel.yTiles;
//       } else {
//         level = i;
//       }

//       for (let i = first[0]; i <= last[0]; i++) {
//         // console.log("tile", i, xTiles[i].range[0]);
//         if (xRange[0] > xTiles[i].range[0][0]) xRange[0] = xTiles[i].range[0][0];
//         if (xRange[1] < xTiles[i].range[0][1]) xRange[1] = xTiles[i].range[0][1];
//       }

//       for (let i = first[1]; i <= last[1]; i++) {
//         if (yRange[0] > yTiles[i].range[0][0]) yRange[0] = yTiles[i].range[0][0];
//         if (yRange[1] < yTiles[i].range[0][1]) yRange[1] = yTiles[i].range[0][1];
//       }
//       // break;
//     }

//     lastLevel = {
//       level: i,
//       first: first,
//       last: last,
//       tileCount: tileCount,
//       tilePoints: tilePoints,
//       xTiles: xTiles,
//       yTiles: yTiles,
//     };
//   }

//   // console.log("level", level, "-> tilePoints", tilePoints, "<", points, " => range", xRange, yRange);
// };

type TileResult = { level: number; first: number; last: number; pointCount: number; enoughPoints: boolean };

const get1DTileRange = (
  levels: Level[],
  range: number[],
  points: number,
  minLevel: number = 0,
  maxLevel: number = -1
): TileResult => {
  const minX = Math.min(range[0], range[1]);
  const maxX = Math.max(range[0], range[1]);
  // const diff = maxX - minX;
  const origPoints = points;

  if (maxX - minX < Number.EPSILON || points < 1 || levels.length < 1)
    return {
      level: -1,
      first: -1,
      last: -1,
      pointCount: -1,
      enoughPoints: false,
    };

  if (minLevel < 0) minLevel = 0;
  if (maxLevel < 0 || maxLevel > levels.length - 1) maxLevel = levels.length - 1;
  if (maxLevel < minLevel) maxLevel = minLevel;

  const levelMap: TileResult[] = [];

  let found = false;
  let first = 0;
  let last = 0;
  // let level = maxLevel;
  let pointCount = 0;
  let lMin = 0;
  let lMax = 0;
  // console.log("------------------------------");
  // levels.forEach((l) => console.log("##", l.depth, l.range[0]));

  // console.log("minmax", minX, maxX, points);
  for (let i = minLevel; i <= maxLevel; i++) {
    const currentLevel = levels[i];

    const len = currentLevel.tiles.length - 1;
    first = 0;
    last = len;
    points = origPoints;

    if (minX < currentLevel.min[0]) {
      first = 0;
    } else if (minX > currentLevel.max[0]) {
      first = len;
    } else {
      for (let j = 0; j < currentLevel.tiles.length; j++) {
        if (minX >= currentLevel.tiles[j].min[0] && minX <= currentLevel.tiles[j].max[0]) {
          first = j;
          break;
        }
      }
    }

    if (maxX < currentLevel.min[0]) {
      last = 0;
      // console.log("last left out");
    } else if (maxX > currentLevel.max[0]) {
      last = len;
      // console.log("last right out");
    } else {
      for (let j = 0; j < currentLevel.tiles.length; j++) {
        if (maxX >= currentLevel.tiles[j].min[0] && maxX <= currentLevel.tiles[j].max[0]) {
          last = j;
          break;
        }
      }
      // console.log("last in tile", last);
    }

    if (first === 0 && last === len) {
      // let minFrac = (minX - currentLevel.min[0]) / diff;
      // if (minFrac < 0) minFrac = 0;
      // let maxFrac = (currentLevel.max[0] - maxX) / diff;
      // if (maxFrac < 0) maxFrac = 0;
      // // let frac = minFrac + maxFrac;
      // // if (frac > 1) frac = 0;

      // points -=
      //   Math.floor((points * (currentLevel.min[0] - minX)) / diff) +
      //   Math.floor((points * (maxX - currentLevel.max[0])) / diff);
      // if (points < 1) points = 1;
      // const f = (currentLevel.min[0] - minX) / diff;

      pointCount = currentLevel.range[0][1] - currentLevel.range[0][0] + 1;
      lMin = currentLevel.min[0];
      lMax = currentLevel.max[0];
      // const diff = (currentLevel.max[0] - currentLevel.min[0]) / pointCount;
      // let overlapMin = (currentLevel.min[0] - minX) / diff;
      // if (overlapMin < 0) overlapMin = 0;
      // let overlapMax = (maxX - currentLevel.max[0]) / diff;
      // if (overlapMax < 0) overlapMax = 0;

      // pointCount += overlapMin + overlapMax;

      // console.log(
      //   "points",
      //   // Math.floor((points * (currentLevel.min[0] - minX)) / diff) +
      //   //   Math.floor((points * (maxX - currentLevel.max[0])) / diff),
      //   origPoints,
      //   minX,
      //   maxX,
      //   "=>",
      //   currentLevel.range[0][1] - currentLevel.range[0][0] + 1,
      //   currentLevel.min[0],
      //   currentLevel.max[0],
      //   "->",
      //   overlapMin,
      //   overlapMax
      // );
    } else {
      pointCount = 0;
      for (let i = first; i <= last; i++) pointCount += currentLevel.tiles[i].size[0];
      lMin = currentLevel.tiles[first].min[0];
      lMax = currentLevel.tiles[last].max[0];
    }

    // const lMin = currentLevel.tiles[first].min[0];
    // const lMax = currentLevel.tiles[last].max[0];
    const diff = (lMax - lMin) / pointCount;
    let overlapMin = (lMin - minX) / diff;
    let overlapMax = (maxX - lMax) / diff;
    pointCount += overlapMin + overlapMax;

    // console.log(
    //   "point count",
    //   pointCount,
    //   lMin,
    //   lMax,
    //   "->",
    //   lMax - lMin,
    //   maxX - minX,
    //   "=>",
    //   Math.floor(overlapMin),
    //   Math.floor(overlapMax),
    //   Math.floor(pointCount + overlapMin + overlapMax)
    // );
    // const tileCount = last - first + 1;

    if (found) {
      // console.log("FOUND");
      const last = levelMap[levelMap.length - 1];
      if (last && last.pointCount > pointCount) {
        if (levelMap.length > 1) levelMap.pop();
        break;
      }
    }

    // console.log("level", i, "-> tilePoints", pointCount, "<", points, "minmax", minLevel, maxLevel);

    if (pointCount < points) found = true;
    levelMap.push({ level: i, first: first, last: last, pointCount: pointCount, enoughPoints: pointCount > points });
  }

  let index = levelMap.length;

  do {
    index--;
  } while (index > 0 && !levelMap[index].enoughPoints);

  // console.log(
  //   "level",
  //   levelMap[index].level,
  //   "-> tilePoints",
  //   levelMap[index].pointCount,
  //   "<",
  //   points,
  //   "tiles",
  //   levelMap[index].first,
  //   levelMap[index].last
  // );
  // levelMap.forEach((l) => console.log("##", l));

  return levelMap[index];
};

const get1DTileByRange = (
  datalevels: { x: Level[]; y: Level[] },
  range: number[][],
  points: number[]
): { x: Datatrack[]; y: Datatrack[] } => {
  // const minX = Math.min(range[0][0], range[0][1]);
  // const maxX = Math.max(range[0][0], range[0][1]);

  const tileRange = get1DTileRange(datalevels.x, range[0], points[0]);

  if (tileRange.level < 0) return { x: [], y: [] };

  // console.log(
  //   `selected tiles => level: ${tileRange.level} tiles: ${tileRange.first}-${tileRange.last} resolution: ${points[0]} tile points: ${tileRange.pointCount}`
  // );

  return {
    x: datalevels.x[tileRange.level].tiles.filter((_, i) => i >= tileRange.first && i <= tileRange.last),
    y: datalevels.y[tileRange.level].tiles.filter((_, i) => i >= tileRange.first && i <= tileRange.last),
  };
};

// const getTileRange = (tiles: Datatrack[], tileRange: number[]) => {
//   let f = 0;
//   for (let i = 0; i < tiles.length; i++) {
//     const tile = tiles[i];
//     if (tileRange[0] >= tile.range[0][0] && tileRange[0] <= tile.range[0][1]) {
//       f = i;
//       break;
//     }
//   }
//   let l = tiles.length - 1;
//   for (let i = f; i < tiles.length; i++) {
//     const tile = tiles[i];
//     if (tileRange[1] >= tile.range[0][0] && tileRange[1] <= tile.range[0][1]) {
//       l = i;
//       break;
//     }
//   }
//   console.log(`l ${l} -> ${tiles[l].range}`);
//   // tiles
//   //   .filter((_, i) => i >= f && i <= l)
//   //   .forEach((tile) => {
//   //     console.log(`i ${tile.range}`);
//   //   });

//   return tiles.filter((_, i) => i >= f && i <= l);
// };

// const get1DTileByRangeOld = (
//   datalevels: { x: Level[]; y: Level[] },
//   range: number[][],
//   points: number[]
// ): { x: Datatrack[]; y: Datatrack[] } => {
//   const minX = Math.min(range[0][0], range[0][1]);
//   const maxX = Math.max(range[0][0], range[0][1]);

//   if (maxX - minX < Number.EPSILON || points[0] < 1) return { x: [], y: [] };

//   const levels = datalevels.x;
//   let lastLevel = { tileCount: Infinity, level: -1, first: -1, last: -1 };
//   let first = 0;
//   let last = 0;
//   let level = 0;
//   let tilePoints = 0;
//   for (let i = levels.length - 1; i > 0; i--) {
//     const currentLevel = levels[i];

//     // console.log("level", i, currentLevel.size[0], "<", points[0]);
//     if (currentLevel.size[0] < points[0]) continue;
//     // console.log("level", i, currentLevel.size[0], "<", points[0]);
//     // console.log("level", i, currentLevel.size[0], points[0]);
//     // for (let tile of currentLevel.tiles) {
//     //   console.log("tile", tile.min[0], tile.max[0]);
//     // }
//     const len = currentLevel.tiles.length - 1;
//     first = 0;
//     last = len;

//     if (minX < currentLevel.tiles[0].min[0]) {
//       first = 0;
//       // console.log("first left out");
//     } else if (minX > currentLevel.tiles[len].max[0]) {
//       first = len;
//       // console.log("first right out");
//     } else {
//       for (let j = 0; j < currentLevel.tiles.length; j++) {
//         if (minX >= currentLevel.tiles[j].min[0] && minX < currentLevel.tiles[j].max[0]) {
//           first = j;
//           break;
//         }
//       }
//       // console.log("first in tile", first);
//     }

//     if (maxX < currentLevel.tiles[0].min[0]) {
//       last = 0;
//       // console.log("last left out");
//     } else if (maxX > currentLevel.tiles[len].max[0]) {
//       last = len;
//       // console.log("last right out");
//     } else {
//       for (let j = 0; j < currentLevel.tiles.length; j++) {
//         if (maxX >= currentLevel.tiles[j].min[0] && maxX < currentLevel.tiles[j].max[0]) {
//           last = j;
//           break;
//         }
//       }
//       // console.log("last in tile", last);
//     }

//     tilePoints = 0;
//     // console.log("select", currentLevel.size[0], first, last);
//     // for (let i = first; i <= last; i++) {
//     //   console.log("tile", i, currentLevel.tiles[i].size[0]);
//     // }

//     for (let i = first; i <= last; i++) tilePoints += currentLevel.tiles[i].size[0];
//     // console.log(
//     //   i,
//     //   "tile points",
//     //   last - first + 1,
//     //   lastLevel,
//     //   "->",
//     //   tilePoints,
//     //   ">=",
//     //   points[0],
//     //   tilePoints >= points[0]
//     // );

//     // console.log(i, "tile points", last - first + 1, lastLevel, "->", tilePoints >= points[0]);
//     const tileCount = last - first + 1;
//     if (lastLevel.tileCount < tileCount) {
//       tilePoints = 0;
//       for (let i = lastLevel.first; i <= lastLevel.last; i++) tilePoints += currentLevel.tiles[i].size[0];
//       if (tilePoints >= points[0]) {
//         level = lastLevel.level;
//         first = lastLevel.first;
//         last = lastLevel.last;
//         break;
//       }
//     }
//     lastLevel = { level: i, first: first, last: last, tileCount: tileCount };
//   }
//   // console.log(
//   //   ` selected tiles => level: ${level} tiles: ${first}-${last} resolution: ${points[0]} tile points: ${tilePoints}`
//   // );
//   return {
//     x: datalevels.x[level].tiles.filter((_, i) => i >= first && i <= last),
//     y: datalevels.y[level].tiles.filter((_, i) => i >= first && i <= last),
//   };
// };
