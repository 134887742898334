import { useEffect, useState, useMemo, useRef, Dispatch, SetStateAction, useLayoutEffect } from "react";
import { useEntityDetail, useIdsOnly } from "../../api/BaseEntityApi";
import { LabNotebookEntry, LabNotebookEntryFilters } from "../types/LabNotebookEntry";
import styles from "./LabNotebookEntriesPaginated.module.css";
import { LabNotebookEntryViewOnly } from "../LabNotebookEntry/LabNotebookEntryViewOnly/LabNotebookEntryViewOnly";
import useIntersectionObserver from "../../common/helperfunctions/useIntersectionObserver";
import { LucideIcon } from "../../common/icon/LucideIcon";
import { SearchInput } from "../../common/forms/SearchInput/SearchInput";
import { useDebouncedValue } from "../../common/helperfunctions/useDebouncedValue";
import { LoadingWrapper } from "../../common/LoadingWrapper";
import { MoreDropdown } from "../../common/buttons/MoreDropdown/MoreDropdown";
import { ELNNoContentContainer } from "../common/ELNNoContentContainer/ELNNoContentContainer";
import { Skeleton } from "../../common/loaders/Skeleton/Skeleton";
import { useELNRoutes } from "../ELNRouter/useELNRoutes";
import { ELNModes } from "../ELNRouter/ELNRouter";
import { Modal } from "../common/ELNModal/ELNModal";
import { PrintComponentsHandler } from "../common/PrintComponentsHandler/PrintComponentsHandler";
import { Alert } from "../../common/overlays/Alert/Alert";
import { getNotebookEntryStringLabel } from "../LabNotebookEntry/LabNotebookEntry";

export const LabNotebookEntriesPaginated = ({
  labNotebookId,
  labNotebookExperimentId,
  labNotebookEntryId,
  mode,
  labNotebookBlockId,
  filters = {},
  setShowCreateLabNotebookExperiment,
  setShowCreateLabNotebookEntry,
  showCreateButtonsOnEmpty = false,
  isLoading = false,
}: {
  labNotebookId?: number;
  labNotebookExperimentId?: number;
  labNotebookEntryId?: number;
  mode?: ELNModes;
  labNotebookBlockId?: string;
  filters?: LabNotebookEntryFilters;
  setShowCreateLabNotebookExperiment?: () => void;
  setShowCreateLabNotebookEntry?: () => void;
  showCreateButtonsOnEmpty?: boolean;
  isLoading?: boolean;
}) => {
  const [searchTerm, setSearchTerm] = useState<string>("");
  const debouncedSearchTerm = useDebouncedValue(searchTerm, 300);

  const { setELNRoute, goUp } = useELNRoutes();

  const [printLabNotebook, setPrintLabNotebook] = useState<boolean>(false);
  const [printLabNotebookExperiment, setPrintLabNotebookExperiment] = useState<boolean>(false);
  const [printLabNotebookEntry, setPrintLabNotebookEntry] = useState<boolean>(false);
  const [printLabNotebookWithCurrentFilters, setPrintLabNotebookWithCurrentFilters] = useState<boolean>(false);

  const {
    data: ids,
    status: statusIds,
    fetchStatus: fetchStatusIds,
    error: errorIds,
  } = useIdsOnly<number, LabNotebookEntryFilters>("lab_notebook_entries", {
    ...filters,
    includeCount: true,
    searchTerm: debouncedSearchTerm,
    useFullTextSearch: true,
  });

  const [countState, setCountState] = useState<number>(0);
  useEffect(() => {
    if (ids?.count !== undefined) setCountState(ids.count);
  }, [ids?.count]);

  const entryIds = useMemo(() => {
    return ids ? ids.results : [];
  }, [ids]);

  const targetFoundIndex = useMemo(() => {
    return entryIds.findIndex((e) => e === labNotebookEntryId);
  }, [entryIds, labNotebookEntryId]);

  const {
    data: currentEntry,
    isFetching,
    status,
    fetchStatus,
    error,
  } = useEntityDetail<LabNotebookEntry, LabNotebookEntryFilters>(
    "lab_notebook_entries",
    entryIds[targetFoundIndex],
    { includeContent: true },
    {
      enabled: !!entryIds.length && targetFoundIndex >= 0 && entryIds[targetFoundIndex] !== undefined,
    }
  );

  useLayoutEffect(() => {
    const timeout = setTimeout(() => {
      if (labNotebookBlockId) {
        const block = document.getElementById(labNotebookBlockId);
        if (block) {
          block.scrollIntoView({ block: "center", behavior: "smooth" });
        } else {
          document
            .getElementById(`LabNotebookEntry_${labNotebookEntryId}`)
            ?.scrollIntoView({ block: "start", behavior: "smooth" });
        }
        goUp(true);
      }
    }, 300);

    return () => clearTimeout(timeout);
  }, [goUp, labNotebookBlockId, labNotebookEntryId]);

  useEffect(() => {
    setSearchTerm("");
  }, [labNotebookId]);

  const ref = useRef<HTMLDivElement>(null);
  const height = useMemo(() => {
    if (!ref.current) return 0;
    return ref.current.getBoundingClientRect().height;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref.current]);

  if (!labNotebookId) return null;
  return (
    <>
      <div className={styles.LabNotebookEntryLazyLoadingContainer}>
        <div className={styles.LabNotebookEntryLazyLoadingPaginationContainer} ref={ref}>
          <div style={{ width: "100%" }}>
            <SearchInput searchValue={searchTerm} setSearchValue={setSearchTerm} placeholder="Search entry" />
          </div>

          <div className="flex justify-center align-center gap-5">
            <button
              disabled={targetFoundIndex <= 0}
              onClick={() =>
                setELNRoute({
                  labNotebookId,
                  labNotebookExperimentId,
                  labNotebookEntryId: entryIds[0],
                  replace: true,
                })
              }
              className="btn btn-secondary btn-xs"
              data-toggle="tooltip"
              title="Jump to first entries"
            >
              | <LucideIcon name="chevrons-left" />
            </button>
            <button
              disabled={targetFoundIndex <= 0}
              onClick={() =>
                setELNRoute({
                  labNotebookId,
                  labNotebookExperimentId,
                  labNotebookEntryId: entryIds[targetFoundIndex - 5 <= 0 ? 0 : targetFoundIndex - 5],
                  replace: true,
                })
              }
              className="btn btn-secondary btn-xs"
            >
              <LucideIcon name="chevrons-left" />
            </button>
            <button
              disabled={targetFoundIndex <= 0}
              onClick={() =>
                setELNRoute({
                  labNotebookId,
                  labNotebookExperimentId,
                  labNotebookEntryId: entryIds[targetFoundIndex - 1 <= 0 ? 0 : targetFoundIndex - 1],
                  replace: true,
                })
              }
              className="btn btn-secondary btn-xs"
              data-toggle="tooltip"
              title="Previous"
            >
              <LucideIcon name="chevron-left" /> Prev
            </button>
            <div style={{ display: "flex", flexDirection: "column", gap: "1px" }}>
              <div className="flex justify-center align-center gap-5">
                <div className="label label-soft-secondary" style={{ padding: "5px 20px", margin: "0 10px" }}>
                  {currentEntry ? (
                    <div style={{ minWidth: "200px", maxWidth: "200px", overflow: "hidden", textOverflow: "ellipsis" }}>
                      {getNotebookEntryStringLabel({ entry: currentEntry })}
                    </div>
                  ) : (
                    <div style={{ minWidth: "100px" }}>{isFetching ? "..." : "No results"}</div>
                  )}
                </div>
              </div>

              <div
                style={{ padding: "5px 20px", margin: "0 10px", overflow: "auto" }}
                className="label label-soft-default"
              >
                {`Entry ${targetFoundIndex + 1 || 0} of ${countState}`}
              </div>
            </div>
            <button
              disabled={targetFoundIndex === entryIds.length - 1}
              onClick={() =>
                setELNRoute({
                  labNotebookId,
                  labNotebookExperimentId,
                  labNotebookEntryId:
                    entryIds[targetFoundIndex + 1 >= entryIds.length - 1 ? entryIds.length - 1 : targetFoundIndex + 1],
                  replace: true,
                })
              }
              className="btn btn-secondary btn-xs"
              data-toggle="tooltip"
              title="Next"
            >
              Next <LucideIcon name="chevron-right" />
            </button>
            <button
              disabled={targetFoundIndex === entryIds.length - 1}
              onClick={() =>
                setELNRoute({
                  labNotebookId,
                  labNotebookExperimentId,
                  labNotebookEntryId:
                    entryIds[targetFoundIndex + 5 >= entryIds.length - 1 ? entryIds.length - 1 : targetFoundIndex + 5],
                  replace: true,
                })
              }
              className="btn btn-secondary btn-xs"
            >
              <LucideIcon name="chevrons-right" />
            </button>
            <button
              disabled={targetFoundIndex === entryIds.length - 1}
              onClick={() =>
                setELNRoute({
                  labNotebookId,
                  labNotebookExperimentId,
                  labNotebookEntryId: entryIds[entryIds.length - 1],
                  replace: true,
                })
              }
              className="btn btn-secondary btn-xs"
              data-toggle="tooltip"
              title="Jump to last entries"
            >
              <LucideIcon name="chevrons-right" /> |
            </button>
          </div>

          {labNotebookId && (
            <MoreDropdown
              btn="btn btn-ghost-secondary btn-xs"
              icon={<LucideIcon name="printer" />}
              direction="down"
              drop="right"
              title="Print"
            >
              {!!currentEntry?.labNotebook.id && (
                <button
                  disabled={!currentEntry}
                  onClick={() => setPrintLabNotebook(true)}
                  style={{ width: "100%", textAlign: "left", padding: "5px 10px" }}
                  className="btn btn-ghost-secondary btn-xs"
                >
                  <LucideIcon name="printer" /> Notebook
                </button>
              )}
              {!!currentEntry?.labNotebookExperiment.id && (
                <button
                  disabled={!currentEntry}
                  onClick={() => setPrintLabNotebookExperiment(true)}
                  style={{ width: "100%", textAlign: "left", padding: "5px 10px" }}
                  className="btn btn-ghost-secondary btn-xs"
                >
                  <LucideIcon name="printer" /> Experiment
                </button>
              )}
              {!!currentEntry?.id && (
                <button
                  disabled={!currentEntry}
                  onClick={() => setPrintLabNotebookEntry(true)}
                  style={{ width: "100%", textAlign: "left", padding: "5px 10px" }}
                  className="btn btn-ghost-secondary btn-xs"
                >
                  <LucideIcon name="printer" /> Entry
                </button>
              )}
              <button
                disabled={!currentEntry}
                onClick={() => setPrintLabNotebookWithCurrentFilters(true)}
                style={{ width: "100%", textAlign: "left", padding: "5px 10px" }}
                className="btn btn-ghost-secondary btn-xs"
              >
                <LucideIcon name="printer" /> Current selection
              </button>
            </MoreDropdown>
          )}
        </div>
        <div
          style={{
            height: `calc(100% - ${height}px)`,
            overflow: "auto",
            overflowX: "hidden",
          }}
        >
          <LoadingWrapper status={statusIds} fetchStatus={fetchStatusIds} error={errorIds}>
            <LoadingWrapper status={status} fetchStatus={fetchStatus} error={error}>
              {isFetching || isLoading ? (
                <div className="flex justify-center">
                  <div style={{ width: "210mm" }}>
                    <Skeleton type="elnpage" />
                  </div>
                </div>
              ) : !entryIds.length || targetFoundIndex < 0 ? (
                <div className="flex justify-center">
                  <div style={{ width: "210mm" }}>
                    {labNotebookExperimentId ? (
                      <ELNNoContentContainer
                        noContentMessage={searchTerm ? `No entries for searchterm "${searchTerm}".` : "No entries yet."}
                        createButton={
                          showCreateButtonsOnEmpty
                            ? {
                                label: "Create new entry",
                                onClick: () => setShowCreateLabNotebookEntry && setShowCreateLabNotebookEntry(),
                                color: "soft-primary",
                                icon: "pencil",
                              }
                            : undefined
                        }
                      />
                    ) : (
                      <ELNNoContentContainer
                        noContentMessage={
                          searchTerm ? `No entries for searchterm "${searchTerm}".` : "No experiments yet."
                        }
                        createButton={
                          showCreateButtonsOnEmpty
                            ? {
                                label: "Create new experiment",
                                onClick: () =>
                                  setShowCreateLabNotebookExperiment && setShowCreateLabNotebookExperiment(),
                                color: "soft-primary",
                                icon: "file-text",
                              }
                            : undefined
                        }
                      />
                    )}
                  </div>
                </div>
              ) : (
                <div className={styles.LabNotebookEntryLazyLoadingEntriesContainer}>
                  {currentEntry ? (
                    <ObserverWrapper
                      key={`LabNotebookEntry_${currentEntry.id}`}
                      entry={currentEntry}
                      labNotebookId={labNotebookId}
                      labNotebookExperimentId={labNotebookExperimentId}
                      labNotebookEntryId={labNotebookEntryId}
                      labNotebookBlockId={labNotebookBlockId}
                      searchTerm={debouncedSearchTerm}
                    />
                  ) : (
                    <Alert fit centered type="light" message="No entry selected..." />
                  )}
                </div>
              )}
            </LoadingWrapper>
          </LoadingWrapper>
        </div>
      </div>

      {printLabNotebook && currentEntry && (
        <Modal isOpen={printLabNotebook} onClose={() => setPrintLabNotebook(false)}>
          <PrintComponentsHandler
            onAfterPrint={() => setPrintLabNotebook(false)}
            filter={{ labNotebookIds: [currentEntry.labNotebook.id] }}
            documentTitle={`${currentEntry.labNotebook.name.replaceAll(" ", "_")}_${new Date().toISOString()}`}
          />
        </Modal>
      )}

      {printLabNotebookExperiment && currentEntry && (
        <Modal isOpen={printLabNotebookExperiment} onClose={() => setPrintLabNotebookExperiment(false)}>
          <PrintComponentsHandler
            onAfterPrint={() => setPrintLabNotebookExperiment(false)}
            filter={{ labNotebookExperimentIds: [currentEntry.labNotebookExperiment.id] }}
            documentTitle={`${currentEntry.labNotebookExperiment.name.replaceAll(" ", "_")}
          }_${new Date().toISOString()}`}
          />
        </Modal>
      )}

      {printLabNotebookEntry && currentEntry && (
        <Modal isOpen={printLabNotebookEntry} onClose={() => setPrintLabNotebookEntry(false)}>
          <PrintComponentsHandler
            onAfterPrint={() => setPrintLabNotebookEntry(false)}
            filter={{ ids: [currentEntry.id] }}
            documentTitle={`${currentEntry.name.split(" > ").join("_").replaceAll(" ", "_")}
          }_${new Date().toISOString()}`}
          />
        </Modal>
      )}

      {printLabNotebookWithCurrentFilters && currentEntry && (
        <Modal isOpen={printLabNotebookWithCurrentFilters} onClose={() => setPrintLabNotebookWithCurrentFilters(false)}>
          <PrintComponentsHandler
            onAfterPrint={() => setPrintLabNotebookWithCurrentFilters(false)}
            filter={filters}
            documentTitle={`${currentEntry.labNotebook.name.replaceAll(
              " ",
              "_"
            )}_${new Date().toISOString()}_selection`}
          />
        </Modal>
      )}
    </>
  );
};

const ObserverWrapper = ({
  labNotebookId,
  labNotebookExperimentId,
  labNotebookEntryId,
  labNotebookBlockId,
  entry,
  setEntriesOnScreen,
  searchTerm,
}: {
  entry: LabNotebookEntry;
  setEntriesOnScreen?: Dispatch<SetStateAction<LabNotebookEntry[]>>;
  labNotebookId?: number;
  labNotebookExperimentId?: number;
  labNotebookEntryId?: number;
  labNotebookBlockId?: string;
  searchTerm?: string;
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const intersectionObserver = useIntersectionObserver(ref, {});

  useEffect(() => {
    if (setEntriesOnScreen) {
      if (intersectionObserver?.isIntersecting) {
        setEntriesOnScreen((prevState) => {
          if (prevState.find((e) => e.id === entry.id)) return prevState;
          return [...prevState, entry].sort((a, b) => {
            if (a.entryDate > b.entryDate) return 1;
            if (a.entryDate === b.entryDate) return 0;
            return -1;
          });
        });
      } else {
        setEntriesOnScreen((prevState) => prevState.filter((e) => e.id !== entry.id));
      }

      return () => setEntriesOnScreen((prevState) => prevState.filter((e) => e.id !== entry.id));
    }
  }, [
    entry,
    intersectionObserver,
    setEntriesOnScreen,
    labNotebookId,
    labNotebookExperimentId,
    labNotebookEntryId,
    labNotebookBlockId,
  ]);

  const remirrorContextRef = useRef<any>(null);
  useEffect(() => {
    try {
      remirrorContextRef?.current?.commands.find({ query: searchTerm || undefined, caseSensitive: false });
    } catch (error) {
      console.error(error);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [remirrorContextRef.current, searchTerm]);

  return (
    <div id={`LabNotebookEntry_${entry.id}`} ref={ref} style={{ width: "100%" }}>
      <LabNotebookEntryViewOnly
        entry={entry}
        remirrorContextRef={remirrorContextRef}
        labNotebookExperimentId={labNotebookExperimentId}
        useEntry
      />
      <hr />
    </div>
  );
};
