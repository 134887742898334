import { useHistory } from "react-router-dom";
import { ColumnsSettings } from "../../../common/tables/ColumnsSelector/ColumnsSelector";
import {
  LabNotebookTemplate,
  LabNotebookTemplateFilters,
  LabNotebookTemplateFiltersTranslator,
} from "../../types/LabNotebookTemplate";
import { useContext, useMemo, useState } from "react";
import { SessionContext } from "../../../common/contexts/SessionContext";
import { TableTabsDict } from "../../../common/tables/Tabs/TableTabsTypes";
import { LabNotebookTemplateFilterBar, LabNotebookTemplateFilterForm } from "./LabNotebookTemplatesFilterBar";
import { useTabStore } from "../../../common/tables/Tabs/useTabStore";
import {
  GenericVirtualizedTableCells,
  SortState,
} from "../../../common/tables/GenericVirtualizedTable/GenericVirtualizedTableTypes";
import { LucideIcon } from "../../../common/icon/LucideIcon";
import { NotSet } from "../../../common/misc/UIconstants";
import { DateTimeRenderer } from "../../../common/datetime/DateTimeFormatter";
import { GetPersons, TableArrayRenderer } from "../../../common/misc/EntityRenders/EntityRenderer";
import TableView, { TableViewLabel } from "../../../common/panels/TableView/TableView";
import { getAddRoute, getDetailLink } from "../../../main/Routing";
import { EntityTableTabs } from "../../../common/tables/Tabs/EntityTableTabs";
import { EntityTable } from "../../../common/tables/EntityTable/EntityTable";
import { EntityFilterIndicator } from "../../../common/tables/EntityFilterIndicator/EntityFilterIndicator";
import { SearchInput } from "../../../common/forms/SearchInput/SearchInput";
import { MoreDropdown } from "../../../common/buttons/MoreDropdown/MoreDropdown";
import { AlertModal } from "../../../common/modals/AlertModal/AlertModal";
import styles from "./LabNotebookTemplatesTable.module.css";
import { LinkButton } from "../../../common/buttons/LinkButton/LinkButton";
import {
  UseEntityTableDefaultProps,
  UseEntityTableProps,
  useGenericVirtualizedTable,
  useGenericVirtualizedTableTabs,
} from "../../../common/tables/GenericVirtualizedTable/useGenericVirtualizedTable";
import { EntityTableProps } from "../../../common/entity/EntityInterfaces";
import { EntityTableEditButton } from "../../../common/entity/entityComponents/EntityTableEditButton";
import { EntityTableCloneButton } from "../../../common/entity/entityComponents/EntityTableCloneButton";
import { EntityTableRestoreButton } from "../../../common/entity/entityComponents/EntityTableRestoreButton";
import { EntityTableSoftDeletableButton } from "../../../common/entity/entityComponents/EntityTableSoftDeletableButton";

export const switchLabNotebookTemplatesDefaultSortState = (
  sortState: LabNotebookTemplateFilters["orderBy"]
): SortState<LabNotebookTemplateFilters["orderBy"]> => {
  switch (sortState) {
    case "ID_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-id",
        orderBy: sortState,
      };
    case "ID_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-id",
        orderBy: sortState,
      };
    case "NAME_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-name",
        orderBy: sortState,
      };
    case "NAME_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-name",
        orderBy: sortState,
      };
    case "CREATED_ON_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-createdOn",
        orderBy: sortState,
      };
    case "CREATED_ON_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-createdOn",
        orderBy: sortState,
      };
    case "CREATED_BY_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-createdBy",
        orderBy: sortState,
      };
    case "CREATED_BY_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-createdBy",
        orderBy: sortState,
      };
    case "MODIFIED_ON_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-modifiedOn",
        orderBy: sortState,
      };
    case "MODIFIED_ON_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-modifiedOn",
        orderBy: sortState,
      };
    case "MODIFIED_BY_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-modifiedBy",
        orderBy: sortState,
      };
    case "MODIFIED_BY_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-modifiedBy",
        orderBy: sortState,
      };
    default:
      return {
        sortDirection: "ASC",
        headerId: "default-name",
        orderBy: sortState,
      };
  }
};
const defaultFilter: LabNotebookTemplateFilters = { orderBy: "NAME_ASC" };

export const useLabNotebookTemplatesTable = ({ fieldLabels }: UseEntityTableDefaultProps<"notebookTemplates">) => {
  const { session } = useContext(SessionContext);

  const defaults: ColumnsSettings<LabNotebookTemplate> = useMemo(
    () => ({
      "default-id": { pos: 0, active: false, header: fieldLabels.id, property: "id" },
      "default-name": { pos: 1, active: true, header: fieldLabels.name, property: "name" },
      "default-projects": {
        pos: 2,
        active: true,
        header: fieldLabels.projects,
        property: "projects",
      },
      "default-description": {
        pos: 3,
        active: true,
        header: fieldLabels.description,
        property: "description",
      },
      "default-createdOn": {
        pos: 4,
        active: true,
        header: fieldLabels.createdOn,
        property: "createdOn",
      },
      "default-createdBy": {
        pos: 5,
        active: true,
        header: fieldLabels.createdBy,
        property: "createdBy",
      },
      "default-modifiedOn": {
        pos: 6,
        active: true,
        header: fieldLabels.modifiedOn,
        property: "modifiedOn",
      },
      "default-modifiedBy": {
        pos: 7,
        active: true,
        header: fieldLabels.modifiedBy,
        property: "modifiedBy",
      },
    }),
    [
      fieldLabels.createdBy,
      fieldLabels.createdOn,
      fieldLabels.description,
      fieldLabels.id,
      fieldLabels.modifiedBy,
      fieldLabels.modifiedOn,
      fieldLabels.name,
      fieldLabels.projects,
    ]
  );

  const tabStoreDefaults: TableTabsDict<
    LabNotebookTemplate,
    LabNotebookTemplateFilters,
    LabNotebookTemplateFilterForm
  > = useMemo(
    () => ({
      default: {
        tabId: "default",
        type: "fixed",
        label: "All",
        title: "All",
        icon: "house",
        align: "left",
        xPos: 0,
        settings: {
          columnSettings: {},
          columnWidths: {},
          filters: defaultFilter,
          sidebarFilters: {},
        },
        forcedSettings: {
          columnSettings: {},
          columnWidths: {},
          filters: {},
          sidebarFilters: {},
        },
      },
      mydata: {
        tabId: "mydata",
        type: "fixed",
        label: "My templates",
        title: "My templates",
        icon: "house",
        align: "left",
        xPos: 1,
        settings: {
          columnSettings: {},
          columnWidths: {},
          filters: {},
          sidebarFilters: {},
        },
        forcedSettings: {
          columnSettings: {},
          columnWidths: {},
          filters: { createdByIds: session?.userId ? [session.userId] : null },
          sidebarFilters: {},
        },
      },
      trash: {
        tabId: "trash",
        type: "fixed",
        label: "Trash",
        title: "Trash",
        icon: "trash",
        align: "left",
        xPos: 3,
        settings: {
          columnSettings: {},
          columnWidths: {},
          filters: defaultFilter,
          sidebarFilters: {},
        },
        forcedSettings: {
          columnSettings: {},
          columnWidths: {},
          filters: { isSoftDeleted: true },
          sidebarFilters: {},
        },
      },
    }),
    [session?.userId]
  );

  return { defaults, tabStoreDefaults };
};

export const useLabNotebookTemplatesTableColumns = ({
  entityConstants,
  fieldLabels,
  sort,
  setSort,
}: UseEntityTableProps<"notebookTemplates">) => {
  const columns = useMemo(() => {
    let headers: GenericVirtualizedTableCells<LabNotebookTemplate> = [
      {
        id: "default-id",
        Header: fieldLabels.id,
        accessor: (row) => <span style={{ color: "var(--gray-400)" }}>{row.id}</span>,
        width: 120,
        minWidth: 120,
        align: "center",
        sortingFn: () => {
          if (sort.headerId === "default-id") {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "ID_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "ID_ASC" }));
            }
          } else {
            setSort({ headerId: "default-id", sortDirection: "ASC", orderBy: "ID_ASC" });
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
      {
        Header: fieldLabels.name,
        id: "default-name",
        accessor: (row) => (
          <div style={{ width: "100%", height: "100%", display: "flex", alignItems: "center" }}>
            <div
              style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                fontWeight: 600,
                whiteSpace: "nowrap",
                marginRight: "5px",
              }}
            >
              <LucideIcon name={entityConstants.icon} color="var(--primary)" />
              <span> {row.name}</span>
            </div>
          </div>
        ),
        minWidth: 150,
        width: 250,
        align: "left",
        sortingFn: () => {
          if (sort.headerId === "default-name") {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "NAME_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "NAME_ASC" }));
            }
          } else {
            setSort({ headerId: "default-name", sortDirection: "ASC", orderBy: "NAME_ASC" });
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
      {
        Header: fieldLabels.projects,
        id: "default-projects",
        accessor: (row) => <TableArrayRenderer values={row.projects}>{(value) => value.name}</TableArrayRenderer>,
        width: 200,
        align: "left",
      },
      {
        Header: fieldLabels.description,
        id: "default-description",
        accessor: (row) => (
          <div title={row.description} className={styles.notes}>
            {row.description || NotSet}
          </div>
        ),
        minWidth: 150,
        width: 600,
        align: "left",
      },
      {
        id: "default-createdOn",
        Header: fieldLabels.createdOn,
        accessor: (row) => <DateTimeRenderer date={row.createdOn} includeElapsed={false} />,
        width: 200,
        align: "left",
        sortingFn: () => {
          if (sort.headerId === "default-createdOn") {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "CREATED_ON_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "CREATED_ON_ASC" }));
            }
          } else {
            setSort({ headerId: "default-createdOn", sortDirection: "ASC", orderBy: "CREATED_ON_ASC" });
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
      {
        id: "default-createdBy",
        Header: fieldLabels.createdBy,
        accessor: (row) => <GetPersons persons={row.createdBy} createLinks={false} />,
        width: 200,
        align: "left",
        sortingFn: () => {
          if (sort.headerId === "default-createdBy") {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "CREATED_BY_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "CREATED_BY_ASC" }));
            }
          } else {
            setSort({ headerId: "default-createdBy", sortDirection: "ASC", orderBy: "CREATED_BY_ASC" });
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
      {
        id: "default-modifiedOn",
        Header: fieldLabels.modifiedOn,
        accessor: (row) => <DateTimeRenderer date={row.modifiedOn} includeElapsed={false} />,
        width: 200,
        align: "left",
        sortingFn: () => {
          if (sort.headerId === "default-modifiedOn") {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "MODIFIED_ON_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "MODIFIED_ON_ASC" }));
            }
          } else {
            setSort({ headerId: "default-modifiedOn", sortDirection: "ASC", orderBy: "MODIFIED_ON_ASC" });
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
      {
        id: "default-modifiedBy",
        Header: fieldLabels.modifiedBy,
        accessor: (row) => <GetPersons persons={row.modifiedBy} createLinks={false} />,
        width: 200,
        align: "left",
        sortingFn: () => {
          if (sort.headerId === "default-modifiedBy") {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "MODIFIED_BY_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "MODIFIED_BY_ASC" }));
            }
          } else {
            setSort({ headerId: "default-modifiedBy", sortDirection: "ASC", orderBy: "MODIFIED_BY_ASC" });
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
    ];

    return headers;
  }, [
    entityConstants.icon,
    fieldLabels.createdBy,
    fieldLabels.createdOn,
    fieldLabels.description,
    fieldLabels.id,
    fieldLabels.modifiedBy,
    fieldLabels.modifiedOn,
    fieldLabels.name,
    fieldLabels.projects,
    setSort,
    sort.headerId,
    sort.sortDirection,
  ]);
  return { columns };
};

export const LabNotebookTemplatesTable = ({
  entityApi,
  entityConstants,
  fieldLabels,
  permissions,
  routes,
}: EntityTableProps<"notebookTemplates">) => {
  const history = useHistory();
  const { route, session } = useContext(SessionContext);
  const [showModal, setShowModal] = useState(false);
  const {
    bulkRestoreMutationAsync: bulkRestoreLabNotebookTemplates,
    bulkDeleteMutationAsync: deleteLabNotebookTemplate,
    isLoadingBulkRestoreMutation,
    isLoadingBulkDeleteMutation,
  } = entityApi;

  const { defaults, tabStoreDefaults } = useLabNotebookTemplatesTable({ fieldLabels });

  const { selection, resultsCount, selectionPermissions, onCountChange, onSelectionChange, onSelectionPermissions } =
    useGenericVirtualizedTable<LabNotebookTemplate>();

  const {
    filters,
    sidebarFilters,
    forcedFilters,
    columnSetting,
    columnWidths,
    customTabs,
    fixedTabs,
    dispatchTabStore,
    currentTab,
    tabsLoading,
    tabsModified,
  } = useTabStore<LabNotebookTemplate, LabNotebookTemplateFilters, LabNotebookTemplateFilterForm>({
    resource: entityConstants.resource,
    defaults: tabStoreDefaults,
  });

  const { functionRef, sort, setSort, searchValue, setSearchValue, onTabChange } = useGenericVirtualizedTableTabs({
    tabsLoading,
    filters,
    switchSortState: switchLabNotebookTemplatesDefaultSortState,
    dispatchTabStore,
  });

  const { columns } = useLabNotebookTemplatesTableColumns({ fieldLabels, entityConstants, sort, setSort });

  const memoizedFilters = useMemo(
    () => ({ ...filters, ...forcedFilters, includeRelations: true }),
    [filters, forcedFilters]
  );

  return (
    <TableView>
      <TableView.Head>
        <TableView.Head.Label>
          <TableViewLabel entityConstants={entityConstants} resultsCount={resultsCount} />
        </TableView.Head.Label>
        <TableView.Head.Controls>
          <LinkButton
            linkTo={route(getAddRoute(entityConstants.frontendIndexRoute))}
            className="btn btn-primary"
            title={`Add ${entityConstants.entitySingular}`}
            disabled={!session?.permissions.can_create_eln_content}
          >
            <LucideIcon name="plus" /> Add {entityConstants.entitySingular}
          </LinkButton>
        </TableView.Head.Controls>
      </TableView.Head>
      <TableView.Body>
        <TableView.Body.Sidebar>
          <LabNotebookTemplateFilterBar
            initialValues={sidebarFilters}
            dispatchTabStore={dispatchTabStore}
            tabsLoading={tabsLoading}
            currentTab={currentTab}
          />
        </TableView.Body.Sidebar>
        <TableView.Body.Content>
          <EntityTableTabs
            currentTab={currentTab}
            onTabChange={onTabChange}
            fixedTabs={fixedTabs}
            filters={filters}
            sidebarFilters={sidebarFilters}
            columnSetting={columnSetting}
            columnWidths={columnWidths}
            customTabs={customTabs}
            dispatchTabStore={dispatchTabStore}
            tabsLoading={tabsLoading}
            tabsModified={tabsModified}
          />
          <EntityTable>
            <EntityTable.Controls
              style={{
                borderTop: "0px",
                borderRadius: "0px",
              }}
            >
              <EntityFilterIndicator<LabNotebookTemplate, LabNotebookTemplateFilters>
                filters={filters}
                excludeFilters={{ includeSoftDeleted: (value) => !!value, isSoftDeleted: (value) => !!value }}
                translatorConsts={LabNotebookTemplateFiltersTranslator}
              />
              <SearchInput searchValue={searchValue} setSearchValue={setSearchValue} placeholder="Search" />
              <EntityTableEditButton
                entityConstants={entityConstants}
                routes={routes}
                permissions={permissions}
                selection={selection}
                selectionPermissions={selectionPermissions}
              />
              <MoreDropdown drop="right" btn="btn btn-ghost-secondary">
                <li>
                  <EntityTableCloneButton
                    entityConstants={entityConstants}
                    routes={routes}
                    permissions={permissions}
                    selection={selection}
                  />
                </li>
                {currentTab === "trash" && (
                  <li>
                    <EntityTableRestoreButton
                      entityConstants={entityConstants}
                      selection={selection}
                      permissions={permissions}
                      selectionPermissions={selectionPermissions}
                      onClick={async () =>
                        await bulkRestoreLabNotebookTemplates({ ids: Array.from(selection) }).catch(() => {})
                      }
                      loading={isLoadingBulkRestoreMutation}
                    />
                  </li>
                )}
                <li>
                  <EntityTableSoftDeletableButton
                    currentTab={currentTab}
                    entityConstants={entityConstants}
                    selection={selection}
                    permissions={permissions}
                    selectionPermissions={selectionPermissions}
                    onClick={() => setShowModal(true)}
                  />
                </li>
              </MoreDropdown>
              <AlertModal
                type={`${currentTab === "trash" ? "danger" : "warning"}`}
                showModal={showModal}
                setShowModal={setShowModal}
                title={`${
                  selection.size === 0
                    ? `Select dataset to ${currentTab === "trash" ? "delete" : "trash"}`
                    : selection.size === 1
                    ? `${currentTab === "trash" ? "Delete" : "Trash"} the selected ${entityConstants.entitySingular}?`
                    : `${currentTab === "trash" ? "Delete" : "Trash"} the selected ${entityConstants.entityPlural}?`
                }`}
                description={`${
                  currentTab === "trash"
                    ? `Proceeding will permanently delete the selected ${entityConstants.entityPlural}.`
                    : `Proceeding will move the selected ${entityConstants.entityPlural} into trash.`
                }`}
                proceedLabel={`${currentTab === "trash" ? "Delete" : "Trash"}`}
                onProceed={async () => {
                  await deleteLabNotebookTemplate({
                    ids: Array.from(selection),
                    goBackOnSuccess: false,
                    showToast: false,
                    entityName: entityConstants.resource,
                    params: { deletePermanently: currentTab === "trash" },
                  }).catch((e) => {});
                  functionRef.current?.resetSelection();
                  setShowModal(false);
                }}
                loading={isLoadingBulkDeleteMutation}
              />
            </EntityTable.Controls>
            <EntityTable.Body<LabNotebookTemplate, LabNotebookTemplateFilters>
              functionRef={functionRef}
              entityConstants={entityConstants}
              filters={memoizedFilters}
              columns={columns}
              columnSelect
              columnSetting={columnSetting}
              columnWidths={columnWidths}
              defaultColumnSettings={defaults}
              dispatchTabStore={dispatchTabStore}
              setResultsCount={onCountChange}
              onSelectionChange={onSelectionChange}
              onSelectionPermissions={onSelectionPermissions}
              showPermissionColumn
              loading={tabsLoading}
              linkTo={(row) => `${route(getDetailLink(entityConstants.frontendIndexRoute, row.id))}/content/view`}
              onRowClick={(row) =>
                history.push(`${route(getDetailLink(entityConstants.frontendIndexRoute, row.id))}/content/view`)
              }
              fixedRowHeight={55}
            />
          </EntityTable>
        </TableView.Body.Content>
      </TableView.Body>
    </TableView>
  );
};
