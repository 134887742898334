import { Annotation } from "./../ViewerLayout/ViewerLayoutTypes";
import styles from "./Annotation.module.css";
import {
  AnnotationShape,
  colorToString,
  DataType,
  PathType,
  ShapeLine,
  ShapeText,
  ShapeXArea,
  trackdata1DType,
  trackProperties,
} from "./GraphViewerTypes";

export const translateAnnotationTypeToShape = (
  annotation: Annotation,
  allTracks: Record<string, trackProperties>,
  height: number,
  width: number
): AnnotationShape[] => {
  const annotationShapes: AnnotationShape[] = [];
  const tracks = annotation.tracks.filter((track) => track in allTracks);
  const color = annotation?.color ? colorToString(annotation.color) : "#566573";

  // console.log("tracks", annotation.type, tracks)

  let x: number;
  let x1: number;
  let x2: number;
  let y: number;
  let y1: number;
  let y2: number;
  let anchor: "start" | "end" | "middle";
  // let entry;
  switch (annotation.type) {
    case "xPoint":
      y1 = 0;
      y2 = height + 20;
      if (annotation?.mode === "low") y1 = height - 20;
      if (annotation?.mode === "high") y2 = 20;

      annotationShapes.push({
        type: "line",
        tracks: tracks,
        id: annotation.id,
        x1: annotation.pos.x,
        y1: y1,
        x2: annotation.pos.x,
        y2: y2,
        y1noScale: true,
        y2noScale: true,
        class: styles.annotation,
        color: color,
      });

      y = height - 10;
      if (annotation?.mode === "low") y = height - 10;
      else if (annotation?.mode === "high") y = 20;

      annotationShapes.push({
        type: "text",
        tracks: tracks,
        id: annotation.id,
        dx: 5,
        dy: 0,
        text: annotation.label.default,
        x: annotation.pos.x,
        y: y,
        ynoScale: true,
        class: [styles.annotationText, styles.noselect].join(" "),
        color: color,
      });
      break;

    case "yPoint":
      x1 = 0;
      x2 = width;
      if (annotation?.mode === "left") x2 = 20;
      if (annotation?.mode === "right") x1 = width - 20;

      annotationShapes.push({
        tracks: tracks,
        type: "line",
        id: annotation.id,
        x1: x1,
        y1: annotation.pos.y,
        x2: x2,
        y2: annotation.pos.y,
        x1noScale: true,
        x2noScale: true,
        class: styles.annotation,
        color: color,
      });

      x = 10;
      anchor = "start";
      if (annotation?.mode === "right") {
        x = width - 5;
        anchor = "end";
      }

      annotationShapes.push({
        tracks: tracks,
        type: "text",
        id: annotation.id,
        dx: 0,
        dy: -5,
        x: x,
        y: annotation.pos.y,
        text: annotation.label.default,
        xnoScale: true,
        class: [styles.annotationText, styles.noselect].join(" "),
        color: color,
        anchor: anchor,
      });
      break;
    case "xyPoint":
      annotationShapes.push({
        tracks: tracks,
        type: "circle",
        id: annotation.id,
        r: 3,
        x: annotation.pos.x,
        y: annotation.pos.y,
        class: styles.annotationCircle,
        color: color,
      });
      annotationShapes.push({
        tracks: tracks,
        type: "text",
        id: annotation.id,
        text: annotation.label.default,
        dx: 3,
        dy: -3,
        x: annotation.pos.x,
        y: annotation.pos.y,
        class: [styles.annotationText, styles.noselect].join(" "),
        color: color,
      });
      break;

    case "xRange":
      annotationShapes.push({
        tracks: tracks,
        type: "rect",
        id: annotation.id,
        x1: annotation.pos.x1,
        y1: 0,
        x2: annotation.pos.x2,
        y2: height,
        y1noScale: true,
        y2noScale: true,
        class: styles.annotationRect,
        color: color,
      });

      annotationShapes.push({
        tracks: tracks,
        type: "text",
        id: annotation.id,
        text: annotation.label.default,
        dx: 0,
        dy: 0,
        x: annotation.pos.x1 + (annotation.pos.x2 - annotation.pos.x1) / 2,
        y: 15,
        ynoScale: true,
        class: styles.annotationCenterText,
        color: color,
      });

      if (annotation.label.min)
        annotationShapes.push({
          tracks: tracks,
          type: "text",
          id: annotation.id,
          text: annotation.label.min,
          dx: 0,
          dy: 0,
          x: annotation.pos.x1,
          y: height / 2,
          ynoScale: true,
          class: styles.annotationCenterText,
          color: color,
          rotate: -90,
        });

      if (annotation.label.max)
        annotationShapes.push({
          tracks: tracks,
          type: "text",
          id: annotation.id,
          text: annotation.label.max,
          dx: 0,
          dy: 0,
          x: annotation.pos.x2,
          y: height / 2,
          ynoScale: true,
          class: styles.annotationCenterText,
          color: color,
          rotate: -90,
        });

      break;

    case "yRange":
      annotationShapes.push({
        tracks: tracks,
        type: "rect",
        id: annotation.id,
        x1: 0,
        y1: annotation.pos.y1,
        x2: width,
        y2: annotation.pos.y2,
        x1noScale: true,
        x2noScale: true,
        class: styles.annotationRect,
        color: color,
      });

      annotationShapes.push({
        tracks: tracks,
        type: "text",
        id: annotation.id,
        text: annotation.label.default,
        dx: 0,
        dy: 0,
        x: 15,
        y: annotation.pos.y1 + (annotation.pos.y2 - annotation.pos.y1) / 2,
        xnoScale: true,
        class: styles.annotationCenterText,
        color: color,
      });

      if (annotation.label.min)
        annotationShapes.push({
          tracks: tracks,
          type: "text",
          id: annotation.id,
          text: annotation.label.min,
          dx: 0,
          dy: 0,
          x: width / 2,
          y: annotation.pos.y1,
          xnoScale: true,
          class: styles.annotationCenterText,
          color: color,
        });

      if (annotation.label.max)
        annotationShapes.push({
          tracks: tracks,
          type: "text",
          id: annotation.id,
          text: annotation.label.max,
          dx: 0,
          dy: 0,
          x: width / 2,
          y: annotation.pos.y2,
          xnoScale: true,
          class: styles.annotationCenterText,
          color: color,
        });

      break;

    case "box":
      annotationShapes.push({
        tracks: tracks,
        type: "rect",
        id: annotation.id,
        x1: annotation.pos.x1,
        y1: annotation.pos.y1,
        x2: annotation.pos.x2,
        y2: annotation.pos.y2,
        class: annotation.mode === "border" ? styles.annotationRectBorder : styles.annotationRect,
        border: annotation.mode === "border",
        color: color,
      });

      annotationShapes.push({
        tracks: tracks,
        type: "text",
        id: annotation.id,
        text: annotation.label.default,
        dx: 0,
        dy: 0,
        x: annotation.pos.x1 + (annotation.pos.x2 - annotation.pos.x1) / 2,
        y: annotation.pos.y1 + (annotation.pos.y2 - annotation.pos.y1) / 2,
        class: styles.annotationCenterText,
        color: color,
      });

      if (annotation.label.above)
        annotationShapes.push({
          tracks: tracks,
          type: "text",
          id: annotation.id,
          text: annotation.label.above,
          dx: 0,
          dy: -3,
          x: annotation.pos.x1 + (annotation.pos.x2 - annotation.pos.x1) / 2,
          y: annotation.pos.y2,
          class: styles.annotationCenterText,
          color: color,
        });

      if (annotation.label.topLeft)
        annotationShapes.push({
          tracks: tracks,
          type: "text",
          id: annotation.id,
          text: annotation.label.topLeft,
          dx: 0,
          dy: 0,
          x: annotation.pos.x1,
          y: annotation.pos.y1,
          class: styles.annotationCenterText,
          color: color,
        });

      if (annotation.label.bottomLeft)
        annotationShapes.push({
          tracks: tracks,
          type: "text",
          id: annotation.id,
          text: annotation.label.bottomLeft,
          dx: 0,
          dy: 0,
          x: annotation.pos.x1,
          y: annotation.pos.y2,
          class: styles.annotationCenterText,
          color: color,
        });

      if (annotation.label.topRight)
        annotationShapes.push({
          tracks: tracks,
          type: "text",
          id: annotation.id,
          text: annotation.label.topRight,
          dx: 0,
          dy: 0,
          x: annotation.pos.x2,
          y: annotation.pos.y1,
          class: styles.annotationCenterText,
          color: color,
        });

      if (annotation.label.bottomRight)
        annotationShapes.push({
          tracks: tracks,
          type: "text",
          id: annotation.id,
          text: annotation.label.bottomRight,
          dx: 0,
          dy: 0,
          x: annotation.pos.x2,
          y: annotation.pos.y2,
          class: styles.annotationCenterText,
          color: color,
        });

      break;

    case "xArea":
      tracks.forEach((track) => {
        annotationShapes.push({
          tracks: [track],
          type: "xArea",
          id: annotation.id,
          x1: annotation.pos.x1,
          x2: annotation.pos.x2,
          path: {} as PathType,
          func: (entry: AnnotationShape, data: DataType) =>
            XArea(entry as ShapeXArea, data, annotation.pos.x1, annotation.pos.x2),
          class: styles.annotationRect,
          color: color,
        });

        annotationShapes.push({
          tracks: [track],
          type: "text",
          id: annotation.id,
          text: annotation.label.default,
          anchor: "middle",
          dx: 0,
          dy: 0,
          x: annotation.pos.x1 + (annotation.pos.x2 - annotation.pos.x1) / 2,
          y: 0,
          func: (entry: AnnotationShape, data: DataType) =>
            XArea(entry as ShapeText, data, annotation.pos.x1, annotation.pos.x2),
          class: styles.annotationCenterText,
          color: color,
        });

        if (annotation.label.min) {
          annotationShapes.push({
            tracks: [track],
            type: "text",
            id: annotation.id,
            text: annotation.label.min,
            dx: 0,
            dy: 0,
            x: annotation.pos.x1,
            y: 0,
            func: (entry: AnnotationShape, data: DataType) =>
              XArea(entry as ShapeText, data, annotation.pos.x1, annotation.pos.x2),
            class: styles.annotationCenterText,
            color: color,
            rotate: -90,
          });
        }

        if (annotation.label.max) {
          annotationShapes.push({
            tracks: [track],
            type: "text",
            id: annotation.id,
            text: annotation.label.max,
            dx: 0,
            dy: 8,
            x: annotation.pos.x2,
            y: 0,
            func: (entry: AnnotationShape, data: DataType) =>
              XArea(entry as ShapeText, data, annotation.pos.x1, annotation.pos.x2),
            class: styles.annotationCenterText,
            color: color,
            rotate: -90,
          });
        }
      });
      break;

    case "xPeak":
      tracks.forEach((track) => {
        annotationShapes.push({
          tracks: [track],
          type: "line",
          id: annotation.id,
          x1: annotation.pos.x,
          y1: 0,
          x2: annotation.pos.x,
          y2: 0,
          xPos: annotation.pos.x,
          func: (entry: AnnotationShape, data: DataType) => XPeak(entry as ShapeLine, data, annotation.pos.x),
          y2rel: !(annotation.mode === "impulse"),
          class: styles.annotation,
          color: color,
        });

        annotationShapes.push({
          tracks: [track],
          type: "text",
          id: annotation.id,
          text: annotation.label.default,
          dx: 0,
          dy: 0,
          x: annotation.pos.x,
          y: 0,
          func: (entry: AnnotationShape, data: DataType) => XPeak(entry as ShapeText, data, annotation.pos.x),
          class: [styles.annotationText, styles.noselect].join(" "),
          color: color,

          // id: annotation.id,
          // x: 0,
          // y: 0,
          // dx: 0,
          // dy: 0,
          // text: annotation.label.default,
          // func: (entry: AnnotationShape, data: DataType) => XPeak(entry, data, annotation.pos.x),
          // class: [styles.annotationText, styles.noselect].join(" "),
          // color: color,
        });
      });
      break;
  }

  return annotationShapes;
};

export const XArea = (
  entry: ShapeXArea | ShapeText,
  data: trackdata1DType,
  x1: number,
  x2: number
): ShapeXArea | ShapeText => {
  // TODO: implement function

  let first = data.size;
  let last = 0;

  for (let i = 1; i < data.size; i++) {
    first = i;
    if (data.x[i] >= x1) break;
    // console.log("x", data.x[i], "<", x1);
  }
  for (let i = first; i < data.size; i++) {
    last = i;
    if (data.x[i] > x2) break;
    // console.log("x", data.x[i], "<", x1);
  }

  // const x = data.x.slice(first, last);
  // const y = data.y.slice(first, last);

  if (entry.type === "xArea") {
    const x = data.x.slice(first, last);
    entry.path = { x: x, y: data.y.slice(first, last), size: x.length };
  } else if (entry.type === "text") {
    let min = Infinity;
    let max = -Infinity;

    for (let i = first; i < last; i++) {
      min = Math.min(min, data.y[i]);
      max = Math.max(max, data.y[i]);
    }
    if (last - first > 0) entry.y = min + (max - min) / 2;
  }
  return entry;
};

export const binarySearch = (list: Float32Array | Float64Array | number[], v: number) => {
  if (list.length < 1) return [-1, -1];
  let l = 0;
  let n = list.length - 1;
  let r = n;

  if (v < list[l]) return [-1, 0];
  if (v > list[r]) return [n, n + 1];

  let m = Math.floor(l + (r - l) / 2);

  while (r - l > 1) {
    if (v < list[m]) {
      r = m;
    } else if (v > list[m]) {
      l = m;
    } else {
      return [m, m];
    }
    m = Math.floor(l + (r - l) / 2);
  }

  return [l, r];
};

export const XPeak = (entry: ShapeLine | ShapeText, data: DataType, xPos: number): ShapeLine | ShapeText => {
  // TODO: implement function
  const pos = binarySearch(data.x, xPos);
  let x = xPos;
  let y = 0;

  if (pos[0] >= 0 && pos[1] < data.x.length) {
    // console.log("line", xPos, pos);
    x = data.x[pos[0]];
    y = data.y[pos[0]];
  }

  if (entry.type === "line") {
    entry.x1 = x;
    entry.x2 = x;

    if (entry.y2rel) {
      entry.y1 = y;
      entry.y2 = y > 0 ? -7 : 7;
    } else {
      entry.y1 = 0;
      entry.y2 = y;
    }
  } else if (entry.type === "text") {
    entry.dx = 3;
    entry.dy = y > 0 ? -5 : 8;
    entry.x = x;
    entry.y = y;
  }

  return entry;
};

export const difference = <T>(target: T, source: T): Partial<T> | undefined => {
  if (typeof target !== typeof source) return source;

  if (typeof target === "object") {
    if (target === source) return undefined;
    if (Array.isArray(target)) {
      if (!Array.isArray(source)) return source;
      if ((target as any[]).length !== (source as any[]).length) return source;
      for (let i in target) {
        if (difference(target[i], source[i]) !== undefined) return source;
      }
    } else {
      const diff: any = {};
      for (let key of Object.keys(target as any)) {
        if (typeof source === "object" && key in (source as any)) {
          const d = difference((target as Record<any, any>)[key], (source as Record<any, any>)[key]);
          if (d !== undefined) {
            diff[key] = d;
          }
        }
      }
      if (Object.keys(diff).length > 0) return diff;
    }
  } else {
    if (typeof target === "number" && typeof source === "number")
      return Math.abs((target as number) - source) > Number.EPSILON ? source : undefined;
    else if (target !== source) return source;
  }
  return undefined;
};
