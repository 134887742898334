import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, useWatch } from "react-hook-form";
import * as yup from "yup";
import { EntityFormProps } from "../../common/entity/EntityInterfaces";
import {
  CustomField,
  CustomFieldDataTypeUtils,
  customFieldConstants,
  CustomFieldFieldLabels,
} from "../../api/CustomFields";
import styles from "../../common/forms/forms.module.css";
import { FormHeader } from "../../common/forms/FormHeader";
import { InputFormField } from "../../common/formfields/InputFormField";
import { TextareaFormField } from "../../common/formfields/TextareaFormField";
import { FormButtons } from "../../common/forms/FormButtons";
import { toUppercase } from "../../common/helperfunctions/stringFunctions";
import { ToggleFormField } from "../../common/formfields/ToggleFormField";
import {
  AbstractedCustomFieldDataTypesConsts,
  EntitySelectOptionsConsts,
  generateDataTypeFromAbstracted,
  convertToYupShape,
  customFieldToCustomFieldForm,
  customFieldFormToCustomField,
  GenerateAbstractedFromDataTypeReturn,
  unifyEnumOptions,
} from "./CustomFieldUtils";
import { OverlayInfo } from "../../common/misc/OverlayInfo/OverlayInfo";
import { LucideIcon } from "../../common/icon/LucideIcon";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { CustomFieldEditRenderer } from "./CustomFieldEditRenderer";
import { GenericVirtualizedSelectFormField } from "../../common/formfields/GenericVirtualizedSelectFormField/GenericVirtualizedSelectFormField";
import { AbstractedCustomFieldDataTypeIcon } from "./CustomFieldRenderUtils";
import { Nullable } from "../../api/GenericTypes";
import { CustomTypesVirtualizedSelectForm } from "../../common/forms/EntityForms/formsVirtualized/CustomTypesVirtualizedSelectForm";
import { CustomTypeEntityType, CustomTypeEntityTypeOptions } from "../../api/CustomTypes";
import { SessionContext } from "../../common/contexts/SessionContext";
import { GenericModal, GenericModalWrapper } from "../../common/modals/Modal/GenericModal";
import { useEntityApi } from "../../api/useEntityApi";
import { InventoryTypesSelectForm } from "../../common/hierarchy/HierarchySelectForm/forms/InventoryTypesSelectForm";
import { FormFieldLayout } from "../../common/formfields/FormFieldLayouts";

export interface CustomFieldForm
  extends Omit<CustomField, "dataType" | "enumOptions">,
    Nullable<GenerateAbstractedFromDataTypeReturn> {
  dataType: CustomField["dataType"];
  enumOptions: (string | number)[] | null;
}

export const useCustomFieldFormSchema = ({ disableNameCheck }: { disableNameCheck?: boolean }) => {
  const { api } = useContext(SessionContext);
  const CustomFieldFormSchema = yup.object().shape({
    name: yup
      .string()
      .required("A name is required")
      .typeError("A name is required")
      .test("unique", async (value, context) => {
        if (disableNameCheck) return true;
        if (!context.parent.dataType)
          return context.createError({
            path: "abstractedDataType",
            message: () => "A data type must be defined",
          });
        const result = await api.post(
          `${customFieldConstants.resource}/count`,
          {
            dataTypes: [context.parent.dataType],
            searchTerm: context.parent.name,
            excludeIds: context.parent.id ? [context.parent.id] : [],
          },
          undefined
        );

        if (result.count === 0) {
          return true;
        }
        return context.createError({
          message: () => "A custom field with this name exists already",
        });
      }),
    dataType: yup.string().required("A data type must be defined").typeError("A data type must be defined"),
    readOnly: yup.bool(),
    defaultValues: yup.mixed().when(["dataType", "readOnly", "isMulti"], ([dataType, readOnly, isMulti]) => {
      if (isMulti === true) {
        if (readOnly === true) {
          return yup
            .array()
            .of(convertToYupShape(dataType.replace("Array", "") as CustomField["dataType"]))
            .transform((_, val) => (Array.isArray(val) && !!val.length ? val : null))
            .required();
        } else {
          return yup
            .array()
            .of(convertToYupShape(dataType.replace("Array", "") as CustomField["dataType"]))
            .transform((_, val) => (Array.isArray(val) && !!val.length ? val : null))
            .nullable();
        }
      } else {
        if (readOnly === true) {
          return convertToYupShape(dataType).nonNullable().required();
        } else {
          return convertToYupShape(dataType).nullable();
        }
      }
    }),
    entitySelectOption: yup.mixed().when(["abstractedDataType"], ([abstractedDataType], schema) => {
      if (abstractedDataType === "Entity Reference") {
        return yup
          .string()
          .required("An reference type must be defined")
          .typeError("An reference type must be defined");
      }
      return schema.nullable();
    }),
    customTypeConstraint: yup
      .mixed()
      .when(["abstractedDataType", "entitySelectOption"], ([abstractedDataType, entitySelectOption], schema) => {
        if (abstractedDataType === "Entity Reference" && entitySelectOption) {
          if (entitySelectOption === "Inventory") {
            return yup
              .array()
              .of(
                yup.object().shape({
                  id: yup.number().positive().required(),
                  name: yup.string().required(),
                })
              )
              .transform((_, val) => (Array.isArray(val) && !!val.length ? val : null))
              .required("Selecting an inventory constraint is required");
          }
          return yup
            .array()
            .of(
              yup.object().shape({
                id: yup.number().positive().required(),
                name: yup.string().required(),
              })
            )
            .nullable();
        }
        return schema.nullable();
      }),
    validationRegexp: yup.string().nullable(),
    validationMessage: yup.string().nullable(),
    enumOptions: yup.mixed().when(["dataType"], ([dataType], schema) => {
      if (dataType)
        return yup
          .array()
          .of(convertToYupShape(dataType.replace("Array", "") as CustomField["dataType"]))
          .transform((_, val) => (Array.isArray(val) && !!val.length ? val : null))
          .nullable();
      return schema;
    }),
  });
  return CustomFieldFormSchema;
};

interface CustomFieldFormCreateModalProps {
  index: number;
  initialValues: Partial<CustomField>;
  onSuccess: (index: number, result: CustomField) => void;
  onClear: () => void;
}
export const CustomFieldFormCreateModal = ({
  index,
  initialValues,
  onSuccess,
  onClear,
}: CustomFieldFormCreateModalProps) => {
  const [showModal, setShowModal] = useState(true);

  const { createMutationAsync, isLoadingCreateMutation } = useEntityApi<CustomField>(customFieldConstants.resource);

  const create = useCallback(
    async (entity: Partial<CustomField>) => {
      await createMutationAsync(
        { body: entity },
        {
          onSuccess: (result) => {
            onSuccess(index, result);
            onClear();
          },
        }
      ).catch(() => {});
    },
    [createMutationAsync, index, onClear, onSuccess]
  );

  return (
    <CustomFieldsFormComponents
      fieldLabels={CustomFieldFieldLabels}
      title={""}
      subtitle={""}
      initialValues={initialValues}
      onSubmit={create}
      onCancel={() => {
        setShowModal(false);
        onClear();
      }}
      submitButtonLabel={"Create"}
      loading={isLoadingCreateMutation}
      event={"ADD"}
    >
      {(head, body, footer) => {
        return (
          <GenericModal
            showModal={showModal}
            setShowModal={(value) => {
              onClear();
              return setShowModal(value);
            }}
            modalTitle={"Create new custom field"}
            modalBody={
              <div
                className="flex col-nowrap"
                style={{
                  width: "100%",
                  height: "100%",
                  overflowY: "scroll",
                  overflowX: "hidden",
                  padding: "10px 10px 10px 0px",
                }}
              >
                {body}
              </div>
            }
            modalControls={footer}
          />
        );
      }}
    </CustomFieldsFormComponents>
  );
};

export const CustomFieldsFormComponents = ({
  fieldLabels,
  title,
  subtitle,
  initialValues,
  onSubmit,
  onCancel,
  submitButtonLabel,
  loading,
  event,
  children,
}: Omit<EntityFormProps<"customFields">, "permissions"> & {
  children: (head: React.ReactNode, body: React.ReactNode, footer: React.ReactNode) => React.ReactNode;
}) => {
  const defaultValues = useMemo(
    () => ({
      ...initialValues,
      ...customFieldToCustomFieldForm(initialValues),
    }),
    [initialValues]
  );

  const CustomFieldFormSchema = useCustomFieldFormSchema({ disableNameCheck: event === "EDIT" });
  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors, isSubmitting, dirtyFields },
    reset,
  } = useForm<Partial<CustomFieldForm>>({
    values: defaultValues,
    resolver: yupResolver(CustomFieldFormSchema),
  });
  const dataType = useWatch({ name: "dataType", control: control });
  const required = useWatch({ name: "required", control: control });
  const readOnly = useWatch({ name: "readOnly", control: control });
  const enumOptionsFromValues = useWatch({ name: "enumOptionsFromValues", control: control });
  const enumOptions = useWatch({ name: "enumOptions", control: control });
  // Pure frontend properties
  const abstractedDataType = useWatch({ name: "abstractedDataType", control: control });
  const showAsTextArea = useWatch({ name: "showAsTextArea", control: control });
  const isMulti = useWatch({ name: "isMulti", control: control });
  const isFloat = useWatch({ name: "isFloat", control: control });
  const isDateTime = useWatch({ name: "isDateTime", control: control });
  const isDateTimeRange = useWatch({ name: "isDateTimeRange", control: control });
  const isTimeRange = useWatch({ name: "isTimeRange", control: control });
  const entitySelectOption = useWatch({ name: "entitySelectOption", control: control });
  const customTypeConstraint = useWatch({ name: "customTypeConstraint", control: control });
  // Entire form

  useEffect(() => {
    if (abstractedDataType) {
      const _dataType = generateDataTypeFromAbstracted({
        abstractedDataType: abstractedDataType,
        isMulti: !!isMulti,
        isDateTime: !!isDateTime,
        isDateTimeRange: !!isDateTimeRange,
        isTimeRange: !!isTimeRange,
        isFloat: !!isFloat,
        entitySelectOption: entitySelectOption ?? undefined,
      });
      if (_dataType) {
        setValue("dataType", _dataType);
      } else {
        setValue("dataType", undefined);
      }
    }
  }, [
    abstractedDataType,
    defaultValues,
    entitySelectOption,
    isDateTime,
    isDateTimeRange,
    isFloat,
    isMulti,
    isTimeRange,
    reset,
    setValue,
  ]);

  useEffect(() => {
    if (abstractedDataType && Object.hasOwn(dirtyFields, "abstractedDataType") && dirtyFields.abstractedDataType) {
      const _dataType = generateDataTypeFromAbstracted({
        abstractedDataType: abstractedDataType,
        isMulti: false,
        isDateTime: false,
        isDateTimeRange: false,
        isTimeRange: false,
        isFloat: false,
        entitySelectOption: undefined,
      });
      reset({
        abstractedDataType: abstractedDataType,
        dataType: _dataType,
        isMulti: false,
        isFloat: false,
        isDateTime: false,
        isDateTimeRange: false,
        isTimeRange: false,
        entitySelectOption: null,
        customTypeConstraint: null,
        defaultValues: null,
        enumOptions: null,
        enumOptionsFromValues: false,
        placeholder: "",
        description: "",
        validationRegexp: "",
        validationMessage: "",
        required: false,
        readOnly: false,
        showAsTextArea: false,
      });
    }
  }, [abstractedDataType, dirtyFields, reset]);

  const head = (
    <fieldset>
      <legend className="col-md-offset-2 col-md-10">Basic details</legend>
    </fieldset>
  );

  const body = (
    <>
      {/* Hidden input to map abstracted datatype to actual datatype */}
      <input type="hidden" {...register("id")} />
      <InputFormField
        id="name"
        label={fieldLabels.name}
        errors={errors}
        register={register}
        autoFocus={true}
        placeholder="Enter custom field name..."
        required
      />

      <GenericVirtualizedSelectFormField
        id={"abstractedDataType"}
        label={fieldLabels.dataType}
        items={AbstractedCustomFieldDataTypesConsts.map((e) => e)}
        control={control}
        placeholder={"Select data type"}
        disabled={!!(initialValues as any)?.abstractedDataType || !["ADD", "CLONE"].includes(event)}
        onRowStartRenderer={(row) => (
          <>
            <AbstractedCustomFieldDataTypeIcon dataType={row} />{" "}
          </>
        )}
        required
        horizontal
      >
        <OverlayInfo icon="circle-help">
          This defines the underlying data type of the custom field values provided.
        </OverlayInfo>
      </GenericVirtualizedSelectFormField>
      {/* Hidden input to map abstracted datatype to actual datatype */}
      <input type="hidden" {...register("dataType")} />

      {abstractedDataType === "Entity Reference" && (
        <>
          <GenericVirtualizedSelectFormField
            id={"entitySelectOption"}
            label={fieldLabels.entitySelectOption}
            items={Array.from(EntitySelectOptionsConsts)
              .sort()
              .map((e) => e)}
            control={control}
            placeholder={"Select entity reference type"}
            required
            horizontal
            onRowStartRenderer={(row) => (
              <>
                <LucideIcon
                  name={CustomFieldDataTypeUtils.MapAbstractedEntityReferenceTypeToEntityConstants(row).icon}
                  color="var(--primary)"
                />{" "}
              </>
            )}
            disabled={!["ADD", "CLONE"].includes(event)}
          >
            <OverlayInfo icon="circle-help">Select the entity type to reference.</OverlayInfo>
          </GenericVirtualizedSelectFormField>
          {entitySelectOption &&
            dataType &&
            CustomFieldDataTypeUtils.CanDefineCustomTypeConstraint(dataType) &&
            [...CustomTypeEntityTypeOptions].includes(entitySelectOption as any) && (
              <>
                {entitySelectOption === "Inventory" ? (
                  <GenericModalWrapper>
                    {({ showModal, setShowModal }) => (
                      <>
                        <FormFieldLayout
                          id=""
                          label={
                            entitySelectOption ? `${entitySelectOption} constraint` : fieldLabels.customTypeConstraint
                          }
                          horizontal
                          hasLabel
                          required
                        >
                          <div className="flex row-nowrap align-center gap-5" style={{ width: "100%" }}>
                            <button
                              className="btn btn-primary"
                              onClick={() => setShowModal(true)}
                              type="button"
                              style={{ marginBottom: "auto" }}
                            >
                              Select inventory
                            </button>
                            <div className="flex row-nowrap align-center gap-5" style={{ width: "100%" }}>
                              <CustomTypesVirtualizedSelectForm
                                id="customTypeConstraint"
                                control={control}
                                filters={{ entityTypes: [entitySelectOption as CustomTypeEntityType] }}
                                isMulti
                                horizontal
                                showControls
                                disabled
                                hasLabel={false}
                                uncontained
                                placeholder="Select inventory"
                              >
                                <div style={{ marginLeft: "5px" }}>
                                  <OverlayInfo icon="circle-help">
                                    Constrain the entity selection to specific{" "}
                                    {entitySelectOption ? `${entitySelectOption} types` : "custom types"}.
                                  </OverlayInfo>
                                </div>
                              </CustomTypesVirtualizedSelectForm>
                            </div>
                          </div>
                          <InventoryTypesSelectForm
                            id="customTypeConstraint"
                            control={control}
                            showModal={showModal}
                            setShowModal={setShowModal}
                          />
                        </FormFieldLayout>
                      </>
                    )}
                  </GenericModalWrapper>
                ) : (
                  <CustomTypesVirtualizedSelectForm
                    id="customTypeConstraint"
                    control={control}
                    label={
                      entitySelectOption ? `${entitySelectOption} type constraint` : fieldLabels.customTypeConstraint
                    }
                    filters={{ entityTypes: [entitySelectOption as CustomTypeEntityType] }}
                    isMulti
                    horizontal
                    showControls
                  >
                    <OverlayInfo icon="circle-help">
                      Constrain the entity selection to specific custom types.
                    </OverlayInfo>
                  </CustomTypesVirtualizedSelectForm>
                )}
              </>
            )}
        </>
      )}

      <TextareaFormField
        id="description"
        label={fieldLabels.description}
        errors={errors}
        register={register}
        placeholder="Enter custom field description..."
      />

      <InputFormField
        id="placeholder"
        label={fieldLabels.placeholder}
        errors={errors}
        register={register}
        placeholder="Enter custom field editing placeholder..."
        buttons={
          <OverlayInfo icon="circle-help">
            This will show up in the edit dialogue as a placeholder if no value (or default value) is defined.
          </OverlayInfo>
        }
      />

      {abstractedDataType && dataType && (
        <>
          {CustomFieldDataTypeUtils.HasMultiSelect(dataType) && (
            <ToggleFormField
              id="isMulti"
              label={fieldLabels.isMultiValue}
              control={control}
              disabled={!["ADD", "CLONE"].includes(event)}
              horizontal
            >
              <OverlayInfo icon="circle-help">Allow multiple selection.</OverlayInfo>
            </ToggleFormField>
          )}
          {CustomFieldDataTypeUtils.IsNumberDataType(dataType) && (
            <ToggleFormField
              id="isFloat"
              label={fieldLabels.isFloat}
              control={control}
              disabled={!["ADD", "CLONE"].includes(event)}
              horizontal
            >
              <OverlayInfo icon="circle-help">Use floating precision.</OverlayInfo>
            </ToggleFormField>
          )}
          {abstractedDataType === "Date" && !isDateTimeRange && (
            <ToggleFormField
              id="isDateTime"
              label={fieldLabels.isDateTime}
              control={control}
              disabled={!["ADD", "CLONE"].includes(event)}
              horizontal
            >
              <OverlayInfo icon="circle-help">Use date and time.</OverlayInfo>
            </ToggleFormField>
          )}
          {abstractedDataType === "Date" && (
            <ToggleFormField
              id="isDateTimeRange"
              label={fieldLabels.isDateTimeRange}
              control={control}
              disabled={!["ADD", "CLONE"].includes(event)}
              horizontal
            >
              <OverlayInfo icon="circle-help">Use a datetime range.</OverlayInfo>
            </ToggleFormField>
          )}
          {abstractedDataType === "Time" && (
            <ToggleFormField
              id="isTimeRange"
              label={fieldLabels.isTimeRange}
              control={control}
              disabled={!["ADD", "CLONE"].includes(event)}
              horizontal
            >
              <OverlayInfo icon="circle-help">Use duration.</OverlayInfo>
            </ToggleFormField>
          )}
          {CustomFieldDataTypeUtils.IsValueDataType(dataType) && (
            <>
              <ToggleFormField id="required" label={fieldLabels.required} control={control} horizontal>
                <OverlayInfo icon="circle-help">Mark this field as required.</OverlayInfo>
              </ToggleFormField>

              {!required && (
                <ToggleFormField id="readOnly" label={fieldLabels.readOnly} control={control} horizontal>
                  <OverlayInfo icon="circle-help">Make this field read-only.</OverlayInfo>
                </ToggleFormField>
              )}
              {dataType === "String" && (
                <ToggleFormField id="showAsTextArea" label={fieldLabels.showAsTextArea} control={control} horizontal>
                  <OverlayInfo icon="circle-help">Show this field as a text area.</OverlayInfo>
                </ToggleFormField>
              )}
              {CustomFieldDataTypeUtils.IsCustomizibleRegexDataType(dataType) && (
                <>
                  <InputFormField
                    id={"validationRegexp"}
                    label={fieldLabels.validationRegexp}
                    errors={errors}
                    register={register}
                    placeholder={"Enter custom validation regular expression (optional)"}
                  />
                  <InputFormField
                    id={"validationMessage"}
                    label={fieldLabels.validationMessage}
                    errors={errors}
                    register={register}
                    placeholder={"Enter validation message (optional)"}
                  />
                </>
              )}

              {CustomFieldDataTypeUtils.IsDefaultValueDataType(dataType) && (
                <CustomFieldEditRenderer
                  id="defaultValues"
                  label={fieldLabels.defaultValues}
                  register={register}
                  control={control}
                  errors={errors}
                  dataType={dataType}
                  required={!!readOnly}
                  placeholder={"Enter default value"}
                  showAsTextArea={!!showAsTextArea}
                  enumOptions={unifyEnumOptions(enumOptions)}
                  enumOptionsFromValues={!!enumOptionsFromValues}
                  customTypeConstraint={customTypeConstraint}
                  isMulti={!!isMulti}
                  filters={
                    Array.isArray(customTypeConstraint) && !!customTypeConstraint.length
                      ? { customTypeIds: customTypeConstraint.map((c) => c.id) }
                      : { customTypeIds: null }
                  }
                  disabled={
                    entitySelectOption === "Inventory" &&
                    (!customTypeConstraint || (Array.isArray(customTypeConstraint) && !customTypeConstraint.length))
                  }
                  description={"Define the default value for this field."}
                />
              )}

              {CustomFieldDataTypeUtils.CanDefineEnumOptionsFromValues(dataType) && (
                <>
                  <ToggleFormField
                    id="enumOptionsFromValues"
                    label={fieldLabels.enumOptionsFromValues}
                    control={control}
                    horizontal
                  >
                    <OverlayInfo icon="circle-help">
                      A user will be able to define a new value or choose from previous values that were assigned to
                      this field.
                    </OverlayInfo>
                  </ToggleFormField>
                  {!enumOptionsFromValues && (
                    <GenericVirtualizedSelectFormField
                      id={"enumOptions"}
                      label={fieldLabels.enumOptions}
                      items={enumOptions}
                      control={control}
                      placeholder={"Enter a new option to select from"}
                      isMulti
                      allowCreateEntity
                      horizontal
                    >
                      <OverlayInfo icon="circle-help">
                        A user will be able to select from this set of options or create a new option.
                      </OverlayInfo>
                    </GenericVirtualizedSelectFormField>
                  )}
                </>
              )}
            </>
          )}
          {/* <div className="flex col-nowrap align-center gap-5" style={{ width: "100%" }}>
            {dataType && name && (
              <>
                <LucideIcon name="ArrowDown" style={{ width: 50, height: 50, color: "var(--gray-300)" }} />
                <RenderCustomFieldPreview
                  initialValues={
                    { ...defaultValues, ...customFieldFormToCustomField(customField) } as Partial<CustomField>
                  }
                  dataType={dataType}
                  trigger={trigger}
                />
              </>
            )}
          </div> */}
        </>
      )}
    </>
  );

  const footer = (
    <FormButtons
      entityId={initialValues ? initialValues.id : undefined}
      onClose={onCancel}
      onSubmit={handleSubmit(async (entity) => {
        // Remove pure frontend properties
        delete entity.abstractedDataType;
        delete entity.isMulti;
        delete entity.isFloat;
        delete entity.isDateTimeRange;
        delete entity.isDateTime;
        delete entity.entitySelectOption;

        return await onSubmit(customFieldFormToCustomField({ ...initialValues, ...entity }));
      })}
      submitButtonLabel={submitButtonLabel || "Save changes"}
      disabled={isSubmitting}
      errors={errors}
      loading={loading}
    />
  );

  return <>{children(head, body, footer)}</>;
};

export const CustomFieldsForm = ({
  fieldLabels,
  permissions,
  title,
  subtitle,
  initialValues,
  onSubmit,
  onCancel,
  submitButtonLabel,
  loading,
  event,
}: EntityFormProps<"customFields">) => {
  return (
    <CustomFieldsFormComponents
      fieldLabels={fieldLabels}
      title={title}
      subtitle={subtitle}
      initialValues={initialValues}
      onSubmit={onSubmit}
      onCancel={onCancel}
      submitButtonLabel={submitButtonLabel}
      loading={loading}
      event={event}
    >
      {(head, body, footer) => {
        return (
          <div
            className="flex col-nowrap align-center gap-5"
            style={{ width: "100%", height: "fit-content", overflow: "scroll" }}
          >
            <div style={{ width: "100%" }}>
              <FormHeader title={title} subtitle={subtitle} />
              <div className={`form-horizontal ${styles.form_holder}`}>
                {head}
                {body}
                {footer}
              </div>
            </div>
          </div>
        );
      }}
    </CustomFieldsFormComponents>
  );
};
