import React, { Dispatch, ReactNode, useContext, useEffect } from "react";
import {
  RenderBundle,
  RenderBundleListControlsProps,
  RenderBundleProps,
  RenderBundleRowControlsProps,
} from "./components/RenderBundle";
import { AnalysisResults, LoadingStatus, ManualUploadActions } from "./FileHandlingUtils";
import { ManualUploadContext } from "./ManualUpload";
import { Parser } from "../../api/Parsers";
import { RenderNoResults } from "./components/RenderNoResults";
import { progressBar } from "./components/common/RenderDatasetUtils";
import { MatchResults } from "./FileFormatAutoDetection";

export interface ManualUploadBundleViewProps {
  isViewableEntity: boolean;
  dispatch: Dispatch<ManualUploadActions>;
  loadingStatus?: LoadingStatus;
  loading: boolean;
  parsers?: Parser[];
  results: AnalysisResults;
  calculateSHA256: RenderBundleProps["calculateSHA256"];
  onCreateCallback?: RenderBundleProps["onCreateCallback"];
  additionalRowControls?: (props: RenderBundleRowControlsProps) => ReactNode;
  additionalListControls?: (props: RenderBundleListControlsProps) => ReactNode;
  onAbortBundle?: (bundle: MatchResults) => void;
}
export const ManualUploadBundleView = ({
  isViewableEntity,
  dispatch,
  loadingStatus,
  loading,
  parsers,
  results,
  calculateSHA256,
  onCreateCallback,
  additionalRowControls,
  additionalListControls,
  onAbortBundle,
}: ManualUploadBundleViewProps) => {
  const context = useContext(ManualUploadContext);

  useEffect(() => {
    if (!Object.keys(results).length) {
      context?.stepProgress.setCurrentStep(1);
      context?.isDirty.setIsDirty(false);
    }
  }, [context?.isDirty, context?.stepProgress, results]);

  return (
    <div className="flex col-nowrap" style={{ width: "100%", height: "100%", overflow: "hidden", padding: 5 }}>
      {!!Object.keys(results).length && Array.isArray(parsers) && parsers.length > 0 ? (
        <div className={"flex col-nowrap gap-5"} style={{ width: "100%", height: "100%", overflow: "hidden" }}>
          {loading && progressBar(loadingStatus)}
          {Object.entries(results)
            .reverse()
            .map(([actionUUID, bundle]) => {
              if (!!Object.keys(bundle).length) {
                return (
                  <RenderBundle
                    isViewableEntity={isViewableEntity}
                    actionUUID={actionUUID}
                    bundle={bundle}
                    key={actionUUID}
                    dispatch={dispatch}
                    calculateSHA256={calculateSHA256}
                    parsers={parsers}
                    onCreateCallback={onCreateCallback}
                    additionalRowControls={additionalRowControls}
                    additionalListControls={additionalListControls}
                    onAbortBundle={onAbortBundle}
                  />
                );
              } else {
                return <RenderNoResults key={actionUUID} dispatch={dispatch} />;
              }
            })}
        </div>
      ) : loading ? (
        <div style={{ padding: "5px 5px" }}>{progressBar(loadingStatus)}</div>
      ) : (
        !loading && loadingStatus && <RenderNoResults dispatch={dispatch} message={loadingStatus.message} />
      )}
    </div>
  );
};
