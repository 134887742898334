import { Dispatch, SetStateAction, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Modal } from "../ELN/common/ELNModal/ELNModal";
import { PersonsSelectWidget } from "../Persons/Table/PersonsSelectWidget";
import { useEntityDetail, usePost } from "../api/BaseEntityApi";
import { Person, PersonFilters, PersonSuggestions } from "../api/Person";
import { LucideIcon } from "../common/icon/LucideIcon";
import {
  ProjectPersonPermission,
  ProjectPersonPermissionHeader,
  ProjectPersonPermissionParameters,
  ProjectPersonPermissionRow,
} from "./ProjectPersonPermissions";
import { Project } from "../api/Projects";
import { useDebouncedValue } from "../common/helperfunctions/useDebouncedValue";
import { SessionContext } from "../common/contexts/SessionContext";
import { IResultModel } from "../api/GenericTypes";
import { Alert } from "../common/overlays/Alert/Alert";
import { FormFieldError } from "../common/formfields/FormFieldLayouts";
import { DeepMap, FieldError } from "react-hook-form";
import { LoadingWrapper } from "../common/LoadingWrapper";

export const ProjectPersonPermissionsEditForm = ({
  id,
  errors,
  project,
  getProjectPersonPermissions,
}: {
  id: string;
  errors?: DeepMap<any, FieldError>;
  project?: Project;
  getProjectPersonPermissions?: (values: ProjectPersonPermission[]) => void;
}) => {
  const [showPersonSelectionWidget, setShowPersonSelectionWiget] = useState<boolean>(false);
  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const { api, session } = useContext(SessionContext);

  const [searchValue, setSearchValue] = useState<string>("");
  const debouncedSearchValue = useDebouncedValue(searchValue.toLowerCase(), 300);

  const handleSelect = useCallback((selection?: Set<number>) => {
    if (selection) setSelectedIds(Array.from(selection));
  }, []);

  const [projectPersonPermissions, setProjectPersonPermissions] = useState<ProjectPersonPermission[]>([]);

  useEffect(() => {
    if (!!project?.id) {
      api
        .post(`projects/${project?.id || 0}/projectPermissions/list/all`, {})
        .then((r) => {
          setProjectPersonPermissions(r.results);
        })
        .catch((e) => console.error(e));
    }
  }, [api, project?.id]);

  const {
    data: initialProjectPersonPermissions,
    status,
    isFetching,
    fetchStatus,
    error,
  } = usePost<IResultModel<ProjectPersonPermission>>(
    `projects/${project?.id || 0}/projectPermissions/list/all`,
    {} as ProjectPersonPermissionParameters,
    { enabled: !!project?.id }
  );

  const initialProjectPersonPermissionPersonIds = useMemo(() => {
    return initialProjectPersonPermissions?.results.map((i) => i.person.id) || [];
  }, [initialProjectPersonPermissions?.results]);

  const { data: currentUser } = useEntityDetail<Person, PersonFilters>(
    "persons",
    session?.userId || 0,
    { includeSystemUsers: !!session?.person.isSystemUser },
    { enabled: !!session?.userId }
  );

  const [newProjectPersonPermission, setNewProjectPersonPermissions] = useState<ProjectPersonPermission[]>([]);

  useEffect(() => {
    if (!project?.id && !!currentUser) {
      setNewProjectPersonPermissions((prevState) =>
        prevState.length === 0
          ? [{ administer: true, edit: true, add: true, read: true, person: currentUser }]
          : prevState
      );
    }
  }, [currentUser, project]);

  const [initialAdminister, setInitialAdminister] = useState<boolean>(false);
  const [initialAdd, setInitialAdd] = useState<boolean>(false);
  const [initialEdit, setInitialEdit] = useState<boolean>(false);

  const handleOnChage = useCallback(
    (
      values: { administer: boolean; add: boolean; edit: boolean; read: boolean },
      index: number,
      setter: Dispatch<SetStateAction<ProjectPersonPermission[]>>
    ) => {
      setter((prevState) => {
        let items = [...prevState];
        items[index] = { ...prevState[index], ...values } as ProjectPersonPermission;
        return items;
      });
    },
    []
  );

  const handleDelete = useCallback((index: number, setter: Dispatch<SetStateAction<ProjectPersonPermission[]>>) => {
    setter((prevState) => {
      let items = [...prevState.filter((v, i) => i !== index)];
      return items;
    });
  }, []);

  const modifiedProjectPersonPermissions = useMemo(
    () => [...projectPersonPermissions, ...newProjectPersonPermission],
    [newProjectPersonPermission, projectPersonPermissions]
  );

  useEffect(() => {
    getProjectPersonPermissions?.(modifiedProjectPersonPermissions);
  }, [getProjectPersonPermissions, modifiedProjectPersonPermissions]);

  // const hasChanged = useCallback(
  //   (projectPersonPermission: ProjectPersonPermission) => {
  //     const target = initialProjectPersionPermissions?.results.find(
  //       (t) => projectPersonPermission.person.id === t.person.id
  //     );

  //     return (
  //       target?.administer !== projectPersonPermission.administer ||
  //       target?.add !== projectPersonPermission.add ||
  //       target?.edit !== projectPersonPermission.edit ||
  //       target?.read !== projectPersonPermission.read
  //     );
  //   },
  //   [initialProjectPersionPermissions?.results]
  // );

  // const [isResetting, setIsResetting] = useState<boolean>(false);
  // const handleReset = useCallback(() => {
  //   setIsResetting(true);
  //   if (initialProjectPersionPermissions) {
  //     setProjectPersonPermissions([]);
  //     setTimeout(() => {
  //       setProjectPersonPermissions(initialProjectPersionPermissions.results);
  //       setIsResetting(false);
  //     }, 200);
  //   }
  //   setNewProjectPersonPermissions([]);
  // }, [initialProjectPersionPermissions]);

  return (
    <div>
      <div className="row" style={{ overflow: "auto" }}>
        <div className="col-sm-10 col-sm-offset-2">
          <LoadingWrapper fetchStatus={fetchStatus} error={error} status={isFetching ? "loading" : status}>
            <ProjectPersonPermissionHeader
              count={modifiedProjectPersonPermissions.length}
              searchValue={searchValue}
              setSearchValue={setSearchValue}
              target="persons"
              // onReset={handleReset}
              // onDelete={() => {
              //   setProjectPersonPermissions([]);
              //   setNewProjectPersonPermissions([]);
              // }}
            />
            <div style={{ overflow: "auto", maxHeight: "350px", minWidth: "max-content" }}>
              {/* {isResetting && <Skeleton type="rows" />} */}
              {/* {!isResetting && !modifiedProjectPersonPermissions.length && (
              <Alert style={{ marginTop: "5px" }} type="light" fit centered message="No persons found..." />
            )} */}
              {!modifiedProjectPersonPermissions.length && (
                <Alert style={{ marginTop: "5px" }} type="light" fit centered message="No persons found..." />
              )}

              {newProjectPersonPermission.map((p, i) => (
                <div
                  key={"new_" + p.person.id + "_" + i}
                  style={{
                    display: p.person.name.toLowerCase().includes(debouncedSearchValue) ? "" : "none",
                  }}
                >
                  <ProjectPersonPermissionRow
                    projectPersonPermission={p}
                    target="persons"
                    label={<span className="label label-soft-success">new</span>}
                    onChange={(values) => handleOnChage(values, i, setNewProjectPersonPermissions)}
                    onDelete={() => handleDelete(i, setNewProjectPersonPermissions)}
                  />
                </div>
              ))}

              {projectPersonPermissions.map((p, i) => (
                <div
                  key={"old_" + p.person.id + "_" + i}
                  style={{
                    display: p.person.name.toLowerCase().includes(debouncedSearchValue) ? "" : "none",
                  }}
                >
                  <ProjectPersonPermissionRow
                    projectPersonPermission={p}
                    initialProjectPersonPermission={initialProjectPersonPermissions?.results.find(
                      (i) => i.person.id === p.person.id
                    )}
                    target="persons"
                    onChange={(values) => handleOnChage(values, i, setProjectPersonPermissions)}
                    onDelete={() => handleDelete(i, setProjectPersonPermissions)}
                  />
                </div>
              ))}
            </div>
          </LoadingWrapper>
        </div>
      </div>

      <div className="row">
        <div className="col-sm-10 col-sm-offset-2">
          <button
            type="button"
            className="btn btn-ghost-primary btn-xs btn-block"
            style={{ margin: "5px 0" }}
            onClick={() => setShowPersonSelectionWiget(true)}
          >
            <LucideIcon name="plus" />
            <span> Add person</span>
          </button>

          <hr style={{ margin: "5px 0", marginBottom: "15px" }} />
        </div>
      </div>

      <div className="row">
        <div className="col-sm-10 col-sm-offset-2">
          <FormFieldError id={id} errors={errors} />
        </div>
      </div>

      <Modal
        isOpen={showPersonSelectionWidget}
        onClose={() => setShowPersonSelectionWiget(false)}
        controls={
          <div style={{ display: "flex", gap: "20px", width: "100%" }}>
            <div className="flex align-center gap-5" style={{ width: "100%", paddingLeft: "10px" }}>
              <span
                style={{
                  paddingTop: "2px",
                  paddingRight: "20px",
                  whiteSpace: "nowrap",
                }}
              >
                Permissions:
              </span>
              <label
                className="flex align-center justify-center gap-5"
                style={{ width: "70px", minWidth: "70px", cursor: "pointer" }}
              >
                <input
                  type="checkbox"
                  checked={initialAdminister}
                  onChange={() => {
                    setInitialAdminister((prevState) => {
                      !prevState && setInitialEdit(true);
                      !prevState && setInitialAdd(true);
                      return !prevState;
                    });
                  }}
                />
                <span style={{ paddingTop: "6px" }}> Admin</span>
              </label>
              <label
                className="flex align-center justify-center gap-5"
                style={{ width: "70px", minWidth: "70px", cursor: "pointer" }}
              >
                <input
                  type="checkbox"
                  checked={initialEdit}
                  onChange={() => {
                    setInitialEdit((prevState) => {
                      prevState && setInitialAdminister(false);
                      !prevState && setInitialAdd(true);
                      return !prevState;
                    });
                  }}
                />
                <span style={{ paddingTop: "6px" }}> Edit</span>
              </label>
              <label
                className="flex align-center justify-center gap-5"
                style={{ width: "70px", minWidth: "70px", cursor: "pointer" }}
              >
                <input
                  type="checkbox"
                  checked={initialAdd}
                  onChange={() => {
                    setInitialAdd((prevState) => {
                      prevState && setInitialAdminister(false);
                      prevState && setInitialEdit(false);
                      return !prevState;
                    });
                  }}
                />
                <span style={{ paddingTop: "6px" }}> Add</span>
              </label>
              <label
                className="flex align-center justify-center gap-5"
                style={{ width: "70px", minWidth: "70px", cursor: "not-allowed" }}
              >
                <input type="checkbox" checked={true} readOnly />
                <span style={{ paddingTop: "6px" }}> Read</span>
              </label>
            </div>
            <button
              type="button"
              className="btn btn-primary"
              onClick={async () => {
                const persons = (
                  (await api
                    .post("persons/suggestions", {
                      ids: selectedIds,
                      pageSize: 1000,
                      page: 1,
                      orderBy: "NAME_ASC",
                      includeSystemUsers: !!session?.person.isSystemUser,
                    } as PersonFilters)
                    .catch((e) => console.error(e))) as IResultModel<PersonSuggestions>
                )?.results;
                if (Array.isArray(persons)) {
                  setProjectPersonPermissions((prevState) => [
                    ...prevState,
                    ...persons
                      .filter((p) => !prevState.some((x) => x.person.id === p.id))
                      .filter((p) => initialProjectPersonPermissionPersonIds.includes(p.id))
                      .map((p) => ({
                        person: p,
                        administer: initialAdminister,
                        edit: initialAdminister || initialEdit,
                        add: initialAdminister || initialAdd,
                        read: true,
                      })),
                  ]);
                  setNewProjectPersonPermissions((prevState) => [
                    ...prevState,
                    ...persons
                      .filter((p) => !prevState.some((x) => x.person.id === p.id))
                      .filter((p) => !initialProjectPersonPermissionPersonIds.includes(p.id))
                      .map((p) => ({
                        person: p,
                        administer: initialAdminister,
                        edit: initialAdminister || initialEdit,
                        add: initialAdminister || initialAdd,
                        read: true,
                      })),
                  ]);
                } else {
                  console.error("Wrong result type when fetching selected persons");
                }
                setShowPersonSelectionWiget(false);
                setInitialAdminister(false);
                setInitialEdit(false);
                setInitialAdd(false);
              }}
            >
              <LucideIcon name="chevrons-down" />
              <span> Select</span>
            </button>
          </div>
        }
      >
        <div style={{ width: "90vw", height: "calc(95vh - 100px)", overflow: "hidden" }}>
          <PersonsSelectWidget
            setSelectionCallback={handleSelect}
            additionalFilters={{
              excludeIds: modifiedProjectPersonPermissions?.map((p) => p.person.id) || [],
              hasAccount: true,
              isAccountEnabled: true,
            }}
          />
        </div>
      </Modal>
    </div>
  );
};
