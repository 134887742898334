import {
  PropertyTranslator,
  IGenericRequestParametersTranslator,
  IPaginationParametersTranslator,
  ITypedEntityCustomValuesSearchParametersTranslator,
  IRelationParametersTranslator,
  IOwnedEntityParametersTranslator,
  ISoftDeletableFilterParametersTranslator,
  ICreatedRecordParametersTranslator,
  IModifiedRecordParametersTranslator,
  INamedEntityParametersTranslator,
  IProjectBasedParametersTranslator,
  ICreationRecordLabels,
  ICustomTypedEntityLabels,
  IModificationRecordLabels,
  INamedEntityLabels,
  IPermissionedEntityLabels,
  IUniqueEntityLabels,
  ISoftDeletableLabels,
  IRelatedEntityLabels,
} from "./GenericTranslator";
import {
  IEntityMinimalModel,
  IModelWithIntId,
  IPermissionedEntity,
  IProjectBasedResponseModel,
  IOwnedEntity,
  IRelatedEntity,
  IRelationModel,
  IPaginationParameters,
  IGenericRequestParameters,
  IRelationParameters,
  IRelation,
  IUniqueEntity,
  ISoftDeletable,
  ISoftDeletableFilterParameters,
  IOwnedEntityParameters,
  EntityConstants,
  DefaultOrderTypeConsts,
  DefaultCustomTypeOrderTypeConsts,
  ICustomTypedEntity,
  TypedEntitySuggestionsModel,
  INamedEntity,
  ICreationRecord,
  IModificationRecord,
  DefaultCreatedByRecordOrderTypeConsts,
  DefaultModifiedByRecordOrderTypeConsts,
  DefaultCreatedOnRecordOrderTypeConsts,
  DefaultModifiedOnRecordOrderTypeConsts,
  ICreatedRecordParameters,
  IModifiedRecordParameters,
  CustomFieldValuesSearchParametersITypedEntity,
  ITypedEntityCustomValuesSearchParameters,
  INamedEntityParameters,
  IProjectBasedParameters,
} from "./GenericTypes";

export const samplesConstants: EntityConstants<Sample, SampleFilters> = {
  resource: "samples",
  frontendIndexRoute: "samples",
  entitySingular: "sample",
  entityPlural: "samples",
  icon: "test-tube-diagonal",
};

export interface Sample
  extends IModelWithIntId,
    IPermissionedEntity,
    IProjectBasedResponseModel,
    IOwnedEntity,
    IRelatedEntity<SampleRelations>,
    IUniqueEntity,
    ISoftDeletable,
    ICustomTypedEntity,
    INamedEntity,
    ICreationRecord,
    IModificationRecord {
  preparedAt: string;
  discardedAt: string | null;
  preparedBy: IEntityMinimalModel[];
  discardedBy: IEntityMinimalModel[];
  notes: string;
  organizations?: IEntityMinimalModel[];
  sequenceNumber?: number;
  nameSuffix: string;
  other: string;
}

export const SampleFieldLabels: PropertyTranslator<Sample> = {
  ...INamedEntityLabels,
  ...IPermissionedEntityLabels,
  ...ICreationRecordLabels,
  ...IModificationRecordLabels,
  ...ICustomTypedEntityLabels,
  ...IUniqueEntityLabels,
  ...IPermissionedEntityLabels,
  ...ISoftDeletableLabels,
  ...IRelatedEntityLabels,
  id: "Sample ID",
  notes: "Notes",
  organizations: "Organizations",
  owner: "Owner",
  preparedAt: "Prepared At",
  preparedBy: "Prepared By",
  projects: "Projects",
  sequenceNumber: "Legacy ID",
  discardedAt: "Discarded At",
  discardedBy: "Discarded By",
  nameSuffix: "Name Suffix",
  other: "Other",
} as const;

export interface SampleSuggestions extends TypedEntitySuggestionsModel<Sample["id"]> {
  discardedAt: string | null;
}

export interface SampleRelations extends IRelationModel {
  datasets: IRelation;
  labNotebookEntries: IRelation;
}

export const SampleSortingOptionsConsts = [
  ...DefaultOrderTypeConsts,
  ...DefaultCustomTypeOrderTypeConsts,
  ...DefaultCreatedByRecordOrderTypeConsts,
  ...DefaultModifiedByRecordOrderTypeConsts,
  ...DefaultCreatedOnRecordOrderTypeConsts,
  ...DefaultModifiedOnRecordOrderTypeConsts,
  "PREPARATION_DATE_ASC",
  "PREPARATION_DATE_DESC",
  "DISCARDED_DATE_ASC",
  "DISCARDED_DATE_DESC",
] as const;
export type SampleSortingOptions = (typeof SampleSortingOptionsConsts)[number];

export interface SampleFilters
  extends IPaginationParameters,
    IGenericRequestParameters<Sample, SampleSortingOptions>,
    ITypedEntityCustomValuesSearchParameters<CustomFieldValuesSearchParametersITypedEntity>,
    IRelationParameters,
    ISoftDeletableFilterParameters,
    IOwnedEntityParameters,
    ICreatedRecordParameters,
    IModifiedRecordParameters,
    INamedEntityParameters,
    IProjectBasedParameters {
  organizationIds?: number[] | null;
  preparedByIds?: number[] | null;
  customFields?: {
    [property: string]: string;
  } | null;
  preparedAtFrom?: string | null;
  preparedAtTo?: string | null;
  participatedPersonIds?: number[] | null;
  discardedByIds?: number[] | null;
  discardedAtFrom?: string | null;
  discardedAtTo?: string | null;
  excludeDiscarded?: boolean | null;
  searchTermIncludeNotes?: boolean | null;
}

export const SampleFiltersTranslator: PropertyTranslator<SampleFilters> = {
  ...IGenericRequestParametersTranslator,
  ...IPaginationParametersTranslator,
  ...ITypedEntityCustomValuesSearchParametersTranslator,
  ...IRelationParametersTranslator,
  ...ISoftDeletableFilterParametersTranslator,
  ...IOwnedEntityParametersTranslator,
  ...ICreatedRecordParametersTranslator,
  ...IModifiedRecordParametersTranslator,
  ...INamedEntityParametersTranslator,
  ...IProjectBasedParametersTranslator,
  organizationIds: "Organization IDs",
  preparedByIds: "Prepared by IDs",
  customFields: "Custom fields",
  preparedAtFrom: "Prepared from",
  preparedAtTo: "Prepared to",
  participatedPersonIds: "Participated person IDs",
  discardedByIds: "Discarded by IDs",
  discardedAtFrom: "Discarded from",
  discardedAtTo: "Discarded to",
  excludeDiscarded: "Exclude discarded samples",
  searchTermIncludeNotes: "notes",
} as const;

export const SampleSearchTermOptions = {
  searchTermIncludeNames: SampleFiltersTranslator["searchTermIncludeNames"],
  searchTermIncludeIds: SampleFiltersTranslator["searchTermIncludeIds"],
  searchTermIncludeUuids: SampleFiltersTranslator["searchTermIncludeUuids"],
  searchTermIncludeNotes: SampleFiltersTranslator["searchTermIncludeNotes"],
} as const;
