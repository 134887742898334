import { useQuery } from "@tanstack/react-query";
import { fetchAuthenticationConfig } from "../../api/Login";
import { LoadingWrapper } from "../../common/LoadingWrapper";
import { AuthConfigContext } from "./AuthConfigContext";
import { AuthProvider } from "react-oidc-context";
import { WebStorageStateStore } from "oidc-client-ts";

interface ChildrenProps {
  group: string;
  children: React.ReactNode;
}

/**
 * AuthenticationConfigProvider component is responsible for fetching the authentication configuration
 * and providing it to the AuthConfigContext. It also sets up the AuthProvider with the necessary
 * configuration for OpenID Connect (OIDC) authentication.
 * @author @CorradoSurmanowicz
 * @param {ChildrenProps} props - The properties passed to the component.
 * @param {string} props.group - The group identifier used to fetch the authentication configuration.
 * @param {React.ReactNode} props.children - The child components to be rendered within the AuthProvider.
 *
 * @returns {JSX.Element} The rendered component wrapped with LoadingWrapper, AuthConfigContext.Provider, and AuthProvider.
 */
export const AuthenticationConfigProvider = ({ group, children }: ChildrenProps) => {
  const {
    data: authConfig,
    status,
    fetchStatus,
  } = useQuery({
    queryKey: ["auth_config"],
    queryFn: async ({ signal }) => await fetchAuthenticationConfig(group, signal),
    cacheTime: Infinity,
    staleTime: Infinity,
  });

  return (
    <LoadingWrapper status={status} fetchStatus={fetchStatus}>
      {authConfig && (
        <AuthConfigContext.Provider value={authConfig}>
          <AuthProvider
            authority={authConfig.oidcAuthority}
            client_id={authConfig.oidcClientId}
            redirect_uri={`${window.location.origin}/${group}/oidc_redirect`}
            response_type="code"
            scope="openid profile email"
            post_logout_redirect_uri={`${window.location.origin}/${group}`}
            userStore={new WebStorageStateStore({ prefix: `group_${group}_`, store: window.localStorage })}
            // onSigninCallback={() => {
            //   window.history.replaceState({}, window.document.title, window.location.origin + window.location.pathname);
            //   window.location.replace(`${window.location.origin}/${group}`);
            // }}
          >
            {children}
          </AuthProvider>
        </AuthConfigContext.Provider>
      )}
    </LoadingWrapper>
  );
};
