import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { InputFormField } from "../../../formfields/InputFormField";
import { FormButtons } from "../../FormButtons";
import { FormHeader } from "../../FormHeader";
import {
  LabNotebook,
  LabNotebookStatusList,
  labNoteBooksConstants,
  labNotebookFieldLabels,
} from "../../../../ELN/types/LabNotebook";
import { useIdsOnly } from "../../../../api/BaseEntityApi";
import { LabNotebookExperimentFilters } from "../../../../ELN/types/LabNotebookExperiment";
import { SelectFormField } from "../../../formfields/SelectFormField";
import { ProjectsVirtualizedSelectForm } from "../../EntityForms/formsVirtualized/ProjectsVirtualizedSelectForm";
import { TextareaFormField } from "../../../formfields/TextareaFormField";
import { LabNotebookFormValues } from "../../../../ELN/LabNotebooks/Forms/LabNotebookForm";
import { useCallback } from "react";
import { useQuickEditSubmit } from "../common/useQuickEditHooks";
import { QuickEditChildrenProps } from "../common/QuickEditWrapper";

export const QuickEditLabNotebookForm = ({ onEdit, onCancel, initialValues }: QuickEditChildrenProps<LabNotebook>) => {
  const { onSubmit } = useQuickEditSubmit<LabNotebook>({
    resource: labNoteBooksConstants.resource,
    onEdit: onEdit,
    onCancel: onCancel,
  });

  const { data: runningExperiments } = useIdsOnly<number, LabNotebookExperimentFilters>(
    "lab_notebook_experiments",
    { labNotebookIds: initialValues?.id ? [initialValues.id] : null, status: ["RUNNING", "PAUSED"] },
    { enabled: !!initialValues }
  );

  const FormSchema = yup.object().shape({
    name: yup.string().required("Lab notebook name is required").typeError("Lab notebook name is required"),
    status: yup
      .object()
      .required("Lab notebook status is required")
      .typeError("Lab notebook status is required")
      .test({
        name: "closedForEditing",
        test: (value: any) => !initialValues || initialValues.status !== "CLOSED" || value.value !== "CLOSED",
        message: "The notebook is closed for editing. Set status to 'Active' to reopen it.",
        exclusive: false,
      })
      .test({
        name: "hasRunningExperiments",
        test: (value: any) => {
          return !(runningExperiments?.results.length && value.value === "CLOSED");
        },
        message: "The notebook still has running (or paused) experiments.",
        exclusive: false,
      }),
  });

  const entityToFormValues = useCallback((entity?: LabNotebook) => {
    return !!entity
      ? ({
          ...entity,
          status: {
            id: entity.status,
            name: entity.status.charAt(0) + entity.status.slice(1).toLowerCase(),
            value: entity.status,
          },
        } as LabNotebookFormValues)
      : {};
  }, []);

  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
  } = useForm<LabNotebookFormValues>({
    defaultValues: entityToFormValues(initialValues),
    resolver: yupResolver(FormSchema),
  });

  const formValuesToEntity = useCallback(
    (entity: LabNotebookFormValues) =>
      entity.id && onSubmit(entity.id, { ...entity, status: entity.status.value } as LabNotebook),
    [onSubmit]
  );

  return (
    <>
      <FormHeader title="Edit lab notebook" subtitle={initialValues?.name} />

      <InputFormField
        id="name"
        label={labNotebookFieldLabels.name}
        errors={errors}
        register={register}
        autoFocus={true}
        required
        placeholder="Enter lab notebook name..."
      />

      <SelectFormField
        id="status"
        label={labNotebookFieldLabels.status}
        items={LabNotebookStatusList.map((s) => {
          return { id: s, name: s.charAt(0) + s.slice(1).toLowerCase(), value: s };
        })}
        control={control}
        required
        menuPortalTarget={document.body}
      />

      <ProjectsVirtualizedSelectForm
        id="projects"
        label={labNotebookFieldLabels.projects}
        control={control}
        showControls
        horizontal
        isMulti
        filters={{ currentUserHasAddPermission: true }}
      />

      <TextareaFormField
        id="description"
        label={labNotebookFieldLabels.description}
        errors={errors}
        register={register}
        placeholder="Enter description..."
      />

      <FormButtons
        groupName={labNoteBooksConstants.frontendIndexRoute}
        entityId={initialValues?.id}
        onClose={onCancel}
        onSubmit={handleSubmit(formValuesToEntity)}
        disabled={isSubmitting}
        errors={errors}
        disableDefaultOnClose
      />
    </>
  );
};
