import { initType } from "./PipelineTypes";

export class StackTraceMessage {
  component: string;
  message: string;
  id: string;

  constructor(init?: StackTraceMessage) {
    this.component = "";
    this.message = "";
    this.id = "";
    initType(this, init);
  }
}

type stringSettingType = { indent?: number; id?: string; list?: boolean };

export class StackTrace {
  trace: StackTraceMessage[];

  constructor(init?: StackTraceMessage | StackTrace) {
    this.trace = [];
    if (init) {
      if ("trace" in init) this.trace = init.trace.slice();
      else this.add(new StackTraceMessage(init));
    }
  }

  add(message: StackTraceMessage) {
    this.trace.unshift(message);
  }

  hasId(id: string): boolean {
    const ids = new Set(this.trace.map((m) => m.id));
    return ids.has(id);
  }

  toString(settings?: { indent?: number; id?: string }): string {
    if (settings?.id) {
      if (!this.hasId(settings.id)) return "";
      // const ids = new Set(this.trace.map((m) => m.id));
      // if (!ids.has(settings.id)) return "";
      // if (ids.has(settings.id) !== settings?.invert ?? false) return "";
    }
    return this.trace
      .map((m, i) => `${" ".repeat(i * (settings?.indent ?? 2))}<${m.component}>  ${m.message}`)
      .join("\n");
  }
}

export class StackTraces {
  traces: StackTrace[];

  constructor(init?: StackTrace | StackTrace[]) {
    this.traces = [];
    if (init) {
      if (Array.isArray(init)) this.traces = init.slice();
      else this.add(init);
    }
  }

  add(traces: StackTrace | StackTrace[]) {
    if (Array.isArray(traces)) this.traces.push(...traces);
    else this.traces.push(traces);
  }

  addTraces(traces: StackTraces) {
    traces.traces.forEach((trace) => this.add(trace));
  }

  addTraceWithMessage(trace: StackTrace, message: StackTraceMessage) {
    const t = new StackTrace(trace);
    t.add(message);
    this.add(t);
  }

  addTracesWithMessage(traces: StackTraces, message: StackTraceMessage) {
    traces.traces.forEach((trace) => this.addTraceWithMessage(trace, message));
  }

  create(message: StackTraceMessage) {
    this.add(new StackTrace(message));
  }

  toStringList(settings?: stringSettingType): string[] {
    return this.traces.map((m) => m.toString(settings)).filter((m) => m !== "");
  }

  toString(settings?: stringSettingType): string {
    return this.toStringList(settings).join("\n\n");
  }

  clear(id?: string) {
    if (id) this.traces = this.traces.filter((m) => !m.hasId(id));
    else this.traces = [];
  }

  get length() {
    return this.traces.length;
  }
}
