import React, { CSSProperties, useCallback, useEffect, useMemo, useRef, useState } from "react";
import styles from "./GenericVirtualizedSelectFormField.module.css";
import { LucideIcon } from "../../icon/LucideIcon";
import { useDebouncedValue } from "../../helperfunctions/useDebouncedValue";
import { FixedSizeList } from "react-window";
import { Control, GlobalError } from "react-hook-form";
import { FloatingDiv } from "../../buttons/FloatingDiv/FloatingDiv";
import { v4 as uuidv4 } from "uuid";
import { useResizeDetector } from "react-resize-detector";
import { IEntityMinimalModel, StringIndexedDict } from "../../../api/GenericTypes";
import { GenericControllerProvider } from "../../forms/common/GenericControllerProvider";
import { FormGroup } from "react-bootstrap";
import { getError } from "./GenericVirtualizedEntitySelectFormFieldControllerWrapper";
import { FormFieldError, FormFieldLayout } from "../FormFieldLayouts";
import { ResourceName } from "../../../main/Routing";
import { useInfiniteList } from "../../../api/BaseEntityApi";
import { CustomFieldValuesFilters } from "../../../api/CustomFields";
import InfiniteLoader from "react-window-infinite-loader";
import { LoadingWrapper } from "../../LoadingWrapper";
import { SearchTermError, NoResults } from "./common/GenericVirtualizedRenderers";

const isEmpty = (value: any) => {
  if (value === null) return true;
  if (value === undefined) return true;
  if (Array.isArray(value)) {
    if (!value.length) return true;
    else return false;
  }
  if (value) return false;
  return true;
};
const convertToString = <T extends GenericVirtualizedSelectFormFieldValueType>(value: T) => {
  if (value === null) return "";
  if (value === undefined) return "";
  if (typeof value === "object" && Object.hasOwn(value, "name")) return value.name;
  return value.toString();
};
const filterValues = <T extends GenericVirtualizedSelectFormFieldValueType>(
  values: T | T[] | null | undefined,
  filterValue: string
) => {
  if (!filterValue) return values;
  if (Array.isArray(values)) return values.filter((v) => convertToString(v) !== filterValue);
  return values;
};
const searchValues = <T extends GenericVirtualizedSelectFormFieldValueType>(
  values: T | T[] | null | undefined,
  filterValue: string
) => {
  if (!filterValue) return values;
  if (Array.isArray(values))
    return values.filter((v) => convertToString(v).toLowerCase().includes(filterValue.toLowerCase()));
  return values;
};
const hasValue = <T extends GenericVirtualizedSelectFormFieldValueType>(
  values: T | T[] | null | undefined,
  filterValue: string
) => {
  if (Array.isArray(values)) {
    return values.some((v) => convertToString(v) === filterValue);
  } else {
    return convertToString(values) === filterValue;
  }
};

export type GenericVirtualizedSelectFormFieldValueType =
  | string
  | number
  | boolean
  | null
  | undefined
  | IEntityMinimalModel<number>
  | IEntityMinimalModel<string>;

export interface GenericVirtualizedSelectFormFieldProps<
  Entity extends StringIndexedDict,
  T extends GenericVirtualizedSelectFormFieldValueType
> {
  id: any;
  label?: string;
  items?: T[] | null;
  control: Control<Entity, any, Entity>;
  onBlur?: () => void;
  defaultValue?: string | string[];
  placeholder?: string;
  isMulti?: boolean;
  required?: boolean;
  disabled?: boolean;
  allowCreateEntity?: boolean;
  horizontal?: boolean;
  hasLabel?: boolean;
  uncontained?: boolean;
  onRowStartRenderer?: (item: T) => React.ReactNode;
  onRowEndRenderer?: (item: T) => React.ReactNode;
  concatItems?: T[];
  resource?: ResourceName;
  itemsFromOptions?: boolean;
  selectFieldStyle?: CSSProperties;
  style?: CSSProperties;
  children?: React.ReactNode;
}

/**
 * Renders a generic virtualized select form field.
 * @author @CorradoSurmanowicz
 * @template Entity - The type of the entity.
 * @template T - The type of the generic virtualized select form field value.
 *
 * @param {GenericVirtualizedSelectFormFieldProps<Entity, T>} props - The props for the generic virtualized select form field.
 * @returns {JSX.Element} - The rendered generic virtualized select form field.
 */
export const GenericVirtualizedSelectFormField = <
  Entity extends StringIndexedDict,
  T extends GenericVirtualizedSelectFormFieldValueType
>(
  props: GenericVirtualizedSelectFormFieldProps<Entity, T>
) => (
  <GenericControllerProvider<Entity> id={props.id} control={props.control} defaultValue={props.defaultValue}>
    {({ value, onChange }, fieldState, { errors }) => (
      <>
        {props.uncontained ? (
          <FormGroup controlId={props.id} style={{ margin: 0, width: "100%", height: "100%" }}>
            <div className="flex" style={{ margin: 0, width: "100%", minHeight: "100%", ...props.style }}>
              <GenericVirtualizedEntitySelectFormFieldCore
                value={value}
                items={props.items}
                onChange={onChange}
                onBlur={props.onBlur}
                error={getError(props.id, errors)}
                placeholder={props.placeholder}
                isMulti={props.isMulti}
                disabled={props.disabled}
                allowCreateEntity={props.allowCreateEntity}
                onRowStartRenderer={props.onRowStartRenderer}
                onRowEndRenderer={props.onRowEndRenderer}
                selectFieldStyle={props.selectFieldStyle}
                concatItems={props.concatItems}
                resource={props.resource}
                itemsFromOptions={props.itemsFromOptions}
              />
              {props.children ? <div className="flex row-nowrap align-center gap-5">{props.children}</div> : <></>}
            </div>
          </FormGroup>
        ) : (
          <FormFieldLayout
            id={props.id}
            label={props.label ?? ""}
            required={props.required}
            horizontal={props.horizontal}
            hasLabel={props.hasLabel}
          >
            <div className={"flex row-nowrap gap-5"} style={{ ...props.style, alignItems: "stretch" }}>
              <GenericVirtualizedEntitySelectFormFieldCore
                value={value}
                items={props.items}
                onChange={onChange}
                onBlur={props.onBlur}
                error={getError(props.id, errors)}
                placeholder={props.placeholder}
                isMulti={props.isMulti}
                disabled={props.disabled}
                allowCreateEntity={props.allowCreateEntity}
                onRowStartRenderer={props.onRowStartRenderer}
                onRowEndRenderer={props.onRowEndRenderer}
                selectFieldStyle={props.selectFieldStyle}
                concatItems={props.concatItems}
                resource={props.resource}
                itemsFromOptions={props.itemsFromOptions}
              />
              {props.children ? <div className="flex row-nowrap align-center gap-5">{props.children}</div> : <></>}
            </div>
            <FormFieldError id={props.id} errors={errors} />
          </FormFieldLayout>
        )}
      </>
    )}
  </GenericControllerProvider>
);

export interface GenericVirtualizedEntitySelectFormFieldCoreProps<
  T extends GenericVirtualizedSelectFormFieldValueType
> {
  value?: T | T[];
  items?: T[] | null;
  onChange: (values: T | T[]) => void; // For controlled inputs via Controller
  onBlur?: () => void; // onBlur callback
  error: GlobalError; // Changes the styling if an error is defined
  placeholder?: string; // Form placeholder
  isMulti?: boolean; // Single or multi-select
  disabled?: boolean; // Disable form
  allowCreateEntity?: boolean; // bool to control wether to add the add entity option
  onRowStartRenderer?: GenericVirtualizedSelectFormFieldOptionsProps<T>["onRowStartRenderer"]; // optional callback to render additional content per row
  onRowEndRenderer?: GenericVirtualizedSelectFormFieldOptionsProps<T>["onRowEndRenderer"]; // optional callback to render additional content per row
  concatItems?: GenericVirtualizedSelectFormFieldOptionsProps<T>["concatItems"]; // custom injected items (will be concatenated to the start)
  selectFieldStyle?: CSSProperties; // CSS override
  itemsFromOptions?: boolean;
  resource?: GenericVirtualizedSelectFormFieldOptionsFromValuesProps<T>["resource"];
}
export const GenericVirtualizedEntitySelectFormFieldCore = <T extends GenericVirtualizedSelectFormFieldValueType>({
  value,
  items,
  onChange,
  onBlur,
  error,
  placeholder,
  isMulti,
  disabled,
  allowCreateEntity,
  onRowStartRenderer,
  onRowEndRenderer,
  concatItems,
  selectFieldStyle,
  itemsFromOptions,
  resource,
}: GenericVirtualizedEntitySelectFormFieldCoreProps<T>) => {
  const [active, setActive] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const debouncedSearchValue = useDebouncedValue(inputValue, 100);
  const _items = useMemo(
    () => (debouncedSearchValue ? (searchValues(items, debouncedSearchValue) as T[]) : items || []),
    [debouncedSearchValue, items]
  );

  const [isInputFocused, setIsInputFocused] = useState(false);
  const selectRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const { width } = useResizeDetector({ targetRef: selectRef });

  const onOpenChange = useCallback(
    (open: boolean) => {
      if (!disabled) {
        if (open) {
          // console.log("Open");
          setActive(true);
          setIsInputFocused(true);
        } else {
          // console.log("Close");
          setActive(false);
          setIsInputFocused(false);
          setInputValue("");
          onBlur?.();
        }
      }
    },
    [disabled, onBlur]
  );

  useEffect(() => {
    if (active && inputRef.current) {
      // console.log("Focusing input");
      setTimeout(() => inputRef.current?.focus(), 100);
    }
  }, [active, inputRef]);

  const onRemove = useCallback(
    (item: T) => {
      if (Array.isArray(value)) {
        // onChange((value as T[]).filter((v) => v !== item));
        onChange(filterValues(value, convertToString(item)) as T[]);
      } else if (value && value === item) {
        return onChange(undefined as T);
      } else {
        return onChange(undefined as T);
      }
    },
    [onChange, value]
  );

  const onItemClick = useCallback(
    (item: T) => {
      if (isMulti) {
        let writeValue: T[] = [];
        // if (((value as T[]) || []).some((v) => v === item)) {
        //   writeValue = ((value as T[]) || []).filter((v) => v !== item) as T[];
        // } else {
        //   writeValue = [...((value as T[]) || []), item] as T[];
        // }
        if (hasValue(value, convertToString(item))) {
          writeValue = filterValues(value, convertToString(item)) as T[];
        } else {
          writeValue = [...((value as T[]) || []), item] as T[];
        }
        onChange(writeValue);
      } else {
        onChange(item);
      }
    },
    [isMulti, onChange, value]
  );

  const onCreate = useCallback(
    (newValue: T) => {
      if (isMulti) {
        onChange([...((value as T[]) || []), newValue]);
      } else {
        onChange(newValue);
      }
    },
    [isMulti, onChange, value]
  );

  // const isUnique = useCallback(
  //   (inputValue: T) =>
  //     {
  //     return !items?.some((item) => item === inputValue) && Array.isArray(value)
  //       ? !value.some((item) => item === inputValue)
  //       : value !== inputValue;
  //   },
  //   [items, value]
  // );

  const isUnique = useCallback(
    (inputValue: T) => !hasValue(items, convertToString(inputValue)) && !hasValue(value, convertToString(inputValue)),
    [items, value]
  );

  return (
    <>
      {/* Actual dropdown */}
      <FloatingDiv
        // defaultOpen={!!inputValue}
        enabled={!disabled}
        disableToggle={isInputFocused}
        onOpenChange={onOpenChange}
        triggerComponent={({ ref, getReferenceProps, setOpen }) => (
          <div className={styles.selectFieldWrapper} ref={ref} {...getReferenceProps()} tabIndex={0}>
            <div
              className={`${styles.selectField} ${active ? styles.selectFieldActive : ""} ${
                error ? "border-danger react-select" : ""
              } ${disabled ? styles.selectFieldDisabled : ""}`}
              ref={selectRef}
              onClick={(e) => {
                if (!disabled) {
                  setInputValue("");
                  setActive(true);
                }
              }}
              style={selectFieldStyle}
            >
              <div className={styles.selectFieldValues}>
                <>
                  {(Array.isArray(value) && !!value.length) || (!Array.isArray(value) && value && !inputValue) ? (
                    <>
                      {[...(Array.isArray(value) ? value : [value])].map((value, index) => (
                        <GenericVirtualizedSelectFormFieldOption
                          item={value}
                          onRemove={onRemove}
                          isMulti={isMulti}
                          disabled={disabled}
                          key={index}
                          onRowStartRenderer={onRowStartRenderer}
                          onRowEndRenderer={onRowEndRenderer}
                        />
                      ))}
                    </>
                  ) : !active ? (
                    <span className={`${styles.selectFieldValuesPlaceholder} ${styles.EllipsisSpan}`}>
                      {placeholder || "Select..."}
                    </span>
                  ) : (
                    <></>
                  )}
                  <>
                    {active && (
                      <div
                        className={styles.selectFieldInputContainer}
                        style={{
                          ...(!isMulti && { position: "absolute", left: 8 }),
                        }}
                      >
                        <input
                          key={uuidv4()}
                          ref={inputRef}
                          className={`${styles.selectFieldInput}`}
                          type="text"
                          autoCapitalize="none"
                          autoComplete="off"
                          autoCorrect="off"
                          spellCheck="false"
                          aria-autocomplete="list"
                          aria-expanded="false"
                          aria-haspopup="true"
                          role="combobox"
                          aria-controls="listbox"
                          aria-owns="listbox"
                          value={inputValue}
                          onChange={(e) => {
                            setInputValue(e.target.value);
                          }}
                          autoFocus={isInputFocused}
                          onKeyDown={(event) => {
                            event.stopPropagation();
                            if (allowCreateEntity) {
                              if (!inputValue) return;
                              switch (event.key) {
                                case "Enter":
                                  event.preventDefault();
                                  if (isUnique(inputValue as T)) {
                                    onCreate(inputValue as T);
                                    // onOpenChange(false);
                                    setInputValue("");
                                  }
                              }
                            }
                          }}
                        />
                      </div>
                    )}
                  </>
                </>
              </div>
              <div className={styles.selectFieldControls}>
                {!isEmpty(value) && (
                  <div
                    className={styles.selectFieldControlsClear}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      if (!disabled) {
                        onChange(null as T);
                      }
                    }}
                  >
                    <LucideIcon name="x" strokeWidth={"3"} />
                  </div>
                )}
                <span className={styles.selectFieldControlsSeparator} />
                <div
                  className={styles.selectFieldControlsChevron}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    if (!disabled) {
                      setOpen((prev) => !prev);
                      setActive((prev) => !prev);
                    }
                  }}
                >
                  <LucideIcon name={active ? "chevron-up" : "chevron-down"} strokeWidth={"3"} />
                </div>
              </div>
            </div>
          </div>
        )}
      >
        {({ setOpen }) => (
          <>
            {width && (
              <>
                {itemsFromOptions && resource ? (
                  <GenericVirtualizedSelectFormFieldOptionsFromValues
                    value={value}
                    resource={resource}
                    onItemClick={(item) => {
                      onItemClick(item);
                      if (!isMulti) {
                        setOpen(false);
                      }
                    }}
                    searchTerm={debouncedSearchValue}
                    onCreate={(item) => {
                      onCreate(item);
                      // if (!isMulti) {
                      //   setOpen(false);
                      // }
                      setInputValue("");
                    }}
                    onRowStartRenderer={onRowStartRenderer}
                    onRowEndRenderer={onRowEndRenderer}
                    allowCreateEntity={allowCreateEntity}
                    style={{ width: width }}
                  />
                ) : (
                  <GenericVirtualizedSelectFormFieldOptions
                    items={_items}
                    value={value}
                    onItemClick={(item) => {
                      onItemClick(item);
                      if (!isMulti) {
                        setOpen(false);
                      }
                    }}
                    searchTerm={debouncedSearchValue}
                    onCreate={(item) => {
                      onCreate(item);
                      // if (!isMulti) {
                      //   setOpen(false);
                      // }
                      setInputValue("");
                    }}
                    onRowStartRenderer={onRowStartRenderer}
                    onRowEndRenderer={onRowEndRenderer}
                    allowCreateEntity={allowCreateEntity}
                    concatItems={concatItems}
                    style={{ width: width }}
                  />
                )}
              </>
            )}
          </>
        )}
      </FloatingDiv>
    </>
  );
};

interface GenericVirtualizedSelectFormFieldOptionsProps<T extends GenericVirtualizedSelectFormFieldValueType> {
  value: T | T[] | undefined;
  items?: T[];
  onItemClick: (item: T) => void;
  searchTerm?: string;
  onCreate?: (item: T) => void;
  onRowStartRenderer?: (item: T) => React.ReactNode;
  onRowEndRenderer?: (item: T) => React.ReactNode;
  allowCreateEntity?: boolean;
  concatItems?: T[];
  style?: CSSProperties;
}
const GenericVirtualizedSelectFormFieldOptions = <T extends GenericVirtualizedSelectFormFieldValueType>({
  value,
  items,
  onItemClick,
  searchTerm,
  onCreate,
  onRowStartRenderer,
  onRowEndRenderer,
  allowCreateEntity,
  concatItems,
  style,
}: GenericVirtualizedSelectFormFieldOptionsProps<T>) => {
  const _items = useMemo(() => (concatItems ? [...concatItems, ...(items || [])] : items || []), [concatItems, items]);
  const pageSize = allowCreateEntity ? 9 : 10;
  // const hasSelected = useCallback((value: T | T[] | undefined, label: T) => {
  //   if (Array.isArray(value)) {
  //     return value.some((v) => v === label);
  //   } else if (value) {
  //     return value === label;
  //   } else {
  //     return false;
  //   }
  // }, []);

  const Item = ({ index, style }: { index: number; style: CSSProperties }) => {
    const item = _items[index];
    const isSelected = hasValue(value, convertToString(item));
    return (
      <div
        className={`${styles.selectFieldOptionsValuesValue} ${
          isSelected ? styles.selectFieldOptionsValuesValueSelected : ""
        }`}
        onClick={() => {
          onItemClick(item);
        }}
        style={{ ...style }}
        title={`${item}`}
        key={index}
        tabIndex={index}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            onItemClick(item);
          }
        }}
      >
        {onRowStartRenderer?.(item)}
        <span className={styles.selectFieldOptionsValuesValueLabel}>{convertToString(item)}</span>
        {onRowEndRenderer?.(item)}
      </div>
    );
  };

  // const isUnique = useCallback(() => {
  //   return !_items.some((item) => item?.toString() === searchTerm) && Array.isArray(value)
  //     ? !value.some((item) => item?.toString() === searchTerm)
  //     : value?.toString() !== searchTerm;
  // }, [_items, searchTerm, value]);

  const isUnique = useCallback(
    () => !hasValue(_items, convertToString(searchTerm)) && !hasValue(value, convertToString(searchTerm)),
    [_items, searchTerm, value]
  );

  return (
    <div className={styles.selectFieldOptions} style={style}>
      <div className={`${styles.selectFieldOptionsValues}`}>
        {/* <LoadingWrapper status={status} error={error}> */}
        {!!_items.length ? (
          <FixedSizeList
            itemCount={_items.length}
            width="100%"
            height={_items.length > pageSize ? pageSize * 30 : _items.length * 30}
            itemSize={30}
          >
            {Item}
          </FixedSizeList>
        ) : searchTerm && searchTerm.length <= 3 ? (
          <SearchTermError />
        ) : (
          <NoResults />
        )}
        {/* </LoadingWrapper> */}
        {allowCreateEntity && searchTerm && isUnique() && (
          <div
            className={`${styles.selectFieldOptionsValuesValueCreate}`}
            onClick={(e) => {
              e.preventDefault();
              onCreate?.(searchTerm as T);
            }}
          >
            <LucideIcon name="plus" /> Create "{searchTerm}"
          </div>
        )}
      </div>
    </div>
  );
};

interface GenericVirtualizedSelectFormFieldOptionsFromValuesProps<
  T extends GenericVirtualizedSelectFormFieldValueType
> {
  value: T | T[] | undefined;
  resource: ResourceName;
  onItemClick: (item: T) => void;
  searchTerm?: string;
  onCreate?: (item: T) => void;
  onRowStartRenderer?: (item: T) => React.ReactNode;
  onRowEndRenderer?: (item: T) => React.ReactNode;
  allowCreateEntity?: boolean;
  style?: CSSProperties;
}
const GenericVirtualizedSelectFormFieldOptionsFromValues = <T extends GenericVirtualizedSelectFormFieldValueType>({
  value,
  onItemClick,
  resource,
  searchTerm,
  onCreate,
  onRowStartRenderer,
  onRowEndRenderer,
  allowCreateEntity,
  style,
}: GenericVirtualizedSelectFormFieldOptionsFromValuesProps<T>) => {
  const [items, setItems] = useState<T[]>([]);
  const pageSize = allowCreateEntity ? 9 : 10;

  const { data, fetchNextPage, hasNextPage, isFetching, error, status, fetchStatus } = useInfiniteList<
    T,
    CustomFieldValuesFilters
  >(
    resource,
    {
      page: 1,
      pageSize: pageSize,
      includeCount: false,
      ...(searchTerm && { searchTerm: searchTerm }),
    },
    { enabled: true }
  );

  const fetchNext = useCallback(() => {
    if (hasNextPage) {
      fetchNextPage();
    }
  }, [fetchNextPage, hasNextPage]);

  const loadMoreCallback = useCallback(() => {}, []);
  const itemCount = hasNextPage ? items.length + 1 : items.length;
  const loadMoreItems = isFetching ? loadMoreCallback : fetchNext;
  const isItemLoaded = useCallback((index: number) => index < items.length, [items]);

  useEffect(() => {
    if (data) {
      setItems(data.pages.map((d) => d.results).flat());
    }
  }, [data]);

  // const hasSelected = useCallback((value: T | T[] | undefined, label: T) => {
  //   if (Array.isArray(value)) {
  //     return value.some((v) => v === label);
  //   } else if (value) {
  //     return value === label;
  //   } else {
  //     return false;
  //   }
  // }, []);

  const Item = ({ index, style }: { index: number; style: CSSProperties }) => {
    if (!isItemLoaded(index)) {
      return (
        <div style={{ ...style }} key={index}>
          <span className="skeleton-block" />
        </div>
      );
    }
    const item = items[index];
    const isSelected = hasValue(value, convertToString(item));
    return (
      <div
        className={`${styles.selectFieldOptionsValuesValue} ${
          isSelected ? styles.selectFieldOptionsValuesValueSelected : ""
        }`}
        onClick={() => {
          onItemClick(item);
        }}
        style={{ ...style }}
        title={`${item}`}
        key={index}
        tabIndex={index}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            onItemClick(item);
          }
        }}
      >
        {onRowStartRenderer?.(item)}
        <span className={styles.selectFieldOptionsValuesValueLabel}>{item}</span>
        {onRowEndRenderer?.(item)}
      </div>
    );
  };

  return (
    <div className={styles.selectFieldOptions} style={style}>
      <div className={`${styles.selectFieldOptionsValues}`}>
        {/* <LoadingWrapper status={status} error={error}> */}
        {!!items.length ? (
          <InfiniteLoader isItemLoaded={isItemLoaded} itemCount={itemCount} loadMoreItems={loadMoreItems as any}>
            {({ onItemsRendered, ref }) => (
              <FixedSizeList
                itemCount={itemCount}
                onItemsRendered={onItemsRendered}
                ref={ref}
                width="100%"
                height={items.length > pageSize ? pageSize * 30 : items.length * 30}
                itemSize={30}
              >
                {Item}
              </FixedSizeList>
            )}
          </InfiniteLoader>
        ) : error ? (
          <div style={{ padding: "10px 15px", width: "100%", height: "fit-content" }}>
            <LoadingWrapper status={status} fetchStatus={fetchStatus} error={error} children={undefined} />
          </div>
        ) : (
          // : searchTerm && searchTerm.length <= searchTermMinLength ? (
          //   <SearchTermError />
          // )
          <NoResults />
        )}
        {/* </LoadingWrapper> */}
        {allowCreateEntity &&
          searchTerm &&
          !items.length &&
          !hasValue(
            items.map((v) => v),
            searchTerm
          ) && (
            <div
              className={`${styles.selectFieldOptionsValuesValueCreate}`}
              onClick={() => onCreate?.(searchTerm as T)}
            >
              <LucideIcon name="plus" /> Create "{searchTerm}"
            </div>
          )}
      </div>
    </div>
  );
};

interface GenericVirtualizedSelectFormFieldOptionProps<T extends GenericVirtualizedSelectFormFieldValueType> {
  item: T;
  onRemove: (label: T) => void;
  isMulti?: boolean;
  disabled?: boolean;
  onRowStartRenderer?: (item: T) => React.ReactNode;
  onRowEndRenderer?: (item: T) => React.ReactNode;
}
const GenericVirtualizedSelectFormFieldOption = <T extends GenericVirtualizedSelectFormFieldValueType>({
  item,
  onRemove,
  isMulti,
  disabled,
  onRowStartRenderer,
  onRowEndRenderer,
}: GenericVirtualizedSelectFormFieldOptionProps<T>) => {
  if (isMulti) {
    return (
      <div className={styles.selectFieldOption}>
        <div
          className={`${styles.selectFieldOptionLabel} ${disabled ? styles.selectFieldOptionLabelDisabled : ""}`}
          title={`${convertToString(item)}`}
        >
          <div className={"ellipsisContainer"}>
            <span>
              {onRowStartRenderer?.(item)}
              {convertToString(item)}
              {onRowEndRenderer?.(item)}
            </span>
          </div>
        </div>
        {!disabled && (
          <div
            className={`${styles.selectFieldOptionControls}`}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              onRemove(item);
            }}
          >
            <LucideIcon name="x" strokeWidth={"3"} />
          </div>
        )}
      </div>
    );
  } else {
    return (
      <div className={styles.selectFieldOptionLabel} title={`${convertToString(item)}`}>
        <div className={"ellipsisContainer"}>
          <span>
            {onRowStartRenderer?.(item)}
            {convertToString(item)}
            {onRowEndRenderer?.(item)}
          </span>
        </div>
      </div>
    );
  }
};
