import React, { useCallback, useState } from "react";

/**
 * Custom hook that returns a callback ref and the corresponding node.
 * @template T - The type of the element.
 * @returns An object containing the ref, refObject and node.
 * @author CS
 */

export const useCallbackRef = <T extends Element>() => {
  const [node, setNode] = useState<T | null>(null);
  const ref = useCallback((node: T) => {
    if (node !== null) {
      setNode(node);
    }
  }, []);
  const refObject = { current: node } as React.RefObject<T>;

  return { ref, refObject, node };
};
