import * as React from "react";
import { ApiKeyManager } from "./ApiKeyManager";
import { useCallback, useState } from "react";
import { API } from "../api/Api";
import { useNonPaginatedListEntityOld } from "../api/BaseEntityApi";
import { LoadingWrapper } from "../common/LoadingWrapper";
import { ApiKey, apiKeysConstants } from "../api/ApiKey";
import { useEntityApi } from "../api/useEntityApi";
import { useEntityPermissions } from "../common/permissions/useEntityPermissions";
import { PageNotAllowed } from "../main/common/PageNotAllowed/PageNotAllowed";

interface ApiKeyDataLayerProps {
  api: API;
}

export const ApiKeyDataLayer = ({ api }: ApiKeyDataLayerProps) => {
  const [lastGeneratedApiKeyId, setLastGeneratedApiKeyId] = useState<number>();

  const { data: apiKeys, status, fetchStatus, error } = useNonPaginatedListEntityOld<ApiKey>("api_keys");
  const { deleteMutationAsync, createMutationAsync } = useEntityApi<ApiKey>(apiKeysConstants.resource);

  const generateApiKey = useCallback(
    async (name: string, readOnly: boolean) => {
      await createMutationAsync(
        { body: { name: name, readOnly: readOnly } as ApiKey },
        {
          onSuccess: (response) => {
            setLastGeneratedApiKeyId(response.id);
          },
        }
      ).catch((e) => {});
    },
    [createMutationAsync]
  );

  const deleteApiKey = useCallback(
    async (id: ApiKey["id"]) => {
      await deleteMutationAsync({ id: id }).catch((e) => {});
    },
    [deleteMutationAsync]
  );

  const { canReadIndex } = useEntityPermissions({ entityTypeId: "apiKeys" });
  if (!canReadIndex) return <PageNotAllowed />;
  return (
    <LoadingWrapper status={status} fetchStatus={fetchStatus} error={error}>
      {apiKeys && (
        <ApiKeyManager
          apiKeys={apiKeys}
          generateApiKey={generateApiKey}
          deleteApiKey={deleteApiKey}
          lastGeneratedApiKeyId={lastGeneratedApiKeyId}
        />
      )}
    </LoadingWrapper>
  );
};
