import React from "react";
import {
  Control,
  FieldValues,
  Path,
  PathValue,
  UseFormGetValues,
  UseFormReturn,
  useFormContext,
  useWatch,
} from "react-hook-form";
import styles from "./MultiEditStyles.module.css";
import { LucideIcon } from "../../../icon/LucideIcon";
import {
  GenericEntity,
  ICustomTypedEntity,
  IEntityMinimalModel,
  StringIndexedDict,
} from "../../../../api/GenericTypes";
import { MultiEditMappedItemObject, useMultiEditFormValues } from "./MultiEditProvider";
import { ConsolidatedValues, mergeValues } from "./MultiEditUtils";
import { CustomType } from "../../../../api/CustomTypes";
interface MultiEditFormWrapperProps<Entity extends StringIndexedDict> {
  children: (props: UseFormReturn<Entity, any, Entity>) => React.ReactNode;
}
export const MultiEditFormWrapper = <Entity extends StringIndexedDict>({
  children,
}: MultiEditFormWrapperProps<Entity>) => {
  const props = useFormContext<Entity>();
  return <React.Fragment>{children(props)}</React.Fragment>;
};

interface MultiEditHeaderWrapperProps<Entity extends GenericEntity & Partial<ICustomTypedEntity>> {
  control: Control<Entity>;
  getValues: UseFormGetValues<Entity>;
  property: string & keyof Entity;
  ids: Set<Entity["id"]>;
  label: string | React.ReactNode;
  additionalControls?: React.ReactNode;
  hasApply?: boolean;
  hasAppend?: boolean;
  disabled?: (consolidatedValues: Required<ConsolidatedValues<Entity>>) => boolean;
  required?: boolean;
  customTypeIds?: CustomType["id"][];
  children: (
    consolidatedValues: Required<ConsolidatedValues<Entity>>,
    consolidatedCustomFieldValues?: Required<ConsolidatedValues<Entity>>,
    customFieldPlaceholders?: StringIndexedDict<string | undefined>
  ) => React.ReactNode;
}
export const MultiEditHeaderWrapper = <Entity extends GenericEntity & Partial<ICustomTypedEntity>>({
  control,
  getValues,
  property,
  ids,
  label,
  additionalControls,
  hasApply,
  hasAppend,
  disabled,
  required,
  customTypeIds,
  children,
}: MultiEditHeaderWrapperProps<Entity>) => {
  const entity = useWatch({ name: property as Path<Entity>, control: control });
  const context = useMultiEditFormValues<Entity>();

  return (
    <MultiEditFormWrapper>
      {(formProps) => (
        <div className={` form-group ${required ? "required" : ""} ${styles.MultiEditHeaderWrapperContainer}`}>
          <label className="control-label required" style={{ margin: 0 }}>
            {label}
          </label>
          {additionalControls}
          <div className={styles.MultiEditHeaderWrapperContainerContent}>
            <div className={styles.MultiEditHeaderWrapperContainerChildren}>
              {children(
                context.consolidatedValues,
                context.consolidatedCustomFieldValues,
                context.customFieldPlaceholders
              )}
            </div>
            <div className={styles.MultiEditHeaderWrapperContainerControls}>
              {hasApply && (
                <button
                  className="btn btn-sm btn-default"
                  title="Apply to selected (overwrite)"
                  disabled={disabled?.(context.consolidatedValues) || !ids.size}
                  onClick={() => {
                    const _ids = Array.from(ids).map((id) => id.toString());
                    let currentValue = getValues(property as Path<Entity>) as any;
                    if (
                      (currentValue && Object.hasOwn(currentValue, "id") && currentValue.id === -2) ||
                      (Array.isArray(currentValue) && currentValue.some((v) => v.id === -2))
                    )
                      currentValue = null as PathValue<FieldValues, string & keyof Entity>;
                    const allValues = formProps.getValues() as MultiEditMappedItemObject<Entity>;

                    _ids.forEach((id) => {
                      if (customTypeIds) {
                        if (allValues[id].customType && customTypeIds.includes(allValues[id].customType!.id)) {
                          if (!Object.hasOwn(allValues[id], "customFields")) allValues[id]["customFields"] = {};
                          const _property = property.replace("customFields.", "");
                          // console.log("_property", _property);
                          allValues[id].customFields![+_property] = currentValue;
                        }
                      } else {
                        allValues[id][property] = currentValue;
                      }
                    });

                    formProps.reset(allValues, { keepDefaultValues: true, keepDirty: true });
                  }}
                >
                  <LucideIcon name="replace-all" /> Replace
                </button>
              )}
              {hasAppend && (
                <button
                  className="btn btn-sm btn-default"
                  title="Append to selected"
                  disabled={
                    disabled?.(context.consolidatedValues) ||
                    !ids.size ||
                    !entity ||
                    (entity as any)?.id === -2 ||
                    (Array.isArray(entity) && entity.some((v: IEntityMinimalModel) => v.id === -2))
                  }
                  // disabled={!ids.size}
                  onClick={() => {
                    const _ids = Array.from(ids).map((id) => id.toString());
                    const currentValue = getValues(property as Path<Entity>);
                    const allValues = formProps.getValues() as MultiEditMappedItemObject<Entity>;
                    _ids.forEach((id) => {
                      allValues[id][property] = mergeValues(allValues[id][property], currentValue);
                    });

                    _ids.forEach((id) => {
                      if (customTypeIds) {
                        if (allValues[id].customType && customTypeIds.includes(allValues[id].customType!.id)) {
                          if (!Object.hasOwn(allValues[id], "customFields")) allValues[id]["customFields"] = {};
                          const _property = property.replace("customFields.", "");

                          allValues[id].customFields![+_property] = mergeValues(
                            allValues[id].customFields![+_property],
                            currentValue
                          );
                        }
                      } else {
                        allValues[id][property] = mergeValues(allValues[id][property], currentValue);
                      }
                    });

                    formProps.reset(allValues, { keepDefaultValues: true, keepDirty: true });
                  }}
                >
                  <LucideIcon name="combine" /> Combine
                  {/* <div style={{ position: "relative" }}>
                    <LucideIcon name="blocks" />
                    <div style={{ position: "absolute", top: 0, right: 0, transform: "translate(5px, -10px)" }}>
                      <LucideIcon name="plus" style={{ width: "7px", height: "7px" }} />
                    </div>
                  </div> */}
                </button>
              )}
            </div>
          </div>
        </div>
      )}
    </MultiEditFormWrapper>
  );
};
