import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import * as d3 from "d3";
import { d3DataType, SelectedState } from "../Dashboard";
import styles from "./Stats.module.css";

type Data = PieData[];
interface PieData {
  name: string;
  datasets: number;
}

// Debounce Hook
function useDebounce<T>(value: T, delay: number): T {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState<T>(value);
  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);
      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay] // Only re-call effect if value or delay changes
  );
  return debouncedValue;
}

// set the dimensions and margins of the graph
const dimension = { width: 960, height: 500 };
const margin = { top: 20, right: 150, bottom: 100, left: 30 };
const width = dimension.width - margin.left - margin.right;
const height = dimension.height - margin.top - margin.bottom;
// const thres = 2 * Math.PI / 36 // Threshold to trigger nonlinear function to spread labels
// const cosfac = 0.4 // Factor for nonlinear cosine
const min_thres = (10 * Math.PI) / 180; // 10 deg Threshold to disable plotting entirely
// Some animation handlers
const pieTween = (datum: any, idx: any, nodes: any) => {
  nodes[idx]._current = nodes[idx]._current || datum;
  const interpolate = d3.interpolate(nodes[idx]._current, datum);
  nodes[idx]._current = interpolate(0);
  return (t: any) => {
    return arc(interpolate(t));
  };
};

const midAngle = (d: any) => {
  return d.startAngle + (d.endAngle - d.startAngle) / 2;
};

const textTween = (datum: any, idx: any, nodes: any) => {
  nodes[idx]._current = nodes[idx]._current || datum;
  const interpolate = d3.interpolate(nodes[idx]._current, datum);
  nodes[idx]._current = interpolate(0);
  return (t: any) => {
    const d2 = interpolate(t);
    const pos = outerArc.centroid(d2);
    // const dA = d2.endAngle - d2.startAngle;
    // const midA = midAngle(d2);
    // if (dA < thres) {
    //     pos[0] = pos[0] + pos[0] * cosfac * Math.cos(8 * midA) ** 2
    //     pos[1] = pos[1] + pos[1] * cosfac * Math.cos(8 * midA) ** 2
    // }

    pos[0] = radius * (midAngle(d2) < Math.PI ? 1 : -1);
    return "translate(" + pos + ")";
  };
};

const textstyleTween = (datum: any, idx: any, nodes: any) => {
  nodes[idx]._current = nodes[idx]._current || datum;
  const interpolate = d3.interpolate(nodes[idx]._current, datum);
  nodes[idx]._current = interpolate(0);
  return (t: any) => {
    const d2 = interpolate(t);
    return midAngle(d2) < Math.PI ? "start" : "end";
  };
};

const lineTween = (datum: any, idx: any, nodes: any) => {
  nodes[idx]._current = nodes[idx]._current || datum;
  const interpolate = d3.interpolate(nodes[idx]._current, datum);
  nodes[idx]._current = interpolate(0);
  return (t: any) => {
    const d2 = interpolate(t);
    const posA = arc.centroid(d2);
    const posB = outerArc.centroid(d2);
    const posC = outerArc.centroid(d2);
    // Translate coordinates to ellipsis
    // const dA = d2.endAngle - d2.startAngle;
    const midA = midAngle(d2);
    // if (dA < thres) {
    //     posB[0] = posB[0] + posB[0] * cosfac * Math.cos(8 * midA) ** 2
    //     posB[1] = posB[1] + posB[1] * cosfac * Math.cos(8 * midA) ** 2
    //     posC[0] = posC[0] + posC[0] * cosfac * Math.cos(8 * midA) ** 2
    //     posC[1] = posC[1] + posC[1] * cosfac * Math.cos(8 * midA) ** 2
    // }

    posC[0] = radius * 0.95 * (midA < Math.PI ? 1 : -1);
    return [posA, posB, posC];
  };
};

const radius = Math.min(width, height) / 2;

const arc = d3
  .arc()
  .outerRadius(radius * 0.8)
  .innerRadius(radius * 0.4);

const outerArc = d3
  .arc()
  .innerRadius(radius * 0.9)
  .outerRadius(radius * 0.9);

const pie = d3
  .pie<PieData>()
  .padAngle(0.005)
  .value((d: any) => d.datasets);
// .sort(null)

const count = (array: any[], key: string | number) => {
  return array.reduce((r, a) => r + a[key], 0);
};

// const datasets_div = (datasets: number) => {
//     const string = '<div class="total-datasets"><div class="total-num">' + datasets.toString() + '</div><div class="total-desc">Datasets</div></div>'
//     console.log("STRING", string)
//     return string
// }
interface Props {
  d3Data: d3DataType;
  selection: SelectedState;
  toggleSelection: Function;
}

export const PieChart = ({ d3Data, selection, toggleSelection }: Props) => {
  const ref = useRef<HTMLDivElement>(null);
  const [data, setData] = useState<Data>([]);
  const debounceddata = useDebounce(data, 100);
  // const [color, setColor] = useState<d3.ScaleOrdinal<string, unknown>>();
  // const [selection, setSelection] = useState(d3Data.selection)

  // Render the axis initially
  const render = useCallback(() => {
    d3.select(ref.current).selectAll("*").remove();
    const svg = d3
      .select(ref.current)
      .append("div")
      .classed("svg-container", true)
      .append("svg")
      // .attr("width", "100%")
      // .attr("height", "100%")
      // .attr("viewBox", "0 0 " + (width) + " " + (height))
      .attr("preserveAspectRatio", "xMinYMin meet")
      .attr("viewBox", "0 0 " + (width + margin.left + margin.right) + " " + (height + margin.top + margin.bottom))
      .classed("svg-content-responsive", true)
      .append("g")
      .attr("id", "main");

    svg
      .append("g")
      // .attr("class", "slices")
      .classed(styles.slices, true);
    svg
      .append("g")
      // .attr("class", "labels")
      .classed(styles.pielabels, true);
    svg
      .append("g")
      // .attr("class", "lines")
      .classed(styles.polylines, true);
    svg.append("g").attr("class", "texts");

    svg.attr(
      "transform",
      "translate(" + (width + margin.left + margin.right) / 2 + "," + (height + margin.top + margin.bottom) / 2 + ")"
    );
    // Add a Tooltip
    d3.select(ref.current).append("div").style("opacity", 0).attr("class", styles.chart_tooltip).style("z-index", "10");
  }, []);

  // Update the data state if props change
  useMemo(() => {
    render();
    if (d3Data) {
      // if (d3Data.color) setColor(d3Data.color);
      const data = d3Data.data;
      let this_data: Data = [];
      const methods = data.map((d) => d.subgroups);
      const subgroups = Array.from(new Set(data.map((d) => Object.keys(d.subgroups)).flat(1)));
      subgroups.forEach((key: string) => {
        const datasets = count(methods, key);
        if (datasets > 0) {
          this_data.push({ name: key, datasets: datasets });
        }
      });
      this_data ? setData(() => this_data) : setData(() => []);
    }
  }, [d3Data, render]);

  // Update the plot if data changes
  useMemo(() => {
    if (debounceddata) {
      const selections = Object.keys(d3Data.selection).filter((d) => d3Data.selection[d] === true);
      const totalSize = debounceddata.map((d: any) => d.datasets).reduce((a: number, b: number) => a + b, 0);
      let percentageString = "";
      // Add mouseover handles
      const mouseover = (event: any, d: any) => {
        const active_targets = Object.keys(d3Data.selection).filter((d) => d3Data.selection[d] === true);
        if (!(Array.isArray(active_targets) && active_targets.length)) {
          const current_target: any = d3.select(d3.event.currentTarget).datum();
          if (current_target) {
            const subgroupName = current_target.data.name;
            const percentage = current_target.percentage / 100;
            if (+percentage < 0.001) {
              percentageString = "< 0.1%";
            } else {
              percentageString = d3.format(".1%")(percentage);
            }
            // Slice opacity
            d3.selectAll(".subgroupElement").style("opacity", 0.2);
            d3.selectAll(".subgroupElement." + d3Data.subgroupkeys[subgroupName]).style("opacity", 1);
            d3.selectAll("." + styles.percentage_text).text(percentageString);
          }
        }
      };

      const click = (event: any, d: any) => {
        const current_target: any = d3.select(d3.event.currentTarget).datum();
        if (current_target) {
          d3Data.selection[current_target.data.name] = !d3Data.selection[current_target.data.name];
          toggleSelection(current_target.data.name);
          const active_targets = Object.keys(d3Data.selection).filter((d) => d3Data.selection[d] === true);
          // const parentData = d3.selectAll(".subgroupElement").data().filter(d => typeof d === "object" && d !== null && !Array.isArray(d))
          // if (parentData) {
          //     const percentages = parentData.filter((d: any) => d.hasOwnProperty("data") && active_targets.includes(d.data.name)).map((d: any) => d.percentage / 100).reduce((pv, cv) => pv + cv, 0)
          //     if (percentages && typeof percentages === "number") {
          //         if (+percentages < 0.001) {
          //             percentageString = "< 0.1%";
          //         } else {
          //             percentageString = d3.format(".1%")(percentages)
          //         }

          //     }
          // }
          if (Array.isArray(active_targets) && active_targets.length) {
            d3.selectAll(".subgroupElement").style("opacity", 0.2);
            active_targets.map((d: string) =>
              d3.selectAll(".subgroupElement." + d3Data.subgroupkeys[d]).style("opacity", 1)
            );
          } else {
            d3.selectAll(".subgroupElement").style("opacity", 1);
            percentageString = "";
          }
          d3.selectAll("." + styles.percentage_text).text(percentageString);
        }
      };
      // const mousemove = (event: any, d: any) => {
      //     console.log("PIE Mouse move")
      //     tooltip.style("transform", "translateY(-55%)")
      //         .style("left", (event.x) / 2 + "px")
      //         .style("top", (event.y) / 2 - 30 + "px")
      // }
      const mouseleave = (event: any, d: any) => {
        const active_targets = Object.keys(d3Data.selection).filter((d) => d3Data.selection[d] === true);
        if (!(Array.isArray(active_targets) && active_targets.length)) {
          // Slice opacity
          d3.selectAll(".subgroupElement").style("opacity", 1);
        }
        percentageString = "";
        d3.selectAll("." + styles.percentage_text).text(percentageString);
      };

      const enterSlice = (enter: any) => {
        enter
          // .filter((d: any) => (d.endAngle - d.startAngle) > min_thres)
          .append("path")
          .each((d: any) => {
            d.percentage = (d.value * 100) / totalSize;
          })
          .style("fill", (d: any) => d3Data.color[d.data.name])
          .style("opacity", (d: any) => {
            if (Array.isArray(selections) && selections.length) {
              if (d3Data.selection[d.data.name]) {
                return 1;
              } else {
                return 0.2;
              }
            } else {
              return 1;
            }
          })
          .attr("class", (d: any) => "subgroupElement " + d3Data.subgroupkeys[d.data.name])
          .attr("stroke-width", "2px")
          .on("mouseover", mouseover)
          .on("click", click)
          .on("mouseleave", mouseleave)
          .style("cursor", "pointer")
          .call((g: any) =>
            g
              .transition()
              .duration(300)
              .ease(d3.easeQuad)
              .attrTween("d", pieTween as any)
          );
      };
      const updateSlice = (update: any) => {
        update
          // .filter((d: any) => (d.endAngle - d.startAngle) > min_thres)
          .each((d: any) => {
            d.percentage = (d.value * 100) / totalSize;
          })
          .call((g: any) =>
            g
              .transition()
              .duration(300)
              .ease(d3.easeQuad)
              .attrTween("d", pieTween as any)
              .attr("class", (d: any) => "subgroupElement " + d3Data.subgroupkeys[d.data.name])
              .style("fill", (d: any, i: number) => d3Data.color[d.data.name])
              .style("opacity", (d: any) => {
                if (Array.isArray(selections) && selections.length) {
                  if (d3Data.selection[d.data.name]) {
                    return 1;
                  } else {
                    return 0.2;
                  }
                } else {
                  return 1;
                }
              })
          );
      };
      const exitSlice = (exit: any) => {
        exit.call((g: any) => g.transition().duration(300).remove());
      };

      const piedata = pie(debounceddata);

      // Pie slices
      d3.select("#main")
        // .select(".slices")
        .select("." + styles.slices)
        .selectAll(".subgroupElement")
        .data(piedata)
        .join(
          (enter) => enterSlice(enter) as any,
          (update) => updateSlice(update) as any,
          (exit) => exitSlice(exit)
        );

      // Labels
      d3.select("#main")
        // .select(".labels")
        .select("." + styles.pielabels)
        .selectAll("text")
        .data(piedata)
        .join(
          (enter) =>
            enter
              .append("text")
              .attr("dy", ".35em")
              // .attr("opacity", d => {
              //     if (d.endAngle - d.startAngle > min_thres) {
              //         return 1
              //     } else {
              //         return 0
              //     }
              // })
              .text((d: any) => (d.endAngle - d.startAngle > min_thres ? d.data.name : ""))
              .attr("class", (d: any) => "subgroupElement " + d3Data.subgroupkeys[d.data.name])
              .style("opacity", (d) => {
                const active_selection = Object.keys(d3Data.selection).filter((d) => d3Data.selection[d] === true);
                if (Array.isArray(active_selection) && active_selection.length) {
                  if (active_selection.includes(d.data.name)) {
                    return 1;
                  } else {
                    return 0.2;
                  }
                } else {
                  if (d.endAngle - d.startAngle > min_thres) {
                    return 1;
                  } else {
                    return 0;
                  }
                }
              })
              .call((enter) =>
                enter
                  .transition()
                  .duration(300)
                  .attrTween("transform", textTween)
                  .styleTween("text-anchor", textstyleTween)
              ),
          (update) =>
            update
              .attr("dy", ".35em")
              .attr("opacity", (d) => {
                if (d.endAngle - d.startAngle > min_thres) {
                  return 1;
                } else {
                  return 0;
                }
              })
              .text((d: any) => (d.endAngle - d.startAngle > min_thres ? d.data.name : ""))
              .attr("class", (d: any) => "subgroupElement " + d3Data.subgroupkeys[d.data.name])
              .style("opacity", (d) => {
                const active_selection = Object.keys(d3Data.selection).filter((d) => d3Data.selection[d] === true);
                if (Array.isArray(active_selection) && active_selection.length) {
                  if (active_selection.includes(d.data.name)) {
                    return 1;
                  } else {
                    return 0.2;
                  }
                } else {
                  if (d.endAngle - d.startAngle > min_thres) {
                    return 1;
                  } else {
                    return 0;
                  }
                }
              })
              .call((enter) =>
                enter
                  .transition()
                  .duration(300)
                  .attrTween("transform", textTween)
                  .styleTween("text-anchor", textstyleTween)
              ),
          (exit) => exit.transition().duration(300).remove()
          // exitSlice(exit)
        );
      // Polylines
      d3.select("#main")
        // .select(".lines")
        .select("." + styles.polylines)
        .selectAll("polyline")
        .data(pie(debounceddata))
        .join(
          (enter) =>
            enter
              .append("polyline")
              .attr("stroke-width", (d) => {
                if (d.endAngle - d.startAngle > min_thres) {
                  return "2px";
                } else {
                  return "0px";
                }
              })
              .attr("stroke", "black")
              .attr("class", (d: any) => "subgroupElement " + d3Data.subgroupkeys[d.data.name])
              .style("opacity", (d) => {
                const active_selection = Object.keys(d3Data.selection).filter((d) => d3Data.selection[d] === true);
                if (Array.isArray(active_selection) && active_selection.length) {
                  if (active_selection.includes(d.data.name)) {
                    return 1;
                  } else {
                    return 0.2;
                  }
                } else {
                  return 1;
                }
              })
              // .attr("stroke-width", "2px")
              .attr("fill", "none")
              .call((enter) =>
                enter
                  .transition()
                  .duration(300)
                  .attrTween("points", lineTween as any)
              ),
          (update) =>
            update
              .attr("stroke-width", (d) => {
                if (d.endAngle - d.startAngle > min_thres) {
                  return "2px";
                } else {
                  return "0px";
                }
              })
              .attr("stroke", "black")
              .attr("class", (d: any) => "subgroupElement " + d3Data.subgroupkeys[d.data.name])
              .style("opacity", (d) => {
                const active_selection = Object.keys(d3Data.selection).filter((d) => d3Data.selection[d] === true);
                if (Array.isArray(active_selection) && active_selection.length) {
                  if (active_selection.includes(d.data.name)) {
                    return 1;
                  } else {
                    return 0.2;
                  }
                } else {
                  return 1;
                }
              })
              // .attr("stroke-width", "2px")
              .attr("fill", "none")
              .call((enter) =>
                enter
                  .transition()
                  .duration(300)
                  .attrTween("points", lineTween as any)
              ),
          (exit) => exit.transition().duration(300).remove()
        );

      // // Dataset count text
      // const eval_percentage = (data: any) => {
      //     const parentData = data.data()
      //     if (Array.isArray(parentData) && parentData.length) {
      //         const percentage = parentData.map((d: any) => d.value * 100 / totalSize).reduce((pv: any, cv: any) => pv + cv, 0).toPrecision(2)
      //         if (percentage) percentageString = percentage + "%"
      //     }
      //     d3.selectAll("." + styles.percentage_text).text(percentageString)
      // }

      d3.selectAll("." + styles.percentage_text).remove();
      d3.select("#main")
        .append("text")
        .classed(styles.percentage_text, true)
        .attr("text-anchor", "middle")
        .attr("dy", "0.35em")
        .text(percentageString);
      if (!(Array.isArray(piedata) && piedata.length)) {
        d3.select("#main")
          .append("text")
          .classed(styles.percentage_text, true)
          .attr("text-anchor", "middle")
          .attr("dy", "0.35em")
          .text("No data in selected range");
      }

      // BELOW PART IS BUGGY FOR NOW

      // // Dataset count text
      // const eval_percentage = (data: any) => {
      //     if (Array.isArray(selections) && selections.length) {
      //         // const parentData = data.data()
      //         const parentData = d3.selectAll("." + styles.percentage_text).data().filter(d => typeof d === "object" && d !== null && !Array.isArray(d))
      //         console.log("PARENT DATA", parentData)
      //         if (Array.isArray(parentData) && parentData.length) {
      //             const filtered = parentData.filter(d => selections.includes(d.data.name))
      //             console.log("filtered", filtered)
      //             const percentage = filtered ? filtered.map((d: any) => d.value * 100 / totalSize).reduce((pv: any, cv: any) => pv + cv, 0).toPrecision(2) : ""

      //             if (percentage) {
      //                 percentageString = percentage + "%"
      //                 console.log(percentageString)
      //             }
      //         }
      //     }
      //     d3.selectAll("." + styles.percentage_text).text(percentageString)
      // }

      // d3.select("#main")
      //     .selectAll('.' + styles.percentage_text)
      //     .data(piedata)
      //     .join((enter) =>
      //         enter
      //             .filter((d: any) => (Array.isArray(selections) && selections.length) ? selections.includes(d.data.name) : d)
      //             .append("text")
      //             .classed(styles.percentage_text, true)
      //             .attr("text-anchor", "middle")
      //             .attr('dy', '0.35em')
      //             .text("$" + percentageString)
      //         ,
      //         (update) =>
      //             update
      //                 .filter((d: any) => (Array.isArray(selections) && selections.length) ? selections.includes(d.data.name) : d)
      //                 .text(percentageString)
      //         ,
      //         (exit) =>
      //             exit.remove()
      //     )

      // d3.select("#main")
      //     .select(".texts")
      //     .selectAll("." + styles.percentage_text)
      //     .data(piedata)
      //     .join(
      //         enter => enter
      //             // .filter((d: any) => selections.includes(d.data.name) && d)
      //             // .filter((d: any) => (Array.isArray(selections) && selections.length) ? selections.includes(d.data.name) : d)
      //             // .each((d: any) => {
      //             //     d.percentage = d.value * 100 / totalSize
      //             // })

      //             .append("svg:text")
      //             .classed(styles.percentage_text, true)
      //             // .attr("class", "text")
      //             .attr("text-anchor", "middle")
      //             .attr('dy', '0.35em')
      //             // .attr("font-size", "40px")
      //             .attr("font-weight", "bold")
      //             .call(eval_percentage)
      //             .text(percentageString)
      //         ,
      //         update => (
      //             update
      //                 .filter((d: any) => (Array.isArray(selections) && selections.length) ? selections.includes(d.data.name) : d)
      //                 .call((d) => eval_percentage(d))
      //                 .text(percentageString)
      //         )
      //         ,
      //         exit => exit.remove()
      //     )

      d3.select(ref.current).on("dblclick", (d) => {
        d3.selectAll(".subgroupElement").style("opacity", 1);
        Object.keys(d3Data.selection).forEach((d) => (d3Data.selection[d] = false));
        toggleSelection(null, d3Data.selection);
        d3.selectAll(".subgroupElement").transition().duration(300).style("opacity", 1);
        d3.selectAll("." + styles.percentage_text).text("");
      });
    }
  }, [d3Data, debounceddata, toggleSelection]);

  return <div className={styles.piestats} ref={ref} />;
};
