import { StringIndexedDict } from "../../../../api/GenericTypes";
import {
  GenericEntityVirtualizedEntitySelectFormFieldProps,
  GenericVirtualizedEntitySelectFormFieldControllerWrapper,
} from "../../../formfields/GenericVirtualizedSelectFormField/GenericVirtualizedEntitySelectFormFieldControllerWrapper";
// import { QuickAddWrapper } from "../../QuickAdd/common/QuickAddWrapper";
import {
  LabNotebook,
  LabNotebookFilters,
  LabNotebookSortingOptionsConsts,
  LabNotebookSuggestions,
  labNoteBooksConstants,
} from "../../../../ELN/types/LabNotebook";
import { useContext } from "react";
import { SessionContext } from "../../../contexts/SessionContext";
import { QuickAddWrapper } from "../../QuickAdd/common/QuickAddWrapper";
import { QuickAddLabNotebookForm } from "../../QuickAdd/forms/QuickAddLabNotebook";

export const LabNotebooksVirtualizedSelectForm = <Entity extends StringIndexedDict>(
  props: GenericEntityVirtualizedEntitySelectFormFieldProps<Entity, LabNotebookSuggestions, LabNotebookFilters>
) => {
  const { session } = useContext(SessionContext);
  return (
    <GenericVirtualizedEntitySelectFormFieldControllerWrapper<Entity, LabNotebookSuggestions, LabNotebookFilters>
      {...props}
      filters={{ orderBy: "MODIFIED_ON_DESC", ...props.filters }}
      //   onRowRenderer={(item) => <></>}
      entityConstants={labNoteBooksConstants}
      orderByOptions={LabNotebookSortingOptionsConsts}
      allowCreateEntity={session?.permissions.can_create_eln_content && props.allowCreateEntity}
      createModal={({ showModal, setShowModal, onCreate }) => (
        <QuickAddWrapper<LabNotebook> showModal={showModal} setShowModal={setShowModal} onCreate={onCreate}>
          {(props) => <QuickAddLabNotebookForm {...props} initialValues={{ status: "ACTIVE" }} />}
        </QuickAddWrapper>
      )}
    />
  );
};
