import React, { useCallback, useEffect, useState } from "react";
import { FieldArrayWithId, FieldErrors, useFieldArray, useForm } from "react-hook-form";
import { CustomTypeSectionWriteModel, CustomTypeWriteModel } from "../../api/CustomTypes";
import { ToggleFormField } from "../../common/formfields/ToggleFormField";
import { OverlayInfo } from "../../common/misc/OverlayInfo/OverlayInfo";
import { useDynamicHeight } from "./hooks/useDynamicHeight";
import * as yup from "yup";
import styles from "./CustomTypeBuilder.module.css";
import { GridLayoutElementWrapper, NestedErrorContainer } from "./CustomTypeRenderUtils";
import { yupResolver } from "@hookform/resolvers/yup";
import { useResizeDetector } from "react-resize-detector";
import GridLayout from "react-grid-layout";
import { LucideIcon } from "../../common/icon/LucideIcon";
import { CustomFieldFormCreateModal, useCustomFieldFormSchema } from "../CustomFields/CustomFieldsForm";
import { customFieldToCustomFieldForm } from "../CustomFields/CustomFieldUtils";
import { CustomField } from "../../api/CustomFields";
import { Nullable } from "../../api/GenericTypes";
import { FormFieldError } from "../../common/formfields/FormFieldLayouts";
import { RenderCustomTypeSectionCustomField } from "./CustomTypeDetail";

export const useCustomSectionFormSchema = ({
  disableCustomFieldNameCheck,
}: {
  disableCustomFieldNameCheck?: boolean;
}) => {
  const CustomFieldFormSchema = useCustomFieldFormSchema({ disableNameCheck: disableCustomFieldNameCheck });

  const CustomSectionFormSchema = yup.object().shape({
    name: yup.string().required("A name is required").typeError("A name is required"),
    customFields: yup
      .array()
      .of(CustomFieldFormSchema)
      .min(1, "At least one custom field is required")
      .typeError("At least one custom field is required"),
  });
  return CustomSectionFormSchema;
};

interface CustomTypeSectionBuilderProps {
  elementId: string;
  defaultValues?: FieldArrayWithId<CustomTypeWriteModel, "sections", "elementId">;
  updateSection: (elementId: string, data: Partial<Nullable<CustomTypeSectionWriteModel>>) => Promise<void>;
  removeSection: (elementId: string) => void;
  nodeHeightCallback: (uuid: string, height: number) => void;
  handleSetSectionValidationStatus: (elementId: string, isValid: boolean) => void;
  disabled?: boolean;
  disabledRemove?: boolean;
  errorsExternal?: FieldErrors<FieldArrayWithId<CustomTypeWriteModel, "sections", "elementId">>;
}

interface LayoutState {
  [elementId: string]: {
    field: FieldArrayWithId<
      FieldArrayWithId<CustomTypeWriteModel, "sections", "elementId">,
      "customFields",
      "elementId"
    >;
    layout: GridLayout.Layout;
  };
}

export const CustomTypeSectionBuilder = ({
  elementId,
  defaultValues,
  updateSection,
  removeSection,
  nodeHeightCallback,
  handleSetSectionValidationStatus,
  disabled,
  disabledRemove,
  errorsExternal,
}: CustomTypeSectionBuilderProps) => {
  const { ref } = useDynamicHeight({
    uuid: elementId,
    nodeHeightCallback: nodeHeightCallback,
    gridRowHeight: 1,
  });

  const { ref: internalRef, width } = useResizeDetector();
  const CustomSectionFormSchema = useCustomSectionFormSchema({ disableCustomFieldNameCheck: true });
  const {
    reset,
    register,
    control,
    handleSubmit,
    getValues,
    formState: { errors: _errors, isDirty },
  } = useForm<FieldArrayWithId<CustomTypeWriteModel, "sections", "elementId">>({
    values: defaultValues,
    resolver: yupResolver(CustomSectionFormSchema),
  });
  const errors = { ..._errors, ...errorsExternal };

  const { fields, insert, replace, remove } = useFieldArray({
    control,
    name: "customFields",
    keyName: "elementId",
  });

  const [layout, setLayout] = useState<LayoutState>(
    Object.fromEntries(
      Object.entries(fields).map(([k, v]) => [
        v.elementId,
        { field: v, layout: { i: v.elementId, x: 0, y: 0, w: 1, h: 6 } },
      ])
    )
  );

  useEffect(() => {
    setLayout((prev) =>
      Object.fromEntries(
        Object.entries(fields).map(([k, v]) => [
          v.elementId,
          { field: v, layout: { i: v.elementId, x: 0, y: 0, w: 1, h: prev?.[v.elementId]?.layout?.h || 6 } },
        ])
      )
    );
  }, [fields]);

  const removeCustomField = useCallback(
    async (elementId: string) => {
      const index = fields.findIndex((f) => f.elementId === elementId);
      remove(index);
    },
    [fields, remove]
  );
  // const updateCustomField = useCallback(
  //   (
  //     elementId: string,
  //     data: FieldArrayWithId<
  //       FieldArrayWithId<CustomTypeWriteModel, "sections", "elementId">,
  //       "customFields",
  //       "elementId"
  //     >
  //   ) => {
  //     const index = fields.findIndex((f) => f.elementId === elementId);
  //     update(index, data);
  //   },
  //   [fields, update]
  // );
  const isValid = !errors || Object.keys(errors).length === 0;

  const isChecked = isValid && !isDirty;

  useEffect(() => {
    handleSetSectionValidationStatus(elementId, isChecked);
  }, [handleSetSectionValidationStatus, isChecked, elementId]);

  const handleReset = useCallback(() => reset(defaultValues), [defaultValues, reset]);

  // This callback will dynamically adjust the layout based on the height of the widgets
  const childNodeHeightCallback = useCallback((elementId: string, height: number) => {
    // console.log("Sub Component reported height: ", height, " with elementId: ", elementId);
    setLayout((prev) => ({
      ...prev,
      [elementId]: { ...prev[elementId], layout: { ...prev[elementId].layout, h: height } },
    }));
  }, []);

  const onSwap = useCallback(
    (layout: GridLayout.Layout[]) => {
      // console.log("Swapping fields");
      const sortedLayout = layout.filter((d) => d.i !== "new").sort((a, b) => a.y - b.y);
      const fieldsDict = Object.fromEntries(Object.entries(fields).map(([, v]) => [v.elementId, v]));
      const newFieldsLayout = sortedLayout.map((d) => d.i).map((id) => fieldsDict[id]);
      replace(newFieldsLayout);
    },
    [fields, replace]
  );

  const saveChanges = useCallback(
    async () => await handleSubmit(async (data) => await updateSection(elementId, data))(),
    [handleSubmit, elementId, updateSection]
  );

  const [showPlaceholder, setShowPlaceholder] = useState(true);
  useEffect(() => {
    if (fields.length === 0) {
      setShowPlaceholder(true);
    } else {
      setShowPlaceholder(false);
    }
  }, [fields.length]);

  interface InlineCustomFieldCreation {
    idx: number;
    initialValues: Partial<CustomField>;
  }
  const [inlineCustomField, setInlineCustomField] = useState<InlineCustomFieldCreation>();

  const handleInsertCustomField = useCallback(
    (index: number, data: Partial<CustomField>) => {
      insert(index, customFieldToCustomFieldForm(data));
    },
    [insert]
  );

  const onClear = useCallback(() => {
    setInlineCustomField(undefined);
  }, []);

  const onDrop = useCallback(
    async (layout: GridLayout.Layout[], item: GridLayout.Layout, e: MouseEvent) => {
      const sortedLayout = layout.sort((a, b) => a.y - b.y);
      const idx = sortedLayout.indexOf(item);
      // Now we get the custom properties from our dragData dataTransfer
      const dragContent = (e as any).dataTransfer.getData("dragData");
      if (!dragContent) return;
      const dragData = JSON.parse((e as any).dataTransfer.getData("dragData")) as Partial<CustomField>;
      if (idx !== -1) {
        if (!dragData.id) {
          setInlineCustomField((prev) => {
            if (!prev) return { idx, initialValues: dragData };
            return prev;
          });
        } else {
          insert(idx, customFieldToCustomFieldForm(dragData));
          await updateSection(elementId, getValues());
        }
      }
    },
    [elementId, getValues, insert, updateSection]
  );

  const fieldPath = "name";
  let error: any = undefined;
  for (const path of fieldPath) {
    if (!error) error = (errors as any)?.[path];
    else error = error?.[path];
  }
  const isUnsaved = !isValid || isDirty;

  return (
    <GridLayoutElementWrapper
      head={
        <div className="flex col-nowrap gap-5" style={{ width: "100%", height: "fit-content" }}>
          <div className="flex row-nowrap align-center gap-5" style={{ width: "100%", height: "fit-content" }}>
            <LucideIcon name={"panel-top-dashed"} style={{ width: "24px", height: "24px" }} />
            <h4 style={{ margin: 0 }}>
              <input
                className={`${error ? "border-danger form-control" : "form-control"} ${"required"}`}
                type="text"
                {...register("name")}
                placeholder={"Enter section name"}
                // autoFocus={true}
                // style={{ border: 0 }}
                onMouseDown={(e) => {
                  // We prevent dragging inside the actual widget
                  e.stopPropagation();
                }}
                disabled={disabled}
                onBlur={async () => await updateSection(elementId, getValues())}
              />
            </h4>
            <FormFieldError id={"name"} errors={errors} />
            <div className="flex row-nowrap align-center gap-5" style={{ marginLeft: "auto" }}>
              <div className="flex row-nowrap align-center gap-5">
                {!isValid ? (
                  <label className="label label-soft-danger" style={{ margin: 0 }}>
                    Validation error
                  </label>
                ) : isDirty ? (
                  <label className="label label-soft-warning" style={{ margin: 0 }}>
                    Unsaved changes
                  </label>
                ) : (
                  <></>
                )}

                {isUnsaved && (
                  <>
                    <button
                      type="button"
                      className="btn btn-primary"
                      title="Save changes"
                      onClick={async (e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        await saveChanges();
                      }}
                      onMouseDown={(e) => {
                        // We prevent dragging inside the actual widget
                        e.stopPropagation();
                      }}
                    >
                      Save Changes
                    </button>
                    {isDirty && (
                      <button
                        type="button"
                        className="btn btn-default"
                        title="Reset changes"
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          handleReset();
                        }}
                        onMouseDown={(e) => {
                          // We prevent dragging inside the actual widget
                          e.stopPropagation();
                        }}
                      >
                        Reset
                      </button>
                    )}
                  </>
                )}
              </div>
              {!disabledRemove && (
                <button
                  type="button"
                  className="btn btn-soft-danger"
                  title="Delete this section"
                  onMouseDown={(e) => {
                    // We prevent dragging inside the actual widget
                    e.stopPropagation();
                  }}
                  onClick={() => removeSection(elementId)}
                >
                  <LucideIcon name="x" />
                </button>
              )}
            </div>
          </div>
          {errors && <NestedErrorContainer errors={errors as any} />}
        </div>
      }
      ref={ref}
      isFolded={false}
      isValid={isValid}
    >
      <div className={styles.sectionMainContainer}>
        <div className={styles.sectionFormContainer}>
          <fieldset>
            <legend>Settings</legend>
          </fieldset>
          <ToggleFormField
            id="isFolded"
            label={"Fold section by default"}
            control={control}
            disabled={disabled}
            horizontal
          >
            <OverlayInfo icon="info">Fold section by default in detail views.</OverlayInfo>
          </ToggleFormField>

          {inlineCustomField && (
            <CustomFieldFormCreateModal
              index={inlineCustomField.idx}
              initialValues={inlineCustomField.initialValues}
              onSuccess={handleInsertCustomField}
              onClear={onClear}
            />
          )}
          <fieldset>
            <legend>Custom fields</legend>
          </fieldset>
          <div className={styles.rglMainContainerSection} ref={internalRef}>
            {!!width && (
              <div style={{ flex: "1 0 100%", position: "relative" }}>
                <GridLayout
                  className={styles.rglContainerSection}
                  layout={Object.values(layout).map((l) => l.layout)}
                  cols={1}
                  rowHeight={1}
                  width={width}
                  verticalCompact={true}
                  // margin={[0, 0]}
                  containerPadding={[5, 7]}
                  compactType={"vertical"}
                  preventCollision={false}
                  onDragStop={onSwap}
                  onDrop={onDrop}
                  isDroppable={true}
                  isDraggable={!disabled}
                  isResizable={false}
                  isBounded={true}
                  droppingItem={{ i: "new", w: 1, h: 7 }}
                >
                  {Object.entries(layout).map(([elementId, l]) => (
                    <div key={elementId} className="customField" data-grid={l.layout}>
                      <RenderCustomTypeSectionCustomField
                        elementId={elementId}
                        customField={l.field as Partial<CustomField>}
                        nodeHeightCallback={childNodeHeightCallback}
                        removeCustomField={removeCustomField}
                      />
                    </div>
                  ))}
                </GridLayout>
                <div
                  className={`flex col-nowrap align-center justify-center ${styles.placeholderOverlay}`}
                  style={{ display: showPlaceholder ? "flex" : "none" }}
                  onDragOver={() => setShowPlaceholder(false)}
                >
                  <LucideIcon name="hand" color={"var(--gray-400)"} style={{ width: "30px", height: "30px" }} />
                  <span style={{ color: "var(--gray-400)", fontWeight: 500 }}>
                    Drag & drop custom fields from the sidebar
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </GridLayoutElementWrapper>
  );
};
