import { EntityAttributes, EntityOptions } from "./entity-types";
import { useEntityDetail } from "../../../../../api/BaseEntityApi";
import { IGenericEntity, INamedEntity } from "../../../../../api/GenericTypes";
import { EntityWrapper, GetPosition } from "./EntityWrapper/EntityWrapper";
import { LoadingWrapper } from "../../../../../common/LoadingWrapper";
import { Alert } from "../../../../../common/overlays/Alert/Alert";
import { toUppercase } from "../../../../../common/helperfunctions/stringFunctions";
import { InventoryItemsDetailTable } from "../../../../../Inventories/InventoryItemsDetail";
import { InventoryItem, InventoryItemFieldLabels, inventoryItemsConstants } from "../../../../../api/Inventories";
import { PersonDetailTable } from "../../../../../Persons/PersonsDetail";
import { Person, PersonFieldLabels, personsConstants } from "../../../../../api/Person";
import { ProjectsDetailTable } from "../../../../../Projects/ProjectsDetail";
import { Project, ProjectFieldLabels, projectsConstants } from "../../../../../api/Projects";
import { SampleDetailTable } from "../../../../../samples/SamplesDetail";
import { Sample, SampleFieldLabels, samplesConstants } from "../../../../../api/Samples";
import { Dataset } from "../../../../../api/Datasets";
import { DatasetControls } from "./entities/datasets/DatasetControls";
import { DatasetWrapper } from "./entities/datasets/DatasetWrapper";
import { isEqual } from "remirror";
import { genericEntityConstants } from "../../../../../api/GenericConstants";
import { LabNotebookExperimentDetailContentTable } from "../../../../LabNotebookExperiments/LabNotebookExperimentDetailContentTable";
import {
  LabNotebookExperiment,
  labNotebookExperimentFieldLabels,
  labNotebookExperimentsConstants,
} from "../../../../types/LabNotebookExperiment";
import { LabNotebookDetailContentTable } from "../../../../LabNotebooks/LabNotebookDetailContentTable";
import { LabNotebook } from "../../../../types/LabNotebook";
import { LabNotebookEntryDetailContentTable } from "../../../../LabNotebookEntries/LabNotebookEntryDetailContentTable";
import {
  labNotebookEntriesConstants,
  LabNotebookEntry,
  labNotebookEntryFieldLabels,
} from "../../../../types/LabNotebookEntry";

export const EntityViews = ({
  attrs,
  getPosition,
  updateAttributes,
  options,
}: {
  attrs: EntityAttributes;
  getPosition: GetPosition;
  updateAttributes: (attrs: EntityAttributes) => void;
  options?: EntityOptions;
}) => {
  const entityConstants =
    !!attrs?.entityTypeId && Object.hasOwn(genericEntityConstants, attrs.entityTypeId)
      ? genericEntityConstants[attrs?.entityTypeId].entityConstants
      : undefined;

  const {
    data: entity,
    status,
    fetchStatus,
    error,
  } = useEntityDetail<IGenericEntity & INamedEntity>(entityConstants?.resource!, attrs?.entityId!, undefined, {
    enabled: !!entityConstants?.resource && !!attrs?.entityId,
  });

  return (
    <LoadingWrapper status={status} fetchStatus={fetchStatus} error={error} type="skeleton-rows">
      {entity ? (
        <EntityWrapper
          attrs={attrs}
          entity={entity}
          getPosition={getPosition}
          updateAttributes={updateAttributes}
          additionalSettingsControls={EntityControlSwitch({ entity, attrs, getPosition, updateAttributes, options })}
          options={options}
        >
          {EntityComponentSwitch({ entity, attrs, getPosition, updateAttributes, options })}
        </EntityWrapper>
      ) : (
        <Alert
          fit
          centered
          type="warning"
          message={`${toUppercase(entityConstants?.entitySingular ?? "item")} with ID ${
            attrs.entityId ?? "-"
          } could not be found`}
          style={{ marginBottom: "15px" }}
        />
      )}
    </LoadingWrapper>
  );
};

const EntityComponentSwitch = ({
  entity,
  attrs,
  getPosition,
  updateAttributes,
  options,
}: {
  entity: IGenericEntity & INamedEntity;
  attrs: EntityAttributes;
  getPosition: GetPosition;
  updateAttributes: (attrs: EntityAttributes) => void;
  options?: EntityOptions;
}) => {
  switch (attrs?.entityTypeId) {
    case "datasets":
    case "attachments":
      return (
        <DatasetWrapper
          entity={entity as Dataset}
          attrs={attrs}
          getPosition={getPosition}
          updateAttributes={updateAttributes}
          onDoubleClick={options?.onDoubleClick}
          ignoreIntersectionObserver={options?.ignoreIntersectionObserver}
        />
      );
    case "inventoryItems":
      return (
        <InventoryItemsDetailTable
          entity={entity as InventoryItem}
          entityConstants={inventoryItemsConstants}
          fieldLabels={InventoryItemFieldLabels}
          showHierarchy
        />
      );
    case "persons":
      return (
        <PersonDetailTable
          entity={entity as Person}
          entityConstants={personsConstants}
          fieldLabels={PersonFieldLabels}
        />
      );
    case "projects":
      return (
        <ProjectsDetailTable
          entity={entity as Project}
          entityConstants={projectsConstants}
          fieldLabels={ProjectFieldLabels}
        />
      );
    case "samples":
      return (
        <SampleDetailTable
          entity={entity as Sample}
          entityConstants={samplesConstants}
          fieldLabels={SampleFieldLabels}
        />
      );
    case "notebookExperiments":
      return (
        <LabNotebookExperimentDetailContentTable
          entity={entity as LabNotebookExperiment}
          entityConstants={labNotebookExperimentsConstants}
          fieldLabels={labNotebookExperimentFieldLabels}
        />
      );
    case "notebooks":
      return <LabNotebookDetailContentTable labNotebook={entity as LabNotebook} />;
    case "notebookEntries":
      return (
        <LabNotebookEntryDetailContentTable
          entity={entity as LabNotebookEntry}
          entityConstants={labNotebookEntriesConstants}
          fieldLabels={labNotebookEntryFieldLabels}
        />
      );

    default:
      return (
        <Alert
          fit
          centered
          type="warning"
          message="No view available for this item."
          style={{ marginBottom: "15px" }}
        />
      );
  }
};

const EntityControlSwitch = ({
  entity,
  attrs,
  getPosition,
  updateAttributes,
  options,
}: {
  entity: IGenericEntity & INamedEntity;
  attrs: EntityAttributes;
  getPosition: GetPosition;
  updateAttributes: (attrs: EntityAttributes) => void;
  options?: EntityOptions;
}) => {
  switch (attrs.entityTypeId) {
    case "datasets":
    case "attachments":
      return (
        <DatasetControls
          entity={entity as Dataset}
          attrs={attrs}
          getPosition={getPosition}
          updateAttributes={updateAttributes}
        />
      );

    default:
      return null;
  }
};

export const compareAndUpdateAttributes = (
  newAttrs: EntityAttributes,
  lastAttrs: EntityAttributes,
  updateFunction: (attrs: EntityAttributes) => void
) => {
  if (!isEqual(newAttrs, lastAttrs)) {
    updateFunction(newAttrs);
  }
};
