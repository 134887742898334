import React from "react";
import { useHistory } from "react-router-dom";
import { Dataset } from "../../../api/Datasets";
import { Container } from "../../../common/panels/Container/Container";
import { Viewer } from "../../../ViewerLayout/ViewerLayout";
import styles from "./Viewer.module.css";

interface Props {
  dataset: Dataset;
  viewShared?: boolean;
}
export const ViewerFullscreen = ({ dataset, viewShared = false }: Props) => {
  // const { route } = useContext(SessionContext);

  const history = useHistory();
  return (
    <Container
      title={"Detailed view"}
      controls={
        <div
          style={{
            display: "flex",
            flexFlow: "row nowrap",
            width: "100%",
            height: "fit-content",
            alignItems: "center",
            gap: 5,
            whiteSpace: "nowrap",
          }}
        >
          <span>Method:</span>
          {dataset.method && (
            <label className="label label-soft-secondary" style={{ margin: 0 }}>
              {dataset.method.name}
            </label>
          )}
          {dataset.experiment && (
            <>
              <div style={{ width: "2px", height: "100%", background: "var(--gray-200)" }}>&nbsp;</div>
              <span>Experiment:</span>
              <span className="text text-primary">{dataset.experiment.name}</span>
            </>
          )}
          <div style={{ width: "2px", height: "100%", background: "var(--gray-200)" }}>&nbsp;</div>
          <span>Tracks:</span>
          <span className="badge" style={{ margin: 0 }}>
            {dataset.parsedMetadata?.trackCount}
          </span>
        </div>
      }
    >
      {!viewShared ? (
        <div className={styles.container}>
          <Viewer ids={[+dataset.id]} />
        </div>
      ) : (
        <div style={{ display: "flex", flexFlow: "column nowrap", width: "100%", height: "100%" }}>
          <div>
            <label
              className={styles.label}
              onClick={() => {
                // history.push(route(getDetailRoute("datasets")));
                history.push(`${history.location.pathname.split("/viewer")[0]}`);
              }}
            >
              « Show details
            </label>
          </div>
          <div className={styles.container}>
            <Viewer ids={[+dataset.id]} />
          </div>
        </div>
      )}
    </Container>
  );
};
