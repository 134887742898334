import { Control, Controller, DeepMap, FieldError, FieldErrors, FieldValues } from "react-hook-form";
import Select, { GroupBase, StylesConfig, createFilter } from "react-select";
import { IEntityMinimalModel } from "../../api/GenericTypes";
import { FormFieldError, FormFieldLayout } from "./FormFieldLayouts";
import styles from "./formfields.module.css";
import { FormGroup } from "react-bootstrap";
import ReactDOMServer from "react-dom/server";
import { LucideIcon, IconNames } from "../icon/LucideIcon";
import { zIndex } from "../../api/CommonConstants";

const icon = (color = "transparent", name: IconNames) => ({
  "alignItems": "center",
  "display": "flex",

  ":before": {
    content: '" "',
    backgroundImage: `url("data:image/svg+xml;base64,${window.btoa(
      ReactDOMServer.renderToString(<LucideIcon name={name} color={color} width={14} height={14} />)
    )}")`,
    display: "block",
    marginRight: 8,
    height: 14,
    width: 14,
  },
});

const isUnassigned = (data: any) => {
  if (data.id === -2) {
    return { ...icon("#f0284b", "minus"), color: "var(--danger)" };
  } else {
    return {};
  }
};

export const reactSelectBaseStyles: StylesConfig<any, boolean, GroupBase<any>> = {
  menuPortal: (base) => ({ ...base, zIndex: zIndex.zIndexPortalDropdown }),
  control: (baseStyles, state) => ({
    ...baseStyles,
    "&:hover": {
      borderColor: "var(--primary)",
    },
    "borderColor": state.isFocused ? "var(--gray-400)" : "#dadde1",
    "cursor": state.isDisabled ? "not-allowed" : "pointer",
    "boxShadow": state.isFocused
      ? "inset 0 0 0 rgba(0, 0, 0, 0), 0 0 0 3.00000429px rgba(var(--primary-rgb), 0.2)"
      : "inset 0 0 0, 0 0 0 0 rgba(var(--primary-rgb), 0.2)",
    "transition": "border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s, -webkit-box-shadow ease-in-out 0.15s",
  }),
  option: (base, { data, isDisabled, isFocused, isSelected }) => ({
    ...base,
    ...(isDisabled && { cursor: "not-allowed" }),
    // We use id -1 for Adding entities
    ...((data.id === -1 || data.id === "-1") && {
      color: "var(--primary)",
      ...(isFocused && { background: "var(--primary-light)", color: "var(--primary)" }),
      ...(isSelected && { background: "var(--primary)", color: "var(--white)" }),
      ...icon(isSelected ? "#ffffff" : "#3273eb", "plus"),
    }),
    // We use id -2 for Unsetting entities
    ...((data.id === -2 || data.id === "-2") && {
      color: "var(--danger)",
      ...(isFocused && { background: "var(--danger-light)", color: "var(--danger)" }),
      ...(isSelected && { background: "var(--danger)", color: "var(--white)" }),
      ...icon(isSelected ? "#ffffff" : "#f0284b", "minus"),
    }),
  }),
  placeholder: (styles) => ({ ...styles, color: "grey" }),
  singleValue: (styles, { data }) => ({
    ...styles,
    ...isUnassigned(data),
  }),
};

export interface SelectFormFieldProps<EntityType, Form extends FieldValues = any> {
  id: string;
  label: string;
  items: EntityType[] | undefined;
  errors?: DeepMap<Form, FieldError> | FieldErrors<Form>;
  required?: boolean;
  control: Control<Form>;
  getOptionLabel?: (option: any) => string;
  getOptionValue?: (option: any) => string;
  isMulti?: boolean;
  buttons?: React.ReactNode;
  horizontal?: boolean;
  hasLabel?: boolean; // to re-use this component in add-multiple-entity as table rows
  name?: string;
  classNamePrefix?: string;
  style?: StylesConfig<any, boolean, GroupBase<any>>;
  defaultValue?: any;
  components?: any;
  placeholder?: string;
  isDisabled?: boolean;
  showErrors?: boolean;
  autoFocus?: boolean;
  menuPortalTarget?: HTMLElement | null | undefined;
  uncontained?: boolean;
  shouldUnregister?: boolean; //	Input will be unregistered after unmount and defaultValues will be removed as well.
  onBlur?: () => void;
  isClearable?: boolean;
}

export const SelectFormField = function <T = IEntityMinimalModel>({
  id,
  label,
  items,
  errors,
  control,
  required,
  // these default function only work for EntityReference
  getOptionLabel = <T,>(option: T) => (option as any)?.name,
  getOptionValue = <T,>(option: T) => (option as any)?.id,
  isMulti = false,
  buttons,
  horizontal = true,
  hasLabel = true,
  name,
  classNamePrefix,
  style,
  defaultValue,
  components = null as any,
  placeholder,
  isDisabled = false,
  showErrors = true,
  autoFocus = false,
  menuPortalTarget = document.body,
  uncontained = false,
  shouldUnregister = false,
  onBlur,
  isClearable = true,
}: SelectFormFieldProps<T>) {
  //#region error
  const fieldPath = id.split(".");
  let error: any = undefined;
  for (const path of fieldPath) {
    if (!error) error = errors?.[path];
    else error = error?.[path];
  }
  //#endregion
  if (uncontained) {
    return (
      <FormGroup controlId={id} style={{ margin: 0, width: "100%", height: "100%" }}>
        <div className="flex" style={{ margin: 0, width: "100%", minHeight: "100%" }}>
          <Controller
            control={control}
            name={name ?? id}
            rules={{ required: required }}
            shouldUnregister={shouldUnregister}
            render={({ field: { onChange, onBlur: onBlurController, value, name, ref } }) => (
              <Select
                menuPortalTarget={menuPortalTarget}
                id={id}
                classNamePrefix={error ? "border-danger react-select" : classNamePrefix ? "" : "react-select"}
                className={styles.input}
                value={value || []}
                ref={ref}
                // required={required}
                options={items || []}
                closeMenuOnSelect={true}
                isSearchable={true}
                isMulti={isMulti}
                isClearable={isClearable}
                onChange={onChange}
                onBlur={onBlur ?? onBlurController}
                getOptionLabel={getOptionLabel}
                getOptionValue={getOptionValue}
                // name={name} // this causes error: A component is changing an uncontrolled input of type hidden to be controlled.
                defaultValue={defaultValue}
                filterOption={createFilter({ ignoreAccents: false })}
                closeMenuOnScroll={(event: any) => {
                  return event.target.id === "scrollContainer";
                }}
                // closeMenuOnScroll={(e: any) => {
                //   return e.target.contains(menuPortalTarget);
                // }}
                // menuPosition="fixed"
                placeholder={placeholder}
                isDisabled={isDisabled}
                styles={{
                  ...reactSelectBaseStyles,
                  ...style,
                }}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: "var(--primary-light)",
                    primary: "var(--primary)",
                  },
                })}
                autoFocus={autoFocus}
              />
            )}
          />
          {buttons ? <div className={styles.buttons}>{buttons}</div> : null}
        </div>
      </FormGroup>
    );
  } else {
    return (
      <FormFieldLayout id={id} label={label} required={required} horizontal={horizontal} hasLabel={hasLabel}>
        <div className={styles.container}>
          <Controller
            control={control}
            name={name ?? id}
            rules={{ required: required }}
            shouldUnregister={shouldUnregister}
            render={({ field: { onChange, onBlur: onBlurController, value, name, ref } }) => (
              <Select
                menuPortalTarget={menuPortalTarget}
                id={id}
                classNamePrefix={error ? "border-danger react-select" : classNamePrefix ? "" : "react-select"}
                className={styles.input}
                value={value || []}
                ref={ref}
                // required={required}
                options={items || []}
                closeMenuOnSelect={true}
                isSearchable={true}
                isMulti={isMulti}
                isClearable={isClearable}
                onChange={onChange}
                onBlur={onBlur ?? onBlurController}
                getOptionLabel={getOptionLabel}
                getOptionValue={getOptionValue}
                // name={name} // this causes error: A component is changing an uncontrolled input of type hidden to be controlled.
                defaultValue={defaultValue}
                filterOption={createFilter({ ignoreAccents: false })}
                closeMenuOnScroll={(event: any) => {
                  return event.target.id === "scrollContainer";
                }}
                // closeMenuOnScroll={(e: any) => {
                //   return e.target.contains(menuPortalTarget);
                // }}
                // menuPosition="fixed"
                placeholder={placeholder}
                isDisabled={isDisabled}
                styles={{
                  ...reactSelectBaseStyles,
                  ...style,
                }}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: "var(--primary-light)",
                    primary: "var(--primary)",
                  },
                })}
                autoFocus={autoFocus}
              />
            )}
          />
          {buttons ? <div className={styles.buttons}>{buttons}</div> : null}
        </div>
        {showErrors && <FormFieldError id={id} errors={errors} />}
      </FormFieldLayout>
    );
  }
};
