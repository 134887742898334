import React, { useState, useEffect } from "react";
import { LucideIcon } from "../common/icon/LucideIcon";

import styles from "./SelectButton.module.css";

export type selectState = boolean | undefined | "uncertain";

export const SelectButton = React.memo((props: any) => {
  const { id, active, onClick, setActive, onChange } = props;
  const [switchState, setSwitchstate] = useState<selectState>(active || false);

  useEffect(() => {
    // console.log("parent", active);
    setSwitchstate(active);
  }, [active]);

  // let glyph = switchState === "uncertain" ? "glyphicon-stop" : switchState ? "glyphicon-check" : "glyphicon-unchecked";
  // let glyph2 = switchState === "uncertain" ? "filled-square" : switchState ? "square-check-big" : "square";
  let color =
    switchState === "uncertain" ? styles.viewerSwitchOff : switchState ? styles.viewerSwitchOn : styles.viewerSwitchOff;

  return (
    <div className={styles.viewerSwitchSelect}>
      <span
        id={id}
        className={styles.viewerSwitchSelect}
        onClick={() => {
          // setState(!state);
          if (onClick) onClick({ id: id, active: switchState });
          else {
            if (setActive) setActive(!switchState);
            else setSwitchstate(!switchState);
            if (onChange) onChange(switchState);
            // if (setActive) setActive(switchState);
          }
        }}
      >
        <span id={id} className={color}>
          {/* <span id={id} className={switchState === true ? styles.viewerSwitchOn : styles.viewerSwitchOff}> */}
          {/* <span id={id} className={switchState ? "glyphicon glyphicon-check" : "glyphicon glyphicon-unchecked"} /> */}
          {/* <span id={id} className={["glyphicon", glyph].join(" ")} /> */}
          <LucideIcon
            name={switchState === true ? "square-check-big" : "square"}
            style={{ height: 12, strokeWidth: 3, fill: switchState === "uncertain" ? "#898989" : undefined }}
          />
          &nbsp;
          <span>{props.children}</span>
        </span>
      </span>
    </div>
  );
});
