import { cloneElement, ReactElement, useContext, useEffect, useMemo } from "react";
import { Redirect, Route, Switch, useParams } from "react-router-dom";
import { LabNotebook } from "../LabNotebooks/LabNotebook";
import { LabNotebookTemplates } from "../LabNotebookTemplates/LabNotebookTemplates";
import { Dashboard } from "../Dashboard/Dashboard";
import { LabNotebookExperiment, labNotebookExperimentsConstants } from "../types/LabNotebookExperiment";
import { labNotebookEntriesConstants, LabNotebookEntry } from "../types/LabNotebookEntry";
import { getAddRoute, getCloneRoute, getDetailRoute, getEditRoute, getIndexRoute } from "../../main/Routing";
import { labNoteBooksConstants } from "../types/LabNotebook";
import { labNotebookTemplatesConstants } from "../types/LabNotebookTemplate";
import {
  LabNotebookTemplateAddFormPage,
  LabNotebookTemplateCloneFormPage,
  LabNotebookTemplateDetailsPage,
  LabNotebookTemplateEditFormPage,
  LabNotebookTemplatesTablePage,
} from "../LabNotebookTemplates/LabNotebookTemplateViews";
import { useEntityDetail } from "../../api/BaseEntityApi";
import { useELNRoutes } from "./useELNRoutes";
import { LoadingWrapper } from "../../common/LoadingWrapper";

import { SessionContext } from "../../common/contexts/SessionContext";
import {
  LabNotebookAddFormPage,
  LabNotebookEditFormPage,
  LabNotebooksTablePage,
} from "../LabNotebooks/LabNotebookViews";
import {
  LabNotebookExperimentAddFormPage,
  LabNotebookExperimentEditFormPage,
  LabNotebookExperimentsTablePage,
} from "../LabNotebookExperiments/LabNotebookExperimentViews";
import {
  LabNotebookEntryAddFormPage,
  LabNotebookEntryEditFormPage,
  LabNotebookEntrysTablePage,
} from "../LabNotebookEntries/LabNotebookEntryViews";

export type ELNModes = "view" | "edit";
export interface ELNUrlParamTypes {
  labNotebookId?: string;
  labNotebookExperimentId?: string;
  labNotebookEntryId?: string;
  mode?: ELNModes;
  labNotebookBlockId?: string;
  labNotebookTemplateId?: string;
}

export const notebooksDashboardRoute = "dashboard";
export const notebooksRoute = "notebooks";
export const notebookExperimentsRoute = "experiments";
export const notebookEntriesRoute = "entries";
export const notebookTemplatesRoute = "templates";
export const notebookBlocksRoute = "blocks";

export const NotebooksRouter = () => {
  const { route } = useContext(SessionContext);
  return (
    <Switch>
      <Route
        path={route(getIndexRoute(labNoteBooksConstants.frontendIndexRoute)) + `/${notebooksDashboardRoute}`}
        exact
      >
        <Dashboard />
      </Route>

      {/* Notebooks */}
      <Route path={route(getIndexRoute(labNoteBooksConstants.frontendIndexRoute))} exact>
        <LabNotebooksTablePage />
      </Route>
      <Route path={route(getAddRoute(labNoteBooksConstants.frontendIndexRoute))}>
        <LabNotebookAddFormPage />
      </Route>
      <Route path={route(getEditRoute(labNoteBooksConstants.frontendIndexRoute))}>
        <LabNotebookEditFormPage />
      </Route>

      {/* Experiments */}
      <Route path={route(getIndexRoute(labNotebookExperimentsConstants.frontendIndexRoute))} exact>
        <LabNotebookExperimentsTablePage />
      </Route>
      <Route path={route(getAddRoute(labNotebookExperimentsConstants.frontendIndexRoute))}>
        <LabNotebookExperimentAddFormPage />
      </Route>
      <Route path={route(getEditRoute(labNotebookExperimentsConstants.frontendIndexRoute))}>
        <LabNotebookExperimentEditFormPage />
      </Route>

      {/* Entries */}
      <Route path={route(getIndexRoute(labNotebookEntriesConstants.frontendIndexRoute))} exact>
        <LabNotebookEntrysTablePage />
      </Route>
      <Route path={route(getAddRoute(labNotebookEntriesConstants.frontendIndexRoute))}>
        <LabNotebookEntryAddFormPage />
      </Route>
      <Route path={route(getEditRoute(labNotebookEntriesConstants.frontendIndexRoute))}>
        <LabNotebookEntryEditFormPage />
      </Route>

      {/* Templates */}
      <Route path={route(getIndexRoute(labNotebookTemplatesConstants.frontendIndexRoute))} exact>
        <LabNotebookTemplatesTablePage />
      </Route>
      <Route path={route(getAddRoute(labNotebookTemplatesConstants.frontendIndexRoute))}>
        <LabNotebookTemplateAddFormPage />
      </Route>
      <Route path={route(getEditRoute(labNotebookTemplatesConstants.frontendIndexRoute))}>
        <LabNotebookTemplateEditFormPage />
      </Route>
      <Route path={route(getCloneRoute(labNotebookTemplatesConstants.frontendIndexRoute))}>
        <LabNotebookTemplateCloneFormPage />
      </Route>
      <Route path={route(getDetailRoute(labNotebookTemplatesConstants.frontendIndexRoute))} exact>
        <LabNotebookTemplateDetailsPage />
      </Route>
      <Route
        path={
          route(getIndexRoute(labNotebookTemplatesConstants.frontendIndexRoute)) +
          `/:labNotebookTemplateId/content/:mode`
        }
      >
        <ELNWrapper>
          <LabNotebookTemplates />
        </ELNWrapper>
      </Route>

      {/* Content */}
      <Route
        path={
          route(getIndexRoute(labNoteBooksConstants.frontendIndexRoute)) +
          `/:labNotebookId/${notebookExperimentsRoute}/:labNotebookExperimentId/${notebookEntriesRoute}/:labNotebookEntryId/:mode/${notebookBlocksRoute}/:labNotebookBlockId`
        }
        exact
      >
        <ELNWrapper>
          <LabNotebook />
        </ELNWrapper>
      </Route>
      <Route
        path={
          route(getIndexRoute(labNoteBooksConstants.frontendIndexRoute)) +
          `/:labNotebookId/${notebookEntriesRoute}/:labNotebookEntryId/:mode/${notebookBlocksRoute}/:labNotebookBlockId`
        }
        exact
      >
        <ELNWrapper>
          <LabNotebook />
        </ELNWrapper>
      </Route>
      <Route
        path={
          route(getIndexRoute(labNoteBooksConstants.frontendIndexRoute)) +
          `/:labNotebookId/${notebookExperimentsRoute}/:labNotebookExperimentId/${notebookEntriesRoute}/:labNotebookEntryId/:mode`
        }
      >
        <ELNWrapper>
          <LabNotebook />
        </ELNWrapper>
      </Route>
      <Route
        path={
          route(getIndexRoute(labNoteBooksConstants.frontendIndexRoute)) +
          `/:labNotebookId/${notebookEntriesRoute}/:labNotebookEntryId/:mode`
        }
      >
        <ELNWrapper>
          <LabNotebook />
        </ELNWrapper>
      </Route>
      <Route
        path={
          route(getIndexRoute(labNoteBooksConstants.frontendIndexRoute)) +
          `/:labNotebookId/${notebookExperimentsRoute}/:labNotebookExperimentId`
        }
      >
        <ELNWrapper>
          <LabNotebook />
        </ELNWrapper>
      </Route>
      <Route
        path={
          route(getIndexRoute(labNoteBooksConstants.frontendIndexRoute)) +
          `/${notebookExperimentsRoute}/:labNotebookExperimentId`
        }
      >
        <ELNWrapper>
          <ExperimentReroute />
        </ELNWrapper>
      </Route>
      <Route
        path={
          route(getIndexRoute(labNoteBooksConstants.frontendIndexRoute)) +
          `/${notebookEntriesRoute}/:labNotebookEntryId`
        }
      >
        <ELNWrapper>
          <EntryReroute />
        </ELNWrapper>
      </Route>
      <Route path={route(getIndexRoute(labNoteBooksConstants.frontendIndexRoute)) + `/:labNotebookId`}>
        <ELNWrapper>
          <LabNotebook />
        </ELNWrapper>
      </Route>
      <Redirect to={route(getIndexRoute(labNoteBooksConstants.frontendIndexRoute)) + `/${notebooksDashboardRoute}`} />
    </Switch>
  );
};

const ELNWrapper = ({ children }: { children?: ReactElement }) => {
  const {
    labNotebookId,
    labNotebookExperimentId,
    labNotebookEntryId,
    mode,
    labNotebookBlockId,
    labNotebookTemplateId,
  } = useParams<ELNUrlParamTypes>();

  const entityInfo = useMemo(() => {
    return {
      labNotebookId: labNotebookId && parseInt(labNotebookId),
      labNotebookExperimentId: labNotebookExperimentId && parseInt(labNotebookExperimentId),
      labNotebookEntryId: labNotebookEntryId && parseInt(labNotebookEntryId),
      mode: mode || "view",
      labNotebookBlockId: labNotebookBlockId?.toString(),
      labNotebookTemplateId: labNotebookTemplateId && parseInt(labNotebookTemplateId),
    };
  }, [labNotebookBlockId, labNotebookEntryId, labNotebookExperimentId, labNotebookId, labNotebookTemplateId, mode]);

  if (!children) return null;
  return cloneElement(children, entityInfo);
};

const ExperimentReroute = () => {
  const { labNotebookExperimentId } = useParams<ELNUrlParamTypes>();
  const { setELNRoute } = useELNRoutes();
  const intId = labNotebookExperimentId ? +labNotebookExperimentId : 0;
  const { data, fetchStatus, status, error } = useEntityDetail<LabNotebookExperiment>(
    labNotebookExperimentsConstants.resource,
    intId,
    undefined,
    {
      enabled: !!intId,
    }
  );
  useEffect(() => {
    if (data)
      setELNRoute({
        labNotebookId: data.labNotebook.id,
        labNotebookExperimentId: data.id,
        replace: true,
      });
  }, [data, setELNRoute]);
  return (
    <LoadingWrapper status={status} fetchStatus={fetchStatus} error={error}>
      <div />
    </LoadingWrapper>
  );
};

const EntryReroute = () => {
  const { labNotebookEntryId } = useParams<ELNUrlParamTypes>();
  const { setELNRoute } = useELNRoutes();
  const intId = labNotebookEntryId ? +labNotebookEntryId : 0;
  const { data, fetchStatus, status, error } = useEntityDetail<LabNotebookEntry>(
    labNotebookEntriesConstants.resource,
    intId,
    undefined,
    {
      enabled: !!intId,
    }
  );
  useEffect(() => {
    if (data)
      setELNRoute({
        labNotebookId: data.labNotebook.id,
        labNotebookExperimentId: data.labNotebookExperiment.id,
        labNotebookEntryId: data.id,
        replace: true,
      });
  }, [data, setELNRoute]);
  return (
    <LoadingWrapper status={status} fetchStatus={fetchStatus} error={error}>
      <div />
    </LoadingWrapper>
  );
};
