import { useCallback, useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import Codeblock from "../common/textfields/Codeblock/Codeblock";

import styles from "./SourceModal.module.css";

export const SourceModal = ({
  show,
  source,
  setShow,
}: {
  show: boolean;
  source: string;
  setShow: (show: boolean) => void;
}) => {
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    setShowModal(show);
  }, [show]);

  useEffect(() => {
    setShow(showModal);
  }, [showModal, setShow]);

  const handleClose = useCallback(() => setShowModal(false), []);

  // const content = "stdOut: Hallo du wurm\nstdErr: Hallo du wurm";

  return (
    <>
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Parser output</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className={styles.main}>
            <Codeblock>{source}</Codeblock>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button bsStyle="default" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
