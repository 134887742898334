import { ControlLabel, FormGroup } from "react-bootstrap";
import { DeepMap, FieldError } from "react-hook-form";
import styles from "./formfields.module.css";

interface Props {
  id: string;
  label: string;
  children: React.ReactNode;
  labelWidth?: number;
  required?: boolean;
  containerClassName?: string;
  horizontal?: boolean;
  hasLabel?: boolean; // to re-use this component in add-multiple-entity as table rows
  style?: React.CSSProperties;
}

export const FormFieldLayout = ({ horizontal, ...props }: Props) => {
  if (horizontal) return <HorizontalFormFieldLayout {...props} />;
  else return <SimpleFormFieldLayout {...props} />;
};

export const HorizontalFormFieldLayout = ({
  id,
  label,
  children,
  labelWidth = 3,
  required,
  hasLabel = true,
  style,
}: Props) => (
  <FormGroup controlId={id} className={`row ${required ? "required" : ""}`} style={style}>
    {hasLabel && (
      <ControlLabel
        className={`col-sm-${labelWidth}`}
        onClick={(e) => {
          e.preventDefault();
        }}
      >
        {label}
        {/* {required ? <span className={styles.required}>*</span> : null} */}
      </ControlLabel>
    )}
    <div className={hasLabel ? `col-sm-${12 - labelWidth}` : `col-xs-12`}>{children}</div>
  </FormGroup>
);

export const SimpleFormFieldLayout = ({ id, label, children, required, hasLabel = true, style }: Props) => (
  <FormGroup controlId={id} style={style}>
    <ControlLabel>
      {hasLabel && label}
      {required ? <span className={styles.required}> *</span> : null}
    </ControlLabel>
    <div>{children}</div>
  </FormGroup>
);

interface FormFieldErrorProps {
  id: string;
  errors?: DeepMap<any, FieldError>;
}

export const FormFieldError = ({ id, errors }: FormFieldErrorProps) => {
  const fieldPath = id.split(".");

  let error: any = undefined;

  for (const path of fieldPath) {
    if (!error) error = errors?.[path];
    else error = error?.[path];
  }
  return <>{error ? <div className={styles.error}>{error?.message} </div> : null}</>;
};
