import React, { useCallback, useContext, useMemo, useRef, useState } from "react";
import { Dataset, DatasetFilters, datasetsConstants } from "../../api/Datasets";
import { MultiEditTable } from "../../common/tables/MultiEdit/MultiEditTable";
import {
  GenericVirtualizedTableCells,
  GenericVirtualizedTableFunctionRef,
  SortState,
} from "../../common/tables/GenericVirtualizedTable/GenericVirtualizedTableTypes";
// import { ColumnsSettings } from "../../common/tables/ColumnsSelector/ColumnsSelector";
import { switchDatasetsDefaultSortState } from "./DatasetsTable";
import { useHistory, useLocation } from "react-router-dom";
import { Alert } from "../../common/overlays/Alert/Alert";
// import * as yup from "yup";
import {
  MultiEditMappedItemObject,
  MultiEditProvider,
} from "../../common/forms/MultiEditForms/common/MultiEditProvider";
import { MultiEditInputForm } from "../../common/forms/MultiEditForms/forms/MultiEditInputForm";
import { MultiEditTextAreaForm } from "../../common/forms/MultiEditForms/forms/MultiEditTextAreaForm";
import { MultiEditSelectForm } from "../../common/forms/MultiEditForms/forms/MultiEditSelectForm";
import { MultiEditHeaderWrapper } from "../../common/forms/MultiEditForms/common/MultiEditFormWrapper";
import {
  generateBulkEditYupSchema,
  getValueOrDefault,
  useMultiEditUtils,
} from "../../common/forms/MultiEditForms/common/MultiEditUtils";

import { InputFormField } from "../../common/formfields/InputFormField";
import { TextareaFormField } from "../../common/formfields/TextareaFormField";
// import styles from "../../common/tables/GenericVirtualizedTable/commons.module.css";
import { useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { SessionContext } from "../../common/contexts/SessionContext";
import { LucideIcon } from "../../common/icon/LucideIcon";
import { GenericModalWrapper } from "../../common/modals/Modal/GenericModal";
import { RenderDatasetPreview } from "../ManualUpload/components/RenderDatasetPreview";
import { BulkEditLocationState } from "../../common/tables/MultiEdit/BulkEditDropdown/BulkEditDropDownTypes";

import { SamplesVirtualizedSelectForm } from "../../common/forms/EntityForms/formsVirtualized/SamplesVirtualizedSelectForm";
import { MethodsVirtualizedSelectForm } from "../../common/forms/EntityForms/formsVirtualized/MethodsVirtualizedSelectForm";
import { InstrumentsVirtualizedSelectForm } from "../../common/forms/EntityForms/formsVirtualized/InstrumentsVirtualizedSelectForm";
import { ProjectsVirtualizedSelectForm } from "../../common/forms/EntityForms/formsVirtualized/ProjectsVirtualizedSelectForm";
import { OrganizationsVirtualizedSelectForm } from "../../common/forms/EntityForms/formsVirtualized/OrganizationsVirtualizedSelectForm";
import { PersonsVirtualizedSelectForm } from "../../common/forms/EntityForms/formsVirtualized/PersonsVirtualizedSelectForm";
import { ExperimentsVirtualizedSelectForm } from "../../common/forms/EntityForms/formsVirtualized/ExperimentsVirtualizedSelectForm";
import { EquipmentsVirtualizedSelectForm } from "../../common/forms/EntityForms/formsVirtualized/EquipmentsVirtualizedSelectForm";
import { Sample, SampleFilters, samplesConstants } from "../../api/Samples";
import { methodsConstants } from "../../api/Methods";
import { equipmentsConstants, instrumentsConstants } from "../../api/Facilities";
import { projectsConstants } from "../../api/Projects";
import { organizationsConstants } from "../../api/Organizations";
import { Person, PersonFilters, personsConstants } from "../../api/Person";
import { experimentsConstants } from "../../api/Experiments";
import { useEntityDetail } from "../../api/BaseEntityApi";
import { Button } from "../../common/buttons/Button/Button";
import { AlertModal } from "../../common/modals/AlertModal/AlertModal";
import { customTypeConstants } from "../../api/CustomTypes";
import { CustomTypesVirtualizedSelectForm } from "../../common/forms/EntityForms/formsVirtualized/CustomTypesVirtualizedSelectForm";
import { multiEditCustomFieldTableCells } from "../../Customization/CustomTypes/generics/useCustomTypesEntityTable";
import { useCustomTypes } from "../../Customization/CustomTypes/generics/useCustomTypes";
import {
  processInitialValuesForTypedEntity,
  processValuesForTypedEntity,
  useCustomFieldsValidationObjectShape,
} from "../../Customization/CustomTypes/generics/CustomTypeValidationUtils";

export const DatasetsBulkEditTable = () => {
  const history = useHistory();
  const { session } = useContext(SessionContext);
  const location = useLocation<BulkEditLocationState<Dataset, DatasetFilters>>();
  const defaultFilters = useMemo(() => (location && location.state?.filters) || {}, [location]);
  const initItems = useMemo(() => (location && location.state?.items) || undefined, [location]);
  const [selection, setSelection] = useState<Set<number>>(new Set());
  const functionRef = useRef<GenericVirtualizedTableFunctionRef<Dataset>>(null);
  const [filters, setFilters] = useState<DatasetFilters>({ ...defaultFilters });
  const [sort, setSort] = useState<SortState<DatasetFilters["orderBy"]>>(
    switchDatasetsDefaultSortState(filters.orderBy)
  );
  const [typeOverride, setTypeOverride] = useState(false);
  const { types } = useCustomTypes({ entityType: "Dataset", filters: { isEnabled: true } });

  const isRequired = useRef(false);

  // Hook to update searchTerm or orderBy if filter changes; (e.g. triggered by the sidebar)
  useMemo(() => {
    if (defaultFilters.orderBy !== undefined) {
      setSort(switchDatasetsDefaultSortState(defaultFilters.orderBy));
    }
  }, [defaultFilters.orderBy]);

  const {
    control,
    register,
    getValues,
    formState: { errors },
  } = useForm<Dataset>({ defaultValues: {} });

  const fixedRowRendererColumnWidth = 35;
  const fixedRowRendererColumn = useCallback(() => {
    return <></>;
  }, []);

  const fixedRowRenderer = useCallback((row: Dataset) => {
    return (
      <GenericModalWrapper>
        {({ showModal, setShowModal }) => (
          <>
            <button title="Preview" className="btn btn-xs btn-round btn-primary" onClick={() => setShowModal(true)}>
              <LucideIcon name="zoom-in" />
            </button>
            <RenderDatasetPreview
              datasetId={row.id}
              name={row.name}
              showModal={showModal}
              setShowModal={setShowModal}
            />
          </>
        )}
      </GenericModalWrapper>
    );
  }, []);

  // Create some suggestions
  const sample = useWatch({ control: control, name: "sample" });
  const { data: sampleFullModel } = useEntityDetail<Sample, SampleFilters>(
    samplesConstants.resource,
    sample?.id!,
    undefined,
    { enabled: !!sample }
  );

  const { data: personFullModel } = useEntityDetail<Person, PersonFilters>(
    personsConstants.resource,
    session?.person?.id!,
    undefined,
    { enabled: !!session?.person }
  );

  const columns: GenericVirtualizedTableCells<Dataset> = useMemo(() => {
    let res: GenericVirtualizedTableCells<Dataset> = [
      {
        id: "default-id",
        Header: "ID",
        accessor: (row) => (
          <div
            className={"flex row-nowrap align-center"}
            style={{ padding: "7px", background: "var(--gray-100)", width: "100%", cursor: "not-allowed" }}
          >
            <span style={{ color: "var(--gray-400)" }}>
              <samp>{row.id}</samp>
            </span>
          </div>
        ),
        width: 100,
        align: "left",
        sortingFn: () => {
          if (sort.headerId === "default-id") {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC" }));
              setFilters((prev) => ({ ...prev, orderBy: "ID_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC" }));
              setFilters((prev) => ({ ...prev, orderBy: "ID_ASC" }));
            }
          } else {
            setSort({ headerId: "default-id", sortDirection: "ASC" });
            setFilters((prev) => ({ ...prev, orderBy: "ID_ASC" }));
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
      {
        id: "default-name",
        Header: "Name",
        accessor: (row) => (
          <div className="flex" style={{ overflow: "auto", width: "100%", height: "100%" }}>
            <MultiEditInputForm id={`name`} row={row} readOnly />
          </div>
        ),
        minWidth: 150,
        width: 250,
        align: "left",
        sortingFn: () => {
          if (sort.headerId === "default-name") {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC" }));
              setFilters((prev) => ({ ...prev, orderBy: "NAME_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC" }));
              setFilters((prev) => ({ ...prev, orderBy: "NAME_ASC" }));
            }
          } else {
            setSort({ headerId: "default-name", sortDirection: "ASC" });
            setFilters((prev) => ({ ...prev, orderBy: "NAME_ASC" }));
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
      {
        id: "default-sourcepath",
        Header: "Source path",
        accessor: (row) => (
          // <div className={styles.container_ellipsis} style={{ padding: "7px" }}>
          //   <span>{row.path}</span>

          // </div>
          <div className="flex" style={{ overflow: "auto", width: "100%", height: "100%" }}>
            <MultiEditInputForm id={`path`} row={row} readOnly />
          </div>
        ),
        width: 200,
        align: "left",
      },
      {
        id: "default-sample",
        Header: (
          <MultiEditHeaderWrapper<Dataset>
            control={control}
            getValues={getValues}
            ids={selection}
            label="Sample"
            property="sample"
            required={isRequired.current}
            hasApply
          >
            {(consolidatedValues) => (
              <SamplesVirtualizedSelectForm
                id="sample"
                control={control}
                hasLabel={false}
                placeholder={getValueOrDefault(consolidatedValues?.sample)}
                defaultValue={consolidatedValues?.sample?.value ?? null}
                horizontal
                allowCreateEntity
                allowUnassigned
                uncontained
                // showControls
              />
            )}
          </MultiEditHeaderWrapper>
        ),
        accessor: (row, rowIndex, columnIndex) => (
          <MultiEditSelectForm
            id={`sample`}
            entityConstants={samplesConstants}
            row={row}
            required={isRequired.current}
          />
        ),
        minWidth: 300,
        width: 300,
        sortingFn: () => {
          if (sort.headerId === "default-sample") {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC" }));
              setFilters((prev) => ({ ...prev, orderBy: "SAMPLE_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC" }));
              setFilters((prev) => ({ ...prev, orderBy: "SAMPLE_ASC" }));
            }
          } else {
            setSort({ headerId: "default-sample", sortDirection: "ASC" });
            setFilters((prev) => ({ ...prev, orderBy: "SAMPLE_ASC" }));
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
      {
        id: "default-method",
        Header: (
          <MultiEditHeaderWrapper<Dataset>
            control={control}
            getValues={getValues}
            ids={selection}
            label="Method"
            property="method"
            required={isRequired.current}
            hasApply
          >
            {(consolidatedValues) => (
              <MethodsVirtualizedSelectForm
                id="method"
                control={control}
                hasLabel={false}
                placeholder={getValueOrDefault(consolidatedValues?.method)}
                defaultValue={consolidatedValues?.method?.value ?? null}
                horizontal
                allowCreateEntity
                allowUnassigned
                uncontained
                // showControls
              />
            )}
          </MultiEditHeaderWrapper>
        ),
        accessor: (row, rowIndex, columnIndex) => (
          <div className="flex" style={{ overflow: "auto", width: "100%", height: "100%" }}>
            <MultiEditSelectForm
              id={`method`}
              entityConstants={methodsConstants}
              row={row}
              required={isRequired.current}
            />
          </div>
        ),
        minWidth: 300,
        width: 300,
        align: "left",
        sortingFn: () => {
          if (sort.headerId === "default-method") {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC" }));
              setFilters((prev) => ({ ...prev, orderBy: "METHOD_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC" }));
              setFilters((prev) => ({ ...prev, orderBy: "METHOD_ASC" }));
            }
          } else {
            setSort({ headerId: "default-method", sortDirection: "ASC" });
            setFilters((prev) => ({ ...prev, orderBy: "METHOD_ASC" }));
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },

      {
        id: "default-instrument",
        Header: (
          <MultiEditHeaderWrapper<Dataset>
            control={control}
            getValues={getValues}
            ids={selection}
            label="Instrument"
            property="instrument"
            hasApply
          >
            {(consolidatedValues) => (
              <InstrumentsVirtualizedSelectForm
                id="instrument"
                control={control}
                hasLabel={false}
                placeholder={getValueOrDefault(consolidatedValues?.instrument)}
                defaultValue={consolidatedValues?.instrument?.value ?? null}
                horizontal
                allowCreateEntity
                allowUnassigned
                uncontained
                // showControls
              />
            )}
          </MultiEditHeaderWrapper>
        ),
        accessor: (row, rowIndex, columnIndex) => (
          <div className="flex" style={{ overflow: "auto", width: "100%", height: "100%" }}>
            <MultiEditSelectForm id={`instrument`} entityConstants={instrumentsConstants} row={row} />
          </div>
        ),
        minWidth: 300,
        width: 300,
        align: "left",
        sortingFn: () => {
          if (sort.headerId === "default-instrument") {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC" }));
              setFilters((prev) => ({ ...prev, orderBy: "INSTRUMENT_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC" }));
              setFilters((prev) => ({ ...prev, orderBy: "INSTRUMENT_ASC" }));
            }
          } else {
            setSort({ headerId: "default-instrument", sortDirection: "ASC" });
            setFilters((prev) => ({ ...prev, orderBy: "INSTRUMENT_ASC" }));
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
      {
        id: "default-projects",
        Header: (
          <MultiEditHeaderWrapper<Dataset>
            control={control}
            getValues={getValues}
            ids={selection}
            label="Projects"
            property="projects"
            required={isRequired.current}
            hasApply
            hasAppend
          >
            {(consolidatedValues) => (
              <ProjectsVirtualizedSelectForm
                id="projects"
                control={control}
                hasLabel={false}
                placeholder={getValueOrDefault(consolidatedValues?.projects)}
                defaultValue={consolidatedValues?.projects?.value ?? null}
                horizontal
                allowCreateEntity
                allowUnassigned
                uncontained
                // showControls
                isMulti
                suggestionsIds={sampleFullModel ? sampleFullModel.projects.map((p) => p.id) : undefined}
                filters={{ currentUserHasAddPermission: true }}
              />
            )}
          </MultiEditHeaderWrapper>
        ),
        accessor: (row, rowIndex, columnIndex) => (
          <div className="flex" style={{ overflow: "auto", width: "100%", height: "100%" }}>
            <MultiEditSelectForm
              id={`projects`}
              entityConstants={projectsConstants}
              row={row}
              isMulti
              required={isRequired.current}
            />
          </div>
        ),
        minWidth: 400,
        width: 400,
      },
      {
        id: "default-organizations",
        Header: (
          <MultiEditHeaderWrapper<Dataset>
            control={control}
            getValues={getValues}
            ids={selection}
            label="Organizations"
            property="organizations"
            hasApply
            hasAppend
          >
            {(consolidatedValues) => (
              <OrganizationsVirtualizedSelectForm
                id="organizations"
                control={control}
                hasLabel={false}
                placeholder={getValueOrDefault(consolidatedValues?.organizations)}
                defaultValue={consolidatedValues?.organizations?.value ?? null}
                horizontal
                allowCreateEntity
                allowUnassigned
                uncontained
                // showControls
                isMulti
                suggestionsIds={
                  personFullModel && personFullModel.organization ? [personFullModel.organization.id] : undefined
                }
              />
            )}
          </MultiEditHeaderWrapper>
        ),
        accessor: (row, rowIndex, columnIndex) => (
          <div className="flex" style={{ overflow: "auto", width: "100%", height: "100%" }}>
            <MultiEditSelectForm id={`organizations`} entityConstants={organizationsConstants} row={row} isMulti />
          </div>
        ),
        minWidth: 400,
        width: 400,
      },
      {
        id: "default-operators",
        Header: (
          <MultiEditHeaderWrapper<Dataset>
            control={control}
            getValues={getValues}
            ids={selection}
            label="Operators"
            property="operators"
            required={isRequired.current}
            hasApply
            hasAppend
          >
            {(consolidatedValues) => (
              <PersonsVirtualizedSelectForm
                id="operators"
                control={control}
                hasLabel={false}
                placeholder={getValueOrDefault(consolidatedValues?.operators)}
                defaultValue={consolidatedValues?.operators?.value ?? null}
                horizontal
                allowCreateEntity
                allowUnassigned
                uncontained
                // showControls
                isMulti
                suggestionsIds={session?.person.id ? [session?.person.id] : undefined}
              />
            )}
          </MultiEditHeaderWrapper>
        ),
        accessor: (row, rowIndex, columnIndex) => (
          <div className="flex" style={{ overflow: "auto", width: "100%", height: "100%" }}>
            <MultiEditSelectForm
              id={`operators`}
              entityConstants={personsConstants}
              row={row}
              required={isRequired.current}
              isMulti
            />
          </div>
        ),
        minWidth: 400,
        width: 400,
      },
      {
        id: "default-experiment",
        Header: (
          <MultiEditHeaderWrapper<Dataset>
            control={control}
            getValues={getValues}
            ids={selection}
            label="Experiment"
            property="experiment"
            hasApply
          >
            {(consolidatedValues) => (
              <ExperimentsVirtualizedSelectForm
                id="experiment"
                control={control}
                hasLabel={false}
                placeholder={getValueOrDefault(consolidatedValues?.experiment)}
                defaultValue={consolidatedValues?.experiment?.value ?? null}
                horizontal
                allowCreateEntity
                allowUnassigned
                uncontained
                // showControls
              />
            )}
          </MultiEditHeaderWrapper>
        ),
        accessor: (row, rowIndex, columnIndex) => (
          <div className="flex" style={{ overflow: "auto", width: "100%", height: "100%" }}>
            <MultiEditSelectForm id={`experiment`} entityConstants={experimentsConstants} row={row} />
          </div>
        ),
        minWidth: 300,
        width: 300,
        align: "left",
        sortingFn: () => {
          if (sort.headerId === "default-experiment") {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC" }));
              setFilters((prev) => ({ ...prev, orderBy: "EXPERIMENT_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC" }));
              setFilters((prev) => ({ ...prev, orderBy: "EXPERIMENT_ASC" }));
            }
          } else {
            setSort({ headerId: "default-experiment", sortDirection: "ASC" });
            setFilters((prev) => ({ ...prev, orderBy: "EXPERIMENT_ASC" }));
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
      {
        id: "default-eq",
        Header: (
          <MultiEditHeaderWrapper<Dataset>
            control={control}
            getValues={getValues}
            ids={selection}
            label="Suppl. Equipment"
            property="equipments"
            hasApply
            hasAppend
          >
            {(consolidatedValues) => (
              <EquipmentsVirtualizedSelectForm
                id="equipments"
                control={control}
                hasLabel={false}
                placeholder={getValueOrDefault(consolidatedValues?.equipments)}
                defaultValue={consolidatedValues?.equipments?.value ?? null}
                horizontal
                allowCreateEntity
                allowUnassigned
                uncontained
                // showControls
                isMulti
              />
            )}
          </MultiEditHeaderWrapper>
        ),
        accessor: (row, rowIndex, columnIndex) => (
          <div className="flex" style={{ overflow: "auto", width: "100%", height: "100%" }}>
            <MultiEditSelectForm id={`equipments`} entityConstants={equipmentsConstants} row={row} isMulti />
          </div>
        ),
        minWidth: 300,
        width: 300,
        align: "left",
      },
      {
        id: "default-other",
        Header: (
          <MultiEditHeaderWrapper<Dataset>
            control={control}
            getValues={getValues}
            ids={selection}
            label="Other"
            property="other"
            hasApply
            hasAppend
          >
            {(consolidatedValues) => (
              <InputFormField
                id={"other"}
                label={""}
                errors={errors}
                register={register}
                autoFocus={false}
                required={false}
                uncontained
                placeholder={getValueOrDefault(consolidatedValues?.other)}
                defaultValue={consolidatedValues?.other?.value ?? ""}
              />
            )}
          </MultiEditHeaderWrapper>
        ),
        accessor: (row, rowIndex, columnIndex) => (
          <div className="flex" style={{ overflow: "auto", width: "100%", height: "100%" }}>
            <MultiEditInputForm id={`other`} row={row} />
          </div>
        ),
        width: 400,
        align: "left",
      },
      {
        id: "default-notes",
        Header: (
          <MultiEditHeaderWrapper<Dataset>
            control={control}
            getValues={getValues}
            ids={selection}
            label="Notes"
            property="notes"
            hasApply
            hasAppend
          >
            {(consolidatedValues) => (
              <TextareaFormField
                id={"notes"}
                label={""}
                errors={errors}
                register={register}
                autoFocus={false}
                required={false}
                uncontained
                placeholder={getValueOrDefault(consolidatedValues?.notes)}
                defaultValue={consolidatedValues?.notes?.value ?? ""}
                rows={2}
              />
            )}
          </MultiEditHeaderWrapper>
        ),
        accessor: (row, rowIndex, columnIndex) => (
          <div className="flex" style={{ overflow: "auto", width: "100%", height: "100%" }}>
            <MultiEditTextAreaForm id={`notes`} row={row} />
          </div>
        ),
        width: 400,
        align: "left",
      },
    ];

    res.push({
      id: "default-type",
      Header: (
        <MultiEditHeaderWrapper<Dataset>
          control={control}
          getValues={getValues}
          ids={selection}
          label="Dataset Type"
          additionalControls={
            <GenericModalWrapper>
              {({ showModal, setShowModal }) => (
                <>
                  <AlertModal
                    type="success"
                    title="Confirm action"
                    description={`Switching between ${customTypeConstants.entityPlural} can lead to data loss. Please contact your system administrator if you are unsure about the implications.`}
                    showModal={showModal}
                    setShowModal={setShowModal}
                    proceedLabel={"Enable editing"}
                    // onCancel={() => setShowModal(false)}
                    onProceed={() => setTypeOverride(true)}
                    forceUserInput
                    forceUserInputText="EDIT"
                  />
                  {!typeOverride && (
                    <button className="btn btn-sm btn-success" onClick={() => setShowModal(true)}>
                      <LucideIcon name="square-pen" /> Enable editing
                    </button>
                  )}
                </>
              )}
            </GenericModalWrapper>
          }
          property="customType"
          disabled={() => !typeOverride}
          // required
          hasApply
        >
          {(consolidatedValues) => (
            <CustomTypesVirtualizedSelectForm
              id="customType"
              control={control}
              hasLabel={false}
              placeholder={getValueOrDefault(consolidatedValues?.customType)}
              defaultValue={consolidatedValues?.customType?.value ?? null}
              disabled={!typeOverride}
              horizontal
              allowUnassigned
              uncontained
              filters={{ entityTypes: ["Dataset"] }}
              // showControls
            />
          )}
        </MultiEditHeaderWrapper>
      ),
      accessor: (row, rowIndex, columnIndex) => (
        <MultiEditSelectForm
          id={`customType`}
          entityConstants={customTypeConstants}
          row={row}
          required
          disabled={!typeOverride}
          filters={{ entityTypes: ["Dataset"] }}
        />
      ),
      minWidth: 300,
      width: 300,
      sortingFn: (id) => {
        if (sort.headerId === id) {
          if (sort.sortDirection === "ASC") {
            setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "TYPE_DESC" }));
          } else {
            setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "TYPE_ASC" }));
          }
        } else {
          setSort({ headerId: id, sortDirection: "ASC", orderBy: "TYPE_ASC" });
        }
      },
      sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
    });
    if (types) {
      res = res.concat(
        multiEditCustomFieldTableCells({
          types: types,
          control: control,
          register: register,
          getValues: getValues,
          selection: selection,
          disabled: !typeOverride,
        })
      );
    }
    return res;
  }, [
    control,
    errors,
    getValues,
    personFullModel,
    register,
    sampleFullModel,
    types,
    selection,
    session?.person.id,
    sort.headerId,
    sort.sortDirection,
    typeOverride,
  ]);

  const onSelectionChange = useCallback((selection: Set<number>) => {
    setSelection(selection);
  }, []);

  const onSuccessCallback = useCallback(
    (data: Dataset[]) => {
      const _data = Object.fromEntries(Object.entries(data).map(([, d]) => [d.id, d]));
      history.replace({ state: { ...location.state, items: _data } });
    },
    [history, location.state]
  );

  const beforeSubmit = useCallback(
    (data: MultiEditMappedItemObject<Dataset>, claimMode: "claim" | "unclaim") => {
      return Object.fromEntries(
        Object.entries(data).map(([id, d]) => [
          id,
          {
            ...d,
            claimed: claimMode === "claim" ? true : false,
            owner: session?.person ?? null,
            ...(d.customType && types && processValuesForTypedEntity(d, types?.[d.customType.id])),
          },
        ])
      );
    },
    [types, session?.person]
  );

  const beforeInit = useCallback(
    (data: MultiEditMappedItemObject<Dataset>) => {
      return Object.fromEntries(
        Object.entries(data).map(([id, d]) => [
          id,
          {
            ...d,
            customFields: {},
            ...(d.customType && types && processInitialValuesForTypedEntity(d, types?.[d.customType.id])),
          },
        ])
      );
    },
    [types]
  );

  const { onSubmit, loading } = useMultiEditUtils<Dataset>({
    entityConstants: datasetsConstants,
    showToast: true,
    onSuccessCallback: onSuccessCallback,
    beforeReset: beforeInit,
  });

  const { conditionalTypeFormSchema } = useCustomFieldsValidationObjectShape();

  const conditionalDatasetClaimFormSchema = useMemo(
    () => ({
      projects: yup
        .array()
        .of(yup.object().shape({ id: yup.number().required().positive().integer(), name: yup.string().required() }))
        .min(1, "At least one project is required")
        .typeError("At least one project is required"),
      operators: yup
        .array()
        .of(yup.object().shape({ id: yup.number().required().positive().integer(), name: yup.string().required() }))
        .min(1, "At least one operator is required")
        .typeError("At least one operator is required"),
      sample: yup
        .object()
        .shape({ id: yup.number().required().positive().integer(), name: yup.string().required() })
        .typeError("A sample must be defined"),
      method: yup
        .object()
        .shape({ id: yup.number().required().positive().integer(), name: yup.string().required() })
        .strict()
        .required("A method must be defined")
        .typeError("A method must be defined"),
      ...conditionalTypeFormSchema,
    }),
    [conditionalTypeFormSchema]
  );

  const resolverCallback = useCallback(
    (schema: yup.ObjectSchema<any>) => {
      const resolverOverride = async (data: MultiEditMappedItemObject<Dataset>, context: any, options: any) => {
        if (isRequired.current) {
          const _schema = generateBulkEditYupSchema(data, conditionalDatasetClaimFormSchema);
          return await yupResolver(_schema)(data, context, options);
        } else {
          const _schema = generateBulkEditYupSchema(data, conditionalTypeFormSchema);
          return await yupResolver(_schema)(data, context, options);
          // return {
          //   values: data,
          //   errors: {},
          // };
        }
      };
      return resolverOverride;
    },
    [conditionalDatasetClaimFormSchema, conditionalTypeFormSchema]
  );

  if (initItems && !Object.keys(initItems).length && !Object.keys(defaultFilters).length)
    return <Alert type="info" message="Bulk editing is only available to a prior selection" fit centered />;
  return (
    <MultiEditProvider<Dataset>
      resource={datasetsConstants.resource}
      initItems={initItems}
      ids={defaultFilters.ids ?? []}
      entityValidationSchema={conditionalDatasetClaimFormSchema} // A MultiEditMapped version will be passed to the custom resolverCallback
      resolverCallback={resolverCallback} // We inject a custom resolver, as we have to validate conditionally (e.g. claim or edit)
      beforeInit={beforeInit}
      isCustomSchemaEntity
      canAbort
    >
      {({ consolidatedValues }) => (
        <MultiEditTable<Dataset, DatasetFilters>
          entityConstants={datasetsConstants}
          columns={columns}
          filters={filters}
          defaultSelection={defaultFilters.ids ?? undefined}
          onSelectionChange={onSelectionChange}
          onSubmit={onSubmit}
          loading={loading}
          functionRef={functionRef}
          additionalSaveButtons={(handleSubmit, reset, isSubmitting, isDirty, nChanges) => (
            <>
              {/* {consolidatedValues.claimed?.isEqual && consolidatedValues.claimed?.value && (
                <button
                  className={`btn btn-soft-danger ${isSubmitting ? "loading" : ""}`}
                  title="Edit & unclaim"
                  onClick={async () => {
                    await handleSubmit(
                      async (data: any) =>
                        await onSubmit(
                          beforeSubmit(data, "unclaim"),
                          Object.keys(data).length,
                          reset,
                          "edited & unclaimed"
                        )
                    )();
                  }}
                  disabled={isSubmitting}
                >
                  {isSubmitting && <span className="spinner" />} Edit & unclaim {`${isDirty ? `(${nChanges})` : ""}`}
                </button>
              )} */}
              {consolidatedValues.claimed?.isEqual && !consolidatedValues.claimed?.value && (
                <Button
                  className={`btn btn-primary ${isSubmitting ? "loading" : ""}`}
                  title="Edit & claim"
                  onClick={async () => {
                    // We trigger validation for claiming
                    isRequired.current = true;
                    await handleSubmit(
                      async (data: any) =>
                        await onSubmit(beforeSubmit(data, "claim"), Object.keys(data).length, reset, "edited & claimed")
                    )();
                    isRequired.current = false;
                  }}
                  disabled={isSubmitting}
                  loading={loading}
                >
                  {isSubmitting && <span className="spinner" />} Edit & claim {`${isDirty ? `(${nChanges})` : ""}`}
                </Button>
              )}
            </>
          )}
          fixedRowRendererColumnWidth={fixedRowRendererColumnWidth}
          fixedRowRenderer={fixedRowRenderer}
          fixedRowRendererColumn={fixedRowRendererColumn}
        />
      )}
    </MultiEditProvider>
  );
};
