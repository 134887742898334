import { StringIndexedDict } from "../../../../api/GenericTypes";
import {
  GenericEntityVirtualizedEntitySelectFormFieldProps,
  GenericVirtualizedEntitySelectFormFieldControllerWrapper,
} from "../../../formfields/GenericVirtualizedSelectFormField/GenericVirtualizedEntitySelectFormFieldControllerWrapper";
import { QuickAddWrapper } from "../../QuickAdd/common/QuickAddWrapper";
import {
  ProjectTag,
  ProjectTagFilters,
  ProjectTagSortingOptionsConsts,
  ProjectTagSuggestions,
  projectTagsConstants,
} from "../../../../api/ProjectTag";
import { QuickAddProjectTagForm } from "../../QuickAdd/forms/QuickAddProjectTagForm";
import { useContext } from "react";
import { SessionContext } from "../../../contexts/SessionContext";

export const ProjectsTagsVirtualizedSelectForm = <Entity extends StringIndexedDict>(
  props: GenericEntityVirtualizedEntitySelectFormFieldProps<Entity, ProjectTagSuggestions, ProjectTagFilters>
) => {
  const { session } = useContext(SessionContext);

  return (
    <GenericVirtualizedEntitySelectFormFieldControllerWrapper<Entity, ProjectTagSuggestions, ProjectTagFilters>
      {...props}
      filters={{ orderBy: "NAME_ASC", ...props.filters }}
      //   onRowRenderer={(item) => <></>}
      createModal={({ showModal, setShowModal, onCreate }) => (
        <QuickAddWrapper<ProjectTag> showModal={showModal} setShowModal={setShowModal} onCreate={onCreate}>
          {(props) => <QuickAddProjectTagForm {...props} />}
        </QuickAddWrapper>
      )}
      entityConstants={projectTagsConstants}
      orderByOptions={ProjectTagSortingOptionsConsts}
      allowCreateEntity={
        (session?.permissions.administer_projects || session?.permissions.add_projects) && props.allowCreateEntity
      }
    />
  );
};
