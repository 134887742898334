import { useEffect, useState } from "react";
import produce from "immer";
import { Button, FormControl } from "react-bootstrap";
import { Range } from "react-range";
import { useInputDebounce } from "../../ViewerUIElements/InputDebounce";
import { getDigit, RangeType } from "./ContourEditor";
import { ContourType } from "./OneBaselineContourEditor";

import styles from "./OneBaselineContourEditor.module.css";
import { SliderThumbBlock, SliderTrack } from "./SliderElements";

const step = 0.001;

const getOffsetsFromContours = (contours: ContourType, range: RangeType): number[] => {
  if (!contours) return [];

  const offset = (v: number) => {
    const r = (v - range.min) / range.diff;
    if (r > 1) return 1;
    if (r < 0) return 0;
    return r;
  };

  return [offset(contours.scale)];
};

const getContoursFromOffsets = (offsets: number[], range: RangeType): number => {
  const contour = (v: number) => range.min + v * range.diff;
  return contour(offsets[0]);
};

type stateType = { state: boolean; value: number };
const validateValue = (value: string, range: RangeType): stateType => {
  let f = parseFloat(value);
  const state: stateType = { value: f, state: true };
  if (isNaN(f) || f < range.min || f > range.max) state.state = false;
  return state;
};

export const OneBaselineConstantOffset = ({
  contours,
  setContours,
  setMinHeight,
}: {
  contours: ContourType;
  setContours: (contours: ContourType) => void;
  setMinHeight?: (height: number) => void;
}) => {
  const [minMaxStr, setMinMaxStr] = useState<string[]>(["", ""]);
  const [countInput, setCountInput] = useState<string>("");
  const [countValid, setCountValid] = useState<boolean>(true);
  const [offsetRange, setOffsetRange] = useState<RangeType>({ diff: 0, min: step, max: 1 });

  const debouncedInput = useInputDebounce<string>(countInput, 500);

  useEffect(() => {
    if (setMinHeight) setMinHeight(240);
  }, []);

  useEffect(() => {
    // const s = getOffsetsFromContours(contours);
    // if (Math.abs(scale[0] - s[0]) >= step) setScale(s);

    const diff = contours.range.diff / 2;
    const digit = getDigit(diff);

    const range = {
      min: step * diff,
      max: diff,
    } as RangeType;
    range.diff = range.max - range.min;

    setOffsetRange(range);

    // setMinMaxStr([(diff * step).toFixed(digit), diff.toFixed(digit)]);
    setMinMaxStr([range.min.toFixed(digit), range.max.toFixed(digit)]);

    // console.log("offset range", range);

    let scale = contours.scale;
    if (scale < range.min) scale = range.min;
    const input = "" + scale.toFixed(digit + 1);
    // console.log(">>", input, countInput);
    if (input !== countInput) setCountInput("" + scale.toFixed(digit + 1));
    setCountValid(true);
  }, [contours]);

  useEffect(() => {
    const valid = validateValue(countInput, offsetRange);
    // console.log("validate", countInput, contours.range.min, contours.range.max);
    setCountValid(valid.state);
    if (valid.state)
      setContours(
        produce(contours, (next) => {
          next.scale = valid.value;
        })
      );
  }, [debouncedInput]);

  return (
    <div>
      <div className={styles.sliderText}>
        Direction:{" "}
        <Button
          bsSize="xs"
          onClick={() => {
            setContours(
              produce(contours, (next) => {
                next.direction = contours.direction < 0 ? 1 : -1;
              })
            );
          }}
        >
          <span
            className={
              "glyphicon " +
              (contours.direction < 0
                ? "glyphicon-arrow-left"
                : contours.direction > 0
                ? "glyphicon-arrow-right"
                : "glyphicon-resize-horizontal")
            }
          />
        </Button>
      </div>
      <div className={styles.rangeSetter}>
        <div className={styles.settingText} style={{ position: "relative", top: -10 }}>
          Offset:
        </div>

        <div style={{ flexGrow: 1, minWidth: 30 }}>
          <Range
            step={step}
            min={0}
            // disabled={disabled}
            max={1}
            values={getOffsetsFromContours(contours, offsetRange)}
            renderTrack={SliderTrack}
            onChange={(scale: number[]) =>
              setContours(
                produce(contours, (next) => {
                  next.scale = getContoursFromOffsets(scale, offsetRange);
                })
              )
            }
            // onChange={setScale}
            renderThumb={SliderThumbBlock}
          />
          <div className={styles.range}>
            <span>{minMaxStr[0]}</span>
            <span>{minMaxStr[1]}</span>
          </div>
        </div>
        <div style={{ paddingLeft: 5, position: "relative", top: -10 }}>
          <FormControl
            type="text"
            value={countInput}
            onChange={(e) => setCountInput((e.target as HTMLInputElement).value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") setCountInput((e.target as HTMLInputElement).value);
            }}
            onFocus={(e) => (e.target as any as HTMLInputElement).select()}
            bsSize={"sm"}
            style={{ width: "6em", scale: "90%", background: countValid ? undefined : "salmon" }}
          />
        </div>
      </div>
    </div>
  );
};
