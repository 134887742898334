import path from "path";
import { useCallback, useReducer, useState } from "react";
import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { FilesStructure } from "../../api/Datasets";
import { v4 as uuidv4 } from "uuid";
import {
  DatasetResult,
  FormatFileEntryResult,
  MatchResultStats,
  MatchResults,
  UploadedFile,
  useMatch,
} from "./FileFormatAutoDetection";
import { showtoast } from "../../common/overlays/Toasts/showtoast";

export interface LoadingStatus {
  message: string;
  progress?: number;
  errors?: string;
}

interface useFileHandlingUtilsProps {
  parserWhiteList: string[] | readonly string[];
  parserBlackList: string[] | readonly string[];
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  transientHasher?: boolean;
}

export type ManualUploadActions =
  | { type: "add"; actionUUID: string; sourceDir: string; results: MatchResults }
  | { type: "setBundle"; actionUUID: string; parserId: string; results: DatasetResult[] }
  | { type: "setBundleStatus"; actionUUID: string; parserId: string; status: boolean }
  | { type: "removeBundle"; actionUUID: string }
  | {
      type: "setBundleDataset";
      actionUUID: string;
      parserId: string;
      id: string;
      status?: DatasetResult["status"];
      progress?: DatasetResult["progress"];
      error?: DatasetResult["error"];
      datasetId?: DatasetResult["datasetId"];
      metaData?: DatasetResult["metadata"];
      queued?: DatasetResult["queued"];
    }
  | { type: "clear" };

export interface AnalysisResults {
  [actionUUID: string]: MatchResults;
}
const isObject = (obj: any) => obj && typeof obj === "object";
//  Generics
const mergeDeep = (...objects: any) => {
  return objects.reduce((prev: any, obj: any) => {
    Object.keys(obj).forEach((key) => {
      const pVal = prev[key];
      const oVal = obj[key];
      if (Array.isArray(pVal) && Array.isArray(oVal)) {
        prev[key] = pVal.concat(...oVal);
      } else if (isObject(pVal) && isObject(oVal)) {
        prev[key] = mergeDeep(pVal, oVal);
      } else {
        prev[key] = oVal;
      }
    });
    return prev;
  }, {});
};

export const flat2Hierarchy = (bundle: FormatFileEntryResult[]) => {
  let hierarchy: FilesStructure = {};
  bundle.forEach((file) => {
    const paths = file.parent.split(path.sep).filter((p) => p);
    const thisHierarchy = paths.reduceRight((r: any, k) => ({ [k]: { name: k, contents: r, isDirectory: true } }), {
      [file.name]: {
        hash: file.sha256,
        size: file.file?.size,
        mtime: file.file ? file.file.lastModified / 1000 : undefined, //To be consistent with mtime from the backend
        uniqueId: file.id,
        state: file.state,
      },
    });
    hierarchy = mergeDeep(hierarchy, thisHierarchy);
  });
  return hierarchy;
};

const isEndOfFile = (file: File, offset: number) => offset >= file.size;

export const previewFile = async (file: File, offset: number): Promise<Uint8Array> => {
  const chunkSize = 128000; // 128 kB
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    if (isEndOfFile(file, offset)) resolve(new Uint8Array());
    const blob = file.slice(offset, chunkSize + offset);
    reader.readAsArrayBuffer(blob);
    reader.onloadend = (event) => {
      const target = event.target;
      if (target) {
        resolve(new Uint8Array(target.result as ArrayBuffer));
      }
    };
    reader.onerror = reject;
  });
};

const getStats = (datasets: DatasetResult[]): MatchResultStats => {
  return {
    new: datasets.filter((d) => d.status && d.status === "new").length,
    success: datasets.filter((d) => d.status && d.status === "created").length,
    updated: datasets.filter((d) => d.status && d.status === "updated").length,
    error: datasets.filter((d) => d.status && d.status === "error").length,
    known: datasets.filter((d) => d.status && d.status === "known").length,
    pending: datasets.filter((d) => d.queued).length,
    update: datasets.filter((d) => d.status && d.status === "update").length,
    warning: datasets.filter((d) => d.status && d.status === "warning").length,
  };
};

export const useFileHandlingUtils = ({
  parserWhiteList,
  parserBlackList,
  setLoading,
  transientHasher,
}: useFileHandlingUtilsProps) => {
  const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>();
  // Match util
  const { analyzeFiles, calculateSHA256, parsers } = useMatch({ parserWhiteList, parserBlackList, transientHasher });
  // Reducer
  const reducer = useCallback((state: AnalysisResults, action: ManualUploadActions) => {
    switch (action.type) {
      case "add":
        return {
          ...state,
          [action.actionUUID]: action.results,
        };
      case "removeBundle":
        if (state.hasOwnProperty(action.actionUUID)) {
          return Object.fromEntries(Object.entries(state).filter(([actionUUID]) => actionUUID !== action.actionUUID));
        } else return state;
      case "setBundleDataset":
        if (state.hasOwnProperty(action.actionUUID) && state[action.actionUUID].hasOwnProperty(action.parserId)) {
          const indexes = state[action.actionUUID][action.parserId].datasets.reduce(
            (a: number[], e, i) => (e.id === action.id ? a.concat(i) : a),
            []
          );
          if (Array.isArray(indexes) && indexes.length > 0) {
            const _state = { ...state };
            indexes.forEach((idx) => {
              _state[action.actionUUID][action.parserId].datasets[idx] = {
                ..._state[action.actionUUID][action.parserId].datasets[idx],
                status: action.status ?? _state[action.actionUUID][action.parserId].datasets[idx].status,
                progress: action.progress ?? _state[action.actionUUID][action.parserId].datasets[idx].progress,
                error: action.error ?? _state[action.actionUUID][action.parserId].datasets[idx].error,
                datasetId: action.datasetId ?? _state[action.actionUUID][action.parserId].datasets[idx].datasetId,
                metadata: action.metaData ?? _state[action.actionUUID][action.parserId].datasets[idx].metadata,
                queued: action.queued ?? _state[action.actionUUID][action.parserId].datasets[idx].queued,
              };
            });
            _state[action.actionUUID][action.parserId].stats = getStats(
              _state[action.actionUUID][action.parserId].datasets
            );
            return _state;
          } else {
            return state;
          }
        } else {
          return state;
        }
      case "setBundle":
        if (state.hasOwnProperty(action.actionUUID) && state[action.actionUUID].hasOwnProperty(action.parserId)) {
          const _state = { ...state };
          _state[action.actionUUID][action.parserId].datasets = action.results;
          _state[action.actionUUID][action.parserId].stats = getStats(
            _state[action.actionUUID][action.parserId].datasets
          );
          return _state;
        } else {
          return state;
        }
      case "setBundleStatus":
        if (state.hasOwnProperty(action.actionUUID) && state[action.actionUUID].hasOwnProperty(action.parserId)) {
          const _state = { ...state };
          _state[action.actionUUID][action.parserId].checked = action.status;
          return _state;
        } else {
          return state;
        }
      case "clear":
        setLoadingStatus(undefined);
        return {};
    }
  }, []);
  const [results, dispatch] = useReducer(reducer, {});

  // Async dispatch
  const asyncDispatch = useCallback(
    async (actionUUID: string, sourceDir: string, files: UploadedFile[]) => {
      setLoading(true);
      // API pattern matching and fingerprinting
      const matchingResult = await analyzeFiles(files, setLoadingStatus).catch((err) => {
        showtoast("error", `Error: ${err && err.hasOwnProperty("message") ? err.message : err}`);
        console.error("ERROR: ", err);
        setLoading(false);
      });
      // console.log("MATCHING RESULT", matchingResult);
      if (matchingResult && Object.keys(matchingResult).length > 0) {
        dispatch({ type: "add", actionUUID: actionUUID, sourceDir: sourceDir, results: matchingResult });
      } else {
        setLoadingStatus({
          message: `No matching result / unknown file format.`,
          progress: 100,
          errors: `No matching result / unknown file format.`,
        });
        setLoading(false);
      }
      setLoading(false);
    },
    [analyzeFiles, setLoading]
  );

  const readFileList = useCallback(async (fileList: FileList): Promise<UploadedFile[]> => {
    let files: UploadedFile[] = [];
    const _fileList = Array.from(fileList);
    const _fileList_length = _fileList.length;
    for (const [index, file] of Object.entries(_fileList)) {
      setLoadingStatus({
        message: `Reading ${+index + 1}/${_fileList_length} files...`,
        progress: Math.floor(((+index + 1) * 100) / _fileList_length),
      });
      // There is a bug (probably with Ubuntu) where the filename has a strange suffix at the end
      // This regex removes the suffix
      var regex = /([-+]?\d+:[-+]?\d+:[-+]?\d+:[-+]?\d+)$/;
      var fullPath = file.webkitRelativePath ? file.webkitRelativePath : file.name;
      var formattedPath = fullPath.replace(regex, "");
      files.push({
        file: file,
        id: uuidv4(),
        fullPath: formattedPath,
        name: file.name,
        parent: file.webkitRelativePath.split(path.sep).slice(0, -1).join(path.sep),
      });
    }
    return files;
  }, []);

  const readFileSystemFileEntry = useCallback(async (file: FileSystemFileEntry): Promise<UploadedFile> => {
    const jsFile = (await new Promise((resolve, reject) => file.file(resolve, reject))) as File;
    return {
      file: jsFile,
      id: uuidv4(),
      fullPath: file.fullPath.substring(1),
      name: file.name,
      parent: file.fullPath.split(path.sep).slice(0, -1).join(path.sep),
    };
  }, []);

  const readFileSystemDirectoryEntry = useCallback((dir: FileSystemDirectoryEntry): Promise<FileSystemFileEntry[]> => {
    const dirReader = dir.createReader();
    return new Promise((resolve, reject) => {
      let entries: any[] = [];
      const getEntries = () => {
        dirReader.readEntries(
          (results) => {
            if (!results.length) {
              resolve(Promise.all(entries));
            } else {
              const res = Promise.all(
                results.map((result) => {
                  if (result.isFile) {
                    // FILE
                    return result as FileSystemFileEntry;
                  } else {
                    // DIRECTORY
                    return readFileSystemDirectoryEntry(result as FileSystemDirectoryEntry);
                  }
                })
              );
              entries.push(res);
              getEntries();
            }
          },
          (error) => {
            reject(error);
          }
        );
      };
      getEntries();
    });
  }, []);

  const readDataTransferItem = useCallback(
    async (item: DataTransferItem): Promise<UploadedFile[]> => {
      return new Promise(async (resolve, reject) => {
        const files: UploadedFile[] = [];
        if (typeof item.webkitGetAsEntry !== "function") {
          //Safari support
          const file = item.getAsFile();
          if (file) {
            // There is a bug (probably with Ubuntu) where the filename has a strange suffix at the end
            // This regex removes the suffix
            var regex = /([-+]?\d+:[-+]?\d+:[-+]?\d+:[-+]?\d+)$/;
            var fullPath = file.webkitRelativePath.substring(1);
            var formattedPath = fullPath.replace(regex, "");
            files.push({
              file: file,
              id: uuidv4(),
              fullPath: formattedPath,
              name: file.name,
              parent: file.webkitRelativePath.split(path.sep).slice(0, -1).join(path.sep),
            });
          }
        } else {
          const entry = item.webkitGetAsEntry();
          if (entry) {
            if (entry.isDirectory) {
              const dir = entry as FileSystemDirectoryEntry;
              const entries = await readFileSystemDirectoryEntry(dir);
              const _entries = entries.flat(Infinity);
              for (const file of _entries) {
                const _file = await readFileSystemFileEntry(file);
                files.push(_file);
              }
            } else if (entry.isFile) {
              const file = entry as FileSystemFileEntry;
              const _file = await readFileSystemFileEntry(file);
              files.push(_file);
            } else {
              console.error("An invalid drag type was used. Please use the buttons to upload data.");
              reject("An invalid drag type was used. Please use the buttons to upload data.");
            }
          } else {
            console.error("FileAPI not supported by browser");
            reject("FileAPI not supported by browser");
          }
        }
        resolve(files);
      });
    },
    [readFileSystemDirectoryEntry, readFileSystemFileEntry]
  );

  const readDataTransfer = useCallback(
    async (dt: DataTransfer) => {
      setLoading(true);
      const items = Array.from(dt.items);
      const n_items = items.length;
      // const files: UploadedFile[] = [];
      const _files = [];
      for (let i = 0; i < items.length; i++) {
        const file = items[i];
        _files.push(file);
      }
      // console.log("DATATRANSFER", dt, _files);
      if (dt.types.includes("Files")) {
        const filesResult = await Promise.all(
          Object.entries(_files).map(([, item], index) => {
            // console.log("ITEM", item);
            setLoadingStatus({
              message: `Reading ${+index + 1}/${n_items} entries...`,
              progress: Math.floor(((+index + 1) * 100) / n_items),
            });
            return readDataTransferItem(item);
          })
        );
        const files = filesResult.reduce((a, b) => a.concat(b), []);
        // console.log("FILES", files);
        // for (const [index, item] of Object.entries(_files)) {
        //   console.log("ITEM", item);
        //   setLoadingStatus({
        //     message: `Reading ${+index + 1}/${n_items} entries...`,
        //     progress: Math.floor(((+index + 1) * 100) / n_items),
        //   });
        //   if (typeof item.webkitGetAsEntry !== "function") { //Safari support
        //     const file = item.getAsFile();
        //     if (file) {
        //       files.push({
        //         file: file,
        //         id: uuidv4(),
        //         fullPath: file.webkitRelativePath.substring(1),
        //         name: file.name,
        //         parent: file.webkitRelativePath.split(path.sep).slice(0, -1).join(path.sep),
        //       });
        //     }
        //   } else {
        //     const entry = item.webkitGetAsEntry();
        //     if (entry) {
        //       if (entry.isDirectory) {
        //         const dir = entry as FileSystemDirectoryEntry;
        //         const entries = await readFileSystemDirectoryEntry(dir);
        //         const _entries = entries.flat(Infinity);
        //         for (const file of _entries) {
        //           const _file = await readFileSystemFileEntry(file);
        //           files.push(_file);
        //         }
        //       } else if (entry.isFile) {
        //         const file = entry as FileSystemFileEntry;
        //         const _file = await readFileSystemFileEntry(file);
        //         files.push(_file);
        //       } else {
        //         console.error("An invalid drag type was used. Please use the buttons to upload data.");
        //       }
        //     } else {
        //       console.error("FileAPI not supported by browser");
        //     }
        //   }

        // }
        const actionUUID = uuidv4();
        await asyncDispatch(actionUUID, actionUUID, files).catch((e) => {});
      } else {
        showtoast("error", `Drag data of type ${dt.types} not supported.`);
      }
      setLoading(false);
    },
    [asyncDispatch, readDataTransferItem, setLoading]
  );
  return {
    results,
    dispatch,
    asyncDispatch,
    loadingStatus,
    setLoadingStatus,
    readDataTransfer,
    readDataTransferItem,
    readFileList,
    parsers,
    calculateSHA256,
  };
};

export const useLocalFilePreview = (
  file: File,
  offset: number,
  options?: UseQueryOptions<any, unknown, Uint8Array>
) => {
  return useQuery({
    queryKey: ["localFile", file, offset],
    queryFn: async () => {
      if (file) {
        return await previewFile(file, offset);
      } else {
        return Promise.reject(new Error("File not found"));
      }
    },
    ...options,
  });
};
