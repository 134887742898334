import React from "react";
import { LucideIcon } from "../../../common/icon/LucideIcon";
import styles from "./RenderBundlePlaceholder.module.css";

interface RenderBundlePlaceholderProps {
  currentStep: number;
}
export const RenderBundlePlaceholder = ({ currentStep = 1 }: RenderBundlePlaceholderProps) => {
  const steps = [
    {
      label: (
        <div className="flex row-nowrap align-center gap-5" style={{ overflow: "hidden" }}>
          <div>
            <LucideIcon name="plus" />
          </div>
          <span className={styles.container_step_label_span}>Add Files</span>
        </div>
      ),
    },
    {
      label: (
        <div className="flex row-nowrap align-center gap-5" style={{ overflow: "hidden" }}>
          <div>
            <LucideIcon name="check" />
          </div>
          <span className={styles.container_step_label_span}>Choose Data Format</span>
        </div>
      ),
    },
    {
      label: (
        <div className="flex row-nowrap align-center gap-5" style={{ overflow: "hidden" }}>
          <div>
            <LucideIcon name="square-check-big" />
          </div>
          <span className={styles.container_step_label_span}>Select & Configure</span>
        </div>
      ),
    },
    {
      label: (
        <div className="flex row-nowrap align-center gap-5" style={{ overflow: "hidden" }}>
          <div>
            <LucideIcon name="upload" />
          </div>
          <span className={styles.container_step_label_span}>Upload datasets</span>
        </div>
      ),
    },
  ];

  return (
    <div
      className="flex row-nowrap align-center"
      style={{
        height: "fit-content",
        padding: "2px 5px",
        justifyContent: "space-evenly",
        overflow: "hidden",
      }}
    >
      <div className={styles.container_steps}>
        {steps.map((step, index) => (
          <React.Fragment key={index}>
            <div className={styles.container_step}>
              <div
                className={`${styles.container_step_index} ${
                  index < currentStep && styles.container_step_index_current
                }`}
              >
                <span>{index + 1}</span>
              </div>
              <div
                className={`${styles.container_step_label} ${
                  index < currentStep && styles.container_step_label_current
                }`}
              >
                {step.label}
              </div>
            </div>

            {index < steps.length - 1 && (
              <div
                className={`${styles.container_step_line} ${
                  currentStep > index && styles.container_step_line_current
                } ${index < currentStep - 1 && styles.container_step_line_past} ${
                  currentStep === steps.length && styles.container_step_line_past
                }`}
              ></div>
            )}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};
