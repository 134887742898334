import {
  autoUpdate,
  flip,
  FloatingFocusManager,
  FloatingPortal,
  offset,
  ReferenceType,
  size,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useRole,
  // useFloatingNodeId,
  // FloatingNode,
  // FloatingTree,
  UseFloatingReturn,
} from "@floating-ui/react";
import { useEffect, useState } from "react";
import { zIndex } from "../../../api/CommonConstants";

const overflowPadding = 30;

interface FloatingDivProps {
  defaultOpen?: boolean;
  enabled?: boolean;
  disableToggle?: boolean;
  onOpenChange?: (open: boolean) => void;
  outsidePress?: (event: MouseEvent) => boolean;
  triggerComponent: <T extends ReferenceType>(props: {
    context: UseFloatingReturn<T>["context"];
    ref: ((node: ReferenceType | null) => void) & ((node: T | null) => void);
    getReferenceProps: (userProps?: React.HTMLProps<Element> | undefined) => Record<string, unknown>;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    isOpen: boolean;
  }) => React.ReactNode;
  children: ({
    open,
    setOpen,
  }: // context,
  {
    // context: UseFloatingReturn<T>["context"];
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  }) => React.ReactNode;
}
export const FloatingDiv = <T extends HTMLElement>({
  defaultOpen = false,
  enabled = true,
  disableToggle,
  onOpenChange,
  outsidePress,
  triggerComponent,
  children,
}: FloatingDivProps) => {
  const [open, setOpen] = useState(defaultOpen);
  const [pointer, setPointer] = useState(false);

  if (!open && pointer) {
    setPointer(false);
  }

  useEffect(() => {
    setOpen(defaultOpen);
  }, [defaultOpen]);

  useEffect(() => {
    onOpenChange?.(open);
  }, [onOpenChange, open]);

  const { refs, floatingStyles, context } = useFloating<T>({
    open,
    onOpenChange: setOpen,
    whileElementsMounted: autoUpdate,
    placement: "bottom",
    middleware: [
      offset(5),
      flip({ padding: overflowPadding }),
      size({
        padding: overflowPadding,
      }),
    ],
  });

  const click = useClick(context, { toggle: !disableToggle, enabled: enabled });
  const role = useRole(context, { role: "listbox" });
  const dismiss = useDismiss(context, {
    outsidePress: outsidePress,
  });

  const { getReferenceProps } = useInteractions([click, role, dismiss]);

  return (
    <>
      {triggerComponent({
        context: context,
        ref: refs.setReference,
        getReferenceProps: getReferenceProps,
        setOpen: setOpen,
        isOpen: open,
      })}

      {open && (
        <FloatingPortal>
          <FloatingFocusManager context={context} modal={false} initialFocus={refs.floating} order={["reference"]}>
            <div
              ref={refs.setFloating}
              tabIndex={-1}
              style={{
                outline: "none",
                ...floatingStyles,
                zIndex: zIndex.zIndexPortalDropdown,
              }}
            >
              {children({
                open: open,
                setOpen: setOpen,
              })}
            </div>
          </FloatingFocusManager>
        </FloatingPortal>
      )}
    </>
  );
};
