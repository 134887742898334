import { API } from "../../api/Api";
import Status from "../../common/badges/Status/Status";
import { ConnectionStatus } from "../../common/signalr/useLiveConnection";

export const getStatusCls = (status: ConnectionStatus) => {
  switch (status) {
    case "idle":
      return "warning";
    case "connecting":
      return "success";
    case "connected":
      return "success";
    case "reconnecting":
      return "secondary";
    case "disconnected":
      return "danger";
  }
};

export const clientStatus = (status: ConnectionStatus) => {
  switch (status) {
    case "idle":
      return (
        <Status type={getStatusCls(status)} idle title={status}>
          Idle
        </Status>
      );
    case "connecting":
      return (
        <Status type={getStatusCls(status)} idle title={status}>
          Connecting
        </Status>
      );
    case "connected":
      return (
        <Status type={getStatusCls(status)} title={status}>
          Connected
        </Status>
      );
    case "reconnecting":
      return (
        <Status type={getStatusCls(status)} idle title={status}>
          Reconnecting
        </Status>
      );
    case "disconnected":
      return (
        <Status type={getStatusCls(status)} title={status}>
          Disconnected
        </Status>
      );
  }
};

export type AutoloadClientOsType = "linux" | "osx" | "win" | "win32";

export const getAutoloadClientDownloadURL = (os: AutoloadClientOsType, api: API) => {
  const { hostname, port, protocol } = api.getUrlComponents();
  return `${protocol}//${hostname}:${port}/${api.getGroup()}/api/0.1/autoload_client_api/download_client_zip?platform=${os}`;
};
