import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  EntityConstants,
  GenericEntity,
  IEntityPermissions,
  IGenericRequestParameters,
  StringIndexedDict,
} from "../../../api/GenericTypes";
import { UseTabActions } from "../Tabs/TableTabsTypes";
import { GenericVirtualizedTableFunctionRef, SortState } from "./GenericVirtualizedTableTypes";
import { useDebouncedValue } from "../../helperfunctions/useDebouncedValue";
import { ColumnsSettings } from "../ColumnsSelector/ColumnsSelector";
import {
  EntityByEntityTypeId,
  FiltersByEntityTypeId,
  GenericEntityConstant,
  GenericEntityConstantsEntities,
} from "../../../api/GenericConstants";

export interface UseEntityTableDefaultProps<EntityTypeId extends GenericEntityConstantsEntities> {
  fieldLabels: GenericEntityConstant<EntityTypeId>["fieldLabels"];
}
export interface UseEntityTableProps<EntityTypeId extends GenericEntityConstantsEntities>
  extends UseEntityTableDefaultProps<EntityTypeId> {
  entityConstants: EntityConstants<EntityByEntityTypeId<EntityTypeId>, FiltersByEntityTypeId<EntityTypeId>>;
  sort: SortState<FiltersByEntityTypeId<EntityTypeId>["orderBy"]>;
  setSort: React.Dispatch<React.SetStateAction<SortState<FiltersByEntityTypeId<EntityTypeId>["orderBy"]>>>;
}

export interface UseITypedEntityTableProps<EntityTypeId extends GenericEntityConstantsEntities>
  extends UseEntityTableProps<EntityTypeId> {
  defaults: ColumnsSettings<EntityByEntityTypeId<EntityTypeId>>;
  filters: FiltersByEntityTypeId<EntityTypeId>;
}

/**
 * Custom hook to manage the state of a generic virtualized table.
 */
export const useGenericVirtualizedTable = <Entity extends GenericEntity>() => {
  const [selection, setSelection] = useState<Set<Entity["id"]>>(new Set()); // Selected elements in the table will be held here
  const [resultsCount, setResultsCount] = useState<number>();
  const [selectionPermissions, setSelectionPermissions] = useState<IEntityPermissions>();

  const onCountChange = useCallback((count: number | undefined) => {
    setResultsCount(count);
  }, []);

  const onSelectionChange = useCallback((selection: Set<Entity["id"]>) => {
    setSelection(selection);
  }, []);

  const onSelectionPermissions = useCallback((permissions?: IEntityPermissions) => {
    setSelectionPermissions(permissions);
  }, []);

  return {
    selection,
    resultsCount,
    selectionPermissions,
    onCountChange,
    onSelectionChange,
    onSelectionPermissions,
  };
};

/**
 * Custom hook to manage the state of a generic virtualized table tabs.
 */
interface UseGenericVirtualizedTableTabsProps<
  Entity extends GenericEntity,
  Filters extends StringIndexedDict & IGenericRequestParameters<Entity, Filters["orderBy"]>,
  FilterForm extends StringIndexedDict
> {
  tabsLoading: boolean;
  filters: Filters;
  switchSortState: (sortState: Filters["orderBy"]) => SortState<Filters["orderBy"]>;
  dispatchTabStore: (action: UseTabActions<Entity, Filters, FilterForm>) => Promise<void>;
}
export const useGenericVirtualizedTableTabs = <
  Entity extends GenericEntity,
  Filters extends StringIndexedDict & IGenericRequestParameters<Entity, Filters["orderBy"]>,
  FilterForm extends StringIndexedDict
>({
  tabsLoading,
  filters,
  switchSortState,
  dispatchTabStore,
}: UseGenericVirtualizedTableTabsProps<Entity, Filters, FilterForm>) => {
  const functionRef = useRef<GenericVirtualizedTableFunctionRef<Entity>>(null);
  const [sort, setSort] = useState<SortState<Filters["orderBy"]>>(switchSortState(filters.orderBy));
  const [searchValue, setSearchValue] = useState(""); //SearchTerm
  const debouncedSearchValue = useDebouncedValue(searchValue); // Debounced searchTerm used for filtering

  const onTabChange = useCallback(
    async (currentTabId: string) => {
      await dispatchTabStore({ type: "setCurrentTab", tabId: currentTabId });
      functionRef.current?.resetSelection();
    },
    [dispatchTabStore]
  );

  useEffect(() => {
    if (!tabsLoading)
      dispatchTabStore({
        type: "setTab",
        payload: { settings: { filters: { searchTerm: debouncedSearchValue } as Filters } },
        options: { keepPrevious: true },
      });
  }, [debouncedSearchValue, dispatchTabStore, tabsLoading]);

  useEffect(() => {
    if (sort.orderBy && !tabsLoading) {
      dispatchTabStore({
        type: "setTab",
        payload: { settings: { filters: { orderBy: sort.orderBy } as Filters } },
        options: { keepPrevious: true },
      });
    }
  }, [dispatchTabStore, sort.orderBy, tabsLoading]);

  useEffect(() => {
    setSearchValue(filters.searchTerm ?? "");
  }, [filters.searchTerm]);

  // Hook to update searchTerm or orderBy if filter changes; (e.g. triggered by the sidebar)
  useMemo(() => {
    if (filters.searchTerm !== undefined) {
      setSearchValue(filters.searchTerm || "");
    }
    if (filters.orderBy !== undefined) {
      setSort(switchSortState(filters.orderBy));
    }
  }, [filters.searchTerm, filters.orderBy, switchSortState]);

  return { functionRef, onTabChange, sort, setSort, searchValue, setSearchValue, debouncedSearchValue };
};
