import React from "react";
import { Redirect, useParams } from "react-router-dom";
import { SingleEntityUUIDRoutingParams } from "../common/entity/EntityInterfaces";
import { useGet } from "../api/BaseEntityApi";
import { LoadingWrapper } from "../common/LoadingWrapper";
import { Alert } from "../common/overlays/Alert/Alert";
import { EntityMinimalModelWithType } from "../api/ApiTypes";
import { useEntitiesRedirect } from "./useEntitiesRedirect";
import { entitiesConstants } from "../api/Entities";

export const EntitiesRedirect = () => {
  const { uuid } = useParams<SingleEntityUUIDRoutingParams>();

  const { data, status, fetchStatus, error } = useGet<EntityMinimalModelWithType>(
    `${entitiesConstants.resource}/${uuid}`,
    null,
    {
      enabled: !!uuid,
    }
  );

  const redirectByUUID = useEntitiesRedirect();

  if (!uuid) return <Alert type="info" message={`No UUID provided`} fit centered />;
  return (
    <LoadingWrapper status={status} fetchStatus={fetchStatus} error={error}>
      {data ? (
        <Redirect to={redirectByUUID(data)} />
      ) : (
        <Alert type="info" message={`No ${entitiesConstants.entityPlural} found with UUID: ${uuid}`} fit centered />
      )}
    </LoadingWrapper>
  );
};
