import { DateTimeRenderer } from "../../datetime/DateTimeFormatter";
import { EntitySidebarTile, EntitySidebarTileProps } from "./EntitySidebarTile/EntitySidebarTile";
import { NotSet } from "../../misc/UIconstants";
import {
  CustomType,
  CustomTypeFilters,
  CustomTypeFiltersTranslator,
  CustomTypeSortingOptionsConsts,
  customTypeConstants,
} from "../../../api/CustomTypes";
import { CustomTypeEntityTypeOptionToIcon } from "../../../Customization/CustomTypes/CustomTypeRenderUtils";

export const CustomTypeTile = (props: EntitySidebarTileProps<CustomType, CustomTypeFilters>) => {
  return (
    <EntitySidebarTile<CustomType, CustomTypeFilters>
      rowLabel={(e) => <>{e.name}</>}
      rowDate={(e) => (
        <>{e.createdOn ? <DateTimeRenderer date={e.createdOn} includeElapsed={false} includeTime={false} /> : NotSet}</>
      )}
      rowInfo={(e) => (
        <div className="flex row-nowrap align-center gap-5">
          Entity type:
          <CustomTypeEntityTypeOptionToIcon entityType={e.entityType} />
          {e.entityType}
        </div>
      )}
      {...props}
      entityConstants={customTypeConstants}
      orderByOptions={CustomTypeSortingOptionsConsts}
      translatorConsts={CustomTypeFiltersTranslator}
      defaultFilters={{ orderBy: "NAME_ASC", ...props.defaultFilters }}
    />
  );
};
