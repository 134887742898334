import { useEffect, useState } from "react";

import { PipelineRunningState, SerializedPipelineCommand } from "../../ProcessingPipeline/PipelineTypes";

import styles from "./PipelineNodeComponent.module.css";

// const colorList = [
//   "Gray",
//   "Salmon",
//   "HotPink",
//   "PaleVioletRed",
//   "Coral",
//   "Orange",
//   "Gold",
//   "Khaki",
//   "Violet",
//   "PaleGreen",
//   "DarkSeaGreen",
//   "Aquamarine",
//   "PowderBlue",
// ];

// const commandColor = Object.fromEntries(
//   Object.values(commandTypes).map((c, i) => [c, colorList[i % colorList.length]])
// );

// const getCommandColor = (command: string): string => commandColor[command] ?? "white";

const pluralCount = (count: number, text: string): string => {
  return `${count} ${text}${count > 1 ? "s" : ""}`;
};

export const PipelineNodeComponent = ({
  node,
  errors,
  warnings,
  state,
  onClick,
  width,
  getCommandColor,
}: {
  node: SerializedPipelineCommand;
  warnings: string[];
  errors: string[];
  state?: PipelineRunningState;
  width?: number;
  onClick?: (id: string) => void;
  getCommandColor: (command: string) => string;
}) => {
  const [showState, setShowState] = useState<any>();

  useEffect(() => {
    let s: any;

    switch (state) {
      case "ok":
        s = <span className={"glyphicon glyphicon-ok " + styles.success} />;
        break;
      case "running":
        s = <span className={"glyphicon glyphicon-hourglass " + styles.info} />;
        break;
      case "failed":
        s = <span className={"glyphicon glyphicon-remove " + styles.error} />;
        break;
    }
    setShowState(s);
  }, [state]);

  return (
    <div
      className={styles.main}
      style={{ background: getCommandColor(node.command), width: width }}
      onClick={(e) => {
        if (onClick) onClick(node.id);
      }}
      tabIndex={-1}
    >
      <div className={styles.sidebar} />
      <div className={styles.node}>
        <span className={styles.name}>{node.name}</span>
        <span className={styles.command}>
          <i>{node.command}</i>
        </span>
        {/* <span className={styles.parameter}> {Object.keys(node?.parameter ?? {}).length} parameter</span> */}
        <span className={styles.parameter}> {pluralCount(Object.keys(node?.parameter ?? {}).length, "parameter")}</span>
      </div>
      <div className={styles.sidebar}>
        <div className={styles.icons}>
          {showState}
          {errors.length > 0 ? <span className={"glyphicon glyphicon-alert " + styles.error} /> : null}
          {warnings.length > 0 ? <span className={"glyphicon glyphicon-alert " + styles.warning} /> : null}
        </div>
      </div>
    </div>
  );
};
