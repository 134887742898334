import { useContext } from "react";
import { EntityConstants } from "../../api/GenericTypes";
import { SessionContext } from "../contexts/SessionContext";
import {
  getAddRoute,
  getBulkEditRoute,
  getCloneLink,
  getDetailLink,
  getEditLink,
  getIndexRoute,
  getUUIDLink,
} from "../../main/Routing";

export interface Routes {
  getIndexRoute: string;
  getAddRoute: string;
  getBulkEditRoute: string;
  getEditLink: (id: number) => string;
  getCloneLink: (id: number) => string;
  getDetailLink: (id: number) => string;
  getUUIDLink: (uuid: string) => string;
}

interface UseEntityRoutesProps {
  entityConstants: EntityConstants;
}

/**
 * Custom hook that generates entity-related routes based on provided entity constants.
 * @author @CorradoSurmanowicz
 * @param {UseEntityRoutesProps} props - The properties required to generate entity routes.
 * @param {EntityConstants} props.entityConstants - Constants related to the entity for route generation.
 * @returns {Routes} An object containing functions to generate various entity-related routes.
 *
 * @example
 * const routes = useEntityRoutes({ entityConstants });
 * const indexRoute = routes.getIndexRoute();
 * const addRoute = routes.getAddRoute();
 * const editLink = routes.getEditLink(1);
 */
export const useEntityRoutes = ({ entityConstants }: UseEntityRoutesProps): Routes => {
  const { route } = useContext(SessionContext);
  return {
    getIndexRoute: route(getIndexRoute(entityConstants.frontendIndexRoute)),
    getAddRoute: route(getAddRoute(entityConstants.frontendIndexRoute)),
    getBulkEditRoute: route(getBulkEditRoute(entityConstants.frontendIndexRoute)),
    getEditLink: (id: number) => route(getEditLink(entityConstants.frontendIndexRoute, id)),
    getCloneLink: (id: number) => route(getCloneLink(entityConstants.frontendIndexRoute, id)),
    getDetailLink: (id: number) => route(getDetailLink(entityConstants.frontendIndexRoute, id)),
    getUUIDLink: (uuid: string) => route(getUUIDLink(uuid)),
  };
};
