import { useCallback, useEffect, useMemo, useState } from "react";

import { getTrackDataLevel } from "../SpectrumViewer/TrackDataManager";
import { useTranslatedFieldUpdate } from "../ViewerLayout/ViewerLayoutHooks";
import { Track, TrackData, TrackTable, viewerPropsType } from "../ViewerLayout/ViewerLayoutTypes";
import { Resizeable } from "../ViewerUIElements/Resizeable";
import { EllipsisTextBlock } from "../ViewerUIElements/TextBlock";
import { SpreadSheet } from "./SpreadSheet/SpreadSheet";
import {
  CellElementProps,
  CellIndex,
  CellSpan,
  FormattedTable,
  FormattedTableCell,
  indexToKey,
  indexToLetter,
  initFormattedTableCell,
} from "./SpreadSheet/SpreadSheetTypes";
import styles from "./TableComponent.module.css";
import { viewerModeType } from "./TableComponentTypes";
import { TableViewerNavigation } from "./TableViewerNavigation";

interface Table {
  cells: Record<string, FormattedTableCell>;
  rowCount: number;
  columnCount: number;
  fixedRow: number;
  fixedColumn: number;
  columnWidths: Record<number, number>;
}

const getTextWidth = function (ctx: CanvasRenderingContext2D, text: string, font?: string) {
  if (font) ctx.font = font;
  return ctx.measureText(text).width;
};

const focusSwitch = (focused: boolean | undefined, className: string, focusedClassName: string) => {
  if (focused) return `${className} ${focusedClassName}`;
  return className;
};

const ColumnIndexElement = ({ content, settings }: CellElementProps) => {
  return (
    <div className={focusSwitch(settings.focused, styles.columnIndex, styles.focusedIndex)}>
      <span>{content.value}</span>
    </div>
  );
};

const ColumnNameElement = ({ content, settings }: CellElementProps) => {
  return (
    <div className={focusSwitch(settings.focused, styles.columnName, styles.focusedIndex)}>
      <span>{content.value}</span>
    </div>
  );
};

const RowIndexElement = ({ content, settings }: CellElementProps) => {
  return (
    <div className={focusSwitch(settings.focused, styles.rowIndex, styles.focusedIndex)}>
      <span>{content.value}</span>
    </div>
  );
};

const RowNameElement = ({ content, settings }: CellElementProps) => {
  return (
    <div className={focusSwitch(settings.focused, styles.rowName, styles.focusedIndex)}>
      <span>{content.value}</span>
    </div>
  );
};

const TopLeftCornerElement = ({}: CellElementProps) => {
  return <div className={styles.cornerCell}>{/* <span>Corner cell</span> */}</div>;
};

const ContentCellElement = ({ content, settings, width }: CellElementProps) => {
  return (
    <div
      className={focusSwitch(settings.focused, styles.contentCell, styles.contentCellFocused)}
      style={{ width: width }}
    >
      <EllipsisTextBlock text={content.value?.toString() ?? ""} />
    </div>
  );
};

const EMPTY_CELL = initFormattedTableCell();

export const TableComponent = ({ api, tracks, datatracks, showNavigation, changeTrackState }: viewerPropsType) => {
  // const [currentTrack, setCurrentTrack] = useState<TrackTable>();
  const [table, setTable] = useState<Table>({
    cells: {},
    fixedRow: 0,
    fixedColumn: 0,
    rowCount: 0,
    columnCount: 0,
    columnWidths: {},
  });
  const [viewerMode, setViewerMode] = useState<viewerModeType>({
    fixMode: { row: false, column: false },
    showIndex: { row: true, column: true },
    letterIndex: { row: false, column: true },
  });

  // const [spanningCells, setSpanningCells] = useState<CellSpan[]>([{ row: 3, column: 2, columnSpan: 2, rowSpan: 1 }]);
  const [spanningCells] = useState<CellSpan[]>([]);

  const currentTrack = useTranslatedFieldUpdate(
    tracks,
    (tracks: Record<string, Track>) => {
      const trackList = Object.values(tracks)
        .filter((track) => track.type === "table" && track.settings.visible)
        .sort((a, b) => a.index - b.index);
      if (trackList.length < 1) return undefined;

      return trackList[0] as TrackTable;
    },
    undefined
  );

  const CellValues = useCallback(
    (index: CellIndex) => {
      const key = indexToKey(index.rowIndex, index.columnIndex);
      // const cell = initFormattedTableCell();
      // cell.value = `<${index.rowIndex} : ${index.columnIndex}>`;
      // return cell;
      // console.log("cell", key in table.cells ? table.cells[key] : EMPTY_CELL);
      return key in table.cells ? table.cells[key] : EMPTY_CELL;
    },
    [table]
  );

  const ctx = useMemo(() => {
    var canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    if (ctx) ctx.font = "bold 12pt arial";
    return canvas.getContext("2d");
  }, []);

  useEffect(() => {
    if (currentTrack)
      getTrackDataLevel(
        api,
        0,
        [currentTrack],
        datatracks,
        (data: TrackData[]) => {
          if (data.length > 0 && data?.[0].type === "table" && data[0].data.table?.[0].data) {
            const table = data[0].data.table[0].data as FormattedTable;
            const columnWidths: Record<number, number> = {};
            if (ctx) {
              let size = Math.max(...table.cells.map((cell) => getTextWidth(ctx, cell.value?.toString() ?? "")));
              for (let i = 0; i < table.columnRange[1]; i++) {
                const width = getTextWidth(ctx, indexToLetter(i));
                if (width > size) size = width;
              }
              for (let i = table.columnRange[0]; i <= table.columnRange[1]; i++) columnWidths[i] = size;
            }

            setTable({
              cells: Object.fromEntries(table.cells.map((cell) => [indexToKey(cell.row, cell.column), cell])),
              fixedRow: table.fixedRow,
              fixedColumn: table.fixedColumn,
              columnCount: table.columnRange[1] + 1,
              rowCount: table.rowRange[1] + 1,
              columnWidths: columnWidths,
            });
          }
        },
        changeTrackState ?? (() => {})
      );
  }, [currentTrack, ctx]);

  // useEffect(() => {
  //   console.log("table", table);
  // }, [table]);

  return (
    <div className={styles.viewer}>
      {showNavigation ? <TableViewerNavigation viewerMode={viewerMode} setViewerMode={setViewerMode} /> : null}
      <div className={styles.main}>
        <Resizeable>
          {/* <SpreadSheet spanningCells={spanningCells} rowCount={table.rowCount} columnCount={table.columnCount} /> */}
          <SpreadSheet
            spanningCells={spanningCells}
            rowCount={table.rowCount}
            columnCount={table.columnCount}
            fixedRowCount={viewerMode.fixMode.row ? 1 : 0}
            fixedColumnCount={viewerMode.fixMode.column ? 1 : 0}
            showRowIndex={viewerMode.showIndex.row as boolean}
            showColumnIndex={viewerMode.showIndex.column as boolean}
            showColumnLetterIndex={viewerMode.letterIndex.column as boolean}
            showRowLetterIndex={viewerMode.letterIndex.row as boolean}
            CellValues={CellValues}
            ColumnIndexElement={ColumnIndexElement}
            ColumnNameElement={ColumnNameElement}
            RowIndexElement={RowIndexElement}
            RowNameElement={RowNameElement}
            TopLeftCornerElement={TopLeftCornerElement}
            ContentCellElement={ContentCellElement}
            columnWidths={table.columnWidths}
          />
        </Resizeable>
      </div>
    </div>
  );
};
