import { useCallback, useMemo, useState } from "react";
import { useUnpaginateOrdered } from "../../api/BaseEntityApi";
import { Parser, ParserFilters } from "../../api/Parsers";
import { LoadingWrapper } from "../../common/LoadingWrapper";
/**
 * ParserName component for display parser name
 * Author: SS
 * @param: parserType,
 * @returns: JSX.Element
 * Changelog:
 * 2022-06-20 CS: Refactoring
 */

interface Props {
  parserType: string;
}
export const ParserName = ({ parserType }: Props) => {
  const [parsers, setParsers] = useState<{ [parserId: string]: Parser }>();
  // const {
  //     isLoading: isLoadingParsers,
  //     error: errorParsers,
  //     data: parsers,
  // } = useQuery(
  //     [],
  //     () => api.getParsers(),
  //     { keepPreviousData: true }
  // );

  const {
    data: parsersQuery,
    error: errorParsers,
    status: errorParsersStatus,
    fetchStatus,
    // isLoading: isLoadingParsers,
  } = useUnpaginateOrdered<Parser, ParserFilters>("parsers", { orderBy: "NAME_ASC" });

  useMemo(() => {
    if (parsersQuery) setParsers(Object.fromEntries(parsersQuery.map((parser) => [parser.id, parser])));
  }, [parsersQuery]);

  const getParserName = useCallback(
    (parserType: string) => {
      return parsers?.[parserType].name || "";
    },
    [parsers]
  );

  return (
    <LoadingWrapper status={errorParsersStatus} fetchStatus={fetchStatus} error={errorParsers}>
      <span>{getParserName(parserType)}</span>
    </LoadingWrapper>
  );
};
