import React, { CSSProperties, useContext, useEffect, useMemo, useState } from "react";
import { LucideIcon } from "../../icon/LucideIcon";
import styles from "./DetailTable.module.css";
/**
 * Generic DetailView component for main content of Detail component
 * Author: CS
 * @param: children
 * @returns: JSX.Element
 */

interface Props {
  noPadding?: boolean;
  isFolded?: boolean;
  nonFoldable?: boolean;
  style?: CSSProperties;
  children: React.ReactNode;
}

interface TableContextStore {
  isFolded: boolean;
  setIsFolded: React.Dispatch<React.SetStateAction<boolean>>;
  nonFoldable?: boolean;
}
const TableContext = React.createContext<TableContextStore | null>(null);

export const Table = ({ noPadding = false, isFolded, nonFoldable, style, children }: Props) => {
  const [_isFolded, setIsFolded] = useState(nonFoldable ? false : isFolded !== undefined ? isFolded : false);
  const context = useMemo(() => {
    return {
      isFolded: _isFolded,
      setIsFolded: setIsFolded,
      nonFoldable: nonFoldable,
    };
  }, [_isFolded, nonFoldable]);

  return (
    <TableContext.Provider value={context}>
      <div className={styles.table_container} style={{ ...style, ...(noPadding && { padding: 0 }) }}>
        {children}
      </div>
    </TableContext.Provider>
  );
};

const Head = ({ style, children }: Props) => {
  const context = useContext(TableContext);
  const [folded, setFolded] = useState(context?.isFolded ?? false);

  useEffect(() => {
    context?.setIsFolded(folded);
  }, [context, folded]);

  return (
    <div
      className={styles.table_container_head}
      onClick={() => {
        if (context?.nonFoldable) return;
        setFolded((prev) => !prev);
      }}
      style={{ ...(context?.nonFoldable && { cursor: "default" }), ...style }}
    >
      <h4 className="flex row-nowrap align-center gap-5" style={{ margin: 0 }}>
        {context?.nonFoldable ? (
          <div className="flex align-center justify-center" style={{ width: 14, height: 14, color: "var(--obsidian)" }}>
            ∙
          </div>
        ) : folded ? (
          <LucideIcon name="chevron-right" color={"var(--obsidian)"} />
        ) : (
          <LucideIcon name="chevron-down" color={"var(--obsidian)"} />
        )}{" "}
        {children}
      </h4>
    </div>
  );
};
Table.Head = Head;

const Body = ({ style, children }: Props) => {
  const context = useContext(TableContext);
  const _style = context?.isFolded
    ? {
        height: 0,
        opacity: 0,
      }
    : { height: style?.height ?? "fit-content", opacity: 1 };
  return (
    <div className={styles.table_container_body} style={{ ...style, ..._style }}>
      {children}
    </div>
  );
};
Table.Body = Body;

const Row = ({ style, children }: Props) => (
  <div className={styles.table_container_row} style={style}>
    {children}
  </div>
);
Body.Row = Row;

const Col = ({ style, children }: Props) => {
  return (
    <div className={styles.table_container_col} style={style}>
      {children}
    </div>
  );
};
Body.Col = Col;

interface RowContentProps {
  title: string;
  content?: string | React.ReactNode;
  rowStyle?: CSSProperties;
  colStyle?: CSSProperties;
}
const RowContent = ({ title, content, rowStyle, colStyle }: RowContentProps) => {
  return (
    <Table.Body.Row style={rowStyle}>
      <Table.Body.Col style={colStyle}>{title}</Table.Body.Col>
      <Table.Body.Col style={colStyle}>{content}</Table.Body.Col>
    </Table.Body.Row>
  );
};
Body.RowContent = RowContent;
