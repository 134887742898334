import { useCallback, useEffect, useState } from "react";
import { DropdownButton, MenuItem } from "react-bootstrap";

import { Color } from "../../ViewerLayout/ViewerLayoutTypes";
import { initColor, initSingleColor } from "../../ViewerLayout/ViewerLayoutUtils";
import { contourGeneratorType, contourState, contourTypes } from "../../ViewerLayout/ViewerTypes";
import { Resizeable } from "../../ViewerUIElements/Resizeable";
import { createContour } from "../ContourClasses";
import styles from "./ContourEditor.module.css";
import { EquidistantContourEditor } from "./EquidistantContourEditor";
import { ExplicitContourEditor } from "./ExplicitContourEditor";
import { OneBaselineContourEditor } from "./OneBaselineContourEditor";

export const getDigit = (range?: number) => {
  if (range) {
    const tic = Math.floor(Math.log(range) / Math.log(10));
    return tic < 0 ? -tic : tic < 3 ? 1 : 0;
  }
  return 0;
};

export type RangeType = { min: number; max: number; diff: number };

export const ArrayNeedsUpdate = (target: number[], source: number[], precision = Number.EPSILON) => {
  if (target === source) return false;

  if ((target === undefined && source !== undefined) || (target !== undefined && source === undefined)) return true;

  if (target.length !== source.length) return true;

  if (target.some((v, i) => Math.abs(v - (source[i] || 0)) > precision)) return true;

  return false;
};

// const log10 = Math.log(10);

export type ToolInput = {
  width?: number;
  color: Color;
  contours: contourGeneratorType;
  valueMin: number;
  valueMax: number;
  setContours: (contours: contourGeneratorType) => void;
  setMinHeight?: (height: number) => void;
  setMinWidth?: (width: number) => void;
};

// const defaultColor = {
//   colors: ["gray", "gray"],
//   offsets: [],
//   range: [0, 1],
//   values: [0, 1],
// };
const defaultColor = initColor({
  colors: [initSingleColor({ color: "gray", value: 0 }), initSingleColor({ color: "gray", value: 1 })],
  range: [0, 1],
});

const MultiBaselineContourEditor = ({ width, color, contours, valueMin, valueMax, setContours }: ToolInput) => {
  return <div>MultiBaselineContourEditor</div>;
};

const Editor = (props: ToolInput) => {
  // console.log("Editor", props?.contours);
  switch (props?.contours?.state?.type) {
    case contourTypes.explicitContours:
      return <ExplicitContourEditor {...props} />;
    case contourTypes.equidistant:
      return <EquidistantContourEditor {...props} />;
    case contourTypes.oneBaseline:
      return <OneBaselineContourEditor {...props} />;
    case contourTypes.multiBaseline:
      return <MultiBaselineContourEditor {...props} />;
    default:
      return null;
  }
  // if (props.contours === undefined) return null;
  // return <ExplicitContourEditor {...props} />;
};

const SliderDiv = (props: ToolInput) => {
  const { width, contours, setContours, ...otherProps } = props;

  const [editorType, setEditorType] = useState<contourTypes>();

  useEffect(() => {
    if (contours?.state?.type) setEditorType(contours.state.type);
  }, [contours?.state?.type]);

  // useEffect(() => {}, [editorType]);

  const changeEditorType = useCallback(
    (type: contourTypes) => {
      // console.log("contours", createContour({ type: type } as contourState));
      if (setContours) setContours(createContour({ type: type } as contourState));
    },
    [setContours]
  );

  // console.log(
  //   "SliderDiv",
  //   Object.entries(contourTypes).map(([k, v]) => k + ":" + v)
  // );
  return (
    <div
      className={styles.sliderDivMain}
      style={{
        width: width,
      }}
    >
      <span style={{ fontWeight: "bold" }}>
        Mode:{" "}
        <DropdownButton
          bsStyle={"default"}
          title={editorType || "Unknown"}
          id={"contour-type-select"}
          bsSize="xs"
          onSelect={(key: any) => changeEditorType(key)}
        >
          {Object.entries(contourTypes)
            .filter(([, v]) => v !== contourTypes.multiBaseline) // remove multi baseline for now
            .map(([k, v]) => (
              <MenuItem key={k} eventKey={v} active={v === editorType}>
                {v}
              </MenuItem>
            ))}
        </DropdownButton>
      </span>

      <div style={{ height: 5 }} />

      <Resizeable>
        <Editor contours={contours} setContours={setContours} {...otherProps} />
      </Resizeable>
      <div style={{ paddingLeft: 5 }}></div>
    </div>
  );
};

export const ContourEditor = function ({
  color,
  contours,
  valueMin,
  valueMax,
  setContours,
  // onSettingsOpen,
  // onSettingsClosed,
  setMinHeight,
  setMinWidth,
}: {
  color?: Color;
  contours?: contourGeneratorType;
  valueMin?: number;
  valueMax?: number;
  setContours?: (contours: contourGeneratorType) => void;
  // onSettingsOpen?: () => void;
  // onSettingsClosed?: () => void;
  setMinHeight?: (height: number) => void;
  setMinWidth?: (width: number) => void;
}) {
  // console.log("value min/max", valueMin, valueMax);
  // console.log("setContours", setContours);
  return (
    <div
      className={styles.main}
      // {...otherProps}
      onWheelCapture={(e) => {
        // let next = current + Math.sign(e.deltaY);
        // if (next >= trackList.length) next = 0;
        // if (next < 0) next = trackList.length - 1;
        // setCurrent(next);
        console.log("wheel", e.deltaY);
      }}
    >
      <Resizeable>
        <SliderDiv
          valueMin={valueMin || 0}
          valueMax={valueMax || 1}
          color={color || defaultColor}
          contours={contours || { generate: () => [], state: { type: contourTypes.explicitContours } }}
          setContours={setContours || (() => {})}
          setMinHeight={setMinHeight}
          setMinWidth={setMinWidth}

          // onSettingsClosed={onSettingsClosed}
          // onSettingsOpen={onSettingsOpen}
        />
      </Resizeable>
    </div>
  );
};
