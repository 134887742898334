import { AuditLogEntityType, AuditLogEventType } from "../api/AuditLog";
import { IconNames } from "../common/icon/LucideIcon";
import { ResourceName, getDetailLink, getIndexRoute } from "../main/Routing";
import { IdTypes } from "../api/GenericTypes";
import { dataSourceConstants } from "../api/DataSource";
import { bridgeConstants } from "../api/Bridges";
import { samplesConstants } from "../api/Samples";
import { datasetsConstants } from "../api/Datasets";
import { labNoteBooksConstants } from "../ELN/types/LabNotebook";
import { labNotebookExperimentsConstants } from "../ELN/types/LabNotebookExperiment";
import { labNotebookEntriesConstants } from "../ELN/types/LabNotebookEntry";
import { labNotebookTemplatesConstants } from "../ELN/types/LabNotebookTemplate";
import { personsConstants } from "../api/Person";
import { announcementsConstants } from "../api/Announcements";
import { documentsConstants } from "../api/Documents";
import { experimentsConstants } from "../api/Experiments";
import { equipmentsConstants, instrumentsConstants, itFacilityConstants } from "../api/Facilities";
import { methodsConstants } from "../api/Methods";
import { organizationsConstants } from "../api/Organizations";
import { projectsConstants } from "../api/Projects";
import { sharedContentConstants } from "../api/SharedContent";
import { apiKeysConstants } from "../api/ApiKey";
import { customFieldConstants } from "../api/CustomFields";
import { customTypeConstants } from "../api/CustomTypes";
import { inventoryItemsConstants } from "../api/Inventories";
import { customImportConstants } from "../api/CustomImports";

export const eventIdToInfo = (
  type: AuditLogEventType
): {
  icon?: IconNames;
  color: "primary" | "secondary" | "danger" | "warning" | "success" | "default";
  label: string;
} => {
  switch (type) {
    case "Login":
      return { icon: "user", color: "primary", label: "Login" };
    case "Logout":
      return { icon: "user-minus", color: "danger", label: "Logout" };
    case "ApiKeyCreate":
      return { icon: "key", color: "success", label: "API-Key create" };
    case "ApiKeySoftDelete":
      return { icon: "trash", color: "warning", label: "API-Key trash" };
    case "ApiKeyRestore":
      return { icon: "rotate-ccw", color: "success", label: "API-Key restore" };
    case "ApiKeyDelete":
      return { icon: "trash-2", color: "danger", label: "API-Key delete" };
    case "EntityCreate":
      return { icon: "plus", color: "success", label: "Entity create" };
    case "EntityEdit":
      return { icon: "square-pen", color: "default", label: "Entity edit" };
    case "EntitySoftDelete":
      return { icon: "trash", color: "warning", label: "Entity trash" };
    case "EntityRestore":
      return { icon: "copy", color: "success", label: "Entity restore" };
    case "EntityDelete":
      return { icon: "trash-2", color: "danger", label: "Entity delete" };
    case "EntityVersionRestore":
      return { icon: "rotate-ccw", color: "success", label: "Version restore" };

    default:
      return { color: "default", label: "-" };
  }
};

export const entityTypeToInfo = <IdType extends IdTypes>(
  type?: AuditLogEntityType
):
  | {
      resource: ResourceName;
      icon?: IconNames;
      color?: "primary" | "secondary" | "danger" | "warning" | "default";
      label: string;
      route: (id: IdType) => string;
    }
  | undefined => {
  switch (type) {
    case "Sample":
      return {
        resource: samplesConstants.resource,
        icon: samplesConstants.icon,
        label: samplesConstants.entitySingular,
        route: (id: IdType) => getDetailLink(samplesConstants.frontendIndexRoute, id),
      };
    case "Dataset":
      return {
        resource: datasetsConstants.resource,
        icon: datasetsConstants.icon,
        label: datasetsConstants.entitySingular,
        route: (id: IdType) => getDetailLink(datasetsConstants.frontendIndexRoute, id),
      };
    case "LabNotebook":
      return {
        resource: labNoteBooksConstants.resource,
        icon: labNoteBooksConstants.icon,
        label: labNoteBooksConstants.entitySingular,
        route: (id: IdType) => getDetailLink(labNoteBooksConstants.frontendIndexRoute, id),
      };
    case "LabNotebookExperiment":
      return {
        resource: labNotebookExperimentsConstants.resource,
        icon: labNotebookExperimentsConstants.icon,
        label: labNotebookExperimentsConstants.entitySingular,
        route: (id: IdType) => getDetailLink(labNotebookExperimentsConstants.frontendIndexRoute, id),
      };
    case "LabNotebookEntry":
      return {
        resource: labNotebookEntriesConstants.resource,
        icon: labNotebookEntriesConstants.icon,
        label: labNotebookEntriesConstants.entitySingular,
        route: (id: IdType) => getDetailLink(labNotebookEntriesConstants.frontendIndexRoute, id),
      };
    case "LabNotebookTemplate":
      return {
        resource: labNotebookTemplatesConstants.resource,
        icon: labNotebookTemplatesConstants.icon,
        label: labNotebookTemplatesConstants.entitySingular,
        route: (id: IdType) => getDetailLink(labNotebookTemplatesConstants.frontendIndexRoute, id),
      };
    case "Person":
      return {
        resource: personsConstants.resource,
        icon: personsConstants.icon,
        label: personsConstants.entitySingular,
        route: (id: IdType) => getDetailLink(personsConstants.frontendIndexRoute, id),
      };
    case "Announcement":
      return {
        resource: announcementsConstants.resource,
        icon: announcementsConstants.icon,
        label: announcementsConstants.entitySingular,
        route: (id: IdType) => getIndexRoute(announcementsConstants.frontendIndexRoute),
      };
    case "AutoloadConfiguration":
      return {
        resource: dataSourceConstants.resource,
        icon: dataSourceConstants.icon,
        label: dataSourceConstants.entitySingular,
        route: (id: IdType) => getDetailLink(dataSourceConstants.frontendIndexRoute, id),
      };
    case "AutoloadSource":
      return {
        resource: bridgeConstants.resource,
        icon: bridgeConstants.icon,
        label: bridgeConstants.entitySingular,
        route: (id: IdType) => getDetailLink(bridgeConstants.frontendIndexRoute, id),
      };
    case "Document":
      return {
        resource: documentsConstants.resource,
        icon: documentsConstants.icon,
        label: documentsConstants.entitySingular,
        route: (id: IdType) => getDetailLink(documentsConstants.frontendIndexRoute, id),
      };
    case "Experiment":
      return {
        resource: experimentsConstants.resource,
        icon: experimentsConstants.icon,
        label: experimentsConstants.entitySingular,
        route: (id: IdType) => getDetailLink(experimentsConstants.frontendIndexRoute, id),
      };
    case "Facility":
      return {
        resource: "facilities",
        icon: "hard-drive",
        label: "Facility",
        route: (id: IdType) => getIndexRoute("facilities"),
      };
    case "EquipmentFacility":
      return {
        resource: equipmentsConstants.resource,
        icon: equipmentsConstants.icon,
        label: equipmentsConstants.entitySingular,
        route: (id: IdType) => getIndexRoute(equipmentsConstants.frontendIndexRoute),
      };
    case "ITFacility":
      return {
        resource: itFacilityConstants.resource,
        icon: itFacilityConstants.icon,
        label: itFacilityConstants.entitySingular,
        route: (id: IdType) => getDetailLink(itFacilityConstants.frontendIndexRoute, id),
      };
    case "InstrumentFacility":
      return {
        resource: instrumentsConstants.resource,
        icon: instrumentsConstants.icon,
        label: instrumentsConstants.entitySingular,
        route: (id: IdType) => getDetailLink(instrumentsConstants.frontendIndexRoute, id),
      };
    case "StorageFacility":
      return {
        resource: "storages",
        icon: "package",
        label: "Storage",
        route: (id: IdType) => getIndexRoute("facilities"),
      };
    case "Folder":
      return {
        resource: "folders",
        icon: "folder",
        label: "Folder",
        route: (id: IdType) => getDetailLink("projects", id), // This is wrong
      };
    case "Method":
      return {
        resource: methodsConstants.resource,
        icon: methodsConstants.icon,
        label: methodsConstants.entitySingular,
        route: (id: IdType) => getDetailLink(methodsConstants.frontendIndexRoute, id),
      };
    case "Organization":
      return {
        resource: organizationsConstants.resource,
        icon: organizationsConstants.icon,
        label: organizationsConstants.entitySingular,
        route: (id: IdType) => getDetailLink(organizationsConstants.frontendIndexRoute, id),
      };
    case "PersonCategory":
      return { resource: "persons", icon: "user", label: "Person category", route: () => "" };
    case "Project":
      return {
        resource: projectsConstants.resource,
        icon: projectsConstants.icon,
        label: projectsConstants.entitySingular,
        route: (id: IdType) => getDetailLink(projectsConstants.frontendIndexRoute, id),
      };
    case "SharedBundleContent":
      return {
        resource: sharedContentConstants.resource,
        icon: sharedContentConstants.icon,
        label: sharedContentConstants.entitySingular,
        route: (id: IdType) => getDetailLink(sharedContentConstants.frontendIndexRoute, id),
      };
    case "SharedBundle":
      return {
        resource: sharedContentConstants.resource,
        icon: sharedContentConstants.icon,
        label: sharedContentConstants.entitySingular,
        route: (id: IdType) => getDetailLink(sharedContentConstants.frontendIndexRoute, id),
      };
    case "User":
      return {
        resource: personsConstants.resource,
        icon: personsConstants.icon,
        label: personsConstants.entitySingular,
        route: (id: IdType) => getDetailLink(personsConstants.frontendIndexRoute, id),
      };
    case "SharedContent":
      return {
        resource: sharedContentConstants.resource,
        icon: sharedContentConstants.icon,
        label: sharedContentConstants.entitySingular,
        route: (id: IdType) => getDetailLink(sharedContentConstants.frontendIndexRoute, id),
      };
    case "Origin":
      return { resource: "origins", icon: "loader", label: "Origin", route: () => "" };
    // case "CustomField":
    //   return {
    //     resource: customFieldsConstants.resource,
    //     icon: customFieldsConstants.icon,
    //     label: customFieldsConstants.entitySingular,
    //     route: (id: IdType) => getDetailLink(customFieldsConstants.frontendIndexRoute, id),
    //   };
    // case "SampleType":
    //   return {
    //     resource: sampleTypesConstants.resource,
    //     icon: sampleTypesConstants.icon,
    //     label: sampleTypesConstants.entitySingular,
    //     route: (id: IdType) => getDetailLink(sampleTypesConstants.frontendIndexRoute, id),
    //   };
    case "CustomImport":
      return {
        resource: customImportConstants.resource,
        icon: customImportConstants.icon,
        label: customImportConstants.entitySingular,
        route: (id: IdType) => getDetailLink(customImportConstants.frontendIndexRoute, id),
      };
    // case "DatasetType":
    //   return {
    //     resource: datasetTypesConstants.resource,
    //     icon: datasetTypesConstants.icon,
    //     label: datasetTypesConstants.entitySingular,
    //     route: (id: IdType) => getDetailLink(datasetTypesConstants.frontendIndexRoute, id),
    //   };
    case "ApiKey":
      return {
        resource: apiKeysConstants.resource,
        icon: apiKeysConstants.icon,
        label: apiKeysConstants.entitySingular,
        route: (id: IdType) => getIndexRoute(apiKeysConstants.frontendIndexRoute),
      };
    case "DataSource":
      return {
        resource: dataSourceConstants.resource,
        icon: dataSourceConstants.icon,
        label: dataSourceConstants.entitySingular,
        route: (id: IdType) => getIndexRoute(dataSourceConstants.frontendIndexRoute),
      };
    case "Bridge":
      return {
        resource: bridgeConstants.resource,
        icon: bridgeConstants.icon,
        label: bridgeConstants.entitySingular,
        route: (id: IdType) => getIndexRoute(bridgeConstants.frontendIndexRoute),
      };
    case "CustomFieldDBModel":
      return {
        resource: customFieldConstants.resource,
        icon: customFieldConstants.icon,
        label: customFieldConstants.entitySingular,
        route: (id: IdType) => getDetailLink(customFieldConstants.frontendIndexRoute, id),
      };
    case "CustomTypeDBModel":
      return {
        resource: customTypeConstants.resource,
        icon: customTypeConstants.icon,
        label: customTypeConstants.entitySingular,
        route: (id: IdType) => getDetailLink(customTypeConstants.frontendIndexRoute, id),
      };
    case "CustomSampleType":
    case "CustomDatasetType":
    case "CustomInventoryType":
    case "CustomProjectType":
    case "CustomPersonType":
    case "CustomMethodType":
    case "CustomFacilityType":
      return {
        resource: customTypeConstants.resource,
        icon: customTypeConstants.icon,
        label: customTypeConstants.entitySingular,
        route: (id: IdType) => getDetailLink(customTypeConstants.frontendIndexRoute, id),
      };
    case "Inventory":
      return {
        resource: inventoryItemsConstants.resource,
        icon: inventoryItemsConstants.icon,
        label: inventoryItemsConstants.entitySingular,
        route: (id: IdType) => getDetailLink(inventoryItemsConstants.frontendIndexRoute, id),
      };
    default:
      return { resource: "" as any, label: type || "-", route: () => "" };
  }
};
