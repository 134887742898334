import React, { useContext, useEffect, useState } from "react";
import {
  inventoriesConstants,
  InventoryItem,
  InventoryItemFilters,
  inventoryItemsConstants,
} from "../../api/Inventories";
import { useEntityDetail, useUnpaginate } from "../../api/BaseEntityApi";
import { LoadingWrapper } from "../../common/LoadingWrapper";
import { SessionContext } from "../../common/contexts/SessionContext";
import { getIndexRoute } from "../../main/Routing";
import { IdTypes, IEntityMinimalModel } from "../../api/GenericTypes";

interface InventoryItemsResult {
  entity: InventoryItem;
  ancestorLinkToOverride: ((ancestor: IEntityMinimalModel<IdTypes>) => string) | undefined;
  entityLinkToOverride: ((entity: IEntityMinimalModel<IdTypes>) => string) | undefined;
  indexEntityLinkToOverride: string;
}
type InventoryItemsResults = InventoryItemsResult[];

// Simple wrapper that returns the InventoryItem data for the given id and filters

interface InventoryItemWrapperProps {
  id: InventoryItem["id"];
  filters?: InventoryItemFilters;
  enabled?: boolean;
  children: (data: InventoryItemsResult) => React.ReactNode;
}
export const InventoryItemWrapper = ({ id, filters = {}, enabled = true, children }: InventoryItemWrapperProps) => {
  const { route } = useContext(SessionContext);

  const { data, status, fetchStatus, error } = useEntityDetail<InventoryItem, InventoryItemFilters>(
    inventoryItemsConstants.resource,
    id,
    filters,
    { enabled: enabled }
  );

  return (
    <LoadingWrapper status={status} fetchStatus={fetchStatus} error={error}>
      {data &&
        children({
          entity: data,
          entityLinkToOverride: data.isHierarchyItem
            ? (ancestor) => {
                return `${route(getIndexRoute(inventoriesConstants.frontendIndexRoute))}/${
                  data.rootCustomType?.id
                }/hierarchy/${ancestor.id}`;
              }
            : undefined,
          ancestorLinkToOverride: data.isHierarchyItem
            ? (ancestor) => {
                return `${route(getIndexRoute(inventoriesConstants.frontendIndexRoute))}/${
                  data.rootCustomType?.id
                }/hierarchy/${ancestor.id}`;
              }
            : undefined,
          indexEntityLinkToOverride: data.isHierarchyItem
            ? `${route(getIndexRoute(inventoriesConstants.frontendIndexRoute))}/${data.rootCustomType?.id}/hierarchy`
            : `${route(getIndexRoute(inventoriesConstants.frontendIndexRoute))}/${data.customType?.id}/items`,
        })}
    </LoadingWrapper>
  );
};

// Simple wrapper that returns the InventoryItems data for the given ids and filters

interface InventoryItemsWrapperProps {
  ids: InventoryItem["id"][];
  filters?: InventoryItemFilters;
  enabled?: boolean;
  children: (data: InventoryItemsResults) => React.ReactNode;
}

export const InventoryItemsWrapper = ({ ids, filters = {}, enabled = true, children }: InventoryItemsWrapperProps) => {
  const [results, setResults] = useState<InventoryItemsResults>();
  const { route } = useContext(SessionContext);

  const { data, status, fetchStatus, error } = useUnpaginate<InventoryItem, InventoryItemFilters>(
    inventoryItemsConstants.resource,
    { ...filters, ids: ids },
    { enabled: enabled }
  );

  useEffect(() => {
    if (data) {
      setResults(
        Object.values(data).map((entity) => {
          return {
            entity: entity,
            entityLinkToOverride: entity.isHierarchyItem
              ? (ancestor) => {
                  return `${route(getIndexRoute(inventoriesConstants.frontendIndexRoute))}/${
                    entity.rootCustomType?.id
                  }/hierarchy/${ancestor.id}`;
                }
              : undefined,
            ancestorLinkToOverride: entity.isHierarchyItem
              ? (ancestor) => {
                  return `${route(getIndexRoute(inventoriesConstants.frontendIndexRoute))}/${
                    entity.rootCustomType?.id
                  }/hierarchy/${ancestor.id}`;
                }
              : undefined,
            indexEntityLinkToOverride: entity.isHierarchyItem
              ? `${route(getIndexRoute(inventoriesConstants.frontendIndexRoute))}/${
                  entity.rootCustomType?.id
                }/hierarchy`
              : `${route(getIndexRoute(inventoriesConstants.frontendIndexRoute))}/${entity.customType?.id}/items`,
          };
        })
      );
    }
  }, [data, route]);

  return (
    <LoadingWrapper status={status} fetchStatus={fetchStatus} error={error}>
      {results && children(results)}
    </LoadingWrapper>
  );
};
