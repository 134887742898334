import { UseFormRegister, Control, FieldErrors, Path } from "react-hook-form";
import { StringIndexedDict } from "../../api/GenericTypes";
import { SingleDatePickerFormField } from "../../common/formfields/DatePickerFormField/SingleDatePickerFormField";
import { GenericVirtualizedSelectFormField } from "../../common/formfields/GenericVirtualizedSelectFormField/GenericVirtualizedSelectFormField";
import { InputFormField } from "../../common/formfields/InputFormField";
import { ToggleFormField } from "../../common/formfields/ToggleFormField";
import { DatasetsVirtualizedSelectForm } from "../../common/forms/EntityForms/formsVirtualized/DatasetsVirtualizedSelectForm";
import { InstrumentsVirtualizedSelectForm } from "../../common/forms/EntityForms/formsVirtualized/InstrumentsVirtualizedSelectForm";
import { InventoriesVirtualizedSelectForm } from "../../common/forms/EntityForms/formsVirtualized/InventoriesVirtualizedSelectForm";
import { LabNotebookEntriesVirtualizedSelectForm } from "../../common/forms/EntityForms/formsVirtualized/LabNotebookEntriesVirtualizedSelectForm";
import { LabNotebookExperimentsVirtualizedSelectForm } from "../../common/forms/EntityForms/formsVirtualized/LabNotebookExperimentsVirtualizedSelectForm";
import { LabNotebooksVirtualizedSelectForm } from "../../common/forms/EntityForms/formsVirtualized/LabNotebooksVirtualizedSelectFormField";
import { MethodsVirtualizedSelectForm } from "../../common/forms/EntityForms/formsVirtualized/MethodsVirtualizedSelectForm";
import { OrganizationsVirtualizedSelectForm } from "../../common/forms/EntityForms/formsVirtualized/OrganizationsVirtualizedSelectForm";
import { PersonsVirtualizedSelectForm } from "../../common/forms/EntityForms/formsVirtualized/PersonsVirtualizedSelectForm";
import { ProjectsVirtualizedSelectForm } from "../../common/forms/EntityForms/formsVirtualized/ProjectsVirtualizedSelectForm";
import { SamplesVirtualizedSelectForm } from "../../common/forms/EntityForms/formsVirtualized/SamplesVirtualizedSelectForm";
import { SharedContentsVirtualizedSelectForm } from "../../common/forms/EntityForms/formsVirtualized/SharedContentsVirtualizedSelectForm";
import { CustomFieldForm } from "./CustomFieldsForm";
import { CustomField, customFieldConstants } from "../../api/CustomFields";
import { TextareaFormField } from "../../common/formfields/TextareaFormField";
import { OverlayInfo } from "../../common/misc/OverlayInfo/OverlayInfo";
import { ManualUploadInlineWrapper } from "../../Dataset/ManualUpload/hook/ManualUploadInlineForm";
// import { BarcodeFormField } from "../../common/icon/barcode/Barcode";
import { ResourceName } from "../../main/Routing";
import { CSSProperties } from "react";
import { DurationPickerFormField } from "../../common/formfields/DurationPickerFormField/DurationPickerFormField";
import { DateArrayPickerFormFieldWrapper } from "../../common/formfields/DatePickerFormField/DateArrayPickerFormField/DateArrayPickerFormField";

interface CustomFieldEditRendererProps<Entity extends StringIndexedDict> {
  id: Path<Entity>;
  label: string;
  register: UseFormRegister<Entity>;
  control: Control<Entity, any, Entity>;
  errors: FieldErrors<Entity>;
  placeholder?: CustomField["placeholder"];
  required?: boolean;
  defaultValue?: any;
  disabled?: boolean;
  dataType: CustomField["dataType"];
  enumOptions?: CustomFieldForm["enumOptions"];
  enumOptionsFromValues?: CustomFieldForm["enumOptionsFromValues"];
  customFieldId?: CustomField["id"];
  showAsTextArea?: boolean;
  description?: CustomField["description"];
  customTypeConstraint?: CustomField["customTypeConstraint"];
  isMulti?: boolean;
  filters?: any;
  uncontained?: boolean;
  style?: CSSProperties;
}

export const CustomFieldEditRenderer = <Entity extends StringIndexedDict>({
  id,
  label,
  register,
  control,
  errors,
  placeholder,
  required,
  defaultValue,
  disabled,
  dataType,
  enumOptions,
  enumOptionsFromValues,
  customFieldId,
  showAsTextArea,
  description,
  customTypeConstraint,
  isMulti,
  filters,
  uncontained,
  style,
}: CustomFieldEditRendererProps<Entity>) => {
  if (enumOptionsFromValues && customFieldId)
    return (
      <GenericVirtualizedSelectFormField
        id={id}
        label={label}
        control={control}
        placeholder={placeholder}
        required={required}
        defaultValue={defaultValue}
        disabled={disabled}
        isMulti={isMulti}
        resource={`${customFieldConstants.resource}/${customFieldId}/options` as ResourceName}
        uncontained={uncontained}
        style={style}
        itemsFromOptions
        allowCreateEntity
        horizontal
      >
        {description && <OverlayInfo icon="info">{description}</OverlayInfo>}
      </GenericVirtualizedSelectFormField>
    );

  if (Array.isArray(enumOptions) && !!enumOptions.length) {
    return (
      <GenericVirtualizedSelectFormField
        id={id}
        label={label}
        items={enumOptions}
        control={control}
        placeholder={placeholder}
        required={required}
        defaultValue={defaultValue}
        disabled={disabled}
        isMulti={isMulti}
        uncontained={uncontained}
        style={style}
        allowCreateEntity={false}
        horizontal
      >
        {description && <OverlayInfo icon="info">{description}</OverlayInfo>}
      </GenericVirtualizedSelectFormField>
    );
  }

  switch (dataType) {
    case "String":
    case "Integer":
    case "Float":
    case "Url":
      return (
        <>
          {showAsTextArea ? (
            <TextareaFormField
              id={id}
              label={label}
              errors={errors}
              register={register}
              placeholder={placeholder}
              required={required}
              defaultValue={defaultValue}
              readOnly={disabled}
              buttons={description ? <OverlayInfo icon="info">{description}</OverlayInfo> : undefined}
              uncontained={uncontained}
              style={style}
              horizontal
            />
          ) : (
            <InputFormField
              id={id}
              label={label}
              errors={errors}
              register={register}
              placeholder={placeholder}
              required={required}
              defaultValue={defaultValue}
              disabled={disabled}
              buttons={description ? <OverlayInfo icon="info">{description}</OverlayInfo> : undefined}
              uncontained={uncontained}
              style={style}
              horizontal
            />
          )}
        </>
      );
    case "StringArray":
    case "IntegerArray":
    case "FloatArray":
    case "UrlArray":
      return (
        <GenericVirtualizedSelectFormField
          id={id}
          label={label}
          items={[]}
          control={control}
          placeholder={placeholder}
          required={required}
          defaultValue={defaultValue}
          disabled={disabled}
          uncontained={uncontained}
          style={style}
          isMulti
          allowCreateEntity
          horizontal
        >
          {description && <OverlayInfo icon="info">{description}</OverlayInfo>}
        </GenericVirtualizedSelectFormField>
      );
    case "Date":
      return (
        <SingleDatePickerFormField
          type="date"
          id={id}
          label={label}
          errors={errors}
          control={control}
          placeholder={placeholder}
          required={required}
          defaultValue={defaultValue}
          disabled={disabled}
          children={description ? <OverlayInfo icon="info">{description}</OverlayInfo> : undefined}
          uncontained={uncontained}
          horizontal
        />
      );
    case "DateArray":
      return (
        <SingleDatePickerFormField
          type="date"
          id={id}
          label={label}
          errors={errors}
          control={control}
          placeholder={placeholder}
          required={required}
          defaultValue={defaultValue}
          disabled={disabled}
          children={description ? <OverlayInfo icon="info">{description}</OverlayInfo> : undefined}
          uncontained={uncontained}
          isMulti
          horizontal
        />
      );
    case "DateTime":
      return (
        <SingleDatePickerFormField
          type="datetime"
          id={id}
          label={label}
          errors={errors}
          control={control}
          placeholder={placeholder}
          required={required}
          defaultValue={defaultValue}
          disabled={disabled}
          children={description ? <OverlayInfo icon="info">{description}</OverlayInfo> : undefined}
          uncontained={uncontained}
          style={style}
          horizontal
        />
      );
    case "DateTimeArray":
      return (
        <DateArrayPickerFormFieldWrapper
          type="datetime"
          id={id}
          label={label}
          errors={errors}
          control={control}
          placeholder={placeholder}
          required={required}
          defaultValue={defaultValue}
          disabled={disabled}
          children={description ? <OverlayInfo icon="info">{description}</OverlayInfo> : undefined}
          uncontained={uncontained}
          style={style}
          isMulti={false} // We set this to false because it is wrapped in a field array
          horizontal
        />
      );
    // return (
    //   <SingleDatePickerFormField
    //     type="datetime"
    //     id={id}
    //     label={label}
    //     errors={errors}
    //     control={control}
    //     placeholder={placeholder}
    //     required={required}
    //     defaultValue={defaultValue}
    //     disabled={disabled}
    //     buttons={description ? <OverlayInfo icon="info">{description}</OverlayInfo> : undefined}
    //     uncontained={uncontained}
    //     style={style}
    //     isMulti
    //     horizontal
    //   />
    // );
    case "Time":
      return (
        <SingleDatePickerFormField
          type="time"
          id={id}
          label={label}
          errors={errors}
          control={control}
          placeholder={placeholder}
          required={required}
          defaultValue={defaultValue}
          disabled={disabled}
          children={description ? <OverlayInfo icon="info">{description}</OverlayInfo> : undefined}
          uncontained={uncontained}
          style={style}
          horizontal
        />
      );
    case "TimeArray":
      return (
        <DateArrayPickerFormFieldWrapper
          type="time"
          id={id}
          label={label}
          errors={errors}
          control={control}
          placeholder={placeholder}
          required={required}
          defaultValue={defaultValue}
          disabled={disabled}
          children={description ? <OverlayInfo icon="info">{description}</OverlayInfo> : undefined}
          uncontained={uncontained}
          style={style}
          isMulti={false} // We set this to false because it is wrapped in a field array
          horizontal
        />
      );
    // return (
    //   <SingleDatePickerFormField
    //     type="time"
    //     id={id}
    //     label={label}
    //     errors={errors}
    //     control={control}
    //     placeholder={placeholder}
    //     required={required}
    //     defaultValue={defaultValue}
    //     disabled={disabled}
    //     buttons={description ? <OverlayInfo icon="info">{description}</OverlayInfo> : undefined}
    //     uncontained={uncontained}
    //     style={style}
    //     isMulti
    //     horizontal
    //   />
    // );
    case "Boolean":
      return (
        <ToggleFormField
          id={id}
          label={label}
          errors={errors}
          control={control}
          required={required}
          defaultValue={defaultValue}
          disabled={disabled}
          uncontained={uncontained}
          style={style}
          horizontal
        >
          {description && <OverlayInfo icon="info">{description}</OverlayInfo>}
        </ToggleFormField>
      );
    // case "DateArray":
    // case "DateTimeArray":
    // case "TimeArray":
    //   // TODO
    //   return (
    //     <CreateableSelectFormField
    //       id={id}
    //       label={label}
    //       control={control}
    //       defaultOptions={[]}
    //       required={required}
    //       defaultValue={defaultValue}
    //       disabled={disabled}
    //         horizontal
    //     >
    //       {description && <OverlayInfo icon="info">{description}</OverlayInfo>}
    //     </CreateableSelectFormField>
    //   );
    case "DateTimeRange":
      return (
        <SingleDatePickerFormField
          type="datetime"
          id={id}
          label={label}
          errors={errors}
          control={control}
          placeholder={placeholder}
          required={required}
          defaultValue={defaultValue}
          disabled={disabled}
          children={description ? <OverlayInfo icon="info">{description}</OverlayInfo> : undefined}
          uncontained={uncontained}
          style={style}
          horizontal
          selectsRange
        />
      );
    case "TimeRange":
      return (
        <DurationPickerFormField
          id={id}
          label={label}
          errors={errors}
          control={control}
          placeholder={placeholder}
          required={required}
          defaultValue={defaultValue}
          disabled={disabled}
          uncontained={uncontained}
          style={style}
          horizontal
        >
          {description && <OverlayInfo icon="info">{description}</OverlayInfo>}
        </DurationPickerFormField>
      );
    case "Dataset":
      return (
        <DatasetsVirtualizedSelectForm
          id={id}
          label={label}
          control={control}
          required={required}
          defaultValue={defaultValue}
          placeholder={placeholder}
          disabled={disabled}
          filters={filters}
          uncontained={uncontained}
          style={style}
          horizontal
          showControls
          allowCreateEntity
        >
          {description && <OverlayInfo icon="info">{description}</OverlayInfo>}
        </DatasetsVirtualizedSelectForm>
      );
    case "Sample":
      return (
        <SamplesVirtualizedSelectForm
          id={id}
          label={label}
          control={control}
          required={required}
          defaultValue={defaultValue}
          placeholder={placeholder}
          disabled={disabled}
          filters={filters}
          uncontained={uncontained}
          style={style}
          horizontal
          showControls
          allowCreateEntity
        >
          {description && <OverlayInfo icon="info">{description}</OverlayInfo>}
        </SamplesVirtualizedSelectForm>
      );
    case "Project":
      return (
        <ProjectsVirtualizedSelectForm
          id={id}
          label={label}
          control={control}
          required={required}
          defaultValue={defaultValue}
          placeholder={placeholder}
          disabled={disabled}
          filters={{ ...filters, currentUserHasAddPermission: true }}
          uncontained={uncontained}
          style={style}
          horizontal
          showControls
          allowCreateEntity
        >
          {description && <OverlayInfo icon="info">{description}</OverlayInfo>}
        </ProjectsVirtualizedSelectForm>
      );
    case "Organization":
      return (
        <OrganizationsVirtualizedSelectForm
          id={id}
          label={label}
          control={control}
          required={required}
          defaultValue={defaultValue}
          placeholder={placeholder}
          disabled={disabled}
          filters={filters}
          uncontained={uncontained}
          style={style}
          horizontal
          showControls
          allowCreateEntity
        >
          {description && <OverlayInfo icon="info">{description}</OverlayInfo>}
        </OrganizationsVirtualizedSelectForm>
      );
    case "Person":
      return (
        <PersonsVirtualizedSelectForm
          id={id}
          label={label}
          control={control}
          required={required}
          defaultValue={defaultValue}
          placeholder={placeholder}
          disabled={disabled}
          filters={filters}
          uncontained={uncontained}
          style={style}
          horizontal
          showControls
          allowCreateEntity
        >
          {description && <OverlayInfo icon="info">{description}</OverlayInfo>}
        </PersonsVirtualizedSelectForm>
      );
    case "Method":
      return (
        <MethodsVirtualizedSelectForm
          id={id}
          label={label}
          control={control}
          required={required}
          defaultValue={defaultValue}
          placeholder={placeholder}
          disabled={disabled}
          filters={filters}
          uncontained={uncontained}
          style={style}
          horizontal
          showControls
          allowCreateEntity
        >
          {description && <OverlayInfo icon="info">{description}</OverlayInfo>}
        </MethodsVirtualizedSelectForm>
      );
    case "Instrument":
      return (
        <InstrumentsVirtualizedSelectForm
          id={id}
          label={label}
          control={control}
          required={required}
          defaultValue={defaultValue}
          placeholder={placeholder}
          disabled={disabled}
          filters={filters}
          uncontained={uncontained}
          style={style}
          horizontal
          showControls
          allowCreateEntity
        >
          {description && <OverlayInfo icon="info">{description}</OverlayInfo>}
        </InstrumentsVirtualizedSelectForm>
      );
    case "SharedContent":
      return (
        <SharedContentsVirtualizedSelectForm
          id={id}
          label={label}
          control={control}
          required={required}
          defaultValue={defaultValue}
          placeholder={placeholder}
          disabled={disabled}
          filters={filters}
          uncontained={uncontained}
          style={style}
          horizontal
          showControls
          allowCreateEntity
        >
          {description && <OverlayInfo icon="info">{description}</OverlayInfo>}
        </SharedContentsVirtualizedSelectForm>
      );
    case "LabNotebook":
      return (
        <LabNotebooksVirtualizedSelectForm
          id={id}
          label={label}
          control={control}
          required={required}
          defaultValue={defaultValue}
          placeholder={placeholder}
          disabled={disabled}
          filters={filters}
          uncontained={uncontained}
          style={style}
          horizontal
          showControls
          allowCreateEntity
        >
          {description && <OverlayInfo icon="info">{description}</OverlayInfo>}
        </LabNotebooksVirtualizedSelectForm>
      );
    case "LabNotebookExperiment":
      return (
        <LabNotebookExperimentsVirtualizedSelectForm
          id={id}
          label={label}
          control={control}
          required={required}
          defaultValue={defaultValue}
          placeholder={placeholder}
          disabled={disabled}
          filters={filters}
          uncontained={uncontained}
          style={style}
          horizontal
          showControls
          allowCreateEntity
        >
          {description && <OverlayInfo icon="info">{description}</OverlayInfo>}
        </LabNotebookExperimentsVirtualizedSelectForm>
      );
    case "LabNotebookEntry":
      return (
        <LabNotebookEntriesVirtualizedSelectForm
          id={id}
          label={label}
          control={control}
          required={required}
          defaultValue={defaultValue}
          placeholder={placeholder}
          disabled={disabled}
          filters={filters}
          uncontained={uncontained}
          style={style}
          horizontal
          showControls
          allowCreateEntity
        >
          {description && <OverlayInfo icon="info">{description}</OverlayInfo>}
        </LabNotebookEntriesVirtualizedSelectForm>
      );
    case "Inventory":
      return (
        <InventoriesVirtualizedSelectForm
          id={id}
          label={label}
          control={control}
          required={required}
          defaultValue={defaultValue}
          placeholder={placeholder}
          disabled={disabled || undefined}
          filters={filters}
          uncontained={uncontained}
          style={style}
          customTypeConstraint={customTypeConstraint}
          horizontal
          showControls
          allowCreateEntity
        >
          {description && <OverlayInfo icon="info">{description}</OverlayInfo>}
        </InventoriesVirtualizedSelectForm>
      );

    case "DatasetArray":
      return (
        <DatasetsVirtualizedSelectForm
          id={id}
          label={label}
          control={control}
          required={required}
          defaultValue={defaultValue}
          placeholder={placeholder}
          disabled={disabled}
          filters={filters}
          uncontained={uncontained}
          style={style}
          horizontal
          showControls
          allowCreateEntity
          isMulti
        >
          {description && <OverlayInfo icon="info">{description}</OverlayInfo>}
        </DatasetsVirtualizedSelectForm>
      );
    case "SampleArray":
      return (
        <SamplesVirtualizedSelectForm
          id={id}
          label={label}
          control={control}
          required={required}
          defaultValue={defaultValue}
          placeholder={placeholder}
          disabled={disabled}
          filters={filters}
          uncontained={uncontained}
          style={style}
          horizontal
          showControls
          allowCreateEntity
          isMulti
        >
          {description && <OverlayInfo icon="info">{description}</OverlayInfo>}
        </SamplesVirtualizedSelectForm>
      );
    case "ProjectArray":
      return (
        <ProjectsVirtualizedSelectForm
          id={id}
          label={label}
          control={control}
          required={required}
          defaultValue={defaultValue}
          placeholder={placeholder}
          disabled={disabled}
          filters={{ ...filters, currentUserHasAddPermission: true }}
          uncontained={uncontained}
          style={style}
          horizontal
          showControls
          allowCreateEntity
          isMulti
        >
          {description && <OverlayInfo icon="info">{description}</OverlayInfo>}
        </ProjectsVirtualizedSelectForm>
      );
    case "OrganizationArray":
      return (
        <OrganizationsVirtualizedSelectForm
          id={id}
          label={label}
          control={control}
          required={required}
          defaultValue={defaultValue}
          placeholder={placeholder}
          disabled={disabled}
          filters={filters}
          uncontained={uncontained}
          style={style}
          horizontal
          showControls
          allowCreateEntity
          isMulti
        >
          {description && <OverlayInfo icon="info">{description}</OverlayInfo>}
        </OrganizationsVirtualizedSelectForm>
      );
    case "PersonArray":
      return (
        <PersonsVirtualizedSelectForm
          id={id}
          label={label}
          control={control}
          required={required}
          defaultValue={defaultValue}
          placeholder={placeholder}
          disabled={disabled}
          filters={filters}
          uncontained={uncontained}
          style={style}
          horizontal
          showControls
          allowCreateEntity
          isMulti
        >
          {description && <OverlayInfo icon="info">{description}</OverlayInfo>}
        </PersonsVirtualizedSelectForm>
      );
    case "MethodArray":
      return (
        <MethodsVirtualizedSelectForm
          id={id}
          label={label}
          control={control}
          required={required}
          defaultValue={defaultValue}
          placeholder={placeholder}
          disabled={disabled}
          filters={filters}
          uncontained={uncontained}
          style={style}
          horizontal
          showControls
          allowCreateEntity
          isMulti
        >
          {description && <OverlayInfo icon="info">{description}</OverlayInfo>}
        </MethodsVirtualizedSelectForm>
      );
    case "InstrumentArray":
      return (
        <InstrumentsVirtualizedSelectForm
          id={id}
          label={label}
          control={control}
          required={required}
          defaultValue={defaultValue}
          placeholder={placeholder}
          disabled={disabled}
          filters={filters}
          uncontained={uncontained}
          style={style}
          horizontal
          showControls
          allowCreateEntity
          isMulti
        >
          {description && <OverlayInfo icon="info">{description}</OverlayInfo>}
        </InstrumentsVirtualizedSelectForm>
      );
    case "SharedContentArray":
      return (
        <SharedContentsVirtualizedSelectForm
          id={id}
          label={label}
          control={control}
          required={required}
          defaultValue={defaultValue}
          placeholder={placeholder}
          disabled={disabled}
          filters={filters}
          uncontained={uncontained}
          style={style}
          horizontal
          showControls
          allowCreateEntity
          isMulti
        >
          {description && <OverlayInfo icon="info">{description}</OverlayInfo>}
        </SharedContentsVirtualizedSelectForm>
      );
    case "LabNotebookArray":
      return (
        <LabNotebooksVirtualizedSelectForm
          id={id}
          label={label}
          control={control}
          required={required}
          defaultValue={defaultValue}
          placeholder={placeholder}
          disabled={disabled}
          filters={filters}
          uncontained={uncontained}
          style={style}
          horizontal
          showControls
          allowCreateEntity
          isMulti
        >
          {description && <OverlayInfo icon="info">{description}</OverlayInfo>}
        </LabNotebooksVirtualizedSelectForm>
      );
    case "LabNotebookExperimentArray":
      return (
        <LabNotebookExperimentsVirtualizedSelectForm
          id={id}
          label={label}
          control={control}
          required={required}
          defaultValue={defaultValue}
          placeholder={placeholder}
          disabled={disabled}
          filters={filters}
          uncontained={uncontained}
          style={style}
          horizontal
          showControls
          allowCreateEntity
          isMulti
        >
          {description && <OverlayInfo icon="info">{description}</OverlayInfo>}
        </LabNotebookExperimentsVirtualizedSelectForm>
      );
    case "LabNotebookEntryArray":
      return (
        <LabNotebookEntriesVirtualizedSelectForm
          id={id}
          label={label}
          control={control}
          required={required}
          defaultValue={defaultValue}
          placeholder={placeholder}
          disabled={disabled}
          filters={filters}
          uncontained={uncontained}
          style={style}
          horizontal
          showControls
          allowCreateEntity
          isMulti
        >
          {description && <OverlayInfo icon="info">{description}</OverlayInfo>}
        </LabNotebookEntriesVirtualizedSelectForm>
      );
    case "InventoryArray":
      return (
        <InventoriesVirtualizedSelectForm
          id={id}
          label={label}
          control={control}
          required={required}
          defaultValue={defaultValue}
          placeholder={placeholder}
          disabled={disabled || undefined}
          filters={filters}
          uncontained={uncontained}
          customTypeConstraint={customTypeConstraint}
          style={style}
          horizontal
          showControls
          allowCreateEntity
          isMulti
        >
          {description && <OverlayInfo icon="info">{description}</OverlayInfo>}
        </InventoriesVirtualizedSelectForm>
      );
    case "ViewableEntity":
      return (
        <ManualUploadInlineWrapper
          id={id}
          label={label}
          control={control}
          required={required}
          defaultValue={defaultValue}
          placeholder={placeholder}
          disabled={disabled}
          uncontained={uncontained}
          style={style}
          horizontal
        />
      );

    case "EntityBarCode":
      //
      // return <Alert type="light" message="This data type has no edit view" fit centered />;
      // return (
      //   <BarcodeFormField
      //     id={id}
      //     register={register}
      //     label={label}
      //     text={"00000000-0000-0000-0000-000000000000"}
      //     horizontal
      //   />
      // );
      return <></>; // Rendering an edit field makes no sense here
    default:
      return <></>;
  }
};
