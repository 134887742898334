import { generateUid } from "../../tools/UID/UID";
import { Data1DReal } from "../../ViewerLayout/ViewerTypes";
import { argumentType, commandTypes, parameterInputTypes, parameterTypes, trackListType } from "../PipelineTypes";
import { StackTraces } from "../StackTrace";
import { Empty } from "./Empty";

// enum functionType {
//   none = "none",
//   exp = "exp",
//   sine = "sine",
// }

type expParameterType = { lb: number };
type sineParameterType = { off: number; end: number; pow: number };
type gaussParameterType = { lb: number };

// type windowParameterType = expParameterType | sineParameterType | gaussParameterType;

// type parameterSetType = { name: functionType; parameter: windowParameterType };
// type parameterType = { type: parameterSetType };

export class WindowFunction extends Empty {
  id: string;
  name: string;
  readonly type = commandTypes.windowFunction;
  errors: StackTraces;
  warnings: StackTraces;

  // parameterTypes = { type: parameterTypes.string, lb: parameterTypes.float };
  // parameterSettings = { type: { name: "Function" }, lb: { name: "Line broadening" } };

  parameterTypes = { type: parameterTypes.parameterSets };
  parameterSettings = {
    type: {
      selected: "exp",
      name: "Function",
      value: [
        {
          name: "exp",
          parameter: {
            // type: {
            //   parameterType: "string",
            //   inputType: "options",
            //   options: ["none", "exp"],
            //   value: "exp",
            // },
            lb: { parameterType: "float", value: 0, name: "Exponential line broadening [units of points]" },
          },
        },
        {
          name: "sine",
          parameter: {
            // type: {
            //   parameterType: "string",
            //   inputType: "options",
            //   options: ["none", "exp"],
            //   value: "exp",
            // },
            off: {
              parameterType: "float",
              value: 0,
              name: "Offset start of sine-bell",
              inputType: parameterInputTypes.range,
              min: 0,
              max: 1,
            },
            end: {
              parameterType: "float",
              value: 1,
              name: "End of of sine-bell",
              inputType: parameterInputTypes.range,
              min: 0,
              max: 1,
            },
            pow: { parameterType: "int", value: 2, min: 1, name: "Power to raise sine-bell to" },
          },
        },
      ],
    },
  };

  // "optional": {
  //   "name": "Window Function",
  //   "value": [
  //     {
  //       "name": "phasing",
  //       "parameter": {
  //         "phase0": {
  //           "parameterType": "float",
  //           "inputType": "range",
  //           "min": -3.14159265358979323,
  //           "max": 3.14159265358979323,
  //           "value": 0
  //         },
  //         "phase1": {
  //           "parameterType": "float",
  //           "inputType": "range",
  //           "min": -3.14159265358979323,
  //           "max": 3.14159265358979323,
  //           "value": 0
  //         },
  //         "pivot": { "parameterType": "float", "inputType": "xPick", "value": "Infinity" }
  //       }
  //     },
  //     {
  //       "name": "window",
  //       "parameter": {
  //         "type": {
  //           "parameterType": "string",
  //           "inputType": "options",
  //           "options": ["none", "exp"],
  //           "value": "exp"
  //         },
  //         "lb": { "parameterType": "int", "value": 1 }
  //       }
  //     }
  //   ]

  internalParameter: Record<string, any>;
  readonly input: argumentType[];
  readonly output: argumentType[];

  constructor() {
    super();
    this.id = generateUid();
    this.name = "NMR window function";
    this.errors = new StackTraces();
    this.warnings = new StackTraces();
    this.internalParameter = {};
    this.input = [
      { name: "FID real", type: "1D_real" },
      { name: "FID imaginary", type: "1D_real" },
    ];
    this.output = [
      { name: "FID real scaled", type: "1D_real" },
      { name: "FID imaginary scaled", type: "1D_real" },
    ];
  }

  static expWindow(data: Data1DReal, parameter: expParameterType): undefined | string {
    // apod = np.exp(-np.pi * np.arange(data.shape[-1]) * lb).astype(data.dtype)
    const { lb } = parameter;
    // const lb = 1;
    // console.log("lb", lb);

    const sw = data.length;

    const f = (x: number) => Math.exp((-Math.PI * lb * x) / sw);
    const f0 = f(sw);
    const w = (x: number) => (1 + f0) * f(x) - f0;
    for (let i = 0; i < data.length; i++) {
      data.y[i] *= w(i);
    }
    return undefined;
  }

  static sineWindow(data: Data1DReal, parameter: sineParameterType): undefined | string {
    const { off, end, pow } = parameter;

    const size = data.length - 1;
    const s = (off < 0 ? 0 : off > 1 ? 1 : off) * size;
    const e = (end < 0 ? 0 : end > 1 ? 1 : end) * size;

    const f = (x: number) => Math.cos((0.5 * (x - s) * Math.PI) / (e - s)) ** pow;
    const w = (x: number) => (x < 2 * s - e ? 0 : x > e ? 0 : f(x));

    for (let i = 0; i < data.length; i++) {
      data.y[i] *= w(i);
    }

    return undefined;
  }

  static gaussWindow(data: Data1DReal, parameter: gaussParameterType): undefined | string {
    return undefined;
  }

  run(tracks: trackListType): trackListType {
    // // return parameter?.trackList.value(tracks) ?? [];
    // if (!tracks?.[0]?.data || !tracks?.[1]?.data) {
    //   this.errors.create({
    //     id: this.id,
    //     component: "WindowFunction.run",
    //     message: `Some tracks do not contain any data.`,
    //   });
    //   return [];
    // }
    // const reIn: Data1DReal = tracks[0].data;
    // const imIn: Data1DReal = tracks[1].data;

    // const { type } = this.parameter as parameterType;
    // // const type: string = "exp";
    // // const selected = type[type.selected];
    // // console.log("param", this.name);

    // let func: (data: Data1DReal, parameter: any) => undefined | string;
    // switch (type.name) {
    //   case functionType.none:
    //     func = () => {
    //       return undefined;
    //     };
    //     break;
    //   case functionType.exp:
    //     func = WindowFunction.expWindow;
    //     break;
    //   case functionType.sine:
    //     func = WindowFunction.sineWindow;
    //     break;
    //   default:
    //     this.errors.create({
    //       id: this.id,
    //       component: "WindowFunction.run",
    //       message: `Unknown window function type '${type}'. (Expected one of '${Object.values(functionType).join(
    //         ", "
    //       )}')`,
    //     });
    //     return [];
    // }
    // // if (type in Object.val)
    // let m = func(reIn, type.parameter);
    // if (m) {
    //   this.errors.create({
    //     id: this.id,
    //     component: "WindowFunction.run",
    //     message: m,
    //   });
    //   return [];
    // }
    // m = func(imIn, type.parameter);

    // tracks[0].label = "window.re";
    // tracks[1].label = "window.im";

    // // tracks[0].parameter.color = undefined;
    // // tracks[1].parameter.color = undefined;
    // // console.log("tracks", tracks);

    return tracks;
  }
}
