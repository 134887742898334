import React, { CSSProperties, useContext } from "react";
import { CustomField, customFieldConstants, CustomFieldDataTypeUtils } from "../../api/CustomFields";
import { Table } from "../../common/panels/Detail/DetailTable";
import { NotAvailable, NotSet } from "../../common/misc/UIconstants";
import { OverlayInfo } from "../../common/misc/OverlayInfo/OverlayInfo";
import {
  DateTimeRenderer,
  RenderTime,
  TimeSpanRenderer,
  totalSecondsToTimeSpan,
} from "../../common/datetime/DateTimeFormatter";
import { Barcode } from "../../common/icon/barcode/Barcode";
import { LinkEntity } from "../../common/misc/LinkEntity/LinkEntity";
import { RenderArray, RenderMinimalEntity, TableArrayRenderer } from "../../common/misc/EntityRenders/EntityRenderer";
import { IdTypes, IEntityMinimalModel, IUniqueEntity } from "../../api/GenericTypes";
import { copyTextToClipboard } from "../../common/helperfunctions/copyToClipboard";
import { LucideIcon } from "../../common/icon/LucideIcon";
import { toUppercase } from "../../common/helperfunctions/stringFunctions";
import { FloatingDiv } from "../../common/buttons/FloatingDiv/FloatingDiv";
import { ToggleRenderer } from "../../common/buttons/ToggleSwitch/ToggleSwitch";
import { AttachmentMinimalView } from "../../Attachments/AttachmentMinimalView";
import { InventoryItemsWrapper, InventoryItemWrapper } from "../../Inventories/common/InventoryItemRenderUtils";
import { EntityBreadcrumbs } from "../../common/hierarchy/breadcrumbs/EntityBreadcrumbs";
import { inventoryItemsConstants } from "../../api/Inventories";
import { SessionContext } from "../../common/contexts/SessionContext";
import { attachmentsConstants } from "../../api/Attachments";
import { LabNotebookEntry, LabNotebookEntrySuggestions } from "../../ELN/types/LabNotebookEntry";

interface RowContentProps {
  title?: string;
  customField: Partial<CustomField>;
  rowStyle?: CSSProperties;
  includeCustomFieldId?: boolean;
  children: React.ReactNode;
}
const RowContent = ({ title, customField, rowStyle, includeCustomFieldId = false, children }: RowContentProps) => {
  return (
    <Table.Body.RowContent
      rowStyle={rowStyle}
      title={title ?? ""}
      content={
        <div className="flex row-nowrap align-center gap-5" style={{ width: "100%" }}>
          {children}
          <div className="flex row-nowrap align-center gap-5" style={{ marginLeft: "auto" }}>
            {customField.description && <OverlayInfo icon="info">{customField.description}</OverlayInfo>}
            {includeCustomFieldId && (
              <div className="container_label_id">
                <span>
                  <LinkEntity
                    entityConstants={customFieldConstants}
                    property={customField as IEntityMinimalModel<IdTypes>}
                    labelOverride={`${toUppercase(customFieldConstants.entitySingular)}-ID: ${customField.id}`}
                    style={{ color: "var(--gray-400)" }}
                    openInNewTab
                    hideIcon
                  />
                </span>
              </div>
            )}
          </div>
        </div>
      }
    />
  );
};

interface RowContentArrayProps extends Omit<RowContentProps, "children"> {
  values: any;
  children: (value: any) => React.ReactNode;
}
const RowContentArray = <T extends Partial<{ value: any }>>({
  title,
  customField,
  rowStyle,
  includeCustomFieldId = false,
  values,
  children,
}: RowContentArrayProps) => {
  return (
    <Table.Body.RowContent
      rowStyle={rowStyle}
      title={title ?? ""}
      content={
        <RenderArray<T>
          values={values}
          additionalChildren={
            <div className="flex row-nowrap align-center gap-5" style={{ margin: "5px 0 0 auto" }}>
              {customField.description && <OverlayInfo icon="info">{customField.description}</OverlayInfo>}
              {includeCustomFieldId && (
                <div className="container_label_id">
                  <span>
                    <LinkEntity
                      entityConstants={customFieldConstants}
                      property={customField as IEntityMinimalModel<IdTypes>}
                      labelOverride={`${toUppercase(customFieldConstants.entitySingular)}-ID: ${customField.id}`}
                      style={{ color: "var(--gray-400)" }}
                      openInNewTab
                      hideIcon
                    />
                  </span>
                </div>
              )}
            </div>
          }
        >
          {(val, index) => (
            <div className="ellipsisContainer" key={index}>
              <span>{children?.(val.value ?? val)}</span>
            </div>
          )}
        </RenderArray>
      }
    />
  );
};

// Table renderer components
const BarcodeTableRenderer = <Entity extends IUniqueEntity>({ entity }: { entity: Entity }) => {
  return (
    <FloatingDiv
      triggerComponent={({ ref, getReferenceProps, setOpen }) => (
        <div
          ref={ref}
          className="flex justify-center align-center gap-5"
          style={{ width: "100%", height: "100%" }}
          onMouseEnter={() => setOpen(true)}
          onMouseLeave={() => setOpen(false)}
          {...getReferenceProps}
        >
          <Barcode text={entity.uid} />
        </div>
      )}
    >
      {() => (
        <div
          style={{
            display: "flex",
            border: "1px solid var(--gray-300)",
            borderRadius: "5px",
            background: "var(--white)",
            width: "150px",
            height: "150px",
            overflow: "hidden",
            padding: "5px",
          }}
        >
          <Barcode text={entity.uid} />
        </div>
      )}
    </FloatingDiv>
  );
};

// Used to display customFieldValues in Tables
export const CustomFieldValueRenderer = <Entity extends IUniqueEntity>({
  title,
  value,
  entity,
  customfield,
}: CustomFieldRendererProps<Entity>) => {
  switch (customfield.dataType) {
    case "String":
    case "Url":
      return (
        <>
          {value ? (
            <div className="ellipsisContainer">
              <span>{value}</span>
            </div>
          ) : (
            NotSet
          )}
        </>
      );
    case "Integer":
    case "Float":
      return (
        <>
          {value ? (
            <div className="container_label">
              <div className="container_label_name">
                <samp>{value}</samp>
              </div>
            </div>
          ) : (
            NotSet
          )}
        </>
      );
    case "Boolean":
      return <ToggleRenderer value={Boolean(value)} />;
    case "StringArray":
      return <TableArrayRenderer<string> values={value}>{(val, index) => val}</TableArrayRenderer>;
    case "IntegerArray":
    case "FloatArray":
      return <TableArrayRenderer<string> values={value}>{(val, index) => <samp>{val}</samp>}</TableArrayRenderer>;
    case "UrlArray":
      return (
        <TableArrayRenderer<string> values={value}>
          {(val, index) => (
            <>
              {val}
              {`${index < value.length - 1 ? "," : ""}`}{" "}
            </>
          )}
        </TableArrayRenderer>
      );
    case "Date":
      return <DateTimeRenderer date={value} includeTime={false} includeElapsed={false} />;
    case "DateTime":
      return <DateTimeRenderer date={value} includeTime includeElapsed={false} />;
    case "Time":
      return <RenderTime time={value} />;
    case "DateArray":
    case "DateTimeArray":
    case "TimeArray":
      return (
        <TableArrayRenderer<string> values={value}>
          {(val, index) => (
            <>
              {customfield.dataType === "TimeArray" ? (
                <RenderTime time={val} />
              ) : (
                <DateTimeRenderer
                  date={val}
                  includeTime={customfield.dataType === "DateTimeArray"}
                  includeDate={["DateArray", "DateTimeArray"].includes(customfield.dataType!)}
                  includeElapsed={false}
                  style={{ overflow: "hidden", textOverflow: "ellipsis" }}
                />
              )}
            </>
          )}
        </TableArrayRenderer>
      );
    case "TimeRange":
      return (
        <>
          {value ? (
            <div className="ellipsisContainer">
              <span>{TimeSpanRenderer(totalSecondsToTimeSpan(value))}</span>
            </div>
          ) : (
            NotSet
          )}
        </>
      );
    case "DateTimeRange":
      return (
        <>
          {Array.isArray(value) && value.length === 2 ? (
            <div className="ellipsisContainer">
              <span className="flex row-nowrap align-center">
                <DateTimeRenderer date={value[0]} includeTime includeElapsed={false} />
                <span style={{ color: "var(--gray-300)" }}>-</span>
                <DateTimeRenderer date={value[1]} includeTime includeElapsed={false} />
              </span>
            </div>
          ) : (
            NotSet
          )}
        </>
      );
    case "EntityBarCode":
      return <BarcodeTableRenderer entity={entity} />;
    case "Dataset":
    case "Sample":
    case "Project":
    case "Organization":
    case "Person":
    case "Method":
    case "Instrument":
    case "SharedContent":
    case "LabNotebook":
    case "LabNotebookExperiment":
    case "Inventory":
      return (
        <>
          {value ? (
            <div className="ellipsisContainer">
              <span>
                <LucideIcon
                  name={CustomFieldDataTypeUtils.MapDataTypeToEntityConstants(customfield.dataType).icon}
                  color={"var(--primary)"}
                />{" "}
                {value.name}
              </span>
            </div>
          ) : (
            NotSet
          )}
        </>
      );
    case "LabNotebookEntry":
      return (
        <>
          {value ? (
            <div className="ellipsisContainer">
              <span>
                <LucideIcon
                  name={CustomFieldDataTypeUtils.MapDataTypeToEntityConstants(customfield.dataType).icon}
                  color={"var(--primary)"}
                />{" "}
                {value.name}
              </span>
            </div>
          ) : (
            NotSet
          )}
        </>
      );
    case "DatasetArray":
    case "SampleArray":
    case "ProjectArray":
    case "OrganizationArray":
    case "PersonArray":
    case "MethodArray":
    case "InstrumentArray":
    case "SharedContentArray":
    case "LabNotebookArray":
    case "LabNotebookExperimentArray":
    case "InventoryArray":
      return (
        <TableArrayRenderer<IEntityMinimalModel> values={value}>
          {(value) => (
            <>
              <LucideIcon
                name={CustomFieldDataTypeUtils.MapDataTypeToEntityConstants(customfield.dataType).icon}
                color={"var(--primary)"}
              />{" "}
              {value.name}
            </>
          )}
        </TableArrayRenderer>
      );
    case "LabNotebookEntryArray":
      return (
        <TableArrayRenderer<LabNotebookEntry | LabNotebookEntrySuggestions> values={value}>
          {(value) => (
            <>
              <LucideIcon
                name={CustomFieldDataTypeUtils.MapDataTypeToEntityConstants(customfield.dataType).icon}
                color={"var(--primary)"}
              />{" "}
              {value.name}
            </>
          )}
        </TableArrayRenderer>
      );
    case "ViewableEntity":
      return (
        <>
          {value ? (
            <div className="ellipsisContainer">
              <span>
                <LucideIcon name={attachmentsConstants.icon} color="var(--primary)" /> {value.name}
              </span>
            </div>
          ) : (
            NotSet
          )}
        </>
      );
    // return <>{value ? <ToggleRenderer value={true} /> : <ToggleRenderer value={false} />}</>;
    // return <>{value ? <ViewableEntityTableRenderer value={value} /> : NotSet}</>;

    default:
      return <>No renderer defined for dataType: {customfield.dataType}</>;
  }
};

// Detail renderer components
interface CustomFieldRendererProps<Entity extends IUniqueEntity> {
  title: string;
  value: any;
  entity: Entity;
  customfield: Partial<CustomField>;
  rowStyle?: CSSProperties;
  includeCustomFieldId?: boolean;
  showLinks?: boolean;
}
/**
 * Renders a custom field based on its data type.
 * @author @CorradoSurmanowicz
 * @template Entity - The type of the entity that extends IUniqueEntity.
 * @param {CustomFieldRendererProps<Entity>} props - The properties for the custom field renderer.
 * @param {string} props.title - The title of the custom field.
 * @param {any} props.value - The value of the custom field.
 * @param {Entity} props.entity - The entity associated with the custom field.
 * @param {CustomField} props.customfield - The custom field definition.
 * @param {React.CSSProperties} [props.rowStyle] - Optional styles for the row.
 * @param {boolean} [props.includeCustomFieldId=true] - Whether to include the custom field ID.
 * @param {boolean} [props.showLinks=true] - Whether to show links for certain field types.
 * @returns {JSX.Element} The rendered custom field.
 */
export const CustomFieldRenderer = <Entity extends IUniqueEntity>({
  title,
  value,
  entity,
  customfield,
  rowStyle,
  includeCustomFieldId = true,
  showLinks = true,
}: CustomFieldRendererProps<Entity>) => {
  const { route } = useContext(SessionContext);
  if (!customfield) return <></>;

  switch (customfield.dataType) {
    case "String":
      return (
        <RowContent
          rowStyle={rowStyle}
          includeCustomFieldId={includeCustomFieldId}
          title={title}
          customField={customfield}
        >
          <>
            {value ? (
              customfield.showAsTextArea ? (
                <div className="container_notes">
                  <textarea rows={4} className="container_textarea" value={value} disabled />
                </div>
              ) : (
                <div className="flex" style={{ overflow: "auto" }}>
                  {value}
                </div>
              )
            ) : (
              NotSet
            )}
          </>
        </RowContent>
      );
    case "Integer":
    case "Float":
      return (
        <RowContent
          rowStyle={rowStyle}
          includeCustomFieldId={includeCustomFieldId}
          title={title}
          customField={customfield}
        >
          <>
            {value ? (
              <div className="container_label">
                <div className="container_label_name">
                  <samp>{value}</samp>
                </div>
              </div>
            ) : (
              NotSet
            )}
          </>
        </RowContent>
      );
    case "Boolean":
      return (
        <RowContent
          rowStyle={rowStyle}
          includeCustomFieldId={includeCustomFieldId}
          title={title}
          customField={customfield}
        >
          <ToggleRenderer value={Boolean(value)} />
        </RowContent>
      );
    case "StringArray":
    case "IntegerArray":
    case "FloatArray":
      return (
        <RowContentArray
          rowStyle={rowStyle}
          includeCustomFieldId={includeCustomFieldId}
          title={title}
          customField={customfield}
          values={value}
        >
          {(value) => <samp>{value}</samp>}
        </RowContentArray>
      );
    case "Date":
      // Todo: Check if this is correct
      return (
        <RowContent
          rowStyle={rowStyle}
          includeCustomFieldId={includeCustomFieldId}
          title={title}
          customField={customfield}
        >
          <DateTimeRenderer date={value} includeTime={false} includeElapsed={false} />
        </RowContent>
      );
    case "DateTime":
      return (
        <RowContent
          rowStyle={rowStyle}
          includeCustomFieldId={includeCustomFieldId}
          title={title}
          customField={customfield}
        >
          <DateTimeRenderer date={value} includeTime includeElapsed={false} />
        </RowContent>
      );
    case "Time":
      return (
        <RowContent
          rowStyle={rowStyle}
          includeCustomFieldId={includeCustomFieldId}
          title={title}
          customField={customfield}
        >
          <RenderTime time={value} />
        </RowContent>
      );
    case "DateArray":
      return (
        <RowContentArray
          rowStyle={rowStyle}
          includeCustomFieldId={includeCustomFieldId}
          title={title}
          customField={customfield}
          values={value}
        >
          {(value) => <DateTimeRenderer date={value} includeTime={false} includeElapsed={false} />}
        </RowContentArray>
      );
    case "TimeArray":
      return (
        <RowContentArray
          rowStyle={rowStyle}
          includeCustomFieldId={includeCustomFieldId}
          title={title}
          customField={customfield}
          values={value}
        >
          {(value) => <RenderTime time={value} />}
        </RowContentArray>
      );
    case "DateTimeArray":
      return (
        <RowContentArray
          rowStyle={rowStyle}
          includeCustomFieldId={includeCustomFieldId}
          title={title}
          customField={customfield}
          values={value}
        >
          {(value) => <DateTimeRenderer date={value} includeTime includeElapsed={false} />}
        </RowContentArray>
      );
    case "TimeRange":
      return (
        <RowContent
          rowStyle={rowStyle}
          includeCustomFieldId={includeCustomFieldId}
          title={title}
          customField={customfield}
        >
          {value ? (
            <div className="ellipsisContainer">
              <span>{TimeSpanRenderer(totalSecondsToTimeSpan(value))}</span>
            </div>
          ) : (
            NotSet
          )}
        </RowContent>
      );
    case "DateTimeRange":
      return (
        <RowContent
          rowStyle={rowStyle}
          includeCustomFieldId={includeCustomFieldId}
          title={title}
          customField={customfield}
        >
          <>
            {Array.isArray(value) && value.length === 2 ? (
              <div className="ellipsisContainer">
                <span>
                  <div className="row-wrap align-center gap-5" style={{ display: "inline-flex" }}>
                    <DateTimeRenderer
                      date={value[0]}
                      includeTime
                      includeElapsed={false}
                      style={{ overflow: "hidden", width: "fit-content" }}
                    />
                    <span style={{ color: "var(--gray-300)" }}>-</span>
                    <DateTimeRenderer
                      date={value[1]}
                      includeTime
                      includeElapsed={false}
                      style={{ overflow: "hidden", width: "fit-content" }}
                    />
                  </div>
                </span>
              </div>
            ) : (
              NotSet
            )}
          </>
        </RowContent>
      );
    case "EntityBarCode":
      return (
        <RowContent
          rowStyle={rowStyle}
          includeCustomFieldId={includeCustomFieldId}
          title={title}
          customField={customfield}
        >
          <Barcode text={entity.uid} />
          <button
            className="btn btn-default"
            title="Copy to clipboard"
            onClick={() => copyTextToClipboard(entity.uid)}
            style={{ marginLeft: "auto" }}
          >
            <LucideIcon name="copy" /> Copy
          </button>
        </RowContent>
      );
    case "Dataset":
    case "Sample":
    case "Project":
    case "Organization":
    case "Person":
    case "Method":
    case "Instrument":
    case "SharedContent":
    case "LabNotebook":
    case "LabNotebookExperiment":
      return (
        <RowContent
          rowStyle={rowStyle}
          includeCustomFieldId={includeCustomFieldId}
          title={title}
          customField={customfield}
        >
          <div className="ellipsisContainer gap-5">
            <LinkEntity
              entityConstants={CustomFieldDataTypeUtils.MapDataTypeToEntityConstants(customfield.dataType)}
              property={value}
              showLink={showLinks}
            />
          </div>
        </RowContent>
      );
    case "LabNotebookEntry":
      return (
        <RowContent
          rowStyle={rowStyle}
          includeCustomFieldId={includeCustomFieldId}
          title={title}
          customField={customfield}
        >
          {!showLinks ? (
            <>
              {value ? (
                <span>
                  <LucideIcon
                    name={CustomFieldDataTypeUtils.MapDataTypeToEntityConstants(customfield.dataType).icon}
                    color={"var(--primary)"}
                  />{" "}
                  {value?.name ?? NotAvailable}
                </span>
              ) : (
                NotSet
              )}
            </>
          ) : !!value && typeof value === "object" && Object.hasOwn(value, "id") ? (
            <LinkEntity
              entityConstants={CustomFieldDataTypeUtils.MapDataTypeToEntityConstants(customfield.dataType)}
              property={value}
            />
          ) : (
            NotSet
          )}
        </RowContent>
      );
    case "Inventory":
      return (
        <RowContent
          rowStyle={rowStyle}
          includeCustomFieldId={includeCustomFieldId}
          title={title}
          customField={customfield}
        >
          {!showLinks ? (
            <>
              {value ? (
                <span>
                  <LucideIcon
                    name={CustomFieldDataTypeUtils.MapDataTypeToEntityConstants(customfield.dataType).icon}
                    color={"var(--primary)"}
                  />{" "}
                  {value?.name ?? NotAvailable}
                </span>
              ) : (
                NotSet
              )}
            </>
          ) : !!value && typeof value === "object" && Object.hasOwn(value, "id") ? (
            <InventoryItemWrapper id={value.id}>
              {(item) => (
                <EntityBreadcrumbs
                  entity={item.entity}
                  breadcrumbs={item.entity.ancestors}
                  entityConstants={inventoryItemsConstants}
                  indexEntityLabelOverride={item.entity.rootCustomType?.inventoryName}
                  indexEntityLinkToOverride={item.indexEntityLinkToOverride}
                  entityLinkToOverride={item.entityLinkToOverride}
                  ancestorLinkToOverride={item.ancestorLinkToOverride}
                  maxWidth={"100%"}
                  renderInline
                  showLinks={showLinks}
                />
              )}
            </InventoryItemWrapper>
          ) : (
            NotSet
          )}
        </RowContent>
      );
    case "DatasetArray":
    case "SampleArray":
    case "ProjectArray":
    case "OrganizationArray":
    case "PersonArray":
    case "MethodArray":
    case "InstrumentArray":
    case "SharedContentArray":
    case "LabNotebookArray":
    case "LabNotebookExperimentArray":
      return (
        <RowContent
          rowStyle={rowStyle}
          includeCustomFieldId={includeCustomFieldId}
          title={title}
          customField={customfield}
        >
          <RenderMinimalEntity
            entities={value}
            entityConstants={CustomFieldDataTypeUtils.MapDataTypeToEntityConstants(customfield.dataType)}
            showLinks={showLinks}
          />
        </RowContent>
      );
    case "LabNotebookEntryArray":
      return (
        <RowContent
          rowStyle={rowStyle}
          includeCustomFieldId={includeCustomFieldId}
          title={title}
          customField={customfield}
        >
          {!showLinks ? (
            <RenderMinimalEntity
              entities={value}
              entityConstants={CustomFieldDataTypeUtils.MapDataTypeToEntityConstants(customfield.dataType)}
              showLinks={false}
            />
          ) : value && Array.isArray(value) && value.length > 0 ? (
            <div
              style={{
                width: "100%",
                overflow: "hidden",
              }}
            >
              <RenderMinimalEntity
                entities={value}
                entityConstants={CustomFieldDataTypeUtils.MapDataTypeToEntityConstants(customfield.dataType)}
                showLinks={true}
              />
            </div>
          ) : (
            NotSet
          )}
        </RowContent>
      );
    case "InventoryArray":
      return (
        <RowContent
          rowStyle={rowStyle}
          includeCustomFieldId={includeCustomFieldId}
          title={title}
          customField={customfield}
        >
          {value && Array.isArray(value) && value.length > 0 ? (
            <div
              style={{
                width: "100%",
                overflow: "hidden",
              }}
            >
              <InventoryItemsWrapper ids={value.map((v) => v.id)}>
                {(data) =>
                  data.map((item, index) => (
                    <EntityBreadcrumbs
                      entity={item.entity}
                      breadcrumbs={item.entity.ancestors}
                      entityConstants={inventoryItemsConstants}
                      indexEntityLabelOverride={item.entity.rootCustomType?.inventoryName}
                      indexEntityLinkToOverride={item.indexEntityLinkToOverride}
                      entityLinkToOverride={item.entityLinkToOverride}
                      maxWidth={"100%"}
                      key={index}
                      showLinks={showLinks}
                      ancestorLinkToOverride={item.ancestorLinkToOverride}
                      renderInline
                    />
                  ))
                }
              </InventoryItemsWrapper>
            </div>
          ) : (
            NotSet
          )}
        </RowContent>
      );
    case "ViewableEntity":
      return (
        <RowContent
          rowStyle={{ ...rowStyle, width: "100%" }}
          includeCustomFieldId={includeCustomFieldId}
          title={title}
          customField={customfield}
        >
          {value && Object.hasOwn(value, "id") ? (
            <AttachmentMinimalView attachment={value} showFiles={showLinks} />
          ) : (
            NotSet
          )}
        </RowContent>
      );

    case "Url":
      return (
        <RowContent
          rowStyle={rowStyle}
          includeCustomFieldId={includeCustomFieldId}
          title={title}
          customField={customfield}
        >
          {value ? (
            <div className="ellipsisContainer">
              <span>
                <a href={value} target="_blank" rel="noreferrer">
                  <LucideIcon name="link-2" /> {value}
                </a>
              </span>
            </div>
          ) : (
            NotSet
          )}
        </RowContent>
      );
    case "UrlArray":
      return (
        <RowContentArray
          rowStyle={rowStyle}
          includeCustomFieldId={includeCustomFieldId}
          title={title}
          customField={customfield}
          values={value}
        >
          {(value) => (
            <a href={value} target="_blank" rel="noreferrer">
              <LucideIcon name="link-2" /> {value}
            </a>
          )}
        </RowContentArray>
      );

    default:
      return (
        <RowContent
          rowStyle={rowStyle}
          includeCustomFieldId={includeCustomFieldId}
          title={title}
          customField={customfield}
        >
          <>No renderer defined for dataType: {customfield.dataType}</>
        </RowContent>
      );
  }
};
