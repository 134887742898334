import { useCallback } from "react";
import { useEditMutation } from "../../../../api/BaseEntityApi";
import { ResourceName } from "../../../../main/Routing";
import { GenericEntity, IdTypes } from "../../../../api/GenericTypes";

// Internal hook for Entities in ./Forms
interface UseQuickEditSubmitProps<T extends GenericEntity> {
  resource: ResourceName;
  onEdit: (entity: T) => void;
  onCancel: () => void;
}
export const useQuickEditSubmit = <T extends GenericEntity>({
  resource,
  onEdit,
  onCancel,
}: UseQuickEditSubmitProps<T>) => {
  const { mutate: createMutation } = useEditMutation<T>(resource);

  const onSubmit = useCallback(
    (id: IdTypes, data: T) => {
      createMutation(
        { id, body: data },
        {
          onSuccess: (data) => {
            onEdit(data);
          },
          onError: () => {
            onCancel();
          },
        }
      );
    },
    [createMutation, onCancel, onEdit]
  );
  return { onSubmit };
};
