import { useCallback, useEffect } from "react";

import { selectState, settingType } from "../SpectrumViewer/ViewerNavigation/TrackSettings";
import { useTranslatedFieldUpdate } from "../ViewerLayout/ViewerLayoutHooks";
import {
  Color,
  HierarchyNode,
  settingTrackType,
  Track,
  TrackMatrix,
  trackModes,
  trackSettingsList,
  trackSettingsType,
  trackTypes,
  TrackXY,
  TrackXYComplex,
} from "../ViewerLayout/ViewerLayoutTypes";
import { mapTracksHierarch } from "../ViewerLayout/ViewerLayoutUtils";
import { annotationVisibilityType, trackState } from "../ViewerLayout/ViewerTypes";
import styles from "./DatasetStructureViewer.module.css";
import { TreeViewer } from "./TreeViewer/TreeViewer";
import { selectActionType, trackStateType, TreeNodeProperties } from "./TreeViewer/TreeViewerTypes";

const typeConvert: Record<trackTypes, settingTrackType> = {
  XY_complex: settingTrackType.track1D,
  XY_real: settingTrackType.track1D,
  matrix_real: settingTrackType.track2D,
  // nucleotide_sequence: settingTrackType.trackNucleotide,
  protein_sequence: settingTrackType.trackProtein,
  image: settingTrackType.trackImage,
  pdf: settingTrackType.trackPDF,
  table: settingTrackType.trackTable,
  molecule_compound: settingTrackType.trackMolecule,
};

const getSettingTypeFromTracks = (ids: string[], tracks: Record<string, Track>): settingType => {
  const types = new Set<settingTrackType>();
  const annotation: Record<string, selectState> = {};
  const draw: Record<string, selectState> = {};
  const hideAxis: Record<string, selectState> = {};
  const normalize: Record<string, selectState> = {};
  ids.forEach((id) => {
    // console.log("  ", viewerTracks[id].label, "=>", viewerTracks[id].parameter.annotation);
    types.add(typeConvert[tracks[id].type]);
    // console.log("  ", viewerTracks[id].label, "=>", viewerTracks[id].parameter.annotation);
    Object.entries(tracks[id].settings.annotation as Record<string, boolean>).forEach(([k, v]) => {
      if (annotation[k] === undefined) annotation[k] = v;
      else annotation[k] = annotation[k] === v ? v : "uncertain";
    });
    if (tracks[id].type === "XY_complex" || tracks[id].type === "XY_real" || tracks[id].type === "matrix_real")
      Object.entries(
        (tracks[id] as TrackXY | TrackXYComplex | TrackMatrix).settings.draw as Record<string, boolean>
      ).forEach(([k, v]) => {
        if (draw[k] === undefined) draw[k] = v;
        else draw[k] = draw[k] === v ? v : "uncertain";
      });
    Object.entries(tracks[id].settings.hideAxis as Record<string, boolean>).forEach(([k, v]) => {
      if (hideAxis[k] === undefined) hideAxis[k] = v;
      else hideAxis[k] = hideAxis[k] === v ? v : "uncertain";
    });
    if (tracks[id].type === "XY_complex" || tracks[id].type === "XY_real")
      Object.entries((tracks[id] as TrackXY | TrackXYComplex).settings.normalize as Record<string, boolean>).forEach(
        ([k, v]) => {
          if (normalize[k] === undefined) normalize[k] = v;
          else normalize[k] = normalize[k] === v ? v : "uncertain";
        }
      );
  });

  return {
    type: Array.from(types),
    draw: draw,
    annotation: annotation,
    hideAxis: hideAxis,
    normalize: normalize,
  };
};

const TrackDiv = (props: any) => {
  const {
    width,
    height,
    children,
  }: {
    width: number;
    height: number;
    children: any;
  } = props;

  return (
    <div className={styles.resize} style={{ width: width, height: height }}>
      {children}
    </div>
  );
};

const nodePropertiesFromTracks = (
  tracksHierarchy: HierarchyNode,
  tracks: Record<string, Track>
): Record<string, TreeNodeProperties> => {
  const properties: Record<string, TreeNodeProperties> = {};
  mapTracksHierarch<{ visible: boolean; state: trackState; selected: boolean; active: boolean }>(
    tracksHierarchy,
    (node, childrenProperties) => {
      let property: TreeNodeProperties;
      if (node.type === "leaf") {
        if (node?.tracks?.[0]) {
          const track = tracks[node.tracks[0]];
          if (track) {
            const visible = track.settings.active && (track.settings.visible ?? true);
            property = new TreeNodeProperties({
              visible: visible,
              state: visible ? track.settings.state : trackState.ready,
              selected: track.settings.selected ?? false,
              color: track.settings.color,
              simpleColor: track.type === "XY_real" || track.type === "XY_complex",
              active: track.settings.active,
              annotation: track.settings.annotation as Record<annotationVisibilityType, boolean>,
              settings: getSettingTypeFromTracks(node.tracks, tracks),
            });
          } else property = new TreeNodeProperties();
        } else {
          property = new TreeNodeProperties();
        }
      } else if (node.type === "node") {
        let state = trackState.ready;
        if (childrenProperties.some((e) => e.state === trackState.loading && e.active)) state = trackState.loading;
        if (childrenProperties.some((e) => e.state === trackState.failed && e.active)) state = trackState.failed;
        const nodeTracks = node.tracks.filter((id) => tracks?.[id] && tracks[id].settings.active);

        property = new TreeNodeProperties({
          visible: childrenProperties.some((p) => p.visible && p.active),
          state: state,
          active: childrenProperties.some((p: any) => p.active),
          selected: childrenProperties.every((p) => p.selected && p.active),
          settings: getSettingTypeFromTracks(nodeTracks, tracks),
        });
      } else property = new TreeNodeProperties();

      properties[node.id] = property;
      return {
        visible: property.visible,
        state: property.state,
        selected: property.selected,
        active: property.active ?? true,
      };
    }
  );
  return properties;
};

// export const DatasetStructureViewer = function (props: any) {
export const DatasetStructureViewer = ({
  tracks,
  tracksHierarchy,
  zoomViewerTracks,
  changeTrackSettings,
  setSelectedTracks: setTrackList,
  trackMode,
  width,
  height,
}: {
  tracks: Record<string, Track>;
  tracksHierarchy: HierarchyNode;
  changeTrackSettings: (list: Partial<trackSettingsList>) => void;
  zoomViewerTracks: any;
  setSelectedTracks: (trackList: string[]) => void;
  trackMode: trackModes;
  width?: number;
  height?: number;
}) => {
  const trackState = useTranslatedFieldUpdate(
    trackMode,
    (trackMode: trackModes) => {
      const state: trackStateType = {
        multiTrack: true,
        clickMode: "visibility",
        multiSelect: true,
        iconMode: "eye",
      };

      switch (trackMode) {
        case trackModes.singleVisible:
          state.iconMode = "noEye-noCog";
          state.clickMode = "visibility";
          state.multiTrack = false;
          state.multiSelect = false;
          break;
        case trackModes.multiVisible:
          state.iconMode = "eye";
          state.clickMode = "visibility";
          state.multiTrack = true;
          state.multiSelect = true;
          break;
        case trackModes.multiSelect:
          state.iconMode = "noEye";
          state.clickMode = "selection";
          state.multiTrack = true;
          state.multiSelect = true;
          break;
        case trackModes.singleSelect:
          state.iconMode = "eye";
          state.clickMode = "selection";
          state.multiTrack = true;
          state.multiSelect = false;
          break;
      }
      return state;
    },
    {
      multiTrack: true,
      clickMode: "visibility",
      multiSelect: true,
      iconMode: "eye",
    }
  );

  const nodeProperties = useTranslatedFieldUpdate<Record<string, Track>, Record<string, TreeNodeProperties>>(
    tracks,
    (tracks: Record<string, Track>) => nodePropertiesFromTracks(tracksHierarchy, tracks),
    {}
  );

  useEffect(() => {
    if (!trackState.multiTrack) {
      const trackIDs = Object.keys(tracks);
      if (Object.keys(tracks).length > 0) {
        const parameters: trackSettingsList = [];
        const visible = trackIDs.filter((id) => tracks[id].settings.active && tracks[id].settings.visible);

        // const first = visible.length > 1 ? visible[0] : trackIDs[0];
        const first = getFirstVisibleTrack(trackIDs);
        if (visible.length > 1) {
          Object.values(tracks)
            .filter((track) => track.settings.active)
            .forEach((track) =>
              parameters.push({ id: track.id, settings: { visible: track.id === first } as trackSettingsType })
            );
        }
        changeTrackSettings(parameters);
      }
    }
  }, [trackState.multiSelect]);

  const onTrackSettingsChange = useCallback(
    (trackIDs: string[], type: string, settings: any) => {
      const param: trackSettingsList = trackIDs
        .filter((id) => tracks?.[id].settings.active)
        .map((id) => {
          const p: any = {};
          p[type] = settings;
          return { id: id, settings: p };
        });
      changeTrackSettings(param);
    },
    [changeTrackSettings, tracks]
  );

  const onTracksZoom = useCallback(
    (trackIDs: string[], visible: boolean) => {
      zoomViewerTracks(trackIDs);
    },
    [zoomViewerTracks]
  );

  const visibilityChange = useCallback(
    (trackIDs: string[], visible: boolean) => {
      const param: trackSettingsList = trackIDs.map((id) => {
        return { id: id, settings: { visible: visible } };
      }) as trackSettingsList;
      changeTrackSettings(param);
    },
    [changeTrackSettings]
  );

  const selectionChange = useCallback(
    (trackIDs: string[], selection: boolean, mode: selectActionType) => {
      // console.log(
      //   "change selection of",
      //   trackIDs,
      //   // trackIDs.map((id) => viewerTracks[id].label),
      //   "to",
      //   mode,
      //   "->",
      //   trackState.clickMode,
      //   trackState.multiTrack
      // );
      trackIDs = trackIDs.filter((id) => tracks[id].settings.active);
      if (trackIDs.length < 1) return;
      if (trackState.clickMode === "selection") {
        const parameters: trackSettingsList = [];
        if (trackState.multiSelect) {
          trackIDs.forEach((id) => {
            parameters.push({ id: id, settings: { selected: selection } as trackSettingsType });
          });
        } else {
          Object.keys(tracks).forEach((id) => {
            parameters.push({ id: id, settings: { selected: false } as trackSettingsType });
          });
          trackIDs = trackIDs.slice(0, 1);
          trackIDs.forEach((id) => {
            parameters.push({ id: id, settings: { selected: true } as trackSettingsType });
          });
        }
        changeTrackSettings(parameters);
      } else if (trackState.clickMode === "visibility") {
        const parameters: trackSettingsList = [];
        if (mode === "add") {
          trackIDs.forEach((id) => parameters.push({ id: id, settings: { selected: selection } as trackSettingsType }));
        } else {
          if (Object.values(tracks).some((track) => track.settings.selected)) {
            Object.keys(tracks).forEach((id) =>
              parameters.push({ id: id, settings: { selected: false } as trackSettingsType })
            );
          }
          if (trackState.multiTrack) {
            const visible = !trackIDs.some((id) => tracks[id].settings.visible);
            trackIDs.forEach((id) => parameters.push({ id: id, settings: { visible: visible } as trackSettingsType }));
          } else {
            const first = getFirstVisibleTrack(trackIDs);
            if (!tracks[first].settings.visible) {
              Object.values(tracks)
                .filter((track) => track.settings.active)
                .forEach((track) =>
                  parameters.push({ id: track.id, settings: { visible: track.id === first } as trackSettingsType })
                );
            }
          }
        }
        changeTrackSettings(parameters);
      }
    },
    [trackState, trackState.multiTrack, changeTrackSettings, tracks]
  );

  const colorChange = useCallback(
    (trackIDs: string[], color: Color) => {
      // console.log("change color of", trackIDs, "to", color);

      if (color === undefined) {
        console.log("TODO: open contour tool for tracks:", ...trackIDs);
      } else {
        changeTrackSettings(trackIDs.map((id) => ({ id: id, settings: { color: color } })) as trackSettingsList);
      }

      // changeViewerTracks(trackIDs, { colorID: undefined, color: color });
    },
    [changeTrackSettings]
  );

  const getFirstVisibleTrack = useCallback(
    (trackIDs: string[]) => {
      const active = trackIDs.filter((id) => tracks[id].settings.active);
      const visible = active.filter((id) => tracks[id].settings.visible);

      return visible.length > 0 ? visible[0] : active[0];
    },
    [tracks]
  );

  return (
    <div className={styles.main}>
      <TrackDiv width={width} height={height}>
        <TreeViewer
          iconMode={trackState.iconMode}
          nodeProperties={nodeProperties}
          onVisibilityChange={visibilityChange}
          onSelectionChange={selectionChange}
          onColorChange={colorChange}
          onTrackSettingsChange={onTrackSettingsChange}
          onTracksZoom={onTracksZoom}
        >
          {tracksHierarchy}
        </TreeViewer>
      </TrackDiv>
    </div>
  );
};
