import { Icon, useActive, useCommands, useCurrentSelection, useHelpers } from "@remirror/react";
import ReactDOM from "react-dom";
import { Dispatch, KeyboardEvent, RefObject, SetStateAction, useCallback, useEffect, useState } from "react";
import { LucideIcon } from "../../../../../common/icon/LucideIcon";
import { Modal } from "../../../ELNModal/ELNModal";
import { ToolbarDropdownButton } from "../../../ToolbarDropdownButton/ToolbarDropdownButton";
import styles from "./TextEditorToolbar.module.css";
import { TimerModal } from "./TimerModal/TimerModal";
import { CellSelection, isCellSelection } from "@remirror/pm/tables";
import { MoreDropdown } from "../../../../../common/buttons/MoreDropdown/MoreDropdown";
import { CircularProgress } from "../../../../../common/loaders/CircularProgress/CircularProgress";
import Markdown from "markdown-to-jsx";
import { ELNSaveParameters, ELNSaveStatus } from "../../TextEditor";
import { findChildrenByMark, findParentNodeOfType } from "remirror";

const helpString = `
# LOGS ELN Shortcuts

## Inline Shortcuts

### Text options

| Type          | Shortcut | Alternative | Example                                 |
| ------------- | -------- | ----------- | --------------------------------------- |
| Bold          | \\_\\_     | \\*\\*        | e.g.: \\_\\_EXAMPLE\\_\\_ / \\*\\*EXAMPLE\\*\\* |
| Italic        | \\_       | \\*          | e.g.: \\_EXAMPLE\\_ / \\*EXAMPLE\\*         |
| Placeholder   | \\[\\[\\]\\] |             | e.g.: \\[\\[EXAMPLE\\]\\]                   |
| Strikethrough | ~        |             | e.g.: \\~EXAMPLE\\~                       |

### Headers & Breaks

| Type            | Shortcut     | Alternative | Alternative | Info                     |
| --------------- | ------------ | ----------- | ----------- | ------------------------ |
| H1              | \\#           |             |             |                          |
| H2              | \\#\\#         |             |             |                          |
| H3              | \\#\\#\\#       |             |             |                          |
| H4              | \\#\\#\\#\\#     |             |             |                          |
| H5              | \\#\\#\\#\\#\\#   |             |             |                          |
| H6              | \\#\\#\\#\\#\\#\\# |             |             |                          |
| Horizontal rule | \\-\\-\\-       | \\_\\_\\_\\s    | \\*\\*\\*\\s    | where "\\s" -> whitespace |

### List & Tables

| Type           | Shortcut | Info                     |
| -------------- | -------- | ------------------------ |
| Unordered list | \\-\\s     | where "\\s" -> whitespace |
| Ordered list   | 1\\.\\s    | where "\\s" -> whitespace |
| Checkboxes     | \\[\\]\\s   | where "\\s" -> whitespace |

### LOGS Content

| Type        | Shortcut      | Example                           |
| -------     | --------      | --------------------------------- |
| Dataset     | @dataset:     | e.g.: @dataset:DATASET_XYZ        |
| Entry       | @entry:       | e.g.: @entry:ENTRY_XYZ            |
| Experiment  | @experiment:  | e.g.: @experiment:EXPERIMENT_XYZ  |
| Inventory    | @inventory:    | e.g.: @inventory:INVENTORY_XYZ      |
| Notebook    | @notebook:    | e.g.: @notebook:NOTEBOOK_XYZ      |
| Person      | @person:      | e.g.: @person:PERSON_XYZ          |
| Project     | @project:     | e.g.: @project:PROJECT_XYZ        |
| Sample      | @sample:      | e.g.: @sample:SAMPLE_XYZ          |

### Formatted blocks

| Type    | Shortcut     | Info                     |
| ------- | ------------ | ------------------------ |
| Quote   | \\>\\s         | where "\\s" -> whitespace |
| Info    | :::info\\s    | where "\\s" -> whitespace |
| Warning | :::warning\\s | where "\\s" -> whitespace |
| Error   | :::error\\s   | where "\\s" -> whitespace |
| Success | :::success\\s | where "\\s" -> whitespace |

### Links

| Type | Shortcut     |
| ---- | ------------ |
| Link | www\\.\\*\\.any |

## Keyboard Shortcuts

### History

| Type | Shortcut       |
| ---- | -------------- |
| Undo | CTRL + z       |
| Redo | CTRL + ALT + z |

### Text handling

| Type        | Shortcut         |
| ----------- | ---------------- |
| Cut         | CTRL + x         |
| Copy        | CTRL + c         |
| Paste       | CTRL + v         |
| Paste plain | CTRL + SHIFT + v |
| Select all  | CTRL + a         |

### Text options

| Type          | Shortcut    | Alternative      |
| ------------- | ----------- | ---------------- |
| Bold          | CTRL + b    |                  |
| Italic        | CTRL + i    |                  |
| Underline     | CTRL + u    |                  |
| Strikethrough | CTRL + d    |                  |
| Superscript   | CTRL + .    |                  |
| Subscript     | CTRL + ,    |                  |
| Code          | CTRL + ö    | CTRL + SHIFT + \` |
| Indent        | TAB         |                  |
| Dedent        | SHIFT + TAB |                  |

### Headers

| Type | Shortcut         |
| ---- | ---------------- |
| H1   | CTRL + SHIFT + 1 |
| H2   | CTRL + SHIFT + 2 |
| H3   | CTRL + SHIFT + 3 |
| H4   | CTRL + SHIFT + 4 |
| H5   | CTRL + SHIFT + 5 |
| H6   | CTRL + SHIFT + 6 |

### List & Tables

| Type           | Shortcut         |
| -------------- | ---------------- |
| Unordered list | CTRL + SHIFT + 8 |
| Checkboxes     | CTRL + SHIFT + 7 |

### Formatted blocks

| Type  | Shortcut         |
| ----- | ---------------- |
| Quote | CTRL + SHIFT + > |

`;

export const DefaultMarkdownToJsxOptions = {
  overrides: {
    h1: {
      props: {
        className: styles.helpH1,
      },
    },
    h2: {
      props: {
        className: styles.helpH2,
      },
    },
    h3: {
      props: {
        className: styles.helpH3,
      },
    },
    h4: {
      props: {
        className: styles.helpH4,
      },
    },
    h5: {
      props: {
        className: styles.helpH5,
      },
    },
    h6: {
      props: {
        className: styles.helpH6,
      },
    },
    table: {
      props: {
        className: styles.helpTable,
      },
    },
    th: {
      props: {
        className: styles.helpTh,
      },
    },
    td: {
      props: {
        className: styles.helpTd,
      },
    },
  },
};

interface NewTableParams {
  x: number;
  y: number;
}

export const TextEditorToolbar = ({
  save,
  saveStatus,
  setSaveStatus,
  uploadProgress,
  cancelUpload,
  toolbarRef,
  onClose,
}: {
  save: (params: ELNSaveParameters) => void;
  saveStatus: ELNSaveStatus;
  setSaveStatus: Dispatch<SetStateAction<ELNSaveStatus>>;
  uploadProgress?: number;
  cancelUpload?: () => void;
  toolbarRef?: RefObject<HTMLDivElement>;
  onClose?: () => void;
}) => {
  const commands = useCommands();
  const helpers = useHelpers();
  const active = useActive(true);

  const selection = useCurrentSelection();

  const [showLinkModal, setShowLinkModal] = useState<boolean>(false);
  const [href, setHref] = useState<string>("");
  const [showTimerModal, setShowTimerModal] = useState<boolean>(false);
  const [showHelpModal, setShowHelpModal] = useState<boolean>(false);
  const [createTableDropdownHoverIndex, setCreateTableDropdwonHoverIndex] = useState<NewTableParams>({ x: 0, y: 0 });

  useEffect(() => {
    const node = findParentNodeOfType({ selection, types: ["paragraph"] });
    if (node?.node) {
      const links = findChildrenByMark({ type: "link", node: node.node });
      if (links.length > 0 && links[0]?.node?.marks[0]?.attrs?.href) {
        const link = links[0]?.node?.marks[0]?.attrs?.href as string;
        if (link.startsWith("//")) {
          setHref(links[0].node.marks[0].attrs.href.slice(2));
        } else {
          setHref(links[0].node.marks[0].attrs.href);
        }
      }
    }

    return () => setHref("");
  }, [selection]);

  const handleAddLink = useCallback(() => {
    commands.updateLink({ href: href.includes("//") ? href : `//${href}` });
    setShowLinkModal(false);
  }, [commands, href]);

  const handleUpdateLinkOnEnter = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === "Enter") {
        handleAddLink();
      }
    },
    [handleAddLink]
  );

  useEffect(() => {
    if (showLinkModal) {
      document.addEventListener("keydown", handleUpdateLinkOnEnter as any);
      return () => document.removeEventListener("keydown", handleUpdateLinkOnEnter as any);
    }
  }, [handleUpdateLinkOnEnter, showLinkModal]);

  const handleTableCellCommand = useCallback(
    (command: Function) => {
      const selection = helpers.getCommandProp().tr.selection;

      if (isCellSelection(selection)) {
        (selection as CellSelection).ranges.forEach((sel) => {
          commands.selectText({ from: sel.$from.pos, to: sel.$to.pos });
          command();
        });
        commands.selectParentCell();
      } else {
        command();
      }
    },
    [helpers, commands]
  );

  if (!toolbarRef?.current) return null;
  return ReactDOM.createPortal(
    <div className={styles.textEditorToolbarContainer}>
      <div className={styles.textEditorToolbarLeftContainer}>
        {/* HISTORY */}
        <div className={styles.textEditorToolbarGroupContainer}>
          <ToolbarDropdownButton
            toolsOnBar={[
              {
                icon: <LucideIcon name="rotate-ccw" />,
                function: () => commands.undo(),
                active: false,
                tooltip: "Undo (CTRL + Z)",
                disabled: false,
              },
              {
                icon: <LucideIcon name="rotate-cw" />,
                function: () => commands.redo(),
                active: false,
                tooltip: "Redo (CTRL + Y)",
                disabled: false,
              },
            ]}
            toolsOnDropdown={[]}
          />
          <div className={styles.textEditorToolbarDivider} />
        </div>
      </div>

      <div className={styles.textEditorToolbarMiddleContainer}>
        {/* TEXT DECORATIONS */}
        <div className={styles.textEditorToolbarGroupContainer}>
          <ToolbarDropdownButton
            toolsOnBar={[
              {
                icon: Icon({ size: 16, name: "bold" }),
                function: () => commands.toggleBold(),
                active: active.bold(),
                tooltip: "Bold (CTRL + B)",
                disabled: false,
              },
              {
                icon: Icon({ size: 16, name: "italic" }),
                function: () => commands.toggleItalic(),
                active: active.italic(),
                tooltip: "Italic (CTRL + I)",
                disabled: false,
              },
              {
                icon: Icon({ size: 16, name: "underline" }),
                function: () => commands.toggleUnderline(),
                active: active.underline(),
                tooltip: "Underline (CTRL + U)",
                disabled: false,
              },
            ]}
            toolsOnDropdown={[
              {
                icon: Icon({ size: 16, name: "space" }),
                function: () =>
                  active.contentPlaceholderNode()
                    ? commands.removeContentPlaceholderNode()
                    : commands.toggleContentPlaceholderNode(),
                active: active.contentPlaceholderNode(),
                tooltip: "Placeholder",
                disabled: false,
              },
              {
                icon: Icon({ size: 16, name: "superscript2" }),
                function: () => commands.toggleSuperscript(),
                active: active.sup(),
                tooltip: "Superscript (CTRL + .)",
                disabled: false,
              },
              {
                icon: Icon({ size: 16, name: "subscript2" }),
                function: () => commands.toggleSubscript(),
                active: active.sub(),
                tooltip: "Subscript (CTRL + ,)",
                disabled: false,
              },
              {
                icon: Icon({ size: 16, name: "strikethrough" }),
                function: () => commands.toggleStrike(),
                active: active.strike(),
                tooltip: "Strikethrough (CTRL + D)",
                disabled: false,
              },
            ]}
          />
          <div className={styles.textEditorToolbarDivider} />
        </div>

        {/* TEXT COLOR */}
        <div className={styles.textEditorToolbarGroupContainer}>
          <ToolbarDropdownButton
            defaultIndex={1}
            buttonsPerRow={4}
            toolsOnBar={[
              {
                icon: <LucideIcon name="type" color="var(--black)" />,
                function: () => handleTableCellCommand(commands.removeTextColor),
                active: false,
                tooltip: "Reset text color",
                disabled: false,
              },
            ]}
            toolsOnDropdown={[
              // PALETTE 1
              {
                icon: <LucideIcon name="type" color="#151965" />,
                function: () => handleTableCellCommand(() => commands.setTextColor("#151965")),
                active: active.textColor(),
                tooltip: "Text color",
                disabled: active.textColor(),
              },
              {
                icon: <LucideIcon name="type" color="#0F4C75" />,

                function: () => handleTableCellCommand(() => commands.setTextColor("#0F4C75")),
                active: active.textColor(),
                tooltip: "Text color",
                disabled: active.textColor(),
              },
              {
                icon: <LucideIcon name="type" color="#3282B8" />,
                function: () => handleTableCellCommand(() => commands.setTextColor("#3282B8")),
                active: active.textColor(),
                tooltip: "Text color",
                disabled: active.textColor(),
              },
              {
                icon: <LucideIcon name="type" color="#46B5D1" />,

                function: () => handleTableCellCommand(() => commands.setTextColor("#46B5D1")),
                active: active.textColor(),
                tooltip: "Text color",
                disabled: active.textColor(),
              },
              // PALETTE 2
              {
                icon: <LucideIcon name="type" color="#062925" />,
                function: () => handleTableCellCommand(() => commands.setTextColor("#062925")),
                active: active.textColor(),
                tooltip: "Text color",
                disabled: active.textColor(),
              },
              {
                icon: <LucideIcon name="type" color="#044A42" />,

                function: () => handleTableCellCommand(() => commands.setTextColor("#044A42")),
                active: active.textColor(),
                tooltip: "Text color",
                disabled: active.textColor(),
              },
              {
                icon: <LucideIcon name="type" color="#3A9188" />,
                function: () => handleTableCellCommand(() => commands.setTextColor("#3A9188")),
                active: active.textColor(),
                tooltip: "Text color",
                disabled: active.textColor(),
              },
              {
                icon: <LucideIcon name="type" color="#84C69B" />,

                function: () => handleTableCellCommand(() => commands.setTextColor("#84C69B")),
                active: active.textColor(),
                tooltip: "Text color",
                disabled: active.textColor(),
              },
              // PALETTE 3
              {
                icon: <LucideIcon name="type" color="#290001" />,
                function: () => handleTableCellCommand(() => commands.setTextColor("#290001")),
                active: active.textColor(),
                tooltip: "Text color",
                disabled: active.textColor(),
              },
              {
                icon: <LucideIcon name="type" color="#87431D" />,

                function: () => handleTableCellCommand(() => commands.setTextColor("#87431D")),
                active: active.textColor(),
                tooltip: "Text color",
                disabled: active.textColor(),
              },
              {
                icon: <LucideIcon name="type" color="#C87941" />,
                function: () => handleTableCellCommand(() => commands.setTextColor("#C87941")),
                active: active.textColor(),
                tooltip: "Text color",
                disabled: active.textColor(),
              },
              {
                icon: <LucideIcon name="type" color="#DECE9C" />,

                function: () => handleTableCellCommand(() => commands.setTextColor("#DECE9C")),
                active: active.textColor(),
                tooltip: "Text color",
                disabled: active.textColor(),
              },
              // PALETTE 4
              {
                icon: <LucideIcon name="type" color="#1B0044" />,
                function: () => handleTableCellCommand(() => commands.setTextColor("#1B0044")),
                active: active.textColor(),
                tooltip: "Text color",
                disabled: active.textColor(),
              },
              {
                icon: <LucideIcon name="type" color="#5727A3" />,

                function: () => handleTableCellCommand(() => commands.setTextColor("#5727A3")),
                active: active.textColor(),
                tooltip: "Text color",
                disabled: active.textColor(),
              },
              {
                icon: <LucideIcon name="type" color="#9153F4" />,
                function: () => handleTableCellCommand(() => commands.setTextColor("#9153F4")),
                active: active.textColor(),
                tooltip: "Text color",
                disabled: active.textColor(),
              },
              {
                icon: <LucideIcon name="type" color="#927FBF" />,

                function: () => handleTableCellCommand(() => commands.setTextColor("#927FBF")),
                active: active.textColor(),
                tooltip: "Text color",
                disabled: active.textColor(),
              },
              // PALETTE 5
              {
                icon: <LucideIcon name="type" color="var(--primary)" />,
                function: () => handleTableCellCommand(() => commands.setTextColor("var(--primary)")),
                active: active.textColor(),
                tooltip: "Text color",
                disabled: active.textColor(),
              },
              {
                icon: <LucideIcon name="type" color="var(--warning)" />,
                function: () => handleTableCellCommand(() => commands.setTextColor("var(--warning)")),
                active: active.textColor(),
                tooltip: "Text color",
                disabled: active.textColor(),
              },
              {
                icon: <LucideIcon name="type" color="var(--success)" />,
                function: () => handleTableCellCommand(() => commands.setTextColor("var(--success)")),
                active: active.textColor(),
                tooltip: "Text color",
                disabled: active.textColor(),
              },
              {
                icon: <LucideIcon name="type" color="var(--danger)" />,
                function: () => handleTableCellCommand(() => commands.setTextColor("var(--danger)")),
                active: active.textColor(),
                tooltip: "Text color",
                disabled: active.textColor(),
              },
            ]}
          />
        </div>

        {/* TEXT HIGHLIGHTING */}
        <div className={styles.textEditorToolbarGroupContainer}>
          <ToolbarDropdownButton
            defaultIndex={2}
            buttonsPerRow={4}
            toolsOnBar={[
              {
                icon: (
                  <div
                    className={styles.textEditorToolbarBackgroundColorIcon}
                    style={{ backgroundColor: "var(--white)" }}
                  >
                    <LucideIcon name="type" />
                  </div>
                ),
                function: () =>
                  handleTableCellCommand(() => {
                    commands.removeTextHighlight();
                    commands.setTableCellBackground(null);
                  }),
                active: false,
                tooltip: "Reset text background",
                disabled: false,
              },
            ]}
            toolsOnDropdown={[
              // PALETTE 1
              {
                icon: (
                  <div className={styles.textEditorToolbarBackgroundColorIcon} style={{ backgroundColor: "#B1B2FF" }}>
                    <LucideIcon name="type" />
                  </div>
                ),
                function: () =>
                  handleTableCellCommand(() => {
                    active.table() ? commands.setTableCellBackground("#B1B2FF") : commands.setTextHighlight("#B1B2FF");
                  }),
                active: active.textHighlight(),
                tooltip: "Text background",
                disabled: active.textHighlight(),
              },
              {
                icon: (
                  <div className={styles.textEditorToolbarBackgroundColorIcon} style={{ backgroundColor: "#AAC4FF" }}>
                    <LucideIcon name="type" />
                  </div>
                ),
                function: () =>
                  handleTableCellCommand(() => {
                    active.table() ? commands.setTableCellBackground("#AAC4FF") : commands.setTextHighlight("#AAC4FF");
                  }),
                active: active.textHighlight(),
                tooltip: "Text background",
                disabled: active.textHighlight(),
              },
              {
                icon: (
                  <div className={styles.textEditorToolbarBackgroundColorIcon} style={{ backgroundColor: "#D2DAFF" }}>
                    <LucideIcon name="type" />
                  </div>
                ),
                function: () =>
                  handleTableCellCommand(() => {
                    active.table() ? commands.setTableCellBackground("#D2DAFF") : commands.setTextHighlight("#D2DAFF");
                  }),
                active: active.textHighlight(),
                tooltip: "Text background",
                disabled: active.textHighlight(),
              },
              {
                icon: (
                  <div className={styles.textEditorToolbarBackgroundColorIcon} style={{ backgroundColor: "#EEF1FF" }}>
                    <LucideIcon name="type" />
                  </div>
                ),
                function: () =>
                  handleTableCellCommand(() => {
                    active.table() ? commands.setTableCellBackground("#EEF1FF") : commands.setTextHighlight("#EEF1FF");
                  }),
                active: active.textHighlight(),
                tooltip: "Text background",
                disabled: active.textHighlight(),
              },
              // PALETTE 2
              {
                icon: (
                  <div className={styles.textEditorToolbarBackgroundColorIcon} style={{ backgroundColor: "#8EC3B0" }}>
                    <LucideIcon name="type" />
                  </div>
                ),
                function: () =>
                  handleTableCellCommand(() => {
                    active.table() ? commands.setTableCellBackground("#8EC3B0") : commands.setTextHighlight("#8EC3B0");
                  }),
                active: active.textHighlight(),
                tooltip: "Text background",
                disabled: active.textHighlight(),
              },
              {
                icon: (
                  <div className={styles.textEditorToolbarBackgroundColorIcon} style={{ backgroundColor: "#9ED5C5" }}>
                    <LucideIcon name="type" />
                  </div>
                ),
                function: () =>
                  handleTableCellCommand(() => {
                    active.table() ? commands.setTableCellBackground("#9ED5C5") : commands.setTextHighlight("#9ED5C5");
                  }),
                active: active.textHighlight(),
                tooltip: "Text background",
                disabled: active.textHighlight(),
              },
              {
                icon: (
                  <div className={styles.textEditorToolbarBackgroundColorIcon} style={{ backgroundColor: "#BCEAD5" }}>
                    <LucideIcon name="type" />
                  </div>
                ),
                function: () =>
                  handleTableCellCommand(() => {
                    active.table() ? commands.setTableCellBackground("#BCEAD5") : commands.setTextHighlight("#BCEAD5");
                  }),
                active: active.textHighlight(),
                tooltip: "Text background",
                disabled: active.textHighlight(),
              },
              {
                icon: (
                  <div className={styles.textEditorToolbarBackgroundColorIcon} style={{ backgroundColor: "#DEF5E5" }}>
                    <LucideIcon name="type" />
                  </div>
                ),
                function: () =>
                  handleTableCellCommand(() => {
                    active.table() ? commands.setTableCellBackground("#DEF5E5") : commands.setTextHighlight("#DEF5E5");
                  }),
                active: active.textHighlight(),
                tooltip: "Text background",
                disabled: active.textHighlight(),
              },
              // PALETTE 3
              {
                icon: (
                  <div className={styles.textEditorToolbarBackgroundColorIcon} style={{ backgroundColor: "#A0937D" }}>
                    <LucideIcon name="type" />
                  </div>
                ),
                function: () =>
                  handleTableCellCommand(() => {
                    active.table() ? commands.setTableCellBackground("#A0937D") : commands.setTextHighlight("#A0937D");
                  }),
                active: active.textHighlight(),
                tooltip: "Text background",
                disabled: active.textHighlight(),
              },
              {
                icon: (
                  <div className={styles.textEditorToolbarBackgroundColorIcon} style={{ backgroundColor: "#E3CDC1" }}>
                    <LucideIcon name="type" />
                  </div>
                ),
                function: () =>
                  handleTableCellCommand(() => {
                    active.table() ? commands.setTableCellBackground("#E3CDC1") : commands.setTextHighlight("#E3CDC1");
                  }),
                active: active.textHighlight(),
                tooltip: "Text background",
                disabled: active.textHighlight(),
              },
              {
                icon: (
                  <div className={styles.textEditorToolbarBackgroundColorIcon} style={{ backgroundColor: "#E7D4B5" }}>
                    <LucideIcon name="type" />
                  </div>
                ),
                function: () =>
                  handleTableCellCommand(() => {
                    active.table() ? commands.setTableCellBackground("#E7D4B5") : commands.setTextHighlight("#E7D4B5");
                  }),
                active: active.textHighlight(),
                tooltip: "Text background",
                disabled: active.textHighlight(),
              },
              {
                icon: (
                  <div className={styles.textEditorToolbarBackgroundColorIcon} style={{ backgroundColor: "#F6E6CB" }}>
                    <LucideIcon name="type" />
                  </div>
                ),
                function: () =>
                  handleTableCellCommand(() => {
                    active.table() ? commands.setTableCellBackground("#F6E6CB") : commands.setTextHighlight("#F6E6CB");
                  }),
                active: active.textHighlight(),
                tooltip: "Text background",
                disabled: active.textHighlight(),
              },
              // PALETTE 4
              {
                icon: (
                  <div className={styles.textEditorToolbarBackgroundColorIcon} style={{ backgroundColor: "#F38181" }}>
                    <LucideIcon name="type" />
                  </div>
                ),
                function: () =>
                  handleTableCellCommand(() => {
                    active.table() ? commands.setTableCellBackground("#F38181") : commands.setTextHighlight("#F38181");
                  }),
                active: active.textHighlight(),
                tooltip: "Text background",
                disabled: active.textHighlight(),
              },
              {
                icon: (
                  <div className={styles.textEditorToolbarBackgroundColorIcon} style={{ backgroundColor: "#FFD495" }}>
                    <LucideIcon name="type" />
                  </div>
                ),
                function: () =>
                  handleTableCellCommand(() => {
                    active.table() ? commands.setTableCellBackground("#FFD495") : commands.setTextHighlight("#FFD495");
                  }),
                active: active.textHighlight(),
                tooltip: "Text background",
                disabled: active.textHighlight(),
              },
              {
                icon: (
                  <div className={styles.textEditorToolbarBackgroundColorIcon} style={{ backgroundColor: "#FFFBAC" }}>
                    <LucideIcon name="type" />
                  </div>
                ),
                function: () =>
                  handleTableCellCommand(() => {
                    active.table() ? commands.setTableCellBackground("#FFFBAC") : commands.setTextHighlight("#FFFBAC");
                  }),
                active: active.textHighlight(),
                tooltip: "Text background",
                disabled: active.textHighlight(),
              },
              {
                icon: (
                  <div className={styles.textEditorToolbarBackgroundColorIcon} style={{ backgroundColor: "#D7E9B9" }}>
                    <LucideIcon name="type" />
                  </div>
                ),
                function: () =>
                  handleTableCellCommand(() => {
                    active.table() ? commands.setTableCellBackground("#D7E9B9") : commands.setTextHighlight("#D7E9B9");
                  }),
                active: active.textHighlight(),
                tooltip: "Text background",
                disabled: active.textHighlight(),
              },
              // PALETTE 5
              {
                icon: (
                  <div className={styles.textEditorToolbarBackgroundColorIcon} style={{ backgroundColor: "#FF7B54" }}>
                    <LucideIcon name="type" />
                  </div>
                ),
                function: () =>
                  handleTableCellCommand(() => {
                    active.table() ? commands.setTableCellBackground("#FF7B54") : commands.setTextHighlight("#FF7B54");
                  }),
                active: active.textHighlight(),
                tooltip: "Text background",
                disabled: active.textHighlight(),
              },
              {
                icon: (
                  <div className={styles.textEditorToolbarBackgroundColorIcon} style={{ backgroundColor: "#FFB26B" }}>
                    <LucideIcon name="type" />
                  </div>
                ),
                function: () =>
                  handleTableCellCommand(() => {
                    active.table() ? commands.setTableCellBackground("#FFB26B") : commands.setTextHighlight("#FFB26B");
                  }),
                active: active.textHighlight(),
                tooltip: "Text background",
                disabled: active.textHighlight(),
              },
              {
                icon: (
                  <div className={styles.textEditorToolbarBackgroundColorIcon} style={{ backgroundColor: "#FFD56F" }}>
                    <LucideIcon name="type" />
                  </div>
                ),
                function: () =>
                  handleTableCellCommand(() => {
                    active.table() ? commands.setTableCellBackground("#FFD56F") : commands.setTextHighlight("#FFD56F");
                  }),
                active: active.textHighlight(),
                tooltip: "Text background",
                disabled: active.textHighlight(),
              },
              {
                icon: (
                  <div className={styles.textEditorToolbarBackgroundColorIcon} style={{ backgroundColor: "#939B62" }}>
                    <LucideIcon name="type" />
                  </div>
                ),
                function: () =>
                  handleTableCellCommand(() => {
                    active.table() ? commands.setTableCellBackground("#939B62") : commands.setTextHighlight("#939B62");
                  }),
                active: active.textHighlight(),
                tooltip: "Text background",
                disabled: active.textHighlight(),
              },
            ]}
          />
          <div className={styles.textEditorToolbarDivider} />
        </div>

        {/* HEADINGS & BREAKS */}
        <div className={styles.textEditorToolbarGroupContainer}>
          <ToolbarDropdownButton
            toolsOnBar={[1, 2].map((level) => {
              return {
                icon: Icon({ size: 16, name: `h${level}` as any }),
                function: () => commands.toggleHeading({ level }),
                active: active.heading({ level }),
                tooltip: `Heading ${level} (CTRL + SHIFT + ${level})`,
                disabled: false,
              };
            })}
            toolsOnDropdown={[3, 4, 5, 6].map((level) => {
              return {
                icon: Icon({ size: 16, name: `h${level}` as any }),
                function: () => commands.toggleHeading({ level }),
                active: active.heading({ level }),
                tooltip: `Heading ${level} (CTRL + SHIFT + ${level})`,
                disabled: false,
              };
            })}
          />
          <div className={styles.textEditorToolbarDivider} />
        </div>

        {/* DIVIDER */}
        <div className={styles.textEditorToolbarGroupContainer}>
          <ToolbarDropdownButton
            toolsOnBar={[
              {
                icon: Icon({ size: 16, name: "pageSeparator" }),
                function: () => commands.insertHorizontalRule(),
                active: false,
                tooltip: "Horizontal rule",
                disabled: false,
              },
            ]}
            toolsOnDropdown={[]}
          />
          <div className={styles.textEditorToolbarDivider} />
        </div>

        {/* PARAGRAPHS */}
        <div className={styles.textEditorToolbarGroupContainer}>
          <ToolbarDropdownButton
            toolsOnDropdown={[
              {
                icon: Icon({ size: 16, name: "alignLeft" }),
                function: () => handleTableCellCommand(commands.leftAlign),
                active: false,
                tooltip: "Text align left (CTRL + SHIFT + L)",
                disabled: false,
              },
              {
                icon: Icon({ size: 16, name: "alignCenter" }),
                function: () => handleTableCellCommand(commands.centerAlign),
                active: false,
                tooltip: "Text align center (CTRL + SHIFT + E)",
                disabled: false,
              },
              {
                icon: Icon({ size: 16, name: "alignRight" }),
                function: () => handleTableCellCommand(commands.rightAlign),
                active: false,
                tooltip: "Text align right (CTRL + SHIFT + R)",
                disabled: false,
              },
              {
                icon: Icon({ size: 16, name: "alignJustify" }),
                function: () => handleTableCellCommand(commands.justifyAlign),
                active: false,
                tooltip: "Text align justify (CTRL + SHIFT + J)",
                disabled: false,
              },
            ]}
          />
          <div className={styles.textEditorToolbarDivider} />
        </div>

        {/* LISTS */}
        <div className={styles.textEditorToolbarGroupContainer}>
          <ToolbarDropdownButton
            toolsOnDropdown={[
              {
                icon: Icon({ size: 16, name: "listUnordered" }),
                function: () => commands.toggleBulletList(),
                active: active.bulletList(),
                tooltip: "Unordered list (CTRL + SHIFT + 8)",
                disabled: false,
              },
              {
                icon: Icon({ size: 16, name: "listOrdered" }),
                function: () => commands.toggleOrderedList(),
                active: active.orderedList(),
                tooltip: "Ordered list",
                disabled: false,
              },
              {
                icon: <LucideIcon name="square-check-big" />,
                function: () => commands.toggleTaskList(),
                active: active.taskList(),
                tooltip: "Checklist (CTRL + SHIFT + 7)",
                disabled: false,
              },
            ]}
          />
          <div className={styles.textEditorToolbarDivider} />
        </div>

        {/* TABLES */}
        {!active.table() ? (
          <div className={styles.textEditorToolbarGroupContainer}>
            <div className={styles.textEditorToolbarNewTableDropdownButtonContainer}>
              <MoreDropdown
                data-toggle="tooltip"
                title="Create table"
                icon={<div className="flex align-center">{Icon({ size: 16, name: "table2" })}</div>}
                closeOnClickInside={false}
              >
                <div
                  className={styles.textEditorToolbarNewTableDropdownContainer}
                  onMouseLeave={() => setCreateTableDropdwonHoverIndex({ x: 0, y: 0 })}
                >
                  {[...Array(49)].map((e, index) => {
                    const x = Math.floor(index / 7) + 1;
                    const y = (index % 7) + 1;
                    return (
                      <div
                        key={index}
                        style={
                          createTableDropdownHoverIndex.x >= x && createTableDropdownHoverIndex.y >= y
                            ? { backgroundColor: "var(--secondary-light)", border: "1px solid var(--gray-300)" }
                            : {}
                        }
                        className={styles.textEditorToolbarNewTableDropdownItem}
                        onMouseEnter={() => setCreateTableDropdwonHoverIndex({ x, y })}
                        onClick={() => {
                          commands.createTable({ rowsCount: x, columnsCount: y, withHeaderRow: false });
                          setCreateTableDropdwonHoverIndex({ x: 0, y: 0 });
                          commands.focus();
                        }}
                      >
                        {x + "x" + y}
                      </div>
                    );
                  })}
                </div>
              </MoreDropdown>
            </div>
            <div className={styles.textEditorToolbarDivider} />
          </div>
        ) : (
          <div className={styles.textEditorToolbarGroupContainer}>
            <ToolbarDropdownButton
              toolsOnBar={[
                {
                  icon: Icon({ size: 16, name: "table2" }),
                  function: () => commands.createTable({ rowsCount: 1, columnsCount: 2, withHeaderRow: false }),
                  active: active.table(),
                  tooltip: "Toggle table",
                  disabled: false,
                },
              ]}
              toolsOnDropdown={
                active.table()
                  ? [
                      {
                        icon: Icon({ size: 16, name: "insertRowBottom" }),
                        function: () => commands.addTableRowAfter(),
                        active: false,
                        tooltip: "Add row after",
                        disabled: false,
                      },
                      {
                        icon: Icon({ size: 16, name: "insertRowTop" }),
                        function: () => commands.addTableRowBefore(),
                        active: false,
                        tooltip: "Add row before",
                        disabled: false,
                      },
                      {
                        icon: Icon({ size: 16, name: "insertColumnRight" }),
                        function: () => commands.addTableColumnAfter(),
                        active: false,
                        tooltip: "Add column after",
                        disabled: false,
                      },
                      {
                        icon: Icon({ size: 16, name: "insertColumnLeft" }),
                        function: () => commands.addTableColumnBefore(),
                        active: false,
                        tooltip: "Add column before",
                        disabled: false,
                      },
                      {
                        icon: Icon({ size: 16, name: "deleteRow" }),
                        function: () => commands.deleteTableRow(),
                        active: false,
                        tooltip: "Remove row",
                        disabled: false,
                      },
                      {
                        icon: Icon({ size: 16, name: "deleteColumn" }),
                        function: () => commands.deleteTableColumn(),
                        active: false,
                        tooltip: "Remove column",
                        disabled: false,
                      },
                      {
                        icon: Icon({ size: 16, name: "mergeCellsHorizontal" }),
                        function: () => commands.mergeTableCells(),
                        active: false,
                        tooltip: "Merge table cells",
                        disabled: false,
                      },
                      {
                        icon: Icon({ size: 16, name: "splitCellsHorizontal" }),
                        function: () => commands.splitTableCell(),
                        active: false,
                        tooltip: "Split table cells",
                        disabled: false,
                      },
                      {
                        icon: Icon({ size: 16, name: "deleteBinLine" }),
                        function: () => commands.deleteTable(),
                        active: false,
                        tooltip: "Remove table",
                        disabled: false,
                      },
                    ]
                  : []
              }
            />
            <div className={styles.textEditorToolbarDivider} />
          </div>
        )}

        {/* Columns */}
        {/* <div className={styles.textEditorToolbarGroupContainer}>
        <ToolbarDropdownButton
          toolsOnBar={[
            {
              icon: <FeatherIcon name="layout" />,
              function: () => commands.toggleColumns({ count: 2 }),
              active: active.columns(),
              tooltip: "Add column",
            },
          ]}
          toolsOnDropdown={[]}
        />
        <div className={styles.textEditorToolbarDivider} />
      </div> */}

        {/* LINKS */}
        <div className={styles.textEditorToolbarGroupContainer}>
          <ToolbarDropdownButton
            toolsOnDropdown={[]}
            toolsOnBar={
              active.link()
                ? [
                    {
                      icon: Icon({ size: 16, name: "link" }),
                      function: () => setShowLinkModal(true),
                      active: false,
                      tooltip: "Update link",
                      disabled: false,
                    },
                    {
                      icon: Icon({ size: 16, name: "linkUnlink" }),
                      function: () => commands.removeLink(),
                      active: true,
                      tooltip: "Remove link",
                      disabled: false,
                    },
                  ]
                : [
                    {
                      icon: Icon({ size: 16, name: "link" }),
                      function: () => setShowLinkModal(true),
                      active: false,
                      tooltip: "Add link",
                      disabled: false,
                    },
                  ]
            }
          />
          <div className={styles.textEditorToolbarDivider} />
        </div>

        {/* BLOCKQUOTES & CODE */}
        <div className={styles.textEditorToolbarGroupContainer}>
          <ToolbarDropdownButton
            toolsOnDropdown={[
              {
                icon: Icon({ size: 16, name: "doubleQuotesR" }),
                function: () => commands.toggleBlockquote(),
                active: active.blockquote(),
                tooltip: "Blockquote (CTRL + SHIFT + >)",
                disabled: false,
              },
              {
                icon: Icon({ size: 16, name: "codeLine" }),
                function: () => commands.toggleCode(),
                active: active.code(),
                tooltip: "Code (CTRL + SHIFT + `)",
                disabled: false,
              },
            ]}
          />
          <div className={styles.textEditorToolbarDivider} />
        </div>

        {/* SPECIAL CHARACTERS */}
        <div className={styles.textEditorToolbarGroupContainer}>
          <ToolbarDropdownButton
            buttonsPerRow={6}
            toolsOnDropdown={[
              "α",
              "Α",
              "β",
              "Β",
              "γ",
              "Γ",
              "δ",
              "Δ",
              "ε",
              "Ε",
              "Ζ",
              "ζ",
              "η",
              "Η",
              "θ",
              "Θ",
              "ι",
              "Ι",
              "κ",
              "Κ",
              "λ",
              "Λ",
              "μ",
              "Μ",
              "ν",
              "Ν",
              "ξ",
              "Ξ",
              "ο",
              "Ο",
              "π",
              "Π",
              "ρ",
              "Ρ",
              "σ",
              "Σ",
              "τ",
              "Τ",
              "υ",
              "Υ",
              "φ",
              "Φ",
              "χ",
              "Χ",
              "ψ",
              "Ψ",
              "ω",
              "Ω",
            ].map((c) => {
              return {
                label: c,
                function: () => commands.insertText(c),
                active: false,
                tooltip: c,
                disabled: false,
              };
            })}
          />

          <ToolbarDropdownButton
            buttonsPerRow={5}
            toolsOnDropdown={[
              "Å",
              "℃",
              "℉",
              "‰",
              "‱",
              "∞",
              "∅",
              "⊂",
              "⊃",
              "⊄",
              "⊅",
              "⊆",
              "⊇",
              "⊈",
              "⊉",
              "⊊",
              "⊋",
              "≈",
              "≠",
              "≙",
              "≤",
              "≥",
              "∫",
              "←",
              "↑",
              "→",
              "↓",
              "↔",
              "↕",
            ].map((c) => {
              return {
                label: c,
                function: () => commands.insertText(c),
                active: false,
                tooltip: c,
                disabled: false,
              };
            })}
          />
          <div className={styles.textEditorToolbarDivider} />
        </div>
      </div>

      <div className={styles.textEditorToolbarLeftContainer}>
        <div className={styles.textEditorToolbarGroupContainer}>
          <MoreDropdown
            drop="right"
            icon={
              <div>
                <LucideIcon name="menu" />
              </div>
            }
          >
            <div className={styles.textEditorToolbarWidgetsGrid}>
              <div data-toggle="tooltip" title="Insert info box">
                <button
                  className="btn btn-sm btn-default btn-block"
                  onClick={() => commands.toggleCallout({ type: "info" })}
                  disabled={false}
                >
                  <LucideIcon name="info" color="var(--info)" />
                  <div>Info box</div>
                </button>
              </div>
              <div data-toggle="tooltip" title="Insert warning box">
                <button
                  className="btn btn-sm btn-default btn-block"
                  onClick={() => commands.toggleCallout({ type: "warning" })}
                  disabled={false}
                >
                  <LucideIcon name="triangle-alert" color="var(--warning)" />
                  <div>Warning box</div>
                </button>
              </div>
              <div data-toggle="tooltip" title="Insert error box">
                <button
                  className="btn btn-sm btn-default btn-block"
                  onClick={() => commands.toggleCallout({ type: "error" })}
                  disabled={false}
                >
                  <LucideIcon name="zap" color="var(--danger)" />
                  <div>Error box</div>
                </button>
              </div>
              <div data-toggle="tooltip" title="Insert success box">
                <button
                  className="btn btn-sm btn-default btn-block"
                  onClick={() => commands.toggleCallout({ type: "success" })}
                  disabled={false}
                >
                  <LucideIcon name="circle-check-big" color="var(--success)" />
                  <div>Success box</div>
                </button>
              </div>
              <div data-toggle="tooltip" title="Insert timer">
                <button
                  className="btn btn-sm btn-default btn-block"
                  onClick={() => setShowTimerModal(true)}
                  disabled={false}
                >
                  <LucideIcon name="clock" />
                  <div>Timer</div>
                </button>
              </div>
              <div data-toggle="tooltip" title="Insert stopwatch">
                <button
                  className="btn btn-sm btn-default btn-block"
                  onClick={() => commands.toggleTimer({})}
                  disabled={false}
                >
                  <LucideIcon name="clock" />
                  <div>Stopwatch</div>
                </button>
              </div>
            </div>
          </MoreDropdown>
          <div className={styles.textEditorToolbarDivider} />
        </div>

        {!!uploadProgress && (
          <div className={styles.textEditorToolbarGroupContainer}>
            <div className={styles.textEditorToolbarUploadIndicator}>
              <CircularProgress width={20} progress={uploadProgress} />
              <div className="label label-soft-secondary" style={{ margin: 0 }}>{`Uploading... ${uploadProgress.toFixed(
                2
              )}%`}</div>
              <button
                className="btn btn-sm btn-ghost-secondary btn-block"
                onClick={() => cancelUpload && cancelUpload()}
                data-toggle="tooltip"
                title="Cancel upload"
              >
                <LucideIcon name="x" />
              </button>
              <div className={styles.textEditorToolbarDivider} />
            </div>
          </div>
        )}

        {/* HELP */}
        <div className={styles.textEditorToolbarGroupContainer}>
          <button
            data-toggle="tooltip"
            title="Help"
            className="btn btn-ghost-secondary btn-sm"
            onClick={() => setShowHelpModal(true)}
          >
            <LucideIcon name="circle-help" />
          </button>
        </div>

        {/* SAVE */}
        <div className={styles.textEditorToolbarGroupContainer}>
          <button
            disabled={saveStatus.isSaving}
            style={{ width: "117px" }}
            className="btn btn-success btn-sm btn-block"
            onClick={() => {
              save({ content: helpers.getJSON(), setSaveStatus, onSave: () => onClose && onClose() });
            }}
            data-toggle="tooltip"
            title="Close (new version)"
          >
            <LucideIcon name={saveStatus.isSaving ? "loader" : "save"} />
            <span> {saveStatus.isSaving ? "Saving..." : "Save & Close"}</span>
          </button>
        </div>
      </div>

      <Modal
        isOpen={showLinkModal}
        onClose={() => {
          setShowLinkModal(false);
          setHref("");
        }}
      >
        <div className="flex align-center gap-5" style={{ width: "50vw", padding: "10px 20px" }}>
          <div style={{ minWidth: "200px", width: "100%" }}>
            <input
              className="form-control"
              placeholder="Enter link..."
              value={href}
              onChange={(e) => setHref(e.target.value)}
              autoFocus
            />
          </div>
          <button
            className="btn btn-success btn-sm"
            onClick={() => {
              handleAddLink();
              setHref("");
            }}
          >
            <LucideIcon name="check" />
            <span> Apply link</span>
          </button>
        </div>
      </Modal>

      <Modal isOpen={showTimerModal} onClose={() => setShowTimerModal(false)}>
        <TimerModal setShowTimerModal={setShowTimerModal} toggleTimer={commands.toggleTimer} />
      </Modal>

      <Modal isOpen={showHelpModal} onClose={() => setShowHelpModal(false)}>
        <div style={{ padding: "20px 40px", backgroundColor: "var(--gray-100)", overflow: "auto" }}>
          <Markdown options={DefaultMarkdownToJsxOptions}>{helpString}</Markdown>
        </div>
      </Modal>
    </div>,
    toolbarRef.current
  );
};
