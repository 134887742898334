import { useContext, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { DetailsPageTopControlsView } from "../common/panels/misc/DetailsPageTopControlsView/DetailsPageTopControlsView";
import { SessionContext } from "../common/contexts/SessionContext";
import { LucideIcon } from "../common/icon/LucideIcon";
import { AlertModal } from "../common/modals/AlertModal/AlertModal";
import { showtoast } from "../common/overlays/Toasts/showtoast";
import Detail from "../common/panels/Detail/Detail";
import { Table } from "../common/panels/Detail/DetailTable";
import { getDetailLink } from "../main/Routing";
import { NotSet } from "../common/misc/UIconstants";
import { PreCode } from "../common/PreCode";
import { PersonsTile } from "../common/sidebar/tiles/PersonsTile";
import { DatasetsTile } from "../common/sidebar/tiles/DatasetsTile";
import { SamplesTile } from "../common/sidebar/tiles/SamplesTile";
import { EntityDetailProps, EntityDetailTableProps } from "../common/entity/EntityInterfaces";
import { CustomFieldReferencesTile } from "../common/sidebar/tiles/CustomFieldReferencesTile";

export const OrganizationsDetailTable = ({
  entity,
  entityConstants,
  fieldLabels,
}: EntityDetailTableProps<"organizations">) => {
  const { route } = useContext(SessionContext);
  let history = useHistory();
  return (
    <Table>
      <Table.Head>Basic details</Table.Head>
      <Table.Body>
        <Table.Body.RowContent title="Name" content={entity.name} />

        <Table.Body.RowContent
          title={fieldLabels.organizationTags}
          content={
            !!entity.organizationTags?.length ? (
              <div style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
                {entity.organizationTags.map((t, i) => (
                  <span
                    key={i}
                    className="label label-soft-secondary"
                    style={{ cursor: "pointer", marginRight: "5px" }}
                    onClick={() => history.push(route(getDetailLink("organization_tags", t.id)))}
                  >
                    {t.name}
                  </span>
                ))}
              </div>
            ) : (
              NotSet
            )
          }
        />

        <Table.Body.RowContent
          title={fieldLabels.email}
          content={
            entity.email ? (
              <Link
                to="#"
                onClick={(e) => {
                  window.location.href = `mailto:${entity.email}`;
                  e.preventDefault();
                }}
              >
                {entity.email}
              </Link>
            ) : (
              NotSet
            )
          }
        />

        <Table.Body.RowContent
          title={fieldLabels.address}
          content={entity.address ? <PreCode>{entity.address}</PreCode> : NotSet}
        />

        <Table.Body.RowContent title={fieldLabels.phoneOffice} content={entity.phoneOffice || NotSet} />
        <Table.Body.RowContent title={fieldLabels.faxOffice} content={entity.faxOffice || NotSet} />

        <Table.Body.RowContent
          title={fieldLabels.web}
          content={
            entity.web ? (
              <Link to={{ pathname: `https://${entity.web}` }} target="_blank">
                {entity.web}
              </Link>
            ) : (
              NotSet
            )
          }
        />

        <Table.Body.RowContent
          title={fieldLabels.notes}
          content={entity.notes ? <PreCode>{entity.notes}</PreCode> : NotSet}
        />
      </Table.Body>
    </Table>
  );
};

export const OrganizationsDetail = ({
  permissions,
  routes,
  entity,
  entityApi,
  entityConstants,
  fieldLabels,
}: EntityDetailProps<"organizations">) => {
  let history = useHistory();

  const [showModal, setShowModal] = useState<boolean>(false);
  return (
    <Detail
      head={
        <DetailsPageTopControlsView
          entity={entity}
          entityConstants={entityConstants}
          controls={
            <>
              <button
                className="btn btn-default"
                onClick={() => history.push(routes.getEditLink(entity.id))}
                disabled={!permissions.canEdit(entity)}
              >
                <LucideIcon name="square-pen" />
                <span> Edit</span>
              </button>
              <button
                className="btn btn-soft-danger"
                title="Delete"
                onClick={() => setShowModal(true)}
                disabled={!permissions.canDelete(entity)}
              >
                <LucideIcon name="trash-2" /> Delete
              </button>
              <AlertModal
                type="danger"
                showModal={showModal}
                setShowModal={setShowModal}
                title="Permanently delete organization?"
                description="This organization may be referenced by other entities. All references will be lost if you proceed."
                proceedLabel="Delete"
                onProceed={async () => {
                  await entityApi
                    .deleteMutationAsync(
                      {
                        id: entity.id,
                        goBackOnSuccess: false,
                        showToast: false,
                        entityName: "organization",
                        params: { deletePermanently: true },
                      },

                      {
                        onSuccess: () => {
                          showtoast("success", `Deleted Organization-ID: ${entity.id}.`);
                          history.goBack();
                        },
                      }
                    )
                    .catch((e) => {});
                }}
                loading={entityApi.isLoadingDeleteMutation}
              />
            </>
          }
        />
      }
      mainStyle={{ padding: "0px 10px" }}
      main={<OrganizationsDetailTable entity={entity} entityConstants={entityConstants} fieldLabels={fieldLabels} />}
      sidebar={
        <>
          {!!entity.relations?.persons.count && (
            <PersonsTile
              defaultFilters={{ organizationIds: [entity.id] }}
              excludeFilters={{ organizationIds: () => false }}
            />
          )}
          {!!entity.relations?.datasets.count && (
            <DatasetsTile
              defaultFilters={{ organizationIds: [entity.id] }}
              excludeFilters={{ organizationIds: () => false }}
            />
          )}
          {!!entity.relations?.samples.count && (
            <SamplesTile
              defaultFilters={{ organizationIds: [entity.id] }}
              excludeFilters={{ organizationIds: () => false }}
            />
          )}
          <CustomFieldReferencesTile entityType="Organization" entity={entity} />

          {/* Empty tiles */}
          {!entity.relations?.persons.count && <PersonsTile disabled />}
          {!entity.relations?.datasets.count && <DatasetsTile disabled />}
          {!entity.relations?.samples.count && <SamplesTile disabled />}
        </>
      }
    />
  );
};
