import { useEffect, useState } from "react";
// Example: const [isOpen, setOpen] = useSessionStorage('is-open', false);
// if sessionStorage does not contain "is-open" it will fall back to the state false
export const useSessionStorage = <T>(storageKey: string, fallbackState: any) => {
  const item = sessionStorage.getItem(storageKey);
  const [value, setValue] = useState<T>(typeof item === "string" ? JSON.parse(item) : fallbackState);
  useEffect(() => {
    sessionStorage.setItem(storageKey, JSON.stringify(value));
  }, [value, storageKey]);

  return { value, setValue };
};
