import React, { useCallback, useContext, useEffect, useState } from "react";
import { AuthConfigContext } from "./AuthConfigContext";
import { useQuery } from "@tanstack/react-query";
import { useAuth } from "react-oidc-context";
import { getSessionById, getSessionByJWT } from "../../api/Login";
import { getLoadingState, LoadingWrapper } from "../../common/LoadingWrapper";
import { Main } from "../Main";
import { LandingPage } from "../LandingPage/LandingPage";
import { LucideIcon } from "../../common/icon/LucideIcon";
import { OIDCLoginDisabled } from "./OidcComponents";
import { useLocalStorage } from "../../common/helperfunctions/useLocalStorage";
import { ServerError } from "../../common/helperfunctions/ApiError";

type LoginOptions = "oidc" | "local";

export interface LoginPageProps {
  group: string;
  loginOption: LoginOptions;
  setSessionToken: React.Dispatch<React.SetStateAction<string | null>>;
}

interface SessionProviderProps {
  group: string;
  children: ({ group, loginOption, setSessionToken }: LoginPageProps) => React.ReactNode;
}

/**
 * Provides session management for the application, handling both OIDC and local sessions.
 * @author @CorradoSurmanowicz
 * @param {SessionProviderProps} props - The properties for the SessionProvider component.
 * @param {string} props.group - The group identifier for the session.
 * @param {React.ReactNode} props.children - The child components to be rendered.
 *
 * @returns {JSX.Element} The rendered SessionProvider component.
 *
 * @remarks
 * This component uses the `useAuth` hook to manage authentication and the `useQuery` hook to fetch session data.
 * It supports both OIDC and local login options, storing session tokens in local or session storage.
 *
 * @example
 * ```tsx
 * <SessionProvider group="exampleGroup">
 *   {({ loginOption, group, setSessionToken }) => (
 *     <LoginComponent loginOption={loginOption} group={group} setSessionToken={setSessionToken} />
 *   )}
 * </SessionProvider>
 * ```
 *
 * @component
 * @example
 * ```tsx
 * <SessionProvider group="exampleGroup">
 *   <YourComponent />
 * </SessionProvider>
 * ```
 */
export const SessionProvider = ({ group, children }: SessionProviderProps) => {
  const authConfig = useContext(AuthConfigContext);
  const auth = useAuth();
  const oidcEnabled = authConfig.oidcEnabled;
  const [sessionToken, setSessionToken] = useState<string | null>(null);

  const { value: loginOption, setValue: setLoginOption } = useLocalStorage<LoginOptions>(
    `loginOption_${group}`,
    oidcEnabled ? "oidc" : "local"
  );

  // Queries
  // OIDC
  //   Here the session token will be auth.user.id_token
  const oidcQuery = useQuery({
    queryKey: ["session"],
    queryFn: async ({ signal }) => {
      if (sessionToken) return await getSessionByJWT(group, { token: sessionToken }, signal);
      return Promise.resolve(null);
    },
    enabled: !!sessionToken && auth.isAuthenticated && oidcEnabled && loginOption === "oidc",
    cacheTime: Infinity,
  });
  const { data: oidcSession, error: oidcError } = oidcQuery;

  //   Local session
  const savedSessionIdFromLocalStorage =
    loginOption === "local" ? localStorage.getItem(`session_${group}`) ?? null : null;
  const savedSessionIdFromSessionStorage =
    loginOption === "local" ? sessionStorage.getItem(`session_${group}`) ?? null : null;

  useEffect(() => {
    if (loginOption === "local") {
      if (savedSessionIdFromSessionStorage ?? savedSessionIdFromLocalStorage) {
        setSessionToken(savedSessionIdFromSessionStorage ?? savedSessionIdFromLocalStorage);
      }
    } else {
      setSessionToken(null);
    }
    return () => {
      setSessionToken(null);
    };
  }, [loginOption, savedSessionIdFromLocalStorage, savedSessionIdFromSessionStorage]);

  //   Here the sessionToken will be sessionId
  const localSessionQuery = useQuery({
    queryKey: ["session"],
    queryFn: async ({ signal }) => {
      if (sessionToken) return await getSessionById(group, { sessionId: sessionToken }, signal);
      return Promise.resolve(null);
    },

    enabled: !!sessionToken && loginOption === "local",
    cacheTime: Infinity,
  });

  const { data: localSession, error: localSessionError } = localSessionQuery;

  const session = localSession ?? oidcSession;

  // Common
  const clearSession = useCallback(async () => {
    if (oidcEnabled && loginOption === "oidc") {
      await auth.removeUser();
    }
    setSessionToken(null);
    localStorage.removeItem(`session_${group}`);
    sessionStorage.removeItem(`session_${group}`);
    window.history.replaceState({}, window.document.title, window.location.origin + window.location.pathname);
    // window.location.replace(`${window.location.origin}/${group}`);
  }, [auth, group, loginOption, oidcEnabled]);

  const { status, fetchStatus } = getLoadingState([oidcQuery, localSessionQuery]);

  useEffect(() => {
    if ([(oidcError as ServerError)?.status, (localSessionError as ServerError)?.status].some((c) => c === 401)) {
      console.log("Invalid session token, clearing session");
      (async () => await clearSession())();
      window.location.reload();
    }
  }, [clearSession, localSessionError, oidcError]);

  return (
    <LoadingWrapper status={status} fetchStatus={fetchStatus} error={localSessionError ?? oidcError}>
      {!session || session.noAccount ? (
        <LandingPage>
          <div
            className="flex row-nowrap gap-5 align-center"
            style={{ fontWeight: "bold", fontSize: "3em", marginBottom: 30 }}
          >
            {/* Disable group icon for now */}
            {/* <div
              className="flex row-nowrap align-center justify-center"
              style={{ background: "var(--success)", width: 36, height: 36, color: "var(--white)" }}
            >
              <LucideIcon name="crown" style={{ width: 28, height: 28 }} />
            </div> */}
            {group}
          </div>

          <div className="flex col-nowrap gap-5">
            {children({ loginOption, group, setSessionToken })}
            {oidcEnabled && (
              <button
                className="btn btn-block btn-default"
                onClick={() => setLoginOption((prev) => (prev === "local" ? "oidc" : "local"))}
              >
                <LucideIcon name="log-in" />{" "}
                {loginOption === "oidc" ? "Log in with local account" : "Log in with Single Sign-On"}
              </button>
            )}
          </div>
        </LandingPage>
      ) : oidcEnabled ? (
        session?.loginDisabled ? (
          <LandingPage>
            <OIDCLoginDisabled />
          </LandingPage>
        ) : (
          <Main group={group} session={session} authConfig={authConfig} clearSession={clearSession} />
        )
      ) : (
        <Main group={group} session={session} authConfig={authConfig} clearSession={clearSession} />
      )}
    </LoadingWrapper>
  );
};
