import { CustomField, CustomFieldFieldLabels, CustomFieldFilters } from "../../../api/CustomFields";
import { IEntityMinimalModel, DefinedPropertyObject } from "../../../api/GenericTypes";
import { SplitDatePickerFormField } from "../../../common/formfields/DatePickerFormField/SplitDatePickerFormField";
import { GenericVirtualizedSelectFormField } from "../../../common/formfields/GenericVirtualizedSelectFormField/GenericVirtualizedSelectFormField";
import { PersonsVirtualizedSelectForm } from "../../../common/forms/EntityForms/formsVirtualized/PersonsVirtualizedSelectForm";
import { EntityFilterSideBar } from "../../../common/sidebar/filtersidebar/EntityFilterBar";
import { FilterSidebarProps, useFilterSidebar } from "../../../common/sidebar/filtersidebar/FilterSidebar";
import { AbstractedCustomFieldDataTypeIcon } from "../CustomFieldRenderUtils";
import {
  AbstractedCustomFieldDataTypes,
  AbstractedCustomFieldDataTypesConsts,
  abstractedDataTypeToFilter,
} from "../CustomFieldUtils";

export interface CustomFieldFilterForm {
  createdByIds?: IEntityMinimalModel[] | undefined | null;
  createdFrom?: Date | undefined | null;
  createdTo?: Date | undefined | null;
  modifiedByIds?: IEntityMinimalModel[] | undefined | null;
  modifiedFrom?: Date | undefined | null;
  modifiedTo?: Date | undefined | null;
  dataType?: AbstractedCustomFieldDataTypes[] | undefined | null;
}
const defaultValues: DefinedPropertyObject<CustomFieldFilterForm> = {
  createdByIds: null,
  createdFrom: null,
  createdTo: null,
  modifiedByIds: null,
  modifiedFrom: null,
  modifiedTo: null,
  dataType: null,
};
const formToFilters = (form: CustomFieldFilterForm): CustomFieldFilters => {
  console.log("FORM", form);
  let filters: CustomFieldFilters = {};
  filters.createdByIds =
    Array.isArray(form.createdByIds) && form.createdByIds.length > 0 ? form.createdByIds?.map((d) => d.id) : null;
  filters.createdFrom = form.createdFrom ? form.createdFrom.toISOString() : null;
  filters.createdTo = form.createdTo ? form.createdTo.toISOString() : null;
  filters.modifiedByIds =
    Array.isArray(form.modifiedByIds) && form.modifiedByIds.length > 0 ? form.modifiedByIds?.map((d) => d.id) : null;
  filters.modifiedFrom = form.modifiedFrom ? form.modifiedFrom.toISOString() : null;
  filters.modifiedTo = form.modifiedTo ? form.modifiedTo.toISOString() : null;
  filters.dataTypes =
    Array.isArray(form.dataType) && form.dataType.length > 0
      ? form.dataType?.map((d: AbstractedCustomFieldDataTypes) => abstractedDataTypeToFilter(d)).flat()
      : null;

  return filters;
};
const defaultFilters: CustomFieldFilters = { orderBy: "NAME_ASC" };

export const CustomFieldsFilterBar = ({
  initialValues,
  setFilters,
  dispatchTabStore,
  onFormChange,
  tabsLoading,
  currentTab,
}: FilterSidebarProps<CustomField, CustomFieldFilterForm, CustomFieldFilters>) => {
  const { control, onReset } = useFilterSidebar({
    initialValues,
    defaultValues,
    defaultFilters,
    formToFilters,
    onFormChange,
    dispatchTabStore,
    setFilters,
  });

  return (
    <EntityFilterSideBar onReset={onReset}>
      <GenericVirtualizedSelectFormField
        id={"dataType"}
        label={CustomFieldFieldLabels.dataType}
        items={AbstractedCustomFieldDataTypesConsts.map((e) => e)}
        control={control}
        placeholder={"Select data type"}
        onRowStartRenderer={(row) => <AbstractedCustomFieldDataTypeIcon dataType={row} />}
        isMulti
      />

      {currentTab !== "mydata" && (
        <PersonsVirtualizedSelectForm id="createdByIds" label="Created by" control={control} isMulti />
      )}
      <SplitDatePickerFormField
        control={control}
        idStart="createdFrom"
        idEnd="createdTo"
        label="Creation date (from - to)"
        horizontal={false}
        showTimeSelect
      />
      <PersonsVirtualizedSelectForm id="modifiedByIds" label="Modified by" control={control} isMulti />

      <SplitDatePickerFormField
        control={control}
        idStart="modifiedFrom"
        idEnd="modifiedTo"
        label="Modification date (from - to)"
        horizontal={false}
        showTimeSelect
      />
    </EntityFilterSideBar>
  );
};
