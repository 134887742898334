import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { InputFormField } from "../../../formfields/InputFormField";
import { QuickAddChildrenProps } from "../common/QuickAddWrapper";
import { FormButtons } from "../../FormButtons";
import { TextareaFormField } from "../../../formfields/TextareaFormField";
import { FormHeader } from "../../FormHeader";
import { useQuickAddSubmit } from "../common/useQuickAddHooks";
import {
  LabNotebookTemplate,
  labNotebookTemplateFieldLabels,
  labNotebookTemplatesConstants,
} from "../../../../ELN/types/LabNotebookTemplate";
import { ProjectsVirtualizedSelectForm } from "../../EntityForms/formsVirtualized/ProjectsVirtualizedSelectForm";
import { EmptyLabNotebookEntry } from "../../../../ELN/types/LabNotebookEntry";

export const QuickAddLabNotebookTemplateForm = ({ onCreate, onCancel }: QuickAddChildrenProps<LabNotebookTemplate>) => {
  const { onSubmit, loading } = useQuickAddSubmit<LabNotebookTemplate>({
    resource: labNotebookTemplatesConstants.resource,
    onCreate: onCreate,
    onCancel: onCancel,
  });

  const FormSchema = yup.object().shape({
    name: yup
      .string()
      .required("Lab notebook template name is required")
      .typeError("Lab notebook template name is required"),
  });

  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
  } = useForm<LabNotebookTemplate>({
    defaultValues: {},
    resolver: yupResolver(FormSchema),
  });

  return (
    <>
      <FormHeader title="Add lab notebook template" />
      <InputFormField
        id="name"
        label={labNotebookTemplateFieldLabels.name}
        errors={errors}
        register={register}
        autoFocus={true}
        required={true}
        placeholder="Enter lab notebook template name..."
      />

      <ProjectsVirtualizedSelectForm
        id="projects"
        label={labNotebookTemplateFieldLabels.projects}
        control={control}
        showControls
        horizontal
        isMulti
        allowCreateEntity
        filters={{ currentUserHasAddPermission: true }}
      />

      <TextareaFormField
        id="description"
        label={labNotebookTemplateFieldLabels.description}
        errors={errors}
        register={register}
        placeholder="Enter description..."
      />
      <FormButtons
        onClose={onCancel}
        onSubmit={handleSubmit((entity) => onSubmit({ ...entity, content: EmptyLabNotebookEntry }))}
        disabled={isSubmitting}
        errors={errors}
        submitButtonLabel="Create"
        loading={loading}
      />
    </>
  );
};
