import React, { createRef, useState } from "react";
import { LucideIcon } from "../../common/icon/LucideIcon";
import styles from "./ManualUpload.module.css";
import { v4 as uuidv4 } from "uuid";
import { ManualUploadBundleViewProps } from "./ManualUploadBundleView";
import { useFileHandlingUtils } from "./FileHandlingUtils";
import { Button } from "../../common/buttons/Button/Button";
import { useManualUploadDragAndDropHandlers } from "./useManualUploadDropArea";

export const defaultDatasetParsersBlackList: readonly string[] = [
  "doc_multi",
  "hello_world",
  "hello_world_fileread",
  "empty_format",
  "pseudoparser",
] as const;
export const defaultDatasetParsersWhiteList: readonly string[] = [] as const;

interface ManualUploadAreaProps {
  children: (props: ManualUploadBundleViewProps) => React.ReactNode;
}
export const ManualUploadDropArea = ({ children }: ManualUploadAreaProps) => {
  const [loading, setLoading] = useState(false);
  const dirInputRef = createRef<HTMLInputElement>();
  const fileInputRef = createRef<HTMLInputElement>();
  // SETTINGS
  const isViewableEntity = false;

  const { results, dispatch, asyncDispatch, loadingStatus, readDataTransfer, readFileList, parsers, calculateSHA256 } =
    useFileHandlingUtils({
      parserWhiteList: defaultDatasetParsersWhiteList,
      parserBlackList: defaultDatasetParsersBlackList,
      setLoading: setLoading,
    });
  const { drag, dragging, handleDragEnter, handleDragLeave, handleDragOver, handleDrop } =
    useManualUploadDragAndDropHandlers({ dispatch, readDataTransfer });

  // const isChecked = Object.values(results)
  //   .map((d) => Object.values(d))
  //   .flat(1)
  //   .every((d) => d.checked === true);

  return (
    <div className={styles.container}>
      {!loading && !Object.keys(results).length && (
        <div className={styles.drop_container}>
          {/* {Object.keys(results).length === 1 ? (
          <div className={styles.drop_stats}>
            {isChecked ? <ManualUploadDropStats matchResults={Object.values(results)[0]} /> : <Spinner />}
          </div>
        ) : ( */}
          <div
            className={`${styles.drop_area} ${dragging && styles.drop_area_active}`}
            // ref={drop}
            onDrop={handleDrop}
            onDragOver={handleDragOver}
            onDragEnter={handleDragEnter}
            onDragLeave={handleDragLeave}
          >
            {dragging && <div className={styles.drag_overlay} ref={drag} />}
            {dragging ? (
              <>
                <div className={styles.drop_icon} style={{ color: "var(--gray-500)" }}>
                  <LucideIcon name="folder-plus" />
                </div>

                <h1 style={{ margin: 0, color: "var(--gray-500)" }}>Drop files</h1>
              </>
            ) : (
              <div className="flex col-nowrap align-center justify-center">
                <div className="flex col-nowrap align-center justify-center" style={{ color: "var(--gray-500)" }}>
                  <div className={styles.drop_icon}>
                    <LucideIcon name="cloud-upload" />
                  </div>
                  <span>
                    <h1 style={{ margin: 0 }}>Drag & Drop files here </h1>
                  </span>
                  <span>or</span>
                </div>
                <div className={styles.input_form}>
                  <Button
                    className="btn btn-sm btn-primary btn-block"
                    title={"Upload single file"}
                    name="upload"
                    onClick={() => fileInputRef.current?.click()}
                    loading={loading}
                  >
                    Upload Files
                  </Button>
                  <Button
                    className="btn btn-sm btn-primary btn-block"
                    title={"Upload directory"}
                    name="upload"
                    onClick={() => dirInputRef.current?.click()}
                    loading={loading}
                  >
                    Upload Directory
                  </Button>
                  <input
                    id="selectDir"
                    ref={dirInputRef}
                    hidden
                    type="file"
                    multiple
                    /* @ts-expect-error */
                    directory=""
                    webkitdirectory=""
                    onChange={async (e) => {
                      if (e.target.files) {
                        setLoading(true);
                        dispatch({ type: "clear" });
                        // console.log("e.target", e.target.files);
                        const bundleName = "/" + Array.from(e.target.files)[0].webkitRelativePath.split("/")[0];
                        const files = await readFileList(e.target.files);
                        const actionUUID = uuidv4();
                        await asyncDispatch(actionUUID, bundleName, files);
                        setLoading(false);
                      }
                    }}
                  />
                  <input
                    id="selectFile"
                    ref={fileInputRef}
                    hidden
                    type="file"
                    multiple
                    onChange={async (e) => {
                      if (e.target.files) {
                        setLoading(true);
                        dispatch({ type: "clear" });
                        const files = await readFileList(e.target.files);
                        const actionUUID = uuidv4();
                        await asyncDispatch(actionUUID, "", files);
                        setLoading(false);
                      }
                    }}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      {children({ isViewableEntity, dispatch, loading, loadingStatus, parsers, results, calculateSHA256 })}
    </div>
  );
};
