import * as d3 from "d3";

import { API } from "../api/Api";
import { ImageComponentState } from "../Images/ImageComponent";
import { Pipeline } from "../ProcessingPipeline/Pipeline";
import { PipelineRunningState, pipelineSetting, SerializedPipeline } from "../ProcessingPipeline/PipelineTypes";
import { LinearGenerator } from "../SpectrumViewer/GraphViewerTypes";
import { SpectrumComponentState } from "../SpectrumViewer/SpectrumComponent";
import { selectState } from "../SpectrumViewer/ViewerNavigation/TrackSettings";
import { trackDataType } from "../SpectrumViewer/ViewerTrackType";
import { generateUid } from "../tools/UID/UID";
import { initColor } from "./ViewerLayoutUtils";
import {
  annotationVisibilityType,
  axisElementType,
  dataTrackType as _viewerDataTrackType,
  drawType,
  normalizeType,
  trackParameterList as _trackParameterList,
  trackParameterType as _viewerTrackParameterType,
  trackState,
  trackType as _viewerTrackType,
  viewerModeType,
} from "./ViewerTypes";

export type ViewerLayoutSettings = {
  showViewer: boolean;
  showParameter: boolean;
  showNavigation: boolean;
  showTrackList: boolean;
  interactiveMode: boolean;
};
// ### New types form json schema

export type windowFunctionType = "exp" | "sine";

export interface TrackNMRProcessingData extends InternalParameterNMR {
  windowFunction: windowFunctionType;
  addPoints: number;
}

export interface NMRTrack {
  re: TrackXY;
  im: TrackXY;
  spectrum: TrackXY;
  reData?: TrackXYData;
  imData?: TrackXYData;
  settings: TrackNMRProcessingData;
  order: number;
}

export interface DatasetOptions {}

export interface ViewerSettings {
  phasing?: {
    shift: number;
    phase0: number;
    phase1: number;
  }[];
}

export enum ParsingStates {
  ParsedSuccessfully = "ParsedSuccessfully",
  NotParseable = "NotParseable",
  ParsingFailed = "ParsingFailed",
  NotYetParsed = "NotYetParsed",
}

export interface DatasetInfo {
  id: number;
  name: string;
  type: string;
  formatVersion: number;
  acquisitionDate: Date;
  parsingState: ParsingStates;
  path: string;
  parserLogs: viewerLogType[];
  downsampled: boolean;
}

export enum JobStates {
  queued = "queued",
  running = "running",
  finished = "finished",
}

export interface JobInfo {
  id: string;
  state: JobStates;
  finishedTime: string;
}

export interface FormattedParameter {
  id: number;
  active?: boolean;
  colors?: string[];
  type: string;
  content?: FormattedParameter[];
  decimal?: number;
  delimiter?: string;
  formatter?: string;
  multiline?: boolean;
  tracks?: number[];
  unit?: string;
  value?: string;
  name?: string;
}

export type TrackTag =
  | {
      name: string;
      id: string;
    }
  | string;

export const trackTypeNames = [
  "XY_real",
  // "nucleotide_sequence",
  "protein_sequence",
  "image",
  "pdf",
  "matrix_real",
  "XY_complex",
  "table",
  "molecule_compound",
] as const;

export type trackTypes = (typeof trackTypeNames)[number];

export enum settingTrackType {
  track1D = "1D",
  track2D = "2D",
  trackNucleotide = "nucleotide",
  trackProtein = "protein",
  trackImage = "image",
  trackPDF = "image",
  trackTable = "image",
  trackMolecule = "molecule",
}

export interface PlotZoomSettings {
  x: number[];
  y: number[];
  z: number[];
}

export interface Color {
  id: string;
  name?: string;
  colors: SingleColor[];
  discrete?: boolean;
  reverse?: boolean;
  range?: number[];
  mapper?: (value: number) => number;
  generate?: (offset: number) => string;
}

export const updateColor = (color: Color) => {
  const newColor = initColor(color);
  // newColor.id = generateUid();
  newColor.name = newColor.colors
    .map((c) => c.color)
    .join("_")
    .replaceAll("rgb", "")
    .replaceAll(" ", "");

  const generate = d3
    .scaleLinear()
    .domain(newColor.colors.map((c) => c.offset ?? 0))
    .range(newColor.colors.map((c) => c.color) as any)
    .interpolate(d3.interpolateRgb as any); //interpolateHsl interpolateHcl interpolateRgb
  newColor.generate = generate as any as (offset: number) => string;
  return newColor;
};

export interface SingleColor {
  name?: string;
  color: string;
  offset?: number;
  value?: number;
}

export interface ParameterBase {
  name: string;
  type: string;
  colors?: Color[];
  active?: boolean;
  tracks?: string[];
  isVisible: boolean;
  // additional properties for UI
  datasetId: string;
  // tracksIndex: number[];
  id: string;
}

export interface ParameterList extends ParameterBase {
  type: "list";
  content: Parameter[];
  border?: boolean;
}
export interface ParameterTable extends ParameterBase {
  type: "table";
  columnNumber: number;
  columnTypes: ("int" | "float" | "str" | "bool")[];
  columnDecimals?: (number | null)[];
  columnNames: (string | null)[];
  table: (string | number | null)[][];
  isLineVisible: boolean[];
}

// export interface ParameterElement extends ParameterBase {
//   type: "parameter";
//   vtype: "int" | "float" | "int[]" | "float[]" | "str";
// }

export type Parameter = ParameterList | ParameterTable | ParameterElement;

export interface ParameterElementInt {
  type: "parameter";
  vtype: "int";
  value: number;
  unit: string;
}

export interface ParameterElementFloat {
  type: "parameter";
  vtype: "float";
  value: number;
  unit: string;
  decimal?: number;
  formatter: "duration" | "length" | "voltage" | "current" | "pressure" | "frequency" | "magnetic" | "epower";
}
export interface ParameterElementStr {
  type: "parameter";
  vtype: "str";
  value: string;
  unit: string;
  multiline: boolean;
}
export interface ParameterElementIntArray {
  type: "parameter";
  vtype: "int[]";
  value: number[];
  unit: string;
  delimiter?: string;
}
export interface ParameterElementFloatArray {
  type: "parameter";
  vtype: "float[]";
  value: number[];
  unit: string;
  delimiter?: string;
}
export interface ParameterElementStrArray {
  type: "parameter";
  vtype: "str[]";
  value: string[];
  unit: string;
  delimiter?: string;
}

export type ParameterElementArray = ParameterElementIntArray | ParameterElementFloatArray | ParameterElementStrArray;

export type ParameterElement = ParameterBase &
  (
    | ParameterElementInt
    | ParameterElementFloat
    | ParameterElementIntArray
    | ParameterElementFloatArray
    | ParameterElementStr
    | ParameterElementStrArray
  );
export interface HierarchyBase {
  id: string;
  name: string;
  type: "node" | "leaf";
  tracks: string[];
  active?: boolean;
}

export interface HierarchyNode extends HierarchyBase {
  type: "node";
  content: Hierarchy[];
}

export const updateTypeInstance = <T extends object>(target: T, source: T) => {
  Object.keys(target as any).forEach((key: any) => {
    if (key in source) target[key as keyof T] = source[key as keyof T] as any;
  });
};

export const newHierarchyNode = (node?: Partial<HierarchyNode>): HierarchyNode => {
  const result: HierarchyNode = { type: "node", content: [], id: generateUid(), name: "", tracks: [], active: true };
  if (node) updateTypeInstance(result, node);
  return result;
};

export const newHierarchyLeaf = (node?: Partial<HierarchyLeaf>): HierarchyLeaf => {
  const result: HierarchyLeaf = {
    type: "leaf",
    id: generateUid(),
    name: "",
    tracks: [],
    active: true,
  };
  if (node) updateTypeInstance(result, node);
  return result;
};

export const newHierarchyLeafFromTrack = (track: Track): HierarchyLeaf => {
  const result: HierarchyLeaf = {
    type: "leaf",
    id: generateUid(),
    name: track.name,
    tracks: [track.id],
    active: true,
  };
  return result;
};

export interface HierarchyLeaf extends HierarchyBase {
  type: "leaf";
}

export type Hierarchy = HierarchyNode | HierarchyLeaf;

export enum contourTypes {
  explicitContours = "Explicit",
  equidistant = "Equidistant",
  oneBaseline = "Baseline",
  multiBaseline = "Multi Baseline",
}

export type contourState = {
  type: contourTypes;
};

export type contourGenerator = (state: any, min: number, max: number) => number[];
export type contourInitializer = (state?: contourState) => contourState;

export type contourGeneratorType = {
  // this is used as state for the reduced -> type & and state should be synchronized with the class internal fields
  state: contourState;
  generate: contourGenerator;
};

export interface TrackBaseSettings {
  zoom: PlotZoomSettings;
  hideAxis: Record<"x" | "y" | "z", boolean>;
  axisLabels: Record<"x" | "y" | "z", string | undefined>;
  axisUnits: Record<"x" | "y" | "z", string | undefined>;
  active: boolean;
  annotation: Record<"tics" | "labels", boolean>;
  color: Color;
  visible: boolean;
  selected: boolean;
  state: trackState;
}

export interface TrackXYSettings extends TrackBaseSettings {
  draw: Record<"line" | "dots", boolean>;
  normalize: Record<"0-1" | "-1-1", boolean>;
}

export interface TrackMoleculeCompoundSettings extends TrackBaseSettings {}

export interface TrackImageSettings extends TrackBaseSettings {}

export interface TrackTableSettings extends TrackBaseSettings {}

export interface TrackPdfSettings extends TrackBaseSettings {}

export interface TrackMatrixSettings extends TrackBaseSettings {
  draw: Record<"heatmap" | "contour" | "filled", boolean>;

  contours: Contour[];
  contourGenerators: contourGeneratorType[];
}

export type TrackSettings = TrackXYSettings | TrackImageSettings | TrackMatrixSettings | TrackMoleculeCompoundSettings;

export type Contour =
  | {
      type: "explicitContours";
      contours: number[];
    }
  | {
      type: "equidistant";
      count: number;
      min: number;
      max: number;
    }
  | {
      type: "oneBaseline";
      mode: "constantOffset" | "evenSpaced" | "previousScaled";
      baseline: number;
      direction: -1 | 0 | 1;
      scale: number;
      count: number;
    };

export interface TrackBaseOld {
  name: string;
  id: string;
  type: trackTypes;
  settings?: any;
  data: Record<string, any>;
  tags?: TrackTag[];

  // groups?: TrackTag[];
}
export interface TrackBase {
  name: string;
  id: string;
  type: trackTypes;
  settings?: TrackBaseSettings;
  data: Record<string, any>;
  tags?: TrackTag[];
  // additional UI fields
  datasetId: string;
  index: number;
  viewer: viewerType;
  idArray: string[];
  state: trackState;
  generation: number;
}

// export interface trackType extends TrackBase {
//   datasetId: string;
//   index: number;
//   viewer: viewerType;
//   idArray: string[];
//   state: trackState;
//   settings: trackSettingsType;
// }

export interface TrackDataBase {
  type: string;
  id: string;
  data: any;
}

export interface TrackXYData extends TrackDataBase {
  type: "XY_real";
  data: {
    x: DatatrackNumericArray[];
    y: DatatrackNumericArray[];
  };
}

export interface TrackXY extends TrackBase {
  type: "XY_real";
  data: {
    x: string | undefined;
    y: string | undefined;
  };
  settings: TrackXYSettings;
}

export interface TrackBioSequenceData extends TrackDataBase {
  type: "nucleotide_sequence" | "protein_sequence";
}

// export interface TrackBioSequence extends TrackBase {
//   type: "nucleotide_sequence" | "protein_sequence";
//   data: {
//     sequence: string | undefined;
//     index?: string | undefined;
//   };
//   settings: TrackXYSettings;
// }

export interface TrackMoleculeCompound extends TrackBase {
  type: "molecule_compound";
  data: {
    image: string;
  };
  settings: TrackMoleculeCompoundSettings;
}

export interface TrackImage extends TrackBase {
  type: "image";
  data: {
    image: string;
  };
  settings: TrackImageSettings;
}

export interface TrackImageData extends TrackDataBase {
  type: "image";
  data: {
    image: DatatrackImage[];
  };
}
export interface TrackTable extends TrackBase {
  type: "table";
  data: {
    table: string;
  };
  settings: TrackTableSettings;
}

export interface TrackTableData extends TrackDataBase {
  type: "table";
  data: {
    table: DatatrackTable[];
  };
}

export interface TrackBinaryData extends TrackDataBase {
  type: "binary";
  data: {
    binary: DatatrackBinary[];
  };
}

export interface TrackPdf extends TrackBase {
  type: "pdf";
  data: {
    pdf: string;
  };
  settings: TrackPdfSettings;
}

export interface TrackMatrixData extends TrackDataBase {
  type: "matrix_real";
  data: {
    x: DatatrackNumericArray[];
    y: DatatrackNumericArray[];
    matrix: DatatrackNumericMatrix[];
  };
}

export interface TrackMatrix extends TrackBase {
  type: "matrix_real";
  data: {
    x: string | undefined;
    y: string | undefined;
    matrix: string;
  };
  settings: TrackMatrixSettings;
}

export interface TrackXYComplexData extends TrackDataBase {
  type: "XY_complex";
}

export interface TrackXYComplex extends TrackBase {
  type: "XY_complex";
  data: {
    x: string | undefined;
    re: string | undefined;
    im: string | undefined;
  };
  settings: TrackXYSettings;
}

// export type Track = TrackXY | TrackXYComplex | TrackMatrix | TrackBioSequence | TrackImage | TrackPdf;
export type Track = TrackXY | TrackXYComplex | TrackMatrix | TrackImage | TrackPdf | TrackTable | TrackMoleculeCompound;
export type TrackData =
  | TrackXYData
  | TrackXYComplexData
  | TrackMatrixData
  | TrackBioSequenceData
  | TrackImageData
  | TrackBinaryData
  | TrackTableData;

// export interface DatatrackCharArray extends DatatrackBase {}
// export interface DatatrackImage extends DatatrackBase {}
// export interface DatatrackNumericArray extends DatatrackBase {}
// export interface DatatrackNumericMatrix extends DatatrackBase {}

// export type Datatrack = DatatrackCharArray | DatatrackImage | DatatrackNumericArray | DatatrackNumericMatrix;

//

export type datasetType = {
  id: string;
  name: string;
  logsID: number;
  tracks: string[];
  trackHierarchy: string[];
  index: number;
  trackIndex: Record<number, string>;
  parserLogs: viewerLogType[];
  parsingState: ParsingStates;
};

export type viewerTypeRecord = {
  name: string;
  // mapping: string[];
  mapping: (trackTypes | "default" | "unknown")[];
};

// export type viewerType = "xy" | "xyz" | "image" | "sequence" | "pdf" | "unknown";
export type viewerType = "xy" | "xyz" | "image" | "pdf" | "table" | "molecule" | "unknown";

export type viewerComponets =
  | "SpectrumComponent"
  | "SequenceComponent"
  | "ImageComponent"
  | "PDFComponent"
  | "TableComponent"
  | "MoleculeComponent"
  | undefined;

export const viewerMapping: Record<viewerType, viewerComponets> = {
  xy: "SpectrumComponent",
  xyz: "SpectrumComponent",
  image: "ImageComponent",
  molecule: "MoleculeComponent",
  // sequence: "SequenceComponent",
  pdf: "PDFComponent",
  table: "TableComponent",
  unknown: undefined,
};

export const viewerTypeProperties: Record<viewerType, viewerTypeRecord> = {
  xy: {
    name: "1D",
    mapping: ["XY_real", "default"],
  },
  xyz: {
    name: "2D",
    // mapping: ["matrix"],
    mapping: ["matrix_real"],
  },
  image: {
    name: "Image",
    mapping: ["image"],
  },
  pdf: {
    name: "PDF",
    mapping: ["pdf"],
  },
  table: {
    name: "Table",
    mapping: ["table"],
  },
  molecule: {
    name: "Molecule",
    mapping: ["molecule_compound"],
  },
  // sequence: {
  //   name: "Sequence",
  //   mapping: ["nucleotide_sequence", "protein_sequence"],
  // },
  unknown: {
    name: "Unknown",
    mapping: ["unknown"],
  },
};

export const copyViewerType = (source: Record<viewerType, viewerTypeRecord>): Record<viewerType, viewerTypeRecord> => {
  const target: Record<string, viewerTypeRecord> = {};
  Object.entries(source).forEach(([k, v]) => (target[k] = { name: v.name, mapping: v.mapping }));

  return target;
};

// export type shape = {};

export type viewerTrackType = _viewerTrackType;
export type viewerTrackParameterType = _viewerTrackParameterType;
export type viewerDataTrackType = _viewerDataTrackType;
export type viewerTrackParameterList = _trackParameterList;

// export type trackType = {
//   id: string;
//   datasetIndex: number;
//   datasetId: string;
//   index: number;
//   groups: string[];
//   label: string;
//   shape: any;
//   viewerTracks: string[];
//   type: viewerType;
// };

export type trackSettingsListElement = {
  id: string;
  settings: trackSettingsType;
};
export type trackSettingsList = trackSettingsListElement[];

export type annotationTypes =
  | "xPoint"
  | "yPoint"
  | "xyPoint"
  | "xRange"
  | "yRange"
  | "box"
  | "xArea"
  | "xPeak"
  | "marker"
  | "restrictase"
  | "orf";

export type AnnotationPosType =
  | { x: number }
  | { x: number; y: number }
  | { x1: number; x2: number }
  | { y1: number; y2: number }
  | { x1: number; y1: number; x2: number; y2: number }
  | { index: number };

export interface AnnotationBase {
  type: annotationTypes;
  id: string;
  color: Color;
  tracks: string[];
  label: Record<string, string>;
}

export interface AnnotationXPoint extends AnnotationBase {
  type: "xPoint";
  pos: { x: number };
  mode: "full" | "low" | "high";
  label: { default: string };
}

export interface AnnotationYPoint extends AnnotationBase {
  type: "yPoint";
  pos: { y: number };
  mode: "full" | "low" | "high" | "left" | "right";
  label: { default: string };
}

export interface AnnotationXYPoint extends AnnotationBase {
  type: "xyPoint";
  pos: { x: number; y: number };
  label: { default: string };
}

export interface AnnotationXRange extends AnnotationBase {
  type: "xRange";
  pos: { x1: number; x2: number };
  label: { default: string; min?: string; max?: string };
}

export interface AnnotationYRange extends AnnotationBase {
  type: "yRange";
  pos: { y1: number; y2: number };
  label: { default: string; min?: string; max?: string };
}

export interface AnnotationBox extends AnnotationBase {
  type: "box";
  pos: { x1: number; y1: number; x2: number; y2: number };
  mode: "border" | "fill";
  label: {
    default: string;
    topLeft: string;
    bottomLeft: string;
    topRight: string;
    bottomRight: string;
    above: string;
  };
}

export interface AnnotationXArea extends AnnotationBase {
  type: "xArea";
  pos: { x1: number; x2: number };
  label: { default: string; min?: string; max?: string };
}

export interface AnnotationXPeak extends AnnotationBase {
  type: "xPeak";
  pos: { x: number };
  mode: "impulse" | "tick";
  label: { default: string };
}

export interface AnnotationMarker extends AnnotationBase {
  type: "marker";
  pos: { index: number; length: number };
  mode: "impulse" | "tick";
  direction?: "forward" | "backward";
  label: { default: string };
}

export interface AnnotationOrf extends AnnotationBase {
  type: "orf";
  pos: { index: number; length: number };
  direction?: "forward" | "backward";
  label: { default: string };
}

export interface AnnotationRestrictase extends AnnotationBase {
  type: "restrictase";
  pos: { index: number; length: number };
  label: { default: string };
}

export type Annotation =
  | AnnotationXPoint
  | AnnotationYPoint
  | AnnotationXYPoint
  | AnnotationXRange
  | AnnotationYRange
  | AnnotationBox
  | AnnotationXArea
  | AnnotationXPeak
  | AnnotationMarker
  | AnnotationOrf
  | AnnotationRestrictase;

// const translateHideAxis = (
//   settings: Record<"x" | "y" | "z", boolean> | undefined
// ): Record<axisElementType, boolean> => {
//   const result: Record<axisElementType, boolean> = {
//     x: false,
//     y: false,
//     z: false,
//   };

//   if (settings)
//     Object.keys(result).forEach((k) => {
//       if (k in settings) result[k as keyof typeof result] = settings[k as "x" | "y" | "z"];
//     });

//   return result;
// };

const translateAnnotation = <T>(
  settings: Record<"tics" | "labels", T> | undefined,
  value: T
): Record<annotationVisibilityType, T> => {
  const result = Object.fromEntries(Object.values(annotationVisibilityType).map((k) => [k, value])) as Record<
    annotationVisibilityType,
    T
  >;

  if (settings)
    Object.keys(result).forEach((k) => {
      if (k in settings) result[k as annotationVisibilityType] = settings[k as "tics" | "labels"];
    });

  return result;
};

const translateAxistype = <T>(settings: Record<string, T> | undefined, value: T): Record<axisElementType, T> => {
  const result = Object.fromEntries(Object.values(axisElementType).map((k) => [k, value])) as Record<
    axisElementType,
    T
  >;

  if (settings)
    Object.keys(result).forEach((k) => {
      if (k in settings) result[k as axisElementType] = settings[k];
    });

  return result;
};

const translateDrawtype = <T>(settings: Record<string, T> | undefined, value: T): Record<drawType, T> => {
  const result = Object.fromEntries(Object.values(drawType).map((k) => [k, value])) as Record<drawType, T>;

  if (settings)
    Object.keys(result).forEach((k) => {
      if (k in settings) result[k as drawType] = settings[k];
    });

  return result;
};

const translateNormalizetype = <T>(settings: Record<string, T> | undefined, value: T): Record<normalizeType, T> => {
  const result = Object.fromEntries(Object.values(normalizeType).map((k) => [k, value])) as Record<normalizeType, T>;

  if (settings)
    Object.keys(result).forEach((k) => {
      if (k in settings) result[k as normalizeType] = settings[k];
    });

  return result;
};

export const trackSettingsFromTrackBaseSettings = (
  settings: TrackBaseSettings | undefined,
  type?: trackTypes
): TrackSettings => {
  const result = {
    hideAxis: translateAxistype(settings?.hideAxis, false),
    axisLabels: translateAxistype(settings?.axisLabels, undefined),
    axisUnits: translateAxistype(settings?.axisUnits, undefined),
    zoom: settings?.zoom ?? { x: [], y: [], z: [] },
    active: settings?.active ?? true,
    annotation: translateAnnotation(settings?.annotation, true),
    contourGenerators: (settings as TrackMatrixSettings)?.contourGenerators,
    // color: settings?.color ? ListToColorType(settings.color.colors.map((c) => c.color)) : ListToColorType([]),
    color: initColor(settings?.color),
    // reverseColor: settings?.color?.reverse ?? false,
    selected: false,
    visible: settings?.visible ?? true,
    state: trackState.loading,
    // draw: translateDrawtype(settings?.draw, false),
    draw: {
      line: true,
      dots: false,
      heatmap: false,
      contour: true,
      filled: false,
    } as any,
    normalize: {
      "0-1": false,
      "-1-1": false,
    },
  };

  if (type === "XY_real") {
    result.draw = translateDrawtype((settings as TrackXYSettings)?.draw, false);
    if (!result.draw.line && !result.draw.dots) result.draw.line = true;
    result.normalize = translateNormalizetype((settings as TrackXYSettings)?.normalize, false);
  } else if (type === "matrix_real") {
    result.draw = translateDrawtype((settings as TrackMatrixSettings)?.draw, false);
    if (!result.draw.contour && !result.draw.heatmap && !result.draw.filled) result.draw.contour = true;
  }

  return result;
};

export interface trackSettingsType {
  zoom: PlotZoomSettings;
  selected: boolean;
  visible: boolean;
  // color?: colorType;
  color?: Color;
  // colorID?: string;
  hideAxis: Record<axisElementType, boolean>;
  axisLabels: Record<axisElementType, string | undefined>;
  axisUnits: Record<axisElementType, string | undefined>;
  reverseColor: boolean;
  active: boolean;
  draw: Record<drawType, boolean>;
  normalize: Record<normalizeType, boolean>;
  // annotation: annotationVisibilityType[];
  annotation: Record<annotationVisibilityType, selectState>;
  contours?: contourGeneratorType;
}

// export type parameterType = Parameter & {
//   datasetId: string;
//   tracksIndex: number[];
//   id: string;
// };

export interface DatatrackBase {
  idArray: string[];
  name: string;
  id: string;
  type: string;
  count: number;
  codec: string;
  byteOffset: number;
  byteSize: number;
  min: number[];
  max: number[];
  size: number[];
  range: [number, number][];
  levels?: Level[];
  depth: number;
  index: number;
  data: any;
}

export interface Level {
  id: string;
  depth: number;
  size: number[];
  count: number;
  tileCount: number[];
  min: number[];
  max: number[];
  range: [number, number][];
  tiles: Datatrack[];
}

export interface DatatrackImage extends DatatrackBase {
  type: "image";
  size: [number, number];
  range: [[number, number], [number, number]];
  codec: "jpeg";
}

export interface DatatrackTable extends DatatrackBase {
  type: "formatted_table";
  size: [number, number];
  range: [[number, number], [number, number]];
}

export interface DatatrackBinary extends DatatrackBase {
  type: "binary";
}

export interface DatatrackCharArray extends DatatrackBase {
  type: "char";
  size: [number];
  range: [[number, number]];
}

export interface DatatrackNumericArray extends DatatrackBase {
  type: "numeric_array";
  min: [number];
  max: [number];
  size: [number];
  range: [[number, number]];
  step: number;
  data: Float32Array | Float64Array | Int32Array;

  numberType: "int" | "float" | "double";
  codec: "points" | "generator";
}

export interface DatatrackNumericMatrix extends DatatrackBase {
  type: "numeric_matrix";
  min: [number, number];
  max: [number, number];
  size: [number, number];
  range: [[number, number], [number, number]];
  numberType: "int" | "float" | "double";
  xGenerator?: LinearGenerator;
  yGenerator?: LinearGenerator;
}

export type Datatrack =
  | DatatrackImage
  | DatatrackCharArray
  | DatatrackNumericArray
  | DatatrackNumericMatrix
  | DatatrackBinary
  | DatatrackTable;

type viewerTrackListElement = {
  trackId: string;
  viewerTracks: viewerTrackType[];
};
export type viewerTrackList = viewerTrackListElement[];

export type trackDataListElement = {
  viewerTrackId: string;
  data: trackDataType;
};
export type trackDataList = trackDataListElement[];

export interface InternalParameterBase {
  id: string;
  type: string;
  name: string;
  tracks: string[];
}

export interface InternalParameterNMR extends InternalParameterBase {
  type: "nmr";
  spectralWidthInPpm: "number";
  offset: "number";
  spectralWidthInHertz: "number";
  reverseSpectrum: boolean;
}

export type InternalParameter = InternalParameterNMR;

export type viewPortType = { width: number; height: number; left: number; top: number };

export type viewerLogType = {
  type: "error" | "info" | "warning";
  message: string;
  description?: string[];
  code?: number;
  // datasetId: number | undefined;
};

export type viewerErrorType = "success" | "failed";
export type parserStatusType = {
  status: viewerErrorType;
  logs: viewerLogType[];
};

export type pipelineNodeState = {
  id: string;
  errors: string[];
  warnings: string[];
  state?: PipelineRunningState;
};

export enum trackModes {
  multiVisible = "multiVisible",
  multiSelect = "multiSelect",
  singleVisible = "singleVisible",
  singleSelect = "singleSelect",
}

export type ViewerInitState = SpectrumComponentState | ImageComponentState | SpectrumComponentState;

export type viewerPropsType = {
  datatracks: Record<string, Datatrack>;
  tracks: Record<string, Track>;
  annotations: Annotation[];
  tracksToZoom: string[];
  viewerMode: viewerModeType;
  parserLogs: viewerLogType[];
  // trackList: string[],
  viewPort: viewPortType;
  pipelines: Record<string, SerializedPipeline>;
  pipelineStates: Record<string, pipelineNodeState>;
  showNavigation: boolean;
  interactiveMode: boolean;
  api: API;
  changeTrackSettings?: (list: trackSettingsList) => void;
  changeTrackState?: (states: { id: string; state: trackState }[]) => void;
  changePipelineParameter?: (settingList: pipelineSetting[]) => void;
  setTracksToZoom?: (ids: string[]) => void;
  setViewerMode?: (viewerMode: viewerModeType) => void;
  setSelectedTracks?: (ids: string[]) => void;
  onDataExport?: (type: string, tracks: string[]) => void;
  setTrackMode?: (trackMode: trackModes) => void;
  setViewerState?: (state: ViewerInitState) => void;
  initViewerState?: ViewerInitState;
};

export type viewerStateType = {
  count: number;
  datasets: Record<string, datasetType>;
  tracks: Record<string, Track>;
  tracksHierarchy: HierarchyNode;
  datatracks: Record<string, Datatrack>;
  viewerTracks: Record<string, viewerTrackType>;
  annotations: Annotation[];
  parameter: ParameterList;
  internalParameters: InternalParameter[];
  // trackIndex: Record<number, string>;
  viewerCalls: Record<viewerType, number>;
  tracksToZoom: string[];
  selectedTracks: string[];
  viewerMode: viewerModeType;
  viewerTypes: Record<viewerType, viewerTypeRecord>;
  viewerSettings: ViewerSettings;
  activeViewer: viewerType | undefined;
  updateParameter: boolean;
  noTracks: boolean | undefined;
  noParameters: boolean | undefined;
  viewPort: viewPortType;
  pipelines: Record<string, Pipeline> | undefined;
  viewerPipelines: Record<string, SerializedPipeline>;
  pipelineStates: Record<string, pipelineNodeState>;
  parserLogs: viewerLogType[];
  viewerStatus: parserStatusType;
  pipelineParamter?: any[];
};
