import * as d3 from "d3";

import { binarySearch } from "../SpectrumViewer/GraphViewerHelper";
import { generateUid } from "../tools/UID/UID";
import {
  contourMode,
  createContour,
  equidistantContourState,
  explicitContoursState,
  oneBaselineContourState,
} from "./../SpectrumViewer/ContourClasses";
import {
  Annotation,
  Color,
  datasetType,
  Datatrack,
  DatatrackNumericArray,
  Hierarchy,
  HierarchyLeaf,
  HierarchyNode,
  newHierarchyLeaf,
  Parameter,
  ParameterList,
  ParsingStates,
  SingleColor,
  Track,
  TrackImageSettings,
  TrackMatrixSettings,
  TrackPdfSettings,
  trackSettingsFromTrackBaseSettings,
  TrackTableSettings,
  trackTypeNames,
  trackTypes,
  TrackXY,
  viewerStateType,
  viewerType,
  viewerTypeProperties,
  viewerTypeRecord,
} from "./ViewerLayoutTypes";
import { contourTypes, initViewerModeType, trackState, viewerModeType } from "./ViewerTypes";

const viewerTypeMapping: Record<string, viewerType> = {};
Object.entries(viewerTypeProperties).forEach(([k, v]) =>
  v.mapping.forEach((m) => (viewerTypeMapping[m] = k as viewerType))
);

export const copyParameterType = (source: Parameter): any => {
  return {
    // datasetId: source.datasetId,
    // tracksIndex: source.tracksIndex,
    // id: source.id,
    // active: source.active,
    // colors: source.colors,
    // type: source.type,
    // content: source.content,
    // decimal: source.decimal,
    // delimiter: source.delimiter,
    // formatter: source.formatter,
    // multiline: source.multiline,
    // tracks: undefined,
    // unit: source.unit,
    // valueType: source.valueType,
    // name: source.name,
    // value: source.value,
    // formattedValue: source.formattedValue,
    // table: source.table,
  };
};

export const initViewerType = (): Record<viewerType, viewerTypeRecord> => {
  const entry: any = {};
  // console.log("initTrackDataType", Object.values(trackDataType));
  // Object.values(viewerType).forEach((v) => (entry[v] = { count: 0, name: names[v] }));
  return entry;
};

// export function update(target: any, source: any): boolean {
//   const tt = typeof target;
//   const ts = typeof source;

//   if (tt !== ts) return true;

//   if (tt === "object") {
//     const tt = Array.isArray(target);
//     const ts = Array.isArray(source);
//     if (tt !== ts) return true;
//     if (tt) {
//       // Array
//       // console.log(">>", target.length, source.length, source);
//       if (target.length !== source.length) return true;
//       // console.log("Array", target.length);
//       for (let i in target) {
//         const update = needUpdate(target[i], source[i]);
//         // console.log(i, "===", update);
//         if (update) return true;
//       }
//     } else {
//       // console.log("Object", Object.keys(source));
//       for (let key of Object.keys(source)) {
//         // console.log(key, "->", key in target);
//         if (key in target) {
//           const update = needUpdate(target[key], source[key]);
//           // if (update) console.log(key + ":", target[key], "===", source[key], " =>", update);
//           if (update) return true;
//         } else return true;
//       }
//     }
//   } else {
//     // console.log(target, "===", source, "=>", Math.abs(target - source) > Number.EPSILON);
//     if (tt === "number") return Math.abs(target - source) > Number.EPSILON;
//     // console.log(target, "===", source, "=>", !(target === source));
//     return !(target === source);
//   }

//   // for (let [key, value] of Object.entries(source)) {
//   //   console.log(key, "->", value);
//   // }
//   return false;
// }

export const deepCopy = <T>(source: T): T => {
  if (source === null) return undefined as any;
  if (typeof source === "object") {
    if (Array.isArray(source)) {
      return source.map((e) => deepCopy(e)) as any;
    } else {
      return Object.fromEntries(Object.entries(source).map(([k, v]) => [k, deepCopy(v)])) as any;
    }
  } else {
    // console.log(target, "===", source, "=>", Math.abs(target - source) > Number.EPSILON);
    if (typeof source === "string") return ("" + source) as any;
    // console.log(target, "===", source, "=>", !(target === source));
    return source;
  }
};

export const datasetIdPrefix = (dataset: { id: string }) => {
  return ["DATASET", dataset.id];
};

export const getDatasetTracks = (tracks: Track[], dataset: { id: string }) => {
  const prefix = idArrayToId(datasetIdPrefix(dataset));
  return tracks.filter((track) => track.id.startsWith(prefix)).map((track) => track.id);
};

export const leadinZeros = (number: number, size = 3) => {
  let str = number.toString();
  while (str.length < size) str = "0" + str;
  return str;
};

export const needUpdate = (target: any, source: any): boolean => {
  if (target === null) target = undefined;
  if (source === null) target = undefined;
  const tt = typeof target;
  const ts = typeof source;

  if (tt !== ts) return true;

  if (tt === "object") {
    const tt = Array.isArray(target);
    const ts = Array.isArray(source);
    if (tt !== ts) return true;
    if (tt) {
      // Array
      // console.log(">>", target.length, source.length, source);
      if (target.length !== source.length) return true;
      // console.log("Array", target);
      for (let i in target) {
        const update = needUpdate(target[i], source[i]);
        // console.log(i, "===", update);
        if (update) return true;
      }
    } else {
      for (let key of Object.keys(source)) {
        // console.log(key, "->", target[key], source[key]);
        if (key in target) {
          const update = needUpdate(target[key], source[key]);
          // if (update) console.log(key + ":", target[key], "===", source[key], " =>", update);
          if (update) return true;
        } else return true;
      }
    }
  } else {
    // console.log(target, "===", source, "=>", Math.abs(target - source) > Number.EPSILON);
    if (tt === "number") return Math.abs(target - source) > Number.EPSILON;
    // console.log(target, "===", source, "=>", !(target === source));
    return !(target === source);
  }

  // for (let [key, value] of Object.entries(source)) {
  //   console.log(key, "->", value);
  // }
  return false;
};

export const setTrackIDFromNumber = (parameters: Parameter[], dataset: datasetType, state: viewerStateType) => {
  parameters.forEach((parameter) => {
    // console.log("parameter", parameter.name);
    // console.log("parameter", dataset);
    // if (parameter.tracks) {
    //   const tracks: string[] = [];
    //   parameter.tracks.forEach((index) => {
    //     const i: number = parseInt(index);
    //     // if (index in dataset.trackIndex) tracks.push(...state.tracks[dataset.trackIndex[i]].viewerTracks);
    //     // if (index in state.trackIndex) console.log("    track", ...state.tracks[state.trackIndex[i]].viewerTracks);
    //   });
    //   parameter.tracks = tracks;
    // }
    // if (parameter.content) setTrackIDFromNumber(parameter.content, dataset, state);
  });
};

export const setParameterTrackIDFromIndex = (
  parameters: Parameter[],
  datasets: Record<string, datasetType>,
  tracks: Record<string, Track>
): any[] => {
  return parameters.map((p) => {
    const parameter = copyParameterType(p);
    // const [parameter]: [parameterType, boolean] = updateCopy({}, p);
    // // console.log("setParameterTrackIDFromIndex", Object.keys(p).sort());
    // // console.log("                          =>", Object.keys(parameter).sort());
    // // console.log("Test", (p?.content?.[0] as any)?.table);
    // // console.log("  =>", (parameter?.content?.[0] as any)?.table);
    // if (parameter.datasetId in datasets) {
    //   const trackIndex = datasets[parameter.datasetId].trackIndex;
    //   if (parameter.tracksIndex) {
    //     const trackList: string[] = [];
    //     parameter.tracksIndex.forEach((i) => {
    //       // console.log("  track", i, trackIndex[i], Object.keys(tracks));
    //       // if (i in trackIndex) trackList.push(...tracks[trackIndex[i]].viewerTracks);
    //     });
    //     if (trackList.length > 0) parameter.tracks = trackList;
    //   }
    //   if (parameter.content) parameter.content = setParameterTrackIDFromIndex(parameter.content, datasets, tracks);
    // }
    // // console.log("  =>", (parameter?.content?.[0] as any)?.table);
    return parameter;
  });
};

export const setParameterIndex = (parameters: Parameter[], datasetId: string) => {
  parameters.forEach((parameter) => {
    // console.log("setParameterIndex", parameter.name, parameter.tracks);
    // if (parameter.tracks) parameter.tracksIndex = parameter.tracks.map((id) => parseInt(id));
    // parameter.datasetId = datasetId;
    // parameter.tracks = undefined;
    // if (parameter.content) setParameterIndex(parameter.content, datasetId);
  });
};

// export const shapeToTracks = (shape: Track): (viewerTrackType | undefined)[] => {
//   // console.log("data type", shape.type === "nmr_fid", shape.dataType);
//   // if (shape.type === "nmr_fid") shape.data_type = ["complex"];
//   // console.log("shape", shape.properties);
//   // console.log("shape", shape.dataType, shape.type);
//   if (shape.type === "image") {
//     console.log("+++ image data is not supported yet +++");
//     return [undefined];
//   }

//   // console.log("label", shape?.label || "Track " + String(shape.id + 1));
//   // shape.label = shape?.label || "Track " + String(shape.id + 1);

//   if (shape?.dataType?.[0] === "complex") {
//     const label = shape?.label || "Track " + String(shape.id + 1);
//     // console.log("TYPE", shape?.type);

//     // const shapes: any[] = [{}, {}];

//     const shapes = Array(2)
//       .fill(undefined)
//       .map((s) => {
//         const tmp: any = {};
//         Object.assign(tmp, shape);
//         tmp.dataType = ["float"];
//         // s.label = label + ".re";
//         return tmp;
//       });

//     shapes[0].label = label + ".re";
//     shapes[1].label = label + ".im";
//     // console.log("label", shapes);
//     return shapes.map((s) => shapeToTrack(s));
//   }
//   return [shapeToTrack(shape)];
// };

const namedColors: Set<string> = new Set();
[
  "BrBG",
  "PRGn",
  "PiYG",
  "PuOr",
  "RdBu",
  "RdGy",
  "RdYlBu",
  "RdYlGn",
  "Spectral",
  "Blues",
  "Greens",
  "Greys",
  "Oranges",
  "Purples",
  "Reds",
  "Turbo",
  "Viridis",
  "Inferno",
  "Magma",
  "Plasma",
  "Cividis",
  "Warm",
  "Cool",
  "CubehelixDefault",
  "BuGn",
  "BuPu",
  "GnBu",
  "OrRd",
  "PuBuGn",
  "PuBu",
  "PuRd",
  "RdPu",
  "YlGnBu",
  "YlGn",
  "YlOrBr",
  "YlOrRd",
  "Rainbow",
  "Sinebow",
].forEach((c) => namedColors.add(c));

// function shapeColorToColorType(color: undefined | string | string[] | singleColorType[]): colorType {
//   if (color === undefined) return { colors: [], offsets: [] };

//   if (typeof color === "string") {
//     // console.log(">>", color, namedColors.has(color));
//     if (namedColors.has(color)) return { colors: [], offsets: [] }; // let viewer setup the color
//     return {
//       colors: [color as string],
//       offsets: [0],
//     };
//   }

//   if (Array.isArray(color)) {
//     const colors: string[] = [];
//     const offsets: (number | undefined)[] = [];
//     const values: (number | undefined)[] = [];

//     color.forEach((c: any) => {
//       if (typeof c === "string") {
//         colors.push(c);
//         offsets.push(undefined);
//         values.push(undefined);
//       } else if (typeof c === "object" && "color" in c) {
//         colors.push(c.color);
//         offsets.push(c?.offset);
//         values.push(c?.value);
//       }
//     });

//     return {
//       colors: colors,
//       offsets: offsets as number[],
//       values: values as number[],
//     };
//   }

//   return { colors: [], offsets: [] };
// }

// function shapeToTrack(shape: Shape): viewerTrackType | undefined {
//   if (shape.dimension > 2) return undefined;
//   if (shape.dimension === 2 && shape.type !== "matrix" && shape.type !== "pdf") return undefined;

//   if (typeof shape.dataType === "string") {
//     shape.axisType = ["float"];
//     shape.dataType = [shape.dataType];
//   }

//   // const parameter = initTrackParameterType();
//   // const track: viewerTrackType = {
//   //   parameter: parameter,
//   //   type: "1D_real",
//   //   id: generateUid(),
//   //   settings: { visible: true },
//   //   // visible: true,
//   //   label: shape.label,
//   // };
//   const track = initTrackType();
//   const parameter = track.parameter;
//   track.label = shape?.label || "";
//   track.index = shape?.id === undefined ? -1 : shape?.id;

//   // console.log(">>", track.label)
//   if (track.label === "") track.label = "Track " + String(shape.id + 1);

//   switch (shape.type) {
//     case "nucleotide_sequence":
//       track.type = "nucleotide_sequence";
//       break;
//     case "protein_sequence":
//       track.type = "protein_sequence";
//       break;
//     case "matrix":
//       track.type = "2D_real_matrix";
//       break;
//     case "pdf":
//       track.type = "pdf";
//       break;
//     default:
//       // console.log("default");

//       if (shape?.dataType?.[0] === "complex") {
//         track.type = "1D_complex";
//       } else {
//         // console.log("TYPE", shape?.type);
//         if (shape?.type === "nmr_fid") track.processingType = "nmr_fid";
//         track.type = "1D_real";
//       }
//       break;
//   }

//   // if (shape.type === "matrix") {
//   //   track.type = "2D_real_matrix";
//   // } else {
//   //   if (shape?.dataType?.[0] === "complex") {
//   //     track.type = "1D_complex";
//   //   } else {
//   //     track.type = "1D_real";
//   //   }
//   // }
//   // console.log("-> shape", shape.active);

//   track.parameter.visible = shape.active === undefined || shape.active === null ? true : shape.active;

//   parameter.xlabel = shape?.axisLabels?.[0] || undefined;
//   parameter.ylabel = shape?.axisLabels?.[1] || undefined;

//   // console.log("shape keys", Object.keys(shape));

//   parameter.xunit = shape?.axisUnits?.[0] || undefined;
//   parameter.yunit = shape?.axisUnits?.[1] || undefined;

//   parameter.label = shape?.label || String(shape.id + 1);

//   parameter.axisMin = shape?.axisMin || undefined;
//   parameter.axisMax = shape?.axisMax || undefined;

//   // console.log("shape color", shape?.properties?.color || undefined);
//   // console.log("   =>", shapeColorToColorType(shape?.properties?.color || undefined));

//   parameter.color = shapeColorToColorType(shape?.properties?.color || undefined);
//   // parameter.color = [
//   //   {
//   //     color: "red",
//   //     offset: 0,
//   //   },
//   //   {
//   //     color: "green",
//   //     offset: 50,
//   //   },
//   //   {
//   //     color: "blue",
//   //     offset: 100,
//   //   },
//   // ];
//   // parameter.color = ["red", "green", "blue"];
//   // track.parameter.visible = parameter.label === "one";
//   // parameter.color = ["blue"];

//   // parameter.colorID = "";

//   parameter.reverseColor = shape?.properties?.reverseColor || false;

//   parameter.reverseColor = shape?.properties?.reverseColor || false;

//   // parameter.hideAxis = shape?.properties?.hideAxis || Array(shape.dimension + 1).fill(false);

//   // if (shape.properties.normalize)
//   //   parameter.normalize = shape.properties.normalize as viewerTrackType["parameter"]["normalize"];

//   if (shape?.properties?.hideAxis) {
//     parameter.hideAxis = Object.fromEntries(Object.values(axisElementType).map((k) => [k, false])) as Record<
//       string,
//       boolean
//     >;
//     shape?.properties?.hideAxis.forEach((v) => {
//       parameter.hideAxis[v as axisElementType] = true;
//     });
//   }

//   if (shape?.properties?.normalize) {
//     parameter.normalize = Object.fromEntries(Object.values(normalizeType).map((k) => [k, false])) as Record<
//       string,
//       boolean
//     >;
//     parameter.normalize[shape?.properties?.normalize as normalizeType] = true;
//   }

//   if (shape?.contours) {
//     // let contours: contourGeneratorType<explicitContourType>;
//     let contour: any = shape?.contours || {};
//     if (Array.isArray(shape.contours)) {
//       if (shape.contours.every((c) => typeof c === "number")) {
//         contour = {
//           type: "explicitContours",
//           contours: shape.contours,
//         };
//       }
//     }

//     if (typeof contour === "object") {
//       const inputType = contour?.type || "";
//       let type: contourTypes | undefined = undefined;
//       let k: keyof typeof contourTypes = inputType as any;
//       if (inputType in contourTypes) type = contourTypes[k];
//       // console.log(">>", inputType, "->", type);

//       switch (type) {
//         case contourTypes.explicitContours:
//           parameter.contours = createContour({
//             type: contourTypes.explicitContours,
//             contours: contour?.contours || [],
//           } as explicitContoursState);
//           break;
//         case contourTypes.equidistant:
//           parameter.contours = createContour({
//             type: contourTypes.equidistant,
//             min: contour?.min,
//             max: contour?.max,
//           } as equidistantContourState);
//           break;
//         case contourTypes.oneBaseline:
//           let m: keyof typeof contourMode = contour?.mode || "";
//           parameter.contours = createContour({
//             type: contourTypes.oneBaseline,
//             mode: m in contourMode ? contourMode[m] : undefined,
//             baseline: contour?.baseline,
//             direction: contour?.direction,
//             scale: contour?.scale,
//             count: contour?.count,
//           } as oneBaselineContourState);
//           break;
//       }

//       // switch(inputType) {
//       //   case contourTypes
//       // }

//       // console.log("INPUT", contour);
//     }

//     // console.log("OUTPUT", parameter.contours);

//     // parameter.contours = createContour({
//     //   type: contourTypes.explicitContours,
//     //   contours: [],
//     // } as explicitContoursState);

//     // parameter.contours = createContour({
//     //   type: contourTypes.equidistant,
//     //   min: -10,
//     //   max: 30,
//     // } as equidistantContourState);

//     // parameter.contours = createContour({
//     //   type: contourTypes.oneBaseline,
//     //   baseline: 7.2,
//     //   mode: contourMode.evenSpaced,
//     //   direction: 1,
//     // } as oneBaselineContourState);

//     // parameter.contours = createContour({
//     //   type: contourTypes.oneBaseline,
//     //   baseline: 7.2,
//     //   mode: contourMode.constantOffset,
//     //   scale: 2.5,
//     //   direction: 1,
//     // } as oneBaselineContourState);

//     // parameter.contours = createContour({
//     //   type: contourTypes.oneBaseline,
//     //   baseline: 5,
//     //   mode: contourMode.previousScaled,
//     //   scale: 1.2,
//     //   direction: 1,
//     // } as oneBaselineContourState);

//     // parameter.contours = {
//     //   state: multiBaselineContour.init({ count: 2, states: [{}, { direction: -1 }] } as multiBaselineContourState),
//     //   generate: multiBaselineContour.generator,
//     // };
//   }

//   if (!parameter.contours)
//     parameter.contours = createContour({
//       type: contourTypes.equidistant,
//     } as equidistantContourState);

//   if (shape?.properties?.annotation) {
//     parameter.annotation = Object.fromEntries(Object.values(annotationVisibilityType).map((k) => [k, false])) as Record<
//       string,
//       boolean
//     >;
//     shape?.properties?.annotation.forEach((v) => {
//       parameter.annotation[v as annotationVisibilityType] = true;
//     });
//   }

//   // parameter.draw = (shape?.properties?.draw as drawType[]) || defaultDraw;
//   // parameter.draw = Object.fromEntries(Object.values(drawType).map((k) => [k, false])) as Record<string, boolean>;
//   if (shape?.properties?.draw) {
//     parameter.draw = Object.fromEntries(Object.values(drawType).map((k) => [k, false])) as Record<string, boolean>;
//     shape?.properties?.draw.forEach((v) => {
//       parameter.draw[v as drawType] = true;
//     });
//   }

//   // console.log(shape.contours, "=>", track.parameter.contours);
//   // console.log("track:", track);
//   return track;
// }

// const markUsedTracks = (hierarchy: Hierarchy, marked: Record<string, boolean>) => {
//   if (hierarchy.type === "node") for (let child of hierarchy.content) markUsedTracks(child, marked);
//   else if (hierarchy?.type === "leaf" && hierarchy?.tracks?.[0] && hierarchy.tracks[0] in marked)
//     marked[hierarchy.tracks[0]] = true;
// };

export const addTrackToHierarchy = (
  hierarchy: Hierarchy,
  tracks: Record<string, { id: string; index: number; type: trackTypes }>
) => {
  const usedTracks = Object.fromEntries(Object.keys(tracks).map((id) => [id, false]));

  if (hierarchy.type !== "node") return;

  hierarchy.tracks.filter((t) => t in usedTracks).forEach((t) => (usedTracks[t] = true));

  const sortedTracks = Object.entries(usedTracks)
    .filter(([, used]) => used)
    .map(([id]) => tracks[id].index)
    .sort((a, b) => a - b);

  let treeMin = -Infinity;
  if (sortedTracks.length > 0) treeMin = sortedTracks[0];

  Object.entries(usedTracks)
    .filter(([, used]) => !used)
    .map(([id]) => tracks[id])
    .forEach((track) => {
      const leaf: HierarchyLeaf = newHierarchyLeaf({ tracks: [track.id] });
      if (track.index <= treeMin) hierarchy.content.unshift(leaf);
      else hierarchy.content.push(leaf);
    });
};

export const initDataset = (id: number): datasetType => {
  return {
    id: id.toString(),
    tracks: [],
    logsID: id,
    index: -1,
    trackIndex: {},
    name: "" + id,
    trackHierarchy: [],
    parserLogs: [],
    parsingState: ParsingStates.NotYetParsed,
  };
};

const idSeperator = "_";
const illegalCharacters = [idSeperator, "@", ":"];
RegExp(`[${illegalCharacters.join("|")}]`, "g");
export const idArrayToId = (array: string[]) => array.map((s) => s.replace(idSeperator, "")).join(idSeperator);

export const setDatatracksLevel = (datatracks: Datatrack[]) => {
  // datatracks.forEach((track) => {

  for (let track of datatracks) {
    if (!track.levels) continue;
    track.levels.forEach((level) => {
      level.tiles.forEach((tile, i) => {
        tile.depth = level.depth;
        tile.index = i;
      });
    });
  }
};

export class NumericArrayGenerator {
  min: number;
  max: number;
  length: number;
  step: number;
  _getItem: any;
  getItem: any;

  constructor(min: number, max: number, length: number) {
    this.min = min;
    this.max = max;
    this.length = length;
    this.step = (this.max - this.min) / (this.length - 1);
    // console.log("step", this.max - this.min, this.step);
  }

  get(index: number) {
    return this.min + index * this.step;
  }

  *[Symbol.iterator]() {
    for (let x = this.min; x <= this.max + this.step / 2; x += this.step) yield x;
  }
}

NumericArrayGenerator.prototype._getItem = Storage.prototype.getItem;
NumericArrayGenerator.prototype.getItem = function (key: any) {
  this._getItem("get" + key);
  // return this._getItem(key);
};

export const setDatatracksGenrators = (datatracks: Datatrack[]) => {
  for (let track of datatracks) {
    if (track.type !== "numeric_array" || track.codec !== "generator") continue;
    const x = new NumericArrayGenerator(track.min[0], track.max[0], track.count) as any;
    // const l = 10;
    // const x = new NumericArrayGenerator(1, 5, l);
    // console.log("  x", Array.from(x));
    // console.log(
    //   "get",
    //   Array(l)
    //     .fill(0)
    //     .map((_, i) => x.get(i))
    // );
    track.data = x;
    // console.log("track", track.data);
  }
};

export const getDatatracksIdMapping = (datatracks: Datatrack[], idPrefix: string[]): Record<string, string> => {
  const mapping: Record<string, string> = {};
  // const tags = [{ id: idArrayToId(["DATASET", dataset.id]), name: dataset.name }];
  // tags.push(...(track.tags?.map((t) => (typeof t !== "object" ? { id: t, name: t } : t)) ?? []));
  for (let datatrack of datatracks) {
    const id = datatrack.id;
    datatrack.idArray = [...idPrefix, "DATA", id];
    datatrack.id = idArrayToId(datatrack.idArray);
    mapping[id] = datatrack.id;
  }
  return mapping;
};

export const getTracksIdMapping = (tracks: Track[], idPrefix: string[]): Record<string, string> => {
  const mapping: Record<string, string> = {};
  for (let track of tracks) {
    const id = track.id;
    console.log("Track ID", id);
    const idArray = [...idPrefix, "TRACK", id];
    track.id = idArrayToId(idArray);
    mapping[id] = track.id;
  }
  return mapping;
};

export const mapParameters = <T>(parameter: Parameter, lambda: (node: Parameter, childrenResult: T[]) => T): T => {
  if (parameter.type === "list") {
    const result: T[] = parameter?.content.map((child) => mapParameters(child, lambda));
    return lambda(parameter, result);
  } else if (parameter.type === "parameter") return lambda(parameter, []);
  else if (parameter.type === "table") return lambda(parameter, []);

  return {} as T;
};

export const updateParameterIds = (parameter: Parameter, trackMapping: Record<string, string[]>) => {
  mapParameters(parameter, (node) => {
    if (!node.id) node.id = generateUid();
    const tracks: string[] = [];
    if (node?.tracks)
      node.tracks.forEach((t) => {
        if (trackMapping?.[t]) tracks.push(...trackMapping[t]);
        else tracks.push(t);
      });
    else Object.values(trackMapping).forEach((t) => tracks.push(...t));
    node.tracks = tracks;
  });
};

export const mapTracksHierarch = <T>(
  trackHierarchy: Hierarchy,
  lambda: (node: Hierarchy, childrenResult: T[]) => T
): T => {
  if (trackHierarchy.type === "node") {
    // for (let child of (trackHierarchy as HierarchyNode)?.content) mapTracksHierarch(child, lambda);
    const result: T[] = trackHierarchy?.content.map((child) => mapTracksHierarch(child, lambda));
    // .filter((r) => Object.keys(r).length < 1);
    return lambda(trackHierarchy, result);
  } else if (trackHierarchy?.type === "leaf") {
    return lambda(trackHierarchy, []);
  }
  return {} as T;
};

export const initHierarchyLeaf = (ref?: HierarchyLeaf): HierarchyLeaf => {
  return {
    type: "leaf",
    id: ref?.id ?? generateUid(),
    name: ref?.name ?? "leaf",
    tracks: ref?.tracks ?? [],
  };
};

export const initHierarchyNode = (ref?: HierarchyNode): HierarchyNode => {
  return {
    type: "node",
    content: ref?.content ?? [],
    id: ref?.id ?? generateUid(),
    name: ref?.name ?? "node",
    tracks: ref?.tracks ?? [],
  };
};

export const addUnusedTracksToHierarchy = (
  tracksHierarchy: Hierarchy,
  tracks: { id: string; index: number; name: string; type: trackTypes }[]
) => {
  updateTracksHierarchyTracks(tracksHierarchy);
  const tracksMap = Object.fromEntries(tracks.map((track) => [track.id, track]));
  addTrackToHierarchy(tracksHierarchy, tracksMap);
  expandComplexTracks(tracksHierarchy, tracksMap);
};

export const updateTracksHierarchyLeafNames = (trackHierarchy: Hierarchy, tracks: Record<string, { name: string }>) => {
  mapTracksHierarch(trackHierarchy, (node) => {
    if (node.type === "leaf" && node?.tracks?.[0] && tracks?.[node.tracks[0]]) {
      node.name = tracks[node.tracks[0]].name;
    }
  });
  updateTracksHierarchyTracks(trackHierarchy);
};

export const expandComplexTracks = (
  trackHierarchy: Hierarchy,
  tracks: Record<string, { name: string; type: trackTypes }>
) => {
  mapTracksHierarch<Hierarchy>(trackHierarchy, (node, children) => {
    if (node.type === "leaf") {
      const track = tracks?.[node?.tracks?.[0] ?? ""];
      if (track && track.type === "XY_complex")
        return initHierarchyNode({ name: track.name, content: [node] } as HierarchyNode);
    } else if (node.type === "node") {
      node.content = [...children];
    }
    return node;
  });
};

export const showTracksHierarchy = (node: Hierarchy, level: number = 0) => {
  if (node.type === "leaf") {
    console.log(".".repeat(level) + "LEAF", node.name, node.tracks[0]);
  }
  if (node.type === "node") {
    console.log(".".repeat(level) + "NODE", node.name, level, node.tracks);
    node.content.forEach((c) => showTracksHierarchy(c, level + 1));
  }
  return 0;
};

export const updateTracksHierarchyIds = (trackHierarchy: Hierarchy, trackMapping: Record<string, string[]>) => {
  mapTracksHierarch<Hierarchy[]>(trackHierarchy, (node, children) => {
    if (!node.id) node.id = generateUid();
    if (node.type === "leaf") {
      const tracks = trackMapping?.[node?.tracks?.[0] ?? ""] ?? [];
      if (tracks.length < 1) return [];
      if (tracks.length > 1) {
        const leafs = tracks.map((t) => {
          const result = initHierarchyLeaf(node);
          result.id = generateUid();
          result.tracks = [t];
          return result;
        });
        return leafs;
      }
      node.tracks = [tracks[0]];
      return [node];
    }
    if (node.type === "node") {
      const content: Hierarchy[] = [];
      children.forEach((c) => {
        content.push(...c);
      });
      node.content = content;
    }

    return [node];
  });
};

export const updateTracksHierarchyTracks = (trackHierarchy: Hierarchy): string[] => {
  return mapTracksHierarch<string[]>(trackHierarchy, (node, childTracks) => {
    if (node?.type === "leaf") {
      let tracks = node?.tracks ?? [];
      if ((node as any)?.track) {
        tracks.push((node as any)?.track);
        delete (node as any)?.track;
      }
      if (tracks.length > 1) tracks = tracks.slice(0, 1);
      if (tracks !== node.tracks) node.tracks = tracks;
    } else {
      node.tracks = [];
      childTracks.forEach((tracks) => node.tracks.push(...tracks));
    }
    return node.tracks;
  });
};

export const updateTracksIds = (tracks: Track[], datatrackMapping: Record<string, string>) => {
  for (let track of tracks) {
    for (let [k, id] of Object.entries(track.data)) {
      (track.data as any)[k] = datatrackMapping?.[id ?? ""];
    }
  }
};

export const SingleColorNeedsUpdate = (target: SingleColor, source: SingleColor): boolean => {
  return (
    target.color !== source.color ||
    target.offset !== source.offset ||
    Math.abs((target.offset ?? 0) - (source.offset ?? 0)) > 0.0001 ||
    target.value !== source.value
  );
};

export const ColorNeedsUpdate = (target: Color, source: Color): boolean => {
  if (target?.id !== source?.id) return true;
  if (target?.colors?.length !== source?.colors?.length) return true;
  if (target?.colors && source?.colors && target.colors.some((v, i) => SingleColorNeedsUpdate(v, source.colors[i])))
    return true;

  return false;
};

export const initSingleColor = (ref?: Partial<SingleColor> | string): SingleColor => {
  if (typeof ref === "string") ref = { color: ref };

  return {
    name: ref?.name ?? undefined,
    color: ref?.color ?? "black",
    offset: ref?.offset ?? undefined,
    value: ref?.value ?? undefined,
  };
};

const sorted = (arr: number[]) => {
  for (let i = 1; i < arr.length; i++) {
    if (arr[i - 1] > arr[i]) return false;
  }

  return true;
};

export const initColor = (ref?: Partial<Color> | SingleColor[] | string[] | string, copy?: boolean): Color => {
  // if (ref === undefined) ref = "gray";
  if (Array.isArray(ref)) ref = { colors: ref.map((c) => initSingleColor(c)) };
  else if (typeof ref === "string") ref = { colors: [initSingleColor(ref)] };
  else if (ref?.colors) {
    ref = Object.assign({}, ref);
    ref.colors = (ref.colors as any[]).map((c) => initSingleColor(c));
  }

  let colors: SingleColor[] = ref?.colors?.slice() || [];
  if (colors.length > 0) {
    // if (colors[0].offset === undefined) colors[0].offset = 0;
    const scale = colors.length > 1 ? 1 / (colors.length - 1) : 0;
    for (let i = 0; i < colors.length; i++) {
      if (colors[i].offset === undefined) colors[i].offset = i * scale;
      else if ((colors[i].offset as number) < 0) colors[i].offset = 0;
      else if ((colors[i].offset as number) > 1) colors[i].offset = 1;
    }
    if (!sorted(colors.map((c) => c.offset as number)))
      colors = colors.sort((a, b) => (a.offset as number) - (b.offset as number));
  }

  let generate: undefined | ((offset: number) => string) = undefined;
  if (colors.length > 0) {
    if (colors.length === 1) {
      colors[0].offset = 0;
      generate = (offset: number) => colors[0].color;
    } else {
      const offsets = colors.map((c) => c.offset as number);
      const len = offsets.length - 1;

      const scales = Array(len)
        .fill(0)
        .map((_, i) => {
          return d3
            .scaleSequential()
            .domain([offsets[i], offsets[i + 1]])
            .interpolator(d3.interpolate(colors[i].color, colors[i + 1].color)) as (offset: number) => string;
        });

      generate = (offset: number) => {
        const s = binarySearch(offsets, offset);
        // console.log("generate", offset, s, len);
        if (s[0] < 0) return colors[0].color;
        if (s[1] > len) return colors[len].color;
        return scales[s[0]](offset);
      };
    }
  }

  let name = ref?.name ?? undefined;
  if (copy)
    name = colors
      .map((c) => c.color)
      .join("_")
      .replaceAll("rgb", "")
      .replaceAll(" ", "");

  return {
    id: copy ? generateUid() : ref?.id ?? generateUid(),
    name: name,
    colors: colors,
    range: ref?.range,
    discrete: ref?.discrete ?? undefined,
    reverse: ref?.reverse ?? undefined,
    generate: generate,
  };
};

export const initParameterList = (ref?: Partial<ParameterList>): ParameterList => {
  return {
    ...(ref as ParameterList),
    type: "list",
    id: ref?.id ?? generateUid(),
    content: ref?.content?.map((p) => initParameter(p)) ?? [],
    name: ref?.name ?? "list",
    datasetId: "WE_DON'T_NEED_THIS_ANYMORE",
    isVisible: true,
  };
};

export const initAnnotation = (ref: Partial<Annotation>): Annotation => {
  return {
    ...(ref as Annotation),
    color: ref?.color ? initColor(ref?.color) : initColor(["black"]),
    id: ref?.id ?? generateUid(),
  };
};

export const initParameter = (ref: Partial<Parameter>): Parameter => {
  if (ref?.type === "list") return initParameterList(ref);

  return {
    ...(ref as Parameter),
    colors: ref?.colors?.map((c) => initColor(c)) ?? undefined,
    datasetId: "WE_DON'T_NEED_THIS_ANYMORE",
    // tracksIndex: [],
    id: generateUid(),
  };
};

// const convertToTrackXYSettings = (settings: Partial<TrackXYSettings>): TrackXYSettings => {
//   const result = {} as TrackXYSettings;

//   return result;
// };

export const apiTrackToTrack = (track: Partial<Track>): Track[] => {
  if (!track?.type || !trackTypeNames.includes(track.type)) return [];

  if (track?.type === "XY_complex") {
    // const settings = track?.settings ?? ({} as TrackXYSettings);

    const re = initTrack({
      ...(track as any),
      data: { x: track?.data?.x, y: track?.data?.re },
      type: "XY_real",
    } as TrackXY);

    re.idArray = ["TRACK", re.id, "COMPLEX", "re"];
    re.name += ".re";
    re.index += 0.1;

    const im = initTrack({
      ...deepCopy(track as any),
      data: { x: track?.data?.x, y: track?.data?.im },
      type: "XY_real",
    } as TrackXY);
    im.idArray = ["TRACK", im.id, "COMPLEX", "im"];
    im.name += ".im";
    im.index += 0.2;

    return [re, im];
  } else if (track?.type === "matrix_real") {
    const settings = track?.settings ?? ({} as TrackMatrixSettings);

    settings.contourGenerators = [];
    if (settings.contours) {
      for (let i in settings.contours) {
        let contour = settings.contours[i];
        switch (contour.type) {
          case "explicitContours":
            settings.contourGenerators.push(
              createContour({
                type: contourTypes.explicitContours,
                contours: contour?.contours || [],
              } as explicitContoursState)
            );
            break;
          case "equidistant":
            settings.contourGenerators.push(
              createContour({
                type: contourTypes.equidistant,
                min: contour?.min,
                max: contour?.max,
                count: contour?.count,
              } as equidistantContourState)
            );
            break;
          case "oneBaseline":
            let m: keyof typeof contourMode = contour?.mode || "";
            settings.contourGenerators.push(
              createContour({
                type: contourTypes.oneBaseline,
                mode: m in contourMode ? contourMode[m] : undefined,
                baseline: contour?.baseline,
                direction: contour?.direction,
                scale: contour?.scale,
                count: contour?.count,
              } as oneBaselineContourState)
            );
            break;
        }
      }

      if (settings.contourGenerators.length < 1)
        settings.contourGenerators.push(
          createContour({
            type: contourTypes.equidistant,
          } as equidistantContourState)
        );
    }

    track.settings = settings;

    // console.log("track", track.settings);
  } else if (track?.type === "image") {
    track.settings = track?.settings ?? ({} as TrackImageSettings);
  } else if (track?.type === "pdf") {
    track.settings = track?.settings ?? ({} as TrackPdfSettings);
  } else if (track?.type === "table") {
    track.settings = track?.settings ?? ({} as TrackTableSettings);
  }

  const t = initTrack(track);
  t.idArray = ["TRACK", t.id];

  // return { tracks: t ? [t] : [] };
  return t ? [t] : [];
};

export const initDatatrackNumericArray = (track: Partial<DatatrackNumericArray> = {}): DatatrackNumericArray => {
  track = initDatatrack(track) as Partial<DatatrackNumericArray>;

  return {
    ...(track as any),
    type: "numeric_array",
    min: track?.min ?? [0],
    max: track?.max ?? [0],
    size: track?.size ?? [0],
    range: track?.range ?? [[0, 0]],
    step: track?.step ?? 0,
    data: track?.data,
    numberType: track?.numberType ?? "float",
    codec: track?.codec ?? "points",
  };
};

export const initDatatrack = (track: Partial<Datatrack> = {}): Datatrack => {
  const id = track?.id ?? generateUid();
  return {
    ...(track as any),
    id: id,
    name: track?.name ?? id,
    type: track?.type ?? "",
    count: track?.count ?? 0,
    codec: track?.codec ?? "",
    byteOffset: track?.byteOffset ?? 0,
    byteSize: track?.byteSize ?? 0,
    min: track?.min ?? [],
    max: track?.max ?? [],
    size: track?.size ?? [],
    range: track?.range ?? [],
    depth: track?.depth ?? 0,
    index: track?.index ?? 0,
  };
};

export const initTrackXY = (track: Partial<TrackXY> = {}): TrackXY => {
  track = initTrack(track) as Partial<TrackXY>;

  return {
    ...(track as any),
    type: "XY_real",
    data: { x: track?.data?.x, y: track?.data?.y },
    settings: track.settings,
  };
};

export const initTrack = (track: Partial<Track> = {}): Track => {
  // if (shape.type === "nucleotide_sequence" || shape.type === "protein_sequence" || shape.type === "image")
  //   return undefined;

  // const tags = [{ id: idArrayToId(["DATASET", dataset.id]), name: dataset.name }];
  // tags.push(...(track.tags?.map((t) => (typeof t !== "object" ? { id: t, name: t } : t)) ?? []));
  // const tags = track.tags?.map((t) => (typeof t !== "object" ? { id: t, name: t } : t)) ?? [];

  // const data = Object.fromEntries(
  //   Object.entries(track.data).map(([key, id]) => {
  //     return [key, datatrackMapping?.[id ?? ""]];
  //   })
  // );
  // const idArray = [...idPrefix, "TRACK", track.id];

  // name: string;
  // id: string;
  // type: trackTypes;
  // settings?: TrackBaseSettings;
  // data: Record<string, any>;
  // tags?: TrackTag[];
  // // additional UI fields
  // datasetId: string;
  // index: number;
  // viewer: viewerType;
  // idArray: string[];
  // state: trackState;
  const idArray = track?.idArray ?? [];

  let id = track?.id ?? (idArray.length > 0 ? idArrayToId(idArray) : undefined) ?? generateUid();

  return {
    ...(track as any),
    id: id,
    idArray: idArray,
    name: track?.name ?? track?.id ?? "",
    type: track?.type ?? "",
    datasetId: "WE_DON'T_NEED_THIS_ANYMORE",
    // tags: tags,
    settings: trackSettingsFromTrackBaseSettings(track?.settings, track?.type),
    state: track.state ?? trackState.loading,
    // data: data,
    generation: track?.generation ?? 0,
    index: track?.index ?? 0,
    viewer:
      track?.type === null || track?.type === undefined
        ? viewerTypeMapping.default
        : track.type in viewerTypeMapping
        ? viewerTypeMapping[track.type as keyof typeof viewerTypeMapping]
        : viewerTypeMapping.unknown,
  };

  // console.log("initTrack", shape?.type);
  // return {
  //   uid: generateUid(),
  //   datasetIndex: datasetIndex,
  //   settings
  //   // datasetId: datasetId,
  //   index: parseInt(shape.id),
  //   viewer:
  //     shape?.type === null || shape?.type === undefined
  //       ? viewerTypeMapping.default
  //       : shape.type in viewerTypeMapping
  //       ? viewerTypeMapping[shape.type as keyof typeof viewerTypeMapping]
  //       : viewerTypeMapping.unknown,
  // };
};

export const initTrackFromTrack = (track: Track): any => {
  return {};
};

// export const initTrackFromTrack = (track: trackType): trackType => {
//   return {
//     ...track,
//     uid: generateUid(),
//     settings: track.settings,
//     shape: track.shape,
//     datasetIndex: track.datasetIndex,
//     datasetId: track.datasetId,
//     index: track.index,
//     groups: track.groups,
//     label: track.label,
//     type: track.type,
//   };

export const initViewerMode = (viewerSettings: any): viewerModeType => {
  const mode = initViewerModeType();

  if (viewerSettings?.fixAxis)
    (viewerSettings?.fixAxis as string[]).forEach((a) => {
      if (a === "x") mode.axisMode["fixX"] = true;
      if (a === "y") mode.axisMode["fixY"] = true;
    });

  return mode;
};

// export const useTranslatedField = function <SOURCE, TARGET>(
//   source: SOURCE,
//   translator: (source: SOURCE) => TARGET
// ): TARGET {
//   // State and setters for debounced value
//   const [target, setTarget] = useState<TARGET>(translator(source));

//   useEffect(() => {
//     setTarget(translator(source));
//   }, [source]);

//   return target;
// };

const isWritable = <T extends Object>(obj: T, key: keyof T): boolean => {
  const desc = Object.getOwnPropertyDescriptor(obj, key) || {};
  return Boolean(desc.writable);
};

export const updateCopyTranslated = <SOURCE, TARGET>(
  target: TARGET,
  source: SOURCE,
  translator: (source: SOURCE) => TARGET
): [TARGET, boolean] => {
  return updateCopy(target, translator(source));
};

export const updateCopy = (target: any, source: any): [any, boolean] => {
  const tt = typeof target;
  const ts = typeof source;

  if (source === undefined || source === null) return [source, !(target === source)];

  let needUpdate = false;
  if (tt !== ts) return [source, true];

  if (tt === "object") {
    const tt = Array.isArray(target);
    const ts = Array.isArray(source);
    if (tt !== ts) return [source, true];
    if (tt) {
      if (target.length !== source.length) return [source, true];
      if (!isWritable(target, 0)) target = target.slice();
      let u: boolean;
      for (let i in source) {
        [target[i], u] = updateCopy(target[i], source[i]);
        needUpdate = needUpdate || u;
      }
    } else {
      const keys = Object.keys(source);
      if (keys.length > 0 && !isWritable(target, keys[0])) target = Object.assign({}, target);

      let u: boolean;
      for (let key of keys) {
        // console.log("key", key, "->", source[key]);
        [target[key], u] = updateCopy(target[key], source[key]);
        needUpdate = needUpdate || u;
      }
    }
  } else {
    needUpdate = false;
    if (tt === "number") needUpdate = needUpdate || Math.abs(target - source) > Number.EPSILON;
    // console.log(target, "===", source, "=>", !(target === source));
    needUpdate = needUpdate || !(target === source);
    return [source, needUpdate];
  }
  return [target, needUpdate];
};

export const sleep = async (milliseconds: number) => {
  const date = Date.now();
  let currentDate = null;
  do {
    currentDate = Date.now();
  } while (currentDate - date < milliseconds);
};

const pipelinePromises: Record<string, "running" | "idle" | "blocked"> = {};

export const run = (id: string, exec: any) => {
  // console.log("id", id, pipelinePromises[id]);
  if (!pipelinePromises[id]) pipelinePromises[id] = "idle";
  if (pipelinePromises[id] === "idle") {
    pipelinePromises[id] = "running";
    exec(id)
      .catch((error: any) => {
        console.error(error); /* this line can also throw, e.g. when console = {} */
      })
      .finally(() => {
        if (pipelinePromises[id] === "running") pipelinePromises[id] = "idle";
        else {
          // console.log("second run");
          pipelinePromises[id] = "idle";
          run(id, exec);
        }
      });
  } else {
    // console.log("########## Blocked", id, "#############");
    pipelinePromises[id] = "blocked";
  }
};
