import React, { Component } from "react";
import { ToastContainer } from "react-toastify";
import "./style.css";
export class ToasterContainerView extends Component {
  render() {
    return (
      // <ToastContainer
      //   position="top-right"
      //   autoClose={2500}
      //   hideProgressBar={true}
      //   newestOnTop={false}
      //   closeOnClick
      //   rtl={false}
      //   pauseOnFocusLoss
      //   draggable
      //   pauseOnHover
      // />
      <ToastContainer
        position="top-center"
        autoClose={2500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    );
  }
}
