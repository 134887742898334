import React, { CSSProperties, useCallback, useContext } from "react";
import { useHistory, useParams } from "react-router-dom";
import { SessionContext } from "../contexts/SessionContext";
import { useEntityApi } from "../../api/useEntityApi";
import { getDetailLink } from "../../main/Routing";
import { useEntityDetail } from "../../api/BaseEntityApi";
import { EntityFormProps, FormEventTypes, SingleEntityRoutingParams } from "./EntityInterfaces";
import { FormLayoutContainer } from "../forms/FormLayout";
import { LoadingWrapper } from "../LoadingWrapper";
import {
  EntityByEntityTypeId,
  FiltersByEntityTypeId,
  GenericEntityConstantsEntities,
  getEntityConstants,
} from "../../api/GenericConstants";
import { useEntityPermissions } from "../permissions/useEntityPermissions";
import { PageNotAllowed } from "../../main/common/PageNotAllowed/PageNotAllowed";

interface EntityCloneFormProps<EntityTypeId extends GenericEntityConstantsEntities> {
  id: EntityByEntityTypeId<EntityTypeId>["id"];
  entityTypeId: EntityTypeId;
  event?: FormEventTypes;
  style?: CSSProperties;
  children: (props: EntityFormProps<EntityTypeId>) => React.ReactNode;
}

export const EntityCloneForm = <EntityTypeId extends GenericEntityConstantsEntities>({
  id,
  entityTypeId,
  event = "CLONE",
  style,
  children,
}: EntityCloneFormProps<EntityTypeId>) => {
  const history = useHistory();
  const { route } = useContext(SessionContext);
  const { entityConstants, fieldLabels } = getEntityConstants(entityTypeId);
  type EntityType = EntityByEntityTypeId<EntityTypeId>;
  type FilterType = FiltersByEntityTypeId<EntityTypeId>;
  const permissions = useEntityPermissions({ entityTypeId });
  const { canCreate } = permissions;

  const { createMutationAsync, isLoadingCreateMutation } = useEntityApi<EntityType>(entityConstants.resource);

  const create = useCallback(
    async (entity: Partial<EntityType>) => {
      await createMutationAsync(
        { body: entity },
        { onSuccess: (result) => history.push(route(getDetailLink(entityConstants.frontendIndexRoute, result.id))) }
      ).catch(() => {});
    },
    [createMutationAsync, entityConstants.frontendIndexRoute, history, route]
  );

  const { data, status, fetchStatus, error, isFetching } = useEntityDetail<EntityType, FilterType>(
    entityConstants.resource,
    id,
    {} as FilterType,
    {
      enabled: canCreate,
    }
  );

  if (!canCreate) return <PageNotAllowed />;
  return (
    <LoadingWrapper status={status} fetchStatus={isFetching ? "fetching" : fetchStatus} error={error}>
      <FormLayoutContainer style={style}>
        {data &&
          children({
            permissions,
            fieldLabels,
            title: `Clone ${entityConstants.entitySingular}`,
            subtitle: "",
            onCancel: history.goBack,
            submitButtonLabel: "Create",
            onSubmit: create,
            loading: isLoadingCreateMutation,
            initialValues: { ...data, id: undefined },
            event: event,
          })}
      </FormLayoutContainer>
    </LoadingWrapper>
  );
};

interface EntityCloneFormPageProps<EntityTypeId extends GenericEntityConstantsEntities> {
  isIntId?: boolean;
  style?: CSSProperties;
  children: (id: EntityByEntityTypeId<EntityTypeId>["id"]) => React.ReactNode;
}

export const EntityCloneFormPage = <EntityTypeId extends GenericEntityConstantsEntities>({
  isIntId = true,
  style,
  children,
}: EntityCloneFormPageProps<EntityTypeId>) => {
  const { id } = useParams<SingleEntityRoutingParams>();
  let _id = isIntId ? parseInt(id) : id;

  return (
    <div className="center-horizontally" style={style}>
      {children(_id as EntityByEntityTypeId<EntityTypeId>["id"])}
    </div>
  );
};
