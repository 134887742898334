import { RoleForm } from "./RoleForm";
import { EntityAddForm, EntityAddFormPage } from "../common/entity/EntityAddForm";
import { EntityCloneForm, EntityCloneFormPage } from "../common/entity/EntityCloneForm";
import { EntityCloneFormProps, EntityDetailViewProps, EntityEditFormProps } from "../common/entity/EntityInterfaces";
import { EntityEditForm, EntityEditFormPage } from "../common/entity/EntityEditForm";
import { EntityDetailPage, EntityDetailView } from "../common/entity/EntityDetailPage";
import { RolesDetail } from "./RolesDetail";
import { EntityTablePage } from "../common/entity/EntityTablePage";
import { RolesTable } from "./Table/RolesTable";

// Add
export const RoleAddForm = () => {
  return <EntityAddForm entityTypeId={"roles"}>{(props) => <RoleForm {...props} />}</EntityAddForm>;
};

export const RoleAddFormPage = () => {
  return (
    <EntityAddFormPage>
      <RoleAddForm />
    </EntityAddFormPage>
  );
};

// Clone
export const RoleCloneForm = ({ id }: EntityCloneFormProps<"roles">) => {
  return (
    <EntityCloneForm id={id} entityTypeId={"roles"}>
      {(props) => (
        <RoleForm {...props} initialValues={{ ...props.initialValues, isDefault: false, isInternal: false }} />
      )}
    </EntityCloneForm>
  );
};

export const RoleCloneFormPage = () => {
  return <EntityCloneFormPage<"roles"> isIntId>{(id) => <RoleCloneForm id={id} />}</EntityCloneFormPage>;
};

// Edit
export const RoleEditForm = ({ id }: EntityEditFormProps<"roles">) => {
  return (
    <EntityEditForm id={id} entityTypeId={"roles"} filters={{}}>
      {(props) => <RoleForm {...props} />}
    </EntityEditForm>
  );
};

export const RoleEditFormPage = () => {
  return <EntityEditFormPage<"roles"> isIntId>{(id) => <RoleEditForm id={id} />}</EntityEditFormPage>;
};

// Detail
export const RolesDetailView = ({ id }: EntityDetailViewProps<"roles">) => {
  return (
    <EntityDetailView id={id} entityTypeId={"roles"} filters={{}}>
      {(props) => <RolesDetail {...props} />}
    </EntityDetailView>
  );
};

export const RoleDetailsPage = () => {
  return <EntityDetailPage<"roles"> isIntId>{(id) => <RolesDetailView id={id} />}</EntityDetailPage>;
};

export const RolesTablePage = () => {
  return <EntityTablePage entityTypeId={"roles"}>{(props) => <RolesTable {...props} />}</EntityTablePage>;
};
