import { PipelineConnector } from "./PipelineConnector";
import { trackListType, deepCopy, initType, SerializedPipelineConnector } from "./PipelineTypes";

export class CachedPipelinConnector extends PipelineConnector {
  name!: string;
  id!: string;
  previous?: string;
  next?: string;
  inputNumber!: number;
  outputNumber!: number;
  mapping!: Record<number, number>;
  cached: boolean = true;

  cache?: trackListType;

  constructor(init?: PipelineConnector) {
    super(init);

    initType(this, init);

    // console.log("COPY", init);
  }

  connect(tracks: trackListType): trackListType {
    const result = super.connect(tracks);
    this.cache = result.map((t) => deepCopy(t));

    return result;
  }

  getCache(): trackListType {
    if (!this.cache) return [];
    return this.cache.map((t) => deepCopy(t));
  }

  serialize(): SerializedPipelineConnector {
    const result: SerializedPipelineConnector = super.serialize();

    result.cached = true;

    return result;
  }
}
