import { useState } from "react";
import { DatasetAdditionalSettings, EntityAttributes } from "../../entity-types";
import { GetPosition } from "../../EntityWrapper/EntityWrapper";
import { LucideIcon } from "../../../../../../../common/icon/LucideIcon";
import { compareAndUpdateAttributes } from "../../EntityViews";
import { Dataset } from "../../../../../../../api/Datasets";

export const DatasetControls = ({
  entity,
  attrs,
  getPosition,
  updateAttributes,
}: {
  entity: Dataset;
  attrs: EntityAttributes<DatasetAdditionalSettings>;
  getPosition: GetPosition;
  updateAttributes: (attrs: EntityAttributes<DatasetAdditionalSettings>) => void;
}) => {
  const [showDetails, setShowDetails] = useState<boolean>(attrs.additionalSettings?.showDetails ?? false);
  const [showSourceInformation, setShowSourceInformation] = useState<boolean>(
    attrs.additionalSettings?.showSourceInformation ?? false
  );
  const [showParameters, setShowParameters] = useState<boolean>(attrs.additionalSettings?.showParameters ?? false);
  const [showViewer, setShowViewer] = useState<boolean>(attrs.additionalSettings?.showViewer ?? true);

  return (
    <>
      <button
        style={{ textAlign: "left", width: "100%" }}
        className="btn btn-xs btn-ghost-secondary btn-block"
        disabled={!!attrs.settings?.defaultCollapsed}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setShowViewer((prevState) => {
            compareAndUpdateAttributes(
              { ...attrs, additionalSettings: { ...attrs.additionalSettings, showViewer: !prevState } },
              attrs,
              updateAttributes
            );
            return !prevState;
          });
        }}
      >
        <LucideIcon name={showViewer ? "eye-off" : "eye"} /> {showViewer ? "Hide" : "Show"} viewer
      </button>
      <button
        style={{ textAlign: "left", width: "100%" }}
        className="btn btn-xs btn-ghost-secondary btn-block"
        disabled={!!attrs.settings?.defaultCollapsed}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setShowDetails((prevState) => {
            compareAndUpdateAttributes(
              { ...attrs, additionalSettings: { ...attrs.additionalSettings, showDetails: !prevState } },
              attrs,
              updateAttributes
            );
            return !prevState;
          });
        }}
      >
        <LucideIcon name={showDetails ? "eye-off" : "eye"} /> {showDetails ? "Hide" : "Show"} details
      </button>
      {!entity.isViewableEntity && (
        <button
          style={{ textAlign: "left", width: "100%" }}
          className="btn btn-xs btn-ghost-secondary btn-block"
          disabled={!!attrs.settings?.defaultCollapsed}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setShowSourceInformation((prevState) => {
              compareAndUpdateAttributes(
                { ...attrs, additionalSettings: { ...attrs.additionalSettings, showSourceInformation: !prevState } },
                attrs,
                updateAttributes
              );
              return !prevState;
            });
          }}
        >
          <LucideIcon name={showSourceInformation ? "eye-off" : "eye"} /> {showSourceInformation ? "Hide" : "Show"}{" "}
          source information
        </button>
      )}
      {!entity.isViewableEntity && (
        <button
          style={{ textAlign: "left", width: "100%" }}
          className="btn btn-xs btn-ghost-secondary btn-block"
          disabled={!!attrs.settings?.defaultCollapsed}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setShowParameters((prevState) => {
              compareAndUpdateAttributes(
                { ...attrs, additionalSettings: { ...attrs.additionalSettings, showParameters: !prevState } },
                attrs,
                updateAttributes
              );
              return !prevState;
            });
          }}
        >
          <LucideIcon name={showParameters ? "eye-off" : "eye"} /> {showParameters ? "Hide" : "Show"} parameters
        </button>
      )}
    </>
  );
};
