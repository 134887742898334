import { useCallback, useMemo, useRef, useState } from "react";
import Draggable from "react-draggable";

import styles from "./SpreadSheet.module.css";
import {
  CellElementProps,
  CellIndex,
  CellProps,
  FormattedTableCell,
  indexToLetter,
  LinePosType,
} from "./SpreadSheetTypes";

export const HeaderCell = ({
  columnIndex,
  rowIndex,
  index,
  style,
  cellState,
  addToColumnWidth,
  setLinePos,
  showColumnIndex,
  showColumnLetterIndex,
  ColumnIndexElement,
  ColumnNameElement,
  CellValues,
}: CellProps & {
  setLinePos: (pos: LinePosType | undefined) => void;
  showColumnIndex: boolean;
  showColumnLetterIndex: boolean;
  ColumnIndexElement?: (props: CellElementProps) => JSX.Element;
  ColumnNameElement?: (props: CellElementProps) => JSX.Element;
  CellValues: (index: CellIndex) => FormattedTableCell;
}) => {
  // const [width, setWidth] = useState<number>(style?.width ? parseInt(style?.width?.toString()) : 100);
  // const width = useFieldsUpdated(style?.width ? parseInt(style?.width?.toString()) : 100);
  const [, setHover] = useState(false);
  const ref = useRef<any>();

  const width = useMemo(() => (style?.width ? parseInt(style?.width?.toString()) : 0), [style?.width]);
  const height = useMemo(() => (style?.height ? parseInt(style?.height?.toString()) : 0), [style?.height]);

  const setPosition = useCallback(() => {
    const element = ref.current as Element;
    const bound = element?.getBoundingClientRect() || { width: 0, height: 0, top: 0, left: 0 };
    setLinePos({ left: bound.left + bound.width / 2, top: bound.top, mode: "vertical" });
    // console.log("bound", bound.left, bound.width)
    // setLinePos({ left: bound.left, top: bound.top, mode: "vertical", row: 0, column: columnIndex });
  }, [ref, setLinePos]);

  const content = useMemo(() => {
    if (showColumnIndex) {
      if (rowIndex < 1)
        return ColumnIndexElement ? (
          <ColumnIndexElement
            width={width}
            height={height}
            columnIndex={columnIndex}
            rowIndex={rowIndex}
            content={{
              value: showColumnLetterIndex ? indexToLetter(columnIndex + 1) : columnIndex + 1,
              type: "string",
              row: rowIndex,
              column: columnIndex,
              settings: {},
            }}
            settings={{ focused: columnIndex === cellState.focused.columnIndex }}
          />
        ) : (
          <div className={columnIndex === cellState.focused.columnIndex ? styles.FixedGridCellFocused : undefined}>
            {showColumnLetterIndex ? indexToLetter(columnIndex + 1) : columnIndex + 1}
          </div>
        );
      rowIndex--;
    }
    return ColumnNameElement ? (
      <ColumnNameElement
        width={width}
        height={height}
        columnIndex={columnIndex}
        rowIndex={rowIndex}
        content={CellValues({ rowIndex: rowIndex, columnIndex: columnIndex })}
        //   {
        //   value: `Header ${rowIndex} ${columnIndex}`,
        //   type: "string",
        //   row: rowIndex,
        //   column: columnIndex,
        // }}
        settings={{ focused: columnIndex === cellState.focused.columnIndex }}
      />
    ) : (
      <div className={columnIndex === cellState.focused.columnIndex ? styles.FixedGridCellFocused : undefined}>
        Header {rowIndex} {columnIndex}
      </div>
    );
  }, [showColumnIndex, ColumnNameElement, width, height, columnIndex, rowIndex, CellValues, cellState.focused.columnIndex, ColumnIndexElement, showColumnLetterIndex]);

  return (
    <div className={styles.FixedGridCell} key={index} style={style}>
      <div className={styles.FixedGridCellInner} style={{ width: width, height: height }}>
        {content}
      </div>
      <Draggable
        axis="x"
        defaultClassName={styles.DragHandle}
        defaultClassNameDragging={styles.DragHandleActive}
        onDrag={() => {
          setPosition();
          setHover(true);
        }}
        onStart={() => {
          setPosition();
          setHover(true);
        }}
        onMouseDown={setPosition}
        onStop={(_, data) => {
          setLinePos(undefined);
          setHover(false);
          if (addToColumnWidth) addToColumnWidth(columnIndex, data.x);
        }}
        position={{
          x: 0,
          y: 0,
        }}
      >
        <div ref={ref} style={{ left: width - 3, height: height, width: 6, cursor: "col-resize" }} />
      </Draggable>
    </div>
  );
};
