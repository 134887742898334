import React from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Method, MethodFieldLabels, methodsConstants } from "../../../../api/Methods";
import { useForm } from "react-hook-form";
import { InputFormField } from "../../../formfields/InputFormField";
import { QuickAddChildrenProps } from "../common/QuickAddWrapper";
import { FormButtons } from "../../FormButtons";
import { FormHeader } from "../../FormHeader";
import { useQuickAddSubmit } from "../common/useQuickAddHooks";

export const QuickAddMethodForm = ({ onCreate, onCancel }: QuickAddChildrenProps<Method>) => {
  const { onSubmit, loading } = useQuickAddSubmit<Method>({
    resource: methodsConstants.resource,
    onCreate: onCreate,
    onCancel: onCancel,
  });

  const FormSchema = yup.object().shape({
    name: yup.string().required("Name is required"),
    fullName: yup.string().required("Full name is required"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<Method>({
    defaultValues: {},
    resolver: yupResolver(FormSchema),
  });

  return (
    <>
      <FormHeader title={"Add method"} />
      <InputFormField
        id={"name"}
        label={MethodFieldLabels.name}
        errors={errors}
        register={register}
        autoFocus={true}
        required={true}
      />
      <InputFormField
        id={"fullName"}
        label={MethodFieldLabels.fullName}
        errors={errors}
        register={register}
        autoFocus={false}
        required={true}
      />
      <FormButtons
        groupName={"methods"}
        entityId={undefined}
        onClose={onCancel}
        onSubmit={handleSubmit(onSubmit)}
        disabled={isSubmitting}
        errors={errors}
        submitButtonLabel="Create"
        loading={loading}
      />
    </>
  );
};
