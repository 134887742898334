import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { FilesStructure, Dataset } from "../../../api/Datasets";
import { LucideIcon } from "../../../common/icon/LucideIcon";
import { LoadingWrapper } from "../../../common/LoadingWrapper";
import { Container } from "../../../common/panels/Container/Container";
import { useFileStructure } from "../../common/Tree/Folder/helpers/FileHelpers";
import { APIFilePreview } from "../../common/Tree/Folder/helpers/FilePreview";
import { FileTree } from "../../common/Tree/Tree";
import { DatasetContext, FullScreenOpts } from "../../Dataset";
import styles from "./Files.module.css";

export interface TreeElementBase {
  id: string;
  name: string;
  parentId: string | undefined;
  hasChildren: [];
  size: number;
  mtime: number;
  path: string;
  hash?: string;
  contents: [];
  uniqueId: string;
}
export type TreeElement<T> = T & TreeElementBase;
export type TreeElements<T> = TreeElement<T>[];

export const getTreeData = <T extends TreeElementBase>(treeData: TreeElements<T>): TreeElements<T> => {
  return treeData.map((item: T) => ({
    ...item,
    hasChildren: treeData.filter((i) => i.parentId === item.id).map((d) => d.id),
  })) as TreeElements<T>;
};

/**
 * Recursively accumulates the file sizes of fileStructure and adds that info to the elements with isDirectory = true.
 * @param fileStructure The file structure to accumulate sizes for.
 * @returns The accumulated file sizes.
 * @author @CorradoSurmanowicz
 */
const accumulateFileSizes = (fileStructure: FilesStructure) => {
  const accumulateSizes = (structure: FilesStructure): number => {
    return Object.entries(structure).reduce((totalSize, [key, value]) => {
      if (value.isDirectory && value.contents) {
        value.size = accumulateSizes(value.contents);
      }
      return totalSize + (value.size || 0);
    }, 0);
  };
  accumulateSizes(fileStructure);
};

export const buildTree = <T extends TreeElementBase>(
  files: FilesStructure | TreeElements<T>,
  fullpath?: string,
  struct: TreeElements<T> = []
) => {
  Object.entries(files).forEach(([name, content]) => {
    let this_obj = content as TreeElement<T>;
    if (fullpath) {
      if (this_obj.path) {
        this_obj = { ...this_obj, path: [this_obj.path, fullpath, name].join("/"), id: name, parentId: fullpath };
      } else {
        this_obj = {
          ...this_obj,
          path: [fullpath, name].join("/"),
          id: [fullpath, name].join("/"),
          parentId: fullpath,
        };
      }
    } else {
      this_obj = { ...this_obj, id: name, path: name, parentId: undefined };
    }
    if (this_obj.hasOwnProperty("isDirectory") && this_obj.hasOwnProperty("contents") && this_obj.contents) {
      // console.log("Folder", name, content, fullpath)
      struct.push({
        ...this_obj,
        id: this_obj.id,
        parentId: this_obj.parentId,
        name: this_obj.name,
        hasChildren: [],
      });
      buildTree(this_obj.contents, fullpath ? [fullpath, name].join("/") : name, struct);
    } else {
      // console.log("Child", name, content)
      this_obj = { ...this_obj, name: name };
      struct.push(this_obj);
    }
  });
};
interface Props {
  dataset: Dataset;
  fullscreen?: {
    component: string;
    state: boolean;
  };
  fullScreenToggle?: (component: FullScreenOpts["component"]) => void;
}
export const Files = ({ dataset, fullscreen, fullScreenToggle }: Props) => {
  const context = useContext(DatasetContext);

  const [showModal, setShowModal] = useState<string>();
  const onModalCloseCallback = useCallback(() => {
    setShowModal(undefined);
  }, []);

  const onFileClick = useCallback((item: TreeElementBase) => {
    setShowModal(item.id);
  }, []);

  const fileControls = useCallback(
    (item: TreeElementBase) => (
      <APIFilePreview
        datasetId={dataset.id}
        item={item}
        defaultShowModal={showModal === item.id}
        onModalCloseCallback={onModalCloseCallback}
      />
    ),
    [dataset.id, onModalCloseCallback, showModal]
  );

  return (
    <Container
      title="Files"
      controls={
        fullscreen ? (
          <div className={styles.container_controls}>
            <div
              className={styles.container_controls_fullscreen}
              onClick={() => fullScreenToggle && fullScreenToggle("files")}
            >
              {fullscreen?.state ? <LucideIcon name="minimize" /> : <LucideIcon name="maximize" />}
            </div>
          </div>
        ) : undefined
      }
    >
      <div className={styles.container}>
        <div className={styles.container_content}>
          <FilesCore
            dataset={dataset}
            onFileClick={onFileClick}
            fileControls={context?.viewShared ? undefined : fileControls}
          />
        </div>
      </div>
    </Container>
  );
};

interface FilesCoreProps {
  dataset: Dataset;
  onFileClick?: (item: TreeElementBase) => void;
  fileControls?: (item: TreeElementBase) => JSX.Element;
}
export const FilesCore = ({ dataset, onFileClick, fileControls }: FilesCoreProps) => {
  const [fileStructure, setFileStructure] = useState<FilesStructure>();
  const [struct, setStruct] = useState<TreeElements<FilesStructure>>([]);

  // Fetch the file structure
  const { filesStructure, filesStructureStatus, fetchStatus } = useFileStructure(dataset.id);

  useEffect(() => {
    if (filesStructure) {
      accumulateFileSizes(filesStructure);
      setFileStructure(() => filesStructure);
    }
    return () => {
      setFileStructure(undefined);
    };
  }, [filesStructure]);

  useMemo(() => {
    if (fileStructure) {
      let struct: TreeElements<FilesStructure> = [];
      buildTree(fileStructure, undefined, struct);
      setStruct(() => struct);
    }
  }, [fileStructure]);
  return (
    <LoadingWrapper status={filesStructureStatus} fetchStatus={fetchStatus}>
      {struct && (
        <FileTree
          files={getTreeData(struct)}
          level={0}
          onFileClick={onFileClick}
          fileControls={fileControls}
          // previewFile={filePreview}
          // downloadSingleFile={downloadSingleFile}
        />
      )}
    </LoadingWrapper>
  );
};
