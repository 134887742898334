import produce from "immer";
import { useEffect } from "react";
import { Button } from "react-bootstrap";
import { ContourType } from "./OneBaselineContourEditor";

import styles from "./OneBaselineContourEditor.module.css";

export const OneBaselineEvenSpaced = ({
  contours,
  setContours,
  setMinHeight,
}: {
  contours: ContourType;
  setContours: (contours: ContourType) => void;
  setMinHeight?: (height: number) => void;
}) => {
  useEffect(() => {
    if (setMinHeight) setMinHeight(280);
  }, []);

  return (
    <span className={styles.sliderText}>
      Direction:{" "}
      <Button
        bsSize="xs"
        onClick={() => {
          setContours(
            produce(contours, (next) => {
              next.direction = contours.direction < 0 ? 1 : -1;
            })
          );
        }}
      >
        <span
          className={
            "glyphicon " +
            (contours.direction < 0
              ? "glyphicon-arrow-left"
              : contours.direction > 0
              ? "glyphicon-arrow-right"
              : "glyphicon-resize-horizontal")
          }
        />
      </Button>
    </span>
  );
};
