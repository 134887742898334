import { CustomFieldForm } from "../Customization/CustomFields/CustomFieldsForm";
import { CustomField } from "./CustomFields";
import {
  PropertyTranslator,
  IGenericRequestParametersTranslator,
  IRelationParametersTranslator,
  IPaginationParametersTranslator,
  ISoftDeletableFilterParametersTranslator,
  ICreatedRecordParametersTranslator,
  IModifiedRecordParametersTranslator,
  ICreationRecordLabels,
  IModificationRecordLabels,
  INamedEntityLabels,
  IPermissionedEntityLabels,
  IRelatedEntityLabels,
  IUniqueEntityLabels,
  ISoftDeletableLabels,
} from "./GenericTranslator";
import {
  DefaultCreatedByRecordOrderTypeConsts,
  DefaultCreatedOnRecordOrderTypeConsts,
  DefaultHierarchyOrderTypeConsts,
  DefaultModifiedByRecordOrderTypeConsts,
  DefaultModifiedOnRecordOrderTypeConsts,
  DefaultOrderTypeConsts,
  EntityConstants,
  ICreatedRecordParameters,
  ICreationRecord,
  IEntityMinimalModel,
  IGenericRequestParameters,
  IHierarchyTypeRead,
  IModelWithIntId,
  IModificationRecord,
  IModifiedRecordParameters,
  INamedEntity,
  IPaginationParameters,
  IPermissionedEntity,
  IRelatedEntity,
  IRelation,
  IRelationModel,
  IRelationParameters,
  ISoftDeletable,
  ISoftDeletableFilterParameters,
  IUniqueEntity,
  Nullable,
} from "./GenericTypes";

export const customTypeConstants: EntityConstants<CustomType, CustomTypeFilters> = {
  resource: "types",
  frontendIndexRoute: "types",
  entitySingular: "custom type",
  entityPlural: "custom types",
  icon: "panel-top",
};

export interface CustomType
  extends IModelWithIntId,
    IPermissionedEntity,
    IRelatedEntity<CustomTypeRelations>,
    INamedEntity,
    IUniqueEntity,
    ISoftDeletable,
    ICreationRecord,
    IModificationRecord,
    IHierarchyTypeRead {
  description: string;
  inventoryDescription?: string | null;
  entityType: CustomTypeEntityType;
  isEnabled: boolean;
  hasRestrictedAddPermission: boolean;
  hasRestrictedEditPermission: boolean;
  hasRestrictedReadPermission: boolean;
  sections: CustomTypeSection[];
}

export interface CustomTypeWriteModel extends Partial<Nullable<Omit<CustomType, "sections">>> {
  sections?: Partial<Nullable<CustomTypeSectionWriteModel>>[];
}

export const CustomTypeFieldLabels: PropertyTranslator<CustomType> & { layout: string } = {
  ...INamedEntityLabels,
  ...IUniqueEntityLabels,
  ...IPermissionedEntityLabels,
  ...ICreationRecordLabels,
  ...IModificationRecordLabels,
  ...IRelatedEntityLabels,
  ...ISoftDeletableLabels,
  id: "Custom type ID",
  description: "Description",
  entityType: "Entity type",
  sections: "Sections",
  isEnabled: "Is enabled",
  rootHierarchy: "Root hierarchy",
  isHierarchyRoot: "Enable hierarchy",
  layout: "Layout",
  inventoryDescription: "Inventory description",
  hasRestrictedAddPermission: "Has restricted add permission",
  hasRestrictedEditPermission: "Has restricted edit permission",
  hasRestrictedReadPermission: "Has restricted read permission",
  inventoryName: "Inventory name",
  parentTypes: "Parent types",
} as const;

export interface CustomTypeSection extends INamedEntity {
  isFolded?: boolean;
  customFields: CustomField[];
}

export interface CustomTypeSectionWriteModel extends Partial<Nullable<Omit<CustomTypeSection, "customFields">>> {
  customFields: Partial<CustomFieldForm>[];
}
// We decided to remove Persons, Facilities and Projects from custom types for now
export const CustomTypeEntityTypeOptions = [
  "Dataset",
  "Instrument",
  "Inventory",
  "Person",
  "Project",
  "Sample",
] as const;
export const CustomTypeEntityTypeOptionsReduced = ["Dataset", "Inventory", "Project", "Sample"] as const;

export type CustomTypeEntityType = (typeof CustomTypeEntityTypeOptions)[number];

export interface CustomTypeSuggestions extends IEntityMinimalModel<CustomType["id"]> {
  inventoryName?: CustomType["inventoryName"];
  isHierarchyRoot?: CustomType["isHierarchyRoot"];
}

export interface CustomTypeRelations extends IRelationModel {
  datasets: IRelation;
  samples: IRelation;
  inventories: IRelation;
  persons: IRelation;
  facilities: IRelation;
  projects: IRelation;
}
export interface CustomTypeFilters
  extends IGenericRequestParameters<CustomType, CustomTypeSortingOptions>,
    IRelationParameters,
    IPaginationParameters,
    IRelationParameters,
    ISoftDeletableFilterParameters,
    ICreatedRecordParameters,
    IModifiedRecordParameters {
  customFieldIds?: number[] | null;
  entityTypes?: CustomTypeEntityType[] | null;
  isEnabled?: boolean | null;
  excludeDisabled?: boolean | null;
  extendSearchToInventoryItems?: boolean | null;
  parentTypeIds?: number[] | null;
  rootHierarchyIds?: number[] | null;
  isInventory?: boolean | null;
  isHierarchyRoot?: boolean | null;
  excludeHierarchyChildren?: boolean | null;
  inventoryNames?: string[] | null;
  names?: string[] | null;
  excludeNonInventories?: boolean | null;
  hasRestrictedAddPermission?: boolean | null;
  hasRestrictedEditPermission?: boolean | null;
  hasRestrictedReadPermission?: boolean | null;
}

export const CustomTypeSortingOptionsConsts = [
  ...DefaultOrderTypeConsts,
  ...DefaultCreatedOnRecordOrderTypeConsts,
  ...DefaultCreatedByRecordOrderTypeConsts,
  ...DefaultModifiedOnRecordOrderTypeConsts,
  ...DefaultModifiedByRecordOrderTypeConsts,
  ...DefaultHierarchyOrderTypeConsts,
] as const;
export type CustomTypeSortingOptions = (typeof CustomTypeSortingOptionsConsts)[number];

export const CustomTypeFiltersTranslator: PropertyTranslator<CustomTypeFilters> = {
  ...IGenericRequestParametersTranslator,
  ...IRelationParametersTranslator,
  ...IPaginationParametersTranslator,

  ...IRelationParametersTranslator,
  ...ISoftDeletableFilterParametersTranslator,
  ...ICreatedRecordParametersTranslator,
  ...IModifiedRecordParametersTranslator,
  customFieldIds: "Custom field ID(s)",
  entityTypes: "Entity Type(s)",
  isEnabled: "Is enabled",
  excludeDisabled: "Exclude disabled",
  extendSearchToInventoryItems: "Extended search (inventory items)",
  parentTypeIds: "Valid as parent for types",
  rootHierarchyIds: "Root hierarchy IDs",
  isInventory: "Is root",
  isHierarchyRoot: "Is hierarchical",
  excludeHierarchyChildren: "Exclude hierarchy children",
  inventoryNames: "Inventory name(s)",
  names: "Name(s)",
  excludeNonInventories: "Exclude non-inventories",
  hasRestrictedAddPermission: "Has restricted add permission",
  hasRestrictedEditPermission: "Has restricted edit permission",
  hasRestrictedReadPermission: "Has restricted read permission",
} as const;

export const CustomTypeDataTypeUtils = {
  CanDefineHierarchy: (entityType: CustomTypeEntityType) => {
    switch (entityType) {
      case "Inventory":
        return true;
      default:
        return false;
    }
  },
};
