// ,
// [cellState, setCellState, addToColumnWidth]
// );
import { ColumnCell } from "./ColumnCell";
import { HeaderCell } from "./HeaderCell";
import { CellIndex, CellRenderProps, indexToKey } from "./SpreadSheetTypes";
import { TopLeftCornerCell } from "./TopLeftCornerCell";

export const CellRenderer = ({
  columnIndex,
  index,
  rowIndex,
  style,
  cellState,
  fixedColumnCount,
  fixedRowCount,
  showColumnIndex,
  showRowIndex,
  showColumnLetterIndex,
  showRowLetterIndex,
  setCellState,
  addToColumnWidth,
  addToRowHeight,
  setLinePos,
  getColumnWidth,
  getRowHeight,
  ColumnIndexElement,
  ColumnNameElement,
  TopLeftCornerElement,
  RowIndexElement,
  RowNameElement,
  CellValues,
}: CellRenderProps) => {
  if (columnIndex === 0 && rowIndex === 0 && showColumnIndex && showRowIndex) {
    return (
      <TopLeftCornerCell
        key={index}
        index={index}
        type={"top"}
        columnIndex={columnIndex}
        rowIndex={rowIndex}
        style={{ ...style, width: getColumnWidth(0, fixedColumnCount), height: getRowHeight(0, fixedRowCount) }}
        cellState={cellState}
        setCellState={setCellState}
        TopLeftCornerElement={TopLeftCornerElement}
      />
    );
  } else if (columnIndex < fixedColumnCount) {
    if (rowIndex < fixedRowCount) return null;
    rowIndex -= fixedRowCount;
    index = indexToKey(rowIndex, columnIndex);
    return (
      <ColumnCell
        key={index}
        index={index}
        type={"top"}
        showRowIndex={showRowIndex}
        fixedColumnCount={fixedColumnCount}
        columnIndex={columnIndex}
        rowIndex={rowIndex}
        showRowLetterIndex={showRowLetterIndex}
        style={style}
        cellState={cellState}
        addToRowHeight={(index: number, height: number) => addToRowHeight(index + fixedRowCount, height)}
        setLinePos={setLinePos}
        // setCellState={setCellState}
        RowIndexElement={RowIndexElement}
        RowNameElement={RowNameElement}
        CellValues={(index: CellIndex) =>
          CellValues({ ...index, rowIndex: index.rowIndex + fixedRowCount - (showColumnIndex ? 1 : 0) })
        }
      />
    );
  } else if (rowIndex < fixedRowCount) {
    if (columnIndex < fixedColumnCount) return null;
    columnIndex -= fixedColumnCount;
    index = indexToKey(columnIndex, columnIndex);

    return (
      <HeaderCell
        key={index}
        index={index}
        type={"top"}
        showColumnIndex={showColumnIndex}
        showColumnLetterIndex={showColumnLetterIndex}
        columnIndex={columnIndex}
        rowIndex={rowIndex}
        style={style}
        cellState={cellState}
        CellValues={(index: CellIndex) =>
          CellValues({ ...index, columnIndex: index.columnIndex + fixedColumnCount - (showRowIndex ? 1 : 0) })
        }
        addToColumnWidth={(index: number, width: number) => addToColumnWidth(index + fixedColumnCount, width)}
        setLinePos={setLinePos}
        ColumnIndexElement={ColumnIndexElement}
        ColumnNameElement={ColumnNameElement}
        // setCellState={setCellState}
      />
    );
  } else {
    return null; // Main cell is rendered by cellRangeRenderer
  }
};
