import { Button, ButtonProps } from "../Button/Button";
import { Link } from "react-router-dom";

interface LinkButtonProps extends ButtonProps {
  linkTo: string;
}

export const LinkButton = ({ linkTo, ...rest }: LinkButtonProps) => {
  if (rest.disabled) return <Button {...rest} />;
  return (
    <Link to={linkTo} target={rest.target} style={{ textDecoration: "none", ...rest.style }}>
      <Button {...rest} />
    </Link>
  );
};
