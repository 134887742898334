import {
  DefaultCreatedByRecordOrderType,
  DefaultCreatedOnRecordOrderType,
  DefaultCustomTypeOrderType,
  DefaultModifiedByRecordOrderType,
  DefaultModifiedOnRecordOrderType,
  DefaultOrderType,
  DefaultOwnerOrderType,
  DefaultVersionedOrderType,
  ICreatedRecordParameters,
  ITypedEntityParameters,
  IGenericRequestParameters,
  IModifiedRecordParameters,
  IOwnedEntityParameters,
  IPaginationParameters,
  IRelationParameters,
  ISoftDeletableFilterParameters,
  IVersionedEntityParameters,
  IdTypes,
  RequireKeys,
  StringIndexedDict,
  ICustomFieldValuesSearchParameters,
  IHierarchyParameters,
  CustomFieldValuesSearchParametersBase,
  INamedEntityParameters,
  IProjectBasedParameters,
  IListRequestParameters,
  IModificationRecord,
  ICreationRecord,
  ICustomTypedEntity,
  IPermissionedEntity,
  INamedEntity,
  IUniqueEntity,
  IRelatedEntity,
  ISoftDeletable,
  IHierarchyRead,
  IConcurrencyToken,
} from "./GenericTypes";

export type PropertyTranslatorArr<T extends string> = { [K in T]: string };
export type PropertyTranslator<T extends StringIndexedDict> = { [key in keyof RequireKeys<T, keyof T>]: string }; // Make all properties (keys) required with a string value
export type FiltersTranslator<Filters extends IListRequestParameters<Filters["orderBy"]>> = PropertyTranslator<Filters>;

// Order By translators
export const DefaultOrderTypeTranslator: PropertyTranslatorArr<DefaultOrderType> = {
  ID_ASC: "ID ascending",
  ID_DESC: "ID descending",
  NAME_ASC: "Name ascending",
  NAME_DESC: "Name descending",
} as const;

export const DefaultOwnerOrderTypeTranslator: PropertyTranslatorArr<DefaultOwnerOrderType> = {
  OWNER_ASC: "Owner ascending",
  OWNER_DESC: "Owner descending",
} as const;

export const DefaultCustomSchemaOrderTypeTranslator: PropertyTranslatorArr<DefaultCustomTypeOrderType> = {
  TYPE_ASC: "Type ascending",
  TYPE_DESC: "Type descending",
} as const;

export const DefaultCreatedOnRecordOrderTypeTranslator: PropertyTranslatorArr<DefaultCreatedOnRecordOrderType> = {
  CREATED_ON_ASC: "Created on ascending",
  CREATED_ON_DESC: "Created on descending",
} as const;

export const DefaultCreatedByRecordOrderTypeTranslator: PropertyTranslatorArr<DefaultCreatedByRecordOrderType> = {
  CREATED_BY_ASC: "Created by ascending",
  CREATED_BY_DESC: "Created by descending",
} as const;

export const DefaultModifiedOnRecordOrderTypeTranslator: PropertyTranslatorArr<DefaultModifiedOnRecordOrderType> = {
  MODIFIED_ON_ASC: "Modified on ascending",
  MODIFIED_ON_DESC: "Modified on descending",
} as const;

export const DefaultModifiedByRecordOrderTypeTranslator: PropertyTranslatorArr<DefaultModifiedByRecordOrderType> = {
  MODIFIED_BY_ASC: "Modified by ascending",
  MODIFIED_BY_DESC: "Modified by descending",
} as const;

export const DefaultVersionedOrderTypeTranslator: PropertyTranslatorArr<DefaultVersionedOrderType> = {
  VERSION_ASC: "Version ascending",
  VERSION_DESC: "Version descending",
} as const;

// Filter translators
export const INamedEntityParametersTranslator: PropertyTranslator<INamedEntityParameters> = {
  names: "Names",
} as const;

export const IPaginationParametersTranslator: PropertyTranslator<IPaginationParameters> = {
  page: "Page",
  pageSize: "Page size",
} as const;

export const IOwnedEntityParametersTranslator: PropertyTranslator<IOwnedEntityParameters> = {
  ownerIds: "Owner IDs",
} as const;

export const IProjectBasedParametersTranslator: PropertyTranslator<IProjectBasedParameters> = {
  projectIds: "Project IDs",
} as const;

export const IGenericRequestParametersTranslator: PropertyTranslator<IGenericRequestParameters<{ id: IdTypes }>> = {
  orderBy: "Order by",
  excludeIds: "Exclude IDs",
  ids: "IDs",
  includeCount: "Include entity count",
  name: "Name",
  searchTerm: "Search term",
  searchTermIncludeIds: "IDs",
  searchTermIncludeNames: "names",
  searchTermIncludeUuids: "UUIDs",
} as const;

export const IRelationParametersTranslator: PropertyTranslator<IRelationParameters> = {
  includeRelationCount: "Include relation count",
  includeRelationLink: "Include relation link",
  includeRelations: "Include relations",
} as const;

export const ICreatedRecordParametersTranslator: PropertyTranslator<ICreatedRecordParameters> = {
  createdByIds: "Created by IDs",
  createdFrom: "Created from",
  createdTo: "Created to",
} as const;

export const IModifiedRecordParametersTranslator: PropertyTranslator<IModifiedRecordParameters> = {
  modifiedByIds: "Modified by IDs",
  modifiedFrom: "Modified from",
  modifiedTo: "Modified to",
} as const;

export const IVersionedEntityParametersTranslator: PropertyTranslator<IVersionedEntityParameters> = {
  original_ids: "Original IDs",
  verions: "Versions",
} as const;

export const ISoftDeletableFilterParametersTranslator: PropertyTranslator<ISoftDeletableFilterParameters> = {
  includeSoftDeleted: "Include trashed",
  isSoftDeleted: "Is trashed",
} as const;

export const ITypedEntityParametersTranslator: PropertyTranslator<ITypedEntityParameters> = {
  customTypeIds: "Custom type ID(s)",
  customFieldIds: "Custom field ID(s)",
} as const;

export const ITypedEntityCustomValuesSearchParametersTranslator: PropertyTranslator<
  ICustomFieldValuesSearchParameters<CustomFieldValuesSearchParametersBase> & ITypedEntityParameters
> = {
  ...ITypedEntityParametersTranslator,
  customFieldValues: "Custom field values",
} as const;

export const IHierarchyParametersTranslator: PropertyTranslator<IHierarchyParameters> = {
  childrenOfParentIds: "Children of parent ID(s)",
  descendantsOfIds: "Descendants of ID(s)",
  excludeHierarchyChildren: "Exclude hierarchy children",
  isHierarchyRoot: "Is part of hierarchy",
  inventoryIds: "Inventory IDs",
} as const;

// Field label translators
export const INamedEntityLabels: PropertyTranslator<INamedEntity> = {
  name: "Name",
} as const;

export const IUniqueEntityLabels: PropertyTranslator<IUniqueEntity> = {
  origin: "Origin",
  uid: "UUID",
} as const;

export const IPermissionedEntityLabels: PropertyTranslator<IPermissionedEntity> = {
  permissions: "Permissions",
} as const;

export const ISoftDeletableLabels: PropertyTranslator<ISoftDeletable> = {
  isDeleted: "Is trashed",
} as const;

export const IRelatedEntityLabels: PropertyTranslator<IRelatedEntity<any>> = {
  relations: "Relations",
} as const;

export const ICustomTypedEntityLabels: PropertyTranslator<ICustomTypedEntity> = {
  customType: "Custom type",
  customFields: "Custom fields",
  customValues: "Custom field values",
} as const;

export const ICreationRecordLabels: PropertyTranslator<ICreationRecord> = {
  createdBy: "Created by",
  createdOn: "Created on",
} as const;

export const IModificationRecordLabels: PropertyTranslator<IModificationRecord> = {
  modifiedBy: "Modified by",
  modifiedOn: "Modified on",
} as const;

export const IHierarchyReadLabels: PropertyTranslator<IHierarchyRead> = {
  ancestors: "Path",
  parent: "Parent",
} as const;

export const IConcurrencyTokenLabels: PropertyTranslator<IConcurrencyToken<string>> = {
  concurrencyToken: "Concurrency token",
};
