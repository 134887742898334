import { LucideIcon } from "../../common/icon/LucideIcon";
import { ImageTransform } from "../ImageComponent";
import { Dispatch, SetStateAction } from "react";
import { ViewerButtonLine } from "../../SpectrumViewer/ViewerNavigation/Menu/ViewerButtonLine";
import { ToggleButtonComponent } from "../../ViewerUIElements/ToggleButtonComponent";
import { ParserLogsButton } from "../../ViewerLayout/ParserLogsIcon";
import { viewerLogType } from "../../ViewerLayout/ViewerLayoutTypes";

export const ImageViewerToolbar = ({
  resetZoom,
  transform,
  showAll,
  setShowAll,
  parserLogs,
  hideGallery,
}: {
  resetZoom: (reset?: ImageTransform) => void;
  transform: ImageTransform;
  showAll: boolean;
  setShowAll: Dispatch<SetStateAction<boolean>>;
  parserLogs: viewerLogType[];
  hideGallery?: boolean;
}) => {
  return (
    <>
      {hideGallery ? (
        <>
          {parserLogs.length > 0 ? (
            <ViewerButtonLine>
              <div className={"flex row-nowrap align-center gap-5"} style={{ marginLeft: "auto" }}>
                <div>
                  <ParserLogsButton logs={parserLogs} />
                </div>
              </div>
            </ViewerButtonLine>
          ) : null}
        </>
      ) : (
        <ViewerButtonLine>
          <ToggleButtonComponent checked={showAll} setChecked={setShowAll} style={{ cursor: "pointer" }}>
            <div className="flex row-nowrap align-center gap-5">
              <LucideIcon name={showAll ? "image" : "layout-grid"} />
              <span>{showAll ? "Gallery" : "Single"}</span>
            </div>
          </ToggleButtonComponent>
          {!showAll ? (
            <div className={"flex row-nowrap align-center gap-5"} style={{ marginLeft: "auto" }}>
              <button
                className="btn btn-xs btn-ghost-secondary"
                onClick={() => {
                  resetZoom({ ...transform, k: transform.k * 1.05 });
                }}
              >
                <LucideIcon name="zoom-in" /> Zoom in
              </button>
              <button
                className="btn btn-xs btn-ghost-secondary"
                onClick={() => {
                  resetZoom({ ...transform, k: transform.k * 0.95 });
                }}
              >
                <LucideIcon name="zoom-out" /> Zoom out
              </button>
              <button
                className="btn btn-xs btn-ghost-secondary"
                onClick={() => {
                  resetZoom({ x: 0, y: 0, k: 1 });
                }}
              >
                <LucideIcon name="maximize" style={{ strokeWidth: 3 }} /> Reset
              </button>
              {parserLogs.length > 0 ? (
                <div>
                  <ParserLogsButton logs={parserLogs} />
                </div>
              ) : null}
            </div>
          ) : null}
        </ViewerButtonLine>
      )}
    </>
  );
};
