import React, { CSSProperties } from "react";
import { Control, Controller, DeepMap, FieldError } from "react-hook-form";
import { ToggleSwitch, ToggleSwitchProps } from "../buttons/ToggleSwitch/ToggleSwitch";
import { FormFieldError, FormFieldLayout } from "./FormFieldLayouts";

interface ToggleFieldProps extends ToggleFormFieldProps {
  value: boolean;
  onChange: (newValue: boolean) => void;
  children?: React.ReactNode;
}
const ToggleField = (props: ToggleFieldProps) => {
  const fieldPath = props.id.split(".");
  let error: any = undefined;
  for (const path of fieldPath) {
    if (!error) error = props.errors?.[path];
    else error = error?.[path];
  }

  if (props.uncontained) {
    return (
      <div
        className={`flex align-center justify-center`}
        style={{ width: "100%", height: "100%", ...props.wrapperContainerstyle }}
      >
        <ToggleSwitch
          checked={props.value}
          onToggle={() => props.onChange(!props.value)}
          toggleStyle={{ borderColor: error ? "var(--danger)" : "transparent" }}
          {...props}
        />
        {props.children ? (
          <div className="flex row-nowrap align-center gap-5" style={{ marginLeft: "auto" }}>
            {props.children}
          </div>
        ) : null}
        <FormFieldError id={props.id} errors={props.errors} />
      </div>
    );
  } else {
    return (
      <FormFieldLayout
        id={props.id}
        label={props.label}
        required={props.required}
        horizontal={props.horizontal}
        hasLabel={props.label !== ""}
      >
        <div
          className={`flex row-nowrap align-center gap-5 `}
          style={{ justifyContent: "flex-end", ...props.wrapperContainerstyle }}
        >
          <ToggleSwitch
            checked={props.value}
            onToggle={() => props.onChange(!props.value)}
            toggleStyle={{ borderColor: error ? "var(--danger)" : "transparent", marginTop: 8 }}
            {...props}
          />
          {props.children ? (
            <div className="flex row-nowrap align-center gap-5" style={{ marginLeft: "auto" }}>
              {props.children}
            </div>
          ) : null}
        </div>
        <FormFieldError id={props.id} errors={props.errors} />
      </FormFieldLayout>
    );
  }
};

export interface ToggleFormFieldProps extends ToggleSwitchProps {
  id: string;
  control: Control<any>;
  label: string;
  errors?: DeepMap<any, FieldError>;
  required?: boolean;
  horizontal?: boolean;
  uncontained?: boolean;
  toggleControllerContainerStyle?: CSSProperties;
  wrapperContainerstyle?: CSSProperties;
  children?: React.ReactNode;
}
export const ToggleFormField = (props: ToggleFormFieldProps) => {
  return (
    <>
      <Controller
        control={props.control}
        name={props.id}
        render={({ field: { onChange, value } }) => (
          <div style={{ whiteSpace: "nowrap", ...props.toggleControllerContainerStyle }}>
            <ToggleField {...props} value={value} onChange={onChange} children={props.children} />
          </div>
        )}
      />
    </>
  );
};
