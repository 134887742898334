import { StringIndexedDict } from "../../../../api/GenericTypes";
import {
  GenericEntityVirtualizedEntitySelectFormFieldProps,
  GenericVirtualizedEntitySelectFormFieldControllerWrapper,
} from "../../../formfields/GenericVirtualizedSelectFormField/GenericVirtualizedEntitySelectFormFieldControllerWrapper";
import { useContext } from "react";
import { SessionContext } from "../../../contexts/SessionContext";
import {
  DataSourceFilters,
  DataSourceSortingOptionsConsts,
  DataSourceSuggestions,
  dataSourceConstants,
} from "../../../../api/DataSource";

export const DataSourcesVirtualizedSelectForm = <Entity extends StringIndexedDict>(
  props: GenericEntityVirtualizedEntitySelectFormFieldProps<Entity, DataSourceSuggestions, DataSourceFilters>
) => {
  const { session } = useContext(SessionContext);

  return (
    <GenericVirtualizedEntitySelectFormFieldControllerWrapper<Entity, DataSourceSuggestions, DataSourceFilters>
      {...props}
      filters={{ orderBy: "NAME_ASC", ...props.filters }}
      //   onRowRenderer={(item) => <></>}
      //   createModal={({ showModal, setShowModal, onCreate }) => (
      //     <QuickAddWrapper showModal={showModal} setShowModal={setShowModal} onCreate={onCreate}>
      //       {(quickAddProps) => <QuickAddMethodForm {...quickAddProps} />}
      //     </QuickAddWrapper>
      //   )}
      entityConstants={dataSourceConstants}
      orderByOptions={DataSourceSortingOptionsConsts}
      allowCreateEntity={session?.permissions.administer_autoload && props.allowCreateEntity}
    />
  );
};
