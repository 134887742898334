import {
  ApplySchemaAttributes,
  CommandFunction,
  ExtensionTag,
  GetAttributes,
  NodeExtension,
  NodeExtensionSpec,
  NodeSpecOverride,
  command,
  extension,
  findSelectedNodeOfType,
  getTextSelection,
  isElementDomNode,
  isSelectionEmpty,
  nodeInputRule,
  // MarkExtension,
  // MarkSpecOverride,
  // MarkExtensionSpec,
} from "@remirror/core";
import { CreateEventHandlers } from "@remirror/extension-events";
import { InputRule } from "@remirror/pm/dist-types/inputrules";
import styles from "./ContentPlaceholderExtension.module.css";
import {
  ContentPlaceholderAttributes,
  ContentPlaceholderOptions,
  toggleContentPlaceholderOptions,
} from "./contentPlaceholder-types";

@extension<ContentPlaceholderOptions>({
  defaultOptions: {
    onClick: () => {},
    onClickLegacy: () => {},
  },
})
export class ContentPlaceholderNodeExtension extends NodeExtension<ContentPlaceholderOptions> {
  get name() {
    return "contentPlaceholderNode" as const;
  }

  createTags() {
    return [ExtensionTag.InlineNode, ExtensionTag.FormattingNode, ExtensionTag.Behavior];
  }

  createNodeSpec(extra: ApplySchemaAttributes, override: NodeSpecOverride): NodeExtensionSpec {
    const dataAttributeId = "content-placeholder-node-id";
    return {
      selectable: true,
      draggable: true,
      ...override,
      inline: true,
      atom: true,
      attrs: { ...extra.defaults(), id: { default: null }, label: { default: null } },
      parseDOM: [
        {
          tag: `span[${dataAttributeId}]`,
          getAttrs: (node) => {
            if (!isElementDomNode(node)) {
              return null;
            }

            const id = node.getAttribute("id");
            const label = node.textContent;
            if (!label) return null;
            return { ...extra.parse(node), id, label };
          },
        },
        ...(override.parseDOM ?? []),
      ],

      toDOM: (node) => {
        const { id, label } = node.attrs as ContentPlaceholderAttributes;
        return [
          "span",
          {
            id,
            [dataAttributeId]: 1,
            class: styles.contentPlaceholder,
          },
          label,
        ];
      },
    };
  }

  createInputRules(): InputRule[] {
    const getAttributes: GetAttributes = ([, match]) => {
      if (!match) {
        return;
      }
      return { label: match };
    };

    return [
      nodeInputRule({
        regexp: /(?:\[\[|__)([^*_]+)(?:\]\]|__)$/,
        type: this.type,
        ignoreWhitespace: true,
        getAttributes,
        beforeDispatch: ({ tr }) => {
          tr.insertText(" ");
        },
      }),
    ];
  }

  @command(toggleContentPlaceholderOptions)
  toggleContentPlaceholderNode(): CommandFunction {
    return (props) => {
      const { tr, dispatch } = props;

      const textContent = tr.doc.cut(tr.selection.from, tr.selection.to).textContent;
      if (!textContent || isSelectionEmpty(tr)) return false;

      tr.selection.replaceWith(tr, this.type.create({ label: textContent }));

      dispatch?.(tr);

      return true;
    };
  }

  @command()
  removeContentPlaceholderNode(): CommandFunction {
    return ({ tr, dispatch }) => {
      const selection = getTextSelection(tr.selection, tr.doc);

      const contentPlaceholderNode = findSelectedNodeOfType({ selection, types: [this.type] });

      const textContent = contentPlaceholderNode?.node.attrs.label;

      if (!textContent) return false;

      tr.deleteRange(selection.from, selection.to);
      tr.insertText(textContent + " ", selection.from);

      dispatch?.(tr);

      return true;
    };
  }

  createEventHandlers(): CreateEventHandlers {
    return {
      click: (event, clickState) => {
        // Check if this is a direct click which must be the case for atom
        // nodes.
        if (!clickState.direct) {
          return;
        }

        const nodeWithPosition = clickState.nodeWithPosition;

        if (!nodeWithPosition) {
          return;
        }

        return this.options.onClick(event, nodeWithPosition);
      },
    };
  }
}

declare global {
  namespace Remirror {
    interface AllExtensions {
      contentPlaceholderNode: ContentPlaceholderNodeExtension;
    }
  }
}
