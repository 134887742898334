import produce from "immer";
import { useCallback, useEffect, useState } from "react";

import { LucideIcon } from "../../common/icon/LucideIcon";
import { pipelineSetting, SerializedPipeline } from "../../ProcessingPipeline/PipelineTypes";
import { ParserLogsButton } from "../../ViewerLayout/ParserLogsIcon";
import { useTranslatedFieldUpdate } from "../../ViewerLayout/ViewerLayoutHooks";
import {
  Datatrack,
  pipelineNodeState,
  Track,
  trackModes,
  trackSettingsList,
  trackTypes,
  viewerLogType,
  viewPortType,
} from "../../ViewerLayout/ViewerLayoutTypes";
import { clickType, offsetType, viewerModeType, zoomModes } from "../../ViewerLayout/ViewerTypes";
import { CheckBoxSelectorDiv, selectState as _selectState } from "../../ViewerUIElements/CheckBoxSelector";
import { IconBox } from "../../ViewerUIElements/IconBox";
import { ToggleButtonComponent } from "../../ViewerUIElements/ToggleButtonComponent";
import { Tools, ToolsType } from "../../ViewerUIElements/Tools";
import { ToolWindow } from "../../ViewerUIElements/ToolWindow";
import { ColorTool } from "./ColorTool";
import { ContourTool } from "./ContourTool";
import { ExportModal } from "./ExportModal";
import { ViewerButtonLine } from "./Menu/ViewerButtonLine";
import { ProcessingTool } from "./Processing/ProcessingTool";
import { SliderTool } from "./SliderTool";
import { StackingTool } from "./StackingTool";
import styles from "./ViewerNavigation.module.css";

// import { ViewerTrackNavigation } from "./ViewerTrackNavigation";
export type selectState = _selectState;

// export enum selectState {
//   none = 2,
//   false = 0,
//   true = 1,
//   both = -1,
// }

// formatIDType = enum {
//   svg ="svg"
// }

type toolType = "slider" | "stacking" | "color" | "contour" | "processing" | "none";

export type TrackToolType = {
  // TODO: remove selected tracks?
  selectedTracks: string[];
  onClose?: () => void;
  tracks: Record<string, Track>;
  datatracks: Record<string, Datatrack>;
  setOffset: (offset: offsetType) => void;
  getTranslateScale: () => offsetType;
  setViewerMode: (viewerMode: viewerModeType) => void;
  viewerMode: viewerModeType;
  toolList: toolType[];
  changeTrackSettings: (list: trackSettingsList) => void;
  // changeTrackParameter: (list: { id: string; parameter: trackParameterType }[]) => void;
  changePipelineParameter: (settings: pipelineSetting[]) => void;
  setMinHeight?: (height: number) => void;
  setMinWidth?: (width: number) => void;
  pipelines: Record<string, SerializedPipeline>;
  pipelineStates: Record<string, pipelineNodeState>;
  graphClick: clickType;
  width?: number;
  height?: number;
};

type toolStateType = {
  active: boolean;
};

const getSelectedTracks = (tracks: Record<string, Track>) => {
  let selected = Object.values(tracks).filter((track) => track.settings.active && track.settings.selected);
  if (selected.length === 0) selected = Object.values(tracks).filter((track) => track.settings.active);
  return selected.map((track) => track.id);
};

type trackToolType = {
  type: toolType;
  trackType: trackTypes;
  multiTrack: boolean;
};

const trackToolList: trackToolType[] = [
  { type: "slider", trackType: "XY_real", multiTrack: true },
  { type: "stacking", trackType: "XY_real", multiTrack: true },
  // { type: "processing", trackType: "XY_real", multiTrack: false },
  { type: "color", trackType: "matrix_real", multiTrack: false },
  // { type: "contour", trackType: "matrix_real", multiTrack: false },
];

type ViewerNavigationProps = {
  resetZoom: any;
  // selectedTracks: string[];
  tracks: Record<string, Track>;
  datatracks: Record<string, Datatrack>;
  autoZoom: boolean;
  // offset: offsetType;
  setAutoZoom: (autoZoom: boolean) => void;
  // setSliderCurrent: (trackID: string | undefined) => void;
  // changeTrackParameter: (list: { id: string; parameter: trackParameterType }[]) => void;
  changeTrackSettings: (list: trackSettingsList) => void;
  changePipelineParameter: (settings: pipelineSetting[]) => void;
  viewerMode: viewerModeType;
  setViewerMode: (viewerMode: viewerModeType) => void;
  onExport: (type: string) => void;
  setOffset: (offset: offsetType) => void;
  getTranslateScale: () => offsetType;
  viewPort: viewPortType;
  pipelines: Record<string, SerializedPipeline>;
  pipelineStates: Record<string, pipelineNodeState>;
  graphClick: clickType;
  parserLogs: viewerLogType[];
};
export function ViewerNavigation({
  resetZoom,
  setViewerMode,
  viewerMode,
  tracks,
  datatracks,
  // selectedTracks,
  // setSliderCurrent,
  autoZoom,
  setAutoZoom,
  changeTrackSettings,
  changePipelineParameter,
  onExport,
  // offset,
  setOffset,
  getTranslateScale,
  viewPort,
  pipelines,
  pipelineStates,
  graphClick,
  parserLogs,
  ...otherProps
}: ViewerNavigationProps) {
  const [boxZoom, setBoxZoom] = useState<boolean>(false);
  // const [trackTools, setTrackTools] = useState<any>([]);
  // const [trackToolMode, setTrackToolMode] = useState<toolType>("none");
  // const [toolList, setToolList] = useState<toolType[]>([]);
  const [showExportModal, setShowExportModal] = useState<boolean>(false);
  const [focusedTool, setFocusedTool] = useState<toolType>("none");
  const [exportTypes] = useState<string[]>(["svg", "png", "jpeg", "csv"]);
  // const [trackType, setTrackType] = useState<Record<string, boolean>>({});

  const [toolState, setToolState] = useState<Record<toolType, toolStateType>>({
    color: { active: false },
    slider: { active: false },
    stacking: { active: false },
    contour: { active: false },
    processing: { active: false },
    none: { active: false },
  });

  const [settingState, setSettingState] = useState<ToolsType>({
    x: undefined,
    y: undefined,
    active: false,
  });

  const selectedTracks = useTranslatedFieldUpdate(tracks, getSelectedTracks, []);
  const activeTracks = useTranslatedFieldUpdate(
    tracks,
    (tracks) =>
      Object.values(tracks)
        .filter((track) => track.settings.active)
        .map((track) => track.id),
    []
  );
  const trackTypes = useTranslatedFieldUpdate<string[], string[]>(
    activeTracks,
    (activeTracks) => {
      return Array.from(new Set(activeTracks.map((id) => tracks[id].type)));
    },
    []
  );

  const trackTools = useTranslatedFieldUpdate<string[], Record<string, toolType[]>>(
    activeTracks,
    (activeTracks) => {
      const result: Record<string, toolType[]> = {};
      for (let tool of trackToolList) {
        if (tool.multiTrack && activeTracks.length < 2) continue;
        if (!(tool.trackType in result)) result[tool.trackType] = [];
        result[tool.trackType].push(tool.type);
      }
      return result;
    },
    {}
  );

  const trackToToolList = useCallback(
    (trackTypes: string[]) =>
      trackTypes
        .map((type) => trackTools[type])
        .flat()
        .filter((tool) => tool),
    [trackTools]
  );

  const toolList: toolType[] = useTranslatedFieldUpdate(trackTypes, trackToToolList, []);

  const settingHandler = useCallback((e: any) => {
    //   // console.log("Test", e.target.getBoundingClientRect(), e.clientX, e.clientY);
    setSettingState({ x: e.clientX, y: e.clientY, active: true });
  }, []);

  const onChange = useCallback(
    (type: string, settings: any) => {
      setViewerMode(
        produce(viewerMode, (next: any) => {
          next[type] = settings;
        })
      );
    },
    [setViewerMode, viewerMode]
  );

  const elementProps = [
    {
      title: "Axis mode:",
      multiselect: false,
      preselect: viewerMode.axisMode,
      items: [
        { key: "fixX", text: "Fix X-axis" },
        { key: "fixY", text: "Fix Y-axis" },
      ],
      onClick: (state: Record<string, selectState>) => onChange("axisMode", state),
      // onClick: (state: Record<string, boolean>) => (onClick ? onClick(ids, "annotation", state) : {}),
    },
    {
      title: "Crosshair:",
      multiselect: true,
      preselect: viewerMode.crosshairMode,
      items: [
        { key: "showXLine", text: "Show X-line" },
        { key: "showYLine", text: "Show Y-line" },
        { key: "showXValue", text: "Show X-value" },
        { key: "showYValue", text: "Show Y-value" },
      ],
      onClick: (state: Record<string, selectState>) => onChange("crosshairMode", state),
      // onClick: (state: Record<string, boolean>) => (onClick ? onClick(ids, "annotation", state) : {}),
    },
  ];

  const tool = (
    <Tools state={settingState} setState={setSettingState}>
      <span className={styles.settingsHeader}>Viewer settings</span>
      <CheckBoxSelectorDiv elements={elementProps} />
    </Tools>
  );

  useEffect(() => {
    setViewerMode(
      produce(viewerMode, (next) => {
        next.zoomMode = boxZoom ? zoomModes.boxZoom : zoomModes.default;
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [boxZoom]);

  const toolButtons: Record<
    toolType,
    { icon: (invert?: boolean) => any; text: string; width?: number; height?: number }
  > = {
    slider: {
      icon: (invert: boolean = false) => (
        <LucideIcon name="film" style={{ marginRight: 2, strokeWidth: invert ? undefined : 3 }} />
      ),
      text: "Track slider",
    },
    stacking: {
      icon: (invert: boolean = false) => (
        <LucideIcon name="layers" style={{ marginRight: 2, strokeWidth: invert ? undefined : 3 }} />
      ),
      text: "Curve stacking",
    },
    processing: {
      icon: (invert: boolean = false) => <IconBox type="graph" invert={invert} />,
      text: "Processing",
    },
    color: {
      icon: (invert: boolean = false) => (
        <LucideIcon name="droplet" style={{ marginRight: 2, strokeWidth: invert ? undefined : 3 }} />
      ),
      text: "Color edit",
    },
    contour: {
      icon: (invert: boolean = false) => <IconBox type="contours" invert={invert} />,
      text: "Contour edit",
    },
    none: {
      icon: (invert: boolean = false) => undefined,
      text: "",
    },
  };

  const closeTool = useCallback(() => {
    // setTrackToolMode("none");
    // setTrackToolMode("contour");
    setViewerMode(
      produce(viewerMode, (next) => {
        next.stacked = false;
        next.trackMode = trackModes.multiVisible;
        // next.trackMode = trackModes.multiSelect;
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewerMode]);

  const getTool = useCallback((id: toolType): ((props: TrackToolType) => any) => {
    switch (id) {
      case "slider":
        return (props: TrackToolType) => <SliderTool {...props} />;

      case "stacking":
        return (props: TrackToolType) => <StackingTool {...props} />;

      case "color":
        return (props: TrackToolType) => <ColorTool {...props} />;

      case "contour":
        return (props: TrackToolType) => <ContourTool {...props} />;

      case "processing":
        return (props: TrackToolType) => <ProcessingTool {...props} />;

      case "none":
        return () => {};
    }
  }, []);

  // useEffect(() => {
  // // console.log("selectedTracks", selectedTracks);
  // const selectedTracks: string[] = [];
  // const newTrackType = Object.fromEntries(selectedTracks.map((id) => [tracks[id].type, true]));
  // const trackTools: Record<string, toolType[]> = {
  //   "1D_real": ["slider", "stacking", "processing"],
  //   "2D_real_matrix": ["color", "contour"],
  // };
  // const multiTrack = { slider: true, stacking: true };
  // if (selectedTracks.length < 2) {
  //   trackTools["1D_real"] = trackTools["1D_real"].filter((t) => !(t in multiTrack));
  // }
  // if (Object.keys(pipelines).length < 1)
  //   trackTools["1D_real"] = trackTools["1D_real"].filter((t) => t !== "processing");
  // if (needUpdate(trackType, newTrackType)) {
  //   if (autoZoom) resetZoom();
  //   setTrackType(newTrackType);
  // }
  // const newToolList: toolType[] = [];
  // Object.keys(newTrackType).forEach((type) => {
  //   if (type in trackTools) newToolList.push(...trackTools[type]);
  // });
  // if (newToolList.length !== toolList.length || newToolList.some((tool, i) => tool !== toolList[i])) {
  //   // console.log(">>", toolList, "->", newToolList);
  //   closeTool();
  //   setToolList(newToolList);
  // }
  // }, [autoZoom, resetZoom, pipelines]);

  // useEffect(() => {
  //   const p = (Object.values(pipelines)?.[0].pipeline?.[0] as any)?.parameter;
  //   if (p) console.log("vpipelines", Object.keys(p));
  // }, [pipelines]);

  // useEffect(() => {
  //   console.log("> ViewerNaviagtion changePipelineParameter")
  // }, [changePipelineParameter])

  const toolProps: TrackToolType = {
    setViewerMode: setViewerMode,
    viewerMode: viewerMode,
    tracks: tracks,
    datatracks: datatracks,
    selectedTracks: selectedTracks,
    // changeTrackParameter: changeTrackParameter,
    // changeTrackParameter: (list) => {
    //   console.log("changeTrackParameter", list);
    // },
    changeTrackSettings: changeTrackSettings,
    changePipelineParameter: changePipelineParameter,
    setOffset: setOffset,
    getTranslateScale: getTranslateScale,
    onClose: closeTool,
    toolList: toolList,
    pipelines: pipelines,
    pipelineStates: pipelineStates,
    graphClick: graphClick,
  };

  // console.log("toolState", toolState);

  return (
    <div {...otherProps} className={styles.noselect}>
      {settingState.active ? tool : undefined}
      <ExportModal show={showExportModal} setShow={setShowExportModal} onExport={onExport} formats={exportTypes} />
      {toolList.map((tool, i) => {
        if (!toolState[tool].active) return null;
        return (
          <ToolWindow
            key={tool}
            defaultPosition={{ x: viewPort.width * 0.15 + i * 20, y: viewPort.height * 0.05 + i * 20 }}
            width={toolButtons[tool]?.width || 100}
            height={toolButtons[tool]?.height || 50}
            parentWidth={viewPort.width}
            parentHeight={viewPort.height}
            top={viewPort.top}
            left={viewPort.left}
            id={tool}
            focused={tool === focusedTool}
            onFocused={() => setFocusedTool(tool)}
            onClosed={() =>
              setToolState(
                produce(toolState, (next) => {
                  next[tool].active = false;
                })
              )
            }
            title={
              <div>
                {toolButtons[tool].icon(true)}
                <span style={{ marginLeft: "3px" }}>{toolButtons[tool].text}</span>
              </div>
            }
          >
            {getTool(tool)(toolProps)}
          </ToolWindow>
        );
      })}
      <ViewerButtonLine>
        <>
          {toolList.map((tool) => (
            <ToggleButtonComponent
              key={tool}
              checked={toolState[tool].active}
              setChecked={(v) => {
                if (v) setFocusedTool(tool);
                setToolState(
                  produce(toolState, (next) => {
                    next[tool].active = v;
                  })
                );
              }}
            >
              {toolButtons[tool].icon()}
              <span style={{ marginRight: "10px" }}>{toolButtons[tool].text}</span>
            </ToggleButtonComponent>
          ))}
          <div
            style={{
              display: "flex",
              flexFlow: "row wrap",
              justifyContent: "space-between",
              alignItems: "center",
              marginLeft: "auto",
              gap: "10px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexFlow: "row wrap",
                justifyContent: "space-between",
                alignItems: "center",
                marginLeft: "auto",
                gap: "10px",
                borderLeft: "1px solid var(--gray-300)",
                paddingLeft: "10px",
              }}
            >
              <div>
                <b>Zoom: </b>
              </div>
              {/* <div>
                <input type="checkbox" checked={boxZoom} onChange={() => setBoxZoom((prev) => !prev)} /> Select box
              </div> */}
              {/* <div>
                <input type="checkbox" checked={false} onChange={() => alert("Not implemented")} /> Auto zoom
              </div> */}
              <ToggleButtonComponent checked={boxZoom} setChecked={setBoxZoom}>
                <span>Select box</span>
              </ToggleButtonComponent>
              <ToggleButtonComponent checked={autoZoom} setChecked={setAutoZoom}>
                <span>Auto</span>
              </ToggleButtonComponent>

              <div>
                <button className="btn btn-xs btn-ghost-primary" onClick={resetZoom} style={{ fontSize: 14 }}>
                  <LucideIcon name="maximize" style={{ strokeWidth: 3 }} /> Reset
                </button>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexFlow: "row wrap",
                justifyContent: "space-between",
                alignItems: "center",
                marginLeft: "auto",
                gap: "10px",
                borderLeft: "1px solid var(--gray-300)",
                paddingLeft: "10px",
              }}
            >
              <div>
                <button
                  className="btn btn-sm btn-default"
                  title={"Export"}
                  onClick={useCallback(() => setShowExportModal(true), [])}
                >
                  <LucideIcon name="external-link" /> Export
                </button>
              </div>
              <div>
                <button
                  className="btn btn-sm btn-round btn-default"
                  title="Settings"
                  onClick={(e) => {
                    settingHandler(e);
                    e.stopPropagation();
                  }}
                >
                  <LucideIcon name="settings" />
                </button>
              </div>
              {parserLogs.length > 0 ? (
                <div>
                  <ParserLogsButton logs={parserLogs} />
                </div>
              ) : null}
            </div>
          </div>
        </>
        {/* <ViewerButtonRow style={{ flexGrow: 1 }}>
          <ViewerButtonColumn>
            Zoom:
            <Button bsSize="xs" style={{ marginBottom: "5px" }} onClick={resetZoom}>
              <FeatherIcon name="maximize" style={{ strokeWidth: 3 }} /> Reset
            </Button>
            <SelectButton active={boxZoom} setActive={setBoxZoom}>
              Select box
            </SelectButton>
          </ViewerButtonColumn>

          <ViewerButtonColumn>
            <span style={{ visibility: toolList.length < 1 ? "hidden" : "visible" }}>Tools:</span>
            <div className={styles.toolBox}>
              {toolList.map((tool) => (
                <ToggleButtonComponent
                  key={tool}
                  checked={toolState[tool].active}
                  setChecked={(v) => {
                    if (v) setFocusedTool(tool);
                    setToolState(
                      produce(toolState, (next) => {
                        next[tool].active = v;
                      })
                    );
                  }}
                >
                  {toolButtons[tool].icon()}
                  <span style={{ marginRight: "10px" }}>{toolButtons[tool].text}</span>
                </ToggleButtonComponent>
              ))}
            </div>
          </ViewerButtonColumn>
        </ViewerButtonRow>
        <ViewerButtonRow>
          <ParserLogsIcon logs={parserLogs} size="200%" />
        </ViewerButtonRow>
        <ViewerButtonRow>
          <ViewerButtonColumn>
            <Button
              bsSize="xs"
              // style={{ padding: 10, fontSize: 14 }}
              // style={{ padding: 5 }}
              style={{ marginBottom: "5px" }}
              onClick={useCallback(() => setShowExportModal(true), [])}
            >
              <FeatherIcon name="external-link" style={{ strokeWidth: 3 }} /> Export
            </Button>

            <Button
              bsSize="xs"
              // style={{ padding: 10, fontSize: 14 }}
              // style={{ padding: 5 }}
              style={{ marginBottom: "5px" }}
              onClick={(e) => {
                settingHandler(e);
                e.stopPropagation();
              }}
            >
              <FeatherIcon name="settings" style={{ strokeWidth: 3 }} /> Settings
            </Button>
          </ViewerButtonColumn>
        </ViewerButtonRow> */}
      </ViewerButtonLine>
    </div>
  );
}
