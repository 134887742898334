import React, { CSSProperties, useEffect, useState } from "react";
import { FormControl } from "react-bootstrap";
import { useInputDebounce } from "../InputDebounce";

import styles from "./DecoratedStringInput.module.css";

export const DecoratedStringInput = ({
  value,
  setValue,
  name,
}: {
  value: string;
  setValue?: (value: string) => void;
  name?: string;
  style?: CSSProperties;
}) => {
  const [input, setInput] = useState<string>(value);
  const debouncedInput = useInputDebounce<string>(input, 500);

  useEffect(() => {
    if (value !== input) setInput(value);
  }, [value, input]);

  useEffect(() => {
    if (setValue && value !== debouncedInput) setValue(debouncedInput);
  }, [debouncedInput, setValue, value]);

  return (
    <div className={styles.rangeSetter}>
      <div className={styles.settingText} style={{ position: "relative", top: -10 }}>
        {name ? <span>{name}:&nbsp;</span> : null}
      </div>
      <div className={styles.inputField} style={{ position: "relative", top: -10 }}>
        <FormControl
          type="text"
          value={input}
          onChange={(e) => setInput((e.target as HTMLInputElement).value)}
          onKeyDown={(e) => {
            if (e.key === "Enter") setInput((e.target as HTMLInputElement).value);
          }}
          onFocus={(e) => (e.target as any as HTMLInputElement).select()}
          style={{}}
          // style={Object.assign({ background: valid ? undefined : "salmon" })}
          className={styles.formControlXS}
          // bsSize={"sm"}
        />
      </div>
    </div>
  );
};
