import React from "react";
import { IGenericEntityPermissions } from "../../permissions/useEntityPermissions";
import { EntityConstants, IEntityPermissions } from "../../../api/GenericTypes";
import { LucideIcon } from "../../icon/LucideIcon";
import { EntityByEntityTypeId, GenericEntityConstantsEntities } from "../../../api/GenericConstants";

interface EntityTableDeleteButtonProps<EntityTypeId extends GenericEntityConstantsEntities> {
  entityConstants: EntityConstants;
  permissions: IGenericEntityPermissions<EntityTypeId>;
  selection: Set<EntityByEntityTypeId<EntityTypeId>["id"]>;
  selectionPermissions: IEntityPermissions | undefined;
  onClick: React.DOMAttributes<HTMLButtonElement>["onClick"];
}

/**
 * A button component for soft deleting entities.
 * @author @CorradoSurmanowicz
 * @template Entity - The type of the entity.
 * @param {EntityTableDeleteButtonProps<EntityTypeId>} props - The properties for the button component.
 * @param {EntityConstants} props.entityConstants - Constants related to the entity.
 * @param {Permissions} props.permissions - The permissions object for the user.
 * @param {Set<Entity>} props.selection - The set of selected entities.
 * @param {Permissions} props.selectionPermissions - The permissions object for the selected entities.
 * @param {() => void} props.onClick - The click handler for the button.
 * @returns {JSX.Element} The rendered button component.
 */
export const EntityTableDeleteButton = <EntityTypeId extends GenericEntityConstantsEntities>({
  entityConstants,
  permissions,
  selection,
  selectionPermissions,
  onClick,
}: EntityTableDeleteButtonProps<EntityTypeId>) => {
  return (
    <button
      className={`btn btn-sm btn-ghost-danger`}
      title={`${
        !permissions.canBulkDelete(selectionPermissions)
          ? `Insufficient permissions to delete selected ${entityConstants.entityPlural}`
          : selection.size === 0
          ? `Select ${entityConstants.entitySingular} to delete`
          : selection.size === 1
          ? `Delete the selected ${entityConstants.entitySingular}`
          : `Delete the selected ${entityConstants.entityPlural}`
      }`}
      disabled={selection.size === 0 || !permissions.canBulkDelete(selectionPermissions)}
      onClick={onClick}
    >
      <LucideIcon name={"trash-2"} /> Delete
    </button>
  );
};
