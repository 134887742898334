import {
  FacilityFiltersTranslator,
  FacilitySortingOptionsConsts,
  InstrumentFacility,
  InstrumentFacilityFilters,
  instrumentsConstants,
} from "../../../api/Facilities";

import { DateTimeRenderer } from "../../datetime/DateTimeFormatter";
import { GetPersons } from "../../misc/EntityRenders/EntityRenderer";

import { NotSet } from "../../misc/UIconstants";
import { EntitySidebarTile, EntitySidebarTileProps } from "./EntitySidebarTile/EntitySidebarTile";

export const InstrumentsTile = (props: EntitySidebarTileProps<InstrumentFacility, InstrumentFacilityFilters>) => (
  <EntitySidebarTile<InstrumentFacility, InstrumentFacilityFilters>
    {...props}
    entityConstants={instrumentsConstants}
    orderByOptions={FacilitySortingOptionsConsts}
    translatorConsts={FacilityFiltersTranslator}
    defaultFilters={{ orderBy: "NAME_DESC", ...props.defaultFilters }}
    rowLabel={(e) => <>{e.name}</>}
    rowDate={(e) => (
      <>{e.createdOn ? <DateTimeRenderer date={e.createdOn} includeElapsed={false} includeTime={false} /> : NotSet}</>
    )}
    rowInfo={(e) => <>{e.owner ? <GetPersons persons={e.owner} createLinks={false} /> : NotSet}</>}
  />
);
