import { useCallback, useRef } from "react";

export const useDebounceVoidFunction = <A extends any[]>(
  func: (...params: A) => void,
  timeout: number
): ((...params: A) => void) => {
  let timer: React.MutableRefObject<NodeJS.Timeout | undefined> = useRef();

  const debouncedFunction = useCallback(
    (...params: A) => {
      if (timer.current) {
        clearTimeout(timer.current);
      }
      timer.current = setTimeout(() => {
        func(...params);
      }, timeout);
    },
    [func, timeout]
  );

  return debouncedFunction;
};
