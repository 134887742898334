import { HubConnection, HubConnectionBuilder, HubConnectionState, LogLevel } from "@microsoft/signalr";
import { useState, useEffect, useContext, useMemo } from "react";
import { SessionContext } from "../contexts/SessionContext";
import { DataSourceStatusChangedEvent } from "../../api/DataSourceStatus";

export type ConnectionStatus = "idle" | "connecting" | "connected" | "reconnecting" | "disconnected";
interface LiveConnectionReturnValues {
  connectionStatus: ConnectionStatus;
}

export const useLiveConnection = ({
  url,
  callbacks,
  enabled = true,
}: {
  url: string;
  callbacks: ClientLiveConnectionCallbacks;
  enabled?: boolean;
}): LiveConnectionReturnValues => {
  const [connection, setConnection] = useState<null | HubConnection>(null);
  const [connectionStatus, setConnectionStatus] = useState<ConnectionStatus>("idle");

  useEffect(() => {
    if (enabled) {
      const connect = new HubConnectionBuilder()
        .withUrl(url)
        .configureLogging(LogLevel.Information)
        .withAutomaticReconnect()
        .build();
      setConnectionStatus("connecting");
      setConnection(connect);
    } else {
      setConnectionStatus("idle");
      setConnection(null);
    }
  }, [enabled, url]);

  const callbackFns = useMemo(() => callbacks, [callbacks]);

  useEffect(() => {
    if (connection) {
      connection.onreconnecting(() => setConnectionStatus("reconnecting"));
      connection.onreconnected(() => setConnectionStatus("connected"));
      connection.onclose(() => setConnectionStatus("disconnected"));

      if (connection.state === HubConnectionState.Disconnected) {
        connection
          .start()
          .then(() => setConnectionStatus("connected"))
          .catch((error) => {
            setConnectionStatus("disconnected");
            console.log("Hubconnection error", error);
          });
      }
    }
    return () => {
      if (connection) {
        // This is a hack using the internal property to reset the onclose event handler
        // We do want to remove this to prevent spurious error messages when it is triggered
        // on an already unmounted component
        connection["_closedCallbacks"] = [];

        connection.stop();
      }
    };
  }, [connection]);

  // When the callback functions change we need to remove previous registrations
  // and add the new ones. Though you really should provide stable callbacks for this
  // hook to avoid reregistering on every render
  useEffect(() => {
    if (connection) {
      Object.entries(callbackFns).forEach(([key, value]) => {
        //console.log("ON", key, value);
        connection.on(key, value);
      });
    }
    return () => {
      Object.entries(callbackFns).forEach(([key, _]) => {
        connection?.off(key);
      });
    };
  }, [connection, callbackFns]);

  return { connectionStatus };
};

export interface ClientLiveConnectionCallbacks {
  DataSourceStatusChanged?: (dataSourceId: number, data: DataSourceStatusChangedEvent) => void;
  DataSourceScheduled?: (dataSourceId: number, nextScheduledDate: string) => void;
  ClientListUpdated?: () => void;
  ClientConnectionChanged?: (bridgeId: number, isConnected: boolean) => void;
}
export const useLiveAutoloadConnection = ({
  callbacks,
  enabled = true,
}: {
  callbacks: ClientLiveConnectionCallbacks;
  enabled?: boolean;
}) => {
  const { api } = useContext(SessionContext);
  const url = process.env.NODE_ENV === "development" ? `http://localhost:5000/${api.group}/api/0.1/` : api.url;
  return useLiveConnection({ url: `${url}autoload/monitoring_hub`, callbacks, enabled });
};
