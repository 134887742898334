import { StringIndexedDict } from "../../../../api/GenericTypes";
import {
  GenericEntityVirtualizedEntitySelectFormFieldProps,
  GenericVirtualizedEntitySelectFormFieldControllerWrapper,
} from "../../../formfields/GenericVirtualizedSelectFormField/GenericVirtualizedEntitySelectFormFieldControllerWrapper";
import { QuickAddWrapper } from "../../QuickAdd/common/QuickAddWrapper";
import {
  EquipmentTag,
  EquipmentTagFilters,
  EquipmentTagSortingOptionsConsts,
  EquipmentTagSuggestions,
  equipmentTagsConstants,
} from "../../../../api/EquipmentTag";
import { QuickAddEquipmentTagForm } from "../../QuickAdd/forms/QuickAddEquipmentTagForm";
import { useContext } from "react";
import { SessionContext } from "../../../contexts/SessionContext";

export const EquipmentTagsVirtualizedSelectForm = <Entity extends StringIndexedDict>(
  props: GenericEntityVirtualizedEntitySelectFormFieldProps<Entity, EquipmentTagSuggestions, EquipmentTagFilters>
) => {
  const { session } = useContext(SessionContext);

  return (
    <GenericVirtualizedEntitySelectFormFieldControllerWrapper<Entity, EquipmentTagSuggestions, EquipmentTagFilters>
      {...props}
      filters={{ orderBy: "NAME_ASC", ...props.filters }}
      //   onRowRenderer={(item) => <></>}
      createModal={({ showModal, setShowModal, onCreate }) => (
        <QuickAddWrapper<EquipmentTag> showModal={showModal} setShowModal={setShowModal} onCreate={onCreate}>
          {(props) => <QuickAddEquipmentTagForm {...props} />}
        </QuickAddWrapper>
      )}
      entityConstants={equipmentTagsConstants}
      orderByOptions={EquipmentTagSortingOptionsConsts}
      allowCreateEntity={session?.permissions.administer_equipment_tags && props.allowCreateEntity}
    />
  );
};
