import React, { useContext } from "react";
import { getAddRoute, getCloneRoute, getDetailRoute, getEditRoute, getIndexRoute, RouteKey } from "../../Routing";
import { Redirect, Route, Switch } from "react-router-dom";
import { SessionContext } from "../../../common/contexts/SessionContext";

interface LegacyRouteRedirectProps {
  from: RouteKey;
  to: RouteKey;
  table?: boolean;
  detail?: boolean;
  create?: boolean;
  edit?: boolean;
  clone?: boolean;
  children?: React.ReactNode;
}

/**
 * Component that handles legacy route redirections.
 * @author @CorradoSurmanowicz
 * @param {Object} props - The properties object.
 * @param {string} props.from - The source route to redirect from.
 * @param {string} props.to - The destination route to redirect to.
 * @param {boolean} [props.table=true] - Whether to redirect the table route.
 * @param {boolean} [props.detail=true] - Whether to redirect the detail route.
 * @param {boolean} [props.create=true] - Whether to redirect the create route.
 * @param {boolean} [props.edit=true] - Whether to redirect the edit route.
 * @param {boolean} [props.clone=true] - Whether to redirect the clone route.
 * @param {React.ReactNode} [props.children] - Additional child components to render.
 * @returns {JSX.Element} The rendered component.
 */
export const LegacyRouteRedirect = ({
  from,
  to,
  table = true,
  detail = true,
  create = true,
  edit = true,
  clone = true,
  children,
}: LegacyRouteRedirectProps) => {
  const { route } = useContext(SessionContext);

  return (
    <Switch>
      {detail && (
        <Route
          path={route(getDetailRoute(from))}
          render={(props) => <Redirect to={`${route(getIndexRoute(to))}/${props.match.params.id}`} />}
        />
      )}
      {create && <Route path={route(getAddRoute(from))} render={(props) => <Redirect to={route(getAddRoute(to))} />} />}
      {edit && (
        <Route
          path={route(getEditRoute(from))}
          render={(props) => <Redirect to={`${route(getIndexRoute(to))}/${props.match.params.id}/edit`} />}
        />
      )}
      {clone && (
        <Route
          path={route(getCloneRoute(from))}
          render={(props) => <Redirect to={`${route(getIndexRoute(to))}/${props.match.params.id}/clone`} />}
        />
      )}
      {table && (
        <Route path={route(getIndexRoute(from))} render={(props) => <Redirect to={route(getIndexRoute(to))} />} exact />
      )}
      {children}
    </Switch>
  );
};
