import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { getTilesByDomainAsync } from "../SpectrumViewer/TrackDataManager";
import { TrackData, viewerPropsType } from "../ViewerLayout/ViewerLayoutTypes";
import styles from "./ImageViewer.module.css";
import * as d3 from "d3";
import { useResizeDetector } from "react-resize-detector";
import { useDebouncedValue } from "../common/helperfunctions/useDebouncedValue";
import { ImageOverview } from "./Sidebar/ImageOverview";
import { ImageViewerGallery } from "./Sidebar/ImageViewerGallery";
import { ImageViewerMulti } from "./ImageViewerMulti";
import { Alert } from "../common/overlays/Alert/Alert";
import { Skeleton } from "../common/loaders/Skeleton/Skeleton";
import { ImageViewerToolbar } from "./Sidebar/ImageViewerToolbar";

export interface ImageTransform {
  x: number;
  y: number;
  k: number;
}

export interface ImageSize {
  width: number;
  height: number;
}

export type ImageComponentState = { current: string };

export type ImageComponentProps = viewerPropsType & {
  hideGallery?: boolean;
};

export const ImageComponent = (props: ImageComponentProps) => {
  const { tracks, datatracks, api, changeTrackState, interactiveMode, showNavigation, parserLogs, hideGallery } = props;

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const { ref, width, height } = useResizeDetector();
  const debouncedWidth = useDebouncedValue(width || 0, 500);
  const debouncedHeight = useDebouncedValue(height || 0, 500);
  const canvasRef = useRef<HTMLCanvasElement>(null);

  const [imageData, setImageData] = useState<HTMLImageElement>();
  const [showAll, setShowAll] = useState<boolean>(false);

  const [transform, setTransform] = useState<ImageTransform>({ x: 0, y: 0, k: 1 });
  const debouncedTransform = useDebouncedValue(transform, 500);

  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const imageIds = useMemo(() => {
    return Object.values(tracks)
      .filter((t) => (t.type === "image" || t.type === "molecule_compound") && t.settings.visible)
      .map((t) => t.id);
  }, [tracks]);

  const currentImage = useMemo(() => {
    if (imageIds.length > currentIndex) return tracks[imageIds[currentIndex]];
  }, [currentIndex, imageIds, tracks]);

  const canvas = d3.select(canvasRef.current);
  const context = useMemo(() => {
    if (canvas) {
      const context = canvas.node()?.getContext("2d");
      return context;
    }
  }, [canvas]);

  const initialImageData: ImageSize = useMemo(() => {
    if (imageData) {
      return {
        width: imageData.width,
        height: imageData.height,
      };
    }
    return { width: 0, height: 0 };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageData?.id]);

  const initialTransform: ImageTransform = useMemo(() => {
    if (initialImageData.width && initialImageData.height && debouncedWidth && debouncedHeight) {
      const x = debouncedWidth / initialImageData.width;
      const y = debouncedHeight / initialImageData.height;
      const k = Math.min(x, y);
      return {
        x: Math.abs((initialImageData.width * k - debouncedWidth) / 2),
        y: Math.abs((initialImageData.height * k - debouncedHeight) / 2),
        k,
      };
    }
    return { x: 0, y: 0, k: 1 };
  }, [debouncedHeight, initialImageData, debouncedWidth]);

  const resetZoom = useCallback(
    (reset?: ImageTransform) => {
      if (canvas && context && interactiveMode) {
        const zoom = d3.zoom().on("zoom", () => {
          setTransform({ x: d3.event.transform.x, y: d3.event.transform.y, k: d3.event.transform.k });
        });
        zoom.scaleExtent([1, 16]);
        if (debouncedWidth && debouncedHeight) {
          zoom.translateExtent([
            [-debouncedWidth, -debouncedHeight],
            [2 * debouncedWidth - 2, 2 * debouncedHeight - 2],
          ]);
        }
        canvas.call(zoom as any);

        canvas.on("dblclick.zoom", () => {
          canvas.call(zoom.transform as any, d3.zoomIdentity);
          setTransform({ x: 0, y: 0, k: 1 });
        });

        if (reset) {
          const k = reset.k <= 1 ? 1 : reset.k >= 16 ? 16 : reset.k;
          canvas.call(zoom.transform as any, d3.zoomIdentity.translate(reset.x, reset.y).scale(k));
          setTransform({ x: reset.x, y: reset.y, k: k });
        }
      }
    },
    [canvas, context, debouncedHeight, interactiveMode, debouncedWidth]
  );

  useEffect(() => {
    resetZoom({ x: 0, y: 0, k: 1 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialTransform, currentIndex, showAll, context, interactiveMode]);

  useEffect(() => {
    if (canvas && context && imageData && initialTransform && initialImageData.width && initialImageData.height) {
      context.clearRect(0, 0, Number(canvas.attr("width")), Number(canvas.attr("height")));
      context.drawImage(
        imageData,
        transform.x + initialTransform.x * transform.k,
        transform.y + initialTransform.y * transform.k,
        initialImageData.width * transform.k * initialTransform.k,
        initialImageData.height * transform.k * initialTransform.k
      );
    } else {
      !!canvas && context?.clearRect(0, 0, Number(canvas.attr("width")), Number(canvas.attr("height")));
    }
  }, [canvas, context, imageData, transform, initialImageData, initialTransform]);

  useEffect(() => {
    if (!showAll) {
      if (currentImage) {
        const timeout = setTimeout(() => {
          const domain = {
            range: [
              [-Infinity, Infinity],
              [-Infinity, Infinity],
            ],
            points: [
              initialImageData.width * debouncedTransform.k * initialTransform.k,
              initialImageData.height * debouncedTransform.k * initialTransform.k,
            ],
          };

          const getImage = (images: TrackData[]) => {
            if (images.length === 1) {
              setIsLoading(true);
              const image = new Image();
              image.id = images[0].id;
              image.src = images[0].data.image[0].data;
              image.onload = () => {
                setImageData(image);
                setIsLoading(false);
              };
            }
          };

          getTilesByDomainAsync(api, [currentImage], datatracks, domain, getImage, changeTrackState ?? (() => null));
        }, 100);

        return () => {
          clearTimeout(timeout);
        };
      } else {
        setCurrentIndex(0);
        setImageData(undefined);
      }
    }
  }, [
    api,
    changeTrackState,
    currentImage,
    datatracks,
    initialImageData,
    debouncedTransform.k,
    initialTransform.k,
    showAll,
  ]);

  const handleArrowKeys = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === "ArrowUp") {
        e.preventDefault();
        setCurrentIndex((prevState) => (prevState > 0 ? prevState - 1 : imageIds.length - 1));
      }
      if (e.key === "ArrowLeft") {
        e.preventDefault();
        setCurrentIndex((prevState) => (prevState > 0 ? prevState - 1 : imageIds.length - 1));
      }
      if (e.key === "ArrowRight") {
        e.preventDefault();
        setCurrentIndex((prevState) => (prevState < imageIds.length - 1 ? prevState + 1 : 0));
      }
      if (e.key === "ArrowDown") {
        e.preventDefault();
        setCurrentIndex((prevState) => (prevState < imageIds.length - 1 ? prevState + 1 : 0));
      }
    },
    [imageIds.length]
  );

  useEffect(() => {
    if (interactiveMode) {
      document.addEventListener("keydown", handleArrowKeys);
      return () => {
        document.removeEventListener("keydown", handleArrowKeys);
      };
    }
  }, [handleArrowKeys, interactiveMode]);

  const { ref: containerRef, width: containerWidth } = useResizeDetector();
  const { ref: toolbarRef, height: toolbarHeight } = useResizeDetector();

  if (showAll)
    return (
      <div style={{ width: "100%", height: "100%" }}>
        <ImageViewerToolbar
          resetZoom={resetZoom}
          transform={transform}
          showAll={showAll}
          setShowAll={setShowAll}
          parserLogs={parserLogs}
        />
        <ImageViewerMulti
          viewerProps={props}
          setCurrentIndex={setCurrentIndex}
          imageIds={imageIds}
          setShowAll={setShowAll}
        />
      </div>
    );

  return (
    <div
      className={styles.ImageComponentContainer}
      ref={containerRef}
      style={{
        gridTemplateColumns:
          !hideGallery && showNavigation && imageData && !!containerWidth && containerWidth > 1000 ? "20% 80%" : "100%",
      }}
    >
      {!hideGallery && showNavigation && imageData && !!containerWidth && containerWidth > 1000 && (
        <div className={styles.ImageComponentSidebar}>
          <ImageOverview
            imageData={imageData}
            initialImageData={initialImageData}
            initialTransform={initialTransform}
            transform={transform}
            width={debouncedWidth}
            height={debouncedHeight}
          />
          <ImageViewerGallery
            viewerProps={props}
            setCurrentIndex={setCurrentIndex}
            selectedId={imageData.id}
            imageIds={imageIds}
          />
        </div>
      )}
      <div className={styles.ImageComponentMain}>
        {showNavigation && (
          <div ref={toolbarRef}>
            <ImageViewerToolbar
              resetZoom={resetZoom}
              transform={transform}
              showAll={showAll}
              setShowAll={setShowAll}
              parserLogs={parserLogs}
              hideGallery={hideGallery}
            />
          </div>
        )}
        <div
          ref={ref}
          className={styles.ImageViewerContainer}
          style={{ height: toolbarHeight ? `calc(100% - ${toolbarHeight}px)` : "100%" }}
        >
          {!!debouncedWidth && !!debouncedHeight && (
            <canvas style={{ position: "absolute" }} ref={canvasRef} width={debouncedWidth} height={debouncedHeight} />
          )}
          {!imageData && isLoading && (
            <div className={styles.ImageViewerLoadingIndicatorContainer}>
              <Skeleton type="viewer" />
            </div>
          )}
          {!imageData && !isLoading && (
            <div className={styles.ImageViewerLoadingIndicatorContainer}>
              <Alert message="No image data..." type="light" fit centered />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
