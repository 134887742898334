import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { FieldArrayWithId, FieldErrors, useFieldArray, useForm, useWatch } from "react-hook-form";
import {
  CustomType,
  CustomTypeEntityTypeOptionsReduced,
  CustomTypeSectionWriteModel,
  CustomTypeWriteModel,
  customTypeConstants,
  CustomTypeFieldLabels,
  CustomTypeDataTypeUtils,
  CustomTypeFilters,
} from "../../api/CustomTypes";
import { InputFormField } from "../../common/formfields/InputFormField";
import { TextareaFormField } from "../../common/formfields/TextareaFormField";
import { GenericVirtualizedSelectFormField } from "../../common/formfields/GenericVirtualizedSelectFormField/GenericVirtualizedSelectFormField";
import {
  CustomTypeEntityTypeOptionToIcon,
  NestedErrorContainer,
  RenderCustomTypeDefaultSections,
} from "./CustomTypeRenderUtils";
import { OverlayInfo } from "../../common/misc/OverlayInfo/OverlayInfo";
import { CustomTypeSectionBuilder, useCustomSectionFormSchema } from "./CustomTypeSectionBuilder";
import GridLayout from "react-grid-layout";
import styles from "./CustomTypeBuilder.module.css";
import { useResizeDetector } from "react-resize-detector";
import { LucideIcon } from "../../common/icon/LucideIcon";
import TableView from "../../common/panels/TableView/TableView";
import { Button } from "../../common/buttons/Button/Button";
import { CustomTypeBuilderSidebar } from "./CustomTypeBuilderSidebar";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { EntityFormProps } from "../../common/entity/EntityInterfaces";
import { FormHeader } from "../../common/forms/FormHeader";
import { SessionContext } from "../../common/contexts/SessionContext";
import { useEntityApi } from "../../api/useEntityApi";
import { CustomField, customFieldConstants } from "../../api/CustomFields";
// import { CustomFieldForm } from "../CustomFields/CustomFieldsForm";
import { ToggleFormField } from "../../common/formfields/ToggleFormField";
import { customFieldFormToCustomField } from "../CustomFields/CustomFieldUtils";
import { CustomTypeToCustomTypeForm } from "./CustomTypeUtils";
import { Nullable } from "../../api/GenericTypes";
import { hierarchyConstants, inventoriesConstants } from "../../api/Inventories";
import { toUppercase } from "../../common/helperfunctions/stringFunctions";
import { useCallbackRef } from "../../common/helperfunctions/useCallbackRef";
import useUnsavedChangesWarning from "../../common/helperfunctions/useUnsavedChangesWarning";
import { GenericModalWrapper } from "../../common/modals/Modal/GenericModal";
import { AlertModal } from "../../common/modals/AlertModal/AlertModal";

const useCustomTypeFormSchema = ({
  disableNameCheck,
  disableInventoryNameCheck,
  disableCustomFieldNameCheck,
}: {
  disableNameCheck?: boolean;
  disableInventoryNameCheck?: boolean;
  disableCustomFieldNameCheck?: boolean;
}) => {
  const { api } = useContext(SessionContext);
  const CustomSectionFormSchema = useCustomSectionFormSchema({
    disableCustomFieldNameCheck: disableCustomFieldNameCheck,
  });
  const CustomTypeFormSchema = yup.object().shape({
    name: yup
      .string()
      .required("A name is required")
      .typeError("A name is required")
      .test("unique", "Name must be unique", async (value, context) => {
        if (disableNameCheck) return true;
        if (!context.parent.name) return false;
        if (!context.parent.entityType) return false;

        const result = await api
          .post(
            `${customTypeConstants.resource}/count`,
            {
              entityTypes: [context.parent.entityType],
              names: [context.parent.name],
              excludeIds: context.parent.id ? [context.parent.id] : [],
            } as CustomTypeFilters,
            undefined
          )
          .catch(() => false);

        if (result.count === 0) {
          return true;
        }
        return false;
      }),
    entityType: yup
      .string()
      .transform((_, val) => {
        return val ? val : null;
      })
      .required("An entity type is required")
      .typeError("An entity type is required"),
    // sections: yup.array().min(1, "At least one section is required").of(CustomSectionFormSchema),
    sections: yup.array().of(CustomSectionFormSchema),
    description: yup.string().nullable(),
    isEnabled: yup.boolean().required("Is enabled is required"),
    isHierarchyRoot: yup.boolean().nullable(),
    hasRestrictedAddPermission: yup.boolean().nullable(),
    hasRestrictedEditPermission: yup.boolean().nullable(),
    hasRestrictedReadPermission: yup.boolean().nullable(),
    parentTypes: yup
      .array()
      .of(
        yup.object().shape({
          id: yup.number().required(),
        })
      )
      .nullable(),
    // inventoryName: yup.mixed().when(["entityType", "parentTypes"], ([entityType, parentTypes], schema) => {
    //   if (CustomTypeDataTypeUtils.CanDefineHierarchy(entityType) && !parentTypes)
    //     return yup.string().required("An inventory name is required");
    //   return yup.string().nullable().notRequired();
    // }),
    inventoryName: yup
      .string()
      .nullable()
      .test("unique", "Inventory name must be unique", async (value, context) => {
        if (disableInventoryNameCheck) return true;
        const result = await api.post(
          `${customTypeConstants.resource}/count`,
          {
            entityType: [context.parent.entityType],
            inventoryNames: [context.parent.inventoryName],
            excludeIds: context.parent.id ? [context.parent.id] : [],
          } as CustomTypeFilters,
          undefined
        );

        if (result.count === 0) {
          return true;
        }
        return false;
      }),
  });

  return CustomTypeFormSchema;
};

interface SectionValidationStatus {
  [elementId: string]: boolean;
}

interface LayoutState {
  [elementId: string]: {
    field: FieldArrayWithId<CustomTypeWriteModel, "sections", "elementId">;
    layout: GridLayout.Layout;
  };
}

export const CustomTypesForm = ({
  fieldLabels,
  permissions,
  title,
  subtitle,
  initialValues,
  onSubmit,
  onCancel,
  submitButtonLabel,
  loading,
  event,
}: EntityFormProps<"customTypes">) => {
  const [sectionValidationStatus, setSectionValidationStatus] = useState<SectionValidationStatus>({});
  const [showPlaceholder, setShowPlaceholder] = useState(true);
  const { ref, width } = useResizeDetector();
  const { routerPrompt, onDirty, onPristine } = useUnsavedChangesWarning();

  const { createMutationAsync: createCustomFieldMutationAsync } = useEntityApi<CustomField>(
    customFieldConstants.resource
  );

  const beforeCreate = async (entity: CustomTypeWriteModel): Promise<Partial<CustomType>> => {
    if (entity.sections) {
      for (const section of entity.sections) {
        if (Array.isArray(section.customFields))
          for (const customField of section.customFields) {
            if (customField.id === undefined) {
              const newCustomField = await createCustomFieldMutationAsync({
                body: customFieldFormToCustomField(customField),
              }).catch(() => {});
              if (newCustomField) customField.id = newCustomField.id;
            }
          }
      }
    }
    return entity as Partial<CustomType>;
  };
  const defaultValues = useMemo(() => CustomTypeToCustomTypeForm(initialValues), [initialValues]);
  const CustomTypeFormSchema = useCustomTypeFormSchema({
    disableNameCheck: event === "EDIT",
    disableInventoryNameCheck:
      initialValues?.entityType !== "Inventory" ||
      event === "EDIT" ||
      (initialValues && Array.isArray(initialValues.parentTypes) && !!initialValues.parentTypes.length),
    disableCustomFieldNameCheck: true,
  });

  const {
    reset,
    register,
    control,
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting, isDirty },
    trigger,
  } = useForm<CustomTypeWriteModel>({
    values: defaultValues,
    resolver: yupResolver(CustomTypeFormSchema),
  });

  useEffect(() => {
    if (isDirty) {
      onDirty();
    } else {
      onPristine();
    }
    return () => onPristine();
  }, [isDirty, onDirty, onPristine]);

  const { fields, append, update, remove, replace } = useFieldArray({
    control,
    name: "sections",
    keyName: "elementId",
  });

  const [layout, setLayout] = useState<LayoutState>(
    Object.fromEntries(
      Object.entries(fields).map(([k, v]) => [
        v.elementId,
        { field: v, layout: { i: v.elementId, x: 0, y: 0, w: 1, h: 6 } },
      ])
    )
  );

  useEffect(() => {
    setLayout((prev) =>
      Object.fromEntries(
        Object.entries(fields).map(([k, v]) => [
          v.elementId,
          { field: v, layout: { i: v.elementId, x: 0, y: 0, w: 1, h: prev?.[v.elementId]?.layout?.h || 6 } },
        ])
      )
    );
  }, [fields]);

  const customFieldIds = fields
    .map((f) => f.customFields)
    .flat()
    .filter((c) => c && c.id !== undefined)
    .map((c) => c?.id) as number[];

  // This callback will dynamically adjust the layout based on the height of the widgets
  const nodeHeightCallback = useCallback((elementId: string, height: number) => {
    // console.log("Component reported height: ", height, " with elementId: ", elementId);
    setLayout((prev) => ({
      ...prev,
      [elementId]: { ...prev[elementId], layout: { ...prev[elementId].layout, h: height } },
    }));
  }, []);

  const onSwap = useCallback(
    (layout: GridLayout.Layout[]) => {
      const sortedLayout = layout.filter((d) => d.i !== "new").sort((a, b) => a.y - b.y);
      const fieldsDict = Object.fromEntries(Object.entries(fields).map(([, v]) => [v.elementId, v]));
      const newFieldsLayout = sortedLayout.map((d) => d.i).map((id) => fieldsDict[id]);
      replace(newFieldsLayout);
    },
    [fields, replace]
  );

  const handleSetSectionValidationStatus = useCallback((elementId: string, isValid: boolean) => {
    setSectionValidationStatus((prev) => ({ ...prev, [elementId]: isValid }));
  }, []);

  const handleReset = useCallback(() => reset(defaultValues), [defaultValues, reset]);

  const updateSection = useCallback(
    async (elementId: string, data: Partial<Nullable<CustomTypeSectionWriteModel>>) => {
      // console.log("Updating section: ", elementId, data);
      const index = fields.findIndex((f) => f.elementId === elementId);
      update(index, data);
      await trigger("sections");
    },
    [fields, trigger, update]
  );

  const removeSection = useCallback(
    (elementId: string) => {
      const index = fields.findIndex((f) => f.elementId === elementId);
      remove(index);
    },
    [fields, remove]
  );

  useEffect(() => {
    if (fields.length === 0) {
      setShowPlaceholder(true);
    } else {
      setShowPlaceholder(false);
    }
  }, [fields.length]);

  const entityType = useWatch({ name: "entityType", control });

  const { ref: callbackRef, node } = useCallbackRef<HTMLDivElement>();

  const hasRestrictedReadPermission = useWatch({ name: "hasRestrictedReadPermission", control });
  useEffect(() => {
    if (hasRestrictedReadPermission) setValue("hasRestrictedEditPermission", true);
  }, [hasRestrictedReadPermission, setValue]);

  return (
    <TableView style={{ gap: 5 }}>
      <TableView.Head>
        <TableView.Head.Label>
          <FormHeader
            title={
              initialValues?.parentTypes &&
              Array.isArray(initialValues.parentTypes) &&
              !!initialValues.parentTypes.length &&
              event === "ADD" ? (
                <div className="flex row-nowrap align-center gap-5">
                  <div className="flex row-nowrap align-center gap-5">
                    <LucideIcon name={customTypeConstants.icon} color={"var(--primary)"} />{" "}
                    {initialValues.parentTypes[0].name}
                  </div>
                  <LucideIcon name="arrow-big-right" />
                  <div>{`Add child ${hierarchyConstants.childType}`}</div>
                </div>
              ) : (
                title
              )
            }
            subtitle={subtitle}
            titleStyle={{ marginBottom: 0 }}
          />
        </TableView.Head.Label>
        <TableView.Head.Controls>
          <>
            {routerPrompt}
            <Button
              className="btn btn-default"
              title={"Cancel"}
              loading={isSubmitting || loading}
              onClick={() => {
                onPristine();
                onCancel();
              }}
            >
              Cancel
            </Button>
            {isDirty && (
              <Button
                loading={isSubmitting || loading}
                className="btn btn-default"
                title="Reset changes"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  handleReset();
                }}
              >
                Reset
              </Button>
            )}
            {event === "EDIT" ? (
              <GenericModalWrapper>
                {({ showModal, setShowModal }) => (
                  <>
                    <AlertModal
                      type="primary"
                      title="Confirm changes"
                      description={
                        "Editing a custom type will affect all entities associated with it. Removing sections and/or custom fields may result in data loss. Are you sure you want to proceed?"
                      }
                      showModal={showModal}
                      setShowModal={setShowModal}
                      proceedLabel={submitButtonLabel}
                      onCancel={() => {
                        onPristine();
                        onCancel();
                      }}
                      onProceed={handleSubmit(async (entity) => {
                        if (await trigger()) {
                          onPristine();
                          const newEntity = await beforeCreate(entity);
                          await onSubmit(newEntity);
                        }
                      })}
                      forceCountdown
                    />
                    <Button
                      className="btn btn-primary"
                      title={submitButtonLabel}
                      onClick={async () => {
                        if (await trigger()) setShowModal(true);
                      }}
                      loading={isSubmitting || loading}
                      disabled={fields.map((f) => f.elementId).some((elementId) => !sectionValidationStatus[elementId])}
                    >
                      {submitButtonLabel}
                    </Button>
                  </>
                )}
              </GenericModalWrapper>
            ) : (
              <Button
                className="btn btn-primary"
                title={submitButtonLabel}
                onClick={handleSubmit(async (entity) => {
                  if (await trigger()) {
                    onPristine();
                    const newEntity = await beforeCreate(entity);
                    await onSubmit(newEntity);
                  }
                })}
                loading={isSubmitting || loading}
                disabled={fields.map((f) => f.elementId).some((elementId) => !sectionValidationStatus[elementId])}
              >
                {submitButtonLabel}
              </Button>
            )}
          </>
        </TableView.Head.Controls>
      </TableView.Head>
      <TableView.Body>
        <TableView.Body.Sidebar>
          <CustomTypeBuilderSidebar disabledItems={customFieldIds} />
        </TableView.Body.Sidebar>
        <TableView.Body.Content>
          <div className={styles.mainContainer} ref={callbackRef}>
            <div className={styles.formContainer} style={{ padding: 20 }}>
              {errors && <NestedErrorContainer errors={errors} />}
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                }}
                autoComplete="off"
                className={`form-horizontal ${styles.form}`}
              >
                {!initialValues?.entityType && (
                  <GenericVirtualizedSelectFormField
                    id={"entityType"}
                    label={CustomTypeFieldLabels.entityType}
                    items={Array.from(CustomTypeEntityTypeOptionsReduced)
                      .sort()
                      .map((e) => e)}
                    control={control}
                    placeholder={"Select entity type"}
                    onRowStartRenderer={(row) => (
                      <CustomTypeEntityTypeOptionToIcon entityType={row} color={"var(--primary)"} />
                    )}
                    required
                    horizontal
                  >
                    <OverlayInfo icon="info">This defines the underlying entity type of the custom type.</OverlayInfo>
                  </GenericVirtualizedSelectFormField>
                )}

                {entityType && CustomTypeDataTypeUtils.CanDefineHierarchy(entityType) && (
                  <>
                    {(!initialValues?.parentTypes ||
                      (Array.isArray(initialValues.parentTypes) && !initialValues.parentTypes.length)) && (
                      <>
                        <fieldset>
                          <legend>Inventory details</legend>
                        </fieldset>
                        <InputFormField
                          id="inventoryName"
                          label={CustomTypeFieldLabels.inventoryName}
                          errors={errors}
                          register={register}
                          placeholder={`Enter ${inventoriesConstants.entitySingular} name e.g. "Sample Storage"`}
                          required
                        />
                        <TextareaFormField
                          id="inventoryDescription"
                          label={CustomTypeFieldLabels.inventoryDescription}
                          errors={errors}
                          register={register}
                          placeholder={`Enter ${inventoriesConstants.entitySingular} description`}
                        />
                        <ToggleFormField
                          id="isHierarchyRoot"
                          label={CustomTypeFieldLabels.isHierarchyRoot}
                          control={control}
                          disabled={!["ADD", "CLONE"].includes(event)}
                          horizontal
                        >
                          <OverlayInfo icon="info">This allows to define a hierarchical structure.</OverlayInfo>
                        </ToggleFormField>
                      </>
                    )}
                    <fieldset>
                      <legend>{toUppercase(hierarchyConstants.childType)} details</legend>
                    </fieldset>
                    {/* {!initialValues?.parentTypes ? (
                      <>
                        <InputFormField
                          id="inventoryName"
                          label={CustomTypeFieldLabels.inventoryName}
                          errors={errors}
                          register={register}
                          placeholder={`Enter ${inventoriesConstants.entitySingular} name e.g. "Freezers"`}
                          required
                        />
                        <ToggleFormField
                          id="isHierarchyRoot"
                          label={CustomTypeFieldLabels.isHierarchyRoot}
                          control={control}
                          horizontal
                        >
                          <OverlayInfo icon="info">This allows to define a hierarchical structure.</OverlayInfo>
                        </ToggleFormField>
                      </>
                    ) : (
                      <>
                        <CustomTypesVirtualizedSelectForm
                          id="rootHierarchy"
                          control={control}
                          label={CustomTypeFieldLabels.rootHierarchy}
                          placeholder="Select root hierarchy type"
                          filters={{ entityTypes: ["Inventory"] }}
                          horizontal
                          disabled
                        >
                          <OverlayInfo icon="info">This defines the root inventory.</OverlayInfo>
                        </CustomTypesVirtualizedSelectForm>

                        <CustomTypesVirtualizedSelectForm
                          id="parentTypes"
                          control={control}
                          label={CustomTypeFieldLabels.parentTypes}
                          placeholder="Select parent types"
                          filters={{ entityTypes: ["Inventory"] }}
                          disabled
                          horizontal
                          isMulti
                        >
                          <OverlayInfo icon="info">Select valid parent types.</OverlayInfo>
                        </CustomTypesVirtualizedSelectForm>
                      </>
                    )} */}
                  </>
                )}

                <InputFormField
                  id="name"
                  label={
                    entityType && CustomTypeDataTypeUtils.CanDefineHierarchy(entityType)
                      ? `${toUppercase(hierarchyConstants.childType)} name`
                      : CustomTypeFieldLabels.name
                  }
                  errors={errors}
                  register={register}
                  placeholder={`${
                    entityType && CustomTypeDataTypeUtils.CanDefineHierarchy(entityType) && !initialValues?.parentTypes
                      ? `Enter ${hierarchyConstants.childType} name e.g. "Freezer"`
                      : entityType && CustomTypeDataTypeUtils.CanDefineHierarchy(entityType)
                      ? `Enter ${hierarchyConstants.childType} name`
                      : `Enter ${customTypeConstants.entitySingular} name`
                  }`}
                  required
                />
                <TextareaFormField
                  id="description"
                  label={CustomTypeFieldLabels.description}
                  errors={errors}
                  register={register}
                  placeholder={`${
                    entityType && CustomTypeDataTypeUtils.CanDefineHierarchy(entityType)
                      ? `Enter ${hierarchyConstants.childType} description`
                      : `Enter ${customTypeConstants.entitySingular} description`
                  }`}
                />

                <ToggleFormField id="isEnabled" label={CustomTypeFieldLabels.isEnabled} control={control} horizontal>
                  <OverlayInfo icon="info">Allow creating entities with this type.</OverlayInfo>
                </ToggleFormField>

                {entityType && CustomTypeDataTypeUtils.CanDefineHierarchy(entityType) && (
                  <>
                    <fieldset>
                      <legend>Permissions</legend>
                    </fieldset>
                    <ToggleFormField
                      id="hasRestrictedAddPermission"
                      label={CustomTypeFieldLabels.hasRestrictedAddPermission}
                      control={control}
                      horizontal
                    >
                      <OverlayInfo icon="info">
                        Only admins will be able to create inventory items when this option is enabled.
                      </OverlayInfo>
                    </ToggleFormField>
                    <ToggleFormField
                      id="hasRestrictedReadPermission"
                      label={CustomTypeFieldLabels.hasRestrictedReadPermission}
                      control={control}
                      horizontal
                    >
                      <OverlayInfo icon="info">
                        Only admins, the creator or users with project permissions will be able to view inventory items
                      </OverlayInfo>
                    </ToggleFormField>
                    <ToggleFormField
                      id="hasRestrictedEditPermission"
                      label={CustomTypeFieldLabels.hasRestrictedEditPermission}
                      control={control}
                      horizontal
                      disabled={!!hasRestrictedReadPermission}
                    >
                      <OverlayInfo icon="info">
                        Only admins, the creator or users with project permissions will be able to edit inventory items
                      </OverlayInfo>
                    </ToggleFormField>
                  </>
                )}

                <fieldset>
                  <legend>Sections</legend>
                </fieldset>
                {entityType && <RenderCustomTypeDefaultSections entityType={entityType} />}
                <div
                  className={styles.rglContainerWrapper}
                  style={{
                    ...(errors?.["sections"] && { borderColor: "var(--danger)" }),
                  }}
                >
                  <div className="flex" style={{ padding: "0px 10px" }}>
                    <div
                      className={styles.addSectionButton}
                      onClick={() => {
                        append({ name: null, isFolded: false, customFields: [] });
                        setTimeout(() => {
                          if (node) node.scrollTo({ top: node.scrollHeight, behavior: "smooth" });
                        }, 100);
                      }}
                    >
                      <LucideIcon name="plus" style={{ width: 24, height: 24 }} />
                      <h3 style={{ margin: 0 }}>Add section</h3>
                    </div>
                  </div>
                  <div className={`${styles.rglMainContainer}`} ref={ref}>
                    {!!width && (
                      <div style={{ flex: "1 0 100%", position: "relative" }}>
                        <GridLayout
                          className={`${styles.rglContainer} `}
                          layout={Object.values(layout).map((l) => l.layout)}
                          cols={1}
                          rowHeight={1}
                          width={width}
                          verticalCompact={true}
                          margin={[10, 10]}
                          containerPadding={[10, 10]}
                          compactType={"vertical"}
                          preventCollision={false}
                          isDroppable={false}
                          isResizable={false}
                          isDraggable
                          onDragStop={onSwap}
                        >
                          {Object.entries(layout).map(([elementId, l], index) => (
                            <div key={elementId} className="section" data-grid={l.layout}>
                              <CustomTypeSectionBuilder
                                elementId={elementId}
                                nodeHeightCallback={nodeHeightCallback}
                                defaultValues={l.field}
                                updateSection={updateSection}
                                removeSection={removeSection}
                                handleSetSectionValidationStatus={handleSetSectionValidationStatus}
                                disabled={false}
                                disabledRemove={false}
                                errorsExternal={
                                  errors?.sections?.[index] as FieldErrors<
                                    FieldArrayWithId<CustomTypeWriteModel, "sections", "elementId">
                                  >
                                }
                              />
                            </div>
                          ))}
                        </GridLayout>
                        <div
                          className={`flex col-nowrap align-center justify-center ${styles.placeholderOverlay}`}
                          style={{ display: showPlaceholder ? "flex" : "none" }}
                        >
                          <LucideIcon name="plus" color={"var(--gray-400)"} style={{ width: "30px", height: "30px" }} />

                          <span style={{ color: "var(--gray-400)", fontWeight: 500 }}>
                            {`Click the "Add section" button to add a new section`}
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </TableView.Body.Content>
      </TableView.Body>
    </TableView>
  );
};
