import { EntityConstantsBase } from "./GenericTypes";

export const documentationConstants: EntityConstantsBase = {
  entityPlural: "documentation",
  entitySingular: "documentation",
  icon: "book-text",
};

export const logsPyConstants: EntityConstantsBase = {
  entityPlural: "python API (LOGS-Py)",
  entitySingular: "python API (LOGS-Py)",
  icon: "code", //We actually use <img src={`${process.env.PUBLIC_URL}/assets/logos/LOGS-Py_Icon.svg`} className="feather" alt={toUppercase(logsPyConstants.entitySingular)} />
};

export const logsSupportConstants: EntityConstantsBase = {
  entityPlural: "Help center",
  entitySingular: "Help center",
  icon: "life-buoy",
};
