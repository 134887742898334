import { DateTimeRenderer } from "../../datetime/DateTimeFormatter";
import { EntitySidebarTile, EntitySidebarTileProps } from "./EntitySidebarTile/EntitySidebarTile";
import { NotSet } from "../../misc/UIconstants";
import { GetPersons } from "../../misc/EntityRenders/EntityRenderer";
import {
  InventoryItem,
  InventoryItemFieldLabels,
  InventoryItemFilters,
  InventoryItemFiltersTranslator,
  InventoryItemSortingOptionsConsts,
  inventoryItemsConstants,
} from "../../../api/Inventories";
import { Dispatch, SetStateAction, useCallback, useState } from "react";
import { CustomTypesVirtualizedSelectForm } from "../../forms/EntityForms/formsVirtualized/CustomTypesVirtualizedSelectForm";
import { useForm } from "react-hook-form";
import { LucideIcon } from "../../icon/LucideIcon";
import { QuickAddInventoryItemForm } from "../../forms/QuickAdd/forms/QuickAddInventoryItemForm";
import { QuickAddWrapper } from "../../forms/QuickAdd/common/QuickAddWrapper";

interface InventoryTileProps extends EntitySidebarTileProps<InventoryItem, InventoryItemFilters> {
  showCustomTypeSelectWidget?: boolean;
  parentInventoryItem?: InventoryItem;
}

export const InventoriesTile = ({ showCustomTypeSelectWidget, ...props }: InventoryTileProps) => {
  const { control, watch } = useForm<Partial<InventoryItem>>();
  const [showCreateInventoryItemModal, setShowCreateInventoryItemModal] = useState<boolean>(false);

  const currentCustomType = watch("customType");

  const defaultControlsUnfolded = useCallback(
    ({
      filters,
      setFilters,
    }: {
      filters: InventoryItemFilters;
      setFilters: Dispatch<SetStateAction<InventoryItemFilters>>;
    }) => {
      if (!!props.controlsUnfolded || !showCustomTypeSelectWidget)
        return props.controlsUnfolded?.({ filters, setFilters });
      return (
        <div className="flex align-center gap-5" style={{ width: "100%" }}>
          <div style={{ height: "38px", overflow: "hidden", width: "100%", maxWidth: "30ch" }}>
            <CustomTypesVirtualizedSelectForm
              id="customType"
              filters={{
                orderBy: "NAME_ASC",
                parentTypeIds: props.parentInventoryItem?.customType?.id
                  ? [props.parentInventoryItem.customType.id]
                  : undefined,
                entityTypes: ["Inventory"],
              }}
              control={control}
              horizontal
              hasLabel={false}
              style={{ minWidth: "200px", padding: 0, width: "100%" }}
              placeholder={InventoryItemFieldLabels.customType}
            />
          </div>
          {!!props.showAddButton && (
            <>
              <button
                className="btn btn-ghost-primary flex align-center"
                onClick={() => setShowCreateInventoryItemModal(true)}
              >
                <LucideIcon name="plus" />
              </button>
              <QuickAddWrapper<InventoryItem>
                showModal={showCreateInventoryItemModal}
                setShowModal={setShowCreateInventoryItemModal}
                onCreate={() => setShowCreateInventoryItemModal(false)}
                onCancel={() => setShowCreateInventoryItemModal(false)}
              >
                {(quickAddProps) => (
                  <QuickAddInventoryItemForm
                    {...quickAddProps}
                    initialValues={{ customType: currentCustomType, parent: props.parentInventoryItem }}
                    customTypeFilters={{
                      parentTypeIds: props.parentInventoryItem?.customType
                        ? [props.parentInventoryItem.customType.id]
                        : undefined,
                    }}
                  />
                )}
              </QuickAddWrapper>
            </>
          )}
        </div>
      );
    },
    [control, currentCustomType, props, showCreateInventoryItemModal, showCustomTypeSelectWidget]
  );

  return (
    <EntitySidebarTile<InventoryItem, InventoryItemFilters>
      {...props}
      entityConstants={inventoryItemsConstants}
      orderByOptions={InventoryItemSortingOptionsConsts}
      translatorConsts={InventoryItemFiltersTranslator}
      defaultFilters={{
        orderBy: "CREATED_ON_DESC",
        ...props.defaultFilters,
        customTypeIds:
          props.defaultFilters?.customTypeIds ?? (currentCustomType?.id ? [currentCustomType.id] : undefined),
        childrenOfParentIds:
          props.defaultFilters?.childrenOfParentIds ??
          (props.parentInventoryItem?.id ? [props.parentInventoryItem.id] : undefined),
      }}
      excludeFilters={{
        ...props.excludeFilters,
        customTypeIds: () => !!props.defaultFilters?.customTypeIds || !!currentCustomType?.id,
        childrenOfParentIds: () => !!props.defaultFilters?.childrenOfParentIds,
      }}
      rowLabel={(e) => <>{e.name}</>}
      rowDate={(e) => (
        <>{e.createdOn ? <DateTimeRenderer date={e.createdOn} includeElapsed={false} includeTime={false} /> : NotSet}</>
      )}
      rowInfo={(e) => (
        <>
          {props.defaultFilters?.customTypeIds?.length !== 1 ? (
            e.customType?.name ?? NotSet
          ) : e.createdBy ? (
            <GetPersons persons={e.createdBy} createLinks={false} />
          ) : (
            NotSet
          )}
        </>
      )}
      controlsUnfolded={defaultControlsUnfolded}
      showAddButton={!showCustomTypeSelectWidget && props.showAddButton}
    />
  );
};
