import {
  DatasetFilters,
  Dataset,
  datasetsConstants,
  DatasetSortingOptionsConsts,
  DatasetFiltersTranslator,
} from "../../../api/Datasets";
import { DateTimeRenderer } from "../../datetime/DateTimeFormatter";
import { EntitySidebarTile, EntitySidebarTileProps } from "./EntitySidebarTile/EntitySidebarTile";
import { NotAvailable, NotSet } from "../../misc/UIconstants";
import { Dispatch, SetStateAction, useCallback, useContext } from "react";
import { DownloadDatasetMulti } from "../../../Dataset/common/DownloadDataset/DownloadDataset";
import { SessionContext } from "../../contexts/SessionContext";

/**
 * Sidebar component for DetailViews
 * Author: CS
 * @param: filterquery, title
 * @returns: JSX.Element
 */

interface DatasetTileProps extends EntitySidebarTileProps<Dataset, DatasetFilters> {
  allowDownload?: boolean;
}

export const DatasetsTile = (props: DatasetTileProps) => {
  const { api } = useContext(SessionContext);

  const controlsUnfolded = useCallback(
    ({ filters, setFilters }: { filters: DatasetFilters; setFilters: Dispatch<SetStateAction<DatasetFilters>> }) => {
      if (!props.allowDownload) return undefined;
      return (
        <DownloadDatasetMulti
          btnStyle="btn btn-primary"
          api={api}
          filters={filters}
          title="Download all datasets for this sample"
        />
      );
    },
    [api, props.allowDownload]
  );

  const rowControls = useCallback(
    (dataset: Dataset) => {
      if (!props.allowDownload) return undefined;
      return (
        <DownloadDatasetMulti
          btnStyle="btn btn-ghost-primary btn-xs"
          api={api}
          filters={{ ids: [dataset.id] }}
          title={`Download ${dataset.name}`}
        />
      );
    },
    [api, props.allowDownload]
  );

  return (
    <EntitySidebarTile<Dataset, DatasetFilters>
      {...props}
      controlsUnfolded={props.controlsUnfolded ?? controlsUnfolded}
      entityConstants={datasetsConstants}
      orderByOptions={DatasetSortingOptionsConsts}
      translatorConsts={DatasetFiltersTranslator}
      defaultFilters={{ orderBy: "ACQUISITION_DATE_DESC", ...props.defaultFilters }}
      rowLabel={(e) => <>{e.name || NotAvailable}</>}
      rowDate={(e) => (
        <>
          {e.acquisitionDate ? (
            <DateTimeRenderer date={e.acquisitionDate} includeElapsed={false} includeTime={false} />
          ) : (
            NotSet
          )}
        </>
      )}
      rowInfo={(e) => (
        <>
          <label className="label label-soft-secondary" style={{ margin: 0 }}>
            {e.method?.name}
          </label>
          {e.experiment ? <span style={{ color: "var(--primary)" }}> {e.experiment.name}</span> : ""}
        </>
      )}
      rowControls={props.rowControls ?? rowControls}
    />
  );
};

// interface Props {
//   foldable?: boolean;
//   filterquery: DatasetFilters;
//   title?: string;
// }
// export const DatasetsTile = ({ foldable = true, filterquery, title = "Datasets" }: Props) => {
//   const history = useHistory();
//   const { api, route } = useContext(SessionContext);
//   const pageSize = 5;
//   const [datasets, setDatasets] = useState<Dataset[]>([]);
//   // const [editDataset, setEditDataset] = useState<Dataset>();
//   const [loading, setLoading] = useState(false);
//   const [searchValue, setSearchValue] = useState("");
//   const debouncedSearchValue = useDebouncedValue(searchValue, 500);

//   const { data, error, fetchNextPage, hasNextPage, isFetching, status, fetchStatus, count } =
//     useInfiniteListEntity<Dataset>(
//       "datasets",
//       {
//         page: 1,
//         pageSize: pageSize,
//         includeCount: true,
//
//         includeSoftDeleted: true,
//         ...(debouncedSearchValue && { searchTerm: debouncedSearchValue }),
//         ...filterquery,
//       },
//       { enabled: !!filterquery && !!pageSize },
//       "post"
//     );

//   // Cleanup
//   useEffect(() => {
//     return () => {
//       setDatasets([]);
//     };
//   }, []);

//   useMemo(() => {
//     if (data) {
//       setDatasets(data.pages.map((d) => d.results).flat());
//     }
//   }, [data]);

//   const handleRowClick = useCallback(
//     (datasetId: number) => {
//       history.push(route(getDetailLink("datasets", datasetId)));
//     },
//     [history, route]
//   );

//   const handleDownloadAll = useCallback(async () => {
//     if (filterquery) {
//       setLoading(true);
//       try {
//         // const ids = await api.getDatasetsIds(filterquery);
//         await getDatasetsZip(api, filterquery);
//         setLoading(false);
//       } catch {
//         showtoast("error", "Sorry, an error occured while processing your request");
//       }
//       setLoading(false);
//     }
//   }, [api, filterquery]);

//   const fetchNext = useCallback(() => {
//     if (hasNextPage) {
//       fetchNextPage();
//     }
//   }, [fetchNextPage, hasNextPage]);

//   const loadMoreCallback = useCallback(() => {}, []);
//   const itemCount = hasNextPage ? datasets.length + 1 : datasets.length;
//   const loadMoreItems = isFetching ? loadMoreCallback : fetchNext;
//   const isItemLoaded = useCallback(
//     (index: number) => !hasNextPage || index < datasets.length,
//     [datasets.length, hasNextPage]
//   );

//   const Item = useCallback(
//     ({ index, style }: { index: number; style: CSSProperties }) => {
//       const d = datasets[index];
//       if (!isItemLoaded(index)) {
//         return (
//           <div style={{ ...style, paddingBottom: "5px" }} key={index}>
//             <span className="skeleton-block btn-lg" style={{ height: 51 }} />
//           </div>
//         );
//       } else {
//         return (
//           <div style={{ ...style, paddingBottom: "5px" }} key={index}>
//             <Link
//               key={index}
//               to={route(getDetailLink("datasets", d.id))}
//               style={{ textDecoration: "none" }}
//               onClick={(e) => e.preventDefault()}
//             >
//               <ContentRow
//                 key={index}
//                 icon={
//                   <div className={styles.icon}>
//                     <FeatherIcon name="activity" />
//                   </div>
//                 }
//                 label={d.name}
//                 currentIndicator={
//                   <>
//                     {d.isDeleted ? (
//                       <div>
//                         <label className={"label label-soft-warning"} title="trashed">
//                           <FeatherIcon name="trash" />
//                         </label>
//                       </div>
//                     ) : (
//                       ""
//                     )}
//                   </>
//                 }
//                 date={
//                   d.acquisitionDate ? (
//                     <DateTimeRenderer date={d.acquisitionDate} includeElapsed={false} includeTime={false} />
//                   ) : (
//                     "-"
//                   )
//                 }
//                 id={<>Dataset-ID: {d.id}</>}
//                 info={
//                   <>
//                     <label className="label label-soft-secondary" style={{ margin: 0 }}>
//                       {d.method?.name}
//                     </label>
//                     {d.experiment ? <span style={{ color: "var(--primary)" }}> {d.experiment.name}</span> : ""}
//                   </>
//                 }
//                 controls={
//                   <button
//                     className={"btn btn-sm btn-ghost-primary"}
//                     type="button"
//                     title="Download"
//                     onClick={(e) => {
//                       e.preventDefault();
//                       e.stopPropagation();
//                       getDatasetZip(api, d.id);
//                     }}
//                   >
//                     <FeatherIcon name="download" />
//                   </button>
//                   // <MoreDropdown key={index} drop={"right"}>
//                   //   {/* <li>
//                   //             <span className={"btn btn-ghost-secondary"}>{edit_icon} Edit</span>
//                   //           </li> */}
//                   //   <li>
//                   //     <button
//                   //       className={"btn btn-ghost-primary"}
//                   //       type="button"
//                   //       onClick={(e) => {
//                   //         e.preventDefault();
//                   //         getDatasetZip(api, d.id);
//                   //       }}
//                   //     >
//                   //       <FeatherIcon name="download" /> Download
//                   //     </button>
//                   //   </li>
//                   //   {/* <li>
//                   //             <span className={"btn btn-ghost-info"}>{share_icon} Share</span>
//                   //           </li>
//                   //           <li>
//                   //             <span className={"btn btn-ghost-warning"}>{x_circle_icon} Unlink</span>
//                   //           </li> */}
//                   //   <li>
//                   //     <button
//                   //       className={"btn btn-ghost-warning"}
//                   //       onClick={(e) => {
//                   //         e.preventDefault();
//                   //         setEditDataset(() => d);
//                   //       }}
//                   //       disabled={!d.permissions.edit}
//                   //     >
//                   //       <FeatherIcon name="trash" /> Move to trash
//                   //     </button>
//                   //   </li>
//                   // </MoreDropdown>
//                 }
//                 onClick={() => handleRowClick(d.id)}
//               />
//             </Link>
//           </div>
//         );
//       }
//     },
//     [api, datasets, handleRowClick, isItemLoaded, route]
//   );

//   return (
//     <Tile
//       isFolded={debouncedSearchValue ? false : !!!datasets?.hasOwnProperty("0")}
//       foldable={debouncedSearchValue ? true : !!datasets?.hasOwnProperty("0")}
//       disabled={debouncedSearchValue ? false : !!!datasets?.hasOwnProperty("0")}
//       // isFolded={debouncedSearchValue ? false : !!!datasets?.hasOwnProperty("0")}
//       // foldable={foldable}
//     >
//       <Tile.Head
//         title={
//           <div className="flex row-nowrap align-center gap-5">
//             {title}
//             {count !== undefined && <span className="badge">{count}</span>}
//           </div>
//         }
//       >
//         <Tile.Head.Controls>
//           <Tile.Head.Controls.Unfolded>
//             <>
//               <SearchInput searchValue={searchValue} setSearchValue={setSearchValue} minimized={false} />
//               {datasets && datasets.hasOwnProperty("0") && (
//                 <button
//                   className={"btn btn-sm btn-ghost-primary"}
//                   title="Download all"
//                   onClick={handleDownloadAll}
//                   disabled={loading}
//                 >
//                   <FeatherIcon name="download" />
//                 </button>
//               )}
//             </>
//           </Tile.Head.Controls.Unfolded>
//         </Tile.Head.Controls>
//       </Tile.Head>
//       <Tile.Body>
//         <div className={styles.column_container}>
//           <LoadingWrapper status={status} fetchStatus={fetchStatus} error={error}>
//             <div className={isFetching ? `${styles.container} ${styles.container_loading}` : styles.container}>
//               {datasets && datasets.length > 0 ? (
//                 <InfiniteLoader isItemLoaded={isItemLoaded} itemCount={itemCount} loadMoreItems={loadMoreItems as any}>
//                   {({ onItemsRendered, ref }) => (
//                     <FixedSizeList
//                       itemCount={itemCount}
//                       onItemsRendered={onItemsRendered}
//                       ref={ref}
//                       width="100%"
//                       height={datasets.length > pageSize ? pageSize * 59 : datasets.length * 59}
//                       itemSize={59}
//                     >
//                       {Item}
//                     </FixedSizeList>
//                   )}
//                 </InfiniteLoader>
//               ) : (
//                 <>
//                   {isFetching ? (
//                     <Skeleton type="rows" />
//                   ) : (
//                     <>
//                       {debouncedSearchValue ? (
//                         <Alert type="light" message={`No results for: "${debouncedSearchValue}"`} fit centered />
//                       ) : (
//                         <Alert type="light" message="No related datasets" fit centered />
//                       )}
//                     </>
//                   )}
//                 </>
//               )}
//             </div>
//           </LoadingWrapper>
//         </div>
//       </Tile.Body>
//     </Tile>
//   );
// };
