import { useCallback, useEffect, useState } from "react";
import { Button, InputGroup } from "react-bootstrap";

import { ValidatedInput } from "../ViewerUIElements/Range/ValidatedInput";
import styles from "./PDFViewer.module.css";

const scaleMin = 5;
const scaleMax = 1000;

export const PDFViewerNavigation = ({
  scale,
  setScale,
  page,
  setPage,
  pageNumber,
  fitScale,
  onExport,
}: {
  scale: number;
  page: number;
  pageNumber: number;
  fitScale: number | undefined;
  setScale: (scale: number) => void;
  setPage: (scale: number) => void;
  onExport: () => void;
}) => {
  const [scaleInput, setScaleInput] = useState<number>(scale * 100);
  // const [page, setPage] = useState<number>(1);

  const changeScale = useCallback(
    (sign: -1 | 1) => {
      let s = 100 * (scale + sign * scale * 0.2);
      if (s < scaleMin) s = scaleMin;
      if (s > scaleMax) s = scaleMax;
      setScaleInput(s);
      setScale(s / 100);
    },
    [scale]
  );

  const changePage = useCallback(
    (sign: -1 | 1) => {
      let p = page + sign * 1;
      if (p > pageNumber) p = pageNumber;
      if (p < 1) p = 1;
      setPage(p);
    },
    [pageNumber, page]
  );

  useEffect(() => {
    if (Math.abs(scale * 100 - scaleInput) > 0.01) setScaleInput(scale * 100);
  }, [scale]);

  // useCallback(() => console.log("scale", scale), [scale]);

  return (
    <div className={styles.menu}>
      <div className={styles.menuSection}>
        <div onClick={() => changeScale(-1)} className={styles.clickable}>
          <span className="glyphicon glyphicon-zoom-out" />
        </div>
        <InputGroup bsSize={"sm"} className={styles.searchInputGroup}>
          <ValidatedInput
            value={scaleInput}
            setValue={setScaleInput}
            validate={(value: number) => {
              return value >= scaleMin && value <= scaleMax;
            }}
            style={{ width: "6em" }}
            digit={1}
            className={"null"}
          />
          <InputGroup.Addon>
            <b>%</b>
          </InputGroup.Addon>
        </InputGroup>
        <div onClick={() => changeScale(1)} className={styles.clickable}>
          <span className="glyphicon glyphicon-zoom-in" />
        </div>
        <div onClick={() => setScale(fitScale ?? 1)} className={styles.clickable}>
          <span className="glyphicon glyphicon-fullscreen" />
        </div>
        <div style={{ width: 30 }}></div>
        <div onClick={() => setPage(1)} className={styles.clickable}>
          <span className="glyphicon glyphicon-fast-backward" />
        </div>
        <div onClick={() => changePage(-1)} className={styles.clickable}>
          <span className="glyphicon glyphicon-step-backward" />
        </div>
        <InputGroup bsSize={"sm"} className={styles.searchInputGroup}>
          <InputGroup.Addon>
            <b>Page</b>
          </InputGroup.Addon>
          <ValidatedInput
            value={page}
            setValue={setPage}
            validate={(value: number) => {
              return value > 0 && value <= pageNumber;
            }}
            style={{ width: "4em" }}
            useInteger={true}
            className={"null"}
          />
          <InputGroup.Addon>
            <b>/ {pageNumber}</b>
          </InputGroup.Addon>
        </InputGroup>
        <div onClick={() => changePage(1)} className={styles.clickable}>
          <span className="glyphicon glyphicon-step-forward" />
        </div>
        <div onClick={() => setPage(pageNumber)} className={styles.clickable}>
          <span className="glyphicon glyphicon-fast-forward" />
        </div>
      </div>

      <div className={styles.menuSection}>
        <Button
          // style={{ marginTop: 2 }}
          style={{ height: 31 }}
          // onClick={useCallback(() => setShowExportModal(true), [])}
          onClick={onExport}
        >
          <span className="glyphicon glyphicon-download" /> Download
        </Button>
      </div>
    </div>
  );
};
