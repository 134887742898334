import { StringIndexedDict } from "../../../../api/GenericTypes";
import {
  GenericEntityVirtualizedEntitySelectFormFieldProps,
  GenericVirtualizedEntitySelectFormFieldControllerWrapper,
} from "../../../formfields/GenericVirtualizedSelectFormField/GenericVirtualizedEntitySelectFormFieldControllerWrapper";
import { useContext } from "react";
import { SessionContext } from "../../../contexts/SessionContext";
import {
  CustomTypeFilters,
  CustomTypeSortingOptionsConsts,
  CustomTypeSuggestions,
  customTypeConstants,
} from "../../../../api/CustomTypes";
import { useEntityCount } from "../../../../api/BaseEntityApi";
import { LoadingWrapper } from "../../../LoadingWrapper";

export const CustomTypesVirtualizedSelectForm = <Entity extends StringIndexedDict>(
  props: GenericEntityVirtualizedEntitySelectFormFieldProps<Entity, CustomTypeSuggestions, CustomTypeFilters> & {
    enableBasicOption?: boolean;
    hideIfNoTypes?: boolean;
  }
) => {
  const { session } = useContext(SessionContext);

  const {
    data: countQuery,
    status,
    fetchStatus,
  } = useEntityCount(customTypeConstants.resource, props.filters, {
    enabled: !!props.hideIfNoTypes,
  });
  return (
    <LoadingWrapper status={status} fetchStatus={fetchStatus}>
      {props.hideIfNoTypes && countQuery?.count === 0 ? (
        <></>
      ) : (
        <GenericVirtualizedEntitySelectFormFieldControllerWrapper<Entity, CustomTypeSuggestions, CustomTypeFilters>
          {...props}
          filters={{ orderBy: "NAME_ASC", ...props.filters }}
          //   onRowRenderer={(item) => <></>}
          //   createModal={({ showModal, setShowModal, onCreate }) => (
          //     <QuickAddWrapper showModal={showModal} setShowModal={setShowModal} onCreate={onCreate}>
          //       {(quickAddProps) => <></>}
          //     </QuickAddWrapper>
          //   )}
          entityConstants={customTypeConstants}
          orderByOptions={CustomTypeSortingOptionsConsts}
          allowCreateEntity={session?.permissions.administer_custom_field_schemas && props.allowCreateEntity}
          concatItems={
            props.enableBasicOption && !props.allowUnassigned && props.filters?.entityTypes?.length === 1
              ? [{ id: 0, name: `Basic ${props.filters.entityTypes[0]}` }]
              : props.concatItems
          }
        />
      )}
    </LoadingWrapper>
  );
};
