import { Bridge, BridgeFilters, BridgeType, BridgeTypeFormValues, BridgeFieldLabels } from "../../../api/Bridges";
import { DefinedPropertyObject, IEntityMinimalModel } from "../../../api/GenericTypes";
import { SelectFormField } from "../../../common/formfields/SelectFormField";
import { DatasetsVirtualizedSelectForm } from "../../../common/forms/EntityForms/formsVirtualized/DatasetsVirtualizedSelectForm";
import { EntityFilterSideBar } from "../../../common/sidebar/filtersidebar/EntityFilterBar";
import { FilterSidebarProps, useFilterSidebar } from "../../../common/sidebar/filtersidebar/FilterSidebar";

export interface BridgeFilterForm {
  types?: { id: BridgeType; name: string }[] | undefined | null;
  datasetIds?: IEntityMinimalModel[] | undefined | null;
}

const defaultValues: DefinedPropertyObject<BridgeFilterForm> = {
  types: null,
  datasetIds: null,
};

const formToFilters = (form: BridgeFilterForm): BridgeFilters => {
  let filters: BridgeFilters = {};
  filters.types = Array.isArray(form.types) && form.types.length > 0 ? form.types?.map((d) => d.id) : null;
  filters.datasetIds =
    Array.isArray(form.datasetIds) && form.datasetIds.length > 0 ? form.datasetIds?.map((d) => d.id) : null;
  return filters;
};
const defaultFilters: BridgeFilters = { orderBy: "NAME_ASC" };

export const BridgeFilterBar = ({
  initialValues,
  setFilters,
  onFormChange,
  dispatchTabStore,
  tabsLoading,
  currentTab,
}: FilterSidebarProps<Bridge, BridgeFilterForm, BridgeFilters>) => {
  const { onReset, control } = useFilterSidebar({
    initialValues,
    defaultValues,
    defaultFilters,
    formToFilters,
    onFormChange,
    dispatchTabStore,
    setFilters,
  });

  return (
    <EntityFilterSideBar onReset={onReset}>
      <SelectFormField
        id="types"
        label={BridgeFieldLabels.type}
        control={control}
        items={BridgeTypeFormValues}
        placeholder="Select connection type..."
        horizontal={false}
        isMulti
      />

      <DatasetsVirtualizedSelectForm id="datasetIds" label={BridgeFieldLabels.datasets} control={control} isMulti />
    </EntityFilterSideBar>
  );
};
