import { useHistory } from "react-router-dom";
import { ColumnsSettings } from "../../../common/tables/ColumnsSelector/ColumnsSelector";
import {
  GenericVirtualizedTableCells,
  SortState,
} from "../../../common/tables/GenericVirtualizedTable/GenericVirtualizedTableTypes";
import { useMemo, useState } from "react";
import { TableTabsDict } from "../../../common/tables/Tabs/TableTabsTypes";
import { useTabStore } from "../../../common/tables/Tabs/useTabStore";
import { LucideIcon } from "../../../common/icon/LucideIcon";
import { DateTimeRenderer } from "../../../common/datetime/DateTimeFormatter";
import { GetPersons } from "../../../common/misc/EntityRenders/EntityRenderer";
import TableView, { TableViewLabel } from "../../../common/panels/TableView/TableView";
import { EntityTableTabs } from "../../../common/tables/Tabs/EntityTableTabs";
import { EntityTable } from "../../../common/tables/EntityTable/EntityTable";
import { EntityFilterIndicator } from "../../../common/tables/EntityFilterIndicator/EntityFilterIndicator";
import { SearchInput } from "../../../common/forms/SearchInput/SearchInput";
import { MoreDropdown } from "../../../common/buttons/MoreDropdown/MoreDropdown";
import { AlertModal } from "../../../common/modals/AlertModal/AlertModal";
import styles from "../../../common/tables/GenericVirtualizedTable/commons.module.css";
import {
  CustomType,
  CustomTypeEntityTypeOptionsReduced,
  CustomTypeFilters,
  CustomTypeFiltersTranslator,
} from "../../../api/CustomTypes";
import { CustomTypeFilterForm, CustomTypesFilterBar } from "./CustomTypesFilterBar";
import { CustomTypeEntityTypeOptionToIcon, customTypeEntityTypeOptionToIconName } from "../CustomTypeRenderUtils";
import { hierarchyConstants } from "../../../api/Inventories";
import {
  UseEntityTableDefaultProps,
  UseEntityTableProps,
  useGenericVirtualizedTable,
  useGenericVirtualizedTableTabs,
} from "../../../common/tables/GenericVirtualizedTable/useGenericVirtualizedTable";
import { EntityTableProps } from "../../../common/entity/EntityInterfaces";
import { EntityTableRestoreButton } from "../../../common/entity/entityComponents/EntityTableRestoreButton";
import { EntityTableSoftDeletableButton } from "../../../common/entity/entityComponents/EntityTableSoftDeletableButton";
import { EntityTableCloneButton } from "../../../common/entity/entityComponents/EntityTableCloneButton";
import { EntityTableEditButton } from "../../../common/entity/entityComponents/EntityTableEditButton";

export const switchCustomTypesDefaultSortState = (
  sortState: CustomTypeFilters["orderBy"]
): SortState<CustomTypeFilters["orderBy"]> => {
  switch (sortState) {
    case "ID_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-id",
        orderBy: sortState,
      };
    case "ID_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-id",
        orderBy: sortState,
      };
    case "NAME_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-name",
        orderBy: sortState,
      };
    case "NAME_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-name",
        orderBy: sortState,
      };
    case "CREATED_ON_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-createdOn",
        orderBy: sortState,
      };
    case "CREATED_ON_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-createdOn",
        orderBy: sortState,
      };
    case "CREATED_BY_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-createdBy",
        orderBy: sortState,
      };
    case "CREATED_BY_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-createdBy",
        orderBy: sortState,
      };
    case "MODIFIED_ON_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-modifiedOn",
        orderBy: sortState,
      };
    case "MODIFIED_ON_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-modifiedOn",
        orderBy: sortState,
      };
    case "MODIFIED_BY_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-modifiedBy",
        orderBy: sortState,
      };
    case "MODIFIED_BY_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-modifiedBy",
        orderBy: sortState,
      };
    default:
      return {
        sortDirection: "ASC",
        headerId: "default-name",
        orderBy: sortState,
      };
  }
};
const defaultFilter: CustomTypeFilters = { orderBy: "NAME_ASC" };

export const useCustomTypesTableDefaults = ({ fieldLabels }: UseEntityTableDefaultProps<"customTypes">) => {
  const defaults: ColumnsSettings<CustomType> = useMemo(
    () => ({
      "default-id": { pos: 0, active: false, header: "Custom type-ID", property: "id" },
      "default-name": { pos: 1, active: true, header: fieldLabels.name, property: "name" },
      "default-description": {
        pos: 2,
        active: true,
        header: fieldLabels.description,
        property: "description",
      },
      "default-entityType": { pos: 3, active: true, header: fieldLabels.entityType, property: "entityType" },
      "default-createdOn": { pos: 4, active: true, header: fieldLabels.createdOn, property: "createdOn" },
      "default-createdBy": { pos: 5, active: true, header: fieldLabels.createdBy, property: "createdBy" },
      "default-modifiedOn": { pos: 6, active: true, header: fieldLabels.modifiedOn, property: "modifiedOn" },
      "default-modifiedBy": { pos: 7, active: true, header: fieldLabels.modifiedBy, property: "modifiedBy" },
      "default-layout": { pos: 8, active: false, header: fieldLabels.isHierarchyRoot, property: "isHierarchyRoot" },
    }),
    [fieldLabels]
  );

  const tabStoreDefaults: TableTabsDict<CustomType, CustomTypeFilters, CustomTypeFilterForm> = useMemo(() => {
    let def: TableTabsDict<CustomType, CustomTypeFilters, CustomTypeFilterForm> = {
      default: {
        tabId: "default",
        type: "fixed",
        label: "All",
        title: "All",
        icon: "house",
        align: "left",
        xPos: 0,
        settings: {
          columnSettings: {},
          columnWidths: {},
          filters: defaultFilter,
          sidebarFilters: {},
        },
        forcedSettings: {
          columnSettings: {},
          columnWidths: {},
          filters: {},
          sidebarFilters: {},
        },
      },
      trash: {
        tabId: "trash",
        type: "fixed",
        label: "Trash",
        title: "Trash",
        icon: "trash",
        align: "left",
        xPos: 1,
        settings: {
          columnSettings: {},
          columnWidths: {},
          filters: defaultFilter,
          sidebarFilters: {},
        },
        forcedSettings: {
          columnSettings: {},
          columnWidths: {},
          filters: { isSoftDeleted: true },
          sidebarFilters: {},
        },
      },
    };
    CustomTypeEntityTypeOptionsReduced.forEach((d, i) => {
      def[`entity-type-${d}`] = {
        tabId: `entity-type-${d}`,
        type: "fixed",
        label: `${d} types`,
        title: `${d} types`,
        icon: customTypeEntityTypeOptionToIconName(d),
        align: "left",
        xPos: 2 + i,
        settings: {
          columnSettings: {
            ...defaults,
            "default-entityType": { pos: 3, active: false, header: fieldLabels.entityType },
            ...(d === "Inventory" && {
              "default-id": { pos: 0, active: false, header: fieldLabels.id, property: "id" },
              "default-name": { pos: 1, active: true, header: fieldLabels.name, property: "name" },
              "default-description": {
                pos: 2,
                active: true,
                header: fieldLabels.description,
                property: "description",
              },
              "default-layout": { pos: 3, active: true, header: "Layout", property: "isHierarchyRoot" },
              "default-entityType": {
                pos: 4,
                active: false,
                header: fieldLabels.entityType,
                property: "entityType",
              },
              "default-createdOn": {
                pos: 5,
                active: true,
                header: fieldLabels.createdOn,
                property: "createdOn",
              },
              "default-createdBy": {
                pos: 6,
                active: true,
                header: fieldLabels.createdBy,
                property: "createdBy",
              },
              "default-modifiedOn": {
                pos: 7,
                active: true,
                header: fieldLabels.modifiedOn,
                property: "modifiedOn",
              },
              "default-modifiedBy": {
                pos: 8,
                active: true,
                header: fieldLabels.modifiedBy,
                property: "modifiedBy",
              },
            }),
          },
          columnWidths: {},
          filters: {
            entityTypes: [d],
          },
          sidebarFilters: {
            entityType: [d],
          },
        },
        forcedSettings: {
          columnSettings: {},
          columnWidths: {},
          filters: { entityTypes: [d] },
          sidebarFilters: {},
        },
      };
    });

    return def;
  }, [
    defaults,
    fieldLabels.createdBy,
    fieldLabels.createdOn,
    fieldLabels.description,
    fieldLabels.entityType,
    fieldLabels.id,
    fieldLabels.modifiedBy,
    fieldLabels.modifiedOn,
    fieldLabels.name,
  ]);
  return { defaults, tabStoreDefaults };
};

export const useCustomTypesTableColumns = ({
  entityConstants,
  fieldLabels,
  sort,
  setSort,
}: UseEntityTableProps<"customTypes">) => {
  const columns = useMemo(() => {
    let headers: GenericVirtualizedTableCells<CustomType> = [
      {
        id: "default-id",
        Header: fieldLabels.id,
        accessor: (row) => <span style={{ color: "var(--gray-400)" }}>{row.id}</span>,
        width: 120,
        minWidth: 120,
        align: "center",
        sortingFn: (id) => {
          if (sort.headerId === id) {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "ID_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "ID_ASC" }));
            }
          } else {
            setSort({ headerId: id, sortDirection: "ASC", orderBy: "ID_ASC" });
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
      {
        Header: fieldLabels.name,
        id: "default-name",
        accessor: (row) => (
          <div className={`${styles.container_ellipsis} gap-5`}>
            <div className={`${styles.container_ellipsis}`}>
              <span className={styles.label_highlight}>
                <LucideIcon name={entityConstants.icon} color={"var(--primary)"} /> {row.name}
              </span>
            </div>
            {row.isDeleted && (
              <div>
                <label className="label label-soft-warning" title={"Trashed"}>
                  <LucideIcon name="trash" />
                </label>
              </div>
            )}
            {!row.isEnabled && (
              <div>
                <label className="label label-soft-danger" title={"Disabled"}>
                  Disabled
                </label>
              </div>
            )}
          </div>
        ),
        minWidth: 150,
        width: 250,
        align: "left",
        sortingFn: (id) => {
          if (sort.headerId === id) {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "NAME_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "NAME_ASC" }));
            }
          } else {
            setSort({ headerId: id, sortDirection: "ASC", orderBy: "NAME_ASC" });
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
      {
        Header: fieldLabels.description,
        id: "default-description",
        accessor: (row) => (
          <div
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              fontWeight: "normal",
              whiteSpace: "nowrap",
              marginRight: "5px",
            }}
          >
            {row.description}
          </div>
        ),
        minWidth: 150,
        width: 300,
        align: "left",
      },
      {
        Header: "Layout",
        id: "default-layout",
        accessor: (row) => (
          <div
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              fontWeight: "normal",
              whiteSpace: "nowrap",
              marginRight: "5px",
            }}
          >
            {row.isHierarchyRoot ? (
              <>
                <LucideIcon name={hierarchyConstants.hierarchyLayoutIcon} color={"var(--primary)"} /> Hierarchy
              </>
            ) : row.rootHierarchy ? (
              <>
                <LucideIcon name={hierarchyConstants.childIcon} color={"var(--primary)"} /> Child
                {row.parentTypes?.slice(-1).pop()?.name ? (
                  <>
                    {" "}
                    of <LucideIcon name={entityConstants.icon} color={"var(--primary)"} />{" "}
                    {row.parentTypes?.slice(-1).pop()?.name}{" "}
                  </>
                ) : (
                  ""
                )}
              </>
            ) : (
              <>
                <LucideIcon name={hierarchyConstants.flatLayoutIcon} color={"var(--primary)"} /> Flat
              </>
            )}
          </div>
        ),
        minWidth: 150,
        width: 300,
        align: "left",
      },
      {
        id: "default-entityType",
        Header: fieldLabels.entityType,
        accessor: (row) => (
          <div className="flex row-nowrap align-center gap-5">
            <CustomTypeEntityTypeOptionToIcon entityType={row.entityType} color={"var(--primary)"} /> {row.entityType}
          </div>
        ),
        width: 200,
        align: "left",
      },
      {
        id: "default-createdOn",
        Header: fieldLabels.createdOn,
        accessor: (row) => <DateTimeRenderer date={row.createdOn} includeElapsed={false} />,
        width: 200,
        align: "left",
        sortingFn: (id) => {
          if (sort.headerId === id) {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "CREATED_ON_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "CREATED_ON_ASC" }));
            }
          } else {
            setSort({ headerId: id, sortDirection: "ASC", orderBy: "CREATED_ON_ASC" });
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
      {
        id: "default-createdBy",
        Header: fieldLabels.createdBy,
        accessor: (row) => <GetPersons persons={row.createdBy} createLinks={false} />,
        width: 200,
        align: "left",
        sortingFn: (id) => {
          if (sort.headerId === id) {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "CREATED_BY_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "CREATED_BY_ASC" }));
            }
          } else {
            setSort({ headerId: id, sortDirection: "ASC", orderBy: "CREATED_BY_ASC" });
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
      {
        id: "default-modifiedOn",
        Header: fieldLabels.modifiedOn,
        accessor: (row) => <DateTimeRenderer date={row.modifiedOn} includeElapsed={false} />,
        width: 200,
        align: "left",
        sortingFn: (id) => {
          if (sort.headerId === id) {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "MODIFIED_ON_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "MODIFIED_ON_ASC" }));
            }
          } else {
            setSort({ headerId: id, sortDirection: "ASC", orderBy: "MODIFIED_ON_ASC" });
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
      {
        id: "default-modifiedBy",
        Header: fieldLabels.modifiedBy,
        accessor: (row) => <GetPersons persons={row.modifiedBy} createLinks={false} />,
        width: 200,
        align: "left",
        sortingFn: (id) => {
          if (sort.headerId === id) {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "MODIFIED_BY_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "MODIFIED_BY_ASC" }));
            }
          } else {
            setSort({ headerId: id, sortDirection: "ASC", orderBy: "MODIFIED_BY_ASC" });
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
    ];

    return headers;
  }, [
    entityConstants.icon,
    fieldLabels.createdBy,
    fieldLabels.createdOn,
    fieldLabels.description,
    fieldLabels.entityType,
    fieldLabels.id,
    fieldLabels.modifiedBy,
    fieldLabels.modifiedOn,
    fieldLabels.name,
    setSort,
    sort.headerId,
    sort.sortDirection,
  ]);
  return { columns };
};
export const CustomTypesTable = ({
  entityApi,
  entityConstants,
  fieldLabels,
  permissions,
  routes,
}: EntityTableProps<"customTypes">) => {
  const history = useHistory();
  const [showModal, setShowModal] = useState(false);
  const {
    bulkRestoreMutationAsync: bulkRestoreCustomField,
    bulkDeleteMutationAsync: deleteCustomField,
    isLoadingBulkRestoreMutation,
    isLoadingBulkDeleteMutation,
  } = entityApi;

  const { defaults, tabStoreDefaults } = useCustomTypesTableDefaults({ fieldLabels });

  const { selection, resultsCount, selectionPermissions, onCountChange, onSelectionChange, onSelectionPermissions } =
    useGenericVirtualizedTable<CustomType>();

  const {
    filters,
    sidebarFilters,
    forcedFilters,
    columnSetting,
    columnWidths,
    customTabs,
    fixedTabs,
    dispatchTabStore,
    currentTab,
    tabsLoading,
    tabsModified,
  } = useTabStore<CustomType, CustomTypeFilters, CustomTypeFilterForm>({
    resource: entityConstants.resource,
    defaults: tabStoreDefaults,
  });

  const { functionRef, onTabChange, sort, setSort, searchValue, setSearchValue } = useGenericVirtualizedTableTabs({
    tabsLoading,
    filters,
    switchSortState: switchCustomTypesDefaultSortState,
    dispatchTabStore,
  });

  const { columns } = useCustomTypesTableColumns({ entityConstants, fieldLabels, sort, setSort });

  const memoizedFilters = useMemo(
    () => ({ ...filters, ...forcedFilters, includeRelations: false }),
    [filters, forcedFilters]
  );

  return (
    <TableView>
      <TableView.Head>
        <TableView.Head.Label>
          <TableViewLabel entityConstants={entityConstants} resultsCount={resultsCount} />
        </TableView.Head.Label>
        <TableView.Head.Controls>
          <button
            className="btn btn-primary"
            title={`Add ${entityConstants.entitySingular}`}
            onClick={() => {
              if (currentTab?.includes("entity-type")) {
                history.push(`${routes.getAddRoute}?entityType=${currentTab.replace("entity-type-", "")}`);
              } else {
                history.push(routes.getAddRoute);
              }
            }}
            disabled={!permissions.canCreate || currentTab === "trash"}
          >
            <LucideIcon name="plus" /> Add{" "}
            {currentTab?.includes("entity-type")
              ? `${currentTab.replace("entity-type-", "").toLowerCase()} type`
              : entityConstants.entitySingular}
          </button>
        </TableView.Head.Controls>
      </TableView.Head>
      <TableView.Body>
        <TableView.Body.Sidebar>
          <CustomTypesFilterBar
            initialValues={sidebarFilters}
            dispatchTabStore={dispatchTabStore}
            tabsLoading={tabsLoading}
            currentTab={currentTab}
          />
        </TableView.Body.Sidebar>
        <TableView.Body.Content>
          <EntityTableTabs
            currentTab={currentTab}
            onTabChange={onTabChange}
            fixedTabs={fixedTabs}
            filters={filters}
            sidebarFilters={sidebarFilters}
            columnSetting={columnSetting}
            columnWidths={columnWidths}
            customTabs={customTabs}
            dispatchTabStore={dispatchTabStore}
            tabsLoading={tabsLoading}
            tabsModified={tabsModified}
          />
          <EntityTable>
            <EntityTable.Controls
              style={{
                borderTop: "0px",
                borderRadius: "0px",
              }}
            >
              <EntityFilterIndicator<CustomType, CustomTypeFilters>
                filters={filters}
                excludeFilters={{
                  entityTypes: (value) => (currentTab?.includes("entity-type") ? false : !!value),
                  includeRelations: (value) => false,
                }}
                translatorConsts={CustomTypeFiltersTranslator}
              />
              <SearchInput searchValue={searchValue} setSearchValue={setSearchValue} placeholder="Search" />
              <EntityTableEditButton
                entityConstants={entityConstants}
                routes={routes}
                permissions={permissions}
                selection={selection}
                selectionPermissions={selectionPermissions}
              />
              <MoreDropdown drop="right" btn="btn btn-ghost-secondary">
                <li>
                  <EntityTableCloneButton
                    entityConstants={entityConstants}
                    routes={routes}
                    permissions={permissions}
                    selection={selection}
                  />
                </li>
                {currentTab === "trash" && (
                  <li>
                    <EntityTableRestoreButton
                      entityConstants={entityConstants}
                      selection={selection}
                      permissions={permissions}
                      selectionPermissions={selectionPermissions}
                      onClick={async () => await bulkRestoreCustomField({ ids: Array.from(selection) }).catch(() => {})}
                      loading={isLoadingBulkRestoreMutation}
                    />
                  </li>
                )}
                <li>
                  <EntityTableSoftDeletableButton
                    currentTab={currentTab}
                    entityConstants={entityConstants}
                    selection={selection}
                    permissions={permissions}
                    selectionPermissions={selectionPermissions}
                    onClick={() => setShowModal(true)}
                  />
                </li>
              </MoreDropdown>
              <AlertModal
                type={`${currentTab === "trash" ? "danger" : "warning"}`}
                showModal={showModal}
                setShowModal={setShowModal}
                title={`${
                  selection.size === 0
                    ? `Select dataset to ${currentTab === "trash" ? "delete" : "trash"}`
                    : selection.size === 1
                    ? `${currentTab === "trash" ? "Delete" : "Trash"} the selected ${entityConstants.entitySingular}?`
                    : `${currentTab === "trash" ? "Delete" : "Trash"} the selected ${entityConstants.entityPlural}?`
                }`}
                description={`${
                  currentTab === "trash"
                    ? `Proceeding will permanently delete the selected ${entityConstants.entityPlural}.`
                    : `Proceeding will move the selected ${entityConstants.entityPlural} into trash.`
                }`}
                proceedLabel={`${currentTab === "trash" ? "Delete" : "Trash"}`}
                onProceed={async () => {
                  await deleteCustomField({
                    ids: Array.from(selection),
                    goBackOnSuccess: false,
                    showToast: false,
                    entityName: entityConstants.resource,
                    params: { deletePermanently: currentTab === "trash" },
                  }).catch((e) => {});
                  functionRef.current?.resetSelection();
                  setShowModal(false);
                }}
                loading={isLoadingBulkDeleteMutation}
              />
            </EntityTable.Controls>
            <EntityTable.Body<CustomType, CustomTypeFilters>
              functionRef={functionRef}
              entityConstants={entityConstants}
              filters={memoizedFilters}
              columns={columns}
              columnSelect
              columnSetting={columnSetting}
              columnWidths={columnWidths}
              defaultColumnSettings={defaults}
              dispatchTabStore={dispatchTabStore}
              setResultsCount={onCountChange}
              onSelectionChange={onSelectionChange}
              onSelectionPermissions={onSelectionPermissions}
              showPermissionColumn
              loading={tabsLoading}
            />
          </EntityTable>
        </TableView.Body.Content>
      </TableView.Body>
    </TableView>
  );
};
