import React, { useEffect, useState } from "react";
import { DecoratedStringListInput } from "../../../ViewerUIElements/Range/DecoratedStringListInput";

export const StringListInput = ({
  parameterName,
  value,
  setParameter,
}: {
  parameterName: string;
  value: string[];
  setParameter?: (parameterName: string, parameters: Record<string, any>) => void;
}) => {
  const [userValue, setUserValue] = useState<string[]>(value);

  useEffect(() => {
    if (setParameter) setParameter(parameterName, { value: userValue });
  }, [userValue]);

  return <DecoratedStringListInput value={value} setValue={setUserValue} />;
};
