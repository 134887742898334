import React, { CSSProperties, useCallback } from "react";
import { IPermissionedEntity, ISoftDeletable } from "../../api/GenericTypes";
import { EntityFormProps, FormEventTypes, SingleEntityRoutingParams } from "./EntityInterfaces";
import { useHistory, useParams } from "react-router-dom";
import { useEntityApi } from "../../api/useEntityApi";
import { useEntityDetail } from "../../api/BaseEntityApi";
import { TrashedNotEditable } from "../misc/UIconstants";
import { FormLayoutContainer } from "../forms/FormLayout";
import { LoadingWrapper } from "../LoadingWrapper";

import {
  EntityByEntityTypeId,
  FiltersByEntityTypeId,
  GenericEntityConstantsEntities,
  getEntityConstants,
} from "../../api/GenericConstants";
import { useEntityPermissions } from "../permissions/useEntityPermissions";
import { PageNotAllowed } from "../../main/common/PageNotAllowed/PageNotAllowed";

interface EntityEditFormProps<EntityTypeId extends GenericEntityConstantsEntities> {
  id: EntityByEntityTypeId<EntityTypeId>["id"];
  entityTypeId: EntityTypeId;
  filters?: FiltersByEntityTypeId<EntityTypeId>;
  event?: FormEventTypes;
  style?: CSSProperties;
  onSuccess?: (entity: EntityByEntityTypeId<EntityTypeId>) => void;
  onCancel?: () => void;
  children: (props: EntityFormProps<EntityTypeId>) => React.ReactNode;
}
export const EntityEditForm = <EntityTypeId extends GenericEntityConstantsEntities>({
  id,
  entityTypeId,
  filters = {} as FiltersByEntityTypeId<EntityTypeId>,
  event = "EDIT",
  style,
  onSuccess,
  onCancel,
  children,
}: EntityEditFormProps<EntityTypeId>) => {
  const history = useHistory();
  const { entityConstants, fieldLabels } = getEntityConstants(entityTypeId);

  type EntityType = EntityByEntityTypeId<EntityTypeId>;
  type FilterType = FiltersByEntityTypeId<EntityTypeId>;
  const permissions = useEntityPermissions({ entityTypeId });
  const { canEdit } = permissions;

  const { editMutationAsync, isLoadingEditMutation } = useEntityApi<EntityType>(entityConstants.resource);

  const edit = useCallback(
    async (entity: Partial<EntityType>) => {
      await editMutationAsync(
        { id: id, body: entity as EntityType },
        { onSuccess: (entity) => (onSuccess ? onSuccess(entity) : history.goBack()) }
      ).catch(() => {});
    },
    [editMutationAsync, history, id, onSuccess]
  );

  const { data, status, fetchStatus, error } = useEntityDetail<EntityType, FilterType>(entityConstants.resource, id, {
    ...filters,
  });

  return (
    <LoadingWrapper status={status} fetchStatus={fetchStatus} error={error}>
      {data && (
        <>
          {!canEdit(data) ? (
            <PageNotAllowed />
          ) : Object.hasOwn(data, "permissions") && !(data as Partial<IPermissionedEntity>).permissions?.edit ? (
            <PageNotAllowed />
          ) : Object.hasOwn(data, "isDeleted") && (data as Partial<ISoftDeletable>).isDeleted ? (
            TrashedNotEditable
          ) : (
            <FormLayoutContainer style={style}>
              {children({
                fieldLabels,
                permissions,
                title: `Edit ${entityConstants.entitySingular}`,
                subtitle: "",
                onCancel: onCancel || history.goBack,
                submitButtonLabel: "Save changes",
                onSubmit: edit,
                loading: isLoadingEditMutation,
                initialValues: data,
                event: event,
              })}
            </FormLayoutContainer>
          )}
        </>
      )}
    </LoadingWrapper>
  );
};

interface EntityEditFormPageProps<EntityTypeId extends GenericEntityConstantsEntities> {
  isIntId?: boolean;
  style?: CSSProperties;
  children: (id: EntityByEntityTypeId<EntityTypeId>["id"]) => React.ReactNode;
}

export const EntityEditFormPage = <EntityTypeId extends GenericEntityConstantsEntities>({
  isIntId = true,
  style,
  children,
}: EntityEditFormPageProps<EntityTypeId>) => {
  const { id } = useParams<SingleEntityRoutingParams>();
  let _id = isIntId ? parseInt(id) : id;

  return (
    <div className="center-horizontally" style={style}>
      {children(_id as EntityByEntityTypeId<EntityTypeId>["id"])}
    </div>
  );
};
