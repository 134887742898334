import { produce } from "immer";
import { useCallback, useEffect, useState } from "react";
import { Range } from "react-range";
import { useInputDebounce } from "../../ViewerUIElements/InputDebounce";
import { equidistantContourState } from "../ContourClasses";
import { getDigit, RangeType, ToolInput } from "./ContourEditor";

import { FormControl } from "react-bootstrap";
import { ColorBox } from "../../ViewerUIElements/ColorBox";
import styles from "./EquidistantContourEditor.module.css";
import { SliderThumbBlock, SliderTrack } from "./SliderElements";

type ContourType = {
  count: number;
  min: number;
  max: number;
  range: RangeType;
};

const ContourNeedsUpdate = (target: ContourType, source: ContourType) => {
  return (
    Math.abs(target.range.min - source.range.min) > Number.EPSILON ||
    Math.abs(target.range.max - source.range.max) > Number.EPSILON ||
    target.count !== source.count ||
    Math.abs(target.min - source.min) > Number.EPSILON ||
    Math.abs(target.max - source.max) > Number.EPSILON
  );
};

const getOffsetsFromContours = (contours: ContourType): number[] => {
  if (!contours) return [];

  const offset = (v: number) => (v - contours.range.min) / contours.range.diff;

  return [offset(contours.min), offset(contours.max)];
};

const getContoursFromOffsets = (offsets: number[], range: RangeType): { min: number; max: number } => {
  const contour = (v: number) => v * range.diff + range.min;
  return {
    min: contour(offsets[0]),
    max: contour(offsets[1]),
  };
};

export const EquidistantContourEditor = ({
  width,
  color,
  contours,
  valueMin,
  valueMax,
  setContours,
  setMinHeight,
  setMinWidth,
}: ToolInput) => {
  const [minMaxStr, setMinMaxStr] = useState<string[]>(["", ""]);
  const [values, setValues] = useState<number[]>([1]);
  const [count, setCount] = useState<number[]>([]);
  const [countInput, setCountInput] = useState<string>("");
  const [countValid, setCountValid] = useState<boolean>(true);
  const [contourLines, setContourLines] = useState<ContourType>({
    count: 0,
    min: 0,
    max: 0,
    range: {
      min: -Infinity,
      max: Infinity,
      diff: Infinity,
    },
  });
  const debouncedContour = useInputDebounce<ContourType>(contourLines, 300);
  const maxCount = 30;

  useEffect(() => {
    if (setMinHeight) setMinHeight(190);
    if (setMinWidth) setMinWidth(400);
  }, []);

  useEffect(() => {
    // console.log("debounce contour", ArrayNeedsUpdate(contours, debouncedContour.contours));

    if (debouncedContour.count > 0) {
      const state: equidistantContourState = {
        count: debouncedContour.count,
        min: debouncedContour.min,
        max: debouncedContour.max,
        type: contours.state.type,
      };
      setContours({ generate: contours.generate, state: state });
    }
  }, [debouncedContour]);

  useEffect(() => {
    const state = contours.state as equidistantContourState;

    const newState: ContourType = {
      count: state.count,
      range: {
        min: valueMin,
        max: valueMax,
        diff: valueMax - valueMin,
      },
      min: state?.min || valueMin,
      max: state?.max || valueMax,
    };

    if (ContourNeedsUpdate(contourLines, newState)) {
      setContourLines(newState);
      setValues(getOffsetsFromContours(newState));
      setCount([newState.count]);
      const digit = getDigit(newState.range.diff);
      const rangeStr = [newState.range.min.toFixed(digit), newState.range.max.toFixed(digit)];
      if (rangeStr.some((r, i) => r !== minMaxStr[i])) setMinMaxStr(rangeStr);
    }
  }, [contours, valueMin, valueMax]);

  useEffect(() => {
    const r = getContoursFromOffsets(values, contourLines.range);

    const newState: ContourType = produce(contourLines, (next) => {
      next.min = r.min;
      next.max = r.max;
    });

    if (ContourNeedsUpdate(contourLines, newState)) {
      setContourLines(newState);
    }
  }, [values]);

  useEffect(() => {
    if (count[0] === undefined) return;
    const newState: ContourType = produce(contourLines, (next) => {
      next.count = count[0];
    });

    if (ContourNeedsUpdate(contourLines, newState)) {
      // console.log("update", newState);
      setContourLines(newState);
    }
  }, [count]);

  const sliderThumb = useCallback(({ index, props }: { index: number; props: any }) => {
    // console.log("props", index, props["aria-valuenow"], props.style.zIndex);
    // console.log("props", index, props);

    props.style.zIndex = undefined;
    return (
      <div {...props}>
        {/* <div>
          <ColorBox color={gradientColors.colors[index]} width={10} height={10} allowColorChange={true}></ColorBox>
        </div> */}
        {/* <div className={index > 0 ? styles.sliderRight : styles.sliderLeft}>12</div> */}
        <div className={styles.sliderThumbLine} />
        {/* <span className={["glyphicon", "glyphicon-step-forward", styles.sliderThumbBorder].join(" ")} />
        <span className={["glyphicon", "glyphicon-step-forward", styles.sliderThumbBorder].join(" ")} /> */}
      </div>
    );
  }, []);

  const sliderNumberThumb = useCallback(
    ({ index, props }: { index: number; props: any }) => {
      const min = contourLines.min;
      const max = contourLines.max;
      const digit = getDigit(contourLines.range.diff) + 1;

      return (
        <div {...props} className={styles.sliderThumb}>
          <div className={index > 0 ? styles.sliderRight : styles.sliderLeft}>
            {index > 0 ? max?.toFixed(digit) : min?.toFixed(digit)}
          </div>
        </div>
      );
    },
    [contourLines]
  );

  useEffect(() => {
    setCountInput("" + count[0]);
    setCountValid(true);
  }, [count]);

  useEffect(() => {
    let i = parseInt(countInput);
    if (i > 0 && i <= maxCount) {
      if (i !== count[0]) {
        setCount([i]);
      }
      setCountValid(true);
      setCountInput("" + i);
    } else setCountValid(false);
  }, [countInput]);

  return (
    <div style={{ width: width, paddingTop: 10 }}>
      <Range
        step={0.001}
        min={0}
        // disabled={disabled}
        max={1}
        values={values}
        // values={values.length < 1 ? [0, 1] : values}
        renderTrack={({ props, children }: { props: any; children: any }) => {
          // return <div>{children}</div>;
          return (
            <div {...props}>
              {children}
              <div style={{ width: width }} />
            </div>
          );
        }}
        // onChange={setValues(v)}
        onChange={(v: number[]) => {}}
        renderThumb={sliderNumberThumb}
      />

      <Range
        step={0.001}
        min={0}
        // disabled={disabled}
        max={1}
        values={values}
        // values={values.length < 1 ? [0, 1] : values}
        renderTrack={({ props, children }: { props: any; children: any }) => {
          // return <div>{children}</div>;
          return (
            <div {...props}>
              {children}
              <div style={{ height: 2 }} />
              <ColorBox color={color} width={width} height={10} />
            </div>
          );
        }}
        // onChange={setValues(v)}
        onChange={(v: number[]) => setValues(v)}
        renderThumb={sliderThumb}
      />
      <div className={styles.range}>
        <span>{minMaxStr[0]}</span>
        <span>{minMaxStr[1]}</span>
      </div>
      <div style={{ height: 10 }} />
      <div className={styles.rangeSetter}>
        <div style={{ flexGrow: 1, minWidth: 30 }}>
          <Range
            step={1}
            min={1}
            // disabled={disabled}
            max={maxCount}
            values={count.length !== 1 ? [1] : count}
            renderTrack={SliderTrack}
            onChange={setCount}
            renderThumb={SliderThumbBlock}
          />
        </div>
        <div style={{ paddingLeft: 5 }}>
          <FormControl
            type="text"
            value={countInput}
            onChange={(e) => setCountInput((e.target as HTMLInputElement).value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") setCountInput((e.target as HTMLInputElement).value);
            }}
            onFocus={(e) => (e.target as any as HTMLInputElement).select()}
            bsSize={"sm"}
            style={{ width: "4em", scale: "90%", background: countValid ? undefined : "salmon" }}
          />
        </div>
      </div>
    </div>
  );
};
