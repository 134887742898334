import { StringIndexedDict } from "../../../../api/GenericTypes";
import {
  GenericEntityVirtualizedEntitySelectFormFieldProps,
  GenericVirtualizedEntitySelectFormFieldControllerWrapper,
} from "../../../formfields/GenericVirtualizedSelectFormField/GenericVirtualizedEntitySelectFormFieldControllerWrapper";

import {
  ExperimentFilters,
  ExperimentSortingOptionsConsts,
  ExperimentSuggestions,
  experimentsConstants,
} from "../../../../api/Experiments";
import { QuickAddWrapper } from "../../QuickAdd/common/QuickAddWrapper";
import { QuickAddExperimentForm } from "../../QuickAdd/forms/QuickAddExperimentForm";
import { useContext } from "react";
import { SessionContext } from "../../../contexts/SessionContext";

export const ExperimentsVirtualizedSelectForm = <Entity extends StringIndexedDict>(
  props: GenericEntityVirtualizedEntitySelectFormFieldProps<Entity, ExperimentSuggestions, ExperimentFilters>
) => {
  const { session } = useContext(SessionContext);

  return (
    <GenericVirtualizedEntitySelectFormFieldControllerWrapper<Entity, ExperimentSuggestions, ExperimentFilters>
      {...props}
      filters={{ orderBy: "NAME_ASC", ...props.filters }}
      //   onRowRenderer={(item) => <></>}
      createModal={({ showModal, setShowModal, onCreate }) => (
        <QuickAddWrapper showModal={showModal} setShowModal={setShowModal} onCreate={onCreate}>
          {(quickAddProps) => <QuickAddExperimentForm {...quickAddProps} />}
        </QuickAddWrapper>
      )}
      entityConstants={experimentsConstants}
      orderByOptions={ExperimentSortingOptionsConsts}
      allowCreateEntity={session?.permissions.administer_experiments && props.allowCreateEntity}
    />
  );
};
