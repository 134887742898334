import React, { memo, useCallback, useState } from "react";
import { MultiEditSelectControlledProps, MultiEditSelectProps } from "../common/MultiEditSelectFormTypes";
import { FieldError, Path } from "react-hook-form";
import {
  IEntityMinimalModel,
  IGenericRequestParameters,
  IOwnedEntityParameters,
  ISoftDeletable,
  IdTypes,
  StringIndexedDict,
} from "../../../../api/GenericTypes";
import { MultiEditFormWrapper } from "../common/MultiEditFormWrapper";
import { checkIsDirty } from "../common/MultiEditUtils";
import { GenericVirtualizedEntitySelectFormFieldControllerWrapper } from "../../../formfields/GenericVirtualizedSelectFormField/GenericVirtualizedEntitySelectFormFieldControllerWrapper";

const MultiEditSelect = <
  Entity extends StringIndexedDict,
  SuggestionsEntity extends Omit<IEntityMinimalModel<IdTypes>, "version"> & Partial<ISoftDeletable>,
  SuggestionsEntityFilters extends StringIndexedDict &
    IOwnedEntityParameters &
    IGenericRequestParameters<SuggestionsEntity, SuggestionsEntityFilters["orderBy"]>
>({
  control,
  formState,
  getFieldState,
  row,
  id,
  disabled,
  ...rest
}: MultiEditSelectControlledProps<Entity, SuggestionsEntity, SuggestionsEntityFilters>) => {
  const [_isError, setIsError] = useState<FieldError | undefined>();
  const [_isDirty, setIsDirty] = useState<boolean>(false);

  const onBlur = useCallback(() => {
    setIsDirty(checkIsDirty((formState.dirtyFields as any)?.[row.id]?.[id]));
    setIsError(getFieldState(`${row.id}.${id}` as Path<Entity>, formState).error);
  }, [formState, getFieldState, id, row.id]);

  const isDirty = checkIsDirty((formState.dirtyFields as any)?.[row.id]?.[id]) ?? _isDirty;
  const isError = getFieldState(`${row.id}.${id}` as Path<Entity>, formState).error ?? _isError;
  return (
    <GenericVirtualizedEntitySelectFormFieldControllerWrapper<Entity, SuggestionsEntity, SuggestionsEntityFilters>
      {...rest}
      id={`${row.id}.${id}` as Path<Entity>}
      control={control}
      selectFieldStyle={{
        cursor: disabled ? "not-allowed " : "pointer",
        background: isError
          ? "var(--danger-light)"
          : isDirty
          ? "var(--success-light)"
          : disabled
          ? "var(--gray-100)"
          : "var(--white)",
        pointerEvents: "all",
        margin: 0,
        width: "100%",
        height: "100%",
        border: "1px",
        borderStyle: "solid",
        borderColor: disabled ? "transparent" : isDirty ? "var(--success)" : "transparent",
      }}
      hasLabel={false}
      required={false}
      onBlur={onBlur}
      disabled={disabled}
      uncontained
    />
  );
};

const MemoComponent = memo(
  MultiEditSelect,
  (prevProps, nextProps) =>
    checkIsDirty(prevProps.formState.dirtyFields?.[prevProps.row.id]?.[prevProps.id]) ===
    checkIsDirty(nextProps.formState.dirtyFields?.[nextProps.row.id]?.[nextProps.id])
) as typeof MultiEditSelect;

export const MultiEditSelectForm = <
  Entity extends StringIndexedDict,
  SuggestionsEntity extends Omit<IEntityMinimalModel<IdTypes>, "version"> & Partial<ISoftDeletable>,
  SuggestionsEntityFilters extends StringIndexedDict &
    IOwnedEntityParameters &
    IGenericRequestParameters<SuggestionsEntity, SuggestionsEntityFilters["orderBy"]>
>(
  props: MultiEditSelectProps<Entity, SuggestionsEntity, SuggestionsEntityFilters>
) => {
  return (
    <MultiEditFormWrapper<Entity>>{(formProps) => <MemoComponent {...formProps} {...props} />}</MultiEditFormWrapper>
  );
};
