import React, { useEffect } from "react";
import { useManualUpload } from "./useManualUpload";
import { Button } from "../../../common/buttons/Button/Button";
import { v4 as uuidv4 } from "uuid";
import { StringIndexedDict } from "../../../api/GenericTypes";
import { progressBar } from "../components/common/RenderDatasetUtils";
import { useCallbackRef } from "../../../common/helperfunctions/useCallbackRef";
import {
  GenericVirtualizedEntitySelectFormFieldCore,
  GenericVirtualizedSelectFormFieldProps,
  GenericVirtualizedSelectFormFieldValueType,
} from "../../../common/formfields/GenericVirtualizedSelectFormField/GenericVirtualizedSelectFormField";
import { getError } from "../../../common/formfields/GenericVirtualizedSelectFormField/GenericVirtualizedEntitySelectFormFieldControllerWrapper";
import { LucideIcon } from "../../../common/icon/LucideIcon";
import { Dataset, DatasetFilters, datasetsConstants } from "../../../api/Datasets";
import { useGetMutation } from "../../../api/BaseEntityApi";
import { FormGroup } from "react-bootstrap";
import { FormFieldLayout, FormFieldError } from "../../../common/formfields/FormFieldLayouts";
import { GenericControllerProvider } from "../../../common/forms/common/GenericControllerProvider";
import { FieldErrors, useForm, useWatch } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import useUnsavedChangesWarning from "../../../common/helperfunctions/useUnsavedChangesWarning";
import { useManualUploadDragAndDropHandlers } from "../useManualUploadDropArea";
import styles from "./styles.module.css";

export const ManualUploadInlineWrapper = <
  Entity extends StringIndexedDict,
  T extends GenericVirtualizedSelectFormFieldValueType
>(
  props: GenericVirtualizedSelectFormFieldProps<Entity, T>
) => {
  return (
    <GenericControllerProvider id={props.id} control={props.control}>
      {({ value, onChange }, fieldState, { errors }) => {
        if (value && typeof value === "object" && Object.hasOwn(value, "id"))
          return <ManualUploadInlineForm {...props} />;
        return <ManualUploadInlineCreateForm {...props} errors={errors} onChange={onChange} />;
      }}
    </GenericControllerProvider>
  );
};

interface FileElement {
  id: string;
  name: string;
  file: File;
}
interface FileListForm {
  fileList: FileElement[] | null;
}

interface ManualUploadInlineCreateFormProps<
  Entity extends StringIndexedDict,
  T extends GenericVirtualizedSelectFormFieldValueType
> extends GenericVirtualizedSelectFormFieldProps<Entity, T> {
  onChange: (...event: any[]) => void;
  errors: FieldErrors<Entity>;
}

const ManualUploadInlineCreateForm = <
  Entity extends StringIndexedDict,
  T extends GenericVirtualizedSelectFormFieldValueType
>(
  props: ManualUploadInlineCreateFormProps<Entity, T>
) => {
  const reqSchema = yup.object().shape({
    fileList: yup
      .array()
      .min(1)
      .of(
        yup.object().shape({
          id: yup.string().required(),
          name: yup.string().required(),
          file: yup.mixed().required(),
        })
      ),
  });

  const { control, setValue } = useForm<FileListForm>({
    defaultValues: { fileList: [] },
    resolver: yupResolver(reqSchema),
  });
  const { ref: dirInputRef, refObject: dirInputRefObject } = useCallbackRef<HTMLInputElement>();
  const { ref: multiFileInputRef, refObject: multiFileInputRefObject } = useCallbackRef<HTMLInputElement>();
  const files = useWatch({ control, name: "fileList" });

  const { progress, loadingStatus, uploadFileList, uploadedDatasets, clearUpload, readDataTransfer, dispatch } =
    useManualUpload({
      viewableEntityType: "CustomField",
      whiteList: ["doc_multi"],
    });
  const { drag, dragging, handleDragEnter, handleDragLeave, handleDragOver, handleDrop } =
    useManualUploadDragAndDropHandlers({ dispatch, readDataTransfer });
  const { mutate } = useGetMutation<Dataset, DatasetFilters>(datasetsConstants.resource);
  const { onPristine, onDirty, routerPrompt } = useUnsavedChangesWarning(
    "You have unsaved uploads. Are you sure you want to leave this page?"
  );
  // const handleDrop = useCallback(
  //   async (e: React.DragEvent) => {
  //     e.preventDefault();
  //     // e.persist();
  //     e.stopPropagation();
  //     setDragging(false);
  //     const dataTransfer = e.dataTransfer;
  //     // console.log("DATA transfer", dataTransfer, dataTransfer?.items.length);
  //     if (dataTransfer) {
  //       dispatch({ type: "clear" });
  //       // await readDataTransfer(dataTransfer);
  //       const items = Array.from(dataTransfer.items);
  //       const _files = [];
  //       for (let i = 0; i < items.length; i++) {
  //         const file = items[i];
  //         _files.push(file);
  //       }
  //       //
  //       if (dataTransfer.types.includes("Files")) {
  //         const filesResult = await Promise.all(
  //           Object.entries(_files).map(([, item], index) => {
  //             return readDataTransferItem(item);
  //           })
  //         );
  //         const files = filesResult.reduce((a, b) => a.concat(b), []);
  //         const _value = [
  //           ...((getValues("fileList") as any) ?? []),
  //           ...files.map((f) => ({
  //             id: f.id,
  //             name: f.name,
  //             file: f,
  //           })),
  //         ];
  //         setValue("fileList", _value);
  //         console.log("ITEMS", files);
  //       }
  //     }
  //   },
  //   [dispatch, getValues, readDataTransferItem, setDragging, setValue]
  // );

  useEffect(() => {
    if (uploadedDatasets && progress === 100) {
      const files = Object.values(uploadedDatasets);
      if (files.length > 0) {
        if (!files[0].datasets[0].name && files[0].datasets[0].datasetId) {
          mutate(
            { id: files[0].datasets[0].datasetId },
            {
              onSuccess: (data) => {
                props.onChange({
                  id: files[0].datasets[0].datasetId,
                  name: data.name || "N/A",
                });
              },
            }
          );
        } else {
          props.onChange({
            id: files[0].datasets[0].datasetId,
            name: files[0].datasets[0].name || "N/A",
          });
        }
        setValue("fileList", null);
        clearUpload();
        onPristine();
      }
    }
  }, [clearUpload, mutate, onPristine, progress, props, setValue, uploadedDatasets]);

  useEffect(() => {
    if (Array.isArray(files) && files.length > 0) {
      onDirty();
    }
  }, [files, onDirty]);

  return (
    <GenericControllerProvider<FileListForm> id={"fileList"} control={control}>
      {({ value, onChange }) => (
        <FormFieldLayout
          id={"fileList"}
          label={props.label ?? ""}
          required={props.required}
          horizontal={props.horizontal}
          hasLabel={props.uncontained ? false : props.hasLabel}
        >
          <div
            className={`${styles.drop_area} ${dragging && styles.drop_area_active} `}
            style={{ alignItems: "stretch" }}
            onDrop={handleDrop}
            onDragOver={handleDragOver}
            onDragEnter={handleDragEnter}
            onDragLeave={handleDragLeave}
          >
            {routerPrompt}
            {dragging && <div className={styles.drag_overlay} ref={drag} />}
            {dragging ? (
              <div className="flex row-nowrap align-center justify-center gap-5" style={{ height: 69 }}>
                <div className={styles.drop_icon} style={{ color: "var(--gray-500)" }}>
                  <LucideIcon name="folder-plus" />
                </div>
                <h1 style={{ margin: 0, color: "var(--gray-500)" }}>Drop files</h1>
              </div>
            ) : (
              <>
                <GenericVirtualizedEntitySelectFormFieldCore<FileElement>
                  value={value as any}
                  items={((value ?? []) as any[]).map((f: any) => ({ id: f.id, name: f.file.name })) as any[]}
                  onChange={onChange}
                  onBlur={props.onBlur}
                  error={getError(props.id, props.errors)}
                  placeholder={props.placeholder || "Select files or drag & drop here"}
                  isMulti={true}
                  disabled={props.disabled}
                  // allowCreateEntity={props.allowCreateEntity}
                  onRowStartRenderer={(row) => (
                    <>
                      <LucideIcon name="file" />{" "}
                    </>
                  )}
                  //  onRowEndRenderer={props.onRowEndRenderer}
                  selectFieldStyle={props.selectFieldStyle}
                  //  concatItems={props.concatItems}
                  // resource={props.resource}
                  // itemsFromOptions={props.itemsFromOptions}
                />

                <div className="flex row-nowrap align-center gap-5">
                  <div
                    className="flex col-nowrap gap-5"
                    style={{ width: "100%", height: "min-content", overflow: "hidden" }}
                  >
                    {progress !== 100 && loadingStatus && !loadingStatus.errors ? (
                      progressBar(loadingStatus)
                    ) : (
                      <>
                        <Button
                          className="btn btn-sm btn-default"
                          title={"Upload single or multiple files"}
                          name="upload"
                          onClick={() => multiFileInputRefObject.current?.click()}
                          loading={!!progress ? progress > 0 && progress < 100 : false}
                        >
                          <LucideIcon name="file-up" /> Select files
                        </Button>
                        <Button
                          className="btn btn-sm btn-default"
                          title={"Upload directory"}
                          name="upload"
                          onClick={() => dirInputRefObject.current?.click()}
                          loading={!!progress ? progress > 0 && progress < 100 : false}
                        >
                          <LucideIcon name="folder-up" /> Select directory
                        </Button>
                        <input
                          id="selectDir"
                          ref={dirInputRef}
                          hidden
                          type="file"
                          multiple
                          /* @ts-expect-error */
                          directory=""
                          webkitdirectory=""
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                          onChange={async (e) => {
                            if (e.target.files) {
                              const _value = [
                                ...((value as any) ?? []),
                                ...(Array.from(e.target.files ?? []) as File[]).map((f) => ({
                                  id: uuidv4(),
                                  name: f.name,
                                  file: f,
                                })),
                              ];
                              onChange(_value);
                            }
                          }}
                          style={{ display: "none" }}
                        />
                        <input
                          id="selectFiles"
                          ref={multiFileInputRef}
                          hidden
                          type="file"
                          multiple
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                          onChange={async (e) => {
                            if (e.target.files) {
                              const _value = [
                                ...((value as any) ?? []),
                                ...(Array.from(e.target.files ?? []) as File[]).map((f) => ({
                                  id: uuidv4(),
                                  name: f.name,
                                  file: f,
                                })),
                              ];
                              onChange(_value);
                            }
                          }}
                          style={{ display: "none" }}
                        />
                      </>
                    )}
                  </div>
                  <Button
                    className="btn btn-primary"
                    onClick={async () => {
                      if (Array.isArray(files) && !!files.length) {
                        clearUpload();
                        const actionUUID = uuidv4();
                        await uploadFileList(
                          files.map((f) => f.file),
                          actionUUID
                        );
                      } else {
                        onChange(null);
                      }
                    }}
                    loading={!!progress ? progress > 0 && progress < 100 : false}
                    disabled={props.disabled || !files || (Array.isArray(files) && !files.length)}
                  >
                    <LucideIcon name="upload" /> Upload
                  </Button>
                </div>
              </>
            )}
          </div>
          <FormFieldError id={props.id} errors={props.errors} />
        </FormFieldLayout>
      )}
    </GenericControllerProvider>
  );
};

/**
 * Component for rendering a manual upload inline form with a virtualized select field.
 *
 * @template Entity - The type of the entity, extending `StringIndexedDict`.
 * @template T - The type of the form field value, extending `GenericVirtualizedSelectFormFieldValueType`.
 *
 * @param {GenericVirtualizedSelectFormFieldProps<Entity, T>} props - The properties for the form field.
 * @returns {JSX.Element} The rendered manual upload inline form component.
 * @author @CorradoSurmanowicz
 * @component
 * @example
 * ```tsx
 * <ManualUploadInlineForm
 *   id="example-id"
 *   control={control}
 *   defaultValue={defaultValue}
 *   items={items}
 *   onBlur={handleBlur}
 *   placeholder="Select an item"
 *   disabled={false}
 *   allowCreateEntity={true}
 *   onRowEndRenderer={rowEndRenderer}
 *   selectFieldStyle={selectFieldStyle}
 *   concatItems={concatItems}
 *   resource={resource}
 *   itemsFromOptions={itemsFromOptions}
 * >
 *   <CustomChildComponent />
 * </ManualUploadInlineForm>
 * ```
 */
export const ManualUploadInlineForm = <
  Entity extends StringIndexedDict,
  T extends GenericVirtualizedSelectFormFieldValueType
>(
  props: GenericVirtualizedSelectFormFieldProps<Entity, T>
) => {
  return (
    <GenericControllerProvider<Entity> id={props.id} control={props.control} defaultValue={props.defaultValue}>
      {({ value, onChange }, fieldState, { errors }) => {
        return (
          <>
            {props.uncontained ? (
              <FormGroup controlId={props.id} style={{ margin: 0, width: "100%", height: "100%" }}>
                <div className="flex" style={{ margin: 0, width: "100%", height: "100%", ...props.style }}>
                  <GenericVirtualizedEntitySelectFormFieldCore
                    value={value}
                    items={props.items}
                    onChange={onChange}
                    onBlur={props.onBlur}
                    error={getError(props.id, errors)}
                    placeholder={props.placeholder}
                    isMulti={false}
                    disabled={props.disabled}
                    allowCreateEntity={props.allowCreateEntity}
                    onRowStartRenderer={(row) => (
                      <>
                        <LucideIcon name="file" />{" "}
                      </>
                    )}
                    onRowEndRenderer={props.onRowEndRenderer}
                    selectFieldStyle={props.selectFieldStyle}
                    concatItems={props.concatItems}
                    resource={props.resource}
                    itemsFromOptions={props.itemsFromOptions}
                  />
                  {/* <ManualUploadInline value={value} onChange={onChange} disabled={props.disabled} /> */}
                  {props.children ? <div className="flex row-nowrap align-center gap-5">{props.children}</div> : <></>}
                </div>
              </FormGroup>
            ) : (
              <FormFieldLayout
                id={props.id}
                label={props.label ?? ""}
                required={props.required}
                horizontal={props.horizontal}
                hasLabel={props.hasLabel}
              >
                <div className={"flex row-nowrap gap-5"} style={{ ...props.style, alignItems: "stretch" }}>
                  <GenericVirtualizedEntitySelectFormFieldCore
                    value={value}
                    items={props.items}
                    onChange={onChange}
                    onBlur={props.onBlur}
                    error={getError(props.id, errors)}
                    placeholder={props.placeholder}
                    isMulti={false}
                    disabled={props.disabled}
                    allowCreateEntity={props.allowCreateEntity}
                    onRowStartRenderer={(row) => (
                      <>
                        <LucideIcon name="file" />{" "}
                      </>
                    )}
                    onRowEndRenderer={props.onRowEndRenderer}
                    selectFieldStyle={props.selectFieldStyle}
                    concatItems={props.concatItems}
                    resource={props.resource}
                    itemsFromOptions={props.itemsFromOptions}
                  />
                  {/* <ManualUploadInline value={value} onChange={onChange} disabled={props.disabled} /> */}

                  {props.children ? <div className="flex row-nowrap align-center gap-5">{props.children}</div> : <></>}
                </div>
                <FormFieldError id={props.id} errors={errors} />
              </FormFieldLayout>
            )}
          </>
        );
      }}
    </GenericControllerProvider>
  );
};

// const generateDatasetNameFallback = () => {
//   const date = new Date();
//   return `Upload_${date.getFullYear()}${date.getMonth()}${date.getDate()}_${date.getHours()}${date.getMinutes()}${date.getSeconds()}`;
// };

// interface ManualUploadInlineProps {
//   value: undefined | null | IEntityMinimalModel<number> | IEntityMinimalModel<number>[];
//   onChange: (value: any) => void;
//   disabled?: boolean;
// }
// const ManualUploadInline = ({ value, onChange, disabled }: ManualUploadInlineProps) => {
//   // const dirInputRef = createRef<HTMLInputElement>();
//   // const fileInputRef = createRef<HTMLInputElement>();
//   const [files, setFiles] = useState<File[]>([]);

//   const { ref: dirInputRef, refObject: dirInputRefObject } = useCallbackRef<HTMLInputElement>();
//   const { ref: multiFileInputRef, refObject: multiFileInputRefObject } = useCallbackRef<HTMLInputElement>();
//   const { progress, loadingStatus, uploadFileList, uploadedDatasets, clearUpload } = useManualUpload({
//     viewableEntityType: "CustomField",
//     whiteList: ["doc_multi"],
//   });
//   const { mutate } = useGetMutation<Dataset, DatasetFilters>(datasetsConstants.resource);
//   useEffect(() => {
//     if (uploadedDatasets && progress === 100) {
//       const files = Object.values(uploadedDatasets);
//       if (files.length > 0) {
//         if (!files[0].datasets[0].name && files[0].datasets[0].datasetId) {
//           mutate(
//             { id: files[0].datasets[0].datasetId },
//             {
//               onSuccess: (data) => {
//                 onChange({
//                   id: files[0].datasets[0].datasetId,
//                   name: data.name || "N/A",
//                 });
//               },
//             }
//           );
//         } else {
//           onChange({
//             id: files[0].datasets[0].datasetId,
//             name: files[0].datasets[0].name || "N/A",
//           });
//         }

//         clearUpload();
//       }
//     }
//   }, [clearUpload, mutate, onChange, progress, uploadedDatasets, value]);

//   return (
//     <div className="flex flex-nowrap align-center gap-5" style={{ padding: 5 }}>
//       {progress !== 100 && loadingStatus && !loadingStatus.errors ? (
//         progressBar(loadingStatus)
//       ) : (
//         <div className="flex col-nowrap gap-5" style={{ width: "100%", height: "min-content", overflow: "hidden" }}>
//           <Button
//             className="btn btn-default"
//             title={"Upload single or multiple files"}
//             name="upload"
//             onClick={() => multiFileInputRefObject.current?.click()}
//             loading={!!progress ? progress > 0 && progress < 100 : false}
//             disabled={disabled}
//           >
//             <LucideIcon name="file-up" /> Upload files
//           </Button>
//           <Button
//             className="btn  btn-default"
//             title={"Upload directory"}
//             name="upload"
//             onClick={() => dirInputRefObject.current?.click()}
//             loading={!!progress ? progress > 0 && progress < 100 : false}
//             disabled={disabled}
//           >
//             <LucideIcon name="folder-up" /> Upload directory
//           </Button>
//           <input
//             id="selectDir"
//             ref={dirInputRef}
//             hidden
//             type="file"
//             multiple
//             /* @ts-expect-error */
//             directory=""
//             webkitdirectory=""
//             onClick={(e) => {
//               e.stopPropagation();
//             }}
//             onChange={async (e) => {
//               if (e.target.files) {
//                 setFiles((prev) => [...prev, ...Array.from(e.target.files as any)] as File[]);
//                 clearUpload();
//                 const actionUUID = uuidv4();
//                 await uploadFileList(e.target.files as any, actionUUID);
//               }
//             }}
//             style={{ display: "none" }}
//           />
//           <input
//             id="selectFiles"
//             ref={multiFileInputRef}
//             hidden
//             type="file"
//             multiple
//             onClick={(e) => {
//               e.stopPropagation();
//             }}
//             onChange={async (e) => {
//               if (e.target.files) {
//                 setFiles((prev) => [...prev, ...Array.from(e.target.files as any)] as File[]);

//                 clearUpload();
//                 const actionUUID = uuidv4();
//                 await uploadFileList(e.target.files as any, actionUUID);
//               }
//             }}
//             style={{ display: "none" }}
//           />

//           {loadingStatus?.errors && (
//             <Alert type="danger" message="Unsupported file(s)" fit centered style={{ margin: 0 }} />
//           )}
//         </div>
//       )}
//     </div>
//   );
// };
