import { StackTraces } from "./../StackTrace";
import { Empty } from "./Empty";
import { generateUid } from "../../tools/UID/UID";
import { commandTypes, parameterTypes, trackListType, argumentType } from "../PipelineTypes";

export class Nullify extends Empty {
  id: string;
  name: string;
  readonly type = commandTypes.nullify;
  errors: StackTraces;
  warnings: StackTraces;

  // readonly input = [{ name: "track", type: "1D_real" }];
  // readonly output = [{ name: "nullified", type: "hurz" },{ name: "deiwel", type: "potz_blitz" }];
  // readonly output = [{ name: "track", type: "1D_real" }];
  // readonly input = [{ name: "nullified", type: "hurz" },{ name: "deiwel", type: "potz_blitz" }];
  parameterTypes = { value: parameterTypes.float };
  internalParameter: Record<string, any>;
  readonly input: argumentType[];
  readonly output: argumentType[];

  constructor() {
    super();
    this.id = generateUid();
    this.name = "Nullify";
    this.errors = new StackTraces();
    this.warnings = new StackTraces();
    this.internalParameter = {};
    this.input = [{ name: "track", type: "1D_real" }];
    this.output = [{ name: "nullified", type: "1D_real" }];

    // this.checkParameter(parameter);
    // console.log("parameters", this.parameter);
  }

  // checkParameter(parameter: parameterType) {
  //   const checker = new ParameterChecker(this.argumentTypes);
  //   checker.setParameter(parameter);
  //   this.parameter = checker.parameter;
  //   this.errors.push(...checker.errors);
  //   // console.log(">>", this.parameter.trackFilter);
  //   this.track = this.parameter.track;
  // }

  getParams() {
    return Object.fromEntries(Object.entries(this.parameter).map(([k, v]) => [k, v.value]));
  }

  run(tracks: trackListType): trackListType {
    // // return parameter?.trackList.value(tracks) ?? [];

    const track = tracks[0];
    // // TODO: put error here
    // if (!track) {
    //   this.errors.create({
    //     component: "Nullify",
    //     id: this.id,
    //     message: `No input tracks. (Expected 1)`,
    //   });
    //   return [];
    // }
    // const { value } = this.parameter;
    // // const value = Math.floor(Math.random() * 10);

    // if (track?.data?.y) {
    //   const y: Float32Array = track?.data?.y;
    //   for (let i = 0; i < y.length; i++) {
    //     y[i] = value;
    //   }
    // }
    // // track.label = "Test " + value;
    // track.label = `nullified`;

    return [track];
  }
}
