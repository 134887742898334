import React from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { InputFormField } from "../../../formfields/InputFormField";
import { QuickAddChildrenProps } from "../common/QuickAddWrapper";
import { FormButtons } from "../../FormButtons";
import { TextareaFormField } from "../../../formfields/TextareaFormField";
import { Organization, OrganizationFieldLabels, organizationsConstants } from "../../../../api/Organizations";
import { FormHeader } from "../../FormHeader";
import { useQuickAddSubmit } from "../common/useQuickAddHooks";
import { OrganizationsTagsVirtualizedSelectForm } from "../../EntityForms/formsVirtualized/OrganizationTagsVirtualizedSelectForm";

export const QuickAddOrganizationForm = ({ onCreate, onCancel }: QuickAddChildrenProps<Organization>) => {
  const { onSubmit, loading } = useQuickAddSubmit<Organization>({
    resource: organizationsConstants.resource,
    onCreate: onCreate,
    onCancel: onCancel,
  });

  const FormSchema = yup.object().shape({
    name: yup.string().required("A Name is required"),
  });

  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
  } = useForm<Organization>({
    defaultValues: {},
    resolver: yupResolver(FormSchema),
  });

  return (
    <>
      <FormHeader title={"Add organization"} />
      <InputFormField
        id="name"
        label={OrganizationFieldLabels.name}
        errors={errors}
        register={register}
        autoFocus={true}
        required={true}
        placeholder="Enter name..."
      />

      <OrganizationsTagsVirtualizedSelectForm
        id="organizationTags"
        label={OrganizationFieldLabels.organizationTags}
        control={control}
        showControls
        horizontal
        isMulti
        allowCreateEntity
      />

      <InputFormField
        id="email"
        label={OrganizationFieldLabels.email}
        errors={errors}
        register={register}
        placeholder="Enter E-mail address..."
      />

      <TextareaFormField
        id="address"
        label={OrganizationFieldLabels.address}
        errors={errors}
        register={register}
        placeholder="Enter address..."
      />

      <InputFormField
        id="phoneOffice"
        label={OrganizationFieldLabels.phoneOffice}
        errors={errors}
        register={register}
        placeholder="Enter phone number..."
      />

      <InputFormField
        id="faxOffice"
        label={OrganizationFieldLabels.faxOffice}
        errors={errors}
        register={register}
        placeholder="Enter fax number..."
      />

      <InputFormField
        id="web"
        label={OrganizationFieldLabels.web}
        errors={errors}
        register={register}
        placeholder="Enter web address..."
      />

      <TextareaFormField
        id="notes"
        label={OrganizationFieldLabels.notes}
        errors={errors}
        register={register}
        placeholder="Enter notes..."
      />

      <FormButtons
        groupName="organizations"
        entityId={undefined}
        onClose={onCancel}
        onSubmit={handleSubmit((entity: Organization) => onSubmit(entity))}
        disabled={isSubmitting}
        errors={errors}
        submitButtonLabel="Create"
        loading={loading}
      />
    </>
  );
};
