import React, { useCallback, useState } from "react";
import SinglePage from "../../panels/SinglePage/SinglePage";
import { GenericVirtualizedTable } from "../GenericVirtualizedTable/GenericVirtualizedTable";
import { GenericVirtualizedTableProps } from "../GenericVirtualizedTable/GenericVirtualizedTableTypes";
import styles from "./MultiEditTable.module.css";
// import { SearchInput } from "../../forms/SearchInput/SearchInput";
// import { useDebouncedValue } from "../../../Dataset/Navigation/Search/Debounce";
import { EntityConstants, GenericEntity, StringIndexedDict } from "../../../api/GenericTypes";
import { UseFormReset, UseFormReturn, useFormContext } from "react-hook-form";
import { MultiEditMappedItemObject } from "../../forms/MultiEditForms/common/MultiEditProvider";
import { useHistory } from "react-router-dom";
import { MultiEditErrorContainer } from "../../forms/MultiEditForms/common/MultiEditRenderUtils";
import { TableViewLabel } from "../../panels/TableView/TableView";
import { Button } from "../../buttons/Button/Button";

export interface MultiEditProps<Entity extends GenericEntity> {
  entityConstants: EntityConstants;
  // handleSubmit: MultiEditFormContextProviderChildrenProps<Entity>["handleSubmit"];
  // reset: MultiEditFormContextProviderChildrenProps<Entity>["reset"];
  // isSubmitting: MultiEditFormContextProviderChildrenProps<Entity>["formState"]["isSubmitting"];
  // isDirty: MultiEditFormContextProviderChildrenProps<Entity>["isDirty"];
  // isError: MultiEditFormContextProviderChildrenProps<Entity>["isError"];
  // nChanges: MultiEditFormContextProviderChildrenProps<Entity>["nChanges"];
  // nErrors: MultiEditFormContextProviderChildrenProps<Entity>["nErrors"];
  // errors: MultiEditFormContextProviderChildrenProps<Entity>["formState"]["errors"];
  onSubmit: (
    data: MultiEditMappedItemObject<Entity>,
    nChanges: number,
    reset: UseFormReset<MultiEditMappedItemObject<Entity>>
  ) => Promise<void>;
}
interface MultiEditTableProps<Entity extends GenericEntity, Filters extends StringIndexedDict>
  extends Omit<GenericVirtualizedTableProps<Entity, Filters>, "resource">,
    MultiEditProps<Entity> {
  additionalSaveButtons?: (
    handleSubmit: UseFormReturn<MultiEditMappedItemObject<Entity>>["handleSubmit"],
    reset: UseFormReturn<MultiEditMappedItemObject<Entity>>["reset"],
    isSubmitting: UseFormReturn<MultiEditMappedItemObject<Entity>>["formState"]["isSubmitting"],
    isDirty: boolean,
    nChanges: number
  ) => React.ReactNode;
}

// const MemoTableGeneric = <Entity extends GenericEntity, Filters extends StringIndexedDict>(
//   props: MultiEditTableProps<Entity, Filters> & { isSubmitting: boolean }
// ) => {
//   return <GenericVirtualizedTable<Entity, Filters> {...props} />;
// };

// const MemoTable = React.memo(
//   MemoTableGeneric,
//   (prevProps, nextProps) => prevProps.isSubmitting === nextProps.isSubmitting
// ) as typeof MemoTableGeneric;

const GenericVirtualizedTableMemo = React.memo(GenericVirtualizedTable) as typeof GenericVirtualizedTable;

export const MultiEditTable = <Entity extends GenericEntity, Filters extends StringIndexedDict>({
  entityConstants,
  onSubmit,
  loading,
  ...rest
}: MultiEditTableProps<Entity, Filters>) => {
  const {
    handleSubmit,
    reset,
    formState: { isSubmitting, errors, isDirty, dirtyFields },
  } = useFormContext<MultiEditMappedItemObject<Entity>>();

  const nChanges = Object.keys(dirtyFields).length;
  // const nErrors = Object.keys(errors).length;
  // const isError = !!nErrors;
  // const isDirty = !!nChanges;

  const history = useHistory();
  // const [selection, setSelection] = useState<Set<Entity["id"]>>(new Set());
  const [resultsCount, setResultsCount] = useState<number>();
  // const [searchTerm, setSearchTerm] = useState<string>("");
  // const debouncedSearchValue = useDebouncedValue(searchTerm, 500);

  const onCancel = useCallback(() => {
    history.goBack();
  }, [history]);

  const onRowClick = useCallback(() => {}, []);
  const onResultsCount = useCallback((count: number | undefined) => {
    setResultsCount(count);
  }, []);

  return (
    <SinglePage>
      <SinglePage.Head>
        <SinglePage.Head.Label>
          <TableViewLabel
            entityConstants={{
              ...entityConstants,
              entityPlural: "Bulk edit " + entityConstants.entityPlural,
            }}
            resultsCount={resultsCount}
          />
        </SinglePage.Head.Label>
        <SinglePage.Head.Controls>
          <div className="flex row-nowrap align-center gap-5">
            <button
              className="btn btn-default"
              title="Cancel & return to the previous page"
              onClick={onCancel}
              disabled={isSubmitting || loading}
            >
              Cancel & return
            </button>
            {rest.additionalSaveButtons?.(handleSubmit, reset, isSubmitting, isDirty, nChanges)}
            <Button
              className={`btn btn-primary ${isSubmitting ? "loading" : ""}`}
              title="Save changes"
              // onClick={handleSubmit(data => {})}
              onClick={async () => await handleSubmit(async (data: any) => await onSubmit(data, nChanges, reset))()}
              disabled={isSubmitting}
              loading={loading}
            >
              {isSubmitting && <span className="spinner" />} Save changes {`${isDirty ? `(${nChanges})` : ""}`}
            </Button>
          </div>
        </SinglePage.Head.Controls>
      </SinglePage.Head>
      <SinglePage.Content>
        {errors && <MultiEditErrorContainer errors={errors} entityDescSingular={entityConstants.entitySingular} />}
        {/* <div className="flex row-nowrap align-center" style={{ padding: 8, borderBottom: "1px solid var(--gray-200)" }}>
          <SearchInput searchValue={searchTerm} setSearchValue={setSearchTerm} />
          {selection.size > 0 && (
            <div style={{ display: "flex", width: "fit-content", height: "100%", alignItems: "center" }}>
              <span style={{ color: "var(--gray-400)", fontWeight: "normal", padding: "0 5px", whiteSpace: "nowrap" }}>
                {selection.size}{" "}
                {selection.size === 1 ? props.entityConstants.entitySingular : props.entityConstants.entityPlural}{" "}
                selected
              </span>
            </div>
          )}
        </div> */}
        {rest.filters && (
          <GenericVirtualizedTableMemo<Entity, Filters>
            {...rest}
            // isSubmitting={isSubmitting}
            // onSubmit={onSubmit}
            entityConstants={entityConstants}
            onRowClick={onRowClick}
            fixedRowHeight={70}
            headerRowHeight={150}
            tableHeadCls={styles.multiEditTableHead}
            tableRowCls={styles.multiEditTableRows}
            tableRowHoverCls={styles.multiEditTableRowsHover}
            tableRowSelectedCls={styles.multiEditTableRowsFocus}
            showPermissionColumn={false}
            setResultsCount={onResultsCount}
            // loading={isFetching}
            columnSelect={false}
            sortOnColumnHeaderClick={false}
          />
        )}
      </SinglePage.Content>
    </SinglePage>
  );
};
