import { MethodForm } from "./MethodForm";
import { EntityAddForm, EntityAddFormPage } from "../common/entity/EntityAddForm";
import { EntityCloneForm, EntityCloneFormPage } from "../common/entity/EntityCloneForm";
import { EntityCloneFormProps, EntityDetailViewProps, EntityEditFormProps } from "../common/entity/EntityInterfaces";
import { EntityEditForm, EntityEditFormPage } from "../common/entity/EntityEditForm";
import { EntityDetailPage, EntityDetailView } from "../common/entity/EntityDetailPage";
import { MethodsDetail } from "./MethodsDetail";
import { EntityTablePage } from "../common/entity/EntityTablePage";
import { MethodsTable } from "./Table/MethodsTable";

// Add
export const MethodAddForm = () => {
  return <EntityAddForm<"methods"> entityTypeId={"methods"}>{(props) => <MethodForm {...props} />}</EntityAddForm>;
};

export const MethodAddFormPage = () => {
  return (
    <EntityAddFormPage>
      <MethodAddForm />
    </EntityAddFormPage>
  );
};

// Clone
export const MethodCloneForm = ({ id }: EntityCloneFormProps<"methods">) => {
  return (
    <EntityCloneForm id={id} entityTypeId={"methods"}>
      {(props) => <MethodForm {...props} />}
    </EntityCloneForm>
  );
};

export const MethodCloneFormPage = () => {
  return <EntityCloneFormPage<"methods"> isIntId>{(id) => <MethodCloneForm id={id} />}</EntityCloneFormPage>;
};

// Edit
export const MethodEditForm = ({ id }: EntityEditFormProps<"methods">) => {
  return (
    <EntityEditForm id={id} entityTypeId={"methods"} filters={{}}>
      {(props) => <MethodForm {...props} />}
    </EntityEditForm>
  );
};

export const MethodEditFormPage = () => {
  return <EntityEditFormPage<"methods"> isIntId>{(id) => <MethodEditForm id={id} />}</EntityEditFormPage>;
};

// Detail
export const MethodsDetailView = ({ id }: EntityDetailViewProps<"methods">) => {
  return (
    <EntityDetailView id={id} entityTypeId={"methods"} filters={{}}>
      {(props) => <MethodsDetail {...props} />}
    </EntityDetailView>
  );
};

export const MethodDetailsPage = () => {
  return <EntityDetailPage<"methods"> isIntId>{(id) => <MethodsDetailView id={id} />}</EntityDetailPage>;
};

export const MethodsTablePage = () => {
  return <EntityTablePage entityTypeId={"methods"}>{(props) => <MethodsTable {...props} />}</EntityTablePage>;
};
