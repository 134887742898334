import React, { CSSProperties, useCallback, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { clickType } from "../../ViewerLayout/ViewerTypes";

import styles from "./GraphClickInput.module.css";
import { ValidatedInput } from "./ValidatedInput";

export const GraphClickInput = ({
  value,
  setValue,
  validate,
  name,
  graphClick,
  useInteger,
}: {
  value: number;
  validate?: (value: number) => boolean;
  setValue?: (value: number) => void;
  name?: string;
  style?: CSSProperties;
  graphClick: clickType;
  useInteger?: boolean;
}) => {
  const [internalValue, setInternalValue] = useState<number>(value);
  const [pick, setPick] = useState<boolean>(false);

  const changeValue = useCallback(
    (value: number) => {
      const v = useInteger ? Math.round(value) : value;
      if (Math.abs(v - internalValue) > Number.EPSILON) {
        setInternalValue(v);
        if (setValue) setValue(v);
      }
      setPick(false);
    },
    [setValue, useInteger, internalValue]
  );

  useEffect(() => {
    if (pick) {
      changeValue(graphClick.x);
    }
  }, [graphClick, changeValue, pick]);

  useEffect(() => {
    if (Math.abs(value - internalValue) > Number.EPSILON) setInternalValue(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <div className={styles.rangeSetter}>
      <div className={styles.settingText} style={{ position: "relative", top: -10 }}>
        {name ? <span>{name}:&nbsp;</span> : null}
      </div>
      {pick ? (
        <Button
          bsSize="xsmall"
          className={styles.active}
          style={{
            position: "relative",
            top: -10,
            fontWeight: "bold",
            background: "repeating-linear-gradient(-45deg, darkgrey, darkgrey 10px, gainsboro 10px, gainsboro 20px)",
          }}
          onClick={() => setPick(false)}
        >
          Click on graph
        </Button>
      ) : (
        <Button
          bsSize="xsmall"
          style={{ position: "relative", top: -10, fontWeight: "normal", background: "Gainsboro" }}
          onClick={() => setPick(true)}
        >
          Pick from graph
        </Button>
      )}
      <div style={{ position: "relative", top: -10, paddingLeft: 10 }}>
        <ValidatedInput
          value={internalValue}
          setValue={changeValue}
          validate={validate}
          style={{
            width: "6em",
            // scale: "80%",
          }}
          useInteger={useInteger}
        />
      </div>
    </div>
  );
};
