import { useHistory } from "react-router-dom";
import { ColumnsSettings } from "../../../common/tables/ColumnsSelector/ColumnsSelector";
import {
  GenericVirtualizedTableCells,
  SortState,
} from "../../../common/tables/GenericVirtualizedTable/GenericVirtualizedTableTypes";
import { useCallback, useMemo, useState } from "react";
import { TableTabsDict } from "../../../common/tables/Tabs/TableTabsTypes";
import { BridgeFilterBar, BridgeFilterForm } from "./BridgeFilterBar";
import { useTabStore } from "../../../common/tables/Tabs/useTabStore";
import { LucideIcon } from "../../../common/icon/LucideIcon";
import styles from "../../../common/tables/GenericVirtualizedTable/commons.module.css";
import { NotAvailable, NotSet } from "../../../common/misc/UIconstants";
import { BridgeConnectionStatus } from "../common/BridgeConnectionStatus";
import TableView, { TableViewLabel } from "../../../common/panels/TableView/TableView";
import { EntityTableTabs } from "../../../common/tables/Tabs/EntityTableTabs";
import { EntityTable } from "../../../common/tables/EntityTable/EntityTable";
import { EntityFilterIndicator } from "../../../common/tables/EntityFilterIndicator/EntityFilterIndicator";
import { SearchInput } from "../../../common/forms/SearchInput/SearchInput";
import { MoreDropdown } from "../../../common/buttons/MoreDropdown/MoreDropdown";
import { AlertModal } from "../../../common/modals/AlertModal/AlertModal";
import { Bridge, BridgeFilters, BridgeFiltersTranslator, BridgeFieldLabels } from "../../../api/Bridges";
import { Alert } from "../../../common/overlays/Alert/Alert";
import { useAutoloadClientList } from "../../../api/autoload/AutoloadClientsApi";
import { ConnectionStatus, useLiveAutoloadConnection } from "../../../common/signalr/useLiveConnection";
import {
  UseEntityTableProps,
  useGenericVirtualizedTable,
  useGenericVirtualizedTableTabs,
} from "../../../common/tables/GenericVirtualizedTable/useGenericVirtualizedTable";
import { EntityTableProps } from "../../../common/entity/EntityInterfaces";
import { EntityTableDeleteButton } from "../../../common/entity/entityComponents/EntityTableDeleteButton";
import { EntityTableCloneButton } from "../../../common/entity/entityComponents/EntityTableCloneButton";
import { EntityTableEditButton } from "../../../common/entity/entityComponents/EntityTableEditButton";

export const switchBridgesDefaultSortState = (
  sortState: BridgeFilters["orderBy"]
): SortState<BridgeFilters["orderBy"]> => {
  switch (sortState) {
    case "ID_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-id",
        orderBy: sortState,
      };
    case "ID_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-id",
        orderBy: sortState,
      };
    case "NAME_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-name",
        orderBy: sortState,
      };
    case "NAME_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-name",
        orderBy: sortState,
      };
    case "TYPE_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-type",
        orderBy: sortState,
      };
    case "TYPE_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-type",
        orderBy: sortState,
      };
    default:
      return {
        sortDirection: "ASC",
        headerId: "default-name",
        orderBy: sortState,
      };
  }
};
const defaultFilter: BridgeFilters = { orderBy: "NAME_ASC" };

export const useBridgesTableDefaults = () => {
  const defaults: ColumnsSettings<Bridge> = useMemo(
    () => ({
      "default-id": { pos: 0, active: false, header: "Bridge-ID", property: "id" },
      "default-name": { pos: 1, active: true, header: BridgeFieldLabels.name, property: "name" },
      "default-type": { pos: 2, active: true, header: BridgeFieldLabels.type, property: "type" },
      "default-hostname": { pos: 3, active: true, header: BridgeFieldLabels.hostname, property: "hostname" },
      "default-ip": { pos: 4, active: true, header: BridgeFieldLabels.ipAddress, property: "ipAddress" },
      "default-connected": { pos: 5, active: true, header: BridgeFieldLabels.isConnected, property: "isConnected" },
      "default-desc": { pos: 6, active: true, header: BridgeFieldLabels.description, property: "description" },
    }),
    []
  );

  const tabStoreDefaults: TableTabsDict<Bridge, BridgeFilters, BridgeFilterForm> = useMemo(
    () => ({
      default: {
        tabId: "default",
        type: "fixed",
        label: "All",
        title: "All",
        icon: "house",
        align: "left",
        xPos: 0,
        settings: {
          columnSettings: {},
          columnWidths: {},
          filters: defaultFilter,
          sidebarFilters: {},
        },
        forcedSettings: {
          columnSettings: {},
          columnWidths: {},
          filters: {},
          sidebarFilters: {},
        },
      },
    }),
    []
  );
  return { defaults, tabStoreDefaults };
};

export const useBridgesTableColumns = ({
  fieldLabels,
  entityConstants,
  sort,
  setSort,
  connectionStatus,
}: UseEntityTableProps<"bridges"> & { connectionStatus: ConnectionStatus }) => {
  const columns = useMemo(() => {
    let headers: GenericVirtualizedTableCells<Bridge> = [
      {
        id: "default-id",
        Header: fieldLabels.id,
        accessor: (row) => <span style={{ color: "var(--gray-400)" }}>{row.id}</span>,
        width: 120,
        minWidth: 120,
        align: "center",
        sortingFn: () => {
          if (sort.headerId === "default-id") {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "ID_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "ID_ASC" }));
            }
          } else {
            setSort({ headerId: "default-id", sortDirection: "ASC", orderBy: "ID_ASC" });
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
      {
        Header: BridgeFieldLabels.name,
        id: fieldLabels.name,
        accessor: (row) => (
          <div className="flex align-center gap-5" data-toggle="tooltip">
            <LucideIcon name={entityConstants.icon} color="var(--primary)" />
            <div
              style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                fontWeight: 600,
                whiteSpace: "nowrap",
                marginRight: "5px",
              }}
            >
              {row.name}
            </div>
          </div>
        ),
        minWidth: 150,
        width: 250,
        align: "left",
        sortingFn: () => {
          if (sort.headerId === "default-name") {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "NAME_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "NAME_ASC" }));
            }
          } else {
            setSort({ headerId: "default-name", sortDirection: "ASC", orderBy: "NAME_ASC" });
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
      {
        Header: fieldLabels.type,
        id: "default-type",
        accessor: (row) => (
          <div style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
            <label className="label label-soft-info" style={{ margin: 0 }}>
              <LucideIcon name="wifi" /> {row.type}
            </label>
          </div>
        ),
        minWidth: 100,
        width: 100,
        align: "left",
        sortingFn: () => {
          if (sort.headerId === "default-type") {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "TYPE_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "TYPE_ASC" }));
            }
          } else {
            setSort({ headerId: "default-type", sortDirection: "ASC", orderBy: "TYPE_ASC" });
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
      {
        Header: fieldLabels.hostname,
        id: "default-hostname",
        accessor: (row) => (
          <div className={styles.container_ellipsis}>
            <span>{row.hostname || NotAvailable}</span>
          </div>
        ),
        minWidth: 150,
        width: 150,
        align: "left",
      },
      {
        Header: fieldLabels.ipAddress,
        id: "default-ip",
        accessor: (row) => (
          <div className={styles.container_ellipsis}>
            <span style={{ fontFamily: "monospace" }}>{row.ipAddress || NotAvailable}</span>
          </div>
        ),
        minWidth: 120,
        width: 120,
        align: "left",
      },
      {
        Header: fieldLabels.isConnected,
        id: "default-connected",
        accessor: (row) => (
          <BridgeConnectionStatus bridge={row} hideCheckConnection signalRConnection={connectionStatus} />
        ),
        minWidth: 200,
        width: 200,
        align: "left",
      },
      {
        Header: fieldLabels.description,
        id: "default-desc",
        accessor: (row) => (
          <div className={styles.container_ellipsis}>
            <span>{row.description || NotSet}</span>
          </div>
        ),
        minWidth: 100,
        width: 250,
        align: "left",
      },
    ];

    return headers;
  }, [
    fieldLabels.id,
    fieldLabels.name,
    fieldLabels.type,
    fieldLabels.hostname,
    fieldLabels.ipAddress,
    fieldLabels.isConnected,
    fieldLabels.description,
    sort.headerId,
    sort.sortDirection,
    setSort,
    entityConstants.icon,
    connectionStatus,
  ]);
  return { columns };
};
export const BridgesTable = ({
  entityApi,
  entityConstants,
  fieldLabels,
  permissions,
  routes,
}: EntityTableProps<"bridges">) => {
  const history = useHistory();
  const [showModal, setShowModal] = useState(false);
  const { bulkDeleteMutationAsync: deleteBridge, isLoadingBulkDeleteMutation } = entityApi;

  const { defaults, tabStoreDefaults } = useBridgesTableDefaults();

  const { selection, resultsCount, selectionPermissions, onCountChange, onSelectionChange, onSelectionPermissions } =
    useGenericVirtualizedTable<Bridge>();

  const {
    filters,
    sidebarFilters,
    forcedFilters,
    columnSetting,
    columnWidths,
    customTabs,
    fixedTabs,
    dispatchTabStore,
    currentTab,
    tabsLoading,
    tabsModified,
  } = useTabStore<Bridge, BridgeFilters, BridgeFilterForm>({
    resource: entityConstants.resource,
    defaults: tabStoreDefaults,
  });

  const { functionRef, sort, setSort, searchValue, setSearchValue, onTabChange } = useGenericVirtualizedTableTabs({
    tabsLoading,
    filters,
    switchSortState: switchBridgesDefaultSortState,
    dispatchTabStore,
  });

  // Client approval
  const { data: clients, refetch: refetchClients } = useAutoloadClientList();

  const [refetchTimeout, setRefetchTimeout] = useState<NodeJS.Timeout | undefined>(undefined);
  const refetchBridges = useCallback(() => {
    if (refetchTimeout) clearTimeout(refetchTimeout);
    setRefetchTimeout(
      setTimeout(() => {
        functionRef.current?.refetch();
      }, 500)
    );
    return () => refetchTimeout && clearTimeout(refetchTimeout);
  }, [functionRef, refetchTimeout]);

  const { connectionStatus } = useLiveAutoloadConnection({
    callbacks: {
      ClientListUpdated: refetchClients,
      DataSourceScheduled: () => {},
      ClientConnectionChanged: refetchBridges,
      DataSourceStatusChanged: () => {},
    },
    enabled: true,
  });

  const { columns } = useBridgesTableColumns({ fieldLabels, entityConstants, sort, setSort, connectionStatus });

  const memoizedFilters = useMemo(() => ({ ...filters, ...forcedFilters }), [filters, forcedFilters]);

  const clientsWaitingForApproval = useMemo(() => clients?.filter((client) => !client.isApproved), [clients]);

  return (
    <TableView>
      <TableView.Head>
        <TableView.Head.Label>
          <TableViewLabel entityConstants={entityConstants} resultsCount={resultsCount} />
        </TableView.Head.Label>
        <TableView.Head.Controls>
          <button
            className="btn btn-primary"
            title={`Add ${entityConstants.entitySingular}`}
            onClick={() => history.push(routes.getAddRoute)}
            disabled={!permissions.canCreate}
          >
            <LucideIcon name="plus" /> Add {entityConstants.entitySingular}
          </button>
        </TableView.Head.Controls>
      </TableView.Head>
      <TableView.Body>
        <TableView.Body.Sidebar>
          <BridgeFilterBar
            initialValues={sidebarFilters}
            dispatchTabStore={dispatchTabStore}
            tabsLoading={tabsLoading}
            currentTab={currentTab}
          />
        </TableView.Body.Sidebar>
        <TableView.Body.Content>
          <EntityTableTabs
            currentTab={currentTab}
            onTabChange={onTabChange}
            fixedTabs={fixedTabs}
            filters={filters}
            sidebarFilters={sidebarFilters}
            columnSetting={columnSetting}
            columnWidths={columnWidths}
            customTabs={customTabs}
            dispatchTabStore={dispatchTabStore}
            tabsLoading={tabsLoading}
            tabsModified={tabsModified}
          />
          <EntityTable>
            <EntityTable.Controls
              style={{
                borderTop: "0px",
                borderRadius: "0px",
              }}
            >
              <div className="flex col-nowrap gap-5" style={{ width: "100%" }}>
                {Array.isArray(clientsWaitingForApproval) && clientsWaitingForApproval.length > 0 && (
                  <>
                    <Alert
                      type="warning"
                      message={
                        <div className="flex row-nowrap gap-5 align-center">
                          There are clients waiting to be configured.
                          <button
                            className="btn btn-sm btn-warning"
                            onClick={() => history.push(`${routes.getAddRoute}?step=2`)}
                          >
                            Setup clients
                          </button>
                        </div>
                      }
                      fit
                      style={{ marginBottom: 0 }}
                    />
                  </>
                )}
                <div className="flex row-nowrap gap-5">
                  <EntityFilterIndicator<Bridge, BridgeFilters>
                    filters={filters}
                    excludeFilters={{}}
                    translatorConsts={BridgeFiltersTranslator}
                  />
                  <SearchInput searchValue={searchValue} setSearchValue={setSearchValue} placeholder="Search" />
                  {selection.size > 0 && (
                    <div style={{ display: "flex", width: "fit-content", height: "100%", alignItems: "center" }}>
                      <span
                        style={{
                          color: "var(--gray-400)",
                          fontWeight: "normal",
                          padding: "0 5px",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {selection.size}{" "}
                        {selection.size === 1 ? entityConstants.entitySingular : entityConstants.entityPlural} selected
                      </span>
                    </div>
                  )}
                  <EntityTableEditButton
                    entityConstants={entityConstants}
                    routes={routes}
                    permissions={permissions}
                    selection={selection}
                    selectionPermissions={selectionPermissions}
                  />
                  <MoreDropdown drop="right" btn="btn btn-ghost-secondary">
                    <li>
                      <EntityTableCloneButton
                        entityConstants={entityConstants}
                        routes={routes}
                        permissions={permissions}
                        selection={selection}
                      />
                    </li>
                    <li>
                      <EntityTableDeleteButton
                        entityConstants={entityConstants}
                        permissions={permissions}
                        selection={selection}
                        selectionPermissions={selectionPermissions}
                        onClick={() => setShowModal(true)}
                      />
                    </li>
                  </MoreDropdown>
                  <AlertModal
                    type="danger"
                    showModal={showModal}
                    setShowModal={setShowModal}
                    title={`${
                      selection.size === 0
                        ? `Select ${entityConstants.entitySingular} to delete`
                        : selection.size === 1
                        ? `Delete the selected ${entityConstants.entitySingular}`
                        : `Delete the selected ${entityConstants.entityPlural}`
                    }`}
                    description={`Proceeding will permanently delete the selected ${entityConstants.entityPlural}.`}
                    proceedLabel="Delete"
                    onProceed={async () => {
                      await deleteBridge({
                        ids: Array.from(selection),
                        goBackOnSuccess: false,
                        showToast: false,
                        entityName: entityConstants.entityPlural,
                        params: { deletePermanently: true },
                      }).catch((e) => {});
                      functionRef.current?.resetSelection();
                      setShowModal(false);
                    }}
                    loading={isLoadingBulkDeleteMutation}
                  />
                </div>
              </div>
            </EntityTable.Controls>
            <EntityTable.Body<Bridge, BridgeFilters>
              functionRef={functionRef}
              entityConstants={entityConstants}
              filters={memoizedFilters}
              columns={columns}
              columnSelect
              columnSetting={columnSetting}
              columnWidths={columnWidths}
              defaultColumnSettings={defaults}
              dispatchTabStore={dispatchTabStore}
              setResultsCount={onCountChange}
              onSelectionChange={onSelectionChange}
              onSelectionPermissions={onSelectionPermissions}
              showPermissionColumn
              loading={tabsLoading}
            />
          </EntityTable>
        </TableView.Body.Content>
      </TableView.Body>
    </TableView>
  );
};
