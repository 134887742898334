import { useContext } from "react";
import { useHistory } from "react-router-dom";
import { getBulkEditRoute } from "../../../../main/Routing";
import { SessionContext } from "../../../contexts/SessionContext";
import { MultiEditProvider } from "../../../forms/MultiEditForms/common/MultiEditProvider";
import { LucideIcon } from "../../../icon/LucideIcon";
import { BulkEditDropDownWrapperPropsWithChildren, BulkEditLocationState } from "./BulkEditDropDownTypes";
import { GenericEntity, StringIndexedDict } from "../../../../api/GenericTypes";

export const BulkEditDropDownWrapper = <Entity extends GenericEntity, Filters extends StringIndexedDict>({
  selection,
  onSuccess,
  onClose,
  filters,
  entityConstants,
  children,
}: BulkEditDropDownWrapperPropsWithChildren<Entity, Filters>) => {
  const history = useHistory();
  const { route } = useContext(SessionContext);
  return (
    <MultiEditProvider<Entity>
      resource={entityConstants.resource}
      ids={Array.from(selection)}
      entityValidationSchema={undefined} // we check on the external form
      childrenOnLoad={
        <button
          className="btn btn-primary"
          onClick={() => {
            history.push({
              pathname: route(getBulkEditRoute(entityConstants.frontendIndexRoute)),
              state: {
                filters: { ...filters, ids: Array.from(selection) },
              } as BulkEditLocationState<Entity, Filters>,
            });
          }}
        >
          <LucideIcon name="table" /> Advanced edit
        </button>
      }
    >
      {({ items, consolidatedValues }) =>
        children({
          entityConstants: entityConstants,
          consolidatedValues: consolidatedValues,
          items: items,
          selection: selection,
          filters: filters,
          onSuccess: onSuccess,
          onClose: onClose,
        })
      }
    </MultiEditProvider>
  );
};
