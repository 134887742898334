import { StringIndexedDict } from "../../../../api/GenericTypes";
import {
  GenericEntityVirtualizedEntitySelectFormFieldProps,
  GenericVirtualizedEntitySelectFormFieldControllerWrapper,
} from "../../../formfields/GenericVirtualizedSelectFormField/GenericVirtualizedEntitySelectFormFieldControllerWrapper";
import { QuickAddWrapper } from "../../QuickAdd/common/QuickAddWrapper";
import {
  PersonTag,
  PersonTagFilters,
  PersonTagSortingOptionsConsts,
  PersonTagSuggestions,
  personTagsConstants,
} from "../../../../api/PersonTag";
import { QuickAddPersonTagForm } from "../../QuickAdd/forms/QuickAddPersonTagForm";
import { useContext } from "react";
import { SessionContext } from "../../../contexts/SessionContext";

export const PersonTagsVirtualizedSelectForm = <Entity extends StringIndexedDict>(
  props: GenericEntityVirtualizedEntitySelectFormFieldProps<Entity, PersonTagSuggestions, PersonTagFilters>
) => {
  const { session } = useContext(SessionContext);

  return (
    <GenericVirtualizedEntitySelectFormFieldControllerWrapper<Entity, PersonTagSuggestions, PersonTagFilters>
      {...props}
      filters={{ orderBy: "NAME_ASC", ...props.filters }}
      //   onRowRenderer={(item) => <></>}
      createModal={({ showModal, setShowModal, onCreate }) => (
        <QuickAddWrapper<PersonTag> showModal={showModal} setShowModal={setShowModal} onCreate={onCreate}>
          {(props) => <QuickAddPersonTagForm {...props} />}
        </QuickAddWrapper>
      )}
      entityConstants={personTagsConstants}
      orderByOptions={PersonTagSortingOptionsConsts}
      allowCreateEntity={session?.permissions.administer_persons && props.allowCreateEntity}
    />
  );
};
