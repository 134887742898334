import { useHistory } from "react-router-dom";
import { dataSourceConstants } from "../../api/DataSource";
import { EntityDetailProps, EntityDetailTableProps } from "../../common/entity/EntityInterfaces";
import { NotSet } from "../../common/misc/UIconstants";
import { Table } from "../../common/panels/Detail/DetailTable";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { SessionContext } from "../../common/contexts/SessionContext";
import Detail from "../../common/panels/Detail/Detail";
import { DetailsPageTopControlsView } from "../../common/panels/misc/DetailsPageTopControlsView/DetailsPageTopControlsView";
import { getDetailLink, getEditLink, getIndexRoute } from "../../main/Routing";
import { MoreDropdown } from "../../common/buttons/MoreDropdown/MoreDropdown";
import { AlertModal } from "../../common/modals/AlertModal/AlertModal";
import { showtoast } from "../../common/overlays/Toasts/showtoast";
import Status from "../../common/badges/Status/Status";
import { LinkEntity } from "../../common/misc/LinkEntity/LinkEntity";
import { instrumentsConstants } from "../../api/Facilities";
import { methodsConstants } from "../../api/Methods";
import { BridgeTile } from "../common/tiles/BridgeTile";
import { bridgeConstants } from "../../api/Bridges";
import { useLiveAutoloadConnection } from "../../common/signalr/useLiveConnection";
import { useQueryClient } from "@tanstack/react-query";
import {
  dataSourceStatusConstants,
  DataSourceStatusInfoClient,
  DataSourceStatusInfoSSH,
} from "../../api/DataSourceStatus";
import { DatasetsTile } from "../../common/sidebar/tiles/DatasetsTile";
import { datasetsConstants } from "../../api/Datasets";
import { Button } from "../../common/buttons/Button/Button";
import { DataSourceStatus } from "../DataSources/common/DataSourceStatus/DataSourceStatus";
import { DataSourceOverview } from "../DataSources/common/DataSourceStatusOverview/DataSourceStatusOverview";
import { DataSourceHistory } from "../DataSources/common/DataSourceHistory/DataSourceHistory";
import { iconNMRConstants } from "../../api/IconNMR";
import { LucideIcon } from "../../common/icon/LucideIcon";
import { useAutoloadConfigurationsActions } from "../../api/autoload/AutoloadClientsApi";
import { DateTimeRenderer } from "../../common/datetime/DateTimeFormatter";
import { CutoffDateInformation } from "../DataSources/DataSourceForm";

export const DataSourceDetailTable = ({
  permissions,
  routes,
  entity,
  entityConstants,
  fieldLabels,
}: EntityDetailTableProps<"dataSources">) => {
  const history = useHistory();
  const { route } = useContext(SessionContext);
  const [showLastClientStatus, setShowLastClientStatus] = useState<boolean>(false);
  const [isRunning, setIsRunning] = useState<boolean>(entity.status?.lastClientStatus?.runState === "Running");
  const queryClient = useQueryClient();
  const refetchDataSource = useCallback(
    (updateDatasets?: boolean) => {
      queryClient.invalidateQueries({ queryKey: [dataSourceConstants.resource, "detail", entity.id] });
      queryClient.invalidateQueries({ queryKey: [dataSourceStatusConstants.resource, "list"] });
      queryClient.invalidateQueries({ queryKey: [dataSourceStatusConstants.resource, "ids_only"] });
      queryClient.invalidateQueries({ queryKey: [dataSourceStatusConstants.resource, "suggestions"] });
      if (updateDatasets) {
        queryClient.invalidateQueries({ queryKey: [datasetsConstants.resource, "list"] });
        queryClient.invalidateQueries({ queryKey: [datasetsConstants.resource, "count"] });
      }
    },
    [entity.id, queryClient]
  );

  useLiveAutoloadConnection({
    callbacks: {
      DataSourceScheduled: (dataSourceId) => dataSourceId === entity.id && refetchDataSource(),
      ClientConnectionChanged: (bridgeId) => bridgeId === entity.bridge.id && refetchDataSource(),
      DataSourceStatusChanged: (dataSourceId, data) => {
        if (dataSourceId === entity.id) {
          setIsRunning(data.runState === "Running");
          setShowLastClientStatus(true);
        }
      },
    },
    enabled: true,
  });

  const datasetsUploaded = useMemo(() => {
    switch (entity.bridge.type) {
      case "Client":
        return (entity.status?.lastClientStatus?.info as DataSourceStatusInfoClient | undefined)?.datasetsSubmitted;
      case "SFTP":
        return (entity.status?.lastClientStatus?.info as DataSourceStatusInfoSSH | undefined)?.datasetsUploaded;
      default:
        return;
    }
  }, [entity.bridge.type, entity.status?.lastClientStatus?.info]);
  useEffect(() => {
    if (isRunning && !!datasetsUploaded) {
      queryClient.invalidateQueries({ queryKey: [datasetsConstants.resource, "list"] });
      queryClient.invalidateQueries({ queryKey: [datasetsConstants.resource, "count"] });
    }
  }, [isRunning, queryClient, datasetsUploaded]);
  useEffect(() => {
    setIsRunning(entity.status?.lastClientStatus?.runState === "Running");
    if (entity.status?.lastClientStatus?.runState === "Running") {
      var interval = setInterval(() => {
        refetchDataSource();
      }, 2000);
      return () => clearInterval(interval);
    }
  }, [entity.status?.lastClientStatus?.runState, refetchDataSource]);

  useEffect(() => {
    if (entity.type !== "IconNMR")
      history.replace(route(getDetailLink(dataSourceConstants.frontendIndexRoute, entity.id)));
  }, [entity.id, entity.type, history, route]);

  return (
    <>
      <Table noPadding nonFoldable>
        <Table.Head>IconNMR monitoring details</Table.Head>
        <Table.Body>
          <Table.Body.RowContent
            title={fieldLabels.name}
            content={
              <div className="container_label">
                <div className="container_label_name" style={{ fontWeight: 600 }}>
                  {entity.name ?? NotSet}
                </div>
                <div className="container_label_id">
                  <span style={{ whiteSpace: "nowrap" }}>IconNMR monitoring-ID: {entity.id}</span>
                </div>
              </div>
            }
          />
          <Table.Body.RowContent
            title={fieldLabels.bridge}
            content={<LinkEntity entityConstants={bridgeConstants} property={entity.bridge} />}
          />
          <Table.Body.RowContent
            title={fieldLabels.type}
            content={
              <div className="flex align-center">
                <label className="label label-soft-secondary" style={{ margin: 0 }}>
                  {entity.type}
                </label>
              </div>
            }
          />

          <Table.Body.RowContent
            title={fieldLabels.enabled}
            content={
              <span className="badge">
                <Status type={entity.enabled ? "success" : "danger"}>{entity.enabled ? "on" : "off"}</Status>
              </span>
            }
          />

          <Table.Body.RowContent
            title={fieldLabels.format}
            content={
              <div className="flex row-wrap align-center gap-5" style={{ width: "100%" }}>
                <span className="label label-soft-default">{entity.format.name}</span>
              </div>
            }
          />

          {!!entity.fileExcludePatterns?.length && (
            <Table.Body.RowContent
              title={fieldLabels.fileExcludePatterns}
              content={
                <div className="flex row-wrap align-center gap-5" style={{ width: "100%" }}>
                  <LucideIcon name="corner-down-right" color="var(--gray-400)" />
                  {entity.fileExcludePatterns.map((p, index) => (
                    <span key={index} className="label label-soft-default" title={`Regular expression: ${p.regex}`}>
                      {p.name}
                    </span>
                  ))}
                </div>
              }
            />
          )}

          <Table.Body.RowContent
            title={fieldLabels.instrument}
            content={<LinkEntity entityConstants={instrumentsConstants} property={entity.instrument} />}
          />
          <Table.Body.RowContent
            title={fieldLabels.method}
            content={<LinkEntity entityConstants={methodsConstants} property={entity.method} />}
          />
          <Table.Body.RowContent
            title="TopSpin directories"
            content={
              <div style={{ width: "100%" }}>
                {entity.directories.map((d, index) => (
                  <div key={index} style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
                    {d}
                  </div>
                ))}
              </div>
            }
          />
          <Table.Body.RowContent
            title={fieldLabels.cutoffDate}
            content={
              <div className="flex align-center gap-5" style={{ width: "100%", justifyContent: "space-between" }}>
                <div className="flex align-center gap-5">
                  {entity.cutoffDate ? (
                    <div>
                      <DateTimeRenderer date={entity.cutoffDate} />
                    </div>
                  ) : (
                    NotSet
                  )}
                </div>
                <CutoffDateInformation />
              </div>
            }
          />
          <Table.Body.RowContent
            title={fieldLabels.customImport}
            content={entity.customImport?.name || entity.customImport?.id || NotSet}
          />
        </Table.Body>
      </Table>
      {(entity.enabled || showLastClientStatus) && (
        <Table noPadding>
          <Table.Head>Status</Table.Head>
          <Table.Body>
            <div style={{ margin: "10px 0" }}>
              <DataSourceStatus dataSource={entity} />
            </div>
          </Table.Body>
        </Table>
      )}
      {(entity.enabled || showLastClientStatus) && (
        <Table noPadding>
          <Table.Head>Statistics</Table.Head>
          <Table.Body>
            <div style={{ margin: "10px 0" }}>
              <DataSourceOverview dataSource={entity} />
            </div>
          </Table.Body>
        </Table>
      )}
      <Table noPadding isFolded={entity.enabled}>
        <Table.Head>History</Table.Head>
        <Table.Body>
          <div style={{ padding: "10px 0" }}>
            <DataSourceHistory dataSource={entity} allowLoadMore />
          </div>
        </Table.Body>
      </Table>
    </>
  );
};
// TODO
export const DataSourcesDetail = ({
  permissions,
  routes,
  entity,
  entityApi,
  entityConstants,
  fieldLabels,
}: EntityDetailProps<"dataSources">) => {
  const history = useHistory();
  const { route } = useContext(SessionContext);
  const [showModal, setShowModal] = useState<boolean>(false);

  const { editMutationAsync, isLoadingEditMutation } = entityApi;

  const { triggerAutoload, cancelAutoload, isRunning, setIsRunning } = useAutoloadConfigurationsActions(entity);

  useEffect(() => {
    if (entity.type !== "IconNMR") history.replace(routes.getEditLink(entity.id));
  }, [entity.id, entity.type, history, routes]);

  return (
    <Detail
      head={
        <DetailsPageTopControlsView
          entity={entity}
          entityConstants={{ ...iconNMRConstants, frontendIndexRoute: dataSourceConstants.frontendIndexRoute }}
          controls={
            <>
              <button
                className="btn btn-primary"
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  history.push(
                    `${route(getIndexRoute(iconNMRConstants.frontendIndexRoute))}/queue?dataSourceId=${entity.id}`
                  );
                }}
              >
                <LucideIcon name="play" /> Show queue
              </button>
              <Button
                className="btn btn-primary"
                disabled={!permissions.canEdit(entity)}
                // loading={isRunning}
                disabledOnLoading={false}
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  setIsRunning(true);
                  isRunning
                    ? cancelAutoload(entity.id).finally(() => {
                        setIsRunning(false);
                      })
                    : triggerAutoload(entity.id).finally(() => {
                        setIsRunning(false);
                      });
                }}
              >
                <LucideIcon name="power" /> {isRunning ? "Abort" : "Start"}
              </Button>
              <button
                className="btn btn-default"
                onClick={() => history.push(route(getEditLink(iconNMRConstants.frontendIndexRoute, entity.id)))}
                disabled={!permissions.canEdit(entity)}
              >
                <LucideIcon name="square-pen" />
                <span> Edit</span>
              </button>

              <MoreDropdown drop="right" btn="btn btn-ghost-secondary">
                <li>
                  {!entity.enabled ? (
                    <Button
                      className="btn btn-sm btn-ghost-success"
                      disabled={!permissions.canEdit(entity)}
                      title={`Enable this ${dataSourceConstants.entityPlural}`}
                      onClick={async () => {
                        await editMutationAsync({ id: entity.id, body: { ...entity, enabled: true } }).catch(() => {});
                      }}
                      loading={isLoadingEditMutation}
                    >
                      <LucideIcon name="toggle-right" /> Enable
                    </Button>
                  ) : (
                    <Button
                      className="btn btn-sm btn-ghost-warning"
                      disabled={!permissions.canEdit(entity)}
                      title={`Disable this ${dataSourceConstants.entityPlural}`}
                      onClick={async () => {
                        await editMutationAsync({ id: entity.id, body: { ...entity, enabled: false } }).catch(() => {});
                      }}
                      loading={isLoadingEditMutation}
                    >
                      <LucideIcon name="toggle-left" /> Disable
                    </Button>
                  )}
                </li>
                <li>
                  <button
                    className="btn btn-sm btn-ghost-secondary"
                    title={`Clone this DataSource`}
                    onClick={() => history.push(routes.getCloneLink(entity.id))}
                    disabled={!permissions.canCreate}
                  >
                    <LucideIcon name="copy" /> Clone
                  </button>
                </li>
                <li>
                  <button
                    className="btn btn-sm btn-ghost-danger"
                    title="Delete"
                    onClick={() => setShowModal(true)}
                    disabled={!permissions.canDelete(entity)}
                  >
                    <LucideIcon name="trash-2" /> Delete
                  </button>
                </li>
              </MoreDropdown>
              <AlertModal
                type="danger"
                showModal={showModal}
                setShowModal={setShowModal}
                title="Permanently delete IconNMR monitoring?"
                description="No datasets will be uploaded via this IconNMR monitoring in the future."
                proceedLabel="Delete"
                onProceed={async () => {
                  await entityApi
                    .deleteMutationAsync(
                      {
                        id: entity.id,
                        goBackOnSuccess: false,
                        showToast: false,
                        entityName: "dataSource",
                        params: { deletePermanently: true },
                      },

                      {
                        onSuccess: () => {
                          showtoast("success", `Deleted Data-Source-ID: ${entity.id}.`);
                          history.goBack();
                        },
                      }
                    )
                    .catch((e) => {});
                }}
                loading={entityApi.isLoadingDeleteMutation}
              />
            </>
          }
        />
      }
      mainStyle={{ padding: "0px 10px" }}
      main={<DataSourceDetailTable entity={entity} entityConstants={entityConstants} fieldLabels={fieldLabels} />}
      sidebar={
        <>
          <BridgeTile bridgeId={entity.bridge.id} />

          {!!entity.relations?.datasets.count && (
            <DatasetsTile
              title="Datasets"
              defaultFilters={{
                dataSourceIds: [entity.id],
                // dateAddedFrom: recently,
                orderBy: "CREATED_ON_DESC",
              }}
              excludeFilters={{ dataSourceIds: () => false }}
            />
          )}

          {/* Empty tiles will go here */}
          {!entity.relations?.datasets.count && <DatasetsTile title="Datasets" disabled />}
        </>
      }
    />
  );
};
