import { useContext, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { IEntityMinimalModel } from "../api/GenericTypes";
import { personsConstants } from "../api/Person";
import { DetailsPageTopControlsView } from "../common/panels/misc/DetailsPageTopControlsView/DetailsPageTopControlsView";
import { PreCode } from "../common/PreCode";
import Status from "../common/badges/Status/Status";
import { SessionContext } from "../common/contexts/SessionContext";
import { LucideIcon } from "../common/icon/LucideIcon";
import { NotSet } from "../common/misc/UIconstants";
import { AlertModal } from "../common/modals/AlertModal/AlertModal";
import { showtoast } from "../common/overlays/Toasts/showtoast";
import Detail from "../common/panels/Detail/Detail";
import { Table } from "../common/panels/Detail/DetailTable";
import { DatasetsTile } from "../common/sidebar/tiles/DatasetsTile";
import { SamplesTile } from "../common/sidebar/tiles/SamplesTile";
import { ProjectPersonPermissions } from "../ProjectPersonPermissions/ProjectPersonPermissions";
import { MoreDropdown } from "../common/buttons/MoreDropdown/MoreDropdown";
import { DateTimeRenderer } from "../common/datetime/DateTimeFormatter";
import { EntityDetailProps, EntityDetailTableProps } from "../common/entity/EntityInterfaces";
import { GetPersons } from "../common/misc/EntityRenders/EntityRenderer";
import { LinkEntity } from "../common/misc/LinkEntity/LinkEntity";
import { organizationsConstants } from "../api/Organizations";
import { ToggleRenderer } from "../common/buttons/ToggleSwitch/ToggleSwitch";
import { CustomTypeDetailRow, CustomTypeRenderer } from "../Customization/CustomTypes/generics/CustomTypeRenderer";
// import { AttachmentsTileWrapped } from "../common/sidebar/tiles/AttachmentsTile";
import { CustomFieldReferencesTile } from "../common/sidebar/tiles/CustomFieldReferencesTile";
import { TwoFactorAuthIndicator } from "./TotpModal";
import { EntityRestoreButton } from "../common/entity/entityComponents/EntityRestoreButton";

export const PersonDetailTable = ({ entity, entityConstants, fieldLabels }: EntityDetailTableProps<"persons">) => {
  const { session } = useContext(SessionContext);
  const enable2FA = session?.features.enable_2fa;

  return (
    <>
      {entity.login && (session?.permissions.administer_persons || session?.userId === entity.id) && (
        <Table noPadding nonFoldable>
          <Table.Head>Account details</Table.Head>
          <Table.Body>
            <Table.Body.RowContent title={fieldLabels.login} content={entity.login} />
            <Table.Body.RowContent
              title={fieldLabels.isLocalUser}
              content={
                <label
                  style={{
                    margin: 0,
                    lineHeight: undefined,
                  }}
                >
                  {entity.isLocalUser ? "Local" : "SSO"}
                </label>
              }
            />
            <Table.Body.RowContent
              title={fieldLabels.accountState}
              content={
                <>
                  {!!entity.login ? (
                    <span className="flex row-nowrap align-center gap-5 badge" title={entity.login || ""}>
                      <Status type={entity.accountState === "Disabled" ? "danger" : "success"} />
                      {entity.accountState === "Disabled" ? "Disabled" : "Enabled"}
                    </span>
                  ) : (
                    NotSet
                  )}
                </>
              }
            />
            {session.permissions.logsadmin && (
              <Table.Body.RowContent
                title={fieldLabels.isSystemUser}
                content={<ToggleRenderer value={!!entity.isSystemUser} />}
              />
            )}
            <Table.Body.RowContent
              title={fieldLabels.roles}
              content={
                <div
                  style={{
                    display: "flex",
                    flexFlow: "row wrap",
                    gap: "5px",
                    overflow: "auto",
                    alignItems: "center",
                  }}
                >
                  {entity.roles?.map((c, i) => (
                    <span key={i} className="label label-soft-secondary">
                      {c.name}
                    </span>
                  )) || NotSet}
                </div>
              }
            />
            {enable2FA && entity.isLocalUser && (
              <Table.Body.RowContent
                title={fieldLabels.has2FA}
                content={<TwoFactorAuthIndicator enabled={!!entity.has2FA} />}
              />
            )}
          </Table.Body>
        </Table>
      )}

      <Table noPadding nonFoldable>
        <Table.Head>Person details</Table.Head>
        <Table.Body>
          {session?.features.enable_person_custom_types && (
            <CustomTypeDetailRow entity={entity} entityConstants={personsConstants} />
          )}
          <Table.Body.RowContent title={fieldLabels.salutation} content={entity.salutation || NotSet} />

          <Table.Body.RowContent
            title={fieldLabels.name}
            content={
              <div className="container_label">
                <div className="container_label_name" style={{ fontWeight: 600 }}>
                  {entity.name ?? NotSet}
                </div>
                <div className="container_label_id">
                  <span style={{ whiteSpace: "nowrap" }}>Person-ID: {entity.id}</span>
                </div>
              </div>
            }
          />

          <Table.Body.RowContent
            title={fieldLabels.email}
            content={
              entity.email ? (
                <Link
                  to="#"
                  onClick={(e) => {
                    window.location.href = `mailto:${entity.email}`;
                    e.preventDefault();
                  }}
                >
                  {entity.email}
                </Link>
              ) : (
                NotSet
              )
            }
          />

          <Table.Body.RowContent
            title={fieldLabels.organization}
            content={<LinkEntity entityConstants={organizationsConstants} property={entity.organization} />}
          />

          <Table.Body.RowContent
            title={fieldLabels.privateAddress}
            content={entity.privateAddress ? <PreCode>{entity.privateAddress}</PreCode> : NotSet}
          />

          <Table.Body.RowContent title={fieldLabels.officePhone} content={entity.officePhone || NotSet} />
          <Table.Body.RowContent title={fieldLabels.phone} content={entity.phone || NotSet} />

          <Table.Body.RowContent
            title={fieldLabels.web}
            content={
              entity.web ? (
                <a
                  className="link"
                  style={{ textDecoration: "none" }}
                  href={entity.web}
                  target="_blank"
                  rel="noreferrer"
                >
                  {entity.web}
                </a>
              ) : (
                NotSet
              )
            }
          />

          <Table.Body.RowContent
            title={fieldLabels.notes}
            content={
              <>
                {entity.notes ? (
                  <div className="container_notes">
                    <textarea rows={4} className="container_textarea" value={entity?.notes} disabled />
                  </div>
                ) : (
                  NotSet
                )}
              </>
            }
          />

          <Table.Body.RowContent
            title={fieldLabels.personTags}
            content={
              <>
                {entity.personTags && (
                  <div
                    style={{
                      display: "flex",
                      flexFlow: "row wrap",
                      gap: "var(--margin-sm)",
                      alignItems: "center",
                    }}
                  >
                    {entity.personTags.map((item: IEntityMinimalModel, index: number) => (
                      <label className="label label-soft-secondary" key={index} style={{ margin: 0 }}>
                        {item.name}
                      </label>
                    ))}
                  </div>
                )}
              </>
            }
          />

          <Table.Body.RowContent
            title={fieldLabels.createdOn}
            content={
              <div className="flex align-center gap-5" style={{ width: "100%", flexWrap: "wrap" }}>
                <div style={{ maxWidth: "max-content", width: "100%" }}>
                  <DateTimeRenderer date={entity.createdOn} includeElapsed={false} />
                </div>
                <div className="flex row-nowrap align-center gap-5">
                  by <GetPersons persons={entity.createdBy} />
                </div>
              </div>
            }
          />
          <Table.Body.RowContent
            title={fieldLabels.modifiedOn}
            content={
              <div className="flex align-center gap-5" style={{ width: "100%", flexWrap: "wrap" }}>
                <div style={{ maxWidth: "max-content", width: "100%" }}>
                  <DateTimeRenderer date={entity.modifiedOn} includeElapsed={false} />
                </div>
                <div className="flex row-nowrap align-center gap-5">
                  by <GetPersons persons={entity.modifiedBy} />
                </div>
              </div>
            }
          />
        </Table.Body>
      </Table>
      <CustomTypeRenderer entity={entity} />
    </>
  );
};

export const PersonsDetail = ({
  permissions,
  routes,
  entity,
  entityApi,
  entityConstants,
  fieldLabels,
}: EntityDetailProps<"persons">) => {
  let history = useHistory();
  const { session } = useContext(SessionContext);
  const [showModal, setShowModal] = useState<boolean>(false);

  return (
    <Detail
      head={
        <DetailsPageTopControlsView
          entity={entity}
          entityConstants={personsConstants}
          attributes={
            <>
              {entity.isDeleted && (
                <h2 style={{ margin: 0 }}>
                  <label className="label label-sm label-soft-warning" style={{ margin: 0 }} title={"Trashed"}>
                    <LucideIcon name="trash" />
                  </label>
                </h2>
              )}
            </>
          }
          controls={
            <>
              {entity.isDeleted ? (
                <EntityRestoreButton
                  entity={entity}
                  entityConstants={entityConstants}
                  entityApi={entityApi}
                  permissions={permissions}
                />
              ) : (
                <button
                  className="btn btn-default"
                  onClick={() => history.push(routes.getEditLink(entity.id))}
                  disabled={!permissions.canEdit(entity)}
                >
                  <LucideIcon name="square-pen" />
                  <span> Edit</span>
                </button>
              )}
              <MoreDropdown drop="right" btn="btn btn-ghost-secondary">
                <li>
                  <button
                    className="btn btn-sm btn-ghost-secondary"
                    title={`Clone this Person`}
                    onClick={() => history.push(routes.getCloneLink(entity.id))}
                    disabled={!permissions.canCreate}
                  >
                    <LucideIcon name="copy" /> Clone
                  </button>
                </li>
                <li>
                  <button
                    className={`btn btn-sm btn-ghost-${entity.isDeleted ? "danger" : "warning"}`}
                    title={`${entity.isDeleted ? "Delete" : "Move to trash"}`}
                    onClick={() => setShowModal(true)}
                    disabled={entity.isDeleted ? !permissions.canDelete(entity) : !permissions.canTrash(entity)}
                  >
                    <LucideIcon name="trash-2" /> {`${entity.isDeleted ? "Delete" : "Trash"}`}
                  </button>
                </li>
              </MoreDropdown>
              <AlertModal
                type={`${entity.isDeleted ? "danger" : "warning"}`}
                showModal={showModal}
                setShowModal={setShowModal}
                title={`${entity.isDeleted ? "Permanently delete person?" : "Move to trash?"}`}
                description={
                  entity.isDeleted
                    ? "This person may be referenced by other entities. Deleting a person is only possible if all references are removed."
                    : "This will move the person into trash. It is possible to restore this person at any time."
                }
                proceedLabel={entity.isDeleted ? "Delete" : "Trash"}
                onProceed={async () => {
                  if (entity.isDeleted) {
                    await entityApi
                      .deleteMutationAsync(
                        {
                          id: entity.id,
                          goBackOnSuccess: false,
                          showToast: false,
                          entityName: "person",
                          params: { deletePermanently: true },
                        },

                        {
                          onSuccess: () => {
                            showtoast("success", `Deleted Person-ID: ${entity.id}.`);
                            history.goBack();
                          },
                        }
                      )
                      .catch((e) => {});
                  } else {
                    await entityApi
                      .deleteMutationAsync(
                        { id: entity.id, goBackOnSuccess: false, showToast: false, entityName: "person" },

                        {
                          onSuccess: () => {
                            showtoast("success", `Moved Person-ID: ${entity.id} to trash.`);
                            setShowModal(false);
                          },
                        }
                      )
                      .catch((e) => {});
                  }
                }}
                loading={entityApi.isLoadingDeleteMutation}
              />
            </>
          }
        />
      }
      mainStyle={{ padding: "0px 10px" }}
      main={
        <>
          <PersonDetailTable entity={entity} entityConstants={entityConstants} fieldLabels={fieldLabels} />
          {(session?.permissions.administer_projects || session?.userId === entity.id) && !!entity.login && (
            <Table>
              <Table.Head>{fieldLabels.projects}</Table.Head>
              <Table.Body>
                <ProjectPersonPermissions person={entity} />
              </Table.Body>
            </Table>
          )}
        </>
      }
      sidebar={
        <>
          {!!entity.relations?.datasets.count && (
            <DatasetsTile
              defaultFilters={{ ownerIds: [entity.id], isViewableEntity: false }}
              excludeFilters={{ ownerIds: () => false, isViewableEntity: () => false }}
            />
          )}
          {!!entity.relations?.samples.count && (
            <SamplesTile
              defaultFilters={{ preparedByIds: [entity.id] }}
              excludeFilters={{ preparedByIds: () => false }}
            />
          )}

          {/* {session?.features.eln && (
            <>
              {!!person.relations?.labNotebooks.count && (
                <LabNotebooksTile
                  defaultFilters={{ createdByIds: [person.id] }}
                  excludeFilters={{ createdByIds: () => false }}
                />
              )}
              {!!person.relations?.labNotebooksEntryMentions.count && (
                <LabNotebookEntriesTile
                  title="Notebook mentions"
                  defaultFilters={{ personIds: [person.id] }}
                  excludeFilters={{ personIds: () => false }}
                />
              )}
            </>
          )} */}
          <CustomFieldReferencesTile
            entityType="Person"
            entity={entity}
            labNotebookFilters={session?.features.eln ? { createdByIds: [entity.id] } : undefined}
            labNotebookEntryFilters={session?.features.eln ? { personIds: [entity.id] } : undefined}
          />
          {/* <AttachmentsTileWrapped entity={person} /> */}
          {!entity.relations?.datasets.count && <DatasetsTile disabled />}
          {!entity.relations?.samples.count && <SamplesTile disabled />}
          {/* {session?.features.eln && (
            <>
              {!person.relations?.labNotebooks.count && <LabNotebooksTile disabled />}
              {!person.relations?.labNotebooksEntryMentions.count && (
                <LabNotebookEntriesTile disabled title="Notebook mentions" />
              )}
            </>
          )} */}
        </>
      }
    />
  );
};
