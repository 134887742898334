import React, { useCallback, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import {
  commandTypes,
  pipelineSetting,
  SerializedPipeline,
  SerializedPipelineCommand,
} from "../../../ProcessingPipeline/PipelineTypes";
import { ProcessingGraphComponent } from "../../../TrackTools/ProcessingGraphComponent/ProcessingGraphComponent";
import { pipelineNodeState } from "../../../ViewerLayout/ViewerLayoutTypes";
import { clickType } from "../../../ViewerLayout/ViewerTypes";
import { Resizeable } from "../../../ViewerUIElements/Resizeable";
import { SelectButton } from "../../../ViewerUIElements/SelectButton";
import { ProcessingNodeParameter } from "./ProcessingNodeParameter";
import styles from "./ShowPipeline.module.css";

const colorList = [
  "Gray",
  "Salmon",
  "HotPink",
  "PaleVioletRed",
  "Coral",
  "Orange",
  "Gold",
  "Khaki",
  "Violet",
  "PaleGreen",
  "DarkSeaGreen",
  "Aquamarine",
  "PowderBlue",
];

const commandColor = Object.fromEntries(
  Object.values(commandTypes).map((c, i) => [c, colorList[i % colorList.length]])
);

const getCommandColor = (command?: string): string => commandColor[command ?? ""] ?? "white";

export const ShowPipeline = ({
  pipeline,
  pipelineStates,
  graphClick,
  changePipelineParameter,
}: {
  pipeline: SerializedPipeline;
  pipelineStates: Record<string, pipelineNodeState>;
  changePipelineParameter: (settings: pipelineSetting[]) => void;
  graphClick: clickType;
}) => {
  const [selected, setSelected] = useState<string>();
  const [selectedNode, setSelectedNode] = useState<SerializedPipelineCommand>();
  const [selecteState, setSelecteState] = useState<pipelineNodeState>();
  const [runable, setRunable] = useState<boolean>();
  const [autorun, setAutorun] = useState<boolean>(pipeline.settings.autorun);
  const [currentPipeline, setCurrentPipeline] = useState<string>(pipeline.id);

  useEffect(() => {
    setRunable(!autorun && (pipeline.nodeErrorCount ? pipeline.nodeErrorCount < 1 : true));

    // console.log(">>", !pipeline.settings.autorun, pipeline.nodeErrorCount ? pipeline.nodeErrorCount < 1 : true);
  }, [autorun, pipeline.nodeErrorCount]);

  useEffect(() => {
    const node = pipeline.pipeline.filter((n) => n.id === selected).shift();
    setSelectedNode(node);
    setSelecteState(node ? pipelineStates?.[node.id] : undefined);
  }, [selected, pipeline, pipelineStates]);

  // useEffect(() => {
  //   console.log("> ShowPipeline changePipelineParameter");
  // }, [changePipelineParameter]);

  // useEffect(() => {
  //   if (setSelected && selected === undefined) {
  //     let node = "";
  //     if (pipeline?.settings?.selectedNode) node = pipeline.settings.selectedNode;
  //     else if (pipeline?.pipeline && pipeline.pipeline.length > 0) node = pipeline.pipeline[0].id;
  //     setSelected(node);
  //   }
  // }, [selected, pipeline]);

  useEffect(() => {
    const node = pipeline.pipeline.filter((n) => n.id === selected).shift();
    if (!node) {
      let node = "";
      if (pipeline?.settings?.selectedNode) node = pipeline.settings.selectedNode;
      else if (pipeline?.pipeline && pipeline.pipeline.length > 0) node = pipeline.pipeline[0].id;
      setSelected(node);
    }
    setAutorun(pipeline.settings.autorun);
  }, [pipeline, selected]);

  const setParameter = useCallback(
    (nodeId: string, parameter: Record<string, any>) => {
      changePipelineParameter([{ pipelineId: currentPipeline, nodeId: nodeId, settings: parameter }]);
    },
    [currentPipeline, changePipelineParameter]
  );

  // useEffect(() => {
  //   console.log("> ShowPipeline setParameter")
  // }, [currentPipeline, changePipelineParameter]);

  useEffect(() => {
    if (currentPipeline !== pipeline.id) setCurrentPipeline(pipeline.id);
    // ignoring dependency as we don't want a cyclic execution if this is dependent on currentPipeline
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pipeline.id]);

  useEffect(() => {
    changePipelineParameter([{ pipelineId: pipeline.id, nodeId: "", settings: { autorun: autorun } }]);
  }, [autorun, changePipelineParameter, pipeline.id]);

  // const setAutoRun = useCallback(
  //   (autorun: boolean) => {
  //     // console.log("test", autorun)
  //     changePipelineParameter([{ pipelineId: pipeline.id, nodeId: "", settings: { autorun: autorun } }]);
  //   },
  //   [pipeline, changePipelineParameter]
  // );

  // useEffect(() => {
  //   const p = (pipeline.pipeline?.[0] as any)?.parameter;
  //   if (p) console.log("spipelines", Object.keys(p));
  // }, [pipeline]);

  // useEffect(() => {
  //   const p = (pipeline.pipeline?.[0] as any)?.parameter;
  //   if (p) console.log("spipelines", Object.keys(p));
  // }, [pipeline]);

  // useEffect(() => {
  //   const p = (selectedNode as any)?.parameter?.tracks;
  //   if (p) console.log("selected", Object.keys(p));
  // }, [selectedNode]);

  const runPipeline = useCallback(() => {
    // console.log("test", autorun)
    changePipelineParameter([{ pipelineId: pipeline.id, nodeId: "", settings: { runPipeline: true } }]);
  }, [pipeline, changePipelineParameter]);

  return (
    <div className={styles.show}>
      <div className={styles.controls}>
        <Button bsSize="xsmall" disabled={!runable} onClick={() => runPipeline()}>
          <span
            className={"glyphicon glyphicon-play"}
            style={{
              color: runable ? "green" : "gray",
            }}
          />{" "}
          Run Pipeline
        </Button>
        &nbsp;
        {/* <SelectButton active={pipeline.settings.autorun} setActive={setAutoRun}> */}
        <SelectButton active={autorun} setActive={setAutorun}>
          Auto run
        </SelectButton>
        {pipeline.nodeErrorCount && pipeline.nodeErrorCount > 0 ? (
          <span className={styles.errorText}>&nbsp;{pipeline.nodeErrorCount} node errors</span>
        ) : null}
      </div>
      <div className={styles.line}></div>
      <div className={styles.split}>
        <div className={styles.graph}>
          <Resizeable>
            <ProcessingGraphComponent
              pipeline={pipeline}
              pipelineStates={pipelineStates}
              selected={selected}
              setSelected={setSelected}
              getCommandColor={getCommandColor}
            />
          </Resizeable>
        </div>
        <div className={styles.settings}>
          <Resizeable>
            <ProcessingNodeParameter
              node={selectedNode}
              graphClick={graphClick}
              setParameter={setParameter}
              nodeState={selecteState}
              getCommandColor={getCommandColor}
            />
          </Resizeable>
        </div>
      </div>
    </div>
  );
};
