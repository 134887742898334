import { DetailsPageTopControlsView } from "../common/panels/misc/DetailsPageTopControlsView/DetailsPageTopControlsView";
import Detail from "../common/panels/Detail/Detail";
import { DatasetsTile } from "../common/sidebar/tiles/DatasetsTile";
import { useHistory } from "react-router-dom";
import { Table } from "../common/panels/Detail/DetailTable";
import { useState } from "react";
import { DateTimeRenderer } from "../common/datetime/DateTimeFormatter";
import { LucideIcon } from "../common/icon/LucideIcon";
import { AlertModal } from "../common/modals/AlertModal/AlertModal";
import { MoreDropdown } from "../common/buttons/MoreDropdown/MoreDropdown";
import { showtoast } from "../common/overlays/Toasts/showtoast";
import { useFeatureFlag } from "../common/contexts/UseFeatureFlags";
import { NotSet } from "../common/misc/UIconstants";
import { EntityDetailProps, EntityDetailTableProps } from "../common/entity/EntityInterfaces";
import { GetProjects, GetOrganizations, GetPersons } from "../common/misc/EntityRenders/EntityRenderer";
import { toUppercase } from "../common/helperfunctions/stringFunctions";
import { CustomTypeDetailRow, CustomTypeRenderer } from "../Customization/CustomTypes/generics/CustomTypeRenderer";
// import { AttachmentsTileWrapped } from "../common/sidebar/tiles/AttachmentsTile";
import { CustomFieldReferencesTile } from "../common/sidebar/tiles/CustomFieldReferencesTile";
import { EntityRestoreButton } from "../common/entity/entityComponents/EntityRestoreButton";

export const SampleDetailTable = ({ entity, entityConstants, fieldLabels }: EntityDetailTableProps<"samples">) => {
  return (
    <>
      <Table noPadding nonFoldable>
        <Table.Head>Basic details</Table.Head>
        <Table.Body>
          <Table.Body.RowContent
            title={fieldLabels.name}
            content={
              <div className={"container_label"}>
                <div className={"container_label_name"} style={{ fontWeight: 600 }}>
                  {entity.name ?? NotSet}
                </div>
                <div className={"container_label_id"}>
                  <span style={{ whiteSpace: "nowrap" }}>Sample-ID: {entity.id}</span>
                </div>
              </div>
            }
          />
          {!!entity.sequenceNumber && (
            <Table.Body.RowContent
              title={fieldLabels.sequenceNumber}
              content={
                <div className={"container_label"}>
                  <div className={"container_label_name"}>{`${
                    entity.sequenceNumber ? `#${entity.sequenceNumber}` : NotSet
                  }`}</div>
                </div>
              }
            />
          )}
          <CustomTypeDetailRow entity={entity} entityConstants={entityConstants} />
          <Table.Body.RowContent
            title={fieldLabels.projects}
            content={<GetProjects projects={entity.projects} createLinks={true} />}
          />

          <Table.Body.RowContent title={fieldLabels.preparedBy} content={<GetPersons persons={entity.preparedBy} />} />

          <Table.Body.RowContent
            title={fieldLabels.preparedAt}
            content={
              <>{entity.preparedAt ? <DateTimeRenderer date={entity.preparedAt} includeTime={false} /> : <>-</>}</>
            }
          />

          <Table.Body.RowContent
            title={fieldLabels.organizations}
            content={<GetOrganizations organizations={entity.organizations} createLinks={true} />}
          />

          {Array.isArray(entity.discardedBy) && entity.discardedBy.length > 0 && (
            <Table.Body.RowContent
              title={fieldLabels.discardedBy}
              content={<GetPersons persons={entity.discardedBy} />}
            />
          )}
          {entity.discardedAt && (
            <Table.Body.RowContent
              title={fieldLabels.discardedAt}
              content={
                <>{entity.discardedAt ? <DateTimeRenderer date={entity.discardedAt} includeTime={false} /> : <>-</>}</>
              }
            />
          )}

          <Table.Body.RowContent
            title={fieldLabels.notes}
            content={
              <>
                {entity.notes ? (
                  <div className={"container_notes"}>
                    <textarea rows={4} className={"container_textarea"} value={entity.notes} disabled />
                  </div>
                ) : (
                  NotSet
                )}
              </>
            }
          />
        </Table.Body>
      </Table>
      <CustomTypeRenderer entity={entity} />
    </>
  );
};

export const SampleDetail = ({
  permissions,
  routes,
  entity,
  entityApi,
  entityConstants,
  fieldLabels,
}: EntityDetailProps<"samples">) => {
  const history = useHistory();
  const isElnActive = useFeatureFlag("eln");
  const [showModal, setShowModal] = useState(false);

  return (
    <Detail
      head={
        <DetailsPageTopControlsView
          entity={entity}
          entityConstants={entityConstants}
          attributes={
            <>
              {entity.isDeleted && (
                <h2 style={{ margin: 0 }}>
                  <label className="label label-sm label-soft-warning" style={{ margin: 0 }} title={"Trashed"}>
                    <LucideIcon name="trash" />
                  </label>
                </h2>
              )}
            </>
          }
          controls={
            <>
              {!entity.isDeleted && (
                <button
                  className="btn btn-default"
                  title={`Edit this Sample`}
                  onClick={() => history.push(routes.getEditLink(entity.id))}
                  disabled={!permissions.canEdit(entity)}
                >
                  <LucideIcon name="square-pen" /> Edit
                </button>
              )}
              {entity.isDeleted && (
                <EntityRestoreButton
                  entity={entity}
                  entityConstants={entityConstants}
                  entityApi={entityApi}
                  permissions={permissions}
                />
              )}

              <MoreDropdown drop="right" btn="btn btn-ghost-secondary">
                <li>
                  <button
                    className="btn btn-sm btn-ghost-secondary"
                    title={`Clone this sample`}
                    onClick={() => history.push(routes.getCloneLink(entity.id))}
                    disabled={!permissions.canCreate}
                  >
                    <LucideIcon name="copy" /> Clone
                  </button>
                </li>
                <li>
                  <button
                    className={`btn btn-sm btn-ghost-${entity.isDeleted ? "danger" : "warning"}`}
                    title={`${entity.isDeleted ? "Delete" : "Move to trash"}`}
                    onClick={() => setShowModal(true)}
                    disabled={entity.isDeleted ? !permissions.canDelete(entity) : !permissions.canTrash(entity)}
                  >
                    <LucideIcon name="trash-2" /> {`${entity.isDeleted ? "Delete" : "Trash"}`}
                  </button>
                </li>
              </MoreDropdown>
              <AlertModal
                type={`${entity.isDeleted ? "danger" : "warning"}`}
                showModal={showModal}
                setShowModal={setShowModal}
                title={`${
                  entity.isDeleted ? `Permanently delete ${entityConstants.entitySingular}?` : "Move to trash?"
                }`}
                description={
                  entity.isDeleted
                    ? `This ${entityConstants.entitySingular} may be referenced by other entities. Deleting a ${entityConstants.entitySingular} is only possible if all references are removed.`
                    : `This will move the ${entityConstants.entitySingular} into trash. It is possible to restore this ${entityConstants.entitySingular} at any time.`
                }
                proceedLabel={entity.isDeleted ? "Delete" : "Trash"}
                onProceed={async () => {
                  if (entity.isDeleted) {
                    await entityApi
                      .deleteMutationAsync(
                        {
                          id: entity.id,
                          goBackOnSuccess: false,
                          showToast: false,
                          entityName: entityConstants.entitySingular,
                          params: { deletePermanently: true },
                        },

                        {
                          onSuccess: () => {
                            showtoast(
                              "success",
                              `Deleted ${toUppercase(entityConstants.entitySingular)}-ID: ${entity.id}.`
                            );
                            history.goBack();
                          },
                        }
                      )
                      .catch((e) => {});
                  } else {
                    await entityApi
                      .deleteMutationAsync(
                        {
                          id: entity.id,
                          goBackOnSuccess: false,
                          showToast: false,
                          entityName: entityConstants.entitySingular,
                        },

                        {
                          onSuccess: () => {
                            showtoast(
                              "success",
                              `Moved ${toUppercase(entityConstants.entitySingular)}-ID: ${entity.id} to trash.`
                            );
                            setShowModal(false);
                          },
                        }
                      )
                      .catch((e) => {});
                  }
                }}
                loading={entityApi.isLoadingDeleteMutation}
              />
            </>
          }
        />
      }
      mainStyle={{ padding: "0px 10px" }}
      main={
        <div className="flex col-nowrap">
          <SampleDetailTable entity={entity} entityConstants={entityConstants} fieldLabels={fieldLabels} />
        </div>
      }
      sidebar={
        <>
          {entity.relations && (
            <>
              {!!entity.relations.datasets.count && (
                <DatasetsTile
                  defaultFilters={{ sampleIds: [entity.id] }}
                  excludeFilters={{ sampleIds: () => false }}
                  allowDownload
                />
              )}

              {/* {isElnActive && !!sample.relations.labNotebookEntries?.count && (
                <LabNotebookEntriesTile
                  title="Notebook mentions"
                  defaultFilters={{ sampleIds: [sample.id] }}
                  excludeFilters={{ sampleIds: () => false }}
                />
              )} */}

              <CustomFieldReferencesTile
                entityType="Sample"
                entity={entity}
                labNotebookEntryFilters={isElnActive ? { sampleIds: [entity.id] } : undefined}
              />
              {/* <AttachmentsTileWrapped entity={sample} /> */}
              {/* <SamplesTile title="Related Samples" defaultFilters={{ excludeIds: [sample.id] }} /> */}

              {/* Empty tiles will go here */}
              {!entity.relations.datasets.count && <DatasetsTile disabled />}
              {/* {isElnActive && !sample.relations.labNotebookEntries?.count && (
                <LabNotebookEntriesTile disabled title="Notebook mentions" />
              )} */}
            </>
          )}
        </>
      }
    />
  );
};
