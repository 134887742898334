import { BrowserRouter, Route, Switch } from "react-router-dom";
import { SharedBundlesContextWrapper, SharedBundlesUIWrapper } from "../SharedBundles/SharedBundles";
import { SharedBundlesView } from "../SharedBundles/SharedBundlesView";
import { GroupSelectionPage } from "./GroupSelector/GroupSelector";
import { ForgotPassword } from "./ForgotPassword/ForgotPassword";
import { DatasetDetailsPage } from "../Dataset/DatasetViews";
import { Login } from "./LoginPage/Login";
import { ErrorBoundary } from "./ErrorBoundary/ErrorBoundary";
import { OidcRedirect } from "./LoginPage/OidcRedirect";
import { AuthenticationConfigProvider } from "./LoginPage/AuthenticationConfigProvider";
import { GroupProvider } from "./LoginPage/GroupProvider";

export const GroupRouter = () => (
  <ErrorBoundary>
    <BrowserRouter>
      <Switch>
        <Route path="/:group/oidc_redirect">
          <GroupProvider>
            {({ group }) => (
              <AuthenticationConfigProvider group={group}>
                <OidcRedirect />
              </AuthenticationConfigProvider>
            )}
          </GroupProvider>
        </Route>
        <Route path="/:group/shared/:sessionId/:id" exact={true}>
          <SharedBundlesContextWrapper>
            <SharedBundlesUIWrapper>
              <DatasetDetailsPage viewShared={true} />
            </SharedBundlesUIWrapper>
          </SharedBundlesContextWrapper>
        </Route>
        <Route path="/:group/shared/:sessionId/:id/viewer" exact={true}>
          <SharedBundlesContextWrapper>
            <SharedBundlesUIWrapper>
              <DatasetDetailsPage viewShared={true} viewViewer={true} />
            </SharedBundlesUIWrapper>
          </SharedBundlesContextWrapper>
        </Route>
        <Route path="/:group/shared/:sessionId" exact={true}>
          <SharedBundlesContextWrapper>
            <SharedBundlesUIWrapper>
              <SharedBundlesView />
            </SharedBundlesUIWrapper>
          </SharedBundlesContextWrapper>
        </Route>
        <Route path="/:group/forgot-password" exact>
          <ForgotPassword />
        </Route>
        <Route path="/:group/">
          <Login />
        </Route>
        <Route path="" exact={true}>
          <GroupSelectionPage />
        </Route>
      </Switch>
    </BrowserRouter>
  </ErrorBoundary>
);
