import produce from "immer";
import React, { useEffect, useState } from "react";
import { Button, FormControl } from "react-bootstrap";
import { Range } from "react-range";
import { useInputDebounce } from "../../ViewerUIElements/InputDebounce";
import { getDigit, RangeType } from "./ContourEditor";
import { ContourType } from "./OneBaselineContourEditor";

import styles from "./OneBaselineContourEditor.module.css";
import { SliderThumbBlock, SliderTrack } from "./SliderElements";

const step = 0.001;
const maxScale = 10;
const minScale = 1 + step;
type directionType = -1 | 0 | 1;

type stateType = { state: boolean; value: number };
const validateValue = (value: string, range: RangeType): stateType => {
  let f = parseFloat(value);
  const state: stateType = { value: f, state: true };
  if (isNaN(f) || f <= 1 || f > maxScale) state.state = false;
  return state;
};

export const OneBaselinePreviousScaled = ({
  contours,
  setContours,
  setMinHeight,
}: {
  contours: ContourType;
  setContours: (contours: ContourType) => void;
  setMinHeight?: (height: number) => void;
}) => {
  const [minMaxStr, setMinMaxStr] = useState<string[]>(["", ""]);
  const [countInput, setCountInput] = useState<string>("");
  const [countValid, setCountValid] = useState<boolean>(true);

  const debouncedInput = useInputDebounce<string>(countInput, 500);

  useEffect(() => {
    if (setMinHeight) setMinHeight(240);
  }, []);

  // useEffect(() => {
  //   //   if (scale[0] === undefined) return;
  //   //   console.log(">>>", scale, getContoursFromOffsets(scale, range));
  //   //   setCountInput("" + contours.scale);
  //   //   setCountValid(true);
  //   //   setContours(
  //   //     produce(contours, (next) => {
  //   //       next.count = scale[0];
  //   //     })
  //   //   );
  //   // (values: number[]) => {
  //   //   setContours(
  //   //     produce(contours, (next) => {
  //   //       next.scale = getContoursFromOffsets(values, contours.range);
  //   //     })
  //   //   );
  //   //   console.log("values:", values);
  //   // }
  //   console.log("values:", getContoursFromOffsets(scale, contours.range));
  //   setContours(
  //     produce(contours, (next) => {
  //       next.scale = getContoursFromOffsets(scale, contours.range);
  //     })
  //   );
  //   // );
  // }, [scale]);

  useEffect(() => {
    // console.log("contours", contours);
    // const s = getOffsetsFromContours(contours);
    // if (Math.abs(scale[0] - s[0]) >= step) setScale(s);
    if (contours.scale > maxScale)
      setContours(
        produce(contours, (next) => {
          next.scale = maxScale;
        })
      );

    if (contours.scale < minScale)
      setContours(
        produce(contours, (next) => {
          next.scale = minScale;
        })
      );

    const digit = getDigit(maxScale - 1);
    setMinMaxStr([(1).toFixed(digit), maxScale.toFixed(digit)]);

    const input = "" + contours.scale.toFixed(digit + 1);
    if (input !== countInput) setCountInput("" + contours.scale.toFixed(digit + 2));
    setCountValid(true);
  }, [contours]);

  useEffect(() => {
    const valid = validateValue(countInput, contours.range);
    setCountValid(valid.state);
    if (valid.state)
      setContours(
        produce(contours, (next) => {
          next.scale = valid.value;
        })
      );
  }, [debouncedInput]);

  return (
    <div>
      <div className={styles.sliderText}>
        Direction:{" "}
        <Button
          bsSize="xs"
          onClick={() => {
            setContours(
              produce(contours, (next) => {
                next.direction = (contours.direction + 1 > 1 ? -1 : contours.direction + 1) as directionType;
              })
            );
          }}
        >
          <span
            className={
              "glyphicon " +
              (contours.direction < 0
                ? "glyphicon-arrow-left"
                : contours.direction > 0
                ? "glyphicon-arrow-right"
                : "glyphicon-resize-horizontal")
            }
          />
        </Button>
      </div>
      <div className={styles.rangeSetter}>
        <div className={styles.settingText} style={{ position: "relative", top: -10 }}>
          Scale:
        </div>
        <div style={{ flexGrow: 1, minWidth: 30 }}>
          <Range
            step={step}
            min={minScale}
            // disabled={disabled}
            max={maxScale}
            values={[contours.scale > maxScale ? maxScale : contours.scale < minScale ? minScale : contours.scale]}
            renderTrack={SliderTrack}
            onChange={(scale: number[]) =>
              setContours(
                produce(contours, (next) => {
                  next.scale = scale[0];
                })
              )
            }
            // onChange={setScale}
            renderThumb={SliderThumbBlock}
          />
          <div className={styles.range}>
            <span>{minMaxStr[0]}</span>
            <span>{minMaxStr[1]}</span>
          </div>
        </div>
        <div style={{ paddingLeft: 5, position: "relative", top: -10 }}>
          <FormControl
            type="text"
            value={countInput}
            onChange={(e) => setCountInput((e.target as HTMLInputElement).value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") setCountInput((e.target as HTMLInputElement).value);
            }}
            onFocus={(e) => (e.target as any as HTMLInputElement).select()}
            bsSize={"sm"}
            style={{ width: "6em", scale: "90%", background: countValid ? undefined : "salmon" }}
          />
        </div>
      </div>
    </div>
  );
};
