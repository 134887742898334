import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Table as BTable } from "react-bootstrap";
import { useResizeDetector } from "react-resize-detector";
import { Cell, Column, useGlobalFilter, useRowSelect, useSortBy, useTable } from "react-table";
import { useGet, useInfiniteListEntity, useUnpaginateOrdered } from "../api/BaseEntityApi";
import { DatasetFilters } from "../api/Datasets";
import { Parser, ParserFilters } from "../api/Parsers";
import { ParserStatistics, ParsingOutputModel, EnqueueOptions, ParsingStatistics } from "../api/Parsing";
import { SessionContext } from "../common/contexts/SessionContext";
import { LucideIcon } from "../common/icon/LucideIcon";
import { Spinner } from "../common/loaders/Spinner/Spinner";
import { LoadingWrapper } from "../common/LoadingWrapper";
import { NoEntriesFound } from "../common/NoEntriesFound";
import { Alert } from "../common/overlays/Alert/Alert";
import { showtoast } from "../common/overlays/Toasts/showtoast";
import { PaginationView } from "../common/PaginationView";
import TableView from "../common/panels/TableView/TableView";
import { getDatasetsZip } from "../Dataset/common/DownloadDataset/DownloadDataset";
import { GenericModal } from "../common/modals/Modal/GenericModal";
import styles from "./Maintenance.module.css";
import { MaintenanceSidebar } from "./MaintenanceSidebar";
import { ParserStatusError } from "./ParserStatusError";
import { SearchInput } from "../common/forms/SearchInput/SearchInput";
import { SelectFormField } from "../common/formfields/SelectFormField";
import { useForm, useWatch } from "react-hook-form";
import { PageNotAllowed } from "../main/common/PageNotAllowed/PageNotAllowed";

const IndeterminateCheckbox = React.forwardRef<HTMLInputElement, any>(({ indeterminate, ...rest }, ref) => {
  const defaultRef = React.useRef<HTMLInputElement>();
  const resolvedRef = ref || defaultRef;

  React.useEffect(() => {
    (resolvedRef as React.MutableRefObject<HTMLInputElement>).current.indeterminate = indeterminate;
  }, [resolvedRef, indeterminate]);

  return (
    <div>
      <input type="checkbox" ref={resolvedRef} {...rest} />
    </div>
  );
});

interface GlobalFilterProps {
  preGlobalFilteredRows: any[];
  globalFilter: string;
  setGlobalFilter: React.Dispatch<string>;
}
const GlobalFilter = ({ preGlobalFilteredRows, globalFilter, setGlobalFilter }: GlobalFilterProps) => {
  // const count = preGlobalFilteredRows.length;
  const [value, setValue] = React.useState(globalFilter);
  const onChange = (value: string) => {
    setValue(value);
    setGlobalFilter(value);
  };

  return (
    <span
      style={{
        width: "100%",
        fontWeight: "normal",
      }}
    >
      <SearchInput searchValue={value} setSearchValue={onChange} />
      {/* <input
        className="form-control input-sm"
        value={value || ""}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder={`Search...`}
        style={{
          width: "100%",
          fontWeight: "normal",
        }}
      /> */}
    </span>
  );
};

interface TableProps {
  columns: Column<any>[];
  data: any[];
  setSelection: React.Dispatch<React.SetStateAction<string[] | undefined>>;
}
const Table = ({ columns, data, setSelection }: TableProps) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    selectedFlatRows,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
  } = useTable<{ [parserId: string]: Parser }>(
    {
      columns,
      data,
      autoResetSelectedRows: false,
      autoResetSelectedCell: false,
      autoResetSelectedColumn: false,
      autoResetGlobalFilter: false,
      autoResetSortBy: false,
    },
    useGlobalFilter,
    useSortBy,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        // Let's make a column for selection
        {
          id: "selection",
          // The header can use the table's getToggleAllRowsSelectedProps method
          // to render a checkbox
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <div style={{ display: "flex", flexFlow: "column nowrap", width: "100%", alignItems: "center" }}>
              <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
            </div>
          ),
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          Cell: ({ row }: { row: any }) => (
            <div style={{ display: "flex", flexFlow: "column nowrap", width: "100%", alignItems: "center" }}>
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </div>
          ),
        },
        ...columns,
      ]);
    }
  );

  useEffect(() => {
    const res = selectedFlatRows.map((d) => d.original.id);
    if (res) setSelection(res as any);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFlatRows]);

  return (
    <BTable striped bordered hover sizes="sm" {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column, index) => (
              <th key={`th-${index}`}>
                <div className={styles.column}>
                  <div className={styles.row} {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render("Header")}
                    {index > 0 && (
                      <span>
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <LucideIcon name="chevron-down" />
                          ) : (
                            <LucideIcon name="chevron-up" />
                          )
                        ) : (
                          <LucideIcon name="code" style={{ transform: "rotate(90deg)", color: "var(--gray-300)" }} />
                        )}
                      </span>
                    )}
                  </div>
                  {0 < index && index < 5 && (
                    <GlobalFilter
                      preGlobalFilteredRows={preGlobalFilteredRows}
                      globalFilter={state.globalFilter}
                      setGlobalFilter={setGlobalFilter}
                    />
                  )}
                </div>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row);
          return (
            <tr
              {...row.getRowProps()}
              // onClick={() => console.log(row.original)}
            >
              {row.cells.map((cell) => {
                return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
              })}
            </tr>
          );
        })}
      </tbody>
    </BTable>
  );
};

interface ParserStatus extends Parser {
  parserStatus: ParserStatistics;
}
interface ParserErrorsFilterObject {
  parserType: string[] | null;
}

export const Maintenance = () => {
  const updateInterval = 5000;
  const { api, session } = useContext(SessionContext);
  const [parsers, setParsers] = useState<{ [parserId: string]: Parser }>();
  const [parserStats, setParserStats] = useState<Record<string, ParserStatistics>>();
  const { width, height, ref } = useResizeDetector();
  const [selection, setSelection] = useState<string[]>();
  const [enqueueFetching, setEnqueueFetching] = useState(false);
  const [dequeueFetching, setDequeueFetching] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isSingleSelection, setIsSingleSelection] = useState(false);
  const [page, setPage] = useState(1);
  const pageSize = 5;
  const [filterObject, setFilterObject] = useState<ParserErrorsFilterObject>();
  const [parserErrors, setParserErrors] = useState<ParsingOutputModel[]>([]);
  const [parsingStatisticsAggregated, setParsingStatisticsAggregated] = useState<ParserStatistics>();

  // Post requests
  const {
    count: parsingErrorsCount,
    data: parsingErrorsQuery,
    error: parsingErrorsError,
    status: parsingErrorsStatus,
    fetchStatus: parsingErrorfetchStatus,
    // fetchNextPage: parsingErrorsFetchNextPage,
    // hasNextPage: parsingErrorsHasNextPage,
    // isFetching: parsingErrorsIsFetching,
    // count: parsingErrorsCount,
    // refetch: parsingErrorsRefetch,
  } = useInfiniteListEntity<ParsingOutputModel>(
    "parsing/errors",
    {
      page: page,
      pageSize: pageSize,
      includeCount: true,
      ...filterObject,
    },
    { enabled: !!filterObject },
    "post"
  );
  useMemo(() => {
    if (filterObject) {
      setPage(1);
    }
  }, [filterObject]);

  useMemo(() => {
    if (parsingErrorsQuery) {
      setParserErrors(() => parsingErrorsQuery.pages.map((d) => d.results).flat());
    }
  }, [parsingErrorsQuery]);

  // Helper functions
  const handleDownloadAll = useCallback(
    async (filterObj: DatasetFilters) => {
      if (filterObj) {
        try {
          // const ids = await api.getDatasetsIds(filterObj);
          await getDatasetsZip(api, filterObj);
        } catch {
          showtoast("error", "Sorry, an error occurred while processing your request");
        }
      }
    },
    [api]
  );

  const handleEnqueueFailed = useCallback(
    async (filterObj: EnqueueOptions) => {
      if (filterObj) {
        try {
          // const ids = await api.Datasets.getIds(filterObj);
          const res = await api.Datasets.enqueueDatasets(filterObj);
          const total = Object.values(res).reduce((a, b) => a + b, 0);
          showtoast("success", `Enqueued ${total} datasets...`);
          setEnqueueFetching(false);
        } catch {
          showtoast("error", "Sorry, an error occurred while processing your request");
          setEnqueueFetching(false);
        }
      }
    },
    [api]
  );

  // GET requests
  const {
    data: parserStatisticsQuery,
    // error: parserStatisticsFetchError,
    status: parserStatisticsFetchStatus,
    refetch: parserStatisticsRefetch,
    isFetching: parserStatisticsIsFetching,
  } = useGet<ParsingStatistics>("parsing/statistics", null, { enabled: true });

  const refetchStats = useCallback(() => {
    !parserStatisticsIsFetching && parserStatisticsRefetch();
  }, [parserStatisticsIsFetching, parserStatisticsRefetch]);

  useEffect(() => {
    const i = setInterval(refetchStats, updateInterval);
    return () => clearInterval(i);
  }, [refetchStats]);

  const {
    data: parsersQuery,
    error: parsersFetchError,
    status: parsersStatus,
    fetchStatus: parsersfetchStatus,
    // refetch: parsersRefetch,
  } = useUnpaginateOrdered<Parser, ParserFilters>("parsers", { orderBy: "NAME_ASC" });

  // POST requests
  const enqueueParsers = useCallback(
    async (queueParsers: { ids?: number[]; parserTypes?: string[] }) => {
      try {
        setEnqueueFetching(true);
        // const res = (await api.post("parsing/enqueue", queueParsers)) as { [parserId: string]: number };
        const res = await api.Parsing.enqueueParsing(queueParsers);
        const total = Object.values(res).reduce((a, b) => a + b, 0);
        showtoast("success", `Enqueued ${total} datasets...`);
        setEnqueueFetching(false);
      } catch {
        showtoast("error", "An internal error occurred sending the request to the Server.");
        setEnqueueFetching(false);
      }
    },
    [api]
  );

  const dequeueParsers = useCallback(
    async (dequeueParsers: { ids?: number[]; parserTypes?: string[] }) => {
      try {
        setDequeueFetching(true);
        // const res = (await api.post("parsing/dequeue", dequeueParsers)) as { [parserId: string]: number };
        const res = await api.Parsing.dequeueParsing(dequeueParsers);
        const total = Object.values(res).reduce((a, b) => a + b, 0);
        showtoast("success", `Dequeued ${total} datasets...`);
        setDequeueFetching(false);
      } catch {
        showtoast("error", "An internal error occurred sending the request to the Server.");
        setDequeueFetching(false);
      }
    },
    [api]
  );

  useMemo(() => {
    if (parserStatisticsQuery) {
      setParserStats(parserStatisticsQuery.byParser);
      setParsingStatisticsAggregated(parserStatisticsQuery.all);
    }
  }, [parserStatisticsQuery]);

  useMemo(() => {
    if (parsersQuery) {
      setParsers(Object.fromEntries(parsersQuery.map((parser) => [parser.id, parser])));
    }
  }, [parsersQuery]);

  const data = useMemo(() => {
    if (parsers && parserStats) {
      const available_parsers = Object.keys(parserStats);
      const res = Object.entries(parsers)
        .filter(([id, parser], index) => available_parsers.includes(id))
        .map(([id, parser], index) => ({
          ...parser,
          parserStatus: parserStats[parser.id],
        }));
      return res;
    }
  }, [parserStats, parsers]);

  const { register, control, setValue } = useForm<{ parsers: Parser[] | null }>({
    defaultValues: undefined,
  });

  const columns = useMemo(
    () => [
      {
        Header: "Format",
        accessor: "name",
      },
      {
        Header: "Format ID",
        accessor: "id",
      },
      {
        Header: "Method",
        accessor: (row: ParserStatus) =>
          row?.metaData
            ?.map((m) => m.method?.map((m) => m.name))
            .flat()
            .join(", "),
      },
      {
        Header: "Version",
        accessor: "version",
      },
      {
        Header: "Successful",
        accessor: (row: ParserStatus) => row.parserStatus.successfullyParsed + row.parserStatus.notParseable,
      },
      {
        Header: "Queued",
        accessor: (row: ParserStatus) => row.parserStatus.inQueue,
      },
      {
        Header: "Failed",
        accessor: (row: ParserStatus) => row.parserStatus.parsingFailed,
      },
      {
        Header: "Not parsed",
        accessor: (row: ParserStatus) => row.parserStatus.notYetParsed,
      },
      {
        Header: "Debugging",
        id: "controls",
        Cell: (cell: Cell) => (
          <div className={styles.row}>
            <button
              className="btn btn-xs btn-soft-dark"
              title="Show errors"
              onClick={() => {
                const parserId = (cell.row.original as ParserStatus).id;
                // setSelection([parserId]); // Problematic as we need to control the checkboxes?
                setFilterObject({ parserType: [parserId] });
                setShowModal(true);
                setIsSingleSelection(true);
                if (parsers) {
                  setValue("parsers", [parsers[parserId]]);
                }
                // history.push(route(mainRoutes.parserStatus) + `/${parserId}`);
              }}
              disabled={(cell.row.original as ParserStatus).parserStatus.parsingFailed > 0 ? false : true}
            >
              <LucideIcon name="code" />
            </button>
            <button
              className={`btn btn-xs btn-soft-primary`}
              title="Download failed datasets"
              disabled={(cell.row.original as ParserStatus).parserStatus.parsingFailed > 0 ? false : true}
              onClick={() =>
                handleDownloadAll({
                  formatIds: [(cell.row.original as ParserStatus).id],
                  parsingState: ["ParsingFailed"],
                })
              }
            >
              <LucideIcon name="download" />
            </button>
            {session?.permissions.logsadmin && (
              <button
                className={`btn btn-xs btn-soft-success`}
                title="Enqueue failed datasets"
                disabled={(cell.row.original as ParserStatus).parserStatus.parsingFailed === 0}
                onClick={() =>
                  handleEnqueueFailed({
                    parserTypes: [(cell.row.original as ParserStatus).id],
                    parsingStates: ["ParsingFailed"],
                  })
                }
              >
                <LucideIcon name="coffee" />
              </button>
            )}
          </div>
        ),
      },
    ],
    [handleDownloadAll, handleEnqueueFailed, parsers, session?.permissions.logsadmin, setValue]
  );

  useEffect(() => {
    if (parsers && showModal && Array.isArray(selection) && !!selection.length && !isSingleSelection) {
      setValue(
        "parsers",
        selection.map((id) => parsers[id])
      );
    }
  }, [isSingleSelection, parsers, selection, setValue, showModal]);

  const selectedParsers = useWatch({ name: "parsers", control: control });

  useEffect(() => {
    setFilterObject({ parserType: selectedParsers ? selectedParsers.map((p) => p.id) : null });
  }, [selectedParsers]);

  const [modalWidth, setModalWidth] = useState<number>();
  const [modalHeight, setModalHeight] = useState<number>();
  const modalRef = useCallback((node: HTMLDivElement) => {
    if (node) {
      setModalHeight(node.getBoundingClientRect().height);
      setModalWidth(node.getBoundingClientRect().width);
    }
  }, []);
  if (
    ![
      session?.permissions.maintenance,
      session?.permissions.view_admin_pages,
      session?.features.activate_maintenance,
    ].some((p) => !!p === true)
  )
    return <PageNotAllowed />;
  return (
    <TableView>
      {!!parsingErrorsCount && (
        <GenericModal
          showModal={showModal}
          setShowModal={(show) => {
            setShowModal(show);
            setIsSingleSelection(false);
          }}
          modalTitle={
            <div style={{ display: "flex", flexFlow: "column nowrap", width: "100%", height: "fit-content" }}>
              <h2>Data format parsing errors</h2>
              <LoadingWrapper status={parsersStatus} fetchStatus={parsersfetchStatus} error={parsersFetchError}>
                {parsers && parserStatisticsQuery && (
                  <SelectFormField
                    id={"parsers"}
                    label={"Selected Data Formats:"}
                    {...register}
                    items={Object.keys(parserStatisticsQuery.byParser)
                      .filter((id) => parserStatisticsQuery.byParser[id].parsingFailed > 0)
                      .map((id) => parsers[id])}
                    control={control}
                    horizontal={false}
                    isMulti={true}
                  />
                )}
              </LoadingWrapper>
            </div>
          }
          modalBody={
            <div className={styles.modal_body} ref={modalRef}>
              <LoadingWrapper
                status={parsingErrorsStatus}
                fetchStatus={parsingErrorfetchStatus}
                error={parsingErrorsError}
              >
                {(!Array.isArray(parserErrors) || (Array.isArray(parserErrors) && !parserErrors.length)) && (
                  <NoEntriesFound />
                )}
                <div style={{ height: modalHeight, width: modalWidth, overflow: "auto" }}>
                  {parserErrors.map((item: ParsingOutputModel, index) => {
                    return <ParserStatusError item={item} key={index} />;
                  })}
                </div>
              </LoadingWrapper>
            </div>
          }
          modalControls={
            <div className="col-xs-12">
              <PaginationView
                listRecords={parserErrors}
                pageSize={Math.ceil(parsingErrorsCount / pageSize)}
                page={page}
                setPage={setPage}
              />
            </div>
          }
        />
      )}
      <TableView.Head>
        <TableView.Head.Label>Dataset Maintenance</TableView.Head.Label>
        <TableView.Head.Controls>
          {selection && selection.length > 0 && (
            <span className={styles.header_desc}>
              Selected {selection.length} data format{selection.length > 1 && "s"}
            </span>
          )}
          <button
            className="btn btn btn-soft-dark"
            title="Show tracebacks for the selected data formats"
            onClick={() => {
              selection && selection.length > 0 && setFilterObject({ parserType: selection });
              setShowModal(true);
            }}
            disabled={!(selection && selection.length > 0)}
          >
            <LucideIcon name="code" /> Show errors
          </button>
          <button
            className="btn btn btn-soft-primary"
            title="Download failed datasets for the selected data formats"
            onClick={() => {
              if (selection && selection.length > 0) {
                const total = selection
                  .map((parserId) => data?.filter((d) => d.id === parserId).map((d) => d.parserStatus.parsingFailed))
                  .flat(1)
                  .reduce((a, b) => (a || 0) + (b || 0), 0);
                Array.isArray(selection) && selection.length > 0 && total && total > 0
                  ? handleDownloadAll({ formatIds: selection, parsingState: ["ParsingFailed"] })
                  : showtoast("error", "There are no failed datasets for the selected data formats");
              }
            }}
            disabled={!(selection && selection.length > 0)}
          >
            <LucideIcon name="download" /> Download selected
          </button>

          {[session?.permissions.logsadmin, session?.features.activate_maintenance].some((p) => p === true) && (
            <button
              className={`btn btn btn-success ${enqueueFetching && "loading"}`}
              onClick={() => {
                if (Array.isArray(selection) && selection.length > 0) {
                  // setQueueParsers({ parserTypes: selection });
                  enqueueParsers({ parserTypes: selection });
                } else {
                  showtoast("error", "Please select at least one data format.");
                }
              }}
              disabled={enqueueFetching}
              // disabled={!(session?.permissions.logsadmin || session?.features.activate_maintenance) || enqueueFetching}
              title={"Queue the selected data formats for reparsing"}
            >
              {enqueueFetching ? <span className="spinner" /> : <LucideIcon name="coffee" />} Queue for parsing
            </button>
          )}
          {session?.permissions.logsadmin && (
            <button
              className={`btn btn-danger ${dequeueFetching && "loading"}`}
              onClick={() => {
                if (Array.isArray(selection) && selection.length > 0) {
                  // setDequeueParsers({ parserTypes: selection });
                  dequeueParsers({ parserTypes: selection });
                } else {
                  // setDequeueParsers({});
                  dequeueParsers({});
                }
              }}
              disabled={dequeueFetching}
              title={"Stop all pending operations"}
            >
              <LucideIcon name="x" /> Clear queue
            </button>
          )}
        </TableView.Head.Controls>
      </TableView.Head>
      <TableView.Body>
        <TableView.Body.Sidebar>
          <MaintenanceSidebar parsingStatisticsAggregated={parsingStatisticsAggregated} />
        </TableView.Body.Sidebar>
        <TableView.Body.Content>
          <div className={styles.container} ref={ref}>
            <div className={styles.table} style={{ height: height, width: width }}>
              {parserStatisticsFetchStatus !== "success" || parsersStatus !== "success" ? (
                <Spinner />
              ) : data ? (
                <Table columns={columns} data={data} setSelection={setSelection} />
              ) : (
                <Alert type="secondary" message="No content" fit centered />
              )}
            </div>
          </div>
        </TableView.Body.Content>
      </TableView.Body>
    </TableView>
  );
};
