import React, { useCallback } from "react";
import SinglePage from "../common/panels/SinglePage/SinglePage";
import { LucideIcon } from "../common/icon/LucideIcon";
import { useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useEntitiesRedirect } from "./useEntitiesRedirect";
import { useGetMutation } from "../api/BaseEntityApi";
import { EntityMinimalModelWithType } from "../api/ApiTypes";
import { useHistory } from "react-router-dom";
import { ErrorsRenderer } from "../common/forms/MultiEditForms/common/MultiEditRenderUtils";
import { entitiesConstants } from "../api/Entities";

interface SearchUUID {
  Input: string;
}

export const EntitiesSearch = () => {
  const redirectByUUID = useEntitiesRedirect();
  const history = useHistory();
  const { mutateAsync } = useGetMutation<EntityMinimalModelWithType, {}>(entitiesConstants.resource);

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<SearchUUID>({
    resolver: yupResolver(
      yup.object().shape({
        Input: yup.string().uuid().required(),
      })
    ),
  });
  const error = errors?.["Input"];
  const uuid = useWatch({ control, name: "Input" });

  const handleGo = useCallback(
    async (data: SearchUUID) => {
      console.log("GO");
      await mutateAsync(
        { id: data.Input as any },
        {
          onSuccess: (data) => {
            history.push(redirectByUUID(data));
          },
          onError: (error) => {
            // console.log(error);
          },
        }
      ).catch(() => {});
    },
    [history, mutateAsync, redirectByUUID]
  );

  return (
    <SinglePage>
      <SinglePage.Head>
        <SinglePage.Head.Label>
          <div className="flex row-nowrap align-center gap-5">
            <LucideIcon name="fingerprint" color={"var(--primary)"} style={{ width: "20px", height: "20px" }} /> Search
            by UUID
          </div>
        </SinglePage.Head.Label>
      </SinglePage.Head>
      <SinglePage.Content style={{ height: "fit-content" }}>
        <div className="flex col-nowrap" style={{ padding: 10 }}>
          <form
            onSubmit={handleSubmit(async (data) => {
              await handleGo(data);
            })}
          >
            <div className="input-group">
              <input
                {...register("Input")}
                className={`${error ? "border-danger form-control" : "form-control"}`}
                type="text"
                placeholder="Enter UUID..."
              />
              <span className={`input-group-btn`}>
                <button
                  className="btn btn-primary"
                  type="submit"
                  // onClick={async () => {
                  //   await handleSubmit(async (data) => {
                  //     await handleGo(data);
                  //   })();
                  // }}
                  disabled={!uuid}
                  style={{ ...(error && { borderColor: "var(--danger)" }) }}
                >
                  Go!
                </button>
              </span>
            </div>
          </form>
          <div style={{ marginLeft: 15 }}>{error && <ErrorsRenderer property="UUID" error={error} />}</div>
        </div>
      </SinglePage.Content>
    </SinglePage>
  );
};
