import { Fragment, useContext, useMemo, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { DetailsPageTopControlsView } from "../common/panels/misc/DetailsPageTopControlsView/DetailsPageTopControlsView";
import { SessionContext } from "../common/contexts/SessionContext";
import { LucideIcon } from "../common/icon/LucideIcon";
import { NotSet } from "../common/misc/UIconstants";
import { AlertModal } from "../common/modals/AlertModal/AlertModal";
import { showtoast } from "../common/overlays/Toasts/showtoast";
import Detail from "../common/panels/Detail/Detail";
import { Table } from "../common/panels/Detail/DetailTable";
import { getAddRoute, getCloneLink, getDetailLink, getEditLink, getIndexRoute } from "../main/Routing";
import { MoreDropdown } from "../common/buttons/MoreDropdown/MoreDropdown";
import { EntityDetailProps, EntityDetailTableProps } from "../common/entity/EntityInterfaces";
import { inventoriesConstants, hierarchyConstants } from "../api/Inventories";
import { CustomTypeRenderer } from "../Customization/CustomTypes/generics/CustomTypeRenderer";
import { InventoriesTile } from "../common/sidebar/tiles/InventoriesTile";
import { toUppercase } from "../common/helperfunctions/stringFunctions";
import { useFeatureFlag } from "../common/contexts/UseFeatureFlags";
import { GetProjects } from "../common/misc/EntityRenders/EntityRenderer";
import { Button } from "../common/buttons/Button/Button";
import { LinkButton } from "../common/buttons/LinkButton/LinkButton";
import { CustomType, customTypeConstants, CustomTypeFilters } from "../api/CustomTypes";
// import { AttachmentsTileWrapped } from "../common/sidebar/tiles/AttachmentsTile";
import { CustomFieldReferencesTile } from "../common/sidebar/tiles/CustomFieldReferencesTile";
import { EntityBreadcrumbs } from "../common/hierarchy/breadcrumbs/EntityBreadcrumbs";
import { AddCustomTypeEntity } from "../Customization/CustomTypes/generics/AddCustomTypeEntity";
import { useEntityDetail, useUnpaginateOrdered } from "../api/BaseEntityApi";
import { QuickAddWrapper } from "../common/forms/QuickAdd/common/QuickAddWrapper";
import { QuickAddInventoryItemForm } from "../common/forms/QuickAdd/forms/QuickAddInventoryItemForm";
import { EntityRestoreButton } from "../common/entity/entityComponents/EntityRestoreButton";

export interface InventoryItemProps extends EntityDetailTableProps<"inventoryItems"> {
  showHierarchy?: boolean;
}

export const InventoryItemsDetailTable = ({
  entity,
  entityConstants,
  fieldLabels,
  showHierarchy = false,
}: InventoryItemProps) => {
  const { route } = useContext(SessionContext);
  return (
    <>
      <Table noPadding nonFoldable>
        <Table.Head>Basic details</Table.Head>
        <Table.Body>
          {!!showHierarchy && !!entity.ancestors?.length && (
            <Table.Body.RowContent
              title={fieldLabels.ancestors}
              content={
                <div
                  className="container_label flex align-center gap-5"
                  style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}
                  title={entity.ancestors.map((a) => a.name).join(" > ") + " > " + entity.name}
                >
                  {entity.ancestors.map((ancestor, index) => (
                    <Fragment key={ancestor.id}>
                      <Link
                        to={`${route(getIndexRoute(entityConstants.frontendIndexRoute))}/${ancestor.id}`}
                        style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}
                      >
                        <LucideIcon name={entityConstants.icon} color="var(--primary)" /> {ancestor.name}
                      </Link>
                      {index < entity.ancestors.length - 1 && (
                        <div>
                          <LucideIcon name="arrow-big-right" />
                        </div>
                      )}
                    </Fragment>
                  ))}
                </div>
              }
            />
          )}

          <Table.Body.RowContent
            title={fieldLabels.name}
            content={
              <div className="container_label" title={entity.name}>
                <div
                  className="container_label_name"
                  style={{
                    fontWeight: 600,
                    paddingLeft: !!showHierarchy && !!entity.ancestors.length ? "5px" : undefined,
                  }}
                >
                  {entity.name ?? NotSet}
                </div>
                <div className="container_label_id">
                  <span style={{ whiteSpace: "nowrap" }}>
                    {toUppercase(entityConstants.entitySingular)}-ID: {entity.id}
                  </span>
                </div>
              </div>
            }
          />

          <Table.Body.RowContent
            title={fieldLabels.projects}
            content={<GetProjects projects={entity.projects} createLinks={true} />}
          />
          <Table.Body.RowContent
            title={fieldLabels.customType}
            content={
              entity.customType ? (
                <div className="container_label" title={entity.customType.name}>
                  <div className="container_label_name" style={{ overflow: "hidden" }}>
                    <Link to={route(getDetailLink(customTypeConstants.frontendIndexRoute, entity.customType.id))}>
                      <LucideIcon name={inventoriesConstants.icon} color="var(--primary)" /> {entity.customType.name}
                    </Link>
                  </div>
                  <div className="container_label_id">
                    <span style={{ whiteSpace: "nowrap" }}>
                      {toUppercase(inventoriesConstants.entitySingular)}-ID: {entity.customType.id}
                    </span>
                  </div>
                </div>
              ) : (
                NotSet
              )
            }
          />
        </Table.Body>
      </Table>
      <CustomTypeRenderer entity={entity} />
    </>
  );
};

interface InventoriesDetailProps extends EntityDetailProps<"inventoryItems"> {
  hideDirectChildrenTile?: boolean;
  hideSameTypeTile?: boolean;
  fullTitleOverride?: React.ReactNode;
  useQuickAdd?: boolean;
}

export const InventoriesDetail = ({
  entityConstants,
  fieldLabels,
  permissions,
  routes,
  entity,
  entityApi,
  hideDirectChildrenTile = false,
  hideSameTypeTile = false,
  fullTitleOverride,
  useQuickAdd,
}: InventoriesDetailProps) => {
  const { route, session } = useContext(SessionContext);
  const isElnActive = useFeatureFlag("eln");
  let history = useHistory();
  const [showModal, setShowModal] = useState<boolean>(false);

  const { data: validChildrenTypes } = useUnpaginateOrdered<CustomType, CustomTypeFilters>(
    customTypeConstants.resource,
    { entityTypes: ["Inventory"], parentTypeIds: entity.customType?.id ? [entity.customType.id] : [] },
    { enabled: !!entity.isHierarchyItem, staleTime: 120000, cacheTime: 120000 }
  );
  const canHaveChildItems = validChildrenTypes && validChildrenTypes.length > 0;
  const validChildrenTypesWithPermission = useMemo(
    () =>
      validChildrenTypes?.filter((t) =>
        t.hasRestrictedAddPermission
          ? !!session?.permissions.add_admin_inventory_items
          : !!session?.permissions.add_inventory_items
      ) ?? [],
    [validChildrenTypes, session]
  );
  const canCreateChildItems = useMemo(
    () =>
      !!validChildrenTypes?.some((t) =>
        t.hasRestrictedAddPermission
          ? !!session?.permissions.add_admin_inventory_items
          : !!session?.permissions.add_inventory_items
      ),
    [validChildrenTypes, session]
  );

  const { data: customType } = useEntityDetail<CustomType>(customTypeConstants.resource, entity.customType?.id ?? 0, {
    enabled: !!entity.customType?.id,
  });

  const [isQuickAddOpen, setIsQuickAddOpen] = useState<boolean>(false);

  return (
    <Detail
      head={
        <DetailsPageTopControlsView
          fullTitleOverride={
            fullTitleOverride ?? (
              <EntityBreadcrumbs
                entity={entity}
                breadcrumbs={entity.ancestors}
                entityConstants={entityConstants}
                indexEntityLabelOverride={entity.rootCustomType?.inventoryName}
                indexEntityLinkToOverride={
                  entity.isHierarchyItem
                    ? `${route(getIndexRoute(inventoriesConstants.frontendIndexRoute))}/${
                        entity.rootCustomType?.id
                      }/hierarchy`
                    : `${route(getIndexRoute(inventoriesConstants.frontendIndexRoute))}/${entity.customType?.id}/items`
                }
                ancestorLinkToOverride={
                  entity.isHierarchyItem
                    ? (ancestor) => {
                        return `${route(getIndexRoute(inventoriesConstants.frontendIndexRoute))}/${
                          entity.rootCustomType?.id
                        }/hierarchy/${ancestor.id}`;
                      }
                    : undefined
                }
              />
            )
          }
          entity={entity}
          entityConstants={entityConstants}
          attributes={
            <>
              {entity.isDeleted && (
                <h2 style={{ margin: 0 }}>
                  <label className="label label-sm label-soft-warning" style={{ margin: 0 }} title="Trashed">
                    <LucideIcon name="trash" />
                  </label>
                </h2>
              )}
            </>
          }
          controls={
            <>
              {!!entity.customType && canHaveChildItems && (
                <>
                  {!useQuickAdd ? (
                    <AddCustomTypeEntity
                      entityConstants={entityConstants}
                      entityType="Inventory"
                      defaultFilters={{
                        entityTypes: ["Inventory"],
                        ids: validChildrenTypesWithPermission.map((t) => t.id),
                      }}
                      excludeFilters={{ parentTypeIds: () => false }}
                      btnCls="btn btn-soft-primary"
                      btnStr="Add child item"
                      iconOverride={hierarchyConstants.childIcon}
                      canCreateEntityPermission={canCreateChildItems}
                      disableBasicCreation
                      autoSelectSingleResult
                      onSelectCustomType={(customType) =>
                        history.push(
                          route(
                            `${getAddRoute(entityConstants.frontendIndexRoute)}?type=${customType.id}&parent=${
                              entity.id
                            }`
                          )
                        )
                      }
                    />
                  ) : (
                    <>
                      <Button
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          setIsQuickAddOpen(true);
                        }}
                        className="btn btn-soft-primary"
                        disabled={!canCreateChildItems}
                        title={
                          !!entity
                            ? `Add ${entityConstants.entitySingular} to ${entity.name}`
                            : `Add ${entityConstants.entitySingular}`
                        }
                      >
                        <LucideIcon name={hierarchyConstants.childItemIcon} /> Add child item
                      </Button>
                      <QuickAddWrapper showModal={isQuickAddOpen} setShowModal={setIsQuickAddOpen} onCreate={() => {}}>
                        {(quickAddProps) => (
                          <QuickAddInventoryItemForm
                            {...quickAddProps}
                            initialValues={{
                              parent: entity,
                              customType:
                                !!validChildrenTypesWithPermission && validChildrenTypesWithPermission.length === 1
                                  ? validChildrenTypesWithPermission[0]
                                  : undefined,
                            }}
                            customTypeFilters={{
                              entityTypes: ["Inventory"],
                              ids: validChildrenTypesWithPermission.map((t) => t.id),
                            }}
                            subtitle={
                              !!entity
                                ? entity.name.length > 30
                                  ? `Parent: ${entity.name.slice(0, 30)}...`
                                  : `Parent: ${entity.name}`
                                : undefined
                            }
                            onCreate={() => setIsQuickAddOpen(false)}
                            onCancel={() => setIsQuickAddOpen(false)}
                          />
                        )}
                      </QuickAddWrapper>
                    </>
                  )}
                </>
              )}
              <LinkButton
                linkTo={route(getEditLink(entityConstants.frontendIndexRoute, entity.id))}
                className="btn btn-default"
                disabled={!entity.permissions?.edit || entity.isDeleted}
              >
                <LucideIcon name="square-pen" />
                <span> Edit</span>
              </LinkButton>

              {entity.isDeleted && (
                <EntityRestoreButton
                  entity={entity}
                  entityConstants={entityConstants}
                  entityApi={entityApi}
                  permissions={permissions}
                />
              )}

              <MoreDropdown drop="right" btn="btn btn-ghost-secondary">
                <li>
                  <button
                    className="btn btn-sm btn-ghost-secondary btn-block"
                    onClick={() => history.push(route(getCloneLink(entityConstants.frontendIndexRoute, entity.id)))}
                    title={`Clone this ${entityConstants.entitySingular}`}
                    disabled={
                      !!customType?.hasRestrictedAddPermission
                        ? !session?.permissions.add_admin_inventory_items
                        : !session?.permissions.add_inventory_items
                    }
                  >
                    <LucideIcon name="copy" /> Clone
                  </button>
                </li>
                <li>
                  <button
                    className={`btn btn-sm btn-ghost-${entity.isDeleted ? "danger" : "warning"}`}
                    title={`${entity.isDeleted ? "Delete" : "Move to trash"}`}
                    onClick={() => setShowModal(true)}
                    disabled={entity.isDeleted ? !permissions.canDelete(entity) : !permissions.canTrash(entity)}
                  >
                    <LucideIcon name="trash-2" /> {`${entity.isDeleted ? "Delete" : "Trash"}`}
                  </button>
                </li>
              </MoreDropdown>
              <AlertModal
                type={`${entity.isDeleted ? "danger" : "warning"}`}
                showModal={showModal}
                setShowModal={setShowModal}
                title={`${
                  entity.isDeleted ? `Permanently delete ${entityConstants.entitySingular}?` : "Move to trash?"
                }`}
                description={
                  entity.isDeleted
                    ? `This ${entityConstants.entitySingular} may be referenced by other entities. Deleting a ${entityConstants.entitySingular} is only possible if all references are removed.`
                    : `This will move the ${entityConstants.entitySingular} into trash. It is possible to restore this ${entityConstants.entitySingular} at any time.`
                }
                proceedLabel={entity.isDeleted ? "Delete" : "Trash"}
                onProceed={async () => {
                  if (entity.isDeleted) {
                    await entityApi
                      .deleteMutationAsync(
                        {
                          id: entity.id,
                          goBackOnSuccess: false,
                          showToast: false,
                          entityName: entityConstants.entitySingular,
                          params: { deletePermanently: true },
                        },

                        {
                          onSuccess: () => {
                            showtoast(
                              "success",
                              `Deleted ${toUppercase(entityConstants.entitySingular)}-ID: ${entity.id}.`
                            );
                            history.goBack();
                          },
                        }
                      )
                      .catch((e) => {});
                  } else {
                    await entityApi
                      .deleteMutationAsync(
                        {
                          id: entity.id,
                          goBackOnSuccess: false,
                          showToast: false,
                          entityName: entityConstants.entitySingular,
                        },

                        {
                          onSuccess: () => {
                            showtoast(
                              "success",
                              `Moved ${toUppercase(entityConstants.entitySingular)}-ID: ${entity.id} to trash.`
                            );
                            setShowModal(false);
                          },
                        }
                      )
                      .catch((e) => {});
                  }
                }}
                loading={entityApi.isLoadingDeleteMutation}
              />
            </>
          }
        />
      }
      mainStyle={{ padding: "0px 10px" }}
      main={<InventoryItemsDetailTable entity={entity} entityConstants={entityConstants} fieldLabels={fieldLabels} />}
      sidebar={
        <>
          {!hideDirectChildrenTile && !!entity.isHierarchyItem && (
            <InventoriesTile
              title="Direct children"
              defaultFilters={{ orderBy: "NAME_ASC" }}
              showCustomTypeSelectWidget
              parentInventoryItem={entity}
              isFolded
              foldable
            />
          )}

          {!hideSameTypeTile && !!entity.isHierarchyItem && (
            <InventoriesTile
              title="Items of the same type"
              defaultFilters={{
                customTypeIds: entity.customType?.id ? [entity.customType.id] : undefined,
                excludeIds: [entity.id],
              }}
              excludeFilters={{ customTypeIds: () => false }}
              isFolded
              foldable
              controlsFixed={() => (
                <div className="flex align-center gap-5">
                  <button
                    disabled={!entity.customType?.id}
                    className="btn btn-default"
                    onClick={() =>
                      !!entity.customType?.id &&
                      history.push(
                        `${route(getIndexRoute(inventoriesConstants.frontendIndexRoute))}/${entity.customType.id}/items`
                      )
                    }
                  >
                    <LucideIcon name="table" /> View table
                  </button>
                </div>
              )}
            />
          )}

          {/* <AttachmentsTileWrapped entity={inventoryItem} /> */}
          <CustomFieldReferencesTile
            entityType="Inventory"
            entity={entity}
            labNotebookEntryFilters={
              isElnActive ? { inventoryIds: [entity.id], orderBy: "MODIFIED_ON_DESC" } : undefined
            }
          />
          {/* {isElnActive && !!inventoryItem.relations?.labNotebookEntries?.count && (
            <LabNotebookEntriesTile
              title="Notebook mentions"
              defaultFilters={{ inventoryIds: [inventoryItem.id], orderBy: "MODIFIED_ON_DESC" }}
              excludeFilters={{ inventoryIds: () => false }}
            />
          )}

          {isElnActive && !inventoryItem.relations?.labNotebookEntries?.count && (
            <LabNotebookEntriesTile disabled title="Notebook mentions" />
          )} */}
        </>
      }
      sidebarMinSize={33}
    />
  );
};
