import React from "react";
import { LucideIcon } from "../common/icon/LucideIcon";

export const ManageEntryPage = () => {
  return (
    <div className="flex col-nowrap align-center justify-center" style={{ width: "100%", height: "100%" }}>
      <div className="flex row-nowrap align-center gap-5" style={{ color: "var(--gray-200)" }}>
        <LucideIcon name="arrow-left" style={{ width: "3vw", height: "3vw" }} />
        <span style={{ fontSize: "3vw", margin: 0 }}>Select a toolbar item</span>
      </div>
    </div>
  );
};
