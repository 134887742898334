import React, { useCallback, useState } from "react";
import { LoginPageProps } from "./SessionProvider";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { login, LoginRequest } from "../../api/Login";
import { Alert } from "react-bootstrap";
import { CheckboxFormField } from "../../common/formfields/CheckboxFormField/CheckboxFormField";
import { InputFormField } from "../../common/formfields/InputFormField";
import { PasswordFormField } from "../../common/formfields/PasswordFormField";
import { LucideIcon } from "../../common/icon/LucideIcon";
import { Button } from "../../common/buttons/Button/Button";
import { ServerError } from "../../common/helperfunctions/ApiError";

export interface LoginRequestForm extends LoginRequest {
  rememberMe: boolean;
}

const initialValues: LoginRequestForm = {
  username: "",
  password: "",
  otp: "",
  rememberMe: true,
} as const;

const validationSchema = yup.object().shape({
  username: yup.string().required("Email or username is required"),
  password: yup.string().required("Password is required"),
});

export const LocalLoginForm = ({ group, setSessionToken }: LoginPageProps) => {
  const [loginError, setLoginError] = useState<string | null>(null);
  const [otpRequired, setOtpRequired] = useState<boolean>(false);

  const {
    register,
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<LoginRequestForm>({ defaultValues: initialValues, resolver: yupResolver(validationSchema) });

  const handleLogin = useCallback(
    async ({ username, password, otp, rememberMe }: LoginRequestForm) => {
      setLoginError(null);
      const loginResponse = await login(group, { username, password, otp }).catch((e: ServerError) => {
        setLoginError(e.description ?? e.message);
        return undefined;
      });
      if (loginResponse === undefined) return;

      if (loginResponse === null) {
        setLoginError("Invalid username or password");
        return;
      }
      if (loginResponse.otpRequired) {
        setOtpRequired(true);
        return;
      } else {
        setOtpRequired(false);
      }
      if (!loginResponse.success) {
        setLoginError("Invalid username or password");
        return;
      } else {
        if (rememberMe) {
          localStorage.setItem(`session_${group}`, loginResponse.session.sessionId as string);
        } else {
          sessionStorage.setItem(`session_${group}`, loginResponse.session.sessionId as string);
        }
        if (loginResponse.session.sessionId) setSessionToken(loginResponse.session.sessionId);
      }
    },
    [group, setSessionToken]
  );

  return (
    <form autoComplete="off" onSubmit={handleSubmit(handleLogin)}>
      {!otpRequired && (
        <>
          <InputFormField
            id={"username"}
            label={"Username"}
            errors={errors}
            register={register}
            autoFocus={true}
            horizontal
            placeholder={"Email or username"}
          />

          <PasswordFormField
            id={"password"}
            label={"Password"}
            errors={errors}
            register={register}
            horizontal
            placeholder={"Password"}
          />
        </>
      )}
      {otpRequired && (
        <PasswordFormField
          id={"otp"}
          label={"One time password (2FA)"}
          errors={errors}
          register={register}
          horizontal
          placeholder={"Password"}
          autoFocus
          showPassword
        />
      )}
      <CheckboxFormField id={"rememberMe"} label={""} control={control} checkboxStyle="square" uncontained>
        <div className="flex row-nowrap align-center" style={{ width: "100%", paddingLeft: 5 }}>
          <label className="control-label" style={{ marginBottom: 0 }}>
            Keep me logged in
          </label>
          {/* TODO */}
          {/* <a href={`${window.origin}/${group}/forgot-password`} style={{ marginLeft: "auto" }}>
            Forgot password?
          </a> */}
        </div>
      </CheckboxFormField>

      {loginError && <Alert bsStyle="danger">{loginError}</Alert>}
      <Button className="btn btn-primary btn-block" type="submit" loading={isSubmitting} style={{ marginLeft: "auto" }}>
        <LucideIcon name="log-in" /> Log in
      </Button>
    </form>
  );
};
