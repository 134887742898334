import React from "react";
import { LucideIcon } from "../common/icon/LucideIcon";
export const Documentation = () => {
  return (
    // <a
    // className="btn btn-ghost-secondary btn-round"
    // href="http://help.logs-repository.com"
    // target="_blank"
    // data-toggle="tooltip"
    // data-placement="bottom"
    // title=""
    // data-original-title="Help"
    // rel="noreferrer"
    // >
    <LucideIcon name="circle-help" />
    //{/* <span className="title">Help</span> */}
    // </a>
  );
};
