import produce from "immer";
import { useCallback, useEffect, useRef, useState } from "react";
import { GridCellProps, GridCellRangeProps, MultiGrid } from "react-virtualized";

import { CellRangeRenderer } from "./CellRangeRenderer";
import { CellRenderer } from "./CellRenderer";
import { LineAtPos } from "./LineAtPos";
import { CellElementProps, CellIndex, CellSpan, CellState, FormattedTableCell, LinePosType } from "./SpreadSheetTypes";

const COLUMN_0_SIZE = 30;
// const COLUMN_1_SIZE = 100;
const COLUMN_MIN_SIZE = 20;
const COLUMN_DEFAULT_SIZE = 100;

const ROW_0_SIZE = 25;
// const ROW_1_SIZE = 25; // we might want this to be dynamic
const ROW_MIN_SIZE = 20;
const ROW_DEFAULT_SIZE = 25;

export const SpreadSheet = ({
  width,
  height,
  spanningCells,
  rowCount,
  columnCount,
  fixedRowCount,
  fixedColumnCount,
  showRowIndex,
  showColumnIndex,
  columnWidths,
  showRowLetterIndex,
  showColumnLetterIndex,
  ColumnIndexElement,
  ColumnNameElement,
  TopLeftCornerElement,
  RowIndexElement,
  RowNameElement,
  ContentCellElement,
  CellValues,
}: {
  width?: number;
  height?: number;
  spanningCells: CellSpan[];
  rowCount: number;
  columnCount: number;
  fixedRowCount: number;
  fixedColumnCount: number;
  showRowIndex: boolean;
  showColumnIndex: boolean;
  showRowLetterIndex: boolean;
  showColumnLetterIndex: boolean;
  columnWidths?: Record<number, number>;
  ColumnIndexElement?: (props: CellElementProps) => JSX.Element;
  ColumnNameElement?: (props: CellElementProps) => JSX.Element;
  TopLeftCornerElement?: (props: CellElementProps) => JSX.Element;
  RowIndexElement?: (props: CellElementProps) => JSX.Element;
  RowNameElement?: (props: CellElementProps) => JSX.Element;
  ContentCellElement?: (props: CellElementProps) => JSX.Element;
  CellValues: (index: CellIndex) => FormattedTableCell;
}) => {
  const [cellState, setCellState] = useState<CellState>({
    focused: { rowIndex: -1, columnIndex: -1 },
  });

  const [columnWidthState, setColumnWidthState] = useState<Record<number, number>>({});
  const [rowHeightState, setRowHeightState] = useState<Record<number, number>>({});

  const [linePos, setLinePos] = useState<LinePosType | undefined>();
  // const [rowSizes, setRowSizes] = useState<Record<number, rowSizeType>>({});
  // const [columnSizes, setColumnSizes] = useState<Record<number, columnSizeType>>({});

  const ref = useRef<any>();

  useEffect(() => {
    setColumnWidthState(columnWidths ?? {});
  }, [columnWidths]);

  const getColumnWidth = useCallback(
    ({ index }: { index: number }) => {
      // if (index === 4) return 10;
      if (showRowIndex) {
        if (index < 1) return COLUMN_0_SIZE;
        index--;
      }

      // if (index < fixedColumnCount) {
      //   if (showRowIndex) {
      //     if (index < 1) return COLUMN_0_SIZE;
      //     return columnWidthState?.[index] ? columnWidthState[index] : COLUMN_1_SIZE;
      //     // return COLUMN_1_SIZE;
      //   }
      // }
      // index--;
      // index -= fixedColumnCount;

      return columnWidthState?.[index] ? columnWidthState[index] : COLUMN_DEFAULT_SIZE;
    },
    [columnWidthState, showRowIndex]
  );

  const getRowHeight = useCallback(
    ({ index }: { index: number }) => {
      if (showColumnIndex) {
        if (index < 1) return ROW_0_SIZE;
        index--;
      }
      // if (index < fixedRowCount) {
      //   if (showColumnIndex) {
      //     if (index < 1) return ROW_0_SIZE;
      //     return ROW_1_SIZE;
      //   }
      // }

      // index -= fixedRowCount;
      return rowHeightState?.[index] ? rowHeightState[index] : ROW_DEFAULT_SIZE;
    },
    [rowHeightState, showColumnIndex]
  );

  const addToRowHeight = useCallback(
    (index: number, height: number) => {
      let size = getRowHeight({ index: index }) + height;
      if (size < ROW_MIN_SIZE) size = ROW_MIN_SIZE;
      setRowHeightState(
        produce(rowHeightState, (next) => {
          next[index - (showColumnIndex ? 1 : 0)] = size;
        })
      );
    },
    [getRowHeight, rowHeightState, showColumnIndex]
  );

  const addToColumnWidth = useCallback(
    (index: number, width: number) => {
      // console.log(
      //   "addToColumnWidth",
      //   index,
      //   getColumnWidth({ index: index }),
      //   "+",
      //   width,
      //   "=",
      //   getColumnWidth({ index: index }) + width
      // );
      let size = getColumnWidth({ index }) + width;
      if (size < COLUMN_MIN_SIZE) size = COLUMN_MIN_SIZE;
      setColumnWidthState(
        produce(columnWidthState, (next) => {
          next[index - (showRowIndex ? 1 : 0)] = size;
        })
      );
    },
    [columnWidthState, getColumnWidth, showRowIndex]
  );

  // useEffect(() => {
  //   console.log(">>>", rowHeightState);
  // }, [rowHeightState]);

  const getRowHeightRange = useCallback(
    (index: number, stopIndex?: number) => {
      if (stopIndex !== undefined) {
        let sum = 0;
        for (let i = index; i < stopIndex; i++) sum += getRowHeight({ index: i });
        return sum;
      }

      return getRowHeight({ index });
    },
    [getRowHeight]
  );

  const getColumnWidthRange = useCallback(
    (index: number, stopIndex?: number) => {
      if (stopIndex !== undefined) {
        let sum = 0;
        for (let i = index; i < stopIndex; i++) sum += getColumnWidth({ index: i });
        return sum;
      }

      return getColumnWidth({ index });
    },
    [getColumnWidth]
  );

  const cellRenderer = useCallback(
    (props: GridCellProps) => (
      <CellRenderer
        {...props}
        index={props.key}
        cellState={cellState}
        fixedRowCount={fixedRowCount}
        fixedColumnCount={fixedColumnCount}
        setCellState={setCellState}
        addToColumnWidth={addToColumnWidth}
        addToRowHeight={addToRowHeight}
        setLinePos={setLinePos}
        getColumnWidth={getColumnWidthRange}
        getRowHeight={getRowHeightRange}
        showColumnIndex={showColumnIndex}
        showRowIndex={showRowIndex}
        ColumnIndexElement={ColumnIndexElement}
        ColumnNameElement={ColumnNameElement}
        TopLeftCornerElement={TopLeftCornerElement}
        RowIndexElement={RowIndexElement}
        RowNameElement={RowNameElement}
        ContentCellElement={ContentCellElement}
        CellValues={CellValues}
        showColumnLetterIndex={showColumnLetterIndex}
        showRowLetterIndex={showRowLetterIndex}
      />
    ),
    [
      cellState,
      fixedRowCount,
      fixedColumnCount,
      addToColumnWidth,
      addToRowHeight,
      getColumnWidthRange,
      getRowHeightRange,
      showColumnIndex,
      showRowIndex,
      ColumnIndexElement,
      ColumnNameElement,
      TopLeftCornerElement,
      RowIndexElement,
      RowNameElement,
      ContentCellElement,
      CellValues,
      showColumnLetterIndex,
      showRowLetterIndex,
    ]
  );
  const cellRangeRenderer = useCallback(
    (props: GridCellRangeProps) =>
      CellRangeRenderer({
        ...props,
        cellState,
        setCellState,
        spanningCells,
        showRowIndex,
        showColumnIndex,
        fixedColumnCount,
        fixedRowCount,
        ContentCellElement,
        CellValues,
      }),
    [
      cellState,
      setCellState,
      spanningCells,
      showRowIndex,
      showColumnIndex,
      fixedColumnCount,
      fixedRowCount,
      ContentCellElement,
      CellValues,
    ]
  );

  useEffect(() => {
    // console.log("columnWidthState", columnWidthState);
    ref.current.recomputeGridSize();
  }, [
    columnWidthState,
    rowHeightState,
    rowCount,
    columnCount,
    fixedColumnCount,
    fixedRowCount,
    showColumnIndex,
    showRowIndex,
    showColumnLetterIndex,
    showRowLetterIndex,
  ]);

  useEffect(() => {
    ref.current.forceUpdateGrids();
  }, [cellState, rowCount, columnCount, showColumnIndex, showRowIndex, fixedColumnCount, fixedRowCount, CellValues]);

  rowCount += showColumnIndex ? 1 : 0;
  fixedRowCount += showColumnIndex ? 1 : 0;

  columnCount += showRowIndex ? 1 : 0;
  fixedColumnCount += showRowIndex ? 1 : 0;

  const updatedHeight = Math.min(height ?? 0, getRowHeightRange(0, rowCount)) + 1;
  const updatedWidth = Math.min(width ?? 0, getColumnWidthRange(0, columnCount)) + 1;
  const line = linePos ? <LineAtPos {...linePos} width={updatedWidth} height={updatedHeight} /> : null;

  return (
    <div
      onClick={() => {}}
      onMouseLeave={() =>
        setCellState(
          produce(cellState, (next) => {
            next.focused.rowIndex = -1;
            next.focused.columnIndex = -1;
          })
        )
      }
    >
      {line}
      <MultiGrid
        cellRenderer={cellRenderer}
        cellRangeRenderer={cellRangeRenderer}
        columnWidth={getColumnWidth}
        rowHeight={getRowHeight}
        rowCount={rowCount}
        columnCount={columnCount}
        fixedColumnCount={fixedColumnCount}
        fixedRowCount={fixedRowCount}
        classNameBottomLeftGrid="classNameBottomLeftGrid"
        classNameBottomRightGrid="classNameBottomRightGrid"
        classNameTopLeftGrid="classNameTopLeftGrid"
        classNameTopRightGrid="classNameTopRightGrid"
        ref={ref}
        // style={{
        //   border: "1px solid #dadada",
        // }}
        // classNameTopLeftGrid={styles.TopLeftGrid}
        width={updatedWidth + 5}
        height={updatedHeight}
      />
    </div>
  );
};
