import React, { useCallback, useContext, useState } from "react";
import { Dataset, DatasetFieldLabels } from "../../../api/Datasets";
import { Parser } from "../../../api/Parsers";
import { LucideIcon } from "../../../common/icon/LucideIcon";
import { IconRenderer } from "../../../common/icon/IconRenderer";
import { Tile } from "../../../common/panels/Tile/Tile";
import { ContentTable } from "../../../common/tables/ContentTable/ContentTable";
import { DatasetContext } from "../../Dataset";
import styles from "./SourceInformation.module.css";
import { useEntityDetail } from "../../../api/BaseEntityApi";
import { LoadingWrapper } from "../../../common/LoadingWrapper";
import { NotAvailable, NotSet } from "../../../common/misc/UIconstants";
import { dataSourceConstants } from "../../../api/DataSource";
import { LinkEntity } from "../../../common/misc/LinkEntity/LinkEntity";
import { UID } from "../../../common/badges/UID/UID";
import { itFacilityConstants } from "../../../api/Facilities";

const DataSource = ({ dataset }: { dataset: Dataset }) => {
  const context = useContext(DatasetContext);
  if (dataset.autouploadServer) {
    if (context?.viewShared)
      return (
        <div className={"container_label"} style={{ gap: 5 }}>
          <LucideIcon name={itFacilityConstants.icon} color={"var(--gray-500)"} style={{ flexShrink: 0 }} />
          <div className={"container_label_name"}>
            <span>{dataset.autouploadServer.name}</span>
          </div>
        </div>
      );
    return <LinkEntity entityConstants={itFacilityConstants} property={dataset.autouploadServer} />;
  }

  if (dataset.origin && dataset.origin.uid)
    return (
      <div className={"container_label"} style={{ gap: 5 }}>
        <LucideIcon name="git-pull-request" color={"var(--gray-500)"} style={{ flexShrink: 0 }} />
        <div className={"container_label_name"}>
          <span>{dataset.origin.name}</span>
        </div>
        <div className={"container_label_id"}>
          <UID placement="right" entity={{ id: dataset.origin.id, uid: dataset.origin.uid }} />
        </div>
      </div>
    );

  switch (dataset.source.type) {
    case "ForeignServer":
      return (
        <div className={"container_label"} style={{ gap: 5 }}>
          <LucideIcon name="server" color={"var(--gray-500)"} style={{ flexShrink: 0 }} />
          <div className={"container_label_name"}>
            <span>{dataset.source.name}</span>
          </div>
        </div>
      );
    case "AutoUpload":
      if (context?.viewShared)
        return (
          <div className={"container_label"} style={{ gap: 5 }}>
            <LucideIcon name={dataSourceConstants.icon} color={"var(--gray-500)"} style={{ flexShrink: 0 }} />
            <div className={"container_label_name"}>
              <span>{dataset.source.name}</span>
            </div>
          </div>
        );
      return <LinkEntity entityConstants={dataSourceConstants} property={dataset.source} />;
    case "ManualUpload":
      return (
        <div className={"container_label"} style={{ gap: 5 }}>
          <LucideIcon name="upload" color={"var(--gray-500)"} style={{ flexShrink: 0 }} />
          <div className={"container_label_name"}>
            <span>Manual file upload</span>
          </div>
        </div>
      );
    case "Unknown":
      return (
        <div className={"container_label"} style={{ gap: 5 }}>
          <LucideIcon name="upload" color={"var(--gray-500)"} style={{ flexShrink: 0 }} />
          <div className={"container_label_name"}>
            <span>Unknown</span>
          </div>
        </div>
      );
  }
};

interface SourceInformationProps {
  dataset: Dataset;
  tile?: boolean;
}
export const SourceInformation = ({ dataset, tile = true }: SourceInformationProps) => {
  const [textAreaHeight, setTextAreaHeight] = useState<number>();
  const ref = useCallback((node: HTMLTextAreaElement) => {
    if (node) {
      setTextAreaHeight(node.scrollHeight);
    }
  }, []);

  const {
    data: parser,
    status: parserStatus,
    fetchStatus,
    error: parserQueryError,
  } = useEntityDetail<Parser>("parsers", dataset.format?.id ?? "<unknown>", undefined, {
    enabled: !!dataset.format?.id,
  });

  const content = (
    <LoadingWrapper status={parserStatus} fetchStatus={fetchStatus} error={parserQueryError}>
      <ContentTable
        content={[
          {
            key: "Origin",
            value: <DataSource dataset={dataset} />,
          },
          {
            key: DatasetFieldLabels.automaticName,
            value: (
              <div className="ellipsisContainer">
                <span>{dataset.automaticName ?? NotSet}</span>
              </div>
            ),
          },
          {
            key: "File format",
            value: (
              <div className={"container_label"}>
                <div className={"container_label_name"}>
                  <span>{parser ? parser.name : NotAvailable}</span>
                </div>
              </div>
            ),
          },
          {
            key: "Vendor",
            value: (
              <>
                {parser && Array.isArray(parser.metaData) && !!parser.metaData.length ? (
                  <>
                    {parser.metaData.map((metaData, index) =>
                      Array.isArray(metaData.vendor) && !!metaData.vendor.length ? (
                        <div className="flex col-nowrap" key={index} style={{ width: "100%" }}>
                          {metaData.vendor.map((vendor, i) => (
                            <React.Fragment key={`${index}_${i}`}>
                              <div className={"container_label"}>
                                <div className={"container_label_name"}>
                                  <span>{vendor.name}</span>
                                </div>
                                <div className={"container_label_id"}>
                                  <IconRenderer src={vendor.icon} />
                                </div>
                              </div>
                            </React.Fragment>
                          ))}
                        </div>
                      ) : (
                        <React.Fragment key={index}> {NotSet}</React.Fragment>
                      )
                    )}
                  </>
                ) : (
                  NotAvailable
                )}
              </>
            ),
          },
          {
            key: "Path",
            value: "",
          },
        ]}
      />
      <div className={styles.path}>
        <textarea
          className={styles.path_textarea}
          ref={ref}
          value={dataset.path}
          disabled
          style={{ height: textAreaHeight }}
        />
      </div>
    </LoadingWrapper>
  );

  if (!tile) return content;
  return (
    <Tile>
      <Tile.Head
        title={
          <>
            <LucideIcon name={dataSourceConstants.icon} /> Source information
          </>
        }
      />
      <Tile.Body>{content}</Tile.Body>
    </Tile>
  );
};
