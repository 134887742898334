import { CSSProperties, useContext } from "react";
import { Announcement, AnnouncementType } from "../api/Announcements";
import { DateTimeRenderer } from "../common/datetime/DateTimeFormatter";
import { LucideIcon } from "../common/icon/LucideIcon";
import { GetPersons } from "../common/misc/EntityRenders/EntityRenderer";
import { AnnouncementContentTypeField, AnnouncementTypeLabel } from "./AnnouncementsDetail";
import { SessionContext } from "../common/contexts/SessionContext";
import { useQueryClient } from "@tanstack/react-query";
import styles from "./AnnouncementCard.module.css";

export const AnnouncementCardStyle = ({ type }: { type: AnnouncementType }) => {
  switch (type) {
    case "regular":
      return {
        backgroundColor: "var(--primary-light)",
        boxShadow: "1px 1px 1px 0px var(--primary)",
        borderLeft: "10px solid var(--primary)",
      } as CSSProperties;
    case "warning":
      return {
        backgroundColor: "var(--warning-light)",
        boxShadow: "1px 1px 1px 0px var(--warning)",
        borderLeft: "10px solid var(--warning)",
      } as CSSProperties;
    case "urgent":
      return {
        backgroundColor: "var(--danger-light)",
        boxShadow: "1px 1px 1px 0px var(--danger)",
        borderLeft: "10px solid var(--danger)",
      } as CSSProperties;
    default:
      return {} as CSSProperties;
  }
};

export const AnnouncementCard = ({ announcement }: { announcement: Announcement }) => {
  const { api } = useContext(SessionContext);
  const queryClient = useQueryClient();
  return (
    <div className={styles.AnnouncementCardContainer}>
      <div
        className={styles.AnnouncementCard}
        style={{
          ...AnnouncementCardStyle({ type: announcement.type }),
        }}
      >
        <div className={styles.AnnouncementCardControlsLeftContainer}>
          <div className={styles.AnnouncementCardControlsLeft}>
            <div style={{ fontSize: "14px", width: "100%", display: "flex", alignItems: "center", gap: "5px" }}>
              <AnnouncementTypeLabel type={announcement.type} opacity="" />
              {announcement.isSystemAnnouncement && " "}
              {announcement.isSystemAnnouncement && <span className="label label-soft-secondary">System</span>}
            </div>

            {announcement.modifiedBy ? (
              <div className="flex align-center gap-5">
                <div>
                  <LucideIcon name="minus" visibility="hidden" />
                </div>
                <div>
                  {announcement.isSystemAnnouncement ? (
                    <a
                      href="https://logs-repository.atlassian.net/servicedesk/customer/portals"
                      target="_blank"
                      className="link flex align-center gap-5"
                      rel="noreferrer"
                      style={{ textDecoration: "none" }}
                    >
                      <LucideIcon name="user" />
                      LOGS - Team
                    </a>
                  ) : (
                    <GetPersons persons={[announcement.modifiedBy]} />
                  )}
                </div>
              </div>
            ) : (
              <div />
            )}

            <div className="flex align-center gap-5">
              <div>
                <LucideIcon name="calendar" />
              </div>
              <div>
                <DateTimeRenderer date={announcement.startDate} />
              </div>
            </div>
            {announcement.endDate ? (
              <div className="flex align-center gap-5">
                <div>
                  <LucideIcon name="minus" />
                </div>
                <div>
                  <LucideIcon name="calendar" />
                </div>
                <div>
                  <DateTimeRenderer date={announcement.endDate} includeElapsed={true} />
                </div>
              </div>
            ) : (
              <div />
            )}
          </div>

          <div style={{ alignItems: "center", height: "100%", display: "flex", marginRight: "10px" }}>
            <button
              className="btn btn-default btn-xs"
              title={`Mark as ${announcement.currentUserState?.isRead ? "un" : ""}read`}
              onClick={() =>
                api
                  .post(`announcements/${announcement.id}/setCurrentUserStates`, {
                    isRead: !announcement.currentUserState?.isRead,
                  })
                  .then(() => {
                    queryClient.invalidateQueries({ queryKey: ["announcements", "list"] });
                    queryClient.invalidateQueries({ queryKey: ["announcements", "suggestions"] });
                    queryClient.invalidateQueries({ queryKey: ["announcements", "ids_only"] });
                    queryClient.invalidateQueries({ queryKey: ["announcements", "detail", announcement.id] });
                  })
                  .catch((e) => console.error(e))
              }
            >
              <LucideIcon name={announcement.currentUserState?.isRead ? "eye-off" : "circle-check-big"} />
              <span style={{ fontSize: "12px", fontWeight: 400 }}>
                {` Mark as ${announcement.currentUserState?.isRead ? "un" : ""}read`}
              </span>
            </button>
          </div>
        </div>

        <div style={{ padding: "10px", paddingTop: "3px", overflow: "auto", height: "100%", maxHeight: "50vh" }}>
          <AnnouncementContentTypeField
            message={announcement.message}
            contentType={announcement.contentType}
            style={{ backgroundColor: "var(--white)" }}
          />
        </div>
      </div>
    </div>
  );
};
