import { ActiveFromExtensions, AnyExtension, NodeWithPosition } from "remirror";
import { Node } from "@remirror/pm/model";

export const isNested = (active: ActiveFromExtensions<AnyExtension>) => {
  return (
    active.blockquote() ||
    active.bulletList() ||
    active.callout() ||
    active.code() ||
    active.dataset() ||
    active.heading() ||
    active.image() ||
    active.orderedList() ||
    active.sample() ||
    active.table() ||
    active.timer()
  );
};

export const findNodeByAttribute = (node: Node, key: string, value: any) => {
  let found: NodeWithPosition | undefined;
  node.descendants((node, pos) => {
    if (!!findNestedAttr(node.attrs, key, value)) {
      found = { node, pos };
      return false;
    }
    return true;
  });
  return found;
};

const findNestedAttr: (object: any, key: string, value: any) => { key: string; value: any } | undefined = (
  object,
  key,
  value
) => {
  let result: { key: string; value: any } | undefined = undefined;

  if (object instanceof Array) {
    for (let i = 0; i < object.length; i++) {
      result = findNestedAttr(object[i], key, value);
      if (result) {
        break;
      }
    }
  } else {
    for (let prop in object) {
      if (prop === key && object[prop] === value) {
        return { key: prop, value: object[prop] };
      }

      if (object[prop] instanceof Object || object[prop] instanceof Array) {
        result = findNestedAttr(object[prop], key, value);
        if (result) {
          break;
        }
      }
    }
  }

  return result;
};
