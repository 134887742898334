import * as d3 from "d3";
import { CodonTranslator } from "./CodonTranslator";
import { sequenceAnnotationType } from "../ViewerLayout/ViewerTypes";
import { getSVGCSS, addCSS } from "../tools/SVGTools/SVGTools";

type sequenceTic = {
  position: number;
  number: number;
};

type lineType = {
  tics: sequenceTic[];
  x: number;
  width: number;
  y: number;
  height: number;
  number: number;
  lastPosition: number;
  object?: any;
};

type annotationType = sequenceAnnotationType & { left: number; right: number };

type markerType = { start: number; end: number; antisense: boolean };

// export type annotationType = spectrumAnnotationType
// export type annotationType = {
//   position: number[];
//   pos: number;
//   index: number[];
//   length: number;
//   type: string;
//   direction?: string;
//   cut?: number[];
//   label: string;
//   color: string;
//   id?: number;
// };

export class SequenceViewer {
  static ASColor: Record<string, any[]> = {
    ASP: ["bright red", [230, 10, 10], "#E60A0A"],
    GLU: ["bright red", [230, 10, 10], "#E60A0A"],
    CYS: ["yellow", [230, 230, 0], "#E6E600"],
    MET: ["yellow", [230, 230, 0], "#E6E600"],
    LYS: ["blue", [20, 90, 255], "#145AFF"],
    ARG: ["blue", [20, 90, 255], "#145AFF"],
    SER: ["orange", [250, 150, 0], "#FA9600"],
    THR: ["orange", [250, 150, 0], "#FA9600"],
    PHE: ["mid blue", [50, 50, 170], "#3232AA"],
    TYR: ["mid blue", [50, 50, 170], "#3232AA"],
    ASN: ["cyan", [0, 220, 220], "#00DCDC"],
    GLN: ["cyan", [0, 220, 220], "#00DCDC"],
    GLY: ["silver", [192, 192, 192], "#C0C0C0"],
    LEU: ["green", [15, 130, 15], "#0F820F"],
    VAL: ["green", [15, 130, 15], "#0F820F"],
    ILE: ["green", [15, 130, 15], "#0F820F"],
    ALA: ["dark grey", [200, 200, 200], "#C8C8C8"],
    TRP: ["pink", [180, 90, 180], "#B45AB4"],
    HIS: ["pale blue", [130, 130, 210], "#8282D2"],
    PRO: ["flesh", [220, 150, 130], "#DC9682"],
    STOP: ["orchid", [218, 112, 214], "#FF00FF"],
  };

  static baseColor: Record<string, string> = {
    A: "green",
    C: "blue",
    G: "DarkOrange",
    T: "red",
    U: "MediumOrchid",
    default: "#1f2b55",
  };

  getAminoacidColorHex(aminoacid: string): string {
    return SequenceViewer.ASColor?.[aminoacid?.toUpperCase()]?.[2] || "white";
  }

  padding = {
    top: 10,
    left: 30,
    right: 40,
    letterWidth: 5,
    letterHeight: 5,
    letterSpacing: 5,
    lineSpacing: 10,
    ticsSpacing: 10,
    ticLength: 5,
    width: 400,
    boxHeight: 14,
    peptideHeight: 14,
  };

  _container: any;
  sequence: string[];
  sequenceIndex: number[];
  reverseIndex: Record<number, number>;
  stringSequence: string;
  svg: any;
  _width: number;
  annotations: annotationType[];
  tooltip: any;
  lineGroup: any;
  markerGroup: any;
  letterSize: Record<string, { width: number; height: number }>;
  codonTranslator: CodonTranslator;
  letterPosition: { x: number; y: number; line: number }[];
  // markers: number[][];
  markers: markerType[];
  letterWidth: number;
  _showORF: boolean;
  _showRestrictase: boolean;
  _showMarker: boolean;
  _showORFLetter: boolean;
  _showAntisense: boolean;
  _colorByBase: boolean;
  redraw: boolean;

  constructor(container: any = null, width: number = 400) {
    // console.log("new SequenceViewer");
    if (container) {
      this._container = container;
      this.svg = d3.select(this._container);
    }
    this.sequence = [];
    this.sequenceIndex = [];
    this.reverseIndex = {};
    this.stringSequence = "";
    this._width = 0;
    this.annotations = [];
    this.letterSize = {};
    this.codonTranslator = new CodonTranslator();
    this.letterPosition = [];
    this.markers = [];
    this.letterWidth = 0;
    this._showORF = true;
    this._showRestrictase = true;
    this._showMarker = true;
    this._showAntisense = true;
    this._showORFLetter = false;
    this._colorByBase = false;
    this.redraw = true;

    this.width = width;
  }

  setAttributes(state: any) {
    // const s = Object.fromEntries(Object.entries(state).filter(([k, v]) => "_" + k in this));

    const s = Object.entries(state)
      .map(([k, v]) => ["_" + k, v])
      .filter(([k, v]: any) => k in this && this[k as keyof SequenceViewer] !== v);

    // console.log("STATE", state);
    if (s.length > 0) {
      // console.log("STATE", s);
      Object.assign(this, Object.fromEntries(s));
      this.draw();
    }
  }

  get showAntisense(): boolean {
    return this._showAntisense;
  }

  set showAntisense(show: boolean) {
    const oldShow = this._showAntisense;
    this._showAntisense = show;
    if (oldShow !== show && this.redraw) this.draw();
  }

  get showORFLetter(): boolean {
    return this._showORFLetter;
  }

  set showORFLetter(show: boolean) {
    this._showORFLetter = show;

    if (this.lineGroup) {
      this.lineGroup
        .selectAll("#orfLabels")
        .nodes()
        .forEach((u: any) => {
          // console.log(">>", u.textContent);
          u.textContent = this.showORFLetter ? u.getAttribute("letter") : u.getAttribute("code");
        });
    }
  }

  get showORF(): boolean {
    return this._showORF;
  }

  set showORF(show: boolean) {
    const oldShow = this._showORF;
    this._showORF = show;
    if (oldShow !== show && this.redraw) this.draw();
  }

  get showRestrictase(): boolean {
    return this._showRestrictase;
  }

  set showRestrictase(show: boolean) {
    const oldShow = this._showRestrictase;
    this._showRestrictase = show;
    if (oldShow !== show && this.redraw) this.draw();
  }

  get showMarker(): boolean {
    return this._showMarker;
  }

  set showMarker(show: boolean) {
    const oldShow = this._showMarker;
    this._showMarker = show;
    if (oldShow !== show && this.redraw) this.draw();
  }

  set container(container: any) {
    this._container = container;
    this.svg = d3.select(this._container);
  }

  get container() {
    return this._container;
  }

  set width(width: number) {
    this._width = width;
    this.padding.width = width;
  }

  get width() {
    return this._width;
  }

  setSequence(sequence: string | string[], sequenceIndex: number[] = []) {
    // console.log("sequence", sequence, sequenceIndex);
    if (!Array.isArray(sequence)) this.sequence = sequence.split("");
    else this.sequence = sequence;

    if (sequenceIndex.length < this.sequence.length) {
      this.sequenceIndex = sequenceIndex.slice();
      let idx = sequenceIndex.length > 0 ? sequenceIndex[sequenceIndex.length - 1] + 1 : 1;
      for (let i = 0; i < sequence.length - sequenceIndex.length; i++) {
        this.sequenceIndex.push(idx);
        idx++;
      }
    } else if (sequenceIndex.length > this.sequence.length) {
      this.sequenceIndex = sequenceIndex.slice(0, this.sequence.length);
    } else {
      this.sequenceIndex = sequenceIndex.slice();
    }
    // console.log("sequenceIndex", this.sequence.length, this.sequenceIndex.length);
    this.sequenceIndex.forEach((index, i) => (this.reverseIndex[index] = i));

    this.stringSequence = this.sequence.join("");

    const anti = this.getAntiSense();
    const uniq: string[] = [];
    this.uniq(this.sequence).forEach((l) => {
      uniq.push(l);
      uniq.push(anti(l));
    });
    uniq.push("-");

    // const letters = this.uniq(this.sequence);
    const letters = this.uniq(uniq);

    // letters.push("-");
    // const anti = makeAntisense(seq);
    // console.log("letters", letters);

    let letterWidth = 0;
    let letterHeight = 0;
    const letterSize: Record<any, any> = {};
    letters.forEach((ch) => {
      const letter = this.svg.append("text").text(ch).attr("class", "sequenceLetter").style("opacity", 0);
      const box = letter.node()?.getBBox();
      const w = box?.width || 0;
      if (letterWidth < w) letterWidth = w;
      const h = box?.height || 0;
      if (letterHeight < h) letterHeight = h;
      letterSize[ch] = { width: w, height: h };
      // console.log("letter", ch, w);
    });
    letterWidth /= letters.length;

    this.padding.letterWidth = letterWidth;
    this.padding.letterHeight = letterHeight;
    this.padding.letterSpacing = this.padding.letterWidth * 1.5;
    this.padding.lineSpacing = this.padding.letterHeight;

    const letter = this.svg.append("text").text("01234567890").attr("class", "sequenceNumber").style("opacity", 0);
    const box = letter.node()?.getBBox();
    letterSize.tics = { width: box?.width || 0, height: box?.height || 0 };

    this.letterSize = letterSize;
  }

  static binarySearch(list: number[], v: number): [number, number] {
    let l = 0;
    let n = list.length - 1;
    let r = n;

    if (v < list[l]) return [-1, 0];
    if (v > list[r]) return [n, n + 1];

    let m = Math.floor(l + (r - l) / 2);

    while (r - l > 1) {
      if (v < list[m]) {
        r = m;
      } else if (v > list[m]) {
        l = m;
      } else {
        return [m, m];
      }
      m = Math.floor(l + (r - l) / 2);
    }

    return [l, r];
  }

  copyAnnotation(annotation: sequenceAnnotationType): annotationType {
    const indexList = this.sequenceIndex.slice();
    indexList.sort((a, b) => a - b);
    let l, r;

    [l, r] = SequenceViewer.binarySearch(indexList, annotation.index[0]);
    const left = l < 0 ? l : r >= this.sequenceIndex.length ? r : l;

    [l, r] = SequenceViewer.binarySearch(indexList, annotation.index[0] + annotation.length);
    const right = l < 0 ? l : r;

    return {
      index: Array.isArray(annotation.index) ? annotation.index.slice() : [annotation.index + 0],
      length: annotation.length,
      type: annotation.type,
      direction: annotation.direction,
      cut: annotation.cut?.slice(),
      label: annotation.label,
      color: annotation.color,
      id: annotation.id,
      tracks: annotation.tracks.slice(),
      left: left,
      right: right,
    };
  }

  setAnnotation(annotations: sequenceAnnotationType[]) {
    this.annotations = annotations.map((a) => this.copyAnnotation(a));
    this.annotations.forEach((a, id) => (a.id = id));
  }

  // addAnnotation(annotation: annotationType | annotationType[]) {
  //   if (!Array.isArray(annotation)) annotation = [annotation];

  //   this.annotations.push(...annotation);
  //   this.annotations.forEach((a, id) => {
  //     a.id = id;
  //     console.log("annotation", a)
  //   });
  // }

  draw() {
    // console.log(">>>", this.sequence.length);
    if (this.sequence.length < 1 || !this.container) return;

    this.defineFilters();
    // create a tooltip
    this.tooltip = d3
      .select("body")
      .append("div")
      .attr("id", "tooltip")
      .attr("class", "tooltip")
      .style("position", "absolute")
      .style("opacity", 0)
      .style("border", "solid")
      .style("border-width", "1px")
      .style("border-radius", "5px")
      .style("padding", "10px")
      .html("<p>Tooltip text</p>");
    // console.log("draw");

    if (!this.lineGroup) this.lineGroup = this.svg.append("g").attr("id", "sequenceLines");
    this.lineGroup.selectAll("*").remove();

    const lines: lineType[] = [];
    let line: lineType = {
      tics: [{ position: this.padding.left, number: 0 }],
      x: this.padding.left,
      width: 0,
      height: 0,
      y: 0,
      number: -1,
      lastPosition: -1,
    };

    this.letterPosition = [];
    this.letterWidth = 0;
    // console.log("padding", this.padding.width);
    //  for (let i = 0; i < 40; i++) {
    do {
      lines.push(line);
      line = this.addLine(line);

      // console.log("line", line.number);
    } while (line.number >= 0);
    // console.log("line", this.letterPosition.length);

    this.drawMarker();
    // d3.selectAll("*")
    //       .style("filter", "url(#glow)");

    let sum = 0;

    lines.forEach((line) => {
      sum += line.height;
    });
    // console.log("BOX", sum);
    // console.log("line", lines?.[lines.length - 1].object.node());
    // lines?.[lines.length - 1].object?.node().focus();

    this.svg.attr("height", sum);
    // console.log("BOX", this.lineGroup.node()?.getBBox());
  }

  distinct(value: string, index: number, array: string[]) {
    return array.indexOf(value) === index;
  }

  uniq(array: string[]): string[] {
    return array.filter(this.distinct);
  }

  getAntiSense() {
    var anti: Record<string, string> = {
      c: "g",
      t: "a",
      g: "c",
      a: "t",
    };

    Object.keys(anti).forEach((k: string) => {
      anti[k.toUpperCase()] = anti[k].toUpperCase();
    });

    return (ch: string) => anti[ch] || "-";
  }

  makeAntisense(sense: string, reverse: boolean = false): string {
    const anti = this.getAntiSense();
    let l = sense.split("").map((ch) => anti(ch));
    if (reverse) l = l.reverse();
    return l.join("");
  }

  defineFilters() {
    var defs = this.svg.append("defs");

    //Filter for the outside glow
    var filter = defs.append("filter").attr("id", "glow");
    filter.append("feGaussianBlur").attr("stdDeviation", "2.5").attr("result", "coloredBlur");
    var feMerge = filter.append("feMerge");
    feMerge.append("feMergeNode").attr("in", "coloredBlur");
    feMerge.append("feMergeNode").attr("in", "SourceGraphic");

    filter = defs.append("filter").attr("id", "Bevel");
    filter.append("feGaussianBlur").attr("in", "SourceAlpha").attr("stdDeviation", "3").attr("result", "blur");
    filter
      .append("feSpecularLighting")
      .attr("in", "blur")
      .attr("surfaceScale", "5")
      .attr("specularConstant", "0.5")
      .attr("specularExponent", "10")
      .attr("result", "specOut")
      .attr("lighting-color", "white")
      .append("fePointLight")
      .attr("x", "-5000")
      .attr("y", "-10000")
      .attr("z", "20000");
    filter
      .append("feComposite")
      .attr("in", "specOut")
      .attr("in2", "SourceAlpha")
      .attr("operator", "in")
      .attr("result", "specOut2");
    filter
      .append("feComposite")
      .attr("in", "SourceGraphic")
      .attr("in2", "specOut2")
      .attr("operator", "arithmetic")
      .attr("k1", "0")
      .attr("k2", "1")
      .attr("k3", "1")
      .attr("k4", "0")
      .attr("result", "litPaint");

    // <filter id="Bevel2" filterUnits="objectBoundingBox" x="-10%" y="-10%" width="150%" height="150%">
    //       <feGaussianBlur in="SourceAlpha" stdDeviation="0.5" result="blur"/>
    //         <fePointLight x="-5000" y="-5000" z="8000"/>
    //       </feSpecularLighting>
    //       <feComposite in="specOut" in2="SourceAlpha" operator="in" result="specOut2"/>
    //       <feComposite in="SourceGraphic" in2="specOut2" operator="arithmetic" k1="0" k2="1" k3="1" k4="0" result="litPaint" />

    filter = defs
      .append("filter")
      .attr("id", "Bevel2")
      .attr("filterUnits", "objectBoundingBox")
      .attr("x", "-10%")
      .attr("y", "-10%")
      .attr("width", "150%")
      .attr("height", "150%");
    filter.append("feGaussianBlur").attr("in", "SourceAlpha").attr("stdDeviation", "0.5").attr("result", "blur");
    filter
      .append("feSpecularLighting")
      .attr("in", "blur")
      .attr("surfaceScale", "5")
      .attr("specularConstant", "0.5")
      .attr("specularExponent", "10")
      .attr("result", "specOut")
      .attr("lighting-color", "white")
      .append("fePointLight")
      .attr("x", "-5000")
      .attr("y", "-5000")
      .attr("z", "8000");
    filter
      .append("feComposite")
      .attr("in", "specOut")
      .attr("in2", "SourceAlpha")
      .attr("operator", "in")
      .attr("result", "specOut2");
    filter
      .append("feComposite")
      .attr("in", "SourceGraphic")
      .attr("in2", "specOut2")
      .attr("operator", "arithmetic")
      .attr("k1", "0")
      .attr("k2", "1")
      .attr("k4", "0")
      .attr("result", "litPaint");
  }

  svgLine = d3
    .line()
    .x(function (d: any) {
      return d[0];
    })
    .y(function (d: any) {
      return d[1];
    })
    .curve(d3.curveLinear);

  getPointyBox(x1: number, x2: number, y: number, boxHeight: number, pointWidth: number, mode: string): any[] {
    switch (mode) {
      case "both":
        return [
          [x1 - pointWidth / 2, y + boxHeight / 2],
          [x1 + pointWidth / 2, y],
          [x2 - pointWidth / 2, y],
          [x2 + pointWidth / 2, y + boxHeight / 2],
          [x2 - pointWidth / 2, y + boxHeight],
          [x1 + pointWidth / 2, y + boxHeight],
        ];
      case "backward":
        return [
          [x1 - pointWidth / 2, y + boxHeight / 2],
          [x1 + pointWidth / 2, y],
          [x2 + pointWidth / 2, y],
          [x2 + pointWidth / 2, y + boxHeight],
          [x1 + pointWidth / 2, y + boxHeight],
        ];
      case "forward":
        return [
          [x1 - pointWidth / 2, y],
          [x2 - pointWidth / 2, y],
          [x2 + pointWidth / 2, y + boxHeight / 2],
          [x2 - pointWidth / 2, y + boxHeight],
          [x1 - pointWidth / 2, y + boxHeight],
        ];
      case "dash-forward":
        return [
          [x1 - pointWidth / 4, y + boxHeight / 2],
          [x1 - pointWidth / 2, y],
          [x2 + pointWidth / 4, y],
          [x2 + pointWidth / 2, y + boxHeight / 2],
          [x2 + pointWidth / 4, y + boxHeight],
          [x1 - pointWidth / 2, y + boxHeight],
          [x1 - pointWidth / 4, y + boxHeight / 2],
        ];
      case "dash-backward":
        return [
          [x1 - pointWidth / 2, y + boxHeight / 2],
          [x1 - pointWidth / 4, y],
          [x2 + pointWidth / 2, y],
          [x2 + pointWidth / 4, y + boxHeight / 2],
          [x2 + pointWidth / 2, y + boxHeight],
          [x1 - pointWidth / 4, y + boxHeight],
          [x1 - pointWidth / 2, y + boxHeight / 2],
        ];
    }
    return [
      // boxType.none
      [x1 - pointWidth / 2, y],
      [x2 + pointWidth / 2, y],
      [x2 + pointWidth / 2, y + boxHeight],
      [x1 - pointWidth / 2, y + boxHeight],
    ];
  }

  checkAnnotationDirection(
    i: number,
    j: number,
    left: number,
    right: number,
    direction?: string
  ): [number, number, string] {
    let d = direction || "default";
    if (i < left) {
      i = left;
      if (d === "backward") d = "default";
    }
    if (j > right) {
      j = right;
      if (d === "forward") d = "default";
    }

    return [i, j, d];
  }

  getBaseColor(base: string) {
    const ch = base.toUpperCase();

    if (ch in SequenceViewer.baseColor) return SequenceViewer.baseColor[ch] || SequenceViewer.baseColor.default;
  }

  addLine(previousLine: lineType): lineType {
    const line: lineType = Object.assign({}, previousLine);
    // console.log("-- addLine --");

    let dx = previousLine.x;
    let dy = previousLine.y + previousLine.height;

    line.y = dy;
    line.tics = [];
    line.number = previousLine.number + 1;
    const offset = previousLine.lastPosition + 1;
    dy += this.padding.lineSpacing;

    const seq = this.sequence;
    // console.log("seq  ", seq);
    // line.number = -1;
    // return line;

    // console.log("offset", offset, seq.length);
    if (offset >= seq.length) return { tics: [], x: 0, width: -1, height: -1, y: 0, number: -1, lastPosition: -1 };

    // if (offset === 0) line.tics.push({ number: 0, position: dx });

    // console.log("line", previousLine);
    // Define the div for the tooltip
    // var tooltip = this.lineGroup.append("g").attr("id", "tooltip");
    // var tool = tooltip
    //   .append("rect")
    //   .attr("x", 0)
    //   .attr("y", 0)
    //   .attr("width", 100)
    //   .attr("height", 100)
    //   .attr("class", "tooltip"); // .attr("opacity", 0.1)
    // // .style("fill", "rgba(255,0,0,0.5)")
    const mouseover = (html: string, delay: number = 0) => {
      return () => {
        this.tooltip
          .style("left", d3.event.pageX + "px")
          .style("top", d3.event.pageY + "px")
          .html(html)
          // .style("opacity", 0)
          // .transition()
          // .delay(delay)
          .style("opacity", 0.9);

        // .style("visibility", "visible");
        // return d3.select("#tooltip").style("visibility", "visible");
      };
    };

    const mouseout = () => {
      // this.tooltip.style("visibility", "hidden");
      // this.tooltip.transition().duration(200).style("opacity", 0);
      this.tooltip.style("opacity", 0);
    };

    const mousemove = () => {
      this.tooltip.style("left", d3.event.pageX + "px").style("top", d3.event.pageY + "px");
    };

    // console.log("TEST");
    let lineTop = dy;
    const anti = this.getAntiSense();
    const letters: any[] = [];
    const letterXPositions: Record<number, number> = {};
    const letterYPositions: Record<number, number> = {};
    // let letterWidth = 0;
    line.lastPosition = seq.length - 1;
    let i;
    let space = 0;
    for (i = offset; i < seq.length; i++) {
      const ch = seq[i];
      const a = anti(ch);
      letterXPositions[i] = dx + this.letterSize[ch].width / 2;
      space =
        this.letterSize[ch].width > this.letterSize[a].width ? this.letterSize[ch].width : this.letterSize[a].width;

      if (this.letterWidth < space) this.letterWidth = space;

      letterYPositions[i] = dy + this.letterSize[ch].height;
      // if (i > 43 && i < 47) console.log("ch", i, ch, dy, line.number);
      // this.letterPosition.push({ x: dx, y: dy + this.letterSize[ch].height, line: line.number });

      // letters.push(
      //   this.lineGroup
      //     .append("text")
      //     .attr("id", "seq" + i)
      //     .text(ch)
      //     .attr("dx", dx)
      //     .attr("dy", dy + this.letterSize[ch].height)
      //     .style("text-anchor", "middle")
      //     .style("text-anchor", "middle")
      //     .attr("class", "sequenceLetter sense")
      //     // .on("mouseover", mouseover("" + (i + 1)))
      //     .on("mouseover", mouseover("" + this.sequenceIndex[i]))
      //     .on("mousemove", mousemove)
      //     .on("mouseout", mouseout)
      //     .attr("tabindex", -1)
      // );

      const color = this._colorByBase ? this.getBaseColor(ch) : SequenceViewer.baseColor.default;

      letters.push(
        this.lineGroup
          .append("text")
          .attr("id", "seq" + i)
          .text(ch)
          .attr("dx", dx)
          // .attr("dy", dy + this.letterSize[ch].height)
          .attr("dy", letterYPositions[i])
          .style("text-anchor", "start")
          .attr("class", "sequenceLetter sense")
          .style("fill", color)
          // .on("mouseover", mouseover("" + (i + 1)))
          .on("mouseover", mouseover("" + this.sequenceIndex[i]))
          .on("mousemove", mousemove)
          .on("mouseout", mouseout)
          .attr("tabindex", -1)
      );

      // this.lineGroup
      //   .append("line")
      //   .attr("x1", dx)
      //   .attr("y1", dy + this.letterSize[ch].height)
      //   .attr("x2", dx)
      //   .attr("y2", dy + 2 * this.letterSize[ch].height)
      //   .attr("fill", "none")
      //   .attr("stroke", "#0000CD")
      //   .attr("stroke-width", "1.5px");

      if (this.showAntisense) {
        const color = this._colorByBase ? this.getBaseColor(a) : SequenceViewer.baseColor.default;

        letters.push(
          this.lineGroup
            .append("text")
            .attr("id", "seq" + i)
            .text(a)
            .attr("dx", dx)
            .attr("dy", dy + 2 * this.letterSize[ch].height)
            .style("text-anchor", "start")
            .attr("class", "sequenceLetter antisense")
            .style("fill", color)
            // .on("mouseover", mouseover("" + (i + 1)))
            .on("mouseover", mouseover("" + this.sequenceIndex[i]))
            .on("mousemove", mousemove)
            .on("mouseout", mouseout)
            .attr("tabindex", -1)
        );
      }
      if (i % 10 === 0 || i === 0) {
        // console.log("tic", i);
        // line.tics.push({ position: dx, number: i });
        line.tics.push({ position: letterXPositions[i], number: i });
      }
      if (dx + space + this.padding.letterSpacing > this.padding.width - this.padding.right) {
        // console.log("dx", dx, width - padding.right);
        // line.y = dy + 2 * this.letterSize[ch].height + padding.ticsSpacing;
        line.lastPosition = i;
        line.object = letters[letters.length - 1];
        break;
      }
      dx += space + this.padding.letterSpacing;
    }
    // console.log("tics", offset, line.lastPosition);

    if (i >= seq.length) dx -= space + this.padding.letterSpacing;
    line.width = dx - line.x;
    dy += this.letterSize[seq[0]].height * (this.showAntisense ? 2 : 1);
    dy += this.padding.ticsSpacing;

    const left = offset;
    const right = line.lastPosition;
    // console.log("left", left, right);

    let foundAnnotations = false;
    if (this.showRestrictase) {
      let maxYShift = 0;
      const restrictase: any[] = [];
      this.annotations
        .filter((l) => {
          return l.type === "restrictase";
        })
        .forEach((l) => {
          // console.log("restrict", l.index[0]);
        });

      this.annotations
        .filter((l) => {
          // return l.index[0] <= right && l.index[0] + l.length >= left && l.type === "restrictase" && l.length > 2;
          return l.left <= right && l.right >= left && l.type === "restrictase" && l.length > 2;
        })
        .forEach((l, k) => {
          foundAnnotations = true;
          // const [i, j] = this.checkAnnotationDirection(l.index[0], l.index[0] + l.length - 1, left, right, l.direction);
          const [i, j] = this.checkAnnotationDirection(l.left, l.right, left, right, l.direction);
          // console.log("i,j", i, j, this.checkAnnotationDirection(l.left, l.right - 1, left, right, l.direction));

          // const p0 = l.index[0] + (l?.cut?.[0] || 0);
          const p0 = l.left + (l?.cut?.[0] || 0);
          // console.log("p0", p0, l.left + (l?.cut?.[0] || 0));
          // console.log("pos", l);

          if (p0 >= i && p0 <= j) {
            // console.log("pos", p0);
            const text = this.lineGroup
              .append("text")
              .text(l.label[0])
              .attr("dx", letterXPositions[p0] - this.letterSize[seq[p0]].width / 2 - this.padding.letterSpacing / 2)
              .attr("dy", lineTop)
              .style("font-size", this.padding.peptideHeight * 0.9 + "px")
              .attr("fill", "#0000CD")
              .attr("class", "restrictaseText")
              .style("text-anchor", "middle")
              .style("dominant-baseline", "top")
              .on("mouseenter", () => {
                this.lineGroup.selectAll("#restrictase_" + l?.id || 0).style("display", null);
                // mouseover("<b>" + l.label[0] + "</b><br>" + l.index[0] + ":" + (l.index[0] + l.length), 2000)();
              })
              .on("mousemove", () => {
                this.lineGroup.selectAll("#restrictase_" + l?.id || 0).style("display", null);
              })
              .on("mouseout", () => {
                this.lineGroup.selectAll("#restrictase_" + l?.id || 0).style("display", "none");
                mouseout();
              });

            const box = text.node().getBBox();
            let yShift = 0;
            restrictase.forEach((t) => {
              if (t != null) {
                const b = t.node().getBBox();
                var x1 = box.x - b.x;
                var x2 = box.x + box.width - b.x;
                // console.log("bbox", x1, x2, b.width);
                if ((x1 > 0 && x1 <= b.width) || (x2 > 0 && x2 <= b.width)) {
                  yShift += b.height;
                  if (maxYShift < yShift) maxYShift = yShift;
                }
              }
            });
            text.attr("dy", parseFloat(text.attr("dy")) + yShift);

            restrictase.push(text);
          } else restrictase.push(null);
        });

      if (foundAnnotations) {
        letters.forEach((l) => {
          l.attr("dy", parseFloat(l.attr("dy")) + maxYShift + this.padding.ticsSpacing);
        });
        Object.keys(letterYPositions).forEach((k: any) => {
          letterYPositions[k] += maxYShift + this.padding.ticsSpacing;
        });
        dy += maxYShift + this.padding.ticsSpacing;
        lineTop += maxYShift + this.padding.ticsSpacing;
      }

      this.annotations
        .filter((l) => {
          // return l.index[0] <= right && l.index[0] + l.length >= left && l.type === "restrictase" && l.length > 2;
          return l.left <= right && l.right >= left && l.type === "restrictase" && l.length > 2;
        })
        .forEach((l, k) => {
          foundAnnotations = true;
          // const [i, j] = this.checkAnnotationDirection(l.index[0], l.index[0] + l.length - 1, left, right, l.direction);
          let [i, j] = this.checkAnnotationDirection(l.left, l.right - 1, left, right, l.direction);

          // const p0 = l.index[0] + (l?.cut?.[0] || 0);
          // const p1 = l.index[0] + (l?.cut?.[1] || 0);
          const p0 = l.left + (l?.cut?.[0] || 0);
          const p1 = l.left + (l?.cut?.[1] || l?.cut?.[0] || 0);

          let width = (j - i) * this.padding.letterSpacing;
          for (let p = 0; p <= j - i + 1; p++) {
            width += this.letterSize[seq[i + p]].width;
          }
          // width =
          //   letterXPositions[j] - letterXPositions[i] + this.letterSize[seq[j]].width + this.padding.letterSpacing;
          // if (l.label[0] === "Test") {
          //   // j--;

          //   console.log(
          //     ">>",
          //     Array(j - i + 1)
          //       .fill(0)
          //       .map((a, b) => i + b + "" + seq[i + b])
          //   );
          //   console.log("pos", l.left, l.right, "border", left, right, "->", i, j);
          //   console.log("cut", l?.cut?.[0], l?.cut?.[1], "->", p0, p1);
          //   console.log("width", width, letterPositions[j] - letterPositions[i] + this.letterSize[seq[j]].width);
          // }
          const textY = parseFloat(restrictase[k]?.attr("dy") || 0) + 1;
          this.lineGroup
            .append("rect")
            .attr("id", "restrictase_" + l?.id || 0)
            // .attr("x", letterPositions[i] - this.letterWidth / 2)
            .attr("x", letterXPositions[i] - this.letterSize[seq[i]].width / 2 - this.padding.letterSpacing / 2)
            // .attr("x", letterXPositions[i] - this.letterSize[seq[i]].width / 2)
            .attr("y", lineTop + this.padding.ticsSpacing / 2)
            // .attr("width", letterPositions[j] - letterPositions[i] + this.padding.letterSpacing / 2)
            .attr("width", width)
            .attr("height", 2 * this.letterSize[seq[0]].height)
            .attr("stroke", "none")
            .attr("opacity", 0.5)
            .attr("fill", "#87CEEB")
            .style("display", "none");

          if (p0 >= i && p0 <= j) {
            // console.log("letter", this.letterSize[seq[p0]]);
            this.lineGroup
              .append("line")
              // .attr("x1", letterPositions[p0] - this.letterWidth / 2)
              // .attr("y1", textY)
              // .attr("x2", letterPositions[p0] - this.letterWidth / 2)

              .attr("x1", letterXPositions[p0] - this.letterSize[seq[p0]].width / 2 - this.padding.letterSpacing / 2)
              .attr("y1", textY)
              .attr("x2", letterXPositions[p0] - this.letterSize[seq[p0]].width / 2 - this.padding.letterSpacing / 2)

              .attr("y2", lineTop + 1.25 * this.letterSize[seq[0]].height)
              .attr("fill", "none")
              .attr("stroke", "#0000CD")
              .attr("stroke-width", "1.5px");
          }
          let lineData: any[] = [];
          // letterPositions[p0] - this.letterWidth / 2
          // console.log("p0", p0 < left, p0 > right, p1 < left, p1 > right);
          const pos0 = letterXPositions[p0] - this.letterSize[seq[p0]].width / 2 - this.padding.letterSpacing / 2;
          const pos1 = letterXPositions[p1] - this.letterSize[seq[p1]].width / 2 - this.padding.letterSpacing / 2;
          const posI = letterXPositions[i] - this.letterSize[seq[i]].width / 2 - this.padding.letterSpacing / 2;
          const posJ = letterXPositions[j] + this.letterSize[seq[j]].width / 2 + this.padding.letterSpacing / 2;
          // pos1

          if (p0 < left) {
            if (p1 >= left) {
              // console.log("case 1", l.label[0]);
              lineData = [
                [posI, lineTop + 1.25 * this.letterSize[seq[0]].height],
                [posI, lineTop + 1.25 * this.letterSize[seq[0]].height],
                [pos1, lineTop + 1.25 * this.letterSize[seq[0]].height],
                [pos1, lineTop + 2 * this.letterSize[seq[0]].height + this.padding.ticsSpacing / 2],
              ];
            }
          } else if (p0 > right) {
            if (p1 <= right) {
              // console.log("case 2", l.label[0]);
              lineData = [
                [posJ, lineTop + 1.25 * this.letterSize[seq[0]].height],
                [pos1, lineTop + 1.25 * this.letterSize[seq[0]].height],
                [pos1, lineTop + 1.25 * this.letterSize[seq[0]].height],
                [pos1, lineTop + 2 * this.letterSize[seq[0]].height + this.padding.ticsSpacing / 2],
              ];
            }
          } else {
            if (p1 < left) {
              // console.log("case 3", l.label[0]);
              lineData = [
                [pos0, lineTop + 1.25 * this.letterSize[seq[0]].height],
                [posI, lineTop + 1.25 * this.letterSize[seq[0]].height],
              ];
            } else if (p1 > right) {
              // console.log("case 4", l.label[0]);
              lineData = [
                [pos0, lineTop + 1.25 * this.letterSize[seq[0]].height],
                [posJ, lineTop + 1.25 * this.letterSize[seq[0]].height],
              ];
            } else {
              // console.log("case 5", l.label[0]);
              lineData = [
                [pos0, lineTop + 1.25 * this.letterSize[seq[0]].height],
                [pos1, lineTop + 1.25 * this.letterSize[seq[0]].height],
                [pos1, lineTop + 2 * this.letterSize[seq[0]].height + this.padding.ticsSpacing / 2],
              ];
            }
          }

          this.lineGroup
            .append("path")
            .attr("id", "restrictase_" + l?.id || 0)
            .attr("d", this.svgLine(lineData))
            .attr("fill", "none")
            .attr("stroke", "#0000CD")
            .attr("stroke-width", "1.5px")
            .style("display", "none");
        });
    }

    foundAnnotations = false;
    if (this.showORF)
      this.annotations
        .filter((l) => {
          // return l.index[0] <= right && l.index[0] + l.length >= left && l.type === "orf" && l.length > 2;
          return l.left <= right && l.right >= left && l.type === "orf" && l.length > 2;
        })
        .forEach((l) => {
          // console.log("--");
          foundAnnotations = true;
          l.length -= l.length % 3;
          const [iScreen, jScreen] = this.checkAnnotationDirection(
            // l.index[0],
            // l.index[0] + l.length - 1,
            l.left,
            l.right - 1,
            left,
            right,
            l.direction
          );

          // const i = iScreen - ((iScreen - l.index[0]) % 3);
          const i = iScreen - ((iScreen - l.left) % 3);
          // console.log("direction", (iScreen - l.index[0]) % 3, 3*Math.ceil((jScreen - i + 1) / 3) );
          const j = i + 3 * Math.ceil((jScreen - i + 1) / 3) - 1;
          let peptide;
          let triplet;
          let ids;
          // const peptide = this.codonTranslator.translateToPeptide(seq.slice(i, j + 1)).map((aa) => aa.toUpperCase());
          if (l.direction === "backward") {
            const reverse = seq
              .slice(i, j + 1)
              .map((n) => anti(n))
              .reverse();
            peptide = this.codonTranslator.translateToPeptide(reverse).reverse();
            triplet = this.codonTranslator.translateToTriplet(reverse).reverse();
            // const from = 1 + Math.floor((i - l.index[0]) / 3);
            // const to = 1 + Math.floor((j - l.index[0]) / 3);
            const from = 1 + Math.floor((i - l.left) / 3);
            const to = 1 + Math.floor((j - l.left) / 3);
            ids = [];
            for (let i = to; i >= from; i--) {
              ids.push(i);
            }
            // console.log("sequence", seq.slice(i, j + 1).join(""));
            // console.log("anti    ", reverse.join(""));
            // console.log("peptide  ", peptide);
            // console.log("sequence", peptide.map((p) => this.codonTranslator.getOneLetterCode(p)).join(""));
          } else {
            // peptide = this.codonTranslator.translateToPeptide(seq.slice(i, j + 1));
            // triplet = this.codonTranslator.translateToTriplet(seq.slice(i, j + 1));
            peptide = this.codonTranslator.translateToPeptide(seq.slice(i, j + 1));
            triplet = this.codonTranslator.translateToTriplet(seq.slice(i, j + 1));

            const from = 1 + Math.floor((i - l.left) / 3);
            const to = 1 + Math.floor((j - l.left) / 3);
            // console.log("i,j", i, j + 1, l.right);
            ids = [];
            for (let i = from; i <= to; i++) {
              // console.log("i", i);
              ids.push(i);
            }
            // console.log("sequence", seq.slice(iScreen, jScreen + 1).join(""));
            // console.log("sequence", seq.slice(i, j + 1).join(""));
            // console.log("peptide  ", peptide.map((p) => this.codonTranslator.getOneLetterCode(p)).join(""));
          }

          let p = 0;
          for (let k = i; k < j; k += 3) {
            // console.log(k, seq.slice(k, k + 3).join(""), "->", this.codonTranslator.getOneLetterCode(peptide[p]));
            // console.log(seq.slice(k, k + 3).join(""), "->", peptide[p], this.getAminoacidColorHex(peptide[p]));
            const p0 = k < iScreen ? iScreen : k;
            const p1 = k + 2 > jScreen ? jScreen : k + 2;
            // console.log("k", k, k + 2, "->", p0, p1, this.sequence[p0], this.sequence[p1], peptide[p]);
            const lineData = this.getPointyBox(
              letterXPositions[p0],
              letterXPositions[p1],
              dy,
              this.padding.peptideHeight,
              this.letterWidth,
              "dash-" + l.direction
            );

            this.lineGroup
              .append("path")
              .attr("d", this.svgLine(lineData))
              // .attr("id", console.log("pep", p, peptide[p]))
              .attr("fill", d3.hsl(this.getAminoacidColorHex(peptide[p])).brighter(0.5))
              // .attr("fill", SequenceViewer.color?.[peptide[p]]?.[2])
              .attr("stroke", "black")
              .attr("stroke-width", "1px")
              .on(
                "mouseover",
                mouseover(
                  "<b>" +
                    l.label[0] +
                    "</b><br>" +
                    l.index[0] +
                    ":" +
                    (l.index[0] + l.length) +
                    "<br><b>" +
                    peptide[p] +
                    (p + 1) +
                    "</b><br>" +
                    (k + 1) +
                    ":" +
                    (k + 3) +
                    "<br>" +
                    triplet[p]
                )
              )
              .on("mousemove", mousemove)
              .on("mouseout", mouseout);

            if (p1 - p0 > 0)
              this.lineGroup
                .append("text")
                .text(this.showORFLetter ? this.codonTranslator.getOneLetterCode(peptide[p]) : ids[p])
                .attr("letter", this.codonTranslator.getOneLetterCode(peptide[p]))
                // .attr("code", l.direction === "backward" ? peptide.length - p + 1 : p + 1)
                .attr("code", ids[p])
                .attr("id", "orfLabels")
                // .attr("dx", letterPositions[k] + (letterPositions[k + 3] - letterPositions[k] - letterWidth) / 2)
                .attr("dx", letterXPositions[p0] + (letterXPositions[p1] - letterXPositions[p0]) / 2)
                .attr("dy", dy + this.padding.peptideHeight / 2 + 1)
                .style("font-size", this.padding.peptideHeight * 0.9 + "px")
                .attr("class", "aminoacidText")
                .style("text-anchor", "middle")
                .style("dominant-baseline", "middle")
                .on(
                  "mouseover",
                  mouseover(
                    "<b>" +
                      l.label[0] +
                      "</b><br>" +
                      l.index[0] +
                      ":" +
                      (l.index[0] + l.length) +
                      "<br><b>" +
                      peptide[p] +
                      (p + 1) +
                      "</b><br>" +
                      (k + 1) +
                      ":" +
                      (k + 3) +
                      "<br>" +
                      triplet[p]
                  )
                )
                .on("mousemove", mousemove)
                .on("mouseout", mouseout);

            p++;
          }

          //   const rect = this.lineGroup
          //     .selectAll("path")
          //     .data(lineData)
          //     .enter()
          //     .append("path")
          //     .attr("d", (d: any) => svgLine(d))
          //     .attr("fill", (d: any, i: number) => (i % 2 == 0 ? "red":"blue"))
          //     .attr("stroke", "black")
          //     .attr("stroke-width", "1px");
        });
    if (foundAnnotations) dy += this.padding.boxHeight + this.padding.ticsSpacing;

    foundAnnotations = false;
    if (this.showMarker)
      this.annotations
        .filter((l) => {
          // return l.index[0] <= right && l.index[0] + l.length >= left && l.type === "marker";
          return l.left <= right && l.right >= left && l.type === "marker";
        })
        .forEach((l) => {
          foundAnnotations = true;
          const [i, j, direction] = this.checkAnnotationDirection(
            // l.index[0],
            // l.index[0] + l.length - 1,
            l.left,
            l.right,
            left,
            right,
            l.direction
          );

          // let i = l.index[0];
          // let j = l.index[0] + l.length;
          // let type = l.direction || "default";
          // if (i < left) {
          //   i = left;
          //   if (type == "backward") type = "default";
          // }
          // if (j > right) {
          //   j = right;
          //   if (type == "forward") type = "default";
          // }

          // const i = l.index[0] < left ? left : l.index[0];
          // const j = l.index[0] + l.length > right ? right : l.index[0] + l.length;
          const lineData = this.getPointyBox(
            letterXPositions[i],
            letterXPositions[j],
            dy,
            this.padding.boxHeight,
            this.letterWidth,
            direction
          );

          this.lineGroup
            .append("path")
            .attr("d", this.svgLine(lineData))
            .attr("fill", l.color)
            .style("filter", "url(#Bevel)")
            .on("mouseover", mouseover("<b>" + l.label[0] + "</b><br>" + l.index[0] + ":" + (l.index[0] + l.length)))
            .on("mousemove", mousemove)
            .on("mouseout", mouseout);
          // console.log("rect", box);
          const text = this.lineGroup
            .append("text")
            .text(l.label[0])
            .attr("dx", letterXPositions[i] + (letterXPositions[j] - letterXPositions[i]) / 2)
            .attr("dy", dy + this.padding.boxHeight / 2)
            .style("font-size", this.padding.boxHeight * 0.9 + "px")
            .attr("class", "boxText")
            .style("text-anchor", "middle")
            .style("dominant-baseline", "middle")
            .on("mouseover", mouseover("<b>" + l.label[0] + "</b><br>" + l.index[0] + ":" + (l.index[0] + l.length)))
            .on("mousemove", mousemove)
            .on("mouseout", mouseout);

          const box = text.node()?.getBBox();
          const w = box?.width || 0;
          if (w > letterXPositions[j] - letterXPositions[i]) text.remove();
        });

    if (foundAnnotations) dy += this.padding.boxHeight + this.padding.ticsSpacing;

    this.lineGroup
      .append("line")
      .attr("id", "line" + line.number)
      .attr("class", "sequenceLine")
      // .attr("x1", line.x)
      // .attr("y1", dy)
      // .attr("x2", line.x + line.width)
      // .attr("y2", dy)
      .attr("x1", letterXPositions[offset])
      .attr("y1", dy)
      .attr("x2", letterXPositions[line.lastPosition])
      .attr("y2", dy)
      .attr("shape-rendering", "crispEdges");

    let lineSelection = this.lineGroup.selectAll("p").data(line.tics).enter();
    // let lineSelection = this.lineGroup.selectAll("p").data([]).enter();
    lineSelection
      .append("line")
      // .attr("id", (d: any) => {
      //   console.log("d", d);
      //   return "id";
      // })
      .attr("id", (d: sequenceTic) => "line" + line.number + "-" + d.number)
      .attr("class", "sequenceTic")
      // // .attr("stroke", (d) => d.color)
      .attr("x1", (d: sequenceTic) => d.position)
      .attr("y1", dy)
      .attr("x2", (d: sequenceTic) => d.position)
      .attr("y2", dy + this.padding.ticLength)
      .attr("shape-rendering", "crispEdges");

    dy += this.padding.ticLength;

    lineSelection
      .insert("text")
      // .attr("id", (d) => {
      //   console.log("d", d);
      //   return "id";
      // })
      .attr("id", (d: sequenceTic) => "pos" + line.number + "-" + d.number)
      // .text((d: sequenceTic) => d.number + 1)
      .text((d: sequenceTic) => this.sequenceIndex[d.number])
      .attr("dx", (d: sequenceTic) => d.position)
      .attr("dy", dy + this.letterSize["tics"].height)
      // .style("text-anchor", "middle")
      .attr("class", "sequenceNumber");

    dy += this.padding.ticLength + this.letterSize["tics"].height;

    line.height = dy - line.y;

    // console.log("letterPosition", this.letterPosition);

    Object.keys(letterXPositions).forEach((i: any) =>
      this.letterPosition.push({
        x: letterXPositions[i] - this.letterSize[seq[i]].width / 2,
        y: letterYPositions[i],
        line: line.number,
      })
    );

    // let re = /\bsense\b/;
    // letters.forEach((l, i) => {
    //   if (i % 2 === 0)
    //     this.letterPosition.push({ x: parseFloat(l.attr("dx")), y: parseFloat(l.attr("dy")), line: line.number });
    // });

    return line;
  }

  getViewBoxText(x: number, y: number, width: number, height: number, decorate: boolean) {
    let viewBox = x + " " + y + " " + width + " " + height;
    if (decorate) viewBox = '"' + viewBox + '"';
    return viewBox;
  }

  getSVGString(css = undefined) {
    const svg = d3.create("svg").node();
    if (!svg) return undefined;
    const { width, height } = this.svg.node().getBBox();
    svg.setAttribute("width", width);
    svg.setAttribute("height", height);

    const rect = d3
      .create("rect")
      .attr("x", 0)
      .attr("y", 0)
      .attr("id", "background")
      .style("fill", "white")
      .attr("width", width)
      .attr("height", height)
      .node();

    if (rect) svg.appendChild(rect as Node);

    svg.appendChild(this.svg.node().cloneNode(true));

    const localCSS = css === undefined ? getSVGCSS(svg) : css;

    if (localCSS) addCSS(localCSS, svg);

    svg.setAttribute("xmlns:xlink", "http://www.w3.org/1999/xlink");

    let xml = new XMLSerializer().serializeToString(svg);
    xml = xml.replace(/xmlns="http:\/\/www.w3.org\/1999\/xhtml"/gi, 'xmlns:css="http://www.w3.org/1999/xhtml"');

    xml = xml.substring(0, 5) + "viewBox=" + this.getViewBoxText(0, 0, width, height, true) + " " + xml.substring(5);

    return xml;
  }

  getIndicesOf(str: string, searchStr: string, caseSensitive: boolean) {
    var searchStrLen = searchStr.length;
    if (searchStrLen === 0) {
      return [];
    }
    var startIndex = 0,
      index,
      indices = [];
    if (!caseSensitive) {
      str = str.toLowerCase();
      searchStr = searchStr.toLowerCase();
    }
    while ((index = str.indexOf(searchStr, startIndex)) > -1) {
      indices.push(index);
      startIndex = index + 1;
    }
    return indices;
  }

  removeAllMarker(redraw: boolean = true) {
    this.markers = [];
    if (redraw) this.drawMarker();
  }

  addMarker(pos: number, length: number, antisense: boolean, redraw: boolean = true) {
    let start = pos;
    let end = pos + length - 1;
    if (start < 0) start = 0;
    if (end >= this.letterPosition.length) end = this.letterPosition.length - 1;
    // console.log("add", start, end)
    // this.markers.push([start, end]);
    this.markers.push({ start: start, end: end, antisense: antisense });
    if (redraw) this.drawMarker();
  }

  drawMarker() {
    const markers: markerType[] = [];
    this.markers.forEach((m) => {
      // console.log("---");
      // const [start, end] = m;
      let left = m.start;
      let right = m.end;
      for (right = left; right <= m.end; right++) {
        // console.log(
        //   "line",
        //   right,
        //   this.sequence[right],
        //   this.letterPosition[left].line,
        //   this.letterPosition[right].line
        // );
        // console.log("line", right);
        if (this.letterPosition[left].line !== this.letterPosition[right].line) {
          // console.log("push", left, right - 1);
          markers.push({
            start: left,
            end: right - 1,
            antisense: m.antisense,
          });
          left = right;
        }
      }
      // console.log("push", left, right);
      markers.push({ start: left, end: right - 1, antisense: m.antisense });
    });
    // console.log("markers", markers);
    if (!this.markerGroup) this.markerGroup = this.svg.append("g").attr("id", "sequenceMarkers");

    const u = this.markerGroup.selectAll("rect").data(markers);
    // this.letterSize[seq[j]].width / 2 + this.padding.letterSpacing / 2

    // this.markerGroup.selectAll("#hurz").remove();
    // const c = ["red", "green", "blue"];
    // for (let p = 0; p < 50 && p < this.sequence.length; p++) {
    //   console.log(">>", this.sequence[p], this.letterPosition?.[p]?.line, this.letterPosition?.[p]?.y);
    //   this.markerGroup
    //     .append("line")
    //     .attr("id", "hurz")
    //     .attr("x1", this.letterPosition[p].x)
    //     .attr("y1", this.letterPosition[p].y)
    //     .attr("x2", this.letterPosition[p].x)
    //     .attr("y2", this.letterPosition[p].y + 10)
    //     .attr("fill", "none")
    //     .attr("stroke", c[this.letterPosition[p].line % c.length])
    //     .attr("stroke-width", "1.5px");
    // }

    u.enter()
      .append("rect")
      .merge(u)
      .attr("id", (m: markerType, i: number) => {
        if (i === 0) return "first-marker";
        return undefined;
      })
      .attr("x", (m: markerType) => this.letterPosition[m.start].x)
      .attr(
        "y",
        (m: markerType) =>
          this.letterPosition[m.start].y -
          this.letterSize[this.sequence[m.start]].height +
          this.padding.ticsSpacing / 2 +
          (m.antisense ? this.letterSize[this.sequence[m.start]].height : 0)
      )
      .attr(
        "width",
        (m: markerType) =>
          this.letterPosition[m.end].x - this.letterPosition[m.start].x + this.letterSize[this.sequence[m.end]].width

        // (m: markerType) => {
        //   let width = (m.end - m.start) * this.padding.letterSpacing;
        //   for (let p = 0; p <= m.end - m.start + 1; p++) {
        //     width += this.letterSize[this.sequence[m.start + p]].width;
        //   }
        //   return width;
        // }
      )
      .attr("height", (m: markerType) => this.letterSize[this.sequence[m.start]].height)
      .attr("fill", "#FF8C00")
      .attr("opacity", 0.5)
      .attr("pointer-events", "none");

    u.exit().remove();

    if (this.markerGroup.select("#first-marker").node()) this.markerGroup.select("#first-marker").node().focus();
    // console.log("marker box", this.markerGroup.select("#first-marker").node());
  }

  selectAll(selection: string, caseSensitive: boolean = false, antisense: boolean = false): number {
    this.removeAllMarker();
    if (antisense) selection = this.makeAntisense(selection, true);
    this.getIndicesOf(this.stringSequence, selection, caseSensitive).forEach((i) => {
      this.addMarker(i, selection.length, antisense, false);
    });
    this.drawMarker();
    return this.markers.length;
  }
}
