import React from "react";
import styles from "./CurrentIndicator.module.css";
import { LucideIcon } from "../../icon/LucideIcon";

interface CurrentIndicatorProps {
  iconOnly?: boolean;
}
export const CurrentIndicator = ({ iconOnly }: CurrentIndicatorProps) => {
  return (
    <div className={`label label-soft-primary label-sm ${styles.currentIndicator}`} title="current">
      {iconOnly ? <LucideIcon name="eye" /> : "current"}
    </div>
  );
};
