import { searchTermMinLength } from "../../../forms/SearchInputWithOptions/SearchInputWithOptions";
import styles from "../GenericVirtualizedSelectFormField.module.css";

export const NoResults = ({ message }: { message?: string }) => (
  <div className={`flex row-nowrap align-center justify-center ${styles.noResultsContainer}`}>
    <span className={styles.ellipsisSpan}>{message ? message : "No options"}</span>
  </div>
);

export const SearchTermError = ({ message }: { message?: string }) => (
  <div className={`flex row-nowrap align-center justify-center ${styles.searchTermError}`}>
    <span className={styles.ellipsisSpan}>
      {message ? message : `Please enter at least ${searchTermMinLength} characters...`}
    </span>
  </div>
);
