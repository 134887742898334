import { useMemo, useState } from "react";
import {
  InventoryItem,
  InventoryItemFilters,
  inventoryItemsConstants,
  InventoryItemSortingOptionsConsts,
} from "../api/Inventories";
import { OrderBySelectDropdown } from "../common/buttons/OrderBySelectDropdown/OrderBySelectDropdown";
import { SearchInput } from "../common/forms/SearchInput/SearchInput";
import { EntityList } from "../common/lists/EntityList";
import { LucideIcon } from "../common/icon/LucideIcon";
import { NotAvailable } from "../common/misc/UIconstants";
import { useDebouncedValue } from "../common/helperfunctions/useDebouncedValue";
import { MoreDropdown } from "../common/buttons/MoreDropdown/MoreDropdown";
import { ToggleButtonComponent } from "../ViewerUIElements/ToggleButtonComponent";
import { GenericEntitySelectRenderer } from "../common/forms/common/GenericEntitySelectRenderer";

export const InventoryItemList = ({
  selectedItems,
  onSelectItem,
  filters: forcedFilters,
}: {
  selectedItems: InventoryItem[] | InventoryItem | null;
  onSelectItem: (item: InventoryItem) => void;
  filters: InventoryItemFilters;
}) => {
  const [searchValue, setSearchValue] = useState<string>("");
  const debouncedSearchValue = useDebouncedValue(searchValue, 300);
  const [filters, setFilters] = useState<InventoryItemFilters>({ orderBy: "NAME_ASC" });
  const activeFilters: InventoryItemFilters = useMemo(
    () => ({ ...filters, ...forcedFilters, searchTerm: debouncedSearchValue }),
    [debouncedSearchValue, filters, forcedFilters]
  );
  return (
    <div
      style={{
        display: "grid",
        gridTemplateRows: "max-content 1fr",
        gap: "5px",
        width: "100%",
        height: "100%",
        padding: "5px 0",
      }}
    >
      <div className="flex align-center gap-5" style={{ padding: "10px" }}>
        <SearchInput
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          placeholder="Search inventory items..."
        />
        <OrderBySelectDropdown
          btnCls="btn btn-default"
          orderByOptions={InventoryItemSortingOptionsConsts.filter(
            (o) =>
              o !== "LAYOUT_ASC" &&
              o !== "LAYOUT_DESC" &&
              o !== "INVENTORY_NAME_ASC" &&
              o !== "INVENTORY_NAME_DESC" &&
              o !== "NUMBER_OF_ITEMS_ASC" &&
              o !== "NUMBER_OF_ITEMS_DESC" &&
              o !== "TYPE_ASC" &&
              o !== "TYPE_DESC"
          )}
          currentFilters={activeFilters}
          setCurrentFilters={setFilters}
          drop="right"
        />
        <MoreDropdown icon={<LucideIcon name="filter" />} btn="btn btn-default" drop="right" closeOnClickInside={false}>
          <label className="flex align-center gap-5 btn btn-ghost-secondary btn-sm">
            <ToggleButtonComponent
              checked={!!filters.includeSoftDeleted}
              setChecked={() =>
                setFilters((prevState) => ({ ...prevState, includeSoftDeleted: !prevState.includeSoftDeleted }))
              }
            >
              <></>
            </ToggleButtonComponent>
            Include trashed
          </label>
        </MoreDropdown>
      </div>
      <div style={{ padding: "0 10px" }}>
        <EntityList<InventoryItem, InventoryItemFilters>
          filters={activeFilters}
          entityConstant={inventoryItemsConstants}
          rowHeight={30}
          rowRenderer={(e) => (
            <GenericEntitySelectRenderer
              row={e}
              entityConstants={inventoryItemsConstants}
              onRowEndRenderer={(row) => (
                <label className="label label-soft-info" style={{ margin: 0 }}>
                  {row.customType?.name || NotAvailable}
                </label>
              )}
              onClick={(e, row) => onSelectItem(row)}
              hideId
              selected={
                selectedItems === null
                  ? undefined
                  : Array.isArray(selectedItems)
                  ? selectedItems.map((i) => i.id).includes(e.id)
                  : typeof selectedItems === "object" && Object.hasOwn(selectedItems, "id")
                  ? selectedItems.id === e.id
                  : false
              }
            />
          )}
        />
      </div>
    </div>
  );
};
