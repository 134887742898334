import {
  PropertyTranslator,
  IGenericRequestParametersTranslator,
  IPaginationParametersTranslator,
  IRelationParametersTranslator,
  ICreatedRecordParametersTranslator,
  IModifiedRecordParametersTranslator,
  ICreationRecordLabels,
  IModificationRecordLabels,
  INamedEntityLabels,
  IPermissionedEntityLabels,
  IRelatedEntityLabels,
} from "./GenericTranslator";
import {
  DefaultCreatedByRecordOrderTypeConsts,
  DefaultCreatedOnRecordOrderTypeConsts,
  DefaultModifiedByRecordOrderTypeConsts,
  DefaultModifiedOnRecordOrderTypeConsts,
  DefaultOrderTypeConsts,
  EntityConstants,
  ICreatedRecordParameters,
  ICreationRecord,
  IEntityMinimalModel,
  IGenericRequestParameters,
  IModelWithIntId,
  IModificationRecord,
  IModifiedRecordParameters,
  INamedEntity,
  IPaginationParameters,
  IPermissionedEntity,
  IRelatedEntity,
  IRelation,
  IRelationModel,
  IRelationParameters,
} from "./GenericTypes";

export const equipmentTagsConstants: EntityConstants<EquipmentTag, EquipmentTagFilters> = {
  resource: "equipment_tags",
  frontendIndexRoute: "equipment_tags",
  entitySingular: "equipment tag",
  entityPlural: "equipment tags",
  icon: "tag",
};

export const EquipmentTagFieldLabels: PropertyTranslator<EquipmentTag> = {
  ...INamedEntityLabels,
  ...IPermissionedEntityLabels,
  ...ICreationRecordLabels,
  ...IModificationRecordLabels,
  ...IRelatedEntityLabels,
  id: "Equipment tag ID",
  notes: "Notes",
} as const;

export interface EquipmentTagRelations extends IRelationModel {
  equipments: IRelation;
}

export interface EquipmentTag
  extends IPermissionedEntity,
    IModelWithIntId,
    INamedEntity,
    IRelatedEntity<EquipmentTagRelations>,
    ICreationRecord,
    IModificationRecord {
  notes: string;
}
export interface EquipmentTagSuggestions extends IEntityMinimalModel<EquipmentTag["id"]> {}

export interface EquipmentTagFilters
  extends IGenericRequestParameters<EquipmentTag, EquipmentTagSortingOptions>,
    IPaginationParameters,
    IRelationParameters,
    ICreatedRecordParameters,
    IModifiedRecordParameters {
  notes?: string | null;
}
export const EquipmentTagFiltersTranslator: PropertyTranslator<EquipmentTagFilters> = {
  ...IGenericRequestParametersTranslator,
  ...IPaginationParametersTranslator,

  ...IRelationParametersTranslator,
  ...ICreatedRecordParametersTranslator,
  ...IModifiedRecordParametersTranslator,
  notes: "Notes",
} as const;

export const EquipmentTagSortingOptionsConsts = [
  ...DefaultOrderTypeConsts,
  ...DefaultCreatedOnRecordOrderTypeConsts,
  ...DefaultCreatedByRecordOrderTypeConsts,
  ...DefaultModifiedOnRecordOrderTypeConsts,
  ...DefaultModifiedByRecordOrderTypeConsts,
] as const;
export type EquipmentTagSortingOptions = (typeof EquipmentTagSortingOptionsConsts)[number];
