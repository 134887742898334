import { useState, useEffect, useLayoutEffect, useRef, CSSProperties } from "react";

import styles from "./Tools.module.css";

export type ToolsType = { x?: number; y?: number; active?: boolean };

export const Tools = function (props: any) {
  const {
    state,
    setState,
    onClose,
  }: {
    state: ToolsType;
    setState: (state: ToolsType) => void;
    x: number | undefined;
    y: number | undefined;
    onClose?: () => void;
  } = props;
  // const data = ["item1", "item2", "item3", ["item4.1", "item4.2", "item4.3"]];
  const targetRef = useRef<HTMLDivElement>(null);
  const [style, setStyle] = useState<CSSProperties>({
    left: 0,
    top: 0,
  });

  useEffect(() => {
    if (targetRef.current) {
      const bound = targetRef.current.getBoundingClientRect();
      // console.log("did mount", bound);
      // console.log("screen", window.innerWidth);
      let x = state.x || 0;
      let y = state.y || 0;
      const right = x + bound.width;
      const bottom = y + bound.height;
      // console.log("screen", right, window.screen.width, window.innerWidth);
      // const width = window.screen.width;
      // const height = window.screen.height;
      const width = window.innerWidth;
      const height = window.innerHeight;

      if (right > width) {
        x -= right - width;
      }
      if (bottom > height) {
        y -= bottom - height;
      }
      if (x !== state.x || y !== state.y) {
        setState({ x: x, y: y, active: state.active });
      }
    }
  }, [setState, state]);

  // const tree = new KeyValueTree(data);
  // console.log("tree", tree.toString());
  // console.log("tree", props.children);
  useLayoutEffect(() => {
    // console.log("pos", x, y);
    if (state.x || state.y) setStyle({ left: state.x ? state.x - 3 : 0, top: state.y ? state.y - 3 : 0 });
  }, [state]);

  if (!state.active) return null;

  return (
    <div
      ref={targetRef}
      className={styles.main}
      style={style}
      onMouseLeave={() => {
        // setStyle({ left: x ? x - 3 : 0, top: y ? y - 3 : 0 });
        if (onClose) onClose();
        setState({ active: false });
      }}
      onClick={(e) => {
        // console.log("click background");
        e.stopPropagation();
      }}
    >
      {props.children}
    </div>
  );
};
