import { useCallback, useEffect, useMemo, useState } from "react";
import { useDebouncedValue } from "../../../../../common/helperfunctions/useDebouncedValue";
import ContentRow from "../../../../../common/tables/ContentRow/ContentRow";
import { SearchInput } from "../../../../../common/forms/SearchInput/SearchInput";
import { Alert } from "../../../../../common/overlays/Alert/Alert";
import Select from "react-select";
import { Dataset, DatasetFilters } from "../../../../../api/Datasets";
import { Sample, SampleFieldLabels, SampleFilters, samplesConstants } from "../../../../../api/Samples";
import { LucideIcon } from "../../../../../common/icon/LucideIcon";
import { DateTimeRenderer } from "../../../../../common/datetime/DateTimeFormatter";
import { LabNotebookEntry, LabNotebookEntryFilters } from "../../../../types/LabNotebookEntry";
import {
  LabNotebookExperiment,
  labNotebookExperimentFieldLabels,
  LabNotebookExperimentFilters,
  labNotebookExperimentsConstants,
} from "../../../../types/LabNotebookExperiment";
import { LabNotebook, LabNotebookFilters } from "../../../../types/LabNotebook";
import { Person, PersonFieldLabels, PersonFilters, personsConstants } from "../../../../../api/Person";
import { LabNotebookTemplateFilters } from "../../../../types/LabNotebookTemplate";
import { LabNotebookTemplate } from "../../../../types/LabNotebookTemplate";
import { Modal } from "../../../ELNModal/ELNModal";
import { DatasetsFilterbar } from "../../../../../Dataset/Table/DatasetsFilterBar";
import { SampleFilterBar } from "../../../../../samples/Table/SampleFilterBar";
import { LabNotebookTemplateFilterBar } from "../../../../LabNotebookTemplates/Table/LabNotebookTemplatesFilterBar";
import { Project, ProjectFieldLabels, ProjectFilters, projectsConstants } from "../../../../../api/Projects";
import { LabNotebookEntryViewOnly } from "../../../../LabNotebookEntry/LabNotebookEntryViewOnly/LabNotebookEntryViewOnly";
import { TextEditor } from "../../TextEditor";
import Status from "../../../../../common/badges/Status/Status";
import { LabNotebookFilterBar } from "../../../../LabNotebooks/Table/LabNotebooksFilterBar";
import { LabNotebookDetailContentTable } from "../../../../LabNotebooks/LabNotebookDetailContentTable";
import { LabNotebookExperimentFilterBar } from "../../../../LabNotebookExperiments/Table/LabNotebookExperimentsFilterBar";
import { LabNotebookEntriesFilterBar } from "../../../../LabNotebookEntries/Table/LabNotebookEntriesFilterBar";
import { SampleDetailTable } from "../../../../../samples/SamplesDetail";
import {
  LabNotebookExperimentDetailContentTable,
  LabNotebookExperimentStatusToLabel,
} from "../../../../LabNotebookExperiments/LabNotebookExperimentDetailContentTable";
import { PersonDetailTable } from "../../../../../Persons/PersonsDetail";
import { ProjectsDetailTable } from "../../../../../Projects/ProjectsDetail";
import { ProjectFilterBar } from "../../../../../Projects/Table/ProjectFilterBar";
import { PersonFilterBar } from "../../../../../Persons/Table/PersonFilterBar";
import {
  InventoryItem,
  InventoryItemFieldLabels,
  InventoryItemFilters,
  inventoryItemsConstants,
} from "../../../../../api/Inventories";
import { InventoryItemsDetailTable } from "../../../../../Inventories/InventoryItemsDetail";
import { InventoryItemsFilterBar } from "../../../../../Inventories/Table/InventoryItemsFilterBar";
import { ViewerModal } from "../../extensions/entity/entities/datasets/ViewerModal/ViewerModal";
import { GenericEntityConstantsEntities, genericEntityConstants } from "../../../../../api/GenericConstants";
import { AttachmentsFilterbar } from "../../../../../Attachments/Table/AttachmentsFilterBar";
import { getNotebookEntryStringLabel } from "../../../../LabNotebookEntry/LabNotebookEntry";
import { TableArrayRenderer } from "../../../../../common/misc/EntityRenders/EntityRenderer";
import { NotSet } from "../../../../../common/misc/UIconstants";
import { EntityList } from "../../../../../common/lists/EntityList";
import { UseTabActions } from "../../../../../common/tables/Tabs/TableTabsTypes";
import { useTabStore } from "../../../../../common/tables/Tabs/useTabStore";
import { IGenericEntity, INamedEntity, IUniqueEntity } from "../../../../../api/GenericTypes";
import { toUppercase } from "../../../../../common/helperfunctions/stringFunctions";
import { EntityFilterIndicator } from "../../../../../common/tables/EntityFilterIndicator/EntityFilterIndicator";
import { DatasetAdditionalSettings, EntityAttributes } from "../../extensions/entity/entity-types";

interface Props {
  foldable?: boolean;
  isFolded?: boolean;
  remirrorContextRef?: any;
}

const AddableContentOptions: GenericEntityConstantsEntities[] = [
  "attachments",
  "datasets",
  "notebookEntries",
  "notebookExperiments",
  "inventoryItems",
  "notebooks",
  "persons",
  "projects",
  "samples",
  "notebookTemplates",
];

const GetEntityFilter = (entityTypeId: GenericEntityConstantsEntities) => {
  switch (entityTypeId) {
    case "datasets":
      return { orderBy: "ACQUISITION_DATE_DESC", isViewableEntity: false } as DatasetFilters;
    case "notebookEntries":
      return { orderBy: "ENTRY_DATE_DESC" } as LabNotebookEntryFilters;
    case "notebookExperiments":
      return { orderBy: "MODIFIED_ON_DESC" } as LabNotebookExperimentFilters;
    case "notebooks":
      return { orderBy: "MODIFIED_ON_DESC" } as LabNotebookFilters;
    case "notebookTemplates":
      return { orderBy: "NAME_DESC" } as LabNotebookTemplateFilters;
    case "inventoryItems":
      return { orderBy: "NAME_ASC" } as InventoryItemFilters;
    case "attachments":
      return { orderBy: "CREATED_ON_DESC", isViewableEntity: true } as DatasetFilters;
    case "persons":
      return { orderBy: "NAME_ASC" } as PersonFilters;
    case "projects":
      return { orderBy: "NAME_ASC" } as ProjectFilters;
    case "samples":
      return { orderBy: "PREPARATION_DATE_DESC" } as SampleFilters;

    default:
      return {};
  }
};

const GetEntityIcon = (entityTypeId: GenericEntityConstantsEntities) => {
  const { entityConstants } = genericEntityConstants[entityTypeId];
  switch (entityTypeId) {
    default:
      return <LucideIcon name={entityConstants.icon} />;
  }
};

const GetEntityLabel = (
  entity: IGenericEntity & Partial<INamedEntity>,
  entityTypeId: GenericEntityConstantsEntities
) => {
  switch (entityTypeId) {
    case "notebookEntries":
      const entry = entity as LabNotebookEntry;
      return <>{getNotebookEntryStringLabel({ entry })}</>;
    case "notebookExperiments":
      const experiment = entity as LabNotebookExperiment;
      return (
        <div className="flex align-center gap-5">
          <Status
            idle={experiment.status === "RUNNING"}
            type={LabNotebookExperimentStatusToLabel(experiment.status).status as any}
          />{" "}
          {experiment.name}
        </div>
      );
    default:
      return entity.name ?? "-";
  }
};

const GetEntityInfo = (entity: IGenericEntity, entityTypeId: GenericEntityConstantsEntities) => {
  switch (entityTypeId) {
    case "datasets":
      const dataset = entity as Dataset;
      return (
        <>
          <label className="label label-soft-secondary" style={{ margin: 0 }}>
            {dataset.method?.name}
          </label>
          {dataset.experiment ? <span style={{ color: "var(--primary)" }}> {dataset.experiment.name}</span> : ""}
        </>
      );
    case "notebookEntries":
      const entry = entity as LabNotebookEntry;
      return <>{`/${entry.labNotebook.name}/${entry.labNotebookExperiment.name}`}</>;
    case "notebookExperiments":
      const experiment = entity as LabNotebookExperiment;
      return <>{`/${experiment.labNotebook.name}`}</>;
    case "notebooks":
      const notebook = entity as LabNotebook;
      return (
        <div className="flex align-center gap-5" style={{ overflow: "auto", paddingBottom: "1px" }}>
          <div className="label label-default">{notebook.createdBy.name}</div>
          {notebook.projects && notebook.projects.length > 0
            ? notebook.projects.map((p, index) => (
                <div key={index} className="label label-soft-default">
                  {p.name}
                </div>
              ))
            : "-"}
        </div>
      );
    case "inventoryItems":
      const inventory = entity as InventoryItem;
      if (inventory.ancestors.length === 0) {
        return NotSet;
      }
      return (
        <div
          style={{
            width: "100%",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
          title={"Hierarchy: " + inventory.ancestors.map((a) => a.name).join(" > ") + " > " + inventory.name}
        >
          <TableArrayRenderer values={inventory.ancestors.concat(inventory)}>
            {(value) => value.name}
          </TableArrayRenderer>
        </div>
      );
    case "attachments":
      const attachment = entity as Dataset;
      return (
        <>
          <label className="label label-soft-secondary" style={{ margin: 0 }}>
            {attachment.method?.name}
          </label>
        </>
      );
    case "persons":
      const person = entity as Person;
      return (
        <div className="flex align-center gap-5" style={{ overflow: "auto", paddingBottom: "1px" }}>
          {person.personTags && person.personTags.length > 0
            ? person.personTags.map((c, index) => (
                <div key={index} className="label label-soft-default">
                  {c.name}
                </div>
              ))
            : "-"}
        </div>
      );
    case "projects":
      const project = entity as Project;
      return (
        <div className="flex align-center gap-5" style={{ overflow: "auto", paddingBottom: "1px" }}>
          {project.projectTags && project.projectTags.length > 0
            ? project.projectTags.map((p, index) => (
                <div key={index} className="label label-soft-default">
                  {p.name}
                </div>
              ))
            : "-"}
        </div>
      );
    case "samples":
      const sample = entity as Sample;
      return (
        <>
          <label className="label label-soft-secondary" style={{ margin: 0 }}>
            {sample.customType ? sample.customType.name : "Basic sample"}
          </label>
        </>
      );
    case "notebookTemplates":
      const template = entity as LabNotebookTemplate;
      return <>{template.description || "-"}</>;

    default:
      return <div />;
  }
};

const GetEntityDate = (entity: IGenericEntity, entityTypeId: GenericEntityConstantsEntities) => {
  switch (entityTypeId) {
    case "datasets":
      const dataset = entity as Dataset;
      return dataset.acquisitionDate ? (
        <DateTimeRenderer date={dataset.acquisitionDate} includeElapsed={false} includeTime={false} />
      ) : (
        "-"
      );
    case "notebookEntries":
      const entry = entity as LabNotebookEntry;
      return (
        <div className="flex align-center gap-5">
          <LucideIcon name="save" style={{ marginBottom: "2px", width: "12px" }} />
          <DateTimeRenderer date={entry.modifiedOn} includeElapsed={true} includeTime={false} />
        </div>
      );
    case "notebookExperiments":
      const experiment = entity as LabNotebookExperiment;
      return (
        <div className="flex align-center gap-5">
          <LucideIcon name="save" style={{ marginBottom: "2px", width: "12px" }} />
          <DateTimeRenderer date={experiment.modifiedOn} includeElapsed={true} includeTime={false} />
        </div>
      );
    case "notebooks":
      const notebook = entity as LabNotebook;
      return (
        <div className="flex align-center gap-5">
          <LucideIcon name="save" style={{ marginBottom: "2px", width: "12px" }} />
          <DateTimeRenderer date={notebook.modifiedOn} includeElapsed={true} includeTime={false} />
        </div>
      );
    case "inventoryItems":
      const inventory = entity as InventoryItem;
      return (
        <div className="flex align-center gap-5">
          <LucideIcon name="save" style={{ marginBottom: "2px", width: "12px" }} />
          <DateTimeRenderer date={inventory.modifiedOn} includeElapsed={true} includeTime={false} />
        </div>
      );
    case "attachments":
      const attachment = entity as Dataset;
      return attachment.createdOn ? (
        <DateTimeRenderer date={attachment.createdOn} includeElapsed={false} includeTime={false} />
      ) : (
        "-"
      );
    case "persons":
      const person = entity as Person;
      return <>{person.organization?.name || "-"}</>;
    case "projects":
      const project = entity as Project;
      return <>{project.owner?.name || "-"}</>;
    case "samples":
      const sample = entity as Sample;
      return sample.preparedAt ? (
        <DateTimeRenderer date={sample.preparedAt} includeElapsed={false} includeTime={false} />
      ) : (
        "-"
      );
    case "notebookTemplates":
      const template = entity as LabNotebookTemplate;
      return (
        <div className="flex align-center gap-5">
          <LucideIcon name="save" style={{ marginBottom: "2px", width: "12px" }} />
          <DateTimeRenderer date={template.modifiedOn} includeElapsed={true} includeTime={false} />
        </div>
      );

    default:
      return <div />;
  }
};

const GetEntityControls = (
  entity: IGenericEntity & Partial<INamedEntity> & Partial<IUniqueEntity>,
  entityTypeId: GenericEntityConstantsEntities,
  commands: any,
  btnStyle: string = "btn btn-ghost-primary btn-sm",
  showLabel: boolean = false,
  onClick?: Function
) => {
  const { entityConstants } = genericEntityConstants[entityTypeId];
  switch (entityTypeId) {
    default:
      return (
        <div className="flex align-center gap-5">
          <button
            className={btnStyle}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              commands.current.commands.insertEntityMention({
                entityId: entity.id,
                entityUuid: entity.uid,
                entityTypeId: entityTypeId as GenericEntityConstantsEntities,
              });
              onClick && onClick();
            }}
            data-toggle="tooltip"
            title={`Insert ${entityConstants.entitySingular} link at last cursor position`}
          >
            <LucideIcon name="at-sign" />
            {showLabel && <span> Insert link</span>}
          </button>
          <button
            className={btnStyle}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              switch (entityTypeId) {
                case "notebookTemplates":
                  commands.current.insertTemplate(entity);
                  break;
                default:
                  commands.current.commands.insertEntity({
                    entityId: entity.id,
                    entityUuid: entity.uid,
                    entityTypeId: entityTypeId,
                    additionalSettings:
                      entityTypeId === "datasets" || entityTypeId === "attachments"
                        ? ({ showViewer: true } as DatasetAdditionalSettings)
                        : undefined,
                  });
                  break;
              }
              onClick && onClick();
            }}
            data-toggle="tooltip"
            title={`Insert ${entityConstants.entitySingular} at last cursor position`}
          >
            <LucideIcon name="chevron-right" />
            {showLabel && <span> Insert {entityConstants.entitySingular}</span>}
          </button>
        </div>
      );
  }
};

const GetEntityPreview = (entity: IGenericEntity, entityTypeId: GenericEntityConstantsEntities) => {
  switch (entityTypeId) {
    case "datasets":
      const dataset = entity as Dataset;
      return <ViewerModal id={dataset.id} />;
    case "notebookEntries":
      const entry = entity as LabNotebookEntry;
      return <LabNotebookEntryViewOnly entry={entry} printMode />;
    case "notebookExperiments":
      const experiment = entity as LabNotebookExperiment;
      return (
        <div style={{ minWidth: "50vw", padding: "20px" }}>
          <LabNotebookExperimentDetailContentTable
            entity={experiment}
            entityConstants={labNotebookExperimentsConstants}
            fieldLabels={labNotebookExperimentFieldLabels}
          />
        </div>
      );
    case "notebooks":
      const labNotebook = entity as LabNotebook;
      return (
        <div style={{ minWidth: "50vw", padding: "20px" }}>
          <LabNotebookDetailContentTable labNotebook={labNotebook} />
        </div>
      );
    case "attachments":
      const attachment = entity as Dataset;
      return <ViewerModal id={attachment.id} />;
    case "inventoryItems":
      const inventory = entity as InventoryItem;
      return (
        <div style={{ minWidth: "50vw", padding: "20px" }}>
          <InventoryItemsDetailTable
            entity={inventory}
            entityConstants={inventoryItemsConstants}
            fieldLabels={InventoryItemFieldLabels}
            showHierarchy
          />
        </div>
      );
    case "persons":
      const person = entity as Person;
      return (
        <div style={{ minWidth: "50vw", padding: "20px" }}>
          <PersonDetailTable entity={person} entityConstants={personsConstants} fieldLabels={PersonFieldLabels} />
        </div>
      );
    case "projects":
      const project = entity as Project;
      return (
        <div style={{ minWidth: "50vw", padding: "20px" }}>
          <ProjectsDetailTable entity={project} entityConstants={projectsConstants} fieldLabels={ProjectFieldLabels} />
        </div>
      );
    case "samples":
      const sample = entity as Sample;
      return (
        <div style={{ minWidth: "50vw", padding: "20px" }}>
          <SampleDetailTable entity={sample} entityConstants={samplesConstants} fieldLabels={SampleFieldLabels} />
        </div>
      );
    case "notebookTemplates":
      const template = entity as LabNotebookTemplate;
      return (
        <div
          style={{
            width: "100%",
            minWidth: "210mm",
            height: "100%",
            maxHeight: "90vh",
            maxWidth: "210mm",
            backgroundColor: "var(--white)",
            padding: "40px",
            border: "1px solid var(--gray-300)",
            boxShadow: "1px 1px 1px 1px solid var(--gray-200)",
            overflow: "auto",
          }}
        >
          <TextEditor editable={false} save={() => {}} initialContent={template.content} />
        </div>
      );

    default:
      return <>{"-"}</>;
  }
};

const GetEntityFilters = (
  entityTypeId: GenericEntityConstantsEntities,
  sidebarFilters: any | undefined,
  dispatchTabStore: ((action: UseTabActions<any, any, any>) => Promise<void>) | undefined
) => {
  switch (entityTypeId) {
    case "datasets":
      return <DatasetsFilterbar initialValues={sidebarFilters} dispatchTabStore={dispatchTabStore} />;
    case "notebookEntries":
      return <LabNotebookEntriesFilterBar initialValues={sidebarFilters} dispatchTabStore={dispatchTabStore} />;
    case "notebookExperiments":
      return <LabNotebookExperimentFilterBar initialValues={sidebarFilters} dispatchTabStore={dispatchTabStore} />;
    case "notebooks":
      return <LabNotebookFilterBar initialValues={sidebarFilters} dispatchTabStore={dispatchTabStore} />;
    case "attachments":
      return <AttachmentsFilterbar initialValues={sidebarFilters} dispatchTabStore={dispatchTabStore} />;
    case "inventoryItems":
      return <InventoryItemsFilterBar initialValues={sidebarFilters} dispatchTabStore={dispatchTabStore} />;
    case "persons":
      return <PersonFilterBar initialValues={sidebarFilters} dispatchTabStore={dispatchTabStore} />;
    case "projects":
      return <ProjectFilterBar initialValues={sidebarFilters} dispatchTabStore={dispatchTabStore} />;
    case "samples":
      return <SampleFilterBar initialValues={sidebarFilters} dispatchTabStore={dispatchTabStore} />;
    case "notebookTemplates":
      return <LabNotebookTemplateFilterBar initialValues={sidebarFilters} dispatchTabStore={dispatchTabStore} />;

    default:
      return null;
  }
};

const GetEntityFilterIndicator = (entityTypeId: GenericEntityConstantsEntities) => {
  switch (entityTypeId) {
    case "datasets":
      return {
        includeParsingState: () => false,
        includeSoftDeleted: (value: boolean) => !!value,
        isSoftDeleted: () => false,
        participatedPersonIds: () => false,
        isClaimed: () => false,
        includeUnclaimed: () => false,
        isViewableEntity: () => false,
        includeViewableEntities: () => false,
      };
    case "notebookEntries":
      return { includeSoftDeleted: (value: boolean) => !!value, isSoftDeleted: (value: boolean) => !!value };
    case "notebookExperiments":
      return { includeSoftDeleted: (value: boolean) => !!value, isSoftDeleted: (value: boolean) => !!value };
    case "notebooks":
      return { includeSoftDeleted: (value: boolean) => !!value, isSoftDeleted: (value: boolean) => !!value };
    case "attachments":
      return {
        includeParsingState: () => false,
        includeSoftDeleted: (value: boolean) => !!value,
        isSoftDeleted: () => false,
        participatedPersonIds: () => false,
        isClaimed: () => false,
        includeUnclaimed: () => false,
        isViewableEntity: () => false,
        includeViewableEntities: () => false,
      };
    case "inventoryItems":
      return {};
    case "persons":
      return {
        includeSoftDeleted: (value: boolean) => !!value,
        isSoftDeleted: (value: boolean) => !!value,
        includeSystemUsers: (value: boolean) => !!value,
        hasAccount: (value: boolean) => !!value,
        isAccountEnabled: (value: boolean) => !!value,
      };
    case "projects":
      return {};
    case "samples":
      return {
        includeSoftDeleted: (value: boolean) => !!value,
        isSoftDeleted: () => false,
        excludeDiscarded: (value: boolean) => !!value,
        participatedPersonIds: () => false,
      };
    case "notebookTemplates":
      return { includeSoftDeleted: (value: boolean) => !!value, isSoftDeleted: (value: boolean) => !!value };

    default:
      return {};
  }
};

export const TextEditorSidebarTile = ({ remirrorContextRef }: Props) => {
  const [searchValue, setSearchValue] = useState("");
  const debouncedSearchValue = useDebouncedValue(searchValue, 500);

  const [target, setTarget] = useState<GenericEntityConstantsEntities>("datasets");
  const { entityConstants } = genericEntityConstants[target];
  const [showPreview, setShowPreview] = useState<boolean>(false);
  const [lastPreview, setLastPreview] = useState<IGenericEntity | undefined>();

  const handleRowClick = useCallback((current_element: IGenericEntity) => {
    setShowPreview(true);
    setLastPreview(current_element);
  }, []);

  const dragStartHandler = useCallback(
    (e: any, entity: IGenericEntity & Partial<IUniqueEntity>) => {
      if (!entity) {
        return;
      }
      const entityAttrs: EntityAttributes = {
        entityId: entity.id,
        entityUuid: entity.uid,
        entityTypeId: target,
      };
      const defaultKey = "entityReferenceData";
      switch (target) {
        case "attachments":
        case "datasets":
          e.dataTransfer.setData(
            defaultKey,
            JSON.stringify({ ...entityAttrs, additionalSettings: { showViewer: true } as DatasetAdditionalSettings })
          );
          break;
        case "notebookTemplates":
          e.dataTransfer.setData("templateData", JSON.stringify(entity));
          break;

        default:
          e.dataTransfer.setData(defaultKey, JSON.stringify(entityAttrs));
          break;
      }
    },
    [target]
  );

  useEffect(() => {
    setSearchValue("");
    setShowPreview(false);
    setLastPreview(undefined);
  }, [target]);

  const [showFilters, setShowFilters] = useState<boolean>(false);
  const [count, setCount] = useState<number>(0);

  const {
    filters: tabStoreFilters,
    sidebarFilters,
    dispatchTabStore,
  } = useTabStore<any, any, any>({
    resource: entityConstants.resource,
    keyOverride: `text-editor-${entityConstants.entitySingular}-sidebar`,
  });

  const filterComponent = useMemo(() => {
    return GetEntityFilters(target, sidebarFilters, dispatchTabStore);
  }, [dispatchTabStore, sidebarFilters, target]);

  return (
    <>
      <div
        style={{
          display: "grid",
          gridTemplateRows: "max-content 1fr",
          overflow: "auto",
          gap: "5px",
          backgroundColor: "var(--white)",
          border: "1px solid var(--gray-200)",
          borderRadius: "5px",
          width: "100%",
          height: "calc(100vh - 200px)",
        }}
      >
        <div
          className="flex align-center gap-5"
          style={{
            paddingBottom: "10px",
            borderBottom: "1px solid var(--gray-200)",
            width: "100%",
            justifyContent: "space-between",
            padding: "10px 5px",
            backgroundColor: "var(--gray-50)",
          }}
        >
          <div
            className="flex row-nowrap align-center gap-5"
            style={{ fontSize: "18px", height: "100%", fontWeight: 800, padding: "5px 10px" }}
          >
            <div className="badge">{count || 0}</div>
          </div>
          <div style={{ width: "100%", minWidth: "200px", fontWeight: 500, fontSize: 14 }}>
            <Select
              value={{ label: toUppercase(entityConstants.entityPlural), id: target, value: target }}
              onChange={(value) => value && setTarget(value.value)}
              options={AddableContentOptions.map((option) => ({
                label: toUppercase(genericEntityConstants[option].entityConstants.entityPlural),
                value: option,
                id: option,
              }))}
              isSearchable
              closeMenuOnSelect
            />
          </div>
          <div style={{ width: "100%", minWidth: "120px" }}>
            <SearchInput searchValue={searchValue} setSearchValue={setSearchValue} minimized={false} />
          </div>
          <button onClick={() => setShowFilters((prevState) => !prevState)} className="btn btn-ghost-secondary btn-xs">
            <EntityFilterIndicator<any, any>
              filters={tabStoreFilters}
              excludeFilters={GetEntityFilterIndicator(target)}
              showAlways
            />
          </button>
        </div>

        <div
          style={{
            display: "grid",
            gridTemplateRows: showFilters ? "max-content max-content 1fr" : "max-content 1fr",
            overflow: "auto",
            gap: "5px",
            backgroundColor: "var(--white)",
            padding: "0 5px",
            paddingBottom: "5px",
          }}
        >
          {showFilters && (
            <div style={{ border: "1px solid var(--gray-300)", borderRadius: "5px", overflow: "hidden" }}>
              <h4
                style={{
                  borderBottom: "1px solid var(--gray-300)",
                  padding: "2px 10px",
                  backgroundColor: "var(--gray-50)",
                }}
              >
                <LucideIcon name="filter" /> Filters
              </h4>
              <div
                style={{
                  height: "25vh",
                  minHeight: "300px",
                  maxHeight: "500px",
                  overflow: "auto",
                  width: "100%",
                  justifyContent: "center",
                  display: "flex",
                  padding: "5px",
                }}
              >
                {filterComponent || (
                  <Alert type="light" message="No filters available" fit centered style={{ margin: 0, padding: 0 }} />
                )}
              </div>
            </div>
          )}

          <Alert
            type="light"
            message="Drag & drop content into the editor..."
            fit
            centered
            style={{ margin: 0, padding: 0 }}
          />

          <div
            style={{
              width: "100%",
              height: "100%",
              minHeight: "200px",
              overflow: "hidden",
            }}
          >
            <EntityList
              pageSize={50}
              rowHeight={59}
              countCallback={setCount}
              rowRenderer={(current_element: IGenericEntity, index: number) => (
                <div
                  style={{ padding: "0", paddingBottom: "5px" }}
                  key={index}
                  draggable
                  onDragStart={(e: any) => dragStartHandler(e, current_element)}
                >
                  <ContentRow
                    key={index}
                    icon={GetEntityIcon(target)}
                    label={GetEntityLabel(current_element, target)}
                    date={GetEntityDate(current_element, target)}
                    id={<>ID: {current_element.id}</>}
                    info={GetEntityInfo(current_element, target)}
                    controls={GetEntityControls(current_element, target, remirrorContextRef)}
                    onClick={() => handleRowClick(current_element)}
                    currentIndicator={
                      <>
                        <div className="flex align-center gap-5">
                          {lastPreview?.id === current_element.id ? (
                            <div data-toggle="tooltip" title="Last previewed">
                              <label className={"label label-soft-primary"}>
                                <LucideIcon name="eye" />
                              </label>
                            </div>
                          ) : (
                            ""
                          )}
                          {(current_element as any).isDeleted && (
                            <div className="label label-soft-warning">trashed</div>
                          )}
                        </div>
                      </>
                    }
                    style={{ cursor: "grab", border: "1px dashed var(--gray-300)" }}
                  />
                </div>
              )}
              entityConstant={genericEntityConstants[target].entityConstants}
              filters={{
                ...tabStoreFilters,
                ...(debouncedSearchValue && { searchTerm: debouncedSearchValue }),
                ...GetEntityFilter(target),
              }}
            />
          </div>
        </div>
      </div>

      <Modal
        isOpen={showPreview && !!lastPreview}
        onClose={() => {
          setSearchValue("");
          setShowPreview(false);
        }}
        controls={
          lastPreview &&
          GetEntityControls(lastPreview, target, remirrorContextRef, "btn btn-primary btn-sm", true, () => {
            setSearchValue("");
            setShowPreview(false);
          })
        }
      >
        {(lastPreview && GetEntityPreview(lastPreview, target)) || (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              margin: "20px",
              marginTop: "40px",
            }}
          >
            <Alert message={"No preview available!"} fit centered type="light" />
          </div>
        )}
      </Modal>
    </>
  );
};
