import {
  ApplySchemaAttributes,
  command,
  CommandFunction,
  DOMCompatibleAttributes,
  extension,
  ExtensionTag,
  findNodeAtPosition,
  findSelectedNodeOfType,
  getTextSelection,
  isEqual,
  NodeExtension,
  NodeExtensionSpec,
  NodeSpecOverride,
  NodeType,
  ResolvedPos,
  StaticKeyList,
  HandlerKeyList,
  CustomHandlerKeyList,
  isElementDomNode,
  FromToProps,
} from "@remirror/core";
import styles from "./EntityMentionExtension.module.css";
import {
  EntityMentionAttributes,
  EntityMentionOptions,
  insertEntityMentionOptions,
  removeEntityMentionOptions,
} from "./entityMention-types";
import { ComponentType } from "react";
import { NodeViewComponentProps } from "@remirror/react";
import { EntityMention } from "./EntityMention";
import { genericEntityConstants, GenericEntityConstantsEntities } from "../../../../../api/GenericConstants";
import { Suggester } from "@remirror/pm/suggest";

interface CustomMatcher {
  entityTypeId: GenericEntityConstantsEntities;
  char: RegExp;
  matchOffset: number;
  supportedCharacters: RegExp;
  mentionClassName?: string;
}

export const MATCHERS: CustomMatcher[] = [
  {
    entityTypeId: "datasets",
    char: /@dataset(s)?(:)?/i,
    matchOffset: 0,
    supportedCharacters: /\S+/i,
    // mentionClassName: styles.datasetMention,
  },
  {
    entityTypeId: "inventoryItems",
    char: /@inventor(y|ies)(:)?/i,
    matchOffset: 0,
    supportedCharacters: /\S+/i,
    // mentionClassName: styles.inventoryMention,
  },
  {
    entityTypeId: "notebookEntries",
    char: /@entr(y|ies)(:)?/i,
    matchOffset: 0,
    supportedCharacters: /\S+/i,
    // mentionClassName: styles.entryMention,
  },
  {
    entityTypeId: "notebookExperiments",
    char: /@experiment(s)?(:)?/i,
    matchOffset: 0,
    supportedCharacters: /\S+/i,
    // mentionClassName: styles.experimentMention,
  },
  {
    entityTypeId: "attachments",
    char: /@attachment(s)?(:)?/i,
    matchOffset: 0,
    supportedCharacters: /\S+/i,
    // mentionClassName: styles.attachmentMention,
  },
  {
    entityTypeId: "notebooks",
    char: /@notebook(s)?(:)?/i,
    matchOffset: 0,
    supportedCharacters: /\S+/i,
    // mentionClassName: styles.notebookMentions,
  },
  {
    entityTypeId: "persons",
    char: /@person(s)?(:)?/i,
    matchOffset: 0,
    supportedCharacters: /\S+/i,
    // mentionClassName: styles.personMention,
  },
  {
    entityTypeId: "projects",
    char: /@project(s)?(:)?/i,
    matchOffset: 0,
    supportedCharacters: /\S+/i,
    // mentionClassName: styles.projectMention,
  },
  {
    entityTypeId: "samples",
    char: /@sample(s)?(:)?/i,
    matchOffset: 0,
    supportedCharacters: /\S+/i,
    // mentionClassName: styles.sampleMention,
  },
  {
    entityTypeId: "notebookTemplates",
    char: /@template(s)?(:)?/i,
    matchOffset: 0,
    supportedCharacters: /\S+/i,
    // mentionClassName: styles.templateMention,
  },
];

@extension<EntityMentionOptions>({
  defaultOptions: {
    openViewer: () => {},
    ignoreIntersectionObserver: false,
  },
  staticKeys: ["openViewer"] as StaticKeyList<EntityMentionOptions>,
  handlerKeys: ["onChange"] as HandlerKeyList<EntityMentionOptions>,
  customHandlerKeys: [] as CustomHandlerKeyList<EntityMentionOptions>,
})
export class EntityMentionExtension extends NodeExtension<EntityMentionOptions> {
  get name() {
    return "entityMention" as const;
  }

  ReactComponent: ComponentType<NodeViewComponentProps> = ({ node, getPosition, updateAttributes }) => {
    return EntityMention({ attrs: node.attrs, getPosition, updateAttributes, options: this.options });
  };

  readonly tags = [ExtensionTag.InlineNode, ExtensionTag.FormattingNode, ExtensionTag.Behavior];

  createNodeSpec(extra: ApplySchemaAttributes, override: NodeSpecOverride): NodeExtensionSpec {
    const dataAttributeBlockId = "id";
    const dataAttributeEntityId = "data-entityId";
    const dataAttributeEntityUuid = "data-entityUuid";
    const dataAttributeEntityTypeId = "data-entityTypeId";
    const dataAttributeVersion = "data-version";
    return {
      draggable: true,
      selectable: true,
      ...override,
      atom: true,
      inline: true,
      attrs: {
        ...extra.defaults(),
        id: { default: null },
        entityId: { default: null },
        entityUuid: { default: null },
        entityTypeId: { default: null },
        version: { default: 0 },
      },
      toDOM: (node) => {
        const attrs: DOMCompatibleAttributes = {
          [dataAttributeBlockId]: node.attrs.id,
          [dataAttributeEntityId]: node.attrs.entityId,
          [dataAttributeEntityUuid]: node.attrs.entityUuid,
          [dataAttributeEntityTypeId]: node.attrs.entityTypeId,
          [dataAttributeVersion]: node.attrs.version ?? 0,
          class: styles.entityMention,
        };

        const matcher = MATCHERS.find((matcher) => matcher.entityTypeId === attrs.entityTypeId);

        return ["span", { ...extra.dom(node), ...attrs, class: matcher?.mentionClassName ?? attrs.class }];
      },
      parseDOM: [
        ...MATCHERS.map((matcher) => ({
          tag: `span[${dataAttributeEntityId}]`,
          getAttrs: (node: string | Node) => {
            if (!isElementDomNode(node)) {
              return false;
            }

            const id = node.getAttribute(dataAttributeBlockId);
            const entityIdString = node.getAttribute(dataAttributeEntityId);
            const entityId = entityIdString ? parseInt(entityIdString) : null;
            const entityUuid = node.getAttribute(dataAttributeEntityUuid);
            const entityTypeId = node.getAttribute(dataAttributeEntityTypeId);

            const versionString = node.getAttribute(dataAttributeVersion);
            const version = versionString ? parseInt(versionString) : 0;

            if (!entityId || !entityTypeId || !genericEntityConstants[entityTypeId as GenericEntityConstantsEntities])
              return false;

            return { ...extra.parse(node), id, entityId, entityUuid, entityTypeId, version };
          },
        })),
        ...(override.parseDOM ?? []),
      ],
    };
  }

  @command(insertEntityMentionOptions)
  insertEntityMention(attributes: EntityMentionAttributes, range?: FromToProps): CommandFunction {
    return ({ tr, dispatch }) => {
      const { from, to } = range ?? getTextSelection(tr.selection, tr.doc);
      const node = this.type.create(attributes);

      dispatch?.(tr.replaceRangeWith(from, to, node));

      const { focus } = this.store.commands;
      focus({ from: from + 1, to: from + 1 });
      return true;
    };
  }

  @command(removeEntityMentionOptions)
  deleteEntityMention(): CommandFunction {
    return ({ tr, dispatch }) => {
      const { from } = getTextSelection(tr.selection, tr.doc);

      dispatch?.(tr.deleteSelection());

      const { focus } = this.store.commands;
      focus({ from, to: from });

      return true;
    };
  }

  @command(insertEntityMentionOptions)
  updateEntityMention(attributes: EntityMentionAttributes, pos?: ResolvedPos): CommandFunction {
    return updateNodeAttributes(this.type)(attributes, pos);
  }

  createSuggesters(): Suggester[] {
    return MATCHERS.map<Suggester>((matcher) => ({
      ...this.options,
      ...matcher,
      name: matcher.entityTypeId,
      onChange: (props) => {
        const { insertEntityMention } = this.store.commands;
        const { range } = props;

        function command(attrs: EntityMentionAttributes) {
          insertEntityMention(attrs, range);
        }

        this.options.onChange(props, command);
      },
    }));
  }
}

declare global {
  namespace Remirror {
    interface AllExtensions {
      entityMention: EntityMentionExtension;
    }
  }
}

// Helper functions
// Update the attributes of a node.
function updateNodeAttributes(type: NodeType) {
  return (attributes: EntityMentionAttributes, pos?: ResolvedPos): CommandFunction =>
    ({ state: { tr, selection }, dispatch }) => {
      const node = !pos ? findSelectedNodeOfType({ types: type, selection }) : findNodeAtPosition(pos);

      if (!node || isEqual(attributes, node.node.attrs)) {
        // Do nothing since the attrs are the same
        return false;
      }

      tr.setNodeMarkup(pos?.pos || node.pos, type, { ...node.node.attrs, ...attributes });

      if (dispatch) {
        dispatch(tr);
      }

      return true;
    };
}
