import { useCallback, useEffect, useState } from "react";

import { getTrackDataLevel } from "../SpectrumViewer/TrackDataManager";
import { TrackData, TrackPdf, viewerPropsType } from "../ViewerLayout/ViewerLayoutTypes";
import { Resizeable } from "../ViewerUIElements/Resizeable";
import { PDFViewer } from "./PDFViewer";
import styles from "./PDFViewer.module.css";
import { PDFViewerNavigation } from "./PDFViewerNavigation";

export const PDFComponent = ({ api, tracks, datatracks, changeTrackState }: viewerPropsType) => {
  const [pdf, setPdf] = useState<Uint8Array>();
  const [currentTrack, setCurrentTrack] = useState<TrackPdf>();
  const [fitScale, setFitScale] = useState<number>();
  const [scale, setScale] = useState<number>(1);
  const [page, setPage] = useState<number>(1);
  const [pageNumber, setPageNumber] = useState<number>(0);

  useEffect(() => {
    // const trackType = Object.fromEntries(selectedTracks.map((id) => [tracks[id].type, true]));
    const trackList = Object.values(tracks)
      .filter((track) => track.type === "pdf" && track.settings.visible)
      .sort((a, b) => a.index - b.index);
    if (trackList.length < 1) return;

    const track = trackList[0] as TrackPdf;
    if (currentTrack?.id !== track.id) {
      setCurrentTrack(track);

      getTrackDataLevel(
        api,
        0,
        [track],
        datatracks,
        (data: TrackData[]) => {
          if (data.length > 0 && data[0].type === "binary" && data[0].data.binary?.[0].data)
            setPdf(data[0].data.binary[0].data);
        },
        changeTrackState ?? (() => null)
      );
    }
  }, [tracks]);

  const changeFitScale = useCallback((fitScale: number) => {
    setFitScale(fitScale);
    setScale(fitScale);
  }, []);

  const onExport = useCallback(() => {
    if (pdf) {
      const name = (currentTrack?.name ?? "file") + ".pdf";
      const mimeType = "application/pdf";
      var blob = new Blob([pdf], { type: mimeType });
      const element = document.createElement("a");
      element.href = URL.createObjectURL(blob);
      element.download = name;
      document.body.appendChild(element); // Required for this to work in FireFox
      element.click();
    }
  }, [pdf, currentTrack]);

  // // console.log("given size", size);

  return (
    <div className={styles.viewer}>
      <PDFViewerNavigation
        scale={scale}
        setScale={setScale}
        pageNumber={pageNumber}
        page={page}
        setPage={setPage}
        fitScale={fitScale}
        onExport={onExport}
      />
      <Resizeable>
        <PDFViewer
          pdf={pdf}
          // size={size}
          scale={scale}
          setPageNumber={setPageNumber}
          page={page}
          fitScale={fitScale}
          setFitScale={changeFitScale}
        />
      </Resizeable>
    </div>
  );
};
