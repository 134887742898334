import { StringIndexedDict } from "../../../../api/GenericTypes";
import {
  GenericEntityVirtualizedEntitySelectFormFieldProps,
  GenericVirtualizedEntitySelectFormFieldControllerWrapper,
} from "../../../formfields/GenericVirtualizedSelectFormField/GenericVirtualizedEntitySelectFormFieldControllerWrapper";
// import { QuickAddWrapper } from "../../QuickAdd/common/QuickAddWrapper";

import {
  LabNotebookExperiment,
  LabNotebookExperimentFilters,
  LabNotebookExperimentSortingOptionsConsts,
  LabNotebookExperimentSuggestions,
  labNotebookExperimentsConstants,
} from "../../../../ELN/types/LabNotebookExperiment";
import { useContext } from "react";
import { SessionContext } from "../../../contexts/SessionContext";
import { QuickAddWrapper } from "../../QuickAdd/common/QuickAddWrapper";
import { QuickAddLabNotebookExperimentForm } from "../../QuickAdd/forms/QuickAddLabNotebookExperiment";

export const LabNotebookExperimentsVirtualizedSelectForm = <Entity extends StringIndexedDict>(
  props: GenericEntityVirtualizedEntitySelectFormFieldProps<
    Entity,
    LabNotebookExperimentSuggestions,
    LabNotebookExperimentFilters
  >
) => {
  const { session } = useContext(SessionContext);
  return (
    <GenericVirtualizedEntitySelectFormFieldControllerWrapper<
      Entity,
      LabNotebookExperimentSuggestions,
      LabNotebookExperimentFilters
    >
      {...props}
      filters={{ orderBy: "MODIFIED_ON_DESC", ...props.filters }}
      //   onRowRenderer={(item) => <></>}
      entityConstants={labNotebookExperimentsConstants}
      orderByOptions={LabNotebookExperimentSortingOptionsConsts}
      allowCreateEntity={session?.permissions.can_create_eln_content && props.allowCreateEntity}
      createModal={({ showModal, setShowModal, onCreate }) => (
        <QuickAddWrapper<LabNotebookExperiment> showModal={showModal} setShowModal={setShowModal} onCreate={onCreate}>
          {(props) => <QuickAddLabNotebookExperimentForm {...props} initialValues={{ status: "RUNNING" }} />}
        </QuickAddWrapper>
      )}
    />
  );
};
