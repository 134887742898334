import { CSSProperties } from "react";
import styles from "./forms.module.css";

interface FormHeaderProps {
  title: React.ReactNode;
  subtitle?: string;
  headerStyle?: CSSProperties;
  titleStyle?: CSSProperties;
}

export const FormHeader = ({ title, subtitle, headerStyle, titleStyle }: FormHeaderProps) => {
  return (
    <div className={styles.header} style={headerStyle}>
      <div className={styles.title} style={titleStyle}>
        <h1>{title}</h1>
        {subtitle ? (
          <h2>
            <label className="label label-soft-secondary" style={{ whiteSpace: "pre-wrap" }}>
              {subtitle}
            </label>
          </h2>
        ) : null}
      </div>
    </div>
  );
};
