import { PipelineConnector } from "./PipelineConnector";
import { trackListType, deepCopy, initType, SerializedPipelineConnector } from "./PipelineTypes";

export class CopyPipelinConnector extends PipelineConnector {
  name!: string;
  id!: string;
  previous?: string;
  next?: string;
  inputNumber!: number;
  outputNumber!: number;
  mapping!: Record<number, number>;

  constructor(init?: PipelineConnector) {
    super(init);

    initType(this, init);

    // console.log("COPY", init);
  }

  connect(tracks: trackListType): trackListType {
    return super.connect(tracks).map((t) => deepCopy(t));
  }

  serialize(): SerializedPipelineConnector {
    const result: SerializedPipelineConnector = super.serialize();

    result.copy = true;

    return result;
  }
}
