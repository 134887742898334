import { useEffect, useState } from "react";

import { LucideIcon } from "../../common/icon/LucideIcon";
import { ParameterList } from "../../ViewerLayout/ViewerLayoutTypes";
import { ColorRectList } from "../../ViewerUIElements/ColorRect";
import { TreeElement } from "./TreeElement";
import styles from "./TreeViewer.module.css";
import { SearchRequestType } from "./TreeViewerTypes";

export const TreeListNode = ({ children, search }: { children: ParameterList; search: SearchRequestType }) => {
  const { name, colors, active, isVisible, border, content } = children;

  const [expanded, setExpanded] = useState<boolean>(active === undefined ? true : active);
  const [listContent, setListContent] = useState<(typeof TreeElement)[]>([]);
  const [contentStyle, setContentStyle] = useState<string>();
  const [searching, setSearching] = useState<boolean>((search?.keyText || search?.valueText) !== undefined);

  useEffect(() => {
    setSearching((search?.keyText || search?.valueText) !== undefined);
  }, [search?.keyText, search?.valueText]);

  useEffect(() => {
    setContentStyle(border === undefined ? styles.listContent + " " + styles.listBorder : styles.listContent);
  }, [border]);

  useEffect(() => {
    if (content) {
      // console.log("TreeListNode", content.length);
      setListContent(
        content.map((child: any, i: number): any => (
          <TreeElement search={search} key={i}>
            {child}
          </TreeElement>
        ))
      );
    } else setListContent([]);
  }, [content]);

  return (
    <div className={styles.list} style={{ display: isVisible ? undefined : "none" }}>
      <div
        className={styles.listHeader}
        onClick={() => {
          setExpanded(!expanded);
        }}
      >
        {/* <span
          className={expanded || searching ? "glyphicon glyphicon-chevron-down" : "glyphicon glyphicon-chevron-right"}
          style={{ fontSize: "small" }}
        /> */}
        <LucideIcon
          name={expanded || searching ? "chevron-down" : "chevron-right"}
          style={{ strokeWidth: 3, width: 15 }}
        />
        &nbsp;
        {name}
        {/* TODO: Implement colorBox here */}
        <ColorRectList colors={colors} />
      </div>
      {/* <div className={styles.listContent} style={{ borderLeftStyle: "red + " + hasBorder ? "dotted" : "none" }}>{content}</div> */}
      <div className={contentStyle} style={{ display: expanded || searching ? undefined : "none" }}>
        {expanded || searching ? listContent : null}
      </div>
    </div>
  );
};
