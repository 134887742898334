import {
  ApplySchemaAttributes,
  command,
  CommandFunction,
  DOMCompatibleAttributes,
  extension,
  ExtensionTag,
  findNodeAtPosition,
  findSelectedNodeOfType,
  getTextSelection,
  isEqual,
  NodeExtension,
  NodeExtensionSpec,
  NodeSpecOverride,
  NodeType,
  ResolvedPos,
  isElementDomNode,
  StaticKeyList,
  HandlerKeyList,
  CustomHandlerKeyList,
  FromToProps,
} from "@remirror/core";
import { NodeViewComponentProps } from "@remirror/react";
import { ComponentType } from "react";
import {
  removeEntityOptions,
  EntityAttributes,
  EntityOptions,
  insertEntityOptions,
  EntityWrapperSettings,
  DatasetAdditionalSettings,
} from "./entity-types";
import { IGenericEntity, INamedEntity } from "../../../../../api/GenericTypes";
import { EntityViews } from "./EntityViews";
import { v4 as uuidv4 } from "uuid";
import { showtoast } from "../../../../../common/overlays/Toasts/showtoast";
import { GenericEntityConstantsEntities, genericEntityConstants } from "../../../../../api/GenericConstants";

@extension<EntityOptions<IGenericEntity & INamedEntity>>({
  defaultOptions: {
    onDoubleClick: () => {},
    ignoreIntersectionObserver: false,
    uploadHandler: (file: File, uuid: string) => {
      return new Promise((resolve, reject) => resolve(null as any));
    },
  },
  staticKeys: ["onDoubleClick", "ignoreIntersectionObserver", "uploadHandler"] as StaticKeyList<EntityOptions>,
  handlerKeys: [] as HandlerKeyList<EntityOptions>,
  customHandlerKeys: [] as CustomHandlerKeyList<EntityOptions>,
})
export class EntityExtension extends NodeExtension<EntityOptions> {
  get name() {
    return "entity" as const;
  }

  ReactComponent: ComponentType<NodeViewComponentProps> = ({ node, getPosition, updateAttributes }) => {
    return EntityViews({ attrs: node.attrs, getPosition, updateAttributes, options: this.options });
  };

  readonly tags = [ExtensionTag.BlockNode];

  createNodeSpec(extra: ApplySchemaAttributes, override: NodeSpecOverride): NodeExtensionSpec {
    // define data attributes to write to and from the DOM (will also be used to copy/paste the node)
    const dataAttributeBlockId = "id";
    const dataAttributeEntityId = "data-entityId";
    const dataAttributeEntityUuid = "data-entityUuid";
    const dataAttributeEntityTypeId = "data-entityTypeId";
    const dataAttributeVersion = "data-version";
    const dataAttributeSettings = "data-settings";
    const dataAttributeSettingsAdditionalSettings = "data-additionalSettings";

    const DefaultSettings: EntityWrapperSettings = { collapsible: true, defaultCollapsed: false, showHeader: true };

    return {
      draggable: true,
      selectable: true,
      atom: true,
      ...override,
      attrs: {
        ...extra.defaults(),
        id: { default: null },
        entityId: { default: null },
        entityUuid: { default: null },
        entityTypeId: { default: null },
        version: { default: 0 },
        settings: {
          default: DefaultSettings,
        },
        additionalSettings: { default: {} },
      },
      toDOM: (node) => {
        const attrs: DOMCompatibleAttributes = {
          [dataAttributeBlockId]: node.attrs.id,
          [dataAttributeEntityId]: node.attrs.entityId,
          [dataAttributeEntityUuid]: node.attrs.entityUuid,
          [dataAttributeEntityTypeId]: node.attrs.entityTypeId,
          [dataAttributeVersion]: node.attrs.version ?? 0,
          [dataAttributeSettings]: JSON.stringify(node.attrs.settings ?? DefaultSettings),
          [dataAttributeSettingsAdditionalSettings]: JSON.stringify(node.attrs.additionalSettings ?? {}),
        };
        return ["div", attrs];
      },
      parseDOM: [
        {
          tag: `div[${dataAttributeEntityId}]`,
          getAttrs: (dom) => {
            const node = dom as HTMLAnchorElement;
            const id = node.getAttribute(dataAttributeBlockId);
            const entityIdString = node.getAttribute(dataAttributeEntityId);
            const entityId = entityIdString ? parseInt(entityIdString) : null;
            const entityUuid = node.getAttribute(dataAttributeEntityUuid);
            const entityTypeId = node.getAttribute(dataAttributeEntityTypeId);

            const versionString = node.getAttribute(dataAttributeVersion);
            const version = versionString ? parseInt(versionString) : 0;

            let settings: EntityWrapperSettings = DefaultSettings;
            const settingsAsString = node.getAttribute(dataAttributeSettings);
            try {
              if (!!settingsAsString) settings = JSON.parse(settingsAsString);
            } catch (error) {
              console.error(error);
            }

            let additionalSettings = {};
            const addtionalSettingsAsString = node.getAttribute(dataAttributeSettingsAdditionalSettings);
            try {
              if (!!addtionalSettingsAsString) additionalSettings = JSON.parse(addtionalSettingsAsString);
            } catch (error) {
              console.error(error);
            }

            if (!entityId || !entityTypeId || !genericEntityConstants[entityTypeId as GenericEntityConstantsEntities])
              return false;

            return {
              id,
              entityId,
              entityUuid,
              entityTypeId,
              version,
              settings,
              additionalSettings,
            };
          },
        },
        {
          // workaround for copy/paste of html images; this uploads the image to the server and replaces the image with a entity node
          tag: "img[src]",
          getAttrs: (dom) => {
            if (isElementDomNode(dom)) {
              const imageAttrs = getImageAttributes({ element: dom, parse: extra.parse });

              const node = dom as HTMLAnchorElement;
              const id = node.getAttribute(dataAttributeBlockId);

              if (!imageAttrs.src || imageAttrs.src?.startsWith("file:///")) {
                return false;
              }

              const uuid = uuidv4();
              const img = new Image();
              img.src = imageAttrs.src;
              img.crossOrigin = "anonymous";
              // img.onload cannot be async, nor getAttrs!
              img.onload = () => {
                DataURLToFile(
                  getDataURL(img),
                  imageAttrs.title.replace(/[^A-Z0-9]+/gi, "_") ||
                    imageAttrs.fileName?.replace(/[^A-Z0-9]+/gi, "_") ||
                    "attachment_" + new Date().toISOString() + ".png",
                  "image/png"
                )
                  .then(async (file) => {
                    if (file) this.options.uploadHandler && (await this.options.uploadHandler(file, uuid));
                    else showtoast("error", "Error uploading attachment");
                  })
                  .catch(() => showtoast("error", "Error uploading attachment"));
              };

              return {
                id,
                entityTypeId: "attachments",
                version: 0,
                settings: { showViewer: true } as EntityWrapperSettings,
                additionalSettings: { overrideId: uuid } as DatasetAdditionalSettings,
              };
            }

            return false;
          },
        },
      ],
    };
  }

  @command(insertEntityOptions)
  insertEntity<T = {}>(attributes: EntityAttributes<T>, range?: FromToProps): CommandFunction {
    return ({ tr, dispatch }) => {
      const { from, to } = range ?? getTextSelection(tr.selection, tr.doc);
      const node = this.type.create(attributes);

      dispatch?.(tr.replaceRangeWith(from, to, node));

      const { focus } = this.store.commands;
      focus({ from, to });
      return true;
    };
  }

  @command(removeEntityOptions)
  deleteEntity(): CommandFunction {
    return ({ tr, dispatch }) => {
      const { from } = getTextSelection(tr.selection, tr.doc);

      dispatch?.(tr.deleteSelection());

      const { focus } = this.store.commands;
      focus({ from, to: from });

      return true;
    };
  }

  @command(insertEntityOptions)
  updateEntity(attributes: EntityAttributes, pos?: ResolvedPos): CommandFunction {
    return updateNodeAttributes(this.type)(attributes, pos);
  }
}

declare global {
  namespace Remirror {
    interface AllExtensions {
      entity: EntityExtension;
    }
  }
}

// Helper functions
// Update the attributes of a node.
function updateNodeAttributes(type: NodeType) {
  return (attributes: EntityAttributes, pos?: ResolvedPos): CommandFunction =>
    ({ state: { tr, selection }, dispatch }) => {
      const node = !pos ? findSelectedNodeOfType({ types: type, selection }) : findNodeAtPosition(pos);

      if (!node || isEqual(attributes, node.node.attrs)) {
        // Do nothing since the attrs are the same
        return false;
      }

      tr.setNodeMarkup(pos?.pos || node.pos, type, { ...node.node.attrs, ...attributes });

      if (dispatch) {
        dispatch(tr);
      }

      return true;
    };
}

// Get the width and the height of the image.
function getDimensions(element: HTMLElement) {
  let { width, height } = element.style;
  width = width || element.getAttribute("width") || "";
  height = height || element.getAttribute("height") || "";

  return { width, height };
}

// Retrieve attributes from the dom for the image extension.
function getImageAttributes({ element, parse }: { element: HTMLElement; parse: ApplySchemaAttributes["parse"] }) {
  const { width, height } = getDimensions(element);

  return {
    ...parse(element),
    alt: element.getAttribute("alt") ?? "",
    height: Number.parseInt(height || "0", 10) || null,
    src: element.getAttribute("src") ?? null,
    title: element.getAttribute("title") ?? "",
    width: Number.parseInt(width || "0", 10) || null,
    fileName: element.getAttribute("data-file-name") ?? null,
  };
}

// Get the data URL of an image.
function getDataURL(img: HTMLImageElement) {
  var canvas = document.createElement("canvas");
  canvas.width = img.width;
  canvas.height = img.height;
  var ctx = canvas.getContext("2d");
  ctx!.drawImage(img, 0, 0);
  var dataURL = canvas.toDataURL("image/png");
  return dataURL;
}

async function DataURLToFile(url: string, filename: string, mimeType: string) {
  try {
    const res = await fetch(url, {
      mode: "no-cors",
    });
    const buffer = await res.arrayBuffer();
    return new File([buffer], filename, { type: mimeType });
  } catch (error) {
    console.error(error);
  }
}
