import { useCallback, useEffect, useState } from "react";
import { FieldValues, Path, PathValue, useWatch } from "react-hook-form";
import { ResourceName } from "../../../../main/Routing";
import {
  GenericEntity,
  ICreationRecord,
  IEntityMinimalModel,
  IModificationRecord,
  IPermissionedEntity,
  IRelatedEntity,
  IdTypes,
} from "../../../../api/GenericTypes";
import { GenericEntityFormProps } from "../../EntityForms/common/EntityFormTypes";
import { useEntityApi } from "../../../../api/useEntityApi";

// Deprecated for the legacy react-select forms; Use forms/formsVirtualized instead
interface ConsolodateSuggestionOptionsProps<IdType extends IdTypes> {
  suggestions: IEntityMinimalModel<IdType>[];
  allowQuickAdd?: boolean;
  allowUnassigned?: boolean;
  permission?: boolean;
  entitySingular: string;
}
export const consolidateSuggestionOptions = <IdType extends IdTypes>({
  suggestions,
  allowQuickAdd,
  allowUnassigned,
  permission,
  entitySingular,
}: ConsolodateSuggestionOptionsProps<IdType>) => {
  if (!allowQuickAdd && !allowUnassigned) return suggestions;

  let _suggestions = [...suggestions];
  if (allowUnassigned)
    _suggestions = [{ id: -2, name: "Unassigned" } as IEntityMinimalModel<IdType>].concat(_suggestions);
  if (allowQuickAdd && permission)
    _suggestions = [{ id: -1, name: `Add ${entitySingular ?? "Entity"}` } as IEntityMinimalModel<IdType>].concat(
      _suggestions
    );
  return _suggestions;
};

interface useQuickAddFormProps<Form extends FieldValues> {
  id: GenericEntityFormProps<Form>["id"];
  control: GenericEntityFormProps<Form>["control"];
  setValue: GenericEntityFormProps<Form>["setValue"];
  isMulti: GenericEntityFormProps<Form>["isMulti"];
  allowUnassigned: GenericEntityFormProps<Form>["allowUnassigned"];
  allowQuickAdd: GenericEntityFormProps<Form>["allowQuickAdd"];
}

export const useQuickAddForm = <Form extends FieldValues>(props: useQuickAddFormProps<Form>) => {
  const [showModal, setShowModal] = useState(false);
  const [isMulti, setIsMulti] = useState(props.isMulti ?? false);

  const value = useWatch({ name: props.id as Path<Form>, control: props.control });
  // console.log("VALUE", props.id, value, showModal);

  useEffect(() => {
    if (props.allowQuickAdd || props.allowUnassigned) {
      // Array type
      if (props.isMulti) {
        if (Array.isArray(value)) {
          const entities = value as PathValue<Form, Path<Form> & Path<Form>>[];
          if (entities && entities.map((p) => p.id).some((id) => id === -1)) {
            props.setValue?.(
              props.id as Path<Form>,
              entities.filter((p) => p.id !== -1) as PathValue<Form, Path<Form>>
            );
            setShowModal(true);
          } else if (entities && entities.map((p) => p.id).some((id) => id === -2)) {
            props.setValue?.(
              props.id as Path<Form>,
              entities.filter((p) => p.id === -2)[0] as PathValue<Form, Path<Form>>
            );
            setIsMulti(false);
          } else {
            setIsMulti(props.isMulti);
          }
        } else if (value === null) {
          setIsMulti(props.isMulti);
        }
      } else {
        // Single select type
        if (value && value.id === -1) {
          props.setValue?.(props.id as Path<Form>, null as PathValue<Form, Path<Form>>);
          setShowModal(true);
        }
        // else if (value && value.id === -2) {
        //   props.setValue?.(props.id as Path<Form>, undefined as PathValue<Form, Path<Form>>);
        // }
      }
    }
  }, [value, props]);

  return { isMulti, showModal, setShowModal };
};

// Internal hook for Entities in ./Forms
interface UseQuickAddSubmitProps<T> {
  resource: ResourceName;
  onCreate: (entity: T) => void;
  onCancel: () => void;
}
export const useQuickAddSubmit = <
  T extends GenericEntity &
    Partial<IPermissionedEntity> &
    Partial<ICreationRecord> &
    Partial<IModificationRecord> &
    Partial<IRelatedEntity<{}>>
>({
  resource,
  onCreate,
  onCancel,
}: UseQuickAddSubmitProps<T>) => {
  const { createMutationAsync, isLoadingCreateMutation } = useEntityApi<T>(resource);

  const onSubmit = useCallback(
    async (data: Partial<T>) => {
      await createMutationAsync(
        { body: data },
        {
          onSuccess: (data) => {
            onCreate(data);
          },
          onError: () => {
            onCancel();
          },
        }
      ).catch(() => {});
    },
    [onCancel, onCreate, createMutationAsync]
  );
  return { onSubmit, loading: isLoadingCreateMutation };
};
