import { useCallback, useEffect, useMemo } from "react";
import { SearchInput } from "../../common/forms/SearchInput/SearchInput";
// import { showtoast } from "../../common/overlays/Toasts/showtoast";
import { EntityTable } from "../../common/tables/EntityTable/EntityTable";
import { EntityFilterIndicator } from "../../common/tables/EntityFilterIndicator/EntityFilterIndicator";
import { IEntityPermissions } from "../../api/GenericTypes";
import { useTabStore } from "../../common/tables/Tabs/useTabStore";
import { TableTabsDict } from "../../common/tables/Tabs/TableTabsTypes";
import { Person, PersonFilters, PersonFieldLabels, personsConstants } from "../../api/Person";
import { PersonFilterBar } from "./PersonFilterBar";
import { PersonFilterForm } from "./PersonFilterBar";
import { switchPersonsDefaultSortState, usePersonsTableColumns, usePersonsTableDefaults } from "./PersonsTable";
import { Alert } from "../../common/overlays/Alert/Alert";
import Detail from "../../common/panels/Detail/Detail";
import {
  useGenericVirtualizedTable,
  useGenericVirtualizedTableTabs,
} from "../../common/tables/GenericVirtualizedTable/useGenericVirtualizedTable";

const MaxSelectedItems = 1000;

const defaultFilter: PersonFilters = { orderBy: "NAME_ASC" };

const tabStoreDefaults: TableTabsDict<Person, PersonFilters, PersonFilterForm> = {
  default: {
    tabId: "default",
    type: "fixed",
    label: "All",
    title: "All",
    icon: "house",
    align: "left",
    xPos: 0,
    settings: {
      columnSettings: {},
      columnWidths: {},
      filters: defaultFilter,
      sidebarFilters: {},
    },
    forcedSettings: {
      columnSettings: {},
      columnWidths: {},
      filters: {},
      sidebarFilters: {},
    },
  },
  activeUsers: {
    tabId: "activeUsers",
    type: "fixed",
    label: "Active accounts",
    title: "Active accounts",
    icon: "user-check",
    align: "left",
    xPos: 1,
    settings: {
      columnSettings: {},
      columnWidths: {},
      filters: defaultFilter,
      sidebarFilters: {},
    },
    forcedSettings: {
      columnSettings: {},
      columnWidths: {},
      filters: { hasAccount: true, isAccountEnabled: true },
      sidebarFilters: {},
    },
  },
  disabledUsers: {
    tabId: "disabledUsers",
    type: "fixed",
    label: "Inactive accounts",
    title: "Inactive accounts",
    icon: "user-x",
    align: "left",
    xPos: 2,
    settings: {
      columnSettings: {},
      columnWidths: {},
      filters: defaultFilter,
      sidebarFilters: {},
    },
    forcedSettings: {
      columnSettings: {},
      columnWidths: {},
      filters: { hasAccount: true, isAccountEnabled: false },
      sidebarFilters: {},
    },
  },
  trash: {
    tabId: "trash",
    type: "fixed",
    label: "Trash",
    title: "Trash",
    icon: "trash",
    align: "left",
    xPos: 3,
    settings: {
      columnSettings: {},
      columnWidths: {},
      filters: defaultFilter,
      sidebarFilters: {},
    },
    forcedSettings: {
      columnSettings: {},
      columnWidths: {},
      filters: { isSoftDeleted: true },
      sidebarFilters: {},
    },
  },
};

export const PersonsSelectWidget = ({
  additionalFilters,
  setResultsCountCallback,
  setSelectionCallback,
  setSelectionPermissionsCallback,
}: {
  additionalFilters: PersonFilters;
  setResultsCountCallback?: (count?: number) => void;
  setSelectionCallback?: (selection?: Set<Person["id"]>) => void;
  setSelectionPermissionsCallback?: (permissions?: IEntityPermissions) => void;
}) => {
  const { defaults: personColumnSettingsDefaults } = usePersonsTableDefaults({ fieldLabels: PersonFieldLabels });

  const { selection, onSelectionChange } = useGenericVirtualizedTable<Person>();

  const { filters, forcedFilters, sidebarFilters, columnSetting, columnWidths, dispatchTabStore, tabsLoading } =
    useTabStore<Person, PersonFilters, PersonFilterForm>({
      resource: personsConstants.resource,
      defaults: tabStoreDefaults,
    });

  const { functionRef, sort, setSort, searchValue, setSearchValue } = useGenericVirtualizedTableTabs({
    tabsLoading,
    filters,
    switchSortState: switchPersonsDefaultSortState,
    dispatchTabStore,
  });

  const { columns, columnSettings } = usePersonsTableColumns({
    fieldLabels: PersonFieldLabels,
    entityConstants: personsConstants,
    defaults: personColumnSettingsDefaults,
    filters,
    sort,
    setSort,
  });

  const onCountChange = useCallback(
    (count: number | undefined) => {
      setResultsCountCallback && setResultsCountCallback(count);
    },
    [setResultsCountCallback]
  );

  useEffect(() => {
    setSelectionCallback && setSelectionCallback(selection);
  }, [selection, setSelectionCallback]);

  const onSelectionPermissions = useCallback(
    (permissions?: IEntityPermissions) => {
      setSelectionPermissionsCallback && setSelectionPermissionsCallback(permissions);
    },
    [setSelectionPermissionsCallback]
  );

  const memoizedFilters = useMemo(() => {
    return { ...filters, ...forcedFilters, includeRelations: true, ...additionalFilters };
  }, [additionalFilters, filters, forcedFilters]);

  return (
    <Detail
      main={
        <EntityTable>
          <EntityTable.Controls
            style={{
              borderTop: "0px",
              borderRadius: "0px",
              display: "block",
            }}
          >
            <div className="flex align-center gap-5" style={{ width: "100%" }}>
              <h4 style={{ margin: 0, whiteSpace: "nowrap", padding: "0 10px" }}>Select accounts...</h4>
              <SearchInput searchValue={searchValue} setSearchValue={setSearchValue} placeholder="Search" />

              <div
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
              >
                <EntityFilterIndicator<Person, PersonFilters>
                  filters={filters}
                  excludeFilters={{
                    includeSoftDeleted: (value) => !!value,
                    isSoftDeleted: (value) => !!value,
                    hasAccount: () => false,
                    excludeIds: () => false,
                    isAccountEnabled: () => false,
                    includeSystemUsers: (value) => !!value,
                  }}
                />
              </div>
            </div>
            {selection.size >= MaxSelectedItems && (
              <Alert
                fit
                type="warning"
                message={`You can only add ${MaxSelectedItems} persons at once.`}
                style={{ margin: 0, marginTop: "10px" }}
              />
            )}
          </EntityTable.Controls>
          <EntityTable.Body<Person, PersonFilters>
            functionRef={functionRef}
            entityConstants={personsConstants}
            filters={memoizedFilters}
            columns={columns}
            columnSelect
            columnSetting={columnSetting}
            columnWidths={columnWidths}
            defaultColumnSettings={columnSettings}
            dispatchTabStore={dispatchTabStore}
            setResultsCount={onCountChange}
            onSelectionChange={onSelectionChange}
            onSelectionPermissions={onSelectionPermissions}
            showPermissionColumn
            loading={tabsLoading}
            onRowClick={(row) => {
              functionRef.current?.setSelection((prevState) => {
                var newSelection = new Set(Array.from(prevState));
                newSelection.has(row.id) ? newSelection.delete(row.id) : newSelection.add(row.id);
                return newSelection;
              });
              functionRef.current?.forceUpdate();
            }}
            defaultSelection={Array.from(selection)}
            maxSelectedItems={MaxSelectedItems}
          />
        </EntityTable>
      }
      mainDefaultSize={80}
      mainMinSize={30}
      sidebar={
        <div style={{ width: "100%", display: "flex" }}>
          <PersonFilterBar
            initialValues={sidebarFilters}
            dispatchTabStore={dispatchTabStore}
            tabsLoading={tabsLoading}
            currentTab="activeUsers" // TO HIDE TRASH FILTERS
          />
        </div>
      }
      sidebarPosition="left"
      sideFr={20}
      sidebarMinSize={20}
    />
  );
};
