import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { Organization, OrganizationFilters } from "../api/Organizations";
import { InputFormField } from "../common/formfields/InputFormField";
import { TextareaFormField } from "../common/formfields/TextareaFormField";
import { FormButtons } from "../common/forms/FormButtons";
import { FormHeader } from "../common/forms/FormHeader";
import styles from "../common/forms/forms.module.css";
import { useContext, useEffect, useState } from "react";
import { IResultModel } from "../api/GenericTypes";
import { SessionContext } from "../common/contexts/SessionContext";
import { useDebouncedValue } from "../common/helperfunctions/useDebouncedValue";
import { OrganizationsTagsVirtualizedSelectForm } from "../common/forms/EntityForms/formsVirtualized/OrganizationTagsVirtualizedSelectForm";
import { EntityFormProps } from "../common/entity/EntityInterfaces";

export const OrganizationForm = ({
  event,
  fieldLabels,
  permissions,
  title,
  subtitle,
  initialValues,
  onSubmit,
  onCancel,
  loading,
  submitButtonLabel,
}: EntityFormProps<"organizations">) => {
  const { api } = useContext(SessionContext);

  const [nameState, setNameState] = useState<string>("");

  const OrganizationFormSchema = yup.object().shape({
    name: yup
      .string()
      .required("Name is required")
      .test({
        test: async () => {
          if (!nameState) return false;
          if (nameState === initialValues?.name) return true;
          const existingLogins = (
            (await api.post(
              "organizations/ids_only",
              { names: [nameState] } as OrganizationFilters,
              {}
            )) as IResultModel<number>
          )?.results;
          return !!existingLogins && !existingLogins.length;
        },
        message: "An organization with this name already exists",
      }),
  });

  const {
    control,
    register,
    handleSubmit,
    watch,
    formState: { errors, isSubmitting },
  } = useForm<Partial<Organization>>({
    values: initialValues,
    resolver: yupResolver(OrganizationFormSchema),
  });

  const nameValue = watch("name");
  const debouncedNameValue = useDebouncedValue(nameValue, 300);

  useEffect(() => {
    setNameState(debouncedNameValue || "");
  }, [debouncedNameValue]);

  return (
    <>
      <FormHeader title={title} subtitle={subtitle} />
      <form
        onSubmit={(e) => {
          e.preventDefault();
        }}
        autoComplete="off"
        className={`form-horizontal ${styles.form_holder}`}
      >
        <fieldset>
          <legend className="col-md-offset-2 col-md-10">Basic details</legend>
        </fieldset>
        <InputFormField
          id="name"
          label={fieldLabels.name}
          errors={errors}
          register={register}
          autoFocus={true}
          required={true}
          placeholder="Enter name..."
        />

        <OrganizationsTagsVirtualizedSelectForm
          id="organizationTags"
          label={fieldLabels.organizationTags}
          control={control}
          showControls
          horizontal
          isMulti
          allowCreateEntity
        />

        <InputFormField
          id="email"
          label={fieldLabels.email}
          errors={errors}
          register={register}
          placeholder="Enter E-mail address..."
        />

        <TextareaFormField
          id="address"
          label={fieldLabels.address}
          errors={errors}
          register={register}
          placeholder="Enter address..."
        />

        <InputFormField
          id="phoneOffice"
          label={fieldLabels.phoneOffice}
          errors={errors}
          register={register}
          placeholder="Enter phone number..."
        />

        <InputFormField
          id="faxOffice"
          label={fieldLabels.faxOffice}
          errors={errors}
          register={register}
          placeholder="Enter fax number..."
        />

        <InputFormField
          id="web"
          label={fieldLabels.web}
          errors={errors}
          register={register}
          placeholder="Enter web address..."
        />

        <TextareaFormField
          id="notes"
          label={fieldLabels.notes}
          errors={errors}
          register={register}
          placeholder="Enter notes..."
        />

        <FormButtons
          groupName="persons"
          entityId={initialValues ? initialValues.id : undefined}
          onClose={onCancel}
          onSubmit={handleSubmit(async (entity) => await onSubmit(entity))}
          submitButtonLabel={submitButtonLabel || "Save changes"}
          disabled={isSubmitting}
          errors={errors}
          loading={loading}
        />
      </form>
    </>
  );
};
