import {
  PropertyTranslator,
  IGenericRequestParametersTranslator,
  IPaginationParametersTranslator,
  ICreatedRecordParametersTranslator,
  IModifiedRecordParametersTranslator,
  ICreationRecordLabels,
  IPermissionedEntityLabels,
  IModificationRecordLabels,
} from "./GenericTranslator";
import {
  DefaultCreatedByRecordOrderTypeConsts,
  DefaultCreatedOnRecordOrderTypeConsts,
  DefaultModifiedByRecordOrderTypeConsts,
  DefaultModifiedOnRecordOrderTypeConsts,
  EntityConstants,
  ICreatedRecordParameters,
  ICreationRecord,
  IEntityMinimalModel,
  IGenericRequestParameters,
  IModelWithIntId,
  IModificationRecord,
  IModifiedRecordParameters,
  IPaginationParameters,
  IPermissionedEntity,
} from "./GenericTypes";

export const announcementsConstants: EntityConstants<Announcement, AnnouncementFilters> = {
  resource: "announcements",
  frontendIndexRoute: "announcements",
  entitySingular: "announcement",
  entityPlural: "announcements",
  icon: "bell",
};

export const announcementFieldLabels = {
  type: "Type",
  startDate: "Start date",
  contentType: "Content type",
  isSystemAnnouncement: "Is system announcement",
  endDate: "Expiration date",
  message: "Message",
  createdBy: "Created by",
  createdOn: "Created on",
  modifiedBy: "Modified by",
  modifiedOn: "Modified on",
  users: "Show to",
};

export type AnnouncementType = "regular" | "warning" | "urgent";
export const AnnouncementTypes = ["regular", "warning", "urgent"] as AnnouncementType[];

export type AnnouncementContentType = "text" | "markdown";
export const AnnouncementContentTypes = ["text", "markdown"] as AnnouncementContentType[];

export interface AnnouncementCurrentUserState {
  isRead: boolean;
}

export interface Announcement extends IModelWithIntId, ICreationRecord, IModificationRecord, IPermissionedEntity {
  uid?: string;
  message: string;
  type: AnnouncementType;
  contentType: AnnouncementContentType;
  isSystemAnnouncement: boolean;
  startDate: string;
  endDate?: string | null;
  currentUserState?: AnnouncementCurrentUserState;
}

export interface AnnouncementSuggestions extends IEntityMinimalModel<number> {}

export const AnnouncementSortingOptionsConsts = [
  ...DefaultCreatedOnRecordOrderTypeConsts,
  ...DefaultCreatedByRecordOrderTypeConsts,
  ...DefaultModifiedOnRecordOrderTypeConsts,
  ...DefaultModifiedByRecordOrderTypeConsts,
  "DEFAULT_ASC",
  "DEFAULT_DESC",
  "ID_ASC",
  "ID_DESC",
  "START_DATE_ASC",
  "START_DATE_DESC",
  "END_DATE_ASC",
  "END_DATE_DESC",
  "TYPE_ASC",
  "TYPE_DESC",
] as const;

export type AnnouncementSortingOptions = (typeof AnnouncementSortingOptionsConsts)[number];

export interface AnnouncementFilters
  extends IPaginationParameters,
    ICreatedRecordParameters,
    IModifiedRecordParameters,
    IGenericRequestParameters<Announcement, AnnouncementSortingOptions> {
  types?: AnnouncementType[] | null;
  startDateFrom?: string | null;
  startDateTo?: string | null;
  endDateFrom?: string | null;
  endDateTo?: string | null;
  current?: boolean;
  includeCurrentUserHasRead?: boolean | null;
  includeCurrentUserStates?: boolean | null;
  includeSystemAnnouncements?: boolean | null;
}

export const AnnouncementFiltersTranslator: PropertyTranslator<AnnouncementFilters> = {
  ...IPaginationParametersTranslator,
  ...IGenericRequestParametersTranslator,
  ...ICreatedRecordParametersTranslator,
  ...IModifiedRecordParametersTranslator,
  types: "Announcement type",
  current: "Is current",
  startDateFrom: "Started from",
  startDateTo: "Start to",
  endDateFrom: "Expiration date from",
  endDateTo: "Expiration date to",
  includeCurrentUserHasRead: "Show already read",
  includeCurrentUserStates: "Internal only",
  includeSystemAnnouncements: "Include system announcements",
} as const;

export const AnnouncementFieldLabels: PropertyTranslator<Announcement> = {
  ...IPermissionedEntityLabels,
  ...ICreationRecordLabels,
  ...IModificationRecordLabels,
  id: "Announcement ID",
  uid: "UID",
  message: "Message",
  type: "Type",
  contentType: "Content type",
  isSystemAnnouncement: "Is system announcement",
  startDate: "Start date",
  endDate: "Expiration date",
  currentUserState: "Current user state",
} as const;
