import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { InputFormField } from "../common/formfields/InputFormField";
import { TextareaFormField } from "../common/formfields/TextareaFormField";
import { FormButtons } from "../common/forms/FormButtons";
import { FormHeader } from "../common/forms/FormHeader";
import styles from "../common/forms/forms.module.css";
import { FormFieldLayout } from "../common/formfields/FormFieldLayouts";
import { ToggleButtonComponent } from "../ViewerUIElements/ToggleButtonComponent";
import { OrganizationsVirtualizedSelectForm } from "../common/forms/EntityForms/formsVirtualized/OrganizationsVirtualizedSelectForm";
import { useEffect, useState } from "react";
import { PersonsVirtualizedSelectForm } from "../common/forms/EntityForms/formsVirtualized/PersonsVirtualizedSelectForm";
import { EntityFormProps } from "../common/entity/EntityInterfaces";
import { EquipmentFacility } from "../api/Facilities";
import { EquipmentTagsVirtualizedSelectForm } from "../common/forms/EntityForms/formsVirtualized/EquipmentTagsVirtualizedSelectForm";
import { phoneNumberRegex } from "../common/misc/RegularExpressionConstats";

export const EquipmentForm = ({
  event,
  fieldLabels,
  permissions,
  title,
  subtitle,
  initialValues,
  onSubmit,
  onCancel,
  loading,
  submitButtonLabel,
}: EntityFormProps<"equipments">) => {
  const [isObsolete, setIsObsolete] = useState<boolean>(!!initialValues?.isObsolete);

  useEffect(() => {
    setIsObsolete(!!initialValues?.isObsolete);
  }, [initialValues?.isObsolete]);

  const EquipmentFormSchema = yup.object().shape({
    name: yup.string().required("Equipment name is required").typeError("Equipment name is required"),
    localPhone: yup
      .string()
      .nullable()
      .optional()
      .matches(phoneNumberRegex, { message: "Invalid phone number", excludeEmptyString: true })
      .typeError("Invalid phone number")
      .default(""),
  });

  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
  } = useForm<Partial<EquipmentFacility>>({
    values: initialValues,
    resolver: yupResolver(EquipmentFormSchema),
  });

  return (
    <>
      <FormHeader title={title} subtitle={subtitle} />
      <form
        onSubmit={(e) => {
          e.preventDefault();
        }}
        autoComplete="off"
        className={`form-horizontal ${styles.form_holder}`}
      >
        <fieldset>
          <legend className="col-md-offset-2 col-md-10">Basic details</legend>
        </fieldset>

        <InputFormField
          id="name"
          label={fieldLabels.name}
          errors={errors}
          register={register}
          autoFocus={true}
          required={true}
          placeholder="Enter equipment name..."
        />

        <EquipmentTagsVirtualizedSelectForm
          id="equipmentTag"
          label={fieldLabels.equipmentTag}
          control={control}
          horizontal
          allowCreateEntity
        />

        <FormFieldLayout id="isObsolete" label={fieldLabels.isObsolete} hasLabel horizontal>
          <div className="flex align-center gap-5" style={{ padding: "10px 0" }}>
            <ToggleButtonComponent checked={!isObsolete} setChecked={(checked) => setIsObsolete(!checked)}>
              <></>
            </ToggleButtonComponent>
            {isObsolete && <div className="label label-soft-danger">Not in use</div>}
          </div>
        </FormFieldLayout>

        <InputFormField
          id="serialnumber"
          label={fieldLabels.serialnumber}
          errors={errors}
          register={register}
          placeholder="Enter serialnumber..."
        />

        <InputFormField
          id="model"
          label={fieldLabels.model}
          errors={errors}
          register={register}
          placeholder="Enter model..."
        />

        <InputFormField
          id="room"
          label={fieldLabels.room}
          errors={errors}
          register={register}
          placeholder="Enter room..."
        />

        <InputFormField
          id="localPhone"
          label={fieldLabels.localPhone}
          errors={errors}
          register={register}
          placeholder="Enter local phone number..."
        />

        <OrganizationsVirtualizedSelectForm
          id="homeLab"
          label={fieldLabels.homeLab}
          control={control}
          horizontal
          allowCreateEntity
        />

        <PersonsVirtualizedSelectForm
          id="homeLabContacts"
          label={fieldLabels.homeLabContacts}
          control={control}
          horizontal
          isMulti
          allowCreateEntity
        />

        <OrganizationsVirtualizedSelectForm
          id="company"
          label={fieldLabels.company}
          control={control}
          horizontal
          allowCreateEntity
        />

        <PersonsVirtualizedSelectForm
          id="companyContacts"
          label={fieldLabels.companyContacts}
          control={control}
          horizontal
          isMulti
          allowCreateEntity
        />

        <TextareaFormField
          id="notes"
          label={fieldLabels.notes}
          errors={errors}
          register={register}
          placeholder="Enter notes..."
        />

        <FormButtons
          groupName="equipments"
          entityId={initialValues ? initialValues.id : undefined}
          onClose={onCancel}
          onSubmit={handleSubmit(async (entity) => {
            await onSubmit({ ...entity, isObsolete: isObsolete });
          })}
          submitButtonLabel={submitButtonLabel || "Save changes"}
          disabled={isSubmitting}
          errors={errors}
          loading={loading}
        />
      </form>
    </>
  );
};
