import { CSSProperties, useContext } from "react";
import { Link } from "react-router-dom";
import {
  EntityConstants,
  ICreationRecord,
  IEntityMinimalModel,
  INamedEntity,
  IOwnedEntity,
  IUniqueEntity,
  StringIndexedDict,
} from "../../../../api/GenericTypes";
import { toUppercase } from "../../../helperfunctions/stringFunctions";
import { LucideIcon } from "../../../icon/LucideIcon";
import { getIndexRoute, getUUIDLink } from "../../../../main/Routing";
import { SessionContext } from "../../../contexts/SessionContext";
import { DateTimeRenderer } from "../../../datetime/DateTimeFormatter";
import { GetPersons } from "../../../misc/EntityRenders/EntityRenderer";
import { NotAvailable } from "../../../misc/UIconstants";
import { copyTextToClipboard } from "../../../helperfunctions/copyToClipboard";
import styles from "./DetailsPageTopControlsView.module.css";
import { Barcode } from "../../../icon/barcode/Barcode";
/**
 * Represents top control buttons actions on the details page; usable on any entity-details-page
 * Author:CS
 */

type PartialEntity = Partial<IOwnedEntity> &
  Partial<ICreationRecord> &
  Partial<INamedEntity> &
  Partial<IUniqueEntity> &
  StringIndexedDict;

interface DetailsPageTopControlsViewProps<Entity extends PartialEntity> {
  entity?: Entity;
  entityConstants: EntityConstants;
  nameOverride?: string;
  attributes?: React.ReactNode;
  controlsLeft?: React.ReactNode;
  controls?: React.ReactNode;
  titleOverride?: string;
  fullTitleOverride?: React.ReactNode;
  createdByOverride?: IEntityMinimalModel<number> | null;
  createdOnOverride?: string | null;
  modifiedByOverride?: IEntityMinimalModel<number> | null;
  modifiedOnOverride?: string | null;
  containerStyle?: CSSProperties;
}

export const DetailsPageTopControlsView = <Entity extends PartialEntity>({
  entity,
  entityConstants,
  nameOverride,
  attributes,
  controlsLeft,
  controls,
  titleOverride,
  fullTitleOverride,
  createdByOverride,
  createdOnOverride,
  modifiedByOverride,
  modifiedOnOverride,
  containerStyle,
}: DetailsPageTopControlsViewProps<Entity>) => {
  const { route, session, api } = useContext(SessionContext);

  return (
    <div
      className="flex row-nowrap gap-5"
      style={{
        width: "100%",
        height: "fit-content",
        paddingBottom: 5,
        borderBottom: "1px solid var(--gray-200)",
        // overflow: "hidden",
        ...containerStyle,
      }}
    >
      {!!session?.features.enable_barcode_display && entity?.uid && (
        <div
          className="flex align-center justify-center"
          onClick={() => copyTextToClipboard(entity.uid!, true, "Copied UUID to clipboard")}
        >
          <Barcode
            text={entity.uid}
            width={12}
            height={12}
            scale={2}
            style={{ width: "min-content", height: "min-content", cursor: "pointer" }}
          />
        </div>
      )}
      <div
        className="flex col-nowrap"
        style={{
          width: "100%",
          height: "fit-content",
          ...containerStyle,
        }}
      >
        <div
          style={{
            display: "grid",
            gridAutoFlow: "column",
            gap: "5px",
          }}
        >
          <div className="flex row-nowrap align-center gap-5" style={{ overflow: "auto" }}>
            {fullTitleOverride ? (
              <>
                {controlsLeft}
                {fullTitleOverride}
              </>
            ) : (
              <>
                {controlsLeft}
                <Link
                  to={route(getIndexRoute(entityConstants.frontendIndexRoute))}
                  style={{ textDecoration: "none" }}
                  title={`Show ${entityConstants.entityPlural} table`}
                >
                  <div
                    className="flex row-nowrap align-center gap-5"
                    style={{
                      fontSize: "24px",
                      fontWeight: 600,
                      lineHeight: "24px",
                      whiteSpace: "nowrap",
                    }}
                  >
                    <LucideIcon
                      name={entityConstants.icon}
                      color={"var(--primary)"}
                      style={{ width: 20, height: 20 }}
                    />
                    {titleOverride || `${toUppercase(entityConstants.entityPlural)}`}
                  </div>
                </Link>
                <div>
                  <LucideIcon name="arrow-right" color="var(--gray-400)" />
                </div>

                <span
                  style={{
                    fontSize: "24px",
                    lineHeight: "28px",
                    margin: 0,
                    fontWeight: 400,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                >
                  {nameOverride || entity?.name || NotAvailable}
                </span>

                {attributes}
              </>
            )}
          </div>

          <div
            className="flex row-wrap align-center gap-5"
            style={{ marginLeft: "auto", gap: 5, width: "fit-content" }}
          >
            {controls}
          </div>
        </div>
        <div
          className="gap-5 align-center"
          style={{
            width: "100%",
            height: "fit-content",
            whiteSpace: "nowrap",
            overflow: "hidden",
            display: "grid",
            gridAutoFlow: "column",
          }}
        >
          {entity?.uid && entity.uid && (
            <div className="flex row-nowrap align-center gap-5" style={{ overflow: "hidden" }}>
              <div
                className="flex row-nowrap"
                style={{
                  color: "var(--gray-400)",
                  overflow: "hidden",
                  cursor: "pointer",
                }}
                title={`Universally unique identifier of the ${entityConstants.entitySingular} (click to copy to clipboard)`}
                onClick={() => copyTextToClipboard(entity.uid!, true, "Copied UUID to clipboard")}
              >
                <span style={{ textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden" }}>
                  UUID: {entity.uid}
                </span>
              </div>
              <button
                className="btn btn-xs btn-ghost-secondary"
                title="Copy UUID-Link to clipboard"
                onClick={() =>
                  copyTextToClipboard(
                    `${window.origin}/${api.getGroup()}${getUUIDLink(entity.uid!)}`,
                    true,
                    "Copied UUID-Link to clipboard"
                  )
                }
              >
                <LucideIcon name="link" color="var(--gray-400)" />
              </button>
            </div>
          )}
          <div className="flex col-nowrap">
            <div
              className="flex row-nowrap align-center"
              style={{
                width: "fit-content",
                height: "fit-content",
                whiteSpace: "nowrap",
                marginLeft: "auto",
                fontSize: "0.75em",
                gap: "2.5px",
                // padding: "1.25px 0px",
                // overflow: "hidden",
              }}
            >
              {createdByOverride ? (
                <div className={styles.createdBy}>
                  Created by: <GetPersons style={{ gap: 2.5 }} persons={[createdByOverride]} />
                </div>
              ) : entity?.createdBy ? (
                <div className={styles.createdBy}>
                  Created by: <GetPersons style={{ gap: 2.5 }} persons={[entity.createdBy]} />
                </div>
              ) : entity?.owner ? (
                <div className={styles.createdBy}>
                  Created by: <GetPersons style={{ gap: 2.5 }} persons={[entity.owner]} />
                </div>
              ) : (
                <></>
              )}

              {createdOnOverride ? (
                <div className="flex row-nowrap gap-5 align-center" style={{ whiteSpace: "nowrap" }}>
                  on <DateTimeRenderer date={createdOnOverride} includeElapsed={false} />
                </div>
              ) : entity?.createdOn ? (
                <div className="flex row-nowrap gap-5 align-center" style={{ whiteSpace: "nowrap" }}>
                  on <DateTimeRenderer date={entity.createdOn} includeElapsed={false} />
                </div>
              ) : (
                <></>
              )}
            </div>
            <div
              className="flex row-nowrap align-center"
              style={{
                width: "fit-content",
                height: "fit-content",
                whiteSpace: "nowrap",
                marginLeft: "auto",
                fontSize: "0.75em",
                gap: "2.5px",
                // padding: "1.25px 0px",
                // overflow: "hidden",
              }}
            >
              {modifiedByOverride ? (
                <div className={styles.createdBy}>
                  Modified by: <GetPersons style={{ gap: 2.5 }} persons={[modifiedByOverride]} />
                </div>
              ) : entity?.modifiedBy ? (
                <div className={styles.createdBy}>
                  Modified by: <GetPersons style={{ gap: 2.5 }} persons={[entity.modifiedBy]} />
                </div>
              ) : entity?.owner ? (
                <div className={styles.createdBy}>
                  Modified by: <GetPersons style={{ gap: 2.5 }} persons={[entity.owner]} />
                </div>
              ) : (
                <></>
              )}

              {modifiedOnOverride ? (
                <div className="flex row-nowrap gap-5 align-center" style={{ whiteSpace: "nowrap" }}>
                  on <DateTimeRenderer date={modifiedOnOverride} includeElapsed={false} />
                </div>
              ) : entity?.modifiedOn ? (
                <div className="flex row-nowrap gap-5 align-center" style={{ whiteSpace: "nowrap" }}>
                  on <DateTimeRenderer date={entity.modifiedOn} includeElapsed={false} />
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
