import { CSSProperties } from "react";

import styles from "./DecoratedNumberInput.module.css";
import { ValidatedInput } from "./ValidatedInput";

export const DecoratedNumberInput = ({
  value,
  setValue,
  validate,
  name,
  useInteger,
}: {
  value: number;
  validate?: (value: number) => boolean;
  setValue?: (value: number) => void;
  name?: string;
  style?: CSSProperties;
  useInteger?: boolean;
}) => {
  // useEffect(() => {
  //   console.log("ValidatedInput", value);
  // }, [value]);

  return (
    <div className={styles.rangeSetter}>
      <div className={styles.settingText} style={{ position: "relative", top: -10 }}>
        {name ? <span>{name}:&nbsp;</span> : null}
      </div>
      <div style={{ position: "relative", top: -10 }}>
        <ValidatedInput
          value={value}
          setValue={setValue}
          validate={validate}
          style={{
            width: "6em",
            // scale: "80%",
          }}
          useInteger={useInteger}
        />
      </div>
    </div>
  );
};
