import { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { DetailsPageTopControlsView } from "../common/panels/misc/DetailsPageTopControlsView/DetailsPageTopControlsView";
import { SessionContext } from "../common/contexts/SessionContext";
import { LucideIcon } from "../common/icon/LucideIcon";
import { NotSet } from "../common/misc/UIconstants";
import { AlertModal } from "../common/modals/AlertModal/AlertModal";
import { showtoast } from "../common/overlays/Toasts/showtoast";
import Detail from "../common/panels/Detail/Detail";
import { Table } from "../common/panels/Detail/DetailTable";
import { MoreDropdown } from "../common/buttons/MoreDropdown/MoreDropdown";
import { InstrumentFieldLabels } from "../api/Instruments";
import { ToggleSwitch } from "../common/buttons/ToggleSwitch/ToggleSwitch";
import { EntityDetailProps, EntityDetailTableProps } from "../common/entity/EntityInterfaces";
import { DatasetsTile } from "../common/sidebar/tiles/DatasetsTile";
import { instrumentsConstants } from "../api/Facilities";
import { GetOrganizations, GetPersons } from "../common/misc/EntityRenders/EntityRenderer";
import { CustomTypeDetailRow, CustomTypeRenderer } from "../Customization/CustomTypes/generics/CustomTypeRenderer";
// import { AttachmentsTileWrapped } from "../common/sidebar/tiles/AttachmentsTile";
import { CustomFieldReferencesTile } from "../common/sidebar/tiles/CustomFieldReferencesTile";

export const InstrumentsDetailTable = ({
  entity,
  entityConstants,
  fieldLabels,
}: EntityDetailTableProps<"instruments">) => {
  const { session } = useContext(SessionContext);
  return (
    <>
      <Table noPadding nonFoldable>
        <Table.Head>Basic details</Table.Head>
        <Table.Body>
          <Table.Body.RowContent
            title={InstrumentFieldLabels.name}
            content={
              <div className="container_label">
                <div className="container_label_name" style={{ fontWeight: 600 }}>
                  {entity.name ?? NotSet}
                </div>
                <div className="container_label_id">
                  <span style={{ whiteSpace: "nowrap" }}>Instrument-ID: {entity.id}</span>
                </div>
              </div>
            }
          />
          {session?.features.enable_facility_custom_types && (
            <CustomTypeDetailRow entity={entity} entityConstants={instrumentsConstants} />
          )}

          <Table.Body.RowContent title={InstrumentFieldLabels.method} content={entity.method.name} />

          <Table.Body.RowContent
            title={InstrumentFieldLabels.isObsolete}
            content={<ToggleSwitch checked={!entity.isObsolete} readOnly />}
          />

          <Table.Body.RowContent title={InstrumentFieldLabels.serialnumber} content={entity.serialnumber || NotSet} />
          <Table.Body.RowContent title={InstrumentFieldLabels.model} content={entity.model || NotSet} />

          <Table.Body.RowContent title={InstrumentFieldLabels.room} content={entity.room || NotSet} />
        </Table.Body>
      </Table>

      <Table noPadding>
        <Table.Head>Contact details</Table.Head>
        <Table.Body>
          <Table.Body.RowContent title={InstrumentFieldLabels.localPhone} content={entity.localPhone || NotSet} />

          <Table.Body.RowContent
            title={InstrumentFieldLabels.homeLab}
            content={!!entity.homeLab ? <GetOrganizations organizations={[entity.homeLab]} /> : NotSet}
          />
          <Table.Body.RowContent
            title={InstrumentFieldLabels.homeLabContacts}
            content={!!entity.homeLabContacts?.length ? <GetPersons persons={entity.homeLabContacts} /> : NotSet}
          />
          <Table.Body.RowContent
            title={InstrumentFieldLabels.company}
            content={!!entity.company ? <GetOrganizations organizations={[entity.company]} /> : NotSet}
          />
          <Table.Body.RowContent
            title={InstrumentFieldLabels.companyContacts}
            content={!!entity.companyContacts?.length ? <GetPersons persons={entity.companyContacts} /> : NotSet}
          />

          <Table.Body.RowContent
            title={InstrumentFieldLabels.notes}
            content={
              <>
                {entity.notes ? (
                  <div className="container_notes">
                    <textarea rows={4} className="container_textarea" value={entity?.notes} disabled />
                  </div>
                ) : (
                  NotSet
                )}
              </>
            }
          />
        </Table.Body>
      </Table>
      <CustomTypeRenderer entity={entity} />
    </>
  );
};

export const InstrumentsDetail = ({
  permissions,
  routes,
  entity,
  entityApi,
  entityConstants,
  fieldLabels,
}: EntityDetailProps<"instruments">) => {
  let history = useHistory();
  const [showModal, setShowModal] = useState<boolean>(false);

  return (
    <Detail
      head={
        <DetailsPageTopControlsView
          entity={entity}
          entityConstants={instrumentsConstants}
          controls={
            <>
              <button
                className="btn btn-default"
                onClick={() => history.push(routes.getEditLink(entity.id))}
                disabled={!permissions.canEdit(entity)}
              >
                <LucideIcon name="square-pen" />
                <span> Edit</span>
              </button>
              <MoreDropdown drop="right" btn="btn btn-ghost-secondary">
                <li>
                  <button
                    className="btn btn-sm btn-ghost-secondary"
                    title={`Clone this Instrument`}
                    onClick={() => history.push(routes.getCloneLink(entity.id))}
                    disabled={!permissions.canCreate}
                  >
                    <LucideIcon name="copy" /> Clone
                  </button>
                </li>
                <li>
                  <button
                    className="btn btn-sm btn-ghost-danger"
                    title="Delete"
                    onClick={() => setShowModal(true)}
                    disabled={!permissions.canDelete(entity)}
                  >
                    <LucideIcon name="trash-2" /> Delete
                  </button>
                </li>
              </MoreDropdown>
              <AlertModal
                type="danger"
                showModal={showModal}
                setShowModal={setShowModal}
                title="Permanently delete instrument?"
                description="This instrument may be referenced by other entities. Deleting a instrument is only possible if all references are removed."
                proceedLabel="Delete"
                onProceed={async () => {
                  await entityApi
                    .deleteMutationAsync(
                      {
                        id: entity.id,
                        goBackOnSuccess: false,
                        showToast: false,
                        entityName: "instrument",
                        params: { deletePermanently: true },
                      },

                      {
                        onSuccess: () => {
                          showtoast("success", `Deleted Instrument-ID: ${entity.id}.`);
                          history.goBack();
                        },
                      }
                    )
                    .catch((e) => {});
                }}
                loading={entityApi.isLoadingDeleteMutation}
              />
            </>
          }
        />
      }
      mainStyle={{ padding: "0px 10px" }}
      main={<InstrumentsDetailTable entity={entity} entityConstants={entityConstants} fieldLabels={fieldLabels} />}
      sidebar={
        <>
          {!!entity.relations?.datasets.count && (
            <DatasetsTile
              defaultFilters={{ instrumentIds: [entity.id] }}
              excludeFilters={{ instrumentIds: () => false }}
            />
          )}
          <CustomFieldReferencesTile entityType="Instrument" entity={entity} />
          {/* <AttachmentsTileWrapped entity={instrument} /> */}
          {!entity.relations?.datasets.count && <DatasetsTile disabled />}
        </>
      }
    />
  );
};
