import { ProsemirrorAttributes } from "remirror";
import { IdTypes, IGenericEntity, INamedEntity } from "../../../../../api/GenericTypes";
import { GenericEntityConstantsEntities } from "../../../../../api/GenericConstants";

export interface EntityWrapperSettings {
  collapsible?: boolean;
  defaultCollapsed?: boolean;
  showHeader?: boolean;
}

export const insertEntityOptions: Remirror.CommandDecoratorOptions = {};
export const removeEntityOptions: Remirror.CommandDecoratorOptions = {};

export interface EntityOptions<T = {}> {
  onDoubleClick?: (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    entity: T & IGenericEntity & INamedEntity,
    entityTypeId: GenericEntityConstantsEntities
  ) => void;
  ignoreIntersectionObserver?: boolean;
  uploadHandler?: (file: File, uuid: string) => Promise<void>;
}

export interface EntityAttributes<T = {}> extends ProsemirrorAttributes {
  id?: string;
  entityId?: IdTypes;
  entityUuid?: string;
  entityTypeId?: GenericEntityConstantsEntities;
  version?: number;
  settings?: EntityWrapperSettings;
  additionalSettings?: T;
}

export interface DatasetAdditionalSettings {
  overrideId?: string;
  showDetails?: boolean;
  showSourceInformation?: boolean;
  showParameters?: boolean;
  showViewer?: boolean;
  width?: number;
  height?: number;
}
