import { useEffect, useLayoutEffect, useRef, useState } from "react";
import {
  SerializedPipeline,
  SerializedPipelineCommand,
  SerializedPipelineConnector,
} from "../../ProcessingPipeline/PipelineTypes";
import { pipelineNodeState } from "../../ViewerLayout/ViewerLayoutTypes";
import { PipelineConnectorComponent } from "./PipelineConnectorComponent";
import { PipelineNodeComponent } from "./PipelineNodeComponent";

import styles from "./ProcessingGraphComponent.module.css";
// const PipelineNodeComponent = ({node}: {node: Pipe})

const Graph = (props: any) => {
  const { children }: { width: number; height: number; children: any } = props;
  // console.log("size", width, height);
  return <div className={styles.graph}>{children}</div>;
};

export const ProcessingGraphComponent = ({
  pipeline,
  width,
  height,
  pipelineStates,
  selected,
  setSelected,
  getCommandColor,
}: {
  pipeline?: SerializedPipeline;
  pipelineStates?: Record<string, pipelineNodeState>;
  width?: number;
  height?: number;
  selected?: string;
  setSelected?: (id: string) => void;
  getCommandColor: (command?: string) => string;
}) => {
  const [graph, setGraph] = useState<any>();
  const [focused, setFocused] = useState<string>("");
  const targetRef = useRef<HTMLDivElement>(null);

  // useEffect(() => {
  //   if (setSelected && selected === undefined) {
  //     let node = "";
  //     if (pipeline?.settings?.selectedNode) node = pipeline.settings.selectedNode;
  //     else if (pipeline?.pipeline && pipeline.pipeline.length > 0) node = pipeline.pipeline[0].id;
  //     setSelected(node);
  //   }
  // }, [selected, pipeline]);

  useLayoutEffect(() => {
    const element = targetRef.current?.getElementsByClassName(styles.selectionArrow).item(0) as HTMLDivElement;
    if (element && element.id !== focused) {
      // console.log("focus?", element.id, focused);
      const a = element.getElementsByTagName("a").item(0);
      a?.focus();
      setFocused(element.id);
    }
  }, [targetRef, graph]);

  useEffect(() => {
    if (!pipeline) {
      setGraph(null);
    } else {
      setGraph(
        pipeline.pipeline.map((element, i) => {
          const state = pipelineStates && pipelineStates[element.id] ? pipelineStates[element.id] : undefined;
          return element.command === "connect" ? (
            <PipelineConnectorComponent
              key={i}
              node={element as SerializedPipelineConnector}
              errors={state?.errors ?? []}
              warnings={state?.warnings ?? []}
              state={state?.state}
            />
          ) : (
            <div key={"main" + i} className={styles.selectionBorder}>
              <div key={"left" + i} className={styles.selectionBox} />
              <PipelineNodeComponent
                key={i}
                node={element as SerializedPipelineCommand}
                errors={state?.errors ?? []}
                warnings={state?.warnings ?? []}
                state={state?.state}
                onClick={(id: string) => {
                  if (setSelected) setSelected(id);
                }}
                width={width ? width - 60 : 100}
                getCommandColor={getCommandColor}
              />
              <div
                key={"right" + i}
                id={element.id}
                className={element.id === selected ? styles.selectionArrow : styles.selectionBox}
              >
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    return false;
                  }}
                />
              </div>
            </div>
          );
        })
      );
    }
  }, [pipeline, pipelineStates, selected, width, height]);

  return (
    <div ref={targetRef} className={styles.main} style={{ height: height, width: width }}>
      <Graph>{graph}</Graph>
    </div>
  );
};
