import { useCommands } from "@remirror/react";
import { useCallback, useEffect, useState } from "react";
import { LucideIcon } from "../../../../../common/icon/LucideIcon";
import { showtoast } from "../../../../../common/overlays/Toasts/showtoast";
import { MoreDropdown } from "../../../../../common/buttons/MoreDropdown/MoreDropdown";
import { useDebouncedValue } from "../../../../../common/helperfunctions/useDebouncedValue";
import styles from "./Timer.module.css";

export const Timer = ({
  time,
  remainingSeconds,
  checkpoints,
  onUpdateTimer,
  onSaveCheckpoints,
  editable = false,
  showContent = true,
}: {
  time?: number;
  remainingSeconds?: number;
  checkpoints?: number[];
  onUpdateTimer?: ({
    checkpoints,
    remainingSeconds,
    showContent,
  }: {
    checkpoints?: number[];
    remainingSeconds?: number;
    showContent: boolean;
  }) => void;
  onSaveCheckpoints?: (checkpoints: string[]) => void;
  editable?: boolean;
  showContent?: boolean;
}) => {
  const checkCheckpointsType = useCallback((checkpoints) => {
    if (typeof checkpoints === "string") {
      return (checkpoints as string).split(",").map((c) => Number(c.trim()));
    }
    if (typeof checkpoints !== "object") {
      return checkpoints;
    }
    return [];
  }, []);

  const [timeInSeconds, setTimeInSeconds] = useState<number>(Number(remainingSeconds) || Number(time) || 0);
  const [timerInterval, setTimerInterval] = useState<any>();
  const [isOpen, setIsOpen] = useState<boolean>(showContent);
  const [checkpointsState, setCheckpointsState] = useState<number[]>(checkCheckpointsType(checkpoints) || []);

  const commands = useCommands();

  const secondsToTime = useCallback(
    (timeInSeconds: number) => {
      const days = Math.floor(timeInSeconds / 60 / 60 / 24);
      const hours = Math.floor((timeInSeconds - days * 24 * 60 * 60) / 60 / 60);
      const minutes = Math.floor((timeInSeconds - days * 24 * 60 * 60 - hours * 60 * 60) / 60);
      const seconds = Math.floor(timeInSeconds - days * 24 * 60 * 60 - hours * 60 * 60 - minutes * 60);
      const milliseconds = parseInt(
        (timeInSeconds - days * 24 * 60 * 60 - hours * 60 * 60 - minutes * 60 - seconds).toFixed(1).split(".")[1]
      );

      return {
        days,
        hours,
        minutes,
        seconds,
        milliseconds,
        timeString: `${time ? "- " : ""}${days.toString().padStart(2, "0")}:${hours
          .toString()
          .padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:${seconds
          .toString()
          .padStart(2, "0")}.${milliseconds}`,
      };
    },
    [time]
  );

  useEffect(() => {
    return () => clearInterval(timerInterval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const startTimer = useCallback(() => {
    !timerInterval &&
      setTimerInterval(
        setInterval(() => {
          setTimeInSeconds((prevState) => {
            if (time) {
              if (prevState <= 0) {
                return 0.0;
              } else {
                return prevState - 0.1;
              }
            } else {
              return prevState + 0.1;
            }
          });
        }, 100)
      );
  }, [timerInterval, time]);

  const debouncedTimeInSeconds = useDebouncedValue(timeInSeconds, 200);
  useEffect(() => {
    if (onUpdateTimer)
      onUpdateTimer({
        remainingSeconds: debouncedTimeInSeconds,
        checkpoints: checkpointsState,
        showContent: isOpen,
      });
  }, [onUpdateTimer, debouncedTimeInSeconds, checkpointsState, isOpen]);

  if (time && timeInSeconds <= 0 && timerInterval) {
    showtoast("warning", "Timer finished!");
    clearInterval(timerInterval);
    setTimerInterval(undefined);
    setTimeInSeconds(0);
  }

  if (typeof checkpointsState !== "object") return null;
  return (
    <div className={styles.elnTimerWrapperContainer}>
      <div className={styles.elnTimerWrapperTitleContainer} onClick={() => setIsOpen((prevState) => !prevState)}>
        <div className={styles.elnTimerWrapperTitle}>
          <div className={styles.elnTimerWrapperDropdownIcon}>
            <LucideIcon name={isOpen ? "chevron-up" : "chevron-down"} style={{ width: "20px", height: "20px" }} />
          </div>
          <LucideIcon name="clock" />
          {time
            ? isOpen
              ? `Timer: ${secondsToTime(time).timeString}`
              : `Timer: ${secondsToTime(timeInSeconds).timeString}`
            : isOpen
            ? `Stopwatch`
            : `Stopwatch: ${secondsToTime(timeInSeconds).timeString}`}
        </div>
        {editable && (
          <div className={styles.elnTimerWrapperDropdownIcon}>
            <MoreDropdown direction="down" drop="right">
              <button
                className="btn btn-xs btn-ghost-danger btn-block"
                style={{ display: "flex", alignItems: "center", gap: "10px" }}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  commands.deleteTimer();
                }}
              >
                <LucideIcon name="trash" />
                <div>Remove timer</div>
              </button>
            </MoreDropdown>
          </div>
        )}
      </div>
      {isOpen && (
        <div style={{ padding: "20px", width: "100%" }}>
          <div className={styles.timerWrapperContainer}>
            <div className={styles.timerRemainingTimeContainer}>
              <div>{time ? "Time remaining:" : "Current time:"} </div>
              <div className={styles.timeInSecondsCounter}>{secondsToTime(timeInSeconds).timeString}</div>
            </div>
          </div>

          {editable && (
            <div className={styles.timerToolbar}>
              <div style={{ marginRight: "10px" }}>
                <button
                  className="btn btn-soft-primary btn-block btn-xs"
                  style={{ display: "flex", justifyContent: "space-around", alignItems: "center", width: "120px" }}
                  onClick={() => startTimer()}
                  disabled={timerInterval}
                >
                  <LucideIcon name="play" style={{ width: "20px", height: "20px" }} />
                  <div style={{ textAlign: "center", width: "100%" }}>Start</div>
                </button>
              </div>
              <div style={{ marginRight: "10px" }}>
                <button
                  className="btn btn-soft-secondary btn-block btn-xs"
                  style={{ display: "flex", justifyContent: "space-around", alignItems: "center", width: "120px" }}
                  onClick={() => {
                    setTimerInterval(undefined);
                    clearInterval(timerInterval);
                  }}
                  disabled={!timerInterval || timeInSeconds <= 0}
                >
                  <LucideIcon name="pause" style={{ width: "20px", height: "20px" }} />
                  <div style={{ textAlign: "center", width: "100%" }}>Pause</div>
                </button>
              </div>
              <div style={{ marginRight: "10px" }}>
                <button
                  className="btn btn-soft-secondary btn-block btn-xs"
                  style={{ display: "flex", justifyContent: "space-around", alignItems: "center", width: "120px" }}
                  onClick={() => {
                    setCheckpointsState((prevState) => [...prevState, timeInSeconds]);
                  }}
                >
                  <LucideIcon name="clock" style={{ width: "20px", height: "20px" }} />
                  <div style={{ textAlign: "center", width: "100%" }}>Checkpoint</div>
                </button>
              </div>
              <div>
                <button
                  className="btn btn-soft-secondary btn-block btn-xs"
                  style={{ display: "flex", justifyContent: "space-around", alignItems: "center", width: "120px" }}
                  onClick={() => {
                    setTimeInSeconds(time || 0);
                    clearInterval(timerInterval);
                    setTimerInterval(undefined);
                    setCheckpointsState([]);
                  }}
                  disabled={timeInSeconds === time}
                >
                  <LucideIcon name="refresh-ccw" style={{ width: "20px", height: "20px" }} />
                  <div style={{ textAlign: "center", width: "100%" }}>Reset</div>
                </button>
              </div>
            </div>
          )}

          {checkpointsState.length > 0 && (
            <>
              <div className={styles.timerCheckpointsListContainer}>
                <div>Checkpoints:</div>
                {onSaveCheckpoints && editable && (
                  <div>
                    <button
                      className="btn btn-default btn-block btn-xs"
                      style={{ width: "120px" }}
                      onClick={() => onSaveCheckpoints(checkpointsState.map((c) => secondsToTime(c).timeString))}
                    >
                      <LucideIcon name="save" />
                      <span> Save to list</span>
                    </button>
                  </div>
                )}
              </div>
              <div className={styles.timerCheckpointsContainer}>
                {checkpointsState.map((c, index) => (
                  <div key={index} className={styles.timerCheckpoint}>
                    <div>{index + 1}.</div>
                    {secondsToTime(c).timeString}
                  </div>
                ))}
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};
