import React from "react";
import { IGenericEntityPermissions } from "../../permissions/useEntityPermissions";
import { EntityConstants, IEntityPermissions } from "../../../api/GenericTypes";
import { LucideIcon } from "../../icon/LucideIcon";
import { Routes } from "../../routes/useEntityRoutes";
import { useHistory } from "react-router-dom";
import { EntityByEntityTypeId, GenericEntityConstantsEntities } from "../../../api/GenericConstants";

interface EntityTableEditButtonProps<EntityTypeId extends GenericEntityConstantsEntities> {
  entityConstants: EntityConstants;
  routes: Routes;
  permissions: IGenericEntityPermissions<EntityTypeId>;
  selection: Set<EntityByEntityTypeId<EntityTypeId>["id"]>;
  selectionPermissions: IEntityPermissions | undefined;
  onClick?: React.DOMAttributes<HTMLButtonElement>["onClick"];
}

/**
 * A button component for editing an entity.
 * @author @CorradoSurmanowicz
 * @template Entity - The type of the entity.
 * @param {EntityTableEditButtonProps<EntityTypeId>} props - The properties for the EntityEditButton component.
 * @param {EntityConstants} props.entityConstants - Constants related to the entity.
 * @param {Routes} props.routes - The routes for navigation.
 * @param {Permissions} props.permissions - The permissions for the entity.
 * @param {Set<Entity>} props.selection - The selected entities.
 * @param {SelectionPermissions} props.selectionPermissions - The permissions for the selected entities.
 * @param {() => void} [props.onClick] - Optional click handler for the button.
 * @returns {JSX.Element} The rendered button component.
 */
export const EntityTableEditButton = <EntityTypeId extends GenericEntityConstantsEntities>({
  entityConstants,
  routes,
  permissions,
  selection,
  selectionPermissions,
  onClick,
}: EntityTableEditButtonProps<EntityTypeId>) => {
  const history = useHistory();
  return (
    <button
      className="btn btn-default"
      title={`${
        selection.size === 0
          ? `Select ${entityConstants.entitySingular} to edit`
          : selection.size === 1
          ? `Edit the selected ${entityConstants.entitySingular}`
          : ""
      }`}
      disabled={selection.size !== 1 || !permissions.canBulkEdit(selectionPermissions)}
      onClick={onClick ? onClick : () => history.push(routes.getEditLink(+Array.from(selection)[0]))}
    >
      <LucideIcon name="square-pen" /> Edit
    </button>
  );
};
