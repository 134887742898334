import { useQuery } from "@tanstack/react-query";
import React from "react";
import { useParams } from "react-router-dom";
import { availableGroups } from "../../api/Login";
import { LoadingWrapper } from "../../common/LoadingWrapper";
import { PageNotFound } from "../common/PageNotFound/PageNotFound";
interface GroupRoutingParams {
  group: string;
}

interface GroupProviderProps {
  children: ({ group }: { group: string }) => React.ReactNode;
}
/**
 * GroupProvider component is responsible for fetching and providing group data to its children.
 * It uses the `useParams` hook to get the current group from the URL parameters and the `useQuery` hook
 * to fetch the available groups.
 * @author @CorradoSurmanowicz
 * @param {GroupProviderProps} props - The properties for the GroupProvider component.
 * @param {React.ReactNode} props.children - The child components that will receive the group data.
 *
 * @returns {JSX.Element} The rendered component, which includes a LoadingWrapper that handles the loading state,
 * and either the children components with the group data or a PageNotFound component if the group is not found.
 */
export const GroupProvider = ({ children }: GroupProviderProps) => {
  const { group } = useParams<GroupRoutingParams>();
  const groupsQuery = useQuery({
    queryKey: ["groups"],
    queryFn: async ({ signal }) => await availableGroups(signal),
    cacheTime: Infinity,
    staleTime: Infinity,
  });

  const { data: groups, status: groupsStatus, fetchStatus: groupsFetchStatus } = groupsQuery;

  return (
    <LoadingWrapper status={groupsStatus} fetchStatus={groupsFetchStatus}>
      {!Array.isArray(groups) || !groups.map((x) => x.name).includes(group) ? <PageNotFound /> : children({ group })}
    </LoadingWrapper>
  );
};
