import { EntityConstants, StringIndexedDict } from "../../../../api/GenericTypes";
import {
  GenericEntityVirtualizedEntitySelectFormFieldProps,
  GenericVirtualizedEntitySelectFormFieldControllerWrapper,
} from "../../../formfields/GenericVirtualizedSelectFormField/GenericVirtualizedEntitySelectFormFieldControllerWrapper";
// import { QuickAddWrapper } from "../../QuickAdd/common/QuickAddWrapper";
import {
  DatasetFilters,
  DatasetSortingOptionsConsts,
  DatasetSuggestions,
  datasetsConstants,
} from "../../../../api/Datasets";
import { useContext } from "react";
import { SessionContext } from "../../../contexts/SessionContext";
import { QuickAddWrapper } from "../../QuickAdd/common/QuickAddWrapper";
import { QuickAddDatasetForm } from "../../QuickAdd/forms/QuickAddDatasetForm";

export const DatasetsVirtualizedSelectForm = <Entity extends StringIndexedDict>(
  props: GenericEntityVirtualizedEntitySelectFormFieldProps<Entity, DatasetSuggestions, DatasetFilters> & {
    entityConstantsOverride?: EntityConstants;
  }
) => {
  const { session } = useContext(SessionContext);
  return (
    <GenericVirtualizedEntitySelectFormFieldControllerWrapper<Entity, DatasetSuggestions, DatasetFilters>
      {...props}
      filters={{ orderBy: "ACQUISITION_DATE_DESC", ...props.filters }}
      createModal={({ showModal, setShowModal, onCreate }) => (
        <QuickAddWrapper showModal={showModal} setShowModal={setShowModal} onCreate={onCreate}>
          {(quickAddProps) => <QuickAddDatasetForm {...quickAddProps} />}
        </QuickAddWrapper>
      )}
      entityConstants={props.entityConstantsOverride ?? datasetsConstants}
      orderByOptions={DatasetSortingOptionsConsts}
      allowCreateEntity={session?.permissions.add_datasets && props.allowCreateEntity}
    />
  );
};
