import React, { useContext } from "react";
import { GenericModal } from "../../../common/modals/Modal/GenericModal";
import { Link } from "react-router-dom";
import { SessionContext } from "../../../common/contexts/SessionContext";
import { getDetailLink } from "../../../main/Routing";
import { LucideIcon } from "../../../common/icon/LucideIcon";
import styles from "./RenderDatasetPreview.module.css";
import { Dataset as DatasetInterface } from "../../../api/Datasets";
import { DatasetsDetailView } from "../../DatasetViews";

interface RenderDatasetPreviewProps {
  datasetId: DatasetInterface["id"];
  name: DatasetInterface["name"];
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
}
export const RenderDatasetPreview = ({ datasetId, name, showModal, setShowModal }: RenderDatasetPreviewProps) => {
  const { route } = useContext(SessionContext);
  return (
    <GenericModal
      showModal={showModal}
      setShowModal={setShowModal}
      modalTitle={
        <div className="flex row-nowrap align-center gap-5" style={{ width: "100%", fontSize: "1.25em" }}>
          <LucideIcon name="activity" color={"var(--primary)"} />
          <span>{name}</span>
        </div>
      }
      modalBody={
        <div
          className="flex"
          style={{
            width: "100%",
            height: "100%",
            border: "1px solid var(--gray-300)",
            borderRadius: "4px",
            overflow: "hidden",
            position: "relative",
          }}
        >
          <DatasetsDetailView id={datasetId} viewShared />
          <Link
            className={styles.overlay}
            to={route(getDetailLink("datasets", datasetId))}
            rel="noreferrer"
            target="_blank"
          >
            {/* <div className={styles.overlay_indicator}>
              <h3 style={{ margin: 0 }}>
                <FeatherIcon name="external-link" width={30} height={30} /> Go to Dataset
              </h3>
            </div> */}
          </Link>
        </div>
      }
      modalControls={
        <div className="flex row-nowrap align-center gap-5" style={{ width: "100%" }}>
          <Link
            className="btn btn-primary"
            to={route(getDetailLink("datasets", datasetId))}
            rel="noreferrer"
            target="_blank"
            style={{ marginLeft: "auto" }}
          >
            <LucideIcon name="external-link" /> Go to Dataset
          </Link>
        </div>
      }
      dialogClassName="full-modal"
    />
  );
};
