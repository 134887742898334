import { OrganizationForm } from "./OrganizationForm";
import { EntityAddForm, EntityAddFormPage } from "../common/entity/EntityAddForm";
import { EntityDetailViewProps, EntityEditFormProps } from "../common/entity/EntityInterfaces";
import { EntityEditForm, EntityEditFormPage } from "../common/entity/EntityEditForm";
import { EntityDetailPage, EntityDetailView } from "../common/entity/EntityDetailPage";
import { OrganizationsDetail } from "./OrganizationsDetail";
import { EntityTablePage } from "../common/entity/EntityTablePage";
import { OrganizationsTable } from "./Table/OrganizationsTable";

// Add
export const OrganizationAddForm = () => {
  return <EntityAddForm entityTypeId={"organizations"}>{(props) => <OrganizationForm {...props} />}</EntityAddForm>;
};

export const OrganizationAddFormPage = () => {
  return (
    <EntityAddFormPage>
      <OrganizationAddForm />
    </EntityAddFormPage>
  );
};

// Clone
// export const OrganizationCloneForm = ({ id }: EntityCloneFormProps<Organization>) => {
//   return (
//     <EntityCloneForm<Organization>
//       id={id}
//       entityTypeId={"organizations"}
//     >
//       {(props) => <OrganizationForm {...props} />}
//     </EntityCloneForm>
//   );
// };

// export const OrganizationCloneFormPage = () => {
//   return <EntityCloneFormPage<Organization> isIntId>{(id) => <OrganizationCloneForm id={id} />}</EntityCloneFormPage>;
// };

// Edit
export const OrganizationEditForm = ({ id }: EntityEditFormProps<"organizations">) => {
  return (
    <EntityEditForm id={id} entityTypeId={"organizations"} filters={{}}>
      {(props) => <OrganizationForm {...props} />}
    </EntityEditForm>
  );
};

export const OrganizationEditFormPage = () => {
  return <EntityEditFormPage<"organizations"> isIntId>{(id) => <OrganizationEditForm id={id} />}</EntityEditFormPage>;
};

// Detail
export const OrganizationsDetailView = ({ id }: EntityDetailViewProps<"organizations">) => {
  return (
    <EntityDetailView id={id} entityTypeId={"organizations"} filters={{}}>
      {(props) => <OrganizationsDetail {...props} />}
    </EntityDetailView>
  );
};

export const OrganizationDetailsPage = () => {
  return <EntityDetailPage<"organizations"> isIntId>{(id) => <OrganizationsDetailView id={id} />}</EntityDetailPage>;
};

export const OrganizationsTablePage = () => {
  return (
    <EntityTablePage entityTypeId={"organizations"}>{(props) => <OrganizationsTable {...props} />}</EntityTablePage>
  );
};
