import { EquipmentForm } from "./EquipmentForm";
import { EntityAddForm, EntityAddFormPage } from "../common/entity/EntityAddForm";
import { EntityCloneForm, EntityCloneFormPage } from "../common/entity/EntityCloneForm";

import { EntityCloneFormProps, EntityDetailViewProps, EntityEditFormProps } from "../common/entity/EntityInterfaces";
import { EntityEditForm, EntityEditFormPage } from "../common/entity/EntityEditForm";
import { EntityDetailPage, EntityDetailView } from "../common/entity/EntityDetailPage";
import { EquipmentsDetail } from "./EquipmentsDetailView";
import { EntityTablePage } from "../common/entity/EntityTablePage";
import { EquipmentsTable } from "./Table/EquipmentsTable";

// Add
export const EquipmentAddForm = () => {
  return (
    <EntityAddForm<"equipments"> entityTypeId={"equipments"}>{(props) => <EquipmentForm {...props} />}</EntityAddForm>
  );
};

export const EquipmentAddFormPage = () => {
  return (
    <EntityAddFormPage>
      <EquipmentAddForm />
    </EntityAddFormPage>
  );
};

// Clone
export const EquipmentCloneForm = ({ id }: EntityCloneFormProps<"equipments">) => {
  return (
    <EntityCloneForm<"equipments"> id={id} entityTypeId={"equipments"}>
      {(props) => <EquipmentForm {...props} />}
    </EntityCloneForm>
  );
};

export const EquipmentCloneFormPage = () => {
  return <EntityCloneFormPage<"equipments"> isIntId>{(id) => <EquipmentCloneForm id={id} />}</EntityCloneFormPage>;
};

// Edit
export const EquipmentEditForm = ({ id }: EntityEditFormProps<"equipments">) => {
  return (
    <EntityEditForm id={id} entityTypeId={"equipments"} filters={{}}>
      {(props) => <EquipmentForm {...props} />}
    </EntityEditForm>
  );
};

export const EquipmentEditFormPage = () => {
  return <EntityEditFormPage<"equipments"> isIntId>{(id) => <EquipmentEditForm id={id} />}</EntityEditFormPage>;
};

// Detail
export const EquipmentsDetailView = ({ id }: EntityDetailViewProps<"equipments">) => {
  return (
    <EntityDetailView id={id} entityTypeId={"equipments"} filters={{}}>
      {(props) => <EquipmentsDetail {...props} />}
    </EntityDetailView>
  );
};

export const EquipmentDetailsPage = () => {
  return <EntityDetailPage<"equipments"> isIntId>{(id) => <EquipmentsDetailView id={id} />}</EntityDetailPage>;
};

export const EquipmentsTablePage = () => {
  return <EntityTablePage entityTypeId={"equipments"}>{(props) => <EquipmentsTable {...props} />}</EntityTablePage>;
};
