import { useCallback } from "react";
import { SerializedPipeTrackType } from "../../../ProcessingPipeline/PipelineTypes";
import { TrackListPatternInput } from "./TrackListPatternInput";

export const TrackPatternInput = ({
  parameterName,
  regex,
  name,
  index,
  type,
  setParameter,
  selected,
  tracks,
}: {
  parameterName: string;
  regex?: string;
  name?: string;
  index?: [number, number];
  type?: string;
  selected?: string;
  tracks?: SerializedPipeTrackType[];
  setParameter?: (parameterName: string, parameters: Record<string, any>) => void;
}) => {
  const setParameters = useCallback(
    (parameterName: string, parameters: Record<string, any>) => {
      if (setParameter) {
        const result = {
          regex: parameters?.value?.[0]?.regex,
          name: parameters?.value?.[0]?.name,
          index: parameters?.value?.[0]?.index,
          type: parameters?.value?.[0]?.type,
          selected: parameters?.value?.[0]?.selected,
        };
        setParameter(parameterName, result);
      }
      // console.log("parameters", parameters?.value?.[1]);
    },
    [setParameter]
  );

  return (
    <TrackListPatternInput
      parameterName={parameterName}
      setParameter={setParameters}
      value={
        regex || name || type || index
          ? [{ regex: regex, trackName: name, index: index, type: type, selected: selected, tracks: tracks }]
          : []
      }
    />
  );
};
