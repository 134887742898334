import { useCallback, useEffect, useMemo } from "react";
import {
  IconNMRHolder,
  IconNMRHolderFilters,
  IconNMRHolderFiltersTranslator,
  iconNMRHistoryConstants,
  iconNMRHoldersConstants,
  iconNMRStatusConstants,
} from "../../../api/IconNMR";
import { ColumnsSettings } from "../../../common/tables/ColumnsSelector/ColumnsSelector";
import {
  GenericVirtualizedTableCells,
  SortState,
} from "../../../common/tables/GenericVirtualizedTable/GenericVirtualizedTableTypes";
import { TableTabsDict } from "../../../common/tables/Tabs/TableTabsTypes";
import { useTabStore } from "../../../common/tables/Tabs/useTabStore";
import { DateTimeRenderer } from "../../../common/datetime/DateTimeFormatter";
import { NotAvailable, NotSet } from "../../../common/misc/UIconstants";
import { EntityTableTabs } from "../../../common/tables/Tabs/EntityTableTabs";
import { EntityTable } from "../../../common/tables/EntityTable/EntityTable";
import { SearchInput } from "../../../common/forms/SearchInput/SearchInput";
import styles from "../../../common/tables/GenericVirtualizedTable/commons.module.css";
import { DataSource, DataSourceFilters, dataSourceConstants } from "../../../api/DataSource";
import { IconNMRHoldersFilterForm, IconNMRHoldersFilterbar } from "./IconNMRHoldersFilterBar";
import { RenderIconNMRHolderStatus } from "../../common/IconNMRRenderer";
import { LoadingWrapper } from "../../../common/LoadingWrapper";
import { instrumentsConstants } from "../../../api/Facilities";
import { useHistory, useLocation } from "react-router-dom";
import { EntityFilterIndicator } from "../../../common/tables/EntityFilterIndicator/EntityFilterIndicator";
import TableView from "../../../common/panels/TableView/TableView";
import { useUnpaginateOrdered } from "../../../api/BaseEntityApi";
import { useQueryClient } from "@tanstack/react-query";
import { LucideIcon } from "../../../common/icon/LucideIcon";
import { ParameterFormatter } from "../../../ParameterViewer/TreeViewer/ParameterFormatter";
import {
  UseEntityTableDefaultProps,
  UseEntityTableProps,
  useGenericVirtualizedTable,
  useGenericVirtualizedTableTabs,
} from "../../../common/tables/GenericVirtualizedTable/useGenericVirtualizedTable";
import { EntityTableProps } from "../../../common/entity/EntityInterfaces";

const parameterFormatter = new ParameterFormatter();

export const switchIconNMRHoldersDefaultSortState = (
  sortState: IconNMRHolderFilters["orderBy"]
): SortState<IconNMRHolderFilters["orderBy"]> => {
  switch (sortState) {
    case "ID_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-id",
        orderBy: sortState,
      };
    case "ID_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-id",
        orderBy: sortState,
      };
    case "HOLDER_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-holder",
        orderBy: sortState,
      };
    case "HOLDER_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-holder",
        orderBy: sortState,
      };
    case "STATUS_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-status",
        orderBy: sortState,
      };
    case "STATUS_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-status",
        orderBy: sortState,
      };
    case "EXPNO_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-expno",
        orderBy: sortState,
      };
    case "EXPNO_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-expno",
        orderBy: sortState,
      };
    case "DURATION_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-duration",
        orderBy: sortState,
      };
    case "DURATION_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-duration",
        orderBy: sortState,
      };
    case "USER_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-user",
        orderBy: sortState,
      };
    case "USER_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-user",
        orderBy: sortState,
      };
    case "MODIFIED_ON_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-modifiedOn",
        orderBy: sortState,
      };
    case "MODIFIED_ON_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-modifiedOn",
        orderBy: sortState,
      };
    case "QUEUE_POSITION_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-queuePosition",
        orderBy: sortState,
      };
    case "QUEUE_POSITION_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-queuePosition",
        orderBy: sortState,
      };
    case "DATA_SOURCE_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-dataSource",
        orderBy: sortState,
      };
    case "DATA_SOURCE_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-dataSource",
        orderBy: sortState,
      };
    case "INSTRUMENT_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-instrument",
        orderBy: sortState,
      };
    case "INSTRUMENT_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-instrument",
        orderBy: sortState,
      };
    case "METHOD_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-method",
        orderBy: sortState,
      };
    case "METHOD_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-method",
        orderBy: sortState,
      };

    default:
      return {
        sortDirection: "ASC",
        headerId: "default-holder",
        orderBy: sortState,
      };
  }
};

export const useIconNMRHoldersTableDefaults = ({ fieldLabels }: UseEntityTableDefaultProps<"iconNMRHolders">) => {
  const {
    data: dataSources,
    status,
    fetchStatus,
    error,
  } = useUnpaginateOrdered<DataSource, DataSourceFilters>(dataSourceConstants.resource, {
    types: ["IconNMR"],
    enabled: true,
  });

  const defaults: ColumnsSettings<IconNMRHolder> = useMemo(
    () => ({
      // "default-id": { pos: 0, active: false, header: fieldLabels.id },
      "default-queuePosition": { pos: 1, active: true, header: fieldLabels.queuePosition, property: "queuePosition" },
      "default-holder": { pos: 2, active: true, header: fieldLabels.holder, property: "holder" },
      "default-status": { pos: 3, active: true, header: fieldLabels.status, property: "status" },
      "default-dataset": { pos: 4, active: true, header: fieldLabels.dataset, property: "dataset" },
      "default-expno": { pos: 5, active: true, header: fieldLabels.expno, property: "expno" },
      "default-experiment": { pos: 6, active: true, header: fieldLabels.experiment, property: "experiment" },
      "default-duration": { pos: 7, active: true, header: fieldLabels.duration, property: "duration" },
      "default-instrument": { pos: 8, active: true, header: fieldLabels.instrument, property: "dataSource.instrument" },
      "default-method": { pos: 9, active: false, header: fieldLabels.method, property: "dataSource.method" },
      "default-dataSource": { pos: 10, active: false, header: fieldLabels.dataSource, property: "dataSource" },
      "default-solvent": { pos: 11, active: false, header: fieldLabels.solvent, property: "solvent" },
      "default-path": { pos: 12, active: false, header: fieldLabels.path, property: "path" },
      "default-user": { pos: 13, active: false, header: fieldLabels.user, property: "user" },
      // "default-modifiedOn": { pos: 13, active: true, header: fieldLabels.modifiedOn },
    }),
    [fieldLabels]
  );

  const tabStoreDefaults = useMemo(() => {
    let def: TableTabsDict<IconNMRHolder, IconNMRHolderFilters, IconNMRHoldersFilterForm> = {
      default: {
        tabId: "default",
        type: "fixed",
        label: "All",
        title: "All",
        icon: "house",
        align: "left",
        xPos: 0,
        settings: {
          columnSettings: {},
          columnWidths: {},
          filters: { orderBy: "INSTRUMENT_ASC", statuses: ["Running", "Submitted"] },
          sidebarFilters: {},
        },
        forcedSettings: {
          columnSettings: {},
          columnWidths: {},
          filters: {
            statuses: ["Running", "Submitted"],
          },
          sidebarFilters: {},
        },
      },
    };
    if (dataSources) {
      dataSources.forEach((d, i) => {
        if (!!d.instrument && d.enabled)
          def[`instrument-id-${d.instrument.id}`] = {
            tabId: `instrument-id-${d.instrument.id}`,
            type: "fixed",
            label: d.instrument?.name ?? d.name,
            title: d.instrument?.name ?? d.name,
            icon: instrumentsConstants.icon,
            align: "left",
            xPos: 2 + i,
            settings: {
              columnSettings: { ...defaults, "default-instrument": { pos: 8, active: false, header: "Instrument" } },
              columnWidths: {},
              filters: {
                instrumentIds: [d.instrument.id],
                statuses: ["Running", "Submitted"],
                orderBy: "QUEUE_POSITION_ASC",
              },
              sidebarFilters: {
                instrument: [d.instrument],
                status: [
                  {
                    id: "Submitted",
                    name: "Submitted",
                  },
                  {
                    id: "Running",
                    name: "Running",
                  },
                ],
              },
            },
            forcedSettings: {
              columnSettings: {},
              columnWidths: {},
              filters: { instrumentIds: [d.instrument.id], statuses: ["Running", "Submitted"] },
              sidebarFilters: {},
            },
          };
      });
    }
    return def as TableTabsDict<IconNMRHolder, IconNMRHolderFilters, IconNMRHoldersFilterForm>;
  }, [dataSources, defaults]);

  return { defaults, tabStoreDefaults, status, fetchStatus, error };
};

export const useIconNMRHoldersTableColumns = ({
  entityConstants,
  fieldLabels,
  sort,
  setSort,
}: UseEntityTableProps<"iconNMRHolders">) => {
  const columns = useMemo(() => {
    let headers: GenericVirtualizedTableCells<IconNMRHolder> = [
      {
        id: "default-id",
        Header: fieldLabels.id,
        accessor: (row) => <span style={{ color: "var(--gray-400)" }}>{row.id}</span>,
        width: 120,
        minWidth: 120,
        align: "center",
        sortingFn: (id) => {
          if (sort.headerId === id) {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "ID_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "ID_ASC" }));
            }
          } else {
            setSort({ headerId: id, sortDirection: "ASC", orderBy: "ID_ASC" });
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
      {
        Header: fieldLabels.queuePosition,
        id: "default-queuePosition",
        accessor: (row) => (
          <div className={`${styles.container_ellipsis}`}>
            <span>
              {row.queuePosition !== null && row.queuePosition !== undefined ? row.queuePosition + 1 : NotSet}
            </span>
          </div>
        ),
        minWidth: 120,
        width: 120,
        align: "left",
        sortingFn: (id) => {
          if (sort.headerId === id) {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "QUEUE_POSITION_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "QUEUE_POSITION_ASC" }));
            }
          } else {
            setSort({ headerId: id, sortDirection: "ASC", orderBy: "QUEUE_POSITION_ASC" });
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
      {
        Header: fieldLabels.status,
        id: "default-status",
        accessor: (row) => (
          <div className={`${styles.container_ellipsis}`}>
            <span>{row.status ? <RenderIconNMRHolderStatus status={row.status} /> : NotSet}</span>
          </div>
        ),
        minWidth: 120,
        width: 120,
        align: "left",
        sortingFn: (id) => {
          if (sort.headerId === id) {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "STATUS_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "STATUS_ASC" }));
            }
          } else {
            setSort({ headerId: id, sortDirection: "ASC", orderBy: "STATUS_ASC" });
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
      {
        Header: fieldLabels.solvent,
        id: "default-solvent",
        accessor: (row) => (
          <div className={`${styles.container_ellipsis}`}>
            <span>{row.solvent ?? NotSet}</span>
          </div>
        ),
        minWidth: 120,
        width: 250,
        align: "left",
      },
      {
        Header: fieldLabels.experiment,
        id: "default-experiment",
        accessor: (row) => (
          <div className={`${styles.container_ellipsis}`}>
            <span>{row.experiment ?? NotSet}</span>
          </div>
        ),
        minWidth: 120,
        width: 250,
        align: "left",
      },
      {
        Header: fieldLabels.expno,
        id: "default-expno",
        accessor: (row) => (
          <div className={`${styles.container_ellipsis}`}>
            <span>{row.expno ?? NotSet}</span>
          </div>
        ),
        minWidth: 120,
        width: 120,
        align: "left",
        sortingFn: (id) => {
          if (sort.headerId === id) {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "EXPNO_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "EXPNO_ASC" }));
            }
          } else {
            setSort({ headerId: id, sortDirection: "ASC", orderBy: "EXPNO_ASC" });
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
      {
        Header: fieldLabels.duration,
        id: "default-duration",
        accessor: (row) => (
          <div className={`${styles.container_ellipsis}`}>
            <span> {row.duration ? parameterFormatter.formatDuration(row.duration, 1).join(" ") : NotAvailable}</span>
          </div>
        ),
        minWidth: 120,
        width: 120,
        align: "left",
        sortingFn: (id) => {
          if (sort.headerId === id) {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "DURATION_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "DURATION_ASC" }));
            }
          } else {
            setSort({ headerId: id, sortDirection: "ASC", orderBy: "DURATION_ASC" });
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
      {
        Header: fieldLabels.instrument,
        id: "default-instrument",
        accessor: (row) => (
          <div className={`${styles.container_ellipsis}`}>
            <span>{row.dataSource.instrument.name}</span>
          </div>
        ),
        minWidth: 120,
        width: 200,
        align: "left",
        sortingFn: (id) => {
          if (sort.headerId === id) {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "INSTRUMENT_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "INSTRUMENT_ASC" }));
            }
          } else {
            setSort({ headerId: id, sortDirection: "ASC", orderBy: "INSTRUMENT_ASC" });
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
      {
        Header: fieldLabels.method,
        id: "default-method",
        accessor: (row) => (
          <div className={`${styles.container_ellipsis}`}>
            <span>{row.dataSource.method.name}</span>
          </div>
        ),
        minWidth: 120,
        width: 120,
        align: "left",
        sortingFn: (id) => {
          if (sort.headerId === id) {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "METHOD_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "METHOD_ASC" }));
            }
          } else {
            setSort({ headerId: id, sortDirection: "ASC", orderBy: "METHOD_ASC" });
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
      {
        Header: fieldLabels.holder,
        id: "default-holder",
        accessor: (row) => (
          <div className={`${styles.container_ellipsis}`}>
            <span>{row.holder ?? NotSet}</span>
          </div>
        ),
        minWidth: 120,
        width: 120,
        align: "left",
        sortingFn: (id) => {
          if (sort.headerId === id) {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "HOLDER_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "HOLDER_ASC" }));
            }
          } else {
            setSort({ headerId: id, sortDirection: "ASC", orderBy: "HOLDER_ASC" });
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
      {
        Header: fieldLabels.dataset,
        id: "default-dataset",
        accessor: (row) => (
          <div className={`${styles.container_ellipsis}`}>
            <span>{row.dataset ?? NotSet}</span>
          </div>
        ),
        minWidth: 120,
        width: 250,
        align: "left",
      },
      {
        Header: fieldLabels.path,
        id: "default-path",
        accessor: (row) => (
          <div className={`${styles.container_ellipsis}`}>
            <span>{row.path ?? NotSet}</span>
          </div>
        ),
        minWidth: 120,
        width: 200,
        align: "left",
      },
      {
        Header: fieldLabels.user,
        id: "default-user",
        accessor: (row) => (
          <div className={`${styles.container_ellipsis}`}>
            <span>{row.user ?? NotSet}</span>
          </div>
        ),
        minWidth: 120,
        width: 150,
        align: "left",
        sortingFn: (id) => {
          if (sort.headerId === id) {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "USER_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "USER_ASC" }));
            }
          } else {
            setSort({ headerId: id, sortDirection: "ASC", orderBy: "USER_ASC" });
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
      {
        Header: fieldLabels.modifiedOn,
        id: "default-modifiedOn",
        accessor: (row) => <DateTimeRenderer date={row.modifiedOn} includeElapsed={false} />,
        minWidth: 120,
        width: 180,
        align: "left",
        sortingFn: (id) => {
          if (sort.headerId === id) {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "MODIFIED_ON_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "MODIFIED_ON_ASC" }));
            }
          } else {
            setSort({ headerId: id, sortDirection: "ASC", orderBy: "MODIFIED_ON_ASC" });
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
      {
        Header: fieldLabels.dataSource,
        id: "default-dataSource",
        accessor: (row) => (
          <div className={`${styles.container_ellipsis}`}>
            <span>
              <LucideIcon name={dataSourceConstants.icon} /> {row.dataSource.name}
            </span>
          </div>
        ),
        minWidth: 120,
        width: 200,
        align: "left",
        sortingFn: (id) => {
          if (sort.headerId === id) {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "DATA_SOURCE_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "DATA_SOURCE_ASC" }));
            }
          } else {
            setSort({ headerId: id, sortDirection: "ASC", orderBy: "DATA_SOURCE_ASC" });
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
    ];

    return headers;
  }, [
    fieldLabels.dataSource,
    fieldLabels.dataset,
    fieldLabels.duration,
    fieldLabels.experiment,
    fieldLabels.expno,
    fieldLabels.holder,
    fieldLabels.id,
    fieldLabels.instrument,
    fieldLabels.method,
    fieldLabels.modifiedOn,
    fieldLabels.path,
    fieldLabels.queuePosition,
    fieldLabels.solvent,
    fieldLabels.status,
    fieldLabels.user,
    setSort,
    sort.headerId,
    sort.sortDirection,
  ]);
  return { columns };
};

export const IconNMRHoldersTableWrapper = ({
  entityApi,
  entityConstants,
  fieldLabels,
  permissions,
  routes,
}: EntityTableProps<"iconNMRHolders">) => {
  const { defaults, tabStoreDefaults, status, fetchStatus, error } = useIconNMRHoldersTableDefaults({ fieldLabels });

  if (!tabStoreDefaults)
    return (
      <LoadingWrapper status={status} fetchStatus={fetchStatus} error={error}>
        Fetching IconNMR instruments...
      </LoadingWrapper>
    );
  return (
    <IconNMRHoldersTable
      defaults={defaults}
      tabStoreDefaults={tabStoreDefaults}
      entityApi={entityApi}
      entityConstants={entityConstants}
      fieldLabels={fieldLabels}
      permissions={permissions}
      routes={routes}
    />
  );
};

export const IconNMRHoldersTable = ({
  entityApi,
  entityConstants,
  fieldLabels,
  permissions,
  routes,
  defaults,
  tabStoreDefaults,
}: {
  defaults: ColumnsSettings<IconNMRHolder>;
  tabStoreDefaults: TableTabsDict<IconNMRHolder, IconNMRHolderFilters, IconNMRHoldersFilterForm>;
} & EntityTableProps<"iconNMRHolders">) => {
  const history = useHistory();

  const { resultsCount, onCountChange, onSelectionChange } = useGenericVirtualizedTable<IconNMRHolder>();

  const {
    filters,
    sidebarFilters,
    forcedFilters,
    columnSetting,
    columnWidths,
    customTabs,
    fixedTabs,
    dispatchTabStore,
    currentTab,
    tabsLoading,
    tabsModified,
  } = useTabStore<IconNMRHolder, IconNMRHolderFilters, IconNMRHoldersFilterForm>({
    resource: entityConstants.resource,
    defaults: tabStoreDefaults,
    // initialState: initialState,
  });

  const { functionRef, sort, setSort, searchValue, setSearchValue, onTabChange } = useGenericVirtualizedTableTabs({
    tabsLoading,
    filters,
    switchSortState: switchIconNMRHoldersDefaultSortState,
    dispatchTabStore,
  });

  const { columns } = useIconNMRHoldersTableColumns({ entityConstants, fieldLabels, sort, setSort });

  // TODO: remove once generic in tab store
  const { search: queryString } = useLocation();

  useEffect(() => {
    if (!tabsLoading) {
      let params = Object.fromEntries(new URLSearchParams(queryString));
      if ("instrumentId" in params && !!params["instrumentId"])
        (async () => await onTabChange(`instrument-id-${params["instrumentId"]}`))();
    }
  }, [history, onTabChange, queryString, tabsLoading]);

  const memoizedFilters = useMemo(() => {
    return { ...filters, ...forcedFilters };
  }, [filters, forcedFilters]);

  const queryClient = useQueryClient();

  const refetchData = useCallback(() => {
    queryClient.invalidateQueries({ queryKey: [iconNMRStatusConstants.resource] });
    queryClient.invalidateQueries({ queryKey: [entityConstants.resource] });
    queryClient.invalidateQueries({ queryKey: [iconNMRHistoryConstants.resource] });
  }, [entityConstants.resource, queryClient]);

  useEffect(() => {
    const interval = setInterval(() => {
      refetchData();
    }, 60000);
    return () => clearInterval(interval);
  }, [refetchData]);

  return (
    <TableView style={{ marginTop: 0, paddingTop: 0 }}>
      <TableView.Body>
        <TableView.Body.Sidebar defaultSize={0}>
          <IconNMRHoldersFilterbar
            initialValues={sidebarFilters}
            dispatchTabStore={dispatchTabStore}
            tabsLoading={tabsLoading}
            currentTab={currentTab}
            hideReset={currentTab !== "default"}
          />
        </TableView.Body.Sidebar>
        <TableView.Body.Content>
          <EntityTableTabs
            currentTab={currentTab}
            onTabChange={onTabChange}
            fixedTabs={fixedTabs}
            filters={filters}
            sidebarFilters={sidebarFilters}
            columnSetting={columnSetting}
            columnWidths={columnWidths}
            customTabs={customTabs}
            dispatchTabStore={dispatchTabStore}
            tabsLoading={tabsLoading}
            tabsModified={tabsModified}
            showAddTab={false}
          />
          <EntityTable>
            <EntityTable.Controls
              style={{
                borderTop: "0px",
                borderRadius: "0px",
              }}
            >
              <EntityFilterIndicator<IconNMRHolder, IconNMRHolderFilters>
                filters={filters}
                excludeFilters={{
                  statuses: () => false,
                  instrumentIds: (value) => (currentTab !== "default" ? false : !!value?.length),
                }}
                translatorConsts={IconNMRHolderFiltersTranslator}
                showAlways
              />

              <SearchInput searchValue={searchValue} setSearchValue={setSearchValue} placeholder="Search" />

              <div className="flex align-center gap-5">
                <span style={{ color: "var(--gray-300)", paddingRight: "5px" }}>|</span>
                <span style={{ color: "var(--gray-500)", whiteSpace: "nowrap" }}>
                  Results with <LucideIcon name="filter" />:
                </span>
                <span className="badge">{resultsCount ?? NotAvailable}</span>
                <span style={{ color: "var(--gray-300)", paddingLeft: "5px" }}>|</span>
              </div>
              <button onClick={refetchData} className="btn btn-ghost-primary btn-xs flex align-center gap-5">
                <LucideIcon name="refresh-cw" />
                Refresh
              </button>
            </EntityTable.Controls>
            <EntityTable.Body<IconNMRHolder, IconNMRHolderFilters>
              functionRef={functionRef}
              entityConstants={iconNMRHoldersConstants}
              filters={memoizedFilters}
              columns={columns}
              columnSelect
              columnSetting={columnSetting}
              columnWidths={columnWidths}
              defaultColumnSettings={defaults}
              dispatchTabStore={dispatchTabStore}
              setResultsCount={onCountChange}
              onSelectionChange={onSelectionChange}
              //   onSelectionPermissions={onSelectionPermissions}
              //   showPermissionColumn
              loading={tabsLoading}
              disableCheckboxes
              onRowClick={() => {}}
              linkTo={() => "#"}
            />
          </EntityTable>
        </TableView.Body.Content>
      </TableView.Body>
    </TableView>
  );
};
