import { MutableRefObject, ReactNode, useEffect, useMemo, useRef, useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { Link } from "react-router-dom";
import { useEntityDetail, useRestoreMutation } from "../../../api/BaseEntityApi";
import { MoreDropdown } from "../../../common/buttons/MoreDropdown/MoreDropdown";
import { DateTimeRenderer } from "../../../common/datetime/DateTimeFormatter";
import useIntersectionObserver from "../../../common/helperfunctions/useIntersectionObserver";
import { LucideIcon } from "../../../common/icon/LucideIcon";
import { ELNDeleteConfirmationModal } from "../../ELNCreateOrEditForms/ELNDeleteConfirmationModal";
import { useELNRoutes } from "../../ELNRouter/useELNRoutes";
import { Modal } from "../../common/ELNModal/ELNModal";
import { EntityHistory } from "../../common/EntityHistory/EntityHistory";
import { PrintComponentsHandler } from "../../common/PrintComponentsHandler/PrintComponentsHandler";
import { TextEditor } from "../../common/TextEditor/TextEditor";
import { LAB_NOTEBOOK_TOC_QUERY_KEY } from "../../common/tiles/LabNotebookTocTile/LabNotebookTocTile";
import { LabNotebookEntry, LabNotebookEntryFilters } from "../../types/LabNotebookEntry";
import styles from "./LabNotebookEntryViewOnly.module.css";
import { QuickEditWrapper } from "../../../common/forms/QuickEdit/common/QuickEditWrapper";
import { QuickEditLabNotebookEntryForm } from "../../../common/forms/QuickEdit/forms/QuickEditLabNotebookEntry";
import { GetPersons } from "../../../common/misc/EntityRenders/EntityRenderer";
import { getNotebookEntryStringLabel } from "../LabNotebookEntry";

export interface LabNotebookEntryViewOnlyPageControls {
  onClick: (e: any) => void;
  icon?: string;
  label?: string;
  tooltip?: string;
  color?:
    | "primary"
    | "secondary"
    | "warning"
    | "danger"
    | "soft-primary"
    | "soft-secondary"
    | "soft-warning"
    | "soft-danger"
    | "ghost-primary"
    | "ghost-secondary"
    | "ghost-warning"
    | "ghost-danger";
}

export const LabNotebookEntryViewOnly = ({
  entry,
  labNotebookExperimentId,
  remirrorContextRef,
  ignoreIntersectionObserver = false,
  printMode = false,
  useEntry = false,
  showModifiedState = false,
  showViewButton,
  overlay,
  ignoreStickyStyles = false,
}: {
  entry?: LabNotebookEntry;
  labNotebookExperimentId?: number;
  remirrorContextRef?: MutableRefObject<any>;
  ignoreIntersectionObserver?: boolean;
  printMode?: boolean;
  useEntry?: boolean;
  showModifiedState?: boolean;
  showViewButton?: boolean;
  overlay?: ReactNode;
  ignoreStickyStyles?: boolean;
}) => {
  const { setELNRoute, getELNRoute } = useELNRoutes();
  const queryClient = useQueryClient();

  const [showEditLabNotebookEntryModal, setShowEditLabNotebookEntryModal] = useState<boolean>(false);
  const [showDeleteLabNotebookEntryModal, setShowDeleteLabNotebookEntryModal] = useState<boolean>(false);
  const [printLabNotebookEntry, setPrintLabNotebookEntry] = useState<boolean>(false);
  const [showLabNotebookEntryHistory, setShowLabNotebookEntryHistory] = useState<boolean>(false);
  const [headerIsPinned, setHeaderIsPinned] = useState<boolean>(false);

  const { mutate } = useRestoreMutation("lab_notebook_entries");

  const { data } = useEntityDetail<LabNotebookEntry, LabNotebookEntryFilters>(
    "lab_notebook_entries",
    entry?.id || 0,
    { includeContent: true },
    {
      enabled: entry?.id !== undefined && !useEntry,
    }
  );

  const currentEntry = useMemo(() => {
    if (useEntry) return entry;
    return data;
  }, [data, entry, useEntry]);

  const ref = useRef<HTMLDivElement>(null);
  const observer = useIntersectionObserver(ref, { threshold: [1] });

  useEffect(() => {
    if (observer && observer.intersectionRatio < 1) {
      setHeaderIsPinned(true);
    } else {
      setHeaderIsPinned(false);
    }
  }, [observer]);

  const header = useMemo(() => {
    if (!currentEntry) return null;
    return (
      <div
        className={styles.pageHeader}
        ref={ref}
        style={
          headerIsPinned && !printMode && !ignoreStickyStyles
            ? {
                boxShadow: "1px 1px 1px 1px var(--gray-300)",
                borderBottomLeftRadius: "5px",
                borderBottomRightRadius: "5px",
                backgroundColor: "var(--gray-50)",
                padding: "2px 10px",
              }
            : {}
        }
      >
        <div
          style={
            !!printMode
              ? { display: "block", overflow: "hidden", alignItems: "center" }
              : {
                  display: "grid",
                  gridTemplateColumns: "1fr max-content 1fr max-content 1fr",
                  overflow: "hidden",
                  alignItems: "center",
                }
          }
        >
          <div
            style={{
              paddingLeft: 0,
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
            title={currentEntry.labNotebook.name}
          >
            <LucideIcon
              name={currentEntry.labNotebook.status === "ACTIVE" ? "book-open" : "book"}
              color="var(--primary)"
            />{" "}
            {currentEntry.labNotebook.name}
          </div>
          {!printMode && (
            <div>
              <LucideIcon name="chevron-right" />
            </div>
          )}
          <div
            style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}
            title={currentEntry.labNotebookExperiment.name}
          >
            <LucideIcon name="file-text" color="var(--secondary)" /> {currentEntry.labNotebookExperiment.name}
          </div>
          {!printMode && (
            <div>
              <LucideIcon name="chevron-right" />
            </div>
          )}
          <div
            style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}
            title={getNotebookEntryStringLabel({ entry: currentEntry })}
          >
            <LucideIcon name="pencil" color="var(--success)" /> {getNotebookEntryStringLabel({ entry: currentEntry })}
            {entry?.isDeleted && (
              <>
                {" "}
                <LucideIcon name="trash" color="var(--warning)" style={{ margin: 0 }} />
              </>
            )}
          </div>
        </div>
        {!printMode && (
          <div className="flex align-center gap-5">
            {showViewButton && (
              <button
                className="btn btn-ghost-secondary btn-xs"
                data-toggle="tooltip"
                title="View entry"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setELNRoute({
                    labNotebookId: currentEntry.labNotebook.id,
                    labNotebookExperimentId: currentEntry.labNotebookExperiment.id,
                    labNotebookEntryId: currentEntry.id,
                    mode: "view",
                    filters: currentEntry.isDeleted ? { includeSoftDeleted: true } : undefined,
                  });
                }}
              >
                <LucideIcon name="maximize" />
                <span> View</span>
              </button>
            )}
            <button
              disabled={
                !currentEntry.permissions?.edit ||
                currentEntry.labNotebook.status === "CLOSED" ||
                currentEntry.isDeleted
              }
              className="btn btn-default btn-xs"
              data-toggle="tooltip"
              title={
                !currentEntry.permissions?.edit ||
                currentEntry.labNotebook.status === "CLOSED" ||
                currentEntry.isDeleted
                  ? "No permissions to edit this entry"
                  : "Edit entry content"
              }
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setELNRoute({
                  labNotebookId: currentEntry.labNotebook.id,
                  labNotebookExperimentId: currentEntry.labNotebookExperiment.id,
                  labNotebookEntryId: currentEntry.id,
                  mode: "edit",
                });
              }}
            >
              <LucideIcon name="square-pen" />
              <span> Edit</span>
            </button>
            <MoreDropdown
              btn="btn btn-ghost-secondary btn-xs"
              icon={<LucideIcon name="ellipsis-vertical" />}
              direction="down"
              drop="right"
            >
              {entry && !entry?.isDeleted && (
                <button
                  disabled={
                    !currentEntry.permissions?.edit ||
                    currentEntry.labNotebook.status === "CLOSED" ||
                    currentEntry.isDeleted
                  }
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setShowEditLabNotebookEntryModal(true);
                  }}
                  style={{ width: "100%", textAlign: "left", padding: "5px 10px" }}
                  className="btn btn-ghost-secondary btn-xs"
                >
                  <LucideIcon name="square-pen" /> Edit entry details
                </button>
              )}
              <button
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setPrintLabNotebookEntry(true);
                }}
                style={{ width: "100%", textAlign: "left", padding: "5px 10px" }}
                className="btn btn-ghost-secondary btn-xs"
              >
                <LucideIcon name="printer" /> Print entry
              </button>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setShowLabNotebookEntryHistory(true);
                }}
                style={{ width: "100%", textAlign: "left", padding: "5px 10px" }}
                className="btn btn-ghost-secondary btn-xs"
              >
                <LucideIcon name="clock" /> Show entry history
              </button>
              {entry && entry.isDeleted ? (
                <>
                  <button
                    disabled={!currentEntry.permissions?.edit || currentEntry.labNotebook.status === "CLOSED"}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      mutate(
                        { id: entry.id },
                        {
                          onSuccess: () => {
                            queryClient.invalidateQueries([LAB_NOTEBOOK_TOC_QUERY_KEY]);
                          },
                        }
                      );
                    }}
                    style={{ width: "100%", textAlign: "left", padding: "5px 10px" }}
                    className="btn btn-ghost-success btn-xs"
                  >
                    <LucideIcon name="archive-restore" /> Restore entry
                  </button>
                </>
              ) : (
                <button
                  disabled={!currentEntry.permissions?.edit || currentEntry.labNotebook.status === "CLOSED"}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setShowDeleteLabNotebookEntryModal(true);
                  }}
                  style={{ width: "100%", textAlign: "left", padding: "5px 10px" }}
                  className="btn btn-ghost-warning btn-xs btn-block"
                >
                  <LucideIcon name="trash" /> Trash entry
                </button>
              )}
            </MoreDropdown>
          </div>
        )}
      </div>
    );
  }, [
    currentEntry,
    entry,
    headerIsPinned,
    ignoreStickyStyles,
    mutate,
    printMode,
    queryClient,
    setELNRoute,
    showViewButton,
  ]);

  const printModal = useMemo(() => {
    if (!currentEntry) return null;
    return (
      <Modal isOpen={printLabNotebookEntry} onClose={() => setPrintLabNotebookEntry(false)}>
        <PrintComponentsHandler
          onAfterPrint={() => setPrintLabNotebookEntry(false)}
          filter={{ ids: [currentEntry.id] }}
          documentTitle={`${currentEntry.labNotebook.name.replaceAll(
            " ",
            "_"
          )}_${currentEntry.labNotebookExperiment.name.replaceAll(" ", "_")}_${
            currentEntry.entryDate
          }_${new Date().toISOString()}`}
        />
      </Modal>
    );
  }, [currentEntry, printLabNotebookEntry]);

  if (!currentEntry) return null;
  return (
    <>
      <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
        <div id={`ELN_ENTRY_${currentEntry.id}`} className={styles.dinA4Paper}>
          {printMode ? (
            <Link
              to={getELNRoute({
                labNotebookId: currentEntry.labNotebook.id,
                labNotebookExperimentId: currentEntry.labNotebookExperiment.id,
                labNotebookEntryId: currentEntry.id,
              })}
              style={{ textDecoration: "none", pointerEvents: "none" }}
            >
              {header}
            </Link>
          ) : (
            header
          )}
          {entry && showModifiedState && (
            <div
              className="flex align-center gap-5"
              style={{
                width: "100%",
                flexWrap: "wrap",
                fontSize: "12px",
              }}
            >
              <div className="flex align-center gap-5" style={{ maxWidth: "max-content", width: "100%" }}>
                <LucideIcon name="save" color="var(--gray-600)" />
                <DateTimeRenderer date={entry.modifiedOn} includeElapsed />
              </div>
              <div className="flex row-nowrap align-center gap-5">
                by <GetPersons persons={[entry.modifiedBy]} />
              </div>
            </div>
          )}
          <hr style={{ margin: "5px 0", marginBottom: "10px" }} />
          <div style={{ position: "relative" }}>
            {!!overlay && (
              <div style={{ position: "absolute", width: "100%", height: "100%", zIndex: 1 }}>{overlay}</div>
            )}
            <TextEditor
              editable={false}
              remirrorContextRef={remirrorContextRef}
              initialContent={currentEntry.content}
              save={() => {}}
              ignoreIntersectionObserver={ignoreIntersectionObserver}
            />
          </div>
        </div>
      </div>

      <>
        {/* Edit */}
        {showEditLabNotebookEntryModal && (
          <QuickEditWrapper<LabNotebookEntry>
            showModal={showEditLabNotebookEntryModal}
            setShowModal={setShowEditLabNotebookEntryModal}
            onEdit={(editedLabNotebook) => {
              queryClient.invalidateQueries({ queryKey: [LAB_NOTEBOOK_TOC_QUERY_KEY] });
              queryClient.invalidateQueries({ queryKey: ["lab_notebook_entries"] });
            }}
          >
            {(props) => <QuickEditLabNotebookEntryForm {...props} initialValues={currentEntry} />}
          </QuickEditWrapper>
        )}

        {/* Delete */}
        <ELNDeleteConfirmationModal
          isOpen={showDeleteLabNotebookEntryModal}
          setIsOpen={setShowDeleteLabNotebookEntryModal}
          name={getNotebookEntryStringLabel({ entry: currentEntry })}
          id={currentEntry.id}
          entityInfo={{
            "Entry": getNotebookEntryStringLabel({ entry: currentEntry }),
            "Part of": currentEntry.labNotebook.name + " - " + currentEntry.labNotebookExperiment.name,
          }}
          resource="lab_notebook_entries"
          onDelete={() => {
            queryClient.invalidateQueries({ queryKey: [LAB_NOTEBOOK_TOC_QUERY_KEY] });
            queryClient.invalidateQueries({ queryKey: ["lab_notebooks"] });
            queryClient.invalidateQueries({ queryKey: ["lab_notebook_experiments"] });
            queryClient.invalidateQueries({ queryKey: ["lab_notebook_entries"] });
          }}
          description="Proceeding will move the selected entry into trash."
          deletePermanently={false}
        />
        {/* Print */}
        {printModal}
        {/* History */}
        <Modal isOpen={showLabNotebookEntryHistory} onClose={() => setShowLabNotebookEntryHistory(false)}>
          <div style={{ width: "90vw", height: "90vh" }}>
            <EntityHistory<LabNotebookEntry>
              resource="lab_notebook_entries"
              id={currentEntry.id}
              onRestore={() => setShowLabNotebookEntryHistory(false)}
            />
          </div>
        </Modal>
      </>
    </>
  );
};
