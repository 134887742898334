import { ProsemirrorAttributes } from "remirror";

export const toggleTimerOptions: Remirror.CommandDecoratorOptions = {};
export const deleteTimerOptions: Remirror.CommandDecoratorOptions = {};

export interface TimerOptions {
  editable: boolean;
}

export interface TimerAttributes extends ProsemirrorAttributes {
  id?: string;
  seconds?: number;
  remainingSeconds?: number;
  checkpoints?: number[];
  initiallyIsOpen?: boolean;
}
