import produce from "immer";
import { useCallback, useEffect, useState } from "react";
import { useResizeDetector } from "react-resize-detector";

import { viewerTrackParameterType, viewPortType } from "../ViewerLayout/ViewerLayoutTypes";
import {
    annotationType,
    sequenceTrackType,
    trackParameterList,
    trackType,
    viewerModeType
} from "../ViewerLayout/ViewerTypes";
import { Sequence } from "./Sequence";
import styles from "./SequenceComponent.module.css";
import { ViewerNavigation } from "./ViewerNavigation";

export type searchType = {
  searchSequence: string;
  caseSensitive: boolean;
  antisense: boolean;
  count: number;
};

export type viewerStateType = {
  sequenceSearch: any;
  // changeDecoration: (decoration: Record<string, boolean>) => void;
  showORFLetter: boolean;
  showORF: boolean;
  showRestrictase: boolean;
  showMarker: boolean;
  sequenceType: sequenceTrackType;
  selectedNumber: number;
  showAntisense: boolean;
  colorByBase: boolean;
  search: searchType;
};

export type SequenceComponentState = {};

// export const SequenceComponent = function (props: any) {
export const SequenceComponent = function ({
  tracks,
  annotations,
  // selectedTracks,
  viewerMode,
  changeViewerTrackParameter,
  viewPort,
  onDataExport,
}: {
  tracks: Record<string, trackType>;
  changeTrackSettings: (list: { id: string; parameter: viewerTrackParameterType }[]) => void;
  // TODO: set real annotation type here
  annotations: any[];
  // selectedTracks: string[];
  viewerMode: viewerModeType;
  changeViewerTrackParameter: (list: trackParameterList) => void;
  onDataExport: (type: string, tracks: string[]) => void;
  viewPort: viewPortType;
}) {
  const { width, height, ref } = useResizeDetector();
  // const targetRef = useRef<HTMLDivElement>(null);
  // const [dimensions, setDimensions] = useState({
  //   width: "100%" as string | number,
  //   height: "100%" as string | number,
  //   percentWidth: 0,
  // });

  const [sequence, setSequence] = useState<string[]>([]);
  const [sequenceIndex, setSequenceIndex] = useState<number[]>([]);
  const [annotationList, setAnnotationList] = useState<annotationType[]>([]);
  const [viewerState, setViewerState] = useState<viewerStateType>({
    showORFLetter: true,
    showORF: true,
    showRestrictase: true,
    showMarker: true,
    showAntisense: true,
    colorByBase: false,
    selectedNumber: 0,
    sequenceSearch: undefined,
    sequenceType: "nucleotide_sequence",
    search: {
      searchSequence: "",
      antisense: false,
      caseSensitive: false,
      count: 0,
    },
  });
  // console.log("api", id, a)

  // const [viewerState, setViewerState] = useViewerState();

  // useEffect(() => {
  //   console.log("viewer state master", viewerState);
  // }, [viewerState]);

  // useLayoutEffect(() => {
  //   if (targetRef.current) {
  //     const element = targetRef.current as Element;
  //     const bound = element.getBoundingClientRect();
  //     const percentWidth = bound.width / window.innerWidth;
  //     setDimensions({ width: bound.width - 5, height: bound.height, percentWidth: percentWidth });
  //     // setDimensions(bound);
  //     console.log("seq dimensions", dimensions);
  //   }
  // }, [targetRef.current]);

  useEffect(() => {
    // const trackType = Object.fromEntries(selectedTracks.map((id) => [tracks[id].type, true]));
    const selectedTracks: string[] = [];
    const trackList = selectedTracks.filter((id) => tracks[id].parameter.visible);
    if (trackList.length < 1) return;

    const track = tracks[trackList[0]];
    // console.log(">>", track, viewerMode);

    // console.log("track", viewerState.sequenceType, "->", track.type);
    setViewerState(
      produce(viewerState, (next) => {
        next.sequenceType = track.type as sequenceTrackType;
        next.search.searchSequence = "";
        next.showAntisense = track.type === "nucleotide_sequence";
      })
    );

    if (track?.data?.y) {
      setSequenceIndex(track.data.x);
      setSequence(track.data.y);
      setAnnotationList(annotations.filter((a) => a.tracks.includes(track.id)));
    }
  }, [tracks]);

  const onExport = useCallback(
    (type: string) => {
      // console.log(`Export as '${type}'`);
      if (type === "csv")
        onDataExport(
          type,
          Object.values(tracks)
            .filter((track) => track.parameter.active && track.parameter.visible)
            .map((track) => track.id)
        );
      else if (ref) (ref.current as any).exportAs(type);
      // if (targetRef) (targetRef.current as any).exportAs(type);
    },
    [tracks]
  );

  return (
    <div id="viewer-container" className={styles.main}>
      <div className={styles.sequenceMenu}>
        {/* {Array(5)
          .fill(0)
          .map((a, i) => (
            <p key={i}>Navi {i}</p>
          ))} */}
        <ViewerNavigation viewerState={viewerState} setViewerState={setViewerState} onExport={onExport} />
      </div>
      {/* <div className={styles.sequenceViewer}>
        {Array(21).fill(0).map((a,i) => <p key={i}>Hallo {i}</p>)}
      </div> */}

      <Sequence
        width={width}
        height={height}
        // className={styles.sequenceViewer}
        // sequence={tracks?.[0] ? tracks[0].data["x"]: []}
        sequence={sequence}
        sequenceIndex={sequenceIndex}
        annotations={annotationList}
        // width={dimensions.width}
        // height={dimensions.height}
        ref={ref}
        viewerState={viewerState}
        setViewerState={setViewerState}
      />
    </div>
  );
};
