import React, { useRef, useState, useEffect, useMemo, useImperativeHandle, forwardRef } from "react";
import { SequenceViewer } from "./SequenceViewer";
// import { viewerState } from "./ViewerState";
import produce from "immer";

import "./SequenceViewer.css";
import styles from "./Sequence.module.css";
import { annotationType } from "../SpectrumViewer/ViewerTrackType";
import { sequenceAnnotationType } from "../ViewerLayout/ViewerTypes";
import { useInputDebounce } from "../ViewerUIElements/InputDebounce";
import { viewerStateType } from "./SequenceComponent";
/*
const getTextWidth = function (ctx: CanvasRenderingContext2D, text: string, font: string) {
  ctx.font = font;
  return ctx.measureText(text).width;
};
*/

// export const Sequence = (props: any) => {
export const Sequence = forwardRef((props: any, ref) => {
  const {
    sequence,
    sequenceIndex,
    width,
    height,
    annotations,
    viewerState,
    setViewerState,
  }: {
    sequence: string[];
    sequenceIndex: number[];
    width: number;
    height: number;
    annotations: annotationType[];
    viewerState: viewerStateType;
    setViewerState: (state: viewerStateType) => void;
  } = props;

  const d3Container = useRef<SVGSVGElement>(null);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const targetRef = useRef<HTMLDivElement>(null);
  const debouncedViewerWidth = useInputDebounce(dimensions.width, 500);

  useImperativeHandle(ref, () => ({
    exportAs(type: string) {
      // console.log("Graph export", type);
      // let svg = "";
      switch (type) {
        case "svg":
          const element = document.createElement("a");
          const blob = new Blob([viewer?.getSVGString() || ""], { type: "image/svg+xml;charset=utf-8" });
          element.href = URL.createObjectURL(blob);
          element.download = "graph.svg";
          document.body.appendChild(element); // Required for this to work in FireFox
          element.click();
          break;
      }
    },
  }));

  useEffect(() => {
    if (targetRef.current) {
      const element = targetRef.current as Element;
      const bound = element.getBoundingClientRect();
      setDimensions(bound);
    }
  }, [width, height]);

  const viewer: SequenceViewer | undefined = useMemo(() => {
    // console.log("Sequence viewer created", d3Container.current);
    if (d3Container.current)
      return new SequenceViewer(d3Container.current, dimensions.width > 10 ? dimensions.width : undefined);
    return undefined;
  }, [d3Container.current]);

  useEffect(() => {
    // console.log("viewer state Sequence", viewerState, viewer);
    if (viewer) {
      const next = produce(viewerState, (state: viewerStateType) => {
        state.sequenceSearch = (selection: any, caseSensitive: any, antisense: boolean) => {
          // console.log("selection", selection);
          return viewer.selectAll(selection, caseSensitive, antisense);
        };
        // state.changeDecoration = (decoration: Record<string, boolean>) => {
        //   viewer.showOrf = decoration.ORF;
        //   viewer.showRestrictase = decoration.restriction;
        //   viewer.showMarker = decoration.marker;
        // };
      });
      // console.log("selection", viewerState.sequenceSearch, "->", next.sequenceSearch);
      setViewerState(next);
      // console.log("viewerState", viewerState.sequenceSearch);
    }
  }, [viewer, viewerState.sequenceSearch]);

  useEffect(() => {
    if (viewer) {
      const r = viewer.selectAll(
        viewerState.search.searchSequence,
        viewerState.search.caseSensitive,
        viewerState.search.antisense
      );
      // console.log("search", viewerState.search, "->", r);
      if (r !== viewerState.search.count) {
        setViewerState(
          produce(viewerState, (next) => {
            next.search.count = r;
          })
        );
      }
    }
  }, [viewer, viewerState.search]);

  useEffect(() => {
    // Object.entries(viewerState).forEach(([k, v]) => {
    //   console.log("viewerState", k, v);
    // });
    if (viewer) viewer.setAttributes(viewerState);
    // if (viewer) viewer.showOrfLetter = viewerState.showORFLetter;
  }, [viewer, viewerState]);

  useEffect(() => {
    if (viewer) {
      // console.log("-- add sequence --");
      // console.log("-- add sequence --", sequence);
      viewer.setSequence(sequence, sequenceIndex);
      viewer.draw();
      // viewer.selectAll("ggtta");
      // viewer.selectAll("cgtatgttg");
      // viewer.selectAll("ctcattagg");
    }
  }, [sequence, viewer]);

  useEffect(() => {
    if (viewer) {
      // console.log("-- set annotation --");
      viewer.setAnnotation(annotations as sequenceAnnotationType[]);
      viewer.draw();
    }
  }, [annotations, viewer]);

  useEffect(() => {
    if (viewer) {
      // console.log("resize viewer", debouncedViewerWidth);
      if (debouncedViewerWidth > 10) {
        viewer.width = debouncedViewerWidth;
        viewer.draw();
      }
    }
  }, [viewer, debouncedViewerWidth]);

  return (
    <div ref={targetRef} className={styles.resizable} style={{ height: 100 + "%" }}>
      <div className={styles.main} style={{ width: dimensions.width, height: dimensions.height - 5 }}>
        <svg className={styles.sequence} ref={d3Container} />
      </div>
    </div>
  );
});
