import {
  ParameterElement,
  ParameterElementArray,
  ParameterElementFloatArray,
  ParameterElementIntArray,
  ParameterElementStr,
  ParameterElementStrArray,
} from "../../ViewerLayout/ViewerLayoutTypes";
import { ColorRectList } from "../../ViewerUIElements/ColorRect";
import styles from "./TreeViewer.module.css";

const formatMultiline = (text: string) => {
  const lines = text.split("\n");

  const result: any[] = [];
  lines.forEach((line, i) => {
    result.push(line);
    result.push(<br key={i} />);
  });

  result.pop();

  return result;
};

const spacesRe = /\s/g;

export const TreeKeyValueLeaf = ({ children }: { children: ParameterElement }) => {
  const { name, value, unit, vtype, isVisible, colors } = children;

  let formattedValue = value;

  if (formattedValue.toString().replace(spacesRe, "").length && unit && unit !== "") formattedValue += " " + unit;
  if (vtype === "str") {
    if ((children as ParameterElementStr).multiline) formattedValue = formatMultiline(String(formattedValue));
  } else {
    let delimiter = (children as ParameterElementArray)?.delimiter ?? ", ";
    if (vtype === "float[]") formattedValue = (children as ParameterElementFloatArray).value.join(delimiter);
    else if (vtype === "int[]") formattedValue = (children as ParameterElementIntArray).value.join(delimiter);
    else if (vtype === "str[]") formattedValue = (children as ParameterElementStrArray).value.join(delimiter);
  }

  return (
    <div style={{ display: isVisible ? undefined : "none" }}>
      <table>
        <tbody>
          <tr>
            <td className={styles.parameterKey}>{name}</td>
            <td className={styles.parameterValue}>
              {formattedValue}
              <ColorRectList colors={colors} />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
