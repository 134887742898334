import { Dispatch, SetStateAction } from "react";
import { LucideIcon } from "../../../../../common/icon/LucideIcon";
import { useCommands, Icon, useHelpers } from "@remirror/react";
import styles from "./TextEditorTableDropdown.module.css";
import { CellSelection } from "@remirror/pm/tables";
import { findParentNodeOfType } from "remirror";

export const TextEditorTableDropdwon = ({
  popupOpen,
  setPopupOpen,
}: {
  popupOpen: boolean;
  setPopupOpen: Dispatch<SetStateAction<boolean>>;
}) => {
  const commands = useCommands();
  const helpers = useHelpers();

  return (
    <div style={{ position: "relative", zIndex: 100 }}>
      <div
        className="btn btn-xs btn-ghost-secondary"
        onClick={() => setPopupOpen((prevState) => !prevState)}
        style={{ marginTop: "3px", marginRight: "3px" }}
      >
        <LucideIcon name={popupOpen ? "chevron-down" : "chevron-right"} />
      </div>
      {popupOpen && (
        <div
          className={styles.textEditorTableDropdownContainer}
          style={{
            top: "32px",
            left: "-80px",
          }}
        >
          <button
            className="btn btn-xs btn-block btn-ghost-secondary flex align-center gap-5"
            onClick={() => {
              const currentSelection = helpers.getCommandProp().tr.selection;
              const parent = findParentNodeOfType({ types: ["tableCell"], selection: currentSelection });
              if (parent) {
                let tr = helpers.getCommandProp().tr;
                const view = helpers.getCommandProp().view;
                const $pos = tr.doc.resolve(parent.pos);
                const selection = CellSelection.rowSelection($pos);
                tr = tr.setSelection(selection);
                view.dispatch(tr);
              }
            }}
          >
            {Icon({ size: 16, name: "table2" })}
            <div style={{ fontWeight: 400, padding: "0 5px", color: "var(--black)" }}>Select row</div>
          </button>
          <button
            className="btn btn-xs btn-block btn-ghost-secondary flex align-center gap-5"
            onClick={() => {
              const currentSelection = helpers.getCommandProp().tr.selection;
              const parent = findParentNodeOfType({ types: ["tableCell"], selection: currentSelection });
              if (parent) {
                let tr = helpers.getCommandProp().tr;
                const view = helpers.getCommandProp().view;
                const $pos = tr.doc.resolve(parent.pos);
                const selection = CellSelection.colSelection($pos);
                tr = tr.setSelection(selection);
                view.dispatch(tr);
              }
            }}
          >
            {Icon({ size: 16, name: "table2" })}
            <div style={{ fontWeight: 400, padding: "0 5px", color: "var(--black)" }}>Select column</div>
          </button>
          <hr style={{ borderColor: "var(--gray-300)", margin: "2px 0" }} />
          <button
            className="btn btn-xs btn-block btn-ghost-secondary flex align-center gap-5"
            onClick={() => commands.addTableRowAfter()}
          >
            {Icon({ size: 16, name: "insertRowBottom" })}
            <div style={{ fontWeight: 400, padding: "0 5px", color: "var(--black)" }}>Add row after</div>
          </button>
          <button
            className="btn btn-xs btn-block btn-ghost-secondary flex align-center gap-5"
            onClick={() => commands.addTableRowBefore()}
          >
            {Icon({ size: 16, name: "insertRowTop" })}
            <div style={{ fontWeight: 400, padding: "0 5px", color: "var(--black)" }}>Add row before</div>
          </button>
          <hr style={{ borderColor: "var(--gray-300)", margin: "2px 0" }} />
          <button
            className="btn btn-xs btn-block btn-ghost-secondary flex align-center gap-5"
            onClick={() => commands.addTableColumnAfter()}
          >
            {Icon({ size: 16, name: "insertColumnRight" })}
            <div style={{ fontWeight: 400, padding: "0 5px", color: "var(--black)" }}>Add column after</div>
          </button>
          <button
            className="btn btn-xs btn-block btn-ghost-secondary flex align-center gap-5"
            onClick={() => commands.addTableColumnBefore()}
          >
            {Icon({ size: 16, name: "insertColumnLeft" })}
            <div style={{ fontWeight: 400, padding: "0 5px", color: "var(--black)" }}>Add column before</div>
          </button>
          <hr style={{ borderColor: "var(--gray-300)", margin: "2px 0" }} />
          <button
            className="btn btn-xs btn-block btn-ghost-secondary flex align-center gap-5"
            onClick={() => {
              commands.mergeTableCells();
              setPopupOpen(false);
            }}
          >
            {Icon({ size: 16, name: "mergeCellsHorizontal" })}
            <div style={{ fontWeight: 400, padding: "0 5px", color: "var(--black)" }}>Merge table cells</div>
          </button>
          <button
            className="btn btn-xs btn-block btn-ghost-secondary flex align-center gap-5"
            onClick={() => {
              commands.splitTableCell();
              setPopupOpen(false);
            }}
          >
            {Icon({ size: 16, name: "splitCellsHorizontal" })}
            <div style={{ fontWeight: 400, padding: "0 5px", color: "var(--black)" }}>Split table cells</div>
          </button>
          <hr style={{ borderColor: "var(--gray-300)", margin: "2px 0" }} />
          <button
            className="btn btn-xs btn-block btn-ghost-secondary flex align-center gap-5"
            onClick={() => {
              const currentSelection = helpers.getCommandProp().tr.selection;
              const parent = findParentNodeOfType({ types: ["tableCell"], selection: currentSelection });
              if (parent) {
                let tr = helpers.getCommandProp().tr;
                const view = helpers.getCommandProp().view;
                const $pos = tr.doc.resolve(parent.pos);
                const selection = CellSelection.colSelection($pos);
                tr = tr.setSelection(selection);
                view.dispatch(tr);
              }
              commands.setTableCellAttribute("colwidth", null);
              setPopupOpen(false);
            }}
          >
            {Icon({ size: 16, name: "space" })}
            <div style={{ fontWeight: 400, padding: "0 5px", color: "var(--black)" }}>Reset column width</div>
          </button>
          <hr style={{ borderColor: "var(--gray-300)", margin: "2px 0" }} />
          <button
            className="btn btn-xs btn-block btn-ghost-danger flex align-center gap-5"
            onClick={() => {
              commands.deleteTableRow();
              setPopupOpen(false);
            }}
          >
            {Icon({ size: 16, name: "deleteRow" })}
            <div style={{ fontWeight: 400, padding: "0 5px" }}>Remove row</div>
          </button>
          <button
            className="btn btn-xs btn-block btn-ghost-danger flex align-center gap-5"
            onClick={() => {
              commands.deleteTableColumn();
              setPopupOpen(false);
            }}
          >
            {Icon({ size: 16, name: "deleteColumn" })}
            <div style={{ fontWeight: 400, padding: "0 5px" }}>Remove column</div>
          </button>
          <hr style={{ borderColor: "var(--gray-300)", margin: "2px 0" }} />
          <button
            className="btn btn-xs btn-block btn-ghost-danger flex align-center gap-5"
            onClick={() => {
              commands.deleteTable();
              setPopupOpen(false);
            }}
          >
            {Icon({ size: 16, name: "deleteBinLine" })}
            <div style={{ fontWeight: 400, padding: "0 5px" }}>Remove table</div>
          </button>
        </div>
      )}
    </div>
  );
};
