import React, { useEffect, useState, useCallback } from "react";
import { Button, FormControl, FormGroup, InputGroup } from "react-bootstrap";
import { useInputDebounce } from "../../ViewerUIElements/InputDebounce";
import { ArrayNeedsUpdate, getDigit } from "./ContourEditor";
import { ContourType } from "./ExplicitContourEditor";

import styles from "./ExplicitContourEditor.module.css";

type stateType = { state: null | "error" | "warning"; value: number };
const validateValue = (value: string, range?: number[]): stateType => {
  let f = parseFloat(value);
  const state: stateType = { value: f, state: null };
  if (isNaN(f)) state.state = "error";
  const minRange = Math.min(...(range || [0]));
  const maxRange = Math.max(...(range || [0]));

  // console.log("minmax", value, f, minRange, maxRange);
  if (f < minRange) {
    state.state = "warning";
    state.value = minRange;
  }
  if (f > maxRange) {
    state.state = "warning";
    state.value = maxRange;
  }

  return state;
};

export const ToolBox = ({
  selectedOffset,
  setSelectedOffset,
  contours,
  setContours,
  onClose,
}: {
  onClose: () => void;
  selectedOffset: number;
  contours: ContourType;
  setContours?: (contours: ContourType) => void;
  setSelectedOffset: (index: number) => void;
}) => {
  const [fieldColor, setFieldColor] = useState<string>();
  const [value, setValue] = useState<string>("");
  const debouncedValue = useInputDebounce<string>(value, 500);

  const addSlider = (index: number, position: "left" | "right"): void => {
    console.log("add slider", index, position);
    const minDiff = 10 ** -getDigit(contours.range.diff);
    let pos = contours.contours[index];
    let newIndex = 0;
    if (position === "left") {
      const left = index > 0 ? contours.contours[index - 1] : contours.range.min;
      pos = left + (contours.contours[index] - left) / 2;
      newIndex = index;
    } else if (position === "right") {
      const right = index < contours.contours.length - 1 ? contours.contours[index + 1] : contours.range.max;
      pos = contours.contours[index] + (right - contours.contours[index]) / 2;
      newIndex = index + 1;
    }

    if (Math.abs(contours.contours[index] - pos) >= minDiff) {
      const c = contours.contours.slice();
      c.splice(newIndex, 0, pos);
      if (setContours) {
        setContours({ range: contours.range, contours: c });
        setSelectedOffset(newIndex);
      }
    }
  };

  const removeSlider = (index: number): void => {
    console.log("remove slider", index);
    if (contours.contours.length > 1) {
      const c = contours.contours.slice();
      c.splice(index, 1);
      if (setContours) {
        setContours({ range: contours.range, contours: c });
        if (onClose) onClose();
        // setSelectedOffset(index > 1 ? index - 1 : 0);
      }
    }
  };

  const setFieldValue = useCallback(
    (newValue: string) => {
      const valid = validateValue(newValue, [contours.range.min, contours.range.max]);
      // console.log("value", value, newValue);
      setValue(newValue);
      // if (valid.state === null || valid.state === "warning") {
      //   console.log("contours", contours.contours[selectedOffset]);
      //   const c = contours.contours.slice();
      //   c[selectedOffset] = valid.value;
      //   if (setContours && ArrayNeedsUpdate(contours.contours, c)) setContours({ range: contours.range, contours: c });
      // }
      setFieldColor(valid.state === "error" ? "LightCoral" : valid.state === "warning" ? "Khaki" : undefined);
    },
    [contours.range]
  );

  const binarySearch = (list: number[], v: number) => {
    let l = 0;
    let n = list.length - 1;
    let r = n;

    if (v < list[l]) return [-1, 0];
    if (v > list[r]) return [n, n + 1];

    let m = Math.floor(l + (r - l) / 2);

    while (r - l > 1) {
      if (v < list[m]) {
        r = m;
      } else if (v > list[m]) {
        l = m;
      } else {
        return [m, m];
      }
      m = Math.floor(l + (r - l) / 2);
    }

    return [l, r];
  };

  const updateContours = useCallback(
    (value: string) => {
      const valid = validateValue(value, [contours.range.min, contours.range.max]);
      if (valid.state === null || valid.state === "warning") {
        // console.log("contours", Math.abs(valid.value - ));
        const c = contours.contours.slice();
        c[selectedOffset] = valid.value;
        c.sort((a, b) => a - b);
        const [l, r] = binarySearch(c, valid.value);
        console.log("contours", l, r, c.length);
        let newIndex: number = l;
        if (valid.value > contours.contours[contours.contours.length - 1]) newIndex = c.length - 1;
        // {
        //   c.unshift(valid.value);
        //   newIndex = 0;
        // } else if (r >= c.length) {
        //   newIndex = c.length;
        //   c.push(valid.value);
        // } else {
        //   newIndex = r;
        //   c.splice(r, 0, valid.value);
        // }

        // console.log("contours", newIndex, c);
        if (setContours && ArrayNeedsUpdate(contours.contours, c)) {
          setContours({ range: contours.range, contours: c });
          setSelectedOffset(newIndex);
        }
      }
    },
    [contours, setContours, selectedOffset, setSelectedOffset]
  );

  useEffect(() => {
    // updateContours(debouncedValue);
  }, [debouncedValue]);

  useEffect(() => {
    if (selectedOffset >= 0 && selectedOffset < contours.contours.length) {
      const digit = getDigit(contours.range.diff);
      setFieldValue("" + contours.contours[selectedOffset].toFixed(digit + 2));
      // console.log("contours", digit);
      // setFieldValue("" + contours.contours[selectedOffset]);
    } else {
      setValue("NaN");
      onClose();
    }
  }, [contours, selectedOffset, onClose, setFieldColor, setFieldValue]);

  return (
    <div className={styles.toolBox}>
      <div style={{ paddingRight: 10 }}>
        <span
          className={"glyphicon glyphicon-remove"}
          onClick={() => {
            onClose();

            setSelectedOffset(-1);
            // console.log("test", onClosed);
            // setActive(false);
            // if (onClosed) onClosed();
          }}
        />
        <FormGroup>
          <InputGroup bsSize={"sm"}>
            <InputGroup.Addon>
              <b>Value</b>
            </InputGroup.Addon>
            <FormControl
              type="text"
              style={{ background: fieldColor }}
              value={value}
              // value={inputState.value}
              onChange={(e) => setFieldValue((e.target as HTMLInputElement).value)}
              onBlur={(e) => updateContours(value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") updateContours(value);
              }}
              onFocus={(e) => (e.target as any as HTMLInputElement).select()}
            />
          </InputGroup>
        </FormGroup>

        <FormGroup className={styles.colorHandleButton}>
          {/* <ButtonToolbar className={styles.colorHandleButton} bsSize="xs"> */}
          {/* <ButtonGroup aria-label="Basic example" bsSize="sm"> */}

          <Button
            bsClass="secondary"
            className="btn-xs"
            onClick={() => addSlider(selectedOffset, "left")}
            style={{ flexGrow: 1 }}
          >
            <span className="glyphicon glyphicon-chevron-left" />
            Add left
          </Button>
          {/* <ControlLabel>add slider</ControlLabel> */}
          <Button
            bsClass="secondary"
            className="btn-xs"
            onClick={() => addSlider(selectedOffset, "right")}
            style={{ flexGrow: 1 }}
          >
            Add right
            <span className="glyphicon glyphicon-chevron-right" />
          </Button>
          {/* </ButtonGroup> */}
          {/* </ButtonToolbar> */}
        </FormGroup>
        <FormGroup>
          <Button
            bsClass="secondary"
            className="btn-xs"
            onClick={() => removeSlider(selectedOffset)}
            style={{ flexGrow: 1, color: contours.contours.length < 2 ? "gray" : undefined }}
            disabled={contours.contours.length < 2}
          >
            <span className={"glyphicon glyphicon-trash"} style={{ cursor: "pointer" }} /> Remove slider
          </Button>
        </FormGroup>
      </div>
    </div>
  );
};
