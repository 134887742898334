import { Dataset } from "../../../../api/Datasets";
import { ManualUploadDropArea } from "../../../../Dataset/ManualUpload/ManualUploadDropArea";
import { ManualUploadBundleView } from "../../../../Dataset/ManualUpload/ManualUploadBundleView";
import { IEntityMinimalModel } from "../../../../api/GenericTypes";
import { useCallback } from "react";
import { QuickAddChildrenProps } from "../common/QuickAddWrapper";

export const QuickAddDatasetForm = ({ onCreate, onCancel }: QuickAddChildrenProps<Dataset>) => {
  const onCreateCallback = useCallback(
    (datasets: IEntityMinimalModel<number>[]) => {
      datasets.forEach((dataset) => onCreate(dataset as Dataset));
    },
    [onCreate]
  );

  return (
    <>
      <ManualUploadDropArea>
        {(props) => <ManualUploadBundleView {...props} onCreateCallback={onCreateCallback} />}
      </ManualUploadDropArea>
    </>
  );
};
