import React, { CSSProperties } from "react";
import styles from "./Container.module.css";
/**
 * Generic Container component
 * Author: CS
 * @param: title, controls, children
 * @returns: JSX.Element
 */
interface Props {
  title: string | React.ReactNode;
  controls?: React.ReactNode;
  children: React.ReactNode;
  noPadding?: boolean;
  style?: CSSProperties;
  containerStyle?: CSSProperties;
}
export const Container = ({ title, controls, children, noPadding = false, style, containerStyle }: Props) => {
  return (
    <div className={styles.container} style={containerStyle}>
      <div className={styles.container_header}>
        <div className={styles.container_header_title}>{title}</div>
        <div className={styles.container_header_controls}>{controls}</div>
      </div>
      <div className={styles.container_body} style={{ ...(noPadding && { padding: 0 }), ...style }}>
        {children}
      </div>
    </div>
  );
};
