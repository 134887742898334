import { API } from "../api/Api";
import {
  Datatrack,
  DatatrackBinary,
  DatatrackImage,
  DatatrackNumericArray,
  DatatrackNumericMatrix,
  DatatrackTable,
  TrackBinaryData,
  TrackImageData,
  TrackMatrixData,
  TrackTableData,
  TrackXYData,
} from "../ViewerLayout/ViewerLayoutTypes";
import { Track } from "./../ViewerLayout/ViewerLayoutTypes";

const tileCache: Record<string, Datatrack> = {};

export const resetCache = () => {
  Object.keys(tileCache).forEach((k) => delete tileCache[k]);
};

export const getNewTiles = (tiles: {
  x?: Datatrack[];
  y?: Datatrack[];
  matrix?: Datatrack[];
  image?: Datatrack[];
  binary?: Datatrack[];
  table?: Datatrack[];
}) => {
  // const l = (tiles.x ?? [])
  //   .concat(tiles.y ?? [])
  //   .concat(tiles.matrix ?? [])
  //   .concat(tiles.image ?? [])
  //   .filter((tile) => !(tile.id in tileCache));
  // console.log("getNewTiles", ...(tiles.image ?? []).map((t) => t.id), l);

  // return l
  return (tiles.x ?? [])
    .concat(tiles.y ?? [])
    .concat(tiles.matrix ?? [])
    .concat(tiles.image ?? [])
    .concat(tiles.binary ?? [])
    .concat(tiles.table ?? [])
    .filter((tile) => !(tile.id in tileCache));
};

const getTilesData = async (api: API, tiles: Datatrack[], needsLoading?: (needsLoading: boolean) => void) => {
  const newTiles: Datatrack[] = [];
  const knownTiles: (Datatrack | undefined)[] = [];

  tiles.forEach((tile) => {
    // console.log("tile", tile.id);
    if (tile.id in tileCache) knownTiles.push(tileCache[tile.id]);
    else newTiles.push(tile);
  });

  // console.log("known", knownTiles.length, "new", newTiles.length);

  if (newTiles.length < 1) return knownTiles;
  if (needsLoading) needsLoading(true);

  const fetched = await api.Viewer.getTilesData(newTiles);
  if (fetched.length < 1) return [];
  fetched.forEach((tile) => {
    if (tile) tileCache[tile.id] = tile;
  });
  return knownTiles.concat(fetched);
};

export const fetchNewTiles = async (api: API, tiles: Datatrack[]) => {
  const tilesData = await api.Viewer.getTilesData(tiles);

  // console.log("tileData", tilesData)

  // pass payload -> now payload is taken from tileCache
  // if (tiles.length !== tilesData.length) return { check: false, tiles: [] };
  // let OK = true;
  // for (let tile of tilesData) {
  //   if (tile) tileCache[tile.id] = tile;
  //   else OK = false;
  // }
  // return { check: OK, tiles: tilesData };

  if (tiles.length !== tilesData.length) return false;
  let OK = true;
  for (let tile of tilesData) {
    // console.log("tile", tile?.id);
    if (tile) tileCache[tile.id] = tile;
    else OK = false;
  }
  return OK;
};

type tileXY = { x: Datatrack[]; y: Datatrack[] };
type tileMatrix = { matrix: Datatrack[] };
type tileImage = { image: Datatrack[] };
type tileBinary = { binary: Datatrack[] };
type tileTable = { table: Datatrack[] };

export const getTrackData = (track: Track, tiles: tileXY | tileMatrix | tileImage | tileBinary | tileTable) => {
  // console.log("getTrackData", (tiles as tileMatrix).matrix.map(t => t.id));
  if (track.type === "XY_real") return getTrackXYData(track, tiles as tileXY);
  else if (track.type === "matrix_real") return getTrackMatrixData(track, tiles as tileMatrix);
  else if (track.type === "image" || track.type === "molecule_compound")
    return getTrackImageData(track, tiles as tileImage);
  else if (track.type === "pdf") return getTrackBinaryData(track, tiles as tileBinary);
  else if (track.type === "table") return getTrackTableData(track, tiles as tileTable);
  return undefined;
};

export const getTrackBinaryData = (track: { id: string }, tiles: tileBinary): TrackBinaryData | undefined => {
  return {
    id: track.id,
    type: "binary",
    data: {
      binary: tiles.binary.map((tile) => tileCache[tile.id]).filter((tile) => tile !== undefined) as DatatrackBinary[],
    },
  };
};

export const getTrackImageData = (track: { id: string }, tiles: tileImage): TrackImageData | undefined => {
  return {
    id: track.id,
    type: "image",
    data: {
      // image: images?.[0],
      image: tiles.image.map((tile) => tileCache[tile.id]).filter((tile) => tile !== undefined) as DatatrackImage[],
    },
  };
};

export const getTrackTableData = (track: { id: string }, tiles: tileTable): TrackTableData | undefined => {
  return {
    id: track.id,
    type: "table",
    data: {
      // image: images?.[0],
      table: tiles.table.map((tile) => tileCache[tile.id]).filter((tile) => tile !== undefined) as DatatrackTable[],
    },
  };
};

export const getTrackMatrixData = (track: { id: string }, tiles: tileMatrix): TrackMatrixData | undefined => {
  // console.log(
  //   "tile",
  //   tiles.matrix.map((tile) => tileCache[tile.id]).filter((tile) => tile !== undefined) as DatatrackNumericMatrix[]
  // );
  return {
    id: track.id,
    type: "matrix_real",
    data: {
      x: [],
      y: [],
      matrix: tiles.matrix
        .map((tile) => tileCache[tile.id])
        .filter((tile) => tile !== undefined) as DatatrackNumericMatrix[],
    },
  };
};

export const getTrackXYData = (
  track: { id: string },
  tiles: { x: Datatrack[]; y: Datatrack[] }
): TrackXYData | undefined => {
  return {
    id: track.id,
    type: "XY_real",
    data: {
      x: tiles.x.map((tile) => tileCache[tile.id]).filter((tile) => tile !== undefined) as DatatrackNumericArray[],
      y: tiles.y.map((tile) => tileCache[tile.id]).filter((tile) => tile !== undefined) as DatatrackNumericArray[],
    },
  };
};

export const fetchTrackXYData = async (
  api: API,
  track: { id: string },
  tiles: { x: Datatrack[]; y: Datatrack[] },
  needsLoading?: (needsLoading: boolean) => void
): Promise<TrackXYData | undefined> => {
  const tileList = tiles.x.concat(tiles.y);
  if (tileList.length < 1)
    return {
      id: track.id,
      type: "XY_real",
      data: {
        x: [],
        y: [],
      },
    };
  const tilesData = await getTilesData(api, tiles.x.concat(tiles.y), needsLoading);
  if (tilesData.length < 1) return undefined;
  const tileMap: Record<string, DatatrackNumericArray> = Object.fromEntries(tilesData.map((tile) => [tile?.id, tile]));

  return {
    id: track.id,
    type: "XY_real",
    data: {
      x: tiles.x.map((tile) => tileMap[tile.id]).filter((tile) => tile !== undefined),
      y: tiles.y.map((tile) => tileMap[tile.id]).filter((tile) => tile !== undefined),
    },
  };
};
