import { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { DetailsPageTopControlsView } from "../common/panels/misc/DetailsPageTopControlsView/DetailsPageTopControlsView";
import { SessionContext } from "../common/contexts/SessionContext";
import { LucideIcon } from "../common/icon/LucideIcon";
import { NotSet } from "../common/misc/UIconstants";
import { AlertModal } from "../common/modals/AlertModal/AlertModal";
import { showtoast } from "../common/overlays/Toasts/showtoast";
import Detail from "../common/panels/Detail/Detail";
import { Table } from "../common/panels/Detail/DetailTable";
import { getDetailLink } from "../main/Routing";
import { MoreDropdown } from "../common/buttons/MoreDropdown/MoreDropdown";
import { DatasetsTile } from "../common/sidebar/tiles/DatasetsTile";
import { SamplesTile } from "../common/sidebar/tiles/SamplesTile";
import { DateTimeRenderer } from "../common/datetime/DateTimeFormatter";
import { PersonProjectPermissions } from "../ProjectPersonPermissions/PersonProjectPermissions";
import { EntityDetailProps, EntityDetailTableProps } from "../common/entity/EntityInterfaces";
import { GetPersons } from "../common/misc/EntityRenders/EntityRenderer";
import { CustomTypeDetailRow, CustomTypeRenderer } from "../Customization/CustomTypes/generics/CustomTypeRenderer";
import { InventoriesTile } from "../common/sidebar/tiles/InventoriesTile";
// import { AttachmentsTileWrapped } from "../common/sidebar/tiles/AttachmentsTile";
import { CustomFieldReferencesTile } from "../common/sidebar/tiles/CustomFieldReferencesTile";

export const ProjectsDetailTable = ({ entity, entityConstants, fieldLabels }: EntityDetailTableProps<"projects">) => {
  const { route, session } = useContext(SessionContext);
  let history = useHistory();
  return (
    <>
      <Table noPadding nonFoldable>
        <Table.Head>Basic details</Table.Head>
        <Table.Body>
          <Table.Body.RowContent
            title={fieldLabels.name}
            content={
              <div className="container_label">
                <div className="container_label_name" style={{ fontWeight: 600 }}>
                  {entity.name ?? NotSet}
                </div>
                <div className="container_label_id">
                  <span style={{ whiteSpace: "nowrap" }}>Project-ID: {entity.id}</span>
                </div>
              </div>
            }
          />
          {session?.features.enable_project_custom_types && (
            <CustomTypeDetailRow entity={entity} entityConstants={entityConstants} />
          )}
          <Table.Body.RowContent
            title={fieldLabels.notes}
            content={
              <>
                {entity.notes ? (
                  <div className="container_notes">
                    <textarea rows={4} className="container_textarea" value={entity?.notes} disabled />
                  </div>
                ) : (
                  NotSet
                )}
              </>
            }
          />

          <Table.Body.RowContent
            title={fieldLabels.projectTags}
            content={
              !!entity.projectTags?.length ? (
                <div style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
                  {entity.projectTags.map((t, i) => (
                    <span
                      key={i}
                      className="label label-soft-secondary"
                      style={{ cursor: "pointer", marginRight: "5px" }}
                      onClick={() => history.push(route(getDetailLink("project_tags", t.id)))}
                    >
                      {t.name}
                    </span>
                  ))}
                </div>
              ) : (
                NotSet
              )
            }
          />

          <Table.Body.RowContent
            title={fieldLabels.createdOn}
            content={
              <div className="flex align-center gap-5" style={{ width: "100%", flexWrap: "wrap" }}>
                <div style={{ maxWidth: "max-content", width: "100%" }}>
                  <DateTimeRenderer date={entity.createdOn} includeElapsed={false} />
                </div>
                <div className="flex row-nowrap align-center gap-5">
                  by <GetPersons persons={[entity.createdBy]} />
                </div>
              </div>
            }
          />
          <Table.Body.RowContent
            title={fieldLabels.modifiedOn}
            content={
              <div className="flex align-center gap-5" style={{ width: "100%", flexWrap: "wrap" }}>
                <div style={{ maxWidth: "max-content", width: "100%" }}>
                  <DateTimeRenderer date={entity.modifiedOn} includeElapsed={false} />
                </div>
                <div className="flex row-nowrap align-center gap-5">
                  by <GetPersons persons={[entity.modifiedBy]} />
                </div>
              </div>
            }
          />
        </Table.Body>
      </Table>

      <Table>
        <Table.Head>{fieldLabels.projectPersonPermissions}</Table.Head>
        <Table.Body>
          <PersonProjectPermissions project={entity} />
        </Table.Body>
      </Table>
      <CustomTypeRenderer entity={entity} />
    </>
  );
};

export const ProjectsDetail = ({
  permissions,
  routes,
  entity,
  entityApi,
  entityConstants,
  fieldLabels,
}: EntityDetailProps<"projects">) => {
  const { session } = useContext(SessionContext);
  let history = useHistory();
  const [showModal, setShowModal] = useState<boolean>(false);

  return (
    <Detail
      head={
        <DetailsPageTopControlsView
          entity={entity}
          entityConstants={entityConstants}
          controls={
            <>
              {/* {project.isDeleted ? (
                      <button
                        className="btn btn-sm btn-soft-success"
                        onClick={async () => {
                          await restoreProject(
                            { id: project.id },
                            {
                              onSuccess: () => {
                                showtoast("success", `person-ID: ${project.id} restored from trash.`);
                              },
                            }
                          ).catch((e) => {});
                        }}
                        disabled={!project.permissions?.edit}
                      >
                        <FeatherIcon name="rotate-ccw" /> Restore
                      </button>
                    ) : ( */}
              <button
                className="btn btn-default"
                onClick={() => history.push(routes.getEditLink(entity.id))}
                disabled={!permissions.canEdit(entity)}
              >
                <LucideIcon name="square-pen" />
                <span> Edit</span>
              </button>
              {/* )} */}
              <MoreDropdown drop="right" btn="btn btn-ghost-secondary">
                <li>
                  <button
                    className="btn btn-sm btn-ghost-secondary"
                    title={`Clone this Project`}
                    onClick={() => history.push(routes.getCloneLink(entity.id))}
                    disabled={!permissions.canCreate}
                  >
                    <LucideIcon name="copy" /> Clone
                  </button>
                </li>
                <li>
                  <button
                    className="btn btn-sm btn-ghost-danger"
                    title="Delete"
                    onClick={() => setShowModal(true)}
                    disabled={!permissions.canDelete(entity)}
                  >
                    <LucideIcon name="trash-2" /> Delete
                  </button>
                </li>
              </MoreDropdown>
              <AlertModal
                type="danger"
                showModal={showModal}
                setShowModal={setShowModal}
                title="Permanently delete project?"
                description="This project may be referenced by other entities. Deleting a project is only possible if all references are removed."
                proceedLabel="Delete"
                onProceed={async () => {
                  await entityApi
                    .deleteMutationAsync(
                      {
                        id: entity.id,
                        goBackOnSuccess: false,
                        showToast: false,
                        entityName: "person",
                        params: { deletePermanently: true },
                      },

                      {
                        onSuccess: () => {
                          showtoast("success", `Deleted Project-ID: ${entity.id}.`);
                          history.goBack();
                        },
                      }
                    )
                    .catch((e) => {});
                }}
                loading={entityApi.isLoadingDeleteMutation}
              />
            </>
          }
        />
      }
      mainStyle={{ padding: "0px 10px" }}
      main={<ProjectsDetailTable entity={entity} entityConstants={entityConstants} fieldLabels={fieldLabels} />}
      sidebar={
        <>
          {/* Non empty */}
          {!!entity.relations?.datasets.count && (
            <DatasetsTile defaultFilters={{ projectIds: [entity.id] }} excludeFilters={{ projectIds: () => false }} />
          )}
          {!!entity.relations?.samples.count && (
            <SamplesTile defaultFilters={{ projectIds: [entity.id] }} excludeFilters={{ projectIds: () => false }} />
          )}
          {!!entity.relations?.inventories.count && (
            <InventoriesTile
              defaultFilters={{ projectIds: [entity.id] }}
              excludeFilters={{ projectIds: () => false }}
            />
          )}

          {/* {session?.features.eln && (
            <>
              {!!project.relations?.labNotebooks.count && (
                <LabNotebooksTile
                  defaultFilters={{ projectIds: [project.id] }}
                  excludeFilters={{ projectIds: () => false }}
                />
              )}
              {!!project.relations?.labNotebooksEntryMentions.count && (
                <LabNotebookEntriesTile
                  title="Notebook mentions"
                  defaultFilters={{ projectIds: [project.id] }}
                  excludeFilters={{ projectIds: () => false }}
                />
              )}
            </>
          )} */}
          <CustomFieldReferencesTile
            entityType="Project"
            entity={entity}
            labNotebookFilters={session?.features.eln ? { projectIds: [entity.id] } : undefined}
            labNotebookEntryFilters={session?.features.eln ? { projectIds: [entity.id] } : undefined}
          />
          {/* <AttachmentsTileWrapped entity={project} /> */}
          {/* Empty */}
          {!entity.relations?.datasets.count && <DatasetsTile disabled />}
          {!entity.relations?.samples.count && <SamplesTile disabled />}
          {!entity.relations?.inventories.count && <InventoriesTile disabled />}
          {/* {session?.features.eln && (
            <>
              {!project.relations?.labNotebooks.count && <LabNotebooksTile disabled />}
              {!project.relations?.labNotebooksEntryMentions.count && (
                <LabNotebookEntriesTile disabled title="Notebook mentions" />
              )}
            </>
          )} */}
        </>
      }
    />
  );
};
