import {
  labNotebookFieldLabels,
  LabNotebookFiltersTranslator,
  labNoteBooksConstants,
  LabNotebookSortingOptionsConsts,
} from "../ELN/types/LabNotebook";
import {
  labNotebookEntriesConstants,
  labNotebookEntryFieldLabels,
  LabNotebookEntryFiltersTranslator,
  LabNotebookEntrySortingOptionsConsts,
} from "../ELN/types/LabNotebookEntry";
import {
  labNotebookExperimentFieldLabels,
  LabNotebookExperimentFiltersTranslator,
  labNotebookExperimentsConstants,
  LabNotebookExperimentSortingOptionsConsts,
} from "../ELN/types/LabNotebookExperiment";
import {
  labNotebookTemplateFieldLabels,
  LabNotebookTemplateFiltersTranslator,
  labNotebookTemplatesConstants,
  LabNotebookTemplateSortingOptionsConsts,
} from "../ELN/types/LabNotebookTemplate";
import {
  AnnouncementFieldLabels,
  AnnouncementFiltersTranslator,
  announcementsConstants,
  AnnouncementSortingOptionsConsts,
} from "./Announcements";
import { ApiKeyFieldLabels, apiKeysConstants } from "./ApiKey";
import {
  auditLogConstants,
  AuditLogFieldLabels,
  AuditLogFiltersTranslator,
  AuditLogSortingOptionsConsts,
} from "./AuditLog";
import { bridgeConstants, BridgeFieldLabels, BridgeFiltersTranslator, BridgeSortingOptionsConsts } from "./Bridges";
import {
  customFieldConstants,
  CustomFieldSortingOptionsConsts,
  CustomFieldFiltersTranslator,
  CustomFieldFieldLabels,
} from "./CustomFields";
import {
  customImportConstants,
  CustomImportFieldLabels,
  CustomImportFiltersTranslator,
  CustomImportSortingOptionsConsts,
} from "./CustomImports";
import {
  customTypeConstants,
  CustomTypeSortingOptionsConsts,
  CustomTypeFiltersTranslator,
  CustomTypeFieldLabels,
} from "./CustomTypes";
import {
  AttachmentFieldLabels,
  DatasetFieldLabels,
  DatasetFiltersTranslator,
  datasetsConstants,
  DatasetSortingOptionsConsts,
} from "./Datasets";
import {
  dataSourceConstants,
  DataSourceSortingOptionsConsts,
  DataSourceFiltersTranslator,
  DataSourceFieldLabels,
} from "./DataSource";
import {
  dataSourceStatusConstants,
  DataSourceStatusSortingOptionsConsts,
  DataSourceStatusFiltersTranslator,
  DataSourceStatusFieldLabels,
} from "./DataSourceStatus";
import { EquipmentFieldLabels } from "./Equipment";
import {
  equipmentTagsConstants,
  EquipmentTagSortingOptionsConsts,
  EquipmentTagFiltersTranslator,
  EquipmentTagFieldLabels,
} from "./EquipmentTag";
import {
  experimentsConstants,
  ExperimentSortingOptionsConsts,
  ExperimentFiltersTranslator,
  ExperimentFieldLabels,
} from "./Experiments";
import {
  equipmentsConstants,
  FacilitySortingOptionsConsts,
  EquipmentFacilityFiltersTranslator,
  instrumentsConstants,
  FacilityFiltersTranslator,
} from "./Facilities";
import { InstrumentFieldLabels } from "./Instruments";
import {
  inventoriesConstants,
  InventoryItemFieldLabels,
  InventoryItemFiltersTranslator,
  inventoryItemsConstants,
  InventoryItemSortingOptionsConsts,
} from "./Inventories";
import { attachmentsConstants } from "./Attachments";
import { methodsConstants, MethodFiltersTranslator, MethodSortingOptionsConsts, MethodFieldLabels } from "./Methods";
import {
  organizationsConstants,
  OrganizationFiltersTranslator,
  OrganizationSortingOptionsConsts,
  OrganizationFieldLabels,
} from "./Organizations";
import {
  organizationTagsConstants,
  OrganizationTagSortingOptionsConsts,
  OrganizationTagFiltersTranslator,
  OrganizationTagFieldLabels,
} from "./OrganizationTag";
import {
  parsersConstants,
  ParserSortingOptionsConsts,
  ParserFiltersTranslator,
  ParserMethodFiltersTranslator,
  parserMethodsConstants,
  ParserMethodSortingOptionsConsts,
  ParserInstrumentFiltersTranslator,
  parserInstrumentsConstants,
  ParserInstrumentSortingOptionsConsts,
  ParserFieldLabels,
  ParserMethodFieldLabels,
  ParserInstrumentFieldLabels,
} from "./Parsers";
import { personsConstants, PersonSortingOptionsConsts, PersonFiltersTranslator, PersonFieldLabels } from "./Person";
import {
  personTagsConstants,
  PersonTagSortingOptionsConsts,
  PersonTagFiltersTranslator,
  PersonTagFieldLabels,
} from "./PersonTag";
import {
  projectsConstants,
  ProjectSortingOptionsConsts,
  ProjectFiltersTranslator,
  ProjectFieldLabels,
} from "./Projects";
import {
  projectTagsConstants,
  ProjectTagSortingOptionsConsts,
  ProjectTagFiltersTranslator,
  ProjectTagFieldLabels,
} from "./ProjectTag";
import { rolesConstants, RoleSortingOptionsConsts, RoleFiltersTranslator, RoleFieldLabels } from "./Role";
import { samplesConstants, SampleSortingOptionsConsts, SampleFiltersTranslator, SampleFieldLabels } from "./Samples";
import {
  sharedContentConstants,
  SharedContentSortingOptionsConsts,
  SharedContentFiltersTranslator,
  SharedContentFieldLabels,
} from "./SharedContent";
import { vendorsConstants, VendorSortingOptionsConsts, VendorFiltersTranslator, VendorFieldLabels } from "./Vendors";
import { EntityConstants, GenericEntity, IListRequestParameters, IRelationParameters } from "./GenericTypes";
import {
  IconNMRHistoryEntrySortingOptionsConsts,
  IconNMRHistoryEntryFiltersTranslator,
  IconNMRHistoryFieldLabels,
  iconNMRHoldersConstants,
  IconNMRHolderSortingOptionsConsts,
  IconNMRHolderFiltersTranslator,
  IconNMRHolderFieldLabels,
  iconNMRHistoryConstants,
} from "./IconNMR";

// Main types
export type GenericEntityConstantsEntities = keyof typeof genericEntityConstants;
export type GenericEntityConstants = typeof genericEntityConstants;
export type GenericEntityConstant<EntityTypeId extends GenericEntityConstantsEntities> =
  (typeof genericEntityConstants)[EntityTypeId];

// This helper function returns the correctly typed entity constants for a given entity type.
/**
 * Retrieves the constants associated with a specific entity type.
 * @author @CorradoSurmanowicz
 * @template T - The key type of the GenericEntityConstants.
 * @param entityTypeId - The identifier of the entity type for which to retrieve constants.
 * @returns The constants associated with the specified entity type.
 */
export const getEntityConstants = <EntityTypeId extends GenericEntityConstantsEntities>(entityTypeId: EntityTypeId) => {
  return genericEntityConstants[entityTypeId];
};

/**
 * Represents the entity associated with a specific entity type ID.
 * @author @CorradoSurmanowicz
 * @template EntityTypeId - A type that extends `GenericEntityConstantsEntities`.
 */
export type EntityByEntityTypeId<EntityTypeId extends GenericEntityConstantsEntities> = InferEntity<
  GenericEntityConstant<EntityTypeId>["entityConstants"]
> &
  GenericEntity;

// export type FiltersByEntityTypeId<EntityTypeId extends GenericEntityConstantsEntities> = InferFilters<
//   GenericEntityConstant<EntityTypeId>["entityConstants"]
// >

/**
 * Represents the filters associated with a specific entity type ID.
 * @author @CorradoSurmanowicz
 * @template EntityTypeId - A type that extends `GenericEntityConstantsEntities`.
 *
 * This type combines the inferred filters from the entity constants of the given entity type ID
 * with the request parameters for a list, including the inferred order by parameters.
 */
export type FiltersByEntityTypeId<EntityTypeId extends GenericEntityConstantsEntities> = InferFilters<
  GenericEntityConstant<EntityTypeId>["entityConstants"]
> &
  IListRequestParameters<InferOderBy<InferFilters<GenericEntityConstant<EntityTypeId>["entityConstants"]>>> &
  IRelationParameters;

/**
 * Constants object that stores entity constants, sorting options, filters translators, and field labels for various entities.
 *
 * @remarks
 * This object is used to define and access constants related to different entities in the application.
 * Each entity has its own set of entity constants, sorting options, filters translators, and field labels.
 *
 * @author @CorradoSurmanowicz
 * @example
 * ```typescript
 * const entity = genericEntityConstants.Announcements;
 * console.log(entity.entityConstants); // Output: announcementsConstants
 * console.log(entity.sortingOptions); // Output: AnnouncementSortingOptionsConsts
 * console.log(entity.filtersTranslator); // Output: AnnouncementFiltersTranslator
 * console.log(entity.fieldLabels); // Output: AnnouncementFieldLabels
 * ```
 */

// Todo:
// Migrate all entities to use fieldLabels
// Add views property: DetailTable, Preview (optional), Filters, QuickForms, Dropdowns, ContentRow, EntityTiles

export const genericEntityConstants = Object.freeze({
  announcements: {
    entityConstants: announcementsConstants,
    sortingOptions: AnnouncementSortingOptionsConsts,
    filtersTranslator: AnnouncementFiltersTranslator,
    fieldLabels: AnnouncementFieldLabels,
  },
  apiKeys: {
    entityConstants: apiKeysConstants,
    sortingOptions: [],
    filtersTranslator: {},
    fieldLabels: ApiKeyFieldLabels,
  },
  attachments: {
    entityConstants: attachmentsConstants,
    sortingOptions: DatasetSortingOptionsConsts,
    filtersTranslator: DatasetFiltersTranslator,
    fieldLabels: AttachmentFieldLabels,
  },
  auditLogs: {
    entityConstants: auditLogConstants,
    sortingOptions: AuditLogSortingOptionsConsts,
    filtersTranslator: AuditLogFiltersTranslator,
    fieldLabels: AuditLogFieldLabels,
  },
  bridges: {
    entityConstants: bridgeConstants,
    sortingOptions: BridgeSortingOptionsConsts,
    filtersTranslator: BridgeFiltersTranslator,
    fieldLabels: BridgeFieldLabels,
  },
  customFields: {
    entityConstants: customFieldConstants,
    sortingOptions: CustomFieldSortingOptionsConsts,
    filtersTranslator: CustomFieldFiltersTranslator,
    fieldLabels: CustomFieldFieldLabels,
  },
  customImports: {
    entityConstants: customImportConstants,
    sortingOptions: CustomImportSortingOptionsConsts,
    filtersTranslator: CustomImportFiltersTranslator,
    fieldLabels: CustomImportFieldLabels,
  },
  customTypes: {
    entityConstants: customTypeConstants,
    sortingOptions: CustomTypeSortingOptionsConsts,
    filtersTranslator: CustomTypeFiltersTranslator,
    fieldLabels: CustomTypeFieldLabels,
  },
  datasets: {
    entityConstants: datasetsConstants,
    sortingOptions: DatasetSortingOptionsConsts,
    filtersTranslator: DatasetFiltersTranslator,
    fieldLabels: DatasetFieldLabels,
  },
  dataSources: {
    entityConstants: dataSourceConstants,
    sortingOptions: DataSourceSortingOptionsConsts,
    filtersTranslator: DataSourceFiltersTranslator,
    fieldLabels: DataSourceFieldLabels,
  },
  dataSourceStatus: {
    entityConstants: dataSourceStatusConstants,
    sortingOptions: DataSourceStatusSortingOptionsConsts,
    filtersTranslator: DataSourceStatusFiltersTranslator,
    fieldLabels: DataSourceStatusFieldLabels,
  },
  // documents: {
  //   entityConstants: documentsConstants,
  //   sortingOptions: DocumentSortingOptionsConsts,
  //   filtersTranslator: DocumentFiltersTranslator,
  //   fieldLabels: DocumentFieldLabels,
  // }, // Deprecated
  equipments: {
    entityConstants: equipmentsConstants,
    sortingOptions: FacilitySortingOptionsConsts,
    filtersTranslator: EquipmentFacilityFiltersTranslator,
    fieldLabels: EquipmentFieldLabels,
  },
  equipmentTags: {
    entityConstants: equipmentTagsConstants,
    sortingOptions: EquipmentTagSortingOptionsConsts,
    filtersTranslator: EquipmentTagFiltersTranslator,
    fieldLabels: EquipmentTagFieldLabels,
  },
  experiments: {
    entityConstants: experimentsConstants,
    sortingOptions: ExperimentSortingOptionsConsts,
    filtersTranslator: ExperimentFiltersTranslator,
    fieldLabels: ExperimentFieldLabels,
  },

  iconNMRHistoryEntry: {
    entityConstants: iconNMRHistoryConstants,
    sortingOptions: IconNMRHistoryEntrySortingOptionsConsts,
    filtersTranslator: IconNMRHistoryEntryFiltersTranslator,
    fieldLabels: IconNMRHistoryFieldLabels,
  },
  iconNMRHolders: {
    entityConstants: iconNMRHoldersConstants,
    sortingOptions: IconNMRHolderSortingOptionsConsts,
    filtersTranslator: IconNMRHolderFiltersTranslator,
    fieldLabels: IconNMRHolderFieldLabels,
  },
  // These are odd entities without IDs
  // iconNMRStatus: {
  //   entityConstants: iconNMRStatusConstants,
  //   sortingOptions: [],
  //   filtersTranslator: {},
  //   fieldLabels: IconNMRStatusFieldLabels,
  // },
  instruments: {
    entityConstants: instrumentsConstants,
    sortingOptions: FacilitySortingOptionsConsts,
    filtersTranslator: FacilityFiltersTranslator,
    fieldLabels: InstrumentFieldLabels,
  },
  inventories: {
    entityConstants: inventoriesConstants,
    sortingOptions: CustomTypeSortingOptionsConsts,
    filtersTranslator: CustomTypeFiltersTranslator,
    fieldLabels: CustomTypeFieldLabels,
  },
  inventoryItems: {
    entityConstants: inventoryItemsConstants,
    sortingOptions: InventoryItemSortingOptionsConsts,
    filtersTranslator: InventoryItemFiltersTranslator,
    fieldLabels: InventoryItemFieldLabels,
  },
  methods: {
    entityConstants: methodsConstants,
    sortingOptions: MethodFiltersTranslator,
    filtersTranslator: MethodSortingOptionsConsts,
    fieldLabels: MethodFieldLabels,
  },
  notebooks: {
    entityConstants: labNoteBooksConstants,
    sortingOptions: LabNotebookFiltersTranslator,
    filtersTranslator: LabNotebookSortingOptionsConsts,
    fieldLabels: labNotebookFieldLabels,
  },
  notebookExperiments: {
    entityConstants: labNotebookExperimentsConstants,
    sortingOptions: LabNotebookExperimentSortingOptionsConsts,
    filtersTranslator: LabNotebookExperimentFiltersTranslator,
    fieldLabels: labNotebookExperimentFieldLabels,
  },
  notebookEntries: {
    entityConstants: labNotebookEntriesConstants,
    sortingOptions: LabNotebookEntrySortingOptionsConsts,
    filtersTranslator: LabNotebookEntryFiltersTranslator,
    fieldLabels: labNotebookEntryFieldLabels,
  },
  notebookTemplates: {
    entityConstants: labNotebookTemplatesConstants,
    sortingOptions: LabNotebookTemplateSortingOptionsConsts,
    filtersTranslator: LabNotebookTemplateFiltersTranslator,
    fieldLabels: labNotebookTemplateFieldLabels,
  },
  organizations: {
    entityConstants: organizationsConstants,
    sortingOptions: OrganizationFiltersTranslator,
    filtersTranslator: OrganizationSortingOptionsConsts,
    fieldLabels: OrganizationFieldLabels,
  },
  organizationTags: {
    entityConstants: organizationTagsConstants,
    sortingOptions: OrganizationTagSortingOptionsConsts,
    filtersTranslator: OrganizationTagFiltersTranslator,
    fieldLabels: OrganizationTagFieldLabels,
  },
  parsers: {
    entityConstants: parsersConstants,
    sortingOptions: ParserSortingOptionsConsts,
    filtersTranslator: ParserFiltersTranslator,
    fieldLabels: ParserFieldLabels,
  },
  parserMethods: {
    entityConstants: parserMethodsConstants,
    sortingOptions: ParserMethodSortingOptionsConsts,
    filtersTranslator: ParserMethodFiltersTranslator,
    fieldLabels: ParserMethodFieldLabels,
  },
  parserInstruments: {
    entityConstants: parserInstrumentsConstants,
    sortingOptions: ParserInstrumentSortingOptionsConsts,
    filtersTranslator: ParserInstrumentFiltersTranslator,
    fieldLabels: ParserInstrumentFieldLabels,
  },
  persons: {
    entityConstants: personsConstants,
    sortingOptions: PersonSortingOptionsConsts,
    filtersTranslator: PersonFiltersTranslator,
    fieldLabels: PersonFieldLabels,
  },
  personTags: {
    entityConstants: personTagsConstants,
    sortingOptions: PersonTagSortingOptionsConsts,
    filtersTranslator: PersonTagFiltersTranslator,
    fieldLabels: PersonTagFieldLabels,
  },
  projects: {
    entityConstants: projectsConstants,
    sortingOptions: ProjectSortingOptionsConsts,
    filtersTranslator: ProjectFiltersTranslator,
    fieldLabels: ProjectFieldLabels,
  },
  projectTags: {
    entityConstants: projectTagsConstants,
    sortingOptions: ProjectTagSortingOptionsConsts,
    filtersTranslator: ProjectTagFiltersTranslator,
    fieldLabels: ProjectTagFieldLabels,
  },
  roles: {
    entityConstants: rolesConstants,
    sortingOptions: RoleSortingOptionsConsts,
    filtersTranslator: RoleFiltersTranslator,
    fieldLabels: RoleFieldLabels,
  },
  samples: {
    entityConstants: samplesConstants,
    sortingOptions: SampleSortingOptionsConsts,
    filtersTranslator: SampleFiltersTranslator,
    fieldLabels: SampleFieldLabels,
  },
  sharedContents: {
    entityConstants: sharedContentConstants,
    sortingOptions: SharedContentSortingOptionsConsts,
    filtersTranslator: SharedContentFiltersTranslator,
    fieldLabels: SharedContentFieldLabels,
  },
  vendors: {
    entityConstants: vendorsConstants,
    sortingOptions: VendorSortingOptionsConsts,
    filtersTranslator: VendorFiltersTranslator,
    fieldLabels: VendorFieldLabels,
  },
} as const);

// Utility types

/**
 * Infers the entity type `T` from a given `EntityConstants` type `S`.
 * If `S` extends `EntityConstants` with some type `T` and any other type,
 * this utility type extracts and returns `T`. Otherwise, it returns `never`.
 * @author @CorradoSurmanowicz
 * @template S - The type from which to infer the entity type.
 */
export type InferEntity<S> = S extends EntityConstants<infer T, any> ? T : never;
/**
 * Infers the filter type `T` from an `EntityConstants` type.
 * @author @CorradoSurmanowicz
 * @template S - The type to infer from.
 * @returns The inferred filter type `T` if `S` extends `EntityConstants<any, T>`, otherwise `never`.
 */
export type InferFilters<S> = S extends EntityConstants<any, infer T> ? T : never;

type InferOderBy<S> = S extends IListRequestParameters<infer O> ? O : never;
