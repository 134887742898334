import { DateTimeRenderer } from "../../common/datetime/DateTimeFormatter";
import { LucideIcon } from "../../common/icon/LucideIcon";
import { GetProjects, GetPersons } from "../../common/misc/EntityRenders/EntityRenderer";
import { NotSet } from "../../common/misc/UIconstants";
import { Table } from "../../common/panels/Detail/DetailTable";
import { LabNotebook, labNotebookFieldLabels } from "../types/LabNotebook";

export const LabNotebookDetailContentTable = ({ labNotebook }: { labNotebook: LabNotebook }) => {
  return (
    <Table noPadding>
      <Table.Head>Lab notebook details</Table.Head>
      <Table.Body>
        <Table.Body.RowContent
          title={labNotebookFieldLabels.name}
          content={
            <div
              className="flex row-nowrap align-center gap-5"
              data-toggle="tooltip"
              title={labNotebook.name}
              style={{ fontWeight: "bold", overflow: "hidden" }}
            >
              <div style={{ overflow: "hidden", textOverflow: "ellipsis" }}>{labNotebook.name || NotSet}</div>
              {labNotebook.isDeleted && (
                <div>
                  <label className="label label-soft-warning" title="Trashed" style={{ margin: 0 }}>
                    <LucideIcon name="trash" />
                  </label>
                </div>
              )}
            </div>
          }
        />

        <Table.Body.RowContent
          title={labNotebookFieldLabels.description}
          content={
            labNotebook.description ? (
              <div className={"container_notes"}>
                <textarea rows={2} className={"container_textarea"} value={labNotebook.description} disabled={true} />
              </div>
            ) : (
              NotSet
            )
          }
        />

        <Table.Body.RowContent
          title={labNotebookFieldLabels.status}
          content={
            <div>
              {labNotebook.status !== "CLOSED" ? (
                <label className="label label-soft-success" style={{ margin: 0 }}>
                  Active
                </label>
              ) : (
                <label className="label label-default" style={{ margin: 0 }}>
                  Closed
                </label>
              )}
            </div>
          }
        />

        <Table.Body.RowContent
          title={labNotebookFieldLabels.projects}
          content={<GetProjects projects={labNotebook.projects} />}
        />

        <Table.Body.RowContent
          title={labNotebookFieldLabels.createdOn}
          content={
            <div className="flex align-center gap-5" style={{ width: "100%", flexWrap: "wrap" }}>
              <div style={{ maxWidth: "max-content", width: "100%" }}>
                <DateTimeRenderer date={labNotebook?.createdOn} includeElapsed={false} />
              </div>
              <div className="flex row-nowrap align-center gap-5">
                by <GetPersons persons={[labNotebook.createdBy]} />
              </div>
            </div>
          }
        />

        <Table.Body.RowContent
          title={labNotebookFieldLabels.modifiedOn}
          content={
            <div className="flex align-center gap-5" style={{ width: "100%", flexWrap: "wrap" }}>
              <div style={{ maxWidth: "max-content", width: "100%" }}>
                <DateTimeRenderer date={labNotebook?.modifiedOn} includeElapsed={false} />
              </div>
              <div className="flex row-nowrap align-center gap-5">
                by <GetPersons persons={[labNotebook.modifiedBy]} />
              </div>
            </div>
          }
        />
      </Table.Body>
    </Table>
  );
};
