import { RemirrorJSON } from "remirror";
import {
  IConcurrencyToken,
  ICreationRecord,
  ICreatedRecordParameters,
  IGenericRequestParameters,
  IModelWithIntId,
  IModificationRecord,
  IModifiedRecordParameters,
  INamedEntity,
  IPaginationParameters,
  IPermissionedEntity,
  IProjectBasedResponseModel,
  IVersionedEntity,
  IVersionedEntityParameters,
  ISoftDeletable,
  ISoftDeletableFilterParameters,
  EntityConstants,
  DefaultOrderTypeConsts,
  DefaultCreatedOnRecordOrderTypeConsts,
  DefaultCreatedByRecordOrderTypeConsts,
  DefaultModifiedOnRecordOrderTypeConsts,
  DefaultModifiedByRecordOrderTypeConsts,
  IEntityMinimalModel,
  IProjectBasedParameters,
} from "../../api/GenericTypes";
import {
  IGenericRequestParametersTranslator,
  PropertyTranslator,
  IPaginationParametersTranslator,
  ICreatedRecordParametersTranslator,
  IModifiedRecordParametersTranslator,
  IVersionedEntityParametersTranslator,
  ISoftDeletableFilterParametersTranslator,
  IProjectBasedParametersTranslator,
} from "../../api/GenericTranslator";

export interface LabNotebookTemplate
  extends IModelWithIntId,
    INamedEntity,
    ICreationRecord,
    IModificationRecord,
    IPermissionedEntity,
    IProjectBasedResponseModel,
    ISoftDeletable,
    IVersionedEntity,
    IConcurrencyToken<string> {
  description: string;
  content?: RemirrorJSON;
}
export interface LabNotebookTemplateSuggestions extends IEntityMinimalModel<LabNotebookTemplate["id"]> {}

export interface LabNotebookTemplateVersion extends LabNotebookTemplate, IVersionedEntity {}

export interface LabNotebookTemplateWriteModel
  extends Omit<
      LabNotebookTemplate,
      "id" | "createdBy" | "createdOn" | "modifiedBy" | "modifiedOn" | "isDeleted" | "version" | "originalId"
    >,
    IConcurrencyToken<string> {}

export interface LabNotebookTemplateFilters
  extends IGenericRequestParameters<LabNotebookTemplate, LabNotebookTemplateSortingOptions>,
    IPaginationParameters,
    ICreatedRecordParameters,
    IModifiedRecordParameters,
    ISoftDeletableFilterParameters,
    IVersionedEntityParameters,
    IProjectBasedParameters {}

export interface LabNotebookTemplateVersionFilter extends LabNotebookTemplateFilters, IVersionedEntityParameters {}

export const labNotebookTemplatesConstants: EntityConstants<LabNotebookTemplate, LabNotebookTemplateFilters> = {
  resource: "lab_notebook_templates",
  frontendIndexRoute: "lab_notebook_templates",
  entitySingular: "notebook template",
  entityPlural: "notebook templates",
  icon: "copy",
};

export const labNotebookTemplateFieldLabels: PropertyTranslator<LabNotebookTemplate> = {
  concurrencyToken: "Concurrency token",
  content: "Content",
  id: "Notebook template ID",
  isDeleted: "Is deleted",
  permissions: "Permissions",
  version: "Version",
  name: "Name",
  projects: "Projects",
  description: "Description",
  createdBy: "Created by",
  createdOn: "Created on",
  modifiedBy: "Modified by",
  modifiedOn: "Modified on",
};

export const LabNotebookTemplateFiltersTranslator: PropertyTranslator<LabNotebookTemplateFilters> = {
  ...IGenericRequestParametersTranslator,
  ...IPaginationParametersTranslator,
  ...ICreatedRecordParametersTranslator,
  ...IModifiedRecordParametersTranslator,
  ...IProjectBasedParametersTranslator,
  ...IVersionedEntityParametersTranslator,
  ...ISoftDeletableFilterParametersTranslator,
} as const;

export const LabNotebookTemplateSortingOptionsConsts = [
  ...DefaultOrderTypeConsts,
  ...DefaultCreatedOnRecordOrderTypeConsts,
  ...DefaultCreatedByRecordOrderTypeConsts,
  ...DefaultModifiedOnRecordOrderTypeConsts,
  ...DefaultModifiedByRecordOrderTypeConsts,
] as const;
export type LabNotebookTemplateSortingOptions = (typeof LabNotebookTemplateSortingOptionsConsts)[number];
