import React from "react";
import { EntityByEntityTypeId, GenericEntityConstantsEntities } from "../../../api/GenericConstants";
import { Button } from "../../buttons/Button/Button";
import { LucideIcon } from "../../icon/LucideIcon";
import { showtoast } from "../../overlays/Toasts/showtoast";
import { toUppercase } from "../../helperfunctions/stringFunctions";
import { EntityConstants } from "../../../api/GenericTypes";
import { EntityApi } from "../../../api/useEntityApi";
import { IGenericEntityPermissions } from "../../permissions/useEntityPermissions";

interface EntityRestoreButtonProps<EntityTypeId extends GenericEntityConstantsEntities> {
  entity: EntityByEntityTypeId<EntityTypeId>;
  entityConstants: EntityConstants;
  entityApi: EntityApi<EntityByEntityTypeId<EntityTypeId>>;
  permissions: IGenericEntityPermissions<EntityTypeId>;
  onSuccess?: () => void;
}
/**
 * EntityRestoreButton component allows restoring an entity from the trash.
 * @author @CorradoSurmanowicz
 * @template EntityTypeId - The type of the entity ID.
 * @param {EntityRestoreButtonProps<EntityTypeId>} props - The properties for the EntityRestoreButton component.
 * @param {object} props.entity - The entity object to be restored.
 * @param {object} props.entityConstants - Constants related to the entity.
 * @param {object} props.entityApi - API methods for the entity, including restoreMutationAsync and isLoadingRestoreMutation.
 * @param {object} props.permissions - Permissions object to check if the entity can be restored.
 * @param {function} [props.onSuccess] - Optional callback function to be called on successful restore.
 * @returns {JSX.Element} The rendered button component.
 */
export const EntityRestoreButton = <EntityTypeId extends GenericEntityConstantsEntities>({
  entity,
  entityConstants,
  entityApi,
  permissions,
  onSuccess,
}: EntityRestoreButtonProps<EntityTypeId>) => {
  const { restoreMutationAsync, isLoadingRestoreMutation } = entityApi;

  return (
    <Button
      className="btn btn-sm btn-soft-success"
      onClick={async () => {
        await restoreMutationAsync(
          { id: entity.id },
          {
            onSuccess: () => {
              showtoast(
                "success",
                `${toUppercase(entityConstants.entitySingular)}-ID: ${entity.id} restored from trash.`
              );
              onSuccess?.();
            },
          }
        ).catch((e) => {});
      }}
      disabled={!permissions.canRestore(entity)}
      loading={isLoadingRestoreMutation}
    >
      <LucideIcon name="archive-restore" /> Restore from trash
    </Button>
  );
};
