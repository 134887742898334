import { useEffect, useState } from "react";

import { updateCopy } from "./ViewerLayoutUtils";

export const useTranslatedFieldUpdate = <SOURCE, TARGET>(
  source: SOURCE,
  translator: (source: SOURCE) => TARGET,
  initValue: any
): TARGET => {
  // State and setters for debounced value
  // const [target, setTarget] = useState<TARGET>(translator(source));
  const [target, setTarget] = useState<TARGET>(initValue);

  useEffect(() => {
    // console.log("target", target, initValue);
    let [copy, doUpdate] = updateCopy(target, translator(source));

    if (doUpdate) {
      if (typeof copy === "object") {
        if (Array.isArray(copy)) copy = copy.slice();
        else copy = Object.assign({}, copy);
      }
      setTarget(copy);
    }
  }, [source, translator]);

  return target;
};

// export const useTranslatedField = function <SOURCE, TARGET>(
//   source: SOURCE,
//   translator: (source: SOURCE) => TARGET,
//   initValue?: TARGET
// ): TARGET {
//   // State and setters for debounced value
//   // const [target, setTarget] = useState<TARGET>(translator(source));
//   const [target, setTarget] = useState<TARGET>(initValue ?? ({} as TARGET));

//   useEffect(() => {
//     // console.log("target", target, initValue);
//     let copy = translator(source);
//     // let [copy, doUpdate] = updateCopy(target, translator(source));
//     if (needUpdate(target, copy)) {
//       if (typeof copy === "object") {
//         if (Array.isArray(copy)) copy = copy.slice() as any as TARGET;
//         else copy = Object.assign({}, copy);
//       }
//       setTarget(copy);
//     }
//   }, [source]);

//   return target;
// };

export const useFieldsUpdated = function <T>(source: T): T {
  // State and setters for debounced value
  const [target, setTarget] = useState<T>(source);

  useEffect(() => {
    const [copy, doUpdate] = updateCopy(target, source);
    // console.log("useFieldsUpdated", doUpdate);
    if (doUpdate) setTarget(copy);
  }, [source]);

  return target;
};
