import React, { CSSProperties } from "react";
import { CustomType, customTypeConstants } from "../../../api/CustomTypes";
import {
  DefaultCustomTypeOrderType,
  EntityConstants,
  GenericEntity,
  ICustomTypedEntity,
  IGenericRequestParameters,
  IUniqueEntity,
} from "../../../api/GenericTypes";
// import { KeyValuePair } from "../../../common/detailviews/MetadataFormatting";
// import { NotSet } from "../../../common/misc/UIconstants";
import { Table } from "../../../common/panels/Detail/DetailTable";
import { CustomFieldRenderer } from "../../CustomFields/CustomFieldRenderer";
import { useCustomType } from "./useCustomTypes";
import { LoadingWrapper } from "../../../common/LoadingWrapper";
import { toUppercase } from "../../../common/helperfunctions/stringFunctions";
import { LinkEntity } from "../../../common/misc/LinkEntity/LinkEntity";
import {
  GenericVirtualizedTableCell,
  SortState,
} from "../../../common/tables/GenericVirtualizedTable/GenericVirtualizedTableTypes";

/**
 * Renders a basic custom type.
 * @author @CorradoSurmanowicz
 * @param entityType - The type of the entity.
 * @returns The rendered custom type.
 */
interface CustomTypeBasicRendererProps {
  entityType: string;
  style?: CSSProperties;
}
export const CustomTypeBasicRenderer = ({ entityType, style }: CustomTypeBasicRendererProps) => {
  return (
    <label className="label label-soft-info" style={{ margin: 0, ...style }}>
      Basic {entityType}
    </label>
  );
};

export const renderCustomTypeColumn = <
  Entity extends GenericEntity & ICustomTypedEntity,
  OrderBy extends string | DefaultCustomTypeOrderType | undefined | null,
  Filters extends IGenericRequestParameters<Entity, OrderBy>
>(
  entityConstants: EntityConstants,
  sort: SortState<Filters["orderBy"]>,
  setSort: React.Dispatch<React.SetStateAction<SortState<Filters["orderBy"]>>>
) => {
  return {
    id: "default-type",
    Header: `${toUppercase(entityConstants.entitySingular)} type`,
    accessor: (row) => (
      <>
        {row.customType ? (
          <label
            className="label label-soft-info"
            style={{ margin: 0, width: "100%", overflow: "hidden", textOverflow: "ellipsis" }}
          >
            {row.customType.name}
          </label>
        ) : (
          <CustomTypeBasicRenderer entityType={entityConstants.entitySingular} style={{ margin: 0, width: "100%" }} />
        )}
      </>
    ),
    width: 200,
    align: "left",
    sortingFn: (id) => {
      if (sort.headerId === id) {
        if (sort.sortDirection === "ASC") {
          setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "TYPE_DESC" as Filters["orderBy"] }));
        } else {
          setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "TYPE_ASC" as Filters["orderBy"] }));
        }
      } else {
        setSort({ headerId: id, sortDirection: "ASC", orderBy: "TYPE_ASC" as Filters["orderBy"] });
      }
    },
    sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
  } as GenericVirtualizedTableCell<Entity>;
};

/**
 * Renders a custom type for detail pages for a given entity.
 * @author @CorradoSurmanowicz
 * @template Entity - The type of the entity.
 * @param {CustomTypeDetailRowProps<Entity>} props - The component props.
 * @returns {JSX.Element} - The rendered custom type section.
 */

interface CustomTypeDetailRowProps<Entity extends ICustomTypedEntity> {
  entity: Entity;
  entityConstants: EntityConstants;
}

export const CustomTypeDetailRow = <Entity extends ICustomTypedEntity>({
  entity,
  entityConstants,
}: CustomTypeDetailRowProps<Entity>) => {
  return (
    <Table.Body.RowContent
      title={`${toUppercase(entityConstants.entitySingular)} type`}
      content={
        <div className={"container_label"}>
          {entity.customType ? (
            <LinkEntity entityConstants={customTypeConstants} property={entity.customType} />
          ) : (
            <div className={"container_label_name"}>
              {/* <span style={{ color: "var(--gray-400)", fontWeight: 500 }}>Basic {entityConstants.entitySingular}</span> */}
              <CustomTypeBasicRenderer entityType={entityConstants.entitySingular} />
            </div>
          )}
        </div>
      }
    />
  );
};

/**
 * Renders a custom type for a given entity.
 * @author @CorradoSurmanowicz
 * @template Entity - The type of the entity.
 * @param {CustomTypeRendererProps<Entity>} props - The component props.
 * @returns {JSX.Element} - The rendered custom type section.
 */
interface CustomTypeRendererProps<Entity extends ICustomTypedEntity & IUniqueEntity> {
  entity: Entity;
  rowStyle?: CSSProperties;
  includeCustomFieldId?: boolean;
}

export const CustomTypeRenderer = <Entity extends ICustomTypedEntity & IUniqueEntity>({
  entity,
  rowStyle,
  includeCustomFieldId = false,
}: CustomTypeRendererProps<Entity>) => {
  const { type, status, fetchStatus, error } = useCustomType({
    typeId: entity.customType?.id,
  });
  return (
    <LoadingWrapper status={status} fetchStatus={fetchStatus} error={error}>
      {type ? (
        <CustomTypeSectionRenderer
          entity={entity}
          customType={type}
          rowStyle={rowStyle}
          includeCustomFieldId={includeCustomFieldId}
        />
      ) : (
        <></>
      )}
    </LoadingWrapper>
  );
};

/**
 * Renders a custom type section.
 * @author @CorradoSurmanowicz
 * @template Entity - The type of the entity.
 * @param {CustomTypeSectionRendererProps<Entity>} props - The component props.
 * @returns {JSX.Element} - The rendered custom type section.
 */
interface CustomTypeSectionRendererProps<Entity extends ICustomTypedEntity & IUniqueEntity>
  extends CustomTypeRendererProps<Entity> {
  customType: CustomType;
}

const CustomTypeSectionRenderer = <Entity extends ICustomTypedEntity & IUniqueEntity>({
  entity,
  customType,
  rowStyle,
  includeCustomFieldId,
}: CustomTypeSectionRendererProps<Entity>) => {
  if (!customType) return <></>;
  return (
    <>
      {customType.sections.map((section, index) => {
        // if (field.fieldType === "CustomFieldSection") {
        return (
          <Table key={`Section-${index}`} isFolded={section.isFolded} noPadding>
            <Table.Head>{section.name ?? ""}</Table.Head>
            <Table.Body>
              {section.customFields?.map((customField, indexChild) => {
                return (
                  <CustomFieldRenderer
                    entity={entity}
                    title={customField.name}
                    value={entity.customFields?.[customField.id]}
                    customfield={customField}
                    key={indexChild}
                    rowStyle={rowStyle}
                    includeCustomFieldId={includeCustomFieldId}
                  />
                );
              })}
            </Table.Body>
          </Table>
        );
      })}
    </>
  );
};

interface CustomTypeMinimalRendererProps<Entity extends ICustomTypedEntity & IUniqueEntity> {
  entity: Entity;
  rowStyle?: CSSProperties;
}

/**
 * Renders a minimal view of a custom type entity.
 * @author @CorradoSurmanowicz
 * @template Entity - The type of the entity that extends `ICustomTypedEntity` and `IUniqueEntity`.
 * @param {CustomTypeMinimalRendererProps<Entity>} props - The properties for the renderer.
 * @param {Entity} props.entity - The entity to be rendered.
 * @param {React.CSSProperties} [props.rowStyle] - Optional style to be applied to each row.
 * @returns {JSX.Element} The rendered minimal view of the custom type entity.
 */
export const CustomTypeMinimalRenderer = <Entity extends ICustomTypedEntity & IUniqueEntity>({
  entity,
  rowStyle,
}: CustomTypeMinimalRendererProps<Entity>) => {
  return (
    <>
      {entity.customValues?.map((section, index) => (
        <Table key={`Section-${index}`} noPadding>
          <Table.Head>{section.name ?? ""}</Table.Head>
          <Table.Body>
            {section.content?.map((customField, indexChild) => {
              return (
                <CustomFieldRenderer
                  entity={entity}
                  title={customField.name}
                  value={customField.value}
                  customfield={customField}
                  key={indexChild}
                  rowStyle={rowStyle}
                  includeCustomFieldId={false}
                  showLinks={false}
                />
              );
            })}
          </Table.Body>
        </Table>
      ))}
    </>
  );
};
