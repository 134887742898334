import React, { useEffect } from "react";
import { Alert } from "react-bootstrap";
import { useGetMutation } from "../../api/BaseEntityApi";
import { StringIndexedDict } from "../../api/GenericTypes";
import { Control, Path, PathValue, UseFormSetValue, useWatch } from "react-hook-form";
import { InstrumentFacility, InstrumentFacilityFilters } from "../../api/Facilities";
import { DatasetParsingState } from "../../api/Datasets";
import { LucideIcon } from "../../common/icon/LucideIcon";

export const errorMSG = (error: string, type: string = "danger") => {
  return <Alert bsStyle={type}>{error}</Alert>;
};

interface UseDatasetInstrumentProps<
  T extends StringIndexedDict,
  InstrumentPath extends keyof T & Path<T>,
  MethodPath extends keyof T & Path<T>
> {
  control: Control<T, any>;
  setValue: UseFormSetValue<T>;
  instrumentPath?: InstrumentPath;
  methodPath?: MethodPath;
}
export const useDatasetInstrument = <
  T extends StringIndexedDict,
  InstrumentPath extends keyof T & Path<T>,
  MethodPath extends keyof T & Path<T>
>({
  control,
  setValue,
  instrumentPath = "instrument" as InstrumentPath,
  methodPath = "method" as MethodPath,
}: UseDatasetInstrumentProps<T, InstrumentPath, MethodPath>) => {
  const { mutateAsync: instrumentMutationAsync } = useGetMutation<InstrumentFacility, InstrumentFacilityFilters>(
    "instruments"
  );
  //   const entities = useWatch({ name: props.id as Path<Form>, control: props.control });
  const instrument = useWatch({ name: instrumentPath as Path<T>, control: control }) as T[InstrumentPath];

  useEffect(() => {
    if (instrument && instrument.id > 0) {
      (async () => {
        await instrumentMutationAsync(
          { id: instrument.id },
          {
            onSuccess: (data) => {
              setValue?.(methodPath as Path<T>, data.method as PathValue<T, Path<T>>);
            },
          }
        ).catch(() => {});
      })();
    }
  }, [instrument, instrumentMutationAsync, methodPath, setValue]);

  return { instrument };
};

export const RenderParsingState = ({ state }: { state: DatasetParsingState }) => {
  switch (state) {
    case "NotParseable":
      return (
        <label className="label label-soft-info" style={{ margin: 0 }}>
          <LucideIcon name="x" /> Not parseable
        </label>
      );
    case "NotYetParsed":
      return (
        <label className="label label-soft-warning" style={{ margin: 0 }}>
          <LucideIcon name="clock" /> Not yet parsed
        </label>
      );
    case "ParsedSuccessfully":
      return (
        <label className="label label-soft-success" style={{ margin: 0 }}>
          <LucideIcon name="check" /> Parsed successfully
        </label>
      );
    case "ParsingFailed":
      return (
        <label className="label label-soft-warning" style={{ margin: 0 }}>
          <LucideIcon name="triangle-alert" /> Parsing failed
        </label>
      );
    default:
      return (
        <label className="label label-soft-warning" style={{ margin: 0 }}>
          <LucideIcon name="ban" /> Unknown
        </label>
      );
  }
};
