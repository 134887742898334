import { SetStateAction, useEffect, useState } from "react";
import { GenericModal } from "../common/modals/Modal/GenericModal";
import { Barcode } from "../common/icon/barcode/Barcode";
import { useForm } from "react-hook-form";
import { useInvalidateQueries, usePost, usePostMutation } from "../api/BaseEntityApi";
import { ResourceName } from "../main/Routing";
import { LoadingWrapper } from "../common/LoadingWrapper";
import { InputFormField } from "../common/formfields/InputFormField";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormButtons } from "../common/forms/FormButtons";
import { PersonFieldLabels, personsConstants } from "../api/Person";
import { Alert } from "../common/overlays/Alert/Alert";
import { LucideIcon } from "../common/icon/LucideIcon";

export const TotpFormSchema = yup.object().shape({
  otp: yup.string().required("Verification code is required").typeError("Verification code is required"),
});

interface TotPGenerate {
  secret: string;
  uri: string;
}
interface FormValues {
  otp: string;
}

interface TotpFormProps {
  accountId: number;
  showModal: boolean;
  setShowModal: (value: React.SetStateAction<boolean>) => void;
}
export const TotpForm = ({ showModal, setShowModal, accountId }: TotpFormProps) => {
  const [secret, setSecret] = useState<string | null>(null);
  const [uri, setUri] = useState<string | null>(null);
  const [saveError, setSaveError] = useState<string | null>(null);
  const invalidateQueries = useInvalidateQueries(personsConstants.resource);

  const { data, status, fetchStatus, error } = usePost<TotPGenerate>("totp/generate", { accountId: accountId });
  const { mutateAsync: saveTotpMutation } = usePostMutation<boolean, {}>({ resource: "totp/save" as ResourceName });

  useEffect(() => {
    if (data) {
      setSecret(data.secret);
      setUri(data.uri);
    }
  }, [data]);

  const saveTotp = async (values: FormValues) => {
    await saveTotpMutation(
      { body: { otp: values.otp, secret: secret, accountId: accountId } },
      {
        onSuccess: (isValid) => {
          if (isValid) {
            invalidateQueries();
            setShowModal(false);
          } else {
            setSaveError("The entered verification code was incorrect. Please try again.");
          }
        },
        onError: (error) => {
          setSaveError("An error occured while saving the 2FA configuration. Please try again.");
          console.error(error);
        },
      }
    ).catch(() => {});
  };

  const {
    register,
    handleSubmit,
    formState: { isSubmitting, isLoading, errors },
  } = useForm<FormValues>({
    resolver: yupResolver(TotpFormSchema),
  });

  return (
    <GenericModal
      showModal={showModal}
      setShowModal={setShowModal}
      modalTitle={`Configure two-factor authentication (2FA)`}
      modalBody={
        <div className="flex" style={{ width: "100%", height: "fit-content" }}>
          <LoadingWrapper status={status} error={error} fetchStatus={fetchStatus}>
            <div
              className="flex col-nowrap align-center justify-center"
              style={{ width: "100%", height: "fit-content" }}
            >
              {uri && (
                <>
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                    }}
                    autoComplete="off"
                    className={`form-horizontal`}
                    style={{ width: "100%", height: "fit-content" }}
                  >
                    <Barcode
                      bcid="qrcode"
                      text={uri}
                      scale={6}
                      width={16}
                      height={16}
                      style={{ alignItems: "center", justifyContent: "center", padding: 10, stroke: "var(--obsidian)" }}
                    />
                    <InputFormField
                      id="otp"
                      label="Verification code"
                      placeholder="Enter the verification code from your authenticator app"
                      register={register}
                      required
                    />
                    {saveError != null && <Alert type="danger" message={saveError} fit centered />}
                  </form>
                </>
              )}
            </div>
          </LoadingWrapper>
        </div>
      }
      modalControls={
        <FormButtons
          onClose={() => setShowModal(false)}
          onSubmit={handleSubmit(saveTotp)}
          loading={isLoading}
          submitButtonLabel={"Save 2FA configuration"}
          disabled={isSubmitting}
          errors={errors}
        />
      }
      styleBody={{ height: "fit-content" }}
    />
  );
};

export const TwoFactorAuthIndicator = ({ enabled }: { enabled: boolean }) => {
  return (
    <label
      // className="label label-soft-secondary"
      style={{
        color: enabled ? "var(--success)" : "var(--warning)",
        margin: 0,
        lineHeight: undefined,
      }}
    >
      {enabled ? (
        <>
          <LucideIcon name="shield-check" /> Enabled
        </>
      ) : (
        <>
          <LucideIcon name="shield-off" /> Disabled
        </>
      )}
    </label>
  );
};
