import { useCallback, useContext, useMemo } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { SessionContext } from "../../common/contexts/SessionContext";
import {
  notebooksDashboardRoute,
  ELNModes,
  ELNUrlParamTypes,
  notebookBlocksRoute,
  notebookEntriesRoute,
  notebookExperimentsRoute,
  notebooksRoute,
  notebookTemplatesRoute,
} from "./ELNRouter";
import { LabNotebookEntryFilters } from "../types/LabNotebookEntry";

type QueryParams = "filters";

export const useELNRoutes = () => {
  const history = useHistory();
  const location = useLocation();
  const { route } = useContext(SessionContext);
  const { labNotebookId, labNotebookExperimentId, labNotebookEntryId, labNotebookBlockId, labNotebookTemplateId } =
    useParams<ELNUrlParamTypes>();

  const getQueryParam = useCallback(
    (param: QueryParams) => {
      return new URLSearchParams(location.search).get(param);
    },
    [location.search]
  );

  const currentRouteInfo = useMemo(() => {
    return {
      labNotebookId: (!!labNotebookId && parseInt(labNotebookId)) || undefined,
      labNotebookExperimentId: (!!labNotebookExperimentId && parseInt(labNotebookExperimentId)) || undefined,
      labNotebookEntryId: (!!labNotebookEntryId && parseInt(labNotebookEntryId)) || undefined,
      labNotebookBlockId: labNotebookBlockId?.toString(),
      labNotebookTemplateId: (!!labNotebookTemplateId && parseInt(labNotebookTemplateId)) || undefined,
    };
  }, [labNotebookBlockId, labNotebookEntryId, labNotebookExperimentId, labNotebookId, labNotebookTemplateId]);

  const baseELNRoute = useMemo(() => {
    return route(`/${notebooksRoute}`);
  }, [route]);

  const toLabNotebooksList = useCallback(() => {
    return baseELNRoute;
  }, [baseELNRoute]);

  const toLabNotebookExperimentsList = useCallback(() => {
    return baseELNRoute + "/" + notebookExperimentsRoute;
  }, [baseELNRoute]);

  const toLabNotebookEntriesList = useCallback(() => {
    return baseELNRoute + "/" + notebookEntriesRoute;
  }, [baseELNRoute]);

  const setELNRoute = useCallback(
    ({
      labNotebookId,
      labNotebookExperimentId,
      labNotebookEntryId,
      mode,
      labNotebookBlockId,
      filters,
      replace,
    }: {
      labNotebookId?: number;
      labNotebookExperimentId?: number;
      labNotebookEntryId?: number;
      mode?: ELNModes;
      labNotebookBlockId?: string;
      filters?: LabNotebookEntryFilters;
      replace?: boolean;
    }) => {
      if (labNotebookId && labNotebookExperimentId && labNotebookEntryId && labNotebookBlockId) {
        const newRoute =
          baseELNRoute +
          `/${labNotebookId}/${notebookExperimentsRoute}/${labNotebookExperimentId}/${notebookEntriesRoute}/${labNotebookEntryId}/${
            mode || "view"
          }/${notebookBlocksRoute}/${labNotebookBlockId}`;
        if (newRoute !== location.pathname)
          if (replace) {
            history.replace({ pathname: newRoute, search: filters ? `filters=${JSON.stringify(filters)}` : undefined });
          } else {
            history.push({ pathname: newRoute, search: filters ? `filters=${JSON.stringify(filters)}` : undefined });
          }
        return newRoute;
      }
      if (labNotebookId && labNotebookEntryId && labNotebookBlockId) {
        const newRoute =
          baseELNRoute +
          `/${labNotebookId}/${notebookEntriesRoute}/${labNotebookEntryId}/${
            mode || "view"
          }/${notebookBlocksRoute}/${labNotebookBlockId}`;
        if (newRoute !== location.pathname)
          if (replace) {
            history.replace({ pathname: newRoute, search: filters ? `filters=${JSON.stringify(filters)}` : undefined });
          } else {
            history.push({ pathname: newRoute, search: filters ? `filters=${JSON.stringify(filters)}` : undefined });
          }
        return newRoute;
      }
      if (labNotebookId && labNotebookExperimentId && labNotebookEntryId) {
        const newRoute =
          baseELNRoute +
          `/${labNotebookId}/${notebookExperimentsRoute}/${labNotebookExperimentId}/${notebookEntriesRoute}/${labNotebookEntryId}/${
            mode || "view"
          }`;
        if (newRoute !== location.pathname)
          if (replace) {
            history.replace({ pathname: newRoute, search: filters ? `filters=${JSON.stringify(filters)}` : undefined });
          } else {
            history.push({ pathname: newRoute, search: filters ? `filters=${JSON.stringify(filters)}` : undefined });
          }
        return newRoute;
      }
      if (labNotebookId && labNotebookExperimentId) {
        const newRoute = baseELNRoute + `/${labNotebookId}/${notebookExperimentsRoute}/${labNotebookExperimentId}`;
        if (newRoute !== location.pathname)
          if (replace) {
            history.replace({ pathname: newRoute, search: filters ? `filters=${JSON.stringify(filters)}` : undefined });
          } else {
            history.push({ pathname: newRoute, search: filters ? `filters=${JSON.stringify(filters)}` : undefined });
          }
        return newRoute;
      }
      if (labNotebookId && labNotebookEntryId) {
        const newRoute =
          baseELNRoute + `/${labNotebookId}/${notebookEntriesRoute}/${labNotebookEntryId}/${mode || "view"}`;
        if (newRoute !== location.pathname)
          if (replace) {
            history.replace({ pathname: newRoute, search: filters ? `filters=${JSON.stringify(filters)}` : undefined });
          } else {
            history.push({ pathname: newRoute, search: filters ? `filters=${JSON.stringify(filters)}` : undefined });
          }
        return newRoute;
      }
      if (labNotebookId) {
        const newRoute = baseELNRoute + `/${labNotebookId}`;
        if (newRoute !== location.pathname)
          if (replace) {
            history.replace({ pathname: newRoute, search: filters ? `filters=${JSON.stringify(filters)}` : undefined });
          } else {
            history.push({ pathname: newRoute, search: filters ? `filters=${JSON.stringify(filters)}` : undefined });
          }
        return newRoute;
      }

      const newRoute = baseELNRoute + `/${notebooksDashboardRoute}`;
      if (newRoute !== location.pathname)
        if (replace) {
          history.replace({ pathname: newRoute, search: filters ? `filters=${JSON.stringify(filters)}` : undefined });
        } else {
          history.push({ pathname: newRoute, search: filters ? `filters=${JSON.stringify(filters)}` : undefined });
        }
      return newRoute;
    },
    [baseELNRoute, location.pathname, history]
  );

  const getELNRoute = useCallback(
    ({
      labNotebookId,
      labNotebookExperimentId,
      labNotebookEntryId,
      mode,
      labNotebookBlockId,
    }: {
      labNotebookId?: number;
      labNotebookExperimentId?: number;
      labNotebookEntryId?: number;
      mode?: ELNModes;
      labNotebookBlockId?: string;
    }) => {
      if (labNotebookId && labNotebookExperimentId && labNotebookEntryId && labNotebookBlockId) {
        return (
          baseELNRoute +
          `/${labNotebookId}/${notebookExperimentsRoute}/${labNotebookExperimentId}/${notebookEntriesRoute}/${labNotebookEntryId}/${
            mode || "view"
          }/${notebookBlocksRoute}/${labNotebookBlockId}`
        );
      }
      if (labNotebookId && labNotebookEntryId && labNotebookBlockId) {
        return (
          baseELNRoute +
          `/${labNotebookId}/${notebookEntriesRoute}/${labNotebookEntryId}/${
            mode || "view"
          }/${notebookBlocksRoute}/${labNotebookBlockId}`
        );
      }
      if (labNotebookId && labNotebookExperimentId && labNotebookEntryId) {
        return (
          baseELNRoute +
          `/${labNotebookId}/${notebookExperimentsRoute}/${labNotebookExperimentId}/${notebookEntriesRoute}/${labNotebookEntryId}/${
            mode || "view"
          }`
        );
      }
      if (labNotebookId && labNotebookExperimentId) {
        return baseELNRoute + `/${labNotebookId}/${notebookExperimentsRoute}/${labNotebookExperimentId}`;
      }
      if (labNotebookId && labNotebookEntryId) {
        return baseELNRoute + `/${labNotebookId}/${notebookEntriesRoute}/${labNotebookEntryId}/${mode || "view"}`;
      }
      if (labNotebookId) {
        return baseELNRoute + `/${labNotebookId}`;
      }

      return baseELNRoute + `/${notebooksDashboardRoute}`;
    },
    [baseELNRoute]
  );

  const setELNTemplateRoute = useCallback(
    (labNotebookTemplateId?: number, mode?: ELNModes, replace?: boolean) => {
      if (labNotebookTemplateId) {
        const newRoute = baseELNRoute + `/${notebookTemplatesRoute}/${labNotebookTemplateId}/content/${mode || "view"}`;
        if (newRoute !== location.pathname) {
          if (replace) {
            history.replace({ pathname: newRoute });
          } else {
            history.push({ pathname: newRoute });
          }
        }
        return newRoute;
      }
      const newRoute = baseELNRoute + `/${notebookTemplatesRoute}`;
      if (newRoute !== location.pathname) history.push({ pathname: newRoute });
      return newRoute;
    },
    [history, location, baseELNRoute]
  );

  const getELNTemplateRoute = useCallback(
    (labNotebookTemplateId?: number, mode?: ELNModes) => {
      if (labNotebookTemplateId) {
        return baseELNRoute + `/${notebookTemplatesRoute}/${labNotebookTemplateId}/content/${mode || "view"}`;
      }

      return baseELNRoute + `/${notebookTemplatesRoute}`;
    },
    [baseELNRoute]
  );

  const changeMode = useCallback(() => {
    const { labNotebookId, labNotebookExperimentId, labNotebookEntryId, labNotebookTemplateId } = currentRouteInfo;
    if (labNotebookId) {
      setELNRoute({ labNotebookId, labNotebookExperimentId, labNotebookEntryId, mode: "view" });
      return;
    }
    if (labNotebookTemplateId) {
      setELNTemplateRoute(labNotebookTemplateId, "view");
    }
  }, [currentRouteInfo, setELNRoute, setELNTemplateRoute]);

  const goUp = useCallback(
    (replace?: boolean) => {
      const { labNotebookId, labNotebookExperimentId, labNotebookEntryId, labNotebookBlockId } = currentRouteInfo;
      if (labNotebookBlockId)
        return setELNRoute({
          labNotebookId,
          labNotebookExperimentId,
          labNotebookEntryId,
          replace,
        });
      if (labNotebookExperimentId && labNotebookEntryId)
        return setELNRoute({ labNotebookId, labNotebookEntryId, replace });
      if (labNotebookEntryId) return setELNRoute({ labNotebookId, replace });
      if (labNotebookExperimentId) return setELNRoute({ labNotebookId, replace });
      if (labNotebookId) return setELNRoute({ replace });
    },
    [currentRouteInfo, setELNRoute]
  );

  return {
    baseELNRoute,
    goUp,
    setELNRoute,
    setELNTemplateRoute,
    changeMode,
    getELNRoute,
    getELNTemplateRoute,
    toLabNotebooksList,
    toLabNotebookExperimentsList,
    toLabNotebookEntriesList,
    getQueryParam,
  };
};
