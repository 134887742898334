import produce from "immer";
import React, { CSSProperties, useEffect, useMemo, useState } from "react";
import { FormControl } from "react-bootstrap";
import { needUpdate } from "../../ViewerLayout/ViewerLayoutUtils";
import { useInputDebounce } from "../InputDebounce";

import styles from "./DecoratedStringListInput.module.css";

export const DecoratedStringListInput = ({
  value,
  setValue,
  name,
}: {
  value: string[];
  setValue?: (value: string[]) => void;
  name?: string;
  style?: CSSProperties;
}) => {
  const [input, setInput] = useState<string[]>(value);
  const debouncedInput = useInputDebounce<string[]>(input, 500);

  const CSSLen = useMemo<string>(() => {
    let len = input.length;
    if (len > 5) len = 5;
    len = len * 1.4 + len * 0.2;
    return `${len}em`;
  }, [input.length]);

  useEffect(() => {
    if (needUpdate(input, value)) setInput(value);
  }, [value, input]);

  useEffect(() => {
    if (setValue && needUpdate(value, debouncedInput)) setValue(debouncedInput);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedInput, setValue]);

  return (
    <div className={styles.rangeSetter}>
      <div className={styles.settingText} style={{ position: "relative", top: -10 }}>
        {name ? <span>{name}:&nbsp;</span> : null}
      </div>
      <div
        className={styles.inputField}
        style={{
          position: "relative",
          top: -10,
          height: CSSLen,
        }}
      >
        {input.map((v, i) => (
          <div key={i} style={{ paddingBottom: "0.2em" }}>
            <FormControl
              type="text"
              value={v}
              onChange={(e) =>
                setInput(
                  produce(input, (next) => {
                    next[i] = (e.target as HTMLInputElement).value;
                  })
                )
              }
              onKeyDown={(e) => {
                // if (e.key === "Enter") setInput((e.target as HTMLInputElement).value);
              }}
              onFocus={(e) => (e.target as any as HTMLInputElement).select()}
              style={{}}
              // style={Object.assign({ background: valid ? undefined : "salmon" })}
              className={styles.formControlXS}
              // bsSize={"sm"}
            />
          </div>
        ))}
      </div>
    </div>
  );
};
