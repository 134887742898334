import React, { useEffect, useState, useCallback } from "react";
import { Button, Form, FormGroup, Modal, Radio } from "react-bootstrap";

type formatType = {
  id: "svg" | "png" | "jpeg" | "csv" | "html";
  text: string;
};

// const formatNames = ["svg", "png", "jpeg", "csv"];
const formatList: Record<string, formatType> = {
  svg: { id: "svg", text: "SVG (Scalable Vector Graphics)" },
  png: { id: "png", text: "PNG (Portable Network Graphics)" },
  jpeg: { id: "jpeg", text: "JPEG (Joint Photographic Experts Group)" },
  csv: { id: "csv", text: "CSV (Character-separated values)" },
  html: { id: "html", text: "HTML (Hypertext Markup Language)" },
};

// Export data as
// o Scalable Vector Graphics (SVG)
// o Portable Network Graphics (PNG)
// o Joint Photographic Experts Group (JPEG, JPG)
// o Comma Separated Value File (CSV)

export const ExportModal = React.memo(
  ({
    show,
    setShow,
    onExport,
    formats,
    preselect,
  }: {
    show: boolean;
    formats?: string[];
    preselect?: string;
    setShow: (show: boolean) => void;
    onExport: (type: string) => void;
  }) => {
    // function ExportModal({
    //   show,
    //   setShow,
    //   onExport,
    // }: {
    //   show: boolean;
    //   setShow: (show: boolean) => void;
    //   onExport: (type: string) => void;
    // }) {
    // const { show, setShow, children }: { children: any; show: boolean; setShow: (show: boolean) => void } = props;

    const [showModal, setShowModal] = useState(false);
    const [content, setContent] = useState<any>();
    const [selected, setSelected] = useState<formatType>(Object.values(formatList)[0]);
    // const [body, setBody] = useState<any>();

    const handleClose = useCallback(() => setShowModal(false), []);

    const handleSuccess = useCallback(
      (selected?: formatType) => {
        // console.log("handleSuccess", selected);
        setShowModal(false);
        if (selected) onExport(selected.id);
      },
      [onExport]
    );

    useEffect(() => {
      setShowModal(show);
    }, [show]);

    useEffect(() => {
      setShow(showModal);
    }, [showModal, setShow]);

    useEffect(() => {
      const select = preselect ?? Object.keys(formatList)[0];
      if (select in formatList) {
        if (formats) {
          if (formats.includes(select)) setSelected(formatList[select]);
          else setSelected(formatList[formats[0]]);
        } else setSelected(formatList[select]);
      }
    }, [preselect, formats]);

    useEffect(() => {
      const formatNames = formats
        ? Object.keys(formatList).filter((name) => formats.includes(name))
        : Object.keys(formatList);
      const content = formatNames.map((id, i) => {
        const format = formatList[id];
        return (
          <Radio key={i} id={format.id} name="radioGroup" defaultChecked={format.id === selected.id}>
            {format.text}
            {/* <FormGroup style={{ display: selected?.id === format.id ? undefined : "none" }}>
              Additionall settings of {format.id}
            </FormGroup> */}
          </Radio>
        );
      });

      setContent(
        <Form>
          Export data as
          <FormGroup onChange={(e) => setSelected(formatList[(e.target as Element).id])}>{content}</FormGroup>
        </Form>
      );
    }, [selected, formats]);

    return (
      <>
        <Modal show={showModal} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Export dataset</Modal.Title>
          </Modal.Header>
          <Modal.Body>{content}</Modal.Body>
          <Modal.Footer>
            <Button bsStyle="default" onClick={handleClose}>
              Close
            </Button>
            <Button bsStyle="primary" onClick={() => handleSuccess(selected)}>
              Export
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
);
