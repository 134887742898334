import { ValueOf } from "remirror";
import {
  EntityConstants,
  GenericEntity,
  IEntityMinimalModel,
  IPagination,
  IdTypes,
  StringIndexedDict,
} from "../../../../api/GenericTypes";
// import { GroupBase, StylesConfig } from "react-select";
import { MultiEditMappedItemObject } from "./MultiEditProvider";
import { ObjectShape } from "yup";
import * as yup from "yup";
import { useBulkEdit } from "../../../../api/BaseEntityApi";
import { useCallback, useContext, useMemo } from "react";
import { Id, toast } from "react-toastify";
import { UseFormReset } from "react-hook-form";
import { SessionContext } from "../../../contexts/SessionContext";
import { ResourceName } from "../../../../main/Routing";
import { useEntityApi } from "../../../../api/useEntityApi";
import { projectTo } from "../../../../api/BaseEntityApiHooks";

export const mergeValues = (prevValue: any, newValue: any) => {
  if (Array.isArray(prevValue) && Array.isArray(newValue)) {
    return mergeArrays(prevValue, newValue);
  } else if (typeof prevValue === "string" && typeof newValue === "string") {
    return prevValue + newValue;
  } else {
    return newValue;
  }
};

const mergeArrays = (prevValue: any[], newValue: any[]) => {
  if (!Array.isArray(prevValue) || !Array.isArray(newValue)) return newValue;
  if (prevValue.length === 0) return newValue;
  if (newValue.length === 0) return prevValue;
  if (typeof prevValue[0] !== typeof newValue[0]) throw new Error("Cannot merge arrays with different types");
  if (typeof prevValue[0] === "object" && typeof newValue[0] === "object")
    return mergeMinimalModels(prevValue, newValue);

  return Array.from(new Set([...prevValue, ...newValue]));
};

export const mergeMinimalModels = <Entity extends StringIndexedDict & GenericEntity>(
  prevArr: IEntityMinimalModel<IdTypes>[],
  currArr: IEntityMinimalModel<IdTypes>[]
) => {
  const uniqueMinimalModels = new Map<IdTypes, IEntityMinimalModel<IdTypes>>();
  const concatArr = [...prevArr, ...currArr];
  concatArr.forEach((model) => uniqueMinimalModels.set(model.id, model));
  return Array.from(uniqueMinimalModels.values()) as unknown as ValueOf<Entity>;
};

export const generateBulkEditYupSchema = <Entity extends GenericEntity>(
  items: MultiEditMappedItemObject<Entity>,
  entitySchema: ObjectShape
) => {
  let fullSchema: StringIndexedDict = {};
  Object.entries(items).forEach(([key, entity]) => {
    fullSchema[key] = yup.object().shape(entitySchema);
  });
  return yup.object().shape(fullSchema);
};

export const checkIsDirty = (dirtyField: any): boolean => {
  if (dirtyField === undefined || dirtyField === null) return false;
  if (typeof dirtyField === "boolean") {
    return dirtyField;
  } else if (Array.isArray(dirtyField)) {
    for (const el of dirtyField) {
      if (checkIsDirty(el)) return true;
    }
  } else if (typeof dirtyField === "object") {
    for (const value of Object.values(dirtyField)) {
      if (checkIsDirty(value)) return true;
    }
  }

  return false;
};

export const checkForUnassignedValue = (
  value: IEntityMinimalModel<IdTypes>[] | IEntityMinimalModel<IdTypes> | null | undefined
) => {
  // console.log("GOT", value);
  if (value === null) return value;
  if (value === undefined) return value;
  if (Array.isArray(value)) {
    for (const val of value) {
      // console.log("checking", val);
      if (checkForUnassignedValue(val) === null) return undefined;
    }
  } else if (typeof value === "object") {
    if (value.id === -2) {
      // console.log("Unassigning!");
      return null;
    } else {
      return value;
    }
  } else {
    return value;
  }
  return value;
};

type Result<Entity extends GenericEntity & StringIndexedDict> = { [key in keyof Entity]: any[] };

const isEmpty = (value: any) => {
  if (Array.isArray(value)) {
    if (!value.length) return true;
  } else if (typeof value === "object") {
    if (!value) return true;
  } else if (typeof value === "number") {
    return false;
  } else if (typeof value === "boolean") {
    return false;
  }
  if (!value) return true;
};

interface GetValueResult<Entity extends GenericEntity & StringIndexedDict, Key extends keyof Entity> {
  isEqual: boolean;
  hasEmpty: boolean;
  value: null | Entity[Key];
}
const getValue = <Entity extends GenericEntity & StringIndexedDict, Key extends keyof Entity>(
  values: any[]
): GetValueResult<Entity, Key> => {
  const hasEmpty = values.map((v) => isEmpty(v)).some((v) => v === true);
  // Check for types
  if (new Set(values.map((v) => JSON.stringify(v))).size === 1) {
    // Types are equal --> return default
    return {
      isEqual: true,
      hasEmpty: hasEmpty,
      value: values[0],
    };
  } else {
    // Types are different so we can safely assume multiple values
    return {
      isEqual: false,
      hasEmpty: hasEmpty,
      value: null,
    };
  }
};

export type ConsolidatedValues<Entity extends GenericEntity & StringIndexedDict> = {
  [key in keyof Entity]: GetValueResult<Entity, key>;
};

export const getDefaultValues = <Entity extends GenericEntity & StringIndexedDict>(collectResult: Result<Entity>) => {
  let result: Partial<ConsolidatedValues<Entity>> = {};
  for (const [key, value] of Object.entries(collectResult)) {
    // console.log("getDefaultValue", key, value);
    result[key as keyof Entity] = getValue(value);
  }
  return result as Required<ConsolidatedValues<Entity>>;
};

export const multiEditInitialValues = <Entity extends GenericEntity & StringIndexedDict>(
  items: MultiEditMappedItemObject<Entity>
) => {
  let collectResult: Partial<Result<Entity>> = {};
  // First we collect all properties
  Object.values(items).forEach((entity) => {
    if (typeof entity === "object" && !Array.isArray(entity) && entity !== null)
      Object.keys(entity).forEach((key) => {
        if (!Object.hasOwn(collectResult, key)) collectResult[key as keyof Entity] = [];
      });
  });
  // Now we collect values (entities with missing keys will have undefined values)
  Object.values(items).forEach((entity) => {
    Object.keys(collectResult).forEach((key) => {
      collectResult[key as keyof Entity]?.push(entity?.[key]);
    });
  });
  const result = getDefaultValues(collectResult as Result<Entity>);
  return result;
};

const placeHolder = "[multiple values]";
export const getValueOrDefault = <Entity extends GenericEntity & StringIndexedDict, Key extends keyof Entity>(
  valueResult: GetValueResult<Entity, Key> | undefined
) => {
  if (!valueResult) return undefined;
  if (!valueResult.isEqual) return placeHolder;
  return undefined;
};

interface UseMultiEditUtilsProps<Entity extends GenericEntity> {
  entityConstants: EntityConstants;
  showToast?: boolean;
  beforeSubmit?: (data: MultiEditMappedItemObject<Entity>) => MultiEditMappedItemObject<Entity>; // Perfom some modifications before submitting (This is after validation!)
  beforeReset?: (data: MultiEditMappedItemObject<Entity>) => MultiEditMappedItemObject<Entity>; // Perfom some modifications after submitting before resetting defaults
  onSuccessCallback?: (data: Entity[]) => void;
  onErrorCallback?: () => void;
}
export const useMultiEditUtils = <Entity extends GenericEntity>({
  entityConstants,
  showToast = true,
  beforeSubmit,
  beforeReset,
  onSuccessCallback,
  onErrorCallback,
}: UseMultiEditUtilsProps<Entity>) => {
  const { bulkEditMutationAsync, isLoadingBulkEditMutation } = useEntityApi<Entity>(entityConstants.resource);

  const onSubmit = useCallback(
    async (
      data: MultiEditMappedItemObject<Entity>,
      nChanges: number,
      reset: UseFormReset<MultiEditMappedItemObject<Entity>>,
      editMsg: string = "edited"
    ): Promise<void> => {
      let _toast: Id | undefined = undefined;
      let _data = data;
      if (beforeSubmit) {
        _data = beforeSubmit(_data);
      }

      if (showToast) {
        _toast = toast(
          `Editing ${nChanges} ${nChanges === 1 ? entityConstants.entitySingular : entityConstants.entityPlural}...`,
          {
            position: "top-center",
            autoClose: false,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: true,
            progress: 0,
            type: toast.TYPE.INFO,
          }
        );
      }
      await bulkEditMutationAsync(
        { body: Object.values(_data) },
        {
          onSuccess: (data) => {
            const response = Object.fromEntries(
              Object.entries(data.results).map(([, d]) => [d.id, d])
            ) as MultiEditMappedItemObject<Entity>;
            let _res = response;
            if (beforeReset) _res = beforeReset(_res);
            reset(_res);
            if (showToast && _toast) {
              toast.update(_toast, {
                type: toast.TYPE.SUCCESS,
                render: `Successfully ${editMsg} ${nChanges} ${
                  nChanges === 1 ? entityConstants.entitySingular : entityConstants.entityPlural
                }.`,
                autoClose: 2000,
                // progress: 1,
              });
            }
            onSuccessCallback?.(data.results);
          },
          onError: () => {
            if (_toast !== undefined) toast.dismiss(_toast);
            onErrorCallback?.();
          },
        }
      ).catch(() => {});
    },
    [
      bulkEditMutationAsync,
      beforeReset,
      beforeSubmit,
      entityConstants.entityPlural,
      entityConstants.entitySingular,
      onErrorCallback,
      onSuccessCallback,
      showToast,
    ]
  );

  return { onSubmit, loading: isLoadingBulkEditMutation };
};

interface UseUnpaginateEntitiesProps {
  resource: ResourceName;
  pageSize?: number;
  onProgress?: (progress: number) => void;
}
export const useUnpaginateEntities = ({ resource, pageSize = 1000, onProgress }: UseUnpaginateEntitiesProps) => {
  const { api } = useContext(SessionContext);
  const abortController = useMemo(() => new AbortController(), []);

  // See a useQuery version in BaseEntityApi.ts -> useUnpaginate
  const unpaginateEntities = useCallback(
    async <Entity extends GenericEntity>(ids: Entity["id"][], signal?: AbortSignal) => {
      let results: MultiEditMappedItemObject<Entity> = {};
      if (Array.isArray(ids) && !!ids.length) {
        let page = 1;
        while (Object.keys(results).length < ids.length) {
          const data: IPagination<Entity> = await api
            .post(
              `${resource}/list`,
              { ids: ids, page: page, pageSize: pageSize, includeCount: true },
              undefined,
              signal ?? abortController.signal
            )
            .catch((e) => {
              console.error("Error during fetching", e);
              return;
            });

          if (data) {
            data.results.forEach((res) => {
              results[res.id] = projectTo(res) as Entity;
            });
            onProgress?.((Object.keys(results).length * 100) / (data.count ?? 9999));
            if (!data.hasNext) {
              break;
            } else {
              page += 1;
            }
          } else {
            break;
          }
        }
      }
      return results;
      // console.log("Fetching done");
    },
    [abortController.signal, api, onProgress, pageSize, resource]
  );
  return { unpaginateEntities, abortController };
};

interface UseMultiEditProps<Entity extends GenericEntity> extends UseUnpaginateEntitiesProps {
  onSuccess?: (data: IPagination<Entity>) => void;
  onError?: (err: unknown) => void;
}
export const useMultiEdit = <Entity extends GenericEntity>({
  resource,
  pageSize = 1000,
  onProgress,
  onSuccess,
  onError,
}: UseMultiEditProps<Entity>) => {
  const { unpaginateEntities, abortController } = useUnpaginateEntities({
    resource: resource,
    onProgress: onProgress,
    pageSize: pageSize,
  });

  const { mutateAsync: bulkEdit } = useBulkEdit<Entity>(resource);

  // This is an async method to apply a single payload to a all entities defined by ids
  const applyPayload = useCallback(
    async (ids: Entity["id"][], payload: Partial<Entity>) => {
      const result = await unpaginateEntities<Entity>(ids);
      const modifications = Object.entries(result).map(([, entity]) => ({ ...entity, ...payload } as Entity));
      await bulkEdit(
        { body: modifications },
        {
          onSuccess: onSuccess,
          onError: onError,
        }
      ).catch((e) => {
        console.error(e);
      });
    },
    [bulkEdit, onError, onSuccess, unpaginateEntities]
  );

  return { applyPayload, unpaginateEntities, abortController };
};
