import { trackState } from "./../../ViewerLayout/ViewerTypes";
import { commandTypes, parameterTypes, SerializedPipelineCommand, trackListType } from "../PipelineTypes";
import { Empty } from "./Empty";

export class TrackSelector extends Empty {
  name: string;
  readonly type = commandTypes.trackSelector;

  parameterTypes = { tracks: parameterTypes.trackListSelection };
  parameterSettings = { tracks: { name: "Input tracks", hidden: false } };

  constructor() {
    super();
    this.name = "Track selector";
  }

  run(tracks: trackListType): trackListType {
    const result: trackListType = this.parameter?.tracks(tracks) ?? [];

    console.log("tracks", this.parameter);

    if (result.some((t) => t?.state !== trackState.ready) || result.some((t) => !t?.data)) {
      this.errors.create({
        id: this.id,
        component: "TrackSelector.run",
        message: `Some input tracks are not ready.`,
      });
      return [];
    }

    return result;
  }

  serialize(): SerializedPipelineCommand {
    const result = super.serialize();

    // if (result.parameter) {
    //   let list: string[] = [];
    //   if (this.internalParameter?.tracks) {
    //     const t = this.internalParameter?.tracks as TrackListPattern;
    //     if (t?.tracks) {
    //       t?.tracks.forEach((track, i) => {
    //         // console.log("track", track);
    //         const p = new StringParameter({
    //           name: "Input tracks",
    //           value: "Test",
    //           // inputType: parameterInputTypes.options,
    //           // options: list,
    //         });
    //         // if (result.parameter) result.parameter["Track" + i] = p.serialize();
    //       });
    //       // if (t.tracks?.[0]) list = t.tracks[0].map((t) => t.name);
    //     }
    //   }
    //   const p = new StringParameter({
    //     name: "Input tracks",
    //     value: "Test",
    //     // inputType: parameterInputTypes.options,
    //     // options: list,
    //   });
    //   result.parameter.test = p.serialize();
    // }

    // console.log("serialize", result);
    return result;
  }
}
