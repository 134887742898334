import React, { CSSProperties, useContext, useMemo, useRef, useState } from "react";
import { Viewer } from "./ViewerLayout";
import { Dataset, DatasetFilters, QueueJob, datasetsConstants } from "../api/Datasets";
import { ViewerLayoutSettings } from "./ViewerLayoutTypes";
import { useEntityDetail, useGet } from "../api/BaseEntityApi";
import { LoadingWrapper } from "../common/LoadingWrapper";
import useIntersectionObserver from "../common/helperfunctions/useIntersectionObserver";
import BrandLoader from "../common/loaders/Spinner/BrandLoader";
import { useCallbackRef } from "../common/helperfunctions/useCallbackRef";
import { Link } from "react-router-dom";
import { getDetailLink } from "../main/Routing";
import { attachmentsConstants } from "../api/Attachments";
import { SessionContext } from "../common/contexts/SessionContext";
import { NotSet } from "../common/misc/UIconstants";
import { Alert } from "../common/overlays/Alert/Alert";

interface MinimalViewerProps {
  id: Dataset["id"];
  settings?: Partial<ViewerLayoutSettings> | undefined;
  width?: CSSProperties["width"];
  height?: CSSProperties["height"];
  disableIntersectionObserver?: boolean;
  disableOnClick?: boolean;
}
export const MinimalViewer = ({
  id,
  settings = {
    interactiveMode: false,
    showParameter: false,
    showNavigation: false,
    showTrackList: false,
  },
  width = 300,
  height = 300,
  disableIntersectionObserver = false,
  disableOnClick = false,
}: MinimalViewerProps) => {
  const { route } = useContext(SessionContext);
  const { ref, refObject } = useCallbackRef<HTMLDivElement>();
  const entry = useIntersectionObserver(refObject, {});

  const { data, status, fetchStatus, error, refetch } = useEntityDetail<Dataset, DatasetFilters>(
    datasetsConstants.resource,
    id,
    {
      includeParsingState: true,
    }
  );
  const [queueId, setQueueId] = useState<number>();
  const timer = useRef<NodeJS.Timeout | number | undefined>();

  const { data: queue } = useGet<QueueJob>(`datasets/${queueId}/high_priority`, null, {
    enabled: !!queueId,
  });

  useMemo(() => {
    if (data) {
      if (data.parsingState === "NotYetParsed") {
        console.log("Dataset is not yet parsed, setting priority queue");
        if (!queue) setQueueId(data.id);
      } else {
        if (timer.current) clearInterval(timer.current as NodeJS.Timeout);
      }
    }
  }, [data, queue]);

  useMemo(() => {
    if (queueId) {
      const intervalId = setInterval(refetch, 3000);
      if (!timer.current) timer.current = intervalId;
    }
    return () => {
      clearInterval(timer.current as NodeJS.Timeout);
      timer.current = undefined;
    };
  }, [queueId, refetch]);

  const entryVisible = disableIntersectionObserver ? true : entry?.isIntersecting;

  const viewerStateSwitch = useMemo(() => {
    switch (data?.parsingState) {
      case "NotYetParsed":
        return (
          <div style={{ width: width, height: height, cursor: "pointer" }}>
            <BrandLoader />
          </div>
        );
      case "ParsedSuccessfully":
        if (disableOnClick) {
          return (
            <div
              style={{ width: width, height: height, cursor: "pointer" }}
              title={"Show viewer"}
              onClick={(e) => {
                // e.preventDefault();
                // e.stopPropagation();
                // if (!disableOnClick) setShowModal((prev) => !prev);
              }}
            >
              {entryVisible ? <Viewer ids={[id]} settings={settings} /> : null}
            </div>
          );
        }
        return (
          <Link to={route(getDetailLink(attachmentsConstants.frontendIndexRoute, id))}>
            <div
              style={{ width: width, height: height, cursor: "pointer" }}
              title={"Show viewer"}
              onClick={(e) => {
                // e.preventDefault();
                // e.stopPropagation();
                // if (!disableOnClick) setShowModal((prev) => !prev);
              }}
            >
              {entryVisible ? <Viewer ids={[id]} settings={settings} /> : null}
            </div>
          </Link>
        );
      case "ParsingFailed":
        return (
          <Alert
            message={data.parserLogs?.join(",\n") ?? "Parsing failed"}
            fit
            centered
            type="danger"
            style={{ margin: 0 }}
          />
        );
      default:
        return NotSet;
    }
  }, [data?.parserLogs, data?.parsingState, disableOnClick, entryVisible, height, id, route, settings, width]);

  return (
    <LoadingWrapper status={status} fetchStatus={fetchStatus} error={error}>
      <div ref={ref}>{viewerStateSwitch}</div>
    </LoadingWrapper>
  );
};
