import { UseQueryOptions } from "@tanstack/react-query";
import { useGet } from "./BaseEntityApi";
import { Features } from "./ApiTypes";

export interface Version {
  application: string;
  vendor: string;
  version: string;
  uid: string;
  apiVersion: string;
  edition: string;
  groups: number;
  licensedTo: string;
  daysUntilExpiration: number;
  expirationDate: string;
  homepage: string;
  email: string;
  features: Features;
}

export const useVersion = (options?: UseQueryOptions<any, unknown, Version>) =>
  useGet<Version>("version/detail", null, options);
