/**
 * This function is designated as a consistent datetime formatting tool
 * Author: CS
 * @param: JS date
 * @returns: object
 */

import { CSSProperties } from "react";
import { NotAvailable, NotSet } from "../misc/UIconstants";
import { TimeSpan } from "../formfields/DurationPickerFormField/DurationPickerFormField";

const locale = Intl.DateTimeFormat().resolvedOptions().locale;
const monthFormat = new Intl.DateTimeFormat(locale, { month: "2-digit" });
const monthShortFormat = new Intl.DateTimeFormat(locale, { month: "short" });
const monthLongFormat = new Intl.DateTimeFormat(locale, { month: "long" });
const dayFormat = new Intl.DateTimeFormat(locale, { day: "2-digit" });
const weekdayFormat = new Intl.DateTimeFormat(locale, { weekday: "short" });
const weekdayShortFormat = new Intl.DateTimeFormat(locale, { weekday: "short" });
const weekdayLongFormat = new Intl.DateTimeFormat(locale, { weekday: "long" });
const yearFormat = new Intl.DateTimeFormat(locale, { year: "numeric" });
const timeformat = new Intl.DateTimeFormat(locale, {
  hour: "numeric",
  minute: "numeric",
  second: "numeric",
  hour12: false,
  // timeZone: "UTC",
  // timeZoneName: "short",
});

export interface FormattedDate {
  month: string;
  monthShort: string;
  monthLong: string;
  day: string;
  weekday: string;
  weekdayShort: string;
  weekdayLong: string;
  year: string;
  time: string;
  timezone: string;
  elapsedDays: number;
}
export const formatDate = (date: Date): FormattedDate => {
  return {
    month: monthFormat.format(date),
    monthShort: monthShortFormat.format(date),
    monthLong: monthLongFormat.format(date),
    day: dayFormat.format(date),
    weekday: weekdayFormat.format(date),
    weekdayShort: weekdayShortFormat.format(date),
    weekdayLong: weekdayLongFormat.format(date),
    year: yearFormat.format(date),
    time: timeformat.format(date),
    timezone: "UTC",
    elapsedDays: Math.floor((new Date(new Date().toISOString()).getTime() - date.getTime()) / (1000 * 3600 * 24)),
  };
};

/**
 * RegExp to test a string for a ISO 8601 Date spec
 *  YYYY
 *  YYYY-MM
 *  YYYY-MM-DD
 *  YYYY-MM-DDThh:mmTZD
 *  YYYY-MM-DDThh:mm:ssTZD
 *  YYYY-MM-DDThh:mm:ss.sTZD
 * @see: https://www.w3.org/TR/NOTE-datetime
 * @type {RegExp}
 */
const ISO_8601 = /^\d{4}(-\d\d(-\d\d(T\d\d:\d\d(:\d\d)?(\.\d+)?(([+-]\d\d:\d\d)|Z)?)?)?)?$/i;

/**
 * RegExp to test a string for a full ISO 8601 Date
 * Does not do any sort of date validation, only checks if the string is according to the ISO 8601 spec.
 *  YYYY-MM-DDThh:mm:ss
 *  YYYY-MM-DDThh:mm:ssTZD
 *  YYYY-MM-DDThh:mm:ss.sTZD
 * @see: https://www.w3.org/TR/NOTE-datetime
 * @type {RegExp}
 */
// const ISO_8601_FULL = /^\d{4}-\d\d-\d\dT\d\d:\d\d:\d\d(\.\d+)?(([+-]\d\d:\d\d)|Z)?$/i

const timeRegex = /(?:[01]\d|2[0-3]):(?:[0-5]\d):(?:[0-5]\d)/;
const fullTimeRegex = /([01]\d|2[0-3]):([0-5]\d):([0-5]\d)(?:\.)(\d+)/;

export const timeSpanRegex =
  /^(?:(-))?(?:(?:(0*[0-9]+)[.])?(?:(0*[2][0-3]|0*[1][0-9]|0*[0-9])[:]))?((?<=:)0*[0-5]?[0-9]|0*[5-9]?[0-9](?=[:]))(?:[:](0*[0-5]?[0-9](?:[.][0-9]{0,7})?))?$/;

export const isTime = (date: any) => {
  if (typeof date === "string") {
    return timeRegex.test(date);
  } else {
    return false;
  }
};

export const isTimeSpan = (date: any) => {
  if (typeof date === "string") {
    return timeSpanRegex.test(date);
  } else {
    return false;
  }
};

export const totalSecondsToTimeSpan = (totalSeconds: number | null | undefined): TimeSpan => {
  if (!totalSeconds) return { Days: 0, Hours: 0, Minutes: 0, Seconds: 0, Milliseconds: 0 };
  return {
    Days: Math.floor(totalSeconds / 86400),
    Hours: Math.floor((totalSeconds % 86400) / 3600),
    Minutes: Math.floor((totalSeconds % 3600) / 60),
    Seconds: Math.floor(totalSeconds % 60),
    Milliseconds: +(totalSeconds % 1).toFixed(3) * 1e3,
  };
};

export const timeSpanToTotalSeconds = (timeSpan: TimeSpan): number | null => {
  if (!timeSpan) return null;
  const number =
    (timeSpan.Days ?? 0) * 86400 +
    (timeSpan.Hours ?? 0) * 3600 +
    (timeSpan.Minutes ?? 0) * 60 +
    (timeSpan.Seconds ?? 0) +
    (timeSpan.Milliseconds ?? 0) / 1e3;
  return number;
};

export const TimeSpanRenderer = (timespan: TimeSpan) => {
  if (!timespan) {
    return NotAvailable;
  }
  const { Days, Hours, Minutes, Seconds, Milliseconds } = timespan;
  return `${Days} days ${Hours} hours ${Minutes} minutes ${Seconds} seconds ${
    Milliseconds ? `${Milliseconds} milliseconds` : ""
  }`;
};

export const isDate = (date: any) => {
  if (typeof date === "string") {
    return ISO_8601.test(date);
  } else {
    return false;
  }
};
const time_ago = (time: string | number | Date) => {
  let _time: number = 0;
  switch (typeof time) {
    case "number":
      _time = time;
      break;
    case "string":
      _time = +new Date(time);
      break;
    case "object":
      if (time.constructor === Date) _time = time.getTime();
      break;
    default:
      _time = +new Date();
  }

  const time_formats = [
    [60, "seconds", 1], // 60
    [120, "1 minute ago", "1 minute from now"], // 60*2
    [3600, "minutes", 60], // 60*60, 60
    [7200, "1 hour ago", "1 hour from now"], // 60*60*2
    [86400, "hours", 3600], // 60*60*24, 60*60
    [172800, "Yesterday", "Tomorrow"], // 60*60*24*2
    [604800, "days", 86400], // 60*60*24*7, 60*60*24
    [1209600, "Last week", "Next week"], // 60*60*24*7*4*2
    [2419200, "weeks", 604800], // 60*60*24*7*4, 60*60*24*7
    [4838400, "Last month", "Next month"], // 60*60*24*7*4*2
    [29030400, "months", 2419200], // 60*60*24*7*4*12, 60*60*24*7*4
    [58060800, "Last year", "Next year"], // 60*60*24*7*4*12*2
    [2903040000, "years", 29030400], // 60*60*24*7*4*12*100, 60*60*24*7*4*12
    [5806080000, "Last century", "Next century"], // 60*60*24*7*4*12*100*2
    [58060800000, "centuries", 2903040000], // 60*60*24*7*4*12*100*20, 60*60*24*7*4*12*100
  ];

  let seconds = (+new Date() - _time) / 1000,
    token = "ago",
    list_choice = 1;

  if (seconds === 0) {
    return "Just now";
  }
  if (seconds < 0) {
    seconds = Math.abs(seconds);
    token = "from now";
    list_choice = 2;
  }
  let i = 0;
  let format: any;
  while ((format = time_formats[i++]))
    if (seconds < format[0]) {
      if (typeof format[2] == "string") return format[list_choice];
      else return Math.floor(seconds / format[2]) + " " + format[1] + " " + token;
    }
  return _time;
};

export const RenderTime = ({ time }: { time: string | undefined | null }) => {
  if (!time) return NotAvailable;
  if (!fullTimeRegex.test(time)) return NotAvailable;
  const [, hours, minutes, seconds, milliseconds] = fullTimeRegex.exec(time) as RegExpExecArray;
  let _date = new Date();
  _date.setHours(+hours);
  _date.setMinutes(+minutes);
  _date.setSeconds(+seconds);
  _date.setMilliseconds(+milliseconds);
  return <>{date2localDate(_date).toLocaleTimeString("en-GB")}</>;
};

export const iso2localDate = (date: string | undefined | null) => {
  let d: Date;
  if (date) {
    d = new Date(date);
  } else {
    d = new Date();
  }
  return new Date(d.getTime() - d.getTimezoneOffset() * 60000);
};

export const date2localDate = (date: Date | string | number | undefined | null) => {
  let d = new Date();
  if (date instanceof Date) {
    d = date;
  } else if (typeof date === "string" || typeof date === "number") {
    d = new Date(date);
  }
  return new Date(d.getTime() - d.getTimezoneOffset() * 60000);
};

// Format date as YYYY-MM-DD local
export const formatISOLocal = (d: Date) => {
  let z = (n: number) => (n < 10 ? "0" : "") + n;
  return d.getFullYear() + "-" + z(d.getMonth() + 1) + "-" + z(d.getDate());
};

const leadingZero = (num: number) => {
  return num < 10 ? `0${num}` : num;
};

export const formatIsoDate = (d: Date) => {
  const datestr = `${d.getFullYear()}-${leadingZero(d.getMonth() + 1)}-${leadingZero(d.getDate())}`;
  const timestr = `${leadingZero(d.getHours())}:${leadingZero(d.getMinutes())}:${leadingZero(d.getSeconds())}`;
  return { datestr, timestr };
};

interface DateTimeRendererProps {
  date: string | undefined;
  includeDate?: boolean;
  includeTime?: boolean;
  includeElapsed?: boolean;
  style?: CSSProperties;
}
export const DateTimeRenderer = ({
  date,
  includeDate = true,
  includeTime = true,
  includeElapsed = true,
  style,
}: DateTimeRendererProps) => {
  // const d = local2isoDate(date);
  // const datestr = d.toISOString().split("T")[0];
  // const timestr = timeformat.format(d);
  let datestr = "-";
  let timestr = "";

  if (date !== undefined && date !== null) {
    const d = new Date(date);
    if (d instanceof Date && !isNaN(+d)) {
      const { datestr: _datestr, timestr: _timestr } = formatIsoDate(d);
      datestr = _datestr;
      timestr = _timestr;
    }
    return (
      <div className={"container_date"} style={style}>
        <div
          className="flex row-nowrap align-center"
          style={{
            fontWeight: "normal",
            whiteSpace: "nowrap",
            // minWidth: "max-content",
            overflow: "hidden",
          }}
        >
          {includeDate && (
            <div className="ellipsisContainer">
              <span>{datestr}</span>
            </div>
          )}
          {includeTime && (
            <div className="ellipsisContainer" style={{ paddingLeft: "0.5rem", color: "var(--gray-400)" }}>
              <span>{timestr}</span>
            </div>
          )}
        </div>
        {includeElapsed && (
          <div className={"container_date_days"}>{d instanceof Date && !isNaN(+d) ? time_ago(d) : ""}</div>
        )}
      </div>
    );
  } else {
    return <div>{NotSet}</div>;
  }
};
