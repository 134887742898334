import { Dataset, DatasetFilters } from "./Datasets";
import { EntityConstants } from "./GenericTypes";

// This is a pseudo entity for viewable entities and will be removed at some point
// The resource does not work
export const attachmentsConstants: EntityConstants<Dataset, DatasetFilters> = {
  resource: "datasets",
  frontendIndexRoute: "attachments",
  entitySingular: "attachment",
  entityPlural: "attachments",
  icon: "folder-archive",
};
