import { DefinedPropertyObject, IEntityMinimalModel } from "../../../api/GenericTypes";
import { ToggleFormField } from "../../../common/formfields/ToggleFormField";
import { LabNotebookTemplate, LabNotebookTemplateFilters } from "../../types/LabNotebookTemplate";
import { FilterSidebarProps, useFilterSidebar } from "../../../common/sidebar/filtersidebar/FilterSidebar";
import { EntityFilterSideBar } from "../../../common/sidebar/filtersidebar/EntityFilterBar";
import { ProjectsVirtualizedSelectForm } from "../../../common/forms/EntityForms/formsVirtualized/ProjectsVirtualizedSelectForm";
import { PersonsVirtualizedSelectForm } from "../../../common/forms/EntityForms/formsVirtualized/PersonsVirtualizedSelectForm";
import { SplitDatePickerFormField } from "../../../common/formfields/DatePickerFormField/SplitDatePickerFormField";

export interface LabNotebookTemplateFilterForm {
  projectIds?: IEntityMinimalModel[] | undefined | null;
  createdByIds?: IEntityMinimalModel[] | undefined | null;
  createdFrom?: Date | undefined | null;
  createdTo?: Date | undefined | null;
  modifiedByIds?: IEntityMinimalModel[] | undefined | null;
  modifiedFrom?: Date | undefined | null;
  modifiedTo?: Date | undefined | null;
  includeSoftDeleted?: boolean | undefined | null;
  isSoftDeleted?: boolean | undefined | null;
}
const defaultValues: DefinedPropertyObject<LabNotebookTemplateFilterForm> = {
  projectIds: null,
  createdByIds: null,
  createdFrom: null,
  createdTo: null,
  modifiedByIds: null,
  modifiedFrom: null,
  modifiedTo: null,
  includeSoftDeleted: false,
  isSoftDeleted: false,
};
const formToFilters = (form: LabNotebookTemplateFilterForm): LabNotebookTemplateFilters => {
  let filters: LabNotebookTemplateFilters = {};

  filters.projectIds =
    Array.isArray(form.projectIds) && form.projectIds.length > 0 ? form.projectIds?.map((d) => d.id) : null;
  filters.createdByIds =
    Array.isArray(form.createdByIds) && form.createdByIds.length > 0 ? form.createdByIds?.map((d) => d.id) : null;
  filters.createdFrom = form.createdFrom ? form.createdFrom.toISOString() : null;
  filters.createdTo = form.createdTo ? form.createdTo.toISOString() : null;
  filters.modifiedByIds =
    Array.isArray(form.modifiedByIds) && form.modifiedByIds.length > 0 ? form.modifiedByIds?.map((d) => d.id) : null;
  filters.modifiedFrom = form.modifiedFrom ? form.modifiedFrom.toISOString() : null;
  filters.modifiedTo = form.modifiedTo ? form.modifiedTo.toISOString() : null;
  filters.includeSoftDeleted = form.includeSoftDeleted || false;
  filters.isSoftDeleted = form.isSoftDeleted || false;

  return filters;
};
const defaultFilters: LabNotebookTemplateFilters = { orderBy: "NAME_ASC" };

export const LabNotebookTemplateFilterBar = ({
  initialValues,
  setFilters,
  onFormChange,
  dispatchTabStore,
  tabsLoading,
  currentTab,
}: FilterSidebarProps<LabNotebookTemplate, LabNotebookTemplateFilterForm, LabNotebookTemplateFilters>) => {
  const { control, onReset } = useFilterSidebar({
    initialValues,
    defaultValues,
    defaultFilters,
    formToFilters,
    onFormChange,
    dispatchTabStore,
    setFilters,
  });

  return (
    <EntityFilterSideBar onReset={onReset}>
      <ProjectsVirtualizedSelectForm id="projectIds" control={control} isMulti />

      {currentTab !== "mydata" && (
        <PersonsVirtualizedSelectForm id="createdByIds" label="Created by" control={control} isMulti />
      )}
      <SplitDatePickerFormField
        control={control}
        idStart="createdFrom"
        idEnd="createdTo"
        label="Creation date (from - to)"
        horizontal={false}
        showTimeSelect
      />
      <PersonsVirtualizedSelectForm id="modifiedByIds" label="Modified by" control={control} isMulti />

      <SplitDatePickerFormField
        control={control}
        idStart="modifiedFrom"
        idEnd="modifiedTo"
        label="Modification date (from - to)"
        horizontal={false}
        showTimeSelect
      />

      {currentTab !== "trash" && (
        <ToggleFormField id={"includeSoftDeleted"} control={control} label={"Include trashed"} horizontal />
      )}
      {currentTab !== "trash" && (
        <ToggleFormField id={"isSoftDeleted"} control={control} label={"Is trashed"} horizontal />
      )}
    </EntityFilterSideBar>
  );
};
