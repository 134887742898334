import { EntityDetailViewProps, EntityEditFormProps } from "../common/entity/EntityInterfaces";
import { EntityEditForm, EntityEditFormPage } from "../common/entity/EntityEditForm";
import { EntityDetailPage, EntityDetailView } from "../common/entity/EntityDetailPage";
import { AttachmentForm } from "./AttachmentForm";
import { AttachmentsDetail } from "./AttachmentsDetail";
import { EntityTablePage } from "../common/entity/EntityTablePage";
import { AttachmentsTable } from "./Table/AttachmentsTable";

// Edit
export const AttachmentEditForm = ({ id }: EntityEditFormProps<"attachments">) => {
  return (
    <EntityEditForm id={id} entityTypeId={"attachments"} filters={{ includeViewableEntities: true }}>
      {(props) => <AttachmentForm {...props} typeId={props.initialValues?.customType?.id} />}
    </EntityEditForm>
  );
};

export const AttachmentEditFormPage = () => {
  return <EntityEditFormPage<"attachments"> isIntId>{(id) => <AttachmentEditForm id={id} />}</EntityEditFormPage>;
};

// Detail
export const AttachmentsDetailView = ({ id }: EntityDetailViewProps<"attachments">) => {
  return (
    <EntityDetailView id={id} entityTypeId={"attachments"} filters={{ includeViewableEntities: true }}>
      {(props) => <AttachmentsDetail {...props} />}
    </EntityDetailView>
  );
};

export const AttachmentDetailsPage = () => {
  return <EntityDetailPage<"attachments"> isIntId>{(id) => <AttachmentsDetailView id={id} />}</EntityDetailPage>;
};

export interface AttachmentsTableOptions {
  setCurrentTab?: "default" | "notebookAttachments" | "customFieldAttachments";
}

export const AttachmentsTablePage = ({ setCurrentTab }: AttachmentsTableOptions) => {
  return (
    <EntityTablePage entityTypeId={"attachments"}>
      {(props) => <AttachmentsTable {...props} setCurrentTab={setCurrentTab} />}
    </EntityTablePage>
  );
};
