import { useHelpers } from "@remirror/react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useResizeDetector } from "react-resize-detector";
import { useDebouncedValue } from "../../../../../../../common/helperfunctions/useDebouncedValue";
import { SourceInformation } from "../../../../../../../Dataset/tiles/SourceInformation/SourceInformation";
import { Viewer } from "../../../../../../../ViewerLayout/ViewerLayout";
import { useEntityDetail } from "../../../../../../../api/BaseEntityApi";
import { Dataset, DatasetFieldLabels, DatasetFilters, datasetsConstants } from "../../../../../../../api/Datasets";
import { LoadingWrapper } from "../../../../../../../common/LoadingWrapper";
import { useIntersectionObserverWithState } from "../../../../../../../common/helperfunctions/useIntersectionObserver";
import { Alert } from "../../../../../../../common/overlays/Alert/Alert";
import { ServerError } from "../../../../../../../common/helperfunctions/ApiError";
import { DatasetAdditionalSettings, EntityAttributes } from "../../entity-types";
import { attachmentsConstants } from "../../../../../../../api/Attachments";
import { Table } from "../../../../../../../common/panels/Detail/DetailTable";
import { DatasetsDetailTable } from "../../../../../../../Dataset/DatasetsDetailTable";
import { compareAndUpdateAttributes } from "../../EntityViews";
import { GenericEntityConstantsEntities } from "../../../../../../../api/GenericConstants";
import { AttachmentsDetailTable } from "../../../../../../../Attachments/AttachmentsDetail";

export const DatasetWrapper = ({
  entity,
  attrs,
  getPosition,
  updateAttributes,
  onDoubleClick,
  ignoreIntersectionObserver = false,
}: {
  entity: Dataset;
  attrs: EntityAttributes<DatasetAdditionalSettings>;
  getPosition?: any;
  updateAttributes: (attrs: EntityAttributes<DatasetAdditionalSettings>) => void;
  onDoubleClick?: (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    dataset: Dataset,
    entityTypeId: GenericEntityConstantsEntities
  ) => void;
  ignoreIntersectionObserver?: boolean;
}) => {
  // const [width] = useState<number | undefined>(attrs.additionalSettings?.width);
  const [height, setHeight] = useState<number | undefined>(attrs.additionalSettings?.height ?? 300);
  const { ref, width: datasetContainerWidth, height: datasetContainerHeight } = useResizeDetector();
  const debouncedWidth = useDebouncedValue(datasetContainerWidth, 300);
  const debouncedHeight = useDebouncedValue(datasetContainerHeight, 300);

  const { isViewEditable } = useHelpers();
  const editable = useMemo(() => {
    return isViewEditable();
  }, [isViewEditable]);

  const { data, status, fetchStatus, refetch, error } = useEntityDetail<Dataset, DatasetFilters>(
    "datasets",
    entity.id,
    {
      includeRelations: true,
      includeCount: true,

      includeParsedMetadata: true,
      includeParsingState: true,
    },
    {
      enabled: !!entity.id,
    }
  );

  useEffect(() => {
    let interval: NodeJS.Timeout;
    if (data?.parsingState === "NotYetParsed") {
      interval = setTimeout(() => {
        refetch();
      }, 3000);
    }

    return () => {
      clearTimeout(interval);
    };
  }, [data?.parsingState, refetch]);

  const _error = useMemo(() => {
    if (typeof error === "object") {
      const message = (error as any)?.message;
      const details = (error as any)?.details;
      const status = (error as any)?.status;

      switch (status) {
        case 403:
          return new ServerError({
            title: message,
            details:
              "You do not have the necessary permissions to view this dataset. Ask for permission to any project this dataset is part of to view it!",
            status,
          });
        case 404:
          return new ServerError({
            title: message,
            details: `${
              entity.isViewableEntity ? attachmentsConstants.entitySingular : datasetsConstants.entitySingular
            } with ID: ${entity.id} could not be found!`,
            status,
          });

        default:
          return new ServerError({ title: message, details, status });
      }
    } else {
      return error;
    }
  }, [error, entity.isViewableEntity, entity.id]);

  useEffect(() => {
    if (!!debouncedWidth && !!debouncedHeight) {
      let timeout: NodeJS.Timeout;
      timeout = setTimeout(() => {
        setHeight(debouncedHeight);
      }, 300);
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [debouncedWidth, debouncedHeight]);

  useEffect(() => {
    if (height !== attrs.additionalSettings?.height) {
      compareAndUpdateAttributes(
        { ...attrs, additionalSettings: { ...attrs.additionalSettings, height } },
        attrs,
        updateAttributes
      );
    }
  }, [attrs, height, updateAttributes]);

  // Intersection observer
  // const entry = useIntersectionObserver(ref, { freezeOnceVisible: true });
  const { entry, setCurrentNode } = useIntersectionObserverWithState({ freezeOnceVisible: true });
  const refCallback = useCallback(
    (node: Element | null) => {
      setCurrentNode(node);
      ref.current = node;
    },
    [ref, setCurrentNode]
  );
  const isIntersecting = ignoreIntersectionObserver || !!entry?.isIntersecting;

  return (
    <LoadingWrapper status={status} fetchStatus={fetchStatus} error={_error}>
      {data ? (
        <div style={{ width: "100%" }}>
          {/* Details */}
          {attrs.additionalSettings?.showDetails && (
            <div style={{ display: "flex", flexDirection: "column", padding: "0 5px" }}>
              {attrs.additionalSettings.showDetails && (
                <>
                  {!data.isViewableEntity ? (
                    <DatasetsDetailTable
                      entity={entity}
                      entityConstants={datasetsConstants}
                      fieldLabels={DatasetFieldLabels}
                      hideHeader={
                        !(
                          (attrs.additionalSettings.showSourceInformation && !data.isViewableEntity) ||
                          attrs.additionalSettings.showViewer ||
                          (attrs.additionalSettings.showParameters && !data.isViewableEntity)
                        )
                      }
                    />
                  ) : (
                    <AttachmentsDetailTable
                      entity={entity}
                      entityConstants={attachmentsConstants}
                      fieldLabels={DatasetFieldLabels}
                    />
                  )}
                </>
              )}
            </div>
          )}
          {/* Source information */}
          {attrs.additionalSettings?.showSourceInformation && !data.isViewableEntity && (
            <div style={{ display: "flex", flexDirection: "column", padding: "0 5px" }}>
              {attrs.additionalSettings.showSourceInformation && (
                <Table noPadding>
                  {(attrs.additionalSettings.showDetails ||
                    attrs.additionalSettings.showViewer ||
                    (attrs.additionalSettings.showParameters && !data.isViewableEntity)) && (
                    <Table.Head>Source information</Table.Head>
                  )}
                  <Table.Body>
                    <SourceInformation dataset={data} tile={false} />
                  </Table.Body>
                </Table>
              )}
            </div>
          )}
          {/* Viewer */}
          {attrs.additionalSettings?.showViewer && (
            <div style={{ display: "flex", flexDirection: "column", padding: "0 5px" }}>
              <Table noPadding>
                {(attrs.additionalSettings.showDetails ||
                  (attrs.additionalSettings.showSourceInformation && !data.isViewableEntity) ||
                  (attrs.additionalSettings.showParameters && !data.isViewableEntity)) && (
                  <Table.Head>Viewer</Table.Head>
                )}
                <Table.Body>
                  <div style={{ paddingTop: "5px" }}>
                    <div
                      ref={refCallback}
                      data-toggle="tooltip"
                      data-placement="bottom"
                      title={
                        onDoubleClick && (attrs.entityTypeId === "datasets" || attrs.entityTypeId === "attachments")
                          ? "Double click to open in viewer"
                          : `${data.name} (ID: ${data.id})`
                      }
                      style={{
                        resize: editable ? "vertical" : "none",
                        // width: settings.width ? settings.width + "px" : undefined,
                        height: `${height ?? 300}px`,
                        width: "100%",
                        cursor: "pointer",
                        overflow: "hidden",
                      }}
                      onDoubleClick={(e) => {
                        onDoubleClick &&
                          (attrs.entityTypeId === "datasets" || attrs.entityTypeId === "attachments") &&
                          onDoubleClick(e, data, attrs.entityTypeId === "datasets" ? "datasets" : "attachments");
                      }}
                    >
                      {isIntersecting ? (
                        <LoadingWrapper
                          status={data.parsingState === "NotYetParsed" ? "loading" : status}
                          fetchStatus={data.parsingState === "NotYetParsed" ? "fetching" : fetchStatus}
                          type="skeleton-block"
                        >
                          <Viewer
                            ids={[data.id]}
                            settings={{
                              showViewer: true,
                              interactiveMode: false,
                              showParameter: false,
                              showNavigation: false,
                              showTrackList: false,
                            }}
                          />
                        </LoadingWrapper>
                      ) : (
                        <LoadingWrapper
                          status={data.parsingState === "NotYetParsed" ? "loading" : status}
                          fetchStatus={data.parsingState === "NotYetParsed" ? "fetching" : fetchStatus}
                          type="skeleton-block"
                        >
                          <div style={{ width: "100%", height: "100%" }} />
                        </LoadingWrapper>
                      )}
                    </div>
                  </div>
                </Table.Body>
              </Table>
            </div>
          )}
          {/* Parameters */}
          {attrs.additionalSettings?.showParameters && !data.isViewableEntity && (
            <div style={{ display: "flex", flexDirection: "column", padding: "0 5px" }}>
              <Table noPadding>
                {(attrs.additionalSettings.showDetails ||
                  attrs.additionalSettings.showViewer ||
                  (attrs.additionalSettings.showSourceInformation && !data.isViewableEntity)) && (
                  <Table.Head>Parameters</Table.Head>
                )}
                <Table.Body>
                  <div style={{ height: "300px", width: "100%" }}>
                    <LoadingWrapper
                      status={data.parsingState === "NotYetParsed" ? "loading" : status}
                      fetchStatus={data.parsingState === "NotYetParsed" ? "fetching" : fetchStatus}
                      type="skeleton-pairs"
                    >
                      <Viewer
                        ids={[data.id]}
                        settings={{
                          showViewer: false,
                          interactiveMode: false,
                          showParameter: true,
                          showNavigation: false,
                          showTrackList: false,
                        }}
                      />
                    </LoadingWrapper>
                  </div>
                </Table.Body>
              </Table>
            </div>
          )}
          {/* Nothing selected */}
          {!attrs.additionalSettings?.showViewer &&
            !attrs.additionalSettings?.showDetails &&
            !attrs.additionalSettings?.showSourceInformation &&
            !attrs.additionalSettings?.showParameters && (
              <Alert style={{ margin: 0 }} type="light" message="No content selected" fit centered />
            )}
        </div>
      ) : (
        <Alert type="light" message="No data" fit centered />
      )}
    </LoadingWrapper>
  );
};
