import React, { useEffect } from "react";
import { Path, PathValue, useFieldArray, useForm } from "react-hook-form";
import { GenericControllerProvider } from "../../../forms/common/GenericControllerProvider";
import { StringIndexedDict } from "../../../../api/GenericTypes";
import {
  convertPathValueToDate,
  SingleDatePickerFormField,
  SingleDatePickerFormFieldProps,
} from "../SingleDatePickerFormField";
import { LucideIcon } from "../../../icon/LucideIcon";

export const DateArrayPickerFormFieldWrapper = <Entity extends StringIndexedDict>(
  props: SingleDatePickerFormFieldProps<Entity>
) => {
  return (
    <GenericControllerProvider control={props.control} id={props.id}>
      {({ value, onChange }) => <DateArrayPickerFormField {...props} value={value} onChange={onChange} />}
    </GenericControllerProvider>
  );
};

interface MultiDate {
  array: { date: any }[] | null;
}

export const DateArrayPickerFormField = <Entity extends StringIndexedDict>(
  props: Omit<SingleDatePickerFormFieldProps<Entity>, "control"> & {
    value: PathValue<Entity, Path<Entity>>;
    onChange: (...event: any[]) => void;
  }
) => {
  const { control, watch } = useForm<MultiDate>({
    values: {
      array:
        Array.isArray(props.value) && !!props.value.length
          ? props.value.map((v: any) => ({ date: v }))
          : [{ date: null }],
    },
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "array",
    keyName: "id",
  });

  useEffect(() => {
    const { unsubscribe } = watch((value) => {
      const val =
        Array.isArray(value.array) && !!value.array.length
          ? value.array
              // .filter((v) => v && v.date)
              .map((v) => (v?.date ? convertPathValueToDate(v.date)?.toISOString() : null))
          : [];
      const isValid = !!val.filter((v) => v).length;
      props.onChange(isValid ? val : null);
    });
    return () => unsubscribe();
  }, [props, watch]);

  return (
    <div className="flex col-nowrap">
      {fields.map((field, index) => (
        <SingleDatePickerFormField
          {...props}
          control={control}
          id={`array.${index}.date`}
          key={field.id}
          label={`${index === 0 ? props.label : " "}`}
          errorIdOverride={props.id as any}
        >
          {index > 0 && (
            <button
              className="btn btn-xs btn-ghost-secondary"
              type="button"
              onClick={() => remove(index)}
              title={`Remove ${props.type}`}
            >
              <LucideIcon name="x" />
            </button>
          )}
        </SingleDatePickerFormField>
      ))}
      <div className="row form-group">
        <div className="col-sm-3 control-label" />
        <div className="col-sm-9">
          <button
            className="btn btn-block btn-ghost-primary"
            type="button"
            onClick={() => append({ date: null })}
            title={`Add ${props.type}`}
          >
            <LucideIcon name="plus" /> Add {props.type}
          </button>
        </div>
      </div>
    </div>
  );
};
