import { EntityConstants, ICustomTypedEntity, IEntityMinimalModel, IGenericEntity } from "../../../api/GenericTypes";
import { Control, FieldErrors, Path, UseFormRegister, UseFormSetValue, useWatch } from "react-hook-form";
import {
  processCustomTypeYupSchema,
  processInitialValuesForTypedEntity,
  processValuesForTypedEntity,
} from "./CustomTypeValidationUtils";
import * as yup from "yup";
import { useCustomType, UseCustomTypeProps, useCustomTypes, UseCustomTypesProps } from "./useCustomTypes";
import { useState, useMemo, useEffect, useCallback } from "react";
import { AlertModal } from "../../../common/modals/AlertModal/AlertModal";
import { MultiEditMappedItemObject } from "../../../common/forms/MultiEditForms/common/MultiEditProvider";
import { CustomType, CustomTypeFilters } from "../../../api/CustomTypes";
import { CustomTypeFormRenderer } from "./CustomTypeFormRenderer";
import { CustomTypesVirtualizedSelectForm } from "../../../common/forms/EntityForms/formsVirtualized/CustomTypesVirtualizedSelectForm";
import { InventoriesVirtualizedSelectForm } from "../../../common/forms/EntityForms/formsVirtualized/InventoriesVirtualizedSelectForm";

interface UseCustomTypeFormProps<Entity extends ICustomTypedEntity> extends UseCustomTypeProps, UseCustomTypesProps {
  initialValues?: Partial<Entity>;
  formSchema: yup.ObjectShape;
  featureFlag?: boolean;
}

export const useCustomTypeForm = <Entity extends ICustomTypedEntity>({
  initialValues,
  formSchema,
  typeId,
  filters,
  entityType,
  featureFlag = true,
}: UseCustomTypeFormProps<Entity>) => {
  const { type, setType } = useCustomType({ typeId, filters, enabled: featureFlag });
  const { types } = useCustomTypes({ entityType, filters, enabled: featureFlag });

  const defaultValues = useMemo(() => {
    if (!featureFlag) return initialValues;
    return {
      ...processInitialValuesForTypedEntity(initialValues ?? {}, type),
      customType: type,
    } as Partial<Entity>;
  }, [featureFlag, initialValues, type]);

  const typedFormSchema = featureFlag
    ? yup.object().shape(processCustomTypeYupSchema(type, formSchema))
    : yup.object().shape(formSchema);

  const processCustomFields = useCallback(
    (entity: Partial<Entity>) => {
      if (!featureFlag) return entity;
      return processValuesForTypedEntity(entity, type);
    },
    [featureFlag, type]
  );

  return { defaultValues, typedFormSchema, processCustomFields, type, setType, types };
};

interface EntityCustomTypeFormProps<Entity extends IGenericEntity & ICustomTypedEntity> {
  entityType: CustomType["entityType"];
  typeId: number | null | undefined;
  type: CustomType | undefined;
  types: MultiEditMappedItemObject<CustomType> | undefined;
  setType: React.Dispatch<React.SetStateAction<CustomType | undefined>>;
  control: Control<Partial<Entity>, any, Partial<Entity>>;
  register: UseFormRegister<Partial<Entity>>;
  setValue: UseFormSetValue<Partial<Entity>>;
  initialValues: Partial<Entity> | undefined;
  errors: FieldErrors<Partial<Entity>>;
  required?: boolean;
  disabled?: boolean;
  entityConstants: EntityConstants;
  allowUnassigned?: boolean;
  disableTypeSelection?: boolean;
  disableParentSelection?: boolean;
  customTypeFilters?: CustomTypeFilters;
}
export const EntityCustomTypeForm = <Entity extends IGenericEntity & ICustomTypedEntity>({
  entityType,
  typeId,
  type,
  types,
  setType,
  control,
  register,
  setValue,
  initialValues,
  errors,
  required = false,
  disabled = false,
  entityConstants,
  allowUnassigned = true,
  disableTypeSelection = false,
  disableParentSelection = false,
  customTypeFilters,
}: EntityCustomTypeFormProps<Entity>) => {
  const [showAlertModal, setShowAlertModal] = useState(false);

  const _type = useWatch({
    name: "customType" as Path<Partial<Entity>>,
    control: control,
  }) as IEntityMinimalModel<number> | null;

  useEffect(() => {
    // A check to warn users when switching types
    if (typeId) {
      if (_type && _type.id !== typeId) {
        setShowAlertModal(true);
      }
    }
    if (_type && types && Object.hasOwn(types, _type.id)) {
      setType(types[_type.id]);
    } else if (_type && (_type as unknown as IEntityMinimalModel).id === -2) {
      setType(undefined);
    } else if (_type === null) {
      setType(undefined);
    }
  }, [_type, typeId, types, setType]);

  return (
    <>
      {types && !!Object.keys(types).length && (
        <CustomTypesVirtualizedSelectForm
          id={"customType" as Path<Partial<Entity>>}
          control={control}
          horizontal
          showControls
          allowUnassigned={allowUnassigned}
          filters={{ ...customTypeFilters, entityTypes: [entityType] }}
          required={required}
          disabled={disabled || disableTypeSelection}
        />
      )}
      {type ? (
        <>
          {type.rootHierarchy?.id && (
            <InventoriesVirtualizedSelectForm
              id={"parent" as Path<Partial<Entity>>}
              control={control}
              horizontal
              // required={!!type.parentMandatory}
              required
              filters={{ orderBy: "NAME_ASC", customTypeIds: type.parentTypes?.map((t) => t.id) }}
              label="Parent inventory item"
              disabled={disabled || disableParentSelection}
            />
          )}

          <CustomTypeFormRenderer<Entity>
            customType={type}
            register={register}
            control={control as any}
            errors={errors}
            disabled={disabled}
          />
        </>
      ) : null}
      <AlertModal
        type="danger"
        showModal={showAlertModal}
        setShowModal={setShowAlertModal}
        title={`Change ${entityConstants.entitySingular} type?`}
        description={`Switching between ${entityConstants.entitySingular} types can lead to data loss. Please contact your system administrator if you are unsure about the implications.`}
        proceedLabel="Continue"
        onProceed={() => setShowAlertModal(false)}
        onCancel={() => {
          setValue("customType" as Path<Partial<Entity>>, (initialValues?.customType ?? null) as any);
          setShowAlertModal(false);
        }}
      />
    </>
  );
};
