import { useCallback, useState } from "react";
import { API } from "../../../api/Api";
import { DatasetFilters, datasetsConstants, ZipFileSize } from "../../../api/Datasets";
import { LucideIcon } from "../../../common/icon/LucideIcon";
import { Button } from "../../../common/buttons/Button/Button";
import { showtoast } from "../../../common/overlays/Toasts/showtoast";
import { formatBytes } from "../Tree/Folder/helpers/FileHelpers";

export const getDatasetZip = (api: API, datasetId: number) => {
  const url = api.Datasets.getDowloadUrl({ ids: [datasetId] });
  api.triggerDownloadLink(url, `Dataset_${datasetId}.zip`);
};

export const getDatasetsZipWithProps = ({ api, options }: { api: API; options?: DatasetFilters }) => {
  return getDatasetsZip(api, options ?? {});
};

export const getDatasetsDownloadUrl = ({ api, options }: { api: API; options?: DatasetFilters }) => {
  return api.Datasets.getDowloadUrl(options ?? {});
};

export const triggerUrlDownload = ({ api, url, filename }: { api: API; url: string; filename: string }) => {
  api.triggerDownloadLink(url, filename, true);
};

export const getDatasetsZip = (api: API, options: DatasetFilters = {}) => {
  const url = api.Datasets.getDowloadUrl(options);
  api.triggerDownloadLink(url, `Datasets_bundle.zip`, true);
};

// const getUrlComponents = () => {
//   return {
//     hostname: window.location.hostname,
//     // The CRA proxy does not proxy this request as it accepts html as content type
//     // so we have to switch to the .NET server on port 5000 here in development mode
//     port: process.env.NODE_ENV === "development" ? 5000 : window.location.port,
//     // The .NET backend doesn't handle SSL by itself, so we have to make plain http
//     // requests when talking directly to it on port 5000
//     protocol: process.env.NODE_ENV === "development" ? "http:" : window.location.protocol,
//   };
// };

interface Props {
  btnStyle?: string;
  api: API;
  id: number;
  label?: string;
}
export const DownloadDataset = ({ btnStyle = "btn btn-sm btn-primary", api, id, label = "Download" }: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  return (
    <Button
      className={btnStyle}
      onClick={() => {
        if (!isLoading) {
          setIsLoading(() => true);
          getDatasetZip(api, id);
          setIsLoading(() => false);
        }
      }}
      disabled={isLoading}
      loading={isLoading}
      title="Download"
    >
      <LucideIcon name="download" /> {label}
    </Button>
  );
};

interface DownloadDatasetMultiProps {
  btnStyle?: string;
  api: API;
  filters: DatasetFilters;
  label?: string;
  title?: string;
  disabled?: boolean;
}
export const DownloadDatasetMulti = ({
  btnStyle = "btn btn-primary",
  api,
  filters,
  label = "Download",
  title,
  disabled,
}: DownloadDatasetMultiProps) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleDownloadAll = useCallback(
    async (filters: DatasetFilters) => {
      if (filters) {
        setIsLoading(true);
        try {
          const zipSize: ZipFileSize = await api.post(`${datasetsConstants.resource}/zip_size`, filters);
          if (zipSize.isDownloadable) {
            await getDatasetsZip(api, filters);
          } else {
            if (zipSize.datasetCount === 0) {
              showtoast(
                "error",
                `Selected ${zipSize.datasetCount} ${datasetsConstants.entityPlural}. No download available.`
              );
            } else if (zipSize.datasetCount > zipSize.maxDatasetCountPerZipFile) {
              showtoast(
                "error",
                `Selected ${datasetsConstants.entityPlural} count: ${zipSize.datasetCount} exceed maximum allowed count: ${zipSize.maxDatasetCountPerZipFile}`
              );
            } else if (zipSize.size > zipSize.maxZipFileSize) {
              showtoast(
                "error",
                `Selected ${datasetsConstants.entityPlural} bundle size: ${formatBytes(
                  zipSize.size
                )} exceeds maximum allowed bundle size: ${formatBytes(zipSize.maxZipFileSize)}`
              );
            } else {
              showtoast(
                "error",
                `Selected ${datasetsConstants.entityPlural}: ${zipSize.datasetCount} (${formatBytes(
                  zipSize.size
                )}) exceed maximum allowed bundle:  ${zipSize.maxDatasetCountPerZipFile} (${formatBytes(
                  zipSize.maxZipFileSize
                )})`
              );
            }
          }
          setIsLoading(false);
        } catch {
          showtoast("error", "An error occurred while processing your request");
        }
        setIsLoading(false);
      }
    },
    [api]
  );

  return (
    <Button
      className={btnStyle}
      onClick={async (e) => {
        e.preventDefault();
        e.stopPropagation();
        await handleDownloadAll(filters);
      }}
      disabled={disabled ?? isLoading}
      loading={isLoading}
      title={title || "Download"}
    >
      <LucideIcon name="download" /> {label}
    </Button>
  );
};
