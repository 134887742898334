import { useState } from "react";
import { useHistory } from "react-router-dom";
import { DetailsPageTopControlsView } from "../common/panels/misc/DetailsPageTopControlsView/DetailsPageTopControlsView";
import { LucideIcon } from "../common/icon/LucideIcon";
import { NotSet } from "../common/misc/UIconstants";
import { AlertModal } from "../common/modals/AlertModal/AlertModal";
import { showtoast } from "../common/overlays/Toasts/showtoast";
import Detail from "../common/panels/Detail/Detail";
import { Table } from "../common/panels/Detail/DetailTable";
import { MoreDropdown } from "../common/buttons/MoreDropdown/MoreDropdown";
import { ToggleSwitch } from "../common/buttons/ToggleSwitch/ToggleSwitch";
import { EntityDetailProps, EntityDetailTableProps } from "../common/entity/EntityInterfaces";
import { DatasetsTile } from "../common/sidebar/tiles/DatasetsTile";
import { GetOrganizations, GetPersons } from "../common/misc/EntityRenders/EntityRenderer";

export const EquipmentsDetailTable = ({
  entity,
  entityConstants,
  fieldLabels,
}: EntityDetailTableProps<"equipments">) => {
  return (
    <>
      <Table noPadding nonFoldable>
        <Table.Head>Basic details</Table.Head>
        <Table.Body>
          <Table.Body.RowContent
            title={fieldLabels.name}
            content={
              <div className="container_label">
                <div className="container_label_name" style={{ fontWeight: 600 }}>
                  {entity.name ?? NotSet}
                </div>
                <div className="container_label_id">
                  <span style={{ whiteSpace: "nowrap" }}>Equipment-ID: {entity.id}</span>
                </div>
              </div>
            }
          />

          <Table.Body.RowContent
            title={fieldLabels.equipmentTag}
            content={
              <>
                {entity.equipmentTag && (
                  <div
                    style={{
                      display: "flex",
                      flexFlow: "row wrap",
                      gap: "var(--margin-sm)",
                      alignItems: "center",
                    }}
                  >
                    <label className="label label-soft-secondary" style={{ margin: 0 }}>
                      {entity.equipmentTag.name}
                    </label>
                  </div>
                )}
              </>
            }
          />

          <Table.Body.RowContent
            title={fieldLabels.isObsolete}
            content={<ToggleSwitch checked={!!entity.isObsolete} readOnly />}
          />

          <Table.Body.RowContent title={fieldLabels.serialnumber} content={entity.serialnumber || NotSet} />
          <Table.Body.RowContent title={fieldLabels.model} content={entity.model || NotSet} />

          <Table.Body.RowContent title={fieldLabels.room} content={entity.room || NotSet} />
        </Table.Body>
      </Table>

      <Table noPadding>
        <Table.Head>Contact details</Table.Head>
        <Table.Body>
          <Table.Body.RowContent title={fieldLabels.localPhone} content={entity.localPhone || NotSet} />

          <Table.Body.RowContent
            title={fieldLabels.homeLab}
            content={!!entity.homeLab ? <GetOrganizations organizations={[entity.homeLab]} /> : NotSet}
          />
          <Table.Body.RowContent
            title={fieldLabels.homeLabContacts}
            content={!!entity.homeLabContacts?.length ? <GetPersons persons={entity.homeLabContacts} /> : NotSet}
          />
          <Table.Body.RowContent
            title={fieldLabels.company}
            content={!!entity.company ? <GetOrganizations organizations={[entity.company]} /> : NotSet}
          />
          <Table.Body.RowContent
            title={fieldLabels.companyContacts}
            content={!!entity.companyContacts?.length ? <GetPersons persons={entity.companyContacts} /> : NotSet}
          />

          <Table.Body.RowContent
            title={fieldLabels.notes}
            content={
              <>
                {entity.notes ? (
                  <div className="container_notes">
                    <textarea rows={4} className="container_textarea" value={entity?.notes} disabled />
                  </div>
                ) : (
                  NotSet
                )}
              </>
            }
          />
        </Table.Body>
      </Table>
    </>
  );
};

export const EquipmentsDetail = ({
  permissions,
  routes,
  entity,
  entityApi,
  entityConstants,
  fieldLabels,
}: EntityDetailProps<"equipments">) => {
  let history = useHistory();
  const [showModal, setShowModal] = useState<boolean>(false);

  return (
    <Detail
      head={
        <DetailsPageTopControlsView
          entity={entity}
          entityConstants={entityConstants}
          controls={
            <>
              <button
                className="btn btn-default"
                onClick={() => history.push(routes.getEditLink(entity.id))}
                disabled={!permissions.canEdit(entity)}
              >
                <LucideIcon name="square-pen" />
                <span> Edit</span>
              </button>
              <MoreDropdown drop="right" btn="btn btn-ghost-secondary">
                <li>
                  <button
                    className="btn btn-sm btn-ghost-secondary"
                    title={`Clone this Equipment`}
                    onClick={() => history.push(routes.getCloneLink(entity.id))}
                    disabled={!permissions.canCreate}
                  >
                    <LucideIcon name="copy" /> Clone
                  </button>
                </li>
                <li>
                  <button
                    className="btn btn-sm btn-ghost-danger"
                    title="Delete"
                    onClick={() => setShowModal(true)}
                    disabled={!permissions.canDelete(entity)}
                  >
                    <LucideIcon name="trash-2" /> Delete
                  </button>
                </li>
              </MoreDropdown>
              <AlertModal
                type="danger"
                showModal={showModal}
                setShowModal={setShowModal}
                title="Permanently delete experiment?"
                description="This experiment may be referenced by other entities. Deleting a experiment is only possible if all references are removed."
                proceedLabel="Delete"
                onProceed={async () => {
                  await entityApi
                    .deleteMutationAsync(
                      {
                        id: entity.id,
                        goBackOnSuccess: false,
                        showToast: false,
                        entityName: "experiment",
                        params: { deletePermanently: true },
                      },

                      {
                        onSuccess: () => {
                          showtoast("success", `Deleted Equipment-ID: ${entity.id}.`);
                          history.goBack();
                        },
                      }
                    )
                    .catch((e) => {});
                }}
                loading={entityApi.isLoadingDeleteMutation}
              />
            </>
          }
        />
      }
      mainStyle={{ padding: "0px 10px" }}
      main={<EquipmentsDetailTable entity={entity} entityConstants={entityConstants} fieldLabels={fieldLabels} />}
      sidebar={
        <>
          {!!entity.relations?.datasets.count && (
            <DatasetsTile
              defaultFilters={{ equipmentIds: [entity.id] }}
              excludeFilters={{ equipmentIds: () => false }}
            />
          )}
          {!entity.relations?.datasets.count && <DatasetsTile disabled />}
        </>
      }
    />
  );
};
