import { useCallback, useEffect, useMemo, useState } from "react";
// import { showtoast } from "../../../common/overlays/Toasts/showtoast";
import { AnalysisResults, useFileHandlingUtils } from "../FileHandlingUtils";
import { useFileUploadUtils } from "../FileUploadUtils";
import { DatasetResult } from "../FileFormatAutoDetection";
import { DatasetForUploadWriteModel, DatasetViewableEntityType } from "../../../api/Datasets";

interface UploadedDataset {
  name: string;
  datasetId: number | undefined;
  uuid: string;
}
export interface UploadedDatasets {
  [taskUUID: string]: {
    progress: number;
    datasets: UploadedDataset[];
  };
}

interface UseManualUploadProps {
  viewableEntityType?: DatasetViewableEntityType;
  blackList?: string[];
  whiteList?: string[];
  isViewableEntity?: boolean;
}

export const useManualUpload = ({
  viewableEntityType = "ELN",
  blackList,
  whiteList,
  isViewableEntity = true,
}: UseManualUploadProps) => {
  // SETTINGS
  const _isViewableEntity = viewableEntityType ? true : isViewableEntity;
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState<number>();
  const [uploadedDatasets, setUploadedDatasets] = useState<UploadedDatasets>({});
  const [processing, setProcessing] = useState(false);

  const parserWhiteList = useMemo(() => {
    return whiteList ?? ["doc_multi"];
  }, [whiteList]);
  const parserBlackList = useMemo(() => {
    return blackList ?? [];
  }, [blackList]);

  const { results, readDataTransfer, readDataTransferItem, dispatch, loadingStatus, readFileList, asyncDispatch } =
    useFileHandlingUtils({
      parserWhiteList,
      parserBlackList,
      setLoading: setLoading,
      transientHasher: true,
    });
  const { uploadMutation, generateDatasetSubmissionMultiForm } = useFileUploadUtils();

  const uploadDataTransfer = useCallback(
    async (dataTransfer: DataTransfer) => {
      if (!processing) {
        setProgress(0);
        setUploadedDatasets({});
        try {
          await readDataTransfer(dataTransfer);
        } catch (e) {
          console.error("Aborting upload...", e);
          setProgress(100);
          setUploadedDatasets({});
        }
      } else {
        console.error("Job is running already");
      }
    },
    [processing, readDataTransfer]
  );

  const uploadFileList = useCallback(
    async (files: File[], uuid: string) => {
      // console.log("TaskUUID:", uuid, files);
      if (!processing) {
        setProgress(0);
        // setUploadedDatasets({});
        try {
          const _files = await readFileList(files as any);
          await asyncDispatch(uuid, uuid, _files);
        } catch (e) {
          console.error("Aborting upload...", e);
          setProgress(100);
          // setUploadedDatasets({});
        }
      } else {
        console.error("Job is running already");
      }
    },
    [asyncDispatch, processing, readFileList]
  );

  const uploadFile = useCallback(
    async (dataset: DatasetResult, parserId: string) => {
      let datasetId: number | undefined = undefined;
      // new file
      const datasetForUpload: DatasetForUploadWriteModel = {
        name: dataset.name,
        format: { id: parserId, name: parserId },
        method: undefined,
        instrument: undefined,
        files: dataset.files.map((d) => ({
          id: d.id,
          isUnique: d.isUnique,
          fullPath: d.fullPath,
          mtime: new Date(d.file.lastModified).toISOString(),
        })),
        isViewableEntity: _isViewableEntity,
        viewableEntityType: viewableEntityType,
      };
      const body = generateDatasetSubmissionMultiForm(dataset, datasetForUpload);
      const response = await uploadMutation
        .mutateAsync(
          { body: body, showToast: false }
          // {
          //   onError: (err) => {
          //     console.error(`Error uploading file.\n ${(err as ServerError).message ?? (err as ServerError).name}`);
          //   },
          // }
        )
        .catch((e) => {
          return undefined;
        });
      datasetId = response?.results?.[0]?.id;
      return datasetId;
    },
    [generateDatasetSubmissionMultiForm, _isViewableEntity, uploadMutation, viewableEntityType]
  );

  const processResults = useCallback(
    async (results: AnalysisResults) => {
      // console.log("Processing results", results);
      // let res: UploadedDatasets = {};
      setProcessing(true);
      for (const [uuid, bundle] of Object.entries(results)) {
        let i = 0;
        const totalDatasets = Object.values(bundle)
          .map((d) => d.datasets.length)
          .reduce((pv, cv) => pv + cv, 0);
        // console.log(`TaskUUID: ${uuid} --> Total datasets: ${totalDatasets}`);
        for (const [parserId, matchingResults] of Object.entries(bundle)) {
          const unrocessedFiles = new Set(matchingResults.datasets.map((d) => d.id));
          // We will only process if it hasn't been processed before
          if (!matchingResults.checked) {
            // CS: We ignore the hash check and just upload

            for (const dataset of matchingResults.datasets) {
              if (unrocessedFiles.has(dataset.id)) {
                const _datasetId = await uploadFile(dataset, parserId);
                const progress = ((i + 1) * 100) / totalDatasets;
                setUploadedDatasets((prev) => {
                  if (prev.hasOwnProperty(uuid)) {
                    return {
                      ...prev,
                      [uuid]: {
                        ...prev[uuid],
                        progress: progress,
                        datasets: [
                          ...prev[uuid].datasets,
                          {
                            name: dataset.name,
                            uuid: dataset.id,
                            datasetId: _datasetId ? +_datasetId : undefined,
                          },
                        ],
                      },
                    };
                  } else {
                    return {
                      ...prev,
                      [uuid]: {
                        progress: progress,
                        datasets: [
                          {
                            name: dataset.name,
                            uuid: dataset.id,
                            datasetId: _datasetId ? +_datasetId : undefined,
                          },
                        ],
                      },
                    };
                  }
                });
                unrocessedFiles.delete(dataset.id);
                i++;
              }
            }

            // First request --> Only check the uniques
            // const payload: DatasetSearchRequest = {
            //   datasets: matchingResults.datasets.map((d) => ({
            //     id: d.id,
            //     parserId: d.parserId,
            //     checkUpdatable: false,
            //     files: d.files
            //       .filter((f) => f.isUnique)
            //       .map((f) => ({
            //         id: f.id,
            //         fullPath: f.fullPath,
            //         mtime: new Date(f.file.lastModified).toISOString(),
            //         hash: f.sha256!,
            //         // fragments: f.fragments,
            //       })),
            //   })),
            //   onlyViewableEntities: true,
            // };
            // const response = await findDatasets(payload);

            // if (Array.isArray(response)) {
            //   // Handle known datasets
            //   for (const _d of response) {
            //     const dataset = matchingResults.datasets.find((d) => d.id === _d.id);
            //     if (dataset) {
            //       unrocessedFiles.delete(dataset.id);
            //       const progress = ((i + 1) * 100) / totalDatasets;
            //       setUploadedDatasets((prev) => {
            //         if (prev.hasOwnProperty(uuid)) {
            //           return {
            //             ...prev,
            //             [uuid]: {
            //               ...prev[uuid],
            //               progress: progress,
            //               datasets: [
            //                 ...prev[uuid].datasets,
            //                 {
            //                   name: dataset.name,
            //                   uuid: dataset.id,
            //                   datasetId: _d.logsId ? +_d.logsId : undefined,
            //                 },
            //               ],
            //             },
            //           };
            //         } else {
            //           return {
            //             ...prev,
            //             [uuid]: {
            //               progress: progress,
            //               datasets: [
            //                 {
            //                   name: dataset.name,
            //                   uuid: dataset.id,
            //                   datasetId: _d.logsId ? +_d.logsId : undefined,
            //                 },
            //               ],
            //             },
            //           };
            //         }
            //       });
            //       i++;
            //     }
            //   }

            //   // Handle new
            //   for (const dataset of matchingResults.datasets) {
            //     if (unrocessedFiles.has(dataset.id)) {
            //       const _datasetId = await uploadFile(dataset, parserId);
            //       const progress = ((i + 1) * 100) / totalDatasets;
            //       setUploadedDatasets((prev) => {
            //         if (prev.hasOwnProperty(uuid)) {
            //           return {
            //             ...prev,
            //             [uuid]: {
            //               ...prev[uuid],
            //               progress: progress,
            //               datasets: [
            //                 ...prev[uuid].datasets,
            //                 {
            //                   name: dataset.name,
            //                   uuid: dataset.id,
            //                   datasetId: _datasetId ? +_datasetId : undefined,
            //                 },
            //               ],
            //             },
            //           };
            //         } else {
            //           return {
            //             ...prev,
            //             [uuid]: {
            //               progress: progress,
            //               datasets: [
            //                 {
            //                   name: dataset.name,
            //                   uuid: dataset.id,
            //                   datasetId: _datasetId ? +_datasetId : undefined,
            //                 },
            //               ],
            //             },
            //           };
            //         }
            //       });
            //       unrocessedFiles.delete(dataset.id);
            //       i++;
            //     }
            //   }
            // }
            dispatch({
              type: "setBundleStatus",
              actionUUID: uuid,
              parserId: parserId,
              status: true,
            });
          }
        }
      }
      setProcessing(false);
    },
    [dispatch, uploadFile]
  );

  useEffect(() => {
    const tasks = Object.keys(uploadedDatasets);
    if (tasks.length!!) {
      const total_progress = Object.values(uploadedDatasets)
        .map((task) => task.progress / 100)
        .reduce((pv, cv) => pv + cv, 0);
      setProgress((total_progress * 100) / tasks.length);
    }
  }, [uploadedDatasets]);

  useEffect(() => {
    const checked = Object.values(results)
      .map((d) => Object.values(d))
      .flat(1)
      .map((f) => f.checked)
      .every((c) => c === true);
    if (!loading && !!Object.keys(results).length && !processing && !checked) {
      (async () => {
        await processResults(results);
      })();
    }
  }, [loading, processResults, processing, results]);

  useEffect(() => {
    // console.log("Mounting");
    return () => {
      // console.log("Unmounting");
      dispatch({ type: "clear" });
    };
  }, [dispatch]);

  const clearUpload = useCallback(() => {
    dispatch({ type: "clear" });
    setUploadedDatasets({});
    setProcessing(false);
    setLoading(false);
    setProgress(100);
  }, [dispatch]);

  return {
    dispatch,
    readDataTransfer,
    readDataTransferItem,
    uploadedDatasets,
    setUploadedDatasets,
    uploadDataTransfer,
    uploadFileList,
    progress,
    clearUpload,
    loadingStatus,
  };
};
