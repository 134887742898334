import { API } from "./Api";
import { DatasetFilters } from "./Datasets";
import { EnqueueOptions } from "./Parsing";

export class DatasetsApi {
  private api: API;

  constructor(api: API) {
    this.api = api;
  }

  public getDowloadUrl(options: DatasetFilters): string {
    const { hostname, port, protocol } = this.api.getUrlComponents();

    return `${protocol}//${hostname}:${port}/${this.api.getGroup()}/api/0.1/datasets/zip?${this.api.getParamString({
      ...options,
      includeSoftDeleted: true,
      includeViewableEntities: true,
    })}&${this.api.getAuthentication().type}=${encodeURIComponent(this.api.getAuthentication().value)}`;
  }

  public async getTracksCSV(id: number, tracks: number[], options: DatasetFilters = {}): Promise<string> {
    const searchParams = new URLSearchParams(options as any);
    try {
      const rv = await this.api.post(
        `datasets/${id}/csv/${searchParams.toString()}`,
        { tracks: tracks }
        // `dataset`
      );
      // console.log("rv", rv);
      return rv;
    } catch {
      return Promise.resolve("");
    }
  }

  public async enqueueDatasets(options: EnqueueOptions = {}): Promise<{ [parserId: string]: number }> {
    return await this.api.post(`parsing/enqueue`, options);
  }
}
