import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
  // memo,
  CSSProperties,
  useImperativeHandle,
  useMemo,
} from "react";
import {
  FixedSizeGrid,
  GridChildComponentProps,
  VariableSizeGrid,
  // areEqual,
} from "react-window";
import styles from "./GenericVirtualizedTable.module.css";
import { useResizeDetector } from "react-resize-detector";
import { AutoSizer } from "react-virtualized";
import { getDetailLink } from "../../../main/Routing";
import {
  useBulkPermissionsMutation,
  useEntityCount,
  useIdsOnlyMutation,
  useInfiniteListEntity,
} from "../../../api/BaseEntityApi";
import InfiniteLoader from "react-window-infinite-loader";
import { LucideIcon } from "../../icon/LucideIcon";
import {
  GenericEntity,
  IEntityPermissions,
  IGenericRequestParameters,
  IPermissionedEntity,
} from "../../../api/GenericTypes";
import { SessionContext } from "../../contexts/SessionContext";
import { Link, useHistory } from "react-router-dom";
import Draggable, { DraggableData } from "react-draggable";
import {
  AlignTypes,
  CheckboxStates,
  GenericVirtualizedTableCell,
  GenericVirtualizedTableCells,
  GenericVirtualizedTableProps,
  IndeterminateCheckboxProps,
  ItemData,
} from "./GenericVirtualizedTableTypes";
import { ColumnsSelector, ColumnsSettings } from "../ColumnsSelector/ColumnsSelector";
import { NoEntriesFound } from "../../NoEntriesFound";
import BrandLoader from "../../loaders/Spinner/BrandLoader";
import { ColumnWidthSettings } from "../Tabs/TableTabsTypes";
import { LoadingWrapper } from "../../LoadingWrapper";
import { useBlockUntil } from "../../helperfunctions/useBlockUntil";
import { useScrollbarSize } from "../../helperfunctions/useScrollbarSize";
import { CheckboxSquare } from "../../formfields/CheckboxFormField/CheckboxFormField";
import { Button } from "../../buttons/Button/Button";

// Utils
const range = (start: number, end: number) => {
  if (start === end) return [start];
  let _start = start,
    _end = end;
  if (start > end) {
    _start = end;
    _end = start;
  }
  return Array.from({ length: _end - _start + 1 }, (_, a) => a + _start);
};

const consolidatePermissions = (permissions: IEntityPermissions[]) => {
  let selectionPermissions: { [property: string]: boolean[] } = {};
  let _permissions: IEntityPermissions = {};
  // We have all necessary permission info
  permissions.forEach((item) => {
    if (item) {
      Object.entries(item).forEach(([property, value]) => {
        if (selectionPermissions.hasOwnProperty(property)) {
          selectionPermissions[property] = [...selectionPermissions[property], value];
        } else {
          selectionPermissions[property] = [value];
        }
      });
    }
  });
  // Now we consolidate
  Object.entries(selectionPermissions).forEach(([property, values]) => {
    _permissions[property as keyof IEntityPermissions] = values.every((b) => b === true);
  });
  return _permissions;
};

const switchAlign = (align: AlignTypes | undefined) => {
  switch (align) {
    case "left":
      return "flex-start";
    case "center":
      return "center";
    case "right":
      return "flex-end";
    default:
      return "flex-start";
  }
};

// Components
const IndeterminateCheckbox = ({ onChange, value }: IndeterminateCheckboxProps) => {
  const [state, setState] = useState<{
    checked: boolean;
    indeterminate: boolean;
  }>({ checked: false, indeterminate: false });

  useEffect(() => {
    if (value === "Checked") {
      setState({ checked: true, indeterminate: false });
    } else if (value === "Empty") {
      setState({ checked: false, indeterminate: false });
    } else if (value === "Indeterminate") {
      setState({ checked: false, indeterminate: true });
    }
  }, [value]);

  // const checkboxRef = useRef<HTMLInputElement>(null);
  // useEffect(() => {
  //   if (checkboxRef.current) {
  //     if (value === "Checked") {
  //       checkboxRef.current.checked = true;
  //       checkboxRef.current.indeterminate = false;
  //     } else if (value === "Empty") {
  //       checkboxRef.current.checked = false;
  //       checkboxRef.current.indeterminate = false;
  //     } else if (value === "Indeterminate") {
  //       checkboxRef.current.checked = false;
  //       checkboxRef.current.indeterminate = true;
  //     }
  //   }
  // }, [checkboxRef, value]);

  return (
    <div className={`${styles.checkbox} ${styles.row}`}>
      {/* {value && <input key={value} ref={checkboxRef} type="checkbox" onChange={async (e) => await onChange?.(e)} />} */}
      <CheckboxSquare
        checked={state.checked}
        indeterminate={state.indeterminate}
        onChange={async (e, _) => await onChange?.(e)}
      />
    </div>
  );
};

const RenderLoader = ({ style, fixedRowHeight }: { style: CSSProperties; fixedRowHeight: number }) => {
  return (
    <div className={`${styles.row} `} style={style}>
      <span className="skeleton-block" style={{ height: fixedRowHeight - 14 }} />
    </div>
  );
};

const RenderTableLoader = () => (
  <div className="flex align-center justify-center" style={{ width: "100%", height: "100%" }}>
    <BrandLoader size={50} />
  </div>
);

export const GenericVirtualizedTable = <
  Entity extends GenericEntity & IPermissionedEntity,
  Filters extends { [property: string]: any } & IGenericRequestParameters<Entity, Filters["orderBy"]>
>({
  items,
  entityConstants,
  filters,
  columns,
  defaultSelection,
  maxSelectedItems = 1000,
  onSelectionChange,
  onSelectionPermissions,
  onRowClick,
  modifyQueryResult,
  linkTo,
  setResultsCount,
  pageSize = 100,
  fixedRowHeight = 35,
  headerRowHeight = 35,
  minColWidth = 100,
  maxColWidth = 9999,
  tableHeadCls,
  tableRowCls,
  tableRowHoverCls,
  tableRowSelectedCls,
  sortOnColumnHeaderClick = true,
  showPermissionColumn,
  disableCheckboxes,
  disableCheckboxNoPermission,
  hasWritePermission,
  columnSelect = true,
  columnSetting,
  columnWidths,
  defaultColumnSettings,
  dispatchTabStore,
  loading,
  functionRef,
  fixedRowRendererColumnWidth,
  fixedRowRendererColumn,
  fixedRowRenderer,
  useInfiniteQueryOptions,
  showColumnOverview = true,
  autoUpdateIndicator = false,
  autoUpdateDelay = 60000,
}: //   scrollToIndex,
GenericVirtualizedTableProps<Entity, Filters>) => {
  const history = useHistory();
  const _filters = useMemo(() => filters, [filters]);
  const { route } = useContext(SessionContext);
  // TEMP FIX:
  // The empty onResize functions are necessary to make the resize detector work on initial render.
  // This is probably a timing issue.
  const { ref: tableContainerRef, height: containerHeight } = useResizeDetector({ onResize: () => {} });
  const { ref: tableHeadRef, width: tableHeadWidth } = useResizeDetector({ onResize: () => {} });
  const cycleCount = useRef(0);
  const checkboxGrid = useRef<FixedSizeGrid>(null);
  const permissionGrid = useRef<FixedSizeGrid>(null);
  const tableHeadGrid = useRef<VariableSizeGrid>(null);
  const virtualLoaderGrid = useRef<InfiniteLoader & { _listRef: VariableSizeGrid }>(null);
  const [selection, setSelection] = useState<Set<Entity["id"]>>(new Set(defaultSelection));
  const permissions = useRef<Map<Entity["id"], IEntityPermissions>>(new Map());
  const [checked, setChecked] = useState<CheckboxStates>("Empty");
  const [_items, setItems] = useState<Entity[]>([]);
  const [prevSelectedRow, setPrevSelectedRow] = useState<number>();
  const [cols, setCols] = useState<GenericVirtualizedTableCells<Entity>>();
  const columnWidthsRef = useRef<ColumnWidthSettings>({});
  const [columnSettingsState, setColumnSettingsState] = useState(columnSetting);
  const { mutateAsync: idsOnlyMutation } = useIdsOnlyMutation<Entity["id"], Filters>(entityConstants.resource);
  const { mutateAsync: bulkPermissionsMutation } = useBulkPermissionsMutation<Entity["id"], Filters>(
    entityConstants.resource
  );
  const [scrollLeft, setScrollLeft] = useState(0);
  const [updates, hasUpdates] = useState(false);

  const scrollbarDimensions = useScrollbarSize();

  const itemData = useMemo(
    () =>
      ({
        prevSelectedRow,
        setPrevSelectedRow,
      } as ItemData),
    [prevSelectedRow]
  );
  const waitUntil = useBlockUntil();

  // Hook to update some states if items are defined manually
  useEffect(() => {
    if (Array.isArray(items)) {
      setItems(items);
      items.forEach((item) => {
        if (Object.hasOwn(item, "permissions") && item.permissions) permissions.current.set(item.id, item.permissions);
      });
    }
  }, [items]);

  // Function to forceUpdate the table components
  const forceTableUpdate = useCallback(() => {
    tableHeadGrid.current?.resetAfterColumnIndex(0, true);
    virtualLoaderGrid.current?._listRef.resetAfterColumnIndex(0, true);
    checkboxGrid.current?.forceUpdate();
    permissionGrid.current?.forceUpdate();
  }, []);

  // Function to scroll to a specific rowIndex / column
  const handleScroll = useCallback(
    (rowIndex: number, columnIndex: number) => {
      if (virtualLoaderGrid.current && rowIndex !== undefined && columnIndex !== undefined) {
        virtualLoaderGrid.current._listRef.scrollToItem({
          rowIndex: rowIndex,
          columnIndex: columnIndex,
          align: "start",
        });

        checkboxGrid.current?.scrollToItem({
          rowIndex: rowIndex,
          columnIndex: 0,
          align: "start",
        });

        tableHeadGrid.current?.scrollToItem({
          rowIndex: 0,
          columnIndex: columnIndex,
          align: "start",
        });

        permissionGrid.current?.scrollToItem({
          rowIndex: rowIndex,
          columnIndex: 0,
          align: "start",
        });
        forceTableUpdate();
      }
    },
    [forceTableUpdate]
  );

  // Function to scroll to a specific position
  const handleScrollTo = useCallback(
    (scrollLeft: number, scrollTop: number) => {
      if (virtualLoaderGrid.current && scrollLeft !== undefined && scrollTop !== undefined) {
        virtualLoaderGrid.current._listRef.scrollTo({
          scrollLeft: scrollLeft,
          scrollTop: scrollTop,
        });

        checkboxGrid.current?.scrollTo({
          scrollLeft: scrollLeft,
          scrollTop: scrollTop,
        });

        tableHeadGrid.current?.scrollTo({
          scrollLeft: scrollLeft,
          scrollTop: scrollTop,
        });

        permissionGrid.current?.scrollTo({
          scrollLeft: scrollLeft,
          scrollTop: scrollTop,
        });

        forceTableUpdate();
      }
    },
    [forceTableUpdate]
  );

  // Handle to scroll to a specific ID
  const handleScrollToId = useCallback(
    (entityId: Entity["id"], columnId?: GenericVirtualizedTableCell<Entity>["id"]) => {
      const index = _items.findIndex((d) => d.id === entityId);
      const columnIndex = columns.findIndex((d) => d.id === columnId);
      if (index !== -1) handleScroll(index, columnIndex !== undefined ? columnIndex : 0);
    },
    [_items, columns, handleScroll]
  );

  // Helper function to create infinite cycling through the selection
  const cycleSelection = useCallback(() => {
    const arr = Array.from(selection);
    const index = cycleCount.current % arr.length;
    cycleCount.current += 1;
    return arr[index];
  }, [selection]);

  // Handle to cycle-jump through the selection
  const handleJumpToNextSelectedItem = useCallback(
    (columnIndex?: number) => {
      handleScrollToId(cycleSelection());
    },
    [cycleSelection, handleScrollToId]
  );

  // Handle to reset selection
  const resetSelection = useCallback(() => {
    setSelection(new Set());
  }, []);

  // Function to return selection permissions (memoized known results)
  const applySelectionPermissions = useCallback(
    async (ids: Entity["id"][]) => {
      if (onSelectionPermissions !== undefined) {
        if (!!ids.length) {
          const _ids = ids.filter((id) => !permissions.current.has(id));
          if (!!_ids.length) {
            await bulkPermissionsMutation(
              { body: { ids: _ids, ..._filters } as Filters },
              {
                onSuccess: (response) => {
                  response.results.forEach((r) => permissions.current.set(r.id, r.permissions));
                },
              }
            ).catch((e) => {
              console.error(e);
            });
          }
          let selectedPermissions: IEntityPermissions[] = [];

          ids.forEach((id) => {
            const _permission = permissions.current.get(id);
            if (_permission) selectedPermissions.push(_permission);
          });
          onSelectionPermissions(consolidatePermissions(selectedPermissions));
        } else {
          onSelectionPermissions({
            edit: false,
            delete: false,
          });
        }
      }
    },
    [bulkPermissionsMutation, _filters, onSelectionPermissions]
  );

  // Hook for filter changes
  // useEffect(() => {
  //   // console.log("Writing filters");
  //   dispatchTabStore?.({ type: "setFilters", filters: filters, options: { keepPrevious: true } });
  // }, [dispatchTabStore, filters]);

  // Hook to update columnWidhts
  useEffect(() => {
    columnWidthsRef.current = Object.fromEntries(columns.map((c) => [c.id, c.width]));
    if (columnWidths) {
      Object.entries(columnWidths).forEach(([id, width]) => {
        columnWidthsRef.current[id] = width;
      });
    }
    forceTableUpdate();
  }, [columnWidths, columns, forceTableUpdate]);

  // Hook for columns
  useEffect(() => {
    if (columnSettingsState && !!Object.keys(columnSettingsState).length) {
      const _cols = columns
        .filter(
          (c) => columnSettingsState && columnSettingsState.hasOwnProperty(c.id) && columnSettingsState[c.id].active
        )
        .sort((a, b) => {
          if (columnSettingsState.hasOwnProperty(a.id) && columnSettingsState.hasOwnProperty(b.id)) {
            return columnSettingsState[a.id].pos - columnSettingsState[b.id].pos;
          } else {
            return 0;
          }
        });
      setCols(_cols);
      forceTableUpdate();
    } else {
      setCols(columns);
    }
  }, [columnSettingsState, columns, forceTableUpdate]);

  const {
    data: query,
    status,
    fetchStatus,
    error,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetched,
    refetch,
    // count: queryCount,
  } = useInfiniteListEntity<Entity>(
    entityConstants.resource,
    {
      ..._filters,
      page: 1,
      pageSize: pageSize,
      includeCount: false,
    },
    { ...useInfiniteQueryOptions, enabled: items ? false : loading === true ? false : true },
    "post"
  );

  // Separate query for count
  const { data: queryCount, refetch: refetchQueryCount } = useEntityCount<Filters>(
    entityConstants.resource,
    { ..._filters, includeCount: true },
    { enabled: items ? false : loading === true ? false : true }
  );
  const queryCountRef = useRef<number>();
  const initialqueryCount = useRef<boolean>(false);

  const count = items ? items.length : queryCount?.count;
  const isLoading = loading || !isFetched;
  const isFetchingData = items ? false : isFetching;

  // Custom ref to access internal functions from the outside
  useImperativeHandle(
    functionRef,
    () => ({
      forceUpdate() {
        // console.log("Forcing update externally");
        virtualLoaderGrid.current?._listRef.forceUpdate();
        virtualLoaderGrid.current?._listRef.resetAfterRowIndex(0, true);
        forceTableUpdate();
      },
      handleScroll(rowIndex: number, columnIndex: number) {
        handleScroll(rowIndex, columnIndex);
        forceTableUpdate();
      },
      handleScrollTo(scrollLeft: number, scrollTop: number) {
        handleScrollTo(scrollLeft, scrollTop);
      },
      handleScrollToId(entityId: Entity["id"], columnId?: GenericVirtualizedTableCell<Entity>["id"]) {
        handleScrollToId(entityId, columnId);
      },
      handleJumpToNextSelectedItem(columnIndex?: number) {
        handleJumpToNextSelectedItem(columnIndex);
      },
      resetSelection() {
        resetSelection();
      },
      setSelection,
      refetch,
    }),
    [
      forceTableUpdate,
      handleJumpToNextSelectedItem,
      handleScroll,
      handleScrollTo,
      handleScrollToId,
      refetch,
      resetSelection,
    ]
  );

  const refetchQueryQuentCallback = useCallback(() => {
    if (initialqueryCount.current) refetchQueryCount();
    initialqueryCount.current = true;
  }, [refetchQueryCount]);

  // Hook to query count on interval
  useEffect(() => {
    let interval: NodeJS.Timeout;
    if (autoUpdateIndicator) {
      interval = setInterval(() => {
        refetchQueryQuentCallback();
      }, autoUpdateDelay);
    }
    return () => clearInterval(interval);
  }, [autoUpdateDelay, autoUpdateIndicator, refetchQueryQuentCallback]);

  useEffect(() => {
    if (autoUpdateIndicator) {
      if (initialqueryCount.current && queryCount !== queryCountRef.current) {
        hasUpdates(true);
      } else {
        hasUpdates(false);
      }
    }
  }, [autoUpdateIndicator, queryCount]);

  // Hook to reset selection if filter changes
  useEffect(() => {
    if (!defaultSelection) {
      if (_filters) {
        // console.log("Filters changed - Resetting selection");
        setSelection((prev) => (!prev.size ? prev : new Set()));
      }
    }
  }, [_filters, defaultSelection]);

  // Selection Hook
  useEffect(() => {
    (async () => await applySelectionPermissions(Array.from(selection)))();
    onSelectionChange?.(selection);
    cycleCount.current = 0;
    if (selection.size === 0) {
      setChecked("Empty");
    } else if (selection.size === count) {
      setChecked("Checked");
    } else {
      setChecked("Indeterminate");
    }
  }, [applySelectionPermissions, count, onSelectionChange, selection]);

  useEffect(() => {
    setResultsCount?.(count);
    if (autoUpdateIndicator && !!count && queryCountRef.current !== count) {
      queryCountRef.current = count;
      initialqueryCount.current = true;
    }
  }, [autoUpdateIndicator, count, setResultsCount]);

  useEffect(() => {
    // console.clear();
    // console.log("Items", query);

    if (query) {
      let flatQuery = query.pages.map((d) => d.results).flat();
      if (modifyQueryResult) {
        flatQuery = modifyQueryResult(flatQuery);
      }
      setItems(flatQuery);
    }
    // return () => {
    //   console.log("Cleanup");
    //   setItems([]);
    // };
  }, [modifyQueryResult, query, query?.pages]);

  const fetchNext = useCallback(() => {
    if (hasNextPage) {
      fetchNextPage();
    }
  }, [fetchNextPage, hasNextPage]);

  const loadMoreCallback = useCallback(() => {}, []);
  const itemCount = hasNextPage ? _items.length + 1 : _items.length;
  const loadMoreItems = isFetchingData ? loadMoreCallback : fetchNext;
  // const loadMoreItems = useCallback(
  //   async (startIndex: number, stopIndex: number): Promise<void> => {
  //     return new Promise((resolve) => {
  //       if (isFetching) {
  //         // loadMoreCallback();
  //         resolve();
  //       } else {
  //         fetchNext();
  //         resolve();
  //       }
  //     });
  //   },
  //   [fetchNext, isFetching]
  // );

  const isItemLoaded = useCallback(
    (index: number) => !hasNextPage || index < _items.length - pageSize,
    [hasNextPage, _items.length, pageSize]
  );

  const manualItemIds = useMemo(() => items?.map((i) => i.id), [items]);

  const handleSelectAll = useCallback(async () => {
    if (selection.size > 0) {
      setSelection(new Set());
      await applySelectionPermissions([]);
    } else {
      if (manualItemIds) {
        setSelection(new Set(manualItemIds));
      } else {
        await idsOnlyMutation(
          { body: _filters ? { ..._filters } : ({} as Filters) },
          {
            onSuccess: async (res) => {
              const ids = res.results.slice(0, maxSelectedItems); // We only allow maxSelectedItems items at once
              await applySelectionPermissions(ids);
              setSelection(new Set(ids));
            },
          }
        ).catch(() => {});
      }
    }
  }, [selection.size, applySelectionPermissions, manualItemIds, idsOnlyMutation, _filters, maxSelectedItems]);

  // Scroll synchronizer
  const onScroll = useCallback(
    ({
      scrollTop,
      scrollLeft,
      scrollUpdateWasRequested,
    }: {
      scrollTop: number;
      scrollLeft: number;
      scrollUpdateWasRequested: boolean;
    }) => {
      setScrollLeft(scrollLeft);
      if (!scrollUpdateWasRequested) {
        checkboxGrid.current?.scrollTo({ scrollLeft: 0, scrollTop });
        permissionGrid.current?.scrollTo({ scrollLeft: 0, scrollTop });
        tableHeadGrid.current?.scrollTo({ scrollLeft: scrollLeft });
      }
    },
    []
  );
  // Used for highlighting rows on hover
  const onCellMouseEnter = useCallback(
    (rowIndex: number) => {
      const cells = document.getElementsByClassName(`cell--row--${rowIndex}`);
      if (cells.length > 0) {
        for (const cell of Array.from(cells)) {
          cell.classList.add(tableRowHoverCls ?? styles.row_hover);
        }
      }
    },
    [tableRowHoverCls]
  );

  const onCellMouseLeave = useCallback(
    (rowIndex: number) => {
      const cells = document.getElementsByClassName(`cell--row--${rowIndex}`);
      if (cells.length > 0) {
        for (const cell of Array.from(cells)) {
          cell.classList.remove(tableRowHoverCls ?? styles.row_hover);
        }
      }
    },
    [tableRowHoverCls]
  );

  const handleSelection = useCallback(
    (rowIndex: number) => {
      setSelection((prev) => {
        const _set = new Set(prev);
        if (_set.has(_items[rowIndex].id)) {
          _set.delete(_items[rowIndex].id);
        } else {
          _set.add(_items[rowIndex].id);
        }
        return _set;
      });
    },
    [_items]
  );

  const Checkbox = ({ rowIndex, data, style }: GridChildComponentProps<ItemData>) => {
    if (!isItemLoaded(rowIndex)) {
      return (
        <div className={`${styles.checkbox} ${styles.row}`} style={style}>
          <span className="skeleton-block" style={{ height: 14, width: 14, flexShrink: 0 }} />
        </div>
      );
    }
    const { prevSelectedRow, setPrevSelectedRow } = data;
    const isSelected = selection.has(_items[rowIndex].id);
    const rowCls = isSelected ? tableRowSelectedCls ?? styles.row_focused : "";
    return (
      <div
        className={`${styles.checkbox} ${styles.row} ${rowCls} cell--row--${rowIndex}`}
        style={style}
        onMouseEnter={() => {
          if (!isSelected) onCellMouseEnter(rowIndex);
        }}
        onMouseLeave={() => {
          if (!isSelected) onCellMouseLeave(rowIndex);
        }}
      >
        <CheckboxSquare
          checked={isSelected}
          onChange={(e, _) => {
            e.preventDefault();
            e.stopPropagation();
            if ((e.nativeEvent as PointerEvent)?.shiftKey && prevSelectedRow !== undefined) {
              let rowIndicies = range(rowIndex, prevSelectedRow);
              if (prevSelectedRow < rowIndex) {
                // Shift direction down
                rowIndicies = rowIndicies.slice(1);
              } else if (prevSelectedRow > rowIndex) {
                // Shift direction up
                rowIndicies = rowIndicies.slice(0, rowIndicies.length - 1);
              }
              const idIndicies = rowIndicies.map((i) => _items[i].id);
              setSelection((prev) => {
                const _set = new Set(prev);
                idIndicies.forEach((id) => {
                  if (_set.has(id)) {
                    _set.delete(id);
                  } else {
                    _set.add(id);
                  }
                });
                return _set;
              });
            } else {
              handleSelection(rowIndex);
            }
            setPrevSelectedRow(rowIndex);
          }}
          disabled={
            disableCheckboxNoPermission
              ? hasWritePermission !== undefined
                ? !hasWritePermission(_items[rowIndex])
                : !_items[rowIndex].permissions?.edit
              : maxSelectedItems && selection.size >= maxSelectedItems && !selection.has(_items[rowIndex].id)
              ? true
              : false
          }
        />
        {/* <input
          type="checkbox"
          checked={isSelected}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            e.preventDefault();
            e.stopPropagation();
            if ((e.nativeEvent as PointerEvent)?.shiftKey && prevSelectedRow !== undefined) {
              let rowIndicies = range(rowIndex, prevSelectedRow);
              if (prevSelectedRow < rowIndex) {
                // Shift direction down
                rowIndicies = rowIndicies.slice(1);
              } else if (prevSelectedRow > rowIndex) {
                // Shift direction up
                rowIndicies = rowIndicies.slice(0, rowIndicies.length - 1);
              }
              const idIndicies = rowIndicies.map((i) => _items[i].id);
              setSelection((prev) => {
                const _set = new Set(prev);
                idIndicies.forEach((id) => {
                  if (_set.has(id)) {
                    _set.delete(id);
                  } else {
                    _set.add(id);
                  }
                });
                return _set;
              });
            } else {
              handleSelection(rowIndex);
            }
            setPrevSelectedRow(rowIndex);
          }}
          disabled={
            disableCheckboxNoPermission
              ? hasWritePermission !== undefined
                ? !hasWritePermission(_items[rowIndex])
                : !_items[rowIndex].permissions?.edit
              : maxSelectedItems && selection.size >= maxSelectedItems && !selection.has(_items[rowIndex].id)
              ? true
              : false
          }
        /> */}
      </div>
    );
  };

  const getWritePermission = useCallback(
    (row: Entity) => {
      if (hasWritePermission !== undefined) {
        return hasWritePermission(row);
      } else {
        if (row.permissions?.edit) {
          return true;
        } else {
          return false;
        }
      }
    },
    [hasWritePermission]
  );

  const PermissionIndicator = ({ rowIndex, data, style }: GridChildComponentProps<ItemData>) => {
    if (!isItemLoaded(rowIndex)) {
      return (
        <div className={`${styles.checkbox} ${styles.row}`} style={style}>
          <span className="skeleton-block" style={{ height: 14, width: 14, flexShrink: 0 }} />
        </div>
      );
    }

    const isSelected = selection.has(_items[rowIndex].id);
    const rowCls = isSelected ? tableRowSelectedCls ?? styles.row_focused : "";

    const row = _items[rowIndex];
    const canWrite = getWritePermission(row);
    return (
      <div
        className={`${styles.checkbox} ${styles.row} ${rowCls} ${styles.permissions} cell--row--${rowIndex}`}
        style={style}
        onMouseEnter={() => {
          if (!isSelected) onCellMouseEnter(rowIndex);
        }}
        onMouseLeave={() => {
          if (!isSelected) onCellMouseLeave(rowIndex);
        }}
      >
        <div
          style={{ marginLeft: "auto", color: "var(--gray-400)" }}
          title={`${canWrite ? "Read/Write" : "Read-Only"}`}
        >
          {canWrite ? <LucideIcon name="lock-open" /> : <LucideIcon name="lock" color={"var(--warning)"} />}
        </div>
      </div>
    );
  };

  const FixedRowRenderer = ({ rowIndex, data, style }: GridChildComponentProps<ItemData>) => {
    if (!isItemLoaded(rowIndex)) {
      return (
        <div className={`${styles.checkbox} ${styles.row}`} style={style}>
          <span className="skeleton-block" style={{ height: 14, width: 14, flexShrink: 0 }} />
        </div>
      );
    }
    const isSelected = selection.has(_items[rowIndex].id);
    const rowCls = isSelected ? tableRowSelectedCls ?? styles.row_focused : "";
    const entity = _items[rowIndex];

    return (
      <div
        className={`${styles.checkbox} ${styles.row} ${rowCls} ${styles.permissions} cell--row--${rowIndex}`}
        style={style}
        onMouseEnter={() => {
          if (!isSelected) onCellMouseEnter(rowIndex);
        }}
        onMouseLeave={() => {
          if (!isSelected) onCellMouseLeave(rowIndex);
        }}
      >
        {fixedRowRenderer?.(entity)}
      </div>
    );
  };

  const handleResize = useCallback(
    (columnIndex: number, column: GenericVirtualizedTableCell<Entity>, props: DraggableData) => {
      const minWidth = column.minWidth ?? minColWidth;
      const maxWidth = column.maxWidth ?? maxColWidth;
      const deltaX = props.deltaX + columnWidthsRef.current[column.id];
      if (deltaX >= minWidth && deltaX <= maxWidth) {
        columnWidthsRef.current[column.id] = deltaX;
      }
    },
    [maxColWidth, minColWidth]
  );

  const Columns = ({ columnIndex, style }: GridChildComponentProps) => {
    if (!cols) return <></>;
    const column = cols[columnIndex];
    const align = switchAlign(column.align);
    const sumWidth = Object.values(cols)
      .map((c) => c.id)
      .map((id) => columnWidthsRef.current[id])
      .reduce((pv, cv) => pv + cv, 0);

    return (
      <div
        className={styles.row}
        style={{
          ...style,
          width: columnWidthsRef.current[column.id],
        }}
      >
        <div
          className={`${styles.resizer_container} ${
            columnSelect && !showPermissionColumn && tableHeadWidth && sumWidth >= tableHeadWidth
              ? styles.resizer_container_overflow
              : ""
          }`}
        >
          <div
            className={styles.column_header_container}
            onClick={() => {
              if (sortOnColumnHeaderClick) column.sortingFn?.(column.id);
            }}
            style={{
              justifyContent: align,
              cursor: sortOnColumnHeaderClick ? "pointer" : "default",
              marginLeft: align === "center" ? (column.sortingFn ? "28px" : "14px") : 0,
            }}
          >
            <span className={styles.column_header}>{column.Header}</span>
          </div>
          <div
            className={styles.sortable}
            onClick={async () => {
              if (column.sortingFn) {
                const prevScrollLeft = ((tableHeadGrid.current?.state as any)?.scrollLeft as number) ?? 0;
                const prevScrollTop = ((virtualLoaderGrid.current?._listRef.state as any)?.scrollTop as number) ?? 0;
                column.sortingFn(column.id);
                await waitUntil(() => isFetchingData === false && isLoading === false);
                setTimeout(() => handleScrollTo(prevScrollLeft, prevScrollTop), 300);
                // TODO the vertical offset doesn't trigger the infinite loader yet
                // const verticalItemIndex = Math.floor(prevScrollTop / fixedRowHeight);
              }
            }}
            style={{ cursor: column.sortingFn !== undefined ? "pointer" : "default" }}
          >
            {column.sortDirection ? (
              column.sortDirection(column.id) === "ASC" ? (
                <LucideIcon name="chevron-up" color={"var(--primary)"} strokeWidth={4} />
              ) : column.sortDirection(column.id) === "DESC" ? (
                <LucideIcon name="chevron-down" color={"var(--primary)"} strokeWidth={4} />
              ) : (
                <LucideIcon name="code" style={{ transform: "rotate(90deg)" }} />
              )
            ) : (
              ""
            )}
          </div>
        </div>
        <Draggable
          axis="x"
          defaultClassName={styles.draggable}
          defaultClassNameDragging={styles.draggable_active}
          onDrag={(event, props) => {
            tableHeadGrid.current?.resetAfterColumnIndex(columnIndex, false);
            virtualLoaderGrid.current?._listRef.resetAfterColumnIndex(columnIndex, false);
            handleResize(columnIndex, column, props);
            tableHeadGrid.current?.resetAfterColumnIndex(columnIndex, true);
            virtualLoaderGrid.current?._listRef.resetAfterColumnIndex(columnIndex, true);
          }}
          onStop={() => {
            // dispatchTabStore?.({ type: "setColumnWidths", columnWidths: columnWidthsRef.current });
            dispatchTabStore?.({
              type: "setTab",
              payload: {
                settings: {
                  columnWidths: columnWidthsRef.current,
                },
              },
            });
          }}
          position={{ x: 0, y: 0 }}
        >
          <div className={styles.draggable_handle} />
        </Draggable>
      </div>
    );
  };

  const Cell = ({ columnIndex, data, rowIndex, style }: GridChildComponentProps<ItemData>) => {
    if (!isItemLoaded(rowIndex)) return <RenderLoader style={style} fixedRowHeight={fixedRowHeight} />;
    const isSelected = selection.has(_items[rowIndex].id);
    const rowCls = isSelected ? tableRowSelectedCls ?? styles.row_focused : "";
    if (!cols) return <></>;
    const entity = _items[rowIndex];
    const column = cols[columnIndex];

    return (
      <Link
        key={rowIndex}
        to={linkTo !== undefined ? linkTo(entity) : route(getDetailLink(entityConstants.frontendIndexRoute, entity.id))}
        className={`${styles.row_link}`}
        onClick={(e) => e.preventDefault()}
        draggable="false"
      >
        <div
          className={`${styles.row} ${tableRowCls ?? ""} ${rowCls} cell--row--${rowIndex}`}
          style={{
            ...style,
            width: columnWidthsRef.current[column.id],
            justifyContent: switchAlign(cols[columnIndex].align),
          }}
          onClick={() => {
            if (onRowClick !== undefined) {
              onRowClick(entity);
            } else {
              history.push(route(getDetailLink(entityConstants.frontendIndexRoute, entity.id)));
            }
          }}
          onMouseEnter={() => {
            if (!isSelected) onCellMouseEnter(rowIndex);
          }}
          onMouseLeave={() => {
            if (!isSelected) onCellMouseLeave(rowIndex);
          }}
        >
          {cols[columnIndex].accessor(entity, rowIndex, columnIndex)}
        </div>
      </Link>
    );
  };

  const onColumnsSettingChange = useCallback(
    (settings: ColumnsSettings<Entity>) => {
      // console.log("onColumnsSettingChange", settings);
      setColumnSettingsState(settings);
      dispatchTabStore?.({
        type: "setTab",
        payload: {
          settings: {
            columnSettings: settings,
          },
        },
      });
    },
    [dispatchTabStore]
  );

  const onResetCallback = useCallback(() => {
    setColumnSettingsState(defaultColumnSettings);
    dispatchTabStore?.({
      type: "setTab",
      payload: {
        settings: {
          columnSettings: defaultColumnSettings ?? {},
          columnWidths: Object.fromEntries(columns.map((c) => [c.id, c.width])),
        },
      },
    });

    columnWidthsRef.current = Object.fromEntries(columns.map((c) => [c.id, c.width]));
    forceTableUpdate();
  }, [columns, defaultColumnSettings, dispatchTabStore, forceTableUpdate]);

  const totalWidth = cols
    ? Object.entries(cols)
        .map(([key, col]) => columnWidthsRef.current[col.id])
        .reduce((pv, cv) => pv + cv, 0)
    : 0;

  const colDraggableWidth = 150;
  const colDraggablePadding = 10;

  return (
    <div className={styles.container}>
      {!!Object.keys(columnWidthsRef.current).length && (
        <>
          {/* Column Headers - Syncronized horizontally */}
          <div className={`${styles.table_thead} ${tableHeadCls}`} style={{ height: headerRowHeight }}>
            {!disableCheckboxes && (
              <>
                {isLoading ? (
                  <div className={`${styles.checkbox} ${styles.row}`}>
                    <span className="skeleton-block" style={{ height: 14, width: 14, flexShrink: 0 }} />
                  </div>
                ) : (
                  <IndeterminateCheckbox
                    onChange={async (e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      await handleSelectAll();
                    }}
                    value={checked}
                  />
                )}
              </>
            )}
            {!!selection.size && <div className={styles.table_thead_main_checkbox_count}>{selection.size}</div>}
            <div className={styles.table_thead_columns} ref={tableHeadRef}>
              {isLoading ? (
                <div className={`${styles.table_thead_columns_loader} skeleton-block third`} />
              ) : (
                <>
                  {tableHeadWidth && cols && (
                    <VariableSizeGrid
                      ref={tableHeadGrid}
                      style={{
                        overflowX: "hidden",
                        scrollbarGutter: "stable",
                        paddingRight: scrollbarDimensions.width,
                      }}
                      // className="Grid"
                      columnCount={cols.length}
                      columnWidth={(index) => columnWidthsRef.current[cols[index].id]}
                      height={headerRowHeight}
                      rowCount={1}
                      rowHeight={(index) => headerRowHeight}
                      width={tableHeadWidth}
                    >
                      {Columns}
                    </VariableSizeGrid>
                  )}
                </>
              )}
            </div>
            {columnSelect && defaultColumnSettings && (
              <div
                className={`${styles.checkbox} ${styles.row} ${styles.column_selector}`}
                style={{ overflow: "visible" }}
              >
                {isLoading ? (
                  <span className="skeleton-block" style={{ height: 14, width: 14, flexShrink: 0 }} />
                ) : (
                  <ColumnsSelector
                    defaults={defaultColumnSettings}
                    columnsSetting={columnSetting}
                    onColumnsSettingChange={onColumnsSettingChange}
                    onReset={onResetCallback}
                  />
                )}
              </div>
            )}
            {fixedRowRendererColumn && fixedRowRenderer && fixedRowRendererColumnWidth && (
              <div className={`${styles.checkbox} ${styles.row}`} style={{ width: `${fixedRowRendererColumnWidth}px` }}>
                {isLoading ? (
                  <span
                    className="skeleton-block"
                    style={{
                      height: `${fixedRowRendererColumnWidth}px`,
                      width: `${fixedRowRendererColumnWidth}px`,
                      flexShrink: 0,
                    }}
                  />
                ) : (
                  <>{fixedRowRendererColumn()}</>
                )}
              </div>
            )}
          </div>

          <div className={styles.table_container} ref={tableContainerRef}>
            {isLoading ? (
              <RenderTableLoader />
            ) : error ? (
              <div style={{ padding: "10px 15px", width: "100%", height: "fit-content" }}>
                <LoadingWrapper status={status} fetchStatus={fetchStatus} error={error} children={undefined} />
              </div>
            ) : containerHeight && cols && !!_items.length ? (
              <>
                {/* Checkboxes - Syncronized vertically */}
                {!disableCheckboxes && (
                  <FixedSizeGrid
                    ref={checkboxGrid}
                    style={{
                      flexShrink: 0,
                      overflowY: "hidden",
                      boxShadow: "4px 0 4px 0 rgba(0, 0, 0, 0.08)",
                      paddingBottom: scrollbarDimensions.height,
                    }}
                    columnCount={1}
                    columnWidth={35} //Fixed checkboxes cell width
                    height={
                      _items.length * fixedRowHeight < containerHeight
                        ? _items.length * fixedRowHeight
                        : containerHeight
                    }
                    rowCount={_items.length}
                    rowHeight={fixedRowHeight}
                    width={35}
                    itemData={itemData}
                    // innerElementType={({ style, ...rest }) => (
                    //   <div style={{ ...style,  }} {...rest} />
                    // )}
                  >
                    {Checkbox}
                  </FixedSizeGrid>
                )}

                <InfiniteLoader
                  ref={virtualLoaderGrid}
                  isItemLoaded={isItemLoaded}
                  itemCount={itemCount}
                  loadMoreItems={loadMoreItems as any}
                  // threshold={pageSize}
                >
                  {({ onItemsRendered, ref }) => (
                    <div className={styles.table}>
                      <AutoSizer defaultHeight={containerHeight} disableHeight>
                        {({ width }) => (
                          /* Rows  */
                          <VariableSizeGrid
                            ref={ref}
                            onItemsRendered={({
                              visibleRowStartIndex,
                              visibleColumnStartIndex,
                              visibleRowStopIndex,
                              overscanRowStopIndex,
                              overscanRowStartIndex,
                            }) => {
                              // console.log(
                              //   "visibleRowStartIndex, visibleColumnStartIndex ",
                              //   visibleRowStartIndex,
                              //   visibleColumnStartIndex
                              // );
                              onItemsRendered({
                                overscanStartIndex: overscanRowStartIndex,
                                overscanStopIndex: overscanRowStopIndex,
                                visibleStartIndex: visibleRowStartIndex,
                                visibleStopIndex: visibleRowStopIndex,
                              });
                            }}
                            onScroll={onScroll} // For synchronizing
                            columnCount={cols.length}
                            columnWidth={(index) => columnWidthsRef.current[cols[index].id]}
                            height={containerHeight}
                            rowCount={_items.length}
                            rowHeight={(index) => fixedRowHeight}
                            width={width}
                            itemData={itemData}
                            className={styles.table_cell_container}
                          >
                            {Cell}
                          </VariableSizeGrid>
                        )}
                      </AutoSizer>
                    </div>
                  )}
                </InfiniteLoader>
                {fixedRowRendererColumn && fixedRowRenderer && fixedRowRendererColumnWidth && (
                  <FixedSizeGrid
                    ref={permissionGrid}
                    style={{ flexShrink: 0, overflowY: "hidden", boxShadow: "4px 0 4px 0 rgba(0, 0, 0, 0.08)" }}
                    columnCount={1}
                    columnWidth={fixedRowRendererColumnWidth} //Fixed column width
                    height={
                      _items.length * fixedRowHeight < containerHeight
                        ? _items.length * fixedRowHeight
                        : containerHeight
                    }
                    rowCount={_items.length}
                    rowHeight={fixedRowHeight}
                    width={fixedRowRendererColumnWidth}
                    itemData={itemData}
                  >
                    {FixedRowRenderer}
                  </FixedSizeGrid>
                )}
                {showPermissionColumn && (
                  <FixedSizeGrid
                    ref={permissionGrid}
                    style={{ flexShrink: 0, overflowY: "hidden", boxShadow: "4px 0 4px 0 rgba(0, 0, 0, 0.08)" }}
                    columnCount={1}
                    columnWidth={35} //Fixed checkboxes cell width
                    height={
                      _items.length * fixedRowHeight < containerHeight
                        ? _items.length * fixedRowHeight
                        : containerHeight
                    }
                    rowCount={_items.length}
                    rowHeight={fixedRowHeight}
                    width={35}
                    itemData={itemData}
                    // innerElementType={({ style, ...rest }) => (
                    //   <div style={{ ...style,  }} {...rest} />
                    // )}
                  >
                    {PermissionIndicator}
                  </FixedSizeGrid>
                )}
                {showColumnOverview && tableHeadWidth && (
                  <div className={styles.table_container_draggable} style={{ width: colDraggableWidth }}>
                    <div className={styles.table_container_draggable_inner}>
                      <div
                        className={styles.table_container_draggable_inner_elements}
                        style={{ width: colDraggableWidth - colDraggablePadding }}
                      >
                        {Object.entries(cols)
                          .map(([, col]) => columnWidthsRef.current[col.id])
                          .map((colwidth, index) => (
                            <span
                              key={index}
                              style={{
                                background: "var(--gray-200)",
                                height: "100%",
                                borderLeft: "1px solid var(--white)",
                                borderRight: "1px solid var(--white)",
                                width: (colwidth / totalWidth) * (colDraggableWidth - colDraggablePadding),
                                flexShrink: 0,
                                borderRadius: "2px",
                                overflow: "hidden",
                              }}
                            />
                          ))}
                      </div>
                      <Draggable
                        axis="x"
                        bounds="parent"
                        position={{
                          x: (scrollLeft / totalWidth) * (colDraggableWidth - colDraggablePadding),
                          y: 0,
                        }}
                        onDrag={(e, data) => {
                          const relPosition = data.x / (colDraggableWidth - colDraggablePadding);
                          const absolutePos = relPosition * totalWidth;
                          tableHeadGrid.current?.scrollTo({ scrollLeft: scrollLeft });
                          virtualLoaderGrid.current?._listRef.scrollTo({ scrollLeft: absolutePos });
                        }}
                      >
                        <div
                          className={styles.table_container_draggable_inner_currentViewport}
                          style={{
                            width: (tableHeadWidth / totalWidth) * (colDraggableWidth - colDraggablePadding),
                          }}
                        />
                      </Draggable>
                    </div>
                  </div>
                )}
              </>
            ) : (
              <NoEntriesFound
                message={
                  Object.hasOwn(filters, "searchTerm") && filters.searchTerm
                    ? `No results for search term: "${filters.searchTerm}"`
                    : undefined
                }
                style={{ width: "100%", height: "fit-content" }}
              />
            )}
          </div>
        </>
      )}
      {autoUpdateIndicator && (
        <div className={`${styles.containerUpdates} ${updates ? styles.containerUpdatesActive : ""}`}>
          <div className={styles.containerUpdatesInner}>
            <span>There are new updates to the table</span>
            <Button
              className="btn btn-xs btn-success"
              onClick={() => {
                refetch();
                hasUpdates(false);
              }}
              loading={isFetching}
            >
              <LucideIcon name="refresh-ccw" /> Refresh
            </Button>
            <div className={styles.containerUpdatesInnerDiscardIcon} onClick={() => hasUpdates(false)}>
              <LucideIcon name="x" />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
