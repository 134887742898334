import { useHistory, useLocation } from "react-router-dom";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { SessionContext } from "../../../common/contexts/SessionContext";
import { SearchInput } from "../../../common/forms/SearchInput/SearchInput";
import { LucideIcon } from "../../../common/icon/LucideIcon";
import TableView, { TableViewLabel } from "../../../common/panels/TableView/TableView";
import { getAddRoute, getIndexRoute } from "../../../main/Routing";
import { useEntityCount } from "../../../api/BaseEntityApi";
import { ColumnsSettings } from "../../../common/tables/ColumnsSelector/ColumnsSelector";
import { AlertModal } from "../../../common/modals/AlertModal/AlertModal";
// import { showtoast } from "../../../common/overlays/Toasts/showtoast";
import { MoreDropdown } from "../../../common/buttons/MoreDropdown/MoreDropdown";
import { EntityTable } from "../../../common/tables/EntityTable/EntityTable";
import {
  GenericVirtualizedTableCells,
  SortState,
} from "../../../common/tables/GenericVirtualizedTable/GenericVirtualizedTableTypes";
import { EntityFilterIndicator } from "../../../common/tables/EntityFilterIndicator/EntityFilterIndicator";
import { EntityTableTabs } from "../../../common/tables/Tabs/EntityTableTabs";
import { useTabStore } from "../../../common/tables/Tabs/useTabStore";
import { TableTabsDict } from "../../../common/tables/Tabs/TableTabsTypes";
import { DataSource, DataSourceFilters, DataSourceFiltersTranslator } from "../../../api/DataSource";
import { DataSourceFilterBar, DataSourceFilterForm } from "./DataSourceFilterBar";
import { BridgeFieldLabels, BridgeFilters, bridgeConstants } from "../../../api/Bridges";
import { Alert } from "../../../common/overlays/Alert/Alert";
import { useAutoloadClientList } from "../../../api/autoload/AutoloadClientsApi";
import { useMultiEdit } from "../../../common/forms/MultiEditForms/common/MultiEditUtils";
import { NotAvailable, NotSet } from "../../../common/misc/UIconstants";
import { ConnectionStatus, useLiveAutoloadConnection } from "../../../common/signalr/useLiveConnection";
import { DateTimeRenderer } from "../../../common/datetime/DateTimeFormatter";
import { CircularCountdown } from "../../../common/loaders/CircularCountdown/CircularCountdown";
import { GetPersons } from "../../../common/misc/EntityRenders/EntityRenderer";
import { useQueryClient } from "@tanstack/react-query";
import { BridgeConnectionStatus } from "../../Bridges/common/BridgeConnectionStatus";
import { DataSourceRunningStatus } from "../common/DataSourceRunningStatus/DataSourceRunningStatus";
import { ToggleIndicator } from "../../../common/badges/ToggleIndicator/ToggleIndicator";
import { ParameterFormatter } from "../../../ParameterViewer/TreeViewer/ParameterFormatter";
import { instrumentsConstants } from "../../../api/Facilities";
import { methodsConstants } from "../../../api/Methods";
import { parsersConstants } from "../../../api/Parsers";
import {
  UseEntityTableDefaultProps,
  UseEntityTableProps,
  useGenericVirtualizedTable,
  useGenericVirtualizedTableTabs,
} from "../../../common/tables/GenericVirtualizedTable/useGenericVirtualizedTable";
import { customImportConstants } from "../../../api/CustomImports";
import { EntityTableProps } from "../../../common/entity/EntityInterfaces";
import { EntityTableCloneButton } from "../../../common/entity/entityComponents/EntityTableCloneButton";
import { EntityTableDeleteButton } from "../../../common/entity/entityComponents/EntityTableDeleteButton";
import { EntityTableEditButton } from "../../../common/entity/entityComponents/EntityTableEditButton";

const parameterFormatter = new ParameterFormatter();

export const switchDataSourcesDefaultSortState = (
  sortState: DataSourceFilters["orderBy"]
): SortState<DataSourceFilters["orderBy"]> => {
  switch (sortState) {
    case "ID_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-id",
        orderBy: sortState,
      };
    case "ID_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-id",
        orderBy: sortState,
      };
    case "NAME_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-name",
        orderBy: sortState,
      };
    case "NAME_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-name",
        orderBy: sortState,
      };
    case "TYPE_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-type",
        orderBy: sortState,
      };
    case "TYPE_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-type",
        orderBy: sortState,
      };
    case "BRIDGE_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-bridge",
        orderBy: sortState,
      };
    case "BRIDGE_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-bridge",
        orderBy: sortState,
      };
    case "BRIDGE_TYPE_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-bridgeType",
        orderBy: sortState,
      };
    case "BRIDGE_TYPE_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-bridgeType",
        orderBy: sortState,
      };
    case "ENABLED_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-enabled",
        orderBy: sortState,
      };
    case "ENABLED_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-enabled",
        orderBy: sortState,
      };
    case "METHOD_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-method",
        orderBy: sortState,
      };
    case "METHOD_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-method",
        orderBy: sortState,
      };
    case "INSTRUMENT_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-instrument",
        orderBy: sortState,
      };
    case "INSTRUMENT_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-instrument",
        orderBy: sortState,
      };
    case "CREATED_ON_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-createdOn",
        orderBy: sortState,
      };
    case "CREATED_ON_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-createdOn",
        orderBy: sortState,
      };
    case "CREATED_BY_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-createdBy",
        orderBy: sortState,
      };
    case "CREATED_BY_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-createdBy",
        orderBy: sortState,
      };
    case "MODIFIED_ON_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-modifiedOn",
        orderBy: sortState,
      };
    case "MODIFIED_ON_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-modifiedOn",
        orderBy: sortState,
      };
    case "MODIFIED_BY_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-modifiedBy",
        orderBy: sortState,
      };
    case "MODIFIED_BY_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-modifiedBy",
        orderBy: sortState,
      };
    default:
      return {
        sortDirection: "ASC",
        headerId: "default-name",
        orderBy: sortState,
      };
  }
};
const defaultFilter: DataSourceFilters = { orderBy: "NAME_ASC" };

export const useDataSourceTableDefaults = ({ fieldLabels }: UseEntityTableDefaultProps<"dataSources">) => {
  const defaults: ColumnsSettings<DataSource> = useMemo(
    () => ({
      "default-id": { pos: 0, active: false, header: "Data Source-ID", property: "id" },
      "default-name": { pos: 1, active: true, header: fieldLabels.name, property: "name" },
      "default-enabled": { pos: 2, active: true, header: fieldLabels.enabled, property: "enabled" },
      "default-instrument": { pos: 3, active: true, header: fieldLabels.instrument, property: "instrument" },
      "default-method": { pos: 4, active: true, header: fieldLabels.method, property: "method" },
      "default-bridge": { pos: 5, active: true, header: fieldLabels.bridge, property: "bridge" },
      "default-bridgeStatus": { pos: 6, active: false, header: "Connection status" },
      "default-bridgeType": { pos: 7, active: true, header: BridgeFieldLabels.type, property: "bridge.type" },
      "default-nextScheduled": {
        pos: 8,
        active: false,
        header: fieldLabels.nextScheduledDate,
        property: "status.nextScheduledDate",
      },
      "default-interval": {
        pos: 9,
        active: true,
        header: fieldLabels.intervalInSeconds,
        property: "intervalInSeconds",
      },
      "default-status": { pos: 10, active: false, header: "Status", property: "status" },
      "default-startedOn": {
        pos: 11,
        active: false,
        header: "Last started on",
        property: "status.lastClientStatus.startedOn",
      },
      "default-duration": {
        pos: 12,
        active: false,
        header: "Duration of last run",
        property: "status.lastClientStatus.duration",
      },
      "default-format": { pos: 13, active: true, header: fieldLabels.format, property: "format" },
      "default-directories": {
        pos: 14,
        active: true,
        header: fieldLabels.directories,
        property: "directories",
      },
      "default-excludedFiles": {
        pos: 15,
        active: false,
        header: fieldLabels.fileExcludePatterns,
        property: "fileExcludePatterns",
      },
      "default-customImport": {
        pos: 16,
        active: false,
        header: fieldLabels.customImport,
        property: "customImport",
      },
      "default-type": { pos: 17, active: false, header: fieldLabels.type, property: "type" },
      "default-createdOn": { pos: 18, active: true, header: fieldLabels.createdOn, property: "createdOn" },
      "default-createdBy": { pos: 19, active: true, header: fieldLabels.createdBy, property: "createdBy" },
      "default-modifiedOn": { pos: 20, active: true, header: fieldLabels.modifiedOn, property: "modifiedOn" },
      "default-modifiedBy": { pos: 21, active: true, header: fieldLabels.modifiedBy, property: "modifiedBy" },
    }),
    [
      fieldLabels.bridge,
      fieldLabels.createdBy,
      fieldLabels.createdOn,
      fieldLabels.customImport,
      fieldLabels.directories,
      fieldLabels.enabled,
      fieldLabels.fileExcludePatterns,
      fieldLabels.format,
      fieldLabels.instrument,
      fieldLabels.intervalInSeconds,
      fieldLabels.method,
      fieldLabels.modifiedBy,
      fieldLabels.modifiedOn,
      fieldLabels.name,
      fieldLabels.nextScheduledDate,
      fieldLabels.type,
    ]
  );

  const tabStoreDefaults: TableTabsDict<DataSource, DataSourceFilters, DataSourceFilterForm> = useMemo(() => {
    let def: TableTabsDict<DataSource, DataSourceFilters, DataSourceFilterForm> = {
      default: {
        tabId: "default",
        type: "fixed",
        label: "All",
        title: "All",
        icon: "house",
        align: "left",
        xPos: 0,
        settings: {
          columnSettings: {},
          columnWidths: {},
          filters: defaultFilter,
          sidebarFilters: {},
        },
        forcedSettings: {
          columnSettings: {},
          columnWidths: {},
          filters: {},
          sidebarFilters: {},
        },
      },
      status: {
        tabId: "status",
        type: "fixed",
        label: "Status",
        title: "Status",
        icon: "play",
        align: "left",
        xPos: 1,
        settings: {
          columnSettings: {
            "default-id": { pos: 0, active: false, header: "Data Source-ID", property: "id" },
            "default-name": { pos: 1, active: true, header: fieldLabels.name, property: "name" },
            "default-instrument": {
              pos: 2,
              active: true,
              header: fieldLabels.instrument,
              property: "instrument",
            },
            "default-method": { pos: 3, active: false, header: fieldLabels.method, property: "method" },
            "default-enabled": { pos: 4, active: false, header: fieldLabels.enabled, property: "enabled" },
            "default-bridge": { pos: 5, active: false, header: fieldLabels.bridge, property: "bridge" },
            "default-bridgeStatus": { pos: 6, active: true, header: "Connection status" },
            "default-bridgeType": { pos: 7, active: false, header: BridgeFieldLabels.type, property: "bridge.type" },
            "default-nextScheduled": {
              pos: 8,
              active: true,
              header: fieldLabels.nextScheduledDate,
              property: "status.nextScheduledDate",
            },
            "default-interval": {
              pos: 9,
              active: true,
              header: fieldLabels.intervalInSeconds,
              property: "intervalInSeconds",
            },
            "default-status": { pos: 10, active: true, header: "Status", property: "status" },
            "default-startedOn": {
              pos: 11,
              active: true,
              header: "Last started on",
              property: "status.lastClientStatus.startedOn",
            },
            "default-duration": {
              pos: 12,
              active: true,
              header: "Duration of last run",
              property: "status.lastClientStatus.duration",
            },
            "default-format": { pos: 13, active: false, header: fieldLabels.format, property: "format" },
            "default-directories": {
              pos: 14,
              active: false,
              header: fieldLabels.directories,
              property: "directories",
            },
            "default-excludedFiles": {
              pos: 15,
              active: false,
              header: fieldLabels.fileExcludePatterns,
              property: "fileExcludePatterns",
            },
            "default-customImport": {
              pos: 16,
              active: false,
              header: fieldLabels.customImport,
              property: "customImport",
            },
            "default-type": { pos: 17, active: false, header: fieldLabels.type, property: "type" },
            "default-createdOn": {
              pos: 18,
              active: false,
              header: fieldLabels.createdOn,
              property: "createdOn",
            },
            "default-createdBy": {
              pos: 19,
              active: false,
              header: fieldLabels.createdBy,
              property: "createdBy",
            },
            "default-modifiedOn": {
              pos: 20,
              active: false,
              header: fieldLabels.modifiedOn,
              property: "modifiedOn",
            },
            "default-modifiedBy": {
              pos: 21,
              active: false,
              header: fieldLabels.modifiedBy,
              property: "modifiedBy",
            },
          },
          columnWidths: {},
          filters: defaultFilter,
          sidebarFilters: { enabled: { id: true, name: "Enabled" } },
        },
        forcedSettings: {
          columnSettings: {},
          columnWidths: {},
          filters: { enabled: true },
          sidebarFilters: { enabled: { id: true, name: "Enabled" } },
        },
      },
    };
    return def;
  }, [
    fieldLabels.bridge,
    fieldLabels.createdBy,
    fieldLabels.createdOn,
    fieldLabels.customImport,
    fieldLabels.directories,
    fieldLabels.enabled,
    fieldLabels.fileExcludePatterns,
    fieldLabels.format,
    fieldLabels.instrument,
    fieldLabels.intervalInSeconds,
    fieldLabels.method,
    fieldLabels.modifiedBy,
    fieldLabels.modifiedOn,
    fieldLabels.name,
    fieldLabels.nextScheduledDate,
    fieldLabels.type,
  ]);
  return { defaults, tabStoreDefaults };
};
export const useDataSourcesTableColumns = ({
  fieldLabels,
  entityConstants,
  sort,
  setSort,
  connectionStatus,
}: UseEntityTableProps<"dataSources"> & { connectionStatus: ConnectionStatus }) => {
  const columns = useMemo(() => {
    let headers: GenericVirtualizedTableCells<DataSource> = [
      {
        id: "default-id",
        Header: fieldLabels.id,
        accessor: (row) => (
          <span style={{ color: "var(--gray-400)" }}>
            <samp>{row.id}</samp>
          </span>
        ),
        width: 150,
        minWidth: 100,
        align: "right",
        sortingFn: () => {
          if (sort.headerId === "default-id") {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "ID_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "ID_ASC" }));
            }
          } else {
            setSort({ headerId: "default-id", sortDirection: "ASC", orderBy: "ID_ASC" });
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
      {
        id: "default-name",
        Header: fieldLabels.name,
        accessor: (row) => (
          <div style={{ display: "flex", flexFlow: "row nowrap", gap: "5px", overflow: "hidden" }}>
            <div style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
              <span style={{ fontWeight: 500, whiteSpace: "nowrap", marginRight: "5px" }}>
                <LucideIcon name={entityConstants.icon} color={"var(--primary)"} /> {row.name}
              </span>
            </div>
          </div>
        ),
        width: 200,
        minWidth: 100,
        align: "left",
        sortingFn: () => {
          if (sort.headerId === "default-name") {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "NAME_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "NAME_ASC" }));
            }
          } else {
            setSort({ headerId: "default-name", sortDirection: "ASC", orderBy: "NAME_ASC" });
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
      {
        id: "default-bridge",
        Header: fieldLabels.bridge,
        accessor: (row) => (
          <div style={{ display: "flex", flexFlow: "row nowrap", gap: "5px", overflow: "hidden" }}>
            <div style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
              <span style={{ fontWeight: 500, whiteSpace: "nowrap", marginRight: "5px" }}>
                <LucideIcon name={bridgeConstants.icon} color={"var(--primary)"} /> {row.bridge.name}
              </span>
            </div>
          </div>
        ),
        width: 150,
        minWidth: 100,
        align: "left",
        sortingFn: () => {
          if (sort.headerId === "default-bridge") {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "BRIDGE_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "BRIDGE_ASC" }));
            }
          } else {
            setSort({ headerId: "default-bridge", sortDirection: "ASC", orderBy: "BRIDGE_ASC" });
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
      {
        id: "default-bridgeType",
        Header: BridgeFieldLabels.type,
        accessor: (row) => (
          <div style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
            <label className="label label-soft-info" style={{ margin: 0 }}>
              <LucideIcon name="wifi" /> {row.bridge.type}
            </label>
          </div>
        ),
        width: 140,
        minWidth: 100,
        align: "left",
        sortingFn: () => {
          if (sort.headerId === "default-bridgeType") {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "BRIDGE_TYPE_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "BRIDGE_TYPE_ASC" }));
            }
          } else {
            setSort({ headerId: "default-bridgeType", sortDirection: "ASC", orderBy: "BRIDGE_TYPE_ASC" });
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
      {
        id: "default-bridgeStatus",
        Header: "Connection status",
        accessor: (row) => (
          <BridgeConnectionStatus
            bridge={row.bridge}
            dataSource={row}
            hideCheckConnection
            signalRConnection={connectionStatus}
          />
        ),
        width: 180,
        minWidth: 100,
        align: "left",
      },
      {
        id: "default-status",
        Header: "Status",
        accessor: (row) => <DataSourceRunningStatus dataSource={row} />,
        width: 150,
        minWidth: 100,
        align: "left",
      },
      {
        id: "default-enabled",
        Header: fieldLabels.enabled,
        accessor: (row) => <ToggleIndicator enabled={row.enabled} />,
        minWidth: 100,
        width: 100,
        align: "left",
        sortingFn: () => {
          if (sort.headerId === "default-enabled") {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "ENABLED_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "ENABLED_ASC" }));
            }
          } else {
            setSort({ headerId: "default-enabled", sortDirection: "ASC", orderBy: "ENABLED_ASC" });
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
      {
        id: "default-interval",
        Header: fieldLabels.intervalInSeconds,
        accessor: (row) => <span>{Math.round(row.intervalInSeconds / 60)}</span>,
        minWidth: 100,
        width: 150,
        align: "left",
        sortingFn: () => {
          if (sort.headerId === "default-interval") {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "INTERVAL_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "INTERVAL_ASC" }));
            }
          } else {
            setSort({ headerId: "default-interval", sortDirection: "ASC", orderBy: "INTERVAL_ASC" });
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
      {
        id: "default-type",
        Header: fieldLabels.type,
        accessor: (row) => (
          <label className="label label-soft-secondary" style={{ margin: 0 }}>
            {row.type}
          </label>
        ),
        width: 150,
        minWidth: 100,
        align: "left",
        sortingFn: () => {
          if (sort.headerId === "default-type") {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "TYPE_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "TYPE_ASC" }));
            }
          } else {
            setSort({ headerId: "default-type", sortDirection: "ASC", orderBy: "TYPE_ASC" });
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
      {
        id: "default-startedOn",
        Header: "Current run started on",
        accessor: (row) => (
          <>
            {row.status?.lastClientStatus?.startedOn && row.status?.lastClientStatus?.runState === "Running" ? (
              <DateTimeRenderer date={row.status.lastClientStatus.startedOn} includeElapsed={true} />
            ) : (
              NotAvailable
            )}
          </>
        ),
        minWidth: 100,
        width: 240,
        align: "left",
      },

      {
        id: "default-duration",
        Header: "Last run duration",
        accessor: (row) => (
          <>
            {row.status?.lastClientStatus?.duration && row.status?.lastClientStatus?.runState !== "Running"
              ? parameterFormatter.formatDuration(row.status.lastClientStatus.duration, 1)
              : NotAvailable}
          </>
        ),
        minWidth: 100,
        width: 160,
        align: "left",
      },
      {
        id: "default-nextScheduled",
        Header: "Next scheduled",
        accessor: (row) => (
          <>
            {row.enabled &&
            row.status?.lastClientStatus?.runState !== "Running" &&
            row.status?.lastClientStatus?.startedOn &&
            row.status.nextScheduledDate ? (
              <div className="flex row-nowrap align-center gap-5">
                <CircularCountdown
                  startTime={new Date(row.status.lastClientStatus.startedOn)}
                  endTime={new Date(row.status.nextScheduledDate)}
                  width={24}
                  style={{ stroke: "var(--primary)" }}
                />
                <DateTimeRenderer date={row.status?.nextScheduledDate} includeElapsed={true} />
              </div>
            ) : (
              NotAvailable
            )}
          </>
        ),
        minWidth: 100,
        width: 300,
        align: "left",
      },
      {
        id: "default-format",
        Header: fieldLabels.format,
        accessor: (row) =>
          row.format.name ? (
            <div
              style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                fontWeight: 600,
                whiteSpace: "nowrap",
                marginRight: "5px",
              }}
            >
              <LucideIcon name={parsersConstants.icon} color="var(--primary)" />
              <span> {row.format.name}</span>
            </div>
          ) : (
            NotSet
          ),
        width: 150,
        minWidth: 100,
        align: "left",
      },
      {
        id: "default-customImport",
        Header: fieldLabels.customImport,
        accessor: (row) => (
          <div style={{ display: "flex", flexFlow: "row nowrap", gap: "5px", overflow: "hidden" }}>
            <div style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
              <span style={{ whiteSpace: "nowrap", marginRight: "5px" }}>
                <LucideIcon name={customImportConstants.icon} color={"var(--primary)"} />{" "}
                {row.customImport?.name ?? NotSet}
              </span>
            </div>
          </div>
        ),
        width: 140,
        minWidth: 100,
        align: "left",
      },
      {
        id: "default-instrument",
        Header: fieldLabels.instrument,
        accessor: (row) =>
          row.instrument?.name ? (
            <div
              style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                fontWeight: 600,
                whiteSpace: "nowrap",
                marginRight: "5px",
              }}
            >
              <LucideIcon name={instrumentsConstants.icon} color="var(--primary)" />
              <span> {row.instrument.name}</span>
            </div>
          ) : (
            NotSet
          ),
        width: 200,
        minWidth: 100,
        align: "left",
        sortingFn: () => {
          if (sort.headerId === "default-instrument") {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "INSTRUMENT_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "INSTRUMENT_ASC" }));
            }
          } else {
            setSort({ headerId: "default-instrument", sortDirection: "ASC", orderBy: "INSTRUMENT_ASC" });
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
      {
        id: "default-method",
        Header: fieldLabels.method,
        accessor: (row) =>
          row.method.name ? (
            <div
              style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                fontWeight: 600,
                whiteSpace: "nowrap",
                marginRight: "5px",
              }}
            >
              <LucideIcon name={methodsConstants.icon} color="var(--primary)" />
              <span> {row.method.name}</span>
            </div>
          ) : (
            NotSet
          ),
        width: 150,
        minWidth: 100,
        align: "left",
        sortingFn: () => {
          if (sort.headerId === "default-method") {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "METHOD_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "METHOD_ASC" }));
            }
          } else {
            setSort({ headerId: "default-method", sortDirection: "ASC", orderBy: "METHOD_ASC" });
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
      {
        id: "default-createdOn",
        Header: fieldLabels.createdOn,
        accessor: (row) => <DateTimeRenderer date={row.createdOn} includeElapsed={false} />,
        width: 200,
        minWidth: 100,
        align: "left",
        sortingFn: () => {
          if (sort.headerId === "default-createdOn") {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "CREATED_ON_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "CREATED_ON_ASC" }));
            }
          } else {
            setSort({ headerId: "default-createdOn", sortDirection: "ASC", orderBy: "CREATED_ON_ASC" });
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
      {
        id: "default-createdBy",
        Header: fieldLabels.createdBy,
        accessor: (row) => <GetPersons persons={row.createdBy} createLinks={false} />,
        width: 200,
        minWidth: 100,
        align: "left",
        sortingFn: () => {
          if (sort.headerId === "default-createdBy") {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "CREATED_BY_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "CREATED_BY_ASC" }));
            }
          } else {
            setSort({ headerId: "default-createdBy", sortDirection: "ASC", orderBy: "CREATED_BY_ASC" });
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
      {
        id: "default-modifiedOn",
        Header: fieldLabels.modifiedOn,
        accessor: (row) => <DateTimeRenderer date={row.modifiedOn} includeElapsed={false} />,
        width: 200,
        minWidth: 100,
        align: "left",
        sortingFn: () => {
          if (sort.headerId === "default-modifiedOn") {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "MODIFIED_ON_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "MODIFIED_ON_ASC" }));
            }
          } else {
            setSort({ headerId: "default-modifiedOn", sortDirection: "ASC", orderBy: "MODIFIED_ON_ASC" });
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
      {
        id: "default-modifiedBy",
        Header: fieldLabels.modifiedBy,
        accessor: (row) => <GetPersons persons={row.modifiedBy} createLinks={false} />,
        width: 200,
        minWidth: 100,
        align: "left",
        sortingFn: () => {
          if (sort.headerId === "default-modifiedBy") {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "MODIFIED_BY_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "MODIFIED_BY_ASC" }));
            }
          } else {
            setSort({ headerId: "default-modifiedBy", sortDirection: "ASC", orderBy: "MODIFIED_BY_ASC" });
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
      {
        id: "default-directories",
        Header: fieldLabels.directories,
        accessor: (row) => (
          <div style={{ overflow: "auto", display: "flex", flexWrap: "wrap", gap: "5px" }}>
            {row.directories?.map((f) => (
              <span
                style={{ overflow: "hidden", textOverflow: "ellipsis" }}
                className="label label-soft-default"
                title={`File path: ${f}`}
              >
                {f}
              </span>
            ))}
          </div>
        ),
        width: 300,
        minWidth: 100,
        align: "left",
      },
      {
        id: "default-excludedFiles",
        Header: fieldLabels.fileExcludePatterns,
        accessor: (row) => (
          <div style={{ overflow: "auto", display: "flex", flexWrap: "wrap", gap: "5px" }}>
            {row.fileExcludePatterns?.map((f) => (
              <span
                style={{ overflow: "hidden", textOverflow: "ellipsis" }}
                className="label label-soft-default"
                title={`Regular expression: ${f.regex}`}
              >
                {f.name}
              </span>
            ))}
          </div>
        ),
        width: 200,
        minWidth: 100,
        align: "left",
      },
    ];

    return headers;
  }, [
    connectionStatus,
    entityConstants.icon,
    fieldLabels.bridge,
    fieldLabels.createdBy,
    fieldLabels.createdOn,
    fieldLabels.customImport,
    fieldLabels.directories,
    fieldLabels.enabled,
    fieldLabels.fileExcludePatterns,
    fieldLabels.format,
    fieldLabels.id,
    fieldLabels.instrument,
    fieldLabels.intervalInSeconds,
    fieldLabels.method,
    fieldLabels.modifiedBy,
    fieldLabels.modifiedOn,
    fieldLabels.name,
    fieldLabels.type,
    setSort,
    sort.headerId,
    sort.sortDirection,
  ]);
  return { columns };
};
export const DataSourcesTable = ({
  entityApi,
  entityConstants,
  fieldLabels,
  permissions,
  routes,
}: EntityTableProps<"dataSources">) => {
  const history = useHistory();
  const { route, session } = useContext(SessionContext);
  const [showModal, setShowModal] = useState(false);
  const { bulkDeleteMutationAsync, isLoadingBulkDeleteMutation } = entityApi;

  const { applyPayload } = useMultiEdit<DataSource>({ resource: entityConstants.resource });

  const { defaults, tabStoreDefaults } = useDataSourceTableDefaults({ fieldLabels });

  const { selection, resultsCount, selectionPermissions, onCountChange, onSelectionChange, onSelectionPermissions } =
    useGenericVirtualizedTable<DataSource>();

  const {
    filters,
    sidebarFilters,
    forcedFilters,
    columnSetting,
    columnWidths,
    customTabs,
    fixedTabs,
    dispatchTabStore,
    currentTab,
    tabsLoading,
    tabsModified,
  } = useTabStore<DataSource, DataSourceFilters, DataSourceFilterForm>({
    resource: entityConstants.resource,
    defaults: tabStoreDefaults,
  });

  const { functionRef, sort, setSort, searchValue, setSearchValue, onTabChange } = useGenericVirtualizedTableTabs({
    tabsLoading,
    filters,
    switchSortState: switchDataSourcesDefaultSortState,
    dispatchTabStore,
  });

  const { data: clients, refetch: refetchClients } = useAutoloadClientList();
  const queryClient = useQueryClient();

  const [refetchTimeout, setRefetchTimeout] = useState<NodeJS.Timeout | undefined>(undefined);
  const refetchDataSources = useCallback(() => {
    if (refetchTimeout) clearTimeout(refetchTimeout);
    setRefetchTimeout(
      setTimeout(() => {
        functionRef.current?.refetch();
      }, 500)
    );
    return () => refetchTimeout && clearTimeout(refetchTimeout);
  }, [functionRef, refetchTimeout]);

  const refetchBridges = useCallback(
    (bridgeId: number) => {
      queryClient.invalidateQueries({ queryKey: [bridgeConstants.resource, "detail", bridgeId] });
    },
    [queryClient]
  );

  const { connectionStatus } = useLiveAutoloadConnection({
    callbacks: {
      ClientListUpdated: refetchClients,
      DataSourceScheduled: () => {},
      ClientConnectionChanged: refetchBridges,
      DataSourceStatusChanged: refetchDataSources,
    },
    enabled: true,
  });

  const { columns } = useDataSourcesTableColumns({ fieldLabels, entityConstants, sort, setSort, connectionStatus });

  const memoizedFilters = useMemo(() => ({ ...filters, ...forcedFilters }), [filters, forcedFilters]);

  // additional informations
  const { data: bridgeCount } = useEntityCount<BridgeFilters>(bridgeConstants.resource, {
    includeCount: true,
  });

  // Client approval
  const clientsWaitingForApproval = useMemo(() => clients?.filter((client) => !client.isApproved), [clients]);

  // TODO: remove once generic in tab store
  const { search: queryString } = useLocation();
  useEffect(() => {
    if (!tabsLoading) {
      let params = Object.fromEntries(new URLSearchParams(queryString));
      if ("tabId" in params && !!params["tabId"]) (async () => await onTabChange(params["tabId"]))();
    }
  }, [history, onTabChange, queryString, tabsLoading]);

  return (
    <TableView>
      <TableView.Head>
        <TableView.Head.Label>
          <TableViewLabel entityConstants={entityConstants} resultsCount={resultsCount} />
        </TableView.Head.Label>
        <TableView.Head.Controls>
          <button
            className="btn btn-primary"
            onClick={() => {
              history.push(route(getAddRoute(bridgeConstants.frontendIndexRoute)));
            }}
          >
            <LucideIcon name="plus" /> Add {bridgeConstants.entitySingular}
          </button>
          <button
            className="btn btn-primary"
            onClick={() => history.push(route(getAddRoute(entityConstants.frontendIndexRoute)))}
            disabled={!bridgeCount?.count}
          >
            <LucideIcon name="plus" /> Add {entityConstants.entitySingular}
          </button>

          <button
            className="btn btn-default"
            onClick={() => {
              // setShowBridgesModal(true);
              history.push(route(getIndexRoute(bridgeConstants.frontendIndexRoute)));
            }}
            disabled={!bridgeCount?.count}
          >
            <LucideIcon name="table" /> List {bridgeConstants.entityPlural}
          </button>
        </TableView.Head.Controls>
      </TableView.Head>
      <TableView.Body>
        <TableView.Body.Sidebar>
          <DataSourceFilterBar
            initialValues={sidebarFilters}
            dispatchTabStore={dispatchTabStore}
            tabsLoading={tabsLoading}
            currentTab={currentTab}
          />
        </TableView.Body.Sidebar>
        <TableView.Body.Content>
          <EntityTableTabs
            currentTab={currentTab}
            onTabChange={onTabChange}
            fixedTabs={fixedTabs}
            filters={filters}
            sidebarFilters={sidebarFilters}
            columnSetting={columnSetting}
            columnWidths={columnWidths}
            customTabs={customTabs}
            dispatchTabStore={dispatchTabStore}
            tabsLoading={tabsLoading}
            tabsModified={tabsModified}
          />
          <EntityTable>
            <EntityTable.Controls
              style={{
                borderTop: "0px",
                borderRadius: "0px",
              }}
            >
              <div className="flex col-nowrap gap-5" style={{ width: "100%" }}>
                {Array.isArray(clientsWaitingForApproval) && clientsWaitingForApproval.length > 0 && (
                  <>
                    <Alert
                      type="warning"
                      message={
                        <div className="flex row-nowrap gap-5 align-center">
                          There are clients waiting to be configured.
                          <button
                            className="btn btn-sm btn-warning"
                            onClick={() =>
                              history.push(`${route(getAddRoute(bridgeConstants.frontendIndexRoute))}?step=2`)
                            }
                          >
                            Setup clients
                          </button>
                        </div>
                      }
                      fit
                      style={{ marginBottom: 0 }}
                    />
                  </>
                )}
                <div className="flex row-nowrap gap-5">
                  <EntityFilterIndicator<DataSource, DataSourceFilters>
                    filters={filters}
                    excludeFilters={{
                      enabled: (value) => currentTab !== "status" && value !== undefined && value !== null,
                    }}
                    translatorConsts={DataSourceFiltersTranslator}
                  />
                  <SearchInput searchValue={searchValue} setSearchValue={setSearchValue} placeholder="Search" />
                  {selection.size > 0 && (
                    <div style={{ display: "flex", width: "fit-content", height: "100%", alignItems: "center" }}>
                      <span
                        style={{
                          color: "var(--gray-400)",
                          fontWeight: "normal",
                          padding: "0 5px",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {selection.size}{" "}
                        {selection.size === 1 ? entityConstants.entitySingular : entityConstants.entityPlural} selected
                      </span>
                    </div>
                  )}
                  <EntityTableEditButton
                    entityConstants={entityConstants}
                    routes={routes}
                    permissions={permissions}
                    selection={selection}
                    selectionPermissions={selectionPermissions}
                  />
                  <MoreDropdown drop="right" btn="btn btn-ghost-secondary" disabled={selection.size === 0}>
                    <li>
                      <button
                        className="btn btn-ghost-success btn-sm"
                        disabled={selection.size === 0 || !session?.permissions.administer_autoload}
                        title={`Enable the selected ${entityConstants.entityPlural}`}
                        onClick={async () => {
                          await applyPayload(Array.from(selection), { enabled: true }).catch(() => {});
                        }}
                      >
                        <LucideIcon name="toggle-right" /> Enable
                      </button>
                    </li>

                    <li>
                      <button
                        className="btn btn-ghost-warning btn-sm"
                        disabled={selection.size === 0 || !session?.permissions.administer_autoload}
                        title={`Disable the selected ${entityConstants.entityPlural}`}
                        onClick={async () => {
                          await applyPayload(Array.from(selection), { enabled: false }).catch(() => {});
                        }}
                      >
                        <LucideIcon name="toggle-left" /> Disable
                      </button>
                    </li>
                    <li>
                      <EntityTableCloneButton
                        entityConstants={entityConstants}
                        routes={routes}
                        permissions={permissions}
                        selection={selection}
                      />
                    </li>
                    <li>
                      <EntityTableDeleteButton
                        entityConstants={entityConstants}
                        permissions={permissions}
                        selection={selection}
                        selectionPermissions={selectionPermissions}
                        onClick={() => setShowModal(true)}
                      />
                    </li>
                  </MoreDropdown>
                  <AlertModal
                    type="danger"
                    showModal={showModal}
                    setShowModal={setShowModal}
                    title={`${
                      selection.size === 0
                        ? `Select ${entityConstants.entitySingular} to delete`
                        : selection.size === 1
                        ? `Delete the selected ${entityConstants.entitySingular}`
                        : `Delete the selected ${entityConstants.entityPlural}`
                    }`}
                    description={`Proceeding will permanently delete the selected ${entityConstants.entityPlural}.`}
                    proceedLabel="Delete"
                    onProceed={async () => {
                      await bulkDeleteMutationAsync({
                        ids: Array.from(selection),
                        goBackOnSuccess: false,
                        showToast: false,
                        entityName: entityConstants.entityPlural,
                        params: { deletePermanently: true },
                      }).catch((e) => {});
                      functionRef.current?.resetSelection();
                      setShowModal(false);
                    }}
                    loading={isLoadingBulkDeleteMutation}
                  />
                </div>
              </div>
            </EntityTable.Controls>
            <EntityTable.Body<DataSource, DataSourceFilters>
              functionRef={functionRef}
              entityConstants={entityConstants}
              filters={memoizedFilters}
              columns={columns}
              columnSelect
              columnSetting={columnSetting}
              columnWidths={columnWidths}
              defaultColumnSettings={defaults}
              dispatchTabStore={dispatchTabStore}
              setResultsCount={onCountChange}
              onSelectionChange={onSelectionChange}
              onSelectionPermissions={onSelectionPermissions}
              showPermissionColumn
              loading={tabsLoading}
              // useInfiniteQueryOptions={{ cacheTime: 300000, staleTime: 300000 }}
            />
          </EntityTable>
        </TableView.Body.Content>
      </TableView.Body>
    </TableView>
  );
};
