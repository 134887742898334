import produce from "immer";
import { useCallback, useContext, useEffect, useReducer, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { useParams } from "react-router-dom";

import { SessionContext } from "../common/contexts/SessionContext";
import { ErrorFallback } from "../common/ErrorFallback";
import { Skeleton } from "../common/loaders/Skeleton/Skeleton";
import { DatasetStructureViewer } from "../DatasetStructureViewer/DatasetStructureViewer";
import { ParameterComponent } from "../ParameterViewer/ParameterComponent";
import { nmr_processing } from "../ProcessingPipeline/nmrProcessing.json";
import { Pipeline } from "../ProcessingPipeline/Pipeline";
import { commandTypes, pipelineSetting, PipelineSettings } from "../ProcessingPipeline/PipelineTypes";
import { AutoResizeDiv, FittingDiv } from "../ViewerUIElements/ResizeLayout/AutoResizeDiv";
import { FitToParentDiv } from "../ViewerUIElements/ResizeLayout/FitToParentDiv";
import { HorizontalResizeDiv } from "../ViewerUIElements/ResizeLayout/HorizontalResizeDiv";
import { VerticalResizeDiv } from "../ViewerUIElements/ResizeLayout/VerticalResizeDiv";
import { DebugButtons } from "./DebugButtons";
import { ParserLogsModal } from "./ParserLogsIcon";
import { SourceModal } from "./SourceModal";
import { TrackTypeTab } from "./TrackTypeTab";
import styles from "./ViewerLayout.module.css";
import {
  checkRunningJobs,
  fetchDatasets,
  fetchParserLog,
  fetchParserOutput,
  reparseDatasets,
  savePipelines,
} from "./ViewerLayoutApi";
import { useTranslatedFieldUpdate } from "./ViewerLayoutHooks";
import {
  JobInfo,
  JobStates,
  newHierarchyNode,
  NMRTrack,
  pipelineNodeState,
  Track,
  trackModes,
  trackSettingsList,
  updateTypeInstance,
  ViewerLayoutSettings,
  viewerLogType,
  viewerMapping,
  viewerType,
  viewPortType,
} from "./ViewerLayoutTypes";
import { initParameterList, initTrackFromTrack, initViewerType, run } from "./ViewerLayoutUtils";
import { ViewerLog } from "./ViewerLog";
import { ViewerSelector } from "./ViewerSelector";
import { ViewerStateReducer } from "./ViewerStateReducer";
import { initViewerModeType, trackState, viewerModeType } from "./ViewerTypes";
import { SingleEntityRoutingParams } from "../common/entity/EntityInterfaces";

// import { process1DNmrFID } from "./NMRProcessing/NMRProcessing";
// import 'bootstrap3/dist/css/bootstrap.min.css'

export type ViewerLayoutProps = {
  ids: number[];
  settings?: Partial<ViewerLayoutSettings>;
};

const SettingFromPartialSettings = (settings?: Partial<ViewerLayoutSettings>): ViewerLayoutSettings => {
  const result: ViewerLayoutSettings = {
    showViewer: true,
    showParameter: true,
    showNavigation: true,
    showTrackList: true,
    interactiveMode: true,
  };
  if (settings) updateTypeInstance(result, settings);
  return result;
};

const ViewerLayout = ({ ids, settings }: ViewerLayoutProps) => {
  // const [pipelinePromises, setPipelinePromises] = useState<Record<string, "running" | "idle">>({});
  const [settingList, setSettingList] = useState<pipelineSetting[]>([]);
  const [reparsing, setReparsing] = useState<boolean>(false);
  const [nmrTracks] = useState<Record<string, NMRTrack>>({});
  // const [nmrTrackSettings, setNmrTrackSettings] = useState<TrackNMRProcessingData[]>([]);
  const [parserOutput, setParserOutput] = useState<string>("");
  const [showParserOutput, setShowParserOutput] = useState<boolean>(false);
  const [viewerLogs, setViewerLogs] = useState<viewerLogType[]>([]);
  const [showParserLogs, setShowParserLogs] = useState<boolean>(false);

  const viewerSettings = useTranslatedFieldUpdate(settings, SettingFromPartialSettings, {});

  const { api, session } = useContext(SessionContext);

  const [viewerState, dispatch] = useReducer<typeof ViewerStateReducer>(ViewerStateReducer, {
    count: 0,
    datasets: {},
    parserLogs: [],
    tracks: {},
    tracksHierarchy: newHierarchyNode(),
    viewerTracks: {},
    datatracks: {},
    annotations: [],
    parameter: initParameterList(),
    internalParameters: [],
    updateParameter: false,
    tracksToZoom: [],
    selectedTracks: [],
    viewerMode: initViewerModeType(),
    viewerTypes: initViewerType(),
    // viewerCalls: { xy: 0, xyz: 0, image: 0, pdf: 0, sequence: 0, unknown: 0 },
    viewerCalls: { xy: 0, xyz: 0, image: 0, pdf: 0, table: 0, molecule: 0, unknown: 0 },
    activeViewer: undefined,
    viewerSettings: {},
    noTracks: undefined,
    noParameters: undefined,
    viewPort: { width: 0, height: 0, left: 0, top: 0 },
    pipelines: undefined,
    viewerPipelines: {},
    pipelineStates: {},
    pipelineParamter: undefined,
    viewerStatus: { status: "success", logs: [] },
  });

  // useLayoutEffect(() => {
  //   handleResize();
  //   window.addEventListener("resize", handleResize);
  //   // if (targetRef.current && svg) {
  //   //   svg.attr("height", dimensions.height).attr("width", dimensions.width);
  //   // }
  //   //   // viewer.resize(bound.width, bound.height);
  //   // console.log("dimensions", dimensions.width, dimensions.height);
  // }, [targetRef.current]);

  const currentIds = useTranslatedFieldUpdate(
    ids,
    (): number[] => {
      if (!Array.isArray(ids)) return [];
      return ids.filter((id) => !isNaN(id) && id !== undefined);
    },
    []
  );

  useEffect(() => {
    let isMounted = true;

    if (currentIds.length > 0 || (reparsing === false && currentIds.length > 0)) {
      console.log(`--- datasets ${currentIds} ---`);
      fetchDatasets(api, currentIds, dispatch, viewerState.count, () => isMounted).then((count) => {
        if (isMounted) dispatch({ type: "incrementCount", count: count });
      });
    }

    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api, currentIds, reparsing]);

  // useEffect(() => {
  //   console.log("--- mount ---");
  //   return () => {
  //     console.log("--- unmount ---");
  //   };
  // }, []);

  // const phaseShift = useCallback(() => {
  //   const tracks = Object.values(viewerState.tracks).filter(
  //     (track) => track.idArray[track.idArray.length - 1] === "spectrum"
  //   ) as TrackXY[];
  //   const datatracks: DatatrackNumericArray[] = [];
  //   tracks.forEach((track) => {
  //     const x = viewerState.datatracks[track.data.x ?? ""] as DatatrackNumericArray;
  //     const y = viewerState.datatracks[track.data.y ?? ""] as DatatrackNumericArray;
  //     if (x && y) {
  //       const phase = Math.PI * Math.random();
  //       const v = new Float32Array(y.data.length);
  //       for (let i = 0; i < x.data.length; i++) {
  //         const x0 = x.data[i];
  //         y.data[i] = Math.cos(x0 - phase);
  //       }
  //       datatracks.push(y);
  //     }
  //   });
  //   dispatch({
  //     type: "setTracks",
  //     tracks: tracks.map((track) =>
  //       produce(track, (next) => {
  //         next.generation = track.generation + 1;
  //       })
  //     ),
  //     datatracks: datatracks,
  //   });
  // }, [viewerState.tracks, viewerState.datatracks]);

  // const processFID = useCallback(
  //   (data: TrackData[], settings: TrackNMRProcessingData) => {
  //     const leafs: HierarchyLeaf[] = [];
  //     const newTracks: Track[] = [];
  //     const newDatatracks: Datatrack[] = [];

  //     const re = viewerState.tracks[data[0].id] as TrackXY;
  //     const im = viewerState.tracks[data[1].id] as TrackXY;

  //     const idArray = re.idArray.slice(0, re.idArray.length - 1);
  //     idArray.push("spectrum");
  //     const id = idArrayToId(idArray);
  //     let spectrum: TrackXY;
  //     let xVal: DatatrackNumericArray;
  //     let yVal: DatatrackNumericArray;
  //     if (id in viewerState.tracks) {
  //       // console.log("found track", id);
  //       spectrum = viewerState.tracks[id] as TrackXY;
  //     } else {
  //       // console.log("new track", id);
  //       xVal = initDatatrackNumericArray();
  //       yVal = initDatatrackNumericArray();

  //       const spec = process1DNmrFID(
  //         (data[0] as TrackXYData).data.x[0],
  //         (data[0] as TrackXYData).data.y[0],
  //         (data[1] as TrackXYData).data.y[0],
  //         settings
  //       );
  //       xVal.data = spec.x;
  //       yVal.data = spec.y;
  //       xVal.min = [spec.xRange[0]];
  //       xVal.max = [spec.xRange[1]];
  //       yVal.min = [spec.yRange[0]];
  //       yVal.max = [spec.yRange[1]];

  //       spectrum = initTrackXY({
  //         name: "Spectrum",
  //         id: id,
  //         idArray: idArray,
  //         type: "XY_real",
  //         data: { x: xVal.id, y: yVal.id },
  //         generation: 1,
  //       });
  //       spectrum.settings.zoom.x = [xVal.min[0], xVal.max[0]];
  //       spectrum.settings.zoom.y = [yVal.min[0], yVal.max[0]];
  //       spectrum.settings.axisLabels.x = "Test";
  //       spectrum.settings.state = trackState.ready;
  //       spectrum.settings.color = re.settings.color;
  //       newTracks.push(spectrum);
  //       newDatatracks.push(xVal);
  //       newDatatracks.push(yVal);
  //       const leaf: HierarchyLeaf = newHierarchyLeafFromTrack(spectrum);
  //       leafs.push(leaf);
  //     }
  //     let node: HierarchyNode | undefined = undefined;
  //     if (leafs.length > 0) {
  //       const n = newHierarchyNode({
  //         name: "LOGS generated",
  //         tracks: leafs.map((t) => t.tracks[0] ?? ""),
  //         content: leafs.slice(),
  //       });

  //       node = produce(viewerState.tracksHierarchy, (next) => {
  //         next.content.unshift(n);
  //       });
  //     }

  //     dispatch({
  //       type: "setTracks",
  //       tracks: newTracks,
  //       datatracks: newDatatracks,
  //       tracksHierarchy: node,
  //       ids: newTracks.map((t) => t.id),
  //     });

  //     // console.log("time", resultTrack.id, data);
  //     // const x = viewerState.datatracks[resultTrack.data.x ?? ""];
  //     // const y = viewerState.datatracks[resultTrack.data.y ?? ""];
  //     // console.log(
  //     //   "x",
  //     //   resultTrack.data.x,
  //     //   Object.values(viewerState.datatracks).map((t) => t.id)
  //     // );
  //     // if (!x || !y) return;
  //     // const spec = process1DNmrFID(data[0].data.x, data[1].data.y, data[1].data.y, settings);
  //   },
  //   [viewerState.datatracks, viewerState.tracks]
  // );

  // const nmrTrackSettings = useTranslatedFieldUpdate<viewerStateType, TrackNMRProcessingData[]>(
  //   viewerState,
  //   (viewerState: viewerStateType) => {
  //     return viewerState.internalParameters
  //       .filter((p) => p.type === "nmr")
  //       .map((p) => ({ ...p, windowFunction: "exp" as windowFunctionType, addPoints: 0 }));
  //   },
  //   []
  // );

  // useEffect(() => {
  //   const trackSettings: Record<string, TrackNMRProcessingData> = {};
  //   nmrTrackSettings.forEach((s) => s.tracks.forEach((id) => (trackSettings[id] = s)));

  //   const tracks = nmrTrackSettings
  //     .map((s) =>
  //       s.tracks
  //         .map((id) => viewerState.tracks[id])
  //         .sort((a, b) => {
  //           const aPrefix = idArrayToId(a.idArray.slice(0, a.idArray.length - 1));
  //           const bPrefix = idArrayToId(b.idArray.slice(0, b.idArray.length - 1));
  //           const aSuffix = a.idArray[a.idArray.length - 1];
  //           const bSuffix = b.idArray[b.idArray.length - 1];
  //           if (
  //             aPrefix === bPrefix &&
  //             ((aSuffix === "re" && bSuffix === "im") || (aSuffix === "im" && bSuffix === "re"))
  //           ) {
  //             return aSuffix === "re" ? -1 : 1;
  //           } else return a.id < b.id ? -1 : a.id > b.id ? 1 : 0;
  //         })
  //     )
  //     .flat();
  //   // const leafs: HierarchyLeaf[] = [];
  //   // const newTracks: Track[] = [];
  //   // const newDatatracks: Datatrack[] = [];
  //   const order = Array(tracks.length / 2)
  //     .fill(0)
  //     .map((_, i) => i * 2)
  //     .sort((a, b) => tracks[a].index - tracks[b].index);

  //   // for (let i = 0; i < tracks.length; i += 2) {
  //   for (let i of order) {
  //     const id = tracks[i].id;

  //     if (id in nmrTracks) {
  //       console.log("found", nmrTracks[id]);
  //     } else {
  //       const im = tracks[i + 1] as TrackXY;
  //       const re = tracks[i] as TrackXY;

  //       // setNmrTracks(
  //       //   produce(nmrTracks, (next) => {
  //       //     next[id] = { id: id, im: im, re: re ,  };
  //       //   })
  //       // );
  //       // const im = tracks[i + 1] as TrackXY;
  //     }
  //   }
  // }, [nmrTrackSettings]);

  // const addNmrTrackData = useCallback(
  //   (data: TrackData[]) => {
  //     const id = data[0].id;
  //     if (id in nmrTracks) {
  //       const nTracks: Record<string, NMRTrack> = Object.assign({}, nmrTracks);
  //       nTracks[id] = produce(nTracks[id], (next) => {
  //         next.reData = data[0].data;
  //         next.imData = data[1].data;
  //       });
  //       setNmrTracks(nTracks);
  //       console.log("spec", nmrTracks[id].spectrum.name);
  //       // dispatch({
  //       //   type: "setTracks",
  //       //   tracks: [
  //       //     produce(nmrTracks[id].spectrum, (next) => {
  //       //       next.settings.state = trackState.ready;
  //       //     }),
  //       //   ],
  //       // });
  //     }
  //   },
  //   [nmrTracks]
  // );

  // useEffect(() => {
  //   Object.values(nmrTracks)
  //     .sort((a, b) => a.order - b.order)
  //     .forEach((track) => {
  //       if (track.reData) {
  //         console.log("nmrTracks", track.re.name, track.im.name);
  //       } else {
  //         console.log(" -> get data", track.re.id, track.reData);
  //         getTrackDataLevel(api, 0, [track.re, track.im], viewerState.datatracks, addNmrTrackData, () => null);
  //       }
  //     });
  // }, [nmrTracks]);

  // useEffect(() => {
  //   const [settings, doUpdate] = updateCopyTranslated(nmrTrackSettings, viewerState, (viewerState: viewerStateType) => {
  //     return viewerState.internalParameters
  //       .filter((p) => p.type === "nmr")
  //       .map((p) => ({ ...p, windowFunction: "exp" as windowFunctionType, addPoints: 0 }));
  //   });

  //   if (doUpdate) {
  //     setNmrTrackSettings(settings);
  //     const trackSettings: Record<string, TrackNMRProcessingData> = {};
  //     settings.forEach((s) => s.tracks.forEach((id) => (trackSettings[id] = s)));

  //     const tracks = settings
  //       .map((s) =>
  //         s.tracks
  //           .map((id) => viewerState.tracks[id])
  //           .sort((a, b) => {
  //             const aPrefix = idArrayToId(a.idArray.slice(0, a.idArray.length - 1));
  //             const bPrefix = idArrayToId(b.idArray.slice(0, b.idArray.length - 1));
  //             const aSuffix = a.idArray[a.idArray.length - 1];
  //             const bSuffix = b.idArray[b.idArray.length - 1];
  //             // console.log(aPrefix, "--", bPrefix)
  //             if (
  //               aPrefix === bPrefix &&
  //               ((aSuffix === "re" && bSuffix === "im") || (aSuffix === "im" && bSuffix === "re"))
  //             ) {
  //               return aSuffix === "re" ? -1 : 1;
  //             } else return aPrefix < bPrefix ? -1 : aPrefix > bPrefix ? 1 : 0;
  //           })
  //       )
  //       .flat();

  //     const order = Array(Math.floor(tracks.length / 2))
  //       .fill(0)
  //       .map((_, i) => i * 2)
  //       .sort((a, b) => tracks[a].index - tracks[b].index);

  //     const leafs: HierarchyLeaf[] = [];
  //     const newTracks: Track[] = [];
  //     const newDatatracks: Datatrack[] = [];
  //     const nTracks: Record<string, NMRTrack> = Object.assign({}, nmrTracks);
  //     let count = 0;
  //     let node: HierarchyNode | undefined = undefined;
  //     for (let i of order) {
  //       const id = tracks[i].id;
  //       // console.log("ID", id);
  //       if (id in nTracks) {
  //         // console.log(" => found", id);
  //         nTracks[id] = produce(nTracks[id], (next) => {
  //           next.settings = trackSettings[id];
  //         });
  //       } else {
  //         // console.log(" => add", id);
  //         const im = tracks[i + 1] as TrackXY;
  //         const re = tracks[i] as TrackXY;

  //         const idArray = re.idArray.slice(0, re.idArray.length - 1);
  //         idArray.push("spectrum");
  //         const spectrumId = idArrayToId(idArray);
  //         let spectrum: TrackXY;
  //         let xVal: DatatrackNumericArray;
  //         let yVal: DatatrackNumericArray;
  //         // console.log("new track", id);
  //         xVal = initDatatrackNumericArray();
  //         yVal = initDatatrackNumericArray();

  //         // xVal.data = spec.x;
  //         // yVal.data = spec.y;
  //         // xVal.min = [spec.xRange[0]];
  //         // xVal.max = [spec.xRange[1]];
  //         // yVal.min = [spec.yRange[0]];
  //         // yVal.max = [spec.yRange[1]];

  //         spectrum = initTrackXY({
  //           name: "Spectrum " + ++count,
  //           id: spectrumId,
  //           idArray: idArray,
  //           type: "XY_real",
  //           data: { x: xVal.id, y: yVal.id },
  //           generation: 1,
  //         });
  //         spectrum.settings.zoom.x = [xVal.min[0], xVal.max[0]];
  //         spectrum.settings.zoom.y = [yVal.min[0], yVal.max[0]];
  //         spectrum.settings.axisLabels.x = "Test";
  //         spectrum.settings.state = trackState.loading;
  //         spectrum.settings.color = re.settings.color;

  //         nTracks[id] = { im: im, re: re, settings: trackSettings[id], order: i, spectrum: spectrum };

  //         newTracks.push(spectrum);
  //         newDatatracks.push(xVal);
  //         newDatatracks.push(yVal);
  //         const leaf: HierarchyLeaf = newHierarchyLeafFromTrack(spectrum);
  //         leafs.push(leaf);

  //         if (leafs.length > 0) {
  //           const n = newHierarchyNode({
  //             name: "LOGS generated",
  //             tracks: leafs.map((t) => t.tracks[0] ?? ""),
  //             content: leafs.slice(),
  //           });

  //           node = produce(viewerState.tracksHierarchy, (next) => {
  //             next.content.unshift(n);
  //           });
  //         }
  //       }
  //     }
  //     setNmrTracks(nTracks);

  //     if (newTracks.length > 0)
  //       dispatch({
  //         type: "setTracks",
  //         tracks: newTracks,
  //         datatracks: newDatatracks,
  //         tracksHierarchy: node,
  //         ids: newTracks.map((t) => t.id),
  //       });
  //   }
  // }, [viewerState.tracks, viewerState.datatracks, viewerState.tracksHierarchy, viewerState.internalParameters]);

  useEffect(() => {
    let activeViewer: viewerType | undefined = viewerState.activeViewer;
    if (
      viewerState.activeViewer === undefined ||
      Object.values(viewerState.tracks).every((t) => t.viewer !== activeViewer)
    ) {
      if (Object.keys(viewerState.tracks).length > 0) {
        const id = Object.values(viewerState.tracks)
          .filter((t) => t.viewer)
          .sort((a, b) => a.index - b.index)[0].id;
        activeViewer = viewerState.tracks[id].viewer;
        // ########### DELETE THIS: Just for testing ################
        // activeViewer = "sequence";
      }
    }
    dispatch({ type: "setActiveViewer", viewerType: activeViewer });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewerState.tracks]);

  // console.log("viewerTracks", select1DTracks(viewerState.viewerTracks));

  const changeTrackSettings = useCallback(
    (list: Partial<trackSettingsList>) =>
      dispatch({
        type: "setMultipleTrackSettings",
        trackSettingsList: list,
      }),
    []
  );

  // const trackMode = useTranslatedFieldUpdate(
  //   viewerState.activeViewer,
  //   (activeViewer: viewerType | undefined) => {
  //     const viewer = viewerMapping[activeViewer ?? "unknown"];
  //     switch (viewer) {
  //       case "SpectrumComponent":
  //         return trackModes.multiVisible;
  //       case "ImageComponent":
  //         return trackModes.multiVisible;
  //       case "PDFComponent":
  //         return trackModes.singleVisible;
  //       case "TableComponent":
  //         return trackModes.singleVisible;
  //     }
  //     return trackModes.multiVisible;
  //   },
  //   trackModes.multiVisible
  // );

  // useEffect(() => {
  //   setViewerMode(
  //     produce(viewerState.viewerMode, (next) => {
  //       next.trackMode = trackMode;
  //     })
  //   );
  // }, [trackMode]);

  useEffect(() => {
    const viewer = viewerMapping[viewerState.activeViewer ?? "unknown"];
    let trackMode = trackModes.multiVisible;
    switch (viewer) {
      case "SpectrumComponent":
        trackMode = trackModes.multiVisible;
        break;
      case "ImageComponent":
        trackMode = trackModes.multiVisible;
        break;
      case "MoleculeComponent":
        trackMode = trackModes.singleVisible;
        break;
      case "PDFComponent":
        trackMode = trackModes.singleVisible;
        break;
      case "TableComponent":
        trackMode = trackModes.singleVisible;
        break;
    }

    dispatch({
      type: "setViewerMode",
      viewerMode: produce(viewerState.viewerMode, (next) => {
        next.trackMode = trackMode;
      }),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewerState.activeViewer]);

  const setTrackMode = useCallback(
    (trackMode: trackModes) => {
      // if (viewerState.viewerMode.trackMode !== trackMode)
      setViewerMode(
        produce(viewerState.viewerMode, (next) => {
          next.trackMode = trackMode;
        })
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [viewerState.viewerMode.trackMode]
  );

  const changeTrackState = useCallback(
    (states: { id: string; state: trackState }[]) =>
      dispatch({
        type: "setMultipleTrackStates",
        states: states,
      }),
    []
  );

  const setTracksToZoom = useCallback((ids: string[]) => dispatch({ type: "setTracksToZoom", ids: ids }), []);

  const setViewerMode = useCallback(
    (viewerMode: viewerModeType) =>
      dispatch({
        type: "setViewerMode",
        viewerMode: viewerMode,
      }),
    []
  );

  const setViewPort = useCallback(
    (viewPort: viewPortType) => dispatch({ type: "setViewPort", viewPort: viewPort }),
    []
  );

  const setSelectedTracks = useCallback((ids: string[]) => {
    // console.log("ids", ids);
    dispatch({ type: "setSelectedTracks", ids: ids });
  }, []);

  const setNodeStatus = useCallback(
    (states: pipelineNodeState[]) => {
      dispatch({
        type: "setPipelineStatus",
        pipelineState: states,
      });
    },
    [dispatch]
  );

  const reparseDone = useCallback(
    async (jobs: JobInfo[], count: number = 0) => {
      jobs = await checkRunningJobs(api, jobs);
      if (jobs.some((job) => job.state !== JobStates.finished)) setTimeout(reparseDone, 500, jobs);
      else {
        // fetchDatasets(api, ids, dispatch, viewerState.count).then((count) => {
        //   dispatch({ type: "incrementCount", count: count });
        // });
        setReparsing(false);
        // dispatch({ type: "setActiveViewer", viewerType: viewerState.activeViewer });
      }
    },
    [api]
  );

  // const onReparse = useCallback(async () => {
  //   setReparsing(true);
  //   const jobs = await reparseDatasets(api, ids);
  //   reparseDone(jobs);
  // }, [api, ids]);

  const onReparse = useCallback(async () => {
    setReparsing(true);
    const jobs = await reparseDatasets(api, ids);
    reparseDone(jobs);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api, ids]);

  const onViewerLog = useCallback(async () => {
    const outputs = await fetchParserLog(api, ids);
    setViewerLogs(outputs);
    setShowParserLogs(true);
  }, [api, ids]);

  const onParserOutput = useCallback(async () => {
    const outputs = await fetchParserOutput(api, ids);
    setParserOutput(outputs.join("\n------\n"));
    setShowParserOutput(true);
  }, [api, ids]);

  useEffect(() => {
    // console.log("update parameter");
    if (viewerState.updateParameter) dispatch({ type: "updateParameter" });
  }, [viewerState.updateParameter]);

  const createPipeline = useCallback(
    (program: any, phasingState: any, parameter: any[]) => {
      const pipeline = new Pipeline(program);
      pipeline.setOnRun(setNodeStatus);

      if (phasingState?.[0]) {
        const phasing = phasingState[0];
        const settingList: pipelineSetting[] = pipeline.getNodeByCommand(commandTypes.phaseNMRSpectrum).map((id) => {
          return {
            pipelineId: pipeline.id,
            nodeId: id,
            // settings: {},
            settings: {
              phase0: { value: phasing.phase0 },
              phase1: { value: phasing.phase1 },
              pivot: { value: phasing.shift },
            },
          };
        });
        setSettingList(settingList);
      }

      if (Array.isArray(parameter)) {
        const parameters = parameter?.filter((p) => p.id === pipeline.id)?.[0]?.pipeline;
        // console.log("pipelines", viewerState.pipelineParamter.filter((p) => p.id === pipeline.id)?.[0]?.pipeline);
        if (parameters) pipeline.setParametersToNodes(parameters);
      }
      return pipeline;
    },
    [setNodeStatus]
  );

  useEffect(() => {
    const program = nmr_processing;
    // console.log("CHECK", nmrTracks);

    // const program = testProgram;
    if (
      false &&
      // viewerState.pipelines &&
      // viewerState.pipelineParamter &&
      !viewerState.pipelines?.[program.id] &&
      viewerState.internalParameters.some((p) => p.type === "nmr")
    ) {
      console.log("Create NMR pipeline");
      const pipeline = createPipeline(program, viewerState.viewerSettings?.phasing, viewerState.pipelineParamter ?? []);

      dispatch({ type: "setPipelines", pipelines: [pipeline] });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewerState.pipelineParamter, viewerState.internalParameters, nmrTracks]);

  // useEffect(() => {
  //   console.log("+++ parse program +++");
  //   // const pipeline = new Pipeline(program);
  //   // console.log("nmr_processing", program);
  //   const pipelines: Pipeline[] = [];
  //   // pipelines.push(new Pipeline(nmr_processing));

  //   // const cut = new Set(["windowFunction", "zeroFill", "nmrAxis"]);
  //   // const pipeline: any = [];
  //   // nmr_processing.pipeline.forEach((command) => {
  //   //   pipeline.push(command);
  //   //   const l = pipeline.slice();
  //   //   l.push({
  //   //     command: "trackAdder",
  //   //     parameter: {
  //   //       track: { trackName: "fid.im" },
  //   //     },
  //   //   });
  //   //   // console.log("name", command.command);
  //   //   if (cut.has(command.command)) {
  //   //     const p = {
  //   //       name: command.command,
  //   //       settings: { autorun: false },
  //   //       pipeline: l,
  //   //     };
  //   //     pipelines.push(new Pipeline(p));
  //   //   }
  //   // });

  //   // pipelines.push(new Pipeline(testProgram));

  //   // const pipeline = new Pipeline(program);
  //   // console.log(">>", pipelines[0].serialize());

  //   // const tmp = new PipelineParser(pipeline);
  //   dispatch({ type: "setPipelines", pipelines: pipelines });
  //   pipelines.forEach((pipeline) => pipeline.setOnRun(setNodeStatus));
  //   // // const ids: string[] = [];
  //   // if (pipeline) {
  //   //   dispatch({ type: "setPipelines", pipelines: [pipeline] });
  //   //   // ids.push(pipeline.id);
  //   //   // dispatch({
  //   //   //   type: "setPipelines",
  //   //   //   pipelines: new Array(5).fill(0).map((v, i) => {
  //   //   //     const p = new Pipeline();
  //   //   //     p.name = "Pipeline " + i;
  //   //   //     ids.push(p.id);
  //   //   //     return p;
  //   //   //   }),
  //   //   // });
  //   //   // console.log(">>", pipeline.parseErrors, pipeline.parseWarnings)
  //   //   pipeline.setOnRun(setNodeStatus);
  //   //   // pipeline.setNodeState(setNodeStatus);
  //   // }

  //   // setInterpreter(interpreter);
  //   pipelines.forEach((pipeline) => {
  //     if (pipeline.warnings.length > 0) console.log("WARNINGS:\n" + pipeline.warnings.toString());
  //     if (pipeline.errors.length > 0) console.log("ERRORS:\n" + pipeline.errors.toString());
  //   });
  // }, []);

  const runPipeline = useCallback(
    async (id: string) => {
      const pipeline = viewerState.pipelines?.[id];
      // console.log("run pipeline", id, Object.keys(viewerState.pipelines));

      if (!pipeline) return;
      // console.log("run pipeline", pipeline);
      // const id = pipeline.getStart()?.id ?? "";
      // dispatch({
      //   type: "setPipelineStatus",
      //   pipelineState: [{ id: id ?? "", warnings: ["warning"], errors: ["error"], state: "ok" }],
      // });
      // console.log(
      //   "track ready",
      //   Object.values(viewerState.viewerTracks).map((track) => track.state)
      // );

      // console.log("+++ run pipeline +++");
      const t0 = performance.now();
      const processed = pipeline.run(viewerState.tracks, viewerState.datatracks, viewerState.internalParameters);
      const t1 = performance.now();
      // if (pipeline.runWarnings.length > 0) console.log("WARNINGS:\n" + pipeline.runWarnings.toString());
      // if (pipeline.runErrors.length > 0) console.log("ERRORS:\n" + pipeline.runErrors.toString());

      // if (pipeline.recalculated) console.log("+++ pipeline run finished +++");
      // else console.log("+++ pipeline not recalculated +++");

      if (pipeline.recalculated && pipeline.errors.length < 1) {
        console.log("Pipeline run took " + (t1 - t0) + " milliseconds.");
        if (pipeline.runWarnings.length > 0) console.log("WARNINGS:\n" + pipeline.runWarnings.toString());
        if (pipeline.runErrors.length > 0) console.log("ERRORS:\n" + pipeline.runErrors.toString());

        const viewerTrackToTrack = Object.fromEntries(
          Object.values(viewerState.tracks)
            .map((t) => Object.values(t.data).map((id) => [id, t.id]))
            .flat()
        );

        let track: Track | undefined;
        let viewerTracks: Track[] | undefined;
        processed.forEach((p) => {
          // let id: string | undefined;
          // let newId = generateUid();
          // if (p.replace) {
          //   console.log("processed", p.replace);
          //   if (p.replace.idOnly) {
          //   } else {
          //     id = p.replace.id;
          //   }
          // } else {
          //   id = p.after ? p.after.id : undefined;
          // }
          // const newId = p.replace ?? generateUid();
          // if (p.replace?.idOnly) p.replace = viewerTrackToTrack?.[p.replace.id];

          let id = p.after;
          if (p.replace && p.replace in viewerTrackToTrack) id = p.replace;
          else p.replace = undefined;

          // console.log(">>", !id && viewerTracks);
          if (!id && Object.keys(viewerState.viewerTracks).length > 0) id = Object.keys(viewerState.viewerTracks).pop();
          // console.log("p", p, "->", id);

          if (id && id in viewerTrackToTrack) {
            // const id = p.replace ?? p.after;

            const ref = viewerState.tracks[viewerTrackToTrack[id]];
            track = initTrackFromTrack(ref);
            if (track) {
              if (p.tracks?.[0]) track.name = p.tracks[0].name.replace(/\.re$|\.im$/, "");
              track.tags = ["LOGS generated"];

              // console.log("ref", ref, "->", track.groups);
              viewerTracks = (p.tracks.filter((t) => t) as Track[]).map((t) => {
                // t.id = generateUid();
                // console.log("viewer tracks", t.parameter.visible);
                t.settings.visible = true;
                t.generation++;
                return t;
              });
              // console.log("replace", p.replace);
              if (p.replace) {
                // console.log("replace", track.id, "=>", ref.id);
                if (p.after) {
                  const t = viewerState.tracks[viewerTrackToTrack[p.after]];
                  if (t) track.tags = t.tags;
                }
                track.id = ref.id;
                Object.values(ref.data).forEach((t, i) => {
                  if (viewerTracks && i < viewerTracks.length) {
                    // console.log("track", viewerState.viewerTracks[t].parameter.visible);
                    viewerTracks[i].settings.visible = viewerState.viewerTracks[t ?? ""].parameter.visible;
                    viewerTracks[i].id = t ?? "";
                    // console.log("visible", viewerTracks[i].);
                  }
                });
              }
            }
            // track.id = newId;
          }
        });

        dispatch({
          type: "updatePipelineStatus",
          id: pipeline.id,
        });

        dispatch({
          type: "updatePipeline",
          id: pipeline.id,
        });

        if (track) {
          // console.log("add track", track.id);
          // const list = Object.values(viewerState.tracks);
          // dispatch({ type: "addTrack", track: track, id: track.datasetId, viewerTracks: viewerTracks });
          dispatch({ type: "setTracks", tracks: [track] });
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [viewerState.tracks, viewerState.viewerTracks, viewerState.pipelines]
  );

  useEffect(() => {
    // Object.values(viewerState.pipelines).forEach((interpreter) => {
    const pipelines: string[] = [];
    for (let pipeline of Object.values(viewerState.viewerPipelines)) {
      // console.log("pipeline", pipeline.id, pipeline.name, pipeline.settings.autorun);
      // console.log("pipeline", pipeline.id, pipeline.name, pipeline.settings.autorun);
      if (!pipeline.settings.autorun) continue;
      // runPipeline(pipeline.id);
      run(pipeline.id, runPipeline);
      pipelines.push(pipeline.id);
    }
    // console.log("pipelines", pipelines);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewerState.viewerTracks, viewerState.viewerPipelines]);
  // }, [viewerState.pipelines]);

  // const changePipelineParameter = useCallback((settingList: pipelineSetting[]) => {
  //   setSettingList(settingList);
  //   // console.log("setSettingList", settingList);
  // }, []);

  // const changePipelineParameter = useCallback(
  //   (settingList: pipelineSetting[]) => {
  useEffect(() => {
    // console.log("change pipeline settings: ", settingList);
    const runPipelines = new Set<string>();
    const pipelines = new Set<string>();
    for (let { pipelineId, nodeId, settings } of settingList) {
      if (!viewerState.pipelines?.[pipelineId]) continue;

      if (nodeId) viewerState.pipelines[pipelineId].setNodeParameter(nodeId, settings);
      else {
        // console.log("change pipeline settings: ", settings);
        if (settings?.save) {
          // console.log("Save pipeline settings: ", pipelineId, settings);
          Object.values(viewerState.datasets).forEach((dataset) => {
            // console.log("Save pipeline settings: ", pipelineId, viewerState?.pipelines?.[pipelineId]);
            const pipeline = viewerState?.pipelines?.[pipelineId];
            if (pipeline) {
              const p = pipeline.serializeParameters();

              savePipelines(api, dataset, [
                { id: pipeline.id, name: pipeline.name, pipeline: p, version: "NaN", settings: {} },
              ]);
              // savePipelines(api, dataset, [viewerState.viewerPipelines[pipelineId]]);
            }
          });
        }
        if (settings?.reset) {
          Object.values(viewerState.datasets).forEach((dataset) => {
            // console.log("Save pipeline settings: ", pipelineId, viewerState?.pipelines?.[pipelineId]);
            const pipeline = viewerState?.pipelines?.[pipelineId];
            if (pipeline) {
              console.log("reset", viewerState.pipelineParamter);
              console.log("Recreate NMR pipeline");
              const program = nmr_processing;
              const pipeline = createPipeline(program, viewerState.viewerSettings?.phasing, []);

              // dispatch({ type: "setPipelines", pipelines: [pipeline, new Pipeline(program)] });
              dispatch({ type: "setPipelines", pipelines: [pipeline] });

              // dispatch({ type: "storePipelineParmeter", pipelineParamter: [] });
              // const p = pipeline.serializeParameters();

              // savePipelines(api, dataset, [
              //   { id: pipeline.id, name: pipeline.name, pipeline: p, version: "NaN", settings: {} },
              // ]);
              // savePipelines(api, dataset, [viewerState.viewerPipelines[pipelineId]]);
            }
          });
        }
        viewerState.pipelines[pipelineId].setSettings(settings as PipelineSettings);
        if (settings?.runPipeline) runPipelines.add(pipelineId);
        // console.log("change pipeline settings: ", settingList);
      }

      pipelines.add(pipelineId);

      // viewerState.pipelines[pipelineId].setNodeState(setNodeStatus);
      //  console.log("setting", pipelineId, nodeId, viewerState.pipelines);
    }

    // runPipelines.forEach((id) => runPipeline(id));
    runPipelines.forEach((id) => run(id, runPipeline));

    // console.log(
    //   "--- update:",
    //   Array.from(pipelines)
    //     .map((id) => viewerState.pipelines[id].name)
    //     .join(", ")
    // );
    dispatch({
      type: "updatePipeline",
      ids: Array.from(pipelines),
    });
    // console.log("<<< update done");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settingList]);
  //   [viewerState.pipelines, runPipeline]
  // );

  if (reparsing)
    return (
      <Skeleton type="viewer">
        <div className={styles.spinner}>
          <div>Reparsing...</div>
        </div>
      </Skeleton>
    );

  if (
    viewerState.viewerStatus.status !== "failed" &&
    viewerState.noTracks === undefined &&
    viewerState.noParameters === undefined
  )
    return (
      <>
        {viewerSettings.showParameter && !viewerSettings.showTrackList ? (
          <div className={styles.spinner}>
            <div>&nbsp;</div>
            <Skeleton type="rows" />
          </div>
        ) : (
          <Skeleton type="viewer">
            <div className={styles.spinner}>
              <div>&nbsp;</div>
            </div>
          </Skeleton>
        )}
      </>
    );
  const datasetId = Object.keys(viewerState.datasets)?.[0];
  if (viewerState.viewerStatus.status === "failed")
    return (
      <>
        <DebugButtons
          debugMode={session?.features.development_mode ?? false}
          onReparse={onReparse}
          onParserOutput={onParserOutput}
          onViewerLog={onViewerLog}
        />
        <SourceModal show={showParserOutput} source={parserOutput} setShow={setShowParserOutput} />
        <Skeleton type="viewer" loading={false}>
          <ViewerLog
            status={viewerState.viewerStatus.status}
            logs={Object.values(viewerState.datasets)
              .map((d) => d.parserLogs)
              .flat()}
            datasetId={datasetId ? +datasetId : undefined}
          />
        </Skeleton>
      </>
    );

  const viewer =
    viewerSettings.showViewer && Object.keys(viewerState.tracks).length > 0 ? (
      <ViewerSelector
        activeViewer={viewerState.activeViewer}
        datatracks={viewerState.datatracks}
        tracks={viewerState.tracks}
        annotations={viewerState.annotations}
        tracksToZoom={viewerState.tracksToZoom}
        viewerMode={viewerState.viewerMode}
        viewPort={viewerState.viewPort}
        pipelines={viewerState.viewerPipelines}
        pipelineStates={viewerState.pipelineStates}
        showNavigation={viewerSettings.showNavigation}
        parserLogs={viewerState.parserLogs}
        api={api}
        interactiveMode={viewerSettings.interactiveMode}
        changeTrackState={changeTrackState}
        changeTrackSettings={changeTrackSettings}
        setViewerMode={setViewerMode}
        setTracksToZoom={setTracksToZoom}
        setTrackMode={setTrackMode}
      />
    ) : null;

  const parameter =
    viewerSettings.showParameter && viewerState.parameter.content.length > 0 ? (
      <ParameterComponent
        // viewerTracks={viewerState.viewerTracks}
        tracks={viewerState.tracks}
        parameter={viewerState.parameter}
      />
    ) : null;

  const tracksTab =
    viewerSettings.showTrackList && Object.keys(viewerState.tracks).length > 0 ? (
      <TrackTypeTab
        viewerTypes={viewerState.viewerTypes}
        activeViewer={viewerState.activeViewer}
        setActiveViewer={(activeViewer: viewerType) => dispatch({ type: "setActiveViewer", viewerType: activeViewer })}
      />
    ) : null;

  const tracks =
    viewerSettings.showTrackList && Object.keys(viewerState.tracks).length > 0 ? (
      <DatasetStructureViewer
        changeTrackSettings={changeTrackSettings}
        trackMode={viewerState.viewerMode.trackMode}
        tracks={viewerState.tracks}
        tracksHierarchy={viewerState.tracksHierarchy}
        zoomViewerTracks={setTracksToZoom}
        setSelectedTracks={setSelectedTracks}
      />
    ) : null;

  const tracksAndTab = tracks ? (
    <>
      {tracksTab}
      {tracks}
    </>
  ) : null;

  // console.log(`viewer ${viewer ? 1 : 0} tracks ${tracks ? 1 : 0} parameter ${parameter ? 1 : 0}`);

  if (viewer && tracks && parameter)
    return (
      <>
        <SourceModal show={showParserOutput} source={parserOutput} setShow={setShowParserOutput} />
        <ParserLogsModal logs={viewerLogs} show={showParserLogs} setShow={setShowParserLogs} />
        <FitToParentDiv setViewPort={setViewPort}>
          <HorizontalResizeDiv rightWidth={300}>
            {viewer}
            <FittingDiv className={styles.autoResize}>
              <DebugButtons
                debugMode={session?.features.development_mode ?? false}
                onReparse={onReparse}
                onParserOutput={onParserOutput}
                onViewerLog={onViewerLog}
              />
              {tracksTab}
              <div style={{ flexGrow: 1 }}>
                <AutoResizeDiv>
                  <VerticalResizeDiv>
                    {tracks}
                    {parameter}
                  </VerticalResizeDiv>
                </AutoResizeDiv>
              </div>
            </FittingDiv>
          </HorizontalResizeDiv>
        </FitToParentDiv>
      </>
    );
  else if (
    (viewer && !tracks && !parameter) ||
    (!viewer && tracks && !parameter) ||
    (!viewer && !tracks && parameter)
  ) {
    const show = viewer ?? tracksAndTab ?? parameter;
    return (
      <FitToParentDiv setViewPort={setViewPort}>
        <FittingDiv>
          <SourceModal show={showParserOutput} source={parserOutput} setShow={setShowParserOutput} />
          <DebugButtons
            debugMode={session?.features.development_mode ?? false}
            onReparse={onReparse}
            onParserOutput={onParserOutput}
            onViewerLog={onViewerLog}
          />
          {show}
        </FittingDiv>
      </FitToParentDiv>
    );
  } else if (viewer && tracks && !parameter) {
    return (
      <>
        <SourceModal show={showParserOutput} source={parserOutput} setShow={setShowParserOutput} />
        <FitToParentDiv setViewPort={setViewPort}>
          <HorizontalResizeDiv rightWidth={300}>
            {viewer}
            <FittingDiv className={styles.autoResize}>
              <DebugButtons
                debugMode={session?.features.development_mode ?? false}
                onReparse={onReparse}
                onParserOutput={onParserOutput}
                onViewerLog={onViewerLog}
              />

              {tracksTab}
              <div style={{ flexGrow: 1 }}>
                <AutoResizeDiv>{tracks}</AutoResizeDiv>
              </div>
            </FittingDiv>
          </HorizontalResizeDiv>
        </FitToParentDiv>
      </>
    );
  } else if (viewer && !tracks && parameter) {
    return (
      <>
        <SourceModal show={showParserOutput} source={parserOutput} setShow={setShowParserOutput} />
        <FitToParentDiv setViewPort={setViewPort}>
          <HorizontalResizeDiv rightWidth={300}>
            {viewer}
            <FittingDiv className={styles.autoResize}>
              <DebugButtons
                debugMode={session?.features.development_mode ?? false}
                onReparse={onReparse}
                onParserOutput={onParserOutput}
                onViewerLog={onViewerLog}
              />
              <div style={{ flexGrow: 1 }}>
                <AutoResizeDiv>{parameter}</AutoResizeDiv>
              </div>
            </FittingDiv>
          </HorizontalResizeDiv>
        </FitToParentDiv>
      </>
    );
  }
  return (
    <>
      <DebugButtons
        debugMode={session?.features.development_mode ?? false}
        onReparse={onReparse}
        onParserOutput={onParserOutput}
        onViewerLog={onViewerLog}
      />
      {!viewerSettings.showParameter ? <NoViewerContent /> : <NoViewerContent />}
      {viewerState.parserLogs.length > 0 ? <ViewerLog status="success" logs={viewerState.parserLogs} /> : null}
    </>
  );
};

export const NoViewerContent = () => (
  <Skeleton type="viewer" loading={false}>
    <div
      className="flex col-nowrap align-center justify-center"
      style={{ color: "var(--gray-500)", width: "100%", height: "100%", overflow: "hidden" }}
    >
      <span style={{ fontSize: "clamp(1rem, -1.5rem + 8vw, 3rem)" }}>No displayable data</span>
    </div>
  </Skeleton>
);

export const Viewer = (props: ViewerLayoutProps) => (
  <ErrorBoundary FallbackComponent={ErrorFallback}>
    <ViewerLayout {...props} />
  </ErrorBoundary>
);

export const ViewerStandalone = (props: ViewerLayoutProps) => {
  const { id } = useParams<SingleEntityRoutingParams>();

  const ids = produce(props.ids, (next) => {
    if (id) next.push(+id);
  });

  return <Viewer {...props} ids={ids} />;
};
