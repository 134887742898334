import {
  ICreatedRecordParametersTranslator,
  ICreationRecordLabels,
  IGenericRequestParametersTranslator,
  IModificationRecordLabels,
  IModifiedRecordParametersTranslator,
  INamedEntityLabels,
  IPaginationParametersTranslator,
  IPermissionedEntityLabels,
  ISoftDeletableFilterParametersTranslator,
  ISoftDeletableLabels,
  PropertyTranslator,
} from "../../api/GenericTranslator";
import {
  ICreationRecord,
  ICreatedRecordParameters,
  IGenericRequestParameters,
  IModelWithIntId,
  IModificationRecord,
  IModifiedRecordParameters,
  INamedEntity,
  IPaginationParameters,
  IPermissionedEntity,
  IVersionedEntity,
  IVersionedEntityParameters,
  IEntityMinimalModel,
  ISoftDeletableFilterParameters,
  ISoftDeletable,
  EntityConstants,
  DefaultCreatedByRecordOrderTypeConsts,
  DefaultCreatedOnRecordOrderTypeConsts,
  DefaultModifiedByRecordOrderTypeConsts,
  DefaultModifiedOnRecordOrderTypeConsts,
  DefaultOrderTypeConsts,
  DefaultVersionedOrderTypeConsts,
} from "../../api/GenericTypes";
import { LabNotebookMinimalModel } from "./LabNotebook";

export const labNotebookExperimentsConstants: EntityConstants<LabNotebookExperiment, LabNotebookExperimentFilters> = {
  resource: "lab_notebook_experiments",
  frontendIndexRoute: "lab_notebook_experiments",
  entitySingular: "notebook experiment",
  entityPlural: "notebook experiments",
  icon: "file-text",
};

export const labNotebookExperimentFieldLabels: PropertyTranslator<LabNotebookExperiment> = {
  ...INamedEntityLabels,
  ...IPermissionedEntityLabels,
  ...ICreationRecordLabels,
  ...IModificationRecordLabels,
  ...ISoftDeletableLabels,
  id: "Notebook experiment ID",
  version: "Version",
  description: "Description",
  status: "Status",
  labNotebook: "Notebook",
  createdBy: "Created by",
  createdOn: "Created on",
  modifiedBy: "Modified by",
  modifiedOn: "Modified on",
};

export interface LabNotebookExperiment
  extends IModelWithIntId,
    INamedEntity,
    ICreationRecord,
    IModificationRecord,
    IPermissionedEntity,
    ISoftDeletable,
    IVersionedEntity {
  labNotebook: LabNotebookMinimalModel;
  name: string;
  status: LabNotebookExperimentStatus;
  description: string;
}
export interface LabNotebookExperimentSuggestions extends IEntityMinimalModel<LabNotebookExperiment["id"]> {}

export interface LabNotebookExperimentMinimalModel extends IEntityMinimalModel {
  status: LabNotebookExperimentStatus;
}

export type LabNotebookExperimentStatus = "RUNNING" | "PAUSED" | "COMPLETED" | "FAILED" | "ABORTED";
export const LabNotebookExperimentStatusList = ["RUNNING", "PAUSED", "COMPLETED", "FAILED", "ABORTED"];

export interface LabNotebookExperimentVersion extends LabNotebookExperiment, IVersionedEntity {}

export type LabNotebookExperimentWriteModel = Omit<
  LabNotebookExperiment,
  "id" | "createdBy" | "createdOn" | "modifiedBy" | "modifiedOn" | "isDeleted" | "version"
>;

export interface LabNotebookExperimentFilters
  extends IGenericRequestParameters<LabNotebookExperiment, LabNotebookExperimentSortingOptions>,
    IPaginationParameters,
    ICreatedRecordParameters,
    IModifiedRecordParameters,
    ISoftDeletableFilterParameters {
  labNotebookIds?: number[] | null;
  status?: LabNotebookExperimentStatus[] | null;
  labNotebookProjectIds?: number[] | null;
}

export interface LabNotebookExperimentVersionFilter extends LabNotebookExperimentFilters, IVersionedEntityParameters {}

export const LabNotebookExperimentSortingOptionsConsts = [
  ...DefaultOrderTypeConsts,
  ...DefaultCreatedOnRecordOrderTypeConsts,
  ...DefaultCreatedByRecordOrderTypeConsts,
  ...DefaultModifiedOnRecordOrderTypeConsts,
  ...DefaultModifiedByRecordOrderTypeConsts,
  ...DefaultVersionedOrderTypeConsts,
  "LAB_NOTEBOOK_ID_ASC",
  "LAB_NOTEBOOK_ID_DESC",
  "LAB_NOTEBOOK_NAME_ASC",
  "LAB_NOTEBOOK_NAME_DESC",
  "STATUS_ASC",
  "STATUS_DESC",
] as const;
export type LabNotebookExperimentSortingOptions = (typeof LabNotebookExperimentSortingOptionsConsts)[number];

export const LabNotebookExperimentFiltersTranslator: PropertyTranslator<LabNotebookExperimentFilters> = {
  ...IPaginationParametersTranslator,
  ...IGenericRequestParametersTranslator,
  ...ICreatedRecordParametersTranslator,
  ...IModifiedRecordParametersTranslator,
  ...ISoftDeletableFilterParametersTranslator,

  status: "Status",
  labNotebookIds: "Notebook IDs",
  labNotebookProjectIds: "Notebook Project IDs",
};
