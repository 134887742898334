import { CustomType, CustomTypeEntityTypeOptionsReduced, CustomTypeFilters } from "../../../api/CustomTypes";
import { IEntityMinimalModel, DefinedPropertyObject } from "../../../api/GenericTypes";
import { SplitDatePickerFormField } from "../../../common/formfields/DatePickerFormField/SplitDatePickerFormField";
import { GenericVirtualizedSelectFormField } from "../../../common/formfields/GenericVirtualizedSelectFormField/GenericVirtualizedSelectFormField";
import { ToggleFormField } from "../../../common/formfields/ToggleFormField";
import { PersonsVirtualizedSelectForm } from "../../../common/forms/EntityForms/formsVirtualized/PersonsVirtualizedSelectForm";
import { EntityFilterSideBar } from "../../../common/sidebar/filtersidebar/EntityFilterBar";
import { FilterSidebarProps, useFilterSidebar } from "../../../common/sidebar/filtersidebar/FilterSidebar";
import { CustomTypeEntityTypeOptionToIcon } from "../CustomTypeRenderUtils";

export interface CustomTypeFilterForm {
  entityType?: CustomType["entityType"][] | undefined | null;
  createdByIds?: IEntityMinimalModel[] | undefined | null;
  createdFrom?: Date | undefined | null;
  createdTo?: Date | undefined | null;
  modifiedByIds?: IEntityMinimalModel[] | undefined | null;
  modifiedFrom?: Date | undefined | null;
  modifiedTo?: Date | undefined | null;
  excludeDisabled?: boolean | null;
}
const defaultValues: DefinedPropertyObject<CustomTypeFilterForm> = {
  entityType: null,
  createdByIds: null,
  createdFrom: null,
  createdTo: null,
  modifiedByIds: null,
  modifiedFrom: null,
  modifiedTo: null,
  excludeDisabled: true,
};
const formToFilters = (form: CustomTypeFilterForm): CustomTypeFilters => {
  let filters: CustomTypeFilters = {};
  filters.entityTypes = Array.isArray(form.entityType) && form.entityType.length > 0 ? form.entityType : null;
  filters.createdByIds =
    Array.isArray(form.createdByIds) && form.createdByIds.length > 0 ? form.createdByIds?.map((d) => d.id) : null;
  filters.createdFrom = form.createdFrom ? form.createdFrom.toISOString() : null;
  filters.createdTo = form.createdTo ? form.createdTo.toISOString() : null;
  filters.modifiedByIds =
    Array.isArray(form.modifiedByIds) && form.modifiedByIds.length > 0 ? form.modifiedByIds?.map((d) => d.id) : null;
  filters.modifiedFrom = form.modifiedFrom ? form.modifiedFrom.toISOString() : null;
  filters.modifiedTo = form.modifiedTo ? form.modifiedTo.toISOString() : null;
  filters.excludeDisabled = form.excludeDisabled;
  return filters;
};
const defaultFilters: CustomTypeFilters = { orderBy: "NAME_ASC" };

export const CustomTypesFilterBar = ({
  initialValues,
  setFilters,
  dispatchTabStore,
  onFormChange,
  tabsLoading,
  currentTab,
  hideEntityTypeFilter = false,
}: FilterSidebarProps<CustomType, CustomTypeFilterForm, CustomTypeFilters> & { hideEntityTypeFilter?: boolean }) => {
  const { control, onReset } = useFilterSidebar({
    initialValues,
    defaultValues,
    defaultFilters,
    formToFilters,
    onFormChange,
    dispatchTabStore,
    setFilters,
  });

  return (
    <EntityFilterSideBar onReset={onReset}>
      {!currentTab?.includes("entity-type") && !hideEntityTypeFilter && (
        <GenericVirtualizedSelectFormField
          id="entityType"
          label="Entity type"
          control={control}
          items={CustomTypeEntityTypeOptionsReduced.map((v) => v)}
          placeholder="Select entity type..."
          onRowStartRenderer={(row) => <CustomTypeEntityTypeOptionToIcon entityType={row} color={"var(--primary)"} />}
          isMulti
        />
      )}
      {currentTab !== "mydata" && (
        <PersonsVirtualizedSelectForm id="createdByIds" label="Created by" control={control} isMulti />
      )}
      <SplitDatePickerFormField
        control={control}
        idStart="createdFrom"
        idEnd="createdTo"
        label="Creation date (from - to)"
        horizontal={false}
        showTimeSelect
      />
      <PersonsVirtualizedSelectForm id="modifiedByIds" label="Modified by" control={control} isMulti />

      <SplitDatePickerFormField
        control={control}
        idStart="modifiedFrom"
        idEnd="modifiedTo"
        label="Modification date (from - to)"
        horizontal={false}
        showTimeSelect
      />
      <ToggleFormField id="excludeDisabled" label="Exclude disabled" control={control} horizontal />
    </EntityFilterSideBar>
  );
};
