import React, { useContext, useMemo, useState } from "react";

import { LoadingWrapper } from "../../../common/LoadingWrapper";
import { LucideIcon } from "../../../common/icon/LucideIcon";
import { Tile } from "../../../common/panels/Tile/Tile";
import { Content, ContentRow, ContentTable } from "../../../common/tables/ContentTable/ContentTable";
import { Bridge, BridgeFilters, bridgeConstants } from "../../../api/Bridges";
import { useEntityDetail } from "../../../api/BaseEntityApi";
import { Link } from "react-router-dom";
import { SessionContext } from "../../../common/contexts/SessionContext";
import { getDetailLink } from "../../../main/Routing";
import { BridgeConnectionStatus } from "../../Bridges/common/BridgeConnectionStatus";
import { NotAvailable, NotSet } from "../../../common/misc/UIconstants";

interface BridgeTileProps {
  bridgeId: number;
  showLink?: boolean;
}
export const BridgeTile = ({ bridgeId, showLink = false }: BridgeTileProps) => {
  const { route } = useContext(SessionContext);
  const [bridgeMapping, setBridgeMapping] = useState<Content>([]);

  const {
    data: bridge,
    status: bridgeStatus,
    fetchStatus,
    error: bridgeError,
  } = useEntityDetail<Bridge, BridgeFilters>(bridgeConstants.resource, bridgeId!, {}, { enabled: !!bridgeId! });

  useMemo(() => {
    let mapping: Content = [];
    if (bridge) {
      mapping.push(
        {
          key: "Bridge name",
          value: (
            <div className={"container_label"}>
              <div className={"container_label_name"} style={{ fontWeight: 600 }}>
                {bridge.name ?? NotSet}
              </div>
              <div className={"container_label_id"}>
                <span style={{ whiteSpace: "nowrap" }}>
                  {showLink ? (
                    <Link
                      to={route(getDetailLink(bridgeConstants.frontendIndexRoute, bridge.id))}
                      className={"flex row-nowrap align-center gap-5"}
                    >
                      Bridge-ID: {bridge.id}{" "}
                      <LucideIcon name="external-link" style={{ width: "10.5px", height: "10.5px" }} />
                    </Link>
                  ) : (
                    <>Bridge-ID: {bridge.id}</>
                  )}
                </span>
              </div>
            </div>
          ),
        },
        {
          key: "Type",
          value: (
            <div style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
              <label className="label label-soft-info" style={{ margin: 0 }}>
                <LucideIcon name="wifi" /> {bridge.type}
              </label>
            </div>
          ),
        },
        {
          key: "Host name",
          value: bridge.hostname,
        },
        {
          key: "IP address",
          value: <span style={{ fontFamily: "monospace" }}>{bridge.ipAddress}</span>,
        },
        ...(bridge.type === "SFTP"
          ? [
              {
                key: "Username",
                value: bridge.username ?? NotAvailable,
              },
            ]
          : [{} as ContentRow]),

        ...(bridge.type === "Client"
          ? [
              {
                key: "Operating system",
                value: bridge.connectedClients[0]?.operatingSystem ?? NotSet,
              },
              {
                key: "Executable path",
                value: bridge.connectedClients[0]?.executablePath ?? NotSet,
              },
              {
                key: "Client version",
                value: bridge.connectedClients[0]?.clientVersion ?? NotSet,
              },
            ]
          : [{} as ContentRow]),
        {
          key: "Status",
          value: <BridgeConnectionStatus bridge={bridge} />,
        },
        { key: "Description", value: bridge?.description ? "" : NotSet }
      );
      setBridgeMapping(() => mapping);
    }
  }, [bridge, route, showLink]);

  return (
    <Tile>
      <Tile.Head title="Bridge" />
      <Tile.Body>
        <LoadingWrapper status={bridgeStatus} fetchStatus={fetchStatus} error={bridgeError}>
          <ContentTable content={bridgeMapping} />
          {bridge?.description && (
            <div className={"container_notes"}>
              <textarea rows={4} className={"container_textarea"} value={bridge?.description} disabled={true} />
            </div>
          )}
        </LoadingWrapper>
      </Tile.Body>
    </Tile>
  );
};
