import { useContext, useMemo, useState } from "react";
import { API } from "../../api/Api";
import styles from "./ComponentOverview.module.css";
import { Display } from "./Display";
// import { Button } from "../buttons/Button/Button";
// import { ActionButton } from "../ActionButton";
import Status from "../badges/Status/Status";
import { ToggleSwitch } from "../buttons/ToggleSwitch/ToggleSwitch";
import { LucideIcon } from "../icon/LucideIcon";
import { Skeleton } from "../loaders/Skeleton/Skeleton";
import BrandLoader from "../loaders/Spinner/BrandLoader";
import { Spinner } from "../loaders/Spinner/Spinner";
// import { MainContentContainer } from "../MainContentContainer";
import { Alert } from "../overlays/Alert/Alert";
import { customToast, showtoast } from "../overlays/Toasts/showtoast";
import { PaginationView } from "../PaginationView";
import { Container } from "../panels/Container/Container";
import Detail from "../panels/Detail/Detail";
import { Table } from "../panels/Detail/DetailTable";
import FullDetail from "../panels/FullDetail/FullDetail";
import { FullDetailNavigation } from "../panels/FullDetail/Navigation/FullDetailNavigation";
import SinglePage from "../panels/SinglePage/SinglePage";
import TableView from "../panels/TableView/TableView";
import { Tile } from "../panels/Tile/Tile";
import { PreCode } from "../PreCode";
// import { SidebarContainer } from "../SidebarContainer";
import { StepwiseWizard } from "../StepwiseWizard/StepwiseWizard";
import ContentRow from "../tables/ContentRow/ContentRow";
import { ContentTable } from "../tables/ContentTable/ContentTable";
import Codeblock from "../textfields/Codeblock/Codeblock";
import { Hexviewer } from "../textfields/Hexviewer/Hexviewer";
// import { TopContentContainer } from "../TopContentContainer";
import { CircularProgress } from "../loaders/CircularProgress/CircularProgress";
import { AlertModal } from "../modals/AlertModal/AlertModal";
import { GenericModal, GenericModalWrapper } from "../modals/Modal/GenericModal";
import { CircularCountdown } from "../loaders/CircularCountdown/CircularCountdown";
import { Count, StatCard } from "../loaders/Count/Count";
import { ExclusiveDropdown } from "../buttons/ExclusiveDropdown/ExclusiveDropdown";
import { MoreDropdown } from "../buttons/MoreDropdown/MoreDropdown";
import { UID } from "../badges/UID/UID";
import { NotAllowed, NotAvailable, NotSet, TrashedNotEditable } from "../misc/UIconstants";
import { CurrentIndicator } from "../badges/CurrentIndicator/CurrentIndicator";
import { FloatingDiv } from "../buttons/FloatingDiv/FloatingDiv";
import { Modal } from "../../ELN/common/ELNModal/ELNModal";
import { IEntityMinimalModel } from "../../api/GenericTypes";
import { useForm } from "react-hook-form";
import { Dataset, DatasetFilters, datasetsConstants, DatasetSortingOptionsConsts } from "../../api/Datasets";
import { GenericVirtualizedEntitySelectFormFieldControllerWrapper } from "../formfields/GenericVirtualizedSelectFormField/GenericVirtualizedEntitySelectFormFieldControllerWrapper";
import { Button } from "../buttons/Button/Button";
import { LinkButton } from "../buttons/LinkButton/LinkButton";
import { SessionContext } from "../contexts/SessionContext";
import { getIndexRoute } from "../../main/Routing";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import { ToggleIndicator } from "../badges/ToggleIndicator/ToggleIndicator";

/**
 * This component is designated as an overview page for custom components
 * Author: CS
 * @param: api
 * @returns: JSX.Element
 */

interface Props {
  api: API;
}
export const ComponentOverview = ({ api }: Props) => {
  const { route } = useContext(SessionContext);

  const [checked, setChecked] = useState(false);
  const [showExclusiveDropDown, setShowExclusiveDropDown] = useState(false);
  // const [searchValue, setSearchValue] = useState("");

  interface TestForm {
    property: IEntityMinimalModel | null | undefined;
  }
  const { control } = useForm<TestForm>({
    values: {
      property: undefined,
    },
  });

  const oneMinuteAgo = useMemo(() => {
    const now = new Date();
    now.setMinutes(now.getMinutes() - 1);
    return now;
  }, []);

  const inOneMinute = useMemo(() => {
    const now = new Date();
    now.setMinutes(now.getMinutes() + 1);
    return now;
  }, []);

  return (
    <div className={styles.main}>
      {/* <Tile isFolded>
        <Tile.Head title="Atomic designs" />
        <Tile.Body>
          <iframe title="Jo" src="https://logs.ioio.design/#" width="100%" height="1000px" />
        </Tile.Body>
      </Tile> */}
      <Tile isFolded>
        <Tile.Head title="Forms" />
        <Tile.Body>
          <div className={styles.flex}>
            <Display
              code={`<GenericVirtualizedSelectFormFieldControllerWrapper<TestForm, Dataset, DatasetFilters>
              id="property"
              control={control}
              entityConstants={datasetsConstants}
              orderByOptions={DatasetSortingOptionsConsts}
              controls={({ currentFilters, onItemClick, setCurrentFilters }) => <>Controls</>}
              createModal={({ onCreate, setShowModal, showModal }) => <> </>}
              defaultValue={undefined}
              disabled={false}
              filters={{}}
              label="Overwrite label"
              onRowRenderer={(item) => (
                <>
                  {item.isDeleted && (
                    <label className="label label-soft-danger">
                      <FeatherIcon name="trash" />
                    </label>
                  )}
                </>
              )}
              placeholder="Placeholder"
              hasLabel
              isMulti
              allowUnassigned
              horizontal
              required
              allowCreateEntity
              showControls
              unassignOnX = {false}
              uncontained
              selectFieldStyle={{}}
              style={{}}
            />`}
            >
              <GenericVirtualizedEntitySelectFormFieldControllerWrapper<TestForm, Dataset, DatasetFilters>
                id="property"
                control={control}
                entityConstants={datasetsConstants}
                orderByOptions={DatasetSortingOptionsConsts}
                controls={({ currentFilters, onItemClick, setCurrentFilters }) => <>Controls</>}
                createModal={({ onCreate, setShowModal, showModal }) => <> </>}
                defaultValue={undefined}
                disabled={false}
                filters={{}}
                label="Overwrite label"
                onRowRenderer={(item) => (
                  <>
                    {item.isDeleted && (
                      <label className="label label-soft-danger">
                        <LucideIcon name="trash" />
                      </label>
                    )}
                  </>
                )}
                placeholder="Placeholder"
                hasLabel
                isMulti
                allowUnassigned
                horizontal
                required
                allowCreateEntity
                showControls
                unassignOnX={false}
                uncontained
                selectFieldStyle={{}}
                style={{}}
              />
            </Display>
          </div>
        </Tile.Body>
      </Tile>
      <Tile isFolded>
        <Tile.Head title="JSX Constants" />
        <Tile.Body>
          <Display code={`NotSet`}>{NotSet}</Display>
          <Display code={`NotAvailable`}>{NotAvailable}</Display>
          <Display code={`NotAllowed`}>{NotAllowed}</Display>
          <Display code={`TrashedNotEditable`}>{TrashedNotEditable}</Display>
        </Tile.Body>
      </Tile>
      <Tile isFolded>
        <Tile.Head title="Buttons" />
        <Tile.Body>
          <div className={styles.flex}>
            <Display
              code={`<Button className="btn btn-primary" title="Title">
                <FeatherIcon name="activity" /> Test
              </Button>`}
            >
              <Button className="btn btn-primary" title="Title">
                <LucideIcon name="activity" /> Test
              </Button>
            </Display>
            <Display
              code={`<Button className="btn btn-primary" title="Title" loading>
                <FeatherIcon name="activity" /> Test
              </Button>`}
            >
              <Button className="btn btn-primary" title="Title" loading>
                <LucideIcon name="activity" /> Test
              </Button>
            </Display>
            <Display
              code={`<LinkButton linkTo={route(getIndexRoute("datasets"))} className="btn btn-primary" title="Title">
                <FeatherIcon name="activity" /> Go to datasets
              </LinkButton>`}
            >
              <LinkButton linkTo={route(getIndexRoute("datasets"))} className="btn btn-primary" title="Title">
                <LucideIcon name="activity" /> Go to datasets
              </LinkButton>
            </Display>
            <Display
              code={`<LinkButton linkTo={route(getIndexRoute("datasets"))} className="btn btn-primary" title="Title">
                <FeatherIcon name="activity" /> Go to datasets
              </LinkButton>`}
            >
              <LinkButton linkTo={route(getIndexRoute("datasets"))} className="btn btn-primary" title="Title" loading>
                <LucideIcon name="activity" /> Go to datasets
              </LinkButton>
            </Display>
            <Display
              code={`<FloatingDiv
              triggerComponent={({ ref, getReferenceProps, setOpen }) => (
                <button className="btn btn-primary" ref={ref} {...getReferenceProps()}>
                  Click to open floating div
                </button>
              )}
            >
              {({ setOpen }) => (
                <div className="flex" style={{ height: "fit-content", background: "var(--white)" }}>
                  Div
                </div>
              )}
            </FloatingDiv>`}
            >
              <FloatingDiv
                triggerComponent={({ ref, getReferenceProps, setOpen }) => (
                  <button className="btn btn-primary" ref={ref} {...getReferenceProps()}>
                    Click to open floating div
                  </button>
                )}
              >
                {({ setOpen }) => (
                  <div className="flex" style={{ height: "fit-content", background: "var(--white)" }}>
                    Div
                  </div>
                )}
              </FloatingDiv>
            </Display>
            <Display code={`<ToggleSwitch checked={checked} onToggle={() => setChecked(!checked)}/>`}>
              <ToggleSwitch checked={checked} onToggle={() => setChecked(!checked)} />
            </Display>
            <Display
              code={`<MoreDropdown btn="btn btn-primary" >
              <li>
                <button className="btn btn-ghost-primary">Item 1</button>
              </li>
              <li>
                <button className="btn btn-ghost-success">Item 2</button>
              </li>
            </MoreDropdown>`}
            >
              <MoreDropdown btn="btn btn-primary">
                <li>
                  <button className="btn btn-ghost-primary">Item 1</button>
                </li>
                <li>
                  <button className="btn btn-ghost-success">Item 2</button>
                </li>
              </MoreDropdown>
            </Display>
            <Display
              code={`<ExclusiveDropdown
              show={showExclusiveDropDown}
              setShow={setShowExclusiveDropDown}
              drop="down-left"
              disabled={false}
              title="Edit"
              btnCls="btn btn-sm btn-default"
              btnLabel="Exclusive dropdown"
              icon={<FeatherIcon name="edit" />}
            >
              <div style={{ width: 300, height: 400 }}>I'm a child</div>
            </ExclusiveDropdown>`}
            >
              <ExclusiveDropdown
                show={showExclusiveDropDown}
                setShow={setShowExclusiveDropDown}
                drop="down-left"
                disabled={false}
                title="Edit"
                btnCls="btn btn-sm btn-default"
                btnLabel="Exclusive dropdown"
                icon={<LucideIcon name="square-pen" />}
              >
                <div style={{ width: 300, height: 400 }}>I'm a child</div>
              </ExclusiveDropdown>
            </Display>
          </div>
        </Tile.Body>
      </Tile>

      <Tile isFolded>
        <Tile.Head title="Panels" />
        <Tile.Body>
          <div className={styles.flex}>
            <Display
              code={`<Tile>
            <Tile.Head title="Tile">
              <Tile.Head.Controls>
                <Tile.Head.Controls.Fixed>
                <button className={"btn btn-sm btn-default"}>
                Fixed ctrl
              </button>
                </Tile.Head.Controls.Fixed>
                <Tile.Head.Controls.Unfolded>
                <button className={"btn btn-sm btn-default"}>
                Unfolded ctrl
              </button>
                </Tile.Head.Controls.Unfolded>
                <Tile.Head.Controls.Folded>
                <button className={"btn btn-sm btn-default"}>
                Folded ctrl
              </button>
                </Tile.Head.Controls.Folded>
              </Tile.Head.Controls>
            </Tile.Head>
            <Tile.Body>
            Children
            </Tile.Body>
          </Tile>`}
            >
              <div style={{ width: 600, height: "min-content" }}>
                <Tile>
                  <Tile.Head title="Tile">
                    <Tile.Head.Controls>
                      <Tile.Head.Controls.Fixed>
                        <button className={"btn btn-sm btn-default"}>Fixed ctrl</button>
                      </Tile.Head.Controls.Fixed>
                      <Tile.Head.Controls.Unfolded>
                        <button className={"btn btn-sm btn-default"}>Unfolded ctrl</button>
                      </Tile.Head.Controls.Unfolded>
                      <Tile.Head.Controls.Folded>
                        <button className={"btn btn-sm btn-default"}>Folded ctrl</button>
                      </Tile.Head.Controls.Folded>
                    </Tile.Head.Controls>
                  </Tile.Head>
                  <Tile.Body>Children</Tile.Body>
                </Tile>
              </div>
            </Display>
            <Display
              code={`<Container
            title={"Container"}
            controls={<button className={"btn btn-sm btn-default"}>{edit_icon} Edit Dataset</button>}
          >
            Im a children
          </Container>`}
            >
              <div style={{ width: 600, height: 300 }}>
                <Container
                  title={"Container"}
                  controls={
                    <button className={"btn btn-sm btn-default"}>
                      <LucideIcon name="square-pen" /> Edit Dataset
                    </button>
                  }
                >
                  Im a children
                </Container>
              </div>
            </Display>
            {/* <Display code={`<SidebarContainer> {filterBarContent} </SidebarContainer>`}>
              <SidebarContainer>
                <div>The left side bar to include filter-inputs </div>{" "}
              </SidebarContainer>
            </Display> */}
            {/* <Display
              code={`<MainContentContainer> <div> The right side bar to include main content </div>  </MainContentContainer>`}
            >
              <MainContentContainer>
                {" "}
                <div>
                  {" "}
                  <h5>
                    {" "}
                    <br></br>The right side bar to include main content{" "}
                  </h5>{" "}
                </div>{" "}
              </MainContentContainer>
            </Display> */}
            {/* <Display
              code={`<TopContentContainer title={'Page Title'}><p> Children as the header content </p></TopContentContainer>`}
            >
              <TopContentContainer title={"Page Title"}>
                <ActionButton buttonTitle={"back"} />
              </TopContentContainer>
            </Display> */}

            <Display
              code={`<Detail
            sidebarPosition="right"
            main={"Main Content"}
            sidebar={
              <>
              <Tile><Tile.Head title="Sidebar content"/><Tile.Body>I'm a child</Tile.Body></Tile>
              <Tile><Tile.Head title="Sidebar content"/><Tile.Body>I'm a child</Tile.Body></Tile>
              <Tile><Tile.Head title="Sidebar content"/><Tile.Body>I'm a child</Tile.Body></Tile>
              <Tile><Tile.Head title="Sidebar content"/><Tile.Body>I'm a child</Tile.Body></Tile>
              <Tile><Tile.Head title="Sidebar content"/><Tile.Body>I'm a child</Tile.Body></Tile>
              <Tile><Tile.Head title="Sidebar content"/><Tile.Body>I'm a child</Tile.Body></Tile>
              </>
            }
          />`}
            >
              <div style={{ width: 600, height: 600 }}>
                <Detail
                  sidebarPosition="right"
                  main={"Main Content"}
                  sidebar={
                    <>
                      <Tile>
                        <Tile.Head title="Sidebar content" />
                        <Tile.Body>I'm a child</Tile.Body>
                      </Tile>
                      <Tile>
                        <Tile.Head title="Sidebar content" />
                        <Tile.Body>I'm a child</Tile.Body>
                      </Tile>
                      <Tile>
                        <Tile.Head title="Sidebar content" />
                        <Tile.Body>I'm a child</Tile.Body>
                      </Tile>
                      <Tile>
                        <Tile.Head title="Sidebar content" />
                        <Tile.Body>I'm a child</Tile.Body>
                      </Tile>
                      <Tile>
                        <Tile.Head title="Sidebar content" />
                        <Tile.Body>I'm a child</Tile.Body>
                      </Tile>
                      <Tile>
                        <Tile.Head title="Sidebar content" />
                        <Tile.Body>I'm a child</Tile.Body>
                      </Tile>
                    </>
                  }
                />
              </div>
            </Display>

            <Display
              code={`<FullDetail>
              <FullDetail.Navigation>
                <FullDetail.Navigation.ControlsLeft>
                  <button className={"btn btn-sm btn-soft-primary"}>
                    <LucideIcon name="arrow-left" />
                  </button>
                  <button className={"btn btn-sm btn-soft-primary"}>
                    <LucideIcon name="arrow-right" />
                  </button>
                </FullDetail.Navigation.ControlsLeft>
                <FullDetail.Navigation.Selector>
                  <FullDetailNavigation
                    icon={<LucideIcon name="aperture" />}
                    entityName={"Entity"}
                    entity={{ id: 1, name: "MinimalModel entity" }}
                  >
                    children
                  </FullDetailNavigation>
                </FullDetail.Navigation.Selector>
                <FullDetail.Navigation.ControlsRight>
                  <button className={"btn btn-sm btn-soft-primary"}>
                    <LucideIcon name="square-pen" /> Edit
                  </button>
                </FullDetail.Navigation.ControlsRight>
              </FullDetail.Navigation>
              <FullDetail.Body>
                <FullDetail.Body.Sidebar>Sidebar</FullDetail.Body.Sidebar>
                <FullDetail.Body.Content>
                  <PanelGroup direction="vertical">
                    <Panel>
                      <div className="flex" style={{ width: "100%", height: "100%", border: "1px solid gray" }}>
                        top
                      </div>
                    </Panel>
                    <PanelResizeHandle />
                    <Panel>
                      <PanelGroup direction="horizontal">
                        <Panel>
                          <div className="flex" style={{ width: "100%", height: "100%", border: "1px solid gray" }}>
                            left
                          </div>
                        </Panel>
                        <PanelResizeHandle />
                        <Panel>
                          <div className="flex" style={{ width: "100%", height: "100%", border: "1px solid gray" }}>
                            right
                          </div>
                        </Panel>
                      </PanelGroup>
                    </Panel>
                  </PanelGroup>
                </FullDetail.Body.Content>
              </FullDetail.Body>
            </FullDetail>`}
            >
              <div style={{ width: 600, height: 600 }}>
                <FullDetail>
                  <FullDetail.Navigation>
                    <FullDetail.Navigation.ControlsLeft>
                      <button className={"btn btn-sm btn-soft-primary"}>
                        <LucideIcon name="arrow-left" />
                      </button>
                      <button className={"btn btn-sm btn-soft-primary"}>
                        <LucideIcon name="arrow-right" />
                      </button>
                    </FullDetail.Navigation.ControlsLeft>
                    <FullDetail.Navigation.Selector>
                      <FullDetailNavigation
                        icon={<LucideIcon name="aperture" />}
                        entityName={"Entity"}
                        entity={{ id: 1, name: "MinimalModel entity" }}
                      >
                        children
                      </FullDetailNavigation>
                    </FullDetail.Navigation.Selector>
                    <FullDetail.Navigation.ControlsRight>
                      <button className={"btn btn-sm btn-soft-primary"}>
                        <LucideIcon name="square-pen" /> Edit
                      </button>
                    </FullDetail.Navigation.ControlsRight>
                  </FullDetail.Navigation>
                  <FullDetail.Body>
                    <FullDetail.Body.Sidebar>Sidebar</FullDetail.Body.Sidebar>
                    <FullDetail.Body.Content>
                      <PanelGroup direction="vertical">
                        <Panel>
                          <div className="flex" style={{ width: "100%", height: "100%", border: "1px solid gray" }}>
                            top
                          </div>
                        </Panel>
                        <PanelResizeHandle />
                        <Panel>
                          <PanelGroup direction="horizontal">
                            <Panel>
                              <div className="flex" style={{ width: "100%", height: "100%", border: "1px solid gray" }}>
                                left
                              </div>
                            </Panel>
                            <PanelResizeHandle />
                            <Panel>
                              <div className="flex" style={{ width: "100%", height: "100%", border: "1px solid gray" }}>
                                right
                              </div>
                            </Panel>
                          </PanelGroup>
                        </Panel>
                      </PanelGroup>
                    </FullDetail.Body.Content>
                  </FullDetail.Body>
                </FullDetail>
              </div>
            </Display>
            <Display
              code={`<TableView>
            <TableView.Head>
              <TableView.Head.Label>Label</TableView.Head.Label>
              <TableView.Head.Controls>
                <button className="btn btn-soft-primary">Edit</button>
              </TableView.Head.Controls>
            </TableView.Head>
            <TableView.Body>
              <TableView.Body.Sidebar>Sidebar</TableView.Body.Sidebar>
              <TableView.Body.Content>Content</TableView.Body.Content>
            </TableView.Body>
          </TableView>`}
            >
              <div style={{ width: 600, height: 600 }}>
                <TableView>
                  <TableView.Head>
                    <TableView.Head.Label>Table Layout</TableView.Head.Label>
                    <TableView.Head.Controls>
                      <button className="btn btn-soft-primary">Edit</button>
                    </TableView.Head.Controls>
                  </TableView.Head>
                  <TableView.Body>
                    <TableView.Body.Sidebar>Table Sidebar</TableView.Body.Sidebar>
                    <TableView.Body.Content>Table Content</TableView.Body.Content>
                  </TableView.Body>
                </TableView>
              </div>
            </Display>
            <Display
              code={`<SinglePage>
              <SinglePage.Head>
                <SinglePage.Head.Label>Single page label</SinglePage.Head.Label>
                <SinglePage.Head.Controls>
                  <button className="btn btn-primary">Controls</button>
                </SinglePage.Head.Controls>
              </SinglePage.Head>
              <SinglePage.Content>Content</SinglePage.Content>
            </SinglePage>`}
            >
              <div style={{ width: 600, height: 600 }}>
                <SinglePage>
                  <SinglePage.Head>
                    <SinglePage.Head.Label>Single page label</SinglePage.Head.Label>
                    <SinglePage.Head.Controls>
                      <button className="btn btn-primary">Controls</button>
                    </SinglePage.Head.Controls>
                  </SinglePage.Head>
                  <SinglePage.Content>Content</SinglePage.Content>
                </SinglePage>
              </div>
            </Display>
            <Display
              code={`<StepwiseWizard
            title={"Title"}
            steps={[
                  { label: "Step 1", validatefn: () => true, component: (props) => <div>Child 1</div> },
                  { label: "Step 2", validatefn: () => true, component: (props) => <div>Child 2</div> },
                  { label: "Step 3", validatefn: () => true, component: (props) => <div>Child 3</div> },
                  { label: "Step 4", validatefn: () => true, component: (props) => <div>Child 4</div> },
            ]}
            onFinish={(value: any) => ...")}
          />`}
            >
              <StepwiseWizard
                title={"Title"}
                steps={[
                  { label: "Step 1", validatefn: () => true, component: (props) => <div>Child 1</div> },
                  { label: "Step 2", validatefn: () => true, component: (props) => <div>Child 2</div> },
                  { label: "Step 3", validatefn: () => true, component: (props) => <div>Child 3</div> },
                  { label: "Step 4", validatefn: () => true, component: (props) => <div>Child 4</div> },
                ]}
              />
            </Display>
          </div>
        </Tile.Body>
      </Tile>

      <Tile isFolded>
        <Tile.Head title="Modals" />
        <Tile.Body>
          <div className={styles.flex}>
            <Display
              code={`<GenericModalWrapper>
                {({ showModal, setShowModal }) => (
                  <>
                    <Modal isOpen={showModal} onClose={() => setShowModal(false)}>
                      <Modal.Title>Title</Modal.Title>
                      <Modal.Body>Body</Modal.Body>
                      <Modal.Controls>Controls</Modal.Controls>
                    </Modal>
                    <button className="btn btn-success" onClick={() => setShowModal(true)}>
                      Show Modal
                    </button>
                  </>
                )}
              </GenericModalWrapper>`}
            >
              <GenericModalWrapper>
                {({ showModal, setShowModal }) => (
                  <>
                    <Modal isOpen={showModal} onClose={() => setShowModal(false)}>
                      <Modal.Title>Title</Modal.Title>
                      <Modal.Body>Body</Modal.Body>
                      <Modal.Controls>Controls</Modal.Controls>
                    </Modal>
                    <button className="btn btn-success" onClick={() => setShowModal(true)}>
                      Show Modal
                    </button>
                  </>
                )}
              </GenericModalWrapper>
            </Display>
            <Display
              code={`<GenericModalWrapper>
              {({ showModal, setShowModal }) => (
                <>
                    <AlertModal
                    type="primary"
                    title="Valid operation"
                    description="Explanation of something to happen"
                    showModal={showModal}
                    setShowModal={setShowModal}
                    proceedLabel={"Optional label, default: Delete"}
                    onCancel={() => alert("optional on cancel handle")}
                    onProceed={() => alert("On delete click")}
                    loading
                  />
                  <button className="btn btn-primary" onClick={() => setShowModal(true)}>
                    Show Alert Modal w/ loading state
                </button>
                </>
              )}
            </GenericModalWrapper>`}
            >
              <GenericModalWrapper>
                {({ showModal, setShowModal }) => (
                  <>
                    <AlertModal
                      type="primary"
                      title="Valid operation"
                      description="Explanation of something to happen"
                      showModal={showModal}
                      setShowModal={setShowModal}
                      proceedLabel={"Optional label, default: Delete"}
                      onCancel={() => alert("optional on cancel handle")}
                      onProceed={() => alert("On delete click")}
                      loading
                    />
                    <button className="btn btn-primary" onClick={() => setShowModal(true)}>
                      Show Alert Modal w/ loading state
                    </button>
                  </>
                )}
              </GenericModalWrapper>
            </Display>
            <Display
              code={`<GenericModalWrapper>
              {({ showModal, setShowModal }) => (
                <>
                    <AlertModal
                    type="primary"
                    title="Valid operation"
                    description="Explanation of something to happen"
                    showModal={showModal}
                    setShowModal={setShowModal}
                    deleteLabel={"Optional label, default: Delete"}
                    onCancel={() => alert("optional on cancel handle")}
                    onProceed={() => alert("On delete click")}
                  />
                  <button className="btn btn-primary" onClick={() => setShowAlertModal(true)}>
                    Show Alert Modal
                  </button>
                </>
              )}
            </GenericModalWrapper>`}
            >
              <GenericModalWrapper>
                {({ showModal, setShowModal }) => (
                  <>
                    <AlertModal
                      type="primary"
                      title="Valid operation"
                      description="Explanation of something to happen"
                      showModal={showModal}
                      setShowModal={setShowModal}
                      proceedLabel={"Optional label, default: Delete"}
                      onCancel={() => alert("optional on cancel handle")}
                      onProceed={() => alert("On delete click")}
                    />
                    <button className="btn btn-primary" onClick={() => setShowModal(true)}>
                      Show Alert Modal
                    </button>
                  </>
                )}
              </GenericModalWrapper>
            </Display>
            <Display
              code={`<GenericModalWrapper>
              {({ showModal, setShowModal }) => (
                <>
                  <AlertModal
                    type="success"
                    title="Valid operation"
                    description="Explanation of something to happen"
                    showModal={showModal}
                    setShowModal={setShowModal}
                    deleteLabel={"Optional label, default: Delete"}
                    onCancel={() => alert("optional on cancel handle")}
                    onDelete={() => alert("On delete click")}
                  />
                  <button className="btn btn-success" onClick={() => setShowAlertModal(true)}>
                    Show Alert Modal
                  </button>
                </>
              )}
            </GenericModalWrapper>`}
            >
              <GenericModalWrapper>
                {({ showModal, setShowModal }) => (
                  <>
                    <AlertModal
                      type="success"
                      title="Valid operation"
                      description="Explanation of something to happen"
                      showModal={showModal}
                      setShowModal={setShowModal}
                      proceedLabel={"Optional label, default: Delete"}
                      onCancel={() => alert("optional on cancel handle")}
                      onProceed={() => alert("On delete click")}
                    />
                    <button className="btn btn-success" onClick={() => setShowModal(true)}>
                      Show Alert Modal
                    </button>
                  </>
                )}
              </GenericModalWrapper>
            </Display>
            <Display
              code={`<GenericModalWrapper>
              {({ showModal, setShowModal }) => (
                <>
                  <AlertModal
                    type="warning"
                    title="Dangerous operation"
                    description="Explanation of something to happen with a forced Countdown."
                    showModal={showModal}
                    setShowModal={setShowModal}
                    deleteLabel={"Optional label, default: Delete"}
                    onCancel={() => alert("optional on cancel handle")}
                    onDelete={() => alert("On delete click")}
                    forceCountdown
                  />
                  <button className="btn btn-warning" onClick={() => setShowModal(true)}>
                    Show Alert Modal w/ Countdown
                  </button>
                </>
              )}
            </GenericModalWrapper>`}
            >
              <GenericModalWrapper>
                {({ showModal, setShowModal }) => (
                  <>
                    <AlertModal
                      type="warning"
                      title="Dangerous operation"
                      description="Explanation of something to happen with a forced Countdown."
                      showModal={showModal}
                      setShowModal={setShowModal}
                      proceedLabel={"Optional label, default: Delete"}
                      onCancel={() => alert("optional on cancel handle")}
                      onProceed={() => alert("On delete click")}
                      forceCountdown
                    />
                    <button className="btn btn-warning" onClick={() => setShowModal(true)}>
                      Show Alert Modal w/ Countdown
                    </button>
                  </>
                )}
              </GenericModalWrapper>
            </Display>
            <Display
              code={`<GenericModalWrapper>
              {({ showModal, setShowModal }) => (
                <>
                  <AlertModal
                    type="danger"
                    title="Very Dangerous operation"
                    description={
                      <div className="flex col-nowrap gap-5">
                        <span>I can be</span>
                        <span>a JSX body</span>
                      </div>
                    }
                    showModal={showModal}
                    setShowModal={setShowModal}
                    deleteLabel={"Optional label, default: Delete"}
                    onCancel={() => alert("optional on cancel handle")}
                    onDelete={() => alert("On delete click")}
                    forceUserInput
                    forceUserInputText="DELETE"
                  />
                  <button className="btn btn-danger" onClick={() => setShowModal(true)}>
                    Show Alert Modal w/ Input confirmation
                  </button>
                </>
              )}
            </GenericModalWrapper>`}
            >
              <GenericModalWrapper>
                {({ showModal, setShowModal }) => (
                  <>
                    <AlertModal
                      type="danger"
                      title="Very Dangerous operation"
                      description={
                        <div className="flex col-nowrap gap-5">
                          <span>I can be</span>
                          <span>a JSX body</span>
                        </div>
                      }
                      showModal={showModal}
                      setShowModal={setShowModal}
                      proceedLabel={"Optional label, default: Delete"}
                      onCancel={() => alert("optional on cancel handle")}
                      onProceed={() => alert("On delete click")}
                      forceUserInput
                      forceUserInputText="DELETE"
                    />
                    <button className="btn btn-danger" onClick={() => setShowModal(true)}>
                      Show Alert Modal w/ Input confirmation
                    </button>
                  </>
                )}
              </GenericModalWrapper>
            </Display>
            <Display
              code={`<GenericModalWrapper>
              {({ showModal, setShowModal }) => (
                <>
                  <GenericModal
                    showModal={showModal}
                    setShowModal={setShowModal}
                    modalTitle={"Modal title"}
                    modalBody={"Some body"}
                    modalFooter={"Some footer"}
                  />

                  <button className="btn btn-default" onClick={() => setShowModal(true)}>
                    Show Generic Modal
                  </button>
                </>
              )}
            </GenericModalWrapper>`}
            >
              <GenericModalWrapper>
                {({ showModal, setShowModal }) => (
                  <>
                    <GenericModal
                      showModal={showModal}
                      setShowModal={setShowModal}
                      modalTitle={"Modal title"}
                      modalBody={"Some body"}
                      modalControls={"Some footer"}
                    />

                    <button className="btn btn-default" onClick={() => setShowModal(true)}>
                      Show Generic Modal
                    </button>
                  </>
                )}
              </GenericModalWrapper>
            </Display>
          </div>
        </Tile.Body>
      </Tile>

      <Tile isFolded>
        <Tile.Head title="Loaders" />
        <Tile.Body>
          <div className={styles.flex}>
            <Display code={`<StatCard label="Some stat" count={1337} />`}>
              <StatCard label="Some stat" count={1337} />
            </Display>
            <Display code={`<Count num={1337} />`}>
              <Count num={1337} />
            </Display>
            <Display code={`<CircularProgress width={32} progress={66} />`}>
              <CircularProgress width={32} progress={66} />
            </Display>
            <Display
              code={`<CircularCountdown
                startTime={+new Date() - 5000}
                currentTime={() => +new Date()}
                endTime={+new Date() + 5000}
                width={32}
                backward
              />`}
            >
              <CircularCountdown startTime={oneMinuteAgo} endTime={inOneMinute} width={32} backward />
            </Display>
            <Display code={`<Spinner />`}>
              <Spinner />
            </Display>
            <Display code={`<BrandLoader size={50} />`}>
              <BrandLoader size={50} />
            </Display>
            <Display code={'<Skeleton type="pairs" />'}>
              <Skeleton type="pairs" />
            </Display>
            <Display code={'<Skeleton type="rows" />'}>
              <Skeleton type="rows" />
            </Display>
            <Display code={'<Skeleton type="columns" n={6} />'}>
              <Skeleton type="columns" n={6} />
            </Display>
            <Display code={'<Skeleton type="columns" n={4} buttons/>'}>
              <Skeleton type="columns" n={4} buttons />
            </Display>
            <Display code={'<Skeleton type="viewer">children </Skeleton>'}>
              <div style={{ width: "800px", height: "400px" }}>
                <Skeleton type="viewer">children </Skeleton>
              </div>
            </Display>
            <Display code={'<Skeleton type="elnpage" />'}>
              <Skeleton type="elnpage" />
            </Display>
          </div>
        </Tile.Body>
      </Tile>

      <Tile isFolded>
        <Tile.Head title="Tables" />
        <Tile.Body>
          <div className={styles.flex}>
            <Display
              code={`<ContentTable
        content={[
          { key: "key", value: "value" },
          { key: "key2", value: <Entity label={"Im a react Node"} /> },
        ]}
      />`}
            >
              <ContentTable
                content={[
                  { key: "key", value: "value" },
                  { key: "key2", value: "value2" },
                ]}
              />
            </Display>
            <Display
              code={`<ContentRow
              icon={<FeatherIcon name="activity" />}
              label="label"
              date={new Date().toISOString()}
              id={"ID: 2"}
              info={"info"}
              controls={
                <button className="btn btn-ghost-secondary">
                  <FeatherIcon name="ellipsis" />
                </button>
              }
              onClick={() => alert("Clickme")}
            />`}
            >
              <ContentRow
                icon={<LucideIcon name="activity" />}
                label="label"
                date={new Date().toISOString()}
                id={"ID: 2"}
                info={"info"}
                controls={
                  <button className="btn btn-ghost-secondary">
                    <LucideIcon name="ellipsis" />
                  </button>
                }
                onClick={() => alert("Clickme")}
              />
            </Display>
            <Display
              code={`<Table>
              <Table.Head>Head</Table.Head>
              <Table.Body>
                <Table.Body.RowContent title={"Name"} content={"1234"} />
                <Table.Body.RowContent title={"Projects"} content={"123"} />
                <Table.Body.RowContent title={"Other"} content={"1234"} />
                <Table.Body.RowContent title={"Suffix"} content={"123123"} />
                <Table.Body.RowContent title={"Organizations"} content={"123"} />
              </Table.Body>
            </Table>`}
            >
              <Table>
                <Table.Head>Head</Table.Head>
                <Table.Body>
                  <Table.Body.RowContent title={"Name"} content={"1234"} />
                  <Table.Body.RowContent title={"Projects"} content={"123"} />
                  <Table.Body.RowContent title={"Other"} content={"1234"} />
                  <Table.Body.RowContent title={"Suffix"} content={"123123"} />
                  <Table.Body.RowContent title={"Organizations"} content={"123"} />
                </Table.Body>
              </Table>
            </Display>
          </div>
        </Tile.Body>
      </Tile>

      <Tile isFolded>
        <Tile.Head title="Overlays" />
        <Tile.Body>
          <div className={styles.flex}>
            <Tile isFolded>
              <Tile.Head title="Errors" />
              <Tile.Body>
                <div className={styles.flex}>
                  <Display code={`<Alert type={"primary"} message={"I'm a dismissible primary alert!"} dismissible />`}>
                    <Alert type={"primary"} message={"I'm a dismissible primary alert!"} dismissible />
                  </Display>
                  <Display
                    code={`<Alert type={"primary"} message={"I'm a primary alert with some underlying content!"}>
                    I can be a React.Node showing some detailed information!
                  </Alert>`}
                  >
                    <Alert type={"primary"} message={"I'm a primary alert with some underlying content!"}>
                      I can be a React.Node showing some detailed information!
                    </Alert>
                  </Display>
                  <Display code={`<Alert type={"secondary"} message={"I'm a secondary alert!"} />`}>
                    <Alert type={"secondary"} message={"I'm a secondary alert!"} />
                  </Display>
                  <Display code={`<Alert type={"success"} message={"I'm a success alert!"} />`}>
                    <Alert type={"success"} message={"I'm a success alert!"} />
                  </Display>
                  <Display code={`<Alert type={"info"} message={"I'm a info alert!"} />`}>
                    <Alert type={"info"} message={"I'm a info alert!"} />
                  </Display>
                  <Display code={`<Alert type={"warning"} message={"I'm a warning alert!"} />`}>
                    <Alert type={"warning"} message={"I'm a warning alert!"} />
                  </Display>
                  <Display code={`<Alert type={"danger"} message={"I'm a danger alert!"} />`}>
                    <Alert type={"danger"} message={"I'm a danger alert!"} />
                  </Display>
                  <Display code={`<Alert type={"light"} message={"I'm a light alert!"} />`}>
                    <Alert type={"light"} message={"I'm a light alert!"} />
                  </Display>
                </div>
              </Tile.Body>
            </Tile>
            <Tile isFolded>
              <Tile.Head title="Toasts" />
              <Tile.Body>
                <div className={styles.flex}>
                  <Display code={`showtoast("default", "Default notification")`}>
                    <button className="btn btn-primary" onClick={() => showtoast("default", "Default notification")}>
                      Default Toast
                    </button>
                  </Display>
                  <Display code={`showtoast("success", "Success notification")`}>
                    <button className="btn btn-success" onClick={() => showtoast("success", "Success notification")}>
                      Success Toast
                    </button>
                  </Display>
                  <Display code={`showtoast("info", "Info notification")`}>
                    <button className="btn btn-info" onClick={() => showtoast("info", "Info notification")}>
                      Info Toast
                    </button>
                  </Display>
                  <Display code={`showtoast("warning", "Warning notification")`}>
                    <button className="btn btn-warning" onClick={() => showtoast("warning", "Warning notification")}>
                      Info Toast
                    </button>
                  </Display>
                  <Display code={`showtoast("error", "Error notification")`}>
                    <button className="btn btn-danger" onClick={() => showtoast("error", "Error notification")}>
                      Error Toast
                    </button>
                  </Display>
                  <Display
                    code={`customToast((closeToast) => (
                          <div className="flex" style={{ border: "1px solid red", width: "100%", height: "100%" }}>
                            <button className="btn btn-primary" onClick={closeToast}>
                              Close me
                            </button>
                          </div>
                        ))`}
                  >
                    <button
                      className="btn btn-secondary"
                      onClick={() =>
                        customToast((closeToast) => (
                          <div className="flex" style={{ border: "1px solid red", width: "100%", height: "100%" }}>
                            <button className="btn btn-primary" onClick={closeToast}>
                              Close me
                            </button>
                          </div>
                        ))
                      }
                    >
                      Custom toast
                    </button>
                  </Display>
                </div>
              </Tile.Body>
            </Tile>
          </div>
        </Tile.Body>
      </Tile>

      <Tile isFolded>
        <Tile.Head title="Pagination" />
        <Tile.Body>
          <div className={styles.flex}>
            <Display
              code={` <PaginationView listRecords={parserErrorsResult} pageSize={pageSize} page={page} setPage={setPage} />`}
            >
              <PaginationView
                listRecords={[] as any}
                pageSize={50}
                page={1}
                setPage={() => {
                  return 1;
                }}
              />
            </Display>
          </div>
        </Tile.Body>
      </Tile>

      <Tile isFolded>
        <Tile.Head title="Textfileds" />
        <Tile.Body>
          <div className={styles.flex}>
            <div className={styles.col}>
              <Display
                code={`<Codeblock showWordWrapToggle>{"AAAAAAAAAAAAA\rBBBBBBBBBBBBB\nCCCCCCCCCCCCCCC\r\nDDDDDDDDDDDDD"}</Codeblock>`}
              >
                <Codeblock showWordWrapToggle>
                  {"AAAAAAAAAAAAA\rBBBBBBBBBBBBB\nCCCCCCCCCCCCCCC\r\nDDDDDDDDDDDDD"}
                </Codeblock>
              </Display>
              <Display code={`<Codeblock loading>{"LOADING\rLOADING\nLOADING\r\nLOADING"}</Codeblock>`}>
                <Codeblock loading>{"LOADING\rLOADING\nLOADING\r\nLOADING"}</Codeblock>
              </Display>
            </div>
            <Display code={`<PreCode>No Entry</PreCode>`}>
              <PreCode>No Entry</PreCode>
            </Display>
            <div className={styles.col}>
              <Display
                code={`<Hexviewer
                buffer={new Uint16Array(Array.from({ length: 1024 * 1e3 }, () => Math.floor(Math.random() * 40))).buffer}
                reachedBottomCallback={() => alert("Hexviewer scrolled to bottom")}
                loading={false}
              />`}
              >
                <Hexviewer
                  buffer={
                    new Uint16Array(Array.from({ length: 1024 * 1e3 }, () => Math.floor(Math.random() * 40))).buffer
                  }
                  reachedBottomCallback={() => alert("Hexviewer scrolled to bottom")}
                  loading={false}
                />
              </Display>
            </div>
          </div>
        </Tile.Body>
      </Tile>

      <Tile isFolded>
        <Tile.Head title="Badges" />
        <Tile.Body>
          <div className={styles.flex}>
            <div className={styles.col}>
              <Display code={`<Status type="success">Success</Status>`}>
                <Status type="success">Success</Status>
              </Display>
              <Display code={`<Status type="success" idle></Status>`}>
                <Status type="success" idle></Status>
              </Display>
              <Display code={`<Status type="warning">Warning</Status>`}>
                <Status type="warning">Warning</Status>
              </Display>
              <Display code={`<Status type="danger">Danger</Status>`}>
                <Status type="danger">Danger</Status>
              </Display>
              <Display code={`<Status type="secondary" />`}>
                <Status type="secondary" />
              </Display>
            </div>
            <div className={styles.col}>
              <Display
                code={`<UID placement="right" entity={{ id: 1, uid: "b47df958-673d-4948-ae0e-ed6a0b9f4dd7" }} />`}
              >
                <UID placement="right" entity={{ id: 1, uid: "b47df958-673d-4948-ae0e-ed6a0b9f4dd7" }} />
              </Display>
            </div>
            <div className={styles.col}>
              <Display code={`<CurrentIndicator />`}>
                <CurrentIndicator />
              </Display>
              <Display code={`<CurrentIndicator iconOnly/>`}>
                <CurrentIndicator iconOnly />
              </Display>
            </div>
            <div className={styles.col}>
              <Display code={`<ToggleIndicator enabled={false} />`}>
                <ToggleIndicator enabled={false} />
              </Display>
              <Display code={`<ToggleIndicator enabled={true} />`}>
                <ToggleIndicator enabled={true} />
              </Display>
            </div>
          </div>
        </Tile.Body>
      </Tile>
    </div>
  );
};
