import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useCustomTypes } from "./useCustomTypes";
import { CustomField, CustomFieldDataTypeUtils } from "../../../api/CustomFields";
import { Control, Path, UseFormGetValues, UseFormRegister } from "react-hook-form";
import { GenericVirtualizedTableCells } from "../../../common/tables/GenericVirtualizedTable/GenericVirtualizedTableTypes";
import { GenericEntity, ICustomTypedEntity, IUniqueEntity, StringIndexedDict } from "../../../api/GenericTypes";
import { ColumnsSettings } from "../../../common/tables/ColumnsSelector/ColumnsSelector";
import { CustomType, CustomTypeEntityType } from "../../../api/CustomTypes";
import { NotAvailable, NotSet } from "../../../common/misc/UIconstants";
import { CustomFieldValueRenderer } from "../../CustomFields/CustomFieldRenderer";
import { MultiEditMappedItemObject } from "../../../common/forms/MultiEditForms/common/MultiEditProvider";
import { MultiEditHeaderWrapper } from "../../../common/forms/MultiEditForms/common/MultiEditFormWrapper";
import { CustomFieldEditRenderer } from "../../CustomFields/CustomFieldEditRenderer";
import { customFieldDataTypeToColumnWidth, customFieldToCustomFieldForm } from "../../CustomFields/CustomFieldUtils";
import { MultiEditCustomFieldForm } from "../../../common/forms/MultiEditForms/forms/MultiEditCustomFieldForm";

interface UseCustomTypesEntityTableProps<Entity extends GenericEntity & ICustomTypedEntity & IUniqueEntity> {
  defaultColumnSettings: ColumnsSettings<Entity>;
  entityType: CustomTypeEntityType;
  featureFlag?: boolean;
  customTypeIds?: number[];
  defaultActive?: boolean;
}
export const useCustomTypesEntityTable = <Entity extends GenericEntity & ICustomTypedEntity & IUniqueEntity>({
  defaultColumnSettings,
  entityType,
  featureFlag = true,
  customTypeIds,
  defaultActive = false,
}: UseCustomTypesEntityTableProps<Entity>) => {
  const [customFieldHeaders, setCustomFieldHeaders] = useState<GenericVirtualizedTableCells<Entity>>([]);
  const [columnSettings, setColumnSettings] = useState<ColumnsSettings<Entity>>(defaultColumnSettings); // The columnSettings will be held here
  const { types } = useCustomTypes({ entityType: entityType, filters: { ids: customTypeIds } });

  useMemo(() => {
    if (types && featureFlag) {
      const def = { ...defaultColumnSettings }; // we make a copy of the default columns as we're going to modify them later
      if (Object.keys(types).length > 0) {
        // let _customSchemaColumnFieldsDefinition: CustomSchemaColumnFieldsDefinition = {};
        let last_position = Object.keys(defaultColumnSettings).length;

        // Collect all custom fields
        const customFields = Object.values(types)
          .map((s) => s.sections?.map((_s) => _s.customFields?.map((c) => c)))
          .flat(Infinity)
          .filter((c) => c) as CustomField[];

        const customFieldDict = Object.fromEntries(customFields.map((c) => [c.id, c]));

        Object.values(customFieldDict).forEach((field) => {
          def[field.id] = {
            pos: last_position,
            active: defaultActive,
            label: "Custom field",
            header: field.name,
            property: `customFields.${field.id}` as any,
          };
          last_position += 1;
        });

        let headers: GenericVirtualizedTableCells<Entity> = [];
        // Build custom field headers
        if (customFieldDict) {
          Object.entries(customFieldDict).forEach(([customFieldId, field]) => {
            const _customFieldId = +customFieldId;
            headers.push({
              Header: field.name,
              id: customFieldId,
              accessor: (row: Entity) =>
                row.customFields && Object.hasOwn(row.customFields, customFieldId) ? (
                  field.dataType === "EntityBarCode" ||
                  (row.customFields?.[_customFieldId] !== undefined &&
                    row.customFields?.[_customFieldId] !== null &&
                    row.customFields?.[_customFieldId] !== "") ? (
                    <CustomFieldValueRenderer
                      customfield={field}
                      value={row.customFields?.[_customFieldId]}
                      entity={row}
                      title={""}
                    />
                  ) : (
                    NotSet
                  )
                ) : (
                  NotAvailable
                ),
              width: 150,
              align: "left",
            });
          });
        }
        setCustomFieldHeaders(headers);
      }
      setColumnSettings(def);
    }
  }, [defaultActive, defaultColumnSettings, featureFlag, types]);

  return { columnSettings, customFieldHeaders, types };
};

// For bulk edit

type CustomFieldEntry = CustomField & { customTypeIds: CustomType["id"][] };
type CustomFieldSingleEntry = CustomField & { customTypeId: CustomType["id"] };
interface MultiEditCustomFieldTableCellsProps<Entity extends GenericEntity & ICustomTypedEntity & StringIndexedDict> {
  types: MultiEditMappedItemObject<CustomType>;
  control: Control<Entity, any>;
  register: UseFormRegister<Entity>;
  getValues: UseFormGetValues<Entity>;
  selection: Set<Entity["id"]>;
  disabled?: boolean;
}

export const multiEditCustomFieldTableCells = <Entity extends GenericEntity & ICustomTypedEntity & StringIndexedDict>({
  types,
  control,
  register,
  getValues,
  selection,
  disabled = false,
}: MultiEditCustomFieldTableCellsProps<Entity>) => {
  let res: GenericVirtualizedTableCells<Entity> = [];

  // Collect all custom fields
  const customFields = Object.values(types)
    .map((s) => s.sections?.map((_s) => _s.customFields?.map((c) => ({ ...c, customTypeId: s.id }))))
    .flat(Infinity)
    .filter((c) => c) as CustomFieldSingleEntry[];

  let customFieldDict: MultiEditMappedItemObject<CustomFieldEntry> = {};
  customFields.forEach((customField) => {
    if (CustomFieldDataTypeUtils.HasEditForm(customField.dataType)) {
      if (!Object.hasOwn(customFieldDict, customField.id)) {
        customFieldDict[customField.id] = {
          ...customField,
          customTypeIds: [customField.customTypeId],
        };
      } else {
        customFieldDict[customField.id] = {
          ...customField,
          customTypeIds: [...customFieldDict[customField.id].customTypeIds, customField.customTypeId],
        };
      }
    }
  });

  Object.values(customFieldDict).forEach((customField: CustomFieldEntry) => {
    res.push({
      id: `${customField.id}`,
      Header: (
        <MultiEditHeaderWrapper<Entity>
          control={control}
          getValues={getValues}
          ids={selection}
          label={customField.name}
          property={`customFields.${customField.id}`}
          required={customField.required}
          customTypeIds={customField.customTypeIds}
          hasApply
          disabled={(consolidatedValues) => {
            return disabled;
          }}
          hasAppend={CustomFieldDataTypeUtils.IsArrayDataType(customField.dataType)}
        >
          {(consolidatedValues, consolidatedCustomFieldValues, customFieldPlaceholders) => {
            const _customField = customFieldToCustomFieldForm(customField);
            return (
              <CustomFieldEditRenderer<Entity>
                id={`customFields.${customField.id}` as Path<Entity>}
                label={_customField.name ?? ""}
                register={register}
                control={control}
                errors={{}}
                placeholder={customFieldPlaceholders?.[customField.id]}
                // required={_customField.required}
                defaultValue={_customField.defaultValues}
                disabled={disabled || _customField.readOnly}
                dataType={_customField.dataType!}
                enumOptions={_customField.enumOptions}
                showAsTextArea={_customField.showAsTextArea}
                description={_customField.description}
                isMulti={!!_customField.isMulti}
                customFieldId={customField.id}
                enumOptionsFromValues={customField.enumOptionsFromValues}
                filters={
                  Array.isArray(customField.customTypeConstraint) && !!customField.customTypeConstraint.length
                    ? { customTypeIds: customField.customTypeConstraint.map((c) => c.id) }
                    : { customTypeIds: null }
                }
                uncontained
              />
            );
          }}
        </MultiEditHeaderWrapper>
      ),
      accessor: (row) => (
        <div className="flex" style={{ overflow: "auto", width: "100%", height: "100%" }}>
          <MultiEditCustomFieldForm row={row} customField={customField} types={types} disabled={disabled} />
        </div>
      ),
      width: customFieldDataTypeToColumnWidth(customField.dataType),
    });
  });

  return res;
};

interface UseCustomTypesBulkEditUtilsProps<Entity extends GenericEntity & ICustomTypedEntity> {
  initItems: MultiEditMappedItemObject<Entity> | undefined;
}
export const useCustomTypesBulkEditUtils = <Entity extends GenericEntity & ICustomTypedEntity>({
  initItems,
}: UseCustomTypesBulkEditUtilsProps<Entity>) => {
  const [uniqueCustomTypeIds, setUniqueCustomTypeIds] = useState<Set<CustomType["id"]>>(new Set());
  useEffect(() => {
    setUniqueCustomTypeIds(
      new Set(
        Object.values(initItems ?? {})
          .map((i) => i.customType?.id)
          .filter((v) => v !== null)
      ) as Set<number>
    );
  }, [initItems]);

  const onCustomTypeSelectCallback = useCallback((customTypeId: CustomType["id"] | undefined) => {
    if (customTypeId !== undefined) {
      setUniqueCustomTypeIds((prev) => {
        const set = new Set(Array.from(prev));
        if (set.has(customTypeId)) {
          set.delete(customTypeId);
        } else {
          set.add(customTypeId);
        }
        return set;
      });
    }
  }, []);

  return { uniqueCustomTypeIds, onCustomTypeSelectCallback };
};
