import { useContext, useMemo, useState } from "react";
import { API } from "../../../api/Api";
import { SampleFieldLabels, SampleFilters, Sample as SampleModel, samplesConstants } from "../../../api/Samples";
import { useEntityDetail } from "../../../api/BaseEntityApi";
import { DateTimeRenderer } from "../../../common/datetime/DateTimeFormatter";
import { LucideIcon } from "../../../common/icon/LucideIcon";
import { LoadingWrapper } from "../../../common/LoadingWrapper";
import { Alert } from "../../../common/overlays/Alert/Alert";
import { Tile } from "../../../common/panels/Tile/Tile";
import { Content, ContentTable } from "../../../common/tables/ContentTable/ContentTable";
import { DatasetContext } from "../../Dataset";
import styles from "./Sample.module.css";
import { Dataset } from "../../../api/Datasets";
import { NotSet } from "../../../common/misc/UIconstants";
import { GetPersons } from "../../../common/misc/EntityRenders/EntityRenderer";
import {
  CustomTypeRenderer,
  CustomTypeMinimalRenderer,
} from "../../../Customization/CustomTypes/generics/CustomTypeRenderer";
import { customTypeConstants } from "../../../api/CustomTypes";
import { LinkEntity } from "../../../common/misc/LinkEntity/LinkEntity";
import { toUppercase } from "../../../common/helperfunctions/stringFunctions";

interface Props {
  api: API;
  dataset: Dataset;
}

export const Sample = ({ api, dataset }: Props) => {
  const context = useContext(DatasetContext);
  const [mapping, setMapping] = useState<Content>([]);
  const {
    data: sample,
    status,
    fetchStatus,
    error,
  } = useEntityDetail<SampleModel, SampleFilters>(
    samplesConstants.resource,
    dataset?.sample?.id!,
    {},
    { enabled: !!dataset.sample?.id }
  );

  // Set the mapping for the ContentTable items
  useMemo(() => {
    let mapping: Content = [];
    if (sample) {
      // const preparedAt = sample.preparedAt && formatDate(new Date(sample.preparedAt));
      // const discardedAt = sample.discardedAt && formatDate(new Date(sample.discardedAt));

      mapping.push(
        {
          key: SampleFieldLabels.name,
          value: sample.name ? (
            <div className={"gap-5 container_label"}>
              {context?.viewShared ? (
                <div className={"container_label_name"} style={{ fontWeight: 600 }}>
                  <LucideIcon name={samplesConstants.icon} color={"var(--primary)"} /> {sample.name}
                </div>
              ) : (
                <LinkEntity
                  entityConstants={samplesConstants}
                  property={sample}
                  style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", fontWeight: 500 }}
                />
              )}
              {sample.discardedAt && (
                <div>
                  <span className="label label-soft-danger" title="Discarded">
                    Discarded
                  </span>
                </div>
              )}
              {sample.isDeleted && (
                <div>
                  <span className="label label-soft-warning" title="Trashed">
                    <LucideIcon name="trash" />
                  </span>
                </div>
              )}
              <div className={"container_label_id"}>
                <span style={{ whiteSpace: "nowrap" }}>
                  {toUppercase(samplesConstants.entitySingular)}-ID: {sample.id}
                </span>
              </div>
            </div>
          ) : (
            NotSet
          ),
        },
        {
          key: SampleFieldLabels.customType,
          value: (
            <div className={"container_label"}>
              {sample.customType ? (
                <>
                  {context?.viewShared ? (
                    <span style={{ fontWeight: 500 }}>{sample.customType.name}</span>
                  ) : (
                    <LinkEntity entityConstants={customTypeConstants} property={sample.customType} />
                  )}
                </>
              ) : (
                <div className={"container_label_name"}>
                  <span style={{ color: "var(--gray-400)", fontWeight: 500 }}>
                    Basic {samplesConstants.entitySingular}
                  </span>
                </div>
              )}
            </div>
          ),
        },
        {
          key: SampleFieldLabels.preparedAt,
          value: sample.preparedAt ? (
            <DateTimeRenderer date={sample.preparedAt} includeTime={false} includeElapsed={false} />
          ) : (
            NotSet
          ),
        },
        {
          key: SampleFieldLabels.preparedBy,
          value: <GetPersons persons={sample.preparedBy} createLinks={context?.viewShared ? false : true} />,
        },
        { key: "Notes", value: <>{!sample.notes && NotSet} </> }
      );

      sample.discardedAt &&
        mapping.push({
          key: SampleFieldLabels.discardedAt,
          value: sample.discardedAt ? <DateTimeRenderer date={sample.discardedAt} includeTime={false} /> : NotSet,
        });

      Array.isArray(sample.discardedBy) &&
        !!sample.discardedBy.length &&
        mapping.push({
          key: SampleFieldLabels.discardedBy,
          value: <GetPersons persons={sample.discardedBy} createLinks={context?.viewShared ? false : true} />,
        });

      setMapping(() => mapping);
    }
  }, [context?.viewShared, sample]);

  return (
    <Tile isFolded={!!!sample} foldable={!!sample} disabled={!!!sample}>
      <Tile.Head
        title={
          <>
            <LucideIcon name={samplesConstants.icon} /> Sample
          </>
        }
      ></Tile.Head>
      <Tile.Body>
        <LoadingWrapper status={status} fetchStatus={fetchStatus} error={error} type="skeleton-pairs">
          <div className={styles.container}>
            {sample ? (
              <>
                <ContentTable content={mapping} />
                {sample.notes && (
                  <div className={"container_notes"}>
                    <textarea rows={4} className={"container_textarea"} value={sample?.notes} disabled />
                  </div>
                )}
                {context?.viewShared ? (
                  <CustomTypeMinimalRenderer entity={sample} />
                ) : (
                  <CustomTypeRenderer entity={sample} />
                )}
              </>
            ) : (
              <Alert type="light" message="No sample defined" fit centered />
            )}
          </div>
        </LoadingWrapper>
      </Tile.Body>
    </Tile>
  );
};
