import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { EntityFormProps } from "../../common/entity/EntityInterfaces";
import { LabNotebookTemplate } from "../types/LabNotebookTemplate";
import { FormHeader } from "../../common/forms/FormHeader";
import { InputFormField } from "../../common/formfields/InputFormField";
import { TextareaFormField } from "../../common/formfields/TextareaFormField";
import { FormButtons } from "../../common/forms/FormButtons";
import styles from "../../common/forms/forms.module.css";
import { ProjectsVirtualizedSelectForm } from "../../common/forms/EntityForms/formsVirtualized/ProjectsVirtualizedSelectForm";
import { EmptyLabNotebookEntry } from "../types/LabNotebookEntry";

export const LabNotebookTemplateForm = ({
  fieldLabels,
  permissions,
  title,
  subtitle,
  initialValues,
  onSubmit,
  onCancel,
  loading,
  submitButtonLabel,
  event,
}: EntityFormProps<"notebookTemplates">) => {
  const LabNotebookTemplateFormSchema = yup.object().shape({
    name: yup
      .string()
      .required("Lab notebook template name is required")
      .typeError("Lab notebook template name is required")
      .test({
        test: (value) => value === value?.trim(),
        message: "Whitespaces at the beginning or at the end of the name are not allowed",
      }),
  });

  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
  } = useForm<Partial<LabNotebookTemplate>>({
    values: initialValues,
    resolver: yupResolver(LabNotebookTemplateFormSchema),
  });

  return (
    <>
      <FormHeader title={title} subtitle={subtitle} />
      <form
        onSubmit={(e) => {
          e.preventDefault();
        }}
        autoComplete="off"
        className={`form-horizontal ${styles.form_holder}`}
      >
        <fieldset>
          <legend className="col-md-offset-2 col-md-10">Basic details</legend>
        </fieldset>

        <InputFormField
          id="name"
          label={fieldLabels.name}
          errors={errors}
          register={register}
          autoFocus={true}
          required={true}
          placeholder="Enter lab notebook template name..."
        />

        <ProjectsVirtualizedSelectForm
          id="projects"
          label={fieldLabels.projects}
          control={control}
          showControls
          horizontal
          isMulti
          allowCreateEntity
          filters={{ currentUserHasAddPermission: true }}
        />

        <TextareaFormField
          id="description"
          label={fieldLabels.description}
          errors={errors}
          register={register}
          placeholder="Enter description..."
        />

        <FormButtons
          groupName="lab_notebook_templates"
          entityId={initialValues ? initialValues.id : undefined}
          onClose={onCancel}
          onSubmit={handleSubmit(
            async (entity) =>
              await onSubmit({
                ...entity,
                content:
                  event === "ADD"
                    ? EmptyLabNotebookEntry
                    : event === "CLONE"
                    ? initialValues?.content ?? EmptyLabNotebookEntry
                    : undefined,
                concurrencyToken: entity.modifiedOn || null,
              })
          )}
          submitButtonLabel={submitButtonLabel || "Save changes"}
          disabled={isSubmitting}
          errors={errors}
          loading={loading}
        />
      </form>
    </>
  );
};
