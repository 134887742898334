/* eslint-disable no-useless-escape */
import { CustomField, CustomFieldDataType, CustomFieldDataTypeUtils } from "../../api/CustomFields";
import * as yup from "yup";
import { AnySchema } from "yup";
import { CustomFieldForm } from "./CustomFieldsForm";
import { convertPathValueToDate } from "../../common/formfields/DatePickerFormField/SingleDatePickerFormField";
import { isDate, isTime } from "../../common/datetime/DateTimeFormatter";
import { RequireKeys } from "../../api/GenericTypes";

export const AbstractedCustomFieldDataTypesConsts = [
  "Text",
  "Number",
  "Toggle",
  "Date",
  "Time",
  "Entity Reference",
  "Entity Barcode",
  "Attachment",
  "URL",
] as const;

export type AbstractedCustomFieldDataTypes = (typeof AbstractedCustomFieldDataTypesConsts)[number];

export const EntitySelectOptionsConsts = [
  "Dataset",
  "Sample",
  "Project",
  "Organization",
  "Person",
  "Method",
  "Instrument",
  "SharedContent",
  "LabNotebook",
  "LabNotebookExperiment",
  "LabNotebookEntry",
  "Inventory",
] as const;

export type EntitySelectOptions = (typeof EntitySelectOptionsConsts)[number];

export interface GenerateAbstractedFromDataTypeReturn {
  abstractedDataType?: AbstractedCustomFieldDataTypes;
  isMulti?: boolean;
  isFloat?: boolean;
  isDateTime?: boolean;
  isDateTimeRange?: boolean;
  isTimeRange?: boolean;
  entitySelectOption?: EntitySelectOptions | undefined;
}

export const generateAbstractedFromDataType = (
  dataType?: CustomField["dataType"]
): GenerateAbstractedFromDataTypeReturn => {
  switch (dataType) {
    case "String":
      return {
        abstractedDataType: "Text",
        isMulti: false,
        isFloat: false,
        isDateTime: false,
        isDateTimeRange: false,
        entitySelectOption: undefined,
      };
    case "StringArray":
      return {
        abstractedDataType: "Text",
        isMulti: true,
        isFloat: false,
        isDateTime: false,
        isDateTimeRange: false,
        entitySelectOption: undefined,
      };
    case "Integer":
      return {
        abstractedDataType: "Number",
        isMulti: false,
        isFloat: false,
        isDateTime: false,
        isDateTimeRange: false,
        entitySelectOption: undefined,
      };
    case "IntegerArray":
      return {
        abstractedDataType: "Number",
        isMulti: true,
        isFloat: false,
        isDateTime: false,
        isDateTimeRange: false,
        entitySelectOption: undefined,
      };
    case "Float":
      return {
        abstractedDataType: "Number",
        isMulti: false,
        isFloat: true,
        isDateTime: false,
        isDateTimeRange: false,
        entitySelectOption: undefined,
      };
    case "FloatArray":
      return {
        abstractedDataType: "Number",
        isMulti: true,
        isFloat: true,
        isDateTime: false,
        isDateTimeRange: false,
        entitySelectOption: undefined,
      };
    case "Boolean":
      return {
        abstractedDataType: "Toggle",
        isMulti: false,
        isFloat: false,
        isDateTime: false,
        isDateTimeRange: false,
        entitySelectOption: undefined,
      };
    case "Date":
      return {
        abstractedDataType: "Date",
        isMulti: false,
        isFloat: false,
        isDateTime: false,
        isDateTimeRange: false,
        entitySelectOption: undefined,
      };
    case "DateArray":
      return {
        abstractedDataType: "Date",
        isMulti: true,
        isFloat: false,
        isDateTime: false,
        isDateTimeRange: false,
        entitySelectOption: undefined,
      };
    case "DateTime":
      return {
        abstractedDataType: "Date",
        isMulti: false,
        isFloat: false,
        isDateTime: true,
        isDateTimeRange: false,
        entitySelectOption: undefined,
      };
    case "DateTimeArray":
      return {
        abstractedDataType: "Date",
        isMulti: true,
        isFloat: false,
        isDateTime: true,
        isDateTimeRange: false,
        entitySelectOption: undefined,
      };
    case "Time":
      return {
        abstractedDataType: "Time",
        isMulti: false,
        isFloat: false,
        isDateTime: false,
        isDateTimeRange: false,
        entitySelectOption: undefined,
      };
    case "TimeArray":
      return {
        abstractedDataType: "Time",
        isMulti: true,
        isFloat: false,
        isDateTime: false,
        isDateTimeRange: false,
        entitySelectOption: undefined,
      };
    case "TimeRange":
      return {
        abstractedDataType: "Time",
        isMulti: false,
        isFloat: false,
        isDateTime: false,
        isDateTimeRange: false,
        isTimeRange: true,
        entitySelectOption: undefined,
      };
    case "DateTimeRange":
      return {
        abstractedDataType: "Date",
        isMulti: false,
        isFloat: false,
        isDateTime: false,
        isDateTimeRange: true,
        entitySelectOption: undefined,
      };
    case "Url":
      return {
        abstractedDataType: "URL",
        isMulti: false,
        isFloat: false,
        isDateTime: false,
        isDateTimeRange: false,
        entitySelectOption: undefined,
      };
    case "UrlArray":
      return {
        abstractedDataType: "URL",
        isMulti: true,
        isFloat: false,
        isDateTime: false,
        isDateTimeRange: false,
        entitySelectOption: undefined,
      };
    case "Dataset":
      return {
        abstractedDataType: "Entity Reference",
        isMulti: false,
        isFloat: false,
        isDateTime: false,
        isDateTimeRange: false,
        entitySelectOption: "Dataset",
      };
    case "Sample":
      return {
        abstractedDataType: "Entity Reference",
        isMulti: false,
        isFloat: false,
        isDateTime: false,
        isDateTimeRange: false,
        entitySelectOption: "Sample",
      };
    case "Project":
      return {
        abstractedDataType: "Entity Reference",
        isMulti: false,
        isFloat: false,
        isDateTime: false,
        isDateTimeRange: false,
        entitySelectOption: "Project",
      };
    case "Organization":
      return {
        abstractedDataType: "Entity Reference",
        isMulti: false,
        isFloat: false,
        isDateTime: false,
        isDateTimeRange: false,
        entitySelectOption: "Organization",
      };
    case "Person":
      return {
        abstractedDataType: "Entity Reference",
        isMulti: false,
        isFloat: false,
        isDateTime: false,
        isDateTimeRange: false,
        entitySelectOption: "Person",
      };
    case "Method":
      return {
        abstractedDataType: "Entity Reference",
        isMulti: false,
        isFloat: false,
        isDateTime: false,
        isDateTimeRange: false,
        entitySelectOption: "Method",
      };
    case "Instrument":
      return {
        abstractedDataType: "Entity Reference",
        isMulti: false,
        isFloat: false,
        isDateTime: false,
        isDateTimeRange: false,
        entitySelectOption: "Instrument",
      };
    case "SharedContent":
      return {
        abstractedDataType: "Entity Reference",
        isMulti: false,
        isFloat: false,
        isDateTime: false,
        isDateTimeRange: false,
        entitySelectOption: "SharedContent",
      };
    case "LabNotebook":
      return {
        abstractedDataType: "Entity Reference",
        isMulti: false,
        isFloat: false,
        isDateTime: false,
        isDateTimeRange: false,
        entitySelectOption: "LabNotebook",
      };
    case "LabNotebookExperiment":
      return {
        abstractedDataType: "Entity Reference",
        isMulti: false,
        isFloat: false,
        isDateTime: false,
        isDateTimeRange: false,
        entitySelectOption: "LabNotebookExperiment",
      };
    case "LabNotebookEntry":
      return {
        abstractedDataType: "Entity Reference",
        isMulti: false,
        isFloat: false,
        isDateTime: false,
        isDateTimeRange: false,
        entitySelectOption: "LabNotebookEntry",
      };
    case "Inventory":
      return {
        abstractedDataType: "Entity Reference",
        isMulti: false,
        isFloat: false,
        isDateTime: false,
        isDateTimeRange: false,
        entitySelectOption: "Inventory",
      };
    case "DatasetArray":
      return {
        abstractedDataType: "Entity Reference",
        isMulti: true,
        isFloat: false,
        isDateTime: false,
        isDateTimeRange: false,
        entitySelectOption: "Dataset",
      };
    case "SampleArray":
      return {
        abstractedDataType: "Entity Reference",
        isMulti: true,
        isFloat: false,
        isDateTime: false,
        isDateTimeRange: false,
        entitySelectOption: "Sample",
      };
    case "ProjectArray":
      return {
        abstractedDataType: "Entity Reference",
        isMulti: true,
        isFloat: false,
        isDateTime: false,
        isDateTimeRange: false,
        entitySelectOption: "Project",
      };
    case "OrganizationArray":
      return {
        abstractedDataType: "Entity Reference",
        isMulti: true,
        isFloat: false,
        isDateTime: false,
        isDateTimeRange: false,
        entitySelectOption: "Organization",
      };
    case "PersonArray":
      return {
        abstractedDataType: "Entity Reference",
        isMulti: true,
        isFloat: false,
        isDateTime: false,
        isDateTimeRange: false,
        entitySelectOption: "Person",
      };
    case "MethodArray":
      return {
        abstractedDataType: "Entity Reference",
        isMulti: true,
        isFloat: false,
        isDateTime: false,
        isDateTimeRange: false,
        entitySelectOption: "Method",
      };
    case "InstrumentArray":
      return {
        abstractedDataType: "Entity Reference",
        isMulti: true,
        isFloat: false,
        isDateTime: false,
        isDateTimeRange: false,
        entitySelectOption: "Instrument",
      };
    case "SharedContentArray":
      return {
        abstractedDataType: "Entity Reference",
        isMulti: true,
        isFloat: false,
        isDateTime: false,
        isDateTimeRange: false,
        entitySelectOption: "SharedContent",
      };
    case "LabNotebookArray":
      return {
        abstractedDataType: "Entity Reference",
        isMulti: true,
        isFloat: false,
        isDateTime: false,
        isDateTimeRange: false,
        entitySelectOption: "LabNotebook",
      };
    case "LabNotebookExperimentArray":
      return {
        abstractedDataType: "Entity Reference",
        isMulti: true,
        isFloat: false,
        isDateTime: false,
        isDateTimeRange: false,
        entitySelectOption: "LabNotebookExperiment",
      };
    case "LabNotebookEntryArray":
      return {
        abstractedDataType: "Entity Reference",
        isMulti: true,
        isFloat: false,
        isDateTime: false,
        isDateTimeRange: false,
        entitySelectOption: "LabNotebookEntry",
      };
    case "InventoryArray":
      return {
        abstractedDataType: "Entity Reference",
        isMulti: true,
        isFloat: false,
        isDateTime: false,
        isDateTimeRange: false,
        entitySelectOption: "Inventory",
      };
    case "EntityBarCode":
      return {
        abstractedDataType: "Entity Barcode",
        isMulti: false,
        isFloat: false,
        isDateTime: false,
        isDateTimeRange: false,
        entitySelectOption: undefined,
      };
    case "ViewableEntity":
      return {
        abstractedDataType: "Attachment",
        isMulti: false,
        isFloat: false,
        isDateTime: false,
        isDateTimeRange: false,
        entitySelectOption: undefined,
      };
    default:
      return {};
  }
};

export const generateDataTypeFromAbstracted = ({
  abstractedDataType,
  isMulti,
  isFloat,
  isDateTime,
  isDateTimeRange,
  isTimeRange,
  entitySelectOption,
}: RequireKeys<GenerateAbstractedFromDataTypeReturn, "isMulti">): CustomField["dataType"] | undefined => {
  switch (abstractedDataType) {
    case "Text":
      return GenerateDataType("String", isMulti);
    case "Number":
      if (isFloat === undefined) return undefined;
      return isFloat ? GenerateDataType("Float", isMulti) : GenerateDataType("Integer", isMulti);
    case "Toggle":
      return "Boolean";
    case "Date":
      if (isDateTimeRange) return "DateTimeRange";
      if (isDateTime === undefined) return undefined;
      return isDateTime ? GenerateDataType("DateTime", isMulti) : GenerateDataType("Date", isMulti);
    case "Time":
      if (isTimeRange) return "TimeRange";
      return GenerateDataType("Time", isMulti);
    case "Entity Reference":
      if (entitySelectOption === undefined) return undefined;
      return GenerateDataType(entitySelectOption, isMulti);
    case "Entity Barcode":
      return "EntityBarCode";
    case "Attachment":
      return GenerateDataType("ViewableEntity", isMulti);
    case "URL":
      return GenerateDataType("Url", isMulti);
  }
};

export const GenerateDataType = (datatype: CustomField["dataType"], isMulti: boolean): CustomField["dataType"] => {
  if (!CustomFieldDataTypeUtils.HasMultiSelect(datatype)) return datatype;
  if (!isMulti) return datatype;
  if (isMulti) {
    if (datatype === "String") return "StringArray";
    if (datatype === "Integer") return "IntegerArray";
    if (datatype === "Float") return "FloatArray";
    if (datatype === "Date") return "DateArray";
    if (datatype === "DateTime") return "DateTimeArray";
    if (datatype === "Time") return "TimeArray";
    if (datatype === "Dataset") return "DatasetArray";
    if (datatype === "Sample") return "SampleArray";
    if (datatype === "Project") return "ProjectArray";
    if (datatype === "Organization") return "OrganizationArray";
    if (datatype === "Person") return "PersonArray";
    if (datatype === "Method") return "MethodArray";
    if (datatype === "Instrument") return "InstrumentArray";
    if (datatype === "SharedContent") return "SharedContentArray";
    if (datatype === "LabNotebook") return "LabNotebookArray";
    if (datatype === "LabNotebookExperiment") return "LabNotebookExperimentArray";
    if (datatype === "LabNotebookEntry") return "LabNotebookEntryArray";
    if (datatype === "Inventory") return "InventoryArray";
    if (datatype === "Url") return "UrlArray";
  }
  return datatype;
};

export const convertEnumOptionsForm2Entity = (
  values?: CustomFieldForm["enumOptions"],
  dataType?: CustomField["dataType"]
) => {
  if (!values) return undefined;
  if (!dataType) throw new Error("Undefined data type");

  switch (dataType) {
    case "String":
    case "StringArray":
      return convertValueToNativeCustomFieldDataType(values, "StringArray");
    case "Integer":
    case "IntegerArray":
      return convertValueToNativeCustomFieldDataType(values, "IntegerArray");
    case "Float":
    case "FloatArray":
      return convertValueToNativeCustomFieldDataType(values, "FloatArray");
    case "Date":
    case "DateArray":
      return convertValueToNativeCustomFieldDataType(values, "DateArray");
    case "DateTime":
    case "DateTimeArray":
      return convertValueToNativeCustomFieldDataType(values, "DateTimeArray");
    case "Time":
    case "TimeArray":
      return convertValueToNativeCustomFieldDataType(values, "TimeArray");
    case "Url":
    case "UrlArray":
      return convertValueToNativeCustomFieldDataType(values, "StringArray");
    default:
      return values;
  }
};

export const convertEnumOptionsEntity2Form = (
  values?: CustomFieldForm["enumOptions"],
  dataType?: CustomField["dataType"]
) => {
  if (!values) return undefined;
  if (!dataType) throw new Error("Undefined data type");
  let _values = values as any[];
  if (Array.isArray(_values)) return _values.map((value) => convertEnumOption(value, dataType));
};

const convertEnumOption = (value?: CustomFieldForm["enumOptions"], dataType?: CustomField["dataType"]) => {
  switch (dataType) {
    case "String":
    case "StringArray":
      return convertNativeCustomFieldDataTypeToValue(value, "String");
    case "Integer":
    case "IntegerArray":
      return convertNativeCustomFieldDataTypeToValue(value, "Integer");
    case "Float":
    case "FloatArray":
      return convertNativeCustomFieldDataTypeToValue(value, "Float");
    case "Date":
    case "DateArray":
      return convertNativeCustomFieldDataTypeToValue(value, "Date");
    case "DateTime":
    case "DateTimeArray":
      return convertNativeCustomFieldDataTypeToValue(value, "DateTime");
    case "Time":
    case "TimeArray":
      return convertNativeCustomFieldDataTypeToValue(value, "Time");
    case "Url":
    case "UrlArray":
      return convertNativeCustomFieldDataTypeToValue(value, "String");
    default:
      return value;
  }
};

export const unifyEnumOptions = (values?: any[] | null | undefined) => {
  if (!values) return undefined;
  return values.map((value) => {
    if (typeof value === "object" && Object.hasOwn(value, "value")) {
      return value.value;
    }
    return value;
  });
};

export const convertValueToNativeCustomFieldDataType = (values?: any, dataType?: CustomField["dataType"]) => {
  let _value: typeof values;
  if (values === undefined || values === null) return null;
  if (typeof values === "object" && Object.hasOwn(values, "value")) {
    _value = values.value;
  } else {
    _value = values;
  }

  if (!dataType) throw new Error("Unsupported data type");
  switch (dataType) {
    case "String":
      return _value.toString();
    case "StringArray":
      return _value?.map((value: any) => convertValueToNativeCustomFieldDataType(value, "String"));
    case "Integer":
      return parseInt(_value);
    case "IntegerArray":
      return _value?.map((value: any) => convertValueToNativeCustomFieldDataType(value, "Integer"));
    case "Float":
      return parseFloat(_value);
    case "FloatArray":
      return _value?.map((value: any) => convertValueToNativeCustomFieldDataType(value, "Float"));
    case "Date":
      const date = convertPathValueToDate(_value);
      if (date)
        return `${date.getFullYear()}-${`${date.getMonth() + 1}`.padStart(2, "0")}-${`${date.getDate()}`.padStart(
          2,
          "0"
        )}`;
      return null;
    // return convertPathValueToDate(_value)?.toISOString().split("T").slice(0, 1)?.[0];
    case "DateArray":
      return _value
        ?.filter((v: any) => !!v)
        .map((value: any) => convertValueToNativeCustomFieldDataType(value, "Date"));
    case "DateTime":
      return convertPathValueToDate(_value)?.toISOString();
    case "DateTimeArray":
      return _value
        ?.filter((v: any) => !!v)
        .map((value: any) => convertValueToNativeCustomFieldDataType(value, "DateTime"));
    case "Time":
      return convertPathValueToDate(_value)?.toISOString().split("T").slice(-1)?.[0].replace("Z", "");
    case "TimeArray":
      return _value
        ?.filter((v: any) => !!v)
        .map((value: any) => convertValueToNativeCustomFieldDataType(value, "Time"));
    case "TimeRange":
      return _value;
    case "Url":
      return _value.toString();
    case "UrlArray":
      return _value?.map((value: any) => convertValueToNativeCustomFieldDataType(value, "Url"));
    default:
      return _value;
  }
};

export const convertNativeCustomFieldDataTypeToValue = (values?: any, dataType?: CustomField["dataType"]) => {
  if (!values) return undefined;
  if (!dataType) throw new Error("Unsupported data type");
  switch (dataType) {
    case "String":
      return values;
    case "StringArray":
      return values;
    case "Integer":
      return +values;
    case "IntegerArray":
      return values?.map((value: any) => +value);
    case "Float":
      return +values;
    case "FloatArray":
      return values?.map((value: any) => +value);
    case "Date":
      return typeof values === "string" ? new Date(`${values}T00:00:00.000Z`) : values;
    case "DateArray":
      return values?.map((value: any) => convertNativeCustomFieldDataTypeToValue(value, "Date"));
    case "DateTime":
      return typeof values === "string" ? new Date(values) : values;
    case "DateTimeArray":
      return values?.map((value: any) => convertNativeCustomFieldDataTypeToValue(value, "DateTime"));
    case "Time":
      return typeof values === "string" ? new Date(`1970-01-01T${values}Z`) : values;
    case "TimeArray":
      return values?.map((value: any) => convertNativeCustomFieldDataTypeToValue(value, "Time"));
    case "TimeRange":
      return values;
    case "Url":
      return values;
    case "UrlArray":
      return values;
    default:
      return values;
  }
};

const maxCustomFieldArray = 250;
const maxCustomFieldStringChars = 10000;
export const maxCustomFieldTextAreaChars = 30000;

export const convertToYupShape = (dataType: CustomField["dataType"], label?: string): AnySchema => {
  switch (dataType) {
    case "String":
      return yup
        .string()
        .max(maxCustomFieldStringChars)
        .label(label ?? "")
        .nullable()
        .transform((_, val) => {
          return val ? val : null;
        })
        .typeError((v) => `${v.label || v.path}: Value must be a string`);
    case "StringArray":
      return yup
        .array()
        .max(maxCustomFieldArray)
        .label(label ?? "")
        .of(convertToYupShape("String", label).nonNullable())
        .transform((_, val) => (Array.isArray(val) && !!val.length ? val : null))
        .typeError("Array of strings required");
    case "Integer":
      return yup
        .number()
        .integer()
        .min(Number.MIN_SAFE_INTEGER)
        .max(Number.MAX_SAFE_INTEGER)
        .label(label ?? "")
        .nullable()
        .transform((_, val) => {
          return val ? +val : null;
        })
        .typeError((v) => `${v.label || v.path}: Value must be an integer`);
    case "IntegerArray":
      return yup
        .array()
        .max(maxCustomFieldArray)
        .label(label ?? "")
        .of(convertToYupShape("Integer", label).nonNullable())
        .transform((_, val) => (Array.isArray(val) && !!val.length ? val : null))
        .typeError("Array of integers required");
    case "Float":
      return (
        yup
          .number()
          .min(Number.MIN_SAFE_INTEGER)
          .max(Number.MAX_SAFE_INTEGER)
          .label(label ?? "")
          // .test(
          //   "is-decimal",
          //   "A floating point number (dot separated decimal) is required",
          //   (val: any, { createError, path }) => {
          //     if (val) {
          //       const isValid = /^(?:\-?)\d*\.{1}\d*$/.test(val);
          //       if (!isValid)
          //         return createError({
          //           message: `${label || path}: Value must be a floating point number (dot separated decimal)`,
          //         });
          //       return true;
          //     }
          //     return true;
          //   }
          // )
          .nullable()
          .transform((_, val) => (val ? +val : null))
          .typeError((v) => `${v.label || v.path}: Value must be a floating point number (dot separated decimal)`)
      );
    case "FloatArray":
      return yup
        .array()
        .max(maxCustomFieldArray)
        .label(label ?? "")
        .of(convertToYupShape("Float", label).nonNullable())
        .transform((_, val) => (Array.isArray(val) && !!val.length ? val : null))
        .typeError("Array of floats required");
    case "Boolean":
      return yup
        .bool()
        .label(label ?? "")
        .typeError((v) => `${v.label || v.path}: Value must be a boolean`);
    case "Date":
      return yup
        .mixed()
        .label(label ?? "")
        .nullable()
        .transform((_, val) => (val ? val : null))
        .test("correct", "A valid date of format YYYY[/.-]MM[/.-]DD is required", (value, { createError, path }) => {
          if (value === null || value === undefined) return true;
          if (typeof value === "string") {
            const isValid = /^\d{4}-\d{2}-\d{2}$/.test(value);
            if (!isValid)
              return createError({
                message: `${label || path}: A valid date of format YYYY[/.-]MM[/.-]DD is required`,
              });
            return true;
          }
          if (value instanceof Date) return true;
          return false;
        })
        .typeError((v) => `${v.label || v.path}: Value must be of format MM[/.-]DD[/.-]YYYY`);
    case "DateArray":
      return yup
        .array()
        .max(maxCustomFieldArray)
        .label(label ?? "")
        .of(convertToYupShape("Date", label).nonNullable())
        .transform((_, val) => (Array.isArray(val) && !!val.length ? val : null))
        .typeError("Array of dates required");

    case "DateTime":
      return yup
        .mixed()
        .label(label ?? "")
        .nullable()
        .transform((_, val) => (val ? val : null))
        .test(
          "correct",
          "Value must be of format YYYY[/.-]MM[/.-]DD HH:MM:SS AM|PM",
          (value, { createError, path }) => {
            if (value === null || value === undefined) return true;
            if (typeof value === "string") {
              const isValid = isDate(value);
              if (!isValid)
                return createError({
                  message: `${label || path}: Value must be of format YYYY[/.-]MM[/.-]DD HH:MM:SS AM|PM`,
                });
              return true;
            }
            if (value instanceof Date) return true;
            return false;
          }
        )
        .typeError((v) => `${v.label || v.path}: Value must be of format YYYY[/.-]MM[/.-]DD HH:MM:SS AM|PM`);
    case "DateTimeArray":
      return yup
        .array()
        .max(maxCustomFieldArray)
        .label(label ?? "")
        .of(convertToYupShape("DateTime", label).nonNullable())
        .transform((_, val) => (Array.isArray(val) && !!val.length ? val : null))
        .typeError("Array of datetimes required");
    case "Time":
      return yup
        .mixed()
        .label(label ?? "")
        .nullable()
        .transform((_, val) => (val ? val : null))
        .test(
          "correct",
          "A valid time of format Value must be of format HH:MM:SS AM|P is required",
          (value, { createError, path }) => {
            if (value === null || value === undefined) return true;
            if (typeof value === "string") {
              const isValid = isTime(value);
              if (!isValid)
                return createError({
                  message: `${label || path}: A valid time of format Value must be of format HH:MM:SS AM|P is required`,
                });
              return true;
            }
            if (value instanceof Date) return true;
            return false;
          }
        )
        .typeError((v) => `${v.label || v.path}: Value must be of format HH:MM:SS AM|PM`);
    case "TimeArray":
      return yup
        .array()
        .max(maxCustomFieldArray)
        .label(label ?? "")
        .of(convertToYupShape("Time", label).nonNullable())
        .transform((_, val) => (Array.isArray(val) && !!val.length ? val : null))
        .typeError("Array of times required");
    case "DateTimeRange":
      return yup
        .array()
        .label(label ?? "")
        .of(yup.date().nonNullable())
        .min(2, "Two datetimes are required")
        .max(2, "Two datetimes are required")
        .typeError("Array of two datetimes required");
    case "TimeRange":
      return yup
        .number()
        .label(label ?? "")
        .nullable()
        .transform((_, val) => (val ? +val : null))
        .typeError((v) => `${v.label || v.path}: A valid timespan must be defined`);
    case "Url":
      return yup
        .string()
        .label(label ?? "")
        .url()
        .nullable()
        .transform((_, val) => {
          return val ? val : null;
        })
        .typeError((v) => `${v.label || v.path}: Value must be a string`);
    case "UrlArray":
      return yup
        .array()
        .max(maxCustomFieldArray)
        .label(label ?? "")
        .of(convertToYupShape("Url", label).nonNullable())
        .transform((_, val) => (Array.isArray(val) && !!val.length ? val : null))
        .typeError("Array of strings required");
    case "Dataset":
    case "Sample":
    case "Project":
    case "Organization":
    case "Person":
    case "Method":
    case "Instrument":
    case "SharedContent":
    case "LabNotebook":
    case "LabNotebookExperiment":
    case "LabNotebookEntry":
    case "ViewableEntity":
    case "Inventory":
      return yup
        .object()
        .label(label ?? "")
        .nullable()
        .shape({ id: yup.number().positive().integer(), name: yup.string() })
        .transform((_, val) => (val && !!Object.keys(val).length ? val : null))
        .typeError((v) => `${v.label || v.path}: A valid ${dataType} must be defined`);
    case "DatasetArray":
    case "SampleArray":
    case "ProjectArray":
    case "OrganizationArray":
    case "PersonArray":
    case "MethodArray":
    case "InstrumentArray":
    case "SharedContentArray":
    case "LabNotebookArray":
    case "LabNotebookExperimentArray":
    case "LabNotebookEntryArray":
    case "InventoryArray":
      return yup
        .array()
        .max(maxCustomFieldArray)
        .label(label ?? "")
        .of(convertToYupShape(dataType.replace("Array", "") as CustomField["dataType"], label).nonNullable())
        .transform((_, val) => (Array.isArray(val) && !!val.length ? val : null))
        .typeError("An array of entities must be defined");
    case "EntityBarCode":
      return yup
        .mixed()
        .nullable()
        .label(label ?? "");
    default:
      console.error("Unsupported data type", dataType);
      return yup.mixed().label(label ?? "");
  }
};

export const customFieldToCustomFieldForm = (
  initialValues: Partial<CustomField> | undefined
): Partial<CustomFieldForm> => {
  const result = {
    ...initialValues,
    dataType: initialValues?.dataType,
    enumOptions: convertEnumOptionsEntity2Form(initialValues?.enumOptions, initialValues?.dataType),
    defaultValues: convertNativeCustomFieldDataTypeToValue(initialValues?.defaultValues, initialValues?.dataType),
    ...generateAbstractedFromDataType(initialValues?.dataType),
  };
  // console.log("Field --> Form", initialValues, "-->", result);

  return result;
};
export const customFieldFormToCustomField = (entity: Partial<CustomFieldForm>): Partial<CustomField> => {
  const result = {
    ...entity,
    dataType: entity.dataType!,
    enumOptions: convertEnumOptionsForm2Entity(entity.enumOptions, entity.dataType),
    defaultValues: convertValueToNativeCustomFieldDataType(entity.defaultValues, entity.dataType),
  };
  // console.log("Form --> Field", entity, "-->", result);

  return result;
};

export const abstractedDataTypeToFilter = (dataType: AbstractedCustomFieldDataTypes): CustomFieldDataType[] => {
  switch (dataType) {
    case "Text":
      return ["String", "StringArray"];
    case "URL":
      return ["Url", "UrlArray"];
    case "Number":
      return ["Integer", "IntegerArray", "Float", "FloatArray"];
    case "Toggle":
      return ["Boolean"];
    case "Date":
      return ["Date", "DateArray", "DateTime", "DateTimeArray", "DateTimeRange"];
    case "Time":
      return ["Time", "TimeArray"];
    case "Entity Reference":
      return [
        "Dataset",
        "Sample",
        "Project",
        "Organization",
        "Person",
        "Method",
        "Instrument",
        "SharedContent",
        "LabNotebook",
        "LabNotebookExperiment",
        "LabNotebookEntry",
        "Inventory",
        "DatasetArray",
        "SampleArray",
        "ProjectArray",
        "OrganizationArray",
        "PersonArray",
        "MethodArray",
        "InstrumentArray",
        "SharedContentArray",
        "LabNotebookArray",
        "LabNotebookExperimentArray",
        "LabNotebookEntryArray",
        "InventoryArray",
      ];
    case "Entity Barcode":
      return ["EntityBarCode"];
    case "Attachment":
      return ["ViewableEntity"];
    default:
      return [];
  }
};

export const customFieldDataTypeToColumnWidth = (dataType: CustomFieldDataType): number => {
  switch (dataType) {
    case "String":
    case "StringArray":
    case "Integer":
    case "IntegerArray":
    case "Float":
    case "FloatArray":
    case "Date":
    case "DateArray":
    case "DateTime":
    case "DateTimeArray":
    case "Time":
    case "TimeArray":
    case "DateTimeRange":
    case "Dataset":
    case "DatasetArray":
    case "Sample":
    case "SampleArray":
    case "Project":
    case "ProjectArray":
    case "Organization":
    case "OrganizationArray":
    case "Person":
    case "PersonArray":
    case "Method":
    case "MethodArray":
    case "Instrument":
    case "InstrumentArray":
    case "SharedContent":
    case "SharedContentArray":
    case "LabNotebook":
    case "LabNotebookArray":
    case "LabNotebookExperiment":
    case "LabNotebookExperimentArray":
    case "LabNotebookEntry":
    case "EntityBarCode":
    case "Url":
    case "UrlArray":
    case "ViewableEntity":
      return 700;
    case "TimeRange":
      return 800;
    case "Boolean":
      return 200;
    case "Inventory":
    case "InventoryArray":
      return 500;
    default:
      return 400;
  }
};
