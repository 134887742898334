import { CustomFieldMapModel, StringIndexedDict } from "../../api/GenericTypes";

export const customFieldDictToCustomValuesList = (
  dict: StringIndexedDict | undefined | null
): CustomFieldMapModel[] => {
  if (!dict) return [];
  return Object.entries(dict).map(([key, value]) => ({
    id: parseInt(key),
    name: "",
    type: "CustomField",
    value: value,
  }));
};

export const customValuesListToCustomFieldDict = (
  values: CustomFieldMapModel[] | undefined | null
): StringIndexedDict => {
  let dict: { [id: string]: string } = {};
  if (!values) return dict;
  if (Array.isArray(values) && !values.length) return dict;

  for (let model of values) {
    if (model.id && model.type === "CustomField") {
      dict[model.id] = model.value;
    }
    if (model.content) {
      let childDict = customValuesListToCustomFieldDict(model.content);
      dict = { ...dict, ...childDict };
    }
  }
  return dict;
};
