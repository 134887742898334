import React, { CSSProperties } from "react";
import styles from "./ContentRow.module.css";

/**
 * Formatted condensed View for use in Sidebar components
 * Author: CS
 * @param: icon, label, date, id, info, controls, onClick
 * @returns: JSX.Element
 */

interface Props {
  icon?: React.ReactNode;
  label?: string | React.ReactNode;
  currentIndicator?: string | React.ReactNode;
  date?: string | React.ReactNode;
  id?: string | React.ReactNode;
  info?: string | React.ReactNode;
  controls?: React.ReactNode;
  onClick?: () => void;
  disabled?: boolean;
  style?: CSSProperties;
}
const ContentRow = ({ icon, label, currentIndicator, date, id, info, controls, onClick, disabled, style }: Props) => {
  return (
    <div
      className={`${styles.row} ${onClick && styles.row_clickable} ${disabled && styles.row_disabled}`}
      onClick={(e) => {
        if (onClick) {
          e.stopPropagation();
          e.preventDefault();
          onClick();
        }
      }}
      style={style}
    >
      <div className={styles.icon}>{icon}</div>
      <div className={`${styles.row_item} ${styles.label} ${!id && styles.label_centered}`}>
        <div className={styles.flex_nowrap}>
          <span className={styles.span_nowrap}>{label}</span>
          {currentIndicator ? <div>{currentIndicator}</div> : null}
        </div>
      </div>
      {info ? (
        <div className={`${styles.row_item} ${styles.date}`}>
          <span className={styles.span_nowrap}>{date}</span>
        </div>
      ) : (
        <div className={`${styles.row_item} ${styles.date} ${styles.date_centered}`}>{date}</div>
      )}

      {info && (
        <div className={`${styles.row_item} ${styles.info}`}>
          <span className={styles.span_nowrap}>{info}</span>
        </div>
      )}
      {id && (
        <div className={`${styles.row_item} ${styles.id}`}>
          <span className={styles.span_nowrap}>{id}</span>
        </div>
      )}
      {controls && <div className={styles.ctrl}>{controls}</div>}
      {disabled && <div className={styles.disabled_overlay} />}
    </div>
  );
};

export default ContentRow;
