import { StringIndexedDict } from "../../../../api/GenericTypes";
import {
  GenericEntityVirtualizedEntitySelectFormFieldProps,
  GenericVirtualizedEntitySelectFormFieldControllerWrapper,
} from "../../../formfields/GenericVirtualizedSelectFormField/GenericVirtualizedEntitySelectFormFieldControllerWrapper";
// import { QuickAddWrapper } from "../../QuickAdd/common/QuickAddWrapper";

import {
  LabNotebookEntry,
  LabNotebookEntryFilters,
  LabNotebookEntrySortingOptionsConsts,
  LabNotebookEntrySuggestions,
  labNotebookEntriesConstants,
} from "../../../../ELN/types/LabNotebookEntry";
import { useContext } from "react";
import { SessionContext } from "../../../contexts/SessionContext";
import {
  getNotebookEntryStringLabel,
  NotebookEntryWithBreadcrumb,
} from "../../../../ELN/LabNotebookEntry/LabNotebookEntry";
import { QuickAddWrapper } from "../../QuickAdd/common/QuickAddWrapper";
import { QuickAddLabNotebookEntryForm } from "../../QuickAdd/forms/QuickAddLabNotebookEntry";

export const LabNotebookEntriesVirtualizedSelectForm = <Entity extends StringIndexedDict>(
  props: GenericEntityVirtualizedEntitySelectFormFieldProps<
    Entity,
    LabNotebookEntrySuggestions,
    LabNotebookEntryFilters
  >
) => {
  const { session } = useContext(SessionContext);
  return (
    <GenericVirtualizedEntitySelectFormFieldControllerWrapper<
      Entity,
      LabNotebookEntrySuggestions,
      LabNotebookEntryFilters
    >
      {...props}
      filters={{ orderBy: "MODIFIED_ON_DESC", ...props.filters }}
      //   onRowRenderer={(item) => <></>}
      entityConstants={labNotebookEntriesConstants}
      orderByOptions={LabNotebookEntrySortingOptionsConsts}
      allowCreateEntity={session?.permissions.can_create_eln_content && props.allowCreateEntity}
      overrideItemRenderer={(item) => <NotebookEntryWithBreadcrumb entry={item} />}
      overrideSelectedRenderer={(item) =>
        item.name && !item.entryDate ? item.name : getNotebookEntryStringLabel({ entry: item })
      }
      createModal={({ showModal, setShowModal, onCreate }) => (
        <QuickAddWrapper<LabNotebookEntry> showModal={showModal} setShowModal={setShowModal} onCreate={onCreate}>
          {(props) => (
            <QuickAddLabNotebookEntryForm {...props} initialValues={{ entryDate: new Date().toISOString() }} />
          )}
        </QuickAddWrapper>
      )}
    />
  );
};
