import produce from "immer";
import React, { CSSProperties, useCallback, useState, useRef, useLayoutEffect, useEffect } from "react";
// import { CSSProperties, useEffect } from "react";
import Draggable from "react-draggable";

import styles from "./ToolWindow.module.css";

// type borderNames = "dragRight" | "dragLeft" | "dragTop" | "dragBottom";
// type dragType = { id?: borderNames; x: number; y: number; draging: boolean };
type sizeType = { width: number; height: number };

const ToolPanel = (props: any) => {
  const { id, children, style, width, height, className, title, onClosed, focused, onFocused, ...otherProps } = props;

  const [size, setSize] = useState<sizeType>({
    width: width,
    height: height,
  });
  const [bodySize, setBodySize] = useState<sizeType>({
    width: width,
    height: height,
  });
  const [minHeight, setMinHeight] = useState<number>();
  const [minWidth, setMinWidth] = useState<number>();
  // const [innerSize, setInnerSize] = useState<{ width: number; height: number }>({
  //   width: width,
  //   height: height,
  // });
  const [active, setActive] = useState<boolean>(true);
  const [headerHeight, setHeaderHeight] = useState<number>(0);
  const [myStyle, setMyStyle] = useState<CSSProperties>({
    position: "fixed",
    zIndex: 1000 + (focused ? 1 : 0),
    width: width,
    height: height,
    resize: "both",
  });
  const bodyRef = useRef<HTMLDivElement>(null);
  const targetRef = useRef<HTMLDivElement>(null);

  const handleResize = useCallback(() => {
    if (targetRef.current) {
      const element = targetRef.current as Element;
      const bound = element.getBoundingClientRect();

      setHeaderHeight(bound.height);
    }
  }, [targetRef]);

  // useEffect(() => {
  //   return () => {
  //     // on unmount remove listerner
  //     window.removeEventListener("resize", handleResize);
  //     console.log("UNMOUNT");
  //   };
  //   // we want to do this on unmount so we don't need any dependencies
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  useLayoutEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      // on unmount remove listerner
      window.removeEventListener("resize", handleResize);
    };
    // we want to do this on un-/mount so we don't need any dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const defaultBorderSize = 6;

  // const dragImage = useMemo(() => {
  //   const img = new Image();
  //   img.src =
  //     "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAADElEQVQI12P4//8/AAX+Av7czFnnAAAAAElFTkSuQmCC";
  //   return img;
  // }, []);

  // console.log("title", id === "color", targetRef);

  // let myStyle: CSSProperties = {
  //   position: "fixed",
  //   zIndex: 1000 + (focused ? 1 : 0),
  //   width: size.width,
  //   height: size.height,
  //   resize: "both",
  // };
  // Object.assign(myStyle, style);
  useEffect(() => {
    setMyStyle((myStyle) =>
      produce(myStyle, (next) => {
        Object.entries(style).forEach(([k, v]) => ((next as any)[k] = v));
      })
    );
  }, [style]);

  useEffect(() => {
    setMyStyle((myStyle) =>
      produce(myStyle, (next) => {
        next.width = size.width;
        next.height = size.height;
      })
    );
    setBodySize({ width: size.width - 20, height: size.height - 20 - headerHeight });
  }, [size, headerHeight]);

  useEffect(() => {
    setMyStyle((myStyle) =>
      produce(myStyle, (next) => {
        next.zIndex = 1000 + (focused ? 1 : 0);
      })
    );
  }, [focused]);

  useEffect(() => {
    // if (h < height)
    //   setSize(
    //     produce(size, (next) => {
    //       next.height = height + headerHeight;
    //     })
    //   );
    // console.log("test", minHeight, size.height - headerHeight, (minHeight || 0) > size.height - headerHeight);

    setSize((size) =>
      produce(size, (next) => {
        if (minHeight && minHeight > size.height - headerHeight) next.height = minHeight + headerHeight;
        if (minWidth && minWidth > size.width - headerHeight) next.width = minWidth + headerHeight;
      })
    );
  }, [minHeight, minWidth, headerHeight]);

  // console.log("size", size);
  const changeMinHeight = useCallback((height: number) => setMinHeight(height), []);
  const changeMinWidth = useCallback((width: number) => setMinWidth(width), []);

  if (!active) return null;

  // console.log("size", size);
  // console.log("children", children);
  return (
    <div
      style={myStyle}
      className={styles.main + " " + className}
      {...otherProps}
      // ref={targetRef}
      onClick={() => {
        // v---- This is for debug purposes ----
        const element = bodyRef.current as Element;
        const bound = element.getBoundingClientRect();
        // console.log("body", bound.width, "x", bound.height);
        setBodySize({ width: bound.width - 20, height: bound.height - 20 });
        // ^---- This is for debug purposes ----

        onFocused();
      }}
    >
      <div className={styles.boxMain}>
        <div className={styles.boxHeader} ref={targetRef}>
          <div>{title}</div>
          <span
            className={"glyphicon glyphicon-remove"}
            onClick={() => {
              // console.log("test", onClosed);
              setActive(false);
              if (onClosed) onClosed();
            }}
          />
        </div>
        <div className={styles.boxBody} ref={bodyRef}>
          {/* {children} */}
          {React.cloneElement(children, {
            setMinHeight: changeMinHeight,
            setMinWidth: changeMinWidth,
            width: bodySize.width,
            height: bodySize.height,
          })}
        </div>
      </div>
    </div>
  );
};

type toolWindowType = {
  defaultPosition?: { x: number; y: number };
  width: number;
  height: number;
  parentWidth: number;
  parentHeight: number;
  onClosed?: () => void;
  onFocused?: () => void;
  children: any;
  title: any;
  id?: string | number;
  focused?: boolean;
  top?: number;
  left?: number;
};

export const ToolWindow = ({
  defaultPosition,
  width,
  height,
  parentHeight,
  parentWidth,
  children,
  title,
  id,
  focused,
  top,
  onFocused,
  onClosed,
}: toolWindowType) => {
  const [active, setActive] = useState<boolean>(true);

  const closeWindow = useCallback(() => {
    // console.log("call");
    setActive(false);
    if (onClosed) onClosed();
  }, [onClosed]);

  if (!active) return null;

  return (
    <Draggable
      // axis="x"
      handle={"." + styles.boxHeader}
      defaultPosition={defaultPosition}
      // position={undefined}
      // grid={[25, 25]}
      scale={1}
      bounds={{
        left: -width * 0.75,
        // top: -(top || 0) / 2,
        top: -(top || 0) * 0.8,
        right: parentWidth - width * 0.25,
        bottom: parentHeight - height * 0.25,
      }}

      // onStart={(e: any) => {
      //   console.log("start", e);
      // }}
      // onDrag={this.handleDrag}
      // onStop={this.handleStop}
    >
      <ToolPanel
        width={width}
        height={height}
        title={title}
        onClosed={closeWindow}
        id={id}
        focused={focused}
        onFocused={onFocused ? onFocused : () => {}}
      >
        {children}
        {/* This readme is really dragging on wasd asdfja aksfdj as kasjf sdlk
        <Button bsStyle={"success"}>resize</Button>
          <ToggleButtonComponent checked={true}>Test</ToggleButtonComponent> */}
      </ToolPanel>
    </Draggable>
  );
};
