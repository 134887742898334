import { PersonTagForm } from "./PersonTagForm";
import { EntityAddForm, EntityAddFormPage } from "../common/entity/EntityAddForm";
import { EntityCloneForm, EntityCloneFormPage } from "../common/entity/EntityCloneForm";
import { EntityCloneFormProps, EntityDetailViewProps, EntityEditFormProps } from "../common/entity/EntityInterfaces";
import { EntityEditForm, EntityEditFormPage } from "../common/entity/EntityEditForm";
import { EntityDetailPage, EntityDetailView } from "../common/entity/EntityDetailPage";
import { PersonTagsDetail } from "./PersonTagsDetail";
import { EntityTablePage } from "../common/entity/EntityTablePage";
import { PersonTagsTable } from "./Table/PersonTagsTable";

// Add
export const PersonTagAddForm = () => {
  return <EntityAddForm entityTypeId={"personTags"}>{(props) => <PersonTagForm {...props} />}</EntityAddForm>;
};

export const PersonTagAddFormPage = () => {
  return (
    <EntityAddFormPage>
      <PersonTagAddForm />
    </EntityAddFormPage>
  );
};

// Clone
export const PersonTagCloneForm = ({ id }: EntityCloneFormProps<"personTags">) => {
  return (
    <EntityCloneForm id={id} entityTypeId={"personTags"}>
      {(props) => <PersonTagForm {...props} />}
    </EntityCloneForm>
  );
};

export const PersonTagCloneFormPage = () => {
  return <EntityCloneFormPage<"personTags"> isIntId>{(id) => <PersonTagCloneForm id={id} />}</EntityCloneFormPage>;
};

// Edit
export const PersonTagEditForm = ({ id }: EntityEditFormProps<"personTags">) => {
  return (
    <EntityEditForm id={id} entityTypeId={"personTags"} filters={{}}>
      {(props) => <PersonTagForm {...props} />}
    </EntityEditForm>
  );
};

export const PersonTagEditFormPage = () => {
  return <EntityEditFormPage<"personTags"> isIntId>{(id) => <PersonTagEditForm id={id} />}</EntityEditFormPage>;
};

// Detail
export const PersonTagsDetailView = ({ id }: EntityDetailViewProps<"personTags">) => {
  return (
    <EntityDetailView id={id} entityTypeId={"personTags"} filters={{}}>
      {(props) => <PersonTagsDetail {...props} />}
    </EntityDetailView>
  );
};

export const PersonTagDetailsPage = () => {
  return <EntityDetailPage<"personTags"> isIntId>{(id) => <PersonTagsDetailView id={id} />}</EntityDetailPage>;
};

export const PersonTagsTablePage = () => {
  return <EntityTablePage entityTypeId={"personTags"}>{(props) => <PersonTagsTable {...props} />}</EntityTablePage>;
};
