import { StackTraces } from "./../StackTrace";
import {
  CommandParameter,
  parameterInputTypes,
  parameterTypes,
  serializedCommandParameterType,
} from "../PipelineTypes";

export class EmptyParameter implements CommandParameter {
  name?: string;
  parameterType = parameterTypes.string;

  errors: StackTraces;
  warnings: StackTraces;
  inputType: parameterInputTypes;
  value: any;
  hidden?: boolean;
  readonly?: boolean;

  constructor(value?: any) {
    this.errors = new StackTraces();
    this.warnings = new StackTraces();
    this.inputType = parameterInputTypes.default;
    this.init(value);
    // this.hidden = value?.hidden ?? false;
    // if (value?.inputType) this.inputType = value?.inputType;
    // if (value?.name) this.name = value.name;
    // if (value?.hidden) this.hidden = value.hidden;
    // if (value?.readonly !== undefined) this.readonly = value.readonly;
  }

  init(value?: any) {
    if (value?.inputType) this.inputType = value?.inputType;
    if (value?.name) this.name = value.name;
    if (value?.hidden) this.hidden = value.hidden;
    if (value?.readonly !== undefined) this.readonly = value.readonly;
  }

  deserialize(value?: any) {
    this.init(value);
  }

  serialize(storageMode?: boolean): serializedCommandParameterType {
    const result: serializedCommandParameterType = {
      parameterType: this.parameterType,
      inputType: this.inputType,
    };
    // console.log("serialize", this.name, this.readonly);

    if (!storageMode) {
      if (this.name !== undefined) result.name = this.name;
      if (this.readonly !== undefined) result.readonly = this.readonly;
      if (this.hidden !== undefined) result.hidden = this.hidden;
    }
    return result;
  }
}
