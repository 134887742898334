import { generateUid } from "../tools/UID/UID";
import { trackModes } from "./ViewerLayoutTypes";

export type viewerState = {
  domainX?: number[];
  domainY?: number[];
};

export type Data1DReal = {
  x: number[] | Float32Array | Float64Array;
  y: number[] | Float32Array | Float64Array;
  length: number;
  type: string;
};

export const resizeData1DReal = (track: Data1DReal, length: number, copyData: boolean = true): Data1DReal => {
  const len = track.length;
  const bufferLen = Math.min(track.x.length, track.y.length);

  // if (length === len) return track;

  if (length < len || length < bufferLen) {
    track.length = length;
    return track;
  }

  if (length > len) {
    const x = new Float32Array(length);
    const y = new Float32Array(length);
    if (copyData) {
      for (let i = 0; i < len; i++) {
        x[i] = track.x[i];
        y[i] = track.y[i];
      }
      // console.log("len", x[len], y[len]);
    }
    track.x = x;
    track.y = y;
    track.length = length;
    return track;
  }

  return track;
};

export type clickType = { count: number; x: number; y: number };

export type offsetType = {
  translateX?: number;
  translateY?: number;
  // getStepSize?: () => number[];
  getStepSize?: () => [number, number];
  stepX?: number;
  stepY?: number;
  directionX?: number;
  directionY?: number;
  // active: boolean;
};

export enum normalizeType {
  normalizeZeroOne = "0-1",
  normalizeMinusToPlusOne = "-1-1",
}



export enum axisElementType {
  hideXLabel = "x",
  hideYLabel = "y",
  hideZLabel = "z",
}

export enum annotationVisibilityType {
  annotationTics = "tics",
  annotationLabels = "labels",
}

export enum drawType {
  line = "line",
  dots = "dots",
  heatmap = "heatmap",
  contour = "contour",
  filled = "filled",
}

export enum zoomModes {
  default = "default",
  boxZoom = "boxZoom",
}

export enum axisModes {
  fixX = "fixX",
  fixY = "fixY",
}

export enum crosshairMode {
  showXLine = "showXLine",
  showYLine = "showYLine",
  showXValue = "showXValue",
  showYValue = "showYValue",
}

export enum contourTypes {
  explicitContours = "Explicit",
  equidistant = "Equidistant",
  oneBaseline = "Baseline",
  multiBaseline = "Multi Baseline",
}

export enum trackState {
  loading = "loading",
  ready = "ready",
  failed = "failed",
}

export type contourState = {
  type: contourTypes;
};

export type contourGenerator = (state: any, min: number, max: number) => number[];
export type contourInitializer = (state?: contourState) => contourState;

export type contourGeneratorType = {
  // this is used as state for the reduced -> type & and state should be synchronized with the class internal fields
  state: contourState;
  generate: contourGenerator;
};

export interface ApiAuthentication {
  type: "session" | "apikey";
  value: string;
}

export type trackParameterType = {
  xlabel?: string;
  ylabel?: string;
  xunit?: string;
  yunit?: string;
  label: string;
  axisMin?: number[];
  axisMax?: number[];
  hideAxis: Record<axisElementType, boolean>;
  // color?: colorType | string | string[];
  color?: colorType;
  // colorID?: string;
  reverseColor: boolean;
  visible: boolean;
  active: boolean;
  selected: boolean;
  draw: Record<drawType, boolean>;
  normalize: Record<normalizeType, boolean>;
  // annotation: annotationVisibilityType[];
  annotation: Record<annotationVisibilityType, boolean>;
  contours?: contourGeneratorType;
};

export type viewerModeType = {
  zoomMode: zoomModes;
  axisMode: Record<axisModes, boolean>;
  crosshairMode: Record<crosshairMode, boolean>;
  autoZoom: boolean;
  trackMode: trackModes;
  stacked: boolean;
  zoomUpdate: boolean;
};

export const initViewerModeType = (): viewerModeType => {
  return {
    zoomMode: zoomModes.default,
    axisMode: { fixX: false, fixY: false },
    autoZoom: true,
    trackMode: trackModes.multiVisible,
    stacked: false,
    crosshairMode: { showXLine: true, showXValue: true, showYLine: true, showYValue: true },
    zoomUpdate: false,
  };
};

export const initTrackParameterType = (): trackParameterType => {
  return {
    label: "",
    draw: { line: true, dots: false, heatmap: false, contour: true, filled: false },
    hideAxis: { x: false, y: false, z: false },
    reverseColor: false,
    visible: true,
    active: true,
    selected: false,
    annotation: { tics: true, labels: true },
    normalize: { "0-1": false, "-1-1": false },
  };
};

export type trackSettingType = {
  visible: boolean;
};

export type processingTypes = "nmr_fid" | "none";

export type sequenceTrackType = "nucleotide_sequence" | "protein_sequence";
export type plotTrackType = "1D_real" | "1D_complex" | "2D_real_matrix";
export type pdfTrackType = "pdf";

// export type dataTrackType = "1D_real" | "1D_complex" | "2D_real_matrix" | "nucleotide_sequence" | "protein_sequence";
export type dataTrackType = plotTrackType | sequenceTrackType | pdfTrackType;

export type trackType = {
  id: string;
  index: number;
  parentTracks: string[];
  label: string;
  type: dataTrackType;
  processingType: processingTypes;
  data?: any;
  state: trackState;
  parameter: trackParameterType;
  generated: boolean;
  // settings: trackSettingType;
  // visible: boolean;
};

export type trackParameterListElement = {
  id: string;
  parameter: trackParameterType;
};
export type trackParameterList = trackParameterListElement[];

export const initTrackType = (): trackType => {
  return {
    parameter: initTrackParameterType(),
    type: "1D_real",
    id: generateUid(),
    index: -1,
    processingType: "none",
    // visible: true,
    label: "",
    parentTracks: [],
    state: trackState.loading,
    generated: false,
  };
};

// export type parameterType = {
//   datasetId: string;
//   tracksIndex: number[];
//   id: number;
//   active?: boolean;
//   colors?: string[];
//   type: string;
//   content?: parameterType[];
//   decimal?: number;
//   delimiter?: string;
//   formatter?: string;
//   multiline?: boolean;
//   tracks?: string[];
//   unit?: string;
//   valueType?: string;
//   name?: string;
//   formattedValue?: [number, string];
//   value: any;
//   table?: (string | number)[];
// };

export type spectrumAnnotationType = {
  type: string;
  mode?: string;
  color: string;
  tracks: string[];
  length: number;
  direction: "string";
  label: string[];
  index: number[];
  cut: number[];
  position: number[];
};

export type sequenceAnnotationType = {
  index: number[];
  length: number;
  type: string;
  direction?: string;
  cut?: number[];
  label: string;
  color: string;
  id?: number;
  tracks: string[];
};

type Without<T, U> = { [P in Exclude<keyof T, keyof U>]?: never };
type XOR<T, U> = T | U extends object ? (Without<T, U> & U) | (Without<U, T> & T) : T | U;

export type annotationType = XOR<spectrumAnnotationType, sequenceAnnotationType>;

export type singleColorType = {
  offset: number;
  color: string;
  value?: number;
};

// export type colorArray = singleColorType[];

// {
//   "colors": [
//     "blue",
//     "red",
//     "green"
//   ],
//   "scales": [
//     0,
//     0.3,
//     0.5
//   ],
//   "id": "DKHIFVBUTB",
//   "range": [
//     -1,
//     1
//   ],
//   "offset": [
//     -1,
//     -0.4,
//     0
//   ]
// }

// export type viewerColorType = {
export type colorType = {
  colors: string[];
  offsets: number[];
  id?: string;
  range?: number[];
  values?: number[];
  // generate?: (offset: number) => string;
};

// export type colorType = viewerColorType;
// export type colorType = XOR<colorArray, viewerColorType>;

export const SingleColorNeedsUpdate = (target: singleColorType, source: singleColorType) => {
  // export type singleColorType = {
  //   offset: number;
  //   color: string;
  //   value?: number;
  // };

  return (
    target.color !== source.color ||
    Math.abs(target.offset - source.offset) > Number.EPSILON ||
    (target?.value && source?.value && Math.abs(target.value - source.value) > Number.EPSILON)
  );
  // return true || Math.abs(target.offset - source.offset) > Number.EPSILON;
};

export const ColorNeedsUpdate = (target: colorType, source: colorType): boolean => {
  if (target?.id !== source?.id) return true;
  if (
    target?.colors?.length !== source?.colors?.length ||
    target?.offsets?.length !== source?.offsets?.length ||
    target?.values?.length !== source?.values?.length
  )
    return true;

  if (target?.colors && source?.colors && target.colors.some((v, i) => v !== source.colors[i])) return true;
  if (
    target?.offsets &&
    source?.offsets &&
    target.offsets.some((v, i) => Math.abs(v - source.offsets[i]) > Number.EPSILON)
  )
    return true;
  if (
    target?.values &&
    source?.values &&
    target.values.some((v, i) => Math.abs(v - (source?.values?.[i] || 0)) > Number.EPSILON)
  )
    return true;

  return false;
};

export const ListToColorType = (color: string | string[] | undefined, offsets?: number[]): colorType | undefined => {
  if (color === undefined) return undefined;

  if (typeof color === "string")
    return {
      colors: [color],
      offsets: [0],
    };

  if (Array.isArray(color) && color.length) {
    if (!offsets) offsets = color.map((c) => 0);
    if (offsets.length < color.length)
      for (let i = offsets.length; i < color.length; i++) offsets[i] = offsets[offsets.length - 1];
    return {
      colors: color,
      offsets: offsets,
    };
  }

  return {
    colors: ["black"],
    offsets: [0],
  };
};
