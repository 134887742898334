import React from "react";
import { Tile } from "../common/panels/Tile/Tile";
import { ContentTable } from "../common/tables/ContentTable/ContentTable";
import { ParserStatistics } from "../api/Parsing";

interface MaintenanceSidebarProps {
  parsingStatisticsAggregated?: ParserStatistics;
}
export const MaintenanceSidebar = ({ parsingStatisticsAggregated }: MaintenanceSidebarProps) => {
  return (
    <div style={{ padding: "0px 5px" }}>
      <Tile>
        <Tile.Head title="Total statistics">
          {/* <Tile.Head.Controls>
            <Tile.Head.Controls.Unfolded>
              <div>
                <button
                  className={`btn btn-xs btn-ghost-default btn-round ${parsingStatisticsIsFetching && "loading"}`}
                  title="Refresh"
                  onClick={() => parsingStatisticsRefetch()}
                  disabled={parsingStatisticsIsFetching}
                >
                  {parsingStatisticsIsFetching && <span className="spinner" aria-hidden="true"></span>}
                  <FeatherIcon name="refresh-cw" />
                </button>
              </div>
            </Tile.Head.Controls.Unfolded>
          </Tile.Head.Controls> */}
        </Tile.Head>
        <Tile.Body>
          <ContentTable
            content={[
              {
                key: "Successful",
                value: (
                  <span style={{ color: "var(--success)", marginLeft: "auto", fontSize: "1.5em" }}>
                    {parsingStatisticsAggregated?.successfullyParsed}
                  </span>
                ),
              },
              {
                key: "In queue",
                value: (
                  <span style={{ color: "var(--warning)", marginLeft: "auto", fontSize: "1.5em" }}>
                    {parsingStatisticsAggregated?.inQueue}
                  </span>
                ),
              },
              {
                key: "Failed",
                value: (
                  <span style={{ color: "var(--danger)", marginLeft: "auto", fontSize: "1.5em" }}>
                    {parsingStatisticsAggregated?.parsingFailed}
                  </span>
                ),
              },
              {
                key: "Not parsed",
                value: (
                  <span style={{ color: "var(--black)", marginLeft: "auto", fontSize: "1.5em" }}>
                    {parsingStatisticsAggregated?.notYetParsed}
                  </span>
                ),
              },
            ]}
            styleKeys={{ alignItems: "center" }}
          />
        </Tile.Body>
      </Tile>

      {/* <Tile
        title="Queue"
        controls={
          <span className="badge loading" title="Continuously updated">
            <span>•</span>
          </span>
        }
      >
        <LoadingWrapper status={parsingStatisticsFetchStatus} error={parsingStatisticsFetchError}>
          <div className={styles.chart} ref={ref}></div>
        </LoadingWrapper>
      </Tile> */}
    </div>
  );
};
