import { BridgeFilters, BridgeSortingOptionsConsts, BridgeSuggestions, bridgeConstants } from "../../../../api/Bridges";
import { StringIndexedDict } from "../../../../api/GenericTypes";
import {
  GenericEntityVirtualizedEntitySelectFormFieldProps,
  GenericVirtualizedEntitySelectFormFieldControllerWrapper,
} from "../../../formfields/GenericVirtualizedSelectFormField/GenericVirtualizedEntitySelectFormFieldControllerWrapper";
// import { QuickAddWrapper } from "../../QuickAdd/common/QuickAddWrapper";

export const BridgesVirtualizedSelectForm = <Entity extends StringIndexedDict>(
  props: GenericEntityVirtualizedEntitySelectFormFieldProps<Entity, BridgeSuggestions, BridgeFilters>
) => {
  return (
    <GenericVirtualizedEntitySelectFormFieldControllerWrapper<Entity, BridgeSuggestions, BridgeFilters>
      {...props}
      filters={{ orderBy: "NAME_ASC", ...props.filters }}
      onRowRenderer={(item) => (
        <>
          <label className="label label-soft-info" style={{ margin: 0 }}>
            {item.type}
          </label>
          <label className="label label-soft-default" style={{ margin: 0, fontFamily: "monospace" }}>
            {item.ipAddress}
          </label>
        </>
      )}
      entityConstants={bridgeConstants}
      orderByOptions={BridgeSortingOptionsConsts}
      allowCreateEntity={false}
    />
  );
};
