import { EntityDetailViewProps, EntityEditFormProps } from "../common/entity/EntityInterfaces";
import { EntityEditForm, EntityEditFormPage } from "../common/entity/EntityEditForm";
import { DatasetForm } from "./Form/DatasetForm";
import { EntityTablePage } from "../common/entity/EntityTablePage";
import { DatasetsTable } from "./Table/DatasetsTable";
import { useEntityPermissions } from "../common/permissions/useEntityPermissions";
import { ManualUpload } from "./ManualUpload/ManualUpload";
import { EntityDetailPage, EntityDetailView } from "../common/entity/EntityDetailPage";
import { DatasetsDetail } from "./Dataset";
import { PageNotAllowed } from "../main/common/PageNotAllowed/PageNotAllowed";

// Add
export const DatasetAddForm = () => {
  const { canCreate } = useEntityPermissions({ entityTypeId: "datasets" });
  if (!canCreate) return <PageNotAllowed />;
  return <ManualUpload />;
};

// Detail
export const DatasetsDetailView = ({
  id,
  viewViewer,
  viewShared,
}: EntityDetailViewProps<"datasets"> & DatasetDetailPageProps) => {
  return (
    <EntityDetailView
      id={id}
      entityTypeId={"datasets"}
      filters={{
        includeRelations: true,
        includeCount: true,
        includeParsedMetadata: true,
        includeParsingState: true,
      }}
    >
      {(props) => <DatasetsDetail {...props} viewViewer={viewViewer} viewShared={viewShared} />}
    </EntityDetailView>
  );
};
export interface DatasetDetailPageProps {
  viewShared?: boolean;
  viewViewer?: boolean;
}

export const DatasetDetailsPage = ({ viewViewer, viewShared }: DatasetDetailPageProps) => {
  return (
    <EntityDetailPage<"datasets"> isIntId>
      {(id) => <DatasetsDetailView id={id} viewViewer={viewViewer} viewShared={viewShared} />}
    </EntityDetailPage>
  );
};

// Edit
export const DatasetEditForm = ({ id }: EntityEditFormProps<"datasets">) => {
  return (
    <EntityEditForm id={id} entityTypeId={"datasets"} filters={{}}>
      {(props) => <DatasetForm {...props} typeId={props.initialValues?.customType?.id} />}
    </EntityEditForm>
  );
};

export const DatasetEditFormPage = () => {
  return <EntityEditFormPage<"datasets"> isIntId>{(id) => <DatasetEditForm id={id} />}</EntityEditFormPage>;
};

export const DatasetClaimForm = ({ id }: EntityEditFormProps<"datasets">) => {
  return (
    <EntityEditForm id={id} entityTypeId={"datasets"} filters={{}} event="CLAIM">
      {(props) => <DatasetForm {...props} typeId={props.initialValues?.customType?.id} />}
    </EntityEditForm>
  );
};

export const DatasetClaimFormPage = () => {
  return <EntityEditFormPage<"datasets"> isIntId>{(id) => <DatasetClaimForm id={id} />}</EntityEditFormPage>;
};

// Detail
// export const DatasetsDetailView = ({ id }: EntityDetailViewProps<"datasets">) => {
//   return (
//     <EntityDetailView id={id} entityTypeId={"datasets"} filters={{}} hasPermission>
//       {(props) => <DatasetsDetail {...props} />}
//     </EntityDetailView>
//   );
// };

// export const DatasetDetailsPage = () => {
//   return <EntityDetailPage<"datasets"> isIntId>{(id) => <DatasetsDetailView id={id} />}</EntityDetailPage>;
// };
export const DatasetsTablePage = () => {
  return <EntityTablePage entityTypeId={"datasets"}>{(props) => <DatasetsTable {...props} />}</EntityTablePage>;
};
