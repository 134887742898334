import { useEffect, useState } from "react";
import { TrackToolType } from "../ViewerNavigation";

import { SerializedPipeline } from "../../../ProcessingPipeline/PipelineTypes";
import { Button, Dropdown, Label, MenuItem } from "react-bootstrap";
import { ShowPipeline } from "./ShowPipeline";
import { ShowTraces } from "./ShowTraces";

import styles from "./ProcessingTool.module.css";

const PipelineSelector = ({
  pipelines,
  selected,
  // setPipeline,
  maxHeight,
  setSelected,
}: {
  pipelines: Record<string, SerializedPipeline>;
  selected?: string;
  setSelected?: (id: string) => void;
  // setPipeline?: (pipeline: SerializedPipeline) => void;
  maxHeight?: number;
}) => {
  const [current, setCurrent] = useState<string>(Object.keys(pipelines)?.[0]);

  useEffect(() => {
    if (selected && selected !== current) {
      setCurrent(selected);
    }
  }, [selected]);

  return (
    <div>
      Pipeline:{" "}
      <Dropdown
        id={"pipeline-dropdown"}
        onSelect={(key: any) => {
          setCurrent(key);
          // if (setPipeline) setPipeline(pipelines[key]);
          if (setSelected) setSelected(key);
        }}
      >
        <Dropdown.Toggle>{pipelines[current].name}</Dropdown.Toggle>
        <Dropdown.Menu
          className={styles.scrollableMenu}
          style={{ maxHeight: maxHeight ?? (100 || 100), overflowY: "auto" }}
        >
          {Object.entries(pipelines).map(([id, pipeline]) => (
            <MenuItem key={id} eventKey={id} active={id === current}>
              <b>{pipeline.name}</b>
            </MenuItem>
          ))}
          {/* {tmp.map((id) => (
      <MenuItem key={id} eventKey={id}>
        {id}
      </MenuItem>
    ))} */}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export const ProcessingTool = ({
  setMinHeight,
  setMinWidth,
  pipelines,
  pipelineStates,
  changePipelineParameter,
  width,
  height,
  graphClick,
}: TrackToolType) => {
  const [pipeline, setPipeline] = useState<SerializedPipeline>(Object.values(pipelines)?.[0] ?? { errors: ["Error"] });
  const [showError, setShowError] = useState<boolean>(pipeline.errors.length > 0);
  const [showWarning, setShowWarning] = useState<boolean>(false);
  const [hintWarning, setHintWarning] = useState<boolean>(false);

  // useEffect(() => {
  //   console.log("> ProcessingTool changePipelineParameter")
  // }, [changePipelineParameter])

  useEffect(() => {
    if (setMinHeight) setMinHeight(430);
    if (setMinWidth) setMinWidth(600);
  }, []);

  useEffect(() => {
    const id = pipeline?.id;
    if (!(id in pipelines)) {
      if (Object.keys(pipelines).length < 1) setPipeline({ errors: ["Error"] } as SerializedPipeline);
      else if (!(pipeline.id in pipelines)) setPipeline(Object.values(pipelines)[0]);
    } else setPipeline(pipelines[id]);

    // for debug
    // const p = Object.values(pipelines)
    //   .filter((p) => p.name === "windowFunction")
    //   .shift();
    // // console.log("pipelines", Object.keys(pipelines), p);
    // if (p) setPipeline(p);
  }, [pipelines]);

  useEffect(() => {
    setShowError(pipeline.errors.length > 0);
  }, [pipeline.errors]);

  // useEffect(() => {
  //   // const p = (Object.values(pipelines)?.[0].pipeline?.[0] as any)?.parameter;
  //   // if (p) console.log("tpipelines", Object.keys(p));
  //   const p = (pipeline.pipeline as any)?.parameter;
  //   if (p) console.log("tpipelines", Object.keys(p));
  //   console.log("pipeline", pipeline);
  // }, [pipelines, pipeline]);

  // const pipeline = Object.values(pipelines)?.[0];

  if (Object.keys(pipelines).length < 1) return <ShowTraces errors={["No pipelines"]} warnings={[]} />;

  return (
    <div
      className={styles.main}
      style={{ width: width, height: height }}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <div>
        <div className={styles.header}>
          <PipelineSelector
            pipelines={pipelines}
            selected={pipeline.id}
            // setPipeline={setPipeline}
            setSelected={(id: string) => {
              if (pipelines[id]) setPipeline(pipelines[id]);
            }}
            maxHeight={height ? height / 2 : undefined}
          />
          <div className={styles.rightButtons}>
            <Button
              bsStyle="success"
              bsSize="xs"
              onClick={() => {
                changePipelineParameter([{ pipelineId: pipeline.id, settings: { save: true } }]);
              }}
            >
              <span className="glyphicon glyphicon-save" /> Save settings
            </Button>
            {/* <div style={{ height: 3 }} />
            <Button
              bsStyle="danger"
              bsSize="xs"
              onClick={() => {
                changePipelineParameter([{ pipelineId: pipeline.id, settings: { reset: true } }]);
              }}
            >
              <span className="glyphicon glyphicon-save" /> Reset settings
            </Button> */}
          </div>
        </div>
        {/* <br /> */}
        {pipeline.warnings.length > 0 ? (
          <span>
            {!showWarning ? (
              <Label
                bsStyle="warning"
                onMouseEnter={() => setHintWarning(true)}
                onMouseLeave={() => setHintWarning(false)}
                onClick={() => {
                  if (!showError) setShowWarning(true);
                }}
              >
                Parsing warning
              </Label>
            ) : (
              <Label bsStyle="default" onClick={() => setShowWarning(false)}>
                Show pipeline
              </Label>
            )}
            &nbsp;
          </span>
        ) : null}
        {pipeline.errors.length > 0 ? <Label bsStyle="danger">Parsing error</Label> : null}
      </div>
      {showError || showWarning || hintWarning ? (
        <ShowTraces errors={pipeline.errors} warnings={pipeline.warnings} />
      ) : (
        <ShowPipeline
          pipeline={pipeline}
          pipelineStates={pipelineStates}
          graphClick={graphClick}
          changePipelineParameter={changePipelineParameter}
        />
      )}
    </div>
  );
};
