import produce from "immer";

import { Pipeline } from "../ProcessingPipeline/Pipeline";
import { trackDataType } from "../SpectrumViewer/ViewerTrackType";
import {
  Annotation,
  copyViewerType,
  datasetType,
  Datatrack,
  HierarchyNode,
  InternalParameter,
  ParameterList,
  ParsingStates,
  pipelineNodeState,
  Track,
  trackDataList,
  trackSettingsList,
  viewerErrorType,
  viewerLogType,
  ViewerSettings,
  viewerStateType,
  viewerTrackList,
  viewerTrackParameterType,
  viewerTrackType,
  viewerType,
  viewerTypeProperties,
  viewerTypeRecord,
  viewPortType,
} from "./ViewerLayoutTypes";
import { getDatasetTracks, initParameterList, needUpdate, updateCopy } from "./ViewerLayoutUtils";
import { trackState, viewerModeType } from "./ViewerTypes";

export type ActionType = {
  type:
    | "setDatasets"
    | "setDatasetParameter"
    | "setInternalParameters"
    | "updateInternalParameters"
    | "updateParameter"
    | "setTracks"
    | "setTrackState"
    | "setMultipleTrackStates"
    | "setTracksHierarchy"
    | "addTrack"
    | "addTracks"
    | "setDatatracks"
    | "setDatatracksLevels"
    | "setActiveTracks"
    | "setViewerTrackData"
    | "setMultipleViewerTrackData"
    | "setViewerAnnotations"
    | "incrementCount"
    | "setViewerTrackParameter"
    | "setMultipleTrackSettings"
    | "setTracksToZoom"
    | "setViewerMode"
    | "setSelectedTracks"
    | "setActiveViewer"
    | "setViewPort"
    | "storePipelineParmeter"
    | "setPipelines"
    | "updatePipelineStatus"
    | "updatePipeline"
    | "setPipelineStatus"
    | "setViewerSettings"
    | "setViewerStatus"
    | "addToDatasetLogs";

  datasets?: datasetType[];
  parameter?: ParameterList;
  internalParameters?: InternalParameter[];
  tracks?: Track[];
  datatracks?: Datatrack[];
  tracksHierarchy?: HierarchyNode;
  track?: Track;
  state?: trackState;
  states?: { id: string; state: trackState }[];
  viewerTracks?: viewerTrackType[];
  viewerTrackList?: viewerTrackList;
  annotations?: Annotation[];
  viewerTrackParameter?: viewerTrackParameterType;
  viewerTrackParameters?: viewerTrackParameterType[];
  trackSettingsList?: Partial<trackSettingsList>;
  count?: number;
  id?: string;
  ids?: string[];
  data?: trackDataType;
  dataList?: trackDataList;
  viewerMode?: viewerModeType;
  viewerType?: viewerType;
  autoZoom?: boolean;
  activeTracks?: { id: string; active: boolean }[];
  pipelines?: Pipeline[];
  viewPort?: viewPortType;
  pipelineState?: pipelineNodeState[];
  viewerSettings?: ViewerSettings;
  viewerStatus?: viewerErrorType;
  viewerLogs?: viewerLogType[];
  pipelineParamter?: any[];
};

export const ViewerStateReducer = (state: viewerStateType, action: ActionType): viewerStateType => {
  switch (action.type) {
    case "setDatasets":
      return produce(state, (next) => {
        if (action.datasets !== undefined) {
          next.viewerStatus.status =
            Object.values(state.datasets).every((d) => d.parsingState !== ParsingStates.ParsedSuccessfully) &&
            action.datasets.every((d) => d.parsingState !== ParsingStates.ParsedSuccessfully)
              ? "failed"
              : "success";

          const logs = Object.fromEntries(Object.values(state.datasets).map((d) => [d.id, d.parserLogs]));
          action.datasets.forEach((dataset) => {
            if (state.datasets && dataset.id in state.datasets) {
              next.activeViewer = undefined;
            }
            next.datasets[dataset.id] = dataset;
            logs[dataset.id] = dataset.parserLogs;
          });

          next.parserLogs = Object.values(logs).flat();
        }
      });
    case "addTrack":
    case "addTracks":
      return produce(state, (next) => {
        if (action.track !== undefined || action.tracks !== undefined) {
          const viewerTypes: Record<viewerType, viewerTypeRecord> = copyViewerType(state.viewerTypes);
          const tracks = action.tracks ?? [];
          if (action.track !== undefined) tracks.push(action.track);
          const trackIndex: Record<string, Record<number, string>> = {};
          for (let t of tracks) {
            const track: Track = Object.assign({}, t);
            // console.log("track", track);

            if (!(track.type in viewerTypes)) {
              viewerTypes[track.viewer] = viewerTypeProperties[track.viewer];
            }
            if (needUpdate(state.viewerTypes, viewerTypes)) next.viewerTypes = viewerTypes;

            if (!(track.id in state.tracks) && state.viewerMode.autoZoom) next.viewerMode.zoomUpdate = true;
            next.tracks[track.id] = track;

            if (track.datasetId in state.datasets) {
              if (!(track.datasetId in trackIndex))
                trackIndex[track.datasetId] = Object.fromEntries(
                  Object.entries(state.datasets[track.datasetId].trackIndex)
                );

              trackIndex[track.datasetId][track.index] = track.id;

              next.datasets[track.datasetId].tracks.push(track.id);
            }
          }
          Object.entries(trackIndex).forEach(([datasetId, index]) => (next.datasets[datasetId].trackIndex = index));
        }
      });
    case "setTrackState":
      return produce(state, (next) => {
        if (action.state && action?.id && action.id in state.tracks) next.tracks[action.id].state = action.state;
      });
    case "setMultipleTrackStates":
      return produce(state, (next) => {
        if (action.states)
          action.states.forEach((trackState) => {
            // console.log("set", trackState.id, trackState.state, trackState.id in state.tracks);
            if (trackState.id in state.tracks) next.tracks[trackState.id].settings.state = trackState.state;
          });
      });
    case "setTracks":
      return produce(state, (next) => {
        if (action.tracks !== undefined) {
          let viewerTypes: Record<viewerType, viewerTypeRecord>;
          // if (action?.tracks.length < 1) next.noTracks = true;
          if (action?.datasets) {
            viewerTypes = {} as Record<viewerType, viewerTypeRecord>;
            action?.datasets.forEach((dataset) => {
              const ids = getDatasetTracks(Object.values(state.tracks), dataset);
              const tracks = Object.entries(state.tracks).filter(([id]) => id in ids);
              next.tracks = Object.fromEntries(tracks);
              tracks.forEach(([, track]) => {
                if (!(track.viewer in viewerTypes)) viewerTypes[track.viewer] = viewerTypeProperties[track.viewer];
              });
            });
          } else viewerTypes = copyViewerType(state.viewerTypes);

          if (state.noTracks === undefined) next.noTracks = action.tracks.length < 1;

          action.tracks.forEach((track) => {
            if (!(track.viewer in viewerTypes)) viewerTypes[track.viewer] = viewerTypeProperties[track.viewer];
            if (state.activeViewer && track.viewer !== state.activeViewer) {
              track = Object.assign({}, track);
              track.settings = Object.assign({}, track.settings);
              track.settings.active = false;
            }
            next.tracks[track.id] = track;
          });

          if (action.tracksHierarchy) next.tracksHierarchy = action.tracksHierarchy;
          if (action.datatracks)
            action.datatracks.forEach((track) => {
              next.datatracks[track.id] = track;
            });

          if (action?.ids === undefined) action.ids = [];
          if (action?.id !== undefined) action.ids.push(action.id);
          if (action.ids.length > 0) next.tracksToZoom = action?.ids as string[];

          if (needUpdate(Object.keys(state.viewerTypes), Object.keys(viewerTypes))) next.viewerTypes = viewerTypes;
        }
      });
    case "setDatatracksLevels":
      return produce(state, (next) => {
        if (action.datatracks !== undefined) {
          action.datatracks.forEach((track) => {
            if (track.id in state.datatracks) next.datatracks[track.id].levels = track.levels;
          });
        }
      });
    case "setDatatracks":
      return produce(state, (next) => {
        if (action.viewerTrackList !== undefined) {
          // const parameters: Parameter[] = state?.parameter.slice() || [];
          action.viewerTrackList.forEach((item) => {
            // console.log("item", item.trackId, item.trackId in state.tracks);
            // if (item.trackId in state.tracks) {
            //   const viewerTracks = item.viewerTracks.map((track) => track.id);

            //   const datasetId: string = state.tracks[item.trackId].datasetId;
            //   const trackIndex: Record<number, string> = state.datasets[datasetId].trackIndex;
            // }
            item.viewerTracks.forEach((track) => (next.viewerTracks[track.id] = track));
            next.updateParameter = true;
          });
        }
      });
    case "setActiveTracks":
      return produce(state, (next) => {
        if (action.activeTracks !== undefined) {
          action.activeTracks.forEach((v) => {
            if (v.id in state.tracks && state.tracks[v.id].settings.active !== v.active)
              next.tracks[v.id].settings.active = v.active;
          });
        }
      });
    case "setMultipleTrackSettings":
      return produce(state, (next) => {
        if (action.trackSettingsList !== undefined) {
          action.trackSettingsList.forEach((settings) => {
            // console.log("=>", state.viewerTracks[parameter.id].parameter.contours, parameter.parameter.contours);
            // console.log("=>", state.viewerTracks[parameter.id].parameter, parameter.parameter)
            // console.log(settings.settings.color, "=>", state.tracks[settings.id].settings.color);
            // console.log("update?", needUpdate(state.tracks[settings.id].settings, settings.settings));
            if (
              settings &&
              settings.id in state.tracks &&
              needUpdate(state.tracks[settings.id].settings, settings.settings)
            ) {
              Object.entries(settings.settings).forEach(([k, v]: [any, any]) => {
                (next.tracks[settings.id].settings as any)[k] = v;
                // console.log("orig", k, state.tracks[parameter.id].parameter[k as keyof viewerTrackParameterType]);
                // console.log("kv", k, (state.tracks[settings.id].settings as any)[k], "->", v);
                if (k === "visible" && (state.tracks[settings.id].settings as any)[k] !== v)
                  next.viewerMode.zoomUpdate = true;
                else if (k === "normalize") next.viewerMode.zoomUpdate = true;
                // console.log(
                //   state.viewerTracks[parameter.id].label,
                //   "-> param",
                //   k + ":",
                //   state.viewerTracks[parameter.id].parameter[k as keyof viewerTrackParameterType],
                //   v
                // );
              });
            }
          });
        }
      });
    case "setViewerTrackData":
      return produce(state, (next) => {
        if (action.data !== undefined) {
          // console.log(action.id, "viewer tracks data", action.data);
          if ((action?.id || "") in state.viewerTracks) {
            // console.log(" ==>");
            next.viewerTracks[action?.id || ""].data = action.data;
          }
        }
      });
    case "setMultipleViewerTrackData":
      return produce(state, (next) => {
        if (action.dataList !== undefined) {
          action.dataList.forEach((data) => {
            if (data.viewerTrackId in state.viewerTracks) {
              if (data.data) {
                next.viewerTracks[data.viewerTrackId].data = data.data;
                next.viewerTracks[data.viewerTrackId].state = trackState.ready;
              } else {
                next.viewerTracks[data.viewerTrackId].state = trackState.failed;
              }
            }
          });
          next.viewerMode.zoomUpdate = true;
        }
      });
    case "setViewerTrackParameter":
      if (action?.ids === undefined) action.ids = [];
      if (action?.id !== undefined) action.ids.push(action.id);

      const viewerTrackParameters = action?.viewerTrackParameters || [];
      if (action?.viewerTrackParameter !== undefined) viewerTrackParameters.push(action.viewerTrackParameter);

      return produce(state, (next) => {
        // console.log("setViewerTrackParameter", action.viewerTrackParameter);

        if (viewerTrackParameters.length > 0) {
          action.ids?.forEach((id, i) => {
            if (needUpdate(state.viewerTracks[id].parameter, action.viewerTrackParameter)) {
              const parameter: viewerTrackParameterType =
                viewerTrackParameters[i < viewerTrackParameters.length ? i : viewerTrackParameters.length - 1];
              Object.keys(parameter).forEach((param) => {
                if (param in state.viewerTracks[id].parameter) {
                  (next.viewerTracks[id] as any).parameter[param] = parameter[param as keyof viewerTrackParameterType];
                }
              });
            }
          });
        }
      });
    case "setSelectedTracks":
      return produce(state, (next) => {
        if (action.ids && needUpdate(state.selectedTracks, action.ids)) next.selectedTracks = action.ids as string[];
      });
    case "setInternalParameters":
      return produce(state, (next) => {
        if (action.id !== undefined && action.internalParameters !== undefined)
          next.internalParameters = action.internalParameters;
      });
    case "updateInternalParameters":
      return produce(state, (next) => {
        if (action.id !== undefined && action.internalParameters !== undefined) {
          const [p, update] = updateCopy(state.internalParameters, action.internalParameters);
          if (update) next.internalParameters = p;
        }
      });
    case "setDatasetParameter":
      return produce(state, (next) => {
        if (action.id !== undefined && action.parameter && needUpdate(state.parameter, action.parameter)) {
          if (state.noParameters === undefined) next.noParameters = action.parameter?.content.length < 1;

          next.parameter = action.parameter;
          next.updateParameter = true;
        }
      });
    case "updateParameter":
      return produce(state, (next) => {
        const parameter = initParameterList(state.parameter);
        next.parameter = parameter;
        next.updateParameter = false;
      });
    case "setViewerAnnotations":
      return produce(state, (next) => {
        // console.log("annotation", action.id, action.annotations);
        if (action.id !== undefined && action.annotations) {
          next.annotations = action.annotations;
        }
      });
    case "setTracksToZoom":
      if (action?.ids === undefined) action.ids = [];
      if (action?.id !== undefined) action.ids.push(action.id);
      // console.log("setTracksToZoom", action?.ids);
      return produce(state, (next) => {
        next.tracksToZoom = action?.ids as string[];
      });
    case "setViewerMode":
      return produce(state, (next) => {
        if (action.viewerMode && needUpdate(state.viewerMode, action.viewerMode)) {
          next.viewerMode = action.viewerMode;
        }
      });
    case "incrementCount":
      return produce(state, (next) => {
        if (action.count !== undefined) next.count += action.count;
      });
    case "setActiveViewer":
      return produce(state, (next) => {
        if (action.viewerType !== undefined && state.activeViewer !== action.viewerType) {
          next.activeViewer = action.viewerType;
          next.viewerCalls[action.viewerType]++;
          Object.values(state.tracks).forEach((track) => {
            next.tracks[track.id].settings.active = track.viewer === action.viewerType;
          });
        }
      });
    case "setViewPort":
      return produce(state, (next) => {
        if (action.viewPort !== undefined) next.viewPort = action.viewPort;
      });
    case "storePipelineParmeter":
      return produce(state, (next) => {
        if (action?.pipelineParamter) next.pipelineParamter = action.pipelineParamter;
      });
    case "setPipelines":
      return produce(state, (next) => {
        if (action?.pipelines) {
          if (!next.pipelines) next.pipelines = {};
          action.pipelines.forEach((pipe) => {
            const pipelines: Record<string, Pipeline> = {};
            pipelines[pipe.id] = pipe;
            next.pipelines = pipelines;

            next.viewerPipelines[pipe.id] = pipe.serialize();
            const states = pipe.getNodeState();
            states.forEach((s) => (next.pipelineStates[s.id] = s));
          });
        }
      });
    case "updatePipelineStatus":
      return produce(state, (next) => {
        const ids: string[] = action?.ids ?? [];
        if (action?.id) ids.push(action.id);
        ids.forEach((id) => {
          if (state.pipelines && id in state.pipelines) {
            const m = state.pipelines[id].getNodeState();
            m.forEach((st) => {
              if (needUpdate(state.pipelineStates[st.id], st)) {
                const tmp = st.state ?? state.pipelineStates[st.id].state;
                next.pipelineStates[st.id] = st;
                next.pipelineStates[st.id].state = tmp; // keep original run state if nothing is set
              }
            });
          }
        });
      });
    case "updatePipeline":
      return produce(state, (next) => {
        const ids: string[] = action?.ids ?? [];
        if (action?.id) ids.push(action.id);
        ids.forEach((id) => {
          if (state.pipelines && id in state.pipelines) {
            const s = state.pipelines[id].serialize();
            // console.log("update", state.pipelines[id].name, "->", s);
            if (needUpdate(state.viewerPipelines[id], s)) {
              Object.entries(s).forEach(([k, v]) => {
                (next.viewerPipelines[id] as any)[k] = v;
              });
            }
          }
        });
      });
    case "setPipelineStatus":
      if (!action.pipelineState) action.pipelineState = [];
      return produce(state, (next) => {
        action.pipelineState?.forEach((s) => {
          let r = {} as pipelineNodeState;
          if (s.id in state.pipelineStates) Object.assign(r, state.pipelineStates[s.id]);
          // console.log("STATE", s.id, r, s);
          Object.assign(r, s);
          next.pipelineStates[s.id] = r;
        });
      });
    case "setViewerSettings":
      if (!action.viewerSettings) action.viewerSettings = {};
      return produce(state, (next) => {
        next.viewerSettings = action.viewerSettings as ViewerSettings;
      });
    case "setViewerStatus":
      return produce(state, (next) => {
        if (action.viewerStatus) next.viewerStatus.status = action.viewerStatus;
        if (action.viewerLogs) {
          const log = state.viewerStatus.logs.slice();
          log.push(...action.viewerLogs);
          next.viewerStatus.logs = log;
        }
      });
    case "setTracksHierarchy":
      return produce(state, (next) => {
        if (action.tracksHierarchy) next.tracksHierarchy = action.tracksHierarchy;
      });
    case "addToDatasetLogs":
      return produce(state, (next) => {
        if (action.viewerLogs) next.parserLogs = [...action.viewerLogs, ...state.parserLogs];
      });

    default:
      throw new Error(`Unknown reducer state '${action.type}'`);
  }
};
