import React, { useCallback, useState } from "react";
import { TreeElement, TreeElementBase } from "../../../tiles/Files/Files";
import styles from "./Folder.module.css";
import { LucideIcon } from "../../../../common/icon/LucideIcon";
import { formatBytes } from "./helpers/FileHelpers";

interface Props<T extends TreeElementBase> {
  label: string;
  item: TreeElement<T>;
  level: number;
  children: React.ReactNode;
  onFileClick?: (item: TreeElement<T>) => void;
  onFolderClick?: (item: TreeElement<T>) => void;
  fileControls?: (item: TreeElement<T>) => React.ReactNode;
  folderControls?: (item: TreeElement<T>) => React.ReactNode;
  fixedControls?: (item: TreeElement<T>) => React.ReactNode;
  rowClassName?: (item: TreeElement<T>) => string;
  collapsed?: boolean;
}

export const Folder = <T extends TreeElementBase>({
  label,
  item,
  level,
  children,
  onFileClick,
  onFolderClick,
  fileControls,
  folderControls,
  fixedControls,
  rowClassName,
  collapsed,
}: Props<T>) => {
  const [isCollapsed, setIsCollapsed] = useState(collapsed ?? false);
  const entityIcon = useCallback(() => {
    return item.hasChildren.length > 0 ? (
      isCollapsed ? (
        <LucideIcon name="folder-minus" />
      ) : (
        <LucideIcon name="folder-plus" />
      )
    ) : (
      <LucideIcon name="file" />
    );
  }, [isCollapsed, item.hasChildren.length]);

  const entityStyle = useCallback(
    () => (item.hasChildren.length > 0 ? styles.container_row_folder : styles.container_row_file),
    [item.hasChildren.length]
  );

  // Max recursion level
  if (level > 10) return null;

  return (
    <div className={styles.container} key={`section-${item.id}`}>
      <div className={`${styles.container_row} ${entityStyle()} ${rowClassName?.(item)}`}>
        <div
          className={styles.container_head}
          style={{ ...(!onFileClick ? { cursor: "default" } : {}) }}
          onClick={() => {
            setIsCollapsed(() => !isCollapsed);
            if (item.hasChildren.length === 0) {
              onFileClick?.(item);
            } else {
              onFolderClick?.(item);
            }
          }}
        >
          <div className={styles.container_icon}>{entityIcon()}</div>
          <div className={styles.container_label}>
            <span>{item.name}</span>
          </div>
        </div>

        <div className={styles.container_controls}>
          {item.hasChildren.length === 0 ? <>{fileControls?.(item)}</> : <>{folderControls?.(item)}</>}
        </div>
        <div className={styles.container_controls_fixed}>
          {fixedControls?.(item)}
          <div className={styles.container_controls_fixed_size}>
            {item.size !== undefined && formatBytes(item.size)}
          </div>
        </div>
      </div>
      {isCollapsed && children && (
        <div className={styles.container_content}>
          <div className={styles.container_content_child}>{children}</div>
        </div>
      )}
    </div>
  );
};
