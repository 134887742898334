import { Component } from "react";
import { ServerError } from "../../common/helperfunctions/ApiError";
import { PageError } from "../common/PageError/PageError";
import { FullScreenWrapper } from "../../common/wrapper/FullScreenWrapper/FullScreenWrapper";

interface State {
  error?: any; // Could be an exception thrown in synchronous code or could be a rejection reason from a Promise, we don't care
}

export class ErrorBoundary extends Component<State> {
  private promiseRejectionHandler = (event: PromiseRejectionEvent) => {
    this.setState({
      error: event.reason,
    });
  };

  public state: State = {
    error: null,
  };

  public static getDerivedStateFromError(error: Error): State {
    // Update state so the next render will show the fallback UI.
    return { error: error };
  }

  public componentDidCatch(error: Error) {
    console.error("Uncaught error:", error);
  }

  componentDidMount() {
    // Add an event listener to the window to catch unhandled promise rejections & stash the error in the state
    window.addEventListener("unhandledrejection", this.promiseRejectionHandler);
  }

  componentWillUnmount() {
    window.removeEventListener("unhandledrejection", this.promiseRejectionHandler);
  }

  public render() {
    if (this.state.error) {
      const error = this.state.error;
      let errorDesc;
      let errorMessage;
      let errorCode: number | string | undefined;
      let stackTrace: string | undefined;
      if (error instanceof ServerError) {
        errorCode = error.status;
        errorMessage = error.name;
        errorDesc = error.description;
        stackTrace = error.details;
      } else {
        errorMessage = error.message;
      }

      return (
        <FullScreenWrapper>
          <PageError code={errorCode} errorDesc={errorDesc} errorMessage={errorMessage} stackTrace={stackTrace} />
        </FullScreenWrapper>
      );
    } else {
      return this.props.children;
    }
  }
}
