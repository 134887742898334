import React from "react";
import { somethingWentWrongLabel } from "./ConstantLabels";

export function ErrorFallback({ error, componentStack }: any) {
  return (
    <>
      <div className="alert alert-danger">
        <p> {somethingWentWrongLabel}: </p>
      </div>
      <div role="alert">
        <pre>{error.message}</pre>
        <pre>{componentStack}</pre>
      </div>
    </>
  );
}
