import { useMemo } from "react";
import styles from "./SpreadSheet.module.css";
import { CellElementProps, CellProps } from "./SpreadSheetTypes";

export const TopLeftCornerCell = ({
  index,
  style,
  rowIndex,
  columnIndex,
  TopLeftCornerElement,
}: CellProps & { TopLeftCornerElement?: (props: CellElementProps) => JSX.Element }) => {
  const width = useMemo(() => (style?.width ? parseInt(style?.width?.toString()) : 0), [style?.width]);
  const height = useMemo(() => (style?.height ? parseInt(style?.height?.toString()) : 0), [style?.height]);

  const content = useMemo(
    () =>
      TopLeftCornerElement ? (
        <TopLeftCornerElement
          width={width}
          height={height}
          columnIndex={columnIndex}
          rowIndex={rowIndex}
          content={{
            value: "",
            type: "string",
            row: rowIndex,
            column: columnIndex,
            settings: {},
          }}
          settings={{}}
        />
      ) : (
        <div className={styles.TopLeftGrid} />
      ),
    [TopLeftCornerElement, width, height, columnIndex, rowIndex]
  );

  return (
    <div key={index} className={styles.FixedGridCell} style={style}>
      <div className={styles.FixedGridCellInner} style={{ width: width, height: height }}>
        {content}
      </div>
    </div>
  );
};
