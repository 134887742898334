import { generateUid } from "../tools/UID/UID";
import { Datatrack, pipelineNodeState, Track } from "../ViewerLayout/ViewerLayoutTypes";
import { updateCopy } from "./../ViewerLayout/ViewerLayoutUtils";
import { CachedPipelinConnector } from "./CachedPipelinConnector";
import { parameterType, PipelineComponentGenerator } from "./Commands/PipelineComponentGenerator";
import { TrackAdder } from "./Commands/TrackAdder";
import { ParameterChecker } from "./ParameterChecker";
import { PipelineConnector } from "./PipelineConnector";
import { PipelineNode } from "./PipelineNode";
import {
  commandTypes,
  initPipelineSettings,
  initType,
  parameterTypes,
  PipelineCommand,
  PipelineSettings,
  range,
  SerializedPipeline,
  trackListType,
} from "./PipelineTypes";
import { StackTrace, StackTraces } from "./StackTrace";

type internalParameterType = Record<string, any>;

type commandType = {
  name?: string;
  command?: commandTypes;
  parameter?: Record<string, parameterTypes>;
  selected?: boolean;
};

type connectorType = {
  name?: string;
  mapping?: [number, number][];
  copy?: boolean;
  cached?: boolean;
};

// type nodeResult = { node?: PipelineNode; errors: StackTraces; warnings: StackTraces };
// type parameterType = Record<string, parameterTypes>;

type pipelineResultType = { after: string | undefined; replace: string | undefined; tracks: trackListType };

type dispatchType = (states: pipelineNodeState[]) => void;
export class Pipeline {
  name: string = "Pipeline";
  id: string = "";
  index: number;
  // warnings: StackTraces;
  // errors: StackTraces;
  nodes: Record<string, PipelineNode>;
  previousNode: Record<string, { node: string; connector: string; cached: boolean }>;

  connectors: Record<string, PipelineConnector>;
  start: string | undefined;
  settings: PipelineSettings;
  runs: number;
  trackIDs: Set<string>;
  recalculated: boolean;

  runWarnings: StackTraces;
  runErrors: StackTraces;
  parseWarnings: StackTraces;
  parseErrors: StackTraces;

  fatalErrors: StackTraces;

  onRun?: dispatchType;

  constructor(input?: any) {
    // this.next = () => undefined;
    // this.init(initPipelineNode({ name: "PipelineParser" } as PipelineNode));
    // this.runWarnings = [];
    // this.runErrors = [];
    this.nodes = {};
    this.previousNode = {};
    this.connectors = {};
    this.index = -1;
    this.runs = 0;
    this.settings = initPipelineSettings();
    this.id = generateUid();
    this.trackIDs = new Set<string>();
    this.recalculated = false;
    this.parseErrors = new StackTraces();
    this.parseWarnings = new StackTraces();
    this.runErrors = new StackTraces();
    this.runWarnings = new StackTraces();

    this.fatalErrors = new StackTraces();

    const t = typeof input;
    if (input === undefined || input === null) this.initConstructor();
    else if (t === "string") this.constructor_string(input);
    else if (t === "object" && !Array.isArray(input)) this.objectConstructor(input);

    this.updatePreviousNode();
  }

  updatePreviousNode() {
    this.previousNode = {};
    Object.values(this.nodes).forEach((node) => {
      node.connections
        // .map((id) => this.connectors[id]?.next)
        .forEach((id) => {
          const connector = this.connectors?.[id];
          if (connector?.next)
            this.previousNode[connector.next] = { node: node.id, connector: connector.id, cached: connector.cached };
        });
    });

    // Object.entries(this.previousNode).forEach(([k, v]) =>
    //   console.log(`${this.nodes[k].name}(${k}) <- ${this.nodes[v.node].name}(${v.node})`)
    // );
  }

  get warnings(): StackTraces {
    return new StackTraces([...this.parseWarnings.traces, ...this.runWarnings.traces]);
  }

  get errors(): StackTraces {
    return new StackTraces([...this.parseErrors.traces, ...this.runErrors.traces, ...this.fatalErrors.traces]);
  }

  // init(node: PipelineNode) {
  //   this.name = node.name;
  //   this.id = node.id;
  //   this.command = node.command;
  //   this.next = node.next;
  //   this.switcher = node.switcher;
  //   this.influx = node.influx;
  //   this.index = node.index;
  // }
  reset() {
    this.resetParsing();
    this.resetRun();
  }

  resetRun() {
    this.runWarnings = new StackTraces();
    this.runErrors = new StackTraces();
    if (this.nodes) Object.values(this.nodes).forEach((node) => node.reset());
  }

  resetParsing() {
    this.runWarnings = new StackTraces();
    this.runErrors = new StackTraces();
    if (this.nodes) Object.values(this.nodes).forEach((node) => node.reset());
  }

  initConstructor() {
    this.copyConstructor({
      name: "Node",
      id: generateUid(),
      start: undefined,
      nodes: {},
      connectors: {},
      index: -1,
      settings: initPipelineSettings(),
    } as Pipeline);
  }

  constructor_string(input: string) {
    try {
      this.objectConstructor(JSON.parse(input));
    } catch (e) {
      console.log("ERROR", e);
    }
  }

  isWritable<T extends Object>(obj: T, key: keyof T) {
    const desc = Object.getOwnPropertyDescriptor(obj, key) || {};
    return Boolean(desc.writable);
  }

  update(target: any, source: any): any {
    const tt = typeof target;
    const ts = typeof source;

    if (tt !== ts) return source;

    if (tt === "object") {
      const tt = Array.isArray(target);
      const ts = Array.isArray(source);
      if (tt !== ts) return source;
      if (tt) {
        // Array
        // console.log(">>", target.length, source.length, source);

        // console.log("read", this.isWritable(target, 0));
        if (this.isWritable(target, 0)) {
          for (let i in source) {
            // console.log("read", i, "->", this.isWritable(target, i));
            target[i] = this.update(target[i], source[i]);
          }
        } else target = source;
      } else {
        // console.log("Object", Object.keys(source));
        for (let key of Object.keys(source)) {
          // console.log(key, "->", key in target);
          target[key] = this.update(target[key], source[key]);
        }
      }
    } else {
      return source;
    }
    return target;
  }

  setSettings(settings: PipelineSettings) {
    const s = Object.assign({}, this.settings);
    Object.assign(s, settings);
    this.settings = s;
  }

  getNodeByCommand(command: commandTypes): string[] {
    // console.log(
    //   ">>",
    //   Object.values(this.nodes).map((node) => node.command?.type == command)
    // );
    return Object.values(this.nodes)
      .filter((node) => node.command?.type === command)
      .map((node) => node.id);
  }

  setNodeParameter(id: string, parameters: parameterType) {
    if (!this.nodes?.[id]) return;
    const node = this.nodes[id];
    if (!node.command) return;
    const cmd = node.command;
    node.updated = true;

    // console.log("node", node.name, node.errors.toString());
    // cmd.reset();
    const checker = new ParameterChecker(cmd.parameterTypes);
    const s = cmd.serialize();
    // console.log("param", parameters);
    // console.log("param s", s.parameter);
    // Object.assign(s.parameter, parameters);
    // this.update(s.parameter, parameters);
    updateCopy(s.parameter, parameters);

    checker.createParameter(s.parameter || {});
    cmd.internalParameter = checker.parameter;
    this.parseErrors.clear(node.id);
    this.parseWarnings.clear(node.id);

    // console.log(">>", this.getExecutionStart()?.id);
    if (!this.getExecutionStart()) this.trackIDs.clear();

    // console.log("cmd", cmd.serialize().parameter?.param2);

    this.parseWarnings.addTracesWithMessage(cmd.warnings, {
      component: "PipelineParser.setNodeParameter",
      id: node.id,
      message: `Parameter change warning on '${cmd.name}'.`,
    });
    this.parseErrors.addTracesWithMessage(checker.errors, {
      component: "PipelineParser.setNodeParameter",
      id: node.id,
      message: `Parmeter change of '${cmd.name}' failed.`,
    });

    // console.log("checker", this.errors.toString({ id: node.id }));
    // console.log("checker", checker.warnings.toString(), checker.errors.toString());
  }

  commandString(name?: string, command?: string) {
    return (name ? `'${name}':` : "") + (command ? command : "unknown");
  }

  nodeString(index: number, name?: string, command?: string) {
    return name ? `'${name}'` : "Node" + index;
  }

  nodeStringFromNode(node?: PipelineNode, showCommand: boolean = false) {
    if (node)
      return (
        (node.name === "" ? "Node " + node.index : `${node.name}`) +
        (showCommand ? `(${node.command?.name ?? "null"})` : "")
      );
    return "null";
  }

  // constructor_object(commands: (commandType & connectorType)[]) {
  objectConstructor(pipeline: any) {
    // const fields = ["id", "index", "name", "nodes", "connectors", "start", "settings"];

    if ("pipeline" in pipeline) this.deserialize(pipeline);
    else this.copyConstructor(pipeline);
  }

  copyConstructor(pipeline: Pipeline) {
    initType(this, pipeline);
  }

  getNextElement(current: PipelineNode): { node?: PipelineNode; connection?: PipelineConnector } {
    const c = this.connectors[current.connections[current.switcher()]];
    let n: PipelineNode | undefined;
    if (c && c.next) n = this.nodes[c.next];

    return { connection: c, node: n };
  }

  getStart(): PipelineNode | undefined {
    if (this.start && this.start in this.nodes) return this.nodes[this.start];
    return undefined;
  }

  setParametersToNodes(parameters: Record<string, any>) {
    let current: PipelineNode | undefined = this.getStart();
    if (!current && Object.keys(this.nodes).length > 0) {
      return [];
    }

    // const result: any[] = [];
    let i = 0;

    while (current !== undefined) {
      if (current?.command) {
        // console.log("---current", current.name);
        const s = current.command.serializeParameters();
        // console.log("        =>", s.parameter);
        // console.log("        ->", parameters[i]);
        // console.log("        ##", s);
        // console.log("        ##", current.command.parameterTypes);
        Object.assign(s, parameters[i]);
        const checker = new ParameterChecker(current.command.parameterTypes);
        checker.createParameter(current.command.parameterSettings);
        checker.updateParameter(s ?? {});

        // console.log("update", checker.parameter);
        current.command.internalParameter = checker.parameter;
        i++;
      }

      const { node, connection } = this.getNextElement(current);
      if (!connection) break;
      // result.pipeline.pus

      // result.push(connection.serialize());
      current = node;
    }
  }

  serializeParameters() {
    let current: PipelineNode | undefined = this.getStart();
    if (!current && Object.keys(this.nodes).length > 0) {
      return [];
    }

    const result: any[] = [];

    while (current !== undefined) {
      // localTracks = current.run(localTracks);

      // console.log("current", current.id, this.warnings.toString({ id: current.id }));
      if (current.command) {
        const s = current.command?.serializeParameters();
        // console.log("param", current.command.internalParameter);
        // s.id = current.id;
        // s.commandName = s.name;
        // s.name = current.name;
        // if (this.settings.selectedNode === s.id) s.selected = true;
        result.push(s);
        // console.log("--- current", s);
        // if (s.id === selected) console.log("selected", s.id);
        // console.log("pipe", (s as any)?.parameter?.tracks?.value);
        // result.pipeline.push(s);
      } else result.push({});

      const { node, connection } = this.getNextElement(current);
      if (!connection) break;
      // result.pipeline.pus

      // result.push(connection.serialize());
      current = node;
    }

    return result;
  }

  // export type SerializedPipelineCommand = {
  //   command: string;
  //   name?: string;
  //   parameter?: Record<string, any>;
  // };

  // export type SerializedPipelineConnector = {
  //   command: string;
  //   name?: string;
  //   copy: boolean;
  //   mapping: [number, number][];
  // };
  serialize(): SerializedPipeline {
    const result: SerializedPipeline = {
      pipeline: [],
      id: this.id,
      version: "0.1",
      name: this.name,
      settings: this.settings,
      warnings: [],
      errors: this.fatalErrors.toStringList(),

      // warnings: this.parseWarnings.toStringList(),
      // errors: this.parseErrors.toStringList(),
    };

    let current: PipelineNode | undefined = this.getStart();
    if (!current && Object.keys(this.nodes).length > 0) {
      const e = new StackTrace({
        id: "",
        component: "PipelineSerializer",
        message: `Pipeline has no starting node.`,
      });
      result.errors.push(e.toString());
    }

    // result.nodeErrorCount = this.errors.length;
    // result.errors.push(...["Hallo", "du", "Wurm"]);
    // result.warnings.push(...["Hallo", "du", "Wurm"]);
    while (current !== undefined) {
      // localTracks = current.run(localTracks);

      // console.log("current", current.id, this.warnings.toString({ id: current.id }));
      if (current.command) {
        const s = current.command?.serialize();
        s.id = current.id;
        s.commandName = s.name;
        s.name = current.name;
        if (this.settings.selectedNode === s.id) s.selected = true;

        // console.log("--- current", s);
        // if (s.id === selected) console.log("selected", s.id);
        // console.log("pipe", (s as any)?.parameter?.tracks?.value);
        result.pipeline.push(s);
      } else result.pipeline.push({ command: commandTypes.empty, id: "" });

      const { node, connection } = this.getNextElement(current);
      if (!connection) break;
      // result.pipeline.pus

      result.pipeline.push(connection.serialize());
      current = node;
    }

    return result;
  }

  deserialize(pipeline: SerializedPipeline) {
    // const program: PipelineNode[] = [];
    this.resetParsing();
    // console.log("pipeline", pipeline);
    this.id = pipeline?.id ?? this.id;
    this.name = pipeline?.name ?? "Pipeline";
    this.settings = initPipelineSettings(pipeline.settings);
    const commands = pipeline?.pipeline ?? [];
    const connections: Record<string, connectorType> = {};
    let nodeId = "start";
    if (commands.length < 1)
      this.fatalErrors.create({
        id: "",
        component: "PipelineParser",
        message: `Pipeline is empty. (No commands specified)`,
      });

    let index = 0;
    for (let i = 0; i < commands.length; i++) {
      if (typeof commands[i] !== "object") continue;

      // const nodeId = generateUid();

      let { name, command, parameter, selected } = commands[i] as commandType;

      if (command === undefined) {
        command = commandTypes.empty;
        this.parseWarnings.create({
          id: nodeId,
          component: "PipelineParser",
          message: `Node ${index}:${name} does not specify a command. (Empty command set)`,
        });
        if (selected) this.settings.selectedNode = nodeId;
        // if (commands[i])
      }
      let connect;
      if ((command as string) === "connect") {
        connect = 1;
        let { name, mapping, copy, cached } = commands[i] as connectorType;
        connections[nodeId] = { name: name, mapping: mapping, copy: copy, cached: cached };
      } else if (!(command in commandTypes)) {
        this.parseWarnings.create({
          id: nodeId,
          component: "PipelineParser",
          message: `Unknown command '${command}' in node ${i}`,
        });
        command = commandTypes.empty;
      }

      // console.log(
      //   ">",
      //   "'" + name + "':",
      //   command +
      //     "(" +
      //     Object.entries(parameter)
      //       .map(([k, v]) => k + "=" + v)
      //       .join(", ") +
      //     ")"
      // );

      if (!connect) {
        // const n = this.createNode(name, command, parameter, index);
        // console.log("create", name, command, parameter);
        const n = PipelineComponentGenerator.CreateNode(name, command, parameter, index);
        if (n.node) {
          nodeId = n.node.id;
          this.nodes[nodeId] = n.node;
        }
        if (selected) this.settings.selectedNode = nodeId;

        this.parseWarnings.addTracesWithMessage(n.warnings, {
          component: "PipelineParser",
          id: nodeId,
          message: "",
        });

        this.parseErrors.addTracesWithMessage(n.errors, {
          component: "PipelineParser",
          id: nodeId,
          message: "",
        });

        index++;
      }
    }

    if (Object.keys(this.nodes).length > 0) {
      const nodes = Object.values(this.nodes).sort((a, b) => a.index - b.index);
      this.start = nodes[0].id;
      // console.log("nodes", nodes);
      for (let i = 1; i < nodes.length; i++) {
        const connectorId = generateUid();
        const iNode = nodes[i - 1];
        const jNode = nodes[i];
        // const c = this.createConnector(
        const c = PipelineComponentGenerator.CreateConnector(
          connectorId,
          connections?.[iNode.id]?.name ?? `Connector_${iNode.index}_${jNode.index}`,
          iNode,
          jNode,
          connections?.[iNode.id]?.mapping,
          connections?.[iNode.id]?.copy,
          connections?.[iNode.id]?.cached
        );

        this.parseWarnings.addTracesWithMessage(c.warnings, {
          component: "PipelineParser",
          id: jNode.id,
          message: `Warning connections nodes '${iNode.name}' and '${this.nodeString(i, jNode.name)}'`,
        });

        this.parseErrors.addTracesWithMessage(c.errors, {
          component: "PipelineParser",
          id: jNode.id,
          message: `Connecting nodes '${iNode.name}' and '${jNode.name}' failed.`,
        });

        if (c.connector) {
          // console.log("con", i, c.connector);
          iNode.connections = [c.connector.id];
          this.connectors[c.connector.id] = c.connector;
        }
      }
    }
  }

  connectTracks(tracks: (Track | undefined)[], connector?: PipelineConnector): (Track | undefined)[] {
    if (!connector || connector.outputNumber <= 0) return [];
    // console.log(
    //   ">>",
    //   connector.mapping,
    //   this.range(connector.outputNumber).map((i) => {
    //     const j = i in connector.mapping ? connector.mapping[i] : i;
    //     return tracks?.[j];
    //   })
    // );
    return range(connector.outputNumber).map((i) => {
      const j = i in connector.mapping ? connector.mapping[i] : i;
      return tracks?.[j];
    });
  }

  getNextNode(current: PipelineNode): { node?: PipelineNode; connection?: PipelineConnector } {
    const c = this.connectors[current.connections[current.switcher()]];
    let n: PipelineNode | undefined;
    if (c && c.next) n = this.nodes[c.next];

    return { connection: c, node: n };
  }

  setOnRun(onRun: dispatchType) {
    this.onRun = onRun;
  }

  getNodeState() {
    const states: pipelineNodeState[] = [];
    Object.values(this.nodes).forEach((node) => {
      const state: pipelineNodeState = {
        id: node.id,
        errors: this.errors.toStringList({ id: node.id }),
        warnings: this.warnings.toStringList({ id: node.id }),
        state: undefined,
      };
      states.push(state);
    });
    return states;
  }

  // setNodeState(dispatch: dispatchType) {
  //   const states = this.getNodeState();
  //   if (states.length > 0) dispatch(states);
  // }

  getRootNode(id: string): string | undefined {
    // if (this.nodes[id].updated) return undefined;
    const previous = this.previousNode[id];
    // this.nodes[id].updated = true;

    if (!previous) return undefined;
    if (previous.cached) return id;

    return this.getRootNode(previous.node);

    // let cached: string | undefined = previous.cached ? id : undefined;
    // const [r, c] = this.getRootNode(previous.node);
    // if (!cached) cached = c;
    // root = c ?? r;
    // return [root, cached];
  }

  isAncestorOf(ancestor: string, id: string): boolean {
    if (ancestor === id) return false;
    const previous = this.previousNode[id];
    // this.nodes[id].updated = true;

    if (!previous) return false;
    if (ancestor === previous.node) return true;

    return this.isAncestorOf(ancestor, previous.node);
  }

  getExecutionStart(): PipelineNode | undefined {
    // const previousNode: Record<string, { node: string; previous: string; connector: string }> = {};
    const updated: string[] = Object.values(this.nodes)
      .filter((node) => node.updated)
      // .filter((node) => node.name === "Step 4" || node.name === "Step 7")
      .map((node) => node.id);

    updated.reverse();

    const roots = updated.map((id) => this.getRootNode(id));
    const root = roots.filter((i) => !roots.some((j) => i && j && this.isAncestorOf(j, i))).shift();

    // console.log(
    //   "roots",
    //   roots.map((id) => this.nodes[id].name),
    //   "->",
    //   this.nodes[root ?? ""].name
    // );
    return root ? this.nodes[root] : undefined;
  }

  updateParameter(command: PipelineCommand) {
    if (!command.updatedParameter) return;
    const checker = new ParameterChecker(command.internalParameter);
    checker.updateParameter(command.updatedParameter);
    command.updatedParameter = {};

    this.runWarnings.addTracesWithMessage(checker.warnings, {
      component: "Pipeline",
      id: "",
      message: `Running pipeline '${this.name}'`,
    });

    this.runErrors.addTracesWithMessage(checker.errors, {
      component: "Pipeline",
      id: "",
      message: `Running pipeline '${this.name}' aborted.`,
    });
  }

  run(
    tracks: Record<string, Track>,
    datatracks: Record<string, Datatrack>,
    parameter: internalParameterType
  ): pipelineResultType[] {
    // const errors: errorType[] = [];
    // const warnings: warningType[] = [];
    if (this.parseErrors.length > 0) {
      this.recalculated = false;
      // this.state = "idle";
      return [];
    }

    this.resetRun();
    this.runs++;
    if (Object.keys(this.nodes).length < 1) {
      this.runWarnings.create({
        component: "Pipeline.run",
        id: "",
        message: `No commands in the pipeline.`,
      });
      // this.state = "idle";
      return [];
    }

    const globalTracks: Track[] = Object.values(tracks).sort((a, b) => a.index - b.index);
    let localTracks: trackListType = globalTracks.map((t) => {
      if (!t) return t;
      const r: any = Object.assign({}, t);
      r.internalParameter = parameter;
      return r;
    });
    // console.log("local Tracks", tracks);

    // const m: Record<number, number> = { 1: 0 };

    // const root = this.getExecutionStart() ?? this.getStart();

    // if (root) console.log("root", root.name, this.getStart()?.name);

    // let current: PipelineNode | undefined = this.getExecutionStart() ?? this.getStart();
    let current: PipelineNode | undefined = this.getStart();
    if (current && current.command?.type === commandTypes.trackSelector) {
      console.log("running command", current?.command?.name, globalTracks);

      const tracks = current.run(globalTracks as trackListType);

      if (current?.command) this.updateParameter(current.command);

      if (tracks.some((t) => this.trackIDs.has(t?.id as string))) {
        // check if input tracks changed since last execution
        current = this.getExecutionStart();
        if (current && this.previousNode[current.id]?.cached) {
          // if they didn't change check if a parameter was changed and start from the last cache
          // console.log("test", current.command?.type);
          const connection = this.connectors[this.previousNode[current.id].connector] as CachedPipelinConnector;
          localTracks = connection.getCache();
        } else {
          this.recalculated = false;
          // console.log("------------------");
          return [];
        }
      }
      tracks.forEach((t) => {
        if (t?.id) this.trackIDs.add(t.id);
      });
    }

    // let current: PipelineNode | undefined = this.getStart();
    // if (this.trackIDs.size > 0) current = this.getExecutionStart() ?? current;

    // // console.log("current", this.trackIDs.size);
    // // console.log("tracks", this.trackIDs);
    // if (current) {
    //   // console.log("RUN");
    //   if (current.command?.type === commandTypes.trackSelector) {
    //     const tracks = current.run(globalTracks as trackListType);
    //     if (tracks.some((t) => this.trackIDs.has(t?.id as string))) {
    //       this.recalculated = false;
    //       // console.log("------------------");
    //       return [];
    //     }
    //     tracks.forEach((t) => {
    //       if (t?.id) this.trackIDs.add(t.id);
    //     });
    //   } else if (this.previousNode[current.id]?.cached) {
    //     // console.log("test", current.command?.type);
    //     const connection = this.connectors[this.previousNode[current.id].connector] as CachedPipelinConnector;
    //     localTracks = connection.getCache();
    //   }
    // }
    // console.log(">>", this.trackIDs);

    if (this.onRun) {
      // console.log("onRun reset", this.runs);
      const states: pipelineNodeState[] = [];
      Object.values(this.nodes).forEach((node) => {
        const state: pipelineNodeState = {
          id: node.id,
          errors: this.errors.toStringList({ id: node.id }),
          warnings: this.warnings.toStringList({ id: node.id }),
          state: undefined,
        };
        states.push(state);
      });
      // if (states.length > 0) this.onRun(states);
    }

    const replacer: TrackAdder[] = [];
    this.recalculated = true;
    while (current !== undefined) {
      if (this.onRun && current.command?.type === commandTypes.trackSelector)
        this.onRun([{ id: current.id, state: "running" } as pipelineNodeState]);

      // console.log(`running node '${current.name}:${current.command?.name}'`);
      if (current.command?.type === commandTypes.trackAdder) replacer.push(current.command as TrackAdder);

      // console.log("running command", current?.command?.name, localTracks.length);
      localTracks = current.run(localTracks);
      // console.log("running command", localTracks.length);
      if (current?.command) this.updateParameter(current.command);

      // console.log(
      //   "tracks",
      //   localTracks.map((t) => t?.label)
      // );

      this.runWarnings.addTracesWithMessage(current.warnings, {
        component: "Pipeline",
        id: "",
        message: `Running pipeline '${this.name}'`,
      });

      this.runErrors.addTracesWithMessage(current.errors, {
        component: "Pipeline",
        id: "",
        message: `Running pipeline '${this.name}' aborted.`,
      });

      if (current.errors.length > 0) {
        if (this.onRun) this.onRun([{ id: current.id, state: "failed" } as pipelineNodeState]);
        break;
      }

      if (this.onRun) this.onRun([{ id: current.id, state: "ok" } as pipelineNodeState]);

      const { node, connection } = this.getNextNode(current);
      if (!connection) break;

      // if (current.command?.type === commandTypes.trackSelector) {
      //   if (localTracks.some((t) => this.trackIDs.has(t?.id as string))) {
      //     this.recalculated = false;
      //     break;
      //   }
      //   localTracks.forEach((t) => {
      //     if (t?.id) this.trackIDs.add(t.id);
      //   });
      // }

      localTracks = connection.connect(localTracks);
      this.runWarnings.addTracesWithMessage(connection.warnings, {
        component: "Pipeline",
        id: "",
        message: `Connecting node '${current.name}' warning.`,
      });

      // console.log(
      //   "  ..connect",
      //   localTracks.map((t) => t?.label)
      // );
      current = node;
    }

    // console.log(
    //   "end",
    //   localTracks.map((t) => t?.label)
    // );
    Object.values(this.nodes).forEach((node) => {
      node.updated = false;
    });

    if (this.onRun) {
      const states: pipelineNodeState[] = [];
      Object.values(this.nodes).forEach((node) => {
        const state: pipelineNodeState = {
          id: node.id,
          errors: this.errors.toStringList({ id: node.id }),
          warnings: this.warnings.toStringList({ id: node.id }),
        };
        states.push(state);
      });
      Object.values(this.connectors).forEach((node) => {
        const state: pipelineNodeState = {
          id: node.id,
          errors: this.errors.toStringList({ id: node.id }),
          warnings: this.warnings.toStringList({ id: node.id }),
        };
        states.push(state);
      });

      if (states.length > 0) this.onRun(states);
    }

    return replacer.map((r) => {
      const t = r.runReplacer(globalTracks as trackListType);
      // console.log("t", globalTracks, t);
      // t.after = undefined;
      // t.replace = undefined;
      return t;
    });

    // if (this.runWarnings.length > 0) console.log("WARNINGS:\n" + traceListToString(this.runWarnings));
    // if (this.runErrors.length > 0) console.log("ERRORS:\n" + traceListToString(this.runErrors));
  }
}
