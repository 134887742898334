import React, { useContext } from "react";
import { DeepMap, FieldError } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { getIndexRoute, RouteKey } from "../../main/Routing";
import { SessionContext } from "../contexts/SessionContext";
import styles from "./forms.module.css";
import { StringIndexedDict } from "../../api/GenericTypes";
import { ErrorsRenderer } from "./MultiEditForms/common/MultiEditRenderUtils";
import { Button } from "../buttons/Button/Button";

interface FormButtonsProps {
  groupName?: RouteKey;
  entityId?: number;
  onClose?: () => void;
  onSubmit?: (data: any) => void;
  loading?: boolean;
  disabled?: boolean;
  submitButtonLabel?: string;
  errors?: DeepMap<any, FieldError>;
  children?: React.ReactNode;
  disableDefaultOnClose?: boolean;
  hideCancel?: boolean;
  btnCls?: string;
}

export const errorContainer_style = { marginTop: 15, padding: 15 };

export const FormButtons = ({
  groupName,
  entityId,
  onClose,
  onSubmit,
  loading,
  disabled,
  submitButtonLabel = "Save changes",
  errors,
  children,
  disableDefaultOnClose,
  hideCancel = false,
  btnCls,
}: FormButtonsProps) => {
  const { route } = useContext(SessionContext);
  let history = useHistory();
  return (
    <>
      <div className={styles.header}>
        <div className={styles.buttons}>
          {children}
          {!hideCancel && (
            <button
              className="btn btn-default"
              type="button"
              onClick={() => {
                if (!disableDefaultOnClose && entityId && entityId > 0 && groupName)
                  history.replace(route(getIndexRoute(groupName)));
                onClose?.();
              }}
              disabled={loading}
            >
              Cancel
            </button>
          )}
          <Button
            className={btnCls ?? "btn btn-primary"}
            type="button"
            onClick={onSubmit}
            disabled={disabled}
            loading={loading}
          >
            {submitButtonLabel}
          </Button>
        </div>
      </div>

      {typeof errors === "object" && !!Object.keys(errors).length && (
        <div style={{ marginTop: 10, padding: 0 }}>
          <FormErrors errors={errors} />
        </div>
      )}
    </>
  );
};

interface Errors {
  [fieldId: string]: FieldError;
}

interface FormErrorProps<Form extends StringIndexedDict> {
  errors: Errors | DeepMap<Form, FieldError>;
  groupName?: string; // DEPRECATE
}
export const FormErrors = <Form extends StringIndexedDict>({ errors }: FormErrorProps<Form>) => {
  if (Object.keys(errors).length > 0) {
    // console.log("ERRORS", errors);
    return (
      <div className="flex" style={{ width: "100%" }}>
        <ul className={styles.errorList}>
          {Object.entries(errors).map(([, error], index) => {
            return <ErrorsRenderer error={error} key={index} />;
            // return <React.Fragment key={index}>{error.message && <li>{error.message}</li>}</React.Fragment>;
          })}
        </ul>
      </div>
    );
  } else return <></>;

  // const polishingErrorMessageList = () => {
  //   // Porpuse: Rather than displaying persons[0].LastName is a ... => In row 1, the Last Name is ...
  //   for (const [key, value] of Object.entries(errors)) {
  //     for (const [k, v] of Object.entries(value)) {
  //       const fieldName = k.replace(/([A-Z])/g, " $1");
  //       const modifiledFieldName = fieldName.charAt(0).toUpperCase() + fieldName.slice(1);
  //       console.log("Error: ", k, v);
  //       Object(v)["message"] = Object(v).message.replace(
  //         `${groupName}[${key}].${k}`,
  //         `In row ${parseInt(key) + 1}, the ${modifiledFieldName}`
  //       );
  //     }
  //   }
  // };

  // useEffect(() => {
  //   if (errors) {
  //     polishingErrorMessageList();
  //   }
  // }, [errors]);

  // if (Object.values(errors).length === 0) return null;

  // return (
  //   <ul className={styles.errorList}>
  //     {Object.values(errors).flatMap((value: any, index: any) => flattenErrors(value, index))}
  //   </ul>
  // );
};

export const flattenErrors = (value: any, index: any): any => {
  if (value.message) return <li>{value.message}</li>;
  else return Object.values(value).flatMap(flattenErrors);
};
