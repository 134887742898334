import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { usePost } from "../api/BaseEntityApi";
import { LoadingWrapper } from "../common/LoadingWrapper";
import { InputFormField } from "../common/formfields/InputFormField";
import { SelectFormField } from "../common/formfields/SelectFormField";
import { TextareaFormField } from "../common/formfields/TextareaFormField";
import { FormButtons } from "../common/forms/FormButtons";
import { FormHeader } from "../common/forms/FormHeader";
import styles from "../common/forms/forms.module.css";
import { Role } from "../api/Role";
import { Permission, PermissionFilters } from "../api/Permission";
import { useMemo } from "react";
import { IResultModel } from "../api/GenericTypes";
import { PermissionRow } from "../Permissions/PermissionsTable";
import { FormFieldLayout } from "../common/formfields/FormFieldLayouts";
import { NoEntriesFound } from "../common/NoEntriesFound";
import { EntityFormProps } from "../common/entity/EntityInterfaces";

export const RoleForm = ({
  event,
  fieldLabels,
  permissions,
  title,
  subtitle,
  initialValues,
  onSubmit,
  onCancel,
  loading,
  submitButtonLabel,
}: EntityFormProps<"roles">) => {
  const RoleFormSchema = yup.object().shape({
    name: yup.string().required("Name is required").typeError("Name is required"),
    rolePermissions: yup
      .array()
      .min(1, "Select at least one permission")
      .required("Select at least one permission")
      .typeError("Select at least one permission"),
  });

  const initialValuesFormatted = useMemo(() => {
    return { ...initialValues, rolePermissions: initialValues?.rolePermissions?.map((p) => ({ id: p.name, ...p })) };
  }, [initialValues]);

  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors, isSubmitting },
  } = useForm<Partial<Role>>({
    values: initialValuesFormatted,
    resolver: yupResolver(RoleFormSchema),
  });

  const {
    data: permissionsData,
    error: errorPermissions,
    status: statusPermissions,
    fetchStatus: fetchStatusPermissions,
  } = usePost<IResultModel<Permission>>("roles/permissions/list", {} as PermissionFilters, {});

  const granularpermissions = useMemo(() => {
    return permissionsData?.results.map((p) => ({ id: p.name, ...p })) || [];
  }, [permissionsData?.results]);

  const currentPermissions = watch("rolePermissions");

  return (
    <>
      <FormHeader title={title} subtitle={subtitle} />
      <form
        onSubmit={(e) => {
          e.preventDefault();
        }}
        autoComplete="off"
        className={`form-horizontal ${styles.form_holder}`}
      >
        <fieldset>
          <legend className="col-md-offset-2 col-md-10">Basic details</legend>
        </fieldset>
        <InputFormField
          id="name"
          label={fieldLabels.name}
          errors={errors}
          register={register}
          autoFocus={true}
          required={true}
          placeholder="Enter role name..."
        />

        <TextareaFormField
          id="description"
          label={fieldLabels.description}
          errors={errors}
          register={register}
          placeholder="Enter description..."
        />

        <fieldset>
          <legend className="col-md-offset-2 col-md-10">Role permissions</legend>
        </fieldset>
        <LoadingWrapper status={statusPermissions} fetchStatus={fetchStatusPermissions} error={errorPermissions}>
          <SelectFormField
            id="rolePermissions"
            label={fieldLabels.rolePermissions}
            items={granularpermissions}
            required={false}
            control={control}
            isMulti={true}
            errors={errors}
            placeholder="Select permissions..."
          />
        </LoadingWrapper>

        <FormFieldLayout id="currentRoles" label="" horizontal>
          {!!currentPermissions?.length ? (
            currentPermissions.map((p) => <PermissionRow permission={p} />)
          ) : (
            <NoEntriesFound message="No permissions selected" />
          )}
        </FormFieldLayout>

        <FormButtons
          groupName="roles"
          entityId={initialValues ? initialValues.id : undefined}
          onClose={onCancel}
          onSubmit={handleSubmit(async (entity) => await onSubmit(entity))}
          submitButtonLabel={submitButtonLabel || "Save changes"}
          disabled={isSubmitting}
          errors={errors}
          loading={loading}
        />
      </form>
    </>
  );
};
