import { useState, useEffect, useRef, useCallback } from "react";
import { Range } from "react-range";

// import { getCSSGradient } from "../ViewerUIElements/ColorSelector";
import { Resizeable } from "../ViewerUIElements/Resizeable";

import styles from "./TrackSliderComponent.module.css";
import { Button, FormControl } from "react-bootstrap";
import produce from "immer";
import { useInputDebounce } from "../ViewerUIElements/InputDebounce";
import { SliderThumbBlock, SliderTrack } from "../SpectrumViewer/ViewerNavigation/SliderElements";
import { Color, Track, trackSettingsList, trackSettingsType } from "../ViewerLayout/ViewerLayoutTypes";
import { ColorNeedsUpdate, initColor, initSingleColor, needUpdate } from "../ViewerLayout/ViewerLayoutUtils";
import { useTranslatedFieldUpdate } from "../ViewerLayout/ViewerLayoutHooks";

export const getTextAsImage = (text: string[], font: string): string[] => {
  const canvasElement = document.createElement("canvas");
  canvasElement.width = 200;
  canvasElement.height = 200;
  const context = canvasElement.getContext("2d");
  if (!context) return [];
  context.fillStyle = "#f6d021";
  context.textAlign = "center";
  context.textBaseline = "middle";
  context.font = font;
  context.textAlign = "start";
  context.textBaseline = "top";
  // context.rotate(-0.5*Math.PI);
  // metrics = context.measureText(text);
  // context.fillText(text, 0, 0);

  return text.map((t) => {
    const metrics = context.measureText(t);
    console.log({
      width: metrics?.actualBoundingBoxRight,
      height: metrics?.actualBoundingBoxDescent,
      overflow: "hidden",
    });
    const canvasElement = document.createElement("canvas");
    canvasElement.width = metrics?.actualBoundingBoxRight;
    canvasElement.height = metrics?.actualBoundingBoxDescent;
    // canvasElement.height = metrics?.actualBoundingBoxRight;
    // canvasElement.width = metrics?.actualBoundingBoxDescent;
    const ctx = canvasElement.getContext("2d");
    if (!ctx) return "";
    ctx.font = font;
    ctx.textAlign = "start";
    ctx.textBaseline = "top";
    // ctx.rotate(-0.5*Math.PI);
    ctx.fillText(t, 10, 0);
    return ctx?.canvas.toDataURL() || "";
  });
};

export function getCSSGradient(color: Color): string {
  const r = color.colors.map((c, i) => `${c} ${color.colors[i].offset}%`).join(", ");
  return `linear-gradient(90deg, ${r})`;
}

const TrackView = ({
  track,
  pinned,
  setPinned,
  setHovered,
  width,
  height,
  small,
  onClick,
}: {
  track: sliderTrackType;
  pinned: boolean;
  setPinned: (pinned: boolean) => void;
  setHovered?: (pinned: boolean) => void;
  small: boolean;
  width: number;
  height: number;
  onClick: () => void;
}) => {
  const [hover, setHover] = useState<boolean>(false);

  const color = track.color;
  // console.log("track.parameter.color", color.length === 1 ? color[0].color : getCSSGradient(color));
  // if (w < 3) return undefined;

  const style = {
    // background: color.length === 1 ? color[0].color : getCSSGradient(color),
    width: width,
    height: height,
    padding: small ? 0 : undefined,
  };

  // useEffect(() => {
  //   console.log("pinned", track.label, pinned);
  // }, [pinned]);

  // if (track.label === "two") console.log("pinned", track.label, pinned);

  const userHover = useCallback(
    (hover: boolean) => {
      if (setHovered) setHovered(hover);
      setHover(hover);
    },
    [setHovered]
  );

  const text = small ? undefined : <span className={styles.trackText}>{track.label}</span>;

  return (
    <div
      className={styles.track}
      style={style}
      // onClick={() => onClick(track.id)}
      // onDoubleClick={() => onDoubleClick(track.id)}
      onClick={onClick}
      // onMouseEnter={() => setHover(true)}
      // onMouseLeave={() => setHover(false)}
      onMouseEnter={() => userHover(true)}
      onMouseLeave={() => userHover(false)}
    >
      {/* <span className={styles.trackText}>{track.label}</span> */}
      <div style={{ height: height - (small ? 20 : 10) }}>{text}</div>
      <span
        className={["glyphicon", "glyphicon-pushpin", styles.pin].join(" ")}
        style={{
          marginLeft: width > 15 ? width - 15 : 0,
          display: pinned || hover ? undefined : "none",
          color: hover && !pinned ? "gray" : undefined,
        }}
        onClickCapture={(e) => {
          console.log("pin click");
          setPinned(!pinned);
          e.stopPropagation();
        }}
      />
      <div
        className={styles.trackText}
        style={{
          background: color.colors.length === 1 ? color.colors[0].color : getCSSGradient(color),
          height: small ? 20 : 10,
        }}
      />
    </div>
  );
};

const TrackDiv = ({
  width,
  height,
  tracks,
  pinned,
  setHovered,
  setPinned,
  setCurrentTrack,
}: {
  width?: number;
  height?: number;
  pinned: Record<string, boolean>;
  setHovered?: (id?: string) => void;
  setPinned: (pinned: Record<string, boolean>) => void;
  tracks: sliderTrackType[];
  setCurrentTrack: (track: string) => void;
}) => {
  const [clickTimeout, setClickTimeout] = useState<any>();
  // const [pinned, setPinned] = useState<Record<string, boolean>>({});

  const clickHandle = (id: string) => {
    // console.log("click", clickTimeout);
    if (clickTimeout === undefined) {
      setClickTimeout(
        setTimeout(() => {
          setClickTimeout(undefined);
          // console.log("single click", pinned);
          setCurrentTrack(id);
          // onClick(id);
        }, 300)
      );
    } else {
      clearTimeout(clickTimeout);
      setClickTimeout(undefined);
      console.log("double click");
      // onDoubleClick(id);
      setPinned(
        produce(pinned, (next) => {
          next[id] = !pinned[id];
        })
      );
    }
  };

  const w: number = tracks.length < 1 ? width || 0 : (width || 0) / tracks.length;
  const tooSmall: boolean = w < 20;

  // useEffect(() => {
  //   console.log("pinned", pinned);
  // }, [pinned]);

  let divList: any = tracks.map((track, i) => (
    <TrackView
      track={track}
      pinned={pinned[track.id]}
      setPinned={(p: boolean) =>
        setPinned(
          produce(pinned, (next) => {
            next[track.id] = p;
          })
        )
      }
      setHovered={(hover: boolean) => {
        if (setHovered) setHovered(hover ? track.id : undefined);
      }}
      width={w}
      height={height || 0}
      small={tooSmall}
      key={i}
      onClick={() => clickHandle(track.id)}
    />
  ));

  if (divList.length < 1)
    divList = (
      <div
        style={{
          // background: getCSSGradient([
          //   { color: "grey", offset: 0 },
          //   { color: "white", offset: 100 },
          // ] as colorType),
          background: getCSSGradient(
            initColor({
              colors: [initSingleColor({ color: "grey", offset: 0 }), initSingleColor({ color: "white", offset: 100 })],
            })
          ),
          width: w,
          padding: 5,
          // height: 100,
        }}
      >
        <b>No tracks</b>
      </div>
    );

  return (
    <div className={styles.tracks} style={{ width: width || 10, height: height || 10 }}>
      {divList}
    </div>
  );
};

const SliderDiv = (props: any) => {
  const {
    width,
    height,
    tracks,
    current,
    setCurrent,
  }: {
    width: number;
    height: number;
    tracks: sliderTrackType[];
    current: number;
    setCurrent: (index: number | undefined) => void;
  } = props;

  const [values, setValues] = useState<number[]>([0]);
  const [oldValue, setOldValue] = useState<number>(-1);
  const [thumbWidth, setThumbWidth] = useState(0);
  const [thumbBound, setThumbBound] = useState({
    width: 10,
    height: 10,
  });
  const [thumbColors, setThumbColors] = useState<string[]>(["gray"]);
  const [disabled, setDisabled] = useState<boolean>(false);
  const debouncedValues = useInputDebounce<number[]>(values, 100);

  const minThumbWidth = 24;

  useEffect(() => {
    const colors = tracks.map((track) => {
      const c = track.color;
      return c.colors.length === 1 ? c.colors[0].color : getCSSGradient(c);
    });
    if (colors.length < 1) {
      setThumbColors(["gray", "gray", "gray"]);
      setDisabled(true);
    } else {
      setThumbColors(colors);
      setDisabled(false);
    }
    // console.log("colors", thumbColors);
  }, [tracks]);

  useEffect(() => {
    if (current !== undefined) {
      let c = current;
      if (c < 0) c = 0;
      if (c >= thumbColors.length) c = thumbColors.length - 1;
      setValues([c]);
      // console.log("setCurrent", setCurrent);
      // if (setCurrent) setCurrent(c);
    }
  }, [current, thumbColors]);

  useEffect(() => {
    const w = thumbColors.length < 1 ? width / 3 : width / thumbColors.length;
    setThumbWidth(w);
    // console.log("dim", w, height);
    // console.log("width", width, height);
    setThumbBound({
      width: w > minThumbWidth ? w - (w % 2) : minThumbWidth,
      height: height,
      // width:  5,
      // height: 10,
    });
  }, [width, height, thumbColors]);

  useEffect(() => {
    if (values[0] !== oldValue) {
      if (setCurrent) setCurrent(tracks.length > 0 ? values[0] : 0);
      setOldValue(values[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValues, tracks]);

  return (
    <div
      style={{
        width: width,
        // width: 100,
        height: height / 2,
        paddingLeft: thumbWidth / 2,
        paddingRight: thumbWidth / 2,
        // background: "red",
      }}
    >
      <Range
        step={1}
        min={0}
        disabled={disabled}
        max={thumbColors.length < 2 ? 1 : thumbColors.length - 1}
        values={values}
        renderTrack={({ props, children }: { props: any; children: any }) => (
          <div {...props} className={styles.sliderTrack}>
            {children}
          </div>
        )}
        onChange={(values: any) => {
          setValues(values);
        }}
        renderThumb={({ props }: { props: any }) => (
          <div
            {...props}
            className={styles.sliderThumb}
            // style={{ width: thumbBound.width, height: 20, background: thumbColors[values[0]] }}
            style={{ width: thumbBound.width, height: 12, background: "silver" }}
          >
            <span
              className="glyphicon glyphicon-chevron-left"
              style={{ visibility: values[0] === 0 ? "hidden" : "visible" }}
            />
            <span
              className="glyphicon glyphicon-chevron-right"
              style={{ visibility: values[0] < thumbColors.length - 1 ? "visible" : "hidden" }}
            />
          </div>
        )}
      />
    </div>
  );
};

const delayRange = { min: 200, max: 1500, diff: 0 };
delayRange.diff = delayRange.max - delayRange.min;

const delayToSpeed = (delay: number): number => {
  let speed = (delay - delayRange.min) / delayRange.diff;

  if (speed < 0) speed = 0;
  if (speed > 1) speed = 1;

  return 1 - speed;
};

const speedToDelay = (speed: number): number => {
  let delay = delayRange.min + (1 - speed) * delayRange.diff;

  if (delay < delayRange.min) delay = delayRange.min;
  if (delay > delayRange.max) delay = delayRange.max;

  return delay;
};

const MovieTool = ({
  frame,
  setFrame,
  frameNumber,
}: {
  frame: number;
  setFrame: (frame: number) => void;
  frameNumber: number;
}) => {
  const [frameInput, setFrameInput] = useState<string>("");
  const [frameValid, setFrameValid] = useState<boolean>(true);
  const [animation, setAnimation] = useState<number>(0);
  const [tick, setTick] = useState(true);
  const [delay, setDelay] = useState<number>(500);
  const [speed, setSpeed] = useState<number>(delayToSpeed(delay));

  const val = useRef<any>();

  useEffect(() => {
    return () => clearInterval(val.current);
  }, []);

  useEffect(() => {
    const str = "" + (frame + 1);
    if (str !== frameInput) setFrameInput(str);
    setFrameValid(true);
  }, [frame]);

  useEffect(() => {
    let i = parseInt(frameInput) - 1;
    if (i >= 0 && i < frameNumber) {
      if (i !== frame) setFrame(i);

      setFrameValid(true);
    } else setFrameValid(false);
  }, [frameInput, frameNumber]);

  useEffect(() => {
    if (animation < 0) previousFrame();
    else nextFrame();
  }, [tick]);

  useEffect(() => {
    const delay = speedToDelay(speed);
    setDelay(delay);
    if (animation !== 0) startAnimation(animation, delay);
  }, [speed]);

  const previousFrame = useCallback(() => {
    if (frameNumber > 1) {
      let f = frame - 1;
      if (f < 0) f = frameNumber - 1;
      setFrame(f);
    }
  }, [frameNumber, frame]);

  const nextFrame = useCallback(() => {
    if (frameNumber > 1) {
      let f = frame + 1;
      if (f >= frameNumber) f = 0;
      setFrame(f);
    }
  }, [frame, frameNumber]);

  const stopAnimation = useCallback(() => {
    clearInterval(val.current);
    val.current = undefined;
    setAnimation(0);
  }, []);

  const startAnimation = useCallback(
    (direction: number, delay: number) => {
      stopAnimation();
      setAnimation(direction);
      val.current = setInterval(() => {
        setTick((tick) => !tick);
      }, delay);
    },
    [tick, delay]
  );

  return (
    <div>
      <div className={styles.movieLine}>
        <div className={styles.movieElement}>
          <Button
            bsSize="xs"
            onClick={() => {
              if (frame > 0 && frameNumber > 0) setFrame(0);
            }}
          >
            <span className={"glyphicon glyphicon-fast-backward"} />
          </Button>
        </div>
        <div className={styles.movieElement}>
          <Button bsSize="xs" onClick={previousFrame}>
            <span className={"glyphicon glyphicon-step-backward"} />
          </Button>
        </div>
        <div className={styles.movieElement}>
          <Button
            bsSize="xs"
            // bsStyle={animation < 0 ? "primary" : "default"}
            style={{ background: animation < 0 ? "#2b7ab9" : undefined }}
            onClick={() => {
              if (animation < 0 && val.current) stopAnimation();
              else startAnimation(-1, delay);
            }}
          >
            <span className={"glyphicon glyphicon-play"} style={{ transform: "rotate(180deg)" }} />
          </Button>
        </div>
        <div style={{ paddingLeft: 5 }}>
          <FormControl
            type="text"
            value={frameInput}
            onChange={(e) => setFrameInput((e.target as HTMLInputElement).value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") setFrameInput((e.target as HTMLInputElement).value);
            }}
            onFocus={(e) => (e.target as any as HTMLInputElement).select()}
            bsSize={"sm"}
            style={{ width: "5em", scale: "90%", background: frameValid ? undefined : "salmon" }}
          />
        </div>
        <div className={styles.movieElement}>
          <Button
            bsSize="xs"
            // bsStyle={animation > 0 ? "primary" : "default"}
            style={{ background: animation > 0 ? "#2b7ab9" : undefined }}
            onClick={() => {
              if (animation > 0 && val.current) stopAnimation();
              else startAnimation(1, delay);
            }}
          >
            <span className={"glyphicon glyphicon-play"} />
          </Button>
        </div>
        <div className={styles.movieElement}>
          <Button
            bsSize="xs"
            onClick={nextFrame}
            // {
            //   const f = nextFrame();
            //   if (f !== null) setFrame(f);
            // }}
          >
            <span className={"glyphicon glyphicon-step-forward"} />
          </Button>
        </div>
        <div className={styles.movieElement}>
          <Button
            bsSize="xs"
            onClick={() => {
              if (frame < frameNumber - 1) setFrame(frameNumber - 1);
            }}
          >
            <span className={"glyphicon glyphicon-fast-forward"} />
          </Button>
        </div>
      </div>
      <div className={styles.movieLine} style={{ paddingTop: 10 }}>
        <div className={styles.movieElement + " " + styles.menuText}>Play speed:</div>

        <div className={styles.movieElement} style={{ width: 100 }}>
          <Range
            step={0.01}
            min={0}
            // disabled={disabled}
            max={1}
            values={[speed]}
            renderTrack={(props: any) => <SliderTrack {...props} />}
            onChange={(values: number[]) => setSpeed(values[0])}
            renderThumb={SliderThumbBlock}
          />
        </div>
      </div>
    </div>
  );
};

type sliderTrackType = {
  id: string;
  color: Color;
  label: string;
};

type trackSliderComponentProps = {
  tracks: Record<string, Track>;
  trackList: string[];
  setCurrentTrack: (trackID: string | undefined) => void;
  // setPinnedTracks: (pinned: Record<string, boolean>) => void;
  changeTrackSettings: (list: trackSettingsList) => void;
};

// const trackNeedsUpdate = (target: sliderTrackType, source: sliderTrackType): boolean => {
//   if (target.id !== source.id) return true;
//   if (target.label !== source.label) return true;
//   if (ColorNeedsUpdate(target.color, source.color)) return true;
//   return false;
// };

const TrackSliderComponentDiv = ({
  tracks,
  trackList,
  width,
  changeTrackSettings,
}: trackSliderComponentProps & { width?: number; height?: number }) => {
  const [trackIndex, setTrackIndex] = useState<Record<string, number>>({});
  const [current, setCurrent] = useState<number>(0);
  const [hovered, setHovered] = useState<number>();
  const [pinned, setPinned] = useState<Record<string, boolean>>({});
  const [wheelPos, setWheelPos] = useState<number>(current);

  const debouncedWheelPos = useInputDebounce<number>(wheelPos, 100);

  const trackElements = useTranslatedFieldUpdate(
    trackList,
    (trackList: string[]) =>
      trackList.map((id) => {
        return {
          id: id,
          color: tracks[id].settings.color || initColor({ colors: [] }),
          label: tracks[id].name,
          visible: tracks[id].settings.visible,
        } as sliderTrackType;
      }),

    []
  );

  useEffect(() => {
    const p = Object.fromEntries(Object.entries(pinned).filter(([id]) => id in trackList));
    if (needUpdate(pinned, p)) setPinned(p);

    // setPinned(Object.fromEntries(Object.entries(pinned).filter(([id]) => id in trackList)));
    setTrackIndex(Object.fromEntries(trackList.map((id, i) => [id, i])));

    changeTrackSettings(
      trackElements.map((track) => ({ id: track.id, settings: { visible: false } as trackSettingsType }))
    );

    if (trackList.length > 0) {
      let c = current;
      if (c >= trackList.length) c = trackList.length - 1;
      if (c < 0) c = 0;
      if (c !== current) setCurrent(c);
    }
  }, [trackElements, current, pinned]);

  useEffect(() => {
    changeTrackSettings(
      trackElements.map((track) => ({
        id: track.id,
        settings: {
          visible: track.id === trackElements[current]?.id || (pinned[track.id] ? true : false),
        } as trackSettingsType,
      }))
    );
  }, [current, pinned, trackElements]);

  useEffect(() => {
    setCurrent(debouncedWheelPos);
  }, [debouncedWheelPos]);

  const jumpToTrack = useCallback((trackID: string) => setCurrent(trackIndex[trackID]), [trackIndex]);

  return (
    <div
      className={styles.main}
      onWheelCapture={(e) => {
        let pos = current + Math.sign(e.deltaY);
        if (pos >= trackList.length) pos = 0;
        if (pos < 0) pos = trackList.length - 1;
        setWheelPos(pos);
      }}
    >
      <div className={styles.menuText}>
        Track {hovered === undefined ? current + 1 : hovered + 1}:{" "}
        <span className={styles.valueText}>
          {trackElements?.[hovered === undefined ? current || -1 : hovered]?.label || ""}
        </span>
      </div>

      <TrackDiv
        tracks={trackElements}
        pinned={pinned}
        setCurrentTrack={jumpToTrack}
        setPinned={(pinned: Record<string, boolean>) => setPinned(pinned)}
        setHovered={(id?: string) => {
          // console.log("hovered", id, trackIndex[id || ""]);
          setHovered(trackIndex[id || ""]);
        }}
        width={width}
        height={50}
      />
      <SliderDiv
        tracks={trackElements}
        current={current}
        width={width}
        height={50}
        setCurrent={(index: number) => setCurrent(index)}
      />
      <Button
        bsSize="xs"
        onClick={() => {
          console.log("current", trackElements[current].label);
          setPinned(
            produce(pinned, (next) => {
              next[trackElements[current].id] = !pinned[trackElements[current].id];
            })
          );
        }}
      >
        {pinned[trackElements?.[current]?.id || ""] ? "unpin" : "pin"} current track
      </Button>
      <div style={{ height: 10 }} />

      <MovieTool frame={current} setFrame={(c: number) => setCurrent(c)} frameNumber={trackElements.length} />
    </div>
  );
};

export const TrackSliderComponent = (props: trackSliderComponentProps) => {
  return (
    <div className={styles.main}>
      <Resizeable className={styles.main}>
        <TrackSliderComponentDiv {...props} />
      </Resizeable>
    </div>
  );
};
