import { CSSProperties, ReactNode, useCallback, useEffect } from "react";
import ReactDOM from "react-dom";
import { LucideIcon } from "../../../common/icon/LucideIcon";
import styles from "./ELNModal.module.css";
interface Props {
  clsNames?: string;
  style?: CSSProperties;
  children: React.ReactNode;
}

export const Modal = ({
  children,
  isOpen = false,
  onClose,
  controls,
}: {
  children: ReactNode;
  isOpen: boolean;
  onClose?: Function;
  controls?: ReactNode;
}) => {
  const closeModalonEscape = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        e.preventDefault();
        e.stopPropagation();
        onClose?.();
      }
    },
    [onClose]
  );

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("keydown", closeModalonEscape);
      return () => document.removeEventListener("keydown", closeModalonEscape);
    }
  }, [closeModalonEscape, isOpen]);

  if (!isOpen) return null;
  return ReactDOM.createPortal(
    <div className={styles.elnModalContainer}>
      <div
        className={styles.toolbarModalBackground}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          onClose?.();
        }}
      />
      <div className={styles.toolbarModalContentContainer}>
        <div
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            onClose?.();
          }}
          className={styles.toolbarModalClose}
          title="Close"
        >
          <LucideIcon name="x" />
        </div>
        <div className={styles.toolbarModalContent}>{children}</div>
        {controls && <div className={styles.toolbarModalControls}>{controls}</div>}
      </div>
    </div>,
    document.body
  );
};

const ModalTitle = ({ clsNames, style, children }: Props) => {
  return (
    <div className={`${styles.modalTitle} ${clsNames}`} style={style}>
      <h2 style={{ margin: 0 }}>{children}</h2>
      <hr style={{ margin: "10px 0px", width: "100%" }} />
    </div>
  );
};
Modal.Title = ModalTitle;

const ModalBody = ({ clsNames, style, children }: Props) => {
  return (
    <div className={`${styles.modalBody} ${clsNames}`} style={style}>
      {children}
    </div>
  );
};
Modal.Body = ModalBody;

const ModalControls = ({ clsNames, style, children }: Props) => {
  return (
    <div className={`${styles.modalControls} ${clsNames}`} style={style}>
      <hr style={{ margin: "10px 0px 5px 0px", width: "100%" }} />
      {children}
    </div>
  );
};
Modal.Controls = ModalControls;
