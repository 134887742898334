import { useCallback, useEffect, useState } from "react";
import { Range } from "react-range";
import { SliderThumbBlock, SliderTrack } from "./SliderElements";

import styles from "./GrayRange.module.css";
import { ValidatedInput } from "./ValidatedInput";
import { useInputDebounce } from "../InputDebounce";

export const getDigit = (range?: number) => {
  if (range) {
    const tic = Math.floor(Math.log(range) / Math.log(10));
    return tic < 0 ? -tic : tic < 3 ? 1 : 0;
  }
  return 0;
};

const minmax = (value: number, min: number, max: number): number => {
  if (value < min) return min;
  if (value > max) return max;
  return value;
};

export const GrayRange = ({
  minLabel,
  maxLabel,
  min,
  max,
  stepNumber,
  stepSize,
  setValue,
  value,
  validate,
  name,
  useInteger,
}: {
  minLabel?: string;
  maxLabel?: string;
  min: number;
  max: number;
  name?: string;
  stepNumber?: number;
  stepSize?: number;
  value: number;
  setValue?: (value: number) => void;
  validate?: (value: number) => boolean;
  useInteger?: boolean;
}) => {
  const [internalValue, setInternalValue] = useState<number>(minmax(value, min, max));
  const [externalValue, setExternalValue] = useState<number>(minmax(value, min, max));
  const [step, setStep] = useState<number>(1);

  const [minMaxStr, setMinMaxStr] = useState<[string, string]>(["", ""]);
  const debouncedInput = useInputDebounce<number>(externalValue, 100);

  const changeValue = useCallback(
    (value: number) => {
      // if (setValue && Math.abs(value - internalValue) > Number.EPSILON) setValue(internalValue);
      if (Math.abs(value - internalValue) > Number.EPSILON) {
        setInternalValue(value);
        setExternalValue(value);
        // if (setValue) setValue(value);
      }
      // setPick(false);
    },
    [internalValue]
  );

  useEffect(() => {
    // setStep(stepNumber ? (max - min) / stepNumber : stepSize ? stepSize : (max - min) / 100);
    let step = stepNumber ? (max - min) / stepNumber : stepSize ? stepSize : (max - min) / 100;
    if (useInteger) step = Math.floor(step);
    if (step < Number.EPSILON) step = 1;
    setStep((step) => step);
  }, [stepNumber, stepSize, min, max, useInteger]);

  useEffect(() => {
    setMinMaxStr([minLabel ?? "" + Math.round(min * 10) / 10, maxLabel ?? "" + Math.round(max * 10) / 10]);
  }, [min, max, setMinMaxStr, minLabel, maxLabel]);

  useEffect(() => {
    // console.log("  => set external value", debouncedInput, setValue && Math.abs(value - debouncedInput) > Number.EPSILON);
    // if (setValue) setValue(debouncedInput);
    if (setValue && Math.abs(value - debouncedInput) > Number.EPSILON) setValue(debouncedInput);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedInput, setValue]);

  useEffect(() => {
    const v = minmax(value, min, max);
    // console.log("v", v);
    if (Math.abs(v - internalValue) > Number.EPSILON) setInternalValue(v);
  }, [value, min, max, internalValue]);

  return (
    <div className={styles.rangeSetter}>
      <div className={styles.settingText} style={{ position: "relative", top: -10 }}>
        {name ? <span>{name}:&nbsp;</span> : null}
      </div>
      <div style={{ flexGrow: 1, minWidth: 30, position: "relative", top: -3 }}>
        <Range
          step={step}
          min={min}
          // disabled={disabled}
          max={max}
          values={[internalValue]}
          renderTrack={SliderTrack}
          // onChange={(values: number[]) => setInternalValue(values[0])}
          onChange={(values: number[]) => changeValue(values[0])}
          // onChange={setScale}
          renderThumb={SliderThumbBlock}
        />
        <div className={styles.range}>
          <span>{minMaxStr[0]}</span>
          <span>{minMaxStr[1]}</span>
        </div>
      </div>
      <div style={{ paddingLeft: 5, position: "relative", top: -10 }}>
        <ValidatedInput
          value={internalValue}
          setValue={changeValue}
          validate={validate}
          style={{
            width: "6em",
            // scale: "80%",
          }}
          useInteger={useInteger}
        />
      </div>
    </div>
  );
};
