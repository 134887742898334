import { yupResolver } from "@hookform/resolvers/yup";
import { FieldError, useForm } from "react-hook-form";
import * as yup from "yup";
import styles from "../../common/forms/forms.module.css";
import { FormHeader } from "../../common/forms/FormHeader";
import { FormButtons } from "../../common/forms/FormButtons";
import { EntityFormProps } from "../../common/entity/EntityInterfaces";
import { InputFormField } from "../../common/formfields/InputFormField";
import { PasswordFormField } from "../../common/formfields/PasswordFormField";
import { TextareaFormField } from "../../common/formfields/TextareaFormField";
import {
  Bridge,
  BridgeType,
  BridgeTypeFormValues,
  BridgeValidationOption,
  BridgeValidationOptions,
  bridgeConstants,
} from "../../api/Bridges";
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { LucideIcon } from "../../common/icon/LucideIcon";
import { SelectFormField } from "../../common/formfields/SelectFormField";
import { useSFTPCheckConnection, usePingConnection } from "../../api/autoload/AutoloadClientsApi";
import { FormFieldLayout } from "../../common/formfields/FormFieldLayouts";
import { SessionContext } from "../../common/contexts/SessionContext";
import { Button } from "../../common/buttons/Button/Button";
import { NotAvailable } from "../../common/misc/UIconstants";
import Status from "../../common/badges/Status/Status";

interface BridgeFormValues extends Omit<Bridge, "type" | "sftpAuthentificationMethod"> {
  type: { id: BridgeType; name: BridgeType };
  sftpAuthentificationMethod?: { id: BridgeValidationOption; name: string };
}

export const BridgeForm = ({
  fieldLabels,
  title,
  subtitle,
  initialValues,
  onSubmit,
  onCancel,
  loading,
  submitButtonLabel,
  event,
}: EntityFormProps<"bridges">) => {
  const { session } = useContext(SessionContext);

  const [changePassword, setChangePassword] = useState<boolean>(false);

  const BridgeFormSchema = yup.object().shape({
    name: yup.string().required("A name is required"),
    type: yup.object().required("Bridge type is required"),
    hostname: yup
      .string()
      .nullable()
      .when("type", ([type], field) =>
        type?.id === "SFTP" ? field.required("A hostname is required") : field.optional()
      ),
    port: yup
      .number()
      .nullable()
      .when("type", ([type], field) =>
        type?.id === "SFTP"
          ? field.required("The port is required").min(1, "A port number needs to be greater than zero")
          : field.optional()
      ),
    username: yup
      .string()
      .nullable()
      .when("type", ([type], field) =>
        type?.id === "SFTP" ? field.required("A username is required") : field.optional()
      ),
    sftpAuthentificationMethod: yup
      .object()
      .nullable()
      .when("type", ([type], field) =>
        type?.id === "SFTP" ? field.required("A authentication method is required") : field.optional()
      ),
    password: yup
      .string()
      .nullable()
      .when("sftpAuthentificationMethod", ([method], field) =>
        method?.id === "Password" && initialValues?.sftpAuthenticationMethod !== "Password"
          ? field.required("A password is required")
          : field.optional()
      ),
    privateKey: yup
      .string()
      .nullable()
      .when("sftpAuthentificationMethod", ([method], field) =>
        method?.id === "PrivateKey" && initialValues?.sftpAuthenticationMethod !== "PrivateKey"
          ? field.required("A private key is required")
          : field.optional()
      ),
    description: yup.string().optional(),
  });

  const {
    handleSubmit,
    register,
    control,
    setValue,
    clearErrors,
    getValues,
    watch,
    formState: { errors, isSubmitting },
  } = useForm<Partial<BridgeFormValues>>({
    values: {
      ...initialValues,
      type: !!initialValues?.type ? { id: initialValues.type, name: initialValues.type } : { id: "SFTP", name: "SFTP" },
      sftpAuthentificationMethod:
        initialValues?.type === "SFTP"
          ? BridgeValidationOptions.find((o) => o.id === (initialValues?.sftpAuthenticationMethod ?? "Password"))
          : undefined,
    },
    resolver: yupResolver(BridgeFormSchema),
  });

  const currentType = watch("type");

  const currentValidationMethod = watch("sftpAuthentificationMethod");
  useEffect(() => {
    currentValidationMethod?.id === "PrivateKey" ? setValue("password", undefined) : setValue("privateKey", undefined);
  }, [currentValidationMethod?.id, setValue]);

  // SSH key upload
  const fileInputRef = useRef<HTMLInputElement>(null);
  const handleReadFile = useCallback(async (file: File): Promise<ArrayBuffer | string | null> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsText(file);
      reader.onloadend = (event) => {
        const target = event.target;
        if (target) {
          resolve(target.result);
        }
      };
      reader.onerror = reject;
    });
  }, []);

  // SFTP connection check
  const { connectionSuccessful, checkConnection, isIdleCheckingConnection, clearConnectionStatus } =
    useSFTPCheckConnection();
  const { pingSuccessful, pingConnection, isIdlePingingConnection, clearPingStatus } = usePingConnection();

  const currentValues = watch();

  useEffect(() => {
    clearErrors("hostname");
    clearPingStatus();
  }, [clearPingStatus, currentValues.hostname, currentValues.port, clearErrors]);

  useEffect(() => {
    clearConnectionStatus();
  }, [
    clearConnectionStatus,
    currentValues.hostname,
    currentValues.port,
    currentValues.username,
    currentValues.password,
    currentValues.privateKey,
  ]);

  const connectionErrors = useMemo(() => {
    const errors: { [key: string]: FieldError } = {};
    pingSuccessful?.errors.forEach((e, index) => {
      errors["PingErrors_" + index] = { type: "validate", message: `[Warning] ${e}` } as FieldError;
    });
    connectionSuccessful?.errors.forEach((e, index) => {
      errors["SSHErrors_" + index] = {
        type: "validate",
        message: `[Warning] ${e}`,
      } as FieldError;
    });
    connectionSuccessful?.warnings.forEach((e, index) => {
      errors["SSHWarning_" + index] = {
        type: "validate",
        message: `[Warning] ${e}`,
      } as FieldError;
    });
    return errors;
  }, [pingSuccessful?.errors, connectionSuccessful?.errors, connectionSuccessful?.warnings]);

  return (
    <>
      <FormHeader title={title} subtitle={subtitle} />
      <form
        onSubmit={(e) => {
          e.preventDefault();
        }}
        autoComplete="off"
        className={`form-horizontal ${styles.form_holder}`}
      >
        <fieldset>
          <legend className="col-md-offset-2 col-md-10">Basic details</legend>
        </fieldset>

        <InputFormField
          id="name"
          label={fieldLabels.name}
          errors={errors}
          register={register}
          autoFocus
          required
          placeholder="Insert bridge name..."
        />

        <SelectFormField
          id="type"
          label={fieldLabels.type}
          control={control}
          items={BridgeTypeFormValues.filter((t) => t.id !== "SFTP" || !session?.features.enable_legacy_SFTP_autoloads)}
          errors={errors}
          placeholder="Select connection type..."
          isDisabled={!!initialValues?.type}
          required
          isClearable={false}
        />

        {currentType?.id === "SFTP" && (
          <>
            <InputFormField
              id="hostname"
              label={fieldLabels.hostname}
              errors={errors}
              register={register}
              required
              placeholder="Enter host name / IP address..."
            />

            {!!initialValues?.ipAddress &&
              currentValues.hostname !== initialValues.ipAddress &&
              currentValues.hostname === initialValues?.hostname && (
                <InputFormField
                  id="ipAddress"
                  label="Resolved IP address"
                  errors={errors}
                  register={register}
                  readOnly
                  placeholder="IPv4-Address..."
                  style={{ fontFamily: "monospace" }}
                />
              )}

            <InputFormField
              id="port"
              label={fieldLabels.port}
              errors={errors}
              register={register}
              required
              type="number"
              min={0}
              placeholder="Insert port number..."
            />

            <InputFormField
              id="username"
              label={fieldLabels.username}
              errors={errors}
              register={register}
              required
              placeholder="Insert user name..."
              autoComplete="off"
            />

            <SelectFormField
              id="sftpAuthentificationMethod"
              label={fieldLabels.sftpAuthenticationMethod}
              control={control}
              items={BridgeValidationOptions}
              errors={errors}
              placeholder="Select authentication method..."
              required
            />

            {currentValidationMethod?.id === "Password" && (
              <>
                {(changePassword || initialValues?.sftpAuthenticationMethod !== "Password") && (
                  <PasswordFormField
                    id="password"
                    label={fieldLabels.password}
                    errors={errors}
                    register={register}
                    required
                    placeholder="Enter password..."
                    autoComplete="new-password"
                  />
                )}
                {initialValues?.sftpAuthenticationMethod === "Password" && (
                  <>
                    {!changePassword ? (
                      <FormFieldLayout id="" label="" horizontal hasLabel>
                        <button
                          className="btn btn-ghost-primary btn-xs"
                          type="button"
                          onClick={() => setChangePassword(true)}
                        >
                          Update password
                        </button>
                      </FormFieldLayout>
                    ) : (
                      <FormFieldLayout id="" label="" horizontal hasLabel>
                        <button
                          className="btn btn-ghost-secondary btn-xs"
                          type="button"
                          onClick={() => setChangePassword(false)}
                        >
                          Keep old password
                        </button>
                      </FormFieldLayout>
                    )}
                  </>
                )}
              </>
            )}

            {currentValidationMethod?.id === "PrivateKey" && (
              <TextareaFormField
                id="privateKey"
                label={fieldLabels.privateKey}
                errors={errors}
                register={register}
                required={!initialValues?.sftpAuthenticationMethod}
                style={{ whiteSpace: "nowrap", fontFamily: "monospace" }}
                placeholder="Paste SSH key..."
                buttons={
                  <div className="flex row-nowrap align-center gap-5">
                    <button
                      className="btn btn-sm btn-ghost-primary btn-block"
                      title="Upload single file"
                      name="upload"
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        fileInputRef.current?.click();
                      }}
                    >
                      <LucideIcon name="upload" />
                      <span> ...or upload SSH key</span>
                    </button>
                    <input
                      id="selectFile"
                      ref={fileInputRef}
                      hidden
                      type="file"
                      onChange={async (e) => {
                        e.stopPropagation();
                        if (e.target.files) {
                          const file = e.target.files[0];
                          const text = await handleReadFile(file);
                          if (typeof text === "string") setValue("privateKey", text);
                        }
                      }}
                      style={{ display: "none" }}
                    />
                  </div>
                }
              />
            )}
          </>
        )}

        <TextareaFormField id="description" label={fieldLabels.description} errors={errors} register={register} />

        <FormButtons
          groupName={bridgeConstants.frontendIndexRoute}
          entityId={initialValues ? initialValues.id : undefined}
          onClose={onCancel}
          onSubmit={handleSubmit(
            async (entity) =>
              await onSubmit({
                ...entity,
                type: entity?.type?.id,
                sftpAuthenticationMethod: entity.sftpAuthentificationMethod?.id,
              })
          )}
          submitButtonLabel={submitButtonLabel || "Save changes"}
          disabled={isSubmitting}
          loading={loading}
          errors={{
            ...errors,
          }}
        />

        {currentType?.id === "SFTP" && (
          <>
            <fieldset style={{ marginTop: "30px" }}>
              <legend className="col-md-offset-2 col-md-10">Connection test</legend>
            </fieldset>

            <FormFieldLayout id="" label="Ping status" horizontal>
              <div className="flex align-center gap-5" style={{ justifyContent: "space-between", paddingTop: "4px" }}>
                {isIdlePingingConnection ? (
                  <div className="label label-soft-default" style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
                    <Status type="secondary" idle>
                      <span> Checking...</span>
                    </Status>
                  </div>
                ) : (
                  <>
                    {!pingSuccessful ? (
                      NotAvailable
                    ) : !!pingSuccessful?.success ? (
                      <div className="flex align-center gap-5">
                        <LucideIcon name="wifi" color="var(--success)" />
                        <div className="label label-soft-success">Ping successful</div>
                      </div>
                    ) : (
                      <div className="flex align-center gap-5">
                        <LucideIcon name="triangle-alert" color="var(--danger)" />
                        <div className="label label-soft-danger">Ping failed</div>
                      </div>
                    )}
                  </>
                )}
                <Button
                  type="button"
                  className="btn btn-soft-info flex align-center gap-5"
                  title={`Ping host${currentValues.hostname ? ` ${currentValues.hostname}` : ""}`}
                  disabled={!currentValues.hostname || !currentValues.port || isIdlePingingConnection}
                  onClick={async () => {
                    clearPingStatus();
                    const vals = getValues();
                    await pingConnection({
                      hostname: vals.hostname,
                      username: vals.username,
                      port: vals.port,
                      password: vals.password,
                      privateKey: vals.privateKey,
                    }).catch((e) => console.error(e));
                  }}
                >
                  <LucideIcon name="refresh-ccw" />
                </Button>
              </div>
            </FormFieldLayout>

            <FormFieldLayout id="" label="SSH status" horizontal>
              <div className="flex align-center gap-5" style={{ justifyContent: "space-between", paddingTop: "4px" }}>
                {isIdleCheckingConnection ? (
                  <div className="label label-soft-default" style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
                    <Status type="secondary" idle>
                      <span> Checking...</span>
                    </Status>
                  </div>
                ) : (
                  <>
                    {!connectionSuccessful ? (
                      NotAvailable
                    ) : !!connectionSuccessful?.success ? (
                      <div className="flex align-center gap-5">
                        <LucideIcon name="wifi" color="var(--success)" />
                        <div className="label label-soft-success"> Connection successful</div>
                      </div>
                    ) : (
                      <div className="flex align-center gap-5">
                        <LucideIcon name="triangle-alert" color="var(--danger)" />
                        <div className="label label-soft-danger"> Connection failed</div>
                      </div>
                    )}
                  </>
                )}
                <Button
                  type="button"
                  className="btn btn-soft-info flex align-center gap-5"
                  title="Check connection"
                  disabled={
                    !currentValues.hostname ||
                    !currentValues.port ||
                    !currentValues.username ||
                    !currentValidationMethod ||
                    (currentValidationMethod.id === "Password" && !currentValues.password && event !== "EDIT") ||
                    (currentValidationMethod.id === "PrivateKey" && !currentValues.privateKey && event !== "EDIT") ||
                    isIdleCheckingConnection
                  }
                  onClick={async () => {
                    clearConnectionStatus();
                    const vals = getValues();
                    await checkConnection({
                      id: event === "EDIT" ? initialValues?.id : undefined,
                      hostname: vals.hostname,
                      username: vals.username,
                      port: vals.port,
                      password: vals.password,
                      privateKey: vals.privateKey,
                    }).catch((e) => console.error(e));
                  }}
                >
                  <LucideIcon name="refresh-ccw" />
                </Button>
              </div>
            </FormFieldLayout>

            <FormButtons
              disableDefaultOnClose
              groupName={bridgeConstants.frontendIndexRoute}
              entityId={initialValues ? initialValues.id : undefined}
              onClose={() => {}}
              onSubmit={async () => {
                clearPingStatus();
                clearConnectionStatus();
                const vals = getValues();
                await pingConnection({
                  hostname: vals.hostname,
                  username: vals.username,
                  port: vals.port,
                  password: vals.password,
                  privateKey: vals.privateKey,
                })
                  .then(async (r) => {
                    if (r?.success) {
                      await checkConnection({
                        id: event === "EDIT" ? initialValues?.id : undefined,
                        hostname: vals.hostname,
                        username: vals.username,
                        port: vals.port,
                        password: vals.password,
                        privateKey: vals.privateKey,
                      }).catch((e) => console.error(e));
                    }
                  })
                  .catch((e) => console.error(e));
              }}
              submitButtonLabel="Check connection"
              disabled={
                !currentValues.hostname ||
                !currentValues.username ||
                !currentValidationMethod ||
                (currentValidationMethod.id === "Password" && !currentValues.password && event !== "EDIT") ||
                (currentValidationMethod.id === "PrivateKey" && !currentValues.privateKey && event !== "EDIT")
              }
              loading={isIdlePingingConnection || isIdleCheckingConnection}
              errors={connectionErrors}
              hideCancel
              btnCls="btn btn-soft-info"
            />
          </>
        )}
      </form>
    </>
  );
};
