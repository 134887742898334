import { Dataset, DatasetFilters } from "../../api/Datasets";
import { SplitDatePickerFormField } from "../../common/formfields/DatePickerFormField/SplitDatePickerFormField";
import { ToggleFormField } from "../../common/formfields/ToggleFormField";
import { DataFormatsVirtualizedSelectForm } from "../../common/forms/EntityForms/formsVirtualized/DataFormatsVirtualizedSelectForm";
import { PersonsVirtualizedSelectForm } from "../../common/forms/EntityForms/formsVirtualized/PersonsVirtualizedSelectForm";
import { EntityFilterSideBar } from "../../common/sidebar/filtersidebar/EntityFilterBar";
import { FilterSidebarProps, useFilterSidebar } from "../../common/sidebar/filtersidebar/FilterSidebar";
import { DatasetsFilterForm, datasetDefaultValues, datasetsFormToFilters } from "../../Dataset/Table/DatasetsFilterBar";

export const AttachmentsFilterbar = ({
  initialValues,
  setFilters,
  dispatchTabStore,
  onFormChange,
  tabsLoading = false,
  currentTab,
}: FilterSidebarProps<Dataset, DatasetsFilterForm, DatasetFilters>) => {
  const { control, onReset } = useFilterSidebar({
    initialValues,
    defaultValues: datasetDefaultValues,
    formToFilters: datasetsFormToFilters,
    onFormChange,
    dispatchTabStore,
    setFilters,
  });

  return (
    <EntityFilterSideBar onReset={onReset}>
      <DataFormatsVirtualizedSelectForm
        id={"formats"}
        control={control}
        isMulti
        filters={{ ids: ["image-single", "doc_microsoft_sheet", "doc_acrobat_pdf", "molecule_compound"] }}
      />

      {currentTab !== "mydata" && (
        <PersonsVirtualizedSelectForm id="createdByIds" label="Created by" control={control} isMulti />
      )}
      <SplitDatePickerFormField
        control={control}
        idStart="createdFrom"
        idEnd="createdTo"
        label="Creation date (from - to)"
        horizontal={false}
        showTimeSelect
      />
      <PersonsVirtualizedSelectForm id="modifiedByIds" label="Modified by" control={control} isMulti />

      <SplitDatePickerFormField
        control={control}
        idStart="modifiedFrom"
        idEnd="modifiedTo"
        label="Modification date (from - to)"
        horizontal={false}
        showTimeSelect
      />

      {currentTab !== "trash" && (
        <ToggleFormField id={"includeSoftDeleted"} control={control} label={"Include trashed"} horizontal />
      )}
    </EntityFilterSideBar>
  );
};
