import { useEffect, useState, useCallback } from "react";
// Custom hook for horizontal mouse wheel scrolling
// Usage: attach the scrollRef to the desired DOM node e.g. ref={ref}

export const useHorizontalScroll = <T extends HTMLElement = HTMLElement>(onWheelCallback?: () => void) => {
  // const scrollRef = useRef<T | null>(null);
  const [node, setNode] = useState<T | null>(null);
  const ref = useCallback((node: T) => setNode(node), []);

  const onWheel = useCallback(
    (e: WheelEvent) => {
      if (node) {
        if (e.deltaY === 0) return;
        e.preventDefault();
        onWheelCallback?.();
        node.scrollTo({
          left: node.scrollLeft + e.deltaY,
          behavior: "smooth",
        });
      }
    },
    [node, onWheelCallback]
  );

  useEffect(() => {
    if (node) {
      node.addEventListener("wheel", onWheel);
      return () => node.removeEventListener("wheel", onWheel);
    }
  }, [onWheel, node]);

  return { ref, node };
};
