import { parameterTypes, serializedCommandParameterType } from "../../../ProcessingPipeline/PipelineTypes";
import { convertToTagList } from "../../../tools/ConvertToTagList/ConvertToTagList";
import { clickType } from "../../../ViewerLayout/ViewerTypes";
import { BooleanInput } from "./BooleanInput";
import { FloatInput } from "./FloatInput";
// import { ParameterSetsInput } from "./ParameterSetsInput";
import { StringInput } from "./StringInput";
import { StringListInput } from "./StringListInput";
import { TrackListPatternInput } from "./TrackListPatternInput";
import { TrackPatternInput } from "./TrackPatternInput";

import styles from "./ProcessParameterInput.module.css";
import { ParameterSetsInput } from "./ParameterSetsInput";

export const ProcessParameterInput = ({
  parameter,
  graphClick,
  setParameter,
}: {
  parameter: Record<string, any>;
  graphClick: clickType;
  setParameter: (parameterName: string, parameter: Record<string, any>) => void;
}) => {
  // const p = parameter?.tracks;
  // useEffect(() => {
  //   console.log("> ParameterViewer setParameter", parameter);
  // }, [setParameter, parameter, graphClick]);

  // console.log("Render ProcessParameterInput");

  const view = Object.entries(parameter).map(([k, v]) => {
    if ((v as serializedCommandParameterType).hidden) return null;
    // console.log(k, "->", v);
    let param: any = null;
    switch (v.parameterType as parameterTypes) {
      case parameterTypes.float:
        param = (
          <FloatInput
            key={k}
            parameterName={k}
            graphClick={graphClick}
            // setParameter={(parameter: Record<string, any>) => setParameter(k, parameter)}
            setParameter={setParameter}
            {...v}
          />
        );
        break;
      case parameterTypes.int:
        param = (
          <FloatInput
            key={k}
            parameterName={k}
            graphClick={graphClick}
            useInteger={true}
            setParameter={setParameter}
            {...v}
          />
        );
        break;
      case parameterTypes.string:
        param = <StringInput key={k} parameterName={k} setParameter={setParameter} {...v} />;
        break;
      case parameterTypes.boolean:
        param = <BooleanInput key={k} parameterName={k} setParameter={setParameter} {...v} />;
        break;
      case parameterTypes.stringList:
        param = <StringListInput key={k} parameterName={k} setParameter={setParameter} {...v} />;
        break;
      case parameterTypes.trackSelection:
        param = <TrackPatternInput key={k} parameterName={k} setParameter={setParameter} {...v} />;
        break;
      case parameterTypes.trackListSelection:
        param = (
          <TrackListPatternInput key={k} parameterName={k} setParameter={setParameter} graphClick={graphClick} {...v} />
        );
        break;
      case parameterTypes.parameterSets:
        // console.log("v", v.value[0].parameter);
        param = <ParameterSetsInput parameterName={k} setParameter={setParameter} graphClick={graphClick} {...v} />;

        break;
      default:
        return null;
    }

    // let name = v.name ?? k;
    const name = convertToTagList(v.name ?? k);

    return (
      <div key={k}>
        <span className={styles.name}>{name}:</span> <span className={styles.type}>[{v.parameterType}]</span>
        {param}
      </div>
    );
  });

  return <div style={{ paddingTop: 10 }}>{view}</div>;
};
