import { useMemo, useState } from "react";
import { SearchInput } from "../common/forms/SearchInput/SearchInput";
import styles from "./Permissions.module.css";
import { usePost } from "../api/BaseEntityApi";
import { IResultModel } from "../api/GenericTypes";
import { Permission, PermissionFilters } from "../api/Permission";
import { LoadingWrapper } from "../common/LoadingWrapper";
import { useDebouncedValue } from "../common/helperfunctions/useDebouncedValue";

export const PermissionsTable = ({ permissionsOverride }: { permissionsOverride?: Permission[] }) => {
  const [searchValue, setSearchValue] = useState<string>("");
  const debouncedSearchValue = useDebouncedValue(searchValue, 300);

  const {
    data: permissionsData,
    error: errorPermissions,
    status: statusPermissions,
    fetchStatus: fetchStatusPermissions,
  } = usePost<IResultModel<Permission>>("roles/permissions/list", {} as PermissionFilters, {
    enabled: !permissionsOverride,
  });

  const permissions = useMemo(() => {
    return permissionsOverride || permissionsData?.results.map((p) => ({ id: p.name, ...p })) || [];
  }, [permissionsData?.results, permissionsOverride]);

  return (
    <div className={styles.permissionTableContainer}>
      <div className={styles.permissionTableControlsContainer}>
        <div className="flex align-center gap-5">
          <div style={{ fontWeight: 600 }}>Permission</div>
          {permissions.length !== undefined && <span className="badge">{permissions.length}</span>}
          <div style={{ width: "100%", padding: "0 20px" }}>
            <SearchInput
              searchValue={searchValue}
              setSearchValue={setSearchValue}
              placeholder={`Search permission...`}
            />
          </div>
        </div>
      </div>
      <LoadingWrapper status={statusPermissions} fetchStatus={fetchStatusPermissions} error={errorPermissions}>
        <div style={{ width: "100%" }}>
          {permissions
            .filter((p) => p.name.toLowerCase().includes(debouncedSearchValue.toLowerCase()))
            .map((p, i) => (
              <PermissionRow permission={p} key={i} />
            ))}
        </div>
      </LoadingWrapper>
    </div>
  );
};

export const PermissionRow = ({ permission }: { permission: Permission }) => {
  return (
    <div className={styles.permissionTableRow}>
      <div className={styles.permissionTableCell} title={permission.name}>
        {permission.name}
      </div>
      <div className={styles.permissionTableCell} title={permission.longDescription}>
        {permission.longDescription}
      </div>
    </div>
  );
};
