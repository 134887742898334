import React, { memo, useCallback, useState } from "react";
import { UseFormReturn, FieldValues, FieldError } from "react-hook-form";
import { MultiEditDateFormProps } from "../common/MultiEditSelectFormTypes";
import { GenericEntity } from "../../../../api/GenericTypes";
import { checkIsDirty } from "../common/MultiEditUtils";
import { MultiEditFormWrapper } from "../common/MultiEditFormWrapper";
import { SingleDatePickerFormField } from "../../../formfields/DatePickerFormField/SingleDatePickerFormField";

const MemoComponent = memo(
  <Entity extends GenericEntity>({
    control,
    formState,
    getFieldState,
    row,
    id,
    readOnly,
    ...rest
  }: UseFormReturn<FieldValues> & MultiEditDateFormProps<Entity>) => {
    const [_isError, setIsError] = useState<FieldError | undefined>();
    const [_isDirty, setIsDirty] = useState<boolean>(false);

    const onBlur = useCallback(() => {
      setIsDirty(checkIsDirty(formState.dirtyFields?.[row.id]?.[id]));
      setIsError(getFieldState(`${row.id}.${id}`, formState).error);
    }, [formState, getFieldState, id, row.id]);

    const isDirty = checkIsDirty(formState.dirtyFields?.[row.id]?.[id]) ?? _isDirty;
    const isError = getFieldState(`${row.id}.${id}`, formState).error ?? _isError;

    return (
      <SingleDatePickerFormField
        {...rest}
        label=""
        id={`${row.id}.${id}`}
        errors={undefined} // errors will be handled at parent level
        control={control}
        classNames={`react-datepicker__custom ${readOnly ? "react-datepicker__customReadOnly" : ""} ${
          isError ? "react-datepicker__customError" : isDirty ? "react-datepicker__customIsDirty" : ""
        }`}
        onBlur={onBlur}
        uncontained
      />
    );
  },
  (prevProps, nextProps) =>
    checkIsDirty(prevProps.formState.dirtyFields?.[prevProps.row.id]?.[prevProps.id]) ===
    checkIsDirty(nextProps.formState.dirtyFields?.[nextProps.row.id]?.[nextProps.id])
);

export const MultiEditDateForm = <Entity extends GenericEntity>(props: MultiEditDateFormProps<Entity>) => {
  return <MultiEditFormWrapper>{(formProps) => <MemoComponent {...formProps} {...props} />}</MultiEditFormWrapper>;
};
