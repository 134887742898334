import { LabNotebookEntry, LabNotebookEntryFilters } from "./LabNotebookEntry";

export interface LabNotebookEntryWithTocEntries {
  labNotebookEntry: LabNotebookEntry;
  tocEntries: LabNotebookTocEntry[];
}

export interface LabNotebookTocEntry {
  labNotebookEntryId: number;
  blockId: string;
  blockIndex: number;
  blockLabel: string;
  blockType: LabNotebookTocEntryTypes;
  blockLevel: number;
}

export type LabNotebookTocEntryTypes =
  | "Header1"
  | "Header2"
  | "Header3"
  | "Header4"
  | "Header5"
  | "Header6"
  | "CalloutError"
  | "CalloutInfo"
  | "CalloutSuccess"
  | "CalloutWarning"
  | "Dataset"
  | "Sample"
  | "Inventory"
  | "Person"
  | "Project";

export const TOC_HEADER_TYPES: LabNotebookTocEntryTypes[] = [
  "Header1",
  "Header2",
  "Header3",
  "Header4",
  "Header5",
  "Header6",
];

export const TOC_TYPES: LabNotebookTocEntryTypes[] = [
  ...TOC_HEADER_TYPES,
  "Dataset",
  "Inventory",
  "Person",
  "Sample",
  "Project",
  "CalloutError",
  "CalloutInfo",
  "CalloutSuccess",
  "CalloutWarning",
];

export const TOC_TYPES_TRANSLATOR = {
  Header1: "Headers (Level 1)",
  Header2: "Headers (Level 2)",
  Header3: "Headers (Level 3)",
  Header4: "Headers (Level 4)",
  Header5: "Headers (Level 5)",
  Header6: "Headers (Level 6)",
  Dataset: "Datasets",
  Sample: "Samples",
  Inventory: "Inventories",
  Person: "Persons",
  Project: "Projects",
  CalloutError: "Error boxes",
  CalloutInfo: "Info boxes",
  CalloutSuccess: "Success boxes",
  CalloutWarning: "Warning boxes",
};

export interface LabNotebookEntryWithTocEntryFilters extends LabNotebookEntryFilters {
  blockTypes?: LabNotebookTocEntryTypes[] | null;
}
