import React, { useCallback, useContext, useMemo } from "react";
import { API } from "../api/Api";
import { useFeatureFlag } from "../common/contexts/UseFeatureFlags";
import FullDetail from "../common/panels/FullDetail/FullDetail";
import { DatasetContext, FullScreenOpts } from "./Dataset";
import { Details } from "./tiles/Details/Details";
import { Files } from "./tiles/Files/Files";
import { Parameters } from "./tiles/Parameters/Parameters";
import { Sample } from "./tiles/Sample/Sample";
import { SourceInformation } from "./tiles/SourceInformation/SourceInformation";
import { Viewer } from "./tiles/Viewer/Viewer";
import { ViewerFullscreen } from "./tiles/Viewer/ViewerFullscreen";
import { SharedContentTile } from "../common/sidebar/tiles/SharedContentTile";
import { Alert } from "../common/overlays/Alert/Alert";
import { PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import { CustomResizeHandle } from "../common/panels/misc/CustomResizeHandle";
import { CustomFieldReferencesTile } from "../common/sidebar/tiles/CustomFieldReferencesTile";

interface Props {
  api: API;
  viewViewer: boolean;
}
export const DatasetDetail = React.forwardRef(({ api, viewViewer }: Props, ref) => {
  const context = useContext(DatasetContext);
  const dataset = useMemo(() => context?.dataset.get, [context?.dataset.get]);

  const fullscreen = useMemo(() => context?.fullscreen.get, [context?.fullscreen.get]);
  const setFullScreen = useMemo(() => context?.fullscreen.set, [context?.fullscreen.set]);

  const fullScreenToggle = useCallback(
    (component: FullScreenOpts["component"]) => {
      if (fullscreen && setFullScreen) {
        setFullScreen({ ...fullscreen, state: !fullscreen.state, component: component });
      }
    },
    [fullscreen, setFullScreen]
  );

  const isElnActive = useFeatureFlag("eln");

  if (dataset?.isViewableEntity) return <Alert fit centered type="danger" message="Dataset not found!" />;
  return (
    <React.Fragment>
      {dataset && (
        <React.Fragment>
          {!viewViewer ? (
            <FullDetail.Body>
              <FullDetail.Body.Sidebar>
                <Details dataset={dataset} />
                {dataset.sample && <Sample api={api} dataset={dataset} />}
                <SourceInformation dataset={dataset} />
                {!context?.viewShared && (
                  <React.Fragment>
                    {dataset.relations && (
                      <>
                        {/* Non empty */}

                        {!!dataset.relations.sharedContent.count && (
                          <SharedContentTile
                            defaultFilters={{ datasetIds: [dataset.id] }}
                            excludeFilters={{ datasetIds: () => false }}
                          />
                        )}
                        <CustomFieldReferencesTile
                          entityType="Dataset"
                          entity={dataset}
                          labNotebookEntryFilters={isElnActive ? { datasetIds: [dataset.id] } : undefined}
                        />

                        {/* Empty */}
                        {!dataset.sample && <Sample api={api} dataset={dataset} />}
                        {!dataset.relations.sharedContent.count && <SharedContentTile disabled />}
                      </>
                    )}
                  </React.Fragment>
                )}
              </FullDetail.Body.Sidebar>

              {dataset.isDeleted ? (
                <FullDetail.Body.Content.Flex>
                  <Files dataset={dataset} fullscreen={fullscreen} fullScreenToggle={fullScreenToggle} />
                </FullDetail.Body.Content.Flex>
              ) : (
                fullscreen &&
                !fullscreen.state && (
                  <FullDetail.Body.Content>
                    <FullDetail.Body.Content.Flex>
                      <PanelGroup direction="vertical">
                        {dataset.parsingState !== "ParsedSuccessfully" ? (
                          <>
                            <FullDetail.Body.Content.Flex>
                              <Viewer api={api} dataset={dataset} fullScreenToggle={fullScreenToggle} />
                            </FullDetail.Body.Content.Flex>
                            <PanelResizeHandle />
                            <FullDetail.Body.Content.Flex>
                              <Files dataset={dataset} fullscreen={fullscreen} fullScreenToggle={fullScreenToggle} />
                            </FullDetail.Body.Content.Flex>
                          </>
                        ) : (
                          <>
                            {dataset.parsedMetadata.tracks && dataset.parsedMetadata.parameters && (
                              <>
                                <FullDetail.Body.Content.Flex>
                                  <Viewer api={api} dataset={dataset} fullScreenToggle={fullScreenToggle} />
                                </FullDetail.Body.Content.Flex>
                                <CustomResizeHandle orientation="vertical" />
                                <FullDetail.Body.Content.Flex>
                                  <PanelGroup direction="horizontal">
                                    <FullDetail.Body.Content.Flex>
                                      <Parameters
                                        api={api}
                                        dataset={dataset}
                                        fullscreen={fullscreen}
                                        fullScreenToggle={fullScreenToggle}
                                      />
                                    </FullDetail.Body.Content.Flex>
                                    <CustomResizeHandle orientation="horizontal" />
                                    <FullDetail.Body.Content.Flex>
                                      <Files
                                        dataset={dataset}
                                        fullscreen={fullscreen}
                                        fullScreenToggle={fullScreenToggle}
                                      />
                                    </FullDetail.Body.Content.Flex>
                                  </PanelGroup>
                                </FullDetail.Body.Content.Flex>
                              </>
                            )}
                            {dataset.parsedMetadata.tracks && !dataset.parsedMetadata.parameters && (
                              <>
                                <FullDetail.Body.Content.Flex>
                                  <Viewer api={api} dataset={dataset} fullScreenToggle={fullScreenToggle} />
                                </FullDetail.Body.Content.Flex>
                                <PanelResizeHandle />
                                <FullDetail.Body.Content.Flex>
                                  <Files
                                    dataset={dataset}
                                    fullscreen={fullscreen}
                                    fullScreenToggle={fullScreenToggle}
                                  />
                                </FullDetail.Body.Content.Flex>
                              </>
                            )}
                            {!dataset.parsedMetadata.tracks && dataset.parsedMetadata.parameters && (
                              <>
                                <FullDetail.Body.Content.Flex>
                                  <Parameters
                                    api={api}
                                    dataset={dataset}
                                    fullscreen={fullscreen}
                                    fullScreenToggle={fullScreenToggle}
                                  />
                                </FullDetail.Body.Content.Flex>
                                <PanelResizeHandle />
                                <FullDetail.Body.Content.Flex>
                                  <Files
                                    dataset={dataset}
                                    fullscreen={fullscreen}
                                    fullScreenToggle={fullScreenToggle}
                                  />
                                </FullDetail.Body.Content.Flex>
                              </>
                            )}
                            {!dataset.parsedMetadata.tracks && !dataset.parsedMetadata.parameters && (
                              <FullDetail.Body.Content.Flex>
                                <Files dataset={dataset} fullscreen={fullscreen} fullScreenToggle={fullScreenToggle} />
                              </FullDetail.Body.Content.Flex>
                            )}
                          </>
                        )}
                      </PanelGroup>
                    </FullDetail.Body.Content.Flex>
                  </FullDetail.Body.Content>
                )
              )}
              {fullscreen && fullscreen.state && fullscreen.component === "files" && (
                <FullDetail.Body.Content>
                  <Files dataset={dataset} fullscreen={fullscreen} fullScreenToggle={fullScreenToggle} />
                </FullDetail.Body.Content>
              )}
              {fullscreen && fullscreen.state && fullscreen.component === "parameters" && (
                <FullDetail.Body.Content>
                  <Parameters api={api} dataset={dataset} fullscreen={fullscreen} fullScreenToggle={fullScreenToggle} />
                </FullDetail.Body.Content>
              )}
            </FullDetail.Body>
          ) : (
            <FullDetail.FullBody>
              <ViewerFullscreen dataset={dataset} viewShared={context?.viewShared} />
            </FullDetail.FullBody>
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
});
