import { useCallback } from "react";
import { parameterInputTypes } from "../../../ProcessingPipeline/PipelineTypes";
import { clickType } from "../../../ViewerLayout/ViewerTypes";
import { DecoratedNumberInput } from "../../../ViewerUIElements/Range/DecoratedNumberInput";
import { GraphClickInput } from "../../../ViewerUIElements/Range/GraphClickInput";
import { GrayRange } from "../../../ViewerUIElements/Range/GrayRange";

export const FloatInput = ({
  parameterName,
  value,
  min,
  max,
  inputType,
  graphClick,
  useInteger,
  setParameter,
}: {
  parameterName: string;
  value: number;
  setParameter?: (parameterName: string, parameters: Record<string, any>) => void;
  min?: number;
  max?: number;
  inputType: parameterInputTypes;
  graphClick: clickType;
  useInteger?: boolean;
}) => {
  // const [viewer, setViewer] = useState<any>();
  // const [viewer, setViewer] = useState<any>();
  // const [userValue, setUserValue] = useState<number>(value);
  // const debouncedInput = useInputDebounce<number>(userValue, 100);

  const changeValue = useCallback(
    (value: number) => {
      if (setParameter) setParameter(parameterName, { value: value });
      // console.log("set user value", value);
    },
    [setParameter, parameterName]
  );

  // useEffect(() => {
  //   console.log("FloatInput", min, max);
  // }, [setParameter, useInteger, graphClick, min, max, value]);

  const validator = useCallback((v: number) => v >= (min ?? -Infinity) && v <= (max ?? Infinity), [min, max]);

  // useEffect(() => {
  //   console.log("FloatInput ", value);
  // }, [value, min, max, useInteger, changeValue, validator]);

  // console.log("Render", value, min, max );

  switch (inputType) {
    case parameterInputTypes.range:
      // console.log("value", value);
      return (
        <GrayRange
          // name={parameterName}
          min={min ?? 0}
          max={max ?? 10}
          value={value}
          setValue={changeValue}
          validate={validator}
          useInteger={useInteger}
        />
      );
    case parameterInputTypes.xPick:
      return (
        <GraphClickInput
          // name={parameterName}
          value={value}
          setValue={changeValue}
          // value={value}
          // setValue={(value) => {
          //   if (setParameter) setParameter({ value: value });
          // }}
          validate={validator}
          graphClick={graphClick}
          useInteger={useInteger}
        />
      );
    default:
      return (
        <DecoratedNumberInput
          // name={parameterName}
          // value={userValue}
          // setValue={setUserValue}
          value={value}
          setValue={changeValue}
          validate={validator}
          useInteger={useInteger}
        />
      );
  }
};
