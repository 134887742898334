import { EmptyParameter } from "./EmptyParameter";
import { parameterTypes, serializedStringListType } from "../PipelineTypes";

type valueType = { value: Set<string> };

export class StringSetParameter extends EmptyParameter {
  name: string = "stringSet";
  parameterType = parameterTypes.stringSet;

  value: Set<string>;

  // private listPattern: TrackPattern;

  constructor(value?: any) {
    super(value);
    const v = this.parseValue(value);
    this.value = v.value;
  }

  // get value() {
  //   return this._value;
  // }

  // set value(value: any) {
  //   if (!Array.isArray(value)) {
  //     this.errors.create({
  //       id: "",
  //       component: "StringSet.setValue",
  //       message: `Expected type 'string[]'. (Got type '${typeof value}').`,
  //     });
  //     return;
  //   }

  //   this._value = new Set<string>(value.map((v) => String(v)));
  //   console.log("VALUE", this.value);
  // }

  parseValue(value?: string[] | valueType): valueType {
    const result: valueType = { value: new Set<string>() };

    if (!Array.isArray(value)) {
      this.errors.create({
        id: "",
        component: "StringSet.setValue",
        message: `Expected type 'string[]'. (Got type '${typeof value}').`,
      });
      return result;
    }

    result.value = new Set<string>(value.map((v) => String(v)));
    return result;
  }

  serialize(): serializedStringListType {
    // const result = serializeCommandParameter(this) as serializedStringListType;
    const result = super.serialize() as serializedStringListType;
    Object.assign(result, { value: Array.from(this.value) });
    return result;
  }
}
