import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { useQueryClient } from "@tanstack/react-query";
import { useEntityDetail, useIdsOnly, useInfiniteListEntity, useRestoreMutation } from "../../api/BaseEntityApi";
import { SessionContext } from "../../common/contexts/SessionContext";
import { formatIsoDate } from "../../common/datetime/DateTimeFormatter";
import { LucideIcon } from "../../common/icon/LucideIcon";
import { getDetailLink } from "../../main/Routing";
import { ELNDeleteConfirmationModal } from "../ELNCreateOrEditForms/ELNDeleteConfirmationModal";
import { ELNModes } from "../ELNRouter/ELNRouter";
import { useELNRoutes } from "../ELNRouter/useELNRoutes";
import { LabNotebookEntriesPaginated } from "../LabNotebookEntriesPaginated/LabNotebookEntriesPaginated";
import { Modal } from "../common/ELNModal/ELNModal";
import { EntityHistory } from "../common/EntityHistory/EntityHistory";
import { ELNSaveStatus } from "../common/TextEditor/TextEditor";
import { TextEditorSidebarTile } from "../common/TextEditor/components/TextEditorSidebarTile/TextEditorSidebarTile";
import { LAB_NOTEBOOK_TOC_QUERY_KEY, LabNotebookTocTile } from "../common/tiles/LabNotebookTocTile/LabNotebookTocTile";
import { LabNotebookFilters, LabNotebook as LabNotebookReadModel } from "../types/LabNotebook";
import { LabNotebookEntryFilters, LabNotebookEntry as LabNotebookEntryReadModel } from "../types/LabNotebookEntry";
import { LabNotebookExperiment, LabNotebookExperimentFilters } from "../types/LabNotebookExperiment";
import styles from "./LabNotebook.module.css";
import { ELNLayoutContainer } from "../common/ELNLayoutContainer/ELNLayoutContainer";
import { QuickAddWrapper } from "../../common/forms/QuickAdd/common/QuickAddWrapper";
import { QuickAddLabNotebookExperimentForm } from "../../common/forms/QuickAdd/forms/QuickAddLabNotebookExperiment";
import { QuickEditWrapper } from "../../common/forms/QuickEdit/common/QuickEditWrapper";
import { QuickEditLabNotebookExperimentForm } from "../../common/forms/QuickEdit/forms/QuickEditLabNotebookExperiment";
import { QuickAddLabNotebookEntryForm } from "../../common/forms/QuickAdd/forms/QuickAddLabNotebookEntry";
import { QuickEditLabNotebookEntryForm } from "../../common/forms/QuickEdit/forms/QuickEditLabNotebookEntry";
import { QuickAddLabNotebookForm } from "../../common/forms/QuickAdd/forms/QuickAddLabNotebook";
import { QuickEditLabNotebookForm } from "../../common/forms/QuickEdit/forms/QuickEditLabNotebook";
import { useDefaultEntryCreateMutation } from "../common/tiles/LabNotebookExperimentsTile";
import { projectsConstants } from "../../api/Projects";
import { personsConstants } from "../../api/Person";
import { getNotebookEntryStringLabel, LabNotebookEntry } from "../LabNotebookEntry/LabNotebookEntry";
import Detail from "../../common/panels/Detail/Detail";

export const LabNotebook = ({
  labNotebookId,
  labNotebookExperimentId,
  labNotebookEntryId,
  mode,
  labNotebookBlockId,
}: {
  labNotebookId?: number;
  labNotebookExperimentId?: number;
  labNotebookEntryId?: number;
  mode?: ELNModes;
  labNotebookBlockId?: string;
}) => {
  // hooks
  const { route } = useContext(SessionContext);
  const { setELNRoute, getELNRoute, getQueryParam } = useELNRoutes();

  const createDefaultEntry = useDefaultEntryCreateMutation();

  // Modal states
  const [showCreateLabNotebookModal, setShowCreateLabNotebookModal] = useState<boolean>(false);
  const [showEditLabNotebookModal, setShowEditLabNotebookModal] = useState<boolean>(false);
  const [showDeleteLabNotebookModal, setShowDeleteLabNotebookModal] = useState<boolean>(false);
  const [showLabNotebookDetailsHistory, setShowLabNotebookDetailsHistory] = useState<boolean>(false);

  const [showCreateLabNotebookExperimentModal, setShowCreateLabNotebookExperimentModal] = useState<boolean>(false);
  const [showEditLabNotebookExperimentModal, setShowEditLabNotebookExperimentModal] = useState<boolean>(false);
  const [showDeleteLabNotebookExperimentModal, setShowDeleteLabNotebookExperimentModal] = useState<boolean>(false);
  const [showLabNotebookExperimentDetailsHistory, setShowLabNotebookExperimentDetailsHistory] =
    useState<boolean>(false);

  const [showCreateLabNotebookEntryModal, setShowCreateLabNotebookEntryModal] = useState<boolean>(false);
  const [showEditLabNotebookEntryModal, setShowEditLabNotebookEntryModal] = useState<boolean>(false);
  const [showDeleteLabNotebookEntryModal, setShowDeleteLabNotebookEntryModal] = useState<boolean>(false);
  const [showLabNotebookEntryHistory, setShowLabNotebookEntryHistory] = useState<boolean>(false);

  const [filters, setFilters] = useState<LabNotebookEntryFilters>({});

  useEffect(() => {
    const urlFilters = JSON.parse(getQueryParam("filters") || "{}");
    if (!!Object.keys(urlFilters).length) {
      setFilters(urlFilters);
    }
  }, [getQueryParam]);

  // LAB NOTEBOOK
  const { data: currentLabNotebook, refetch: refetchLabNotebook } = useEntityDetail<
    LabNotebookReadModel,
    LabNotebookFilters
  >(
    "lab_notebooks",
    labNotebookId ?? 0,
    {},
    {
      enabled: labNotebookId !== undefined,
      retry: false,
      onError: (e) => {
        setELNRoute({ replace: true });
      },
    }
  );

  // LAB NOTEBOOK EXPERIMENT
  const { data: currentLabNotebookExperiment, refetch: refetchLabNotebookExperiment } = useEntityDetail<
    LabNotebookExperiment,
    LabNotebookExperimentFilters
  >(
    "lab_notebook_experiments",
    labNotebookExperimentId || 0,
    {},
    {
      enabled: labNotebookExperimentId !== undefined,
      retry: false,
      onError: (e) => {
        setELNRoute({ labNotebookId, replace: true });
      },
    }
  );

  // LAB NOTEBOOK ENTRY
  const { data: currentLabNotebookEntry, refetch: refetchLabNotebookEntry } = useEntityDetail<
    LabNotebookEntryReadModel,
    LabNotebookEntryFilters
  >(
    "lab_notebook_entries",
    labNotebookEntryId || 0,
    { includeContent: false },
    {
      enabled: labNotebookEntryId !== undefined,
      retry: false,
      onError: (e) => {
        setELNRoute({ labNotebookId, labNotebookExperimentId, replace: true });
      },
    }
  );

  // add filters based on entities
  const combinedFilters = useMemo(
    () => ({
      ...filters,
      includeSoftDeleted:
        currentLabNotebook?.isDeleted ||
        currentLabNotebookExperiment?.isDeleted ||
        currentLabNotebookEntry?.isDeleted ||
        filters.includeSoftDeleted,
    }),
    [
      filters,
      currentLabNotebook?.isDeleted,
      currentLabNotebookEntry?.isDeleted,
      currentLabNotebookExperiment?.isDeleted,
    ]
  );

  const { data: experimentsData, isFetching: isFetchingExperiments } = useIdsOnly<number, LabNotebookExperimentFilters>(
    "lab_notebook_experiments",
    { labNotebookIds: labNotebookId ? [labNotebookId] : undefined, page: 1, pageSize: 1, orderBy: "CREATED_ON_DESC" },
    { enabled: !!labNotebookId && !labNotebookExperimentId }
  );
  const { data: entriesData, isFetching: isFetchingEntries } = useInfiniteListEntity<
    LabNotebookEntryReadModel,
    LabNotebookEntryFilters
  >(
    "lab_notebook_entries",
    {
      ...combinedFilters,
      labNotebookIds: !!labNotebookId ? [labNotebookId] : undefined,
      labNotebookExperimentIds: !!labNotebookExperimentId ? [labNotebookExperimentId] : undefined,
      page: 1,
      pageSize: 1,
    },
    { enabled: !labNotebookEntryId },
    "post"
  );

  const entries = useMemo(() => {
    return entriesData?.pages.map((d) => d.results).flat() || [];
  }, [entriesData?.pages]);

  const [isCheckingURL, setIsCheckingURL] = useState<boolean>(false);
  useEffect(() => {
    setIsCheckingURL(!labNotebookEntryId || !labNotebookExperimentId);
    if (!isFetchingEntries && !isFetchingExperiments) {
      if (!labNotebookEntryId && !!entries.length) {
        const timeout = setTimeout(() => {
          setELNRoute({
            labNotebookId,
            labNotebookExperimentId: entries[0].labNotebookExperiment.id,
            labNotebookEntryId: entries[0].id,
            replace: true,
          });
        }, 300);

        return () => clearTimeout(timeout);
      } else {
        if (!labNotebookExperimentId && !!experimentsData?.results.length && entries && !entries.length) {
          const timeout = setTimeout(() => {
            setELNRoute({
              labNotebookId,
              labNotebookExperimentId: experimentsData.results[0],
              replace: true,
            });
          }, 300);

          return () => clearTimeout(timeout);
        }
      }
    }
    setIsCheckingURL(false);
  }, [
    currentLabNotebookEntry,
    entries,
    experimentsData?.results,
    isFetchingEntries,
    isFetchingExperiments,
    labNotebookEntryId,
    labNotebookExperimentId,
    labNotebookId,
    setELNRoute,
  ]);

  // editor functions
  const remirrorContextRef = useRef<any>(null);
  const [saveStatus, setSaveStatus] = useState<ELNSaveStatus>({ isSaved: true, isSaving: false, isError: false });

  const queryClient = useQueryClient();

  const { mutate: restoreLabNotebook } = useRestoreMutation("lab_notebooks");
  const { mutate: restoreLabNotebookExperiment } = useRestoreMutation("lab_notebook_experiments");
  const { mutate: restoreLabNotebookEntry } = useRestoreMutation("lab_notebook_entries");

  const [activeSidebarTab, setActiveSidebarTab] = useState<string>("Toc");

  // update sidebar if mode changes
  useEffect(() => {
    if (mode !== "edit") setActiveSidebarTab((prevState) => (prevState === "AddContent" ? "Toc" : prevState));
  }, [mode]);

  if (!currentLabNotebook) return null;
  return (
    <>
      <ELNLayoutContainer
        headerParams={{
          title:
            currentLabNotebook && currentLabNotebookEntry
              ? [
                  {
                    label: currentLabNotebook.name,
                    icon: currentLabNotebook.status === "CLOSED" ? "book" : "book-open",
                    linkTo: getELNRoute({ labNotebookId: currentLabNotebook.id }),
                    iconColor: "var(--primary)",
                  },
                  {
                    label: currentLabNotebookEntry.labNotebookExperiment.name,
                    icon: "file-text",
                    linkTo: getELNRoute({
                      labNotebookId: currentLabNotebook.id,
                      labNotebookExperimentId: currentLabNotebookEntry.labNotebookExperiment.id,
                    }),
                    iconColor: "var(--secondary)",
                  },
                  {
                    label: getNotebookEntryStringLabel({ entry: currentLabNotebookEntry }),
                    icon: "pencil",
                    linkTo: getELNRoute({
                      labNotebookId: currentLabNotebook.id,
                      labNotebookExperimentId: currentLabNotebookEntry.labNotebookExperiment.id,
                      labNotebookEntryId: currentLabNotebookEntry.id,
                    }),
                    iconColor: "var(--success)",
                  },
                ]
              : currentLabNotebook && currentLabNotebookExperiment
              ? [
                  {
                    label: currentLabNotebook.name,
                    icon: currentLabNotebook.status === "CLOSED" ? "book" : "book-open",
                    linkTo: getELNRoute({ labNotebookId: currentLabNotebook.id }),
                    iconColor: "var(--primary)",
                  },
                  {
                    label: currentLabNotebookExperiment.name,
                    icon: "file-text",
                    linkTo: getELNRoute({
                      labNotebookId: currentLabNotebook.id,
                      labNotebookExperimentId: currentLabNotebookExperiment.id,
                    }),
                    iconColor: "var(--secondary)",
                  },
                ]
              : [
                  {
                    label: currentLabNotebook.name,
                    icon: currentLabNotebook.status === "CLOSED" ? "book" : "book-open",
                    linkTo: getELNRoute({ labNotebookId: currentLabNotebook.id }),
                    iconColor: "var(--primary)",
                  },
                ],
          description:
            mode === "edit" && currentLabNotebookEntry
              ? `Last saved: ${formatIsoDate(new Date(currentLabNotebookEntry.modifiedOn)).datestr} ${
                  formatIsoDate(new Date(currentLabNotebookEntry.modifiedOn)).timestr
                }`
              : !!labNotebookExperimentId
              ? currentLabNotebookExperiment?.description || "-"
              : currentLabNotebook.description || "-",
          tags:
            mode === "edit" && currentLabNotebookEntry
              ? [
                  {
                    label: currentLabNotebookEntry.createdBy.name,
                    color: "primary",
                    linkTo: route(
                      getDetailLink(personsConstants.frontendIndexRoute, currentLabNotebookEntry.createdBy.id)
                    ),
                    icon: personsConstants.icon,
                  },
                  ...currentLabNotebook.projects.map((p) => {
                    return {
                      label: p.name,
                      color: "soft-secondary" as any,
                      linkTo: route(getDetailLink(projectsConstants.frontendIndexRoute, p.id)),
                      icon: projectsConstants.icon,
                    };
                  }),
                  {
                    label: saveStatus.isSaved ? "Saved!" : saveStatus.isError ? "Failed!" : "Saving...",
                    color: saveStatus.isSaved ? "success" : saveStatus.isError ? "danger" : "secondary",
                    icon: "save",
                  },
                ]
              : [
                  {
                    label: currentLabNotebook.createdBy.name,
                    color: "primary",
                    linkTo: route(getDetailLink(personsConstants.frontendIndexRoute, currentLabNotebook.createdBy.id)),
                    icon: personsConstants.icon,
                  },
                  ...currentLabNotebook.projects.map((p) => {
                    return {
                      label: p.name,
                      color: "soft-secondary" as any,
                      linkTo: route(getDetailLink(projectsConstants.frontendIndexRoute, p.id)),
                      icon: projectsConstants.icon,
                    };
                  }),
                  {
                    label: currentLabNotebook.status === "CLOSED" ? "closed" : "",
                    color: "soft-warning",
                  },
                  {
                    label: "Notebook trashed",
                    color: "soft-warning",
                    icon: "trash-2",
                    condition: currentLabNotebook.isDeleted,
                  },
                  {
                    label: "Experiment trashed",
                    color: "soft-warning",
                    icon: "trash-2",
                    condition: !!currentLabNotebookExperiment?.isDeleted,
                  },
                  {
                    label: "Entry trashed",
                    color: "soft-warning",
                    icon: "trash-2",
                    condition: !!currentLabNotebookEntry?.isDeleted,
                  },
                ],
          controls: [
            {
              label: "New notebook",
              icon: "book",
              type: "create",
              color: "primary",
              onClick: () => setShowCreateLabNotebookModal(true),
              options: [
                {
                  label: "Edit notebook details",
                  icon: "square-pen",
                  color: "ghost-secondary",
                  onClick: () => setShowEditLabNotebookModal(true),
                  disabled: !currentLabNotebook.permissions?.edit || currentLabNotebook.isDeleted,
                },
                {
                  label: "Show notebook history",
                  icon: "clock",
                  color: "ghost-secondary",
                  onClick: () => setShowLabNotebookDetailsHistory(true),
                  disabled: !currentLabNotebook.id,
                },
                {
                  label: "Restore notebook",
                  icon: "rotate-ccw",
                  color: "ghost-success",
                  onClick: () =>
                    restoreLabNotebook(
                      { id: currentLabNotebook.id },
                      {
                        onSuccess: () => {
                          queryClient.invalidateQueries([LAB_NOTEBOOK_TOC_QUERY_KEY]);
                        },
                      }
                    ),
                  disabled: !currentLabNotebook.permissions?.edit,
                  condition: currentLabNotebook.isDeleted,
                },
                {
                  label: "Trash notebook",
                  icon: "trash-2",
                  color: "ghost-warning",
                  onClick: () => setShowDeleteLabNotebookModal(true),
                  disabled: !currentLabNotebook.permissions?.edit,
                  condition: !currentLabNotebook.isDeleted,
                },
              ],
            },
            {
              label: "New experiment",
              icon: "file-text",
              type: "create",
              color: "primary",
              onClick: () => setShowCreateLabNotebookExperimentModal(true),
              disabled: !currentLabNotebook?.permissions?.edit || currentLabNotebook.status === "CLOSED",
              options: currentLabNotebookExperiment
                ? [
                    {
                      label: "Edit experiment details",
                      icon: "square-pen",
                      color: "ghost-secondary",
                      onClick: () => setShowEditLabNotebookExperimentModal(true),
                      disabled:
                        !currentLabNotebookExperiment.permissions?.edit ||
                        currentLabNotebook.status === "CLOSED" ||
                        currentLabNotebookExperiment.isDeleted,
                    },
                    {
                      label: "Show experiment history",
                      icon: "clock",
                      color: "ghost-secondary",
                      onClick: () => setShowLabNotebookExperimentDetailsHistory(true),
                    },
                    {
                      label: "Restore experiment",
                      icon: "rotate-ccw",
                      color: "ghost-success",
                      onClick: () =>
                        restoreLabNotebookExperiment(
                          { id: currentLabNotebookExperiment.id },
                          {
                            onSuccess: () => {
                              queryClient.invalidateQueries([LAB_NOTEBOOK_TOC_QUERY_KEY]);
                            },
                          }
                        ),
                      disabled:
                        !currentLabNotebookExperiment.permissions?.edit || currentLabNotebook.status === "CLOSED",
                      condition: currentLabNotebookExperiment.isDeleted,
                    },
                    {
                      label: "Trash experiment",
                      icon: "trash-2",
                      color: "ghost-warning",
                      onClick: () => setShowDeleteLabNotebookExperimentModal(true),
                      disabled:
                        !currentLabNotebookExperiment.permissions?.edit || currentLabNotebook.status === "CLOSED",
                      condition: !currentLabNotebookExperiment.isDeleted,
                    },
                  ]
                : undefined,
            },
            {
              label: "New entry",
              icon: "pencil",
              type: "create",
              color: "primary",
              onClick: () => setShowCreateLabNotebookEntryModal(true),
              disabled: !currentLabNotebookExperiment?.permissions?.edit || currentLabNotebook.status === "CLOSED",
              options: currentLabNotebookEntry
                ? [
                    {
                      label: "Edit entry details",
                      icon: "square-pen",
                      color: "ghost-secondary",
                      onClick: () => setShowEditLabNotebookEntryModal(true),
                      disabled:
                        !currentLabNotebookEntry.permissions?.edit ||
                        currentLabNotebook.status === "CLOSED" ||
                        currentLabNotebookEntry.isDeleted,
                    },
                    {
                      label: "Show entry history",
                      icon: "clock",
                      color: "ghost-secondary",
                      onClick: () => setShowLabNotebookEntryHistory(true),
                    },
                    {
                      label: "Restore entry",
                      icon: "rotate-ccw",
                      color: "ghost-success",
                      onClick: () =>
                        restoreLabNotebookEntry(
                          { id: currentLabNotebookEntry.id },
                          {
                            onSuccess: () => {
                              queryClient.invalidateQueries([LAB_NOTEBOOK_TOC_QUERY_KEY]);
                            },
                          }
                        ),
                      disabled: !currentLabNotebookEntry.permissions?.edit || currentLabNotebook.status === "CLOSED",
                      condition: currentLabNotebookEntry.isDeleted,
                    },
                    {
                      label: "Trash entry",
                      icon: "trash-2",
                      color: "ghost-warning",
                      onClick: () => setShowDeleteLabNotebookEntryModal(true),
                      disabled: !currentLabNotebookEntry.permissions?.edit || currentLabNotebook.status === "CLOSED",
                      condition: !currentLabNotebookEntry.isDeleted,
                    },
                  ]
                : undefined,
            },
          ],
          showDashboardButton: true,
        }}
      >
        <Detail
          sidebarPosition="left"
          sidebarStyle={{ padding: "0" }}
          sidebarMinSize={Math.max(
            100 - Math.ceil(100 * ((window.innerWidth - 500) / Math.max(window.innerWidth, 1))), // minimum width of 500px
            10
          )}
          sidebar={
            <>
              <div>
                <Tabs
                  defaultActiveKey="Toc"
                  activeKey={activeSidebarTab}
                  id="tabs-menue"
                  bsStyle="tabs"
                  onSelect={(k) => setActiveSidebarTab(k)}
                  justified
                  style={{ backgroundColor: "var(--gray-50)", padding: "2px 0px 0px 0px" }}
                >
                  <Tab
                    eventKey="Toc"
                    title={
                      <div
                        style={{
                          display: "grid",
                          alignItems: "center",
                          width: "100%",
                          height: mode === "edit" ? "19px" : "31px",
                        }}
                      >
                        <div
                          data-toggle="tooltip"
                          title="Table of content"
                          style={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            width: "100%",
                            whiteSpace: "nowrap",
                          }}
                        >
                          <LucideIcon name="menu" />
                          <span> Table of content</span>
                        </div>
                      </div>
                    }
                  />
                  <Tab
                    eventKey="AddContent"
                    disabled={mode !== "edit"}
                    title={
                      <div
                        style={{
                          display: "grid",
                          alignItems: "center",
                          width: "100%",
                          height: mode === "edit" ? "19px" : "31px",
                        }}
                      >
                        <div
                          data-toggle="tooltip"
                          title="Add content"
                          style={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            width: "100%",
                            whiteSpace: "nowrap",
                          }}
                        >
                          <LucideIcon name="plus" />
                          <span> Add content</span>
                        </div>
                      </div>
                    }
                  />
                </Tabs>
              </div>

              <div style={{ height: "100%", padding: "0 10px" }}>
                {activeSidebarTab === "Toc" && (
                  <div className={styles.LabNotebookDetailsContainer}>
                    <LabNotebookTocTile
                      labNotebookId={currentLabNotebook.id}
                      labNotebookExperimentId={currentLabNotebookExperiment?.id}
                      labNotebookEntryId={currentLabNotebookEntry?.id}
                      mode={mode}
                      activeLabNotebookBlockId={labNotebookBlockId}
                      filters={combinedFilters}
                      setFilters={setFilters}
                    />
                  </div>
                )}
                {activeSidebarTab === "AddContent" && (
                  <div className={styles.LabNotebookDetailsContainer}>
                    <TextEditorSidebarTile remirrorContextRef={remirrorContextRef} />
                  </div>
                )}
              </div>
            </>
          }
          mainStyle={{ padding: "0", margin: 0 }}
          main={
            <div
              className={styles.LabNotebookContentContainer}
              style={{ backgroundColor: "var(--gray-100)", minWidth: "210mm", height: "100%", overflow: "hidden" }}
            >
              {mode === "edit" && labNotebookEntryId ? (
                <LabNotebookEntry
                  labNotebookId={labNotebookId}
                  labNotebookExperimentId={labNotebookExperimentId}
                  labNotebookEntryId={labNotebookEntryId}
                  labNotebookBlockId={labNotebookBlockId}
                  mode={mode}
                  remirrorContextRef={remirrorContextRef}
                  setSaveStatus={setSaveStatus}
                />
              ) : (
                <LabNotebookEntriesPaginated
                  labNotebookId={labNotebookId}
                  labNotebookExperimentId={labNotebookExperimentId}
                  labNotebookEntryId={labNotebookEntryId}
                  mode={mode}
                  labNotebookBlockId={labNotebookBlockId}
                  setShowCreateLabNotebookEntry={() => setShowCreateLabNotebookEntryModal(true)}
                  setShowCreateLabNotebookExperiment={() => setShowCreateLabNotebookExperimentModal(true)}
                  filters={{
                    ...filters,
                    labNotebookIds: [currentLabNotebook.id],
                    includeSoftDeleted:
                      currentLabNotebook.isDeleted ||
                      currentLabNotebookExperiment?.isDeleted ||
                      currentLabNotebookEntry?.isDeleted ||
                      filters.includeSoftDeleted,
                  }}
                  showCreateButtonsOnEmpty={!!currentLabNotebook.permissions?.edit}
                  isLoading={
                    (!labNotebookExperimentId && isFetchingExperiments) ||
                    (!labNotebookEntryId && isFetchingEntries) ||
                    isCheckingURL
                  }
                />
              )}
            </div>
          }
        />
      </ELNLayoutContainer>

      {/* Notebook Modals */}
      {/* Create */}
      {showCreateLabNotebookModal && (
        <QuickAddWrapper<LabNotebookReadModel>
          showModal={showCreateLabNotebookModal}
          setShowModal={setShowCreateLabNotebookModal}
          onCreate={(newNotebook) => {
            if (newNotebook && newNotebook.id > 0) {
              queryClient.invalidateQueries([LAB_NOTEBOOK_TOC_QUERY_KEY]);
              queryClient.invalidateQueries(["lab_notebooks"]);
              queryClient.invalidateQueries(["lab_notebook_experiments"]);
              queryClient.invalidateQueries(["lab_notebook_entries"]);
              setELNRoute({ labNotebookId: newNotebook.id });
            }
          }}
        >
          {(props) => <QuickAddLabNotebookForm {...props} initialValues={{ status: "ACTIVE" }} />}
        </QuickAddWrapper>
      )}

      {/* Edit */}
      {currentLabNotebook && showEditLabNotebookModal && (
        <QuickEditWrapper<LabNotebookReadModel>
          showModal={showEditLabNotebookModal}
          setShowModal={setShowEditLabNotebookModal}
          onEdit={(editedLabNotebook) => {
            queryClient.invalidateQueries([LAB_NOTEBOOK_TOC_QUERY_KEY]);
            queryClient.invalidateQueries(["lab_notebooks"]);
          }}
        >
          {(props) => <QuickEditLabNotebookForm {...props} initialValues={currentLabNotebook} />}
        </QuickEditWrapper>
      )}

      {/* Delete */}
      <ELNDeleteConfirmationModal
        isOpen={showDeleteLabNotebookModal}
        setIsOpen={setShowDeleteLabNotebookModal}
        id={currentLabNotebook.id}
        name={currentLabNotebook.name.length > 25 ? "notebook" : currentLabNotebook.name}
        entityInfo={{ "Lab notebook": currentLabNotebook.name, "Description": currentLabNotebook.description }}
        resource="lab_notebooks"
        onDelete={() => {
          queryClient.invalidateQueries([LAB_NOTEBOOK_TOC_QUERY_KEY]);
          queryClient.invalidateQueries(["lab_notebooks"]);
          queryClient.invalidateQueries(["lab_notebook_experiments"]);
          queryClient.invalidateQueries(["lab_notebook_entries"]);
          setShowDeleteLabNotebookModal(false);
        }}
        description="Proceeding will move the selected notebook into trash."
        deletePermanently={false}
      />
      {/* History */}
      {currentLabNotebook && (
        <Modal isOpen={showLabNotebookDetailsHistory} onClose={() => setShowLabNotebookDetailsHistory(false)}>
          <div style={{ width: "90vw", height: "90vh" }}>
            <EntityHistory<LabNotebookReadModel>
              resource="lab_notebooks"
              id={currentLabNotebook.id}
              onRestore={() => {
                setShowLabNotebookDetailsHistory(false);
                refetchLabNotebook();
              }}
            />
          </div>
        </Modal>
      )}

      {/* Experiment Modals */}
      {/* Create */}
      {showCreateLabNotebookExperimentModal && !!currentLabNotebook && (
        <QuickAddWrapper<LabNotebookExperiment>
          showModal={showCreateLabNotebookExperimentModal}
          setShowModal={setShowCreateLabNotebookExperimentModal}
          onCreate={(newExperiment) => {
            if (newExperiment && newExperiment.id > 0) {
              queryClient.invalidateQueries([LAB_NOTEBOOK_TOC_QUERY_KEY]);
              queryClient.invalidateQueries(["lab_notebooks"]);
              queryClient.invalidateQueries(["lab_notebook_experiments"]);
              queryClient.invalidateQueries(["lab_notebook_entries"]);
              createDefaultEntry(newExperiment);
              setELNRoute({ labNotebookId: newExperiment.labNotebook.id, labNotebookExperimentId: newExperiment.id });
            }
          }}
        >
          {(props) => (
            <QuickAddLabNotebookExperimentForm
              {...props}
              initialValues={{ labNotebook: currentLabNotebook, status: "RUNNING" }}
            />
          )}
        </QuickAddWrapper>
      )}

      {/* Edit */}
      {currentLabNotebookExperiment && showEditLabNotebookExperimentModal && (
        <QuickEditWrapper<LabNotebookExperiment>
          showModal={showEditLabNotebookExperimentModal}
          setShowModal={setShowEditLabNotebookExperimentModal}
          onEdit={(editedLabNotebookExperiment) => {
            queryClient.invalidateQueries([LAB_NOTEBOOK_TOC_QUERY_KEY]);
            queryClient.invalidateQueries(["lab_notebooks"]);
            queryClient.invalidateQueries(["lab_notebook_experiments"]);
          }}
        >
          {(props) => <QuickEditLabNotebookExperimentForm {...props} initialValues={currentLabNotebookExperiment} />}
        </QuickEditWrapper>
      )}

      {/* Delete */}
      {currentLabNotebookExperiment && showDeleteLabNotebookExperimentModal && (
        <ELNDeleteConfirmationModal
          isOpen={showDeleteLabNotebookExperimentModal}
          setIsOpen={setShowDeleteLabNotebookExperimentModal}
          id={currentLabNotebookExperiment.id}
          name={currentLabNotebookExperiment.name.length > 25 ? "experiment" : currentLabNotebookExperiment.name}
          entityInfo={{
            "Experiment": currentLabNotebookExperiment.name,
            "Description": currentLabNotebookExperiment.description,
            "Part of": currentLabNotebookExperiment.labNotebook.name,
          }}
          resource="lab_notebook_experiments"
          onDelete={() => {
            queryClient.invalidateQueries([LAB_NOTEBOOK_TOC_QUERY_KEY]);
            queryClient.invalidateQueries(["lab_notebook_experiments"]);
            queryClient.invalidateQueries(["lab_notebook_entries"]);
            setShowDeleteLabNotebookExperimentModal(false);
          }}
          description="Proceeding will move the selected experiment into trash."
          deletePermanently={false}
        />
      )}
      {/* History */}
      {currentLabNotebookExperiment && (
        <Modal
          isOpen={showLabNotebookExperimentDetailsHistory}
          onClose={() => setShowLabNotebookExperimentDetailsHistory(false)}
        >
          <div style={{ width: "90vw", height: "90vh" }}>
            <EntityHistory<LabNotebookExperiment>
              resource="lab_notebook_experiments"
              id={currentLabNotebookExperiment.id}
              onRestore={() => {
                setShowLabNotebookExperimentDetailsHistory(false);
                refetchLabNotebookExperiment();
              }}
            />
          </div>
        </Modal>
      )}

      {/* Entry Modals */}
      {/* Create */}
      {showCreateLabNotebookEntryModal && !!currentLabNotebook && !!currentLabNotebookExperiment && (
        <QuickAddWrapper<LabNotebookEntryReadModel>
          showModal={showCreateLabNotebookEntryModal}
          setShowModal={setShowCreateLabNotebookEntryModal}
          onCreate={(newEntry) => {
            if (newEntry && newEntry.id > 0) {
              queryClient.invalidateQueries([LAB_NOTEBOOK_TOC_QUERY_KEY]);
              queryClient.invalidateQueries(["lab_notebooks"]);
              queryClient.invalidateQueries(["lab_notebook_experiments"]);
              queryClient.invalidateQueries(["lab_notebook_entries"]);
              setELNRoute({
                labNotebookId: newEntry.labNotebook.id,
                labNotebookExperimentId: newEntry.labNotebookExperiment.id,
                labNotebookEntryId: newEntry.id,
              });
            }
          }}
        >
          {(props) => (
            <QuickAddLabNotebookEntryForm
              {...props}
              initialValues={{
                labNotebookExperiment: currentLabNotebookExperiment,
                labNotebook: currentLabNotebook,
                entryDate: new Date().toISOString(),
              }}
            />
          )}
        </QuickAddWrapper>
      )}

      {/* Edit */}
      {currentLabNotebookEntry && showEditLabNotebookEntryModal && (
        <QuickEditWrapper<LabNotebookEntryReadModel>
          showModal={showEditLabNotebookEntryModal}
          setShowModal={setShowEditLabNotebookEntryModal}
          onEdit={(editedLabNotebookEntry) => {
            queryClient.invalidateQueries([LAB_NOTEBOOK_TOC_QUERY_KEY]);
            queryClient.invalidateQueries(["lab_notebooks"]);
            queryClient.invalidateQueries(["lab_notebook_experiments"]);
            queryClient.invalidateQueries(["lab_notebook_entries"]);
          }}
        >
          {(props) => <QuickEditLabNotebookEntryForm {...props} initialValues={currentLabNotebookEntry} />}
        </QuickEditWrapper>
      )}

      {/* Delete */}
      {currentLabNotebookEntry && showDeleteLabNotebookEntryModal && (
        <ELNDeleteConfirmationModal
          isOpen={showDeleteLabNotebookEntryModal}
          setIsOpen={setShowDeleteLabNotebookEntryModal}
          id={currentLabNotebookEntry.id}
          name={getNotebookEntryStringLabel({ entry: currentLabNotebookEntry })}
          entityInfo={{
            "Entry": getNotebookEntryStringLabel({ entry: currentLabNotebookEntry }),
            "Part of":
              currentLabNotebookEntry.labNotebook.name + " - " + currentLabNotebookEntry.labNotebookExperiment.name,
          }}
          resource="lab_notebook_entries"
          onDelete={() => {
            queryClient.invalidateQueries([LAB_NOTEBOOK_TOC_QUERY_KEY]);
            queryClient.invalidateQueries(["lab_notebook_entries"]);
            setShowDeleteLabNotebookEntryModal(false);
          }}
          description="Proceeding will move the selected entry into trash."
          deletePermanently={false}
        />
      )}
      {/* History */}
      {currentLabNotebookEntry && (
        <Modal isOpen={showLabNotebookEntryHistory} onClose={() => setShowLabNotebookEntryHistory(false)}>
          <div style={{ width: "90vw", height: "90vh" }}>
            <EntityHistory<LabNotebookEntryReadModel>
              resource="lab_notebook_entries"
              id={currentLabNotebookEntry.id}
              onRestore={() => {
                setShowLabNotebookEntryHistory(false);
                refetchLabNotebookEntry();
                setELNRoute({ labNotebookId, labNotebookExperimentId, labNotebookEntryId, mode: "view" });
              }}
            />
          </div>
        </Modal>
      )}
    </>
  );
};
