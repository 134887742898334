import { Color, Track } from "../ViewerLayout/ViewerLayoutTypes";
import { plotTrackType } from "../ViewerLayout/ViewerTypes";
import { StackTraces } from "./StackTrace";

type internalParameterType = Record<string, any>;

export type SerializedPipelineCommand = {
  command: string;
  name?: string;
  commandName?: string;
  id: string;
  readonly?: boolean;
  parameter?: Record<string, any>;
  warnings?: string;
  errors?: string;
  selected?: boolean;
};

export type SerializedPipelineConnector = {
  command: string;
  name?: string;
  copy: boolean;
  cached: boolean;
  id: string;
  mapping?: [number, number][];
  warnings?: string;
  errors?: string;
  inputNumber?: number;
  outputNumber?: number;
};

export type PipelineRunningState = "ok" | "failed" | "running" | "undefined";

export type SerializedPipelineEntry = SerializedPipelineCommand | SerializedPipelineConnector;

export type pipelineSetting = {
  pipelineId: string;
  nodeId?: string;
  settings: Record<string, any>;
};

export interface SerializedPipeline {
  name: string;
  id: string;
  version: string;
  settings: PipelineSettings;
  pipeline: SerializedPipelineEntry[];
  warnings: string[];
  errors: string[];
  nodeErrorCount?: number;
}

export type PipelineSettings = {
  autorun: boolean;
  selectedNode?: string;
};

export const initPipelineSettings = (init?: PipelineSettings): PipelineSettings => {
  const settings: PipelineSettings = { autorun: false };

  initType(settings, init);
  return settings;
};

// export interface Pipeline {
//   id: string;
//   index: number;
//   name: string;
//   nodes: Record<string, PipelineNode>;
//   connectors: Record<string, PipelineConnector>;
//   start: string | undefined;
//   settings: PipelineSettings;
// }

export const initType = <T>(node: T, init?: T): void => {
  if (init)
    for (let key of Object.keys(node as any)) {
      if (key in init) (node[key as keyof T] as any) = init[key as keyof T];
    }
};

// export const initPipeline = (init?: Pipeline): Pipeline => {
//   const pipe: Pipeline = {
//     name: "Node",
//     id: generateUid(),
//     start: undefined,
//     nodes: {},
//     connectors: {},
//     index: -1,
//     settings: initPipelineSettings(),
//   };

//   initType(pipe, init);
//   return pipe;
// };

export type stateType = Record<string, any>;

// export interface PipelineNode {
//   name: string;
//   id: string;

//   command?: PipelineCommand;
//   run: (tracks: trackListType) => trackListType;
//   reset: () => void;

//   connections: string[];
//   switcher: () => number;
//   index: number;
//   errors: StackTraces;
//   warnings: StackTraces;
// }

// export interface PipelineConnector {
//   name: string;
//   id: string;
//   previous?: string;
//   next?: string;
//   inputNumber: number;
//   outputNumber: number;
//   mapping: Record<number, number>; // optional mapping <output index, connected input>
//   connect: (tracks: trackListType) => trackListType;
// }

// export interface ParameterParser {
//   errors: StackTraces;
//   warnings: StackTraces;
//   type: parameterTypes | undefined;
// }

export enum commandTypes {
  trackSelector = "trackSelector",
  trackAdder = "trackAdder",
  zeroFill = "zeroFill",
  windowFunction = "windowFunction",
  complexFFT = "complexFFT",
  nmrAxis = "nmrAxis",
  phaseNMRSpectrum = "phaseNMRSpectrum",
  autophaseNMRSpectrum = "autophaseNMRSpectrum",
  showTrack = "showTrack",
  empty = "empty",
  nullify = "nullify",
  trackRenamer = "trackRenamer",
  test = "test",
}

export type argumentType = {
  name: string;
  type: plotTrackType;
};

export enum parameterInputTypes {
  default = "default",
  range = "range",
  xPick = "xPick",
  options = "options",
}

export type parameterSettingType = {
  name?: string;
  hidden?: boolean;
  value?: any;
};

export interface CommandParameter {
  name?: string;
  value: any;
  hidden?: boolean;
  readonly?: boolean;
  parameterType: parameterTypes;
  inputType: parameterInputTypes;
  errors: StackTraces;
  warnings: StackTraces;
  serialize: (storageMode?: boolean) => any;
  deserialize: (value?: any) => any;
}

export type serializedCommandParameterType = {
  parameterType: parameterTypes;
  inputType: parameterInputTypes;
  hidden?: boolean;
  readonly?: boolean;
  name?: string;
};

// export const serializeCommandParameter = (p: CommandParameter): serializedCommandParameterType => {
//   return { parameterType: p.parameterType, inputType: p.inputType, hidden: p.hidden };
// };

export type serializedNumberType = serializedCommandParameterType & { value: number; min?: number; max?: number };
export type serializedStringType = serializedCommandParameterType & {
  value: string;
  options?: string[];
  readonly?: boolean;
};
export type serializedBooleanType = serializedCommandParameterType & { value: boolean };
export type serializedStringListType = serializedCommandParameterType & { value: string[] };
export type SerializedTrackSelection = serializedCommandParameterType & {
  regex?: string;
  trackName?: string;
  index?: [number, number];
  selected?: string;
  tracks?: SerializedPipeTrackType[];
  type?: string;
};
export type SerializedTrackListSelection = serializedCommandParameterType & {
  value: SerializedTrackSelection[];
  selected?: (string | undefined)[];
  tracks?: (undefined | SerializedPipeTrackType[])[];
};

export type SerializedParameterSets = serializedCommandParameterType & {
  selected: string;
  value: {
    name: string;
    parameter: (serializedNumberType | serializedStringType | serializedBooleanType | serializedStringListType)[];
  }[];
};

export enum parameterTypes {
  string = "string",
  int = "int",
  boolean = "boolean",
  float = "float",
  trackSelection = "trackSelection",
  trackListSelection = "trackListSelection",
  stringSet = "stringSet",
  stringList = "stringList",
  readonlyNumber = "readonlyNumber",
  parameterSets = "parameterSets",
  null = "null",
}

export type SerializedPipeTrackType = {
  name: string;
  id: string;
  color?: Color;
};

export type pipeTrackType = Track & { internalParameter: internalParameterType };

// export type trackListElement = trackType | undefined;
export type trackListElement = pipeTrackType | undefined;
export type trackListType = trackListElement[];

export interface PipelineCommand {
  name: string;
  description?: string;
  id: string;
  type: commandTypes;
  errors: StackTraces;
  warnings: StackTraces;
  readonly input: argumentType[];
  readonly output: argumentType[];
  parameterTypes: Record<string, parameterTypes>;
  internalParameter: Record<string, CommandParameter>;
  parameterSettings: Record<string, parameterSettingType>;
  parameter: Record<string, any>;
  updatedParameter?: Record<string, any>;
  // run: (tracks: trackListType, parameter: Record<string, commandParameter>) => trackListType;
  run: (tracks: trackListType) => trackListType;
  reset: () => void;
  serialize: () => SerializedPipelineCommand;
  serializeParameters: () => Record<string, any>;
  deserializeParameters: (parameter: Record<string, any>) => void;
}

// type ProtoExtends<T, U> = U & Omit<T, keyof U>;
// interface PipelineCommandGlobalExtend {
//   run: (internalTracks: trackType[], tracks: trackType[]) => trackType[];
// }
// export type PipelineCommandGlobal = ProtoExtends<PipelineCommand, PipelineCommandGlobalExtend>;

const funcNameRegex = /function (.{1,})\(/;
const classNameRegex = /class (.*) {/;
export const getObjectName = (obj: any): string => {
  let results = classNameRegex.exec(obj.constructor.toString());
  if (results && results.length > 1) return results[1];
  results = funcNameRegex.exec(obj.constructor.toString());
  return results && results.length > 1 ? results[1] : "";
};

// export interface messageType {
//   component: string;
//   message: string;
//   id: string;
// }

// export type stackTraceType = messageType[];

// export const initStackTraceType = (init?: messageType): stackTraceType => {
//   return [{ id: init?.id ?? "", component: init?.component ?? "", message: init?.message ?? "" }];
// };

// export const addTrace = (messages: StackTraces, init?: messageType) => {
//   messages.push(initStackTraceType(init));
// };

// export const addMessageToTrace = (message: messageType, subTrace: stackTraceType): stackTraceType => {
//   return [message].concat(subTrace);
// };

// export const traceToString = (trace: stackTraceType, indent: number = 2): string => {
//   return trace.map((m, i) => `${" ".repeat(i * indent)}<${m.component}>  ${m.message}`).join("\n");
// };

// export const traceListToString = (traceList: StackTraces, indent: number = 2): string => {
//   return traceList.map((m) => traceToString(m, indent)).join("\n\n");
// };

export const range = (n: number): number[] => {
  var x = [];
  var i = 0;
  while (x.push(i++) < n) {}
  return x;
};

export const deepCopy = <T>(data: any): T => {
  if (data === null) return data;
  // console.log("copy ", typeof data);
  if (typeof data === "object") {
    if (Array.isArray(data)) {
      // console.log("<= array");
      return data.map((d) => deepCopy(d)) as any;
    } else {
      // console.log("object", data instanceof Float32Array, data instanceof ArrayBuffer);
      if (
        data instanceof Float32Array ||
        data instanceof Int16Array ||
        data instanceof Float64Array ||
        data instanceof Int32Array ||
        data instanceof Uint8Array
      )
        return data.slice() as any;
      return Object.fromEntries(Object.entries(data).map(([k, v]) => [k, deepCopy(v)])) as any;
    }
  } else {
    // console.log("<= data");
    return data;
  }
};
