import React from "react";
import { Alert } from "../../../common/overlays/Alert/Alert";
import { LucideIcon } from "../../../common/icon/LucideIcon";
import { ManualUploadActions } from "../FileHandlingUtils";

interface RenderNoResultsProps {
  dispatch: React.Dispatch<ManualUploadActions>;
  message?: string;
}
export const RenderNoResults = ({ dispatch, message = "Unrecognized data format" }: RenderNoResultsProps) => {
  return (
    <Alert
      message={
        <div className="flex col-nowrap align-center" style={{ width: "100%" }}>
          <h3>
            <div className="flex row-nowrap align-center gap-5">
              <LucideIcon name="info" /> {message}
            </div>
          </h3>

          <span>
            <button
              className="btn btn-default"
              onClick={() => {
                window.location.href = "https://logs-repository.atlassian.net/servicedesk/customer/portal/4";
              }}
            >
              <LucideIcon name="upload" /> Submit new data format
            </button>
          </span>
        </div>
      }
      onDismiss={() => dispatch({ type: "clear" })}
      dismissible
      type="primary"
      fit
      //   centered
    />
  );
};
