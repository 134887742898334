import { Bridge, BridgeType } from "./Bridges";
import { DataSourceStatus as DataSourceStatusHistory } from "./DataSourceStatus";
import {
  ICreatedRecordParametersTranslator,
  ICreationRecordLabels,
  IGenericRequestParametersTranslator,
  IModificationRecordLabels,
  IModifiedRecordParametersTranslator,
  INamedEntityLabels,
  INamedEntityParametersTranslator,
  IPaginationParametersTranslator,
  IPermissionedEntityLabels,
  IRelatedEntityLabels,
  IRelationParametersTranslator,
  PropertyTranslator,
} from "./GenericTranslator";
import {
  DefaultCreatedByRecordOrderTypeConsts,
  DefaultCreatedOnRecordOrderTypeConsts,
  DefaultModifiedByRecordOrderTypeConsts,
  DefaultModifiedOnRecordOrderTypeConsts,
  DefaultOrderTypeConsts,
  EntityConstants,
  ICreatedRecordParameters,
  ICreationRecord,
  IEntityMinimalModel,
  IGenericRequestParameters,
  IModelWithIntId,
  IModificationRecord,
  IModifiedRecordParameters,
  INamedEntity,
  INamedEntityParameters,
  IPaginationParameters,
  IPermissionedEntity,
  IRelatedEntity,
  IRelation,
  IRelationModel,
  IRelationParameters,
} from "./GenericTypes";
import { Parser } from "./Parsers";

export const dataSourceConstants: EntityConstants<DataSource, DataSourceFilters> = {
  resource: "data_sources",
  frontendIndexRoute: "data_sources",
  entitySingular: "data source",
  entityPlural: "data sources",
  icon: "database-backup",
};
export interface DataSourceLastRunStatus {
  isConnected: boolean;
  nextScheduledDate: string;
  lastClientStatus?: DataSourceStatusHistory;
}
export interface FileExcludePattern {
  name: string;
  regex: string;
}
export type DataSourceType = "Crawler" | "IconNMR";
export const DataSourceTypes = ["Crawler", "IconNMR"] as DataSourceType[];
export const DataSourceTypeFormValues = DataSourceTypes.map((t) => ({ id: t, name: t }));
export interface DataSource
  extends IPermissionedEntity,
    IModelWithIntId,
    INamedEntity,
    ICreationRecord,
    IModificationRecord,
    IRelatedEntity<DataSourceRelations> {
  type: DataSourceType;
  enabled: boolean;
  bridge: Bridge;
  format: IEntityMinimalModel<string>;
  directories: string[];
  intervalInSeconds: number;
  method: IEntityMinimalModel;
  instrument?: IEntityMinimalModel;
  cutoffDate?: string | null;
  customImport?: IEntityMinimalModel<string>;
  parserDefinitions: {
    [parserId: string]: Parser;
  };
  status?: DataSourceLastRunStatus;
  fileExcludePatterns?: FileExcludePattern[];
}
export interface DataSourceSuggestions extends IEntityMinimalModel<DataSource["id"]> {}

export interface DataSourceFilters
  extends IGenericRequestParameters<DataSource, DataSourceSortingOptions>,
    IPaginationParameters,
    ICreatedRecordParameters,
    IModifiedRecordParameters,
    IRelationParameters,
    INamedEntityParameters {
  enabled?: boolean | null;
  bridgeIds?: number[] | null;
  bridgeTypes?: BridgeType[] | null;
  datasetIds?: number[] | null;
  formatIds?: string[] | null;
  customImportIds?: string[] | null;
  directories?: string[] | null;
  methodIds?: number[] | null;
  instrumentIds?: number[] | null;
  sourceHostnames?: string[] | null;
  sourceIpAddresses?: string[] | null;
  types?: DataSourceType[] | null;
  includeStatusHistory?: boolean | null;
}

export const DataSourceFiltersTranslator: PropertyTranslator<DataSourceFilters> = {
  ...IPaginationParametersTranslator,
  ...IGenericRequestParametersTranslator,
  ...INamedEntityParametersTranslator,
  ...ICreatedRecordParametersTranslator,
  ...IModifiedRecordParametersTranslator,
  ...IRelationParametersTranslator,
  enabled: "Is enabled",
  formatIds: "Data formats",
  customImportIds: "Metadata mappings",
  directories: "Directories",
  bridgeIds: "Bridge IDs",
  bridgeTypes: "Bridge types",
  datasetIds: "Dataset IDs",
  methodIds: "Method IDs",
  instrumentIds: "Instrument IDs",
  sourceHostnames: "Bridge Hostnames",
  sourceIpAddresses: "Bridge IP-Address(es)",
  types: "Types",
  includeStatusHistory: "Include status history",
} as const;

export const DataSourceSortingOptionsConsts = [
  ...DefaultOrderTypeConsts,
  ...DefaultCreatedOnRecordOrderTypeConsts,
  ...DefaultCreatedByRecordOrderTypeConsts,
  ...DefaultModifiedOnRecordOrderTypeConsts,
  ...DefaultModifiedByRecordOrderTypeConsts,
  "BRIDGE_ASC",
  "BRIDGE_DESC",
  "BRIDGE_TYPE_ASC",
  "BRIDGE_TYPE_DESC",
  "TYPE_ASC",
  "TYPE_DESC",
  "INTERVAL_ASC",
  "INTERVAL_DESC",
  "ENABLED_ASC",
  "ENABLED_DESC",
  "METHOD_ASC",
  "METHOD_DESC",
  "INSTRUMENT_ASC",
  "INSTRUMENT_DESC",
] as const;

export interface DataSourceRelations extends IRelationModel {
  datasets: IRelation;
}

export type DataSourceSortingOptions = (typeof DataSourceSortingOptionsConsts)[number];

export const DataSourceFieldLabels: PropertyTranslator<DataSource> & { nextScheduledDate: string } = {
  ...INamedEntityLabels,
  ...IPermissionedEntityLabels,
  ...ICreationRecordLabels,
  ...IModificationRecordLabels,
  ...IRelatedEntityLabels,
  bridge: "Bridge",
  cutoffDate: "Cut-off date",
  customImport: "Metadata mapping",
  directories: "Directories",
  enabled: "Enabled",
  format: "Data format",
  id: "Data source ID",
  instrument: "Instrument",
  intervalInSeconds: "Interval [min]",
  method: "Measuring method",
  parserDefinitions: "Parser definitions",
  status: "Status",
  type: "Source type",
  fileExcludePatterns: "Exclude files",
  nextScheduledDate: "Next scheduled run",
} as const;
// Client
export interface AutoloadClient {
  id: string; //GUID
  connectionId: string;
  host: string;
  clientVersion: number;
  ipAddress: string;
  firstSeen: string;
  executablePath?: string;
  operatingSystem?: string;
  isApproved: boolean;
  bridgeId: number;
}
