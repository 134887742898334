import React, { CSSProperties, useMemo } from "react";
import { StringIndexedDict } from "../../../api/GenericTypes";
import { Control, DeepMap, FieldError, Path } from "react-hook-form";
import { GenericControllerProvider } from "../../forms/common/GenericControllerProvider";
import DatePicker from "react-datepicker";
import styles from "../formfields.module.css";
import { FormFieldError, FormFieldLayout } from "../FormFieldLayouts";
import { LucideIcon } from "../../icon/LucideIcon";
import { isDate, isTime } from "../../datetime/DateTimeFormatter";
// import { Portal } from "../../helperfunctions/Portal";

type DateModes = "date" | "time" | "datetime";

const dateFormat = (mode: DateModes) => {
  switch (mode) {
    case "date":
      return "yyyy-MM-dd";
    case "datetime":
      return "yyyy-MM-dd HH:mm";
    case "time":
      return "HH:mm";
  }
};

export const convertPathValueToDate = (value: any): Date | undefined | null => {
  if (value instanceof Date) return value;
  if (typeof value === "string") {
    if (isDate(value)) return new Date(value);
    // Time Only
    if (isTime(value)) return new Date(`${new Date().toISOString().split("T").slice(0, 1)[0]}T${value}Z`);
    // Fallback
    return new Date(value);
  }

  return value;
};

export const convertToDate = (value: Date | any[] | null) => {
  if (value === null) return null;
  if (Array.isArray(value)) return value.map((v) => convertPathValueToDate(v));
  return convertPathValueToDate(value);
};

export const getStartDate = (value: Date | any[] | null): Date | null | undefined => {
  const vals = convertToDate(value);
  if (Array.isArray(vals)) {
    if (!vals.length) return null;
    return vals[0];
  }
  return convertToDate(value) as Date | undefined | null;
};
export const getEndDate = (value: Date | any[] | null) => {
  const vals = convertToDate(value);
  if (Array.isArray(vals)) {
    if (!vals.length || vals.length !== 2) return null;
    return vals[1];
  }
  return undefined;
};
// const CalendarContainer = ({ children }: { children: React.ReactNode }) => {
//   return <Portal className="calendar-portal">{children}</Portal>;
// };

export interface SingleDatePickerFormFieldProps<Entity extends StringIndexedDict> {
  id: Path<Entity>;
  label: string;
  control: Control<Entity>;
  type: DateModes;
  errors?: DeepMap<any, FieldError>;
  required?: boolean;
  placeholder?: string;
  autoComplete?: string;
  disabled?: boolean;
  readOnly?: boolean;
  horizontal?: boolean;
  autoFocus?: boolean;
  excludeDays?: Date[];
  showWeekNumbers?: boolean;
  uncontained?: boolean;
  defaultValue?: Date | Date[] | string | string[] | number | null;
  classNames?: string;
  onBlur?: () => void;
  selectsRange?: boolean;
  timeIntervals?: number;
  timeFormat?: string;
  isMulti?: boolean;
  style?: CSSProperties;
  portalId?: string;
  errorIdOverride?: Path<Entity>;
  children?: React.ReactNode;
}

export const SingleDatePickerFormField = <Entity extends StringIndexedDict>({
  id,
  label,
  control,
  type,
  errors,
  required,
  placeholder,
  autoComplete = "off",
  disabled,
  readOnly,
  horizontal,
  autoFocus,
  excludeDays,
  showWeekNumbers,
  uncontained,
  defaultValue,
  classNames,
  onBlur,
  selectsRange,
  timeIntervals,
  timeFormat,
  isMulti,
  style,
  portalId,
  errorIdOverride = id,
  children,
}: SingleDatePickerFormFieldProps<Entity>) => {
  const error = useMemo(() => {
    const fieldPath = errorIdOverride.split(".");
    let error: any = undefined;
    for (const path of fieldPath) {
      if (!error) error = errors?.[path];
      else error = error?.[path];
    }
    return error;
  }, [errors, errorIdOverride]);

  return (
    <GenericControllerProvider id={id} control={control} defaultValue={defaultValue}>
      {({ value, onChange, ref }) => (
        <>
          {uncontained ? (
            <>
              <div className={`flex`} style={{ width: "100%", height: "100%" }}>
                <div style={{ position: "relative", width: "100%", height: "100%", ...style }}>
                  <div className="react-datepicker__iconPlacement">
                    <LucideIcon name="calendar" color={value ? "var(--primary)" : "gray"} />
                  </div>
                  <DatePicker
                    //   ref={ref}
                    className={`form form-control ${error ? "border-danger" : ""} ${classNames ?? ""} ${
                      required ? "required" : ""
                    }`}
                    wrapperClassName={`${error ? "border-danger" : ""}`}
                    placeholderText={placeholder || `Select ${type}...`}
                    startDate={selectsRange ? getStartDate(value) : undefined}
                    endDate={selectsRange ? getEndDate(value) : undefined}
                    onChange={onChange}
                    selected={getStartDate(value)}
                    dateFormat={dateFormat(type)}
                    timeFormat={timeFormat || ["time", "datetime"].includes(type) ? "HH:mm" : undefined}
                    showTimeSelect={["time", "datetime"].includes(type)}
                    showTimeSelectOnly={type === "time"}
                    timeIntervals={timeIntervals}
                    timeCaption="Time"
                    autoComplete={autoComplete}
                    disabled={disabled}
                    readOnly={readOnly}
                    autoFocus={autoFocus}
                    isClearable={!readOnly && !disabled}
                    excludeDates={excludeDays}
                    showWeekNumbers={showWeekNumbers}
                    // popperContainer={CalendarContainer}
                    portalId={portalId ?? "root-portal"}
                    onCalendarClose={onBlur} // Workaround: onBlur is not triggered when used with portals. See https://github.com/Hacker0x01/react-datepicker/issues/2028
                    selectsRange={selectsRange}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault();
                        e.stopPropagation();
                      }
                    }}
                    selectedDates={value}
                    selectsMultiple={isMulti}
                    disabledKeyboardNavigation={isMulti}
                  />
                  {children ? <div className={styles.buttons}>{children}</div> : null}
                </div>
                <FormFieldError id={id} errors={errors} />{" "}
              </div>
            </>
          ) : (
            <FormFieldLayout
              id={id}
              label={label}
              required={required}
              horizontal={horizontal}
              hasLabel={!!label && label !== ""}
            >
              <div className={styles.container}>
                <div style={{ position: "relative", width: "100%", height: "100%", ...style }}>
                  <div className="react-datepicker__iconPlacement">
                    <LucideIcon name="calendar" color={value ? "var(--primary)" : "gray"} />
                  </div>
                  <DatePicker
                    //   ref={ref}
                    className={`form form-control ${error ? "border-danger" : ""} ${classNames ?? ""} ${
                      required ? "required" : ""
                    }`}
                    wrapperClassName={`${error ? "border-danger" : ""}`}
                    placeholderText={placeholder || `Select ${type}...`}
                    startDate={selectsRange ? getStartDate(value) : undefined}
                    endDate={selectsRange ? getEndDate(value) : undefined}
                    onChange={onChange}
                    selected={getStartDate(value)}
                    dateFormat={dateFormat(type)}
                    timeFormat={timeFormat || ["time", "datetime"].includes(type) ? "HH:mm" : undefined}
                    showTimeSelect={["time", "datetime"].includes(type)}
                    showTimeSelectOnly={type === "time"}
                    timeIntervals={timeIntervals}
                    timeCaption="Time"
                    autoComplete={autoComplete}
                    disabled={disabled}
                    readOnly={readOnly}
                    autoFocus={autoFocus}
                    isClearable={!readOnly && !disabled}
                    excludeDates={excludeDays}
                    showWeekNumbers={showWeekNumbers}
                    // popperContainer={CalendarContainer}
                    portalId={portalId ?? "root-portal"}
                    onCalendarClose={onBlur} // Workaround: onBlur is not triggered when used with portals. See https://github.com/Hacker0x01/react-datepicker/issues/2028
                    selectsRange={selectsRange}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault();
                        e.stopPropagation();
                      }
                    }}
                    selectedDates={isMulti ? value : undefined}
                    selectsMultiple={isMulti}
                    disabledKeyboardNavigation={isMulti}
                  />
                </div>
                {children ? <div className={styles.buttons}>{children}</div> : null}
              </div>
              <FormFieldError id={id} errors={errors} />
            </FormFieldLayout>
          )}
        </>
      )}
    </GenericControllerProvider>
  );
};
