import {
  PropertyTranslator,
  IGenericRequestParametersTranslator,
  IPaginationParametersTranslator,
  INamedEntityLabels,
} from "./GenericTranslator";
import {
  DefaultOrderTypeConsts,
  EntityConstants,
  IEntityMinimalModel,
  IGenericRequestParameters,
  IModelWithStringId,
  INamedEntity,
  IPaginationParameters,
} from "./GenericTypes";

export const vendorsConstants: EntityConstants<Vendor, VendorFilters> = {
  resource: "vendors",
  frontendIndexRoute: "formats",
  entitySingular: "vendor",
  entityPlural: "vendors",
  icon: "truck",
};

export interface Vendor extends IModelWithStringId, INamedEntity {
  description: string;
  icon: string;
}

export const VendorFieldLabels: PropertyTranslator<Vendor> = {
  ...INamedEntityLabels,
  id: "Vendor ID",
  description: "Description",
  icon: "Icon",
} as const;

export interface VendorSuggestions extends IEntityMinimalModel<Vendor["id"]> {}

export interface VendorFilters extends IGenericRequestParameters<Vendor>, IPaginationParameters {
  includeIcon?: boolean;
}
export const VendorFiltersTranslator: PropertyTranslator<VendorFilters> = {
  ...IGenericRequestParametersTranslator,
  ...IPaginationParametersTranslator,
  includeIcon: "Include icon",
} as const;

export const VendorSortingOptionsConsts = [...DefaultOrderTypeConsts] as const;
