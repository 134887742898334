import { useCallback, useEffect, useRef } from "react";

// Simple hook to detect inside / outside clicks relative to a ref; Optional callbacks; Returns width & height of ref via resizeDetector

interface UseIsClickedOutsideProps {
  onClickInside?: () => void;
  onClickOutside?: () => void;
}
export const useIsRefClicked = <T extends Node>({ onClickInside, onClickOutside }: UseIsClickedOutsideProps) => {
  // const { ref, width, height } = useResizeDetector<T>();
  const ref = useRef<T | null>(null);

  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        onClickOutside?.();
      }
    },
    [ref, onClickOutside]
  );
  const handleClickInside = useCallback(
    (event: MouseEvent) => {
      if (ref.current && ref.current.contains(event.target as Node)) {
        onClickInside?.();
      }
    },
    [ref, onClickInside]
  );

  useEffect(() => {
    if (onClickOutside) document.addEventListener("click", handleClickOutside);
    if (onClickInside) document.addEventListener("click", handleClickInside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
      document.removeEventListener("click", handleClickInside);
    };
  }, [handleClickInside, handleClickOutside, onClickInside, onClickOutside]);

  return { ref };
};
