import React from "react";

const supRegex = /<sup>(.*?)<\/sup>/gi;

export const convertToTagList = (input: any): any[] => {
  let list: any[] = Array.isArray(input) ? input : [input];

  const result: any[] = [];

  // let t: string;
  for (let t of list as string[]) {
    if (typeof t !== "string") continue;
    let index = 0;
    // console.log("m", t.matchAll(supRegex));
    Array.from(t.matchAll(supRegex), (m) => {
      // console.log("m", m);
      result.push(t.substr(index, (m?.index ?? 0) - index));
      result.push(<sup key={index}>{m[1]}</sup>);
      index = (m?.index ?? 0) + m[0].length;
      return result;
    });
    result.push(t.substr(index));
  }

  return result;
};
