import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import styles from "../../../common/forms/forms.module.css";
import { FormHeader } from "../../../common/forms/FormHeader";
import { FormButtons } from "../../../common/forms/FormButtons";
import { LabNotebookExperimentsVirtualizedSelectForm } from "../../../common/forms/EntityForms/formsVirtualized/LabNotebookExperimentsVirtualizedSelectForm";
import {
  EmptyLabNotebookEntry,
  LabNotebookEntry,
  LabNotebookEntryFilters,
  labNotebookEntriesConstants,
} from "../../types/LabNotebookEntry";
import { useCallback, useEffect, useMemo } from "react";
import { useInfiniteListEntity } from "../../../api/BaseEntityApi";
import { date2localDate } from "../../../common/datetime/DateTimeFormatter";
import { EntityFormProps } from "../../../common/entity/EntityInterfaces";
import { useELNRoutes } from "../../ELNRouter/useELNRoutes";
import { LinkButton } from "../../../common/buttons/LinkButton/LinkButton";
import { SingleDatePickerFormField } from "../../../common/formfields/DatePickerFormField/SingleDatePickerFormField";
import { getNotebookEntryStringLabel } from "../../LabNotebookEntry/LabNotebookEntry";

export interface LabNotebookEntryFormValues extends Omit<LabNotebookEntry, "entryDate"> {
  entryDate?: Date | null;
}

export const LabNotebookEntryForm = ({
  fieldLabels,
  loading,
  permissions,
  title,
  subtitle,
  initialValues,
  onSubmit,
  onCancel,
  submitButtonLabel,
  event,
}: EntityFormProps<"notebookEntries">) => {
  const { getELNRoute } = useELNRoutes();

  const LabNotebookEntryFormSchema = yup.object().shape({
    labNotebookExperiment: yup.object().required("An experiment is required").typeError("An experiment is required"),
    entryDate: yup.date().required("Entry date is required").typeError("Entry date is required"),
  });

  const {
    handleSubmit,
    control,
    watch,
    setError,
    clearErrors,
    formState: { errors, isSubmitting },
  } = useForm<Partial<LabNotebookEntryFormValues>>({
    values: { ...initialValues, entryDate: initialValues?.entryDate ? new Date(initialValues.entryDate) : new Date() },
    resolver: yupResolver(LabNotebookEntryFormSchema),
  });

  const formValuesToEntity = useCallback(
    (entity: Partial<LabNotebookEntryFormValues>) => {
      if (!!entity.entryDate && !!entity.labNotebookExperiment) {
        const entryDate = date2localDate(entity.entryDate);
        entryDate.setUTCHours(0, 0, 0, 0);
        onSubmit({
          ...entity,
          entryDate: entryDate.toISOString(),
          content:
            event === "ADD"
              ? EmptyLabNotebookEntry
              : event === "CLONE"
              ? initialValues?.content ?? EmptyLabNotebookEntry
              : undefined,
          concurrencyToken: entity.modifiedOn || null,
        });
      }
    },
    [event, initialValues?.content, onSubmit]
  );

  const currentExperiment = watch("labNotebookExperiment");
  const currentEntryDate = watch("entryDate");

  const { data: existingEntriesData } = useInfiniteListEntity<LabNotebookEntry, LabNotebookEntryFilters>(
    "lab_notebook_entries",
    {
      page: 1,
      pageSize: 1,
      orderBy: "ID_ASC",
      labNotebookIds: initialValues?.labNotebook?.id ? [initialValues.labNotebook.id] : undefined,
      labNotebookExperimentIds: currentExperiment?.id ? [currentExperiment.id] : null,
      entryDateFrom: currentEntryDate ? currentEntryDate.toISOString() : null,
      entryDateTo: currentEntryDate ? currentEntryDate.toISOString() : null,
      includeContent: false,
      includeCount: true,
      includeSoftDeleted: true,
    },
    { enabled: !!currentEntryDate && !!currentExperiment?.id },
    "post"
  );

  const existingEntries = useMemo(() => {
    return existingEntriesData?.pages.map((d) => d.results).flat() || [];
  }, [existingEntriesData?.pages]);

  useEffect(() => {
    if (existingEntries && existingEntries.length > 0 && existingEntries[0].id !== initialValues?.id) {
      setError("entryDate", {
        message: `${getNotebookEntryStringLabel({ entry: existingEntries[0] })} - Entry already exists!`,
        type: "validate",
      });
    } else {
      clearErrors("entryDate");
    }
  }, [clearErrors, existingEntries, initialValues?.id, setError]);

  const hasErrors = !!Object.keys(errors).length;
  return (
    <>
      <FormHeader title={title} subtitle={subtitle} />
      <form
        onSubmit={(e) => {
          e.preventDefault();
        }}
        autoComplete="off"
        className={`form-horizontal ${styles.form_holder}`}
      >
        <fieldset>
          <legend className="col-md-offset-2 col-md-10">Basic details</legend>
        </fieldset>

        <LabNotebookExperimentsVirtualizedSelectForm
          id="labNotebookExperiment"
          label={fieldLabels.labNotebookExperiment}
          control={control}
          showControls
          horizontal
          allowCreateEntity
          required
        />

        <div id="calendarPortalRef">
          <SingleDatePickerFormField
            id="entryDate"
            label={fieldLabels.entryDate}
            control={control}
            required
            type="date"
            errors={errors}
            horizontal
            portalId="calendarPortalRef"
          />
        </div>

        <FormButtons
          groupName={labNotebookEntriesConstants.frontendIndexRoute}
          entityId={initialValues ? initialValues.id : undefined}
          onClose={onCancel}
          onSubmit={handleSubmit(formValuesToEntity)}
          submitButtonLabel={submitButtonLabel || "Save changes"}
          disabled={isSubmitting || !currentEntryDate || hasErrors}
          errors={errors}
          loading={loading}
          disableDefaultOnClose
          children={
            existingEntries && existingEntries.length > 0 && existingEntries[0].id !== initialValues?.id ? (
              <LinkButton
                linkTo={getELNRoute({
                  labNotebookId: existingEntries[0].labNotebook.id,
                  labNotebookExperimentId: existingEntries[0].labNotebookExperiment.id,
                  labNotebookEntryId: existingEntries[0].id,
                })}
                className="btn btn-ghost-primary"
                onClick={() => onCancel()}
              >
                Open entry
              </LinkButton>
            ) : undefined
          }
        />
      </form>
    </>
  );
};
