import { produce } from 'immer';
import { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { DropdownButton, FormControl, MenuItem } from 'react-bootstrap';
import { Range } from 'react-range';

import { ColorBox } from '../../ViewerUIElements/ColorBox';
import { useInputDebounce } from '../../ViewerUIElements/InputDebounce';
import { contourMode, oneBaselineContourState } from '../ContourClasses';
import { getDigit, RangeType, ToolInput } from './ContourEditor';
import { OneBaselineConstantOffset } from './OneBaselineConstantOffset';
import styles from './OneBaselineContourEditor.module.css';
import { OneBaselineEvenSpaced } from './OneBaselineEvenSpaced';
import { OneBaselinePreviousScaled } from './OneBaselinePreviousScaled';
import { SliderThumbBlock, SliderTrack } from './SliderElements';

type directionType = -1 | 0 | 1;
export type ContourType = {
  baseline: number;
  direction: directionType;
  scale: number;
  count: number;
  mode: contourMode;
  range: RangeType;
};

const ContourNeedsUpdate = (target: ContourType, source: ContourType) => {
  return (
    Math.abs(target.range.min - source.range.min) > Number.EPSILON ||
    Math.abs(target.range.max - source.range.max) > Number.EPSILON ||
    target.count !== source.count ||
    target.direction !== source.direction ||
    target.mode !== source.mode ||
    Math.abs(target.baseline - source.baseline) > Number.EPSILON ||
    Math.abs(target.scale - source.scale) > Number.EPSILON
  );
};

const getOffsetsFromContours = (contours: ContourType): number[] => {
  if (!contours) return [];

  const offset = (v: number) => (v - contours.range.min) / contours.range.diff;

  // if (contours.direction === 0) {
  //   let d = -contours.baseline;
  //   if (d < contours.range.min) d = contours.range.min;
  //   if (d > contours.range.max) d = contours.range.max;
  //   if (d > contours.baseline) return [offset(contours.baseline), offset(d)];
  //   return [offset(d), offset(contours.baseline)];
  // }

  return [offset(contours.baseline)];
};

const getContoursFromOffsets = (offsets: number[], range: RangeType): number => {
  const contour = (v: number) => v * range.diff + range.min;
  return contour(offsets[0]);
};

const Settings = (props: {
  contours: ContourType;
  setContours: (contours: ContourType) => void;
  setMinHeight?: (height: number) => void;
  setMinWidth?: (width: number) => void;
}) => {
  let settings = null;
  switch (props.contours.mode) {
    case contourMode.evenSpaced:
      settings = <OneBaselineEvenSpaced {...props} />;
      break;
    case contourMode.constantOffset:
      settings = <OneBaselineConstantOffset {...props} />;
      break;
    case contourMode.previousScaled:
      settings = <OneBaselinePreviousScaled {...props} />;
      break;
  }

  return (
    <div>
      <div className={styles.sliderText} style={{ paddingBottom: 10 }}>
        Mode:{" "}
        <DropdownButton
          bsStyle={"default"}
          title={props.contours.mode || "Unknown"}
          id={"contour-type-select"}
          bsSize="xs"
          onSelect={(key: any) => {
            props.setContours(
              produce(props.contours, (next) => {
                next.mode = key;
              })
            );
          }}
        >
          {Object.entries(contourMode).map(([k, v]) => (
            <MenuItem key={k} eventKey={v} active={v === props.contours.mode}>
              {v}
            </MenuItem>
          ))}
        </DropdownButton>
      </div>

      {settings}
    </div>
  );
};

export const OneBaselineContourEditor = ({
  width,
  color,
  contours,
  valueMin,
  valueMax,
  setContours,
  setMinHeight,
  setMinWidth,
}: ToolInput) => {
  const [minMaxStr, setMinMaxStr] = useState<string[]>(["", ""]);
  const [values, setValues] = useState<number[]>([1]);
  const [count, setCount] = useState<number[]>([]);
  // const [range, setRange] = useState<RangeType>({
  //   min: -Infinity,
  //   max: Infinity,
  //   diff: Infinity,
  // });
  const [countInput, setCountInput] = useState<string>("");
  const [countValid, setCountValid] = useState<boolean>(true);
  const [contourLines, setContourLines] = useState<ContourType>({
    baseline: 0,
    direction: 0,
    scale: 0,
    count: 0,
    mode: contourMode.evenSpaced,
    range: {
      min: -Infinity,
      max: Infinity,
      diff: Infinity,
    },
  });
  const [windowHeight, setWindowHeight] = useState<number>(205);
  const debouncedContour = useInputDebounce<ContourType>(contourLines, 300);
  const maxCount = 30;

  useLayoutEffect(() => {
    if (setMinHeight) setMinHeight(windowHeight);
  }, [setMinHeight, windowHeight]);

  useEffect(() => {
    //   if (setMinHeight) setMinHeight(205);
    if (setMinWidth) setMinWidth(400);
  }, [setMinWidth]);

  useEffect(() => {
    // console.log("debounce contour", ArrayNeedsUpdate(contours, debouncedContour.contours));

    if (debouncedContour.count > 0) {
      const state: oneBaselineContourState = {
        count: debouncedContour.count,
        baseline: debouncedContour.baseline,
        direction: debouncedContour.direction,
        scale: debouncedContour.scale,
        mode: debouncedContour.mode,
        type: contours.state.type,
      };
      setContours({ generate: contours.generate, state: state });
    }
  }, [debouncedContour, contours.generate, contours.state.type, setContours]);

  useEffect(() => {
    const state = contours.state as oneBaselineContourState;

    const newState: ContourType = {
      count: state.count,
      baseline: state.baseline,
      direction: state.direction,
      scale: state.scale,
      mode: state.mode,
      range: {
        min: valueMin,
        max: valueMax,
        diff: valueMax - valueMin,
      },
    };

    if (ContourNeedsUpdate(contourLines, newState)) {
      setContourLines(newState);
      setValues(getOffsetsFromContours(newState));
      setCount([newState.count]);
      const digit = getDigit(newState.range.diff);
      const rangeStr = [newState.range.min.toFixed(digit), newState.range.max.toFixed(digit)];
      if (rangeStr.some((r, i) => r !== minMaxStr[i])) setMinMaxStr(rangeStr);
    }
  }, [contours, valueMin, valueMax]);

  useEffect(() => {
    const r = getContoursFromOffsets(values, contourLines.range);
    const newState: ContourType = produce(contourLines, (next) => {
      next.baseline = r;
    });
    if (ContourNeedsUpdate(contourLines, newState)) {
      setContourLines(newState);
    }
  }, [values]);

  useEffect(() => {
    if (count[0] === undefined) return;

    setCountInput("" + count[0]);
    setCountValid(true);

    const newState: ContourType = produce(contourLines, (next) => {
      next.count = count[0];
    });
    if (ContourNeedsUpdate(contourLines, newState)) {
      // console.log("update", newState);
      setContourLines(newState);
    }
  }, [count]);

  useEffect(() => {
    let i = parseInt(countInput);
    if (i > 0 && i <= maxCount) {
      if (i !== count[0]) {
        setCount([i]);
      }
      setCountValid(true);
      setCountInput("" + i);
    } else setCountValid(false);
  }, [countInput]);

  const sliderThumb = useCallback(({ index, props }: { index: number; props: any }) => {
    // console.log("props", index, props["aria-valuenow"], props.style.zIndex);
    // console.log("props", index, props);

    props.style.zIndex = undefined;
    return (
      <div {...props}>
        {/* <div>
          <ColorBox color={gradientColors.colors[index]} width={10} height={10} allowColorChange={true}></ColorBox>
        </div> */}
        {/* <div className={index > 0 ? styles.sliderRight : styles.sliderLeft}>12</div> */}
        <div className={styles.sliderThumbLine} />
        {/* <span className={["glyphicon", "glyphicon-step-forward", styles.sliderThumbBorder].join(" ")} />
        <span className={["glyphicon", "glyphicon-step-forward", styles.sliderThumbBorder].join(" ")} /> */}
      </div>
    );
  }, []);

  const sliderNumberThumb = useCallback(
    ({ index, props }: { index: number; props: any }) => {
      const digit = getDigit(contourLines.range.diff) + 1;
      // const v = getContoursFromOffsets(values, contourLines.range);

      return (
        <div {...props} className={styles.sliderThumb}>
          <div className={styles.sliderText}>{contourLines.baseline.toFixed(digit)}</div>
        </div>
      );
    },
    [contourLines]
  );

  return (
    <div style={{ width: width, paddingTop: 10 }}>
      <Range
        step={0.001}
        min={0}
        // disabled={disabled}
        max={1}
        values={values}
        // values={values.length < 1 ? [0, 1] : values}
        renderTrack={({ props, children }: { props: any; children: any }) => {
          // return <div>{children}</div>;
          return (
            <div {...props}>
              {children}
              <div style={{ width: width }} />
            </div>
          );
        }}
        // onChange={setValues(v)}
        onChange={(v: number[]) => {}}
        renderThumb={sliderNumberThumb}
      />

      <Range
        step={0.001}
        min={0}
        // disabled={disabled}
        max={1}
        values={values}
        // values={values.length < 1 ? [0, 1] : values}
        renderTrack={({ props, children }: { props: any; children: any }) => {
          // return <div>{children}</div>;
          const offset = (v: number) => (v - contourLines.range.min) / contourLines.range.diff;
          const l = offset(-contourLines.baseline) * (width || 0);
          return (
            <div {...props}>
              {children}
              <div style={{ height: 2 }} />
              <ColorBox color={color} width={width} height={10} />
              {contourLines.direction === 0 ? (
                <div className={styles.sliderThumbLine} style={{ position: "absolute", left: isNaN(l) ? 0 : l }} />
              ) : null}
            </div>
          );
        }}
        // onChange={setValues(v)}
        onChange={(v: number[]) => setValues(v)}
        renderThumb={sliderThumb}
      />
      <div className={styles.range}>
        <span>{minMaxStr[0]}</span>
        <span>{minMaxStr[1]}</span>
      </div>
      <div style={{ height: 10 }} />
      {/* <DropdownButton
        bsStyle={"default"}
        title={contourLines.mode || "Unknown"}
        id={"contour-type-select"}
        bsSize="xs"
        onSelect={(key: any) => {
          console.log("change mode to", key);
        }}
      >
        {Object.entries(contourMode).map(([k, v]) => (
          <MenuItem key={k} eventKey={v} active={v === contourLines.mode}>
            {v}
          </MenuItem>
        ))}
      </DropdownButton> */}
      <div className={styles.rangeSetter}>
        <div className={styles.settingText}>Count:</div>
        <div style={{ flexGrow: 1, minWidth: 30 }}>
          <Range
            step={1}
            min={1}
            // disabled={disabled}
            max={maxCount}
            values={count.length !== 1 ? [1] : count}
            renderTrack={SliderTrack}
            onChange={setCount}
            renderThumb={SliderThumbBlock}
          />
        </div>
        <div style={{ paddingLeft: 5 }}>
          <FormControl
            type="text"
            value={countInput}
            onChange={(e) => setCountInput((e.target as HTMLInputElement).value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") setCountInput((e.target as HTMLInputElement).value);
            }}
            onFocus={(e) => (e.target as any as HTMLInputElement).select()}
            bsSize={"sm"}
            style={{ width: "4em", scale: "90%", background: countValid ? undefined : "salmon" }}
          />
        </div>
      </div>

      <Settings
        contours={contourLines}
        setContours={(contours: ContourType) => {
          if (ContourNeedsUpdate(contourLines, contours)) setContourLines(contours);
        }}
        setMinHeight={setWindowHeight}
      />
    </div>
  );
};
