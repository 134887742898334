import { formatBytes } from "../../../../Dataset/common/Tree/Folder/helpers/FileHelpers";
import { DataSource } from "../../../../api/DataSource";
import { DataSourceStatusInfoClient, DataSourceStatusInfoSSH } from "../../../../api/DataSourceStatus";
import { NotAvailable } from "../../../../common/misc/UIconstants";
import { Table } from "../../../../common/panels/Detail/DetailTable";

export const DataSourceOverview = ({ dataSource }: { dataSource: DataSource }) => {
  switch (dataSource.bridge.type) {
    case "Client":
      return <DataSourceOverviewClient dataSource={dataSource ?? NotAvailable} />;
    case "SFTP":
      return <DataSourceOverviewSFTP dataSource={dataSource ?? NotAvailable} />;
    default:
      return null;
  }
};

const DataSourceOverviewClient = ({ dataSource }: { dataSource: DataSource }) => {
  const statistics = dataSource.status?.lastClientStatus?.info as DataSourceStatusInfoClient | undefined;
  return (
    <>
      <Table.Body.RowContent title="Datasets submitted" content={statistics?.datasetsSubmitted ?? NotAvailable} />
      <Table.Body.RowContent
        title="Dataset failed during submission"
        content={statistics?.datasetsFailedSubmission ?? NotAvailable}
      />
      <Table.Body.RowContent title="Datasets in queue" content={statistics?.datasetInQueue ?? NotAvailable} />
      <Table.Body.RowContent
        title="Datasets known from cache"
        content={statistics?.datasetsKnownFromCache ?? NotAvailable}
      />
      <Table.Body.RowContent
        title="Datasets not known from cache"
        content={statistics?.datasetsNotKnownFromCache ?? NotAvailable}
      />
      <Table.Body.RowContent
        title="Datasets known from server"
        content={statistics?.datasetsKnownFromServer ?? NotAvailable}
      />
      <Table.Body.RowContent
        title="Datasets older than cutoff date"
        content={statistics?.datasetsOlderThanCutoffDate ?? NotAvailable}
      />
      <Table.Body.RowContent title="Incomplete datasets" content={statistics?.incompleteDatasets ?? NotAvailable} />
      <Table.Body.RowContent
        title="Total size of datasets submitted"
        content={
          statistics?.totalSizeSubmittedDatasetsInBytes !== undefined
            ? formatBytes(statistics?.totalSizeSubmittedDatasetsInBytes)
            : NotAvailable
        }
      />
      <Table.Body.RowContent
        title="Total size of datasets in queue"
        content={
          statistics?.totalSizeDatasetsInQueueInBytes !== undefined
            ? formatBytes(statistics?.totalSizeDatasetsInQueueInBytes)
            : NotAvailable
        }
      />
      <Table.Body.RowContent title="Directories entered" content={statistics?.directoriesEntered ?? NotAvailable} />
      <Table.Body.RowContent
        title="Files and directories scanned"
        content={statistics?.entriesScanned ?? NotAvailable}
      />
      <Table.Body.RowContent
        title="Files and directories matched"
        content={statistics?.entriesMatched ?? NotAvailable}
      />
      <Table.Body.RowContent
        title="Broken symbolic links ignored"
        content={statistics?.brokenSymlinksIgnored ?? NotAvailable}
      />
    </>
  );
};

const DataSourceOverviewSFTP = ({ dataSource }: { dataSource: DataSource }) => {
  const statistics = dataSource.status?.lastClientStatus?.info as DataSourceStatusInfoSSH | undefined;
  return (
    <>
      <Table.Body.RowContent title="Datasets found" content={statistics?.datasetsFound ?? NotAvailable} />
      <Table.Body.RowContent title="Datasets uploaded" content={statistics?.datasetsUploaded ?? NotAvailable} />
      <Table.Body.RowContent title="Datasets updated" content={statistics?.datasetsUpdated ?? NotAvailable} />
      <Table.Body.RowContent title="Datasets known" content={statistics?.datasetsKnown ?? NotAvailable} />
      <Table.Body.RowContent
        title="Total size of datasets uploaded"
        content={statistics?.filesUploadSize !== undefined ? formatBytes(statistics?.filesUploadSize) : NotAvailable}
      />
      <Table.Body.RowContent title="Directories entered" content={statistics?.directoriesEntered ?? NotAvailable} />
      <Table.Body.RowContent
        title="Directories failed to read"
        content={statistics?.directoriesFailedToRead ?? NotAvailable}
      />
      <Table.Body.RowContent title="Files found" content={statistics?.filesFound ?? NotAvailable} />
      <Table.Body.RowContent title="Files uploaded" content={statistics?.filesUploaded ?? NotAvailable} />
      <Table.Body.RowContent title="Files failed to read" content={statistics?.filesFailedToRead ?? NotAvailable} />
    </>
  );
};
