import { StringIndexedDict } from "../../../../api/GenericTypes";
import {
  GenericEntityVirtualizedEntitySelectFormFieldProps,
  GenericVirtualizedEntitySelectFormFieldControllerWrapper,
} from "../../../formfields/GenericVirtualizedSelectFormField/GenericVirtualizedEntitySelectFormFieldControllerWrapper";
import { QuickAddWrapper } from "../../QuickAdd/common/QuickAddWrapper";
import {
  OrganizationFilters,
  OrganizationSortingOptionsConsts,
  OrganizationSuggestions,
  organizationsConstants,
} from "../../../../api/Organizations";
import { QuickAddOrganizationForm } from "../../QuickAdd/forms/QuickAddOrganizationForm";
import { useContext } from "react";
import { SessionContext } from "../../../contexts/SessionContext";

export const OrganizationsVirtualizedSelectForm = <Entity extends StringIndexedDict>(
  props: GenericEntityVirtualizedEntitySelectFormFieldProps<Entity, OrganizationSuggestions, OrganizationFilters>
) => {
  const { session } = useContext(SessionContext);

  return (
    <GenericVirtualizedEntitySelectFormFieldControllerWrapper<Entity, OrganizationSuggestions, OrganizationFilters>
      {...props}
      filters={{ orderBy: "NAME_ASC", ...props.filters }}
      //   onRowRenderer={(item) => <></>}
      createModal={({ showModal, setShowModal, onCreate }) => (
        <QuickAddWrapper showModal={showModal} setShowModal={setShowModal} onCreate={onCreate}>
          {(quickAddProps) => <QuickAddOrganizationForm {...quickAddProps} />}
        </QuickAddWrapper>
      )}
      entityConstants={organizationsConstants}
      orderByOptions={OrganizationSortingOptionsConsts}
      allowCreateEntity={session?.permissions.administer_organizations && props.allowCreateEntity}
    />
  );
};
