import { UseMutateAsyncFunction, UseMutateFunction } from "@tanstack/react-query";
import { ResourceName } from "../main/Routing";
import {
  BulkCreateOptions,
  BulkDeleteOptions,
  BulkEditOptions,
  BulkRestoreOptions,
  CreateMutationOptions,
  DeleteMutationOptions,
  EditMutationOptions,
  RestoreMutationOptions,
  useBulkCreate,
  useBulkDelete,
  useBulkEdit,
  useBulkRestore,
  useCreateMutation,
  useDeleteMutation,
  useEditMutation,
  useRestoreMutation,
} from "./BaseEntityApi";
import { GenericEntity, IPagination } from "./GenericTypes";

export interface EntityApi<Entity extends GenericEntity> {
  createMutation: UseMutateFunction<Entity, unknown, CreateMutationOptions<Entity>, unknown>;
  createMutationAsync: UseMutateAsyncFunction<Entity, unknown, CreateMutationOptions<Entity>, unknown>;
  isLoadingCreateMutation: boolean;
  bulkCreateMutation: UseMutateFunction<IPagination<Entity>, unknown, BulkCreateOptions<Entity>, unknown>;
  bulkCreateMutationAsync: UseMutateAsyncFunction<IPagination<Entity>, unknown, BulkCreateOptions<Entity>, unknown>;
  isLoadingBulkCreateMutation: boolean;
  editMutation: UseMutateFunction<Entity, unknown, EditMutationOptions<Entity>, unknown>;
  editMutationAsync: UseMutateAsyncFunction<Entity, unknown, EditMutationOptions<Entity>, unknown>;
  isLoadingEditMutation: boolean;
  bulkEditMutation: UseMutateFunction<IPagination<Entity>, unknown, BulkEditOptions<Entity>, unknown>;
  bulkEditMutationAsync: UseMutateAsyncFunction<IPagination<Entity>, unknown, BulkEditOptions<Entity>, unknown>;
  isLoadingBulkEditMutation: boolean;
  deleteMutation: UseMutateFunction<any, unknown, DeleteMutationOptions, unknown>;
  deleteMutationAsync: UseMutateAsyncFunction<any, unknown, DeleteMutationOptions, unknown>;
  isLoadingDeleteMutation: boolean;
  bulkDeleteMutation: UseMutateFunction<any, unknown, BulkDeleteOptions<Entity>, unknown>;
  bulkDeleteMutationAsync: UseMutateAsyncFunction<any, unknown, BulkDeleteOptions<Entity>, unknown>;
  isLoadingBulkDeleteMutation: boolean;
  restoreMutation: UseMutateFunction<Entity, unknown, RestoreMutationOptions<Entity>, unknown>;
  restoreMutationAsync: UseMutateAsyncFunction<Entity, unknown, RestoreMutationOptions<Entity>, unknown>;
  isLoadingRestoreMutation: boolean;
  bulkRestoreMutation: UseMutateFunction<IPagination<Entity>, unknown, BulkRestoreOptions<Entity>, unknown>;
  bulkRestoreMutationAsync: UseMutateAsyncFunction<IPagination<Entity>, unknown, BulkRestoreOptions<Entity>, unknown>;
  isLoadingBulkRestoreMutation: boolean;
}

export const useEntityApi = <Entity extends GenericEntity>(resource: ResourceName): EntityApi<Entity> => {
  const {
    mutate: createMutation,
    mutateAsync: createMutationAsync,
    isLoading: isLoadingCreateMutation,
  } = useCreateMutation<Entity>(resource);
  const {
    mutate: bulkCreateMutation,
    mutateAsync: bulkCreateMutationAsync,
    isLoading: isLoadingBulkCreateMutation,
  } = useBulkCreate<Entity>(resource);
  const {
    mutate: editMutation,
    mutateAsync: editMutationAsync,
    isLoading: isLoadingEditMutation,
  } = useEditMutation<Entity>(resource);
  const {
    mutate: bulkEditMutation,
    mutateAsync: bulkEditMutationAsync,
    isLoading: isLoadingBulkEditMutation,
  } = useBulkEdit<Entity>(resource);
  const {
    mutate: deleteMutation,
    mutateAsync: deleteMutationAsync,
    isLoading: isLoadingDeleteMutation,
  } = useDeleteMutation(resource);
  const {
    mutate: bulkDeleteMutation,
    mutateAsync: bulkDeleteMutationAsync,
    isLoading: isLoadingBulkDeleteMutation,
  } = useBulkDelete<Entity>(resource);
  const {
    mutate: restoreMutation,
    mutateAsync: restoreMutationAsync,
    isLoading: isLoadingRestoreMutation,
  } = useRestoreMutation<Entity>(resource);
  const {
    mutate: bulkRestoreMutation,
    mutateAsync: bulkRestoreMutationAsync,
    isLoading: isLoadingBulkRestoreMutation,
  } = useBulkRestore<Entity>(resource);

  return {
    createMutation,
    createMutationAsync,
    isLoadingCreateMutation,
    bulkCreateMutation,
    bulkCreateMutationAsync,
    isLoadingBulkCreateMutation,
    editMutation,
    editMutationAsync,
    isLoadingEditMutation,
    bulkEditMutation,
    bulkEditMutationAsync,
    isLoadingBulkEditMutation,
    deleteMutation,
    deleteMutationAsync,
    isLoadingDeleteMutation,
    bulkDeleteMutation,
    bulkDeleteMutationAsync,
    isLoadingBulkDeleteMutation,
    restoreMutation,
    restoreMutationAsync,
    isLoadingRestoreMutation,
    bulkRestoreMutation,
    bulkRestoreMutationAsync,
    isLoadingBulkRestoreMutation,
  };
};
