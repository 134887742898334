import { Control, DeepMap, FieldError, Path, UseFormRegister } from "react-hook-form";
import { CustomType } from "../../../api/CustomTypes";
import { ICustomTypedEntity, IGenericEntity, IntIndexedDict } from "../../../api/GenericTypes";
import React from "react";
import { CustomFieldEditRenderer } from "../../CustomFields/CustomFieldEditRenderer";
import { customFieldToCustomFieldForm } from "../../CustomFields/CustomFieldUtils";

interface CustomFieldsFormRendererProps<Entity extends IGenericEntity & ICustomTypedEntity> {
  customType?: CustomType;
  register: UseFormRegister<any>;
  control: Control<Entity, any, Entity>;
  errors: DeepMap<any, FieldError>;
  disabled?: boolean;
  placeholders?: IntIndexedDict;
}

/**
 * Renders a form for a custom type entity.
 * @author @CorradoSurmanowicz
 * @template Entity - The type of the custom type entity.
 * @param {CustomFieldsFormRendererProps<Entity>} props - The props for the form renderer.
 * @returns {JSX.Element} - The rendered form.
 */
export const CustomTypeFormRenderer = <Entity extends IGenericEntity & ICustomTypedEntity>({
  customType,
  control,
  register,
  errors,
  disabled,
  placeholders,
}: CustomFieldsFormRendererProps<Entity>) => {
  return (
    <>
      {customType?.sections.map((field, idx0) => {
        return (
          <React.Fragment key={idx0}>
            <fieldset>
              {field.name ? <legend className="col-md-offset-2 col-md-10">{field.name}</legend> : null}
            </fieldset>
            {Array.isArray(field.customFields) &&
              field.customFields.map((customField, idx1) => {
                const _customField = customFieldToCustomFieldForm(customField);
                if (!_customField) return <></>;
                return (
                  <React.Fragment key={idx1}>
                    <CustomFieldEditRenderer<Entity>
                      id={`customFields.${_customField.id}` as Path<Entity>}
                      label={_customField.name ?? ""}
                      register={register}
                      control={control}
                      errors={errors}
                      placeholder={placeholders?.[customField.id] ?? _customField.placeholder ?? ""}
                      required={_customField.required}
                      defaultValue={_customField.defaultValues}
                      disabled={disabled || _customField.readOnly}
                      dataType={_customField.dataType!}
                      enumOptions={_customField.enumOptions}
                      showAsTextArea={_customField.showAsTextArea}
                      description={_customField.description}
                      isMulti={!!_customField.isMulti}
                      customFieldId={customField.id}
                      enumOptionsFromValues={customField.enumOptionsFromValues}
                      filters={
                        Array.isArray(customField.customTypeConstraint) && !!customField.customTypeConstraint.length
                          ? { customTypeIds: customField.customTypeConstraint.map((c) => c.id) }
                          : { customTypeIds: null }
                      }
                      customTypeConstraint={customField.customTypeConstraint}
                    />
                  </React.Fragment>
                );
              })}
          </React.Fragment>
        );
      })}
    </>
  );
};
