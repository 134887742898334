import { EntityAddForm, EntityAddFormPage } from "../../common/entity/EntityAddForm";
import { Bridge } from "../../api/Bridges";
import { BridgeForm } from "./BridgeForm";
import { EntityCloneFormProps, EntityDetailViewProps, EntityEditFormProps } from "../../common/entity/EntityInterfaces";
import { EntityCloneForm, EntityCloneFormPage } from "../../common/entity/EntityCloneForm";
import { EntityEditForm, EntityEditFormPage } from "../../common/entity/EntityEditForm";
import { EntityDetailPage, EntityDetailView } from "../../common/entity/EntityDetailPage";
import { BridgesDetail } from "./BridgesDetail";
import { EntityTablePage } from "../../common/entity/EntityTablePage";
import { BridgesTable } from "./Table/BridgesTable";

// Add
export const BridgeAddForm = ({
  onSuccess,
  initialValues,
}: {
  onSuccess?: (bridge: Bridge) => void;
  initialValues?: Partial<Bridge>;
}) => {
  return (
    <EntityAddForm entityTypeId={"bridges"} onSuccess={onSuccess}>
      {(props) => (
        <BridgeForm
          {...props}
          initialValues={{
            ...initialValues,
            port: initialValues?.type === "SFTP" && !initialValues.port ? 22 : initialValues?.port,
          }}
        />
      )}
    </EntityAddForm>
  );
};

export const BridgeAddFormPage = () => {
  return (
    <EntityAddFormPage>
      <BridgeAddForm />
    </EntityAddFormPage>
  );
};

// Clone
export const BridgeCloneForm = ({ id }: EntityCloneFormProps<"bridges">) => {
  return (
    <EntityCloneForm id={id} entityTypeId={"bridges"}>
      {(props) => (
        <BridgeForm {...props} initialValues={{ ...props.initialValues, sftpAuthenticationMethod: undefined }} />
      )}
    </EntityCloneForm>
  );
};

export const BridgeCloneFormPage = () => {
  return <EntityCloneFormPage<"bridges"> isIntId>{(id) => <BridgeCloneForm id={id} />}</EntityCloneFormPage>;
};

// Edit
export const BridgeEditForm = ({ id }: EntityEditFormProps<"bridges">) => {
  return (
    <EntityEditForm id={id} entityTypeId={"bridges"}>
      {(props) => <BridgeForm {...props} />}
    </EntityEditForm>
  );
};

export const BridgeEditFormPage = () => {
  return <EntityEditFormPage<"bridges"> isIntId>{(id) => <BridgeEditForm id={id} />}</EntityEditFormPage>;
};

// Detail
export const BridgesDetailView = ({ id }: EntityDetailViewProps<"bridges">) => {
  return (
    <EntityDetailView id={id} entityTypeId={"bridges"} filters={{}}>
      {(props) => <BridgesDetail {...props} />}
    </EntityDetailView>
  );
};

export const BridgeDetailsPage = () => {
  return <EntityDetailPage<"bridges"> isIntId>{(id) => <BridgesDetailView id={id} />}</EntityDetailPage>;
};

export const BridgesTablePage = () => {
  return <EntityTablePage entityTypeId={"bridges"}>{(props) => <BridgesTable {...props} />}</EntityTablePage>;
};
