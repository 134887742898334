import {
  IGenericRequestParametersTranslator,
  IPaginationParametersTranslator,
  PropertyTranslator,
} from "./GenericTranslator";
import { EntityConstants, IGenericRequestParameters, IModelWithIntId, IPaginationParameters } from "./GenericTypes";
import { Person } from "./Person";

export const auditLogConstants: EntityConstants<AuditLog, AuditLogFilters> = {
  resource: "audit_logs",
  frontendIndexRoute: "audit_logs",
  entitySingular: "audit log",
  entityPlural: "audit logs",
  icon: "terminal",
};

export type AuditLogEventType =
  | "Login"
  | "Logout"
  | "ApiKeyCreate"
  | "ApiKeySoftDelete"
  | "ApiKeyRestore"
  | "ApiKeyDelete"
  | "EntityCreate"
  | "EntityEdit"
  | "EntitySoftDelete"
  | "EntityRestore"
  | "EntityDelete"
  | "EntityVersionRestore";

export const AuditLogEventTypes: AuditLogEventType[] = [
  "Login",
  "Logout",
  "ApiKeyCreate",
  "ApiKeySoftDelete",
  "ApiKeyRestore",
  "ApiKeyDelete",
  "EntityCreate",
  "EntityEdit",
  "EntitySoftDelete",
  "EntityRestore",
  "EntityDelete",
  "EntityVersionRestore",
];

export enum AuditLogEntityType {
  Sample = "Sample",
  Dataset = "Dataset",
  LabNotebook = "LabNotebook",
  LabNotebookExperiment = "LabNotebookExperiment",
  LabNotebookEntry = "LabNotebookEntry",
  LabNotebookTemplate = "LabNotebookTemplate",
  Person = "Person",
  Announcement = "Announcement",
  AutoloadConfiguration = "AutoloadConfiguration",
  AutoloadSource = "AutoloadSource",
  Document = "Document",
  Experiment = "Experiment",
  Facility = "Facility",
  EquipmentFacility = "EquipmentFacility",
  ITFacility = "ITFacility",
  InstrumentFacility = "InstrumentFacility",
  StorageFacility = "StorageFacility",
  Folder = "Folder",
  Method = "Method",
  Organization = "Organization",
  PersonCategory = "PersonCategory",
  Project = "Project",
  SharedBundleContent = "SharedBundleContent",
  SharedBundle = "SharedBundle",
  User = "User",
  SharedContent = "SharedContent",
  Origin = "Origin",
  CustomField = "CustomField",
  SampleType = "SampleType",
  CustomImport = "CustomImport",
  DatasetType = "DatasetType",
  ApiKey = "ApiKey",
  ProjectTag = "ProjectTag",
  OrganizationTag = "OrganizationTag",
  Bridge = "Bridge",
  DataSource = "DataSource",
  CustomFieldDBModel = "CustomFieldDBModel",
  CustomTypeDBModel = "CustomTypeDBModel",
  CustomSampleType = "CustomSampleType",
  CustomDatasetType = "CustomDatasetType",
  CustomInventoryType = "CustomInventoryType",
  CustomProjectType = "CustomProjectType",
  CustomPersonType = "CustomPersonType",
  CustomMethodType = "CustomMethodType",
  CustomFacilityType = "CustomFacilityType",
  Inventory = "Inventory",
}

export const AuditLogEntityTypes = Object.values(AuditLogEntityType);

export interface AuditLog extends IModelWithIntId {
  timestamp: string;
  eventId: AuditLogEventType;
  user?: Person;
  apiKeyId?: number;
  entityType?: AuditLogEntityType;
  entityIntId?: number;
  // entityInt?: IEntityMinimalModel;
  entityStringId?: string;
  entityName?: string;
  // entityString?: IEntityMinimalModel;
  entityCreatedVersion?: number;
  // entityCreatedVersion?: IEntityMinimalModel;
  payload?: any;
  message: string;
}

export const AuditLogSortingOptionsConsts = [
  "ID_ASC",
  "ID_DESC",
  "TIMESTAMP_ASC",
  "TIMESTAMP_DESC",
  "EVENT_ID_ASC",
  "EVENT_ID_DESC",
  "USER_ASC",
  "USER_DESC",
  "API_KEY_ASC",
  "API_KEY_DESC",
  "ENTITY_TYPE_ASC",
  "ENTITY_TYPE_DESC",
  "ENTITY_INT_ID_ASC",
  "ENTITY_INT_ID_DESC",
  "ENTITY_STRING_ID_ASC",
  "ENTITY_STRING_ID_DESC",
  "ENTITYNAME_ASC",
  "ENTITYNAME_DESC",
  "ENTITY_VERSION_CREATED_ID_ASC",
  "ENTITY_VERSION_CREATED_ID_DESC",
] as const;

export type AuditLogSortingOptions = (typeof AuditLogSortingOptionsConsts)[number];

type EntityIntIdsParameter = {
  [type in AuditLogEntityType]: number[];
};

type EntityStringIdsParameter = {
  [type in AuditLogEntityType]: string[];
};

export interface AuditLogFilters
  extends IPaginationParameters,
    IGenericRequestParameters<AuditLog, AuditLogSortingOptions> {
  timestampFrom?: string | null;
  timestampTo?: string | null;
  eventIds?: AuditLogEventType[] | null;
  userIds?: number[] | null;
  apiKeyIds?: (string | number)[] | null;
  entityTypes?: AuditLogEntityType[] | null;
  entityIntIds?: EntityIntIdsParameter | null;
  entityStringIds?: EntityStringIdsParameter | null;
  entityVersionIdsCreated?: number[] | null;
}

export const AuditLogFiltersTranslator: PropertyTranslator<AuditLogFilters> = {
  ...IPaginationParametersTranslator,
  ...IGenericRequestParametersTranslator,
  apiKeyIds: "API-Key IDs",
  timestampFrom: "Timestamp from",
  timestampTo: "Timestamp to",
  eventIds: "Event IDs",
  userIds: "User IDs",
  entityTypes: "Entity Types",
  entityIntIds: "Entity IDs",
  entityStringIds: "Entity IDs",
  entityVersionIdsCreated: "Entity version IDs created",
} as const;

export const AuditLogFieldLabels: PropertyTranslator<AuditLog> = {
  id: "Audit-Log ID",
  timestamp: "Timestamp",
  eventId: "Event ID",
  user: "User",
  apiKeyId: "API-Key",
  entityType: "Entity Type",
  entityIntId: "Entity ID",
  entityStringId: "Entity ID",
  entityName: "Target",
  entityCreatedVersion: "Version",
  payload: "Payload",
  message: "Message",
} as const;
