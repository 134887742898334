import React from "react";
import styles from "./About.module.css";
import { LucideIcon } from "../common/icon/LucideIcon";

export const EULA = () => {
  return (
    <div className={styles.eula_container}>
      <div className={styles.eula_head}>
        <LucideIcon name="scale" /> End User License Agreement (EULA)
      </div>
      <div className={styles.eula_text}>
        <p style={{ marginLeft: "auto" }}>Version 1, last updated: 2024-05-20 </p>
        <p>
          This <b>LOGS Software Agreement</b> (the "<u>Agreement</u>") is entered into by and between{" "}
          <b>Bruker Biospin GmbH & Co. KG, Rudolf-Plank-Str. 27, Ettlingen 76275, Germany</b> (“LOGS”) and the
          subscriber identified below (the “<u>Subscriber</u>”) as of the last date of signature below (the “
          <u>Effective Date</u>”).
        </p>
        <ol>
          <li>
            <b>
              <u>Definitions.</u>
            </b>
            The below capitalized terms have the meanings set forth below or in the referenced provision. Other
            capitalized terms used herein are defined as they arise in this Agreement.
            <ol>
              <li>
                “<u>Quote</u>” means a written quote from LOGS specifying the Software licenses acquired and the
                corresponding Fees for such payable to LOGS.
              </li>
              <li>
                “<u>Authorized Collaborator</u>” has the meaning set forth in Section 2.3 below.
              </li>
              <li>
                “<u>Authorized Number of Instruments</u>” means the number of Registered Instruments that Subscriber has
                purchased a subscription for pursuant to a purchase order issued or payment made in response to an
                applicable Quote.
              </li>
              <li>
                “<u>Authorized Number of Users</u>” means the number of Registered Users that Subscriber has purchased a
                subscription for pursuant to a purchase order issued or payment made in response to an applicable Quote.
              </li>
              <li>
                “<u>Documentation</u>” means the user documentation provided to Subscriber with respect to the use of
                the Software, as updated from time to time by LOGS.
              </li>
              <li>
                “<u>LOGS API</u>” means an application program interface defined as set of services, protocols and tools
                for building software third party client applications according to the licensing model.
              </li>
              <li>
                “<u>Registered Instrument</u>” means the Instrument where Software is Authorised to be utilised
                according to the relevant Quote.
              </li>
              <li>
                “<u>Registered User</u>” means an employee or individual consultant or contractor (who is a natural
                person) of Subscriber or its Authorized Collaborator who has been authorized by Subscriber or (if
                Subscriber permits) its Authorized Collaborator to use the Software on behalf of Subscriber or its
                Authorized Collaborator and who has been provided a unique user name and password for use when using
                such Software.
              </li>
              <li>
                “<u>Subscriber Content</u>” means all text, data, graphics, pictures, or other content that Subscriber,
                its Authorized Collaborators, or the Registered Users post or upload into the Software. As set forth in
                Section 5.3(b), Subscriber Content is the Confidential Information of Subscriber.
              </li>
              <li>
                “<u>Subscription</u>” has the meaning set forth in Section 2.1 below.
              </li>
              <li>
                “<u>Subscription Fees</u>” has the meaning set forth in Section 4.
              </li>
              <li>
                “<u>Term</u>”, “<u> InitialTerm</u>”, and “<u>Renewal Term</u> each have the meaning set forth in
                Section 3.1.
              </li>
            </ol>
          </li>
          <li>
            <b>
              <u>Grant of Subscription License; Subscription Restrictions.</u>
            </b>
            <ol>
              <li>
                <u>Software License and Subscription</u>. Subject to the terms and conditions in this Agreement and as
                specified in the Quote, LOGS grants to Subscriber, and Subscriber accepts, a nonexclusive,
                nontransferable license (the “<u>Subscription</u>”), without the right to sublicense, for Registered
                Users of Subscriber and of its Authorized Collaborators to install and use the Software in the manner
                described in the Software Documentation, solely for Subscriber’s benefit. Subscriber acknowledges that
                while the license entitles Subscriber and its Authorized Collaborators to use the Software, Subscriber
                is not entitled or permitted to download or otherwise obtain a copy of the Software.
              </li>
              <li>
                <u>Registered Users</u>. Subscriber may register with the Software named employees and individual
                consultants or contractors (who are natural persons) of Subscriber and its Authorized Collaborators as
                Registered Users. Each Registered User shall login unique login and password associated with such
                Registered User. Subscriber and its Authorized Collaborators shall not permit any person to use a
                Registered User’s login and password to login and access the Software (other than the Registered User
                associated with such login and password). As further described in Section 4.3, if the aggregate number
                of Registered Users of Subscriber and its Authorized Collaborators or the number or authorised
                instruments exceeds the then current Authorized Number of Users, then Subscriber shall pay LOGS for the
                purchase of additional licenses for the excess additional Registered Users, which shall correspondingly
                increase the Authorized Number of Users. Subscriber may replace an existing Registered User with a new
                Registered User without increasing the aggregate number of Registered User licenses, provided that the
                replaced Registered User may not continue to access the Software after being replaced and further
                provided that such replaced Registered User may not in the future replace another Registered User and
                may only be restored as a Registered User through Subscriber’s purchase of a new license for such
                Registered User as described in Section 4.3.
              </li>
              <li>
                <u>Authorized Collaborators</u>. Subscriber may authorize a third party with whom Subscriber is working
                or collaborating with to access the Software as an “Authorized Collaborator” of Subscriber hereunder
                (each an “<u>Authorized Collaborator</u>”) in order that Subscriber may better collaborate with such
                Authorized Collaborator. LOGS reserves the right to check that the Authorized Collaborators and their
                Registered Users are not in breach of the licensing and pricing conditions of the Subscriber. Subscriber
                acknowledges that Authorized Collaborators and their Registered Users shall have access and may
                contribute to Subscriber Content. For purposes of clarity, an Authorized Collaborator and its Registered
                Users may be Authorized Collaborators and Registered Users of one or more other subscribers of LOGS, in
                which case the Authorized Collaborator and its Registered Users will have separate and unique logins for
                those other subscriber accounts. When an Authorized Collaborator and its Registered Users are logged
                into the Software under a different subscriber’s account, they will not have access to Subscriber
                Content; and when they are logged in to Subscriber’s account, they will not have access to any other
                subscriber’s content through the Software. Subscriber acknowledges that while such an Authorized
                Collaborator and its Registered Users are logged into the Software under logins and passwords associated
                with a different subscriber’s account (i.e., not Subscriber’s account) where they are acting as
                authorized collaborators and registered users of such different subscriber, then the Authorized
                Collaborator’s and its Registered Users’ use of the Software shall not be governed by this Agreement
                (but rather by LOGS’s agreement with such other subscriber).
              </li>
              <li>
                <u>Subscriber Responsibilities and Obligations</u>. Subscriber and its Authorized Collaborators shall be
                solely responsible, at its sole cost and expense, for establishing, maintaining, and operating their
                connection to the Internet, including all access lines, all Internet service provider connection
                charges, and any long distance telephone charges. Subscriber shall be responsible for the actions and
                omissions of all its Authorized Collaborators and the Registered Users of Subscriber and its Authorized
                Collaborators with respect to their use of the Software.
              </li>
              <li>
                <u>Use of LOGS API</u>
                <ol type="a">
                  <li>
                    The Subscriber will require its Registered Users to comply with (and not knowingly enable them to
                    violate) applicable law, regulation, and the Terms.
                  </li>
                  <li>
                    The subscriber and the Registered users will only access (or attempt to access) the LOGS API by the
                    means described in the documentation of that API. If LOGS assigns you developer credentials (e.g.
                    client IDs), you must use them with the applicable APIs. You will not misrepresent or mask either
                    your identity or your API Client’s identity when using the APIs or developer accounts.
                  </li>
                  <li>
                    The Terms are non-exclusive. You acknowledge that LOGS may develop products or services that may
                    compete with the API Clients or any other products or services.
                  </li>
                  <li>
                    LOGS sets and enforces limits on your use of the API (e.g. limiting the number of API requests that
                    you may make or the number of users you may serve. You agree to, and will not attempt to circumvent,
                    such limitations documented with each API. If you would like to use any API beyond these limits, you
                    must obtain LOGS express consent so using the LOGS API without an API license or performing more
                    request per unit of time than defined in your purchasing conditions will also lead to a revocation
                    of your license.
                  </li>
                  <li>
                    When using the LOGS API, you may not (or allow those acting on your behalf to):
                    <ul>
                      <li>
                        Sublicense an API for use by a third party. Consequently, you will not create an API Client that
                        functions substantially the same as the APIs and offer it for use by third parties.
                      </li>
                      <li>
                        Perform an action with the intent of introducing to LOGS products and services any viruses,
                        worms, defects, Trojan horses, malware, or any items of a destructive nature.
                      </li>
                      <li>Defame, abuse, harass, stalk, or threaten others.</li>
                      <li>Interfere with or disrupt the API or the servers or networks providing the API.</li>
                      <li>
                        Reverse engineer or attempt to extract the source code from any API or any related software.
                      </li>
                      <li>
                        Use the APIs for any activities where the use or failure of the APIs could lead to death,
                        personal injury, or environmental damage.
                      </li>
                      <li>
                        Remove, obscure, or alter any LOGS terms of service or any links to or notices of those terms.
                      </li>
                    </ul>
                  </li>
                </ol>
              </li>
              <li>
                <u>No Modification, Reverse Engineering, etc.</u> Subscriber and its Authorized Collaborators and their
                respective Registered Users shall not
                <ol type="a">
                  <li>
                    copy, modify, or create derivative work of the Software (other than the one granted by the use of
                    LOGS API) or
                  </li>
                  <li>access, attempt to access, or otherwise interfere with the LOGS Servers or Software.</li>
                </ol>
              </li>
              <li>
                <u>Security</u>. When applicable, LOGS (or its subcontractors as applicable) shall use good faith
                efforts to implement security measures (such as password protection and encryption) and maintain such
                other safeguards which are reasonably intended to prevent the destruction, loss, interception, or
                alteration of Subscriber Content by unauthorized persons and which are consistent with current
                commercial practices in the industry. The parties expressly recognize that, although LOGS shall take
                such reasonable steps, or cause such reasonable steps to be taken, to prevent security breaches, it is
                impossible to maintain flawless security. Subscriber and its Registered Users will not attempt to
                disable, modify or circumvent any security safeguard adopted by LOGS.
              </li>
              <li>
                <u>Training</u>. Subscriber may engage LOGS to provide training pursuant to a separate written
                agreement.
              </li>
              <li>
                <u>Technical Support</u>. LOGS shall provide reasonable technical support via email during its normal
                business hours. Technical support questions and requests should be addressed to{" "}
                <a href="mailto:support@sciy.com" target="_blank" rel="noreferrer">
                  support@sciy.com
                </a>
                .
              </li>
            </ol>
          </li>
          <li>
            <b>
              <u>Term and Termination</u>
            </b>
            .
            <ol>
              <li>
                <u>Term</u>. Subject to earlier termination as described in Section 3.2, and unless otherwise agreed in
                writing by the parties, this Agreement shall commence on the Effective Date and continue for the
                associated term as stated in the associated Quote (such period being referred to as the “
                <u>Initial Term</u>”). Thereafter, this Agreement may be renewed for successive renewal terms of one
                year each (each a “<u>Renewal Term</u>”, and, together with the Initial Term, the “<u>Term</u>”) if
                Subscriber notifies LOGS of its desire to renew at least ten (10) days prior to the expiration of the
                then current Initial Term of Renewal Term.
              </li>
              <li>
                <u>Termination</u>. This Agreement may be terminated prior to the expiration of its Term as follows:
                <ol type="a">
                  <li>
                    By Subscriber for any or no reason upon ten (10) days’ written notice to LOGS, in which case LOGS
                    shall not have any obligation to provide any refund, including for fees paid to LOGS in advance by
                    Subscriber;
                  </li>
                  <li>
                    By LOGS, if Subscriber fails to pay any amount when due and does not amend such breach within ten
                    (10) days of delivery of written notice;
                  </li>
                  <li>
                    By LOGS, if Subscriber has breached any provision of this Agreement and, if such breach is subject
                    to amendment, such breach has remained unamended for at least thirty (30) days following notice
                    thereof (and if not subject to amendment, then upon notice of the breach to Subscriber).
                  </li>
                </ol>
              </li>
              <li>
                <u>Effects of Termination</u>.
                <ol type="a">
                  <li>
                    Except as expressly provided herein, upon any expiration or termination of this Agreement, all
                    rights, licenses and obligations of the parties shall immediately cease and terminate and Subscriber
                    and its Authorized Collaborators and their respective Registered Users shall cease to use the
                    Software. Subscriber shall pay LOGS in full any remaining unpaid amounts owed to LOGS relating to
                    the Subscription.
                  </li>
                  <li>
                    Subject to Subscriber paying LOGS all unpaid amounts owed, Subscriber shall be entitled to acces
                    Suscriber’s Content obtain a copy of the Subscriber Content, in case it is not in Suscriber’s own
                    servers, for a period discretionary granted by LOGS.
                  </li>
                  <li>
                    Notwithstanding the foregoing, the provisions of Sections 2.5, 3.3, 4, 5, 6, 7, 8 and 9.6 shall
                    survive the termination of this Agreement in accordance with their terms.
                  </li>
                </ol>
              </li>
            </ol>
          </li>
          <li>
            <b>
              <u>Subscription Fees</u>
            </b>
            .
            <ol>
              <li>
                <u>Trial</u>. Subscriber may obtain a free of cost evaluation version of the Software for a period up to
                45 days. The use of the evaluation version is subject to the terms and conditions of this Agreement.
                After expiry of the trial period, Subscriber’s and Authorized Collaborators’ access to the Software is
                contingent upon Subscriber’s payment to LOGS of the relevant subscription fees (the “
                <u>Subscription Fees</u>”).
              </li>
              <li>
                <u>Initial Subscription Term</u>. The Subscriber shall pay LOGS the Subscription Fee specified in the
                applicable Quote for the Initial Term for a subscription to use Software on or prior to the Effective
                Date, specified in the applicable Quote.
              </li>
              <li>
                <u>Additional Registered User or Instruments Licenses</u>. If Subscriber desires to add additional
                Registered Users or Instruments in excess of the then current Authorized Number of any of them, then
                Subscriber may purchase an additional Registered User license for each Registered User or Registered
                Instrument in excess of the Authorized Number of Users and/or Instruments (and thereby increase the
                Authorized Number of Users and/or Instruments by such number) by paying LOGS the then current per
                Registered User Subscription Fee (pro-rated for any partial Term based on when the additional Registered
                Users are registered) for each additional Registered User or Instrument license. LOGS may from time to
                time during each Initial Term and Renewal audit the number of ,Registered Users and if LOGS discovers
                that the number of Registered Users of Subscriber and its Authorized Collaborators and/or Instruments
                exceeds the then Authorized Number of any of them, then LOGS shall invoice Subscriber and Subscriber
                shall pay LOGS the applicable Subscription Fees for such additional Registered Users and/or Instruments
                (prorated for any partial Term based on when the additional Registered Users were registered).
              </li>
              <li>
                <u>Payment Terms</u>. All payments shall be made in Euros or in USA dollars, as determined in the
                relevant Quote.
              </li>
            </ol>
          </li>
          <li>
            <b>
              <u>Ownership, Intellectual Property Rights and Nondisclosure</u>
            </b>
            .
            <ol>
              <li>
                LOGS and its licensors are the sole owners of the Software and of all copyright, trade secret, patent,
                trademark and other intellectual property rights therein and thereto. This Agreement does not provide
                Subscriber with any rights to the Software, as expressly set forth herein. As between Subscriber and
                LOGS, Subscriber is the sole owner of the Subscriber Content and all intellectual property right therein
                and thereto, and this Agreement does not provide LOGS with any rights to the Subscriber Content except
                as expressly set forth herein.
              </li>
              <li>
                Subscriber shall take all reasonable steps to ensure that no unauthorized persons have access to the
                Software using logins and passwords issued to Subscriber or its Authorized Collaborators or Registered
                Users, and to ensure that no persons authorized to have such access shall take any action which would be
                in violation of this Agreement if taken by Subscriber. Subscriber shall promptly report to LOGS any
                actual or suspected violation of this Section, and shall take further steps as may reasonably be
                requested by LOGS to prevent or remedy any such violation.
              </li>
              <li>
                <u>Confidentiality Obligations</u>.
                <ol type="a">
                  <li>
                    <b>Confidential Information</b>. From time to time, either party (the “Disclosing Party”) may
                    disclose or make available to the other party (the “Receiving Party”), whether orally or in physical
                    or electronic form, confidential or proprietary information concerning the Disclosing Party and/or
                    its business, products, customers, services, policyholders and/or claimants, including but not
                    limited to information and/or documents concerning; (1) financial information, strategic business
                    plans, policies and/or methods; (2) marketing, claims, sales, underwriting strategy, and decision
                    making processes; (3) pricing and/or profit information; (4) lists of actual or prospective
                    customers; (5) proprietary and/or confidential intellectual property; and (6) intellectual property
                    of third parties licensed to the disclosing party (collectively, “Confidential Information”) in
                    connection with this Agreement. Each party agrees that during the Term and thereafter: (i) it will
                    use Confidential Information belonging to the Disclosing Party solely for the purpose(s) of this
                    Agreement; and (ii) it will take reasonable precautions, but no less than it would take to prevent
                    the disclosure of its own similar Confidential Information, to ensure that it does not disclose
                    Confidential Information belonging to the Disclosing Party to any third party (other than the
                    Receiving Party’s employees, contractors, and/or professional advisors on a need-to-know basis who
                    are bound by obligations of nondisclosure and limited use at least as stringent as those contained
                    herein) without first obtaining the Disclosing Party’s written consent. Upon request by the
                    Disclosing Party, the Receiving Party will return all copies of any Confidential Information to the
                    Disclosing Party. For Confidential Information that does not constitute trade secrets under
                    applicable law, these confidentiality obligations will expire three (3) years after the termination
                    or expiration of this Agreement, and for Confidential Information that constitutes trade secrets
                    shall survive until such Confidential Information ceases to be a trade secret. The Receiving Party
                    will be responsible for any breach of this Section by its employees, representatives, and agents.
                    Non-public information regarding the Software (including but not limited to performance, operation,
                    and data formats) and the Documentation are the Confidential Information of LOGS. The terms of this
                    Agreement are Confidential Information of both parties, but may be disclosed by either party with
                    its advisors and potential investors or acquirers who are subject to confidentiality obligations or
                    to enforce its terms.
                  </li>
                  <li>
                    <b>Subscriber Content</b>. LOGS acknowledges and agrees that the Subscriber Content is the
                    Confidential Information of Subscriber (subject to Section 5.3(c)) and is therefore subject to the
                    restrictions on disclosure and use set forth in Section 5.3(a) above. Notwithstanding the foregoing,
                    LOGS may use Subscriber Content or other Subscriber Confidential Information to (i) provide Services
                    to Subscriber, (ii) monitor Subscriber’s and its Authorized Collaborators’ and Registered Users’ use
                    of the Software for security purposes, (iii) enforce the terms of this Agreement, and (iv) to study
                    usage of the Software, including to aggregate with other subscribers’ usage data, in order to
                    identify patterns of use and/or improve the Software and to further develop other software
                    applications offered by LOGS, including to train algorithms.
                  </li>
                  <li>
                    <b>Exclusions</b>. For purposes hereof, “Confidential Information” will not include any information
                    that the Receiving Party can establish by convincing written evidence: (i) was independently
                    developed by the Receiving Party without use of or reference to any Confidential Information
                    belonging to the Disclosing Party; (ii) was acquired by the Receiving Party from a third party
                    having the legal right to furnish same to the Receiving Party; or (iii) was at the time in question
                    (whether at disclosure or thereafter) generally known by or available to the public (through no
                    fault of the Receiving Party).
                  </li>
                  <li>
                    <b>Required Disclosures</b>. These confidentiality obligations will not restrict any disclosure
                    required by order of a court or any government agency, provided that the Receiving Party gives
                    prompt notice to the Disclosing Party of any such order and reasonably cooperates with the
                    Disclosing Party at the Disclosing Party’s request and expense to resist such order or to obtain a
                    protective order.
                  </li>
                  <li>
                    <b>Equitable Relief</b>. Because unauthorized access, use, disclosure or transfer of the
                    Confidential Information or other intellectual property of either party in violation of this Section
                    5 will diminish substantially the value of such Confidential Information or other intellectual
                    property and irreparably harm the owner of such Confidential Information or intellectual property,
                    if either party breaches the provisions of this Section 5, the other party shall be entitled to seek
                    equitable relief, including a restraining order, preliminary and permanent injunctive relief,
                    specific performance and any other relief that may be available from any court, without providing a
                    bond or other security, in addition to any other remedy to which such party may be entitled at law
                    or in equity.
                  </li>
                </ol>
              </li>
            </ol>
          </li>
          <li>
            <b>
              <u>Data Privacy</u>
            </b>
            .
            <ol type="a">
              <li>
                <b>Subscriber’s contact information</b>: LOGS will process the Subscriber’s personal data (i.e. name,
                surname, company, email, address, payment details) for several purposes depending on the Subscriber’s
                interaction with LOGS: If the Subscriber has registered for a free trial or to make a purchase, the
                Subscriber’s data will be used to set up and maintain the Subscriber’s user account, for compliance
                screenings (i.e. to satisfy export control requirements) and, in case of a purchase, to invoice the
                Subscriber and administrate that sales case. LOGS will use the data provided by the Subscriber via the
                website, email or any other contact channel to follow up on any request submitted by the Subscriber as
                well as to inform the Subscriber on LOGS’s products and services related to any purchase made by the
                Subscriber. These activities are based on either the performance of LOGS’s contractual relationship with
                the Subscriber (article 6 (1) b GDPR), on LOGS’s legitimate interest (article 6 (1) f GDPR) or the
                fulfilment of a legal obligation (article 6 (1) c GDPR). LOGS may engage in general marketing
                communication with the Subscriber based on the Subscriber’s prior consent. LOGS does not use the
                Subscriber’s personal data for automated decision making or profiling. The provision of the the
                Subscriber’s personal information as required by LOGS is necessary to enter and perform the contract
                with LOGS and/or to manage any requests submitted by the Subscriber. Failure to provide such necessary
                information may lead to LOGS not being able to offer the products and/or services required by the
                Subscriber.
              </li>
              <li>
                <b>Personal data processed in Software</b>. Subscriber acknowledges that LOGS, may have access to
                limited personal information related to Registered Users. The Parties will enter into the Data
                Processing Agreement as set out in Exhibit A to this Agreement. Please contact{" "}
                <a href="mailto:support@sciy.com" target="_blank" rel="noreferrer">
                  support@sciy.com
                </a>{" "}
                in the case of any privacy-related enquiry.
              </li>
            </ol>
          </li>
          <li>
            <b>
              <u>Limited Warranty; Limitations of Liability</u>
            </b>
            .
            <ol>
              <li>
                LOGS warrants to Subscriber that the Software shall perform substantially in accordance with the
                Documentation. LOGS’s sole obligation, and Subscriber’s sole remedy, with respect to any breach of this
                limited warranty of performance shall be for LOGS to correct such nonconformance or provide reasonable
                alternative functionality at LOGS’s sole cost and expense.
              </li>
              <li>
                Except as stated above, LOGS disclaims all other warranties, both express and implied, with respect to
                the Software, including, but not limited to, all implied warranties of merchantability and fitness for a
                particular purpose (irrespective of any previous course of dealing between the parties or custom or
                usage of trade), non-infringement, or that the Software results will be error free.
              </li>
              <li>
                LOGS’s liability for damages to Subscriber for any claims whatsoever, and for all claims in the
                aggregate, regardless of the form of any claim or action, shall not exceed the Subscription Fees paid
                under this agreement for the initial or renewal term in which the most recent claim arose.
              </li>
              <li>
                LOGS shall in no event be liable for any indirect, special, incidental, exemplary, punitive or
                consequential damages arising out of or in connection with the use or performance of the Software,
                whether or not LOGS has been made aware of the possibility of such damages, including, but not limited
                to, any damages resulting from loss of data or content or lost profits.
              </li>
              <li>
                Subscriber acknowledges and agrees that LOGS only grants the rights under this Agreement in reliance on
                limitations of liability and disclaimers of warranty set forth in Sections 7.2, 7.3 and 7.4 above and
                that these provisions are essential terms of this Agreement.
              </li>
            </ol>
          </li>
          <li>
            <b>
              <u>Indemnification</u>
            </b>
            .
            <ol>
              <li>
                LOGS shall defend, indemnify, and hold harmless Subscriber and its officers, directors, employees, and
                shareholders from and against any and all any damages, penalties, judgments and reasonable related costs
                and expenses, including but not limited to reasonable legal fees and expenses, (“Damages”) arising out
                of any third party claim or allegation (a “Claim”) that Subscriber’s use or access of the Software in
                accordance with the terms of this Agreement infringes the patent, copyright or other intellectual
                property right of any third party, except to the extent (a) due to any Subscriber Content or specific
                materials that LOGS uses or uploads into the Software or (b) such claim is based on Subscriber’s use of
                such the Software in combination with other services and products not supplied by LOGS and not required
                by the Documentation.
              </li>
              <li>
                Subscriber shall defend, indemnify, and hold harmless LOGS, and its respective officers, directors,
                employees, and shareholders from and against any and all Damages, including but not limited to
                reasonable legal fees and expenses, arising out of any third party Claim that any Subscriber Content
                posted or loaded into the Software by Subscriber or its Authorized Collaborators or Registered Users
                infringes the patent, copyright or other intellectual property right of any third party or violates any
                laws, including privacy laws.
              </li>
              <li>
                If Subscriber or LOGS intend to claim indemnification hereunder (for itself or for another Indemnitee)
                with respect to any Damages arising under a Claim, then Subscriber or LOGS (the “
                <u>Indemnified Party</u>
                ”) shall promptly notify the other party (the “<u>Indemnifying Party</u>”) of any Claim in respect of
                which the Indemnified Party (whether for itself or for one of the persons entitled to indemnification
                under Section 8.1 or 8.2) intends to claim such indemnification reasonably promptly after the
                Indemnified Party is aware thereof, and the Indemnifying Party shall assume the defense of any related
                third party action, suit or proceeding with counsel of the Indemnifying Party’s choice. The indemnity
                agreement in this Section 8 shall not apply to amounts paid in settlement of any claim, loss, damage or
                expense if such settlement is effected without the consent of the Indemnifying Party, which consent
                shall not be withheld or delayed unreasonably. The failure of the Indemnified Party to deliver notice to
                the Indemnifying Party within a reasonable time after the Indemnified Party becomes aware of any such
                matter, if prejudicial to Indemnifying Party’s ability to defend such action, shall relieve the
                Indemnifying Party of any liability under this Section 8 with respect to such Claim. The Indemnified
                Party and all indemnitees shall cooperate fully with the Indemnifying Party and its legal
                representatives in the investigation of any matter covered by this indemnification.
              </li>
              <li>
                Should the Software become, or in LOGS’s opinion, be likely to become the subject of a claim or an
                injunction preventing its use as contemplated herein, LOGS may, at its option (i) procure for Subscriber
                the right to continue to access the Software hereunder, (ii) replace or modify the Software so that it
                becomes non-infringing, or, if LOGS determines, in its sole discretion, that (i) and (ii) are not
                commercially practical for LOGS, then (iii) LOGS may terminate this Agreement and arrange to refund to
                Subscriber a pro-rated amount of the Subscription Fees paid in advance for the then current Initial Term
                or Renewal Term based on the remaining portion of the then current Term for which such Subscription Fees
                were paid.
              </li>
              <li>
                This Section 8 states the entire liability of LOGS with respect to infringement of third party
                intellectual property rights by the Software or any part thereof or by its operation.
              </li>
            </ol>
          </li>
          <li>
            <b>
              <u>Compliance with Laws; Export Control</u>
            </b>
            .
            <ol>
              <li>
                Subscriber agrees to comply with all applicable laws with respect to Subscriber’s use of the Software.
                Subscriber acknowledges and agrees that the Software, technical data and/or services (“<b>Items</b>”),
                are subject to export control, customs, economic sanctions, and anti- boycott laws, regulations, rules,
                and associated executive orders enacted, issued, entered, promulgated, or enforced by the European
                Union, the United States, the United Nations, the Organization for Security and Co-operation in Europe
                (the “OSCE”), or the country where Subscriber resides (provided such applicable laws or regulations of
                the Territory do not conflict with applicable regulations of the European Union or the United States)
                (each individually, an “Export Control Law” and, collectively, the “Export Control Laws”). Subscriber
                will comply with all applicable Export Control Laws and not provide any Items to anyone in a country or
                other destination that is subject to a United States Government export embargo (currently Cuba, Iran,
                North Korea, Sudan, Syria and the Crimea Region) (an “Embargoed Destination”), to a representative of an
                Embargoed Destination, or to persons or for purposes prohibited under any Export Control Law. Prohibited
                end-uses include the research on or development of chemical, biological or nuclear weapons, unmanned air
                vehicles or missiles, or nuclear explosive or fuel cycle activities. In addition to any other remedy it
                may have, LOGS may terminate this Agreement immediately and without notice, and cancel the download,
                activation and/or provision of any maintenance or other customer service involving any Item if (a) LOGS
                has not received all export- related documentation requested by LOGS or (b) LOGS believes that such
                activity may violate any Export Control Law or LOGS’s own compliance policies. If Subscriber accesses
                the LOGS Server, or purchases, downloads, or activates Items from the LOGS Server, Subscriber represents
                that it is not in any Embargoed Destination, acting for anyone in an Embargoed Destination, and that it
                is not a person or entity (or owned by one or more entities) that has/have been sanctioned or otherwise
                listed on a prohibited persons lists issued by the United States, the United Nations, the European
                Union, the OSCE, the United Kingdom, Japan, Australia or Switzerland. Subscriber shall indemnify LOGS
                for all losses, costs, claims, damages and expenses (including attorneys’ fees and expenses) arising
                from its violation or alleged violation of any applicable federal, state or local law, including without
                limitation, any Export Control Law.
              </li>
            </ol>
          </li>
          <li>
            <b>
              <u>Miscellaneous</u>
            </b>
            .
            <ol>
              <li>
                <u>Trademark and Names</u>. This Agreement does not grant either party the right to use any trademark,
                trade name or logo of the other party in any advertising or promotional material. If mutually agreed by
                Subscriber and LOGS, LOGS may identify Subscriber as a customer of LOGS, including as a subscriber of
                the Software, in promotional materials and published lists of LOGS’s customers and/or issue a press
                release describing this subscription including a quote from Subscriber.
              </li>
              <li>
                <u>Entire Agreement</u>. This Agreement constitutes the entire agreement between LOGS and Subscriber
                with respect to the subject matter hereof, and supersedes any prior agreement between the parties with
                respect to the subject matter hereof. No Addendum, waiver, consent, modification, amendment or change of
                the terms of this Agreement shall bind either party unless in writing and signed by duly authorized
                officers of Subscriber and LOGS. No terms and conditions in any purchase order issued by Subscriber
                under this Agreement, including in response to a Quote, shall apply and any such purchase order terms
                and conditions shall be superseded by the terms of this Agreement, except that, as applicable, such
                purchase order shall evidence Subscriber’s acceptance of the applicable Quote, including Subscriber’s
                election of any options or choices expressly contemplated by such Quote (such as number of Authorized
                Number of Users).
              </li>
              <li>
                <u>Severability</u>. In the event that any provision of this Agreement is held by a court of competent
                jurisdiction to be unenforceable because it is invalid or in conflict with any law of any relevant
                jurisdiction, the validity of the remaining provisions shall not be affected, and the rights and
                obligations of the parties shall be construed and enforced as if the Agreement did not contain the
                particular provisions held to be unenforceable.
              </li>
              <li>
                <u>Assignments</u>. Neither this Agreement nor any rights, obligations or subscriptions granted
                hereunder may be assigned or delegated by either party, including but not limited to by operation of
                law, without the prior written consent of the other party, which shall not unreasonably be withheld,
                except that either party may assign this Agreement to a successor to its business in connection with a
                merger or sale of all or substantially all of its assets. This Agreement shall inure to the benefit of
                the parties and their permitted successors and assigns.
              </li>
              <li>
                <u>Notices</u>. Any notice by a party under this Agreement shall be in writing and either personally
                delivered or sent via reputable overnight courier (such as Federal Express) or certified mail, postage
                prepaid and return receipt requested addressed to the other party as follows:
                <p>
                  <i>To LOGS</i>:
                </p>
                <p>
                  <i>To Subscriber</i>: to the address on the signature page of this Agreement; or, in each case, such
                  other address of which either party may from time to time notify the other in accordance with this
                  Section 10.5. All notices shall be in English and shall be deemed effective on the date of personal
                  delivery, one business day after deposit with an overnight courier, or five (5) business days after
                  deposit in the mail.
                </p>
              </li>
              <li>
                <u>Governing Law; Dispute Resolution</u>. The validity, construction and interpretation of this
                Agreement, and the rights and duties of the parties, shall be governed by and construed in accordance
                with the laws of the local jurisdiction of the applicable LOGS entity listed on Page 1 of this
                agreement, without giving effect to the conflict of law provisions thereof, and excluding any
                application of the United Nations Convention on Contracts for the International Sale of Goods. Other
                than as necessary to enforce any final judgment, award or determination or to obtain a preliminary
                injunction or other equitable relief to safeguard a party’s intellectual property or confidential
                information, any action brought pursuant to or in connection with this Agreement shall be brought only
                in the state or federal courts within the local jurisdiction of the applicable LOGS entity listed on
                Page 1 of this Agreement without regard to its conflict of law provisions and both parties submit to the
                personal jurisdiction, and waive any objections to venue, of such courts.
              </li>
              <li>
                <u>No Waiver</u>. The waiver by either party of a breach of a default of any provision of this Agreement
                by the other party shall not be construed as a waiver of any succeeding breach of the same or any other
                provision, nor shall any delay or omission on the part of either party to exercise or avail itself of
                any right, power or privilege that it has, or may have thereunder, operate as a waiver of any right,
                power or privilege by such party.
              </li>
              <li>
                <u>Section Headings</u>. Captions and section headings hereof are for reference purposes only and shall
                not control or alter the meaning of this Agreement as set forth in the text.
              </li>
              <li>
                <u>Force Majeure</u>. In the event that either party is unable to perform any of its obligations under
                this Agreement because of causes beyond its reasonable control or because of any Act of God, accident to
                equipment or machinery; any fire, flood, hurricane, tornado, storm or other weather condition; any war,
                act of war, act of public enemy, terrorist act, sabotage, riot, civil disorder, act or decree of any
                governmental body; any failure of communications lines, transportation, light, electricity or power; any
                earthquake, civil disturbance, commotion, lockout, strike or other labor or industrial disturbance; or
                any illness, epidemic, quarantine, death or any other natural or artificial disaster the party who has
                been so affected shall immediately give notice to the other party and shall do everything reasonably
                possible to resume performance. Upon receipt of such notice, all obligations under this Agreement shall
                be immediately suspended and performance times shall be considered extended for a period of time
                equivalent to the time lost because of any such delay. Nothing provided herein shall excuse the delay of
                any payment that is validly due by Subscriber to LOGS.
              </li>
            </ol>
          </li>
        </ol>
        <p>
          The Subscriber agrees to be bound by this End User License Agreement and evidences so by pressing the ‘Agree’
          button.
        </p>
        <hr></hr>
        <p>
          <p>
            <strong>Exhibit A</strong>
          </p>
          <p>
            <b>Data Processing Agreement</b>
          </p>
          <p>
            <b>LOGS</b>
          </p>
          <p>
            This Data Processing Agreement (“Addendum”) forms part of the LOGS Account Agreement (“Agreement”) between
            LOGS and the Subscriber for the provision of the Software (“Software”).
          </p>
          <p>
            In this Addendum LOGS is also referred to as “the processor” and Subscriber is also referred to as “the
            controller”.
          </p>
          <p>
            In consideration of the mutual obligations set out herein, the parties hereby agree that the terms and
            conditions set out below shall be added as an Addendum to the Agreement. In the event of any conflict or
            inconsistency between this Addendum and the Agreement in effect between the Subscriber and LOGS, this
            Addendum shall prevail. In the event of any conflict or inconsistency between this Addendum and the Model
            Clauses under Section 1.4, the Model Clauses shall prevail, except with respect to provisions of this
            Addendum that expressly clarify a specific provision of the Model Clauses.
          </p>
          <ol>
            <li>
              <i>Definitions</i>: Capitalized terms not defined herein will have the meanings given to them in the
              Agreement. In this Addendum, the following terms shall have the following meanings:
              <ol type="a">
                <li>
                  “<b>controller</b>“, “<b>processor</b>“, “<b>data subject</b>“, “<b>personal data</b>” and “
                  <b>processing</b>” (and “<b>process</b>“) shall have the meanings given in the Applicable Data
                  Protection Law;
                </li>
                <li>
                  “<b>Software”</b> shall have the meaning assigned under the Agreement;
                </li>
                <li>
                  “<b>Registered User</b>” shall have the meaning assigned under the Agreement;
                </li>
                <li>
                  “<b>Applicable Data Protection Law</b>” shall mean: Regulation 2016/679 of the European Parliament and
                  of the Council (“General Data Protection Regulation” or “GDPR”), as well as laws implementing and
                  supplementing the GDPR. Outside of the scope of the Regulation referred to above, “Applicable Data
                  Protection Law” shall mean the data protection laws of the country in which the relevant Subscriber
                  entity (controller) is domiciled or where the Subscriber’s end users are located;
                </li>
              </ol>
            </li>
            <li>
              <i>Relationship of the parties</i>: Subscriber (the controller) appoints LOGS as a processor to process
              the personal data described in <u>Annex A</u> (the “<b>Data</b>“). Subscriber shall verify that the
              processing operations for the provision of the Software under the Agreement – including any international
              transfer – are in compliance with the Applicable Data Protection Law and notify LOGS about any potential
              noncompliance before the processing starts.
            </li>
            <li>
              <i>Governance</i>. LOGS acts as a processor and Subscriber and those entities that it permits to use the
              Software act as controllers under the Addendum. Subscriber acts as a single point of contact and is solely
              responsible for obtaining any relevant authorizations, consents and permissions for the processing of
              personal data in accordance with this Addendum, including, where applicable approval by controllers to use
              LOGS as a Processor. Where authorizations, consent, instructions or permissions are provided by Subscriber
              these are provided not only on behalf of the Subscriber but also on behalf of any other controller using
              the Software. Where LOGS informs or gives notice to Subscriber, such information or notice is deemed
              received by those controllers permitted by Subscriber to use the Software and it is Subscriber’s
              responsibility to forward such information and notices to the relevant Controllers.
            </li>
            <li>
              <i>Purpose limitation</i>: LOGS shall process the Data as a processor for the purpose of providing the
              Software according to the purposes described in Annex A and strictly in accordance with the documented
              instructions given by the Subscriber to LOGS (the “<b>Permitted Purpose</b>“), except where otherwise
              required by any law applicable to LOGS. In such a case, LOGS will inform Subscriber of such legal
              requirement before processing, unless the law prohibits LOGS to inform Subscriber on important grounds of
              public interest. The Agreement (including this Addendum) constitutes the documented initial instructions.
              LOGS will use reasonable efforts to follow any other Subscriber instructions, as long as they are required
              by Data Protection Law, technically feasible and do not require changes to the Software. If any of the
              beforementioned exceptions apply, or LOGS otherwise cannot comply with an instruction or is of the opinion
              that an instruction infringes Data Protection Law, LOGS will immediately notify Subscriber (email
              permitted). Subscriber acknowledges that LOGS collects and processes aggregated system performance data to
              ensure proper performance of the services: such data sets may contain personal related information such as
              usernames and any personal related information contained in log files. For the sake of clarity, data used
              on development and test systems do not contain personal data.
            </li>
            <li>
              <i>International transfers</i>: Within the scope of GDPR, personal data are not transferred outside of the
              European Economic Area (“<b>EEA</b>“). In the event personal data need to be transferred outside of the
              EEA or the defined country of storage and processing as defined in the Agreement, LOGS will (i) inform
              Subscriber in advance; and (ii) take such measures as are necessary to ensure the transfer is in
              compliance with the Applicable Data Protection Law. Such measures may include (without limitation)
              transferring the Data to a recipient in a country that the European Commission has decided provides
              adequate protection for personal data, to a recipient that has achieved binding corporate rules
              authorisation in accordance with Applicable Data Protection Law, to a recipient that has executed the
              standard contractual clauses (“Model Clauses”) adopted or approved by the European Commission, as the case
              may be. In the case Data will be subject to an international transfer as described in this section, the
              Model Clauses Controller to Processor will be automatically incorporated in this Addendum. A copy of the
              Model Clauses (notified under the document C(2010) 593) is available at the following link:{" "}
              <a
                href="https://eur-lex.europa.eu/legal-content/en/TXT/?uri=CELEX%3A32010D0087"
                target="_blank"
                rel="noreferrer"
              >
                https://eur-lex.europa.eu/legal-content/en/TXT/?uri=CELEX%3A32010D0087
              </a>
              . In case the data exporter under the clauses is located outside of the EU/EEA, the term “member state”
              under the Model Clauses shall mean “country where the data exporter is located”.
            </li>
            <li>
              <i>Confidentiality of processing</i>: LOGS shall ensure that any person that it authorises to process the
              Data (including LOGS’s staff, agents and subcontractors) (an “<b>Authorised Person</b>“) shall be subject
              to an appropriate duty of confidentiality (whether a contractual duty or a statutory duty) and shall not
              permit any person to process the Data who is not under such a duty of confidentiality. LOGS shall ensure
              that all Authorised Persons process the Data only as necessary for the Permitted Purpose.
            </li>
            <li>
              <i>Security</i>: LOGS shall implement appropriate technical and organisational measures to protect the
              Data (i) from accidental or unlawful destruction, and (ii) loss, alteration, unauthorised disclosure of,
              or access to the Data (a “<b>Security Incident</b>“). Such measures shall have regard to the state of the
              art, the costs of implementation and the nature, scope, context and purposes of processing as well as the
              risk of varying likelihood and severity for the rights and freedoms of natural persons. Such measures
              shall include, as appropriate:
              <ol type="a">
                <li>the pseudonymisation and encryption of personal data;</li>
                <li>
                  the ability to ensure the ongoing confidentiality, integrity, availability and resilience of
                  processing systems and services;
                </li>
                <li>
                  the ability to restore the availability and access to personal data in a timely manner in the event of
                  a physical or technical incident;
                </li>
                <li>
                  a process for regularly testing, assessing and evaluating the effectiveness of technical and
                  organisational measures for ensuring the security of the processing.
                </li>
              </ol>
            </li>
            <li>
              <i>Subprocessing</i>: Subscriber acknowledges and agrees that Data Processor may engage subprocessors to
              process personal data in connection with the services pursuant to the Agreement, subject to the
              requirements in this section. LOGS shall enter into a binding agreement with each subprocessor before any
              transfer of personal data to such subprocessor. Such agreement shall impose data protection terms that
              protect the personal data at the same standard provided for by this Addendum, especially regarding
              confidentiality, data protection and information security. LOGS shall remain liable for any breach of this
              Addendum if and to the extent that it is caused by an act, error or omission of its subprocessor. LOGS may
              chance a subprocessor at its discretion, provided that: (a) LOGS will inform Subscriber in advance by
              e-mail of any intended additions or replacements to the list of subprocessors including name, address and
              role of the new subprocessor; and (b) Subscriber may object to such changes. If Subscriber refuses to
              consent to LOGS’s appointment of a third party subprocessor on reasonable grounds relating to the
              protection of the data, then either LOGS will not appoint the subprocessor to the services rendered to
              Subscriber, or, if that is not possible Subscriber may elect to suspend or terminate the Agreement. Any
              termination under this Section shall be deemed to be without fault by either party; Subscriber shall be
              entitled to a refund on a pro rata basis of any subscription charges paid in advance. LOGS may replace a
              Subprocessor without advance notice where the reason for the change is outside of LOGS’s reasonable
              control and prompt replacement is required for security or other urgent reasons. In this case, LOGS will
              inform Subscriber of the replacement Subprocessor as soon as possible following its appointment. A list of
              subprocessors as at the date of this Addendum is attached as <u>Annex B</u>.
            </li>
            <li>
              <i>Cooperation and data subjects’ rights</i>: At Subscriber’s request, LOGS will reasonably cooperate with
              Subscriber in dealing with requests from Data Subjects or regulatory authorities regarding LOGS’s
              processing of Personal Data or any Security Incident referred to in Section 11 below. LOGS shall notify
              the Subscriber as soon as reasonably practical about any request it has received from a data subject in
              relation to the Personal Data processing, without itself responding to such request without Subscriber’s
              further instructions, if applicable. LOGS shall provide functionality that supports Subscriber’s ability
              to correct or remove Personal Data from the Software, or restrict its processing in line with Data
              Protection Law. Where such functionality is not provided, LOGS will correct or remove any Personal Data,
              or restrict its processing, in accordance with the Subscriber’s instruction and Data Protection Law.
            </li>
            <li>
              <i>Data Protection Impact Assessment</i>: If LOGS believes or becomes aware that its processing of the
              Data under GDPR is likely to result in a high risk to the data protection rights and freedoms of data
              subjects, it shall promptly inform Subscriber and provide Subscriber with all such reasonable and timely
              assistance as Subscriber may require in order to conduct a data protection impact assessment and, if
              necessary, consult with its relevant data protection authority.
            </li>
            <li>
              <i>Security incidents</i>: Upon becoming aware of a Security Incident, LOGS shall inform Subscriber
              without undue delay and shall provide all such timely information and cooperation as Subscriber may
              require in order for Subscriber to fulfil its data breach reporting obligations under (and in accordance
              with the timescales required by) the Applicable Data Protection Law. LOGS shall further take all such
              measures and actions as are necessary to remedy or mitigate the effects of the Security Incident and shall
              keep Subscriber informed of all developments in connection with the Security Incident.
            </li>
            <li>
              <i>Audit</i>: LOGS shall permit Subscriber (or its appointed third party auditors, (which shall not
              include any third party auditors who are either a competitor of LOGS or not suitably qualified or
              independent) to audit LOGS’s compliance with this Addendum, if: (a) LOGS has not provided sufficient
              evidence of its compliance with the technical and organizational measures or with the terms of this
              Addendum; (b) a Security Incident has occurred; (c) an audit is formally requested by Subscriber’s data
              protection authority; or (d) Mandatory Data Protection Law provides Subscriber with a direct audit right
              and provided that Subscriber shall only audit once in any twelve month period unless mandatory Data
              Protection Law requires more frequent audits. Subscriber (or its third-party auditors) may enter its
              premises for the purposes of conducting this audit, provided that Subscriber gives it reasonable prior
              notice of its intention to audit, conducts its audit during normal business hours, and takes all
              reasonable measures to prevent unnecessary disruption to LOGS’s operations. Document-based audits are
              preferred.
            </li>
            <li>
              <i>Deletion or return of Data</i>: Upon termination or expiry of the Agreement, LOGS shall (at
              Subscriber’s election) destroy or return to Subscriber all Data (including all copies of the Data) in its
              possession or control (including any Data subcontracted to a third party for processing). This requirement
              shall not apply to the extent that LOGS is required any law applicable to LOGS to retain some or all of
              the Data, in which event it shall isolate and protect the Data from any further processing except to the
              extent required by such law.
            </li>
            <li>
              <i>No Consideration</i>:{" "}
              <u>
                Notwithstanding anything in the Agreement and in this Addendum, LOGS’s access to Subscriber personal
                data is not part of the consideration exchanged by the parties in respect of the Agreement
              </u>
              .
            </li>
            <li>
              <i>Indemnity</i>: Each party (the “<b>Indemnifying Party</b>“) shall indemnify the other (the “
              <b>Indemnified Party</b>“) from and against all loss, cost, harm, expense (including reasonable legal
              fees), liabilities or damage (“<b>Damage</b>“) suffered or incurred by the Indemnified Party as a result
              of the Indemnifying Party’s breach of the data protection provisions set out in this Addendum, and
              provided that: (i) the Indemnified Party gives the Indemnifying Party prompt notice of any circumstances
              of which it is aware that give rise to an indemnity claim under this Addendum; and (ii) the Indemnified
              Party takes reasonable steps and actions to mitigate any ongoing Damage it may suffer as a consequence of
              the Indemnifying Party’s breach.
            </li>
            <li>
              <i>Liability</i>: LOGS is liable for the damage caused by processing where it has not complied with
              obligations of the Data Protection Law applicable to the processor, or where it has acted outside or
              contrary to lawful instructions of the Subscriber. LOGS is liable to pay administrative fines which result
              from a breach of the provisions of the Data Protection Law applicable to the processor. LOGS shall be
              exempt from its liability, only if it proves that it’s not responsible for the event giving rise to the
              breach of the provisions of the Data Protection Law Applicable to LOGS.
            </li>
          </ol>
        </p>
        <hr></hr>
        <p>
          <p>
            <strong>Annex A</strong>
          </p>
          <p>
            <b>Data Processing Description</b>
            <p>
              This Annex A forms part of the Agreement and describes the processing that the processor shall perform on
              behalf of the controller.
            </p>
          </p>
          <p>
            <b>Purposes</b>
            <p>
              The processor will process the personal data on behalf of the controller for the following purposes:
              <ul>
                <li>Provision and maintenance of the Software</li>
              </ul>
            </p>
          </p>
          <p>
            <b>Controller</b>
            <p>
              The controller is (please specify briefly the controller’s activities relevant to the processing): the
              Subscriber who subscribed to the Software that allows certain users to enter, amend, use, delete or
              otherwise process personal data. Where the Subscriber allows other companies to also use the Software,
              these other companies are considered as controllers to the personal data under their control.
            </p>
          </p>
          <p>
            <b>Processor</b>
            <p>The processor is LOGS provides and maintains the Software as described in the Agreement.</p>
          </p>
          <p>
            <b>Data subjects</b>
            <p>
              The personal data to be processed concern the following categories of data subjects: Registered Users,
              e.g. Subscriber’s Employees or Subscriber’s Students in the case Subscriber is a university or educational
              institution.
            </p>
          </p>
          <p></p>
          <p>
            <b>Categories of data</b>
            <p>
              The personal data to be processed concern the following categories of data (please specify): first name,
              last name, address (work), e-mail, phone, organization (company or institution), organizational role,
              Registered Users access and use of the Software as resulting from the log files.
            </p>
          </p>
          <p>
            <b>Special categories of data (if applicable)</b>
            <p>The personal data to be processed concern the following special categories of data Not Applicable</p>
          </p>
          <p></p>
          <p>
            <b>Processing operations</b>
            <p>
              The personal data will be subject to the following basic processing activities:
              <ul>
                <li>Acquiring the Software</li>
                <li>Installing the Software or premise or on a third’s party platform</li>
                <li>Monitoring Backup & restoration of Subscriber data, if aplicable</li>
                <li>Release and development of fixes and upgrades to the Software</li>
                <li>
                  Support when a Subscriber submits a support ticket because the Software is not working as expected.
                  LOGS answers the requests from Subscriber and performs basic troubleshooting, and handles support
                  tickets in a tracking system that is separate from the production instance of the Software
                </li>
              </ul>
            </p>
          </p>
          <p></p>
          <p>
            <p>
              <b>Data Privacy Office Contact Information:</b>
            </p>
            <p>LOGS</p>
            <p>
              <a href="mailto:jakob.lopez-zeller@sciy.com" target="_blank" rel="noreferrer">
                jakob.lopez-zeller@sciy.com
              </a>
            </p>
          </p>
        </p>
      </div>
    </div>
  );
};
