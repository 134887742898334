import { StringIndexedDict } from "../../../../api/GenericTypes";
import {
  GenericEntityVirtualizedEntitySelectFormFieldProps,
  GenericVirtualizedEntitySelectFormFieldControllerWrapper,
} from "../../../formfields/GenericVirtualizedSelectFormField/GenericVirtualizedEntitySelectFormFieldControllerWrapper";
import {
  EquipmentFacilityFilters,
  EquipmentFacilitySuggestions,
  FacilitySortingOptionsConsts,
  equipmentsConstants,
} from "../../../../api/Facilities";
import { useContext } from "react";
import { SessionContext } from "../../../contexts/SessionContext";
import { QuickAddWrapper } from "../../QuickAdd/common/QuickAddWrapper";
import { QuickAddEquipmentForm } from "../../QuickAdd/forms/QuickAddEquipmentForm";

export const EquipmentsVirtualizedSelectForm = <Entity extends StringIndexedDict>(
  props: GenericEntityVirtualizedEntitySelectFormFieldProps<
    Entity,
    EquipmentFacilitySuggestions,
    EquipmentFacilityFilters
  >
) => {
  const { session } = useContext(SessionContext);

  return (
    <GenericVirtualizedEntitySelectFormFieldControllerWrapper<
      Entity,
      EquipmentFacilitySuggestions,
      EquipmentFacilityFilters
    >
      {...props}
      filters={{ orderBy: "NAME_ASC", ...props.filters }}
      //   onRowRenderer={(item) => <></>}
      createModal={({ showModal, setShowModal, onCreate }) => (
        <QuickAddWrapper showModal={showModal} setShowModal={setShowModal} onCreate={onCreate}>
          {(quickAddProps) => (
            <QuickAddEquipmentForm
              {...quickAddProps}
              initialValues={{
                ...quickAddProps.initialValues,
                ...(props.filters?.customTypeIds && {
                  customType: { name: "", id: props.filters?.customTypeIds?.[0] },
                }),
              }}
            />
          )}
        </QuickAddWrapper>
      )}
      entityConstants={equipmentsConstants}
      orderByOptions={FacilitySortingOptionsConsts}
      allowCreateEntity={session?.permissions.administer_facilities && props.allowCreateEntity}
    />
  );
};
