import { CSSProperties, ReactNode, useContext, useEffect, useState } from "react";
import { useResizeDetector } from "react-resize-detector";
import { EntityConstants, GenericEntity, IdTypes, IEntityMinimalModel, INamedEntity } from "../../../api/GenericTypes";
import { getIndexRoute } from "../../../main/Routing";
import { SessionContext } from "../../contexts/SessionContext";
import { toUppercase } from "../../helperfunctions/stringFunctions";
import { LucideIcon } from "../../icon/LucideIcon";
import { ConditionalLink } from "../../misc/ConditionalLink/ConditionalLink";

interface EntityBreadcrumbsProps<Entity extends GenericEntity & INamedEntity> {
  entityConstants: EntityConstants;
  indexEntityLabelOverride?: string;
  indexEntityConstantsOverride?: EntityConstants;
  indexEntityFullRouteOverride?: ReactNode;
  indexEntityLinkToOverride?: string;
  ancestorLinkToOverride?: (ancestor: IEntityMinimalModel<IdTypes>) => string;
  entityLinkToOverride?: (entity: IEntityMinimalModel<IdTypes>) => string;
  entity: Entity;
  breadcrumbs: IEntityMinimalModel<IdTypes>[];
  maxWidth?: CSSProperties["maxWidth"];
  renderInline?: boolean;
  showLinks?: boolean;
}

/**
 * Component to render breadcrumbs for an entity hierarchy.
 * @author @CorradoSurmanowicz
 * @template Entity - The type of the entity.
 * @param {EntityBreadcrumbsProps<Entity>} props - The properties for the EntityBreadcrumbs component.
 * @param {EntityConstants} props.entityConstants - Constants related to the entity.
 * @param {string} [props.indexEntityLabelOverride] - Optional override for the index entity label.
 * @param {EntityConstants} [props.indexEntityConstantsOverride=entityConstants] - Optional override for the index entity constants.
 * @param {ReactNode} [props.indexEntityFullRouteOverride] - Optional override for the full route of the index entity.
 * @param {string} [props.indexEntityLinkToOverride] - Optional override for the link to the index entity.
 * @param {(ancestor: IEntityMinimalModel<IdTypes>) => string} [props.ancestorLinkToOverride] - Optional override for the link to an ancestor entity.
 * @param {(entity: IEntityMinimalModel<IdTypes>) => string} [props.entityLinkToOverride] - Optional override for the link to the final entity.
 * @param {Entity} props.entity - The current entity.
 * @param {IEntityMinimalModel<IdTypes>[]} props.breadcrumbs - The list of ancestor breadcrumbs.
 * @param {CSSProperties["maxWidth"]} [props.maxWidth=400] - The maximum width of the breadcrumbs container.
 * @param {boolean} [props.renderInline=false] - Whether to render the breadcrumbs inline.
 * @param {boolean} [props.showLinks=true] - Whether to show links in the breadcrumbs.
 * @returns {JSX.Element} The rendered EntityBreadcrumbs component.
 */
export const EntityBreadcrumbs = <Entity extends GenericEntity & INamedEntity>({
  entityConstants,
  indexEntityLabelOverride,
  indexEntityConstantsOverride = entityConstants,
  indexEntityFullRouteOverride,
  indexEntityLinkToOverride,
  ancestorLinkToOverride,
  entityLinkToOverride,
  entity,
  breadcrumbs,
  maxWidth = 400,
  renderInline = false,
  showLinks = true,
}: EntityBreadcrumbsProps<Entity>) => {
  const { route } = useContext(SessionContext);
  const { width, ref: scrollRef } = useResizeDetector<HTMLDivElement>();
  const [scrollLeft, setScrollLeft] = useState(0);

  useEffect(() => {
    scrollRef.current?.scrollTo({ left: scrollRef.current?.scrollWidth, behavior: "auto" });
  }, [scrollRef, width]);

  const showEllipsis = scrollRef.current?.scrollWidth && width ? width < scrollRef.current?.scrollWidth : false;
  const hasAncestors = Array.isArray(breadcrumbs) && !!breadcrumbs.length;
  return (
    <div
      style={{
        // display: "grid",
        // gridTemplateColumns:
        //   "max-content" + // Back to inventory overview
        //   (breadcrumbs.length ? " minmax(auto, 1fr)" : "") + // Ancestors
        //   " minmax(15ch, max-content)", // Current inventory
        display: "flex",
        flexFlow: "row-nowrap",
        alignItems: "center",
        gap: "5px",
        // minWidth: "fit-content",
        maxWidth: "fit-content",
        overflow: "hidden",
        textOverflow: "ellipsis",
      }}
    >
      <div className="flex row-nowrap align-center gap-5" style={{ overflow: "hidden" }}>
        {indexEntityFullRouteOverride ?? (
          <ConditionalLink
            to={indexEntityLinkToOverride ?? route(getIndexRoute(indexEntityConstantsOverride.frontendIndexRoute))}
            style={{ textDecoration: "none", overflow: "hidden" }}
            title={`Show ${indexEntityConstantsOverride.entityPlural} table`}
            showLink={showLinks}
          >
            <div
              className="flex row-nowrap align-center gap-5"
              style={{
                fontSize: renderInline ? "14px" : hasAncestors ? "14px" : "24px",
                fontWeight: renderInline ? 0 : 500,
                lineHeight: renderInline ? "14px" : hasAncestors ? "14px" : "24px",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                // overflow: "hidden",
              }}
            >
              <LucideIcon
                name={indexEntityConstantsOverride.icon}
                color={"var(--primary)"}
                style={{
                  width: renderInline ? 14 : hasAncestors ? 14 : 20,
                  height: renderInline ? 14 : hasAncestors ? 14 : 20,
                  flexShrink: 0,
                }}
              />
              <span style={{ textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden" }}>
                {toUppercase(indexEntityLabelOverride ?? indexEntityConstantsOverride.entityPlural)}
              </span>
            </div>
          </ConditionalLink>
        )}
        <div>
          <LucideIcon name="arrow-right" color="var(--gray-400)" />
        </div>
      </div>
      {hasAncestors && (
        <div className="flex" style={{ position: "relative", overflow: "hidden", flex: "0 1 min-content" }}>
          <div
            className="flex align-center gap-5"
            style={{
              overflow: "auto",
              ...(!!maxWidth && { maxWidth: maxWidth }),
              height: "100%",
              position: "relative",
              flexShrink: 1,
            }}
            ref={scrollRef}
            onScroll={(e) => setScrollLeft(e.currentTarget.scrollLeft)}
          >
            {breadcrumbs.map((ancestor, index) => (
              <div className="flex row-nowrap align-center gap-5" key={ancestor.id} style={{ height: "100%" }}>
                <ConditionalLink
                  to={
                    ancestorLinkToOverride
                      ? ancestorLinkToOverride(ancestor)
                      : `${route(getIndexRoute(entityConstants.frontendIndexRoute))}/${ancestor.id}`
                  }
                  style={{ textDecoration: "none" }}
                  title={`Show ${entityConstants.entitySingular}`}
                  showLink={showLinks}
                >
                  <div
                    className="flex row-nowrap align-center gap-5"
                    style={{
                      fontSize: "14px",
                      fontWeight: renderInline ? 0 : 500,
                      lineHeight: "14px",
                      whiteSpace: "nowrap",
                    }}
                  >
                    <LucideIcon
                      name={entityConstants.icon}
                      color={"var(--primary)"}
                      style={{ width: 14, height: 14 }}
                    />
                    {ancestor.name}
                  </div>
                </ConditionalLink>
                {index < breadcrumbs.length && (
                  <div>
                    <LucideIcon name="arrow-right" color="var(--gray-400)" />
                  </div>
                )}
              </div>
            ))}
          </div>
          {showEllipsis && scrollLeft !== 0 && (
            <div
              className="flex"
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                background: "linear-gradient(100deg, rgba(255,255,255,1) 70%, rgba(255,255,255,0) 99%)",
                paddingRight: 5,
                pointerEvents: "none",
              }}
            >
              ...
            </div>
          )}
        </div>
      )}
      <div className="flex row-nowrap align-center gap-5" style={{ flex: "1 0 auto", overflow: "hidden" }}>
        {renderInline ? (
          <ConditionalLink
            to={
              entityLinkToOverride
                ? entityLinkToOverride(entity)
                : `${route(getIndexRoute(entityConstants.frontendIndexRoute))}/${entity.id}`
            }
            style={{ textDecoration: "none" }}
            title={`Show ${entityConstants.entitySingular}`}
            showLink={showLinks}
          >
            <div className="flex row-nowrap align-center gap-5" style={{ overflow: "hidden" }}>
              <div>
                <LucideIcon name={entityConstants.icon} color="var(--primary)" />
              </div>
              <div
                className="ellipsisContainer"
                style={{
                  // fontWeight: 500,
                  whiteSpace: "nowrap",
                }}
              >
                <span>{entity.name}</span>
              </div>
            </div>
          </ConditionalLink>
        ) : (
          <>
            <div>
              <LucideIcon name={entityConstants.icon} color="var(--primary)" />
            </div>
            <div
              style={{
                fontSize: "24px",
                fontWeight: 600,
                lineHeight: "24px",
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap",
              }}
            >
              {entity.name}
            </div>
          </>
        )}
      </div>
    </div>
  );
};
