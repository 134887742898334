import React, { useCallback, useEffect } from "react";
import { LoginPageProps } from "./SessionProvider";
import { Button } from "../../common/buttons/Button/Button";
import { LucideIcon } from "../../common/icon/LucideIcon";
import { useAuth } from "react-oidc-context";
import { registerByJWT } from "../../api/Login";
import { OIDCAuthLoading, OIDCAuthError } from "./OidcComponents";
import { Alert } from "../../common/overlays/Alert/Alert";
import { useReturnUrlStorage } from "./OidcRedirect";

export const OIDCLoginForm = ({ group, setSessionToken }: LoginPageProps) => {
  const auth = useAuth();

  // Store the URL in local storage so that we always know where to redirect the user after login
  useReturnUrlStorage();

  const register = useCallback(async () => {
    // const jtwStorageLocation = `group_${group}_user:${authConfig?.oidcAuthority}:${authConfig?.oidcClientId}`;
    // const jwt = JSON.parse(localStorage.getItem(jtwStorageLocation) || "") as User;
    if (auth.user?.id_token) {
      await registerByJWT(group, { token: auth.user.id_token }).catch(() => {});
      setSessionToken(auth.user.id_token);
    }
    window.location.reload();
  }, [auth.user?.id_token, group, setSessionToken]);

  useEffect(() => {
    if (auth.user?.id_token) setSessionToken(auth.user.id_token);
  }, [auth.user?.id_token, setSessionToken]);

  switch (auth.activeNavigator) {
    case "signinSilent":
      return <OIDCAuthLoading message="Signing you in..." />;
    case "signoutRedirect":
      return <OIDCAuthLoading message="Signing you out..." />;
  }

  if (auth.isLoading) return <OIDCAuthLoading />;

  if (auth.error) return <OIDCAuthError errorMessage={auth.error.message} />;

  // End of placeholder code

  if (auth.isAuthenticated) {
    return (
      <>
        <div style={{ fontWeight: "bold", fontSize: "1.2em", marginBottom: "1em" }}>
          Welcome {auth.user?.profile.name}!
        </div>
        <Alert
          type="info"
          message={`It seems you haven't registered yet. Please click on "Register" to complete the registration process.`}
          fit
        />
        <button className="btn btn-block btn-success" title="Register" onClick={async () => await register()}>
          <LucideIcon name="user-round-pen" /> Register
        </button>
        <button className="btn btn-block btn-soft-danger" title="Log out" onClick={async () => await auth.removeUser()}>
          <LucideIcon name="log-out" />
          Log out
        </button>
      </>
    );
  }

  return (
    <Button
      className="btn btn-primary btn-block"
      onClick={async () => await auth.signinRedirect()}
      style={{ marginLeft: "auto" }}
    >
      <LucideIcon name="log-in" /> Log in via Single Sign-On
    </Button>
  );
};
