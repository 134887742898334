import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { InputFormField } from "../common/formfields/InputFormField";
import { TextareaFormField } from "../common/formfields/TextareaFormField";
import { FormButtons } from "../common/forms/FormButtons";
import { FormHeader } from "../common/forms/FormHeader";
import styles from "../common/forms/forms.module.css";
import { Experiment } from "../api/Experiments";
import { MethodsVirtualizedSelectForm } from "../common/forms/EntityForms/formsVirtualized/MethodsVirtualizedSelectForm";
import { EntityFormProps } from "../common/entity/EntityInterfaces";

export const ExperimentForm = ({
  event,
  fieldLabels,
  permissions,
  title,
  subtitle,
  initialValues,
  onSubmit,
  onCancel,
  loading,
  submitButtonLabel,
}: EntityFormProps<"experiments">) => {
  const ExperimentFormSchema = yup.object().shape({
    name: yup.string().required("Experiment name is required").typeError("Experiment name is required"),
    method: yup.object().required("Method is required").typeError("Method is required"),
  });

  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
  } = useForm<Partial<Experiment>>({
    values: initialValues,
    resolver: yupResolver(ExperimentFormSchema),
  });

  return (
    <>
      <FormHeader title={title} subtitle={subtitle} />
      <form
        onSubmit={(e) => {
          e.preventDefault();
        }}
        autoComplete="off"
        className={`form-horizontal ${styles.form_holder}`}
      >
        <fieldset>
          <legend className="col-md-offset-2 col-md-10">Basic details</legend>
        </fieldset>

        <InputFormField
          id="name"
          label={fieldLabels.name}
          errors={errors}
          register={register}
          autoFocus={true}
          required={true}
          placeholder="Enter experiment name..."
        />
        <MethodsVirtualizedSelectForm
          id="method"
          label={fieldLabels.method}
          control={control}
          horizontal
          allowCreateEntity
          required={true}
        />

        <TextareaFormField
          id="notes"
          label={fieldLabels.notes}
          errors={errors}
          register={register}
          placeholder="Enter notes..."
        />

        <FormButtons
          groupName="experiments"
          entityId={initialValues ? initialValues.id : undefined}
          onClose={onCancel}
          onSubmit={handleSubmit(async (entity) => await onSubmit(entity))}
          submitButtonLabel={submitButtonLabel || "Save changes"}
          disabled={isSubmitting}
          errors={errors}
          loading={loading}
        />
      </form>
    </>
  );
};
