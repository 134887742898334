import { SerializedParameterSets } from "./../PipelineTypes";
import { EmptyParameter } from "./EmptyParameter";
import { CommandParameter, parameterTypes } from "../PipelineTypes";
import { ParameterChecker } from "../ParameterChecker";

// parameterTypes: Record<string, parameterTypes>;
// internalParameter: Record<string, CommandParameter>;
// parameterSettings: Record<string, parameterSettingType>;

type parameterSetType = {
  name: string;
  parameter: Record<string, CommandParameter>;
  parameterTypes: Record<string, parameterTypes>;
};

type inputType = { value: parameterSetType[]; selected: string };
type valueType = { sets: parameterSetType[]; value: Record<string, any>; selected: string };

export class ParameterSets extends EmptyParameter {
  name: string = "parameterSets";
  parameterType = parameterTypes.parameterSets;

  sets: parameterSetType[];
  selected: string;

  constructor(value?: any) {
    super(value);
    this.sets = [];
    this.value = {};
    this.selected = "";
    this.deserialize(value);
  }

  deserialize(value?: parameterSetType[] | inputType) {
    super.deserialize(value);
    const v = this.parseValue(value);
    this.sets = v.sets;
    this.value = v.value;
    this.selected = v.selected;
    // console.log("selected", this.selected);
  }

  parseValue(value?: parameterSetType[] | inputType): valueType {
    const result: valueType = { sets: this.sets, value: this.value, selected: "" };

    if (value === undefined) return result;

    if (typeof value !== "object") {
      // console.log("value", value, typeof value);
      this.errors.create({
        id: "",
        component: "ParameterSets.setValue",
        message: `Expected a list of parameters. (Got type '${typeof value}').`,
      });
      return result;
    }

    if (!Array.isArray(value)) {
      if (value?.selected) result.selected = value.selected;
      value = (value as inputType)?.value ?? [];
      // if (!Array.isArray(value)) console.log(" value", value);
    }
    // console.log(" =>", value, !Array.isArray(value));

    if (!Array.isArray(value)) {
      this.errors.create({
        id: "",
        component: "ParameterSets.setValue",
        message: `Expected a list of parameters. (Got type '${typeof value}').`,
      });
      return result;
    }

    const typeSet = new Set(Object.values(parameterTypes));
    const setMap = Object.fromEntries(result.sets.map((s, i) => [s.name, i]));

    // console.log("value", value);
    let i = 0;
    for (let { name, parameter } of value as parameterSetType[]) {
      if (!name) {
        this.errors.create({
          id: "",
          component: "ParameterSets.setValue",
          message: `Parameter set ${i} must specify a name.`,
        });
        return result;
      }

      const parameterSet: parameterSetType = { name: name, parameterTypes: {}, parameter: {} };
      if (name in setMap) result.sets[setMap[name]] = parameterSet;
      else {
        setMap[name] = result.sets.length;
        result.sets.push(parameterSet);
      }

      if (!parameter) {
        continue;
      }

      const parameterSettings: Record<string, any> = {};
      const parameterTypes: Record<string, parameterTypes> = {};

      // let j = 0;
      // for (let { parameterType, ...param } of parameter as CommandParameter[]) {
      Object.entries(parameter).forEach(([k, v]) => {
        // console.log("P", k + ":", v);
        const { parameterType, ...settings } = v;
        // console.log("type:", parameterType, "->", settings);
        if (!parameterType) {
          this.errors.create({
            id: "",
            component: "ParameterSets.setValue",
            message: `The parameter '${k}' in parameter set '${name}' must specify its type.`,
          });
          return result;
        }

        if (!typeSet.has(parameterType)) {
          this.errors.create({
            id: "",
            component: "ParameterSets.setValue",
            message: `The parameter ${k} in parameter set ${i + 1} has an unknown type. (Got type '${parameterType}')`,
          });
          return result;
        }

        parameterTypes[k] = parameterType;
        parameterSettings[k] = settings;

        // console.log("parameter", settings);

        // parameterSet.parameterTypes.push(parameterType);

        // j++;
      });

      // result.value = this.sets.map((v) => {
      //   const result = {};
      //   return {
      //     name: v.name,
      //     parameter: Object.fromEntries(Object.entries(v.parameter).map(([k, v]) => [k, v.value])),
      //   };
      // }) as any;

      // console.log("type", parameterTypes);
      // console.log("settings", parameterSettings);
      const checker = new ParameterChecker(parameterTypes);
      checker.createParameter(parameterSettings);
      // console.log("errors", checker.errors);

      // this.warnings.addTracesWithMessage(checker.warnings, {
      //   id: "",
      //   component: "ParameterSets.setValue",
      //   message: `Warning in parameter ${j + 1} in parameter set ${i + 1}`,
      // });

      // this.errors.addTracesWithMessage(checker.errors, {
      //   id: "",
      //   component: "ParameterSets.setValue",
      //   message: `Error in parameter ${j + 1} in parameter set ${i + 1}`,
      // });
      this.warnings.addTraces(checker.warnings);
      this.errors.addTraces(checker.errors);

      if (checker.errors.length < 1) {
        parameterSet.parameter = checker.parameter;
        parameterSet.parameterTypes = parameterTypes;
        // result.value[parameterSet.name] = Object.fromEntries(
        //   Object.entries(parameterSet.parameter).map(([k, v]) => {
        //     return [k, v.value];
        //   })
        // );
      }

      i++;
    }

    if (result.selected === "" && result.sets?.[0].name) result.selected = result.sets[0].name;
    const selected = result.sets.filter((s) => s.name === result.selected).shift();
    if (selected) {
      // console.log(">>", result.sets.filter((s) => s.name === result.selected).shift());
      result.value = {
        name: selected.name,
        parameter: Object.fromEntries(
          Object.entries(selected.parameter).map(([k, v]) => {
            return [k, v.value];
          })
        ),
      };
    }

    // result.value.selected = result.selected;
    // result.value = value.map((v) => String(v));
    return result;
  }

  // "value": [
  //   {
  //     "name": "option1",
  //     "parameter": {
  //       "phase0": {
  //         "parameterType": "float",
  //         "inputType": "range",
  //         "min": -3.14159265358979323,
  //         "max": 3.14159265358979323,
  //         "value": 0
  //       },
  //       "phase1": {
  //         "parameterType": "float",
  //         "inputType": "range",
  //         "min": -3.14159265358979323,
  //         "max": 3.14159265358979323,
  //         "value": 0
  //       },
  //       "pivot": { "parameterType": "float", "inputType": "xPick", "value": "Infinity" }
  //     }
  //   }
  // ]

  serialize(): SerializedParameterSets {
    // const result = serializeCommandParameter(this) as serializedParameterSetsType;
    const result = super.serialize() as SerializedParameterSets;
    // console.log("result", result);
    result.selected = this.selected;
    result.value = this.sets.map((v) => {
      // const result = {};
      return {
        name: v.name,
        parameter: Object.fromEntries(Object.entries(v.parameter).map(([k, v]) => [k, v.serialize()])),
      };
    }) as any;
    // Object.assign(result, { value: this.value });
    return result;
  }
}
