import { Dispatch, RefObject, SetStateAction, useEffect, useMemo, useState } from "react";

interface Args extends IntersectionObserverInit {
  freezeOnceVisible?: boolean;
}

const useIntersectionObserver = (
  elementRef: RefObject<Element>,
  { threshold = 0, root = null, rootMargin = "0%", freezeOnceVisible = false }: Args
): IntersectionObserverEntry | undefined => {
  const [entry, setEntry] = useState<IntersectionObserverEntry>();

  const observer = useMemo(() => {
    const updateEntry = ([entry]: IntersectionObserverEntry[]): void => {
      setEntry(entry);
    };
    return new IntersectionObserver(updateEntry, { threshold, root, rootMargin });
  }, [root, rootMargin, threshold]);

  useEffect(() => {
    const hasIOSupport = !!window.IntersectionObserver;
    const frozen = entry?.isIntersecting && freezeOnceVisible;
    const node = elementRef?.current; // DOM Ref

    if (!hasIOSupport || frozen || !node) return;

    observer.observe(node);

    return () => observer.disconnect();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [root, rootMargin, observer, entry?.isIntersecting, freezeOnceVisible, elementRef.current]);

  return entry;
};

export default useIntersectionObserver;

export const useIntersectionObserverWithState = ({
  threshold = 0,
  root = null,
  rootMargin = "0%",
  freezeOnceVisible = false,
}: Args): {
  entry: IntersectionObserverEntry | undefined;
  setCurrentNode: Dispatch<SetStateAction<Element | null>>;
} => {
  const [entry, setEntry] = useState<IntersectionObserverEntry>();
  const [node, setCurrentNode] = useState<Element | null>(null);

  const observer = useMemo(() => {
    const updateEntry = ([entry]: IntersectionObserverEntry[]): void => {
      setEntry(entry);
    };
    return new IntersectionObserver(updateEntry, { threshold, root, rootMargin });
  }, [root, rootMargin, threshold]);

  useEffect(() => {
    const hasIOSupport = !!window.IntersectionObserver;
    const frozen = entry?.isIntersecting && freezeOnceVisible;

    if (!hasIOSupport || frozen || !node) return;

    observer.observe(node);

    return () => observer.disconnect();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [root, rootMargin, observer, entry?.isIntersecting, freezeOnceVisible, node]);

  return { entry: entry, setCurrentNode };
};
