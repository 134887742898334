import { createSHA256 } from "hash-wasm";
import { IHasher } from "hash-wasm/dist/lib/WASMInterface";
import { useCallback, useMemo, useRef } from "react";
// import { showtoast } from "../../common/overlays/Toasts/showtoast";

// A transient hasher will initiate a new hasher on every instance (less efficient than re-using existing one)
export const useFileHashing = (transientHasher: boolean = false) => {
  const hasher = useRef<IHasher>();
  // const chunkSize = 1 * 1024 ** 2; // bytes
  const chunkSize = 64 * 1e3; // bytes

  const calculateSHA256 = useCallback(
    async (file: File) => {
      let _hasher: IHasher | undefined = undefined;
      if (transientHasher) {
        // console.log("Using transient hasher");
        _hasher = await createSHA256();
      } else {
        if (hasher.current) {
          hasher.current.init();
        } else {
          hasher.current = await createSHA256();
        }
        _hasher = hasher.current;
      }
      try {
        const chunkNumber = Math.floor(file.size / chunkSize);
        for (let i = 0; i <= chunkNumber; i++) {
          const chunk = file.slice(chunkSize * i, Math.min(chunkSize * (i + 1), file.size));
          // await hashChunk(chunk, hasher.current);
          const arrayBuffer = await chunk.arrayBuffer();
          const uInt8Array = new Uint8Array(arrayBuffer);
          _hasher.update(uInt8Array);
        }
        const hash = _hasher.digest();
        return Promise.resolve(hash);
      } catch (e: unknown) {
        // showtoast(
        //   "error",
        //   `Error while hashing the files: ${e instanceof Error && e.hasOwnProperty("message") ? e.message : e}`
        // );
        return Promise.reject(e);
      }
    },
    [chunkSize, transientHasher]
  );

  return { calculateSHA256 };
};

export const useBlobURL = () => {
  let urls = useMemo(() => new WeakMap(), []);

  const blobURL = useCallback(
    (blob: Blob) => {
      if (urls.has(blob)) {
        return urls.get(blob);
      } else {
        let url = URL.createObjectURL(blob);
        urls.set(blob, url);
        return url;
      }
    },
    [urls]
  );

  return blobURL;
};
