import { LucideIcon } from "../common/icon/LucideIcon";
import { Dispatch, SetStateAction, useContext, useEffect, useState } from "react";
import { AnnouncementsList } from "./Table/AnnouncementsList";
import { useIdsOnly } from "../api/BaseEntityApi";
import { Announcement, AnnouncementFilters, AnnouncementType, announcementsConstants } from "../api/Announcements";
import { useHistory } from "react-router-dom";
import { Modal } from "../ELN/common/ELNModal/ELNModal";
import { showtoast } from "../common/overlays/Toasts/showtoast";
import { ToasterContainerView } from "../common/overlays/Toasts/ToasterContainerView";
import { SessionContext } from "../common/contexts/SessionContext";
import { AnnouncementContentTypeField, AnnouncementTypeLabel } from "./AnnouncementsDetail";
import { useQueryClient } from "@tanstack/react-query";

const NewAnnouncementToast = ({
  announcement,
  setShowModal,
}: {
  announcement: Announcement;
  setShowModal: Dispatch<SetStateAction<boolean>>;
}) => {
  const { api } = useContext(SessionContext);
  const queryClient = useQueryClient();
  return (
    <div id={`active-announcement-${announcement.id}`}>
      <div className="flex align-center gap-5" style={{ justifyContent: "flex-end", width: "100%" }}>
        <div className="flex align-center gap-5" style={{ width: "100%" }}>
          <AnnouncementTypeLabel type={announcement.type} opacity="" />
          <h5 style={{ margin: 0 }}>Announcement</h5>
        </div>
        <button className="btn btn-default btn-xs flex align-center gap-5" onClick={() => setShowModal(true)}>
          <LucideIcon name="maximize" />
          Show
        </button>
        <button
          className="btn btn-default btn-xs flex align-center gap-5"
          onClick={() =>
            api
              .post(`announcements/${announcement.id}/setCurrentUserStates`, {
                isRead: true,
              })
              .then(() => {
                queryClient.invalidateQueries({ queryKey: ["announcements", "list"] });
                queryClient.invalidateQueries({ queryKey: ["announcements", "suggestions"] });
                queryClient.invalidateQueries({ queryKey: ["announcements", "ids_only"] });
                queryClient.invalidateQueries({ queryKey: ["announcements", "detail", announcement.id] });
              })
              .catch((e) => console.error(e))
          }
        >
          <LucideIcon name="circle-check-big" />
          Mark as read
        </button>
      </div>
      <hr style={{ margin: "5px 0" }} />
      <AnnouncementContentTypeField
        contentType={announcement.contentType}
        message={announcement.message}
        style={{
          maxHeight: "200px",
          overflow: "auto",
          backgroundColor: "var(--gray-100)",
        }}
      />
    </div>
  );
};

export const Announcements = () => {
  const { api } = useContext(SessionContext);
  const { location } = useHistory();
  const [show, setShow] = useState<boolean>(false);
  const { data, count, refetch } = useIdsOnly<number, AnnouncementFilters>(
    announcementsConstants.resource,
    {
      includeCount: true,
      current: true,
      includeSystemAnnouncements: true,
    },
    {
      refetchInterval: 300000,
      // toast: show on every refetch
      // onSuccess: ({ count }) => {
      //   setHasNewAnnouncements(!!count);
      //   if (!!count && !show) showtoast("info", <NewAnnouncementToast />, () => setShow(true));
      // },
    }
  );

  useEffect(() => {
    setShow(false);
  }, [location]);

  // toast: show only on count change
  useEffect(() => {
    if (!!count && !show && !!data) {
      if (data.results.length <= 2) {
        data.results.slice(0, 2).forEach((a) => {
          if (!document.getElementById(`active-announcement-${a}`))
            return api
              .get(`announcements/${a || ""}`)
              .then(
                // (e: Announcement) => customToast((closeToast) => <AnnouncementCard announcement={e} />)
                (e: Announcement) =>
                  showtoast(
                    announcementTypeToColor(e.type),
                    <NewAnnouncementToast announcement={e} setShowModal={setShow} />,
                    undefined,
                    !!e.isSystemAnnouncement ? false : undefined,
                    false,
                    () => null
                  )
              )
              .catch((e) => console.error(e));
        });
      } else {
        showtoast(
          "info",
          <div>
            <div className="flex align-center gap-5" style={{ width: "100%", justifyContent: "space-between" }}>
              <h5 style={{ margin: 0 }}>New announcements!</h5>
            </div>
            <hr style={{ margin: "10px 0", marginTop: "5px" }} />
            {`You have ${data.results.length} new announcements. Click to view!`}
          </div>,
          () => setShow(true),
          false
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [count]);

  // modal: show only on count change
  // useEffect(() => {
  //   setHasNewAnnouncements(!!count);
  //   if (!!count) setShow(true);
  // }, [count]);

  return (
    <>
      <button
        style={{ position: "relative" }}
        className="btn btn-ghost-secondary btn-round"
        onClick={() => {
          refetch();
          setShow((prevState) => !prevState);
        }}
      >
        <LucideIcon name="bell" />
        {!!count && (
          <div
            className="label label-primary"
            style={{ position: "absolute", top: "0px", right: "-3px", borderRadius: "0.25em", fontSize: "9px" }}
          >
            {count}
          </div>
        )}
      </button>

      <Modal
        isOpen={show}
        onClose={() => {
          setShow(false);
        }}
      >
        <AnnouncementsList />
      </Modal>

      {/* TODO: Move toasts component in MainApp after removing conductance/sjs */}
      <ToasterContainerView />
    </>
  );
};

export default Announcements;

export const announcementTypeToColor = (type: AnnouncementType) => {
  switch (type) {
    case "regular":
      return "info";
    case "urgent":
      return "error";
    case "warning":
      return "warning";

    default:
      return "info";
  }
};
