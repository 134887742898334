import styles from "./InventoryItemSelectModal.module.css";
import { Dispatch, ReactNode, SetStateAction, useEffect, useMemo, useState } from "react";
import { hierarchyConstants, inventoriesConstants, InventoryItem, inventoryItemsConstants } from "../api/Inventories";
import { CustomType, customTypeConstants, CustomTypeFilters, CustomTypeSortingOptionsConsts } from "../api/CustomTypes";
import { GenericModalWrapper } from "../common/modals/Modal/GenericModal";
import { Modal } from "../ELN/common/ELNModal/ELNModal";
import { LucideIcon } from "../common/icon/LucideIcon";
import { useEntityDetail } from "../api/BaseEntityApi";
import { LoadingWrapper } from "../common/LoadingWrapper";
import { EntityList } from "../common/lists/EntityList";
import { Link } from "react-router-dom";
import { toUppercase } from "../common/helperfunctions/stringFunctions";
import { SearchInput } from "../common/forms/SearchInput/SearchInput";
import { OrderBySelectDropdown } from "../common/buttons/OrderBySelectDropdown/OrderBySelectDropdown";
import { useDebouncedValue } from "../common/helperfunctions/useDebouncedValue";
import { InventoryItemTreeSelectWidget } from "./InventoriesTree";
import { EntityBreadcrumbs } from "../common/hierarchy/breadcrumbs/EntityBreadcrumbs";
import { InventoryItemList } from "./InventoryItemList";
import { MoreDropdown } from "../common/buttons/MoreDropdown/MoreDropdown";
import { ToggleButtonComponent } from "../ViewerUIElements/ToggleButtonComponent";
import { GenericEntitySelectRenderer } from "../common/forms/common/GenericEntitySelectRenderer";
import { InventoriesVirtualizedSelectForm } from "../common/forms/EntityForms/formsVirtualized/InventoriesVirtualizedSelectForm";
import { useForm } from "react-hook-form";
import { GenericControllerProvider } from "../common/forms/common/GenericControllerProvider";
import { addOrRemoveValue } from "../common/hierarchy/HierarchySelectForm/HierarchySelectForm";

interface InventoryItemSelectModalProps {
  triggerComponent?: ({
    showModal,
    setShowModal,
  }: {
    showModal: boolean;
    setShowModal: Dispatch<SetStateAction<boolean>>;
  }) => ReactNode;
  onSelectCallback?: (items: InventoryItem[] | InventoryItem | null) => void;
  initialValues?: InventoryItem[];
  hierarchyRootTypeIds: CustomType["id"][];
  selectableCustomTypeIds?: CustomType["id"][];
  isMulti?: boolean;
}
export const InventoryItemSelectModal = ({
  initialValues,
  isMulti,
  triggerComponent,
  hierarchyRootTypeIds,
  selectableCustomTypeIds,
  ...props
}: InventoryItemSelectModalProps) => {
  //   const defaultHierarchyRootTypeId = hierarchyRootTypeIds.length === 1 ? hierarchyRootTypeIds[0] : undefined;
  const defaultHierarchyRootTypeId = undefined;

  const [currentInventoryTypeId, setCurrentInventoryTypeId] = useState<CustomType["id"] | undefined>(
    defaultHierarchyRootTypeId
  );

  const { control } = useForm<{ fieldValues: InventoryItem | InventoryItem[] | null }>({
    values: initialValues ? { fieldValues: initialValues } : { fieldValues: isMulti ? [] : null },
  });

  const {
    data: customType,
    status,
    fetchStatus,
    error,
  } = useEntityDetail<CustomType>(customTypeConstants.resource, currentInventoryTypeId ?? 0, undefined, {
    enabled: !!currentInventoryTypeId,
  });

  return (
    <GenericControllerProvider
      id={"fieldValues"}
      control={control}
      defaultValue={initialValues}
      shouldUnregister={false}
    >
      {({ value, onChange }) => (
        <GenericModalWrapper>
          {({ showModal, setShowModal }) => (
            <>
              {triggerComponent?.({ showModal, setShowModal }) ?? (
                <button className="btn btn-default" onClick={() => setShowModal(true)}>
                  Select {inventoryItemsConstants.entityPlural}...
                </button>
              )}
              <Modal
                isOpen={showModal}
                onClose={() => {
                  setShowModal(false);
                  setCurrentInventoryTypeId(defaultHierarchyRootTypeId);
                }}
              >
                <Modal.Body clsNames={styles.InventoryItemSelectModalBody}>
                  {/* Select widget */}
                  <div style={{ width: "100%", height: "100%", minHeight: "200px" }}>
                    {!!currentInventoryTypeId ? (
                      <>
                        <LoadingWrapper status={status} fetchStatus={fetchStatus} error={error}>
                          {!!customType && (
                            <div style={{ height: "100%", display: "grid", gridTemplateRows: "max-content 1fr" }}>
                              <div
                                className="flex align-center gap-5"
                                style={{
                                  padding: "10px 20px",
                                  borderBottom: "1px solid var(--gray-200)",
                                  flexShrink: 0,
                                }}
                              >
                                {!!customType && (
                                  <EntityBreadcrumbs
                                    entity={{ ...customType, name: customType.inventoryName ?? "" }}
                                    breadcrumbs={[]}
                                    entityConstants={inventoriesConstants}
                                    indexEntityFullRouteOverride={
                                      <Link
                                        to="#"
                                        style={{ textDecoration: "none" }}
                                        onClick={() => setCurrentInventoryTypeId(defaultHierarchyRootTypeId)}
                                      >
                                        <div
                                          className="flex row-nowrap align-center gap-5"
                                          style={{
                                            fontSize: "24px",
                                            fontWeight: 600,
                                            lineHeight: "24px",
                                            whiteSpace: "nowrap",
                                          }}
                                        >
                                          <LucideIcon
                                            name={inventoriesConstants.icon}
                                            color={"var(--primary)"}
                                            style={{ width: 20, height: 20 }}
                                          />
                                          {toUppercase(inventoriesConstants.entityPlural)}
                                        </div>
                                      </Link>
                                    }
                                  />
                                )}
                              </div>
                              <div style={{ width: "100%", overflow: "auto" }}>
                                {!!customType.isHierarchyRoot ? (
                                  <InventoryItemTreeSelectWidget
                                    hierarchyTypeId={currentInventoryTypeId}
                                    hideAddButtons
                                    onSelectItem={(item) => {
                                      if (isMulti) onChange(addOrRemoveValue(item, value));
                                      else onChange(item);
                                    }}
                                    selectedItems={value}
                                    selectableCustomTypeIds={selectableCustomTypeIds}
                                    hideHeader
                                    breadCrumbsIndexEntityFullRouteOverride={
                                      <Link
                                        to="#"
                                        style={{ textDecoration: "none" }}
                                        onClick={() => setCurrentInventoryTypeId(defaultHierarchyRootTypeId)}
                                      >
                                        <div
                                          className="flex row-nowrap align-center gap-5"
                                          style={{
                                            fontSize: "24px",
                                            fontWeight: 600,
                                            lineHeight: "24px",
                                            whiteSpace: "nowrap",
                                          }}
                                        >
                                          <LucideIcon
                                            name={inventoriesConstants.icon}
                                            color={"var(--primary)"}
                                            style={{ width: 20, height: 20 }}
                                          />
                                          {toUppercase(inventoriesConstants.entityPlural)}
                                        </div>
                                      </Link>
                                    }
                                  />
                                ) : (
                                  <InventoryItemList
                                    selectedItems={value}
                                    onSelectItem={(item) => {
                                      if (isMulti) onChange(addOrRemoveValue(item, value));
                                      else onChange(item);
                                    }}
                                    filters={{ customTypeIds: [currentInventoryTypeId] }}
                                  />
                                )}
                              </div>
                            </div>
                          )}
                        </LoadingWrapper>
                      </>
                    ) : (
                      <InventoryItemSelectModalInventorySelectContainer
                        hierarchyRootTypeIds={hierarchyRootTypeIds}
                        currentInventoryTypeId={currentInventoryTypeId}
                        setCurrentInventoryTypeId={setCurrentInventoryTypeId}
                        defaultHierarchyRootTypeId={defaultHierarchyRootTypeId}
                      />
                    )}
                  </div>

                  {/* Selected items overview */}
                  <div
                    className="flex row-nowrap align-center gap-5"
                    style={{ padding: 10, maxHeight: 100, overflow: "hidden" }}
                  >
                    <InventoriesVirtualizedSelectForm
                      id={"fieldValues"}
                      control={control}
                      filters={{ customTypeIds: selectableCustomTypeIds }}
                      isMulti={isMulti}
                      horizontal
                      showControls
                      // disabled
                      hasLabel={false}
                      uncontained
                      placeholder="Select inventory"
                      disabled={false}
                      showBrowseButton={false}
                      children={<></>}
                    />
                    <button
                      className="btn btn-default"
                      onClick={() => {
                        setCurrentInventoryTypeId(defaultHierarchyRootTypeId);
                        setShowModal(false);
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      className="btn btn-primary"
                      onClick={() => {
                        props.onSelectCallback?.(value as any);
                        setCurrentInventoryTypeId(defaultHierarchyRootTypeId);
                        setShowModal(false);
                      }}
                    >
                      Select
                    </button>
                  </div>
                </Modal.Body>
              </Modal>
            </>
          )}
        </GenericModalWrapper>
      )}
    </GenericControllerProvider>
  );
};

const InventoryItemSelectModalInventorySelectContainer = ({
  hierarchyRootTypeIds,
  currentInventoryTypeId,
  setCurrentInventoryTypeId,
  defaultHierarchyRootTypeId,
}: {
  hierarchyRootTypeIds: CustomType["id"][];
  currentInventoryTypeId: CustomType["id"] | undefined;
  setCurrentInventoryTypeId: Dispatch<SetStateAction<CustomType["id"] | undefined>>;
  defaultHierarchyRootTypeId: CustomType["id"] | undefined;
}) => {
  const [searchValue, setSearchValue] = useState<string>("");
  const debouncedSearchValue = useDebouncedValue(searchValue, 300);
  const [filters, setFilters] = useState<CustomTypeFilters>({ orderBy: "NAME_ASC" });
  const activeFilters: CustomTypeFilters = useMemo(
    () => ({
      searchTerm: debouncedSearchValue,
      ...filters,
      ...{
        ...(!!hierarchyRootTypeIds.length && { ids: hierarchyRootTypeIds }),
        excludeHierarchyChildren: true,
        entityTypes: ["Inventory"],
      },
    }),
    [debouncedSearchValue, filters, hierarchyRootTypeIds]
  );
  const [inventoryCount, setInventoryCount] = useState<number>(0);
  useEffect(() => {
    if (hierarchyRootTypeIds.length === 1) {
      setCurrentInventoryTypeId(hierarchyRootTypeIds[0]);
    }
  }, [hierarchyRootTypeIds, setCurrentInventoryTypeId]);

  return (
    <div className={styles.InventoryItemSelectModalInventorySelectContainer}>
      <Link
        className="flex align-center gap-5"
        to="#"
        style={{
          textDecoration: "none",
          padding: "5px 10px",
          paddingBottom: "10px",
          borderBottom: "1px solid var(--gray-200)",
        }}
        onClick={() => setCurrentInventoryTypeId(defaultHierarchyRootTypeId)}
      >
        <div
          className="flex row-nowrap align-center gap-5"
          style={{
            fontSize: "24px",
            fontWeight: 600,
            lineHeight: "24px",
            whiteSpace: "nowrap",
          }}
        >
          <LucideIcon name={inventoriesConstants.icon} color={"var(--primary)"} style={{ width: 20, height: 20 }} />
          {toUppercase(inventoriesConstants.entityPlural)}
        </div>
        <div className="badge">{inventoryCount}</div>
      </Link>
      <div className="flex align-center gap-5" style={{ padding: "", paddingRight: 0 }}>
        <SearchInput searchValue={searchValue} setSearchValue={setSearchValue} placeholder="Search inventories..." />
        <OrderBySelectDropdown
          btnCls="btn btn-default"
          orderByOptions={CustomTypeSortingOptionsConsts.filter(
            (o) => o !== "LAYOUT_ASC" && o !== "LAYOUT_DESC" && o !== "NAME_DESC" && o !== "NAME_ASC"
          )}
          currentFilters={filters}
          setCurrentFilters={setFilters}
          drop="right"
        />
        <MoreDropdown icon={<LucideIcon name="filter" />} btn="btn btn-default" drop="right" closeOnClickInside={false}>
          <label className="flex align-center gap-5 btn btn-ghost-secondary btn-sm">
            <ToggleButtonComponent
              checked={!!filters.includeSoftDeleted}
              setChecked={() =>
                setFilters((prevState) => ({ ...prevState, includeSoftDeleted: !prevState.includeSoftDeleted }))
              }
            >
              <></>
            </ToggleButtonComponent>
            Include trashed
          </label>
        </MoreDropdown>
      </div>
      <div style={{ height: "100%", padding: "0 10px", overflow: "auto" }}>
        <EntityList<CustomType, CustomTypeFilters>
          entityConstant={customTypeConstants}
          filters={activeFilters}
          countCallback={setInventoryCount}
          noItemMessage="There are no inventories in this context..."
          rowHeight={30}
          rowRenderer={(e) => (
            <GenericEntitySelectRenderer
              row={e}
              entityConstants={customTypeConstants}
              onRowEndRenderer={(row) => (
                <label className="label label-soft-info" style={{ margin: 0 }}>
                  {row.name}
                </label>
              )}
              onRowStartRenderer={(row) => <>{row.inventoryName}</>}
              onRowNameRendererOverride={(row) => <></>}
              onClick={(e, row) => setCurrentInventoryTypeId(row.id)}
              iconOverride={(row) =>
                row.isHierarchyRoot ? hierarchyConstants.hierarchyLayoutIcon : hierarchyConstants.flatLayoutIcon
              }
              hideId
              selected={currentInventoryTypeId === e.id}
            />
          )}
        />
      </div>
    </div>
  );
};
