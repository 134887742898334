import React from "react";
import { Dataset } from "../../../api/Datasets";
import { SharedContentCreateModal } from "../../../SharedBundles/Edit/SharedContentCreateModal";
import { LucideIcon } from "../../icon/LucideIcon";
import { sharedContentConstants } from "../../../api/SharedContent";
import { GenericModalWrapper } from "../../modals/Modal/GenericModal";

interface SharedContentModalProps {
  btnCls?: string;
  disabled?: boolean;
  datasetIds: Dataset["id"][];
}
export const SharedContentModal = ({
  btnCls = "btn btn-sm btn-ghost-info",
  disabled,
  datasetIds,
}: SharedContentModalProps) => {
  return (
    <GenericModalWrapper>
      {({ showModal, setShowModal }) => (
        <>
          <button
            className={btnCls}
            disabled={disabled}
            onClick={() => setShowModal(true)}
            title={`Create ${sharedContentConstants.entitySingular}`}
          >
            <LucideIcon name={sharedContentConstants.icon} /> Share datasets
          </button>
          <SharedContentCreateModal showModal={showModal} setShowModal={setShowModal} datasetIds={datasetIds} />
        </>
      )}
    </GenericModalWrapper>
  );
};
