import { useState } from "react";
import { LucideIcon } from "../icon/LucideIcon";
import { InputFormField, InputFormFieldProps } from "./InputFormField";
import styles from "./formfields.module.css";

export interface PasswordFormFieldProps extends Omit<InputFormFieldProps, "type"> {
  hideShowPasswordButton?: boolean;
  disableShowPasswordButton?: boolean;
  showPassword?: boolean;
}

export const PasswordFormField = ({
  hideShowPasswordButton = false,
  disableShowPasswordButton = false,
  showPassword = false,
  ...props
}: PasswordFormFieldProps) => {
  const [_showPassword, setShowPassword] = useState(showPassword);

  return (
    <div className={styles.password}>
      <InputFormField
        {...props}
        type={_showPassword ? "text" : "password"}
        wrapperContainerstyle={{ position: "relative" }}
        placeholder={props.placeholder ?? "Enter password..."}
        style={{ ...props.style, paddingRight: "30px" }}
      >
        {!hideShowPasswordButton && (
          <div className={`${styles.password_icon} ${disableShowPasswordButton ? styles.password_icon_disabled : ""}`}>
            <LucideIcon
              name={_showPassword ? "eye-off" : "eye"}
              onClick={() => {
                if (!disableShowPasswordButton) {
                  setShowPassword((prev) => !prev);
                }
              }}
            />
          </div>
        )}
      </InputFormField>
    </div>
  );
};
