import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { getTilesByDomainAsync } from "../SpectrumViewer/TrackDataManager";
import { TrackData, viewerPropsType } from "../ViewerLayout/ViewerLayoutTypes";
import styles from "./ImageViewer.module.css";

export const ImageViewerMulti = ({
  viewerProps,
  setCurrentIndex,
  imageIds,
  setShowAll,
}: {
  viewerProps: viewerPropsType;
  setCurrentIndex: Dispatch<SetStateAction<number>>;
  imageIds: string[];
  setShowAll: Dispatch<SetStateAction<boolean>>;
}) => {
  const { tracks, datatracks, api, changeTrackState } = viewerProps;

  const [images, setImages] = useState<TrackData[]>([]);
  useEffect(() => {
    const timeout = setTimeout(() => {
      const visibleTracks = Object.values(tracks).filter((t) => t.type === "image" && t.settings.visible);

      if (visibleTracks.length > 0) {
        const domain = {
          range: [
            [-Infinity, Infinity],
            [-Infinity, Infinity],
          ],
          points: [400, 400],
        };

        const getImages = (images: TrackData[]) => {
          if (images.length > 0) {
            setImages(images);
          }
        };

        getTilesByDomainAsync(
          api,
          Object.values(tracks).filter((t) => t.type === "image" && t.settings.visible),
          datatracks,
          domain,
          getImages,
          changeTrackState ?? (() => null)
        );
      } else {
        setImages([]);
      }
    }, 100);

    return () => {
      clearTimeout(timeout);
    };
  }, [api, changeTrackState, datatracks, tracks]);

  return (
    <div className={styles.ImageComponentMultiContainer}>
      <div className={styles.ImageComponentMultiImagesContainer}>
        {images.map((i) => (
          <div
            key={i.id}
            data-toggle="tooltip"
            title={"Click to select '" + i.id.split("_")[3] + "'"}
            className={styles.ImageComponentMultiImageCell}
            onClick={() => {
              setCurrentIndex(imageIds.findIndex((id) => id === i.id));
              setShowAll(false);
            }}
          >
            <img className={styles.ImageComponentMultiImage} src={i.data.image[0].data} alt={i.id} />
          </div>
        ))}
      </div>
    </div>
  );
};
