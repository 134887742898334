import { Dispatch } from "react";

import { API } from "../api/Api";
import { DatasetPipeline } from "../api/ApiTypes";
import { GenerateTrackColor } from "../SpectrumViewer/GenerateTrackColor";
import { LinearGenerator } from "../SpectrumViewer/GraphViewerTypes";
import { generateUid } from "../tools/UID/UID";
import {
  Color,
  DatasetInfo,
  datasetType,
  Datatrack,
  DatatrackNumericArray,
  DatatrackNumericMatrix,
  HierarchyNode,
  JobInfo,
  newHierarchyNode,
  ParameterList,
  ParsingStates,
  Track,
  TrackMatrix,
  trackTypes,
  viewerLogType,
} from "./ViewerLayoutTypes";
import {
  addUnusedTracksToHierarchy,
  apiTrackToTrack,
  datasetIdPrefix,
  getDatatracksIdMapping,
  idArrayToId,
  initAnnotation,
  initDataset,
  initParameter,
  initParameterList,
  initViewerMode,
  setDatatracksLevel,
  updateParameterIds,
  updateTracksHierarchyIds,
  updateTracksHierarchyLeafNames,
  updateTracksIds,
} from "./ViewerLayoutUtils";
import { ActionType } from "./ViewerStateReducer";

type cacheType = {
  tracks: Track[];
  datatracks: Datatrack[];
  tracksHierarchy?: HierarchyNode;
  mapping: Record<string, string[]>;
  idArrayPrefix: string[];
  // reverseMapping: Record<string, string>;
};

// const datasetTrackMapping: Record<string, Record<string, string[]>> = {};
const tracksCache: Record<string, cacheType> = {};
const colorGenerator = new GenerateTrackColor();
const colorCache: Record<string, Color> = {};

// const fetchViewerSettings = async (api: API, dataset: datasetType, dispatch: Dispatch<ActionType>) => {
//   const data = await api.Viewer.getViewerSettings(dataset.logsID);
//   dispatch({ type: "setViewerSettings", viewerSettings: data });
// };

// const fetchPipelines = async (api: API, dataset: datasetType, dispatch: Dispatch<ActionType>) => {
//   const data = await api.Datasets.getDatasetPipelines(dataset.logsID);

//   // console.log("pipelines", data);
//   dispatch({ type: "storePipelineParmeter", pipelineParamter: data });
//   // const pipelines = data.map((pipeline) => new Pipeline(pipeline));
//   // dispatch({ type: "setPipelines", pipelines: pipelines });
// };

// export const savePipelines = async (api: API, dataset: datasetType, pipelines: SerializedPipeline[]) => {
export const savePipelines = async (api: API, dataset: datasetType, pipelines: DatasetPipeline[]) => {
  // console.log(
  //   "save pipelines",
  //   pipelines.map((pipeline) => `${pipeline.name}(${pipeline.id})`)
  // );
  // console.log(
  //   "save pipelines",
  //   pipelines.map((pipeline) => pipeline)
  // );
  // const data = await api.Datasets.saveDatasetPipelines(dataset.logsID, pipelines);
  // const pipelines = data.map((pipeline) => new Pipeline(pipeline));
  // dispatch({ type: "setPipelines", pipelines: pipelines });
};

export const convertTracksAndGetMapping = (
  tracks: Track[],
  dataset: { id: string }
): {
  tracks: Track[];
  mapping: Record<string, string[]>;
  idArrayPrefix: string[];
  reverseMapping: Record<string, string>;
} => {
  const idPrefix = datasetIdPrefix(dataset);
  const trackMapping: Record<string, string[]> = {};

  const newTracks: Track[] = [];
  for (let i = 0; i < tracks.length; i++) {
    // for (let i = 0; i < 1; i++) {
    const t = tracks[i];

    // if (t.type === "image") {
    //   console.log("+++ image data is not supported yet +++");
    //   continue;
    // }
    // console.log("track", t);
    // const { tracks: trackList } = apiTrackToTrack(t);
    const trackList = apiTrackToTrack(t);

    const id = t.id;
    trackMapping[id] = [];
    trackList.forEach((track) => {
      if (track) {
        // track.settings.zoom.x = track.settings.zoom.x.reverse();
        // track.settings.zoom.y = track.settings.zoom.y.reverse();
        // console.log("track zoom", track.settings.zoom.x, track.settings.zoom.y);
        const idArray = [...idPrefix, ...track.idArray];
        track.id = idArrayToId(idArray);
        track.index += i;
        trackMapping[id].push(track.id);
        if (track.type === "XY_real" || track.type === "matrix_real") {
          if (!(track.id in colorCache))
            colorCache[track.id] = colorGenerator.getNextColor(
              track.settings?.color,
              track.type === "matrix_real" ? "gradient" : "single"
            );
          track.settings.color = colorCache[track.id];
        }
        newTracks.push(track);
      }
    });
  }

  const reverseTrackMapping: Record<string, string> = {};
  Object.entries(trackMapping).forEach(([id, ids]) => {
    ids.forEach((t) => {
      reverseTrackMapping[t] = id;
    });
  });
  // const trackMapping = getTracksIdMapping(newTracks, idArray);

  return {
    tracks: newTracks,
    mapping: trackMapping,
    idArrayPrefix: idPrefix,
    reverseMapping: reverseTrackMapping,
  };
};

const add1DLevelToMatrixTracks = (tracks: Track[], datatracks: Datatrack[]) => {
  for (let track of tracks as TrackMatrix[]) {
    if (track.type !== "matrix_real") continue;
    const matrix = datatracks.filter((t) => t.id === track.data.matrix)?.[0] as DatatrackNumericMatrix;

    // console.log(
    //   "datatracks",
    //   datatracks.map((t) => t.id)
    // );

    const xg = new LinearGenerator(matrix.min[0], matrix.max[0], matrix.size[0]);
    const yg = new LinearGenerator(matrix.min?.[1] ?? 0, matrix.max?.[1] ?? 1, matrix.size?.[1] ?? 1);

    matrix.xGenerator = xg;
    matrix.yGenerator = yg;

    for (let level of matrix.levels ?? []) {
      xg.setCount(level.range[0][1] - level.range[0][0] + 1);
      yg.setCount(level.range[1][1] - level.range[1][0] + 1);
      for (let tile of level.tiles) {
        tile.min = [xg.reverse(tile.range[0][0]), yg.reverse(tile.range[1]?.[0] ?? Infinity)];
        tile.max = [xg.reverse(tile.range[0][1]), yg.reverse(tile.range[1]?.[1] ?? -Infinity)];
      }
    }

    // const xg = new LinearGenerator(matrix.min[0], matrix.max[0], matrix.size[0]);
    const xIdArray = [...matrix.idArray, "GENERIC", "x"];
    const x: DatatrackNumericArray = {
      type: "numeric_array",
      name: matrix.name + "_x",
      min: [matrix.min[0]],
      max: [matrix.max[0]],
      size: [matrix.size[0]],
      byteOffset: -1,
      byteSize: -1,
      count: -1,
      data: new Float32Array(),
      idArray: xIdArray,
      id: idArrayToId(xIdArray),
      levels: (matrix.levels ?? []).map((level) => {
        //   const ry = level.tiles[0].range[1] ?? [[Infinity, Infinity]];
        xg.setCount(level.range[0][1] - level.range[0][0] + 1);
        // console.log("level", level.depth);
        const tiles = [] as Datatrack[];
        for (let i = 0; i < level.tileCount[0]; i++) {
          const tile = level.tiles[i];
          // console.log("x tile", level.depth, i, xg.reverse(tile.range[0][0]), xg.reverse(tile.range[0][1]));
          // console.log("   -->", level.depth, i, tile.min[0], tile.max[0]);
          tiles.push({
            min: [tile.min[0]],
            max: [tile.max[0]],
            size: [tile.size[0]],
          } as Datatrack);
        }
        // console.log("x tile", level.depth, tiles.length, level.tileCount[0]);
        // console.log(
        //   "set x range",

        //   tiles.map((t) => [...t.min, ...t.max])
        // );

        return {
          id: level.id,
          depth: level.depth,
          size: [level.size[0]],
          tileCount: [tiles.length],
          min: [level.min[0]],
          max: [level.max[0]],
          range: [level.range[0]],
          count: level.count,
          tiles: tiles,
        };
      }),
      step: 0,
      numberType: "float",
      codec: "generator",
      range: [[-1, -1]],
      depth: -1,
      index: -1,
    };

    // const yg = new LinearGenerator(matrix.min?.[1] ?? 0, matrix.max?.[1] ?? 1, matrix.size?.[1] ?? 1);
    const yIdArray = [...matrix.idArray, "GENERIC", "y"];
    const y: DatatrackNumericArray = {
      type: "numeric_array",
      name: matrix.name + "_y",
      min: [matrix.min?.[1] ?? 0],
      max: [matrix.max?.[1] ?? 0],
      size: [matrix.size[1] ?? 0],
      byteOffset: -1,
      byteSize: -1,
      count: -1,
      data: new Float32Array(),
      idArray: yIdArray,
      id: idArrayToId(yIdArray),
      levels: (matrix.levels ?? []).map((level) => {
        //   const ry = level.tiles[1].range[1] ?? [[Infinity, Infinity]];
        yg.setCount(level.range[1][1] - level.range[1][0] + 1);

        const tiles = [] as Datatrack[];
        for (let i = 0; i < level.tileCount[1]; i++) {
          const tile = level.tiles[i * level.tileCount[0]];
          tiles.push({
            // min: [yg.reverse(tile.range[1]?.[0] ?? Infinity)],
            // max: [yg.reverse(tile.range[1]?.[1] ?? -Infinity)],
            min: [tile.min[1]],
            max: [tile.max[1]],
            size: [tile.size[1]],
          } as Datatrack);
        }
        return {
          id: level.id,
          depth: level.depth,
          size: [level.size[1]],
          tileCount: [tiles.length],
          min: [level.min[1]],
          max: [level.max[1]],
          range: [level.range[1]],
          count: level.count,
          tiles: tiles,
        };
      }),
      step: 0,
      numberType: "float",
      codec: "generator",
      range: [[-1, -1]],
      depth: -1,
      index: -1,
    };
    // console.log(
    //   "set x range",
    //   (x.levels ?? [])[0].tiles.map((t) => [...t.min, ...t.max])
    // );
    // console.log(
    //   "set y range",
    //   (y.levels ?? [])[0].tiles.map((t) => [...t.min, ...t.max])
    // );

    track.data.x = x.id;
    track.data.y = y.id;

    datatracks.push(x);
    datatracks.push(y);

    // console.log("     x", x.id, y.id);
  }
};

const fetchTracksMapping = async (
  api: API,
  dataset: { name: string; id: string; logsID: number },
  forced: boolean = false
) => {
  // console.log("found", dataset.id in tracksCache);
  if (dataset.id in tracksCache && !forced) return;

  const data = await api.Viewer.getTracks(dataset.logsID);

  if (data.tracks.length > 0 && data.datatracks.length < 1) return;

  const datatracks = data.datatracks as Datatrack[];
  const tracksHierarchy = data.tracksHierarchy ?? newHierarchyNode();

  const tracksData = convertTracksAndGetMapping(data.tracks, dataset);
  const datatrackMapping = getDatatracksIdMapping(datatracks, tracksData.idArrayPrefix);
  setDatatracksLevel(datatracks);
  // setDatatracksGenrators(datatracks);

  updateTracksIds(tracksData.tracks, datatrackMapping);

  const tracksMap = Object.fromEntries(tracksData.tracks.map((track) => [track.id, track]));

  type resultType = { id: string; index: number; name: string; type: trackTypes };
  const oldIDTracks = data.tracks
    .map((track) => {
      if (!tracksData.mapping[track.id]) return undefined;
      const tracks = tracksData.mapping[track.id].map((t) => tracksMap?.[t]);
      if (tracks.length < 1) return undefined;
      return { id: track.id, index: tracks[0].index, name: track.name, type: track.type };
    })
    .filter((track) => track) as resultType[];

  addUnusedTracksToHierarchy(tracksHierarchy, oldIDTracks);

  updateTracksHierarchyIds(tracksHierarchy, tracksData.mapping);
  updateTracksHierarchyLeafNames(tracksHierarchy, tracksMap);

  // console.log("add1DLevelToMatrixTracks");
  add1DLevelToMatrixTracks(data.tracks, datatracks);

  tracksHierarchy.name = dataset.name;

  let trackMapping: Record<string, Datatrack> | undefined = undefined;
  for (let track of tracksData.tracks) {
    if (track.type !== "XY_real") continue;
    if (trackMapping === undefined) trackMapping = Object.fromEntries(datatracks.map((t) => [t.id, t]));

    if (!track.settings.zoom) track.settings.zoom = { x: [], y: [], z: [] };
    else {
      if (!track.settings.zoom?.x) track.settings.zoom.x = [];
      if (!track.settings.zoom?.y) track.settings.zoom.y = [];
      if (!track.settings.zoom?.z) track.settings.zoom.z = [];
    }

    if (
      track.settings.zoom.x.length < 2 ||
      Math.abs(track.settings.zoom.x[0] - track.settings.zoom.x[1]) < Number.EPSILON
    ) {
      const x = trackMapping?.[track.data.x as string];
      if (x) track.settings.zoom.x = [x.min[0], x.max[0]];
    }
    if (
      track.settings.zoom.y.length < 2 ||
      Math.abs(track.settings.zoom.y[0] - track.settings.zoom.y[1]) < Number.EPSILON
    ) {
      const y = trackMapping?.[track.data.y as string];
      if (y) track.settings.zoom.y = [y.min[0], y.max[0]];
    }
  }

  tracksCache[dataset.id] = {
    mapping: tracksData.mapping,
    tracks: tracksData.tracks,
    datatracks: datatracks,
    tracksHierarchy: tracksHierarchy,
    idArrayPrefix: tracksData.idArrayPrefix,
  };
};

const fetchTracks = async (
  api: API,
  dataset: { id: string; logsID: number; name: string },
  dispatch: Dispatch<ActionType>
) => {
  const viewerSettings = await api.Viewer.getViewerSettings(dataset.logsID);
  dispatch({
    type: "setViewerMode",
    viewerMode: initViewerMode(viewerSettings),
  });

  await fetchTracksMapping(api, dataset);

  const data = tracksCache[dataset.id];
  if (!data) {
    dispatch({
      type: "addToDatasetLogs",
      id: dataset.id,
      viewerLogs: [
        {
          type: "error",
          message: "Failed to fetch dataset tracks",
          description: ["The server is not responding as expected. Please contact your LOGS administrator."],
        },
      ],
    });
    return;
  }

  dispatch({
    type: "setTracks",
    datasets: [dataset as datasetType],
    tracks: data.tracks,
    datatracks: data.datatracks,
    tracksHierarchy: data.tracksHierarchy,
  });
};

const fetchAnnotations = async (
  api: API,
  dataset: { name: string; id: string; logsID: number },
  dispatch: Dispatch<ActionType>
) => {
  let annotations = await api.Viewer.getAnnotations(dataset.logsID);
  // dispatch({ type: "setViewerAnnotations", annotations: annotations, id: dataset.id });
  if (!annotations) annotations = [];
  annotations = annotations.map((a) => initAnnotation(a));
  await fetchTracksMapping(api, dataset);
  const trackIdMapping: Record<string, string[]> = tracksCache?.[dataset.id]?.mapping ?? {};

  annotations.forEach((a) => {
    if (!a.id) a.id = generateUid();
    const tracks: string[] = [];
    if (a?.tracks)
      a.tracks.forEach((t) => {
        if (trackIdMapping?.[t]) tracks.push(...trackIdMapping[t]);
        else tracks.push(t);
      });
    else Object.values(trackIdMapping).forEach((t) => tracks.push(...t));
    a.tracks = tracks;
  });

  dispatch({ type: "setViewerAnnotations", annotations: annotations, id: dataset.id });
};

const fetchParameter = async (
  api: API,
  dataset: { name: string; id: string; logsID: number },
  dispatch: Dispatch<ActionType>
) => {
  let parameters = await api.Viewer.getParameters(dataset.logsID);

  parameters = parameters.map((p) => initParameter(p));

  await fetchTracksMapping(api, dataset);
  const trackIdMapping: Record<string, string[]> = tracksCache?.[dataset.id]?.mapping ?? {};

  parameters.forEach((p) => updateParameterIds(p, trackIdMapping));
  const root = initParameterList({ type: "list", content: parameters } as ParameterList);

  if (parameters.length > 0)
    dispatch({
      type: "setViewerStatus",
      viewerStatus: "success",
    });

  dispatch({ type: "setDatasetParameter", parameter: root, id: dataset.id });
  // else dispatch({ type: "setDatasetParameter", parameter: (parameter as parameterType).content, id: dataset.id });
};

async function fetchInternalParameters(api: API, dataset: datasetType, dispatch: Dispatch<ActionType>) {
  const parameter = (await api.Viewer.getInternalParameters(dataset.logsID)) ?? [];

  await fetchTracksMapping(api, dataset);
  const trackIdMapping: Record<string, string[]> = tracksCache?.[dataset.id]?.mapping ?? {};

  parameter.forEach((p) => {
    if (!p.id) p.id = generateUid();
    const tracks: string[] = [];
    if (p?.tracks)
      p.tracks.forEach((t) => {
        if (trackIdMapping?.[t]) tracks.push(...trackIdMapping[t]);
        else tracks.push(t);
      });
    else Object.values(trackIdMapping).forEach((t) => tracks.push(...t));
    p.tracks = tracks;
  });

  dispatch({ type: "setInternalParameters", internalParameters: parameter, id: dataset.id });
}

const fetchDatasetInfo = async (api: API, id: number): Promise<DatasetInfo> => {
  try {
    const dataset = await api.Viewer.getDatasetInfo(id);
    // await api.Viewer.generateTiles(id);

    // if (dataset.downsampled) await api.Viewer.generateTiles(id);
    // return dataset.name ?? dataset.name !== "" ? { name: dataset.name } : { name: "" + id };
    dataset.name = dataset.name ?? dataset.name !== "" ? dataset.name : "" + id;

    // console.log("dataset", dataset.parsingState, ParsingStates.NotParseable);
    if (dataset.parsingState === ParsingStates.ParsingFailed && dataset.parserLogs.length < 1) {
      dataset.parserLogs = [
        {
          type: "error",
          message: `Parsing of dataset '${dataset.name}' (id: ${id}) failed.`,
        },
      ];
    } else if (dataset.parsingState === ParsingStates.NotParseable) {
      dataset.parserLogs = [
        {
          type: "info",
          message: `No parser defined for dataset '${dataset.name}' (id: ${id}). Please contact support for further information.`,
        },
      ];
    } else if (dataset.parsingState === ParsingStates.NotYetParsed) {
      dataset.parserLogs = [
        {
          type: "info",
          message: `Dataset '${dataset.name}' (id: ${id}) is under maintenance. Please wait for the page to refresh automatically or visit this dataset at a later time.`,
        },
      ];
    }

    return dataset;
    // return { name: dataset.name ?? dataset.name !== "" ? dataset.name : "" + id, logs: dataset.parserLogs };
  } catch (e: any) {
    const error: viewerLogType = {
      type: "error",
      message: "Failed to fetch dataset",
      description: [e.message],
    };
    return {
      id: id,
      name: "",
      type: "",
      formatVersion: 0,
      acquisitionDate: new Date(),
      parsingState: ParsingStates.NotParseable,
      path: "",
      parserLogs: [error],
      downsampled: false,
    };
    // return { id: id, type: "", name: "", parserLogs: [error] };
  }
};

export const fetchCSVTracks = async (api: API, datasetIds: number[], tracks: number[]) => {
  return await api.Datasets.getTracksCSV(datasetIds[0], tracks);
};

export const checkRunningJobs = async (api: API, jobs: JobInfo[]) => {
  for (let i = 0; i < jobs.length; i++) {
    jobs[i] = await api.Viewer.getJobState(jobs[i].id);
  }
  return jobs;
};

export const reparseDatasets = async (api: API, ids: number[]): Promise<JobInfo[]> => {
  const jobs: JobInfo[] = [];
  for (let id of ids) {
    try {
      jobs.push(await api.Viewer.getReparseJob(id));
    } catch {
      console.log(`ERROR: could not reparse dataset ${id}`);
    }
  }
  return jobs;
};

export const fetchParserOutput = async (api: API, ids: number[]): Promise<string[]> => {
  const outputs: string[] = [];
  for (let id of ids) {
    try {
      outputs.push(await api.Viewer.getParserOutput(id));
    } catch {
      console.log(`ERROR: could fetch dataset ${id} parser output`);
    }
  }
  return outputs;
};

export const fetchParserLog = async (api: API, ids: number[]): Promise<viewerLogType[]> => {
  const outputs: viewerLogType[] = [];
  for (let id of ids) {
    try {
      var l = (await api.Viewer.getDatasetInfo(id)).parserLogs;
      outputs.push(...l);
    } catch {
      console.log(`ERROR: could fetch dataset ${id} parser output`);
    }
  }
  return outputs;
};

function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

const fetchHighResolutionDatatracks = async (
  api: API,
  id: number,
  dispatch: Dispatch<ActionType>,
  isMounted: () => boolean
) => {
  let result: DatasetInfo;
  do {
    try {
      result = await fetchDatasetInfo(api, id);
    } catch {
      return;
    }
    console.log("Waiting for high resolution data...");
    if (!isMounted()) return;
    await sleep(400);
  } while (result.downsampled);

  const dataset = initDataset(id);
  dataset.name = result.name;
  // const datatracks = await api.Viewer.getDatatracks(result.id);
  if (!isMounted()) return;
  console.log("... high resolution data received");
  await fetchTracksMapping(api, dataset, true);
  dispatch({
    type: "setDatatracksLevels",
    datatracks: tracksCache[dataset.id]?.datatracks ?? [],
  });
};

export const fetchDatasets = async (
  api: API,
  ids: number[],
  dispatch: Dispatch<ActionType>,
  index0: number,
  isMounted: () => boolean
) => {
  colorGenerator.reset();
  const datasets: datasetType[] = [];
  let count = 0;
  for (let id of ids) {
    const dataset = initDataset(id);
    if (dataset.id in tracksCache) delete tracksCache[dataset.id];

    const result = await fetchDatasetInfo(api, id);
    if (result.downsampled) {
      await api.Viewer.generateTiles(result.id);
      fetchHighResolutionDatatracks(api, id, dispatch, isMounted);
    }

    dataset.name = result.name;
    if (dataset.index < 0) {
      dataset.index = index0 + count;
      count++;
    }
    dataset.parserLogs = result.parserLogs ?? [];
    dataset.parsingState = result.parsingState;
    datasets.push(dataset);

    // if (result.parserLogs) {
    //   dispatch({
    //     type: "setViewerStatus",
    //     // viewerStatus: "failed",
    //     viewerLogs: result.parserLogs,
    //   });
    // } else {
    //   dataset.name = result.name;
    //   if (dataset.index < 0) {
    //     dataset.index = index0 + count;
    //     count++;
    //   }
    //   datasets.push(dataset);
    // }
  }
  dispatch({ type: "setDatasets", datasets: datasets });

  datasets
    .filter((dataset) => dataset.parsingState === ParsingStates.ParsedSuccessfully)
    .forEach(async (dataset) => {
      await fetchTracksMapping(api, dataset);
      fetchTracks(api, dataset, dispatch);
      fetchAnnotations(api, dataset, dispatch);
      fetchParameter(api, dataset, dispatch);
      fetchInternalParameters(api, dataset, dispatch);
    });

  // datasets.forEach((dataset) => fetchTracks(api, dataset, dispatch));
  // datasets.forEach((dataset) => fetchAnnotations(api, dataset, dispatch));
  // datasets.forEach((dataset) => fetchParameter(api, dataset, dispatch));
  // datasets.forEach((dataset) => fetchInternalParameters(api, dataset, dispatch));
  // // datasets.forEach((dataset) => fetchViewerSettings(api, dataset, dispatch));
  // // datasets.forEach((dataset) => fetchDatatracks(api, dataset, dispatch));
  // // datasets.forEach((dataset) => fetchPipelines(api, dataset, dispatch));
  return count;
};
