import React, { useCallback, useEffect, useRef, useState } from "react";
import { requestFileBytes, requestFileLines } from "../../../../../api/Datasets";
import { DateTimeRenderer } from "../../../../../common/datetime/DateTimeFormatter";
import { LucideIcon } from "../../../../../common/icon/LucideIcon";
import Codeblock from "../../../../../common/textfields/Codeblock/Codeblock";
import { Hexviewer } from "../../../../../common/textfields/Hexviewer/Hexviewer";
import { TreeElement, TreeElementBase } from "../../../../tiles/Files/Files";
import { GenericModal } from "../../../../../common/modals/Modal/GenericModal";
import { ControlBtn } from "../../ControlBtn/ControlBtn";
import { arrayBufferToString, formatBytes, useFilePreview, useSingleFileDownload } from "./FileHelpers";
import styles from "./FilePreview.module.css";

interface FilePreviewProps<T extends TreeElementBase> {
  datasetId: number;
  item: TreeElement<T>;
  defaultShowModal?: boolean;
  onModalCloseCallback?: () => void;
  showButton?: boolean;
}
export const APIFilePreview = <T extends TreeElementBase>({
  datasetId,
  item,
  defaultShowModal = false,
  onModalCloseCallback,
  showButton = true,
}: FilePreviewProps<T>) => {
  // Single file download
  const { triggerDownload, isPreparingDownload } = useSingleFileDownload();
  const downloadBtn = useCallback(
    () => (
      <ControlBtn
        icon={isPreparingDownload ? <LucideIcon name="loader" /> : <LucideIcon name="download" />}
        onClick={() => {
          item.path && triggerDownload(datasetId, item.path);
        }}
        disabled={isPreparingDownload}
        title={"Download file"}
      />
    ),
    [datasetId, isPreparingDownload, item.path, triggerDownload]
  );

  const chunkSize = 128000;
  const current_offset = useRef<number>(0);

  const lines = 100;
  const maxLineLength = 600;
  const current_line = useRef<number>(0);

  const [preview, setPreview] = useState<Uint8Array | string[]>();
  const [showBytes, setShowBytes] = useState<boolean>(false);
  const [filePreviewBody, setFilePreviewBody] = useState<requestFileBytes | requestFileLines>();
  const [showModal, setShowModal] = useState(defaultShowModal);
  const [showHex, setShowHex] = useState(false);

  useEffect(() => {
    setShowModal(defaultShowModal);
  }, [defaultShowModal]);

  useEffect(() => {
    if (!showModal) {
      onModalCloseCallback?.();
      setFilePreviewBody(undefined);
      current_offset.current = 0;
      current_line.current = 0;
      setPreview(undefined);
    } else {
      if (item.path) {
        current_offset.current = 0;
        current_line.current = 0;
        if (showBytes) {
          setFilePreviewBody({ path: item.path, offset: 0 } as requestFileBytes);
        } else {
          setFilePreviewBody({ path: item.path, line: 0, count: lines } as requestFileLines);
        }
      }
    }
  }, [item.path, onModalCloseCallback, showBytes, showModal]);

  const { fileResults, isFetchingFileResults } = useFilePreview({
    id: datasetId,
    body: filePreviewBody,
    returnBytes: showBytes,
    chunkSize: chunkSize,
    maxLineLength: maxLineLength,
  });

  // For the hex viewer we need bytes
  useEffect(() => {
    if (showHex) {
      setShowBytes(true);
    }
  }, [showBytes, showHex]);

  // console.log("fileResults", preview);

  // Update the filePreview
  useEffect(() => {
    if (fileResults && !isFetchingFileResults) {
      if (fileResults instanceof ArrayBuffer) {
        // console.log("SHOWING BYTES", fileResults);
        setPreview((prev) => {
          if (prev && prev instanceof Uint8Array) {
            const results = new Uint8Array(fileResults);
            const merged = new Uint8Array(prev.length + results.length);
            merged.set(prev as Uint8Array);
            merged.set(results, prev.length);
            return merged;
          } else {
            return new Uint8Array(fileResults);
          }
        });
      } else if (fileResults.results && Array.isArray(fileResults.results)) {
        // console.log("SHOWING LINES", fileResults.results);
        setPreview((prev) => {
          if (prev && Array.isArray(prev)) {
            return [...prev, ...fileResults.results] as string[];
          } else {
            return [...fileResults.results] as string[];
          }
        });
      }
    }
  }, [filePreviewBody, fileResults, isFetchingFileResults]);

  const reachedBottomCallback = useCallback(
    (item: TreeElement<T>) => {
      // This callback will fetch the next 128kb when reaching the bottom
      if (showBytes) {
        current_offset.current += chunkSize;
        setFilePreviewBody({ offset: current_offset.current, path: item.path });
      } else {
        current_line.current += lines;
        current_line.current += 1;
        setFilePreviewBody({ line: current_line.current, count: lines, path: item.path });
      }
    },
    [showBytes]
  );

  const previewBtn = useCallback(
    () => (
      <ControlBtn
        icon={<LucideIcon name="code" />}
        onClick={() => {
          setShowModal(true);
        }}
        title={"Preview file"}
      />
    ),
    []
  );

  const filePreviewModal = useCallback(
    () => (
      <GenericModal
        showModal={showModal}
        setShowModal={setShowModal}
        modalTitle={item?.path}
        modalBody={
          <div
            style={{
              display: "flex",
              flexFlow: "column nowrap",
              width: "100%",
              height: "100%",
              overflow: "hidden",
            }}
          >
            <div className={styles.modal_btns}>
              View file as:
              <button
                type="button"
                className={`btn btn-sm ${!showHex ? "btn-primary" : "btn-soft-primary"}`}
                onClick={() => setShowHex(false)}
              >
                ASCII
              </button>
              <button
                type="button"
                className={`btn btn-sm ${showHex ? "btn-primary" : "btn-soft-primary"}`}
                onClick={() => setShowHex(true)}
              >
                Hex
              </button>
            </div>
            <div className={styles.modal_notes} style={{ overflow: "hidden" }}>
              <>
                {preview ? (
                  showHex ? (
                    <Hexviewer
                      buffer={(preview as Uint8Array).buffer}
                      reachedBottomCallback={() => reachedBottomCallback(item)}
                      loading={isFetchingFileResults}
                    />
                  ) : (
                    <Codeblock
                      reachedBottomCallback={() => reachedBottomCallback(item)}
                      loading={isFetchingFileResults}
                      wordWrapStateCallback={(wordWrap) => setShowBytes(wordWrap)}
                      defaultWordWrap={false}
                      showWordWrapToggle
                    >
                      {preview instanceof Uint8Array ? arrayBufferToString(preview) : preview}
                    </Codeblock>
                  )
                ) : (
                  <Codeblock loading>{"Loading preview..."}</Codeblock>
                )}
              </>
            </div>
          </div>
        }
        modalControls={
          <div className={styles.modal_ctrl}>
            <div className={styles.modal_ctrl_download}>
              <button
                className={`btn btn-sm btn-primary ${isPreparingDownload && "disabled"}`}
                onClick={() => {
                  if (item.path) {
                    triggerDownload(datasetId, item.path);
                  }
                }}
                disabled={isPreparingDownload}
                title={"Download file"}
              >
                {isPreparingDownload ? <LucideIcon name="loader" /> : <LucideIcon name="download" />} Download
              </button>
            </div>
            <div className={styles.modal_ctrl_info}>
              <div className={styles.modal_ctrl_info_fsize}>File size: {item.size && formatBytes(item.size)}</div>
              <div className={`${styles.modal_ctrl_info_mtime} flex row-nowrap align-center gap-5`}>
                Last modified:{" "}
                {item.mtime ? (
                  <DateTimeRenderer date={new Date(item.mtime * 1000).toISOString()} includeElapsed={false} />
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        }
      />
    ),
    [
      showModal,
      datasetId,
      isFetchingFileResults,
      isPreparingDownload,
      item,
      preview,
      reachedBottomCallback,
      showHex,
      triggerDownload,
    ]
  );

  return (
    <React.Fragment key={item.id}>
      {downloadBtn()}
      {showButton && previewBtn()}
      {showModal && filePreviewModal()}
    </React.Fragment>
  );
};

// Local filePreview for manual upload
interface LocalFilePreviewProps<T extends TreeElementBase> {
  buffer: Uint8Array | undefined;
  item: TreeElement<T>;
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  showHex: boolean;
  setShowHex: React.Dispatch<React.SetStateAction<boolean>>;
  reachedBottomCallback: (item: TreeElement<T>) => void;
  loading: boolean;
}
export const LocalFilePreview = <T extends TreeElementBase>({
  buffer,
  item,
  showModal,
  setShowModal,
  showHex,
  setShowHex,
  reachedBottomCallback,
  loading,
}: LocalFilePreviewProps<T>) => {
  return (
    <GenericModal
      showModal={showModal}
      setShowModal={setShowModal}
      modalTitle={item?.path}
      modalBody={
        <div
          style={{
            display: "flex",
            flexFlow: "column nowrap",
            width: "100%",
            height: "100%",
            overflow: "hidden",
          }}
        >
          <div className={styles.modal_btns}>
            View file as:
            <button
              type="button"
              className={`btn btn-sm ${!showHex ? "btn-primary" : "btn-soft-primary"}`}
              onClick={() => setShowHex(false)}
            >
              ASCII
            </button>
            <button
              type="button"
              className={`btn btn-sm ${showHex ? "btn-primary" : "btn-soft-primary"}`}
              onClick={() => setShowHex(true)}
            >
              Hex
            </button>
          </div>
          <div className={styles.modal_notes} style={{ overflow: "hidden" }}>
            <>
              {buffer ? (
                showHex ? (
                  <Hexviewer
                    buffer={buffer.buffer}
                    reachedBottomCallback={() => reachedBottomCallback(item)}
                    loading={loading}
                  />
                ) : (
                  <Codeblock
                    reachedBottomCallback={() => reachedBottomCallback(item)}
                    loading={loading}
                    // wordWrapStateCallback={(wordWrap) => setShowBytes(wordWrap)}
                    defaultWordWrap={false}
                  >
                    {arrayBufferToString(buffer)}
                  </Codeblock>
                )
              ) : (
                <Codeblock loading>{"Loading preview..."}</Codeblock>
              )}
            </>
          </div>
        </div>
      }
      modalControls={
        <div className={styles.modal_ctrl}>
          {/* <div className={styles.modal_ctrl_download}>
          <button
            className={`btn btn-sm btn-primary ${isPreparingDownload && "disabled"}`}
            onClick={() => {
              if (item.path) {
                triggerDownload(datasetId, item.path);
              }
            }}
            disabled={isPreparingDownload}
          >
            {isPreparingDownload ? <FeatherIcon name="loader" /> : <FeatherIcon name="download" />} Download
          </button>
        </div> */}
          <div className={styles.modal_ctrl_info}>
            <div className={styles.modal_ctrl_info_fsize}>File size: {item.size && formatBytes(item.size)}</div>
            <div className={`${styles.modal_ctrl_info_mtime} flex row-nowrap align-center gap-5`}>
              <span style={{ whiteSpace: "nowrap" }}>Last modified:</span>{" "}
              {item.mtime ? (
                <DateTimeRenderer date={new Date(item.mtime * 1000).toISOString()} includeElapsed={false} />
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      }
    />
  );
};
