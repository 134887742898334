import { CSSProperties, Dispatch, SetStateAction, useCallback, useEffect, useState } from "react";
import { getTilesByDomainAsync } from "../../SpectrumViewer/TrackDataManager";
import { TrackData, viewerPropsType } from "../../ViewerLayout/ViewerLayoutTypes";
import styles from "../ImageViewer.module.css";
import { LucideIcon } from "../../common/icon/LucideIcon";
import { FixedSizeList } from "react-window";
import { useResizeDetector } from "react-resize-detector";

export const ImageViewerGallery = ({
  viewerProps,
  setCurrentIndex,
  selectedId,
  imageIds,
}: {
  viewerProps: viewerPropsType;
  setCurrentIndex: Dispatch<SetStateAction<number>>;
  selectedId: string;
  imageIds: string[];
}) => {
  const { tracks, datatracks, api, changeTrackState } = viewerProps;

  const [previews, setPreviews] = useState<TrackData[]>([]);
  useEffect(() => {
    const domain = {
      range: [
        [-Infinity, Infinity],
        [-Infinity, Infinity],
      ],
      points: [40, 22.5],
    };

    const getImage = (data: TrackData[]) => {
      const images = data.map((t) => {
        return { data: t };
      });

      setPreviews(images.map((i) => i.data));
    };

    getTilesByDomainAsync(
      api,
      Object.values(tracks).filter((t) => imageIds.includes(t.id)),
      datatracks,
      domain,
      getImage,
      changeTrackState ?? (() => null)
    );
  }, [api, changeTrackState, datatracks, imageIds, tracks]);

  const Item = useCallback(
    ({ index, style }: { index: number; style: CSSProperties }) => {
      const d = previews[index];
      return (
        <div
          key={index}
          style={{
            ...style,
            backgroundColor: d.id === selectedId ? "var(--primary)" : "",
            color: d.id === selectedId ? "var(--white)" : "",
          }}
          onClick={() => {
            setCurrentIndex(imageIds.findIndex((i) => i === d.id));
          }}
          className={styles.ImageComponentGalleryItem}
        >
          <div key={d.id} className={styles.ImageComponentPreviewImageContainer}>
            <img className={styles.ImageComponentPreviewImage} src={d.data.image[0].data} alt="" />
            <div className={styles.ImageComponentPreviewImageInfoContainer}>
              <div className={styles.ImageComponentPreviewImageName}>{tracks[d.id].name}</div>
              <div className={styles.ImageComponentPreviewImageSize}>
                <span className={styles.ImageComponentPreviewImageSizeLabel}>
                  {`${datatracks[(tracks[d.id].data as any).image]?.size[0]}px`},{" "}
                  {`${datatracks[(tracks[d.id].data as any).image]?.size[1]}px`}
                </span>
              </div>
            </div>
          </div>
        </div>
      );
    },
    [datatracks, imageIds, previews, selectedId, setCurrentIndex, tracks]
  );

  const { ref, height } = useResizeDetector();
  return (
    <div className={styles.ImageComponentGalleryContainer}>
      <div className={styles.ImageComponentToolbar}>
        {imageIds.length > 1 && (
          <button
            className="btn btn-primary btn-sm"
            onClick={() => setCurrentIndex((prevState) => (prevState > 0 ? prevState - 1 : imageIds.length - 1))}
          >
            <LucideIcon name="chevron-left" />
          </button>
        )}
        {imageIds.length > 1 && (
          <div className={styles.ImageComponentToolbarGalleryCurrentName}>{tracks[selectedId].name}</div>
        )}
        {imageIds.length > 1 && (
          <button
            className="btn btn-primary btn-sm"
            onClick={() => setCurrentIndex((prevState) => (prevState < imageIds.length - 1 ? prevState + 1 : 0))}
          >
            <LucideIcon name="chevron-right" />
          </button>
        )}
      </div>
      <div className={styles.ImageComponentToolbarGallery} ref={ref}>
        {height && (
          <FixedSizeList
            itemCount={previews.length}
            width="100%"
            height={height}
            itemSize={64}
            style={{ position: "absolute" }}
          >
            {Item}
          </FixedSizeList>
        )}
      </div>
    </div>
  );
};
