import { StackTraces } from "../StackTrace";
import { Empty } from "./Empty";
import { generateUid } from "../../tools/UID/UID";
import { commandTypes, parameterTypes, trackListType, argumentType } from "../PipelineTypes";

export class Test extends Empty {
  id: string;
  name: string;
  readonly type = commandTypes.test;
  errors: StackTraces;
  warnings: StackTraces;

  // readonly input = [{ name: "track", type: "1D_real" }];
  // readonly output = [{ name: "nullified", type: "hurz" },{ name: "deiwel", type: "potz_blitz" }];
  // readonly output = [{ name: "track", type: "1D_real" }];
  // readonly input = [{ name: "nullified", type: "hurz" },{ name: "deiwel", type: "potz_blitz" }];
  parameterTypes = {
    param0: parameterTypes.trackListSelection,
    param1: parameterTypes.float,
    param2: parameterTypes.float,
    param3: parameterTypes.float,
    param4: parameterTypes.int,
    param5: parameterTypes.int,
    param6: parameterTypes.int,
    param7: parameterTypes.string,
    param8: parameterTypes.boolean,
    param9: parameterTypes.stringList,
    param10: parameterTypes.trackSelection,
    param11: parameterTypes.string,
    optional: parameterTypes.parameterSets,
  };

  parameterSettings: Record<string, any> = {
    param0: [
      { regex: "fid.*", trackName: "fid.re", type: "1D_real" },
      { regex: "fid.*", trackName: "fid.im", type: "1D_real" },
    ],
    param1: 6,
    param2: { inputType: "range", min: 3, max: 10, value: 3 },
    param3: { inputType: "xPick" },
    param4: 6,
    param5: { inputType: "range", min: 3, max: 10 },
    param6: { inputType: "xPick" },
    param7: {
      value: "Hallo",
      inputType: "options",
      options: ["Hallo", "wie", "geht's"],
      hidden: false,
    },
    param8: true,
    param9: ["Hallo", "du", "Wurm", "!", "Wie", "geht", "es", "dir", "?"],
    param10: { regex: "fid.*", trackName: "fid.re", type: "1D_real" },
    param11: { name: "Readonly", value: "Hallo", readonly: true },
    optional: {
      name: "Window Function",
      value: [
        {
          name: "phasing",
          parameter: {
            phase0: {
              parameterType: "float",
              inputType: "range",
              min: -3.14159265358979323,
              max: 3.14159265358979323,
              value: 0,
            },
            phase1: {
              parameterType: "float",
              inputType: "range",
              min: -3.14159265358979323,
              max: 3.14159265358979323,
              value: 0,
            },
            pivot: { parameterType: "float", inputType: "xPick", value: "Infinity" },
          },
        },
        {
          name: "window",
          parameter: {
            type: {
              parameterType: "string",
              inputType: "options",
              options: ["none", "exp"],
              value: "exp",
            },
            lb: { parameterType: "int", value: 1 },
          },
        },
      ],
    },
  };

  // parameterTypes = {
  //   optional: parameterTypes.parameterSets,
  // };

  // parameterSettings = {
  //   optional: { name: "This one has a name" },
  // };
  internalParameter: Record<string, any>;
  readonly input: argumentType[];
  readonly output: argumentType[];

  constructor() {
    super();
    this.id = generateUid();
    this.name = "Only for testing";
    this.errors = new StackTraces();
    this.warnings = new StackTraces();
    this.internalParameter = {};
    this.input = [];
    this.output = [];

    // this.errors.create({
    //   id: "",
    //   component: "FloatParameter.parseValue",
    //   message: `Test error`,
    // });

    // this.checkParameter(parameter);
    // console.log("parameters", this.parameter);
  }

  // checkParameter(parameter: parameterType) {
  //   const checker = new ParameterChecker(this.argumentTypes);
  //   checker.setParameter(parameter);
  //   this.parameter = checker.parameter;
  //   this.errors.push(...checker.errors);
  //   // console.log(">>", this.parameter.trackFilter);
  //   this.track = this.parameter.track;
  // }

  getParams() {
    return Object.fromEntries(Object.entries(this.parameter).map(([k, v]) => [k, v.value]));
  }

  run(tracks: trackListType): trackListType {
    // return parameter?.trackList.value(tracks) ?? [];

    this.parameter = {
      param1: -Infinity,
      param11: "read only text from run",
      param7: "wie",
    };

    // console.log("test");
    // (this.internalParameter.param1 as FloatParameter).value = 13;
    // (this.internalParameter.param11 as StringParameter).value = "read only text from run";
    // (this.internalParameter.param7 as StringParameter).value = "Test";
    // console.log(this.parameter);

    return tracks;
  }
}
