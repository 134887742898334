import { useEffect } from "react";
import { useAuth } from "react-oidc-context";
import { Redirect, useLocation } from "react-router-dom";

import { OIDCAuthError, OIDCAuthLoading } from "./OidcComponents";

/**
 * This component is shown at the /:group/oidc_redirect route. After logging in with OIDC, the user is redirected here,
 * We then redirect the user to the page they were trying to access before logging in, which we stored in localStorage.
 *
 * Another purpose of this component is to remove all URL params that are added by the OIDC provider. This happens
 * automatically due to the Redirect component rendered here.
 */
export const OidcRedirect = () => {
  const returnUrl = localStorage.getItem("returnUrl");
  const auth = useAuth();

  if (auth.isLoading) {
    return <OIDCAuthLoading />;
  }

  if (auth.error) {
    return <OIDCAuthError errorMessage={auth.error.message} />;
  }

  if (auth.isAuthenticated) {
    return <Redirect to={returnUrl ?? "/"} />;
  }
  return <OIDCAuthError errorMessage="Unknown auth state" />;
};

export const useReturnUrlStorage = () => {
  const location = useLocation();

  // We store the return URL in local storage so we can redirect the user back to the page they were trying to access
  useEffect(() => {
    localStorage.setItem("returnUrl", location.pathname + location.search);
  }, [location]);
};
