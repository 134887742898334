import {
  CustomImportFilters,
  CustomImportSortingOptionsConsts,
  CustomImportSuggestions,
  customImportConstants,
} from "../../../../api/CustomImports";
import { StringIndexedDict } from "../../../../api/GenericTypes";
import {
  GenericEntityVirtualizedEntitySelectFormFieldProps,
  GenericVirtualizedEntitySelectFormFieldControllerWrapper,
} from "../../../formfields/GenericVirtualizedSelectFormField/GenericVirtualizedEntitySelectFormFieldControllerWrapper";
// import { QuickAddWrapper } from "../../QuickAdd/common/QuickAddWrapper";

export const CustomImportsVirtualizedSelectForm = <Entity extends StringIndexedDict>(
  props: GenericEntityVirtualizedEntitySelectFormFieldProps<Entity, CustomImportSuggestions, CustomImportFilters>
) => {
  return (
    <GenericVirtualizedEntitySelectFormFieldControllerWrapper<Entity, CustomImportSuggestions, CustomImportFilters>
      {...props}
      filters={{ orderBy: "ID_ASC", ...props.filters }}
      //   onRowRenderer={(item) => <></>}
      entityConstants={customImportConstants}
      orderByOptions={CustomImportSortingOptionsConsts}
      allowCreateEntity={false}
    />
  );
};
