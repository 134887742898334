import { CustomType, CustomTypeEntityTypeOptions } from "../../api/CustomTypes";
import { EntityAddForm, EntityAddFormPage } from "../../common/entity/EntityAddForm";
import { EntityCloneForm, EntityCloneFormPage } from "../../common/entity/EntityCloneForm";
import { EntityCloneFormProps, EntityDetailViewProps, EntityEditFormProps } from "../../common/entity/EntityInterfaces";
import { EntityEditForm, EntityEditFormPage } from "../../common/entity/EntityEditForm";
import { EntityDetailPage, EntityDetailView } from "../../common/entity/EntityDetailPage";
import { CustomTypesForm } from "./CustomTypeForm";
import { useLocation } from "react-router-dom";
import { CustomTypeDetail } from "./CustomTypeDetail";
import { EntityTablePage } from "../../common/entity/EntityTablePage";
import { CustomTypesTable } from "./Table/CustomTypesTable";

// Add
export const CustomTypeAddForm = ({
  entityType,
  parentTypes,
  rootHierarchy,
}: {
  entityType?: string | null;
  parentTypes?: string | null;
  rootHierarchy?: string | null;
}) => {
  let _entityType: CustomType["entityType"] | undefined;
  let _parentTypes: CustomType["parentTypes"] | undefined;
  let _rootHierarchy: CustomType["rootHierarchy"] | undefined;

  if (entityType && (Array.from(CustomTypeEntityTypeOptions) as string[]).includes(entityType))
    _entityType = entityType as CustomType["entityType"];

  if (parentTypes) {
    try {
      const json = JSON.parse(parentTypes) as CustomType["parentTypes"];
      if (Array.isArray(json) && !!json.length) {
        _parentTypes = json;
      }
    } catch (e) {
      console.error("Failed to parse parent types", e);
    }
  }

  if (rootHierarchy) {
    try {
      _rootHierarchy = JSON.parse(rootHierarchy) as CustomType["rootHierarchy"];
    } catch (e) {
      console.error("Failed to parse root hierarchy type", e);
    }
  }

  return (
    <EntityAddForm
      entityTypeId={"customTypes"}
      style={{
        display: "flex",
        width: "100%",
        height: "100%",
        maxWidth: "100%",
      }}
    >
      {(props) => (
        <CustomTypesForm
          {...props}
          initialValues={{
            ...props.initialValues,
            ...(_entityType && { entityType: _entityType }),
            ...(Array.isArray(_parentTypes) &&
              !!_parentTypes.length &&
              _entityType === "Inventory" && { parentTypes: _parentTypes }),
            ...(_rootHierarchy && { rootHierarchy: _rootHierarchy }),
          }}
          // event={_entityType ? "EDIT" : props.event}
          title={_entityType ? `Add ${_entityType.toLowerCase()} type` : props.title}
        />
      )}
    </EntityAddForm>
  );
};

export const CustomTypeAddFormPage = () => {
  const entityType = new URLSearchParams(useLocation().search).get("entityType");
  const parentTypes = new URLSearchParams(useLocation().search).get("parentTypes");
  const rootHierarchy = new URLSearchParams(useLocation().search).get("rootHierarchy");
  return (
    <EntityAddFormPage style={{ height: "100%" }}>
      <CustomTypeAddForm
        entityType={entityType ?? undefined}
        parentTypes={parentTypes ?? undefined}
        rootHierarchy={rootHierarchy ?? undefined}
      />
    </EntityAddFormPage>
  );
};

// Clone
export const CustomTypeCloneForm = ({ id }: EntityCloneFormProps<"customTypes">) => {
  return (
    <EntityCloneForm
      id={id}
      entityTypeId={"customTypes"}
      style={{
        display: "flex",
        width: "100%",
        height: "100%",
        maxWidth: "100%",
      }}
    >
      {(props) => (
        <CustomTypesForm
          {...props}
          {...(props.initialValues?.entityType && {
            title: `Clone ${props.initialValues.entityType.toLowerCase()} type`,
          })}
        />
      )}
    </EntityCloneForm>
  );
};

export const CustomTypeCloneFormPage = () => {
  return (
    <EntityCloneFormPage<"customTypes"> style={{ height: "100%" }} isIntId>
      {(id) => <CustomTypeCloneForm id={id} />}
    </EntityCloneFormPage>
  );
};

// Edit
export const CustomTypeEditForm = ({ id }: EntityEditFormProps<"customTypes">) => {
  return (
    <EntityEditForm
      id={id}
      entityTypeId={"customTypes"}
      filters={{}}
      style={{
        display: "flex",
        width: "100%",
        height: "100%",
        maxWidth: "100%",
      }}
    >
      {(props) => (
        <CustomTypesForm
          {...props}
          {...(props.initialValues?.entityType && {
            title: `Edit ${props.initialValues.entityType.toLowerCase()} type`,
          })}
        />
      )}
    </EntityEditForm>
  );
};

export const CustomTypeEditFormPage = () => {
  return (
    <EntityEditFormPage<"customTypes"> style={{ height: "100%" }} isIntId>
      {(id) => <CustomTypeEditForm id={id} />}
    </EntityEditFormPage>
  );
};

// Detail
export const CustomTypesDetailView = ({ id }: EntityDetailViewProps<"customTypes">) => {
  return (
    <EntityDetailView id={id} entityTypeId={"customTypes"} filters={{}}>
      {(props) => <CustomTypeDetail {...props} />}
    </EntityDetailView>
  );
};

export const CustomTypeDetailsPage = () => {
  return <EntityDetailPage<"customTypes"> isIntId>{(id) => <CustomTypesDetailView id={id} />}</EntityDetailPage>;
};

export const CustomTypesTablePage = () => {
  return <EntityTablePage entityTypeId={"customTypes"}>{(props) => <CustomTypesTable {...props} />}</EntityTablePage>;
};
