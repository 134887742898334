import React, { useCallback } from "react";
import { IGenericEntity, IdTypes } from "../../../../api/GenericTypes";
import { Modal } from "../../../../ELN/common/ELNModal/ELNModal";

export interface QuickEditChildrenProps<T extends IGenericEntity<IdTypes>> {
  onEdit: (editedEntity: T) => void;
  onCancel: () => void;
  initialValues?: T;
}

interface QuickEditWrapperProps<T extends IGenericEntity<IdTypes>> {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  onEdit: (editedEntity: T) => void;
  onCancel?: () => void;
  children: (props: QuickEditChildrenProps<T>) => React.ReactNode;
}
export const QuickEditWrapper = <T extends IGenericEntity<IdTypes>>({
  showModal,
  setShowModal,
  onEdit,
  onCancel,
  children,
}: QuickEditWrapperProps<T>) => {
  const onEditEntity = useCallback(
    (editedEntity: T) => {
      onEdit(editedEntity);
      setShowModal(false);
    },
    [onEdit, setShowModal]
  );
  const _onCancel = useCallback(() => {
    onCancel?.();
    setShowModal(false);
  }, [onCancel, setShowModal]);

  return (
    <Modal isOpen={showModal} onClose={setShowModal}>
      <Modal.Body>
        <div
          className="flex col-nowrap form-group center-horizontally"
          style={{
            width: "70vw",
            height: "100%",
            justifyContent: "flex-start",
            padding: "10px 15px",
          }}
        >
          {children({ onEdit: (entity) => onEditEntity(entity), onCancel: _onCancel })}
        </div>
      </Modal.Body>
    </Modal>
  );
};
