import React, { CSSProperties } from "react";
import { GenericVirtualizedTable } from "../GenericVirtualizedTable/GenericVirtualizedTable";
import { GenericVirtualizedTableProps } from "../GenericVirtualizedTable/GenericVirtualizedTableTypes";
import styles from "./EntityTable.module.css";
import { GenericEntity } from "../../../api/GenericTypes";

// Some style wrappers
interface Props {
  style?: CSSProperties;
  children: React.ReactNode;
}
export const EntityTable = ({ style, children }: Props) => {
  return (
    <div className={styles.container} style={style}>
      {children}
    </div>
  );
};

const EntityTableControls = ({ style, children }: Props) => {
  return (
    <div className={styles.controls} style={style}>
      {children}
    </div>
  );
};
EntityTable.Controls = EntityTableControls;

export const EntityTableBody = <Entity extends GenericEntity, Filters extends { [property: string]: any }>(
  props: GenericVirtualizedTableProps<Entity, Filters>
) => {
  return (
    <div className={styles.body}>
      <GenericVirtualizedTable {...props} tableHeadCls={styles.entityTableHead} />
    </div>
  );
};
EntityTable.Body = EntityTableBody;
