import { ObjectShape } from "yup";
import { CustomType, customTypeConstants } from "../../../api/CustomTypes";
import * as yup from "yup";
import {
  convertValueToNativeCustomFieldDataType,
  convertToYupShape,
  maxCustomFieldTextAreaChars,
} from "../../CustomFields/CustomFieldUtils";
import { GenericEntity, ICustomTypedEntity, Nullable, StringIndexedDict } from "../../../api/GenericTypes";
import { CustomField } from "../../../api/CustomFields";
import { useContext, useMemo } from "react";
import { MultiEditMappedItemObject } from "../../../common/forms/MultiEditForms/common/MultiEditProvider";
import {
  ConsolidatedValues,
  multiEditInitialValues,
  getValueOrDefault,
} from "../../../common/forms/MultiEditForms/common/MultiEditUtils";
import { SessionContext } from "../../../common/contexts/SessionContext";
import { yupResolver } from "@hookform/resolvers/yup";

const generateCustomFieldsObjectShape = (customSchema: CustomType) => {
  let newSchema: ObjectShape = {};
  const fields = customSchema.sections
    .map((d) => d.customFields)
    .map((c) => c)
    .flat(1);

  for (const field of fields) {
    newSchema[field.id] = yup.mixed().when("_", (_, schema) => {
      if (field.showAsTextArea)
        return yup
          .string()
          .max(maxCustomFieldTextAreaChars)
          .label(field.name ?? "")
          .nullable()
          .transform((_, val) => {
            return val ? val : null;
          })
          .typeError((v) => `${v.label || v.path}: Value must be a string`);

      if (field.validationRegexp) {
        return yup.string().matches(new RegExp(field.validationRegexp), {
          message:
            field.validationMessage || `${field.name} does not match the required pattern ${field.validationRegexp}`,
          excludeEmptyString: false,
          name: field.name,
        });
      }

      if (field.required) {
        return convertToYupShape(field.dataType, field.name)
          .nonNullable()
          .required(field.validationMessage || `${field.name} is required`);
      }

      return convertToYupShape(field.dataType, field.name).nullable();
    });
  }
  return newSchema;
};

export const processCustomTypeYupSchema = (
  customType: CustomType | undefined,
  validationSchema: ObjectShape
): ObjectShape => {
  if (customType === undefined || customType === null)
    return {
      ...validationSchema,
      customFields: yup.mixed().nullable().notRequired(),
    };
  validationSchema["customFields"] = yup.object().shape(generateCustomFieldsObjectShape(customType));
  return validationSchema;
};

export const processField2Form = <
  T extends Partial<Nullable<ICustomTypedEntity>>,
  E extends Required<Omit<ICustomTypedEntity, "customType">>
>(
  entity: T,
  processedCustomFieldsEntity: E,
  field: CustomField
) => {
  if (!entity.customFields) entity.customFields = {};
  if (field.id) {
    processedCustomFieldsEntity.customFields[field.id] =
      (entity.customFields?.[field.id] === undefined || entity.customFields?.[field.id] === null) &&
      field.defaultValues !== null &&
      field.defaultValues !== undefined
        ? field.defaultValues
        : entity.customFields?.[field.id] === undefined || entity.customFields?.[field.id] === null
        ? null
        : entity.customFields?.[field.id];
  }
  // console.log("processedCustomFieldsEntity", processedCustomFieldsEntity);
  return processedCustomFieldsEntity;
};

const processForm2Field = <
  T extends Partial<Nullable<ICustomTypedEntity>>,
  E extends Required<Omit<ICustomTypedEntity, "customType">>
>(
  entity: T,
  processedCustomFieldsEntity: E,
  field: CustomField
) => {
  if (entity.customFields) {
    if (field.id) {
      processedCustomFieldsEntity.customFields[field.id] = convertValueToNativeCustomFieldDataType(
        entity.customFields[field.id],
        field.dataType
      );
    }
  }
  return processedCustomFieldsEntity;
};

export const processValuesForTypedEntity = <T extends Partial<Nullable<ICustomTypedEntity>>>(
  entity: T,
  type: CustomType | undefined | null
) => {
  let processedCustomFieldsEntity: Required<Omit<ICustomTypedEntity, "customType">> = {
    customFields: {},
    customValues: [],
  };
  if (type === undefined || type === null || entity.customType === undefined || entity.customType === null) {
    return { ...entity, customType: null, customFields: null }; // Delete custom fields if no type
  } else {
    const _entity = structuredClone(entity); // Deep clone entity
    type.sections.forEach((section) => {
      section.customFields.forEach((customField) => {
        processedCustomFieldsEntity = processForm2Field(_entity, processedCustomFieldsEntity, customField);
      });
    });
    return { ..._entity, ...processedCustomFieldsEntity };
  }
};

export const processInitialValuesForTypedEntity = <Entity extends Partial<Nullable<ICustomTypedEntity>>>(
  entity: Entity,
  type: CustomType | undefined
) => {
  let processedCustomFieldsEntity: Required<Omit<ICustomTypedEntity, "customType">> = {
    customFields: {},
    customValues: [],
  };
  if (type === undefined) {
    return entity;
  } else {
    type.sections.forEach((section) => {
      section.customFields.forEach((customField) => {
        processedCustomFieldsEntity = processField2Form(entity, processedCustomFieldsEntity, customField);
      });
    });
  }
  return { ...entity, ...processedCustomFieldsEntity };
};

// For Bulk edit
export const useCustomFieldDefaultValues = <Entity extends GenericEntity & ICustomTypedEntity & StringIndexedDict>(
  items: MultiEditMappedItemObject<Entity>,
  consolidatedValues: Required<ConsolidatedValues<Entity>>,
  type: CustomType | undefined
) => {
  const { conditionalTypeFormSchema } = useCustomFieldsValidationObjectShape();

  const consolidatedCustomFieldValues = useMemo(
    () =>
      multiEditInitialValues(
        Object.fromEntries(
          Object.entries(items).map(([id, entity]) => [id, entity.customFields])
        ) as MultiEditMappedItemObject<Entity>
      ),
    [items]
  );

  const customFieldPlaceholders = useMemo(
    () =>
      Object.fromEntries(
        Object.keys(consolidatedCustomFieldValues).map((key) => [
          key,
          getValueOrDefault(consolidatedCustomFieldValues[key]),
        ])
      ),
    [consolidatedCustomFieldValues]
  );

  const customFieldInitialValues = useMemo(
    () =>
      Object.fromEntries(
        Object.entries(consolidatedCustomFieldValues).map(([key, values]) => [key, values.value])
      ) as Entity["customFields"],
    [consolidatedCustomFieldValues]
  );

  const defaultValues = useMemo(
    () =>
      ({
        ...Object.fromEntries(Object.entries(consolidatedValues).map(([key, value]) => [key, value.value ?? null])),
        ...processInitialValuesForTypedEntity({ customFields: customFieldInitialValues }, type),
      } as Entity),
    [consolidatedValues, customFieldInitialValues, type]
  );

  // console.log("DEFAULT VALUES", defaultValues, "VALUES", values);
  // console.log("customFieldPlaceholders", customFieldPlaceholders);

  return { customFieldPlaceholders, defaultValues, conditionalTypeFormSchema };
};

export const useCustomFieldsValidationObjectShape = () => {
  const { api } = useContext(SessionContext);
  const conditionalTypeFormSchema = {
    customFields: yup.mixed().when("customType", ([customType], schema) => {
      if (customType && typeof customType.id === "number") {
        return yup.object().test("customFields", async (value, { createError, path }) => {
          if (typeof customType.id !== "number") return createError({ message: "Custom type not found" });
          if (typeof customType.id == "number" && customType.id < 0) return true; //e.g. unassigned
          const _customType = await api.get(`${customTypeConstants.resource}/${customType.id}`).catch((e) => {
            return createError({
              message: `Error fetching API for ${customType.name}`,
              path: path,
            });
          });
          const objectShape = yup.object().shape(generateCustomFieldsObjectShape(_customType));
          if (!value) return true;
          const resolverResult = await yupResolver(objectShape)(value, undefined, {
            criteriaMode: undefined,
            fields: value,
            shouldUseNativeValidation: undefined,
            names: Object.keys(value) as any,
          });
          const errors = Object.keys(resolverResult.errors);
          const isValid = !errors.length;
          if (isValid) return true;
          const _errors = Object.entries(resolverResult.errors).map(
            ([key, error]) => new yup.ValidationError(error?.message ?? "", (value as any)?.[key], `${path}.${key}`)
          );
          return createError({
            message: () => _errors,
          });
        });
      } else {
        return yup.mixed().nullable().notRequired();
      }
    }),
  };
  return { conditionalTypeFormSchema };
};
