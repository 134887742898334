import { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";

import { LucideIcon } from "../../common/icon/LucideIcon";
import { settingType, TrackSettings } from "../../SpectrumViewer/ViewerNavigation/TrackSettings";
import { useTranslatedFieldUpdate } from "../../ViewerLayout/ViewerLayoutHooks";
import { HierarchyNode } from "../../ViewerLayout/ViewerLayoutTypes";
import { trackState } from "../../ViewerLayout/ViewerTypes";
import { EllipsisTextBlock } from "../../ViewerUIElements/TextBlock";
import { Tools, ToolsType } from "../../ViewerUIElements/Tools";
import { TreeElement } from "./TreeElement";
import styles from "./TreeViewer.module.css";
import { TreeNodeProperties, TreeViewerProps } from "./TreeViewerTypes";

// const canvas = document.createElement("canvas");
// const ctx = canvas.getContext("2d");
// if (ctx) ctx.font = "bold 14pt arial";

// const getTextWidth = function (text: string, font?: string) {
//   if (font && ctx) ctx.font = font;
//   return ctx?.measureText(text)?.width || 0;
// };

// const TextSize = ({
//   text,
//   trigger,
//   fontFamily,
//   fontSize,
//   fontWeight,
// }: {
//   text: string;
//   trigger?: any;
//   fontFamily: string;
//   fontSize: string;
//   fontWeight: string;
// }) => {
//   // console.log(">>", props.children.geti)
//   const targetRef = useRef<HTMLDivElement>(null);
//   const [textWidth, setTextWidth] = useState<number>(0);
//   const [overflow, setOverflow] = useState<boolean>(false);

//   useEffect(() => {
//     const font = fontWeight + " " + fontSize + " " + fontFamily;
//     // setTextWidth(getTextWidth(text));
//     // console.log("element", text, getTextWidth(text));
//   }, [text]);

//   useLayoutEffect(() => {
//     // console.log("test", targetRef.current);
//     if (targetRef.current) {
//       const element = targetRef.current as Element;
//       const bound = element.getBoundingClientRect();
//       const tmp: string = element.textContent || "";

//       setOverflow(textWidth > bound.width);
//       console.log("element", tmp, textWidth > bound.width);
//     }
//   }, [trigger, textWidth]);

//   return (
//     <div
//       style={{
//         display: "flex",
//         flexFlow: "row nowrap",
//         justifyContent: "flex-start",
//         // alignItems: "left",
//       }}
//     >
//       <span
//         ref={targetRef}
//         style={{
//           fontFamily: fontFamily,
//           fontSize: fontSize,
//           fontWeight: fontWeight as any,
//           height: "1.2em",
//           overflow: "hidden",
//           textOverflow: "elipsis",
//           // background: "green",
//         }}
//       >
//         {text}
//       </span>

//       <span style={{ visibility: overflow ? undefined : "collapse" }}>...</span>
//     </div>
//   );
// };

export const TreeListNode = ({
  children,
  onVisibilityChange,
  onSelectionChange,
  onColorChange,
  onTrackSettingsChange,
  onTracksZoom,
  nodeProperties,
  iconMode,
}: TreeViewerProps) => {
  const properties = useTranslatedFieldUpdate<
    Record<string, TreeNodeProperties>,
    {
      id: string;
      active: boolean;
      name: string;
      tracks: string[];
      visible: boolean;
      selected: boolean;
      expanded: boolean;
      state: trackState;
      border?: string;
      settings: settingType;
    }
  >(
    nodeProperties,
    (source: Record<string, TreeNodeProperties>) => {
      const { id, name, tracks, active } = children;
      const node = source?.[id] ?? new TreeNodeProperties();
      const expanded = active ?? node.expanded ?? true;
      return {
        name: name,
        id: id,
        tracks: tracks,
        visible: node.visible,
        active: node.active ?? true,
        expanded: expanded,
        selected: node.selected,
        state: node.state,
        border: node.border,
        settings: node.settings,
      };
    },
    {}
  );

  const [expanded, setExpanded] = useState<boolean>(properties?.expanded ?? true);
  const [toolState, setToolState] = useState<ToolsType>({
    x: undefined,
    y: undefined,
    active: false,
  });
  const [toolsVisible, setToolsVisible] = useState<boolean>(false);

  useEffect(() => {
    setExpanded(properties.expanded);
  }, [properties.expanded]);

  const contentStyle = useMemo(() => {
    return properties.border === undefined ? styles.listContent + " " + styles.listBorder : styles.listContent;
  }, [properties.border, styles.listBorder]);

  // const contentStyle = border === undefined ? styles.listContent + " " + styles.listBorder : styles.listContent;

  const toolHandler = useCallback((e: any) => {
    //   // console.log("Test", e.target.getBoundingClientRect(), e.clientX, e.clientY);
    setToolState({ x: e.clientX, y: e.clientY, active: true });
  }, []);

  const content = useMemo(() => {
    if ((children as HierarchyNode).content) {
      // console.log("content set", properties.name, Object.keys(nodeProperties));
      return (children as HierarchyNode).content.map((child: any, i: number): any => (
        <TreeElement
          key={i}
          nodeProperties={nodeProperties}
          // nodeProperties={{}}
          onVisibilityChange={onVisibilityChange}
          onSelectionChange={onSelectionChange}
          children={child}
          onColorChange={onColorChange}
          onTrackSettingsChange={onTrackSettingsChange}
          onTracksZoom={onTracksZoom}
          iconMode={iconMode}
        />
      ));
    } else return null;
  }, [
    (children as HierarchyNode).content,
    nodeProperties,
    onVisibilityChange,
    onSelectionChange,
    onColorChange,
    onTrackSettingsChange,
    onTracksZoom,
    iconMode,
  ]);

  const showIcons = useMemo(() => {
    if (iconMode === undefined || iconMode === "eye") return { eye: true, cog: true };
    else if (iconMode === "noEye") return { eye: false, cog: true };
    else return { eye: false, cog: false };
  }, [iconMode, onVisibilityChange, properties.tracks, properties.visible]);

  const status = useMemo(() => {
    switch (properties.state) {
      case trackState.loading:
        return (
          <div style={{ paddingLeft: 3, paddingRight: 3 }}>
            <div className={[styles.loader].join(" ")} />
          </div>
        );
      case trackState.failed:
        return (
          <div className={styles.broken} style={{ paddingLeft: 3, paddingRight: 3 }}>
            <LucideIcon name="triangle-alert" style={{ height: 15 }} />
          </div>
        );

      case trackState.ready:
      default:
        return null;
    }
  }, [properties.state]);

  if (!properties.active) return null;

  return (
    <div className={styles.list}>
      <div
        className={[
          styles.keyLine,
          toolState.active || toolsVisible
            ? styles.highlightedLine
            : properties.selected
            ? styles.selectedLine
            : undefined,
        ].join(" ")}
        // style={{ background: toolState.active || toolsVisible ? "darkgray" : undefined }}
        // style={{ background: toolState.active || toolsVisible ? "darkgray" : selected ? "red" : undefined }}
        onMouseEnter={() => setToolsVisible(true)}
        onMouseLeave={() => setToolsVisible(false)}
        onClick={(e) => {
          if (onSelectionChange) {
            if (e.shiftKey || e.ctrlKey) onSelectionChange(properties.tracks, !properties.selected, "add");
            else onSelectionChange(properties.tracks, !properties.selected, "reset");
          }

          e.preventDefault();
        }}
      >
        <div className={styles.listHeader}>
          {toolState.active ? (
            <Tools state={toolState} setState={setToolState}>
              <span className={styles.settingsHeader}>List: {properties.name}</span>
              <TrackSettings
                settings={properties.settings}
                onChange={onTrackSettingsChange}
                onZoom={onTracksZoom}
                tracks={properties.tracks}
              />
            </Tools>
          ) : undefined}
          <div
            onClick={(e) => {
              setExpanded(!expanded);
              e.stopPropagation();
            }}
          >
            <LucideIcon name={expanded ? "chevron-down" : "chevron-right"} style={{ strokeWidth: 3 }} />
          </div>
          &nbsp;
          <div style={{ flexGrow: 1, overflow: "hidden" }}>
            <EllipsisTextBlock text={properties.name} />
          </div>
        </div>
        <div className={styles.toolList}>
          {status}
          {properties.settings ? (
            <div className={[properties.visible ? styles.active : styles.faded, styles.headerIcon].join(" ")}>
              <LucideIcon
                name="settings"
                onClick={(e) => {
                  toolHandler(e);
                  e.stopPropagation();
                }}
                style={{ visibility: showIcons.cog ? "visible" : "hidden", height: 15 }}
              />
            </div>
          ) : undefined}
          <div className={[properties.visible ? styles.active : styles.faded, styles.headerIcon].join(" ")}>
            <LucideIcon
              name="eye"
              onClick={(e) => {
                onVisibilityChange(properties.tracks, !properties.visible);
                e.stopPropagation();
              }}
              style={{ visibility: showIcons.eye ? "visible" : "hidden", height: 15, strokeWidth: 3 }}
            />
          </div>
        </div>
      </div>

      <div className={contentStyle} style={{ display: expanded ? undefined : "none" }}>
        {content}
      </div>
    </div>
  );
};
