import React, { useCallback, useEffect, useState } from "react";
import { clickType } from "../../../ViewerLayout/ViewerTypes";
import { DecoratedChoiceInput } from "../../../ViewerUIElements/Range/DecoratedChoiceInput";

import styles from "./ParameterSetsInput.module.css";
import { ProcessParameterInput } from "./ProcessParameterInput";
import { needUpdate } from "../../../ViewerLayout/ViewerLayoutUtils";

type optionType = { name: string; parameter: Record<string, any> };

// const ProcessParameterInput = ({
//   parameter,
//   graphClick,
//   setParameter,
// }: {
//   parameter: Record<string, any>;
//   graphClick: clickType;
//   setParameter: (parameterName: string, parameter: Record<string, any>) => void;
// }) => {
//   const p = parameter?.tracks;
//   // useEffect(() => {
//   //   console.log("> ParameterViewer setParameter");
//   // }, [setParameter]);

//   const view = Object.entries(parameter).map(([k, v]) => {
//     if ((v as serializedCommandParameterType).hidden) return null;
//     // console.log(k, "->", v);
//     let param: any = null;
//     switch (v.parameterType as parameterTypes) {
//       case parameterTypes.float:
//         param = (
//           <FloatInput
//             key={k}
//             parameterName={k}
//             graphClick={graphClick}
//             // setParameter={(parameter: Record<string, any>) => setParameter(k, parameter)}
//             setParameter={setParameter}
//             {...v}
//           />
//         );
//         break;
//       case parameterTypes.int:
//         param = (
//           <FloatInput
//             key={k}
//             parameterName={k}
//             graphClick={graphClick}
//             useInteger={true}
//             setParameter={setParameter}
//             {...v}
//           />
//         );
//         break;
//       case parameterTypes.string:
//         param = <StringInput key={k} parameterName={k} setParameter={setParameter} {...v} />;
//         break;
//       case parameterTypes.boolean:
//         param = <BooleanInput key={k} parameterName={k} setParameter={setParameter} {...v} />;
//         break;
//       case parameterTypes.stringList:
//         param = <StringListInput key={k} parameterName={k} setParameter={setParameter} {...v} />;
//         break;
//       default:
//         return null;
//     }

//     // let name = v.name ?? k;
//     const name = convertToTagList(v.name ?? k);

//     return (
//       <div key={k}>
//         <span className={styles.name}>{name}:</span> <span className={styles.type}>[{v.parameterType}]</span>
//         {param}
//       </div>
//     );
//   });

//   return <div style={{ paddingTop: 10 }}>{view}</div>;
// };

export const ParameterSetsInput = ({
  parameterName,
  value,
  setParameter,
  graphClick,
  selected,
}: {
  parameterName: string;
  value: optionType[];
  selected: string;
  graphClick: clickType;
  setParameter?: (parameterName: string, parameters: Record<string, any>) => void;
}) => {
  const [options, setOptions] = useState<{ id: string; value: string }[]>(
    value.map((v, i) => {
      return { id: "" + i, value: v.name };
    })
  );
  const [current, setCurret] = useState<string>(
    options.filter((o) => o.value === selected).shift()?.id ?? options?.[0]?.id ?? ""
  );
  const [currentParameter, setCurrentParameter] = useState<Record<string, any>>({});

  useEffect(() => {
    const o = value.map((v, i) => {
      return { id: "" + i, value: v.name };
    });
    if (needUpdate(options, o)) setOptions(o);
  }, [value]);

  useEffect(() => {
    const id = options.filter((o) => o.value === selected).shift()?.id ?? options?.[0]?.id ?? "";
    if (id !== current) setCurret(id);
  }, [selected]);

  useEffect(() => {
    const name = options[parseInt(current)].value;
    if (setParameter) setParameter(parameterName, { selected: name });
  }, [current, setParameter]);

  // useState <  string >
  //   useEffect(() => {
  //     if (setParameter) setParameter(parameterName, { value: userValue });
  //   }, [userValue]);

  const setValue = useCallback(
    (settingName: string, parameters: Record<string, any>) => {
      if (setParameter) {
        // console.log(settingName, "->", parameters);
        const c = value[parseInt(current)].name;
        const v: optionType[] = value.map((v, i) => {
          const parameter: Record<string, any> = {};
          // console.log("found", v.name, i, c);
          if (v.name === c) {
            parameter[settingName] = parameters;
          }
          return { name: v.name, parameter: parameter };
        });
        // v[parseInt(current)].parameter = { parameterName: parameters };
        // console.log({ value: v });
        setParameter(parameterName, { value: v });
      }
    },
    [current, setParameter]
  );

  useEffect(() => {
    // console.log("current", value[parseInt(current)]);
    if (current) setCurrentParameter(value[parseInt(current)].parameter);
  }, [current]);

  return (
    <div>
      <div>
        <DecoratedChoiceInput choices={options ?? []} value={current} setValue={setCurret} />
      </div>
      <div className={styles.parameter}>
        <ProcessParameterInput graphClick={graphClick} parameter={currentParameter} setParameter={setValue} />
      </div>
    </div>
  );
};
