import React, { useState, useLayoutEffect, useRef } from "react";
import ReactResizeDetector from "react-resize-detector";

type boundType = { width: number; height: number };

const NullDiv = function (props: any) {
  const {
    width,
    height,
    parentRef,
    bound,
    setBound,
  }: {
    width: number;
    height: number;
    parentRef: any;
    bound: boundType;
    setBound: (bound: boundType) => void;
  } = props;

  useLayoutEffect(() => {
    const element = parentRef.current as Element;
    const rect = element?.getBoundingClientRect();
    if (rect) {
      // console.log("bound", rect.width !== bound.width, rect.height !== bound.height);
      // console.log("bound", rect);
      const w = Math.round(rect.width);
      const h = Math.round(rect.height);
      if (w !== bound.width || h !== bound.height) setBound({ width: w, height: h });
    }
  }, [width, height, parentRef, bound.width, bound.height, setBound]);

  return <div></div>;
};

export function Resizeable(props: any) {
  const { children, style, ...otherProps } = props;

  const targetRef = useRef<HTMLDivElement>(null);
  const [bound, setBound] = useState<boundType>({ width: -1, height: -1 });
  // const [elements, setElements] = useState<any>([]);

  const elements = React.Children.map(props.children, (child: any, i: number) => {
    if (child?.type) return React.cloneElement(child, bound);
    return undefined;
  });
  // const elements = list.map((child: any) => React.cloneElement(child, {}));
  //   console.log("child", elements);

  const newStyle: any = {
    // background: "violet",
    float: "left",
    width: "100%",
    height: "100%",
    position: "relative",
  };

  if (style) Object.assign(newStyle, style);

  return (
    <ReactResizeDetector handleWidth handleHeight>
      {({ width, height }: boundType) => {
        return (
          <div ref={targetRef} style={newStyle} {...otherProps}>
            <NullDiv width={width} height={height} parentRef={targetRef} bound={bound} setBound={setBound} />
            <div style={{ position: "absolute" }}>{elements}</div>
          </div>
        );
      }}
    </ReactResizeDetector>
  );
}
