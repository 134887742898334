import { IconNMRHolderStatus } from "../../api/IconNMR";

export const RenderIconNMRHolderStatus = ({ status }: { status: IconNMRHolderStatus | undefined | null }) => {
  switch (status) {
    case "Completed":
      return (
        <label className="label label-soft-success" style={{ margin: 0 }}>
          {status}
        </label>
      );
    case "Running":
      return (
        <label className="label label-soft-warning" style={{ margin: 0 }}>
          {status}
        </label>
      );
    case "Submitted":
      return (
        <label className="label label-soft-default" style={{ margin: 0 }}>
          {status}
        </label>
      );
    default:
      return (
        <label className="label label-soft-default" style={{ margin: 0 }}>
          {status}
        </label>
      );
  }
};
