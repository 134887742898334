import { useContext, useEffect, useRef, useState } from "react";
import { Modal } from "../../../ELN/common/ELNModal/ELNModal";
import { Button } from "../../buttons/Button/Button";
import { CsvObjectLevelOptionsForm, csvObjectLevelOptionsToLabel, CsvSettings, useDownloadCSV } from "./useDownloadCSV";
import { EntityConstants, GenericEntity, IGenericRequestParameters } from "../../../api/GenericTypes";
import { ColumnsSettings } from "../ColumnsSelector/ColumnsSelector";
import { LucideIcon } from "../../icon/LucideIcon";
import { FormHeader } from "../../forms/FormHeader";
import { toUppercase } from "../../helperfunctions/stringFunctions";
import { FormFieldLayout } from "../../formfields/FormFieldLayouts";
import Select from "react-select";
import { ToggleButtonComponent } from "../../../ViewerUIElements/ToggleButtonComponent";
import { SessionContext } from "../../contexts/SessionContext";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { zIndex } from "../../../api/CommonConstants";

export const ExportCsvButton = <
  Entity extends GenericEntity,
  Filters extends { [property: string]: any } & IGenericRequestParameters<Entity, Filters["orderBy"]>
>({
  loading,
  disabled,
  entityConstants,
  columnSettings,
  filters,
  defaultCsvSettings = {
    delimiter: ";",
    listDelimiter: ",",
    nullValue: "-",
    propertyDelimiter: ":",
    csvObjectLevel: "Name",
    includeHeader: true,
  },
  className = "btn btn btn-sm btn-ghost-secondary",
  skipCsvSettingsModal = false,
  fileNameOverride,
}: {
  loading?: boolean;
  disabled?: boolean;
  entityConstants: EntityConstants;
  columnSettings?: ColumnsSettings<Entity>;
  filters?: Filters;
  defaultCsvSettings?: CsvSettings;
  className?: string;
  skipCsvSettingsModal?: boolean;
  fileNameOverride?: string;
}) => {
  const { session } = useContext(SessionContext);

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const [csvSettings, setCsvSettings] = useState<CsvSettings>(defaultCsvSettings);
  const [useActiveColumns, setUseActiveColumns] = useState<boolean>(true);

  const { downloadCSV, loading: preparingDownload } = useDownloadCSV<Entity, Filters>({
    entityConstants,
    filters,
    columnSettings: useActiveColumns ? columnSettings : null,
    csvSettings,
    fileNameOverride,
  });

  const ref = useRef<HTMLDivElement>(null);

  const [customizeDelimiter, setCustomizeDelimiter] = useState<boolean>(false);
  const [customizeListDelimiter, setCustomizeListDelimiter] = useState<boolean>(false);
  const [customizePropertyDelimiter, setCustomizePropertyDelimiter] = useState<boolean>(false);
  const [customizeCsvObjectLevel, setCustomizeCsvObjectLevel] = useState<boolean>(false);
  const [customizeNullValue, setCustomizeNullValue] = useState<boolean>(false);

  useEffect(() => {
    if (customizeDelimiter) {
      setCsvSettings((prevState) => ({ ...prevState, delimiter: "" }));
      document.getElementById("delimiter")?.focus();
    } else {
      setCsvSettings((prevState) => ({ ...prevState, delimiter: defaultCsvSettings.delimiter }));
    }
  }, [customizeDelimiter, defaultCsvSettings.delimiter]);

  useEffect(() => {
    if (customizeListDelimiter) {
      setCsvSettings((prevState) => ({ ...prevState, listDelimiter: "" }));
      document.getElementById("listDelimiter")?.focus();
    } else {
      setCsvSettings((prevState) => ({ ...prevState, listDelimiter: defaultCsvSettings.listDelimiter }));
    }
  }, [customizeListDelimiter, defaultCsvSettings.listDelimiter]);

  useEffect(() => {
    if (customizePropertyDelimiter) {
      setCsvSettings((prevState) => ({ ...prevState, propertyDelimiter: "" }));
      document.getElementById("propertyDelimiter")?.focus();
    } else {
      setCsvSettings((prevState) => ({ ...prevState, propertyDelimiter: defaultCsvSettings.propertyDelimiter }));
    }
  }, [customizePropertyDelimiter, defaultCsvSettings.propertyDelimiter]);

  useEffect(() => {
    setCsvSettings((prevState) => ({ ...prevState, csvObjectLevel: defaultCsvSettings.csvObjectLevel }));
  }, [customizeCsvObjectLevel, defaultCsvSettings.csvObjectLevel]);

  useEffect(() => {
    if (customizeNullValue) {
      setCsvSettings((prevState) => ({ ...prevState, nullValue: "" }));
      document.getElementById("nullValue")?.focus();
    } else {
      setCsvSettings((prevState) => ({ ...prevState, nullValue: defaultCsvSettings.nullValue }));
    }
  }, [customizeNullValue, defaultCsvSettings.nullValue]);

  return (
    <>
      <Button
        className={className}
        loading={loading}
        disabled={preparingDownload || disabled}
        onClick={() => (skipCsvSettingsModal ? downloadCSV() : setIsOpen(true))}
        title={`Export ${entityConstants.entityPlural} to CSV`}
      >
        <LucideIcon name="download" /> Export to CSV
      </Button>
      {!skipCsvSettingsModal && (
        <Modal
          isOpen={isOpen}
          onClose={() => {
            setIsOpen(false);
            setCsvSettings(defaultCsvSettings);
          }}
        >
          <Modal.Body>
            <div style={{ minWidth: "50vw", padding: "10px 20px" }} ref={ref}>
              <>
                <FormHeader
                  title="Export to CSV"
                  subtitle={`${toUppercase(entityConstants.entityPlural)}${
                    !!filters?.ids?.length ? " (" + filters.ids.length + ")" : ""
                  }`}
                />

                <fieldset>
                  <legend>CSV settings</legend>
                </fieldset>

                <FormFieldLayout id="" label="Include header" horizontal>
                  <ToggleButtonComponent
                    checked={!!csvSettings.includeHeader}
                    setChecked={() => setCsvSettings((prevState) => ({ ...prevState, includeHeader: !prevState }))}
                  >
                    <></>
                  </ToggleButtonComponent>
                </FormFieldLayout>

                <FormFieldLayout id="" label="Delimiter" horizontal>
                  <div className="flex align-center gap-5">
                    <input
                      id="delimiter"
                      value={csvSettings.delimiter}
                      className="form form-control"
                      onChange={(e) => {
                        const { value } = e.target;
                        setCsvSettings((prevState) => ({ ...prevState, delimiter: value }));
                      }}
                      readOnly={!customizeDelimiter}
                    />
                    <button
                      className="btn btn-default flex align-center gap-5"
                      style={{ minWidth: "130px", justifyContent: "center" }}
                      onClick={() => {
                        setCustomizeDelimiter((prevState) => !prevState);
                      }}
                    >
                      <LucideIcon name={customizeDelimiter ? "x" : "settings"} />
                      {customizeDelimiter ? "Reset" : "Customize"}
                    </button>
                    <div style={{ width: "50px", textAlign: "center" }}>
                      <OverlayTrigger
                        placement="bottom"
                        overlay={
                          <Popover
                            id="popover-info"
                            style={{ textAlign: "justify", zIndex: zIndex.zIndexPortalDropdown }}
                          >
                            The delimiter which will be used to separate columns.
                          </Popover>
                        }
                      >
                        <LucideIcon name="info" color={"var(--gray-400)"} />
                      </OverlayTrigger>
                    </div>
                  </div>
                </FormFieldLayout>

                <FormFieldLayout id="" label="List delimiter" horizontal>
                  <div className="flex align-center gap-5">
                    <input
                      id="listDelimiter"
                      value={csvSettings.listDelimiter}
                      className="form form-control"
                      onChange={(e) => {
                        const { value } = e.target;
                        setCsvSettings((prevState) => ({ ...prevState, listDelimiter: value }));
                      }}
                      readOnly={!customizeListDelimiter}
                    />
                    <button
                      className="btn btn-default flex align-center gap-5"
                      style={{ minWidth: "130px", justifyContent: "center" }}
                      onClick={() => {
                        setCustomizeListDelimiter((prevState) => !prevState);
                      }}
                    >
                      <LucideIcon name={customizeListDelimiter ? "x" : "settings"} />
                      {customizeListDelimiter ? "Reset" : "Customize"}
                    </button>
                    <div style={{ width: "50px", textAlign: "center" }}>
                      <OverlayTrigger
                        placement="bottom"
                        overlay={
                          <Popover
                            id="popover-info"
                            style={{ textAlign: "justify", zIndex: zIndex.zIndexPortalDropdown }}
                          >
                            The delimiter which will be used to separate elements in columns with multiple values.
                          </Popover>
                        }
                      >
                        <LucideIcon name="info" color={"var(--gray-400)"} />
                      </OverlayTrigger>
                    </div>
                  </div>
                </FormFieldLayout>

                <FormFieldLayout id="" label="Reference property delimiter" horizontal>
                  <div className="flex align-center gap-5">
                    <input
                      id="propertyDelimiter"
                      value={csvSettings.propertyDelimiter}
                      className="form form-control"
                      onChange={(e) => {
                        const { value } = e.target;
                        setCsvSettings((prevState) => ({ ...prevState, propertyDelimiter: value }));
                      }}
                      readOnly={!customizePropertyDelimiter}
                    />
                    <button
                      className="btn btn-default flex align-center gap-5"
                      style={{ minWidth: "130px", justifyContent: "center" }}
                      onClick={() => {
                        setCustomizePropertyDelimiter((prevState) => !prevState);
                      }}
                    >
                      <LucideIcon name={customizePropertyDelimiter ? "x" : "settings"} />
                      {customizePropertyDelimiter ? "Reset" : "Customize"}
                    </button>
                    <div style={{ width: "50px", textAlign: "center" }}>
                      <OverlayTrigger
                        placement="bottom"
                        overlay={
                          <Popover
                            id="popover-info"
                            style={{ textAlign: "justify", zIndex: zIndex.zIndexPortalDropdown }}
                          >
                            The delimiter which will be used to separate different properties of references.
                          </Popover>
                        }
                      >
                        <LucideIcon name="info" color={"var(--gray-400)"} />
                      </OverlayTrigger>
                    </div>
                  </div>
                </FormFieldLayout>

                <FormFieldLayout id="" label="Reference detail level" horizontal>
                  <div className="flex align-center gap-5">
                    <div style={{ width: "100%" }}>
                      <Select
                        id="csvObjectLevel"
                        isSearchable={false}
                        value={{
                          id: csvSettings.csvObjectLevel,
                          label: csvObjectLevelOptionsToLabel(
                            csvSettings.csvObjectLevel,
                            csvSettings.propertyDelimiter
                          ) as string,
                          value: csvSettings.csvObjectLevel,
                        }}
                        options={CsvObjectLevelOptionsForm.map((c) => ({
                          ...c,
                          label: csvObjectLevelOptionsToLabel(c.label, csvSettings.propertyDelimiter),
                        }))}
                        placeholder="Select salutation..."
                        menuPosition="fixed"
                        onChange={(e) => {
                          e?.value && setCsvSettings((prevState) => ({ ...prevState, csvObjectLevel: e.value }));
                        }}
                        isDisabled={!customizeCsvObjectLevel}
                      />
                    </div>
                    <button
                      className="btn btn-default flex align-center gap-5"
                      style={{ minWidth: "130px", justifyContent: "center" }}
                      onClick={() => {
                        setCustomizeCsvObjectLevel((prevState) => !prevState);
                      }}
                    >
                      <LucideIcon name={customizeCsvObjectLevel ? "x" : "settings"} />
                      {customizeCsvObjectLevel ? "Reset" : "Customize"}
                    </button>
                    <div style={{ width: "50px", textAlign: "center" }}>
                      <OverlayTrigger
                        placement="bottom"
                        overlay={
                          <Popover
                            id="popover-info"
                            style={{ textAlign: "justify", zIndex: zIndex.zIndexPortalDropdown }}
                          >
                            The detail level of references.
                          </Popover>
                        }
                      >
                        <LucideIcon name="info" color={"var(--gray-400)"} />
                      </OverlayTrigger>
                    </div>
                  </div>
                </FormFieldLayout>

                <FormFieldLayout id="" label="Empty value text" horizontal>
                  <div className="flex align-center gap-5">
                    <input
                      id="nullValue"
                      value={csvSettings.nullValue}
                      className="form form-control"
                      onChange={(e) => {
                        const { value } = e.target;
                        setCsvSettings((prevState) => ({ ...prevState, nullValue: value }));
                      }}
                      readOnly={!customizeNullValue}
                    />
                    <button
                      className="btn btn-default flex align-center gap-5"
                      style={{ minWidth: "130px", justifyContent: "center" }}
                      onClick={() => {
                        setCustomizeNullValue((prevState) => !prevState);
                      }}
                    >
                      <LucideIcon name={customizeNullValue ? "x" : "settings"} />
                      {customizeNullValue ? "Reset" : "Customize"}
                    </button>
                    <div style={{ width: "50px", textAlign: "center" }}>
                      <OverlayTrigger
                        placement="bottom"
                        overlay={
                          <Popover
                            id="popover-info"
                            style={{ textAlign: "justify", zIndex: zIndex.zIndexPortalDropdown }}
                          >
                            The text which will be shown instead of empty values.
                          </Popover>
                        }
                      >
                        <LucideIcon name="info" color={"var(--gray-400)"} />
                      </OverlayTrigger>
                    </div>
                  </div>
                </FormFieldLayout>
              </>

              {(session?.permissions.view_admin_pages || session?.permissions.administer_permissions) && (
                <>
                  <fieldset>
                    <legend>CSV column settings</legend>
                  </fieldset>

                  <FormFieldLayout id="" label="Only use active columns" horizontal>
                    <ToggleButtonComponent checked={useActiveColumns} setChecked={setUseActiveColumns}>
                      <></>
                    </ToggleButtonComponent>
                  </FormFieldLayout>
                </>
              )}
            </div>
          </Modal.Body>
          <Modal.Controls>
            <div className="flex align-center gap-5" style={{ justifyContent: "flex-end", marginTop: "5px" }}>
              <button
                className="btn btn-default"
                onClick={() => {
                  setCsvSettings(defaultCsvSettings);
                  setIsOpen(false);
                }}
              >
                Cancel
              </button>
              <button
                className="btn btn-primary"
                onClick={() => {
                  downloadCSV();
                  setCsvSettings(defaultCsvSettings);
                  setIsOpen(false);
                }}
              >
                {`Export ${entityConstants.entityPlural}`}
              </button>
            </div>
          </Modal.Controls>
        </Modal>
      )}
    </>
  );
};
