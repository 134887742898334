import { EntityDetailTableProps } from "../common/entity/EntityInterfaces";
import { Table } from "../common/panels/Detail/DetailTable";
import { NotSet } from "../common/misc/UIconstants";
import { LinkEntity } from "../common/misc/LinkEntity/LinkEntity";
import { customTypeConstants } from "../api/CustomTypes";
import { toUppercase } from "../common/helperfunctions/stringFunctions";
import { methodsConstants } from "../api/Methods";
import { CustomTypeRenderer } from "../Customization/CustomTypes/generics/CustomTypeRenderer";
import { experimentsConstants } from "../api/Experiments";
import { instrumentsConstants } from "../api/Facilities";
import { GetEquipments, GetOrganizations, GetPersons, GetProjects } from "../common/misc/EntityRenders/EntityRenderer";
import { DateTimeRenderer } from "../common/datetime/DateTimeFormatter";

interface DatasetDetailTableProps extends EntityDetailTableProps<"datasets"> {
  hideHeader?: boolean;
}

export const DatasetsDetailTable = ({
  entity: dataset,
  entityConstants,
  fieldLabels,
  hideHeader = false,
}: DatasetDetailTableProps) => {
  return (
    <>
      <Table noPadding nonFoldable>
        {!hideHeader && <Table.Head>Basic details</Table.Head>}
        <Table.Body>
          <Table.Body.RowContent
            title={fieldLabels.name}
            content={
              <div className="container_label">
                <div className="container_label_name" style={{ fontWeight: 600 }}>
                  {dataset.name ?? NotSet}
                </div>
                <div className="container_label_id">
                  <span style={{ whiteSpace: "nowrap" }}>
                    {toUppercase(entityConstants.entitySingular)}-ID: {dataset.id}
                  </span>
                </div>
              </div>
            }
          />

          <Table.Body.RowContent
            title={fieldLabels.customType}
            content={
              <div className="container_label">
                {dataset.customType ? (
                  <LinkEntity entityConstants={customTypeConstants} property={dataset.customType} />
                ) : (
                  <div className="container_label_name">
                    <span style={{ color: "var(--gray-400)", fontWeight: 500 }}>
                      Basic {entityConstants.entitySingular}
                    </span>
                  </div>
                )}
              </div>
            }
          />

          <Table.Body.RowContent
            title={fieldLabels.method}
            content={
              dataset.method?.id ? <LinkEntity entityConstants={methodsConstants} property={dataset.method} /> : NotSet
            }
          />

          <Table.Body.RowContent
            title={fieldLabels.experiment}
            content={
              dataset.experiment?.id ? (
                <LinkEntity entityConstants={experimentsConstants} property={dataset.experiment} />
              ) : (
                NotSet
              )
            }
          />

          <Table.Body.RowContent
            title={fieldLabels.instrument}
            content={
              dataset.instrument ? (
                <LinkEntity entityConstants={instrumentsConstants} property={dataset.instrument} />
              ) : (
                NotSet
              )
            }
          />

          <Table.Body.RowContent
            title={fieldLabels.equipments}
            content={<GetEquipments equipments={dataset.equipments} createLinks />}
          />

          <Table.Body.RowContent
            title={fieldLabels.acquisitionDate}
            content={dataset.acquisitionDate ? <DateTimeRenderer date={dataset.acquisitionDate} /> : NotSet}
          />

          <Table.Body.RowContent
            title={fieldLabels.projects}
            content={<GetProjects projects={dataset.projects} createLinks />}
          />

          <Table.Body.RowContent
            title={fieldLabels.organizations}
            content={<GetOrganizations organizations={dataset.organizations} createLinks />}
          />

          <Table.Body.RowContent
            title={fieldLabels.operators}
            content={<GetPersons persons={dataset.operators} createLinks />}
          />

          <Table.Body.RowContent title={fieldLabels.other} content={dataset.other ?? NotSet} />

          <Table.Body.RowContent title={fieldLabels.notes} content={dataset.notes ?? NotSet} />
        </Table.Body>
      </Table>
      <CustomTypeRenderer entity={dataset} />
    </>
  );
};
