import React from "react";
import styles from "./Sekeleton.module.css";
import { LucideIcon } from "../../icon/LucideIcon";
import BrandLoader from "../Spinner/BrandLoader";
/**
 * This component returns an animated skeleton in different styles to be used as loading indications
 * Author: CS
 * @param: type
 * @returns: JSX.Element
 */

interface Props {
  type: "pairs" | "rows" | "columns" | "viewer" | "elnpage";
  loading?: boolean;
  n?: number;
  buttons?: boolean;
  children?: React.ReactNode;
}
export const Skeleton = ({ type = "pairs", loading = true, n = 3, buttons = false, children }: Props) => {
  const rows = (
    <>
      <div className={`skeleton-block txt-body`}></div>
      <div className={`skeleton-block second txt-body`}></div>
      <div className={`skeleton-block third txt-body`}></div>
    </>
  );
  return (
    <div className={styles.container}>
      {type === "pairs" && (
        <div className={styles.pairs_container}>
          <div className={`skeleton-block txt-body`}></div>
          <div className={`skeleton-block txt-body`}></div>
          <div className={`skeleton-block second txt-body`}></div>
          <div className={`skeleton-block second txt-body`}></div>
          <div className={`skeleton-block third txt-body`}></div>
          <div className={`skeleton-block third txt-body`}></div>
        </div>
      )}
      {type === "rows" && <div className={styles.rows_container}>{rows}</div>}
      {type === "columns" && (
        <React.Fragment>
          {buttons ? (
            <div className={styles.column_container}>
              {[...Array(n - 1)].map((d, i) => (
                <div className={styles.rows_container} key={i}>
                  {rows}
                </div>
              ))}
              <div className={styles.rows_container}>
                <div className={`skeleton-block btn-xs`}></div>
                <div className={`skeleton-block second btn-xs`}></div>
                <div className={`skeleton-block third btn-xs`}></div>
              </div>
            </div>
          ) : (
            <div className={styles.column_container}>
              {[...Array(n)].map((d, i) => (
                <div className={styles.rows_container} key={i}>
                  {rows}
                </div>
              ))}
            </div>
          )}
        </React.Fragment>
      )}
      {type === "viewer" && (
        <div className={styles.viewer_skeleton}>
          <div className={styles.panel_left}>
            <div className={styles.panel_controls}>
              <div></div>
              <div></div>
            </div>
            <div className={styles.panel_left_bg}>
              {loading ? <BrandLoader size={"50%"} style={{ opacity: "0.3" }} /> : <LucideIcon name="image-off" />}
            </div>
            {/* <div className={styles.viewer_content}>{children}</div> */}
          </div>
          <div className={styles.panel_right_top}>
            <div className={styles.panel_container}>
              <div className="skeleton-block" style={{ ...(!loading && { animation: "none" }) }}></div>
              <div className="skeleton-block second" style={{ ...(!loading && { animation: "none" }) }}></div>
              <div className="skeleton-block third" style={{ ...(!loading && { animation: "none" }) }}></div>
            </div>
          </div>
          <div className={styles.panel_right_bottom}>
            <div className={styles.panel_pairs_container}>
              <div className="skeleton-block" style={{ ...(!loading && { animation: "none" }) }}></div>
              <div className="skeleton-block" style={{ ...(!loading && { animation: "none" }) }}></div>
              <div className="skeleton-block second" style={{ ...(!loading && { animation: "none" }) }}></div>
              <div className="skeleton-block second" style={{ ...(!loading && { animation: "none" }) }}></div>
              <div className="skeleton-block third" style={{ ...(!loading && { animation: "none" }) }}></div>
              <div className="skeleton-block third" style={{ ...(!loading && { animation: "none" }) }}></div>
            </div>
          </div>
          <div className={styles.viewer_content}>{children}</div>
        </div>
      )}
      {type === "elnpage" && (
        <div className={styles.page} style={{ opacity: children ? 1 : 0.5 }}>
          {children !== undefined ? (
            <div>{children}</div>
          ) : (
            <div className={styles.pairs_container}>
              <div className={`skeleton-block txt-body`}></div>
              <div className={`skeleton-block txt-body`}></div>
              <div className={`skeleton-block second txt-body`}></div>
              <div className={`skeleton-block second txt-body`}></div>
              <div className={`skeleton-block third txt-body`}></div>
              <div className={`skeleton-block third txt-body`}></div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
