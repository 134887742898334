import {
  PropertyTranslator,
  IGenericRequestParametersTranslator,
  IPaginationParametersTranslator,
  ICreatedRecordParametersTranslator,
  IModifiedRecordParametersTranslator,
  IRelationParametersTranslator,
  INamedEntityParametersTranslator,
  ICreationRecordLabels,
  IModificationRecordLabels,
  INamedEntityLabels,
  IPermissionedEntityLabels,
  IRelatedEntityLabels,
  IUniqueEntityLabels,
} from "./GenericTranslator";
import {
  IRelationModel,
  IModelWithIntId,
  IRelatedEntity,
  IRelation,
  INamedEntity,
  IGenericRequestParameters,
  IRelationParameters,
  EntityConstants,
  IPermissionedEntity,
  IGenericEntity,
  ICreatedRecordParameters,
  IModifiedRecordParameters,
  ICreationRecord,
  IModificationRecord,
  DefaultCreatedByRecordOrderTypeConsts,
  DefaultCreatedOnRecordOrderTypeConsts,
  DefaultModifiedByRecordOrderTypeConsts,
  DefaultModifiedOnRecordOrderTypeConsts,
  DefaultOrderTypeConsts,
  IPaginationParameters,
  IEntityMinimalModel,
  INamedEntityParameters,
} from "./GenericTypes";

export const organizationsConstants: EntityConstants<Organization, OrganizationFilters> = {
  resource: "organizations",
  frontendIndexRoute: "organizations",
  entitySingular: "organization",
  entityPlural: "organizations",
  icon: "house",
};

export const OrganizationFieldLabels: PropertyTranslator<Organization> = {
  ...INamedEntityLabels,
  ...IPermissionedEntityLabels,
  ...ICreationRecordLabels,
  ...IModificationRecordLabels,
  ...IRelatedEntityLabels,
  ...IUniqueEntityLabels,
  id: "Organization ID",
  address: "Address",
  phoneOffice: "Phone",
  faxOffice: "Fax",
  email: "Mail",
  web: "Web",
  notes: "Notes",
  organizationTags: "Tags",
} as const;

export interface OrganizationRelations extends IRelationModel {
  datasets: IRelation;
  samples: IRelation;
  persons: IRelation;
}

export interface Organization
  extends IGenericEntity,
    INamedEntity,
    IModelWithIntId,
    IRelatedEntity<OrganizationRelations>,
    IPermissionedEntity,
    INamedEntity,
    ICreationRecord,
    IModificationRecord {
  address: string;
  phoneOffice: string;
  faxOffice: string;
  email: string;
  web: string;
  notes: string;
  organizationTags?: IEntityMinimalModel<number>[];
}
export interface OrganizationSuggestions extends IEntityMinimalModel<Organization["id"]> {}

export interface OrganizationFilters
  extends IGenericRequestParameters<Organization, OrganizationSortingOptions>,
    IPaginationParameters,
    IRelationParameters,
    ICreatedRecordParameters,
    IModifiedRecordParameters,
    INamedEntityParameters {
  organizationTagIds?: number[] | null;
  personIds?: number[] | null;
}
export const OrganizationFiltersTranslator: PropertyTranslator<OrganizationFilters> = {
  ...IGenericRequestParametersTranslator,
  ...IPaginationParametersTranslator,
  ...ICreatedRecordParametersTranslator,
  ...IModifiedRecordParametersTranslator,
  ...INamedEntityParametersTranslator,
  ...IRelationParametersTranslator,
  names: "Names",
  organizationTagIds: "Organization-Tag IDs",
  personIds: "Person IDs",
} as const;

export const OrganizationSortingOptionsConsts = [
  ...DefaultOrderTypeConsts,
  ...DefaultCreatedOnRecordOrderTypeConsts,
  ...DefaultCreatedByRecordOrderTypeConsts,
  ...DefaultModifiedOnRecordOrderTypeConsts,
  ...DefaultModifiedByRecordOrderTypeConsts,
] as const;
export type OrganizationSortingOptions = (typeof OrganizationSortingOptionsConsts)[number];
