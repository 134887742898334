import React, { CSSProperties, useCallback, useContext } from "react";
import { useHistory } from "react-router-dom";
import { SessionContext } from "../contexts/SessionContext";
import { useEntityApi } from "../../api/useEntityApi";
import { getDetailLink } from "../../main/Routing";
import { FormLayoutContainer } from "../forms/FormLayout";
import { EntityFormProps, FormEventTypes } from "./EntityInterfaces";
import { EntityByEntityTypeId, GenericEntityConstantsEntities, getEntityConstants } from "../../api/GenericConstants";
import { useEntityPermissions } from "../permissions/useEntityPermissions";
import { PageNotAllowed } from "../../main/common/PageNotAllowed/PageNotAllowed";

interface EntityAddFormProps<EntityTypeId extends GenericEntityConstantsEntities> {
  entityTypeId: EntityTypeId;
  children: (props: EntityFormProps<EntityTypeId>) => React.ReactNode;
  onSuccess?: (entity: EntityByEntityTypeId<EntityTypeId>) => void;
  onCancel?: () => void;
  event?: FormEventTypes;
  style?: CSSProperties;
}
export const EntityAddForm = <EntityTypeId extends GenericEntityConstantsEntities>({
  entityTypeId,
  children,
  onSuccess,
  onCancel,
  event = "ADD",
  style,
}: EntityAddFormProps<EntityTypeId>) => {
  const history = useHistory();
  const { route } = useContext(SessionContext);
  const { entityConstants, fieldLabels } = getEntityConstants(entityTypeId);
  type EntityType = EntityByEntityTypeId<EntityTypeId>;
  const permissions = useEntityPermissions({ entityTypeId });
  const { canCreate } = permissions;
  const { createMutationAsync, isLoadingCreateMutation } = useEntityApi<EntityType>(entityConstants.resource);

  const create = useCallback(
    async (entity: Partial<EntityType>) => {
      await createMutationAsync(
        { body: entity },
        {
          onSuccess: (result) => {
            onSuccess
              ? onSuccess(result)
              : history.push(route(getDetailLink(entityConstants.frontendIndexRoute, result.id)));
          },
        }
      ).catch(() => {});
    },
    [createMutationAsync, entityConstants.frontendIndexRoute, history, onSuccess, route]
  );

  if (!canCreate) return <PageNotAllowed />;
  return (
    <FormLayoutContainer style={style}>
      {children({
        fieldLabels,
        permissions,
        title: `Add ${entityConstants.entitySingular}`,
        onCancel: onCancel || history.goBack,
        submitButtonLabel: "Create",
        onSubmit: create,
        loading: isLoadingCreateMutation,
        event: event,
      })}
    </FormLayoutContainer>
  );
};

interface EntityAddFormPageProps {
  style?: CSSProperties;
  children: React.ReactNode;
}
export const EntityAddFormPage = ({ style, children }: EntityAddFormPageProps) => {
  return (
    <div className="center-horizontally" style={style}>
      {children}
    </div>
  );
};
