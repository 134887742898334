import { IconNames } from "../common/icon/LucideIcon";
import { CustomType, CustomTypeFilters } from "./CustomTypes";
import {
  PropertyTranslator,
  IGenericRequestParametersTranslator,
  IRelationParametersTranslator,
  IPaginationParametersTranslator,
  ISoftDeletableFilterParametersTranslator,
  ICreatedRecordParametersTranslator,
  IModifiedRecordParametersTranslator,
  ITypedEntityCustomValuesSearchParametersTranslator,
  IHierarchyParametersTranslator,
  IProjectBasedParametersTranslator,
  ICreationRecordLabels,
  ICustomTypedEntityLabels,
  IModificationRecordLabels,
  INamedEntityLabels,
  IPermissionedEntityLabels,
  IRelatedEntityLabels,
  ISoftDeletableLabels,
  IUniqueEntityLabels,
  IHierarchyReadLabels,
} from "./GenericTranslator";
import {
  DefaultCreatedByRecordOrderTypeConsts,
  DefaultCreatedOnRecordOrderTypeConsts,
  DefaultModifiedByRecordOrderTypeConsts,
  DefaultModifiedOnRecordOrderTypeConsts,
  DefaultOrderTypeConsts,
  EntityConstants,
  ICreatedRecordParameters,
  ICreationRecord,
  IGenericRequestParameters,
  IModelWithIntId,
  IModificationRecord,
  IModifiedRecordParameters,
  INamedEntity,
  IPaginationParameters,
  IPermissionedEntity,
  IRelatedEntity,
  IRelationModel,
  IRelationParameters,
  ISoftDeletable,
  ISoftDeletableFilterParameters,
  ICustomTypedEntity,
  IUniqueEntity,
  TypedEntitySuggestionsModel,
  IRelation,
  IProjectBasedResponseModel,
  IHierarchyRead,
  IHierarchyParameters,
  DefaultHierarchyOrderTypeConsts,
  DefaultCustomTypeOrderTypeConsts,
  IEntityMinimalModel,
  CustomFieldValuesSearchParametersITypedEntity,
  ITypedEntityCustomValuesSearchParameters,
  IProjectBasedParameters,
} from "./GenericTypes";

export const inventoriesConstants: EntityConstants<CustomType, CustomTypeFilters> = {
  resource: "inventories",
  frontendIndexRoute: "inventories",
  entitySingular: "inventory",
  entityPlural: "inventories",
  icon: "box",
};

export const inventoryItemsConstants: EntityConstants<InventoryItem, InventoryItemFilters> = {
  resource: "inventories",
  frontendIndexRoute: "inventory_items",
  entitySingular: "inventory item",
  entityPlural: "inventory items",
  icon: "file-box",
};

export const hierarchyConstants: {
  flatLayoutIcon: IconNames;
  hierarchyLayoutIcon: IconNames;
  childIcon: IconNames;
  childItemIcon: IconNames;
  childType: string;
  childTypeDescription: string;
} = {
  flatLayoutIcon: "list",
  hierarchyLayoutIcon: "network",
  childIcon: "git-pull-request-create-arrow",
  childItemIcon: "plus",
  childType: "content type",
  childTypeDescription: "content type description",
};

export interface InventoryItem
  extends IModelWithIntId,
    IPermissionedEntity,
    IRelatedEntity<InventoryItemRelations>,
    INamedEntity,
    IUniqueEntity,
    ISoftDeletable,
    ICreationRecord,
    IModificationRecord,
    ICustomTypedEntity,
    IProjectBasedResponseModel,
    IHierarchyRead {
  rootCustomType?: InventoryItemRootCustomTypeModel;
  isRootItem: boolean;
  isHierarchyItem: boolean;
}

interface InventoryItemRootCustomTypeModel extends IEntityMinimalModel {
  inventoryName: string;
}

export const InventoryItemFieldLabels: PropertyTranslator<InventoryItem> = {
  ...INamedEntityLabels,
  ...IUniqueEntityLabels,
  ...IPermissionedEntityLabels,
  ...ICreationRecordLabels,
  ...IModificationRecordLabels,
  ...IRelatedEntityLabels,
  ...ISoftDeletableLabels,
  ...ICustomTypedEntityLabels,
  ...IHierarchyReadLabels,
  id: "Inventory item ID",
  projects: "Projects",
  rootCustomType: "Inventory",
  isRootItem: "Is top level item",
  isHierarchyItem: "Is part of hierarchy",
} as const;

export interface InventoryItemSuggestions extends TypedEntitySuggestionsModel<InventoryItem["id"]> {}

export interface InventoryItemRelations extends IRelationModel {
  labNotebookEntries: IRelation;
}

export interface InventoryItemFilters
  extends IGenericRequestParameters<InventoryItem, InventoryItemSortingOptions>,
    IRelationParameters,
    IPaginationParameters,
    ISoftDeletableFilterParameters,
    ICreatedRecordParameters,
    IModifiedRecordParameters,
    ITypedEntityCustomValuesSearchParameters<CustomFieldValuesSearchParametersITypedEntity>,
    IHierarchyParameters,
    IProjectBasedParameters {}

export const InventoryItemSortingOptionsConsts = [
  ...DefaultOrderTypeConsts,
  ...DefaultCustomTypeOrderTypeConsts,
  ...DefaultCreatedOnRecordOrderTypeConsts,
  ...DefaultCreatedByRecordOrderTypeConsts,
  ...DefaultModifiedOnRecordOrderTypeConsts,
  ...DefaultModifiedByRecordOrderTypeConsts,
  ...DefaultHierarchyOrderTypeConsts,
] as const;
export type InventoryItemSortingOptions = (typeof InventoryItemSortingOptionsConsts)[number];

export const InventoryItemFiltersTranslator: PropertyTranslator<InventoryItemFilters> = {
  ...IGenericRequestParametersTranslator,
  ...IRelationParametersTranslator,
  ...IPaginationParametersTranslator,
  ...IRelationParametersTranslator,
  ...ISoftDeletableFilterParametersTranslator,
  ...ICreatedRecordParametersTranslator,
  ...IModifiedRecordParametersTranslator,
  ...ITypedEntityCustomValuesSearchParametersTranslator,
  ...IHierarchyParametersTranslator,
  ...IProjectBasedParametersTranslator,
} as const;
