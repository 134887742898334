import { Dataset } from "./Datasets";
import { PropertyTranslator, IGenericRequestParametersTranslator, INamedEntityLabels } from "./GenericTranslator";
import {
  DefaultOrderTypeConsts,
  EntityConstants,
  IConfigFile,
  IEntityMinimalModel,
  IGenericEntity,
  IGenericRequestParameters,
  StringIndexedDict,
} from "./GenericTypes";

export const customImportConstants: EntityConstants<CustomImport, CustomImportFilters> = {
  resource: "custom_imports",
  frontendIndexRoute: "custom_imports",
  entitySingular: "metadata mapping",
  entityPlural: "metadata mappings",
  icon: "settings",
};

export const customImportFieldLabels = {
  name: "Name",
};

export interface CustomImport extends IGenericEntity<string>, IConfigFile {
  name: string;
  variables: {
    [property: string]: CustomImportVariable;
  };
  variableMappings: {
    [property: string]: EntityVariableMapping;
  };
  rules: CustomImportRule[];
}
export interface CustomImportSuggestions extends IEntityMinimalModel<CustomImport["id"]> {}

export interface CustomImportFilters extends IGenericRequestParameters<CustomImport> {}

export const CustomImportFiltersTranslator: PropertyTranslator<CustomImportFilters> = {
  ...IGenericRequestParametersTranslator,
} as const;

interface CustomImportVariable {
  extractor: CustomImportExtractorDefinition;
  preprocessors?: CustomImportPreprocessor[];
}

interface CustomImportExtractorDefinition {
  type: CustomImportExtractorType;
  parameters: {
    [property: string]: Object;
  };
}
type CustomImportExtractorType = "PATH" | "NAME" | "PARAMETERS" | "STATIC" | "NOTES" | "MERGE";

interface CustomImportPreprocessor {
  type: CustomImportPreprocessorType;
  parameters: {
    [property: string]: Object;
  };
}
type CustomImportPreprocessorType = "REGEXP" | "ARRAY";

interface EntityVariableMapping {
  entityType: EntityType;
  sourceVariables: { [property: string]: string };
  resolvedVariables?: { [property: string]: string };
  mappingType: EntityMappingType;
  createIfNotExists: boolean;
}
type EntityType = "PROJECT" | "SAMPLE" | "PERSON" | "EXPERIMENT";
type EntityMappingType = "BY_NAME" | "BY_ID";

interface CustomImportRule {
  type: RuleType;
  target: AssignmentTarget;
  sourceVariable: string;
  data?: { [property: string]: object };
}
type RuleType = "SET" | "ADD" | "CLAIM";
type AssignmentTarget =
  | "PROJECT"
  | "SAMPLE"
  | "OPERATOR"
  | "OWNER"
  | "ORGANIZATION"
  | "EXPERIMENT"
  | "OTHER"
  | "CUSTOM_FIELDS";

export const CustomImportSortingOptionsConsts = [...DefaultOrderTypeConsts] as const;

export const CustomImportFieldLabels: PropertyTranslator<CustomImport> = {
  ...INamedEntityLabels,
  id: "Meta data mapping ID",
  variables: "Variables",
  variableMappings: "Variable Mappings",
  rules: "Rules",
} as const;

type MappingResultAction = "CREATE_ENTITY" | "USE_EXISTING_ENTITY" | "NOT_FOUND";
interface MappingPlan {
  index: number;
  entityType: EntityType;
  action: MappingResultAction;
  sourceVariable: StringIndexedDict<string>;
  entity: IGenericEntity<number>;
}

interface CustomImportError {
  message: string;
  stackTrace: string;
}
export interface CustomImportResult {
  resolvedStringVariables: StringIndexedDict<string>;
  mappingResults: StringIndexedDict<MappingPlan>;
  mappedEntitiesForResponse: StringIndexedDict<object>;
  modifiedDataset: Dataset;
  error: CustomImportError;
  datasetId: Dataset["id"];
}
