import produce from "immer";
import { useCallback, useMemo } from "react";

import styles from "./SpreadSheet.module.css";
import { CellElementProps, CellIndex, CellProps, FormattedTableCell } from "./SpreadSheetTypes";

export const MainCell = ({
  columnIndex,
  index,
  rowIndex,
  style,
  cellState,
  setCellState,
  ContentCellElement,
  CellValues,
}: CellProps & {
  ContentCellElement?: (props: CellElementProps) => JSX.Element;
  CellValues: (index: CellIndex) => FormattedTableCell;
}) => {
  // const [cellValues] = useState<Record<string, string>>({ "1-4": "hurz" });
  // const [edit, setEdit] = useState(false);

  // const value = cellValues[index] || "";

  const isFocused = columnIndex === cellState.focused.columnIndex && rowIndex === cellState.focused.rowIndex;

  const width = useMemo(() => (style?.width ? parseInt(style?.width?.toString()) : 0), [style?.width]);
  const height = useMemo(() => (style?.height ? parseInt(style?.height?.toString()) : 0), [style?.height]);

  const content = useMemo(() => {
    return ContentCellElement ? (
      <ContentCellElement
        width={width}
        height={height}
        columnIndex={columnIndex}
        rowIndex={rowIndex}
        content={CellValues({ rowIndex, columnIndex })}
        settings={{ focused: isFocused }}
      />
    ) : (
      <div className={isFocused ? styles.MainGridCellFocused : undefined} style={{ flexGrow: 1 }}>
        <span>{`Cell ${rowIndex} ${columnIndex}`}</span>
      </div>
    );
  }, [ContentCellElement, width, height, columnIndex, rowIndex, CellValues, isFocused]);

  const setFocus = useCallback(() => {
    if (!setCellState) return;

    if (cellState.focused.columnIndex !== columnIndex || cellState.focused.rowIndex !== rowIndex)
      setCellState(
        produce(cellState, (next) => {
          next.focused.columnIndex = columnIndex;
          next.focused.rowIndex = rowIndex;
        })
      );
  }, [setCellState, columnIndex, rowIndex, cellState]);

  return (
    <div
      className={styles.FixedGridCell}
      onMouseEnter={setFocus}
      onMouseMove={setFocus}
      onMouseOver={setFocus}
      style={style}
    >
      <div className={styles.FixedGridCellInner} style={{ width: width, height: height }}>
        {content}
      </div>
    </div>
  );
};
