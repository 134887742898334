import styles from "./LandingPage.module.css";

interface Props {
  children: React.ReactNode;
}

export const LandingPage = ({ children }: Props) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.container_logo}>
          {/* We apply a random seed to disable caching, because this will prevent the animation */}
          <img
            src={`${process.env.PUBLIC_URL}/assets/logos/Logs-Main-LandingPage.svg?seed=${new Date().getSeconds()}`}
            alt="LOGS"
          />
        </div>
        <div className={styles.container_child}>{children}</div>
      </div>
      <div className={styles.footer}>
        <small>
          © 2015 - 2024 Bruker BioSpin GmbH &amp; Co. KG | <a href="https://logs.sciy.com/legal">Legal Notice</a> |{" "}
          <a href="https://logs.sciy.com/privacy">Privacy Policy</a>
        </small>
      </div>
    </div>
  );
};
