import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useForm, useFormContext, useWatch } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Nullable, StringIndexedDict } from "../../api/GenericTypes";
import { SessionContext } from "../../common/contexts/SessionContext";
import { LucideIcon } from "../../common/icon/LucideIcon";
import { useHistory } from "react-router-dom";
import { getBulkEditRoute } from "../../main/Routing";
import { FormErrors } from "../../common/forms/FormButtons";
import { MultiEditMappedItemObject } from "../../common/forms/MultiEditForms/common/MultiEditProvider";
import {
  checkForUnassignedValue,
  getValueOrDefault,
  useMultiEditUtils,
} from "../../common/forms/MultiEditForms/common/MultiEditUtils";
import { showtoast } from "../../common/overlays/Toasts/showtoast";
import { MultiEditErrorContainer } from "../../common/forms/MultiEditForms/common/MultiEditRenderUtils";
import {
  BulkEditDropDownWrapperProps,
  BulkEditLocationState,
  BulkEditDropDownProps,
} from "../../common/tables/MultiEdit/BulkEditDropdown/BulkEditDropDownTypes";
import { BulkEditDropDownWrapper } from "../../common/tables/MultiEdit/BulkEditDropdown/BulkEditDropDownWrapper";
import { Sample, SampleFieldLabels, SampleFilters } from "../../api/Samples";
import { iso2localDate } from "../../common/datetime/DateTimeFormatter";
import { PersonsVirtualizedSelectForm } from "../../common/forms/EntityForms/formsVirtualized/PersonsVirtualizedSelectForm";
import { ProjectsVirtualizedSelectForm } from "../../common/forms/EntityForms/formsVirtualized/ProjectsVirtualizedSelectForm";
import { SingleDatePickerFormField } from "../../common/formfields/DatePickerFormField/SingleDatePickerFormField";
import { AlertModal } from "../../common/modals/AlertModal/AlertModal";
import { useCustomTypes } from "../../Customization/CustomTypes/generics/useCustomTypes";
import {
  processValuesForTypedEntity,
  useCustomFieldDefaultValues,
} from "../../Customization/CustomTypes/generics/CustomTypeValidationUtils";
import { CustomTypesVirtualizedSelectForm } from "../../common/forms/EntityForms/formsVirtualized/CustomTypesVirtualizedSelectForm";
import { CustomTypeFormRenderer } from "../../Customization/CustomTypes/generics/CustomTypeFormRenderer";
import { Button } from "../../common/buttons/Button/Button";
import { CustomType } from "../../api/CustomTypes";

export const SamplesBulkEditDropDownWrapper = ({
  selection,
  onSuccess,
  onClose,
  filters,
  entityConstants,
}: BulkEditDropDownWrapperProps<Sample, SampleFilters>) => {
  return (
    <BulkEditDropDownWrapper<Sample, SampleFilters>
      selection={selection}
      onSuccess={onSuccess}
      onClose={onClose}
      filters={filters}
      entityConstants={entityConstants}
    >
      {(props) => <SamplesBulkEditDropDown {...props} />}
    </BulkEditDropDownWrapper>
  );
};

const SamplesBulkEditDropDown = ({
  entityConstants,
  items,
  consolidatedValues,
  selection,
  filters,
  onSuccess,
  onClose,
}: BulkEditDropDownProps<Sample, SampleFilters>) => {
  const [showAlertModal, setShowAlertModal] = useState(false);
  const {
    handleSubmit,
    reset,
    formState: { isSubmitting, errors },
  } = useFormContext<MultiEditMappedItemObject<Sample>>();

  const history = useHistory();
  const { route } = useContext(SessionContext);
  // const { schemas, type, setType } = useCustomSchemasDeprecated<SampleType, SampleTypeFilters>({
  //   entityConstants: sampleTypesConstants,
  // });
  const { types } = useCustomTypes({ entityType: "Sample" });
  const [type, setType] = useState<CustomType>();

  const { customFieldPlaceholders, defaultValues, conditionalTypeFormSchema } = useCustomFieldDefaultValues<Sample>(
    items,
    consolidatedValues,
    type
  );

  useEffect(() => {
    if (errors && !!Object.keys(errors).length) {
      showtoast(
        "error",
        <MultiEditErrorContainer errors={errors} entityDescSingular={entityConstants.entitySingular} />
      );
    }
  }, [entityConstants.entitySingular, errors]);

  const { onSubmit, loading } = useMultiEditUtils<Sample>({
    entityConstants: entityConstants,
    showToast: true,
    onSuccessCallback: onSuccess,
  });

  const conditionalSampleFormSchema = useMemo(
    () => ({
      projects: yup.array().when([], () =>
        consolidatedValues.projects?.isEqual === false &&
        consolidatedValues.projects?.value === null &&
        consolidatedValues.projects?.hasEmpty === false
          ? yup.array().nullable().notRequired()
          : yup
              .array()
              .of(
                yup.object().shape({ id: yup.number().required().positive().integer(), name: yup.string().required() })
              )
              .min(1, "At least one project is required")
              .typeError(
                !!consolidatedValues.projects?.hasEmpty
                  ? "At least one item is missing a project"
                  : "At least one project is required"
              )
      ),
      preparedAt: yup.mixed().when([], () =>
        consolidatedValues.preparedAt?.hasEmpty === false
          ? yup.mixed().nullable().notRequired()
          : yup
              .date()
              .required("Preparation date is required")
              .typeError(
                !!consolidatedValues.preparedAt?.hasEmpty
                  ? "At least one item is missing a preparation date"
                  : "Preparation date is required"
              )
      ),
      preparedBy: yup.array().when([], () =>
        consolidatedValues.preparedBy?.isEqual === false &&
        consolidatedValues.preparedBy?.value === null &&
        consolidatedValues.preparedBy?.hasEmpty === false
          ? yup.array().nullable().notRequired()
          : yup
              .array()
              .of(
                yup.object().shape({ id: yup.number().required().positive().integer(), name: yup.string().required() })
              )
              .min(1, "At least one operator is required")
              .typeError(
                !!consolidatedValues.preparedBy?.hasEmpty
                  ? "At least one item is missing an operator"
                  : "At least one operator is required"
              )
      ),
      ...conditionalTypeFormSchema,
    }),
    [consolidatedValues, conditionalTypeFormSchema]
  );

  const {
    register,
    trigger,
    control,
    formState: { errors: errorsExternal, isDirty, dirtyFields },
    handleSubmit: internalSubmit,
    clearErrors,
    reset: resetExternal,
  } = useForm<Partial<Nullable<Sample>>>({
    defaultValues: defaultValues,
    resolver: yupResolver(yup.object().shape(conditionalSampleFormSchema)),
  });

  useEffect(() => {
    resetExternal({ ...defaultValues, customType: type ?? defaultValues.customType }, { keepDirty: true });
  }, [defaultValues, resetExternal, type]);

  const beforeSubmit = useCallback(
    (data: MultiEditMappedItemObject<Sample>, changes: Partial<Nullable<Sample>>) => {
      const currentChanges = {
        ...(changes.customType !== null && { customType: checkForUnassignedValue(changes.customType) }),
        ...(changes.projects !== null && { projects: checkForUnassignedValue(changes.projects) }),
        ...(changes.preparedBy !== null && { preparedBy: checkForUnassignedValue(changes.preparedBy) }),
        ...(changes.preparedAt && { preparedAt: iso2localDate(changes.preparedAt).toISOString() }),
        ...(changes.discardedBy !== null && { discardedBy: checkForUnassignedValue(changes.discardedBy) }),
        ...(changes.discardedAt && { discardedAt: iso2localDate(changes.discardedAt).toISOString() }),
      } as Partial<Sample>;

      const dirtyCustomFields = Object.fromEntries(
        Object.entries(changes.customFields ?? {}).filter(
          ([key, value]) => (dirtyFields?.customFields as StringIndexedDict)?.[key]
        )
      );
      let updates: MultiEditMappedItemObject<Sample> = {};
      for (const [id, entity] of Object.entries(data)) {
        updates[id] = {
          ...entity,
          ...currentChanges,
          ...(currentChanges.customType?.id && {
            ...processValuesForTypedEntity(
              {
                customType: currentChanges.customType?.id ? { id: currentChanges.customType.id } : null,
                customFields: { ...entity.customFields, ...dirtyCustomFields },
              } as Partial<Sample>,
              currentChanges.customType?.id ? types?.[currentChanges.customType.id] : undefined
            ),
          }),
        };
      }
      return updates;
    },
    [dirtyFields?.customFields, types]
  );

  const nChanges = selection.size;
  // const nErrors = Object.keys(errorsExternal).length;
  // const isError = !!nErrors;

  const _type = useWatch({ name: "customType", control: control });
  useEffect(() => {
    if (_type && typeof _type.id === "string" && types && Object.hasOwn(types, _type.id)) {
      setType(types[_type.id]);
    } else {
      // Clear custom field errors
      clearErrors("customFields");
    }
  }, [_type, clearErrors, types, setType]);

  return (
    <div
      className={`flex col-nowrap align-center`}
      style={{ width: "50vw", height: "fit-content", padding: "10px 15px", overflow: "auto" }}
    >
      <h3>Edit Metadata</h3>
      <div className="form form-group form-horizontal" style={{ width: "100%", height: "fit-content" }}>
        <ProjectsVirtualizedSelectForm
          id={"projects"}
          control={control}
          placeholder={getValueOrDefault(consolidatedValues?.projects)}
          horizontal
          isMulti
          allowCreateEntity
          allowUnassigned
          // unassignOnX
          filters={{ currentUserHasAddPermission: true }}
        />

        <PersonsVirtualizedSelectForm
          id={"preparedBy"}
          label={SampleFieldLabels.preparedBy}
          control={control}
          placeholder={getValueOrDefault(consolidatedValues?.preparedBy)}
          horizontal
          isMulti
          allowCreateEntity
          allowUnassigned
          // unassignOnX
        />

        <SingleDatePickerFormField
          type="date"
          id={"preparedAt"}
          label={SampleFieldLabels.preparedAt}
          placeholder={getValueOrDefault(consolidatedValues?.preparedAt)}
          control={control}
          required={true}
          errors={errorsExternal}
          horizontal
        />

        <PersonsVirtualizedSelectForm
          id={"discardedBy"}
          label={SampleFieldLabels.discardedBy}
          placeholder={getValueOrDefault(consolidatedValues?.discardedBy)}
          control={control}
          allowCreateEntity
          isMulti
          horizontal
        />

        <SingleDatePickerFormField
          type="date"
          id={"discardedAt"}
          label={SampleFieldLabels.discardedAt}
          placeholder={getValueOrDefault(consolidatedValues?.discardedAt)}
          control={control}
          required={false}
          errors={errors}
          horizontal
        />

        <CustomTypesVirtualizedSelectForm
          id={"customType"}
          control={control}
          placeholder={getValueOrDefault(consolidatedValues?.customType)}
          horizontal
          allowUnassigned
          disabled={
            !(
              consolidatedValues.customType === undefined ||
              (consolidatedValues.customType.value === null && consolidatedValues.customType.isEqual)
            )
          }
          filters={{ entityTypes: ["Sample"] }}
        />

        {_type && types ? (
          // <CustomFieldsFormRenderer
          //   customSchema={schemas[_type.id]}
          //   register={register}
          //   control={control}
          //   errors={errorsExternal}
          //   placeholders={customFieldPlaceholders}
          // />
          <CustomTypeFormRenderer
            customType={types[_type.id]}
            register={register}
            control={control as any}
            errors={errorsExternal}
            placeholders={customFieldPlaceholders}
          />
        ) : null}
      </div>
      <FormErrors errors={errorsExternal} />

      <AlertModal
        type={`primary`}
        showModal={showAlertModal}
        setShowModal={setShowAlertModal}
        title={`Apply changes to ${selection.size} ${
          selection.size === 1 ? entityConstants.entitySingular : entityConstants.entityPlural
        }?`}
        // description={`Changing the ${samplesConstants.entitySingular} type can lead to data loss if the selected type contains different custom fields.`}
        proceedLabel={"Apply changes"}
        onProceed={async () => {
          setShowAlertModal(false);
          await internalSubmit(async (changes) => {
            await handleSubmit(async (data) => await onSubmit(beforeSubmit(data, changes), nChanges, reset))();
          })();
        }}
        onCancel={() => {
          setShowAlertModal(false);
          onClose?.();
        }}
        loading={loading}
      />

      <div className="flex row-nowrap align-center gap-5" style={{ width: "100%" }}>
        <div>
          <button
            className="btn btn-primary"
            onClick={() => {
              history.push({
                pathname: route(getBulkEditRoute(entityConstants.frontendIndexRoute)),
                state: {
                  filters: { ...filters, ids: Array.from(selection) },
                  items: items,
                } as BulkEditLocationState<Sample, SampleFilters>,
              });
            }}
            disabled={isSubmitting}
          >
            <LucideIcon name="table" /> Advanced edit
          </button>
        </div>
        <div className="flex row-nowrap align-center gap-5" style={{ marginLeft: "auto" }}>
          <button className="btn btn-default" title="Close" onClick={onClose} disabled={isSubmitting}>
            Close
          </button>

          <Button
            className={`btn btn-primary ${isSubmitting ? "loading" : ""}`}
            title={`Apply changes to all selected ${
              selection.size === 1 ? entityConstants.entitySingular : entityConstants.entityPlural
            }`}
            onClick={async () => {
              if (await trigger()) setShowAlertModal(true);
            }}
            disabled={!isDirty}
            loading={isSubmitting}
          >
            {`${isSubmitting ? "Processing..." : "Edit"}`}{" "}
            {`${selection.size} ${
              selection.size === 1 ? entityConstants.entitySingular : entityConstants.entityPlural
            }`}
          </Button>
        </div>
      </div>
    </div>
  );
};
