import { Plugin, EditorState } from "prosemirror-state";
import { EditorView } from "prosemirror-view";
import { dropPoint } from "prosemirror-transform";

interface DropCursorOptions {
  /// The color of the cursor. Defaults to `black`.
  color?: string;

  /// The precise width of the cursor in pixels. Defaults to 1.
  width?: number;

  /// A CSS class name to add to the cursor element.
  class?: string;
}

export const DropCursor = new Plugin({
  view(editorView) {
    return new DropCursorView(editorView, {} as DropCursorOptions);
  },
});

class DropCursorView {
  width: number;
  color: string;
  class: string | undefined;
  cursorPos: number | null = null;
  element: HTMLElement | null = null;
  timeout: NodeJS.Timeout | undefined;
  handlers: { name: string; handler: (event: Event) => void }[];

  constructor(readonly editorView: EditorView, options: DropCursorOptions) {
    this.width = options.width || 1;
    this.color = options.color || "black";
    this.class = options.class;

    this.handlers = ["dragover", "dragend", "drop", "dragleave"].map((name) => {
      let handler = (e: Event) => {
        (this as any)[name](e);
      };
      editorView.dom.addEventListener(name, handler);
      return { name, handler };
    });
  }

  destroy() {
    this.handlers.forEach(({ name, handler }) => this.editorView.dom.removeEventListener(name, handler));
  }

  update(editorView: EditorView, prevState: EditorState) {
    if (this.cursorPos !== null && prevState.doc !== editorView.state.doc) {
      if (this.cursorPos > editorView.state.doc.content.size) this.setCursor(null);
      else this.updateOverlay();
    }
  }

  setCursor(pos: number | null) {
    if (pos === this.cursorPos) return;
    this.cursorPos = pos;
    if (pos === null) {
      this.element!.parentNode!.removeChild(this.element!);
      this.element = null;
    } else {
      this.updateOverlay();
    }
  }

  updateOverlay() {
    try {
      let $pos = this.editorView.state.doc.resolve(this.cursorPos!),
        rect;
      if (!$pos.parent.inlineContent) {
        let before = $pos.nodeBefore,
          after = $pos.nodeAfter;
        if (before || after) {
          let node = this.editorView.nodeDOM(this.cursorPos! - (before ? before.nodeSize : 0));
          if (node) {
            let nodeRect = (node as HTMLElement).getBoundingClientRect();
            let top = before ? nodeRect.bottom : nodeRect.top;
            if (before && after)
              top = (top + (this.editorView.nodeDOM(this.cursorPos!) as HTMLElement)?.getBoundingClientRect().top) / 2;
            rect = {
              left: nodeRect.left,
              right: nodeRect.right,
              top: top - this.width / 2,
              bottom: top + this.width / 2,
            };
          }
        }
      }
      if (!rect) {
        let coords = this.editorView.coordsAtPos(this.cursorPos!);
        rect = {
          left: coords.left - this.width / 2,
          right: coords.left + this.width / 2,
          top: coords.top,
          bottom: coords.bottom,
        };
      }

      let parent = this.editorView.dom.offsetParent!;
      if (!this.element) {
        this.element = parent.appendChild(document.createElement("div"));
        if (this.class) this.element.className = this.class;
        this.element.style.cssText =
          "position: absolute; z-index: 50; pointer-events: none; background-color: " + this.color;
      }
      let parentLeft, parentTop;
      if (!parent || (parent === document.body && getComputedStyle(parent).position === "static")) {
        // eslint-disable-next-line no-restricted-globals
        parentLeft = -scrollX;
        // eslint-disable-next-line no-restricted-globals
        parentTop = -scrollY;
      } else {
        let rect = parent.getBoundingClientRect();
        parentLeft = rect.left - parent.scrollLeft;
        parentTop = rect.top - parent.scrollTop;
      }
      this.element.style.left = rect.left - parentLeft + "px";
      this.element.style.top = rect.top - parentTop + "px";
      this.element.style.width = rect.right - rect.left + "px";
      this.element.style.height = rect.bottom - rect.top + "px";
    } catch (error) {
      console.error(error);
    }
  }

  scheduleRemoval(timeout: number) {
    clearTimeout(this.timeout as any);
    this.timeout = setTimeout(() => this.setCursor(null), timeout);
  }

  dragover(event: DragEvent) {
    if (!this.editorView.editable) return;
    let pos = this.editorView.posAtCoords({ left: event.clientX, top: event.clientY });

    let node = pos && pos.inside >= 0 && this.editorView.state.doc.nodeAt(pos.inside);
    let disableDropCursor = node && node.type.spec.disableDropCursor;
    let disabled =
      typeof disableDropCursor === "function" ? disableDropCursor(this.editorView, pos, event) : disableDropCursor;

    if (pos && !disabled) {
      let target: number | null = pos.pos;
      if (this.editorView.dragging && this.editorView.dragging.slice) {
        target = dropPoint(this.editorView.state.doc, target, this.editorView.dragging.slice);
        if (target === null) return this.setCursor(null);
      }
      this.setCursor(target);
      this.scheduleRemoval(1000);
    }
  }

  dragend() {
    this.scheduleRemoval(20);
  }

  drop() {
    this.scheduleRemoval(20);
  }

  dragleave(event: DragEvent) {
    if (event.target === this.editorView.dom || !this.editorView.dom.contains((event as any).relatedTarget))
      this.setCursor(null);
  }
}
