import {
  ICreatedRecordParametersTranslator,
  ICreationRecordLabels,
  IGenericRequestParametersTranslator,
  IModificationRecordLabels,
  IModifiedRecordParametersTranslator,
  INamedEntityLabels,
  IPaginationParametersTranslator,
  IPermissionedEntityLabels,
  IRelatedEntityLabels,
  IRelationParametersTranslator,
  PropertyTranslator,
} from "./GenericTranslator";
import {
  IGenericRequestParameters,
  IModelWithIntId,
  INamedEntity,
  IPaginationParameters,
  IPermissionedEntity,
  EntityConstants,
  ICreationRecord,
  IModificationRecord,
  ICreatedRecordParameters,
  IModifiedRecordParameters,
  DefaultCreatedByRecordOrderTypeConsts,
  DefaultCreatedOnRecordOrderTypeConsts,
  DefaultModifiedByRecordOrderTypeConsts,
  DefaultModifiedOnRecordOrderTypeConsts,
  DefaultOrderTypeConsts,
  IRelation,
  IRelationModel,
  IRelatedEntity,
  IRelationParameters,
  IEntityMinimalModel,
} from "./GenericTypes";
import { Permission } from "./Permission";

export const rolesConstants: EntityConstants<Role, RoleFilters> = {
  resource: "roles",
  frontendIndexRoute: "roles",
  entitySingular: "role",
  entityPlural: "roles",
  icon: "users",
};

export const RoleFieldLabels: PropertyTranslator<Role> = {
  ...INamedEntityLabels,
  ...IPermissionedEntityLabels,
  ...IRelatedEntityLabels,
  ...ICreationRecordLabels,
  ...IModificationRecordLabels,
  id: "Role ID",
  description: "Description",
  isInternal: "Internal",
  isDefault: "Default",
  rolePermissions: "Roles",
} as const;

export interface Role
  extends IModelWithIntId,
    IPermissionedEntity,
    INamedEntity,
    ICreationRecord,
    IModificationRecord,
    IRelatedEntity<RoleRelations> {
  description: string;
  isInternal: boolean;
  isDefault: boolean;
  rolePermissions: Permission[];
}
export interface RoleSuggestions extends IEntityMinimalModel<Role["id"]> {}

export interface RoleRelations extends IRelationModel {
  persons: IRelation;
}
export interface RoleFilters
  extends IGenericRequestParameters<Role, RoleSortingOptions>,
    IRelationParameters,
    IPaginationParameters,
    ICreatedRecordParameters,
    IModifiedRecordParameters {
  includeInternals?: boolean | null;
}
export const RoleFiltersTranslator: PropertyTranslator<RoleFilters> = {
  ...IGenericRequestParametersTranslator,
  ...IRelationParametersTranslator,
  ...IPaginationParametersTranslator,

  ...ICreatedRecordParametersTranslator,
  ...IModifiedRecordParametersTranslator,
  includeInternals: "Include internal roles",
} as const;

export const RoleSortingOptionsConsts = [
  ...DefaultOrderTypeConsts,
  ...DefaultCreatedOnRecordOrderTypeConsts,
  ...DefaultCreatedByRecordOrderTypeConsts,
  ...DefaultModifiedOnRecordOrderTypeConsts,
  ...DefaultModifiedByRecordOrderTypeConsts,
  "IS_INTERNAL_ASC",
  "IS_INTERNAL_DESC",
] as const;
export type RoleSortingOptions = (typeof RoleSortingOptionsConsts)[number];
