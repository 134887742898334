import { GetMarkRange, NodeWithPosition, ProsemirrorAttributes } from "remirror";

export const toggleContentPlaceholderOptions: Remirror.CommandDecoratorOptions = {};

export interface ContentPlaceholderOptions {
  onClickLegacy?: (e: MouseEvent, nodeWithPosition: GetMarkRange) => void;
  onClick?: (e: MouseEvent, nodeWithPosition: NodeWithPosition) => void;
}

export interface ContentPlaceholderAttributes extends ProsemirrorAttributes {
  id?: string;
  label: string;
}
