import React from "react";
import styles from "./IconRenderer.module.css";
import { LucideIcon } from "./LucideIcon";

interface IconRendererProps extends React.SVGProps<SVGSVGElement> {
  // vendors: string[];
  src: React.ImgHTMLAttributes<HTMLImageElement>["src"] | null;
  style?: React.CSSProperties;
}

export const IconRenderer = ({ src, style }: IconRendererProps) => {
  return (
    <div className={styles.image_container}>
      {src ? <img src={src} alt="" className={styles.icon} style={style} /> : <LucideIcon name="image-off" />}
    </div>
  );
};
