import { StackTraces } from "./../StackTrace";
import { generateUid } from "../../tools/UID/UID";
import {
  PipelineCommand,
  commandTypes,
  trackListType,
  SerializedPipelineCommand,
  argumentType,
  CommandParameter,
  parameterSettingType,
} from "../PipelineTypes";

export class Empty implements PipelineCommand {
  id: string;
  name: string;
  type = commandTypes.empty;
  errors: StackTraces;
  warnings: StackTraces;

  // readonly input = [];
  // readonly output = [];
  readonly input: argumentType[];
  readonly output: argumentType[];
  parameterTypes = {};
  parameterSettings: Record<string, parameterSettingType>;
  internalParameter: Record<string, CommandParameter>;

  updatedParameter?: Record<string, any>;

  constructor() {
    this.id = generateUid();
    this.name = "Empty";
    this.errors = new StackTraces();
    this.warnings = new StackTraces();
    this.internalParameter = {};
    this.parameterSettings = {};
    this.input = [];
    this.output = [];
  }

  reset() {
    this.errors = new StackTraces();
    this.warnings = new StackTraces();
  }

  // getParams(): Record<string, any> {
  //   return Object.fromEntries(Object.entries(this.parameter).map(([k, v]) => [k, v.value]));
  // }

  get parameter(): Record<string, any> {
    // return Object.fromEntries(Object.entries(this.internalParameter).map(([k, v]) => [k, v.value]));

    return Object.fromEntries(
      Object.entries(this.internalParameter).map(([k, v]) => {
        // console.log(">>", v.value);
        return [k, v.value];
      })
    );
  }

  set parameter(value: Record<string, any>) {
    this.updatedParameter = value;
  }

  run(tracks: trackListType): trackListType {
    // return parameter?.trackList.value(tracks) ?? [];
    return tracks;
  }

  deserializeParameters(parameter: Record<string, any>) {
    Object.entries(this.internalParameter).forEach(([k, v]) => {
      // console.log("------", this.name, "-------------");

      const s = (v as CommandParameter).deserialize(parameter);

      // if (this.parameterSettings?.[k]) {
      //   Object.entries(this.parameterSettings[k]).forEach(([k, v]) => {
      //     // console.log("settings", k + ":", v, "<-", s?.[k] === undefined);
      //     if (s?.[k] === undefined) s[k] = v;
      //   });
      //   // console.log("settings", s);
      // } // s.type = v.type;
      return [k, s];
    });

    // return Object.fromEntries(parameter);
  }

  serializeParameters(): Record<string, any> {
    const parameter = Object.entries(this.internalParameter).map(([k, v]) => {
      // console.log("------", this.name, "-------------");

      const s = (v as CommandParameter).serialize(true);

      // if (this.parameterSettings?.[k]) {
      //   Object.entries(this.parameterSettings[k]).forEach(([k, v]) => {
      //     // console.log("settings", k + ":", v, "<-", s?.[k] === undefined);
      //     if (s?.[k] === undefined) s[k] = v;
      //   });
      //   // console.log("settings", s);
      // } // s.type = v.type;
      return [k, s];
    });

    return Object.fromEntries(parameter);
  }

  serialize(): SerializedPipelineCommand {
    const result: SerializedPipelineCommand = { command: this.type, id: this.id, name: this.name };

    // if (minimizedSettings) console.log("NAME", this.name, "->", Object.keys(this.internalParameter));

    const parameter = Object.entries(this.internalParameter)
      .map(([k, v]) => {
        // console.log("------", this.name, "-------------");

        const s = (v as CommandParameter).serialize();

        // if (this.parameterSettings?.[k]) {
        //   Object.entries(this.parameterSettings[k]).forEach(([k, v]) => {
        //     // console.log("settings", k + ":", v, "<-", s?.[k] === undefined);
        //     if (s?.[k] === undefined) s[k] = v;
        //   });
        //   // console.log("settings", s);
        // } // s.type = v.type;
        return [k, s];
      })
      .filter(([k, v]) => v !== undefined);

    if (parameter.length > 0) result.parameter = Object.fromEntries(parameter);

    if (this.name) result.name = this.name;

    return result;
  }
}
