/**
 * @module DurationPickerFormField
 * @description A form field component for selecting durations.
 * @author @CorradoSurmanowicz
 */

import React, { useCallback } from "react";
import { GenericControllerProvider } from "../../forms/common/GenericControllerProvider";
import { Control, DeepMap, FieldError, Path, RefCallBack } from "react-hook-form";
import { StringIndexedDict } from "../../../api/GenericTypes";
import { FormFieldError, FormFieldLayout } from "../FormFieldLayouts";
import { LucideIcon } from "../../icon/LucideIcon";
import styles from "./DurationPickerFormField.module.css";
import { timeSpanToTotalSeconds, totalSecondsToTimeSpan } from "../../datetime/DateTimeFormatter";
/**
 * Represents a duration with optional days, hours, minutes, seconds, and milliseconds.
 */
export interface TimeSpan {
  Days?: number;
  Hours?: number;
  Minutes?: number;
  Seconds?: number;
  Milliseconds?: number;
}

/**
 * Props for the DurationPickerFormField component.
 */
interface DurationPickerFormFieldProps<Entity extends StringIndexedDict> {
  id: Path<Entity>;
  label: string;
  control: Control<Entity>;
  errors?: DeepMap<any, FieldError>;
  defaultValue?: string;
  required?: boolean;
  placeholder?: string;
  disabled?: boolean;
  horizontal?: boolean;
  uncontained?: boolean;
  style?: React.CSSProperties;
  children?: React.ReactNode;
}

/**
 * A form field component for selecting durations.
 * @param id - The ID of the form field.
 * @param label - The label of the form field.
 * @param control - The control object from react-hook-form.
 * @param errors - The errors object from react-hook-form.
 * @param defaultValue - The default value of the form field.
 * @param required - Whether the form field is required.
 * @param placeholder - The placeholder text of the form field.
 * @param disabled - Whether the form field is disabled.
 * @param horizontal - Whether to display the form field horizontally.
 * @param uncontained - Whether to display the form field without a container.
 * @param style - The style object for the form field.
 * @param children - The child components of the form field.
 * @returns The DurationPickerFormField component.
 */
export const DurationPickerFormField = <Entity extends StringIndexedDict>({
  id,
  label,
  control,
  errors,
  defaultValue,
  required,
  placeholder,
  disabled,
  horizontal,
  uncontained,
  style,
  children,
}: DurationPickerFormFieldProps<Entity>) => {
  return (
    <GenericControllerProvider id={id} control={control} defaultValue={defaultValue}>
      {({ value, onChange, ref }) => (
        <>
          {uncontained ? (
            <div className="flex row-nowrap align-center gap-5" style={{ width: "100%", height: "100%", ...style }}>
              <DurationPickerCore
                value={value}
                onChange={onChange}
                placeholder={placeholder}
                refCallback={ref}
                disabled={disabled}
              />
              {children}
              <FormFieldError id={id} errors={errors} />
            </div>
          ) : (
            <FormFieldLayout
              id={id}
              label={label}
              required={required}
              horizontal={horizontal}
              hasLabel={!!label && label !== ""}
            >
              <div className="flex row-nowrap" style={{ alignItems: "stretch", ...style }}>
                <DurationPickerCore
                  value={value}
                  onChange={onChange}
                  placeholder={placeholder}
                  refCallback={ref}
                  disabled={disabled}
                />
                <div>{children}</div>
              </div>
              <FormFieldError id={id} errors={errors} />
            </FormFieldLayout>
          )}
        </>
      )}
    </GenericControllerProvider>
  );
};

/**
 * Props for the DurationPickerCore component.
 */
interface DurationPickerCoreProps {
  value?: number | null;
  onChange: (...event: any[]) => void;
  refCallback: RefCallBack;
  placeholder?: string;
  disabled?: boolean;
}

/**
 * The core component of the DurationPickerFormField.
 * @param value - The value of the duration.
 * @param onChange - The change event handler.
 * @param placeholder - The placeholder text of the form field.
 * @param refCallback - The ref callback function.
 * @param disabled - Whether the duration picker is disabled.
 * @returns The DurationPickerCore component.
 */
const DurationPickerCore = ({ value, onChange, refCallback, placeholder, disabled }: DurationPickerCoreProps) => {
  const timeSpanObject = totalSecondsToTimeSpan(value);

  /**
   * Handles the change event of the input fields.
   * @param e - The change event.
   * @param maxValue - The maximum value allowed for the input field.
   */
  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, maxValue?: number) => {
      const { name, value: _currentValue } = e.target;
      let _value = _currentValue ? +_currentValue : 0;
      if (maxValue && +_currentValue > maxValue) _value = maxValue;
      const newValue = { ...timeSpanObject, [name]: _value };
      onChange(timeSpanToTotalSeconds(newValue));
    },
    [onChange, timeSpanObject]
  );

  return (
    <div className={`${styles.durationPickerFormField}`}>
      <div className="flex row-nowrap align-center gap-5">
        <div
          style={{
            display: "grid",
            gridAutoFlow: "dense",
            gridTemplateColumns: "repeat(auto-fill, minmax(100px, 1fr))",
            width: "100%",
            gap: "5px",
          }}
          ref={refCallback}
        >
          {/* <input type="hidden" value={value || ""} ref={refCallback} /> */}
          <div className="flex col-nowrap" style={{ minWidth: 60 }}>
            <span style={{ color: "gray" }}>Days</span>
            <input
              name="Days"
              type="number"
              className="form form-control"
              placeholder="0"
              value={timeSpanObject?.Days ?? ""}
              onChange={(e) => handleChange(e)}
              min="0"
              disabled={disabled}
            />
          </div>
          <div className="flex col-nowrap" style={{ minWidth: 60 }}>
            <span style={{ color: "gray" }}>Hours</span>
            <input
              name="Hours"
              type="number"
              className="form form-control"
              placeholder="0"
              value={timeSpanObject?.Hours ?? ""}
              onChange={(e) => handleChange(e, 23)}
              min="0"
              max="23"
              disabled={disabled}
            />
          </div>

          <div className="flex col-nowrap" style={{ minWidth: 60 }}>
            <span style={{ color: "gray" }}>Minutes</span>
            <input
              name="Minutes"
              type="number"
              className="form form-control"
              placeholder="0"
              value={timeSpanObject?.Minutes ?? ""}
              onChange={(e) => handleChange(e, 59)}
              min="0"
              max="59"
              disabled={disabled}
            />
          </div>

          <div className="flex col-nowrap" style={{ minWidth: 60 }}>
            <span style={{ color: "gray" }}>Seconds</span>
            <input
              name="Seconds"
              type="number"
              className="form form-control"
              placeholder="0"
              value={timeSpanObject?.Seconds ?? ""}
              onChange={(e) => handleChange(e, 59)}
              min="0"
              max="59"
              disabled={disabled}
            />
          </div>

          <div className="flex col-nowrap" style={{ minWidth: 60 }}>
            <span style={{ color: "gray" }}>Milliseconds</span>
            <input
              name="Milliseconds"
              type="number"
              className="form form-control"
              placeholder="0"
              value={timeSpanObject?.Milliseconds ?? ""}
              onChange={(e) => handleChange(e, 999)}
              min="0"
              max="999"
              disabled={disabled}
            />
          </div>
        </div>
        {!disabled && (
          <div
            className="flex col-nowrap"
            style={{ width: "min-content", height: "100%", borderLeft: "1px solid var(--gray-300)" }}
          >
            <div className={styles.clearXbtn} title="Clear" onClick={() => onChange(null)}>
              <LucideIcon name="x" strokeWidth={3} />
            </div>
          </div>
        )}
      </div>
      {placeholder && <span style={{ color: "var(--gray-400)" }}>{placeholder}</span>}
    </div>
  );
};
