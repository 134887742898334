import { LucideIcon } from "../../../common/icon/LucideIcon";
import { LabNotebookExperimentsTile } from "../../common/tiles/LabNotebookExperimentsTile";
import { LabNotebooksTile } from "../../common/tiles/LabNotebooksTile";
import { LabNotebookEntriesVirtualized } from "./LabNotebookEntriesVirtualized/LabNotebookEntriesVirtualized";
import styles from "./RecentActivities.module.css";

export const RecentActivities = () => {
  return (
    <div className={styles.RecentActivitiesContainer}>
      <LabNotebooksTile
        title="Recent notebooks"
        showAddButton
        showTableButton
        openNewTab={false}
        pageSize={3}
        iconOverride={(e) => <LucideIcon name={e?.status !== "CLOSED" ? "book-open" : "book"} color="var(--primary)" />}
        useAddModal
        foldable={false}
        rowTitle={(row) => row.description}
      />
      <LabNotebookExperimentsTile
        title="Recent experiments"
        showAddButton
        showTableButton
        openNewTab={false}
        pageSize={3}
        iconOverride={(e) => <LucideIcon name="file-text" color="var(--gray-600)" />}
        useAddModal
        foldable={false}
        rowTitle={(row) => row.description}
      />

      <div className={styles.LabNoteBookEntriesVirtualized}>
        <LabNotebookEntriesVirtualized />
      </div>
    </div>
  );
};
