import React, { useContext, useEffect, useState, useMemo, useCallback } from "react";
import { SessionContext } from "../common/contexts/SessionContext";
import { useDebouncedValue } from "../common/helperfunctions/useDebouncedValue";
import { ColumnsSettings } from "../common/tables/ColumnsSelector/ColumnsSelector";
import { LucideIcon } from "../common/icon/LucideIcon";
import styles from "../common/tables/GenericVirtualizedTable/commons.module.css";
import { getDatasetsZip } from "../Dataset/common/DownloadDataset/DownloadDataset";
import { showtoast } from "../common/overlays/Toasts/showtoast";
import TableView from "../common/panels/TableView/TableView";
import { SearchInput } from "../common/forms/SearchInput/SearchInput";
import { SharedBundlesFilterBar } from "./SharedBundlesFilterBar";
import { DateTimeRenderer } from "../common/datetime/DateTimeFormatter";
import { DatasetFilters, Dataset, datasetsConstants, DatasetFieldLabels } from "../api/Datasets";
import { useHistory, useParams } from "react-router-dom";
import { useIdsOnly, usePost } from "../api/BaseEntityApi";
import { SharedContentPublicModel, SharedContentPublicModelRequest } from "../api/SharedContent";
import { LoadingWrapper } from "../common/LoadingWrapper";
import { Alert } from "../common/overlays/Alert/Alert";
import { EntityTable } from "../common/tables/EntityTable/EntityTable";
import {
  GenericVirtualizedTableCells,
  SortState,
} from "../common/tables/GenericVirtualizedTable/GenericVirtualizedTableTypes";
import { NotSet } from "../common/misc/UIconstants";
import { GetPersons, TableArrayRenderer } from "../common/misc/EntityRenders/EntityRenderer";
import { switchDatasetsDefaultSortState } from "../Dataset/Table/DatasetsTable";
import { useGenericVirtualizedTable } from "../common/tables/GenericVirtualizedTable/useGenericVirtualizedTable";

const columnSetting: ColumnsSettings<Dataset> = {
  "default-id": { pos: 0, active: false, header: "Dataset-ID", property: "id" },
  "default-name": { pos: 1, active: true, header: "Name", property: "name" },
  "default-sample": { pos: 2, active: true, header: "Sample", property: "sample" },
  "default-acqdate": { pos: 3, active: true, header: "Acquisition date", property: "acquisitionDate" },
  "default-createdOn": { pos: 4, active: false, header: DatasetFieldLabels.createdOn, property: "createdOn" },
  "default-createdBy": { pos: 5, active: false, header: DatasetFieldLabels.createdBy, property: "createdBy" },
  "default-modifiedOn": { pos: 6, active: false, header: DatasetFieldLabels.modifiedOn, property: "modifiedOn" },
  "default-modifiedBy": { pos: 7, active: false, header: DatasetFieldLabels.modifiedBy, property: "modifiedBy" },
  "default-projects": { pos: 8, active: true, header: "Projects", property: "projects" },
  "default-organizations": { pos: 9, active: true, header: "Organizations", property: "organizations" },
  "default-operators": { pos: 10, active: true, header: "Operators", property: "operators" },
  "default-method": { pos: 11, active: true, header: "Method", property: "method" },
  "default-experiment": { pos: 12, active: true, header: "Experiment", property: "experiment" },
  "default-instrument": { pos: 13, active: true, header: "Instrument", property: "instrument" },
  "default-eq": { pos: 14, active: true, header: "Suppl. Equipment", property: "equipments" },
  "default-notes": { pos: 15, active: true, header: "Notes", property: "notes" },
};

export const SharedBundlesView = () => {
  const history = useHistory();
  const { api, route } = useContext(SessionContext);

  const { selection, resultsCount, onCountChange, onSelectionChange } = useGenericVirtualizedTable<Dataset>();

  const [filters, setFilters] = useState<DatasetFilters>({ orderBy: "ACQUISITION_DATE_DESC" });
  const [searchValue, setSearchValue] = useState("");
  const debouncedSearchValue = useDebouncedValue(searchValue);
  const [sort, setSort] = useState<SortState<DatasetFilters["orderBy"]>>(
    switchDatasetsDefaultSortState(filters.orderBy)
  );

  useEffect(() => {
    setFilters((prev) => ({ ...prev, searchTerm: debouncedSearchValue }));
  }, [debouncedSearchValue]);

  const columns = useMemo(() => {
    return [
      {
        id: "default-id",
        Header: "Dataset-ID",
        accessor: (row) => (
          <span style={{ color: "var(--gray-400)" }}>
            <samp>{row.id}</samp>
          </span>
        ),
        width: 135,
        minWidth: 135,
        align: "right",
        sortingFn: () => {
          if (sort.headerId === "default-id") {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC" }));
              setFilters((prev) => ({ ...prev, orderBy: "ID_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC" }));
              setFilters((prev) => ({ ...prev, orderBy: "ID_ASC" }));
            }
          } else {
            setSort({ headerId: "default-id", sortDirection: "ASC" });
            setFilters((prev) => ({ ...prev, orderBy: "ID_ASC" }));
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
      {
        id: "default-name",
        Header: "Name",
        accessor: (row) => (
          <div style={{ display: "flex", flexFlow: "row nowrap", gap: "5px", overflow: "hidden" }}>
            <div style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
              <span style={{ fontWeight: 500, whiteSpace: "nowrap", marginRight: "5px" }}>
                <LucideIcon name="activity" color={"var(--primary)"} /> {row.name}
              </span>
            </div>
            {row.isDeleted && (
              <div>
                <label className="label label-soft-warning">Trashed</label>
              </div>
            )}
          </div>
        ),
        minWidth: 150,
        width: 250,
        align: "left",
        sortingFn: () => {
          if (sort.headerId === "default-name") {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC" }));
              setFilters((prev) => ({ ...prev, orderBy: "NAME_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC" }));
              setFilters((prev) => ({ ...prev, orderBy: "NAME_ASC" }));
            }
          } else {
            setSort({ headerId: "default-name", sortDirection: "ASC" });
            setFilters((prev) => ({ ...prev, orderBy: "NAME_ASC" }));
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
      {
        id: "default-sample",
        Header: "Sample",
        accessor: (row) => (
          <div className={styles.container_ellipsis}>
            {row.sample ? (
              <span style={{ fontWeight: 500, whiteSpace: "nowrap", marginRight: "5px" }}>
                <LucideIcon name="box" color={"var(--primary)"} /> {row.sample.name}
              </span>
            ) : (
              NotSet
            )}
          </div>
        ),
        minWidth: 150,
        width: 200,
        align: "left",
        sortingFn: () => {
          if (sort.headerId === "default-sample") {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC" }));
              setFilters((prev) => ({ ...prev, orderBy: "SAMPLE_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC" }));
              setFilters((prev) => ({ ...prev, orderBy: "SAMPLE_ASC" }));
            }
          } else {
            setSort({ headerId: "default-sample", sortDirection: "ASC" });
            setFilters((prev) => ({ ...prev, orderBy: "SAMPLE_ASC" }));
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
      {
        id: "default-acqdate",
        Header: "Acquisition date",
        accessor: (row) => <DateTimeRenderer date={row.acquisitionDate} includeElapsed={false} />,
        width: 170,
        minWidth: 170,
        align: "left",
        sortingFn: () => {
          if (sort.headerId === "default-acqdate") {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC" }));
              setFilters((prev) => ({ ...prev, orderBy: "ACQUISITION_DATE_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC" }));
              setFilters((prev) => ({ ...prev, orderBy: "ACQUISITION_DATE_ASC" }));
            }
          } else {
            setSort({ headerId: "default-acqdate", sortDirection: "ASC" });
            setFilters((prev) => ({ ...prev, orderBy: "ACQUISITION_DATE_ASC" }));
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
      {
        id: "default-createdOn",
        Header: "Created on",
        accessor: (row) => <DateTimeRenderer date={row.createdOn} includeElapsed={false} />,
        width: 200,
        align: "left",
        sortingFn: () => {
          if (sort.headerId === "default-createdOn") {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "CREATED_ON_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "CREATED_ON_ASC" }));
            }
          } else {
            setSort({ headerId: "default-createdOn", sortDirection: "ASC", orderBy: "CREATED_ON_ASC" });
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
      {
        id: "default-createdBy",
        Header: "Created by",
        accessor: (row) => <GetPersons persons={row.createdBy} createLinks={false} />,
        width: 200,
        align: "left",
        sortingFn: () => {
          if (sort.headerId === "default-createdBy") {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "CREATED_BY_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "CREATED_BY_ASC" }));
            }
          } else {
            setSort({ headerId: "default-createdBy", sortDirection: "ASC", orderBy: "CREATED_BY_ASC" });
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
      {
        id: "default-modifiedOn",
        Header: "Last Modified on",
        accessor: (row) => <DateTimeRenderer date={row.modifiedOn} includeElapsed={false} />,
        width: 200,
        align: "left",
        sortingFn: () => {
          if (sort.headerId === "default-modifiedOn") {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "MODIFIED_ON_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "MODIFIED_ON_ASC" }));
            }
          } else {
            setSort({ headerId: "default-modifiedOn", sortDirection: "ASC", orderBy: "MODIFIED_ON_ASC" });
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
      {
        id: "default-modifiedBy",
        Header: "Last Modified by",
        accessor: (row) => <GetPersons persons={row.modifiedBy} createLinks={false} />,
        width: 200,
        align: "left",
        sortingFn: () => {
          if (sort.headerId === "default-modifiedBy") {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "MODIFIED_BY_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "MODIFIED_BY_ASC" }));
            }
          } else {
            setSort({ headerId: "default-modifiedBy", sortDirection: "ASC", orderBy: "MODIFIED_BY_ASC" });
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
      {
        id: "default-projects",
        Header: "Projects",
        accessor: (row) => <TableArrayRenderer values={row.projects}>{(value) => value.name}</TableArrayRenderer>,
        width: 200,
        align: "left",
      },
      {
        id: "default-organizations",
        Header: "Organizations",
        accessor: (row) => <TableArrayRenderer values={row.organizations}>{(value) => value.name}</TableArrayRenderer>,
        width: 200,
        align: "left",
      },
      {
        id: "default-operators",
        Header: "Operators",
        accessor: (row) => (
          <TableArrayRenderer values={row.operators}>
            {(value) => <GetPersons persons={value} createLinks={false} />}
          </TableArrayRenderer>
        ),
        width: 200,
        align: "left",
      },
      {
        Header: "Method",
        id: "default-method",
        accessor: (row) => <label className="label label-soft-secondary">{row.method?.name ?? NotSet}</label>,
        width: 150,
        align: "left",
        sortingFn: () => {
          if (sort.headerId === "default-method") {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC" }));
              setFilters((prev) => ({ ...prev, orderBy: "METHOD_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC" }));
              setFilters((prev) => ({ ...prev, orderBy: "METHOD_ASC" }));
            }
          } else {
            setSort({ headerId: "default-method", sortDirection: "ASC" });
            setFilters((prev) => ({ ...prev, orderBy: "METHOD_ASC" }));
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
      {
        Header: "Experiment",
        id: "default-experiment",
        accessor: (row) => (
          <div className={styles.container_ellipsis}>
            <span>{row.experiment ? row.experiment.name : NotSet}</span>
          </div>
        ),
        width: 200,
        align: "left",
        sortingFn: () => {
          if (sort.headerId === "default-experiment") {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC" }));
              setFilters((prev) => ({ ...prev, orderBy: "EXPERIMENT_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC" }));
              setFilters((prev) => ({ ...prev, orderBy: "EXPERIMENT_ASC" }));
            }
          } else {
            setSort({ headerId: "default-experiment", sortDirection: "ASC" });
            setFilters((prev) => ({ ...prev, orderBy: "EXPERIMENT_ASC" }));
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
      {
        id: "default-instrument",
        Header: "Instrument",
        accessor: (row) => (
          <div className={styles.container_ellipsis}>
            <span>{row.instrument?.name ?? NotSet}</span>
          </div>
        ),
        width: 200,
        align: "left",
        sortingFn: () => {
          if (sort.headerId === "default-instrument") {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC" }));
              setFilters((prev) => ({ ...prev, orderBy: "INSTRUMENT_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC" }));
              setFilters((prev) => ({ ...prev, orderBy: "INSTRUMENT_ASC" }));
            }
          } else {
            setSort({ headerId: "default-instrument", sortDirection: "ASC" });
            setFilters((prev) => ({ ...prev, orderBy: "INSTRUMENT_ASC" }));
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
      {
        Header: "Suppl. Equipment",
        id: "default-eq",
        accessor: (row) => (
          <div className={styles.container_ellipsis}>
            <span>
              {Array.isArray(row.equipments) && row.equipments.length > 0
                ? row.equipments.map((d) => d.name).join(", ")
                : NotSet}
            </span>
          </div>
        ),
        width: 200,
        align: "left",
      },
      {
        Header: "Notes",
        id: "default-notes",
        accessor: (row) => (
          <div className={styles.container_ellipsis}>
            <span>{row.notes}</span>
          </div>
        ),
        width: 200,
        align: "left",
      },
    ] as GenericVirtualizedTableCells<Dataset>;
  }, [sort.headerId, sort.sortDirection]);

  const { data: ids } = useIdsOnly<Dataset["id"], DatasetFilters>("datasets", filters);

  const handleDownloadAll = useCallback(async () => {
    if (filters) {
      try {
        // const ids = await api.getDatasetsIds(filterObj);
        await getDatasetsZip(api, { ids: !!selection.size ? Array.from(selection) : ids?.results });
      } catch (e) {
        console.error("Error: ", e);
        showtoast("error", "Sorry, an error occured while processing your request");
      }
    }
  }, [api, filters, ids?.results, selection]);

  const linkTo = useCallback(
    (row: Dataset) => route(`/shared/${api.getAuthentication().value}/${row.id}`),
    [api, route]
  );
  const onRowClick = useCallback(
    (row: Dataset) => history.push(route(`/shared/${api.getAuthentication().value}/${row.id}`)),
    [api, history, route]
  );

  let { sessionId }: { group: string; sessionId?: string | undefined } = useParams();
  const {
    data: pubInfo,
    status,
    fetchStatus,
    error,
  } = usePost<SharedContentPublicModel, SharedContentPublicModelRequest>(
    `shared_content/public/${sessionId}`,
    { password: api.getAuthentication().password },
    { enabled: !!sessionId }
  );

  const _filters = useMemo(() => ({ ...filters, includeParsingState: true }), [filters]);

  return (
    <LoadingWrapper status={status} fetchStatus={fetchStatus} error={error}>
      {pubInfo && (!pubInfo.requiresPassword || pubInfo?.suppliedPasswordCorrect) ? (
        <TableView>
          <TableView.Head>
            <TableView.Head.Label>
              <div className="flex col-nowrap" style={{ overflow: "hidden" }}>
                <div className="flex row-nowrap align-center gap-5">
                  {pubInfo.name} {typeof resultsCount === "number" && <span className="badge">{resultsCount}</span>}
                </div>
                <span
                  style={{
                    color: "var(--gray-400)",
                    fontSize: "0.75em",
                    fontWeight: "normal",
                    height: "min-content",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                  }}
                >
                  {pubInfo.publicNotes}
                </span>
              </div>
            </TableView.Head.Label>
            <TableView.Head.Controls>
              {pubInfo.allowContentDownload && (
                <button
                  className="btn btn-primary"
                  onClick={handleDownloadAll}
                  disabled={!pubInfo.allowContentDownload}
                >
                  <LucideIcon name="download" /> Download {`${!!selection.size ? "selected" : "all"}`}
                </button>
              )}
            </TableView.Head.Controls>
          </TableView.Head>
          <TableView.Body>
            <TableView.Body.Sidebar>
              <SharedBundlesFilterBar setFilters={setFilters} />
            </TableView.Body.Sidebar>
            <TableView.Body.Content>
              <EntityTable>
                <EntityTable.Controls>
                  <SearchInput searchValue={searchValue} setSearchValue={setSearchValue} placeholder="Search" />
                  {/* {pubInfo.allowContentDownload && (
                    <>
                      {selection.size > 0 && (
                        <div style={{ display: "flex", width: "fit-content", height: "100%", alignItems: "center" }}>
                          <span
                            style={{
                              color: "var(--gray-400)",
                              fontWeight: "normal",
                              padding: "0 5px",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {selection.size} {selection.size === 1 ? "dataset" : "datasets"} selected
                          </span>
                        </div>
                      )}
                      <button
                        className="btn btn-sm btn-ghost-primary"
                        disabled={selection.size === 0 || loading}
                        onClick={handleDownloadSelected}
                        title="Download the selected datasets"
                      >
                        <LucideIcon name="download" /> Download
                      </button>
                    </>
                  )} */}
                </EntityTable.Controls>
                <EntityTable.Body<Dataset, DatasetFilters>
                  entityConstants={datasetsConstants}
                  filters={_filters}
                  columns={columns}
                  columnSelect
                  defaultColumnSettings={columnSetting}
                  setResultsCount={onCountChange}
                  onSelectionChange={onSelectionChange}
                  linkTo={linkTo}
                  onRowClick={onRowClick}
                  disableCheckboxes={!pubInfo.allowContentDownload}
                />
              </EntityTable>
            </TableView.Body.Content>
          </TableView.Body>
        </TableView>
      ) : (
        <Alert type="danger" message="Resource not found or unauthorized" fit centered />
      )}
    </LoadingWrapper>
  );
};
