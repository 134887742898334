import { EmptyParameter } from "./EmptyParameter";
import { SerializedTrackListSelection, trackListType } from "../PipelineTypes";
import { parameterTypes } from "../PipelineTypes";
import { TrackSelection } from "./TrackSelection";

type valueType = { patterns: TrackSelection[]; _value: (tracks: trackListType) => trackListType };

export class TrackListSelection extends EmptyParameter {
  parameterType = parameterTypes.trackListSelection;

  patterns: TrackSelection[];

  value: (tracks: trackListType) => trackListType;
  private _value: (tracks: trackListType) => trackListType;

  // private listPattern: TrackListSelection;

  constructor(value?: any) {
    super(value);
    // console.log("TrackListSelection");
    this.patterns = [];
    this._value = (tracks) => [];
    this.value = this._value.bind(this);

    this.deserialize(value);
    // const v = this.parseValue(value);
    // this.value = this._value.bind(this);
    // this.patterns = v.patterns;
  }

  deserialize(value?: { value: SerializedTrackListSelection } | SerializedTrackListSelection) {
    super.deserialize(value);
    const v = this.parseValue(value);
    this.patterns = v.patterns;
    this._value = v._value;
    this.value = this._value.bind(this);
  }

  parseValue(value?: { value: SerializedTrackListSelection } | SerializedTrackListSelection): valueType {
    const result: valueType = { patterns: this.patterns, _value: this._value };
    // console.log("init", result);
    if (value === undefined) return result;

    if (typeof value !== "object") {
      this.errors.create({
        id: "",
        component: "TrackListSelection.setValue",
        message: `Expected type 'trackPattern[]'. (Got type '${typeof value}').`,
      });
      return result;
    }

    if (!Array.isArray(value)) value = (value?.value as SerializedTrackListSelection) ?? [];

    if (!Array.isArray(value)) {
      this.errors.create({
        id: "",
        component: "TrackListSelection.setValue",
        message: `Expected type 'trackPattern[]'. (Got type '${typeof value}').`,
      });
      return result;
    }

    result.patterns = (value as any[]).map((v) => new TrackSelection(v));
    result.patterns.forEach((p, i) => {
      this.errors.addTracesWithMessage(p.errors, {
        component: "TrackListSelection.setValue",
        id: "",
        message: `Error in pattern ${i}`,
      });
      this.warnings.addTracesWithMessage(p.warnings, {
        component: "TrackListSelection.setValue",
        id: "",
        message: `Warning on pattern ${i}`,
      });
    });

    // console.log("value", value);
    // this.tracks = this.patterns.map((p) => undefined);
    // this.selected = this.patterns.map((p) => undefined);

    result._value = (tracks: trackListType): trackListType => {
      const selectedTracks = this.patterns.map((p) => p.value(tracks));

      // this.selected = this.patterns.map((p) => p.selected);
      // this.tracks = this.patterns.map((p) => p.tracks);

      return selectedTracks;
    };
    return result;
  }

  serialize(storageMode?: boolean): SerializedTrackListSelection {
    const result = super.serialize(storageMode) as SerializedTrackListSelection;

    result.value = this.patterns.map((p) => p.serialize(storageMode));

    return result;
  }
}
