import { useRef, useState } from "react";
import { Bridge } from "../../api/Bridges";
import { IGenericEntity } from "../../api/GenericTypes";
import { SelectFormField, SelectFormFieldProps } from "./SelectFormField";
import { GenericModal } from "../modals/Modal/GenericModal";
import { FileBrowser } from "../../Autoloads/DataSources/common/FileBrowser/FileBrowser";
import { UseFormClearErrors, UseFormSetValue, useWatch } from "react-hook-form";

interface FileBrowserFormFieldProps<T> extends SelectFormFieldProps<T> {
  bridge: Bridge;
  modalTitle?: string;
  setValue: UseFormSetValue<any>;
  clearErrors: UseFormClearErrors<any>;
}

export const FileBrowserFormField = <T extends IGenericEntity>(props: FileBrowserFormFieldProps<T>) => {
  const [showModal, setShowModal] = useState<boolean>(false);

  const currentDirectories: { id: string; name: string }[] = useWatch({ name: props.id, control: props.control });
  const addCallback = (dir: string) => {
    const ids = currentDirectories?.map((d) => d.id);
    if (!ids?.includes(dir)) {
      props.setValue(
        props.id,
        props.isMulti ? [...(currentDirectories || []), { id: dir, name: dir }] : [{ id: dir, name: dir }]
      );
      props.clearErrors(props.id);
    }
    setShowModal(false);
  };

  const ref = useRef<HTMLDivElement>(null);

  return (
    <>
      <div ref={ref} style={{ display: "none" }} />
      <div onClick={() => !currentDirectories?.length && setShowModal(true)}>
        <SelectFormField
          {...props}
          items={currentDirectories || []}
          defaultValue={[]}
          menuPortalTarget={ref.current}
          placeholder={props.placeholder || "Browse directories..."}
          buttons={
            <button
              className="btn btn-primary"
              onClick={(e) => {
                e.preventDefault();
                setShowModal(true);
              }}
              disabled={!props.bridge}
              title={`${props.bridge?.id ? "To browse the client must be connected" : "Browse the local file system"}`}
            >
              Browse
            </button>
          }
        />
      </div>

      <GenericModal
        showModal={showModal}
        setShowModal={setShowModal}
        modalTitle={props.modalTitle || "Add directories"}
        modalBody={<FileBrowser bridgeId={props.bridge.id} addCallback={addCallback} />}
        styleBody={{ paddingBottom: 10 }}
      />
    </>
  );
};
