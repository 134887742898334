import React, { CSSProperties, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import styles from "./EntitySidebarTile.module.css";
import {
  EntityConstants,
  GenericEntity,
  IGenericRequestParameters,
  ISoftDeletable,
  StringIndexedDict,
} from "../../../../api/GenericTypes";
import { useEntityCount, useInfiniteListEntity } from "../../../../api/BaseEntityApi";
import { Tile } from "../../../panels/Tile/Tile";
import { toUppercase } from "../../../helperfunctions/stringFunctions";
import { SearchInput } from "../../../forms/SearchInput/SearchInput";
import { useDebouncedValue } from "../../../helperfunctions/useDebouncedValue";
import { IconNames, LucideIcon } from "../../../icon/LucideIcon";
import { Link } from "react-router-dom";
import { SessionContext } from "../../../contexts/SessionContext";
import { getAddRoute, getDetailLink, getIndexRoute } from "../../../../main/Routing";
import ContentRow from "../../../tables/ContentRow/ContentRow";
import { LoadingWrapper } from "../../../LoadingWrapper";
import { FixedSizeList } from "react-window";
import InfiniteLoader from "react-window-infinite-loader";
import { Skeleton } from "../../../loaders/Skeleton/Skeleton";
import { Alert } from "../../../overlays/Alert/Alert";
import { OrderBySelectDropdown } from "../../../buttons/OrderBySelectDropdown/OrderBySelectDropdown";
import {
  EntityFilterIndicator,
  IsFilteredDefinition,
} from "../../../tables/EntityFilterIndicator/EntityFilterIndicator";
import { PropertyTranslator } from "../../../../api/GenericTranslator";
import { DragContainer } from "../../../../Customization/CustomTypes/CustomTypeRenderUtils";
import { NotSet } from "../../../misc/UIconstants";

export interface EntitySidebarTileProps<
  Entity extends GenericEntity & Partial<ISoftDeletable>,
  Filters extends StringIndexedDict & IGenericRequestParameters<Entity, Filters["orderBy"]>
> extends Omit<EntitySidebarTileCoreProps<Entity, Filters>, "entityConstants" | "orderByOptions"> {}

export interface EntitySidebarTileCoreProps<
  Entity extends GenericEntity & Partial<ISoftDeletable>,
  Filters extends StringIndexedDict & IGenericRequestParameters<Entity, Filters["orderBy"]>
> {
  title?: string;
  pageSize?: number; // No. of items to show on one page
  entityConstants: EntityConstants;
  orderByOptions: readonly Filters["orderBy"][];
  translatorConsts?: PropertyTranslator<Filters>;
  excludeFilters?: Partial<IsFilteredDefinition<Filters>>;
  defaultFilters?: Filters;
  tableLocationStatePayload?: StringIndexedDict;
  rowLabel?: (entity: Entity) => React.ReactNode;
  rowDate?: (entity: Entity) => React.ReactNode;
  rowInfo?: (entity: Entity) => React.ReactNode;
  rowControls?: (entity: Entity) => React.ReactNode;
  onRowClick?: (entity: Entity) => void;
  linkTo?: (entity: Entity) => string;
  controlsFixed?: ({
    filters,
    setFilters,
  }: {
    filters: Filters;
    setFilters: React.Dispatch<React.SetStateAction<Filters>>;
  }) => React.ReactNode;
  controlsUnfolded?: ({
    filters,
    setFilters,
  }: {
    filters: Filters;
    setFilters: React.Dispatch<React.SetStateAction<Filters>>;
  }) => React.ReactNode;
  showTableButton?: boolean; // Wether to show a button to the full entity table
  showAddButton?: boolean; // Wether to show an add button for the current entity
  disabled?: boolean;
  isFolded?: boolean;
  foldable?: boolean;
  draggable?: boolean;
  onAddButtonClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
  headerIconOverride?: IconNames;
  iconOverride?: (entity?: Entity) => React.ReactNode;
  useSearchTermFullText?: boolean;
  openNewTab?: boolean;
  headless?: boolean;
  filterIndicatorOverride?: ({
    filters,
    setFilters,
  }: {
    filters: Filters;
    setFilters: React.Dispatch<React.SetStateAction<Filters>>;
  }) => React.ReactNode;
  rowTitle?: (entity: Entity) => string;
  prependItems?: Entity[];
  disabledItems?: Entity["id"][];
}

/**
 * EntitySidebarTile component renders a sidebar tile for displaying a list of entities with various controls and filters.
 *
 * @template Entity - The type of the entity being displayed, extending GenericEntity and optionally ISoftDeletable.
 * @template Filters - The type of the filters applied to the entity list, extending StringIndexedDict and IGenericRequestParameters.
 * @author @CorradoSurmanowicz
 * @param {EntitySidebarTileCoreProps<Entity, Filters>} props - The properties for the EntitySidebarTile component.
 * @param {string} props.title - The title of the sidebar tile.
 * @param {number} [props.pageSize=5] - The number of items to display per page.
 * @param {EntityConstants} props.entityConstants - Constants related to the entity.
 * @param {OrderByOption[]} props.orderByOptions - Options for ordering the entity list.
 * @param {TranslatorConsts} props.translatorConsts - Constants for translation.
 * @param {Partial<IsFilteredDefinition<Filters>>} [props.excludeFilters] - Filters to exclude from the filter indicator.
 * @param {Filters} [props.defaultFilters] - Default filters to apply to the entity list.
 * @param {any} [props.tableLocationStatePayload] - Payload for the table location state.
 * @param {(entity: Entity) => React.ReactNode} [props.rowLabel] - Function to render the label for each row.
 * @param {(entity: Entity) => React.ReactNode} [props.rowDate] - Function to render the date for each row.
 * @param {(entity: Entity) => React.ReactNode} [props.rowInfo] - Function to render additional info for each row.
 * @param {(entity: Entity) => React.ReactNode} [props.rowControls] - Function to render controls for each row.
 * @param {(entity: Entity) => void} [props.onRowClick] - Callback function when a row is clicked.
 * @param {(entity: Entity) => string} [props.linkTo] - Function to generate the link for each row.
 * @param {boolean} [props.controlsFixed] - Whether the controls are fixed.
 * @param {boolean} [props.controlsUnfolded] - Whether the controls are unfolded.
 * @param {boolean} [props.showTableButton=false] - Whether to show the table button.
 * @param {boolean} [props.showAddButton=false] - Whether to show the add button.
 * @param {boolean} [props.disabled=false] - Whether the component is disabled.
 * @param {boolean} [props.isFolded=false] - Whether the tile is folded.
 * @param {boolean} [props.foldable=true] - Whether the tile is foldable.
 * @param {boolean} [props.draggable=false] - Whether the rows are draggable.
 * @param {(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void} [props.onAddButtonClick] - Callback function when the add button is clicked.
 * @param {string} [props.headerIconOverride] - Override for the header icon.
 * @param {(entity: Entity) => React.ReactNode} [props.iconOverride] - Override for the row icon.
 * @param {boolean} [props.useSearchTermFullText] - Whether to use full-text search for the search term.
 * @param {boolean} [props.openNewTab=false] - Whether to open links in a new tab.
 * @param {boolean} [props.headless=false] - Whether the tile is headless.
 * @param {(props: { filters: Filters; setFilters: React.Dispatch<React.SetStateAction<Filters>> }) => React.ReactNode} [props.filterIndicatorOverride] - Override for the filter indicator.
 * @param {(entity: Entity) => string} [props.rowTitle] - Function to generate the title for each row.
 * @param {Entity[]} [props.prependItems] - Items to prepend to the entity list.
 * @param {Entity["id"][]} [props.disabledItems] - IDs of items to disable.
 *
 * @returns {JSX.Element} The rendered EntitySidebarTile component.
 */
export const EntitySidebarTile = <
  Entity extends GenericEntity & Partial<ISoftDeletable>,
  Filters extends StringIndexedDict & IGenericRequestParameters<Entity, Filters["orderBy"]>
>({
  title,
  pageSize = 5,
  entityConstants,
  orderByOptions,
  translatorConsts,
  excludeFilters,
  defaultFilters,
  tableLocationStatePayload,
  rowLabel,
  rowDate,
  rowInfo,
  rowControls,
  onRowClick,
  linkTo,
  controlsFixed,
  controlsUnfolded,
  showTableButton = false,
  showAddButton = false,
  disabled = false,
  isFolded = false,
  foldable = true,
  draggable = false,
  onAddButtonClick,
  headerIconOverride,
  iconOverride,
  useSearchTermFullText,
  openNewTab = false,
  headless = false,
  filterIndicatorOverride,
  rowTitle,
  prependItems,
  disabledItems,
}: EntitySidebarTileCoreProps<Entity, Filters>) => {
  // const history = useHistory();
  const { route } = useContext(SessionContext);
  const [items, setItems] = useState<Entity[]>([]);
  const [searchValue, setSearchValue] = useState("");
  const debouncedSearchValue = useDebouncedValue(searchValue, 500);
  const [resultsCount, setResultsCount] = useState<number>();
  const [filters, setFilters] = useState<Filters>(defaultFilters || ({} as Filters));
  const checkEmpty = useRef(false);
  const [isEmpty, setIsEmpty] = useState<boolean>();

  useEffect(() => {
    if (defaultFilters) setFilters(defaultFilters);
  }, [defaultFilters]);

  const searchTerm = useMemo(() => {
    if (!debouncedSearchValue) return {};
    return useSearchTermFullText
      ? { searchTerm: debouncedSearchValue, fullTextSearch: true }
      : { searchTerm: debouncedSearchValue };
  }, [debouncedSearchValue, useSearchTermFullText]);

  const { data, error, fetchNextPage, hasNextPage, isFetching, status, fetchStatus } = useInfiniteListEntity<Entity>(
    entityConstants.resource,
    {
      page: 1,
      pageSize: pageSize,
      includeCount: false,
      ...searchTerm,
      ...filters,
    },
    { enabled: !disabled },
    "post"
  );

  // Separate query for count
  const { data: queryCount } = useEntityCount<Filters>(
    entityConstants.resource,
    { includeCount: true, ...filters },
    { enabled: !disabled }
  );

  useEffect(() => {
    if (!checkEmpty.current) {
      if (queryCount !== undefined) {
        checkEmpty.current = true;
        setIsEmpty(!queryCount.count);
      }
    }
    return () => {
      checkEmpty.current = false;
    };
  }, [queryCount]);

  useEffect(() => {
    setResultsCount(queryCount?.count);
  }, [queryCount?.count]);

  useEffect(() => {
    if (data) {
      setItems([...(prependItems ?? []), ...data.pages.map((d) => d.results).flat()]);
    }
  }, [data, prependItems]);

  // const handleAdd = useCallback(() => {
  //   history.push(route(getAddRoute(entityConstants.resource)));
  // }, [entityConstants.resource, history, route]);

  const loadMoreCallback = useCallback(() => {}, []);
  const itemCount = hasNextPage ? items.length + 1 : items.length;
  const loadMoreItems = isFetching ? loadMoreCallback : hasNextPage ? fetchNextPage : loadMoreCallback;

  const isItemLoaded = useCallback(
    (index: number) => !hasNextPage || index < items.length - pageSize,
    [hasNextPage, items.length, pageSize]
  );

  const Item = useCallback(
    ({ index, style }: { index: number; style: CSSProperties }) => {
      if (!isItemLoaded(index))
        return (
          <div style={{ ...style, paddingBottom: "5px" }} key={index}>
            <span className="skeleton-block btn-lg" style={{ height: 51 }} />
          </div>
        );

      const entity = items[index];
      const isDisabled = disabledItems?.includes(entity.id);

      if (draggable)
        return (
          <div style={{ ...style, paddingBottom: "5px" }} key={index} title={rowTitle?.(entity)}>
            {isDisabled ? (
              <Link
                key={index}
                to={linkTo ? linkTo(entity) : route(getDetailLink(entityConstants.frontendIndexRoute, entity.id))}
                style={{ textDecoration: "none", width: "100%" }}
                target={openNewTab ? "_blank" : undefined}
              >
                <CustomFieldContentRow
                  index={index}
                  entity={entity}
                  entityConstants={entityConstants}
                  iconOverride={iconOverride}
                  rowLabel={rowLabel}
                  rowDate={rowDate}
                  rowInfo={rowInfo}
                  rowControls={rowControls}
                  onRowClick={undefined}
                  disabled
                  draggable
                />
              </Link>
            ) : (
              <DragContainer
                dataGrid={entity}
                style={{ width: "100%", border: "1px dashed var(--gray-400)", borderRadius: 5 }}
              >
                <Link
                  key={index}
                  to={linkTo ? linkTo(entity) : route(getDetailLink(entityConstants.frontendIndexRoute, entity.id))}
                  style={{ textDecoration: "none", width: "100%" }}
                  target={openNewTab ? "_blank" : undefined}
                >
                  <CustomFieldContentRow
                    index={index}
                    entity={entity}
                    entityConstants={entityConstants}
                    iconOverride={iconOverride}
                    rowLabel={rowLabel}
                    rowDate={rowDate}
                    rowInfo={rowInfo}
                    rowControls={rowControls}
                    onRowClick={undefined}
                    draggable
                  />
                </Link>
              </DragContainer>
            )}
          </div>
        );
      return (
        <div style={{ ...style, paddingBottom: "5px" }} key={index} title={rowTitle?.(entity)}>
          <Link
            key={index}
            to={linkTo ? linkTo(entity) : route(getDetailLink(entityConstants.frontendIndexRoute, entity.id))}
            style={{ textDecoration: "none" }}
            target={openNewTab ? "_blank" : undefined}
          >
            <CustomFieldContentRow
              index={index}
              entity={entity}
              entityConstants={entityConstants}
              iconOverride={iconOverride}
              rowLabel={rowLabel}
              rowDate={rowDate}
              rowInfo={rowInfo}
              rowControls={rowControls}
              onRowClick={onRowClick}
              draggable={draggable}
              disabled={isDisabled}
            />
            {/* <ContentRow
              key={index}
              icon={
                <div className={styles.icon}>
                  {iconOverride?.(entity) || <LucideIcon name={entityConstants.icon} />}
                </div>
              }
              label={rowLabel?.(entity)}
              currentIndicator={
                <>
                  {entity.isDeleted ? (
                    <div>
                      <label className={"label label-soft-warning"} title="trashed">
                        <LucideIcon name="trash" />
                      </label>
                    </div>
                  ) : (
                    ""
                  )}
                </>
              }
              date={rowDate?.(entity)}
              id={!!entity.id ? <>{`${toUppercase(entityConstants.entitySingular)}-ID: ${entity.id}`}</> : <></>}
              info={rowInfo?.(entity)}
              controls={rowControls?.(entity)}
              onClick={onRowClick ? () => onRowClick(entity) : undefined}
            /> */}
          </Link>
        </div>
      );
    },
    [
      disabledItems,
      draggable,
      entityConstants,
      iconOverride,
      isItemLoaded,
      items,
      linkTo,
      onRowClick,
      openNewTab,
      route,
      rowControls,
      rowDate,
      rowInfo,
      rowLabel,
      rowTitle,
    ]
  );

  const handleAddButtonClick = useCallback(
    (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      if (onAddButtonClick) {
        event.preventDefault();
        event.stopPropagation();
        onAddButtonClick(event);
      }
    },
    [onAddButtonClick]
  );

  return (
    <Tile
      isFolded={disabled ? true : isEmpty ? (!foldable ? false : true) : isFolded}
      foldable={disabled ? false : isEmpty ? false : foldable}
      disabled={disabled ? true : isEmpty ? (!foldable ? false : true) : false}
    >
      {!headless && (
        <Tile.Head
          title={
            <div className="flex row-nowrap align-center gap-5">
              <LucideIcon name={headerIconOverride ?? entityConstants.icon} />{" "}
              {title ?? toUppercase(entityConstants.entityPlural)}
              <span className={`badge ${typeof resultsCount !== "number" && !disabled ? "loading" : ""}`}>
                {disabled ? 0 : typeof resultsCount === "number" ? resultsCount : "•"}
              </span>
            </div>
          }
        >
          <Tile.Head.Controls>
            <Tile.Head.Controls.Unfolded>
              <>
                {controlsUnfolded?.({ filters, setFilters })}

                {/* <SearchInput
                searchValue={searchValue}
                setSearchValue={setSearchValue}
                minimizedBtnCls={"btn btn-xs btn-ghost-primary"}
                minimized
              /> */}
              </>
            </Tile.Head.Controls.Unfolded>
            <Tile.Head.Controls.Fixed>
              <>
                {controlsFixed?.({ filters, setFilters })}
                {showTableButton && (
                  <Link
                    to={{
                      pathname: route(getIndexRoute(entityConstants.frontendIndexRoute)),
                      state: tableLocationStatePayload,
                    }}
                    target={openNewTab ? "_blank" : undefined} // Deactivate this once the tableLocationState is implemented in useTabStore
                  >
                    <button className="btn btn-ghost-primary" title={`List ${entityConstants.entityPlural}`}>
                      <LucideIcon name="table" />
                    </button>
                  </Link>
                )}
                {showAddButton && (
                  <Link
                    to={route(getAddRoute(entityConstants.frontendIndexRoute))}
                    target={openNewTab ? "_blank" : undefined}
                    onClick={handleAddButtonClick}
                  >
                    <button className="btn btn-ghost-primary" title={`Add ${entityConstants.entitySingular}`}>
                      <LucideIcon name="plus" />
                    </button>
                  </Link>
                )}
              </>
            </Tile.Head.Controls.Fixed>
          </Tile.Head.Controls>
        </Tile.Head>
      )}
      <Tile.Body>
        <div className="flex col-nowrap align-center gap-5" style={{ width: "100%", height: "100%" }}>
          <div className="flex row-nowrap align-center gap-5" style={{ width: "100%" }}>
            {filterIndicatorOverride?.({ filters, setFilters }) || (
              <EntityFilterIndicator<Entity, Filters>
                filters={filters}
                translatorConsts={translatorConsts}
                excludeFilters={
                  {
                    excludeIds: () => false,
                    ...excludeFilters,
                  } as Partial<IsFilteredDefinition<Filters>>
                }
                overlayPlaycement="right"
              />
            )}
            <SearchInput
              searchValue={searchValue}
              setSearchValue={setSearchValue}
              // minimizedBtnCls={"btn btn-xs btn-ghost-primary"}
              // minimized
            />
            <div style={{ marginLeft: "auto" }}>
              <OrderBySelectDropdown
                btnCls="btn btn-xs btn-ghost-secondary"
                orderByOptions={orderByOptions}
                currentFilters={filters}
                setCurrentFilters={setFilters}
              />
            </div>
          </div>
          <LoadingWrapper status={status} fetchStatus={fetchStatus} error={error} type="entity-tile">
            <div className={isFetching ? `${styles.container} ${styles.container_loading}` : styles.container}>
              {items && items.length > 0 ? (
                <InfiniteLoader isItemLoaded={isItemLoaded} itemCount={itemCount} loadMoreItems={loadMoreItems as any}>
                  {({ onItemsRendered, ref }) => (
                    <FixedSizeList
                      itemCount={itemCount}
                      onItemsRendered={onItemsRendered}
                      ref={ref}
                      width="100%"
                      height={items.length > pageSize ? pageSize * 59 : items.length * 59}
                      itemSize={59}
                    >
                      {Item}
                    </FixedSizeList>
                  )}
                </InfiniteLoader>
              ) : (
                <>
                  {isFetching ? (
                    <Skeleton type="rows" />
                  ) : (
                    <>
                      {debouncedSearchValue ? (
                        <Alert type="light" message={`No results for: "${debouncedSearchValue}"`} fit centered />
                      ) : (
                        <Alert type="light" message={`No related ${entityConstants.entityPlural}`} fit centered />
                      )}
                    </>
                  )}
                </>
              )}
            </div>
          </LoadingWrapper>
        </div>
      </Tile.Body>
    </Tile>
  );
};

interface CustomFieldContentRowProps<
  Entity extends GenericEntity & Partial<ISoftDeletable>,
  Filters extends StringIndexedDict & IGenericRequestParameters<Entity, Filters["orderBy"]>
> {
  index: number;
  entity: Entity;
  entityConstants: EntitySidebarTileCoreProps<Entity, Filters>["entityConstants"];
  iconOverride?: EntitySidebarTileCoreProps<Entity, Filters>["iconOverride"];
  rowLabel?: EntitySidebarTileCoreProps<Entity, Filters>["rowLabel"];
  rowDate?: EntitySidebarTileCoreProps<Entity, Filters>["rowDate"];
  rowInfo?: EntitySidebarTileCoreProps<Entity, Filters>["rowInfo"];
  rowControls?: EntitySidebarTileCoreProps<Entity, Filters>["rowControls"];
  onRowClick?: EntitySidebarTileCoreProps<Entity, Filters>["onRowClick"];
  draggable?: EntitySidebarTileCoreProps<Entity, Filters>["draggable"];
  disabled?: boolean;
}
const CustomFieldContentRow = <
  Entity extends GenericEntity & Partial<ISoftDeletable>,
  Filters extends StringIndexedDict & IGenericRequestParameters<Entity, Filters["orderBy"]>
>({
  index,
  entity,
  entityConstants,
  iconOverride,
  rowLabel,
  rowDate,
  rowInfo,
  rowControls,
  onRowClick,
  draggable,
  disabled,
}: CustomFieldContentRowProps<Entity, Filters>) => {
  return (
    <ContentRow
      key={index}
      icon={<div className={styles.icon}>{iconOverride?.(entity) || <LucideIcon name={entityConstants.icon} />}</div>}
      label={rowLabel?.(entity)}
      currentIndicator={
        <>
          {entity.isDeleted ? (
            <div>
              <label className={"label label-soft-warning"} title="trashed">
                <LucideIcon name="trash" />
              </label>
            </div>
          ) : (
            ""
          )}
        </>
      }
      date={rowDate?.(entity)}
      id={!!entity.id ? <>{`${toUppercase(entityConstants.entitySingular)}-ID: ${entity.id}`}</> : NotSet}
      info={rowInfo?.(entity)}
      controls={rowControls?.(entity)}
      onClick={disabled ? undefined : onRowClick ? () => onRowClick(entity) : undefined}
      style={{
        cursor: draggable ? "grab" : "pointer",
        ...(disabled && { cursor: "not-allowed" }),
      }}
      disabled={disabled}
    />
  );
};
