import { Tab, Tabs } from "react-bootstrap";
import { DataSource } from "../../../../api/DataSource";
import { Dispatch, SetStateAction, useMemo, useState } from "react";
import { LucideIcon } from "../../../../common/icon/LucideIcon";
import { DateTimeRenderer } from "../../../../common/datetime/DateTimeFormatter";
import { NotAvailable } from "../../../../common/misc/UIconstants";
import { ParameterFormatter } from "../../../../ParameterViewer/TreeViewer/ParameterFormatter";
import styles from "./DataSourceHisitory.module.css";
import { formatBytes } from "../../../../Dataset/common/Tree/Folder/helpers/FileHelpers";
import { BridgeType } from "../../../../api/Bridges";
import { GenericModal } from "../../../../common/modals/Modal/GenericModal";
import { Alert } from "../../../../common/overlays/Alert/Alert";
import { useInfiniteListEntity } from "../../../../api/BaseEntityApi";
import {
  DataSourceStatus,
  DataSourceStatusFilters,
  DataSourceStatusInfoClient,
  DataSourceStatusInfoSSH,
  dataSourceStatusConstants,
} from "../../../../api/DataSourceStatus";
import { LoadingWrapper } from "../../../../common/LoadingWrapper";
import { useLiveAutoloadConnection } from "../../../../common/signalr/useLiveConnection";
import { getDataSourceStatusLabel } from "../DataSourceRunningStatus/DataSourceRunningStatus";

const parameterFormatter = new ParameterFormatter();

export const DataSourceHistory = ({
  dataSource,
  showLastUpdated = false,
  statusOnly = false,
  pageSize = 10,
  allowLoadMore = false,
  lastStateOnly = false,
}: {
  dataSource: DataSource;
  showLastUpdated?: boolean;
  statusOnly?: boolean;
  pageSize?: number;
  allowLoadMore?: boolean;
  lastStateOnly?: boolean;
}) => {
  const [currentTab, setCurrentTab] = useState<"Status" | "Statistics">("Status");
  const [highlightRow, setHighlightRow] = useState<number | number>();
  const [showErrorModal, setShowErrorModal] = useState<number | undefined>();

  const { data, error, fetchNextPage, hasNextPage, status, fetchStatus, refetch } = useInfiniteListEntity<
    DataSourceStatus,
    DataSourceStatusFilters
  >(
    dataSourceStatusConstants.resource,
    {
      page: 1,
      pageSize,
      includeCount: false,
      orderBy: "STARTED_ON_DESC",
      dataSourceIds: [dataSource.id],
    },
    { enabled: !lastStateOnly },
    "post"
  );

  const history = useMemo(() => {
    if (lastStateOnly) return dataSource.status?.lastClientStatus ? [dataSource.status.lastClientStatus] : [];
    return data?.pages.map((d) => d.results).flat() || [];
  }, [data?.pages, dataSource.status?.lastClientStatus, lastStateOnly]);

  useLiveAutoloadConnection({
    callbacks: {
      DataSourceScheduled: (dataSourceId) => dataSourceId === dataSource.id && !lastStateOnly && refetch(),
    },
    enabled: true,
  });

  return (
    <>
      <div style={{ display: "grid", gridTemplate: "100%" }}>
        {!statusOnly && (
          <div style={{ width: "max-content", marginLeft: "1px" }}>
            <Tabs
              defaultActiveKey="Status"
              activeKey={currentTab}
              id="tabs-menue"
              bsStyle="tabs"
              onSelect={(k) => setCurrentTab(k)}
              justified
            >
              <Tab
                eventKey="Status"
                title={
                  <div data-toggle="tooltip" title="Status" style={{ width: "120px" }}>
                    <LucideIcon name="info" />
                    <span> Status</span>
                  </div>
                }
              />
              <Tab
                eventKey="Statistics"
                title={
                  <div data-toggle="tooltip" title="Statistics" style={{ width: "120px" }}>
                    <LucideIcon name="chart-column" />
                    <span> File statistics</span>
                  </div>
                }
              />
            </Tabs>
          </div>
        )}
        <LoadingWrapper status={status} fetchStatus={fetchStatus} error={error}>
          {!!history.length ? (
            <div
              style={{
                width: "100%",
                overflow: "auto",
                padding: "0 2px",
                marginTop: "-1px",
              }}
            >
              {currentTab === "Status" && (
                <table>
                  <thead>
                    <tr className={styles.statusHistoryTableRow}>
                      <th className={styles.statusHistoryTableHeader}>State</th>
                      <th className={styles.statusHistoryTableHeader}>Started on</th>
                      {showLastUpdated && <th className={styles.statusHistoryTableHeader}>Status last updated on</th>}
                      <th className={styles.statusHistoryTableHeader}>Duration</th>
                      <th className={styles.statusHistoryTableHeader}>Errors</th>
                    </tr>
                  </thead>
                  <tbody>
                    {history.map((d, index) => (
                      <tr
                        key={index}
                        onClick={() => setHighlightRow((prevState) => (prevState === index ? undefined : index))}
                        style={{
                          backgroundColor: highlightRow === index ? "var(--primary-light)" : "var(--white)",
                        }}
                        className={styles.statusHistoryTableRow}
                      >
                        <td className={styles.statusHistoryTableCell}>
                          {getDataSourceStatusLabel({ runState: d.runState, hasErrors: !!d.errors?.length })}
                        </td>
                        <td className={styles.statusHistoryTableCell} style={{ width: "100%" }}>
                          <DateTimeRenderer date={d.startedOn} />
                        </td>
                        {showLastUpdated && (
                          <td className={styles.statusHistoryTableCell}>
                            {d.lastUpdated ? <DateTimeRenderer date={d.lastUpdated} /> : NotAvailable}
                          </td>
                        )}
                        <td className={styles.statusHistoryTableCell}>
                          {d.duration ? parameterFormatter.formatDuration(d.duration, 1).join(" ") : NotAvailable}
                        </td>
                        <td className={styles.statusHistoryTableCell} style={{ width: "max-content" }}>
                          <div className="flex align-center" style={{ justifyContent: "center" }}>
                            {!!d.errors?.length ? (
                              <button
                                className="btn btn-danger btn-xs flex align-center gap-5"
                                onClick={() => setShowErrorModal(index)}
                              >
                                <LucideIcon name="triangle-alert" /> <div>Show errors</div>
                              </button>
                            ) : (
                              <span className="label label-soft-default">No errors</span>
                            )}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}

              {currentTab === "Statistics" && (
                <>
                  <table>
                    <thead>
                      <DataSourceStatisticHeaders type={history[0].type || "Unknown"} />
                    </thead>
                    <tbody style={{ overflow: "auto" }}>
                      {history.map((d, index) => (
                        <DataSourceStatistics
                          key={index}
                          startedOn={d.startedOn}
                          status={d}
                          highlightRow={highlightRow}
                          setHighlightRow={setHighlightRow}
                          index={index}
                        />
                      ))}
                    </tbody>
                  </table>
                </>
              )}
            </div>
          ) : (
            <Alert type="light" fit centered message="No status history entries yet." />
          )}
        </LoadingWrapper>
        {allowLoadMore && hasNextPage && (
          <div style={{ width: "100%" }}>
            <hr style={{ margin: "10px 0" }} />
            <button
              className="btn btn-sm btn-soft-primary btn-block flex align-center gap-5"
              style={{ justifyContent: "center" }}
              onClick={() => fetchNextPage()}
              disabled={history.length >= 1000}
            >
              <LucideIcon name="chevrons-down" />
              Load more
            </button>
          </div>
        )}
      </div>

      <GenericModal
        showModal={showErrorModal !== undefined}
        setShowModal={(value) => setShowErrorModal((prevState) => (!!value ? prevState : undefined))}
        modalTitle={
          <div className="flex align-center gap-5">
            <LucideIcon name="triangle-alert" color="var(--danger)" />
            <div> Errors</div>
          </div>
        }
        modalBody={
          <div
            className="flex col-nowrap align-center gap-5"
            style={{ width: "100%", padding: "0 10px", overflow: "auto" }}
          >
            {!!(showErrorModal !== undefined) && !!history[showErrorModal].errors?.length ? (
              <>
                {history[showErrorModal]?.errors?.map((error, index) => (
                  <Alert type="danger" fit message={error.message} style={{ margin: 0 }} key={index} />
                ))}
              </>
            ) : (
              <Alert type="secondary" message="No errors" fit centered />
            )}
          </div>
        }
        modalControls={
          <div className="flex align-center gap-5" style={{ justifyContent: "flex-end" }}>
            <button className="btn btn-default" onClick={() => setShowErrorModal(undefined)}>
              <span> Close</span>
            </button>
          </div>
        }
      />
    </>
  );
};

const DataSourceStatisticHeaders = ({ type }: { type?: BridgeType }) => {
  switch (type) {
    case "Client":
      return (
        <tr>
          <th className={styles.statusHistoryTableHeader}>Started on</th>
          <th className={styles.statusHistoryTableHeader}>Datasets submitted</th>
          <th className={styles.statusHistoryTableHeader}>Dataset failed during submission</th>
          <th className={styles.statusHistoryTableHeader}>Datasets in queue</th>
          <th className={styles.statusHistoryTableHeader}>Datasets known from cache</th>
          <th className={styles.statusHistoryTableHeader}>Datasets not known from cache</th>
          <th className={styles.statusHistoryTableHeader}>Datasets known from server</th>
          <th className={styles.statusHistoryTableHeader}>Datasets older than cutoff date</th>
          <th className={styles.statusHistoryTableHeader}>Incomplete datasets</th>
          <th className={styles.statusHistoryTableHeader}>Total size of datasets submitted</th>
          <th className={styles.statusHistoryTableHeader}>Total size of datasets in queue</th>
          <th className={styles.statusHistoryTableHeader}>Directories entered</th>
          <th className={styles.statusHistoryTableHeader}>Files and directories scanned</th>
          <th className={styles.statusHistoryTableHeader}>Files and directories matched</th>
          <th className={styles.statusHistoryTableHeader}>Broken symbolic links ignored</th>
        </tr>
      );
    case "SFTP":
      return (
        <tr>
          <th className={styles.statusHistoryTableHeader}>Started on</th>
          <th className={styles.statusHistoryTableHeader}>Datasets found</th>
          <th className={styles.statusHistoryTableHeader}>Datasets uploaded</th>
          <th className={styles.statusHistoryTableHeader}>Datasets updated</th>
          <th className={styles.statusHistoryTableHeader}>Datasets known</th>
          <th className={styles.statusHistoryTableHeader}>Total size of datasets uploaded</th>
          <th className={styles.statusHistoryTableHeader}>Directories entered</th>
          <th className={styles.statusHistoryTableHeader}>Directories failed to read</th>
          <th className={styles.statusHistoryTableHeader}>Files found</th>
          <th className={styles.statusHistoryTableHeader}>Files uploaded</th>
          <th className={styles.statusHistoryTableHeader}>Files failed to read</th>
        </tr>
      );
    default:
      return <>Unknown type</>;
  }
};

const DataSourceStatistics = ({
  startedOn,
  status,
  highlightRow,
  setHighlightRow,
  index,
}: {
  startedOn: string;
  status: DataSourceStatus;
  highlightRow?: number;
  setHighlightRow: Dispatch<SetStateAction<number | undefined>>;
  index: number;
}) => {
  if (status.info) {
    switch (status.type) {
      case "Client":
        const clientinfo = status.info as DataSourceStatusInfoClient | undefined;
        return (
          <tr
            onClick={() => setHighlightRow((prevState) => (prevState === index ? undefined : index))}
            style={{ backgroundColor: highlightRow === index ? "var(--primary-light)" : undefined }}
            className={styles.statusHistoryTableRow}
          >
            <td className={styles.statusHistoryTableCell}>
              <DateTimeRenderer date={startedOn} />
            </td>
            <td className={styles.statusHistoryTableCell}>{clientinfo?.datasetsSubmitted ?? NotAvailable}</td>
            <td className={styles.statusHistoryTableCell}>{clientinfo?.datasetsFailedSubmission ?? NotAvailable}</td>
            <td className={styles.statusHistoryTableCell}>{clientinfo?.datasetInQueue ?? NotAvailable}</td>
            <td className={styles.statusHistoryTableCell}>{clientinfo?.datasetsKnownFromCache ?? NotAvailable}</td>
            <td className={styles.statusHistoryTableCell}>{clientinfo?.datasetsNotKnownFromCache ?? NotAvailable}</td>
            <td className={styles.statusHistoryTableCell}>{clientinfo?.datasetsKnownFromServer ?? NotAvailable}</td>
            <td className={styles.statusHistoryTableCell}>{clientinfo?.datasetsOlderThanCutoffDate ?? NotAvailable}</td>
            <td className={styles.statusHistoryTableCell}>{clientinfo?.incompleteDatasets ?? NotAvailable}</td>
            <td className={styles.statusHistoryTableCell}>
              {clientinfo?.totalSizeSubmittedDatasetsInBytes !== undefined
                ? formatBytes(clientinfo?.totalSizeSubmittedDatasetsInBytes)
                : NotAvailable}
            </td>
            <td className={styles.statusHistoryTableCell}>
              {clientinfo?.totalSizeDatasetsInQueueInBytes !== undefined
                ? formatBytes(clientinfo?.totalSizeDatasetsInQueueInBytes)
                : NotAvailable}
            </td>
            <td className={styles.statusHistoryTableCell}>{clientinfo?.directoriesEntered ?? NotAvailable}</td>
            <td className={styles.statusHistoryTableCell}>{clientinfo?.entriesScanned ?? NotAvailable}</td>
            <td className={styles.statusHistoryTableCell}>{clientinfo?.entriesMatched ?? NotAvailable}</td>
            <td className={styles.statusHistoryTableCell}>{clientinfo?.brokenSymlinksIgnored ?? NotAvailable}</td>
          </tr>
        );
      case "SFTP":
        const sshInfo = status.info as DataSourceStatusInfoSSH | undefined;
        return (
          <tr
            onClick={() => setHighlightRow((prevState) => (prevState === index ? undefined : index))}
            style={{ backgroundColor: highlightRow === index ? "var(--primary-light)" : undefined }}
            className={styles.statusHistoryTableRow}
          >
            <td className={styles.statusHistoryTableCell}>
              <DateTimeRenderer date={startedOn} />
            </td>
            <td className={styles.statusHistoryTableCell}>{sshInfo?.datasetsFound ?? NotAvailable}</td>
            <td className={styles.statusHistoryTableCell}>{sshInfo?.datasetsUploaded ?? NotAvailable}</td>
            <td className={styles.statusHistoryTableCell}>{sshInfo?.datasetsUpdated ?? NotAvailable}</td>
            <td className={styles.statusHistoryTableCell}>{sshInfo?.datasetsKnown ?? NotAvailable}</td>
            <td className={styles.statusHistoryTableCell}>
              {sshInfo?.filesUploadSize !== undefined ? formatBytes(sshInfo?.filesUploadSize) : NotAvailable}
            </td>
            <td className={styles.statusHistoryTableCell}>{sshInfo?.directoriesEntered ?? NotAvailable}</td>
            <td className={styles.statusHistoryTableCell}>{sshInfo?.directoriesFailedToRead ?? NotAvailable}</td>
            <td className={styles.statusHistoryTableCell}>{sshInfo?.filesFound ?? NotAvailable}</td>
            <td className={styles.statusHistoryTableCell}>{sshInfo?.filesUploaded ?? NotAvailable}</td>
            <td className={styles.statusHistoryTableCell}>{sshInfo?.filesFailedToRead ?? NotAvailable}</td>
          </tr>
        );
      default:
        return <>Unknown type</>;
    }
  } else {
    return <></>;
  }
};
