import { FormHeader } from "../common/forms/FormHeader";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { SharedContent, sharedContentConstants } from "../api/SharedContent";
import { InputFormField } from "../common/formfields/InputFormField";
import { FormButtons } from "../common/forms/FormButtons";
import { TextareaFormField } from "../common/formfields/TextareaFormField";
import { ToggleFormField } from "../common/formfields/ToggleFormField";
import { DatasetsVirtualizedSelectForm } from "../common/forms/EntityForms/formsVirtualized/DatasetsVirtualizedSelectForm";
import { PasswordFormField } from "../common/formfields/PasswordFormField";
import { EntityFormProps } from "../common/entity/EntityInterfaces";
import styles from "../common/forms/forms.module.css";

const SharedContentFormSchema = yup.object().shape({
  name: yup.string().required("A name is required"),
  datasets: yup.array().min(1, "At least one dataset is required").required("At least one dataset is required"),
});

export const SharedContentForm = ({
  event,
  fieldLabels,
  permissions,
  title,
  subtitle,
  initialValues,
  onSubmit,
  onCancel,
  loading,
  submitButtonLabel,
}: EntityFormProps<"sharedContents">) => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
  } = useForm<Partial<SharedContent>>({
    defaultValues: initialValues,
    resolver: yupResolver(SharedContentFormSchema),
  });

  return (
    <>
      <FormHeader title={title} subtitle={subtitle} />
      <form
        onSubmit={(e) => {
          e.preventDefault();
        }}
        autoComplete="off"
        className={`form-horizontal ${styles.form_holder}`}
      >
        <fieldset>
          <legend className="col-md-offset-2 col-md-10">Basic details</legend>
        </fieldset>

        <InputFormField
          id={"name"}
          label={"Name"}
          errors={errors}
          register={register}
          autoFocus={true}
          required={true}
        />
        <TextareaFormField id="publicNotes" label="Public Notes" errors={errors} register={register} />
        <PasswordFormField
          id={"password"}
          label={"Password"}
          errors={errors}
          register={register}
          required={false}
          showPassword={event === "ADD"}
        />
        <TextareaFormField id="privateNotes" label="Private Notes" errors={errors} register={register} />
        <DatasetsVirtualizedSelectForm id="datasets" control={control} horizontal required isMulti showControls />

        <ToggleFormField id={"allowContentDownload"} label="Allow Download" control={control} horizontal />

        <FormButtons
          groupName="project_tags"
          entityId={initialValues ? initialValues.id : undefined}
          onClose={onCancel}
          onSubmit={handleSubmit(async (entity) => await onSubmit(entity))}
          submitButtonLabel={submitButtonLabel || "Save changes"}
          disabled={isSubmitting}
          errors={errors}
          loading={loading}
        />
      </form>
    </>
  );
};
