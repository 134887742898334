import { useCallback, useEffect, useMemo } from "react";
import { DefinedPropertyObject, GenericEntity, StringIndexedDict } from "../../../api/GenericTypes";
import { UseTabActions } from "../../tables/Tabs/TableTabsTypes";
import { DefaultValues, useForm } from "react-hook-form";

// Common interface to be used for Entity Tables Sidebar Filter component
export interface FilterSidebarProps<
  Entity extends GenericEntity,
  FormInterface extends StringIndexedDict,
  Filters extends StringIndexedDict
> {
  initialValues?: FormInterface;
  setFilters?: React.Dispatch<React.SetStateAction<Filters>>; // Optional stateSetter
  onFormChange?: (formValues: FormInterface) => void; // Optional callback to retrieve form values
  dispatchTabStore?: (action: UseTabActions<Entity, Filters, FormInterface>) => Promise<void>; // Dispatcher for TabsStore
  tabsLoading?: boolean; // TabsStore loading state
  currentTab?: string; // TabsStore currentTab
  hideReset?: boolean; // Hide reset button
}

// Hook for sidebar value updates
interface UseSidebarValuesProps<FormInterface extends StringIndexedDict> {
  defaultValues: DefinedPropertyObject<FormInterface>;
  initialValues?: FormInterface;
}
export const useSidebarValues = <FormInterface extends StringIndexedDict>({
  defaultValues,
  initialValues,
}: UseSidebarValuesProps<FormInterface>) => {
  const values = useMemo(() => {
    return Object.fromEntries(
      Object.entries(defaultValues).map(([key, value]) => [key, initialValues?.[key] ?? value])
    ) as FormInterface;
  }, [defaultValues, initialValues]);

  return values;
};

interface UseFilterSidebarProps<
  Entity extends GenericEntity,
  FormInterface extends StringIndexedDict,
  EntityFilters extends StringIndexedDict
> {
  initialValues?: FormInterface;
  defaultValues: DefinedPropertyObject<FormInterface>;
  defaultFilters?: EntityFilters;
  formToFilters: (values: FormInterface) => EntityFilters;
  onFormChange?: FilterSidebarProps<Entity, FormInterface, EntityFilters>["onFormChange"];
  setFilters?: FilterSidebarProps<Entity, FormInterface, EntityFilters>["setFilters"];
  dispatchTabStore?: FilterSidebarProps<Entity, FormInterface, EntityFilters>["dispatchTabStore"];
}
export const useFilterSidebar = <
  Entity extends GenericEntity,
  FormInterface extends StringIndexedDict,
  EntityFilters extends StringIndexedDict
>({
  initialValues,
  defaultValues,
  defaultFilters,
  formToFilters,
  dispatchTabStore,
  onFormChange,
  setFilters,
}: UseFilterSidebarProps<Entity, FormInterface, EntityFilters>) => {
  const initialForm = useMemo(() => initialValues, [initialValues]);
  const values = useSidebarValues({ defaultValues, initialValues: initialForm });

  const { register, control, reset, watch } = useForm<FormInterface>({
    defaultValues: defaultValues as DefaultValues<FormInterface>,
    values: values,
  });

  useEffect(() => {
    const subscription = watch(async (value: any) => {
      onFormChange?.(value);
      setFilters?.((prev) => ({ ...prev, ...formToFilters(value as FormInterface) }));
      await dispatchTabStore?.({
        type: "setTab",
        options: { keepPrevious: true },
        payload: {
          settings: {
            sidebarFilters: {
              ...(value as FormInterface),
            },
            filters: formToFilters(value),
          },
        },
      });
    });
    return () => subscription.unsubscribe();
  }, [dispatchTabStore, watch, formToFilters, onFormChange, setFilters]);

  const onReset = useCallback(
    async (e: React.MouseEvent) => {
      e.preventDefault();
      reset(defaultValues);
      await dispatchTabStore?.({
        type: "setTab",
        payload: {
          settings: {
            sidebarFilters: defaultValues,
            filters: defaultFilters
              ? { ...formToFilters(defaultValues), ...defaultFilters }
              : formToFilters(defaultValues),
          },
        },
      });
    },
    [defaultFilters, defaultValues, dispatchTabStore, formToFilters, reset]
  );

  return { register, control, reset, watch, onReset };
};
