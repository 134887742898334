import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm, useWatch } from "react-hook-form";
import { InputFormField } from "../../../formfields/InputFormField";
import { QuickAddChildrenProps } from "../common/QuickAddWrapper";
import { FormButtons } from "../../FormButtons";
import { FormHeader } from "../../FormHeader";
import { Experiment, ExperimentFieldLabels, experimentsConstants } from "../../../../api/Experiments";
import { TextareaFormField } from "../../../formfields/TextareaFormField";
import { IEntityMinimalModel, Nullable } from "../../../../api/GenericTypes";
import { useQuickAddSubmit } from "../common/useQuickAddHooks";
import { MethodsVirtualizedSelectForm } from "../../EntityForms/formsVirtualized/MethodsVirtualizedSelectForm";

interface QuickAddExperimentProps {
  measurementMethod?: IEntityMinimalModel | null;
}

export const QuickAddExperimentForm = ({
  onCreate,
  onCancel,
  measurementMethod,
}: QuickAddChildrenProps<Experiment> & QuickAddExperimentProps) => {
  const { onSubmit, loading } = useQuickAddSubmit<Experiment>({
    resource: experimentsConstants.resource,
    onCreate: onCreate,
    onCancel: onCancel,
  });

  const FormSchema = yup.object().shape({
    name: yup.string().required("A Name is required"),
    method: yup
      .object()
      .shape({ id: yup.number().required().positive().integer(), name: yup.string().required() })
      .typeError("A Method must be defined"),
  });

  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
  } = useForm<Partial<Nullable<Experiment>>>({
    values: {
      method: measurementMethod,
    },
    resolver: yupResolver(FormSchema),
  });

  const method = useWatch({ name: "method", control: control });

  return (
    <>
      <FormHeader title={"Add experiment"} subtitle={measurementMethod?.name ?? method?.name ?? ""} />
      <InputFormField
        id="name"
        label={ExperimentFieldLabels.name}
        errors={errors}
        register={register}
        autoFocus={true}
        required={true}
        placeholder="Enter experiment name..."
      />
      <MethodsVirtualizedSelectForm
        id="method"
        label={ExperimentFieldLabels.method}
        control={control}
        horizontal
        allowCreateEntity
      />

      <TextareaFormField
        id="notes"
        label={ExperimentFieldLabels.notes}
        errors={errors}
        register={register}
        placeholder="Enter notes..."
      />
      <FormButtons
        onClose={onCancel}
        onSubmit={handleSubmit((entity) => {
          if (entity.name && entity.method) {
            onSubmit({ ...entity, notes: entity.notes ?? "" } as Experiment);
          }
        })}
        disabled={isSubmitting}
        errors={errors}
        submitButtonLabel="Create"
        loading={loading}
      />
    </>
  );
};
