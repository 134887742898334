import { DefinedPropertyObject, IEntityMinimalModel } from "../../api/GenericTypes";
// import { PersonsVirtualizedSelectForm } from "../../common/forms/EntityForms/formsVirtualized/PersonsVirtualizedSelectForm";
import { SplitDatePickerFormField } from "../../common/formfields/DatePickerFormField/SplitDatePickerFormField";
import { FilterSidebarProps, useFilterSidebar } from "../../common/sidebar/filtersidebar/FilterSidebar";
import { EntityFilterSideBar } from "../../common/sidebar/filtersidebar/EntityFilterBar";
import { InstrumentFieldLabels } from "../../api/Instruments";
import { MethodsVirtualizedSelectForm } from "../../common/forms/EntityForms/formsVirtualized/MethodsVirtualizedSelectForm";
import { InstrumentFacility, InstrumentFacilityFilters } from "../../api/Facilities";
import { DatasetsVirtualizedSelectForm } from "../../common/forms/EntityForms/formsVirtualized/DatasetsVirtualizedSelectForm";
import { SelectFormField } from "../../common/formfields/SelectFormField";
import { CustomTypesVirtualizedSelectForm } from "../../common/forms/EntityForms/formsVirtualized/CustomTypesVirtualizedSelectForm";
import { useContext } from "react";
import { SessionContext } from "../../common/contexts/SessionContext";

type InstrumentStatus = { id: number; name: string; value: boolean };
export interface InstrumentFilterForm {
  methodIds?: IEntityMinimalModel[] | undefined | null;
  datasetIds?: IEntityMinimalModel[] | undefined | null;
  isObsolete?: InstrumentStatus | undefined | null;
  createdByIds?: IEntityMinimalModel[] | undefined | null;
  createdFrom?: Date | undefined | null;
  createdTo?: Date | undefined | null;
  modifiedByIds?: IEntityMinimalModel[] | undefined | null;
  modifiedFrom?: Date | undefined | null;
  modifiedTo?: Date | undefined | null;
  customType?: { id: number; name: string }[] | undefined | null;
}

const defaultValues: DefinedPropertyObject<InstrumentFilterForm> = {
  methodIds: null,
  datasetIds: null,
  isObsolete: null,
  createdByIds: null,
  createdFrom: null,
  createdTo: null,
  modifiedByIds: null,
  modifiedFrom: null,
  modifiedTo: null,
  customType: null,
};

const formToFilters = (form: InstrumentFilterForm): InstrumentFacilityFilters => {
  let filters: InstrumentFacilityFilters = {};

  filters.methodIds =
    Array.isArray(form.methodIds) && form.methodIds.length > 0 ? form.methodIds?.map((d) => d.id) : null;
  filters.datasetIds =
    Array.isArray(form.datasetIds) && form.datasetIds.length > 0 ? form.datasetIds?.map((d) => d.id) : null;
  filters.isObsolete = form.isObsolete?.value ?? null;
  filters.createdByIds =
    Array.isArray(form.createdByIds) && form.createdByIds.length > 0 ? form.createdByIds?.map((d) => d.id) : null;
  filters.createdFrom = form.createdFrom ? form.createdFrom.toISOString() : null;
  filters.createdTo = form.createdTo ? form.createdTo.toISOString() : null;
  filters.modifiedByIds =
    Array.isArray(form.modifiedByIds) && form.modifiedByIds.length > 0 ? form.modifiedByIds?.map((d) => d.id) : null;
  filters.modifiedFrom = form.modifiedFrom ? form.modifiedFrom.toISOString() : null;
  filters.modifiedTo = form.modifiedTo ? form.modifiedTo.toISOString() : null;
  filters.customTypeIds =
    Array.isArray(form.customType) && form.customType.length > 0 ? form.customType.map((d) => d.id) : null;
  return filters;
};

const defaultFilters: InstrumentFacilityFilters = { orderBy: "NAME_ASC" };

export const InstrumentFilterBar = ({
  initialValues,
  setFilters,
  dispatchTabStore,
  onFormChange,
  tabsLoading,
  currentTab,
}: FilterSidebarProps<InstrumentFacility, InstrumentFilterForm, InstrumentFacilityFilters>) => {
  const { session } = useContext(SessionContext);

  const { control, onReset, register } = useFilterSidebar({
    initialValues,
    defaultValues,
    defaultFilters,
    formToFilters,
    onFormChange,
    dispatchTabStore,
    setFilters,
  });

  return (
    <EntityFilterSideBar onReset={onReset}>
      {session?.features.enable_facility_custom_types && (
        <CustomTypesVirtualizedSelectForm
          id="customType"
          control={control}
          showControls={false}
          filters={{ entityTypes: ["Instrument"] }}
          isMulti
          enableBasicOption
          hideIfNoTypes
        />
      )}
      <MethodsVirtualizedSelectForm
        id="methodIds"
        label={InstrumentFieldLabels.method}
        control={control}
        horizontal={false}
        allowCreateEntity
        showControls
        isMulti
      />

      <DatasetsVirtualizedSelectForm
        id="datasetIds"
        label={"Datasets"}
        control={control}
        horizontal={false}
        allowCreateEntity
        showControls
        isMulti
      />

      <SelectFormField
        id="isObsolete"
        label={InstrumentFieldLabels.isObsolete}
        {...register}
        items={
          [
            { id: 2, name: "not in use", value: true },
            { id: 3, name: "in use", value: false },
          ] as InstrumentStatus[]
        }
        control={control}
        horizontal={false}
      />

      {/* {currentTab !== "mydata" && (
        <PersonsVirtualizedSelectForm id="createdByIds" label="Created By" control={control} isMulti />
      )} */}
      <SplitDatePickerFormField
        control={control}
        idStart="createdFrom"
        idEnd="createdTo"
        label="Creation date (from - to)"
        horizontal={false}
        showTimeSelect
      />
      {/* <PersonsVirtualizedSelectForm id="modifiedByIds" label="Modified By" control={control} isMulti /> */}

      <SplitDatePickerFormField
        control={control}
        idStart="modifiedFrom"
        idEnd="modifiedTo"
        label="Modification date (from - to)"
        horizontal={false}
        showTimeSelect
      />
    </EntityFilterSideBar>
  );
};
