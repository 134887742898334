import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { InputFormField } from "../../../formfields/InputFormField";
import { QuickAddChildrenProps } from "../common/QuickAddWrapper";
import { FormButtons } from "../../FormButtons";
import { FormHeader } from "../../FormHeader";
import { useQuickAddSubmit } from "../common/useQuickAddHooks";
import {
  LabNotebook,
  LabNotebookStatusList,
  labNoteBooksConstants,
  labNotebookFieldLabels,
} from "../../../../ELN/types/LabNotebook";
import { SelectFormField } from "../../../formfields/SelectFormField";
import { ProjectsVirtualizedSelectForm } from "../../EntityForms/formsVirtualized/ProjectsVirtualizedSelectForm";
import { TextareaFormField } from "../../../formfields/TextareaFormField";
import { LabNotebookFormValues } from "../../../../ELN/LabNotebooks/Forms/LabNotebookForm";
import { useCallback } from "react";

export const QuickAddLabNotebookForm = ({ onCreate, onCancel, initialValues }: QuickAddChildrenProps<LabNotebook>) => {
  const { onSubmit, loading } = useQuickAddSubmit<LabNotebook>({
    resource: labNoteBooksConstants.resource,
    onCreate: onCreate,
    onCancel: onCancel,
  });

  const FormSchema = yup.object().shape({
    name: yup.string().required("Lab notebook name is required").typeError("Lab notebook name is required"),
    status: yup.object().required("Lab notebook status is required").typeError("Lab notebook status is required"),
  });

  const entityToFormValues = useCallback((entity?: Partial<LabNotebook>) => {
    return !!entity
      ? ({
          ...entity,
          status: entity.status
            ? {
                id: entity.status,
                name: entity.status.charAt(0) + entity.status.slice(1).toLowerCase(),
                value: entity.status,
              }
            : undefined,
        } as LabNotebookFormValues)
      : {};
  }, []);

  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
  } = useForm<LabNotebookFormValues>({
    defaultValues: entityToFormValues(initialValues),
    resolver: yupResolver(FormSchema),
  });

  const formValuesToEntity = useCallback(
    (entity: LabNotebookFormValues) => onSubmit({ ...entity, status: entity.status.value } as LabNotebook),
    [onSubmit]
  );

  return (
    <>
      <FormHeader title="Add lab notebook" />

      <InputFormField
        id="name"
        label={labNotebookFieldLabels.name}
        errors={errors}
        register={register}
        autoFocus={true}
        required
        placeholder="Enter lab notebook name..."
      />

      <SelectFormField
        id="status"
        label={labNotebookFieldLabels.status}
        items={LabNotebookStatusList.map((s) => {
          return { id: s, name: s.charAt(0) + s.slice(1).toLowerCase(), value: s };
        })}
        control={control}
        required
        menuPortalTarget={document.body}
      />

      <ProjectsVirtualizedSelectForm
        id="projects"
        label={labNotebookFieldLabels.projects}
        control={control}
        showControls
        horizontal
        isMulti
        allowCreateEntity
        filters={{ currentUserHasAddPermission: true }}
      />

      <TextareaFormField
        id="description"
        label={labNotebookFieldLabels.description}
        errors={errors}
        register={register}
        placeholder="Enter description..."
      />

      <FormButtons
        groupName={labNoteBooksConstants.frontendIndexRoute}
        entityId={undefined}
        onClose={onCancel}
        onSubmit={handleSubmit(formValuesToEntity)}
        disabled={isSubmitting}
        errors={errors}
        disableDefaultOnClose
        submitButtonLabel="Create"
        loading={loading}
      />
    </>
  );
};
