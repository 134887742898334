import {
  PropertyTranslator,
  IGenericRequestParametersTranslator,
  IPaginationParametersTranslator,
  ICreatedRecordParametersTranslator,
  IModifiedRecordParametersTranslator,
  IRelationParametersTranslator,
  INamedEntityParametersTranslator,
  ICreationRecordLabels,
  IModificationRecordLabels,
  INamedEntityLabels,
  IPermissionedEntityLabels,
  IRelatedEntityLabels,
  IUniqueEntityLabels,
} from "./GenericTranslator";
import {
  DefaultCreatedByRecordOrderTypeConsts,
  DefaultCreatedOnRecordOrderTypeConsts,
  DefaultModifiedByRecordOrderTypeConsts,
  DefaultModifiedOnRecordOrderTypeConsts,
  DefaultOrderTypeConsts,
  EntityConstants,
  ICreatedRecordParameters,
  ICreationRecord,
  IEntityMinimalModel,
  IGenericRequestParameters,
  IModelWithIntId,
  IModificationRecord,
  IModifiedRecordParameters,
  INamedEntity,
  INamedEntityParameters,
  IPaginationParameters,
  IPermissionedEntity,
  IRelatedEntity,
  IRelation,
  IRelationModel,
  IRelationParameters,
  IUniqueEntity,
} from "./GenericTypes";

export const methodsConstants: EntityConstants<Method, MethodFilters> = {
  resource: "methods",
  frontendIndexRoute: "methods",
  entitySingular: "method",
  entityPlural: "methods",
  icon: "microscope",
};

export const MethodFieldLabels: PropertyTranslator<Method> = {
  ...INamedEntityLabels,
  ...IPermissionedEntityLabels,
  ...ICreationRecordLabels,
  ...IModificationRecordLabels,
  ...IRelatedEntityLabels,
  ...IUniqueEntityLabels,
  id: "Method ID",
  fullName: "Full name",
  description: "Description",
} as const;

export interface Method
  extends IModelWithIntId,
    INamedEntity,
    IPermissionedEntity,
    IUniqueEntity,
    ICreationRecord,
    IRelatedEntity<MethodRelations>,
    IModificationRecord {
  fullName: string;
  description: string;
}
export interface MethodSuggestions extends IEntityMinimalModel<Method["id"]> {}

export interface MethodRelations extends IRelationModel {
  datasets: IRelation;
  experiments: IRelation;
}
export interface MethodFilters
  extends IGenericRequestParameters<Method, MethodSortingOptions>,
    IPaginationParameters,
    IRelationParameters,
    ICreatedRecordParameters,
    IModifiedRecordParameters,
    INamedEntityParameters {}

export const MethodFiltersTranslator: PropertyTranslator<MethodFilters> = {
  ...IGenericRequestParametersTranslator,
  ...IPaginationParametersTranslator,
  ...ICreatedRecordParametersTranslator,
  ...IModifiedRecordParametersTranslator,
  ...INamedEntityParametersTranslator,
  ...IRelationParametersTranslator,
} as const;

export const MethodSortingOptionsConsts = [
  ...DefaultOrderTypeConsts,
  ...DefaultCreatedOnRecordOrderTypeConsts,
  ...DefaultCreatedByRecordOrderTypeConsts,
  ...DefaultModifiedOnRecordOrderTypeConsts,
  ...DefaultModifiedByRecordOrderTypeConsts,
  "FULL_NAME_ASC",
  "FULL_NAME_DESC",
] as const;
export type MethodSortingOptions = (typeof MethodSortingOptionsConsts)[number];
