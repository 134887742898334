import React, { useContext, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { InstrumentFacility, instrumentsConstants } from "../../../../api/Facilities";
import { useForm, useWatch } from "react-hook-form";
import { FormButtons } from "../../FormButtons";
import { FormHeader } from "../../FormHeader";
import { QuickAddChildrenProps } from "../common/QuickAddWrapper";
import { InputFormField } from "../../../formfields/InputFormField";
import { TextareaFormField } from "../../../formfields/TextareaFormField";
import { useQuickAddSubmit } from "../common/useQuickAddHooks";
import { MethodsVirtualizedSelectForm } from "../../EntityForms/formsVirtualized/MethodsVirtualizedSelectForm";
import { InstrumentFieldLabels } from "../../../../api/Instruments";
import { OrganizationsVirtualizedSelectForm } from "../../EntityForms/formsVirtualized/OrganizationsVirtualizedSelectForm";
import { PersonsVirtualizedSelectForm } from "../../EntityForms/formsVirtualized/PersonsVirtualizedSelectForm";
import { FormFieldLayout } from "../../../formfields/FormFieldLayouts";
import { ToggleButtonComponent } from "../../../../ViewerUIElements/ToggleButtonComponent";
import {
  EntityCustomTypeForm,
  useCustomTypeForm,
} from "../../../../Customization/CustomTypes/generics/useCustomTypeForm";
import { InstrumentFormSchema } from "../../../../Instruments/InstrumentForm";
import { CustomTypesVirtualizedSelectForm } from "../../EntityForms/formsVirtualized/CustomTypesVirtualizedSelectForm";
import { SessionContext } from "../../../contexts/SessionContext";

export const QuickAddInstrumentForm = ({
  onCreate,
  onCancel,
  initialValues,
}: QuickAddChildrenProps<InstrumentFacility>) => {
  const { session } = useContext(SessionContext);

  const [isObsolete, setIsObsolete] = useState<boolean>(false);

  const { onSubmit, loading } = useQuickAddSubmit<InstrumentFacility>({
    resource: instrumentsConstants.resource,
    onCreate: onCreate,
    onCancel: onCancel,
  });

  const { defaultValues, typedFormSchema, type, processCustomFields, setType, types } = useCustomTypeForm({
    initialValues: initialValues,
    formSchema: InstrumentFormSchema,
    typeId: initialValues?.customType?.id,
    entityType: "Instrument",
    featureFlag: session?.features.enable_facility_custom_types,
  });

  const {
    control,
    register,
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm<Partial<InstrumentFacility>>({
    values: defaultValues,
    resolver: yupResolver(typedFormSchema),
  });

  const _customType = useWatch({ name: "customType", control: control });
  const typesLength = Object.keys(types ?? {}).length;

  return (
    <>
      <FormHeader title={"Add instrument"} subtitle={""} />
      {!!typesLength && !_customType ? (
        <CustomTypesVirtualizedSelectForm
          id={"customType"}
          control={control}
          concatItems={[{ name: "Basic instrument", id: -1 }]}
          filters={{ entityTypes: ["Instrument"] }}
          horizontal
        />
      ) : (
        <>
          <InputFormField
            id="name"
            label={InstrumentFieldLabels.name}
            errors={errors}
            register={register}
            autoFocus={true}
            required={true}
            placeholder="Enter instrument name..."
          />

          <MethodsVirtualizedSelectForm
            id="method"
            label={InstrumentFieldLabels.method}
            control={control}
            horizontal
            allowCreateEntity
            required={true}
          />

          <InputFormField
            id="serialnumber"
            label={InstrumentFieldLabels.serialnumber}
            errors={errors}
            register={register}
            placeholder="Enter serialnumber..."
          />

          <InputFormField
            id="model"
            label={InstrumentFieldLabels.model}
            errors={errors}
            register={register}
            placeholder="Enter model..."
          />

          <InputFormField
            id="room"
            label={InstrumentFieldLabels.room}
            errors={errors}
            register={register}
            placeholder="Enter room..."
          />

          <InputFormField
            id="localPhone"
            label={InstrumentFieldLabels.localPhone}
            errors={errors}
            register={register}
            placeholder="Enter local phone number..."
          />

          <OrganizationsVirtualizedSelectForm
            id="homeLab"
            label={InstrumentFieldLabels.homeLab}
            control={control}
            horizontal
            allowCreateEntity
          />

          <PersonsVirtualizedSelectForm
            id="homeLabContacts"
            label={InstrumentFieldLabels.homeLabContacts}
            control={control}
            horizontal
            isMulti
            allowCreateEntity
          />

          <OrganizationsVirtualizedSelectForm
            id="company"
            label={InstrumentFieldLabels.company}
            control={control}
            horizontal
            allowCreateEntity
          />

          <PersonsVirtualizedSelectForm
            id="companyContacts"
            label={InstrumentFieldLabels.companyContacts}
            control={control}
            horizontal
            isMulti
            allowCreateEntity
          />

          <FormFieldLayout id="isObsolete" label={InstrumentFieldLabels.isObsolete} hasLabel horizontal>
            <div className="flex align-center gap-5" style={{ padding: "10px 0" }}>
              <ToggleButtonComponent checked={!isObsolete} setChecked={(checked) => setIsObsolete(!checked)}>
                <></>
              </ToggleButtonComponent>
              {isObsolete && <div className="label label-soft-danger">Not in use</div>}
            </div>
          </FormFieldLayout>

          <TextareaFormField
            id="notes"
            label={InstrumentFieldLabels.notes}
            errors={errors}
            register={register}
            placeholder="Enter notes..."
          />
          <EntityCustomTypeForm
            entityType="Instrument"
            typeId={initialValues?.customType?.id}
            type={type}
            types={types}
            setType={setType}
            control={control}
            register={register}
            setValue={setValue}
            initialValues={initialValues}
            errors={errors}
            entityConstants={instrumentsConstants}
          />
          <FormButtons
            groupName="instruments"
            entityId={undefined}
            onClose={onCancel}
            onSubmit={handleSubmit(
              async (entity) => await onSubmit(processCustomFields({ ...entity, isObsolete: isObsolete }))
            )}
            disabled={isSubmitting}
            errors={errors}
            submitButtonLabel="Create"
            loading={loading}
          />
        </>
      )}
    </>
  );
};
