import { useHistory } from "react-router-dom";
import { useContext, useMemo, useState } from "react";
import { SessionContext } from "../../common/contexts/SessionContext";
import { SearchInput } from "../../common/forms/SearchInput/SearchInput";
import { LucideIcon } from "../../common/icon/LucideIcon";
import TableView, { TableViewLabel } from "../../common/panels/TableView/TableView";
import { getAddRoute } from "../../main/Routing";
import { ColumnsSettings } from "../../common/tables/ColumnsSelector/ColumnsSelector";
import { AlertModal } from "../../common/modals/AlertModal/AlertModal";
// import { showtoast } from "../../common/overlays/Toasts/showtoast";
import { MoreDropdown } from "../../common/buttons/MoreDropdown/MoreDropdown";
import { EntityTable } from "../../common/tables/EntityTable/EntityTable";
import {
  GenericVirtualizedTableCells,
  SortState,
} from "../../common/tables/GenericVirtualizedTable/GenericVirtualizedTableTypes";
import { EntityFilterIndicator } from "../../common/tables/EntityFilterIndicator/EntityFilterIndicator";
import { EntityTableTabs } from "../../common/tables/Tabs/EntityTableTabs";
import { useTabStore } from "../../common/tables/Tabs/useTabStore";
import { TableTabsDict } from "../../common/tables/Tabs/TableTabsTypes";
import { Organization, OrganizationFilters, OrganizationFiltersTranslator } from "../../api/Organizations";
import { OrganizationFilterBar, OrganizationFilterForm } from "./OrganizationFilterBar";
import { NotSet } from "../../common/misc/UIconstants";
import { DateTimeRenderer } from "../../common/datetime/DateTimeFormatter";
import { GetPersons } from "../../common/misc/EntityRenders/EntityRenderer";
import {
  UseEntityTableDefaultProps,
  UseEntityTableProps,
  useGenericVirtualizedTable,
  useGenericVirtualizedTableTabs,
} from "../../common/tables/GenericVirtualizedTable/useGenericVirtualizedTable";
import { EntityTableProps } from "../../common/entity/EntityInterfaces";
import { EntityTableDeleteButton } from "../../common/entity/entityComponents/EntityTableDeleteButton";
import { EntityTableEditButton } from "../../common/entity/entityComponents/EntityTableEditButton";

export const switchOrganizationsDefaultSortState = (
  sortState: OrganizationFilters["orderBy"]
): SortState<OrganizationFilters["orderBy"]> => {
  switch (sortState) {
    case "ID_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-id",
        orderBy: sortState,
      };
    case "ID_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-id",
        orderBy: sortState,
      };
    case "NAME_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-name",
        orderBy: sortState,
      };
    case "NAME_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-name",
        orderBy: sortState,
      };
    case "CREATED_ON_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-createdOn",
        orderBy: sortState,
      };
    case "CREATED_ON_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-createdOn",
        orderBy: sortState,
      };
    case "CREATED_BY_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-createdBy",
        orderBy: sortState,
      };
    case "CREATED_BY_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-createdBy",
        orderBy: sortState,
      };
    case "MODIFIED_ON_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-modifiedOn",
        orderBy: sortState,
      };
    case "MODIFIED_ON_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-modifiedOn",
        orderBy: sortState,
      };
    case "MODIFIED_BY_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-modifiedBy",
        orderBy: sortState,
      };
    case "MODIFIED_BY_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-modifiedBy",
        orderBy: sortState,
      };
    default:
      return {
        sortDirection: "ASC",
        headerId: "default-name",
        orderBy: sortState,
      };
  }
};
const defaultFilter: OrganizationFilters = { orderBy: "NAME_ASC" };

export const useOrganizationsTableDefaults = ({ fieldLabels }: UseEntityTableDefaultProps<"organizations">) => {
  const defaults: ColumnsSettings<Organization> = useMemo(
    () => ({
      "default-id": { pos: 0, active: false, header: fieldLabels.id, property: "id" },
      "default-name": { pos: 1, active: true, header: fieldLabels.name, property: "name" },
      "default-organizationTags": {
        pos: 3,
        active: true,
        header: fieldLabels.organizationTags,
        property: "organizationTags",
      },
      "default-email": { pos: 4, active: true, header: fieldLabels.email, property: "email" },
      "default-phone": { pos: 5, active: true, header: fieldLabels.phoneOffice, property: "phoneOffice" },
      "default-fax": { pos: 6, active: true, header: fieldLabels.faxOffice, property: "faxOffice" },
      "default-adress": { pos: 7, active: true, header: fieldLabels.address, property: "address" },
      "default-web": { pos: 8, active: true, header: fieldLabels.web, property: "web" },
      "default-createdOn": { pos: 9, active: true, header: fieldLabels.createdOn, property: "createdOn" },
      "default-createdBy": { pos: 10, active: true, header: fieldLabels.createdBy, property: "createdBy" },
      "default-modifiedOn": {
        pos: 11,
        active: true,
        header: fieldLabels.modifiedOn,
        property: "modifiedOn",
      },
      "default-modifiedBy": {
        pos: 12,
        active: true,
        header: fieldLabels.modifiedBy,
        property: "modifiedBy",
      },
    }),
    [
      fieldLabels.address,
      fieldLabels.createdBy,
      fieldLabels.createdOn,
      fieldLabels.email,
      fieldLabels.faxOffice,
      fieldLabels.id,
      fieldLabels.modifiedBy,
      fieldLabels.modifiedOn,
      fieldLabels.name,
      fieldLabels.organizationTags,
      fieldLabels.phoneOffice,
      fieldLabels.web,
    ]
  );

  const tabStoreDefaults: TableTabsDict<Organization, OrganizationFilters, OrganizationFilterForm> = useMemo(
    () => ({
      default: {
        tabId: "default",
        type: "fixed",
        label: "All",
        title: "All",
        icon: "house",
        align: "left",
        xPos: 0,
        settings: {
          columnSettings: {},
          columnWidths: {},
          filters: defaultFilter,
          sidebarFilters: {},
        },
        forcedSettings: {
          columnSettings: {},
          columnWidths: {},
          filters: {},
          sidebarFilters: {},
        },
      },
    }),
    []
  );

  return { defaults, tabStoreDefaults };
};

export const useOrganizationsTableColumns = ({
  entityConstants,
  fieldLabels,
  sort,
  setSort,
}: UseEntityTableProps<"organizations">) => {
  const columns = useMemo(() => {
    let headers: GenericVirtualizedTableCells<Organization> = [
      {
        id: "default-id",
        Header: fieldLabels.id,
        accessor: (row) => <span style={{ color: "var(--gray-400)" }}>{row.id}</span>,
        width: 120,
        minWidth: 120,
        align: "center",
        sortingFn: (id) => {
          if (sort.headerId === id) {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "ID_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "ID_ASC" }));
            }
          } else {
            setSort({ headerId: id, sortDirection: "ASC", orderBy: "ID_ASC" });
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
      {
        Header: fieldLabels.name,
        id: "default-name",
        accessor: (row) => (
          <div
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              fontWeight: 600,
              whiteSpace: "nowrap",
              marginRight: "5px",
            }}
          >
            <LucideIcon name={entityConstants.icon} color="var(--primary)" />
            <span> {row.name}</span>
          </div>
        ),
        minWidth: 150,
        width: 250,
        align: "left",
        sortingFn: (id) => {
          if (sort.headerId === id) {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "NAME_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "NAME_ASC" }));
            }
          } else {
            setSort({ headerId: id, sortDirection: "ASC", orderBy: "NAME_ASC" });
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
      {
        id: "default-organizationTags",
        Header: fieldLabels.organizationTags,
        accessor: (row) => (
          <div style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
            {!!row.organizationTags?.length
              ? row.organizationTags.map((t, i) => (
                  <span key={i} className="label label-soft-secondary" style={{ marginRight: "5px" }}>
                    {t.name}
                  </span>
                ))
              : NotSet}
          </div>
        ),
        minWidth: 100,
        width: 400,
        align: "left",
      },
      {
        Header: fieldLabels.email,
        id: "default-email",
        accessor: (row) => (
          <div
            style={{ display: "flex", flexFlow: "row nowrap", gap: "5px", overflow: "hidden", alignItems: "center" }}
          >
            <div style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
              {row.email ? (
                <span style={{ fontWeight: 500, whiteSpace: "nowrap", marginRight: "5px" }}>{row.email}</span>
              ) : (
                NotSet
              )}
            </div>
          </div>
        ),
        minWidth: 300,
        width: 300,
        align: "left",
      },
      {
        Header: fieldLabels.phoneOffice,
        id: "default-phone",
        accessor: (row) => (
          <div
            style={{ display: "flex", flexFlow: "row nowrap", gap: "5px", overflow: "hidden", alignItems: "center" }}
          >
            <div style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
              {row.phoneOffice ? (
                <span style={{ fontWeight: 500, whiteSpace: "nowrap", marginRight: "5px" }}>{row.phoneOffice}</span>
              ) : (
                NotSet
              )}
            </div>
          </div>
        ),
        minWidth: 150,
        width: 250,
        align: "left",
      },
      {
        Header: fieldLabels.faxOffice,
        id: "default-fax",
        accessor: (row) => (
          <div
            style={{ display: "flex", flexFlow: "row nowrap", gap: "5px", overflow: "hidden", alignItems: "center" }}
          >
            <div style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
              {row.faxOffice ? (
                <span style={{ fontWeight: 500, whiteSpace: "nowrap", marginRight: "5px" }}>{row.faxOffice}</span>
              ) : (
                NotSet
              )}
            </div>
          </div>
        ),
        minWidth: 150,
        width: 250,
        align: "left",
      },
      {
        Header: fieldLabels.address,
        id: "default-adress",
        accessor: (row) => (
          <div
            style={{ display: "flex", flexFlow: "row nowrap", gap: "5px", overflow: "hidden", alignItems: "center" }}
          >
            <div style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
              {row.address ? (
                <span style={{ fontWeight: 500, whiteSpace: "nowrap", marginRight: "5px" }}>{row.address}</span>
              ) : (
                NotSet
              )}
            </div>
          </div>
        ),
        minWidth: 150,
        width: 250,
        align: "left",
      },
      {
        Header: fieldLabels.web,
        id: "default-web",
        accessor: (row) => (
          <div style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
            {row.web ? (
              <span style={{ fontWeight: 500, whiteSpace: "nowrap", marginRight: "5px" }}>{row.web}</span>
            ) : (
              NotSet
            )}
          </div>
        ),
        minWidth: 150,
        width: 250,
        align: "left",
      },
      {
        id: "default-createdOn",
        Header: fieldLabels.createdOn,
        accessor: (row) => <DateTimeRenderer date={row.createdOn} includeElapsed={false} />,
        width: 200,
        align: "left",
        sortingFn: (id) => {
          if (sort.headerId === id) {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "CREATED_ON_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "CREATED_ON_ASC" }));
            }
          } else {
            setSort({ headerId: id, sortDirection: "ASC", orderBy: "CREATED_ON_ASC" });
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
      {
        id: "default-createdBy",
        Header: fieldLabels.createdBy,
        accessor: (row) => <GetPersons persons={row.createdBy} createLinks={false} />,
        width: 200,
        align: "left",
        sortingFn: (id) => {
          if (sort.headerId === id) {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "CREATED_BY_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "CREATED_BY_ASC" }));
            }
          } else {
            setSort({ headerId: id, sortDirection: "ASC", orderBy: "CREATED_BY_ASC" });
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
      {
        id: "default-modifiedOn",
        Header: fieldLabels.modifiedOn,
        accessor: (row) => <DateTimeRenderer date={row.modifiedOn} includeElapsed={false} />,
        width: 200,
        align: "left",
        sortingFn: (id) => {
          if (sort.headerId === id) {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "MODIFIED_ON_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "MODIFIED_ON_ASC" }));
            }
          } else {
            setSort({ headerId: id, sortDirection: "ASC", orderBy: "MODIFIED_ON_ASC" });
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
      {
        id: "default-modifiedBy",
        Header: fieldLabels.modifiedBy,
        accessor: (row) => <GetPersons persons={row.modifiedBy} createLinks={false} />,
        width: 200,
        align: "left",
        sortingFn: (id) => {
          if (sort.headerId === id) {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "MODIFIED_BY_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "MODIFIED_BY_ASC" }));
            }
          } else {
            setSort({ headerId: id, sortDirection: "ASC", orderBy: "MODIFIED_BY_ASC" });
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
    ];

    return headers;
  }, [
    entityConstants.icon,
    fieldLabels.address,
    fieldLabels.createdBy,
    fieldLabels.createdOn,
    fieldLabels.email,
    fieldLabels.faxOffice,
    fieldLabels.id,
    fieldLabels.modifiedBy,
    fieldLabels.modifiedOn,
    fieldLabels.name,
    fieldLabels.organizationTags,
    fieldLabels.phoneOffice,
    fieldLabels.web,
    setSort,
    sort.headerId,
    sort.sortDirection,
  ]);
  return { columns };
};
export const OrganizationsTable = ({
  entityApi,
  entityConstants,
  fieldLabels,
  permissions,
  routes,
}: EntityTableProps<"organizations">) => {
  const history = useHistory();
  const [showModal, setShowModal] = useState(false);
  const { bulkDeleteMutationAsync } = entityApi;
  const { route, session } = useContext(SessionContext);

  const { defaults, tabStoreDefaults } = useOrganizationsTableDefaults({ fieldLabels });

  const { selection, resultsCount, selectionPermissions, onCountChange, onSelectionChange, onSelectionPermissions } =
    useGenericVirtualizedTable<Organization>();

  const {
    filters,
    sidebarFilters,
    forcedFilters,
    columnSetting,
    columnWidths,
    customTabs,
    fixedTabs,
    dispatchTabStore,
    currentTab,
    tabsLoading,
    tabsModified,
  } = useTabStore<Organization, OrganizationFilters, OrganizationFilterForm>({
    resource: entityConstants.resource,
    defaults: tabStoreDefaults,
  });

  const { functionRef, onTabChange, sort, setSort, searchValue, setSearchValue } = useGenericVirtualizedTableTabs({
    tabsLoading,
    filters,
    switchSortState: switchOrganizationsDefaultSortState,
    dispatchTabStore,
  });
  const { columns } = useOrganizationsTableColumns({ entityConstants, fieldLabels, sort, setSort });

  const memoizedFilters = useMemo(
    () => ({ ...filters, ...forcedFilters, includeRelations: true }),
    [filters, forcedFilters]
  );

  return (
    <TableView>
      <TableView.Head>
        <TableView.Head.Label>
          <TableViewLabel entityConstants={entityConstants} resultsCount={resultsCount} />
        </TableView.Head.Label>
        <TableView.Head.Controls>
          <button
            className="btn btn-primary"
            title={`Add ${entityConstants.entitySingular}`}
            onClick={() => history.push(route(getAddRoute(entityConstants.frontendIndexRoute)))}
            disabled={!session?.permissions.administer_organizations}
          >
            <LucideIcon name="plus" /> Add {entityConstants.entitySingular}
          </button>
        </TableView.Head.Controls>
      </TableView.Head>
      <TableView.Body>
        <TableView.Body.Sidebar>
          <OrganizationFilterBar
            initialValues={sidebarFilters}
            dispatchTabStore={dispatchTabStore}
            tabsLoading={tabsLoading}
            currentTab={currentTab}
          />
        </TableView.Body.Sidebar>
        <TableView.Body.Content>
          <EntityTableTabs
            currentTab={currentTab}
            onTabChange={onTabChange}
            fixedTabs={fixedTabs}
            filters={filters}
            sidebarFilters={sidebarFilters}
            columnSetting={columnSetting}
            columnWidths={columnWidths}
            customTabs={customTabs}
            dispatchTabStore={dispatchTabStore}
            tabsLoading={tabsLoading}
            tabsModified={tabsModified}
          />
          <EntityTable>
            <EntityTable.Controls
              style={{
                borderTop: "0px",
                borderRadius: "0px",
              }}
            >
              <EntityFilterIndicator<Organization, OrganizationFilters>
                filters={filters}
                excludeFilters={{}}
                translatorConsts={OrganizationFiltersTranslator}
              />
              <SearchInput searchValue={searchValue} setSearchValue={setSearchValue} placeholder="Search" />
              <EntityTableEditButton
                entityConstants={entityConstants}
                routes={routes}
                permissions={permissions}
                selection={selection}
                selectionPermissions={selectionPermissions}
              />
              <MoreDropdown drop="right" btn="btn btn-ghost-secondary">
                <li>
                  <EntityTableDeleteButton
                    entityConstants={entityConstants}
                    permissions={permissions}
                    selection={selection}
                    selectionPermissions={selectionPermissions}
                    onClick={() => setShowModal(true)}
                  />
                </li>
              </MoreDropdown>
              <AlertModal
                type="danger"
                showModal={showModal}
                setShowModal={setShowModal}
                title={`${
                  selection.size === 0
                    ? `Select ${entityConstants.entitySingular} to delete`
                    : selection.size === 1
                    ? `Delete the selected ${entityConstants.entitySingular}`
                    : `Delete the selected ${entityConstants.entityPlural}`
                }`}
                description={`Proceeding will permanently delete the selected ${entityConstants.entityPlural}.`}
                proceedLabel="Delete"
                onProceed={async () => {
                  await bulkDeleteMutationAsync({
                    ids: Array.from(selection),
                    goBackOnSuccess: false,
                    showToast: false,
                    entityName: entityConstants.entityPlural,
                  }).catch((e) => {});
                  functionRef.current?.resetSelection();
                  setShowModal(false);
                }}
              />
            </EntityTable.Controls>
            <EntityTable.Body<Organization, OrganizationFilters>
              functionRef={functionRef}
              entityConstants={entityConstants}
              filters={memoizedFilters}
              columns={columns}
              columnSelect
              columnSetting={columnSetting}
              columnWidths={columnWidths}
              defaultColumnSettings={defaults}
              dispatchTabStore={dispatchTabStore}
              setResultsCount={onCountChange}
              onSelectionChange={onSelectionChange}
              onSelectionPermissions={onSelectionPermissions}
              showPermissionColumn
              loading={tabsLoading}
            />
          </EntityTable>
        </TableView.Body.Content>
      </TableView.Body>
    </TableView>
  );
};
