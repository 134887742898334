import { useEffect, useState, useRef } from "react";
import { TrackToolType } from "./ViewerNavigation";

import styles from "./SliderTool.module.css";
import { TrackSliderComponent } from "../../TrackTools/TrackSliderComponent";
import { Track, trackSettingsType } from "../../ViewerLayout/ViewerLayoutTypes";
import { useTranslatedFieldUpdate } from "../../ViewerLayout/ViewerLayoutHooks";

type trackSelectionMode = "all" | "selected";

export const SliderTool = ({ tracks, changeTrackSettings, setMinHeight, setMinWidth }: TrackToolType) => {
  const [sliderTracks, setSliderTracks] = useState<string[]>([]);
  const [selectionMode, setSelectionMode] = useState<trackSelectionMode>("all");

  const val = useRef<Record<string, boolean>>();

  const activeTracks = useTranslatedFieldUpdate(
    tracks,
    (tracks: Record<string, Track>) =>
      Object.values(tracks)
        .filter((track) => track.settings.active)
        .map((track) => track.id),
    []
  );

  useEffect(() => {
    let mode: trackSelectionMode = "selected";
    let list = activeTracks.filter((id) => tracks[id].settings.selected);
    // console.log("selected", list);
    if (list.length < 1) {
      list = activeTracks;
      mode = "all";
    }
    setSelectionMode(mode);

    const found = Object.fromEntries(Object.entries(sliderTracks).map((id) => [id, false]));

    let changed = false;
    for (let i = 0; i < list.length; i++) {
      if (list[i] in found) found[list[i]] = true;
      else {
        changed = true;
        break;
      }
    }
    if (!changed) changed = Object.values(found).some((f) => !f);

    if (changed) setSliderTracks(list);
  }, [activeTracks]);

  useEffect(() => {
    if (setMinHeight) setMinHeight(230);
    if (setMinWidth) setMinWidth(400);

    // const l = Object.fromEntries(selectedTracks.map((id) => [id, tracks[id].settings.visible]));

    // val.current = Object.fromEntries(selectedTracks.map((id) => [id, tracks[id].settings.visible]));
    // changeTrackSettings(selectedTracks.map((id) => ({ id: id, settings: { visible: false } as trackSettingsType })));

    val.current = Object.fromEntries(Object.values(tracks).map((track) => [track.id, track.settings.visible]));
    changeTrackSettings(
      Object.values(tracks).map((track) => ({ id: track.id, settings: { visible: false } as trackSettingsType }))
    );

    return () => {
      const d: Record<string, boolean> = val.current || {};

      changeTrackSettings(
        Object.entries(d).map(([id, visible]) => ({ id: id, settings: { visible: visible } as trackSettingsType }))
      );
    };
  }, []);

  return (
    <div className={styles.viewerButtonColumn} style={{ flexGrow: 1 }}>
      <div className={styles.trackSliderHead}>
        <div>
          Browse:{" "}
          <b>
            {selectionMode === "selected"
              ? sliderTracks.length + " tracks selected"
              : "all " + sliderTracks.length + " tracks selected"}
            {/* {sliderTracks.length !== selectedTracks.length ? "selected tracks" : "all tracks"} */}
          </b>
        </div>
      </div>
      <TrackSliderComponent
        tracks={tracks}
        trackList={sliderTracks}
        setCurrentTrack={() => {}}
        // setPinnedTracks={() => {}}
        changeTrackSettings={changeTrackSettings}
      />
    </div>
  );
};
