import {
  Dispatch,
  // KeyboardEvent,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useEntityDetail, useGet } from "../../../api/BaseEntityApi";
import { ResourceName } from "../../../main/Routing";
import styles from "./EntityHistory.module.css";
import Detail from "../../../common/panels/Detail/Detail";
import { GenericEntity, IVersionedEntity } from "../../../api/GenericTypes";
import { AuditLogTile } from "../../../AuditLog/AuditLogTile";
import { ELNNoContentContainer } from "../ELNNoContentContainer/ELNNoContentContainer";
// import { Dataset } from "../../../api/Datasets";
import { LabNotebookEntry } from "../../types/LabNotebookEntry";
import { LabNotebookEntryViewOnly } from "../../LabNotebookEntry/LabNotebookEntryViewOnly/LabNotebookEntryViewOnly";
import {
  LabNotebookExperiment,
  labNotebookExperimentFieldLabels,
  labNotebookExperimentsConstants,
} from "../../types/LabNotebookExperiment";
import { LabNotebook } from "../../types/LabNotebook";
import { Sample, SampleFieldLabels, samplesConstants } from "../../../api/Samples";
import { LabNotebookTemplate } from "../../types/LabNotebookTemplate";
import { TextEditor } from "../TextEditor/TextEditor";
import { LucideIcon } from "../../../common/icon/LucideIcon";
import { IPermissionedEntity } from "../../../api/GenericTypes";
import { SessionContext } from "../../../common/contexts/SessionContext";
import { showtoast } from "../../../common/overlays/Toasts/showtoast";
import { useQueryClient } from "@tanstack/react-query";
import { LAB_NOTEBOOK_TOC_QUERY_KEY } from "../tiles/LabNotebookTocTile/LabNotebookTocTile";
import { LabNotebookTemplateFilterBar } from "../../LabNotebookTemplates/Table/LabNotebookTemplatesFilterBar";
import { LabNotebookFilterBar } from "../../LabNotebooks/Table/LabNotebooksFilterBar";
import { AlertModal } from "../../../common/modals/AlertModal/AlertModal";
import { LabNotebookDetailContentTable } from "../../LabNotebooks/LabNotebookDetailContentTable";
import { LabNotebookExperimentFilterBar } from "../../LabNotebookExperiments/Table/LabNotebookExperimentsFilterBar";
import { LabNotebookEntriesFilterBar } from "../../LabNotebookEntries/Table/LabNotebookEntriesFilterBar";
import { SampleDetailTable } from "../../../samples/SamplesDetail";
import { LabNotebookExperimentDetailContentTable } from "../../LabNotebookExperiments/LabNotebookExperimentDetailContentTable";

interface VersionReadModel extends GenericEntity, IVersionedEntity, IPermissionedEntity {}

export const GetEntityFilterComponent = <Filters extends { [property: string]: any }>(
  resource: ResourceName,
  filters: Filters,
  setFilters: Dispatch<SetStateAction<any>>
) => {
  switch (resource) {
    case "datasets":
      return null;
    case "lab_notebook_entries":
      return <LabNotebookEntriesFilterBar setFilters={setFilters} />;
    case "lab_notebook_experiments":
      return <LabNotebookExperimentFilterBar setFilters={setFilters} />;
    case "lab_notebooks":
      return <LabNotebookFilterBar setFilters={setFilters} />;
    case "persons":
      return null;
    case "projects":
      return null;
    case "samples":
      return null;
    case "lab_notebook_templates":
      return <LabNotebookTemplateFilterBar setFilters={setFilters} />;

    default:
      return {};
  }
};

export const GetEntityVersionView = (entity: GenericEntity, resource: ResourceName) => {
  switch (resource) {
    case "datasets":
      //   const dataset = entity as Dataset;
      return null;
    case "lab_notebook_entries":
      const entry = entity as LabNotebookEntry;
      return (
        <div style={{ padding: "20px" }}>
          <LabNotebookEntryViewOnly entry={entry} printMode showModifiedState useEntry />
        </div>
      );
    case "lab_notebook_experiments":
      const experiment = entity as LabNotebookExperiment;
      return (
        <div style={{ width: "100%", padding: "20px", height: "100%", backgroundColor: "var(--white)" }}>
          <LabNotebookExperimentDetailContentTable
            entity={experiment}
            entityConstants={labNotebookExperimentsConstants}
            fieldLabels={labNotebookExperimentFieldLabels}
          />
        </div>
      );
    case "lab_notebooks":
      const labNotebook = entity as LabNotebook;
      return (
        <div style={{ width: "100%", padding: "20px", height: "100%", backgroundColor: "var(--white)" }}>
          <LabNotebookDetailContentTable labNotebook={labNotebook} />
        </div>
      );
    case "persons":
      // const person = entity as Person;
      return null;
    case "projects":
      // const project = entity as Project;
      return null;
    case "samples":
      const sample = entity as Sample;
      return <SampleDetailTable entity={sample} entityConstants={samplesConstants} fieldLabels={SampleFieldLabels} />;
    case "lab_notebook_templates":
      const template = entity as LabNotebookTemplate;
      return (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div
            style={{
              margin: "20px",
              width: "210mm",
              minWidth: "210mm",
              maxWidth: "210mm",
              height: "100%",
              backgroundColor: "var(--white)",
              padding: "40px",
              boxShadow: "1px 1px 1px 1px var(--gray-300)",
              border: "1px solid var(--gray-200)",
            }}
          >
            <TextEditor editable={false} save={() => {}} initialContent={template.content} />
          </div>
        </div>
      );

    default:
      return <>{"-"}</>;
  }
};

export const EntityHistory = <Entity extends VersionReadModel>({
  resource,
  id,
  onRestore,
}: {
  resource: ResourceName;
  id: number;
  onRestore?: () => void;
}) => {
  const [version, setVersion] = useState<number>(0);

  const {
    data: entityOriginal,
    // isFetching: isFetchingEntityOriginal,
    // status: statusEntityOriginal,
    // error: errorEntityOriginal,
  } = useEntityDetail<Entity>(
    resource,
    id,
    {
      includeContent: resource === "lab_notebook_entries" ? true : undefined,
    },
    // We override the queryKey, because if not, it replaces the cache of the get request at a
    // higher level in the DOM, which will sometimes rerender (and potentially close modals)
    { queryKey: [resource, "detail", id, "version"] }
  );

  useEffect(() => {
    if (entityOriginal && entityOriginal.version >= 1) setVersion(entityOriginal.version);
  }, [entityOriginal]);

  const {
    data: entityVersionData,
    // isFetching: isFetchingEntityVersion,
    // status: statusEntityVersion,
    // error: errorEntityVersion,
  } = useGet(
    `${resource}/${id}/versions/${version}`,
    { includeContent: resource === "lab_notebook_entries" ? true : undefined },
    { enabled: entityOriginal && entityOriginal.version > 0 && entityOriginal.version !== version }
  );

  const entityVersion = useMemo(() => {
    if (entityOriginal && entityOriginal.version === version) return entityOriginal;
    return entityVersionData as Entity;
  }, [entityOriginal, entityVersionData, version]);

  const ref = useRef<HTMLDivElement>(null);
  const height = useMemo(() => {
    if (!ref.current) return 0;
    return ref.current.getBoundingClientRect().height;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref.current]);

  // const handleKeyEvent = useCallback(
  //   (e: KeyboardEvent) => {
  //     if (!entityOriginal) return;
  //     if (e.key === "ArrowLeft") setVersion((prevState) => (prevState > 1 ? prevState - 1 : prevState));
  //     if (e.key === "ArrowRight")
  //       setVersion((prevState) => (prevState < entityOriginal.version ? prevState + 1 : entityOriginal.version));
  //   },
  //   [entityOriginal]
  // );

  // useEffect(() => {
  //   document.addEventListener("keydown", (e) => handleKeyEvent(e as any));
  //   return () => document.removeEventListener("keydown", (e) => handleKeyEvent(e as any));
  // }, [handleKeyEvent]);

  const { api } = useContext(SessionContext);
  const queryClient = useQueryClient();
  const handleRestore = useCallback(() => {
    if (version !== entityOriginal?.version)
      api
        .put(`${resource}/${id}/versions/${version}/restore`, {}, {})
        .then((e) => {
          queryClient.invalidateQueries([resource]);
          if (resource === "lab_notebooks") {
            queryClient.invalidateQueries(["lab_notebook_entries"]);
            queryClient.invalidateQueries(["lab_notebook_experiments"]);
            queryClient.invalidateQueries([LAB_NOTEBOOK_TOC_QUERY_KEY]);
          }
          if (resource === "lab_notebook_experiments") {
            queryClient.invalidateQueries(["lab_notebook_entries"]);
            queryClient.invalidateQueries([LAB_NOTEBOOK_TOC_QUERY_KEY]);
          }
          if (resource === "lab_notebook_entries") {
            queryClient.invalidateQueries([LAB_NOTEBOOK_TOC_QUERY_KEY]);
          }
          if (resource === "lab_notebook_templates") {
            queryClient.invalidateQueries(["lab_notebook_templates"]);
          }
          setShowRestoreModal(false);
          onRestore && onRestore();
          showtoast("success", `Version ${version + 1} restored`);
        })
        .catch((e) => {
          showtoast("error", `Version ${version + 1} could not be restored`);
          console.error(e);
        });
  }, [api, entityOriginal?.version, id, onRestore, queryClient, resource, version]);

  const [showRestoreModal, setShowRestoreModal] = useState<boolean>(false);

  if (!entityOriginal) return null;
  return (
    <>
      <div className={styles.entityHistoryContainer}>
        <Detail
          main={
            <div className={styles.entityHistoryMain}>
              <div className={styles.entityHistoryHeaderContainer}>
                <div className={styles.entityHistoryHeaderTitle}>Version {version + 1}</div>
                <div className={styles.entityHistoryPaginationContainer} ref={ref}>
                  <div className="flex justify-center align-center gap-5">
                    <button
                      disabled={version <= 0}
                      onClick={() => setVersion(0)}
                      className="btn btn-secondary btn-xs"
                      data-toggle="tooltip"
                      title="Jump to first version"
                    >
                      | <LucideIcon name="chevrons-left" />
                    </button>
                    <button
                      disabled={version <= 0}
                      onClick={() => setVersion((prevState) => (prevState - 5 <= 0 ? 0 : prevState - 5))}
                      className="btn btn-secondary btn-xs"
                    >
                      <LucideIcon name="chevrons-left" />
                    </button>
                    <button
                      disabled={version <= 0}
                      onClick={() => setVersion((prevState) => prevState - 1)}
                      className="btn btn-secondary btn-xs"
                      data-toggle="tooltip"
                      title="Previous"
                    >
                      <LucideIcon name="chevron-left" /> Prev
                    </button>
                    <div style={{ display: "flex", flexDirection: "column", gap: "1px" }}>
                      {entityOriginal && entityOriginal.version === version ? (
                        <div style={{ display: "flex" }}>
                          <label
                            style={{
                              padding: "5px 20px",
                              margin: "0 10px",
                              overflow: "auto",
                              minWidth: "200px",
                              width: "100%",
                            }}
                            className="label label-soft-primary"
                          >
                            Current
                          </label>
                        </div>
                      ) : (
                        <div style={{ display: "flex" }}>
                          <label
                            className="label label-soft-secondary"
                            style={{ padding: "5px 20px", margin: "0 10px", minWidth: "200px" }}
                          >
                            {`Version ${version + 1} of ${entityOriginal.version + 1}`}
                          </label>
                        </div>
                      )}
                    </div>
                    <button
                      disabled={version === entityOriginal.version}
                      onClick={() => setVersion((prevState) => prevState + 1)}
                      className="btn btn-secondary btn-xs"
                      data-toggle="tooltip"
                      title="Next"
                    >
                      Next <LucideIcon name="chevron-right" />
                    </button>
                    <button
                      disabled={version === entityOriginal.version}
                      onClick={() => setVersion((prevState) => (prevState + 5 >= 0 ? 0 : prevState + 5))}
                      className="btn btn-secondary btn-xs"
                    >
                      <LucideIcon name="chevrons-right" />
                    </button>
                    <button
                      disabled={version === entityOriginal.version}
                      onClick={() => setVersion(entityOriginal.version)}
                      className="btn btn-secondary btn-xs"
                      data-toggle="tooltip"
                      title="Jump to last version"
                    >
                      <LucideIcon name="chevrons-right" /> |
                    </button>
                  </div>
                  <button
                    disabled={!entityOriginal.permissions?.edit || version === entityOriginal.version}
                    onClick={() => setShowRestoreModal(true)}
                    className="btn btn-success btn-xs"
                    data-toggle="tooltip"
                    title="Restore version"
                  >
                    <LucideIcon name="refresh-ccw" />
                    <span> Restore</span>
                  </button>
                </div>
              </div>
              {entityVersion ? (
                <div
                  style={{ height: `calc(100% - ${height}px)` }}
                  className={styles.entityHistoryVersionViewContainer}
                >
                  {GetEntityVersionView(entityVersion, resource)}
                </div>
              ) : (
                <ELNNoContentContainer noContentMessage={"No versions"} />
              )}
            </div>
          }
          sidebar={<AuditLogTile resource={resource} id={id} />}
          sidebarPosition="left"
        />
      </div>

      <AlertModal
        showModal={showRestoreModal}
        setShowModal={setShowRestoreModal}
        type="success"
        description={`Proceeding will restore version ${version + 1}.`}
        proceedLabel="Restore"
        onProceed={() => handleRestore()}
        backdropClassName={styles.entityHistoryRestoreModalBackdrop}
        modalStyle={{ zIndex: 10001 }}
        title="Restore"
      />
    </>
  );
};
