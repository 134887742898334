import {
  customFieldDictToCustomValuesList,
  customValuesListToCustomFieldDict,
} from "../Customization/common/CustomFieldConverter";
import { ICustomTypedEntity, IPagination, StringIndexedDict } from "./GenericTypes";

// ------------------------------------
// Hooks to modify the response of the API
// ------------------------------------
export const modifyReadResponseList = <Entity>(response: Promise<IPagination<Entity>>) => {
  return response.then((res) => {
    if (!res) return res;
    if (Array.isArray(res) && !res.length) return res;
    if (Array.isArray(res.results))
      res.results = res.results.map((r: any) => {
        // Modify the results
        if (!Object.hasOwn(r, "customValues")) return r;
        return {
          ...r,
          customFields: customValuesListToCustomFieldDict(r.customValues),
        } as Entity;
      });
    return res;
  });
};

export const modifyReadResponse = <Entity>(response: Promise<Entity>) => {
  return response.then((res: any) => {
    if (!res) return res;
    if (!Object.hasOwn(res, "customValues")) return res;
    return {
      ...res,
      customFields: customValuesListToCustomFieldDict(res.customValues),
    } as Entity;
  });
};

// ------------------------------------
// Hooks to modify the request to the API
// ------------------------------------

export const projectTo = <Entity extends StringIndexedDict>(entity: Entity | undefined | null) => {
  if (!entity) return entity;
  if (!Object.hasOwn(entity, "customFields")) return entity;
  return {
    ...entity,
    customValues: customFieldDictToCustomValuesList((entity as Partial<ICustomTypedEntity>).customFields),
  };
};

export const projectToBulk = <Entity extends StringIndexedDict>(entities: Entity[] | undefined | null) => {
  if (!entities) return entities;
  if (Array.isArray(entities) && !entities.length) return entities;
  if (Array.isArray(entities) && !!entities.length) return entities.map((r) => projectTo(r));
  return entities;
};
