import { yupResolver } from "@hookform/resolvers/yup";
import { Person, personsConstants } from "../../../../api/Person";
import { useForm, useWatch } from "react-hook-form";
import { FormButtons } from "../../FormButtons";
import { FormHeader } from "../../FormHeader";
import { QuickAddChildrenProps } from "../common/QuickAddWrapper";
import { InputFormField } from "../../../formfields/InputFormField";
import { TextareaFormField } from "../../../formfields/TextareaFormField";
import { useQuickAddSubmit } from "../common/useQuickAddHooks";
import { PersonFieldLabels } from "../../../../api/Person";
import { PersonTagsVirtualizedSelectForm } from "../../EntityForms/formsVirtualized/PersonTagsVirtualizedSelectForm";
import { OrganizationsVirtualizedSelectForm } from "../../EntityForms/formsVirtualized/OrganizationsVirtualizedSelectForm";
import { PersonFormSchema, SALUTATIONS } from "../../../../Persons/PersonForm";
import { SelectFormField } from "../../../formfields/SelectFormField";
import {
  EntityCustomTypeForm,
  useCustomTypeForm,
} from "../../../../Customization/CustomTypes/generics/useCustomTypeForm";
import { CustomTypesVirtualizedSelectForm } from "../../EntityForms/formsVirtualized/CustomTypesVirtualizedSelectForm";
import { useContext } from "react";
import { SessionContext } from "../../../contexts/SessionContext";

export const QuickAddPersonForm = ({ onCreate, onCancel, initialValues }: QuickAddChildrenProps<Person>) => {
  const { session } = useContext(SessionContext);
  const { onSubmit, loading } = useQuickAddSubmit<Person>({
    resource: personsConstants.resource,
    onCreate: onCreate,
    onCancel: onCancel,
  });

  const { defaultValues, typedFormSchema, processCustomFields, type, setType, types } = useCustomTypeForm({
    initialValues: {
      ...initialValues,
      salutation: SALUTATIONS.find((s) => s.name === initialValues?.salutation) as any,
    },
    formSchema: PersonFormSchema,
    typeId: initialValues?.customType?.id,
    entityType: "Person",
    featureFlag: session?.features.enable_person_custom_types,
  });

  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm<Partial<Person>>({
    values: defaultValues,
    resolver: yupResolver(typedFormSchema),
  });
  const _customType = useWatch({ name: "customType", control: control });
  const typesLength = Object.keys(types ?? {}).length;

  return (
    <>
      <FormHeader title={"Add person"} subtitle={""} />
      {!!typesLength && !_customType ? (
        <CustomTypesVirtualizedSelectForm
          id={"customType"}
          control={control}
          concatItems={[{ name: "Basic person", id: -1 }]}
          filters={{ entityTypes: ["Person"] }}
          horizontal
        />
      ) : (
        <>
          <InputFormField
            id="email"
            label={PersonFieldLabels.email}
            errors={errors}
            register={register}
            placeholder="Enter E-mail address (e.g. support@logs-repository.com) ..."
            autoComplete="email"
          />

          <InputFormField
            id="lastName"
            label={PersonFieldLabels.lastName}
            errors={errors}
            register={register}
            autoFocus
            required
            placeholder="Enter last name..."
            autoComplete="off"
          />

          <InputFormField
            id="firstName"
            label={PersonFieldLabels.firstName}
            errors={errors}
            register={register}
            placeholder="Enter first name..."
            autoComplete="off"
          />

          <SelectFormField
            id="salutation"
            label={PersonFieldLabels.salutation}
            required={false}
            control={control}
            isMulti={false}
            items={SALUTATIONS?.map((x) => ({ id: x.id, name: x.name })) ?? []}
            errors={errors}
            placeholder="Select salutation..."
          />

          <OrganizationsVirtualizedSelectForm id="organization" control={control} horizontal allowCreateEntity />

          <TextareaFormField
            id="privateAddress"
            label={PersonFieldLabels.privateAddress}
            errors={errors}
            register={register}
            placeholder="Enter address..."
          />

          <InputFormField
            id="officePhone"
            label={PersonFieldLabels.officePhone}
            errors={errors}
            register={register}
            placeholder="Enter phone number..."
            autoComplete="off"
          />

          <InputFormField
            id="phone"
            label={PersonFieldLabels.phone}
            errors={errors}
            register={register}
            placeholder="Enter phone number..."
            autoComplete="off"
          />

          <InputFormField
            id="web"
            label={PersonFieldLabels.web}
            errors={errors}
            register={register}
            placeholder="Enter web adress (e.g. https://demo.logs-repository.com) ..."
            autoComplete="off"
          />

          <PersonTagsVirtualizedSelectForm
            id="personTags"
            label={PersonFieldLabels.personTags}
            control={control}
            isMulti
            horizontal
            allowCreateEntity
          />

          <TextareaFormField
            id="notes"
            label={PersonFieldLabels.notes}
            errors={errors}
            register={register}
            placeholder="Enter notes..."
          />
          <EntityCustomTypeForm
            entityType="Person"
            typeId={initialValues?.customType?.id}
            type={type}
            types={types}
            setType={setType}
            control={control}
            register={register}
            setValue={setValue}
            initialValues={initialValues}
            errors={errors}
            entityConstants={personsConstants}
          />
          <FormButtons
            groupName="persons"
            entityId={undefined}
            onClose={onCancel}
            onSubmit={handleSubmit(
              async (entity) =>
                await onSubmit(processCustomFields({ ...entity, salutation: (entity.salutation as any)?.name || "" }))
            )}
            disabled={isSubmitting}
            errors={errors}
            submitButtonLabel="Create"
            loading={loading}
          />
        </>
      )}
    </>
  );
};
