import {
  SampleFilters,
  SampleSortingOptionsConsts,
  SampleSuggestions,
  samplesConstants,
} from "../../../../api/Samples";
import { StringIndexedDict } from "../../../../api/GenericTypes";
import {
  GenericEntityVirtualizedEntitySelectFormFieldProps,
  GenericVirtualizedEntitySelectFormFieldControllerWrapper,
} from "../../../formfields/GenericVirtualizedSelectFormField/GenericVirtualizedEntitySelectFormFieldControllerWrapper";
import { QuickAddWrapper } from "../../QuickAdd/common/QuickAddWrapper";
import { QuickAddSampleForm } from "../../QuickAdd/forms/QuickAddSampleForm";
import { useContext } from "react";
import { SessionContext } from "../../../contexts/SessionContext";

export const SamplesVirtualizedSelectForm = <Entity extends StringIndexedDict>(
  props: GenericEntityVirtualizedEntitySelectFormFieldProps<Entity, SampleSuggestions, SampleFilters>
) => {
  const { session } = useContext(SessionContext);

  return (
    <GenericVirtualizedEntitySelectFormFieldControllerWrapper<Entity, SampleSuggestions, SampleFilters>
      {...props}
      filters={{ orderBy: "PREPARATION_DATE_DESC", ...props.filters }}
      onRowRenderer={(item) => (
        <>
          {item.discardedAt && (
            <label className="label label-soft-danger" style={{ margin: 0 }}>
              Discarded
            </label>
          )}
        </>
      )}
      createModal={({ showModal, setShowModal, onCreate }) => (
        <QuickAddWrapper showModal={showModal} setShowModal={setShowModal} onCreate={onCreate}>
          {(quickAddProps) => (
            <QuickAddSampleForm
              {...quickAddProps}
              initialValues={{
                ...quickAddProps.initialValues,
                ...(props.filters?.customTypeIds && {
                  customType: { name: "", id: props.filters?.customTypeIds?.[0] },
                }),
              }}
            />
          )}
        </QuickAddWrapper>
      )}
      entityConstants={samplesConstants}
      orderByOptions={SampleSortingOptionsConsts}
      allowCreateEntity={session?.permissions.add_samples && props.allowCreateEntity}
    />
  );
};
