import { Dispatch, ReactNode, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { Parser } from "../../../api/Parsers";
import { LucideIcon } from "../../../common/icon/LucideIcon";
import { Tile } from "../../../common/panels/Tile/Tile";
import styles from "./RenderBundle.module.css";
import { RenderDataset } from "./RenderDataset";
import { UseMutationResult, useQueryClient } from "@tanstack/react-query";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useFileUploadUtils } from "../FileUploadUtils";
import { SessionContext } from "../../../common/contexts/SessionContext";
// import { InstrumentFacility } from "../../../api/Facilities";
import { LoadingStatus, ManualUploadActions } from "../FileHandlingUtils";
import { DatasetResult, FormatFileEntryResult, FrontendMatchResult, MatchResults } from "../FileFormatAutoDetection";
import { IEntityMinimalModel, IntIndexedDict, IResultModel } from "../../../api/GenericTypes";
import {
  Dataset,
  DatasetForSearch,
  DatasetForUpdateWriteModel,
  DatasetForUploadWriteModel,
  DatasetSearchRequest,
  FormatFileWriteModel,
  datasetsConstants,
} from "../../../api/Datasets";
import { ManualUploadContext } from "../ManualUpload";
import { AlertModal } from "../../../common/modals/AlertModal/AlertModal";
import React from "react";
import { progressBar, renderBundleStats } from "./common/RenderDatasetUtils";
import { Alert } from "../../../common/overlays/Alert/Alert";
import { ServerError } from "../../../common/helperfunctions/ApiError";
import { DataFormatsVirtualizedSelectForm } from "../../../common/forms/EntityForms/formsVirtualized/DataFormatsVirtualizedSelectForm";
import { MethodsVirtualizedSelectForm } from "../../../common/forms/EntityForms/formsVirtualized/MethodsVirtualizedSelectForm";
import { InstrumentsVirtualizedSelectForm } from "../../../common/forms/EntityForms/formsVirtualized/InstrumentsVirtualizedSelectForm";
import { SamplesVirtualizedSelectForm } from "../../../common/forms/EntityForms/formsVirtualized/SamplesVirtualizedSelectForm";
import { ProjectsVirtualizedSelectForm } from "../../../common/forms/EntityForms/formsVirtualized/ProjectsVirtualizedSelectForm";
import { OrganizationsVirtualizedSelectForm } from "../../../common/forms/EntityForms/formsVirtualized/OrganizationsVirtualizedSelectForm";
import { PersonsVirtualizedSelectForm } from "../../../common/forms/EntityForms/formsVirtualized/PersonsVirtualizedSelectForm";
import { ExperimentsVirtualizedSelectForm } from "../../../common/forms/EntityForms/formsVirtualized/ExperimentsVirtualizedSelectForm";
import { EquipmentsVirtualizedSelectForm } from "../../../common/forms/EntityForms/formsVirtualized/EquipmentsVirtualizedSelectForm";
import { useDatasetInstrument } from "../../common/HelperModules";
import { EntityCustomTypeForm, useCustomTypeForm } from "../../../Customization/CustomTypes/generics/useCustomTypeForm";
import { toUppercase } from "../../../common/helperfunctions/stringFunctions";
import { GenericModal } from "../../../common/modals/Modal/GenericModal";
import { Button } from "../../../common/buttons/Button/Button";
import { FormErrors } from "../../../common/forms/FormButtons";
import { customFieldDictToCustomValuesList } from "../../../Customization/common/CustomFieldConverter";

interface BundleForm {
  format: IEntityMinimalModel<string> | null | undefined;
  method: IEntityMinimalModel | null | undefined;
  instrument: IEntityMinimalModel | null | undefined;
  sample: IEntityMinimalModel | null | undefined;
  experiment: IEntityMinimalModel | null | undefined;
  projects: IEntityMinimalModel[] | null | undefined;
  organizations: IEntityMinimalModel[] | null | undefined;
  operators: IEntityMinimalModel[] | null | undefined;
  equipments: IEntityMinimalModel[] | null | undefined;
  customType: IEntityMinimalModel<number> | null;
  customFields?: IntIndexedDict;
}

export interface RenderBundleProps {
  isViewableEntity: boolean;
  actionUUID: string;
  bundle: MatchResults;
  dispatch: Dispatch<ManualUploadActions>;
  calculateSHA256: (file: File) => Promise<string | undefined>;
  parsers: Parser[];
  onCreateCallback?: (datasets: IEntityMinimalModel<number>[]) => void;
  additionalRowControls?: (props: RenderBundleRowControlsProps) => ReactNode;
  additionalListControls?: (props: RenderBundleListControlsProps) => ReactNode;
  onAbortBundle?: (bundle: MatchResults) => void;
}
export interface RenderBundleRowControlsProps {
  dataset: DatasetResult;
  actionUUID: string;
  loading: boolean;
  isProcessing: boolean;
  uploadMutation: UseMutationResult<IResultModel<Dataset>, unknown, any, unknown>;
  dispatch: Dispatch<ManualUploadActions>;
}
export interface RenderBundleListControlsProps {
  datasets: DatasetResult[];
  loading: boolean;
  isProcessing: boolean;
  uploadMutation: UseMutationResult<IResultModel<Dataset>, unknown, any, unknown>;
  dispatch: Dispatch<ManualUploadActions>;
}
export const RenderBundle = ({
  isViewableEntity,
  actionUUID,
  bundle,
  dispatch,
  calculateSHA256,
  parsers,
  onCreateCallback,
  additionalRowControls,
  additionalListControls,
  onAbortBundle,
}: RenderBundleProps) => {
  const { session } = useContext(SessionContext);
  const context = useContext(ManualUploadContext);
  const queryClient = useQueryClient();
  const [isProcessing, setIsProcessing] = useState(false);
  const [loading, setIsLoading] = useState(false);
  const [checkedItems, setCheckedItems] = useState<{
    [format: string]: {
      [datasetUUID: string]: {
        checked: boolean;
        disabled: boolean;
      };
    };
  }>({});
  const [newDatasetsCount, setNewDatasetsCount] = useState<number>();
  const { findDatasets, uploadMutation, updateMutation, generateDatasetSubmissionMultiForm } = useFileUploadUtils();
  const { mutateAsync: uploadMutationAsync } = uploadMutation;
  const { mutateAsync: updateMutationAsync } = updateMutation;
  const [showModal, setShowModal] = useState(false);
  const [processingErrors, setProcessingErrors] = useState<string[]>([]);
  const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>();
  const [showAlertModal, setShowAlertModal] = useState(false);
  const parsersDict = Object.fromEntries(parsers.map((p) => [p.id, p]));
  const formatItems = useMemo(() => {
    return Object.keys(bundle)
      .filter((i) => !!parsersDict[i])
      .map((d) => ({ id: d, name: parsersDict[d].name }));
  }, [bundle, parsersDict]);

  const DatasetUploadFormSchema = {
    format: yup
      .object()
      .shape({ id: yup.string().required(), name: yup.string().required() })
      .strict()
      .required("A data format must be selected")
      .typeError("A data format must be selected"),
    method: yup
      .object()
      .shape({ id: yup.number().required().positive().integer(), name: yup.string().required() })
      .strict()
      .required("A method must be defined")
      .typeError("A method must be defined"),
    // instrument: yup
    //   .object()
    //   .shape({ id: yup.number().required().positive().integer(), name: yup.string().required() })
    //   .strict()
    //   .required("An instrument must be defined")
    //   .typeError("An instrument must be defined"),
  };

  const initialValues = useMemo(
    () => ({
      format: formatItems && formatItems.length === 1 ? formatItems[0] : null,
      method: null,
      instrument: null,
      sample: null,
      experiment: null,
      projects: null,
      organizations: null,
      operators: null,
      equipments: null,
      customType: null,
      customFields: {},
    }),
    [formatItems]
  );

  const { defaultValues, typedFormSchema, processCustomFields, type, setType, types } = useCustomTypeForm<BundleForm>({
    initialValues: initialValues,
    formSchema: DatasetUploadFormSchema,
    typeId: null,
    entityType: "Dataset",
  });

  const {
    control,
    register,
    trigger,
    setFocus,
    handleSubmit,
    formState: { isDirty, errors },
    reset,
    getValues,
    setValue,
  } = useForm<Partial<BundleForm>>({
    defaultValues: defaultValues,
    resolver: yupResolver(typedFormSchema),
  });

  useEffect(() => {
    if (isDirty && newDatasetsCount && newDatasetsCount > 0) {
      context?.isDirty.setIsDirty(true);
    } else {
      context?.isDirty.setIsDirty(false);
    }
  }, [context?.isDirty, isDirty, newDatasetsCount]);

  const format = useWatch({ name: "format", control: control });
  const method = useWatch({ name: "method", control: control });

  // Custom hook to set method if instrument is set
  const { instrument } = useDatasetInstrument<Partial<BundleForm>, "instrument", "method">({ control, setValue });

  const applyAdditionalConfiguration = useCallback(
    (entity: Partial<BundleForm>) => {
      if (format && checkedItems.hasOwnProperty(format.id)) {
        const applicableDatasets = Object.fromEntries(
          Object.entries(checkedItems[format.id]).filter(([datasetUUID, value]) => value.checked && !value.disabled)
        );
        for (const [datasetUUID] of Object.entries(applicableDatasets)) {
          dispatch({
            type: "setBundleDataset",
            actionUUID: actionUUID,
            id: datasetUUID,
            parserId: format.id,
            metaData: processCustomFields(entity),
          });
        }
      }
      setShowModal(false);
    },
    [actionUUID, checkedItems, dispatch, format, processCustomFields]
  );

  const resetAdditionConfiguration = useCallback(() => {
    reset({
      ...getValues(),
      sample: null,
      experiment: null,
      projects: null,
      organizations: null,
      operators: null,
      equipments: null,
      customType: null,
      customFields: {},
    });
    applyAdditionalConfiguration({});
  }, [applyAdditionalConfiguration, getValues, reset]);

  useEffect(() => {
    if (format && checkedItems.hasOwnProperty(format.id)) {
      setNewDatasetsCount(Object.values(checkedItems[format.id]).filter((d) => d.checked && !d.disabled).length);
    }
  }, [checkedItems, format]);

  const checkDatasets = useCallback(
    async (parserId: string, matches: FrontendMatchResult) => {
      setIsProcessing(true);
      let tmp: DatasetResult[] = [];
      // First request --> Only check the uniques
      const payload: DatasetSearchRequest = {
        datasets: matches.datasets.map((d) => ({
          id: d.id,
          parserId: d.parserId,
          checkUpdatable: false,
          files: d.files
            .filter((f) => f.isUnique)
            .map((f) => ({
              id: f.id,
              fullPath: f.fullPath,
              mtime: new Date(f.file.lastModified).toISOString(),
              hash: f.sha256!,
              // fragments: f.fragments,
            })),
        })),
      };
      const response = await findDatasets(payload);
      if (Array.isArray(response)) {
        // New
        if (response.length === 0) {
          matches.datasets.forEach((d) => {
            tmp.push({ ...d, status: "new", files: d.files.map((f) => ({ ...f, state: "NEW" })) });
          });
          dispatch({
            type: "setBundle",
            actionUUID: actionUUID,
            parserId: parserId,
            results: tmp,
          });
          dispatch({
            type: "setBundleStatus",
            actionUUID: actionUUID,
            parserId: parserId,
            status: true,
          });
          setCheckedItems((prev) => ({
            ...prev,
            [parserId]: Object.fromEntries(
              tmp.map((d) => [
                d.id,
                {
                  checked: d.status ? ["new", "update"].includes(d.status) : false,
                  disabled: d.status !== "new" && d.status !== "update",
                },
              ])
            ),
          }));
        } else {
          // Update --> We need to hash all remaining files
          let _datasets: DatasetForSearch[] = [];
          for (const d of matches.datasets) {
            let _files: FormatFileWriteModel[] = [];
            for (const f of d.files) {
              let _hash: string | undefined;
              if (!f.sha256) {
                _hash = await calculateSHA256(f.file);
              } else {
                _hash = f.sha256;
              }
              if (_hash) {
                _files.push({
                  id: f.id,
                  fullPath: f.fullPath,
                  mtime: new Date(f.file.lastModified).toISOString(),
                  hash: _hash,
                  // fragments: f.fragments,
                });
              }
            }
            _datasets.push({
              id: d.id,
              parserId: d.parserId,
              checkUpdatable: true,
              files: _files,
            });
          }

          const finalResponse = await findDatasets({ datasets: _datasets });
          // console.log("finalResponse", finalResponse);
          if ((Array.isArray(finalResponse) && finalResponse.length === 0) || !finalResponse) {
            throw new Error("Server response did not met expectations");
          }
          finalResponse.forEach((d) => {
            if (Array.isArray(d.errors) && !!d.errors.length) {
              throw new Error(d.errors.join(";"));
            }
            const dataset = matches.datasets.find((_d) => _d.id === d.id);
            if (dataset) {
              tmp.push({
                ...dataset,
                files: dataset.files.map((_f) => ({ ..._f, state: d.files.find((f) => f.id === _f.id)?.state })),
                datasetId: d.logsId,
                status: d.logsId && d.isUpdateable ? "update" : d.logsId && !d.isUpdateable ? "known" : "new",
                ...(d.errors && Array.isArray(d.errors) && d.errors.length > 0 && { error: d.errors.join("; ") }),
              });

              // Append some deleted files for display
              const deleted = d.files.filter((_d) => !_d.id);
              if (Array.isArray(deleted) && deleted.length > 0) {
                // console.log("DELETED", deleted);
                tmp[tmp.length - 1].files = [
                  ...tmp[tmp.length - 1].files,
                  ...deleted.map(
                    (_d) =>
                      ({
                        file: { size: _d.size ?? 0 } as File,
                        name: _d.fullPath.split("/").slice(-1)[0],
                        id: "",
                        fullPath: _d.fullPath,
                        mtime: _d.mtime ?? 0,
                        state: "DELETE",
                        parent: _d.fullPath.replace(_d.fullPath.split("/").slice(-1)[0], ""),
                        isUnique: false,
                      } as FormatFileEntryResult)
                  ),
                ];
              }
            }
          });

          // We sort the datasets
          tmp
            .sort((a, b) => {
              const nameA = a.name.toUpperCase();
              const nameB = b.name.toUpperCase();
              if (nameA < nameB) {
                return -1;
              }
              if (nameA > nameB) {
                return 1;
              }
              return 0;
            })
            .sort((a) => (a.status ? (a.status === "update" ? 1 : -1) : 0))
            .sort((a) => (a.status ? (a.status === "new" ? 1 : -1) : 0));
          tmp.reverse();

          setCheckedItems((prev) => ({
            ...prev,
            [parserId]: Object.fromEntries(
              tmp.map((d) => [
                d.id,
                {
                  checked: d.status ? ["new", "update"].includes(d.status) : false,
                  disabled: d.status ? !["new", "update"].includes(d.status) : true,
                },
              ])
            ),
          }));
          dispatch({
            type: "setBundle",
            actionUUID: actionUUID,
            parserId: parserId,
            results: tmp,
          });
          dispatch({
            type: "setBundleStatus",
            actionUUID: actionUUID,
            parserId: parserId,
            status: true,
          });
        }
      }
    },
    [actionUUID, calculateSHA256, dispatch, findDatasets]
  );

  const isInitialized = useRef(false);
  const init = useCallback(async () => {
    isInitialized.current = true;
    let num = 0;
    const elementsLength = Object.values(bundle).length;
    for (const [parserId, matches] of Object.entries(bundle)) {
      // console.log("HASHING", parserId);
      setLoadingStatus?.({
        message: `Finalizing results...`,
        progress: Math.floor(((+num + 1) * 100) / elementsLength),
      });
      if (!matches.checked) {
        await checkDatasets(parserId, matches).catch((e) => {
          console.error("ERROR", e);
          if (e instanceof Error && e.message) {
            setProcessingErrors((prev) => [...prev, e.message]);
          }
          if (typeof e === "string") {
            setProcessingErrors((prev) => [...prev, e]);
          }
        });
      }
    }
    setIsProcessing(false);
  }, [bundle, checkDatasets, setLoadingStatus]);

  // We check all dataset status once on startup
  useEffect(() => {
    if (!isInitialized.current) {
      (async () => await init())();
    }
    return () => {};
  }, [init]);

  const isChecked = Object.values(bundle)
    .flat(1)
    .every((d) => d.checked === true);

  const onProgress = useCallback(
    (progress: number, parserId: string, id: string) => {
      const proc = Math.round(progress * 100);
      // console.log("Progress:", `${Math.round(progress * 100)}%`);
      if (proc < 100) {
        dispatch({
          type: "setBundleDataset",
          actionUUID: actionUUID,
          parserId: parserId,
          id: id,
          status: "uploading",
          progress: proc,
        });
      } else {
        dispatch({
          type: "setBundleDataset",
          actionUUID: actionUUID,
          parserId: parserId,
          id: id,
          status: "idle",
        });
      }
    },
    [actionUUID, dispatch]
  );

  const asyncUpload = useCallback(
    async (dataset: DatasetResult, parserId: string, body: FormData, update = false) => {
      const mutation = update ? updateMutationAsync : uploadMutationAsync;
      await mutation(
        {
          body: body,
          showToast: false,
          goBackOnSuccess: false,
          onProgress: (progress) => onProgress(progress, parserId, dataset.id),
        },
        {
          onSuccess: (response) => {
            // showtoast("success", `Uploaded Dataset-ID: ${datasetId}`);
            if (!!response.results.length) {
              const datasetId = response.results?.[0]?.id;
              dispatch({
                type: "setBundleDataset",
                actionUUID: actionUUID,
                parserId: parserId,
                id: dataset.id,
                status: update ? "updated" : "created",
                datasetId: datasetId,
                queued: false,
              });
              setCheckedItems((prev) => ({ ...prev, [parserId]: { ...prev[parserId], [datasetId]: false } }));
              queryClient.invalidateQueries(["datasets", "list"]);
              queryClient.invalidateQueries(["datasets", "suggestions"]);
              queryClient.invalidateQueries(["datasets", "detail", datasetId]);
            }
          },
          onError: (err) => {
            console.log("ERROR for", dataset.name, err);
            dispatch({
              type: "setBundleDataset",
              actionUUID: actionUUID,
              parserId: parserId,
              id: dataset.id,
              status: "error",
              error: `Error creating dataset.\n ${(err as ServerError).message ?? (err as ServerError).name}`,
              queued: false,
            });
          },
        }
      ).catch((e) => {
        dispatch({
          type: "setBundleDataset",
          actionUUID: actionUUID,
          parserId: parserId,
          id: dataset.id,
          status: "error",
          error: `Error creating dataset.\n ${(e as ServerError).message ?? (e as ServerError).name}`,
          queued: false,
        });
      });
    },
    [actionUUID, dispatch, onProgress, queryClient, updateMutationAsync, uploadMutationAsync]
  );

  const triggerDatasetUpload = useCallback(
    async ({
      format,
      method,
      instrument,
      dataset,
    }: {
      format: IEntityMinimalModel<string>;
      method: IEntityMinimalModel;
      instrument: IEntityMinimalModel | null | undefined;
      dataset: DatasetResult;
    }) => {
      let body: FormData;
      if (dataset.status === "update" && dataset.datasetId) {
        // Update
        const datasetForUpdate: DatasetForUpdateWriteModel = {
          logsId: dataset.datasetId,
          files: dataset.files
            .filter((f) => f.id) //We filter out "ghost" items, as are injected when a file is deleted but we still want to display it
            .map((d) => ({
              id: d.id,
              isUnique: d.isUnique,
              fullPath: d.fullPath,
              mtime: new Date(d.file.lastModified).toISOString(),
            })),
        };
        body = generateDatasetSubmissionMultiForm(dataset, datasetForUpdate);
      } else {
        // Create
        const datasetForUpload: DatasetForUploadWriteModel = {
          name: dataset.name,
          format: format,
          method: method,
          instrument: instrument,
          sample: dataset.metadata?.sample,
          customType: dataset.metadata?.customType,
          customValues: customFieldDictToCustomValuesList(dataset.metadata?.customFields),
          experiment: dataset.metadata?.experiment,
          projects:
            dataset.metadata && Array.isArray(dataset.metadata.projects) && dataset.metadata.projects.length > 0
              ? dataset.metadata.projects
              : undefined,
          organizations:
            dataset.metadata &&
            Array.isArray(dataset.metadata.organizations) &&
            dataset.metadata.organizations.length > 0
              ? dataset.metadata.organizations
              : undefined,
          operators:
            dataset.metadata && Array.isArray(dataset.metadata.operators) && dataset.metadata.operators.length > 0
              ? dataset.metadata.operators
              : undefined,
          equipments:
            dataset.metadata && Array.isArray(dataset.metadata.equipments) && dataset.metadata.equipments.length > 0
              ? dataset.metadata.equipments
              : undefined,
          files: dataset.files
            .filter((f) => f.id) //We filter out "ghost" items, as are injected when a file is deleted but we still want to display it
            .map((d) => ({
              id: d.id,
              isUnique: d.isUnique,
              fullPath: d.fullPath,
              mtime: new Date(d.file.lastModified).toISOString(),
            })),
          isViewableEntity: isViewableEntity,
        };
        body = generateDatasetSubmissionMultiForm(dataset, datasetForUpload);
      }
      dispatch({
        type: "setBundleDataset",
        actionUUID: actionUUID,
        parserId: format.id,
        id: dataset.id,
        status: "idle",
      });
      await asyncUpload(dataset, format.id, body, !!dataset.datasetId);
    },
    [actionUUID, asyncUpload, generateDatasetSubmissionMultiForm, dispatch, isViewableEntity]
  );

  // Hook to update the global progress
  useEffect(() => {
    if (format) {
      context?.stepProgress.setCurrentStep(2);
    } else {
      context?.stepProgress.setCurrentStep(1);
    }

    if (format && newDatasetsCount && newDatasetsCount > 0) {
      context?.stepProgress.setCurrentStep(3);
      const uuids = Object.entries(checkedItems[format.id])
        .filter(([uuid, value]) => value.checked && !value.disabled)
        .map(([uuid]) => uuid);
      const uploaded = bundle[format.id].datasets
        .filter((d) => uuids.includes(d.id) && d.status === "created")
        .filter((d) => d.datasetId !== undefined)
        .map((d) => ({ id: d.datasetId, name: d.name })) as IEntityMinimalModel<number>[];
      onCreateCallback?.(uploaded);
    }

    if (format && bundle.hasOwnProperty(format.id)) {
      if (
        bundle[format.id].datasets
          .filter((d) => d.status)
          .map((d) => d.status && ["created", "updated"].includes(d.status))
          .some((d) => d === true)
      ) {
        context?.stepProgress.setCurrentStep(4);
      }
    }
  }, [bundle, checkedItems, context?.stepProgress, format, newDatasetsCount, onCreateCallback]);

  const handleUploadAll = useCallback(async () => {
    setIsLoading(true);
    const isValidMethod = await trigger("method");
    if (!isValidMethod) setFocus("method");
    const isValidFormat = await trigger("format");
    if (!isValidFormat) setFocus("format");

    const isValid = [isValidFormat, isValidMethod].every((d) => d === true);
    if (isValid && format && method && bundle.hasOwnProperty(format.id) && checkedItems.hasOwnProperty(format.id)) {
      context?.isDirty.setIsDirty(true);

      const datasetsForUpload = Object.entries(checkedItems[format.id])
        .filter(([, dataset]) => !dataset.disabled && dataset.checked)
        .map(([datasetUUID]) => datasetUUID);

      // console.log("Dispatching pending...");
      const pendingDatasets = bundle[format.id].datasets.map((d) => {
        if (datasetsForUpload.includes(d.id)) {
          return { ...d, queued: true };
        } else {
          return d;
        }
      });

      dispatch({ type: "setBundle", actionUUID: actionUUID, parserId: format.id, results: pendingDatasets });

      for (const dataset of bundle[format.id].datasets) {
        if (datasetsForUpload.includes(dataset.id)) {
          await triggerDatasetUpload({
            format: format,
            method: method,
            instrument: instrument,
            dataset: dataset,
          });
        }
      }
      setCheckedItems((prev) => ({
        ...prev,
        [format.id]: Object.fromEntries(
          bundle[format.id].datasets.map((d) => [
            d.id,
            {
              checked: d.status ? ["new", "update"].includes(d.status) : false,
              disabled: d.status ? !["new", "update"].includes(d.status) : true,
            },
          ])
        ),
      }));
    }
    context?.isDirty.setIsDirty(false);
    setIsLoading(false);
  }, [
    actionUUID,
    bundle,
    checkedItems,
    context?.isDirty,
    dispatch,
    format,
    instrument,
    method,
    setFocus,
    trigger,
    triggerDatasetUpload,
  ]);

  const removeBundle = useCallback(
    (actionUUID: string) => {
      // dispatch({ type: "removeBundle", actionUUID: actionUUID });
      dispatch({ type: "clear" });
    },
    [dispatch]
  );

  const toggleCheck = useCallback((dataset: DatasetResult, parserId: string) => {
    setCheckedItems((prev) => ({
      ...prev,
      [parserId]: {
        ...prev[parserId],
        [dataset.id]: {
          checked: prev[parserId][dataset.id].disabled ? false : !prev[parserId][dataset.id].checked,
          disabled: prev[parserId][dataset.id].disabled,
        },
      },
    }));
  }, []);

  return (
    <div className="flex" style={{ width: "100%", height: "100%", overflow: "hidden", padding: "5px 5px" }}>
      {Array.isArray(processingErrors) && !!processingErrors.length ? (
        <Alert type="danger" message="Error processing request" fit centered>
          <div className="flex col-nowrap align-center gap-5">
            {processingErrors.map((err, i) => (
              <div key={i}>{err}</div>
            ))}
          </div>
        </Alert>
      ) : !isChecked ? (
        progressBar(loadingStatus)
      ) : (
        <>
          {parsers && (
            <Tile
              style={{
                boxShadow: `${
                  format && method && bundle.hasOwnProperty(format.id) && bundle[format.id].stats
                    ? (bundle[format.id].stats!.new ||
                        bundle[format.id].stats!.success ||
                        bundle[format.id].stats!.update) > 0
                      ? `inset 0 0 0 rgba(0, 0, 0, 0), 0 0 0 3px rgba(0, 200, 115, 0.2)`
                      : `inset 0 0 0 rgba(0, 0, 0, 0), 0 0 0 3px rgba(250, 155, 70, 0.2)`
                    : `inset 0 0 0 rgba(0, 0, 0, 0), 0 0 0 3px rgba(240, 40, 75, 0.2)`
                }`,
                height: "min-content",
                maxHeight: "100%",
                gridTemplateRows: "min-content 1fr",
                overflow: "hidden",
              }}
              foldable={!!format}
              // isFolded={!!!format}
            >
              <Tile.Head
                title={
                  <div className="flex row-wrap gap-5 align-center">
                    {!format ? (
                      <>
                        <div className="flex row-nowrap align-center gap-5 label label-soft-dark">
                          <LucideIcon name="file" /> Files
                          <span className="badge" style={{ background: "var(--white)", color: "var(--secondary)" }}>
                            {Object.values(bundle)
                              .map((b) => b.datasets)
                              .flat(1)
                              .map((d) => d.files.length)
                              .reduce((pv, cv) => pv + cv, 0)}
                          </span>
                        </div>
                        <LucideIcon name="arrow-right" color={"var(--secondary)"} />

                        <div className="flex row-nowrap align-center gap-5 label label-soft-dark">
                          <LucideIcon name="code" /> Recognized Data Formats
                          <span className="badge" style={{ background: "var(--white)", color: "var(--secondary)" }}>
                            {formatItems.length}
                          </span>
                        </div>

                        <LucideIcon name="arrow-right" color={"var(--secondary)"} />
                        <div className="flex row-nowrap align-center gap-5 label label-primary">
                          <LucideIcon name="activity" /> Possible Datasets
                          <span className="badge" style={{ background: "var(--white)", color: "var(--secondary)" }}>
                            {
                              Object.values(bundle)
                                .map((b) => b.datasets)
                                .flat(1).length
                            }
                          </span>
                        </div>
                      </>
                    ) : (
                      format &&
                      bundle.hasOwnProperty(format.id) && (
                        <>
                          <div className="flex row-nowrap align-center gap-5 label label-soft-dark">
                            <LucideIcon name="file" /> Files
                            <span className="badge" style={{ background: "var(--white)", color: "var(--secondary)" }}>
                              {bundle[format.id].datasets.map((d) => d.files.length).reduce((pv, cv) => pv + cv, 0)}
                            </span>
                          </div>
                          <LucideIcon name="arrow-right" color={"var(--secondary)"} />
                          <div
                            className="flex row-nowrap align-center gap-5 label label-soft-dark"
                            style={{ height: "24.6px" }}
                          >
                            <LucideIcon name="code" /> {format.name}
                          </div>
                          <LucideIcon name="arrow-right" color={"var(--secondary)"} />
                          {bundle[format.id].stats && renderBundleStats(bundle[format.id].stats!)}
                        </>
                      )
                    )}
                    {/* <span>{bundle.sourceDir}</span> */}
                  </div>
                }
              >
                <Tile.Head.Controls>
                  <Tile.Head.Controls.Fixed>
                    <div className={`flex row-nowrap align-center gap-5 ${styles.container_controls}`}>
                      <button
                        className={`btn btn-success ${loading ? "loading" : ""}`}
                        title={"Upload"}
                        onClick={() => handleUploadAll()}
                        disabled={loading || isProcessing || uploadMutation.isLoading || !newDatasetsCount}
                      >
                        {loading ? (
                          <>
                            <span className="spinner" /> Uploading...
                          </>
                        ) : (
                          <>
                            <LucideIcon name="upload" /> Upload{" "}
                            {`${
                              format && newDatasetsCount
                                ? newDatasetsCount === 1
                                  ? `${newDatasetsCount} dataset`
                                  : `${newDatasetsCount} datasets`
                                : ""
                            }`}
                          </>
                        )}
                      </button>
                      <AlertModal
                        title="You have unsaved changes"
                        description={
                          "If you cancel the current operation before completing, then your changes will be lost."
                        }
                        type="warning"
                        showModal={showAlertModal}
                        setShowModal={setShowAlertModal}
                        onProceed={() => removeBundle(actionUUID)}
                        proceedLabel="Abort upload"
                      />

                      <button
                        className="btn btn-soft-default"
                        title={"Abort"}
                        onClick={() => {
                          if (!newDatasetsCount) {
                            removeBundle(actionUUID);
                            onAbortBundle?.(bundle);
                          } else {
                            setShowAlertModal(true);
                          }
                        }}
                        disabled={uploadMutation.isLoading}
                      >
                        <LucideIcon name="x" /> {context?.stepProgress.currentStep === 4 ? "Close" : "Abort"}
                      </button>
                    </div>
                  </Tile.Head.Controls.Fixed>
                </Tile.Head.Controls>
              </Tile.Head>
              <Tile.Body style={{ padding: "5px", overflow: "hidden" }}>
                <div className={"flex col-nowrap gap-5"} style={{ width: "100%", height: "100%" }}>
                  <div className={`flex col-nowrap gap-5 form-horizontal ${styles.form_group}`}>
                    <>
                      <DataFormatsVirtualizedSelectForm
                        id="format"
                        control={control}
                        placeholder="More than one possible Dataformat was detected. Pick one to proceed."
                        horizontal
                        required
                        onRowRenderer={(item) => <span className="badge">{bundle?.[item.id]?.datasets.length}</span>}
                        filters={{
                          ids: formatItems.map((v) => v.id),
                          ...(!session?.features.allow_generic_files && { excludeIds: ["files_generic"] }),
                          orderBy: "NAME_ASC",
                        }}
                      />
                      {format && newDatasetsCount !== undefined && !!newDatasetsCount && (
                        <>
                          <MethodsVirtualizedSelectForm
                            id="method"
                            label={"Measuring Method"}
                            control={control}
                            horizontal
                            required
                            allowCreateEntity
                            disabled={
                              !!instrument ||
                              !format ||
                              loading ||
                              isProcessing ||
                              uploadMutation.isLoading ||
                              !newDatasetsCount
                            }
                          />
                          <InstrumentsVirtualizedSelectForm
                            id="instrument"
                            control={control}
                            horizontal
                            allowCreateEntity
                            disabled={
                              !newDatasetsCount ||
                              !format ||
                              loading ||
                              isProcessing ||
                              uploadMutation.isLoading ||
                              !newDatasetsCount
                            }
                          />
                        </>
                      )}
                    </>
                  </div>
                  {format && (
                    <div className="flex col-nowrap" style={{ width: "100%", height: "100%", overflow: "hidden" }}>
                      <div
                        className="flex row-nowrap align-center"
                        style={{
                          alignSelf: "flex-start",
                          width: "100%",
                          height: "min-content",
                          borderBottom: "1px solid var(--gray-300)",
                          padding: "5px",
                          // background: "var(--primary-light-30)",
                          // borderRadius: "8px 8px 0px 0px",
                        }}
                      >
                        <div style={{ marginRight: "10px" }}>
                          {checkedItems.hasOwnProperty(format.id) && (
                            <input
                              type="checkbox"
                              ref={(input) => {
                                if (input) {
                                  input.indeterminate = Object.values(checkedItems[format.id]).every(
                                    (d) => d.checked === true
                                  );
                                }
                              }}
                              onChange={() => {
                                if (
                                  Object.values(checkedItems[format?.id])
                                    .filter((d) => !d.disabled)
                                    .every((d) => d.checked === true)
                                ) {
                                  setCheckedItems((prev) => ({
                                    ...prev,
                                    [format.id]: Object.fromEntries(
                                      Object.entries(prev[format.id]).map(([datasetId]) => [
                                        datasetId,
                                        {
                                          checked: false,
                                          disabled: prev[format.id][datasetId].disabled,
                                        },
                                      ])
                                    ),
                                  }));
                                } else {
                                  setCheckedItems((prev) => ({
                                    ...prev,
                                    [format.id]: Object.fromEntries(
                                      Object.entries(prev[format.id]).map(([datasetId]) => [
                                        datasetId,
                                        {
                                          checked: prev[format.id][datasetId].disabled ? false : true,
                                          disabled: prev[format.id][datasetId].disabled,
                                        },
                                      ])
                                    ),
                                  }));
                                }
                              }}
                              defaultChecked
                              disabled={
                                !bundle[format.id].datasets.filter(
                                  (d) => d.status && ["new", "update"].includes(d.status)
                                ).length
                              }
                            />
                          )}
                        </div>
                        <div className={"container_label"}>
                          <div className={"flex container_label_name"} style={{ width: "100%" }}>
                            <div
                              className="flex row-nowrap align-center gap-5"
                              style={{
                                width: "100%",
                                whiteSpace: "nowrap",
                                fontSize: "1.25em",
                                fontWeight: "bold",
                                color: "var(--black)",
                              }}
                            >
                              {toUppercase(datasetsConstants.entityPlural)}
                              <span className="badge">{bundle[format.id].datasets.length}</span>
                            </div>
                          </div>

                          <div className={"flex row-nowrap align-center gap-5"}>
                            {newDatasetsCount ? (
                              <span style={{ whiteSpace: "nowrap", color: "var(--gray-400)" }}>
                                {newDatasetsCount} {`${newDatasetsCount === 1 ? "Dataset" : "Datasets"} selected`}
                              </span>
                            ) : (
                              <></>
                            )}

                            <GenericModal
                              showModal={showModal}
                              setShowModal={setShowModal}
                              modalTitle="Edit Metadata"
                              modalBody={
                                <div
                                  className="flex"
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                    overflowY: "scroll",
                                    overflowX: "hidden",
                                  }}
                                >
                                  <div
                                    className="form form-group form-horizontal"
                                    style={{ width: "100%", height: "fit-content", paddingRight: "10px" }}
                                  >
                                    <MethodsVirtualizedSelectForm
                                      id="method"
                                      label={"Measuring Method"}
                                      control={control}
                                      horizontal
                                      required
                                      allowCreateEntity
                                      disabled={
                                        !!instrument ||
                                        !format ||
                                        loading ||
                                        isProcessing ||
                                        uploadMutation.isLoading ||
                                        !newDatasetsCount
                                      }
                                    />
                                    <InstrumentsVirtualizedSelectForm
                                      id="instrument"
                                      control={control}
                                      horizontal
                                      allowCreateEntity
                                      disabled={
                                        !newDatasetsCount ||
                                        !format ||
                                        loading ||
                                        isProcessing ||
                                        uploadMutation.isLoading ||
                                        !newDatasetsCount
                                      }
                                    />
                                    <SamplesVirtualizedSelectForm
                                      id="sample"
                                      control={control}
                                      horizontal
                                      required
                                      allowCreateEntity
                                    />
                                    <ProjectsVirtualizedSelectForm
                                      id="projects"
                                      control={control}
                                      horizontal
                                      required
                                      allowCreateEntity
                                      isMulti
                                      filters={{ currentUserHasAddPermission: true }}
                                    />
                                    <OrganizationsVirtualizedSelectForm
                                      id="organizations"
                                      control={control}
                                      horizontal
                                      allowCreateEntity
                                      isMulti
                                    />
                                    <PersonsVirtualizedSelectForm
                                      id="operators"
                                      label={"Operators"}
                                      control={control}
                                      horizontal
                                      required
                                      allowCreateEntity
                                      isMulti
                                    />
                                    <ExperimentsVirtualizedSelectForm
                                      id="experiment"
                                      control={control}
                                      horizontal
                                      allowCreateEntity
                                      filters={{ methodIds: method && method.id > 0 ? [method.id] : undefined }}
                                    />
                                    <EquipmentsVirtualizedSelectForm
                                      id="equipments"
                                      label={"Suppl. equipment"}
                                      control={control}
                                      horizontal
                                      allowCreateEntity
                                      isMulti
                                    />

                                    <EntityCustomTypeForm
                                      entityType="Dataset"
                                      typeId={null}
                                      type={type}
                                      types={types}
                                      setType={setType}
                                      control={control}
                                      register={register}
                                      setValue={setValue}
                                      initialValues={initialValues}
                                      errors={errors}
                                      entityConstants={datasetsConstants}
                                      allowUnassigned={false}
                                    />

                                    <div className="flex row-nowrap align-center" style={{ width: "100%" }}>
                                      <span style={{ color: "var(--danger)", marginLeft: "auto" }}>
                                        <sup>*</sup> Required for automatic claiming
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              }
                              modalControls={
                                <div className="flex col-nowrap">
                                  <div
                                    className="flex row-nowrap align-center gap-5"
                                    style={{ width: "100%", justifyContent: "flex-end" }}
                                  >
                                    <button
                                      className="btn btn-default"
                                      title="Reset"
                                      onClick={resetAdditionConfiguration}
                                    >
                                      Reset
                                    </button>
                                    <button
                                      className="btn btn-primary"
                                      title="Apply to selected datasets"
                                      onClick={handleSubmit((entity) => applyAdditionalConfiguration(entity))}
                                    >
                                      Apply to{" "}
                                      {format && checkedItems.hasOwnProperty(format.id)
                                        ? Object.values(checkedItems[format.id]).filter((v) => v.checked).length
                                        : "all"}{" "}
                                      {`${
                                        format && checkedItems.hasOwnProperty(format.id)
                                          ? Object.values(checkedItems[format.id]).filter((v) => v.checked).length === 1
                                            ? datasetsConstants.entitySingular
                                            : datasetsConstants.entityPlural
                                          : datasetsConstants.entityPlural
                                      }`}
                                    </button>
                                  </div>
                                  {typeof errors === "object" && !!Object.keys(errors).length && (
                                    <div style={{ marginTop: 10, padding: 0 }}>
                                      <FormErrors errors={errors} />
                                    </div>
                                  )}
                                </div>
                              }
                            />

                            <Button
                              className="btn btn-sm btn-default"
                              title="Edit Metadata"
                              disabled={loading || isProcessing || uploadMutation.isLoading || !newDatasetsCount}
                              onClick={() => setShowModal(true)}
                            >
                              <LucideIcon name="square-pen" /> Edit Metadata
                            </Button>
                            {additionalListControls?.({
                              datasets: bundle[format.id].datasets,
                              loading,
                              isProcessing,
                              uploadMutation,
                              dispatch,
                            })}
                          </div>
                        </div>
                      </div>

                      <div className={styles.dataset_rows}>
                        {bundle.hasOwnProperty(format.id) &&
                        bundle[format.id].checked &&
                        checkedItems.hasOwnProperty(format.id) ? (
                          bundle[format.id].datasets.map((dataset, idx) => (
                            <div className="flex row-nowrap align-center" key={`${format.id}-${idx}`}>
                              <div style={{ margin: "10.75px 10px 0px 0px", alignSelf: "flex-start" }}>
                                {/* {!checkedItems.hasOwnProperty(dataset.key) && dataset.status && processChecked(dataset)} */}
                                <input
                                  type="checkbox"
                                  disabled={["error", "known", "pending", "created", undefined].includes(
                                    dataset.status
                                  )}
                                  checked={
                                    checkedItems[format.id].hasOwnProperty(dataset.id) &&
                                    checkedItems[format.id][dataset.id].checked
                                  }
                                  onChange={() => toggleCheck(dataset, format.id)}
                                />
                              </div>
                              <RenderDataset
                                dataset={dataset}
                                actionUUID={actionUUID}
                                dispatch={dispatch}
                                additionalControls={additionalRowControls?.({
                                  dataset,
                                  actionUUID,
                                  loading,
                                  isProcessing,
                                  uploadMutation,
                                  dispatch,
                                })}
                              />
                            </div>
                          ))
                        ) : (
                          <>
                            <div
                              className="flex row-nowrap align-center gap-5"
                              style={{ width: "100%", height: "43px" }}
                            >
                              <span
                                className="skeleton-block"
                                style={{ width: "14px", height: "14px", marginRight: "10px" }}
                              />
                              <span className="skeleton-block" style={{ width: "100%", height: "43px" }} />
                            </div>
                            <div
                              className="flex row-nowrap align-center gap-5"
                              style={{ width: "100%", height: "43px" }}
                            >
                              <span
                                className="skeleton-block second"
                                style={{ width: "14px", height: "14px", marginRight: "10px" }}
                              />
                              <span className="skeleton-block second" style={{ width: "100%", height: "43px" }} />
                            </div>
                            <div
                              className="flex row-nowrap align-center gap-5"
                              style={{ width: "100%", height: "43px" }}
                            >
                              <span
                                className="skeleton-block third"
                                style={{ width: "14px", height: "14px", marginRight: "10px" }}
                              />
                              <span className="skeleton-block third" style={{ width: "100%", height: "43px" }} />
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </Tile.Body>
            </Tile>
          )}
        </>
      )}
    </div>
  );
};
