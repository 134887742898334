import { RemirrorJSON } from "remirror";
import {
  ICreationRecord,
  ICreatedRecordParameters,
  IGenericRequestParameters,
  IEntityMinimalModel,
  IModelWithIntId,
  IModificationRecord,
  IModifiedRecordParameters,
  IPaginationParameters,
  IPermissionedEntity,
  IRelationParameters,
  IVersionedEntity,
  IVersionedEntityParameters,
  IConcurrencyToken,
  INamedEntity,
  ISoftDeletable,
  ISoftDeletableFilterParameters,
  EntityConstants,
  DefaultCreatedByRecordOrderTypeConsts,
  DefaultCreatedOnRecordOrderTypeConsts,
  DefaultModifiedByRecordOrderTypeConsts,
  DefaultModifiedOnRecordOrderTypeConsts,
  DefaultVersionedOrderTypeConsts,
  IProjectBasedParameters,
} from "../../api/GenericTypes";
import { LabNotebookStatus } from "./LabNotebook";
import { LabNotebookExperimentMinimalModel } from "./LabNotebookExperiment";
import {
  PropertyTranslator,
  IPaginationParametersTranslator,
  IGenericRequestParametersTranslator,
  ICreatedRecordParametersTranslator,
  IModifiedRecordParametersTranslator,
  ISoftDeletableFilterParametersTranslator,
  IRelationParametersTranslator,
  IProjectBasedParametersTranslator,
  ICreationRecordLabels,
  IModificationRecordLabels,
  INamedEntityLabels,
  IPermissionedEntityLabels,
  ISoftDeletableLabels,
  IConcurrencyTokenLabels,
} from "../../api/GenericTranslator";

export const labNotebookEntriesConstants: EntityConstants<LabNotebookEntry, LabNotebookEntryFilters> = {
  resource: "lab_notebook_entries",
  frontendIndexRoute: "lab_notebook_entries",
  entitySingular: "notebook entry",
  entityPlural: "notebook entries",
  icon: "pencil",
};

export const labNotebookEntryFieldLabels: PropertyTranslator<LabNotebookEntry> = {
  ...INamedEntityLabels,
  ...IPermissionedEntityLabels,
  ...ICreationRecordLabels,
  ...IModificationRecordLabels,
  ...ISoftDeletableLabels,
  ...IConcurrencyTokenLabels,
  content: "Content",
  id: "Notebook entry ID",
  version: "Version",
  entryDate: "Entry date",
  labNotebook: "Notebook",
  labNotebookExperiment: "Experiment",
};

export const EmptyLabNotebookEntry = { type: "doc", content: [] };

export interface LabNotebookMinimalModel extends IEntityMinimalModel {
  status: LabNotebookStatus;
}
export interface LabNotebookEntry
  extends IModelWithIntId,
    INamedEntity,
    ICreationRecord,
    IModificationRecord,
    IPermissionedEntity,
    IVersionedEntity,
    ISoftDeletable,
    IConcurrencyToken<string> {
  labNotebook: LabNotebookMinimalModel;
  labNotebookExperiment: LabNotebookExperimentMinimalModel;
  entryDate: string;
  content?: RemirrorJSON;
}
export interface LabNotebookEntrySuggestions extends IEntityMinimalModel<LabNotebookEntry["id"]> {
  entryDate: string;
  labNotebook: LabNotebookMinimalModel;
  labNotebookExperiment: LabNotebookExperimentMinimalModel;
}

export interface LabNotebookEntryVersion extends LabNotebookEntry, IVersionedEntity {}

export interface LabNotebookEntryWriteModel
  extends Omit<
      LabNotebookEntry,
      | "id"
      | "name"
      | "labNotebook"
      | "createdBy"
      | "createdOn"
      | "modifiedBy"
      | "modifiedOn"
      | "isDeleted"
      | "originalId"
      | "version"
    >,
    IConcurrencyToken<string> {}

export interface LabNotebookEntryFilters
  extends IGenericRequestParameters<LabNotebookEntry, LabNotebookEntrySortingOptions>,
    IPaginationParameters,
    IRelationParameters,
    ICreatedRecordParameters,
    IModifiedRecordParameters,
    ISoftDeletableFilterParameters,
    IProjectBasedParameters {
  labNotebookIds?: number[] | null;
  labNotebookExperimentIds?: number[] | null;
  datasetIds?: number[];
  mediaIds?: number[];
  sampleIds?: number[];
  inventoryIds?: number[];
  personIds?: number[];
  entryDateFrom?: string | null;
  entryDateTo?: string | null;
  includeContent?: boolean;
  useFullTextSearch?: boolean | null;
  labNotebookProjectIds?: number[] | null;
}

export interface LabNotebookEntryVersionFilter extends LabNotebookEntryFilters, IVersionedEntityParameters {}

export const LabNotebookEntrySortingOptionsConsts = [
  "ID_ASC",
  "ID_DESC",
  "ENTRY_DATE_ASC",
  "ENTRY_DATE_DESC",
  ...DefaultCreatedOnRecordOrderTypeConsts,
  ...DefaultCreatedByRecordOrderTypeConsts,
  ...DefaultModifiedOnRecordOrderTypeConsts,
  ...DefaultModifiedByRecordOrderTypeConsts,
  ...DefaultVersionedOrderTypeConsts,
  "LAB_NOTEBOOK_ID_ASC",
  "LAB_NOTEBOOK_ID_DESC",
  "LAB_NOTEBOOK_NAME_ASC",
  "LAB_NOTEBOOK_NAME_DESC",
  "LAB_NOTEBOOK_EXPERIMENT_ID_ASC",
  "LAB_NOTEBOOK_EXPERIMENT_ID_DESC",
  "LAB_NOTEBOOK_EXPERIMENT_NAME_ASC",
  "LAB_NOTEBOOK_EXPERIMENT_NAME_DESC",
] as const;
export type LabNotebookEntrySortingOptions = (typeof LabNotebookEntrySortingOptionsConsts)[number];

export const LabNotebookEntryFiltersTranslator: PropertyTranslator<LabNotebookEntryFilters> = {
  ...IPaginationParametersTranslator,
  ...IGenericRequestParametersTranslator,
  ...ICreatedRecordParametersTranslator,
  ...IModifiedRecordParametersTranslator,
  ...ISoftDeletableFilterParametersTranslator,
  ...IProjectBasedParametersTranslator,
  ...IRelationParametersTranslator,
  labNotebookIds: "Notebook IDs",
  labNotebookExperimentIds: "Experiment IDs",
  datasetIds: "Dataset IDs",
  inventoryIds: "Inventory IDs",
  mediaIds: "Attachment IDs",
  sampleIds: "Sample IDs",
  personIds: "Person IDs",
  entryDateFrom: "Entry date from",
  entryDateTo: "Entry date to",
  includeContent: "Include content",
  useFullTextSearch: "Full text search",
  labNotebookProjectIds: "Notebook Project IDs",
};
