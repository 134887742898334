import {
  DatasetInfo,
  DatasetOptions,
  Datatrack,
  DatatrackBinary,
  DatatrackImage,
  DatatrackNumericArray,
  DatatrackTable,
  HierarchyNode,
  InternalParameter,
  JobInfo,
  Parameter,
  Track,
  ViewerSettings,
} from "../ViewerLayout/ViewerLayoutTypes";
import { deepCopy } from "../ViewerLayout/ViewerLayoutUtils";
import { Annotation } from "./../ViewerLayout/ViewerLayoutTypes";
import { API } from "./Api";
import { base64ArrayBuffer } from "./base64ArrayBuffer";

export class Viewer {
  private api: API;

  constructor(api: API) {
    this.api = api;
  }

  public async getDatasetInfo(id: number, options: DatasetOptions = {}): Promise<DatasetInfo> {
    const searchParams = new URLSearchParams(options as any);
    const rv = await this.api.get(`datasets/${id}/viewer/info/${searchParams.toString()}`);
    return rv;
  }

  public async getParserOutput(id: number, options: DatasetOptions = {}): Promise<string> {
    const searchParams = new URLSearchParams(options as any);
    const rv = await this.api.get(`datasets/${id}/viewer/parser_output/${searchParams.toString()}`);
    return rv;
  }

  public async getReparseJob(id: number, options: DatasetOptions = {}): Promise<JobInfo> {
    const searchParams = new URLSearchParams(options as any);
    const rv = await this.api.get(`datasets/${id}/viewer/reparse/${searchParams.toString()}`);
    return rv;
  }

  public async getJobState(id: string, options: DatasetOptions = {}): Promise<JobInfo> {
    const searchParams = new URLSearchParams(options as any);
    try {
      const rv = await this.api.get(`jobs/${id}/${searchParams.toString()}`);
      return rv;
    } catch {
      return {
        id: id,
        state: "finished",
        finishedTime: "unknown",
      } as JobInfo;
    }
  }

  public async getParameters(id: number, options: DatasetOptions = {}): Promise<Parameter[]> {
    const searchParams = new URLSearchParams(options as any);
    const rv = (await this.api.get(
      `datasets/${id}/viewer/parameters/${searchParams.toString()}`
      // `dataset`
    )) as { parameters: Parameter[] };
    // console.log("rv", rv);
    return rv.parameters;
  }

  public async getInternalParameters(id: number, options: DatasetOptions = {}): Promise<InternalParameter[]> {
    const searchParams = new URLSearchParams(options as any);
    const rv = (await this.api.get(
      `datasets/${id}/viewer/internal_parameters/${searchParams.toString()}`
      // `dataset`
    )) as { parameters: InternalParameter[] };
    // console.log("rv", rv);
    return rv.parameters;
  }

  public async generateTiles(id: number, options: DatasetOptions = {}): Promise<void> {
    const searchParams = new URLSearchParams(options as any);
    await this.api.get(
      `datasets/${id}/viewer/generate_highres_tiles/${searchParams.toString()}`
      // `dataset`
    );
  }

  public async getDatatracks(id: number, options: DatasetOptions = {}): Promise<Datatrack[]> {
    const searchParams = new URLSearchParams(options as any);
    const rv = await this.api.get(
      `datasets/${id}/viewer/datatracks/${searchParams.toString()}`
      // `dataset`
    );

    return rv;
  }

  public async getTracks(
    id: number,
    options: DatasetOptions = {}
  ): Promise<{ tracks: Track[]; datatracks: Datatrack[]; tracksHierarchy?: HierarchyNode }> {
    const searchParams = new URLSearchParams(options as any);
    const rv = await this.api.get(
      `datasets/${id}/viewer/tracks/${searchParams.toString()}`
      // `dataset`
    );

    return { tracks: rv.tracks ?? [], datatracks: rv.datatracks ?? [], tracksHierarchy: rv.tracksHierarchy };
  }

  public async getAnnotations(id: number, options: DatasetOptions = {}): Promise<Annotation[]> {
    const searchParams = new URLSearchParams(options as any);
    const rv = (await this.api.get(
      `datasets/${id}/viewer/annotations/${searchParams.toString()}`
      // `dataset`
    )) as { annotations: Annotation[] };

    return rv.annotations;
  }

  // public async getTracksCSV(id: number, options: DatasetOptions = {}): Promise<Track[]> {
  //   const searchParams = new URLSearchParams(options as any);
  //   const rv = (await this.api.get(
  //     `datasets/${id}/viewer/tracks/${searchParams.toString()}`
  //     // `dataset`
  //   )) as Track[];

  //   return rv ?? [];
  // }

  public async getDataTracks(id: number, options: DatasetOptions = {}): Promise<Track[]> {
    const searchParams = new URLSearchParams(options as any);
    const rv = (await this.api.get(
      `datasets/${id}/viewer/tracks/${searchParams.toString()}`
      // `dataset`
    )) as Track[];

    return rv ?? [];
  }

  public async getTilesData(tiles: Datatrack[], options: DatasetOptions = {}): Promise<(Datatrack | undefined)[]> {
    const searchParams = new URLSearchParams(options as any);

    try {
      const rv = (await this.api.post(`datasets/viewer/tiles/${searchParams.toString()}`, {
        tiles: tiles,
      })) as ArrayBuffer;

      let offset = 0;
      let end = 0;
      let newTrack: Datatrack | undefined;

      const l = tiles.map((tile) => {
        end = offset + tile.byteSize;
        newTrack = undefined;
        // console.log("slice", tile.type, tile.id, offset, end, tile.byteSize);
        switch (tile.type) {
          case "numeric_matrix":
          case "numeric_array":
            {
              const track = deepCopy(tile) as DatatrackNumericArray;

              if (track.numberType === "double") track.data = new Float64Array(rv.slice(offset, end));
              else if (track.numberType === "float") track.data = new Float32Array(rv.slice(offset, end));
              else if (track.numberType === "int") track.data = new Int32Array(rv.slice(offset, end));
              else throw new Error(`Unknown track number type '${track.numberType}'. (Expected 'double' or 'float'})`);

              if (track.data.length !== track.count)
                throw new Error(
                  `Not enough data points from backend. (Expected ${track.count} got ${track.data.length})`
                );
              newTrack = track;
            }
            break;
          case "image":
            {
              const track = deepCopy(tile) as DatatrackImage;

              const b64 = base64ArrayBuffer(new Uint8Array(rv, offset, end));
              var dataURL = "data:image/jpeg;base64," + b64;

              track.data = dataURL as any;
              // document.getElementById("image").src = dataURL;
              // newTrack = track;
              newTrack = track;

              // output: SmF2YVNjcmlwdCBpcyBmdW4hIQ==

              // console.log(">>", rv.slice(offset, offset + 10));
              // image.src = rv.slice(offset, end);
              // image.src = canvas.node().toDataURL();
              // console.log("tile", rv.slice(offset, end));
            }
            break;
          case "binary":
            {
              const track = deepCopy(tile) as DatatrackBinary;
              track.data = new Uint8Array(rv, offset, end);
              newTrack = track;
            }
            break;
          case "formatted_table":
            {
              const track = deepCopy(tile) as DatatrackTable;
              let data = new Uint8Array(rv, offset, end);
              let split = data.length;
              for (let i = 0; i < data.length; i++) {
                if (data[i] === 0) split = i;
              }
              data = data.slice(0, split);
              const enc = new TextDecoder("utf-8");
              // const table = JSON.parse(enc.decode(data));
              track.data = JSON.parse(enc.decode(data));
              // track.data = new Uint8Array(rv, offset, end);
              newTrack = track;
            }
            break;
        }
        offset = end;
        return newTrack;
      });
      // console.log("tiles data", l);
      return l;
    } catch (e) {
      console.log("error", e);
      return [];
    }
  }

  public async getViewerSettings(id: number, options: DatasetOptions = {}): Promise<ViewerSettings> {
    // const searchParams = new URLSearchParams(options as any);
    // const rv = (await this.get(
    //   `datasets/${id}/viewer_settings/${searchParams.toString()}`
    //   // `dataset`
    // )) as ViewerSettings;

    // return rv;

    return {};
  }
}
