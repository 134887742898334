import { StringIndexedDict } from "../../../../api/GenericTypes";
import {
  GenericEntityVirtualizedEntitySelectFormFieldProps,
  GenericVirtualizedEntitySelectFormFieldControllerWrapper,
} from "../../../formfields/GenericVirtualizedSelectFormField/GenericVirtualizedEntitySelectFormFieldControllerWrapper";
import { QuickAddWrapper } from "../../QuickAdd/common/QuickAddWrapper";
import {
  ProjectFilters,
  ProjectSortingOptionsConsts,
  ProjectSuggestions,
  projectsConstants,
} from "../../../../api/Projects";
import { QuickAddProjectForm } from "../../QuickAdd/forms/QuickAddProjectForm";
import { useContext } from "react";
import { SessionContext } from "../../../contexts/SessionContext";

export const ProjectsVirtualizedSelectForm = <Entity extends StringIndexedDict>(
  props: GenericEntityVirtualizedEntitySelectFormFieldProps<Entity, ProjectSuggestions, ProjectFilters>
) => {
  const { session } = useContext(SessionContext);

  return (
    <GenericVirtualizedEntitySelectFormFieldControllerWrapper<Entity, ProjectSuggestions, ProjectFilters>
      {...props}
      filters={{ orderBy: "NAME_ASC", ...props.filters }}
      //   onRowRenderer={(item) => <></>}
      createModal={({ showModal, setShowModal, onCreate }) => (
        <QuickAddWrapper showModal={showModal} setShowModal={setShowModal} onCreate={onCreate}>
          {(quickAddProps) => (
            <QuickAddProjectForm
              {...quickAddProps}
              initialValues={{
                ...quickAddProps.initialValues,
                ...(props.filters?.customTypeIds && {
                  customType: { name: "", id: props.filters?.customTypeIds?.[0] },
                }),
              }}
            />
          )}
        </QuickAddWrapper>
      )}
      entityConstants={projectsConstants}
      orderByOptions={ProjectSortingOptionsConsts}
      allowCreateEntity={
        (session?.permissions.administer_projects || session?.permissions.add_projects) && props.allowCreateEntity
      }
    />
  );
};
