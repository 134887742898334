import { LucideIcon } from "../../../common/icon/LucideIcon";
import styles from "../CommonPages.module.css";

/**
 * PageNotFound component renders a user-friendly message indicating that the requested page was not found.
 * It includes an icon, a descriptive message, and a button to navigate back to the previous page.
 * @author @CorradoSurmanowicz
 * @component
 * @example
 * return (
 *   <PageNotFound />
 * )
 *
 * @returns {JSX.Element} A React component that displays a "Page Not Found" message.
 */
export const PageNotFound = () => {
  return (
    <div className={styles.container}>
      <div className={styles.image}>
        <LucideIcon name="tent-tree" />
      </div>
      <div className="flex row-nowrap align-center gap-5">
        <div className={styles.desc}>
          <div className="flex col-nowrap align-center">
            <span style={{ fontWeight: "bold", fontSize: "1.5em" }}>Oops!</span>
            <span style={{ fontWeight: "400", fontSize: "0.75em" }}>
              Looks like you've reached a page that doesn't exist.
            </span>
          </div>
          <button
            className="btn btn-primary"
            onClick={() => window.history.back()}
            style={{ width: "min-content", margin: "auto" }}
          >
            Take me back
          </button>
        </div>
      </div>
      <div className={styles.details}>
        <span className={styles.codeDesc}>
          Error: The requested URL <code>{window.location.pathname}</code> was not found on this server.
        </span>
      </div>
    </div>
  );
};
