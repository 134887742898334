import { useEffect, useMemo, useRef } from "react";
import styles from "../ImageViewer.module.css";

import * as d3 from "d3";
import { ImageSize, ImageTransform } from "../ImageComponent";

const overviewRatio = 5;

export const ImageOverview = ({
  imageData,
  initialImageData,
  initialTransform,
  transform,
  width,
  height,
}: {
  imageData?: HTMLImageElement;
  initialImageData: ImageSize;
  initialTransform?: ImageTransform;
  transform: ImageTransform;
  width: number;
  height: number;
}) => {
  const overviewCanvasRef = useRef<HTMLCanvasElement>(null);
  const overviewCanvas = d3.select(overviewCanvasRef.current);
  const overviewContext = useMemo(() => {
    if (overviewCanvas) {
      const context = overviewCanvas.node()?.getContext("2d");
      return context;
    }
  }, [overviewCanvas]);

  useEffect(() => {
    if (overviewCanvas && overviewContext && imageData && initialTransform) {
      const width = Number(overviewCanvas.attr("width"));
      const height = Number(overviewCanvas.attr("height"));
      overviewContext.clearRect(0, 0, width, height);
      overviewContext.drawImage(
        imageData,
        initialTransform.x / overviewRatio,
        initialTransform.y / overviewRatio,
        (initialImageData.width * initialTransform.k) / overviewRatio,
        (initialImageData.height * initialTransform.k) / overviewRatio
      );

      const rectX = -transform.x / transform.k / overviewRatio;
      const rectY = -transform.y / transform.k / overviewRatio;
      const rectWidth = width / transform.k;
      const rectHeight = height / transform.k;

      overviewContext.strokeStyle = "red";
      overviewContext.lineWidth = 1;
      overviewContext.strokeRect(rectX, rectY, rectWidth - 1, rectHeight - 1);

      overviewContext.fillStyle = "rgba(255, 255, 255, 0.5)";
      overviewContext.fillRect(0, 0, width, rectY);
      overviewContext.fillRect(0, rectY, rectX, rectHeight);
      overviewContext.fillRect(rectX + rectWidth, rectY, width - rectX, rectHeight);
      overviewContext.fillRect(0, rectY + rectHeight, width, height - rectY);
    }
  }, [imageData, initialImageData, initialTransform, overviewCanvas, overviewContext, transform]);

  return (
    <div className={styles.ImageComponentOverview}>
      <canvas ref={overviewCanvasRef} width={width / overviewRatio} height={height / overviewRatio} />
    </div>
  );
};
