import { CSSProperties, ReactNode, useCallback, useContext, useMemo } from "react";
import { Dropdown, MenuItem } from "react-bootstrap";
import { useQueryClient } from "@tanstack/react-query";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import Announcements from "../Announcements/Announcements";
import { SessionContext } from "../common/contexts/SessionContext";
import { useFeatureFlag } from "../common/contexts/UseFeatureFlags";
import { LucideIcon } from "../common/icon/LucideIcon";
import { Documentation } from "../Documentation/Documentation";
import styles from "./main.module.css";
import { getEditLink, getIndexRoute } from "./Routing";
import { projectsConstants } from "../api/Projects";
import { samplesConstants } from "../api/Samples";
import { datasetsConstants } from "../api/Datasets";
import { inventoriesConstants } from "../api/Inventories";
import { personsConstants } from "../api/Person";
import { documentationConstants, logsPyConstants, logsSupportConstants } from "../api/Misc";
import { toUppercase } from "../common/helperfunctions/stringFunctions";
import { useAuth } from "react-oidc-context";

export const NavigationBar = () => {
  const history = useHistory();
  const { group, session } = useContext(SessionContext);
  const route = useMemo(() => (url: string) => `/${group}${url}`, [group]);
  const logout = useLogout();
  const isElnActivated = useFeatureFlag("eln");

  return (
    <div className="navbar navbar-default navbar-fixed-top" role="navigation">
      <div className="navbar-header">
        <button
          type="button"
          className="btn btn-default btn-square navbar-toggle"
          data-toggle="collapse"
          data-target=".navbar-collapse"
        >
          <span className="sr-only">Toggle navigation</span>
          <LucideIcon name="menu" />
        </button>
        <div style={{ position: "relative" }}>
          <a className="navbar-brand" href={route(getIndexRoute("dashboard"))}>
            <img src={`${process.env.PUBLIC_URL}/assets/logos/Logs-Main.svg`} className="brand" alt="LOGS" />
          </a>
          {process.env.NODE_ENV === "development" && (
            <div className="flex row-nowrap align-center gap-5" style={{ position: "absolute", top: 0, left: 0 }}>
              <label className="label label-xs label-danger" style={{ margin: 0, fontSize: ".6em" }}>
                DEVEL
              </label>
              <label className="label label-xs label-info" style={{ margin: 0, fontSize: ".6em" }}>
                REACT
              </label>
            </div>
          )}
        </div>
      </div>
      <div className="collapse navbar-collapse">
        <ul className="nav navbar-nav">
          <NavBarMenuItem to={route(getIndexRoute(projectsConstants.frontendIndexRoute))}>Projects</NavBarMenuItem>
          <NavBarMenuItem to={route(getIndexRoute(samplesConstants.frontendIndexRoute))}>Samples</NavBarMenuItem>
          {/* <NavBarMenuItem to={route("/samples_old")}>Samples old</NavBarMenuItem> */}
          <NavBarMenuItem to={route(getIndexRoute(datasetsConstants.frontendIndexRoute))}>Datasets</NavBarMenuItem>
          <NavBarMenuItem to={route(getIndexRoute(inventoriesConstants.frontendIndexRoute))}>
            Inventories
          </NavBarMenuItem>
          {session?.features.development_mode && (
            <NavBarMenuItem to={route(getIndexRoute("components"))}>Components</NavBarMenuItem>
          )}
          <NavBarMenuItem to={route(getIndexRoute("manage"))}>More</NavBarMenuItem>

          {isElnActivated && (
            <NavBarMenuItem
              to={route(getIndexRoute("notebooks"))}
              liStyle={{
                borderLeft: "1px solid var(--gray-400)",
                // borderRight: "1px solid var(--gray-400)",
              }}
            >
              <LucideIcon name="book" /> Notebook
            </NavBarMenuItem>
          )}
        </ul>
        <ul className="nav navbar-nav navbar-right">
          <li>
            <Announcements />
          </li>
          <li>
            <Dropdown id="help" pullRight={true}>
              <Dropdown.Toggle noCaret className={"btn-ghost-secondary btn-round"} bsStyle="link">
                <Documentation />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <MenuItem href={"https://logs-support.sciy.com/home/"} target="_blank">
                  <LucideIcon name={documentationConstants.icon} /> {toUppercase(documentationConstants.entitySingular)}
                </MenuItem>
                <MenuItem href={"https://docs.logs-python.com"} target="_blank">
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/logos/LOGS-Py_Icon.svg`}
                    className="feather"
                    alt={toUppercase(logsPyConstants.entitySingular)}
                  />{" "}
                  {toUppercase(logsPyConstants.entitySingular)}
                </MenuItem>
                <MenuItem href={"https://logs-repository.atlassian.net/servicedesk/customer/portal/4"} target="_blank">
                  <LucideIcon name={"mail-question"} /> New data format request
                </MenuItem>

                <MenuItem href={"https://logs-support.sciy.com/new/"} target="_blank">
                  <LucideIcon name={logsSupportConstants.icon} /> {toUppercase(logsSupportConstants.entitySingular)}
                </MenuItem>
                <MenuItem href={route(getIndexRoute("about"))}>
                  <LucideIcon name="info" /> About LOGS
                </MenuItem>
              </Dropdown.Menu>
            </Dropdown>
          </li>
          <Dropdown id="account" className={styles.dropdown} pullRight={true}>
            <Dropdown.Toggle className="btn dropdown-toggle user-account">
              <span>
                {/* <img src="assets/img/user-badge-admin.svg" className="user-role" /> */}
                <LucideIcon name="check" className="feather user-role" color={"var(--success)"} />
                <LucideIcon name="user" className="feather feather-icon" /> {session?.person?.lastName}
              </span>{" "}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <li className="dropdown-header">
                <strong>{`${session?.person?.firstName ?? ""} ${session?.person?.lastName ?? ""}`}</strong>
              </li>
              <li className="divider"></li>
              <MenuItem
                disabled={!session?.userId}
                onClick={() =>
                  history.push(route(getEditLink(personsConstants.frontendIndexRoute, session?.userId || 0)))
                }
              >
                <LucideIcon name="square-pen" /> Edit account
              </MenuItem>
              <MenuItem onClick={logout}>
                <LucideIcon name="log-out" /> Log out
              </MenuItem>
            </Dropdown.Menu>
          </Dropdown>
        </ul>
      </div>
    </div>
  );
};

const NavBarMenuItem = ({ to, children, liStyle }: { to: string; children: ReactNode; liStyle?: CSSProperties }) => {
  const location = useLocation();

  return (
    <li className={`${location.pathname.includes(to) ? "active" : ""}`} style={liStyle}>
      <NavLink to={to} className={`${styles.navlink}`} activeClassName={`${styles.active}`}>
        {children}
      </NavLink>
    </li>
  );
};

// For some reason this breaks the dropdown behaviour, don't use this for now
// const NavBarDropdownMenuItem = ({
//   to,
//   closeDropdown,
//   children,
// }: {
//   to: string;
//   closeDropdown: (x: any) => void;
//   children: ReactNode;
// }) => (
//   <li role="presentation">
//     <NavLink role="menuitem" to={to} activeClassName={styles.active} onClick={closeDropdown}>
//       <span>{children}</span>
//     </NavLink>
//   </li>
// );

const useLogout = () => {
  const oidcAuth = useAuth();
  const context = useContext(SessionContext);
  const queryClient = useQueryClient();

  const internalLogoutFunc = useCallback(() => {
    // console.log("LOGOUT");
    sessionStorage.removeItem(`session_${context.group}`);
    localStorage.removeItem(`session_${context.group}`);
    context.session = undefined;
    // queryClient.removeQueries(["session"]);
    queryClient.removeQueries();
    context.clearSession();
  }, [context, queryClient]);

  const oidcLogoutFunc = useCallback(async () => {
    console.log("Removing user");
    await oidcAuth.removeUser();
    context.session = undefined;
    // queryClient.removeQueries(["session"]);
    queryClient.removeQueries();
    context.clearSession();
    console.log("User removed");
  }, [context, oidcAuth, queryClient]);

  if (context.session?.type === "jwt") return oidcLogoutFunc;
  else return internalLogoutFunc;
};
