import { Node } from "@remirror/pm/model";
import { useCommands, useHelpers } from "@remirror/react";
import { useCallback } from "react";
import { Timer } from "./Timer";

export const TextEditorTimerWrapper = ({
  time,
  remainingSeconds,
  checkpoints,
  editable = false,
  showContent = true,
}: {
  time?: number;
  remainingSeconds: number;
  checkpoints: number[];
  editable?: boolean;
  showContent?: boolean;
}) => {
  const commands = useCommands();
  const helpers = useHelpers();

  const onUpdateTimer = useCallback(
    ({
      checkpoints,
      remainingSeconds,
      showContent,
    }: {
      checkpoints?: number[];
      remainingSeconds?: number;
      showContent?: boolean;
    }) => {
      commands.updateTimer({ checkpoints, remainingSeconds, showContent });
    },
    [commands]
  );

  const generateOrderedListFromCheckpoints = useCallback((checkpoints: string[]) => {
    const listItems = checkpoints.map((c) => {
      return {
        type: "listItem",
        attrs: {
          closed: false,
          nested: false,
        },
        content: [
          {
            type: "paragraph",
            attrs: {
              nodeIndent: null,
              nodeTextAlignment: null,
              nodeLineHeight: null,
              style: "",
            },
            content: [
              {
                type: "text",
                text: c,
              },
            ],
          },
        ],
      };
    });

    const orderedList = {
      type: "orderedList",
      attrs: {
        order: 1,
      },
      content: listItems,
    };

    return orderedList;
  }, []);

  const onSaveCheckpoints = useCallback(
    (checkpoints: string[]) => {
      const pos = helpers.getCommandProp().tr.selection;
      commands.insertNode(
        Node.fromJSON(helpers.getCommandProp().view.state.schema, generateOrderedListFromCheckpoints(checkpoints)),
        { range: { from: pos.$anchor.after(pos.$anchor.depth + 1), to: pos.$anchor.after(pos.$anchor.depth + 1) } }
      );
      commands.insertNode(
        Node.fromJSON(helpers.getCommandProp().view.state.schema, {
          type: "heading",
          attrs: { level: 5 },
          content: [{ type: "text", text: "Checkpoints" }],
        }),
        { range: { from: pos.$anchor.after(pos.$anchor.depth + 1), to: pos.$anchor.after(pos.$anchor.depth + 1) } }
      );
    },
    [commands, helpers, generateOrderedListFromCheckpoints]
  );

  return (
    <Timer
      time={time}
      remainingSeconds={remainingSeconds}
      checkpoints={checkpoints}
      onUpdateTimer={onUpdateTimer}
      onSaveCheckpoints={onSaveCheckpoints}
      editable={editable}
      showContent={showContent}
    />
  );
};
