import { LucideIcon } from "../../../common/icon/LucideIcon";
import styles from "../CommonPages.module.css";

/**
 * Component that renders a "Page Not Allowed" message.
 * This component is displayed when a user tries to access a page without the necessary permissions.
 * @author @CorradoSurmanowicz
 * @returns {JSX.Element} A JSX element containing the "Access Denied" message and a button to navigate back.
 */
export const PageNotAllowed = () => {
  return (
    <div className={styles.container}>
      <div className={styles.image_error}>
        <LucideIcon name="circle-x" />
      </div>
      <div className="flex row-nowrap align-center gap-5">
        <div className={styles.desc}>
          <div className="flex col-nowrap align-center">
            <span style={{ fontWeight: "bold", fontSize: "1.5em" }}>Access Denied</span>
            <span style={{ fontWeight: "400", fontSize: "0.75em" }}>No permission to access this page.</span>
          </div>
          <button
            className="btn btn-primary"
            onClick={() => window.history.back()}
            style={{ width: "min-content", margin: "auto" }}
          >
            Take me back
          </button>
        </div>
      </div>
      <div className={styles.details}>
        <span className={styles.codeDesc}>To access, please contact an administrator.</span>
      </div>
    </div>
  );
};
