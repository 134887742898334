import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { ToggleSwitch } from "../../buttons/ToggleSwitch/ToggleSwitch";
import styles from "./Codeblock.module.css";

/**
 * This component is designed to show code blocks
 * Author: CS (copied design from SK)
 * @param: children (string)
 * @returns: JSX.Element
 */

const leadinZeros = (number: number, size = 3) => {
  let str = number.toString();
  while (str.length < size) str = "0" + str;
  return str;
};

interface Props {
  children: string | string[];
  loading?: boolean;
  defaultWordWrap?: boolean;
  reachedBottomCallback?: () => void; // Optional callback to perfom actions once the bottom of the Codeblock is reached
  wordWrapStateCallback?: (wordWrap: boolean) => void;
  showWordWrapToggle?: boolean;
  showLineNumber?: boolean;
  borderColor?: string;
}
const Codeblock = ({
  children,
  loading = false,
  defaultWordWrap = false,
  reachedBottomCallback,
  wordWrapStateCallback,
  showWordWrapToggle = false,
  showLineNumber = true,
  borderColor = "var(--warning)",
}: Props) => {
  const [wordWrap, setWordWrap] = useState(defaultWordWrap);
  // const [code, setCode] = useState<JSX.Element>();
  const listInnerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (wordWrapStateCallback !== undefined) {
      wordWrapStateCallback(wordWrap);
    }
  }, [wordWrap, wordWrapStateCallback]);

  const onScroll = useCallback(() => {
    if (reachedBottomCallback && listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (scrollTop + clientHeight === scrollHeight) {
        reachedBottomCallback();
      }
    }
  }, [reachedBottomCallback]);

  const code = useMemo(() => {
    return children ? (Array.isArray(children) ? children : children.split(/[\r\n]+/)) : [];
  }, [children]);

  if (code.length > 1 && code[code.length - 1] === "") code.pop();
  const re = /^(stdErr|stdOut): (.*)/;
  const zeros = Math.ceil(Math.log10(code.length)) + 1;

  return (
    <div className={styles.container}>
      {showWordWrapToggle && (
        <div style={{ fontFamily: "Inter", fontWeight: 100, fontSize: 14 }}>
          <div className="flex row-nowrap gap-5 align-center">
            <span>Word wrap</span>
            <div style={{ marginRight: "auto" }}>
              <ToggleSwitch checked={wordWrap} onToggle={() => setWordWrap((prev) => !prev)} />
            </div>
          </div>
        </div>
      )}
      <div className={`${styles.codeblock} ${loading && styles.codeblock_loading}`}>
        <div
          className={styles.code}
          onScroll={() => onScroll()}
          ref={listInnerRef}
          style={{ whiteSpace: wordWrap ? "normal" : "nowrap", borderLeftColor: borderColor }}
        >
          {code.map((d, i) => {
            let style = styles.stdStd;
            const match = d.match(re);
            if (match) {
              d = match[2];
              if (match[1] === "stdErr") style = styles.stdErr;
              else if (match[1] === "stdOut") style = styles.stdOut;
            }

            return (
              <span key={i}>
                {showLineNumber && <span className={styles.lineNumber}>{leadinZeros(i, zeros)}&nbsp;</span>}
                <span className={style}>
                  {d}
                  {d.includes("\n") && (
                    <span style={{ color: "var(--gray-400)" }} title="The remaining part was truncated">
                      [...]
                    </span>
                  )}
                </span>
                {/* <br /> */}
              </span>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Codeblock;
