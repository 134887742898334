import React from "react";
import { IGenericEntityPermissions } from "../../permissions/useEntityPermissions";
import { EntityConstants, IEntityPermissions } from "../../../api/GenericTypes";
import { LucideIcon } from "../../icon/LucideIcon";
import { EntityByEntityTypeId, GenericEntityConstantsEntities } from "../../../api/GenericConstants";

interface EntityTableSoftDeletableButtonProps<EntityTypeId extends GenericEntityConstantsEntities> {
  entityConstants: EntityConstants;
  currentTab: string;
  permissions: IGenericEntityPermissions<EntityTypeId>;
  selection: Set<EntityByEntityTypeId<EntityTypeId>["id"]>;
  selectionPermissions: IEntityPermissions | undefined;
  onClick: React.DOMAttributes<HTMLButtonElement>["onClick"];
}

/**
 * A button component for soft deleting or moving entities to trash.
 * @author @CorradoSurmanowicz
 * @template Entity - The type of the entity.
 * @param {EntityTableSoftDeletableButtonProps<EntityTypeId>} props - The properties for the button component.
 * @param {EntityConstants} props.entityConstants - Constants related to the entity.
 * @param {string} props.currentTab - The current tab, either "trash" or another tab.
 * @param {Permissions} props.permissions - The permissions object for the user.
 * @param {Set<Entity>} props.selection - The set of selected entities.
 * @param {Permissions} props.selectionPermissions - The permissions object for the selected entities.
 * @param {() => void} props.onClick - The click handler for the button.
 * @returns {JSX.Element} The rendered button component.
 */
export const EntityTableSoftDeletableButton = <EntityTypeId extends GenericEntityConstantsEntities>({
  entityConstants,
  currentTab,
  permissions,
  selection,
  selectionPermissions,
  onClick,
}: EntityTableSoftDeletableButtonProps<EntityTypeId>) => {
  return (
    <button
      className={`btn btn-sm btn-ghost-${currentTab === "trash" ? "danger" : "warning"}`}
      title={`${
        !(currentTab === "trash"
          ? permissions.canBulkDelete(selectionPermissions)
          : permissions.canBulkEdit(selectionPermissions))
          ? `Insufficient permissions to ${currentTab === "trash" ? "trash" : "delete"} selected ${
              entityConstants.entityPlural
            }`
          : selection.size === 0
          ? `Select ${entityConstants.entitySingular} to ${currentTab === "trash" ? "trash" : "delete"}`
          : selection.size === 1
          ? `${currentTab === "trash" ? "Trash" : "Delete"} the selected ${entityConstants.entitySingular}`
          : `${currentTab === "trash" ? "Trash" : "Delete"} the selected ${entityConstants.entityPlural}`
      }`}
      disabled={
        selection.size === 0 ||
        (currentTab === "trash"
          ? !permissions.canBulkDelete(selectionPermissions)
          : !permissions.canBulkEdit(selectionPermissions))
      }
      onClick={onClick}
    >
      <LucideIcon name={`${currentTab === "trash" ? "trash-2" : "trash"}`} />{" "}
      {`${currentTab === "trash" ? "Delete" : "Move to trash"}`}
    </button>
  );
};
