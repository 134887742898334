import { useCallback } from "react";
import { parameterInputTypes } from "../../../ProcessingPipeline/PipelineTypes";
import { convertToTagList } from "../../../tools/ConvertToTagList/ConvertToTagList";
import { DecoratedChoiceInput } from "../../../ViewerUIElements/Range/DecoratedChoiceInput";
import { DecoratedStringInput } from "../../../ViewerUIElements/Range/DecoratedStringInput";

export const StringInput = ({
  parameterName,
  value,
  inputType,
  options,
  setParameter,
  readonly,
}: {
  parameterName: string;
  inputType: parameterInputTypes;
  value: string;
  options: string[];
  readonly?: boolean;
  setParameter?: (parameterName: string, parameters: Record<string, any>) => void;
}) => {
  const setValue = useCallback(
    (value: string) => {
      if (setParameter && !readonly) setParameter(parameterName, { value: value });
    },
    [setParameter]
  );

  if (readonly)
    return (
      <div style={{ fontSize: "small", paddingBottom: 5 }}>
        {value && value !== "" ? convertToTagList(value) : "<empty>"}
      </div>
    );

  if (inputType === parameterInputTypes.options)
    return <DecoratedChoiceInput value={value} choices={options} setValue={setValue} />;

  // console.log("value", value)
  // return <DecoratedStringInput value={userValue} setValue={setUserValue} />;
  return <DecoratedStringInput value={value} setValue={setValue} />;
};
