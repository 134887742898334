import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import { useEntityCount, useUnpaginateOrdered } from "../../../api/BaseEntityApi";
import { Spinner } from "../../../common/loaders/Spinner/Spinner";
import { LabNotebookEntryViewOnly } from "../../LabNotebookEntry/LabNotebookEntryViewOnly/LabNotebookEntryViewOnly";
import { LabNotebookEntry, LabNotebookEntryFilters } from "../../types/LabNotebookEntry";
import styles from "./PrintComponentsHandler.module.css";
import { LoadingWrapper } from "../../../common/LoadingWrapper";
import { Alert } from "../../../common/overlays/Alert/Alert";

export const PrintComponentsHandler = ({
  documentTitle,
  filter = {},
  onAfterPrint,
  orientation = "portrait",
}: {
  documentTitle?: string;
  filter?: LabNotebookEntryFilters;
  onAfterPrint?: Function;
  orientation?: "portrait" | "landscape";
}) => {
  const [isPrinted, setIsPrinted] = useState<boolean>(false);

  const ref = useRef<HTMLDivElement>(null);

  const contentCallback = useCallback(() => {
    return ref.current;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref.current]);

  const setIsPrintingCallback = useCallback(() => {}, []);

  const setIsPrintedCallback = useCallback(() => {
    setIsPrinted(true);
  }, []);

  const handlePrint = useReactToPrint({
    documentTitle: documentTitle || new Date().toISOString(),
    content: contentCallback,
    onBeforePrint: setIsPrintingCallback,
    onAfterPrint: setIsPrintedCallback,
    pageStyle: `@page { size: A4 ${orientation} !important; }`,
    removeAfterPrint: true,
  });

  const filters: LabNotebookEntryFilters = useMemo(() => {
    return { orderBy: "ENTRY_DATE_ASC", ...filter, page: 1, pageSize: 2, includeCount: true, includeContent: true };
  }, [filter]);
  const {
    data: entries,
    status,
    fetchStatus,
    error,
    isFetching,
    isFetched,
  } = useUnpaginateOrdered<LabNotebookEntry, LabNotebookEntryFilters>("lab_notebook_entries", filters, {});
  const { data: count } = useEntityCount<LabNotebookEntryFilters>("lab_notebook_entries", filters, {});

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (!isFetching && isFetched && entries?.length && count?.count === entries.length) {
      timeout = setTimeout(async () => {
        await handlePrint();
      }, 3000);
      return () => clearTimeout(timeout);
    }
  }, [count?.count, entries?.length, handlePrint, isFetched, isFetching]);

  useEffect(() => {
    if (isPrinted) {
      onAfterPrint && onAfterPrint();
    }
  }, [isPrinted, onAfterPrint]);
  return (
    <div className={styles.PrintComponentsHandlerContainer}>
      <div className={styles.PrintComponentsHandlerContentContainer}>
        <div ref={ref} style={{ width: "210mm" }}>
          {!!entries?.length &&
            entries.map((element, i) => (
              <div style={{ pageBreakInside: "avoid" }} key={i}>
                {i !== 0 && <hr />}
                <LabNotebookEntryViewOnly key={i} entry={element} useEntry ignoreIntersectionObserver printMode />
              </div>
            ))}
        </div>
      </div>
      <LoadingWrapper status={status} fetchStatus={fetchStatus} error={error} type="skeleton-rows">
        {!!entries?.length ? (
          <div className={styles.PrintComponentHandlerOverlaySpinner}>
            <div>
              <Spinner />
            </div>
            <div style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
              Preparing {entries?.length === 1 ? "entry" : "entries"} for printing...
            </div>
          </div>
        ) : (
          <Alert type="warning" message="No entries found" />
        )}
      </LoadingWrapper>
    </div>
  );
};
