import { Fragment, Node } from "@remirror/pm/model";
import { useActive, useCommands, useHelpers, useKeymap } from "@remirror/react";
import { MutableRefObject, useCallback, useEffect, useState } from "react";
import { useDebouncedValue } from "../../../../../common/helperfunctions/useDebouncedValue";
import { LabNotebookTemplate } from "../../../../types/LabNotebookTemplate";
import { findNodeByAttribute } from "../../extensions/extension-utils";
import { UploadedDatasets } from "../../../../../Dataset/ManualUpload/hook/useManualUpload";
import { DatasetAdditionalSettings, EntityAttributes } from "../../extensions/entity/entity-types";
import { GenericEntityConstantsEntities } from "../../../../../api/GenericConstants";
import { UploadModal } from "./UploadModal";

export const TextEditorExternalEventHandler = ({
  target,
  remirrorContextRef,
  uploadedDatasets,
  editable = false,
}: {
  target: HTMLDivElement;
  remirrorContextRef?: MutableRefObject<any>;
  uploadedDatasets: UploadedDatasets;
  editable?: boolean;
}) => {
  const commands = useCommands();
  const helpers = useHelpers();
  const active = useActive(true);

  const debouncedCommands = useDebouncedValue(commands, 300);
  const debouncedHelpers = useDebouncedValue(helpers, 300);

  const [handledDatasets, setHandledDatasets] = useState<string[]>([]);

  useKeymap("Tab", () => {
    if (!editable) return true;
    if (active.taskList() || active.bulletList() || active.orderedList()) return false;
    commands.insertText("    ");
    return true;
  });

  useKeymap("Shift-Tab", () => {
    if (!editable) return true;
    if (active.taskList() || active.bulletList() || active.orderedList()) return false;
    return true;
  });

  const insertMention = useCallback(
    ({
      entityTypeId,
      entityId,
      entityUuid,
    }: {
      entityTypeId: GenericEntityConstantsEntities;
      entityId: number;
      entityUuid: string;
    }) => {
      const pos = debouncedHelpers.getCommandProp().tr.selection.from;
      if (pos !== undefined) {
        debouncedCommands.insertEntityMention({
          entityId,
          entityUuid,
          entityTypeId,
        });
        debouncedCommands.focus(debouncedHelpers.getCommandProp().tr.selection.$from);
      }
    },
    [debouncedCommands, debouncedHelpers]
  );

  const insertTemplate = useCallback(
    (template: LabNotebookTemplate) => {
      const pos = debouncedHelpers.getStateJSON().selection;
      if (pos !== undefined && template.content?.content?.length) {
        const fragment = Fragment.fromArray(
          template.content.content.map((n) => Node.fromJSON(debouncedHelpers.getCommandProp().view.state.schema, n))
        );
        debouncedCommands.insertNode(fragment);
        debouncedCommands.focus(debouncedHelpers.getCommandProp().tr.selection.$from);
      }
    },
    [debouncedCommands, debouncedHelpers]
  );

  useEffect(() => {
    if (remirrorContextRef) {
      remirrorContextRef.current = {
        helpers: debouncedHelpers,
        commands: debouncedCommands,
        insertPerson: insertMention,
        insertTemplate,
      };
    }
  }, [remirrorContextRef, debouncedCommands, insertMention, insertTemplate, debouncedHelpers]);

  useEffect(() => {
    if (editable) {
      const taskUUIDs = Object.keys(uploadedDatasets);
      if (taskUUIDs.length > 0) {
        const doc = helpers.getCommandProp().state.doc;
        taskUUIDs.forEach((taskUUID) => {
          const task = uploadedDatasets[taskUUID];
          const datasets = task.datasets;
          if (datasets.length > 0) {
            datasets.forEach((dataset) => {
              if (!handledDatasets.includes(dataset.uuid)) {
                const node = findNodeByAttribute(doc, "overrideId", taskUUID);
                if (node && node.pos !== undefined) {
                  commands.updateEntity(
                    {
                      entityId: dataset.datasetId,
                      entityUuid: dataset.uuid,
                      entityTypeId: "attachments",
                      additionalSettings: { showViewer: true } as DatasetAdditionalSettings,
                    },
                    doc.resolve(node.pos)
                  );
                } else {
                  commands.insertEntity({
                    entityId: dataset.datasetId,
                    entityUuid: dataset.uuid,
                    entityTypeId: "attachments",
                    additionalSettings: { showViewer: true } as DatasetAdditionalSettings,
                  });
                }
                setHandledDatasets((prevState) => [...prevState, dataset.uuid]);
              }
            });
          }
        });
      }
    }
  }, [commands, editable, handledDatasets, helpers, uploadedDatasets]);

  const onDrop = useCallback(
    (e) => {
      if (editable) {
        const { view } = debouncedHelpers.getCommandProp();
        const posAtCoords = view.posAtCoords({ left: e.clientX, top: e.clientY });
        if (posAtCoords) {
          debouncedCommands.focus(posAtCoords.pos);
        }

        const entityAttrs: EntityAttributes = JSON.parse(e.dataTransfer?.getData("entityReferenceData") || "{}");
        if (!!entityAttrs?.entityId && !!entityAttrs?.entityTypeId) {
          e.preventDefault();
          e.stopPropagation();
          switch (entityAttrs.entityTypeId) {
            default:
              debouncedCommands.insertEntity(entityAttrs);
              break;
          }
          return;
        }

        const template = JSON.parse(e.dataTransfer?.getData("templateData") || "{}") as LabNotebookTemplate;
        if (template?.id) {
          e.preventDefault();
          e.stopPropagation();
          insertTemplate(template);
          return;
        }
      }
    },
    [debouncedCommands, debouncedHelpers, editable, insertTemplate]
  );

  const onUpload = useCallback(
    (datasetIds: number[], entityTypeId: GenericEntityConstantsEntities) => {
      console.log("onUpload", datasetIds, entityTypeId);
      datasetIds.forEach((datasetId) => {
        debouncedCommands.insertEntity({
          entityId: datasetId,
          entityTypeId,
          additionalSettings: { showViewer: true } as DatasetAdditionalSettings,
        });
      });
    },
    [debouncedCommands]
  );

  return (
    <>{editable && <UploadModal dropTarget={target} onDrop={onDrop} viewableEntityType="ELN" onCreate={onUpload} />}</>
  );
};
