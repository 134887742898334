import React, { useContext, useLayoutEffect } from "react";
import { SessionContext } from "../common/contexts/SessionContext";
import Menue from "../common/panels/Menue/Menue";
import { ManageEntryPage } from "./ManageEntryPage";
import { personsConstants } from "../api/Person";
import { equipmentsConstants, instrumentsConstants, itFacilityConstants } from "../api/Facilities";
import { organizationsConstants } from "../api/Organizations";
import { methodsConstants } from "../api/Methods";
import { sharedContentConstants } from "../api/SharedContent";
import { dataSourceConstants, DataSourceFilters } from "../api/DataSource";
import { bridgeConstants } from "../api/Bridges";
import { apiKeysConstants } from "../api/ApiKey";
import { announcementsConstants } from "../api/Announcements";
import { auditLogConstants } from "../api/AuditLog";
import { equipmentTagsConstants } from "../api/EquipmentTag";
import { iconNMRStatusConstants } from "../api/IconNMR";
import { experimentsConstants } from "../api/Experiments";
import { projectTagsConstants } from "../api/ProjectTag";
import { personTagsConstants } from "../api/PersonTag";
import { organizationTagsConstants } from "../api/OrganizationTag";
import { rolesConstants } from "../api/Role";
import { useEntityCount } from "../api/BaseEntityApi";
import { parsersConstants } from "../api/Parsers";
import { customFieldConstants } from "../api/CustomFields";
import { customTypeConstants } from "../api/CustomTypes";
import { attachmentsConstants } from "../api/Attachments";
import { entitiesConstants } from "../api/Entities";
import { usePermissions } from "../common/permissions/useEntityPermissions";
// import { useIsRefClicked } from "../common/helperfunctions/useIsRefClicked";

interface ManageProps {
  children?: React.ReactNode;
}

export const Manage = ({ children }: ManageProps) => {
  const { session } = useContext(SessionContext);
  const { permissions } = usePermissions();
  // IconNMR
  const { data: iconNMRDataSourcesCountQuery } = useEntityCount<DataSourceFilters>(dataSourceConstants.resource, {
    types: ["IconNMR"],
    includeCount: true,
  });
  const [isFolded, setIsFolded] = React.useState(false);

  // const onClickOutside = useCallback(() => {
  //   if (children) setIsFolded(true);
  // }, [children]);

  // const onClickInside = useCallback(() => {}, []);

  useLayoutEffect(() => {
    if (!children) setIsFolded(false);
  }, [children]);

  // const { ref } = useIsRefClicked<HTMLDivElement>({ onClickOutside, onClickInside });

  return (
    <Menue isFolded={isFolded}>
      <Menue.Items>
        {/* <Menue.Items ref={ref}> */}
        {/* Manage */}
        <Menue.Items.Header label="Content" folded={false}>
          <Menue.Items.Item>
            <Menue.Items.Item.Link
              entityConstants={attachmentsConstants}
              disabled={!permissions.attachments.canReadIndex}
            />
          </Menue.Items.Item>

          <Menue.Items.Item>
            <Menue.Items.Item.Link
              entityConstants={experimentsConstants}
              disabled={!permissions.experiments.canReadIndex}
            />
          </Menue.Items.Item>

          <Menue.Items.Item>
            <Menue.Items.Item.Link
              entityConstants={instrumentsConstants}
              disabled={!permissions.instruments.canReadIndex}
            />
          </Menue.Items.Item>

          <Menue.Items.Item>
            <Menue.Items.Item.Link entityConstants={methodsConstants} disabled={!permissions.methods.canReadIndex} />
          </Menue.Items.Item>

          <Menue.Items.Item>
            <Menue.Items.Item.Link
              entityConstants={organizationsConstants}
              disabled={!permissions.organizations.canReadIndex}
            />
          </Menue.Items.Item>

          <Menue.Items.Item>
            <Menue.Items.Item.Link entityConstants={personsConstants} disabled={!permissions.persons.canReadIndex} />
          </Menue.Items.Item>

          <Menue.Items.Item>
            <Menue.Items.Item.Link entityConstants={entitiesConstants} labelOverride="Search by UUID" />
          </Menue.Items.Item>

          <Menue.Items.Item>
            <Menue.Items.Item.Link
              entityConstants={equipmentsConstants}
              disabled={!permissions.equipments.canReadIndex}
            />
          </Menue.Items.Item>

          <Menue.Items.Item>
            <Menue.Items.Item.Link
              entityConstants={parsersConstants}
              labelOverride={`Supported ${parsersConstants.entityPlural}`}
            />
          </Menue.Items.Item>
        </Menue.Items.Header>

        {/* Custom fields & types */}
        <Menue.Items.Header label={"Customization"} folded={false}>
          <Menue.Items.Item>
            <Menue.Items.Item.Link
              entityConstants={customTypeConstants}
              disabled={!permissions.customTypes.canReadIndex}
            />
          </Menue.Items.Item>
          <Menue.Items.Item>
            <Menue.Items.Item.Link
              entityConstants={customFieldConstants}
              disabled={!permissions.customFields.canReadIndex}
            />
          </Menue.Items.Item>
        </Menue.Items.Header>

        {/* Tags */}
        <Menue.Items.Header label="Tags" folded={false}>
          <Menue.Items.Item>
            <Menue.Items.Item.Link
              entityConstants={equipmentTagsConstants}
              disabled={!permissions.equipmentTags.canReadIndex}
            />
          </Menue.Items.Item>

          <Menue.Items.Item>
            <Menue.Items.Item.Link
              entityConstants={organizationTagsConstants}
              disabled={!permissions.organizationTags.canReadIndex}
            />
          </Menue.Items.Item>

          <Menue.Items.Item>
            <Menue.Items.Item.Link
              entityConstants={personTagsConstants}
              disabled={!permissions.personTags.canReadIndex}
            />
          </Menue.Items.Item>

          <Menue.Items.Item>
            <Menue.Items.Item.Link
              entityConstants={projectTagsConstants}
              disabled={!permissions.projectTags.canReadIndex}
            />
          </Menue.Items.Item>
        </Menue.Items.Header>

        {/* TOOLS */}
        <Menue.Items.Header label="Tools" folded={false}>
          <Menue.Items.Item>
            <Menue.Items.Item.Link entityConstants={apiKeysConstants} />
          </Menue.Items.Item>

          <Menue.Items.Item>
            <Menue.Items.Item.Link
              entityConstants={sharedContentConstants}
              disabled={!permissions.sharedContents.canReadIndex}
            />
          </Menue.Items.Item>
        </Menue.Items.Header>

        <Menue.Items.Header label="Data import" folded={false}>
          <Menue.Items.Item>
            <Menue.Items.Item.Link entityConstants={bridgeConstants} disabled={!permissions.bridges.canReadIndex} />
          </Menue.Items.Item>

          <Menue.Items.Item>
            <Menue.Items.Item.Link
              entityConstants={dataSourceConstants}
              disabled={!permissions.dataSources.canReadIndex}
            />
          </Menue.Items.Item>

          {!!session?.features.enable_legacy_SFTP_autoloads && (
            <Menue.Items.Item>
              <Menue.Items.Item.Link
                entityConstants={itFacilityConstants}
                labelOverride="IT Hardware"
                disabled={[session?.permissions.administer_facilities, session?.permissions.view_admin_pages].every(
                  (p) => !!p === false
                )}
              />
            </Menue.Items.Item>
          )}
        </Menue.Items.Header>

        {/* Monitoring */}
        {!!iconNMRDataSourcesCountQuery?.count && (
          <Menue.Items.Header label={"Monitoring"} folded={false}>
            <Menue.Items.Item>
              <Menue.Items.Item.Link
                entityConstants={iconNMRStatusConstants}
                labelOverride="IconNMR"
                disabled={!permissions.iconNMRHistoryEntry.canReadIndex}
              />
            </Menue.Items.Item>
          </Menue.Items.Header>
        )}

        {/* Admin tools */}
        <Menue.Items.Header label="Admin" folded={false}>
          {session?.permissions.logsadmin &&
            (session?.features.parser_development_mode || session?.features.activate_devel_parsers) && (
              <Menue.Items.Item>
                <Menue.Items.Item.Link
                  entityConstants={{
                    frontendIndexRoute: "parser_selections",
                    entityPlural: "Parser selections",
                    entitySingular: "Parser selection",
                    icon: "swatch-book",
                    resource: "",
                  }}
                  disabled={!permissions.auditLogs.canReadIndex}
                />
              </Menue.Items.Item>
            )}

          <Menue.Items.Item>
            <Menue.Items.Item.Link
              entityConstants={announcementsConstants}
              disabled={!permissions.announcements.canReadIndex}
            />
          </Menue.Items.Item>

          {/* TODO: REMOVE CONDITION ONCE RELEASED */}
          {(session?.permissions.logsadmin || session?.permissions.view_admin_pages) && (
            <Menue.Items.Item>
              <Menue.Items.Item.Link entityConstants={auditLogConstants} />
            </Menue.Items.Item>
          )}

          <Menue.Items.Item>
            <Menue.Items.Item.Link
              entityConstants={{
                frontendIndexRoute: "maintenance",
                entityPlural: "Maintenance",
                entitySingular: "Maintenance",
                icon: "construction",
                resource: "parsing/errors",
              }}
              labelOverride="Dataset maintenance"
              disabled={
                ![
                  session?.permissions.maintenance,
                  session?.permissions.view_admin_pages,
                  session?.features.activate_maintenance,
                ].some((p) => !!p === true)
              }
            />
          </Menue.Items.Item>

          <Menue.Items.Item>
            <Menue.Items.Item.Link entityConstants={rolesConstants} disabled={!permissions.roles.canReadIndex} />
          </Menue.Items.Item>
        </Menue.Items.Header>
      </Menue.Items>
      <Menue.Content>{children ? children : <ManageEntryPage />}</Menue.Content>
    </Menue>
  );
};
