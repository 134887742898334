import { CSSProperties } from "react";
import { ToggleButtonComponent } from "../ToggleButtonComponent";

import styles from "./DecoratedBooleanInput.module.css";

export const DecoratedBooleanInput = ({
  value,
  setValue,
  name,
}: {
  value: boolean;
  setValue?: (value: boolean) => void;
  name?: string;
  style?: CSSProperties;
}) => {
  return (
    <div className={styles.rangeSetter}>
      <div className={styles.settingText} style={{ position: "relative", top: -10 }}>
        {name ? <span>{name}:&nbsp;</span> : null}
      </div>
      <div className={styles.inputField} style={{ position: "relative", top: -8 }}>
        <ToggleButtonComponent checked={value} setChecked={setValue}>
          {/* {value ? "on" : "off"} */}
        </ToggleButtonComponent>
      </div>
    </div>
  );
};
