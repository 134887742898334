import { Modal } from "../../../ELNModal/ELNModal";
import { datasetsConstants, DatasetViewableEntityType, FilesStructure } from "../../../../../api/Datasets";
import { toUppercase } from "../../../../../common/helperfunctions/stringFunctions";
import { attachmentsConstants } from "../../../../../api/Attachments";
import { GenericEntityConstantsEntities } from "../../../../../api/GenericConstants";
import { ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import { ManualUploadBundleView } from "../../../../../Dataset/ManualUpload/ManualUploadBundleView";
import { flat2Hierarchy, useFileHandlingUtils } from "../../../../../Dataset/ManualUpload/FileHandlingUtils";
import { LucideIcon } from "../../../../../common/icon/LucideIcon";
import { defaultDatasetParsersBlackList } from "../../../../../Dataset/ManualUpload/ManualUploadDropArea";
import { UploadedFile } from "../../../../../Dataset/ManualUpload/FileFormatAutoDetection";
import { useManualUpload } from "../../../../../Dataset/ManualUpload/hook/useManualUpload";
import { v4 as uuidv4 } from "uuid";
import { buildTree, getTreeData, TreeElements } from "../../../../../Dataset/tiles/Files/Files";
import { FileTree } from "../../../../../Dataset/common/Tree/Tree";
import { Container } from "../../../../../common/panels/Container/Container";
import { Button } from "../../../../../common/buttons/Button/Button";
import { TableViewLabel } from "../../../../../common/panels/TableView/TableView";
import { LoadingWrapper } from "../../../../../common/LoadingWrapper";
import {
  RenderBundleListControlsProps,
  RenderBundleRowControlsProps,
} from "../../../../../Dataset/ManualUpload/components/RenderBundle";

export const UploadModal = ({
  dropTarget,
  onDrop,
  onClose,
  onCreate,
  viewableEntityType,
}: {
  dropTarget?: HTMLDivElement;
  onDrop?: (ev: DragEvent) => void;
  onClose?: () => void;
  onCreate?: (datasetIds: number[], entityTypeId: GenericEntityConstantsEntities) => void;
  viewableEntityType: DatasetViewableEntityType;
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [ressource, setRessource] = useState<GenericEntityConstantsEntities | undefined>();
  const [files, setFiles] = useState<UploadedFile[]>([]);

  const parserBlackList = useMemo(() => {
    return defaultDatasetParsersBlackList.filter((p) => p !== "doc_multi");
  }, []);
  const parserWhiteList = useMemo(() => {
    return [];
  }, []);

  const { readFileList } = useFileHandlingUtils({
    parserWhiteList: parserWhiteList,
    parserBlackList: parserBlackList,
    setLoading: setLoading,
  });

  const onDropCallback = useCallback(
    async (ev: DragEvent) => {
      if (!!ev?.dataTransfer?.types.includes("Files")) {
        ev.preventDefault();
        ev.stopPropagation();
        setLoading(true);
        setFiles(await readFileList(ev.dataTransfer.files));
        setLoading(false);
      }
      onDrop?.(ev);
    },
    [onDrop, readFileList]
  );

  useEffect(() => {
    if (!dropTarget) return;
    dropTarget.ondrop = onDropCallback;
  }, [dropTarget, onDropCallback]);

  const onCloseCallback = useCallback(() => {
    setRessource(undefined);
    setFiles([]);
    onClose?.();
  }, [onClose]);

  const onSelectDatasets = useCallback(() => {
    setRessource("datasets");
  }, []);

  const onSelectAttachments = useCallback(() => {
    setRessource("attachments");
  }, []);

  if (!files.length) return null;
  return (
    <Modal isOpen={!!files.length} onClose={onCloseCallback}>
      <div style={{ height: "80vh", width: "80vw", overflow: "auto", padding: "10px" }}>
        <LoadingWrapper
          status={loading ? "loading" : "success"}
          fetchStatus={loading ? "fetching" : "idle"}
          type="skeleton-rows"
        >
          {!ressource ? (
            <div className="flex col-nowrap gap-5" style={{ height: "100%" }}>
              <div
                className="flex align-center gap-5"
                style={{
                  padding: "10px",
                  borderBottom: "1px solid var(--gray-300)",
                  fontStyle: "normal",
                  fontWeight: 600,
                  fontSize: "24px",
                  lineHeight: "24px",
                  whiteSpace: "nowrap",
                }}
              >
                <LucideIcon name="upload" style={{ width: "20px", height: "20px", color: "var(--primary)" }} />
                Upload files:
              </div>
              <FilesTile
                files={files}
                controls={
                  <div className="flex align-center" style={{ whiteSpace: "nowrap", gap: "10px" }}>
                    <div>
                      <LucideIcon name="upload" />
                    </div>
                    <div>Upload files as:</div>
                    <button className="btn btn-primary" style={{ margin: 0 }} onClick={onSelectAttachments}>
                      <LucideIcon name={attachmentsConstants.icon} /> {toUppercase(attachmentsConstants.entitySingular)}
                    </button>
                    or
                    <button className="btn btn-primary" style={{ margin: 0 }} onClick={onSelectDatasets}>
                      <LucideIcon name={datasetsConstants.icon} /> {toUppercase(datasetsConstants.entitySingular)}
                    </button>
                  </div>
                }
              />
            </div>
          ) : (
            <div className="flex col-nowrap gap-5" style={{ overflow: "hidden", height: "100%" }}>
              <div className="flex align-center gap-5" style={{ borderBottom: "1px solid var(--gray-300)" }}>
                <button className="btn btn-ghost-secondary" onClick={() => setRessource(undefined)}>
                  <LucideIcon name="arrow-big-left" />
                </button>
                <div style={{ padding: "10px 0" }}>
                  <TableViewLabel
                    entityConstants={ressource === "datasets" ? datasetsConstants : attachmentsConstants}
                    resultsCount={files.length}
                  />
                </div>
              </div>
              {ressource === "datasets" && (
                <UploadModalDatasetWrapper files={files} onClose={onCloseCallback} onCreate={onCreate} />
              )}
              {ressource === "attachments" && (
                <UploadModalAttachmentWrapper
                  files={files}
                  viewableEntityType={viewableEntityType}
                  onClose={onCloseCallback}
                  onCreate={onCreate}
                />
              )}
            </div>
          )}
        </LoadingWrapper>
      </div>
    </Modal>
  );
};

const UploadModalDatasetWrapper = ({
  files,
  onClose,
  onCreate,
}: {
  files: UploadedFile[];
  onClose?: () => void;
  onCreate?: (datasetIds: number[], entityTypeId: GenericEntityConstantsEntities) => void;
}) => {
  const [loading, setLoading] = useState<boolean>(false);

  const parserBlackList = useMemo(() => {
    return defaultDatasetParsersBlackList;
  }, []);
  const parserWhiteList = useMemo(() => {
    return [];
  }, []);

  const { asyncDispatch, ...rest } = useFileHandlingUtils({
    parserBlackList,
    parserWhiteList,
    setLoading: setLoading,
  });

  useMemo(async () => {
    const actionUUID = uuidv4();
    await asyncDispatch(actionUUID, actionUUID, files).catch((e) => console.error(e));
  }, [asyncDispatch, files]);

  const additionalRowControls = useCallback(
    (props: RenderBundleRowControlsProps) => {
      return <InsertButton {...props} onCreate={onCreate} />;
    },
    [onCreate]
  );
  const additionalListControls = useCallback(
    ({ loading, isProcessing, uploadMutation, datasets }: RenderBundleListControlsProps) => {
      const datasetIds = datasets.filter((d) => !!d.datasetId).map((d) => d.datasetId) as number[];
      const disabled = loading || isProcessing || uploadMutation.isLoading || datasets.some((d) => !d.datasetId);
      return (
        <Button
          disabled={disabled}
          className="btn btn-default btn-sm flex align-center gap-5"
          onClick={() => {
            onCreate?.(datasetIds, "datasets");
            onClose?.();
          }}
        >
          <LucideIcon name="chevron-right" />
          Insert all
        </Button>
      );
    },
    [onClose, onCreate]
  );

  return (
    <ManualUploadBundleView
      isViewableEntity={false}
      loading={loading}
      {...rest}
      additionalRowControls={additionalRowControls}
      additionalListControls={additionalListControls}
      onAbortBundle={onClose}
    />
  );
};

const InsertButton = ({
  dataset,
  loading,
  isProcessing,
  uploadMutation,
  onCreate,
}: RenderBundleRowControlsProps & {
  onCreate?: (datasetIds: number[], entityTypeId: GenericEntityConstantsEntities) => void;
}) => {
  const [inserted, setInserted] = useState<boolean>(false);
  if (!dataset.datasetId) return null;
  return (
    <Button
      disabled={inserted || loading || isProcessing || uploadMutation.isLoading || !dataset.datasetId}
      className="btn btn-default flex align-center gap-5"
      onClick={() => {
        setInserted(true);
        !!dataset.datasetId && onCreate?.([dataset.datasetId], "datasets");
      }}
    >
      {inserted ? <LucideIcon name="check" color="var(--success)" /> : <LucideIcon name="chevron-right" />}
      Insert{inserted ? "ed" : ""}
    </Button>
  );
};

const UploadModalAttachmentWrapper = ({
  files,
  viewableEntityType,
  onClose,
  onCreate,
}: {
  files: UploadedFile[];
  viewableEntityType: DatasetViewableEntityType;
  onClose?: () => void;
  onCreate?: (datasetIds: number[], entityTypeId: GenericEntityConstantsEntities) => void;
}) => {
  const [loading, setLoading] = useState<boolean>(false);

  const parserWhiteList = useMemo(() => {
    return ["doc_multi"];
  }, []);

  const { uploadFileList, uploadedDatasets, clearUpload } = useManualUpload({
    viewableEntityType,
    whiteList: parserWhiteList,
  });

  const uploadFiles = useCallback(async () => {
    setLoading(true);
    const uuid = uuidv4();
    await uploadFileList(
      files.map((f) => f.file),
      uuid
    ).catch((e) => console.error(e));
    setLoading(false);
  }, [files, uploadFileList]);

  useEffect(() => {
    var datasetIds: number[] = [];
    Object.keys(uploadedDatasets).forEach((d) => {
      uploadedDatasets[d].datasets.forEach((ds) => {
        ds.datasetId && datasetIds.push(ds.datasetId);
      });
    });
    if (!datasetIds.length) return;
    onCreate?.(datasetIds, "attachments");
    clearUpload();
    onClose?.();
  }, [clearUpload, onClose, onCreate, uploadedDatasets]);

  return (
    <FilesTile
      files={files}
      controls={
        <div className="flex align-center gap-5">
          <Button
            className="btn btn-success flex align-center gap-5"
            disabled={!files.length}
            onClick={uploadFiles}
            loading={loading}
          >
            <LucideIcon name="upload" />
            Upload {files.length} files
          </Button>
          <Button className="btn btn-soft-secondary flex align-center gap-5" onClick={() => onClose?.()}>
            <LucideIcon name="x" />
            Abort
          </Button>
        </div>
      }
    />
  );
};

const FilesTile = ({ files, controls }: { files: UploadedFile[]; controls?: ReactNode }) => {
  const fileTree = useMemo(() => {
    let struct: TreeElements<FilesStructure> = [];
    buildTree(flat2Hierarchy(files as any), undefined, struct);
    return struct;
  }, [files]);
  return (
    <div className="flex align-center" style={{ padding: "1px", height: "100%", overflow: "hidden" }}>
      <Container
        title={
          <div className="flex align-center gap-5">
            <LucideIcon name="file" />
            Files <span className="badge">{files.length}</span>
          </div>
        }
        controls={controls}
      >
        <div className="flex" style={{ overflow: "auto", width: "100%", height: "100%" }}>
          <div className="flex" style={{ width: "100%", height: "min-content" }}>
            <FileTree files={getTreeData(fileTree)} level={0} />
          </div>
        </div>
      </Container>
    </div>
  );
};
