import { Dispatch, SetStateAction, useCallback, useContext, useEffect, useState } from "react";
import {
  IconNMRStatus,
  iconNMRConstants,
  iconNMRHistoryConstants,
  iconNMRHoldersConstants,
  iconNMRStatusConstants,
} from "../../api/IconNMR";
import styles from "./IconNMRStatus.module.css";
import { ParameterFormatter } from "../../ParameterViewer/TreeViewer/ParameterFormatter";
import { instrumentsConstants } from "../../api/Facilities";
import { LinkEntity } from "../../common/misc/LinkEntity/LinkEntity";
import { useListEntity } from "../../api/BaseEntityApi";
import { LoadingWrapper } from "../../common/LoadingWrapper";
import { SessionContext } from "../../common/contexts/SessionContext";
import { getEditLink, getIndexRoute } from "../../main/Routing";
import { DateTimeRenderer } from "../../common/datetime/DateTimeFormatter";
import { useHistory } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { dataSourceConstants } from "../../api/DataSource";
import { LucideIcon } from "../../common/icon/LucideIcon";

const parameterFormatter = new ParameterFormatter();

export const IconNMRStatusOverview = ({ countCallback }: { countCallback?: Dispatch<SetStateAction<number>> }) => {
  const { route, session } = useContext(SessionContext);
  const history = useHistory();
  const { data, status, fetchStatus, error } = useListEntity<IconNMRStatus>(iconNMRStatusConstants.resource, undefined);
  useEffect(() => {
    countCallback?.(data?.results.length || 0);
  }, [data, countCallback]);
  const [lastUpdated, setLastUpdated] = useState<Date>(new Date());

  const queryClient = useQueryClient();

  const refetchData = useCallback(() => {
    queryClient.invalidateQueries({ queryKey: [iconNMRStatusConstants.resource] });
    queryClient.invalidateQueries({ queryKey: [iconNMRHoldersConstants.resource] });
    queryClient.invalidateQueries({ queryKey: [iconNMRHistoryConstants.resource] });
    queryClient.invalidateQueries({ queryKey: [dataSourceConstants.resource] });
    setLastUpdated(new Date());
  }, [queryClient]);

  useEffect(() => {
    const interval = setInterval(() => {
      refetchData();
    }, 60000);
    return () => clearInterval(interval);
  }, [refetchData]);
  return (
    <LoadingWrapper status={status} fetchStatus={fetchStatus} error={error}>
      <table style={{ width: "100%" }}>
        <thead>
          <tr>
            <th className={`${styles.iconNMRStatusTableCell} ${styles.iconNMRStatusTableTh}`} colSpan={4}>
              <div className="flex align-center gap-5" style={{ justifyContent: "space-between", width: "100%" }}>
                <div className="flex align-center gap-5" style={{ whiteSpace: "nowrap" }}>
                  <span>Last updated:</span>
                  <DateTimeRenderer date={lastUpdated.toISOString()} includeElapsed={false} />
                </div>
                <button className="btn btn-ghost-primary btn-xs flex align-center gap-5" onClick={refetchData}>
                  <LucideIcon name="refresh-cw" />
                  Refresh
                </button>
              </div>
            </th>
          </tr>
          <tr>
            <th className={`${styles.iconNMRStatusTableCell} ${styles.iconNMRStatusTableTh}`}>
              <div className="flex align-center">Instrument:</div>
            </th>
            <th className={`${styles.iconNMRStatusTableCell} ${styles.iconNMRStatusTableTh}`}>
              <div className="flex align-center">Acquisition:</div>
            </th>
            <th className={`${styles.iconNMRStatusTableCell} ${styles.iconNMRStatusTableTh}`}>
              <div className="flex align-center">Experiments queued:</div>
            </th>
            <th className={`${styles.iconNMRStatusTableCell} ${styles.iconNMRStatusTableTh}`}>
              <div className="flex align-center">Length of queue:</div>
            </th>
            <th
              className={`${styles.iconNMRStatusTableCell} ${styles.iconNMRStatusTableTh}`}
              style={{ width: "30px" }}
            />
          </tr>
        </thead>
        <tbody>
          {data &&
            data.results.map((stat, index) => (
              <tr className={styles.iconNMRStatusTableTr} key={index}>
                <td className={styles.iconNMRStatusTableCell}>
                  <div className="flex align-center">
                    <LinkEntity
                      entityConstants={instrumentsConstants}
                      property={stat.dataSource.instrument}
                      routeOverride={
                        !!stat.dataSource.instrument?.id
                          ? route(getIndexRoute(iconNMRConstants.frontendIndexRoute)) +
                            `/queue?instrumentId=${stat.dataSource.instrument.id}`
                          : route(getIndexRoute(iconNMRConstants.frontendIndexRoute)) + "/queue"
                      }
                    />
                  </div>
                </td>
                <td className={styles.iconNMRStatusTableCell}>
                  <label
                    className={`label label-soft-${stat.acquisitionRunning ? "success" : "default"}`}
                    style={{ margin: 0 }}
                  >
                    {stat.acquisitionRunning ? "Running" : "Idle"}
                  </label>
                </td>
                <td className={styles.iconNMRStatusTableCell}>
                  <div className="flex align-center">{stat.queuedExperiments}</div>
                </td>
                <td className={styles.iconNMRStatusTableCell}>
                  {parameterFormatter.formatDuration(stat.totalQueueTimeInSeconds, 1).join(" ")}
                </td>
                <td className={styles.iconNMRStatusTableCell}>
                  <div className="flex align-center" style={{ justifyContent: "center" }}>
                    <button
                      title="Edit monitoring settings"
                      className="btn btn-xs btn-ghost-secondary"
                      onClick={() =>
                        history.push(route(getEditLink(iconNMRConstants.frontendIndexRoute, stat.dataSource.id)))
                      }
                      disabled={!session?.permissions.administer_autoload}
                    >
                      <LucideIcon name="settings" />
                    </button>
                  </div>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </LoadingWrapper>
  );
};
