import { StringIndexedDict } from "../../../../api/GenericTypes";
import {
  GenericEntityVirtualizedEntitySelectFormFieldProps,
  GenericVirtualizedEntitySelectFormFieldControllerWrapper,
} from "../../../formfields/GenericVirtualizedSelectFormField/GenericVirtualizedEntitySelectFormFieldControllerWrapper";
import { QuickAddWrapper } from "../../QuickAdd/common/QuickAddWrapper";
import { useContext } from "react";
import { SessionContext } from "../../../contexts/SessionContext";
import {
  SharedContentFilters,
  SharedContentSortingOptionsConsts,
  SharedContentSuggestions,
  sharedContentConstants,
} from "../../../../api/SharedContent";
import { QuickAddSharedContentForm } from "../../QuickAdd/forms/QuickAddSharedContentForm";

export const SharedContentsVirtualizedSelectForm = <Entity extends StringIndexedDict>(
  props: GenericEntityVirtualizedEntitySelectFormFieldProps<Entity, SharedContentSuggestions, SharedContentFilters>
) => {
  const { session } = useContext(SessionContext);
  return (
    <GenericVirtualizedEntitySelectFormFieldControllerWrapper<Entity, SharedContentSuggestions, SharedContentFilters>
      {...props}
      filters={{ orderBy: "CREATION_DATE_DESC", ...props.filters }}
      //   onRowRenderer={(item) => <></>}
      createModal={({ showModal, setShowModal, onCreate }) => (
        <QuickAddWrapper showModal={showModal} setShowModal={setShowModal} onCreate={onCreate}>
          {(quickAddProps) => <QuickAddSharedContentForm {...quickAddProps} />}
        </QuickAddWrapper>
      )}
      entityConstants={sharedContentConstants}
      orderByOptions={SharedContentSortingOptionsConsts}
      allowCreateEntity={session?.permissions.add_bundles && props.allowCreateEntity}
    />
  );
};
