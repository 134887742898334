import { EmptyParameter } from "./EmptyParameter";
import { pipeTrackType, SerializedPipeTrackType, SerializedTrackSelection } from "../PipelineTypes";
import { parameterTypes, trackListElement, trackListType } from "../PipelineTypes";

type valueType = {
  nameRegex?: string;
  trackName?: string;
  trackIndex?: [number, number];
  trackType?: string;
  selected?: string;
  tracks?: SerializedPipeTrackType[];
  _value: (tracks: trackListType) => trackListElement;
};

export class TrackSelection extends EmptyParameter {
  name: string = "trackSelection";
  parameterType = parameterTypes.trackSelection;

  nameRegex?: string;
  // nameRegexString?: string;
  trackName?: string;
  trackIndex?: [number, number];
  trackType?: string;

  selected?: string;
  tracks?: SerializedPipeTrackType[];

  remove_slash_re: RegExp;

  value: (tracks: trackListType) => trackListElement;
  private _value: (tracks: trackListType) => trackListElement;

  // private listPattern: TrackSelection;

  constructor(value?: any) {
    super(value);
    // console.log("TrackSelection");
    this.remove_slash_re = /^\/|\/$/g;
    this._value = (tracks) => {
      return {} as trackListElement;
    };
    this.value = this._value.bind(this);

    this.deserialize(value);
  }

  deserialize(value?: SerializedTrackSelection) {
    super.deserialize(value);
    const v = this.parseValue(value);
    this._value = v._value;
    this.value = this._value.bind(this);
    this.nameRegex = v.nameRegex;
    this.trackName = v.trackName;
    this.trackIndex = v.trackIndex;
    this.trackType = v.trackType;
    this.selected = v.selected;
    this.tracks = v.tracks;
  }

  parseValue(value?: SerializedTrackSelection): valueType {
    const result: valueType = { _value: this._value };

    if (value == null) value = {} as SerializedTrackSelection;
    if (typeof value !== "object") {
      this.errors.create({
        id: "",
        component: "TrackSelection.setValue",
        message: `Expected type 'Record<string, string>'. (Got type '${typeof value}').`,
      });
      return result;
    } else if (Array.isArray(value)) {
      this.errors.create({
        id: "",
        component: "TrackSelection.setValue",
        message: `Expected type 'Record<string, string>'. (Got type 'array').`,
      });
      return result;
    }

    // console.log("value", value);

    const { regex, trackName, index, type, selected, tracks } = value;
    if (regex) {
      result.nameRegex = regex;
      try {
        const r = new RegExp(regex);
        result.nameRegex = r.toString().replace(this.remove_slash_re, "");
      } catch (e: any) {
        this.errors.create({
          id: "",
          component: "TrackSelection.setValue",
          message: `Invalid regular expression '${regex}': ${e.message}`,
        });
      }
    }

    if (index) {
      if (Array.isArray(index)) {
        if (typeof index[0] === "number" && typeof index[1] === "number") {
          result.trackIndex = index.slice(0, 2).map((s: any) => parseInt(s)) as [number, number];
        } else
          this.errors.create({
            id: "",
            component: "TrackSelection.setValue",
            message: `Index type must be a tuple of numbers. (Got [${typeof index[0]}, ${typeof index[1]}]')`,
          });
      } else
        this.errors.create({
          id: "",
          component: "TrackSelection.setValue",
          message: `Index type must be a tuple of numbers. (Got type '${typeof index}')`,
        });
    }

    if (trackName) {
      if (typeof trackName === "string") result.trackName = trackName;
      else
        this.errors.create({
          id: "",
          component: "TrackSelection.setValue",
          message: `Name type 'string' expected. (Got '${typeof trackName}')`,
        });
    }
    if (type) {
      if (typeof type === "string") this.trackType = type;
      else
        this.errors.create({
          id: "",
          component: "TrackSelection.setValue",
          message: `Type type 'string' expected. (Got '${typeof type}')`,
        });
    }

    if (selected) {
      result.selected = selected;
    }

    if (tracks) {
      result.tracks = tracks;
    }
    // console.log(">>>");

    result._value = (tracks: trackListType): trackListElement => {
      // console.log(
      //   ">>>",
      //   tracks.map((track) => [this.trackName, track?.label, this.trackName === undefined ? true : this.trackName === track?.label])
      // );
      let selected: trackListElement;
      if (this.selected) {
        selected = tracks.filter((track) => track?.id === this.selected).shift();
      }

      if (!selected) {
        selected = tracks
          .filter((track) => track !== undefined)
          .filter((track) => (this.trackName === undefined ? true : this.trackName === track?.name))
          .filter((track) => (this.trackType === undefined ? true : this.trackType === track?.type))
          .filter((track) => {
            const index = track?.index ?? -1;
            return this.trackIndex === undefined ? true : index >= this.trackIndex[0] && index < this.trackIndex[1];
          })
          .filter((track) =>
            this.nameRegex === undefined ? true : new RegExp(this.nameRegex).exec(track?.name ?? "")
          )
          .shift();
      }

      this.selected = selected?.id;

      this.tracks = tracks
        .filter((track) => track !== undefined)
        .filter((track) => (this.trackType === undefined ? true : this.trackType === track?.type))
        .map((track) => {
          return {
            id: (track as pipeTrackType).id,
            name: (track as pipeTrackType).name,
            color: (track as pipeTrackType).settings.color,
          };
        });

      return selected;
    };

    return result;
  }

  serialize(storageMode?: boolean): SerializedTrackSelection {
    // const result = serializeCommandParameter(this) as SerializedTrackSelection;
    const result = super.serialize(storageMode) as SerializedTrackSelection;

    if (this.nameRegex) result.regex = this.nameRegex;
    if (this.trackName) result.trackName = this.trackName;
    if (this.trackIndex) result.index = this.trackIndex;
    if (this.trackType) result.type = this.trackType;
    if (!storageMode) {
      if (this.selected) result.selected = this.selected;
      if (this.tracks) result.tracks = this.tracks;
    }
    // if (storageMode) console.log("result", result);
    // console.log(
    //   "SerializedTrackSelection",
    //   this.tracks?.filter((t) => t.id === this.selected).shift()?.name,
    //   "->",
    //   this.tracks?.map((t) => t.name)
    // );
    return result;
  }
}
