import { Dispatch, Fragment, SetStateAction } from "react";
import { useDeleteMutation } from "../../api/BaseEntityApi";
import { AlertModal } from "../../common/modals/AlertModal/AlertModal";
import { ResourceName } from "../../main/Routing";
import styles from "./ELNCreateOrEditForms.module.css";

interface EntityInfo {
  [key: string]: string | number | undefined;
}

export const ELNDeleteConfirmationModal = ({
  isOpen,
  setIsOpen,
  id,
  resource,
  onCancel,
  onDelete,
  name,
  description,
  entityInfo,
  forceUserInput = false,
  deletePermanently = false,
}: {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  id?: number;
  resource: ResourceName;
  onCancel?: () => void;
  onDelete?: () => void;
  name?: string;
  description?: string;
  entityInfo?: EntityInfo;
  forceUserInput?: boolean;
  deletePermanently?: boolean;
}) => {
  const deleteMutation = useDeleteMutation(resource);

  if (!id) return null;
  return (
    <AlertModal
      type={deletePermanently ? "danger" : "warning"}
      title={name ? `${deletePermanently ? "Delete " : "Trash "}` + name + "?" : "Delete?"}
      proceedLabel={`${deletePermanently ? "Delete" : "Trash"}`}
      description={
        <>
          {entityInfo && (
            <>
              <div className={styles.deleteEntityInfoContainer}>
                {Object.keys(entityInfo).map((key, i) => (
                  <Fragment key={i}>
                    <div className={styles.deleteEntityInfoKey}>{key}:</div>
                    <div className={styles.deleteEntityInfoValue}>{entityInfo[key] || "-"}</div>
                  </Fragment>
                ))}
              </div>
            </>
          )}
          {description && <div className={styles.description}>{description}</div>}
        </>
      }
      showModal={isOpen}
      setShowModal={setIsOpen}
      forceUserInput={deletePermanently || forceUserInput}
      forceUserInputText={name}
      onCancel={() => {
        onCancel && onCancel();
      }}
      onProceed={() => {
        deleteMutation.mutate(
          { id, params: { deletePermanently } },
          {
            onSuccess: () => {
              onDelete && onDelete();
            },
          }
        );
      }}
    />
  );
};
