import React from "react";
import { IconProps, toast } from "react-toastify";
import { LucideIcon } from "../../icon/LucideIcon";

export const customToast = (children: (closeToast: (() => void) | undefined) => React.ReactNode) => {
  return toast(({ closeToast }) => children(closeToast), {
    position: "top-right",
    autoClose: false,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    className: "customToast",
    bodyClassName: "customToastBody",
    closeButton: false,
    // progressClassName: "fancy-progress-bar",
  });
};

export const showtoast = (
  type: "info" | "success" | "warning" | "error" | "default",
  message: string | React.ReactNode,
  onClick?: (event: React.MouseEvent<Element, MouseEvent>) => void,
  autoCloseInMs?: number | false,
  closeButton?: boolean,
  icon?: (props: IconProps) => React.ReactNode
) => {
  switch (type) {
    case "default":
      toast(message, {
        position: "top-center",
        autoClose: autoCloseInMs ?? 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        closeButton: closeButton,
        onClick,
      });
      break;
    case "info":
      toast.info(message, {
        position: "top-center",
        autoClose: autoCloseInMs ?? 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        icon: icon ?? (({ theme, type }) => <LucideIcon name="info" color={"var(--info)"} />),
        closeButton: closeButton,
        onClick,
      });
      break;
    case "success":
      toast.success(message, {
        position: "top-center",
        autoClose: autoCloseInMs ?? 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        icon: icon ?? (({ theme, type }) => <LucideIcon name="check" color={"var(--success)"} />),
        closeButton: closeButton,
        onClick,
      });
      break;
    case "warning":
      toast.warning(message, {
        position: "top-center",
        autoClose: autoCloseInMs ?? 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        icon: icon ?? (({ theme, type }) => <LucideIcon name="circle-alert" color={"var(--warning)"} />),
        closeButton: closeButton,
        onClick,
      });
      break;
    case "error":
      toast.error(message, {
        position: "top-center",
        autoClose: autoCloseInMs ?? false,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        icon: icon ?? (({ theme, type }) => <LucideIcon name="triangle-alert" color={"var(--danger)"} />),
        closeButton: closeButton,
        onClick,
      });
      break;
  }
};
