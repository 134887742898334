import { Dataset } from "../api/Datasets";
import { LucideIcon } from "../common/icon/LucideIcon";
import { viewerLogType } from "./ViewerLayoutTypes";

interface Props {
  datasetId?: Dataset["id"];
  logs: viewerLogType[];
}
export const ParserErrorFeedback = ({ datasetId, logs }: Props) => {
  return (
    <button
      className="btn btn-soft-info"
      onClick={(e) => {
        // const url = `mailto:support@sciy.com?subject=${mail.subject}&body=${mail.body}`;
        window.open("https://logs-support.sciy.com/new/", "_blank");
        e.preventDefault();
      }}
    >
      <LucideIcon name="mail" /> Send report
    </button>
  );
};
