import { DefinedPropertyObject, IEntityMinimalModel } from "../../api/GenericTypes";
import { Project, ProjectFilters, ProjectFieldLabels } from "../../api/Projects";
import { FilterSidebarProps, useFilterSidebar } from "../../common/sidebar/filtersidebar/FilterSidebar";
import { PersonsVirtualizedSelectForm } from "../../common/forms/EntityForms/formsVirtualized/PersonsVirtualizedSelectForm";
import { EntityFilterSideBar } from "../../common/sidebar/filtersidebar/EntityFilterBar";
import { SplitDatePickerFormField } from "../../common/formfields/DatePickerFormField/SplitDatePickerFormField";
import { ProjectsTagsVirtualizedSelectForm } from "../../common/forms/EntityForms/formsVirtualized/ProjectTagsVirtualizedSelectForm";
import { CustomTypesVirtualizedSelectForm } from "../../common/forms/EntityForms/formsVirtualized/CustomTypesVirtualizedSelectForm";
import { useContext } from "react";
import { SessionContext } from "../../common/contexts/SessionContext";

export interface ProjectFilterForm {
  projectTagIds?: IEntityMinimalModel[] | undefined | null;
  createdByIds?: IEntityMinimalModel[] | undefined | null;
  createdFrom?: Date | undefined | null;
  createdTo?: Date | undefined | null;
  modifiedByIds?: IEntityMinimalModel[] | undefined | null;
  modifiedFrom?: Date | undefined | null;
  modifiedTo?: Date | undefined | null;
  customType?: { id: number; name: string }[] | undefined | null;
}

const defaultValues: DefinedPropertyObject<ProjectFilterForm> = {
  projectTagIds: null,
  createdByIds: null,
  createdFrom: null,
  createdTo: null,
  modifiedByIds: null,
  modifiedFrom: null,
  modifiedTo: null,
  customType: null,
};
const formToFilters = (form: ProjectFilterForm): ProjectFilters => {
  let filters: ProjectFilters = {};
  filters.projectTagIds =
    Array.isArray(form.projectTagIds) && form.projectTagIds.length > 0 ? form.projectTagIds.map((t) => t.id) : null;
  filters.createdByIds =
    Array.isArray(form.createdByIds) && form.createdByIds.length > 0 ? form.createdByIds?.map((d) => d.id) : null;
  filters.createdFrom = form.createdFrom ? form.createdFrom.toISOString() : null;
  filters.createdTo = form.createdTo ? form.createdTo.toISOString() : null;
  filters.modifiedByIds =
    Array.isArray(form.modifiedByIds) && form.modifiedByIds.length > 0 ? form.modifiedByIds?.map((d) => d.id) : null;
  filters.modifiedFrom = form.modifiedFrom ? form.modifiedFrom.toISOString() : null;
  filters.modifiedTo = form.modifiedTo ? form.modifiedTo.toISOString() : null;
  filters.customTypeIds =
    Array.isArray(form.customType) && form.customType.length > 0 ? form.customType.map((d) => d.id) : null;
  return filters;
};
const defaultFilters: ProjectFilters = { orderBy: "NAME_ASC" };

export const ProjectFilterBar = ({
  initialValues,
  setFilters,
  onFormChange,
  dispatchTabStore,
  tabsLoading,
  currentTab,
}: FilterSidebarProps<Project, ProjectFilterForm, ProjectFilters>) => {
  const { session } = useContext(SessionContext);
  const { control, onReset } = useFilterSidebar({
    initialValues,
    defaultValues,
    defaultFilters,
    formToFilters,
    onFormChange,
    dispatchTabStore,
    setFilters,
  });

  return (
    <EntityFilterSideBar onReset={onReset}>
      {session?.features.enable_project_custom_types && (
        <CustomTypesVirtualizedSelectForm
          id="customType"
          control={control}
          showControls={false}
          filters={{ entityTypes: ["Project"] }}
          isMulti
          enableBasicOption
          hideIfNoTypes
        />
      )}
      <ProjectsTagsVirtualizedSelectForm
        id="projectTagIds"
        label={ProjectFieldLabels.projectTags}
        control={control}
        isMulti
      />

      {currentTab !== "mydata" && (
        <PersonsVirtualizedSelectForm id="createdByIds" label="Created by" control={control} isMulti />
      )}
      <SplitDatePickerFormField
        control={control}
        idStart="createdFrom"
        idEnd="createdTo"
        label="Creation date (from - to)"
        horizontal={false}
        showTimeSelect
      />
      <PersonsVirtualizedSelectForm id="modifiedByIds" label="Modified by" control={control} isMulti />

      <SplitDatePickerFormField
        control={control}
        idStart="modifiedFrom"
        idEnd="modifiedTo"
        label="Modification date (from - to)"
        horizontal={false}
        showTimeSelect
      />
    </EntityFilterSideBar>
  );
};
