import { EquipmentTag, EquipmentTagFilters } from "../../api/EquipmentTag";
import { DefinedPropertyObject, IEntityMinimalModel } from "../../api/GenericTypes";
import { FilterSidebarProps, useFilterSidebar } from "../../common/sidebar/filtersidebar/FilterSidebar";
import { EquipmentsVirtualizedSelectForm } from "../../common/forms/EntityForms/formsVirtualized/EquipmentsVirtualizedSelectForm";
import { EntityFilterSideBar } from "../../common/sidebar/filtersidebar/EntityFilterBar";
import { SplitDatePickerFormField } from "../../common/formfields/DatePickerFormField/SplitDatePickerFormField";

export interface EquipmentTagFilterForm {
  createdByIds?: IEntityMinimalModel[] | undefined | null;
  createdFrom?: Date | undefined | null;
  createdTo?: Date | undefined | null;
  modifiedByIds?: IEntityMinimalModel[] | undefined | null;
  modifiedFrom?: Date | undefined | null;
  modifiedTo?: Date | undefined | null;
}
const defaultValues: DefinedPropertyObject<EquipmentTagFilterForm> = {
  createdByIds: null,
  createdFrom: null,
  createdTo: null,
  modifiedByIds: null,
  modifiedFrom: null,
  modifiedTo: null,
};

const formToFilters = (form: EquipmentTagFilterForm): EquipmentTagFilters => {
  let filters: EquipmentTagFilters = {};
  filters.createdByIds =
    Array.isArray(form.createdByIds) && form.createdByIds.length > 0 ? form.createdByIds?.map((d) => d.id) : null;
  filters.createdFrom = form.createdFrom ? form.createdFrom.toISOString() : null;
  filters.createdTo = form.createdTo ? form.createdTo.toISOString() : null;
  filters.modifiedByIds =
    Array.isArray(form.modifiedByIds) && form.modifiedByIds.length > 0 ? form.modifiedByIds?.map((d) => d.id) : null;
  filters.modifiedFrom = form.modifiedFrom ? form.modifiedFrom.toISOString() : null;
  filters.modifiedTo = form.modifiedTo ? form.modifiedTo.toISOString() : null;

  return filters;
};
const defaultFilters: EquipmentTagFilters = { orderBy: "NAME_ASC" };

export const EquipmentTagFilterBar = ({
  initialValues,
  setFilters,
  dispatchTabStore,
  onFormChange,
  tabsLoading,
  currentTab,
}: FilterSidebarProps<EquipmentTag, EquipmentTagFilterForm, EquipmentTagFilters>) => {
  const { control, onReset } = useFilterSidebar({
    initialValues,
    defaultValues,
    defaultFilters,
    formToFilters,
    onFormChange,
    dispatchTabStore,
    setFilters,
  });

  return (
    <EntityFilterSideBar onReset={onReset}>
      {currentTab !== "mydata" && (
        <EquipmentsVirtualizedSelectForm id="createdByIds" label="Created by" control={control} isMulti />
      )}
      <SplitDatePickerFormField
        control={control}
        idStart="createdFrom"
        idEnd="createdTo"
        label="Creation date (from - to)"
        horizontal={false}
        showTimeSelect
      />
      <EquipmentsVirtualizedSelectForm id="modifiedByIds" label="Modified by" control={control} isMulti />

      <SplitDatePickerFormField
        control={control}
        idStart="modifiedFrom"
        idEnd="modifiedTo"
        label="Modification date (from - to)"
        horizontal={false}
        showTimeSelect
      />
    </EntityFilterSideBar>
  );
};
