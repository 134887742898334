import produce from "immer";
import { useEffect, useState } from "react";
import { SerializedPipeTrackType } from "../../../ProcessingPipeline/PipelineTypes";
import { DecoratedChoiceInput } from "../../../ViewerUIElements/Range/DecoratedChoiceInput";

import { needUpdate } from "../../../ViewerLayout/ViewerLayoutUtils";

type patternType = {
  regex?: string;
  trackName?: string;
  index?: [number, number];
  type?: string;
  selected?: string;
  tracks?: SerializedPipeTrackType[];
};

// const TrackPattern = ({
//   pattern,
//   setPattern,
// }: {
//   pattern: patternType;
//   setPattern: (pattern: patternType) => void;
// }) => {
//   return (
//     <div>
//       {pattern.regex ? (
//         <DecoratedStringInput
//           name={"regex"}
//           value={pattern.regex}
//           setValue={(s: string) =>
//             setPattern(
//               produce(pattern, (next) => {
//                 next.regex = s;
//               })
//             )
//           }
//         />
//       ) : null}
//       {pattern.trackName ? (
//         <DecoratedStringInput
//           name={"name"}
//           value={pattern.trackName}
//           setValue={(s: string) =>
//             setPattern(
//               produce(pattern, (next) => {
//                 next.trackName = s;
//               })
//             )
//           }
//         />
//       ) : null}
//       {pattern.index ? (
//         <div className={styles.tupleField}>
//           <DecoratedNumberInput
//             name={"from"}
//             value={pattern.index[0]}
//             useInteger
//             setValue={(i: number) =>
//               setPattern(
//                 produce(pattern, (next) => {
//                   next.index = pattern.index ?? [0, 0];
//                   next.index[0] = i;
//                 })
//               )
//             }
//           />
//           &nbsp;
//           <DecoratedNumberInput
//             name={"to"}
//             value={pattern.index[1]}
//             useInteger
//             setValue={(i: number) =>
//               setPattern(
//                 produce(pattern, (next) => {
//                   next.index = pattern.index ?? [0, 0];
//                   next.index[1] = i;
//                 })
//               )
//             }
//           />
//         </div>
//       ) : null}
//     </div>
//   );
// };

export const TrackListPatternInput = ({
  parameterName,
  value,
  setParameter,
}: {
  parameterName: string;
  value: patternType[];
  setParameter?: (parameterName: string, parameters: Record<string, any>) => void;
}) => {
  const [userValue, setUserValue] = useState<patternType[]>(value);

  // useEffect(() => {
  //   if (setParameter) setParameter(parameterName, { value: userValue });
  // }, [userValue]);

  useEffect(() => {
    // console.log("value", value);
    const t0 = value.map((v, i) => v.tracks?.map((t) => t.color?.id));
    const t1 = userValue.map((v, i) => v.tracks?.map((t) => t.color?.id));
    // console.log("new", t0);
    // console.log("old", t1);
    if (value.some((v, i) => v.selected !== userValue?.[i].selected) || needUpdate(t1, t0)) setUserValue(value);
    // else if (value.length < 1) setUserValue([{ trackName: "" }]);
    // if (needUpdate(userValue, value)) setUserValue(value);
  }, [value]);

  // const CSSLen = useMemo<string>(() => {
  //   let len = 0;
  //   for (let p of value) {
  //     len += (p.regex ? 1 : 0) + (p.trackName ? 1 : 0) + (p.index ? 1 : 0);
  //   }
  //   if (len > 5) len = 5;
  //   len = len * 2.1 + value.length * 1.4;
  //   return `${len}em`;
  // }, [value]);

  return (
    <div>
      {(userValue?.length > 0 ? userValue : [{ trackName: "" }]).map((p, i) => {
        return (
          <DecoratedChoiceInput
            key={i}
            name={`Track${userValue.length > 1 ? " " + (i + 1) : ""}`}
            value={p.selected}
            choices={
              p.tracks
                ? p.tracks
                    .filter((t) => t)
                    .map((t) => {
                      return { id: t.id, value: t.name, color: t.color };
                    })
                : []
            }
            setValue={(v) => {
              if (setParameter) {
                const s = produce(value, (next) => {
                  next[i].selected = v;
                });
                // const s = selected.slice();
                // s[i] = v;
                setParameter(parameterName, { value: s });
              }
            }}
          />
        );
      })}
    </div>
  );
};
