import { EquipmentTagForm } from "./EquipmentTagForm";
import { EntityAddForm, EntityAddFormPage } from "../common/entity/EntityAddForm";
import { EntityCloneForm, EntityCloneFormPage } from "../common/entity/EntityCloneForm";
import { EntityCloneFormProps, EntityDetailViewProps, EntityEditFormProps } from "../common/entity/EntityInterfaces";
import { EntityEditForm, EntityEditFormPage } from "../common/entity/EntityEditForm";
import { EntityDetailPage, EntityDetailView } from "../common/entity/EntityDetailPage";
import { EquipmentTagsDetail } from "./EquipmentTagsDetail";
import { EntityTablePage } from "../common/entity/EntityTablePage";
import { EquipmentTagsTable } from "./Table/EquipmentTagsTable";

// Add
export const EquipmentTagAddForm = () => {
  return (
    <EntityAddForm<"equipmentTags"> entityTypeId={"equipmentTags"}>
      {(props) => <EquipmentTagForm {...props} />}
    </EntityAddForm>
  );
};

export const EquipmentTagAddFormPage = () => {
  return (
    <EntityAddFormPage>
      <EquipmentTagAddForm />
    </EntityAddFormPage>
  );
};

// Clone
export const EquipmentTagCloneForm = ({ id }: EntityCloneFormProps<"equipmentTags">) => {
  return (
    <EntityCloneForm<"equipmentTags"> id={id} entityTypeId={"equipmentTags"}>
      {(props) => <EquipmentTagForm {...props} />}
    </EntityCloneForm>
  );
};

export const EquipmentTagCloneFormPage = () => {
  return (
    <EntityCloneFormPage<"equipmentTags"> isIntId>{(id) => <EquipmentTagCloneForm id={id} />}</EntityCloneFormPage>
  );
};

// Edit
export const EquipmentTagEditForm = ({ id }: EntityEditFormProps<"equipmentTags">) => {
  return (
    <EntityEditForm id={id} entityTypeId={"equipmentTags"} filters={{}}>
      {(props) => <EquipmentTagForm {...props} />}
    </EntityEditForm>
  );
};

export const EquipmentTagEditFormPage = () => {
  return <EntityEditFormPage<"equipmentTags"> isIntId>{(id) => <EquipmentTagEditForm id={id} />}</EntityEditFormPage>;
};

// Detail
export const EquipmentTagsDetailView = ({ id }: EntityDetailViewProps<"equipmentTags">) => {
  return (
    <EntityDetailView id={id} entityTypeId={"equipmentTags"} filters={{}}>
      {(props) => <EquipmentTagsDetail {...props} />}
    </EntityDetailView>
  );
};

export const EquipmentTagDetailsPage = () => {
  return <EntityDetailPage<"equipmentTags"> isIntId>{(id) => <EquipmentTagsDetailView id={id} />}</EntityDetailPage>;
};

export const EquipmentTagsTablePage = () => {
  return (
    <EntityTablePage entityTypeId={"equipmentTags"}>{(props) => <EquipmentTagsTable {...props} />}</EntityTablePage>
  );
};
