import { generateUid } from "../../tools/UID/UID";
import { CachedPipelinConnector } from "../CachedPipelinConnector";
import { CopyPipelinConnector } from "../CopyPipelinConnector";
import { ParameterChecker } from "../ParameterChecker";
import { PipelineConnector } from "../PipelineConnector";
import { PipelineNode } from "../PipelineNode";
import { CommandParameter, commandTypes, parameterTypes, PipelineCommand, range } from "../PipelineTypes";
import { StackTraces } from "../StackTrace";
import { ComplexFFT } from "./ComplexFFT";
import { Empty } from "./Empty";
import { NMRAxis } from "./NMRAxis";
import { Nullify } from "./Nullify";
import { PhaseNMRSpectrum } from "./PhaseNMRSpectrum";
import { AutophaseNMRSpectrum } from "./AutophaseNMRSpectrum";
import { Test } from "./Test";
import { TrackAdder } from "./TrackAdder";
import { TrackRenamer } from "./TrackRenamer";
import { TrackSelector } from "./TrackSelector";
import { WindowFunction } from "./WindowFunction";
import { ZeroFill } from "./ZeroFillFID";

type commandResult = {
  command?: PipelineCommand;
  errors: StackTraces;
  warnings: StackTraces;
  parameter?: Record<string, CommandParameter>;
};

type connectorResult = {
  connector?: PipelineConnector;
  errors: StackTraces;
  warnings: StackTraces;
};

type nodeResult = { node?: PipelineNode; errors: StackTraces; warnings: StackTraces };

export type parameterType = Record<string, parameterTypes>;

export class PipelineComponentGenerator {
  static CreateCommand(command: string, parameters: Record<string, any> = {}): commandResult {
    const result: commandResult = { errors: new StackTraces(), warnings: new StackTraces() };

    let cmd: any = undefined;
    switch (command) {
      case commandTypes.trackRenamer:
        cmd = new TrackRenamer();
        break;
      case commandTypes.nullify:
        cmd = new Nullify();
        break;
      case commandTypes.trackSelector:
        cmd = new TrackSelector();
        break;
      case commandTypes.trackAdder:
        cmd = new TrackAdder();
        break;
      case commandTypes.test:
        cmd = new Test();
        break;
      case commandTypes.zeroFill:
        cmd = new ZeroFill();
        break;
      case commandTypes.windowFunction:
        cmd = new WindowFunction();
        break;
      case commandTypes.complexFFT:
        cmd = new ComplexFFT();
        break;
      case commandTypes.nmrAxis:
        cmd = new NMRAxis();
        break;
      case commandTypes.phaseNMRSpectrum:
        cmd = new PhaseNMRSpectrum();
        break;
      case commandTypes.autophaseNMRSpectrum:
        cmd = new AutophaseNMRSpectrum();
        break;
      case commandTypes.empty:
        cmd = new Empty();
        break;
      default:
        cmd = new Empty();
        result.errors.create({
          id: "",
          component: "CreateCommand",
          message: `Unknown command '${command}'.`,
        });
    }

    if (cmd) {
      result.warnings.addTracesWithMessage(cmd.warnings, {
        component: "PipelineParser.createCommand",
        id: "",
        message: `Warning on command '${cmd.name}' creation.`,
      });
      result.errors.addTracesWithMessage(cmd.errors, {
        component: "PipelineParser.createCommand",
        id: "",
        message: `Command '${cmd.name}' creation failed.`,
      });

      if (cmd.errors.length > 0) {
        result.command = undefined;
        return result;
      }

      const checker = new ParameterChecker(cmd.parameterTypes);
      // checker.setParameter(parameters);

      checker.createParameter(cmd.parameterSettings);

      // if (command === commandTypes.trackSelector) console.log("parameter", parameters);
      checker.updateParameter(parameters);
      // console.log("parameter", checker.parameter["tracks"]);
      // checker.parameter["tracks"].deserialize(p);
      // console.log("  =>", checker.parameter["tracks"]);
      // result.parameter = checker.parameter;

      // console.log("checker.errors", checker.errors.toString());

      result.warnings.addTracesWithMessage(checker.warnings, {
        component: "PipelineParser.createCommand",
        id: "",
        message: `Warning on command '${cmd.name}' creation.`,
      });

      result.errors.addTracesWithMessage(checker.errors, {
        component: "PipelineParser.createCommand",
        id: "",
        message: `Command '${cmd.name}' creation failed.`,
      });

      cmd.internalParameter = checker.parameter;

      result.command = cmd;
    }

    return result;
  }

  static CreateNode(
    name: string | undefined,
    command: commandTypes,
    parameter: parameterType | undefined,
    index: number
  ): nodeResult {
    const result: nodeResult = { errors: new StackTraces(), warnings: new StackTraces() };

    // if (!id) id = generateUid();
    const id = generateUid();

    if (typeof name !== "string") {
      if (name !== undefined)
        result.warnings.create({
          id: id ?? "",
          component: "PipelineParser.createNode",
          message: `Node name in node ${index} must be of type string. (Got type '${typeof name}')`,
        });
      name = undefined;
    }
    if (name === undefined) name = "Step " + (index + 1);
    if (typeof parameter !== "object") parameter = {};

    if (command === undefined) {
      command = commandTypes.empty;
      result.warnings.create({
        id: id ?? "",
        component: "PipelineParser.createNode",
        message: `Node ${index}:${name} does not specify a command. (Empty command set)`,
      });
    }

    const b = PipelineComponentGenerator.CreateCommand(command, parameter);

    // const b = this.createCommand(command, parameter);

    result.warnings.addTracesWithMessage(b.warnings, {
      component: "PipelineParser.createNode",
      id: id ?? "",
      message: `Warning on node '${name}' command creation.`,
    });
    result.errors.addTracesWithMessage(b.errors, {
      component: "PipelineParser.createNode",
      id: id ?? "",
      message: `Creating command on node '${name}' failed.`,
    });

    result.node = new PipelineNode({
      command: b.command,
      name: name,
      index: index,
      id: id,
      // parameter: b.parameter,
    } as PipelineNode);

    // result.node = initPipelineNode({
    //   command: b.command,
    //   name: name,
    //   index: index,
    //   id: id,
    //   parameter: b.parameter,
    // } as PipelineNode);
    return result;
  }

  static CreateConnector(
    id: string | undefined,
    name: string | undefined,
    previous: PipelineNode | undefined,
    next: PipelineNode | undefined,
    mapping?: [number, number][],
    copy?: boolean,
    cached?: boolean
  ): connectorResult {
    const result: connectorResult = { errors: new StackTraces(), warnings: new StackTraces() };

    if (typeof name !== "string") {
      if (name !== undefined)
        result.warnings.create({
          id: id ?? "",
          component: "PipelineParser.createConnector",
          message: `Connection of node '${
            previous?.name ?? "undefined"
          }' must be of type string. (Got type '${typeof name}')`,
        });
      name = undefined;
    }
    if (name === undefined) name = "Connector " + previous?.id;

    let p: string[] = previous?.command?.output.map((p) => p.type) ?? [];
    let n: string[] = next?.command?.input.map((p) => p.type) ?? [];

    let outputNumber = n.length;
    let inputNumber = p.length;

    if (inputNumber < 1 && outputNumber > 0) {
      inputNumber = outputNumber;
      p = range(inputNumber).map(() => "Generic");
    }

    if (inputNumber > 0 && outputNumber < 1) {
      outputNumber = inputNumber;
      n = range(outputNumber).map(() => "Generic");
    }

    const previousName = previous?.name ?? "";
    const nextName = next?.name ?? "";

    if (outputNumber > inputNumber) {
      result.errors.create({
        id: id ?? "",
        component: "PipelineParser.createConnector",
        message: `Node '${nextName}' did not receive enough input tracks from previous node '${previousName}'. (Expected ${outputNumber} got ${inputNumber})`,
      });
    } else {
      for (let i = 0; i < outputNumber; i++) {
        if (p[i] !== n[i] && p[i] !== "Generic" && n[i] !== "Generic") {
          result.errors.create({
            id: id ?? "",
            component: "PipelineParser.createConnector",
            message: `Cannot connect node '${previousName}' output ${i} to input of node '${nextName}'. (Expected type '${n[i]}' got '${p[i]}')`,
          });
          continue;
        }
      }
    }

    const map: Record<number, number> = {};
    if (mapping) {
      if (!Array.isArray(mapping)) {
        result.errors.create({
          id: id ?? "",
          component: "PipelineParser.createConnector",
          message: `Mapping in '${name}' must be of type array. (Got type '${typeof mapping}')`,
        });
        mapping = [];
      }

      for (let i = 0; i < mapping.length; i++) {
        const m: [number, number] = mapping[i];

        if (!Array.isArray(m)) {
          result.errors.create({
            id: id ?? "",
            component: "PipelineParser.createConnector",
            message: `Mapping entry ${i} of '${name}' must be a tuple. (Got type '${typeof m}')`,
          });
          continue;
        }
        if (typeof m[0] !== "number" || typeof m[1] !== "number") {
          result.errors.create({
            id: id ?? "",
            component: "PipelineParser.createConnector",
            message: `Mapping entry ${i} of '${name}' must be a tuple of numbers. (Got [${typeof m[0]}, ${typeof m[1]}])`,
          });
          continue;
        }

        if (m[1] in map) {
          result.errors.create({
            id: id ?? "",
            component: "PipelineParser.createConnector",
            message: `Output connection ${m[1]} already mapped to input ${
              map[m[1]]
            }. Remapped in mapping entry ${i} of '${name}'.`,
          });
          continue;
        }
        map[m[1]] = m[0];
      }
    }
    for (let i = 0; i < outputNumber; i++) if (!(i in map)) map[i] = i;
    // console.log("previous node", p);
    // console.log("    next node", n);
    if (result.errors.length < 1) {
      const init = {
        id: id ?? "",
        name: name,
        previous: previous?.id,
        next: next?.id,
        inputNumber: inputNumber,
        outputNumber: outputNumber,
        mapping: map,
      } as PipelineConnector;

      result.connector = cached
        ? new CachedPipelinConnector(init)
        : copy
        ? new CopyPipelinConnector(init)
        : new PipelineConnector(init);
      // result.connector = new DefaultPipelinConnector(init);
    }

    return result;
  }
}
