import { CSSProperties, useEffect, useState } from "react";
import styles from "./styles.module.css";
interface Props {
  wait?: number;
  size?: "xs" | "sm" | "md" | "lg" | "xl";
  inline?: boolean;
  style?: CSSProperties;
  [propName: string]: any;
}
export const Spinner = ({ wait = 200, inline = false, size = "xl", style, ...rest }: Props) => {
  const [show, setShow] = useState(false);
  useEffect(() => {
    const timer = setTimeout(() => {
      setShow(true);
    }, wait);
    return () => clearTimeout(timer);
  }, [wait]);
  if (inline) return <span className={`spinner spinner-${size}`} style={{ ...style }} />;
  return (
    <>
      {show && (
        <div className={styles.container}>
          {/* <MoonLoader speedMultiplier={0.5} color={"var(--primary)"} {...rest} /> */}
          <div className={`spinner spinner-${size}`} style={{ ...style }} />
        </div>
      )}
    </>
  );
};
