import React, { useCallback, useEffect, useMemo, useState } from "react";
import { SessionContext } from "../common/contexts/SessionContext";
import styles from "./SharedBundles.module.css";
import { useParams } from "react-router-dom";
import { API } from "../api/Api";
import { ToastContainer } from "react-toastify";
import { SharedContentPublicModel } from "../api/SharedContent";
import { LandingPage } from "../main/LandingPage/LandingPage";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSessionStorage } from "../common/helperfunctions/useSessionStorage";
import { PasswordFormField } from "../common/formfields/PasswordFormField";
import { MigrationModeWrapper } from "../main/Main";
import { Session } from "../api/ApiTypes";
import { useQuery } from "@tanstack/react-query";
import { getLoadingState, LoadingWrapper } from "../common/LoadingWrapper";

interface Props {
  children: JSX.Element;
}

export const SharedBundlesUIWrapper = ({ children }: Props) => {
  return (
    <div className={styles.container}>
      <div className={styles.container_head}>
        <a href="http://logs.sciy.com/" target="_blank" rel="noreferrer">
          <div style={{ display: "flex", alignItems: "center" }}>
            <img
              src={`${process.env.PUBLIC_URL}/assets/logos/Logs-Main.svg`}
              height="40px"
              width="144px"
              alt="LOGS"
              style={{ textDecoration: "none" }}
            />

            <span>Make your research data findable</span>
          </div>
        </a>
      </div>
      <ToastContainer />
      {children}
    </div>
  );
};

export const SharedBundlesContextWrapper = ({ children }: Props) => {
  const [password, setPassword] = useState<string>();
  const [publicInfo, setPublicInfo] = useState<SharedContentPublicModel>();
  const [api, setAPI] = useState<API>();

  const { value: pwd, setValue: setPwd } = useSessionStorage<{ [shareId: string]: string }>("LOGS_shared_content", {});

  let { group, sessionId }: { group: string; sessionId?: string | undefined } = useParams();
  // console.log("X-Shared-Key: ", sessionId, "Group:", group);
  const [session, setSession] = useState<string>();

  useEffect(() => {
    if (sessionId) {
      setSession(sessionId);
    }
  }, [sessionId]);

  useEffect(() => {
    if (pwd && session && pwd.hasOwnProperty(session)) {
      setPassword(pwd[session]);
    }
  }, [pwd, session]);

  const _api = useMemo(
    () => new API(group, { type: "shared", value: session!, password: undefined }),
    [group, session]
  );
  const route = useCallback((url: string) => `/${group}${url}`, [group]);
  const clearSession = useCallback(() => setSession(undefined), []);

  const init = useCallback(
    async (signal?: AbortSignal) => {
      const res: SharedContentPublicModel = await _api.post(`shared_content/public/${session}`, {}, undefined, signal);
      if (res) setPublicInfo(res);
      if (pwd && session && !pwd.hasOwnProperty(session)) {
        return pwd[session] ?? "";
      }
      return "";
    },
    [_api, pwd, session]
  );

  const checkPassword = useCallback(
    async (signal?: AbortSignal) => {
      if (!session) return Promise.resolve({});
      if (!password) return Promise.resolve({});
      const res: SharedContentPublicModel = await _api.post(
        `shared_content/public/${session}`,
        {
          password: password,
        },
        undefined,
        signal
      );
      if (res) setPublicInfo(res);
      if (res.suppliedPasswordCorrect && res.requiresPassword) {
        return { [session]: password };
      }
      setPassword(undefined);
      throw new Error("Incorrect password");
    },
    [_api, session, password]
  );

  const pwdQuery = useQuery({
    queryKey: ["sharedSession", session],
    queryFn: async ({ signal }) => await init(signal),
    enabled: !!pwd && !!session,
  });
  const { data: pwdData, error: pwdDataError } = pwdQuery;

  const pwdCheckQuery = useQuery({
    queryKey: ["sharedSessionPwdChk", session],
    queryFn: async ({ signal }) => await checkPassword(signal),
    enabled: !!session && !!password,
  });
  const { data: pwdCheckData, error: pwdCheckError } = pwdCheckQuery;

  const { status, fetchStatus } = getLoadingState([pwdQuery, pwdCheckQuery]);

  // We fetch the public info
  useEffect(() => {
    if (pwdData) {
      setPassword(pwdData);
    }
  }, [pwdData]);

  useEffect(() => {
    if (pwdCheckData && session) {
      setPwd(pwdCheckData);
      // We modify the API with the set password
      setAPI(new API(group, { type: "shared", value: session, password: password }));
    }
  }, [group, password, pwdCheckData, session, setPwd]);

  useEffect(() => {
    if (publicInfo) {
      if (!publicInfo.requiresPassword) {
        // No need to modify the constructed API
        setAPI(_api);
      }
    }
  }, [_api, publicInfo]);

  // useEffect(() => {
  //   if (password && sessionId) {
  //     // We modify the API with the set password
  //     (async () => await checkPassword(sessionId, password))();
  //     setAPI(new API(group, { type: "shared", value: sessionId!, password: password }));
  //   }
  // }, [checkPassword, group, password, sessionId]);

  const validationSchema = yup.object().shape({
    password: yup.string().required("Password is required"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<{ password: string }>({ defaultValues: undefined, resolver: yupResolver(validationSchema) });

  const handleLogin = useCallback(({ password }: { password: string }) => {
    setPassword(password);
  }, []);

  console.log("Public Info: ", publicInfo);

  return (
    <React.Fragment>
      {publicInfo && (
        <LoadingWrapper status={status} fetchStatus={fetchStatus} error={pwdDataError ?? pwdCheckError}>
          {!password && publicInfo.requiresPassword ? (
            <LandingPage>
              <form autoComplete="off" onSubmit={handleSubmit(handleLogin)}>
                <PasswordFormField
                  id={"password"}
                  label={"Password"}
                  errors={errors}
                  register={register}
                  horizontal={false}
                  required
                />

                <div className={styles.btn_login}>
                  <button
                    className="btn btn-primary"
                    type="submit"
                    disabled={isSubmitting}
                    style={{ marginLeft: "auto" }}
                  >
                    Login
                  </button>
                </div>
              </form>
            </LandingPage>
          ) : (
            api && (
              <SessionContext.Provider
                value={{
                  api,
                  group,
                  clearSession,
                  route,
                  session: {
                    sessionId: sessionId!,
                    features: {},
                    permissions: {
                      view_unclaimed_datasets: publicInfo.allowContentDownload,
                    },
                    person: {},
                    userId: -1,
                  } as Session,
                }}
              >
                <MigrationModeWrapper>{children}</MigrationModeWrapper>
              </SessionContext.Provider>
            )
          )}
        </LoadingWrapper>
      )}
    </React.Fragment>
  );
};

export const SharedBundlesConductanceIntegrationWrapper = (props: {
  group: string;
  api: API;
  children: JSX.Element;
}) => {
  // const route = useMemo(() => (url: string) => `/${props.group}${url}`, [props.group]);
  // const clearSession = () => null;
  return (
    // <SessionContext.Provider value={{ api: props.api, group: props.group, clearSession, route }}>
    //   <SharedBundlesContextWrapper>{props.children}</SharedBundlesContextWrapper>
    // </SessionContext.Provider>
    <SharedBundlesContextWrapper>{props.children}</SharedBundlesContextWrapper>
  );
};
