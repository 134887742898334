import React, { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { Dataset as DatasetType, QueueJob } from "../api/Datasets";
import { useGet } from "../api/BaseEntityApi";
import { SessionContext } from "../common/contexts/SessionContext";
import { DatasetDetail } from "./DatasetDetail";
import { Navigation } from "./Navigation/Navigation";
// import styles from "./Dataset.module.css";
import { useQueryClient } from "@tanstack/react-query";
import { useReactToPrint } from "react-to-print";
import FullDetail from "../common/panels/FullDetail/FullDetail";
import { EntityDetailProps } from "../common/entity/EntityInterfaces";
import { DatasetDetailPageProps } from "./DatasetViews";

export interface FullScreenOpts {
  state: boolean;
  component: "files" | "parameters" | "";
}

// The context shared among all child components for the Detail page
export interface Store {
  // A global dataset setter & getter
  dataset: {
    get: DatasetType | undefined;
  };
  // A global full screen state
  fullscreen: {
    get: FullScreenOpts;
    set: React.Dispatch<React.SetStateAction<FullScreenOpts>>;
  };
  // A state wether to hide certain features for shared bundle view
  viewShared: boolean;
}
export const DatasetContext = createContext<Store | null>(null);

export const DatasetsDetail = ({
  entity,
  entityApi,
  entityConstants,
  fieldLabels,
  permissions,
  routes,
  viewShared,
  viewViewer,
}: EntityDetailProps<"datasets"> & DatasetDetailPageProps) => {
  // const history = useHistory();
  const queryClient = useQueryClient();
  const { api } = useContext(SessionContext);

  const [queueId, setQueueId] = useState<number>();
  const [fullscreen, setFullscreen] = useState<FullScreenOpts>({
    state: false,
    component: "",
  });
  const componentRef = useRef(null);
  const { data: queue } = useGet<QueueJob>(`${entityConstants.resource}/${queueId}/high_priority`, null, {
    enabled: !!queueId,
  });

  const refetchDataset = useCallback(() => {
    if (queueId) {
      queryClient.invalidateQueries([entityConstants.resource, "detail", entity.id]); // Invalidation does not for useQueries with options {enabled : ...}
    }
  }, [entity.id, entityConstants.resource, queryClient, queueId]);

  // Hook to set data or refetch if it hasn't been parsed yet
  const timer = useRef<ReturnType<typeof setInterval>>();

  useEffect(() => {
    if (queueId) {
      const intervalId = setInterval(refetchDataset, 3000);
      if (!timer.current) timer.current = intervalId;
    }
    return () => {
      clearInterval(timer.current as NodeJS.Timeout);
      timer.current = undefined;
    };
  }, [queueId, refetchDataset]);

  useEffect(() => {
    if (entity.parsingState === "NotYetParsed") {
      console.log("Dataset is not yet parsed, setting priority queue");
      if (!queue) setQueueId(entity.id);
    } else {
      if (timer.current) clearInterval(timer.current as NodeJS.Timeout);
    }
  }, [entity, queue]);

  const context = useMemo(
    (): Store => ({
      dataset: { get: entity },
      fullscreen: { get: fullscreen, set: setFullscreen },
      viewShared: !!viewShared,
    }),
    [entity, fullscreen, viewShared]
  );

  const orientationCallback = useCallback(() => {
    // Some custom CSS injection handlers for PrintView
    if (!viewViewer) {
      require("./PrintPortrait.module.css");
    } else {
      require("./PrintLandscape.module.css");
    }
  }, [viewViewer]);

  const reactToPrintContent = useCallback(() => {
    return componentRef.current;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [componentRef.current]);

  const printCallback = useReactToPrint({
    content: reactToPrintContent,
    // pageStyle: styles.toString(),
    documentTitle: entity?.name,
    onBeforeGetContent: orientationCallback,
  });

  return (
    <DatasetContext.Provider value={context}>
      {viewShared ? (
        <DatasetDetail api={api} viewViewer={!!viewViewer} ref={componentRef} />
      ) : (
        <FullDetail>
          <Navigation
            entity={entity}
            entityConstants={entityConstants}
            permissions={permissions}
            routes={routes}
            entityApi={entityApi}
            fieldLabels={fieldLabels}
            api={api}
            printCallback={printCallback}
            viewViewer={!!viewViewer}
          />
          <DatasetDetail api={api} viewViewer={!!viewViewer} ref={componentRef} />
        </FullDetail>
      )}
    </DatasetContext.Provider>
  );
};
