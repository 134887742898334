import { useRef, useState } from "react";
import { Tile } from "../common/panels/Tile/Tile";
import { ContentTable } from "../common/tables/ContentTable/ContentTable";
import { DateTimeRenderer } from "../common/datetime/DateTimeFormatter";
import { LucideIcon } from "../common/icon/LucideIcon";
import { ToggleSwitch } from "../common/buttons/ToggleSwitch/ToggleSwitch";
import { AlertModal } from "../common/modals/AlertModal/AlertModal";
import { ApiKey } from "../api/ApiKey";
import { CopyToClipboardButton } from "../common/buttons/CopyToClipboardButton/CopyToClipboardButton";

interface ApiKeyWidgetProps {
  apiKey: ApiKey;
  deleteApiKey: (id: number) => Promise<void>;
  isNew: boolean;
}

export const ApiKeyWidget = ({ apiKey, deleteApiKey, isNew }: ApiKeyWidgetProps) => {
  const [showAlertModal, setShowAlertModal] = useState(false);
  const apikeyRef = useRef<HTMLPreElement>(null!);

  return (
    <Tile foldable isFolded={!isNew}>
      <Tile.Head title={apiKey.name}>
        <Tile.Head.Controls>
          <Tile.Head.Controls.Fixed>
            <AlertModal
              type="danger"
              title="Delete API-Key"
              description="This action cannot be undone. Access via this API-Key will no longer be possible."
              showModal={showAlertModal}
              setShowModal={setShowAlertModal}
              onProceed={async () => await deleteApiKey(apiKey.id)}
            />
            <button
              className="btn btn-sm btn-ghost-danger"
              title="Delete API-Key"
              onClick={() => setShowAlertModal(true)}
            >
              <LucideIcon name="trash-2" />
            </button>
          </Tile.Head.Controls.Fixed>
        </Tile.Head.Controls>
      </Tile.Head>
      <Tile.Body>
        <ContentTable
          content={[
            {
              key: "API-Key",
              value: (
                <div className={"flex row-nowrap align-center gap-5"} style={{ width: "100%", overflow: "hidden" }}>
                  <pre ref={apikeyRef} style={{ flex: "1 1 auto", margin: 0, overflow: "auto" }}>
                    {apiKey.apiKey}
                  </pre>
                  <CopyToClipboardButton content={apiKey.apiKey} />
                </div>
              ),
            },
            {
              key: "Created On",
              value: <DateTimeRenderer date={apiKey.createdOn} />,
            },
            {
              key: "Read-Only",
              value: <ToggleSwitch checked={!!apiKey.readOnly} onToggle={() => {}} disabled />,
            },
          ]}
        />
      </Tile.Body>
    </Tile>
  );
};
