import { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { DetailsPageTopControlsView } from "../common/panels/misc/DetailsPageTopControlsView/DetailsPageTopControlsView";
import { SessionContext } from "../common/contexts/SessionContext";
import { LucideIcon } from "../common/icon/LucideIcon";
import { NotSet } from "../common/misc/UIconstants";
import { AlertModal } from "../common/modals/AlertModal/AlertModal";
import { showtoast } from "../common/overlays/Toasts/showtoast";
import Detail from "../common/panels/Detail/Detail";
import { Table } from "../common/panels/Detail/DetailTable";
import { getCloneLink } from "../main/Routing";
import { MoreDropdown } from "../common/buttons/MoreDropdown/MoreDropdown";
import { PersonsTile } from "../common/sidebar/tiles/PersonsTile";
import { PermissionsTable } from "../Permissions/PermissionsTable";
import { EntityDetailProps, EntityDetailTableProps } from "../common/entity/EntityInterfaces";

export const RolesDetailTable = ({ entity, entityConstants, fieldLabels }: EntityDetailTableProps<"roles">) => {
  const { session } = useContext(SessionContext);
  return (
    <>
      <Table noPadding nonFoldable>
        <Table.Head>Basic details</Table.Head>
        <Table.Body>
          <Table.Body.RowContent title={fieldLabels.name} content={entity.name} />

          <Table.Body.RowContent
            title={fieldLabels.description}
            content={
              <>
                {entity.description ? (
                  <div className="container_notes">
                    <textarea rows={4} className="container_textarea" value={entity?.description} disabled />
                  </div>
                ) : (
                  NotSet
                )}
              </>
            }
          />
        </Table.Body>
      </Table>

      {session?.permissions.logsadmin && (
        <Table noPadding>
          <Table.Head>{fieldLabels.rolePermissions}</Table.Head>
          <Table.Body>
            <PermissionsTable permissionsOverride={entity.rolePermissions} />
          </Table.Body>
        </Table>
      )}
    </>
  );
};

export const RolesDetail = ({
  permissions,
  routes,
  entity,
  entityApi,
  entityConstants,
  fieldLabels,
}: EntityDetailProps<"roles">) => {
  let history = useHistory();
  const { route, session } = useContext(SessionContext);
  const [showModal, setShowModal] = useState<boolean>(false);

  return (
    <Detail
      head={
        <DetailsPageTopControlsView
          entity={entity}
          entityConstants={entityConstants}
          controls={
            session?.permissions.logsadmin ? (
              <>
                <button
                  className="btn btn-default"
                  onClick={() => history.push(routes.getEditLink(entity.id))}
                  disabled={!entity.permissions?.edit || entity.isDefault || entity.isInternal}
                >
                  <LucideIcon name="square-pen" />
                  <span> Edit</span>
                </button>
                <MoreDropdown drop="right" btn="btn btn-ghost-secondary">
                  <li>
                    <button
                      className="btn btn-sm btn-ghost-secondary"
                      title={`Clone this Role`}
                      onClick={() => history.push(route(getCloneLink("roles", entity.id)))}
                      disabled={!permissions.canCreate}
                    >
                      <LucideIcon name="copy" /> Clone
                    </button>
                  </li>
                  <li>
                    <button
                      className="btn btn-sm btn-ghost-danger"
                      title="Delete"
                      onClick={() => setShowModal(true)}
                      disabled={!permissions.canEdit(entity)}
                    >
                      <LucideIcon name="trash-2" /> Delete
                    </button>
                  </li>
                </MoreDropdown>
                <AlertModal
                  type="danger"
                  showModal={showModal}
                  setShowModal={setShowModal}
                  title="Permanently delete role?"
                  description="This role may be referenced by other entities. Deleting a role is only possible if all references are removed."
                  proceedLabel="Delete"
                  onProceed={async () => {
                    await entityApi
                      .deleteMutationAsync(
                        {
                          id: entity.id,
                          goBackOnSuccess: false,
                          showToast: false,
                          entityName: "role",
                          params: { deletePermanently: true },
                        },

                        {
                          onSuccess: () => {
                            showtoast("success", `Deleted Role-ID: ${entity.id}.`);
                            history.goBack();
                          },
                        }
                      )
                      .catch((e) => {});
                  }}
                  loading={entityApi.isLoadingDeleteMutation}
                />
              </>
            ) : undefined
          }
        />
      }
      mainStyle={{ padding: "0px 10px" }}
      main={<RolesDetailTable entity={entity} entityConstants={entityConstants} fieldLabels={fieldLabels} />}
      sidebar={
        <>
          {!!entity.relations?.persons.count && (
            <PersonsTile defaultFilters={{ roleIds: [entity.id] }} excludeFilters={{ roleIds: () => false }} />
          )}
          {!entity.relations?.persons.count && <PersonsTile disabled />}
        </>
      }
    />
  );
};
