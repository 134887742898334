import { useContext } from "react";
import { useHistory } from "react-router-dom";
import { API } from "../../../api/Api";
import { Dataset } from "../../../api/Datasets";
import { SessionContext } from "../../../common/contexts/SessionContext";
import { LucideIcon } from "../../../common/icon/LucideIcon";
import { Skeleton } from "../../../common/loaders/Skeleton/Skeleton";
import { Alert } from "../../../common/overlays/Alert/Alert";
import { Container } from "../../../common/panels/Container/Container";
import { getIndexRoute } from "../../../main/Routing";
import { Viewer as NewViewer } from "../../../ViewerLayout/ViewerLayout";
import { DatasetContext, FullScreenOpts } from "../../Dataset";
import styles from "./Viewer.module.css";
import { DownloadDataset } from "../../common/DownloadDataset/DownloadDataset";

interface Props {
  api: API;
  dataset: Dataset;
  fullScreenToggle?: (component: FullScreenOpts["component"]) => void;
}
export const Viewer = ({ api, dataset, fullScreenToggle }: Props) => {
  // const [showError, setShowError] = useState<boolean>(false);
  const { route, session } = useContext(SessionContext);
  const context = useContext(DatasetContext);
  const history = useHistory();

  return (
    <Container
      title={"Preview"}
      controls={
        <div
          style={{
            display: "flex",
            flexFlow: "row nowrap",
            width: "100%",
            height: "fit-content",
            alignItems: "center",
            gap: 5,
            whiteSpace: "nowrap",
          }}
        >
          {Array.isArray(dataset.parsedMetadata.trackViewerTypes) &&
            dataset.parsedMetadata.trackViewerTypes.length > 0 && (
              <>
                {dataset.parsedMetadata.trackViewerTypes.map((d, i) => (
                  <label className="label label-info" style={{ margin: 0 }} key={i}>
                    {d}
                  </label>
                ))}
                <div style={{ width: "2px", height: "100%", background: "var(--gray-200)" }}>&nbsp;</div>
              </>
            )}

          <span>Tracks:</span>
          <span className="badge" style={{ margin: 0 }}>
            {dataset.parsedMetadata?.trackCount}
          </span>
          {
            // Checking for view_unclaimed_datasets is a hack to check wether we can download this dataset and is set when authenticated via shared key
            context?.viewShared && session?.permissions.view_unclaimed_datasets && (
              <DownloadDataset api={api} id={dataset.id} />
            )
          }
        </div>
      }
    >
      <div className={styles.container}>
        <div className={styles.viewer_container}>
          <div className={styles.viewer}>
            <>
              {dataset.parsingState === "NotYetParsed" ? (
                <Skeleton type="viewer">
                  <div
                    className="flex col-nowrap align-center justify-center"
                    style={{ width: "100%", height: "100%" }}
                  >
                    <Alert
                      type="info"
                      fit
                      centered
                      message={`Dataset '${dataset.name}' (id: ${dataset.id}) is under maintenance. Please wait for the page to refresh automatically or visit this dataset at a later time.`}
                    />
                  </div>
                </Skeleton>
              ) : (
                <>
                  <NewViewer
                    ids={[+dataset.id]}
                    settings={{ showParameter: false, showNavigation: false, showTrackList: false }}
                  />
                  {dataset.parsingState === "ParsedSuccessfully" && (
                    <div
                      className={`${styles.overlay} ${styles.overlay_fullscreen}`}
                      onClick={() => {
                        // fullScreenToggle("viewer");
                        context?.viewShared
                          ? history.push(`${history.location.pathname}/viewer`)
                          : history.push(route(`${getIndexRoute("datasets")}/${dataset.id}/viewer`));
                      }}
                      title={"Open interactive viewer"}
                    >
                      <div className={`${styles.overlay_indicator} ${styles.overlay_indicator_fullscreen}`}>
                        <span>Open Viewer</span> <LucideIcon name="maximize" />
                      </div>
                    </div>
                  )}
                </>
              )}
            </>
          </div>
        </div>
      </div>
    </Container>
  );
};
