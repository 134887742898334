import * as d3 from "d3";
import produce from "immer";
import { useCallback, useEffect, useState } from "react";
import { Button, FormControl, FormGroup, InputGroup } from "react-bootstrap";
import { Range } from "react-range";

import { useTranslatedFieldUpdate } from "../../ViewerLayout/ViewerLayoutHooks";
import { Color, SingleColor } from "../../ViewerLayout/ViewerLayoutTypes";
import { ColorNeedsUpdate, initColor, initSingleColor } from "../../ViewerLayout/ViewerLayoutUtils";
import { ColorBox } from "../../ViewerUIElements/ColorBox";
import { ColorSelector } from "../../ViewerUIElements/ColorSelector";
import { useInputDebounce } from "../../ViewerUIElements/InputDebounce";
import { Resizeable } from "../../ViewerUIElements/Resizeable";
import styles from "./ColorGradientEditor.module.css";
import { ColorSliderThumb } from "./ColorSliderThumb";

// const getDigitOld = (range: number[]) => {
//   if (range?.[0] && range?.[1]) {
//     const diff = Math.abs(range[0] - range[1]);
//     const tic = Math.floor(Math.log(diff) / Math.log(10));

//     return tic < 0 ? -tic : tic < 3 ? 1 : 0;
//   }
//   return 0;
// };

const getDigit = (range: number[], width: number) => {
  if (range?.[0] && range?.[1]) {
    const diff = Math.abs(range[0] - range[1]);

    let digit = Math.round(-Math.log10(diff / width));
    if (digit < 0) digit = 0;
    return digit;
  }
  return 0;
};

// const getValueFromOffset = (offset: number, range?: number[]): number => {
//   if (range) {
//     const diff = range[1] - range[0];
//     return range[0] + diff * offset;
//   }
//   return NaN;
// };

const getOffsetFromValue = (offset: number, range?: number[]) => {
  if (range) {
    const diff = range[1] - range[0];
    // console.log("offset", offset, offset - range[0], (offset - range[0]) / diff);
    return (offset - range[0]) / diff;
  }
  return NaN;
};

type stateType = { state: "valid" | "error" | "warning"; value: number };
const validateValue = (value: string, range?: number[]): stateType => {
  let f = parseFloat(value);
  const state: stateType = { value: f, state: "valid" };
  if (isNaN(f)) state.state = "error";
  const minRange = Math.min(...(range || [0]));
  const maxRange = Math.max(...(range || [0]));

  // console.log("minmax", value, f, minRange, maxRange);
  if (f < minRange) {
    state.state = "warning";
    state.value = minRange;
  }
  if (f > maxRange) {
    state.state = "warning";
    state.value = maxRange;
  }
  return state;
};

const sliderThumb = ({ props }: { props: any }) => {
  // console.log("props", index, props.style.translate);

  return (
    <div {...props} className={styles.sliderThumb}>
      <div className={styles.sliderThumbLine} />
    </div>
  );
};

const getValueStringFromOffset = (offset: number, width: number, range?: number[]): string => {
  if (!range) return "NaN";
  const diff = range[1] - range[0];
  // let digit = Math.round(-Math.log10(diff / width));
  // if (digit < 0) digit = 0;
  const digit = getDigit(range, width);
  return (range[0] + diff * offset).toFixed(digit);
};

const stateColor = {
  valid: undefined,
  error: "LightCoral",
  warning: "LightCoral",
};

const ToolBox = ({
  color,
  setColor,
  selectedOffset,
  setSelectedOffset,
  onClose,
  width,
}: {
  width: number;
  color: Color;
  setColor: (color: Color) => void;
  onClose: () => void;
  selectedOffset: number;
  setSelectedOffset: (index: number) => void;
}) => {
  const [fieldColor, setFieldColor] = useState<string>();
  const [offset, setOffset] = useState<number>();
  const [formValue, setFormValue] = useState<string>("");
  const [blockForm, setBlockForm] = useState<boolean>(false);

  const debouncedOffset = useInputDebounce<number | undefined>(offset, 300);

  useEffect(() => {
    if (
      debouncedOffset &&
      color.colors?.[selectedOffset]?.offset &&
      Math.abs(debouncedOffset - (color.colors[selectedOffset].offset as number)) > 0.00001
    ) {
      const colors = produce(color.colors, (next) => {
        next[selectedOffset].offset = debouncedOffset;
      });
      setColor(initColor({ ...color, colors: colors }, true));
    }
  }, [debouncedOffset]);

  useEffect(() => {
    if (selectedOffset >= 0 && selectedOffset < color.colors.length) {
      if (!blockForm)
        changeFormValue({
          target: {
            value: getValueStringFromOffset(color.colors[selectedOffset].offset as number, width, color.range),
          },
        });
      setOffset(color.colors[selectedOffset].offset as number);
    } else {
      setOffset(NaN);
      onClose();
    }
  }, [color, width, selectedOffset]);

  const changeFormValue = useCallback(
    (e: any) => {
      const v = (e.target as HTMLInputElement).value;
      setFormValue(v);
      const valid = validateValue(v, color.range);
      if (valid.state === "valid") setOffset(getOffsetFromValue(valid.value, color.range));
      setFieldColor(stateColor[valid.state]);
    },
    [color]
  );

  const changeColor = useCallback(
    (singleColor: SingleColor) => {
      if (selectedOffset < 0 || selectedOffset > color.colors.length - 1 || color.colors.length < 1) return;
      const colors = produce(color.colors, (next) => {
        next[selectedOffset].color = singleColor.color;
      });
      setColor(initColor({ ...color, colors: colors }, true));
    },
    [color, selectedOffset]
  );

  const removeSlider = useCallback((): void => {
    if (selectedOffset < 0 || selectedOffset > color.colors.length - 1 || color.colors.length < 2) return;

    let colors: SingleColor[];
    if (color.colors.length < 3) {
      const c = selectedOffset > 0 ? color.colors[0] : color.colors[1];
      colors = [initSingleColor({ ...c, offset: 0 }), initSingleColor({ ...c, offset: 1 })];
    } else {
      colors = color.colors.slice();
      colors.splice(selectedOffset, 1);
    }
    setColor(initColor({ ...color, colors: colors }, true));

    onClose();
    setSelectedOffset(-1);
  }, [selectedOffset]);

  const addSlider = useCallback(
    (position: "left" | "right"): void => {
      if (selectedOffset < 0 || selectedOffset > color.colors.length - 1) return;
      let colorRange: string[];
      let offsetRange: number[];
      let i = 0;
      const treshold = 0.01;
      if (position === "left") {
        colorRange = [
          selectedOffset === 0 ? color.colors[selectedOffset].color : color.colors[selectedOffset - 1].color,
          color.colors[selectedOffset].color,
        ];
        offsetRange = [
          selectedOffset === 0 ? 0 : (color.colors[selectedOffset - 1].offset as number),
          color.colors[selectedOffset].offset as number,
        ];
        i = selectedOffset;
      } else if (position === "right") {
        colorRange = [
          color.colors[selectedOffset].color,
          selectedOffset === color.colors.length - 1
            ? color.colors[selectedOffset].color
            : color.colors[selectedOffset + 1].color,
        ];
        offsetRange = [
          color.colors[selectedOffset].offset as number,
          selectedOffset === color.colors.length - 1 ? 1 : (color.colors[selectedOffset + 1].offset as number),
        ];
        i = selectedOffset + 1;
      } else return;
      let col: string;
      let offset: number;
      const diff = offsetRange[1] - offsetRange[0];
      if (Math.abs(diff) > treshold) {
        offset = offsetRange[0] + diff / 2;
        const g = d3
          .scaleLinear()
          .domain(offsetRange)
          .range(colorRange as any)
          .interpolate(d3.interpolateRgb as any) as any as (offset: number) => string;
        col = g(offset);
        if (col === undefined) return;
      } else return;

      const colors = color.colors.slice();
      colors.splice(i, 0, initSingleColor({ color: col, offset: offset }));
      setColor(initColor({ ...color, colors: colors }, true));

      if (selectedOffset < i) setSelectedOffset(i);
    },
    [color, selectedOffset]
  );

  return (
    <div className={styles.toolBox}>
      <div style={{ paddingRight: 10 }}>
        <span
          className={"glyphicon glyphicon-remove"}
          onClick={() => {
            onClose();
            setSelectedOffset(-1);
          }}
        />
        <FormGroup>
          <InputGroup bsSize={"sm"} className={styles.colorHandleInput}>
            <InputGroup.Addon>
              <b>Value</b>
            </InputGroup.Addon>
            <FormControl
              type="text"
              style={{ background: fieldColor }}
              value={formValue}
              onChange={changeFormValue}
              onFocus={(e) => {
                (e.target as any as HTMLInputElement).select();
                setBlockForm(true);
              }}
              onBlur={(e) => {
                setBlockForm(false);
              }}
            />
          </InputGroup>
        </FormGroup>

        <FormGroup className={styles.colorHandleButton}>
          <Button bsClass="secondary" className="btn-xs" onClick={() => addSlider("left")} style={{ flexGrow: 1 }}>
            <span className="glyphicon glyphicon-chevron-left" />
            Add left
          </Button>
          <Button bsClass="secondary" className="btn-xs" onClick={() => addSlider("right")} style={{ flexGrow: 1 }}>
            Add right
            <span className="glyphicon glyphicon-chevron-right" />
          </Button>
        </FormGroup>
        <FormGroup>
          <Button bsClass="secondary" className="btn-xs" onClick={removeSlider} style={{ flexGrow: 1 }}>
            <span className={"glyphicon glyphicon-trash"} style={{ cursor: "pointer" }} /> Remove color
          </Button>
        </FormGroup>
      </div>
      <ColorSelector color={color.colors[selectedOffset]} setSingleColor={changeColor} />
    </div>
  );
};

const SvgLine = ({ offsets, index, width }: { offsets: number[]; index: number; width: number }) => {
  const v = offsets?.[index];
  if (v === undefined) return null;
  const x0 = v * width || 0;
  return (
    <svg
      style={{
        width: width > 0 ? width : 0,
        height: 35,
        strokeWidth: 3,
        stroke: "black",
        fill: "none",
        // background: "pink",
      }}
    >
      <polyline points={`${x0},2 ${x0},25 20,25, 20,35`} />
    </svg>
  );
};

const SliderDiv = (props: any) => {
  const {
    width,
    color,
    setColor,
    onSettingsOpen,
    onSettingsClosed,
  }: {
    width: number;
    height: number;
    color: Color;
    setColor: (color: Color) => void;
    onSettingsOpen?: () => void;
    onSettingsClosed?: () => void;
  } = props;

  const [minMax, setMinMax] = useState<string[]>(["", ""]);
  // const [values, setValues] = useState<number[]>([]);
  const [selectedOffset, setSelectedOffset] = useState<number>(-1);
  const [gradientColor, setGradientColor] = useState<Color>(initColor(color) ?? ["gray", "gray"]);

  const [toolBoxState, setToolBoxState] = useState<boolean>(false);

  const debouncedColor = useInputDebounce<Color>(gradientColor, 300);

  useEffect(() => {
    // if (debouncedColor.id)
    setColor(debouncedColor);
  }, [debouncedColor]);

  const [values, colors] = useTranslatedFieldUpdate(
    gradientColor,
    (color: Color) => {
      const colors = color.colors.filter((c) => c.offset !== undefined);
      return [colors.map((c) => c.offset as number), colors.map((c) => c.color)];
    },
    [[], []]
  );

  useEffect(() => {
    if (color && ColorNeedsUpdate(gradientColor, color)) setGradientColor(color);
  }, [color]);

  useEffect(() => {
    if (color?.range?.[0] && color?.range?.[1]) {
      const range = color.range;
      // const diff = Math.abs(range[0] - range[1]);
      // const tic = Math.floor(Math.log(diff) / Math.log(10));

      const digit = getDigit(range, width);
      const newRange = range.map((v) => v.toFixed(digit));
      if (newRange.some((r, i) => r !== minMax[i])) setMinMax(newRange);
    }
  }, [color?.range, width]);

  useEffect(() => {
    const state = selectedOffset >= 0 && selectedOffset < values.length;
    if (state) {
      if (onSettingsOpen && !toolBoxState) onSettingsOpen();
    } else {
      if (onSettingsClosed && toolBoxState) onSettingsClosed();
    }

    setToolBoxState(state);
  }, [selectedOffset]);

  const changeColor = useCallback(
    (color: Color) => {
      if (ColorNeedsUpdate(gradientColor, color)) {
        let unsorted = false;
        for (let i = 1; i < color.colors.length; i++) {
          if ((color.colors[i - 1].offset as number) > (color.colors[i].offset as number)) {
            unsorted = true;
            break;
          }
        }
        if (unsorted) {
          const colors = color.colors.slice().sort((a, b) => (a.offset as number) - (b.offset as number));
          color = initColor({ ...color, colors: colors }, true);
        }
        setGradientColor(color);
      }
    },
    [gradientColor]
  );

  const setOffsets = useCallback(
    (values: number[]) => {
      const colors = produce(gradientColor.colors, (next) => {
        values.forEach((v, i) => (next[i].offset = v));
      });
      setGradientColor(initColor({ ...gradientColor, colors: colors }, true));
    },
    [gradientColor]
  );

  return (
    <div
      style={{
        width: width,
      }}
    >
      <Range
        step={0.001}
        min={0}
        max={1}
        values={values}
        renderTrack={({ props, children }: { props: any; children: any }) => (
          <div {...props}>
            {children}
            <div style={{ width: width, height: 20 }} />
          </div>
        )}
        onChange={setOffsets}
        renderThumb={({ index, props }: { index: number; props: any }) => {
          return (
            <div {...props}>
              <ColorSliderThumb
                color={colors[index]}
                onSingleClick={() => (index === selectedOffset ? setSelectedOffset(-1) : setSelectedOffset(index))}
                {...props}
              />
            </div>
          );
        }}
      />
      <div style={{ height: 3 }} />

      <Range
        step={0.001}
        min={0}
        max={1}
        values={values}
        renderTrack={({ props, children }: { props: any; children: any }) => (
          // <div {...props} className={styles.sliderTrack} style={{ background: getCSSGradient(gradientColors) }}>
          <div {...props}>
            {children}
            <ColorBox color={gradientColor} width={width} height={20} />
          </div>
        )}
        onChange={setOffsets}
        renderThumb={sliderThumb}
      />

      <div className={styles.range}>
        <span>{minMax[0]}</span>
        <span>{minMax[1]}</span>
        {/* <span>{color}</span> */}
      </div>

      {toolBoxState && selectedOffset >= 0 ? (
        <div
          style={{
            position: "relative",
            top: -25,
            // background: "green",
          }}
        >
          <SvgLine offsets={values} index={selectedOffset} width={width} />
          <div style={{ position: "relative", top: -6 }}>
            <ToolBox
              width={width}
              color={gradientColor}
              setColor={changeColor}
              // setColor={(color: Color) => console.log("ToolBox set", color)}
              selectedOffset={selectedOffset}
              setSelectedOffset={setSelectedOffset}
              onClose={() => {
                setToolBoxState(false);
                if (onSettingsClosed) onSettingsClosed();
              }}
            />
          </div>
        </div>
      ) : null}
    </div>
  );
};

export const ColorGradientEditor = function ({
  color,
  setColor,
  onSettingsOpen,
  onSettingsClosed,
}: {
  color?: Color;
  setColor?: (color: Color) => void;
  onSettingsOpen?: () => void;
  onSettingsClosed?: () => void;
}) {
  return (
    <div
      className={styles.main}
      onWheelCapture={(e) => {
        // let next = current + Math.sign(e.deltaY);
        // if (next >= trackList.length) next = 0;
        // if (next < 0) next = trackList.length - 1;
        // setCurrent(next);
        console.log("wheel", e.deltaY);
      }}
    >
      <Resizeable className={styles.sliderMain}>
        <SliderDiv
          color={color}
          setColor={setColor}
          onSettingsOpen={onSettingsOpen}
          onSettingsClosed={onSettingsClosed}
        />
      </Resizeable>
    </div>
  );
};
