import { Control, Controller, ControllerRenderProps, DeepMap, FieldError, Path, useWatch } from "react-hook-form";
import { FormFieldError, FormFieldLayout } from "../FormFieldLayouts";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./DatePickerFormField.css";
import { useCallback, useMemo, useState } from "react";
import { LucideIcon } from "../../icon/LucideIcon";
import { convertPathValueToDate } from "./SingleDatePickerFormField";
import { StringIndexedDict } from "../../../api/GenericTypes";

export interface SplitDatePickerFormFieldProps<Entity extends StringIndexedDict> {
  idStart: Path<Entity>;
  idEnd: Path<Entity>;
  label: string;
  errors?: DeepMap<any, FieldError>;
  required?: boolean;
  autoFocus?: boolean;
  readOnly?: boolean;
  horizontal?: boolean;
  uncontained?: boolean;
  autoComplete?: string;
  disabled?: boolean;
  control: Control<Entity, any, Entity>;
  shouldUnregister?: boolean; //	Input will be unregistered after unmount and defaultValues will be removed as well.
  excludeDates?: Date[];
  showWeekNumbers?: boolean;
  showTimeSelect?: boolean;
}

export const SplitDatePickerFormField = <Entity extends StringIndexedDict>({
  idStart,
  idEnd,
  label,
  errors,
  required,
  autoFocus,
  readOnly,
  horizontal = true,
  uncontained = false,
  autoComplete = "off",
  disabled = false,
  control,
  shouldUnregister = false,
  excludeDates,
  showWeekNumbers = true,
  showTimeSelect = false,
}: SplitDatePickerFormFieldProps<Entity>) => {
  //#region error
  const errorStart = useMemo(() => {
    const fieldPath = idStart.split(".");
    let error: any = undefined;
    for (const path of fieldPath) {
      if (!error) error = errors?.[path];
      else error = error?.[path];
    }
    return error;
  }, [errors, idStart]);
  //#endregion

  //#region error
  const errorEnd = useMemo(() => {
    const fieldPath = idEnd.split(".");
    let error: any = undefined;
    for (const path of fieldPath) {
      if (!error) error = errors?.[path];
      else error = error?.[path];
    }
    return error;
  }, [errors, idEnd]);
  //#endregion

  const startDate = useWatch({ name: idStart, control }) as string | Date | undefined | null;
  const endDate = useWatch({ name: idEnd, control }) as string | Date | undefined | null;

  const [openStartDatePicker, setOpenStartDatePicker] = useState<boolean>(false);
  const [openEndDatePicker, setOpenEndDatePicker] = useState<boolean>(false);

  const StartDatePicker = useCallback(
    ({ field: { onChange, onBlur, value, name, ref } }: { field: ControllerRenderProps<Entity> }) => (
      <div style={{ position: "relative" }}>
        <div className="react-datepicker__iconPlacement">
          <LucideIcon name="calendar" color={!!startDate ? "var(--primary)" : "gray"} />
        </div>
        <DatePicker
          selected={convertPathValueToDate(value)}
          onChange={onChange}
          onBlur={onBlur}
          selectsStart
          startDate={convertPathValueToDate(value)}
          endDate={convertPathValueToDate(endDate)}
          isClearable={!readOnly && !disabled}
          className={`form form-control${errorStart ? " border-danger" : ""}`}
          wrapperClassName={`${errorStart ? "border-danger" : ""}`}
          dateFormat={showTimeSelect ? "yyyy-MM-dd HH:mm" : "yyyy-MM-dd"}
          ref={ref}
          name={name}
          todayButton="Today"
          excludeDates={excludeDates}
          popperPlacement="bottom"
          showWeekNumbers={showWeekNumbers}
          autoComplete={autoComplete}
          disabled={disabled}
          readOnly={readOnly}
          autoFocus={autoFocus}
          placeholderText={showTimeSelect ? "yyyy-MM-dd HH:mm" : "yyyy-MM-dd"}
          open={openStartDatePicker}
          onCalendarClose={() => {
            setOpenStartDatePicker(false);
            setOpenEndDatePicker(!!startDate && !endDate);
          }}
          onInputClick={() => {
            setOpenEndDatePicker(false);
            setOpenStartDatePicker(true);
          }}
          showMonthDropdown
          showYearDropdown
          scrollableYearDropdown
          dropdownMode="select"
          portalId="root-portal"
          openToDate={convertPathValueToDate(endDate) ?? undefined}
          showTimeSelect={showTimeSelect}
          timeFormat="HH:mm"
          filterTime={(date) => !endDate || date <= endDate}
          onClickOutside={() => {
            setOpenStartDatePicker(false);
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              e.stopPropagation();
            }
          }}
        />
      </div>
    ),
    [
      autoComplete,
      autoFocus,
      disabled,
      endDate,
      errorStart,
      excludeDates,
      openStartDatePicker,
      readOnly,
      showTimeSelect,
      showWeekNumbers,
      startDate,
    ]
  );

  const EndDatePicker = useCallback(
    ({ field: { onChange, onBlur, value, name, ref } }: { field: ControllerRenderProps<Entity> }) => (
      <div style={{ position: "relative" }}>
        <div className="react-datepicker__iconPlacement">
          <LucideIcon name="calendar" color={!!endDate ? "var(--primary)" : "gray"} />
        </div>
        <DatePicker
          selected={convertPathValueToDate(value)}
          onChange={onChange}
          onBlur={onBlur}
          selectsEnd
          startDate={convertPathValueToDate(startDate)}
          endDate={convertPathValueToDate(value)}
          minDate={convertPathValueToDate(startDate)}
          isClearable={!readOnly && !disabled}
          className={`form form-control ${errorEnd ? " border-danger" : ""}`}
          wrapperClassName={`${errorEnd ? "border-danger" : ""}`}
          dateFormat={showTimeSelect ? "yyyy-MM-dd HH:mm" : "yyyy-MM-dd"}
          ref={ref}
          name={name}
          todayButton="Today"
          excludeDates={excludeDates}
          popperPlacement="bottom"
          showWeekNumbers={showWeekNumbers}
          autoComplete={autoComplete}
          disabled={disabled}
          readOnly={readOnly}
          placeholderText={showTimeSelect ? "yyyy-MM-dd HH:mm" : "yyyy-MM-dd"}
          open={openEndDatePicker}
          onCalendarClose={() => {
            setOpenEndDatePicker(false);
          }}
          onInputClick={() => {
            setOpenStartDatePicker(false);
            setOpenEndDatePicker(true);
          }}
          showMonthDropdown
          showYearDropdown
          scrollableYearDropdown
          dropdownMode="select"
          portalId="root-portal"
          openToDate={convertPathValueToDate(startDate) ?? undefined}
          showTimeSelect={showTimeSelect}
          timeFormat="HH:mm"
          filterTime={(date) => !!startDate && date >= startDate}
          onClickOutside={() => {
            setOpenEndDatePicker(false);
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              e.stopPropagation();
            }
          }}
        />
      </div>
    ),
    [
      autoComplete,
      disabled,
      endDate,
      errorEnd,
      excludeDates,
      openEndDatePicker,
      readOnly,
      showTimeSelect,
      showWeekNumbers,
      startDate,
    ]
  );

  if (uncontained) {
    return (
      <>
        <div
          className="react-datepicker__custom-container"
          style={{ gridTemplateColumns: `repeat(auto-fit, minmax(${showTimeSelect ? "21ch" : "18ch"}, 1fr))` }}
        >
          <Controller
            control={control}
            name={idStart}
            rules={{ required: required }}
            shouldUnregister={shouldUnregister}
            render={StartDatePicker}
          />
          <Controller
            control={control}
            name={idEnd}
            rules={{ required: required }}
            shouldUnregister={shouldUnregister}
            render={EndDatePicker}
          />
        </div>
        <FormFieldError id={idStart} errors={errors} />
        <FormFieldError id={idEnd} errors={errors} />
      </>
    );
  } else {
    return (
      <FormFieldLayout
        id={idStart}
        label={label}
        required={required}
        horizontal={horizontal}
        hasLabel={!!label && label !== ""}
      >
        <div
          className="react-datepicker__custom-container"
          style={{ gridTemplateColumns: `repeat(auto-fit, minmax(${showTimeSelect ? "21ch" : "18ch"}, 1fr))` }}
        >
          <Controller
            control={control}
            name={idStart}
            rules={{ required: required }}
            shouldUnregister={shouldUnregister}
            render={StartDatePicker}
          />
          <Controller
            control={control}
            name={idEnd}
            rules={{ required: required }}
            shouldUnregister={shouldUnregister}
            render={EndDatePicker}
          />
        </div>
        <FormFieldError id={idStart} errors={errors} />
        <FormFieldError id={idEnd} errors={errors} />
      </FormFieldLayout>
    );
  }
};
