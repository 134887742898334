import {
  ICreatedRecordParametersTranslator,
  ICreationRecordLabels,
  IGenericRequestParametersTranslator,
  IModificationRecordLabels,
  IModifiedRecordParametersTranslator,
  INamedEntityLabels,
  IOwnedEntityParametersTranslator,
  IPaginationParametersTranslator,
  IPermissionedEntityLabels,
  IRelatedEntityLabels,
  IRelationParametersTranslator,
  IUniqueEntityLabels,
  PropertyTranslator,
} from "./GenericTranslator";
import {
  IGenericRequestParameters,
  IEntityMinimalModel,
  IModelWithIntId,
  INamedEntity,
  IPaginationParameters,
  IPermissionedEntity,
  IUniqueEntity,
  IOwnedEntityParameters,
  EntityConstants,
  ICreationRecord,
  IModificationRecord,
  ICreatedRecordParameters,
  IModifiedRecordParameters,
  IOwnedEntity,
  DefaultOrderTypeConsts,
  DefaultOwnerOrderTypeConsts,
  DefaultCreatedByRecordOrderTypeConsts,
  DefaultCreatedOnRecordOrderTypeConsts,
  DefaultModifiedByRecordOrderTypeConsts,
  DefaultModifiedOnRecordOrderTypeConsts,
  IRelatedEntity,
  IRelation,
  IRelationModel,
  IRelationParameters,
} from "./GenericTypes";

export const experimentsConstants: EntityConstants<Experiment, ExperimentFilters> = {
  resource: "experiments",
  frontendIndexRoute: "experiments",
  entitySingular: "experiment",
  entityPlural: "experiments",
  icon: "clipboard-pen-line",
};

export const ExperimentFieldLabels: PropertyTranslator<Experiment> = {
  ...INamedEntityLabels,
  ...IPermissionedEntityLabels,
  ...ICreationRecordLabels,
  ...IModificationRecordLabels,
  ...IRelatedEntityLabels,
  ...IUniqueEntityLabels,
  id: "Experiment ID",
  method: "Method",
  notes: "Notes",
  owner: "Owner",
} as const;

export interface Experiment
  extends IModelWithIntId,
    INamedEntity,
    IPermissionedEntity,
    IUniqueEntity,
    ICreationRecord,
    IModificationRecord,
    IPermissionedEntity,
    IOwnedEntity,
    IRelatedEntity<ExperimentRelations> {
  method: IEntityMinimalModel;
  notes: string;
}
export interface ExperimentSuggestions extends IEntityMinimalModel<Experiment["id"]> {}

export interface ExperimentRelations extends IRelationModel {
  datasets: IRelation;
}
export interface ExperimentFilters
  extends IGenericRequestParameters<Experiment, ExperimentSortingOptions>,
    IPaginationParameters,
    IOwnedEntityParameters,
    ICreatedRecordParameters,
    IModifiedRecordParameters,
    IRelationParameters {
  methodIds?: number[] | null;
}
export const ExperimentFiltersTranslator: PropertyTranslator<ExperimentFilters> = {
  ...IGenericRequestParametersTranslator,
  ...IPaginationParametersTranslator,
  ...IOwnedEntityParametersTranslator,
  ...ICreatedRecordParametersTranslator,
  ...IModifiedRecordParametersTranslator,

  ...IRelationParametersTranslator,
  methodIds: "Method IDs",
} as const;

export const ExperimentSortingOptionsConsts = [
  ...DefaultOrderTypeConsts,
  ...DefaultOwnerOrderTypeConsts,
  ...DefaultCreatedOnRecordOrderTypeConsts,
  ...DefaultCreatedByRecordOrderTypeConsts,
  ...DefaultModifiedOnRecordOrderTypeConsts,
  ...DefaultModifiedByRecordOrderTypeConsts,
  "METHOD_ASC",
  "METHOD_DESC",
] as const;

export type ExperimentSortingOptions = (typeof ExperimentSortingOptionsConsts)[number];

// export class ExperimentsApi extends AbstractBaseEntityApi<Experiment> {
//   suggestions = async (measurementMethodId: number): Promise<IMinimalModel[]> =>
//     await this.api.get(`experiments/${measurementMethodId}/suggestions`);
//   useSuggestions = (measurementMethodId: number) => () =>
//     useQuery([this.resource, "list", "suggestions"], () => this.suggestions(measurementMethodId));
// }
