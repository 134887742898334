import { DataSource, DataSourceFilters } from "./DataSource";
import {
  IGenericRequestParametersTranslator,
  IPaginationParametersTranslator,
  PropertyTranslator,
} from "./GenericTranslator";
import {
  DefaultOrderTypeConsts,
  EntityConstants,
  IEntityMinimalModel,
  IGenericEntity,
  IGenericRequestParameters,
  IPaginationParameters,
  IPermissionedEntity,
} from "./GenericTypes";

// IconNMR
export const iconNMRConstants: EntityConstants<DataSource, DataSourceFilters> = {
  resource: "data_sources",
  frontendIndexRoute: "iconnmr",
  entitySingular: "IconNMR monitoring",
  entityPlural: "IconNMR monitoring",
  icon: "radar",
};

// IconNMR History
export interface IconNMRHistoryEntry extends IGenericEntity<number>, IPermissionedEntity {
  startTime?: string;
  completionTime?: string;
  user: string;
  holder?: number;
  diskUnit: string;
  datasetName: string;
  experimentNo?: number;
  experimentName: string;
  statusInformationForATM: IconNMRProgressState | null;
  rotation: IconNMRProgressState | null;
  lock: IconNMRProgressState | null;
  shim: IconNMRProgressState | null;
  acquisition: IconNMRProgressState | null;
  processing: IconNMRProgressState | null;
  originatorTitle: string;
  remarks: string;
  sampleLoad: IconNMRProgressState | null;
  tubeId: string;
  analysisResult: IconNMRProgressState | null;
  analysisOutput: string;
  sBase: string;
  setupRow: number;
  consistency: IconNMRProgressState | null;
  dataSource: {
    instrument: IEntityMinimalModel;
    method: IEntityMinimalModel;
  } & DataSource;
}

type IconNMRProgressState = "S" | "Y" | "X";

export interface IconNMRHistoryEntryFilters
  extends IGenericRequestParameters<IconNMRHistoryEntry, IconNMRHistoryEntrySortingOptions>,
    IPaginationParameters {
  dataSourceIds?: number[] | null;
  instrumentIds?: number[] | null;
}

export const IconNMRHistoryEntrySortingOptionsConsts = [
  ...DefaultOrderTypeConsts,
  "START_TIME_ASC",
  "START_TIME_DESC",
  "COMPLETION_TIME_ASC",
  "COMPLETION_TIME_DESC",
  "USER_ASC",
  "USER_DESC",
  "HOLDER_ASC",
  "HOLDER_DESC",
  "DISK_UNIT_ASC",
  "DISK_UNIT_DESC",
  "EXPNO_ASC",
  "EXPNO_DESC",
  "DATA_SOURCE_ASC",
  "DATA_SOURCE_DESC",
  "INSTRUMENT_ASC",
  "INSTRUMENT_DESC",
  "METHOD_ASC",
  "METHOD_DESC",
] as const;

export type IconNMRHistoryEntrySortingOptions = (typeof IconNMRHistoryEntrySortingOptionsConsts)[number];

export const IconNMRHistoryEntryFiltersTranslator: PropertyTranslator<IconNMRHistoryEntryFilters> = {
  ...IPaginationParametersTranslator,
  ...IGenericRequestParametersTranslator,

  dataSourceIds: "Data-source-IDs",
  instrumentIds: "Instrument-IDs",
};

export const iconNMRHistoryConstants: EntityConstants<IconNMRHistoryEntry, IconNMRHistoryEntryFilters> = {
  resource: "iconnmr_history_entries",
  frontendIndexRoute: "iconnmr",
  entitySingular: "iconNMR history entry",
  entityPlural: "iconNMR history entries",
  icon: "radar",
};

export const IconNMRHistoryFieldLabels: PropertyTranslator<IconNMRHistoryEntry> & {
  duration: string;
  instrument: string;
  method: string;
} = {
  acquisition: "Acquisition",
  analysisOutput: "Analysis output",
  analysisResult: "Analysis result",
  completionTime: "Completion time",
  consistency: "Consistency",
  datasetName: "Dataset name",
  dataSource: "Data source",
  diskUnit: "Disk unit",
  experimentName: "Experiment name",
  experimentNo: "Experiment no",
  holder: "Holder",
  id: "History entry ID",
  lock: "Lock",
  originatorTitle: "Originator title",
  processing: "Processing",
  permissions: "Permissions",
  remarks: "Remarks",
  rotation: "Rotation",
  sampleLoad: "Sample load",
  setupRow: "Setup row",
  shim: "Shim",
  sBase: "S base",
  startTime: "Start time",
  statusInformationForATM: "Status information for ATM",
  tubeId: "Tube ID",
  user: "User",
  duration: "Duration",
  instrument: "Instrument",
  method: "Method",
} as const;

// Holders
export interface IconNMRHolder extends IGenericEntity<number>, IPermissionedEntity {
  holder?: number;
  status: IconNMRHolderStatus;
  dataset: string;
  expno: number;
  solvent: string[];
  experiment: string[];
  path: string;
  duration?: number;
  user: string;
  queuePosition: number;
  dataSource: {
    instrument: IEntityMinimalModel;
    method: IEntityMinimalModel;
  } & DataSource;
  modifiedOn: string;
}

export const IconNMRHolderFieldLabels: PropertyTranslator<IconNMRHolder> & {
  instrument: string;
  method: string;
} = {
  dataset: "Dataset",
  dataSource: "Data source",
  duration: "Duration in seconds",
  experiment: "Experiment",
  expno: "Expno",
  holder: "Holder",
  id: "Holder ID",
  modifiedOn: "Modified on",
  path: "Path",
  permissions: "Permissions",
  queuePosition: "Queue position",
  solvent: "Solvent",
  status: "Status",
  user: "User",
  instrument: "Instrument",
  method: "Method",
} as const;

export type IconNMRHolderStatus = "Unknown" | "Submitted" | "Running" | "Completed" | "Available";

export interface IconNMRHolderFilters
  extends IGenericRequestParameters<IconNMRHolder, IconNMRHolderSortingOptions>,
    IPaginationParameters {
  dataSourceIds?: number[] | null;
  instrumentIds?: number[] | null;
  methodIds?: number[] | null;
  statuses?: IconNMRHolderStatus[] | null;
}

export const iconNMRHoldersConstants: EntityConstants<IconNMRHolder, IconNMRHolderFilters> = {
  resource: "iconnmr_holders",
  frontendIndexRoute: "iconnmr",
  entitySingular: "iconNMR queue",
  entityPlural: "iconNMR queue",
  icon: "radar",
};
export const IconNMRHolderSortingOptionsConsts = [
  ...DefaultOrderTypeConsts,
  "HOLDER_ASC",
  "HOLDER_DESC",
  "STATUS_ASC",
  "STATUS_DESC",
  "EXPNO_ASC",
  "EXPNO_DESC",
  "DURATION_ASC",
  "DURATION_DESC",
  "USER_ASC",
  "USER_DESC",
  "MODIFIED_ON_ASC",
  "MODIFIED_ON_DESC",
  "QUEUE_POSITION_ASC",
  "QUEUE_POSITION_DESC",
  "DATA_SOURCE_ASC",
  "DATA_SOURCE_DESC",
  "INSTRUMENT_ASC",
  "INSTRUMENT_DESC",
  "METHOD_ASC",
  "METHOD_DESC",
] as const;

export type IconNMRHolderSortingOptions = (typeof IconNMRHolderSortingOptionsConsts)[number];

export const IconNMRHolderFiltersTranslator: PropertyTranslator<IconNMRHolderFilters> = {
  ...IPaginationParametersTranslator,
  ...IGenericRequestParametersTranslator,

  dataSourceIds: "Data-source-IDs",
  instrumentIds: "Instrument-IDs",
  methodIds: "Method-IDs",
  statuses: "Statuses",
};

// Status
export interface IconNMRStatus {
  dataSource: DataSource;
  queuedExperiments: number;
  totalQueueTimeInSeconds: number;
  acquisitionRunning: boolean;
}

export const iconNMRStatusConstants: EntityConstants<IconNMRStatus> = {
  resource: "iconnmr_status",
  frontendIndexRoute: "iconnmr",
  entitySingular: "iconNMR status",
  entityPlural: "iconNMR status",
  icon: "radar",
};

export const IconNMRStatusFieldLabels: PropertyTranslator<IconNMRStatus> = {
  dataSource: "Data source",
  queuedExperiments: "Queued experiments",
  totalQueueTimeInSeconds: "Total queue time in seconds",
  acquisitionRunning: "Acquisition",
} as const;
