import { useContext } from "react";
import { Panel } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { ParsingOutputModel } from "../api/Parsing";
import { SessionContext } from "../common/contexts/SessionContext";
import { LucideIcon } from "../common/icon/LucideIcon";
import { showtoast } from "../common/overlays/Toasts/showtoast";
import { DownloadDataset } from "../Dataset/common/DownloadDataset/DownloadDataset";
import { ParserName } from "../Dataset/common/ParserName";
import { getDetailLink } from "../main/Routing";
import { ViewerLogEntry } from "../ViewerLayout/ViewerLog";
import Codeblock from "../common/textfields/Codeblock/Codeblock";
/**
 * ParserStatusError component abstracted from ParserStatusErrors by SS
 * Author: CS
 * @param: parserErrorsResult, page, setPage
 * @returns: JSX.Element
 */

interface Props {
  item: ParsingOutputModel;
}
export const ParserStatusError = ({ item }: Props) => {
  const { api, route } = useContext(SessionContext);
  const history = useHistory();

  return (
    <Panel>
      <Panel.Heading>
        <div style={{ display: "flex" }}>
          <span key={item.datasetId} style={{ whiteSpace: "nowrap" }}>
            <b> Dataset-ID:</b> {item.datasetId}
          </span>
        </div>
      </Panel.Heading>
      <Panel.Body>
        {item.parsingOutput ? (
          <Codeblock>{item.parsingOutput}</Codeblock>
        ) : (
          <div className="flex col-nowrap gap-5" style={{ width: "100%", height: "fit-content", paddingRight: 10 }}>
            {item.parserLogs.map((log, i) => (
              <ViewerLogEntry key={i} log={log} defaultShowError />
            ))}
          </div>
        )}
      </Panel.Body>
      <Panel.Footer>
        <div
          style={{
            display: "flex",
            flexFlow: "row nowrap",
            gap: "5px",
            alignItems: "center",
            width: "100%",
            height: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              flexFlow: "row nowrap",
              gap: "5px",
              whiteSpace: "nowrap",
              width: "min-content",
              alignItems: "center",
            }}
          >
            Data format:
            <b>
              <ParserName parserType={item.parserType} />
            </b>
            Version:
            <b>
              {item.majorVersion}.{item.minorVersion}
            </b>
          </div>
          <div
            style={{
              display: "flex",
              flexFlow: "row nowrap",
              gap: "5px",
              marginLeft: "auto",
              width: "min-content",
            }}
          >
            <button
              onClick={() => {
                navigator.clipboard.writeText(item.parsingOutput);
                showtoast("success", "Copied traceback to clipboard");
              }}
              className="btn btn-sm btn-default"
              title={"Copy the traceback to the clipboard"}
            >
              <LucideIcon name="clipboard" /> Copy error
            </button>
            <Link to={route(getDetailLink("datasets", item.datasetId))}>
              <button
                className="btn btn-sm btn-info"
                onClick={() => history.push(route(getDetailLink("datasets", item.datasetId)))}
              >
                <LucideIcon name="external-link" /> View Dataset
              </button>
            </Link>
            <DownloadDataset api={api} id={item.datasetId} label={"Download dataset"} />
          </div>
        </div>
      </Panel.Footer>
    </Panel>
  );
};
