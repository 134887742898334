import { Position } from "geojson";
import { contourGeneratorType, PlotZoomSettings, TrackMatrixData } from "./../ViewerLayout/ViewerLayoutTypes";
import {
  TrackXY,
  TrackMatrix,
  TrackXYSettings,
  TrackMatrixSettings,
  TrackXYData,
} from "../ViewerLayout/ViewerLayoutTypes";
import { TypedArray } from "d3-interpolate";

import { Color } from "../ViewerLayout/ViewerLayoutTypes";

export type Track = TrackXY | TrackMatrix;
export type TrackSettings = TrackXYSettings | TrackMatrixSettings;

export type d3ScaleLinearType = {
  (value: number): number;
  invert: (x: number) => number;
  domain: (d?: number[]) => number[];
  range: (r?: number[]) => number[];
  tickFormat: (tic: number, format: string) => (value: number) => string;
  ticks: (count: number) => number[];
};

export type d3LinearType = {
  (d: [number, number][]): void;
};

export const colorToString = (color: Color) => {
  if (color.colors.length === 1) return color.colors[0].color;
  return "#566573"; // default color for annotations
};

export class LinearGenerator {
  min: number;
  max: number;
  step: number;

  constructor(min: number, max: number, count: number) {
    this.min = min;
    this.max = max;
    this.step = count > 1 ? (max - min) / (count - 1) : 0;
  }

  public setCount(count: number) {
    this.step = (this.max - this.min) / (count - 1);
  }

  public generate(value: number) {
    return Math.round((value - this.min) / this.step);
  }

  public generateArray(value: number[]) {
    return value.map((v) => Math.round((v - this.min) / this.step));
  }

  public reverse(index: number) {
    return this.min + index * this.step;
  }

  public reverseArray(index: number[]) {
    return index.map((i) => this.min + i * this.step);
  }
}

export type parameterType = {
  width: number;
  height: number;
  title: string;
  xMin: number;
  xMax: number;
  yMin: number;
  yMax: number;
  xNum: number;
  yNum: number;
  offsetAngle: number;
  offsetPadding: number;
  scaleExtent: [number, number];
  translate: [number, number];
  minTranslateExtent: [number, number];
  maxTranslateExtent: [number, number];
  maxOffsetPadding: number;
};

export type shapeTypes = "xPoint" | "yPoint" | "xyPoint" | "xRange" | "yRange" | "box" | "xArea" | "xPeak";

export type DataType = trackdata1DType;
export type PathType = { x: Float32Array | Float64Array; y: Float32Array | Float64Array; size: number };

export type shapeFunction = (entry: AnnotationShape, data: DataType) => AnnotationShape;

export interface ShapeBase {
  tracks: string[];
  type: string;
  id: string;
  class: string;
  color: string;
  func?: shapeFunction;
}

export interface ShapeLine extends ShapeBase {
  type: "line";
  xPos?: number;
  x1: number;
  y1: number;
  x2: number;
  y2: number;
  x1noScale?: boolean;
  y1noScale?: boolean;
  x2noScale?: boolean;
  y2noScale?: boolean;
  x2rel?: boolean;
  y2rel?: boolean;
}

export interface ShapeText extends ShapeBase {
  type: "text";
  dx: number;
  dy: number;
  text: string;
  x: number;
  y: number;
  xnoScale?: boolean;
  ynoScale?: boolean;
  anchor?: "start" | "end" | "middle";
  rotate?: number;
}

export interface ShapeCircle extends ShapeBase {
  type: "circle";
  r: number;
  x: number;
  y: number;
  xnoScale?: boolean;
  ynoScale?: boolean;
}

export interface ShapeRect extends ShapeBase {
  type: "rect";
  x1: number;
  y1: number;
  x2: number;
  y2: number;
  x1noScale?: boolean;
  y1noScale?: boolean;
  x2noScale?: boolean;
  y2noScale?: boolean;
  border?: boolean;
}

export interface ShapeXArea extends ShapeBase {
  type: "xArea";
  x1: number;
  x2: number;
  path: PathType;
}

export type AnnotationShape = ShapeLine | ShapeText | ShapeCircle | ShapeRect | ShapeXArea;

export type trackStyle = {
  hideAxis: { x: boolean; y: boolean; z: boolean };
  reverseColor: boolean;
  draw: { line: boolean; dots: boolean; heatmap: boolean; contour: boolean; filled: boolean };
  annotation: { tics: boolean; labels: boolean };
  normalize: { "0-1": boolean; "-1-1": boolean };
  visible: boolean;
  active: boolean;
  marked: boolean;
  color: undefined;
  axisMin: undefined;
  axisMax: undefined;
  contours: { state: undefined; generate: undefined };
};

export interface trackPropertiesBase {
  id: string;
  dimension: number;
  name: string;
  resampled: boolean;
  visible: boolean;
  settings: TrackSettings;
  zIndex: number;
  ratio: number;
  colors: Color;
}

// export interface trackPropertiesMatrix extends trackPropertiesBase {
//   data: TrackMatrixData;
//   type: "matrix_real";
// }

// export interface trackPropertiesXY extends trackPropertiesBase {
//   data: TrackXYData;
//   type: "XY_real";
// }

// export type trackProperties = trackPropertiesXY | trackPropertiesMatrix;
export type trackContourType = {
  id: string;
  paths?: contourPath[];
};

export type trackHeatmapCanvasType = {
  // image?: HTMLImageElement;
  canvas?: HTMLCanvasElement;
  canvasX: number;
  canvasY: number;
  min: number[];
  max: number[];
  id: string;
};

export type trackdata1DType = {
  x: Float32Array | Float64Array;
  y: Float32Array | Float64Array;
  min: number[];
  max: number[];
  size: number;
  id: string;
};

export type contourPath = {
  value: number;
  path: Position[];
};

export type trackDrawType = {
  line: boolean;
  dots: boolean;
  heatmap: boolean;
  contour: boolean;
  filled: boolean;
};

export type trackProperties = {
  id: string;
  dimension: number;
  name: string;
  resampled: boolean;
  settings: {
    hideAxis: Record<"x" | "y" | "z", boolean>;
    axisLabels: Record<"x" | "y" | "z", string | undefined>;
    axisUnits: Record<"x" | "y" | "z", string | undefined>;
    annotation: Record<"tics" | "labels", boolean>;
    draw: trackDrawType;
    color: Color;
    zoom: PlotZoomSettings;
    visible: boolean;
    normalize: Record<"0-1" | "-1-1", boolean>;
    contourGenerators: contourGeneratorType[];
  };
  range: {
    min?: number[];
    max?: number[];
  };
  zIndex: number;
  ratio: number;
  data: TrackXYData | TrackMatrixData;
  type: "matrix_real" | "XY_real";
  trackdata1D?: trackdata1DType;
  heatmap?: trackHeatmapCanvasType;
  contours?: trackContourType;
  contourGenerators?: contourGeneratorType[];
};

export type drawType = {
  x: number[] | TypedArray;
  y: number[] | TypedArray;
  length: number;
};

export type textType = {
  x: number;
  y: number;
  text: string;
};

export type drawStyle = {
  lineWidth?: number;
  strokeStyle?: string;
  fillStyle?: string;
  radius?: number;
  color?: string;
  mode?: "fill" | "empty";
};

export type vector2D = [number, number];

export type domainType = { range: number[][]; points: number[] };

export type posListenerType = (pos: { x: number | undefined; y: number | undefined }) => void;
export type visibilityListenerType = (visible: boolean) => void;
export type redrawListenerType = (domain: { range: number[][]; points: number[] }) => void;

export enum COLOR {
  BY_DATASET = 0,
  BY_TRACK = 1,
}

export enum VIEW {
  ONETRACK = 0,
  MULTI = 1,
  OFFSET = 2,
}

export enum DRAW {
  LINE = 1,
  DOTS = 2,
  LINE_DOTS = 3,
}

export enum DRAW2D {
  HEATMAP = 1,
  CONTOUR = 2,
  FILL = 4,
  CONTOUR_FILL = 6,
  CONTOUR_HEATMAP = 3,
}

export enum ZOOM {
  VISIBLE = 0,
  ALL = 1,
  VISIBLE_WIDTH = 2,
  VISIBLE_HEIGHT = 3,
  ALL_WIDTH = 4,
  ALL_HEIGHT = 5,
}

export enum ANNOTATION {
  SHAPE = 1,
  LABEL = 2,
  SHAPE_LABEL = 3,
  NONE = 0,
}

export enum AXIS {
  FIXX = 1,
  FIXY = 2,
  FREE = 0,
}

export enum CROSSHAIR {
  XLINE = 1,
  YLINE = 2,
  XVALUE = 4,
  YVALUE = 8,
  ALL = 15,
  NONE = 0,
}

export enum TICS {
  XY = 3,
  X = 1,
  Y = 2,
  NONE = 0,
}

export enum NORM {
  NONE = 0,
  FULL = 1,
  POSNEG = 2,
}

export enum PLOT {
  REAL_1D = "1D_real",
  COMPLEX_1D = "1D_complex",
  MATRIX_2D = "2D_real_matrix",
}
