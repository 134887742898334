import React, { useCallback } from "react";
import { Spinner } from "../../common/loaders/Spinner/Spinner";
import { PageError, PageErrorProps } from "../common/PageError/PageError";
import { useAuth } from "react-oidc-context";
import { LucideIcon } from "../../common/icon/LucideIcon";
import { Alert } from "../../common/overlays/Alert/Alert";

export const OIDCAuthLoading = ({ message = "Loading..." }: { message?: string }) => (
  <div className="flex row-nowrap align-center justify-center gap-5" style={{ width: "100%", height: "100%" }}>
    <Spinner inline /> {message}
  </div>
);

export const OIDCAuthError = (props: PageErrorProps) => <PageError {...props} />;

export const OIDCLoginDisabled = () => {
  const auth = useAuth();

  const logout = useCallback(async () => {
    await auth.removeUser();
    // window.history.replaceState({}, window.document.title, window.location.origin + window.location.pathname);
    window.location.replace(window.location.origin);
  }, [auth]);

  return (
    <div className="flex col-nowrap">
      <div style={{ fontWeight: "bold", fontSize: "1.2em", marginBottom: "1em" }}>
        Welcome {auth.user?.profile.name}!
      </div>
      <Alert
        type="info"
        message="Your LOGS account is awaiting approval. If you just registered your account please wait until an administrator unlocks
        your access."
        fit
      />
      <button className="btn btn-block btn-soft-danger" onClick={logout}>
        <LucideIcon name="log-out" />
        Log out
      </button>
    </div>
  );
};
