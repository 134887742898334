import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import styles from "../../../common/forms/forms.module.css";
import { LabNotebook, LabNotebookStatus, LabNotebookStatusList, labNoteBooksConstants } from "../../types/LabNotebook";
import { FormHeader } from "../../../common/forms/FormHeader";
import { InputFormField } from "../../../common/formfields/InputFormField";
import { TextareaFormField } from "../../../common/formfields/TextareaFormField";
import { FormButtons } from "../../../common/forms/FormButtons";
import { SelectFormField } from "../../../common/formfields/SelectFormField";
import { ProjectsVirtualizedSelectForm } from "../../../common/forms/EntityForms/formsVirtualized/ProjectsVirtualizedSelectForm";
import { useIdsOnly } from "../../../api/BaseEntityApi";
import { LabNotebookExperimentFilters } from "../../types/LabNotebookExperiment";
import { useCallback } from "react";
import { EntityFormProps } from "../../../common/entity/EntityInterfaces";

export interface LabNotebookFormValues extends Omit<Partial<LabNotebook>, "status"> {
  status: { id: string; name: string; value: LabNotebookStatus };
}

export const LabNotebookForm = ({
  event,
  fieldLabels,
  loading,
  permissions,
  title,
  subtitle,
  initialValues,
  onSubmit,
  onCancel,
  submitButtonLabel,
}: EntityFormProps<"notebooks">) => {
  const { data: runningExperiments } = useIdsOnly<number, LabNotebookExperimentFilters>(
    "lab_notebook_experiments",
    { labNotebookIds: initialValues?.id ? [initialValues.id] : null, status: ["RUNNING", "PAUSED"] },
    { enabled: !!initialValues }
  );

  const LabNotebookFormSchema = yup.object().shape({
    name: yup.string().required("Lab notebook name is required").typeError("Lab notebook name is required"),
    status: yup
      .object()
      .required("Lab notebook status is required")
      .typeError("Lab notebook status is required")
      .test({
        name: "closedForEditing",
        test: (value: any) => !initialValues || initialValues.status !== "CLOSED" || value.value !== "CLOSED",
        message: "The notebook is closed for editing. Set status to 'Active' to reopen it.",
        exclusive: false,
      })
      .test({
        name: "hasRunningExperiments",
        test: (value: any) => {
          return !(runningExperiments?.results.length && value.value === "CLOSED");
        },
        message: "The notebook still has running (or paused) experiments.",
        exclusive: false,
      }),
  });

  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
  } = useForm<LabNotebookFormValues>({
    values: !!initialValues
      ? {
          ...initialValues,
          status: {
            id: initialValues.status as LabNotebookStatus,
            name: initialValues!.status!.charAt(0) + initialValues!.status!.slice(1).toLowerCase(),
            value: initialValues.status as LabNotebookStatus,
          },
        }
      : {
          status: {
            id: "ACTIVE" as LabNotebookStatus,
            name: "Active",
            value: "ACTIVE" as LabNotebookStatus,
          },
        },
    resolver: yupResolver(LabNotebookFormSchema),
  });

  const formValuesToEntity = useCallback(
    (entity: LabNotebookFormValues) => onSubmit({ ...entity, status: entity.status.value } as LabNotebook),
    [onSubmit]
  );

  return (
    <>
      <FormHeader title={title} subtitle={subtitle} />
      <form
        onSubmit={(e) => {
          e.preventDefault();
        }}
        autoComplete="off"
        className={`form-horizontal ${styles.form_holder}`}
      >
        <fieldset>
          <legend className="col-md-offset-2 col-md-10">Basic details</legend>
        </fieldset>

        <InputFormField
          id="name"
          label={fieldLabels.name}
          errors={errors}
          register={register}
          autoFocus={true}
          required
          placeholder="Enter lab notebook name..."
        />

        <SelectFormField
          id="status"
          label={fieldLabels.status}
          items={LabNotebookStatusList.map((s) => {
            return { id: s, name: s.charAt(0) + s.slice(1).toLowerCase(), value: s };
          })}
          control={control}
          required
          menuPortalTarget={document.body}
        />

        <ProjectsVirtualizedSelectForm
          id="projects"
          label={fieldLabels.projects}
          control={control}
          showControls
          horizontal
          isMulti
          allowCreateEntity
          filters={{ currentUserHasAddPermission: true }}
        />

        <TextareaFormField
          id="description"
          label={fieldLabels.description}
          errors={errors}
          register={register}
          placeholder="Enter description..."
        />

        <FormButtons
          groupName={labNoteBooksConstants.frontendIndexRoute}
          entityId={initialValues ? initialValues.id : undefined}
          onClose={onCancel}
          onSubmit={handleSubmit(formValuesToEntity)}
          submitButtonLabel={submitButtonLabel || "Save changes"}
          disabled={isSubmitting}
          errors={errors}
          loading={loading}
          disableDefaultOnClose
        />
      </form>
    </>
  );
};
