import React, { CSSProperties, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import styles from "./GenericVirtualizedSelectFormField.module.css";
import { LucideIcon } from "../../icon/LucideIcon";
// import { SearchInput } from "../../forms/SearchInput/SearchInput";
import { useDebouncedValue } from "../../helperfunctions/useDebouncedValue";
import {
  EntityConstants,
  IEntityMinimalModel,
  IGenericRequestParameters,
  IOwnedEntityParameters,
  ISoftDeletable,
  IdTypes,
  StringIndexedDict,
  TypedEntitySuggestionsModel,
} from "../../../api/GenericTypes";
import { useEntityCount, useEntitySuggestions, useUnpaginate } from "../../../api/BaseEntityApi";
import { FixedSizeList } from "react-window";
import InfiniteLoader from "react-window-infinite-loader";
import { SessionContext } from "../../contexts/SessionContext";
import { OrderBySelectDropdown } from "../../buttons/OrderBySelectDropdown/OrderBySelectDropdown";
import { GlobalError, Path, PathValue } from "react-hook-form";
import { toUppercase } from "../../helperfunctions/stringFunctions";
import { FloatingDiv } from "../../buttons/FloatingDiv/FloatingDiv";
import { v4 as uuidv4 } from "uuid";
import { useResizeDetector } from "react-resize-detector";

const isEmpty = (value: any) => {
  if (value === null) return true;
  if (value === undefined) return true;
  if (Array.isArray(value)) {
    if (!value.length) return true;
    else return false;
  }
  if (value) return false;
  return true;
};

export interface FilterChildrenProps<
  SuggestionsEntity extends IEntityMinimalModel<IdTypes> & Partial<ISoftDeletable>,
  SuggestionsEntityFilters extends StringIndexedDict &
    IOwnedEntityParameters &
    IGenericRequestParameters<SuggestionsEntity, SuggestionsEntityFilters["orderBy"]>
> {
  filters: SuggestionsEntityFilters;
  onFilterChange: (filters: SuggestionsEntityFilters) => void;
}

export interface TableChildrenProps<SuggestionsEntity extends IEntityMinimalModel<IdTypes> & Partial<ISoftDeletable>> {
  onItemClick: (item: SuggestionsEntity) => void;
}

export interface GenericVirtualizedEntitySelectFormFieldProps<
  Entity extends StringIndexedDict,
  SuggestionsEntity extends IEntityMinimalModel<IdTypes> | TypedEntitySuggestionsModel<IdTypes>,
  SuggestionsEntityFilters extends StringIndexedDict &
    IOwnedEntityParameters &
    IGenericRequestParameters<SuggestionsEntity, SuggestionsEntityFilters["orderBy"]>
> {
  value: GenericVirtualizedEntitySelectFormFieldOptionsProps<
    Entity,
    SuggestionsEntity,
    SuggestionsEntityFilters
  >["value"]; // For controlled inputs via Controller
  onChange: (values: IEntityMinimalModel<IdTypes>[] | IEntityMinimalModel<IdTypes> | undefined | null) => void; // For controlled inputs via Controller
  onBlur?: () => void; // onBlur callback
  error: GlobalError; // Changes the styling if an error is defined
  placeholder?: string; // Form placeholder
  isMulti?: boolean; // Single or multi-select
  disabled?: boolean; // Disable form
  entityConstants: EntityConstants; // Entity constants
  filters?: SuggestionsEntityFilters; // Default (inital) Filters
  createModal?: ({
    showModal,
    setShowModal,
    onCreate,
  }: {
    showModal: boolean;
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
    onCreate: (entity: SuggestionsEntity) => void;
  }) => React.ReactNode; // (props) => React.ReactNode callback to render inline add modals
  unassignOnX?: boolean; // Optional prop to set value to unassigned when clicking on "x"
  allowUnassigned?: GenericVirtualizedEntitySelectFormFieldOptionsProps<
    Entity,
    SuggestionsEntity,
    SuggestionsEntityFilters
  >["allowUnassigned"]; // bool to control wether to add the unassign option
  allowCreateEntity?: GenericVirtualizedEntitySelectFormFieldOptionsProps<
    Entity,
    SuggestionsEntity,
    SuggestionsEntityFilters
  >["allowCreateEntity"]; // bool to control wether to add the add entity option
  orderByOptions?: GenericVirtualizedEntitySelectFormFieldOptionsProps<
    Entity,
    SuggestionsEntity,
    SuggestionsEntityFilters
  >["orderByOptions"]; // OrderBy options
  controls?: (props: {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    onItemClick: (item: SuggestionsEntity) => void;
    currentFilters: SuggestionsEntityFilters;
    setCurrentFilters: React.Dispatch<React.SetStateAction<SuggestionsEntityFilters>>;
    value: PathValue<Entity, Path<Entity>> | undefined;
    onChange: (values: IEntityMinimalModel<IdTypes>[] | IEntityMinimalModel<IdTypes> | undefined | null) => void; // For controlled inputs via Controller
  }) => React.ReactNode; // RHF control
  onRowRenderer?: GenericVirtualizedEntitySelectFormFieldOptionsProps<
    Entity,
    SuggestionsEntity,
    SuggestionsEntityFilters
  >["onRowRenderer"]; // optional callback to render additional content per row
  showControls?: GenericVirtualizedEntitySelectFormFieldOptionsProps<
    Entity,
    SuggestionsEntity,
    SuggestionsEntityFilters
  >["showControls"]; // bool wether to show additional controls
  concatItems?: GenericVirtualizedEntitySelectFormFieldOptionsProps<
    Entity,
    SuggestionsEntity,
    SuggestionsEntityFilters
  >["concatItems"]; // custom injected items (will be concatenated to the start)
  suggestionsIds?: GenericVirtualizedEntitySelectFormFieldOptionsProps<
    Entity,
    SuggestionsEntity,
    SuggestionsEntityFilters
  >["suggestionsIds"]; // suggested Ids will be highlighted
  selectFieldStyle?: CSSProperties; // CSS override
  overrideItemRenderer?: (item: SuggestionsEntity) => React.ReactNode; // Custom item renderer
  overrideSelectedRenderer?: (item: SuggestionsEntity) => string; // Custom selected item renderer
}
/**
 * A generic virtualized entity select form field component.
 * @author @CorradoSurmanowicz
 * @template Entity - The type of the entity.
 * @template SuggestionsEntity - The type of the suggestions entity.
 * @template SuggestionsEntityFilters - The type of the suggestions entity filters.
 *
 * @param value - The current value of the form field.
 * @param onChange - The callback function to handle value changes.
 * @param onBlur - The callback function to handle blur events.
 * @param error - The error message to display, if any.
 * @param placeholder - The placeholder text to display when no value is selected.
 * @param isMulti - A boolean indicating whether multiple values can be selected.
 * @param disabled - A boolean indicating whether the form field is disabled.
 * @param entityConstants - The constants for the entity.
 * @param unassignOnX - A boolean indicating whether unassigning should occur when the "x" button is clicked.
 * @param allowUnassigned - A boolean indicating whether unassigned values are allowed.
 * @param allowCreateEntity - A boolean indicating whether creating new entities is allowed.
 * @param filters - The filters to apply to the suggestions.
 * @param createModal - The modal component for creating new entities.
 * @param orderByOptions - The options for ordering the suggestions.
 * @param controls - The controls to display in the form field.
 * @param onRowRenderer - The custom row renderer function.
 * @param showControls - A boolean indicating whether to show the controls.
 * @param concatItems - A boolean indicating whether to concatenate the items.
 * @param suggestionsIds - The IDs of the suggestions.
 * @param selectFieldStyle - The style for the select field.
 *
 * @returns The rendered component.
 */
export const GenericVirtualizedEntitySelectFormField = <
  Entity extends StringIndexedDict,
  SuggestionsEntity extends IEntityMinimalModel<IdTypes> | TypedEntitySuggestionsModel<IdTypes>,
  SuggestionsEntityFilters extends StringIndexedDict &
    IOwnedEntityParameters &
    IGenericRequestParameters<SuggestionsEntity, SuggestionsEntityFilters["orderBy"]>
>({
  value,
  onChange,
  onBlur,
  error,
  placeholder,
  isMulti,
  disabled,
  entityConstants,
  unassignOnX,
  allowUnassigned,
  allowCreateEntity,
  filters,
  createModal,
  orderByOptions,
  controls,
  onRowRenderer,
  showControls,
  concatItems,
  suggestionsIds,
  selectFieldStyle,
  overrideItemRenderer,
  overrideSelectedRenderer,
}: GenericVirtualizedEntitySelectFormFieldProps<Entity, SuggestionsEntity, SuggestionsEntityFilters>) => {
  const [currentFilters, setCurrentFilters] = useState<SuggestionsEntityFilters>({} as SuggestionsEntityFilters);
  const [active, setActive] = useState(false);
  const [isUnassigned, setIsUnassigned] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const debouncedSearchValue = useDebouncedValue(inputValue, 300);
  const [isInputFocused, setIsInputFocused] = useState(false);
  const selectRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const { width } = useResizeDetector({ targetRef: selectRef });
  const [showCreateModal, setShowCreateModal] = useState(false);

  const onOpenChange = useCallback(
    (open: boolean) => {
      if (!disabled) {
        if (open) {
          // console.log("Open");
          setActive(true);
          setIsInputFocused(true);
        } else {
          // console.log("Close");
          setActive(false);
          setIsInputFocused(false);
          setInputValue("");
          onBlur?.();
        }
      }
    },
    [disabled, onBlur]
  );
  const memoizedFilters = useMemo(() => filters, [filters]);

  useEffect(() => {
    setCurrentFilters((prev) => ({ ...prev, ...memoizedFilters, searchTerm: debouncedSearchValue }));
  }, [debouncedSearchValue, memoizedFilters]);

  useEffect(() => {
    if (active && inputRef.current) {
      // console.log("Focusing input");
      setTimeout(() => inputRef.current?.focus(), 100);
    }
  }, [active, inputRef]);

  const onRemove = useCallback(
    (id: SuggestionsEntity["id"]) => {
      // console.log("onRemove", id);
      if (Array.isArray(value)) {
        onChange(value.filter((v: SuggestionsEntity) => v.id !== id));
      } else if (value && (value as SuggestionsEntity).id === id) {
        return onChange(undefined);
      } else {
        return onChange(undefined);
      }
    },
    [onChange, value]
  );

  useEffect(() => {
    // Some checks
    if (isMulti) {
      if (
        value &&
        Array.isArray(value) &&
        value.some((v: any) => !v || (!!v && typeof v === "object" && !Object.hasOwn(v, "id")))
      ) {
        console.error(`Invalid value: ${value} for multi select, resetting to null`);
        onChange(null);
      }
    } else {
      if (!!value && typeof value === "object" && !Object.hasOwn(value, "id")) {
        console.error(`Invalid value: ${value} for single select, resetting to null`);
        onChange(null);
      }
    }
    // Unassigned check
    if (Array.isArray(value) && value.some((i: Partial<IEntityMinimalModel>) => i?.id === -2)) {
      setIsUnassigned(true);
    } else if (value && typeof value === "object" && Object.hasOwn(value, "id") && value.id === -2) {
      setIsUnassigned(true);
    }
  }, [isMulti, onChange, value]);

  const onItemClick = useCallback(
    (item: SuggestionsEntity) => {
      if (!item.name) {
        item.name = "";
      }
      if (item.id === -1) {
        // Unassigned
        setIsUnassigned(true);
        onChange(isMulti ? [{ id: -2, name: "" }] : { id: -2, name: "" });
      } else {
        setIsUnassigned(false);
        if (isMulti) {
          let writeValue: any[] = [];
          if ((value || []).some((v: SuggestionsEntity) => v.id === item.id)) {
            writeValue = (value || []).filter((v: SuggestionsEntity) => v.id !== item.id);
          } else {
            writeValue = [...(value || []), item];
          }
          onChange(writeValue.filter((v) => v.id !== -2));
        } else {
          onChange(item);
        }
      }
    },
    [isMulti, onChange, value]
  );

  return (
    <>
      {/* Custom insert point outside of the floating div to prevent ref issues */}
      {allowCreateEntity &&
        createModal?.({ showModal: showCreateModal, setShowModal: setShowCreateModal, onCreate: onItemClick })}
      {/* Actual dropdown */}
      <FloatingDiv
        defaultOpen={!!inputValue}
        enabled={!disabled}
        disableToggle={isInputFocused}
        onOpenChange={onOpenChange}
        triggerComponent={({ ref, getReferenceProps, setOpen }) => (
          <div className={styles.selectFieldWrapper} ref={ref} {...getReferenceProps()} tabIndex={0}>
            <div
              className={`${styles.selectField} ${active ? styles.selectFieldActive : ""} ${
                error ? "border-danger react-select" : ""
              } ${disabled ? styles.selectFieldDisabled : ""}`}
              ref={selectRef}
              onClick={(e) => {
                if (!disabled) {
                  setInputValue("");
                  setActive(true);
                }
              }}
              style={selectFieldStyle}
            >
              <div className={styles.selectFieldValues}>
                <>
                  {isUnassigned && !inputValue ? (
                    <div className={styles.selectFieldOptionLabel}>
                      <LucideIcon name={"minus"} color="var(--danger)" /> Unassigned
                    </div>
                  ) : (Array.isArray(value) && !!value.length) || (value && value.id && !inputValue) ? (
                    <>
                      {[...(Array.isArray(value) ? value : [value])].map((value: SuggestionsEntity, index) => (
                        <GenericVirtualizedEntitySelectFormFieldOption<SuggestionsEntity>
                          item={value as SuggestionsEntity}
                          entityConstants={entityConstants}
                          onRemove={onRemove}
                          key={index}
                          isMulti={isMulti}
                          disabled={disabled}
                          overrideSelectedRenderer={overrideSelectedRenderer}
                        />
                      ))}
                    </>
                  ) : !active ? (
                    <span className={`${styles.selectFieldValuesPlaceholder} ${styles.EllipsisSpan}`}>
                      {placeholder ? (
                        <>
                          <LucideIcon name={entityConstants.icon} /> {placeholder}
                        </>
                      ) : (
                        <>
                          <LucideIcon name={entityConstants.icon} /> Select{" "}
                          {isMulti ? entityConstants.entityPlural : entityConstants.entitySingular}...
                        </>
                      )}
                    </span>
                  ) : (
                    <></>
                  )}
                  <>
                    {active && (
                      <div
                        className={styles.selectFieldInputContainer}
                        style={{
                          ...((!isMulti || isUnassigned) && { position: "absolute", left: 8 }),
                        }}
                      >
                        <input
                          key={uuidv4()}
                          ref={inputRef}
                          className={`${styles.selectFieldInput}`}
                          type="text"
                          autoCapitalize="none"
                          autoComplete="off"
                          autoCorrect="off"
                          spellCheck="false"
                          aria-autocomplete="list"
                          aria-expanded="false"
                          aria-haspopup="true"
                          role="combobox"
                          aria-controls="listbox"
                          aria-owns="listbox"
                          value={inputValue}
                          onChange={(e) => {
                            setInputValue(e.target.value);
                          }}
                          autoFocus={isInputFocused}
                        />
                      </div>
                    )}
                  </>
                </>
              </div>
              <div className={styles.selectFieldControls}>
                {!isEmpty(value) && (
                  <div
                    className={styles.selectFieldControlsClear}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      if (!disabled) {
                        if (unassignOnX) {
                          setIsUnassigned(true);
                          onChange(isMulti ? [{ id: -2, name: "" }] : { id: -2, name: "" });
                        } else {
                          setIsUnassigned(false);
                          onChange(null);
                        }
                      }
                    }}
                  >
                    <LucideIcon name="x" strokeWidth={"3"} />
                  </div>
                )}
                <span className={styles.selectFieldControlsSeparator} />
                <div
                  className={styles.selectFieldControlsChevron}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    if (!disabled) {
                      setOpen((prev) => !prev);
                      setActive((prev) => !prev);
                    }
                  }}
                >
                  <LucideIcon name={active ? "chevron-up" : "chevron-down"} strokeWidth={"3"} />
                </div>
              </div>
            </div>
          </div>
        )}
      >
        {({ open, setOpen }) => (
          <>
            {width && (
              <GenericVirtualizedEntitySelectFormFieldOptions<Entity, SuggestionsEntity, SuggestionsEntityFilters>
                entityConstants={entityConstants}
                currentFilters={currentFilters}
                setCurrentFilters={setCurrentFilters}
                orderByOptions={orderByOptions}
                value={value}
                onItemClick={(item) => {
                  onItemClick(item);
                  if (item.id === -1) {
                    setOpen(false);
                  } else if (!isMulti) {
                    setOpen(false);
                  }
                }}
                controls={controls?.({
                  open,
                  setOpen,
                  onItemClick,
                  currentFilters,
                  setCurrentFilters,
                  value,
                  onChange,
                })}
                onRowRenderer={onRowRenderer}
                style={{ width: width }}
                onToggleDropdown={() => {
                  setOpen(false);
                }}
                toggleCreateModal={() => {
                  setOpen(false);
                  setShowCreateModal((prev) => !prev);
                }}
                allowCreateEntity={allowCreateEntity}
                allowUnassigned={allowUnassigned}
                showControls={showControls}
                isUnassigned={isUnassigned}
                concatItems={concatItems}
                suggestionsIds={suggestionsIds}
                overrideItemRenderer={overrideItemRenderer}
              />
            )}
          </>
        )}
      </FloatingDiv>
    </>
  );
};

interface GenericVirtualizedEntitySelectFormFieldOptionsProps<
  Entity extends StringIndexedDict,
  SuggestionsEntity extends (IEntityMinimalModel<IdTypes> | TypedEntitySuggestionsModel<IdTypes>) &
    Partial<ISoftDeletable>,
  Filters extends StringIndexedDict &
    IOwnedEntityParameters &
    IGenericRequestParameters<SuggestionsEntity, Filters["orderBy"]>
> {
  entityConstants: EntityConstants;
  currentFilters: Filters;
  setCurrentFilters: React.Dispatch<React.SetStateAction<Filters>>;
  orderByOptions?: readonly Filters["orderBy"][];
  // selection: Map<Entity["id"], Entity>;
  value: PathValue<Entity, Path<Entity>> | undefined;
  onItemClick: (item: SuggestionsEntity) => void;
  controls?: React.ReactNode;
  onRowRenderer?: (item: SuggestionsEntity) => React.ReactNode;
  onToggleDropdown: () => void;
  toggleCreateModal: () => void;
  allowCreateEntity?: boolean;
  allowUnassigned?: boolean;
  showControls?: boolean;
  isUnassigned: boolean;
  concatItems?: SuggestionsEntity[];
  suggestionsIds?: SuggestionsEntity["id"][];
  style?: CSSProperties;
  overrideItemRenderer?: (item: SuggestionsEntity) => React.ReactNode;
}
const GenericVirtualizedEntitySelectFormFieldOptions = <
  Entity extends StringIndexedDict,
  SuggestionsEntity extends (IEntityMinimalModel<IdTypes> | TypedEntitySuggestionsModel<IdTypes>) &
    Partial<ISoftDeletable>,
  Filters extends StringIndexedDict &
    IOwnedEntityParameters &
    IGenericRequestParameters<SuggestionsEntity, Filters["orderBy"]>
>({
  entityConstants,
  currentFilters,
  setCurrentFilters,
  orderByOptions,
  value,
  onItemClick,
  controls,
  onRowRenderer,
  onToggleDropdown,
  toggleCreateModal,
  allowCreateEntity,
  allowUnassigned,
  showControls = false,
  isUnassigned,
  concatItems,
  suggestionsIds,
  style,
  overrideItemRenderer,
}: GenericVirtualizedEntitySelectFormFieldOptionsProps<Entity, SuggestionsEntity, Filters>) => {
  const { session } = useContext(SessionContext);
  // const [searchValue, setSearchValue] = useState("");
  const [items, setItems] = useState<SuggestionsEntity[]>([]);
  const [count, setCount] = useState<number>();
  // const debouncedSearchValue = useDebouncedValue(searchValue);
  const pageSize = allowUnassigned && allowCreateEntity ? 8 : allowUnassigned || allowCreateEntity ? 9 : 10;

  const { data, fetchNextPage, hasNextPage, isFetching } = useEntitySuggestions<SuggestionsEntity, Filters>(
    entityConstants.resource,
    {
      page: 1,
      pageSize: pageSize,
      includeCount: false,

      excludeIds: suggestionsIds,
      ...currentFilters,
      // ...(debouncedSearchValue && { searchTerm: debouncedSearchValue }),
    },
    { enabled: true }
  );

  const { data: queryCount } = useEntityCount<Filters>(
    entityConstants.resource,
    {
      ...currentFilters,
      // ...(debouncedSearchValue && { searchTerm: debouncedSearchValue }),

      includeCount: true,
    },
    { enabled: true && showControls }
  );

  // Suggested items
  const { data: suggestedItems } = useUnpaginate<SuggestionsEntity, Filters>(
    entityConstants.resource,
    {
      ids: suggestionsIds,
      includeCount: false,
    } as Filters,
    { enabled: Array.isArray(suggestionsIds) && !!suggestionsIds.length }
  );

  useEffect(() => {
    setCount(queryCount?.count);
  }, [queryCount]);

  useEffect(() => {
    let _items: SuggestionsEntity[] = [];
    if (concatItems) {
      _items = [...concatItems];
    }
    if (Array.isArray(suggestionsIds) && !!suggestionsIds.length) {
      if (data && suggestedItems) {
        // Append suggested items to the start
        _items = [..._items, ...Object.values(suggestedItems), ...data.pages.map((d) => d.results).flat()];
        // setItems((prev) => [...Object.values(suggestedItems), ...data.pages.map((d) => d.results).flat()]);
      }
    } else if (data) {
      _items = [..._items, ...data.pages.map((d) => d.results).flat()];
      // setItems(data.pages.map((d) => d.results).flat());
    }
    setItems(_items);
    return () => {
      setItems([]);
    };
  }, [concatItems, data, suggestedItems, suggestionsIds]);

  const fetchNext = useCallback(() => {
    if (hasNextPage) {
      fetchNextPage();
    }
  }, [fetchNextPage, hasNextPage]);

  const hasSelected = useCallback(
    (
      value: IEntityMinimalModel<IdTypes> | IEntityMinimalModel<IdTypes>[] | null | undefined,
      id: SuggestionsEntity["id"]
    ) => {
      if (Array.isArray(value)) {
        return value.some((v) => v.id === id);
      } else if (value) {
        return value.id === id;
      } else {
        return false;
      }
    },
    []
  );

  const loadMoreCallback = useCallback(() => {}, []);
  const itemCount = hasNextPage ? items.length + 1 : items.length;
  const loadMoreItems = isFetching ? loadMoreCallback : fetchNext;
  const isItemLoaded = useCallback((index: number) => index < items.length, [items]);
  const Item = ({ index, style }: { index: number; style: CSSProperties }) => {
    if (!isItemLoaded(index)) {
      return (
        <div style={{ ...style }} key={index}>
          <span className="skeleton-block" />
        </div>
      );
    }
    const item = items[index];
    const isSelected = hasSelected(value, item.id);
    return (
      <div
        className={`${styles.selectFieldOptionsValuesValue} ${
          isSelected ? styles.selectFieldOptionsValuesValueSelected : ""
        }`}
        onClick={() => {
          onItemClick(item);
        }}
        style={{ ...style }}
        title={`${toUppercase(entityConstants.entitySingular)}-ID: ${item.id}`}
        key={index}
        tabIndex={index}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            onItemClick(item);
          }
        }}
      >
        <></>
        {overrideItemRenderer?.(item) ?? (
          <div className="flex row-nowrap align-center gap-5" style={{ flex: "1 0 auto" }}>
            <span className={styles.selectFieldOptionsValuesValueLabel}>
              <LucideIcon name={entityConstants.icon} color={"var(--primary)"} /> {item.name}
            </span>

            {item.isDeleted && (
              <label className="label label-soft-warning">
                <LucideIcon name="trash" />
              </label>
            )}
            {onRowRenderer?.(item)}
          </div>
        )}
        {(item as TypedEntitySuggestionsModel<IdTypes>).customType && (
          <div
            style={{
              color: "var(--gray-400)",
              marginLeft: "auto",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              overflow: "hidden",
              background: "var(--gray-100)",
              padding: "2.5px 5px",
              borderRadius: "2.5px",
              fontSize: "75%",
            }}
            title={(item as TypedEntitySuggestionsModel<IdTypes>).customType?.name}
          >
            {(item as TypedEntitySuggestionsModel<IdTypes>).customType?.name ||
              `Basic ${entityConstants.entitySingular}`}
          </div>
        )}
        {!!item.id && +item.id > 0 && <div className={styles.selectFieldOptionsValuesValueId}>ID: {item.id}</div>}
        {suggestionsIds?.includes(item.id) && (
          <div className="flex" style={{ marginLeft: "auto" }}>
            <LucideIcon name="star" color={"var(--success)"} />
          </div>
        )}
      </div>
    );
  };

  return (
    <div className={styles.selectFieldOptions} style={style}>
      {showControls && (
        <div className={styles.selectFieldOptionsControls}>
          {/* <TableViewLabel entityConstants={entityConstants} resultsCount={count} /> */}
          {count !== undefined && (
            <div className="flex row-nowrap align-center gap-5" style={{ padding: "0px 5px" }}>
              <span className="badge">{count}</span>
              <span style={{ color: "var(--gray-500)", fontWeight: 500 }}>
                {count === 1 ? toUppercase(entityConstants.entitySingular) : toUppercase(entityConstants.entityPlural)}{" "}
              </span>
            </div>
          )}
          <div
            className="btn-group"
            role="group"
            aria-label="Presets"
            style={{ display: "flex", flexFlow: "row nowrap" }}
          >
            <button
              type="button"
              className={`btn btn-sm btn-default ${!Object.keys(currentFilters).includes("ownerIds") && "active"}`}
              onClick={() => {
                setCurrentFilters(
                  (prev) => Object.fromEntries(Object.entries(prev).filter(([key]) => key !== "ownerIds")) as Filters
                );
              }}
              title="All"
            >
              <LucideIcon name="house" />
            </button>

            <button
              type="button"
              className={`btn btn-sm btn-default ${Object.keys(currentFilters).includes("ownerIds") && "active"}`}
              onClick={() => {
                setCurrentFilters((prev) => ({ ...prev, ownerIds: [session?.person.id] }));
              }}
              title={`My ${entityConstants.entityPlural}`}
            >
              <LucideIcon name="user" />
            </button>
          </div>

          {orderByOptions && (
            <OrderBySelectDropdown<SuggestionsEntity, Filters>
              btnCls="btn btn-sm btn-default"
              orderByOptions={orderByOptions}
              currentFilters={currentFilters}
              setCurrentFilters={setCurrentFilters}
            />
          )}

          {controls}

          <div className="flex row-nowrap align-center gap-5" style={{ marginLeft: "auto" }}>
            <button className="btn btn-sm btn-ghost-secondary" title="Close" onClick={onToggleDropdown}>
              <LucideIcon name="x" />
            </button>
          </div>
        </div>
      )}
      <div className={`${styles.selectFieldOptionsValues} ${isFetching ? styles.selectFieldOptionsValuesloading : ""}`}>
        {/* <LoadingWrapper status={status} error={error}> */}
        {!!items.length ? (
          <InfiniteLoader isItemLoaded={isItemLoaded} itemCount={itemCount} loadMoreItems={loadMoreItems as any}>
            {({ onItemsRendered, ref }) => (
              <FixedSizeList
                itemCount={itemCount}
                onItemsRendered={onItemsRendered}
                ref={ref}
                width="100%"
                height={items.length > pageSize ? pageSize * 30 : items.length * 30}
                itemSize={30}
              >
                {Item}
              </FixedSizeList>
            )}
          </InfiniteLoader>
        ) : (
          <>
            {isFetching ? (
              <span
                className="flex align-center justify-center skeleton-block third"
                style={{ width: "100%", height: "30px" }}
              >
                Loading...
              </span>
            ) : (
              <>
                {
                  // currentFilters.searchTerm && currentFilters.searchTerm.length <= 3 ? (
                  //   <SearchTermError />
                  // ) :
                  currentFilters.searchTerm ? (
                    <NoResults message={`No options for: "${currentFilters.searchTerm}"`} />
                  ) : (
                    <NoResults />
                  )
                }
              </>
            )}
          </>
        )}
        {/* </LoadingWrapper> */}
        {allowCreateEntity && (
          <div className={`${styles.selectFieldOptionsValuesValueCreate}`} onClick={toggleCreateModal}>
            <LucideIcon name="plus" /> Add {entityConstants.entitySingular}
          </div>
        )}
        {allowUnassigned && (
          <div
            className={`${styles.selectFieldOptionsValuesValueUnassigned} ${
              isUnassigned ? styles.selectFieldOptionsValuesValueUnassignedSelected : ""
            }`}
            onClick={() => onItemClick({ id: -1, name: "" } as SuggestionsEntity)}
          >
            <LucideIcon name="minus" /> Unassign
          </div>
        )}
      </div>
    </div>
  );
};

const NoResults = ({ message }: { message?: string }) => (
  <div className={`flex row-nowrap align-center justify-center ${styles.NoResultsContainer}`}>
    <span className={styles.EllipsisSpan}>{message ? message : "No options"}</span>
  </div>
);

interface GenericVirtualizedEntitySelectFormFieldOptionProps<
  SuggestionsEntity extends IEntityMinimalModel<IdTypes> & Partial<ISoftDeletable>
> {
  entityConstants: EntityConstants;
  item: SuggestionsEntity;
  onRemove: (id: SuggestionsEntity["id"]) => void;
  isMulti?: boolean;
  disabled?: boolean;
  overrideSelectedRenderer?: (item: SuggestionsEntity) => string;
}
const GenericVirtualizedEntitySelectFormFieldOption = <
  SuggestionsEntity extends IEntityMinimalModel<IdTypes> & Partial<ISoftDeletable>
>({
  entityConstants,
  item,
  onRemove,
  isMulti,
  disabled,
  overrideSelectedRenderer,
}: GenericVirtualizedEntitySelectFormFieldOptionProps<SuggestionsEntity>) => {
  if (isMulti) {
    return (
      <div className={styles.selectFieldOption}>
        <div
          className={`${styles.selectFieldOptionLabel} ${disabled ? styles.selectFieldOptionLabelDisabled : ""}`}
          title={`${toUppercase(entityConstants.entitySingular)}-ID: ${item.id}`}
        >
          <LucideIcon name={entityConstants.icon} style={{ flexShrink: 0 }} />{" "}
          <div className={"ellipsisContainer"}>
            <span>{overrideSelectedRenderer?.(item) ?? item.name}</span>
          </div>
        </div>
        {!disabled && (
          <div
            className={`${styles.selectFieldOptionControls}`}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              onRemove(item.id);
            }}
          >
            <LucideIcon name="x" strokeWidth={"3"} />
          </div>
        )}
      </div>
    );
  } else {
    return (
      <div
        className={styles.selectFieldOptionLabel}
        title={`${toUppercase(entityConstants.entitySingular)}-ID: ${item.id}`}
      >
        <div>
          <LucideIcon name={entityConstants.icon} color="var(--primary)" style={{ flexShrink: 0 }} />
        </div>
        {overrideSelectedRenderer?.(item) ?? (
          <div className={"ellipsisContainer"}>
            <span>{overrideSelectedRenderer?.(item) ?? item.name}</span>
          </div>
        )}
      </div>
    );
  }
};
