import { useQuery } from "@tanstack/react-query";
import { API } from "../api/Api";
import { SessionContext } from "../common/contexts/SessionContext";
import { LoadingWrapper } from "../common/LoadingWrapper";

export const ConductanceIntegrationSessionContextProvider = ({
  api,
  group,
  children,
}: {
  api: API;
  group: string;
  children: React.ReactNode;
}) => {
  const session = useQuery({ queryKey: ["session"], queryFn: async ({ signal }) => await api.getSession() });

  return (
    <LoadingWrapper status={session.status} fetchStatus={session.fetchStatus}>
      <SessionContext.Provider
        value={{ api, session: session.data!, group: group, clearSession: () => {}, route: (x: string) => x }}
      >
        {children}
      </SessionContext.Provider>
    </LoadingWrapper>
  );
};
