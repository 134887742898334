import { useCallback } from "react";
// A generic hook that can be used to block execution until condition is met
// Usage e.g.:
// await waitUntil(() => condition === true);

export const useBlockUntil = () => {
  const waitUntil = useCallback((conditionFunction: () => boolean) => {
    const poll = (resolve: Function) => {
      if (conditionFunction()) resolve();
      else setTimeout((_) => poll(resolve), 400);
    };

    return new Promise(poll);
  }, []);

  return waitUntil;
};
