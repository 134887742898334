import React, { useEffect, useState } from "react";
// import { ReactComponent as FIDAnimation } from "./fid.svg";
import { ReactComponent as BrandAnimation } from "./brand_loader.svg";
import styles from "./styles.module.css";

/**
 * This component returns an animated spinner
 * Author: CS
 * @param: size (pixel), progressing, bsStyle, wait (delay in ms)
 * @returns: JSX.Element
 */

interface Props {
  size?: React.CSSProperties["width"];
  wait?: number;
  style?: React.CSSProperties;
}
const BrandLoader = ({ size, wait = 200, style }: Props) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShow(true);
    }, wait);
    return () => clearTimeout(timer);
  }, [wait]);

  return show ? (
    <div className={styles.container} style={{ width: size, height: "auto" }}>
      <div className={styles.spinner_flex}>
        <div className={styles.spinner} style={{ width: size, height: "auto" }}>
          {show && <BrandAnimation style={style} />}
        </div>
      </div>
    </div>
  ) : null;
};

export default BrandLoader;
