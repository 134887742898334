import { CSSProperties, useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { AnnouncementContentType, AnnouncementType } from "../api/Announcements";
import { DetailsPageTopControlsView } from "../common/panels/misc/DetailsPageTopControlsView/DetailsPageTopControlsView";
import { SessionContext } from "../common/contexts/SessionContext";
import { LucideIcon } from "../common/icon/LucideIcon";
import { NotSet } from "../common/misc/UIconstants";
import { AlertModal } from "../common/modals/AlertModal/AlertModal";
import { showtoast } from "../common/overlays/Toasts/showtoast";
import { Table } from "../common/panels/Detail/DetailTable";
import { MoreDropdown } from "../common/buttons/MoreDropdown/MoreDropdown";
import { DateTimeRenderer } from "../common/datetime/DateTimeFormatter";
import { EntityDetailProps, EntityDetailTableProps } from "../common/entity/EntityInterfaces";
import Markdown from "markdown-to-jsx";
import { DefaultMarkdownToJsxOptions } from "../ELN/common/TextEditor/components/TextEditorToolbar/TextEditorToolbar";
import { ToggleButtonComponent } from "../ViewerUIElements/ToggleButtonComponent";
import { toUppercase } from "../common/helperfunctions/stringFunctions";

export const AnnouncementTypeLabel = ({
  type,
  opacity = "soft-",
}: {
  type?: AnnouncementType;
  opacity?: "soft-" | "ghost-" | "";
}) => {
  switch (type) {
    case "regular":
      return <span className={`label label-${opacity}primary`}>Info</span>;
    case "warning":
      return <span className={`label label-${opacity}warning`}>{toUppercase(type)}</span>;
    case "urgent":
      return <span className={`label label-${opacity}danger`}>{toUppercase(type)}</span>;

    default:
      return NotSet;
  }
};

export const AnnouncementContentTypeField = ({
  contentType,
  message,
  style,
}: {
  contentType?: AnnouncementContentType;
  message: string;
  style?: CSSProperties;
}) => {
  switch (contentType) {
    case "markdown":
      return <AnnouncementMarkdownPreview message={message} style={style} />;

    default:
      return (
        <div
          className="form form-control"
          style={{
            fontFamily: "inherit",
            fontSize: "1em",
            width: "100%",
            height: "max-content",
            maxHeight: "100%",
            overflow: "auto",
            backgroundColor: "var(--gray-50)",
            whiteSpace: "pre-wrap",
            ...style,
          }}
        >
          {message}
        </div>
      );
  }
};

export const AnnouncementMarkdownPreview = ({ message, style }: { message: string; style?: CSSProperties }) => {
  return (
    <div
      className="form form-control"
      style={{
        fontFamily: "inherit",
        fontSize: "1em",
        width: "100%",
        height: "max-content",
        maxHeight: "100%",
        overflow: "auto",
        backgroundColor: "var(--gray-50)",
        whiteSpace: "pre-wrap",
        ...style,
      }}
    >
      <Markdown options={DefaultMarkdownToJsxOptions}>{message}</Markdown>
    </div>
  );
};

export const AnnouncementDetailTable = ({
  entity,
  entityConstants,
  fieldLabels,
  permissions,
  routes,
}: EntityDetailTableProps<"announcements">) => {
  const { session } = useContext(SessionContext);
  return (
    <Table noPadding nonFoldable>
      <Table.Head>Basic details</Table.Head>
      <Table.Body>
        <Table.Body.RowContent title={fieldLabels.type} content={<AnnouncementTypeLabel type={entity.type} />} />

        <Table.Body.RowContent
          title={fieldLabels.startDate}
          content={entity.startDate ? <DateTimeRenderer date={entity.startDate} includeElapsed={false} /> : NotSet}
        />

        <Table.Body.RowContent
          title={fieldLabels.endDate}
          content={entity.endDate ? <DateTimeRenderer date={entity.endDate} includeElapsed={false} /> : NotSet}
        />

        {!!session?.permissions.logsadmin && (
          <Table.Body.RowContent
            title={fieldLabels.isSystemAnnouncement}
            content={
              <ToggleButtonComponent checked={entity.isSystemAnnouncement} setChecked={() => {}}>
                <></>
              </ToggleButtonComponent>
            }
          />
        )}

        <Table.Body.RowContent
          title={fieldLabels.contentType}
          content={toUppercase(entity.contentType.replaceAll("_", " "))}
        />

        <Table.Body.RowContent
          title={fieldLabels.message}
          content={
            <div style={{ maxHeight: "50vh", width: "100%" }}>
              <AnnouncementContentTypeField contentType={entity.contentType} message={entity.message} />
            </div>
          }
        />
      </Table.Body>
    </Table>
  );
};

export const AnnouncementsDetail = ({
  entity,
  entityApi,
  entityConstants,
  fieldLabels,
  permissions,
  routes,
}: EntityDetailProps<"announcements">) => {
  let history = useHistory();
  const [showModal, setShowModal] = useState<boolean>(false);
  const { deleteMutationAsync } = entityApi;
  return (
    <div className="flex" style={{ width: "100%", height: "100%", overflow: "hidden" }}>
      <div
        className="flex col-nowrap"
        style={{ width: "100%", height: "100%", padding: "15px 15px 10px 15px", overflow: "auto" }}
      >
        <DetailsPageTopControlsView
          entity={entity}
          nameOverride={entity.type}
          entityConstants={entityConstants}
          controls={
            <div className="flex align-center gap-5">
              <button
                className="btn btn-default"
                onClick={() => history.push(routes.getEditLink(entity.id))}
                disabled={!permissions.canEdit(entity)}
              >
                <LucideIcon name="square-pen" />
                <span> Edit</span>
              </button>
              <MoreDropdown drop="right" btn="btn btn-ghost-secondary">
                <li>
                  <button
                    className="btn btn-sm btn-ghost-secondary"
                    title={`Clone this Announcement`}
                    onClick={() => history.push(routes.getCloneLink(entity.id))}
                    disabled={!permissions.canCreate}
                  >
                    <LucideIcon name="copy" /> Clone
                  </button>
                </li>
                <li>
                  <button
                    className="btn btn-sm btn-ghost-danger"
                    title="Delete"
                    onClick={() => setShowModal(true)}
                    disabled={!permissions.canDelete(entity)}
                  >
                    <LucideIcon name="trash-2" /> Delete
                  </button>
                </li>
              </MoreDropdown>
              <AlertModal
                type="danger"
                showModal={showModal}
                setShowModal={setShowModal}
                title="Permanently delete person?"
                description={""}
                proceedLabel="Delete"
                onProceed={async () => {
                  await deleteMutationAsync(
                    {
                      id: entity.id,
                      goBackOnSuccess: false,
                      showToast: false,
                      entityName: "announcement",
                      params: { deletePermanently: true },
                    },

                    {
                      onSuccess: () => {
                        showtoast("success", `Deleted Announcement-ID: ${entity.id}.`);
                        history.goBack();
                      },
                    }
                  ).catch((e) => {});
                }}
                loading={entityApi.isLoadingDeleteMutation}
              />
            </div>
          }
        />

        <AnnouncementDetailTable
          entity={entity}
          entityConstants={entityConstants}
          fieldLabels={fieldLabels}
          permissions={permissions}
          routes={routes}
        />
      </div>
    </div>
  );
};
