import { Button } from "react-bootstrap";
import styles from "./DebugButtons.module.css";

export const DebugButtons = ({
  debugMode,
  onReparse,
  onTest,
  onParserOutput,
  onViewerLog,
}: {
  debugMode: boolean;
  onReparse?: () => void;
  onTest?: () => void;
  onParserOutput?: () => void;
  onViewerLog?: () => void;
}) => {
  return (
    <>
      {debugMode ? (
        <div className={styles.reparse}>
          {onTest ? (
            <Button bsSize="xs" bsStyle="info" onClick={onTest}>
              Test
            </Button>
          ) : null}
          {onViewerLog ? (
            <Button bsSize="xs" bsStyle="warning" onClick={onViewerLog}>
              Show logs
            </Button>
          ) : null}
          {onParserOutput ? (
            <Button bsSize="xs" bsStyle="success" onClick={onParserOutput}>
              Show output
            </Button>
          ) : null}
          {onReparse ? (
            <Button bsSize="xs" bsStyle="info" onClick={onReparse}>
              Reparse
            </Button>
          ) : null}
        </div>
      ) : null}
    </>
  );
};
