import React from "react";
import styles from "./CustomTypeBuilder.module.css";
import { AbstractedCustomFieldDataTypesConsts } from "../CustomFields/CustomFieldUtils";
import { abstractedDataTypeToIconName, RenderCustomFieldDraggable } from "../CustomFields/CustomFieldRenderUtils";
import { DragContainer } from "./CustomTypeRenderUtils";
import { CustomFieldsTile } from "../../common/sidebar/tiles/CustomFieldsTile";
import { CustomFieldForm } from "../CustomFields/CustomFieldsForm";
import { CustomField } from "../../api/CustomFields";

interface CustomTypeBuilderSidebarProps {
  disabledItems?: CustomField["id"][];
}
export const CustomTypeBuilderSidebar = ({ disabledItems }: CustomTypeBuilderSidebarProps) => {
  return (
    <div className={styles.sidebarContainer}>
      <div className={styles.sidebarNewCustomField}>
        <h3 style={{ color: "var(--gray-400)" }}>Create new custom field</h3>
        <div className={styles.sidebarNewCustomFieldContainer}>
          {AbstractedCustomFieldDataTypesConsts.map((dataType, index) => (
            <DragContainer<Partial<CustomFieldForm>>
              dataGrid={{ abstractedDataType: dataType }}
              key={index}
              style={{ width: "100%", height: "100%" }}
            >
              <RenderCustomFieldDraggable dataType={dataType} />
            </DragContainer>
          ))}
        </div>
      </div>
      <div className={styles.sidebarExistingCustomField}>
        <h3 style={{ color: "var(--gray-400)" }}>Use existing custom fields</h3>
        <div className={styles.sidebarCustomFieldContainer}>
          <CustomFieldsTile
            title="Text"
            defaultFilters={{ dataTypes: ["String", "StringArray"] }}
            headerIconOverride={abstractedDataTypeToIconName("Text")}
            disabledItems={disabledItems ? disabledItems : undefined}
            isFolded
            foldable
            draggable
          />
          <CustomFieldsTile
            title="Number"
            defaultFilters={{ dataTypes: ["Integer", "IntegerArray", "Float", "FloatArray"] }}
            headerIconOverride={abstractedDataTypeToIconName("Number")}
            disabledItems={disabledItems ? disabledItems : undefined}
            isFolded
            foldable
            draggable
          />
          <CustomFieldsTile
            title="Toggle"
            defaultFilters={{ dataTypes: ["Boolean"] }}
            headerIconOverride={abstractedDataTypeToIconName("Toggle")}
            disabledItems={disabledItems ? disabledItems : undefined}
            isFolded
            foldable
            draggable
          />
          <CustomFieldsTile
            title="Date"
            defaultFilters={{
              dataTypes: ["Date", "DateArray", "DateTime", "DateTimeArray", "DateTimeRange"],
            }}
            headerIconOverride={abstractedDataTypeToIconName("Date")}
            disabledItems={disabledItems ? disabledItems : undefined}
            isFolded
            foldable
            draggable
          />
          <CustomFieldsTile
            title="Time"
            defaultFilters={{
              dataTypes: ["Time", "TimeArray", "TimeRange"],
            }}
            headerIconOverride={abstractedDataTypeToIconName("Time")}
            disabledItems={disabledItems ? disabledItems : undefined}
            isFolded
            foldable
            draggable
          />
          <CustomFieldsTile
            title="Entity Reference"
            defaultFilters={{
              dataTypes: [
                "Dataset",
                "DatasetArray",
                "Sample",
                "SampleArray",
                "Project",
                "ProjectArray",
                "Organization",
                "OrganizationArray",
                "Person",
                "PersonArray",
                "Method",
                "MethodArray",
                "Instrument",
                "InstrumentArray",
                "SharedContent",
                "SharedContentArray",
                "LabNotebook",
                "LabNotebookArray",
                "LabNotebookExperiment",
                "LabNotebookExperimentArray",
                "LabNotebookEntry",
                "LabNotebookEntryArray",
                "Inventory",
                "InventoryArray",
              ],
            }}
            headerIconOverride={abstractedDataTypeToIconName("Entity Reference")}
            disabledItems={disabledItems ? disabledItems : undefined}
            isFolded
            foldable
            draggable
          />
          <CustomFieldsTile
            title="Entity Barcode"
            defaultFilters={{
              dataTypes: ["EntityBarCode"],
            }}
            headerIconOverride={abstractedDataTypeToIconName("Entity Barcode")}
            disabledItems={disabledItems ? disabledItems : undefined}
            isFolded
            foldable
            draggable
          />
          <CustomFieldsTile
            title="Attachment"
            defaultFilters={{
              dataTypes: ["ViewableEntity"],
            }}
            headerIconOverride={abstractedDataTypeToIconName("Attachment")}
            disabledItems={disabledItems ? disabledItems : undefined}
            isFolded
            foldable
            draggable
          />
          <CustomFieldsTile
            title="Url"
            defaultFilters={{
              dataTypes: ["Url", "UrlArray"],
            }}
            headerIconOverride={abstractedDataTypeToIconName("URL")}
            disabledItems={disabledItems ? disabledItems : undefined}
            isFolded
            foldable
            draggable
          />
        </div>
      </div>
    </div>
  );
};
