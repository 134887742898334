import { StringIndexedDict } from "../../../../api/GenericTypes";
import {
  GenericEntityVirtualizedEntitySelectFormFieldProps,
  GenericVirtualizedEntitySelectFormFieldControllerWrapper,
} from "../../../formfields/GenericVirtualizedSelectFormField/GenericVirtualizedEntitySelectFormFieldControllerWrapper";
import { QuickAddWrapper } from "../../QuickAdd/common/QuickAddWrapper";
import {
  MethodFilters,
  MethodSortingOptionsConsts,
  MethodSuggestions,
  methodsConstants,
} from "../../../../api/Methods";
import { QuickAddMethodForm } from "../../QuickAdd/forms/QuickAddMethodForm";
import { useContext } from "react";
import { SessionContext } from "../../../contexts/SessionContext";

export const MethodsVirtualizedSelectForm = <Entity extends StringIndexedDict>(
  props: GenericEntityVirtualizedEntitySelectFormFieldProps<Entity, MethodSuggestions, MethodFilters>
) => {
  const { session } = useContext(SessionContext);

  return (
    <GenericVirtualizedEntitySelectFormFieldControllerWrapper<Entity, MethodSuggestions, MethodFilters>
      {...props}
      filters={{ orderBy: "NAME_ASC", ...props.filters }}
      //   onRowRenderer={(item) => <></>}
      createModal={({ showModal, setShowModal, onCreate }) => (
        <QuickAddWrapper showModal={showModal} setShowModal={setShowModal} onCreate={onCreate}>
          {(quickAddProps) => <QuickAddMethodForm {...quickAddProps} />}
        </QuickAddWrapper>
      )}
      entityConstants={methodsConstants}
      orderByOptions={MethodSortingOptionsConsts}
      allowCreateEntity={session?.permissions.administer_measurement_methods && props.allowCreateEntity}
    />
  );
};
