import { CSSProperties } from "react";

import styles from "./ToggleButtonComponent.module.css";

export const ToggleButtonComponent = ({
  checked,
  setChecked,
  style,
  children,
}: {
  checked: boolean;
  setChecked?: (checked: boolean) => void;
  style?: CSSProperties;
  children: any;
}) => {
  // const [checked, setChecked] = useState<boolean>(props.checked === undefined ? false : props.checked);

  // console.log(">", props.value);

  return (
    <div className={styles.main} style={style}>
      <label className={styles.switch} style={{ margin: 0 }}>
        <input
          type="checkbox"
          checked={checked}
          onChange={(e) => {
            // console.log("Test", e.target.value);
            if (setChecked) setChecked(!checked);
            // setSelected(!selected);
          }}
        />
        <span className={styles.slider + " " + styles.round}></span>
      </label>
      <div
        style={{ paddingLeft: 3 }}
        onClick={() => {
          if (setChecked) setChecked(!checked);
        }}
      >
        {children}
      </div>
    </div>
  );
};
