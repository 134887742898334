import Status from "../Status/Status";

const SETS = {
  "on/off": ["on", "off"],
  "yes/no": ["yes", "no"],
} as const;
type LabelSet = keyof typeof SETS;
export const ToggleIndicator = ({ enabled, labelSet = "on/off" }: { enabled: boolean; labelSet?: LabelSet }) => {
  return (
    <span className="badge">
      <Status type={enabled ? "success" : "danger"}>{enabled ? SETS[labelSet][0] : SETS[labelSet][1]}</Status>
    </span>
  );
};
