import { ProjectForm } from "./ProjectForm";
import { EntityAddForm, EntityAddFormPage } from "../common/entity/EntityAddForm";
import { EntityCloneForm, EntityCloneFormPage } from "../common/entity/EntityCloneForm";
import {
  CustomTypeEntityAddFormProps,
  EntityCloneFormProps,
  EntityDetailViewProps,
  EntityEditFormProps,
} from "../common/entity/EntityInterfaces";
import { EntityEditForm, EntityEditFormPage } from "../common/entity/EntityEditForm";
import { EntityDetailPage, EntityDetailView } from "../common/entity/EntityDetailPage";
import { ProjectsDetail } from "./ProjectsDetail";
import { useLocation } from "react-router-dom";
import { EntityTablePage } from "../common/entity/EntityTablePage";
import { ProjectsTable } from "./Table/ProjectsTable";

// Add
export const ProjectAddForm = ({ typeId }: CustomTypeEntityAddFormProps) => {
  return (
    <EntityAddForm entityTypeId={"projects"}>{(props) => <ProjectForm {...props} typeId={typeId} />}</EntityAddForm>
  );
};

export const ProjectAddFormPage = () => {
  const typeId = new URLSearchParams(useLocation().search).get("type");

  return (
    <EntityAddFormPage>
      <ProjectAddForm typeId={typeId ? +typeId : null} />
    </EntityAddFormPage>
  );
};

// Clone
export const ProjectCloneForm = ({ id }: EntityCloneFormProps<"projects">) => {
  return (
    <EntityCloneForm id={id} entityTypeId={"projects"}>
      {(props) => <ProjectForm {...props} typeId={props.initialValues?.customType?.id} />}
    </EntityCloneForm>
  );
};

export const ProjectCloneFormPage = () => {
  return <EntityCloneFormPage<"projects"> isIntId>{(id) => <ProjectCloneForm id={id} />}</EntityCloneFormPage>;
};

// Edit
export const ProjectEditForm = ({ id }: EntityEditFormProps<"projects">) => {
  return (
    <EntityEditForm id={id} entityTypeId={"projects"} filters={{}}>
      {(props) => <ProjectForm {...props} typeId={props.initialValues?.customType?.id} />}
    </EntityEditForm>
  );
};

export const ProjectEditFormPage = () => {
  return <EntityEditFormPage<"projects"> isIntId>{(id) => <ProjectEditForm id={id} />}</EntityEditFormPage>;
};

// Detail
export const ProjectsDetailView = ({ id }: EntityDetailViewProps<"projects">) => {
  return (
    <EntityDetailView id={id} entityTypeId={"projects"} filters={{}}>
      {(props) => <ProjectsDetail {...props} />}
    </EntityDetailView>
  );
};

export const ProjectDetailsPage = () => {
  return <EntityDetailPage<"projects"> isIntId>{(id) => <ProjectsDetailView id={id} />}</EntityDetailPage>;
};

export const ProjectsTablePage = () => {
  return <EntityTablePage entityTypeId={"projects"}>{(props) => <ProjectsTable {...props} />}</EntityTablePage>;
};
