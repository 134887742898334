import {
  ICreatedRecordParametersTranslator,
  ICreationRecordLabels,
  IGenericRequestParametersTranslator,
  IModificationRecordLabels,
  IModifiedRecordParametersTranslator,
  INamedEntityLabels,
  IPaginationParametersTranslator,
  IPermissionedEntityLabels,
  IProjectBasedParametersTranslator,
  ISoftDeletableFilterParametersTranslator,
  ISoftDeletableLabels,
  PropertyTranslator,
} from "../../api/GenericTranslator";
import {
  ICreationRecord,
  ICreatedRecordParameters,
  IGenericRequestParameters,
  IEntityMinimalModel,
  IModelWithIntId,
  IModificationRecord,
  IModifiedRecordParameters,
  INamedEntity,
  IPaginationParameters,
  IPermissionedEntity,
  IProjectBasedResponseModel,
  IVersionedEntity,
  IVersionedEntityParameters,
  ISoftDeletableFilterParameters,
  ISoftDeletable,
  EntityConstants,
  DefaultCreatedByRecordOrderTypeConsts,
  DefaultCreatedOnRecordOrderTypeConsts,
  DefaultModifiedByRecordOrderTypeConsts,
  DefaultModifiedOnRecordOrderTypeConsts,
  DefaultOrderTypeConsts,
  DefaultVersionedOrderTypeConsts,
  IProjectBasedParameters,
} from "../../api/GenericTypes";

export interface LabNotebook
  extends IModelWithIntId,
    INamedEntity,
    IPermissionedEntity,
    ICreationRecord,
    IModificationRecord,
    IProjectBasedResponseModel,
    ISoftDeletable,
    IVersionedEntity {
  description: string;
  status: LabNotebookStatus;
}
export interface LabNotebookSuggestions extends IEntityMinimalModel<LabNotebook["id"]> {}

export const labNoteBooksConstants: EntityConstants<LabNotebook, LabNotebookFilters> = {
  resource: "lab_notebooks",
  frontendIndexRoute: "lab_notebooks",
  entitySingular: "notebook",
  entityPlural: "notebooks",
  icon: "book-open",
};

export const labNotebookFieldLabels: PropertyTranslator<LabNotebook> = {
  ...INamedEntityLabels,
  ...IPermissionedEntityLabels,
  ...ICreationRecordLabels,
  ...IModificationRecordLabels,
  ...ISoftDeletableLabels,
  id: "Notebook ID",
  version: "Version",
  description: "Description",
  status: "Status",
  projects: "Projects",
};

export interface LabNotebookVersion extends LabNotebook, IVersionedEntity {}

export interface LabNotebookMinimalModel extends IEntityMinimalModel {
  status: LabNotebookStatus;
}

export type LabNotebookWriteModel = Omit<
  LabNotebook,
  "id" | "createdBy" | "createdOn" | "modifiedBy" | "modifiedOn" | "isDeleted" | "version"
>;

export type LabNotebookStatus = "ACTIVE" | "CLOSED";
export const LabNotebookStatusList = ["ACTIVE", "CLOSED"];

export interface LabNotebookStatistics {
  ExperimentsTotal: number;
  EntriesTotal: number;
  LinkedDatasetsTotal: number;
  linkedLabNotebookEntriesTotal: number;
  linkedLabNotebookExperimentsTotal: number;
  linkedLabNotebooksTotal: number;
  LinkedPersonsTotal: number;
  LinkedSamplesTotal: number;
  LinkedLabNotebookTemplatesTotal: number;
}

export interface LabNotebookFilters
  extends IGenericRequestParameters<LabNotebook, LabNotebookSortingOptions>,
    IPaginationParameters,
    ICreatedRecordParameters,
    IModifiedRecordParameters,
    ISoftDeletableFilterParameters,
    IProjectBasedParameters {
  status?: LabNotebookStatus[] | null;
}

export interface LabNotebookVersionFilter extends LabNotebookFilters, IVersionedEntityParameters {}

export const LabNotebookSortingOptionsConsts = [
  ...DefaultOrderTypeConsts,
  ...DefaultCreatedOnRecordOrderTypeConsts,
  ...DefaultCreatedByRecordOrderTypeConsts,
  ...DefaultModifiedOnRecordOrderTypeConsts,
  ...DefaultModifiedByRecordOrderTypeConsts,
  ...DefaultVersionedOrderTypeConsts,
  "STATUS_ASC",
  "STATUS_DESC",
] as const;
export type LabNotebookSortingOptions = (typeof LabNotebookSortingOptionsConsts)[number];

export const LabNotebookFiltersTranslator: PropertyTranslator<LabNotebookFilters> = {
  ...IPaginationParametersTranslator,
  ...IGenericRequestParametersTranslator,
  ...ICreatedRecordParametersTranslator,
  ...IModifiedRecordParametersTranslator,
  ...ISoftDeletableFilterParametersTranslator,
  ...IProjectBasedParametersTranslator,
  status: "",
};
