import { useContext } from "react";
import { Features } from "../../api/ApiTypes";
import { SessionContext } from "./SessionContext";

export const useFeatureFlag = (flag: keyof Features): boolean => {
  const { session } = useContext(SessionContext);
  // console.log("S", session);
  const features = session?.features;
  if (features == null) return false;
  return features[flag];
};
