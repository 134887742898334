import React from "react";
import styles from "./Status.module.css";
/**
 * This component is designated a status indicator
 * Author: CS
 * @param: type, children
 * @returns: JSX.Element
 */
interface Props {
  type: "success" | "warning" | "danger" | "secondary";
  children?: React.ReactNode;
  idle?: boolean;
  title?: string;
}
const Status = ({ type, children, idle = false, title }: Props) => {
  let cls: any;
  switch (type) {
    case "success":
      cls = styles.success;
      break;
    case "warning":
      cls = styles.warning;
      break;
    case "danger":
      cls = styles.danger;
      break;
    case "secondary":
      cls = styles.secondary;
      break;
    default:
      cls = styles.secondary;
      break;
  }

  return (
    <div className={styles.container} title={title}>
      <div className={`${styles.status_indicator} ${cls} ${idle && styles.animated}`} />
      {children && <div className={styles.status_message}>{children}</div>}
    </div>
  );
};

export default Status;
