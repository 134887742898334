import { bridgeConstants } from "./Bridges";
import {
  PropertyTranslator,
  IGenericRequestParametersTranslator,
  IPaginationParametersTranslator,
  IRelationParametersTranslator,
  ICreatedRecordParametersTranslator,
  IModifiedRecordParametersTranslator,
  ITypedEntityCustomValuesSearchParametersTranslator,
} from "./GenericTranslator";
import {
  IRelationModel,
  IGenericRequestParameters,
  IEntityMinimalModel,
  IModelWithIntId,
  INamedEntity,
  IOwnedEntity,
  IPaginationParameters,
  IRelatedEntity,
  IRelation,
  IRelationParameters,
  IUniqueEntity,
  IPermissionedEntity,
  EntityConstants,
  DefaultOrderTypeConsts,
  ICreatedRecordParameters,
  IModifiedRecordParameters,
  DefaultCreatedByRecordOrderTypeConsts,
  DefaultCreatedOnRecordOrderTypeConsts,
  DefaultModifiedByRecordOrderTypeConsts,
  DefaultModifiedOnRecordOrderTypeConsts,
  ICreatedOn,
  IModifiedOn,
  ICustomTypedEntity,
  TypedEntitySuggestionsModel,
  CustomFieldValuesSearchParametersITypedEntity,
  ITypedEntityCustomValuesSearchParameters,
} from "./GenericTypes";

export interface Facility
  extends IModelWithIntId,
    INamedEntity,
    IOwnedEntity,
    IPermissionedEntity,
    IUniqueEntity,
    ICreatedOn,
    IModifiedOn,
    ICustomTypedEntity {
  // ICreationRecord,
  // IModificationRecord
  // type: FacilityTypes;
  serialnumber: string | null;
  room: string | null;
  notes: string | null;
  model: string | null;

  localPhone?: string;
  homeLab?: IEntityMinimalModel;
  homeLabContacts?: IEntityMinimalModel[];
  company?: IEntityMinimalModel;
  companyContacts?: IEntityMinimalModel[];
  isObsolete?: boolean;
}

export type FacilityTypes = "Spectrometer" | "IT" | "Storage" | "Equipment";

export interface FacilityFilters
  extends IPaginationParameters,
    IGenericRequestParameters<Facility, FacilitySortingOptions>,
    IRelationParameters,
    ICreatedRecordParameters,
    IModifiedRecordParameters,
    ITypedEntityCustomValuesSearchParameters<CustomFieldValuesSearchParametersITypedEntity> {
  type?: FacilityTypes | null;
  serialnumber?: string | null;
  room?: string | null;
  model?: string | null;
  homeLabIds?: number[] | null;
  companyIds?: number[] | null;
  isObsolete?: boolean | null;
}
export const FacilitySortingOptionsConsts = [
  ...DefaultOrderTypeConsts,
  ...DefaultCreatedOnRecordOrderTypeConsts,
  ...DefaultCreatedByRecordOrderTypeConsts,
  ...DefaultModifiedOnRecordOrderTypeConsts,
  ...DefaultModifiedByRecordOrderTypeConsts,
  "METHOD_ASC",
  "METHOD_DESC",
  "IS_OBSOLETE_ASC",
  "IS_OBSOLETE_DESC",
] as const;

export type FacilitySortingOptions = (typeof FacilitySortingOptionsConsts)[number];

export const FacilityFiltersTranslator: PropertyTranslator<FacilityFilters> = {
  ...IGenericRequestParametersTranslator,
  ...IPaginationParametersTranslator,
  ...IRelationParametersTranslator,
  ...ICreatedRecordParametersTranslator,
  ...IModifiedRecordParametersTranslator,
  ...ITypedEntityCustomValuesSearchParametersTranslator,
  type: "Facility type",
  serialnumber: "Serialnumber",
  room: "Room",
  model: "Model",
  homeLabIds: "Homelab IDs",
  companyIds: "Company IDs",
  isObsolete: "in use",
} as const;

// Instruments
export const instrumentsConstants: EntityConstants<InstrumentFacility, InstrumentFacilityFilters> = {
  resource: "instruments",
  frontendIndexRoute: "instruments",
  entitySingular: "instrument",
  entityPlural: "instruments",
  icon: "hard-drive",
};
export interface InstrumentFacility extends Facility, IRelatedEntity<InstrumentRelations> {
  method: IEntityMinimalModel;
}
export interface InstrumentFacilitySuggestions extends TypedEntitySuggestionsModel<InstrumentFacility["id"]> {}

export interface InstrumentRelations extends IRelationModel {
  datasets: IRelation;
}

export interface InstrumentFacilityFilters extends FacilityFilters {
  methodIds?: number[] | null;
  datasetIds?: number[] | null;
}

// Equipments
export const equipmentsConstants: EntityConstants<EquipmentFacility, EquipmentFacilityFilters> = {
  resource: "equipments",
  frontendIndexRoute: "equipments",
  entitySingular: "suppl. equipment",
  entityPlural: "suppl. equipment",
  icon: "radio-receiver",
};
export interface EquipmentFacilityRelations extends IRelationModel {
  datasets: IRelation;
}
export interface EquipmentFacility extends Facility, IRelatedEntity<EquipmentFacilityRelations> {
  equipmentTag: IEntityMinimalModel;
}
export interface EquipmentFacilitySuggestions extends TypedEntitySuggestionsModel<EquipmentFacility["id"]> {}

export interface EquipmentFacilityFilters extends FacilityFilters {
  datasetIds?: number[] | null;
  equipmentTagIds?: number[] | null;
}
export const EquipmentFacilityFiltersTranslator = FacilityFiltersTranslator;
// Storage
export const storageFacilityConstants: EntityConstants = {
  resource: "facilities",
  frontendIndexRoute: "storage", // bridgeConstants.frontendIndexRoute, TODO: Revert to bridge constants if autoload is fully migrated
  entitySingular: "Storage", //bridgeConstants.entitySingular,
  entityPlural: "Storage", //bridgeConstants.entityPlural,
  icon: "archive",
};

export interface StorageFacility extends Facility {}
export interface StorageFacilityFilters extends FacilityFilters {}
export const StorageFacilityFiltersTranslator = FacilityFiltersTranslator;

// IT-Facility
export interface ITFacilityRelations extends IRelationModel {
  autoloadConfigurations: IRelation;
}
export interface ITFacility extends Facility, IRelatedEntity<ITFacilityRelations> {
  hostname: string;
  // autoloadConfigurations: IEntityMinimalModel[];
}

export const itFacilityConstants: EntityConstants = {
  resource: "its",
  frontendIndexRoute: "facilities", // bridgeConstants.frontendIndexRoute, TODO: Revert to bridge constants if autoload is fully migrated
  entitySingular: "IT Facility", //bridgeConstants.entitySingular,
  entityPlural: "IT Facilities", //bridgeConstants.entityPlural,
  icon: bridgeConstants.icon,
};

export interface ITFacilityFilters extends FacilityFilters {}
export const ITFacilityFiltersTranslator = FacilityFiltersTranslator;
