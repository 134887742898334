import { useState, useMemo, useCallback, useEffect, useContext, useRef, ReactNode, Dispatch } from "react";
import { SessionContext } from "../../../common/contexts/SessionContext";
import { LucideIcon } from "../../../common/icon/LucideIcon";
import { getDetailLink } from "../../../main/Routing";
import { LocalFilePreview } from "../../common/Tree/Folder/helpers/FilePreview";
import { FileTree } from "../../common/Tree/Tree";
import { TreeElementBase, buildTree, getTreeData } from "../../tiles/Files/Files";
import { ManualUploadActions, flat2Hierarchy, useLocalFilePreview } from "../FileHandlingUtils";
import styles from "./RenderDataset.module.css";
import { formatBytes } from "../../common/Tree/Folder/helpers/FileHelpers";
import { DatasetResult } from "../FileFormatAutoDetection";
import { Link } from "react-router-dom";
import { RenderDatasetPreview } from "./RenderDatasetPreview";
import { Dataset, DatasetFilters, datasetsConstants, FormatFileState } from "../../../api/Datasets";
import { Container } from "../../../common/panels/Container/Container";
import { useEntityDetail } from "../../../api/BaseEntityApi";
import { useForm, useWatch } from "react-hook-form";
import { Nullable } from "../../../api/GenericTypes";
import useIntersectionObserver from "../../../common/helperfunctions/useIntersectionObserver";
import { getStatusIndication, renderFileStats } from "./common/RenderDatasetUtils";
import { SamplesVirtualizedSelectForm } from "../../../common/forms/EntityForms/formsVirtualized/SamplesVirtualizedSelectForm";
import { EquipmentsVirtualizedSelectForm } from "../../../common/forms/EntityForms/formsVirtualized/EquipmentsVirtualizedSelectForm";
import { ExperimentsVirtualizedSelectForm } from "../../../common/forms/EntityForms/formsVirtualized/ExperimentsVirtualizedSelectForm";
import { OrganizationsVirtualizedSelectForm } from "../../../common/forms/EntityForms/formsVirtualized/OrganizationsVirtualizedSelectForm";
import { PersonsVirtualizedSelectForm } from "../../../common/forms/EntityForms/formsVirtualized/PersonsVirtualizedSelectForm";
import { ProjectsVirtualizedSelectForm } from "../../../common/forms/EntityForms/formsVirtualized/ProjectsVirtualizedSelectForm";
import { EntityCustomTypeForm, useCustomTypeForm } from "../../../Customization/CustomTypes/generics/useCustomTypeForm";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormErrors } from "../../../common/forms/FormButtons";
import { Tile } from "../../../common/panels/Tile/Tile";
import { MethodsVirtualizedSelectForm } from "../../../common/forms/EntityForms/formsVirtualized/MethodsVirtualizedSelectForm";
import { InstrumentsVirtualizedSelectForm } from "../../../common/forms/EntityForms/formsVirtualized/InstrumentsVirtualizedSelectForm";

interface ExtendedTreeElement extends TreeElementBase {
  state?: FormatFileState;
}

interface RenderDatasetProps {
  dataset: DatasetResult;
  actionUUID: string;
  dispatch: Dispatch<ManualUploadActions>;
  additionalControls?: ReactNode;
}

export const RenderDataset = ({ dataset, actionUUID, dispatch, additionalControls }: RenderDatasetProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const entry = useIntersectionObserver(ref, {});
  const [showFiles, setShowFiles] = useState(false);
  const { route } = useContext(SessionContext);
  let struct: ExtendedTreeElement[] = [];
  const fileHierarchy = useMemo(() => flat2Hierarchy(dataset.files), [dataset.files]);
  buildTree(fileHierarchy, undefined, struct);
  const chunkSize = 128000;
  const [preview, setPreview] = useState<Uint8Array>();
  const [showModal, setShowModal] = useState<string>();
  const [showHex, setShowHex] = useState<boolean>(false);
  const [offset, setOffset] = useState(0);
  const [file, setFile] = useState<File>();
  const { data: buffer, isFetching: isFetchingBuffer } = useLocalFilePreview(file!, offset, { enabled: !!file });
  const [showPreviewModal, setShowPreviewModal] = useState(false);

  const reachedBottomCallback = useCallback(() => {
    setOffset((prev) => prev + chunkSize);
  }, []);

  useEffect(() => {
    if (buffer && !isFetchingBuffer) {
      setPreview((prev) => {
        if (prev && prev instanceof Uint8Array) {
          const merged = new Uint8Array(prev.length + buffer.length);
          merged.set(prev as Uint8Array);
          merged.set(buffer, prev.length);
          return merged;
        } else {
          return buffer;
        }
      });
    }
  }, [buffer, isFetchingBuffer]);

  const { data: realDataset } = useEntityDetail<Dataset, DatasetFilters>("datasets", dataset.datasetId!, undefined, {
    enabled: !!dataset.datasetId,
  });

  const defaults = useMemo(() => {
    return realDataset ? realDataset : (dataset.metadata as Partial<Dataset>);
  }, [dataset.metadata, realDataset]);

  const { defaultValues, typedFormSchema, processCustomFields, type, setType, types } = useCustomTypeForm<Dataset>({
    initialValues: defaults,
    formSchema: {},
    typeId: defaults?.customType ? defaults?.customType.id : null,
    entityType: "Dataset",
  });

  const {
    setValue,
    control,
    register,
    reset,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm<Partial<Nullable<Dataset>>>({
    values: defaultValues,
    resolver: yupResolver(typedFormSchema),
  });

  const formCleanup = useCallback(<T extends object>(values: T) => {
    return Object.fromEntries(
      Object.entries(values).map(([key, value]) => {
        if (Array.isArray(value) && value.length === 0) {
          return [key, null];
        } else {
          return [key, value];
        }
      })
    ) as T;
  }, []);

  const handleSave = useCallback(() => {
    handleSubmit((entity) => {
      const values = formCleanup(processCustomFields(entity as Partial<Dataset>));
      if (!dataset.metadata || values !== dataset.metadata) {
        dispatch({
          type: "setBundleDataset",
          id: dataset.id,
          actionUUID: actionUUID,
          parserId: dataset.parserId,
          metaData: values as DatasetResult["metadata"],
        });
      }
    })();
  }, [
    actionUUID,
    dataset.id,
    dataset.metadata,
    dataset.parserId,
    dispatch,
    formCleanup,
    handleSubmit,
    processCustomFields,
  ]);

  const handleReset = useCallback(() => {
    reset({
      sample: null,
      experiment: null,
      projects: null,
      organizations: null,
      operators: null,
      equipments: null,
      customType: null,
      customFields: {},
    });
  }, [reset]);

  const disabled = useMemo(
    () => !!dataset.datasetId || !dataset.status || (dataset.status && !["new", "update"].includes(dataset.status)),
    [dataset.datasetId, dataset.status]
  );

  const method = useWatch({ control, name: "method" });

  return (
    <div className={styles.file_row} ref={ref}>
      {entry && entry.isIntersecting ? (
        <>
          <div
            className="flex row-nowrap justify-center"
            style={{
              width: "min-content",
              flexShrink: 0,
              alignSelf: "flex-start",
              marginTop: "9.2px",
            }}
          >
            {dataset.status ? (
              getStatusIndication(dataset)
            ) : (
              <span title={"Processing dataset..."} style={{ width: "min-content", height: "min-content" }}>
                <div className="spinner spinner-sm" style={{ width: 14, height: 14 }} />
              </span>
            )}
          </div>

          <div
            className={`${styles.file_row_detail} ${dataset.status === "known" && styles.file_row_detail_knownDataset}`}
          >
            <div className={styles.file_row_detail_head} onClick={() => setShowFiles((prev) => !prev)}>
              {showFiles ? <LucideIcon name="chevron-up" /> : <LucideIcon name="chevron-right" />}
              {/* <label className="label label-primary" style={{ margin: 0 }}>
            <div style={{ display: "flex", alignItems: "center", gap: 5 }}>
              {dataset.files.length === 1 ? <FeatherIcon name="file" /> : <FeatherIcon name="folder" />}
              {dataset.files.length} {dataset.files.length === 1 ? "File" : "Files"}
            </div>
          </label> */}

              <LucideIcon name="activity" color="var(--primary)" />
              <span style={{ overflow: "hidden", textOverflow: "ellipsis" }}>{dataset.name}</span>
              <span style={{ marginLeft: "auto", marginBottom: 0, color: "var(--gray-400)" }}>
                {formatBytes(dataset.files.map((file) => file.file.size).reduce((a, b) => a + b, 0))}
              </span>
            </div>
            {showFiles && (
              <div
                className="flex col-nowrap gap-5"
                style={{ width: "100%", height: "100%", borderTop: "1px solid var(--gray-300)", padding: "5px 0px" }}
              >
                <Tile foldable isFolded>
                  <Tile.Head title="Metadata">
                    <Tile.Head.Controls>
                      {!disabled && (
                        <Tile.Head.Controls.Unfolded>
                          <button className="btn btn-default" title="Reset" onClick={handleReset}>
                            Reset
                          </button>
                          <button
                            className="btn btn-primary"
                            title="Save changes"
                            onClick={handleSave}
                            disabled={!isDirty}
                          >
                            Save changes
                          </button>
                        </Tile.Head.Controls.Unfolded>
                      )}
                    </Tile.Head.Controls>
                  </Tile.Head>
                  <Tile.Body>
                    <div
                      className="flex col-nowrap gap-5 form-horizontal"
                      style={{ width: "100%", height: "fit-content" }}
                    >
                      {typeof errors === "object" && !!Object.keys(errors).length && (
                        <div style={{ marginTop: 10, padding: 0 }}>
                          <FormErrors errors={errors} />
                        </div>
                      )}
                      <MethodsVirtualizedSelectForm
                        id="method"
                        label={"Measuring Method"}
                        control={control}
                        disabled={disabled}
                        defaultValue={realDataset ? realDataset.method : dataset.metadata?.method}
                        horizontal
                        required
                        allowCreateEntity
                      />
                      <InstrumentsVirtualizedSelectForm
                        id="instrument"
                        control={control}
                        disabled={disabled}
                        defaultValue={realDataset ? realDataset.instrument : dataset.metadata?.instrument}
                        horizontal
                        allowCreateEntity
                      />
                      <SamplesVirtualizedSelectForm
                        id="sample"
                        control={control}
                        disabled={disabled}
                        defaultValue={realDataset ? realDataset.sample : dataset.metadata?.sample}
                        horizontal
                        allowCreateEntity
                      />
                      <ProjectsVirtualizedSelectForm
                        id="projects"
                        control={control}
                        defaultValue={realDataset ? realDataset.projects : dataset.metadata?.projects}
                        disabled={disabled}
                        horizontal
                        allowCreateEntity
                        isMulti
                        filters={{ currentUserHasAddPermission: true }}
                      />
                      <OrganizationsVirtualizedSelectForm
                        id="organizations"
                        control={control}
                        defaultValue={realDataset ? realDataset.organizations : dataset.metadata?.organizations}
                        disabled={disabled}
                        horizontal
                        allowCreateEntity
                        isMulti
                      />
                      <PersonsVirtualizedSelectForm
                        id="operators"
                        label={"Operators"}
                        control={control}
                        defaultValue={realDataset ? realDataset.operators : dataset.metadata?.operators}
                        disabled={disabled}
                        horizontal
                        allowCreateEntity
                        isMulti
                      />
                      <ExperimentsVirtualizedSelectForm
                        id="experiment"
                        control={control}
                        defaultValue={realDataset ? realDataset.experiment : dataset.metadata?.experiment}
                        disabled={disabled}
                        horizontal
                        allowCreateEntity
                        filters={{ methodIds: method && method.id > 0 ? [method.id] : undefined }}
                      />
                      <EquipmentsVirtualizedSelectForm
                        id="equipments"
                        label={"Suppl. Equipment"}
                        control={control}
                        defaultValue={realDataset ? realDataset.experiment : dataset.metadata?.experiment}
                        disabled={disabled}
                        horizontal
                        allowCreateEntity
                        isMulti
                      />

                      <EntityCustomTypeForm
                        entityType="Dataset"
                        typeId={null}
                        type={type}
                        types={types}
                        setType={setType}
                        control={control as any}
                        register={register as any}
                        setValue={setValue as any}
                        initialValues={defaults}
                        errors={errors}
                        entityConstants={datasetsConstants}
                        allowUnassigned={false}
                        disabled={disabled}
                      />
                    </div>
                  </Tile.Body>
                </Tile>

                <Container
                  title={
                    <div className="flex row-nowrap align-center gap-5">
                      {dataset.files.length === 1 ? "File" : "Files"}
                      <span className="badge">{dataset.files.length}</span>{" "}
                    </div>
                  }
                  controls={
                    <div
                      className="flex row-nowrap align-center gap-5"
                      style={{ width: "min-content", marginLeft: "auto" }}
                    >
                      {renderFileStats(dataset.files)}
                    </div>
                  }
                >
                  <div className="flex col-nowrap" style={{ width: "100%", height: "fit-content" }}>
                    {dataset.parentPath && (
                      <span style={{ color: "var(--gray-400)", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>
                        {`.../${dataset.parentPath}/`}
                      </span>
                    )}

                    <FileTree
                      files={getTreeData(struct)}
                      level={0}
                      onFileClick={(item) => {
                        if (item.uniqueId) {
                          const file = dataset.files.filter((file) => file.id === item.uniqueId);
                          if (Array.isArray(file) && file.length > 0) {
                            setFile(file[0].file);
                          } else {
                            setFile(undefined);
                          }
                          setShowModal(item.id);
                        }
                      }}
                      fileControls={(item) => (
                        <>
                          {file && (
                            <LocalFilePreview
                              buffer={preview}
                              item={item}
                              showModal={showModal === item.id}
                              setShowModal={() => {
                                // console.log("Clearing buffer..");
                                setFile(undefined);
                                setShowModal(undefined);
                                setOffset(0);
                                setPreview(undefined);
                              }}
                              loading={isFetchingBuffer}
                              showHex={showHex}
                              setShowHex={setShowHex}
                              reachedBottomCallback={reachedBottomCallback}
                            />
                          )}
                        </>
                      )}
                      rowClassName={(item) => {
                        switch (item.state) {
                          case "DELETE":
                            return "alert-danger";
                          // case "NEW":
                          //   if (dataset.datasetId) {
                          //     // We only want to indicate new files on updates
                          //     return "alert-success";
                          //   } else return "";
                          case "NEW":
                            return "alert-success";
                          case "NEEDSUPDATE":
                            return "alert-warning";
                          default:
                            return "";
                        }
                      }}
                      fixedControls={(item) => (
                        <>
                          {item.state && item.state === "NEEDSUPDATE" ? (
                            <label
                              className="label label-warning"
                              style={{ margin: 0, fontSize: "1em", width: "10ch" }}
                            >
                              Modified
                            </label>
                          ) : // :
                          // dataset.datasetId && item.state && item.state === "NEW" ? ( // We only want to indicate new files on updates
                          //   <label
                          //     className="label label-success"
                          //     style={{ margin: 0, fontSize: "1em",  width: "10ch" }}
                          //   >
                          //     New
                          //   </label>
                          // )

                          item.state && item.state === "NEW" ? (
                            <label
                              className="label label-success"
                              style={{ margin: 0, fontSize: "1em", width: "10ch" }}
                            >
                              New
                            </label>
                          ) : item.state === "DELETE" ? (
                            <label className="label label-danger" style={{ margin: 0, fontSize: "1em", width: "10ch" }}>
                              Deleted
                            </label>
                          ) : item.state === "UNCHANGED" ? (
                            <label
                              className="label label-default"
                              style={{ margin: 0, fontSize: "1em", width: "10ch" }}
                            >
                              Unchanged
                            </label>
                          ) : (
                            <></>
                          )}
                        </>
                      )}
                    />
                  </div>
                </Container>
              </div>
            )}
          </div>

          <div className={styles.file_row_remove}>
            {dataset.datasetId && (
              <>
                <RenderDatasetPreview
                  datasetId={dataset.datasetId}
                  name={dataset.name}
                  showModal={showPreviewModal}
                  setShowModal={setShowPreviewModal}
                />
                <Link
                  to={route(getDetailLink("datasets", dataset.datasetId))}
                  rel="noreferrer"
                  target="_blank"
                  onClick={(e) => e.preventDefault()}
                >
                  <button className="btn btn-primary" onClick={() => setShowPreviewModal(true)}>
                    View
                  </button>
                </Link>
              </>
            )}
          </div>
          {additionalControls}
        </>
      ) : (
        <div className="flex row-nowrap align-center gap-5" style={{ width: "100%", height: "43px" }}>
          <div className="skeleton-block" style={{ width: "93px", height: "24.6px", flexShrink: 0 }} />
          <div className="skeleton-block" style={{ width: "100%", height: "43px" }} />
        </div>
      )}
    </div>
  );
};
