import { useEffect } from "react";
import { useForm, useWatch } from "react-hook-form";
// import { useHistory } from "react-router-dom";
import { IEntityMinimalModel } from "../api/GenericTypes";
import { LucideIcon } from "../common/icon/LucideIcon";
import { DatasetFilters } from "../api/Datasets";
import { SplitDatePickerFormField } from "../common/formfields/DatePickerFormField/SplitDatePickerFormField";
import { SamplesVirtualizedSelectForm } from "../common/forms/EntityForms/formsVirtualized/SamplesVirtualizedSelectForm";

interface DatasetsFilterbarProps {
  setFilters: React.Dispatch<React.SetStateAction<DatasetFilters>>;
}

interface DatasetsFilterForm {
  operator?: IEntityMinimalModel[] | undefined | null;
  project?: IEntityMinimalModel[] | undefined | null;
  organization?: IEntityMinimalModel[] | undefined | null;
  method?: IEntityMinimalModel[] | undefined | null;
  sample?: IEntityMinimalModel[] | undefined | null;
  experiment?: IEntityMinimalModel[] | undefined | null;
  instrument?: IEntityMinimalModel[] | undefined | null;
  equipment?: IEntityMinimalModel[] | undefined | null;
  acquisitionDateFrom?: string | undefined | null;
  acquisitionDateTo?: string | undefined | null;
}
// const defaultValues: DefinedPropertyObject<DatasetsFilterForm> = {
//   operator: null,
//   project: null,
//   organization: null,
//   method: null,
//   sample: null,
//   experiment: null,
//   instrument: null,
//   equipment: null,
//   acquisitionDateFrom: null,
//   acquisitionDateTo: null,
// };

export const SharedBundlesFilterBar = ({ setFilters }: DatasetsFilterbarProps) => {
  // const history = useHistory();
  // const { data: persons, status: personsStatus, error: personsError } = useEntitySuggestions("persons");
  // const { data: projects, status: projectsStatus, error: projectsError } = useEntitySuggestions("projects");
  // const {
  //   data: organizations,
  //   status: organizationsStatus,
  //   error: organizationsError,
  // } = useEntitySuggestions("organizations");
  // const { data: methods, status: methodsStatus, error: methodsError } = useEntitySuggestions("methods");
  // const {
  //   data: samples,
  //   status: samplesStatus,
  //   fetchStatus: samplesFetchStatus,
  //   error: samplesError,
  // } = useEntitySuggestions("samples");
  // const { data: experiments, status: experimentsStatus, error: experimentsError } = useEntitySuggestions("experiments");
  // const {
  //   data: instruments,
  //   status: instrumentsStatus,
  //   error: instrumentsError,
  // } = useEntitySuggestions("facilities/instruments");
  // const {
  //   data: equipments,
  //   status: equipmentsStatus,
  //   error: equipmentsError,
  // } = useEntitySuggestions("facilities/eq_facilities");

  const { control, reset } = useForm<DatasetsFilterForm>({
    defaultValues: {},
  });

  // const operator = useWatch({ name: "operator", control: control });
  // const project = useWatch({ name: "project", control: control });
  // const organization = useWatch({ name: "organization", control: control });
  // const method = useWatch({ name: "method", control: control });
  const sample = useWatch({ name: "sample", control: control });
  // const experiment = useWatch({ name: "experiment", control: control });
  // const instrument = useWatch({ name: "instrument", control: control });
  // const equipment = useWatch({ name: "equipment", control: control });
  const acqDateFrom = useWatch({ name: "acquisitionDateFrom", control: control });
  const acqDateTo = useWatch({ name: "acquisitionDateTo", control: control });

  // useEffect(() => {
  //   setFilters((prevState) => ({
  //     ...prevState,
  //     operatorIds: Array.isArray(operator) && operator.length > 0 ? operator?.map((d) => d.id) : null,
  //   }));
  // }, [operator, setFilters]);

  // useEffect(() => {
  //   setFilters((prevState) => ({
  //     ...prevState,
  //     projectIds: Array.isArray(project) && project.length > 0 ? project?.map((d) => d.id) : null,
  //   }));
  // }, [project, setFilters]);

  // useEffect(() => {
  //   setFilters((prevState) => ({
  //     ...prevState,
  //     organizationIds: Array.isArray(organization) && organization.length > 0 ? organization?.map((d) => d.id) : null,
  //   }));
  // }, [organization, setFilters]);

  // useEffect(() => {
  //   setFilters((prevState) => ({
  //     ...prevState,
  //     methodIds: Array.isArray(method) && method.length > 0 ? method?.map((d) => d.id) : null,
  //   }));
  // }, [method, setFilters]);

  useEffect(() => {
    setFilters((prevState) => ({
      ...prevState,
      sampleIds: Array.isArray(sample) && sample.length > 0 ? sample?.map((d) => d.id) : null,
    }));
  }, [sample, setFilters]);

  // useEffect(() => {
  //   setFilters((prevState) => ({
  //     ...prevState,
  //     experimentIds: Array.isArray(experiment) && experiment.length > 0 ? experiment?.map((d) => d.id) : null,
  //   }));
  // }, [experiment, setFilters]);

  // useEffect(() => {
  //   setFilters((prevState) => ({
  //     ...prevState,
  //     instrumentIds: Array.isArray(instrument) && instrument.length > 0 ? instrument?.map((d) => d.id) : null,
  //   }));
  // }, [instrument, setFilters]);

  // useEffect(() => {
  //   setFilters((prevState) => ({
  //     ...prevState,
  //     equipmentIds: Array.isArray(equipment) && equipment.length > 0 ? equipment?.map((d) => d.id) : null,
  //   }));
  // }, [equipment, setFilters]);

  useEffect(() => {
    setFilters((prevState) => ({
      ...prevState,
      acquisitionDateFrom: acqDateFrom ? new Date(acqDateFrom).toISOString() : null,
    }));
  }, [acqDateFrom, setFilters]);

  useEffect(() => {
    setFilters((prevState) => ({
      ...prevState,
      acquisitionDateTo: acqDateTo ? new Date(acqDateTo).toISOString() : null,
    }));
  }, [acqDateTo, setFilters]);

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
      }}
      autoComplete="off"
    >
      {/* <LoadingWrapper status={personsStatus} error={personsError}>
        <SelectFormField
          id={"operator"}
          label={"Operator"}
          {...register}
          items={persons}
          control={control}
          horizontal={false}
          isMulti={true}
        />
      </LoadingWrapper>
      <LoadingWrapper status={projectsStatus} error={projectsError}>
        <SelectFormField
          id={"project"}
          label={"Project"}
          {...register}
          items={projects}
          control={control}
          horizontal={false}
          isMulti={true}
        />
      </LoadingWrapper>
      <LoadingWrapper status={organizationsStatus} error={organizationsError}>
        <SelectFormField
          id={"organization"}
          label={"Organization"}
          {...register}
          items={organizations}
          control={control}
          horizontal={false}
          isMulti={true}
        />
      </LoadingWrapper>
      <LoadingWrapper status={methodsStatus} error={methodsError}>
        <SelectFormField
          id={"method"}
          label={"Method"}
          {...register}
          items={methods}
          control={control}
          horizontal={false}
          isMulti={true}
        />
      </LoadingWrapper> */}
      <SamplesVirtualizedSelectForm id={"sample"} control={control} isMulti />
      {/* <LoadingWrapper status={samplesStatus} fetchStatus={samplesFetchStatus} error={samplesError}>
        <SelectFormField
          id={"sample"}
          label={"Sample"}
          {...register}
          items={samples}
          control={control}
          horizontal={false}
          isMulti={true}
        />
      </LoadingWrapper> */}
      {/* <LoadingWrapper status={experimentsStatus} error={experimentsError}>
        <SelectFormField
          id={"experiment"}
          label={"Experiment"}
          {...register}
          items={experiments}
          control={control}
          horizontal={false}
          isMulti={true}
        />
      </LoadingWrapper>
      <LoadingWrapper status={instrumentsStatus} error={instrumentsError}>
        <SelectFormField
          id={"instrument"}
          label={"Instrument"}
          {...register}
          items={instruments}
          control={control}
          horizontal={false}
          isMulti={true}
        />
      </LoadingWrapper>
      <LoadingWrapper status={equipmentsStatus} error={equipmentsError}>
        <SelectFormField
          id={"equipment"}
          label={"Supplementary equipment"}
          {...register}
          items={equipments}
          control={control}
          horizontal={false}
          isMulti={true}
        />
      </LoadingWrapper> */}

      <SplitDatePickerFormField
        control={control}
        idStart="acquisitionDateFrom"
        idEnd="acquisitionDateTo"
        label="Acquisition date (from - to)"
        horizontal={false}
        showTimeSelect
      />

      <hr />
      <div style={{ display: "flex", justifyContent: "center" }}>
        <button
          className="btn btn-ghost-secondary"
          onClick={(e) => {
            reset({
              project: null,
              organization: null,
              operator: null,
              method: null,
              sample: null,
              experiment: null,
              instrument: null,
              equipment: null,
              acquisitionDateFrom: null,
              acquisitionDateTo: null,
            });
            e.preventDefault();
          }}
        >
          <LucideIcon name="refresh-cw" /> Reset filters
        </button>
      </div>
    </form>
  );
};
