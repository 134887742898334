import { createContext, useContext, useEffect, useMemo, useState } from "react";
import SinglePage from "../../common/panels/SinglePage/SinglePage";
import { ManualUploadBundleView } from "./ManualUploadBundleView";
import { ManualUploadDropArea } from "./ManualUploadDropArea";
import { RenderBundlePlaceholder } from "./components/RenderBundlePlaceholder";
import useUnsavedChangesWarning from "../../common/helperfunctions/useUnsavedChangesWarning";
import { SessionContext } from "../../common/contexts/SessionContext";
import { useHistory } from "react-router-dom";
import { LucideIcon } from "../../common/icon/LucideIcon";
interface ManualUploadContextStore {
  stepProgress: {
    currentStep: number;
    setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  };
  isDirty: {
    isDirty: boolean;
    setIsDirty: React.Dispatch<React.SetStateAction<boolean>>;
  };
}
export const ManualUploadContext = createContext<ManualUploadContextStore | undefined>(undefined);

export const ManualUpload = () => {
  const { route } = useContext(SessionContext);
  const history = useHistory();
  const { onDirty, onPristine, routerPrompt } = useUnsavedChangesWarning();
  // Setup the context
  const [currentStep, setCurrentStep] = useState<number>(1);
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const context = useMemo(
    () => ({
      stepProgress: {
        currentStep: currentStep,
        setCurrentStep: setCurrentStep,
      },
      isDirty: {
        isDirty: isDirty,
        setIsDirty: setIsDirty,
      },
    }),
    [currentStep, isDirty]
  );

  useEffect(() => {
    if (isDirty) {
      onDirty();
    } else {
      onPristine();
    }
    return () => onPristine();
  }, [isDirty, onDirty, onPristine]);

  return (
    <ManualUploadContext.Provider value={context}>
      {routerPrompt}
      <SinglePage>
        <SinglePage.Head>
          <SinglePage.Head.Label>Add datasets</SinglePage.Head.Label>
          <SinglePage.Head.Controls>
            <button
              className="btn btn-default"
              title="Supported data formats"
              onClick={() => history.push(route("/formats"))}
            >
              <LucideIcon name="circle-help" /> Supported data formats
            </button>
          </SinglePage.Head.Controls>
        </SinglePage.Head>
        <SinglePage.Content>
          <div
            className="flex col-nowrap"
            style={{ padding: "5px 5px 0px 5px", width: "100%", height: "100%", overflow: "hidden" }}
          >
            <RenderBundlePlaceholder currentStep={currentStep} />
            <ManualUploadDropArea>{(props) => <ManualUploadBundleView {...props} />}</ManualUploadDropArea>
          </div>
        </SinglePage.Content>
      </SinglePage>
    </ManualUploadContext.Provider>
  );
};
