import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { InputFormField } from "../../../formfields/InputFormField";
import { QuickAddChildrenProps } from "../common/QuickAddWrapper";
import { FormButtons } from "../../FormButtons";
import { TextareaFormField } from "../../../formfields/TextareaFormField";
import { FormHeader } from "../../FormHeader";
import { useQuickAddSubmit } from "../common/useQuickAddHooks";
import { ProjectTag, ProjectTagFieldLabels, projectTagsConstants } from "../../../../api/ProjectTag";

export const QuickAddProjectTagForm = ({ onCreate, onCancel }: QuickAddChildrenProps<ProjectTag>) => {
  const { onSubmit, loading } = useQuickAddSubmit<ProjectTag>({
    resource: projectTagsConstants.resource,
    onCreate: onCreate,
    onCancel: onCancel,
  });

  const FormSchema = yup.object().shape({
    name: yup.string().required("Project tag name is required").typeError("Project tag name is required"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<ProjectTag>({
    defaultValues: {},
    resolver: yupResolver(FormSchema),
  });

  return (
    <>
      <FormHeader title="Add project tag" />
      <InputFormField
        id="name"
        label={ProjectTagFieldLabels.name}
        errors={errors}
        register={register}
        autoFocus={true}
        required={true}
        placeholder="Enter project tag name..."
      />

      <TextareaFormField
        id="notes"
        label={ProjectTagFieldLabels.notes}
        errors={errors}
        register={register}
        placeholder="Enter notes..."
      />
      <FormButtons
        onClose={onCancel}
        onSubmit={handleSubmit(onSubmit)}
        disabled={isSubmitting}
        errors={errors}
        submitButtonLabel="Create"
        loading={loading}
      />
    </>
  );
};
