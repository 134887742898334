import { StringIndexedDict } from "../../../../api/GenericTypes";
import {
  GenericEntityVirtualizedEntitySelectFormFieldProps,
  GenericVirtualizedEntitySelectFormFieldControllerWrapper,
} from "../../../formfields/GenericVirtualizedSelectFormField/GenericVirtualizedEntitySelectFormFieldControllerWrapper";
import {
  ParserInstrumentFilters,
  ParserInstrumentSortingOptionsConsts,
  ParserInstrumentSuggestions,
  parserInstrumentsConstants,
} from "../../../../api/Parsers";

export const ParserInstrumentsVirtualizedSelectForm = <Entity extends StringIndexedDict>(
  props: GenericEntityVirtualizedEntitySelectFormFieldProps<
    Entity,
    ParserInstrumentSuggestions,
    ParserInstrumentFilters
  >
) => {
  return (
    <GenericVirtualizedEntitySelectFormFieldControllerWrapper<
      Entity,
      ParserInstrumentSuggestions,
      ParserInstrumentFilters
    >
      {...props}
      filters={{ orderBy: "NAME_ASC", ...props.filters }}
      //   onRowRenderer={(item) => <></>}
      entityConstants={parserInstrumentsConstants}
      orderByOptions={ParserInstrumentSortingOptionsConsts}
      allowCreateEntity={false}
    />
  );
};
