import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import {
  Announcement,
  AnnouncementContentType,
  AnnouncementContentTypes,
  AnnouncementType,
  AnnouncementTypes,
} from "../api/Announcements";
import { SelectFormField } from "../common/formfields/SelectFormField";
import { FormButtons } from "../common/forms/FormButtons";
import { FormHeader } from "../common/forms/FormHeader";
import styles from "../common/forms/forms.module.css";
import { EntityFormProps } from "../common/entity/EntityInterfaces";
import { SplitDatePickerFormField } from "../common/formfields/DatePickerFormField/SplitDatePickerFormField";
import { TextareaFormField } from "../common/formfields/TextareaFormField";
import { FormFieldLayout } from "../common/formfields/FormFieldLayouts";
import { ToggleButtonComponent } from "../ViewerUIElements/ToggleButtonComponent";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { SessionContext } from "../common/contexts/SessionContext";
import { IEntityMinimalModel } from "../api/GenericTypes";
import { toUppercase } from "../common/helperfunctions/stringFunctions";
import { AnnouncementContentTypeField } from "./AnnouncementsDetail";

interface AnnouncementFormValues extends Omit<Announcement, "type" | "contentType" | "startDate" | "endDate"> {
  type: { id: AnnouncementType; name: string };
  contentType: { id: AnnouncementContentType; name: string };
  startDate?: Date | null;
  endDate?: Date | null;
}

export const ContentyTypes: IEntityMinimalModel<string>[] = AnnouncementContentTypes.map((c) => ({
  id: c,
  name: toUppercase(c),
}));

export const AnnouncementForm = ({
  fieldLabels,
  title,
  subtitle,
  initialValues,
  onSubmit,
  onCancel,
  loading,
  submitButtonLabel,
}: EntityFormProps<"announcements">) => {
  const { session } = useContext(SessionContext);

  const [isSystemAnnouncement, setIsSystemAnnouncement] = useState<boolean>(
    initialValues?.isSystemAnnouncement ?? false
  );

  useEffect(() => {
    setIsSystemAnnouncement(initialValues?.isSystemAnnouncement ?? false);
  }, [initialValues?.isSystemAnnouncement]);

  const AnnouncementFormSchema = yup.object().shape({
    type: yup.object().required("Announcement type is required").typeError("Announcement type is required"),
    contentType: yup.object().required("Content type is required").typeError("Content type is required"),
    isSystemAnnouncement: yup.boolean().optional().default(false),
    startDate: yup.date().required("Start date is required").typeError("Start date is required"),
    message: yup.string().required("Message is required").typeError("Message is required"),
  });

  // we need to memoize the initial values here due to the new date objects as defaults resulting in an infinite loop
  const initialValuesMemoized: Partial<AnnouncementFormValues> = useMemo(() => {
    return {
      ...initialValues,
      type: initialValues?.type
        ? { id: initialValues.type, name: initialValues.type === "regular" ? "info" : initialValues.type }
        : { id: "regular", name: "info" },
      contentType: initialValues?.contentType
        ? { id: initialValues.contentType, name: toUppercase(initialValues.contentType) }
        : { id: "text", name: "Text" },
      startDate: initialValues?.startDate ? new Date(initialValues.startDate) : new Date(),
      endDate: initialValues?.endDate ? new Date(initialValues.endDate) : undefined,
    };
  }, [initialValues]);

  const {
    handleSubmit,
    control,
    register,
    watch,
    formState: { errors, isSubmitting },
  } = useForm<Partial<AnnouncementFormValues>>({
    values: initialValuesMemoized,
    resolver: yupResolver(AnnouncementFormSchema),
  });

  const formValuesToEntity = useCallback(
    (entity: Partial<AnnouncementFormValues>) => {
      if (!!entity.startDate && !!entity.type && !!entity.message)
        onSubmit({
          ...entity,
          type: (entity.type as any)?.id || "regular",
          startDate: entity.startDate.toISOString(),
          endDate: entity.endDate?.toISOString(),
          isSystemAnnouncement,
          contentType: (entity.contentType as any)?.id || "text",
        });
    },
    [isSystemAnnouncement, onSubmit]
  );

  const currentContentType = watch("contentType");
  const currentMessage = watch("message");

  return (
    <>
      <FormHeader title={title} subtitle={subtitle} />
      <form
        onSubmit={(e) => {
          e.preventDefault();
        }}
        autoComplete="off"
        className={`form-horizontal ${styles.form_holder}`}
      >
        <fieldset>
          <legend className="col-md-offset-2 col-md-10">Basic details</legend>
        </fieldset>
        <SelectFormField
          id="type"
          label={fieldLabels.type}
          required
          control={control}
          isMulti={false}
          items={AnnouncementTypes?.map((x) => ({ id: x, name: x === "regular" ? "info" : x })) ?? []}
          errors={errors}
          placeholder="Select announcement type..."
        />

        <SplitDatePickerFormField
          control={control}
          idStart="startDate"
          idEnd="endDate"
          label="Valid from - to"
          errors={errors}
          showTimeSelect
        />

        {session?.permissions.logsadmin && (
          <FormFieldLayout id="isSystemAnnouncement" label="Is system announcement" hasLabel horizontal>
            <div className="flex align-center gap-5" style={{ padding: "10px 0" }}>
              <ToggleButtonComponent checked={isSystemAnnouncement} setChecked={setIsSystemAnnouncement}>
                <></>
              </ToggleButtonComponent>
            </div>
          </FormFieldLayout>
        )}

        <SelectFormField
          id="contentType"
          label={fieldLabels.contentType}
          required={true}
          control={control}
          isMulti={false}
          items={ContentyTypes}
          errors={errors}
          placeholder="Select content type..."
        />

        <TextareaFormField
          id="message"
          register={register}
          label={fieldLabels.message}
          required
          errors={errors}
          placeholder="Insert message..."
          rows={10}
        />

        {currentContentType?.id === "markdown" && (
          <FormFieldLayout id="markdown" label="Markdown preview" horizontal>
            <AnnouncementContentTypeField
              contentType="markdown"
              message={currentMessage ?? ""}
              style={{ maxHeight: "50vh" }}
            />
          </FormFieldLayout>
        )}

        <FormButtons
          groupName="announcements"
          entityId={initialValues ? initialValues.id : undefined}
          onClose={onCancel}
          onSubmit={handleSubmit(formValuesToEntity)}
          loading={loading}
          submitButtonLabel={submitButtonLabel || "Save changes"}
          disabled={isSubmitting}
          errors={errors}
        />
      </form>
    </>
  );
};
