import { useForm } from "react-hook-form";
import { InputFormField } from "../common/formfields/InputFormField";
import { TextareaFormField } from "../common/formfields/TextareaFormField";
import { FormButtons } from "../common/forms/FormButtons";
import { FormHeader } from "../common/forms/FormHeader";
import { FormLayoutContainer } from "../common/forms/FormLayout";
import styles from "../common/forms/forms.module.css";
import { Dataset } from "../api/Datasets";
import { CustomTypedEntityFormProps } from "../common/entity/EntityInterfaces";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useCallback } from "react";

export const attachmentFormSchema = {
  name: yup
    .string()
    .required("Attachment name is required")
    .typeError("Attachment name is required")
    .test({
      test: (value) => value === value?.trim(),
      message: "Whitespaces at the beginning or at the end of the name are not allowed",
    }),
};

export const AttachmentForm = ({
  fieldLabels,
  title,
  subtitle,
  initialValues,
  onSubmit,
  onCancel,
  loading,
  submitButtonLabel,
  event,
  typeId,
}: CustomTypedEntityFormProps<"attachments">) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<Dataset>({
    defaultValues: initialValues,
    resolver: yupResolver(yup.object(attachmentFormSchema)),
  });

  const prepareEntity = useCallback((entity: Partial<Dataset>) => {
    // We need to add back the time zone info
    entity.acquisitionDate = entity.acquisitionDate + "Z";

    if (entity.name !== entity.automaticName) {
      entity.isManuallyNamed = true;
    } else {
      entity.isManuallyNamed = false;
    }

    return entity;
  }, []);

  return (
    <div className="center-horizontally">
      <FormLayoutContainer>
        <FormHeader title={title} subtitle={subtitle} />
        <form
          onSubmit={(e) => {
            e.preventDefault();
          }}
          autoComplete="off"
          className={`form-horizontal ${styles.form_holder}`}
        >
          <InputFormField
            id={"name"}
            label={fieldLabels.name}
            errors={errors}
            register={register}
            autoFocus={false}
            required={false}
          />

          <TextareaFormField id={"notes"} label={fieldLabels.notes} errors={errors} register={register} />

          <FormButtons
            groupName="datasets"
            entityId={initialValues ? initialValues.id : undefined}
            onClose={onCancel}
            onSubmit={handleSubmit(async (entity) => await onSubmit(prepareEntity(entity)))}
            submitButtonLabel={submitButtonLabel || "Save changes"}
            disabled={isSubmitting}
            errors={errors}
          />
        </form>
      </FormLayoutContainer>
    </div>
  );
};
