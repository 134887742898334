import { useQuery } from "@tanstack/react-query";
import { AbstractBaseEntityApi } from "./BaseEntityApi";
import {
  IRelationModel,
  IGenericRequestParameters,
  IEntityMinimalModel,
  IModelWithIntId,
  INamedEntity,
  IOwnedEntity,
  IPaginationParameters,
  IPermissionedEntity,
  IRelatedEntity,
  IRelation,
  IRelationParameters,
  IUniqueEntity,
  IOwnedEntityParameters,
  EntityConstants,
  ICreatedRecordParameters,
  IModifiedRecordParameters,
  ICreationRecord,
  IModificationRecord,
  DefaultCreatedByRecordOrderTypeConsts,
  DefaultCreatedOnRecordOrderTypeConsts,
  DefaultModifiedByRecordOrderTypeConsts,
  DefaultModifiedOnRecordOrderTypeConsts,
  DefaultOrderTypeConsts,
  ICustomTypedEntity,
  TypedEntitySuggestionsModel,
  DefaultCustomTypeOrderTypeConsts,
  ITypedEntityCustomValuesSearchParameters,
  CustomFieldValuesSearchParametersITypedEntity,
  INamedEntityParameters,
} from "./GenericTypes";
import { ProjectPersonPermission } from "../ProjectPersonPermissions/ProjectPersonPermissions";
import {
  ICreatedRecordParametersTranslator,
  ITypedEntityCustomValuesSearchParametersTranslator,
  IGenericRequestParametersTranslator,
  IModifiedRecordParametersTranslator,
  IOwnedEntityParametersTranslator,
  IPaginationParametersTranslator,
  IRelationParametersTranslator,
  PropertyTranslator,
  INamedEntityParametersTranslator,
  ICreationRecordLabels,
  ICustomTypedEntityLabels,
  IModificationRecordLabels,
  IPermissionedEntityLabels,
  INamedEntityLabels,
  IUniqueEntityLabels,
  IRelatedEntityLabels,
} from "./GenericTranslator";

export const projectsConstants: EntityConstants<Project, ProjectFilters> = {
  resource: "projects",
  frontendIndexRoute: "projects",
  entitySingular: "project",
  entityPlural: "projects",
  icon: "folder",
};

export const ProjectFieldLabels: PropertyTranslator<Project> = {
  ...INamedEntityLabels,
  ...IPermissionedEntityLabels,
  ...ICreationRecordLabels,
  ...IModificationRecordLabels,
  ...ICustomTypedEntityLabels,
  ...IUniqueEntityLabels,
  ...IRelatedEntityLabels,
  id: "Project ID",
  projectPersonPermissions: "Project access",
  owner: "Owner",
  notes: "Notes",
  projectTags: "Tags",
} as const;

export interface ProjectRelations extends IRelationModel {
  samples: IRelation;
  datasets: IRelation;
  labNotebooks: IRelation;
  labNotebooksEntries: IRelation;
  labNotebooksEntryMentions: IRelation;
  inventories: IRelation;
}

export interface Project
  extends IPermissionedEntity,
    IOwnedEntity,
    IModelWithIntId,
    INamedEntity,
    IRelatedEntity<ProjectRelations>,
    IUniqueEntity,
    ICreationRecord,
    IModificationRecord,
    ICustomTypedEntity {
  notes?: string;
  projectTags?: IEntityMinimalModel<number>[];
  projectPersonPermissions?: ProjectPersonPermission[];
}
export interface ProjectSuggestions extends TypedEntitySuggestionsModel<Project["id"]> {}

export interface ProjectFilters
  extends IGenericRequestParameters<Project, ProjectSortingOptions>,
    IPaginationParameters,
    IRelationParameters,
    IOwnedEntityParameters,
    ICreatedRecordParameters,
    IModifiedRecordParameters,
    INamedEntityParameters,
    ITypedEntityCustomValuesSearchParameters<CustomFieldValuesSearchParametersITypedEntity> {
  notes?: string | null;
  projectTagIds?: number[] | null;
  personIds?: number[] | null;
  datasetIds?: number[] | null;
  sampleIds?: number[] | null;
  documentIds?: number[] | null;
  currentUserHasAddPermission?: boolean | null;
}
export const ProjectFiltersTranslator: PropertyTranslator<ProjectFilters> = {
  ...IGenericRequestParametersTranslator,
  ...IPaginationParametersTranslator,
  ...IOwnedEntityParametersTranslator,
  ...ICreatedRecordParametersTranslator,
  ...IModifiedRecordParametersTranslator,
  ...IRelationParametersTranslator,
  ...ITypedEntityCustomValuesSearchParametersTranslator,
  ...INamedEntityParametersTranslator,
  notes: "Notes",
  projectTagIds: "Project-Tag IDs",
  personIds: "Person IDs",
  datasetIds: "Dataset IDs",
  sampleIds: "Sample IDs",
  documentIds: "Document IDs",
  currentUserHasAddPermission: "Current user has add permission",
} as const;

export const ProjectSortingOptionsConsts = [
  ...DefaultOrderTypeConsts,
  ...DefaultCreatedOnRecordOrderTypeConsts,
  ...DefaultCreatedByRecordOrderTypeConsts,
  ...DefaultModifiedOnRecordOrderTypeConsts,
  ...DefaultModifiedByRecordOrderTypeConsts,
  ...DefaultCustomTypeOrderTypeConsts,
  "DATASET_COUNT_ASC",
  "DATASET_COUNT_DESC",
  "SAMPLE_COUNT_ASC",
  "SAMPLE_COUNT_DESC",
  "DOCUMENT_COUNT_ASC",
  "DOCUMENT_COUNT_DESC",
  "LAB_NOTEBOOK_COUNT_ASC",
  "LAB_NOTEBOOK_COUNT_DESC",
] as const;
export type ProjectSortingOptions = (typeof ProjectSortingOptionsConsts)[number];

export class ProjectsApi extends AbstractBaseEntityApi<Project> {
  suggestions = async (): Promise<IEntityMinimalModel[]> => await this.api.get("projects/suggestions");
  useSuggestions = () => () => useQuery([this.resource, "list", "suggestions"], this.suggestions);
}
