import * as d3 from "d3";
import React, { useCallback, useMemo, useRef, useState } from "react";
import { OverlayTrigger, Popover, Tab, Tabs } from "react-bootstrap";
import { useGet, useUnpaginateOrdered } from "../api/BaseEntityApi";
import { Parser, ParserFilters } from "../api/Parsers";
import {
  DatasetStatisticsByMethod,
  DatasetStatisticsByInstrument,
  DatasetStatisticsByType,
  DatasetStatistics,
} from "../api/Statistics";
import { LucideIcon } from "../common/icon/LucideIcon";
import { getLoadingState, LoadingWrapper } from "../common/LoadingWrapper";
import { Container } from "../common/panels/Container/Container";
import SinglePage from "../common/panels/SinglePage/SinglePage";
import styles from "./Dashboard.module.css";
import { MainChart } from "./Stats/MainChart";
import { PieChart } from "./Stats/PieChart";
import { StatsTable } from "./Stats/StatsTable";
import { StatCard } from "../common/loaders/Count/Count";

// Helper functions
const generate_dateaxis = (N = 365) => {
  let dates: string[] = [];
  for (let i = 1; i <= N; i++) {
    let currentDate = new Date(new Date().setUTCHours(0, 0, 0, 0));
    // currentDate.setUTCDate(currentDate.getUTCDate() - (N - (i + 1)));
    currentDate.setUTCDate(currentDate.getUTCDate() - (N - i));
    dates.push(currentDate.toISOString());
  }
  return dates.reverse();
};

const format_date = (date: Date) => {
  var d = date.getDate();
  var m = date.getMonth() + 1; //Month from 0 to 11
  var y = date.getFullYear();
  return "" + y + "-" + (m <= 9 ? "0" + m : m) + "-" + (d <= 9 ? "0" + d : d); // returns YYYY-MM-dd
  // return (m <= 9 ? '0' + m : m) + "/" + (d <= 9 ? '0' + d : d) + "/" + y; // returns dd/MM/YYYY
};
export interface SelectedState {
  [subgroup: string]: Boolean;
}
export interface SubgroupClasses {
  [id: string]: string;
}
interface MainchartEntry {
  date: string;
  subgroups: {
    [name: string]: number;
  };
}
export type MainchartEntries = MainchartEntry[];

export interface d3DataType {
  color: { [subgroup: string]: string };
  subgroups: string[];
  subgroupkeys: SubgroupClasses;
  data: MainchartEntries;
  selection: SelectedState;
}

export const Dashboard = () => {
  const [statsSelection, setStatsSelection] = useState<"by_method" | "by_instrument" | "by_type">("by_instrument");
  const [dataTypeInfo, setDataTypeInfo] = useState<"Method" | "Instrument" | "Data format">("Instrument");
  const [statistics, setStatistics] = useState<
    DatasetStatisticsByMethod | DatasetStatisticsByInstrument | DatasetStatisticsByType
  >(); // Coherent Mainchart data types
  // Chart states
  // const [dateAxis, setDateAxis] = useState<string[]>(generate_dateaxis()); // Generated date axis
  const dateAxis = useRef(generate_dateaxis());
  const [d3MainchartData, setd3MainchartData] = useState<d3DataType>(); // Processed Mainchart data
  const [d3PiechartData, setd3PiechartData] = useState<d3DataType>(); // Processed PieChart data
  const [dateSelection, setDateSelection] = useState<[Date, Date]>(); // Brush selection
  const [selection, setSelection] = useState<SelectedState>({});
  const [activeColor, setActiveColor] = useState(4); // Viridis as default
  const [currentBrush, setCurrentBrush] = useState<[Date, Date]>();
  const [parsers, setParsers] = useState<{ [parserId: string]: Parser }>();

  const {
    data: overviewStatistics,
    status: overviewStatisticsStatus,
    fetchStatus: overviewStatisticsFetchStatus,
  } = useGet<DatasetStatistics>("dataset_statistics");

  const statisticsByMethodQuery = useGet<DatasetStatisticsByMethod>("dataset_statistics/by_method", null, {
    enabled: statsSelection === "by_method",
  });
  const { data: statisticsByMethod } = statisticsByMethodQuery;

  const statisticsByInstrumentQuery = useGet<DatasetStatisticsByInstrument>("dataset_statistics/by_instrument", null, {
    enabled: statsSelection === "by_instrument",
  });
  const { data: statisticsByInstrument } = statisticsByInstrumentQuery;

  const statisticsByTypeQuery = useGet<DatasetStatisticsByType>("dataset_statistics/by_type", null, {
    enabled: statsSelection === "by_type",
  });
  const { data: statisticsByType } = statisticsByTypeQuery;

  const parsersQuery = useUnpaginateOrdered<Parser, ParserFilters>(
    "parsers",
    { orderBy: "NAME_ASC" },
    { enabled: statsSelection === "by_type" }
  );

  const { data: parserQueryResult } = parsersQuery;

  useMemo(() => {
    if (parserQueryResult) {
      setParsers(Object.fromEntries(parserQueryResult.map((parser) => [parser.id, parser])));
    }
  }, [parserQueryResult]);

  // Main hook to set the statistics
  useMemo(() => {
    if (statisticsByMethod && statsSelection === "by_method") setStatistics(statisticsByMethod);
    if (statisticsByInstrument && statsSelection === "by_instrument") setStatistics(statisticsByInstrument);
    if (statisticsByType && parsers && statsSelection === "by_type") {
      // We modify the keys with actual parser names here
      const res = Object.keys(statisticsByType).reduce(
        (map, type) => ({ ...map, [parsers[type].name]: statisticsByType[type] }),
        {}
      );
      setStatistics(res);
    }
  }, [parsers, statisticsByInstrument, statisticsByMethod, statisticsByType, statsSelection]);

  // Callback to set PieStats selection / color from MainChart
  const PieStatsCallback = useCallback((data: MainchartEntries) => {
    setd3PiechartData((prev) => {
      if (prev) {
        return {
          ...prev,
          data: data,
        };
      }
    });
  }, []);

  // Callback to change active Color selection
  const ColorCallback = useCallback((activeSel: number) => {
    setActiveColor(() => activeSel);
  }, []);

  // Callback to update start- end date states
  const DateSelectionCallback = useCallback((start: Date, end: Date) => {
    if (start instanceof Date && end instanceof Date) {
      setDateSelection(() => [start, end]);
    }
  }, []);

  // Callback to change current Brush selection
  const currentBrushCallback = useCallback((range: [Date, Date]) => {
    setCurrentBrush(() => range);
  }, []);

  // Callback to toggle current Subgroup selection
  const toggleSelection = useCallback((current_target?, new_selection?) => {
    if (current_target) {
      setSelection((selection) => ({ ...selection, [current_target]: !selection[current_target] }));
    }
    if (new_selection) {
      setSelection({ ...new_selection });
    }
  }, []);

  // Handle to switch between data source
  const tabSelect = useCallback((selectedKey: any) => {
    switch (selectedKey) {
      case 1:
        setStatsSelection("by_method");
        setDataTypeInfo("Method");
        break;
      case 2:
        setStatsSelection("by_instrument");
        setDataTypeInfo("Instrument");
        break;
      case 3:
        setStatsSelection("by_type");
        setDataTypeInfo("Data format");
        break;
    }
  }, []);

  // Color switch
  const colorSwitch = useCallback(
    (subgroups: string[]) => {
      let range: string[];
      if (activeColor && Array.isArray(subgroups) && subgroups.length && subgroups.length > 1) {
        switch (activeColor) {
          case 1:
            range = d3
              .scaleOrdinal()
              .range(d3.quantize((t) => d3.interpolateSpectral(t), subgroups.length).reverse())
              .range() as string[];
            break;
          case 2:
            range = d3
              .scaleOrdinal()
              .range(d3.quantize((t) => d3.interpolatePlasma(t), subgroups.length).reverse())
              .range() as string[];
            break;
          case 3:
            range = d3
              .scaleOrdinal()
              .range(d3.quantize((t) => d3.interpolateSinebow(t), subgroups.length).reverse())
              .range() as string[];
            break;
          case 4:
            range = d3
              .scaleOrdinal()
              .range(d3.quantize((t) => d3.interpolateViridis(t), subgroups.length).reverse())
              .range() as string[];
            break;
          case 5:
            range = d3
              .scaleOrdinal()
              .range(d3.quantize((t) => d3.interpolateCool(t), subgroups.length).reverse())
              .range() as string[];
            break;
          case 6:
            range = d3
              .scaleOrdinal()
              .range(d3.quantize((t) => d3.interpolateWarm(t), subgroups.length).reverse())
              .range() as string[];
            break;
          case 7:
            range = d3
              .scaleOrdinal()
              .range(d3.quantize((t) => d3.interpolateMagma(t), subgroups.length).reverse())
              .range() as string[];
            break;
          case 8:
            range = d3
              .scaleOrdinal()
              .range(d3.quantize((t) => d3.interpolateGreys(t * 0.8 + 0.3), subgroups.length).reverse())
              .range() as string[];
            break;
        }
      } else if (activeColor && Array.isArray(subgroups) && subgroups.length && subgroups.length === 1) {
        range = d3.scaleOrdinal().range(["#4ac16d"]).range() as string[];
      }
      return subgroups.reduce((map, key, index) => ({ ...map, [key]: range[index] }), {});
    },
    [activeColor]
  );

  useMemo(() => {
    if (statistics) {
      let _data: MainchartEntries = [];
      // Current keys (e.g. methods / instruments / dataformats)
      if (Object.keys(statistics).length === 0) {
        setStatistics(() => undefined);
      } else {
        const subgroups = Object.keys(statistics).sort();
        const subgroupkeys = subgroups.reduce((obj, cur, i) => {
          return { ...obj, [cur.toString()]: "subgroupclass" + i.toString() };
        }, {});
        // Generate structure for MainChart
        const N = dateAxis.current.length;
        for (let i = 0; i < N; i++) {
          let _dic: any = {};
          for (let key of subgroups) {
            _dic[key] = statistics[key][i];
          }
          _data.push({ date: dateAxis.current[i], subgroups: _dic });
        }
        _data.reverse();
        // Get current color
        const _color = colorSwitch(subgroups);

        // State to handle active selections
        const this_selection = subgroups.reduce((obj, cur, i) => ({ ...obj, [cur]: false }), {});
        setSelection({ ...this_selection });
        if (_color) {
          setd3MainchartData({
            color: _color,
            subgroups: subgroups,
            subgroupkeys: subgroupkeys,
            data: _data,
            selection: Object.keys(this_selection).length === 0 ? selection : this_selection,
          });
          setd3PiechartData({
            color: _color,
            subgroups: subgroups,
            subgroupkeys: subgroupkeys,
            data: _data,
            selection: Object.keys(this_selection).length === 0 ? selection : this_selection,
          });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [colorSwitch, statistics]);

  // Popovers
  const popover_total = (
    <Popover id="popover-highlights">These numbers represent total values since the creation of this group.</Popover>
  );
  const popover_acqtimeline = (
    <Popover id="popover-acqtimeline">This chart represents acquired datasets to a maximum of 12 months.</Popover>
  );
  const popover_piechart = (
    <Popover id="popover-piechart">
      This chart represents statistics based on the current selection of the acquisition timeline.
    </Popover>
  );

  const { status, fetchStatus } = getLoadingState([
    statisticsByInstrumentQuery,
    statisticsByMethodQuery,
    statisticsByTypeQuery,
    parsersQuery,
  ]);
  return (
    <SinglePage>
      <SinglePage.Head>
        <SinglePage.Head.Label>Dashboard</SinglePage.Head.Label>
        {/* <SinglePage.Head.Controls><FID /></SinglePage.Head.Controls> */}
      </SinglePage.Head>
      <SinglePage.Content style={{ overflow: "auto", height: "fit-content" }}>
        <div className={styles.content}>
          <div className={styles.content_highlights}>
            <Container
              title="Total"
              controls={
                <OverlayTrigger placement="left" overlay={popover_total}>
                  <LucideIcon name="circle-help" color={"var(--gray-400)"} />
                </OverlayTrigger>
              }
            >
              <LoadingWrapper status={overviewStatisticsStatus} fetchStatus={overviewStatisticsFetchStatus}>
                <div className={styles.statcard_container}>
                  {overviewStatistics &&
                    Object.entries(overviewStatistics).map(([key, value], index) => (
                      <StatCard key={index} label={key} count={value} />
                    ))}
                </div>
              </LoadingWrapper>
            </Container>
          </div>
          <div className={styles.content_mainchart}>
            <Container
              title="Acquisition timeline"
              controls={
                <OverlayTrigger placement="left" overlay={popover_acqtimeline}>
                  <LucideIcon name="circle-help" color={"var(--gray-400)"} />
                </OverlayTrigger>
              }
            >
              <div className={styles.flex_col} style={{ overflow: "hidden", height: "min-content" }}>
                <Tabs defaultActiveKey={2} id="tabs-menue" bsStyle="tabs" onSelect={(k) => tabSelect(k)}>
                  <Tab bsClass={styles.panel_left_tab} eventKey={1} title={"Methods"} />
                  <Tab bsClass={styles.panel_left_tab} eventKey={2} title={"Instruments"} />
                  <Tab bsClass={styles.panel_left_tab} eventKey={3} title={"Data formats"} />
                </Tabs>
                <LoadingWrapper status={status} fetchStatus={fetchStatus}>
                  {d3MainchartData && (
                    <MainChart
                      d3Data={d3MainchartData}
                      dateCallback={DateSelectionCallback}
                      pieStatsCallback={PieStatsCallback}
                      activeColor={activeColor}
                      colorCallback={ColorCallback}
                      selection={selection}
                      toggleSelection={toggleSelection}
                      currentBrush={currentBrush}
                      currentBrushCallback={currentBrushCallback}
                    />
                  )}
                </LoadingWrapper>
              </div>
            </Container>
          </div>
          <div className={styles.content_piechart}>
            <Container
              title="Statistics"
              controls={
                <OverlayTrigger placement="left" overlay={popover_piechart}>
                  <LucideIcon name="circle-help" color={"var(--gray-400)"} />
                </OverlayTrigger>
              }
            >
              <div className={styles.flex_col} style={{ overflow: "hidden", height: "min-content" }}>
                {d3PiechartData && (
                  <>
                    <div className={"center-horizontally"}>
                      <h3>{dateSelection?.map((d) => format_date(d)).join(" to ")}</h3>
                    </div>
                    <PieChart d3Data={d3PiechartData} selection={selection} toggleSelection={toggleSelection} />
                    <StatsTable d3Data={d3PiechartData} type={dataTypeInfo} selection={selection} />
                  </>
                )}
              </div>
            </Container>
          </div>
        </div>
      </SinglePage.Content>
    </SinglePage>
  );
};
