import produce from "immer";
import { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";

import { API } from "../api/Api";
import { useTranslatedFieldUpdate } from "../ViewerLayout/ViewerLayoutHooks";
import {
  Annotation,
  Datatrack,
  DatatrackNumericArray,
  Track,
  TrackData,
  TrackMatrix,
  TrackMatrixData,
  trackSettingsList,
  TrackXY,
  TrackXYData,
} from "../ViewerLayout/ViewerLayoutTypes";
import { needUpdate, updateCopyTranslated } from "../ViewerLayout/ViewerLayoutUtils";
import { offsetType, trackState, viewerModeType, viewerState, zoomModes } from "../ViewerLayout/ViewerTypes";
import { GraphViewer } from "./GraphViewer";
import { AXIS, CROSSHAIR, LinearGenerator, VIEW } from "./GraphViewerTypes";
import { getTilesByDomainAsync, getTilesForTrackLevel } from "./TrackDataManager";

// import { TrackSettings, annotationType } from "./ViewerNavigation";

// export type drawType = "line" | "dots" | "heatmap" | "contour" | "filled";
// export type normalizeType = "0-1" | "-1-1";
// export type annotationVisibilityType = "tics" | "labels";

type stateTypes = "crosshair" | "axis";
const stateToModeMappings: Record<stateTypes, Record<string, number>> = {
  crosshair: {
    showXLine: CROSSHAIR.XLINE,
    showYLine: CROSSHAIR.YLINE,
    showXValue: CROSSHAIR.XVALUE,
    showYValue: CROSSHAIR.YVALUE,
  },
  axis: {
    fixX: AXIS.FIXX,
    fixY: AXIS.FIXY,
  },
};

const stateToModeMapper = (type: stateTypes, state: Record<string, boolean>) => {
  return Object.entries(state)
    .filter(([k, v]) => v)
    .reduce((sum, a) => sum + stateToModeMappings[type][a[0]], 0);
};

type GraphProps = {
  width?: number;
  height?: number;
  tracks: Record<string, Track>;
  datatracks: Record<string, Datatrack>;
  annotations: Annotation[];
  changeTrackSettings: (list: trackSettingsList) => void;
  changeTrackState: (states: { id: string; state: trackState }[]) => void;
  tracksToZoom: string[];
  activeTrack: string;
  viewerMode: viewerModeType;
  autoZoom: boolean;
  zoomUpdate: boolean;
  onZoomUpdate: () => void;
  clickListener?: (pos: { x: number | undefined; y: number | undefined }) => void;
  setViewerState?: (state: viewerState) => void;
  initViewerState?: viewerState;
  interactiveMode?: boolean;
  // setOffset: (offset: offsetType) => void;
  offset: offsetType;
  api: API;
};
export const Graph = forwardRef((props: GraphProps, ref) => {
  const {
    width,
    height,
    tracks,
    datatracks,
    tracksToZoom,
    activeTrack,
    annotations,
    changeTrackState,
    viewerMode,
    autoZoom,
    offset,
    zoomUpdate,
    onZoomUpdate,
    clickListener,
    setViewerState,
    initViewerState,
    interactiveMode,
    api,
  } = props;

  const targetRef = useRef<HTMLDivElement>(null);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const [active, setActive] = useState<string | undefined>(undefined);
  const [domain, setDomain] = useState<{ range: number[][]; points: number[] }>();
  const [generic, setGeneric] = useState<Record<string, number>>({});
  const [firstDraw, setFirstDraw] = useState<boolean>(true);
  const [activeTracks, setActiveTracks] = useState<string[]>([]);
  const [visibleTracks, setVisibleTracks] = useState<string[]>([]);
  const [datatrackIds, setDatatrackIds] = useState<string[]>([]);

  useImperativeHandle(ref, () => ({
    exportAs(type: "svg" | "png" | "jpeg" | "jpg" | "csv") {
      // console.log("Graph export", type);
      let svg = "";
      switch (type) {
        case "svg":
          {
            const element = document.createElement("a");
            const blob = new Blob([viewer?.getSVGString() || ""], { type: "image/svg+xml;charset=utf-8" });
            element.href = URL.createObjectURL(blob);
            element.download = "graph.svg";
            document.body.appendChild(element); // Required for this to work in FireFox
            element.click();
          }
          break;
        case "png":
        case "jpeg":
        case "jpg":
          {
            svg = viewer?.getSVGString() || "";
            const name = "graph." + type;
            const mimeType = "image/" + (type === "png" ? "png" : "jpeg");
            viewer?.getImageBlob(
              svg,
              (blob: any) => {
                const element = document.createElement("a");
                element.href = URL.createObjectURL(blob);
                element.download = name;
                document.body.appendChild(element); // Required for this to work in FireFox
                // console.log("blob", blob);
                element.click();
              },
              mimeType
            );
          }
          break;
        case "csv":
          createCSV();
          break;
      }
    },
    getTranslateScale(): offsetType {
      return viewer?.getTranslateScale() || {};
    },
  }));

  // const onRedraw = useCallback(
  //   (domain: { range: number[][]; points: number[] }) => {
  //     setDomain(domain);
  //     if (setViewerState)
  //       setViewerState({
  //         domainX: domain.range[0],
  //         domainY: domain.range[1],
  //       });
  //   },
  //   [setViewerState]
  // );

  const viewer: GraphViewer | undefined = useMemo(() => {
    if (targetRef.current) {
      const viewer = new GraphViewer(targetRef.current, {
        width: dimensions.width,
        height: dimensions.height,
        interactive: interactiveMode ?? true,
      });

      const onRedraw = (domain: { range: number[][]; points: number[] }) => setDomain(domain);
      viewer.setRedrawListener(onRedraw);
      return viewer;
    }
    return undefined;
  }, [targetRef.current]);

  useEffect(() => {
    if (viewer && initViewerState) {
      if (initViewerState?.domainX) viewer.setDomainX(initViewerState.domainX);
      if (initViewerState?.domainY) viewer.setDomainY(initViewerState.domainY);
      viewer.redraw();
    }
  }, [viewer, initViewerState]);

  useEffect(() => {
    if (viewer) {
      const onRedraw = (domain: { range: number[][]; points: number[] }) => {
        setDomain(domain);
        if (setViewerState)
          setViewerState({
            domainX: domain.range[0],
            domainY: domain.range[1],
          });
      };
      viewer.setRedrawListener(onRedraw);
    }
  }, [viewer, setViewerState]);

  const resetZoom = useCallback(() => {
    // console.log("resetZoom", zoomUpdate);
    if (viewer && autoZoom && zoomUpdate) viewer.resetZoom(false);
    onZoomUpdate();
  }, [viewer, autoZoom, zoomUpdate, onZoomUpdate]);

  const setDatatracks = useCallback(
    (data: TrackData[], forced: boolean = false) => {
      if (!viewer) return;
      let update = false;
      data.forEach((d) => {
        // console.log("data", Object.values(d.data));
        // for (let q of Object.values(d.data) as TrackXYData[][]) {
        //   //   //   // let id = q.id.split("@");
        //   for (let p of q) {
        //    console.log(" -> data", p);
        //   }
        // }
        if (d.type === "matrix_real" || d.type === "XY_real") update = viewer.setTrackData(d, forced, false) || update;
      });
      // if (update) console.log(" --> update while fetch", data.length);
      if (update) viewer.redraw();
    },
    [viewer]
  );

  useEffect(() => {
    if (!domain || !viewer) return;
    let isMounted = true;

    changeTrackState(
      Object.values(tracks)
        .filter((track) => !track.settings.visible && track.settings.state === trackState.loading)
        .map((track) => ({ id: track.id, state: trackState.ready }))
    );

    // const ids = Object.values(datatracks).map((t) => t.levels?.[0].tiles[0].id ?? "");
    const [ids, forced] = updateCopyTranslated(datatrackIds, datatracks, (datatracks) =>
      Object.values(datatracks).map((t) => t.levels?.[0].tiles?.[0]?.id ?? "")
    );
    // let forced = false;
    if (forced) setDatatrackIds(ids);

    const setDat = (data: TrackData[]) => {
      if (isMounted) return setDatatracks(data, forced);
    };

    // const trackList = Object.values(tracks).filter((track) => track.settings.visible && track.generation < 1);
    const trackList = Object.values(tracks).filter((track) => track.settings.visible && track.generation < 1);
    getTilesByDomainAsync(api, trackList, datatracks, domain, setDat, changeTrackState);

    return () => {
      isMounted = false;
    };
  }, [domain, viewer, datatracks, changeTrackState, setDatatracks]);

  const csvContentFromTrack = useCallback((tracks: { name: string; data: TrackData }[]) => {
    type columnType = number | string;
    tracks = tracks.filter((track) => track.data);
    const len = tracks.length;
    if (len < 1) return "";
    const count: number[] = new Array(len).fill(0);
    const emptyLine: columnType[][] = new Array(len).fill(0);
    const tile: number[] = new Array(len).fill(0);
    const done: boolean[] = new Array(len).fill(false);
    const range: {
      row: number[];
      col: number[];
      min: number[];
      max: number[];
      xg: LinearGenerator;
      yg: LinearGenerator;
    }[] = new Array(len).fill([]);

    const lines: string[] = [];
    let columns: columnType[] = [];

    for (let j = 0; j < len; j++) {
      if (tracks[j].data.type === "XY_real") {
        columns.push(tracks[j].name + ".x", tracks[j].name + ".y");
        const data = (tracks[j].data as TrackXYData).data;
        emptyLine[j] = ["", ""];
        if (data.x.length < 1 || data.y.length < 1 || data.x[0].count < 1 || data.y[0].count < 1) done[j] = true;
      } else if (tracks[j].data.type === "matrix_real") {
        const data = (tracks[j].data as TrackMatrixData).data;
        const col = [Infinity, -Infinity];
        const row = [Infinity, -Infinity];
        const min = [Infinity, Infinity];
        const max = [-Infinity, -Infinity];
        data.matrix.forEach((m) => {
          if (col[0] > m.range[0][0]) col[0] = m.range[0][0];
          if (col[1] < m.range[0][1]) col[1] = m.range[0][1];
          if (row[0] > m.range[1][0]) row[0] = m.range[1][0];
          if (row[1] < m.range[1][1]) row[1] = m.range[1][1];
          if (min[0] > m.min[0]) min[0] = m.min[0];
          if (min[1] > m.min[1]) min[1] = m.min[1];
          if (max[0] < m.max[0]) max[0] = m.max[0];
          if (max[1] < m.max[1]) max[1] = m.max[1];
        });
        let xg = new LinearGenerator(min[0], max[0], col[1] - col[0]);
        let yg = new LinearGenerator(min[1], max[1], row[1] - row[0]);

        range[j] = { col: col, row: row, min: min, max: max, xg: xg, yg: yg };
        emptyLine[j] = new Array(col[1] - col[0] + 1).fill("");
        const r = range[j];
        columns.push(tracks[j].name);
        for (let col = r.col[0]; col < r.col[1]; col++) {
          columns.push(xg.reverse(col));
          // columns.push("col." + (col + 1));
        }
      }
    }
    if (columns.length < 1) return "";
    lines.push(columns.join("\t"));

    while (done.some((d) => !d)) {
      columns = [];
      for (let j = 0; j < len; j++) {
        if (done[j]) {
          columns.push(...emptyLine[j]);
          continue;
        }
        if (tracks[j].data.type === "XY_real") {
          const data = (tracks[j].data as TrackXYData).data;
          // const t = tile[j] as keyof typeof data.x;
          const t = tile[j];
          const k = count[j];
          columns.push(data.x[t].data[k], data.y[t].data[k]);
          // console.log(i, columns);
          count[j]++;
          if (count[j] >= data.x[t].count) {
            count[j] = 0;
            tile[j]++;
            if (tile[j] >= data.x.length || tile[j] >= data.y.length) done[j] = true;
          }
        } else if (tracks[j].data.type === "matrix_real") {
          const data = (tracks[j].data as TrackMatrixData).data;
          let t = tile[j];
          const r = range[j];
          const row = count[j];
          columns.push(r.yg.reverse(row));
          let index = 0;
          let i = 0;
          // let diff = data.matrix[t].range[0][1] - data.matrix[t].range[0][0];
          // let xg = new LinearGenerator(data.matrix[t].min[0], data.matrix[t].max[0], data.matrix[t].size[0]);
          // let yg = new LinearGenerator(
          //   data.matrix[t].min?.[1] ?? 0,
          //   data.matrix[t].max?.[1] ?? 1,
          //   data.matrix[t].size?.[1] ?? 1
          // );

          for (let col = r.col[0]; col < r.col[1]; col++) {
            if (col >= data.matrix[t].range[0][1]) {
              // console.log("col", col);
              i = 0;
              t++;
            }
            index = row * data.matrix[t].size[0] + i;
            columns.push(data.matrix[t].data[index]);
            i++;
            // columns.push(data.matrix[t].data);
            // console.log(col, "<", data.matrix[t].data);
            // console.log(col >= data.matrix[t].range[0][0] && col < data.matrix[t].range[0][1]);
          }
          count[j]++;
          if (count[j] >= data.matrix[t].size[1]) {
            count[j] = 0;
            let search = -1;
            for (let i = t + 1; i < data.matrix.length; i++) {
              if (data.matrix[i].range[0][0] === range[j].col[0]) {
                search = i;
                break;
              }
            }
            if (search >= 0) {
              tile[j] = search;
              count[j] = 0;
            } else done[j] = true;
          }
        }
      }
      lines.push(columns.join("\t"));
      // if (lines.length > 10) break;
    }
    // console.log(lines.join("\n"));
    return lines.join("\n");
  }, []);

  const createCSV = useCallback(() => {
    const trackList = Object.values(tracks).filter((track) => track.settings.visible);

    getTilesForTrackLevel(
      api,
      0,
      trackList,
      datatracks,
      (data: TrackData[]) => {
        const trackMap = Object.fromEntries(data.map((d) => [d.id, d]));
        const csv = csvContentFromTrack(
          trackList.map((track) => {
            return { name: track.name, data: trackMap[track.id] };
          })
        );
        const element = document.createElement("a");
        const blob = new Blob([csv || ""], { type: "text/csv;charset=utf-8" });
        element.href = URL.createObjectURL(blob);
        element.download = "datapoints.csv";
        document.body.appendChild(element); // Required for this to work in FireFox
        element.click();
      },
      () => null
    );
  }, [api, tracks, datatracks]);

  useEffect(() => {
    // if (offset.getStepSize === undefined) viewer?.getTranslateScale();
    // console.log("graph offset", offset.translateX, offset.translateY);
    if (viewer && offset.translateX !== undefined && offset.translateY !== undefined) {
      viewer.setTranslateXY(offset.translateX, offset.translateY, true);
      resetZoom();
      viewer.redraw();
    }

    // if (viewer) viewer.setTranslateXY(offset.translateX, 0, true);
  }, [viewer, offset.translateX, offset.translateY, resetZoom]);

  useEffect(() => {
    if (viewer) {
      // console.log("change activeTrack", active, activeTrack, false);
      if (active !== undefined) {
        // console.log("change activeTrack", tracks[active]);
        const state = active in tracks ? tracks[active].settings.visible : false;
        // tracks
        viewer?.setTrackVisibility(active, state);
      }
      viewer?.setTrackVisibility(activeTrack, true);
      setActive(activeTrack);
      viewer.setOffsetPadding(20);
      viewer.redraw();
    }
  }, [activeTrack, viewer]);

  useEffect(() => {
    // if (viewer) console.log("Graph zoom to tracks", tracksToZoom, Object.keys(viewer.tracks));
    if (viewer && tracksToZoom.length > 0) viewer.resetZoom(true, tracksToZoom as any);
  }, [tracksToZoom, viewer]);

  useEffect(() => {
    if (viewer) {
      const element = targetRef.current as Element;
      const { width, height } = element.getBoundingClientRect();

      // const percentWidth = bound.width / window.innerWidth;
      // setDimensions({ width: bound.width - 5, height: bound.height, percentWidth: percentWidth });
      setDimensions({ width, height });
      if (width > 10 && height > 10) viewer.resize(width, height - 5);
      // console.log("dimensions", dimensions.width, dimensions.height);
    }
  }, [width, height, viewer]);

  useEffect(() => {
    if (viewer && clickListener) viewer.setClickListener(clickListener);
  }, [clickListener]);

  useEffect(() => {
    if (viewer) viewer?.setViewMode(viewerMode.stacked ? VIEW.OFFSET : VIEW.ONETRACK, true);
  }, [viewerMode.stacked, viewer]);

  useEffect(() => {
    if (viewer) viewer.setBoxZoom(viewerMode.zoomMode === zoomModes.boxZoom);
  }, [viewerMode.zoomMode, viewer]);

  useEffect(() => {
    if (viewer) viewer.setAxisMode(stateToModeMapper("axis", viewerMode.axisMode));
  }, [viewerMode.axisMode, viewer]);

  useEffect(() => {
    if (viewer) viewer.setCrosshairMode(stateToModeMapper("crosshair", viewerMode.crosshairMode));
  }, [viewerMode.crosshairMode, viewer]);

  useEffect(() => {
    if (viewer) viewer.setAnnotations(annotations);
  }, [viewer, annotations]);

  const trackMinMax = useTranslatedFieldUpdate(
    tracks,
    (tracks) => {
      return Object.fromEntries(
        Object.values(tracks)
          .filter((track) => track.type === "XY_real")
          .map((track) => [
            track.id,
            {
              ...track.data,
              min: [
                datatracks[(track as TrackXY).data.x ?? ""]?.min[0],
                datatracks[(track as TrackXY).data.y ?? ""]?.min[0],
              ],
              max: [
                datatracks[(track as TrackXY).data.x ?? ""]?.max[0],
                datatracks[(track as TrackXY).data.y ?? ""]?.max[0],
              ],
            },
          ])
      );
    },
    {}
  );

  useEffect(() => {
    if (viewer) {
      let update = false;
      const visible: string[] = [];
      const active: string[] = [];
      Object.values(tracks)
        .filter((track) => track.type === "XY_real" || track.type === "matrix_real")
        .forEach((track) => {
          // console.log(" --- add track ----", track);
          // console.log(" --- add track ----", track.name, track.settings.active);
          // console.log(" --- add track ----", track.settings.color.generate?.(0), track.settings.color.generate?.(1));
          // console.log(" --- add track ----", datatracks[(track as TrackXY).data.x ?? ""].levels?.[0].tiles[0].id);

          if (track.id in trackMinMax) {
            track = Object.assign({}, track);
            track.data = trackMinMax[track.id];
          }

          if (track.settings.active) {
            active.push(track.id);
            if (track.settings.visible) visible.push(track.id);
          }

          update = viewer.setTrack(track as TrackXY | TrackMatrix, false) || update;
          // console.log(" --- add track ----", viewer.getTrackColorAsColorName(id), viewer.getTrackColorAsColorType(id));
          // console.log(" --- add track ----", viewer.getTrackColorAsColorId(id));
          // console.log(" --- add track ----", viewer.getTrackColorId(id));

          // console.log(" --- viewer track ----", viewer.getTrackColor(id));
          // console.log(" --- get track ----", viewer.getTrackContours(id));

          if (track.generation > 0) {
            if (track.type === "XY_real") {
              if (generic[track.id] !== track.generation) {
                const d: TrackXYData = {
                  id: track.id,
                  type: track.type,
                  data: {
                    x: [datatracks[track.data.x ?? ""] as DatatrackNumericArray],
                    y: [datatracks[track.data.y ?? ""] as DatatrackNumericArray],
                  },
                };
                viewer.setTrackData(d, true);

                // viewer.setTrackData({
                //   id: track.id,
                //   type: track.type,
                //   data: { x: [datatracks[track.data.x ?? ""]], y: datatracks[track.data.y ?? ""] },
                // } as Track);
                setGeneric(
                  produce(generic, (next) => {
                    next[track.id] = track.generation;
                  })
                );
              }
            }
          }

          // changeViewerTrackParameter(track.id, {
          //   colorID: viewer.getTrackColorAsColorName(id),
          //   color: viewer.getTrackColorAsColorType(id),
          // } as trackParameterType | { color: string[] });
          // viewer.getAxisMin(id);

          // changeTrackParameter([
          //   {
          //     id: track.id,
          //     parameter: {
          //       color: viewer.getTrackColor(id),
          //       // contours: viewer.getTrackContours(id),
          //     },
          //   },
          // ]);

          // console.log(" --- add track ----", track.parameter.color);
        });

      if (needUpdate(visibleTracks, visible)) {
        const activeChange = needUpdate(activeTracks, active);
        if (autoZoom && !activeChange) viewer.resetZoom(false);
        else setActiveTracks(active);
        setVisibleTracks(visible);
      }

      if (update && firstDraw) {
        if (!initViewerState) viewer.resetZoom(false);
        setFirstDraw(false);
      }
      if (update) viewer.redraw();
      // viewer.redraw();
      // viewer.stopLoading();
    }
  }, [viewer, tracks, autoZoom]);

  // useEffect(() => {
  //   if (autoZoom && viewer) viewer.resetZoom();
  // }, [autoZoom, viewer]);

  return <div ref={targetRef} style={{ height: 100 + "%" }} />;
});
