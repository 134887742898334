import { ProjectTagForm } from "./ProjectTagForm";
import { EntityAddForm, EntityAddFormPage } from "../common/entity/EntityAddForm";
import { EntityCloneForm, EntityCloneFormPage } from "../common/entity/EntityCloneForm";
import { EntityCloneFormProps, EntityDetailViewProps, EntityEditFormProps } from "../common/entity/EntityInterfaces";
import { EntityEditForm, EntityEditFormPage } from "../common/entity/EntityEditForm";
import { EntityDetailPage, EntityDetailView } from "../common/entity/EntityDetailPage";
import { ProjectTagsDetail } from "./ProjectTagsDetail";
import { EntityTablePage } from "../common/entity/EntityTablePage";
import { ProjectTagsTable } from "./Table/ProjectTagsTable";

// Add
export const ProjectTagAddForm = () => {
  return <EntityAddForm entityTypeId={"projectTags"}>{(props) => <ProjectTagForm {...props} />}</EntityAddForm>;
};

export const ProjectTagAddFormPage = () => {
  return (
    <EntityAddFormPage>
      <ProjectTagAddForm />
    </EntityAddFormPage>
  );
};

// Clone
export const ProjectTagCloneForm = ({ id }: EntityCloneFormProps<"projectTags">) => {
  return (
    <EntityCloneForm id={id} entityTypeId={"projectTags"}>
      {(props) => <ProjectTagForm {...props} />}
    </EntityCloneForm>
  );
};

export const ProjectTagCloneFormPage = () => {
  return <EntityCloneFormPage<"projectTags"> isIntId>{(id) => <ProjectTagCloneForm id={id} />}</EntityCloneFormPage>;
};

// Edit
export const ProjectTagEditForm = ({ id }: EntityEditFormProps<"projectTags">) => {
  return (
    <EntityEditForm id={id} entityTypeId={"projectTags"} filters={{}}>
      {(props) => <ProjectTagForm {...props} />}
    </EntityEditForm>
  );
};

export const ProjectTagEditFormPage = () => {
  return <EntityEditFormPage<"projectTags"> isIntId>{(id) => <ProjectTagEditForm id={id} />}</EntityEditFormPage>;
};

// Detail
export const ProjectTagsDetailView = ({ id }: EntityDetailViewProps<"projectTags">) => {
  return (
    <EntityDetailView id={id} entityTypeId={"projectTags"} filters={{}}>
      {(props) => <ProjectTagsDetail {...props} />}
    </EntityDetailView>
  );
};

export const ProjectTagDetailsPage = () => {
  return <EntityDetailPage<"projectTags"> isIntId>{(id) => <ProjectTagsDetailView id={id} />}</EntityDetailPage>;
};

export const ProjectTagsTablePage = () => {
  return <EntityTablePage entityTypeId={"projectTags"}>{(props) => <ProjectTagsTable {...props} />}</EntityTablePage>;
};
