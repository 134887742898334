import React from "react";
import { HierarchyVirtualizedSelectFormDropdown } from "../HierarchySelectForm";
import {
  CustomTypeSuggestions,
  CustomTypeFilters,
  customTypeConstants,
  CustomTypeEntityType,
} from "../../../../api/CustomTypes";
import { IEntityMinimalModel, StringIndexedDict } from "../../../../api/GenericTypes";
import { GenericControllerProvider } from "../../../forms/common/GenericControllerProvider";
import { Control, Path, PathValue, useForm } from "react-hook-form";
import { Modal } from "../../../../ELN/common/ELNModal/ELNModal";
import { inventoriesConstants } from "../../../../api/Inventories";
import { CustomTypesVirtualizedSelectForm } from "../../../forms/EntityForms/formsVirtualized/CustomTypesVirtualizedSelectForm";

interface InventoryTypesSelectFormProps<Entity extends StringIndexedDict> {
  id: Path<Entity>;
  control: Control<Entity>;
  defaultValue?: PathValue<Entity, Path<Entity>>;
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export const InventoryTypesSelectForm = <Entity extends StringIndexedDict>(
  props: InventoryTypesSelectFormProps<Entity>
) => {
  return (
    <GenericControllerProvider<Entity>
      id={props.id}
      control={props.control}
      defaultValue={props.defaultValue}
      shouldUnregister={false}
    >
      {({ value, onChange }, fieldState, { errors }) => (
        <InventoryTypesSelectFormModal
          initialValues={value}
          onChangeCallback={onChange}
          showModal={props.showModal}
          setShowModal={props.setShowModal}
          entitySelectOption={"Inventory"}
        />
      )}
    </GenericControllerProvider>
  );
};

interface InventoryTypesIntermediateForm {
  fieldValues: IEntityMinimalModel<number>[] | undefined | null;
}
interface InventoryTypesSelectFormModalProps {
  initialValues: InventoryTypesIntermediateForm["fieldValues"];
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  onChangeCallback: (value: InventoryTypesIntermediateForm["fieldValues"]) => void;
  entitySelectOption: CustomTypeEntityType;
}
const InventoryTypesSelectFormModal = (props: InventoryTypesSelectFormModalProps) => {
  const { control } = useForm<InventoryTypesIntermediateForm>({
    values: props.initialValues ? { fieldValues: props.initialValues } : undefined,
  });
  return (
    <GenericControllerProvider<InventoryTypesIntermediateForm>
      id={"fieldValues"}
      control={control}
      shouldUnregister={false}
    >
      {({ value, onChange }, fieldState, { errors }) => (
        <Modal isOpen={props.showModal} onClose={props.setShowModal}>
          <Modal.Title>Browse {inventoriesConstants.entityPlural}</Modal.Title>
          <Modal.Body>
            <div
              className="flex col-nowrap form-group center-horizontally"
              style={{
                width: "70vw",
                height: "75vh",
                justifyContent: "flex-start",
                overflow: "hidden",
                marginBottom: "0",
              }}
            >
              <HierarchyVirtualizedSelectFormDropdown<CustomTypeSuggestions, CustomTypeFilters>
                entityConstants={customTypeConstants}
                filters={{ excludeNonInventories: true }}
                value={value as any}
                onChange={onChange}
              />
            </div>
          </Modal.Body>
          <Modal.Controls style={{ width: "100%" }}>
            <div className="flex row-nowrap align-center gap-5" style={{ maxWidth: "70vw", overflow: "hidden" }}>
              <CustomTypesVirtualizedSelectForm
                id={"fieldValues"}
                control={control}
                filters={{ entityTypes: [props.entitySelectOption as CustomTypeEntityType] }}
                isMulti
                horizontal
                showControls
                // disabled
                hasLabel={false}
                uncontained
                placeholder="Select inventory"
              />
              <button className="btn btn-default" onClick={() => props.setShowModal(false)}>
                Cancel
              </button>
              <button
                className="btn btn-primary"
                onClick={() => {
                  props.onChangeCallback(value as any);
                  props.setShowModal(false);
                }}
              >
                Select
              </button>
            </div>
          </Modal.Controls>
        </Modal>
      )}
    </GenericControllerProvider>
  );
};
