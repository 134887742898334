import { useCallback, useContext, useState } from "react";
import { SessionContext } from "../../contexts/SessionContext";
import { EntityConstants, GenericEntity, IGenericRequestParameters } from "../../../api/GenericTypes";
import { showtoast } from "../../overlays/Toasts/showtoast";
import { ColumnsSettings } from "../ColumnsSelector/ColumnsSelector";

export type CsvObjectLevelOptions = "Id" | "Name" | "IdName" | "Uid" | "UidName" | "Link" | "All";
export const csvObjectLevelOptionsToLabel = (level: CsvObjectLevelOptions, delimiter: string = ":") => {
  switch (level) {
    case "IdName":
      return `Id${delimiter}Name`;
    case "UidName":
      return `Uid${delimiter}Name`;
    case "All":
      return `Id${delimiter}Name${delimiter}Uid${delimiter}Version${delimiter}Link`;
    default:
      return level as string;
  }
};
export const CsvObjectLevelOptionsForm = (
  ["Id", "Name", "IdName", "Uid", "UidName", "Link", "All"] as CsvObjectLevelOptions[]
).map((c) => ({
  id: c,
  label: c,
  value: c,
}));

export interface CsvSettings {
  delimiter: string;
  nullValue: string;
  listDelimiter: string;
  propertyDelimiter: string;
  csvObjectLevel: CsvObjectLevelOptions;
  includeHeader?: boolean;
}
interface DownloadCsvProps<
  Entity extends GenericEntity,
  Filters extends { [property: string]: any } & IGenericRequestParameters<Entity, Filters["orderBy"]>
> {
  filters: { [property: string]: any } & IGenericRequestParameters<Entity, Filters["orderBy"]>;
  csvSettings: CsvSettings;
  csvColumnSettings: ColumnsSettings<Entity>;
}

export const useDownloadCSV = <
  Entity extends GenericEntity,
  Filters extends { [property: string]: any } & IGenericRequestParameters<Entity, Filters["orderBy"]>
>({
  entityConstants,
  filters,
  csvSettings = {
    delimiter: ";",
    listDelimiter: ",",
    nullValue: "-",
    propertyDelimiter: ":",
    csvObjectLevel: "Name",
    includeHeader: true,
  },
  columnSettings,
  fileNameOverride,
}: {
  entityConstants: EntityConstants;
  filters?: Filters;
  csvSettings?: CsvSettings;
  columnSettings?: ColumnsSettings<Entity> | null;
  fileNameOverride?: string;
}) => {
  const { api } = useContext(SessionContext);
  const [loading, setLoading] = useState<boolean>(false);

  const downloadCSV = useCallback(async () => {
    setLoading(true);
    try {
      api
        .post(`${entityConstants.resource}/export/csv`, {
          filters,
          csvSettings,
          csvColumnSettings: columnSettings,
        } as DownloadCsvProps<Entity, Filters>)
        .then((result) => {
          var blob = new Blob([result], { type: "application/csv;charset=utf-8" });
          var url = URL.createObjectURL(blob);
          const anchor = document.createElement("a");
          anchor.href = url;
          anchor.target = "_blank";
          anchor.download = !!fileNameOverride
            ? `${fileNameOverride}.csv`
            : `${entityConstants.entityPlural.replaceAll(" ", "_")}.csv`;
          document.body.appendChild(anchor);
          anchor.click();
          document.body.removeChild(anchor);
        })
        .catch((e) => console.error(e));

      setLoading(false);
    } catch {
      showtoast("error", "An error occurred while processing your request");
    }
    setLoading(false);
  }, [
    api,
    columnSettings,
    csvSettings,
    entityConstants.entityPlural,
    entityConstants.resource,
    filters,
    fileNameOverride,
  ]);

  return { downloadCSV, loading };
};
