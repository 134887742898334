import { generateUid } from "../../tools/UID/UID";
import { argumentType, commandTypes, parameterTypes, trackListType } from "../PipelineTypes";
import { Empty } from "./Empty";

// type parameterType = { sw: number; offset: number };

export class NMRAxis extends Empty {
  id: string;
  name: string;
  readonly type = commandTypes.nmrAxis;

  parameterTypes = {
    sw: parameterTypes.float,
    offset: parameterTypes.float,
    axis: parameterTypes.string,
    usedSw: parameterTypes.string,
  };
  parameterSettings = {
    sw: { name: "Spectral width", hidden: true, value: Infinity },
    offset: { name: "Axis offset", inputType: "xPick", hidden: true, value: Infinity },
    axis: { name: "Axis", readonly: true },
    usedSw: { name: "Spectral width", readonly: true },
  };

  readonly input: argumentType[];
  readonly output: argumentType[];

  constructor() {
    super();
    this.id = generateUid();
    this.name = "NMR Axis";
    this.input = [
      { name: "FID real", type: "1D_real" },
      { name: "FID imaginary", type: "1D_real" },
    ];
    this.output = [
      { name: "FID real + zeros", type: "1D_real" },
      { name: "FID imaginary + zeros", type: "1D_real" },
    ];
  }

  round(v: number) {
    return Math.round(v * 1000) / 1000;
  }

  run(tracks: trackListType): trackListType {
    // // return parameter?.trackList.value(tracks) ?? [];
    // if (!tracks?.[0]?.data || !tracks?.[1]?.data) {
    //   this.errors.create({
    //     id: this.id,
    //     component: "ZeroFillFID.run",
    //     message: `Some tracks do not contain any data.`,
    //   });
    //   return [];
    // }
    // const reIn: Data1DReal = tracks[0].data;
    // const imIn: Data1DReal = tracks[1].data;

    // const parameter = tracks[0]?.internalParameter.NMR ?? {};

    // let { sw, offset } = this.parameter as parameterType;

    // if (!isFinite(sw)) {
    //   if (parameter?.SW) {
    //     sw = parseFloat(parameter.SW);
    //     (this.internalParameter.sw as FloatParameter).value = sw;
    //   } else {
    //     this.errors.create({
    //       id: this.id,
    //       component: "NMRAxis.run",
    //       message: `Spectral with must be a infinite number.`,
    //     });
    //     return [];
    //   }
    // }

    // if (!isFinite(offset)) {
    //   offset = parseFloat(parameter?.OFFSET ?? 0);

    //   // (this.internalParameter.offset as FloatParameter).value = offset;
    // }

    // const len = reIn.length;
    // const xMin = offset;
    // const xMax = xMin - sw;

    // this.parameter = { usedSw: `${this.round(sw)} ppm`, axis: `${this.round(xMin)} to  ${this.round(xMax)} ppm` };

    // const step = (xMax - xMin) / (len - 1);

    // for (let i = 0; i < len; i++) {
    //   const x = xMin + i * step;
    //   reIn.x[i] = x;
    //   imIn.x[i] = x;
    // }

    // tracks[0].parameter.axisMin = [xMin];
    // tracks[0].parameter.axisMax = [xMax];
    // tracks[1].parameter.axisMin = [xMin];
    // tracks[1].parameter.axisMax = [xMax];

    // tracks[0].parameter.xunit = "ppm";
    // tracks[0].parameter.xlabel = parameter.NUC?.[0] ?? "";
    // tracks[0].parameter.ylabel = "Intensity";
    // tracks[0].parameter.hideAxis = { x: false, y: true, z: true };
    // tracks[1].parameter.xunit = "ppm";
    // tracks[1].parameter.xlabel = parameter.NUC?.[0] ?? "";
    // tracks[1].parameter.ylabel = "Intensity";
    // tracks[1].parameter.hideAxis = { x: false, y: true, z: true };

    // tracks[0].label = "NMR spectrum.re";
    // tracks[1].label = "NMR spectrum.im";

    return tracks;
  }
}
