import { useContext } from "react";
import { SessionContext } from "../../common/contexts/SessionContext";
import { Alert } from "../../common/overlays/Alert/Alert";
import { StepwiseWizard } from "../../common/StepwiseWizard/StepwiseWizard";
import { BridgeAddForm } from "../Bridges/BridgeViews";
import { DataSourceAddFormPage } from "../DataSources/DataSourceViews";
import { NotAllowed } from "../../common/misc/UIconstants";

export const SFTPBridgeClientWizard = () => {
  const { session } = useContext(SessionContext);

  if (!!session?.features.enable_legacy_SFTP_autoloads)
    return <Alert type="danger" message={"Bridges of type SFTP are disabled. Use IT hardware instead."} fit centered />;
  if (!session?.permissions.administer_autoload) return NotAllowed;
  return (
    <StepwiseWizard
      title={"Bridge setup wizard"}
      steps={[
        {
          label: "Add SFTP-Bridge",
          validatefn: () => true,
          hideNextButton: true,
          hidePrevButton: true,
          nextOnValidFn: true,
          component: ({ nextHandle }) => (
            <div className="center-horizontally">
              <BridgeAddForm
                onSuccess={(data) => nextHandle({ bridgeId: data.id.toString() })}
                initialValues={{ type: "SFTP" }}
              />
            </div>
          ),
        },
        {
          label: "Add data source",
          validatefn: () => true,
          hideNextButton: true,
          hidePrevButton: true,
          component: () => (
            <div className="center-horizontally">
              <DataSourceAddFormPage />
            </div>
          ),
        },
      ]}
    />
  );
};
