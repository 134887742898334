import { useContext, useEffect, useMemo, useState } from "react";
import { ColumnsSettings } from "../../common/tables/ColumnsSelector/ColumnsSelector";
import {
  GenericVirtualizedTableCells,
  SortState,
} from "../../common/tables/GenericVirtualizedTable/GenericVirtualizedTableTypes";
import { TableTabsDict } from "../../common/tables/Tabs/TableTabsTypes";
import { useTabStore } from "../../common/tables/Tabs/useTabStore";
import { EntityTableTabs } from "../../common/tables/Tabs/EntityTableTabs";
import { EntityTable } from "../../common/tables/EntityTable/EntityTable";
import { SearchInput } from "../../common/forms/SearchInput/SearchInput";
import { LoadingWrapper } from "../../common/LoadingWrapper";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import { EntityFilterIndicator } from "../../common/tables/EntityFilterIndicator/EntityFilterIndicator";
import TableView, { TableViewLabel } from "../../common/panels/TableView/TableView";
import { useEntityDetail } from "../../api/BaseEntityApi";
import { LucideIcon } from "../../common/icon/LucideIcon";
import {
  InventoryItem,
  InventoryItemFilters,
  InventoryItemFiltersTranslator,
  hierarchyConstants,
  inventoriesConstants,
} from "../../api/Inventories";
import { InventoryItemsFilterBar, InventoryFilterForm } from "./InventoryItemsFilterBar";
import { CustomType, CustomTypeFilters, customTypeConstants } from "../../api/CustomTypes";
import { SessionContext } from "../../common/contexts/SessionContext";
import { getDetailLink, getEditLink, getIndexRoute } from "../../main/Routing";
import { MoreDropdown } from "../../common/buttons/MoreDropdown/MoreDropdown";
import { AlertModal } from "../../common/modals/AlertModal/AlertModal";
import { useCustomTypesEntityTable } from "../../Customization/CustomTypes/generics/useCustomTypesEntityTable";
import { NotSet } from "../../common/misc/UIconstants";
import { DateTimeRenderer } from "../../common/datetime/DateTimeFormatter";
import { GetPersons, TableArrayRenderer } from "../../common/misc/EntityRenders/EntityRenderer";
import { ExportCsvButton } from "../../common/tables/ExportCsvButton/ExportCsvButton";
import { toUppercase } from "../../common/helperfunctions/stringFunctions";
import { Alert } from "../../common/overlays/Alert/Alert";
import { LinkButton } from "../../common/buttons/LinkButton/LinkButton";
import {
  UseEntityTableDefaultProps,
  useGenericVirtualizedTable,
  useGenericVirtualizedTableTabs,
  UseITypedEntityTableProps,
} from "../../common/tables/GenericVirtualizedTable/useGenericVirtualizedTable";
import { EntityTableProps } from "../../common/entity/EntityInterfaces";
import { EntityTableCloneButton } from "../../common/entity/entityComponents/EntityTableCloneButton";
import { EntityTableSoftDeletableButton } from "../../common/entity/entityComponents/EntityTableSoftDeletableButton";
import { EntityTableRestoreButton } from "../../common/entity/entityComponents/EntityTableRestoreButton";
import { EntityTableEditButton } from "../../common/entity/entityComponents/EntityTableEditButton";

export const switchInventoriesDefaultSortState = (
  sortState: InventoryItemFilters["orderBy"]
): SortState<InventoryItemFilters["orderBy"]> => {
  switch (sortState) {
    case "ID_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-id",
        orderBy: sortState,
      };
    case "ID_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-id",
        orderBy: sortState,
      };
    case "NAME_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-name",
        orderBy: sortState,
      };
    case "NAME_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-name",
        orderBy: sortState,
      };
    case "TYPE_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-customType",
        orderBy: sortState,
      };
    case "TYPE_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-customType",
        orderBy: sortState,
      };
    case "INVENTORY_NAME_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-inventoryName",
        orderBy: sortState,
      };
    case "INVENTORY_NAME_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-inventoryName",
        orderBy: sortState,
      };
    case "CREATED_ON_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-createdOn",
        orderBy: sortState,
      };
    case "CREATED_ON_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-createdOn",
        orderBy: sortState,
      };
    case "CREATED_BY_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-createdBy",
        orderBy: sortState,
      };
    case "CREATED_BY_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-createdBy",
        orderBy: sortState,
      };
    case "MODIFIED_ON_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-modifiedOn",
        orderBy: sortState,
      };
    case "MODIFIED_ON_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-modifiedOn",
        orderBy: sortState,
      };
    case "MODIFIED_BY_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-modifiedBy",
        orderBy: sortState,
      };
    case "MODIFIED_BY_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-modifiedBy",
        orderBy: sortState,
      };
    default:
      return {
        sortDirection: "ASC",
        headerId: "default-name",
        orderBy: sortState,
      };
  }
};

export const useInventoryItemsTableDefaults = ({ fieldLabels }: UseEntityTableDefaultProps<"inventoryItems">) => {
  const { customTypeId } = useParams<{ customTypeId: string }>();
  const {
    data: customType,
    status,
    fetchStatus,
    error,
  } = useEntityDetail<CustomType, CustomTypeFilters>(customTypeConstants.resource, +(customTypeId ?? 0), undefined, {
    enabled: !!customTypeId,
  });

  const defaults: ColumnsSettings<InventoryItem> = useMemo(
    () => ({
      "default-id": { pos: 0, active: false, header: fieldLabels.id, property: "id" },
      "default-name": { pos: 1, active: true, header: fieldLabels.name, property: "name" },
      "default-inventoryName": {
        pos: 2,
        active: true,
        header: fieldLabels.rootCustomType,
        property: "rootCustomType.inventoryName",
      },
      "default-customType": { pos: 3, active: true, header: hierarchyConstants.childType, property: "customType" },
      "default-projects": { pos: 4, active: true, header: fieldLabels.createdOn, property: "projects" },
      "default-createdOn": { pos: 5, active: true, header: fieldLabels.createdOn, property: "createdOn" },
      "default-createdBy": { pos: 6, active: true, header: fieldLabels.createdBy, property: "createdBy" },
      "default-modifiedOn": {
        pos: 7,
        active: true,
        header: fieldLabels.modifiedOn,
        property: "modifiedOn",
      },
      "default-modifiedBy": {
        pos: 8,
        active: true,
        header: fieldLabels.modifiedBy,
        property: "modifiedBy",
      },
    }),
    [
      fieldLabels.createdBy,
      fieldLabels.createdOn,
      fieldLabels.id,
      fieldLabels.modifiedBy,
      fieldLabels.modifiedOn,
      fieldLabels.name,
      fieldLabels.rootCustomType,
    ]
  );

  const tabStoreDefaults = useMemo(() => {
    let def: TableTabsDict<InventoryItem, InventoryItemFilters, InventoryFilterForm> = {
      default: {
        tabId: "default",
        type: "fixed",
        label: "All",
        title: "All",
        icon: "house",
        align: "left",
        xPos: 0,
        settings: {
          columnSettings: {},
          columnWidths: {},
          filters: { orderBy: "NAME_ASC" },
          sidebarFilters: {},
        },
        forcedSettings: {
          columnSettings: {},
          columnWidths: {},
          filters: {},
          sidebarFilters: {},
        },
      },
      trash: {
        tabId: "trash",
        type: "fixed",
        label: "Trash",
        title: "Trash",
        icon: "trash",
        align: "left",
        xPos: 2,
        settings: {
          columnSettings: {},
          columnWidths: {},
          filters: { orderBy: "NAME_ASC" },
          sidebarFilters: {},
        },
        forcedSettings: {
          columnSettings: {},
          columnWidths: {},
          filters: { isSoftDeleted: true },
          sidebarFilters: {},
        },
      },
    };
    if (!!customType) {
      def["default"] = {
        tabId: "default",
        type: "fixed",
        label: "All",
        title: "All",
        icon: "house",
        align: "left",
        xPos: 0,
        settings: {
          columnSettings: {
            "default-inventoryName": {
              pos: 3,
              active: false,
              header: fieldLabels.rootCustomType,
              property: "rootCustomType.inventoryName",
            },
            "default-customType": {
              pos: 4,
              active: false,
              header: hierarchyConstants.childType,
              property: "customType",
            },
          },
          columnWidths: {},
          filters: { orderBy: "NAME_ASC" },
          sidebarFilters: {},
        },
        forcedSettings: {
          columnSettings: {},
          columnWidths: {},
          filters: { customTypeIds: [customType.id] },
          sidebarFilters: { customTypeIds: [customType] },
        },
      };
      def["trash"] = {
        tabId: "trash",
        type: "fixed",
        label: "Trash",
        title: "Trash",
        icon: "trash",
        align: "left",
        xPos: 2,
        settings: {
          columnSettings: {
            "default-inventoryName": {
              pos: 3,
              active: false,
              header: fieldLabels.rootCustomType,
              property: "rootCustomType.inventoryName",
            },
            "default-customType": {
              pos: 4,
              active: false,
              header: hierarchyConstants.childType,
              property: "customType",
            },
          },
          columnWidths: {},
          filters: { orderBy: "NAME_ASC" },
          sidebarFilters: {},
        },
        forcedSettings: {
          columnSettings: {},
          columnWidths: {},
          filters: { isSoftDeleted: true, customTypeIds: [customType.id] },
          sidebarFilters: { customTypeIds: [customType] },
        },
      };
    }
    return def as TableTabsDict<InventoryItem, InventoryItemFilters, InventoryFilterForm>;
  }, [customType, fieldLabels.rootCustomType]);

  return { defaults, tabStoreDefaults, status, fetchStatus, error, customType };
};

export const useInventoryItemsTableColumns = ({
  entityConstants,
  fieldLabels,
  defaults,
  filters,
  sort,
  setSort,
  customType,
}: UseITypedEntityTableProps<"inventoryItems"> & { customType: CustomType }) => {
  // Custom Fields
  const { columnSettings, customFieldHeaders } = useCustomTypesEntityTable({
    defaultColumnSettings: defaults,
    entityType: "Inventory",
    customTypeIds: customType.id ? [+customType.id] : filters?.customTypeIds ?? undefined,
    defaultActive: !!customType.id,
  });

  const columns = useMemo(() => {
    let headers: GenericVirtualizedTableCells<InventoryItem> = [
      {
        id: "default-id",
        Header: fieldLabels.id,
        accessor: (row) => <span style={{ color: "var(--gray-400)" }}>{row.id}</span>,
        width: 120,
        minWidth: 120,
        align: "center",
        sortingFn: (id) => {
          if (sort.headerId === id) {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "ID_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "ID_ASC" }));
            }
          } else {
            setSort({ headerId: id, sortDirection: "ASC", orderBy: "ID_ASC" });
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
      {
        Header: fieldLabels.name,
        id: "default-name",
        accessor: (row) => (
          <div
            style={{ display: "flex", flexFlow: "row nowrap", gap: "5px", overflow: "hidden", alignItems: "center" }}
          >
            <div style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
              <span style={{ fontWeight: 500, whiteSpace: "nowrap", marginRight: "5px" }}>
                <LucideIcon name={entityConstants.icon} color={"var(--primary)"} /> {row.name}
              </span>
            </div>
            {row.isDeleted && (
              <div>
                <label className="label label-soft-warning" title={"Trashed"}>
                  <LucideIcon name="trash" />
                </label>
              </div>
            )}
          </div>
        ),
        minWidth: 150,
        width: 250,
        align: "left",
        sortingFn: () => {
          if (sort.headerId === "default-name") {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "NAME_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "NAME_ASC" }));
            }
          } else {
            setSort({ headerId: "default-name", sortDirection: "ASC", orderBy: "NAME_ASC" });
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
      {
        Header: fieldLabels.rootCustomType,
        id: "default-inventoryName",
        accessor: (row) => (
          <div
            style={{ display: "flex", flexFlow: "row nowrap", gap: "5px", overflow: "hidden", alignItems: "center" }}
          >
            <div style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
              <span style={{ fontWeight: 500, whiteSpace: "nowrap", marginRight: "5px" }}>
                <LucideIcon name={inventoriesConstants.icon} color={"var(--primary)"} />{" "}
                {row.rootCustomType?.inventoryName ?? NotSet}
              </span>
            </div>
          </div>
        ),
        minWidth: 150,
        width: 250,
        align: "left",
        sortingFn: (id) => {
          if (sort.headerId === id) {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "INVENTORY_NAME_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "INVENTORY_NAME_ASC" }));
            }
          } else {
            setSort({ headerId: id, sortDirection: "ASC", orderBy: "INVENTORY_NAME_ASC" });
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
      {
        Header: hierarchyConstants.childType,
        id: "default-customType",
        accessor: (row) => (
          <div
            style={{ display: "flex", flexFlow: "row nowrap", gap: "5px", overflow: "hidden", alignItems: "center" }}
          >
            <div style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
              <span style={{ fontWeight: 500, whiteSpace: "nowrap", marginRight: "5px" }}>
                <LucideIcon name={inventoriesConstants.icon} color={"var(--primary)"} />{" "}
                {row.customType?.name ?? NotSet}
              </span>
            </div>
          </div>
        ),
        minWidth: 150,
        width: 250,
        align: "left",
        sortingFn: (id) => {
          if (sort.headerId === id) {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "TYPE_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "TYPE_ASC" }));
            }
          } else {
            setSort({ headerId: id, sortDirection: "ASC", orderBy: "TYPE_ASC" });
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
      {
        Header: fieldLabels.projects,
        id: "default-projects",
        accessor: (row) => <TableArrayRenderer values={row.projects}>{(value) => value.name}</TableArrayRenderer>,
        width: 200,
        align: "left",
      },
      {
        id: "default-createdOn",
        Header: fieldLabels.createdOn,
        accessor: (row) => <DateTimeRenderer date={row.createdOn} includeElapsed={false} />,
        width: 200,
        align: "left",
        sortingFn: () => {
          if (sort.headerId === "default-createdOn") {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "CREATED_ON_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "CREATED_ON_ASC" }));
            }
          } else {
            setSort({ headerId: "default-createdOn", sortDirection: "ASC", orderBy: "CREATED_ON_ASC" });
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
      {
        id: "default-createdBy",
        Header: fieldLabels.createdBy,
        accessor: (row) => <GetPersons persons={[row.createdBy]} createLinks={false} />,
        width: 200,
        align: "left",
        sortingFn: () => {
          if (sort.headerId === "default-createdBy") {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "CREATED_BY_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "CREATED_BY_ASC" }));
            }
          } else {
            setSort({ headerId: "default-createdBy", sortDirection: "ASC", orderBy: "CREATED_BY_ASC" });
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
      {
        id: "default-modifiedOn",
        Header: fieldLabels.modifiedOn,
        accessor: (row) => <DateTimeRenderer date={row.modifiedOn} includeElapsed={false} />,
        width: 200,
        align: "left",
        sortingFn: () => {
          if (sort.headerId === "default-modifiedOn") {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "MODIFIED_ON_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "MODIFIED_ON_ASC" }));
            }
          } else {
            setSort({ headerId: "default-modifiedOn", sortDirection: "ASC", orderBy: "MODIFIED_ON_ASC" });
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
      {
        id: "default-modifiedBy",
        Header: fieldLabels.modifiedBy,
        accessor: (row) => <GetPersons persons={[row.modifiedBy]} createLinks={false} />,
        width: 200,
        align: "left",
        sortingFn: () => {
          if (sort.headerId === "default-modifiedBy") {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "MODIFIED_BY_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "MODIFIED_BY_ASC" }));
            }
          } else {
            setSort({ headerId: "default-modifiedBy", sortDirection: "ASC", orderBy: "MODIFIED_BY_ASC" });
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
    ];
    // Add custom field headers
    headers = headers.concat(customFieldHeaders);
    return headers;
  }, [
    customFieldHeaders,
    entityConstants.icon,
    fieldLabels.createdBy,
    fieldLabels.createdOn,
    fieldLabels.id,
    fieldLabels.modifiedBy,
    fieldLabels.modifiedOn,
    fieldLabels.name,
    fieldLabels.projects,
    fieldLabels.rootCustomType,
    setSort,
    sort.headerId,
    sort.sortDirection,
  ]);
  return { columns, columnSettings };
};
export const InventoryItemsTableWrapper = ({
  entityApi,
  entityConstants,
  fieldLabels,
  permissions,
  routes,
}: EntityTableProps<"inventoryItems">) => {
  const { defaults, tabStoreDefaults, status, fetchStatus, error, customType } = useInventoryItemsTableDefaults({
    fieldLabels,
  });

  if (!customType)
    return (
      <LoadingWrapper status={status} fetchStatus={fetchStatus} error={error}>
        <Alert type="light" message={`Loading ${inventoriesConstants.entitySingular}...`} fit centered />
      </LoadingWrapper>
    );
  return (
    <InventoryItemsTable
      customType={customType}
      defaults={defaults}
      tabStoreDefaults={tabStoreDefaults}
      entityApi={entityApi}
      entityConstants={entityConstants}
      fieldLabels={fieldLabels}
      permissions={permissions}
      routes={routes}
    />
  );
};

export const InventoryItemsTable = ({
  entityApi,
  entityConstants,
  fieldLabels,
  permissions,
  routes,
  customType,
  defaults,
  tabStoreDefaults,
}: {
  customType: CustomType;
  defaults: ColumnsSettings<InventoryItem>;
  tabStoreDefaults: TableTabsDict<InventoryItem, InventoryItemFilters, InventoryFilterForm>;
} & EntityTableProps<"inventoryItems">) => {
  const history = useHistory();
  const { route, session } = useContext(SessionContext);
  const {
    bulkRestoreMutationAsync,
    bulkDeleteMutationAsync,
    isLoadingBulkDeleteMutation,
    isLoadingBulkRestoreMutation,
  } = entityApi;

  const { selection, resultsCount, selectionPermissions, onCountChange, onSelectionChange, onSelectionPermissions } =
    useGenericVirtualizedTable<InventoryItem>();

  const {
    filters,
    sidebarFilters,
    forcedFilters,
    columnSetting,
    columnWidths,
    customTabs,
    fixedTabs,
    dispatchTabStore,
    currentTab,
    tabsLoading,
    tabsModified,
  } = useTabStore<InventoryItem, InventoryItemFilters, InventoryFilterForm>({
    resource: entityConstants.resource,
    defaults: tabStoreDefaults,
    keyOverride: `tableTabs-inventories-${customType.id}`,
  });

  const { functionRef, sort, setSort, searchValue, setSearchValue, onTabChange, debouncedSearchValue } =
    useGenericVirtualizedTableTabs({
      tabsLoading,
      filters,
      switchSortState: switchInventoriesDefaultSortState,
      dispatchTabStore,
    });

  const { columns, columnSettings } = useInventoryItemsTableColumns({
    entityConstants,
    fieldLabels,
    defaults,
    filters,
    sort,
    setSort,
    customType,
  });

  // TODO: remove once generic in tab store
  const queryTypeId = new URLSearchParams(useLocation().search).get("type");
  useEffect(() => {
    if (!tabsLoading && queryTypeId === "default") {
      (async () => await onTabChange("default"))();
    } else if (!tabsLoading && queryTypeId && +queryTypeId) {
      (async () => await onTabChange(`inventory-id-${+queryTypeId}`))();
    }
  }, [history, onTabChange, queryTypeId, tabsLoading]);

  const memoizedFilters = useMemo(() => {
    return { ...filters, ...forcedFilters };
  }, [filters, forcedFilters]);

  const [showModal, setShowModal] = useState(false);

  return (
    <TableView>
      <TableView.Head>
        <TableView.Head.Label>
          <div className="flex align-center gap-5">
            <Link to={route(getIndexRoute(inventoriesConstants.frontendIndexRoute))} style={{ textDecoration: "none" }}>
              <div
                className="flex row-nowrap align-center gap-5"
                style={{
                  fontSize: "24px",
                  fontWeight: 600,
                  lineHeight: "24px",
                  whiteSpace: "nowrap",
                }}
              >
                <LucideIcon name={inventoriesConstants.icon} color="var(--primary)" style={{ width: 20, height: 20 }} />
                {toUppercase(inventoriesConstants.entityPlural)}
              </div>
            </Link>
            <LucideIcon name="arrow-big-right" color="var(--gray-400)" />
            <TableViewLabel
              entityConstants={entityConstants}
              resultsCount={resultsCount}
              overwriteLabel={customType?.inventoryName ?? undefined}
            />
            {!!customType && !customType.inventoryName && (
              <div className="label label-soft-info">{customType.name}</div>
            )}
          </div>
        </TableView.Head.Label>
        <TableView.Head.Controls>
          {!!customType?.isHierarchyRoot && (
            <LinkButton
              className="btn btn-default"
              linkTo={`${route(getDetailLink(inventoriesConstants.frontendIndexRoute, customType.id))}/hierarchy`}
              title="Show items in hierarchy"
              disabled={!customType.id}
            >
              <LucideIcon name="list-tree" />
            </LinkButton>
          )}
          <LinkButton
            className="btn btn-default"
            linkTo={route(getEditLink(customTypeConstants.frontendIndexRoute, customType.id))}
            title={`Edit ${inventoriesConstants.entitySingular}`}
            disabled={!session?.permissions.administer_custom_field_schemas}
          >
            <LucideIcon name="square-pen" /> Edit
          </LinkButton>

          <LinkButton
            className="btn btn-primary"
            linkTo={`${routes.getAddRoute}${customType.id ? `?type=${customType.id}` : ""}`}
            title={`Add new ${inventoriesConstants.entitySingular}`}
            disabled={
              !!customType?.hasRestrictedAddPermission
                ? !session?.permissions.add_admin_inventory_items
                : !session?.permissions.add_inventory_items
            }
          >
            <LucideIcon name="plus" /> Add {entityConstants.entitySingular}
          </LinkButton>
        </TableView.Head.Controls>
      </TableView.Head>
      <TableView.Body>
        <TableView.Body.Sidebar minSize={20}>
          <InventoryItemsFilterBar
            initialValues={sidebarFilters}
            dispatchTabStore={dispatchTabStore}
            tabsLoading={tabsLoading}
            currentTab={currentTab}
            hideReset={currentTab !== "default"}
            hideHierarchyFilters={!!customType}
          />
        </TableView.Body.Sidebar>
        <TableView.Body.Content>
          <EntityTableTabs
            currentTab={currentTab}
            onTabChange={onTabChange}
            fixedTabs={fixedTabs}
            filters={filters}
            sidebarFilters={sidebarFilters}
            columnSetting={columnSetting}
            columnWidths={columnWidths}
            customTabs={customTabs}
            dispatchTabStore={dispatchTabStore}
            tabsLoading={tabsLoading}
            tabsModified={tabsModified}
          />
          <EntityTable>
            <EntityTable.Controls
              style={{
                borderTop: "0px",
                borderRadius: "0px",
              }}
            >
              <EntityFilterIndicator<InventoryItem, InventoryItemFilters>
                filters={filters}
                excludeFilters={{
                  customTypeIds: customType.id ? () => false : (value) => !!value,
                }}
                translatorConsts={InventoryItemFiltersTranslator}
                showAlways
              />

              <SearchInput
                searchValue={searchValue}
                setSearchValue={setSearchValue}
                placeholder={`Search ${entityConstants.entityPlural}...`}
              />

              {currentTab !== "trash" && (
                <EntityTableEditButton
                  entityConstants={entityConstants}
                  routes={routes}
                  permissions={permissions}
                  selection={selection}
                  selectionPermissions={selectionPermissions}
                />
              )}
              <MoreDropdown drop="right" btn="btn btn-ghost-secondary">
                <li>
                  <EntityTableCloneButton
                    entityConstants={entityConstants}
                    routes={routes}
                    permissions={permissions}
                    selection={selection}
                  />
                </li>
                <li>
                  <ExportCsvButton
                    entityConstants={entityConstants}
                    columnSettings={columnSetting}
                    filters={{
                      ids: selection.size > 0 ? Array.from(selection) : undefined,
                      ...memoizedFilters,
                      searchTerm: debouncedSearchValue,
                    }}
                    disabled={selection.size === 0}
                    fileNameOverride={customType?.name.replaceAll(" ", "_") ?? undefined}
                  />
                </li>
                {currentTab === "trash" && (
                  <li>
                    <EntityTableRestoreButton
                      entityConstants={entityConstants}
                      selection={selection}
                      permissions={permissions}
                      selectionPermissions={selectionPermissions}
                      onClick={async () =>
                        await bulkRestoreMutationAsync({ ids: Array.from(selection) }).catch(() => {})
                      }
                      loading={isLoadingBulkRestoreMutation}
                    />
                  </li>
                )}
                <li>
                  <EntityTableSoftDeletableButton
                    currentTab={currentTab}
                    entityConstants={entityConstants}
                    selection={selection}
                    permissions={permissions}
                    selectionPermissions={selectionPermissions}
                    onClick={() => setShowModal(true)}
                  />
                </li>
              </MoreDropdown>
              <AlertModal
                type={`${currentTab === "trash" ? "danger" : "warning"}`}
                showModal={showModal}
                setShowModal={setShowModal}
                title={`${
                  selection.size === 0
                    ? `Select dataset to ${currentTab === "trash" ? "delete" : "trash"}`
                    : selection.size === 1
                    ? `${currentTab === "trash" ? "Delete" : "Trash"} the selected ${
                        inventoriesConstants.entitySingular
                      }?`
                    : `${currentTab === "trash" ? "Delete" : "Trash"} the selected ${
                        inventoriesConstants.entityPlural
                      }?`
                }`}
                description={`${
                  currentTab === "trash"
                    ? `Proceeding will permanently delete the selected ${inventoriesConstants.entityPlural}.`
                    : `Proceeding will move the selected ${inventoriesConstants.entityPlural} into trash.`
                }`}
                proceedLabel={`${currentTab === "trash" ? "Delete" : "Trash"}`}
                onProceed={async () => {
                  await bulkDeleteMutationAsync({
                    ids: Array.from(selection),
                    goBackOnSuccess: false,
                    showToast: false,
                    entityName: inventoriesConstants.entityPlural,
                    params: { deletePermanently: currentTab === "trash" },
                  }).catch((e) => {});
                  functionRef.current?.resetSelection();
                  setShowModal(false);
                }}
                loading={isLoadingBulkDeleteMutation}
              />
            </EntityTable.Controls>
            <EntityTable.Body<InventoryItem, InventoryItemFilters>
              functionRef={functionRef}
              entityConstants={entityConstants}
              filters={memoizedFilters}
              columns={columns}
              columnSelect
              columnSetting={columnSetting}
              columnWidths={columnWidths}
              defaultColumnSettings={columnSettings}
              dispatchTabStore={dispatchTabStore}
              setResultsCount={onCountChange}
              onSelectionChange={onSelectionChange}
              onSelectionPermissions={onSelectionPermissions}
              showPermissionColumn
              loading={tabsLoading}
              // onRowClick={() => {}}
              // linkTo={() => "#"}
            />
          </EntityTable>
        </TableView.Body.Content>
      </TableView.Body>
    </TableView>
  );
};
