import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import styles from "../../common/forms/forms.module.css";
import { FormHeader } from "../../common/forms/FormHeader";
import { FormButtons } from "../../common/forms/FormButtons";
import { EntityFormProps } from "../../common/entity/EntityInterfaces";
import {
  DataSource,
  DataSourceType,
  DataSourceTypeFormValues,
  FileExcludePattern,
  dataSourceConstants,
} from "../../api/DataSource";
import { BridgesVirtualizedSelectForm } from "../../common/forms/EntityForms/formsVirtualized/BridgeVirtualizedSelectForm";
import { FormFieldLayout } from "../../common/formfields/FormFieldLayouts";
import ContentRow from "../../common/tables/ContentRow/ContentRow";
import { LucideIcon } from "../../common/icon/LucideIcon";
import { IEntityMinimalModel } from "../../api/GenericTypes";
import { useEntityDetail } from "../../api/BaseEntityApi";
import { Bridge, BridgeSuggestions, bridgeConstants } from "../../api/Bridges";
import { BridgeConnectionStatus } from "../Bridges/common/BridgeConnectionStatus";
import { InputFormField } from "../../common/formfields/InputFormField";
import { SelectFormField } from "../../common/formfields/SelectFormField";
import { DataFormatsVirtualizedSelectForm } from "../../common/forms/EntityForms/formsVirtualized/DataFormatsVirtualizedSelectForm";
import { CustomImportsVirtualizedSelectForm } from "../../common/forms/EntityForms/formsVirtualized/CustomImportVirtualizedSelectForm";
import { InstrumentsVirtualizedSelectForm } from "../../common/forms/EntityForms/formsVirtualized/InstrumentsVirtualizedSelectForm";
import { MethodsVirtualizedSelectForm } from "../../common/forms/EntityForms/formsVirtualized/MethodsVirtualizedSelectForm";
import { CSSProperties, useEffect, useMemo, useState } from "react";
import { InstrumentFacility, instrumentsConstants } from "../../api/Facilities";
import { ToggleButtonComponent } from "../../ViewerUIElements/ToggleButtonComponent";
import { LoadingWrapper } from "../../common/LoadingWrapper";
import { FileBrowserFormField } from "../../common/formfields/FileBrowserFormField";
import { Alert } from "../../common/overlays/Alert/Alert";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { Parser, parsersConstants } from "../../api/Parsers";
import { SingleDatePickerFormField } from "../../common/formfields/DatePickerFormField/SingleDatePickerFormField";
import { documentationConstants } from "../../api/Misc";

export interface DataSourceFormValues
  extends Omit<DataSource, "bridge" | "type" | "customImportId" | "cutoffDate" | "directories"> {
  bridge: BridgeSuggestions;
  type: { id: DataSourceType; name: DataSourceType };
  customImport?: IEntityMinimalModel<string>;
  directories: { id: string; name: string }[];
  cutoffDate: Date;
}

export const DataSourceForm = ({
  fieldLabels,
  permissions,
  title,
  subtitle,
  initialValues,
  onSubmit,
  onCancel,
  loading,
  submitButtonLabel,
  event,
}: EntityFormProps<"dataSources">) => {
  const [enabled, setEnabled] = useState(initialValues?.enabled ?? true);

  const history = useHistory();
  const bridgeId = new URLSearchParams(history.location.search).get("bridgeId");
  const { data: bridgeDetail } = useEntityDetail<Bridge>(bridgeConstants.resource, bridgeId || 0, undefined, {
    enabled: !initialValues?.bridge && !!bridgeId,
  });

  useEffect(() => {
    setEnabled(initialValues?.enabled ?? true);
  }, [initialValues?.enabled]);

  const DataSourceFormSchema = yup.object().shape({
    bridge: yup.object().required("A bridge is required").typeError("A bridge is required"),
    name: yup.string().required("A name is required").typeError("A name is required"),
    type: yup
      .object()
      .required("A source type is required")
      .typeError("A source type is required")
      .default({ id: "Crawler", name: "Crawler" }),
    format: yup.object().required("A format is required").typeError("A format is required"),
    directories: yup
      .array()
      .min(1, "At least one directory needs to be selected")
      .required("At least one directory needs to be selected")
      .typeError("At least one directory needs to be selected"),
    instrument: yup.object().nullable().optional(),
    method: yup.object().required("A method is required").typeError("A method is required"),
    intervalInSeconds: yup
      .string()
      .required("Interval is required")
      .typeError("Interval is required")
      .min(1, "Interval needs to be greater than zero")
      .test({
        test: (value?: string) => {
          const numberRegex = /^\d+$/;
          return !!value && numberRegex.test(value.toString());
        },
        message: "Only full minutes are allowed",
      }),
  });

  const defaultCutoff = useMemo(() => {
    const now = new Date();
    now.setMonth(now.getMonth() - 3);
    now.setHours(0, 0, 0, 0);
    return now;
  }, []);

  const {
    handleSubmit,
    control,
    register,
    watch,
    setValue,
    clearErrors,
    formState: { errors, isSubmitting },
  } = useForm<Partial<DataSourceFormValues>>({
    values: {
      ...initialValues,
      bridge: initialValues?.bridge ?? bridgeDetail ?? undefined,
      type: initialValues?.type
        ? { id: initialValues.type, name: initialValues.type }
        : { id: "Crawler", name: "Crawler" },
      directories: initialValues?.directories?.map((d) => ({ id: d, name: d })),
      intervalInSeconds: initialValues?.intervalInSeconds ? Math.round(initialValues.intervalInSeconds / 60) : 15,
      cutoffDate: initialValues?.cutoffDate
        ? new Date(initialValues.cutoffDate)
        : event === "ADD"
        ? defaultCutoff
        : undefined,
    },
    resolver: yupResolver(DataSourceFormSchema),
  });

  // show bridge details on select
  const currentBridge = watch("bridge");
  const {
    data: currentBridgeFull,
    status,
    isLoading,
    error,
    fetchStatus,
  } = useEntityDetail<Bridge>(bridgeConstants.resource, currentBridge?.id || 0, {}, { enabled: !!currentBridge?.id });

  // update method on instrument change
  const currentInstrument = watch("instrument");
  const { data: currentInstrumentFull } = useEntityDetail<InstrumentFacility>(
    instrumentsConstants.resource,
    currentInstrument?.id || 0,
    {},
    { enabled: !!currentInstrument?.id }
  );
  useEffect(() => {
    if (!!currentInstrumentFull) {
      setValue("method", currentInstrumentFull?.method);
      clearErrors("method");
    }
  }, [clearErrors, currentInstrumentFull, setValue]);

  const currentFormat = watch("format");
  const { data: currentFormatFull } = useEntityDetail<Parser>(
    parsersConstants.resource,
    currentFormat?.id || 0,
    {},
    { enabled: !!currentFormat?.id }
  );

  const currentFileExcludePatterns = watch("fileExcludePatterns");

  return (
    <>
      <FormHeader title={title} subtitle={subtitle} />
      <form
        onSubmit={(e) => {
          e.preventDefault();
        }}
        autoComplete="off"
        className={`form-horizontal ${styles.form_holder}`}
      >
        <fieldset>
          <legend className="col-md-offset-2 col-md-10">Data source</legend>
        </fieldset>

        <BridgesVirtualizedSelectForm
          id="bridge"
          label={fieldLabels.bridge}
          control={control}
          horizontal
          required
          disabled={!!initialValues?.bridge}
        />

        {currentBridgeFull && (
          <FormFieldLayout id="" label="" horizontal>
            <LoadingWrapper status={status} error={error} fetchStatus={isLoading ? "fetching" : fetchStatus}>
              <BridgeContentRow bridge={currentBridgeFull} />
            </LoadingWrapper>
          </FormFieldLayout>
        )}

        {currentBridge ? (
          <>
            <fieldset>
              <legend className="col-md-offset-2 col-md-10">Basic details</legend>
            </fieldset>

            <InputFormField
              id="name"
              label={fieldLabels.name}
              errors={errors}
              register={register}
              autoFocus={!!initialValues?.bridge}
              required
              placeholder="Insert data source name..."
            />

            <FormFieldLayout id="" label={fieldLabels.enabled} hasLabel horizontal>
              <div className="flex align-center" style={{ padding: "10px 0" }}>
                <ToggleButtonComponent checked={enabled} setChecked={setEnabled}>
                  <></>
                </ToggleButtonComponent>
              </div>
            </FormFieldLayout>

            <SelectFormField
              id="type"
              label={fieldLabels.type}
              items={DataSourceTypeFormValues.filter((d) => d.id !== "IconNMR")}
              required
              control={control}
              errors={errors}
              isClearable={false}
              isDisabled={initialValues?.type !== undefined}
            />

            <DataFormatsVirtualizedSelectForm
              id="format"
              control={control}
              horizontal
              required
              label={fieldLabels.format}
            />

            {!!currentFormatFull?.fileExcludePatterns?.length && (
              <SelectFormField<FileExcludePattern>
                id="fileExcludePatterns"
                label={fieldLabels.fileExcludePatterns}
                items={currentFormatFull.fileExcludePatterns}
                control={control}
                isMulti
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.regex}
              />
            )}

            {!!currentFileExcludePatterns?.length && (
              <FormFieldLayout id="excludePatternRegex" label="" horizontal>
                {currentFileExcludePatterns.map((p, index) => (
                  <div className="flex align-center gap-5" style={{ marginBottom: "5px" }} key={index}>
                    <LucideIcon name="corner-down-right" color="var(--gray-400)" style={{ margin: "10px" }} />
                    <input className="form form-control" value={p.regex} readOnly style={{ overflow: "scroll" }} />
                  </div>
                ))}
              </FormFieldLayout>
            )}

            <InstrumentsVirtualizedSelectForm
              id="instrument"
              label={fieldLabels.instrument}
              control={control}
              horizontal
              allowCreateEntity
              allowUnassigned
            />

            <MethodsVirtualizedSelectForm
              id="method"
              label={fieldLabels.method}
              control={control}
              required
              horizontal
              allowCreateEntity
              disabled={!!currentInstrument}
              allowUnassigned={false}
            />

            {currentBridgeFull && (
              <FileBrowserFormField<DataSourceFormValues>
                id="directories"
                label={fieldLabels.directories}
                items={[]}
                required
                control={control}
                isMulti
                bridge={currentBridgeFull}
                setValue={setValue}
                errors={errors}
                clearErrors={clearErrors}
              />
            )}

            <CustomImportsVirtualizedSelectForm
              id="customImport"
              label={fieldLabels.customImport}
              control={control}
              horizontal
              placeholder="Select metadata template..."
            >
              <CustomImportInformation />
            </CustomImportsVirtualizedSelectForm>

            <InputFormField
              id="intervalInSeconds"
              label={fieldLabels.intervalInSeconds}
              type="number"
              errors={errors}
              register={register}
              required
              min={1}
            />

            <SingleDatePickerFormField
              id="cutoffDate"
              label={fieldLabels.cutoffDate}
              control={control}
              errors={errors}
              horizontal
              children={<CutoffDateInformation />}
              type="datetime"
            />
          </>
        ) : (
          <>
            <FormFieldLayout id="" label="" horizontal>
              <hr style={{ margin: "5px 0 15px 0" }} />
              <Alert fit centered type="light" message="Select bridge to continue..." />
            </FormFieldLayout>
          </>
        )}

        <FormButtons
          groupName={dataSourceConstants.frontendIndexRoute}
          entityId={initialValues ? initialValues.id : undefined}
          onClose={onCancel}
          onSubmit={handleSubmit(
            async (entity) =>
              await onSubmit({
                ...entity,
                bridge: entity?.bridge as Bridge,
                type: entity?.type?.id,
                intervalInSeconds: entity?.intervalInSeconds ? entity?.intervalInSeconds * 60 : undefined,
                directories: entity?.directories?.map((d) => d.id),
                cutoffDate: entity?.cutoffDate?.toISOString(),
                enabled: enabled,
              })
          )}
          loading={loading}
          submitButtonLabel={submitButtonLabel || "Save changes"}
          disabled={isSubmitting}
          errors={errors}
        />
      </form>
    </>
  );
};

export const CutoffDateInformation = () => {
  return (
    <OverlayTrigger
      placement="bottom"
      overlay={
        <Popover id="popover-info">
          File creation date since when datasets will be included during the automatic import
        </Popover>
      }
    >
      <LucideIcon name="info" color={"var(--gray-400)"} />
    </OverlayTrigger>
  );
};

export const CustomImportInformation = () => {
  return (
    <a
      style={{ marginLeft: "5px" }}
      href="https://logs-support.sciy.com/kb/article/335-metadata-mapping/"
      target="_blank"
      rel="noreferrer"
    >
      <OverlayTrigger
        placement="bottom"
        overlay={
          <Popover id="popover-info">
            Metadata templates can be used to extract additional information from uploaded files and map it to further
            LOGS content.
            <br />
            <br />
            Click to learn more.
          </Popover>
        }
      >
        <LucideIcon name={documentationConstants.icon} color={"var(--gray-400)"} />
      </OverlayTrigger>
    </a>
  );
};

export const BridgeContentRow = ({
  bridge,
  controls,
  style,
  onClick,
}: {
  bridge: Bridge;
  controls?: React.ReactNode;
  style?: CSSProperties;
  onClick?: () => void;
}) => {
  return (
    <ContentRow
      icon={
        <div style={{ padding: "0 5px" }}>
          <LucideIcon name={bridgeConstants.icon} color="var(--primary)" />
        </div>
      }
      label={
        <>
          {bridge.name}{" "}
          <label className="label label-soft-default" style={{ margin: 0 }}>
            {bridge.type}
          </label>
        </>
      }
      date={
        <div className="flex row-nowrap align-center gap-5">
          <BridgeConnectionStatus bridge={bridge} />
        </div>
      }
      id={<>Bridge-ID: {bridge.id}</>}
      info={<>IP-Address: {bridge.ipAddress}</>}
      controls={controls}
      style={style}
      onClick={onClick}
    />
  );
};
