import React, { CSSProperties } from "react";
import styles from "./SinglePage.module.css";
import { EntityConstants } from "../../../api/GenericTypes";
import { LucideIcon } from "../../icon/LucideIcon";
/**
 * Generic TableView wrapper component, with Title, top controls, left sidebar and main content area
 * Author: CS
 * @param: children
 * @returns: JSX.Element
 */

interface Props {
  style?: CSSProperties;
  children: React.ReactNode;
}
const SinglePage = ({ style, children }: Props) => {
  return (
    <div className={styles.container} style={style}>
      {children}
    </div>
  );
};

const Head = ({ style, children }: Props) => {
  return (
    <div className={styles.container_head} style={style}>
      {children}
    </div>
  );
};
SinglePage.Head = Head;

const Label = ({ style, children }: Props) => (
  <div className={styles.container_label} style={style}>
    {children}
  </div>
);
Head.Label = Label;

const Controls = ({ style, children }: Props) => (
  <div className={styles.container_ctrls} style={style}>
    {children}
  </div>
);
Head.Controls = Controls;

const Content = ({ style, children }: Props) => {
  return (
    <div className={styles.container_content} style={style}>
      {children}
    </div>
  );
};
SinglePage.Content = Content;

export default SinglePage;

// Used for countless headers including icon, title

interface SinglePageViewLabelProps {
  entityConstants: EntityConstants;
  children?: React.ReactNode;
  overwriteLabel?: string;
}
export const SinglePageViewLabel = ({ entityConstants, children, overwriteLabel }: SinglePageViewLabelProps) => {
  return (
    <div className={`${styles.headerViewLabel} flex row-nowrap align-center gap-5`}>
      <LucideIcon name={entityConstants.icon} />{" "}
      {overwriteLabel ?? entityConstants.entityPlural.charAt(0).toUpperCase() + entityConstants.entityPlural.slice(1)}
      {children}
    </div>
  );
};
