import {
  contourTypes,
  contourGenerator,
  contourInitializer,
  contourState,
  contourGeneratorType,
} from "../ViewerLayout/ViewerTypes";

export type contourBundle = {
  generator: contourGenerator;
  init: contourInitializer;
};

// ---- Explicit contours ----
export type explicitContoursState = contourState & {
  contours: number[];
};

const explicitContoursGenerator: contourGenerator = (state: explicitContoursState, min: number, max: number) => {
  // console.log("state.contours", state.contours, min, max);
  const thresholds = state.contours.filter((c) => c >= min && c <= max);

  return thresholds;
  // return thresholds.length < 1 ? [min] : thresholds;
};

export const initExplicitContours = (state?: explicitContoursState): explicitContoursState => {
  // let newState = state as explicitContoursState;

  const newState: explicitContoursState = {
    type: contourTypes.explicitContours,
    contours: state?.contours || [],
  };

  // console.log("initExplicitContours", newState);
  return newState;
};

export const explicitContours: contourBundle = {
  generator: explicitContoursGenerator,
  init: initExplicitContours as contourInitializer,
};

// ---- Equidistant contours ----
export type equidistantContourState = contourState & {
  count: number;
  min?: number;
  max?: number;
};

const equidistantContourGenerator: contourGenerator = (state: equidistantContourState, min: number, max: number) => {
  let left = min;
  if (state.min !== undefined && state.min > min) left = state.min;

  let right = max;
  if (state.max !== undefined && state.max < max) right = state.max;

  const step = (right - left) / (state.count - 1);

  if (Math.abs(step) < Number.EPSILON) return [];

  const thresholds: number[] = [];

  for (let t = left; t <= right; t += step) {
    thresholds.push(t);
  }

  return thresholds;
};

export const initEquidistantContourContours = (state?: equidistantContourState): equidistantContourState => {
  // let newState = state as explicitContoursState;

  const newState: equidistantContourState = {
    type: contourTypes.equidistant,
    count: state?.count || 10,
    min: state?.min,
    max: state?.max,
  };

  return newState;
};

export const equidistantContour: contourBundle = {
  generator: equidistantContourGenerator,
  init: initEquidistantContourContours as contourInitializer,
};

// ---- Baseline contours ----
type directionType = -1 | 0 | 1;
export enum contourMode {
  constantOffset = "Constant offset",
  evenSpaced = "Even spaced",
  previousScaled = "Scale previous",
}

export type oneBaselineContourState = contourState & {
  baseline: number;
  direction: directionType;
  scale: number;
  count: number;
  mode: contourMode;
};

const iterateContour = (start: number, scale: number, maxCount: number, min: number, max: number): number[] => {
  const thresholds: number[] = [];
  let count = 0;
  let previous;
  let t = start;
  while (t >= min && t <= max) {
    thresholds.push(t);
    count++;
    if (count >= maxCount) break;
    previous = t;
    t *= scale;
    // console.log(previous, " ->", t, Math.abs(t - previous) < Number.EPSILON);
    if (Math.abs(t - previous) < Number.EPSILON) break;
  }

  return thresholds;
};

const oneBaselineContourGenerator: contourGenerator = (state: oneBaselineContourState, min: number, max: number) => {
  let step: number;
  let start: number;
  // let stop: number;
  const thresholds: number[] = [];
  switch (state.mode) {
    case contourMode.evenSpaced:
      if (state.direction > 0) {
        step = (max - state.baseline) / state.count;
        if (step < Number.EPSILON) return [];
        for (let t = state.baseline; t < max; t += step) {
          thresholds.push(t);
        }
      } else {
        step = (state.baseline - min) / state.count;
        if (step < Number.EPSILON) return [];
        for (let t = state.baseline; t > min; t -= step) {
          thresholds.push(t);
        }
      }
      break;
    case contourMode.constantOffset:
      if (state.scale < Number.EPSILON) return [];

      if (state.direction > 0) {
        // start = state.baseline;
        // stop = max;
        for (let t = state.baseline, i = 0; t <= max && i < state.count; t += state.scale) {
          thresholds.push(t);
          i++;
        }
      } else {
        for (let t = state.baseline, i = 0; t >= min && i < state.count; t -= state.scale) {
          thresholds.push(t);
          i++;
        }
        start = min;
        // stop = state.baseline;
      }

      // console.log(">>", start, step);

      // let i = 0;
      // for (let t = start; t <= stop; t += state.scale) {
      //   thresholds.push(t);
      //   i++;
      //   if (i >= state.count) break;
      // }
      break;
    case contourMode.previousScaled:
      const scale = Math.abs(state.scale);
      if (scale - 1 < Number.EPSILON) return [];

      start = state.baseline * (state.direction < 0 ? -1 : 1);
      // if (scale > 0) stop = max;
      // else stop = 0;

      if (Math.abs(scale - 1) < Number.EPSILON) return [];
      // console.log(">>", state.baseline, state.direction);
      const t = iterateContour(start, scale, state.count, min, max);
      thresholds.push(...t);
      if (state.direction === 0) {
        const t = iterateContour(-start, scale, state.count, min, max);
        thresholds.push(...t);
      }

      break;
  }

  // console.log("thresholds", min, max);
  // console.log("thresholds", thresholds);

  return thresholds.sort((a, b) => a - b);
};

export const initOneBaselineContourContours = (state?: oneBaselineContourState): oneBaselineContourState => {
  const newState: oneBaselineContourState = {
    type: contourTypes.oneBaseline,
    mode: state?.mode || contourMode.evenSpaced,
    baseline: state?.baseline || 0,
    count: state?.count === undefined ? 10 : state.count,
    direction: state?.direction === undefined ? 1 : state.direction,
    scale: Math.abs(state?.scale || 1),
  };

  return newState;
};

export const oneBaselineContour: contourBundle = {
  generator: oneBaselineContourGenerator,
  init: initOneBaselineContourContours as contourInitializer,
};

// ---- Multi baseline contours ----
export type multiBaselineContourState = contourState & {
  count: number;
  states: oneBaselineContourState[];
};

const multiBaselineContourGenerator: contourGenerator = (
  state: multiBaselineContourState,
  min: number,
  max: number
) => {
  const thresholds: number[] = [];

  state.states.forEach((s) => {
    const t = oneBaselineContourGenerator(s, min, max);
    thresholds.push(...t);
  });

  return thresholds;
};

export const initMultiBaselineContourContours = (state?: multiBaselineContourState): multiBaselineContourState => {
  const newState: multiBaselineContourState = {
    type: contourTypes.multiBaseline,
    count: state?.count === undefined ? 1 : state.count,
    states: [],
  };

  for (let i = 0; i < newState.count; i++) {
    newState.states.push(initOneBaselineContourContours(state?.states?.[i] || ({} as oneBaselineContourState)));
  }

  return newState;
};

export const multiBaselineContour: contourBundle = {
  generator: multiBaselineContourGenerator,
  init: initMultiBaselineContourContours as contourInitializer,
};

// ---- Generic contours ----
export const createContourClass = (type?: contourTypes): contourBundle => {
  let generator: contourGenerator = (state: any, min: number, max: number) => [];
  let init: contourInitializer = (state?: contourState) => state || ({} as contourState);
  switch (type) {
    default:
    case contourTypes.equidistant:
      generator = equidistantContourGenerator as contourGenerator;
      init = initEquidistantContourContours as contourInitializer;
      break;
    case contourTypes.explicitContours:
      generator = explicitContoursGenerator as contourGenerator;
      init = initExplicitContours as contourInitializer;
      break;
    case contourTypes.oneBaseline:
      generator = oneBaselineContourGenerator as contourGenerator;
      init = initOneBaselineContourContours as contourInitializer;
      break;
    case contourTypes.multiBaseline:
      generator = multiBaselineContourGenerator as contourGenerator;
      init = initMultiBaselineContourContours as contourInitializer;
      break;
  }

  return {
    generator: generator,
    init: init,
  };
};

export const createContour = (state?: contourState): contourGeneratorType => {
  const contourClass = createContourClass(state?.type);

  return {
    state: contourClass.init(state),
    generate: contourClass.generator,
  };
};
