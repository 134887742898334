import { useState } from "react";
import { PanelResizeHandle } from "react-resizable-panels";

import styles from "./CustomResizeHandle.module.css";
import { LucideIcon } from "../../icon/LucideIcon";

interface CustomResizeHandleProps {
  className?: string;
  id?: string;
  orientation?: "horizontal" | "vertical";
}

export const CustomResizeHandle = ({ id, className = "", orientation }: CustomResizeHandleProps) => {
  const [isDragging, setIsDragging] = useState(false);

  return (
    <PanelResizeHandle className={[styles.ResizeHandleOuter, className].join(" ")} id={id} onDragging={setIsDragging}>
      <div
        className={styles.ResizeHandleInner}
        data-is-dragging={isDragging}
        style={{
          ...(orientation === "horizontal" && { width: 5, top: 0, left: "-2.5px" }),
          ...(orientation === "vertical" && { height: 5, top: "-2.5px", left: 0 }),
        }}
      >
        <div
          className={styles.IconWrapper}
          style={{
            ...(orientation === "horizontal" && { left: "calc(50% - 10px)", top: "calc(50% - 10px)" }),
            ...(orientation === "vertical" && { left: "calc(50% - 10px)", top: "calc(50% - 10px)" }),
          }}
        >
          <LucideIcon
            className={styles.Icon}
            name={orientation === "horizontal" ? "unfold-horizontal" : "unfold-vertical"}
            size={24}
          />
        </div>
      </div>
    </PanelResizeHandle>
  );
};
