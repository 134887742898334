import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";
import { useResizeDetector } from "react-resize-detector";

// import styles from "./ResizeLayout.module.css";
type viewPortType = { width: number; height: number; left: number; top: number };

export const FitToParentDivOld = (props: any) => {
  const {
    children,
    fillViewPortHeight,
    setViewPort,
  }: { children: any; fillViewPortHeight: boolean; setViewPort?: (viewPort: viewPortType) => void } = props;
  const targetRef = useRef<HTMLDivElement>(null);
  const [dimension, setDimensions] = useState<{ width: number; height: number }>({ width: 0, height: 0 });

  const handleResize = useCallback(() => {
    if (targetRef.current) {
      const element = targetRef.current as Element;
      const bound = element?.parentElement?.getBoundingClientRect() || { width: 0, height: 0, top: 0, left: 0 };

      // console.log(">>", bound, window.innerHeight);
      let dim: viewPortType;
      if (fillViewPortHeight)
        dim = { width: bound.width - 4, height: window.innerHeight - bound.top - 4, left: bound.left, top: bound.top };
      else dim = { width: bound.width - 4, height: bound.height - 4, left: bound.left, top: bound.top };
      setDimensions(dim);
      if (setViewPort) setViewPort(dim);
      // if (fillViewPortHeight) setDimensions({ width: bound.width - 4, height: window.innerHeight - bound.top - 4 });
      // else setDimensions({ width: bound.width - 4, height: bound.height - 4 });
    }
  }, [targetRef, setViewPort, fillViewPortHeight]);

  useLayoutEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
  }, [handleResize]);

  if (React.Children.count(children) !== 1)
    throw new Error(`FitToParentDiv expect exactly one child (got ${React.Children.count(children)}).`);

  React.Children.map(children, (child) => {
    // checking isValidElement is the safe way and avoids a typescript error too
    console.log("dim", dimension);

    if (React.isValidElement(child))
      return React.cloneElement(child, { width: dimension.width, height: dimension.height } as any);
    return child;
  });

  return (
    <div ref={targetRef} style={{ width: "100%", height: "100%", background: "Orchid" }}>
      {}
    </div>
  );
  // return <div ref={targetRef}>{childrenWithProps}</div>;
};

export const FitToParentDiv = (props: any) => {
  const { children, setViewPort }: { children: any; setViewPort?: (viewPort: viewPortType) => void } = props;
  // const targetRef = useRef<HTMLDivElement>(null);
  const [dimension, setDimensions] = useState<{ width: number; height: number }>({ width: 0, height: 0 });
  const fillViewPortHeight = false;

  const { width, height, ref } = useResizeDetector();

  if (React.Children.count(children) !== 1)
    throw new Error(`FitToParentDiv expect exactly one child (got ${React.Children.count(children)}).`);

  useEffect(() => {
    if (ref.current) {
      const element = ref.current as Element;
      const bound = element?.parentElement?.getBoundingClientRect() || { width: 0, height: 0, top: 0, left: 0 };

      // console.log(">>", bound, window.innerHeight);
      let dim: viewPortType;
      if (fillViewPortHeight)
        dim = { width: bound.width - 4, height: window.innerHeight - bound.top - 4, left: bound.left, top: bound.top };
      else dim = { width: width ?? 0, height: height ?? 0, left: bound.left, top: bound.top };
      setDimensions(dim);
      if (setViewPort) setViewPort(dim);
      // if (fillViewPortHeight) setDimensions({ width: bound.width - 4, height: window.innerHeight - bound.top - 4 });
      // else setDimensions({ width: bound.width - 4, height: bound.height - 4 });
    }
  }, [ref, width, height, fillViewPortHeight, setViewPort]);

  const childrenWithProps = React.Children.map(children, (child) => {
    // checking isValidElement is the safe way and avoids a typescript error too

    if (React.isValidElement(child))
      return React.cloneElement(child, { width: dimension.width, height: dimension.height } as any);
    return child;
  });

  return (
    <div ref={ref} style={{ width: "100%", height: "100%" }}>
      {childrenWithProps}
    </div>
  );
  // return <div ref={targetRef}>{childrenWithProps}</div>;
};
