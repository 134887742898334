import "core-js";
import "react-toastify/dist/ReactToastify.css";
import "./index.css";
import "./index-override.css";

import React from "react";
import ReactDOM from "react-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { BrowserRouter, HashRouter, Route, Switch } from "react-router-dom";
import { Announcements as AnnouncementsReact } from "./Announcements/Announcements";
import { API } from "./api/Api";
import { ApiAuthentication } from "./api/ApiTypes";
import { ApiKeyDataLayer } from "./ApiKeyManager/ApiKeyDataLayer";
import App from "./App";
import { Dashboard as LOGSDashboard } from "./Dashboard/Dashboard";
import { getDatasetsDownloadUrl, triggerUrlDownload } from "./Dataset/common/DownloadDataset/DownloadDataset";
import { ConductanceIntegration } from "./main/ConductanceIntegration";
import { ConductanceIntegrationSessionContextProvider } from "./main/ConductanceIntegrationSessionContextProvider";
import { GroupRouter } from "./main/GroupRouter";
import { SamplesConductanceIntegration } from "./samples/SamplesRouter";
import { SequenceComponent } from "./SequenceViewer/SequenceComponent";
import * as serviceWorker from "./serviceWorker";
import { SharedBundlesConductanceIntegrationWrapper, SharedBundlesUIWrapper } from "./SharedBundles/SharedBundles";
import { SharedBundlesView } from "./SharedBundles/SharedBundlesView";
import { Viewer } from "./ViewerLayout/ViewerLayout";
import { FullScreenWrapper } from "./common/wrapper/FullScreenWrapper/FullScreenWrapper";
import styles from "./main/main.module.css";
import { AccountRequiredFieldWrapper, MigrationModeWrapper } from "./main/Main";
import { DatasetDetailsPage } from "./Dataset/DatasetViews";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retryDelay: 500,
      retry: 2,
    },
  },
});

// Don't render top-level component when we're inside LOGS/Conductance
if (!(window as any).__oni_rt) {
  ReactDOM.render(
    <QueryClientProvider client={queryClient}>
      <GroupRouter />
    </QueryClientProvider>,
    document.getElementById("root")
  );
}

const QueryClientWrapper = ({ children }: { children: any }) => (
  <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
);

const WrappedConductanceIntegration = (props: any) => (
  <FullScreenWrapper>
    <QueryClientWrapper>
      <div className={styles.mainContentContainer}>
        <ConductanceIntegration {...props} />
      </div>
    </QueryClientWrapper>
  </FullScreenWrapper>
);

const Samples = (props: any) => (
  <QueryClientWrapper>
    <SamplesConductanceIntegration {...props} />
  </QueryClientWrapper>
);

const Announcements = (props: any) => {
  return (
    <QueryClientWrapper>
      <ConductanceIntegrationSessionContextProvider api={props.api} group={props.group}>
        <HashRouter hashType="noslash">
          <AnnouncementsReact />
        </HashRouter>
      </ConductanceIntegrationSessionContextProvider>
    </QueryClientWrapper>
  );
};

const Dashboard = (props: any) => {
  return (
    <QueryClientWrapper>
      <ConductanceIntegrationSessionContextProvider api={props.api} group={props.group}>
        <MigrationModeWrapper>
          <AccountRequiredFieldWrapper>
            <LOGSDashboard />
          </AccountRequiredFieldWrapper>
        </MigrationModeWrapper>
      </ConductanceIntegrationSessionContextProvider>
    </QueryClientWrapper>
  );
};
const SharedBundles = (props: any) => (
  <QueryClientWrapper>
    <BrowserRouter>
      <Switch>
        <Route path="/:group/shared/:sessionId/:id" exact={true}>
          <SharedBundlesConductanceIntegrationWrapper {...props}>
            <SharedBundlesUIWrapper>
              <DatasetDetailsPage viewShared={true} />
            </SharedBundlesUIWrapper>
          </SharedBundlesConductanceIntegrationWrapper>
        </Route>
        <Route path="/:group/shared/:sessionId/:id/viewer" exact={true}>
          <SharedBundlesConductanceIntegrationWrapper {...props}>
            <SharedBundlesUIWrapper>
              <DatasetDetailsPage viewShared={true} viewViewer={true} />
            </SharedBundlesUIWrapper>
          </SharedBundlesConductanceIntegrationWrapper>
        </Route>
        <Route path="/:group/shared/:sessionId" exact={true}>
          <SharedBundlesConductanceIntegrationWrapper {...props}>
            <SharedBundlesUIWrapper>
              <SharedBundlesView />
            </SharedBundlesUIWrapper>
          </SharedBundlesConductanceIntegrationWrapper>
        </Route>
      </Switch>
    </BrowserRouter>
  </QueryClientWrapper>
);

(window as any).LOGS_React_interface = {
  render: ReactDOM.render,
  ReactDOM: ReactDOM,
  React: React,
  components: {
    App: App,
    ApiKeyManagement: ApiKeyDataLayer,
    SequenceViewer: SequenceComponent,
    NewViewer: Viewer,
    Announcements: Announcements,
    // AnnouncementList: AnnouncementsOverview,
    Samples: Samples,
    Dashboard: Dashboard,
    Router: WrappedConductanceIntegration,
    SharedBundles: SharedBundles,
    DownloadDatasets: getDatasetsDownloadUrl,
    TriggerDownloadLink: triggerUrlDownload,
  },
  getAPI: (group: string, apikey: ApiAuthentication) => new API(group, apikey),
};

// console.log("ADDING LOGS REACT INTERFACE1: ", (window as any).LOGS_React_interface);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

/*
import { UserManager, UserManagerSettings, WebStorageStateStore } from "oidc-client-ts";
import ReactDOM from "react-dom";
import { AuthProvider, AuthProviderProps, useAuth } from "react-oidc-context";

console.log("Started OIDC Test");

const oidcConfig: AuthProviderProps = {
  authority: "http://localhost:8080/realms/LOGS", // Keycloak realm URL
  client_id: "logs", // Client ID from Keycloak
  redirect_uri: "http://localhost:3000/callback", // Where Keycloak will redirect after login
  response_type: "code", // Use 'code' for Authorization Code Flow (recommended)
  scope: "openid profile email", // OIDC scopes, depending on what you need
  post_logout_redirect_uri: "http://localhost:3000/", // After logout, redirect here
  userStore: new WebStorageStateStore({ store: window.localStorage }), // Store session info in local storage
  onSigninCallback: (user) => {
    window.history.replaceState({}, document.title, "test");
  },
};


ReactDOM.render(
  <AuthProvider {...oidcConfig}>
    <App />
  </AuthProvider>,
  document.getElementById("root")
);

function App() {
  const auth = useAuth();

  switch (auth.activeNavigator) {
    case "signinSilent":
      return <div>Signing you in...</div>;
    case "signoutRedirect":
      return <div>Signing you out...</div>;
  }

  if (auth.isLoading) {
    return <div>Loading...</div>;
  }

  if (auth.error) {
    return <div>Oops... {auth.error.message}</div>;
  }

  if (auth.isAuthenticated) {
    return (
      <div>
        Hello {auth.user?.profile.sub} <button onClick={() => void auth.removeUser()}>Log out</button>
      </div>
    );
  }

  return <button onClick={() => void auth.signinRedirect()}>Log in</button>;
}
*/
