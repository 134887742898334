import * as d3 from "d3";
import { Position } from "geojson";

import {
  DatatrackNumericArray,
  DatatrackNumericMatrix,
  TrackMatrixData,
  TrackMatrixSettings,
  TrackXYData,
  TrackXYSettings,
} from "../ViewerLayout/ViewerLayoutTypes";
import { Annotation } from "./../ViewerLayout/ViewerLayoutTypes";
import { idArrayToId, initColor, needUpdate } from "./../ViewerLayout/ViewerLayoutUtils";
import styles from "./GraphViewer.module.css";
import { difference, translateAnnotationTypeToShape as translateAnnotationTypeToShapes } from "./GraphViewerHelper";
import {
  AnnotationShape,
  AXIS,
  COLOR,
  contourPath,
  CROSSHAIR,
  d3LinearType,
  d3ScaleLinearType,
  domainType,
  DRAW,
  DRAW2D,
  drawStyle,
  drawType,
  LinearGenerator,
  NORM,
  parameterType,
  PathType,
  posListenerType,
  redrawListenerType,
  ShapeCircle,
  shapeFunction,
  ShapeLine,
  ShapeRect,
  ShapeText,
  shapeTypes,
  ShapeXArea,
  textType,
  TICS,
  Track,
  trackContourType,
  trackdata1DType,
  trackDrawType,
  trackHeatmapCanvasType,
  trackProperties,
  vector2D,
  VIEW,
  visibilityListenerType,
  ZOOM,
} from "./GraphViewerTypes";

type inputParameterType = Partial<parameterType> & {
  graphPrefix?: string;
  annotationPrefix?: string;
  width?: number;
  height?: number;
  translateExtent?: [number, number];
  interactive?: boolean;
};

export class GraphViewer {
  // id: string;
  interactive: boolean;
  parameter: parameterType;
  parent: any;
  width: number;
  height: number;
  graphPrefix: string;
  annotationPrefix: string;
  tracks: Record<string, trackProperties>;
  trackOrder: string[];
  trackIndex: Record<string, number>;
  shiftHold: boolean;
  ControlHold: boolean;
  annotationList: Record<string, Annotation>;
  annotationShapes: AnnotationShape[] | undefined;
  showAnnotations: boolean;

  doDownSample: boolean;
  doDownSample2D: boolean;
  sliderDist: 10;
  showLabels: boolean;
  rangeScale: 0.01;
  isDrawing: boolean;
  mustRedraw: boolean;
  colorCounter: 0;
  colorScaleCounter: 0;
  axisMode: AXIS;
  zoomMode: ZOOM;
  viewMode: VIEW;
  colorMode: COLOR;
  drawMode: DRAW;
  ticsXYMode: TICS;
  draw2DMode: DRAW2D;
  crosshairMode: CROSSHAIR;
  normYMinMax: Record<number, [number, number]>;
  shapeTypes: Record<shapeTypes, { label: number; position: number }>;
  updateAnnotationShapes: boolean;
  autoPadding: boolean;
  padding: { top: number; right: number; bottom: number; left: number };
  size: { width: number; height: number };
  scale: number | undefined;
  xScale: number;
  yScale: number;
  xTranslate: number;
  yTranslate: number;
  touchStart?: vector2D[];
  x: d3ScaleLinearType;
  y: d3ScaleLinearType;
  downX: number;
  downy: number;
  doOffsetBarDrag: boolean;
  domainX: number[];
  domainY: number[];
  selectBoxPos: [number, number, number, number] | undefined;
  boxZoom: boolean;
  cachedState: { domain: domainType; tracks: string[] };
  xFormatter: (n: number | { valueOf(): number }) => string;
  yFormatter: (n: number | { valueOf(): number }) => string;

  canvas: any; // SVG
  background: any; // Rect
  plotCanvas: any; // Canvas
  graphSVG: any; // SVG
  xRect: any; // Rect
  yRect: any; // Rect
  exportGraph: any; // Group
  graph: any; // Group
  rect: any; // Rect
  plotGroup: any; // Group
  plot: any; // SVG
  cPlot: any; // Context("2D")
  cline: any; // Line
  offsetAxisGroup: any; // Group
  offsetAxis: any; // Line
  offsetAxisDot: any; // Circle
  crosshair: any; // Group
  annotation: any; // SVG
  selectBox: any; // Group
  line: d3LinearType;

  resetTranslate: boolean;

  lastClick: number;
  clickListener?: posListenerType;
  posListener?: posListenerType;
  visibilityListener: Record<string, { listener: visibilityListenerType; state: boolean }>;
  redrawListener?: redrawListenerType;

  constructor(canvasID: HTMLDivElement, parameter: inputParameterType) {
    if (!canvasID) throw new Error("No canvas was defined.");

    // self.parent = d3.select("#" + canvasID);
    this.parameter = {} as parameterType;

    this.parent = d3.select(canvasID);

    // var dummy = self.parent.node().offsetHeight;

    // console.log("GraphViewer", parameter);

    this.width = parameter.width === undefined ? this.parent.node().clientWidth : parameter.width;
    this.height = parameter.height === undefined ? this.parent.node().clientHeight : parameter.height;

    // console.log("parent", this.width, this.height);
    // self.params = shape ? shape : {};
    parameter = parameter || {};
    // console.log("PARAMS:", params)
    // this.parameter = parameter;
    this.parameter.xMin = parameter.xMin === undefined ? -1 : parameter.xMin;
    this.parameter.xMax = parameter.xMax === undefined ? 1 : parameter.xMax;
    this.parameter.yMin = parameter.yMin === undefined ? -1 : parameter.yMin;
    this.parameter.yMax = parameter.yMax === undefined ? 1 : parameter.yMax;

    // console.log("self.params.doLoading", params);

    // self.params.xNum = params.xNum === undefined ? 130 : params.xNum;
    this.parameter.xNum = parameter.xNum === undefined ? this.width : parameter.xNum;
    this.parameter.yNum = parameter.yNum === undefined ? this.height : parameter.yNum;

    // self.params.xNum = 10;

    this.parameter.offsetAngle = parameter.offsetAngle === undefined ? Math.PI / 2 : parameter.offsetAngle;
    this.parameter.offsetPadding = parameter.offsetPadding === undefined ? 0 : parameter.offsetPadding;
    // console.log("init offset", this.parameter.offsetPadding);

    // self.params.offsetAngle = params.offsetAngle === undefined ? 45 *
    // Math.PI / 180 : params.offsetAngle;

    // self.params.offsetAngle = 15 * Math.PI / 180;

    this.graphPrefix = parameter.graphPrefix === undefined ? "graph" : parameter.graphPrefix;
    this.annotationPrefix = parameter.annotationPrefix === undefined ? "annotation" : parameter.annotationPrefix;
    this.parameter.scaleExtent = parameter.scaleExtent === undefined ? [0.01, 100] : parameter.scaleExtent;

    this.parameter.translate = parameter.translate === undefined ? [0, 0] : parameter.translate;

    if (parameter.translateExtent) {
      this.parameter.minTranslateExtent = parameter.translateExtent;
      this.parameter.maxTranslateExtent = parameter.translateExtent;
    }

    this.parameter.minTranslateExtent =
      parameter.minTranslateExtent === undefined ? [0, 0] : parameter.minTranslateExtent;
    this.parameter.maxTranslateExtent =
      parameter.maxTranslateExtent === undefined ? [0, 0] : parameter.maxTranslateExtent;

    this.interactive = parameter.interactive ?? true;
    // this.id = parameter.idStart === undefined ? "0" : parameter.idStart;
    // this.idAnnotation = parameter.idStart === undefined ? 0 : parameter.idStart;
    this.tracks = {};
    this.trackOrder = [];
    this.trackIndex = {};

    // this.datasetParams = {};
    // this.trackLabels = {};
    // this.trackTags = {};
    // this.storedCanvas = { new: true };

    this.shiftHold = false;
    this.ControlHold = false;

    this.visibilityListener = {};

    this.annotationList = {};
    this.annotationShapes = undefined;
    this.showAnnotations = true;

    this.doDownSample = true;
    this.doDownSample2D = true;
    this.showAnnotations = true;

    this.sliderDist = 10;
    this.showLabels = false;

    this.rangeScale = 0.01;

    this.isDrawing = false;
    this.mustRedraw = false;
    this.resetTranslate = false;

    this.colorCounter = 0;
    this.colorScaleCounter = 0;

    this.xFormatter = d3.format("0.3f");
    this.yFormatter = d3.format("0.3f");

    // self.addOffset = false;

    this.axisMode = AXIS.FREE;
    this.zoomMode = ZOOM.VISIBLE;
    this.viewMode = VIEW.ONETRACK;
    this.colorMode = COLOR.BY_TRACK;
    this.drawMode = DRAW.LINE;
    this.ticsXYMode = TICS.XY;
    this.draw2DMode = DRAW2D.HEATMAP;
    this.crosshairMode = CROSSHAIR.ALL;

    this.normYMinMax = {
      1: [0, 1],
      2: [-1, 1],
    };

    this.shapeTypes = {
      xPoint: {
        // mode: full (default), low, high
        label: 1,
        position: 1,
      },
      yPoint: {
        // mode: full (default), low, high
        label: 1,
        position: 1,
      },
      xyPoint: {
        label: 1,
        position: 2,
      },
      xRange: {
        // mode: full (default), low, high
        label: 3, // label, min_label, max_label
        position: 2,
      },
      yRange: {
        label: 3, // label, min_label, max_label
        position: 2,
      },
      box: {
        label: 5, // label, left_bottom_label, left_top_label, right_bottom_label, right_top_label
        position: 4, // [x1, y1, x2, y2] = [left, top, right, bottom]
      },
      xArea: {
        label: 3, // label, min_label, max_label
        position: 2,
      },
      xPeak: {
        // mode: impulse (default), tick
        label: 1,
        position: 1,
      },
    };
    this.updateAnnotationShapes = true;

    this.autoPadding = false;

    // Label always active
    this.padding = {
      top: this.parameter.title ? 40 : 10,
      right: 20,
      bottom: 35,
      left: 75,
    };

    this.size = {
      width: this.width - this.padding.left - this.padding.right,
      height: this.height - this.padding.top - this.padding.bottom,
    };

    // console.log("paddin", self.padding, self.size);

    // if (this.parameter.offsetAngle === undefined) this.parameter.offsetAngle = this.getOffsetDiagonalAngle();
    // self.params.maxOffsetPadding = self.getGraphDiagonal() / 3;
    this.parameter.maxOffsetPadding = this.size.height / 10;

    // self.params.offsetAngle = 45 * Math.PI / 180;

    // console.log("self.offsetAngle", self.params.offsetAngle,
    // self.params.offsetPadding);

    this.scale = 1;
    this.xScale = 1;
    this.yScale = 1;
    this.xTranslate = 0;
    this.yTranslate = 0;

    this.lastClick = 0;

    this.touchStart = undefined;

    // x-scale
    this.x = d3
      .scaleLinear()
      .domain([this.parameter.xMin, this.parameter.xMax])
      .range([0, this.size.width]) as d3ScaleLinearType;

    // y-scale
    this.y = d3
      .scaleLinear()
      .domain([this.parameter.yMax, this.parameter.yMin])
      .nice()
      .range([0, this.size.height])
      .nice() as d3ScaleLinearType;

    // drag x-axis
    this.downX = Number.NaN;

    // drag y-axis
    this.downy = Number.NaN;

    // drag offset axis
    this.doOffsetBarDrag = false;

    this.domainX = this.x.domain();
    this.domainY = this.y.domain();

    this.selectBoxPos = undefined;
    this.boxZoom = false;

    this.cline = d3.line();

    this.line = d3.line();

    this.cachedState = { domain: { range: [], points: [] }, tracks: [] };

    this.createCanvas();

    this.redraw();
  }

  createCanvas() {
    /*
     * self.plotCanvas = self.parent.append("div") .append("canvas")
     * .style("position", "absolute") .style("left",
     * self.padding.left + "px") .style("top", self.padding.top +
     * "px") .attr("width", self.size.width) .attr("height",
     * self.size.height)
     */

    // console.log("Parent:", self.parent)

    // console.log("size", this.width, this.height);

    // *** background and axis ***
    // if (self.canvas) delete self.canvas;
    this.canvas = this.parent
      .append("div")
      .attr("id", "GraphViewer-background-div")
      .style("position", "absolute")
      .append("svg")
      .attr("id", "canvas")
      .attr("width", this.width)
      .attr("height", this.height)
      .attr("class", styles.canvas);
    // .attr("class", styles.canvas);

    this.background = this.canvas
      .append("rect")
      .attr("x", 0)
      .attr("y", 0)
      .attr("id", "background")
      .attr("width", this.width)
      .attr("height", this.height)
      .attr("class", styles.canvasBackground);

    // *** drawing canvas ***
    this.plotCanvas = this.parent
      .append("div")
      .attr("id", "GraphViewer-canvas-div")
      .append("canvas")
      .attr("id", "GraphViewer-canvas")
      .style("position", "absolute")
      .style("left", this.padding.left + "px")
      .style("top", this.padding.top + "px")
      .attr("width", this.size.width)
      .attr("height", this.size.height);

    // *** SVG layer for crosshair and axis text ***
    // The actual graph area SVG
    // self.graphSVG = self.canvas.append("svg")
    this.graphSVG = this.parent
      .append("div")
      .attr("id", "GraphViewer-SVG-div")
      .append("svg")
      .attr("id", "graphSVG")
      .attr("class", styles.graphSVG)
      .style("position", "absolute")
      .attr("width", this.width)
      .attr("height", this.height)
      .style("cursor", "crosshair");

    // The actual graph area
    // self.graph = self.graphSVG.append("g")
    // .attr("transform", "translate(" + self.padding.left + "," +
    // self.padding.top + ")")
    // .attr("pointer-events", "all")
    // .on("mousedown.drag", self.plot_drag())

    // The actual graph area
    // self.graph = self.canvas
    // .append("svg")
    // .attr("id", "graph")
    // .attr("width", self.width)
    // .attr("height", self.height)

    if (this.size.width < 0) this.size.width = 0;
    if (this.size.height < 0) this.size.height = 0;
    this.xRect = this.graphSVG
      .append("rect")
      // .attr("transform", "translate(" + 0 + "," +
      // self.size.height + ")")
      .attr("x", this.padding.left)
      .attr("y", this.padding.top + this.size.height)
      .attr("width", this.size.width)
      .attr("height", this.height - this.size.height)
      .attr("class", styles.axisRect);

    this.yRect = this.graphSVG
      .append("rect")
      // .attr("transform", "translate(" + (-self.padding.left) +
      // "," + 0 + ")")
      .attr("x", 0)
      .attr("y", this.padding.top)
      .attr("width", this.padding.left)
      .attr("height", this.size.height)
      .attr("class", styles.axisRect);
    // .style("fill", "red")

    // self.graph = self.canvas
    this.exportGraph = this.canvas
      .append("g")
      .attr("transform", "translate(" + this.padding.left + "," + this.padding.top + ")");

    this.graph = this.graphSVG
      .append("g")
      .attr("transform", "translate(" + this.padding.left + "," + this.padding.top + ")")
      .style("text-rendering", "optimizeSpeed");
    // .attr("pointer-events", "all")
    // .on("mousedown.drag", self.plot_drag())

    this.rect = this.graph
      .append("rect")
      // self.rect = self.graphSVG.append("rect")
      .attr("class", styles.graph)
      .attr("width", this.size.width)
      .attr("height", this.size.height)
      .attr("id", "rect")
      // .attr("transform", "translate(" + self.padding.left + ","
      // + self.padding.top + ")")
      // .attr("pointer-events", "all")
      // .on("mousedown.drag", self.plot_drag())
      // .on("touchstart.drag", self.plot_drag())
      .on("mouseenter", () => {
        this.graph_mouseenter();
      });

    /*
     * self.plot = self.graph.append("svg") .attr("top", 0)
     * .attr("left", 0) .attr("width", self.size.width)
     * .attr("height", self.size.height) .attr("viewBox", "0 0 " +
     * self.size.width + " " + self.size.height)
     */

    this.plotGroup = this.canvas
      .append("g")
      .attr("transform", "translate(" + this.padding.left + "," + this.padding.top + ")");

    // self.plot = self.plotGroup.append("svg")
    this.plot = this.graph
      .append("svg")
      .attr("class", styles.plot)
      .attr("top", 0)
      .attr("left", 0)
      .attr("width", this.size.width)
      .attr("height", this.size.height)
      .attr("viewBox", "0 0 " + this.size.width + " " + this.size.height);

    this.parent.style("position", "relative");

    /*
     * self.plotCanvas = self.parent.append("div") .append("canvas")
     * .style("position", "absolute") .style("left",
     * self.padding.left + "px") .style("top", self.padding.top +
     * "px") .attr("width", self.size.width) .attr("height",
     * self.size.height/2) //~ .style("border-style", "solid")
     */

    // -------------------------------------
    /*
     * //get DPI var dpi = window.devicePixelRatio;
     *
     * var canvas = self.plotCanvas.node(); //get context var ctx =
     * canvas.getContext('2d');
     *
     * let style_height =
     * +getComputedStyle(canvas).getPropertyValue("height").slice(0,
     * -2);
     *
     * //get CSS width let style_width =
     * +getComputedStyle(canvas).getPropertyValue("width").slice(0,
     * -2);
     *
     * //scale the canvas console.log(">>",
     * getComputedStyle(canvas).getPropertyValue("height").slice(0,
     * -2), dpi); console.log(">>",
     * getComputedStyle(canvas).getPropertyValue("width").slice(0,
     * -2), dpi); canvas.setAttribute('height', style_height * dpi);
     * canvas.setAttribute('width', style_width * dpi);
     */
    // -------------------------------------

    this.cPlot = this.plotCanvas.node().getContext("2d");
    // console.log("cPlot", self.cPlot);

    // self.cPlot.translate(self.padding.left+100,
    // self.padding.top);

    this.cline = d3
      .line()
      .x((d) => {
        return this.x(d[0]) ?? 0;
      })
      .y((d) => {
        return this.y(d[1]) ?? 0;
      })
      .curve(this.interpolateTypes[0])
      .context(this.cPlot);

    // Title
    if (this.parameter.title) {
      this.canvas
        .append("text")
        .text(this.parameter.title)
        .attr("class", styles.title)
        // .attr("x", self.size.width/2)
        .attr("x", this.padding.left)
        .attr("dx", this.size.width / 2)
        .attr("y", this.padding.top)
        .attr("dy", "-0.8em")
        .style("text-anchor", "middle");
    }

    // X-axis label
    // if (self.params.xLabel) {
    this.graphSVG
      .append("text")
      // .text(self.params.xLabel)
      .attr("id", "xLabel")
      .attr("class", [styles.axislabel, styles.noselect].join(" "))
      .style("text-anchor", "middle")
      // .attr("x", self.padding.left + self.size.width/2)
      // .attr("dx", self.size.width/2)
      // .attr("y", self.padding.top + self.size.height)
      // .attr("dy","2.4em")
      .attr(
        "transform",
        `translate(${this.padding.left + this.size.width / 2}, ${this.padding.top + this.size.height + 30})`
      );

    // Y-axis label
    this.graphSVG
      .append("g")
      .append("text")
      .attr("id", "yLabel")
      .attr("class", [styles.axislabel, styles.noselect].join(" "))
      .style("text-anchor", "middle")
      .attr("transform", `translate(${this.padding.left / 2}, ${this.padding.top + this.size.height / 2}) rotate(-90)`);

    this.offsetAxisGroup = this.graphSVG.append("g").attr("id", "offsetAxisGroup");

    this.offsetAxis = this.offsetAxisGroup.append("line").attr("class", styles.offsetAxisLine);
    this.offsetAxisDot = this.offsetAxisGroup.append("circle").attr("class", styles.offsetAxisDot).attr("r", 3);

    this.offsetAxisGroup.style("display", "none");

    this.updateOffsetAxis();

    this.crosshair = this.graphSVG
      .append("g")
      .style("display", "none")
      .attr("transform", `translate(${this.padding.left}, ${this.padding.top})`);

    // self.annotation = self.graphSVG
    // 	.append("g")
    // 	.attr("transform", "translate(" + self.padding.left + "," + self.padding.top + ")")
    // 	.attr("width", self.size.width)
    // 	.attr("height", self.size.height)
    // 	.attr("viewBox", "100 0 " + self.size.width + " " + self.size.height)

    this.annotation = this.graphSVG
      .append("g")
      .attr("transform", `translate(${this.padding.left}, ${this.padding.top})`)
      .append("svg")
      .attr("id", "GraphViewer-annotation")
      .attr("top", 0)
      .attr("left", 0)
      .attr("width", this.size.width)
      .attr("height", this.size.height)
      .attr("viewBox", "0 0 " + this.size.width + " " + this.size.height);

    // .attr("transform", "translate(" + self.padding.left + "," +
    // self.padding.top + ")")

    this.crosshair.append("line").attr("id", "crosshairX").attr("class", styles.crosshairLine);

    this.crosshair.append("line").attr("id", "crosshairY").attr("class", styles.crosshairLine);

    this.crosshair.append("rect").attr("id", "crosshairXBox").attr("class", styles.crosshairText);

    this.crosshair.append("rect").attr("id", "crosshairYBox").attr("class", styles.crosshairText);

    this.crosshair
      .append("text")
      .attr("id", "crosshairXText")
      .attr("class", styles.crosshairText)
      .attr("dx", 0)
      .attr("dy", 0);

    this.crosshair
      .append("text")
      .attr("id", "crosshairYText")
      .attr("class", styles.crosshairText)
      .attr("dx", 0)
      .attr("dy", 0);

    this.crosshair
      .append("text")
      .attr("id", "crosshairText")
      .attr("class", styles.crosshairText)
      .attr("dx", 0)
      .attr("dy", 0);

    // self.selectBox = self.graph.append('g').style('display',
    // 'none');
    this.selectBox = this.graphSVG.append("g").style("display", "none");

    this.selectBox
      .append("rect")
      .attr("x", 0)
      .attr("y", 0)
      .attr("width", 10)
      .attr("height", 10)
      .attr("id", "selectBox")
      .attr("class", styles.selectBox);

    // self.offsetAxisSliderLine.style('display', 'none');

    /*
     * self.canvas .on("mousemove.drag", d)  =>{
     * self.canvas_mousemove(d) } ) .on("touchmove.drag",
     * d)  =>{ self.canvas_mousemove(d) } )
     * .on("mouseup.drag", ) { => self.canvas_mouseup() } )
     * .on("touchend.drag", ) { => self.canvas_mouseup() } )
     * .on("mouseleave", ) { => self.canvas_mouseup() } )
     */

    this.line = d3
      .line()
      .x((d) => {
        return this.x(d[0]) ?? 0;
      })
      .y((d) => {
        return this.y(d[1]) ?? 0;
      })
      .curve(this.interpolateTypes[0]);

    const xZoom = d3.zoom().on("zoom", () => {
      const k = d3.event.transform.k / this.xScale;

      // if (k - self.scale === 0) k = 1;

      // console.log("xZoom", d3.event.transform.k, "->", k);
      this.canvas_mouseup();

      this.xScale = d3.event.transform.k;

      // console.log("X",
      // self.x.invert(d3.event.sourceEvent.clientX -
      // self.padding.left));

      this.zoomXY(
        this.x.invert(d3.event.sourceEvent.clientX - this.padding.left),
        this.y.invert(d3.event.sourceEvent.clientY - this.padding.top - 80),
        k,
        1
      );
    });

    const yZoom = d3.zoom().on("zoom", () => {
      const k = d3.event.transform.k / this.yScale;

      // if (k - self.scale === 0) k = 1;

      // console.log("yZoom", d3.event.transform.k, "->", k);

      this.yScale = d3.event.transform.k;

      // console.log("Y",
      // self.y.invert(d3.event.sourceEvent.clientY -
      // self.padding.top-80), self.y(-1), self.y(1));

      this.zoomXY(
        this.x.invert(d3.event.sourceEvent.clientY - this.padding.left),
        this.y.invert(d3.event.sourceEvent.clientY - this.padding.top - 80),
        1,
        k
      );
    });

    this.createColorScales();

    // events on graph are not necessary anymore
    // self.graph
    if (this.interactive) {
      this.graphSVG
        .on("click", () => {
          this.graph_click();
        })
        .on("mouseleave", () => {
          // console.log("graphSVG mouseleave")
          this.graph_mouseleave();
        })
        .on("mousemove", () => {
          this.graph_mousemove();
        })
        .on("touchstart", () => {
          this.graph_touchmove();
        })
        .on("touchmove", () => {
          this.graph_touchmove();
        })
        .on("touchend", () => {
          this.graph_touchmove();
        });

      // window.onkeydown = (e) => {
      //   console.log("key down", e.key, e.keyCode);
      //   console.log("shift hold", self.shiftHold);
      //   var step = 0;
      //   if (self.shiftHold) step = 10;
      //   else if (self.ControlHold) step = 20;

      //   if (e.keyCode === 16) self.shiftHold = true; // Shift
      //   if (e.keyCode === 17) self.ControlHold = true; // Control
      //   if (e.keyCode === 39) self.translateGraph(-step, 0); // Shift + ArrowRight
      //   if (e.keyCode === 37) self.translateGraph(step, 0); // Shift + ArrowLeft
      //   if (e.keyCode === 38) self.translateGraph(0, step); // Shift + ArrowUp
      //   if (e.keyCode === 40) self.translateGraph(0, -step); // Shift + ArrowDown
      // };

      window.onkeyup = (e) => {
        if (e.key === "Shift") this.canvas_mouseup(); // Shift
      };

      this.xRect
        .on("mousemove.drag", () => {
          this.canvas_mousemove();
        })
        .on("mouseup.drag", () => {
          this.canvas_mouseup();
        })
        .on("click", () => {
          this.canvas_mouseup();
        })
        .on("mousemove", () => {
          this.graph_mousemove();
        });

      this.yRect
        .on("mousemove.drag", () => {
          this.canvas_mousemove();
        })
        .on("mouseup.drag", () => {
          this.canvas_mouseup();
        })
        .on("click", () => {
          this.canvas_mouseup();
        })
        .on("mousemove", () => {
          this.graph_mousemove();
        });

      this.plotCanvas
        .on("click", () => {
          this.graph_click();
        })
        .on("mouseleave", () => {
          this.graph_mouseleave();
        })
        .on("mousemove", () => {
          this.graph_mousemove();
        });

      // self.plot.selectAll("circle").exit().remove();

      // self.graph.call(self.zoom());
      this.graphSVG.call(this.zoom());
      this.plotCanvas.call(this.zoom());

      this.xRect.call(xZoom);
      this.yRect.call(yZoom);
    }
  }

  resizeCanvas() {
    // console.log("=> reSIZE");

    this.canvas.attr("width", this.width).attr("height", this.height);

    this.background.attr("x", 0).attr("y", 0).attr("width", this.width).attr("height", this.height);

    this.yRect
      .attr("x", 0)
      .attr("y", this.padding.top)
      .attr("width", this.padding.left)
      .attr("height", this.size.height);

    this.xRect
      .attr("x", this.padding.left)
      .attr("y", this.padding.top + this.size.height)
      .attr("width", this.size.width)
      .attr("height", this.height - this.size.height);

    this.plotCanvas.attr("width", this.size.width).attr("height", this.size.height);

    // The actual graph area SVG
    this.graphSVG.attr("width", this.width).attr("height", this.height);

    this.rect.attr("width", this.size.width).attr("height", this.size.height);
    // .attr("stroke", "red")

    this.plot
      .attr("top", 0)
      .attr("left", 0)
      .attr("width", this.size.width)
      .attr("height", this.size.height)
      .attr("viewBox", "0 0 " + this.size.width + " " + this.size.height);

    // Title
    if (this.parameter.title) {
      this.canvas
        .selectAll("#title")
        .attr("x", this.padding.left)
        .attr("dx", this.size.width / 2)
        .attr("y", this.padding.top);
    }

    this.graphSVG.selectAll("path");

    this.graphSVG
      .selectAll("#xLabel")
      .attr(
        "transform",
        `translate(${this.padding.left + this.size.width / 2}, ${this.padding.top + this.size.height + 30})`
      );

    // Y-axis label
    // if (self.params.yLabel) {
    this.graphSVG
      .selectAll("#yLabel")
      .attr(
        "transform",
        `translate(${this.padding.left - 60}, ${this.padding.top + this.size.height / 2}) rotate(-90)`
      );

    // this.annotationShapes = {};

    this.updateOffsetAxis();

    this.graph.selectAll("g.x").remove();
    this.graph.selectAll("g.y").remove();

    this.annotation
      .attr("width", this.size.width)
      .attr("height", this.size.height)
      .attr("viewBox", "0 0 " + this.size.width + " " + this.size.height);

    this.crosshair.attr("transform", "translate(" + this.padding.left + "," + this.padding.top + ")");
  }

  resize(width: number, height: number) {
    // var dummy = self.parent.node().offsetHeight;

    // console.log("INPUT", parseInt(width), parseInt(height));
    if (!width || width === undefined) width = this.parent.node().clientWidth;
    if (!height || height === undefined) height = this.parent.node().clientHeight;

    if (this.width === width && this.height === height) return;

    this.width = width;
    this.height = height;
    // console.log("=> SIZE", self.width, self.height);

    this.parameter.xNum = this.width; // from here the
    this.parameter.yNum = this.height; // from here the
    // user input of
    // xNum and yNum is
    // ignored !!!

    this.size = {
      width: this.width - this.padding.left - this.padding.right,
      height: this.height - this.padding.top - this.padding.bottom,
    };

    this.parameter.maxOffsetPadding = this.size.height / 10;

    // x-scale
    this.x.range([0, this.size.width]);

    // y-scale
    this.y.range([0, this.size.height]);

    this.resizeCanvas();

    this.updateAnnotationShapes = true;
    this.redraw();
  }

  getDomainBoarders() {
    const domX = [this.x(this.x.domain()[0]) ?? 0, this.x(this.x.domain()[1]) ?? 0];
    const domY = [this.y(this.y.domain()[0]) ?? 0, this.y(this.y.domain()[1]) ?? 0];

    let l, r, u, d;
    if (domX[0] < domX[1]) {
      l = domX[0];
      r = domX[1];
    } else {
      l = domX[1];
      r = domX[0];
    }

    if (domY[0] < domY[1]) {
      u = domY[0];
      d = domY[1];
    } else {
      u = domY[1];
      d = domY[0];
    }

    return [l, r, u, d];
  }

  setDomainX(domain: number[]) {
    this.x.domain(domain);
    this.domainX = this.x.domain();
  }

  setDomainY(domain: number[]) {
    this.y.domain(domain);
    this.domainY = this.y.domain();
  }

  checkInside(pos: [number, number]) {
    const [l, r, u, d] = this.getDomainBoarders();

    return pos[0] >= l && pos[0] <= r && pos[1] >= u && pos[1] <= d;
  }

  resetToInside(pos: [number, number]) {
    const [l, r, u, d] = this.getDomainBoarders();

    if (pos[0] < l) pos[0] = l;
    if (pos[0] > r) pos[0] = r;

    if (pos[1] < u) pos[1] = u;
    if (pos[1] > d) pos[1] = d;
  }

  translateGraph(x: number, y: number) {
    // console.log("translateGraph", x, y);
    if (this.axisMode === AXIS.FREE || this.axisMode === AXIS.FIXY) {
      const origDomain = this.x.domain();
      const domain = [this.x.invert(this.x(origDomain[0]) - x), this.x.invert(this.x(origDomain[1]) - x)];
      // this.x.domain(domain);
      this.setDomainX(domain);
    }

    if (this.axisMode === AXIS.FREE || this.axisMode === AXIS.FIXX) {
      const origDomain = this.y.domain();
      const domain = [this.y.invert(this.y(origDomain[0]) - y), this.y.invert(this.y(origDomain[1]) - y)];
      // this.y.domain(domain);
      this.setDomainY(domain);
    }
    this.redraw();
  }

  zoom() {
    return d3.zoom().on("zoom", () => {
      if (d3.event.sourceEvent === null) return;

      if (d3.event.sourceEvent.type === "mousemove") {
        const holdKeyZoom = d3.event.sourceEvent.shiftKey;
        // console.log("zoom", holdKeyZoom, this.boxZoom);

        if (this.boxZoom || holdKeyZoom) {
          // Draw zoombox

          const pos = d3.mouse(this.graph.node());
          if (!this.selectBoxPos) {
            if (this.checkInside(pos)) {
              this.selectBoxPos = [...pos, 0, 0];
              this.selectBox.style("display", null);
            }
          }

          if (this.selectBoxPos) {
            let rx, ry, w, h;

            // self.zoomXYMode = self.ZOOM_X;
            // console.log("INSIDE", self.checkInside(pos));
            this.resetToInside(pos);
            // console.log("=>", self.checkInside(pos));

            if (pos[0] > this.selectBoxPos[0]) {
              rx = this.selectBoxPos[0];
              w = pos[0] - this.selectBoxPos[0];
            } else {
              rx = pos[0];
              w = this.selectBoxPos[0] - pos[0];
            }

            if (pos[1] > this.selectBoxPos[1]) {
              ry = this.selectBoxPos[1];
              h = pos[1] - this.selectBoxPos[1];
            } else {
              ry = pos[1];
              h = this.selectBoxPos[1] - pos[1];
            }

            if (this.axisMode === AXIS.FIXX) {
              rx = 0;
              w = this.x(this.x.domain()[1]);
            }

            if (this.axisMode === AXIS.FIXY) {
              ry = 0;
              h = this.y(this.y.domain()[1]);
            }

            // console.log("+++ rerender +++");
            // self.storedCanvas.stale = true;

            this.selectBoxPos[2] = pos[0];
            this.selectBoxPos[3] = pos[1];

            this.selectBox
              .select("#selectBox")
              .attr("x", rx + this.padding.left)
              .attr("y", ry + this.padding.top)
              .attr("width", w)
              .attr("height", h);

            this.xTranslate = d3.event.transform.x;
            this.yTranslate = d3.event.transform.y;
          }
        } else {
          // console.log("MOUSE Translate", this.resetTranslate, d3.event.transform.x, d3.event.transform.y);
          // Translate graph
          // var x, y;
          // deactivate cross
          this.crosshair.style("display", "none");
          if (this.resetTranslate) {
            this.xTranslate = d3.event.transform.x;
            this.yTranslate = d3.event.transform.y;
          }
          this.resetTranslate = false;
          const y = d3.event.transform.y - this.yTranslate;
          const x = d3.event.transform.x - this.xTranslate;
          this.xTranslate = d3.event.transform.x;
          this.yTranslate = d3.event.transform.y;

          // console.log("MOUSE DRAG", d3.event.transform.x, d3.event.transform.y);
          // console.log("MOUSE move", x, y);
          this.translateGraph(x, y);
        }
      } else {
        // console.log("MOUSE zoom", d3.event.transform.k, this.scale, "->", d3.event.transform.k / this.scale);
        // console.log("MOUSE zoom", d3.event);
        if (this.scale === undefined) this.scale = d3.event.transform.k;
        const k = d3.event.transform.k / (this.scale as number);
        this.crosshair.style("display", "none");

        this.scale = d3.event.transform.k;

        this.xTranslate = d3.event.transform.x;
        this.yTranslate = d3.event.transform.y;
        // var pos = d3.mouse(this);
        const pos = d3.mouse(this.graph.node());

        // console.log("MOUSE zoom", k);
        // console.log("+++ rerender +++");
        // self.storedCanvas.stale = true;

        this.zoomXY(
          this.x.invert(pos[0]),
          this.y.invert(pos[1]),
          this.axisMode === AXIS.FREE || this.axisMode === AXIS.FIXY ? k : 1,
          this.axisMode === AXIS.FREE || this.axisMode === AXIS.FIXX ? k : 1
        );
      }
    });
  }

  initTrackXYData(): TrackXYData {
    return {
      type: "XY_real",
      id: "",
      data: { x: [], y: [] },
    };
  }

  initTrackMatrixData(): TrackMatrixData {
    return {
      type: "matrix_real",
      id: "",
      data: { x: [], y: [], matrix: [] },
    };
  }

  getTrackData(id: string) {
    return this.tracks?.[id]?.data;
  }

  setTrackData(data: TrackXYData | TrackMatrixData, force: boolean = true, redraw: boolean = true) {
    if (!(data.id in this.tracks)) return false;
    if (data.type === "XY_real" && this.tracks[data.id].type === "XY_real")
      return this.set1DTrackData(data, force, redraw);
    if (data.type === "matrix_real" && this.tracks[data.id].type === "matrix_real")
      return this.set2DTrackData(data, force, redraw);
    return false;
  }

  resort2DTrackData(data: TrackMatrixData): TrackMatrixData {
    const xTiles: DatatrackNumericArray[] = [];
    const yTiles: DatatrackNumericArray[] = [];
    let i = 0;
    // console.log("data size", data.data.x.length, data.data.y.length, data.data.matrix.length);
    for (let yTile of data.data.y) {
      // console.log("y", yTile.index);
      for (let xTile of data.data.x) {
        const index = data.data.matrix[i].index;
        const x = Object.assign({}, xTile);
        x.index = index;
        xTiles.push(x);
        const y = Object.assign({}, yTile);
        y.index = index;
        yTiles.push(y);
        // console.log("x", ...xTile.range, ...yTile.range, i, "->", data.data.matrix[i].index);
        i++;
      }
    }

    const result = Object.assign({}, data);
    const d = Object.assign({}, data.data);
    d.x = xTiles;
    d.y = yTiles;
    result.data = d;
    return result;
  }

  set2DTrackData(data: TrackMatrixData, force: boolean = true, redraw: boolean = true) {
    const trackData: TrackMatrixData = this.tracks[data.id].data as TrackMatrixData;
    const depth = trackData.data.matrix?.[0]?.depth ?? -1;
    const newDepth = data.data.matrix?.[0]?.depth ?? -1;

    // console.log("depth", data.id, depth, "->", newDepth);
    // data = this.resort2DTrackData(data);
    // console.log("set2DTrackData", data);
    if (force || newDepth !== depth) {
      // console.log(
      //   "depth",
      //   data.id,
      //   depth,
      //   "->",
      //   data.data.x.map((d) => d.depth)
      // );
      this.tracks[data.id].data = data;
      if (redraw) this.redraw();
      return true;
    }

    const mapping = Object.fromEntries(trackData?.data.matrix.map((tile) => [tile.id, tile]) ?? []);
    let update = false;

    data.data.matrix.forEach((tile, i) => {
      if (!(tile.id in mapping)) {
        update = true;
        trackData?.data.matrix.push(tile);
        // trackData?.data.x.push(data.data.x[i]);
        // trackData?.data.y.push(data.data.y[i]);
      }
    });

    if (update) {
      // trackData.data.x = trackData.data.x.sort((a, b) => a.index - b.index);
      // trackData.data.y = trackData.data.y.sort((a, b) => a.index - b.index);
      trackData.data.matrix = trackData.data.matrix.sort((a, b) => a.index - b.index);
      if (redraw) this.redraw();
    }
    return update;
  }

  set1DTrackData(data: TrackXYData, force: boolean = true, redraw: boolean = true) {
    const trackData: TrackXYData = this.tracks[data.id].data as TrackXYData;
    const depth = trackData.data.x?.[0]?.depth ?? -1;
    const newDepth = data.data.x?.[0]?.depth ?? -1;

    if (force || newDepth !== depth) {
      // console.log(
      //   "depth",
      //   data.id,
      //   depth,
      //   "->",
      //   data.data.x.map((d) => d.depth)
      // );
      this.tracks[data.id].data = data;
      if (this.tracks[data.id]?.trackdata1D) (this.tracks[data.id].trackdata1D as any).id = "";
      if (redraw) this.redraw();
      return true;
    }

    const mapping = Object.fromEntries(trackData?.data.x.map((tile) => [tile.id, tile]) ?? []);
    let update = false;
    data.data.x.forEach((tile, i) => {
      if (!(tile.id in mapping)) {
        update = true;
        trackData?.data.x.push(tile);
        trackData?.data.y.push(data.data.y[i]);
      }
    });

    if (update) {
      trackData.data.x = trackData.data.x.sort((a, b) => a.index - b.index);
      trackData.data.y = trackData.data.y.sort((a, b) => a.index - b.index);
      if (redraw) this.redraw();
    }
    return update;

    // const x = this.mergeTiles(trackData?.data.x ?? [], data.data.x);
    // if (x) {
    //   const y = this.mergeTiles(trackData?.data.y ?? [], data.data.y);
    //   this.tracks[data.id].data = {
    //     type: "XY_real",
    //     id: data.id,
    //     data: { x: x as DatatrackNumericArray[], y: (y ?? []) as DatatrackNumericArray[] },
    //   };
    // }
    // const checkNew =
    //   !trackData ||
    //   trackData?.data.x.length !== data.data.x.length ||
    //   trackData?.data.y.length !== data.data.y.length ||
    //   data.data.x.some(
    //     (_, i) => trackData?.data.x[i].id !== data.data.x[i].id || trackData?.data.y[i].id !== data.data.y[i].id
    //   );

    // if (checkNew) {
    //   // console.log("----------------");
    //   this.tracks[data.id].data = data;
    //   if (redraw) this.redraw();
    // }
  }

  initTrackProperties(track: Track): trackProperties {
    let dimension = 0;
    let draw: trackDrawType = {
      line: true,
      dots: false,
      heatmap: false,
      contour: true,
      filled: false,
    };
    let range: { min: number[]; max: number[] } | undefined = undefined;
    if (track.type === "XY_real") {
      dimension = 1;
      draw.line = track.settings?.draw?.line ?? true;
      draw.dots = track.settings?.draw?.dots ?? true;
      range = {
        min: (track.data as any)?.min,
        max: (track.data as any)?.max,
      };
    } else if (track.type === "matrix_real") {
      dimension = 2;
      draw.heatmap = track.settings?.draw?.heatmap ?? true;
      draw.contour = track.settings?.draw?.contour ?? true;
      draw.filled = track.settings?.draw?.filled ?? true;
    }

    return {
      id: track.id,
      dimension: dimension,
      resampled: false,
      // settings: trackSettingsFromTrackBaseSettings(undefined) as TrackSettings,
      settings: {
        visible: track.settings.visible && track.settings.active,
        hideAxis: track.settings.hideAxis,
        axisLabels: track.settings.axisLabels,
        axisUnits: track.settings.axisUnits,
        annotation: track.settings.annotation,
        draw: draw,
        color: track.settings.color ?? initColor(),
        zoom: track.settings.zoom,
        normalize: (track.settings as TrackXYSettings)?.normalize ?? { "0-1": false, "-1-1": false },
        contourGenerators: (track.settings as TrackMatrixSettings)?.contourGenerators ?? [],
      },
      name: track.name ?? "Track" + (1 + Object.keys(this.tracks).length),
      zIndex: Number.NaN,
      ratio: -1,
      type: track.type,
      range: range ?? {},
      data: track.type === "XY_real" ? this.initTrackXYData() : this.initTrackMatrixData(),
    };
  }

  clearTracks(redraw: boolean = true) {
    this.tracks = {};
    if (redraw) this.redraw();
  }

  setTrack(track: Track, redraw: boolean = true): boolean {
    const newProp = this.initTrackProperties(track);

    const prop = this.tracks?.[track.id] ?? newProp;
    let updatedParameter: Partial<trackProperties> | undefined;
    if (this.tracks?.[track.id] === undefined) updatedParameter = newProp;
    else {
      updatedParameter = difference(prop, newProp);
      delete updatedParameter?.data;
    }
    // console.log("updatedParameter", track.settings);
    // console.log("prop", prop);
    // console.log("settings", settings);
    if (updatedParameter === undefined || Object.keys(updatedParameter).length < 1) return false;
    // console.log("updatedParameter", updatedParameter);

    if (isNaN(prop.zIndex)) prop.zIndex = track.index;

    // console.log("prop", track.idArray, "->", track);
    // console.log("prop", track.id, "->", prop.colors);

    // console.log("prop", prop.settings.color, track.settings.color);
    // console.log("prop", updatedParameter);
    // if (updatedParameter?.settings?.normalize !== undefined) prop.ratio = -1;
    // if (updatedParameter?.color !== unedfined) delete updatedParameter.color; // color updated are threated seperately
    // if (updatedParameter !== undefined) style = this.update(style, updatedParameter).value;
    // else updatedParameter = {};
    // if (updatedParameter?.contours?.state !== undefined) style.contours.state = track.parameter.contours.state; // overwrite state completely with input state
    // style.visible = style.visible && style.active;
    // let thresholds;

    // console.log("prop", updatedParameter.zoom);
    if (track.type === "XY_real") {
      // prop.dimension = 1;
      // if (track.type === PLOT.1D_REAL) {
      //   t.dimension = 1;
      // let minx = Infinity;
      // let maxx = -Infinity;
      // let miny = Infinity;
      // let maxy = -Infinity;
      //   if (track.data !== undefined && (track?.data?.x !== t?.data?.x || track?.data?.y !== t?.data?.y)) {
      //     t.downSample = { x: [], y: [], length: 0 };
      //     t.ratio = 1;
      //     // v--- Create dataset object ---
      //     const length = Math.min(track.data.x.length, track.data.y.length);
      //     const data = { x: track.data.x, y: track.data.y, length: length };
      //     if (data.x.length > length) data.x = data.x.slice(0, length);
      //     if (data.y.length > length) data.y = data.y.slice(0, length);
      //     // ^--- Create dataset object ---
      //     t.data = data;
      //     data.x.forEach((d:number) => {
      //       if (minx > d) minx = d;
      //       if (maxx < d) maxx = d;
      //     });
      //     data.y.forEach((d:number) => {
      //       if (miny > d) miny = d;
      //       if (maxy < d) maxy = d;
      //     });
      //     if (Math.abs(maxx - minx) < 2 * Number.EPSILON) {
      //       minx = minx - 1;
      //       maxx = minx + 1;
      //     }
      //     if (Math.abs(maxy - miny) < 2 * Number.EPSILON) {
      //       miny = miny - 1;
      //       maxy = miny + 1;
      //     }
      //     const y = (maxy - miny) * this.rangeScale;
      //     miny -= y;
      //     maxy += y;
      //     const x = (maxx - minx) * this.rangeScale;
      //     minx -= x;
      //     maxx += x;
      //     data.rangeX = [minx, maxx];
      //     data.rangeY = [miny, maxy];
      //     t.data = data;
      //   } else {
      //     if (t.domain) [minx, maxx, miny, maxy] = t.domain;
      //   }
      //   if (track.parameter.axisMin) {
      //     if (track.parameter.axisMin[0] !== undefined) minx = track.parameter.axisMin[0];
      //     if (track.parameter.axisMin[1] !== undefined) miny = track.parameter.axisMin[1];
      //   }
      //   if (track.parameter.axisMax) {
      //     if (track.parameter.axisMax[0] !== undefined) maxx = track.parameter.axisMax[0];
      //     if (track.parameter.axisMax[1] !== undefined) maxy = track.parameter.axisMax[1];
      //   }
      //   t.domain = [minx, maxx, miny, maxy];
      //   t.zIndex = track.index;
      //   newColor.range = [miny, maxy];
    } else if (track.type === "matrix_real") {
      // prop.dimension = 2;
      // } else if (track.type === PLOT.2D_MATRIX) {
      //   t.dimension = 2;
      //   let minx = -1;
      //   let maxx = 1;
      //   let miny = -1;
      //   let maxy = 1;
      //   // thresholds = [];
      //   let minz = -1;
      //   let maxz = 1;
      //   if (track.data !== undefined && track?.data?.matrix !== t?.data?.matrix) {
      //     t.data = {};
      //     minz = Infinity;
      //     maxz = -Infinity;
      //     track.data.matrix.forEach((d:number) => {
      //       if (minz > d) minz = d;
      //       if (maxz < d) maxz = d;
      //     });
      //     newColor.range = [minz, maxz];
      //     t.data.matrix = track.data.matrix;
      //     const width = track.data.width,
      //       height = track.data.height;
      //     minx = 0;
      //     maxx = width;
      //     miny = 0;
      //     maxy = height;
      //     const domX = d3
      //       .scaleLinear()
      //       .domain([minx, maxx])
      //       .range([0, width - 1]);
      //     const domY = d3
      //       .scaleLinear()
      //       .domain([miny, maxy])
      //       .range([0, height - 1]);
      //     const x = [];
      //     for (let ix = 0; ix < width; ix++) {
      //       x.push(domX.invert(ix));
      //     }
      //     const y = [];
      //     for (let iy = 0; iy < height; iy++) {
      //       y.push(domY.invert(iy));
      //     }
      //     t.data.height = height;
      //     t.data.width = width;
      //     t.data.x = x;
      //     t.data.y = y;
      //     t.ratio = {
      //       // thresholds: thresholds,
      //       xScale: Infinity,
      //       yScale: Infinity,
      //     };
      //     t.zIndex = Object.keys(this.tracks).length;
      //     t.downSample = { contours: undefined, heatmap: undefined };
      //   } else {
      //     if (t.domain) [minx, maxx, miny, maxy, minz, maxz] = t.domain;
      //   }
      //   if (track.parameter.axisMin) {
      //     if (track.parameter.axisMin[0] !== undefined) minx = track.parameter.axisMin[0];
      //     if (track.parameter.axisMin[1] !== undefined) miny = track.parameter.axisMin[1];
      //   }
      //   if (track.parameter.axisMax) {
      //     if (track.parameter.axisMax[0] !== undefined) maxx = track.parameter.axisMax[0];
      //     if (track.parameter.axisMax[1] !== undefined) maxy = track.parameter.axisMax[1];
      //   }
      //   t.domain = [minx, maxx, miny, maxy, minz, maxz];
      //   if (t.data) {
      //     t.data.xDomain = [minx, maxx];
      //     t.data.yDomain = [miny, maxy];
      //     t.data.rangeX = [minx, maxx];
      //     t.data.rangeY = [miny, maxy];
      //     t.data.rangeZ = [minz, maxz];
      //   }
      // }
      // const [, , , , minz, maxz] = t.domain;
      // if (minz !== undefined && maxz !== undefined) {
      //   if (!style.contours?.generate) {
      //     style.contours = {
      //       state: {},
      //       generate: (state, min, max) => {
      //         const step = (max - min) / 11;
      //         return d3.range(min + step, max + step, step);
      //       },
      //     };
      //   }
      //   if (typeof t.ratio === "object") {
      //     thresholds = style.contours.generate(style.contours.state, minz, maxz);
      //     const step = (maxz - minz) / 1000000;
      //     if (
      //       t.ratio?.thresholds === undefined ||
      //       t.ratio.thresholds.length !== thresholds.length ||
      //       t.ratio.thresholds.some((t, i) => Math.abs(t - thresholds[i]) > step)
      //     ) {
      //       t.ratio.thresholds = thresholds;
      //       t.data.contours = undefined;
      //     }
      //   }
      // }
      // if (
      //   newColor.reset ||
      //   style?.color?.range?.[0] !== newColor.range[0] ||
      //   style?.color?.range?.[1] !== newColor.range[1]
      // ) {
      //   delete newColor.reset;
      //   const step = 1 / (newColor.offsets.length - 1);
      //   const diff = newColor.range[1] - newColor.range[0];
      //   newColor.offsets = newColor.offsets.map((scale, i) => {
      //     if (newColor?.values?.[i] === undefined) {
      //       if (scale === undefined) return i * step;
      //       else return scale;
      //     } else {
      //       if (diff === 0) return 0;
      //       if (newColor.values[i] < newColor.range[0]) return 0;
      //       if (newColor.values[i] > newColor.range[1]) return 1;
      //       return (newColor.values[i] - newColor.range[0]) / diff;
      //     }
      //   });
      //   delete newColor.values;
      //   newColor.values = newColor.offsets.map((scale) =>
      //     newColor.range[0] === undefined ? undefined : newColor.range[0] + diff * scale
      //   );
      //   const minRange = newColor.values[0];
      //   const maxRange = newColor.values[newColor.values.length - 1];
      //   const g = d3.scaleLinear().domain(newColor.values).range(newColor.colors).interpolate(d3.interpolateRgb);
      //   newColor.generate = (v) => (v < minRange ? g(minRange) : v > maxRange ? g(maxRange) : g(v));
      //   style.color = newColor;
    }
    // if (track.parameter.label) {
    //   t.label = track.parameter.label;
    // } else {
    //   t.label = "Track" + (1 + this.tracks.length);
    // }
    // t.drawMode = this.DRAW_LINE;
    // const axisLabel = {};
    // axisLabel.xLabel = track.parameter.xLabel === undefined ? this.parameter.xLabel : track.parameter.xLabel;
    // axisLabel.yLabel = track.parameter.yLabel === undefined ? this.parameter.yLabel : track.parameter.yLabel;
    // axisLabel.xUnit = track.parameter.xUnit === undefined ? this.parameter.xUnit : track.parameter.xUnit;
    // axisLabel.yUnit = track.parameter.yUnit === undefined ? this.parameter.yUnit : track.parameter.yUnit;
    // t.axisLabel = axisLabel;
    // t.style = style;
    // this.storedCanvas.stale = true;

    prop.settings = newProp.settings;
    this.tracks[track.id] = prop;
    // this.trackOrder = Object.entries(this.tracks)
    //   .sort((a, b) => a[1].zIndex - b[1].zIndex)
    //   .map(([id]) => id);
    this.trackOrder = Object.values(this.tracks)
      .sort((a, b) => a.zIndex - b.zIndex)
      .map((track) => track.id);

    this.updateAnnotationShapes = true;

    if (redraw) this.redraw();
    return true;
  } // addPlot

  getTicsModeFromVisibleTracks(tracks?: string[]) {
    if (tracks === undefined) tracks = this.trackOrder;

    const axis = { x: false, y: false, z: false };
    const axisKeys: (keyof typeof axis)[] = ["x", "y", "z"];
    for (let id of tracks) {
      const track = this.tracks[id];

      if (!track.settings.visible) continue;
      axisKeys.forEach((k) => {
        axis[k] = axis[k] || !track.settings.hideAxis[k];
      });

      // console.log("axis", this.tracks[id].style.hideAxis.x);
    }
    let ticsXYMode = 0;
    if (axis.x) ticsXYMode += 1;
    if (axis.y) ticsXYMode += 2;
    return ticsXYMode;
  }

  cCircle(
    data: drawType,
    style: drawStyle = { radius: 1, fillStyle: "#cccccc" },
    translate: [number, number] = [0, 0]
  ) {
    const arc = 2 * Math.PI;

    Object.keys(style).forEach((id) => {
      this.cPlot[id] = style[id as keyof drawStyle];
    });

    data.x.forEach((d, i) => {
      this.cPlot.beginPath();

      this.cPlot.arc(this.x(data.x[i] + translate[0]), this.y(data.y[i] + translate[1]), style.radius, 0, arc);
      // this.cPlot.lineTo(this.x(data.x[i] + translate[0]), this.y(data.y[i] + translate[1]));
      this.cPlot.fill();
    });
  }

  cDraw(
    data: drawType,
    style: drawStyle = { lineWidth: 1, strokeStyle: "#cccccc" },
    translate: [number, number] = [0, 0]
  ) {
    if (data.length < 1) return;
    this.cPlot.beginPath();

    let previousX = NaN;

    // this.cPlot.moveTo(this.x(data.x[0] + translate[0]), this.y(data.y[0] + translate[1]));

    data.x.forEach((d, i) => {
      if (isNaN(previousX)) this.cPlot.moveTo(this.x(data.x[i] + translate[0]), this.y(data.y[i] + translate[1]));
      else this.cPlot.lineTo(this.x(data.x[i] + translate[0]), this.y(data.y[i] + translate[1]));
      previousX = data.x[i];
    });

    // this.cPlot.strokeRect(20, 20, 150, 100);

    Object.keys(style).forEach((id) => {
      this.cPlot[id] = style[id as keyof drawStyle];
    });

    this.cPlot.stroke();
  }

  cText(
    data: textType,
    style: drawStyle = { lineWidth: 1, strokeStyle: "#cccccc" },
    translate: [number, number] = [0, 0]
  ) {
    if (data.text === "") return;
    // if (data.length < 1) return;
    // this.cPlot.beginPath();

    console.log("text", data);
    this.cPlot.fillStyle = "red";

    //There are several options for setting text
    this.cPlot.font = "30px Open Sans";
    this.cPlot.fillText(data.text, this.x(data.x + translate[0]), this.y(data.y + translate[1]));

    // this.cPlot.moveTo(this.x(data.x[0] + translate[0]), this.y(data.y[0] + translate[1]));

    // data.x.forEach((d, i) => {
    //   // console.log("d", this.x(data.x[i]), this.y(data.y[i]))
    //   // console.log("d", this.colorScales[names[i]].colors[0])
    //   this.cPlot.lineTo(this.x(data.x[i] + translate[0]), this.y(data.y[i] + translate[1]));
    // });

    // // this.cPlot.strokeRect(20, 20, 150, 100);

    // Object.keys(style).forEach((id) => {
    //   this.cPlot[id] = style[id as keyof drawStyle];
    // });

    // this.cPlot.stroke();
  }

  xAxisDrag() {
    // console.log("xAxisDrag", d);

    document.onselectstart = () => {
      return false;
    };
    const p = d3.mouse(this.graph.node());
    console.log("xAxisDrag", p[0]);
    this.downX = this.x.invert(p[0]) - this.x.domain()[0];
    d3.event.preventDefault();
    d3.event.stopPropagation();
  }

  yAxisDrag() {
    // console.log("yAxisDrag", d);
    document.onselectstart = () => {
      return false;
    };
    const p = d3.mouse(this.graph.node());
    console.log("yAxisDrag", p[1]);
    this.downy = this.y.invert(p[1]) - this.y.domain()[1];
    d3.event.preventDefault();
    d3.event.stopPropagation();
  }

  roundFormatter(step: number, max: number, maxLength: number = 6) {
    if (!isFinite(step)) return d3.format("0.3f");

    let fy = d3.format("." + d3.precisionRound(step, max) + "r");
    if (fy(max).toString().length > maxLength) fy = d3.format(".3e");
    return fy;
  }

  ticsFormatter(tics: number[]) {
    const step = Math.min(...tics.map((v, i) => (i < 1 ? Infinity : Math.abs(v - tics[i - 1]))));
    const max = Math.max(...tics.map((v) => Math.abs(v)));
    return this.roundFormatter(step, max);
  }

  drawAxis(SVGonly: boolean = false) {
    // console.time("draw axis");

    const tx = (d: number) => {
      return "translate(" + this.x(d) + ",0)";
    };

    const ty = (d: number) => {
      return "translate(0," + this.y(d) + ")";
    };

    const stroke = (d: number) => {
      return d ? "#cccccc" : "#666666";
    };

    // const fx = this.x.tickFormat(5, d3.format(".3n") as any);
    // const fy = this.y.tickFormat(5, d3.format(".3n") as any);

    const xTics = this.x.ticks(5);
    const yTics = this.y.ticks(5);

    const fx = this.ticsFormatter(xTics);
    const fy = this.ticsFormatter(yTics);

    this.xFormatter = this.roundFormatter(
      Math.abs(this.domainX[0] - this.domainX[1]) / this.size.width,
      Math.max(this.domainX[0], this.domainX[1])
    );
    this.yFormatter = this.roundFormatter(
      Math.abs(this.domainY[0] - this.domainY[1]) / this.size.height,
      Math.max(this.domainY[0], this.domainY[1]),
      8
    );

    // Adapt x-ticks
    if (this.ticsXYMode === TICS.XY || this.ticsXYMode === TICS.X) {
      const gx = this.graph.selectAll("#xTic").data(xTics, String);

      // this.cText({ text: "Hallo", x: 0, y: 0 });
      gx.enter()
        .append("text")
        .merge(gx)
        .attr("transform", tx)
        .attr("id", "xTic")
        .attr("class", [styles.axislabel, styles.noselect].join(" "))
        .attr("y", this.size.height)
        .attr("dy", "1em")
        .attr("text-anchor", "middle")
        .text(fx)
        .style("cursor", "ew-resize");

      if (this.interactive)
        gx.on("mouseover", function (this: any) {
          d3.select(this as any).style("font-weight", "bold");
        })
          .on("mouseout", function (this: any) {
            d3.select(this).style("font-weight", "normal");
          })
          .on("mousedown.drag", () => this.xAxisDrag())
          .on("touchstart.drag", () => this.xAxisDrag())
          .on("mouseup.drag", () => this.canvas_mouseup());
      else
        gx.on("mousedown.drag", () => {})
          .on("touchstart.drag", () => {})
          .on("mouseup.drag", () => {});

      gx.exit().remove();
    } else {
      this.graph.selectAll("#xTic").remove();
    }

    // Adapt y-ticks
    if (this.ticsXYMode === TICS.XY || this.ticsXYMode === TICS.Y) {
      const gy = this.graph.selectAll("#yTic").data(yTics, String);
      gy.enter()
        .append("text")
        .merge(gy)
        .attr("transform", ty)
        .attr("id", "yTic")
        .attr("class", [styles.axislabel, styles.noselect].join(" "))
        .attr("x", -3)
        .attr("dy", ".35em")
        .attr("text-anchor", "end")
        .text(fy)
        .style("cursor", "ns-resize");

      if (this.interactive)
        gy.on("mouseover", function (this: any) {
          d3.select(this).style("font-weight", "bold");
        })
          .on("mouseout", function (this: any) {
            d3.select(this).style("font-weight", "normal");
          })
          .on("mousedown.drag", () => this.yAxisDrag())
          .on("touchstart.drag", () => this.yAxisDrag())
          .on("mouseup.drag", () => this.canvas_mouseup());
      else
        gy.on("mousedown.drag", () => {})
          .on("touchstart.drag", () => {})
          .on("mouseup.drag", () => {});

      gy.exit().remove();
    } else {
      this.graph.selectAll("#yTic").remove();
    }

    // grid lines on SVG
    if (SVGonly) {
      if (this.ticsXYMode === TICS.XY || this.ticsXYMode === TICS.X) {
        const xLine = this.graph.selectAll("#xTicLine").data(xTics);

        xLine
          .enter()
          .append("line")
          .merge(xLine)
          .attr("id", "xTicLine")
          .attr("x1", this.x)
          .attr("y1", 0)
          .attr("x2", this.x)
          .attr("y2", this.size.height)
          .attr("stroke", (d: number) => stroke(d));

        xLine.exit().remove();
      }

      if (this.ticsXYMode === TICS.XY || this.ticsXYMode === TICS.Y) {
        const yLine = this.graph.selectAll("#yTicLine").data(yTics);

        yLine
          .enter()
          .append("line")
          .merge(yLine)
          .attr("id", "yTicLine")
          .attr("x1", 0)
          .attr("y1", this.y)
          .attr("x2", this.size.width)
          .attr("y2", this.y)
          .attr("stroke", (d: number) => stroke(d));

        yLine.exit().remove();
      }
    } else {
      if (this.ticsXYMode === TICS.XY || this.ticsXYMode === TICS.X) {
        xTics.forEach((d: number) => {
          this.cDraw(
            {
              x: [d, d],
              y: [this.y.domain()[0], this.y.domain()[1]],
              length: 2,
            },
            {
              lineWidth: 1.0,
              strokeStyle: stroke(d),
            }
          );
        });
      }

      if (this.ticsXYMode === TICS.XY || this.ticsXYMode === TICS.Y) {
        yTics.forEach((d: number) =>
          this.cDraw(
            {
              x: [this.x.domain()[0], this.x.domain()[1]],
              y: [d, d],
              length: 2,
            },
            {
              lineWidth: 0.5,
              strokeStyle: stroke(d),
            }
          )
        );
      }
    }

    if (this.viewMode === VIEW.OFFSET) {
      this.offsetAxisGroup.style("display", null);
      this.updateOffsetAxis();
    } else {
      this.offsetAxisGroup.style("display", "none");
    }

    // console.timeEnd("draw axis");
  }

  createAnnotationShapesFromList() {
    this.annotationShapes = Object.values(this.annotationList)
      // .filter((a) => a.type === "xPeak")
      .map((annotation) => translateAnnotationTypeToShapes(annotation, this.tracks, this.size.height, this.size.width))
      .flat();
  }

  drawAnnotationShapes() {
    if (this.annotationShapes === undefined) return;

    const annotationShapes = this.annotationShapes.filter((d) =>
      d.tracks.some(
        (id) =>
          this.tracks[id].settings.visible &&
          (d.type === "text" ? this.tracks[id].settings.annotation.labels : this.tracks[id].settings.annotation.tics)
      )
    );

    // console.log("annotationShapes", annotationShapes);
    for (let i in annotationShapes) {
      if (!annotationShapes?.[i]?.func) continue;
      const track = this.tracks?.[annotationShapes[i].tracks[0]];
      if (!track || !track.trackdata1D) continue;
      (annotationShapes[i].func as shapeFunction)(annotationShapes[i], track.trackdata1D);
      // console.log("func", annotationShapes[i].type, annotationShapes[i]);
    }

    // const trackGroups = {};
    // TODO: implement function dependent shapes
    // for (let i in annotationShapes) {
    //   if (annotationShapes[i].func instanceof Function) {
    //     const trackId = annotationShapes[i].tracks[0];
    //     if (!(trackId in trackGroups)) trackGroups[trackId] = [];
    //     trackGroups[trackId].push(i);
    //   }
    // }

    // TODO: implement function dependent shapes
    // Object.entries(trackGroups).forEach(([trackId, shapes]) => {
    //   const track = this.tracks[trackId];
    //   if (track.data !== undefined) {
    //     const data = this.getSortedData(track.data);
    //     shapes.forEach((i) => {
    //       const shape = annotationShapes[i];
    //       annotationShapes[i] = shape.func(shape, data);
    //     });
    //   }
    // });

    const annotationLines = annotationShapes.filter((d) => d.type === "line") as ShapeLine[];
    const annotationText = annotationShapes.filter((d) => d.type === "text") as ShapeText[];
    const annotationCircles = annotationShapes.filter((d) => d.type === "circle") as ShapeCircle[];
    const annotationRect = annotationShapes.filter((d) => d.type === "rect") as ShapeRect[];
    const annotationArea = annotationShapes.filter((d) => d.type === "xArea") as ShapeXArea[];

    const curveFunc = (data: PathType) => {
      const area = d3
        .area()
        .x((_, i) => this.x(data.x[i]))
        .y0(this.y(0))
        .y1((_, i) => this.y(data.y[i]));

      return area(data.x as any);
    };

    // annotationArea [0].func(annotationArea [0], )

    this.annotationShapes
      .filter((d) => d.type === "xArea")
      .forEach((d) => {
        this.annotation
          .selectAll("#" + d.id)
          .filter("path")
          .remove();
      });

    // this.annotation.append("g").attr("id", "areaGroup");
    this.annotation.selectAll("#areaGroup").remove();
    const s = this.annotation.append("g").attr("id", "areaGroup");
    annotationArea
      .filter((d) => d?.path?.x !== undefined)
      .forEach((d) => {
        //   this.annotation
        //     .selectAll("#" + d.id)
        //     .filter("path")
        //     .remove();

        // console.log("path", d.id);
        if (d?.path?.x)
          // this.annotation
          s.append("path")
            .attr("id", d.id)
            .attr("d", curveFunc(d.path))
            .attr("class", d.class)
            .attr("stroke", d.color)
            .attr("fill", d.color);
      });

    // -- draw annotations lines --
    // this.annotation.selectAll("line").data(annotationLines).enter().append("line");

    // const fy = (x: number) => 3;
    // if (fy(3) === undefined) {
    // }

    // gx.select("text").text(fx);

    // const gxe = gx.enter().append("g", "a").merge(gx).attr("transform", tx).attr("id", "xTic");

    const line = this.annotation.selectAll("line").data(annotationLines);

    line
      .enter()
      .append("line")
      .merge(line)
      .attr("id", (d: ShapeLine) => d.id)
      .attr("class", (d: ShapeLine) => d.class)
      .attr("stroke", (d: ShapeLine) => d.color)
      .attr("x1", (d: ShapeLine) => (d.x1noScale ? d.x1 : this.x(d.x1)))
      .attr("y1", (d: ShapeLine) => (d.y1noScale ? d.y1 : this.y(d.y1)))
      .attr("x2", (d: ShapeLine) => (d.x2noScale ? d.x2 : this.x(d.x2)))
      .attr("y2", (d: ShapeLine) => {
        let y = 0;

        if (d.y2noScale) {
          y = d.y2;
        } else if (d.y2rel) {
          y = (d.y1noScale ? d.y1 : this.y(d.y1)) + d.y2;
        } else {
          y = this.y(d.y2) as number;
        }
        return y;
      });
    line.exit().remove();

    const circle = this.annotation.selectAll("circle").data(annotationCircles);

    // // -- draw annotations circle --
    // this.annotation.selectAll("circle").data(annotationCircles).enter().append("circle");

    circle
      .enter()
      .append("circle")
      .merge(circle)
      .attr("id", (d: ShapeCircle) => d.id)
      .attr("r", (d: ShapeCircle) => d.r)
      .attr("cx", (d: ShapeCircle) => (d.xnoScale ? d.x : this.x(d.x)))
      .attr("cy", (d: ShapeCircle) => (d.ynoScale ? d.y : this.y(d.y)))
      .attr("class", (d: ShapeCircle) => d.class)
      .attr("fill", (d: ShapeCircle) => d.color);
    circle.exit().remove();

    // // -- draw annotations rect --
    annotationRect.forEach((d) => {
      const x1 = d.x1noScale ? d.x1 : this.x(d.x1);
      const y1 = d.y1noScale ? d.y1 : this.y(d.y1);
      const x2 = d.x2noScale ? d.x2 : this.x(d.x2);
      const y2 = d.y2noScale ? d.y2 : this.y(d.y2);

      if (x1 > x2) {
        const x = d.x1;
        d.x1 = d.x2;
        d.x2 = x;
      }

      if (y1 > y2) {
        const y = d.y1;
        d.y1 = d.y2;
        d.y2 = y;
      }
    });

    const rect = this.annotation.selectAll("rect").data(annotationRect);

    rect
      .enter()
      .append("rect")
      .merge(rect)
      .attr("id", (d: ShapeRect) => d.id)
      .attr("class", (d: ShapeRect) => d.class)
      .attr("opacity", 0.3)
      .attr("fill", (d: ShapeRect) => (d.border ? "none" : d.color))
      .attr("stroke", (d: ShapeRect) => d.color)
      .attr("x", (d: ShapeRect) => (d.x1noScale ? d.x1 : this.x(d.x1)))
      .attr("y", (d: ShapeRect) => (d.y1noScale ? d.y1 : this.y(d.y1)))
      .attr("width", (d: ShapeRect) => {
        const x1 = d.x1noScale ? d.x1 : this.x(d.x1);
        const x2 = d.x2noScale ? d.x2 : this.x(d.x2);
        return x2 - x1;
      })
      .attr("height", (d: ShapeRect) => {
        const y1 = d.y1noScale ? d.y1 : this.y(d.y1);
        const y2 = d.y2noScale ? d.y2 : this.y(d.y2);
        return y2 - y1;
      });
    rect.exit().remove();

    // this.annotation.selectAll("rect").data(annotationRect).exit().remove();

    // // -- draw annotations text --
    const text = this.annotation.selectAll("text").data(annotationText);

    text
      .enter()
      .append("text")
      .merge(text)
      .attr("id", (d: ShapeText) => d.id)
      .text((d: ShapeText) => d.text)
      .attr("dx", (d: ShapeText) => d.dx)
      .attr("dy", (d: ShapeText) => d.dy)
      .attr("class", (d: ShapeText) => d.class)
      .attr("fill", (d: ShapeText) => d.color)
      .attr("text-anchor", (d: ShapeText) => (d.anchor ? d.anchor : "start"))
      .attr(
        "transform",
        (d: ShapeText) =>
          "translate(" +
          (d.xnoScale ? d.x : this.x(d.x)) +
          ", " +
          (d.ynoScale ? d.y : this.y(d.y)) +
          ") rotate(" +
          (d.rotate ? d.rotate : 0) +
          ")"
      );
    text.exit().remove();
  }

  cDrawXYTrack(
    data: TrackXYData,
    style: {
      lineWidth?: number;
      // strokeStyle: this.getTrackColor(id),
      strokeStyle?: string;
    },
    translate: [number, number] = [0, 0]
  ) {
    if (style === undefined) style = {};
    if (translate === undefined) translate = [0, 0];

    if (data.data.x.length !== data.data.y.length) return;

    this.cPlot.beginPath();

    for (let tileIndex = 0; tileIndex < data.data.x.length; tileIndex++) {
      const x = data.data.x[tileIndex].data;
      const y = data.data.y[tileIndex].data;
      if (!x || !y || x.length !== y.length || x.length < 1) continue;
      if (tileIndex === 0) this.cPlot.moveTo(this.x(x[0] + translate[0]), this.y(y[0] + translate[1]));
      // if (data.length < 1) return;
      x.forEach((_, i) => {
        this.cPlot.lineTo(this.x(x[i] + translate[0]), this.y(y[i] + translate[1]));
      });
    }
    Object.keys(style).forEach((id) => {
      this.cPlot[id] = style[id as keyof typeof style];
    });
    this.cPlot.stroke();
  }

  getTrackColorNameFromTrack(track: trackProperties): string {
    if (!track?.settings?.color?.colors?.[0]) return "black";

    return track.settings.color.colors[0].color;
  }

  getTrackStrokeStyle(id: string): string {
    const track = this.tracks?.[id];
    if (!track) return "black";

    return track.settings.color.colors[0].color;
  }

  createTileMatrix(track: trackProperties, zDomain: [number, number], directionX: -1 | 1, directionY: -1 | 1) {
    const data = (track.data as TrackMatrixData).data;
    // console.log("create tile matrix...");

    if (data.matrix.length < 1)
      return {
        matrix: undefined,
        size: [0, 0] as [number, number],
        min: 0,
        max: 0,
      };

    const min: number[] = [Infinity, Infinity];
    const max: number[] = [-Infinity, -Infinity];
    const range: [number, number][] = [
      [Infinity, -Infinity],
      [Infinity, -Infinity],
    ];
    // type tilePropType = { x0: number; y0: number; width: number; height: number };
    // const tileProp: tilePropType[] = [];

    for (let i = 0; i < data.matrix.length; i++) {
      const matrixTile = data.matrix[i];
      min[0] = Math.min(min[0], matrixTile.min[0]);
      max[0] = Math.max(max[0], matrixTile.max[0]);
      min[1] = Math.min(min[1], matrixTile.min[1]);
      max[1] = Math.max(max[1], matrixTile.max[1]);
      range[0][0] = Math.min(range[0][0], matrixTile.range[0][0]);
      range[0][1] = Math.max(range[0][1], matrixTile.range[0][1]);
      range[1][0] = Math.min(range[1][0], matrixTile.range[1][0]);
      range[1][1] = Math.max(range[1][1], matrixTile.range[1][1]);
    }

    const oldSize = [range[0][1] - range[0][0] + 1, range[1][1] - range[1][0] + 1];

    const newSize: [number, number] = [oldSize[0] + 2, oldSize[1] + 2];
    const matrix = new Float32Array(newSize[0] * newSize[1]);

    const convert = (ix: number, iy: number) => ix + 1 + (iy + 1) * newSize[0];
    // const reverse = (ix: number, iy: number) => ix - 1 + (iy - 1) * oldSize[0];
    const getIndex = (ix: number, iy: number) => ix + iy * newSize[0];

    // fill matrix with tile data
    for (let i = 0; i < data.matrix.length; i++) {
      const matrixTile = data.matrix[i];
      // const generate = d3.scaleSequential().domain(zDomain).interpolator(d3.interpolatePuOr);

      // if (i == 3) continue;

      let index: number;
      let x0: number;
      let y0: number;
      const tileMatrix = matrixTile.data as Float32Array | Float64Array;
      for (let iy = 0; iy < matrixTile.size[1]; iy++) {
        for (let ix = 0; ix < matrixTile.size[0]; ix++) {
          x0 = matrixTile.range[0][0] - range[0][0];
          y0 = matrixTile.range[1][0] - range[1][0];

          index = Math.round(iy * matrixTile.size[0] + ix);

          // x0 = directionX < 0 ? range[0][1] - matrixTile.range[0][1] - 1 : matrixTile.range[0][0] - range[0][0];
          // y0 = directionY < 0 ? range[1][1] - matrixTile.range[1][1] - 1 : matrixTile.range[1][0] - range[1][0];
          // j = directionY < 0 ? matrixTile.size[1] - iy - 1 : iy;
          // index = Math.round(j * matrixTile.size[0] + ix);

          matrix[convert(x0 + ix, y0 + iy)] = tileMatrix[index];
        }
      }
    }

    const dimX = newSize[0] - 1;
    const dimY = newSize[1] - 1;
    // fill first and last line
    for (let ix = 0; ix < newSize[0]; ix++) {
      matrix[getIndex(ix, 0)] = matrix[getIndex(ix, 1)];
      matrix[getIndex(ix, dimY)] = matrix[getIndex(ix, dimY - 1)];
    }

    // fill first and last column
    for (let iy = 0; iy < newSize[1]; iy++) {
      matrix[getIndex(0, iy)] = matrix[getIndex(1, iy)];
      matrix[getIndex(dimX, iy)] = matrix[getIndex(dimX - 1, iy)];
    }

    matrix[getIndex(0, 0)] = matrix[getIndex(1, 1)]; // top left corner
    matrix[getIndex(dimX, 0)] = matrix[getIndex(dimX - 1, 1)]; // top right corner
    matrix[getIndex(0, dimY)] = matrix[getIndex(1, dimY - 1)]; // bottom left corner
    matrix[getIndex(dimX, dimY)] = matrix[getIndex(dimX - 1, dimY - 1)]; // bottom right corner

    const xg = new LinearGenerator(min[0], max[0], oldSize[0]);
    const yg = new LinearGenerator(min[1], max[1], oldSize[1]);

    // console.log("x minmax", min[0], max[0], "->", xg.reverse(-1), xg.reverse(dimX));
    // console.log("y minmax", min[1], max[1], "->", yg.reverse(-1), yg.reverse(dimY));

    // console.log("... tile matrix created");
    return {
      matrix: matrix,
      size: newSize,
      min: [xg.reverse(-1), yg.reverse(-1)],
      max: [xg.reverse(dimX), yg.reverse(dimY)],
    };
  }

  createContours(
    track: trackProperties,
    zDomain: [number, number],
    thresholds: number[],
    directionX: -1 | 1,
    directionY: -1 | 1,
    id = ""
  ): trackContourType {
    const tilesMatrix = this.createTileMatrix(track, zDomain, directionX, directionY);

    if (!tilesMatrix.matrix) return { id: "" };

    const contours = d3.contours().size(tilesMatrix.size);
    const polygons = contours.thresholds(thresholds)(tilesMatrix.matrix as any);

    const xg = new LinearGenerator(tilesMatrix.min[0], tilesMatrix.max[0], tilesMatrix.size[0]);
    const yg = new LinearGenerator(tilesMatrix.min[1], tilesMatrix.max[1], tilesMatrix.size[1]);

    const paths: contourPath[] = [];

    const dimX = tilesMatrix.size[0] - 1;
    const dimY = tilesMatrix.size[1] - 2;

    const checkDim = (v: Position): boolean => {
      return !(v[0] < 1 || v[0] > dimX || v[1] <= 1 || v[1] > dimY);
    };

    for (let i = 0; i < polygons.length; i++) {
      const data = polygons[i];
      for (let d of data.coordinates) {
        for (let e of d) {
          let contour: contourPath = { value: thresholds[i], path: [] };
          let valid: boolean = true;
          for (let v of e) {
            const check = checkDim(v);
            if (valid !== check) {
              // console.log("disrupt at", v, "<-", dimX, dimY);
              contour.path.push([Number.NaN, Number.NaN]);
              valid = check;
            }
            if (v[0] < 1.5) v[0] = 1.5;
            else if (v[0] > dimX - 0.5) v[0] = dimX - 0.5;

            if (v[1] < 1.5) v[1] = 1.5;
            else if (v[1] > dimY - 0.5) v[1] = dimY - 0.5;

            contour.path.push([xg.reverse(v[0] - 1), yg.reverse(v[1] - 1)]);
          }
          if (contour.path.length > 0) paths.push(contour);
        }
      }
      // break;
    }

    return { id: id, paths: paths };
  }

  crateHeatMapImage(
    track: trackProperties,
    zDomain: [number, number],
    directionX: -1 | 1,
    directionY: -1 | 1,
    id = ""
  ): trackHeatmapCanvasType {
    const data = (track.data as TrackMatrixData).data;
    // console.log("create heatmap image...");

    if (data.matrix.length < 1) return { canvasX: 0, canvasY: 0, min: [0, 0], max: [0, 0], id: "" };
    // const zDomain = track.settings.zoom.z as [number, number];

    // let directionX = this.domainX[1] - this.domainX[0] < 0 ? -1 : 1;
    // let directionY = this.domainY[1] - this.domainY[0] < 0 ? -1 : 1;
    // data.matrix = data.matrix.filter((t) => t.range[0][1] < 136);

    // let canvasX = 0;
    // let canvasY = 0;
    const min: number[] = [Infinity, Infinity];
    const max: number[] = [-Infinity, -Infinity];
    const range: [number, number][] = [
      [Infinity, -Infinity],
      [Infinity, -Infinity],
    ];
    // type tilePropType = { x0: number; y0: number; width: number; height: number };
    // const tileProp: tilePropType[] = [];

    for (let i = 0; i < data.matrix.length; i++) {
      const matrixTile = data.matrix[i];
      // console.log("matrix", matrixTile);
      min[0] = Math.min(min[0], matrixTile.min[0]);
      max[0] = Math.max(max[0], matrixTile.max[0]);
      min[1] = Math.min(min[1], matrixTile.min[1]);
      max[1] = Math.max(max[1], matrixTile.max[1]);
      range[0][0] = Math.min(range[0][0], matrixTile.range[0][0]);
      range[0][1] = Math.max(range[0][1], matrixTile.range[0][1]);
      range[1][0] = Math.min(range[1][0], matrixTile.range[1][0]);
      range[1][1] = Math.max(range[1][1], matrixTile.range[1][1]);
    }
    const canvasX = range[0][1] - range[0][0] + 1;
    const canvasY = range[1][1] - range[1][0] + 1;

    const canvas = d3.create("canvas").attr("width", canvasX).attr("height", canvasY) as any;
    if (!canvas) return { canvasX: 0, canvasY: 0, min: [0, 0], max: [0, 0], id: "" };

    const ctx = (canvas.node() as HTMLCanvasElement).getContext("2d");
    if (!ctx) return { canvasX: 0, canvasY: 0, min: [0, 0], max: [0, 0], id: "" };

    // ctx.fillStyle = "black";
    // ctx.fillRect(0, 0, canvasX, canvasY);
    const g = d3.scaleLinear().domain(zDomain).range([0, 1]) as (value: number) => number;
    const gc = track.settings.color.generate ?? ((_: number) => "black");
    const generate = (v: number) => gc(g(v));

    for (let i = 0; i < data.matrix.length; i++) {
      const matrixTile = data.matrix[i];
      // const generate = d3.scaleSequential().domain(zDomain).interpolator(d3.interpolatePuOr);

      // if (i == 3) continue;

      const matrix = matrixTile.data as Float32Array | Float64Array;
      const imageDataObject = ctx.getImageData(0, 0, matrixTile.size[0], matrixTile.size[1] + 1);
      const imageData = imageDataObject.data;
      // console.log("draw", matrixTile.index, this.colors[matrixTile.index]);
      // console.log("index", imageData.length, matrixTile.size[0], matrixTile.size[1]);
      // console.log("direction", directionX, directionY);
      const add = directionY < 0 ? 1 : 0;
      // 1, -1 -> +1
      // 1, 1 -> +0

      for (let iy = 0; iy < matrixTile.size[1]; iy++) {
        for (let ix = 0; ix < matrixTile.size[0]; ix++) {
          // const n = (matrixTile.size[0] * (iy + 1) + ix) * 4;
          const n = (matrixTile.size[0] * (iy + add) + ix) * 4;

          // const n = (matrixTile.size[0] * (iy + (directionX > 0 ? 0 : 1)) + ix) * 4;
          // const n = (matrixTile.size[0] * (iy + 1) + ix) * 4;

          // const index = Math.round(iy * matrixTile.size[0] + ix);
          // const index = Math.round(iy * matrixTile.size[0] + ix);
          const i = directionX < 0 ? matrixTile.size[0] - ix - 1 : ix;
          const j = directionY < 0 ? matrixTile.size[1] - iy - 1 : iy;

          const index = Math.round(j * matrixTile.size[0] + i);
          // const index = Math.round(j * matrixTile.size[0] + ix);

          // const index = Math.floor(iy * matrixTile.size[0] + ix);
          const color = d3.color(generate(matrix[index]) ?? "white") as d3.RGBColor;
          // console.log("index", ix, iy, j, "->", index, n);

          // const color = d3.color("red") as d3.RGBColor;
          // const color = d3.color(this.colors[matrixTile.index]) as d3.RGBColor;

          // const color = d3.color("green") as d3.RGBColor;
          // this.cPlot.fillStyle = generate(matrix[index]);
          // console.log("generate", imageData[n], imageData[n + 1], imageData[n + 2], imageData[n + 3]);
          // console.log("generate", iy, matrixTile.size[1] - iy - 1);
          imageData[n] = color.r; // r
          imageData[n + 1] = color.g; // g
          imageData[n + 2] = color.b; // b
          imageData[n + 3] = 255; // opacity
        }
      }

      // Index Test
      // for (let iy = 0; iy < matrixTile.size[1]; iy++) {
      //   for (let ix = 0; ix < matrixTile.size[0]; ix++) {
      //     const color = d3.color("red") as d3.RGBColor;
      //     const n = (matrixTile.size[0] * (iy + 1) + ix) * 4;
      //     console.log("index", ix, iy, "->", n);

      //     imageData[n] = (iy * 255) / matrixTile.size[1]; // r
      //     imageData[n + 1] = (ix * 255) / matrixTile.size[0]; // g
      //     imageData[n + 2] = 0; // b
      //     imageData[n + 3] = 255; // opacity
      //   }
      // }

      // console.log("xy0", matrixTile.index, x0, y0, matrixTile.range[0], matrixTile.range[0], matrixTile.range[1]);
      const x0 = directionX < 0 ? range[0][1] - matrixTile.range[0][1] - 1 : matrixTile.range[0][0] - range[0][0];
      const y0 = directionY < 0 ? range[1][1] - matrixTile.range[1][1] - 1 : matrixTile.range[1][0] - range[1][0];

      // const x0 = directionX < 0 ? range[0][1] - matrixTile.range[0][1] - 1 : matrixTile.range[0][0] - range[0][0];
      // const y0 = directionY < 0 ? range[1][1] - matrixTile.range[1][1] - 1 : matrixTile.range[1][0] - range[1][0];

      // console.log("matrixTile.size", x0, y0, matrixTile.size);

      // console.log(
      //   "xy0",
      //   matrixTile.index,
      //   directionX,
      //   range[0][1] - matrixTile.range[0][0] - (matrixTile.range[0][1] - matrixTile.range[0][0])
      // );
      ctx.putImageData(imageDataObject, x0, y0);
      // break;
    }

    // const image = new Image();
    // image.width = canvasX;
    // image.height = canvasY;
    // image.src = canvas.node().toDataURL();

    // console.log("... heatmap image created");

    return {
      // image: image,
      canvas: canvas.node() as HTMLCanvasElement,
      canvasX: canvasX,
      canvasY: canvasY,
      min: min,
      max: max,
      id: id,
    };
  }

  plotHeatMap(track: trackProperties, useSVG: boolean) {
    const data = (track.data as TrackMatrixData).data;

    if (data.matrix.length < 1) return;

    const directionX = this.domainX[1] - this.domainX[0] < 0 ? -1 : 1;
    const directionY = this.domainY[1] - this.domainY[0] < 0 ? -1 : 1;

    const idArray = [
      ...data.matrix.map((t) => t.id),
      "dx" + directionX,
      "dy" + directionY,
      "zMin" + track.settings.zoom.z[0],
      "zMax" + track.settings.zoom.z[1],
      "color" + track.settings.color.id,
    ];
    const imageId = idArrayToId(idArray);
    // console.log("imageId", imageId);

    if (track?.heatmap?.id !== imageId)
      track.heatmap = this.crateHeatMapImage(
        track,
        track.settings.zoom.z as [number, number],
        directionX,
        directionY,
        imageId
      );

    const canvas = track.heatmap;
    if (!canvas.canvas) return;

    const xg = new LinearGenerator(canvas.min[0], canvas.max[0], canvas.canvasX);
    const yg = new LinearGenerator(canvas.min[1], canvas.max[1], canvas.canvasY);

    let w = Math.round(this.x(xg.reverse(1)) - this.x(xg.reverse(0)));
    let h = Math.round(this.y(yg.reverse(1)) - this.y(yg.reverse(0)));
    if (w < 2) w = 0;
    if (h < 2) h = 0;

    const x1 = this.x(canvas.min[0]) - (directionX * w) / 2;
    const x2 = this.x(canvas.max[0]) + (directionX * w) / 2;
    const y1 = this.y(canvas.min[1]) - (directionY * h) / 2;
    const y2 = this.y(canvas.max[1]) + (directionY * h) / 2;
    const width = x2 - x1;
    const height = y1 - y2;

    this.cPlot.imageSmoothingEnabled = false;
    this.cPlot.drawImage(canvas.canvas, x1, y2, width, height);

    // const wi = 200;
    // const hi = 200;
    // this.cPlot.drawImage(canvas.canvas, 0, 0, wi, hi);
    // this.cPlot.beginPath();
    // this.cPlot.rect(0, 0, wi, hi);
    // this.cPlot.strokeStyle = "black";
    // this.cPlot.lineWidth = 2;
    // this.cPlot.stroke();
    this.cPlot.imageSmoothingEnabled = true;

    // this is the old drawing loop for low resolution matrices
    // const generate = d3.scaleSequential().domain(zDomain).interpolator(d3.interpolatePuOr);
    // const matrix = matrixTile.data as Float32Array | Float64Array;
    // if (w > 4 || h > 4) {
    //   for (let iy = 0; iy < matrixTile.size[1]; iy++) {
    //     const y = this.y(yg.reverse(iy));
    //     const y1 = y - h / 2;
    //     const y2 = y + h / 2;
    //     // if ((y1 < 0 && y2 < 0) || (y1 > canvasHeight && y2 > canvasHeight)) continue;
    //     const b = yg.reverse(iy);
    //     for (let ix = 0; ix < matrixTile.size[0]; ix++) {
    //       const x = this.x(xg.reverse(ix));
    //       const x1 = x - w / 2;
    //       const x2 = x + w / 2;
    //       // if ((x1 < 0 && x2 < 0) || (x1 > canvasWidth && x2 > canvasWidth)) continue;
    //       const index = Math.round(iy * matrixTile.size[0] + ix);
    //       // this.cPlot.fillStyle = generate(matrix[index]);
    //       this.cPlot.fillRect(x1, y1, w, h);
    //       // console.log(xg.reverse(ix), yg.reverse(iy), "=> index", ix, iy, index, "->", matrix[index]);
    //       // console.log("index", ix, iy, index, "->", matrix[index]);
    //       // break;
    //     }
    //     // break;
    //   }
    //   continue;
    // }
  }

  calculateContoursForTile(matrixTile: DatatrackNumericMatrix, zDomain: [number, number], thresholds: number[]) {
    const xg = new LinearGenerator(matrixTile.min[0], matrixTile.max[0], matrixTile.size[0]);
    const yg = new LinearGenerator(matrixTile.min[1], matrixTile.max[1], matrixTile.size[1]);

    // const contours = d3.contours().size(matrixTile.size);

    const matrix = matrixTile.data as Float32Array | Float64Array;
    const size: [number, number] = [matrixTile.size[0] + 2, matrixTile.size[1] + 2];
    const m = new Float32Array(size[0] * size[1]);

    let index: number;
    let k: number;
    for (let j = 0; j < matrixTile.size[1]; j++) {
      for (let i = 0; i < matrixTile.size[0]; i++) {
        index = i + j * matrixTile.size[0];
        k = i + 1 + (j + 1) * size[0];
        m[k] = matrix[index];
      }
    }

    // fill first and last line
    for (let i = 0; i < matrixTile.size[0]; i++) {
      m[i + 1] = matrix[i];
      m[i + 1 + (size[1] - 1) * size[0]] = matrix[i + (matrixTile.size[1] - 1) * matrixTile.size[0]];
    }

    // fill first and last column
    for (let i = 0; i < matrixTile.size[1]; i++) {
      index = i * matrixTile.size[0];
      k = (i + 1) * size[0];
      m[k] = matrix[index];
      m[k + size[0] - 1] = matrix[index + matrixTile.size[0] - 1];
    }

    m[0] = matrix[0]; // top left corner
    m[size[0] - 1] = matrix[matrixTile.size[0] - 1]; // top right corner
    m[(size[1] - 1) * size[0]] = matrix[(matrixTile.size[1] - 1) * matrixTile.size[0]]; // bottom left corner
    m[(size[1] - 1) * size[0] + size[0] - 1] =
      matrix[(matrixTile.size[1] - 1) * matrixTile.size[0] + matrixTile.size[0] - 1]; // bottom right corner

    // for (let i = 0; i < size[0]; i++) {
    //   let ii = i + 1;
    //   if (ii >= matrixTile.size[0]) ii = matrixTile.size[0] - 1;
    //   m[i] = matrix[ii];
    //   m[i + (size[1] - 1) * size[0]] = matrix[ii + (matrixTile.size[1] - 1) * matrixTile.size[0]];
    // }

    // for (let i = 0; i < size[1]; i++) {
    //   let ii = i + 1;
    //   if (ii >= matrixTile.size[1]) ii = matrixTile.size[1] - 1;
    //   ii *= matrixTile.size[0];
    //   k = i * size[0];
    //   m[k] = matrix[ii];
    //   m[k + size[0] - 1] = matrix[ii + matrixTile.size[0] - 1];
    // }

    const contours = d3.contours().size(size);
    const polygons = contours.thresholds(thresholds)(m as any);
    // console.log("tile", matrixTile.index, matrixTile.min[0], matrixTile.max[0]);

    // let minX = Infinity;
    // let maxX = -Infinity;

    // const poly: d3.ContourMultiPolygon[] = [];
    // for (let data of polygons) {
    //   const coordinates: Position[][][] = [];
    //   for (let d of data.coordinates) {
    //     const paths: Position[][] = [];
    //     for (let e of d) {
    //       const path: Position[][] = [];
    //       for (let e of d) {
    //       }
    //     }
    //     break;
    //   }
    //   const dat = { coordinates: coordinates } as d3.ContourMultiPolygon;
    // }

    const paths: contourPath[] = [];

    for (let i = 0; i < polygons.length; i++) {
      const data = polygons[i];
      for (let d of data.coordinates) {
        for (let e of d) {
          let contour: contourPath = { value: thresholds[i], path: [] };
          for (let v of e) {
            // v[0] = xg.reverse(v[0] - 0.5);
            // v[1] = yg.reverse(v[1] - 0.5);
            if (v[0] === 0 || v[0] >= size[0] || v[1] === 0 || v[1] >= size[1]) {
              if (contour.path.length > 0) {
                paths.push(contour);
                contour = { value: thresholds[i], path: [] };
              }
            } else contour.path.push([xg.reverse(v[0] - 0.5), yg.reverse(v[1] - 0.5)]);
          }
          if (contour.path.length > 0) paths.push(contour);
        }
      }
    }

    // polygons.forEach((data) => {
    //   data.coordinates.forEach((d) => {
    //     // console.log("  d", d.length);
    //     d.forEach((e) => {
    //       // console.log("     e", e.length);
    //       // self.cplot.moveTo(e[0][0], e[0][1]);
    //       const path: Position[] = [];
    //       e.forEach((v) => {
    //         // v[0] = domX.invert(v[0] - 0.5);
    //         // v[1] = domY.invert(v[1] - 0.5);

    //         v[0] = xg.reverse(v[0] - 0.5);
    //         v[1] = yg.reverse(v[1] - 0.5);

    //         minX = Math.min(minX, v[0]);
    //         maxX = Math.max(maxX, v[0]);
    //         path.push(v);
    //         // self.cplot.lineTo(v[0], v[1]);
    //         // console.log("   =>", v);
    //       });
    //       paths.push(path);
    //       // console.log("   =>", e[0], e[e.length - 1]);
    //       // console.log("   =>", e);
    //     });
    //   });
    // });
    // console.log("min max", minX, maxX);
    // console.log(
    //   "calculate contoures",
    //   contours[0]
    // );
    // track.data.contours = contours;
    // }

    return paths;
    // return polygons;
  }

  plotContour(path: Position[], style: drawStyle, SVGonly = false) {
    if (path === undefined) return;
    // console.log("data:", style.color);
    // console.log("plotContour", SVGonly, style);
    // const SVGonly = false;
    // SVGonly = true;

    // paths.forEach((e) => {
    // console.log("  d", d.length);
    // d.forEach((e) => {
    this.cPlot.beginPath();
    // this.cPlot.moveTo(this.x(path[0][0]), this.y(path[0][1]));
    if (style.mode === "fill") {
      this.cPlot.moveTo(this.x(path[0][0]), this.y(path[0][1]));
      for (let v of path) {
        this.cPlot.lineTo(this.x(v[0]), this.y(v[1]));
      }
      this.cPlot.fillStyle = style.color;
      this.cPlot.fill();
    } else {
      let draw = true;
      let restart = true;

      for (let v of path) {
        if (isNaN(v[0])) {
          draw = !draw;
          restart = true;
          continue;
        }
        if (!draw) continue;
        if (restart) {
          // this.cPlot.moveTo(this.x(0), this.y(0));
          this.cPlot.moveTo(this.x(v[0]), this.y(v[1]));
          restart = false;
        } else this.cPlot.lineTo(this.x(v[0]), this.y(v[1]));
      }
      this.cPlot.strokeStyle = style.color;
      this.cPlot.lineWidth = style.lineWidth;
      this.cPlot.stroke();
    }
    // if (style.mode === "fill") {
    //   this.cPlot.fillStyle = style.color;
    //   this.cPlot.fill();
    // } else {
    //   this.cPlot.strokeStyle = style.color;
    //   this.cPlot.lineWidth = style.lineWidth;
    //   this.cPlot.stroke();
    // }
  }

  plotContours(track: trackProperties, style: drawStyle, useSVG: boolean) {
    const zDomain = track.settings.zoom.z as [number, number];

    const thresholdCount = 5;
    const contourStep = (zDomain[1] - zDomain[0]) / thresholdCount;

    const settings = track.settings as any as TrackMatrixSettings;
    const thresholds: number[] = [];
    if (settings?.contourGenerators && settings.contourGenerators.length > 0) {
      const generators = settings.contourGenerators;

      for (let generator of generators) {
        // console.log("test", generator.generate(generator.state, zDomain[0], zDomain[1]));
        thresholds.push(...generator.generate(generator.state, zDomain[0], zDomain[1]));
      }
    } else {
      if (Math.abs(contourStep) < Number.EPSILON) thresholds.push(zDomain[0]);
      else for (let t = zDomain[0] + contourStep; t <= zDomain[1]; t += contourStep) thresholds.push(t);
    }

    const data = (track.data as TrackMatrixData).data;

    if (data.matrix.length < 1) return [];
    // if (!track.contours) track.contours = {};
    const directionX = this.domainX[1] - this.domainX[0] < 0 ? -1 : 1;
    const directionY = this.domainY[0] - this.domainY[1] < 0 ? -1 : 1;

    const idArray = [
      ...data.matrix.map((t) => t.id),
      "dx" + directionX,
      "dy" + directionY,
      "zMin" + track.settings.zoom.z[0],
      "zMax" + track.settings.zoom.z[1],
    ];
    const imageId = idArrayToId(idArray);

    if (track?.contours?.id !== imageId)
      track.contours = this.createContours(track, zDomain, thresholds, directionX, directionY, imageId);

    const contours = track.contours;
    if (!contours.paths) return;

    // const contours = this.createContours(track, zDomain, thresholds, directionX, directionY);
    // console.log("track", style.color, zDomain);

    // const generate = d3.scaleSequential().domain(zDomain).interpolator(d3.interpolatePuOr);
    const g = d3.scaleLinear().domain(zDomain).range([0, 1]) as (value: number) => number;
    const gc = track.settings.color.generate ?? ((offset: number) => "black");
    const generate = (v: number) => gc(g(v));

    const drawStyle = Object.assign({}, style);
    drawStyle.lineWidth = style?.lineWidth ?? 1.2;
    const color = drawStyle.color;
    // drawStyle.mode = "fill";

    // drawStyle.color = "green";
    // drawStyle.mode = "empty";
    // this.plotContour(contours.paths[0].path, drawStyle, useSVG);

    //     contours.paths.forEach((contour, i) => {
    //       if (i < 2)
    // {      drawStyle.color = color ?? generate(contour.value);
    //       this.plotContour(contour.path, drawStyle, useSVG);
    // }    });

    contours.paths.forEach((contour) => {
      // console.log(contour.value, "->", contour.cut);
      // drawStyle.mode = contour.open ? "fill" : "fill";
      // drawStyle.color = style?.color ?? generate(contour.value);
      drawStyle.color = color ?? generate(contour.value);
      // drawStyle.color = style?.color ?? gc(g(contour.value));
      this.plotContour(contour.path, drawStyle, useSVG);
    });

    // for (let i = 0; i < data.matrix.length; i++) {
    //   const matrixTile = data.matrix[i];
    //   // if (matrixTile.index !== 4) continue;

    //   // if (!(matrixTile.id in track.contours))
    //   //   track.contours[matrixTile.id] = this.calculateContoursForTile(matrixTile, zDomain, thresholds);

    //   // const contours = track.contours[matrixTile.id];
    //   const contours = this.calculateContoursForTile(matrixTile, zDomain, thresholds);

    //   const generate = d3.scaleSequential().domain(zDomain).interpolator(d3.interpolatePuOr);
    //   // console.log("contours", contours);

    //   contours.forEach((contour) => {
    //     let color = generate(contour.value);
    //     let lineWidth = 2;

    //     this.plotContour(
    //       contour.path,
    //       {
    //         color: color,
    //         lineWidth: lineWidth,
    //         mode: "",
    //       },
    //       useSVG
    //     );
    //   });
    // }
  }

  draw2DData(SVGonly: boolean = false) {
    // console.log("tracks", this.tracks);
    let hasAnyPlots = false;

    const orderedTracks = Object.values(this.tracks)
      .filter((track) => track.dimension === 2 && track.data !== undefined)
      .sort((a, b) => a.zIndex - b.zIndex);

    for (let track of orderedTracks) {
      // const id = track.id;
      if (!track.settings.visible) continue;

      hasAnyPlots = true;
      const settings = track.settings as any as TrackMatrixSettings;
      if (settings.draw["heatmap"] || settings.draw["filled"]) {
        if (settings.draw["heatmap"]) this.plotHeatMap(track, false);
        else this.plotContours(track, { mode: "fill" }, false);
      }
      if (settings.draw["contour"])
        this.plotContours(
          track,
          { color: settings.draw["heatmap"] || settings.draw["filled"] ? "black" : undefined },
          false
        );
      // this.plotContours(track, false);
    }

    return hasAnyPlots;
  }

  crateTrackdata1D(track: trackProperties, id = ""): trackdata1DType {
    const data = (track.data as TrackXYData).data;

    const min = [Math.min(...data.x.map((t) => t.min[0])), Math.min(...data.y.map((t) => t.min[0]))];
    const max = [Math.max(...data.x.map((t) => t.max[0])), Math.max(...data.y.map((t) => t.max[0]))];

    let typeX: "Float64Array" | "Float32Array" | "" = "";
    let typeY = "";
    let size = 0;
    for (let tileIndex = 0; tileIndex < data.x.length; tileIndex++) {
      const x = data.x[tileIndex].data;
      const y = data.y[tileIndex].data;
      if (!x || !y || x.length !== y.length || x.length < 1) size++;
      else {
        typeX = x.constructor.name as "Float64Array" | "Float32Array";
        typeY = y.constructor.name as "Float64Array" | "Float32Array";
        size += x.length;
      }
    }
    // console.log("size", size, typeX, typeY);
    let newX: Float32Array | Float64Array;
    let newY: Float32Array | Float64Array;

    if (typeX === "Float32Array") newX = new Float32Array(size);
    else if (typeX === "Float64Array") newX = new Float64Array(size);
    else newX = new Float32Array(0);

    if (typeY === "Float32Array") newY = new Float32Array(size);
    else if (typeY === "Float64Array") newY = new Float64Array(size);
    else newY = new Float32Array(0);

    let i = 0;
    for (let tileIndex = 0; tileIndex < data.x.length; tileIndex++) {
      const x = data.x[tileIndex].data;
      const y = data.y[tileIndex].data;
      if (!x || !y || x.length !== y.length || x.length < 1) {
        newX[i] = NaN;
        newY[i] = NaN;
        i++;
      } else {
        newX.set(x, i);
        newY.set(y, i);
        i += x.length;
      }
    }

    return { x: newX, y: newY, size: size, id: id, min: min, max: max };
  }

  normalize1DData(data: trackdata1DType, min: number[], max: number[], mode: "0-1" | "-1-1") {
    if (mode === "0-1") {
      let diff = max[1] - min[1];
      if (diff === 0) diff = 1;

      for (let i = 0; i < data.size; i++) {
        data.y[i] = (data.y[i] - min[1]) / diff;
      }
    } else if (mode === "-1-1") {
      for (let i = 0; i < data.size; i++) {
        data.y[i] = data.y[i] / max[1];
      }
    }
  }

  draw1DData(SVGonly: boolean = false) {
    // let hasAnyPlot = false;

    for (let index = this.trackOrder.length - 1; index >= 0; index--) {
      const id = this.trackOrder[index];
      const track = this.tracks[id];
      if (!track.settings.visible || track.dimension !== 1 || track.data === undefined) continue;

      // hasAnyPlot = true;

      const data = (track.data as TrackXYData).data;

      const norm = track.settings.normalize["0-1"] ? "0-1" : track.settings.normalize["-1-1"] ? "-1-1" : undefined;
      const idArray = [...data.x.map((t) => t.id)];
      if (norm) idArray.push("norm" + norm);
      const trackId = idArrayToId(idArray);

      if (track?.trackdata1D?.id !== trackId) {
        track.trackdata1D = this.crateTrackdata1D(track, trackId);
        if (norm && track.range.min && track.range.max)
          this.normalize1DData(track.trackdata1D, track.range.min, track.range.max, norm);
      }

      // console.log("trackdata", track.settings.normalize);

      let offset: [number, number] = [0, 0];

      if (this.viewMode === VIEW.OFFSET) {
        const xDirection = this.domainX[1] - this.domainX[0] < 0 ? -1 : 1;
        const yDirection = this.domainY[1] - this.domainY[0] > 0 ? -1 : 1;
        offset = [index * xDirection * this.parameter.translate[0], index * yDirection * this.parameter.translate[1]];
      }

      const settings = track.settings as any as TrackXYSettings;

      if (settings.draw.line)
        this.cDraw(
          { x: track.trackdata1D.x, y: track.trackdata1D.y, length: track.trackdata1D.size },
          {
            lineWidth: 2,
            // strokeStyle: this.getTrackStrokeStyle(id),
            strokeStyle: this.getTrackColorNameFromTrack(track),
          },
          offset
        );
      if (settings.draw.dots || (settings.draw.line && track.trackdata1D.size < 3)) {
        this.cCircle(
          { x: track.trackdata1D.x, y: track.trackdata1D.y, length: track.trackdata1D.size },
          { radius: 3, fillStyle: this.getTrackColorNameFromTrack(track) }
        );
      }
    }
  }

  redraw(SVGonly: boolean = false, forceRedraw: boolean = false) {
    //   // SVGonly = true;
    this.cPlot.lineJoin = "bevel";
    // if (!forceRedraw) console.time("redraw");
    // const time = performance.now();

    if (this.isDrawing) {
      this.mustRedraw = true;
      return;
    }
    if (this.updateAnnotationShapes) this.annotationShapes = undefined;
    if (this.annotationShapes === undefined) this.createAnnotationShapesFromList();
    this.updateAnnotationShapes = false;

    this.cPlot.fillStyle = "white";
    // this.cPlot.fillStyle = "green";
    // this.cPlot.fillRect(0, 0, this.size.width, this.size.height);
    this.cPlot.clearRect(0, 0, this.size.width, this.size.height);
    // console.log(
    //   ` >> redraw ${Object.values(this.tracks)
    //     .map((t): number => (t.visible ? 1 : 0))
    //     .reduce((p, c) => p + c, 0)} tracks <<`
    // );
    this.ticsXYMode = this.getTicsModeFromVisibleTracks();

    this.drawAxis(SVGonly);

    const visibleTracks: string[] = [];
    //   this.annotation.selectAll("path").remove();
    const xLabel = [];
    const yLabel = [];
    for (let [id, track] of Object.entries(this.tracks)) {
      if (id in this.visibilityListener && this.visibilityListener[id].state !== track.settings.visible) {
        this.visibilityListener[id].state = track.settings.visible;
        this.visibilityListener[id].listener(track.settings.visible);
      }

      if (!track.settings.visible) {
        this.plot
          .selectAll("#" + id)
          .attr("d", this.line([]))
          .exit()
          .remove();
        continue;
      }
      visibleTracks.push(track.id);

      let label = [];
      if (track.settings.axisLabels.x) label.push(track.settings.axisLabels.x);
      if (track.settings.axisUnits.x) label.push("(" + track.settings.axisUnits.x + ")");
      let l = label.join(" ");
      if (xLabel.indexOf(l) < 0) xLabel.push(l);

      label = [];
      if (track.settings.axisLabels.y) label.push(track.settings.axisLabels.y);
      if (track.settings.axisUnits.y) {
        if (track.settings.normalize["0-1"]) label.push("( [0; 1] )");
        else if (track.settings.normalize["-1-1"]) label.push("( [-1; 1] )");
        else label.push("(" + track.settings.axisUnits.y + ")");
      }
      l = label.join(" ");
      if (yLabel.indexOf(l) < 0) yLabel.push(l);
      // console.log("id", id, xLabel, yLabel);
    }
    this.graphSVG.select("#xLabel").text(xLabel.join(" / "));
    this.graphSVG.select("#yLabel").text(yLabel.join(" / "));

    this.draw2DData(SVGonly);
    this.draw1DData(SVGonly);

    this.drawAnnotationShapes();
    //   if (this.annotationMode === ANNOTATION.NONE) {
    //     this.annotation.selectAll("*").style("display", "none");
    //   } else {
    //     const shape = this.annotation.selectAll("rect,circle,line,path");
    //     const text = this.annotation.selectAll("text");
    //     text
    //       .on("mouseover", function (e, i) {
    //         // console.log("mouseover", e);
    //         if (this.annotationMode === ANNOTATION.SHAPE_LABEL || this.annotationMode === ANNOTATION.LABEL) {
    //           d3.select(this).attr("text-decoration", "underline");
    //         } else {
    //           d3.select(this).style("display", null);
    //         }
    //       })
    //       .on("mouseout", function (e, i) {
    //         if (this.annotationMode === ANNOTATION.SHAPE_LABEL || this.annotationMode === ANNOTATION.LABEL) {
    //           d3.select(this).attr("text-decoration", "none");
    //         } else {
    //           d3.select(this).style("display", "none");
    //         }
    //       });
    //     if (this.annotationMode === ANNOTATION.SHAPE_LABEL || this.annotationMode === ANNOTATION.LABEL) {
    //       text.style("display", null);
    //     } else {
    //       text.style("display", "none");
    //     }
    //     if (this.annotationMode === ANNOTATION.SHAPE_LABEL || this.annotationMode === ANNOTATION.SHAPE) {
    //       shape.style("display", null);
    //     } else {
    //       shape.style("display", "none");
    //     }
    //   }
    //   this.graphSVG.select("#xLabel").text(xLabel.join(" / "));
    //   this.graphSVG.select("#yLabel").text(yLabel.join(" / "));
    this.isDrawing = false;
    if (this.mustRedraw) {
      this.mustRedraw = false;
      // console.log("must redraw again");
      this.redraw();
    }
    // console.log(" >> redraw done <<");
    // console.log(" >> redraw done <<", performance.now() - time);

    if (this.redrawListener) {
      const state = { domain: this.getDomain(), tracks: visibleTracks };
      if (needUpdate(this.cachedState, state)) {
        this.cachedState = state;
        this.redrawListener(state.domain);
      }
    }
  }

  getDomain(): domainType {
    return {
      range: [this.x.domain(), this.y.domain()],
      points: [this.x.range()[1] - this.x.range()[0], this.y.range()[1] - this.y.range()[0]],
    };
  }

  graph_mouseenter() {
    if (d3.select(d3.event.target).attr("class") === "axis") return;
    // console.log("mouse enter", self.selectBoxPos);
    this.crosshair.style("display", null);
  }

  getOffsetCoords(): {
    ax1: number;
    ay1: number;
    ax2: number;
    ay2: number;
    cx1: number;
    cy1: number;
  } {
    const coord = {
      ax1: 0,
      ay1: 0,
      ax2: 0,
      ay2: 0,
      cx1: 0,
      cy1: 0,
    };
    const tx = this.x as (d: d3.NumberValue) => number;
    const ty = this.y as (d: d3.NumberValue) => number;

    const x =
      this.domainX[1] - this.domainX[0] < 0
        ? (tx(0) ?? 0) - tx(this.parameter.translate[0])
        : tx(this.parameter.translate[0]) - tx(0);
    const y =
      this.domainY[1] - this.domainY[0] < 0
        ? ty(0) - ty(this.parameter.translate[1])
        : ty(this.parameter.translate[1]) - ty(0);
    let a = Math.atan2(y, x);
    const threshold = Math.atan2(this.size.height, this.size.width);

    if (a > Math.PI / 2) a = Math.PI / 2 - a;
    if (a < -Math.PI / 2) a = -Math.PI / 2 - a;
    // console.log("translate", this.parameter.translate[0], this.parameter.translate[1], x, y, (a * 180) / Math.PI);
    let r = a < threshold ? this.size.width / Math.cos(a) : this.size.height / Math.sin(a);
    if (isNaN(r)) r = 0;
    // Axis postion
    coord.ax1 = this.padding.left;
    coord.ay1 = this.padding.top + this.size.height;
    coord.ax2 = coord.ax1 + r * Math.cos(a);
    coord.ay2 = coord.ay1 - r * Math.sin(a);

    coord.cx1 = coord.ax1 + x;
    coord.cy1 = coord.ay1 - y;

    return coord;
  }

  updateOffsetAxis() {
    const offsetCoord = this.getOffsetCoords();

    this.offsetAxis
      .attr("x1", offsetCoord.ax1)
      .attr("y1", offsetCoord.ay1)
      .attr("x2", offsetCoord.ax2)
      .attr("y2", offsetCoord.ay2)
      .attr("class", styles.offsetAxisLine);

    this.offsetAxisDot.attr("cx", offsetCoord.cx1).attr("cy", offsetCoord.cy1);
  }

  setCrosshair(pos: [number, number]) {
    this.crosshair.style("display", null);

    this.canvas_mouseup();
    // console.log("mode", this.crosshairMode, this.crosshairMode & CROSSHAIR.YLINE);

    if (this.crosshairMode & CROSSHAIR.XLINE)
      this.crosshair
        .select("#crosshairX")
        .attr("x1", pos[0])
        .attr("y1", this.y(this.y.domain()[0]))
        .attr("x2", pos[0])
        .attr("y2", this.y(this.y.domain()[1]))
        .style("display", null);
    else this.crosshair.select("#crosshairX").style("display", "none");

    if (this.crosshairMode & CROSSHAIR.YLINE)
      this.crosshair
        .select("#crosshairY")
        .attr("x1", this.x(this.x.domain()[0]))
        .attr("y1", pos[1])
        .attr("x2", this.x(this.x.domain()[1]))
        .attr("y2", pos[1])
        .style("display", null);
    else this.crosshair.select("#crosshairY").style("display", "none");

    if (this.crosshairMode & CROSSHAIR.XVALUE) {
      this.crosshair
        .select("#crosshairXText")
        .attr("class", styles.crosshairText)
        // .text(d3.format(".3n")(this.x.invert(pos[0])))
        .text(this.xFormatter(this.x.invert(pos[0])))
        .attr("x", pos[0])
        .attr("y", this.y(this.y.domain()[1]) + 1)
        .attr("dy", 14)
        .style("display", null);

      const xbox = this.crosshair.select("#crosshairXText").node().getBBox();
      this.crosshair
        .select("#crosshairXBox")
        .attr("x", xbox.x - 5)
        .attr("y", xbox.y)
        .attr("width", xbox.width + 10)
        .attr("height", xbox.height)
        .style("fill", "white")
        .style("display", null);
    } else {
      this.crosshair.select("#crosshairXText").style("display", "none");
      this.crosshair.select("#crosshairXBox").style("display", "none");
    }

    if (this.crosshairMode & CROSSHAIR.YVALUE) {
      this.crosshair
        .select("#crosshairYText")
        .attr("class", styles.crosshairText)
        // .text(d3.format(".3n")(this.y.invert(pos[1])))
        .text(this.yFormatter(this.y.invert(pos[1])))
        .style("text-anchor", "end")
        .attr("x", this.x(this.x.domain()[0]))
        .attr("y", pos[1])
        .attr("dx", -3)
        .style("display", null);

      const yBox = this.crosshair.select("#crosshairYText").node().getBBox();
      this.crosshair
        .select("#crosshairYBox")
        .attr("x", yBox.x)
        .attr("y", yBox.y - 5)
        .attr("width", yBox.width)
        .attr("height", yBox.height + 10)
        .style("fill", "white")
        .style("display", null);
    } else {
      this.crosshair.select("#crosshairYText").style("display", "none");
      this.crosshair.select("#crosshairYBox").style("display", "none");
    }
  }

  addTrackVisibilityListener(listener: visibilityListenerType, id: string) {
    this.visibilityListener[id] = {
      listener: listener,
      state: false,
    };
  }

  setPosListener(listener: posListenerType) {
    this.posListener = listener;
  }

  setClickListener(listener: posListenerType) {
    this.clickListener = listener;
  }

  graph_click() {
    const time = Date.now();
    if (time - this.lastClick < 200) {
      this.resetTranslate = true;
      this.resetZoom();
      this.lastClick = time;
      return;
    }
    this.lastClick = time;
    // d3.mouse(self.graph.node()))

    const pos = d3.mouse(this.graph.node());
    const inside = this.checkInside(pos);

    // console.log( "MOUSE", inside);

    if (inside && this.clickListener !== undefined) {
      const graphPos = {
        x: this.x.invert(pos[0]),
        y: this.y.invert(pos[1]),
      };
      this.clickListener(graphPos);
    }

    if (this.selectBoxPos === undefined) {
      if (inside) {
      }
    } else {
    }
  }

  graph_mouseleave() {
    this.selectBox.style("display", "none");
    // console.log("mouse leave", self.selectBoxPos);
    this.selectBoxPos = undefined;
    // console.log("mouse leave", self.selectBoxPos);
    this.crosshair.style("display", "none");
    if (this.posListener) this.posListener({ x: undefined, y: undefined });

    this.canvas_mouseup();
  }

  graph_mousemove() {
    d3.event.preventDefault();
    d3.event.stopPropagation();
    const pos = d3.mouse(this.graph.node());
    const inside = this.checkInside(pos);
    if (inside) this.setCrosshair(pos);
    else this.crosshair.style("display", "none");
    if (this.posListener) {
      if (isNaN(pos[0]) || isNaN(pos[1]) || !inside) {
        this.posListener({ x: undefined, y: undefined });
      } else {
        this.posListener({ x: this.x.invert(pos[0]), y: this.y.invert(pos[1]) });
      }
    }
    // var formatter = d3.format("0.3f");
    // TODO: re-implement showLables
    // if (this.showLabels) {
    //   const points = [];
    //   // const colors = [];
    //   let index = 0;
    //   for (let id in this.tracks) {
    //     const visibleTracks = this.isVisibleTrack(id);
    //     for (let track in this.tracks[id]) {
    //       if (!visibleTracks[track]) continue;
    //       const offset = this.getTrackOffset(index);
    //       const x = this.x.invert(pos[0] - offset[0]);
    //       let i = this.tracks[id][track].findIndex(this.dataFindX(x));
    //       if (i === 0) {
    //         const diff = this.tracks[id][track][1][0] - this.tracks[id][track][0][0];
    //         if (this.tracks[id][track][0][0] - x > diff) i = -1;
    //       }
    //       if (i >= 0) {
    //         points.push([
    //           this.tracks[id][track][i][0],
    //           this.tracks[id][track][i][1],
    //           offset[0],
    //           offset[1],
    //           this.colorMode === this.COLOR_BY_DATASET
    //             ? this.datasetParams[id].color
    //             : this.colors[track % this.colors.length],
    //         ]);
    //       }
    //       index++;
    //     }
    //   }
    //   let crosshair = this.crosshair
    //     .selectAll("#crosshairCircle")
    //     // .data( [ [d3.event.clientX, d3.event.clientY],
    //     // d3.mouse(this) ] )
    //     .data(points);
    //   crosshair.exit().remove();
    //   crosshair
    //     .enter()
    //     .append("circle")
    //     .attr("id", "crosshairCircle")
    //     .attr("r", 3)
    //     .merge(crosshair)
    //     .attr("cx", (d) => {
    //       return this.x(d[0]) + d[2];
    //     })
    //     .attr("cy", (d) => {
    //       return this.y(d[1]) + d[3];
    //     })
    //     .style("fill", (d) => {
    //       return d[4];
    //     });
    //   crosshair = this.crosshair.selectAll("#crosshairText").data(points);
    //   crosshair.exit().remove();
    //   crosshair
    //     .enter()
    //     .append("text")
    //     .attr("id", "crosshairText")
    //     .attr("class", styles.crosshairText)
    //     .attr("dx", 5)
    //     .attr("dy", 5)
    //     .merge(crosshair)
    //     .text((d) => {
    //       return "[" + this.formatter(d[0]) + ", " + this.formatter(d[1]) + "]";
    //     })
    //     // .text((d=>) { return "Hallo" } )
    //     .attr("x", (d) => {
    //       return this.x(d[0]) + d[2];
    //     })
    //     .attr("y", (d) => {
    //       return this.y(d[1]) + d[3];
    //     })
    //     .style("fill", (d) => {
    //       return d[4];
    //     });
    // }
  }

  pointDistance(u: vector2D, v: vector2D) {
    const dx = u[0] - v[0];
    const dy = u[1] - v[1];

    return Math.sqrt(dx * dx + dy * dy);
  }

  pointCenter(u: vector2D, v: vector2D): vector2D {
    return [(u[0] + v[0]) / 2, (u[1] + v[1]) / 2];
  }

  graph_touchmove() {
    d3.event.preventDefault();
    d3.event.stopPropagation();

    const pos = d3.touches(this.graph.node());
    // console.log("EVENT", d3.event.type);

    if (d3.event.type === "touchmove" && this.touchStart) {
      // console.log("EVENT touchmove", pos);
      if (this.touchStart.length === 1) {
        // Translate graph
        // const x, y;
        // deactivate cross
        // self.crosshair.style("display", "none");
        const tx = pos[0][0] - this.touchStart[0][0];
        const ty = pos[0][1] - this.touchStart[0][1];
        const x = tx - this.xTranslate;
        const y = ty - this.yTranslate;
        this.xTranslate = tx;
        this.yTranslate = ty;

        this.translateGraph(x, y);
      }
      if (this.touchStart.length === 2) {
        // console.log("EVENT zoom", pos[0], pos[1], "->", self.pointDistance(pos[0], pos[1]));
        const center = this.pointCenter(this.touchStart[0], this.touchStart[1]);
        const d0 = this.pointDistance(this.touchStart[0], this.touchStart[1]);
        const d1 = this.pointDistance(pos[0], pos[1]);
        const scale = d0 / d1;
        if (this.scale === undefined) this.scale = scale;

        const k = scale / this.scale;
        this.crosshair.style("display", "none");

        this.scale = scale;

        // console.log("EVENT dist1", d1 - d0, k);
        this.zoomXY(
          this.x.invert(center[0]),
          this.y.invert(center[1]),
          this.axisMode === AXIS.FREE || this.axisMode === AXIS.FIXY ? k : 1,
          this.axisMode === AXIS.FREE || this.axisMode === AXIS.FIXX ? k : 1
        );

        // console.log("EVENT scale", self.getGraphDiagonal(), self.getGraphDiagonal() / self.pointDistance(pos[0], pos[1]));
      }
      // console.log("EVENT touchmove", d3.event.type);
      // self.touchStart = pos;
    } else if (d3.event.type === "touchstart") {
      console.log("EVENT touchstart", pos);
      this.touchStart = pos;
      this.xTranslate = 0;
      this.yTranslate = 0;
      this.scale = 1;
    } else if (d3.event.type === "touchend") {
      console.log("EVENT touchend");
      this.touchStart = undefined;
      this.xTranslate = 0;
      this.yTranslate = 0;
      this.scale = 1;
    }
  }

  createColorScales() {
    // TODO: implement
  }

  canvas_mouseup() {
    if (this.selectBoxPos !== undefined) {
      this.setZoomBox();
    }

    if (!isNaN(this.downX)) {
      this.redraw();
      this.downX = Number.NaN;
      d3.event.preventDefault();
      d3.event.stopPropagation();
    }

    if (!isNaN(this.downy)) {
      this.redraw();
      this.downy = Number.NaN;
      d3.event.preventDefault();
      d3.event.stopPropagation();
    }
    this.updateOffsetAxis();
  }

  canvas_mousemove() {
    d3.event.preventDefault();
    d3.event.stopPropagation();
    // console.log("mouse move", d)

    if (!isNaN(this.downX)) {
      const p = d3.mouse(this.graph.node());
      const rupX = this.x.invert(p[0]) - this.x.domain()[0],
        xAxis1 = this.x.domain()[0],
        xAxis2 = this.x.domain()[1];
      let xExtent = xAxis2 - xAxis1;
      if (rupX !== 0) {
        const changeX = this.downX / rupX;
        const new_domain = [xAxis1, xAxis1 + xExtent * changeX];
        // new_xExtent = new_domain[1] - new_domain[0];

        const orig_xExtent = this.domainX[1] - this.domainX[0];
        xExtent = new_domain[1] - new_domain[0];
        const scale = xExtent / orig_xExtent;
        // console.log("scale", orig_xExtent, xExtent, "->", scale);

        if (scale >= this.parameter.scaleExtent[0] && scale <= this.parameter.scaleExtent[1])
          this.setDomainX(new_domain); //this.x.domain(new_domain);
        this.crosshair.style("display", "none");

        this.redraw();
      }
      this.downX = this.x.invert(p[0]) - this.x.domain()[0];
    }
    if (!isNaN(this.downy)) {
      const p = d3.mouse(this.graph.node());
      const rupY = this.y.invert(p[1]) - this.y.domain()[1],
        yAxis1 = this.y.domain()[1],
        yAxis2 = this.y.domain()[0];
      let yExtent = yAxis2 - yAxis1;
      // console.log("y axis", yAxis1, yaxis2, "extent", yExtent);
      if (rupY !== 0) {
        const changey = this.downy / rupY;
        const new_domain = [yAxis1 + yExtent * changey, yAxis1];

        const orig_yExtent = this.domainY[0] - this.domainY[1];
        yExtent = new_domain[0] - new_domain[1];
        const scale = yExtent / orig_yExtent;
        // console.log("scale", orig_yExtent, yExtent, "->", scale);

        if (scale >= this.parameter.scaleExtent[0] && scale <= this.parameter.scaleExtent[1])
          this.setDomainY(new_domain); // this.y.domain(new_domain);
        this.crosshair.style("display", "none");

        this.redraw();
      }
      this.downy = this.y.invert(p[1]) - this.y.domain()[1];
    }
  }

  zoomXY(posX: number, posY: number, scaleX: number, scaleY: number) {
    let origDomain = this.x.domain();
    let lo = (posX - origDomain[0]) * scaleX;
    let hi = (origDomain[1] - posX) * scaleX;

    let domain = [posX - lo, posX + hi];

    let ext1 = this.domainX[1] - this.domainX[0];
    let ext2 = domain[1] - domain[0];
    let scale = ext1 / ext2;
    if (scale < this.parameter.scaleExtent[0] || scale > this.parameter.scaleExtent[1]) domain = origDomain;
    this.setDomainX(domain);
    // this.x.domain(domain);

    origDomain = this.y.domain();
    lo = (posY - origDomain[0]) * scaleY;
    hi = (origDomain[1] - posY) * scaleY;

    domain = [posY - lo, posY + hi];

    ext1 = this.domainY[1] - this.domainY[0];
    ext2 = domain[1] - domain[0];
    scale = ext1 / ext2;
    if (scale < this.parameter.scaleExtent[0] || scale > this.parameter.scaleExtent[1]) domain = origDomain;
    // this.y.domain(domain);
    this.setDomainY(domain);
    this.redraw();
  }

  setZoomBox() {
    // console.log("setZoomBox", self.selectBoxPos, self.zoomXYMode);
    if (this.selectBoxPos == null) return;

    if (this.selectBoxPos[2] < this.selectBoxPos[0]) {
      const tmp = this.selectBoxPos[0];
      this.selectBoxPos[0] = this.selectBoxPos[2];
      this.selectBoxPos[2] = tmp;
    }

    if (this.selectBoxPos[3] < this.selectBoxPos[1]) {
      const tmp = this.selectBoxPos[1];
      this.selectBoxPos[1] = this.selectBoxPos[3];
      this.selectBoxPos[3] = tmp;
    }

    const w = this.selectBoxPos[2] - this.selectBoxPos[0];
    const h = this.selectBoxPos[3] - this.selectBoxPos[1];

    if (w === 0 || h === 0) return;

    if (this.axisMode === AXIS.FREE || this.axisMode === AXIS.FIXY) {
      const domain = [this.x.invert(this.selectBoxPos[0]), this.x.invert(this.selectBoxPos[2])];

      // console.log("X domain", domain, self.x.domain());
      // this.x.domain(domain);
      this.setDomainX(domain);
    }

    if (this.axisMode === AXIS.FREE || this.axisMode === AXIS.FIXX) {
      const domain = [this.y.invert(this.selectBoxPos[1]), this.y.invert(this.selectBoxPos[3])];

      // console.log("Y domain", domain, self.y.domain());
      // this.y.domain(domain);
      this.setDomainY(domain);
    }

    this.selectBox.style("display", "none");
    this.selectBoxPos = undefined;
    this.redraw();
  }

  getAxisMin() {
    // TODO: implement
  }

  getTrackColor() {
    // TODO: implement
  }

  getTrackColorAsColorId() {
    // TODO: implement
  }

  getTrackColorAsColorName() {
    // TODO: implement
  }

  getTrackColorAsColorType() {
    // TODO: implement
  }

  getTrackColorId() {
    // TODO: implement
  }

  getTrackContours() {
    // TODO: implement
  }

  resetZoom(redraw: boolean = true, trackIDs: string[] | undefined = undefined) {
    let minx = Infinity;
    let maxx = -Infinity;
    let miny = Infinity;
    let maxy = -Infinity;

    // console.log("resetZoom", trackIDs);

    // if (trackIDs) trackIDs.push(2);
    let tracks;
    if (trackIDs === undefined) {
      tracks = Object.values(this.tracks).filter((track) => track.settings.visible);
    } else {
      tracks = trackIDs.filter((id) => id in this.tracks).map((id) => this.tracks[id]);
    }

    let xDirection = 0;
    let index = 0;
    for (let track of tracks) {
      const trackDirection = track.settings.zoom.x[1] - track.settings.zoom.x[0] < 0 ? -1 : 1;
      xDirection += trackDirection;
      let offset = [0, 0];
      if (track.settings.visible && track.dimension === 1 && this.viewMode === VIEW.OFFSET) {
        offset = [index * this.parameter.translate[0], index * this.parameter.translate[1]];
        index++;
      }

      const zy = track.settings.normalize["0-1"]
        ? [0, 1]
        : track.settings.normalize["0-1"]
        ? [-1, 1]
        : track.settings.zoom.y;

      const domain = [
        track.settings.zoom.x[0] + offset[0],
        track.settings.zoom.x[1] + offset[0],
        zy[0] + offset[1],
        zy[1] + offset[1],
      ];

      if (trackDirection > 0) {
        if (minx > domain[0]) minx = domain[0];
        if (maxx < domain[1]) maxx = domain[1];
      } else {
        if (minx > domain[1]) minx = domain[1];
        if (maxx < domain[0]) maxx = domain[0];
      }

      let normMode = NORM.NONE;
      const settings = track.settings as any as TrackXYSettings;
      if (settings.normalize["0-1"]) normMode = NORM.FULL;
      else if (settings.normalize["-1-1"]) normMode = NORM.POSNEG;

      if (normMode === NORM.NONE || track.dimension !== 1) {
        if (miny > domain[2]) miny = domain[2];
        if (maxy < domain[3]) maxy = domain[3];
      } else {
        const domain = [this.normYMinMax[normMode][0] + offset[1], this.normYMinMax[normMode][1] + offset[1]];
        if (miny > domain[0]) miny = domain[0];
        if (maxy < domain[1]) maxy = domain[1];
      }
    }

    if (isFinite(minx) || isFinite(maxx) || isFinite(miny) || isFinite(maxy)) {
      let x = (maxx - minx) * this.rangeScale;
      if (Math.abs(x) < Number.EPSILON) x = 1;
      minx -= x;
      maxx += x;
      // if (xDirection < 0) this.domainX = [maxx, minx];
      // else this.domainX = [minx, maxx];

      if (xDirection < 0) this.setDomainX([maxx, minx]);
      else this.setDomainX([minx, maxx]);

      // this.setDomainX(this.domainX);
      const y = (maxy - miny) * this.rangeScale;
      miny -= y;
      maxy += y;

      // this.domainY = [maxy, miny];
      // this.y.domain(this.domainY);
      this.setDomainY([maxy, miny]);
      this.scale = undefined;
    }
    if (redraw === undefined || redraw) this.redraw();
  }

  setAnnotations(annotations: Annotation[], redraw: boolean = true) {
    // console.log("setAnnotations");

    if (!Array.isArray(annotations))
      throw new Error("setAnnotations: expected array of annotations. Got '" + typeof annotations + "'");

    this.annotationList = Object.fromEntries(
      annotations
        .filter((a) => a.type !== undefined && a.type !== null && a.type in this.shapeTypes)
        .map((a) => [a.id, a])
    );
    this.updateAnnotationShapes = true;
    if (redraw === undefined || redraw) this.redraw();
  }

  setAxisMode(mode: AXIS) {
    this.axisMode = mode;
  }

  setBoxZoom(boxZoom: boolean) {
    this.boxZoom = boxZoom;

    this.graphSVG.style("cursor", this.boxZoom ? "nwse-resize" : "crosshair");
  }

  setCrosshairMode(mode: CROSSHAIR) {
    this.crosshairMode = mode;
  }

  stopLoading() {
    // TODO: implement
  }

  getSVGClasses(svg: SVGSVGElement | SVGAElement) {
    // console.log("node", this.parent.node())
    const children = svg.childNodes;
    let svgClasses: string[] = [];
    for (let i = 0; i < children.length; i++) {
      const child = children[i] as SVGAElement;
      const tagName = child.tagName;
      if (tagName === undefined) continue;

      svgClasses = svgClasses.concat(this.getSVGClasses(child));

      const svgClass = child.getAttribute("class");
      if (svgClass === null) continue;
      svgClasses.push("." + svgClass);
    }

    return svgClasses;
  }

  getSVGCSS(svg: SVGSVGElement) {
    const svgClasses = new Set(Array.from(new Set(this.getSVGClasses(svg))).map((a) => a.split(/ /)[0]));

    // const svgClasses = new Set([...new Set(this.getSVGClasses(svg))].map((a) => a.split(/ /)[0]));

    let extractedCSSText = "";
    for (let i = 0; i < document.styleSheets.length; i++) {
      const s = document.styleSheets[i];

      try {
        if (!s.cssRules) continue;
      } catch (e: any) {
        if (e.name !== "SecurityError") throw e; // for
        // Firefox
        continue;
      }

      const cssRules = s.cssRules;
      for (let r = 0; r < cssRules.length; r++) {
        if (svgClasses.has((cssRules[r] as CSSStyleRule).selectorText)) extractedCSSText += cssRules[r].cssText;
      }
    }
    return extractedCSSText;
  }

  addCSS(cssText: string, element: SVGSVGElement) {
    const styleElement = document.createElement("style");
    styleElement.setAttribute("type", "text/css");
    styleElement.innerHTML = cssText;

    const styles = element.getElementsByTagName("style");

    for (let s of styles as any as HTMLStyleElement[]) {
      element.removeChild(s);
    }

    const refNode = element.hasChildNodes() ? element.children[0] : null;
    element.insertBefore(styleElement, refNode);
  }

  getSVGString() {
    const image = document.createElementNS("http://www.w3.org/2000/svg", "image"); //Create a path in SVG's namespace
    image.setAttribute("width", this.size.width.toString());
    image.setAttribute("height", this.size.height.toString());
    image.setAttribute("x", this.padding.left.toString());
    image.setAttribute("y", this.padding.top.toString());
    image.setAttribute("xlink:href", this.plotCanvas.node().toDataURL().toString());

    const svg = d3.create("svg").node();
    if (!svg) return "";
    svg.setAttribute("width", this.width.toString());
    svg.setAttribute("height", this.height.toString());

    svg.appendChild(this.canvas.node().cloneNode(true));
    svg.appendChild(image);
    svg.appendChild(this.graphSVG.node().cloneNode(true));

    const css = this.getSVGCSS(svg);

    this.addCSS(css, svg);

    svg.setAttribute("xmlns:xlink", "http://www.w3.org/1999/xlink");

    let xml = new XMLSerializer().serializeToString(svg);
    // xml = xml.replaceAll(/xmlns=".*?" +/gi, "");
    xml = xml.replaceAll(/xmlns="http:\/\/www.w3.org\/1999\/xhtml"/gi, 'xmlns:css="http://www.w3.org/1999/xhtml"');
    // xmlns:css="http://www.w3.org/1999/xhtml"

    return xml;
  }

  getImageBlob(svg: string, callback: (blob: any) => void, mimeType = "image/png", qualityArgument = 0.95) {
    const imgsrc = "data:image/svg+xml;base64," + btoa(unescape(encodeURIComponent(svg))); // Convert

    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    if (!context) return;

    canvas.width = this.width;
    canvas.height = this.height;
    context.fillStyle = "white";
    context.fillRect(0, 0, this.width, this.height);
    const size = { width: this.width, height: this.height };

    const image = new Image();
    image.width = this.width;
    image.height = this.height;

    image.onload = () => {
      context.drawImage(image, 0, 0, size.width, size.height);
      canvas.toBlob(
        (blob) => {
          if (callback) callback(blob);
        },
        mimeType,
        qualityArgument
      );
    };

    image.src = imgsrc;
  }

  getTranslateScale() {
    const stepX = this.size.width === 0 ? 0 : (this.x.domain()[1] - this.x.domain()[0]) / this.size.width;
    const stepY = this.size.height === 0 ? 0 : (this.y.domain()[1] - this.y.domain()[0]) / this.size.height;

    return {
      stepX: Math.abs(stepX),
      stepY: Math.abs(stepY),
      directionX: Math.sign(stepX),
      directionY: Math.sign(stepY),
      translateX: this.parameter.translate[0],
      translateY: this.parameter.translate[1],
    };
  }

  hasTrack(id: string) {
    return id in this.tracks;
  }

  setTranslateXY(translateX: number, translateY: number, redraw: boolean = false) {
    this.parameter.translate = [translateX, translateY];

    if (redraw) this.redraw();
  }

  setTrackVisibility(id: string, visible: boolean) {
    // TODO: implement
  }

  setOffsetPadding(padding: number, redraw?: boolean) {
    // TODO: implement
  }

  setViewMode(viewMode: VIEW, redraw?: boolean) {
    const change = this.viewMode !== viewMode;
    this.viewMode = viewMode;
    if (change && redraw) this.redraw();
  }

  setViewChangeListener(listener: (domainX: [number, number], domainY: [number, number]) => void) {
    // TODO: implement
  }

  setRedrawListener(redrawListener: redrawListenerType) {
    this.redrawListener = redrawListener;
  }

  interpolateTypes = [
    d3.curveLinear,
    d3.curveStepBefore,
    d3.curveStepAfter,
    d3.curveBasis,
    d3.curveBasisOpen,
    d3.curveBasisClosed,
    d3.curveBundle,
    d3.curveCardinal,
    d3.curveCardinal,
    d3.curveCardinalOpen,
    d3.curveCardinalClosed,
    d3.curveNatural,
  ];

  colors = [
    "#4363d8", // Blue
    "#f58231", // Orange
    "#911eb4", // Purple
    "#42d4f4", // Cyan
    "#f032e6", // Magenta
    "#bfef45", // Lime
    "#fabebe", // Pink
    "#469990", // Teal
    "#e6194B", // Red
    "#3cb44b", // Green
    "#ffe119", // Yellow
    "#e6beff", // Lavender
    "#9A6324", // Brown
    "#fffac8", // Beige
    "#800000", // Maroon
    "#aaffc3", // Mint
    "#808000", // Olive
    "#ffd8b1", // Apricot
    "#000075", // Navy
    "#a9a9a9", // Grey
  ];
}
