import { generateUid } from "../../tools/UID/UID";
import { argumentType, commandTypes, parameterInputTypes, parameterTypes, trackListType } from "../PipelineTypes";
import { StackTraces } from "../StackTrace";
import { Empty } from "./Empty";

export class ZeroFill extends Empty {
  id: string;
  name: string;
  readonly type = commandTypes.zeroFill;
  errors: StackTraces;
  warnings: StackTraces;

  static defaultMax = 1024;

  parameterTypes = { size: parameterTypes.string, add: parameterTypes.int, outputSize: parameterTypes.string };
  parameterSettings = {
    add: { name: "Add points to FID", min: 0, max: ZeroFill.defaultMax, inputType: parameterInputTypes.range },
    size: { name: "FID size", readonly: true },
    outputSize: { name: "New FID size", readonly: true },
  };
  internalParameter: Record<string, any>;
  readonly input: argumentType[];
  readonly output: argumentType[];

  constructor() {
    super();
    this.id = generateUid();
    this.name = "Zero filling";
    this.errors = new StackTraces();
    this.warnings = new StackTraces();
    this.internalParameter = {};
    this.input = [
      { name: "FID real", type: "1D_real" },
      { name: "FID imaginary", type: "1D_real" },
    ];
    this.output = [
      { name: "FID real + zeros", type: "1D_real" },
      { name: "FID imaginary + zeros", type: "1D_real" },
    ];
  }

  calculatePot2Length(len: number) {
    const expo = Math.ceil(Math.log2(len));
    return Math.pow(2, expo);
  }

  run(tracks: trackListType): trackListType {
    // // return parameter?.trackList.value(tracks) ?? [];
    // if (!tracks?.[0]?.data || !tracks?.[1]?.data) {
    //   this.errors.create({
    //     id: this.id,
    //     component: "ZeroFillFID.run",
    //     message: `Some tracks do not contain any data.`,
    //   });
    //   return [];
    // }
    // const reIn: Data1DReal = tracks[0].data;
    // const imIn: Data1DReal = tracks[1].data;

    // // const len =
    // const { add } = this.parameter as parameterType;

    // if (add < 0) {
    //   this.errors.create({
    //     id: this.id,
    //     component: "ZeroFillFID.run",
    //     message: `Parameter factor must be a positive number. (Got ${add})`,
    //   });
    //   return [];
    // }

    // if (reIn.length < 2) {
    //   this.errors.create({
    //     id: this.id,
    //     component: "ZeroFillFID.run",
    //     message: `Track must have two or more points. (Got ${reIn.length})`,
    //   });
    //   return [];
    // }

    // // const len = this.calculatePot2Length(reIn.length + add);
    // // console.log(reIn.length + add, "->", Math.log2(len));
    // let len = reIn.length;

    // const parameter: Record<string, any> = {
    //   size: reIn.length + " points",
    //   // min: -reIn.length,
    // };

    // const scale = reIn.length * 3;
    // if (scale > ZeroFill.defaultMax) parameter.add = { max: scale };
    // len += add;
    // parameter.outputSize = `${len} points`;

    // this.parameter = parameter;

    // if (len === reIn.length) return tracks;

    // // const twoPowerLen = this.calculatePot2Length(len);
    // const x = new Float32Array(len);
    // const re = new Float32Array(len);
    // const im = new Float32Array(len);
    // for (let i = 0; i < reIn.length; i++) {
    //   x[i] = reIn.x[i];
    //   re[i] = reIn.y[i];
    //   im[i] = imIn.y[i];
    // }
    // const x0 = reIn.x[0];
    // const dw = reIn.x[1] - x0;

    // for (let i = reIn.length; i < len; i++) {
    //   x[i] = x0 + i * dw;
    //   re[i] = 0;
    //   im[i] = 0;
    // }

    // const reData: Data1DReal = { x: x, y: re, type: reIn.type, length: len };
    // const imData: Data1DReal = { x: x, y: im, type: reIn.type, length: len };

    // // console.log("zero fill:", reIn.length, "->", len);

    // tracks[0].data = reData;
    // tracks[1].data = imData;
    // tracks[0].label = "zero.re";
    // tracks[1].label = "zero.im";

    return tracks;
  }
}
