import React, { CSSProperties } from "react";
import styles from "./TableView.module.css";
import { EntityConstants } from "../../../api/GenericTypes";
import { SinglePageViewLabel } from "../SinglePage/SinglePage";
import { Panel, PanelGroup } from "react-resizable-panels";
import { CustomResizeHandle } from "../misc/CustomResizeHandle";

/**
 * Generic TableView wrapper component, with Title, top controls, left sidebar and main content area
 * Author: CS
 * @param: children
 * @returns: JSX.Element
 */

interface Props {
  style?: CSSProperties;
  children: React.ReactNode;
}
const TableView = ({ style, children }: Props) => {
  return (
    <div className={styles.container} style={style}>
      {children}
    </div>
  );
};

const Head = ({ style, children }: Props) => {
  return (
    <div className={styles.container_head} style={style}>
      {children}
    </div>
  );
};
TableView.Head = Head;

const Label = ({ style, children }: Props) => (
  <div className={styles.container_label} style={style}>
    {children}
  </div>
);
Head.Label = Label;

const Controls = ({ style, children }: Props) => (
  <div className={styles.container_ctrls} style={style}>
    {children}
  </div>
);
Head.Controls = Controls;

const Body = ({ style, children }: Props) => {
  return (
    <div className={styles.container_body} style={style}>
      <PanelGroup direction="horizontal">{children}</PanelGroup>
    </div>
  );
};
TableView.Body = Body;

const Sidebar = ({
  style,
  children,
  defaultSize = 20,
  minSize = 10,
  onFold,
}: Props & { minSize?: number; defaultSize?: number; onFold?: (isFolded: boolean) => void }) => {
  return (
    <>
      <Panel collapsible={true} minSize={minSize} className={styles.container_navleft} defaultSize={defaultSize}>
        {children}
      </Panel>
      <CustomResizeHandle orientation="horizontal" />
    </>
  );
};
Body.Sidebar = Sidebar;

const Content = ({ style, children }: Props) => (
  <Panel className={styles.container_content} style={style} defaultSize={80}>
    {children}
  </Panel>
);
Body.Content = Content;

export default TableView;

// Misc

// Used for table headers including icon, title and count
interface TableViewLabelProps {
  entityConstants: EntityConstants;
  resultsCount: number | undefined;
  overwriteLabel?: string;
}
export const TableViewLabel = ({ entityConstants, resultsCount, overwriteLabel }: TableViewLabelProps) => {
  return (
    <SinglePageViewLabel entityConstants={entityConstants} overwriteLabel={overwriteLabel}>
      <span className={`badge ${typeof resultsCount !== "number" ? "loading" : ""}`}>
        {typeof resultsCount === "number" ? resultsCount : "•"}
      </span>
    </SinglePageViewLabel>
  );
};
