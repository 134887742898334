import { useEffect, useState } from "react";

import { LucideIcon } from "../../common/icon/LucideIcon";
import { ParameterElement } from "../../ViewerLayout/ViewerLayoutTypes";
import { ColorRectList } from "../../ViewerUIElements/ColorRect";
import { FormattedMultiline } from "./FormatMultiline";
import styles from "./TreeViewer.module.css";
import { SearchRequestType } from "./TreeViewerTypes";

export const TreeMultilineLeaf = ({ children, search }: { children: ParameterElement; search: SearchRequestType }) => {
  const { name, value, vtype, isVisible, active, colors } = children;

  const [expanded, setExpanded] = useState<boolean>(true);
  const [content, setContent] = useState<any>();
  const [searching, setSearching] = useState<boolean>((search?.keyText || search?.valueText) !== undefined);

  useEffect(() => {
    setSearching((search?.keyText || search?.valueText) !== undefined);
  }, [search?.keyText, search?.valueText]);

  useEffect(() => {
    setExpanded(active === undefined ? true : active);
  }, [active]);

  useEffect(() => {
    // let formattedValue = value;
    if (vtype === "str") setContent(<FormattedMultiline>{value.toString()}</FormattedMultiline>);
    else setContent(null);
  }, [vtype, children]);

  return (
    <div style={{ display: isVisible ? undefined : "none" }}>
      <div
        className={styles.parameterKey}
        onClick={() => {
          setExpanded(!expanded);
        }}
      >
        <LucideIcon
          name={expanded || searching ? "chevron-down" : "chevron-right"}
          style={{ strokeWidth: 3, width: 15 }}
        />
        <LucideIcon name="align-left" style={{ strokeWidth: 3, width: 15 }} />
        &nbsp;
        {name}
        <ColorRectList colors={colors} />
      </div>
      <div className={styles.listContent} style={{ display: expanded || searching ? undefined : "none" }}>
        {content}
      </div>
    </div>
  );
};
