import { useContext, useMemo, useState } from "react";
import { SessionContext } from "../../../common/contexts/SessionContext";
import { SearchInput } from "../../../common/forms/SearchInput/SearchInput";
import { LucideIcon } from "../../../common/icon/LucideIcon";
import TableView, { TableViewLabel } from "../../../common/panels/TableView/TableView";
import { ColumnsSettings } from "../../../common/tables/ColumnsSelector/ColumnsSelector";
import { AlertModal } from "../../../common/modals/AlertModal/AlertModal";
import { MoreDropdown } from "../../../common/buttons/MoreDropdown/MoreDropdown";
import { EntityTable } from "../../../common/tables/EntityTable/EntityTable";
import {
  GenericVirtualizedTableCells,
  SortState,
} from "../../../common/tables/GenericVirtualizedTable/GenericVirtualizedTableTypes";
import { EntityFilterIndicator } from "../../../common/tables/EntityFilterIndicator/EntityFilterIndicator";
import { EntityTableTabs } from "../../../common/tables/Tabs/EntityTableTabs";
import { useTabStore } from "../../../common/tables/Tabs/useTabStore";
import { TableTabsDict } from "../../../common/tables/Tabs/TableTabsTypes";
import { NotSet } from "../../../common/misc/UIconstants";
import { LabNotebook, LabNotebookFilters } from "../../types/LabNotebook";
import { LabNotebookFilterBar, LabNotebookFilterForm } from "./LabNotebooksFilterBar";
import { DateTimeRenderer } from "../../../common/datetime/DateTimeFormatter";
import { Modal } from "../../common/ELNModal/ELNModal";
import { GetPersons, TableArrayRenderer } from "../../../common/misc/EntityRenders/EntityRenderer";
import { LinkButton } from "../../../common/buttons/LinkButton/LinkButton";
import {
  UseEntityTableDefaultProps,
  UseEntityTableProps,
  useGenericVirtualizedTable,
  useGenericVirtualizedTableTabs,
} from "../../../common/tables/GenericVirtualizedTable/useGenericVirtualizedTable";
import { LabNotebookAddForm } from "../LabNotebookViews";
import { EntityTableProps } from "../../../common/entity/EntityInterfaces";
import { EntityTableRestoreButton } from "../../../common/entity/entityComponents/EntityTableRestoreButton";
import { EntityTableSoftDeletableButton } from "../../../common/entity/entityComponents/EntityTableSoftDeletableButton";
import { EntityTableEditButton } from "../../../common/entity/entityComponents/EntityTableEditButton";

export const switchLabNotebooksDefaultSortState = (
  sortState: LabNotebookFilters["orderBy"]
): SortState<LabNotebookFilters["orderBy"]> => {
  switch (sortState) {
    case "ID_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-id",
        orderBy: sortState,
      };
    case "ID_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-id",
        orderBy: sortState,
      };
    case "NAME_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-name",
        orderBy: sortState,
      };
    case "NAME_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-name",
        orderBy: sortState,
      };
    case "STATUS_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-status",
        orderBy: sortState,
      };
    case "STATUS_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-status",
        orderBy: sortState,
      };
    case "CREATED_ON_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-createdOn",
        orderBy: sortState,
      };
    case "CREATED_ON_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-createdOn",
        orderBy: sortState,
      };
    case "CREATED_BY_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-createdBy",
        orderBy: sortState,
      };
    case "CREATED_BY_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-createdBy",
        orderBy: sortState,
      };
    case "MODIFIED_ON_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-modifiedOn",
        orderBy: sortState,
      };
    case "MODIFIED_ON_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-modifiedOn",
        orderBy: sortState,
      };
    case "MODIFIED_BY_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-modifiedBy",
        orderBy: sortState,
      };
    case "MODIFIED_BY_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-modifiedBy",
        orderBy: sortState,
      };
    default:
      return {
        sortDirection: "DESC",
        headerId: "default-modifiedBy",
        orderBy: sortState,
      };
  }
};

const defaultFilter: LabNotebookFilters = { orderBy: "MODIFIED_ON_DESC" };

export const useLabNotebooksTableDefaults = ({ fieldLabels }: UseEntityTableDefaultProps<"notebooks">) => {
  const { session } = useContext(SessionContext);

  const defaults: ColumnsSettings<LabNotebook> = useMemo(
    () => ({
      "default-id": { pos: 0, active: false, header: fieldLabels.id, property: "id" },
      "default-name": { pos: 1, active: true, header: fieldLabels.name, property: "name" },
      "default-description": { pos: 2, active: true, header: fieldLabels.description, property: "description" },
      "default-status": { pos: 3, active: true, header: fieldLabels.status, property: "status" },
      "default-projects": { pos: 4, active: true, header: fieldLabels.projects, property: "projects" },
      "default-createdOn": { pos: 5, active: true, header: fieldLabels.createdOn, property: "createdOn" },
      "default-createdBy": { pos: 6, active: true, header: fieldLabels.createdBy, property: "createdBy" },
      "default-modifiedOn": { pos: 7, active: true, header: fieldLabels.modifiedOn, property: "modifiedOn" },
      "default-modifiedBy": { pos: 8, active: true, header: fieldLabels.modifiedBy, property: "modifiedBy" },
    }),
    [fieldLabels]
  );

  const tabStoreDefaults: TableTabsDict<LabNotebook, LabNotebookFilters, LabNotebookFilterForm> = useMemo(
    () => ({
      default: {
        tabId: "default",
        type: "fixed",
        label: "All",
        title: "All",
        icon: "house",
        align: "left",
        xPos: 0,
        settings: {
          columnSettings: {},
          columnWidths: {},
          filters: defaultFilter,
          sidebarFilters: {},
        },
        forcedSettings: {
          columnSettings: {},
          columnWidths: {},
          filters: {},
          sidebarFilters: {},
        },
      },
      mydata: {
        tabId: "mydata",
        type: "fixed",
        label: "My notebooks",
        title: "My notebooks",
        icon: "house",
        align: "left",
        xPos: 1,
        settings: {
          columnSettings: {},
          columnWidths: {},
          filters: {},
          sidebarFilters: {},
        },
        forcedSettings: {
          columnSettings: {},
          columnWidths: {},
          filters: { createdByIds: session?.userId ? [session.userId] : null },
          sidebarFilters: {},
        },
      },
      trash: {
        tabId: "trash",
        type: "fixed",
        label: "Trash",
        title: "Trash",
        icon: "trash",
        align: "left",
        xPos: 3,
        settings: {
          columnSettings: {},
          columnWidths: {},
          filters: defaultFilter,
          sidebarFilters: {},
        },
        forcedSettings: {
          columnSettings: {},
          columnWidths: {},
          filters: { isSoftDeleted: true },
          sidebarFilters: {},
        },
      },
    }),
    [session?.userId]
  );

  return { defaults, tabStoreDefaults };
};

export const useLabNotebooksTableColumns = ({
  entityConstants,
  fieldLabels,
  sort,
  setSort,
}: UseEntityTableProps<"notebooks">) => {
  const columns = useMemo(() => {
    let headers: GenericVirtualizedTableCells<LabNotebook> = [
      {
        id: "default-id",
        Header: fieldLabels.id,
        accessor: (row) => (
          <span style={{ color: "var(--gray-400)" }}>
            <samp>{row.id}</samp>
          </span>
        ),
        width: 200,
        align: "right",
        sortingFn: () => {
          if (sort.headerId === "default-id") {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "ID_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "ID_ASC" }));
            }
          } else {
            setSort({ headerId: "default-id", sortDirection: "ASC", orderBy: "ID_ASC" });
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
      {
        id: "default-name",
        Header: fieldLabels.name,
        accessor: (row) => (
          <div style={{ display: "flex", flexFlow: "row nowrap", gap: "5px", overflow: "hidden" }}>
            <div>
              <LucideIcon name={`${row.status === "CLOSED" ? "book" : "book-open"}`} color={"var(--primary)"} />
            </div>
            <div style={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }}>{row.name}</div>
            {row.isDeleted && (
              <div>
                <label className="label label-soft-warning" title="Trashed" style={{ margin: 0 }}>
                  <LucideIcon name="trash" />
                </label>
              </div>
            )}
          </div>
        ),
        width: 300,
        align: "left",
        sortingFn: () => {
          if (sort.headerId === "default-name") {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "NAME_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "NAME_ASC" }));
            }
          } else {
            setSort({ headerId: "default-name", sortDirection: "ASC", orderBy: "NAME_ASC" });
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
      {
        id: "default-description",
        Header: fieldLabels.description,
        accessor: (row) => (
          <div
            style={{ display: "flex", flexFlow: "row nowrap", gap: "5px", overflow: "hidden" }}
            data-toggle="tooltip"
            title={row.description || "No description"}
          >
            <div style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
              <span style={{ fontWeight: 500, whiteSpace: "nowrap", marginRight: "5px" }}>
                {row.description || NotSet}
              </span>
            </div>
          </div>
        ),
        width: 300,
        align: "left",
      },
      {
        id: "default-status",
        Header: fieldLabels.status,
        accessor: (row) => (
          <label
            className={`label label-soft-${row.status === "CLOSED" ? "danger" : "success"}`}
            style={{ margin: 0 }}
          >{`${row.status === "CLOSED" ? "Closed" : "Active"}`}</label>
        ),
        width: 150,
        // maxWidth: 200,
        align: "left",
        sortingFn: () => {
          if (sort.headerId === "default-status") {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "STATUS_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "STATUS_ASC" }));
            }
          } else {
            setSort({ headerId: "default-status", sortDirection: "ASC", orderBy: "STATUS_ASC" });
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
      {
        id: "default-projects",
        Header: fieldLabels.projects,
        accessor: (row) => <TableArrayRenderer values={row.projects}>{(value) => value.name}</TableArrayRenderer>,
        width: 200,
        align: "left",
      },
      {
        id: "default-createdOn",
        Header: fieldLabels.createdOn,
        accessor: (row) => <DateTimeRenderer date={row.createdOn} includeElapsed={false} />,
        width: 200,
        align: "left",
        sortingFn: () => {
          if (sort.headerId === "default-createdOn") {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "CREATED_ON_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "CREATED_ON_ASC" }));
            }
          } else {
            setSort({ headerId: "default-createdOn", sortDirection: "ASC", orderBy: "CREATED_ON_ASC" });
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
      {
        id: "default-createdBy",
        Header: fieldLabels.createdBy,
        accessor: (row) => <GetPersons persons={[row.createdBy]} createLinks={false} />,
        width: 200,
        align: "left",
        sortingFn: () => {
          if (sort.headerId === "default-createdBy") {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "CREATED_BY_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "CREATED_BY_ASC" }));
            }
          } else {
            setSort({ headerId: "default-createdBy", sortDirection: "ASC", orderBy: "CREATED_BY_ASC" });
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
      {
        id: "default-modifiedOn",
        Header: fieldLabels.modifiedOn,
        accessor: (row) => <DateTimeRenderer date={row.modifiedOn} includeElapsed={false} />,
        width: 200,
        align: "left",
        sortingFn: () => {
          if (sort.headerId === "default-modifiedOn") {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "MODIFIED_ON_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "MODIFIED_ON_ASC" }));
            }
          } else {
            setSort({ headerId: "default-modifiedOn", sortDirection: "ASC", orderBy: "MODIFIED_ON_ASC" });
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
      {
        id: "default-modifiedBy",
        Header: fieldLabels.modifiedBy,
        accessor: (row) => <GetPersons persons={[row.modifiedBy]} createLinks={false} />,
        width: 200,
        align: "left",
        sortingFn: () => {
          if (sort.headerId === "default-modifiedBy") {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "MODIFIED_BY_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "MODIFIED_BY_ASC" }));
            }
          } else {
            setSort({ headerId: "default-modifiedBy", sortDirection: "ASC", orderBy: "MODIFIED_BY_ASC" });
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
    ];

    return headers;
  }, [
    fieldLabels.createdBy,
    fieldLabels.createdOn,
    fieldLabels.description,
    fieldLabels.id,
    fieldLabels.modifiedBy,
    fieldLabels.modifiedOn,
    fieldLabels.name,
    fieldLabels.projects,
    fieldLabels.status,
    setSort,
    sort.headerId,
    sort.sortDirection,
  ]);
  return { columns };
};

export const LabNotebooksTable = ({
  entityApi,
  entityConstants,
  fieldLabels,
  permissions,
  routes,
}: EntityTableProps<"notebooks">) => {
  const [showModal, setShowModal] = useState(false);
  const [showCreateLabNotebookModal, setShowCreateLabNotebookModal] = useState<boolean>(false);

  const {
    bulkRestoreMutationAsync: bulkRestoreLabNotebooks,
    bulkDeleteMutationAsync: deleteLabNotebooks,
    isLoadingBulkRestoreMutation,
    isLoadingBulkDeleteMutation,
  } = entityApi;

  const { defaults, tabStoreDefaults } = useLabNotebooksTableDefaults({ fieldLabels });

  const { selection, resultsCount, selectionPermissions, onCountChange, onSelectionChange, onSelectionPermissions } =
    useGenericVirtualizedTable<LabNotebook>();

  const {
    filters,
    sidebarFilters,
    forcedFilters,
    columnSetting,
    columnWidths,
    customTabs,
    fixedTabs,
    dispatchTabStore,
    currentTab,
    tabsLoading,
    tabsModified,
  } = useTabStore<LabNotebook, LabNotebookFilters, LabNotebookFilterForm>({
    resource: entityConstants.resource,
    defaults: tabStoreDefaults,
  });

  const { functionRef, sort, setSort, searchValue, setSearchValue, onTabChange } = useGenericVirtualizedTableTabs({
    tabsLoading,
    filters,
    switchSortState: switchLabNotebooksDefaultSortState,
    dispatchTabStore,
  });

  const { columns } = useLabNotebooksTableColumns({ fieldLabels, entityConstants, sort, setSort });

  const memoizedFilters = useMemo(() => ({ ...filters, ...forcedFilters }), [filters, forcedFilters]);

  return (
    <>
      <TableView>
        <TableView.Head>
          <TableView.Head.Label>
            {/* <div>Lab notebooks {typeof resultsCount === "number" && <span className="badge">{resultsCount}</span>}</div> */}
            <TableViewLabel entityConstants={entityConstants} resultsCount={resultsCount} />
          </TableView.Head.Label>
          <TableView.Head.Controls>
            <LinkButton
              linkTo={routes.getAddRoute}
              className="btn btn-primary"
              title={`Add ${entityConstants.entitySingular}`}
              disabled={!permissions.canCreate}
            >
              <LucideIcon name="plus" /> Add {entityConstants.entitySingular}
            </LinkButton>
          </TableView.Head.Controls>
        </TableView.Head>
        <TableView.Body>
          <TableView.Body.Sidebar>
            <LabNotebookFilterBar
              initialValues={sidebarFilters}
              dispatchTabStore={dispatchTabStore}
              tabsLoading={tabsLoading}
              currentTab={currentTab}
            />
          </TableView.Body.Sidebar>
          <TableView.Body.Content>
            <EntityTableTabs
              currentTab={currentTab}
              onTabChange={onTabChange}
              fixedTabs={fixedTabs}
              filters={{
                ...filters,
                isSoftDeleted: filters.isSoftDeleted || false,
                includeSoftDeleted: filters.includeSoftDeleted || false,
              }}
              sidebarFilters={sidebarFilters}
              columnSetting={columnSetting}
              columnWidths={columnWidths}
              customTabs={customTabs}
              dispatchTabStore={dispatchTabStore}
              tabsLoading={tabsLoading}
              tabsModified={tabsModified}
            />
            <EntityTable>
              <EntityTable.Controls
                style={{
                  borderTop: "0px",
                  borderRadius: "0px",
                }}
              >
                <EntityFilterIndicator<LabNotebook, LabNotebookFilters>
                  filters={filters}
                  excludeFilters={{ includeSoftDeleted: (value) => !!value, isSoftDeleted: (value) => !!value }}
                />
                <SearchInput searchValue={searchValue} setSearchValue={setSearchValue} placeholder="Search" />
                <EntityTableEditButton
                  entityConstants={entityConstants}
                  routes={routes}
                  permissions={permissions}
                  selection={selection}
                  selectionPermissions={selectionPermissions}
                />
                <MoreDropdown drop="right" btn="btn btn-ghost-secondary">
                  {currentTab === "trash" && (
                    <li>
                      <EntityTableRestoreButton
                        entityConstants={entityConstants}
                        selection={selection}
                        permissions={permissions}
                        selectionPermissions={selectionPermissions}
                        onClick={async () =>
                          await bulkRestoreLabNotebooks({ ids: Array.from(selection) }).catch(() => {})
                        }
                        loading={isLoadingBulkRestoreMutation}
                      />
                    </li>
                  )}
                  <li>
                    <EntityTableSoftDeletableButton
                      currentTab={currentTab}
                      entityConstants={entityConstants}
                      selection={selection}
                      permissions={permissions}
                      selectionPermissions={selectionPermissions}
                      onClick={() => setShowModal(true)}
                    />
                  </li>
                </MoreDropdown>
                <AlertModal
                  type={`${currentTab === "trash" ? "danger" : "warning"}`}
                  showModal={showModal}
                  setShowModal={setShowModal}
                  forceUserInput={currentTab === "trash"}
                  title={`${
                    selection.size === 0
                      ? `Select dataset to ${currentTab === "trash" ? "delete" : "trash"}`
                      : selection.size === 1
                      ? `${currentTab === "trash" ? "Delete" : "Trash"} the selected ${entityConstants.entitySingular}?`
                      : `${currentTab === "trash" ? "Delete" : "Trash"} the selected ${entityConstants.entityPlural}?`
                  }`}
                  description={`${
                    currentTab === "trash"
                      ? `Proceeding will permanently delete the selected ${entityConstants.entityPlural}.`
                      : `Proceeding will move the selected ${entityConstants.entityPlural} into trash.`
                  }`}
                  proceedLabel={`${currentTab === "trash" ? "Delete" : "Trash"}`}
                  onProceed={async () => {
                    await deleteLabNotebooks({
                      ids: Array.from(selection),
                      goBackOnSuccess: false,
                      showToast: false,
                      entityName: entityConstants.resource,
                      params: { deletePermanently: currentTab === "trash" },
                    }).catch((e) => {});
                    functionRef.current?.resetSelection();
                    setShowModal(false);
                  }}
                  loading={isLoadingBulkDeleteMutation}
                />
              </EntityTable.Controls>
              <EntityTable.Body<LabNotebook, LabNotebookFilters>
                functionRef={functionRef}
                entityConstants={entityConstants}
                filters={memoizedFilters}
                columns={columns}
                columnSelect
                columnSetting={columnSetting}
                columnWidths={columnWidths}
                defaultColumnSettings={defaults}
                dispatchTabStore={dispatchTabStore}
                setResultsCount={onCountChange}
                onSelectionChange={onSelectionChange}
                onSelectionPermissions={onSelectionPermissions}
                showPermissionColumn
                loading={tabsLoading}
              />
            </EntityTable>
          </TableView.Body.Content>
        </TableView.Body>
      </TableView>
      <Modal isOpen={showCreateLabNotebookModal} onClose={() => setShowCreateLabNotebookModal(false)}>
        <div style={{ overflow: "auto" }}>
          <div style={{ width: "50vw", minWidth: "max-content" }}>
            <LabNotebookAddForm
              onSuccess={() => setShowCreateLabNotebookModal(false)}
              onCancel={() => setShowCreateLabNotebookModal(false)}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};
