import { InstrumentForm } from "./InstrumentForm";
import { EntityAddForm, EntityAddFormPage } from "../common/entity/EntityAddForm";
import { EntityCloneForm, EntityCloneFormPage } from "../common/entity/EntityCloneForm";
import {
  CustomTypeEntityAddFormProps,
  EntityCloneFormProps,
  EntityDetailViewProps,
  EntityEditFormProps,
} from "../common/entity/EntityInterfaces";
import { EntityEditForm, EntityEditFormPage } from "../common/entity/EntityEditForm";
import { EntityDetailPage, EntityDetailView } from "../common/entity/EntityDetailPage";
import { InstrumentsDetail } from "./InstrumentsDetailView";
import { useLocation } from "react-router-dom";
import { InstrumentsTable } from "./Table/InstrumentsTable";
import { EntityTablePage } from "../common/entity/EntityTablePage";

// Add
export const InstrumentAddForm = ({ typeId }: CustomTypeEntityAddFormProps) => {
  return (
    <EntityAddForm entityTypeId={"instruments"}>
      {(props) => <InstrumentForm {...props} typeId={typeId} />}
    </EntityAddForm>
  );
};

export const InstrumentAddFormPage = () => {
  const typeId = new URLSearchParams(useLocation().search).get("type");
  return (
    <EntityAddFormPage>
      <InstrumentAddForm typeId={typeId ? +typeId : null} />
    </EntityAddFormPage>
  );
};

// Clone
export const InstrumentCloneForm = ({ id }: EntityCloneFormProps<"instruments">) => {
  return (
    <EntityCloneForm id={id} entityTypeId={"instruments"}>
      {(props) => <InstrumentForm {...props} typeId={props.initialValues?.customType?.id} />}
    </EntityCloneForm>
  );
};

export const InstrumentCloneFormPage = () => {
  return <EntityCloneFormPage<"instruments"> isIntId>{(id) => <InstrumentCloneForm id={id} />}</EntityCloneFormPage>;
};

// Edit
export const InstrumentEditForm = ({ id }: EntityEditFormProps<"instruments">) => {
  return (
    <EntityEditForm id={id} entityTypeId={"instruments"} filters={{}}>
      {(props) => <InstrumentForm {...props} typeId={props.initialValues?.customType?.id} />}
    </EntityEditForm>
  );
};

export const InstrumentEditFormPage = () => {
  return <EntityEditFormPage<"instruments"> isIntId>{(id) => <InstrumentEditForm id={id} />}</EntityEditFormPage>;
};

// Detail
export const InstrumentsDetailView = ({ id }: EntityDetailViewProps<"instruments">) => {
  return (
    <EntityDetailView id={id} entityTypeId={"instruments"} filters={{}}>
      {(props) => <InstrumentsDetail {...props} />}
    </EntityDetailView>
  );
};

export const InstrumentDetailsPage = () => {
  return <EntityDetailPage<"instruments"> isIntId>{(id) => <InstrumentsDetailView id={id} />}</EntityDetailPage>;
};

export const InstrumentsTablePage = () => {
  return <EntityTablePage entityTypeId={"instruments"}>{(props) => <InstrumentsTable {...props} />}</EntityTablePage>;
};
