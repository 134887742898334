import { OnChangeJSON, useHelpers } from "@remirror/react";
import { Dispatch, RefObject, SetStateAction, useCallback, useEffect, useState } from "react";
import { Prompt } from "react-router-dom";
import { RemirrorJSON } from "remirror";
import { useDebouncedValue } from "../../../../../common/helperfunctions/useDebouncedValue";
import { ELNSaveParameters, ELNSaveStatus } from "../../TextEditor";
import { useELNRoutes } from "../../../../ELNRouter/useELNRoutes";

const AUTOSAVE_TIMEOUT_DURATION = 3000;

export const TextEditorOnChangeHandler = ({
  save,
  saveStatus,
  setSaveStatus,
  autosave,
  onChange,
  initialContent,
  saveButtonRef,
  saveCloseButtonRef,
}: {
  save: (params: ELNSaveParameters) => void;
  saveStatus: ELNSaveStatus;
  setSaveStatus: Dispatch<SetStateAction<ELNSaveStatus>>;
  autosave: boolean;
  onChange?: () => void;
  initialContent?: RemirrorJSON;
  saveButtonRef?: RefObject<HTMLButtonElement>;
  saveCloseButtonRef?: RefObject<HTMLButtonElement>;
}) => {
  const { changeMode } = useELNRoutes();

  const [currentContent, setCurrentContent] = useState<RemirrorJSON | undefined>(initialContent);

  const helpers = useHelpers();
  const debouncedHelpers = useDebouncedValue(helpers, 300);

  useEffect(() => {
    if (saveButtonRef?.current) {
      saveButtonRef.current.onclick = () => {
        if (!saveStatus.isError) {
          if (saveButtonRef.current) {
            currentContent &&
              currentContent.content &&
              currentContent.content.length > 0 &&
              save({ content: currentContent, setSaveStatus });
          }
        }
        return null;
      };
    }
  }, [currentContent, save, saveButtonRef, saveStatus.isError, setSaveStatus]);

  useEffect(() => {
    if (saveCloseButtonRef?.current) {
      saveCloseButtonRef.current.onclick = (e) => {
        if (!saveStatus.isError) {
          if (saveCloseButtonRef.current) {
            e.stopPropagation();
            currentContent && currentContent.content && currentContent.content.length > 0
              ? save({ content: currentContent, setSaveStatus, onSave: changeMode })
              : changeMode();
          }
        }
        return null;
      };
    }
  }, [currentContent, changeMode, save, saveCloseButtonRef, setSaveStatus, saveStatus.isError]);

  useEffect(() => {
    let saveTimeout: NodeJS.Timeout;
    if (autosave && !saveStatus.isError) {
      if (currentContent) {
        if (!saveStatus.isSaved) {
          saveTimeout = setTimeout(() => {
            // console.log(">> AUTOSAVE: ", new Date().toISOString());
            save({ content: currentContent, setSaveStatus, evaluateVersioning: true });
          }, AUTOSAVE_TIMEOUT_DURATION);
        }
      }
    }
    return () => clearTimeout(saveTimeout);
  }, [currentContent, autosave, save, debouncedHelpers, setSaveStatus, saveStatus.isSaved, saveStatus.isError]);

  const beforeClose = useCallback((ev: BeforeUnloadEvent) => {
    ev.preventDefault();
    return (ev.returnValue = "");
  }, []);

  useEffect(() => {
    if (!saveStatus.isSaved) {
      window.addEventListener("beforeunload", beforeClose);
      return () => window.removeEventListener("beforeunload", beforeClose);
    }
  }, [beforeClose, saveStatus.isSaved]);

  return (
    <>
      <Prompt when={!saveStatus.isSaved} message="You have unsaved changes. Leave without saving?" />
      <OnChangeJSON
        onChange={(json) => {
          // console.log(json);
          setCurrentContent(json);
          if (saveStatus.isSaved || saveStatus.isError) {
            setSaveStatus((prevState) => {
              return { ...prevState, isSaved: false, isError: false };
            });
          }
          onChange && onChange();
        }}
      />
    </>
  );
};
