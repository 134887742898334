import { StringIndexedDict } from "../../../../api/GenericTypes";
import {
  GenericEntityVirtualizedEntitySelectFormFieldProps,
  GenericVirtualizedEntitySelectFormFieldControllerWrapper,
} from "../../../formfields/GenericVirtualizedSelectFormField/GenericVirtualizedEntitySelectFormFieldControllerWrapper";
import { RoleFilters, RoleSortingOptionsConsts, RoleSuggestions, rolesConstants } from "../../../../api/Role";
import { useContext } from "react";
import { SessionContext } from "../../../contexts/SessionContext";

export const RolesVirtualizedSelectForm = <Entity extends StringIndexedDict>(
  props: GenericEntityVirtualizedEntitySelectFormFieldProps<Entity, RoleSuggestions, RoleFilters>
) => {
  const { session } = useContext(SessionContext);

  return (
    <GenericVirtualizedEntitySelectFormFieldControllerWrapper<Entity, RoleSuggestions, RoleFilters>
      {...props}
      filters={{ orderBy: "NAME_ASC", ...props.filters }}
      //   onRowRenderer={(item) => <></>}
      entityConstants={rolesConstants}
      orderByOptions={RoleSortingOptionsConsts}
      allowCreateEntity={session?.permissions.logsadmin && props.allowCreateEntity}
    />
  );
};
