import { useCallback, useMemo, useState } from "react";
import { useExtensionEvent } from "@remirror/react-core";
import { EntityMentionExtension } from "./EntityMentionExtension";
import { EntityMentionAttributes } from "./entityMention-types";
import { ChangeReason, SuggestChangeHandlerProps } from "@remirror/pm/suggest";
import { genericEntityConstants, GenericEntityConstantsEntities } from "../../../../../api/GenericConstants";

interface EntityMentionState<Data extends EntityMentionAttributes = EntityMentionAttributes>
  extends Pick<SuggestChangeHandlerProps, "query" | "text" | "range"> {
  entityTypeId: GenericEntityConstantsEntities;
  reason: ChangeReason;
  command: (attrs: Data) => void;
}

export type EntityMentionOnChangeHandler = (
  handlerState: SuggestChangeHandlerProps,
  command: (attrs: EntityMentionAttributes) => void
) => void;

export function useEntityMentionSuggester() {
  const [state, setState] = useState<EntityMentionState | null>(null);

  const onChange: EntityMentionOnChangeHandler = useCallback((props, command) => {
    const { query, range, name, exitReason, changeReason, text } = props;

    if (exitReason) {
      setState(null);

      return;
    }

    if (!changeReason) {
      return;
    }

    if (!name || !Object.hasOwn(genericEntityConstants, name)) {
      return;
    }

    setState({
      query,
      range,
      entityTypeId: name as GenericEntityConstantsEntities,
      reason: changeReason,
      text,
      command: (attrs: EntityMentionAttributes) => {
        command(attrs);
        setState(null);
      },
    });
  }, []);

  useExtensionEvent(EntityMentionExtension, "onChange", onChange);

  return useMemo(() => ({ state }), [state]);
}
