import { useCallback, useEffect, useState } from "react";
import { TrackMatrix } from "../../ViewerLayout/ViewerLayoutTypes";
import { contourGeneratorType } from "../../ViewerLayout/ViewerTypes";
import { ContourEditor } from "./ContourEditor";
import { TrackSelector } from "./TrackSelector";
import { TrackToolType } from "./ViewerNavigation";

import viewerStyles from "./ViewerNavigation.module.css";

export const ContourTool = ({
  tracks,
  onClose,
  changeTrackSettings,
  selectedTracks,
  setMinHeight,
  setMinWidth,
}: TrackToolType) => {
  const [editTrack, setEditTrack] = useState<string | undefined>();

  useEffect(() => {
    if (selectedTracks.length > 0) {
      // SetEditTrack(selectedTracks[0]);
      // const change = selectedTracks.map((id) => {
      //   return { id: id, parameter: { selected: false } as trackParameterType };
      // });

      // TODO: update changeTrackSettings
      // changeTrackParameter(
      //   selectedTracks.map((id) => {
      //     return { id: id, parameter: { selected: false } as trackParameterType };
      //   })
      // );
    }

    // if (setMinHeight) setMinHeight(160);
    // if (setMinWidth) setMinWidth(440);

    // if (setMinHeight) setMinHeight(500);
    // if (setMinWidth) setMinWidth(440);
  }, []);

  const changeContours = useCallback(
    (contours: contourGeneratorType) => {
      if (editTrack) {
        // TODO: update changeTrackSettings
        // changeTrackParameter([
        //   {
        //     id: editTrack,
        //     parameter: {
        //       contours: contours,
        //     } as trackParameterType,
        //   },
        // ]);
      }
    },
    [editTrack]
  );

  return (
    <div className={viewerStyles.viewerButtonColumn} style={{ flexGrow: 1 }}>
      <TrackSelector
        tracks={tracks}
        selectedTracks={selectedTracks}
        currentTrack={editTrack}
        setCurrentTrack={setEditTrack}
      />
      <div style={{ height: 5 }} />
      <ContourEditor
        color={editTrack ? tracks[editTrack].settings.color : undefined}
        contours={editTrack ? (tracks[editTrack] as TrackMatrix).settings.contourGenerators[0] : undefined}
        setContours={changeContours}
        valueMin={editTrack ? tracks[editTrack].settings?.zoom.z[0] : undefined}
        valueMax={editTrack ? tracks[editTrack].settings?.zoom.z[1] : undefined}
        setMinHeight={setMinHeight}
        setMinWidth={setMinWidth}
        // onSettingsOpen={() => {
        //   if (setMinHeight) setMinHeight(340);
        // }}
        // onSettingsClosed={() => {
        //   if (setMinHeight) setMinHeight(160);
        // }}
      />
    </div>
  );
};
