import { EmptyParameter } from "./EmptyParameter";
import { serializedStringType, serializedBooleanType, serializedNumberType } from "./../PipelineTypes";
import { parameterInputTypes, parameterTypes } from "../PipelineTypes";

type valueType = { name?: string; value: number; min?: number; max?: number; inputType?: string };
type stringType = {
  name?: string;
  value: string | undefined;
  options?: string[];
  inputType?: string;
  readonly?: boolean;
};
type booleanType = { name?: string; value: boolean; inputType?: string };

// export class StringParameter extends EmptyParameter {
export class StringParameter extends EmptyParameter {
  parameterType = parameterTypes.string;

  value: string | undefined;
  options: Set<string>;
  inputType: parameterInputTypes;

  constructor(value?: string | stringType) {
    super(value);
    // console.log("read only", this.name, this.readonly);
    // this.errors = new StackTraces();
    // this.warnings = new StackTraces();
    // const result: stringType = { value: undefined, options: [], inputType: parameterInputTypes.default };
    this.value = undefined;
    this.options = new Set();
    this.inputType = parameterInputTypes.default;
    this.deserialize(value);
  }

  deserialize(value?: string | stringType) {
    // console.log("read only", this.name, this.readonly);
    super.deserialize(value);
    const v = this.parseValue(value);
    this.value = v.value;
    this.options = new Set(v.options);
    this.readonly = v.readonly;
    this.inputType = v.inputType as parameterInputTypes;
  }

  convertToString(value?: any, suffix: string = ""): string | undefined {
    if (value === undefined) return value;

    if (typeof value === "string") return value;
    else {
      this.errors.create({
        id: "",
        component: "StringParameter.parseValue",
        message: `Expected type 'string'${suffix}. (Got type '${typeof value}').`,
      });
    }
    return undefined;
  }

  parseValue(input?: string | stringType): stringType {
    const result: stringType = {
      value: this.value,
      options: Array.from(this.options),
      inputType: this.inputType,
      readonly: this.readonly,
    };

    if (typeof input === "object") {
      const { value, options, inputType, readonly } = input;
      if (readonly !== undefined) result.readonly = readonly;

      if (value) {
        const v = this.convertToString(value, " in the value entry");
        if (v !== undefined) result.value = v;
      }

      if (inputType === parameterInputTypes.options) {
        result.inputType = inputType;
        if (options) {
          if (Array.isArray(options)) {
            const types = Array.from(new Set(options.map((o) => typeof o).filter((t) => t !== "string")));
            if (types.length > 0) {
              this.errors.create({
                id: "",
                component: "StringParameter.parseValue",
                message: `Field 'options' must be a string list. (Got elements of type '${types.join(", ")}').`,
              });
            } else {
              result.options = options;
            }
          } else {
            this.errors.create({
              id: "",
              component: "StringParameter.parseValue",
              message: `Field 'options' must be a string list. (Got type '${typeof options}').`,
            });
          }
        } else {
          this.errors.create({
            id: "",
            component: "StringParameter.parseValue",
            message: `Field 'options' must be defined for input type '${inputType}'.`,
          });
        }
      } else if (inputType === parameterInputTypes.default || inputType === undefined) {
        result.inputType = inputType;
      } else {
        result.inputType = parameterInputTypes.default;
        this.errors.create({
          id: "",
          component: "StringParameter.parseValue",
          message: `Unknown input type '${inputType}'`,
        });
      }
    } else {
      const v = this.convertToString(input);
      if (v !== undefined) result.value = v;
    }

    return result;
  }

  serialize(): serializedStringType {
    // const result = serializeCommandParameter(this) as serializedStringType;
    const result = super.serialize() as serializedStringType;
    Object.assign(result, { value: this.value, options: Array.from(this.options) });
    return result;
  }
}

export class BooleanParameter extends EmptyParameter {
  name: string = "boolean";
  parameterType = parameterTypes.boolean;
  inputType: parameterInputTypes;
  value: boolean;

  constructor(value?: boolean | string) {
    super(value);
    // const result: booleanType = { value: false, inputType: parameterInputTypes.default };
    this.value = false;
    this.inputType = parameterInputTypes.default;
    this.deserialize(value);
  }

  deserialize(value?: number | string | boolean | booleanType) {
    super.deserialize(value);
    const v = this.parseValue(value);
    this.value = v.value;
    this.inputType = v.inputType as parameterInputTypes;
  }

  convertToBoolean(value?: any, suffix: string = ""): boolean | undefined {
    if (value === undefined) return value;

    if (typeof value === "boolean" || typeof value === "number" || typeof value === "string") return !!value;
    else {
      this.errors.create({
        id: "",
        component: "BooleanParameter.parseValue",
        message: `Expected type 'boolean'${suffix}. (Got type '${typeof value}').`,
      });
    }
    return undefined;
  }

  parseValue(input?: number | string | boolean | booleanType): booleanType {
    const result: booleanType = { value: this.value, inputType: this.inputType };

    if (typeof input === "object") {
      const { value } = input;

      const v = this.convertToBoolean(value, " in the value entry");
      if (v !== undefined) result.value = v;
    } else {
      const v = this.convertToBoolean(input);
      if (v !== undefined) result.value = v;
    }
    return result;
  }

  serialize(): serializedBooleanType {
    // const result = serializeCommandParameter(this) as serializedBooleanType;
    const result = super.serialize() as serializedBooleanType;
    Object.assign(result, { value: this.value });
    return result;
  }
}

export class IntParameter extends EmptyParameter {
  parameterType = parameterTypes.int;
  value: number;
  min?: number;
  max?: number;
  inputType: parameterInputTypes;

  constructor(value?: number | valueType) {
    super(value);
    // const result: valueType = { value: 0, min: -Infinity, max: Infinity, inputType: this.inputType };
    this.value = 0;
    this.min = -Infinity;
    this.max = Infinity;
    this.inputType = parameterInputTypes.default;
    this.deserialize(value);
  }

  deserialize(value?: number | valueType) {
    super.deserialize(value);
    const v = this.parseValue(value);
    this.value = v.value;
    this.min = v.min;
    this.max = v.max;
    this.inputType = v.inputType as parameterInputTypes;

    this.checkValues();
  }

  checkValues() {
    if (this.min !== undefined && this.max !== undefined) {
      const diffMin = 0.00001;
      const diff = this.max - this.min;
      if (diff < diffMin) {
        this.errors.create({
          id: "",
          component: "IntParameter.check",
          message: `Min parameter ${this.min} muss be smaller than the max ${this.max} parameter.`,
        });
        return;
      }

      if (this.value < this.min) {
        this.warnings.create({
          id: "",
          component: "IntParameter.check",
          message: `Value is smaller than the min parameter (${this.value} < ${this.min}). (Value set to ${this.min})`,
        });
        this.value = this.min;
        return;
      }
      if (this.value > this.max) {
        this.warnings.create({
          id: "",
          component: "IntParameter.check",
          message: `Value is bigger than the max parameter (${this.value} > ${this.max}). (Value set to ${this.max})`,
        });
        this.value = this.max;
        return;
      }
    }
  }

  convertToInt(value?: any, suffix: string = ""): number | undefined {
    if (value === undefined) return value;

    if (typeof value === "number") return Math.floor(value);
    else {
      this.errors.create({
        id: "",
        component: "IntParameter.parseValue",
        message: `Expected type 'integer'${suffix}. (Got type '${typeof value}').`,
      });
    }
    return undefined;
  }

  parseValue(value?: number | valueType): valueType {
    const result: valueType = { value: this.value, min: this.min, max: this.max, inputType: this.inputType };

    if (typeof value === "object") {
      if ("min" in value) {
        const v = this.convertToInt(value.min, " in the min entry");
        if (v !== undefined) result.min = v;
      }
      if ("max" in value) {
        const v = this.convertToInt(value.max, " in the min entry");
        if (v !== undefined) result.max = v;
      }
      if ("value" in value) {
        const v = this.convertToInt(value.value, " in the value entry");
        if (v !== undefined) result.value = v;
      } else if (result.min && isFinite(result.min)) {
        result.value = result.min;
      }

      if (value.inputType) {
        result.inputType = value.inputType;
        if (value.inputType === parameterInputTypes.default || value.inputType === parameterInputTypes.xPick) {
        } else if (value.inputType === parameterInputTypes.range) {
          // if (!isFinite(result.min as number))
          //   this.errors.create({
          //     id: "",
          //     component: "IntParameter.parseValue",
          //     message: `Min value must be defined for input type '${value.inputType}'.`,
          //   });
          // if (!isFinite(result.max as number))
          //   this.errors.create({
          //     id: "",
          //     component: "IntParameter.parseValue",
          //     message: `Max value must be defined for input type '${value.inputType}'.`,
          //   });
        } else {
          result.inputType = parameterInputTypes.default;
          this.errors.create({
            id: "",
            component: "IntParameter.parseValue",
            message: `Unknown input type '${value.inputType}'`,
          });
        }
      }
    } else {
      const v = this.convertToInt(value);

      if (v !== undefined) result.value = v;
    }

    return result;
  }

  serialize(): serializedNumberType {
    const result = super.serialize() as serializedNumberType;
    Object.assign(result, { value: this.value, min: this.min, max: this.max });
    if (!isFinite(result.min ?? Infinity)) delete result.min;
    if (!isFinite(result.max ?? Infinity)) delete result.max;
    return result;
  }
}

export class FloatParameter extends EmptyParameter {
  parameterType = parameterTypes.float;
  value: number;
  min?: number;
  max?: number;
  inputType: parameterInputTypes;

  constructor(value?: number | valueType) {
    super(value);
    this.value = 0;
    this.min = -Infinity;
    this.max = Infinity;
    this.inputType = parameterInputTypes.default;
    this.deserialize(value);
    // const v = this.parseValue(value);
    // this.value = v.value;
    // this.min = v.min;
    // this.max = v.max;
    // this.inputType = v.inputType as parameterInputTypes;

    // this.checkValues();
  }

  deserialize(value?: number | valueType) {
    // console.log("FloatParameter", value);

    super.deserialize(value);
    const v = this.parseValue(value);
    this.value = v.value;
    this.min = v.min;
    this.max = v.max;
    this.inputType = v.inputType as parameterInputTypes;

    this.checkValues();
  }

  checkValues() {
    if (this.min !== undefined && this.max !== undefined) {
      const diffMin = 0.00001;
      const diff = this.max - this.min;
      if (diff < diffMin) {
        this.errors.create({
          id: "",
          component: "FloatParameter.check",
          message: `Min parameter ${this.min} muss be smaller than the max ${this.max} parameter.`,
        });
        return;
      }
      if (this.value < this.min) {
        this.warnings.create({
          id: "",
          component: "FloatParameter.check",
          message: `Value is smaller than the min parameter (${this.value} < ${this.min}). (Value set to ${this.min})`,
        });
        this.value = this.min;
        return;
      }
      if (this.value > this.max) {
        this.warnings.create({
          id: "",
          component: "FloatParameter.check",
          message: `Value is bigger than the max parameter (${this.value} > ${this.max}). (Value set to ${this.max})`,
        });
        this.value = this.max;
        return;
      }
    }
  }

  convertToFloat(value?: any, suffix: string = ""): number | undefined {
    if (value === undefined) return value;

    if (typeof value === "number") return value;
    else if (typeof value === "string" && value === "Infinity") return Infinity;
    // else if (typeof value === "string" && value === "NaN") return NaN;
    else if (typeof value === "string" && value === "-Infinity") return -Infinity;
    else {
      this.errors.create({
        id: "",
        component: "FloatParameter.parseValue",
        message: `Expected type 'float'${suffix}. (Got type '${typeof value}').`,
      });
    }
    return undefined;
  }

  parseValue(value?: number | valueType): valueType {
    const result: valueType = { value: this.value, min: this.min, max: this.max, inputType: this.inputType };

    if (typeof value === "object") {
      if ("min" in value) {
        const v = this.convertToFloat(value.min, " in the min entry");
        if (v !== undefined) result.min = v;
      }
      if ("max" in value) {
        const v = this.convertToFloat(value.max, " in the min entry");
        if (v !== undefined) result.max = v;
      }

      if ("value" in value) {
        const v = this.convertToFloat(value.value, " in the value entry");
        if (v !== undefined) result.value = v;
      } else if (result.min && isFinite(result.min)) {
        result.value = result.min;
      }
      // console.log(" >", result.value, "value" in value, "<-", result.min, result.max);

      if (value.inputType) {
        result.inputType = value.inputType;
        if (value.inputType === parameterInputTypes.default || value.inputType === parameterInputTypes.xPick) {
        } else if (value.inputType === parameterInputTypes.range) {
        } else {
          result.inputType = parameterInputTypes.default;
          this.errors.create({
            id: "",
            component: "FloatParameter.parseValue",
            message: `Unknown input type '${value.inputType}'`,
          });
        }
      }
    } else {
      const v = this.convertToFloat(value);
      if (v !== undefined) result.value = v;
    }

    return result;
  }

  serialize(): serializedNumberType {
    const result = super.serialize() as serializedNumberType;
    Object.assign(result, { value: this.value ?? "", min: this.min, max: this.max });

    if (!isFinite(result.min ?? Infinity)) delete result.min;
    if (!isFinite(result.max ?? Infinity)) delete result.max;
    return result;
  }
}
