import { TreeElement } from "./TreeElement";
import { TreeViewerProps } from "./TreeViewerTypes";

export const TreeViewer = function (props: TreeViewerProps) {
  return (
    <div>
      <TreeElement {...props}>{props.children}</TreeElement>
    </div>
  );
};
