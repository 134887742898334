import React, { CSSProperties, useEffect, useState } from "react";
import { FormControl } from "react-bootstrap";
import { useInputDebounce } from "../InputDebounce";

import styles from "./ValidatedInput.module.css";

type stateType = { state: boolean; value: number };
const validateValue = (value: string, validate?: (value: number) => boolean, useInteger?: boolean): stateType => {
  let f = parseFloat(value);
  const state: stateType = { value: f, state: true };
  if (isNaN(f)) state.state = false;
  else if (validate) state.state = validate(f);

  if (useInteger && Math.abs(f - Math.floor(f)) > Number.EPSILON) state.state = false;

  return state;
};

const convert = (value: number, digit?: number): string => {
  // if (digit === undefined) digit = 1;
  // console.log("convert", digit, "" + value.toFixed(digit));
  // return "" + value.toFixed(digit);

  return "" + (digit === undefined ? value : value.toFixed(digit));
};

export const ValidatedInput = ({
  value,
  setValue,
  validate,
  digit,
  style,
  useInteger,
  className,
}: {
  value: number;
  validate?: (value: number) => boolean;
  setValue?: (value: number) => void;
  digit?: number;
  style?: CSSProperties;
  useInteger?: boolean;
  className?: string;
}) => {
  const [input, setInput] = useState<string>(convert(value, useInteger ? 0 : digit));
  const [valid, setValid] = useState<boolean>(true);
  const [internalValue, setInternalValue] = useState<number>(value);
  const debouncedInput = useInputDebounce<string>(input, 500);

  useEffect(() => {
    if (Math.abs(value - internalValue) > Number.EPSILON) {
      const newInput = convert(value, useInteger ? 0 : digit);
      if (newInput !== input) setInput(newInput);
    }
    setInternalValue(value);
  }, [value, digit, input, internalValue, useInteger]);

  useEffect(() => {
    const valid = validateValue(input, validate, useInteger);
    // console.log("valid", valid);
    setValid(valid.state);
    if (valid.state && Math.abs(valid.value - internalValue) > Number.EPSILON) {
      setInternalValue(valid.value);
      if (setValue) setValue(valid.value);
    }
    // if (valid.state && setValue) setValue(valid.value);
  }, [debouncedInput, validate, useInteger, input, internalValue, setValue]);

  return (
    <FormControl
      type="text"
      value={input}
      onChange={(e) => {
        // console.log("set value", (e.target as HTMLInputElement).value);
        setInput((e.target as HTMLInputElement).value);
      }}
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          setInput((e.target as HTMLInputElement).value);
          e.preventDefault();
        }
      }}
      onFocus={(e) => (e.target as any as HTMLInputElement).select()}
      style={Object.assign({ background: valid ? undefined : "salmon" }, style ?? {})}
      className={className ?? styles.formControlXS}
    />
  );
};
