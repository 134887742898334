var replace = (line: string): (string | JSX.Element)[] => {
  const leadingSpaces = line.match(/^ +/);
  const spaceElements = leadingSpaces ? Array(leadingSpaces[0].length).fill(<>&nbsp;</>) : [];
  return [...spaceElements, line.trimStart()];
};

export const FormattedMultiline = ({ children }: { children: string }) => {
  const lines = children.split("\n");

  // const result: any[] = [];
  // lines.forEach((line, i) => {
  //   result.push(line);
  //   result.push(<br key={i} />);
  // });

  const result = lines.map((line, i) => [replace(line), <br key={i} />]).flat();

  result.pop();

  return <div>{result}</div>;
};
