import { CSSProperties, Dispatch, SetStateAction, useContext, useEffect, useMemo, useState } from "react";
import { SearchInput } from "../../../../common/forms/SearchInput/SearchInput";
import { LucideIcon, IconNames } from "../../../../common/icon/LucideIcon";
import { LoadingWrapper } from "../../../../common/LoadingWrapper";
import { Tile } from "../../../../common/panels/Tile/Tile";
import { useDebouncedValue } from "../../../../common/helperfunctions/useDebouncedValue";
import { ELNModes } from "../../../ELNRouter/ELNRouter";
import { useELNRoutes } from "../../../ELNRouter/useELNRoutes";
import styles from "./LabNotebookTocTile.module.css";
import {
  labNotebookEntriesConstants,
  LabNotebookEntry,
  LabNotebookEntryFilters,
} from "../../../types/LabNotebookEntry";
// import { LabNotebookEntriesFilterBar } from "../../../LabNotebookEntries/LabNotebookEntriesFilterBar";
import {
  LabNotebookEntryWithTocEntries,
  LabNotebookEntryWithTocEntryFilters,
  LabNotebookTocEntryTypes,
  TOC_HEADER_TYPES,
  TOC_TYPES,
  TOC_TYPES_TRANSLATOR,
} from "../../../types/LabNotebookTocEntry";
import {
  LabNotebookExperiment,
  labNotebookExperimentFieldLabels,
  LabNotebookExperimentFilters,
  labNotebookExperimentsConstants,
} from "../../../types/LabNotebookExperiment";
import { useEntityDetail, usePost, useUnpaginateOrdered } from "../../../../api/BaseEntityApi";
import { LabNotebook, LabNotebookFilters, labNoteBooksConstants } from "../../../types/LabNotebook";
import { IEntityMinimalModel } from "../../../../api/GenericTypes";
import { Modal } from "../../ELNModal/ELNModal";
import { LabNotebookEntryViewOnly } from "../../../LabNotebookEntry/LabNotebookEntryViewOnly/LabNotebookEntryViewOnly";
import { ToggleButtonComponent } from "../../../../ViewerUIElements/ToggleButtonComponent";
import { Alert } from "../../../../common/overlays/Alert/Alert";
import { Skeleton } from "../../../../common/loaders/Skeleton/Skeleton";
import { Link } from "react-router-dom";
import { CurrentIndicator } from "../../../../common/badges/CurrentIndicator/CurrentIndicator";
import { datasetsConstants } from "../../../../api/Datasets";
import { samplesConstants } from "../../../../api/Samples";
import { ExclusiveDropdown } from "../../../../common/buttons/ExclusiveDropdown/ExclusiveDropdown";
import { useLocalStorage } from "../../../../common/helperfunctions/useLocalStorage";
import { LabNotebookDetailContentTable } from "../../../LabNotebooks/LabNotebookDetailContentTable";
import {
  LabNotebookEntriesFilterBar,
  LabNotebookEntriesFilterForm,
} from "../../../LabNotebookEntries/Table/LabNotebookEntriesFilterBar";
import { LabNotebookExperimentDetailContentTable } from "../../../LabNotebookExperiments/LabNotebookExperimentDetailContentTable";
import { getEditLink } from "../../../../main/Routing";
import { SessionContext } from "../../../../common/contexts/SessionContext";
import { LinkButton } from "../../../../common/buttons/LinkButton/LinkButton";
import { inventoryItemsConstants } from "../../../../api/Inventories";
import { Button } from "../../../../common/buttons/Button/Button";
import { showtoast } from "../../../../common/overlays/Toasts/showtoast";
import { useQueryClient } from "@tanstack/react-query";
import { useTabStore } from "../../../../common/tables/Tabs/useTabStore";
import { TableTabsDict } from "../../../../common/tables/Tabs/TableTabsTypes";
import { personsConstants } from "../../../../api/Person";
import { projectsConstants } from "../../../../api/Projects";
import { getNotebookEntryStringLabel } from "../../../LabNotebookEntry/LabNotebookEntry";

export const LAB_NOTEBOOK_TOC_QUERY_KEY = "lab_notebook_entries/toc";

interface EntriesPerExperiment {
  [key: number]: LabNotebookEntryWithTocEntries[];
}

export const LabNotebookTocTile = ({
  labNotebookId,
  labNotebookExperimentId,
  labNotebookEntryId,
  title = "Table of content",
  mode = "view",
  activeLabNotebookBlockId,
  filters = {},
  setFilters,
}: {
  labNotebookId: number;
  labNotebookExperimentId?: number;
  labNotebookEntryId?: number;
  title?: string;
  mode?: ELNModes;
  activeLabNotebookBlockId?: string;
  filters?: LabNotebookEntryFilters;
  setFilters: Dispatch<SetStateAction<LabNotebookEntryFilters>>;
}) => {
  const { route, api } = useContext(SessionContext);
  const { setELNRoute, getELNRoute } = useELNRoutes();
  const queryClient = useQueryClient();

  const [searchValue, setSearchValue] = useState<string>("");
  const debouncedSearchValue = useDebouncedValue(searchValue, 300);

  const [showPreview, setShowPreview] = useState<number | undefined>();
  const [showLabNotebookDetails, setShowLabNotebookDetails] = useState<boolean>(false);
  const [showLabNotebookExperimentDetails, setShowExperimentDetails] = useState<number | undefined>();

  const { data: labNotebookExperimentPreview } = useEntityDetail<LabNotebookExperiment>(
    "lab_notebook_experiments",
    showLabNotebookExperimentDetails || 0,
    {},
    { enabled: !!showLabNotebookExperimentDetails }
  );

  useEffect(() => {
    setShowPreview(undefined);
  }, [labNotebookId, labNotebookExperimentId, labNotebookEntryId]);

  const {
    data: preview,
    isFetching: isFetchingPreview,
    error: errorPreview,
    status: statusPreview,
    fetchStatus: fetchStatusPreview,
  } = useEntityDetail<LabNotebookEntry, LabNotebookEntryFilters>(
    "lab_notebook_entries",
    showPreview || 0,
    { includeContent: true },
    { enabled: !!showPreview }
  );

  const { value: initialGroupByExperiment, setValue: setInitialGroupByExperiment } = useLocalStorage<boolean>(
    "ElnGroupByExperiment",
    true
  );
  const { value: initialGroupByEntry, setValue: setInitialGroupByEntry } = useLocalStorage<boolean>(
    "ElnGroupByEntry",
    false
  );
  const { value: initialBlockTypes, setValue: setInitialBlockTypes } = useLocalStorage<LabNotebookTocEntryTypes[]>(
    "ElnBlockTypes",
    TOC_HEADER_TYPES
  );

  const [groupByExperiment, setGroupByExperiment] = useState<boolean>(initialGroupByExperiment);
  const [groupByEntry, setGroupByEntry] = useState<boolean>(initialGroupByEntry);

  useEffect(() => {
    if (!groupByExperiment) setFilters((prevState) => ({ ...prevState, orderBy: "ENTRY_DATE_ASC" }));
    if (groupByExperiment) setFilters((prevState) => ({ ...prevState, orderBy: "LAB_NOTEBOOK_EXPERIMENT_NAME_ASC" }));
  }, [groupByEntry, groupByExperiment, setFilters]);

  // const [focus, setFocus] = useState<boolean>(false);
  const [focus] = useState<boolean>(false);
  const [showTocSettings, setShowTocSettings] = useState<boolean>(false);
  const [showBlockTypes, setShowBlockTypes] = useState<LabNotebookTocEntryTypes[]>(initialBlockTypes);

  useEffect(() => {
    setInitialGroupByExperiment(groupByExperiment);
  }, [groupByExperiment, setInitialGroupByExperiment]);

  useEffect(() => {
    setInitialGroupByEntry(groupByEntry);
  }, [groupByEntry, setInitialGroupByEntry]);

  useEffect(() => {
    setInitialBlockTypes(showBlockTypes);
  }, [showBlockTypes, setInitialBlockTypes]);

  const activeFiltersCount = useMemo(() => {
    return Object.keys(filters).filter(
      (e) => !!(filters as any)[e] && e !== "orderBy" && e !== "pageSize" && e !== "page" && e !== "searchTerm"
    ).length;
  }, [filters]);

  const {
    data: labNotebook,
    isFetching: isFetchingLabNotebook,
    status: statusLabNotebook,
    fetchStatus: fetchStatusLabNotebook,
    error: errorLabNotebook,
  } = useEntityDetail<LabNotebook, LabNotebookFilters>("lab_notebooks", labNotebookId, {
    includeSoftDeleted: filters.includeSoftDeleted,
  });

  const {
    data: experimentsData,
    isFetching: isFetchingExperiments,
    error: errorExperiments,
    status: statusExperiments,
    fetchStatus: fetchStatusExperiments,
  } = useUnpaginateOrdered<LabNotebookExperiment, LabNotebookExperimentFilters>("lab_notebook_experiments", {
    labNotebookIds: [labNotebookId],
    orderBy: "NAME_ASC",
    includeSoftDeleted: filters.includeSoftDeleted,
  });

  const tabStoreDefaults: TableTabsDict<LabNotebookEntry, LabNotebookEntryFilters, LabNotebookEntriesFilterForm> =
    useMemo(
      () => ({
        default: {
          tabId: "default",
          type: "fixed",
          label: "All",
          title: "All",
          icon: "house",
          align: "left",
          xPos: 0,
          settings: {
            columnSettings: {},
            columnWidths: {},
            filters: filters,
            sidebarFilters: {},
          },
          forcedSettings: {
            columnSettings: {},
            columnWidths: {},
            filters: {},
            sidebarFilters: {},
          },
        },
      }),
      [filters]
    );

  const {
    filters: tabStoreFilters,
    sidebarFilters,
    dispatchTabStore,
    currentTab,
    tabsLoading,
  } = useTabStore<LabNotebookEntry, LabNotebookEntryFilters, LabNotebookEntriesFilterForm>({
    resource: labNotebookEntriesConstants.resource,
    defaults: tabStoreDefaults,
    keyOverride: "toc-labNotebookEntries",
  });

  const {
    data: tocEntriesData,
    isFetching: isFetchingTocEntries,
    error: errorTocEntries,
    status: statusTocEntries,
    fetchStatus: fetchStatusTocEntries,
  } = usePost<LabNotebookEntryWithTocEntries[], LabNotebookEntryWithTocEntryFilters>(
    LAB_NOTEBOOK_TOC_QUERY_KEY,
    {
      ...tabStoreFilters,
      labNotebookIds: [labNotebookId],
      searchTerm: debouncedSearchValue,
      blockTypes: showBlockTypes,
    },
    {}
  );

  const tocEntries = useMemo(() => {
    if (!tocEntriesData) return [];
    if (!focus) return tocEntriesData;
    return tocEntriesData.filter(
      (t) =>
        (!labNotebookExperimentId || t.labNotebookEntry.labNotebookExperiment.id === labNotebookExperimentId) &&
        (!labNotebookEntryId || t.labNotebookEntry.id === labNotebookEntryId)
    );
  }, [labNotebookEntryId, labNotebookExperimentId, focus, tocEntriesData]);
  const debouncedTocEntries = useDebouncedValue(tocEntries, 300);

  const activeEntry = useMemo(() => {
    return debouncedTocEntries.find((t) => t.labNotebookEntry.id === labNotebookEntryId);
  }, [labNotebookEntryId, debouncedTocEntries]);

  const experiments: IEntityMinimalModel[] = useMemo(() => {
    if (!experimentsData) return [];
    if (!focus) return experimentsData;
    return experimentsData.filter(
      (e) =>
        (!labNotebookExperimentId || e.id === labNotebookExperimentId) &&
        (!labNotebookEntryId || e.id === activeEntry?.labNotebookEntry.labNotebookExperiment.id)
    );
  }, [
    activeEntry?.labNotebookEntry.labNotebookExperiment.id,
    labNotebookEntryId,
    labNotebookExperimentId,
    experimentsData,
    focus,
  ]);

  const tocEntriesPerExperiment = useMemo(() => {
    if (experiments) {
      var dictionary: EntriesPerExperiment = {};
      experiments.forEach((e) => {
        const entries = tocEntries.filter((t) => {
          return t.labNotebookEntry.labNotebookExperiment.id === e.id;
        });
        if (!((debouncedSearchValue || activeFiltersCount > 0) && entries.length === 0)) dictionary[e.id] = entries;
      });
      return dictionary;
    }
    return {};
  }, [activeFiltersCount, debouncedSearchValue, experiments, tocEntries]);

  const [showFilters, setShowFilters] = useState<boolean>(false);

  const [recreatingToc, setRecreatingToc] = useState<boolean>(false);

  const hasActiveFilters = useMemo(() => {
    return (
      !!tabStoreFilters.labNotebookExperimentIds?.length ||
      !!tabStoreFilters.entryDateFrom ||
      !!tabStoreFilters.entryDateTo ||
      !!tabStoreFilters.createdByIds?.length ||
      !!tabStoreFilters.createdFrom ||
      !!tabStoreFilters.createdTo ||
      !!tabStoreFilters.modifiedByIds?.length ||
      !!tabStoreFilters.modifiedFrom ||
      !!tabStoreFilters.modifiedTo ||
      !!tabStoreFilters.includeSoftDeleted ||
      !!tabStoreFilters.isSoftDeleted
    );
  }, [tabStoreFilters]);

  return (
    <>
      <Tile foldable={false}>
        <Tile.Head title="">
          <SearchInput searchValue={searchValue} setSearchValue={setSearchValue} />
          <ExclusiveDropdown
            show={showFilters}
            setShow={setShowFilters}
            drop="down-left"
            btnCls={`btn btn-sm btn-ghost-${hasActiveFilters ? "warning" : "secondary"}`}
            icon={<LucideIcon name="filter" />}
            backdropStyle={{ backgroundColor: "transparent", backdropFilter: "none" }}
            title={hasActiveFilters ? "Active filters" : "Filters"}
          >
            <div
              style={{
                display: "flex",
                padding: "0 5px",
                marginTop: "10px",
                maxHeight: "300px",
                width: "25vw",
                maxWidth: "500px",
              }}
            >
              <LabNotebookEntriesFilterBar
                initialValues={sidebarFilters}
                dispatchTabStore={dispatchTabStore}
                tabsLoading={tabsLoading}
                currentTab={currentTab}
                labNotebookId={labNotebookId}
              />
            </div>
          </ExclusiveDropdown>
          <ExclusiveDropdown
            title="Table of content settings"
            drop="down-left"
            btnCls="btn btn-sm btn-ghost-secondary"
            show={showTocSettings}
            setShow={setShowTocSettings}
            backdropStyle={{ backgroundColor: "transparent", backdropFilter: "none" }}
            icon={<LucideIcon name="columns-2" />}
          >
            <div onClick={(e) => e.stopPropagation()}>
              <div className={styles.tocControls}>
                <div style={{ padding: "5px 0", fontSize: "12px", fontWeight: 600 }}>Group by:</div>
                <ToggleButtonComponent
                  checked={groupByExperiment}
                  setChecked={setGroupByExperiment}
                  style={{ cursor: "pointer" }}
                >
                  <span style={{ whiteSpace: "nowrap", fontSize: 12 }}> Experiment</span>
                </ToggleButtonComponent>
                <ToggleButtonComponent
                  checked={groupByEntry}
                  setChecked={setGroupByEntry}
                  style={{ cursor: "pointer" }}
                >
                  <span style={{ whiteSpace: "nowrap", fontSize: 12 }}> Entry</span>
                </ToggleButtonComponent>
              </div>
              <hr style={{ margin: "5px 0", marginTop: "10px" }} />
              <div>
                <div style={{ padding: "5px", fontSize: "12px", fontWeight: 600 }}>Show blocks:</div>
                <div style={{ maxHeight: "175px", overflow: "auto", padding: "0 5px" }}>
                  {TOC_TYPES.map((type) => (
                    <button
                      key={type}
                      style={{ margin: 0 }}
                      className="btn btn-block btn-xs btn-ghost-secondary"
                      onClick={(e) =>
                        setShowBlockTypes((prevState) =>
                          prevState.includes(type) ? prevState.filter((x) => x !== type) : [...prevState, type]
                        )
                      }
                    >
                      <div
                        style={{
                          textAlign: "left",
                          display: "flex",
                          alignItems: "center",
                          gap: "10px",
                        }}
                      >
                        <input
                          style={{ marginBottom: "4px" }}
                          type="checkbox"
                          className="form-check-input"
                          checked={!!showBlockTypes.find((x) => x === type)}
                          onChange={() => {}}
                        />
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "5px",
                            justifyContent: "space-between",
                            width: "100%",
                          }}
                        >
                          <div>{TOC_TYPES_TRANSLATOR[type]}</div>
                          <div>{blockTypeToIcon(type)}</div>
                        </div>
                      </div>
                    </button>
                  ))}
                </div>
              </div>
            </div>
          </ExclusiveDropdown>
          <Button
            title="Refresh table of content"
            disabled={recreatingToc}
            className="btn btn-sm btn-ghost-secondary"
            onClick={async () => {
              setRecreatingToc(true);
              try {
                await api.post(`${LAB_NOTEBOOK_TOC_QUERY_KEY}/create`, {
                  ...filters,
                  labNotebookIds: [labNotebookId],
                  searchTerm: debouncedSearchValue,
                  blockTypes: showBlockTypes,
                });
                queryClient.invalidateQueries({ queryKey: [LAB_NOTEBOOK_TOC_QUERY_KEY] });
              } catch (error) {
                console.error(error);
                showtoast("error", "An error occurred while creating the table of content.");
              }
              setRecreatingToc(false);
            }}
          >
            <LucideIcon name="refresh-ccw" />
          </Button>
        </Tile.Head>
        <Tile.Body>
          {labNotebook && (
            <div
              style={{
                overflowY: "auto",
                height: "100%",
                maxHeight: "calc(100vh - 315px)",
                minHeight: "200px",
                width: "100%",
              }}
            >
              <LoadingWrapper
                status={isFetchingLabNotebook ? "loading" : statusLabNotebook}
                fetchStatus={isFetchingLabNotebook ? "fetching" : fetchStatusLabNotebook}
                error={errorLabNotebook}
              >
                <FoldableTocEntry
                  id={`ELN_TOC_NOTEBOOK_${labNotebook.id}`}
                  label={labNotebook.name}
                  tooltip={labNotebook.name}
                  icon={labNotebook.status !== "CLOSED" ? "book-open" : "book"}
                  entryColor="primary"
                  isInitiallyOpen
                  controls={[
                    {
                      icon: "info",
                      tooltip: "Show details",
                      onClick: () => setShowLabNotebookDetails(true),
                      color: "ghost-secondary",
                    },
                    {
                      icon: "arrow-right",
                      onClick: () => {
                        setELNRoute({ labNotebookId: labNotebook.id });
                      },
                      color: "ghost-secondary",
                      tooltip: "View notebook",
                      link: getELNRoute({ labNotebookId: labNotebook.id }),
                    },
                  ]}
                  active={labNotebookId === labNotebook.id}
                  style={{
                    position: "sticky",
                    top: 0,
                    left: 0,
                    right: 0,
                    zIndex: 100,
                  }}
                >
                  <>
                    {!debouncedTocEntries.length && (isFetchingTocEntries || isFetchingExperiments) && (
                      <div style={{ width: "100%" }}>
                        <Skeleton type="rows" n={10} />
                      </div>
                    )}
                    {groupByExperiment ? (
                      <LoadingWrapper
                        status={statusExperiments}
                        fetchStatus={fetchStatusExperiments}
                        error={errorExperiments}
                      >
                        {experiments &&
                          experiments
                            .filter((e) => !!tocEntriesPerExperiment[e.id])
                            .map((e, index) => (
                              <FoldableTocEntry
                                id={`ELN_TOC_EXPERIMENT_${e.id}`}
                                label={e.name}
                                foldable={!!tocEntriesPerExperiment[e.id].length}
                                tooltip={`${e.name}`}
                                icon="file-text"
                                entryColor="secondary"
                                controls={[
                                  {
                                    icon: "info",
                                    tooltip: "Show details",
                                    onClick: () => setShowExperimentDetails(e.id),
                                    color: "ghost-secondary",
                                  },
                                  {
                                    icon: "arrow-right",
                                    onClick: () => {
                                      setELNRoute({ labNotebookId, labNotebookExperimentId: e.id });
                                    },
                                    color: "ghost-secondary",
                                    tooltip: "View experiment",
                                    link: getELNRoute({ labNotebookId, labNotebookExperimentId: e.id }),
                                  },
                                ]}
                                active={
                                  labNotebookExperimentId === e.id ||
                                  activeEntry?.labNotebookEntry.labNotebookExperiment.id === e.id
                                }
                                key={index}
                                style={{
                                  position: "sticky",
                                  top: 31,
                                  left: 0,
                                  right: 0,
                                  zIndex: 99,
                                }}
                              >
                                <LoadingWrapper
                                  status={statusTocEntries}
                                  fetchStatus={fetchStatusTocEntries}
                                  error={errorTocEntries}
                                >
                                  {tocEntriesPerExperiment[e.id].map((t, i) => {
                                    return groupByEntry ? (
                                      <FoldableTocEntry
                                        id={`ELN_TOC_ENTRY_${t.labNotebookEntry.id}`}
                                        label={getNotebookEntryStringLabel({ entry: t.labNotebookEntry })}
                                        // count={t.tocEntries.length}
                                        foldable={!!t.tocEntries.length}
                                        tooltip={t.labNotebookEntry.name}
                                        icon="pencil"
                                        entryColor="success"
                                        controls={[
                                          {
                                            disabled: labNotebookEntryId === t.labNotebookEntry.id,
                                            icon: "zoom-in",
                                            onClick: () => setShowPreview(t.labNotebookEntry.id),
                                            color: "ghost-secondary",
                                            tooltip: "Preview entry",
                                          },
                                          {
                                            icon: "arrow-right",
                                            onClick: () =>
                                              setELNRoute({
                                                labNotebookId: t.labNotebookEntry.labNotebook.id,
                                                labNotebookExperimentId: t.labNotebookEntry.labNotebookExperiment.id,
                                                labNotebookEntryId: t.labNotebookEntry.id,
                                              }),
                                            color: "ghost-secondary",
                                            tooltip: "View entry",
                                            disabled: labNotebookEntryId === t.labNotebookEntry.id,
                                            link: getELNRoute({
                                              labNotebookId: t.labNotebookEntry.labNotebook.id,
                                              labNotebookExperimentId: t.labNotebookEntry.labNotebookExperiment.id,
                                              labNotebookEntryId: t.labNotebookEntry.id,
                                            }),
                                          },
                                        ]}
                                        active={labNotebookEntryId === t.labNotebookEntry.id}
                                        key={i}
                                        style={{
                                          position: "sticky",
                                          top: 62,
                                          left: 0,
                                          right: 0,
                                          zIndex: 98,
                                        }}
                                      >
                                        <LabNotebookTocEntry
                                          tocEntry={t}
                                          mode={mode}
                                          labNotebookEntryId={labNotebookEntryId}
                                          labNotebookExperimentId={labNotebookExperimentId}
                                        />
                                      </FoldableTocEntry>
                                    ) : (
                                      <LabNotebookTocEntry
                                        tocEntry={t}
                                        mode={mode}
                                        labNotebookEntryId={labNotebookEntryId}
                                        labNotebookExperimentId={labNotebookExperimentId}
                                        labels={[getNotebookEntryStringLabel({ entry: t.labNotebookEntry })]}
                                        key={i}
                                        showEntryRow
                                      />
                                    );
                                  })}
                                </LoadingWrapper>
                              </FoldableTocEntry>
                            ))}
                      </LoadingWrapper>
                    ) : (
                      <LoadingWrapper
                        status={statusTocEntries}
                        fetchStatus={fetchStatusTocEntries}
                        error={errorTocEntries}
                      >
                        {debouncedTocEntries.map((t, index) => {
                          return groupByEntry ? (
                            <FoldableTocEntry
                              id={`ELN_TOC_ENTRY_${t.labNotebookEntry.id}`}
                              label={
                                <div
                                  style={{
                                    display: "grid",
                                    gridTemplateColumns: "minmax(100px, 1fr) minmax(50px, max-content)",
                                    gap: "5px",
                                  }}
                                >
                                  <div
                                    style={{
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      whiteSpace: "nowrap",
                                    }}
                                  >
                                    {getNotebookEntryStringLabel({ entry: t.labNotebookEntry })}
                                  </div>
                                  <div
                                    className="label label-soft-default label-xs"
                                    style={{
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      whiteSpace: "nowrap",
                                      margin: 0,
                                    }}
                                  >
                                    {t.labNotebookEntry.labNotebookExperiment.name}
                                  </div>
                                </div>
                              }
                              foldable={!!t.tocEntries.length}
                              tooltip={t.labNotebookEntry.name}
                              icon="pencil"
                              entryColor="success"
                              controls={[
                                {
                                  disabled: labNotebookEntryId === t.labNotebookEntry.id,
                                  icon: "zoom-in",
                                  onClick: () => setShowPreview(t.labNotebookEntry.id),
                                  color: "ghost-secondary",
                                  tooltip: "Preview entry",
                                },
                                {
                                  icon: "arrow-right",
                                  onClick: () =>
                                    setELNRoute({
                                      labNotebookId: t.labNotebookEntry.labNotebook.id,
                                      labNotebookExperimentId: t.labNotebookEntry.labNotebookExperiment.id,
                                      labNotebookEntryId: t.labNotebookEntry.id,
                                    }),
                                  color: "ghost-secondary",
                                  tooltip: "View entry",
                                  disabled: labNotebookEntryId === t.labNotebookEntry.id,
                                  link: getELNRoute({
                                    labNotebookId: t.labNotebookEntry.labNotebook.id,
                                    labNotebookExperimentId: t.labNotebookEntry.labNotebookExperiment.id,
                                    labNotebookEntryId: t.labNotebookEntry.id,
                                  }),
                                },
                              ]}
                              active={labNotebookEntryId === t.labNotebookEntry.id}
                              key={index}
                              style={{
                                position: "sticky",
                                top: 31,
                                left: 0,
                                right: 0,
                                zIndex: 99,
                              }}
                            >
                              <LabNotebookTocEntry
                                tocEntry={t}
                                mode={mode}
                                labNotebookEntryId={labNotebookEntryId}
                                labNotebookExperimentId={labNotebookExperimentId}
                              />
                            </FoldableTocEntry>
                          ) : (
                            <LabNotebookTocEntry
                              tocEntry={t}
                              mode={mode}
                              labNotebookEntryId={labNotebookEntryId}
                              labNotebookExperimentId={labNotebookExperimentId}
                              labels={[
                                `${t.labNotebookEntry.labNotebookExperiment.name}`,
                                getNotebookEntryStringLabel({ entry: t.labNotebookEntry }),
                              ]}
                              key={index}
                              showEntryRow
                            />
                          );
                        })}
                      </LoadingWrapper>
                    )}
                    {!isFetchingTocEntries && debouncedTocEntries.length === 0 && (
                      <div style={{ padding: "10px" }}>
                        <Alert
                          message={
                            debouncedSearchValue ? `No entries for searchterm: '${debouncedSearchValue}'` : "No entries"
                          }
                          type="light"
                          fit
                          centered
                          style={{ margin: 0, padding: 0 }}
                        />
                      </div>
                    )}
                  </>
                </FoldableTocEntry>
              </LoadingWrapper>
            </div>
          )}
        </Tile.Body>
      </Tile>

      {labNotebook && (
        <Modal
          isOpen={!!showLabNotebookDetails}
          onClose={() => setShowLabNotebookDetails(false)}
          controls={
            <div className="flex align-center gap-5" style={{ width: "100%", justifyContent: "flex-end" }}>
              <LinkButton
                linkTo={route(getEditLink(labNoteBooksConstants.frontendIndexRoute, labNotebook.id))}
                className="btn btn-default"
              >
                <LucideIcon name="square-pen" /> <span> Edit details</span>
              </LinkButton>
            </div>
          }
        >
          <div style={{ minWidth: "50vw", padding: "10px 20px" }}>
            <LabNotebookDetailContentTable labNotebook={labNotebook} />
          </div>
        </Modal>
      )}

      <Modal
        isOpen={!!showLabNotebookExperimentDetails}
        onClose={() => setShowExperimentDetails(undefined)}
        controls={
          !!showLabNotebookExperimentDetails ? (
            <div className="flex align-center gap-5" style={{ width: "100%", justifyContent: "flex-end" }}>
              <LinkButton
                linkTo={route(
                  getEditLink(labNotebookExperimentsConstants.frontendIndexRoute, showLabNotebookExperimentDetails)
                )}
                className="btn btn-default"
              >
                <LucideIcon name="square-pen" /> <span> Edit details</span>
              </LinkButton>
            </div>
          ) : undefined
        }
      >
        {labNotebookExperimentPreview && (
          <div style={{ minWidth: "50vw", padding: "10px 20px" }}>
            <LabNotebookExperimentDetailContentTable
              entity={labNotebookExperimentPreview}
              entityConstants={labNotebookExperimentsConstants}
              fieldLabels={labNotebookExperimentFieldLabels}
            />
          </div>
        )}
      </Modal>

      <Modal
        isOpen={!!showPreview && !!preview && showPreview !== labNotebookEntryId}
        onClose={() => setShowPreview(undefined)}
      >
        <LoadingWrapper
          status={isFetchingPreview ? "loading" : statusPreview}
          fetchStatus={isFetchingPreview ? "fetching" : fetchStatusPreview}
          error={errorPreview}
        >
          <LabNotebookEntryViewOnly entry={preview} printMode useEntry />
        </LoadingWrapper>
      </Modal>
    </>
  );
};

interface TocEntryControls {
  onClick: (e: any) => void;
  color?: string;
  label?: string;
  icon?: IconNames;
  disabled?: boolean;
  invisible?: boolean;
  tooltip?: string;
  link?: string;
}

const FoldableTocEntry = ({
  id,
  label = "Title",
  tooltip = "",
  icon,
  children,
  isInitiallyOpen = false,
  entryColor = "default",
  controls,
  active = false,
  onClick,
  onDoubleClick,
  style,
  foldable = true,
  count,
}: {
  id?: string;
  label?: JSX.Element | string;
  tooltip?: string;
  icon?: IconNames;
  children: JSX.Element;
  isInitiallyOpen?: boolean;
  entryType?: string;
  entryColor?: "default" | "primary" | "secondary" | "success" | "warning" | "danger";
  controls?: TocEntryControls[];
  active?: boolean;
  onClick?: () => void;
  onDoubleClick?: () => void;
  remirrorContextRef?: any;
  autoScroll?: boolean;
  style?: CSSProperties;
  foldable?: boolean;
  count?: number;
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(isInitiallyOpen);

  useEffect(() => {
    if (active && id) {
      setIsOpen(active);
      document.getElementById(id)?.scrollIntoView({ block: "center", behavior: "smooth" });
    }
  }, [active, id]);

  return (
    <div id={id}>
      <div
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          if (e.detail === 1) {
            setIsOpen((prevState) => !prevState);
            onClick && onClick();
          }
        }}
        onDoubleClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          if (e.detail === 2) {
            onDoubleClick && onDoubleClick();
          }
        }}
        data-toggle="tooltip"
        title={tooltip}
        className={
          active ? styles.tocEntryFoldableTocEntryHeaderContainerActive : styles.tocEntryFoldableTocEntryHeaderContainer
        }
        style={{ cursor: foldable ? "pointer" : "default", ...style }}
      >
        <div className={styles.tocEntryFoldableTocEntryHeaderLeftContainer}>
          <div
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              if (foldable) setIsOpen((prevState) => !prevState);
            }}
            style={{ opacity: foldable ? 1 : 0 }}
          >
            <LucideIcon name={isOpen ? "chevron-down" : "chevron-right"} />
          </div>
          <div className={styles.tocEntryFoldableTocEntryHeaderLabelContainer}>
            <div className="flex align-center gap-5">
              {icon && <LucideIcon name={icon} color={`var(--${entryColor})`} />}
            </div>
            <div className={styles.tocEntryFoldableTocEntryHeaderLabel}>{label}</div>
            <div className={`badge ${styles.tocEntryFoldableTocEntryHeaderLabel}}`}>{!!count && count}</div>
          </div>
        </div>
        <div className={styles.tocEntryFoldableTocEntryHeaderControlsContainer}>
          {active && (!isOpen || !foldable) && <CurrentIndicator iconOnly />}
          {controls &&
            controls.length > 0 &&
            controls.map((c, index) =>
              c.link && !c.disabled ? (
                <Link
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                  to={c.link}
                  className={styles.tocEntryLinkContainer}
                  key={index}
                >
                  <button
                    className={`btn btn-xs btn-${c.color || "ghost-secondary"}`}
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      c.onClick(e);
                    }}
                    disabled={c.disabled}
                    style={{ opacity: c.invisible ? 0 : 1 }}
                    data-toggle="tooltip"
                    title={c.tooltip || ""}
                  >
                    {c.icon && <LucideIcon name={c.icon} />}
                    {c.label && c.label}
                  </button>
                </Link>
              ) : (
                <button
                  key={index}
                  className={`btn btn-xs btn-${c.color || "ghost-secondary"}`}
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    c.onClick(e);
                  }}
                  disabled={c.disabled}
                  style={{ opacity: c.invisible ? 0 : 1 }}
                  data-toggle="tooltip"
                  title={c.tooltip || ""}
                >
                  {c.icon && <LucideIcon name={c.icon} />}
                  {c.label && c.label}
                </button>
              )
            )}
        </div>
      </div>
      {(!foldable || isOpen) && (
        <div className={active ? styles.tocEntryListContainerActive : styles.tocEntryListContainer}>{children}</div>
      )}
    </div>
  );
};

const blockTypeToStyle = (type: LabNotebookTocEntryTypes, level: number) => {
  const indentWidth = 14;
  switch (type) {
    case "Header1":
      return {
        paddingLeft: `${level * indentWidth}px`,
        fontWeight: 600,
        color: "var(--gray-900)",
      } as CSSProperties;
    case "Header2":
      return {
        paddingLeft: `${level * indentWidth}px`,
        fontWeight: 600,
        color: "var(--gray-900)",
      } as CSSProperties;
    case "Header3":
      return {
        paddingLeft: `${level * indentWidth}px`,
        fontWeight: 600,
        color: "var(--gray-900)",
      } as CSSProperties;
    case "Header4":
      return {
        paddingLeft: `${level * indentWidth}px`,
        fontWeight: 600,
        color: "var(--gray-900)",
      } as CSSProperties;
    case "Header5":
      return {
        paddingLeft: `${level * indentWidth}px`,
        fontWeight: 600,
        color: "var(--gray-900)",
      } as CSSProperties;
    case "Header6":
      return {
        paddingLeft: `${level * indentWidth}px`,
        fontWeight: 600,
        color: "var(--gray-900)",
      } as CSSProperties;

    default:
      return {
        paddingLeft: `${level * indentWidth}px`,
      } as CSSProperties;
  }
};

const blockTypeToIcon = (type: LabNotebookTocEntryTypes) => {
  switch (type) {
    case "CalloutError":
      return <LucideIcon name="octagon-alert" color="var(--danger)" />;
    case "CalloutInfo":
      return <LucideIcon name="info" color="var(--info)" />;
    case "CalloutSuccess":
      return <LucideIcon name="circle-check-big" color="var(--success)" />;
    case "CalloutWarning":
      return <LucideIcon name="triangle-alert" color="var(--warning)" />;

    case "Dataset":
      return <LucideIcon name={datasetsConstants.icon} color="var(--primary)" />;
    case "Sample":
      return <LucideIcon name={samplesConstants.icon} color="var(--primary)" />;
    case "Inventory":
      return <LucideIcon name={inventoryItemsConstants.icon} color="var(--primary)" />;
    case "Person":
      return <LucideIcon name={personsConstants.icon} color="var(--primary)" />;
    case "Project":
      return <LucideIcon name={projectsConstants.icon} color="var(--primary)" />;

    default:
      return null;
  }
};

const LabNotebookTocEntry = ({
  mode,
  tocEntry,
  labNotebookEntryId,
  labNotebookExperimentId,
  labels = [],
  showEntryRow = false,
}: {
  mode?: ELNModes;
  tocEntry: LabNotebookEntryWithTocEntries;
  labNotebookEntryId?: number;
  labNotebookExperimentId?: number;
  labels?: string[];
  showEntryRow?: boolean;
}) => {
  const { getELNRoute } = useELNRoutes();
  return (
    <ul style={{ padding: 0, margin: 0, borderTop: "1px solid var(--gray-100)" }}>
      {showEntryRow && !tocEntry.tocEntries.length && (
        <Link
          to={getELNRoute({
            labNotebookId: tocEntry.labNotebookEntry.labNotebook.id,
            labNotebookExperimentId: tocEntry.labNotebookEntry.labNotebookExperiment.id,
            labNotebookEntryId: tocEntry.labNotebookEntry.id,
            mode: tocEntry.labNotebookEntry.id === labNotebookEntryId ? mode : "view",
          })}
          className={styles.tocEntryLinkContainer}
        >
          <li
            className={styles.tocEntryListEntryItem}
            style={{
              gridTemplateColumns: ["minmax(100px, 1fr)"]
                .concat(labNotebookEntryId === tocEntry.labNotebookEntry.id ? ["max-content"] : [])
                .concat(labels.map((l) => "minmax(50px, min-content)"))
                .join(" "),
              paddingLeft: "14px",
            }}
          >
            <div style={{ color: "var(--gray-400)", fontSize: 11 }}>No outline</div>
            {labNotebookEntryId === tocEntry.labNotebookEntry.id && <CurrentIndicator iconOnly />}
            {!!labels.length &&
              labels.map((l, i) => (
                <div key={i} className={`label label-soft-default ${styles.tocEntryListEntryItemLabel}`}>
                  {l}
                </div>
              ))}
          </li>
        </Link>
      )}
      {tocEntry.tocEntries.map((entry, index) => (
        <Link
          to={getELNRoute({
            labNotebookId: tocEntry.labNotebookEntry.labNotebook.id,
            labNotebookExperimentId: tocEntry.labNotebookEntry.labNotebookExperiment.id,
            labNotebookEntryId: tocEntry.labNotebookEntry.id,
            mode: tocEntry.labNotebookEntry.id === labNotebookEntryId ? mode : "view",
            labNotebookBlockId: entry.blockId,
          })}
          className={styles.tocEntryLinkContainer}
          key={index}
        >
          <li
            id={`ELN_TOC_BLOCK_${entry.blockId}`}
            className={styles.tocEntryListEntryItem}
            style={{
              gridTemplateColumns: ["minmax(100px, 1fr)"]
                .slice(0, labNotebookEntryId === tocEntry.labNotebookEntry.id ? undefined : 1)
                .concat(labNotebookEntryId === tocEntry.labNotebookEntry.id ? ["max-content"] : [])
                .concat(labels.map((l) => (l.length > 30 ? "minmax(50px, min-content)" : "minmax(50px, max-content)")))
                .join(" "),
              ...blockTypeToStyle(entry.blockType, entry.blockLevel),
            }}
            data-toggle="tooltip"
            title={`${tocEntry.labNotebookEntry.name} > ${entry.blockLabel}`}
          >
            <div className={styles.tocEntryListEntryItemLabel}>
              {blockTypeToIcon(entry.blockType)}
              <span> {entry.blockLabel}</span>
            </div>
            {index === 0 && labNotebookEntryId === tocEntry.labNotebookEntry.id && <CurrentIndicator iconOnly />}
            {index === 0 &&
              !!labels.length &&
              labels.map((l, i) => (
                <div key={i} className={`label label-soft-default ${styles.tocEntryListEntryItemLabel}`}>
                  {l}
                </div>
              ))}
          </li>
        </Link>
      ))}
    </ul>
  );
};
