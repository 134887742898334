import React, { CSSProperties, useCallback, useState } from "react";
import { LucideIcon } from "../../icon/LucideIcon";
import styles from "./MoreDropdown.module.css";
import { useIsRefClicked } from "../../helperfunctions/useIsRefClicked";

// const padding = 10;
interface Props {
  direction?: "up" | "down";
  drop?: "left" | "right";
  wrapperCls?: string;
  btn?: string;
  title?: string;
  icon?: React.ReactNode;
  disabled?: boolean;
  closeOnClickOutside?: boolean;
  closeOnClickInside?: boolean;
  children?: React.ReactNode;
  contentStyle?: CSSProperties;
  button?: (onDropDownToggle: (e: React.MouseEvent) => void, disabled: boolean) => React.ReactNode;
}
export const MoreDropdown = ({
  direction = "down",
  drop = "left",
  wrapperCls,
  btn = "btn btn-sm btn-ghost-dark",
  title = "More",
  icon,
  disabled = false,
  closeOnClickOutside = true,
  closeOnClickInside = true,
  children,
  contentStyle,
  button,
}: Props) => {
  // const buttonRef = useRef<HTMLDivElement>(null);
  const [showDropDown, setShowDropDown] = useState(false);

  const onClickInside = useCallback(() => {
    if (closeOnClickInside) setShowDropDown(false);
  }, [closeOnClickInside]);
  const { ref: child } = useIsRefClicked<HTMLUListElement>({ onClickInside });

  const onClickOutside = useCallback(() => {
    if (closeOnClickOutside) setShowDropDown(false);
  }, [closeOnClickOutside]);
  const { ref: parent } = useIsRefClicked<HTMLDivElement>({ onClickOutside });

  const onDropDownToggle = useCallback((e: React.MouseEvent) => {
    setShowDropDown((prevState) => !prevState);
    e.preventDefault();
    e.stopPropagation();
  }, []);

  // const getStyle = useCallback(() => {
  //   const rect = buttonRef.current?.getBoundingClientRect();
  //   const width = rect?.width;
  //   const height = rect?.height;
  //   let style: React.CSSProperties = {};
  //   if (!width || !height) return style;
  //   if (drop === "left") {
  //     style = { ...style, left: `${width / 2}px` };
  //   } else {
  //     style = { ...style, left: `-${width / 2}px` };
  //   }
  //   if (direction === "up") {
  //     style = { ...style, bottom: `${height + padding}px` };
  //   }

  //   return style;
  // }, [direction, drop]);

  // return (
  //   <FloatingDiv
  //     triggerComponent={({ context, ref, getReferenceProps, setOpen, isOpen }) => (
  //       <div className={`${wrapperCls ? wrapperCls : ""} ${styles.menu_wrapper}`} ref={ref} {...getReferenceProps()}>
  //         {button ? (
  //           // <div className="flex row-nowrap align-center gap-5" ref={buttonRef}>
  //           button?.((e) => {
  //             setOpen((prevState) => !prevState);
  //             e.preventDefault();
  //             e.stopPropagation();
  //           }, disabled)
  //         ) : (
  //           // </div>
  //           // <div className="flex row-nowrap align-center gap-5" ref={buttonRef}>
  //           <button
  //             className={`${btn} dropdown-toggle ${disabled ? "disabled" : ""}`}
  //             data-toggle="dropdown"
  //             aria-haspopup="true"
  //             aria-expanded="false"
  //             title={title}
  //             onClick={(e) => {
  //               // setShowDropDown((prevState) => !prevState);
  //               setOpen((prevState) => !prevState);
  //               e.preventDefault();
  //               e.stopPropagation();
  //             }}
  //             disabled={disabled}
  //           >
  //             {icon ?? <LucideIcon name="ellipsis-vertical" />}
  //           </button>
  //           // </div>
  //         )}
  //       </div>
  //     )}
  //   >
  //     {({ open, setOpen }) => (
  //       <>
  //         {open && (
  //           <div
  //             className={styles.dropdownMenu}
  //             //  style={getStyle()}
  //           >
  //             {children}
  //           </div>
  //         )}
  //       </>
  //     )}
  //   </FloatingDiv>
  // );

  return (
    <div
      className={`${wrapperCls ? wrapperCls : ""} ${showDropDown && "open"} ${direction === "up" ? "dropup" : ""} ${
        styles.menu_wrapper
      }`}
      ref={parent}
    >
      {button ? (
        button(onDropDownToggle, disabled)
      ) : (
        <button
          className={`${btn} dropdown-toggle ${disabled ? "disabled" : ""}`}
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          title={title}
          onClick={(e) => {
            setShowDropDown((prevState) => !prevState);
            e.preventDefault();
            e.stopPropagation();
          }}
          disabled={disabled}
        >
          {icon ?? <LucideIcon name="ellipsis-vertical" />}
        </button>
      )}
      <ul
        className={`dropdown-menu ${drop === "left" ? "" : "dropdown-menu-right"} ${styles.menu}`}
        ref={child}
        style={contentStyle}
      >
        {children}
      </ul>
    </div>
  );
};
