import { Person, PersonFilters, PersonFieldLabels } from "../../api/Person";
import { SelectFormField } from "../../common/formfields/SelectFormField";
import { DefinedPropertyObject, IEntityMinimalModel } from "../../api/GenericTypes";
import { ToggleFormField } from "../../common/formfields/ToggleFormField";
import { FilterSidebarProps, useFilterSidebar } from "../../common/sidebar/filtersidebar/FilterSidebar";
import { PersonTagsVirtualizedSelectForm } from "../../common/forms/EntityForms/formsVirtualized/PersonTagsVirtualizedSelectForm";
import { OrganizationsVirtualizedSelectForm } from "../../common/forms/EntityForms/formsVirtualized/OrganizationsVirtualizedSelectForm";
import { PersonsVirtualizedSelectForm } from "../../common/forms/EntityForms/formsVirtualized/PersonsVirtualizedSelectForm";
import { RolesVirtualizedSelectForm } from "../../common/forms/EntityForms/formsVirtualized/RolesVirtualizedSelectForm";
import { EntityFilterSideBar } from "../../common/sidebar/filtersidebar/EntityFilterBar";
import { SplitDatePickerFormField } from "../../common/formfields/DatePickerFormField/SplitDatePickerFormField";
import { useContext } from "react";
import { SessionContext } from "../../common/contexts/SessionContext";
import { CustomTypesVirtualizedSelectForm } from "../../common/forms/EntityForms/formsVirtualized/CustomTypesVirtualizedSelectForm";
import { GenericVirtualizedSelectFormField } from "../../common/formfields/GenericVirtualizedSelectFormField/GenericVirtualizedSelectFormField";

type AccountStatus = { id: number; name: string; value: boolean };

const AccountStatusOptions = [
  { id: 1, name: "no account", value: false },
  { id: 2, name: "has account", value: true },
  { id: 3, name: "enabled", value: true },
  { id: 4, name: "disabled", value: false },
] as AccountStatus[];

export interface PersonFilterForm {
  organizationIds?: IEntityMinimalModel[] | undefined | null;
  personTagIds?: IEntityMinimalModel[] | undefined | null;
  roleIds?: IEntityMinimalModel[] | undefined | null;
  accountState?: AccountStatus | undefined | null;
  includeSoftDeleted?: boolean | undefined | null;
  isSoftDeleted?: boolean | undefined | null;
  isLocalUser?: "Local" | "SSO" | undefined | null;
  createdByIds?: IEntityMinimalModel[] | undefined | null;
  createdFrom?: Date | undefined | null;
  createdTo?: Date | undefined | null;
  modifiedByIds?: IEntityMinimalModel[] | undefined | null;
  modifiedFrom?: Date | undefined | null;
  modifiedTo?: Date | undefined | null;
  includeSystemUsers?: boolean | undefined | null;
  customType?: { id: number; name: string }[] | undefined | null;
}

const defaultValues: DefinedPropertyObject<PersonFilterForm> = {
  organizationIds: null,
  personTagIds: null,
  roleIds: null,
  accountState: null,
  includeSoftDeleted: false,
  isSoftDeleted: false,
  isLocalUser: null,
  createdByIds: null,
  createdFrom: null,
  createdTo: null,
  modifiedByIds: null,
  modifiedFrom: null,
  modifiedTo: null,
  includeSystemUsers: false,
  customType: null,
};
const formToFilters = (form: PersonFilterForm): PersonFilters => {
  let filters: PersonFilters = {};
  filters.organizationIds =
    Array.isArray(form.organizationIds) && form.organizationIds.length > 0
      ? form.organizationIds?.map((d) => d.id)
      : null;
  filters.personTagIds =
    Array.isArray(form.personTagIds) && form.personTagIds.length > 0 ? form.personTagIds?.map((d) => d.id) : null;
  filters.roleIds = Array.isArray(form.roleIds) && form.roleIds.length > 0 ? form.roleIds?.map((d) => d.id) : null;
  filters.hasAccount =
    form.accountState !== undefined && form.accountState !== null
      ? form.accountState.id <= 2
        ? form.accountState.value
        : null
      : null;
  filters.isAccountEnabled =
    form.accountState !== undefined && form.accountState !== null
      ? form.accountState.id > 2
        ? form.accountState.value
        : null
      : null;
  filters.includeSoftDeleted = form.includeSoftDeleted || false;
  filters.isSoftDeleted = form.isSoftDeleted || false;
  filters.isLocalUser = form.isLocalUser === "Local" ? true : form.isLocalUser === "SSO" ? false : null;
  filters.createdByIds =
    Array.isArray(form.createdByIds) && form.createdByIds.length > 0 ? form.createdByIds?.map((d) => d.id) : null;
  filters.createdFrom = form.createdFrom ? form.createdFrom.toISOString() : null;
  filters.createdTo = form.createdTo ? form.createdTo.toISOString() : null;
  filters.modifiedByIds =
    Array.isArray(form.modifiedByIds) && form.modifiedByIds.length > 0 ? form.modifiedByIds?.map((d) => d.id) : null;
  filters.modifiedFrom = form.modifiedFrom ? form.modifiedFrom.toISOString() : null;
  filters.modifiedTo = form.modifiedTo ? form.modifiedTo.toISOString() : null;
  filters.includeSystemUsers = form.includeSystemUsers || false;
  filters.customTypeIds =
    Array.isArray(form.customType) && form.customType.length > 0 ? form.customType.map((d) => d.id) : null;
  return filters;
};
const defaultFilters: PersonFilters = { orderBy: "NAME_ASC" };

export const PersonFilterBar = ({
  initialValues,
  setFilters,
  onFormChange,
  dispatchTabStore,
  tabsLoading,
  currentTab,
}: FilterSidebarProps<Person, PersonFilterForm, PersonFilters>) => {
  const { session } = useContext(SessionContext);
  const { register, control, onReset } = useFilterSidebar({
    initialValues,
    defaultValues,
    defaultFilters,
    formToFilters,
    onFormChange,
    dispatchTabStore,
    setFilters,
  });

  return (
    <EntityFilterSideBar onReset={onReset}>
      {session?.features.enable_person_custom_types && (
        <CustomTypesVirtualizedSelectForm
          id="customType"
          control={control}
          showControls={false}
          filters={{ entityTypes: ["Person"] }}
          isMulti
          enableBasicOption
          hideIfNoTypes
        />
      )}
      <PersonTagsVirtualizedSelectForm
        id="personTagIds"
        label={PersonFieldLabels.personTags}
        control={control}
        isMulti
      />
      <OrganizationsVirtualizedSelectForm id="organizationIds" control={control} showControls={false} isMulti />

      {currentTab !== "activeUsers" && currentTab !== "disabledUsers" && (
        <SelectFormField
          id="accountState"
          label={PersonFieldLabels.accountState}
          {...register}
          items={AccountStatusOptions}
          control={control}
          horizontal={false}
        />
      )}
      <GenericVirtualizedSelectFormField
        id="isLocalUser"
        label={PersonFieldLabels.isLocalUser}
        control={control}
        items={["Local", "SSO"]}
        // placeholder="Select entity type..."
        // isMulti
      />
      <RolesVirtualizedSelectForm
        id="roleIds"
        label={PersonFieldLabels.roles}
        control={control}
        isMulti
        filters={{ includeInternals: !!session?.permissions.logsadmin || !!session?.permissions.view_admin_pages }}
      />

      {currentTab !== "mydata" && (
        <PersonsVirtualizedSelectForm id="createdByIds" label="Created by" control={control} isMulti />
      )}
      <SplitDatePickerFormField
        control={control}
        idStart="createdFrom"
        idEnd="createdTo"
        label="Creation date (from - to)"
        horizontal={false}
        showTimeSelect
      />
      <PersonsVirtualizedSelectForm id="modifiedByIds" label="Modified by" control={control} isMulti />

      <SplitDatePickerFormField
        control={control}
        idStart="modifiedFrom"
        idEnd="modifiedTo"
        label="Modification date (from - to)"
        horizontal={false}
        showTimeSelect
      />

      {(!!session?.permissions.logsadmin || !!session?.permissions.view_admin_pages) && (
        <ToggleFormField id="includeSystemUsers" control={control} label="Include system users" horizontal />
      )}
      {currentTab !== "trash" && currentTab !== "activeUsers" && currentTab !== "disabledUsers" && (
        <ToggleFormField id="includeSoftDeleted" control={control} label="Include trashed" horizontal />
      )}
      {currentTab !== "trash" && currentTab !== "activeUsers" && currentTab !== "disabledUsers" && (
        <ToggleFormField id="isSoftDeleted" control={control} label="Is trashed" horizontal />
      )}
    </EntityFilterSideBar>
  );
};
