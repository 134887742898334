import { EntityAddForm, EntityAddFormPage } from "../common/entity/EntityAddForm";
import { EntityCloneForm, EntityCloneFormPage } from "../common/entity/EntityCloneForm";
import { useContext, useMemo } from "react";
import { SessionContext } from "../common/contexts/SessionContext";
import {
  CustomTypeEntityAddFormProps,
  EntityCloneFormProps,
  EntityDetailViewProps,
  EntityEditFormProps,
} from "../common/entity/EntityInterfaces";
import { EntityEditForm, EntityEditFormPage } from "../common/entity/EntityEditForm";
import { EntityDetailPage, EntityDetailView } from "../common/entity/EntityDetailPage";
import { Sample } from "../api/Samples";
import { useLocation } from "react-router-dom";
import { SampleForm } from "./SampleForm";
import { SampleDetail } from "./SamplesDetail";
import { EntityTablePage } from "../common/entity/EntityTablePage";
import { SamplesTable } from "./Table/SamplesTable";

// Add
export const SampleAddForm = ({ typeId }: CustomTypeEntityAddFormProps) => {
  const { session } = useContext(SessionContext);
  const initialValues: Partial<Sample> = useMemo(
    () => ({
      preparedAt: new Date().toJSON().slice(0, 10),
      preparedBy: session ? [{ id: session.userId, name: session.person.name ?? "" }] : [],
    }),
    [session]
  );
  return (
    <EntityAddForm entityTypeId={"samples"}>
      {(props) => <SampleForm {...props} initialValues={initialValues} typeId={typeId} />}
    </EntityAddForm>
  );
};

export const SampleAddFormPage = () => {
  const typeId = new URLSearchParams(useLocation().search).get("type");

  return (
    <EntityAddFormPage>
      <SampleAddForm typeId={typeId ? +typeId : null} />
    </EntityAddFormPage>
  );
};

// Clone
export const SampleCloneForm = ({ id }: EntityCloneFormProps<"samples">) => {
  return (
    <EntityCloneForm id={id} entityTypeId={"samples"}>
      {(props) => <SampleForm {...props} typeId={props.initialValues?.customType?.id} />}
    </EntityCloneForm>
  );
};

export const SampleCloneFormPage = () => {
  return <EntityCloneFormPage<"samples"> isIntId>{(id) => <SampleCloneForm id={id} />}</EntityCloneFormPage>;
};

// Edit
export const SampleEditForm = ({ id }: EntityEditFormProps<"samples">) => {
  return (
    <EntityEditForm id={id} entityTypeId={"samples"} filters={{}}>
      {(props) => <SampleForm {...props} typeId={props.initialValues?.customType?.id} />}
    </EntityEditForm>
  );
};

export const SampleEditFormPage = () => {
  return <EntityEditFormPage<"samples"> isIntId>{(id) => <SampleEditForm id={id} />}</EntityEditFormPage>;
};

// Detail
export const SamplesDetailView = ({ id }: EntityDetailViewProps<"samples">) => {
  return (
    <EntityDetailView id={id} entityTypeId={"samples"}>
      {(props) => <SampleDetail {...props} />}
    </EntityDetailView>
  );
};

export const SampleDetailsPage = () => {
  return <EntityDetailPage<"samples"> isIntId>{(id) => <SamplesDetailView id={id} />}</EntityDetailPage>;
};

export const SamplesTablePage = () => {
  return <EntityTablePage entityTypeId={"samples"}>{(props) => <SamplesTable {...props} />}</EntityTablePage>;
};
