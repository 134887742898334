import { EntityAddForm, EntityAddFormPage } from "../../common/entity/EntityAddForm";
import { EntityCloneForm, EntityCloneFormPage } from "../../common/entity/EntityCloneForm";
import { EntityDetailView, EntityDetailPage } from "../../common/entity/EntityDetailPage";
import { EntityEditForm, EntityEditFormPage } from "../../common/entity/EntityEditForm";
import { EntityCloneFormProps, EntityEditFormProps, EntityDetailViewProps } from "../../common/entity/EntityInterfaces";
import { EntityTablePage } from "../../common/entity/EntityTablePage";
import { LabNotebookExperimentForm } from "./Forms/LabNotebookExperimentForm";
import { LabNotebookExperimentsTable } from "./Table/LabNotebookExperimentsTable";
import { LabNotebookExperimentsDetail } from "./LabNotebookExperimentDetail";
import { LabNotebookExperiment } from "../types/LabNotebookExperiment";

// Add
export const LabNotebookExperimentAddForm = ({
  onSuccess,
  onCancel,
}: {
  onSuccess?: (labNotebookExperiment: LabNotebookExperiment) => void;
  onCancel?: () => void;
}) => {
  return (
    <EntityAddForm entityTypeId={"notebookExperiments"} onCancel={onCancel} onSuccess={onSuccess}>
      {(props) => <LabNotebookExperimentForm {...props} />}
    </EntityAddForm>
  );
};

export const LabNotebookExperimentAddFormPage = () => {
  return (
    <EntityAddFormPage>
      <LabNotebookExperimentAddForm />
    </EntityAddFormPage>
  );
};

// Clone
export const LabNotebookExperimentCloneForm = ({ id }: EntityCloneFormProps<"notebookExperiments">) => {
  return (
    <EntityCloneForm id={id} entityTypeId={"notebookExperiments"}>
      {(props) => <LabNotebookExperimentForm {...props} />}
    </EntityCloneForm>
  );
};

export const LabNotebookExperimentCloneFormPage = () => {
  return (
    <EntityCloneFormPage<"notebookExperiments"> isIntId>
      {(id) => <LabNotebookExperimentCloneForm id={id} />}
    </EntityCloneFormPage>
  );
};

// Edit
export const LabNotebookExperimentEditForm = ({
  id,
  onSuccess,
  onCancel,
}: EntityEditFormProps<"notebookExperiments">) => {
  return (
    <EntityEditForm id={id} entityTypeId={"notebookExperiments"} filters={{}} onSuccess={onSuccess} onCancel={onCancel}>
      {(props) => <LabNotebookExperimentForm {...props} />}
    </EntityEditForm>
  );
};

export const LabNotebookExperimentEditFormPage = () => {
  return (
    <EntityEditFormPage<"notebookExperiments"> isIntId>
      {(id) => <LabNotebookExperimentEditForm id={id} />}
    </EntityEditFormPage>
  );
};

// Detail
export const LabNotebookExperimentsDetailView = ({ id }: EntityDetailViewProps<"notebookExperiments">) => {
  return (
    <EntityDetailView id={id} entityTypeId={"notebookExperiments"} filters={{}}>
      {(props) => <LabNotebookExperimentsDetail {...props} />}
    </EntityDetailView>
  );
};

export const LabNotebookExperimentDetailsPage = () => {
  return (
    <EntityDetailPage<"notebookExperiments"> isIntId>
      {(id) => <LabNotebookExperimentsDetailView id={id} />}
    </EntityDetailPage>
  );
};

export const LabNotebookExperimentsTablePage = () => {
  return (
    <EntityTablePage entityTypeId={"notebookExperiments"}>
      {(props) => <LabNotebookExperimentsTable {...props} />}
    </EntityTablePage>
  );
};
