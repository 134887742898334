import { useContext, useEffect, useState, useMemo, useCallback } from "react";
import { SessionContext } from "../../common/contexts/SessionContext";
import { SearchInput } from "../../common/forms/SearchInput/SearchInput";
import { LucideIcon } from "../../common/icon/LucideIcon";
import TableView, { TableViewLabel } from "../../common/panels/TableView/TableView";
import { MoreDropdown } from "../../common/buttons/MoreDropdown/MoreDropdown";
import { AlertModal } from "../../common/modals/AlertModal/AlertModal";
import { showtoast } from "../../common/overlays/Toasts/showtoast";
import { ColumnsSettings } from "../../common/tables/ColumnsSelector/ColumnsSelector";
import { DateTimeRenderer } from "../../common/datetime/DateTimeFormatter";
import { DatasetFilters, Dataset, ZipFileSize } from "../../api/Datasets";
// import { MultiEdit, MultiEditModal } from "../../common/tables/MultiEdit/MultiEdit";
import { EntityTable } from "../../common/tables/EntityTable/EntityTable";
import {
  GenericVirtualizedTableCells,
  SortState,
} from "../../common/tables/GenericVirtualizedTable/GenericVirtualizedTableTypes";
import styles from "../../common/tables/GenericVirtualizedTable/commons.module.css";
import { EntityFilterIndicator } from "../../common/tables/EntityFilterIndicator/EntityFilterIndicator";
import { EntityTableTabs } from "../../common/tables/Tabs/EntityTableTabs";
import { useTabStore } from "../../common/tables/Tabs/useTabStore";
import { TableTabsDict } from "../../common/tables/Tabs/TableTabsTypes";
import { getDatasetsZip } from "../../Dataset/common/DownloadDataset/DownloadDataset";
import { formatBytes } from "../../Dataset/common/Tree/Folder/helpers/FileHelpers";
import { DatasetsFilterForm } from "../../Dataset/Table/DatasetsFilterBar";
import { NotAvailable, NotSet } from "../../common/misc/UIconstants";
import { GetPersons } from "../../common/misc/EntityRenders/EntityRenderer";
import { customFieldConstants } from "../../api/CustomFields";
import { labNoteBooksConstants } from "../../ELN/types/LabNotebook";
import { AttachmentsFilterbar } from "./AttachmentsFilterBar";
import {
  UseEntityTableDefaultProps,
  UseEntityTableProps,
  useGenericVirtualizedTable,
  useGenericVirtualizedTableTabs,
} from "../../common/tables/GenericVirtualizedTable/useGenericVirtualizedTable";
import { EntityTableProps } from "../../common/entity/EntityInterfaces";
import { AttachmentsTableOptions } from "../AttachmentViews";
import { EntityTableRestoreButton } from "../../common/entity/entityComponents/EntityTableRestoreButton";
import { EntityTableSoftDeletableButton } from "../../common/entity/entityComponents/EntityTableSoftDeletableButton";

export const switchMediaDefaultSortState = (
  sortState: DatasetFilters["orderBy"]
): SortState<DatasetFilters["orderBy"]> => {
  switch (sortState) {
    case "ID_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-id",
        orderBy: sortState,
      };
    case "ID_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-id",
        orderBy: sortState,
      };
    case "NAME_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-name",
        orderBy: sortState,
      };
    case "NAME_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-name",
        orderBy: sortState,
      };
    case "CREATED_ON_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-createdOn",
        orderBy: sortState,
      };
    case "CREATED_ON_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-createdOn",
        orderBy: sortState,
      };
    case "CREATED_BY_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-createdBy",
        orderBy: sortState,
      };
    case "CREATED_BY_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-createdBy",
        orderBy: sortState,
      };
    case "MODIFIED_ON_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-modifiedOn",
        orderBy: sortState,
      };
    case "MODIFIED_ON_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-modifiedOn",
        orderBy: sortState,
      };
    case "MODIFIED_BY_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-modifiedBy",
        orderBy: sortState,
      };
    case "MODIFIED_BY_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-modifiedBy",
        orderBy: sortState,
      };
    default:
      return {
        sortDirection: "DESC",
        headerId: "default-acqdate",
        orderBy: sortState,
      };
  }
};

const defaultFilter: DatasetFilters = { orderBy: "CREATED_ON_DESC" };

export const useAttachmentsTableDefaults = ({ fieldLabels }: UseEntityTableDefaultProps<"attachments">) => {
  const { session } = useContext(SessionContext);

  const defaults: ColumnsSettings<Dataset> = useMemo(
    () => ({
      "default-id": { pos: 0, active: false, header: fieldLabels.id, property: "id" },
      "default-name": { pos: 1, active: true, header: fieldLabels.name, property: "name" },
      "default-viewableEntityType": {
        pos: 2,
        active: true,
        header: fieldLabels.viewableEntityType,
        property: "viewableEntityType",
      },
      "default-createdOn": { pos: 3, active: true, header: fieldLabels.createdOn, property: "createdOn" },
      "default-createdBy": { pos: 4, active: true, header: fieldLabels.createdBy, property: "createdBy" },
      "default-modifiedOn": { pos: 5, active: true, header: fieldLabels.modifiedOn, property: "modifiedOn" },
      "default-modifiedBy": { pos: 6, active: true, header: fieldLabels.modifiedBy, property: "modifiedBy" },
      "default-owner": { pos: 7, active: true, header: fieldLabels.owner, property: "owner" },
      "default-notes": { pos: 9, active: false, header: fieldLabels.notes, property: "notes" },
      "default-format": { pos: 10, active: false, header: fieldLabels.format, property: "format" },
    }),
    [fieldLabels]
  );

  const tabStoreDefaults: TableTabsDict<Dataset, DatasetFilters, DatasetsFilterForm> = useMemo(
    () => ({
      default: {
        tabId: "default",
        type: "fixed",
        label: "All",
        title: "All",
        icon: "house",
        align: "left",
        xPos: 0,
        settings: {
          columnSettings: {},
          columnWidths: {},
          filters: defaultFilter,
          sidebarFilters: { includeUnclaimed: true, includeSoftDeleted: false },
        },
        forcedSettings: {
          columnSettings: {},
          columnWidths: {},
          filters: {},
          sidebarFilters: {},
        },
      },
      mydata: {
        tabId: "mydata",
        type: "fixed",
        label: "My attachments",
        title: "My attachments",
        icon: "user",
        align: "left",
        xPos: 1,
        settings: {
          columnSettings: {},
          columnWidths: {},
          filters: defaultFilter,
          sidebarFilters: {},
        },
        forcedSettings: {
          columnSettings: {},
          columnWidths: {},
          filters: { ownerIds: session?.person.id ? [session.person.id] : null },
          sidebarFilters: {},
        },
      },
      trash: {
        tabId: "trash",
        type: "fixed",
        label: "Trash",
        title: "Trash",
        icon: "trash",
        align: "left",
        xPos: 3,
        settings: {
          columnSettings: {},
          columnWidths: {},
          filters: defaultFilter,
          sidebarFilters: { includeUnclaimed: true },
        },
        forcedSettings: {
          columnSettings: {},
          columnWidths: {},
          filters: { isSoftDeleted: true },
          sidebarFilters: {},
        },
      },
      notebookAttachments: {
        tabId: "notebookAttachments",
        type: "fixed",
        label: "Notebook attachments",
        title: "Notebook attachments",
        icon: labNoteBooksConstants.icon,
        align: "left",
        xPos: 4,
        settings: {
          columnSettings: {},
          columnWidths: {},
          filters: defaultFilter,
          sidebarFilters: { includeUnclaimed: true, includeSoftDeleted: false },
        },
        forcedSettings: {
          columnSettings: {},
          columnWidths: {},
          filters: { viewableEntityTypes: ["ELN"] },
          sidebarFilters: {},
        },
      },
      customFieldAttachments: {
        tabId: "customFieldAttachments",
        type: "fixed",
        label: "Custom field attachments",
        title: "Custom field attachments",
        icon: customFieldConstants.icon,
        align: "left",
        xPos: 4,
        settings: {
          columnSettings: {},
          columnWidths: {},
          filters: defaultFilter,
          sidebarFilters: { includeUnclaimed: true, includeSoftDeleted: false },
        },
        forcedSettings: {
          columnSettings: {},
          columnWidths: {},
          filters: { viewableEntityTypes: ["CustomField"] },
          sidebarFilters: {},
        },
      },
    }),
    [session?.person.id]
  );

  return { defaults, tabStoreDefaults };
};
export const useAttachmentsTableColumns = ({
  entityConstants,
  fieldLabels,
  sort,
  setSort,
}: UseEntityTableProps<"attachments">) => {
  const columns = useMemo(() => {
    let headers: GenericVirtualizedTableCells<Dataset> = [
      {
        id: "default-id",
        Header: fieldLabels.id,
        accessor: (row) => (
          <span style={{ color: "var(--gray-400)" }}>
            <samp>{row.id}</samp>
          </span>
        ),
        width: 135,
        minWidth: 135,
        align: "right",
        sortingFn: () => {
          if (sort.headerId === "default-id") {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "ID_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "ID_ASC" }));
            }
          } else {
            setSort({ headerId: "default-id", sortDirection: "ASC", orderBy: "ID_ASC" });
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
      {
        id: "default-name",
        Header: fieldLabels.name,
        accessor: (row) => (
          <div className={`${styles.container_ellipsis} gap-5`}>
            <div className={`${styles.container_ellipsis}`}>
              <span className={styles.label_highlight}>
                <LucideIcon name={entityConstants.icon} color={"var(--primary)"} /> {row.name || NotAvailable}
              </span>
            </div>
            {row.isDeleted && (
              <div>
                <label className="label label-soft-warning" title="Trashed" style={{ margin: 0 }}>
                  <LucideIcon name="trash" />
                </label>
              </div>
            )}
          </div>
        ),
        minWidth: 150,
        width: 400,
        align: "left",
        sortingFn: () => {
          if (sort.headerId === "default-name") {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "NAME_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "NAME_ASC" }));
            }
          } else {
            setSort({ headerId: "default-name", sortDirection: "ASC", orderBy: "NAME_ASC" });
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
      {
        id: "default-viewableEntityType",
        Header: fieldLabels.viewableEntityType,
        accessor: (row) => (
          <label className="label label-soft-info" style={{ margin: 0 }}>
            {row.viewableEntityType !== "ELN" ? row.viewableEntityType : "Notebook"}
          </label>
        ),
        width: 200,
        align: "left",
      },
      {
        id: "default-createdOn",
        Header: fieldLabels.createdOn,
        accessor: (row) => <DateTimeRenderer date={row.createdOn} includeElapsed={false} />,
        width: 200,
        align: "left",
        sortingFn: () => {
          if (sort.headerId === "default-createdOn") {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "CREATED_ON_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "CREATED_ON_ASC" }));
            }
          } else {
            setSort({ headerId: "default-createdOn", sortDirection: "ASC", orderBy: "CREATED_ON_ASC" });
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
      {
        id: "default-createdBy",
        Header: fieldLabels.createdBy,
        accessor: (row) => <GetPersons persons={row.createdBy} createLinks={false} />,
        width: 200,
        align: "left",
        sortingFn: () => {
          if (sort.headerId === "default-createdBy") {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "CREATED_BY_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "CREATED_BY_ASC" }));
            }
          } else {
            setSort({ headerId: "default-createdBy", sortDirection: "ASC", orderBy: "CREATED_BY_ASC" });
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
      {
        id: "default-modifiedOn",
        Header: fieldLabels.modifiedOn,
        accessor: (row) => <DateTimeRenderer date={row.modifiedOn} includeElapsed={false} />,
        width: 200,
        align: "left",
        sortingFn: () => {
          if (sort.headerId === "default-modifiedOn") {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "MODIFIED_ON_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "MODIFIED_ON_ASC" }));
            }
          } else {
            setSort({ headerId: "default-modifiedOn", sortDirection: "ASC", orderBy: "MODIFIED_ON_ASC" });
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
      {
        id: "default-modifiedBy",
        Header: fieldLabels.modifiedBy,
        accessor: (row) => <GetPersons persons={row.modifiedBy} createLinks={false} />,
        width: 200,
        align: "left",
        sortingFn: () => {
          if (sort.headerId === "default-modifiedBy") {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "MODIFIED_BY_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "MODIFIED_BY_ASC" }));
            }
          } else {
            setSort({ headerId: "default-modifiedBy", sortDirection: "ASC", orderBy: "MODIFIED_BY_ASC" });
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
      {
        id: "default-format",
        Header: fieldLabels.format,
        accessor: (row) => (
          <div className={styles.container_ellipsis}>
            <span>{row.format?.name ?? NotSet}</span>
          </div>
        ),
        width: 200,
        align: "left",
      },
      {
        id: "default-owner",
        Header: fieldLabels.owner,
        accessor: (row) => row.owner?.name || NotSet,
        width: 200,
        align: "left",
      },
      {
        id: "default-notes",
        Header: fieldLabels.notes,
        accessor: (row) => (
          <div className={styles.container_ellipsis}>
            <span>{row.notes || NotSet}</span>
          </div>
        ),
        width: 200,
        align: "left",
      },
    ];

    return headers;
  }, [fieldLabels, setSort, sort.headerId, sort.sortDirection, entityConstants.icon]);

  return { columns };
};

export const AttachmentsTable = ({
  entityApi,
  entityConstants,
  fieldLabels,
  permissions,
  routes,
  setCurrentTab,
}: EntityTableProps<"attachments"> & AttachmentsTableOptions) => {
  const { api } = useContext(SessionContext);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const { bulkDeleteMutationAsync, bulkRestoreMutationAsync, isLoadingBulkRestoreMutation } = entityApi;

  const { defaults, tabStoreDefaults } = useAttachmentsTableDefaults({ fieldLabels });

  const { selection, resultsCount, selectionPermissions, onCountChange, onSelectionChange, onSelectionPermissions } =
    useGenericVirtualizedTable<Dataset>();

  const {
    filters,
    forcedFilters,
    sidebarFilters,
    columnSetting,
    columnWidths,
    customTabs,
    fixedTabs,
    dispatchTabStore,
    currentTab,
    tabsLoading,
    tabsModified,
  } = useTabStore<Dataset, DatasetFilters, DatasetsFilterForm>({
    resource: entityConstants.resource,
    defaults: tabStoreDefaults,
    keyOverride: "tableTabs-media",
  });

  useEffect(() => {
    if (!tabsLoading && setCurrentTab) {
      (async () => await dispatchTabStore({ type: "setCurrentTab", tabId: setCurrentTab }))();
    }
  }, [dispatchTabStore, setCurrentTab, tabsLoading]);

  const { functionRef, sort, setSort, searchValue, setSearchValue, onTabChange } = useGenericVirtualizedTableTabs({
    tabsLoading,
    filters,
    switchSortState: switchMediaDefaultSortState,
    dispatchTabStore,
  });

  const memoizedFilters = useMemo(
    () => ({
      ...filters,
      ...forcedFilters,
      isViewableEntity: true,
    }),
    [filters, forcedFilters]
  );

  const { columns } = useAttachmentsTableColumns({ entityConstants, sort, setSort, fieldLabels });

  const handleDownloadAll = useCallback(async () => {
    if (selection.size > 0) {
      setLoading(true);
      try {
        const ids = Array.from(selection);
        const zipSize: ZipFileSize = await api.post("datasets/zip_size", {
          ids: ids,
          includeViewableEntities: true,
        } as DatasetFilters);
        if (zipSize.isDownloadable) {
          await getDatasetsZip(api, { ids: ids, includeViewableEntities: true });
        } else {
          if (zipSize.datasetCount > zipSize.maxDatasetCountPerZipFile) {
            showtoast(
              "error",
              `Selected item count: ${zipSize.datasetCount} exceed maximum allowed count: ${zipSize.maxDatasetCountPerZipFile}`
            );
          } else if (zipSize.size > zipSize.maxZipFileSize) {
            showtoast(
              "error",
              `Selected item bundle size: ${formatBytes(
                zipSize.size
              )} exceeds maximum allowed bundle size: ${formatBytes(zipSize.maxZipFileSize)}`
            );
          } else {
            showtoast(
              "error",
              `Selected item: ${zipSize.datasetCount} (${formatBytes(zipSize.size)}) exceed maximum allowed bundle:  ${
                zipSize.maxDatasetCountPerZipFile
              } (${formatBytes(zipSize.maxZipFileSize)})`
            );
          }
        }
        setLoading(false);
      } catch {
        showtoast("error", "An error occurred while processing your request");
      }
      setLoading(false);
    }
  }, [api, selection]);

  return (
    <TableView>
      <TableView.Head>
        <TableView.Head.Label>
          <TableViewLabel entityConstants={entityConstants} resultsCount={resultsCount} />
        </TableView.Head.Label>
      </TableView.Head>
      <TableView.Body>
        <TableView.Body.Sidebar>
          <AttachmentsFilterbar
            initialValues={sidebarFilters}
            dispatchTabStore={dispatchTabStore}
            tabsLoading={tabsLoading}
            currentTab={currentTab}
          />
        </TableView.Body.Sidebar>
        <TableView.Body.Content>
          <EntityTableTabs
            currentTab={currentTab}
            onTabChange={onTabChange}
            fixedTabs={fixedTabs}
            filters={{ ...filters, includeParsingState: true }}
            sidebarFilters={sidebarFilters}
            columnSetting={columnSetting}
            columnWidths={columnWidths}
            customTabs={customTabs}
            dispatchTabStore={dispatchTabStore}
            tabsLoading={tabsLoading}
            tabsModified={tabsModified}
          />
          <EntityTable>
            <EntityTable.Controls
              style={{
                borderTop: "0px",
                borderRadius: "0px",
              }}
            >
              <EntityFilterIndicator<Dataset, DatasetFilters>
                filters={filters}
                excludeFilters={{
                  includeParsingState: () => false,
                  includeSoftDeleted: (value) => value === true,
                  isSoftDeleted: () => false,
                  participatedPersonIds: () => false,
                  includeUnclaimed: (value) => false,
                  isViewableEntity: () => false,
                  viewableEntityTypes: () => false,
                }}
              />
              <SearchInput searchValue={searchValue} setSearchValue={setSearchValue} placeholder="Search" />
              <button
                className="btn btn btn-primary"
                disabled={selection.size === 0 || loading}
                onClick={handleDownloadAll}
                title="Download the selected item"
              >
                <LucideIcon name="download" /> Download
              </button>
              <MoreDropdown drop="right" btn="btn btn-ghost-secondary">
                {currentTab === "trash" && (
                  <li>
                    <EntityTableRestoreButton
                      entityConstants={entityConstants}
                      selection={selection}
                      permissions={permissions}
                      selectionPermissions={selectionPermissions}
                      onClick={async () =>
                        await bulkRestoreMutationAsync({ ids: Array.from(selection) }).catch(() => {})
                      }
                      loading={isLoadingBulkRestoreMutation}
                    />
                  </li>
                )}
                <li>
                  <EntityTableSoftDeletableButton
                    currentTab={currentTab}
                    entityConstants={entityConstants}
                    selection={selection}
                    permissions={permissions}
                    selectionPermissions={selectionPermissions}
                    onClick={() => setShowModal(true)}
                  />
                </li>
              </MoreDropdown>
              <AlertModal
                type={`${currentTab === "trash" ? "danger" : "warning"}`}
                showModal={showModal}
                setShowModal={setShowModal}
                title={`${
                  selection.size === 0
                    ? `Select item to ${currentTab === "trash" ? "delete" : "trash"}`
                    : selection.size === 1
                    ? `${currentTab === "trash" ? "Delete" : "Trash"} the selected item?`
                    : `${currentTab === "trash" ? "Delete" : "Trash"} the selected items?`
                }`}
                description={`${
                  currentTab === "trash"
                    ? "Proceeding will permanently delete the selected items."
                    : "Proceeding will move the selected items into trash."
                }`}
                proceedLabel={`${currentTab === "trash" ? "Delete" : "Trash"}`}
                onProceed={async () => {
                  await bulkDeleteMutationAsync(
                    {
                      ids: Array.from(selection),
                      goBackOnSuccess: false,
                      showToast: false,
                      entityName: "items",
                      params: { deletePermanently: currentTab === "trash" },
                    },
                    {
                      onSettled: () => {
                        // functionRef.current?.resetSelection();
                      },
                    }
                  ).catch((e) => {});

                  functionRef.current?.resetSelection();
                  setShowModal(false);
                }}
              />
            </EntityTable.Controls>
            <EntityTable.Body<Dataset, DatasetFilters>
              // tabStoreDefaults={tabStoreDefaults}
              functionRef={functionRef}
              entityConstants={entityConstants}
              filters={memoizedFilters}
              columns={columns}
              columnSelect
              columnSetting={columnSetting}
              columnWidths={columnWidths}
              defaultColumnSettings={defaults}
              dispatchTabStore={dispatchTabStore}
              setResultsCount={onCountChange}
              onSelectionChange={onSelectionChange}
              onSelectionPermissions={onSelectionPermissions}
              showPermissionColumn
              loading={tabsLoading}
              // onRowClick={(row) => setELNMediaRoute(row.id)}
            />
          </EntityTable>
        </TableView.Body.Content>
      </TableView.Body>
    </TableView>
  );
};
