import {
  PropertyTranslator,
  IGenericRequestParametersTranslator,
  IPaginationParametersTranslator,
  IRelationParametersTranslator,
  ICreatedRecordParametersTranslator,
  IModifiedRecordParametersTranslator,
  ICreationRecordLabels,
  IModificationRecordLabels,
  INamedEntityLabels,
  IPermissionedEntityLabels,
  IRelatedEntityLabels,
} from "./GenericTranslator";
import {
  DefaultCreatedByRecordOrderTypeConsts,
  DefaultCreatedOnRecordOrderTypeConsts,
  DefaultModifiedByRecordOrderTypeConsts,
  DefaultModifiedOnRecordOrderTypeConsts,
  DefaultOrderTypeConsts,
  EntityConstants,
  ICreatedRecordParameters,
  ICreationRecord,
  IEntityMinimalModel,
  IGenericRequestParameters,
  IModelWithIntId,
  IModificationRecord,
  IModifiedRecordParameters,
  INamedEntity,
  IPaginationParameters,
  IPermissionedEntity,
  IRelatedEntity,
  IRelation,
  IRelationModel,
  IRelationParameters,
} from "./GenericTypes";

export const personTagsConstants: EntityConstants<PersonTag, PersonTagFilters> = {
  resource: "person_tags",
  frontendIndexRoute: "person_tags",
  entitySingular: "person tag",
  entityPlural: "person tags",
  icon: "tag",
};

export const PersonTagFieldLabels: PropertyTranslator<PersonTag> & { persons: string } = {
  ...INamedEntityLabels,
  ...IPermissionedEntityLabels,
  ...ICreationRecordLabels,
  ...IModificationRecordLabels,
  ...IRelatedEntityLabels,
  id: "Person tag ID",
  notes: "Notes",
  persons: "Persons",
} as const;

export interface PersonTagRelations extends IRelationModel {
  persons: IRelation;
}

export interface PersonTag
  extends IPermissionedEntity,
    IModelWithIntId,
    INamedEntity,
    IRelatedEntity<PersonTagRelations>,
    ICreationRecord,
    IModificationRecord {
  notes: string;
}
export interface PersonTagSuggestions extends IEntityMinimalModel<PersonTag["id"]> {}

export interface PersonTagFilters
  extends IGenericRequestParameters<PersonTag, PersonTagSortingOptions>,
    IPaginationParameters,
    IRelationParameters,
    ICreatedRecordParameters,
    IModifiedRecordParameters {
  notes?: string | null;
}
export const PersonTagFiltersTranslator: PropertyTranslator<PersonTagFilters> = {
  ...IGenericRequestParametersTranslator,
  ...IPaginationParametersTranslator,

  ...IRelationParametersTranslator,
  ...ICreatedRecordParametersTranslator,
  ...IModifiedRecordParametersTranslator,
  notes: "Notes",
} as const;

export const PersonTagSortingOptionsConsts = [
  ...DefaultOrderTypeConsts,
  ...DefaultCreatedOnRecordOrderTypeConsts,
  ...DefaultCreatedByRecordOrderTypeConsts,
  ...DefaultModifiedOnRecordOrderTypeConsts,
  ...DefaultModifiedByRecordOrderTypeConsts,
] as const;
export type PersonTagSortingOptions = (typeof PersonTagSortingOptionsConsts)[number];
