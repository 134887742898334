import produce from "immer";
import { useCallback, useEffect, useRef, useState } from "react";
import ReactResizeDetector from "react-resize-detector";

import { viewerPropsType } from "../ViewerLayout/ViewerLayoutTypes";
import { clickType, offsetType, viewerState as _viewerState } from "../ViewerLayout/ViewerTypes";
import { Graph } from "./Graph";
import styles from "./SpectrumComponent.module.css";
import { ViewerNavigation } from "./ViewerNavigation/ViewerNavigation";
import { data1DType as _data1DType, data2DMatrixType as _data2DMatrixType } from "./ViewerTrackType";

// import { TreeViewer } from "./TreeViewer";
// type trackType = _trackType;
// export type trackParameterType = _trackParameterType;
// export const initTrackParameterType = _initTrackParameterType;
// export const initTrackType = _initTrackType;
export type data1DType = _data1DType;
export type data2DMatrixType = _data2DMatrixType;
export type SpectrumComponentState = _viewerState;
// export type annotationType = _annotationType;
// export type dataTrackType = _dataTrackType;
// export type drawType = _drawType;

// export type trackType = {
//   id?: string;
//   type: "1D_real" | "1D_complex" | "2D_real_matrix";
//   data?: any;
//   parameter: graphTrackParameterType;
// };

// const getSelectedTracks = (tracks: Record<string, Track>) => {
//   const selected = Object.values(tracks)
//     .filter((track) => track.settings.active && track.settings.selected)
//     .map((track) => track.id);

//   if (selected.length === 0) return Object.values(tracks).map((track) => track.id);
//   return selected;
// };

export const SpectrumComponent = ({
  tracks,
  setTracksToZoom,
  // setZoomMode,
  setViewerMode,
  viewerMode,
  // selectedTracks,
  changePipelineParameter,
  changeTrackState,
  changeTrackSettings,
  viewPort,
  onDataExport,
  pipelines,
  pipelineStates,
  showNavigation,
  datatracks,
  annotations,
  tracksToZoom,
  setViewerState,
  initViewerState,
  parserLogs,
  interactiveMode,
  api,
}: viewerPropsType) => {
  const [offsetState, setOffsetState] = useState<offsetType>({});
  const [autoZoom, setAutoZoom] = useState(viewerMode.autoZoom);
  const [lastClick, setLastClick] = useState<clickType>({ count: 0, x: 0, y: 0 });
  const graphRef = useRef();
  // const selectedTracks = useTranslatedFieldUpdate(tracks, getSelectedTracks, []);

  // console.log("viewerMode", viewerMode);
  // const [tracks, setTracks] = useState([]);
  const resetZoom = useCallback(
    () =>
      setTracksToZoom
        ? setTracksToZoom(Object.keys(tracks).filter((id) => tracks[id].settings.visible && tracks[id].settings.active))
        : null,
    [setTracksToZoom, tracks]
  );

  const getTranslateScale = useCallback(() => {
    if (graphRef?.current) return (graphRef.current as any).getTranslateScale();
    return {};
  }, []);

  const setNewOffset = useCallback(
    (value: offsetType) => {
      setOffsetState(
        produce(offsetState, (next) => {
          if (value?.translateX !== undefined) next.translateX = value.translateX;
          if (value?.translateY !== undefined) next.translateY = value.translateY;
          // Object.entries(value).forEach(([k, v]) => (next[k as keyof offsetType] = v));
        })
      );
      // }
    },
    [offsetState]
  );

  useEffect(() => {
    if (setViewerMode)
      setViewerMode(
        produce(viewerMode, (next) => {
          next.autoZoom = autoZoom;
        })
      );

    // set viewerMode is set here and the effect should not be dependent on it
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoZoom, setViewerMode]);

  const onExport = useCallback(
    (type: string) => {
      // console.log(`Export as '${type}'`);
      if (graphRef?.current) {
        (graphRef.current as any).exportAs(type);

        // the following is for the case where the export is done by ViewerLayout
        // if (type === "csv")
        //   onDataExport(
        //     type,
        //     Object.values(tracks)
        //       .filter((track) => track.settings.active && track.settings.visible)
        //       .map((track) => track.id)
        //   );
        // else (graphRef.current as any).exportAs(type);
      }
    },
    [tracks, onDataExport]
  );

  const onZoomUpdate = useCallback(() => {
    if (viewerMode.zoomUpdate)
      if (setViewerMode)
        setViewerMode(
          produce(viewerMode, (next) => {
            next.zoomUpdate = false;
          })
        );
  }, [setViewerMode, viewerMode]);

  // useEffect(() => {
  //   const p = (Object.values(pipelines)?.[0].pipeline?.[0] as any)?.parameter;
  //   if (p) console.log("pipelines", Object.keys(p));
  // }, [pipelines]);

  return (
    <div id="viewer-container" className={styles.viewer}>
      {showNavigation === undefined || showNavigation === true ? (
        <div className={styles.viewerNavigation}>
          <ViewerNavigation
            // className={styles.viewerNavigation}
            resetZoom={resetZoom}
            parserLogs={parserLogs}
            // setZoomMode={(zoomMode: zoomModes) =>
            //   setViewerMode(
            //     produce(viewerMode, (next) => {
            //       next.zoomMode = zoomMode;
            //     })
            //   )
            // }
            setViewerMode={setViewerMode ?? (() => null)}
            // setViewerMode={() => {}}
            viewerMode={viewerMode}
            tracks={tracks}
            datatracks={datatracks}
            // selectedTracks={selectedTracks}
            // selectedTracks={[]}
            // setSliderCurrent={setActiveTrack}
            changeTrackSettings={changeTrackSettings ?? (() => null)}
            changePipelineParameter={changePipelineParameter ?? (() => null)}
            autoZoom={autoZoom}
            setAutoZoom={setAutoZoom}
            onExport={onExport}
            setOffset={setNewOffset}
            // offset={offset}
            getTranslateScale={getTranslateScale}
            viewPort={viewPort}
            pipelines={pipelines}
            pipelineStates={pipelineStates}
            graphClick={lastClick}
          />
        </div>
      ) : null}

      <ReactResizeDetector handleWidth handleHeight>
        <Graph
          api={api}
          tracks={tracks}
          activeTrack={""}
          tracksToZoom={tracksToZoom}
          annotations={annotations}
          datatracks={datatracks}
          viewerMode={viewerMode}
          // activeTrack={activeTrack}
          changeTrackSettings={changeTrackSettings ?? (() => null)}
          changeTrackState={changeTrackState ?? (() => null)}
          setViewerState={setViewerState}
          autoZoom={autoZoom}
          ref={graphRef}
          offset={offsetState}
          interactiveMode={interactiveMode}
          zoomUpdate={viewerMode.zoomUpdate}
          onZoomUpdate={onZoomUpdate}
          clickListener={(pos: { x: number | undefined; y: number | undefined }) =>
            setLastClick(
              produce(lastClick, (next) => {
                next.count++;
                next.x = pos.x ?? Number.NaN;
                next.y = pos.y ?? Number.NaN;
              })
            )
          }
          initViewerState={initViewerState as SpectrumComponentState}
          // {...otherProps}
        />
      </ReactResizeDetector>
    </div>
  );
};
