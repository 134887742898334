import React, { CSSProperties, useCallback, useEffect, useMemo, useRef } from "react";
import {
  EntityConstants,
  GenericEntity,
  IPaginationParameters,
  RequiredKeyAndValue,
  StringIndexedDict,
} from "../../api/GenericTypes";
import { useEntityCount, useInfiniteListEntity } from "../../api/BaseEntityApi";
import { LoadingWrapper } from "../LoadingWrapper";
import InfiniteLoader from "react-window-infinite-loader";
import styles from "./EntityList.module.css";
import { FixedSizeList } from "react-window";
import { Skeleton } from "../loaders/Skeleton/Skeleton";
import { Alert } from "../overlays/Alert/Alert";
import { useResizeDetector } from "react-resize-detector";

export const EntityList = <Entity extends GenericEntity, Filters extends StringIndexedDict & IPaginationParameters>({
  entityConstant,
  pageSize = 10,
  rowRenderer,
  filters,
  rowHeight,
  noItemMessage = "No items",
  countCallback,
  currentIndex,
  currentEntityCallback,
  enabled = true,
}: {
  entityConstant: EntityConstants;
  pageSize?: number;
  rowRenderer: (entity: Entity, index: number) => React.ReactNode;
  filters?: Filters;
  rowHeight: number;
  noItemMessage?: string;
  countCallback?: (count: number) => void;
  currentIndex?: number;
  currentEntityCallback?: (entity: Entity) => void;
  enabled?: boolean;
}) => {
  const { data, isFetching, hasNextPage, fetchNextPage, isFetched, fetchStatus, status, error } = useInfiniteListEntity<
    Entity,
    Filters
  >(
    entityConstant.resource,
    { ...filters, page: 1, pageSize } as RequiredKeyAndValue<Filters, "page">,
    { enabled: enabled },
    "post"
  );

  const entities = useMemo(() => {
    return data?.pages.map((d) => d.results).flat() || [];
  }, [data?.pages]);

  const fetchNext = useCallback(() => {
    if (hasNextPage) {
      fetchNextPage();
    }
  }, [fetchNextPage, hasNextPage]);

  const loadMoreCallback = useCallback(() => {}, []);
  const itemCount = hasNextPage ? entities.length + 1 : entities.length;
  const loadMoreItems = isFetching ? loadMoreCallback : fetchNext;
  const isItemLoaded = useCallback(
    (index: number) => !hasNextPage || index < entities.length,
    [hasNextPage, entities.length]
  );

  const Item = useCallback(
    ({ index, style }: { index: number; style: CSSProperties }) => {
      const current_element = entities[index];
      if (!isItemLoaded(index) || !isFetched) {
        return (
          <div
            style={{
              ...style,
              overflow: "auto",
              overflowY: "hidden",
              maxHeight: `${rowHeight}px`,
            }}
            key={index}
          >
            <span className="skeleton-block btn-lg" style={{ height: `${rowHeight - 8}px` }} />
          </div>
        );
      } else {
        return (
          <Row
            rowIndex={index}
            style={style}
            current_element={current_element}
            currentIndex={currentIndex}
            rowRenderer={rowRenderer}
            rowHeight={rowHeight}
          />
        );
      }
    },
    [currentIndex, entities, isFetched, isItemLoaded, rowHeight, rowRenderer]
  );

  const { ref, height } = useResizeDetector();

  // count callback
  useEntityCount(entityConstant.resource, filters, {
    enabled: !!countCallback,
    onSuccess: (data) => {
      countCallback?.(data.count);
    },
  });

  useEffect(() => {
    currentIndex !== undefined &&
      currentIndex >= 0 &&
      currentIndex < entities.length &&
      currentEntityCallback?.(entities[currentIndex]);
  }, [entities, currentIndex, currentEntityCallback]);

  useEffect(() => {
    if (currentIndex === entities.length - 1) loadMoreCallback();
  }, [currentIndex, entities.length, loadMoreCallback]);

  return (
    <div ref={ref} style={{ height: "100%", width: "100%" }}>
      <LoadingWrapper status={status} error={error} fetchStatus={fetchStatus} type="skeleton-rows">
        <div className={isFetching ? `${styles.container} ${styles.container_loading}` : styles.container}>
          {entities && entities.length > 0 ? (
            <InfiniteLoader isItemLoaded={isItemLoaded} itemCount={itemCount} loadMoreItems={loadMoreItems as any}>
              {({ onItemsRendered, ref }) => (
                <FixedSizeList
                  itemCount={itemCount}
                  onItemsRendered={onItemsRendered}
                  ref={ref}
                  width="100%"
                  height={height ?? 0}
                  itemSize={rowHeight}
                >
                  {Item}
                </FixedSizeList>
              )}
            </InfiniteLoader>
          ) : (
            <>{isFetching ? <Skeleton type="rows" /> : <Alert type="light" message={noItemMessage} fit centered />}</>
          )}
        </div>
      </LoadingWrapper>
    </div>
  );
};

const Row = <Entity extends GenericEntity>({
  rowIndex,
  currentIndex,
  style,
  current_element,
  rowHeight,
  rowRenderer,
}: {
  rowIndex: number;
  currentIndex?: number;
  style: CSSProperties;
  current_element: Entity;
  rowHeight: number;
  rowRenderer: (entity: Entity, index: number) => React.ReactNode;
}) => {
  const currentEntityRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    if (currentEntityRef.current && rowIndex === currentIndex)
      currentEntityRef.current.scrollIntoView({ behavior: "smooth", block: "nearest" });
  }, [currentIndex, rowIndex]);
  return (
    <div
      style={{
        ...style,
        overflow: "auto",
        overflowY: "hidden",
        maxHeight: `${rowHeight}px`,
      }}
      key={rowIndex}
      ref={currentEntityRef}
    >
      {rowRenderer(current_element, rowIndex)}
    </div>
  );
};
