type SIPrefix = {
  left: number;
  right: number;
  prefix: string;
  scale: number;
  inverse: number;
};

export class ParameterFormatter {
  SI_ranges: SIPrefix[];
  decimalRegex: RegExp;

  constructor() {
    this.decimalRegex = /^[-+]?[0-9]+|^[-+]?[0-9]+\.[0-9]+$/;

    this.SI_ranges = [
      { left: -Infinity, right: -12, prefix: "f", scale: -15, inverse: 0 },
      { left: -12, right: -9, prefix: "p", scale: -12, inverse: 0 },
      { left: -9, right: -6, prefix: "n", scale: -9, inverse: 0 },
      { left: -6, right: -3, prefix: "\u00B5", scale: -6, inverse: 0 },
      { left: -3, right: -2, prefix: "m", scale: -3, inverse: 0 },
      { left: -2, right: 0, prefix: "c", scale: -2, inverse: 0 },
      { left: 0, right: 3, prefix: "", scale: 0, inverse: 0 },
      { left: 3, right: 6, prefix: "k", scale: 3, inverse: 0 },
      { left: 6, right: 9, prefix: "M", scale: 6, inverse: 0 },
      { left: 9, right: 12, prefix: "G", scale: 9, inverse: 0 },
      { left: 12, right: 15, prefix: "T", scale: 12, inverse: 0 },
      { left: 15, right: Infinity, prefix: "P", scale: 15, inverse: 0 },
    ];

    for (let i = 0; i < this.SI_ranges.length; i++) {
      this.SI_ranges[i].left = isFinite(this.SI_ranges[i].left)
        ? Math.pow(10, this.SI_ranges[i].left)
        : this.SI_ranges[i].left;
      this.SI_ranges[i].right = isFinite(this.SI_ranges[i].right)
        ? Math.pow(10, this.SI_ranges[i].right)
        : this.SI_ranges[i].right;
      this.SI_ranges[i].inverse = Math.pow(10, -this.SI_ranges[i].scale);
      this.SI_ranges[i].scale = Math.pow(10, this.SI_ranges[i].scale);
      // console.log("TEST", i, SI_ranges[i]);
    }
  }

  formatSIPrefix(value: number | string, suffix: string, prefix?: string): [number | string, string] {
    if (typeof value !== "number") return [value, suffix];
    if (value === 0) return [value, ""];
    var sign = 1;
    if (value < 0) {
      sign = -1;
      value = -value;
    }

    if (prefix !== undefined) {
      for (let i = 0; i < this.SI_ranges.length - 1; i++) {
        const range = this.SI_ranges[i];
        if (prefix === range.prefix) {
          return [sign * value * range.inverse, range.prefix + suffix];
        }
      }
    }

    for (let i = 0; i < this.SI_ranges.length - 1; i++) {
      const range = this.SI_ranges[i];
      // console.log("exp", range.left, "<", value, "<", range.right, "=>", range.prefix)
      if (range.left <= value && value < range.right) {
        // console.log("found", range.inverse)
        // console.log("----------------------------------------")
        return [sign * value * range.inverse, range.prefix + suffix];
      }
    }

    return [value, ""];
  }

  formatDefaultUnit(value: number | string, unit: string, desiredUnit?: string) {
    let prefix: string | undefined, suffix;
    if (desiredUnit) {
      suffix = desiredUnit.substring(1, 1 + unit.length);
      if (suffix === "G" && unit === "T") {
        value = (value as number) / 10000;
        unit = "G";
      }
      if (suffix === unit) prefix = desiredUnit.substring(0, 1);
      if (prefix === "") prefix = undefined;
    }

    return this.formatSIPrefix(value, unit, prefix);
  }

  formatDuration(duration: number | string, precision: number = 3): [string | number, string | undefined] {
    if (typeof duration !== "number") return ["", ""];

    if (duration > 60) {
      var years = Math.floor(duration / (365 * 60 * 60 * 24));
      var days = Math.floor((duration % (365 * 60 * 60 * 24)) / (60 * 60 * 24));
      var hours = Math.floor((duration % (60 * 60 * 24)) / (60 * 60));
      var minutes = Math.floor((duration % (60 * 60)) / 60);
      var seconds = Math.floor(duration % 60);

      const rv: string[] = [];
      if (years !== 0) rv.push(years + " y");
      if (days !== 0) rv.push(days + " d");
      if (hours !== 0) rv.push(hours + " h");
      if (minutes !== 0) rv.push(minutes + " min");
      if (seconds !== 0) rv.push(seconds + " s");
      return [rv.join(" "), undefined];
    }

    if (duration > 1) return [parseFloat(duration.toFixed(precision)), "s"];
    if (duration > 0.001) return [parseFloat((duration * 1000).toFixed(precision)), "ms"];
    if (duration > 0.000001) return [parseFloat((duration * 1000000).toFixed(precision)), "μs"];
    if (duration > 0.000000001) return [parseFloat((duration * 1000000000).toFixed(precision)), "ns"];
    return [duration, "s"];
  }

  FormatParameterValue(
    value: number | string,
    type: string,
    decimal?: number,
    unit?: string,
    formatter?: string
  ): string {
    // console.log("value", value, "->", type);

    if (Array.isArray(value)) {
      value = value.map((v) => this.FormatParameterValue(v, type, decimal, unit, formatter)).join(", ");
      type = "string";
      decimal = undefined;
      formatter = undefined;
      unit = undefined;
      // console.log(">>>>", value.map((v) => this.FormatParameterValue(v, type, decimal, unit, formatter) ).join("|"));
    }

    if (decimal) type = "float";

    if (type === "float" || type === "int") {
      if (typeof value !== "number") {
        if (typeof value === "string") {
          console.log("value", value);
          // if (value.match(this.decimalRegex)) {
          if (value.match(this.decimalRegex)) {
            value = parseFloat(value);
          } else {
            console.log(`ERROR: parameter value '${value}' is not a number.`);
            return "";
          }
        } else {
          console.log(`ERROR: parameter value '${value}' is not a number.`);
          return "";
        }
      }
      if (type === "int") {
        value = parseInt(Math.round(value) as any);
        // delete parameter.decimal;
      }

      if (formatter) {
        let v;
        switch (formatter) {
          case "duration":
            v = this.formatDuration(value);
            value = v[0];
            unit = v[1];
            decimal = undefined;
            break;
          case "length":
            v = this.formatDefaultUnit(value, "m", unit);
            value = v[0];
            unit = v[1];
            break;
          case "voltage":
            v = this.formatDefaultUnit(value, "V", unit);
            value = v[0];
            unit = v[1];
            break;
          case "current":
            v = this.formatDefaultUnit(value, "A", unit);
            value = v[0];
            unit = v[1];
            break;
          case "pressure":
            v = this.formatDefaultUnit(value, "Pa", unit);
            value = v[0];
            unit = v[1];
            break;
          case "frequency":
            v = this.formatDefaultUnit(value, "Hz", unit);
            value = v[0];
            unit = v[1];
            break;
          case "magnetic":
            v = this.formatDefaultUnit(value, "T", unit);
            value = v[0];
            unit = v[1];
            break;
          case "epower":
            v = this.formatDefaultUnit(value, "W", unit);
            value = v[0];
            unit = v[1];
            break;
        }
      }

      if (decimal && decimal >= 0) {
        value = (value as number).toFixed(decimal);
      }

      if (unit) value = value + " " + unit;

      // console.log("result", value, decimal, unit);

      return value as string;
    } else {
      return value as string;
    }
  }
}
