import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { InputFormField } from "../../../formfields/InputFormField";
import { FormButtons } from "../../FormButtons";
import { FormHeader } from "../../FormHeader";
import { ProjectsVirtualizedSelectForm } from "../../EntityForms/formsVirtualized/ProjectsVirtualizedSelectForm";
import { TextareaFormField } from "../../../formfields/TextareaFormField";
import { useCallback } from "react";
import { useQuickEditSubmit } from "../common/useQuickEditHooks";
import { QuickEditChildrenProps } from "../common/QuickEditWrapper";
import {
  LabNotebookTemplate,
  labNotebookTemplateFieldLabels,
  labNotebookTemplatesConstants,
} from "../../../../ELN/types/LabNotebookTemplate";

export const QuickEditLabNotebookTemplateForm = ({
  onEdit,
  onCancel,
  initialValues,
}: QuickEditChildrenProps<LabNotebookTemplate>) => {
  const { onSubmit } = useQuickEditSubmit<LabNotebookTemplate>({
    resource: labNotebookTemplatesConstants.resource,
    onEdit: onEdit,
    onCancel: onCancel,
  });

  const FormSchema = yup.object().shape({
    name: yup
      .string()
      .required("Lab notebook template name is required")
      .typeError("Lab notebook template name is required"),
  });

  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
  } = useForm<LabNotebookTemplate>({
    values: initialValues,
    resolver: yupResolver(FormSchema),
  });

  const formValuesToEntity = useCallback(
    (entity: LabNotebookTemplate) =>
      onSubmit(entity.id, { ...entity, content: undefined, concurrencyToken: entity.modifiedOn }),
    [onSubmit]
  );

  return (
    <>
      <FormHeader title="Edit lab notebook template" subtitle={initialValues?.name} />

      <InputFormField
        id="name"
        label={labNotebookTemplateFieldLabels.name}
        errors={errors}
        register={register}
        autoFocus={true}
        required
        placeholder="Enter lab notebook template name..."
      />

      <ProjectsVirtualizedSelectForm
        id="projects"
        label={labNotebookTemplateFieldLabels.projects}
        control={control}
        showControls
        horizontal
        isMulti
        filters={{ currentUserHasAddPermission: true }}
      />

      <TextareaFormField
        id="description"
        label={labNotebookTemplateFieldLabels.description}
        errors={errors}
        register={register}
        placeholder="Enter description..."
      />

      <FormButtons
        groupName={labNotebookTemplatesConstants.frontendIndexRoute}
        entityId={initialValues?.id}
        onClose={onCancel}
        onSubmit={handleSubmit(formValuesToEntity)}
        disabled={isSubmitting}
        errors={errors}
        disableDefaultOnClose
      />
    </>
  );
};
