import {
  PropertyTranslator,
  IGenericRequestParametersTranslator,
  IPaginationParametersTranslator,
  IRelationParametersTranslator,
  ICreatedRecordParametersTranslator,
  IModifiedRecordParametersTranslator,
  ICreationRecordLabels,
  IModificationRecordLabels,
  INamedEntityLabels,
  IPermissionedEntityLabels,
  IRelatedEntityLabels,
} from "./GenericTranslator";
import {
  DefaultCreatedByRecordOrderTypeConsts,
  DefaultCreatedOnRecordOrderTypeConsts,
  DefaultModifiedByRecordOrderTypeConsts,
  DefaultModifiedOnRecordOrderTypeConsts,
  DefaultOrderTypeConsts,
  EntityConstants,
  ICreatedRecordParameters,
  ICreationRecord,
  IEntityMinimalModel,
  IGenericRequestParameters,
  IModelWithIntId,
  IModificationRecord,
  IModifiedRecordParameters,
  INamedEntity,
  IPaginationParameters,
  IPermissionedEntity,
  IRelatedEntity,
  IRelation,
  IRelationModel,
  IRelationParameters,
} from "./GenericTypes";

export const projectTagsConstants: EntityConstants<ProjectTag, ProjectTagFilters> = {
  resource: "project_tags",
  frontendIndexRoute: "project_tags",
  entitySingular: "Project tag",
  entityPlural: "Project tags",
  icon: "tag",
};

export const ProjectTagFieldLabels: PropertyTranslator<ProjectTagFrontendType> = {
  ...INamedEntityLabels,
  ...IPermissionedEntityLabels,
  ...ICreationRecordLabels,
  ...IModificationRecordLabels,
  ...IRelatedEntityLabels,
  id: "Project tag ID",
  notes: "Notes",
  projects: "Projects",
} as const;

export interface ProjectTagRelations extends IRelationModel {
  projects: IRelation;
}

export interface ProjectTag
  extends IPermissionedEntity,
    IModelWithIntId,
    INamedEntity,
    IRelatedEntity<ProjectTagRelations>,
    ICreationRecord,
    IModificationRecord {
  notes: string;
}

export interface ProjectTagFrontendType extends ProjectTag {
  projects: string;
}
export interface ProjectTagSuggestions extends IEntityMinimalModel<ProjectTag["id"]> {}

export interface ProjectTagFilters
  extends IGenericRequestParameters<ProjectTag, ProjectTagSortingOptions>,
    IPaginationParameters,
    IRelationParameters,
    ICreatedRecordParameters,
    IModifiedRecordParameters {
  notes?: string | null;
}

export const ProjectTagFiltersTranslator: PropertyTranslator<ProjectTagFilters> = {
  ...IGenericRequestParametersTranslator,
  ...IPaginationParametersTranslator,

  ...IRelationParametersTranslator,
  ...ICreatedRecordParametersTranslator,
  ...IModifiedRecordParametersTranslator,
  notes: "Notes",
} as const;

export const ProjectTagSortingOptionsConsts = [
  ...DefaultOrderTypeConsts,
  ...DefaultCreatedOnRecordOrderTypeConsts,
  ...DefaultCreatedByRecordOrderTypeConsts,
  ...DefaultModifiedOnRecordOrderTypeConsts,
  ...DefaultModifiedByRecordOrderTypeConsts,
] as const;
export type ProjectTagSortingOptions = (typeof ProjectTagSortingOptionsConsts)[number];
