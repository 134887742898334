import React, { useCallback } from "react";
import { IGenericEntity, IdTypes } from "../../../../api/GenericTypes";
import { Modal } from "../../../../ELN/common/ELNModal/ELNModal";

// Example usage for Forms
// const [showQuickAddMethodModal, setShowQuickAddMethodModal] = useState(false);
// useEffect(() => {
//   if (method && method.id === -1) {
//     setValue("method", null);
//     setShowQuickAddMethodModal(true);
//   }
// }, [method, setValue]);

/* <SelectFormField
id={"method"}
label={"Measuring Method"}
items={[{ id: -1, name: "Add Method" }].concat(methodsSuggestions)}
errors={errors}
control={control}
isMulti={false}
required={true}
hasLabel={true}
horizontal
showErrors={true}
buttons={
  <QuickAddWrapper<Method>
    showModal={showQuickAddMethodModal}
    setShowModal={setShowQuickAddMethodModal}
    onCreate={(newMethod) => {
      if (newMethod && newMethod.id > 0) {
        setValue("method", { id: newMethod.id, name: newMethod.name });
      }
    }}
  >
    {(props) => <QuickAddMethodForm {...props} />}
  </QuickAddWrapper>
}
/> */

export interface QuickAddChildrenProps<Entity extends IGenericEntity<IdTypes>> {
  initialValues?: Partial<Entity>;
  onCreate: (createdEntity: Entity) => void;
  onCancel: () => void;
}

interface QuickAddWrapperProps<Entity extends IGenericEntity<IdTypes>> {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  onCreate: (createdEntity: Entity) => void;
  onCancel?: () => void;
  children: (props: QuickAddChildrenProps<Entity>) => React.ReactNode;
}
export const QuickAddWrapper = <Entity extends IGenericEntity<IdTypes>>({
  showModal,
  setShowModal,
  onCreate,
  onCancel,
  children,
}: QuickAddWrapperProps<Entity>) => {
  const onCreateEntity = useCallback(
    (createdEntity: Entity) => {
      onCreate(createdEntity);
      setShowModal(false);
    },
    [onCreate, setShowModal]
  );
  const _onCancel = useCallback(() => {
    onCancel?.();
    setShowModal(false);
  }, [onCancel, setShowModal]);

  return (
    <Modal isOpen={showModal} onClose={setShowModal}>
      <Modal.Body>
        <div
          className="flex col-nowrap form-group center-horizontally"
          style={{
            width: "70vw",
            height: "100%",
            justifyContent: "flex-start",
            padding: "10px 15px",
          }}
        >
          {children({
            onCreate: (entity) => onCreateEntity(entity),
            onCancel: _onCancel,
          })}
        </div>
      </Modal.Body>
    </Modal>
  );
};
