import React, { useCallback, useContext, useMemo, useState } from "react";
import { useVersion, Version } from "../api/Version";
import Status from "../common/badges/Status/Status";
import { formatIsoDate } from "../common/datetime/DateTimeFormatter";
import { Alert } from "../common/overlays/Alert/Alert";
import SinglePage from "../common/panels/SinglePage/SinglePage";
import { Content, ContentTable } from "../common/tables/ContentTable/ContentTable";
import styles from "./About.module.css";
import { EULA } from "./EULA";
import { SessionContext } from "../common/contexts/SessionContext";

export const About = () => {
  const { session } = useContext(SessionContext);
  const [infoMapping, setInfoMapping] = useState<Content>();
  const [additionalFeatures, setAdditionalFeatures] = useState<Content>();
  const { data: info } = useVersion({ enabled: true });

  const generate_licence_message = useCallback((info: Version) => {
    const expirationDate = formatIsoDate(new Date(info.expirationDate));
    const status = info.daysUntilExpiration > 30 ? "success" : info.daysUntilExpiration >= 0 ? "warning" : "danger";
    const message =
      info.daysUntilExpiration > 30
        ? `Valid until ${expirationDate.datestr} ${expirationDate.timestr} ( ${info.daysUntilExpiration} days left )`
        : info.daysUntilExpiration >= 0
        ? `Valid until ${expirationDate.datestr} ${expirationDate.timestr} ( expiring in ${info.daysUntilExpiration} days )`
        : "Expired";
    return <Alert type={status} message={message} fit />;
  }, []);

  useMemo(() => {
    if (info) {
      let current_mapping: Content = [];

      current_mapping.push(
        { key: "Application", value: <label style={{ margin: 0 }}>{info.application}</label> },
        { key: "Version", value: <samp style={{ margin: 0 }}>{info.version}</samp> },
        { key: "API-Version", value: <samp style={{ margin: 0 }}>{info.apiVersion}</samp> },

        { key: "Edition", value: <span style={{ margin: 0 }}>{info.edition}</span> },
        {
          key: "Licenced",
          value: (
            <div className={styles.container_expiredate}>
              <div className={styles.container_expiredate_date}>
                {/* 2147483647 = 2**31 - 1 (maximum for 32bit signed int) */}
                {info.licensedTo} for {info.groups === 2147483647 ? "unlimited" : info.groups}{" "}
                {`${info.groups > 1 ? "groups" : "group"}`}
              </div>
            </div>
          ),
        },
        {
          key: "Licence status",
          value: generate_licence_message(info),
        }
      );
      setInfoMapping(current_mapping);

      if (!process.env.NODE_ENV || process.env.NODE_ENV === "development" || session?.features.development_mode) {
        let additional_features_mapping: Content = [];
        const additional_features = Object.entries(info.features).map(([key, value], index) => ({
          key: <span style={{ paddingLeft: 20, fontWeight: "normal" }}>{key}</span>,
          value: <Status type={`${value ? "success" : "danger"}`}>{value ? "enabled" : "disabled"}</Status>,
        }));
        additional_features_mapping.push({ key: "Additional features", value: "" });
        additional_features_mapping.push(...additional_features);
        setAdditionalFeatures(additional_features_mapping);
      }
    }
  }, [generate_licence_message, info, session?.features.development_mode]);

  return (
    <SinglePage>
      <SinglePage.Head>
        <SinglePage.Head.Label>About LOGS</SinglePage.Head.Label>
      </SinglePage.Head>
      <SinglePage.Content>
        <div className={styles.container}>
          <div className={styles.info}>
            <React.Fragment>
              {infoMapping && <ContentTable content={infoMapping} />}
              {additionalFeatures && (
                <React.Fragment>
                  <hr />
                  <ContentTable content={additionalFeatures} />
                </React.Fragment>
              )}
            </React.Fragment>
          </div>

          <div className={styles.vendor}>
            <img src={`${process.env.PUBLIC_URL}/assets/logos/logo_sciy.svg`} alt="SciY" />
            <div style={{ display: "flex", flexFlow: "column nowrap", width: "100%", height: "100%" }}>
              <h3 style={{ margin: 0 }}>
                Bruker Integrated Data Solutions <br />
              </h3>
              <p>
                Bruker BioSpin GmbH & Co. KG
                <br />
                Rudolf-Plank-Str. 23
                <br />
                76275 Ettlingen
                <br />
                Germany
                <br />
                <br />
                {info && (
                  <React.Fragment>
                    <b>
                      <a href={info.homepage} target="_blank" rel="noreferrer">
                        {info.homepage}
                      </a>
                    </b>
                    <br />
                    Support:{" "}
                    <a href={`mailto:${info.email}`} target="_blank" rel="noreferrer">
                      {info.email}
                    </a>
                  </React.Fragment>
                )}
              </p>
            </div>
          </div>
          <div className={styles.eula_wrapper}>
            <EULA />
          </div>
        </div>
      </SinglePage.Content>
    </SinglePage>
  );
};
