import { useRef, useState, useCallback } from "react";
import { ManualUploadActions } from "./FileHandlingUtils";

interface UseManualUploadDragAndDropHandlersProps {
  dispatch: React.Dispatch<ManualUploadActions>;
  readDataTransfer: (dt: DataTransfer) => Promise<void>;
}
export const useManualUploadDragAndDropHandlers = ({
  dispatch,
  readDataTransfer,
}: UseManualUploadDragAndDropHandlersProps) => {
  const drag = useRef<HTMLDivElement>(null);
  const [dragging, setDragging] = useState(false);

  const handleDragOver = useCallback((e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    // console.log("DRAGGING");
  }, []);

  const handleDrop = useCallback(
    async (e: React.DragEvent) => {
      e.preventDefault();
      // e.persist();
      e.stopPropagation();
      setDragging(false);
      const dataTransfer = e.dataTransfer;
      // console.log("DATA transfer", dataTransfer, dataTransfer?.items.length);
      if (dataTransfer) {
        dispatch({ type: "clear" });
        await readDataTransfer(dataTransfer);
      }
    },
    [dispatch, readDataTransfer]
  );

  const handleDragEnter = useCallback((e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.target !== drag.current) {
      setDragging(true);
    }
  }, []);

  const handleDragLeave = useCallback((e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.target === drag.current) {
      setDragging(false);
    }
  }, []);

  return { drag, dragging, setDragging, handleDragOver, handleDrop, handleDragEnter, handleDragLeave };
};
