import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import styles from "../../../common/forms/forms.module.css";
import { FormHeader } from "../../../common/forms/FormHeader";
import { InputFormField } from "../../../common/formfields/InputFormField";
import { TextareaFormField } from "../../../common/formfields/TextareaFormField";
import { FormButtons } from "../../../common/forms/FormButtons";
import { SelectFormField } from "../../../common/formfields/SelectFormField";
import {
  LabNotebookExperiment,
  LabNotebookExperimentStatus,
  LabNotebookExperimentStatusList,
  labNotebookExperimentsConstants,
} from "../../types/LabNotebookExperiment";
import { useCallback } from "react";
import { LabNotebooksVirtualizedSelectForm } from "../../../common/forms/EntityForms/formsVirtualized/LabNotebooksVirtualizedSelectFormField";
import { EntityFormProps } from "../../../common/entity/EntityInterfaces";

export interface LabNotebookExperimentFormValues extends Omit<Partial<LabNotebookExperiment>, "status"> {
  status: { id: string; name: string; value: LabNotebookExperimentStatus };
}

export const LabNotebookExperimentForm = ({
  event,
  fieldLabels,
  loading,
  permissions,
  title,
  subtitle,
  initialValues,
  onSubmit,
  onCancel,
  submitButtonLabel,
}: EntityFormProps<"notebookExperiments">) => {
  const LabNotebookFormSchema = yup.object().shape({
    labNotebook: yup.object().required("A lab notebook is required").typeError("A lab notebook is required"),
    name: yup
      .string()
      .required("Lab notebook experiment name is required")
      .typeError("Lab notebook experiment name is required"),
    status: yup.object().required("Lab notebook status is required").typeError("Lab notebook status is required"),
  });

  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
  } = useForm<LabNotebookExperimentFormValues>({
    values: !!initialValues
      ? {
          ...initialValues,
          status: {
            id: initialValues.status as LabNotebookExperimentStatus,
            name: initialValues.status!.charAt(0) + initialValues.status!.slice(1).toLowerCase(),
            value: initialValues.status as LabNotebookExperimentStatus,
          },
        }
      : {
          status: {
            id: "RUNNING" as LabNotebookExperimentStatus,
            name: "Running",
            value: "RUNNING" as LabNotebookExperimentStatus,
          },
        },
    resolver: yupResolver(LabNotebookFormSchema),
  });

  const formValuesToEntity = useCallback(
    (entity: LabNotebookExperimentFormValues) =>
      onSubmit({ ...entity, status: entity.status.value } as LabNotebookExperiment),
    [onSubmit]
  );

  return (
    <>
      <FormHeader title={title} subtitle={subtitle} />
      <form
        onSubmit={(e) => {
          e.preventDefault();
        }}
        autoComplete="off"
        className={`form-horizontal ${styles.form_holder}`}
      >
        <fieldset>
          <legend className="col-md-offset-2 col-md-10">Basic details</legend>
        </fieldset>

        <LabNotebooksVirtualizedSelectForm
          id="labNotebook"
          label={fieldLabels.labNotebook}
          control={control}
          showControls
          horizontal
          allowCreateEntity
          required
        />

        <hr />

        <InputFormField
          id="name"
          label={fieldLabels.name}
          errors={errors}
          register={register}
          autoFocus={true}
          required
          placeholder="Enter lab notebook name..."
        />

        <SelectFormField
          id="status"
          label={fieldLabels.status}
          items={LabNotebookExperimentStatusList.map((s) => {
            return { id: s, name: s.charAt(0) + s.slice(1).toLowerCase(), value: s };
          })}
          control={control}
          required
          menuPortalTarget={document.body}
          errors={errors}
        />

        <TextareaFormField
          id="description"
          label={fieldLabels.description}
          errors={errors}
          register={register}
          placeholder="Enter description..."
        />

        <FormButtons
          groupName={labNotebookExperimentsConstants.frontendIndexRoute}
          entityId={initialValues ? initialValues.id : undefined}
          onClose={onCancel}
          onSubmit={handleSubmit(formValuesToEntity)}
          submitButtonLabel={submitButtonLabel || "Save changes"}
          disabled={isSubmitting}
          errors={errors}
          loading={loading}
          disableDefaultOnClose
        />
      </form>
    </>
  );
};
