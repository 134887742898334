import { SharedContentForm } from "./SharedContentForm";
import { EntityAddForm, EntityAddFormPage } from "../common/entity/EntityAddForm";
import { EntityCloneForm, EntityCloneFormPage } from "../common/entity/EntityCloneForm";
import { EntityCloneFormProps, EntityDetailViewProps, EntityEditFormProps } from "../common/entity/EntityInterfaces";
import { EntityEditForm, EntityEditFormPage } from "../common/entity/EntityEditForm";
import { EntityDetailPage, EntityDetailView } from "../common/entity/EntityDetailPage";
import { EntityTablePage } from "../common/entity/EntityTablePage";
import { SharedContentDetail } from "./SharedContentDetail";
import { SharedContentsTable } from "./Table/SharedContentsTable";

// Add
export const SharedContentAddForm = () => {
  return <EntityAddForm entityTypeId={"sharedContents"}>{(props) => <SharedContentForm {...props} />}</EntityAddForm>;
};

export const SharedContentAddFormPage = () => {
  return (
    <EntityAddFormPage>
      <SharedContentAddForm />
    </EntityAddFormPage>
  );
};

// Clone
export const SharedContentCloneForm = ({ id }: EntityCloneFormProps<"sharedContents">) => {
  return (
    <EntityCloneForm id={id} entityTypeId={"sharedContents"}>
      {(props) => <SharedContentForm {...props} />}
    </EntityCloneForm>
  );
};

export const SharedContentCloneFormPage = () => {
  return (
    <EntityCloneFormPage<"sharedContents"> isIntId>{(id) => <SharedContentCloneForm id={id} />}</EntityCloneFormPage>
  );
};

// Edit
export const SharedContentEditForm = ({ id }: EntityEditFormProps<"sharedContents">) => {
  return (
    <EntityEditForm id={id} entityTypeId={"sharedContents"} filters={{}}>
      {(props) => <SharedContentForm {...props} />}
    </EntityEditForm>
  );
};

export const SharedContentEditFormPage = () => {
  return <EntityEditFormPage<"sharedContents"> isIntId>{(id) => <SharedContentEditForm id={id} />}</EntityEditFormPage>;
};

// Detail
export const SharedContentsDetailView = ({ id }: EntityDetailViewProps<"sharedContents">) => {
  return (
    <EntityDetailView id={id} entityTypeId={"sharedContents"} filters={{}}>
      {(props) => <SharedContentDetail {...props} />}
    </EntityDetailView>
  );
};

export const SharedContentDetailsPage = () => {
  return <EntityDetailPage<"sharedContents"> isIntId>{(id) => <SharedContentsDetailView id={id} />}</EntityDetailPage>;
};

export const SharedContentsTablePage = () => {
  return (
    <EntityTablePage entityTypeId={"sharedContents"}>{(props) => <SharedContentsTable {...props} />}</EntityTablePage>
  );
};
